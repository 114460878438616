import React from "react";
import { Modal } from "react-bootstrap";
import "./uploadPopup.css";
import $ from "jquery";
import ReadVideo from "../readVideo/readvideo";
//import ReadImage from "../readImage/readImage";
import ProfileImageDisplay from "../profileimage/profileimage";
// import PostImage from "../postimage/postimage";
import PostImage from "../postimage/postimage";
//import PostFile from "../postfile/postfile";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
// import Picker from "emoji-picker-react";
//import ReactPlayer from "react-player";
// const urlRegex = /(https?:\/\/[^\s]+)/gi;
import VideoPlayer from "../videoPlayer/videoplayer";
//import NewFeedsServices from "../../feedService/feedsServices";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import {getLinkPreview} from 'link-preview-js';
import { ReactTinyLink } from "react-tiny-link";


class UploadPopup extends React.Component {
  constructor(props) {
    super(props);
    this.fileAttachRef = React.createRef();
    this.state = {
      feedPostBtnStatus: 1,
      videoUploader: false,
      showMediaUpload: false,
      showLinkUpload: false,
      showEmoji: 0,
      description: "",
      assets: [],
      noPostAllowed: 1,
      chosenEmoji: {},
      channelindex: -1,
      selectedChannelIndex: -1,
      fileAccptance:
        ".PNG,.JPG,.JPEG,.GIF,.MP3,.MP4,.MPEG,.MOV,.WMV,.3GP,.3G2,.AVI,.MKV",
      attachedFiles: [],
      channel_info: "", //{},
      channelsData: this.props.channelsData,
      userInfo: this.props.userInfo,
      og_content: [],
      orgUrl: "",
      urlRegex: /(https?:\/\/[^\s]+)/gi,
      currentuser: this.props.userInfo.currentuser,
      showAlert: 0,
    };
  }
  componentDidMount = () => {
    // alert(this.props.userInfo.currentuser.profile_info.profile_id)
    if (this.props.type === 2) {
      console.log("this.state.channelsData", this.state.channelsData, this.props.feedItemData.channel_info.channel_id)
      var index = this.state.channelsData.findIndex(
        (obj) => obj._id === this.props.feedItemData.channel_info.channel_id
      );
      this.setState({
        description: this.props.feedItemData.description,
        channelindex: index,
        attachedFiles: this.props.feedItemData.assets,
      });
    }
    this.showMediaHandler();
  };
  componentWillReceiveProps() {
    //this is called to before render method
    this.setState({
      value: this.props.data,
    });
  }

  showMediaHandler = () => {
    this.setState({
      showMediaUpload: true,
      showLinkUpload: false,
    });
    $(".cool-link a").click(function () {
      $(".cool-link").removeClass("active");
      $(this).closest(".cool-link").addClass("active");
    });
  };

  showLinkHandler = () => {
    this.setState({
      showMediaUpload: false,
      showLinkUpload: true,
    });
    $(".cool-link a").click(function () {
      $(".cool-link").removeClass("active");
      $(this).closest(".cool-link").addClass("active");
    });
  };

  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.feedsLanguageData && this.props.feedsLanguageData[paramLBL]
        ? this.props.feedsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  onEmojiClickHandler = (emojiObj) => {
    console.log(emojiObj)
    this.setState(
      {
        chosenEmoji: emojiObj,
      },
      () => {
        this.setState({
          description: this.state.description + this.state.chosenEmoji.native,
        });
      }
    );
  };
  // openBrowseWindow
  openBrowseWindow = () => {
    this.setState({
      showEmoji: 0,
    });
    this.fileAttachRef.current.click();
  };
  getAttachedImages = (fileObj) => {
    this.setState({
      attachedFiles: this.state.attachedFiles,
    });
  };
  getAttachedVideo = (fileObj) => {
    this.setState({
      attachedFiles: this.state.attachedFiles,
    });
  };
  channelChangeHandler = (evt) => {
    console.log("evt", evt, evt.target.value)
    this.setState({
      channelindex: Number(evt.target.value),
      // channel_info: this.state.channelsData[evt.target.value].channel_name
    });
    // console.log(this.state.channel_info)
  };
  fileAttachOnChangeHandler = (evt) => {
    //this.props.errorReportChannel(`${this.props.getResourceFromLabels("LBLMAXFILESIZEALERTMSG")} ${process.env.REACT_APP_UPLOAD_VIDEO_SIZE_LIMIT / (1024 * 1024)}MB`)
    if (evt && evt.target && evt.target.files && evt.target.files.length) {
      //this.state.restrictedFileName = [];
      for (let i = 0; i < evt.target.files.length; i++) {
        console.log(evt.target);
        console.log(evt.target.files[i].size);
        let splitArr = String(evt.target.files[i].name).split(".");
        if (
          !this.state.fileAccptance.includes(
            String(splitArr[splitArr.length - 1]).toUpperCase()
          )
        ) {
          this.props.errorReportChannel(
            `${this.props.getResourceFromLabels("LBLINVALIDFILEFORMATMES")} ${this.state.fileAccptance
            }`
          );
          break;
        } else if (
          evt.target.files[i].size >
          process.env.REACT_APP_UPLOAD_VIDEO_SIZE_LIMIT
        ) {
          this.props.errorReportChannel(
            `${this.props.getResourceFromLabels("LBLMAXFILESIZEALERTMSG")} ${process.env.REACT_APP_UPLOAD_VIDEO_SIZE_LIMIT / (1024 * 1024)
            }MB`
          );
          break;
        } else {
          // let fileName = evt.target.files[i].name
          // validationResult = SCUtils.validateFileName(fileName);

          //if(!this.restrictSpecialCharForFileName(evt.target.files[i].name)){

          this.state.attachedFiles.push(evt.target.files[i]);
          // this.state.attachedFiles.push(URL.createObjectURL(evt.target.files[0]));
          if (evt.target.files[i].type.match("image.*")) {
            this.getAttachedImages(evt.target.files[i]);
          } else if (evt.target.files[i].type.match("video.*")) {
            // this.getAttachedVideo(evt.target.files[i]);
            this.getAttachedVideo(URL.createObjectURL(evt.target.files[0]));
          }
          // }
          // else{
          //     this.state.restrictedFileName.push(evt.target.files[i].name);
        }
      }
    }

    evt.target.value = "";
  };
  showImojiPickerHandler = (evt) => {
    this.setState(
      {
        showEmoji: this.state.showEmoji === 1 ? 0 : 1,
      },
      () => {
        if (this.state.showEmoji === 1) {
          console.log("out side click event added");
          document.addEventListener("click", this.emojiOutsideClickHandler);
        }
      }
    );
  };
  emojiOutsideClickHandler = (evt) => {
    //console.log("this.emojiOutsideClickHandler",evt.target, evt.currentTarget);
    let elements = evt.target; //document.getElementById("target");
    let els = [];
    let classAtr = "";
    while (elements) {
      els.unshift(elements);
      elements = elements.parentNode;
      classAtr =
        elements && elements.getAttribute && elements.getAttribute("class")
          ? elements.getAttribute("class")
          : "";
      if (classAtr === "ssi-feeds-feedsPostEmojiHolder") {
        break;
      }
    }  
    if(evt.target.id !== "smiley" && evt.target.id !== "ssi-feeds-textArea" && classAtr !== "ssi-feeds-feedsPostEmojiHolder" ) {
      document.removeEventListener("click", this.emojiOutsideClickHandler);
      this.setState({
        showEmoji:0
      });
    }
  };
  handleChange = (event) => {
    this.setState({
      description: event.target.value,
     
    });
  };
  removePostAssets = (index) => {
    // console.log(event)
    this.state.attachedFiles.splice(index, 1);
    this.setState({
      attachedFiles: this.state.attachedFiles,
    });
    // this.setState({description: event.target.value});
  };
  createPostHandler = (event) => {
    console.log("*******", this.state.channelindex)
    var postfeed = {};
    var og_content = [];
    if (this.props.type === 2) {
      postfeed = {
        description: this.state.description,
        // "og_content": og_content,
        _id: this.props.feedItemData._id,
      };

      this.props.submitPost(event, postfeed, this.state.attachedFiles, 1);
    } else {
      if (this.state.description.match(this.state.urlRegex)) {
        og_content.push({
          heading: "",
          sub_heading: "",
          description: "",
          cover_image: "",
          link: this.state.description.match(this.state.urlRegex).toString(),
        });
      }
      if (this.state.channelindex !== -1) {
        postfeed = {
          profile_info: {
            active_status: 1,
            profile_id: this.state.currentuser.user_id,
          },
          channel_info: {
            channel_name:
              this.state.channelsData[this.state.channelindex].channel_name,
            channel_profile_image:
              this.state.channelsData[this.state.channelindex]
                .channel_profile_image,
            channel_id: this.state.channelsData[this.state.channelindex]._id,
            active_status:
              this.state.channelsData[this.state.channelindex].active_status,
          },
          description: this.state.description,
          feeds_approval_status: 1,
          tags: [],
          del_flag: 0,
          profile_id: this.state.currentuser.user_id,
          og_content: og_content,
          assets: [], //this.state.attachedFiles
        };

        this.props.submitPost(event, postfeed, this.state.attachedFiles, 1);
      } else {
        this.props.submitPost(event, postfeed, this.state.attachedFiles, 0);
      }
    }
  };
  render() {
    console.log("")
    return (
      <Modal show={this.props.isOpen} id="modal-forCreatePost" className="modal_content">
        <Modal.Header
          closeButton
          className="alert-modal-header"
          onClick={this.props.onHide}
        >
          <Modal.Title className='alert-modal-title'>
            {this.props.label}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="ssi-feeds-postBody">
          <div className="ssi-feeds-profileContents">
            <div className="ssi-feeds-contProfile">
              <ProfileImageDisplay
                imageName={""}
                firstLetterDisplay={
                  this.state.userInfo[
                    this.props.userInfo.currentuser.profile_info.profile_id
                  ]?.user_name[0]
                }
              />

              <div>
                <span className="ssi-feeds-userName">
                  {
                    this.state.userInfo[
                      this.props.userInfo.currentuser.profile_info.profile_id
                    ]?.user_name
                  }
                </span>
              </div>
            </div>
            <div className="ssi-feeds-textArea" >
              <textarea
              id="ssi-feeds-textArea"
                maxlength="1024"
                value={this.state.description}
                placeholder={this.props.getResourceFromLabels(
                  "LBLTYPEYOURPOST"
                )} //{"What do you want to write about?"}
                onChange={this.handleChange}
                className="ssi-feeds-textarea"
              ></textarea>
              {this.state.description.match(this.state.urlRegex) &&
                this.state.attachedFiles.length === 0 ? (
                <span>
                  <ReactTinyLink
                    cardSize="small"
                    showGraphic={true}
                    maxLine={2}
                    minLine={1}
                    url={this.state.description
                      .match(this.state.urlRegex)
                      .toString()} //"https://www.youtube.com/watch?v=XEtSJ1whUBM"//{this.state.orgUrl}//"https://www.amazon.com/Steve-Madden-Mens-Jagwar-10-5/dp/B016X44MKA/ref=lp_18637582011_1_1?srs=18637582011&ie=UTF8&qid=1550721409&sr=8-1"
                  />
                </span>
              ) : null}
            </div>
            <div
              className="ssi-feeds-feedsPostTextArea"
              id="ssi-feeds-feedsPostTextArea"

            >
              {this.state.attachedFiles.map((fileObj, index) => {
                console.log(fileObj);
                if (this.props.type !== 2) {
                  if (fileObj.type.match("image.*")) {
                    return (
                      // <img src={ URL.createObjectURL(fileObj)}></img>
                      <PostImage
                        key={fileObj.name}
                        index={index}
                        type={1}
                        imageObj={URL.createObjectURL(fileObj)}
                        // imageObj={fileObj}
                        removePostAssets={this.removePostAssets}
                        feedsLanguageData={this.state.feedsLanguageData}
                      />
                    );
                  } else if (fileObj.type.match("video.*")) {
                    return (
                      // <span></span>
                      // <ReactPlayer url={this.state.attachedFiles1} />
                      <div className="ssi-feeds-videoPostOverlay">
                        <VideoPlayer
                          company_id={11}
                          url={URL.createObjectURL(fileObj)}
                          fileName={"ss"}
                          propFullScreen={true}
                          autoPlay={false}
                          propProgressBar={true}
                          labels={this.videoPlayerLabels}
                        />
                        <div
                          className="ssi-feeds-imageCloseBtn"
                          onClick={() => {
                            this.removePostAssets(index);
                          }}
                        >
                          <span className="fas fa-times-circle"></span>
                        </div>
                      </div>
                    );
                  }
                } else {
                  if (fileObj.asset_type === "image") {
                    return (
                      <PostImage
                        key={fileObj.name}
                        index={index}
                        type={2}
                        signedUrlResponseHandler={
                          this.props.signedUrlResponseHandler
                        }
                        // imageObj={URL.suma(fileObj)}
                        imageObj={fileObj}
                        removePostAssets={this.removePostAssets}
                        feedsLanguageData={this.state.feedsLanguageData}
                      />
                    );
                  } else {
                    let assetFolder = String(fileObj.asset_name).split(".");
                    // assetFolder.pop();
                    if (assetFolder.length > 1) {
                      assetFolder.pop();
                    }
                    return (
                      // <VideoPlayer company_id={11}  url={response.data} fileName={"ss"} propFullScreen={true} autoPlay={false} propProgressBar={true} labels={this.videoPlayerLabels} />
                      <span>
                        <ReadVideo
                          company_id={this.props.feedItemData.company_id}
                          index={index}
                          key={fileObj._id}
                          feed_id={this.props.feedItemData._id}
                          assetItem={fileObj}
                          totalAssets={1}
                        />
                      </span>
                    );
                    // })
                  }
                }
                return null;
              }
              )}
            </div>
          </div>
          {/* <textarea></textarea> */}
        </Modal.Body>
        <Modal.Footer className="modal-popup-footer">
          <div className="ssi-feeds-feedsPostBtnContainer">
            <div className="ssi-feeds-feedsPostBtnWrapper">
              <div
                className={"ssi-feeds-feedsPostEmojiBtn"}
                onClick={this.showImojiPickerHandler}
                id="smiley"
                title={this.props.getResourceFromLabels("LBLEMOJI")}
              // title={this.getResourceFromLabels("LBLEMOJI")}
              >

                <span className="far fa-smile" style={{pointerEvents: 'none'}} ></span>
              </div>
              {this.state.showEmoji !== 0 ? (
                <div className="ssi-feeds-feedsPostEmojiHolder">
                  {/* <Picker set='apple' /> */}
                  <Picker showSkinTones={false} showPreview={false} onSelect={this.onEmojiClickHandler} />
                  {/* <Picker onEmojiClick={this.onEmojiClickHandler} /> */}
                </div>
              ) : (
                ""
              )}
              {this.props.type === 1 ? (
                <span
                  className="ssi-feeds-camInputIcon"
                  onClick={this.openBrowseWindow}
                  title={this.props.getResourceFromLabels("LBLPHOTO")}
                // title={`${this.getResourceFromLabels(
                //   "LBLPHOTO"
                // )} / ${this.getResourceFromLabels("LBLVIDEO")}`}
                >
                  <input
                    type="file"
                    ref={this.fileAttachRef}
                    hidden
                    multiple
                    accept={this.state.fileAccptance}
                    onChange={this.fileAttachOnChangeHandler}
                  />
                  <span className="fas fa-camera" id="camera"></span>
                </span>
              ) : null}

              {this.props.channelsData.length ? (
                <div className="ssi-feeds-channelListInPost">
                  <div className="ssi-feeds-channelListInPostWrapper">

                    {console.log("this.props.typeauto", this.props.type)}
                    {console.log("this.state.channelindexauto", this.state.channelindex)}
                    {console.log("this.props.channelsDataauto", this.props.channelsData)}
                    <Autocomplete
                      //className={this.state.channelindex === -1 ? "postChannelListDropdown placeholder" : "postChannelListDropdown"}
                      className="filter-drpdwn"
                      aria-required="true"
                      required
                      disableClearable
                      noOptionsText={this.props.getResourceFromLabels("LBLNOOPTIONSDROPDOWN")}
                      options={this.props.channelsData}
                      getOptionLabel={(option) => option.channel_name}
                      disabled={this.props.type === 2}
                      //value={this.state.channelindex}
                      value={this.state.channelindex > -1 ? this.props.channelsData.find((option, index) => { return this.state.channelindex === index }) : null}
                      //onChange={this.channelChangeHandler}
                      onChange={(e, i) => {
                        let selindex = -1;
                        let findobj = this.props.channelsData.find((option, index) => {
                          if (i.channel_id === option.channel_id) {
                            console.log("index", index)
                            selindex = index
                            return index
                          }
                        })
                        console.log("qq", i, e);
                        this.setState({
                          channelindex: selindex,
                        });
                      }}
                      renderOption={(option) => (
                        <div className={this.state.channelindex === -1 ? "postChannelListDropdown placeholder" : "postChannelListDropdown"} style={{ fontSize: "14px" }}>{option.channel_name}</div>
                      )}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            placeholder={this.props.getResourceFromLabels(
                              "LBLCHOOSETHECHANNEL"
                            )}
                            {...params}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div
                className={
                  this.state.description.trim() !== "" ||
                    this.state.attachedFiles.length > 0
                    ? "ssi-feeds-feedsPostdBtn"
                    : "ssi-feeds-feedsPostdBtn disable"
                }
                onClick={this.createPostHandler}
              >
                {/* <button>{this.getResourceFromLabels("LBLPOST")}</button> */}
                <button className="btn btn-primary btn-sm">
                  {this.props.getResourceFromLabels("LBLPOST")}
                </button>
              </div>
            </div>
          </div>
          {/* <Button variant="secondary" onClick={this.props.onHide}>
            {this.getResourceFromLabels("LBLCANCEL")}
          </Button>
          <Button variant="primary">
            {this.getResourceFromLabels("LBLDONE")}
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UploadPopup;

import React, { useEffect } from 'react';
import styles from "././deactivate.module.scss"
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import landingimg from "../../assets/images/landingimg.png";
import acculandingbg from "../../assets/images/acculandingbg.png";
import CompanyLogo from '../../admin/components/companylogo/companylogo';

const DeactivatedPage = (props) => {
    const historyVal = useHistory();
    useEffect(()=> {
        console.log("history val",historyVal);
        historyVal.replace("/deactivated");
    },[])
    console.log("propsss---",props.labels.labels);

  // render()// const {company_logo } = this.props;
  const {company_logo } = props; 
  console.log("props",props)
 localStorage.getItem('site_type')
   return (
    localStorage.getItem('site_type')=== 'SafeStart'?
         <div className={styles.landingpage}>
            <div className={styles.landingpagectn}>
            <div className={styles.lanpgheadctn}>
               <div>
                        <CompanyLogo
                          imageName={company_logo}
                        />
                        {/* <img src={loginlogo} /> */}
                      </div>
            </div>
            {/* <span>hii</span> */}
           {/* <div className={styles.ctn}> */}
          <div className={styles.lanctn}>
            <div className={styles.lanalerttext}>
            <div className={styles.lanpgerrortxt}>{props.labels.labels.LBLSERVERERRORHEADHEAD}</div>   
            <div className={styles.lanpgerrorsubtxt}>{props.labels.labels.LBLSAFEDESCRIPTION}</div>
      
                <div className={styles.landingalertmsg}>
                <div className={styles.landalerttxt}>{props.labels.labels.LBLDEACTIVEERRORMESSAGE}</div>
                <hr style={{margin:"20px 0px",background:"#FFFFFF",opacity: "20%"}}></hr>
                <div className={styles.landalerttxt1}>{props.labels.labels.LBLACCUALERT}<span className={styles.lansupporttxt}>{props.labels.labels.LBLSUPPORT}</span></div>
                   
                </div>
           </div>
                <div className={styles.ladingimg}>
                <img src={landingimg} style={{height:"90%",width:"90%" }}></img>
                </div>
                </div>
        </div>
     {/* </div> */}
           
           
        </div>

  :
    <div className={styles.acculandingpage}>
    <div className={styles.landingpagectn}>
    <div className={styles.lanpgheadctn}>
       <div>
                <CompanyLogo
                  imageName={company_logo}
                />
                {/* <img src={loginlogo} /> */}
              </div>
    </div>
    {/* <span>hii</span> */}
   {/* <div className={styles.ctn}> */} 
  <div className={styles.lanctn}>
    <div className={styles.lanalerttext}>
    <div className={styles.lanpgerrortxt}>{props.labels.labels.LBLACCUHEAD}</div>   
    <div className={styles.lanpgerrorsubtxt}>{props.labels.labels.LBLHDACCUDESCRIPTION}</div>
        <div className={styles.landingalertmsg}>
          <div className={styles.landalerttxt}><span>{props.labels.labels.LBLACCUDESCRIPTION}</span></div>
          <hr style={{margin:"20px 0px",background:"#FFFFFF",opacity: "20%"}}></hr>
          <div className={styles.landalerttxt1}><span>{props.labels.labels.LBLACCUALERT}</span> <span className={styles.lansupporttxt}>{props.labels.labels.LBLACCUSUPPORT}</span></div>

        </div>
   </div>
        <div className={styles.ladingimg}>
        <img src={acculandingbg} style={{height:"80%",width:"80%" }}></img>
        </div>
        </div>
</div>
{/* </div> */}
   
   
</div>

        
      
   
    );
    

}

export default DeactivatedPage;

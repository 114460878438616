import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Point } from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import './HighChart.scss'
highchartsMore(Highcharts);
solidGauge(Highcharts);

const HighGaugeChart = (props) => {
    console.log(props.percent)
    const [options, setOptions] = useState({
        chart: {
            type: "solidgauge",
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0,
            spacingBottom: 0,
            height: "180",
            style: {
                fontFamily: "'Roboto', sans-serif", fontSize: "14p",
                fontWeight: "500"
            }
        },
        title: null,
        pane: {
            center: ['50%', '75%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            },

        },

        credits: false,
        exporting: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },
        yAxis: {

            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                y: -70
            },
            labels: {
                y: 16
            },
            min: 0,
            max: 10
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }

        }, 
        series: [
            {
                name: "Move",
                type: "solidgauge",
                data: [{
                    color: props.color,
                    y: props.percent
                }],
                dataLabels: {
                    format:
                        '<div style="text-align:center">' +
                        '<span class="gauge-score">{y}</span><br/>' +
                        '<span class="gauge-label">' + props.label + '</span>' +
                        '</div>'

                }

            },


        ]
    });
    useEffect(() => {
        setOptions({
            ...options, series: [
                {
                    name: "Move",
                    type: "solidgauge",
                    data: [{
                        color: props.color,
                        y: props.percent
                    }],
                    dataLabels: {
                        format:
                            '<div style="text-align:center;">' +
                            '<span  class="gauge-score">{y}</span><br/>' +
                            '<span class="gauge-label">' + props.label + '</span>' +
                            '</div>'

                    }

                },


            ]
        })
    }, [props.percent, props.color, props.label])

    return (
        <div  >
            <HighchartsReact containerProps={{ style: { height: "100%" } }}
                highcharts={Highcharts} options={options} />
        </div>
    );
};

export default HighGaugeChart
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

function Alert(props) {
    const { title, body, button, type, action, onClose, alertFor } = props
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false);
        onClose();
    }

    const handleAction = (name,index) => {
        setShow(false);
        action(name, index, alertFor);
    }

    return (
        <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton className='alert-modal-header'>
                <Modal.Title className= {"alert-modal-title"}>{title ? title : 'Empty'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert-modal-body">
                    {body ? body : 'Empty'}
                </div>
            </Modal.Body>
            <Modal.Footer style={{borderTop:"none"}}>
              {body !== 'No Message' && props.showButton  ?   
                button.map((btn, btn_idx) => {
                    return(
                    <button
                    key={'alert_'+btn_idx}
                    type="button"
                    className={ (btn_idx === 0 ? "button-style secondary-btn" : (type === 'warning' ? "button-style danger-btn" : "button-style primary-btn" ) )}
                    onClick={() => handleAction(btn, btn_idx)}
                  >
                 {btn}
                  </button>
                    )
                }): null }
            </Modal.Footer>
        </Modal>
    );
}

Alert.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    type: PropTypes.string,
    button: PropTypes.array,
    action: PropTypes.func,
    onClose: PropTypes.func
};
Alert.defaultProps = {
    title: "No Title",
    body: "No Message",
    type: "primary",
    action:() => {},
    onClose:() => {}
}

export default Alert;
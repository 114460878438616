import React, { Component } from "react";
import { animateScroll as scroller } from "react-scroll";
import "./ratestate.scss";
import DatePicker from "react-date-picker";
import ScCommonServices from "../../../services/common_services";
import InputRange from "react-input-range";
import { Modal } from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import MailSettings from "../settings/mailsettings";
import $ from "jquery";

let response = {};
let scrollPoint = null;
let images = [
  { image: "../../images/nearmiss/Eyes.png" },
  { image: "../../images/nearmiss/Mind.png" },
  { image: "../../images/nearmiss/Line.png" },
  { image: "../../images/nearmiss/Balance.png" },
];
const cstLabels = {
  LBLEDITMAILSETTINGS: "RYS Mail Settings",
  LBLRYSNOTIFICATIONSETTINGS: "RYS Reminder Settings",
};
class RateState extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const uid = params.get("uid");
    this.totalPages = 4;
    this.state = {
      showScreen: "RYS",
      selected: 0,
      show: false,
      loading: true,
      version: 1.0,
      LBLRUSHING: 0,
      LBLFRUSTRATION: 0,
      LBLFATIGUE: 0,
      LBLCOMPLACENCY: 0,
      next: false,
      previous: false,
      err: false,
      qanswers: [],
      rys_date: new Date(),
      choices: [],
      user_id: uid || this.props.user_id,
      LBLOTHER: "",
      showMS: false,
      initialValues: {},
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
    };
    this.baseState = this.state;
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield];
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        if (cstLabels.hasOwnProperty(xfield.label)) {
          return cstLabels[xfield.label].replace("$$$", xfield.val);
        } else {
          return xfield.label;
        }
      }
    }
  };
  componentDidMount = () => {
    setTimeout(() => {
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("aside").addClass("slideOutLeft");
      $("#sidenav").css({ display: "none" });
    }, 1000);
    ScCommonServices.stateContents().then((contents) => {
      this.setState({ loading: false });
      let questions = contents.result2.map((item, index) => {
        let tempItem = [];
        JSON.parse(item.options).map((opt) => {
          opt = { ...opt, userAnswer: index === 0 ? true : false };
          return tempItem.push(opt);
        });
        item.options = tempItem;
        return item;
      });
      let passvars = window.location.href.split("#")[1];
      this.setState({ qanswers: questions, passvars: passvars });
    });
  };

  componentWillUnmount = () => {};

  getAnswers = (e, item2) => {
    scrollPoint = window.scrollY;
    //let val = e.target.value;
    item2.userAnswer = e.target.checked;
    let qanswers = this.state.qanswers;
    this.setState({ qanswers });
  };

  setInitialState = () => {
    response = {};
    let orgQanswers = this.state.qanswers.map((item, index) => {
      let tempItem = [];
      item.options.map((opt) => {
        opt = { ...opt, userAnswer: index === 0 ? true : false };
        return tempItem.push(opt);
      });
      item.options = tempItem;
      return item;
    });
    this.setState({
      LBLRUSHING: 0,
      LBLFRUSTRATION: 0,
      LBLFATIGUE: 0,
      LBLCOMPLACENCY: 0,
      next: false,
      choices: [],
      qanswers: orgQanswers,
      LBLOTHER: "",
      loading: false,
      rys_date: new Date(),
    });
  };

  scrollToSection = () => {
    scroller.scrollTo("test2", {
      duration: 800,
      delay: 0,
      smooth: "easeInQuad",
    });
  };

  submitAnswer = () => {
    response = {};
    let resObj = "{";
    let version = this.state.qanswers[0].version;
    this.state.qanswers[0].options.map((item, index) => {
      resObj +=
        '"' +
        item.label_id +
        '":{"qid":' +
        this.state.qanswers[0].label_id +
        ',"vr":' +
        version +
        ',"ovl":' +
        this.state[item.label_val] +
        "},";
    });
    this.state.qanswers
      .slice(1, this.state.qanswers.length)
      .map((questions, index) => {
        questions.options.map((item) => {
          if (item.userAnswer === true) {
            resObj +=
              '"' +
              item.label_id +
              '":{"qid":' +
              questions.label_id +
              ',"vr":' +
              version +
              ',"ovl":' +
              1 +
              "},";
          }
          if (item.type === "user_input" && this.state.LBLOTHER) {
            resObj +=
              '"' +
              item.label_id +
              '":{"qid":' +
              questions.label_id +
              ',"vr":' +
              version +
              ',"ovl":"' +
              this.state.LBLOTHER +
              '"}}';
          } else if (item.type === "user_input") {
            let pos = resObj.lastIndexOf(",");
            resObj = resObj.substring(0, pos) + String("}");
          }
        });
      });
    //response.resArr = resArr;
    response.resObj = resObj;
    let datenew = new Date(this.state.rys_date);
    datenew.setHours(datenew.getHours() + 5);
    datenew.setMinutes(datenew.getMinutes() + 30);
    let postData = {
      response,
      user_id: this.state.user_id || this.props.user_id,
      card_id: "rys",
      time_stamp: datenew,
      activity_id: this.state.passvars,
    };
    ScCommonServices.rate_your_state(postData)
      .then((response) => {
        scrollPoint = 0;
        this.setState(
          {
            show: true,
            // selected: 0
          },
          () => {
            setTimeout(() => {
              this.setInitialState();
            }, 4000);
          }
        );
        setTimeout(() => {
          this.setState({
            show: false,
          });
          setTimeout(() => {
            if (this.state.passvars) {
              let xurl = window.location.href;
              xurl =
                xurl.substr(0, xurl.lastIndexOf("/rate-state")) + "/products";
              window.location.href = xurl;
            } else {
              window.scrollTo({ top: 0 });
              this.slider.slickGoTo(0);
            }
          }, 1000);
        }, 3000);
      })
      .catch((err) => {
        scrollPoint = 0;
        if (err) {
          console.log(err);
        }
      });
  };

  handleSelectedChange = (selected) => {
    this.setState({ selected });
  };

  stateDateHandler = (date) => {
    this.setState({ rys_date: date });
  };

  handleRange = (event, item) => {
    scrollPoint = window.scrollY;
    this.setState({ [item.label_val]: event });
    this.setState((state) => ({
      selected: state.selected + 1,
      next: true,
    }));
    this.slider.slickNext();
  };

  handleRangeInMobile = (event, item) => {
    scrollPoint = window.scrollY;
    this.setState({ [item.label_val]: event });
  };

  next = () => {
    window.scrollTo({ top: 0 });
    this.setState((state) => ({
      selected: state.selected + 1,
      next: true,
    }));
    this.slider.slickNext();
  };

  previous = () => {
    window.scrollTo({ top: 0 });
    this.setState((state) => ({
      selected: state.selected - 1,
      next: false,
    }));
    this.slider.slickPrev();
  };

  closeModal = () => {
    this.setState({ show: false, loading: false }, () => {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
        this.slider.slickGoTo(0);
      }, 3000);
    });
  }; //test1

  userInputChange = (e) => {
    const enterVal = e.target.value.replace(/\n/g, " ");
    this.setState({ LBLOTHER: enterVal });
  };

  closeModalMS = () => {
    this.setState({
      showMS: false,
    });
    try {
      $(".modal-backdrop").css({ position: "relative" });
    } catch (e) {}
  };
  ShowToastMS = (x) => {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: x,
        changeInToast: new Date(),
      },
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 1500);
    this.closeModalMS();
  };

  ShowToastMSSEND = (x) => {
    this.closeModalMS();
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: x,
        changeInToast: new Date(),
      },
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 1500);
  };

  openModalMS = (item, e) => {
    this.setState({
      showScreen: "RYSREMINDERSETTINGS",
    });
  };
  showReminderSettings = (item, e) => {
    this.setState({
      showScreen: "RYSREMINDERSETTINGS",
    });
  };
  showRYSState = (item, e) => {
    this.setState({
      showScreen: "RYS",
    });
  };

  hideReminderSettings = (item, e) => {
    this.setState({
      showScreen: "RYS",
    });
  };
  render() {
    const {
      loading,
      qanswers,
      LBLRUSHING,
      LBLFRUSTRATION,
      LBLFATIGUE,
      LBLCOMPLACENCY,
      LBLOTHER,
      next,
      selected,
      rys_date,
      showScreen,
      show,
      showToast,
      initialValues      
    } = this.state;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      touchMove: false,
    };
    var todayDate = rys_date.toLocaleDateString("en-GB"); //this.props.language_code
    let todate = new Date();
    todate.setDate(todate.getDate() - 30);
    const { labels, role_id } = this.props;

    return (
      <div className="ssi-rys rateState">
        {showScreen === "RYS" ? (
          <>
            <div className="page-header-field">
              <h3 className="page-title">{labels.labels.LBLRATEYOURSTATE}</h3>
              {role_id === 1 ? (
                <div className="btn-align">
                  <button
                    className="button-style primary-btn"
                    data-toggle="modal"
                    onClick={this.showReminderSettings}
                  >
                    {this.getLabel("LBLRYSNOTIFICATIONSETTINGS")}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <hr className="head-line" />
            <div className="card-box rate-section">
              <div className="row rate-not-xs">
                {loading === false ? (
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <section className="rate-card">
                      <div className="ratecard-section">
                        <div className="rate-slide">
                          {qanswers.slice(0, 1).map((item, i) => {
                            return (
                              <div className="qa-cardbd">
                                <div className="form-group rate-header qa-rate-board">
                                  <h4>{labels.labels[item.label]}</h4>
                                  <div className="rate-dp">
                                    <DatePicker
                                      onChange={this.stateDateHandler}
                                      value={rys_date}
                                      className="form-control"
                                      format={this.props.dateformat}
                                      minDate={todate}
                                      maxDate={new Date()}
                                      locale={
                                        this.props.language_code.split("-")[0]
                                      }
                                    />
                                    <span>{todayDate}</span>
                                  </div>
                                </div>
                                <div className="rate-range">
                                  {item.options.map((item2, index2) => {
                                    return (
                                      <div className="form-group">
                                        <h4 className="text-center">
                                          {labels.labels[item2.label_val]}
                                        </h4>
                                        <div className="form-group">
                                          <InputRange
                                            maxValue={10}
                                            minValue={0}
                                            value={this.state[item2.label_val]}
                                            onChange={(e) =>
                                              this.handleRange(e, item2)
                                            }
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="rate-footer">
                        <button
                          className="button-style primary-btn"
                          onClick={this.next}
                          disabled={
                            !LBLFRUSTRATION &&
                            !LBLCOMPLACENCY &&
                            !LBLRUSHING &&
                            !LBLFATIGUE
                          }
                        >
                          {labels.labels.LBLNEXT}
                        </button>
                      </div>
                    </section>
                  </div>
                ) : (
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                    <section className="rate-card">
                      <div className="ratecard-section">
                        <div className="rate-slide">
                          <div className="qa-cardbd">
                            <div className="form-group rate-header qa-rate-board">
                              <h4>
                                <Skeleton height={35} />
                              </h4>
                              <div className="rate-dp">
                                <Skeleton />
                              </div>
                            </div>
                            <div className="rate-range">
                              <div className="form-group">
                                <h4>
                                  <Skeleton height={20} />
                                </h4>
                                <div className="form-group">
                                  <Skeleton height={15} />
                                </div>
                              </div>
                              <div className="form-group">
                                <h4>
                                  <Skeleton height={20} />
                                </h4>
                                <div className="form-group">
                                  <Skeleton height={15} />
                                </div>
                              </div>
                              <div className="form-group">
                                <h4>
                                  <Skeleton height={20} />
                                </h4>
                                <div className="form-group">
                                  <Skeleton height={15} />
                                </div>
                              </div>
                              <div className="form-group">
                                <h4>
                                  <Skeleton height={20} />
                                </h4>
                                <div className="form-group">
                                  <Skeleton height={15} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
                <div
                  className="col-xl-3 col-lg-3 col-md-6 col-sm-6"
                  style={
                    (LBLRUSHING ||
                      LBLCOMPLACENCY ||
                      LBLFRUSTRATION ||
                      LBLFATIGUE) &&
                    next
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <section className="rate-card">
                    <div className="ratecard-section">
                      <div className="rate-slide qa-rate-board qa-rate-board">
                        {qanswers.slice(1, 2).map((item, i) => {
                          return (
                            <div className="qa-cardbd">
                              <h4>{labels.labels[item.label]}</h4>
                              <div class="row">
                                {item.options.map((item2, index2) => {
                                  return (
                                    <div class="column">
                                      <div class="card box-state">
                                        <label class="check-lbl">
                                          <input
                                            className="checkbox-iconLabel"
                                            type="checkbox"
                                            value={item2.label_id}
                                            onClick={(e) =>
                                              this.getAnswers(e, item2)
                                            }
                                            checked={item2.userAnswer}
                                          />
                                          <span class="checkmark"></span>
                                          <img
                                            src={images[index2].image}
                                            alt=""
                                          ></img>
                                        </label>
                                        <span className="rys-text">
                                          {labels.labels[item2.label_val]}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="rate-footer">
                      <button
                        className="button-style primary-btn  button-right"
                        onClick={this.previous}
                        disabled={!selected}
                      >
                        {labels.labels.LBLPREVIOUS}
                      </button>
                      <button
                        className="button-style primary-btn button-right"
                        onClick={this.submitAnswer}
                        //disabled={selected + 1 === this.totalPages}
                      >
                        {labels.labels.LBLSUBMIT}
                      </button>
                    </div>
                  </section>
                </div>
                <div
                  className="col-xl-3 col-lg-3 col-md-6 col-sm-6"
                  style={
                    (LBLRUSHING ||
                      LBLCOMPLACENCY ||
                      LBLFRUSTRATION ||
                      LBLFATIGUE) &&
                    next
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <section className="rate-card">
                    <div className="ratecard-section">
                      <div className="rate-slide qa-rate-board qa-rate-board">
                        {qanswers.slice(2, 3).map((item, i) => {
                          return (
                            <div className="qa-cardbd qa-cardbdmid">
                              <h4>{labels.labels[item.label]}</h4>
                              {item.options.map((item2, index2) => {
                                return (
                                  <label
                                    class={`check-lbl bg-opts  ${
                                      item2.userAnswer ? "selectedlbl" : ""
                                    }`}
                                  >
                                    <span class="rys-textTwo">
                                      {labels.labels[item2.label_val]}
                                    </span>
                                    <input
                                      type="checkbox"
                                      value={item2.label_id}
                                      onClick={(e) => this.getAnswers(e, item2)}
                                      checked={item2.userAnswer}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="rate-footer"
                      style={
                        !LBLCOMPLACENCY && next
                          ? { visibility: "initial" }
                          : { visibility: "hidden" }
                      }
                    >
                      <button
                        className="button-style primary-btn button-right"
                        onClick={this.previous}
                        disabled={!selected}
                      >
                        {labels.labels.LBLPREVIOUS}
                      </button>
                      <button
                        className="button-style primary-btn  button-right"
                        onClick={this.submitAnswer}
                        //disabled={selected + 1 === this.totalPages}
                      >
                        {labels.labels.LBLSUBMIT}
                      </button>
                    </div>
                  </section>
                </div>
                <div
                  className="col-xl-3 col-lg-3 col-md-6 col-sm-6"
                  style={
                    LBLCOMPLACENCY && next
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <section className="rate-card">
                    <div className="ratecard-section">
                      <div className="rate-slide qa-rate-board qa-rate-board">
                        {qanswers.slice(3, qanswers.length).map((item, i) => {
                          return (
                            <div className="qa-cardbd">
                              <h4>{labels.labels[item.label]}</h4>
                              {item.options.map((item2, index2) => {
                                return item2.label_id === 27 ? (
                                  <div className="rate-ifield">
                                    <label className="label label-one">
                                      {labels.labels.LBLOTHER} :
                                    </label>
                                    <textarea
                                      rows="3"
                                      className="input-field"
                                      onChange={this.userInputChange}
                                      value={LBLOTHER}
                                      placeholder={labels.labels.LBLENTERHABIT}
                                      style={{ width: "100%" }}
                                    ></textarea>
                                  </div>
                                ) : (
                                  <div>
                                    <label
                                      class={`check-lbl  habit-card ${
                                        item2.userAnswer ? "selectedlbl" : ""
                                      }`}
                                    >
                                      <span
                                        style={{
                                          color: item2.userAnswer
                                            ? "#2196F3"
                                            : "#7E7B7B",
                                        }}
                                      >
                                        {labels.labels[item2.label_val]}
                                      </span>
                                      <input
                                        type="checkbox"
                                        value={item2.label_id}
                                        onClick={(e) =>
                                          this.getAnswers(e, item2)
                                        }
                                        checked={item2.userAnswer}
                                      />
                                      <span class="checkmark"></span>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="rate-footer"
                      style={
                        LBLCOMPLACENCY && next
                          ? { visibility: "initial" }
                          : { visibility: "hidden" }
                      }
                    >
                      <button
                        className="button-style primary-btn button-right"
                        onClick={this.previous}
                        disabled={!selected}
                      >
                        {labels.labels.LBLPREVIOUS}
                      </button>
                      <button
                        className="button-style primary-btn  button-right"
                        onClick={this.submitAnswer}
                        // disabled={selected + 1 === this.totalPages}
                      >
                        {labels.labels.LBLSUBMIT}
                      </button>
                    </div>
                  </section>
                </div>
              </div>

              {/* Rate Your State Mobile View  */}
              <div className="justify-content-center rate-xs">
                <div className="rate-card">
                  <Slider
                    ref={(c) => (this.slider = c)}
                    {...settings}
                    className="ratecard-section"
                  >
                    <div className="rate-slide">
                      {qanswers.slice(0, 1).map((item, i) => {
                        return (
                          <div className="qa-cardbd">
                            <div className="form-group rate-header qa-rate-board">
                              <h2 className="text-center">
                                {labels.labels.LBLRATEYOURSTATE}
                              </h2>
                              <h4>
                                {loading === false ? (
                                  labels.labels[item.label]
                                ) : (
                                  <Skeleton height={42} />
                                )}
                              </h4>
                              {loading === false ? (
                                <div className="rate-dp">
                                  <DatePicker
                                    onChange={this.stateDateHandler}
                                    value={rys_date}
                                    className="form-control"
                                    format={this.props.dateformat}
                                    minDate={todate}
                                    maxDate={new Date()}
                                    locale={
                                      this.props.language_code.split("-")[0]
                                    }
                                  />
                                  <span>{todayDate}</span>
                                </div>
                              ) : (
                                <Skeleton height={44} />
                              )}
                            </div>
                            <div className="rate-range">
                              {item.options.map((item2, index2) => {
                                return (
                                  <div className="form-group">
                                    <h4 className="text-center">
                                      {loading === false ? (
                                        labels.labels[item2.label_val]
                                      ) : (
                                        <Skeleton height={21} />
                                      )}
                                    </h4>
                                    <div className="form-group">
                                      {loading === false ? (
                                        <InputRange
                                          maxValue={10}
                                          minValue={0}
                                          value={this.state[item2.label_val]}
                                          onChange={(e) =>
                                            this.handleRangeInMobile(e, item2)
                                          }
                                        />
                                      ) : (
                                        <Skeleton height={16} />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                      <div
                        className={`rate-footer ${
                          LBLFRUSTRATION ||
                          LBLCOMPLACENCY ||
                          LBLRUSHING ||
                          LBLFATIGUE
                            ? "rate-footer-visible"
                            : ""
                        }`}
                      >
                        {!next ? (
                          ""
                        ) : (
                          <button
                            className="button-style primary-btn button-right"
                            onClick={this.previous}
                          >
                            {labels.labels.LBLPREVIOUS}
                          </button>
                        )}
                        <button
                          className="button-style primary-btn button-right"
                          onClick={this.next}
                          disabled={
                            !LBLFRUSTRATION &&
                            !LBLCOMPLACENCY &&
                            !LBLRUSHING &&
                            !LBLFATIGUE
                          }
                        >
                          {labels.labels.LBLNEXT}
                        </button>
                      </div>
                    </div>

                    <div className="rate-slide qa-rate-board qa-rate-board">
                      <h2 className="text-center">
                        {labels.labels.LBLRATEYOURSTATE}
                      </h2>
                      {qanswers.slice(1, 2).map((item, i) => {
                        return (
                          <div className="qa-cardbd">
                            <h4>{labels.labels[item.label]}</h4>
                            <div class="row">
                              {item.options.map((item2, index2) => {
                                return (
                                  <div class="column">
                                    <div class="card box-state">
                                      <label class="check-lbl">
                                        <input
                                          type="checkbox"
                                          value={item2.label_id}
                                          onClick={(e) =>
                                            this.getAnswers(e, item2)
                                          }
                                          checked={item2.userAnswer}
                                        />
                                        <span class="checkmark"></span>
                                        <img
                                          src={images[index2].image}
                                          alt=""
                                        ></img>
                                      </label>
                                      <span className="rys-text">
                                        {labels.labels[item2.label_val]}
                                      </span>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                      <div
                        className={`rate-footer ${
                          LBLFRUSTRATION ||
                          LBLCOMPLACENCY ||
                          LBLRUSHING ||
                          LBLFATIGUE
                            ? "rate-footer-visible"
                            : ""
                        }`}
                      >
                        <button
                          className="button-style primary-btn button-right"
                          onClick={this.previous}
                        >
                          {labels.labels.LBLPREVIOUS}
                        </button>
                        <button
                          className="button-style primary-btn button-right"
                          onClick={this.next}
                          disabled={
                            !LBLFRUSTRATION &&
                            !LBLCOMPLACENCY &&
                            !LBLRUSHING &&
                            !LBLFATIGUE
                          }
                        >
                          {labels.labels.LBLNEXT}
                        </button>
                      </div>
                    </div>
                    <div className="rate-slide qa-rate-board qa-rate-board">
                      <h2 className="text-center">
                        {labels.labels.LBLRATEYOURSTATE}
                      </h2>
                      {qanswers.slice(2, 3).map((item, i) => {
                        return (
                          <div className="qa-cardbd qa-cardbdmid">
                            <h4>{labels.labels[item.label]}</h4>
                            {item.options.map((item2, index2) => {
                              return (
                                <div>
                                  <label
                                    class={`check-lbl bg-opts ${
                                      item2.userAnswer ? "selectedlbl" : ""
                                    }`}
                                  >
                                    <span className="rys-textTwo">
                                      {labels.labels[item2.label_val]}
                                    </span>
                                    <input
                                      type="checkbox"
                                      value={item2.label_id}
                                      onClick={(e) => this.getAnswers(e, item2)}
                                      checked={item2.userAnswer}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                      <div
                        className={`rate-footer ${
                          LBLFRUSTRATION ||
                          LBLCOMPLACENCY ||
                          LBLRUSHING ||
                          LBLFATIGUE
                            ? "rate-footer-visible"
                            : ""
                        }`}
                      >
                        <button
                          className="button-style primary-btn button-right"
                          onClick={this.previous}
                        >
                          {labels.labels.LBLPREVIOUS}
                        </button>
                        {!LBLCOMPLACENCY ? (
                          <button
                            className="button-style primary-btn button-right"
                            onClick={this.submitAnswer}
                          >
                            {labels.labels.LBLSUBMIT}
                          </button>
                        ) : (
                          <button
                            className="button-style primary-btn button-right"
                            onClick={this.next}
                            disabled={
                              !LBLFRUSTRATION &&
                              !LBLCOMPLACENCY &&
                              !LBLRUSHING &&
                              !LBLFATIGUE
                            }
                          >
                            {labels.labels.LBLNEXT}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="rate-slide qa-rate-board qa-rate-board">
                      <h2 className="text-center">
                        {labels.labels.LBLRATEYOURSTATE}
                      </h2>
                      {qanswers.slice(3, qanswers.length).map((item, i) => {
                        return (
                          <div className="qa-cardbd">
                            <h4>{labels.labels[item.label]}</h4>
                            {item.options.map((item2, index2) => {
                              return (
                                <div>
                                  <label
                                    class={`check-lbl ${
                                      item2.userAnswer ? "selectedlbl" : ""
                                    }`}
                                  >
                                    <span className="habit-card">
                                      {labels.labels[item2.label_val]}
                                    </span>
                                    <input
                                      type="checkbox"
                                      value={item2.label_id}
                                      onClick={(e) => this.getAnswers(e, item2)}
                                      checked={item2.userAnswer}
                                    />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                              );
                            })}
                            <div className="rate-ifield">
                              <label>{labels.labels.LBLOTHER} :</label>
                              <textarea
                                rows="3"
                                className="form-control text-brdclr"
                                onChange={this.userInputChange}
                                placeholder={labels.labels.LBLENTERHABIT}
                                style={{ width: "100%" }}
                              ></textarea>
                            </div>
                          </div>
                        );
                      })}
                      <div
                        className={`rate-footer ${
                          LBLFRUSTRATION ||
                          LBLCOMPLACENCY ||
                          LBLRUSHING ||
                          LBLFATIGUE
                            ? "rate-footer-visible"
                            : ""
                        }`}
                      >
                        <button
                          className="button-style primary-btn  button-right"
                          onClick={this.previous}
                          // style={{marginLeft:"5px"}}
                        >
                          {labels.labels.LBLPREVIOUS}
                        </button>
                        <button
                          className="button-style primary-btn button-right"
                          onClick={this.submitAnswer}
                        >
                          {labels.labels.LBLSUBMIT}
                        </button>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>

              <Modal show={show} onHide={this.closeModal} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4 className="alert-modal-title">
                      {labels.labels.LBLRATEYOURSTATE}
                    </h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-xl-12 rate-sub-card">
                        <div class="center">
                            <i class="fa fa-check icon"></i>                          
                        </div>
                        <h2>{labels.labels.LBLRYSALERTHDR}</h2>
                      </div>
                      <div className="rate-sub-card col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div
                          class="rate-sub-box"
                          style={
                            (LBLRUSHING === 0 && {
                              background: "#c4cbd4",
                            }) ||
                            (LBLRUSHING <= 4 && {
                              background: "#3d9e5e",
                            }) ||
                            ((LBLRUSHING === 5 || LBLRUSHING === 6) && {
                              background: "#ddb618",
                            }) ||
                            (LBLRUSHING >= 7 && {
                              background: "#dd4b18",
                            })
                          }
                        >
                          <h5>{LBLRUSHING}</h5>
                        </div>
                        <h3
                          className="modal-rys"
                          title={labels.labels.LBLRUSHING}
                        >
                          {labels.labels.LBLRUSHING}
                        </h3>
                      </div>
                      <div className="rate-sub-card col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div
                          class="rate-sub-box"
                          style={
                            (LBLFRUSTRATION === 0 && {
                              background: "#c4cbd4",
                            }) ||
                            (LBLFRUSTRATION <= 4 && {
                              background: "#3d9e5e",
                            }) ||
                            ((LBLFRUSTRATION === 5 || LBLFRUSTRATION === 6) && {
                              background: "#ddb618",
                            }) ||
                            (LBLFRUSTRATION >= 7 && {
                              background: "#dd4b18",
                            })
                          }
                        >
                          <h5>{LBLFRUSTRATION}</h5>
                        </div>
                        <h3
                          className="modal-rys"
                          title={labels.labels.LBLFRUSTRATION}
                        >
                          {labels.labels.LBLFRUSTRATION}
                        </h3>
                      </div>
                      <div className="rate-sub-card col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div
                          class="rate-sub-box"
                          style={
                            (LBLFATIGUE === 0 && {
                              background: "#c4cbd4",
                            }) ||
                            (LBLFATIGUE <= 4 && {
                              background: "#3d9e5e",
                            }) ||
                            ((LBLFATIGUE === 5 || LBLFATIGUE === 6) && {
                              background: "#ddb618",
                            }) ||
                            (LBLFATIGUE >= 7 && {
                              background: "#dd4b18",
                            })
                          }
                        >
                          <h5>{LBLFATIGUE}</h5>
                        </div>
                        <h3
                          className="modal-rys"
                          title={labels.labels.LBLFATIGUE}
                        >
                          {labels.labels.LBLFATIGUE}
                        </h3>
                      </div>
                      <div className="rate-sub-card col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <div
                          class="rate-sub-box"
                          style={
                            (LBLCOMPLACENCY === 0 && {
                              background: "#c4cbd4",
                            }) ||
                            (LBLCOMPLACENCY <= 4 && {
                              background: "#3d9e5e",
                            }) ||
                            ((LBLCOMPLACENCY === 5 || LBLCOMPLACENCY === 6) && {
                              background: "#ddb618",
                            }) ||
                            (LBLCOMPLACENCY >= 7 && {
                              background: "#dd4b18",
                            })
                          }
                        >
                          <h5>{LBLCOMPLACENCY}</h5>
                        </div>
                        <h3
                          className="modal-rys"
                          title={labels.labels.LBLCOMPLACENCY}
                        >
                          {labels.labels.LBLCOMPLACENCY}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            {showToast.show ? (
              <ToastMessage
                show={showToast.show}
                helpers={showToast}
              ></ToastMessage>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {showScreen === "RYSREMINDERSETTINGS" ? (
          <>
            <MailSettings
              {...this.props}
              initialValues={initialValues}
              closeModal={this.hideReminderSettings}
              ShowToast={this.ShowToastMS}
              ShowToastSEND={this.ShowToastMSSEND}
            ></MailSettings>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    user_id: state.user_info_reducer.user_info.user_id,
    language_code: state.user_info_reducer.user_info.language_code,
    dateformat: state.user_info_reducer.user_info.dateformat,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
  };
};
export default connect(mapStateToProps, null)(RateState);

import React from "react"; 
import Button from "@material-ui/core/Button"; 
import { withStyles } from '@material-ui/styles';
import { alpha, styled } from '@material-ui/styles'; 
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import Switch from '@material-ui/core/Switch';
//import Switch from '@material-ui/core/GreenSwitch'; 
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import { Field, reduxForm, change } from "redux-form";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert"; 
import { Form } from "react-bootstrap"; 
import Loader from "../../components/loader/Loader";
import { bindActionCreators } from "redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ScCommonServices from "../../../services/common_services";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import UsersService from "../user-list/UsersService"; 
import "date-fns"; 
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import { LibraryAddCheckSharp } from "@material-ui/icons";
  
const $ = require("jquery");
let lblstatusconfirm = "";

const GreenSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#38c16f',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#38c16f',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#CDCDCD',
    opacity: 1,
  },
}));
let img_path;
let classes;
class TeamList extends React.Component {

  constructor(props) {
    super(props);
    const { editTeamDetails } = props;

    this.state = {
      shownorecords: false,
      shownorecs: false,
      userlist:[],
      selectedDate: new Date(),
      dbteamListsAll: [],
      dbteamLists: [],
      status: [],
      teamLists: {},
      showList: false,
      loading: true,
      noImageUrl:"../../images/steercom/No_Image.png",
      dateformat: props.dateformat,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
    };
  }
  classes = styled();
  componentDidMount() {
    $('#root').scrollTop(0);
    img_path = process.env.REACT_APP_RESOURCES_URL;
    this.props.role_id == 1
      ? $(".cls_add_teams").show()
      : $(".cls_add_teams").hide();
    var header = this.props.labels.labels.LBLSTEERCOMTEAMS;
    this.getUsers();
    this.setdateformat();
    this.isAdminCheck(); 
  }
  getUsers = async() => { 
    let result = await SteercomServices.getStreercomUsers();
    UsersService.getUsers({ active: true })
      .then((res) => {
       let allusers=[], suserslist=[], userlist = res.result; 
       suserslist = result.result[0][0].users?JSON.parse(result.result[0][0].users):[];
        userlist.map((item,x)=>{
          suserslist.map((itm,y)=>{
            if(itm.user_id == item.user_id){
              allusers.push(item)
            }
          });
        }); 
        let shownorecs, user_response = allusers;  
        shownorecs=allusers.length>0?false:true;
        this.getTeamListCallback(allusers)
        this.setState((state, props) => ({
          userlist: allusers,  
        }));
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  isAdminCheck = async () => {
    let res = await SteercomServices.getMemberTypeDash();
    let isTeamAdmin = 0;
    let roleid = this.props.role_id;
    if (res.result[0][0].TeamAdmin_TeamCount > 0 || roleid == 1) {
      isTeamAdmin = 1;
    }
    if (roleid == 1) {
      isTeamAdmin = 1;
    } else {
      isTeamAdmin = 0;
    }
    this.setState({
      isTeamAdminUser: isTeamAdmin
    });
  }
  deleteTeamCallback = (res) => {
    this.loadTeamList();
  };
  deleteTeam = (team_id) => {
    let apiUrl = "steercomteam/" + team_id;
    let data = {
      team_id: team_id,
    };
    SteeringCommitteeService.SteeringAPIServicesCall(
      apiUrl,
      "delete",
      this.deleteTeamCallback,
      data
    );
  };
  editTeam = (team_id) => {
    this.props.editTeamDetails(team_id);
  };
  editTeamMembersList = (team_id, item) => {
    setTimeout(() => {
      this.props.editTeamMembersList(item, team_id, 2);
    }, 100);

  };

  getTeamListCallback = async (allusers) => {
    let cnt_recs=0, shownorecs, res = await SteercomServices.getTeamList();
    let teams = res.result;
    let { status, showList ,userlist} = this.state;
    let dbteamLists = [], dbteamListsAll = res.result;
    if (teams.length > 0) {
      teams.map((item, idx) => {
        status[item.team_id] = item.team_status == 0 ? false : true;
        if (item.team_status != 0) {
          item.groups=JSON.parse(item.groups)
          dbteamLists.push(item);

          if(this.props.role_id == 1 ||
            (this.props.role_id > 1 &&
              (this.props.userId == item.team_administrator || 
                this.props.userId == item.team_addnl_administrator))){
                cnt_recs++;
              }
        }
         
      })
    }
    //dbteamLists = []; 
    if (showList) {
      shownorecs = res.result.length > 0 ? false : true;
    } else {
      shownorecs = dbteamLists.length > 0 ? false : true;
      if(cnt_recs==0){
        shownorecs=true;
      }
    }

    if(dbteamLists)
    dbteamLists.map((item, idx) => {
      if (item.image_name) {
        let url = this.getImage(item, dbteamLists);
      } else {
        item.imageUrl = null;
      }
    });
    userlist=allusers?allusers:[];
    if(dbteamLists.length==0 && userlist.length==0){ 
      $(".cls_add_teams").addClass("cls_disable");
    }else{
      $(".cls_add_teams").removeClass("cls_disable");
    }
    setTimeout(() => {
      this.setState({ 
        loading: false,
        dbteamListsAll: res.result,
        dbteamLists: dbteamLists, 
        status,
        shownorecords: shownorecs
      });
    }, 100); 
  };
  getImage = (item, dbteamLists) => {
    let imageName = item.image_name;
    if (imageName) {
      let assetFolder = String(imageName).split(".");
      assetFolder.pop();
      let postData = {
        filename: imageName,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      // return this.getImagePath(postData);
      this.getAttachedImages(item, dbteamLists);
    }
  }
  async getImagePath(postData) {
    let imageUrl = "";
    let testurl = await ScCommonServices.getAssyncSignedUrl(postData).then((resultData) => {
      let arrUrl, arrUrlCompany, url = resultData.data;
      arrUrl = url.split("image/");
      arrUrlCompany = arrUrl[1].split("/")
      url = arrUrl[0] + "image/" + this.props.companyId + "/" + arrUrlCompany[1];
      imageUrl = url
      fetch(url, {
        retryOn: function (attempt, error, response) {
          if (!response || response.status >= 403) {
            return false;
          }
        },
      }).then((response) => {
        if (response.status === 200) {
          imageUrl = resultData.data;
          this.setState({
            imageUrl: resultData.data,
          });
        }
      });
    });
    return imageUrl;
  }
  async getAttachedImages(assets, dbteamLists) {
    let asset_name = "ss.jpg";
    let FEEDAPIURL = process.env.REACT_APP_FEEDAPI_URL;
    let assetFolder = String(assets.image_name).split(".");
    assetFolder.pop();
    let index = 0;
    dbteamLists.map((item, idx) => {
      if (item.team_id == assets.team_id) {
        index = idx;
      }
    })
    let data = {
      filename: assets.image_name,
      assettype: "image",
      assetFoldet: assetFolder.join(),
    };
    let state = this.state;
    let urlinfo,
      allInfo = [],
      urlData = [];
    if (assets.image_name.length > 0) {
      let data = {
        filename: assets.image_name,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      const response = await HttpInterceptor.post(
        FEEDAPIURL + "gets3signedassetsurl",
        data
      ).then(
        (response) => {
          if (response) {
            allInfo = response.data.data;
            urlinfo = allInfo;
            urlData.push(urlinfo);
            if (dbteamLists)
              dbteamLists[index].imageUrl = urlData[0];
            this.setState({ imageUrl: urlData[0], dbteamLists: dbteamLists });
          }
          return urlData;
        },
        (err) => { }
      );
    }

  }
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
  };
  searchAllItems = (search_Value,mode) =>{ 
    let {dbteamListsAll,searchValue} = this.state; 
    searchValue = mode==1?search_Value:searchValue;
    let loc_dbteamListsAll = dbteamListsAll && dbteamListsAll.filter( item  => 
      (item.team_name &&
        (item.team_name.includes(searchValue) ||
          item.team_name.toLowerCase().includes(searchValue.toLowerCase()))) ||
      (item.adminname &&
        (item.adminname.includes(searchValue) ||
          item.adminname.toLowerCase().includes(searchValue.toLowerCase()))) ||
      (item.start_date &&
        (this.formattingDate(item.start_date).substr(0, 10).includes(searchValue) ||
        this.formattingDate(item.start_date).substr(0, 10).toLowerCase().includes(searchValue.toLowerCase()))) || 
      (item.groups &&
        (isNaN(item.groups) ? JSON.stringify(item.groups).includes(searchValue) : false ||
        isNaN(item.groups) ?  JSON.stringify(item.groups).toLowerCase().includes(searchValue.toLowerCase()):false))
    )
      
      return loc_dbteamListsAll;
  }
  handleSearch = (event) => {
    let search_text;
    if (event.currentTarget) {
      search_text = event.currentTarget.value;
    } else {
      search_text = event;
    }
    if(!this.state.showList) {
    $("#searchclear").css({visibility:"hidden"});
    if (search_text.length > 0) {
      $("#searchclear").css({visibility:"visible"});
    }
    let topelement1,
      topelement = $("#teamlist").children();
    let divcount = $("#successfactorlist").children().length;
    $.expr[":"].contains = function (a, i, m) {
      return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
    };
    let search_result1, shownorecs = false,search_result;
      search_result = $("div:contains(" + search_text + ")")
        .filter(function () {
          return $(this).children().length === 0;
        })
        .parent()
        .parent()  //.css({"display":"block"})
        .parent();
    if (search_text.length > 0) {
      topelement.map((idx, item) => {
        $(item).css({ display: "none" });
      });
      search_result.map((ix, itm) => {
        if (itm.parentElement && itm.parentElement.id) {
          shownorecs = true;
          $(itm.parentElement).css({ display: "" });
        }
      });
    } else {
      topelement.map((idx, item) => {
        $(item).css({ display: "" });
        shownorecs = true;
      });
    }

    if (shownorecs) {
      $("#norecs").hide();
    } else {
      $("#norecs").show();
    }
  }
  this.setState({
    searchValue:search_text
  })
  };
  getImages = () => {
    let all_images = [
      "../images/steercom/Team_1.png",
      "../images/steercom/Team_2.png",
      "../images/steercom/Team_3.png",
      "../images/steercom/Team_4.png",
      "../images/steercom/Team_5.png",
      "../images/steercom/Team_6.png",
    ];
    let img_lnk = all_images[Math.floor(Math.random() * all_images.length)];
    return img_lnk;
  };
  getColors = () => {
    let clr = [
      "#FB6E52",
      "#F4D88F",
      "#C6E7A3",
      "#85E9D0",
      "#89D5F1",
      "#9BBFED",
    ];
    let r_clr = clr[Math.floor(Math.random() * clr.length)];
    return r_clr;
  };
  handleSwitchChange = (val, teamdata) => {
    let stats, value;
    let { status } = this.state;
    value = val.currentTarget.id;
    stats = this.state.status[value] == true ? false : true;
    let team_data = [];
    teamdata.team_status = stats ? 1 : 0;
    status[value] = stats;
    this.openConfirmDialog1(teamdata, status);
  }
  updateTeamStatus = async (team_data, status) => { 
    let team_datas ={
       team_status:team_data.team_status,
       team_id : team_data.team_id,
       mode:1
    }
    let res = await SteercomServices.editTeam(team_datas); 
    if (res.status == "SUCCESS") {
      this.showAlert("success", this.props.labels.labels["LBLUPDTSTATUSSUCC"]);
    }
    this.setState({
      status: status
    })
  }

  showAlert = (messageType, message) => {
    this.setState({
      openalert: true,
      messageType: messageType,
      message: message
    });
  };

  handleClose = () => {
    this.setState({ openalert: false });
  };
  handleTabsChange = (target) => {
    let shownorecs = false;
    let { dbteamListsAll, dbteamLists } = this.state;
    let showList = false, val = target.currentTarget.id;

    if (val == "tabs2") {
      showList = true;
      shownorecs = dbteamListsAll.length > 0 ? false : true;
    } else {
      shownorecs = dbteamLists.length > 0 ? false : true;
    }
    this.setState({
      showList: showList,
      shownorecords: shownorecs
    })
  }
  openConfirmDialog1 = (item, status) => {
    let opendialog3 = false, teamSuccessFactorList = this.state.teamSuccessFactorList;
    lblstatusconfirm = this.props.labels.labels.LBLCONFIRMSTATUS + item.team_name + "?";
    this.setState({
      opendialog1: true,
      teamdata: item,
      status: status
    })
  }
  handleCloseDialog1 = (val) => {
    let { teamdata, status } = this.state;

    if (val.currentTarget.id == 2) {
      if(typeof(teamdata.groups)!='object')
      teamdata.groups = JSON.parse(teamdata.groups);
      this.updateTeamStatus(teamdata, status);
      lblstatusconfirm = "";
    } else {
      status[teamdata.team_id] = status[teamdata.team_id] ? false : true
    }
    this.setState({
      opendialog1: false,
      status: status
    })

  }
  render() {
    let getPlantNames = (xdata) => {
      let xnames = [];
      if (typeof (xdata) == "string") {
        xdata = JSON.parse(xdata);
      }
      Object.keys(xdata).map((item, index) => {
        if (xdata[item]) {
          xdata[item].map((citem, index) => {
            if (citem.name != "") {
              xnames.push(citem.name);
            }
          });
        } else {

        }
      });
      return xnames.join(", ");
    };
    let {
      LBLSTEERCOMDET, LBLACTION, LBLPLANNORECORDS, LBLEDIT, LBLREQUIRED, LBLSTATUS,
      LBLSTEERINGCOMMITTEE,LBLSEARCH,LBLTEAMSEARCH,LBLEDTMEMBER,LBLVWMEMBER,LBLEDTTEAM,LBLVWTEAM,
      LBLSUBMIT, LBLYES, LBLAGREE, LBLNO,
      LBLCANCEL,
      LBLPLANT, LBLPLANTASSOCIATE, LBLACTIVETEAMS, LBLALLTEAMS,
      LBLTEAMADMINS,
      LBLTEAMS,
      LBLTOTMEMBERS,
      LBLVIEW, LBLCONFIRMSTATUS,
      LBLTEAM, LBLNORECSFOUND,LBLNORECSFOUNDNOTEAM,
      LBLSTEERCOMSTARTDATE,
    } = this.props.labels.labels;
    const { teamLists, dbteamLists, dbteamListsAll, isTeamAdminUser ,searchValue,showList} = this.state;
    const userId = this.props.userId;
    let filtered_dbteamListsAll = dbteamListsAll?dbteamListsAll:[];
    if(searchValue)
      filtered_dbteamListsAll = this.searchAllItems()
    this.formattingDate = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let dateformat,
        fulldate = "";
      dateformat = this.state.dateformat;
      if (dateformat == "dd/MM/yyyy") {
        fulldate = [day, month, year].join("/");
      } else if (dateformat == "MM/dd/yyyy") {
        fulldate = [month, day, year].join("/");
      } else if (dateformat == "yyyy/MM/dd") {
        fulldate = [year, month, day].join("/");
      } else {
        fulldate = [day, month, year].join("/");
      }
      return fulldate;
    };
    let m = 1;
    const renderToggleInput = (field) => (
      <Form.Check
        type="switch"
        id={"custom-switch" + m++}
        label=""
        checked={field.input.value === "" ? true : field.input.value}
        onChange={field.input.onChange}
        icons={false}
      />
    );
    let status = this.state.status;
    let teamSuccessFactorColumns = [
      {
        name: LBLTEAM,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "23%",
        compact: true,
        cell: (row, index) => (
          <div
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "1px"
            }}
          >
            <div
                      style={{
                        width: "4px",
                        height: "35px", 
                        backgroundColor: "rgb(128, 227, 186)",
                        border: "2px solid rgb(128, 227, 186)", 
                        display: "block",
                        marginRight:"5px"
                      }}
                    ></div>
            {this.props.userId == row.created_by ||
              this.props.userId == row.team_administrator || 
              this.props.userId == row.team_addnl_administrator ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid",
                    display: "flex",
                  }}
                >
                  
                  {row.team_member_id != "NEW" &&
                    isNaN(row.team_member_id) == false ? (
                    <div
                      style={{ display: "inline-block", paddingLeft: "10px" }}
                    >
                      <label
                        class={
                          row.isNewAssigned
                            ? "check-lbl"
                            : "check-lbl check-newassigned"
                        }
                      >
                        <input
                          type="checkbox"
                          name={"chkg_" + row.id}
                          id={"chkg_" + row.id}
                          data-toggle="modal"
                          checked={row.isNewAssigned}
                          onClick={this.openConfirmDialog.bind(
                            this,
                            row,
                            row.isNewAssigned ? 1 : 0
                          )}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                  <div title={row.team_name} className="cls_team_name" style={{ display: "inline-block" }}>
                    <div className="cls_team_name_span1">
                      {row.team_name}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="cls_team_name" >
                  <div className="cls_team_name_span1">
                    {row.team_name}
                  </div>
                </div>
              </>
            )}
          </div>
        ),
      },
      {
        name: LBLPLANTASSOCIATE,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => {
          let index = 0;
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == row.created_by ||
              this.props.userId == row.team_administrator || 
              this.props.userId == row.team_addnl_administrator) && 
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
            <div
              className={"cls_team_title"}
              style={{ width: "100%", border: "0px solid", padding: "0px" ,marginLeft:"2px" }}
            >
              <div className="cls_team_name" >
                <div className="cls_team_name_span grid-ellipsis">
                  {getPlantNames(row.groups)}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        name: LBLTEAMADMINS,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "15%",
        compact: true,
        cell: (row) => {
          let index = 0;
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == row.created_by ||
              this.props.userId == row.team_administrator || 
              this.props.userId == row.team_addnl_administrator) && 
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
            <div
              className={"cls_team_title"}
              style={{ width: "100%", border: "0px solid", padding: "0px",marginLeft:"2px" }}
            >
              <div className="cls_admin_name" >
                <div className="cls_team_name_span1">
                  {row.adminname}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        name: LBLSTEERCOMSTARTDATE,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "10%",
        compact: true,
        cell: (row) => {
          let index = 0;
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == row.created_by ||
              this.props.userId ==row.team_administrator || 
              this.props.userId ==row.team_addnl_administrator) && 
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
            <div
              className={"cls_team_title"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              <div className="cls_team_name" >
                <div className="cls_team_name_span grid-ellipsis">
                  {this.formattingDate(row.start_date.substr(0, 10))}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        name: LBLTOTMEMBERS,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "10%",
        compact: true,
        cell: (row) => {
          let index = 0;
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == row.created_by ||
              this.props.userId == row.team_administrator || 
              this.props.userId == row.team_addnl_administrator ) && 
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
            <div
              className={"cls_team_title"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              <div className="cls_team_name" >
                <div className="cls_team_name_span ">
                  {row.memberscount}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        name: LBLSTATUS,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "10%",
        compact: true,
        cell: (row, idx) => {
          let index = 0;
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == row.created_by ||
              this.props.userId ==row.team_administrator || 
              this.props.userId ==row.team_addnl_administrator) && 
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
            <div id={row.team_id}
              className={"cls_team_title cls-rad-mail"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {(row.created_by == userId || row.team_administrator == userId || row.team_addnl_administrator == userId) ? (
                <GreenSwitch
                  id={row.team_id}
                  checked={this.state.status[row.team_id]}
                  onChange={(e) => this.handleSwitchChange(e, row)}
                  color="primary"
                />) : ""}
            </div>
          )
        },
      },
      {
        name: LBLACTION,
        selector: "status",
        width: "10%",
        compact: true,
        cell: (row) => {
          let index = 0;

          return (
            <div
              className={"cls_team_title"}
              style={{ width: "100%", border: "0px solid", "marginLeft": "-18px" }}
            >
              <img
                src={(row.created_by == userId || row.team_administrator == userId || row.team_addnl_administrator == userId) ? (
                  "../images/steercom/team_edit1.png"
                ) : ("../images/steercom/eye21.png")}
                onClick={this.editTeamMembersList.bind(
                  this,
                  row.team_id
                )}
                title={(row.created_by == userId || row.team_administrator == userId || row.team_addnl_administrator == userId) ? (
                  LBLEDTMEMBER
                ) : (LBLVWMEMBER)}
                className="cls_view_new cls_clear"
                alt=""
              ></img>
              <img
                src={(row.created_by == this.props.userId || row.team_administrator == this.props.userId || row.team_addnl_administrator == userId) ? (
                  "../images/steercom/1team_edit.png"
                ) : ("../images/steercom/1eye20.png")}
                onClick={this.editTeam.bind(
                  this,
                  row.team_id
                )}
                title={(row.created_by == this.props.userId || row.team_administrator == this.props.userId || row.team_addnl_administrator == userId) ? (
                  LBLEDTTEAM
                ) : (LBLVWTEAM)}
                className="img_team_edit"
                alt=""
              ></img>
            </div>
          )
        },
      },
    ]; 
    let horizontal = "top",
      vertical = "center"; 
      LBLNORECSFOUND = this.state.userlist.length>0?LBLNORECSFOUND:LBLNORECSFOUNDNOTEAM;
       
    return (
      <div className="team_main_container container">
        <Dialog className="cls_dialog1"
          open={this.state.opendialog1}
          onClose={this.handleCloseDialog1}
          aria-labelledby="responsive-dialog-title"
          contentStyle={{ minWidth: "500px" }}
        >
          <div>
            <DialogTitle id="responsive-dialog-title" className="mui-title">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="cls_reset_all">{LBLTEAM} </div>
                <i style={{ margin: "2px 13px 0px 13px"}} class="fas fa-times fas fa-bars mui-close-icon" onClick={this.handleCloseDialog1}></i>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText >
                <div className="mui-modal-body">{lblstatusconfirm}</div>
              </DialogContentText>
            </DialogContent>

            <DialogActions className="mui-footer">
              <button autoFocus id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog1} >
                {LBLNO}
              </button>
              <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog1} >
                {LBLYES}
              </button>
            </DialogActions>
          </div>
        </Dialog>
        <Snackbar
          anchorOrigin={{ horizontal, vertical }}
          key={"top-center"}
          open={this.state.openalert}
          autoHideDuration={this.state.showtime ? this.state.showtime : 3000}
          onClose={this.handleClose}
          bodyStyle={{ backgroundColor: "teal", color: "coral" }}
        >
          <Alert
            variant="filled"
            onClose={this.handleClose}
            severity={this.state.messageType}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        
        {this.state.loading ? (
            <Loader></Loader>
          // <Skeleton height={300} />
        ) : (
          <div>
            <div className="cls_tabs_container">
              <ul className="nav nav-tabs" style={{ visibility: dbteamListsAll.length > 0 ? "visible" : "hidden" }}>
                <li className={ !this.state.showList ? "active" : ""}><div id="tabs1"  onClick={this.handleTabsChange}>{LBLACTIVETEAMS}</div></li>
               <li className={ this.state.showList ? "active" : ""}> <div style={{ display: isTeamAdminUser == 1 ? "" : "none" }} id="tabs2" onClick={this.handleTabsChange}>{LBLALLTEAMS}</div></li>
              </ul>
              {this.state.shownorecords ? "" :
                  <div class="cls_search_container margin-bottom-sm">
                    {/* <span class="cls_search_icon"><i class="fa fa-search"></i></span> */}
                    <input
                      // style={{width:"270px"}}
                      type="text"
                      id="team_search"
                      className="input-field"
                      placeholder={showList ? LBLTEAMSEARCH : LBLTEAMSEARCH}
                      value={this.state.searchValue}
                      onChange={this.handleSearch}
                    />
                    <a
                      id="searchclear"
                      class="fa fa-times search_clear"
                      style={{ visibility: "hidden" }}
                      onClick={() => { $('#team_search').val(''); this.handleSearch(""); return false; }}>
                    </a>
                  </div>
              }
            </div>              
            {!this.state.showList ?
              <div class="row" id="teamlist" style={{display: this.state.shownorecords ? "none":""}}>
               
                {dbteamLists &&
                  dbteamLists.length > 0 &&
                  dbteamLists.map((item, index) => {
                    return (
                      <>
                        {this.props.role_id == 1 ||
                          (this.props.role_id > 1 &&
                            (this.props.userId == item.team_administrator || 
                              this.props.userId == item.team_addnl_administrator)) ? (
                          <>
                            <div
                              id={"parent_div_" + index}
                              className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 img-card-box"
                            >
                              <div className="cls_main_container">
                              <div
                                className="cls-team-dtls" data-animation="ripple"
                                // style={{ background: "#9bb8f4" }} 
                                onClick={this.editTeam.bind(
                                  this,
                                  item.team_id
                                )}
                              >
                                <div id="topcontainerImage" className="cls_topcontainerImage">
                                  <div id="containerImage1">
                                    {item.imageUrl ?
                                      <img id="teamImage" className="cls_teamImage1" src={item.imageUrl}>
                                      </img>
                                      :
                                      <img id="teamImage" style={{filter: "brightness(.96)"}} className="cls_teamImage1" src={this.state.noImageUrl}>
                                        
                                      </img>}
                                  </div>
                                  <div id="containerImage2">
                                    <img
                                      className="cls_teamImage2 img_team_delete"
                                      style={{ filter: "invert(80%)" }}
                                      src={(item.created_by == this.props.userId || item.team_administrator == this.props.userId || item.team_addnl_administrator == userId) ? (
                                        "../images/steercom/team_edit.png"
                                      ) : ("../images/steercom/eye20.png")}
                                      onClick={this.editTeam.bind(
                                        this,
                                        item.team_id
                                      )}
                                      title={(item.created_by == this.props.userId || item.team_administrator == this.props.userId || item.team_addnl_administrator == userId) ? (
                                        LBLEDTTEAM
                                      ) : (LBLVWTEAM)} 
                                      alt=""
                                    ></img>
                                  </div>
                                </div>
                                <div className="cls_teamname_container">
                                  <div className="team-name" title={item.team_name}>
                                    {item.team_name.length > 20 ? item.team_name.substring(0, 22) + "..." : item.team_name}
                                  </div>
                                </div>
                              </div>
                              <div className="container" id={"div_" + index}>
                                <div className="card">
                                  <div className="content">
                                    <div className="sub_container20">
                                      <div className="sub_container99">
                                        {LBLPLANTASSOCIATE}
                                      </div>
                                      <div className="sub_container00">:</div>
                                      <div
                                        className="sub_container23"
                                        title={getPlantNames(item.groups)}
                                      >
                                        {getPlantNames(item.groups)}
                                      </div>
                                    </div>
                                    <div className="sub_container20">
                                      <div className="sub_container99">
                                        {LBLSTEERCOMSTARTDATE}
                                      </div>
                                      <div className="sub_container00">:</div>
                                      <div className="sub_container23">
                                        {this.formattingDate(
                                          item.start_date.substr(0, 10)
                                        )}
                                      </div>
                                    </div>
                                    <div className="sub_container20">
                                      <div className="sub_container99">
                                        {LBLTEAMADMINS}
                                      </div>
                                      <div className="sub_container00">:</div>
                                      <div
                                        className="sub_container23"
                                        title={item.adminname}
                                      >
                                        {item.adminname}
                                      </div>
                                    </div>
                                    <div className="sub_container20">
                                      <div className="sub_container99">
                                        {LBLTOTMEMBERS}
                                      </div>
                                      <div className="sub_container00">:</div>
                                      <div className="sub_container23">
                                        {item.memberscount}
                                        <img
                                          src={(item.created_by == userId || item.team_administrator == userId || item.team_addnl_administrator == userId) ? (
                                            "../images/steercom/team_edit1.png"
                                          ) : ("../images/steercom/eye21.png")}
                                          onClick={this.editTeamMembersList.bind(
                                            this,
                                            item.team_id
                                          )}
                                          title={(item.created_by == userId || item.team_administrator == userId || item.team_addnl_administrator == userId) ? (
                                           LBLEDTMEMBER
                                          ) : (LBLVWMEMBER)}
                                          className="cls_view_new"
                                          alt=""
                                        ></img>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                              </div>
                            </div>

                          </>
                        ) : (
                          <>

                          </>
                        )}
                      </>
                    );
                  })}
              </div> :
              <div
              style={{
                marginBottom: "0px",
                width: "calc(100% + 17px)",
              }}
              >
              <DataTable
                columns={teamSuccessFactorColumns}
                data={filtered_dbteamListsAll}
                className="datatable-list"
                noDataComponent={<div className="cls_norecords"> <EmptyState image={emptyImage} text2={this.state.shownorecords == true ? LBLPLANNORECORDS : LBLNORECSFOUND}/> </div>}
                style={{
                  border: "0px solid red",
                  backgroundColor: "#fff",
                }} 
              />
            </div>
            }

            {<div style={{ display: "none" }} className="cls_norecords" id="norecs"> <EmptyState image={emptyImage} text2={LBLNORECSFOUND}/> </div> }
            
            {this.state.shownorecords == true ?
              <div className="cls_norecords" id="norecords"> <EmptyState image={emptyImage} text2={LBLNORECSFOUND}/> </div>
              : null} 
          </div>
        )}
      </div>
   
    );
  }
}
export default withStyles(styled)(TeamList);

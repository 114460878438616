import React from "react";
import "./companylogo.css";
import ScCommonServices from "../../../services/common_services";
import Skeleton from "react-loading-skeleton";

var originalFetch = require("isomorphic-fetch");
var fetch = require("fetch-retry")(originalFetch);

class CompanyLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      imageName: this.props.imageName || "",
      maxHeight: this.props.maxHeight,
      loading: true
    };
  }
  componentWillReceiveProps(nextProps) {
    if (String(nextProps.imageName).indexOf("/images/") !== -1) {
      let profileImgPath = nextProps.imageName.substr(
        nextProps.imageName.indexOf("/images/")
      );

      if (String(window.location.href).split("/").length === 6) {
        profileImgPath = "../.." + profileImgPath;
      } else if (String(window.location.href).split("schome/reports").length === 2) {
        profileImgPath = "../.." + profileImgPath;
      } else if (String(window.location.href).split("/").length === 5) {
        profileImgPath = ".." + profileImgPath;
      } else {
        profileImgPath = "." + profileImgPath;
      }

      this.setState({
        imageUrl: profileImgPath,
        loading: false
      });
    } else if (this.props.company_aws_logo_path !== "") {
      this.setState({
        imageUrl: this.props.company_aws_logo_path,
        loading: false
      });
    } else if (this.props.imageName) {
      let assetFolder = String(nextProps.imageName).split(".");
      assetFolder.pop();
      let postData = {
        filename: nextProps.imageName,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      this.getLogoPath(postData);
    }
  }
  componentDidMount() {
    if (String(this.props.imageName).indexOf("/images/") !== -1) {
      let profileImgPath = this.props.imageName.substr(
        this.props.imageName.indexOf("/images/")
      );
      if (String(window.location.href).split("/").length === 6) {
        profileImgPath = "../.." + profileImgPath;
      } else if (String(window.location.href).split("schome/reports").length === 2) {
        profileImgPath = "../.." + profileImgPath;
      } else if (String(window.location.href).split("/").length === 5) {
        profileImgPath = ".." + profileImgPath;
      } else {
        profileImgPath = "." + profileImgPath;
      }
      this.setState({
        imageUrl: profileImgPath,
        loading: false
      });
    } else if (this.props.imageName) {
      let assetFolder = String(this.props.imageName).split(".");
      assetFolder.pop();
      let postData = {
        filename: this.props.imageName,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      this.getLogoPath(postData);
    }
  }
  getLogoPath(postData) {
    ScCommonServices.getAssyncSignedUrl(postData).then((resultData) => {
      let url = resultData.data;
      fetch(url, {
        retryOn: function (attempt, error, response) {
          if (!response || response.status >= 403) {
            return false;
          }
        },
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            imageUrl: resultData.data,
            loading: false
          });
        } else if (response.status === 404) {
          this.setState({
            imageUrl: null,
            loading: false
          });
        }
      });
    });
  }

  componentWillReceiveProps() {
    this.setState({
      firstLetterDisplay: this.props.firstLetterDisplay
        ? this.props.firstLetterDisplay
        : "",
    });
  }
  getStyle() {
    const sObj = {};
    if (this.props.maxHeight) {
      sObj.maxHeight = this.props.maxHeight;
    }
    if (this.props.imgWidth) {
      sObj.width = this.props.imgWidth;
    }
    if (this.props.imgHeight) {
      sObj.height = this.props.imgHeight;
    }
    return sObj;
  }
  render() {

    const { imageUrl, loading } = this.state;
    const appLogo = process.env.REACT_APP_LOGO;
    if (imageUrl ) {
      return (
        <img className="img-fluid safestartlogo" style={this.getStyle()}
          src= {imageUrl}
          alt=''
        />
      );
    } else if (String(window.location.href).split("schome/reports").length === 2) {
      return (
        <img
          className="img-fluid safestartlogo"
          style={this.getStyle()}
          src={"../images/" + appLogo}
          alt=''
        />
      );
    } else if (loading) {
      return (<Skeleton height={50} />)
    } else {
      return (
        <img
          className="img-fluid safestartlogo"
          style={this.getStyle()}
          src={"../images/" + appLogo}
          alt=''
        />
      );
    }
  }
}

export default CompanyLogo;

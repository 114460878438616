import React, { Component } from "react"; 
import { withRouter } from "react-router";
import $ from 'jquery';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts"; 
import pareto from 'highcharts/modules/pareto';
import emptyImage from '../../../assets/images/learnerprogress_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";

// Then call it
pareto(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
class NearMissReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      options_sts:{},
      reporttype:this.props.reporttype,
      reportLabels:props.reportLabels,
      hasData:false
    };
  }

  componentDidMount() {
    this.chartDetails();
    var chart = this.chart;
    $('body').on("onresize", function () { 
      chart.render();
    })
  }
  componentWillUnmount() {
    $('body').off("onresize")
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.chartDetails();
    }
  } 
  onChartClick = (e) => {
    if (this.props.onElementClick) {
      this.props.onChartClick(e,1)
    }
  } 
  getParetoChart=()=>{
    let options = {
      chart: { 
          type: 'column',
          chartName:"PARETO",
          style: {
            fontFamily: "'Roboto', sans-serif"
          },
      },
      title: {
          text: '.',
          style: {
            visibility: 'hidden'
          } 
      },
      tooltip: {
         // shared: true,
          headerFormat: '', 
          pointFormat: '{series.name} : <strong>{point.y}</strong>', 
      },
      plotOptions: { 
      series: {
        tooltip: { 
          headerFormat: '', 
          pointFormat: '<strong>{point.name} </strong>: <strong>{point.y}</strong>' 
        }, 
      },
       pareto: {
        tooltip: { 
          headerFormat: '', 
          pointFormat: '<strong>{series.name} : <strong>{point.y}</strong>' 
        } 
       }
      },
      xAxis: {
        title: {
          text: '',
          style: {
            fontWeight: 'bold',
            fontSize:15,
            marginTop:10   
          }
        },
        labels: {
          style: {
              fontWeight: 'bold'   
          }
         },
          categories: [ 
          ],
          crosshair: true
      },
      yAxis: [{
          title: {
              text: '',
              style: {
                fontWeight: 'bold',
                fontSize:15   
              }
          },
          // tickInterval: 2, 
      }, {
          title: {
              text: '', 
              rotation: -90,
              style: {
                fontWeight: 'bold',
                fontSize:15,
                marginLeft:20
              }
          },
          minPadding: 0,
          maxPadding: 0,
          max: 100,
          min: 0,
          opposite: true,
          labels: {
              format: '{value}%',
              fontWeight:500
          } 
      }],
      legend:{
        enabled:false
      },
      credits: { 
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [{
          type: 'pareto',
          name: '',
          yAxis: 1,
          zIndex: 10,
          baseSeries: 1,
          color:"#C0504E",
          tooltip: {
              valueDecimals: 2,
              valueSuffix: '%',
          }
      }, {
       //   name: 'Complaints',
          type: 'column',
          zIndex: 2,
          data: [755],
          color:"#55A5E4",
          events: { 
            click: this.onChartClick,
          }, 
      }]
  };
  return options;
  }
  sortingData=(array)=>{
    if(array){ 
      array.sort((a, b) => {if (a > b) {return -1;}});
    }
    return array;
  }
  chartDetails(Options) {
    let options_sts=[];
    let data = {}; 
    let categories = [];
    let dataPoints = [];
    let compOptions = this.props.compOptions;
    let type = 1, hasData=false, chartTypes = {1:"column",2:"pie",3:"pie"};
    let names = {1:"Months",2:"",3:"",4:"",5:"",6:"",7:"",8:"",9:"" }
    let {reporttype,chartName} = this.state;
    let {LBLMONTHS, LBLINCCOUNT,LBLRUSHING, LBLFRUSTRATION, LBLSTATES,LBLERRORS,LBLFATIGUE,LBLINCTYPES,
         LBLCUMULATIVEPERCENTAGE, LBLCOMPLACENCY,LBLSEVERITY,LBLEYENOTONTASK, LBLMINDNOTTASK, LBLLINEOFFIRE, LBLBALANCETRACGRIP } = this.props.labels;
    if (compOptions.data[0] && compOptions.data[0].dataPoints) {
      data = compOptions.data[0].dataPoints;
      options_sts = this.getOption(data); 
      data.sort(function (a, b) {
        return a.itemid  - b.itemid;
      });
      data.map(function (item) {
        categories.push(item.name);
      }); 
      options_sts.series[0].data =  data; 
      options_sts.series[0].name = names[type];
      categories = this.sortingData(categories);
      options_sts.xAxis.categories = categories;
      type = this.props.chartType; 
      options_sts.chart.type = compOptions.data[0].type;
      options_sts.chart.chartName = compOptions.data[0].chartName;
      options_sts.chart.chartSubName = compOptions.data[0].chartSubName;
      if(compOptions.data[0].type=="bar"){
        options_sts.plotOptions.series.pointWidth = 30;
        options_sts.yAxis.stackLabels.enabled = true;
      }else if(compOptions.data[0].type=="pie"){
        options_sts.xAxis.categories = this.sortingData(options_sts.xAxis.categories);
        options_sts.tooltip.pointFormat = '<strong>{point.name}</strong> : <strong>{point.y}%</strong>';
        options_sts.legend.enabled = true;  
        options_sts.plotOptions.pie.dataLabels.format= '{point.y: .0f}%';
      } else if(compOptions.data[0].type=="doughnut") {
        options_sts.chart.type = "pie";
        options_sts.plotOptions.pie = { 
          size: 250,
          innerSize:120, 
        };
        let title, arrTitle = compOptions.subtitles[0].text;
        arrTitle = arrTitle.split(" ");
        title = arrTitle[0] + "<br/>" + arrTitle[1];  
        options_sts.title.text = title;
        options_sts.tooltip.pointFormat = '<strong>{point.name}</strong> : <strong>{point.y}%</strong>';
        options_sts.legend.enabled = true; 
      } else if(compOptions.data[0].type=="splineArea") {
        options_sts.chart.type = "areaspline";
        options_sts.tooltip.pointFormat = '<strong>{point.name}</strong> : <strong>{point.y}%</strong>';
      }else if(compOptions.data[0].type=="stackedArea") {
        options_sts.chart.type = "area";
        //options_sts.series= [];
        let locArray=[];
        let monArray=[];
        let idx=0;
        if(compOptions.data.length>0){
          compOptions.data.map(item=>{
            locArray=[];
            //options_sts.series[0].data.push(item.dataPoints);
            if(item.dataPoints.length>0){
              item.dataPoints.map(itm=>{
                locArray.push({y:itm.y,x:itm.x,name:itm.name,states:item.name});
                if(idx==0){
                  monArray.push(itm.name);
                } 
              });
              options_sts.series[idx]={};
              options_sts.series[idx].data=[];
              options_sts.series[idx].data = (locArray); 
              idx++;
            }
          })
          options_sts.xAxis.categories = monArray;
        }
        options_sts.tooltip.pointFormat = '<strong>{point.name}</strong> : <strong>{point.y}</strong>';
      }

      if (compOptions.data[0].chartName=="MVC")
      {
        options_sts.xAxis.title.text = LBLMONTHS;
        options_sts.yAxis.title.text = LBLINCCOUNT; 
        options_sts.legend.enabled=false; 
        options_sts.tooltip.pointFormat = '<strong>{point.name}</strong> : <strong>{point.y}%</strong>';
        options_sts.yAxis.stackLabels.enabled=false; 
        let categories=[];
         options_sts.series[0].data.map(item=>{
          categories.push(item.name);
        });
        options_sts.xAxis.categories=categories;
        if(categories.length<5){
          options_sts.plotOptions.series.pointWidth = 80;
        }else{
          options_sts.plotOptions.series.pointWidth = null;
        }
      }else if(compOptions.data[0].chartName=="Severity"){ 
        options_sts.xAxis.title.text = LBLSEVERITY;
        options_sts.yAxis.title.text = LBLINCCOUNT; 
        options_sts.legend.enabled =false;
        options_sts.plotOptions.bar.dataLabels.enabled=true;
        let categories=[];
        compOptions.data[0].dataPoints.map(item=>{
          categories.push(item.name);
        });
        options_sts.xAxis.categories=categories;
      }else if(compOptions.data[0].chartName=="Incident Type"){ 
        options_sts.plotOptions.colors=[]; 
        options_sts.tooltip.pointFormat = '<strong>{point.name}</strong> : <strong>{point.y}</strong>';
      }else if(compOptions.data[0].chartName=="IVS"){  
        options_sts = this.getAreaOption();
        options_sts.chart.chartName="IVS";
        options_sts.series[0].color="#fdc0a3";
        options_sts.tooltip.pointFormat = '<strong>{point.name}</strong> : <strong>{point.y}</strong>';
        options_sts.xAxis.title.text = LBLSTATES;
        options_sts.yAxis.title.text = LBLINCCOUNT;
        options_sts.series[0].data = data;
        //options_sts.xAxis.categories=[LBLRUSHING,LBLFRUSTRATION,LBLFATIGUE,LBLCOMPLACENCY];
        let categories=[];
        compOptions.data[0].dataPoints.map(item=>{
          categories.push(item.name);
        });
        options_sts.xAxis.categories=categories;
      }else if(compOptions.data[0].chartName=="IVE"){  
        options_sts = this.getAreaOption();
        options_sts.chart.chartName="IVE";
        options_sts.series[0].color="#9de0e3";
        options_sts.xAxis.title.text = LBLERRORS;
        options_sts.yAxis.title.text = LBLINCCOUNT;
       // options_sts.xAxis.categories= [LBLEYENOTONTASK, LBLMINDNOTTASK, LBLLINEOFFIRE, LBLBALANCETRACGRIP] ;
        options_sts.series[0].data = data;
        let categories=[];
        compOptions.data[0].dataPoints.map(item=>{
          categories.push(item.name);
        });
        options_sts.xAxis.categories=categories;
      }else if(compOptions.data[0].chartName=="PARETO"){   
        options_sts = this.getParetoChart();
        options_sts.xAxis.title.text = LBLINCTYPES;
        options_sts.yAxis[0].title.text = LBLINCCOUNT;
        options_sts.yAxis[1].title.text = LBLCUMULATIVEPERCENTAGE; 
        options_sts.series[1].data = data;
        options_sts.series[0].name = LBLCUMULATIVEPERCENTAGE; 
        let categories=[]; 
        data.sort(function (a, b) {
          return b.y - a.y;
        });
        data.map((item)=>{
          categories.push(item.name);
        });
        options_sts.xAxis.categories = categories;
        if(categories.length<5){
          options_sts.plotOptions.series.pointWidth = 80;
        }else{
          options_sts.plotOptions.series.pointWidth = null;
        }
       // options_sts.plotOptions.pareto.categories = categories; 
      }else if(compOptions.data[0].chartName=="Serious"){ 
        options_sts.xAxis.title.text = LBLSEVERITY;
        options_sts.yAxis.title.text = LBLINCCOUNT;
        let categories  = options_sts.xAxis.categories;
        categories.splice(0,0,0);
        options_sts.xAxis.categories = categories;
        let legends=[];
        compOptions.data.map((item)=>{
          legends.push(item.name);
        });
        options_sts.legend.labelFormatter = function(idx) { 
          return legends[this.colorIndex];
        };
      }
    };

    if(compOptions.data[0].chartName=="PARETO" ){
      if(options_sts.series && options_sts.series[1].data.length>0 && !hasData){ 
           hasData=true;
       }
    }else if(options_sts.series && options_sts.series[0].data.length>0 && !hasData){
      hasData=true;
   }
     
    
    this.setState({ 
      options_sts:options_sts, 
      hasData:hasData
    });
  }
  getAreaOption=()=>{
   let chartName="IVS"
   let option = {
      chart: {
          type: 'area',
          chartName:chartName, 
          style: {
            fontFamily: "'Roboto', sans-serif"
          },
      },
      title: {
        text: "" 
      },
      credits: { 
        enabled: false
      },
      xAxis: {
        categories: [],
        labels: {
          style: {
              fontWeight: 'bold'   
          }
         },
        title: {
          text: "",
          style: {
            fontWeight: '500',
            fontSize:16
          },
        },
        plotLines: [{ 
          width: 2, 
        }],
    },
    yAxis: {
      tickInterval: 2, 
      labels: {
        style: {
            fontWeight: '500'   
        }
      },
      title: {
          text: "",
          style: {
            fontWeight: '500',
            fontSize:16 
          }
      },
      plotLines: [{
        value: -1, 
        width: 2, 
      }],
      stackLabels: { 
          enabled: true,
          style: {
              fontWeight: '500', 
          }
      }
    },
    exporting: {
      enabled: false
    },
    tooltip: { 
      headerFormat: '', 
      pointFormat: '{point.name} : <strong>{point.y}</strong>', 
    },
    plotOptions: {  
      area: { 
        dataLabels: {
            enabled: true, 
            style:{
            fontWeight:500
            }
        },
        tooltip: { 
          headerFormat: '', 
          pointFormat: '<strong>{point.name} </strong>: <strong>{point.y}</strong>', 
        },
    } 
    },
    legend: { 
      enabled:false, 
    },
      series: [{
          data:  [],
          color: '#FF0000',
          events: { 
            click: this.onChartClick,
          }, 
      }]
  }
  return option;
  }
  getOption = (data)=>{ 
    let pieColors = ["#A6D486", "#FFFF99", "#FF8F8F"]; //7DB1F5  F5C947 C8C6C6
    let objData =[];  
    let categories = [];//[LBLCOMPLETED,LBLINPROGRESS,LBLNOTSTARTED]; 
    let chart_data = {
    chart: {
        type: 'column',
        chartName:"MVC", 
        style: {
          fontFamily: "'Roboto', sans-serif"
        },
    },
    legend: { 
      enabled:true, 
      align: 'left',
      padding:12,  
      itemStyle: { 
        fontWeight: '450', 
        fontSize: '11px',
        border:'1px solid black',
        fontFamily: "'Roboto', sans-serif", 
    }
    },
    credits: { 
      enabled: false
    },
    exporting: {
      enabled: false
    },
    tooltip: { 
      headerFormat: '', 
      pointFormat: '<strong>{point.name}</strong> : <strong>{point.y}</strong>', 
      //backgroundColor: '#FCFFC5', 
    },
    title: { 
        text: null , 
        verticalAlign: 'middle',
        floating: true,
        y:-10,
        useHTML: true, 
        style:{
          textAlign:"center",
          fontWeight:700,
          lineHeight:1.5
        }
    },
    subtitle: {
        text: null ,
        align: 'left'
    },
    plotOptions: { 
      //colors: ["#000"],   
      column: {
          stacking: 'normal',
          cursor: 'pointer',
          dataLabels: {
            style:{
                fontWeight:500,
                fontSize:14
              }
          },
          tooltip: { 
            headerFormat: '', 
            pointFormat: '<strong>{point.name} </strong>: <strong>{point.y}</strong>', 
          },
      },
      bar: { 
        dataLabels: {
            enabled: false, 
            style:{
              fontWeight:500,
              fontSize:16
            }
        },
        tooltip: { 
          headerFormat: '', 
          pointFormat: '<strong>{point.name} </strong>: <strong>{point.y}</strong>', 
        },
      },
      series: {
        pointWidth: 55,
        events: { 
        //  click: this.onChartClick,
        },
        dataLabels: {
            enabled: true, 
        }
      },
      pie: { 
        size : 250,
        //allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
           // shadow : true,
            distance: -40,
            enabled: true,
           // format : '{point.y: .0f}%', 
        },
        showInLegend: true
      },
      area:{
        dataLabels: {
          enabled: false, 
          style:{
          fontWeight:500
          }
      },
      tooltip: { 
        headerFormat: '', 
        pointFormat: '<strong>{point.name} </strong>: <strong>{point.y}</strong>', 
        pointFormatter: function() {
          return '<b>' +  this.name + '<br/>' +
                  this.states + " : " + this.y + "</b>";
         }
      },
      },
      series: {  
        events: { 
          click: this.onChartClick,
        }, 
     } 
    },
    xAxis: {
        categories: [],
        labels: {
          style: {
              fontWeight: 'bold'   
          }
         },
        title: {
          text: "",
          style: {
            fontWeight: '450',
            fontSize:15
          },
        },
        plotLines: [{ 
          width: 2, 
        }],
    },
    yAxis: {
      tickInterval: 1, 
      labels: {
        style: {
            fontWeight: 'bold'   
        }
      },
      title: {
          text: "",
          style: {
            fontWeight: '450',
            fontSize:15 
          }
      },
      plotLines: [{
        value: -1, 
        width: 2, 
      }],
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: '450', 
          }
      }
    },
    series: [{ 
        showInLegend: true,
        colorByPoint: true,    
        selected: false,    
        data: objData,
        events: { 
          click: this.onChartClick,
        }, 
    }],  
    };
   return chart_data;
  }
   render() { 
    let { LBLNORECORDS } = this.props.labels;
    return ( 
      
      <> 
        {this.state.hasData?
       <HighchartsReact highcharts={Highcharts} 
        options={this.state.options_sts} 
        ref={this.chart}   
      />
       : <div style={{margin: "5%"}}><EmptyState image={emptyImage} text2={ LBLNORECORDS}/></div>  } 
      </> 
      
    );
  }
}
NearMissReportComponent = withRouter(NearMissReportComponent)
export default NearMissReportComponent;

import React, { Component } from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { connect } from "react-redux";
import ScCommonData from "../../../services/common_data";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import Radio from "@material-ui/core/Radio";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { blue } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import Loader from "../../../admin/components/loader/Loader";

const $ = require("jquery");
let uName = "",
  eMail = "",
  timezone;

class Support extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      userData: [],
      status: null,
      loading: true,
      user_roles: [],
      value: "1",
      selectedValue: "Technical Issue",
      helperText: "",
      error: false,
      helperTextDes: "",
      errorDes: false,
      reload: false,
      showToast: {
        show: false,
        alertClass: "",
        status: "warning",
        message: "",
        timeout: 10000,
        cha: new Date(),
      },
    };
    var body = document.createElement("div");
    var script = document.createElement("script");
    script.type = "text/javascript"; 
  }

  handleChange = (event) => {
    let val = event.target.value;
    let selval = event.target.value;
    this.setState({ value: val, selectedValue: selval });
  };
  componentDidMount() { 
    $(".modal-header").css({
      marginRight: "0rem",
      "margin-top": "0rem",
      "border-bottom": "0",
    });
    $(".close").css({ paddingRight: "0px", "margin-top": "-138px" });

    var body = document.createElement("div");
    var script = document.createElement("script");
    script.type = "text/javascript"; 
    setTimeout(() => {
      this.setState({ 
        loading: false 
      });
    }, 1500);
   
  }

  onChange(event) {
    if (event.target.value !== "") {
      this.setState({ helperText: "", error: false });
    } else {
      this.setState({
        helperText: this.props.labels.labels.LBLREQUIRED,
        error: true,
      });
    }
  }
  onChangeDes(event) {
    if (event.target.value !== "") {
      this.setState({ helperTextDes: "", errorDes: false });
    } else {
      this.setState({
        helperTextDes: this.props.labels.labels.LBLREQUIRED,
        errorDes: true,
      });
    }
  }
  getTimezoneName = (tzone) => {
    var summerOffset = tzone * 60;
    var winterOffset = tzone * 60;
    var region;

    if (-720 === summerOffset && -720 === winterOffset) {
      region = "Dateline";
    } else if (-660 === summerOffset && -660 === winterOffset) {
      region = "UTC-11";
    } else if (-660 === summerOffset && -660 === winterOffset) {
      region = "Samoa";
    } else if (-660 === summerOffset && -600 === winterOffset) {
      region = "Hawaiian";
    } else if (-570 === summerOffset && -570 === winterOffset) {
      region.value = "Pacific/Marquesas";
    } else if (-540 === summerOffset && -600 === winterOffset) {
      region.value = "America/Adak";
    } else if (-540 === summerOffset && -540 === winterOffset) {
      region.value = "Pacific/Gambier";
    } else if (-480 === summerOffset && -540 === winterOffset) {
      region = "Alaskan";
    } else if (-480 === summerOffset && -480 === winterOffset) {
      region = "Pacific/Pitcairn";
    } else if (-420 === summerOffset && -480 === winterOffset) {
      region = "Pacific";
    } else if (-420 === summerOffset && -420 === winterOffset) {
      region = "US Mountain";
    } else if (-360 === summerOffset && -420 === winterOffset) {
      region = "Mountain";
    } else if (-360 === summerOffset && -360 === winterOffset) {
      region = "Central America";
    } else if (-360 === summerOffset && -300 === winterOffset) {
      region = "Pacific/Easter";
    } else if (-300 === summerOffset && -360 === winterOffset) {
      region = "Central";
    } else if (-300 === summerOffset && -300 === winterOffset) {
      region = "SA Pacific";
    } else if (-240 === summerOffset && -300 === winterOffset) {
      region = "Eastern";
    } else if (-270 === summerOffset && -270 === winterOffset) {
      region = "Venezuela";
    } else if (-240 === summerOffset && -240 === winterOffset) {
      region = "SA Western";
    } else if (-240 === summerOffset && -180 === winterOffset) {
      region = "Central Brazilian";
    } else if (-180 === summerOffset && -240 === winterOffset) {
      region = "Atlantic";
    } else if (-180 === summerOffset && -180 === winterOffset) {
      region = "Montevideo";
    } else if (-180 === summerOffset && -120 === winterOffset) {
      region = "E. South America";
    } else if (-150 === summerOffset && -210 === winterOffset) {
      region = "Mid-Atlantic";
    } else if (-120 === summerOffset && -180 === winterOffset) {
      region = "America/Godthab";
    } else if (-120 === summerOffset && -120 === winterOffset) {
      region = "SA Eastern";
    } else if (-60 === summerOffset && -60 === winterOffset) {
      region = "Cape Verde";
    } else if (0 === summerOffset && -60 === winterOffset) {
      region = "Azores Daylight";
    } else if (0 === summerOffset && 0 === winterOffset) {
      region = "Morocco";
    } else if (60 === summerOffset && 0 === winterOffset) {
      region = "GMT";
    } else if (60 === summerOffset && 60 === winterOffset) {
      region = "Africa/Algiers";
    } else if (60 === summerOffset && 120 === winterOffset) {
      region = "Namibia";
    } else if (120 === summerOffset && 60 === winterOffset) {
      region = "Central European";
    } else if (120 === summerOffset && 120 === winterOffset) {
      region = "South Africa";
    } else if (180 === summerOffset && 120 === winterOffset) {
      region = "GTB";
    } else if (180 === summerOffset && 180 === winterOffset) {
      region = "E. Africa";
    } else if (240 === summerOffset && 180 === winterOffset) {
      region = "Russian";
    } else if (240 === summerOffset && 240 === winterOffset) {
      region = "Arabian";
    } else if (270 === summerOffset && 210 === winterOffset) {
      region = "Iran";
    } else if (270 === summerOffset && 270 === winterOffset) {
      region = "Afghanistan";
    } else if (300 === summerOffset && 240 === winterOffset) {
      region = "Pakistan";
    } else if (300 === summerOffset && 300 === winterOffset) {
      region = "West Asia";
    } else if (330 === summerOffset && 330 === winterOffset) {
      region = "India";
    } else if (345 === summerOffset && 345 === winterOffset) {
      region = "Nepal";
    } else if (360 === summerOffset && 300 === winterOffset) {
      region = "N. Central Asia";
    } else if (360 === summerOffset && 360 === winterOffset) {
      region = "Central Asia";
    } else if (390 === summerOffset && 390 === winterOffset) {
      region = "Myanmar";
    } else if (420 === summerOffset && 360 === winterOffset) {
      region = "North Asia";
    } else if (420 === summerOffset && 420 === winterOffset) {
      region = "SE Asia";
    } else if (480 === summerOffset && 420 === winterOffset) {
      region = "North Asia East";
    } else if (480 === summerOffset && 480 === winterOffset) {
      region = "China";
    } else if (540 === summerOffset && 480 === winterOffset) {
      region = "Yakutsk";
    } else if (540 === summerOffset && 540 === winterOffset) {
      region = "Tokyo";
    } else if (570 === summerOffset && 570 === winterOffset) {
      region = "Cen. Australia";
    } else if (570 === summerOffset && 630 === winterOffset) {
      region = "Australia/Adelaide";
    } else if (600 === summerOffset && 540 === winterOffset) {
      region = "Asia/Yakutsk";
    } else if (600 === summerOffset && 600 === winterOffset) {
      region = "E. Australia";
    } else if (600 === summerOffset && 660 === winterOffset) {
      region = "AUS Eastern";
    } else if (630 === summerOffset && 660 === winterOffset) {
      region = "Australia/Lord_Howe";
    } else if (660 === summerOffset && 600 === winterOffset) {
      region = "Tasmania";
    } else if (660 === summerOffset && 660 === winterOffset) {
      region = "West Pacific";
    } else if (690 === summerOffset && 690 === winterOffset) {
      region = "Central Pacific";
    } else if (720 === summerOffset && 660 === winterOffset) {
      region = "Magadan";
    } else if (720 === summerOffset && 720 === winterOffset) {
      region = "Fiji";
    } else if (720 === summerOffset && 780 === winterOffset) {
      region = "New Zealand";
    } else if (765 === summerOffset && 825 === winterOffset) {
      region = "Pacific/Chatham";
    } else if (780 === summerOffset && 780 === winterOffset) {
      region = "Tonga";
    } else if (840 === summerOffset && 840 === winterOffset) {
      region = "Pacific/Kiritimati";
    } else {
      region = "US/Pacific";
    }
    return region;
  };
  sendsupportdetails = (values) => {
    let close = this.state.reload;
    if (close) {
      this.setState({ reload: false });
      $("#modal .close").click();
    } else {
    }

    let xObj = {};
    const TimeZones = ScCommonData.getTimeZones();
    xObj =
      TimeZones[
        Object.keys(TimeZones).find((d, key) => TimeZones[d].value === timezone)
      ];
    let dText = xObj.name;

    values.preventDefault();
    let usrName, iSubject, iDesc, iType, iEmail, urlName, tzone;
    iSubject = $("#txtSubject").val();
    iType = this.state.selectedValue; //$("#cateType option:selected").text();
    iDesc = $("#txtDescription").val();

    if (iSubject === "") {
      this.setState({
        helperText: this.props.labels.labels.LBLREQUIRED,
        error: true,
      });
      return false;
    } else if (iDesc === "") {
      this.setState({
        helperTextDes: this.props.labels.labels.LBLREQUIRED,
        errorDes: true,
      });
      return false;
    } else {
      this.setState({
        helperText: this.props.labels.labels.LBLREQUIRED,
        error: false,
        helperTextDes: this.props.labels.labels.LBLREQUIRED,
        errorDes: false,
        loading: true,
        reload: true,
      });
    }
    urlName = window.location.href.split(window.location.pathname)[0];
    usrName = uName; //this.props.company_name
    iEmail = eMail;
    tzone = this.getTimezoneName(timezone);
    let data = {
      iSubject: iSubject,
      iType: iType,
      iDesc: iDesc,
      usrName: usrName,
      iEmail: iEmail,
      site: urlName,
      region: tzone,
    };
    HttpInterceptor.post("supportchanges", data).then((response) => {
      this.setState({
        status: response.data.status,
      });
      if (response.data.status.token !== 0) {
        $("#username").val("");
        $("#cateType option:selected").text();
        $("#comment").val("");
        this.setState({
          status: response.data.status,
          show: false,
          loading: false,
          showToast: {
            show: true,
            alertClass: "success",
            status: "warning",
            message: this.props.labels.labels.LBLTOKENSUCCESS,

            cha: new Date(),
          },
        });
      } else {
        this.setState({
          status: response.data.status.token,
          show: false,
          showToast: {
            show: true,
            alertClass: "success",
            status: "Success",
            timeout: 10000,
            message: "Support Token not Created.",
            cha: new Date(),
          },
        });
      }
    });
  };
  render() {

    const selectedValue = this.state.selectedValue;
    return (
      <form className="support" onSubmit={this.sendsupportdetails} noValidate autoComplete="off">
        <div style={{ width: "100%", marginTop: "50px" }}>
          {this.state.showToast.show ? (
            <ToastMessage
              showTime={this.state.showToast.showTime}
              show={this.state.showToast["show"]}
              helpers={this.state.showToast}
            ></ToastMessage>
          ) : (
            ""
          )}
          <section>
            {this.state.loading ? (
              <Loader />
            ) : (
              <div className="row justify-content-center">
                <div
                  id="supportContent"
                  className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 "
                >
                  <div className="profile-section1">
                    <div class="row">
                      <div style={{ width: "100%" }}>
                        <Radio
                          checked={selectedValue === "Technical Issue"}
                          onChange={this.handleChange}
                          value="Technical Issue"
                          className="clsRadioText"
                          style={{ color: green[600] }}
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "A" }}
                        />
                        <span className="clsRadioText">
                          {this.props.labels.labels.LBLTECHISSUE}
                        </span>
                        <Radio
                          checked={selectedValue === "Clarification"}
                          onChange={this.handleChange}
                          value="Clarification"
                          style={{ color: red[600] }}
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "B" }}
                        />{" "}
                        <span className="clsRadioText">
                          {this.props.labels.labels.LBLCLARIFICATION}
                        </span>
                        <Radio
                          checked={selectedValue === "Complaint"}
                          onChange={this.handleChange}
                          value="Complaint"
                          style={{ color: blue[600] }}
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "C" }}
                        />{" "}
                        <span className="clsRadioText">
                          {this.props.labels.labels.LBLCOMPLAINT}
                        </span>
                      </div>
                    </div>
                    <br></br>
                    <TextField
                      id="txtSubject"
                      label={this.props.labels.labels.LBLSUBJECT}
                      style={{ width: "386px", marginTop: "-10px" }}
                      type="search"
                      variant="outlined"
                      helperText={this.state.helperText}
                      onChange={this.onChange.bind(this)}
                      error={this.state.error}
                      required
                    />
                    <br></br>
                    <br></br>
                    <br></br>
                    <TextField
                      id="txtDescription"
                      label={this.props.labels.labels.LBLDESCRIPTION}
                      placeholder="Description"
                      multiline
                      rows={4}
                      variant="outlined"
                      style={{ width: "386px", marginTop: "-10px" }}
                      helperText={this.state.helperTextDes}
                      onChange={this.onChangeDes.bind(this)}
                      error={this.state.errorDes}
                      required
                    />
                    <br></br> <br></br>
                    <div
                      className="modal-footer1"
                      style={{ float: "left", marginTop: "20px" }}
                    >
                      <input
                        type="submit"
                        className="btn btn-primary"
                        id="submitUser"
                        value={this.props.labels.labels.LBLSEND}
                      ></input>
                    </div>
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  uName = state.user_info_reducer.user_info.name;
  eMail = state.user_info_reducer.user_info.email;
  timezone = state.user_info_reducer.user_info.timezone;
  return {
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
  };
};

export default connect(mapStateToProps, null)(Support);

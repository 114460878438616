import React, { Component } from 'react';
import CardContent from './RiskCardContent';
import './RiskCard.scss';
import checkboxes from './cardJson';

class RiskCard extends Component {
    state = {
        cardJson: [],
        enableSubmit: false,
        submitClicked: false,
        labels: [],
        currentContent: 1
    }

    constructor(props) {
        super(props);
        this.cardRef = React.createRef();
        this.state.cardJson = checkboxes//props.data;
    }
    enableSubmit = (enable) => {
        this.setState({ enableSubmit: enable });
    }
    handleSubmit = () => {
        console.log("submit clicked");
        console.log(this.cardRef.current.state.checkedItems);
        const submitClicked = true;
        this.setState({ submitClicked, enableSubmit: false });
    }
    moveContent = (incrementVal) => {
        let currentContent = this.state.currentContent + incrementVal;
        this.setState({ currentContent });
    }
    render() {
        const { header_text, questions, cards } = this.state.cardJson;
        const { submitClicked, currentContent } = this.state;
        const { LBLCONTINUE, LBLSUBMIT, LBLBACK } = this.props.labels;

        return (
            <div className="container riskcard-board slide-brd">
                <div className="row">
                    {(header_text || questions) ?
                        <div className="col-12">
                            <div className="container">
                                {header_text ? <div className="board-hdr">{this.props.labels[header_text]}</div> : ""}
                                {questions ? <ul className="questions-left">
                                    {questions.map((element, i) => {
                                        return (<li key={i}>{element.text}</li>)
                                    })}
                                </ul> : ""}
                            </div>
                        </div> : ""
                    }
                    <div className="col-12 riskcard-view">
                        <CardContent ref={this.cardRef} currentContent={currentContent} card_id={this.props.card_id} data={cards} showAnswer={submitClicked} optionChanged={this.enableSubmit} isAnswer={this.props.card_id === "card01"} labels={this.props.labels} />
                    </div>
                </div>
                <div className="container text-center btn-ctn">
                    <button className={currentContent === 1 ? "hide-content" : "btn btn-primary btn-sm mr-2"} onClick={() => this.moveContent(-1)}>{LBLBACK}</button>
                    <button className={currentContent === 2 ? "hide-content" : "btn btn-primary btn-sm mr-2"} onClick={() => this.moveContent(1)}>{LBLCONTINUE}</button>
                    <button className={currentContent === 1 ? "hide-content" : !this.state.enableSubmit ? "btn btn-primary btn-sm disabled" : "btn btn-primary btn-sm"} onClick={this.handleSubmit}>{LBLSUBMIT}</button>
                </div>
            </div >
        );
    }
}

export default RiskCard;
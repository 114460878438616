import React from "react";
import Skeleton from "react-loading-skeleton";
import steercomdashboard from "./steercomdashboard.scss";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import { TextField, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from '@material-ui/core/Paper';
import { connect } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DataTable from "react-data-table-component";
import CanvasJSReact from "../../../assets/canvasjs/canvasjs.react";
import { isUndefined } from "lodash";
import AdminDashboard from "./steercomadmindashboard";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { enUS,itIT,esES,deDE, ptPT, ptBR, frFR,svSE, etEE, faIR, fiFI ,ruRU} from '@material-ui/core/locale';
import emptyImage from '../../../assets/images/toolboxtalks_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
 
let locale_code = {"en-US":enUS,"it-IT":enUS,"es-MX":esES,"de-DE":deDE,
                   "pt-BR":ptBR,"fr-FR":frFR,"sv-SE":svSE,"ru-RU":ruRU};
let theme = createTheme( enUS); 
let currId = "";
const $ = require("jquery");
let currstatus = "", parentask = "";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class SteerComUserDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      dataPoints: [],
      loading: true,
      viewTabsValue: 0,
      filterDate: this.getweekdays(),
      weekTabsValue: 0,
      successFactorData: {},
      successFactorsList: [],
      allStatus: [],
      firstLoad: true,
      dateformat: props.dateformat,
      loadingListView: true,
      teamMembersList: [],
      teamSuccessFactorList: [],
      teamSuccessFactorListPrev: [],
      teamData: {},
      teamRolesList: [],
      editingTeamSF: {},
      steercomSettings: {},
      isTeamAdminUser: -1,
      currstatus: "",
      mobView:false,
      mPad:false
    };
    this.childref = React.createRef();
    theme = createTheme( locale_code[this.props.language_code]);
  }

  componentDidMount() {
    $('#root').scrollTop(0);
    $("#back_to_list").hide();
    this.setdateformat();
    this.loadFunctions();
    let mobView = this.isMobileView();//navigator.userAgentData.mobile;
    let mPad = (mobView && (window.screen.availWidth > 750 || document.body.clientWidth > 750))?true:false;
    this.setState({ 
      mobView:mobView,
      mPad:mPad
    }); 
  }
  isMobileView=()=>{
    let mView=false;
    /// iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone|Opera Mini/i.test(navigator.userAgent)
    if(/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone|Opera Mini/i.test(navigator.userAgent)
    || window.screen.availWidth < 750 || document.body.clientWidth < 750){
      mView=true;
    }  
    return mView;
  }
  loadFunctions = () => {
    this.getSteercomSettings();
    this.getTeamList();
  }
  isAdminCheck = () => {
    let apiUrl = "steercomteammembertype";
    this.isAdminCheckCallback();
  }
  isAdminCheckCallback = async() => {
    let res =  await SteercomServices.getMemberTypeDash(); 
    let isTeamAdmin = 0;
    let roleid = this.props.role_id;
     
    //alert(res.data.result[0][0].TeamAdmin_TeamCount) 
    if (res.result[0][0].TeamAdmin_TeamCount > 0 || roleid == 1) {
      isTeamAdmin = 1;
    }
    // isTeamAdmin = 0;
    this.setState({
      isTeamAdminUser: isTeamAdmin
    });
  }

  getTeamList = () => {
    let apiUrl = "steercomteams";
    this.getTeamListCallback();
  };
  getTeamListCallback = async () => {
    let res =  await SteercomServices.getTeamListMyTask(); 
    let dbteamLists=[], teamList = res.result;
    if(teamList.length>0){
      teamList.map((item,idx)=>{
       // status[item.team_id] = item.team_status==0?false:true;
        if(item.team_status!=0){
          dbteamLists.push(item);
        }
      })
    }
    let filterTeamList = dbteamLists.filter((item) => {
      if (this.props.role_id > 1) {
        if (item.team_administrator == this.props.userId) {
          return item;
        } else if (item.memberofteam > 0) {
          return item;
        }
      } else {
        return item;
      }
    });
      //filterTeamList=[];
      let loc_loading=true, teamidloc=1;
      if(filterTeamList.length==0)
        loc_loading=false;
      this.setState(
      {
        teamList: filterTeamList,
        // teamData:filterTeamList[0],
        loading: loc_loading, 
      },
      () => {

        if (this.props.location.state && this.props.location.state.teamData) {
          //alert(11 + "--" + this.props.location.state.teamData.team_id)
          if(this.props.location.state.teamData){
            this.getTeamMembersList(this.props.location.state.teamData.team_id)
          }
        } else { 
          if(filterTeamList[0]){
          this.getTeamMembersList(filterTeamList[0].team_id)
          }
        }
      }

    );
  };
  getTeamSFList = (team_id, sfid) => {
    if (
      this.state.teamData &&
      this.state.teamData.team_id &&
      this.state.successFactorData &&
      this.state.successFactorData.id
    ) {
      let roleid = this.props.role_id;
      roleid = 3;
      this.getTeamSFListCallback(team_id,sfid,roleid);
    }
  };
  getTeamSFListCallback = async (team_id,sfid,roleid) => {
    let mainTasksList=[], res =  await SteercomServices.getMyTasks(team_id,sfid,roleid); 
    let filterTeamSFList,
      base_date,
      teamSFList = res.result;
    if (teamSFList.length > 0) {
      base_date = teamSFList[0].base_date;
      for (let i = 0; i < teamSFList.length; i++) {
        if (this.state.firstLoad) {
          teamSFList[i].expandFlag = 0;
        } else if (parentask == teamSFList[i].parent_task_display_id) {
          teamSFList[i].expandFlag = 1;
        } else {
          teamSFList[i].expandFlag = 0;
        }
        teamSFList[i].expandFlag = 1;
        if (
          teamSFList[i].task_type == 1 &&
          base_date &&
          !teamSFList[i].planned_start_date
        ) {
          teamSFList[i].planned_start_date = new Date(base_date);
          teamSFList[i].planned_start_date.setDate(
            teamSFList[i].planned_start_date.getDate() +
            teamSFList[i].recommended_start_days
          );
        }
        if(teamSFList[i].task_type==0){
          mainTasksList.push(teamSFList[i]);
        }  
      }

      filterTeamSFList = teamSFList.filter((item) => {
        if (item.task_type == 0) {
          let childitems = teamSFList.filter((citem) => {
            if (citem.parent_task_display_id == item.task_display_id) {
              if (
                typeof citem.team_member_id == "number" ||
                typeof citem.team_member_id == "string"
              ) {
                if (this.props.role_id > 1) {
                  if (
                    this.state.teamData.team_administrator == this.props.userId
                  ) {
                    return citem;
                  } else if (citem.member_user_id == this.props.userId) {
                    return citem;
                  }
                } else {
                  return citem;
                }
              }
            }
          });
          if (childitems.length > 0) {
            return item;
          }
        } else {
          if (
            typeof item.team_member_id == "number" ||
            typeof item.team_member_id == "string"
          ) {
            return item;
          }
        }
      });
    } else {
      filterTeamSFList = [];
    }
    filterTeamSFList = this.sortByKeyAsc(filterTeamSFList, 'parent_task_display_id');
    let prev_type = "", locTeamSFList = [];
    // locTeamSFList = filterTeamSFList;
    filterTeamSFList.map((item, i) => {
      //locTeamSFList.push(item);
      if (item.task_type == 0) {
        let tid = filterTeamSFList[i].team_id;
        if (filterTeamSFList[i + 1] && filterTeamSFList[i + 1].task_type == 1 && filterTeamSFList[i + 1].del_flag!="1") {
          //  item.teams_id=filterTeamSFList[i+1].team_id;
          if (tid == filterTeamSFList[i].team_id) {
            locTeamSFList.push(item);
          }
        }
      } else if (item.task_type == 1 && item.del_flag!="1") {
        locTeamSFList.push(item);
      }
    });

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    var groupedByTeam = groupBy(locTeamSFList, 'team_id'); 

    let prevParent = "", allSFs = groupedByTeam[null];

    let groupedList = [], prev_parent = "";
    let allSFTeams=[];
    if (allSFs != undefined) {
      Object.keys(groupedByTeam).map((items, idx) => {
        prevParent = "";
        items != null && groupedByTeam[items].map((item, ind) => {
          if (prevParent != item.parent_task_display_id) {
            var teamData = allSFs.find(function (items) {
              return items.parent_task_display_id == item.parent_task_display_id &&
                items.template_id == item.template_id && items.version == item.version
            });
            if (ind == 0){
              groupedList.push({ "task_type": "2", "team_id": item.team_id, "team_name": item.team_name, 
              "success_factor_id": item.success_factor_id, "team_id": groupedByTeam[items].item.team_id?groupedByTeam[items].item.team_id:""
             });
            }
            allSFTeams[item.success_factor_id] = item.success_factor_id;
            groupedList.push(teamData);
            prevParent = item.parent_task_display_id;
          }
          groupedList.push(item);
        })
      })
    } else {
      Object.keys(groupedByTeam).map((items, idx) => {
        let groupedByTeamLoc=[]; 
        groupedByTeam[items].map((item, ind) => {
          if (prev_parent != item.parent_task_display_id) //&& items.parent_task_display_id == item.parent_task_display_id 
          {
            if (ind == 0) {
              groupedList.push({ "task_type": "2", "team_id": item.team_id, "team_name": item.team_name, "success_factor_id": item.success_factor_id, "groups": idx+1 });
            }
            item["groups"]= idx+1 ;
            groupedList.push(item);
            prev_parent = items.parent_task_display_id;
          } else {
            groupedList.push(item);
          }
        });
      });
    }
    filterTeamSFList = groupedList;//groupedByTeam;//locTeamSFList; 
    this.setState({
      teamSuccessFactorList: filterTeamSFList,
      teamSuccessFactorListPrev: filterTeamSFList,
      base_date: base_date,
      loading: false,
      firstLoad: false,
      mainTasksList:mainTasksList,
    }, this.isAdminCheck());
  };
  sortByKeyAsc = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  getTeamMembersList = (team_id) => {
    let apiUrl = "steercomteammembers/" + team_id ;
    this.getTeamMembersListCallback(team_id);
  };
  getFactorName = (row) => {
    let factorName = ""; 
    let SFTITLE = row.success_factor_id.replace("Y1","LBL") + "TITLE";
    factorName = this.getLabel(SFTITLE);
    return factorName;
  }
  getTeamMembersListCallback = async(team_id) => {
    let res =  await SteercomServices.getTeamMembersListMyTask(team_id); 
    console.log("getTeamMembersListCallback", res.data);

    let teamData = res.result[0][0];
    let teamMembersList = res.result[1];
    let teamRolesList = [];
    let rolesObj = {};
    let addedRoles = {};
    for (let j = 0; j < teamMembersList.length; j++) {
      if (rolesObj.hasOwnProperty(teamMembersList[j].steercom_role_id)) {
        if (
          addedRoles.hasOwnProperty(teamMembersList[j].steercom_role_id) ==
          false
        ) {
          addedRoles[teamMembersList[j].steercom_role_id] = 1;
          teamRolesList.push(rolesObj[teamMembersList[j].steercom_role_id]);
        }
      }
    }
    console.log("teamRolesList", teamRolesList);
    this.setState(
      {
        teamData: teamData,
        teamMembersList: teamMembersList,
        teamRolesList: teamRolesList,
      },
      () => {
        setTimeout(() => {
          this.getTeamSFList(0, 0)
        }, 1000)
      }
    );
  };
  getColors = (status) => {
    let colors = { "YETTOSTART": "#f5f4f5", "INPROGONTRACK": "#e2f3ff", "INPROGDELAYED": "#DBF2F3", "COMPLETED": "#DBF2F3" };
    let selcolor = colors[status];//colors[Math.floor(Math.random() * colors.length)];
    return selcolor;
  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    for (let x in steercomSettings) {
      steercomSettings[x].map((item, index) => {
        if (item.name && item.name.indexOf("LBL") == 0) {
          item.name = this.getLabel(item.name);
        }
        if (item.title && item.title.indexOf("LBL") == 0) {
          item.title = this.getLabel(item.title);
        }
        if (item.description && item.description.indexOf("LBL") == 0) {
          item.description = this.getLabel(item.description);
        }
      });
    }
    steercomSettings.successfactors.unshift({
      active: 1,
      color: "sf_color1",
      description: "",
      id: "0",
      name: "LBLALL",
      title: this.props.labels.labels.LBLALL, 
      version: "1"
    });
    if (this.props.location.state && this.props.location.state.successFactorData) {
      //alert(this.props.location.state.successFactorData.id)
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: this.props.location.state.successFactorData,
      });
    } else {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: steercomSettings.successfactors[0],
      });
    }
  };
  getLabel = (xfield, mode) => {
    if (typeof xfield === "string" ) {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (
        typeof xfield === "object" &&
        this.props.labels.labels.hasOwnProperty(xfield.label)
      ) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield ;
      }
    }

  };
  getStatusDetails = (item, row) => {
    alert(item.CurrentStatus)
  }
  updateEditFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    let editingTeamSF = this.state.editingTeamSF;
    for (var i = 0; i < teamSuccessFactorList.length; i++) {
      if (item.task_display_id == teamSuccessFactorList[i].task_display_id) {
        teamSuccessFactorList[i].isNewAssigned = status ? 0 : 1;
        if ((teamSuccessFactorList[i].isNewAssigned || 0) == 1) {
          editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id] = {
            team_id: teamSuccessFactorList[i].team_id,
            success_factor_id: teamSuccessFactorList[i].success_factor_id,
            task_display_id: teamSuccessFactorList[i].task_display_id,
            actual_start_date: teamSuccessFactorList[i].actual_start_date,
            actual_end_date: teamSuccessFactorList[i].actual_end_date,
            task_status: teamSuccessFactorList[i].task_status,
            completion_percentage:
              teamSuccessFactorList[i].completion_percentage,
            last_activity_description:
              teamSuccessFactorList[i].last_activity_description,
            oldMemberId: teamSuccessFactorList[i].team_member_id,
          };
        } else {
          delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
        }
      } else if (
        status == 0 &&
        (teamSuccessFactorList[i].isNewAssigned || 0) == 1
      ) {
        teamSuccessFactorList[i].isNewAssigned = 0;
        delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
      }
    }
    // console.log("this.state.productGroups[i].isNewAssigned", this.state.productGroups[i].isNewAssigned)
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
      editingTeamSF: editingTeamSF,
    }));
  };
  updateExpandFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    for (let i = 0; i < teamSuccessFactorList.length; i++) {
      if (
        item.task_display_id == teamSuccessFactorList[i].parent_task_display_id
      ) {
        teamSuccessFactorList[i].expandFlag = status ? 0 : 1;
      }
    }
    // console.log("this.state.productGroups[i].isNewAssigned", this.state.productGroups[i].isNewAssigned)
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
    }));
  };
  handleSFChange = (event, values) => {
    let { teamSuccessFactorListPrev, teamSuccessFactorList } = this.state;
    if (values.id == "0") {
      this.getTeamList();
    } else if (values.id == "Y1SF1") {
      //this.getTeamList();
    } else if (values.id == "Y1SF2") {
      //this.getTeamList();
    } else if (values.id == "Y1SF3") {
      //this.getTeamList();
    } else if (values.id == "Y1SF4") {
      //this.getTeamList();
    } else if (values.id == "Y1SF5") {
      //this.getTeamList();
    } else {
      teamSuccessFactorList = [];
    }
    this.setState({
      successFactorData: values,
      teamSuccessFactorList: teamSuccessFactorList
    })
  }
  handleTabChange = (event, newValue) => {
    // alert(newValue)
    this.setState({
      viewTabsValue: newValue,
      loadingListView: newValue
    });
  };
  handleWeekTabChange = (event, newValue) => {
    let filterDate = this.getweekdays(newValue);

    this.setState({
      weekTabsValue: newValue,
      filterDate: filterDate
    });
  };
  showDescription = (desc, name, tid) => {
    this.setState({
      showDescModal: true,
      descriptionData: desc,
      nameData: name,
      taskid: tid,
    });
  };
  closeDescription = () => {
    this.setState({
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
    });
  };
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
    return dateformat;
  };
  formattingDate = (date) => {
    let dateformat,
      fulldate = "";
    dateformat = this.state.dateformat;
    if (dateformat.toString().indexOf("YYYY") > 0)
      dateformat = this.setdateformat();
     let dateArray = date.split("/"); 
    if (date) {
      var d=new Date(),month,day,year;
      d = new Date(date);  
      day = "" + d.getDate();
      if(d==undefined || isNaN(d.getDate()))
      {  
        d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
      } 
       // d = new Date(date.split("/")[1] + "/" + date.split("/")[0] + "/" + date.split("/")[2]);
        month = "" + (d.getMonth() + 1);
        day = "" + d.getDate();
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      if (dateformat == "dd/MM/yyyy") {
        fulldate = [day, month, year].join("/");
      } else if (dateformat == "MM/dd/yyyy") {
        fulldate = [month, day, year].join("/");
      } else if (dateformat == "yyyy/MM/dd") {
        fulldate = [year, month, day].join("/");
      } else {
        fulldate = [day, month, year].join("/");
      }
    }
    return fulldate;
  };
  openRefModal = (row) => {
    console.log(" row.references", row.references)
    this.setState({
      showmodal: true,
      resourceData: JSON.parse(row.references || '{"folder_id":998}'),
      resourceDataCustom: row.custom_references || ""
    });
  };
  redirecttoplan = (item, event) => { 
    let dirpath = "/schome/"
    if (window.location.href.indexOf("/home/") > -1) {
      dirpath = "/home/"
    }
    let backlinkurl = dirpath + "steercomdashboard";
    var teamData = this.state.teamList.find(function (items) {
      return items.team_id == item.team_id;
    });
    let successFactorData = this.state.successFactorData;
    if (successFactorData.id == 0) {
      successFactorData = {
        active: 1,
        color: "sf_color1",
        description: "LBLSF1SUBTITLE",
        id: item.success_factor_id,//"Y1SF1",
        name: "LBL" + item.success_factor_id.split("Y1")[1],// "LBLSF1",
        title: this.props.labels.labels["LBL" + item.success_factor_id.split("Y1")[1] + "TITLE"] // "Success Factors 1"LBLSF1TITLE
      }
    }
    this.props.history.push({
      pathname: dirpath + "successfactorplan",
      state: {
        backlink: true,
        backlinkurl: backlinkurl,
        successFactorData: successFactorData,
        teamData: teamData,
        taskItem: item,
        parentId: item.parent_task_display_id
      }
    });

  }
  getweekdays = (mode) => {
    let currDays, currentDate = new Date();
    if (isUndefined(mode)) {
      mode = 1;
    }
    if (mode == 0) {
      currentDate = new Date(new Date().setMonth(-360));
    } else if (mode == 1) {
      currDays = 7 - (new Date().getDay());
      currentDate = new Date(new Date().setDate(currDays));
    } else if (mode == 2) {
      currDays = 7 - (new Date().getDay());
      currentDate = new Date(new Date().setDate(currDays + 7));
    } else {
      currentDate = new Date(new Date().setMonth(360));
    }
    // alert(currentDate)
    return currentDate;
  }
  handleAllSF = (event) => {

    if (this.childref.current) {
      this.childref.current.backLoadTableData();
    } else {
      this.loadFunctions();
    }
    currstatus = "";
    this.setState({
      currstatus: currstatus
    })
  }
  getParentName = (row) => {
    let parentName = "", parentTaskId = row.parent_task_display_id;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        parentName = i.custom_task_name?i.custom_task_name:this.getLabel( "LBL" + parentTaskId );
      }
    }); 
    return parentName;
  }
  render() {
    let { LBLEARLIER, LBLCURRWEEK, LBLNXTWEEK, LBLLATRMONTH, LBLSTEERCOMMYTASK, LBLSF, LBLCURRENTSTATUS,
      LBLYETTOSTART, LBLINPROGONTRACK, LBLINPROGONDELAY, LBLCOMPLETED, LBLSTARTDATE, LBLVIEW, LBLEXPIRED,
      LBLENDDATE, LBLLISTVIEW, LBLCARDVIEW, LBLSF1TITLE, LBLSF2TITLE, LBLSF3TITLE, LBLSF4TITLE, LBLSF5TITLE,
      LBLTASK, LBLREQUIRED, LBLPLANNORECORDS, LBLLEGENDS, LBLTEAMNAME,LBLEDIT,
      LBLPLANNEDENDDATE, LBLACTION, LBLTEAM, LBLALLTASKS,LBLPARENTTASK,
      LBLPCOMPLETED, LBLPLANNEDSTARTDATE
    } = this.props.labels.labels;
    const { successFactorData, successFactorsList, allStatus, filterDate, weekTabsValue,mobView,mPad } = this.state;
    const currentStatus = { YETTOSTART: LBLYETTOSTART, INPROGONTRACK: LBLINPROGONTRACK, INPROGONDELAY: LBLINPROGONDELAY, COMPLETED: LBLCOMPLETED }
    const statusTypes = [LBLEARLIER, LBLCURRWEEK, LBLNXTWEEK, LBLLATRMONTH];
    const SFTYPES = { Y1SF1: LBLSF1TITLE, Y1SF2: LBLSF2TITLE, Y1SF3: LBLSF3TITLE, Y1SF4: LBLSF4TITLE, Y1SF5: LBLSF5TITLE }
    const { teamData, teamSuccessFactorList, userlist, editingTeamSF, teamMembersList, teamRolesList } = this.state;

    let healthactivity = {
      YETTOSTART: "notstarted",
      INPROGONTRACK: "ontrack",
      INPROGONDELAY: "delayed",
      COMPLETED: "completed",
    };

    let getDateFormat = (xdata, item, mode) => {
      let enddate;
      if (xdata) {
        if (item && mode != 1) {
          enddate = xdata;
          enddate = new Date(enddate).toLocaleDateString("en-US");
         // enddate = new Date(enddate).toLocaleString();
          enddate = enddate.split(",")[0]; //enddate.substr(0, 10);
        } else {
          enddate = xdata.split(",")[0]; //xdata.substr(0, 10);
        }
        //if (navigator.userAgent.search("Firefox") < 0 || !item)
          enddate = this.formattingDate(enddate);
        return enddate; //.substr(0, 10)
      } else {
        return false;
      }
    };
    let getRoleName = (xdata) => {
      if (xdata) {
        return this.getLabel("LBL" + xdata);
      } else {
        return "";
      }
    };
    let getStatusClass = (status) => {
      if (typeof status == "string") {
        return healthactivity[status];
      } else {
        return healthactivity["YETTOSTART"];
      }
    };
    let filterSuccessFactorsListData = teamSuccessFactorList.filter(
      (item) => {
        if (
          (item.task_type == 1 && item.expandFlag == 1) || item.task_type == 2 //|| item.task_type == 0 
        ) {
          return item;
        }
        // }
      }
    );
    //if(item.task_type==0 || item.task_type==2 && new Date(filterSuccessFactorsListData[i+1].planned_start_date)<new Date(lastday) && (filterSuccessFactorsListData[i+1].planned_end_date ==null || new Date(filterSuccessFactorsListData[i+1].planned_end_date) > new Date())){

    let currentDate, firstday, lastday;
    let dateFilterSuccessFactorsList;

    dateFilterSuccessFactorsList = filterSuccessFactorsListData.filter((item, i) => {
      if (weekTabsValue == 0) {
        return item;
      } else if (weekTabsValue == 1) {
        currentDate = new Date();
        firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()))
        if (item.task_type == 0 || item.task_type == 2) {
          return item;
        } else if (new Date(item.planned_start_date) < new Date(firstday)) { //&& (item.task_status!="COMPLETED")){
          return item;
        }
      } else if (weekTabsValue == 2) {
        currentDate = new Date();
        firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()))
        lastday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7))

        if (item.task_type == 0 || item.task_type == 2) {
          return item;
        } else if (new Date(item.planned_start_date) > firstday && new Date(item.planned_start_date) <= lastday) { // && (item.task_status!="COMPLETED")){
          return item;
        }
      } else if (weekTabsValue == 3) {
        currentDate = new Date();
        firstday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7))
        lastday = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 3650))
        if (item.task_type == 0 || item.task_type == 2) {
          return item;
        } else if (new Date(item.planned_start_date) > firstday && new Date(item.planned_start_date) <= lastday) { // && (item.task_status!="COMPLETED")){
          return item;
        }
      }
    });
    let sfids,finaldata = [];
    if(successFactorData.id ){
      sfids = successFactorData.id.split("Y1SF")[1];
    }
   
    dateFilterSuccessFactorsList.map((item, i) => { 
      if((successFactorData.id==item.success_factor_id) || successFactorData.id=="0" || item.task_type == 2) {
        if (item.task_type == 2) {
          let val = dateFilterSuccessFactorsList.filter((items,idx)=>{
            if(successFactorData.id==0){
              return items.team_id == item.team_id && items.task_type!=2 
            }else{
              return items.success_factor_id==successFactorData.id && 
                     items.team_id == item.team_id && items.task_type!=2
            }
            })
            let taskitems = val;
              taskitems=val.find((content,i)=>{
                return content.task_type==1
              })
          if (taskitems && taskitems.task_type ) {
             finaldata.push(item);
            val.map((itm,ix)=>{ 
              if(val[ix+1] && val[ix+1].task_type==1 && itm.task_type==0)
                finaldata.push(itm);
              if(itm.task_type==1)  
               finaldata.push(itm);
            })
          }
        }  
      }
    })
    filterSuccessFactorsListData = finaldata;//dateFilterSuccessFactorsList; 

    let teamSuccessFactorListData = filterSuccessFactorsListData;


    let idx = 1;
    let teamSuccessFactorColumns = [
      {
        name: "",
        selector: "",
        width: "5%",
        cell: (row) => (
          <>
            <div
              style={{
                display: "inline-block",
                paddingLeft: "0px",
                position: "absolute",
                backgroundColor: "white",
                left: "0px",
                width: "72px",
                height: "52px",
              }}
            ></div>
            {row.main_type ? <div>{row.main_type}</div> :
              row.task_type == 2 ? "" :
                row.task_type == 0 ? (
                  ""
                ) : (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        paddingLeft: "0px",
                        position: "absolute",
                        border: "0px solid red",
                        top: "0px",
                        left: "8px",
                      }}
                    >
                      <div> { }</div>
                    </div>
                  </>
                )}
          </>
        ),
      },
      {
        name: LBLTASK,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "22%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.task_type == 2) {
            idx++;
          }
          if (row.main_type) {
            index = idx++;
          }
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == this.state.teamData.created_by ||
              this.props.userId ==
              this.state.teamData.team_administrator) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              
              {row.main_type ? <div className={"MAINTASK_" + (index)}>{row.main_type}</div> :
                row.task_type == 2 ? (
                  <div className="cls_team_name" >
                    <div className="cls_team_name_span">
                      {LBLTEAMNAME} -  {row.team_name}
                    </div>
                  </div>
                ) :
                  row.task_type == 1 ? (
                    <>
                      <div
                        style={{
                          width: "15px",
                          height: "34px",
                          border: "0px solid",
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            width: "4px",
                            height: "34px",

                            backgroundColor: "#80E3BA",
                            border: "0px solid",
                            display: "block",
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          width: "calc(100% - 14px)",
                          border: "0px solid",
                          display: "flex",
                        }}
                      >

                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              border: "0px solid red",
                              width: "calc(100% - 12px)",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                              style={{
                                width: "90%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                textOverflow: "ellipsis",
                                fontWeight: "410"
                              }}
                            >
                             {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                            </div>
                          </div>

                        </>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={"MAINTASK_" + (index)}
                        style={{
                          border: "0px solid",
                          height: "100%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "8px",
                              left: "2px",
                              border: "0px solid blue",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                fontSize: "15px",
                                fontWeight: "430",
                              }}
                            >
                              {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                            </div>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              width: "28px",
                            }}
                          >

                          </div>
                        </>
                      </div>
                    </>
                  )}
            </div>
          )
        },
      },
      {
        name: LBLSF,
        selector: "member_enddate",
        width: "16%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ color: "#4486a6", fontWeight: "500" }}>
                        {(this.props.labels.labels["LBL" + row.success_factor_id.split("Y1")[1] + "TITLE"])}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },

      {
        name: LBLCURRENTSTATUS,
        selector: "task_status",
        //   sortable: true,
        width: "16%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div className="cls_status_container">
              <div className="cls_img_container" style={{ display: row.task_type == 1 ? "" : "none" }}>
                <img style={{ display: row.task_type == 1 ? "" : "none" }}
                  src={
                    "../images/steercom/" +
                    getStatusClass(row.task_status) +
                    ".png"
                  }
                  className="cls_img_team_status"
                  alt=""
                ></img>
              </div>

              <div
                className={
                  row.task_type == 0 || row.main_type
                    ? "sfcellbox MAINTASK" :
                    row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : ("sfcellbox " + getStatusClass(row.task_status))
                }
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                  row.task_type == 2 ? "" :
                    row.task_type == 1 ? (
                      <>
                        {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                          <>
                          <ThemeProvider theme={theme}>
                            <Autocomplete
                              id={"ac_task_status" + row.task_display_id}
                              disableClearable
                              options={this.state.steercomSettings.taskstatuses}
                              getOptionLabel={(option) => option.name.toString()}
                              getOptionSelected={(option, values) =>
                                option.id === values.id
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "task_status",
                                row,
                                "id"
                              )}
                              underlineStyle={{ display: "none" }}
                              defaultValue={this.state.steercomSettings.taskstatuses.find(
                                (v) =>
                                  v.id ==
                                  editingTeamSF["M_" + row.task_display_id].task_status
                              )}
                              renderInput={(params) => (
                                <TextField
                                  style={{ textAlign: "center", width: "100%" }}
                                  id={"task_status" + row.task_display_id}
                                  {...params}
                                />
                              )}
                              renderOption={(option) => (
                                <div
                                  className={
                                    option.id == null
                                      ? healthactivity["YETTOSTART"]
                                      : healthactivity[option.id]
                                  }
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    width: "150%",
                                    padding: "12px 12px 12px 6px",
                                    marginLeft: "-10px",
                                    marginBottom: "-9px",
                                    marginRight: "-10px",
                                    backgroundColor:
                                      healthactivity[option.id] == "notstarted"
                                        ? "#c8c6c6"
                                        : healthactivity[option.id] == "ontrack"
                                          ? "#7db1f5"
                                          : healthactivity[option.id] == "delayed"
                                            ? "#f5c947"
                                            : healthactivity[option.id] == "completed"
                                              ? "#79da8d"
                                              : "#c8c6c6",
                                  }}
                                >
                                  {" "}
                                  {option.name}{" "}
                                </div>
                              )}
                            />
                            </ThemeProvider>
                            <span
                              className="required"
                              id={"span_task_status_" + row.task_display_id}
                            >
                              {LBLREQUIRED}
                            </span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={this.redirecttoplan.bind(this, row)}
                            >
                              {getRoleName(row.task_status || "YETTOSTART")}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
              </div>
            </div>
          )
        },
      },

      {
        name: LBLPCOMPLETED,
        selector: "member_enddate",
        //      sortable: true,
        width: "8%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{
                width: "100%",
                border: "0px solid",
                height: "100%",
                display: "flex",
                justifyContent: mobView?"": "center",
                alignItems: "center",
              }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <span className="cls_span_sm_text" type="text">
                            {(row.completion_percentage * row.weightage) / 100}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          <span className="cls_span_sm_text" type="text">
                            {row.completion_percentage > 0 ?
                              ((row.completion_percentage * row.weightage) / 100) + "%" :
                              (row.completion_percentage * row.weightage) / 100
                            }

                          </span>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },

      {
        name: LBLPLANNEDSTARTDATE,
        selector: "member_enddate",
        width: "13%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "95%" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        {getDateFormat(row.planned_start_date, row)}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },
      {
        name: LBLPLANNEDENDDATE,
        selector: "member_enddate",
        //    sortable: true,
        width: "13%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type != 1) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "95%", textAlign: "center" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        {getDateFormat(row.planned_end_date, row)}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },

      {
        name: LBLACTION,
        selector: "status",
        //     sortable: true,
        width: "40px",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.task_type) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", "marginLeft": "0px" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    {" "}
                    <>
                      <label className="show-sm">{LBLACTION}</label>

                      <div
                        className="sub_container3 cls_team_cross"
                        style={{ display: "inline-block", display: "" , "marginLeft": "20px"}}
                        onClick={this.redirecttoplan.bind(this, row)}
                      >
                        <i title={LBLEDIT} class="fas fa-edit cls_edit"></i>
                      </div>
                    </>
                    <></>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },
    ];
    let teamSuccessFactorColumnsAll = [
      {
        name: "",
        selector: "",
        width: "5%",
        cell: (row) => (
          mobView?"":
          <>
            <div
              style={{
                display: "inline-block",
                paddingLeft: "0px",
                position: "absolute",
                backgroundColor: "white",
                left: "0px",
                width: "72px",
                height: "52px",
              }}
            ></div>
            {row.main_type ? <div>{row.main_type}</div> :
              row.task_type == 2 ? "" :
                row.task_type == 0 ? (
                  ""
                ) : (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        paddingLeft: "0px",
                        position: "absolute",
                        border: "0px solid red",
                        top: "0px",
                        left: "8px",
                      }}
                    >
                      <div> { }</div>
                    </div>
                  </>
                )}
          </>
        ),
      }, 
      {
        name: LBLSF,//LBLPARENTTASK,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "25%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.task_type == 2) {
            idx++;
          }
          if (row.main_type) {
            index = idx++;
          }
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == this.state.teamData.created_by ||
              this.props.userId ==
              this.state.teamData.team_administrator) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false;
            let task_name = "", parent_task_name;
           task_name =  this.getFactorName(row)//this.getParentName(row);//this.getLabel("LBL" + row.parent_task_display_id);
          if (row.is_default == 0) {
            task_name = this.getFactorName(row)//this.getParentName(row);
          }
           
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              
              {row.main_type ? <div className={"MAINTASK_" + (index)}>{row.main_type}</div> :
                row.task_type == 2 ? (
                  <div className="cls_team_name">
                  <div className="cls_team_name_span" title={row.team_name}>
                    {LBLTEAMNAME} -  {row.team_name}
                  </div>
                </div>
                ) :
                  row.task_type == 1 ? (
                    <>
                      <div
                        style={{
                          width: "15px",
                          height: "34px",
                          border: "0px solid",
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            width: "4px",
                            height: "34px",

                            backgroundColor: "#80E3BA",
                            border: "0px solid",
                            display: "block",
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          width: "calc(100% - 14px)",
                          border: "0px solid",
                          display: "flex",
                        }}
                      >

                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              border: "0px solid red",
                              width: "calc(100% - 12px)",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={task_name}
                              style={{
                                width: "90%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                textOverflow: "ellipsis !important",
                                fontWeight: mobView?"600":"410",
                                color:mobView?"black":"inherit",
                              }}
                            >
                             {task_name}
                            </div>
                          </div>

                        </>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={"MAINTASK_" + (index)}
                        style={{
                          // width: "100%",
                          border: "0px solid",
                          height: "100%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "8px",
                              left: "2px",
                              border: "0px solid blue",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                              style={{ 
                                fontSize: "15px",
                                fontWeight: "430" 
                              }}
                              className="cls_ellipsis"
                            >
                              {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) } 
                            </div>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              width: "28px",
                            }}
                          >

                          </div>
                        </>
                      </div>
                    </>
                  )}
            </div>
          )
        },
      }, 
      {
        name: LBLTASK,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "20%",
        compact: true,
        id:"TaskName",
        cell: (row) => {
          let index = 0;
          if (row.task_type == 2) {
            idx++;
          }
          if (row.main_type) {
            index = idx++;
          }
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == this.state.teamData.created_by ||
              this.props.userId ==
              this.state.teamData.team_administrator) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
             row.task_type == 2 && mobView ?"": 
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              
              {row.main_type ? <div className={"MAINTASK_" + (index)}>{row.main_type}</div> :
                row.task_type == 2 ? (
                  ""
                ) :
                  row.task_type == 1 ? (
                    <>
                      <div
                        style={{
                          width: "15px",
                          height: "34px",
                          border: "0px solid",
                          display: "block",
                        }}
                      >
                        
                      </div>

                      <div
                        style={{
                          width: "calc(100% - 14px)",
                          border: "0px solid",
                          display: "flex",
                        }}
                      >

                        <>
                          <div
                           className="cls_dash_sub_task_container"  
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                              className="cls_dash_sub_task"
                            >
                            {/* {row.task_display_id}  */}
                             {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                            </div>
                          </div>

                        </>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={"MAINTASK_" + (index)}
                        style={{
                          // width: "100%",
                          border: "0px solid",
                          height: "100%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "8px",
                              left: "2px",
                              border: "0px solid blue",
                              // width: "calc(100% - 28px)",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                fontSize: "15px",
                                fontWeight: "430",
                              }}
                            >
                              {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) } 
                            </div>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              width: "28px",
                            }}
                          >

                          </div>
                        </>
                      </div>
                    </>
                  )}
            </div>
          )
        },
      },
       
      {
        name: LBLCURRENTSTATUS,
        selector: "task_status",
        //   sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            row.task_type == 2 && mobView  && !mPad ?"": 
            <div className="cls_status_container">
              <div className="cls_img_container" style={{ display: row.task_type == 1 ? "" : "none" }}>
                <img style={{ display: row.task_type == 1 ? "" : "none" }}
                  src={
                    "../images/steercom/" +
                    getStatusClass(row.task_status) +
                    ".png"
                  }
                  className="cls_img_team_status"
                  alt=""
                ></img>
              </div>

              <div
                className={
                  row.task_type == 0 || row.main_type
                    ? "sfcellbox MAINTASK" :
                    row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : ("sfcellbox " + getStatusClass(row.task_status))
                }
                style={{ minWidth: mobView?"45%":"145px", width: mobView?"45%":"90%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                  row.task_type == 2 ? "" :
                    row.task_type == 1 ? (
                      <>
                        {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                          <>
                          <ThemeProvider theme={theme}>
                            <Autocomplete
                              id={"ac_task_status" + row.task_display_id}
                              disableClearable
                              options={this.state.steercomSettings.taskstatuses}
                              getOptionLabel={(option) => option.name.toString()}
                              getOptionSelected={(option, values) =>
                                option.id === values.id
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "task_status",
                                row,
                                "id"
                              )}
                              underlineStyle={{ display: "none" }}
                              defaultValue={this.state.steercomSettings.taskstatuses.find(
                                (v) =>
                                  v.id ==
                                  editingTeamSF["M_" + row.task_display_id].task_status
                              )}
                              renderInput={(params) => (
                                <TextField
                                  style={{ textAlign: "center", width: "100%" }}
                                  id={"task_status" + row.task_display_id}
                                  {...params}
                                />
                              )}
                              renderOption={(option) => (
                                <div
                                  className={
                                    option.id == null
                                      ? healthactivity["YETTOSTART"]
                                      : healthactivity[option.id]
                                  }
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    width: "150%",
                                    padding: "12px 12px 12px 6px",
                                    marginLeft: "-10px",
                                    marginBottom: "-9px",
                                    marginRight: "-10px",
                                    backgroundColor:
                                      healthactivity[option.id] == "notstarted"
                                        ? "#c8c6c6"
                                        : healthactivity[option.id] == "ontrack"
                                          ? "#7db1f5"
                                          : healthactivity[option.id] == "delayed"
                                            ? "#f5c947"
                                            : healthactivity[option.id] == "completed"
                                              ? "#79da8d"
                                              : "#c8c6c6",
                                  }}
                                >
                                  {" "}
                                  {option.name}{" "}
                                </div>
                              )}
                            />
                            </ThemeProvider>
                            <span
                              className="required"
                              id={"span_task_status_" + row.task_display_id}
                            >
                              {LBLREQUIRED}
                            </span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",
                                cursor: "pointer",
                                whiteSpace:"nowrap",
                                overflow:"hidden",
                                textOverflow:"ellipsis"
                              }}
                              onClick={this.redirecttoplan.bind(this, row)}
                              title={getRoleName(row.task_status || "YETTOSTART")}
                            >
                              {getRoleName(row.task_status || "YETTOSTART")}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
              </div>
            </div>
          )
        },
      },  
      {
        name: LBLPLANNEDSTARTDATE,
        selector: "member_enddate",
        width: "11%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            row.task_type == 2 && mobView && !mPad ?"": 
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox "}
              style={{ width: "95%", textAlign:  mobView?"":"center"  }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ width: "100%", textAlign: mobView?"":"center" }}>
                        {getDateFormat(row.planned_start_date, row)}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },
      {
        name: LBLPLANNEDENDDATE,
        selector: "member_enddate",
        width: "11%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type != 1) {
            index = idx - 1;
          }
          return (
            row.task_type == 2 && mobView  && !mPad ?"": 
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "95%", textAlign:  mobView?"":"center" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ width: "100%", textAlign:  mobView?"":"center" }}>
                        {getDateFormat(row.planned_end_date, row)}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      }, 
      {
        name: LBLACTION,
        selector: "status",
        //     sortable: true,
        width: "40px",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.task_type) {
            index = idx - 1;
          }
          return (
            row.task_type == 2 && mobView  && !mPad ?"": 
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", "marginLeft": "0px" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    {" "}
                    {/* {this.props.userId == this.state.teamData.created_by ||
                      this.props.userId == this.state.teamData.team_administrator ? ( */}
                    <>
                      <label className="show-sm">{LBLACTION}</label>

                      <div
                        className="sub_container3 cls_team_cross"
                        style={{ display: "inline-block", display: "" , "marginLeft":mobView?"5px": "20px" }}
                        onClick={this.redirecttoplan.bind(this, row)}
                      >
                        <i title={LBLEDIT} class="fas fa-edit cls_edit"></i>

                        {/* <img src="../images/steercom/wrong.png" onClick={this.deleteSuccessFactor.bind(this, row)} title="Cancel Team Member" className="img_team_cross" alt="steer"></img> */}
                      </div>
                    </>
                    {/* ) : ( */}
                    <></>
                    {/* )} */}
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },
    ];
    teamSuccessFactorColumns  = teamSuccessFactorColumnsAll;
    return (
      <div className="mob-pad cls_dashboard">
        <div className="page-header-field">

          <h3 className="page-title" id="header_top">
            <span id="header_det_mytask"> {LBLSTEERCOMMYTASK}</span>
          </h3>
          {/* <h4>
            <span className="cls_teams">{LBLSTEERCOMMYTASK}</span>
          </h4> */}
          {/* <div className="cls_sf_links">
          <div className="cls_sf_link1">{LBLUPDATEPLAN}</div>
          <div className="cls_sf_link2">{LBLSTEERCOMSFSETUP}</div>
          </div> */}
        </div>
        <hr class="head-line"></hr>
        <div className="cls_user_section">

          <div className="row cls_tabs_container">
            <div className="col-xl-12">
              <Paper square className={"cls_tabs_root"}>
                <Tabs
                  value={this.state.weekTabsValue}
                  onChange={this.handleWeekTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                  textColor="secondary"
                  aria-label=" label tabs example"
                  selectedIndex={1}
                  defaultIndex={1}
                >

                  <Tab style={{width:"50px", color: " " }} label={LBLALLTASKS} />
                  <Tab style={{ color: " " }} label={LBLEARLIER} />
                  <Tab style={{ color: " " }} label={LBLCURRWEEK} />
                  <Tab style={{ color: " " }} label={LBLLATRMONTH} />
                  {/* <Tab icon={<PersonPinIcon />} label="NEARBY" />   */}
                </Tabs>
              </Paper>
            </div>
          </div>
          <br></br>
          {successFactorsList.length > 0 ? (
            <div className="cls_fltr_section">
              <div className="cls_sf_combo">
                <label className="label" >{LBLSF} : </label>
                {/* <ThemeProvider theme={theme}> */}
                <Autocomplete
                  style={{ fontSize: "12px", width: "250px", marginTop: "5px" }}
                  label={LBLSF}
                  placeholder={LBLSF}
                  disableClearable
                  options={successFactorsList}
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, values) =>
                    option.id === values.id
                  }
                  onChange={this.handleSFChange.bind(this)}
                  underlineStyle={{ display: "none" }}
                  defaultValue={successFactorData}
                  renderOption={(option) => (
                    <div  style={{fontSize:"14px"}}>{option.title}</div>
                  )} 
                  renderInput={(params) => (
                    <TextField style={{ fontSize: "12px" }} id="sflist" {...params} variant="standard" />
                  )}

                  underline="none"
                />
                {/* </ThemeProvider> */}
              </div>

              <div className="cls_view" style={{ display: "none" }}>

                <div class="cls_view_label">{LBLVIEW} : </div>
                <Paper square className={"cls_tabs_view_root"}>
                  <Tabs
                    value={this.state.viewTabsValue}
                    onChange={this.handleTabChange}
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label=" label tabs example"
                  >
                    <Tab label={LBLCARDVIEW} />
                    <Tab label={LBLLISTVIEW} />
                  </Tabs>
                </Paper>
              </div>

            </div>) : ""}


          <div className="cls_dsbrd_container">
            <br></br>
            {this.state.loading ? (
              <Skeleton height={300} />
            ) : !this.state.loadingListView ? (
              this.state.teamSuccessFactorList.length > 0 ? (
                <div className="row" style={{ display: "flex" }}>
                  {/* {teamSuccessFactorList && teamSuccessFactorList.map((item, idx) => { */}
                  {teamSuccessFactorListData && teamSuccessFactorListData.map((item, idx) => {

                    return (
                      item.task_type == 1 ?
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 cls_dashboard_main" id="cls_dashboard_main">
                          <div className={item.success_factor_id} style={{}}>{this.props.labels.labels["LBL" + item.success_factor_id.split("Y1")[1] + "TITLE"]}</div>
                          <div className="cls_sf_container" style={{ backgroundColor: this.getColors(item.task_status ? item.task_status : "YETTOSTART") }}
                            onClick={this.redirecttoplan.bind(this, item)} >
                            <div className="cls_sf_container1">
                              <div title={item.team_name} className="cls_sf_container12">
                                {/* <div className="cls_sf_container13">{LBLTEAMNAME}</div>    */}
                                <div className="cls_sf_container14">{item.team_name} </div>
                              </div>
                              <div title={this.props.labels.labels[item.task_name] && this.props.labels.labels[item.task_name]} className="cls_sf_container11">
                                {this.props.labels.labels[item.task_name] && this.props.labels.labels[item.task_name].length > 120 ? this.props.labels.labels[item.task_name].substring(0, 120) + "..." : this.props.labels.labels[item.task_name]}
                              </div>
                            </div>
                            <div className="cls_sf_container2">
                              <div className="cls_sf_container210" >
                                <div className="cls_sf_container211">
                                  {LBLCURRENTSTATUS}
                                </div>
                                <div title={currentStatus[item.task_status]} className={"cls_sf_container220 " + (item.task_status ? item.task_status : " YETTOSTART")}>
                                  {item.task_status ? currentStatus[item.task_status] : currentStatus["YETTOSTART"]}
                                </div>
                              </div>
                              <div className="cls_sf_container21">
                                <div className="cls_sf_container211">
                                  {LBLSTARTDATE}
                                </div>
                                <div className="cls_sf_container212">
                                  {/* {item.planned_start_date?item.planned_start_date:""} */}
                                  {item.planned_start_date ? this.formattingDate(item.planned_start_date) : ""}

                                </div>
                              </div>
                              <div className="cls_sf_container21">
                                <div className="cls_sf_container211">
                                  {LBLENDDATE}
                                </div>
                                <div className="cls_sf_container212">
                                  {item.planned_end_date ? this.formattingDate(item.planned_end_date) : ""}
                                </div>
                              </div>
                              <div className="cls_sf_container23">
                              </div>
                            </div>
                          </div>
                        </div> : "")

                  })}
                </div>
              ) : (
                <div style={{ margin: "10% 35%" }}>{LBLPLANNORECORDS}</div>
              )
            ) : (
              <div>
                {!mobView?
                <div
                  id="legends"
                  class="row"
                  style={{ float: "left", display: teamSuccessFactorListData && teamSuccessFactorListData.length > 0 ? "" : "none" }} //marginBottom:"-18px"
                >
                  <div className="cls_legends_name">{LBLLEGENDS}</div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/notstarted.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLYETTOSTART}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/ontrack.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLINPROGONTRACK}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/delayed.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLINPROGONDELAY}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/completed.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLCOMPLETED}</div>
                  </div>
                  {/* <div className=" cls_legends" style={{ width: "120px" }}>
                    <img style={{ transform: "scale(0.5)" }}
                      src={"../images/steercom/Expired.png"}
                      className="img_team_status"
                      alt="steer"
                    ></img>
                    <div class="cls_img_status">{LBLEXPIRED}</div>
                  </div> */}
                </div>
                :""}
               
                <div id="datatable_dash"
                  style={{
                    marginBottom: "0px",
                    width: "calc(100% + 85px)",
                    marginLeft: "-58px",
                  }}
                >
                  {teamSuccessFactorListData && teamSuccessFactorListData.length > 0 ? (
                    <DataTable
                      columns={teamSuccessFactorColumns}
                      data={teamSuccessFactorListData}
                      className="datatable-list"
                      noDataComponent={ <EmptyState image={emptyImage} text2={LBLPLANNORECORDS}/>}
                      style={{
                        //minHeight:($("#root").height() - 276) + "px" ,
                        border: "0px solid red",
                        backgroundColor: "#fff",
                      }}
                    //conditionalRowStyles={conditionalRowStyles}
                    // fixedHeader
                    // fixedHeaderScrollHeight={
                    //   $("#root").height() - 180 + "px" //257
                    // }
                    //expandableRows
                    //expandableRowDisabled={row => row.expanderDisabled}
                    //expandableRowsComponent={<ExpandableComponent />}
                    />
                  ) : (
                    <div className="cls_norecords">{ <EmptyState image={emptyImage} text2= {LBLPLANNORECORDS}/>}</div>
                  )}

                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};
export default connect(mapStateToProps, null)(SteerComUserDashboard);

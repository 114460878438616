import React from "react";
import { connect } from "react-redux"; 
import { TextField, Select } from "@material-ui/core"; 
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import Alert from "@material-ui/lab/Alert"; 
import Snackbar from "@material-ui/core/Snackbar"; 
import Autocomplete from "@material-ui/lab/Autocomplete"; 
const $ = require("jquery");
class AddTask extends React.Component {
    constructor(props) {
        super(props);
        const { callcomponents, editTeamDetails } = props; 
        this.state = {
            taskName: "",
            taskDetails: props.taskDetails,
            labels: props.labels,
            currSF:"",
            SFMainTasks:[],
            selectedSF: props.taskDetails.success_factors[0],
            selectedValue: "2",
            selectedCategory:props.taskDetails.task_category[0],
            selectedType: props.taskDetails.task_type[0], 
            selectedRole: "",
            selectedMain: "",
            selectedWeight:0,
            selectedDeadline:0,
            cntWeight:0
        }
    }
    componentDidMount() {
        this.setValues();
    }
    setValues = () => {
        let taskDetails = this.state.taskDetails;
        let mainTasks, categories, taskTypes, defaultRoles, successFactors,template_data;
        mainTasks=[];
        mainTasks = taskDetails.main_tasks;
        categories = [taskDetails.task_category[0]];
        taskTypes = taskDetails.task_type;
        defaultRoles = taskDetails.steercom_roles;
        successFactors = taskDetails.success_factors;
        template_data =  taskDetails.template_data;
        mainTasks.unshift({
            active: 1,
            color: "sf_color1",
            description: "",
            task_id: "0",
            task_name:  this.state.labels.LBLSELECTS,
            custom_task_name: this.state.labels.LBLSELECT,
            title: this.state.labels.LBLSELECT,
            version: "1"
        });

        successFactors[0] = {
            active: 1,
            color: "sf_color1",
            description: "",
            id: "0",
            name: "LBLSELECT",
            title: this.state.labels.LBLSELECTS,
            version: "1"
        };
        this.setState({
            successFactors: successFactors,
            mainTasks: mainTasks,
            categories: categories,
            taskTypes: taskTypes,
            defaultRoles: defaultRoles,
            selectedSF: successFactors[0],
            selectedMain: mainTasks[0],
            template_data:template_data
        })
    }
    getLabel = (xfield, mode) => {
        if (typeof xfield === "string") {
            if (this.props.labels.labels.hasOwnProperty(xfield)) {
                return this.props.labels.labels[xfield];
            } else {
                return xfield;
            }
        } else {
            if (xfield != undefined) {
                if (
                    typeof xfield === "object" &&
                    this.props.labels.labels.hasOwnProperty(xfield.label)
                ) {
                    return this.props.labels.labels[xfield.label].replace(
                        "$$$",
                        xfield.val
                    );
                } else {
                    return xfield + "111";
                }
            } else {
                return "";
            }
        }
    };
    checkValidations = (tmpTemplateDetails, mode) => {
        let errorWeight = false, errorDeadl = false, errorType = false, errorRole = false, errorCat = false, errorSubDesc = false,errorSubDesc1 = false, errorSub = false,errorSub1 = false, errorDesc = false,errorDesc1;
        let errorMaind = false, errorMain = false, errorMain1 = false, errorFactor = false, errorFactor1 = false;
        let taskname = "", checkVal = true;
        let { err_task_name, err_task_description, err_weightage, err_references, teamSuccessFactorList,
            err_default_role, err_task_type, err_recommended_start_days, cntWeight, editingTeamSF } = this.state;

        let loc_editingTeamSF = tmpTemplateDetails;
        if (mode == 1) {
            if ($.trim(tmpTemplateDetails.success_factor_id) == "0") {
                checkVal = false;
                errorFactor = true;
            } 
            if ($.trim(tmpTemplateDetails.task_name) == "") {
                checkVal = false;
                errorMain = true;
            } else {  
                if(tmpTemplateDetails.task_name.length>1024){
                    errorMain1 = true;
                    errorMain = true;
                } 
            }

            if ($.trim(tmpTemplateDetails.task_description) == "") {
                checkVal = false;
                errorDesc = true;
            }else {  
                if(tmpTemplateDetails.task_description.length>1024){
                    errorDesc1 = true;
                    errorDesc = true;
                } 
            }
        } else { 
            if (!tmpTemplateDetails.success_factor_id || $.trim(tmpTemplateDetails.success_factor_id) == "0") {
                checkVal = false;
                errorFactor = true;
            }
            if ($.trim(tmpTemplateDetails.task_name) == "") {
                checkVal = false;errorSub = true; 
            }else {  
                if(tmpTemplateDetails.task_name.length>1024){
                    errorSub = true;errorSub1 = true; 
                } 
            }

            if (checkVal) {
                if (tmpTemplateDetails.task_id) {
                    teamSuccessFactorList.map((list, i) => {
                        taskname = list.custom_task_name ? list.custom_task_name : this.getLabel(list.task_name);
                        taskname = $.trim(taskname);
                        if (list.task_type == tmpTemplateDetails.task_type && taskname == tmpTemplateDetails.task_name && list.task_id != tmpTemplateDetails.task_id) {
                            this.showAlert("warning", this.props.labels.labels.LBLTASKEXISTS, 3000);
                            checkVal = false;
                        }
                    });
                }
            }
            if ($.trim(tmpTemplateDetails.task_description) == "") {
                checkVal = false; errorSubDesc = true; 
            }else {  
                if(tmpTemplateDetails.task_description.length>1024){
                    errorSubDesc1 = true; errorSubDesc = true; 
                } 
            }
             
            if (tmpTemplateDetails.parent_task_display_id == "") {
                checkVal = false;
                errorMaind = true;
            }
            let category = tmpTemplateDetails.category==0?false:tmpTemplateDetails.category==1?false:true;
            if (category) {
                checkVal = false;
                errorCat = true;
            }
            tmpTemplateDetails.weightage = 0;
            tmpTemplateDetails.default_role_id  = "DEFAULT";
            if ((tmpTemplateDetails.recommended_start_days || "") == "" ) {
                checkVal = false;
                errorDeadl = true; 
            }  
            if (tmpTemplateDetails.task_type_base == undefined || tmpTemplateDetails.task_type_base == -1 && (tmpTemplateDetails.task_type == 1 || tmpTemplateDetails.task_type == -1 )) {
                checkVal = false;
                errorType = true;
            }
             
            if (tmpTemplateDetails.task_type == 1 && loc_editingTeamSF.custom_references && loc_editingTeamSF.custom_references.length > 250) {
                checkVal = false;
            } else {
                var regex = new RegExp(/^([a-z]:((\\|\/|\\\\|\/\/))|(\\\\|\/\/))[^<>:"|?*]+/i);
                if ($.trim(loc_editingTeamSF.custom_references) != "") {
                    if (regex.test(loc_editingTeamSF.custom_references)) {
                        err_references = false;
                    } else {

                    }
                }
            }
            if (tmpTemplateDetails.custom_references && tmpTemplateDetails.custom_references.length > 250) {
                checkVal = false;
                this.showAlert("warning", this.props.labels.labels.LBLREFMAXEXCEED, 3000);
            }
        }
        this.setState({
            errorWeight, errorDeadl, errorType, errorRole,
            errorCat, errorSubDesc,  errorSubDesc1,errorSub,errorSub1, errorDesc,errorDesc1,
            errorMaind, errorMain,errorMain1, errorFactor
        })

        return checkVal;
    }
    addTaskDetails = () => {
        let { taskDetails, selectedValue, selectedSF, selectedCategory, selectedType, selectedRole, selectedMain } = this.state;
        let values = taskDetails, sfid = selectedSF.id, lastsortorder = 110;
        let parentTaskId="", allTasks = taskDetails.all_tasks;
        let taskType = 1, taskName, taskDescription, mainTask, mainTaskDescription, weightage, deadline, references;
        taskName = $("#txt_sub_task").val();
        taskDescription = $("#txt_sub_desc").val();
        mainTask = $("#txt_main_task").val();
        mainTaskDescription = $("#txt_main_desc").val();
        weightage = $("#txt_weightage").val();
        deadline = $("#txt_deadline").val();
        references = $("#txt_resource").val();
        deadline = Math.abs(deadline);
        deadline = selectedType.id == 0 ? deadline : selectedType.id == 1?"-" + deadline:"";

        if (selectedValue == "1") {
            taskType = 0;
            taskName = mainTask;
            taskDescription = mainTaskDescription;
            deadline = "";
            weightage = 0;
            sfid = selectedSF.id
        } else {
            //sfid = selectedMain.success_factor_id;
            sfid = selectedSF.id ;
            parentTaskId = selectedSF.id +"M1";
            selectedMain=taskDetails.main_tasks[1];
        }
        let loc_values = taskDetails;
        var tmpTemplateDetails = {
            //task_id: values.task_id,
            template_id: taskDetails.template_data.template_id,
            template_name: taskDetails.template_data.template_name,
            template_from: selectedMain.template_from,
            language_code: selectedMain.language_code,
            version: "1.0",
            success_factor_id: sfid,//values.success_factor_id,
            task_display_id: values.task_display_id,
            parent_task_display_id: parentTaskId,//selectedValue == "2"?selectedMain.parent_task_display_id:"",
            task_type: selectedValue == "1" ? (0) : (1),
            sequece_order: lastsortorder,
            task_name: taskName,
            task_description: taskDescription,
            task_type_base: selectedValue == "2" ? selectedType.id : "",
            category: selectedValue == "2" ? selectedCategory.id : "",
            recommended_start_days: deadline,
            weightage: weightage,
            created_by: this.props.userId,
            updated_by: this.props.userId,
            del_flag: 0,
            references: selectedValue == "2" ? references : "",
            custom_references: selectedValue == "2" ? references : "",
            default_role_id: selectedValue == "2" ? selectedRole.steercom_role_id : "",
            directchild: 1 
        }
        if (this.checkValidations(tmpTemplateDetails, selectedValue)) {
            allTasks.map((list, i) => {
                if (tmpTemplateDetails.task_id == null) {
                    if (list.task_type == tmpTemplateDetails.task_type && list.task_name == tmpTemplateDetails.task_name) {
                        this.showAlert("warning", this.props.labels.labels.LBLTASKEXISTS, 3000);
                        return false;
                    }
                }
            });
            this.saveSuccessFactorTempCallback(tmpTemplateDetails);
        }
    }
    saveSuccessFactorTemp = (tmpSuccessFactorData) => {
        let values = tmpSuccessFactorData;
        let { taskDetails, steercomSettings, teamSuccessFactorList, editingTeamSF, teamLanguage } = this.state;
        let lastsortorder = 100, currval = 0;
        let currData = teamSuccessFactorList.filter((items, idx) => {
            if (items.team_id == tmpSuccessFactorData.task_id) {
                return teamSuccessFactorList[currval]
            }
        })
        let loc_values = editingTeamSF["M_" + values.task_display_id];
        let sfid = values.parent_task_display_id.split("M")[0];
        loc_values = loc_values ? loc_values : [];
        lastsortorder++;

        var tmpTemplateDetails = {
            task_id: values.task_id,
            template_id: taskDetails.template_data.template_id,
            template_name: taskDetails.template_data.template_name,
            language_code: teamLanguage.id,
            version: "1.0",
            success_factor_id: sfid,//values.success_factor_id,
            task_display_id: values.task_display_id,
            parent_task_display_id: values.parent_task_display_id,
            task_type: values.task_type,
            sequece_order: lastsortorder,
            task_name: loc_values.task_name ? $.trim(loc_values.task_name) : "",
            task_description: loc_values.task_description ? $.trim(loc_values.task_description) : "",
            task_type_base: loc_values.task_type_base,
            recommended_start_days: loc_values.recommended_start_days,
            weightage: loc_values.weightage,
            created_by: this.props.userId,
            updated_by: this.props.userId,
            del_flag: 0,
            references: loc_values.references,
            custom_references: loc_values.custom_references,
            default_role_id: loc_values.default_role_id,
            directchild: loc_values.directchild

        }
        if (this.checkValidations(tmpTemplateDetails)) {
            teamSuccessFactorList.map((list, i) => {
                if (tmpTemplateDetails.task_id == null) {
                    if (list.task_type == tmpTemplateDetails.task_type && list.task_name == tmpTemplateDetails.task_name) {
                        this.showAlert("warning", this.state.labels.LBLTASKEXISTS, 3000);
                        return false;
                    }
                }
            });

            tmpTemplateDetails.recommended_start_days = Math.abs(tmpTemplateDetails.recommended_start_days);
            tmpTemplateDetails.recommended_start_days = tmpTemplateDetails.task_type_base == 0 ? tmpTemplateDetails.recommended_start_days : "-" + tmpTemplateDetails.recommended_start_days;
            this.saveSuccessFactorTempCallback(tmpTemplateDetails);
        } else {

        }
    }
    saveSuccessFactorTempCallback = async (tmpSuccessFactorData) => {
        let labelname = "LBLADDSUCCESS", callType = "add";
        if (tmpSuccessFactorData.task_id) {
            callType = "put";
            labelname = "LBLUPDTSUCCESS";
        }
        delete tmpSuccessFactorData.company_id;
        let res = await SteercomServices.saveSuccessFactorTask(tmpSuccessFactorData, callType);

        labelname = tmpSuccessFactorData.task_type == 1 ? "LBLADDSUCCESS" : "LBLADDMAINSUCCESS";
        if (callType == "put") {
            labelname = "LBLUPDTSUCCESS";
        }
        if (res.status == "SUCCESS") {
            //this.getTeamSFList();
            //this.showAlert("success", this.state.labels[labelname], 3000); 
            this.props.closeModal(labelname);
        } else {
            this.showAlert("warning", this.state.labels.LBLTASKEXISTS, 3000);
        }
        this.setState({
            showSave: false
        })
    }
    showAlert = (messageType, message, showtime) => {

        this.setState({
            openalert: true,
            messageType: messageType,
            message: message,
            showtime: showtime ? showtime : 3000
        });
    };
    handleCloseAlert = () => {
        this.setState({ openalert: false });
    };
    handleChange = (event) => {
        let val = event.target.value;
        let selval = event.target.value;
        this.setState({ value: val, selectedValue: selval });
    };
    handleSF = (event, selval) => {
        this.setState({ selectedSF: selval });
    }
    handleSFMainTasks = (event, selval) => {
        let SFMainTasks=[];
        let {cntWeight, taskDetails, currSF, mainTasks}=this.state;
        let errorSF=false,allTasks = taskDetails.all_tasks;
        cntWeight = 0;
        currSF = selval.id; 
        errorSF = currSF !=0?false:true;
        allTasks.map(item=>{
            if(item.task_type==0 && item.success_factor_id==currSF){
                SFMainTasks.push(item);
            }
        }); 
        this.setState({SFMainTasks:SFMainTasks,selectedSF: selval,selectedMain: mainTasks[0],errorFactor:errorSF});
    }
    handleMainTask = (event, selval) => {
        let {cntWeight, taskDetails, currSF}=this.state;
        let allTasks = taskDetails.all_tasks;
        cntWeight = 0;
        currSF = selval.success_factor_id;
        allTasks.map(item=>{
            if(item.task_type==1 && item.success_factor_id==currSF){
                cntWeight = cntWeight + parseInt(item.weightage);
            }
        }); 
        this.setState({ selectedMain: selval,cntWeight:cntWeight,currSF:currSF });
    }
    handleCategory = (event, selval) => {
        this.setState({ selectedCategory: selval });
    }
    handleType = (event, selval) => {
        let errorType = selval.id==-1?true:false;
        this.setState({ selectedType: selval,errorType});
    }
    handleRoles = (event, selval) => {
        this.setState({ selectedRole: selval });
    }
    handleDeadline = (event)=>{
        let selval = event.currentTarget.value;
        if(selval == "-" || selval.toLowerCase() == "e"){ 
            selval = 0;
        }else if(selval.indexOf("-")>-1 || selval.toLowerCase().indexOf("e")>-1){
            selval = Math.abs(selval);
        } 
        this.setState({ selectedDeadline: selval }); 
    }
    handleWeightage = (event)=>{
        let selval = event.currentTarget.value;
        if(selval == "-" || selval.toLowerCase() == "e"){ 
            selval = 0;
        }else if(selval.indexOf("-")>-1 || selval.toLowerCase().indexOf("e")>-1){
            selval = Math.abs(selval);
        }
        
       this.setState({ selectedWeight: selval }); 
    }
    render() {
        let {
            LBLPARENTTASK, LBLTASK, LBLDESCRIPTION, LBLTASKTYPE, LBLUPLOADRESOURCE, LBLCATEGORY, LBLSAVE, LBLCANCEL,LBLTOTALWEIGHT,
            LBLSF, LBLINDAYS, LBLDEADLINES, LBLREQUIRED, LBLNAMEEXCEED, LBLDESCNAMEEXCEED
        } = this.state.labels;
        let { successFactors, mainTasks, categories, taskTypes, selectedValue,
             errorDeadl, errorType, errorCat, errorSubDesc,errorSubDesc1, errorSub, errorDesc,errorSub1, errorDesc1,
             errorMain,errorMain1, errorFactor,currSF } = this.state;
        let horizontal = "top", vertical = "center";
        return (
            <div className="steercom-configuration ">
                <Snackbar
                    style={{ width: "43%", margin: "0% 30%", justifyItems: "center" }}
                    anchorOrigin={{ horizontal, vertical }}
                    key={"top-center"}
                    open={this.state.openalert}
                    autoHideDuration={
                        this.state.showtime ? this.state.showtime : 2000
                    }
                    onClose={this.handleCloseAlert}
                    bodyStyle={{ backgroundColor: "teal", color: "coral" }}
                >
                    <Alert
                        variant="filled"
                        onClose={this.handleClose}
                        severity={this.state.messageType}
                    >
                        {this.state.message}
                    </Alert>
                </Snackbar>
                {mainTasks && mainTasks.length > 0 ?
                    <div>
                         <div style={{display:"flex"}}>
                         <div style={{display:selectedValue=="2"?"none":"none"}} className="cls_weightage"><div className="cls_weightage1">{LBLTOTALWEIGHT} ({currSF}) : </div><div className="cls_weightage2">{this.state.cntWeight}</div></div>
                         </div>
                         <div className="row cls_add_main_container">
                        {selectedValue == "1" ?
                            <div className="row cls_add_main_containers">
                                <div className="col-xl-10 col-md-8 col-sm-12" style={{ marginTop: "0px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLSF}:</label>
                                    <Autocomplete
                                        id="cmb_factor"
                                        style={{ width: "calc(100%)", }}
                                        options={successFactors}
                                        getOptionLabel={(option) => option.title}
                                        getOptionSelected={(option, values) =>
                                            option.id === values.id
                                        }
                                        onChange={this.handleSF}
                                        disableClearable
                                        defaultValue={this.state.selectedSF}
                                        value={this.state.selectedSF}
                                        renderOption={(option) => (
                                            <div style={{ fontSize: "14px" }}>{option.title}</div>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                value="surs"
                                                text="sur"
                                                {...params}
                                            />
                                        )}
                                    />
                                    <span style={{ display: errorFactor ? "block" : "none"}} className="required-field">{LBLREQUIRED}</span>
                                </div>
                                <div className="col-xl-10 col-md-8 col-sm-12" style={{ marginTop: "10px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLPARENTTASK}:</label>
                                    <div className="cls_act_description">
                                        <TextField id={"txt_main_task"}
                                            style={{
                                                overflow: "hidden", fontSize: "12px", width: "450px",
                                                whiteSpace: "nowrap", textOverflow: "ellipsis",
                                            }}>
                                        </TextField> 
                                    </div>
                                    <span style={{ display: errorMain ? "block" : "none"}} className="required-field">{errorMain1?LBLNAMEEXCEED:LBLREQUIRED}</span>
                                </div>
                                <div className="col-xl-10 col-md-8 col-sm-12" style={{ marginTop: "10px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLDESCRIPTION}:</label>

                                    <textarea
                                        style={{
                                            width: "100%",
                                        }}
                                        id={"txt_main_desc"}
                                        className="cls_txt_area cls_act_inp_description"
                                    ></textarea>
                                    <span style={{ display: errorDesc ? "block" : "none"}} className="required-field">{errorDesc1?LBLDESCNAMEEXCEED:LBLREQUIRED}</span>

                                </div>
                            </div>
                            :
                            <div className="row cls_add_main_containers">
                                 <div className="col-xl-10 col-md-8 col-sm-12" style={{ marginTop: "0px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLSF}:</label>
                                    <Autocomplete
                                        id="cmb_factor"
                                        style={{ width: "calc(100%)", }}
                                        options={successFactors}
                                        getOptionLabel={(option) => option.title}
                                        getOptionSelected={(option, values) =>
                                            option.id === values.id
                                        }
                                        onChange={this.handleSFMainTasks}
                                        disableClearable
                                        defaultValue={this.state.selectedSF}
                                        value={this.state.selectedSF}
                                        renderOption={(option) => (
                                            <div style={{ fontSize: "14px" }}>{option.title}</div>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                value="surs"
                                                text="sur"
                                                {...params}
                                            />
                                        )}
                                    />
                                    <span style={{ display: errorFactor ? "block" : "none"}} className="required-field">{LBLREQUIRED}</span>
                                </div>
                                <div className="col-xl-10 col-md-9 col-sm-12" style={{ marginTop: "20px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLTASK}:</label>
                                    <div className="cls_act_description">
                                        <TextField  id={"txt_sub_task"} className="cls_ellipsis addTaskTaskField"> 
                                        </TextField>
                                    </div>
                                    <span style={{ display: errorSub ? "block" : "none"}} className="required-field">{errorSub1?LBLNAMEEXCEED:LBLREQUIRED}</span>
                                </div>
                                <div className="col-xl-10 col-md-9 col-sm-12" style={{ marginTop: "20px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLDESCRIPTION}:</label>
                                    <textarea
                                        style={{
                                            width: "100%",
                                        }}
                                        id={"txt_sub_desc"}
                                        className="cls_txt_area cls_act_inp_description"
                                    ></textarea>
                                    <span style={{ display: errorSubDesc ? "block" : "none"}} className="required-field">{errorSubDesc1?LBLDESCNAMEEXCEED:LBLREQUIRED}</span>

                                </div>
                                <div className="col-xl-4 col-md-5 col-sm-12" style={{ marginTop: "20px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLCATEGORY}:</label>
                                    <Autocomplete
                                        style={{ width: "calc(100%)", fontSize: "12px" }}
                                        size={"small"}
                                        label={LBLPARENTTASK}
                                        placeholder={LBLPARENTTASK}
                                        disableClearable
                                        options={categories}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, values) =>
                                            option.id === values.id
                                        }
                                        onChange={this.handleCategory.bind(this)}
                                        underlineStyle={{ display: "none" }}
                                        defaultValue={this.state.selectedCategory}
                                        value={this.state.selectedCategory}
                                        renderOption={(option) => (
                                            <div style={{ fontSize: "14px" }}>{option.name}</div>
                                        )}
                                        renderInput={(params) => (
                                            <TextField style={{ fontSize: "12px" }} id="sflist" {...params} />
                                        )}
                                        underline="none"
                                    />
                                    <span style={{ display: errorCat ? "block" : "none"}} className="required-field">{LBLREQUIRED}</span>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-12" style={{ marginTop: "20px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLTASKTYPE}:</label>
                                    <Autocomplete
                                        style={{ width: "calc(100%)", fontSize: "12px" }}
                                        size={"small"}
                                        label={LBLPARENTTASK}
                                        placeholder={LBLPARENTTASK}
                                        disableClearable
                                        options={taskTypes}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, values) =>
                                            option.id === values.id
                                        }
                                        onChange={this.handleType.bind(this)}
                                        underlineStyle={{ display: "none" }}
                                        defaultValue={this.state.selectedType}
                                        value={this.state.selectedType}
                                        renderOption={(option) => (
                                            <div style={{ fontSize: "14px" }}>{option.name}</div>
                                        )}
                                        renderInput={(params) => (
                                            <TextField style={{ fontSize: "12px" }} id="sflist" {...params} />
                                        )}
                                        underline="none"
                                    />
                                    <span style={{ display: errorType ? "block" : "none"}} className="required-field">{LBLREQUIRED}</span>
                                </div>
                                <div className="col-xl-3 col-md-9 col-sm-12" style={{ marginTop: "20px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLDEADLINES}:</label>
                                    <div className="cls_act_description">
                                        <input className="cls_small_text"
                                            type="number"
                                            name={"chkg_"}
                                            id={"txt_deadline"}
                                            data-toggle="modal"
                                            value={this.state.selectedDeadline }
                                            onChange={this.handleDeadline.bind(this )}
                                        /> <span>{LBLINDAYS}</span>
                                    </div>
                                    <span style={{ display: errorDeadl ? "block" : "none"}} className="required-field">{LBLREQUIRED}</span>
                                </div>
                                <div className="col-xl-10 col-md-9 col-sm-12" style={{ marginTop: "20px" }}>
                                    <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                                        {LBLUPLOADRESOURCE}:</label>
                                    <div className="cls_act_description">
                                        <TextField  id={"txt_resource"}
                                            className="addTaskCustomerResourse"
                                            style={{

                                            }}>
                                        </TextField>
                                    </div>
                                </div>
                                <br></br>
                            </div>
                        }
                <br></br>
                <br></br>
                <div className="col-sm-12 btm-submit cls_submit">
                    <>
                        <button
                            type="submit"
                            className="button-style primary-btn"
                            disabled={this.state.submitting}
                            style={{ float: "right" }}
                            onClick={this.addTaskDetails}
                        >
                            {LBLSAVE}
                        </button>
                    </>
                    <button
                        type="button"
                        className="button-style secondary-btn"
                        id="btnaddCancel"
                        onClick={this.props.closeModal}
                        style={{ float: "right", marginRight: "10px" }}
                    >
                        {LBLCANCEL}
                    </button>
                </div>
                <br></br>
                <br></br>
                        </div>
                    </div>
                    : ""}
                <br></br>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyId: state.user_info_reducer.user_info.company_id,
        name: state.user_info_reducer.user_info.name,
        userId: state.user_info_reducer.user_info.user_id,
        role_id: state.user_info_reducer.user_info.role_id,
        role: state.user_info_reducer.user_info.role,
        company: state.user_info_reducer.user_info.company,
        email: state.user_info_reducer.user_info.email,
        company_name: state.user_info_reducer.user_info.company_name,
        language_code: state.user_info_reducer.user_info.language_code,
        currency: state.user_info_reducer.user_info.currency,
        dateformat: state.user_info_reducer.user_info.dateformat,
        timeformat: state.user_info_reducer.user_info.timeformat,
        timezone: state.user_info_reducer.user_info.timezone,
    };
};

export default connect(mapStateToProps, null)(AddTask);

import React from "react";
import "./assetsviewer.css";
import ReadImage from "../readImage/readImage";
import ReadVideo from "../readVideo/readvideo";
import DisplayOGConent from "../ogcontent/ogcontent";

class AssetsViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currNoOfAssets: this.props.activeIndex + 1,
      playing:false
    };
    this.totalAssets = 0;
  }
  componentDidMount() {
    this.showSelectedAssets();
  }
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.feedsLanguageData && this.props.feedsLanguageData[paramLBL]
        ? this.props.feedsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  closeAssetsViewerHandler = (evt) => {
    this.props.closeAssetsViewer();
  };
  showPreviousAssetsHandler = (evt) => {
    if (this.state.currNoOfAssets > 1) {
      this.setState(
        {
          currNoOfAssets: this.state.currNoOfAssets - 1,
        },
        () => {
          this.showSelectedAssets();
        }
      );
    }
  };
  showNextAssetsHandler = (evt) => {
    if (this.state.currNoOfAssets < this.totalAssets) {
      this.setState(
        {
          currNoOfAssets: this.state.currNoOfAssets + 1,
        },
        () => {
          this.showSelectedAssets();
        }
      );
    }
  };
  showSelectedAssets = () => {
    let element = document.querySelector(
      ".ssi-feeds-assetsViewerComponen, .showAssets"
    );
    if (element !== null) {
      element.classList.remove("showAssets");
    }
    let selElement = document.querySelectorAll(
      ".ssi-feeds-assetsViewerComponent"
    );
    if (selElement.length) {
      selElement[this.state.currNoOfAssets - 1].classList.add("showAssets");
    }
  };

  render() {
    this.totalAssets =
      this.props.feedItemData.assets.length +
      this.props.feedItemData.og_content.length;
    return (
      <div className="ssi-feeds-assertsViewerContainer">
        <div className="ssi-feeds-assertsViewerWrpper">
          <div className="ssi-feeds-assertsViewerInnerWrpper">
            <div className="ssi-feeds-assertsViewerHolder">
              <div className="ssi-feeds-assertsViewerContentContaner">
                <div className="ssi-feeds-assertsViewerContentWrapper">
                  {this.props.feedItemData.assets.length > 1 ? (
                    <div
                      className={
                        this.state.currNoOfAssets <= 1
                          ? "ssi-feeds-assetsViewerLeftSideBtn disable"
                          : "ssi-feeds-assetsViewerLeftSideBtn"
                      }
                      onClick={this.showPreviousAssetsHandler}
                    >
                      <span className="viewerIcon fas fa-arrow-alt-circle-left"></span>
                    </div>
                  ) : null}
                  <div className="ssi-feeds-assertsViewerContent">
                    <div className="ssi-feeds-assertsViewerContentHolder">
                       
                        
{
    this.props.feedItemData.assets.length?
   this.props.feedItemData.assets.map((assetItem, index) => {
                       
       if (
                assetItem.asset_type === "image" ||
                assetItem.asset_type === "image/jpeg"
              ) {
                return (
                  <div
                    className="ssi-feeds-assetsViewerComponent"
                    key={index}
                  >
                    <ReadImage
                      key={index}
                      assetItem={assetItem}
                      totalAssets={0}
                    />
                  </div>
                );
              } else if (assetItem.asset_type === "video") {
                let playing =false
if(this.state.currNoOfAssets ===index+1){
  playing =true
}

                return (
                  <div
                    className="ssi-feeds-assetsViewerComponent"
                    key={index}
                  >
                    <ReadVideo
                    playingstatus={playing}
                      key={index}
                      keyvalue={index}
                      currNoOfAssets={this.state.currNoOfAssets}
                      showAssetsViewer={
                        this.props.showAssetsViewer
                      }
                      assetItem={assetItem}
                      totalAssets={0}
                    />
                  </div>
                );
              }
   })
   :null
}

                      {this.props.feedItemData.og_content.length
                        ? this.props.feedItemData.og_content.map(
                            (ogItem, index) => {
                              return (
                                <div
                                  className="ssi-feeds-assetsViewerComponent"
                                  key={
                                    this.props.feedItemData.assets.length +
                                    index
                                  }
                                >
                                  <DisplayOGConent
                                    key={
                                      this.props.feedItemData.assets.length +
                                      index
                                    }
                                    ogItem={ogItem}
                                    totalAssets={0}
                                  />
                                </div>
                              );
                            }
                          )
                        : ""}
                    </div>
                  </div>
                  {this.props.feedItemData.assets.length > 1 ? (
                    <div
                      className={
                        this.state.currNoOfAssets >= this.totalAssets
                          ? "ssi-feeds-assetsViewerRightSideBtn disable"
                          : "ssi-feeds-assetsViewerRightSideBtn"
                      }
                      onClick={this.showNextAssetsHandler}
                    >
                      <span className="viewerIcon fas fa-arrow-alt-circle-right"></span>
                    </div>
                  ) : null}
                </div>
                <div
                  className="ssi-feeds-assetsCloseButton"
                  onClick={this.closeAssetsViewerHandler}
                  title={this.getResourceFromLabels("LBLCLOSE")}
                >
                  <span>x</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssetsViewer;

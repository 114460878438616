import React, { Component } from "react";
import ReportServices from "../report_services";
import "./RefresherCapsuleCompletion.scss";
import RefresherCapsuleProduct from "./RefresherCapsules/RefresherCapsule_product";
import RefresherCapsuleusers from "./RefresherCapsules/RefresherCapsule _users";
import { connect } from "react-redux";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import UsersService from "../../user-list/UsersService";
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import emptyImage from "../../../../assets/images/learnerprogress_emptyst.png";
import {
  exportCSV,
  exportToExcel,
} from "../../../../common_components/rptexport";
let resetColumns = [];
let objFilterData = {};
let loadfilter = true;
const unitordersort = (a, b) =>
  (a["order"] > b["order"] && 1) || (a["order"] === b["order"] ? 0 : -1);
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};
const $ = require("jquery");
class LearnerProgressList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCapsuleItem: null,
      backclicked: false,
      courseList: null,
      showCourse: true,
      filterFields: [],
      resetfilterFields: [],
      load_refCap_List: 0,
      load_refCap_List_Groups: 1,
      load_refCap_Fetched: 0,
      filterDataLoaded: 0,
      selectedIds: [],
      showUsers: false,
      courses: [],
      userNames: [],
      userMails: [],
      courseunits: [],
      rpt_showfields: [],
      coursestatus: [],
      showFilterInitialMode: "OPEN",

    };
  }
  backHandler = () => {
    this.setState({
      selectedCapsuleItem: null,
      backclicked: true,
      showUsers: false,
      showFilterInitialMode: "CLOSE",
    });
    $("html").scrollTop(0);
  };
  setCapsuleDetails = (param) => {
    console.log("param", param);
    this.setState({
      selectedCapsuleItem: param,
      showUsers: true,
    });
  };
  loadpage(a, x) {
    if (a === "LCP_D1") {
      this.setState({load_refCap_List: 1},
      ()=>{
        this.checkLoadFun();
      });
    }
    if (a === "group") {
      this.setState({load_refCap_List_Groups: 1},
        ()=>{
          this.checkLoadFun();
        });
    }
  }
  
  checkLoadFun = () => {
    if (
      this.state.load_refCap_List === 1 &&
      this.state.load_refCap_List_Groups === 1
    ) {
      this.setState({
        loading: false,
      });
    }
  }
  componentDidMount = () => {
    objFilterData = {};
    let data = {};
    data.date1 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    data.date2 = new Date(new Date());
    data.sdate1 = this.getDateFormat(data.date1);
    data.sdate2 = this.getDateFormat(data.date2);
    data.offsetTime = this.getOffsetTime();
    this.getFilterDatas();
    let getData = {
      language: this.props.language,
    };
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      // {group_type_id: "GP_1", lbl_GP_1: "Location", Active: "1"};
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
        },
        () => this.loadpage("groups")
      );
    });
  };
  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  async getFilterDatas() {
    let data = {};
    data.report = "LCP_D1";
    let group_types = await require("../../../../json_files/group_types.json");
    ReportServices.getFilterDatas(data).then((response) => {
      let xgroup_types = response.result[0];
      let labels;
      let groups = {};
      for (var i = 0; i < xgroup_types.length; i++) {
        if (this.props.language_code === xgroup_types[i].language_code) {
          labels = JSON.parse(xgroup_types[i].labels).group_labels;
        }
      }
      if (labels.length > 0) {
        for (const grouptype of group_types) {
          if (labels[grouptype.group_type_id]) {
            grouptype["lbl_" + grouptype.group_type_id] =
              labels[grouptype.group_type_id]["lbl_" + grouptype.group_type_id];
            grouptype["Active"] = labels[grouptype.group_type_id]["Active"];
            groups[grouptype.group_type_id] = [];
            groups["obj" + grouptype.group_type_id] = {};
          } else {
            grouptype["Active"] = "0";
          }
        }
      }
      let xgroups = response.result[1]; 
      for (var j = 0; j < xgroups.length; j++) {
        if (
          groups[xgroups[j].group_type_id] &&
          !groups["obj" + xgroups[j].group_type_id][xgroups[j].value]
        ) {
          groups["obj" + xgroups[i].group_type_id][xgroups[j].value] = 1;
          groups[xgroups[j].group_type_id].push(xgroups[j]);
        }
      }
      let courses = response.result[2].sort(this.sortorder);
      let users = response.result[3];
      let usersemail = [];
      for (let i = 0; i < users.length; i++) {
        if (users[i].email) {
          usersemail.push({ id: users[i].id, value: users[i].email });
        }
      }
      let courseunits = response.result[4];
      let xrs5 = response.result[5];
      let rpt_showfields = "";
      if (
        xrs5 &&
        xrs5[0] &&
        xrs5[0].user_showfields &&
        String(xrs5[0].user_showfields) !== ""
      ) {
        rpt_showfields = xrs5[0].user_showfields.split(",");
      }
      this.setState(
        {
          filterDataLoaded: 1,
          pageState: "LCP_D1",
          courses: courses,
          userNames: users,
          userMails: usersemail,
          courseunits: courseunits,
          rpt_showfields: rpt_showfields,
          coursestatus: [
            {
              id: "Notstarted",
              value: this.props.labels.labels["LBLNOTSTARTED"],
            },
            {
              id: "Completed",
              value: this.props.labels.labels["LBLCOMPLETED"],
            },
          ],
        },
        () => this.loadpage("LCP_D1")
      );
    });
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  runReportsList = () => {
    this.getProducts();
  };
  getProducts() {
    ReportServices.getRefresherReportList(this.state.selectedIds).then(
      (response) => {
        this.setState({
          courseList: response,
          showCourse: true,
          load_refCap_Fetched: 1,
        });
      }
    );
  }
  setFilterFields = (conditions) => {
    let xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportfunction) {
        xobj[conditions[i].name].exportfunction = conditions[i].exportfunction;
      }
    }
    objFilterData.availableRYS_D1Fields = xobj;
  };
  applyFilters_conditions = (conditions) => {
    let locfilter = { singleItems: [], multiItems: [] };
    for (var i = 0; i < Object.keys(conditions).length; i++) {
      if (Object.keys(conditions)[i] === "Incident Type") {
        locfilter.singleItems["Incident Type"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      } else if (Object.keys(conditions)[i] === "Incident Source") {
        locfilter.singleItems["Incident Source"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      } else if (Object.keys(conditions)[i] === "Severity") {
        locfilter.singleItems["Severity"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      } else if (Object.keys(conditions)[i] === "Status") {
        locfilter.singleItems["Status"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      } else if (Object.keys(conditions)[i] === "Location") {
        locfilter.multiItems = {
          GP_1: conditions[Object.keys(conditions)[i]].condition.value,
        };
      } else if (Object.keys(conditions)[i] === "Date") {
        locfilter["dateItems"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      }
    }
    loadfilter = false;
    this.bindfilters(conditions);
    this.setState({ allFilterValues: locfilter, showNode: true });
    $("#selItem_spanless_Date")
      .closest("div")
      .parent()
      .parent()
      //  .parent()
      .show();
  };
  bindfilters = (locfilter) => {
    let orgfilter = locfilter,
      filterval = {};
    let datefilter = {},
      inctype = {},
      incsource = {},
      location = {},
      department = {},
      severity = {},
      status = {};
    let status_condition = [],
      statuslen;
    let inc_type = [],
      inctypelen;
    let inc_source = [],
      incsourcelen;
    let loc_det = [],
      locationlen;
    let dep_det = [],
      departmentlen;
    let sev_det = [],
      severitylen;
    let lblsource = this.props.labels.labels.LBLERRORSOURCE;
    let lblincidenttypes = this.props.labels.labels.LBLINCTYPES;
    let lblseverity = this.props.labels.labels.LBLSEVERITY;
    let lbllocation = this.props.labels.labels.LBLLOCATIONS;
    let lbldepartment = this.props.labels.labels.LBLDEPARTMENT;
    let lblstatus = this.props.labels.labels.LBLSTATUS;
    inctypelen = orgfilter[lblincidenttypes].condition.value.length;
    if (inctypelen > 0) {
      orgfilter[lblincidenttypes].condition.value.map((itm) =>
        inc_type.push(itm.id)
      );
    }

    lblsource = lblsource ? lblsource : "Source of Error";
    incsourcelen = orgfilter[lblsource].condition.value.length;

    if (incsourcelen > 0) {
      orgfilter[lblsource].condition.value.map((itm) =>
        inc_source.push(itm.id)
      );
    }
    severitylen = orgfilter[lblseverity].condition.value.length;
    if (severitylen > 0) {
      orgfilter[lblseverity].condition.value.map((itm) => sev_det.push(itm.id));
    }
    locationlen = orgfilter[lbllocation].condition.value.length;
    if (locationlen > 0) {
      orgfilter[lbllocation].condition.value.map((itm) => loc_det.push(itm.id));
    }

    departmentlen = orgfilter[lbldepartment].condition.value.length;
    if (departmentlen > 0) {
      orgfilter[lbldepartment].condition.value.map((itm) =>
        dep_det.push(itm.id)
      );
    }

    statuslen = orgfilter[lblstatus].condition.value.length;
    if (statuslen > 1 || statuslen === 0) {
      status_condition = "";
    } else if (statuslen === 1) {
      status_condition =
        orgfilter[lblstatus].condition.value[0].id === 1 ? true : false;
    }
    let lbldate = this.props.labels.labels.LBLDATE;
    datefilter["operator"] =
      orgfilter[lbldate ? lbldate : "Date"].condition.operator;
    datefilter["value"] = orgfilter[lbldate ? lbldate : "Date"].condition.value;

    let year, month, date, maxdate;
    if (
      orgfilter[this.props.labels.labels.LBLDATE].condition.value.length > 1
    ) {
      maxdate = new Date(
        orgfilter[this.props.labels.labels.LBLDATE].condition.value[1]
      );
      year = maxdate.getFullYear();
      month = maxdate.getMonth();
      date = maxdate.getDate();
      orgfilter[this.props.labels.labels.LBLDATE].condition.value[1] = new Date(
        year,
        month,
        date,
        23,
        59,
        59
      );
    }
    inctype["operator"] = "INCIDENT_TYPE";
    inctype["value"] = inc_type;

    incsource["operator"] = "INCIDENT_SOURCE";
    incsource["value"] = inc_source;

    severity["operator"] = "SEVERITY";
    severity["value"] = sev_det;

    location["operator"] = "LOCATION";
    location["value"] = loc_det;

    department["operator"] = "DEPARTMENT";
    department["value"] = dep_det;

    status["operator"] = "STATUS";
    status["value"] = status_condition;

    filterval = {
      date: datefilter,
      incident_types: inctype,
      incident_sources: incsource,
      location: location,
      department: department,
      severity: severity,
      status: status,
    };

    this.setState({ filterval: filterval });
  };
  applyFilterCondition = (orgresult, data) => {
    if (objFilterData.availableRYS_D1Fields) {
      let xobj = objFilterData.availableRYS_D1Fields;
      // let result = [];
      if (!orgresult) {
        this.applyFilters_conditions(xobj);
      }
    }
  };
  onFilterCancel = (id, conditions) => { };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions) => {
    // console.log("onFilterChange", this.state.onFilterChange);
    let isValid = true;
    conditions.map((item, i) => {
      if (item.filter.checked && item.filter.required) {
        try {
          if (
            (item.filter.condition.operator === "MULTI_EQUALS" ||
              item.filter.condition.operator === "EQUALS") &&
            item.filter.condition.value === ""
          ) {
            isValid = false;
            REFS.REF.onError(item);
          }
        } catch (e) {
          isValid = false;
          REFS.REF.onError(item);
        }
      }
      return null;
    });
    if (isValid === true) {
      this.setState({
        reportloading: true,
        load_refCap_Fetched: 1,
        showFilterInitialMode: "CLOSE",
      });
      this.setFilterFields(conditions);
      let data = { type: "web", columns: objFilterData.availableRYS_D1Fields };
      data.filtercolumns = conditions;
      // console.log(
      //   "filter datas----",
      //   data.filtercolumns[0].filter.condition.value
      // );
      let selectedIds = data.filtercolumns[0].filter.condition.value
        ? data.filtercolumns[0].filter.condition.value.map((item) => {
          return item.id;
        })
        : [];
      this.setState({ selectedIds }, () => this.getProducts());
    }
  };
  onExport = (type, id, conditions) => {
    console.log("onExport", type, id, conditions, this.state.courseList);

    if (this.state.courseList) {
      this.convertExportData(this.state.courseList, type);
    } else {
      let selectedIds = [];
      this.setState({ selectedIds });
      ReportServices.getRefresherReportList(selectedIds).then((response) => {
        this.convertExportData(response, type);
      });
    }

  };
  convertExportData = (defData, type) => {
    const fileName = this.props.labels.labels.LBLREFCAPSSUMMARYEPRT;
    let tList = [];
    defData.map((cItem) => {
      let pObj = {};
      pObj[this.props.labels.labels.LBLCOURSENAME] = cItem.pname;
      cItem.units.map((uItem) => {
        let uObj = {};
        uObj[this.props.labels.labels.LBLUNITNAME] = uItem.unit;
        uItem.capsules.map((capItem) => {
          let cObj = {};
          cObj[this.props.labels.labels.LBLCAPSULENAME] =
            capItem.c_name.replace("#", "");
          cObj[this.props.labels.labels.LBLRELEASETYPE.trim()] = capItem.rel_ty;
          cObj[this.props.labels.labels.TIMEINTERVAL] = capItem.tm_intr;
          cObj[this.props.labels.labels.LBLNUMBEROFUSERS] = capItem.n_lrn;
          cObj[this.props.labels.labels.LBLRELEASED.trim()] = capItem.rel;
          cObj[this.props.labels.labels.LBLCOMPLETED] = capItem.compl;
          cObj[this.props.labels.labels.LBLNOTSTARTED] = capItem.nt_str;
          tList.push(Object.assign({}, pObj, uObj, cObj));
          return null;
        });
        return null;
      });
      return null;
    });
    let data = tList;
    console.log("tlist", data);
    if (type === "csv") {
      exportCSV(data, fileName,this.props.dateformat);
    } else if (type === "xlsx") {
      exportToExcel(data, fileName,this.props.dateformat);
    }
  };

  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    resetColumns = cols;
    let xcols = [];
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    
    if (mode === "COURSE") {
      let xobj = objFilterData.availableRYS_D1Fields;
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    }
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    let xcols = [];
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    return xcols;
  };
  getCapsuleReport_Columns = (x) => {
    let columns = [
      {
        name: this.props.labels.labels.LBLCOURSENAME,
        selector: "name",
        filter: {
          type: "string",
          showField: true,
          checked: true,
        },
        filterData: this.state.courses,
        width: "30%",
        exportcell: "_products_name",
        exportcellproperty: { order: 300 },
        tableIndex: 1,
      },
    ];
    return columns; //JSON.parse(JSON.stringify(columns))
  };

  getCondition(mode) {
    if (mode === "product_name") {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.selProduct
      ) {
        let selindex = -1;

        if (this.state.courses) {
          for (let i = 0; i < this.state.courses.length; i++) {
            if (
              this.state.courses[i].id === this.props.location.state.selProduct
            ) {
              selindex = i;
            }
          }
        }
        return {
          name: this.getLabel("LBLCOURSENAME"),
          operator: "EQUALS",
          value:
            this.state.courses && selindex > -1
              ? [this.state.courses[selindex]]
              : "",
          operatorText: "",
        };
      } else {
        return {
          name: this.getLabel("LBLCOURSENAME"),
          operator: "EQUALS",
          value:
            this.state.courses && this.state.courses[0]
              ? [this.state.courses[0]]
              : "",
          operatorText: "",
        };
      }
    }
  }

  render() {
    let {
      courseList,
      showUsers,
      courses,
      userNames,
      userMails,
      courseunits,
      rpt_showfields,
      coursestatus,
      groups,
      groupTypeNames,
    } = this.state;
    let columns = this.getCapsuleReport_Columns();
    let filterFields = this.getFilterColumns("COURSE", columns);
    let resetfilterFields = this.getResetFilterColumns("COURSE", columns);

    return (
      <>
        <div className="reportsCtn">
          <h3 className="page-title">
            {this.state.selectedCapsuleItem ? (
              <i
                className="fas fa-arrow-left cu-back-btn"
                aria-hidden="true"
                onClick={this.backHandler}
                style={{marginRight:"10px"}}
              ></i>
            ) : null}

            {this.state.showUsers
              ? this.props.labels.labels.LBLREFDETAILEDSUMMARY
              : this.props.labels.labels.LBLREFCAPSSUMMARY}
          </h3>
        </div>
        <hr className="head-line" />
        {/* {console.log(
          "filtereeeeee",
          this.state.loading,
          this.state.showUsers,
          this.state.filterDataLoaded
        )} */}
        {this.state.loading === false &&
          this.state.showUsers === false &&
          this.state.filterDataLoaded === 1 ? (
          <div className="reportsCtn">
            <CustomFilter
              labels={this.props.labels.labels}
              availableFields={filterFields}
              selectedFields={filterFields}
              orgAvailableFields={resetfilterFields}
              onFilterChange={this.onFilterChange}
              onExport={this.onExport}
              id="REF"
              reportName={this.props.labels.labels.LBLREFCAPSSUMMARY}
              companyName={this.props.company_name}
              onRef={(ref) => (REFS.REF = ref)}
              showFieldFilter={false}
            />
          </div>
        ) : null}
        {this.state.load_refCap_Fetched === 0 &&
          this.state.loading === false ? (
          <div style={{ textAlign: "center", padding: "30px" }}>
            <button
              onClick={this.runReportsList}
              id="btnGroupDrop12"
              type="button"
              className="button-style primary-btn"
            >
              {this.props.labels.labels.LBLRUNREPORT}
            </button>
          </div>
        ) : null}
        {courseList && !showUsers ? (
          <>
            <div className="bottomborder print_hide">
              <span className="subHeader" style={{ display: "inline-block" }}>
                <h4>
                  {this.props.labels.labels.LBLRECORDS} : {courseList.length}
                </h4>
              </span>
            </div>
          </>
        ) : null}

        {this.state.load_refCap_Fetched === 1 &&
          courseList &&
          courseList.length === 0 ? (
          <EmptyState
            image={emptyImage}
            text2={this.props.labels.labels.LBLNORECORDS}
          />
        ) : null}

        <div className="refreshercompletion">
          {showUsers ? (
            <RefresherCapsuleusers
              labels={this.props.labels.labels}
              selectedCapsuleItem={this.state.selectedCapsuleItem}
              courses={courses}
              userNames={userNames}
              userMails={userMails}
              courseunits={courseunits}
              rpt_showfields={rpt_showfields}
              coursestatus={coursestatus}
              groupTypeNames={groupTypeNames}
              groups={groups}
            />
          ) : courseList ? (
            <RefresherCapsuleProduct
              language_code={this.props.language}
              labels={this.props.labels.labels}
              setSelectedCapsule={this.setCapsuleDetails}
              product={this.state.courseList}
            />
          ) : null}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};
export default connect(mapStateToProps, null)(LearnerProgressList);

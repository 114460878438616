import React, { Component } from "react";
import MultiLevelAccordian from "../../../../../common_components/MultiLevelAccordian/MultiLevelAccordian";
class RefresherCapsuleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseList: this.props.product
    };
  }
  componentDidMount = () => {
    console.log("comp did mount");
    this.setState(
      {
        courseList: this.props.product
      }
    )
  }
  componentWillReceiveProps = (nextProps) => {
    // console.log("componentWillReceiveProps", nextProps.product, this.state.courseList);
    // console.log("componentWillReceiveProps", (nextProps.product !== this.state.courseList));
    // console.log("componentWillReceiveProps", nextProps.product.length, this.state.courseList.length);
    if (nextProps.product !== this.state.courseList) {
      this.setState(
        {
          courseList: nextProps.product
        }
      )
    }
  }
  render() {
    let { courseList } = this.state;
    console.log("this.props.product", this.props.product);
    console.log("courseList", courseList);
    return (
      <>
        {courseList ? (
          <MultiLevelAccordian
            labels={this.props.labels}
            data={courseList}
            itemsPerPage={10}
            setSelectedCapsule={this.props.setSelectedCapsule}
            course={courseList}
          />


        ) : null}
      </>
    );
  }

}


export default (RefresherCapsuleProduct);

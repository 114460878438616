import React from "react";
import coursetimingicon from "../../../assets/images/coursetimingicon.png";
import coursecapsuleicon from "../../../assets/images/pills.png";
import coursemoduleicon from "../../../assets/images/coursemoduleicon.png";
import utils from "../../../utils/utils";
import playImage from "../../../assets/images/coursePlayIcon.png";

const CourseCardComponent = (props) => {
    console.log(props.labels,props?.capsuleListLength,props.unitAverageTime, "labels")
    return (
        <div>
                            {/* {props.currentPage === "products" && props.currentBelt?
                    <div className="badgeCtn">
                        <div className="badgeWrapper">
                            <img className="outerCircle" src={require(`../../../assets/images/karate/beltoutercircle/${props.currentBelt}.png`)} alt="" style={{ marginRight: "5px" }} />
                            <div className="innerAnimation">
                                <img className="karateAnimation" src={require(`../../../assets/images/karate/Badges/${props.currentBelt}.gif`)} alt="" style={{ marginRight: "5px" }} />

                            </div>

                        </div>
                    </div> : null} */}
            <div className="courseUnitImageContainer" onClick={props.handleCourseClick}>

                {props.currentPage === "products" && props.CourseCompletionStatus === "Not Started" ?
                    <div className="newCtn">
                        {props?.labels?.LBLNEW}
                    </div>
                    :
                    null
                }
                <div className={props.currentPage !== "products" && (props.unitAverageTime === 0 || props.unitAverageTime === null || props.unitAverageTime === undefined) && (props?.capsuleListLength === 0  || props?.capsuleListLength === undefined || props?.capsuleListLength === null) ? "coursenoiconctn" : "courseiconctn"}>
                    {props.currentPage === "products" ?
                        <>
                            <img src={coursemoduleicon} alt="" style={{ marginRight: "5px" }} />
                            <span className="avgtimetext" style={{ marginRight: "10px" }}>
                                {props.totalunits + " " + (localStorage.getItem('site_type') === "Accutrain" || props.isModule && props.isModule === true ? props.labels.LBLMODULES !== undefined ? props.labels.LBLMODULES : "Modules" : props.labels.LBLUNITS !== undefined ? props.labels.LBLUNITS : "Units")}
                            </span>
                        </>
                        :
                        null
                    }
                    {props.unitAverageTime !== 0 && props.unitAverageTime !== null && props.unitAverageTime !== undefined ? (
                        <>
                            <img src={coursetimingicon} alt="" style={{ marginRight: "5px" }} />
                            <span className="avgtimetext" style={{ marginRight: "10px" }}>{utils.convertToHoursAndMinutes(props.unitAverageTime, props.labels)}</span>
                        </>
                    ) : null}
                    {props.currentPage !== "products" && props?.capsuleListLength !== 0 ?
                        <>
                            <img src={coursecapsuleicon} alt="" style={{ marginRight: "5px" }} />
                            <span className="avgtimetext">
                                {(props?.capsuleListLength !== null ? props?.capsuleListLength : 0) + " " + (props.labels.LBLCAPSULES !== undefined ? props.labels.LBLCAPSULES : " capsules")}
                            </span>
                        </>
                        :
                        null
                    }
                </div>
                <div className="overlayCard">
                </div>
                {props.showPlayIcon ?
                    <img src={playImage} alt="" className="playImage" />
                    :
                    null
                }
                <img src={props.unitImage ? props.unitImage : ""} alt='' className="courseUnitImage" />
            </div>
            <div onClick={props.handleCourseDesc}>
                <div className="unitText grid-ellipsis" title={props.unitTitle}>
                    {props.unitTitle}
                </div>
                {props.unitdesc && props.unitdesc !== props.unitTitle ?
                    <div className="descText grid-ellipsis" title={props.unitdesc}>
                        {props.unitdesc}
                    </div>
                    :
                    null
                }
                <div style={{ display: "flex", alignItems: "center", margin: "10px 0px 10px 0px" }}>
                    <div className="progressContainer" style={{width:props.showMoreDetails? "80%" : "100%"}}>
                        <div className="progressBar" style={{ width: props.CourseCompletionStatus === "Completed" ? "100%" : props.CourseCompletionPercentage + "%" }}></div>
                    </div>
                    <div className="progressPercentage">{props.CourseCompletionStatus === "Completed" ? "100%" : props.CourseCompletionPercentage + "%"}</div>

                {props.showMoreDetails ? 
                <div className="moreDetailsCtn" title={props?.labels?.LBLDESCRIPTION}>
                    <i class="fas fa-ellipsis-h" style={{marginLeft:"3px"}}></i>
                    {/* <span class="tooltiptext">{props?.labels?.LBLDESCRIPTION}</span> */}
                </div>
                :
                null
                }
                </div>

            </div>
            {/* <div>
                {props.currentPage === "products" ?
                    <div style={{display:"flex",alignItems:"center"}}>
                        <StartRating
                            readonly={true}
                            size={15}
                            initialValue={4.0}
                        />
                        <span className="productRating">
                         {props.courseFeedback}
                        </span>
                    </div>

                    :
                    null
                }
            </div> */}
        </div>

    );
};

export default CourseCardComponent;
import React, { Component } from "react";
import ReportServices from "../report_services";
import GroupServices from "../../group-list/group_services";
import orderBy from "lodash/orderBy";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import UsersService from "../../user-list/UsersService";
import { connect } from "react-redux";
import Loader from "../../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
import ScCommonServices from "../../../../services/common_services";
import emptyImage from '../../../../assets/images/learnerprogress_emptyst.png'
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import moment from "moment";

let objFilterData = {};
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};

class LCHReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_logo: this.props.company_logo,
      siteType:localStorage.getItem('site_type')=="SafeStart"?1:2, 
      pageSize: 100,
      repos: [],
      open: false,
      loading: true,
      reportloading: false,
      userData: [],
      user_roles: [],
      pageState: "LCC_D1",
      toggleunitstate: {},
      roles: [{ role_id: 1, role_name: "learner" }],

      loadLCC_D1_List: 0,
      loadLCC_D1_List_Groups: 0,
      orgLCC_D1_List: [],
      LCC_D1_List: [],
      LCC_D1_List_Fetched: 0,
      LCC_D1_SummaryData: { records: 0 },

      sel_D1_Data: {},

      LCC_D1_GPH_SelStateId: "1",
      filterDataLoaded: 0,
      coursestatus: [{ "id": "Notstarted", "value": this.props.labels.labels["LBLNOTSTARTED"] },
      { "id": "Inprogress", "value": this.props.labels.labels["LBLINPROGRESS"] },
      { "id": "Completed", "value": this.props.labels.labels["LBLCOMPLETED"] }]
    };
    this.LCC_D1_Filter = React.createRef();
  }

  loadpage(a, x) {
    if (a === "LCC_D1") {
      this.setState({loadLCC_D1_List: 1});
    }
    if (a === "group") {
      this.setState({loadLCC_D1_List_Groups: 1});
    }
    if (
      this.state.loadLCC_D1_List === 1 &&
      this.state.loadLCC_D1_List_Groups === 1
    ) {
      this.setState({
        loading: false,
      });
    }
  }
  getCompanyLogoPath() {
    if (String(this.props.company_logo).indexOf("/images/") == -1) {
      let assetFolder = String(this.props.imageName).split(".");
      assetFolder.pop();
      let postData = {
        filename: this.props.company_logo,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      this.getLogoPath(postData);
    } else {
      this.setState({
        company_aws_logo_path: "",
      });
    }
  }
  getLogoPath(postData) {
    ScCommonServices.getAssyncSignedUrl(postData).then((resultData) => {
      let url = resultData.data;
      let appLogo = process.env.REACT_APP_LOGO;
      fetch(url, {
        retryOn: function (attempt, error, response) {
          if (!response || response.status >= 403) {
            return false;
          }
        },
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            company_aws_logo_path: resultData.data,
          });
        } else {
          this.setState({
            company_aws_logo_path: "",
            company_logo: "/images/" + appLogo
          });
        }
      });
    });
  }
  componentDidMount() {
    objFilterData = {};
    let data = {};
    data.date1 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    data.date2 = new Date(new Date());
    data.sdate1 = this.getDateFormat(data.date1);
    data.sdate2 = this.getDateFormat(data.date2);
    data.offsetTime = this.getOffsetTime();
    this.getFilterDatas();
    let getData = {
      language: this.props.language,
    };
    this.getCompanyLogoPath()
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
        },
        this.loadpage("group", iresponse)
      );
    });
  }
  async getFilterDatas() {
    let data = {};
    data.report = "LCC_D1";
    let group_types = await require("../../../../json_files/group_types.json");
    ReportServices.getFilterDatas(data).then((response) => {
      let xgroup_types = response.result[0];
      let labels;
      let groups = {};
      for (var i = 0; i < xgroup_types.length; i++) {
        if (this.props.language_code === xgroup_types[i].language_code) {
          labels = JSON.parse(xgroup_types[i].labels).group_labels;
        }
      }
      if (labels && labels.length > 0) {
        for (const grouptype of group_types) {
          if (labels[grouptype.group_type_id]) {
            grouptype["lbl_" + grouptype.group_type_id] =
              labels[grouptype.group_type_id]["lbl_" + grouptype.group_type_id];
            grouptype["Active"] = labels[grouptype.group_type_id]["Active"];
            groups[grouptype.group_type_id] = [];
            groups["obj" + grouptype.group_type_id] = {};
          } else {
            grouptype["Active"] = "0";
          }
        }
      }
      let xgroups = response.result[1]; 
      for (var j = 0; j < xgroups.length; j++) {
        if (
          groups[xgroups[j].group_type_id] &&
          !groups["obj" + xgroups[j].group_type_id][xgroups[j].value]
        ) {
          groups["obj" + xgroups[j].group_type_id][xgroups[j].value] = 1;
          groups[xgroups[j].group_type_id].push(xgroups[j]);
        }
      }
      let courses = response.result[2];
      let users = response.result[3];
      let courseunits = response.result[4];
      this.setState(
        {
          filterDataLoaded: 1,
          pageState: "LCC_D1",
          courses: courses,
          userNames: users,
          courseunits: courseunits
        },
        this.loadpage("LCC_D1")
      );
    });
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getLCC_D1_Export(data) {
    data.report = "LCC_D1";
    this.setsvaluefordate(data);
    ReportServices.getLCH_D1_Export(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob)
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName = this.props.labels.labels.LBLHLCEXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }

  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  getLCC_D1_Summary = (xresult, ydata) => {
    //console.log("getLCC_D1_SummaryQQQQQQQQQQQQQQQQQ", ydata);
    let xsummaryData = {
      rushing: {},
      fatigue: {},
      frustration: {},
      complacency: {},
    };
    let grpSummaryData = {
      sum_rushing: 0,
      sum_fatigue: 0,
      sum_frustration: 0,
      sum_complacency: 0,
      dwgh_rushing: {},
      dwgh_fatigue: {},
      dwgh_frustration: {},
      dwgh_complacency: {},
    };
    let summaryData = {
      rushing: [],
      fatigue: [],
      frustration: [],
      complacency: [],
    };
    for (var i = 0; i < xresult.length; i++) {
      let xdate = xresult[i].created_on
        .substr(0, 10)
        .split("-")
        .reverse()
        .join("/");
      if (xsummaryData["rushing"].hasOwnProperty(xdate) === false) {
        xsummaryData["rushing"][xdate] = {};
        xsummaryData["fatigue"][xdate] = {};
        xsummaryData["frustration"][xdate] = {};
        xsummaryData["complacency"][xdate] = {};
      }
      if (
        xsummaryData["rushing"][xdate].hasOwnProperty(xresult[i].rushing) ===
        false
      ) {
        xsummaryData["rushing"][xdate][xresult[i].rushing] = 0;
      }
      xsummaryData["rushing"][xdate][xresult[i].rushing]++;
      grpSummaryData["sum_rushing"]++;

      if (
        xsummaryData["fatigue"][xdate].hasOwnProperty(xresult[i].fatigue) ===
        false
      ) {
        xsummaryData["fatigue"][xdate][xresult[i].fatigue] = 0;
      }
      xsummaryData["fatigue"][xdate][xresult[i].fatigue]++;
      grpSummaryData["sum_fatigue"]++;
      if (
        xsummaryData["frustration"][xdate].hasOwnProperty(
          xresult[i].frustration
        ) === false
      ) {
        xsummaryData["frustration"][xdate][xresult[i].frustration] = 0;
      }
      xsummaryData["frustration"][xdate][xresult[i].frustration]++;
      grpSummaryData["sum_frustration"]++;
      if (
        xsummaryData["complacency"][xdate].hasOwnProperty(
          xresult[i].complacency
        ) === false
      ) {
        xsummaryData["complacency"][xdate][xresult[i].complacency] = 0;
      }
      xsummaryData["complacency"][xdate][xresult[i].complacency]++;
      grpSummaryData["sum_complacency"]++;
    }
    var getcount = function (xday, ydays) {
      let xdt = xday.split("/");
      let xdays =
        parseInt(xday.split("/")[2]) * 1000 +
        getDays(
          new Date(parseInt(xdt[2]), parseInt(xdt[1]) - 1, parseInt(xdt[0]))
        );

      return xdays - ydays;
    };
    var getDays = function (xdate) {
      var now = new Date(xdate.getTime());
      var start = new Date(now.getFullYear(), 0, 0);
      var diff =
        now -
        start +
        (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      return day;
    };

    let startDay = ydata.date1.getFullYear() * 1000 + getDays(ydata.date1);
    for (let xstate in xsummaryData) {
      for (let xday in xsummaryData[xstate]) {
        var dtotal = 0;
        for (let xrate in xsummaryData[xstate][xday]) {
          //console.log("[xstate][xday][xrate]",xstate,xday,xrate)
          let obj = {
            x: getcount(xday, startDay),
            y: xrate,
            org_value: xsummaryData[xstate][xday][xrate],
            day: xday,
          };
          summaryData[xstate].push(obj);
          dtotal = dtotal + xsummaryData[xstate][xday][xrate];
        }
        grpSummaryData["dwgh_" + xstate][xday] =
          dtotal / grpSummaryData["sum_" + xstate];
      }
      grpSummaryData["mwgh_" + xstate] = 0;
    }
    //set weight value
    for (let xstate in summaryData) {
      for (i = 0; i < summaryData[xstate].length; i++) {
        summaryData[xstate][i].weight =
          summaryData[xstate][i].org_value *
          grpSummaryData["dwgh_" + xstate][summaryData[xstate][i].day];
        grpSummaryData["mwgh_" + xstate] =
          grpSummaryData["mwgh_" + xstate] > summaryData[xstate][i].weight
            ? grpSummaryData["mwgh_" + xstate]
            : summaryData[xstate][i].weight;
      }
    }
    for (let xstate in summaryData) {
      for (i = 0; i < summaryData[xstate].length; i++) {
        summaryData[xstate][i].value = Math.round(
          (summaryData[xstate][i].weight / grpSummaryData["mwgh_" + xstate]) *
          100,
          0
        );
      }
    }
    //console.log("sssssssssssssummaryData",summaryData)
    return summaryData;
  };
  getLCC_D1_List(data) {
    this.setState({
      reportloading: true,
      LCC_D1_List_Fetched: 1,
    });
    data.report = "LCC_D1";

    //console.log("getLCC_D1_List data", data);
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;
    data.timeZoneOffset = (new Date()).getTimezoneOffset()
    this.setsvaluefordate(data);
    ReportServices.getLCH_D1_List(data).then((response) => {
      //console.log("getLCC_D1_List!!!!!!!!11", response)
      let result = response.result[9];

      let xsummaryData = {};
      //xsummaryData.graphData = this.getLCC_D1_Summary(result, data)
      xsummaryData.records = result.length;
      let pageCounts = (result.length / this.state.pageSize) + 1
      let pageIndex = 1
      let xresult = result.slice(0, pageIndex * this.state.pageSize)

      this.setState(
        {
          pageState: "LCC_D1",
          LCC_D1_List_Fetched: 1,
          orgLCC_D1_List: result,
          LCC_D1_List: xresult,
          pageIndex: pageIndex,
          pageCounts: pageCounts,
          //LCC_D1_SummaryData: xsummaryData,
          sel_D1_Data: data,
          reportloading: false,
        },
        this.applyFilterCondition(result, data),
        REFS.F1.onExportDone()
      );
    });
  }
  applyFilterCondition = (orgresult, data) => {
    if (objFilterData.availableLCC_D1Fields) {
      let xobj = objFilterData.availableLCC_D1Fields;
      let result = [];
      //console.log("applyFilterCondition!!!!!!!", xobj);
      for (var i = 0; i < orgresult.length; i++) {
        //if (this.checkFilterCondition(xobj, orgresult[i]) === true) {
        result.push(orgresult[i]);
        //}
      }
      this.setState(
        {
          LCC_D1_List: result,
        },
        this.loadpage("LCC_D1")
      );
    } else {
      let result = orgresult;
      this.setState(
        {
          LCC_D1_List: result,
        },
        this.loadpage("LCC_D1")
      );
    }
  };
  showLCC_D1 = () => {
    //console.log("showLCC_D1")
    this.setState({
      pageState: "LCC_D1",
    });
  };

  getGroupTypes = async () => {
    GroupServices.getGroupTypeLabels().then((response) => {
      //console.log("response!!!!!!!!!!!!!1", response);
      this.setState(
        {
          groupTypeNames: response,
        },
        this.loadpage("group", response)
      );
    });
  };
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  toggletree = (xid) => {
    let xtoggleunitstate = this.state.toggleunitstate;
    xtoggleunitstate[xid] = !(xtoggleunitstate[xid] || false);
    //alert(xid + "-" + xtoggleunitstate[xid])
    this.setState({
      toggleunitstate: xtoggleunitstate,
    });
  };
  getLCC_D1_Columns = (x) => {
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
    let unitstaus, unitname = this.state.siteType==1?this.props.labels.labels.LBLUNITMODNAME:this.props.labels.labels.LBLMODULENAME;
    unitstaus = this.state.siteType==1?this.props.labels.labels.LBLUNITSTATUS:this.props.labels.labels.LBLMODULESTATUS;
    unitname = unitname?unitname:"Module Name";
    unitstaus = unitstaus?unitstaus:"Module Status";
    let columns = [
      {
        name: this.props.labels.labels.LBLRESETDATE,
        selector: "reset_date",
        filter: {
          type: "date", showField: true, checked: true, condition: {
            name: "Date",
            operator: "LAST7DAYS",
            value: xrArr.slice(),
            operatorText: this.props.labels.labels.LBLLAST7DAYS,
          },
        },
        exportcell: "_reset_date_format",
        exportcellproperty: { order: 100 },
        sortable: true,
        compact: true,
        cell: (row) => {
          return row.reset_date_format;
        },
      },
      {
        name: this.props.labels.labels.LBLFULLNAME,
        selector: "first_name",
        filter: {
          type: "string",
          showField: true,
          checked: true,
          render: "server",
        },
        filterData: this.state.userNames,
        sortable: true,
        compact: true,
        exportcell: "Full_Name",
        exportcellproperty: { order: 200 },
        cell: (row) => {
          return (
            <div className="grid-ellipsis" title={row.first_name + " " + row.last_name}>
              {row.first_name + " " + row.last_name}
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOURSENAME,
        selector: "name",
        filter: { type: "string", showField: true, checked: true },
        filterData: this.state.courses,
        width: "20%",
        exportcell: "_name",
        exportcellproperty: { order: 300 },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div className="grid-ellipsis" title={row.name}>
              {row.name}
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOURSESTATUS,
        selector: "product_status",
        filter: { type: "string", showField: false, checked: true },
        filterData: this.state.coursestatus,
        exportcell: "_product_status",
        exportcellproperty: { order: 400 },
        sortable: true,
        compact: true,
        cell: (row) => {
          return <span className="rle-brdr" title={row.product_status}>{
            this.props.labels.labels["LBL" + row.product_status.toUpperCase()]
          }
          </span>
        },
      },
      {
        name: unitname,
        selector: "unitname",
        filter: { type: "string", showField: true, checked: true },
        filterData: this.state.courseunits,
        exportcell: "_unitname",
        exportcellproperty: { order: 500 },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <>
              <div className="grid-ellipsis" title={row.actunitscount > 1 ? row.unitname : ""}>
                {row.actunitscount > 1 ? row.unitname : ""}
              </div>
            </>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLETEDTHROUGH,
        selector: "training_type",
        filter: { type: "string", showField: true, checked: false },
        exportcell: "_training_type",
        exportcellproperty: { order: 600 },
        exportlabels: {
          "LBLCLASSROOM": this.props.labels.labels["LBLCLASSROOM"],
          "LBLONLINE": this.props.labels.labels["LBLONLINE"],
          "LBLONLINEANDCLASSROOM": this.props.labels.labels["LBLONLINEANDCLASSROOM"]
        },
        sortable: true,
        compact: true,
        cell: (row) => {
          return this.props.labels.labels["LBL" + row.training_type.toUpperCase()];
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLETIONDATE,
        selector: "completed_date",
        filter: { type: "date", showField: true, checked: false },
        exportcell: "_completed_date_format",
        exportcellproperty: { order: 700 },
        sortable: true,
        compact: true,
        cell: (row) => {
          return row.completed_date_format;
        },
      },
      {
        name: this.props.labels.labels.LBLSTATUS,
        selector: "unitstatus",
        filter: { type: "string", showField: true, checked: true },
        filterData: this.state.coursestatus,
        exportcell: "_status",
        exportcellproperty: { order: 800 },
        sortable: true,
        compact: true,
        cell: (row) => {
          return this.props.labels.labels["LBL" + row.status.toUpperCase()];
        },
      },
      {
        name: this.props.labels.labels.LBLACTIVE || 'Active',
        selector: "act_st",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLACTIVE}
              </label>
              <span className="rle-brdr" title={row.act_st} style={{ color: row.act_st === "Active" ? "#2DA703" : "#D90000" }}>{
                this.props.labels.labels["LBL" + row.act_st.toUpperCase()]
              }</span>
            </div>
          );
        },
      },
    ];
    let xcolumns = [];
    let response = this.state.groupTypeNames || [];
    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          filter: { type: "string", showField: false, checked: true },
          filterData: this.state.groups[response[i].group_type_id],
          exportcellproperty: { order: (200 + i) },
          sortable: true,
          compact: true
        });
      }
    }

    columns.splice.apply(columns, [2, 0].concat(xcolumns));
    //alert(columns.length)
    return columns; //JSON.parse(JSON.stringify(columns))
  };

  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    //console.log("getFilterColumns", mode, cols)
    let xcols = [];
    //console.log("getFilterColumns-111", mode, xcols)
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "LCC_D1") {
      let xobj = objFilterData.availableLCC_D1Fields;
      //console.log("getFilterColumns-xobj", xobj)
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    }
    //console.log("getFilterColumns-222", mode, xcols, cols)
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      //console.log(row[field])
      if (row[field] && row[field] !== 1 && row[field] !== 0) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  setsvaluefordate = (data) => {

    for (let j = 0; j < data.filtercolumns.length; j++) {
      if (data.filtercolumns[j].filter.type === "date" && data.filtercolumns[j].filter.condition) {
        //console.log("setsvaluefordate", data.filtercolumns[j].filter)
        let dt1, dt2;
        let xcondition = JSON.parse(JSON.stringify(data.filtercolumns[j].filter.condition))
        if (typeof xcondition.value == "string") {
          let arrdt = xcondition.value.split("T")[0].split("-");
          let arrdt2 = xcondition.value.split("T")[1].split(":");
          xcondition.xvalue = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2],
            arrdt2[0],
            arrdt2[1],
            0, 0
          );
        } else {
          if (typeof xcondition.value == "object" && xcondition.value[0] && typeof xcondition.value[0] == "string") {
            xcondition.xvalue = []
            //console.log("xcondition.value[0]", xcondition.value[0])
            let arrdt = xcondition.value[0].split("T")[0].split("-");
            let arrdt2 = xcondition.value[0].split("T")[1].split(":");
            xcondition.xvalue[0] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
          if (typeof xcondition.value == "object" && xcondition.value[1] && typeof xcondition.value[1] == "string") {
            let arrdt = xcondition.value[1].split("T")[0].split("-");
            let arrdt2 = xcondition.value[1].split("T")[1].split(":");
            xcondition.xvalue[1] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
        }
        if (typeof xcondition.value == "object" && xcondition.value[0] && xcondition.value[1]) {
          dt1 = new Date(xcondition.xvalue[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        } else {
          dt1 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        }
        data.filtercolumns[j].filter.condition["svalue"] = [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
      }
    }
  }

  onExport = (type, id, conditions) => {
    //console.log("onExport", type, id, conditions)
    if (id === "F1") {
      this.setFilterFields("F1", conditions);

      let data = { type: type, columns: objFilterData.availableLCC_D1Fields };
      data.dateformat = this.props.dateformat;
      data.timeformat = this.props.timeformat;
      data.filtercolumns = conditions;
      if (type === "web") {
        this.getLCC_D1_List(data);
      } else {
        this.getLCC_D1_Export(data);
      }

    }
  };
  setFilterFields = (id, conditions) => {
    //console.log("!!!@@@@setFilterFields", id, conditions)
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportcellproperty) {
        xobj[conditions[i].name].exportcellproperty =
          conditions[i].exportcellproperty;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableLCC_D1Fields = xobj;
    }
  };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions, index) => {
    //console.log("onFilterChangeeeeee2", conditions);
    this.setFilterFields("F1", conditions);
    let data = { type: "web", columns: objFilterData.availableLCC_D1Fields };
    data.filtercolumns = conditions;
    this.getLCC_D1_List(data);
    //
  };

  RunReport = () => {
    REFS.F1.onRunReport();
  };
  handleClose = () => {
    this.setState({ showCert: false });
    document.getElementById("root").className = "";
  };
  handlePrint = (xid) => {
    if (xid === "PRINTDIV") {
      document.getElementById("hideprint").style.display = "none"
    }

    this.setState({ showPrint: false }, function () {
      var print_div = document.getElementById(xid);
      var newWindow = window.open();
      newWindow.document.write("<!DOCTYPE html");
      newWindow.document.write("<html>");
      newWindow.document.write("<head>");
      newWindow.document.write("</head>");
      newWindow.document.write("<body>");
      newWindow.document.write(print_div.innerHTML);
      newWindow.document.write("</body>");
      newWindow.document.write("</html>");
      newWindow.document.write("<script>window.onload = function(e){window.print();window.close();}</script>");
      // Append the new container to the body of the new window
      const parentHead = window.document.querySelector("head").childNodes;
      parentHead.forEach(item => {
        newWindow.document.head.appendChild(item.cloneNode(true)); // deep copy
      })
      newWindow.document.close();
      newWindow.focus();
      if (xid === "PRINTDIV") {
        document.getElementById("hideprint").style.display = ""
      }
    });
  };
  render() {
    const {
      LBLRUNREPORT,
      LBLLOADINGREPORT,
      LBLRECORDS,
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
    } = this.props.labels.labels;

    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };

    try {
      if (this.state.pageState === "LCC_D1") {
        let columns = this.getLCC_D1_Columns();
        let filterFields = this.getFilterColumns("LCC_D1", columns);
        let resetfilterFields = this.getResetFilterColumns("LCC_D1", columns);
        for (let i = 0; i < columns.length; i++) {
          for (let j = 0; j < filterFields.length; j++) {
            if (
              filterFields[j] &&
              columns[i] &&
              filterFields[j].name === columns[i].name &&
              filterFields[j].filter.showField === false
            ) {
              columns.splice(i, 1);
              i--;
            }
          }
        }
        objFilterData.orgavailableLCC_D1Fields = this.getFilterColumns(
          "LCC_D1",
          this.getLCC_D1_Columns()
        );
        let data = this.state.LCC_D1_List;
        return (
          <div className="reportsCtn">
            <div className="mob-pad">
              <div >
                <h3 className="page-title">{this.props.labels.labels.LBLLEARNERCOURSEHISTORY}</h3>
              </div>
              <hr className="head-line" />
              <div id="accordion" className="row rptlst-row">
                {this.state.loading ? (
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div>
                      <div>
                        <div>
                          <div>
                            <div className="row box ml-1">
                              <Skeleton height={46} />
                            </div>
                            <div className="row col-sm-12 prowfilterbox"></div>
                          </div>
                          <div>
                            <h3>
                              <Skeleton height={21} />
                            </h3>
                            <h2>
                              <Skeleton height={26} />
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div className="print_hide">
                      {this.state.loading === false &&
                        this.state.filterDataLoaded === 1 &&
                        filterFields.length > 3 ? (
                        <div>
                          <CustomFilter
                            availableFields={filterFields}
                            selectedFields={filterFields}
                            orgAvailableFields={resetfilterFields}
                            onFilterChange={this.onFilterChange}
                            onExport={this.onExport}
                            id="F1"
                            onRef={(ref) => (REFS.F1 = ref)}
                            labels={this.props.labels.labels}
                            server={true}
                            reportName={
                              this.props.labels.labels.LBLLEARNERCOURSEHISTORY
                            }
                            companyName={this.props.company_name}
                            showFieldFilter={true}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    {this.state.LCC_D1_List_Fetched === 0 ? (
                      <div style={{ textAlign: "center", padding: "30px" }}>
                        <button
                          onClick={this.RunReport.bind(this)}
                          id="btnGroupDrop12"
                          type="button"
                          className="button-style primary-btn"
                        >
                          {/* <i className="fas fa-table" aria-hidden="true"></i>  */}
                          {LBLRUNREPORT}
                        </button>
                      </div>
                    ) : (
                      <>
                        {this.state.LCC_D1_List_Fetched !== 0 &&
                          this.state.reportloading === false ? (
                          <>
                            <div className="bottomborder print_hide">
                              <span className="subHeader" style={{ display: "inline-block" }}>
                                <h4>
                                  {LBLRECORDS} : {this.state.orgLCC_D1_List.length}
                                </h4>
                              </span>
                            </div>
                            {this.state.LCC_D1_List.length === 0 ? (
                              <div
                                style={{ textAlign: "center", padding: "30px" }}
                              >
                                <EmptyState image={emptyImage} text2={this.props.labels.labels.LBLLEARNERCRSPROGEXPTYTXT} />
                              </div>
                            ) : (
                              <>
                                <DataTable
                                  columns={columns}
                                  data={data}
                                  className="datatable-list"
                                  sortFunction={this.customSort}
                                  pagination
                                  noDataComponent={<EmptyState image={emptyImage} text2={this.props.labels.labels.LBLLEARNERCRSPROGEXPTYTXT} />}
                                  paginationComponentOptions={paginationComponentOptions}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Loader></Loader> {LBLLOADINGREPORT}
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return <div>No Data for {this.state.pageState}</div>;
      }
    } catch (e) {
      return <div>No Data for : {this.state.pageState}</div>;
    }

  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(LCHReport);

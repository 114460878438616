const checkboxes = {
    "course_id": "Course05hml",
    "card_id": "card05",
    "card_name": "Risk Patterns",
    "header_text": "LBLRISKQUESHDR",
    "cards": [
        {
            "ques_header": "LBLRISKQUES1",
            "type": "option_2",
            "option_contents": [
                {
                    "options": [
                        {
                            name: 'LBLFAMILYMEMBER',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLFRIEND',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLSTRANGER',
                            correctAnswer: true
                        }]
                }
            ]
        },
        {
            "ques_header": "LBLRISKQUES2",
            "type": "option_2",
            "option_contents": [
                {
                    "options": [
                        {
                            name: 'LBLATHOME',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLONROAD',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLONTV',
                            correctAnswer: true
                        }
                    ]
                }
            ]
        },
        {
            "ques_header": "LBLRISKQUES3",
            "type": "option_3",
            "option_contents": [
                {
                    "options": [
                        {
                            name: 'LBLRUSHING',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLFRUSTRATION',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLFATIGUE',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLCOMPLACENCY',
                            correctAnswer: true
                        }
                    ]
                },
                {
                    "options": [
                        {
                            name: 'LBLEYENOTONTASK',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLMINDNOTTASK',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLLINEOFFIRE',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLBALANCETRACGRIP',
                            correctAnswer: true
                        }
                    ]
                }
            ]
        },
        {
            "ques_header": "LBLRISKPATTERNDETLS",
            "type": "textarea",
            "option_contents": [
                {
                    "details": ""
                }
            ]
        },
        {
            "ques_header": "LBLACTIONTAKENTOCORRECT",
            "type": "option_4",
            "option_contents": [
                {
                    "options": [
                        {
                            name: 'LBLATHOME',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLONROAD',
                            correctAnswer: true
                        },
                        {
                            name: 'LBLONTV',
                            correctAnswer: true
                        }
                    ]
                }
            ]
        }
    ]
};
export default checkboxes;
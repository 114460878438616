import React from "react";
import { Link } from "react-router-dom";
import "../managechannel/managechannel.scss";
import ProfileImageDisplay from "../../components/profileimage/profileimage";
import { event } from "jquery";

class ChannelRowItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChannelMenu: 0,
    };
    this.moreActionRef = React.createRef();
  }
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.channelsLanguageData &&
      this.props.channelsLanguageData[paramLBL]
        ? this.props.channelsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  moreActionClickHandler = (evt) => {
    this.setState(
      {
        showChannelMenu: this.state.showChannelMenu === 1 ? 0 : 1,
      },
      () => {
        document.addEventListener(
          "click",
          this.channelMenuItemOustSideClickHandler
        );
      }
    );
  };
  channelMenuItemOustSideClickHandler = (evt) => {
    let elements = evt.target;
    if (elements && !elements.classList.contains("ssi-manch-morePopupMenuItem") && !this.moreActionRef.contains(elements)) {
      document.removeEventListener(
        "click",
        this.channelMenuItemOustSideClickHandler
      );
      this.setState({
        showChannelMenu: 0,
      });
    }
  };
  channelEditHandler = (evt) => {
    this.setState({ showChannelMenu: 0 });
    this.props.editChannelItem(this.props.channelItem);
  };
  channelDeleteHandler = (evt) => {
    this.setState({ showChannelMenu: 0 });
    this.props.deleteChannelItem(this.props.channelItem);
  };
  channelUserEnrollHandler = (evt) => {
    this.setState({ showChannelMenu: 0 });
    this.props.userEnrollForChannel(this.props.channelItem);
  };
  channelGroupEnrollHandler = (evt) => {
    this.setState({ showChannelMenu: 0 });
    this.props.groupEnrollForChannel(this.props.channelItem);
  };
  naivateToModerateChannelHandler = (evt) => {
    localStorage.setItem("ssiselectedchannelid", this.props.channelItem._id);
  };

  render() {
    return (
      <div
        className="ssi-manch-channelStrip"
        id={`${this.props.channelItem._id}_mangeChannelItem`}
      >
        <div className="ssi-manch-channelStripWrapper">
          <div className="ssi-manch-channelImage">
            <ProfileImageDisplay
              key={`${this.props.channelItem._id}manageChannelProfile`}
              imageName={this.props.channelItem.channel_profile_image}
              firstLetterDisplay={""}
              placeHolderType={"channel"}
            />
          </div>
          {/* <div className={(this.props.channelItem.active_status == 0 || this.props.channelItem.channel_type == "default") ? "ssi-manch-channelDescription disable" : "ssi-manch-channelDescription"}> */}
          <div className="ssi-manch-channelDescription">
            <Link to="/schome/watch-channels" className="channelLink">
              <div
                className="channelName"
                onClick={this.naivateToModerateChannelHandler}
              >
                <span>{this.props.channelItem.channel_name}</span>
              </div>
            </Link>
            <div className="channelDetails">
              <span className="count">{`${
                this.props.channelItem.followers
              } ${this.getResourceFromLabels("LBLMEMBERS")}`}</span>
              <span className="status">
                {this.props.channelItem.active_status === 1
                  ? this.getResourceFromLabels("LBLACTIVE")
                  : this.getResourceFromLabels("LBLINACTIVE")}
              </span>
            </div>
          </div>
          <div className="ssi-manch-channelAction">
            <div
              className={
                this.props.channelItem.channel_type === "default"
                  ? "morebtn disable"
                  : "morebtn"
              }
              ref={(ref)=> this.moreActionRef = ref }
              onClick={this.moreActionClickHandler}
              title={this.getResourceFromLabels("LBLACTION")}
            >
              <span className="actionIcon"></span>
            </div>
            {this.state.showChannelMenu === 1 ? (
              <div className="ssi-manch-morePopupContainer">
                <div className="ssi-manch-morePopupWrapper">
                  <div
                    className="ssi-manch-morePopupMenuItem edit"
                    onClick={this.channelEditHandler}
                  >
                    <div className="menuIcon">
                      <span className="editIcon"></span>
                    </div>
                    <div className="menuText">
                      <span>{this.getResourceFromLabels("LBLEDIT")}</span>
                    </div>
                  </div>
                  {
                    <React.Fragment>
                      <div
                        className="ssi-manch-morePopupMenuItem users"
                        onClick={this.channelUserEnrollHandler}
                      >
                        <div className="menuIcon">
                          <span className="fa fa-user"></span>
                        </div>
                        <div className="menuText">
                          <span>
                            {this.getResourceFromLabels("LBLMANAGEUSERS")}
                          </span>
                        </div>
                      </div>
                      <div
                        className="ssi-manch-morePopupMenuItem group"
                        onClick={this.channelGroupEnrollHandler}
                      >
                        <div className="menuIcon">
                          <span className="fa fa-users"></span>
                        </div>
                        <div className="menuText">
                          <span>
                            {this.getResourceFromLabels("LBLMANAGEGROUPS")}
                          </span>
                        </div>
                      </div>
                      <Link
                        to="/schome/watch-channels"
                        className="ssi-manch-popupMenuLink"
                      >
                        <div
                          className="ssi-manch-morePopupMenuItem moderateChannel"
                          onClick={this.naivateToModerateChannelHandler}
                        >
                          <div className="menuIcon">
                            <span className="channelIcon"></span>
                          </div>
                          <div className="menuText">
                            <span>
                              {this.getResourceFromLabels(
                                "LBLMODERATECHANNELS"
                              )}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </React.Fragment>
                  }
                  <div
                    className="ssi-manch-morePopupMenuItem delete"
                    onClick={this.channelDeleteHandler}
                  >
                    <div className="menuIcon">
                      <span className="deleteIcon"></span>
                    </div>
                    <div className="menuText">
                      <span>{this.getResourceFromLabels("LBLDELETE")}</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="ssi-manch-channelSpliter"></div>
        </div>
      </div>
    );
  }
}

export default ChannelRowItem;

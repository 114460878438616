import React from "react";
import Skeleton from "react-loading-skeleton";

let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const UnitListingSkeleton = () => {

    return (
        skel.map((i) => (
            <div
                className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 img-card-box"
                style={{ marginBottom: "20px" }}
                id={"row-" + i}
                key={"row-" + i}
            >
                <div className="card img-cnt-card imgCtnLoading">
                    <div className="imgHold">
                        <Skeleton height={190} />
                    </div>
                    <div className="card-body imgCtnLoading col-xl-12 col-sm-12 col-xs-12" style={{ padding: "0.5rem" }}>
                        <p>
                            <Skeleton height={24} />
                        </p>
                        <h5>
                            <Skeleton height={15} />
                        </h5>
                        <div>
                            <Skeleton height={3} />
                        </div>
                    </div>
                </div>
            </div>
        ))
    );
};

export default UnitListingSkeleton;
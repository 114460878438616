import React, {
    useState,
    useImperativeHandle,
    forwardRef,
} from 'react';
import BuildQuestionText from "../elements/BuildQuestionText";
import BuildRange from '../elements/BuildRange';
const SliderBoard = forwardRef((props, ref) => {

    const [updatedJson, setUpdatedJson] = useState(props.content);

    useImperativeHandle(ref, () => ({
        getAnswerDetails: () => {
            return updatedJson;
        },
    }));

    const handleUpdatedJson = (value, nextStatus,textIndex) => {

        let newArr = [...updatedJson];
        newArr[textIndex].text = value;
        setUpdatedJson(newArr)
        props.handleNextEnabled(nextStatus);
      }
    return (
        <div>
            {updatedJson && updatedJson.map((item, index) => (
                item.type === "question_txt" ? (
                    <BuildQuestionText key={index} text={item.text} currentIndex = {props.currentIndex} labels = {props.labels}/>
                ) : (
                    null
                )
            ))}

            {updatedJson && updatedJson.map((item, index) => (
                item.type === "range" ? (
                    <BuildRange key={index} id={item.sid} text = {item.text} handleUpdatedJson={(value, nextStatus) =>handleUpdatedJson(value, nextStatus, index)}/>
                ) : (
                    null
                )
            ))}
        </div>
    );
});

export default SliderBoard;
import React, { useState, useEffect } from "react";
import { Switch, withRouter, Route, useRouteMatch } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";
import TranslatedRoutes from "../translator/TranslatedRoutes";
import Dashboard from "../employee/pages/dashboard/Dashboard";
import GlobalSettings from "../admin/pages/settings/GlobalSettings";
import RiskPattern from "../admin/pages/riskpattern/RiskPattern";
import GroupList from "../admin/pages/group-list/GroupList";
import Users from "../admin/pages/user-list/Users";
import ScServices from "../services/common_services";
import Not_Found from "../shared/Not_Found";
import Login from "../login/Login";
import SteercomReports from "../admin/pages/steeringcommittee/components/steercom_reports";
import TeamSetup from "../admin/pages/steeringcommittee/components/Team_Setup";
import successfactors from "../admin/pages/steeringcommittee/components/success_factors";
import successfactorplan from "../admin/pages/steeringcommittee/components/success_factor_plan";
import steercomdashboard from "../admin/pages/steeringcommittee/components/steercom_dashboard";
import steercommaillanding from "../admin/pages/steeringcommittee/components/steercom_mail_landing";
import steercomuserdashboard from "../admin/pages/steeringcommittee/components/steercom_user_dashboard";
import RateState from "../admin/pages/rate_your_state/RateState";
import Feed from "../ssifeeds/feed";
import Products from "../employee/pages/products/Products";
import Resources from "../employee/pages/resources/Resources";
import Learner_Progress from "../admin/pages/reports/report_types/Learner_Progress";
import Learner_Course_Progress from "../admin/pages/reports/report_types/Learner_Course_Progress";
import RateYourState from "../admin/pages/reports/report_types/RateYourState";
import RateYourStateConversation from "../admin/pages/reports/report_types/RateYourStateCoversation";
import Profile from "../admin/pages/profile/Profile";
import UnitListingPage from "../employee/pages/products/UnitListingPage";
import NearMiss from "../admin/pages/nearmiss/NearMiss";
import NearMissReport from "../admin/pages/nearmiss/NearMissReport";
import SafeStartSurvey from "../admin/pages/survey/SafeStartSurvey";
import CapsuleList from "../employee/pages/products/CapsuleList";
import SteerComTaskSetup from "../admin/pages/steeringcommittee/steercomtasksetup";
import Learner_Course_History from "../admin/pages/reports/report_types/Learner_Course_History";
import Learner_Course_Certificates from "../admin/pages/reports/report_types/Learner_Course_Certificates";
import Safe_Start_Survey_Report from "../admin/pages/reports/report_types/SafeStartSurveyReportSummary";
import SafeStartSurveyReportSummary from "../admin/pages/reports/report_types/SafeStartSurveyReportSummary";
import RefCapsCompletion from "../admin/pages/reports/report_types/RefresherCapsuleCompletion";
import LCS from "../admin/pages/reports/report_types/lcs";
import ToolBox from "../admin/pages/toolbox/toolbox";
import UnitDescription from "../employee/pages/products/UnitDescription";
let data = {
  "/userlist": {
    service: "Users",
    Component: Users,
    comp_name: "users",
  }, 
  "/group-list": {
    service: "Groups",
    Component: GroupList,
    comp_name: "groups", 
  },
  "/steercomtasksetup": {
    service: "Steercom",
    Component: SteerComTaskSetup,
    comp_name: "steercomdashboard",
  },
  "/steeringcommittee": {
    service: "Steercom",
    Component: TeamSetup,
    comp_name: "steercomdashboard",
  },
  "/steercomreports": {
    service: "Steercom",
    Component: SteercomReports,
    comp_name: "steercomreports",
  },
  "/successfactors": {
    service: "Steercom",
    Component: successfactors,
    comp_name: "steercomdashboard",
  },
  "/successfactorplan": {
    service: "Steercom",
    Component: successfactorplan,
    comp_name: "steercomdashboard",
  },
  "/steercomdashboard": {
    service: "Steercom",
    Component: steercomdashboard,
    comp_name: "steercomdashboard",
  },
  "/steercomuserdashboard": {
    service: "Steercom",
    Component: steercomuserdashboard,
    comp_name: "steercomuserdashboard",
  },
  "/steercommaillanding": {
    service: "Steercom",
    Component: steercommaillanding,
    comp_name: "steercommaillanding",
  },
  "/dashboard": {
    service: "Steercom",
    Component: Dashboard,
    comp_name: "dashboard",
  },
  "/globalsetting": {
    service: "Steercom",
    Component: GlobalSettings,
    comp_name: "globalsettings",
  },
  "/riskpattern": {
    service: "Steercom",
    Component: RiskPattern,
    comp_name: "riskpattern",
  },
  "/feeds": {
    service: "Steercom",
    Component: Feed,
    comp_name: "dashboard",
  },
  "/rate-state": {
    service: "Steercom",
    Component: RateState,
    comp_name: "dashboard",
  },
  "/products": {
    service: "Steercom",
    Component: Products,
    comp_name: "dashboard",
  },
  "/resources": {
    service: "resources",
    Component: Resources,
    comp_name: "dashboard",
  },
  "/reports/learner_progress": {
    service: "reports",
    Component: Learner_Progress,
    comp_name: "dashboard",
  },
  "/reports/learner_course_progress": {
    service: "reports",
    Component: Learner_Course_Progress,
    comp_name: "dashboard",
  },
  "/reports/rate_your_state": {
    service: "reports",
    Component: RateYourState,
    comp_name: "dashboard",
  },
  "/reports/rys_conversation": {
    service: "reports",
    Component: RateYourStateConversation,
    comp_name: "",
  },
  "/nearmiss": {
    service: "nearmiss",
    Component: NearMiss,
    comp_name: "nearmiss",
  },
  "/nearmissreport": {
    service: "nearmiss",
    Component: NearMissReport,
    comp_name: "nearmiss",
  },
  "/reports/lch": {
    service: "reports",
    Component: Learner_Course_History,
    comp_name: "",
  },
  "/reports/learner_course_certificates": {
    service: "reports",
    Component: Learner_Course_Certificates, 
    comp_name: "",
  },
  "/reports/safe_start_survey_report": {
    service: "reports",
    Component: SafeStartSurveyReportSummary,
    comp_name: "",
  },
  "/reports/rcs": {
    service: "reports",
    Component: RefCapsCompletion,
    comp_name: "",
  },
  "/reports/lcs": {
    service: "reports",
    Component: LCS,
    comp_name: "",
  },
  "/toolbox": {
    service: "toolbox",
    Component: ToolBox,
    comp_name: "toolbox",
  },
};

const RouteList = (props) => {
  let { url } = useRouteMatch();
  return (
    <Switch>
      {props.access &&
        props.access.map((item, i) => {
          if (item.route_link && !item.childrens && !item.external_links) {
            return (
              <TranslatedRoutes
                path={`${url}` + item.route_link}
                allow={1}
                permission={item.permission}
                {...props}
                component={data[item.route_link].Component}
                comp_name={item.route_link.substring(1)}
              />
            );
          } else if (item.childrens) {
            return item.childrens.map((child_item) => {
              if (data[child_item.route_link]) {
                return (
                  <TranslatedRoutes
                    path={`${url}` + child_item.route_link}
                    comp_name={child_item.route_link.substring(1)}
                    allow={1}
                    permission={child_item.permission}
                    {...props}
                    component={data[child_item.route_link].Component}
                  />
                );
              } else {
                console.log(child_item);

                return null;
              }
            });
          }
        })}
      <TranslatedRoutes
        path={`${url}/profile`}
        comp_name={"profile"}
        {...props}
        component={Profile}
      />
      <TranslatedRoutes
        path={`${url}/product-lists`}
        comp_name={"products"}
        {...props}
        component={UnitListingPage}
      />
      <TranslatedRoutes
        path={`${url}/safestart-survey`}
        comp_name={"safestartsurvey"}
        {...props}
        component={SafeStartSurvey}
      />
      <TranslatedRoutes
        path={`${url}/capsule-list`}
        comp_name={"products"}
        {...props}
        component={CapsuleList}
      />
      <TranslatedRoutes
        path={`${url}/unit-detail`}
        comp_name={"products"}
        {...props}
        component={UnitDescription}
      />
      <TranslatedRoutes
        path={`${url}/toolbox`}
        comp_name={"toolbox"}
        {...props}
        component={ToolBox}
      />
      <Route path="*">
        {" "}
        <Not_Found />{" "}
      </Route>
    </Switch>
  );
};

function IAMRoutes(props) {
  const [access, setAccess] = useState(props.access);
  let { url } = useRouteMatch();

  useEffect(() => {
    if ($(window).width() <= 991) {
      $("#sidenav").css({ display: "none" });
      $(".main-content").css({ marginLeft: "0", transition: "0s" });
      $("#side-backdrop").css({ display: "none" });
    }
    if (!access && props.role) {
        console.log("get accesss from i am routes",props.role);
        ScServices.access_privileges(props.role).then(response => {
            // console.log("access", response)
            setAccess(response)
        })
    }
  }, [access]);
  if (access) {
    console.log("url", url);
    return (
      <Switch>
        <RouteList access={access} {...props}></RouteList>
        <Route exact path="/" component={Login} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path="/" component={Login} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.user_info_reducer.user_info.language_code,
    role: state.user_info_reducer.user_info.role_id,
    loaded: state.user_info_reducer.user_info.loaded,
    //access: state.access_reducer.access ? state.access_reducer.access.access : null,
  };
};
export default connect(mapStateToProps, null)(withRouter(IAMRoutes));

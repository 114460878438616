import React from "react";
import styles from "./Transition.module.scss";

const TransitionPopUp = (props) => {

    return(
        <div className={localStorage.getItem('site_type')=== 'SafeStart'?styles.transitionCtn:styles.accuTransitionCtn}>
          <div className={styles.transitionHeader}>
            <div className={styles.TransitionTitle}>{props?.labels?.LBLFEEDBACK}</div>
            <div className={styles.CloseCtn} onClick={props?.handleTransitionClose}>
              <div className={styles.closeInnerCtn} title="Close">
                <div className={styles.leftRight}></div>
                <div className={styles.rightLeft}></div>
              </div>
            </div>
          </div>
          <div className={styles.transitionDesc}>
            { props?.labels?.LBLCOMPLFEEDBACKCERTIFICATE }
          </div>
          <button
                type="button"
                className="button-style primary-btn"
                onClick={props.handleStartnow}
            >
                {props?.labels?.LBLNOTSTARTED}
            </button>
        </div>
    )
};

export default TransitionPopUp
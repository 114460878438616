import React from 'react'
import './footer.scss'
import { connect } from 'react-redux';
import langLabels from '../../layout/translations/layout.json';

const Footer = (props) => {
    if (props && props.user_lang !== '...') {
        return (
            <div>
                <footer className="page-footer">
                    <div className="container footer text-center">
                        <p>{langLabels[props.user_lang].labels.LBLALLRIGHTSRESERVED}</p>
                    </div>
                </footer>
            </div>
        );
    } else {
        return (
            <div />            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_lang: state.user_info_reducer.user_info.language_code
    }
}

export default connect(mapStateToProps, null)(Footer)
import { HttpInterceptor } from '../../auth/HttpInterceptor';

class FeedsServices {
    feedsAPIServicesCall(apiName, method, callback, data = null, domainURL = process.env.REACT_APP_FEEDAPI_URL) {
        let apiUrl = domainURL + apiName;
        console.log('apiUrl ',apiUrl)
        if (method === "get") {
            HttpInterceptor[method](apiUrl)
                .then(result => {
                    if (callback) {
                        callback(result);
                    }
                }).catch(errr => {
                    if (errr && errr.response && errr.response.status && Number(errr.response.status)) {
                        alert("Error code : " + Number(errr.response.status))
                    }
                    //alert("UnAuthorized Request")
                    /*if(errr && errr.response && errr.response.status && Number(errr.response.status) == 401 && errr.response.data && errr.response.data.message && errr.response.data.message == "UnAuthorized Request"){
                        HttpInterceptor.get(process.env.REACT_APP_APIURL+'GetTokenFromSteeringCommitteeApi')
                        .then(res => {
                            console.log(res.data.token)
                            debugger
                            HttpInterceptor.post(process.env.REACT_APP_FEEDAPI_URL+'getToken',{sctoken:res.data.token})
                            .then(res => {
                                debugger
                                this.feedsAPIServicesCall(apiName, method, callback, data, domainURL);
                            })
                        })
                    }*/
                })
        }
        else {
            HttpInterceptor[method](apiUrl, data)
                .then(result => {
                    if (callback) {
                        callback(result);
                    }
                }).catch(errr => {
                    // alert("UnAuthorized Request")
                    /*HttpInterceptor.get(process.env.REACT_APP_APIURL+'GetTokenFromSteeringCommitteeApi')
                    .then(res => {
                        console.log(res.data.token)
                        HttpInterceptor.post(process.env.REACT_APP_FEEDAPI_URL+'getToken',{sctoken:res.data.token})
                        .then(res => {
                            HttpInterceptor[method](apiUrl)
                        })
                    })*/
                })
        }
    }
    async getS3AssetsSignedURL(param) {
        const options = {
            method: 'post',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(param)
        };

        let response = await fetch(process.env.REACT_APP_FEEDAPI_URL + "gets3signedassetsurl", options);

        return response;
    }


}
export default new FeedsServices();


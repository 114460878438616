import React, { Component } from "react";
import $ from "jquery";
import "react-multi-carousel/lib/styles.css";
import { Chart } from "react-chartjs-2";
import RoleChanger from "../../../services/RoleChanger";
import { connect } from "react-redux";
import ScServices from "../../../admin/services/services";
import DoughnutChart from "../../../admin/components/charts/DoughnutChartAll";
import RYSAveragesByState from "../../../admin/components/charts/rys_average_states_bystate";
import Skeleton from "react-loading-skeleton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField} from "@material-ui/core";
import HighGaugeChart from "./GaugeChart/HighGaugeChart";
import emptyImage from '../../../assets/images/dashboard _Emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import styles from "../../../admin/pages/dashboard/dashboard.module.scss";
import "../../../admin/pages/dashboard/dashboard.scss";
const moment = require("moment");
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);

    var chart = this.chart;
    var width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx;
    ctx.textBaseline = "top";
    var text = "",
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;

    ctx.fillText(text, textX, textY);
  },
});


const REFS = {};
let objFilterData = {};
const cstLabels = {
  "LBLCOURSENAME": "Course Name",
  "LBLAVERAGERYS": "Average Rate Your State",
  "LBLCOUNT": "Count",
  "LBLDATE": "Date",
  "LBLVIEWTRAININGCOMPLETIONBYUNIT": "View Completion Level by Learning Unit"
}
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainingStatusData: {},
      rys: {},
      loading: true,
      rysmode:false,
      trainingGraphType: "CourseWise",
      trainingGraphTitle: this.getLabel("LBLTRAININGCOMPLETION"),
      trainingGraphViewTitle: this.getLabel("LBLVIEWTRAININGCOMPLETIONBYUNIT"),
      trainingGraphImage: "baricon.png", 
      rysStates:"",
      rysGraphType: "AverageRYS",
      rysGraphTitle: this.getLabel("LBLAVERAGERYS"),
      rysGraphViewTitle: "",//this.getLabel("LBLVIEWLBLAVERAGERYSBYSTATE") + "-" + this.getLabel("LBLRUSHING"),
      rysGraphImage: "baricon.png",
      dates: [
        { "id": "TODAY", "value": "LBLTODAY" },
        { "id": "YESTERDAY", "value": "LBLYESTERDAY" },
        { "id": "LAST7DAYS", "value": "LBLLAST7DAYS" },
        { "id": "LAST30DAYS", "value": "LBLLAST30DAYS" },
        { "id": "THISMONTH", "value": "LBLTHISMONTH" },
        { "id": "LASTMONTH", "value": "LBLLASTMONTH" },
        { "id": "YTD", "value": "LBLYTD" },
      ],
      rys_date: "YTD",
      showModal:false,
    };
  }
  componentDidMount() {
    console.log("dashboard props values",this.props.companyInfo);
    this.setState({
      rysGraphTitle: this.props.labels.labels.LBLAVERAGERYS
    })    
    $(window).resize(function () {
      if ($(window).width() <= 991) {
        $("aside").removeClass("slideInLeft");
      }
    });
    $(document).on('mouseup', function (e) {
      if (REFS) {
        for (let x in REFS) {
          if (REFS[x]) {
            REFS[x].handlePreviousInputBlur("aa")
          }
        }
      }
    });

    if(this.props.userId != '...') {
      let data = {};
      data.offsetTime = this.getOffsetTime();
      data.rys_dates = this.getrysdates("YTD")
      ScServices.gettUserTrainingStatusList(this.props.userId)
        .then((res) => {
          let data = {
            labels: [
              this.props.labels.labels.LBLCOMPLETED,
              this.props.labels.labels.LBLINPROGRESS,
              this.props.labels.labels.LBLNOTSTARTED,
            ],
            datasets: [
              { data: [], backgroundColor: [], hoverBackgroundColor: [] },
            ],
          };
          let Completed = 0,
            Inprogress = 0,
            Notstarted = 0;
          for (let i = 0; i < res.result.length; i++) {
            Completed += res.result[i].Completed;
            Inprogress += res.result[i].Inprogress;
            Notstarted += res.result[i].Notstarted;
          }
          data.datasets[0].data = [Completed, Inprogress, Notstarted];
          data.datasets[0].backgroundColor = ["#A6D486", "#FFFF99", "#FF8F8F"];
          data.datasets[0].hoverBackgroundColor = [
            "#A6D486", "#FFFF99", "#FF8F8F"
          ];
          this.setState({
            trainingStatusData: data,
            orgTrainingStatusData: res.result,
            get: true,
          });

        })
        .catch((err) => {
          console.log(err);
          //alert(err.message)
        });
      this.getRYS_D1_List(data);

      this.setState({
        trainingGraphType: "CourseWise",
        trainingGraphTitle: this.getLabel("LBLTRAININGCOMPLETION"),
        trainingGraphViewTitle: this.getLabel("LBLVIEWTRAININGCOMPLETIONBYUNIT"),
        trainingGraphImage: "baricon.png",
        get: true,
      })
    }
  }


  getLearnerProgressColumns = (x) => {
    let f1columns = [
      {
        name: this.getLabel("LBLCOURSENAME"),
        selector: "product_name",
        filter: {
          type: "stringsingle",
          showField: false,
          checked: true,
          required: true,
          condition: {
            name: this.getLabel("LBLCOURSENAME"),
            operator: "EQUALS",
            value: this.state.courses ? [this.state.courses[0]] : "",
            operatorText: "",
          }
        },
        filterData: this.state.courses,
        sortable: true,
        exportcell: "_product_name",
        exportcellproperty: { order: 300, cellwidth: 20 },
        tableIndex: 1,
        width: "220px",
        cell: (row) => {
          return ""
        },
      }
    ];
    return f1columns; //JSON.parse(JSON.stringify(columns))
  };
  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    let xcols = [];
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "LearnerProgress") {
      let xobj = objFilterData.availableLearnProgressFields;
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    } else if (mode === "rysAverage") {
      let xobj = objFilterData.availableRYS_D1Fields;
      if (xobj) {
        for (var k = 0; k < xcols.length; k++) {
          xcols[k].filter = xobj[xcols[k].name];
        }
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };

  onFilterChange = (id, conditions) => {
    let isValid = true
    conditions.map((item, i) => {
      if (item.filter.checked && item.filter.required) {
        try {
          if ((item.filter.condition.operator === "MULTI_EQUALS" || item.filter.condition.operator === "EQUALS") && item.filter.condition.value === "") {
            isValid = false
            REFS[id].onError(item)
          }
        } catch (e) {
          isValid = false
          REFS[id].onError(item)
        }
      }
      return null;
    });
    if (isValid === true) {
      if (id === "F1") {
        REFS.F1.onCloseCheckList()
        this.setFilterFields("F1", conditions);
        let data = { type: "web", columns: objFilterData.availableLearnProgressFields };
        data.filtercolumns = conditions;
        this.getLP_DSBD_D1(data);
      } else if (id === "F2") {
        //console.log("onFilterChangeeeeee2", conditions);
        this.setFilterFields("F2", conditions);
        let data = { type: "web", columns: objFilterData.availableRYS_D1Fields };
        data.filtercolumns = conditions;
        this.setsvaluefordate(data)
        this.getRYS_D1_List(data);
      }
    }
  };
  setFilterFields = (id, conditions) => {
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportcellproperty) {
        xobj[conditions[i].name].exportcellproperty =
          conditions[i].exportcellproperty;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      objFilterData.availableLearnProgressFields = xobj;
    } else if (id === "F2") {
      objFilterData.availableRYS_D1Fields = xobj;
    }
  };
  showFilter = (id) => {
    if (id === "F1" && REFS) {
      if (REFS.F1.getOpenStatus() === "CLOSE") {
        REFS.F1.showCheckList()
      } else {
        REFS.F1.onCloseCheckList()
      }
    } else if (id === "F2" && REFS) {
      if (REFS.F2.getOpenStatus() === "CLOSE") {
        REFS.F2.showCheckList()
      } else {
        REFS.F2.onCloseCheckList()
      }
    }
  }

  getLP_DSBD_D1 = (data) => {
    ScServices.getLP_DSBD_D1_USER(data).then((res) => {
      let result2 = res.result[9];
      let courses = res.result[10];
      let result1 = res.result[11];
      let data1 = {
        labels: [],
        datasets: [{ graphData: result1, backgroundColor: [], hoverBackgroundColor: [], ynames: [] }],
      };
      let data2 = {
        labels: [],
        datasets: [{ graphData: result2, backgroundColor: [], hoverBackgroundColor: [], ynames: [] }],
      };
      this.setState({
        trainingCompData: data1,
        trainingCompDataUnitWise: data2,
        orgTrainingCompData: result1,
        trainingCompDataLoaded: true,
        courses: courses,
        groupsLoaded: "YES"
      });
    });
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getOffsetTime = () => {
    return "053000";
  };
  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  showTrainingGraph = (x) => {
    if (x === "UnitWise") {
      this.setState({
        trainingGraphType: "UnitWise",
        trainingGraphTitle: this.getLabel("LBLTRAININGCOMPLETIONBYUNIT"),
        trainingGraphViewTitle: this.getLabel("LBLVIEWTRAININGCOMPLETION"),
        trainingGraphImage: "pieicon.png"
      })
    } else {
      this.setState({
        trainingGraphType: "CourseWise",
        trainingGraphTitle: this.getLabel("LBLTRAININGCOMPLETION"),
        trainingGraphViewTitle: this.getLabel("LBLVIEWTRAININGCOMPLETIONBYUNIT"),
        trainingGraphImage: "baricon.png"
      })
    }
  }
  showRYSGraph = (x, dp) => {
    if (x === "AverageRYS-State") {
      // let rysState =  dp.category?dp.category:this.state.rysStates; 
      this.setState({
        rysGraphType: "AverageRYS-State",
        rysGraphTitle: this.getLabel("LBLAVERAGERYSBYSTATE") + " - " + this.getLabel("LBL" + dp.category.toUpperCase()),
        rysGraphViewTitle: this.getLabel("LBLVIEWLBLAVERAGERYS"),
        rysLevel2Data: dp,
        rysStates:dp.category, 
        rysGraphImage: "baricon.png",
        rysmode:true
      })
    } else {
      this.setState({
        rysGraphType: "AverageRYS",
        rysGraphTitle: false,
        rysGraphViewTitle: "",//this.getLabel("LBLVIEWLBLAVERAGERYSBYSTATE") + "-" + this.getLabel("LBLRUSHING"),
        rysLevel2Data: {},
        rysGraphImage: "baricon.png",
        rysmode:false
      })
    }

  }
  getRYS_D1_Columns = (x) => {
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
    xrArr.push(new Date());
    let columns = [
      {
        name: this.props.labels.labels.LBLDATE,
        selector: "created_on",
        filter: {
          type: "date",
          showField: true,
          checked: true,
          render: "server",
          condition: {
            name: "Date",
            operator: "YTD",
            value: xrArr.slice(),
            operatorText: this.getLabel("LBLYTD"),
          },
          exportcell: "_created_on_dateformat",
        },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLDATE}
              </label>
              <span className="rle-brdr">
                {row.created_on_format}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLRUSHING,
        selector: "rushing",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLRUSHING}
              </label>
              <span className="rle-brdr">{row.rushing}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLFATIGUE,
        selector: "fatigue",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLFATIGUE}
              </label>
              <span className="rle-brdr">{row.fatigue}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLFRUSTRATION,
        selector: "frustration",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLFATIGUE}
              </label>
              <span className="rle-brdr">{row.frustration}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLACENCY,
        selector: "complacency",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLCOMPLACENCY}
              </label>
              <span className="rle-brdr">{row.complacency}</span>
            </div>
          );
        },
      },
    ];
    return columns; //JSON.parse(JSON.stringify(columns))
  };

  onDetailReportLevel2 = (xdataPoint) => {
    xdataPoint = xdataPoint ? xdataPoint : {}
    this.props.history.push("/schome/reports/lcs");
  }
  onRYSDetailReportLevel2 = (xdataPoint) => {
    let ryscolumns = objFilterData.availableRYS_D1Fields
    if (!ryscolumns) {
      let columns = this.getRYS_D1_Columns();
      ryscolumns = this.getFilterColumns("RYS_D1", columns);
    }
    this.props.history.push("/schome/reports/rate_your_state");
  }



  getRYS_D1_Summary = (xresult, ydata) => {
    let data = {
      labels: [],
      datasets: [{ graphData: xresult, backgroundColor: [], hoverBackgroundColor: [], ynames: [] }],
    };
    let summaryData = {
      rushing: 0,
      fatigue: 0,
      frustration: 0,
      complacency: 0,
      cnt_rushing: 0,
      cnt_fatigue: 0,
      cnt_frustration: 0,
      cnt_complacency: 0,
    };
    if (xresult) {
      let result = xresult
      if (result) {
        for (var i = 0; i < result.length; i++) {
          if (parseInt(result[i].rushing) >= 0) {
            summaryData.rushing = summaryData.rushing + parseInt(result[i].rushing)
            summaryData.cnt_rushing++
          }
          if (parseInt(result[i].fatigue) >= 0) {
            summaryData.fatigue = summaryData.fatigue + parseInt(result[i].fatigue)
            summaryData.cnt_fatigue++
          }
          if (parseInt(result[i].frustration) >= 0) {
            summaryData.frustration = summaryData.frustration + parseInt(result[i].frustration)
            summaryData.cnt_frustration++
          }
          if (parseInt(result[i].complacency) >= 0) {
            summaryData.complacency = summaryData.complacency + parseInt(result[i].complacency)
            summaryData.cnt_complacency++
          }
        }
      }
    }
    data.avg = {
      rushing: parseFloat((summaryData.cnt_rushing > 0 ? summaryData.rushing / summaryData.cnt_rushing : 0).toFixed(1)),
      frustration: parseFloat((summaryData.cnt_frustration > 0 ? summaryData.frustration / summaryData.cnt_frustration : 0).toFixed(1)),
      fatigue: parseFloat((summaryData.cnt_fatigue > 0 ? summaryData.fatigue / summaryData.cnt_fatigue : 0).toFixed(1)),
      complacency: parseFloat((summaryData.cnt_complacency > 0 ? summaryData.complacency / summaryData.cnt_complacency : 0).toFixed(1))
    }
    let xstartDay = ""
    let xendDay = ""
    let ryscolumns = objFilterData.availableRYS_D1Fields
    //console.log("objFilterData.availableRYS_D1Fields", objFilterData.availableRYS_D1Fields)
    let xcondition
    if (!ryscolumns) {
      //console.log("REASSIGN ryscolumns")
      let columns = this.getRYS_D1_Columns();
      ryscolumns = this.getFilterColumns("RYS_D1", columns);
      let xdata = { filtercolumns: ryscolumns }
      this.setsvaluefordate(xdata)
      for (let i = 0; i < ryscolumns.length; i++) {
        if (ryscolumns[i].selector === "created_on") {
          xcondition = ryscolumns[i].filter.condition
        }
      }
    } else {
      for (var key in ryscolumns) {
        if (ryscolumns[key].selector === "created_on") {
          xcondition = ryscolumns[key].condition
        }
      }
    }
    if (xcondition.svalue) {
      xstartDay = xcondition.svalue[0]
      if (xcondition.svalue[1]) {
        xendDay = xcondition.svalue[1]
      } else {
        xendDay = xstartDay.split(" ")[0] + " 23:59:59"
      }
    }
    data.date1 = this.state.rys_date1
    data.date2 = this.state.rys_date2
    return data;

  };
  setsvaluefordate = (data) => {
    if (data.filtercolumns) {
      for (let j = 0; j < data.filtercolumns.length; j++) {
        if (data.filtercolumns[j].filter.type == "date" && data.filtercolumns[j].filter.condition) {
          //console.log("setsvaluefordate", data.filtercolumns[j].filter)
          let dt1, dt2;
          let xcondition = JSON.parse(JSON.stringify(data.filtercolumns[j].filter.condition))
          // let ddata = {}
          if (typeof xcondition.value == "string") {
            let arrdt = xcondition.value.split("T")[0].split("-");
            let arrdt2 = xcondition.value.split("T")[1].split(":");
            xcondition.xvalue = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          } else {
            if (typeof xcondition.value == "object" && xcondition.value[0] && typeof xcondition.value[0] == "string") {
              xcondition.xvalue = []
              //console.log("xcondition.value[0]", xcondition.value[0])
              let arrdt = xcondition.value[0].split("T")[0].split("-");
              let arrdt2 = xcondition.value[0].split("T")[1].split(":");
              xcondition.xvalue[0] = new Date(
                arrdt[0],
                parseInt(arrdt[1]) - 1,
                arrdt[2],
                arrdt2[0],
                arrdt2[1],
                0, 0
              );
            }
            if (typeof xcondition.value == "object" && xcondition.value[1] && typeof xcondition.value[1] == "string") {
              let arrdt = xcondition.value[1].split("T")[0].split("-");
              let arrdt2 = xcondition.value[1].split("T")[1].split(":");
              xcondition.xvalue[1] = new Date(
                arrdt[0],
                parseInt(arrdt[1]) - 1,
                arrdt[2],
                arrdt2[0],
                arrdt2[1],
                0, 0
              );
            }
          }
          if (typeof xcondition.value == "object" && xcondition.value[0] && xcondition.value[1]) {
            dt1 = new Date(xcondition.xvalue[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
            dt2 = new Date(xcondition.xvalue[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          } else {
            dt1 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
            dt2 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          }
          data.filtercolumns[j].filter.condition["svalue"] = [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
        }
      }
    }
  }
  getRYS_D1_List(data) {
    data.report = "RYS_D1";
    this.setsvaluefordate(data)
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;
    data.offsetTime = this.getOffsetTime();
    ScServices.getRYS_D1_USER_List(data).then((response) => {
      let result = response.result[7];
      this.setState({
        pageState: "RYS_D1",
        RYS_D1_List: result,
        orgRYS_D1_List: result,
        //RYS_D1_SummaryData_Old: this.getRYS_D1_Summary_Old(result, data),
        RYS_D1_SummaryData: this.getRYS_D1_Summary(result, data),
        sel_D1_Data: data,
        RYSDataLoaded: true,
        zoomChanged: 0,
        rysGraphTitle: this.props.labels.labels.LBLAVERAGERYS
      });
    }); 
  }
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  getrysdatesvalues = (xvalue) => {
    let xrArr = []
    let cDate = new Date();
    switch (xvalue) {
      case "LAST7DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "LAST30DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "THISMONTH":
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "LASTMONTH":
        let thisMonthFDate = new Date(cDate.getFullYear(), cDate.getMonth(), 1, 23, 59, 59, 999);
        xrArr.push(new Date(thisMonthFDate.getFullYear(), thisMonthFDate.getMonth() - 1, 1, 0, 0, 0, 0));
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
      case "YTD":
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "TODAY":
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "YESTERDAY":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      default:
        break;
    }
    if (xrArr.length === 1) {
      return moment(xrArr[0]).format(this.props.dateformat)
    } else {
      return moment(xrArr[0]).format(this.props.dateformat) + " " + this.getLabel("LBLTO") + " " + moment(xrArr[1]).format(this.props.dateformat)
    }
  }
  getrysdates = (xvalue) => {
    let cDate = new Date();
    let xrArr = []
    switch (xvalue) {
      case "LAST7DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "LAST30DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "THISMONTH":
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "LASTMONTH":
        let thisMonthFDate = new Date(cDate.getFullYear(), cDate.getMonth(), 1, 23, 59, 59, 999);
        xrArr.push(new Date(thisMonthFDate.getFullYear(), thisMonthFDate.getMonth() - 1, 1, 0, 0, 0, 0));
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
      case "YTD":
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "TODAY":
        xrArr.push(new Date(cDate.getTime()));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "YESTERDAY":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
        default:
        break;
    }
    let dt1 = new Date(xrArr[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
    let dt2 = new Date(xrArr[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));

    let xxdt1 = new Date(xrArr[0].getTime());
    let xxdt2 = new Date(xrArr[1].getTime());
    xxdt1.setHours(0)
    xxdt1.setMinutes(0)
    xxdt1.setSeconds(0)
    xxdt2.setHours(23)
    xxdt2.setMinutes(59)
    xxdt2.setSeconds(59)
    this.setState({
      rys_date1: xxdt1,
      rys_date2: xxdt2
    })
    return [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
  }
  getRYSDatesFilter() {
    let strdateval = (id) => {
      return this.getLabel("LBL" + id);// + " (" + this.getrysdatesvalues(id) + ")"
    }
    return [
      { "id": "TODAY", "value": strdateval("TODAY") },
      { "id": "YESTERDAY", "value": strdateval("YESTERDAY") },
      { "id": "LAST7DAYS", "value": strdateval("LAST7DAYS") },
      { "id": "LAST30DAYS", "value": strdateval("LAST30DAYS") },
      { "id": "THISMONTH", "value": strdateval("THISMONTH") },
      { "id": "LASTMONTH", "value": strdateval("LASTMONTH") },
      { "id": "YTD", "value": strdateval("YTD") },
    ]
  }
  onZooomChangeEvent = (x) => {
    this.setState({
      zoomChanged: x
    })
  }
  render() {
    let RYSShow = Boolean($('body').attr('RYS-SHOW'));
    let optionClass = RYSShow
      ? "col-xl-4 col-lg-4 col-md-4 col-sm-6"
      : "col-xl-12 col-lg-12 col-md-12 col-sm-12";
    const {
      LBLRUSHING,
      LBLFRUSTRATION,
      LBLFATIGUE,
      LBLCOMPLACENCY,
      LBLMYTRAINING,
      LBLAVERAGERYS,
      LBLNOOPTIONSDROPDOWN
    } = this.props.labels.labels;
    return (
      <section className="home-section">
        <div className="row container">
          <RoleChanger  labels={this.props.labels.labels}
           currentpage={"dashboard"} />
        </div>
        {this.state.loading === false ? (
          ""
        ) : (
          <>
            <div className="row">
              <div className={optionClass}>
                <div className="s-card">
                  <div className={styles.chartCard} style={{ marginBottom: "38px" }}>
                    <h3>{LBLMYTRAINING}</h3>
                  </div>
                  {!this.state.orgTrainingStatusData ||
                    this.state.orgTrainingStatusData === undefined ? (
                    <div>
                      <Skeleton height={400} />
                    </div>
                  ) : this.state.orgTrainingStatusData.length === 0 ? (
                      <div className="text-danger m-1 text-center">
                        {<EmptyState image={emptyImage} text2={this.getLabel("LBLDASHBOARDEMPTYTXT")}/>}
                      </div>
                  ) : (
                    <DoughnutChart
                      data={this.state.trainingStatusData}
                      legend={true}
                    />
                  )}
                </div>
              </div>
              {RYSShow ? (
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                  <div className="s-card">
                    <div className={styles.chartCard} style={{ marginTop: "-10px" }}> 
                      <h3>
                        {this.state.rysmode ?this.getLabel("LBLAVERAGERYSBYSTATE") + (" - ") + this.getLabel("LBL" + this.state.rysStates.toUpperCase()) :LBLAVERAGERYS}
                          
                      </h3>
                      <div className="cls_back_status" onClick={(x) => {
                        if (this.state.rysGraphType === "AverageRYS") {
                        } else {
                          this.showRYSGraph("AverageRYS", {})
                        }
                      }}
                        title={this.state.rysGraphViewTitle}
                        style={{
                        }}
                      >
                        {this.state.rysGraphType === "AverageRYS" ?
                          <img className={styles.clsBar} src={
                            "../images/steercom/" + this.state.rysGraphImage
                          } style={{ visibility: "hidden" }} alt=""/>
                          :
                          <i class="fas fa-arrow-left" style={{ cursor: "pointer", paddingRight: "10px", margin: "4px 4px 4px 4px" }} aria-hidden="true"></i>
                        }
                      </div>
                    </div>

                    {(this.state.RYSDataLoaded === true) ? (
                      <><div className="d-flex">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 ml-auto" style={{ margin: "-20px -10px 10px 0px " }}>
                          <Autocomplete
                            id="combo-box-demo"
                            options={this.getRYSDatesFilter()}
                            getOptionLabel={(option) => this.getLabel("LBL" + option.id)} //+ " (" + this.getrysdatesvalues(option.id) + ")"}
                            noOptionsText={LBLNOOPTIONSDROPDOWN}
                            getOptionSelected={(option) => option.id === this.state.rys_date}
                            disableClearable
                            onChange={(event, values) => {
                              let data = { type: "web" };
                              data.offsetTime = this.getOffsetTime();
                              data.product_id = values.id
                              data.rys_dates = this.getrysdates(values.id)
                              if (this.state.zoomChanged === 1) {
                                this.setState({
                                  pageState: "RYS_D1",
                                  RYS_D1_List: [],
                                  orgRYS_D1_List: [],
                                  RYS_D1_SummaryData: this.getRYS_D1_Summary([], {}),
                                  sel_D1_Data: {},
                                  RYSDataLoaded: true,
                                });
                              }
                              this.getRYS_D1_List(data);
                              this.setState({
                                rys_date: values.id,
                                get: true,
                              });
                            }}
                            defaultValue={this.state.dates.find(
                              (v) => v.id == this.state.rys_date
                            )}
                            renderOption={(option) => (
                              <div className={styles.renderOptionDash}>{option.value}</div>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                          />
                        </div>
                      </div>
                        <div style={{ "margin-top": "0px" }}>
                          {
                            this.state.RYS_D1_SummaryData.datasets[0].graphData.length > 0 ? <>

                              {
                                this.state.rysGraphType === "AverageRYS" ?
                                  <><div className={styles.mltpleGuageFlex} style={{ "marginTop": "10px" }}>
                                    <div className={`row ${styles.cardGuageFlex}`}>
                                      <div className="chart-guage col-xl-6 col-lg-6 col-md-6" style={{ cursor: "pointer" }} onClick={() => { this.showRYSGraph("AverageRYS-State", { category: "rushing" }) }}>
                                        <HighGaugeChart percent={this.state.RYS_D1_SummaryData.avg.rushing} color={"#F46980"} label={LBLRUSHING}></HighGaugeChart>
                                      </div>
                                      <div className="chart-guage col-xl-6 col-lg-6 col-md-6" style={{ cursor: "pointer" }} onClick={() => { this.showRYSGraph("AverageRYS-State", { category: "frustration" }) }}>
                                        <HighGaugeChart percent={this.state.RYS_D1_SummaryData.avg.frustration} color={"#FBBF7D"} label={LBLFRUSTRATION}></HighGaugeChart>
                                      </div>
                                    </div>
                                    <div className={`row ${styles.cardGuageFlex}`}>
                                      <div className="chart-guage col-xl-6 col-lg-6 col-md-6" style={{ cursor: "pointer" }} onClick={() => { this.showRYSGraph("AverageRYS-State", { category: "fatigue" }) }}>
                                        <HighGaugeChart label={LBLFATIGUE} percent={this.state.RYS_D1_SummaryData.avg.fatigue} color={"#B7B3AE"}></HighGaugeChart>
                                      </div>
                                      <div className="chart-guage col-xl-6 col-lg-6 col-md-6" style={{ cursor: "pointer" }} onClick={() => { this.showRYSGraph("AverageRYS-State", { category: "complacency" }) }}>
                                        <HighGaugeChart percent={this.state.RYS_D1_SummaryData.avg.complacency} color={"#58AFA9"} label={LBLCOMPLACENCY}></HighGaugeChart >
                                      </div>
                                    </div>
                                  </div>
                                  </>
                                  :
                                  <RYSAveragesByState
                                    {...this.props}
                                    data={this.state.RYS_D1_SummaryData}
                                    date1={this.state.RYS_D1_SummaryData.date1}
                                    date2={this.state.RYS_D1_SummaryData.date2}
                                    dataPoint={this.state.rysLevel2Data}  
                                    dataStates = {this.state.rysStates}
                                    count={this.getLabel("LBLCOUNT")}
                                    date={this.getLabel("LBLDATE")}
                                    graphId={"DBRYSBYSTATE"}
                                    onZoomChange={this.onZooomChangeEvent}
                                  />
                              }
                            </> : <div className="text-danger m-1 text-center">
                              {this.state.zoomChanged === 0 ? 
                                <EmptyState image={emptyImage} text2={this.getLabel("LBLDASHBOARDEMPTYTXT")}/>
                              : null}
                            </div>
                          }
                        </div>
                      </>
                    ) : ("")}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
        
            </div>
          </>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
    companyInfo: state.companyinfo_reducer.companyInfo
  };
};

export default connect(mapStateToProps, null)(Dashboard);

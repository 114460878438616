import React, { Component } from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import GroupServices from "../group-list/GroupServices";
import "./ProdList.scss";
import { connect } from "react-redux";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import Skeleton from "react-loading-skeleton";
import { Modal } from "react-bootstrap";
import TrainingSettings from "../trainingsettings/trainingsettings"
import emptyImage from '../../../assets/images/prodlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import EnrollUser from "../prod-list/EnrollUser";
import EnrollGroup from "../prod-list/EnrollGroup";
import TrainingManagementHeader from "../../../common_components/datatable/TrainingManagementHeader"
import { DataGrid} from '@mui/x-data-grid';
import "../../../assets/scss/datatable.scss"
import styles from "./ProdList.module.scss"
const $ = require("jquery");
const cstLabels = {
  "LBLEDITPRODUCT": "Edit Course" 
}
class ProdList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusFilter:0,
      productlist: [],
      resultData: [],
      fullprodlist: [],
      groupTypeInfo: [],
      selected_group_type: "GP_1",
      prodtype: "Participant",
      roles: [],
      orgs: [],
      groups: [],
      loading: true,
      show: false,
      status: 0,
      pageKey: 0,
      initialValues: null,
      errorType: null,
      assignmentstate: "products",
      productIds: [],
      selectedCourses:[],
      selectedRowsCount:0,
      selProductid: "",
      selProductName: "",
      disMoreCourses:true,
      productGroups: [],
      productUsers: [],
      enrollUsersSelected: false,
      enrollGroupsSelected: false,
      prodroles: { Participant: 4, Trainer: 3, Consultant: 2 },
      isChecked: false,
      searchValue: "",
      userSearchValue: "",
      groupSearchValue: "",
      groupSearchValueModal:"",
      userSearchValueModal:"",
      showModalDetails: false,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
        showList: false
      },
      toggleClearRows: true,
      // selectAllEnabled: false,
      visibleRowsSelected: false,
      pageStatus:{ // Loading data in the datagrid component
        isLoading: false,
        data: [], // rows
        total: 0 // total count of data grid we need to show
      },
      paginationModel:{ // pagination variables
        page: 0,
        pageSize: 10,
      },
      rowSelectionModel:[],
      filteredValue:[],
      sortModel: [],
    };

    this.modalChild = React.createRef();
    this.CloseEnrolledDetails = this.CloseEnrolledDetails.bind(this);
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        if (cstLabels.hasOwnProperty(xfield.label)) {
          return cstLabels[xfield.label].replace(
            "$$$",
            xfield.val
          );
        } else {
          return xfield.label;
        }
      }
    }
  };
  componentDidMount() {
    this.getProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.pageStatus.data !== this.state.pageStatus.data ||
      prevState.rowSelectionModel !== this.state.rowSelectionModel
    ) {
      this.handleVisibleRowsSelection();
   }
  }
  handleVisibleRowsSelection = () => {
    let totalavailableRows =  this.state.pageStatus.data.length;
    if (this.state.pageStatus.data.length > 0) {
      let selectedRowCount = 0;
      this.state.pageStatus.data.forEach(val => {
        if (this.state.rowSelectionModel.includes(val.productid)) {
          selectedRowCount++;
        }
      });

      let totalSelectedRows = selectedRowCount ;
      if(totalavailableRows === 0 ) {
        this.setState({visibleRowsSelected:false})
      } else if (totalavailableRows === totalSelectedRows && this.state.selectedRowsCount !== this.state.productlist.length ) {
        this.setState({visibleRowsSelected:true})
      } else {
        this.setState({visibleRowsSelected:false})
      }
    }
  }
  getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };
  updateTrainingAssignment = (selectedUser) => {
    this.setState({searchValue:""})
    var todaydate = this.getTodayDate();
    if (this.state.assignmentstate === "users" && selectedUser) {
      var postData = { productUsers: [] };
      for (var i = 0; i < selectedUser.length; i++) {
          var item = {
            id: selectedUser[i].id,
            productid: this.state.rowSelectionModel,
            delflag: this.state.statusFilter === 1 ? 0 : 1,
            assigned_date: todaydate,
          };
          postData.productUsers.push(item);
      }
        HttpInterceptor.post("updateUsersProducts", postData).then((res) => {
          this.ToastMessageDetails(
            "Success",
            this.props.labels.labels.LBLCHANGESUPDATEDSUCCESS
          );
          this.getProducts();
        });
    }
  };
  updateTrainingAssignmentGrp = (selectedGroup) => {
    this.setState({searchValue:""})
    var todaydate = this.getTodayDate();
    if (this.state.assignmentstate === "groups" && selectedGroup) {
      var postData = { productGroups: [] };
      for (var i = 0; i < selectedGroup.length; i++) {
          var item = {
            id: selectedGroup[i].id,
            grouptypeid: selectedGroup[i].group_typeid,
            productid: this.state.rowSelectionModel,
            delflag: this.state.statusFilter === 3 ? 0 : 1,
            assigned_date: todaydate,
          };
          postData.productGroups.push(item);
      }
        HttpInterceptor.post("updateGroupsProducts", postData).then((res) => {
          this.ToastMessageDetails(
            "Success",
            this.props.labels.labels.LBLCHANGESUPDATEDSUCCESS
          );
          this.getProducts();
        });
    }
  }
  getProductUsers = (xstate,e) => {
    var showErrorid = document.getElementById("selectproductError");
    var showErrorselector = document.querySelector("#selectproductError");
    let assignedStatus = e.target.value === 1 ? 1 : 0 ;
    if (this.state.rowSelectionModel.length > 0 ) {
      let data = {
        productid: this.state.rowSelectionModel,
        // role: this.state.prodroles[this.state.prodtype],
        assigned_status:assignedStatus,
      };

      HttpInterceptor.post(
        "productusers", data
        // "productusers/" + data.productid + "/" + data.role
      ).then((res) => { 
        this.setState((state, props) => ({
          productUsers: res.data.slice(),
          loading: false,
          assignmentstate : xstate,
        }));
      }).catch((err) => {
        if (err.message === "Request failed with status code 400" ) {
        this.setState({
          statusFilter:0
        })
        }
      });
    } else {
      showErrorselector.classList.remove("d-none");
      showErrorid.classList.add("d-block");
    }
  };

  getGroupTypes = async (assignmentstate) => {
    var showErrorid = document.getElementById("selectproductError");
    var showErrorselector = document.querySelector("#selectproductError");
    if (this.state.rowSelectionModel.length > 0 ) {
      GroupServices.getGroups({ language: this.props.language_code }).then(
        (response) => {
          this.setState(
            {
              groupTypeInfo: response,
              group_type: "lbl_" + response[0].group_type_id,
              selected_group_type: response[0].group_type_id,
            },
            () => {
              this.getProductGroups(assignmentstate,this.state.statusFilter);
            }
          );
        }
      );
    } else {
      showErrorselector.classList.remove("d-none");
      showErrorid.classList.add("d-block");
    }
  };

  getProductGroups = (xstate,e) => {
    this.setState({assignmentstate: xstate});
    let assignedStatus = e === 3 ? 1 : 0 ;
    if (this.state.group_type === undefined) {
      this.getGroupTypes();
    } else {
      let data = {
        productid: this.state.rowSelectionModel,
        grouptype: this.state.selected_group_type,
        assigned_status:assignedStatus
      };
      HttpInterceptor.post(
        "productgroups" ,data
      ).then((res) => {
        this.setState((state, props) => ({
          productGroups: res.data.slice(),
          loading: false,
        }));
      });
    }
  };
  filterOnChangeHandler = (e) => {
    // let index = e.nativeEvent.target.selectedIndex;
    this.setState(
      {
        selected_group_type: e,
        // group_type: e.nativeEvent.target[index].text,
      },
      () => {
        this.getProductGroups(this.state.assignmentstate, this.state.statusFilter);
      }
    );
  }
  handlefilterStaus = (e) => {
    if(this.state.rowSelectionModel.length > 0){
      this.setState({
        statusFilter: e.target.value,
        paginationModel:{ // pagination variables
          page: 0,
          pageSize: 10,
        },
      })
    }
    if (e.target.value === 1 ||e.target.value ===  2) {
      this.getProductUsers("users", e)
    } else if (e.target.value === 3 ||e.target.value ===  4) {
      this.getGroupTypes("groups",e)
    }
  }
  handleCancel = () => {
    this.setState({
      rowSelectionModel: [],
      selProductName: "",
      assignmentstate: "products",
      userSearchValue: "",
      groupSearchValue: "",
      searchValue:"",
      showModalDetails: false,
      statusFilter: 0,
      selectedRowsCount :0,
      selectedCourses:[],
      disMoreCourses : true,
      paginationModel:{ // pagination variables
        page: 0,
        pageSize: 10,
      },
      pageStatus : { isLoading: false, data: this.state.resultData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: this.state.resultData.length },
    });
  }
  getProducts = () => {
    this.setState({
      rowSelectionModel: [],
      selProductName: "",
      assignmentstate: "products",
      userSearchValue: "",
      groupSearchValue: "",
      showModalDetails: false,
      drip_settings: false,
      statusFilter: 0,
      selectedRowsCount : 0
    });
    let data = {};
    let drip_settings = false
    HttpInterceptor.get("products", data).then((res) => {
      let tproducts = [];
      this.setState({fullprodlist: res.data});
      for (let i = 0; i < this.state.fullprodlist.length; i++) {
        if (this.state.prodtype === this.state.fullprodlist[i].type) {
          tproducts.push(this.state.fullprodlist[i]);
        }
        if (this.state.fullprodlist[i].drip_settings === 1) {
          drip_settings = true
        }
      }
      this.setState((state, props) => ({
        productlist: tproducts.slice(),
        resultData: tproducts.slice(),
        loading: false,
        drip_settings: drip_settings,
        pageStatus : { isLoading: false, data: tproducts.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: tproducts.length },
        filteredValue:tproducts.slice(),
      }));
    });
  };

  setProdType = (value) => {
    this.setState({prodtype: value})
    let tproducts = [];
    for (let i = 0; i < this.state.fullprodlist.length; i++) {
      if (this.state.prodtype === this.state.fullprodlist[i].type) {
        tproducts.push(this.state.fullprodlist[i]);
      }
    }
    this.setState((state, props) => ({
      prodtype: value,
      productlist: tproducts.slice(),
      resultData: tproducts.slice(),
      loading: false,
    }));
  };

  handleSortModelChange = (sortModel) => {
    console.log("sortModelstart",sortModel)
    // if (sortModel?.length > 0) {
      this.setState({sortModel:sortModel});
      const field = sortModel[0]?.field ;
      let direction = sortModel[0]?.sort ;
      let sortedData = [...this.state.filteredValue];
       if(direction === undefined) {
        sortedData = this.state.searchValue !== "" ? [...this.state.filteredValue] : [...this.state.resultData];
       }
       console.log("sortedData",sortedData)
       
       sortedData.sort((a, b) => {
        let valueA;
        let valueB;
        if(field === "language_id"){
          valueA = this.props.labels.labels[`LBL${a.language_id.replace("-", "").toUpperCase()}`] || "";
          valueB = this.props.labels.labels[`LBL${b.language_id.replace("-", "").toUpperCase()}`] || ""; 
        }else{
          valueA = a[field] === null ? "" : a[field];
          valueB = b[field] === null ? "" : b[field];
        }
        if (direction === 'asc') {
            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB);
            } else {
                return valueA - valueB;
            }
        } else {
            if (typeof valueB === 'string') {
                return valueB.localeCompare(valueA);
            } else {
                return valueB - valueA;
            }
        }
      });
    
      console.log("sortedData",sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)))
      this.setState({ pageStatus : { isLoading: false, data: sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)), total: sortedData?.length },productlist:sortedData });
    // }
    console.log("sortModel end",sortModel[0]?.sort)
  };

  searchUser = (e) => {
    console.log(e,"evee")
    if (this.state.assignmentstate === "products") {
      this.setState({
        searchValue: e.target.value,
        paginationModel: {
          page: 0,
          pageSize: 10,
        },
      },() => {
        this.filterGroup(); 
      });
    } else if (
      this.state.assignmentstate === "users" &&
      this.state.showModalDetails === false
    ) {
      this.setState({
        userSearchValue: e.target.value,
      });
    } else if (
      this.state.assignmentstate === "users" &&
      this.state.showModalDetails === true
    ) {
      this.setState({
        groupSearchValueModal: e.target.value,
      });
    } else if (
      this.state.assignmentstate === "groups" &&
      this.state.showModalDetails === false
    ) {
      this.setState({
        groupSearchValue: e.target.value,
      });
    } else if (
      this.state.assignmentstate === "groups" &&
      this.state.showModalDetails === true
    ) {
      this.setState({
        userSearchValueModal: e.target.value,
      });
    }
  };

  ShowEnrolledDetails = (e) => {
    let vKey = typeof e !== "string" ? e.toString() : e ; // Individual user id/ group id
    if (this.state.assignmentstate === "groups") {
      let data = {
        productid: this.state.rowSelectionModel,
        groupid:vKey,
      }
      HttpInterceptor.post(
        "productgroupusers",data
      ).then((res) => {
        this.setState({
          showModalDetails: !this.state.showModalDetails,
          enrollredDetails: res.data,
        });
      });
    }
    if (this.state.assignmentstate === "users") {
        let data = {
          productid: this.state.rowSelectionModel,
          userid:vKey,
          language_code:this.props.language_code
        }
      HttpInterceptor.post(
        "productusergroups",data
      ).then((res) => {
        this.setState({
          showModalDetails: !this.state.showModalDetails,
          enrollredDetails: res.data,
        });
      });
    }
  };
  CloseEnrolledDetails() {
    this.setState({
      showModalDetails: !this.state.showModalDetails,
      groupSearchValueModal:"",
      userSearchValueModal:""
    });
  }

  ToastMessageDetails(vStatus, vMessage) {
    this.setState({
      show: false,
      initialValues: null,
      showToast: {
        show: true,
        alertClass: "success",
        status: vStatus, //'Success',
        message: vMessage, //'The user is created successfully',
        changeInToast: new Date(),
      },
      errorType: null,
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 1500);
  }
  handleCourses= () => {
       this.setState({showList: true,disMoreCourses :!this.state.disMoreCourses})
 } 
 closeMoreModal = () => {
  this.setState({showList: false, disMoreCourses :!this.state.disMoreCourses})
} 

  handleClear = () => {
    this.setState({
      rowSelectionModel : [],
      selectedRowsCount: 0
    });
  }
  handleSelectAll = () => {
    const selectedRowsCount = this.state.filteredValue.length;    
    const productId = this.state.filteredValue.map((item) => item.productid);
    this.setState({rowSelectionModel:productId, selectedRowsCount:selectedRowsCount});
  }
  generateColumns = () => {
    const columns = [
      {
        headerName: this.props.labels.labels.LBLCOURSENAME,
        field: "name",
        width: "400",
        renderCell: (row) => (
          <div className="grid-ellipsis">
            <span title={row.row.name}>{row.row.name}</span>
          </div>
        ),
      },
      {
        headerName: this.props.labels.labels.LBLLANGUAGE,
        field: "language_id",
        width: "200",
        sortable: true,
        renderCell: (row) => (
          <div>
            <span>
              {
                this.props.labels.labels[
                `LBL${row.row.language_id.replace("-", "").toUpperCase()}`
                ]
              }
            </span>
          </div>
        ),
        valueGetter: (params) => {
          const languageId = params.row.language_id;
          return this.props.labels.labels[`LBL${languageId.replace("-", "")}`];
        }
      },
      {
        headerName: this.props.labels.labels.LBLENROLLUSER,
        field: "enrolledUsers",
        sortable: true,
        width: "200",
        compact: true,
        renderCell: (row) => (
          <div>
            <span>{row.row.enrolledUsers}</span>
          </div>
        ),
      },
      {
        headerName: this.props.labels.labels.LBLACTION !== undefined ? this.props.labels.labels.LBLACTION : "Action",
        field: "LBLEDIT",
        width: "150",
        sortable: false,
        renderCell: (row) => (
          <div className="icoBtn">
            <i
              className=""
              data-toggle="modal"
              onClick={this.openEditModal.bind(this, row.row)}
              alt={this.props.labels.labels.LBLEDIT}
            >
              {this.props.labels.labels.LBLEDIT}
            </i>
          </div>
        ),
      }
    ];

    // // Add the edit column conditionally based on this.state.drip_settings
    // if (this.state.drip_settings === true) {
    //   columns.push({
    //     headerName: this.props.labels.labels.LBLACTION !== undefined ? this.props.labels.labels.LBLACTION : "Action",
    //     field: "LBLEDIT",
    //     width: "150",
    //     sortable: false,
    //     renderCell: (row) => (
    //       <div className="icoBtn">
    //         <i
    //           className=""
    //           data-toggle="modal"
    //           onClick={this.openEditModal.bind(this, row.row)}
    //           alt={this.props.labels.labels.LBLEDIT}
    //         >
    //           {this.props.labels.labels.LBLEDIT}
    //         </i>
    //       </div>
    //     ),
    //   });
    // }

    return columns;
  };
  filterGroup = () => {
    const filteredVal = this.state.resultData.filter((item) => {
      return (
        item.name &&
        (item.name.includes(this.state.searchValue) ||
          item.name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()))
      );
    });
    this.setState({ 
      pageStatus : { isLoading: false, data: filteredVal.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: filteredVal.length },
      filteredValue:filteredVal
    });
    return filteredVal;
  }
  tableHeader = () => {
    let placeText =
      this.state.assignmentstate === "products"
        ? this.props.labels.labels.LBLSEARCHBYPRODNAME
        : this.props.labels.labels.LBLSEARCHBYNAMEEMAIL;
    let dropObj =  [
          {
            data: [
              { id: 1, value: 0, lable: this.props.labels.labels.LBLACTIONS !== undefined ? this.props.labels.labels.LBLACTIONS : "Actions", diasabled: true },
              { id: 2, value: 1, lable: this.props.labels.labels.LBLASSIGNUSER !== undefined ? this.props.labels.labels.LBLASSIGNUSER : "Assign users", diasabled:  this.state.selectedRowsCount > 0 ? false : true },
              { id: 3, value: 2, lable: this.props.labels.labels.LBLUNASSIGNUSER !== undefined ? this.props.labels.labels.LBLUNASSIGNUSER : "Unassign users", diasabled:  this.state.selectedRowsCount > 0 ? false : true  },
              { id: 4, value: 3, lable: this.props.labels.labels.LBLASSIGNGROUP !== undefined ? this.props.labels.labels.LBLASSIGNGROUP : "Assign groups", diasabled:  this.state.selectedRowsCount > 0 ? false : true  },
              { id: 5, value: 4, lable: this.props.labels.labels.LBLUNASSIGNGROUP !== undefined ? this.props.labels.labels.LBLUNASSIGNGROUP : "Unassign groups", diasabled: this.state.selectedRowsCount > 0 ? false : true }
            ],
            changeHandler: this.handlefilterStaus,
            selectedValue: this.state.statusFilter
          }
        ]
    return (
      <>
        <div className={this.state.assignmentstate === "users" ? styles.assignmentHeaderUsers : this.state.selectedRowsCount > 0 ? styles.assignmentHeader : styles.assignmentHeaderEnd}>
          {this.state.show === true ?
            <Modal
              id="training_Modal"
              show={this.state.show}
              backdrop="static"
              onHide={this.closeModal}
              size="xl"
              className={
                "animated  modal-right-side " +
                (this.state.show ? "fadeInRight" : "fadeOutRight")
              }
              style={{ overflowY: "scroll" }}
            >
              <Modal.Header closeButton className='alert-modal-header'>
                <Modal.Title className="page-title" id="exampleModalLongTitle">
                  {this.getLabel("LBLEDITPRODUCT")}
                </Modal.Title>
              </Modal.Header>
              <TrainingSettings
                {...this.props}
                initialValues={this.state.initialValues}
                closeModal={this.closeModal}
              ></TrainingSettings>
            </Modal> : ""}
          {this.state.assignmentstate === "products" &&
          <div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
            <input
              type="text"
              className="input-field"
              placeholder={placeText}
              value={this.state.searchText}
              onChange={this.searchUser}
              onKeyDown={this.searchUser}
            />
          </div>
          }
        </div>
        {this.state.selectedRowsCount > 0  && this.state.assignmentstate === "products" &&
        <TrainingManagementHeader 
          selectedRowsCount = {this.state.selectedRowsCount} 
          labels = {this.props.labels.labels}
          handleRowChange = {this.handleSelectAll}
          value={this.state.statusFilter}
          handlefilterStaus={this.handlefilterStaus}
          handleClear = {this.handleClear}
          assignmentstate = {this.state.assignmentstate}
          selectedRows = {null}
          dropObj = {dropObj}
          showdropdown = {true}
          selectAll={this.props.labels.labels.LBLSELECTALLCOURSES.replace("$$$","")}
          visibleRowsSelected = {this.state.visibleRowsSelected}
          visiblerowsselectedLabel = {this.props.labels.labels.LBLALLCOURSESSELECTED !== undefined ? this.props.labels.labels.LBLALLCOURSESSELECTED : `All courses on this page are selected.`}
        />
        }
      </>
    );
  };
  handlepaginationModalChange = (paginationVal) => {
    // console.log(paginationVal,"paginationVal");
    this.setState({paginationModel: paginationVal},() => {
      this.filterGroup();
      this.handleSortModelChange(this.state.sortModel);
    });
  }
   handleSelectionModelChange = (newRowSelectionModel) => {
    const selectedRows = newRowSelectionModel.map((id) =>
    this.state.resultData.find((row) => row.productid === id)
  );
    this.setState({
      rowSelectionModel: newRowSelectionModel,
      selectedRowsCount: newRowSelectionModel.length,
      selectedCourses:selectedRows,
    });
  };
  userDetTableHeader = () => {
    return (
      <div className="tableFilter">
        <div className="assignmentHeader">
          <input
            type="text"
            className="input-field"
            placeholder={
              this.state.assignmentstate === "users"
                ? this.props.labels.labels.LBLSEARCHBYGROUPNAME
                : this.state.assignmentstate === "groups"
                  ? this.props.labels.labels.LBLSEARCHBYNAMEEMAIL
                  : ""
            }
            value={this.state.searchText}
            onChange={this.searchUser}
            onKeyDown={this.searchUser}
          />
        </div>
      </div>
    );
  };

  setInitialValues = (item) => {
    this.setState({
      initialValues: {
        company_id: this.props.companyId,
        product_id: item.productid,
        name: item.name,
        type: item.type,
        language_id: item.language_id
      },
    });
  };

  openEditModal = (item, e) => {
    this.setInitialValues(item);
    this.setState({
      show: true,
      showTab: "info",
    });
  };
  closeModal = () => {
    this.setState({
      show: false,
    });
    try {
      $(".modal-backdrop").css({ position: "relative" });
    } catch (e) {
    }

  };

  ShowToast = (x) => {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: x,
        changeInToast: new Date(),
      },
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 1500);
    this.closeModal();

  };
  settingModalPagination = (newValue) => {
    // console.log(newValue,"newValue")
    this.setState({paginationModel: newValue })
  }
  render() {
    const {
      LBLCOURSEASSIGNMENT,
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLASSIGNMENTEMPTYTXT,
      // LBLPRODASSIGNORUNASSIGN,
    } = this.props.labels.labels;
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };

    if (this.state.assignmentstate === "products") {
      let columns = this.generateColumns();
      return (
        <div className="prodlist">
          <div >
            <h3 className="page-title">
              {LBLCOURSEASSIGNMENT}
            </h3>
          </div>
          <hr className="head-line" />
          {this.state.selectedRowsCount === 0 ?
            <div className="row" style={{paddingTop:"0px",paddingBottom:"10px"}}>
              {this.state.loading === false ? (
                  <span className="pageSubTitle" style={{paddingLeft:"15px"}}>{this.props.labels.errors.LBLSELANYPRODUCT !== undefined ? this.props.labels.errors.LBLSELANYPRODUCT : "Select courses to assign/unassign"}</span> 
              ) : (
                null
              )}

            </div>
            :
            null
          }
          <div>
            {this.state.loading ? (
              <div>
                <Skeleton height={34} />
                <Skeleton height={536} />
              </div>
            ) : (
              <>
                  <>
                    <div>{this.tableHeader()}</div>
                    <div style={{ marginTop: "10px" }}>
                      {this.state.pageStatus.data.length > 0 ?
                        <DataGrid
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                          },
                        }}
                        onSortModelChange={this.handleSortModelChange}
                        // initialState={{
                        //   sorting: {
                        //     sortModel: [{ field: 'name', sort: 'asc' }],
                        //   },
                        // }}
                          style={{ border: 'none' }}
                          className="cust-data-grid"
                          autoHeight
                          checkboxSelection
                          disableRowSelectionOnClick
                          // isRowSelectable={rowDisabledCriteria} // matched condition rows only enabled
                          getRowId={(row) => row.productid} // key value of the row item
                          columns={columns}
                          rows={this.state.pageStatus.data}
                          rowCount={this.state.pageStatus.total}
                          loading={this.state.pageStatus.isLoading}
                          pageSizeOptions={[10, 15, 20, 25, 30]}
                          pagination
                          paginationMode="server"
                          paginationModel={this.state.paginationModel}
                          onPaginationModelChange={this.handlepaginationModalChange}
                          onRowSelectionModelChange={this.handleSelectionModelChange}
                          rowSelectionModel={this.state.rowSelectionModel}
                          keepNonExistentRowsSelected
                          hideFooterSelectedRowCount
                          disableColumnMenu
                          slotProps={{
                            pagination: {
                              labelRowsPerPage: LBLROWSPERPAGE + ":"
                            }
                          }}
                          sx={{
                            '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                              outline: 'none',
                            },
                  
                            '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                              outline: 'none',
                            },
                         }}
                        />
                        :
                        <div style={{ marginTop: "5%" }}><EmptyState image={emptyImage} text2={LBLASSIGNMENTEMPTYTXT} /></div>
                      }
                    </div>
                  </>
                {this.state.showToast.show ? (
                  <ToastMessage
                    show={this.state.showToast["show"]}
                    helpers={this.state.showToast}
                  ></ToastMessage>
                ) : (
                  ""
                )}
              </>
            )}
            { }
          </div>
        </div>
      );
    }
    else if (this.state.assignmentstate === "users") {
      /****************** Enroll user details start***********************/
      let userTableHeader = this.tableHeader();
      let usergroupTableHeader = this.userDetTableHeader();
      return (
          <EnrollUser
            language_code={this.props.language_code}
            labels = {this.props.labels.labels}
            LBLSELANYUSER= {this.props.labels.errors.LBLSELANYUSER}
            paginationComponentOptions={paginationComponentOptions}
            productUsers={this.state.productUsers}
            userSearchValue={this.state.userSearchValue}
            loading={this.state.loading}
            userTableHeader={userTableHeader}
            getProducts={this.getProducts}
            handleCancel={this.handleCancel}
            updateTrainingAssignment={this.updateTrainingAssignment}
            getLabel={this.getLabel}
            updateUserAssignment={this.updateUserAssignment}
            ShowEnrolledDetails={this.ShowEnrolledDetails}
            showModalDetails={this.state.showModalDetails}
            enrollredDetails={this.state.enrollredDetails}
            CloseEnrolledDetails={this.CloseEnrolledDetails}
            usergroupTableHeader={usergroupTableHeader}
            groupSearchValueModal={this.state.groupSearchValueModal}
            statusFilter={this.state.statusFilter}
            value = {this.state.searchText}
            onChange =  {this.searchUser}
            selectedRowsCount= {this.state.selectedRowsCount}
            selectedRows = {this.state.selectedCourses}
            closeMoreModal= {this.closeMoreModal}
            assignmentstate={this.state.assignmentstate}
            />
      );
      /****************** Enroll user details End ***********************/
    } else if (this.state.assignmentstate === "groups") {
      // let groupTableHeader = this.groupTableHeader();
      let groupuserTableHeader = this.userDetTableHeader();
      const { productGroups } = this.state;
      return (
        <EnrollGroup
        language_code={this.props.language_code}
        labels = {this.props.labels.labels}
        productGroups={productGroups}
        groupSearchValue={this.state.groupSearchValue}
        userSearchValueModal={this.state.userSearchValueModal}
        loading={this.state.loading}
        LBLSELANYGROUP={this.props.labels.errors.LBLSELANYGROUP}
        handleCancel={this.handleCancel}
        updateTrainingAssignmentGrp={this.updateTrainingAssignmentGrp}
        updateGroupAssignment={this.updateGroupAssignment}
        showModalDetails={this.state.showModalDetails}
        getLabel={this.getLabel}
        ShowEnrolledDetails={this.ShowEnrolledDetails}
        CloseEnrolledDetails={this.CloseEnrolledDetails}
        enrollredDetails={this.state.enrollredDetails}
        paginationComponentOptions={paginationComponentOptions}
        // groupTableHeader={groupTableHeader}
        groupuserTableHeader={groupuserTableHeader}
        statusFilter={this.state.statusFilter}
        value = {this.state.searchText}
        onChange =  {this.searchUser}
        selectedRowsCount= {this.state.selectedRowsCount}
        selectedRows = {this.state.selectedCourses}
        closeMoreModal= {this.closeMoreModal}
        assignmentstate={this.state.assignmentstate}
        groupTypeInfo = {this.state.groupTypeInfo}
        selected_group_type = {this.state.selected_group_type}
        onChangeHandler = {this.filterOnChangeHandler}
        getProductGroups = {this.getProductGroups}
        group_type={this.state.group_type}
        />
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    language_code: state.user_info_reducer.user_info.language_code,
  };
};
export default connect(mapStateToProps, null)(ProdList);

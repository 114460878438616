import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './AcitivitiesModalPopup.scss';

class ActivitiesModalpopup extends Component {
    constructor(props) {
        super(props);

        this.handleClickActivities = this.handleClickActivities.bind(this);
    }

    handleClickActivities = (j, res) => {
        if (res.capsule_content_type == "RYS") {
            let xurl = window.location.href
            xurl = xurl.substr(0, xurl.lastIndexOf("/")) + "/rate-state#" + res.encref
            window.location.href = xurl
        } else if (res.capsule_content_type == "LEARNINGOBJECT") {
            this.props.showCourseDetailsfn(this.props.selectedRowID, "Capsule", res)
        } else {

        }

    }

    render() {
        const PropsData = this.props;
        return (
            <Modal show={PropsData.Showmodal}
                onHide={PropsData.closemodal}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 className="modal-title">{PropsData.ModalTiltle}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        PropsData.DatatableData.length === 0 ?
                            <div className="activitynorecords">
                                {PropsData.Norecords}
                            </div>
                            :
                            <>
                                <div className="activitiesheader">
                                    {PropsData.DataTableHeader}
                                </div>
                                <table className="table table-hover tblacitvities">
                                    <tbody>
                                        <tr>
                                            <th>{PropsData.labels.LBLNAME}</th>
                                            <th>{PropsData.labels.LBLSTATUS}</th>
                                            <th>{PropsData.labels.LBLCOMPLETIONDATE}</th>
                                        </tr>
                                        {
                                            PropsData.DatatableData.map((res, j) => {
                                                if (res.trigger_status === 3) // 3 - 'Completed'
                                                    res.activityHide = false;
                                                else
                                                    res.activityHide = true;

                                                return <tr key={j} className={
                                                    ((j === 0 && res.trigger_status === 1) || (j !== 0 && res.trigger_status === 1
                                                        && PropsData.DatatableData[j - 1].activityHide === false))
                                                        ? 'activityenabled'
                                                        : res.trigger_status === 3 ? 'activitycompleted'
                                                            : 'activitydisabled'}

                                                    onClick={() =>
                                                        ((j === 0 && res.trigger_status === 1) || (j !== 0 && res.trigger_status === 1
                                                            && this.props.DatatableData[j - 1].activityHide === false)) ?
                                                            this.handleClickActivities(j, res) : ''
                                                    }>
                                                    <td>{res.capsule_content_name}</td>
                                                    <td>
                                                        {
                                                            res.trigger_status == 3 ?
                                                                PropsData.labels.LBLCOMPLETED
                                                                : res.trigger_status == 2 ?
                                                                    PropsData.labels.LBLINPROGRESS
                                                                    : res.trigger_status == 1 ?
                                                                        PropsData.labels.LBLNOTSTARTED
                                                                        : ''
                                                        }
                                                    </td>
                                                    <td>{res.completed_date}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </>
                    }
                </Modal.Body>
            </Modal>
        )
    }
}
export default ActivitiesModalpopup;
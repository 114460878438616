import labels from './translate_components';
import commonLabels from './common_labels';
import translate_fetch from './translate_fetch';

class Translator {
    getlabels() {
        let comp_name = arguments[0].comp_name;
        let lang_code = arguments[0].lang_code;
        let tr_labels;
        if (!comp_name.includes("reports")) {
            tr_labels = labels[comp_name].data[lang_code] ? labels[comp_name].data[lang_code] : labels[comp_name].data['en-US'];
        } else if (comp_name.includes("reports")) {
            comp_name = "reports";
            tr_labels = labels[comp_name].data[lang_code] ? labels[comp_name].data[lang_code] : labels[comp_name].data['en-US'];
        }
        if (labels[comp_name].data1) {
            let tr_labels1 = labels[comp_name].data1[lang_code] ? labels[comp_name].data1[lang_code] : labels[comp_name].data1['en-US'];
            tr_labels.buttons = { ...tr_labels.buttons, ...tr_labels1.buttons }
            tr_labels.labels = { ...tr_labels.labels, ...tr_labels1.labels }
            tr_labels.errors = { ...tr_labels.labels, ...tr_labels1.errors }
        }
        return tr_labels;



    }
    async getComponentsLabels(component, language) {

        let componentLabels = await translate_fetch.getLabels({
            comp_name: component,
            lang_code: language ? language : "en-US",
            url: process.env.REACT_APP_YOUFACTORS_TRANSLATION_URL + 'admin/components/' + component + '/' + component + '.json'
        });
        return componentLabels;
    }

    getCommonLabels() {
        let lang_code = arguments[0].lang_code;
        let commonlabels = commonLabels.data[lang_code] ? commonLabels.data[lang_code] : commonLabels.data['en-US'];
        return commonlabels;
    }
}

export default new Translator();
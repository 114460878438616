import React, { useState } from "react";
import CompanyLogo from "../admin/components/companylogo/companylogo";
import common_services from "../services/common_services";
import GenerateAgain from "./GenerateAgain";
import InviteCodeRegistration from "./InviteCodeRegistration";
import ToastMessage from "../shared/ToastComponent/ToastComponent";
import "./registration.scss";
import { Link } from "react-router-dom";

function NewUserRegistration(props) {
  const { labels } = props.labels;
  const [showGenAgain, setGenAgain] = useState(false);
  const [toast_message, show_toast] = useState({
    show: false,
    alertClass: "",
    status: "",
    message: "",
    changeInToast: new Date(),
  });
  console.log(labels);

  let registrationSubmit = (values) => {
    let data = {
      otp: values.invitecode,
      user_attribute: values.email,
    };

    common_services
      .registerUser(data)
      .then((result) => {
        console.log(result);
        props.history.push({
          pathname: "/forgot-password",
          state: { user_attribute: values.email, code: true },
        });
      })
      .catch((error) => {
        console.log(error);
        show_toast({
          show: true,
          alertClass: "success",
          status: "success",
          message: labels[error.message],
          changeInToast: new Date(),
        });
        toastOff();
      });
  };
  let genCodeAgain = (values) => {
    let data = {
      user_attribute: values.email,
    };
    common_services
      .generateCode(data)
      .then((result) => {
        console.log(result);
        show_toast({
          show: true,
          alertClass: "success",
          status: "Success",
          message: labels.LBLEMAILSENTSUCCESS,
          changeInToast: new Date(),
        });
        switchToGenerateAgain();
        toastOff();
      })
      .catch((error) => {
        console.log(error);
        show_toast({
          show: true,
          alertClass: "success",
          status: "success",
          message: labels[error.message],
          changeInToast: new Date(),
        });
        toastOff();
      });
  };
  let toastOff = () => {
    setTimeout(() => {
      show_toast({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 1500);
  };
  let switchToGenerateAgain = () => {
    setGenAgain(!showGenAgain);
    return false;
  };

  return (
    <div className="setpassword-in-section">
      <div className="card">
        <div className="card-body">
          <div className="container text-center">
            {props.company_logo ? (
              <CompanyLogo
                className="applogo"
                imageName={props.company_logo}
                imgWidth="317px"
              />
            ) : (
              ""
            )}
          </div>
          <h3 className="reg-lbl">
            {!showGenAgain ? labels.LBLINVITECODEREG : labels.LBLNEWINVITECODE}
          </h3>
          <ToastMessage
            show={toast_message["show"]}
            helpers={toast_message}
          ></ToastMessage>
          {!showGenAgain ? (
            <InviteCodeRegistration
              RegistrationSubmit={registrationSubmit}
              {...props}
            ></InviteCodeRegistration>
          ) : (
            <GenerateAgain
              {...props}
              GenerateCode={genCodeAgain}
            ></GenerateAgain>
          )}

          <div className="text-center links">
            <a href="#/" onClick={switchToGenerateAgain}>
              {!showGenAgain ? labels.LBLGENERATENEW : labels.LBLBACK}
            </a>

            {!showGenAgain ? (
              <Link
                to={{
                  pathname: "/",
                }}
              >
                {labels.LBLLOGIN}
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewUserRegistration;

import React from "react";
import "./coursepopup.scss";
import { connect } from "react-redux";
import $ from 'jquery';

class Coursepopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPopupData: [], closePopup: [], courseengineUrl: "" };
    this.LoadCourse = this.LoadCourse.bind(this);
    this.GetDatafromCourseengine = this.GetDatafromCourseengine.bind(this);
  }

  componentDidMount = () => {
    let coursedt = {
      CourseEngineAvailable: "ON",
      productid: this.props.Params1,
      unitid: this.props.Params2,
      unitedetails: this.props.unitedetails,
      courseName: this.props.courseName
    };
    this.LoadCourse(coursedt);
    this.GetDatafromCourseengine(coursedt);
  };

  componentWillUnmount = () => {
    document.body.style.overflow = "auto";
  };

  LoadCourse(coursedt) {
    var vUserid = this.props.userid;
    var vCompanyid = this.props.companyid;
    var vProductid = coursedt.productid;
    var vUnitid = coursedt.unitid;
    var vCourseID = coursedt.unitedetails.courseid;
    var vCourseCompletionStatus = coursedt.unitedetails.unitcompletionstatus;
    var vUnitname = coursedt.unitedetails.unitname ? coursedt.unitedetails.unitname.split(" ").join("_&_") : '';
    var vUnitdesc = coursedt.unitedetails.unitdesc ? coursedt.unitedetails.unitdesc.split(" ").join("_&_") : '';
    var vUunitsinfoid = coursedt.unitedetails.unitsinfoid;
    var vCourseVersion = coursedt.unitedetails.courseversion;
    var vLanguageCode = coursedt.unitedetails.languagecode;
    var vActivityid = coursedt.unitedetails.activityid;
    var vCapsuleid = coursedt.unitedetails.capsuleid;
    var vTrainingtype = coursedt.unitedetails.trainingType;
    if (coursedt.CourseEngineAvailable === "ON") {
      this.CourseDetails = {
        courseid: vCourseID,
        userId: JSON.parse(vUserid),
        companyid: JSON.parse(vCompanyid),
        unitsinfoid: vUunitsinfoid,
      };
      let vOrigin = window.location.origin.split(".");
      vOrigin = vOrigin[0].split("//");
      vOrigin = vOrigin[1];
      let youthLightURL = "";
      let scToken = ""; // res.data[1];
      let vCourseEngineUrlData = "",
        vCourseParams = ""; // process.env.REACT_APP_COURSEENGINE_URL;
      var vCurrentPage = window.location.href.split("/");
      vCurrentPage = vCurrentPage.slice(vCurrentPage.length - 1); //current Pagename only set here
      if (coursedt.unitedetails.engineType === "Capsule") {
        vCourseEngineUrlData = process.env.REACT_APP_CAPSULEENGINE_URL;
        vCourseParams =
          vCourseID +
          "&&" +
          vProductid +
          "&&" +
          vUnitid +
          "&&" +
          scToken +
          "&&" +
          vOrigin +
          "&&" +
          vUserid +
          "&&" +
          vCompanyid +
          "&&" +
          vUnitname +
          "&&" +
          vUnitdesc +
          "&&" +
          vCourseCompletionStatus +
          "&&" +
          vLanguageCode +
          "&&web&&" +
          vCurrentPage +
          "&&" +
          vUunitsinfoid +
          "&&" +
          vCourseVersion +
          "&&" +
          vActivityid +
          "&&" +
          vCapsuleid;
      } else {
        if (
          coursedt.unitedetails.engineVersion !== null &&
          coursedt.unitedetails.engineVersion == "2"
        ) {
          let isModule = this.props.isModule ? (typeof this.props.isModule !== 'string' ? this.props.isModule.toString() :  this.props.isModule) : false
          vCourseEngineUrlData = process.env.REACT_APP_COURSEENGINE2_URL;
          vTrainingtype = null;
          vCourseParams =
            vCourseID +
            "&&" +
            vProductid +
            "&&" +
            vUnitid +
            "&&" +
            scToken +
            "&&" +
            vOrigin +
            "&&" +
            vUserid +
            "&&" +
            vCompanyid +
            "&&" +
            vUnitname +
            "&&" +
            vUnitdesc +
            "&&" +
            vCourseCompletionStatus +
            "&&" +
            vLanguageCode +
            "&&web&&" +
            vCurrentPage +
            "&&" +
            vUunitsinfoid +
            "&&" +
            vCourseVersion +
            "&&" +
            vActivityid +
            "&&" +
            vTrainingtype +
            "&&name&&" +
            encodeURI(coursedt.courseName) + 
            "&&game&&" +
            this.props.gameAvailable +
            "&&ismodule&&" +
            isModule +
            "~~~~" +
            encodeURI(this.props.resultDataString) +
            "~~~~" +
            this.props.dateformat;
        } else if (
          (coursedt.unitedetails.engineVersion &&
            coursedt.unitedetails.engineVersion == "1") ||
          !coursedt.unitedetails.engineVersion
        ) {
          vCourseEngineUrlData = process.env.REACT_APP_COURSEENGINE_URL;
          vCourseParams =
            vCourseID +
            "&&" +
            vProductid +
            "&&" +
            vUnitid +
            "&&" +
            scToken +
            "&&" +
            vOrigin +
            "&&" +
            vUserid +
            "&&" +
            vCompanyid +
            "&&" +
            vUnitname +
            "&&" +
            vUnitdesc +
            "&&" +
            vCourseCompletionStatus +
            "&&" +
            vLanguageCode +
            "&&web&&" +
            vCurrentPage +
            "&&" +
            vUunitsinfoid +
            "&&" +
            vCourseVersion +
            "&&" +
            vActivityid;
        } else if (coursedt.unitedetails.engineVersion === "3") {          
          youthLightURL =
            process.env.REACT_APP_YOUTHLIGHT_URL + vProductid+"/index.html";
        }
      }
      let iframeTag = "";
      iframeTag = document.createElement("iframe");
      iframeTag.id = "courselaunchpopup";
      if (coursedt.unitedetails.engineType !== "Capsule")
        iframeTag.className = "iframestyle";
      else iframeTag.className = "iframestylecap";
      iframeTag.src = youthLightURL
        ? youthLightURL
        : vCourseEngineUrlData + "#" + vCourseParams;
      this.setState({
        courseengineUrl: vCourseEngineUrlData,
      });
      var vCoursecontent = document.getElementById("coursecontent");
      vCoursecontent.classList.add("showcourse");
      vCoursecontent.classList.remove("hidecourse");
      if (document.getElementById("iframediv") != null) {
        document.getElementById("iframediv").innerHTML = "";
      }
      let courseLaunchPopup1 = document.getElementById("iframediv");
      courseLaunchPopup1.appendChild(iframeTag);
      iframeTag.setAttribute("allowFullScreen", "true");
      document.body.style.overflow = "hidden";
      iframeTag.onunload = () => {
        let vCloseurl = window.location.url;
        if (vCloseurl !== undefined && vCloseurl.indexOf("/close") > -1)
          this.GetDatafromCourseengine("close");
      };
      if (coursedt.unitedetails.engineVersion === "3") {          
        let close = $('<div class="coursepopup-closebtn">X</div>');
        $("#iframediv").append(close);
        close.on('click', ()=>{
          document.getElementById("iframediv").innerHTML = "";
          $("#mclosepopup").trigger('click');          
        })
      }
    }
  }

  GetDatafromCourseengine(coursedt, vClose) {
    // View Full Screen
    var isInFullScreen =
      (document.fullScreenElement && document.fullScreenElement !== null) || // alternative standard method
      document.mozFullScreen ||
      document.webkitIsFullScreen;
    var docElm = document.documentElement;
    if (isInFullScreen) {
      // if (coursedt.unitedetails.engineType !== "Capsule") {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
        // alert("This will take you to a fullscreen mode");
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
        alert("This will take you to a fullscreen mode");
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
        alert("This will take you to a fullscreen mode");
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
        alert("This will take you to a fullscreen mode");
      }
    }
    if (vClose !== undefined && vClose === "Close") {
      var vmClosepopup = document.getElementById("mclosepopup");
      if (vmClosepopup != null) {
        vmClosepopup.click();
      }
    }
    window.addEventListener("message", function (evt) {
      if (
        evt.data !== undefined &&
        evt.data !== "" &&
        document.getElementById("coursemodalpopup") !== null
      ) {
        // console.log("cloase3543656547"+this.props, evt);
        var vCoursecontent = document.getElementById("coursecontent");
        vCoursecontent.classList.remove("showcourse");
        vCoursecontent.classList.add("hidecourse");
        document.body.removeAttribute("style");
        var isInFullScreen =
          (document.fullScreenElement && document.fullScreenElement !== null) || // alternative standard method
          document.mozFullScreen ||
          document.webkitIsFullScreen;
        if (isInFullScreen) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
        var vmClosepopup = document.getElementById("mclosepopup");
        if (vmClosepopup != null) {
          vmClosepopup.click();
        }
      }
    });
  }

  render() {
    return (
      <div id="coursemodalpopup">
        {
          <div id="coursecontent" className="coursepopup">
            <div id="iframediv"></div>
            {this.props.Params1 === undefined ? (
              ""
            ) : (
              <span id="mclosepopup" onClick={this.props.closePopup}></span>
            )}
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userid: state.user_info_reducer.user_info.user_id,
    companyid: state.user_info_reducer.user_info.company_id,
    roleid: state.user_info_reducer.user_info.role_Id,
    languagecode: state.user_info_reducer.user_info.language_code,
  };
};

export default connect(mapStateToProps, null)(Coursepopup);

import React from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import utils from "../../../utils/utils";
import { S3MultiUpload } from "../../../shared/s3upload";
import { Form } from "react-bootstrap";
import orderBy from "lodash/orderBy";
import { connect } from "react-redux";
import NearMissService from "./NearMissService/nearMissService";
import GroupServices from "../group-list/GroupServices";
import Loader from "../../components/loader/Loader";
import FilterComp from "../../../common_components/filter/filterComp";
import FeedsServices from "../../../ssifeeds/feedService/feedservices";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import DateFnsUtils from "@date-io/date-fns";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import "./NearMiss.scss"
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  required,
  maxLength255,
} from "../validate/Validate";
import emptyImage from '../../../assets/images/nearmiss_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import { Autocomplete } from "@material-ui/lab";

const $ = require("jquery");
let changesIn = {};
let loadType = false, loadLoc = false, loadDepart = false;
let objLabels = [];
class NearMiss extends React.Component {
  constructor(props) {
    super(props);
    objLabels = props.labels.labels;
    this.state = {
      file: "",
      imagePreviewUrl: "",
      attachedFiles: [],
      fileAttachRef: "",
      fileNameRef: "",
      userlist: [],
      attachedImageUrls: [],
      roles: [],
      dateformat: props.dateformat,
      visible: "none",
      incclosed: false,
      loadingupdate: false,
      orgs: [],
      showaid: false,
      groups: [],
      showtime: 3000,
      loading: true,
      isemployee: false,
      show: false,
      selflist: false,
      showFilter: false,
      status: 0,
      initialValues: null,
      ssi_errors: null,
      searchValue: "",
      roleFilter: "",
      groupData: [],
      group_type: "",
      roleValid: false,
      mailPhoneValid: false,
      groups_by_type: [],
      helpersLoaded: false,
      showTab: "info",
      allFilterValues: [],
      listData: [],
      singleObj: [],
      showlist: "none",
      showadd: true,
      submitClicked: false,
      showedit: false,
      labels: [],
      loading: false,
      currentContent: 1,
      selected: 1,
      helperSourceDesc: "",
      helperText: "",
      helperType: "",
      showhabits: false,
      subhabits: false,
      selwork: false,
      selhome: false,
      value: 1,
      selected_image: 0,
      source_images: ["../../images/nearmiss/Unselected.png",
        "../../images/nearmiss/Self.png",
        "../../images/nearmiss/Other_People.png",
        "../../images/nearmiss/Equipment.png"],
      sources: ["", props.labels.labels.LBLSOURCE1, props.labels.labels.LBLSOURCE2, props.labels.labels.LBLSOURCE3],
      title: props.initialValues ? props.initialValues.incident_title : "",
      selectedValue: props.initialValues ? props.initialValues.status : "1",
      type: props.initialValues ? [props.initialValues.incident_type] : "",
      source: props.initialValues ? [props.initialValues.incident_source] : "",
      severity: props.initialValues
        ? [props.initialValues.incident_severity]
        : "",
      location: props.initialValues  ? [props.initialValues.location] : "",
      department: props.initialValues  ? [props.initialValues.department] : "",
      place: props.initialValues ? [props.initialValues.incident_place] : "",
      shift: props.initialValues ? [props.initialValues.shift] : "0",
      selectedStateItems: props.initialValues
        ? props.initialValues.incident_state
        : [],
      selectedErrorItems: props.initialValues
        ? props.initialValues.incident_error
        : [],
      selectedCAItems: props.initialValues
        ? props.initialValues.corrective_actions
        : [],
      summary: props.initialValues
        ? props.initialValues.incident_summary.summary_value.toString()
        : "",
      selother: props.initialValues
        ? props.initialValues.corrective_actions_other
        : "",
      rowid_id: props.initialValues ? props.initialValues._id : "",
      selsummary: "",
      inclocation: "",
      seltitle: "",
      seltype: "",
      selseverity: "",
      sellocation: "",
      seldepartment: "",
      selplace: "",
      selshift: "",
      selstate: "",
      selserror: "",
      selcAction: "",
      caerrors: "",
      castates: "",
      cActions: "",
      selcaerrors: "",
      selcastates: "",
      selcActions: "",
      selectedDate: new Date(),
      selectedTime: new Date(Date.now()).getHours(),
      allGroups: [],
      selectedpermission: "1",
      open: false,
      selectedIndex: "6",
      transition: "",
      message: "",
      attachedImages: [],
      messageType: "success",
      selCAothers: true,
      selected: false,
      isload: true,
      selectedShift: "0",
      selectedPrevSource: "",
      selectedSource: "",
      selectedPlace: "1",
      Data: [],
      loadingadd: true,
      selreset: false,
    };
    this.child = React.createRef();
    this.deletedImages = [];
    this.isFileUploadFinish = 1;
    this.fileNameWithTimeStamp = 0;
    this.fileAttachRef = React.createRef();
    this.fileNameRef = React.createRef();
    this.fileAccptance = ".jpg, .jpeg, .png, .gif";
  }
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat === "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat === "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat === "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
  };
  componentDidMount() {
    this.getNearMissList(false);
    this.getNearMisCollections();
    $(".mui-selected").css({ background: "#fff" })
    // let xx = process.env.REACT_APP_Domain_URL;
    this.setdateformat();
    if ($("._7ahQImyV4dj0EpcNOjnwA")) {
      $("#date-picker-inline")
        .next()
        .find(".MuiIconButton-label")
        .css({ color: "#61a9dc" });
      $("#time-picker")
        .next()
        .find(".MuiIconButton-label")
        .css({ color: "#61a9dc" });
      $("._7ahQImyV4dj0EpcNOjnwA").css({
        padding: "4px 1px",
        "margin-left": "-10px",
      });
      $("#txtCA").css({ color: "#61a9dc", fontSize: "14px" });
      $(".rdt_TableHeadRow").css({ "background-color": "#f3f2f2" });
    }

    $("#Type_input").css({ width: "400px" });

    let { location, department } = this.state;

    if (this.props.initialValues != null) {
      location = [
        {
          location_id: location[0].location_id,
          group_name: location[0].location_name,
        },
      ];
      department = [
        {
          department_id: location[0].department_id,
          group_name: department[0].department_name,
        },
      ];

      this.setState({
        location: location,
        department: department,
      });
    }
    this.loadimgdetails();
  }
  replacelabels = () => {
    let { listData } = this.state;
    if (listData) {
      listData.incident_type =
        listData.incident_type.incident_type_name.indexOf("LBL") > -1
          ? this.props.labels.labels(listData.incident_type.incident_type_name)
          : listData.incident_type.incident_type_name;
      listData.incident_severity =
        listData.incident_severity.incident_severity_name.indexOf("LBL") > -1
          ? this.props.labels.labels(
            listData.incident_severity.incident_severity_name
          )
          : listData.incident_severity.incident_severity_name;

      this.setState({
        listData: listData,
      });
    }
  };
  componentDidUpdate() {
    if ($("._7ahQImyV4dj0EpcNOjnwA")) {
      $(".optionContainer li").removeClass("lhyQmCtWOINviMz0WG_gr");
      $("._7ahQImyV4dj0EpcNOjnwA").css({
        padding: "4px 3px",
        "margin-left": "-10px",
      });
      $("#Date").next().find(".MuiIconButton-label").css({ color: "#61a9dc" });
      $("#Time").next().find(".MuiIconButton-label").css({ color: "#61a9dc" });
      $("#Title-label").find("span").remove();
      $("#Date").css({ "font-size": "14px" });
      $("#Time").css({ "font-size": "14px" });
    }
    $(".rdt_TableHeadRow").css({ "background-color": "#f3f2f2" });
    let { isload, incclosed, type } = this.state;
    if (isload) {
      setTimeout(() => {
        this.onloadchanges();
      }, 500);
    }
    $("#menuList li:nth-child(4)").hide();
    if (type[0] && type[0].incident_type_id == "incident_type_01") {
      $("#menuList li:nth-child(4)").show();
    }
    if (incclosed) {
      $(".cls_grouping").css({ "pointer-events": "none" });
    } else {
      $(".cls_grouping").css({ "pointer-events": "all" });
    }
    $(document).ready(function () {
      $("#Type_input").css({ width: "400px" });
      $("#Type_input").prop("tabindex", "3");
    });


    $("#main")
      .unbind("click")
      .click(function (evt) {
        if ((evt.target.id != "Type_input" &&
          evt.target.id != "Location_input" &&
          evt.target.id != "Department_input")

        ) {
          $("#Type .optionListContainer").toggle(false);
          $("#Location .optionListContainer").toggle(false);
          $("#Department .optionListContainer").toggle(false);
        }
        else if (evt.target.id == "Type_input" ||
          (evt.target.className.indexOf("search-wrapper") >= 0 && evt.target.parentElement.id == "Type")) {
          $("#Type .optionListContainer").toggle(true);
          $("#Location .optionListContainer").toggle(false);
          $("#Department .optionListContainer").toggle(false);
        }
        else if (evt.target.id == "Location_input" ||
          (evt.target.className.indexOf("search-wrapper") >= 0 && evt.target.parentElement.id == "Location")) {
          $("#Location .optionListContainer").toggle(true);
          $("#Type .optionListContainer").toggle(false);
          $("#Department .optionListContainer").toggle(false);
        }
        else if (evt.target.id == "Department_input" ||
          (evt.target.className.indexOf("search-wrapper") >= 0 && evt.target.parentElement.id == "Department")) {
          $("#Department .optionListContainer").toggle(true);
          $("#Type .optionListContainer").toggle(false);
          $("#Location .optionListContainer").toggle(false);
        }
      });

    $(".searchBox").prop({ "disabled": false });
  }
  applyFilters = (filter) => {
    console.log("filter", filter)
    this.setState({ allFilterValues: filter, showNode: true });
    if (!filter.reset) {
      this.setState({ visible: "block" });
    }
    $(".bounceInDown").hide();
    $(".modal-backdrop").css({ position: "relative" });
    $(".modal-backdrop.show").css({ opacity: "0" });

    $("#root").css({ overflowY: "auto", overflowX: "hidden" });
  };
  keyvaluechanges = (response) => {
    let keys1,
      keys2,
      allGroups,
      objAllGroups = [];
    let groupname,
      grouptypeid,
      objTypes, objSources,
      objSeverity,
      singles = [];
    let objStatus = [
      { name: "LBLOPEN", id: "1" },
      { name: "LBLCLOSED", id: "0" },
    ];

    allGroups = response; //.groups;
    let { singleObj } = this.state;
    objSources = singleObj.incident_source;
    objTypes = singleObj.incident_type;
    objSeverity = singleObj.severity;

    objTypes = JSON.parse(
      JSON.stringify(objTypes)
        .split('"incident_type_id":')
        .join('"id":')
        .split('"incident_type_name":')
        .join('"name":')
    );
    objSources = JSON.parse(
      JSON.stringify(objSources)
        .split('"incident_source_id":')
        .join('"id":')
        .split('"incident_source_name":')
        .join('"name":')
    );
    objSeverity = JSON.parse(
      JSON.stringify(objSeverity)
        .split('"incident_severity_id":')
        .join('"id":')
        .split('"incident_severity_name":')
        .join('"name":')
    );
    singles.push({
      items: objSources,
      itemsinfo: {
        itemid: "incidentsource",
        itemname: "ERRORSOURCE",
        label: this.props.labels.labels["LBLERRORSOURCE"],
        trans: true,
      },
    });
    singles.push({
      items: objTypes,
      itemsinfo: {
        itemid: "incidenttype",
        itemname: "incident Type",
        label: "LBLINCTYPES",
        trans: false,
      },
    });

    singles.push({
      items: objSeverity,
      itemsinfo: {
        itemid: "incidentseverity",
        itemname: "incident Severity",
        label: "LBLSEVERITY",
        trans: false,
      },
    });
    singles.push({
      items: objStatus,
      itemsinfo: {
        itemid: "status",
        itemname: "status",
        label: "LBLSTATUS",
        trans: true,
      },
    });
    if (typeof allGroups == "object") {
      keys1 = Object.keys(allGroups);
      allGroups.sort(function (a, b) {
        return a.group_type.split("_")[1] - b.group_type.split("_")[1];
      });
      allGroups.map((element, i) => {
        if (i > 1) return false;
        if (typeof element == "object") {
          keys2 = Object.keys(element);
          if (typeof element.group_type != "undefined") {
            if (typeof element == "string") {
              grouptypeid = JSON.parse(element.group_type);
              groupname = JSON.parse(element.group_type);
            } else if (typeof element == "object") {
              grouptypeid = element.group_type;
              groupname = element.group_type;
            } else {
              groupname = "Group";
            }
            let groups = "",
              loclabels = ["LBLLOCATION", "LBLDEPARTMENT"];
            if (typeof element.groups == "string") {
              groups = element.groups
                .split('"group_id":')
                .join('"id":')
                .split('"group_name":')
                .join('"name":');
              JSON.parse(
                objAllGroups.push({
                  items: groups,
                  itemsinfo: {
                    itemid: groupname,
                    itemname: groupname,
                    label: groupname,
                  },
                })
              );
            } else {
              groups = JSON.parse(
                JSON.stringify(element.groups)
                  .split('"group_id":')
                  .join('"id":')
                  .split('"group_name":')
                  .join('"name":')
              );
              objAllGroups.push({
                items: groups,
                itemsinfo: {
                  itemid: groupname,
                  itemname: loclabels[i],
                  label: this.props.labels.labels[loclabels[i]],
                },
              });
            }
          }
        }
        return null;
      });
    }
    objAllGroups.splice(2, 1);
    objAllGroups.splice(1, 1);
    this.setState({
      toFilters: {
        multi: objAllGroups,
        single: singles,
        loading: false,
      },
    });

    return objAllGroups;
    // response.groups[0].groups_by_type.split('"group_id":').join('"id":').split('"group_name":').join('"name":')
  };

  showhidepages = (currId, mode) => {
    let { showadd, Data } = this.state;
    let allSources;
    if (currId == "showlist") {
      $("#nearmissList").hide();
      //  $("#nearmissList").slideUp(1000);

      setTimeout(() => {
        $("#addNearMiss").show();
        this.setState({ loadingadd: false });
      }, 100);
    } else if (currId == "showlistadd") {
      //$("#addNearMiss").slideUp(1000);
      if (showadd) {
        allSources = Data.source;
        Data.source.map(i => i.incident_source_desc = "");
      }
      $("#addNearMiss").hide();
      $("#nearmissList").show();
      this.setState({ incclosed: false, Data });
    }

    if (mode == 1) {
      setTimeout(() => {
        //   this.showAlert("success", this.props.labels.labels.LBLUPDTSUCC);
      }, 1000);
    }
    if (mode == 3) {
      this.setState({
        selhome: false,
        selwork: false
      });
    }

    if (mode == 4) {
      this.setState({
        showadd: false,
        loading: false,
        showedit: false,
        selhome: false,
        selwork: false,
      });
    }
    $(".sc-fzpans").css({ "font-size": "10px", "margin-top": "3px" });
    $(".doBktq").css({ "border-bottom-color": "#a5a2a21f" });
    $(".rdt_TableCol").css({ "font-size": "15px" });
    $(".rdt_TableHeadRow").css({ "background-color": "#f3f2f2" });
    $("._32r8xHW3WLH5mygPuyDIBF").css({ "margin-left": "-10px" });
  };
  resetValidations = () => {
    this.setState({
      helperSource: "",
      helperTitle: "",
      helperType: "",
      helperSeverity: "",
      helperPlace: "",
      helperLocation: "",
      helperDepartment: "",
      helperShift: "",
      helperincLocation: "",
      helperDate: "",
      helperTime: "",
      helperSummary: "",
      helperCActions: "",
      helperErrors: "",
      helperStates: "",
      error: false,
      errorType: false,
      errorDes: false,
    });
  }
  resetDetails = () => {
    let { Data } = this.state;

    Data.incident_states.map((m) => (m.isNewAssigned = 0));
    Data.incident_errors.map((m) => (m.isNewAssigned = 0));
    Data.corrective_actions.map((m) => (m.isNewAssigned = 0));

    Data.incident_place.map((m) => {
     return m.isNewAssigned = 0;
    });
    let menuclass = [0, 1, 2, 3];
    menuclass.map((i, j) => {
     return $("#Placeid" + (i + 1)).css({ filter: "" });
    }); //("cls_highlight"+i)})

    $("#preview").html("");
    this.setState({
      showadd: true,
      attachedImageUrls: [],
      title: "",
      selectedValue: "1",
      type: [],
      severity: [],
      location: [],
      department: [],
      place: [],
      shift: [],
      selectedShift: "1",
      selectedStateItems: [],
      selectedErrorItems: [],
      selectedCAItems: [],
      summary: "",
      inclocation: "",
      selother: "",
      rowid_id: "",
      initialValues: null,
      Data: Data,
      NearMissDetails: [],
      selreset: true,
      helperSource: "",
      helperTitle: "",
      helperType: "",
      helperSeverity: "",
      helperPlace: "",
      helperLocation: "",
      helperDepartment: "",
      helperShift: "",
      helperincLocation: "",
      helperDate: "",
      helperTime: "",
      helperSummary: "",
    });
  };

  getGroupList = (selGroupTypeId) => {
    let apiUrl = "groupstype";
    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "get",
      this.productGroupResultHandler
    );
  };
  getNearMisCollections = (evt) => {
    let apiUrl = "nearmiss/incidentmasters";
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getNearMisCollectionsHandler,
      ""
    );
  };
  getNearMisCollectionsHandler = (response) => {
    let other = true,
      Data = [],
      DataLabels = [],
      DataLabels1 = response.data.nearmiss_master,
      allData = response.data.nearmiss_master;
    if (allData.length == 0) {
      return false;
    }
    let allGroupsResponse = response.data.groups;
    // let allUserResponse = response.data.userlist;
    for (var i = 0, len = DataLabels1.length; i < len; i++) {
      DataLabels[i] = JSON.parse(JSON.stringify(DataLabels1[i])); // empty object to hold properties added below

    }
    allData.map((item, idx) => {
      if (item.type == "LBLINCIDENTTYPE") {
        let xx = this.labelTranslation(item.values, "incident_type_name");
        Data.incident_type = item.values;
      } else if (item.type == "LBLINCSOURCE") {
        //let xx = this.labelTranslation(item.values, "incident_source_name");
        Data.source = item.values;
      }
      else if (item.type == "LBLINCIDENTSEVERITY") {
        this.labelTranslation(item.values, "incident_severity_name");
        Data.severity = item.values;
      } else if (item.type == "locations") {
        Data.location = item.values;
      } else if (item.type == "LBLDEPARTMENT") {
        Data.department = item.values;
      } else if (item.type == "LBLINCIDENTPLACE") {
        this.labelTranslation(item.values, "incident_place_name");
        Data.incident_place = item.values;
      } else if (item.type == "LBLSHIFT") {
        this.labelTranslation(item.values, "shift_name");
        Data.shift = item.values;
      } else if (item.type == "LBLINCIDENTSTATES") {
        this.labelTranslation(item.values, "incident_state_name");
        Data.incident_states = item.values;

        let { selectedStateItems } = this.state;
        let idx,
          locState = Data.incident_states;
        if (selectedStateItems) {
          for (var x = 0; x < selectedStateItems.length; x++) {
            idx = locState.findIndex(
              (item) =>
                item.incident_state_id ===
                selectedStateItems[x].incident_state_id
            );
            if (idx >= 0) {
              locState[idx].isNewAssigned = 1;
              locState[idx].isAssigned = 1;
            }
          }
          Data.incident_states = locState;
        }
      } else if (item.type == "LBLINCIDENTERROR") {
        this.labelTranslation(item.values, "incident_error_name");
        Data.incident_errors = item.values;

        let { selectedErrorItems } = this.state;
        let idx,
          locError = Data.incident_errors;
        //   selectedErrorItems = $.isArray(selectedErrorItems)?selectedErrorItems[0]:selectedErrorItems
        if (selectedErrorItems) {
          for (var j = 0; j < selectedErrorItems.length; j++) {
            idx = locError.findIndex(
              (item) =>
                item.incident_error_id ===
                selectedErrorItems[j].incident_error_id
            );
            if (idx >= 0) {
              locError[idx].isNewAssigned = 1;
              locError[idx].isAssigned = 1;
            }
          }
          Data.incident_errors = locError;
        }
      } else if (item.type == "LBLCORRECTIVEACTION") {
        this.labelTranslation(item.values, "corrective_action_name");

        Data.corrective_actions = item.values;
        let { selectedCAItems } = this.state;
        let idx,
          locCAction = Data.corrective_actions;
        if (selectedCAItems) {
          for (var k = 0; k < selectedCAItems.length; k++) {
            idx = locCAction.findIndex(
              (item) =>
                item.corrective_action_id ===
                selectedCAItems[k].corrective_action_id
            );
            if (idx >= 0) {
              locCAction[idx].isNewAssigned = 1;
              locCAction[idx].isAssigned = 1;
              if (locCAction[idx].corrective_action_id == "corrective_action_15") {
                other = false;
              }
            }
          }
          Data.corrective_actions = locCAction;
        }
      } else if (item.type == "corrective_actions_other") {
        $("#txtCA").val(item.corrective_actions_other);
        other = false;
      }
      return null;
    });

    let singleobjectVals = [];
    singleobjectVals["incident_type"] = Data.incident_type;
    singleobjectVals["incident_source"] = Data.source;
    singleobjectVals["severity"] = Data.severity;
    let allGroups = [];
    response = allGroupsResponse;
    if (response) {
      for (var m = 0; m < response.length; m++) {
        if (response[m].group_type)
          allGroups[response[m].group_type] = response[m].groups;
      }
    }

    this.setState({
      allDataList: allData,
      Data: Data,
      singleObj: singleobjectVals,
      selCAothers: other,
      DataLabels: DataLabels,
      loading: Object.keys(Data).length > 0 ? false : true,
      //  shift: Data.shift[0],
      loadingadd: false,
      groupTypeInfo: allGroupsResponse,
      allGroupss: allGroups,
    });
    this.keyvaluechanges(response);
  };
  labelTranslation = (allLabels, key) => {
    let transLabels = [];
    if (allLabels.length > 0) {
      allLabels.map((item, i) => {
       return item[key] = this.props.labels.labels[item[key]]
          ? this.props.labels.labels[item[key]]
          : item[key];
      });
    }
    return transLabels;
  };
  getGroups = (response) => {
    let getData = this.props;
    let allGroups = [];
    getData = {
      language: this.props.language,
    };
    if (response) {
      this.keyvaluechanges(response);
      $(".rdt_TableHeadRow").css({ "background-color": "#f3f2f2" });
    }
    return allGroups;
  };
  handlePermissions = (event) => {
    let selflist,
      vals = event.target.value;
    selflist = vals == "1" ? false : true;
    $("#permission").show();
    $(".rdt_Table").hide();
    $(".rdt_Pagination").hide();

    this.setState({
      selflist: selflist,
      selectedpermission: vals,
    });
    this.getNearMissList(selflist, 1);
  };
  getNearMissList(selflist, mode) {
    let apiUrl = "nearmiss";
    let created_by = "5f4fb3efe3825e3a04b95177",
      params,
      roleid = 1,
      filterby,
      gettype = 0;
    roleid = this.props.role_id;

    if (roleid == 1 && selflist == false) {
      gettype = 0;
      filterby = 0;
    } else if (roleid == 1 && selflist == true) {
      gettype = 0;
      filterby = 1;
    } else if (roleid == 2) {
      gettype = 1;
      filterby = 1;
    } else {
      gettype = 1;
    }
    if (mode == 1) this.showhidepages("showlistadd", 1);
    params = {
      get_type: gettype,
      posted_by: filterby,
    };
    if (roleid == 4) {
      params = {
        get_type: gettype,
      };
    }
    // apiUrl = "nearmiss/" + this.state.rowid_id;
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getNearMissListHandler,
      { params }
    );
  }
  getNearMissListHandler = (response) => {
    let allDataList = [],
      allDatas = response.data;
    allDataList = allDatas;

    $(".rdt_Table").show();
    $(".rdt_Pagination").show();
    $("#permission").hide();

    let isemployee = this.props.role_id == 4 ? true : false;
    this.setState({
      show: false,
      initialValues: null,
      showDel: false,
      listData: allDataList,
      allDataLists: allDatas,
      loading: false,
      isemployee: isemployee,
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
      initialValues: null,
      showDel: false,
      ssi_errors: null,
    });
  };

  openModal = () => {
    this.setState({ show: true });
  };
  openEditModal = (item, e) => {
    loadType = false;
    loadLoc = false;
    loadDepart = false;
    this.setInitialValues(item);
    this.resetValidations();
    this.showhidepages("showlist", 2);
    // this.setState({ show: true, roleValid: false, });
  };
  openDelModal = (item, e) => {
    this.setState({
      showDel: true,
      userId: item.user_id,
      userName: item.username,
    });
  };
  openFilterModal = () => {
    let state = this.state.showNode;

    let { toFilters } = this.state;
    if (toFilters) {
      if (state) {
        $("#filterModal").show();
        $(".bounceInDown").show();
        $(".modal-backdrop").css({ position: "fixed" });
        $(".modal-backdrop.show").css({ opacity: ".5" });
        this.setState({ showNode: false, visible: "none" });
      } else {
        this.setState({ showFilter: true, showNode: false, visible: "none" });
      }
    } else {
      this.filterDetails();
      this.setState({ showFilter: false });
    }
    //let val = this.state.allFilterValues;
    setTimeout(() => {
      $(".mob-wid").css("margin-bottom", "20px")
    }, 100);

  };
  closeFilterModal = () => {
    $(".modal-backdrop").css({ position: "relative" });
    this.setState({
      showFilter: false,
      initialValues: null,
    });
  };
  getGroupTypes = () => {
    this.setState({ isLoadAPI: 1 });
    GroupServices.getGroupTypeLabels().then((response) => {
      this.setState(
        {
          groupTypeNames: response,
        }
      );
    });
  };
  setMultiItems = (items) => {
    let {
      place,
      selectedStateItems,
      selectedErrorItems,
      selectedCAItems,
      Data,
    } = this.state;
    let severitys, severity;
    selectedStateItems = items.incident_states;
    selectedErrorItems = items.incident_errors;
    selectedCAItems = items.corrective_actions;
    place = [items.incident_place];
    severity = items.incident_severity.incident_severity_id;
    severitys = Data.incident_severity;
    // Data.incident_states = item.values
    let idx,
      locState = Data.incident_states;
    if (selectedStateItems) {
      for (var x = 0; x < selectedStateItems.length; x++) {
        idx = locState.findIndex(
          (item) =>
            item.incident_state_id === selectedStateItems[x].incident_state_id
        );
        if (idx >= 0) {
          locState[idx].isNewAssigned = 1;
          locState[idx].isAssigned = 1;
        }
      }
      Data.incident_states = locState;
    }

    let locError = Data.incident_errors;
    idx = 0;
    if (selectedErrorItems) {
      for (var y = 0; y < selectedErrorItems.length; y++) {
        idx = locError.findIndex(
          (item) =>
            item.incident_error_id === selectedErrorItems[y].incident_error_id
        );
        if (idx >= 0) {
          locError[idx].isNewAssigned = 1;
          locError[idx].isAssigned = 1;
        }
      }
      Data.incident_errors = locError;
    }

    let locCAction = Data.corrective_actions;
    idx = 0;
    if (selectedCAItems) {
      for (var z = 0; z < selectedCAItems.length; z++) {
        idx = locCAction.findIndex(
          (item) =>
            item.corrective_action_id ===
            selectedCAItems[z].corrective_action_id
        );
        if (idx >= 0) {
          locCAction[idx].isNewAssigned = 1;
          locCAction[idx].isAssigned = 1;
        }
      }

      Data.corrective_actions = locCAction;
    }

    let locPlace = Data.incident_place;
    idx = 0;
    if (place) {
      for (var i = 0; i < place.length; i++) {
        idx = locPlace.findIndex(
          (item) => item.incident_place_id === place[i].incident_place_id
        );
        if (idx >= 0) {
          locPlace[idx].isNewAssigned = 1;
          locPlace[idx].isAssigned = 1;
        }
      }

      Data.incident_place = locPlace;
    }

    this.setState({
      Data: Data,
    });
  };

  async getAttachedImages(assets) {
    let FEEDAPIURL = process.env.REACT_APP_FEEDAPI_URL;
    let assetFolder = String(assets[0].asset_name).split(".");
    assetFolder.pop();
    let urlinfo, allInfo = [], urlData = [];
    if (assets.length > 0) {
      assets.map((itm, i) => {
        let data = {
          filename: assets[i].asset_name,
          assettype: "image",
          assetFoldet: assetFolder.join(),
        };
        const response = HttpInterceptor.post(
          FEEDAPIURL + "gets3signedassetsurl",
          data
        ).then(
          (response) => {
            if (response) {
              allInfo = response.data.data;
              urlinfo = allInfo;
              urlData.push(urlinfo);
              //     });
              this.setState({ attachedImageUrls: urlData });
              setTimeout(() => {
                if (urlData.length > 0) this.setImages(urlData);
              }, 100);
              //    }
            }
          },
          (err) => { }
        );
      });
    }
    return urlData;
  }
  setInitialValues = (item) => {
    $("#addNearMiss").css({ display: "none" });
    //  this.getNearMisCollections();
    let urlData = [];
    if (item.assets.length > 0) urlData = this.getAttachedImages(item.assets);
    if (item.active === 0 || !item.active) {
      item.active = false;
    } else {
      item.active = true;
    }

    let {Data, selCAothers } = this.state;

    let severity,
      shift,
      place;

    let shiftid;

    if (item.incident_severity.incident_severity_id)
      severity =
        parseInt(item.incident_severity.incident_severity_id.split("_")[2]) - 1;
    else severity = 0;
    place = item.incident_place.incident_place_id;

    this.setMultiItems(item);
    let location,
      department,
      selwork = false,
      selhome = true;
    if (place == "incident_place_01") {
      if ($.isArray(item.shift)) {
        shiftid = item.shift[0].shift_id;
        item.shift = item.shift[0];
      } else {
        shiftid =
          item.shift == null ? Data.shift[0].shift_id : item.shift.shift_id;
      }
      location = [
        {
          location_id:
            item.location == null
              ? Data.location[0].location_id
              : item.location.location_id,
          group_name:
            item.location == null
              ? Data.location[0].location_name
              : item.location.location_name,
        },
      ];
      department = [
        {
          department_id:
            item.department == null
              ? Data.department[0].department_id
              : item.department.department_id,
          group_name:
            item.department == null
              ? Data.department[0].department_id
              : item.department.department_name,
        },
      ];
      shift = parseInt(item.shift.shift_id.split("_")[1]) - 1;
      selwork = true;
      selhome = false;
    } else {
      item["shift"] = Data.shift[0];
      shift = 0;
    }
    item["source"] = Data.source[0];
    this.setbackgroundplace(place + 1);
    let incclosed,
      status,
      idx_work,
      showhabit = false,
      workhabit = Data.corrective_actions[3].corrective_action_id;
    if (item.corrective_actions.length > 0)
      idx_work = item.corrective_actions.findIndex(
        (ix) => ix.corrective_action_id == workhabit
      );
    showhabit = idx_work >= 0 ? true : false;
    let inctype = item.incident_type.incident_type_name;
    let sevtype = item.incident_severity.incident_severity_name;
    sevtype = {
      incident_severity_id: item.incident_severity.incident_severity_id,
      incident_severity_name:
        sevtype.indexOf("LBL") > -1 ? objLabels[sevtype] : sevtype,
    };
    inctype = {
      incident_type_id: item.incident_type.incident_type_id,
      incident_type_name:
        inctype.indexOf("LBL") > -1 ? objLabels[inctype] : inctype,
    };
    //item.incident_type.incident_type_name = inctype.indexOf("LBL")>-1?objLabels[inctype]:inctype;
    //item.incident_severity.incident_severity_name = sevtype.indexOf("LBL")>-1?objLabels[sevtype]:sevtype;
    incclosed = item.status == false ? true : false;
    selCAothers = item.corrective_actions_other && item.corrective_actions_other !== "" ? false : true;
    let selectedSource = item.incident_source.incident_source_id ? item.incident_source : Data.source[0];
    this.handleSources(selectedSource, 1)
    this.setState({
      showadd: true,
      title: item.incident_title,
      selectedDate: item.event_date,
      selectedTime: item.event_time,
      selectedValue: item.status ? item.status : "1",
      type: [inctype],
      severity: [sevtype],
      selectedIndex: severity,
      selectedShift: shift + 1,
      selectedSource: selectedSource,
      selectedPrevSource: selectedSource,
      selCAothers: selCAothers,
      selectedPlace: place,
      location: [item.location],
      department: [item.department],
      place: $.isArray(item.incident_place)
        ? item.incident_place
        : [item.incident_place],
      shift: [item.shift],
      selectedStateItems: item.incident_states,
      selectedErrorItems: item.incident_errors,
      selectedCAItems: item.corrective_actions,
      summary: item.incident_summary.summary_value,
      inclocation: item.incident_location.incident_location_name, //item.incident_inclocation.summary_inclocation,
      selother: item.corrective_actions_other,
      rowid_id: item._id,
      initialValues: item,
      // location: location,
      // department: department,
      showedit: true,
      loadingadd: true,
      showhabits: showhabit,
      selwork: selwork,
      selhome: selhome,
      incclosed: incclosed,
      // selectedValue: item.status,
      attachedFiles: urlData,
      //attachedImages:urlData
      //  attachedImageUrls:urlData
    });
    //  this.onloadchanges()
  };

  handleCancel = () => {
    let mode = 3;
    let { showedit } = this.state;
    if (showedit) {
      this.resetDetails();
      mode = 4;
    }
    this.showhidepages("showlistadd", mode);
    this.setState({
      showedit: false,
    });
  };
  handleAdds = () => {
    let mode = 3;
    let { showedit } = this.state;
    if (showedit) {
      this.resetDetails();
      mode = 4;
    }
    let selectedSource = {
      incident_source_id: "incident_source_01",
      incident_source_name: "LBLSOURCE1",
      incident_source_desc: ""
    }
    this.setState({
      selectedSource: "",
      selected_image: 0
    })
    this.showhidepages("showlist", mode);

    // this.setState({showedit:false})
  };
  handleLists = () => {
    let { showedit } = this.state;
    if (showedit) {
      this.resetDetails();
    }
    this.showhidepages("showlistadd");
  };
  updateResultHandler1 = (result) => {
    if (result.data) {
      alert(result.status)
    }
    //console.log("CCCCCC",result);
  }
  searchUser = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };
  roleFilter = (e) => {
    this.setState({
      roleFilter: e.target.value,
      searchValue: "",
    });
  };
  tableHeader = () => {
    return (
      <div className="table-filter neatmiss_tab">
        <Modal
          show={this.state.showFilter}
          id="filterModal"
          backdrop="static"
          onHide={this.closeFilterModal}
          size="lg"
          dialogClassName="modal-90w"
          data-easein="expandIn"
          className={
            "animated  bounceInDown " +
            (this.state.showFilter ? "expandIn" : "fadeOutUp")
          }
        >
          <Modal.Header closeButton className='alert-modal-header'>
            <Modal.Title
              className= "alert-modal-title"
              id="exampleModalLongTitle"
              style={{ lineHeight: "2.8" }}
            >
              {this.props.labels.labels.LBLNMFILTER}
            </Modal.Title>
          </Modal.Header>

          <FilterComp
            initialValues={this.state.initialValues}
            sendInvitation={this.sendInvitation}
            handleSubmit={this.applyFilters}
            filter={this.state.selAllValues}
            toFilters={this.state.toFilters}
            helpersLoaded={this.state.helpersLoaded}
            closeModal={this.closeModal}
            ssi_errors={this.state.ssi_errors}
            groupData={this.state.groupData}
            roleValid={this.state.roleValid}
            labels={this.props.labels}
            changesIn={this.changesIn}
            className="toggleClass"
          ></FilterComp>
        </Modal>


        <div className="row" style={{ width: "100%" }}>
          <div className="col-xl-8" style={{ display: "inline-flex" }}>
            <div
              className="btn-align"
              style={{
                marginRight: "20px",
                float: "left",
                textAlign: "left",
                minWidth: "110px",
              }}
              id="showlistl"
            >
              <button
                className="button-style primary-btn"
                type="button"
                id="showlist"
                onClick={this.handleAdds}
              >
                {this.props.labels.labels.LBLADDNEWNM}
              </button>{" "}
            </div>
            {this.state.isemployee ? (
              ""
            ) : (
              <div
                style={{
                  display: "inline-flex",
                  minWidth: "300px",
                  margin: "-10px 0px 11px 10px;",
                  lineHeight: "25px",
                  verticalAlign: "top",
                }}
                className="cols"
              >
                <Radio
                  checked={this.state.selectedpermission === "1"}
                  onChange={this.handlePermissions}
                  value="1"
                  className="clsRadioText"
                  style={{
                    color: green[600],
                    display: this.state.incclosed ? "none" : "",
                  }}
                  name="radioall"
                  id="radioall"
                  inputProps={{ "aria-label": "A" }}
                />
                <span className="clsRadioText">
                  {this.props.labels.labels.LBLALL}
                </span>
                <Radio
                  checked={this.state.selectedpermission === "2"}
                  onChange={this.handlePermissions}
                  value="2"
                  style={{ color: green[600] }}
                  name="radioall"
                  id="radioself"
                  inputProps={{ "aria-label": "B" }}
                />{" "}
                <span className="clsRadioText">
                  {this.props.labels.labels.LBLMYINCIDENTS}
                </span>
              </div>
            )}
          </div>

          <div
            className="col-xl-4"
            style={{ display: "inline-flex", padding: "0px", left: "15px" }}
          >
            <div
              className="clsSelFilters"
              style={{ display: this.state.visible }}
            >
              <span></span>
            </div>

            <div
              data-toggle="tooltip"
              title={this.props.labels.labels.LBLFILTER}
              data-target="#myModal"
              style={{
                width: "25px",
                marginRight: "33px",
                zoom: "1.4",
                cursor: "pointer",
                //title: "Filter",
                margin: "0px 6px",
                marginBottom: "10px",
              }}
            >
              <i
                data-target="#myModal"
                onClick={this.openFilterModal}
                className="fa fa-filter"
                aria-hidden="true"
              ></i>
            </div>
            <div  >
              <input
                style={{ position: "relative" }}
                type="text"
                className="input-field near-miss-search"
                placeholder={this.props.labels.labels.LBLSEARCH}
                value={this.state.searchValue}
                onChange={this.searchUser}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (
        row[field] &&
        row[field] !== 1 &&
        row[field] !== 0 &&
        typeof row[field] !== "boolean"
      ) {
        return row[field].toString().toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  closeToast = () => {
    this.setState({ showToast: { show: false } });
  };
  setvalue = (evt) => {
    evt.preventDefault();

    let title = this.state.title;
    if (title.length > 0) {
      $($('#Title').parent()).unbind('click');
    } else {
      $($('#Title').parent()).bind('click');
    }
    //  alert(evt.target.selectionStart )

  }
  handleTitleChange = (item) => {
    // let title = this.state.title;
    let { LBLMAXCHARS } = this.props.labels.labels;
    let value = item.currentTarget.value;
    let error = false, helper = "", lenSourceDesc = value.length;
    if (lenSourceDesc > 1024) {
      helper = LBLMAXCHARS;
      error = true;
    } else if (lenSourceDesc < 1024) {
      helper = "";
      error = false;
    }
    this.setState({
      helperTitle: helper,
      errorTitle: error,
      title: value,
    });
  };
  handleChange = (event) => {
    //  alert(event.target.value)
    let val = event.target.value;
    let selval = event.target.value;
    this.setState({ value: val, selectedValue: selval });
  };
  handleSource = (event) => {
    let locSource = "", selval, val = event.target.value;
    let { Data, selectedPrevSource, showedit, sourceDecText } = this.state;
    selval = val.split("LBLSOURCE")[1];
    locSource = Data.source[selval - 1];
    sourceDecText = locSource;
    if (selectedPrevSource && selectedPrevSource.incident_source_name == val && showedit) {
      locSource = selectedPrevSource;
    }
    this.setState({
      selectedSource: locSource,
      selected_image: selval,
      helperSource: "",
      error: false,
    });
  };
  handleSources = (event, mode) => {
    let locSource = "", selval, val = "";
    if (event.target) {
      selval = event.target.id;
    } else {
      selval = event.incident_source_name.split("LBLSOURCE")[1];
      if (!selval && mode == 1) {
        selval = event.incident_source_id.split("incident_source_0")[1];
        selval = selval ? selval : 1;
      }
    }
    let { Data, selectedPrevSource, showedit, sourceDecText } = this.state;
    this.addRemoveClass(selval);
    locSource = Data.source[selval - 1];
    sourceDecText = locSource;
    if (selectedPrevSource && selectedPrevSource.incident_source_name == val && showedit) {
      locSource = selectedPrevSource;
    }
    this.setState({
      selectedSource: locSource,
      selected_image: selval,
      helperSource: "",
      error: false,
    });
  };
  addRemoveClass = (val) => {
    [1, 2, 3].map(i => $("#" + i).removeClass("cls_active_source"));
    $("#" + val).addClass("cls_active_source");
  }
  handleShift = (event) => {
    //  alert(event.target.value)
    let val = event.target.value;
    let locshift,
      selval = event.target.value;
    this.setState({});
    let { Data } = this.state;
    locshift = Data.shift[val - 1];
    this.setState({
      shift: locshift,
      value: val,
      selectedShift: selval,
      helperShift: "",
      error: false,
    });
  };
  handleTypeChange = (e, item) => {

    let type = item;
    if (type !== "") {
      this.setState({
        helperType: "",
        error: false,
        type: [item],
      });
    }
  };

  handleTypeChange1 = (item) => {
    console.log("checkitem", item)
    let type = item;
    if (type !== "") {
      this.setState({
        helperType: "",
        error: false,
        type: item,
      });
    }
  };

  handleSeverityChange = (item) => {
    this.setState({
      helperSeverity: "",
      error: false,
      severity: item,
    });
    let color = "";

    if (item[0].id == 1) {
      color = "red";
    } else if (item[0].id == 2) {
      color = "#ffa500";
    } else {
      color = "black";
    }

    //  $("._7ahQImyV4dj0EpcNOjnwA").css({"color":color})
  };
  handleLocationChange = (e, item) => {
    this.setState({
      helperLocation: "",
      error: false,
      location: [item],
    });
  };

  handleDepartmentChange = (e, item) => {
    console.log("deptcheck", item)
    this.setState({
      helperDepartment: "",
      error: false,
      department: [item],
    });
  };

  handleDepartmentChange1 = (item) => {
    console.log("deptcheck", item)
    this.setState({
      helperDepartment: "",
      error: false,
      department: item,
    });
  };
  handleShiftChange = (item) => {
    this.setState({
      helperShift: "",
      error: false,
      shift: item,
    });
  };
  handleincLocationChange = (item) => {
    let { LBLMAXCHARSDESC } = this.props.labels.labels;
    let value = item.currentTarget.value;
    let error = false, helper = "", lenSourceDesc = value.length;
    if (lenSourceDesc > 1024) {
      helper = LBLMAXCHARSDESC;
      error = true;
    } else if (lenSourceDesc < 1024) {
      helper = "";
      error = false;
    }
    this.setState({
      inclocation: item.currentTarget.value,
      helperincLocation: helper,
      errorLoc: error,
    });
  };
  handleSummaryChange = (item) => {
    let { LBLMAXCHARSDESC } = this.props.labels.labels;
    let value = item.currentTarget.value;
    let error = false, helper = "", lenSourceDesc = value.length;
    if (lenSourceDesc > 1024) {
      helper = LBLMAXCHARSDESC;
      error = true;
    } else if (lenSourceDesc < 1024) {
      helper = "";
      error = false;
    }
    this.setState({
      summary: item.currentTarget.value,
      helperSummaryDesc: helper,
      errorSum: error,
    });
  };
  handleSourceChange = (item) => {
    let { selectedSource } = this.state;
    let { LBLMAXCHARSDESC } = this.props.labels.labels;
    let value = item.currentTarget.value;
    let error = false, helper = "", lenSourceDesc = value.length;
    if (lenSourceDesc > 1024) {
      helper = LBLMAXCHARSDESC;
      error = true;
    } else if (lenSourceDesc < 1024) {
      helper = "";
      error = false;
    }
    selectedSource.incident_source_desc = item.currentTarget.value;

    this.setState({
      selectedSource: selectedSource,
      helperSourceDesc: helper,
      errorSrc: error
    });
  };
  handleother = (item) => {
    let { LBLMAXCHARSDESC } = this.props.labels.labels;
    let value = item.currentTarget.value;
    let error = false, helper = "", lenSourceDesc = value.length;
    if (lenSourceDesc > 1024) {
      helper = LBLMAXCHARSDESC;
      error = true;
    } else if (lenSourceDesc < 1024) {
      helper = "";
      error = false;
    }
    this.setState({
      selother: item.currentTarget.value,
      helperTextDes: helper,
      errorDes: error
    });
  };

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
      helperDate: "", 
      error: false,
    });
  };
  handleTimeChange = (time) => {
    console.log("time",time)
    // let time1 = new Date(time).getHours() + ":" + new Date(time).getMinutes();
    this.setState({
      selectedTime: time,
      helperTime: "",
      error: false,
    });
  };
  handleCA = (evt) => {
    //   evt.preventDefault();
    // let cnt = $("#near_chk_CA li").length;
    let currid = evt.currentTarget.id;
    let { Data, showhabits, selectedCAItems } = this.state;
    let dataval = Data.corrective_actions;

    let ix,
      workhabitid,
      checkeditems = selectedCAItems;

    workhabitid = Data.corrective_actions[3].corrective_action_id;
    if (currid == "3") {
      if (dataval[3].isNewAssigned === 1) {
        showhabits = true;
      } else {
        checkeditems.map((itm, i) => {
          if (itm.corrective_action_id > workhabitid) {
            selectedCAItems.splice(i, 1);
            ix = dataval.findIndex(
              (mm) => mm.corrective_action_id == itm.corrective_action_id
            );
            dataval[ix].isNewAssigned = 0;
          }
        });
        showhabits = false;
        Data.corrective_actions = dataval;
      }
    }
    this.setState({
      showhabits: showhabits,
      selectedCAItems: selectedCAItems,
      Data: Data,
    });
  };
  handleClose = (event) => {
    this.setState({
      open: false,
      transition: "Fade",
    });
  };
  showAlert = (messageType, message) => {
    this.setState({
      open: true,
      messageType: messageType,
      message: message,
    });
  };

  updateIncidentDetails = () => {
    this.objPreview = $("#preview li");
    let attachedLocImages = this.getUploadedImages();
    attachedLocImages = attachedLocImages ? attachedLocImages : [];
    let NearMissDetails = this.checkvalidation();
    if (NearMissDetails) {
      this.setState({
        loadingadd: true,
        loadingupdate: true,
        attachedLocImages: attachedLocImages,
        NearMissDetails: NearMissDetails,
      });
      if (attachedLocImages.length > 0) {
        this.saveUploadedImages();
      } else {
        this.updateIncidents(NearMissDetails);
      }
    }
  };
  updateIncidents = (NearMissDetails1) => {
    let posttype = "post";
    let attachedfiles; //=  this.getUploadedImages();
    //  attachedLocImages1 =  this.getUploadedImages();
    let { NearMissDetails } = this.state; //this.checkvalidation();
    if (NearMissDetails1) {
      attachedfiles = this.getAttachedFiles();
      NearMissDetails1.assets = attachedfiles;
      NearMissDetails = NearMissDetails1;
    } else {
      attachedfiles = this.getAttachedFiles();
      NearMissDetails.assets = attachedfiles;
    }

    // let { NearMissDetails} = this.state;//this.checkvalidation();
    if (NearMissDetails) {
      // this.setState({ loading: true });
      let apiUrl = "nearmiss";
      let isEdit = this.state.initialValues;

      if (isEdit != null) {
        apiUrl = "nearmiss/" + this.state.rowid_id;
        posttype = "put";
        //  NearMissDetails._id=this.state.rowid_id
      }
      //   this.imageUploadHandler()

      NearMissService.nearmissAPIServicesCall(
        apiUrl,
        posttype,
        this.updateResultHandler,
        NearMissDetails
        //JSON.stringify(NearMissDetails)
      );

    }
  };
  updateResultHandler = (response) => {
    if (response.data) {
      this.setState({
        loadingadd: false,
        loadingupdate: false,
        loading: false,
        title: "",
        selwork: false,
        selhome: false,
        selectedPrevSource: "",
        showhabits: false,
        NearMissDetails: [],
        attachedfiles: [],
        deletedImages: [],
        attachedLocImages: []
      });
      this.resetDetails();
      this.updatesuccess();
    }
  };
  updatesuccess = () => {
    let selflist = this.state.selflist;
    // if(selflist=='1') selflist=true;
    this.getNearMissList(selflist);
    this.showAlert("success", this.props.labels.labels.LBLUPDTSUCC);
    setTimeout(() => {
      this.showhidepages("showlistadd", 1);
    }, 2000);
  };
  getAttachedFiles = () => {
    let attachedAllfiles = [], attachedUrls = [], attachedfiles;
    let {  attachedLocImages, attachedImageUrls } = this.state;
    attachedLocImages = !attachedLocImages ? [] : attachedLocImages;
    attachedImageUrls = !attachedImageUrls ? [] : attachedImageUrls;
    let allNodes = [], imgSrc = "";
    allNodes = this.objPreview; //$("#preview li"); 
    allNodes.map((item, idx) => {
      imgSrc = $(allNodes[item]).find("img").attr("src");
      if (imgSrc.indexOf("http") >= 0) {
        attachedUrls.push(imgSrc);
      }
      return null;
    });
    attachedImageUrls = attachedUrls;
    attachedfiles = [];
    if (attachedImageUrls.length > 0 && attachedLocImages.length > 0) {
      attachedAllfiles = [...attachedImageUrls, ...attachedLocImages];
    } else if (attachedImageUrls.length > 0) {
      attachedAllfiles = attachedImageUrls;
    } else if (attachedLocImages.length > 0) {
      attachedAllfiles = attachedLocImages;
    }
    if (attachedAllfiles.length > 0) {
      attachedAllfiles.map((itm) => {
        let img_name, img_url;
        if (itm.uploadedFiles) {
          img_name = itm.uploadedFiles;
        } else {
          img_url = itm.split("?")[0].split("/");
          img_url = img_url[img_url.length - 1];
          img_name = img_url;
        }
        attachedfiles.push({
          asset_name: img_name,
          asset_type: "image",
        });
        return null;
      });
    }

    return attachedfiles;
  }
  checkvalidation = () => {
    let arrVals = [];
    let {
      Data,
      title,
      selectedValue,
      selother,
      selectedSource,
      type,
      severity,
      location,
      department,
      place,
      shift,
      summary,
      inclocation,
      selectedDate,
      selectedTime,
    } = this.state;
    let lentitle = 0, lenSourceDesc = 0,
      blnValidity = false,
      arroffset;
    title = this.state.title;

    let { LBLMAXCHARS, LBLMAXCHARSDESC, LBLREQD } = this.props.labels.labels;
    if (selectedSource == "" || selectedSource == undefined) {
      this.setState({ helperSource: LBLREQD });
      $("#Source").focus();
      arrVals.push("Source");
    } else {
      selectedSource.incident_source_desc = $.trim(selectedSource.incident_source_desc);
      lenSourceDesc = selectedSource.incident_source_desc.length;
    }

    if (lenSourceDesc > 1024) {
      this.setState({ helperSourceDesc: LBLMAXCHARSDESC, error: true });
      $("#SourceDesc").focus();
      arrVals.push("SourceDesc");
    } else if (lenSourceDesc < 1024) {
      this.setState({ helperSourceDesc: "", error: false });
    }
    lentitle = title.length;
    if ($.trim(title) === "") {
      this.setState({ helperTitle: LBLREQD, error: true });
      $("#Title").focus();
      blnValidity = true;
      arrVals.push("Title");
    } else if (lentitle > 255) {
      this.setState({ helperTitle: LBLMAXCHARS, error: true });
      $("#Title").focus();
      arrVals.push("Title");
    }
    if (type == "") {
      this.setState({ helperType: LBLREQD, errorType: true });
      $("#Type").focus();
      arrVals.push("Type");
    }
    if (
      severity == "" ||
      ($("#menuList li:nth-child(4)").css("display") == "none" &&
        severity.incident_severity_id == "incident_state_04")
    ) {
      this.setState({ helperSeverity: LBLREQD });
      $("#Severity").focus();
      arrVals.push("Severity");
    }

    if (place == "") {
      this.setState({ helperPlace: LBLREQD });
      $("#Place").focus();
      arrVals.push("Place");
    }
    let selectedPlace = false;
    if ($.isArray(place)) place = place[0];
    if (
      place &&
      place.incident_place_id == Data.incident_place[0].incident_place_id
    ) {
      selectedPlace = true;
      if (location == "") {
        this.setState({ helperLocation: LBLREQD });
        $("#Location").focus();
        arrVals.push("Location");
      }
      if (department == "") {
        this.setState({ helperDepartment: LBLREQD });
        $("#Department").focus();
        arrVals.push("Department");
      }
      if (shift == "" || shift == "0") {
        if (shift == "" || shift == "undefined") {
          shift = Data.shift[0];
        } else {
          this.setState({ helperShift: LBLREQD });
          $("#Shift").focus();
          arrVals.push("Shift");
        }
      }
      inclocation = "";
    } else {
      selectedPlace = false;
      let lenIncLocation = $.trim(inclocation).length;
      if (lenIncLocation == 0) {
        this.setState({ helperincLocation: LBLREQD });
        $("#incLocation").focus();
        arrVals.push("incLocation");
      } else if (lenIncLocation > 1024) {
        this.setState({ helperincLocation: LBLMAXCHARSDESC, errorLoc: true });
        $("#incLocation").focus();
        arrVals.push("incLocation");
      } else if (lenIncLocation < 1024) {
        this.setState({ helperincLocation: "", errorLoc: false });
      }
    }
    if (selectedDate == "" || selectedDate == null) {
      //|| selectedDate=="Invalid Date") {
      this.setState({ helperDate: LBLREQD });
      $("#Date").focus();
      arrVals.push("Date");
    }
    if (selectedTime == "" || selectedTime == null) {
      // || selectedTime=="Invalid Date") {
      this.setState({ helperTime: LBLREQD });
      $("#Time").focus();
      arrVals.push("Time");
    }

    let lenSummary = $.trim(summary).length;

    if ($.trim(summary) == "") {
      this.setState({ helperSummary: LBLREQD });
      arrVals.push("Summary");
    } else if (lenSummary > 1024) {
      this.setState({ helperSummaryDesc: LBLMAXCHARSDESC });
      arrVals.push("Summary");
    } else if (lenSummary < 1024) {
      this.setState({ helperSummaryDesc: "" });
    }
    let lenOthers = $.trim(selother).length;
    if (lenOthers > 1024) {
      this.setState({ helperTextDes: LBLMAXCHARSDESC, errorSum: true });
      arrVals.push("txtCA");
    } else if (lenOthers < 1024) {
      this.setState({ helperTextDes: "", errorSum: false });
    }


    let { selectedStateItems, selectedErrorItems, selectedCAItems } = this.state;
    selectedStateItems = selectedStateItems ? selectedStateItems : [];
    selectedErrorItems = selectedErrorItems ? selectedErrorItems : [];
    selectedCAItems = selectedCAItems ? selectedCAItems : [];

    if (selectedSource.incident_source_id == "incident_source_03") {
      selectedStateItems = [];
      selectedErrorItems = [];
      selectedCAItems = [];
      selother = "";
    } else {

      if (selectedStateItems.length == 0) {
        this.setState({ helperStates: LBLREQD });
        arrVals.push("States");
      }
      if (selectedErrorItems.length == 0) {
        this.setState({ helperErrors: LBLREQD });
        arrVals.push("Errors");
      }
      let indx,
        indx1,
        CAWork = Data.corrective_actions[3].corrective_action_id;
      if (selectedCAItems.length == 0) {
        this.setState({ helperCActions: LBLREQD });
        arrVals.push("CActions");
      } else {
        indx = selectedCAItems.findIndex((i) => i.corrective_action_id == CAWork);
        indx1 = selectedCAItems.findIndex((i) => i.corrective_action_id > CAWork);
        if ((indx >= 0 && indx1 < 0) || (indx < 0 && indx1 >= 0)) {
          this.setState({ helperCActions: LBLREQD });
          arrVals.push("CActions");
        } else if (indx < 0 && indx1 >= 0) {
        }
      }

    }
    if (arrVals.length > 0) {
      if (
        arrVals[0] == "title" ||
        arrVals[0] == "SourceDesc" ||
        arrVals[0] == "Summary" ||
        arrVals[0] == "incLocation" ||
        arrVals[0] == "txtCA"
      ) {
        $("#" + arrVals[0]).focus();
      } else {
        arroffset = $("#" + arrVals[0]).offset();
        if (arroffset) window.scrollTo(arroffset.left, arroffset.top - 100);
      }
      return false;
    }

    // let NearMissDetails = [],
    //   Attchments = { ["companyid"]: {} };

    let locationid = "",
      departmentid = "",
      lCAothers = $("#txtCA").val();
    if (selectedPlace) {
      locationid = location[0].group_id;
      departmentid = department[0].group_id;
    }
    let attachedfiles = [];
    attachedfiles.map((itm) => {
      return attachedfiles.push({ asset_name: itm.name, asset_type: "image" });
    });
    lCAothers = selother ? selother : "";

    let objNearMissDetails = {
      incident_title: title,
      status: selectedValue == "1" || selectedValue == true ? true : false,
      incident_source: selectedSource,
      incident_type: type[0],
      incident_severity: $.isArray(severity) ? severity[0] : severity,
      location: {
        location_name: selectedPlace ? location[0].group_name : "",
        location_id: locationid,
      },
      // department: { department_id: 60, department_name: "Finance" },
      department: {
        department_name: selectedPlace ? department[0].group_name : "",
        department_id: departmentid,
      },
      incident_place: place,
      shift: shift,
      incident_location: {
        incident_location_name: inclocation ? inclocation : "",
      },
      event_date:
        selectedDate.length > 24
          ? selectedDate.setHours(0, 0, 0, 0)
          : selectedDate, //selectedDate.setUTCHours(0,0,0,0),
      event_time: selectedTime,
      assets: attachedfiles, //,
      incident_summary: { summary_value: summary },
      incident_states: selectedStateItems,
      incident_errors: selectedErrorItems,
      corrective_actions: selectedCAItems,
      corrective_actions_other: lCAothers,
    };
    if (selectedPlace === false) {
      objNearMissDetails.location = null;
      objNearMissDetails.department = null;
      objNearMissDetails.shift = null;
    }
    this.savetranslation(objNearMissDetails);
    //return false;
    return objNearMissDetails;
  };
  savetranslation = (objNearMissDetails) => {
    if (objNearMissDetails) {
      let incident_type_01 = this.savetranslation_data(
        objNearMissDetails.incident_type,
        "incident_type",
        "incident_type_id"
      );
      objNearMissDetails.incident_type = incident_type_01;
      let incident_severity1 = this.savetranslation_data(
        objNearMissDetails.incident_severity,
        "incident_severity",
        "incident_severity_id"
      );
      objNearMissDetails.incident_severity = incident_severity1;
      let shift1 = this.savetranslation_data(
        objNearMissDetails.shift,
        "shift",
        "shift_id"
      );
      objNearMissDetails.shift = shift1;
      let incident_place1 = this.savetranslation_data(
        objNearMissDetails.incident_place,
        "incident_place",
        "incident_place_id"
      );
      objNearMissDetails.incident_place = incident_place1;
      let incident_states1 = this.savetranslation_data(
        objNearMissDetails.incident_states,
        "incident_state",
        "incident_state_id"
      );
      objNearMissDetails.incident_states = incident_states1;
      let incident_errors1 = this.savetranslation_data(
        objNearMissDetails.incident_errors,
        "incident_error",
        "incident_error_id"
      );
      objNearMissDetails.incident_errors = incident_errors1;
      let corrective_actions1 = this.savetranslation_data(
        objNearMissDetails.corrective_actions,
        "corrective_action",
        "corrective_action_id"
      );
      objNearMissDetails.corrective_actions = corrective_actions1;
    }
  };
  savetranslation_data = (NearMissDetails, type, label) => {
    let { DataLabels } = this.state;
    let blnmulti = false,
      newdata = [];
    if (NearMissDetails) {
      if (NearMissDetails.length > 0) {
        blnmulti = true;
        NearMissDetails.map((item, i) => {
          DataLabels.map((data, j) => {
            if (data.type_id == type) {
              data.values.map((itm, j) => {
                if (item[label] == itm[label]) {
                  newdata.push(itm);
                }
                return null;
              });
            }
            return null;
          });
          return null;
        });
      } else {
        blnmulti = false;
        DataLabels.map((item, j) => {
          if (item.type_id == type) {
            item.values.map((itm, j) => {
              if (NearMissDetails[label] == itm[label]) {
                NearMissDetails = itm;
              }
              return null;
            });
          }
        });
      }
    }
    if (blnmulti) NearMissDetails = newdata;
    return NearMissDetails;
  };

  checkOtherItems = () => {
    if ($("#txtCA").prop("disabled")) {
      $("#txtCA").prop("disabled", false);
      $(".MuiInputBase-input").css({ color: "#000", fontSize: "14px" });
      $("#txtCA").focus();
      // others=true;
    } else {
      $("#txtCA").prop("disabled", true);
      $(".MuiInputBase-input").css({ color: "#b7b2b2", fontSize: "14px" });
      //  others=false;
    }
  };

  selectedCheckStates = (item, status, mode, currobj) => {
    let inx,
      checkeditems = [],
      itemsList;
    let { Data } = this.state;

    checkeditems = this.state.selectedStateItems
      ? this.state.selectedStateItems
      : [];
    itemsList = Data.incident_states;
    inx = checkeditems.findIndex(
      (list) => list.incident_state_id === item.incident_state_id
    );

    if (inx > -1) {
      checkeditems.splice(inx, 1);
      if (!currobj.currentTarget) $("#" + currobj).css({ color: "" });
    } else {
      checkeditems[checkeditems.length] = item; //.incident_state_id;
    }

    for (var i = 0; i < itemsList.length; i++) {
      if (item.incident_state_id === itemsList[i].incident_state_id) {
        itemsList[i].isNewAssigned = status ? 0 : 1;
      }
    }
    let LBLREQD = this.props.labels.labels.LBLREQD;

    this.setState((state, props) => ({
      selectedStateItems: checkeditems,
      helperStates: checkeditems.length > 0 ? "" : LBLREQD,
    }));
  };

  selectedCheckErrors = (item, status, mode, currobj) => {
    let inx,
      checkeditems = [],
      itemsList;
    let { Data } = this.state;

    checkeditems = this.state.selectedErrorItems
      ? this.state.selectedErrorItems
      : [];
    itemsList = Data.incident_errors;
    inx = checkeditems.findIndex(
      (list) => list.incident_error_id === item.incident_error_id
    );

    if (inx > -1) {
      checkeditems.splice(inx, 1);
      if (!currobj.currentTarget) $("#" + currobj).css({ color: "" });
    } else {
      checkeditems[checkeditems.length] = item;
    }
    for (var i = 0; i < itemsList.length; i++) {
      if (item.incident_error_id === itemsList[i].incident_error_id) {
        itemsList[i].isNewAssigned = status ? 0 : 1;
      }
    }
    let LBLREQD = this.props.labels.labels.LBLREQD;

    this.setState((state, props) => ({
      selectedErrorItems: checkeditems,
      helperErrors: checkeditems.length > 0 ? "" : LBLREQD,
    }));
  };
  selectCheckItems = (item, status, mode, currobj) => {
    let closed = this.state.incclosed;
    if (closed) return false;
    let inx,
      checkeditems = [],
      itemsList,
      others = true;
    let { Data } = this.state;

    if (!currobj.currentTarget)
      $("#caction" + currobj).css({ color: "#5192d6" });

    checkeditems = this.state.selectedCAItems;
    itemsList = Data.corrective_actions;
    inx = checkeditems.findIndex(
      (list) => list.corrective_action_id === item.corrective_action_id
    );

    if (inx > -1) {
      checkeditems.splice(inx, 1);
      if (!currobj.currentTarget) $("#caction" + currobj).css({ color: "" });
      if (currobj == 14) {
        others = true;
      }
    } else {
      checkeditems[checkeditems.length] = item; //.incident_state_id;
      if (currobj == 3) {
        this.showAlert("warning", this.props.labels.labels.LBLSELHABIT);
        others = true;
      } else if (currobj == 14) {
        others = false;
      }
    }
    for (var i = 0; i < itemsList.length; i++) {
      if (item.corrective_action_id === itemsList[i].corrective_action_id) {
        itemsList[i].isNewAssigned = status ? 0 : 1;
      }
    }
    let LBLREQD = this.props.labels.labels.LBLREQD;

    this.setState((state, props) => ({
      selectedCAItems: checkeditems,
      selCAothers: others,
      helperCActions: checkeditems.length > 0 ? "" : LBLREQD,
    }));
  };

  selectCheckPlace = (item, status, mode, currobj) => { };

  uploadStatusHandler = (status, fileName) => {
    this.isUploadCompleted = status;
    this.uploadedImageName = fileName;
    let { attachedImages } = this.state;
    // if(status){
    //   attachedImages.filename = fileName
    // }

    let idx = attachedImages.findIndex((itm) => itm == fileName);
    if (status == 1 && idx < 0) {
      attachedImages = fileName;
      this.setState({
        attachedImages: attachedImages,
      });
      this.updateIncidents();
    }
  };
  setattachedImages = (attachedImages) => { };

  handlegoup = () => {
    let offset = $("#addNearMiss").offset();
    window.scrollTo(offset.left, offset.top);
  };
  setbackgroundplace = (id) => {
    $("#Placeid" + id).css({
      filter:
        "invert(3%) sepia(41%) saturate(307%) hue-rotate(46deg) brightness(100%) contrast(84%)",
    });
  };
  handlePlaceChange = (item, assign, id) => {
    let { Data } = this.state;
    Data.incident_place.map((itm) => (itm.isNewAssigned = 0));
    item.isNewAssigned = 1;
    let menuclass = [0, 1, 2, 3];
    menuclass.map((i, j) => {
     return $("#Placeid" + (i + 1)).css({ filter: "", border: "", backgroundColor: "" });
    }); //("cls_highlight"+i)})
    $("#Placeid" + id).css({
      filter:
        "invert(3%) sepia(41%) saturate(307%) hue-rotate(46deg) brightness(100%) contrast(84%)",
      border: "1px solid #69c581", backgroundColor: "#FFF"
    });
    let selhome = false,
      selwork = false;
    if (id == 1) {
      selwork = true;
    } else {
      selhome = true;
    }
    this.setState({
      helperPlace: "",
      selwork: selwork,
      selhome: selhome,
      error: false,
      place: item,
    });
  };
  onloadchanges = () => {
    let menuclass = [0, 1, 2, 3, 4, 5];
    let menuitems = [
      "#c00000",
      "#fc0201",
      "#f97d2a",
      "#fec000",
      "#fbe805",
      "#92d051",
    ];
    menuclass.map((i, j) => {
     return  $("#menuList #" + i).css({
        "background-color": "#fcfcfc",
        border: ".95px solid #a6a5a5",
      });
    });
    let { selectedIndex, initialValues, place, Data } =
      this.state;
    if (initialValues) {
      //menuclass.map((i, j) => {
      $("#menuList #" + selectedIndex).css({
        background: menuitems[selectedIndex],
      });
      let placeid, place_id;
      if (place[0]) place_id = place[0].incident_place_id;
      else place_id = place.incident_place_id;

      placeid = Data.incident_place.findIndex(
        (items) => items.incident_place_id == place_id
      );
      setTimeout(() => {
        this.setbackgroundplace(placeid + 1);
      }, 100);
      // });
    }
  };
  handleSelected = (evt, idx) => {
    let menuclass = [0, 1, 2, 3, 4, 5];
    this.setState({
      helperSeverity: "",
      error: false,
    });
    let menuitems = [
      "#c00000",
      "#fc0201",
      "#f97d2a",
      "#fec000",
      "#fbe805",
      "#92d051",
    ];
    menuclass.map((i, j) => {
     return $("#menuList #" + i).css({ "background-color": "#fcfcfc" });
    }); //("cls_highlight"+i)})
    menuclass.map((i, j) => {
      // return $("#menuList #" + idx).css({ background: menuitems[idx] });
      
      return $("#menuList #" + idx).attr("style" ,"background: " + menuitems[idx] + " !important" ).css({border: "1px solid #cdcdcd" });
    });

    let { Data} = this.state;

    this.setState({
      selectedIndex: idx,
      selectedclass: "cls_highlight",
      severity: Data.severity[idx],
      isload: false,
    });
  };
  loadimgdetails = () => {
    let lenPreview,
      imgname, deletedImages = [],
      imageids = ["img_1", "img_2", "img_3", "img_4", "img_5", "img_6"];

    let { attachedFiles } = this.state;
    $(document).ready(function () {
      $(window).click(function (event) {
        let idx,
          idx1,
          currli,
          currId = event.target.id;
        idx = imageids.findIndex((itm) => itm === currId);

        if (idx > -1) {
          currli = "li_" + currId.split("_")[1];
          if ($("#" + currli + " span")[0]) {
            idx1 = attachedFiles.findIndex((item) => item.name == imgname);
            let delIdx = "-1", delSrc = $("#preview li[id='" + currli + "']").find("img").attr("src")
            if (delSrc)
              delIdx = delSrc.indexOf("http");
            if (delIdx > -1) {
              deletedImages.push(delSrc)
            }
            imgname = $("#" + currli + " span")[0].id;
            $("#preview li[id='" + currli + "']").remove();
            attachedFiles.splice(idx1, 1);
            lenPreview = $("#preview li").length;
            if (lenPreview <= 2) { $("#noImage").css({ display: "block" }); $("#addplus").show() };
            if (lenPreview == 0) {
              $("#noImage").removeClass("noImage");
              $("#addchoose").show();
              $("#addplus").hide();
            }
          }
        }
      });
    });
    this.setState({ attachedFiles: attachedFiles, deletedImages: deletedImages });
  }

  _handleSubmit(e) {
    e.preventDefault();
  }
  setImages = (urlData) => {
    this.setState({ attachedFiles: [] })
    var preview = document.getElementById("preview");
    preview.innerHTML = ""
    let attachedImageUrls = urlData
    attachedImageUrls = attachedImageUrls ? attachedImageUrls : []
    let lenPreview = $("#preview li").length;
    let idx = 0, imgcnt = lenPreview + 1;
    let lenFiles = attachedImageUrls.length;
    lenPreview = lenFiles + lenPreview;

    let lenImg = attachedImageUrls.length < 4 ? attachedImageUrls.length : 3;
    if (lenImg > 0) {
      for (var i = 0; i < lenImg; i++) {
        // reader = new FileReader();
        // reader.onload = function (readerEvent) {
        var listItem = document.createElement("li");
        listItem.class = "liContainer";
        listItem.id = "li_" + (imgcnt + idx);
        listItem.innerHTML =
          "<span id='" +
          "span_" + (imgcnt + idx) +
          "' style='display:none'></span><div  id='img_" +
          (imgcnt + idx) +
          "' class='spn_close_image'>X</div><Image class='card-img-top' src='" +
          attachedImageUrls[i] +
          "' />";
        preview.append(listItem);
        idx++;
      }
      $("#noImage").addClass("noImage");
      $("#addchoose").hide();
      if (attachedImageUrls.length < 3) {
        $("#noImage").show();
        $("#addplus").show();
      } else {
        $("#noImage").hide();
        $("#addplus").hide();
      }

    }
  }
  handleImageChange(e, mode) {

    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var preview = document.getElementById("preview");
    var fileInput = document.querySelector("input[type=file]");
    //$("#noImage").css({ "display": "none" });
    $("#noImage").addClass("noImage");
    $("#addchoose").hide();
    $("#addplus").show();
    let lenFiles = fileInput.files.length;
    let lenPreview = $("#preview li").length;
    let imgcnt = lenPreview + 1;
    lenPreview = lenFiles + lenPreview;
    if (lenFiles > 3 || lenPreview > 3) {
      //alert("maximum 3 images only allowed...");
      this.showAlert("warning", this.props.labels.labels.LBLMAXIMGALWD);
      $(".fileInput").value = "";
      return false;
    }
    if (lenFiles >= 3 || lenPreview >= 3) {
      $("#noImage").css({ display: "none" });
    } else {
      $("#noImage").css({ display: "block" });
    }
    let idx = 0;
    let { attachedFiles } = this.state;
    for (var i = 0; i < lenFiles; i++) {
      reader = new FileReader();
      reader.onload = function (readerEvent) {
        var listItem = document.createElement("li");
        listItem.class = "liContainer";
        listItem.id = "li_" + (imgcnt + idx);
        listItem.innerHTML =
          "<span id='" +
          file.name +
          "' style='display:none'></span><div  id='img_" +
          (imgcnt + idx) +
          "' class='spn_close_image'>X</div><Image class='card-img-top' src='" +
          readerEvent.target.result +
          "' />";
        preview.append(listItem);
        idx++;
      };
      attachedFiles.push(file);
      reader.readAsDataURL(fileInput.files[i]);
    }

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        attachedFiles: attachedFiles,
      });

      // reader.readAsDataURL(fileInput.files[0]);
    };
    //reader.readAsDataURL(file)
  }
  getFileName = () => {
    let fileName,
      currDate = new Date();
    fileName = currDate.getDate() + "" + 
      (parseInt(currDate.getMonth()) + 1) + "" + 
      currDate.getFullYear() +
      currDate.getTime();
    return fileName;
  };
  getUploadedImages() {
    let arrUrl, arrUrlLen, imgName, idx;
    let { attachedFiles, attachedImageUrls } = this.state;
    let deletedImages = this.deletedImages ? this.deletedImages : [];
    if (deletedImages.length > 0) {
      deletedImages.map((item, i) => {
        arrUrl = item.split("?")[0].split("/")
        arrUrlLen = arrUrl.length;
        imgName = arrUrl[arrUrlLen - 1];

        idx = attachedImageUrls.findIndex(itm => itm === item);
        attachedImageUrls.splice(idx, 1);
        idx = attachedImageUrls.map(itm => itm.asset_name == deletedImages[i]);
        if (idx < 0) {
          attachedFiles.push({ "uploadedFiles": deletedImages[i] })
        }
      });
    }
    return attachedFiles;
  };
  saveUploadedImages() {
    //let currId = evt.currentTarget.id;
    this.imageUploadHandler();
    let { attachedFiles } = this.state;
    return attachedFiles;
  };


  fileAttachOnChangeHandler = (evt) => {
    if (evt && evt.target && evt.target.files && evt.target.files.length) {
      this.setState({ attachedFiles: [] });
      for (let i = 0; i < evt.target.files.length; i++) {
        this.state.attachedFiles.push(evt.target.files[i]);
        this.fileNameWithTimeStamp = utils.generateFeedsPostFileName(
          evt.target.files[i].name
        );
        this.fileNameRef.current.value = this.fileNameWithTimeStamp;

        this.setState({ fileNameRef: this.fileNameWithTimeStamp });
        this.isFileUploadFinish = 0;
        this.props.uploadStatusHandler(
          this.isFileUploadFinish,
          this.fileNameRef.current.value
        );
        this.imageUploadHandler();
      }
      if (this.state.attachedFiles.length) {
        this.setState({
          isUploadBtnActive: 0, //attachedFiles
        });
      }
    }
  };
  removeFileNameHandler = (evt) => {
    this.fileNameRef.current.value = "";
    this.isFileUploadFinish = 0;
    this.props.uploadStatusHandler(
      this.isFileUploadFinish,
      this.fileNameRef.current.value
    );
    this.setState({
      attachedFiles: [],
      isUploadBtnActive: 1,
      isDeleteBtnActive: 0,
    });
  };
  imageUploadHandler = () => {
    this.setattachedImages();
    let attachedAllFiles = this.state.attachedFiles;

    if (attachedAllFiles == 0 || attachedAllFiles)
      if (this.state.attachedFiles.length) {
        this.state.attachedFiles.map((item, i) => {
          this.fileNameWithTimeStamp = utils.generateFeedsPostFileName(
            this.state.attachedFiles[i].name
          );
          this.isFileUploadFinish = 0;
          let attachedFilesLoc = this.state.attachedFiles;
          attachedFilesLoc[i].uploadedFiles = this.fileNameWithTimeStamp;
          this.setState({ showLoader: 1, attachedFiles: attachedFilesLoc });
          this.uploadAssets(item, i);
        });
      }
    return this.isFileUploadFinish
  };
  uploadAssets = (data, i) => {
    let count = i;
    let folderPath = this.props.companyId + "/"; //this.props.userInfo.currentuser.company_id + "/";
    let s3Upload = new S3MultiUpload(
      data,
      this.fileNameWithTimeStamp,
      "",
      folderPath
    );
    s3Upload.start();
    let uploadedFiles = []
    s3Upload.onUploadCompleted = (data) => {
      if (data) {
        let { attachedFiles } = this.state;
        let cnt = count;
        //attachedFiles[i].uploadedFiles = this.fileNameWithTimeStamp
        uploadedFiles.push(this.fileNameWithTimeStamp)
        let lenFiles = this.state.attachedFiles.length

        this.isFileUploadFinish = 1;
        if ((i + 1) == lenFiles) {
          this.uploadStatusHandler(
            this.isFileUploadFinish, attachedFiles
            //this.fileNameWithTimeStamp
          );
          this.setState({
            showLoader: 0,
            isUploadBtnActive: 0,
            isDeleteBtnActive: 1,
            attachedFiles: attachedFiles
          });
        }
      }
    };
  };
  render() {
    console.log(" selectedDate," ,this.state.selectedDate, this.state.selectedTime)
    let {
      LBLINCIDENTID, LBLERRORSOURCE,
      LBLSOURCE1, LBLSOURCE2, LBLSOURCE3,
      LBLSTATUS, LBLACTION, LBLEDIT,
      LBLCANCEL,LBLOF, LBLROWSPERPAGE,
      LBLALL,LBLPLACE, LBLDEPARTMENT, LBLLOCATION,
      LBLINCLOCATION, LBLINCLOCATIONINPUT, LBLSHIFT,
      LBLINCTYPES, LBLSEVERITY, LBLDATE,
      LBLNMEDIT,LBLLOCATIONS, LBLOPEN, LBLCLOSE, LBLCLOSED,
      LBLINCTITLE,LBLEVTDATE, LBLEVTTIME, LBLINCSUMMARY,
      LBLATTACHPIC, LBLSTATES, LBLERRORS, LBLCA,
      LBLCHOSEIMG,LBLSELECT,LBLWRITEHERE, LBLSUBMIT,
      LBLFIRST, LBLSECOND, LBLTHIRD
    } = this.props.labels.labels;

    const {
      Data, selectedSource,
      selectedDate,
      selectedShift,
      selectedTime,
      allGroupss,
      open,
      message,
      messageType,
    } = this.state;

    LBLSOURCE1 = LBLSOURCE1 ? LBLSOURCE1 : "Self";
    LBLSOURCE2 = LBLSOURCE2 ? LBLSOURCE2 : "Other People";
    LBLSOURCE3 = LBLSOURCE3 ? LBLSOURCE3 : "Equipment";

    const selectedValue = this.state.selectedValue == true ? "1" : "2";

    $(".rdt_TableHeadRow").css({ "background-color": "#f3f2f2" });
    $(".sc-fzpans").css({ "font-size": "10px", "margin-top": "3px" });

    const location = allGroupss ? allGroupss["GP_1"] : [];
    const department = allGroupss ? allGroupss["GP_2"] : [];
    let imgCardStates = [
      { image: "../../images/nearmiss/Rushing.png" },
      { image: "../../images/nearmiss/Frustration.png" },
      { image: "../../images/nearmiss/Fatigue.png" },
      { image: "../../images/nearmiss/Complacency.png" },
    ];
    let imgCardErrors = [
      { image: "../../images/nearmiss/Eyes.png" },
      { image: "../../images/nearmiss/Mind.png" },
      { image: "../../images/nearmiss/Line.png" },
      { image: "../../images/nearmiss/Balance.png" },
    ];
    let imgCardPlaces = [
      { image: "../../images/nearmiss/work54.png" },
      { image: "../../images/nearmiss/Home53.png" },
      { image: "../../images/nearmiss/drive52.png" },
      { image: "../../images/nearmiss/trans.png" },
    ];

    let horizontal = "top",
      vertical = "center";

    const { searchValue, allFilterValues, listData } = this.state;
    let allGroups = [],
      allGroupIds = [],
      allFilters = [],
      typeFilter = "", sourceFilter = "",
      status = "",
      severityFilter = "";
    // allFilters = JSON.parse(allFilterValues)
    if (Object.keys(allFilterValues).length > 0) {
      allFilters = allFilterValues.multiItems;
      typeFilter = allFilterValues.singleItems.incidenttype
        ? allFilterValues.singleItems.incidenttype
        : "";
      sourceFilter = allFilterValues.singleItems.incidentsource
        ? allFilterValues.singleItems.incidentsource
        : "";
      severityFilter = allFilterValues.singleItems.incidentseverity
        ? allFilterValues.singleItems.incidentseverity
        : "";
      status = allFilterValues.singleItems.status
        ? allFilterValues.singleItems.status
        : "";
      status = status == "1" ? true : status == "0" ? false : "";
      //  allGroups = [...allFilters.group1,...allFilters.group2,...allFilters.group3]
      let objkeys = Object.keys(allFilters);
      for (var m = 0; m < objkeys.length; m++) {
        allGroups =
          allFilters[objkeys[m]] && allFilters[objkeys[m]].length > 0
            ? allGroups.concat(allFilters[objkeys[m]])
            : allGroups.concat({});
      }

      allGroups.map((item) => {
        if (item.name) {
          allGroupIds.push(item.name);
        }
        return null;
      });
    }

    let objSeverity,
      objIncType, objIncSource,
      filteredListItems = [];
    filteredListItems = listData;

    if (listData && Object.keys(listData).length > 0) {
      filteredListItems = listData.filter((item) => {
        // item = items.values[0];
        objIncSource =
          item.incident_source.incident_source_name.indexOf("LBLSOURCE") >= 0
            ? objLabels[item.incident_source.incident_source_name]
            : item.incident_source.incident_source_name;
        objIncSource = objIncSource ? objIncSource : ""
        objIncType =
          item.incident_type.incident_type_name.indexOf("LBL") >= 0
            ? objLabels[item.incident_type.incident_type_name]
            : item.incident_type.incident_type_name;
        objSeverity =
          item.incident_severity.incident_severity_name.indexOf("LBL") >= 0
            ? objLabels[item.incident_severity.incident_severity_name]
            : item.incident_severity.incident_severity_name;
        if (
          (item.incident_source.incident_source_name &&
            (objIncSource.includes(searchValue) ||
              objIncSource.toLowerCase().includes(searchValue.toLowerCase()))) ||
          (item.incident_type.incident_type_name &&
            (objIncType.includes(searchValue) ||
              objIncType.toLowerCase().includes(searchValue.toLowerCase()))) ||
          (item.event_date && item.event_date.includes(searchValue)) ||
          (item.incident_severity &&
            (objSeverity.includes(searchValue) ||
              objSeverity.toLowerCase().includes(searchValue.toLowerCase()))) ||
          (item.incident_title &&
            (item.incident_title.includes(searchValue) ||
              item.incident_title
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          (item.incident_id &&
            (item.incident_id.toString().includes(searchValue) ||
              item.incident_id
                .toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          (item.location &&
            (item.location.location_name.includes(searchValue) ||
              (item.location !== "" &&
                item.location != null &&
                item.location.location_name
                  .toLowerCase()
                  .includes(searchValue.toLowerCase()))))
        ) {
          //  if (typeFilter != "" && severityFilter!="") {
          let { allDataLists } = this.state;
          let allData = allDataLists;
          let indx1,
            indx2, indx3,
            srchType = "",
            srchSource = "",
            srchSeverity = "";
          if (allData[0].incident_type) {
            indx1 = allData.findIndex(
              (itm) => itm.incident_type.incident_type_id === typeFilter
            );
            indx2 = allData.findIndex(
              (itm) =>
                itm.incident_severity.incident_severity_id === severityFilter
            );
            indx3 = allData.findIndex(
              (itm) => itm.incident_source.incident_source_id === sourceFilter
            );
            if (indx1 > -1)
              srchType = allData[indx1].incident_type.incident_type_name;
            else srchType = typeFilter;
            if (indx2 > -1)
              srchSeverity =
                allData[indx2].incident_severity.incident_severity_name;
            else srchSeverity = severityFilter;
            if (indx3 > -1)
              srchSource = allData[indx3].incident_source.incident_source_name;
            else srchSource = sourceFilter;
            // if(srchSeverity!="")
            // srchSeverity= this.props.labels.labels[srchSeverity]
          }
          if (
            ((item.incident_type &&
              item.incident_type.incident_type_name == srchType) ||
              srchType === "") &&
            ((item.incident_source &&
              item.incident_source.incident_source_name == srchSource) ||
              srchSource === "") &&
            (item.incident_severity.incident_severity_name === srchSeverity ||
              srchSeverity === "") &&
            (item.status === status || status === "")
          ) {
            let blnRetrn = false,
              usrGroupsNew = [{}, {}, {}, {}, {}, {}, {}],
              usrGroups = [item.location, item.department];

            usrGroupsNew = usrGroups;
            if (allGroupIds.length > 0) {
              let cnt = 0,
                count = 0;
              for (let idx = 0; idx < Object.values(allFilters).length; idx++) {
                if (
                  usrGroupsNew[idx] && //&& usrGroupsNew[idx].length !== undefined
                  Object.values(allFilters)[idx].length > 0
                ) {
                  blnRetrn =
                    allGroupIds.indexOf(usrGroupsNew[idx].location_name) >= 0
                      ? true
                      : false;

                  if (blnRetrn) cnt++;
                  else cnt--;
                  count++;
                } else if (Object.values(allFilters)[idx].length > 0) {
                  blnRetrn = false;
                  cnt--;
                } else if (blnRetrn && cnt >= count) {
                  blnRetrn = true;
                }
              }
              if (blnRetrn && cnt >= count) {
                ;
                return item;
              }
            } else {
              return item;
            }
          } else {
            return;
          }
        }
        return null;
      });
    }
    this.formattingDate = (date) => {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let dateformat,
        fulldate = "";
      dateformat = this.state.dateformat;
      if (dateformat === "dd/MM/yyyy") {
        fulldate = [day, month, year].join("/");
      } else if (dateformat === "MM/dd/yyyy") {
        fulldate = [month, day, year].join("/");
      } else if (dateformat === "yyyy/MM/dd") {
        fulldate = [year, month, day].join("/");
      } else {
        fulldate = [day, month, year].join("/");
      }
      return fulldate;
    };

    let columns = [
      {
        name: "",
        selector: "name",
        sortable: true,
        compact: true,
        width: "1%",
        cell: (row) => {
          return <div data-toggle="tooltip"></div>;
        },
      },
      {
        name: LBLINCIDENTID,
        selector: "incident_id",
        sortable: true,
        compact: true,
        width: "10%",
        visible: false,
        cell: (row) => {
          return (
            <div
              data-toggle="tooltip"
              style={{ width: "100%" }}
            >
              <span
                style={{
                  textAlign: "center",
                  display: "block",
                  marginLeft: "-40%",
                }}
                className="rle-brdr1"
              >
                {row.incident_id > 0
                  ? row.incident_id.toString().substring(7, 12)
                  : row.incident_id}
              </span>
            </div>
          );
        },
      },
      {
        name: LBLINCTITLE,
        selector: "incident_title",
        width: "18%",
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          let incident_title = row.incident_title.length > 20 ?
            row.incident_title.substring(0, 20) + "..." : row.incident_title;
          return (
            <div className="vfy-col">
              <span title={row.incident_title} className="rle-brdr">{incident_title}</span>
              <span title={row.incident_title} className="show-sm-xs">{incident_title}</span>
            </div>
          );
        },
      },
      {
        name: LBLERRORSOURCE,
        selector: "incident_type",
        width: "14%",
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col">
              <span className="rle-brdr">
                {this.props.labels.labels[row.incident_source.incident_source_name]}
              </span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLINCTYPES,
        selector: "incident_type",
        width: "13%",
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col">
              <span className="rle-brdr">
                {this.props.labels.labels[row.incident_type.incident_type_name]}
              </span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLSEVERITY,
        selector: "incident_severity",
        width: "8%",
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col">
              <span
                className="rle-brdr"
                style={{
                  color:
                    row.incident_severity.incident_severity_name === "Serious"
                      ? "#c00000"
                      : row.incident_severity.incident_severity_name === "Major"
                        ? "#fc0201"
                        : row.incident_severity.incident_severity_name === "Minor"
                          ? "#f97d2a"
                          : row.incident_severity.incident_severity_name ===
                            "First Aid"
                            ? "#fec000"
                            : row.incident_severity.incident_severity_name === "Low"
                              ? "#fbe805"
                              : row.incident_severity.incident_severity_name ===
                                "Very Low"
                                ? "#92d051"
                                : "",
                }}
              >
                {
                  this.props.labels.labels[
                  row.incident_severity.incident_severity_name
                  ]
                }
              </span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLLOCATIONS,
        selector: "location",
        width: "10%",
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col" style={{ width: "100%" }}>
              <span className="rle-brdr cls_ellipsis" title={row.location && row.location.location_name}>
                {row.location && row.location.location_name}
              </span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLDATE,
        selector: "event_date",
        width: "11%",
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col">
              <span className="rle-brdr">
                {this.formattingDate(row.event_date)}
              </span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLSTATUS,
        selector: "status",
        width: "8%",
        style: {},
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col">
              {/* <span className="rle-brdr">{row.shift}</span> */}
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
              <Form.Check
                type="switch"
                label=""
                title={`${row.status === true || row.status === 1 ? LBLOPEN : LBLCLOSED
                  }`}
                disabled={true}
                //checked={true}
                checked={row.status === true || row.status === 1}
              />
            </div>
          );
        },
      },

      {
        name: LBLACTION,
        selector: "actions",
        width: "7%",
        compact: true,
        cell: (row) => {
          return (
            <div className="icoBtn">
              <i
                className=""
                data-toggle="modal"
                onClick={this.openEditModal.bind(this, row)}
                alt={LBLEDIT}
              >
                {LBLEDIT}
              </i>
            </div>
          );
        },
      },
    ];

    let data = filteredListItems;

    data = filteredListItems;
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    let closed = this.props.incclosed;
    let tableHeader = this.tableHeader();
    let sourceDecText = "", sourceDecTitle = selectedSource.incident_source_name ? selectedSource.incident_source_name : "";
    sourceDecTitle = selectedSource.incident_source_name && this.props.labels.labels[selectedSource.incident_source_name];
    //sourceDecTitle =  labels[selectedSource.incident_source_name];
    sourceDecText = selectedSource.incident_source_desc ? selectedSource.incident_source_desc : "";

    return (
      <div className="mob-pad">
        {this.state.loading ? (

          <Loader></Loader>
        ) : (
          <div id="nearmissList" style={{ display: "none" }} className="near_miss">
            <div
              id="permission"
              style={{
                margin: "15% 0% 0% 33%",
                position: "absolute",
                "z-index": "1000",
              }}
            >
              <div>
                <Loader></Loader>
              </div>
            </div>
            <DataTable
              id="near_miss_list"
              columns={columns}
              data={data}
              defaultSortField="name"
              className="datatable-list"
              pagination
              subHeader
              subHeaderComponent={tableHeader}
              sortFunction={this.customSort}
              noDataComponent={<div style={{ marginTop: "5%" }}><EmptyState image={emptyImage} text2={this.props.labels.labels.LBLCLOSECALLEMPTYTXT} /></div>}
              paginationComponentOptions={paginationComponentOptions}
            />
          </div>
        )}
        {this.state.loadingadd ? (
          <div style={{ top: "130px", position: "relative" }}>
            <Loader></Loader>
          </div>
        ) : (
          <div
            id="addNearMiss"
            className="near_miss"
            style={{
              display: this.state.showadd ? "" : "none",
              marginTop: "-20px",
            }}
          >
            {this.state.initialValues === null ? (
              <div>
                <div
                  style={{
                    width: "100%",
                    marginTop: "0px",
                    //textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="page-header-field"
                >
                  <h3  className="page-title" style={{marginBottom: "0px"}}>
                    {this.props.labels.labels.LBLADDNEWNM}
                  </h3>

                  <div className="btn-align">
                    <button
                      type="button"
                      className="button-style primary-btn"
                      id="showlistadd"
                      onClick={this.handleLists}
                    >
                      {" "}
                      {this.props.labels.labels.LBLVWNMLIST}
                    </button>
                  </div>
                  {/* <span style={{ color: "red", "fontSize": "14px", marginRight: "2%" }} className="required">*{LBLALLREQD}</span> */}
                </div>
                <hr className="head-line" />
              </div>
            ) : (
              <div>
                <div
                  style={{
                    width: "100%",
                    marginTop: "0px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                  className="col-xl-12 col-sm-6"
                >
                  <h3 style={{ marginTop: "20px" }} className="page-title">{LBLNMEDIT}</h3>
                  <div className="btn-align">
                    <button
                      type="button"
                      className="button-style primary-btn"
                      id="showlistadd"
                      onClick={this.handleLists}
                    >
                      {" "}
                      {this.props.labels.labels.LBLVWNMLIST}
                    </button>
                  </div>
                  {/* <span style={{ color: "red", "fontSize": "14px", marginRight: "2%" }} className="required">*{LBLALLREQD}</span> */}
                </div>
                <hr className="head-line" />
              </div>
            )}
            <div
              className="near_miss_lnk"
              style={{
                marginTop: "0px",
                width: "100%",
                display: "inline-flex",
              }}
            >
              <div
                className="col-xl-9 col-sm-6"
                style={{ marginRight: "0%" }}
                id="showlista"
              ></div>
              {this.state.initialValues ?
                <div
                  style={{ display: "inline-flex", margin: "1px -4px 14px 1px" }}
                  className="col-xl-3 col-sm-6"
                >
                  <label
                    style={{ display: "block", lineHeight: "40px" }}
                    className="cls_subtitle label"
                  >
                    {LBLSTATUS}
                  </label>
                  <Radio
                    checked={selectedValue === "1"}
                    onChange={this.handleChange}
                    value="1"
                    className="clsRadioText"
                    style={{
                      color: green[600],
                      display: this.state.incclosed ? "none" : "",
                    }}
                    name="radio1"
                    id="radio1"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <span
                    style={{ display: this.state.incclosed ? "none" : "" }}
                    className="clsRadioText"
                  >
                    {LBLOPEN}
                  </span>
                  {this.state.initialValues ?
                    <>
                      <Radio
                        checked={selectedValue === "2"}
                        onChange={this.handleChange}
                        value="2"
                        style={{ color: red[600] }}
                        name="radio2"
                        id="radio2"
                        inputProps={{ "aria-label": "B" }}
                      />
                      <span className="clsRadioText">
                        {this.state.incclosed ? LBLCLOSED : LBLCLOSE}
                      </span>
                    </> : ""}
                </div>
                : ""}
            </div>
            <React.Fragment>
              <Snackbar
                anchorOrigin={{ horizontal, vertical }}
                key={"top-center"}
                open={open}
                autoHideDuration={
                  this.state.showtime ? this.state.showtime : 3000
                }
                onClose={this.handleClose}
                bodyStyle={{ backgroundColor: "teal", color: "coral" }}
              >
                <Alert
                  variant="filled"
                  onClose={this.handleClose}
                  severity={messageType}
                >
                  {message}
                </Alert>
              </Snackbar>
              <section className="card-box1 near_miss_section near_miss">
                <div className="container riskcard-board slide-brd">
                  <div className="reporting_table">

                    <div
                      style={{ marginTop: "-4px" }}
                      className="cls_nm_sub_header"
                    >
                      {this.props.labels.labels.LBLSOURCETITLE ? this.props.labels.labels.LBLSOURCETITLE : "What is the source of error?"}
                    </div>
                    <div className="cls_grouping">
                      <div className="cls_sub_grouping">
                        <div className="cls_sub_grouping3" style={{ display: "none", marginRight: "0px" }}>
                          <div className="row">
                            <div className="col-xl-6 col-sm-12">
                              <label className="cls_subtitle label">

                              </label>
                              <div
                                className="column_src"
                                id="Source"
                              // style={{ display: "inline-flex" }}
                              >
                                <div style={{}}>
                                  <Radio
                                    checked={selectedSource.incident_source_id === "incident_source_01"}
                                    onChange={this.handleSource}
                                    value="LBLSOURCE1"
                                    className="clsRadioText"
                                    style={{ color: green[600] }}
                                    name="sourceradio"
                                    inputProps={{ "aria-label": "A" }}
                                    disabled={this.state.incclosed}
                                  />{" "}
                                  <span className="clsRadioText">
                                    {LBLSOURCE1}
                                  </span>
                                </div>

                                <div style={{}}>
                                  <Radio
                                    checked={selectedSource.incident_source_id === "incident_source_02"}
                                    onChange={this.handleSource}
                                    value="LBLSOURCE2"
                                    style={{ color: green[600] }}
                                    name="sourceradio"
                                    inputProps={{ "aria-label": "B" }}
                                    disabled={this.state.incclosed}
                                  />{" "}
                                  <span className="clsRadioText">
                                    {LBLSOURCE2}
                                  </span>
                                </div>

                                <div style={{}}>
                                  <Radio
                                    checked={selectedSource.incident_source_id === "incident_source_03"}
                                    onClick={this.handleSource}
                                    value="LBLSOURCE3"
                                    style={{ color: green[600] }}
                                    name="sourceradio"
                                    inputProps={{ "aria-label": "C" }}
                                    disabled={this.state.incclosed}
                                  />{" "}
                                  <span className="clsRadioText">
                                    {LBLSOURCE3}
                                  </span>
                                </div>
                              </div>

                              <span className="required">
                                {this.state.helperSource}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="cls_sub_grouping4">
                          <div className="cls_parent_container">
                            <div className="cls_img_parent">
                              <img
                                src={this.state.source_images[this.state.selected_image]}
                                alt=""
                              ></img>
                            </div>
                            <div className="cls_img_text cls_img_pointer_text">
                              {/* {this.state.sources[this.state.selected_image]} */}
                              <div id="1" className={"cls_img_pointer " + (selectedSource.incident_source_id === "incident_source_01" ? "cls_active_source" : "")} onClick={this.handleSources}>{LBLSOURCE1}</div>
                              <div id="2" className={"cls_img_pointer " + (selectedSource.incident_source_id === "incident_source_02" ? "cls_active_source" : "")} onClick={this.handleSources}>{LBLSOURCE2}</div>
                              <div id="3" className={"cls_img_pointer " + (selectedSource.incident_source_id === "incident_source_03" ? "cls_active_source" : "")} onClick={this.handleSources}>{LBLSOURCE3}</div>
                            </div>

                          </div>
                        </div>

                      </div>

                      <div className="row cls_sub_grouping5" style={{ display: "none" }}>
                        {/* style={{display:sourceDecTitle?"":"none"}} */}
                        <div className="col-xl-9 col-sm-12">
                          <label className="cls_subtitle label">
                            {sourceDecTitle}
                          </label>

                          <br></br>
                          <TextField
                            id="SourceDesc"
                            className="col-xl-8"
                            style={{ maxWidth: "600px" }}
                            multiline
                            rows={3}
                            //helperText={this.state.helperSummary}
                            variant="outlined"
                            cols={200}
                            value={sourceDecText}
                            onChange={this.handleSourceChange}
                            error={this.state.errorSrc}
                            //required
                            disabled={this.state.incclosed}
                          />
                          <span className="required">{this.state.helperSourceDesc}</span>
                        </div>
                      </div>
                      <span className="required">
                        {this.state.helperSource}
                      </span>
                    </div>
                    <div className="cls_nm_sub_header">
                      {this.props.labels.labels.LBLLOCATIONHAPPEN}
                    </div>
                    <div className="cls_grouping cls_sub_grouping">
                      <div className="cls_sub_grouping1">
                        <div className="row">
                          <div className="col-xl-12 col-sm-12" id="inc_title">
                            <TextField
                              className="cls_subtitle inc_title"
                              id="Title"
                              //autoFocus
                              disabled={this.state.incclosed}
                              value={this.state.title}
                              label={LBLINCTITLE}
                              validate={[required, maxLength255]}
                              //helperText={this.state.helperTitle} 
                              error={this.state.errorTitle}
                              onChange={this.handleTitleChange}
                            //onClick={this.setvalue}
                            //required
                            />
                            <span className="required">
                              {this.state.helperTitle}
                            </span>
                          </div>
                          {/* <div className="col-xl-2 col-sm-2"></div> */}
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="col-xl-12 col-sm-6">
                            <label htmlFor="Type" className="cls_subtitle label">
                              {LBLINCTYPES}
                            </label>
                            <Autocomplete
                              id="combo-box-demo"
                              aria-required="true"
                              required
                              disableClearable
                              options={Data.incident_type ? Data.incident_type : []}
                              getOptionLabel={(option) => option[0] ? option[0].incident_type_name : option.incident_type_name}
                              getOptionSelected={(option, value) => (
                                value.length ? option.incident_type_name === value[0].incident_type_name : option.incident_type_name === value.incident_type_name
                              )}
                              value={this.state.type.length ? this.state.type[0] : null}
                              onChange={this.handleTypeChange}
                              renderOption={(option, i) =>
                                <div className="renderOption" >
                                  {option.incident_type_name}
                                </div>
                              }
                              renderInput={(params) => (
                                <div>
                                  <TextField
                                    placeholder={LBLSELECT}
                                    {...params}
                                  />
                                </div>
                              )}
                            />
                            {/* </FormControl> */}
                            <span className="required">
                              {this.state.helperType}
                            </span>
                          </div>
                          {/* <div className="col-xl-2 col-sm-2"></div> */}
                        </div>
                        <br></br>
                        <div className="row">
                          <div className="col-xl-6 col-sm-6">
                            <label className="cls_subtitle label">{LBLEVTDATE}</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                //label="Date picker dialog"
                                format={this.state.dateformat}
                                // format="dd/MM/yyyy"
                                id="Date"
                                style={{ fontSize: "24px" }}
                                value={selectedDate}
                                maxDate={new Date()}
                                onChange={this.handleDateChange}
                                helperText={this.state.helperDate}
                                disabled={this.state.incclosed}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                              <span className="required">
                                {this.state.helperDate}
                              </span>
                            </MuiPickersUtilsProvider>
                          </div>

                          <div
                            className="col-xl-6 col-sm-6"
                          // style={{ marginTop: "10px" }}
                          >
                            <label className="cls_subtitle label">{LBLEVTTIME}</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardTimePicker
                                margin="normal"
                                helperText={this.state.helperTime}
                                id="Time"
                                // label="Time picker"
                                value={selectedTime}
                                onChange={this.handleTimeChange}
                                disabled={this.state.incclosed}
                                KeyboardButtonProps={{
                                  "aria-label": "change time",
                                }}
                              />
                              <span className="required">
                                {this.state.helperTime}
                              </span>
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                      </div>
                      <div className="cls_sub_grouping2">
                        <img
                          src="../../images/nearmiss/man wire fall2.png"
                          style={{
                            opacity: "0.7",
                            width: "80%",
                            height: "230px",
                            position: "relative",
                          }}
                          alt=""
                        ></img>
                      </div>
                    </div>

                    <div className="cls_nm_sub_header">
                      {this.props.labels.labels.LBLINCIDENTPLACE}
                    </div>
                    <div className="cls_grouping">
                      <label className="cls_subtitle label"> {LBLPLACE}</label>
                      <div className="place_id">
                        {/* <label className="cls_subtitle label"> {LBLPLACE}</label> */}
                        <div id="cls_inc_place" style={{ display: "flex" }}>
                          <div
                            className="col-md-2 col-sm-12 column_plc"
                            id="Place1"
                          >
                            <div className="card_place box-place">
                              <label className="check-lbl-plc">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  name=" "
                                  checked={
                                    Data.incident_place[0] &&
                                    Data.incident_place[0].isNewAssigned
                                  }
                                  onClick={this.handlePlaceChange.bind(
                                    this,
                                    Data.incident_place[0],
                                    Data.incident_place[0].isNewAssigned
                                      ? 1
                                      : 0,
                                    1
                                  )}
                                  disabled={this.state.incclosed}
                                ></input>
                                {/* <span className="checkmark"></span> */}
                                <img
                                  src={imgCardPlaces[0].image}
                                  style={{ borderRadius: "10px" }}
                                  alt=""
                                  id="Placeid1"
                                ></img>
                              </label>
                              <span className="rys-text-place">
                                {Data.incident_place &&
                                  Data.incident_place[0].incident_place_name}
                              </span>
                            </div>
                          </div>

                          <div
                            className="col-md-2 col-sm-12 column_plc"
                            id="Place2"
                          >
                            <div className="card_place box-place">
                              <label className="check-lbl-plc">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  name=" "
                                  checked={
                                    Data.incident_place[1] &&
                                    Data.incident_place[1].isNewAssigned
                                  }
                                  onClick={this.handlePlaceChange.bind(
                                    this,
                                    Data.incident_place[1],
                                    Data.incident_place[1].isNewAssigned
                                      ? 1
                                      : 0,
                                    2
                                  )}
                                  disabled={this.state.incclosed}
                                ></input>
                                {/* <span className="checkmark"></span> */}
                                <img
                                  src={imgCardPlaces[1].image}
                                  style={{ borderRadius: "10px" }}
                                  alt=""
                                  id="Placeid2"
                                ></img>
                              </label>
                              <span className="rys-text-place">
                                {Data.incident_place &&
                                  Data.incident_place[1].incident_place_name}
                              </span>
                            </div>
                          </div>

                          <div
                            className="col-md-2 col-sm-12 column_plc"
                            id="Place3"
                          >
                            <div className="card_place box-place">
                              <label className="check-lbl-plc">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  name=" "
                                  checked={
                                    Data.incident_place[2] &&
                                    Data.incident_place[2].isNewAssigned
                                  }
                                  onClick={this.handlePlaceChange.bind(
                                    this,
                                    Data.incident_place[2],
                                    Data.incident_place[2].isNewAssigned
                                      ? 1
                                      : 0,
                                    3
                                  )}
                                  disabled={this.state.incclosed}
                                ></input>
                                {/* <span className="checkmark"></span> */}
                                <img
                                  src={imgCardPlaces[2].image}
                                  style={{ borderRadius: "10px" }}
                                  alt=""
                                  id="Placeid3"
                                ></img>
                              </label>
                              <span className="rys-text-place">
                                {Data.incident_place &&
                                  Data.incident_place[2].incident_place_name}
                              </span>
                            </div>
                          </div>

                          <div
                            className="col-md-2 col-sm-12 column_plc"
                            id="Place4"
                          >
                            <div className="card_place box-place">
                              <label className="check-lbl-plc">
                                <input
                                  className="custom-control-input"
                                  type="radio"
                                  name=" "
                                  checked={
                                    Data.incident_place[3] &&
                                    Data.incident_place[3].isNewAssigned
                                  }
                                  onClick={this.handlePlaceChange.bind(
                                    this,
                                    Data.incident_place[3],
                                    Data.incident_place[3].isNewAssigned
                                      ? 1
                                      : 0,
                                    4
                                  )}
                                  disabled={this.state.incclosed}
                                ></input>
                                {/* <span className="checkmark"></span> */}
                                <img
                                  src={imgCardPlaces[3].image}
                                  style={{ borderRadius: "10px" }}
                                  alt=""
                                  id="Placeid4"
                                ></img>
                              </label>
                              <span className="rys-text-place">
                                {Data.incident_place &&
                                  Data.incident_place[3].incident_place_name}
                              </span>
                            </div>
                          </div>
                        </div>
                        <span
                          style={{ marginLeft: "15px", marginBottom: "5px" }}
                          className="required"
                        >
                          {this.state.helperPlace}
                        </span>
                      </div>

                      <div
                        style={{ display: this.state.selwork ? "" : "none" }}
                      >
                        <div className="row">
                          <div className="col-xl-6 col-sm-12">
                            <label className="cls_subtitle label">
                              {LBLLOCATION}
                            </label>
                            {console.log("yyy", this.state.location)}
                            <Autocomplete
                              id="combo-box-demo"
                              aria-required="true"
                              required
                              disableClearable
                              options={location ? location : []}
                              getOptionLabel={(option) => option[0] ? option[0].group_name : option.group_name}
                              getOptionSelected={(option, value) => (
                                value.length ? option.group_name === value[0].group_name : option.group_name === value.group_name
                              )}
                              //value={this.state.location.length ? this.state.location[0] : null}
                              value={this.state.location !== undefined && this.state.location.length>0 ? this.state.location[0] : null}
                              onChange={this.handleLocationChange}
                              renderOption={(option, i) =>
                                <div className="renderOption" >
                                  {option.group_name}
                                </div>
                              }
                              renderInput={(params) => (
                                <div>
                                  <TextField
                                    placeholder={LBLSELECT}
                                    {...params}
                                  />
                                </div>
                              )}
                            />
                            <span className="required">
                              {this.state.helperLocation}
                            </span>
                          </div>
                          <div className="col-xl-2 col-sm-2"></div>
                        </div>
                        <div className="clsverticalAlign"></div>
                        <div style={{ display: "" }} className="row">
                          <div className="col-xl-6 col-sm-12">
                            <label className="cls_subtitle label">
                              {LBLDEPARTMENT}
                            </label>
                            {console.log("dept", this.state.department)}
                            <Autocomplete
                              id="combo-box-demo"
                              aria-required="true"
                              required
                              disableClearable
                              options={department ? department : []}
                              getOptionLabel={(option) => option[0] ? option[0].group_name : option.group_name}
                              getOptionSelected={(option, value) => (
                                value.length ? option.group_name === value[0].group_name : option.group_name === value.group_name
                              )}
                              //value={this.state.department.length ? this.state.department[0] : null}
                              value={this.state.department !== undefined && this.state.department.length>0 ? this.state.department[0] : null}
                              
                              onChange={this.handleDepartmentChange}
                              renderOption={(option, i) => (
                                <div className="renderOption" >
                                  {console.log("option", option)}
                                  {option.group_name}
                                </div>
                              )}
                              renderInput={(params) => (
                                <div>
                                  <TextField
                                    placeholder={LBLSELECT}
                                    {...params}
                                  />
                                </div>
                              )}
                            />
                            <span className="required">
                              {this.state.helperDepartment}
                            </span>
                          </div>

                        </div>
                        <br></br>

                        <div className="row" style={{ marginLeft: "0px" }}>
                          <div className="row">
                            <div className="col-xl-6 col-sm-12">
                              <label className="cls_subtitle label">
                                {" "}
                                {LBLSHIFT}
                              </label>
                              <div
                                className="column_plc"
                                id="Shift"
                                style={{ display: "inline-flex" }}
                              >
                                {/* <label style={{ display: "block", "lineHeight": "40px" }} className="cls_subtitle label">{LBLINCSTATUS}</label>   */}
                                <div style={{ display: "inline-flex" }}>
                                  <Radio
                                    checked={selectedShift.toString() === "1"}
                                    onChange={this.handleShift}
                                    value="1"
                                    className="clsRadioText"
                                    style={{ color: green[600] }}
                                    name="placeradio"
                                    inputProps={{ "aria-label": "A" }}
                                    disabled={this.state.incclosed}
                                  />
                                  <span className="clsRadioText">
                                    {LBLFIRST}
                                  </span>
                                </div>

                                <div style={{ display: "inline-flex" }}>
                                  <Radio
                                    checked={selectedShift.toString() === "2"}
                                    onChange={this.handleShift}
                                    value="2"
                                    style={{ color: green[600] }}
                                    name="placeradio"
                                    inputProps={{ "aria-label": "B" }}
                                    disabled={this.state.incclosed}
                                  />{" "}
                                  <span className="clsRadioText">
                                    {LBLSECOND}
                                  </span>
                                </div>

                                <div style={{ display: "inline-flex" }}>
                                  <Radio
                                    checked={selectedShift.toString() === "3"}
                                    onClick={this.handleShift}
                                    value="3"
                                    style={{ color: green[600] }}
                                    name="placeradio"
                                    inputProps={{ "aria-label": "C" }}
                                    disabled={this.state.incclosed}
                                  />{" "}
                                  <span className="clsRadioText">
                                    {LBLTHIRD}
                                  </span>
                                </div>
                              </div>

                              <span className="required">
                                {this.state.helperShift}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ display: this.state.selhome ? "" : "none" }}
                      >
                        <div className="row">
                          <div className="col-xl-9 col-sm-12" style={{ marginBottom: "10px" }}>
                            <label className="cls_subtitle label">
                              {LBLINCLOCATION}
                            </label>

                            <br></br>
                            <TextField
                              id="incLocation"
                              className="col-xl-8"
                              style={{ maxWidth: "600px" }}
                              multiline
                              rows={3}
                              placeholder={LBLINCLOCATIONINPUT}
                              //helperText={this.state.helperincLocation}
                              variant="outlined"
                              cols={200}
                              value={this.state.inclocation}
                              onChange={this.handleincLocationChange}
                              error={this.state.errorLoc}
                              required
                              disabled={this.state.incclosed}
                            />
                          </div>
                        </div>
                        <span className="required">
                          {this.state.helperincLocation}
                        </span>
                      </div>
                    </div>
                    <div style={{ display: "" }} className="cls_nm_sub_header">
                      {this.props.labels.labels.LBLRISKHIGH}
                    </div>
                    <div style={{ display: "" }} className="cls_grouping">
                      <div className="row">
                        <div className="col-xl-12 col-sm-12">
                          <label className="cls_subtitle label">{LBLSEVERITY}</label>
                          <MenuList id="menuList">
                            {Data.severity &&
                              Data.severity.map((item, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    id={index}
                                    onClick={(event) =>
                                      this.handleSelected(event, index)
                                    }
                                    selected={
                                      index === this.state.selectedIndex
                                    }
                                    classes={{
                                      selected:
                                        this.state.selectedclass +
                                        this.state.selectedIndex,
                                    }}
                                    disabled={this.state.incclosed}
                                  >
                                    {item.incident_severity_name}
                                  </MenuItem>
                                );
                              })}
                          </MenuList>
                          <span className="required">
                            {this.state.helperSeverity}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="cls_nm_sub_header">
                      {this.props.labels.labels.LBLCAPTURENEARMISS}
                    </div>
                    <div
                      className="cls_grouping"
                      style={{ padding: "7px 10px 12px 50px" }}
                    >
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <label className="cls_subtitle label">{LBLATTACHPIC}</label>
                          <span className="cls_warn_msg">
                            {this.props.labels.labels.LBLSUPPORTFORMAT}
                          </span>
                          <div className="previewComponent">
                            <form onSubmit={(e) => this._handleSubmit(e)}>
                              <div id="image_container" style={{ display: "flex" }}>
                                <ul
                                  className="row"
                                  style={{ display: "flex", marginTop: "3px", marginLeft: "0", overflow: "hidden" }}
                                  id="preview"
                                ></ul>
                                <ul
                                  style={{ display: closed ? "none" : "flex", marginTop: "0px", marginLeft: "20px" }}
                                  id="noImage1"
                                >
                                  <li id="noImage" className="noImageempty">
                                    <label htmlFor="fileInput" className="btn btn-link chooseFile">
                                      <i
                                        style={{ display: "none" }}
                                        id="addplus"
                                        className="fas fa-plus near_add_plus"
                                      ></i>{" "}
                                      <span id="addchoose">{LBLCHOSEIMG}</span>
                                    </label>

                                    <input
                                      style={{ display: "none" }}
                                      className="fileInput"
                                      multiple
                                      id="fileInput"
                                      type="file"
                                      accept="image/x-png,image/gif,image/jpeg"
                                      ref={this.fileNameRef}
                                      onChange={(e) => this.handleImageChange(e)}
                                    />
                                  </li>
                                </ul>
                              </div>
                              <div
                                style={{ display: "flex", marginTop: "0px" }}
                                className="row imgPreview1"
                              >
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-9 col-sm-12">
                          <label className="cls_subtitle label">
                            {LBLINCSUMMARY}
                          </label>

                          <br></br>
                          <TextField
                            id="Summary"
                            className="col-xl-8"
                            style={{ maxWidth: "600px" }}
                            multiline
                            rows={3}
                            helperText={this.state.helperSummary}
                            variant="outlined"
                            cols={200}
                            value={this.state.summary}
                            onChange={this.handleSummaryChange}
                            error={this.state.errorSum}
                            required
                            disabled={this.state.incclosed}
                          />
                          {<span className="required">{this.state.helperSummaryDesc}</span>}
                        </div>
                      </div>
                    </div>
                    {selectedSource.incident_source_id === "incident_source_03" ? "" :
                      <>
                        <div className="cls_nm_sub_header">
                          {this.props.labels.labels.LBLSTATESERRORS}
                        </div>
                        <div
                          className="cls_grouping"
                          style={{ padding: "7px 10px 24px 50px" }}
                        >
                          <div className="row" style={{ marginLeft: "0px" }}>
                            <label className="cls_subtitle label"> {LBLSTATES}</label>
                            <div className="col-md-12 col-sm-12 nm-state-box ssi-nm-res">
                              {Data.incident_states &&
                                Data.incident_states.map((element, i) => {
                                  return (
                                    <div key={i} className="column" id="States">
                                      <div className="card box-state">
                                        <label className="check-lbl check-lbl-states">
                                          <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            name=" "
                                            checked={element.isNewAssigned}
                                            onClick={this.selectedCheckStates.bind(
                                              this,
                                              element,
                                              element.isNewAssigned ? 1 : 0,
                                              1
                                            )}
                                            disabled={this.state.incclosed}
                                          ></input>
                                          <span className="checkmark"></span>
                                          <img
                                            src={imgCardStates[i].image}
                                            alt=""
                                            className="cls-img-states"
                                          ></img>
                                        </label>
                                        <span className="rys-text">
                                          {element.incident_state_name}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <span className="required">
                              {this.state.helperStates}
                            </span>
                          </div>

                          <div className="row" style={{ marginLeft: "0px" }}>
                            <label className="cls_subtitle label"> {LBLERRORS}</label>
                            <div className="col-md-12 col-sm-12 nm-state-box ssi-nm-res">
                              {Data.incident_errors &&
                                Data.incident_errors.map((element, i) => {
                                  return (
                                    <div key={i} className="column" id="Errors">
                                      <div className="card box-state">
                                        <label className="check-lbl check-lbl-states">
                                          <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            name=" "
                                            checked={element.isNewAssigned}
                                            onClick={this.selectedCheckErrors.bind(
                                              this,
                                              element,
                                              element.isNewAssigned ? 1 : 0,
                                              2
                                            )}
                                            disabled={this.state.incclosed}
                                          ></input>
                                          <span className="checkmark"></span>
                                          <img
                                            src={imgCardErrors[i].image}
                                            alt=""
                                          ></img>
                                        </label>
                                        <span className="rys-text">
                                          {element.incident_error_name}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <span className="required">
                              {this.state.helperErrors}
                            </span>
                          </div>
                        </div>

                        <div className="cls_nm_sub_header">
                          {this.props.labels.labels.LBLCORRECTIVEACTIONS}
                        </div>
                        <div className="cls_grouping">
                          <div className="row" style={{ marginBottom: "-9px" }}>
                            <div className="col-xl-9 col-sm-12">
                              <label className="cls_subtitle label">{LBLCA}</label>
                              <ul className="near_chk_left" id="CActions">
                                {Data.corrective_actions &&
                                  Data.corrective_actions.map((element, i) => {
                                    return (
                                      <div>
                                        {i < 4 ? (
                                          <li
                                            id={i}
                                            key={i}
                                            onClick={this.handleCA}
                                          >
                                            <div className="custom-control-checkbox">
                                              <label className="checkbox-custom-label">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  name=" "
                                                  checked={element.isNewAssigned}
                                                  onClick={this.selectCheckItems.bind(
                                                    this,
                                                    element,
                                                    element.isNewAssigned ? 1 : 0,
                                                    3,
                                                    i
                                                  )}
                                                  disabled={this.state.incclosed}
                                                ></input>
                                                <span
                                                  className="checkboxmark"
                                                  id={i}
                                                ></span>
                                              </label>
                                            </div>
                                            <div
                                              onClick={this.selectCheckItems.bind(
                                                this,
                                                element,
                                                element.isNewAssigned ? 1 : 0,
                                                3,
                                                i
                                              )}
                                              style={{
                                                color:
                                                  element.isNewAssigned === 1
                                                    ? "#5192d6"
                                                    : "",
                                              }}
                                              id={"caction" + i}
                                            >
                                              {" "}
                                              {element.corrective_action_name.search("LBL") !== -1 ? this.props.labels.labels[element.corrective_action_name] : element.corrective_action_name}
                                            </div>{" "}
                                          </li>
                                        ) : (
                                          <li
                                            id={i}
                                            key={i}
                                            onClick={this.handleCA}
                                            style={{
                                              marginLeft: "30px",
                                              display: this.state.showhabits
                                                ? ""
                                                : "none",
                                            }}
                                          >
                                            <div className="custom-control-checkbox">
                                              <label className="checkbox-custom-label">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  name=" "
                                                  checked={element.isNewAssigned}
                                                  onClick={this.selectCheckItems.bind(
                                                    this,
                                                    element,
                                                    element.isNewAssigned ? 1 : 0,
                                                    3,
                                                    i
                                                  )}
                                                  disabled={this.state.incclosed}
                                                ></input>
                                                <span
                                                  className="checkboxmark"
                                                  id={i}
                                                ></span>
                                              </label>
                                            </div>
                                            <div
                                              onClick={this.selectCheckItems.bind(
                                                this,
                                                element,
                                                element.isNewAssigned ? 1 : 0,
                                                3,
                                                i
                                              )}
                                              style={{
                                                color:
                                                  element.isNewAssigned === 1
                                                    ? "#5192d6"
                                                    : "",
                                              }}
                                              id={"caction" + i}
                                              disabled={this.state.incclosed}
                                            >
                                              {" "}
                                              {element.corrective_action_name}
                                            </div>{" "}
                                          </li>
                                        )}
                                      </div>
                                    );
                                  })}
                                <li className="CAothers" id="CAothers">
                                  <TextField
                                    id="txtCA"
                                    className="col-xl-12"
                                    style={{
                                      maxWidth: "600px",
                                      fontSize: "12px",
                                      color: "black",
                                      marginLeft: "60px",
                                      display: this.state.selCAothers ? "none" : "",
                                    }}
                                    multiline
                                    rows={3}
                                    value={this.state.selother}
                                    variant="outlined"
                                    placeholder={LBLWRITEHERE}
                                    helperText={this.state.helperTextDes}
                                    onChange={this.handleother}
                                    error={this.state.errorDes}
                                    required
                                    disabled={this.state.incclosed}
                                  />
                                  {/* {<span className="required">{this.state.helperTextDes}</span>} */}
                                </li>
                              </ul>
                              <span className="required">
                                {this.state.helperCActions}
                              </span>
                              <p></p>
                            </div>
                          </div>
                        </div>
                        <br></br>
                      </>
                    }
                  </div>
                  <div
                    className="cls_go_top"
                    style={{ display: "none" }}
                    onClick={this.handlegoup}
                  >
                    Top
                  </div>
                  <br></br>
                  <div
                    // style={{ marginTop: "-30px" }}
                    className="col-md-12 text-right"
                  >
                    <button
                      type="button"
                      className="button-style secondary-btn btnspacin"
                      onClick={this.handleCancel}
                      style={{marginRight: "10px"}}
                    >
                      {LBLCANCEL}
                    </button>
                    <button
                      type="submit"
                      disabled={this.state.incclosed}
                      className="button-style primary-btn btnspacin"
                      onClick={this.updateIncidentDetails}
                    // onSubmit={this.updateIncidentDetails}
                    >
                      {LBLSUBMIT}
                    </button>
                  </div>
                </div>
              </section>
            </React.Fragment>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(NearMiss);
// export default NearMiss;

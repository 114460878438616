import React from 'react';
import Checkbox from './Checkbox';

const DistractorComp = (props) => {
    return (
        <React.Fragment>
            <ul className={props.type === "option_3" ? "options-table col-md-6 col-sm-12" : "options-table"} >
                {
                    props.options.map((opt, j) => {
                        return (
                            <li className="option-row" key={j}>
                                <div className="custom-control-checkbox">
                                    <Checkbox name={opt.name} checked={opt.userAnswer} disabled={props.showAnswer} label={props.labels[opt.name]} onChange={(e) => props.onChange(e, props.index, props.cIndex, j)}></Checkbox>
                                </div>
                                {props.isAnswer === true ?
                                    <div className={!props.showAnswer ? "chk-container" : "chk-container show-answer"} >
                                        <Checkbox name={opt.name} checked={opt.correctAnswer} disabled={props.showAnswer} label="" onChange={console.log("change")}></Checkbox>
                                    </div> : ""}
                            </li>
                        )
                    })
                }
            </ul>
        </React.Fragment>
    );
}

export default DistractorComp;
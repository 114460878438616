import React from "react";
import styles from "./CapsuleListUnit.module.scss";
import unitsicon from "../../../assets/images/unitsicon.png";
import avgtimeicon from "../../../assets/images/avgtimeicon.png";
import playImage from "../../../assets/images/playimage.png";
import utils from "../../../utils/utils";
const UnitBannerDescription = (props) => {
console.log(props.onClick,"props.onClick")
    return (
        <div className={styles.CapsuleListUnitCtn}>
            <div className={styles.imageContainer} onClick={props.onClick} style = {{width: (props.unitDesc !== null && props.unitDesc !== undefined) ? "60%" : "100%"}}>
                <div className = {styles.overlay}> </div>
                <img src={props.unitImage} className={styles.unitImage} />
                <img src={playImage} alt = "" className={styles.playImage} style = {{width: (props.unitDesc !== null && props.unitDesc !== undefined) ? "10%" : "6%"}}/>
                <div className={styles.unitNameDescWrapp}>
                <span className={styles.unitName} >
                    {props.unitName}
                </span>
                <span className={styles.unitDesc} title={props.description}>
                    {props.description}
                </span>
                </div>
            </div>
            {(props.unitDesc !== null && props.unitDesc !== undefined) ?
                <div className={styles.descCtn}>
                    <div className={styles.iconCtn}>
                        {localStorage.getItem('site_type') !== "Accutrain" ? 
                        <div>
                        <img src={unitsicon} alt="" />
                        <span className={styles.unitsLangText}>
                            <span style={{ marginRight: "5px" }}>{props.unitModuleCount}</span>
                            {props.labels.LBLMODULE !== undefined ? props.labels.LBLMODULE : "Module"}
                        </span>
                        </div> : null}
                        <div>
                        <img src={avgtimeicon} alt="" />
                        <span className={styles.unitsLangText}>
                            {utils.convertToHoursAndMinutes(props.unitAverageTime ? props.unitAverageTime : 0, props.labels)}
                        </span>
                        </div>
                    </div>
                    <div className={styles.descTitle}>
                        {props.labels.LBLDESCRIPTION !== undefined ? props.labels.LBLDESCRIPTION : "Description"}
                    </div>
                    <p className={styles.courseDesc}>
                        {props.unitDesc}
                    </p>
                </div>
                :
                null
            }
        </div>
    );
};

export default UnitBannerDescription;
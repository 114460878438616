import React, { useState,useEffect,useRef } from "react";
import styles from "./ManagementHeader.module.scss";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import $ from "jquery";
const TraingCompletionHeader = (props) => {
    const [recordStatus, setRecordStatus] = useState(false);
    const recordStatusRef = useRef(null);
    const { 
        LBLUSERSSELECTED, 
        LBLCLEAR, 
        LBLSELECTSTATUS, 
        LBLPOSTDATE, 
        LBLSUBMIT, 
        LBLCANCEL,
        LBLINPROGRESS,
        LBLCOMPLETED, 
        LBLSETCOURSECOMPLETION 
    } = props.labels;
    const handleRecordStatus = () => {
        console.log("atcvjhb")
        setRecordStatus(true);
    }
    const handleCancel = () => {
        setRecordStatus(false);
        props.recordHandleCancel()
    }
    const handleOptionChange = (e) => {
        console.log(e.target.value, "savghjbnj")
        props.handleStatusChange(e.target.value)
    }
    useEffect(() => {
        function handleClickOutside(event) {
            console.log($(event.target) && $(event.target).parent() && $(event.target).parent().get(0),event.target,"event")
            let elements = event.target; //document.getElementById("target");
            let els = [];
            let classAtr = "";
            while (elements) {
                els.unshift(elements);
                elements = elements.parentNode;
                classAtr =
                  elements && elements.getAttribute && elements.getAttribute("class")
                    ? elements.getAttribute("class")
                    : "";
            console.log(classAtr,elements,"classAtrconsole")
                if (classAtr.includes("MuiDayCalendar") || classAtr.includes("MuiPickersPopper-root")) {
                  break;
                }
              }
              console.log(classAtr,"classAtr")
            if (recordStatusRef.current && !recordStatusRef.current.contains(event.target)&& !classAtr && !classAtr.includes("MuiDayCalendar") && !classAtr && !classAtr.includes("MuiPickersPopper-root") ) {
                setRecordStatus(false);
            }
        }

        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    let Sitetype=localStorage.getItem('site_type');
    return (
        <>
            <div className={Sitetype==='SafeStart'?styles.container:styles.greencontainer}>
                <div className={Sitetype==='SafeStart'?styles.selectedcount:styles.greenselectedcount}>{props.selectedRowsCount}</div>
                <span className={styles.coursesselected}>{LBLUSERSSELECTED}</span>
                <span className={styles.clearbtn} onClick={props.handleClear}>{LBLCLEAR}</span>
                <button className={Sitetype==='SafeStart'?styles.selectallbtn:styles.greenselectallbtn} onClick={props.handleRowChange}>{props.selectAll}</button>
                <div className={styles.hrline}></div>
                <div style={{position:"relative"}}>
                    <button className={Sitetype==='SafeStart'?styles.recordStatusbtn:styles.greenrecordStatusbtn} onClick={handleRecordStatus} id="setcoursecompletion">{LBLSETCOURSECOMPLETION}</button>
                    {recordStatus && props.selectedRowsCount > 0 ?
                        <div className={styles.recordStatusContainer} ref={recordStatusRef}>
                            <div className={styles.recordSubTitles} style={{ marginTop: "0px" }}>{LBLSELECTSTATUS}</div>
                            <div style={{ display: "flex", marginTop: "10px" }}>
                                <div className={styles.recordRadioContainer} style={{ marginLeft: "0px" }}>
                                    <input
                                        type="radio"
                                        name="option"
                                        value="2"
                                        // checked={selectedOption === 'Option 1'}
                                        onChange={handleOptionChange}
                                    />
                                    <div style={{ marginLeft: "5px" }}>{LBLCOMPLETED}</div>
                                </div>
                                <div className={styles.recordRadioContainer}>
                                    <input
                                        type="radio"
                                        name="option"
                                        value="1"
                                        // checked={selectedOption === 'Option 2'}
                                        onChange={handleOptionChange}
                                    />
                                    <div style={{ marginLeft: "5px" }}>{LBLINPROGRESS}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                    <div>
                                        <div
                                            className="filter-wth-label"
                                        >
                                            <label className={styles.recordSubTitles}>{LBLPOSTDATE}</label>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    margin="normal"
                                                    format={props.dateformat}
                                                    id="Date"
                                                    defaultValue={props.selectedDate}
                                                    maxDate={new Date()}
                                                    onChange={props.handleDateChange}
                                                    slotProps={{ textField: { variant: 'outlined' } }}
                                                    sx={{
                                                        '& .MuiInputBase-input': {
                                                            fontSize: '13px !important',
                                                            fontWeight: 400
                                                        }
                                                    }}
                                                    KeyboardButtonProps={{
                                                        "aria-label": "change date",
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 text-right btn-ctn">
                                    <button
                                        type="button"
                                        // className="btnspacin"
                                        className="button-style secondary-btn"
                                        onClick={handleCancel}
                                        style={{height:"40px",minWidth:"90px",marginRight:"10px"}}
                                    >
                                        {LBLCANCEL}
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={props.disableSubmit}
                                        className="button-style primary-btn"
                                        onClick={props.openSaveModal}
                                        style={{height:"40px",minWidth:"90px"}}
                                    >
                                        {LBLSUBMIT}
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            {props.visibleRowsSelected ?
                <div className={styles.visiblerowsselected}>
                    {props.visiblerowsselectedLabel}
                </div>
                :
                null
            }
        </>
    );
}
export default TraingCompletionHeader;
import React, { Component } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import DataTable from '../../../common_components/datatable/datatable';
import CustomFilter from "../../components/reportfilter/containers/CustomFilter"
import Services from "../../services/services";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";

const moment = require("moment");
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};
let objFilterData = {}
class EmailFailure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultData: [],
      loading: true
    }

  }
  componentDidMount() {
    let data = {};
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
    let dt1 = new Date(xrArr[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
    let dt2 = new Date(xrArr[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
    data.filterDate = [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
    this.loadEmailFailures(data)
  }

  loadEmailFailures = (data) => {
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;
    data.timeZoneOffset = (new Date()).getTimezoneOffset()
    data = data !== undefined ? data : "";
    let filterDate = "";
    if (data && data.filtercolumns && data.filtercolumns !== undefined) {


    // if (data.filtercolumns !== undefined && data != "") {
      // console.log("=========",data)
      this.setsvaluefordate(data);
      filterDate = data.filtercolumns[data.filtercolumns.length - 1].filter.condition.svalue;
    }
    else {
      filterDate = data.filterDate;
    }
    
      Services.getEmailFailureList(filterDate).then((res) => {
        // if(res!== undefined){
        this.setState({
          resultData: res,
          loading: false
        },
          //REFS.F1.onExportDone()   
        )
        // }
      })
  
  }

  setsvaluefordate = (data) => {
    for (let j = 0; j < data.filtercolumns.length; j++) {
      if (data.filtercolumns[j].filter.type == "date" && data.filtercolumns[j].filter.condition) {
        let dt1, dt2;
        let xcondition = JSON.parse(JSON.stringify(data.filtercolumns[j].filter.condition))
        let ddata = {}
        //console.log("typeof xcondition-----", xcondition.value)
        //console.log("typeof xcondition.value-----",typeof xcondition.value)
        if (typeof xcondition.value == "string") {
          console.log("string---")
          let arrdt = xcondition.value.split("T")[0].split("-");
          let arrdt2 = xcondition.value.split("T")[1].split(":");
          //console.log("string---arrdt",arrdt)
          //console.log("string---arrdt2",arrdt2)

          xcondition.xvalue = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2],
            arrdt2[0],
            arrdt2[1],
            0, 0
          );
        } else {
          if (typeof xcondition.value == "object" && xcondition.value[0] && typeof xcondition.value[0] == "string") {
            xcondition.xvalue = []
            
          //console.log("object---xcondition.value[0]",xcondition.value[0])
          //console.log("object---arrdt2",xcondition.value[0])
            let arrdt = xcondition.value[0].split("T")[0].split("-");
            let arrdt2 = xcondition.value[0].split("T")[1].split(":");
  
            xcondition.xvalue[0] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
          if (typeof xcondition.value == "object" && xcondition.value[1] && typeof xcondition.value[1] == "string") {
            
          //console.log("object111---xcondition.value[0]",xcondition.value[0])
          //console.log("object111---arrdt2",xcondition.value[0])
            let arrdt = xcondition.value[1].split("T")[0].split("-");
            let arrdt2 = xcondition.value[1].split("T")[1].split(":");
            xcondition.xvalue[1] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
        }
        if (typeof xcondition.value == "object" && xcondition.value[0] && xcondition.value[1]) {
          dt1 = new Date(xcondition.xvalue[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        } else {
          dt1 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        }
        data.filtercolumns[j].filter.condition["svalue"] = [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
      }
    }
  }
  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }

  setFilterFields = (id, conditions) => {
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportcellproperty) {
        xobj[conditions[i].name].exportcellproperty =
          conditions[i].exportcellproperty;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableEF_D1Fields = xobj;
    }
  };
  onFilterChange = (id, conditions) => {

    //console.log("onFilterChangeeeeee2", conditions);
    this.setFilterFields("F1", conditions);
    let data = { type: "web", columns: objFilterData.availableEF_D1Fields };
    data.filtercolumns = conditions;
    //console.log("data---",data)
    this.loadEmailFailures(data)
    //console.log(this.state.resultData)
  };

  onExport = (type, id, conditions) => {
    if (id === "F1") {
      this.setFilterFields("F1", conditions);
      let data = { type: type, columns: objFilterData.availableEF_D1Fields };
      data.dateformat = this.props.dateformat;
      data.timeformat = this.props.timeformat;
      data.filtercolumns = conditions;
      if (type === "web") {
        this.loadEmailFailures(data);
      } else {
        this.getEF_D1_Export(data);
      }
    }
  };
  getEF_D1_Export(data) {
    data.report = "EF_D1";
    data = data !== undefined ? data : "";
    let filterDate = "";
    if (data.filtercolumns !== undefined && data != "") {
      this.setsvaluefordate(data);
      filterDate = data.filtercolumns[data.filtercolumns.length - 1].filter.condition.svalue;
    }
    Services.getEmailFailureListExport(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === " csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob)
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      let xmimeType = filemime[data.type];
      let xfileName = "EF_D1." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }
  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    let xcols = objFilterData.length > 0 ? objFilterData.availableEF_D1Fields : [];
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter != undefined) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
        // console.log("getFilterColumns-111",  xcols);
      }
    }

    if (mode === "EF_D1") {
      let xobj = objFilterData.availableEF_D1Fields;
      if (xobj) {
        for (var i = 0; i < xcols.length; i++) {
          xcols[i].filter = xobj[xcols[i].name];
        }
      }
    }
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  getColumns = () => {
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
    let columns = [
      {
        name: this.props.labels.labels.LBLEMAIL,
        selector: "email",
        sortable: true,
        compact: true,
        width: "30%",
        exportcell: "_email",
        exportcellproperty: { order: 100 },
        filter: {
          type: "string", showField: true, checked: false
        },
        cell: (row) => {
          return (
            <div data-toggle="tooltip" title={row.user_email}>
              {row.user_email}
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLEMAILTYPE,
        selector: "emailtype",
        sortable: true,
        compact: true,
        width: "20%",
        exportcell: "_emailtype",
        exportcellproperty: { order: 200 },
        filter: {
          type: "string", showField: true, checked: false
        },
        cell: (row) => {
          return (
            <div data-toggle="tooltip" title={row.email_type}>
              {row.email_type}
            </div>
          );
        },
      },
      // {
      //   name: this.props.labels.labels.LBLREASON,
      //   selector: "reason",
      //   sortable: true,
      //   compact: true,
      //   width: "30%",
      //   exportcell: "_reason",
      //   exportcellproperty: { order: 300 },
      //   filter: {
      //     type: "string", showField: true, checked: false
      //   },
      //   cell: (row) => {
      //     return (
      //       <div data-toggle="tooltip" title={row.status_info} style={{ height: "40px", overflow: "hidden" }}>
      //         {row.status_info}
      //       </div>
      //     );
      //   },
      // },
      {
        name: this.props.labels.labels.LBLSTATUS,
        selector: "status",
        sortable: true,
        compact: true,
        width: "25%",
        exportcell: "_status",
        exportcellproperty: { order: 400 },
        filter: {
          type: "string", showField: true, checked: false
        },
        cell: (row) => {
          return (
            <div data-toggle="tooltip" title={row.status}>
              {row.status}
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLSENTDATE,
        selector: "sentdate",
        sortable: true,
        compact: true,
        width: "25%",
        exportcell: "_sentdate",
        exportcellproperty: { order: 500 },
        filter: {
          type: "date", showField: true, checked: true,
          condition: {
            name: "Date",
            operator: "LAST7DAYS",
            value: xrArr.slice(),
            operatorText: this.props.labels.labels.LBLLAST7DAYS,
          },
        },
        cell: (row) => {
          return (
            <div data-toggle="tooltip" title={moment.unix(row.sg_date).format(this.props.dateformat + " " + this.props.timeformat)} >
              {moment.unix(row.sg_date).format(this.props.dateformat + " " + this.props.timeformat)}
            </div>
          );
        },
      }
    ];
    return columns;
  }
  render() {
    let columns="", filterFields="", resetfilterFields="";

    if (!this.state.loading) {      
      columns = this.getColumns();
      filterFields = this.getFilterColumns("EF_D1", columns);
      resetfilterFields = this.getResetFilterColumns("EF_D1", columns);
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < filterFields.length; j++) {
          if (
            filterFields[j] &&
            columns[i] &&
            filterFields[j].name === columns[i].name &&
            filterFields[j].filter.showField === false
          ) {
            columns.splice(i, 1);
            i--;
          }
        }
      }
    }
    return (
      <div>
        <div className="mob-pad">
          <div>
            <h3 className="page-title">
            {this.props.labels.labels.LBLEMAILFAILURE}
            </h3>
          </div>
          <hr className="head-line" />
          <div id="accordion" className="row rptlst-row">
            <div style={{ width: "100%", paddingLeft: "15px" }}>
              {this.state.loading ? (
                <div>
                  <div>
                    <div>
                      <div>
                        <div className="row box ml-1">
                          <Skeleton height={46} />
                        </div>
                        <div className="row col-sm-12 prowfilterbox"></div>
                      </div>
                      <div>
                        <h3>
                          <Skeleton height={21} />
                        </h3>
                        <h2>
                          <Skeleton height={26} />
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div className="print_hide">
                    <div id="custom_reports">
                      {/* {
                        console.log("this.state.resultData.length",this.state.resultData.length)
                      } */}
                      <CustomFilter
                      initialMode={this.state.resultData.length===0?"OPEN":"CLOSE"}
                        availableFields={filterFields}
                        selectedFields={filterFields}
                        orgAvailableFields={resetfilterFields}
                        onFilterChange={this.onFilterChange}
                        onExport={this.onExport}
                        id="F1"
                        onRef={(ref) => (REFS.F1 = ref)}
                        labels={this.props.labels.labels}
                        reportName={
                          this.props.labels.labels.LBLEMAILFAILURE
                        }
                        companyName={this.props.company_name}
                      />
                    </div>

                    <DataTable
                      columns={columns}
                      resultData={this.state.resultData}
                      noDataComponent={
                        <div style={{paddingTop: '5%'}}><EmptyState image={emptyImage} text2={this.props.labels.labels.LBLNORECORDS}/></div>
                      }
                    />
                  </div>
                </div>
              )
              }
            </div>
          </div>
        </div>
      </div>
    )

  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};
export default connect(mapStateToProps, null)(EmailFailure);
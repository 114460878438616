import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import UsersService from "./UsersService";
import Skeleton from "react-loading-skeleton";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import styles from "./userManagementForm.module.scss";
import info from "../../../assets/images/info.png";
import infoaccu from "../../../assets/images/infoaccu.png"


let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class ChannelPermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeds_channels: [],
      loading: true,
      assigned_channels: [],
      show: false,
      search_results: [],
      assign_search_results: [],
      search_text: null,
      assign_search_text: null,
      Sitetype:localStorage.getItem('site_type'),
    };
  }

  componentDidMount() {
    if (this.state.loading) {
      this.getAssignedChannels();
    }
  }
  getAssignedChannels = () => {
    let data = {
      user_id: this.props.user_id,
    };
    UsersService.getChannels(data).then((result) => {
      this.setState({
        feeds_channels: result.channels,
        loading: false,
        feeds_channels_assigned: result.assigned_channels,
        assigned_channels: result.assigned_ids ? result.assigned_ids : [],
      });
    });
  };
  openModal = () => {
    this.setState({
      show: true,
    });
  };
  closeModal = () => {
    this.setState({ show: false, search_result: [], search_text: null });
  };
  assignChannels = (e) => {
    let channel_id = e.target.value;
    if (this.state.assigned_channels.indexOf(channel_id) === -1) {
      this.setState({
        assigned_channels: [...this.state.assigned_channels, channel_id],
      });
    } else {
      let removed_array = this.state.assigned_channels;
      removed_array.splice(this.state.assigned_channels.indexOf(channel_id), 1);
      this.setState({
        assigned_channels: removed_array,
      });
    }
  };
  selectAllChannels = (e) => {

    if (e.target.checked) {
      let channel_ids = this.state.feeds_channels.map((item) => {
        if (item._id) {
          return item._id;
        }
        return null;
      });
      this.setState({
        assigned_channels: channel_ids,
      });
    } else {
      this.setState({
        assigned_channels: [],
      });
    }
  };
  searchOperation = (value, from) => {
    let source = null;
    if (from) {
      source = this.state.feeds_channels;
    } else {
      source = this.state.feeds_channels_assigned;
    }
    let search_result = source.filter((item) => {
      if (
        item.channel_name
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
      ) {
        return item;
      }
      return null;
    });

    if (from) {
      this.setState({
        search_text: value,
        search_results: search_result,
      });
    } else {
      this.setState({
        assign_search_text: value,
        assign_search_results: search_result,
      });
    }

  };
  updateAssignedChannels = () => {
    let data = {
      channels: this.state.assigned_channels.length > 0 ? this.state.assigned_channels : null,
      user_id: this.props.user_id,
    };
    this.setState({
      loading: true,
      show: false,
    });
    UsersService.manageAssignedChannels(data).then((response) => {
      this.getAssignedChannels();
    });
  };
  groupTableHeader = (filterGroup) => {
    return (
      <div
        className={`row asgn-done ${styles.assignmentHeader}`}>
        <div className="table-filter" style={{ marginLeft: "-14px" }}>
          <div
            data-toggle="tooltip"
            title="Assign Groups"
            className="sm-icn"
            style={{
              cursor: "pointer",
              paddingRight: "0px",
              maxHeight: "40px",
              margin: "1px 0px",
            }} >
            <button
              className="button-style primary-btn"
              data-toggle="modal"
              onClick={this.openModal} >
              {this.props.labels.labels.LBLASSIGN}
            </button>
          </div>
        </div>
        <div
          className="row table-filter cls-right"
          style={{ display: "inline-block" }}
        >
          <input
            type="text"
            className="input-field"
            placeholder={this.props.labels.labels.LBLSEARCH}
            value={this.state.assign_search_text}
            onChange={(e) => {
              this.searchOperation(e.target.value);
            }}
            onKeyDown={(e) => {
              this.searchOperation(e.target.value);
            }}
          />
        </div>
        {filterGroup?.length > 0 ?
        <div className={this.state.Sitetype==='SafeStart'?styles.usertraingDescrip: styles.usertraingaccuDescrip}>
           <img className={styles.trainingimg} src= {this.state.Sitetype==='SafeStart'?info :infoaccu} alt = ""/>
         <div className={styles.usertraingDescriptext}>{this.props.labels.labels.LBLCHANNELSAVECOURSE}</div>
          </div>
        :""} 
      </div>
    );
  };

  render() {
    const {
      LBLOF,
      LBLROWSPERPAGE,
      LBLNORECORDS,
      LBLALL,
      LBLUNASSIGN,
      LBLASSIGNED,
      LBLWILDCARDMSG,
      LBLCHANNELNAME,
      LBLCHANNELS,
      LBLSAVE
    } = this.props.labels.labels;
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    

    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    let channel_columns = [
      {
        name: LBLCHANNELNAME,
        selector: "channel_name",
        sortable: true,
        width: "85%",
        compact: true,
        cell: (row) => (
          <div>
            <label className="show-sm">{LBLCHANNELNAME}</label>
            {row.channel_name}
          </div>
        ),
      },
      {
        name: LBLASSIGNED,
        selector: "order",
        width: "15%",
        headerAlign: "center",
        className: "cssCenter",
        style: { textAlign: "center" },
        compact: true,
        cell: (row) => (
          <div className="w100">
            <label className="show-sm"> </label>
            <div className="icoBtn">
              <label
                class={
                  row.isAssigned
                    ? row.isNewAssigned
                      ? "check-lbl"
                      : "check-lbl check-newunassigned1"
                    : "check-lbl check-newassigned1"
                }
              >
                <input
                  type="checkbox"
                  name={"chkg_" + row.group_id}
                  id={"chkg_" + row.group_id}
                  data-toggle="modal"
                  checked={
                    this.state.assigned_channels.indexOf(row._id) !== -1
                      ? true
                      : false
                  }
                  onChange={this.assignChannels}
                  value={row._id}
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        ),
      },
    ];
    const { search_results, feeds_channels, assign_search_text, assign_search_results } = this.state;

    let channel_data =
      search_results.length > 0 && this.state.search_text
        ? search_results
        : !this.state.search_text
          ? feeds_channels
          : search_results;
    let assigned_channels = (this.state.assign_search_text) ? assign_search_results : this.state.feeds_channels_assigned;
    console.log("assigned_channels",assigned_channels);
    let groupTableHeader = this.groupTableHeader(assigned_channels);
    if (this.state.loading) {
      
      return (
        <div className="usr-mang-datatable">
          <div className="sc-fznxsB fWHyCt datatable-list web-skull">
            <header className="sc-fzoXzr hlidmt">
              <Skeleton height={41} />
            </header>
            <div className="sc-fznWqX gnahTY">
              <div className="sc-AxjAm gIMaKV rdt_Table">
                <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                  <Skeleton height={56} />
                </div>
                <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                  <Skeleton height={480} />
                </div>
              </div>
            </div>
            <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
              <Skeleton height={56} />
            </nav>
          </div>
          <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
            <header className="sc-fzoXzr hlidmt">
              <Skeleton height={34} />
            </header>
            <div className="sc-fznWqX gnahTY">
              <div className="sc-AxjAm gIMaKV rdt_Table">
                <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                  {skel.map((i) => {
                    return (
                      <div
                        id={"row-" + i}
                        className="sc-fzoLsD doBktq rdt_TableRow"
                      >
                        <Skeleton height={90} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
              <div className="sc-fzpmMD ftIMtk">
                <Skeleton height={40} />
              </div>
            </nav>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Modal
            show={this.state.show}
            onHide={this.closeModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title">
                {this.props.labels.labels.LBLASSIGN + " "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ marginTop: "5px", padding: "0px" }}>
              <div >
                <input
                  className="input-field"
                  style={{
                    width: "auto",
                    margin: "0px",
                    float: "right",
                    marginBottom: "5px",
                  }}
                  placeholder={this.props.labels.labels.LBLSEARCH}
                  value={this.state.search_text}
                  onChange={(e) => {
                    this.searchOperation(e.target.value, 1);
                  }}
                  onKeyDown={(e) => {
                    this.searchOperation(e.target.value, 1);
                  }}
                  ref="myInput"
                />

                {channel_data !== undefined && channel_data.length > 0 ? (
                  <div className={styles.filterWthLabel}>
                    {" "}
                    <div className={styles.pageSubTitle}>
                      {this.props.labels.labels.LBLCHANNELS}
                    </div>
                    <ul
                      className={styles.listGroup}
                      style={{ width: "100%", overflowX: "auto" }}
                    >
                      <li
                        className={styles.listGroupItem}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <label
                          className="check-lbl"
                          style={{ marginBottom: "0px", marginLeft: "0px" }}
                        >
                          <input
                            type="checkbox"
                            data-toggle="modal"
                            id="chkSelAll"
                            value={this.state.selAll}
                            onClick={this.selectAllChannels}
                            onChange={this.selectAllChannels}
                          />
                          <span
                            className="checkmark"
                            id="spnSelAll"
                            style={{
                              "font-size": "20px",
                              border: "1px solid #8888b9",
                            }}
                          >
                            --{" "}
                          </span>
                          <span
                            style={{
                              display: "block",
                              fontWeight: "500",
                              paddingTop: "6px",
                              textAlign: "left",
                            }}
                          >
                            {this.props.labels.labels.LBLSELECTALL}{" "}
                          </span>
                        </label>
                      </li>
                      <hr
                        style={{
                          border: "1px solid #f5eaea",
                          marginBottom: "0px",
                          width: "98%",
                        }}
                      ></hr>
                      {channel_data.map((item, i) => (
                        <li
                          key={i}
                          className={styles.listGroupItem}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <label
                            className="check-lbl"
                            style={{ marginBottom: "0px", marginLeft: "0px" }}
                          >
                            <input
                              type="checkbox"
                              data-toggle="modal"
                              checked={
                                this.state.assigned_channels &&
                                  this.state.assigned_channels.indexOf(item._id) !==
                                  -1
                                  ? true
                                  : false
                              }
                              onClick={this.assignChannels}
                              value={item._id}
                            />
                            <span className="checkmark"></span>
                            <span style={{
                              display: "block",
                              paddingTop: "6px",
                              textAlign: "left",
                            }} >
                              {item.channel_name}{" "}
                            </span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <ul className={styles.listGroup}
                      style={{ width: "100%", overflowX: "auto" }} >
                      <li className={styles.listGroupItem} style={{ whiteSpace: "nowrap" }} >
                        <label className="check-lbl"
                          style={{ marginBottom: "0px", marginLeft: "0px" }} >
                          <span style={{
                            display: "block",
                            paddingTop: "6px",
                            marginTop: "30px",
                            textAlign: "left"
                          }} >
                            <EmptyState image={emptyImage} text2={this.props.labels.labels.LBLNORECORDS} />
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer >
              <div>
                <button
                  style={{
                    display: this.state.assigned_channels
                      ? this.state.assigned_channels.length > 0
                        ? ""
                        : "none"
                      : "none",
                  }}
                  className="button-style primary-btn"
                  id="assign"
                  onClick={this.updateAssignedChannels} >
                  {this.props.labels.labels.LBLSAVE}
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <DataTable
            id="assignedlist"
            columns={channel_columns}
            data={assigned_channels}
            className="datatable-list"
            pagination
            subHeader
            subHeaderComponent={groupTableHeader}
            noDataComponent={
              assign_search_text ? <EmptyState image={emptyImage} text2={LBLNORECORDS} /> : this.state.wildshow ? <EmptyState image={emptyImage} text2={LBLWILDCARDMSG} />
                : <EmptyState image={emptyImage} text2={LBLWILDCARDMSG} />}
            paginationComponentOptions={paginationComponentOptions}
          />
          <div className="row section-body">
            <div
              id="selectproductError"
              className="col-md-12 required-field text-center d-none"
              style={{ fontSize: "14px" }}
            >
              {this.props.labels.errors.LBLSELANYGROUP}
            </div>
            <div className="col-md-12 text-right">
              <button
                type="button"
                id="btnsubmit"
                class="btnspacin"
                style={{
                  display: this.state.feeds_channels_assigned
                    ? this.state.feeds_channels_assigned.length > 0
                      ? ""
                      : "none"
                    : "none",
                }}
                className="button-style primary-btn"
                onClick={this.updateAssignedChannels}
              >
                {LBLSAVE}
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ChannelPermissions;

import React from "react";
import { connect } from "react-redux";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import "date-fns";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import "./steercomconfiguration.scss";
import FormLabel from '@material-ui/core/FormLabel';
import DataTable from "react-data-table-component";
import { TextField, Select, Tooltip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import orderBy from "lodash/orderBy";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { TRUE } from 'node-sass';
const $ = require("jquery");
let roleName = "";
let lblroledeleteconfirm = "";
let lbltaskresetconfirm = "";
class IMRoles extends React.Component {
  constructor(props) {
    super(props);
    const { callcomponents, editTeamDetails } = props;

    this.state = {
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      show_language: false,
      opendialog2: false,
      templates: "",
      userlist: [],
      message1: "",
      addNewRole: false,
      openalert: false,
      steercomSettings: {},
      teamData: [],
      hasRights: false,
      showAddRole: true,
      editingMembers: [],
      roleLanguage: { "template_id": "default", "template_name": "Default" },
      openalert: false,
      loading: false,
      showEmailConfig: false,
      adminassign: false, adminunassign: false, taskconfig: false, taskassign: false,
      taskdue2day: false, taskduetoday: false, taskoverdue: false, taskstatuschange: false,
      usertaskstatuschange: false, usertaskassign: false, usertaskunassign: false,
      usertaskoverdue: false, usertaskduetoday: false, usertaskdue2day: false,
      active: 1,
      editingMembers: {
        M_NEW: {
          steercom_role_id: "NEW",
          memberStatus: 1
        }
      }
    };
  }
  componentWillUnmount() {
    $("#root").css("height", "100%");
    $("#root").css("overflow", "auto");
  }
  componentDidMount() {
    $('#root').scrollTop(0);
    this.getSteercomSettings();
  }
  componentDidUpdate() {
    $(".rdt_TableBody").css({ marginTop: "36px" });
    $("#addnewmember").html(this.props.labels.labels.LBLADDNEWROLE);
    $(".datatable-list").css({ marginTop: "0px" });
    $("#role_container").css({ "margin-top": "0x" });
    let { addNewRole, showAddRole } = this.state;
    if (addNewRole) {
      $("#role_container").css({ "margin-top": "-28px" });
      $(".rdt_TableBody").css({ marginTop: "3px" });
    }
    if (!showAddRole) {
      $(".rdt_TableBody").css({ marginTop: "3px" });
    }
  }
  setContent = () => {
    setTimeout(() => {
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("aside").addClass("slideOutLeft");
      $("#sidenav").css({ display: "none" });
    }, 1500);
  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    let roleLanguage = this.state.roleLanguage;
    let templates = [], lang_code = roleLanguage.template_id; //this.props.language_code; 
    steercomSettings.templates.map(item => {
      item.template_name = this.getLabel(item.template_name);
      templates.push(item.template_id);
    })
    this.getRolesByLanguage(lang_code, steercomSettings);
    this.setState({
      steercomSettings: steercomSettings,
      templates: templates,
      addNewRole: false,
      edit_mode: false
    });
  };
  
  getRolesByLanguage = (lang_code, steercomSettings1) => {
    let { steercomSettings } = this.state;
    if (steercomSettings1) {
      steercomSettings = steercomSettings1;
      lang_code = lang_code;
    }
    let roles = steercomSettings.roles;
    roles = roles.filter(item => item.template_id == lang_code && item.del_flag != "1")
    this.sortResults(roles, "steercom_role_name", true);
    roles = roles.sort((a, b) => (a.is_default > b.is_default) ? -1 : 1)
    roles.unshift({
      steercom_role_id: "NEW",
    })
    this.setState({
      roles: roles,
    });
  }
  sortResults = (arr, prop, asc) => {
    arr.sort(function (a, b) {
      if (a[prop] != null) {
        if (asc) {
          return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
        } else {
          return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
        }
      }
    });
  };
  openConfirmDialog = () => {

  }
  onfocus1 = (item, event) => {
    let editingMembers = this.state.editingMembers;
    event.target.selectionStart = event.target.selectionEnd =
      editingMembers["M_" + item.steercom_role_id]["focus_position"] ||
      event.target.value.length;
  };
  updateEditFlag = (item, status, mode) => {
    let { addNewRole, edit_mode } = this.state;
    if (addNewRole || (edit_mode && status == 0)) {
      this.showAlert("warning", this.props.labels.labels["LBLSAVEWARN"]);
      return false;
    }
    let roles = this.state.roles;
    let editingMembers = this.state.editingMembers;
    for (var i = 0; i < roles.length; i++) {
      //alert(status + "--" + item.team_steercom_role_id + "--" + roles[i].team_steercom_role_id + "--" + roles[i].isNewAssigned || 0)
      if (item.steercom_role_id == roles[i].steercom_role_id) {
        roles[i].isNewAssigned = status ? 0 : 1;
        //alert(roles[i].isNewAssigned)
        if ((roles[i].isNewAssigned || 0) == 1) {
          editingMembers["M_" + roles[i].steercom_role_id] = {
            steercom_role_id: roles[i].steercom_role_id,
            steercom_role_name: roles[i].steercom_role_name
          };
          roleName = roles[i].steercom_role_name;
        } else {
          delete editingMembers["M_" + roles[i].steercom_role_id];
        }
      } else if (status == 0 && (roles[i].isNewAssigned || 0) == 1) {
        if (mode == 1) {
          roles[i].isNewAssigned = 0;
          delete editingMembers["M_" + roles[i].steercom_role_id];
        } else {
          roles[i].isNewAssigned = 0;
          delete editingMembers["M_" + roles[i].steercom_role_id];
        }
      }
    }
    let editMode = status ? 0 : 1;
    this.setState((state, props) => ({
      roles: roles,
      editingMembers: editingMembers,
      edit_mode: editMode,
      show_language: true
    }));
  };
  showNotes = (row) => {
    let editingMembers = this.state.editingMembers;
    if (
      editingMembers["M_" + row.steercom_role_id].hasOwnProperty("notes_flag") ==
      true
    ) {
      editingMembers["M_" + row.steercom_role_id].notes_flag =
        !editingMembers["M_" + row.steercom_role_id].notes_flag;
    } else {
      editingMembers["M_" + row.steercom_role_id].notes_flag = true;
    }
    if (editingMembers["M_" + row.steercom_role_id].notes_flag == true) {
      editingMembers["M_" + row.steercom_role_id]["auto_focus"] =
        "steercom_role_name";
    } else {
      editingMembers["M_" + row.steercom_role_id]["auto_focus"] = "";
    }

    this.setState({
      editingMembers: editingMembers,
    });
  };
  handleRoleName = (item, event) => {
    let locval = event.target.value;
    let editingMembers = this.state.editingMembers;
    editingMembers["M_" + item.steercom_role_id]["steercom_role_name"] = locval;
    editingMembers["M_" + item.steercom_role_id]["auto_focus"] = "steercom_role_name";
    editingMembers["M_" + item.steercom_role_id]["focus_position"] = event.target.selectionStart;
    this.setState(
      {
        editingMembers,
      },
      event.target.focus()
    );
  }
  handleRoleNameFocus = (row, event) => {
    let editingMembers = this.state.editingMembers;
    if (editingMembers["M_" + row.steercom_role_id]["auto_focus"] != "steercom_role_name") {
      editingMembers["M_" + row.steercom_role_id]["auto_focus"] = "steercom_role_name";
      this.setState({
        editingMembers: editingMembers,
      });
    }
  }
  onfocus2 = (item, event) => {
    let editingMembers = this.state.editingMembers;
    console.log(editingMembers["M_" + item.steercom_role_id]["focus_position"] + " - " + event.target.value.length)
    event.target.selectionStart = event.target.selectionEnd =
      editingMembers["M_" + item.steercom_role_id]["focus_position"] ||
      event.target.value.length;
  };
  hideAddRole = (row, item) => {
    let { SuccessFactorListData, roles } = this.state;
    let len_items = roles.length;
    len_items = len_items > 0 ? len_items - 1 : len_items;
    let parentId = "", curr_parent = row.parent_task_display_id;
    roles.map((items, idx) => {
      if (items.steercom_role_id == "NEW") {
      }
      roles[idx].isNewAssigned = 0;
    });
    this.setState({
      roles: roles,
      editModeFlag: 0,
      addNewRole: false,
      edit_mode: false,
    })
  }
  openConfirmDialog1 = () => {
    let edit_mode = this.state.edit_mode;
    if (edit_mode) {
      this.showAlert("warning", this.props.labels.labels["LBLSAVEWARN"]);
      return false;
    }
    this.addNewRoles();
  }
  addNewRoles = () => {
    this.setState({
      addNewRole: true,
      isNewRecord: false,
      showAddMember: false,
      opendialog1: false,
      show_language: false
    });
  }
  deleteRole = async (item, event) => { 
    let res = await SteercomServices.deleteRole(item);
    if (res.result.status == "LBLDELETESUCCESS") {
      this.showAlert("success", this.props.labels.labels["LBLDELETESUCCESS"]);
      this.getSteercomSettings();
    } else if (res.result.status == "LBLROLEASSIGNED") {
      let message = this.props.labels.labels["LBLNODELETE"].replace("<<>>", item.steercom_role_name);
      this.showAlert("warning", message, '', 4000);
    }
  };
  openConfirmDialog3 = (item, tstatus) => {
    if(item.is_default){
      this.showAlert("warning", this.props.labels.labels["LBLDELETEDEFAULT"]);
      return false;
    }
    lblroledeleteconfirm = this.props.labels.labels.LBLDELCONFIRMRL + (item.steercom_role_name) + "?";
    if (tstatus == 2) {
      lblroledeleteconfirm = this.props.labels.labels.LBLDELRECONFIRMRL;
    }
    this.setState({
      opendialog3: true,
      deletetitem: item,
      tstatus: tstatus,
      reconfirm: true
    })
  }
  handleCloseDialog3 = (val) => {
    let { updateItem, tstatus, deletetitem, reconfirm } = this.state;
    let opendialog = false;
    if (val.currentTarget.id == 2) {
      lblroledeleteconfirm = this.props.labels.labels.LBLDELRECONFIRMRL;
      if (tstatus == 2) {
        lblroledeleteconfirm = this.props.labels.labels.LBLDELRECONFIRMRL;
      }
      if (!reconfirm) {
        if (tstatus == 2) {
          this.deleteRole(deletetitem);
        } else {
          deletetitem.custom_task = false;
          this.deleteRole(deletetitem);
        }
      }
    } else {
      reconfirm = false
    }
    if (reconfirm) {
      opendialog = true
    }
    this.setState({
      opendialog3: opendialog,
      tstatus: tstatus,
      reconfirm: false
    });
  }
  resetSingleRole = (item) => {
    this.openConfirmDialog2(item)
  }

  openConfirmDialog2 = (item, tstatus) => {
    let opendialog3 = false, teamSuccessFactorList = this.state.teamSuccessFactorList;
    lbltaskresetconfirm = this.props.labels.labels.LBLRESETCONFIRMRL + (item.steercom_role_name) + "?";
    if (tstatus == 2) {
      lbltaskresetconfirm = this.props.labels.labels.LBLRESETALLCONFIRMRL;
    }
    this.setState({
      opendialog2: true,
      resetitem: item,
      tstatus: tstatus,
      reconfirm: true
    })
  }
  handleCloseDialog2 = (val) => {
    let { updateItem, tstatus, resetitem, reconfirm } = this.state;
    let opendialog = false;
    if (val.currentTarget.id == 2) {
      lbltaskresetconfirm = this.props.labels.labels.LBLRESETRECONFIRMRL;
      if (tstatus == 2) {
        lbltaskresetconfirm = this.props.labels.labels.LBLRESETALLRECONFIRMRL;
      }
      if (!reconfirm) {
        if (tstatus == 2) {
          this.updateResetRole(resetitem);
        } else {
          resetitem.custom_task = false;
          this.updateResetRole(resetitem);
        }
      }
    } else {
      reconfirm = false
    }
    if (reconfirm) {
      opendialog = true
    }
    this.setState({
      opendialog2: opendialog,
      tstatus: tstatus,
      reconfirm: false
    });
  }
  updateResetRole = async (item) => {
    let res = await SteercomServices.updateResetRole(item);
    if (res.result.status == "SUCCESS") {
      this.showAlert("success", this.props.labels.labels["LBLUPDROLESSUCCESS"]);
      this.getSteercomSettings();
    }
  }
  handleClose = () => {
    this.setState({ openalert: false });
  };
  showAlert = (messageType, message, message1, showtime) => {
    this.setState({
      openalert: true,
      messageType: messageType,
      message: message,
      message1: message1,
      showtime: showtime
    });
  };
  updateRolesDetails = async (item) => {
    let { editingMembers, roleLanguage, templates } = this.state;
    let roleName, lbl_message1 = "", role_details = {}, callType = item.steercom_role_id == "NEW" ? "add" : "update";
    roleName = $.trim(editingMembers["M_" + item.steercom_role_id].steercom_role_name);
    let llabel_success = "LBLUPDROLESSUCCESS";
    if (this.checkValidation(roleName)) {
      role_details.steercom_role_name = roleName;
      role_details.steercom_role_id = item.steercom_role_id;
      role_details.template_id = roleLanguage.template_id;
      if (callType == "add") {
        role_details.languages = "en-US";
        role_details.templates = templates.join(",");
        llabel_success = "LBLADDROLESSUCCESS";
        lbl_message1 = this.props.labels.labels["LBLADDROLESSUCCESS1"];
      }
      let res = await SteercomServices.updateRolesDetails(role_details, callType);
      if (res.result.status == "SUCCESS") {
        this.showAlert("success", this.props.labels.labels[llabel_success], lbl_message1, 3000);
        this.getSteercomSettings();
        if (item.steercom_role_id == "NEW") {
          editingMembers["M_" + item.steercom_role_id].steercom_role_name = '';
        }
      } else if (res.result.status == "LBLROLENAMEEXISTS") {
        this.showAlert("warning", this.props.labels.labels["LBLROLENAMEEXISTS"]);
      }
    }
  }
  checkValidation = (roleName) => {
    let retval = true;
    if ($.trim(roleName) == "") {
      this.showAlert("warning", this.props.labels.labels["LBLUPDROLESWARN"]);
      retval = false;
    } else if ($.trim(roleName).length > 256) {
      this.showAlert("warning", this.props.labels.labels["LBLUPDROLESLENWARN"]);
      retval = false;
    }
    return retval;
  }
  handleLanguageChange = (event, values) => {
    let language = values;
    let { addNewRole, edit_mode, roleLanguage } = this.state;
    if (addNewRole || edit_mode) {
      this.showAlert("warning", this.props.labels.labels["LBLSAVEWARN"]);
      language = roleLanguage;
      //return false;
    } else {
      this.getRolesByLanguage(language.template_id);
    }
    this.setState({
      roleLanguage: language,
    });
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  showLanguage = (event) => {
    let addrole = true, addlanguage = true, sid = event.target.id;
    let addNewRole = this.state.addNewRole;
    if (addNewRole) {
      this.showAlert("warning", this.props.labels.labels["LBLSAVEWARN"]);
      return false;
    }
    addrole = sid == 2 ? true : false;
    addlanguage = sid == 1 ? true : false;
    this.setState({
      show_language: addlanguage,
      //showAddRole:addrole
    });
  }
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (
        row[field] &&
        row[field] !== 1 &&
        row[field] !== 0 &&
        typeof row[field] !== "boolean"
      ) {
        return row[field].toString().toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  render() {
    let horizontal = "top", vertical = "center";
    const {
      LBLSCOMCONFIG, LBLNO, LBLAGREE, LBLUNSAVEDATA, LBLIMROLES, LBLLANGUAGE, LBLTRANSLANGUAGE,
      LBLREQUIRED, LBLACTION, LBLADDNEWROLE, LBLCANCEL, LBLRESET, LBLTRANSLATION, LBLCUSTOMROLES,
    } = this.props.labels.labels;
    
    let {
      editingMembers, roles, show_language
    } = this.state;
    show_language = true;
    const filterRoles = roles && roles.filter((item) => {
      if (this.state.addNewRole == true) {
        return item;
      } else {
        if ((item.steercom_role_id) != "NEW") {
          return item;
        }
      }
    });

    let roleColumns = [
      {
        name: LBLIMROLES,
        selector: "steercom_role_name",
        sortable: true,
        width: "88%",
        subHeader: true,
        subHeaderAlign: "left",
        subHeaderComponent: ["Add New "],
        compact: true,

        cell: (row, index) => (
          <div
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "4px",
                height: "34px",
                backgroundColor: "#80E3BA",
                border: "0px solid",
                display: "block",
                marginRight: "10px"
              }}
            ></div>

            <>
              <div
                id="role_container"
                style={{
                  border: "0px solid",
                  display: "flex",
                }}
              >
                {/* {row.team_steercom_role_id || row.steercom_role_id} */}
                {row.steercom_role_id != "NEW" && show_language ? (
                  <div
                    style={{ display: "inline-block", paddingLeft: "0px" }}
                  >
                    <label
                      class={
                        row.isNewAssigned
                          ? "check-lbl"
                          : "check-lbl check-newassigned"
                      }
                    >
                      <input
                        type="checkbox"
                        name={"chkg_" + row.id}
                        id={"chkg_" + row.id}
                        data-toggle="modal"
                        checked={row.isNewAssigned}
                        onClick={this.updateEditFlag.bind(
                          this,
                          row,
                          row.isNewAssigned ? 1 : 0
                        )}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                ) : ""}

                {row.isNewAssigned === 1 || row.steercom_role_id == "NEW" ?
                  (
                    <>
                      <input
                        id={row.steercom_role_id}
                        onClick={this.showNotes.bind(this, row)}
                        onKeyPress={this.showNotes.bind(this, row)}
                        className="cls_txt_name"
                        value={
                          editingMembers["M_" + row.steercom_role_id].steercom_role_name
                        }
                        autoComplete={'off'}
                        autoFocus={
                          editingMembers["M_" + row.steercom_role_id]["auto_focus"] == "steercom_role_name"
                            ? true
                            : false
                        }
                      />
                      {row.steercom_role_id && editingMembers["M_" + row.steercom_role_id].notes_flag == true ? (
                        <>
                          <div className="minipop" id={"notes_" + row.steercom_role_id}>
                            <input type="text"
                              className="cls_txt_name"
                              onChange={this.handleRoleName.bind(this, row)}
                              value={
                                editingMembers["M_" + row.steercom_role_id].steercom_role_name
                              }
                              autoFocus={
                                editingMembers["M_" + row.steercom_role_id][
                                  "auto_focus"
                                ] == "steercom_role_name"
                                  ? true
                                  : false
                              }
                              onFocus={(e) => this.onfocus1(row, e)}
                            ></input>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                  : <div style={{ marginTop: "4px", color: row.is_default ? "#716B6B" : "#18ad0a" }}> {row.steercom_role_name}</div>}
              </div>

            </>

          </div>
        ),
      },
      {
        name: LBLACTION,
        selector: "status",
        //     sortable: true,
        width: "10%",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid", display: "inline-flex" }}
          >
            {((row.isNewAssigned && row.isNewAssigned == true) || row.steercom_role_id == "NEW") ? (
              <>
                {" "}

                <>
                  <label className="show-sm">{LBLACTION}</label>
                  <div
                    className="sub_container33"
                    style={{ display: "inline-block" }}
                  >
                    <img 
                      src="../images/steercom/Checkbox.png" 
                      onClick={this.updateRolesDetails.bind(this, row)}
                      title="Save"
                      className="img_team_cross"
                      alt=""
                    ></img>
                  </div>
                  <div
                    className="sub_container3 cls_team_cross"
                    style={{ display: "inline-block" }}
                  >
                    <i
                      class="fas fa-undo cls_team_action"
                      onClick={this.hideAddRole.bind(this, row)}
                      title={LBLCANCEL}
                    ></i>
                  </div>

                </>

              </>
            ) : (
              <>
                {row.steercom_role_id != "ADD" ?
                  <>
                    <div
                      className="sub_container3 cls_team_cross"
                      style={{ display: "inline-block", textAlign: "center", width: "40%" }}
                    >
                      <i
                        class="far fa-trash-alt cls_team_action"
                        style={{opacity:row.is_default ? 0.3  : 1, cursor:row.is_default ? "default"  : "pointer"}}
                        onClick={row.is_default?"":this.openConfirmDialog3.bind(this, row)} //</div>  this.deleteTask.bind(this, row)
                        title="Delete"
                      ></i>
                      {/* <img src="../images/steercom/wrong.png" onClick={this.deleteSuccessFactor.bind(this, row)} title="Cancel Team Member" className="img_team_cross" alt="steer"></img> */}
                    </div>
                    <div
                      className=" cls_team_cross"
                      style={{ display: "inline-block" }}
                    >
                      <i
                        class="fas fa-reply cls_team_action"
                        onClick={this.resetSingleRole.bind(this, row)}
                        title={LBLRESET}
                      ></i>
                    </div></>
                  : ""} </>
            )}
          </div>
        ),
      },
    ];
   
    return (
      <div className="mob-pad steercom-configuration">
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage>
        <Snackbar
          style={{ width: "43%", margin: "0% 30%", justifyItems: "center" }}
          anchorOrigin={{ horizontal, vertical }}
          key={"top-center"}
          open={this.state.openalert}
          autoHideDuration={
            this.state.showtime ? this.state.showtime : 2000
          }
          onClose={this.handleClose}
          bodyStyle={{ backgroundColor: "teal", color: "coral" }}
        >
          <Alert
            variant="filled"
            onClose={this.handleClose}
            severity={this.state.messageType}
          >
            <> {this.state.message}</>
            <div style={{display:this.state.message1?"":"none"}}><p></p><p>{this.state.message1}</p> </div>
          </Alert>
        </Snackbar>

        <div id="dialog3">
          <Dialog className="cls_dialog"
            open={this.state.opendialog3}
            onClose={this.handleCloseDialog3}
            aria-labelledby="responsive-dialog-title"
            contentStyle={{ width: "100%", minWidth: "500px" }}
          >
            <div style={{ width: "500px", backgroundColor: "#FCEDEA" }}>
              <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ fontSize: "16px" }} className="cls_reset_all">{LBLSCOMCONFIG} </div>
                  <i style={{ margin: "0px 13px", color: "#ff4040", float: "right" }} class="fas fa-exclamation-triangle cls_reset_img"></i>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ color: "white" }}>
                  {/* <div style={{ color: "#f33434" }}> {LBLCNFRESETALLDATE} </div> */}
                  <div className="cls_confirm_details" style={{ color: "#313236" }}> {lblroledeleteconfirm} </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button autoFocus id="1" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog3} color="primary">
                  {LBLNO}
                </Button>
                <Button id="2" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog3} color="primary">
                  {LBLAGREE}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
          <Dialog className="cls_dialog"
            open={this.state.opendialog2}
            onClose={this.handleCloseDialog2}
            aria-labelledby="responsive-dialog-title"
            contentStyle={{ width: "100%", minWidth: "500px" }}
          >
            <div style={{ width: "500px", backgroundColor: "#FCEDEA" }}>
              <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ fontSize: "16px" }} className="cls_reset_all">{LBLIMROLES} </div>
                  <i style={{ margin: "0px 13px", color: "#ff4040", float: "right" }} class="fas fa-exclamation-triangle cls_reset_img"></i>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ color: "white" }}>
                  {/* <div style={{ color: "#f33434" }}> {LBLCNFRESETALLDATE} </div> */}
                  <div className="cls_confirm_details" style={{ color: "#313236" }}> {lbltaskresetconfirm} </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button autoFocus id="1" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog2} color="primary">
                  {LBLNO}
                </Button>
                <Button id="2" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog2} color="primary">
                  {LBLAGREE}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
        <div className="row cls_trans_top_container"> 
          <div className="col-xl-5" style={{zIndex:1,position:"relative", top:"20px", left:"8px"}}>
          <div className="col-xl-2"></div>
          <div className="cls_lgnd_rl_container">
          <div className="cls_lgnd_rl">
          </div> {LBLCUSTOMROLES}
        </div>
          </div>
          {!show_language ? (
            <div className="col-xl-3 cls_trans_container">
              <label><span id="1" onClick={this.showLanguage}
                style={{ color: "#007BFF", cursor: "pointer" }}>{LBLTRANSLATION}</span></label>
            </div>)
            : ""}

          {this.state.steercomSettings.templates && show_language ?
            <div className="col-xl-3 cls_language_container">
              <FormLabel className="cls_label" component="legend">{LBLTRANSLATION}</FormLabel>
              <div>
                <Autocomplete
                  id="combo_language"
                  options={this.state.steercomSettings && this.state.steercomSettings.templates}
                  getOptionLabel={(option) => option.template_name.toString()}
                  getOptionSelected={(option, values) =>
                    option.template_id === values.template_id
                  }
                  onChange={this.handleLanguageChange}
                  disableClearable
                  defaultValue={this.state.roleLanguage}
                  renderOption={(option) => (
                    <div style={{ fontSize: "14px" }}>{(option.template_name)}</div>
                  )}
                  value={this.state.roleLanguag}
                  renderInput={(params) => (
                    <TextField
                      value="surs"
                      text="sur"
                      {...params}
                      style={{ fontSize: "12px" }}
                    // label={LBLLANGUAGE}
                    />
                  )}
                />
              </div>
            </div>
            : ""}
        </div>
        {filterRoles && filterRoles.length>0?
        <>  
        <div className="cls_role_container">
          <DataTable
            columns={roleColumns}
            data={filterRoles}
            className="datatable-list"
            subHeader="true"
            subHeaderAlign="left"
            sortFunction={this.customSort} 
            subHeaderComponent={<Tooltip className="cls_add_new_role"
              title={LBLADDNEWROLE} arrow>
              <span
                style={{ display: this.state.showAddRole == false || this.state.addNewRole ? "none" : "" }}
                id="addnewmember"
                onClick={this.openConfirmDialog1}
                class="cls_add_new_member"> {LBLADDNEWROLE} </span>
            </Tooltip>}
          />
        </div>
       
        </>:""}
      </div>
    )
  }
}
//export default SuccessFactorPlan;
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    steercom_role_id: state.user_info_reducer.user_info.steercom_role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(IMRoles);

import React, { Component } from "react";
//import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
//import { faPlay, faPause, faUndo, faVolumeMute, faVolumeUp, faCompress, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from "react-player";
//import { findDOMNode } from 'react-dom';
//import screenfull from 'screenfull'
import "./videoplayer.scss";
//import { Spinner } from "react-bootstrap";
import { Waypoint } from "react-waypoint";

class VideoPlayer extends Component {
  state = {
    url: null,
    pip: false,
    playing: true,
    controls: false,
    progressbar: true,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    ended: false,
    fullScreen: false,
    errmsg: false,
    vLoad: false,
    clicked: true,
  };

  async componentDidMount() {
    // //console.log("component did mount", this.props);
    if (this.props.url) {
      
    }
    // }
    let checck = ReactPlayer.canPlay(this.props.url);
  }
  componentWillUnmount() {
    // //console.log("component will unmount");
    this.setState({ url: null, playing: false });
  }
  componentWillReceiveProps() {
    // //console.log("component will receive props" + this.props.propPlayPause);
    let playStatus = this.state.ended ? false : this.props.propPlayPause;
    this.setState({
      // playing: playStatus,
      progressbar: this.props.propProgressBar,
      propFullScreen: this.props.propFullScreen,
    });
  }
  load = (url) => {
    //console.log("load called");
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };
  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.player.seekTo(0);
    this.setState({ played: 0, playing: false });
    //this.setState({ url: null, playing: false })
  };

  handleReplay = () => {
    this.player.seekTo(0);
    this.setState({ played: 0, playing: true });
    //this.setState({ url: null, playing: false })
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    //console.log("onPlay", this.state.playing, this.state.clicked);
    if (!this.state.playing || !this.state.clicked) {
      this.setState({ playing: true, clicked: true });
    }
  };

  handleEnablePIP = () => {
    //console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    //console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    //console.log("onPause");
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    //console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    //console.log("onEnded");
    this.setState({ playing: this.state.loop, ended: true });
  };

  handleDuration = (duration) => {
    //console.log('onDuration', duration);
    let playerWrapper = document.getElementById("player-wrapper");
    playerWrapper.className = playerWrapper.className.replace("hidden", "");
    this.setState({ duration, playing: this.state.playing });
  };

  handleClickFullscreen = () => {
    //console.log("full screen");
    /*var obj = document.getElementById("player-wrapper");
    screenfull.toggle(obj);*/
    this.setState({ fullScreen: !this.state.fullScreen });
  };
  handleStart = () => {
    //console.log("on start");
    this.controlBar.className = this.controlBar.className.replace("hidden", "");
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  handleError = (e) => {
    //console.log("onError", e);
    if (e.type && e.type === "error") {
      this.setState({ errmsg: true });
      let playerWrapper = document.getElementById("player-wrapper");
      playerWrapper.className = playerWrapper.className.replace("hidden", "");
    }
  };

  ref = (player) => {
    this.player = player;
  };
  controlRef = (controlBar) => {

    //console.log('Exit1')
    this.controlBar = controlBar;
  };
  handleExitViewport = () => {
    //console.log('Exit', this.state.playing, this.state.clicked)

    this.setState({
      playing: false,
      clicked: false,
    });
  };
  handleEnterViewport = () => {
    //console.log('enter', this.state.playing, this.state.clicked)

    this.setState({
      playing: false,
    });
  };

  render() {
    // console.log("hello world",typeof this.props.currNoOfAssets +'==='+ (this.props.keyvalue+1))
  console.log(this.props.playingstatus +'==='+this.props.currNoOfAssets+'==='+ (this.props.keyvalue+1))
  console.log(this.props.playingstatus === (this.props.currNoOfAssets=== (this.props.keyvalue+1)) )
    const {
      //url,
      playing,
      //controls,
      // progressbar,
      // light,
      // volume,
      // muted,
      // loop,
      // played,
      // loaded,
      // duration,
      // playbackRate,
      // pip,
      fullScreen,
      errmsg,
      clicked,
    } = this.state;
    //const { propProgressBar, propFullScreen, labels } = this.props;
    const { labels } = this.props;
   
    if (this.props.url) {
      return (
        <Waypoint
          onEnter={this.handleEnterViewport}
          onLeave={this.handleExitViewport}
        >
          <div
            className={
              fullScreen ? "player-wrapper full-screen" : "player-wrapper"
            }
            id="player-wrapper"
          >
            {/* <h2>VIDEO 2 {this.props.url}</h2> */}
            {errmsg === true ? (
              <h1 className="error-message">
                {labels.course_engine_videoNotFound}
              </h1>
            ) : (
                ""
              )}
           
 {this.props.fileName ? (
              <ReactPlayer
                className="react-player"
                controls
                // playing={playing || clicked || this.props.mplay}
                // onPlay={this.handlePlay}
                // onClick={this.handlePlay}
                // light={
                //   this.props.url.replace("/playlist.m3u8", "") +
                //   "/thumb/thumbnail_00001.png"
                // }

                light={this.props.showAssetsViewer===1?false: this.props.url.replace("/playlist.m3u8", "") +  "/thumb/thumbnail_00001.png"}
                // url={this.testUrl(stream).then(res=>{return res})}
                url={this.props.url}
                onError={() => {
                  //console.log("err in video url");
                }}
                playing={this.props.playingstatus }
                config={{
                  file: {
                    hlsOptions: {
                      xhrSetup: function (xhr, url) {
                        //console.log("xhr");
                        xhr.setRequestHeader(
                          "Access-Control-Allow-Origin",
                          process.env.REACT_APP_Domain_URL
                        );
                        xhr.withCredentials = true; // send cookies
                      },
                    },
                  },
                }}
                width="100%"
                height="100%"
                key={this.state.fetching}
              />
            ) :null
            
              
              }

          </div>
        </Waypoint>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

export default VideoPlayer;

import React from "react";
import "./products.scss";
import Coursepopup from "../coursepopup/Coursepopup";
import { connect } from "react-redux";
import CCCertificate from "../../../common_components/certificate/certificate";
import products_services from "../../../services/products_services";
import Skeleton from "react-loading-skeleton";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import UnitBannerDescription from "./UnitBannerDescription";
import coursetimingicon from "../../../assets/images/coursetimingicon.png";
import tickMarkImg from "../../../assets/images/tickmark.png"
import TransitionPopUp from "./TransitionPopUp";
import Survey from "../../../survey/Survey";
import survey_services from "../../../survey/survey_services";
import SurveyAlert from "../../../survey/SurveyAlert";

class CapsuleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showCert: false,
      productData: null,
      showPopup: false,
      resultData: null,
      actLength: null,
      listView: false,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      feedbackData:{},
      showFeedbackPopUp:false,
      showFeedbackTransition:true,
      labels:{},
      postSurveyData:{},
      showPostSurvey:false,
      showPostSurveyAlert:false
    };
    this.togglePopup = this.togglePopup.bind(this);
    this.showCourseDetails = this.showCourseDetails.bind(this);
  }

  componentDidMount = () => {
    this.loadUnitBasedCapsuleList();
  };

  loadUnitBasedCapsuleList = () => {
    if (this.props.location.state === null) {
      this.props.history.push("/home/products");
    } else {
      console.log("dateformat", this.props.dateformat)
      var vParams = {
        userid: this.props.userid, //localStorage.getItem("sc_userid"),
        languagecode: this.props.language_code,
        productid: this.props.location.state.productID,
        unitid: this.props.location.state.unitID,
        dateformat: this.props.dateformat,
      };
      products_services
        .getproductunitscapsules(vParams)
        .then((res) => {
          return res;
        })
        .then((data) => {
          this.setState({
            resultData: data.data,
            actLength: data.data[0].activities.length,
          });
          this.fetchFeedbackTransltion(data.data)
          console.log(this.props.survey,"capsulesurveyid")
          if(this.props.survey === "") {
            this.checkPostSurvey();
          }
        });
      this.setState({ loading: false });
    }
    
  };

  fetchFeedbackTransltion =  async (units) => {
    try {
      const url = `${process.env.REACT_APP_YOUFACTORS_TRANSLATION_URL}products/products.json`;
      const response = await fetch(url, { cache: "force-cache" });

      if (response.status === 200) {
        const data = await response.json();
        console.log(data[`${units[0].languagecode}`] ? data[`${units[0].languagecode}`].labels : data["en-US"].labels, "dataaa");
        this.setState({labels:(data[`${units[0].languagecode}`] ? data[`${units[0].languagecode}`].labels : data["en-US"].labels)});
      } else {
        console.log("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  checkfeedback = () => {
    var vParams = {
      userid: this.props.userid,
      productid: this.props.location.state.productID,
    };
    survey_services.getfeedback(vParams).then((res) => {
      this.setState({feedbackData:res?.data});
     console.log(res?.data,"feedbackresponse");
    })
    .catch((error) => {
      console.error("Error while fetching Feedback status:", error);
    });
  };

  checkPostSurvey = () => {
    var vParams = {
      userid: this.props.userid,
    };
    survey_services.getpostsurvey(vParams).then((res) => {
      this.setState({postSurveyData:res?.data, showPostSurveyAlert:res?.data.survey_id})
     console.log(res?.data,"postSurveyData");
     if(res?.data.survey_id === "") {
      this.checkfeedback();
     }
    })
    .catch((error) => {
      console.error("Error while fetching postSurveyData status:", error);
    });
  };

  checkActivity(xi) {
    let units = this.state.resultData;
    for (let i = 0; i <= xi; i++) {
      if (units[i].activity_enabled == 1) {
        let actvts = units[i].activities;
        for (let j = 0; j < actvts.length; j++) {
          if (
            actvts[j].activity_capsule_id > 0 &&
            actvts[j].trigger_condition.and[0].when === "pre" &&
            actvts[j].trigger_status != 3
          ) {
            return false;
          } else if (
            actvts[j].activity_capsule_id > 0 &&
            i < xi &&
            actvts[j].trigger_status !== 3
          ) {
            return false;
          } else if (units[i].CourseCompletionPercentage < 100) {
          }
        }
      }
    }
    return true;
  }

  showCourseDetails = (vProductunitKey, vEngineType, activityDetails) => {
    var vCourseID, vActivityID, vCapsuleID, vCourseVersion;
    if (vEngineType === "Capsule") {
      vActivityID = activityDetails.activity_id;
      vCapsuleID = activityDetails.capsule_id;
      vCourseID = activityDetails.capsule_content_id;
      vCourseVersion = activityDetails.capsule_content_version;
      vProductunitKey = 0;
    } else {
      vCourseVersion = this.state.resultData[vProductunitKey].course_version;
      vCourseID = this.state.resultData[vProductunitKey].courseid;
      vActivityID = this.state.resultData[vProductunitKey].activity_id;
    }

    var vUnitsInfoID = this.state.resultData[vProductunitKey].unitsinfoid;
    var vProductUnitID = this.state.resultData[vProductunitKey].unitid;
    var vProductID = this.state.resultData[vProductunitKey].productid;
    var vCourseCompletionStatus =
      this.state.resultData[vProductunitKey].CourseCompletionStatus;
    var vUnitname = this.state.resultData[vProductunitKey].units;
    var vUnitDesc = this.state.resultData[vProductunitKey].description;
    var vLanguageCode = this.state.resultData[vProductunitKey].languagecode;
    var vEngineVersion = this.state.resultData[vProductunitKey].engineversion;

    var vCourseDet = {
      courseid: vCourseID,
      unitcompletionstatus: vCourseCompletionStatus,
      unitname: vUnitname,
      unitdesc: vUnitDesc,
      unitsinfoid: vUnitsInfoID,
      languagecode: vLanguageCode,
      courseversion: vCourseVersion,
      engineType: vEngineType,
      activityid: vActivityID,
      capsuleid: vCapsuleID,
      trainingType: "CourseUnits",
      engineVersion: vEngineVersion,
    };
    this.setState({
      showPopup: !this.state.showPopup,
      productid: vProductID,
      unitid: vProductUnitID,
      unitedetails: vCourseDet,
      showModalDetails: false,
    });
  };

  togglePopup = (e) => {
    let vProductunitKey = 0;
    console.log(vProductunitKey,"vProductunitKey")
    if (typeof(e) === 'object') {
      vProductunitKey = e.currentTarget.dataset.div_key;
    } else if (typeof(e) === 'number') { vProductunitKey = e };
    if (
      vProductunitKey !== undefined &&
      this.state.resultData[vProductunitKey]?.unit_launch_type === "classroom"
    ) {
      return;
    }
    if (vProductunitKey !== undefined) {
      this.showCourseDetails(vProductunitKey, "Course");
    }  
    if (vProductunitKey === undefined) {
      console.log("capsuleclosed")
      this.loadUnitBasedCapsuleList();
      this.setState({
        showPopup: !this.state.showPopup,
        productid: undefined,
        unitid: undefined,
        unitedetails: undefined,
      });
    }
  };

  capsulemodefilter = (activties, mode) => {
    try {
      var yahooOnly = activties.filter(function (actData) {
        if (
          actData.trigger_condition &&
          actData.trigger_condition["and"] &&
          (actData.trigger_condition["and"][0]["when"] || "post") === mode
        ) {
          return true;
        } else {
          return false;
        }
      });
      return yahooOnly.length;
    } catch (e) {
      return 0;
    }
  };

  sortorder = (a, b) => {
    if (a.capsule_order < b.capsule_order) {
      return -1;
    }
    if (a.capsule_order > b.capsule_order) {
      return 1;
    }
    return 0;
  };

  writecapsule = (actData, mode, DatatableData, unit_index) => {
    if (
      (unit_index === 0 &&
        actData.trigger_status > 0 &&
        actData.trigger_status < 3) ||
      (unit_index !== 0 &&
        actData.trigger_status > 0 &&
        actData.trigger_status < 3)
    )
      actData.activityHide = false;
    else actData.activityHide = true;

    if (actData.capsule_content_type === "SURVEY") {
    }
    if (
      actData.trigger_condition &&
      actData.trigger_condition["and"] &&
      (actData.trigger_condition["and"][0]["when"] || "post") === mode
    ) {
      return (
        <>
        {!this.state.listView ? (
        <div
          class="card-cap"
          onClick={
            () =>
              actData.trigger_status > 0
                ? this.handleClickActivities(unit_index, actData)
                : this.handleClickActivities_Fail(unit_index, actData)
          }
          style={
            actData.trigger_status === 3
              ? { border: "1px solid #34CF27", borderRadius: "15px" }
              : null
          }
        >
          <div
            class="card-image"
            onClick={
              () =>
                actData.trigger_status > 0
                  ? this.handleClickActivities(unit_index, actData)
                  : this.handleClickActivities_Fail(unit_index, actData)
            }
          >
            <div className="capsuleiconctn" >
              <img src={coursetimingicon} alt="" style={{ marginRight: "5px" }} />
              <span className="avgtimetext"> 
                {actData.capsule_content_duration
                ? actData.capsule_content_duration
                : ""}
              {actData.capsule_content_duration ? (
                <small>&nbsp;{this.props.labels.labels.LBLMINUTES}</small>
              ) : (
                ""
              )}
              </span>
            </div>
            <div className="overlayCard"></div>
            <img
              className="cap-type"
              src={
                actData.capsule_content_type === "LEARNINGOBJECT"
                  ? actData.capsule_content_id === null ||
                    actData.capsule_content_id === undefined
                    ? "../images/learnobject.png"
                    : process.env.REACT_APP_RESOURCES_URL +
                      "image/" +
                      actData.capsule_content_id +
                      "/" +
                      actData.capsule_content_image
                  : actData.capsule_content_type === "RYS" || "SURVEY"
                  ? "../images/rys.png"
                  : actData.capsule_content_type === "STORYFEEDS"
                  ? "../images/Storyfeeds.png"
                  : "../images/products_list/activitycolor.png"
              }
              alt=""
            ></img>
            {actData.trigger_status === 3 ? (
              <img
                className="cap-icn"
                src={tickMarkImg}
                alt=""
              ></img>
            ) : (
              ""
            )}
          </div>
          <div class="card-text">
            <h2 title={actData.capsule_content_name}>
              {actData.capsule_content_name}
            </h2>
            <p title={actData.capsule_description}>
              {actData.capsule_description}
            </p>
          </div>
        </div>
        ) : (
          <div
            className="capsule-aside"
            onClick={
              () =>
                actData.trigger_status > 0
                  ? this.handleClickActivities(unit_index, actData)
                  : this.handleClickActivities_Fail(unit_index, actData)
            }
          >
            <div className="capsule-body">
              <div className="capsule-list">
                <div style={{position: "relative", display: "flex", alignItems: "center"}}>
                  <img
                    className="cl_image"
                    src={
                      actData.capsule_content_type === "LEARNINGOBJECT"
                        ? actData.capsule_content_id === null ||
                          actData.capsule_content_id === undefined
                          ? "../images/learnobject.png"
                          : process.env.REACT_APP_RESOURCES_URL +
                            "image/" +
                            actData.capsule_content_id +
                            "/" +
                            actData.capsule_content_image
                        : actData.capsule_content_type === "RYS" || "SURVEY"
                        ? "../images/rys.png"
                        : actData.capsule_content_type === "STORYFEEDS"
                        ? "../images/Storyfeeds.png"
                        : "../images/products_list/activitycolor.png"
                    }
                    alt=""
                  />
                  {actData.capsule_content_duration !== null || actData.capsule_content_duration === "" ? <div className="capsule-duration">{actData.capsule_content_duration !== null || actData.capsule_content_duration === "" ? actData.capsule_content_duration + " " + this.props.labels.labels.LBLMINUTES : null}</div> : null}
                </div>
                <div className="capsule_dtls">
                  <h2 className="cl-name" title={actData.capsule_content_name}>{actData.capsule_content_name}</h2>
                  <p className="cl-desc" title={actData.capsule_description}>{actData.capsule_description}</p>
                  <p className="cl-status" style={{color: actData.trigger_status === 3 ? "#21BF36" : ""}}>{actData.trigger_status === 3 ? this.props.labels.labels.LBLCOMPLETED : this.props.labels.labels.LBLNOTSTARTED}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        </>
      );
    } else {
      return <></>;
    }
  };

  handleClickActivities = (unit_index, res) => {
    if (res.capsule_content_type === "RYS") {
      let xurl = window.location.href;
      xurl =
        xurl.substr(0, xurl.lastIndexOf("/")) + "/rate-state#" + res.encref;
      window.location.href = xurl;
    } else if (res.capsule_content_type === "SURVEY") {
      let xurl = window.location.href;
      let launchmode = 0;
      if ((res.trigger_condition["and"][0]["when"] || "post") === "post") {
        launchmode = 1;
      }
      xurl =
        xurl.substr(0, xurl.lastIndexOf("/")) +
        "/safestart-survey#" +
        res.encref +
        "_" +
        launchmode;
      window.location.href = xurl;
    } else if (res.capsule_content_type === "LEARNINGOBJECT") {
      //this.showCourseDetails(unit_index, "Capsule", res);
      this.showCourseDetails(unit_index, "Capsule", res);
    } else {
    }
  };
  getLabel = (res) => {
    let faillable = "";
    try {
      if (res.triggerFail) {
        faillable = res.triggerFail.conditionData.lable;
        if (faillable.startsWith("LBLSTATUSUNLOCK")) {
          return this.props.labels.labels[faillable].replace(
            "$$$",
            res.triggerFail.conditionData.name
          );
        } else if (faillable.startsWith("LBLDATEUNLOCKFROM")) {
          return this.props.labels.labels[faillable].replace(
            "$$$",
            res.triggerFail.conditionData.unlockdate
          );
        }
      }
      return faillable;
    } catch (e) {
      return faillable;
    }
  };
  handleClickActivities_Fail = (unit_index, res) => {
    console.log(this.getLabel(res),res,"rescap")
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: this.getLabel(res),
        changeInToast: new Date(),
      },
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 5000);
    return;
  };
  showCertificate = (e, productData) => {
    e.stopPropagation();
    let userData = {
      userid: this.props.userid,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      dateformat: this.props.dateformat
    };
    this.setState({ userData, productData, showCert: true });
    document.getElementById("root").className = "no-scroll";
  };

  handleClose = () => {
    this.setState({ showCert: false });
    document.getElementById("root").className = "";
  };

  goBackHandler = () => {
    if (this.props.location.pathname === "/schome/capsule-list") {
      this.props.history.push({
        pathname: "/schome/product-lists",
        state: {
          productid: this.props.location.state.productID,
          companyid: this.props.companyid,
          userid: this.props.userid,
          productName: this.props.location.state.productName,
          bgThumbnail: this.props.location.state.bgThumbnail,
          selectedTab: 2
        },
      });
    } else {
      this.props.history.push({
        pathname: "/home/product-lists",
        state: {
          productid: this.props.location.state.productID,
          companyid: this.props.companyid,
          userid: this.props.userid,
          productName: this.props.location.state.productName,
          bgThumbnail: this.props.location.state.bgThumbnail,
          selectedTab: 2
        },
      });
    }
  };

  changeViewType = () => {
    this.setState({listView: !this.state.listView});
  }
  getImageSource = (res) => {
    const baseUrl = res.engineversion === "2" ? process.env.REACT_APP_CRSENGINE2_IMAGEURL : process.env.REACT_APP_RESOURCES_URL;
    const imageName = res?.prod_unit_imagename?.replace("jpg", "png");
    return `${baseUrl}image/${res.courseid}/${imageName}`;
  };

  handleUnitClick = (res, i) => {
    if (
      ((i !== 0 &&
        this.state.resultData[i - 1].CourseHide === false) ||
        i === 0) &&
      res.sequential_access_unit === 1 ||
      res.sequential_access_unit === 0
    ) {
      if (
        this.checkActivity(i) === true &&
        ((i !== 0 &&
          this.state.resultData[i - 1].CourseCompletionPercentage === 100) ||
          i === 0)
      ) {
        this.togglePopup(i);
      }
    }
  };
  
  handleTransitionClose = () => {
    this.setState({showFeedbackTransition:false});
  }

  handleFeedback = () => {
    this.setState({showFeedbackPopUp:true,showFeedbackTransition:false});
  }

  handleFeedBackClose = () => {
    this.setState({showFeedbackPopUp:false});
  }
  
  showFeedbackToast = (message) => {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: message,
        changeInToast: new Date(),
      },
    });

    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
      this.checkfeedback();
    }, 2000);
  }

  handleFeedbackCompletion = () => {
    this.setState({showFeedbackPopUp:false});
    this.showFeedbackToast(this.props?.labels?.labels?.LBLFEEDBACKCOMPLETED);
  };
  handleSurvey = () => {
    this.setState({showPostSurvey:true});
  };

  handlePostSurveyClose = () => {
    this.setState({showPostSurvey:false,showPostSurveyAlert:false});
  };
  
  handleSurveyCompletion = () => {
    this.showFeedbackToast(this.props?.labels?.labels?.LBLPOSTSURVEYCOMPLETED)
  };

  render() {
    console.log(this.props.location.state.unitDescription,this.state.labels,"unitDescription")
    const { labels } = this.props.labels;
    return (
      <div className="row productCtn">
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage>
        {this.state.showPopup}
        {this.state.showPopup ? (
          <Coursepopup
            text='Click "Close Button" to hide popup'
            closePopup={this.togglePopup.bind(this)}
            showPopup={this.state?.showPopup}
            Params1={this.state?.productid}
            Params2={this.state?.unitid}
            unitedetails={this.state?.unitedetails}
            resultDataString = { this.props?.location?.state?.resultDataString}
            dateformat = {this.props?.dateformat}
            courseName={this.state.resultData[0].products}
            isModule = {this.props?.location?.state?.isModule}
          ></Coursepopup>
        ) : null}
        <div className="col-xl-12">
          {this.state.loading || this.state.resultData === null ? (
            <Skeleton height={180} style={{ transform: "scale(1, 1)" }} />
          ) : (
            this.state.resultData.map((res, i) => {
              console.log(res,"resres")
              return (
                <div className={`mob-pad ${this.state.listView ? "capsule-card" : "capsule-list"}`}>
                  <div className="page-header-field">
                    <h3 className="page-title">
                      <i
                        className="fas fa-arrow-left cuBackBtn"
                        aria-hidden="true"
                        onClick={this.goBackHandler}
                      ></i>
                      {res.products}
                    </h3>
                  </div>
                  <hr className="head-line" />
                  <div
                    className="row img-card-pad"
                    style={{ marginLeft: "0px" }}
                  >
                    {!this.state.listView ? (
                    <div
                      className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      style={{
                        width: "100%",
                        maxWidth: "inherit",
                        marginBottom: "15px",
                        paddingLeft: "0px",
                      }}
                    >
                      <div
                        className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        style={{
                          width: "100%",
                          maxWidth: "inherit",
                          marginBottom: "15px",
                          paddingRight: "0px",
                          paddingLeft: "0px",
                        }}
                      >
                        {this.state.loading === false ? 
                          <UnitBannerDescription
                            unitImage={this.getImageSource(res)}
                            unitDesc={res.unitDescription}
                            unitAverageTime={res.unitEstimationTime}
                            labels={this.props.labels.labels}
                            unitName={res.units}
                            description={res.description}
                            onClick={() => this.handleUnitClick(res, i)}
                            unitModuleCount = {res.unit_module_count}
                          />
                              :
                              null
                        }
                        <div>
                        </div>
                      </div>
                      <h4 className="pageSubTitle">{this.props.labels.labels.LBLMYREFCAPSULES} : {res.activities.length}</h4>
                      <hr />
                      <div
                        className="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-xs-12 capsule-listing"
                        style={{
                          width: "100%",
                          maxWidth: "inherit",
                          marginBottom: "15px"
                        }}
                      >
                        {res.activities.map((actData, i) => {
                          return this.writecapsule(actData, "pre", res, i);
                        })}
                        {res.activities.map((actData, i) => {
                          return this.writecapsule(actData, "post", res, i);
                        })}
                      </div>
                    </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
        {this.state.showFeedbackTransition && this.state.feedbackData?.feedback_available === "1" && Object.keys(this.state.feedbackData?.feedback_details).length > 0 && this.state.feedbackData?.feedback_id === "" && Object.keys(this.state.labels).length > 0 ?
          <TransitionPopUp
            labels={this.state.labels}
            handleStartnow = {this.handleFeedback}
            handleTransitionClose = {this.handleTransitionClose}
          />
          :
          null
        } 
      {this.state.showFeedbackPopUp ?
        <Survey
          hide={this.handleFeedBackClose}
          surveyId={this.state.feedbackData?.feedback_details?.capsule_content_id}
          feedback_file_name = {this.state.feedbackData?.feedback_file_name}
          handleFeedbackCompletion={this.handleFeedbackCompletion}
          labels={this.state.labels}
          surveyType="feedback"
          launch_data = {this.state.feedbackData?.feedback_details?.encref}
          closeButton = {true}
          showFeedbackToast= {this.showFeedbackToast}
          languagecode = {this.state.resultData[0].languagecode}
          productID = {this.props?.location?.state?.productID}
        />
        :
        null
      }
      {this.state.showPostSurveyAlert ?
        <SurveyAlert
          handleSurvey = {this.handleSurvey}
          skip = {false}
          label={this.props.labels.labels}
          title = {this.props.labels.labels.LBLPOSTSURVEY}
          description= {this.props.labels.labels.LBLMOMPOSTSURVEY}
        />
        :
        null
      }

      {this.state.showPostSurvey ?
        <Survey
          hide={this.handlePostSurveyClose}
          surveyId={this.state.postSurveyData?.survey_id}
          handleSurveyCompletion = {this.handleSurveyCompletion}
          labels={this.props.labels.labels}
          surveyType="post"
          closeButton = {false}
        />
        :
        null
      }

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userid: state.user_info_reducer.user_info.user_id,
    first_name: state.user_info_reducer.user_info.first_name,
    last_name: state.user_info_reducer.user_info.last_name,
    companyid: state.user_info_reducer.user_info.company_id,
    // roleid:state.user_info_reducer.user_info.role_Id,
    language_code: state.user_info_reducer.user_info.language_code,
    company_logo: state.user_info_reducer.user_info.company_logo,
    dateformat: state.user_info_reducer.user_info.dateformat,
    survey:state.user_info_reducer.user_info.survey
  };
};

export default connect(mapStateToProps, null)(CapsuleList);

import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import CourseList from './report_types/CourseList'
import RoleAccess_report from './report_types/RoleAccess_report'
import Learner_Progress from './report_types/Learner_Progress'
import NearMissReport from '../../pages/nearmiss/NearMissReport'
import Learner_Course_Progress from './report_types/Learner_Course_Progress'
import Learner_Course_History from './report_types/Learner_Course_History'
import RateYourState from './report_types/RateYourState'
import RateYourStateCoversation from './report_types/RateYourStateCoversation'
import Learner_Course_Certificates from './report_types/Learner_Course_Certificates'
import LCS from './report_types/lcs'
function ReportRoutes(props) {
    let { url } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${url}/course-list`} component={() => <CourseList {...props} />} ></Route>
            <Route path={`${url}/role-access`} component={() => <RoleAccess_report {...props} />} ></Route>
            <Route path={`${url}/learner_progress`} component={() => <Learner_Progress  {...props} />} ></Route>
            <Route path={`${url}/nearmissreportssss`} component={() => <NearMissReport  {...props} />} ></Route>
            <Route path={`${url}/learner_course_progress`} component={() => <Learner_Course_Progress  {...props} />} ></Route>
            <Route path={`${url}/rate_your_state`} component={() => <RateYourState {...props} />} ></Route>
            <Route path={`${url}/rys_conversation`} component={() => <RateYourStateCoversation {...props} />} ></Route>
            <Route path={`${url}/learner_course_certificates`} component={() => <Learner_Course_Certificates  {...props} />} ></Route>
            <Route path={`${url}/lch`} component={() => <Learner_Course_History {...props} />} ></Route>
            <Route path={`${url}/lcs`} component={() => <LCS {...props} />} ></Route>
        
        </Switch>
    );
}

export default ReportRoutes;
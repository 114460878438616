import * as FileSaver from "file-saver";
import moment from "moment";
import * as XLSX from "xlsx";
const $ = require("jquery");

const convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;
    if(args.data.length > 0){
    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    let result_key = "";
    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        if (key.toLowerCase().indexOf("summary") > 0) {
          result_key = item[key].toString().replace(/\n/g, "");
        } else {
          if (typeof item[key] == "undefined") item[key] = "";
          result_key = item[key];
        }
        result += result_key;
        ctr++;
      });
      result += lineDelimiter;
    });
  }else{
    result = "";
  }
    return result;
  };

  const exportCSV = (listdata, fileName,dateFormat) => {
    var data, fileName, link;
    var stockData = [];
    var objHeader = {};

    let lheader,
      xfields = listdata;
    stockData = xfields;
    var csv = convertArrayOfObjectsToCSV({
      data: stockData,
    });
    if (csv == null) return;

    const dateStamp = moment(new Date()).format("DDMMYYYY");
    const timeStamp = new Date().getTime();
      fileName = fileName + '_' + dateStamp  + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }

    data = encodeURI(csv);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", fileName);
    link.click();
    $('#CSTFilter_spinner').hide();
  };
  const exportToExcel = (excelData, fileName,dateFormat) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const dateStamp = moment(new Date()).format("DDMMYYYY");
    const timeStamp = new Date().getTime();
    FileSaver.saveAs(data, fileName + '_' + dateStamp + fileExtension);
    $('#CSTFilter_spinner').hide();
  };

  export { exportCSV, exportToExcel }
import { forwardRef, useImperativeHandle, useState } from "react";
import "./style.scss";
import Accordian2 from "./Accordian2";

const Accordian1 = (props) => {
  const { item } = props;
  const [isActive, setIsActive] = useState(false);
  const expandClickHandler = () => {
    setIsActive(!isActive);
  };
  return (
    <div>
      <div className="acc-unit-ctn" key={item.uid} id={"uni_" + item.uid}>
        <div className="acc-unit-expand" onClick={expandClickHandler}>
          {item.capsules ? isActive ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i> : null}
        </div>
        <div className="acc-unit-name">{item.unit}</div>
        <div className="acc-unit-name">{`${props.labels.LBLTOTALCAPSULES}: ${item.t_cap}`}</div>
      </div>
      {isActive && item.capsules ? (
        <Accordian2 labels={props.labels} item={item.capsules} key={"capsuls_" + item.uid} onClicked={props.onClicked} />
      ) : null}
    </div>
  );
};
export default Accordian1;

import React, { Component } from "react";
import ReportServices from "../report_services";
import orderBy from "lodash/orderBy";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import RYSAveragesBar from "../../../components/charts/rys_average_states";
import RYSAveragesByState from "../../../components/charts/rys_average_states_bystate";
import UsersService from "../../user-list/UsersService";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
import emptyImage from '../../../../assets/images/rateyourstate_emptyst.png';
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import moment from "moment";

let objFilterData = {};
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};


class RYSReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_logo: this.props.company_logo,
      open: false,
      loading: true,
      reportloading: false,
      userData: [],
      user_roles: [],
      pageState: "RYS_D1",
      roles: [{ role_id: 1, role_name: "learner" }],
      loadRYS_D1_List: 0,
      orgRYS_D1_List: [],
      RYS_D1_List: [],
      RYS_D1_SummaryData: {
        records: 0,
        graphData: {
          rushing: [],
          fatigue: [],
          frustration: [],
          complacency: [],
        },
      },
      RYS_D1_GPH_SelStateId: "1",
      filterDataStatus: false,
      filterDataLoaded: 0,
      rysGraphType: "AverageRYS",
      rysGraphTitle: this.getLabel("LBLAVERAGERYS"),
      rysGraphViewTitle: "",//this.getLabel("LBLVIEWLBLAVERAGERYSBYSTATE"),
      rysGraphImage: "baricon.png"
    };
    this.RYS_D1_Filter = React.createRef();
  }
  rysStatusTranslation = {
    1: this.props.labels.labels.LBLRUSHING,
    3: this.props.labels.labels.LBLFATIGUE,
    2: this.props.labels.labels.LBLFRUSTRATION,
    4: this.props.labels.labels.LBLCOMPLACENCY,
  };
  loadpage(a, x) {
    if (a === "RYS_D1") {
      this.setState({loadRYS_D1_List: 1,});
    }

    if (this.state.loadRYS_D1_List === 1) {
      this.setState({
        loading: false,
      });
    }
  }
  componentDidMount() {
    objFilterData = {};
    let data = {};
    data.offsetTime = this.getOffsetTime();
    let getData = {
      language: this.props.language,
    };
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      //{group_type_id: "GP_1", lbl_GP_1: "Location", Active: "1"}
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
          filterDataLoaded: 1,
        },
        this.loadpage("group", iresponse)
      );
    });
    let ryscolumns = objFilterData.availableRYS_D1Fields
    if (!ryscolumns) {
      let columns = this.getRYS_D1_Columns();
      ryscolumns = this.getFilterColumns("RYS_D1", columns);
    }
    data.filtercolumns = ryscolumns

    this.getRYS_D1_List(data);
  }

  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getRYS_D1_Export(data) {
    data.report = "RYS_D1";
    data.offsetTime = this.getOffsetTime();
    this.setsvaluefordate(data)
    ReportServices.getRYS_D1_Export(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob)
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName = this.props.labels.labels.LBLRYSEXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }

  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  getRYS_D1_Summary = (xresult, ydata) => {
    let data = {
      labels: [],
      datasets: [{ graphData: xresult, backgroundColor: [], hoverBackgroundColor: [], ynames: [] }],
    };


    var getObjDate = (xstrday) => {
      let arrdt = xstrday.split(" ")[0].split("-");
      let arrdt2 = xstrday.split(" ")[1].split(":");
      return new Date(
        arrdt[0],
        parseInt(arrdt[1]) - 1,
        arrdt[2],
        arrdt2[0],
        arrdt2[1],
        0, 0
      );
    }
    let xstartDay = ""
    let xendDay = ""
    let ryscolumns = objFilterData.availableRYS_D1Fields
    //console.log("objFilterData.availableRYS_D1Fields", objFilterData.availableRYS_D1Fields)
    let xcondition
    if (!ryscolumns) {
      console.log("REASSIGN ryscolumns")
      let columns = this.getRYS_D1_Columns();
      ryscolumns = this.getFilterColumns("RYS_D1", columns);
      let xdata = { filtercolumns: ryscolumns }
      this.setsvaluefordate(xdata)
      for (let i = 0; i < ryscolumns.length; i++) {
        if (ryscolumns[i].selector === "created_on") {
          xcondition = ryscolumns[i].filter.condition
        }
      }
    } else {
      for (var key in ryscolumns) {
        if (ryscolumns[key].selector === "created_on") {
          xcondition = ryscolumns[key].condition
        }
      }
    }
    if (xcondition.svalue) {
      xstartDay = xcondition.svalue[0]
      if (xcondition.svalue[1]) {
        xendDay = xcondition.svalue[1]
      } else {
        xendDay = xstartDay.split(" ")[0] + " 23:59:59"
      }
    }
    //alert(xstartDay + "--" + xendDay)
    let objStartDate = getObjDate(xstartDay)
    let objEndDate = getObjDate(xendDay)

    //alert(objStartDate + "--" + objEndDate)

    data.date1 = objStartDate
    data.date2 = objEndDate
    return data;

  };
  showRYSGraph = (x, dp) => {
    if (x === "AverageRYS-State") {
      this.setState({
        rysGraphType: "AverageRYS-State",
        rysGraphTitle: this.getLabel("LBLAVERAGERYSBYSTATE") + " - " + this.getLabel("LBL" + dp.states.toUpperCase()),
        rysGraphViewTitle: this.getLabel("LBLVIEWLBLAVERAGERYS"),
        rysLevel2Data: dp,
        rysGraphImage: "baricon.png"
      })
    } else {
      this.setState({
        rysGraphType: "AverageRYS",
        rysGraphTitle: this.getLabel("LBLAVERAGERYS"),
        rysGraphViewTitle: "",//this.getLabel("LBLVIEWLBLAVERAGERYSBYSTATE") + "-" + this.getLabel("LBLRUSHING"),
        rysLevel2Data: {},
        rysGraphImage: "baricon.png"
      })
    }

  }
  onRYSDetailReportLevel2 = (xdataPoint) => {
    //alert(JSON.stringify(xdataPoint))
  }
  onZooomChangeEvent = (x) => {
    this.setState({
      zoomChanged: x
    })
  }
  getRYS_D1_List(data) {
    data.offsetTime = this.getOffsetTime();
    data.report = "RYS_D1";
    this.setsvaluefordate(data)
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;
    ReportServices.getRYS_D1_List(data).then((response) => {
      let result = response.result[7];
      this.setState(
        {
          pageState: "RYS_D1",
          RYS_D1_List: result,
          orgRYS_D1_List: result,
          RYS_D1_SummaryData: this.getRYS_D1_Summary(result, data),
          filterDataStatus: true,
          RYSDataLoaded: true,
          zoomChanged: 0
        },
        //setTimeout(() => {
        this.loadpage("RYS_D1")
        //}, 2000)
      );
    });
  }
  showRYS_D1 = () => {
    //console.log("showRYS_D1")
    this.setState({
      pageState: "RYS_D1",
    });
  };

  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  getrysdates = (xvalue) => {
    let cDate = new Date();
    let xrArr = []
    switch (xvalue) {
      case "LAST7DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "LAST30DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "THISMONTH":
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "LASTMONTH":
        let thisMonthFDate = new Date(cDate.getFullYear(), cDate.getMonth(), 1, 23, 59, 59, 999);
        xrArr.push(new Date(thisMonthFDate.getFullYear(), thisMonthFDate.getMonth() - 1, 1, 0, 0, 0, 0));
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
      case "YTD":
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "TODAY":
        xrArr.push(new Date(cDate.getTime()));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "YESTERDAY":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      default:
        break;
    }
    return xrArr
  }
  getCondition(mode) {
    if (mode === "date") {
      if (this.props.location && this.props.location.state && this.props.location.state.rys_date) {
        return {
          name: "Date",
          operator: this.props.location.state.rys_date,
          value: this.getrysdates(this.props.location.state.rys_date).slice(),
          operatorText: this.getLabel("LBL" + this.props.location.state.rys_date),
        }
      } else {
        let xrArr = [];
        let cDate = new Date();
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date());
        return {
          name: "Date",
          operator: "YTD",
          value: this.getrysdates("YTD").slice(),
          operatorText: this.getLabel("LBLYTD"),
        }
      }
    }
  }
  getRYS_D1_Columns = (x) => {

    let columns = [
      {
        name: this.props.labels.labels.LBLDATE,
        selector: "created_on",
        filter: {
          type: "date",
          showField: true,
          checked: true,
          render: "server",
          condition: this.getCondition("date"),
          exportcell: "_created_on_dateformat",
        },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLDATE}
              </label>
              <span className="rle-brdr">
                {row.created_on_format}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLRUSHING,
        selector: "rushing",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLRUSHING}
              </label>
              <span className="rle-brdr">{row.rushing}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLFRUSTRATION,
        selector: "frustration",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLFRUSTRATION}
              </label>
              <span className="rle-brdr">{row.frustration}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLFATIGUE,
        selector: "fatigue",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLFATIGUE}
              </label>
              <span className="rle-brdr">{row.fatigue}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLACENCY,
        selector: "complacency",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLCOMPLACENCY}
              </label>
              <span className="rle-brdr">{row.complacency}</span>
            </div>
          );
        },
      },
    ];

    let xcolumns = [];
    let response = this.state.groupTypeNames || [];
    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          filter: { type: "string", showField: false, checked: true },
          filterData: this.state.groups[response[i].group_type_id],
          sortable: true,
          compact: true
        });
      }
    }

    columns.splice.apply(columns, [1, 0].concat(xcolumns));
    return columns; //JSON.parse(JSON.stringify(columns))
  };

  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    //console.log("getFilterColumns", mode, cols)
    let xcols = [];
    //console.log("getFilterColumns-111", mode, xcols)
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "RYS_D1") {
      let xobj = objFilterData.availableRYS_D1Fields;
      //console.log("getFilterColumns-xobj", xobj)
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    }
    //console.log("getFilterColumns-222", mode, xcols, cols)
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      //console.log(row[field])
      if (row[field] && row[field] !== 1 && row[field] !== 0) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  setsvaluefordate = (data) => {
    for (let j = 0; j < data.filtercolumns.length; j++) {
      if (data.filtercolumns[j].filter.type === "date" && data.filtercolumns[j].filter.condition) {
        //console.log("setsvaluefordate", data.filtercolumns[j].filter)
        let dt1, dt2;
        let xcondition = JSON.parse(JSON.stringify(data.filtercolumns[j].filter.condition))
        // let ddata = {}
        if (typeof xcondition.value == "string") {
          let arrdt = xcondition.value.split("T")[0].split("-");
          let arrdt2 = xcondition.value.split("T")[1].split(":");
          xcondition.xvalue = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2],
            arrdt2[0],
            arrdt2[1],
            0, 0
          );
        } else {
          if (typeof xcondition.value == "object" && xcondition.value[0] && typeof xcondition.value[0] == "string") {
            xcondition.xvalue = []
            //console.log("xcondition.value[0]", xcondition.value[0])
            let arrdt = xcondition.value[0].split("T")[0].split("-");
            let arrdt2 = xcondition.value[0].split("T")[1].split(":");
            xcondition.xvalue[0] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
          if (typeof xcondition.value == "object" && xcondition.value[1] && typeof xcondition.value[1] == "string") {
            let arrdt = xcondition.value[1].split("T")[0].split("-");
            let arrdt2 = xcondition.value[1].split("T")[1].split(":");
            xcondition.xvalue[1] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
        }
        if (typeof xcondition.value == "object" && xcondition.value[0] && xcondition.value[1]) {
          dt1 = new Date(xcondition.xvalue[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        } else {
          dt1 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        }
        data.filtercolumns[j].filter.condition["svalue"] = [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
      }
    }
  }

  onExport = (type, id, conditions) => {
    //console.log("onExport", type, id, conditions)
    if (id === "F1") {
      this.setFilterFields("F1", conditions);
      let data = { type: type, columns: objFilterData.availableRYS_D1Fields };
      data.dateformat = this.props.dateformat;
      data.timeformat = this.props.timeformat;
      data.filtercolumns = conditions;
      this.getRYS_D1_Export(data);
    }
  };
  setFilterFields = (id, conditions) => {
    //console.log("setFilterFields", id, conditions)
    let xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportcellproperty) {
        xobj[conditions[i].name].exportcellproperty =
          conditions[i].exportcellproperty;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableRYS_D1Fields = xobj;
    }
  };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions) => {
    //console.log("onFilterChangeeeeee2", conditions);
    this.setFilterFields("F1", conditions);
    let data = { type: "web", columns: objFilterData.availableRYS_D1Fields };
    data.filtercolumns = conditions;
    this.setsvaluefordate(data)
    if (this.state.zoomChanged === 1) {
      this.setState({
        pageState: "RYS_D1",
        RYS_D1_List: [],
        orgRYS_D1_List: [],
        RYS_D1_SummaryData: this.getRYS_D1_Summary([], {}),
        sel_D1_Data: {},
        RYSDataLoaded: true,
      });
    }
    this.getRYS_D1_List(data);
  };

  render() {
    const {
      LBLRATESTATEREPORT,
      LBLRECORDS,
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLCOUNT,
      LBLDATE,
      LBLRYSREPORTEMPTYTXT,
    } = this.props.labels.labels;

    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };

    if (this.state.pageState === "RYS_D1") {
      //const { RYS_D1_List } = this.state;
      let columns = this.getRYS_D1_Columns();
      let filterFields = this.getFilterColumns("RYS_D1", columns);
      let resetfilterFields = this.getResetFilterColumns("RYS_D1", columns);

      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < filterFields.length; j++) {
          if (
            filterFields[j] &&
            columns[i] &&
            filterFields[j].name === columns[i].name &&
            filterFields[j].filter.showField === false
          ) {
            columns.splice(i, 1);
            i--;
          }
        }
      }
      objFilterData.orgavailableRYS_D1Fields = this.getFilterColumns("RYS_D1", this.getRYS_D1_Columns());
      let data = this.state.RYS_D1_List;

      return (
        <div className="reportsCtn">
          {/* <div className="card-box"> */}
          <div className="mob-pad">
            <div >
              <h3 className="page-title">{LBLRATESTATEREPORT}</h3>
            </div>
            <hr className="head-line" />

            <div id="accordion" className="row rptlst-row">
              {this.state.loading === false &&
                this.state.filterDataStatus === true &&
                filterFields.length >= 5 ? (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    {this.state.loading === false &&
                      this.state.filterDataLoaded === 1 &&
                      (filterFields.length > 6 || this.props.ssi_data_priv) ? (
                      <div>
                        <CustomFilter
                          availableFields={filterFields}
                          selectedFields={filterFields}
                          orgAvailableFields={resetfilterFields}
                          onFilterChange={this.onFilterChange}
                          onExport={this.onExport}
                          id="F1"
                          onRef={(ref) => (REFS.F1 = ref)}
                          labels={this.props.labels.labels}
                          reportName={LBLRATESTATEREPORT}
                          companyName={this.props.company_name}
                          showFieldFilter={true}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {data.length === 0 ? (
                    <EmptyState image={emptyImage} text2={LBLRYSREPORTEMPTYTXT} />
                  ) : (
                    <>
                      <div className="row zrowbox">
                        {this.state.RYSDataLoaded === true ?
                          <>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                              <div className="s-card">
                                <div className="chart-card">
                                  <h3>
                                    {this.state.rysGraphTitle}
                                  </h3>
                                  <h6><div className="cls_back_status" onClick={(x) => {
                                    if (this.state.rysGraphType === "AverageRYS") {
                                    } else {
                                      this.showRYSGraph("AverageRYS", {})
                                    }
                                  }}
                                    style={{
                                    }}
                                  >
                                    {this.state.rysGraphType === "AverageRYS" ?
                                      <img className="cls_bar" src={
                                        "../../images/steercom/" + this.state.rysGraphImage
                                      } style={{ visibility: "hidden" }} alt=""/>
                                      :
                                     <i class="fas fa-arrow-left" style={{ paddingRight: "10px", margin: "4px 4px 3px 4px", cursor: "pointer" }} aria-hidden="true" title={this.getLabel("LBLBACK")}></i>
                                    }
                                  </div></h6>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                  {
                                    this.state.rysGraphType === "AverageRYS" ?
                                      <RYSAveragesBar
                                        {...this.props}
                                        data={this.state.RYS_D1_SummaryData}
                                        date1={this.state.RYS_D1_SummaryData.date1}
                                        date2={this.state.RYS_D1_SummaryData.date2}
                                        count={LBLCOUNT}
                                        date={LBLDATE}
                                        onElementClick={true}
                                        onBarClick={this.showRYSGraph}
                                      />
                                      :
                                      <RYSAveragesByState
                                        {...this.props}
                                        data={this.state.RYS_D1_SummaryData}
                                        date1={this.state.RYS_D1_SummaryData.date1}
                                        date2={this.state.RYS_D1_SummaryData.date2}
                                        dataPoint={this.state.rysLevel2Data}
                                        linktoreport={true}
                                        count={LBLCOUNT}
                                        date={LBLDATE}
                                        onElementClick={true}
                                        onBarClick={this.onRYSDetailReportLevel2}
                                        onZoomChange={this.onZooomChangeEvent}
                                      />
                                  }
                                </div>
                              </div>

                            </div>
                          </>
                          : <>NOT LOADED</>}

                      </div>

                      <div className="bottomborder">
                        <span className="subHeader">
                          <h4>{`${LBLRECORDS} : ${this.state.RYS_D1_List.length}`}</h4>
                        </span>
                      </div>
                      <DataTable
                        columns={columns}
                        data={data}
                        className="datatable-list"
                        sortFunction={this.customSort}
                        pagination
                        noDataComponent={<EmptyState image={emptyImage} text2={LBLRYSREPORTEMPTYTXT} />}
                        defaultSortField="created_on"
                        paginationComponentOptions={paginationComponentOptions}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    <div>
                      <div>
                        <div>
                          <h3>
                            <Skeleton height={21} />
                          </h3>
                          <h2>
                            <Skeleton height={26} />
                          </h2>
                          <div>
                            <div>
                              <div className="align-items-top">
                                <div>
                                  <Skeleton height={19} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row zrowbox">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="form-group col-xl-3 col-lg-3 col-md-3 ml-auto mr-12">
                        <Skeleton height={34} />
                      </div>
                      <Skeleton height={400} />
                    </div>
                  </div>

                  <div className="bottomborder">
                    <span className="subHeader">
                      <h4>
                        <Skeleton height={38} />
                      </h4>
                    </span>
                  </div>
                  <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                    <div className="sc-fznWqX gnahTY">
                      <div className="sc-AxjAm gIMaKV rdt_Table">
                        <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                          <Skeleton height={56} />
                        </div>
                        <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                          <Skeleton height={480} />
                        </div>
                      </div>
                    </div>
                    <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                      <Skeleton height={56} />
                    </nav>
                  </div>
                  <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                    <header className="sc-fzoXzr hlidmt">
                      <Skeleton height={34} />
                    </header>
                    <div className="sc-fznWqX gnahTY">
                      <div className="sc-AxjAm gIMaKV rdt_Table">
                        <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                          {skel.map((i) => {
                            return (
                              <div
                                id={"row-" + i}
                                className="sc-fzoLsD doBktq rdt_TableRow"
                              >
                                <Skeleton height={280} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                      <div className="sc-fzpmMD ftIMtk">
                        <Skeleton height={40} />
                      </div>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div >
      );
    } else {
      return <div>No Data for {this.state.pageState}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(RYSReport);
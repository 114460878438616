import React, { Component } from "react";
//import { connect } from "react-redux";
//import { createBrowserHistory } from 'history';
import { withRouter } from "react-router";
import $ from 'jquery';
//import CanvasJSReact from "../../../assets/canvasjs/canvasjs.reactdashbrd";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";  

highchartsGantt(Highcharts); 
require("highcharts/modules/exporting")(Highcharts);

//var CanvasJSChart = CanvasJSReact.CanvasJSChart;
//let chart = null;
const cstLabels = {
  "LBLCOMPLEVEL":"Completion Level",
}
class StackedColumn100Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
    };
  }

  componentDidMount() {
    this.chartDetails();
    var chart = this.chart;
    $('body').on("onresize", function () {
      //console.log("doughnut resize")
      chart.render();
    })
  }
  componentWillUnmount() {
    $('body').off("onresize")
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.chartDetails();
    }
  }

  onBubbleClick = () => {
    if (this.props.onElementClick) {
      this.props.onDetailReport()
    }
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  }; 
  
  chartDetails() {
    let options_bar=[];
    let gData = {};
    let datasets = [];
    let obj = {};
    let dataPoints = [];
    let unitslength = 0
    let barColors = ["#A6D486", "#FFFF99", "#FF8F8F"];
    if (this.props.data.datasets) {
      obj.backgroundColor = this.props.data.datasets[0].backgroundColor;
      obj.data = [];
      obj.hoverBackgroundColor = this.props.data.datasets[0].hoverBackgroundColor;
      datasets.push(obj);
      gData.datasets = datasets;
      let labels = this.props.data.labels;
      gData.labels = labels;


      let result = this.props.data.datasets[0].graphData
      if (result) {
        let nsdps = []
        let ipdps = []
        let cmdps = []
        let dmyps = []

        unitslength= result.length
        // for (var i = 0; i < result.length; i++) {
        for (var j = 0; j < result.length; j++) {
          let ns = parseInt(result[j].Notstarted * 100 / result[j].totalUsers)
          let ip = parseInt(result[j].Inprogress * 100 / result[j].totalUsers)
          let cd = parseInt(result[j].Completed * 100 / result[j].totalUsers)
          nsdps.push({ y: ns, x: j + 1, color: "#FF8F8F", tpcount: result[j].Notstarted, count: ns > 8 ? result[j].Notstarted : "", tcount: result[j].totalUsers})
          ipdps.push({ y: ip, x: j + 1, color: "#FFFF99", tpcount: result[j].Inprogress, count: ip > 8 ? result[j].Inprogress : "", tcount: result[j].totalUsers})
          cmdps.push({ y: cd, x: j + 1, color: "#A6D486", tpcount: result[j].Completed, count: cd > 8 ? result[j].Completed : "", tcount: result[j].totalUsers})
          dmyps.push({ y: 0, x: j + 1, color: "#FFFFFF", tpcount: "", count: "", tcount: result[j].totalUsers })
        }
        //}

        gData.datasets[0].data.push({
          
          type: "stackedColumn100",
          name: this.getLabel("LBLNOTSTARTED"),
          showInLegend: true,
          dataPoints: nsdps,
          toolTipContent: "{name}: <strong>{tpcount} " + this.getLabel("LBLUSERS") + " ({y}%)</strong>",
          indexLabelPlacement: "inside",
          indexLabel: "{count}",
          legendMarkerColor: "#FF8F8F",
          click: this.onBubbleClick,
          cursor: this.props.onElementClick ? "pointer" : "default",
          indexLabelFontSize: 12,
          indexLabelFontFamily: "'Roboto', sans-serif",
          indexLabelFontWeight: "400",
          indexLabelFontColor:"#333",
        })
        gData.datasets[0].data.push({
          type: "stackedColumn100",
          name: this.getLabel("LBLINPROGRESS"),
          showInLegend: true,
          dataPoints: ipdps,
          toolTipContent: "{name}: <strong>{tpcount} " + this.getLabel("LBLUSERS") + " ({y}%)</strong>",
          indexLabelPlacement: "inside",
          indexLabel: "{count}",
          legendMarkerColor: "#FFFF99",
          click: this.onBubbleClick,
          cursor: this.props.onElementClick ? "pointer" : "default",
          indexLabelFontSize: 12,
          indexLabelFontFamily: "'Roboto', sans-serif",
          indexLabelFontWeight: "400",
          indexLabelFontColor:"#333",

        })
        gData.datasets[0].data.push({
          type: "stackedColumn100",
          name: this.getLabel("LBLCOMPLETED"),
          showInLegend: true,
          dataPoints: cmdps,
          toolTipContent: "{name}: <strong>{tpcount} " + this.getLabel("LBLUSERS") + " ({y}%)</strong>",
          indexLabelPlacement: "inside",
          indexLabel: "{count}",
          legendMarkerColor: "#A6D486",
          click: this.onBubbleClick,
          cursor: this.props.onElementClick ? "pointer" : "default",
          indexLabelFontSize: 12,
          indexLabelFontFamily: "'Roboto', sans-serif",
          indexLabelFontWeight: "400",
          indexLabelFontColor:"#333",
        })
        gData.datasets[0].data.push({
          type: "column",
          name: "",
          dataPoints: dmyps,
          toolTipContent: "",
          indexLabelPlacement: "outside",
          indexLabel: "{count}",
          legendMarkerColor: "#FFFFFF",
          axisXType: "secondary",
          indexLabelFontSize: 12,
          indexLabelFontFamily: "'Roboto', sans-serif",
          indexLabelFontWeight: "400",
          indexLabelFontColor:"#333",
        })
      }

    }
    let usersCount = [];
    if (this.props.data.datasets) {
      obj.backgroundColor = this.props.data.datasets[0].backgroundColor;
      obj.data = [];
      obj.hoverBackgroundColor = this.props.data.datasets[0].hoverBackgroundColor;
      datasets.push(obj);
      gData.datasets = datasets;
      let labels = this.props.data.labels;
      gData.labels = labels; 

      let result = this.props.data.datasets[0].graphData
      if (result) {
        let nsdps = []
        let ipdps = []
        let cmdps = []
        let dmyps = []

        unitslength= result.length
        // for (var i = 0; i < result.length; i++) {
        for (var j = 0; j < result.length; j++) {
          usersCount.push(result[j].totalUsers);
          let ns = parseInt(result[j].Notstarted * 100 / result[j].totalUsers)
          let ip = parseInt(result[j].Inprogress * 100 / result[j].totalUsers)
          let cd = parseInt(result[j].Completed * 100 / result[j].totalUsers) 
          let ns1 = parseInt(result[j].Notstarted)
          let ip1 = parseInt(result[j].Inprogress)
          let cd1 = parseInt(result[j].Completed)
          ns = ns1;cd = cd1;ip = ip1; 
          nsdps.push({ y: ns, x: j + 1, k:ns1, color: "#FF8F8F", tpcount: result[j].Notstarted, count: ns > 8 ? result[j].Notstarted : "", tcount: result[j].totalUsers})
          ipdps.push({ y: ip, x: j + 1, k:ip1, color: "#FFFF99", tpcount: result[j].Inprogress, count: ip > 8 ? result[j].Inprogress : "", tcount: result[j].totalUsers})
          cmdps.push({ y: cd, x: j + 1, k:cd1, color: "#A6D486", tpcount: result[j].Completed, count: cd > 8 ? result[j].Completed : "", tcount: result[j].totalUsers})
          dmyps.push({ y: 0, x: j + 1,  k:0, color: "#FFFFFF", tpcount: "", count: "", tcount: result[j].totalUsers })  
        }
        //}
        gData.datasets[0].data.push({ 
          name: this.getLabel("LBLCOMPLETED"),
          showInLegend: true,
          data: cmdps,
          color: barColors[0],
          toolTipContent: "{name}: <strong>{tpcount} " + this.getLabel("LBLUSERS") + " ({y}%)</strong>",
          indexLabelPlacement: "inside",
          indexLabel: "{count}",
          legendMarkerColor: "#A6D486",
          click: this.onBubbleClick,
          cursor: this.props.onElementClick ? "pointer" : "default",
          indexLabelFontSize: 12,
          indexLabelFontFamily: "'Roboto', sans-serif",
          indexLabelFontWeight: "400",
          indexLabelFontColor:"#333",
        })
        gData.datasets[0].data.push({ 
          name: this.getLabel("LBLINPROGRESS"),
          showInLegend: true,
          data: ipdps,
          color: barColors[1],
          toolTipContent: "{name}: <strong>{tpcount} " + this.getLabel("LBLUSERS") + " ({y}%)</strong>",
          indexLabelPlacement: "inside",
          indexLabel: "{count}",
          legendMarkerColor: "#FFFF99",
          click: this.onBubbleClick,
          cursor: this.props.onElementClick ? "pointer" : "default",
          indexLabelFontSize: 12,
          indexLabelFontFamily: "'Roboto', sans-serif",
          indexLabelFontWeight: "400",
          indexLabelFontColor:"#333", 
        })
        gData.datasets[0].data.push({ 
          name: this.getLabel("LBLNOTSTARTED"),
          showInLegend: true,
          data : nsdps,
          color: barColors[2],
          toolTipContent: "{name}: <strong>{tpcount} " + this.getLabel("LBLUSERS") + " ({y}%)</strong>",
          indexLabelPlacement: "inside",
          indexLabel: "{count}",
          //legendMarkerColor: "#FF8F8F",
          click: this.onBubbleClick,
          cursor: this.props.onElementClick ? "pointer" : "default",
          indexLabelFontSize: 12,
          indexLabelFontFamily: "'Roboto', sans-serif",
          indexLabelFontWeight: "400",
          indexLabelFontColor:"#333",
        })
      
   
        // gData.datasets[0].data.push({ 
        //   name: "",
        //   data: dmyps,
        //   toolTipContent: "",
        //   indexLabelPlacement: "outside",
        //   indexLabel: "{count}",
        //   legendMarkerColor: "#FFFFFF",
        //   axisXType: "secondary",
        //   indexLabelFontSize: 12,
        //   indexLabelFontFamily: "'Roboto', sans-serif",
        //   indexLabelFontWeight: "400",
        //   indexLabelFontColor:"#333",
        // })
      }
      options_bar = this.getOption();
    }
    let idx=0;
    options_bar.series = gData.datasets[0].data;
    console.log("@@@@@@@@@#@@@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$$")
    console.log(gData.datasets[0].data) 
    // options_bar.yAxis.stackLabels.formatter=function(e){
    //   let idx1 = idx++; 
    //   return usersCount[idx1].toString();
    // }
    options_bar.yAxis.labels.formatter=function(e){
      let index = e.value; 
      if (index % 100 === 0) { 
        return index + "%"
      } else {
        return "";
      }
    }
    let unitnames = ["", "U1", "U2", "U3", "U4", "U5"]
    this.setState({ 
      options_bar:options_bar,
      options: {
        height: 260,
        interactivityEnabled: true,
        animationEnabled: true,
        animationDuration: 1000,
        backgroundColor: "transparent",
        legend: {
          horizontalAlign: "center",
          verticalAlign: "bottom",
          fontSize: 12,
          fontFamily: "'Roboto', sans-serif",
          fontWeight: "500",
          fontColor:"#333",
        },
        toolTip:{
          fontSize: 13,
          fontFamily: "'Roboto', sans-serif",
          fontColor: "#333",
        },
        axisY2: {
          interlacedColor: "rgba(1,77,101,.2)",
          gridColor: "rgba(1,77,101,.1)",
          titleFontSize: 12,
          titleFontFamily: "'Roboto', sans-serif",
          titleFontWeight: "400",
          titleFontColor:"#333",
          labelFontSize: 12,
          labelFontFamily: "'Roboto', sans-serif",
          labelFontWeight: "400",
          labelFontColor:"#333",
        },
        axisX2: {
          interval: 1,
          gridThickness: 0,
          lineThickness: 0,
          tickLength: 4,
          tickColor: "#FFFFFF",
          gridColor: "orange",
          position: "top",
          labelFormatter: function (e) {
            let index = e.value;
            if (index > 0 && index <= unitslength) {
              return gData.datasets[0].data[0].dataPoints[0].tcount
            } else {
              return "";
            }
          },
          titleFontSize: 12,
          titleFontFamily: "'Roboto', sans-serif",
          titleFontWeight: "400",
          titleFontColor:"#333",
          labelFontSize: 12,
          labelFontFamily: "'Roboto', sans-serif",
          labelFontWeight: "400",
          labelFontColor:"#333",
        },
        axisX: {          
          interval: 1,
          gridThickness: 0,
          gridColor: "orange",
          position: "top",
          labelFormatter: function (e) {
            let index = e.value;
            if (index > 0 && index <= unitslength) {
              return unitnames[index]
            } else {
              return "";
            }
          },
          tickLength: 10,
          tickColor: "#FFFFFF",
          titleFontSize: 12,
          titleFontFamily: "'Roboto', sans-serif",
          titleFontWeight: "400",
          titleFontColor:"#333",
          labelFontSize: 12,
          labelFontFamily: "'Roboto', sans-serif",
          labelFontWeight: "400",
          labelFontColor:"#333",
        },
        axisY: {
          gridThickness: 0,
          gridColor: "orange",
          title: this.getLabel("LBLCOMPLEVEL"),//"LBLCOMPLEVEL":"Completion Level",
          //maximum: 105,
          tickLength: 0,
          labelFormatter: function (e) {
            let index = e.value;
            if (index % 25 === 0) {
              return index + "%"
            } else {
              return "";
            }
          },
          titleFontSize: 12,
          titleFontFamily: "'Roboto', sans-serif",
          titleFontWeight: "400",
          titleFontColor:"#333",
          labelFontSize: 12,
          labelFontFamily: "'Roboto', sans-serif",
          labelFontWeight: "400",
          labelFontColor:"#333",
        },
        dataPointMaxWidth: 30,
        data: gData.datasets[0].data
      },
    });
  }
  getOption = (data)=>{
    let pieColors = ["#A6D486", "#FFFF99", "#FF8F8F"]; //7DB1F5  F5C947 C8C6C6
    let unitnames = [ "","U1", "U2", "U3", "U4", "U5"]
    let gData=[]
    let objData =[];  
    let unitslength = 0
    let {LBLCOMPLETED,LBLINPROGRESS,LBLNOTSTARTED} = this.props.labels.labels;   
    let categories = [LBLCOMPLETED,LBLINPROGRESS,LBLNOTSTARTED];
    if(data && data.length>0){
      objData.push({name : LBLCOMPLETED, y:data[0].Completed},
                   {name : LBLINPROGRESS, y:data[0].Inprogress},
                   {name : LBLNOTSTARTED, y:data[0].Notstarted}
      );
     } 
    let chart_data = {
      chart: {
          type: 'column',
		      height : 285,
         // width:250, 
          marginTop: 0, 
          spacingBottom: 15,
          spacingTop: 15,
          spacingLeft: -3,
          spacingRight: 10,
          style: {
            fontFamily: "'Roboto', sans-serif"
          }
      },
      legend: { 
        //width: '100%',
        //width: 290, 
        align: 'left',
        padding:12,  
        itemStyle: { 
          fontWeight: '450', 
          fontSize: '11px',
          border:'1px solid black',
          fontFamily: "'Roboto', sans-serif", 
      }
    },
      credits: { 
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
         headerFormat: '',  
         pointFormat: '{series.name}: <strong>{point.y} ' + this.getLabel("LBLUSERS") + " ({point.percentage:.0f}%)" + '</strong>' 
        //pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.1f}  )<br/>',
        
      },
      title: {
          text: null ,
          align: 'left',
      },
      subtitle: {
          text: null ,
          align: 'left'
      },
      plotOptions: {
        colors: pieColors,  
        //click: this.onBubbleClick,
        column: { 
            stacking: 'percent', 
            dataLabels: {
                enabled: true 
            }
        },
        series: {
          stacking: 'normal',
          pointWidth: 30,
          events: { 
            click: this.onBubbleClick,
          },
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.point.k>0) {
                return this.point.k;
              }else{
                return ""
              }
            }
          }
        }
    },
    xAxis: {
        categories: unitnames, 
        gridLineColor: 'transparent', 
        labels:{
          //formatter: function() {}
        },
    },
    yAxis: {
      min: 0, 
      max: 110,
      tickInterval :10,
      lineWidth: .1,
      gridLineColor: 'transparent',
      labels:{
        // formatter: function() {
        //   return this.point.total
        // } 
      },
      title: {
          text: this.getLabel("LBLCOMPLEVEL") ,
      },
      stackLabels: {
          enabled: true, 
          useHTML: true, 
          style:{ 
          },
          x: 0,
          y: 0,   
      }
    },
    series: [{ 
        showInLegend: true,         
        data: objData
  }] 
}; 
   return chart_data;
  }
  render() {
    return (
      // <CanvasJSChart options={this.state.options}
      //   onRef={ref => this.chart = ref}
      // />
      <HighchartsReact highcharts={Highcharts} 
        options={this.state.options_bar}
        ref={this.chart}   
      />
    );
  }
}
StackedColumn100Chart = withRouter(StackedColumn100Chart)
export default StackedColumn100Chart;
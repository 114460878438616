export const EQUALS = 'EQUALS';
export const NOT_EQUALS = 'NOT_EQUALS';
export const GREATER_THAN = 'GREATER_THAN';
export const GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS';
export const LESS_THAN = 'LESS_THAN';
export const LESS_THAN_EQUALS = 'LESS_THAN_EQUALS';
export const BETWEEN = 'BETWEEN';
export const NOT_BETWEEN = 'NOT_BETWEEN';
export const LIKE = 'LIKE';
export const NOT_LIKE = 'NOT_LIKE';
export const CONTAINS = 'CONTAINS';
export const DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN';
export const LAST = 'LAST';
export const TODAY = 'TODAY';
export const YESTERDAY = 'YESTERDAY';
export const LAST7DAYS = 'LAST7DAYS';
export const LAST30DAYS = 'LAST30DAYS';
export const THISMONTH = 'THISMONTH';
export const LASTMONTH = 'LASTMONTH';
export const MULTI_EQUALS = 'MULTI_EQUALS';
export const NOCONDITION = 'NOCONDITION';
export const YTD =  'YTD';


//"EQUALS":"Equals"
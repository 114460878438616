import React, { useEffect, useState } from "react";
import SSOService from "../services/sso_services";

const Auth0Login = (props) => {
  const redirecttoAuth0Login = () => {
    let location = window.location;
    let origin;
    if (location.hostname === "localhost")
      origin = "http://" + location.host;
    else
      origin = location.origin;
    SSOService.getSSOConnectionCreds(origin, "auth0_conn_creds").then((responseurl) => {
      if (responseurl) {
        window.location.href = responseurl
      }
    });
  }
  return (
    <>
      <a onClick={redirecttoAuth0Login}
        className="btn btn-block sso-btn"
      >
        <span className="sso-btn-text">
          {props.labels.labels.LBLSIGNINWITHSSO}
        </span>
      </a>
    </>)
}
export default Auth0Login;

import React from 'react';
//import { connect } from 'react-redux';
//import { Link } from "react-router-dom";

const ServerError = (props) => {
    console.log(props.labels.errors.LBLSERVERERROR)
    return (<div className="main-content" id="main-content">
        <h1>{props.labels.errors.LBLSERVERERROR1}</h1>  <h1>{props.labels.errors.LBLSERVERERROR2}</h1>
        <div></div>
        <h2>{props.labels.errors.LBLSUPPORTEMAIL}</h2>
    </div>);
}

export default ServerError;
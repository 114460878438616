import React, { Component } from "react";
//import { connect } from "react-redux";
//import { createBrowserHistory } from 'history';
import { withRouter } from "react-router";
import $ from 'jquery';
//import CanvasJSReact from "../../../assets/canvasjs/canvasjs.reactdashbrd";
import { da } from "date-fns/locale";
import { parse } from "crypto-js/enc-base64";
import { min } from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";  

highchartsGantt(Highcharts); 
require("highcharts/modules/exporting")(Highcharts);

const moment = require("moment");
//var CanvasJSChart = CanvasJSReact.CanvasJSChart;
//let chart = null;
var xinterval = 1
var zoomChanged = 0
var dataPoints=[];
const cstLabels = {
  "LBLCOMPLEVEL":"Completion Level",
}
class StatesByState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      options_area:{},
      aColor: this.props.dataPoint.color,
      options_area:[],
      dataPoints:this.props.dataPoint,
      id: this.props.graphId
    };
    Object.keys(props.dataPoint).map((item)=>{
      dataPoints[item] = props.dataPoint[item];
    }); 
  }

  componentDidMount() {
    this.chartDetails(dataPoints); 
    var chart = this.chart;
    $('body').on("onresize", function () {
      //console.log("doughnut resize")
      chart.render();
    })
  }
  componentWillUnmount() {
    $('body').off("onresize")
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.chartDetails();
    }
  }

  onBubbleClick = () => {
    if (this.props.onElementClick) {
      this.props.onDetailReport()
    }
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  onBarClick = (e) => {
    if (this.props.onElementClick) {
      this.props.onBarClick(e.dataPoint)
    }
  }
  chartDetails(dataPointsL) { 
    let sd1 = this.props.data.date1
    let sd2 = this.props.data.date2
    var daydiff = Math.floor((sd2 - sd1) / (1000 * 60 * 60 * 24))
    let maxXpoint = 0
    let minXPoint = 0
    var yearpos, monthpos, datepos;
    let arrDtFmt = this.props.dateformat.split("/");
    for (let txi = 0; txi < arrDtFmt.length; txi++) {
      if (arrDtFmt[txi].toUpperCase().indexOf("D") > -1) {
        datepos = txi;
      }
      if (arrDtFmt[txi].toUpperCase().indexOf("M") > -1) {
        monthpos = txi;
      }
      if (arrDtFmt[txi].toUpperCase().indexOf("Y") > -1) {
        yearpos = txi;
      }
    }
    var getObjDate = (xstrday) => {
      let arrdt = xstrday.split(" ")[0].split("/");
      return new Date(
        arrdt[yearpos],
        parseInt(arrdt[monthpos]) - 1,
        arrdt[datepos],
        0,
        0,
        0, 0
      );
    }
    var getDaysDifferent = function (xdate) {
      var xObjDt = getObjDate(xdate)
      let diff = xObjDt - sd1
      var oneDay = 1000 * 60 * 60 * 24;
      diff = diff < 0 ? 0 : diff
      // console.log("getDaysDifferent", xdate, sd1, xObjDt, sd1.getTime(), xObjDt.getTime(), diff, Math.floor(diff / oneDay), oneDay)
      return Math.floor(diff / oneDay);
    };
    var getWeekNo = function (xdate) {
      return parseInt(getDaysDifferent(xdate) / 7);
    };
    var oneDay = 1000 * 60 * 60 * 24;
    var getWeekData = function (xdate) {
      let dayDiff = getDaysDifferent(xdate)
      let weekNo = parseInt(dayDiff / 7);

      let sdate = getObjDate(xdate).getTime() - (oneDay * (dayDiff % 7))
      let edate = sdate + (oneDay * (dayDiff * 6))
      return ({
        dayDiff: dayDiff,
        weekNo: weekNo,
        wkStartDate: moment(sdate).format(dateformat).substr(0, 10),
        wkEndDate: moment(edate).format(dateformat).substr(0, 10)
      })
    };
    var getMonthNo = function (xdate) {
      return getObjDate(xdate).getMonth()
    };
    var getWeekDay = function (xdate) {
      var xObjDt = getObjDate(xdate)
      return xObjDt.getDay();
    };
    let gData = {};
    let datasets = [];
    let obj = {};
    let dataPoints = [];
    let dateformat = this.props.dateformat;
    let xsummaryData = {
      rushing: {},
      fatigue: {},
      frustration: {},
      complacency: {},
      cnt_rushing: {},
      cnt_fatigue: {},
      cnt_frustration: {},
      cnt_complacency: {},
    };
    let summaryData = {
      rushing: {},
      fatigue: {},
      frustration: {},
      complacency: {},
      cnt_rushing: {},
      cnt_fatigue: {},
      cnt_frustration: {},
      cnt_complacency: {},
    };
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", this.props.data)
    if (this.props.data.datasets) {
      let result = this.props.data.datasets[0].graphData

      var colors = {
        "rushing": "#F46980",
        "frustration": "#FBBF7D",
        "fatigue": "#B7B3AE",
        "complacency": "#58AFA9"
      }
      //var rys_state = this.props.dataPoint ? this.props.dataPoint.state || "rushing" : "rushing"
      var rys_state = dataPointsL? dataPointsL.states?dataPointsL.states.toLowerCase():"rushing" || "rushing" : "rushing"
      
      var xdataPoints = []
      var dummydataPoints = []
      var axisX2Labels = []
      var axisXLabels = []
      var axisXobjLabels = []
      var xAxLabels = []
      if (result) {
        for (var i = 0; i < result.length; i++) {
          let xdate = moment.utc(result[i].created_on).format(dateformat);//.substr(0, 10).split("/").reverse().join("/")
          // console.log("QWERTY", rys_state, result[i][rys_state])
          if (parseInt(result[i][rys_state]) >= 0) {
            if (xsummaryData[rys_state].hasOwnProperty(xdate) === false) {
              xsummaryData[rys_state][xdate] = 0;
              xsummaryData["cnt_" + rys_state][xdate] = 0
            }
            xsummaryData[rys_state][xdate] = xsummaryData[rys_state][xdate] + parseInt(result[i][rys_state])
            xsummaryData["cnt_" + rys_state][xdate]++;
          }
          xAxLabels.push(new Date(result[i].created_on_format).getTime());
        }
        // console.log("xsummaryData", xsummaryData)
      }
      if (daydiff <= -1) {
        axisXLabels = [
        this.getLabel("LBLSUN"),
        this.getLabel("LBLMON"),
        this.getLabel("LBLTUE"),
        this.getLabel("LBLWED"),
        this.getLabel("LBLTHU"),
        this.getLabel("LBLFRI"),
        this.getLabel("LBLSAT")
        ]
        maxXpoint = 6
        minXPoint = 0
        for (let xdate in xsummaryData[rys_state]) {
          let xDay = getWeekDay(xdate)
          let strXDay = "" + xDay
          if (summaryData[rys_state].hasOwnProperty(strXDay) === false) {
            summaryData[rys_state][strXDay] = 0;
            summaryData["cnt_" + rys_state][strXDay] = 0
          }
          summaryData[rys_state][strXDay] = summaryData[rys_state][strXDay] + xsummaryData[rys_state][xdate]
          summaryData["cnt_" + rys_state][strXDay] = summaryData["cnt_" + rys_state][strXDay] + xsummaryData["cnt_" + rys_state][xdate]
          console.log("BXBXBXBXBBX", strXDay, summaryData[rys_state][strXDay], summaryData["cnt_" + rys_state][strXDay])
        }
        for (let xkey in summaryData[rys_state]) {
          xdataPoints.push(
            { y: parseFloat((summaryData[rys_state][xkey] / summaryData["cnt_" + rys_state][xkey]).toFixed(1)), x: xkey, color: colors[rys_state], tpcount: summaryData["cnt_" + rys_state][xkey], tpsum: summaryData[rys_state][xkey] }
          )
          console.log("AXAXAXAXA", xdataPoints[xdataPoints.length - 1])
          dummydataPoints.push({ y: 0, x: xkey })
          axisX2Labels[xkey] = summaryData["cnt_" + rys_state][xkey]
        }
      } else if (daydiff < 14) {
        maxXpoint = 0
        minXPoint = 0
        for (let i = 0; i < 14; i++) {
          let xindex = i;
          let xDate = new Date(sd1.getTime() + (24 * 60 * 60 * 1000 * xindex));
          axisXLabels[i] = moment(xDate).format(dateformat)
          axisX2Labels[i] = ""
        }
        if (result) {
          for (let xdate in xsummaryData[rys_state]) {
            let xDDiff = getDaysDifferent(xdate)
            xdataPoints.push(
              { y: parseFloat((xsummaryData[rys_state][xdate] / xsummaryData["cnt_" + rys_state][xdate]).toFixed(1)), x: xDDiff, color: colors[rys_state], tpcount: xsummaryData["cnt_" + rys_state][xdate], tpsum: xsummaryData[rys_state][xdate] }
            )
            console.log("CXCXCXCX", xdataPoints[xdataPoints.length - 1])
            dummydataPoints.push({ y: 0, x: xDDiff })
            axisX2Labels[xDDiff] = xsummaryData["cnt_" + rys_state][xdate]
            //axisXLabels[xDDiff] = xdate
            maxXpoint = xDDiff > maxXpoint ? xDDiff : maxXpoint
          }
        }
      } else if (daydiff < 35) {
        axisXLabels = []
        for (let i = 0; i < 5; i++) {
          let sdate = sd1.getTime() + (oneDay * (i * 7))
          let edate = sdate + (oneDay * 6)
          axisXobjLabels[i] = {}
          axisXobjLabels[i].wkNumber = this.getLabel("LBLWEEK" + (i + 1))
          axisXobjLabels[i].wkStartDate = moment(sdate).format("MMM DD")//.substr(0, 10)
          axisXobjLabels[i].wkEndDate = moment(edate).format("MMM DD")//.substr(0, 10)
          axisXLabels[i] = axisXobjLabels[i].wkStartDate.split(" ").join("-") + " " + axisXobjLabels[i].wkEndDate.split(" ").join("-") + ""
        }
        maxXpoint = 0
        minXPoint = 0
        for (let xdate in xsummaryData[rys_state]) {
          let wkData = getWeekData(xdate)
          let xDay = wkData.weekNo
          let strXDay = "" + xDay
          if (summaryData[rys_state].hasOwnProperty(strXDay) === false) {
            summaryData[rys_state][strXDay] = 0;
            summaryData["cnt_" + rys_state][strXDay] = 0
          }
          summaryData[rys_state][strXDay] = summaryData[rys_state][strXDay] + xsummaryData[rys_state][xdate]
          summaryData["cnt_" + rys_state][strXDay] = summaryData["cnt_" + rys_state][strXDay] + xsummaryData["cnt_" + rys_state][xdate]
          console.log("BXBXBXBXBBX", strXDay, summaryData[rys_state][strXDay], summaryData["cnt_" + rys_state][strXDay])
        }
        for (let xkey in summaryData[rys_state]) {
          xdataPoints.push(
            { y: parseFloat((summaryData[rys_state][xkey] / summaryData["cnt_" + rys_state][xkey]).toFixed(1)), x: xkey, color: colors[rys_state], tpcount: summaryData["cnt_" + rys_state][xkey], tpsum: summaryData[rys_state][xkey] }
          )
          console.log("AXAXAXAXA", xdataPoints[xdataPoints.length - 1])
          dummydataPoints.push({ y: 0, x: xkey })
          axisX2Labels[xkey] = summaryData["cnt_" + rys_state][xkey]
          let xDDiff = parseInt(xkey)
          maxXpoint = xDDiff > maxXpoint ? xDDiff : maxXpoint
          //minXPoint = xDDiff < minXPoint ? xDDiff : minXPoint
        }
      }
      // else if (daydiff < 366) {
      //   let xmonths = ["LBLJAN", "LBLFEB", "LBLMAR", "LBLAPR", "LBLMAY", "LBLJUN", "LBLJUL", "LBLAUG", "LBLSEP", "LBLOCT", "LBLNOV", "LBLDEC"]
      //   axisXLabels = [
      //     this.getLabel("LBLJAN"),
      //     this.getLabel("LBLFEB"),
      //     this.getLabel("LBLMAR"),
      //     this.getLabel("LBLAPR"),
      //     this.getLabel("LBLMAY"),
      //     this.getLabel("LBLJUN"),
      //     this.getLabel("LBLJUL"),
      //     this.getLabel("LBLAUG"),
      //     this.getLabel("LBLSEP"),
      //     this.getLabel("LBLOCT"),
      //     this.getLabel("LBLNOV"),
      //     this.getLabel("LBLDEC"),
      //   ]
      //   maxXpoint = 11
      //   minXPoint = 0
      //   for (let xdate in xsummaryData[rys_state]) {
      //     let xDay = getMonthNo(xdate)
      //     let strXDay = "" + xDay
      //     if (summaryData[rys_state].hasOwnProperty(strXDay) === false) {
      //       summaryData[rys_state][strXDay] = 0;
      //       summaryData["cnt_" + rys_state][strXDay] = 0
      //     }
      //     summaryData[rys_state][strXDay] = summaryData[rys_state][strXDay] + xsummaryData[rys_state][xdate]
      //     summaryData["cnt_" + rys_state][strXDay] = summaryData["cnt_" + rys_state][strXDay] + xsummaryData["cnt_" + rys_state][xdate]
      //     console.log("BXBXBXBXBBX", strXDay, summaryData[rys_state][strXDay], summaryData["cnt_" + rys_state][strXDay])
      //   }
      //   for (let xkey in summaryData[rys_state]) {
      //     xdataPoints.push(
      //       { y: parseFloat((summaryData[rys_state][xkey] / summaryData["cnt_" + rys_state][xkey]).toFixed(1)), x: xkey, color: colors[rys_state], tpcount: summaryData["cnt_" + rys_state][xkey], tpsum: summaryData[rys_state][xkey] }
      //     )
      //     console.log("AXAXAXAXA", xdataPoints[xdataPoints.length - 1])
      //     dummydataPoints.push({ y: 0, x: xkey })
      //     axisX2Labels[xkey] = summaryData["cnt_" + rys_state][xkey]
      //   }
      // } 
      else {
        maxXpoint = 0
        minXPoint = 0
        for (let i = 0; i < 1200; i++) {
          let xindex = i;
          let xDate = new Date(sd1.getTime() + (24 * 60 * 60 * 1000 * xindex));
          axisXLabels[i] = moment(xDate).format(dateformat).substr(0, 10);
        }
        if (result) {
          for (let xdate in xsummaryData[rys_state]) {
            let xDDiff = getDaysDifferent(xdate)
            xdataPoints.push(
              { y: parseFloat((xsummaryData[rys_state][xdate] / xsummaryData["cnt_" + rys_state][xdate]).toFixed(1)), x: xDDiff, color: colors[rys_state], tpcount: xsummaryData["cnt_" + rys_state][xdate], tpsum: xsummaryData[rys_state][xdate] }
            )
            // console.log("CXCXCXCX", xdataPoints[xdataPoints.length - 1])
            dummydataPoints.push({ y: 0, x: xDDiff })
            axisX2Labels[xDDiff] = xsummaryData["cnt_" + rys_state][xdate]
            axisXLabels[xDDiff] = xdate
            maxXpoint = xDDiff > maxXpoint ? xDDiff : maxXpoint
          }
        }
      }
    }
 
    let unitnames = ["", "U1", "U2", "U3", "U4", "U5"]

    let data = [{
      indexLabelFontSize: 12,
      type: daydiff < 35 ? "column" : "splineArea",
      showInLegend: false,
      legendMarkerColor: "grey",
      toolTipContent: this.getLabel("LBLTOTALRESPONSES") + ": <strong>{tpcount}</strong>  <br/>" + this.getLabel("LBLAVGRYSRATINGS") + ": <strong>{y} </strong>",
      indexLabel: daydiff < 35 ? "{y}" : "",
      indexLabelPlacement: "inside",
      color: colors[rys_state],
      cursor: this.props.onElementClick ? "pointer" : "default",
      dataPoints: xdataPoints,
      click: this.onBarClick
    }]

    // console.log("@@@@@@@@@#@@@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$$", xdataPoints)
    // console.log("@@@@@@@@@#@@@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$$", dummydataPoints)
    // console.log("@@@@@@@@@#@@@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$$", axisXLabels)
    // console.log("@@@@@@@@@#@@@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$$", axisX2Labels)
    if (daydiff < 35) {
      data.push({
        type: "stackedColumn100",
        name: "",
        showInLegend: true,
        legendMarkerColor: "grey",
        dataPoints: dummydataPoints,
        toolTipContent: "",
        indexLabelPlacement: "outside",
        indexLabel: "{count}",
        legendMarkerColor: "#FFFFFF",
        axisXType: "secondary",
      })
    }
    let options = {
      height: 260,
      interactivityEnabled: true,
      animationEnabled: true,
      animationDuration: 1000,
      backgroundColor: "transparent",
      legend: {
        horizontalAlign: "center",
        verticalAlign: "bottom",
        fontSize: 12,
        fontFamily: "'Roboto', sans-serif",
      },
      toolTip: {
        fontSize: 13,
        fontFamily: "'Roboto', sans-serif",
        fontColor: "#333",
      },
      axisY2: {
        titleFontSize: 12,
        titleFontFamily: "'Roboto', sans-serif",
        titleFontWeight: "400",
        titleFontColor: "#333",
        labelFontSize: 12,
        labelFontFamily: "'Roboto', sans-serif",
        labelFontWeight: "400",
        labelFontColor: "#333",
        interlacedColor: "rgba(1,77,101,.2)",
        gridColor: "rgba(1,77,101,.1)",
      },
      axisX2: {
        titleFontSize: 12,
        titleFontFamily: "'Roboto', sans-serif",
        titleFontWeight: "400",
        titleFontColor: "#333",
        labelFontSize: 12,
        labelFontFamily: "'Roboto', sans-serif",
        labelFontWeight: "400",
        labelFontColor: "#333",
        interval: 1,
        gridThickness: 0,
        lineThickness: 0,
        tickLength: 4,
        tickColor: "#FFFFFF",
        gridColor: "orange",
        position: "top",
        maximum: maxXpoint + 1,
        minimum: -1,
        labelFormatter: function (e) {
          let index = e.value;
          return axisX2Labels[index] || ""
        },
      },
      axisY: {
        titleFontSize: 12,
        titleFontFamily: "'Roboto', sans-serif",
        titleFontWeight: "400",
        titleFontColor: "#333",
        labelFontSize: 12,
        labelFontFamily: "'Roboto', sans-serif",
        labelFontWeight: "400",
        labelFontColor: "#333",
        interval: 1,
        gridThickness: 0,
        gridColor: "orange",
        position: "top",
        maximum: 10,
        title: this.getLabel("LBLAVGRYSRATINGS"),
        tickLength: 0,
        labelFormatter: function (e) {
          let index = e.value;
          if (index % 10 === 0) {
            return index
          } else {
            return "";
          }
        },
      },
      axisX: {
        //labelFontSize: daydiff < 14 ? 12 : daydiff < 35 ? 12 : 12,
        titleFontSize: 12,
        titleFontFamily: "'Roboto', sans-serif",
        titleFontWeight: "400",
        titleFontColor: "#333",
        labelFontSize: 12,
        labelFontFamily: "'Roboto', sans-serif",
        labelFontWeight: "400",
        labelFontColor: "#333",
        gridThickness: 1,
        gridColor: "#fafafa",
        interval: daydiff < 35 ? 1 : parseInt((maxXpoint) / 10),
        //tickLength: 10,
        //tickColor: "#FFFFFF",
        //labelFontSize: 12,
        maximum: maxXpoint + 1,
        minimum: -1,
        labelMaxWidth: daydiff < 14 ? 70 : daydiff < 35 ? 50 : 70,
        //labelWrap: true,
        labelTextAlign: "center",
        labelAutoFit: daydiff < 35 ? false : true,
        labelAngle: daydiff < 35 ? daydiff < 14 ? 150 : 0 : 150,
        //labelAutoFit: true,
        labelFormatter: function (e) {
          let index = e.value;
          if (index >= 0 && index <= maxXpoint && daydiff < 35) {
            if (this.chart.width / maxXpoint > 55) {
              //return axisXLabels[index] || index // e.value
              // if (index % 2 === 0) {
              //   return axisXLabels[index] || index // e.value
              // } else {
              //   return ""
              // }
              return axisXLabels[index]
            } else {
              // if (index % 2 === 0) {
              //   return axisXLabels[index] || index // e.value
              // } else {
              //   return ""
              // }
              return axisXLabels[index]
            }

          } else if (index > 0 && index <= maxXpoint && daydiff >= 35) {
            return axisXLabels[index] || "" // e.value
          } else {
            return ""
          }
        },
      },
      dataPointMaxWidth: 30,
      data: data,
      zoomEnabled: daydiff < 35 ? false : true,
      rangeChanged: function (e) {
        if (e.trigger === "reset") {
          zoomChanged = 0
          $('#zoomChange').trigger('click')
          e.chart.options.axisX.interval = parseInt((e.chart.options.axisX.maximum - e.chart.options.axisX.minimum) / 10)
          e.chart.options.axisX.labelAutoFit = true
          e.chart.options.axisX.labelAngle = 150
          e.chart.render()
        } else {
          zoomChanged = 1
          $('#zoomChange').trigger('click')
          e.chart.options.axisX.interval = parseInt((e.axisX[0].viewportMaximum - e.axisX[0].viewportMinimum) / 10)
          e.chart.options.axisX.labelAutoFit = true
          e.chart.options.axisX.labelAngle = 150
          e.chart.render()
        }
      }
    }
    var state_colors = {
      "rushing": "#F46980",
      "frustration": "#FBBF7D",
      "fatigue": "#B7B3AE",
      "complacency": "#58AFA9"
    }
    let options_area = this.getOption(axisXLabels);  
    options_area.series[0].data = options.data[0].dataPoints
    options_area.chart.type = daydiff < 35 ? "column" : "areaspline";
    if(options_area.chart.type == "column"){
      options_area.yAxis.stackLabels.enabled=false; 
    }
    options_area.xAxis.categories = [];//xAxLabels; //[1000,2000,3000,4000,5000,6000,7000,8000,9000];// 
    //options_area.plotOptions.series.pointStart = xLabels[0];  
    let dataStates = this.props.dataStates; 
    let gcolor=this.state.aColor?this.state.aColor:state_colors[dataStates];
    options_area.series[0].color = gcolor;
    //options_area.xAxis.max  = maxXpoint + 1;
    //options_area.xAxis.min =  -1;
    //options_area.xAxis.tickInterval= daydiff < 35 ? 1 : parseInt((maxXpoint) / 10);
    // options_area.xAxis.labels.formatter = function(e) {
    //   let index = this.value;
    //   if (index >= 0 && index <= maxXpoint && daydiff < 35) {
    //     if (this.chart.width / maxXpoint > 55) { 
    //       return axisXLabels[index]
    //     } else { 
    //       return axisXLabels[index]
    //     } 
    //   } else if (index >= 0 && index <= maxXpoint && daydiff >= 35) {
    //     return axisXLabels[index] || "" // e.value
    //   } else {
    //     return ""
    //   }
    // }


    var chart = this.chart;
    this.setState({
      options_area:options_area,
      options: options,
      axisXLabels:axisXLabels
    });
  }
  getOption = (data)=>{ 
    let pieColors = ["#A6D486", "#FFFF99", "#FF8F8F"]; //7DB1F5  F5C947 C8C6C6 
    let objData =[];  
    let axisXLabels =  data;
    let {LBLCOMPLETED,LBLINPROGRESS,LBLNOTSTARTED,LBLCOMPLEVEL} = this.props.labels.labels;   
    let categories = [LBLCOMPLETED,LBLINPROGRESS,LBLNOTSTARTED];
    let df = this.props.dateformat;//"MM/DD/YYYY" ;
    let dateformats={"DD/MM/YYYY":"%d/%m/%y","MM/DD/YYYY":"%m/%d/%y", "YYYY/MM/DD":"%y/%m/%d" };
   // alert(dateformats[df])
  let chart_data = {
    chart: {
        type: 'areaspline',
        height : 270,
        marginTop: 5,
        style: {
          fontFamily: "'Roboto', sans-serif"
        } 
    },
    legend: { 
          borderColor: '#CCC', 
        width: '100%',
        itemStyle: { 
          fontWeight: '450',
          fontSize: '12px',
          border:'1px solid black',
          fontFamily: "'Roboto', sans-serif", 
      }
    },
    credits: { 
      enabled: false
    },
    exporting: {
      enabled: false
    },
    tooltip: { 
     // pointFormat: this.getLabel("LBLTOTALRESPONSES") + ": <strong>{point.tpcount}</strong> <br/>" + this.getLabel("LBLAVGRYSRATINGS") + ": <strong>{point.y} </strong>"
     pointFormat: "No State"
    },
    title: {
        text: null ,
        align: 'left',
    },
    subtitle: {
        text: null ,
        align: 'left'
    },
    plotOptions: {
      colors: pieColors,  
      //click: this.onBubbleClick,
      column: {
          stacking: 'normal',
          dataLabels: {
              enabled: true 
          }
      },
      series: { 
        //  pointStart: 1000,//new Date('01/08/2023'),
        stacking: 'normal',
        pointWidth: 28, 
        tooltip: { 
          headerFormat:"",
          pointFormat: this.getLabel("LBLTOTALRESPONSES") + ": <strong>{point.tpcount}</strong> <br/>" + this.getLabel("LBLAVGRYSRATINGS") + ": <strong>{point.y} </strong>"
         },
        // tickPixelInterval:(24 * 3600 * 1000)*7,
        cursor: 'pointer',
        events: { 
          click: this.onBarClick,
        },
        dataLabels: {
          enabled: true,
          formatter: function() {
            if (this.y) {
              return this.y;
            }
          }
        }
      }
    },
    xAxis: { 
      labels: {
        format: "{value:" + dateformats[df] + "}",
        //format: "{value:%d/%m/%y}",
        style: {
          fontWeight: 'bold',
        }
      }
    },
    yAxis: {
      title: {
        text: this.getLabel("LBLAVGRYSRATINGS"),
        style: {
          fontWeight: 'bold',
        }
      },
      stackLabels: {
          enabled: true,
          style: {
              fontWeight: 'bold',
              color: ( // theme
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || 'gray',
              textOutline: 'none'
          }
      }
    },
    series: [{ 
        showInLegend: false,         
        data: objData
  }]
};
   return chart_data;
  }
  render() {
    let {options_area, options,axisXLabels} =  this.state ; 
   // let aColor = this.props.dataPoint.color;
    let data_loc = [],categories=[];
    if(options_area.series)
     options_area.series[0].data=[];
    if(options.data && options.data[0].dataPoints.length>0){    
      //options_area.series[0].data =  options.data[0].dataPoints ; 
      options.data[0].dataPoints.map((item)=>{
        if(item){
          categories.push(axisXLabels[item.x]); 
          data_loc.push( {y:item.y,tpcount:item.tpcount});//, color:aColor 
        } 
      });
      options_area.series[0].data = data_loc;
      options_area.xAxis.categories = categories;
      options_area.xAxis.max = categories.length-1;
    }
    return (<>
      {/* <CanvasJSChart options={this.state.options}
        onRef={ref => this.chart = ref}
      />   */}
      <HighchartsReact highcharts={Highcharts} 
        options={this.state.options_area} 
        ref={this.chart}   
      />
      <div id="zoomChange" onClick={(event) => {
        this.props.onZoomChange(zoomChanged)
      }}></div>
    </>
    );
  }
}
StatesByState = withRouter(StatesByState)
export default StatesByState;

import React, { Component } from "react";
import "react-input-range/lib/css/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import Survey from "./Survey";
import ToastMessage from "../shared/ToastComponent/ToastComponent";

class SurveyRL extends Component {
  constructor(props) {
    super(props);
    // const params = new URLSearchParams(props.location.search);
    // const uid = params.get("uid");
    console.log("survey rl props",props)
    console.log("survey rl props this.props",this.props);
    this.state = {
      //passvars: window.location.href.split("#")[1] || "",
      loading: true,
      isValidUser: false,   
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
        selectGroup: [],
      }, 
    };
  }
  componentDidMount() {
    let xitem = localStorage.getItem('sc_user')
    if (xitem === 'true') {
      this.props.history.goBack();
      return
    }
    this.setState({
      isValidUser: true,
      user_id: this.props.location.state.user_id, //uid || 
      language_code: this.props.location.state.language_code,
      company_logo: this.props.location.state.company_logo,
      surveyType: this.props.location.state.survey_type,
      survey_id: this.props.location.state.survey_id      
    })
  }

  refreshURLToClose = () => {
    this.setState({isValidUser:false})
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: this.state.surveyType === "pre" ? this.props.labels.labels?.LBLREMOTEPRESURVEYCOMPLETED : this.props.labels.labels?.LBLPOSTSURVEYCOMPLETED,
        changeInToast: new Date(),
      },
    });

    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    this.props.history.goBack();
    }, 1500);
  };
  
  render() {
    let {isValidUser, user_id} = this.state;
    return (
      <>
      <ToastMessage
        show={this.state.showToast["show"]}
        helpers={this.state.showToast}
      ></ToastMessage>
        {isValidUser ? 
          <Survey
            hide={this.refreshURLToClose}
            user_id={user_id}
            surveyId = {this.state.survey_id}
            labels={this.props.labels.labels}
            language_code={this.state.language_code}
            surveyType={this.state.surveyType}
            closeButton={false}
            launchType='remote'
            showToastStatus = {() => {}}
            handleSurveyCompletion = {() => {}}
          />
          : null }
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    user_id: state.user_info_reducer.user_info.user_id,
    language_code: state.user_info_reducer.user_info.language_code,
    dateformat: state.user_info_reducer.user_info.dateformat,
  };
};
export default connect(mapStateToProps, null)(SurveyRL);

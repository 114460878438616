import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const handleOkClick = (props, setShow) => {
    setShow(false);

    if (props.helpers && props.helpers.sso_error === true) {
        window.location.href = "/";
    }
    if (props.helpers && props.helpers.sso_admin_verify === true) {
        window.location.href = "/home/products";
    }
};

function SSOLoginError(props) {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.show && !show) {
            setShow(true);
        }
    }, [props.show]);

    if (props.show) {
        return (
            <Stack>
                <div style={{ marginTop: '7%' }}>
                    <img
                        src="./images/SSOLoginError.png"
                        style={{
                            width: "25%",
                            height: "20%",
                            position: "relative",
                            display: 'block',
                            margin: 'auto'
                        }}
                    />
                </div>
                <div style={{ margin: '1% 30%' }} >
                    <Alert severity={props.helpers.sso_error ? 'error' : 'warning'} style={{ display: 'flex', justifyContent: 'center' }} >
                        {props.helpers.message}
                    </Alert>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btnspacing btn-primary"
                        style={{ margin: '1% 45%', width: "8%", borderRadius: '5px' }} onClick={() => handleOkClick(props, setShow)} >
                        {props.oklabel}
                    </button>
                </div>
            </Stack>
        );
    } else {
        return null;
    }
}

export default SSOLoginError;

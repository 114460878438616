import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

class Modalpopup extends Component {
    render() {
        const PropsData = this.props;
        return (
            <Modal show={PropsData.Showmodal}
                onHide={PropsData.closemodal}
                size="md">
                <Modal.Header closeButton className='alert-modal-header'>
                    <Modal.Title className="alert-modal-title">
                        {PropsData.ModalTiltle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataTable
                        columns={PropsData.DatatableColumns}
                        data={PropsData.DatatableData}
                        className="datatable-list"
                        subHeader subHeaderComponent={PropsData.DataTableHeader}
                        paginationPerPage={PropsData.paginationPerPage}
                        pagination
                        noDataComponent={PropsData.Norecords}
                        paginationComponentOptions={PropsData.paginationComponentOptions}
                        paginationRowsPerPageOptions={PropsData.paginationRowsPerPageOptions}
                    />
                </Modal.Body>
            </Modal>
        )
    }
}
export default Modalpopup;
import React,{useEffect} from 'react';
// import data from  "./app.json";
import 'bootstrap/dist/css/bootstrap.min.css';
import './commonstyles/colors.css';
import './commonstyles/typography.css';
import './commonstyles/utils.css';
import './commonstyles/button.css';
import './commonstyles/inputfield.css';
import './commonstyles/alert.css';
import './commonstyles/header.css';
import './commonstyles/tab.css';
// import {
//   BrowserRouter as Router
// } from "react-router-dom";
// import Routes from "./admin/AdminRoutes";
import PublicRoutes from './PublicRoutes';
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import user_info_reducer from './redux/reducer';
import logout_reducer from './redux/Logout/logout_reducer/logout_reducer';
import access_reducer from './redux/access/access_reducer/access_reducer';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import companyinfo_reducer from './redux/companyinfo/reducer';
const reducers = {
  // ... your other reducers here ...
  form: formReducer,     // <---- Mounted at 'form'
  user_info_reducer,
  logout_reducer,
  access_reducer,
  companyinfo_reducer,
}
const reducer = combineReducers(reducers);
const store = createStore(reducer);
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Roboto',
      textTransform: 'none',
      fontSize: 16,
    },
  },
})
function App() {

  useEffect(()=> {
    let logoURL = process.env.REACT_APP_RESOURCES_URL;
    let appTitle = process.env.REACT_APP_MAIN_TITLE;
    let appIcon = process.env.REACT_APP_ICON;
    document.getElementById("mainTitle").innerHTML = appTitle;
    document.getElementById("mainLogo").href = logoURL+"appasset/images/" + appIcon;
  },[])
  // console.log(process.env.REACT_APP_MAIN_TITLE,"appTitle")
  function loadStart(){    
    if(window.location.href.indexOf("safestart-survey") > -1 && window.location.href.indexOf('#') > -1 && !localStorage.getItem('sc_user')){        
        localStorage.setItem('sc_user', 'true');            
    }
  }
  loadStart();
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PublicRoutes ></PublicRoutes>
      </Provider>
    </ThemeProvider>
  );
}

export default App;

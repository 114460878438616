import React, { useState, useEffect, useRef } from "react";
import styles from "./AboutProduct.module.scss"
import CertifatePopUp from "./CertificatePopUp";
import certificateImage from "../../../assets/images/certificateImage.png"
import tickmark from "../../../assets/images/tickmark.png";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import greytickmark from "../../../assets/images/greytickmark.png";
import Survey from "../../../survey/Survey";
import { useSelector, useDispatch } from 'react-redux';
import userInfo from "../../../../src/redux/action";
import survey_services from "../../../survey/survey_services";
import ExpandableDiv from "../../components/expandableview/ExpandableDiv";
import ViewAllModal from "../../components/modalview/BadgesModal";

const CertificateTabs = (props) => {
    const [show, setShow] = useState(false);
    const [disableCertificate, setDisableCertificate] = useState(false);
    const [showEvalution, setShowEvalution] = useState(false);
    const [feedBackCompleted, setFeedbackCompleted] = useState(false);
    const [feedbackActive,setFeedbackActive] = useState(false);
    const [feedbackData, setFeedbackData] = useState();
    const [loading, setLoading] = useState(true);
    const [completedCourseCount,setCompletedCourseCount] = useState(0);
    const [resultDataLength,setResultDataLength] = useState(0);
    const [resultData,setResultData] = useState([]);
    const [showunits_certificate,setShowUnits_certificate] = useState(0);
    const uerInfo = useSelector(state => state.user_info_reducer.user_info);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [showToast, setShowToast] = useState({
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      });
    const [showAllAchiev, setShowAllAchiev] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("certificate tab useeffect called", props?.resultData, showEvalution)
        setResultData(props?.resultData);
        checkfeedback(props?.resultData);
    }, [props?.resultData,props?.feedBackCompleted]);
    useEffect(()=>{
        certificateStatus();
    },[completedCourseCount,showunits_certificate,props?.feedBackCompleted])
    const checkfeedback = (unitData) => {
        console.log("checkfeedback called")
        var vParams = {
            userid: uerInfo.user_id,
            productid: unitData[0]?.productid,
        };

        survey_services.getfeedback(vParams).then((res) => {
            console.log(res?.data, res?.data?.feedback_id !== "", "feedbackresponsecertificatetabs");
            setFeedbackData(res.data);
            setFeedbackCompleted(res.data?.course_feedback_available === 1 ? res.data?.feedback_id !== "" : true);
            setFeedbackActive(res?.data?.feedback_details !== null && res?.data?.feedback_details !== undefined ? Object.keys(res.data?.feedback_details).length > 0 : false);
            setLoading(false);
            certificateStatus(res?.data);
            let completedCourseCount = 0;
            props?.resultData.forEach((item, i) => {
                let resultDataLength = props?.resultData.length;
                console.log(resultDataLength , completedCourseCount, item.showunits_certificate === 0,"feedBackCompleted")
                if (item?.CourseCompletionStatus.toUpperCase() === "COMPLETED") {
                    completedCourseCount++;
                }
                setResultDataLength(resultDataLength);
                setCompletedCourseCount(completedCourseCount);
                setShowUnits_certificate(item.showunits_certificate);
            });

            // if(res.data?.feedback_id === "" && Object.keys(res.data?.feedback_details).length > 0){
            //     setShowEvalution(props.showEvalution);
            // }else{
            //     setShowEvalution(false);
            // }
        })
            .catch((error) => {
                console.error("Error while fetching Feedback status:", error);
            });
    };
   
    console.log(feedbackActive,"feedbackActive")
    const certificateStatus = () => {
        if (completedCourseCount === 0) {
            console.log("1111111111")
            setDisableCertificate(true)
        }else if(showunits_certificate === 0 && resultDataLength !== completedCourseCount){
            setDisableCertificate(true)
        }else if (!feedBackCompleted && showunits_certificate === 0 && feedbackData?.feedback_available === "1" && feedbackData?.course_feedback_available === 1){
            console.log("3333333333333",feedBackCompleted,showunits_certificate);
            setDisableCertificate(true)
        }
        else if(!feedBackCompleted && resultDataLength === 1 && completedCourseCount === 1){
            setDisableCertificate(true)
        }
        else {
            setDisableCertificate(false);
        }
    }
    function clickHandler() {
        setShow(true);
        document.getElementById("root").className = "no-scroll";
    }

    const handleClose = () => {
        setShow(false);
        document.getElementById("root").className = "";
    }
    const handleEvalution = () => {
        dispatch(userInfo({ ...uerInfo, skip: "yes" }));
        setShowEvalution(true);
        props.showFeedback(false);
    }
    const handleOnClose = () => {
        // checkfeedback(feedbackData);
        setShowEvalution(false);
    }
    const showFeedbackToast = (message) => {
        setShowToast({
          show: true,
          alertClass: 'success',
          status: 'Success',
          message: message,
          changeInToast: new Date(),
        });
    
        setTimeout(() => {
          setShowToast({
            show: false,
            alertClass: '',
            status: '',
            message: '',
            changeInToast: new Date(),
          });
        }, 2000);
      };

    const handleFeedbackCompletion = (status) => {
        console.log("handleFeedbackCompletion called")
        checkfeedback(props?.resultData);
        setFeedbackCompleted(true);
        showFeedbackToast(props?.labels?.LBLFEEDBACKCOMPLETED)
        setFeedbackActive(false);
        certificateStatus();
    }
    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
      };
      const scrollRight = () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollBy({ left: 180, behavior: 'smooth' });
        }
      };
      const scrollLeftBelts = () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollBy({ left: -180, behavior: 'smooth' });
        }
      };
      const onMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
      };
    
      const onMouseLeave = () => {
        setIsDragging(false);
      };
    
      const onMouseUp = () => {
        setIsDragging(false);
      };
    
      const onMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 3; // Adjust the scroll speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
      };
    console.log(feedBackCompleted, "feedback_id")
    return (
        <div className="certificateContWrapp" >
            <ToastMessage show={showToast["show"]} helpers={showToast} />
            {props.gamificationDetails?.length ?
                <div>
                    <div className="pageSubTitle" style={{ marginBottom: "6px" }}> {props.labels.LBLACHIEVS}</div>
                    <div className={styles.achieveSub}>{props.labels.LBLACHIEVALLBELTS} </div>
                    <div className={styles.viewAll}><span onClick={() => setShowAllAchiev(true)} className={styles.viewAllText}>{props.labels.LBLVIEWALLACHIEV}</span></div>
                    <div className={styles.beltsWrapper}>
                    <div className={styles.scrollLeft} onClick={scrollLeftBelts}>

                            <img className={styles.scrollRightIcon} src={require("../../../assets/images/karate/arrow_back.png")} />
                            <div className={styles.verticalLine}></div>
                        
                        </div>
                        <div className={styles.beltsContainter} ref={scrollContainerRef}
                            onMouseDown={onMouseDown}
                            onMouseLeave={onMouseLeave}
                            onMouseUp={onMouseUp}
                            onMouseMove={onMouseMove}>
                            {props.gamificationDetails.map((item, index) => {
                                return (
                                    <ExpandableDiv
                                        key={index}
                                        isExpanded={expandedIndex === index}
                                        toggleExpand={() => toggleExpand(index)}
                                        item={item}
                                    />
                                )
                            })}
                        </div>

                        <div className={styles.scrollRight} onClick={scrollRight}>
                        <div className={styles.verticalLine}></div>

                            <img className={styles.scrollRightIcon} src={require("../../../assets/images/karate/arrow_front.png")} />
                        </div>
                        {/* <div className={styles.scrollRight} onClick={scrollRight}></div> */}
                    </div>
                </div>
                : null}
            <div className="pageSubTitle" style={{ marginBottom: "6px" }}> {props.labels.LBLCERTIFICATE}</div>
            {feedbackData?.feedback_available === "1" && feedbackData?.course_feedback_available === 1 ?
                <div style={{ marginBottom: "15px" }}>{ localStorage.getItem('site_type') === "Accutrain" || props.isModule ? props?.labels?.LBLCOMMODFEEDBACKUNLOCK : props.labels.LBLCOMFEEDBACKUNLOCK  }</div>
                :
                null
            }
            {!loading &&
                <>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: "10px", padding: "5px 0px" }}>
                        {props.allUnitsCompletionStatus ?
                            <img src={tickmark} alt="" />
                            :
                            <img src={greytickmark} alt="" />
                        }
                        <div className={styles.certificateUnitComplText}>{localStorage.getItem('site_type') === "Accutrain" || props.isModule ? props.labels.LBLALLMODULESCOMP !== undefined ? props.labels.LBLALLMODULESCOMP : "All Modules Complete" : props.labels.LBLALLUNITSCOMP !== undefined ? props.labels.LBLALLUNITSCOMP : "All Units Complete"} </div>
                    </div>
                    {feedbackData?.feedback_available === "1" && feedbackData?.course_feedback_available === 1 ?
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px", padding: "5px 0px" }}>
                            {feedBackCompleted ?
                                <img src={tickmark} alt="" />
                                :
                                <img src={greytickmark} alt="" />
                            }

                            <div className={ feedbackActive ? styles.courseFeedbackActive : styles.courseFeedbackInActive} onClick={handleEvalution}>{props.labels.LBLFEEDBACK !== undefined ? props.labels.LBLFEEDBACK : "Feedback"}</div>
                        </div>
                        :
                        null
                    }
                    <button
                        type="button"
                        className="button-style primary-btn"
                        onClick={clickHandler}
                        disabled={disableCertificate}
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <img src={certificateImage} alt="" style={{ paddingRight: "10px" }} />
                        {props.labels.LBLVIEWCERTIFICATE !== undefined ? props.labels.LBLVIEWCERTIFICATE : "View Certificate"}
                    </button>
                </>
            }           
            <CertifatePopUp onClose={handleClose} show={show} labels={props.labels} productDetails={props?.productDetails} resultData={props?.resultData} feedBackCompleted =  {feedBackCompleted} showProductCertificate = {completedCourseCount === resultDataLength && feedBackCompleted } isModule = {props.isModule}/>

            {showEvalution && resultData.length > 0 ?
                <Survey
                    hide={handleOnClose}
                    userId={uerInfo.user_id}
                    surveyId={feedbackData?.feedback_details?.capsule_content_id}
                    feedback_file_name = {feedbackData?.feedback_file_name}
                    labels={props.FeedbackLabels}
                    language_code={uerInfo.language_code}
                    surveyType="feedback"
                    launch_data={feedbackData?.feedback_details?.encref}
                    closeButton={true}
                    handleFeedbackCompletion={handleFeedbackCompletion}
                    languagecode = {props?.resultData[0].languagecode === "en-UK" ? "en-US" : props?.resultData[0].languagecode}
                    productID = {resultData[0]?.productid}
                />
                :
                null
            }
            {showAllAchiev ?
                <ViewAllModal 
                close={() => setShowAllAchiev(false)}
                badges={props?.gamificationDetails} 
                title = {props.labels.LBLACHIEVS}
                /> : null
            }
        </div>
    );
};
export default CertificateTabs;
import React, { Component } from "react";
import "../rptcertificate/rptcertificate.scss";
import CompanyLogo from "../../admin/components/companylogo/companylogo";

const moment = require("moment");

class CCCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = { showPrint: false };
    console.log("check", this.props)
  }

  handleClose = () => {
    this.props.handleClose();
  };

  handlePrint = () => {
    this.setState({ showPrint: false }, function () {
      window.print();
    });
  };

  render() {
    console.log(this.props.productData.CourseCompletionDate ,"productData.CourseCompletionDate")
    const { labels, company_logo } = this.props;
    const logoClassName = "col-md-6 col-sm-6 cert-logowrapper";
    return (
      <div className="reportsCertificate">
      <div
        className="cert-popup "
        onMouseOver={() => {
          this.setState({ showPrint: false });
        }}
      >
        <div className="cert-wrapper">
          <div className="cert-closebtn" onClick={this.handleClose}>
            x
          </div>
          <div
            className="cert-body"
            onMouseOver={(evt) => {
              evt.stopPropagation();
              this.setState({ showPrint: true });
            }}
          >
            {this.state.showPrint ? (
              <div className="cert-printbtn" onClick={this.handlePrint}>
                <img
                  src="../images/print.png"
                  width="100%"
                  height="100%"
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            <div className="cert-logo-ctn row">
              {company_logo ? (
                <CompanyLogo
                  className={logoClassName}
                  imageName={company_logo}
                />
              ) : (
                ""
              )}
            </div>
            <div className="row cert-hdr">
              <span>{labels.LBLCERTOFCOMPLETION}</span>
            </div>
            <div className="row cert-name-hdr">
              <span>{labels.LBLCERTIFYTHAT}</span>
            </div>
            <div className="row cert-uname-value">
              <span className="cert-username">
                {this.props.userData.first_name.charAt(0).toUpperCase() +
                  this.props.userData.first_name.slice(1) +
                  " " +
                  this.props.userData.last_name.charAt(0).toUpperCase() +
                  this.props.userData.last_name.slice(1)}
              </span>
            </div>
            <div className="row cert-crsname-hdr">
              <span>{labels.LBLCRSSUCCESS}</span>
            </div>
            <div className="row cert-crsname-value">
              <span className="cert-coursename">
                {this.props.productData.name ||
                  this.props.productData.description}
              </span>
            </div>
            <div className="row cert-datesign-ctn">
              <div className="col-md-6 col-sm-12 text-center">
                <div className="row cert-date-value">
                  {this.props.productData.CourseCompletionDate !== null &&
                  this.props.productData.CourseCompletionDate !== "" &&
                  this.props.productData.CourseCompletionDate !==
                    "0000-00-00 00:00:00"
                    ? moment(
                        this.props.productData.CourseCompletionDate //.replace(/\s/g,'/')
                      ).format(this.props.userData.dateformat)
                    : ""}
                </div>
                <div className="row cert-date-label">
                  {labels.LBLDATE.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default CCCertificate;

import React from "react";
import "./readvideo.css";
import VideoPlayer from "../videoPlayer/videoplayer";
import FeedsServices from "../../feedService/feedservices";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";

var originalFetch = require("isomorphic-fetch");
var fetch = require("fetch-retry")(originalFetch);

class ReadVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: "",
      user_id: this.props.user_id,
      company_id: this.props.company_id,
      Loading: true,
      is_job_done: this.props.assetItem.is_job_done === "0" ? null : true,
    };
    this.videoPlayerLabels =
      '{"course_engine_meida_lblPlay":"Play","course_engine_meida_lblPause":"Pause","course_engine_meida_lblReplay":"Replay","course_engine_meida_lblStop":"Stop","course_engine_meida_lblMute":"Mute","course_engine_meida_lblUnmute":"Unmute","course_engine_meida_lblFullScreen":"Full Screen","course_engine_meida_lblNormalScreen":"Normal Screen","course_engine_videoNotFound":"Video Not Found"}';
    this.videoPlayerLabels = JSON.parse(this.videoPlayerLabels);
  }
  componentDidMount() {
    if (this.props.assetItem.asset_name) {
      let assetFolder = String(this.props.assetItem.asset_name).split(".");
      if (assetFolder.length > 1) {
        assetFolder.pop();
      }
      // assetFolder = assetFolder.length > 1 ?  : assetFolder;
      // assetFolder.pop()
      let postData = {
        filename: this.props.assetItem.asset_name,
        assettype: "video",
        assetFolder: assetFolder.join(),
      };
      FeedsServices.feedsAPIServicesCall(
        "gets3signedassetsurl",
        "post",
        this.signedUrlResultHandler,
        postData
      );
      if (
        this.props.assetItem.is_job_done &&
        (this.props.assetItem.is_job_done === "0" ||
          !this.props.assetItem.is_job_done)
      ) {
        FeedsServices.feedsAPIServicesCall(
          "getjobs",
          "post",
          this.checkJobStatus,
          this.props.assetItem
        );
      }
      /*this.setState({
                videoUrl : "https://ssifeedsmedia.s3.ap-south-1.amazonaws.com/media/video/11/"+assetFolder+"/playlist.m3u8"
            })*/
    }
  }
  signedUrlResultHandler = (resultData) => {
    this.setState({
      videoUrl: resultData.data.data,
      Loading: false,
    });
  };
  checkJobStatus = (info) => {
    // console.log(info);
    if (info.data.job.length === 0) {
      // let api_url = "feeds/" + this.props.feed_id + "/job";
      // FeedsServices.feedsAPIServicesCall(api_url, "put", this.checkJobStatus, this.props.assetItem);
      // this.setState({
      //     is_job_done: true
      // })
      this.checkStream(this.state.videoUrl);
    } else {
      setTimeout(() => {
        FeedsServices.feedsAPIServicesCall(
          "getjobs",
          "post",
          this.checkJobStatus,
          this.props.assetItem
        );
      }, 2000);
    }
    // if (info.data.jobs.length)
  };
  checkStream = (url) => {
    fetch(url, {
      retryOn: function (attempt, error, response) {
        if (error !== null || response.status >= 403) {
          return true;
        }
      },
    }).then((response) => {
      if (response.status === 200) {
        let api_url = "feeds/" + this.props.feed_id + "/job";
        FeedsServices.feedsAPIServicesCall(
          api_url,
          "put",
          this.checkJobStatus,
          this.props.assetItem
        );
        this.setState({
          is_job_done: true,
        });
      }
    });
  };
  render() {
    let readAssetsContainer =
      this.props.totalAssets > 1 &&
      this.props.totalAssets === 3 &&
      this.props.index === 0
        ? "ssi-feeds-readVideoContainer multiassets threeAssets"
        : this.props.totalAssets > 1
        ? "ssi-feeds-readVideoContainer multiassets"
        : "ssi-feeds-readVideoContainer";
    if (
      this.props.assetItem.is_job_done &&
      this.props.assetItem.is_job_done === "0" &&
      this.state.is_job_done === null
    ) {
      return (
        <div className={readAssetsContainer}>
          <div className="ssi-feeds-readVideoWrapper">
            {/* <ProgressBar label={'Media under processing for streaming'} animated striped variant={'success'} now={100} /> */}
            <div className="text-center process_cover">
              <p>{this.props.mediaProcessing}</p>

              <Spinner animation="grow" variant="success"></Spinner>
            </div>
          </div>
        </div>
      );
    } else if (
      this.state.videoUrl !== "" &&
      !this.state.Loading &&
      this.state.is_job_done
    ) {
      return (
        <div className={readAssetsContainer}>
          {/* <h2>VIDEO {this.state.videoUrl}</h2> */}
          <div className="ssi-feeds-readVideoWrapper">
            <VideoPlayer
              keyvalue={this.props.keyvalue}
              currNoOfAssets={this.props.currNoOfAssets}
              playingstatus={this.props.playingstatus}
              showAssetsViewer={this.props.showAssetsViewer}
              company_id={this.props.company_id}
              mplay={this.props.multiAssetPlayCtrl}
              url={this.state.videoUrl}
              fileName={this.props.assetItem.asset_name}
              propFullScreen={true}
              autoPlay={false}
              propProgressBar={true}
              labels={this.videoPlayerLabels}
            />
          </div>
        </div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

//export default ReadVideo;
const mapStateToProps = (state) => {
  return {
    company_id: state.user_info_reducer.user_info.company_id,
    user_id: state.user_info_reducer.user_info.user_id,
  };
};
export default connect(mapStateToProps, null)(ReadVideo);

import React from "react";
import { connect } from "react-redux";
import SteerCom from "../steeringcommittee";
import IAMutils from "../../../../utils/IAMutils";

class TeamSetup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            permission: props.permission || props.location.state ? IAMutils.userPermission(props.permission || props.location.state.permission) : false
        }
    }
    render() { 
        
        const { ssi_iam_user, allow } = this.props;
        const { permission } = this.state;
        if (!ssi_iam_user) {
            return (
                <SteerCom {...this.props}></SteerCom>
            )
        } else if (ssi_iam_user && permission) {
            if (permission && permission.hasRead && allow) {
                return (
                    <SteerCom {...this.props}></SteerCom>
                )
            } else if (permission && !permission.hasRead && allow) {
                return (<div>
                    <h2>Service Denied</h2>
                </div>);
            } else if (allow === 0) {
                return (<div>
                    <h2>Policy Not activated</h2>
                </div>);
            } else {
                return (<div>
                    <h2>Loading...</h2>
                </div>);
            }

        }

    }
}

const mapStateToProps = (state) => {
    return {
        ssi_iam_user: state.user_info_reducer.user_info.ssi_iam_user

    };
};
export default connect(mapStateToProps, null)(TeamSetup);



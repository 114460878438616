import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { required, email, checkSpace } from "../admin/pages/validate/Validate";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";

class RemoteLaunchForm extends Component {
  renderField = ({
    input,
    label,
    type,
    className,
    meta: { touched, error, warning },
  }) => (
    <div className="form-group">
      <input
        {...input}
        type={type}
        className={`${className} ${input.value ? "complete" : ""}`}
      />
      <label htmlFor={input.name}>{label}</label>
      {touched &&
        ((error && (
          <span className="required-field">{this.props.labels.labels[error]}</span>
        )) ||
          (warning && <span className="required-field">{warning}</span>))}
    </div>
  );

  onChangeHandler = (e) => {
    if (e.target.value) {
      $(e.target).addClass("complete");
    } else {
      $(e.target).removeClass("complete");
    }
  };

  render() {
    const { handleSubmit, submitting, btnStatus, labels } = this.props;
    return (
      <div className="fp-form">
        <form className="form" onSubmit={handleSubmit}>
          <Field
            name="email"
            type="type"
            component={this.renderField}
            label={labels.labels.LBLENTERREGISTEMAILID}
            className="form-field"
            validate={[required]}
          ></Field>
          <div className="form-group btn-ctn">
            <button
              type="submit"
              className={
                "btn login-btn primary-btn btn-block" + (btnStatus ? "disabled" : "")
              }
              disabled={submitting}
            >
              {labels.labels.LBLSUBMIT}
            </button>
          </div>
          <Link to="/" className="fp-back">
            <i className="fas fa-chevron-left"></i>
            <p className="fp-lg-lbl">{labels.labels.LBLBACKTOLOGIN}</p>
          </Link>
        </form>
      </div>
    );
  }
}

RemoteLaunchForm = reduxForm({
  form: "remoteaunchform", // a unique name for this form
})(RemoteLaunchForm);

export default connect()(RemoteLaunchForm);

import React, { Component } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import { HttpInterceptor } from "../auth/HttpInterceptor";
import * as CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";

let hash = "";
export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vSuccessStatus: "",
      validmsg: "",
      btnStatus: false,
      closeFunc: true,
    };
  }

  addPassword = (values) => {
    this.setState({ btnStatus: true });
    if (this.state.closeFunc === true) {
      this.setState({ closeFunc: false });
      hash = CryptoJS.SHA512(values.password);
      hash = Base64.stringify(hash);
      let data = {
        vCode: this.props.paramid,
        password: hash,
      };

      HttpInterceptor.post("resetpassword", data).then(
        (res) => {
          this.setState({ vSuccessStatus: true, validmsg: res.data.data });
          debugger;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  render() {
    const { labels } = this.props;
    return (
      <div>
        {this.props.validate ? (
          <div className="forgot-password-section">
            <div class="card">
              <div class="card-body fp-pad">
                <img src="../images/login-logo.png" alt="" />
                <h3>{labels.labels.LBLRESETPASSWORD}</h3>
                <h5>{labels.labels.LBLPASSWORDCHANGEHERE}</h5>
                <ResetPasswordForm
                  onSubmit={this.addPassword}
                  btnStatus={this.state.btnStatus}
                  labels={labels}
                ></ResetPasswordForm>
                <p
                  className={
                    this.state.vSuccessStatus ? "text-success" : "text-danger"
                  }
                >
                  {this.state.validmsg}
                </p>
              </div>
            </div>
          </div>
        ) : (
            ""
          )}
      </div>
    );
  }
}

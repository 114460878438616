import React,{useEffect} from "react";
import RYSAveragesBar from "../../../components/charts/rys_average_states";
import styles from './RysRecords.module.scss';
import rysrecordsicon from '../../../../assets/images/rysrecordsicon.png';
import style from "./RysCoversation.module.scss"
import DataTable from "react-data-table-component";
import emptyImage from '../../../../assets/images/rateyourstate_emptyst.png';
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import  "./RateYourStateConversation.scss";
import Loader from "../../../components/loader/Loader";
import  "../../../components/reportfilter/containers/CustomFilter.scss"
import {
  exportCSV,
  exportToExcel,
} from "../../../../common_components/rptexport";
import moment from "moment";

const $ = require("jquery");

const RateYourStateConversationPageTwo = (props) => {

    useEffect(() => {
      $('html').scrollTop(0);
    },[]);

    const {
        LBLOF,
        LBLROWSPERPAGE,
        LBLALL,
      } = props.labels.labels;    

    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];

    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };

    const columns = [
      {
        name: props.labels.labels.LBLDATE,
        selector: 'date',
        sortable: true,
        cell: (row) => (
          <div className="rptConCellNormal" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "10px 0px 0px 10px" }}>
            <span className="rle-brdr1" style={{ paddingLeft: "10px" }}>
              {row.date}
            </span>
          </div>
        ),
        format: (row) => new Date(row.date), // Function to format the date
        sortFunction: (a, b) => {
          return new Date(a.date) - new Date(b.date); // Custom sort function for dates
        },
      },
        {
          name: props.labels.labels.LBLRUSHING,
          selector: 'rushing',
          sortable: true,
          cell: (row) => {
            return (
              <div className="rptConCellNormal">
                <span className="rle-brdr">{row.rushing}</span>
              </div>
            );
          },
        },
        {
          name: props.labels.labels.LBLFRUSTRATION,
          selector: 'frustration',
          sortable: true,
          cell: (row) => {
            return (
              <div className="rptConCellNormal">
                <span className="rle-brdr">{row.frustration}</span>
              </div>
            );
          },
        },
        {
          name: props.labels.labels.LBLFATIGUE,
          selector: 'fatigue',
          sortable: true,
          cell: (row) => {
            return (
              <div className="rptConCellNormal">
                <span className="rle-brdr">{row.fatigue}</span>
              </div>
            );
          },
        },
        {
          name: props.labels.labels.LBLCOMPLACENCY,
          selector: 'complacency',
          sortable: true,
          cell: (row) => {
            return (
              <div className="rptConCellNormal" style={{borderRight:"1px solid rgba(0, 0, 0, 0.12)",borderRadius:"0px 10px 10px 0px"}}>
                <span className="rle-brdr">{row.complacency}</span>
              </div>
            );
          },
        },
      ];
      const onExport = (type, id, conditions) => {
        if (props.rysUserData) {
          convertExportData(props.rysUserData,type);
        }
    
      };
      const convertExportData = (rysData, type) => {
        const fileName = props.labels.labels?.LBLRYSSUPCONVRPT;
        let tList = [];
        rysData.map((rItem) => {
          let obj = {};
          let aObj = {};
          let bObj = {};
          let cObj = {};
          let dObj = {};
          let eObj = {};
          obj[props.labels.labels.LBLSUPERVISOR] = props.selDetail.name;
          aObj[props.labels.labels.LBLDATE] = rItem.date;
          bObj[props.labels.labels.LBLRUSHING] = rItem.rushing;
          cObj[props.labels.labels.LBLFRUSTRATION] = rItem.frustration;
          dObj[props.labels.labels.LBLFATIGUE] = rItem.fatigue;
          eObj[props.labels.labels.LBLCOMPLACENCY] = rItem.complacency;
          tList.push(Object.assign({},obj, aObj, bObj, cObj,dObj,eObj));
          return null;
        });
        let data = tList;
        console.log("tlist", data);
        if (type === "csv") {
          exportCSV(data, fileName,props.dateformat);
        } else if (type === "xlsx") {
          exportToExcel(data, fileName,props.dateformat);
        }
      };
      
      const goBackHandler = () => {
        props.goBackHandler();
      }
      const customStyles = {
        headCells: {
          style: {
            '&:nth-child(1)': {
              left: "5px"
            },
          }
        },
      };
      
    return (
        <div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
              <h3 className="page-title">
                <i
                    className="fas fa-arrow-left cuBackBtn"
                    aria-hidden="true"
                    onClick={goBackHandler}
                ></i>
                {props.labels.labels.LBLRYSCONVERSATATION}
              </h3>
              <div style= {{marginBottom:"8px"}} role="group">
              <span
                id="CSTFilter_spinner"
                style={{
                  display: "none",
                  marginRight: "10px",
                }}
              >
                <Loader></Loader>
              </span>
              <button
                id="btnGroupDrop1"
                type="button"
                className="dropdown-toggle exportBtn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-mail-forward" aria-hidden="true">
                  &#xf064;
                </i>
                <span style={{padding: '5px 10px'}}>{props.labels.labels?.LBLEXPORT}</span>
              </button> 
              <div
                className="dropdown-menu  dropdown-menu-right"
                aria-labelledby="btnGroupDrop1"
              >
                  <span
                    className="dropdown-item exportCsvXlsv"
                    onClick={() => onExport("csv")}
                  >
                    {props.labels.labels?.LBLEXPORTTOCSV}
                  </span>

                <span
                  className="dropdown-item exportCsvXlsv"
                  onClick={() => onExport("xlsx")}
                >
                  {props.labels.labels?.LBLEXPORTTOEXCEL}
                </span>
              </div> 
              </div>              
            </div>
            <hr className="head-line" />
            <div className={styles.RysRecordsCtn}>
                <div className={styles.RysRecordsInnerCtn}>
                    <div className={styles.RysRecordsInnerLeftCtn}>
                        <div style={{ display: "flex" }}>
                            <div>
                                <h1 className={styles.title}>
                                    {props.labels.labels.LBLRANK}
                                </h1>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span className={styles.totalRecordCount} style={{ paddingLeft: "10px" }}>{props.selDetail.rank}</span>
                                </div>
                            </div>
                            <div style={{ paddingLeft: "10%" }}>
                                <h1 className={styles.title}>
                                    {props.labels.labels.LBLSUPNAME}
                                </h1>
                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                    <span className={styles.totalSupervisorCount} style={{ paddingLeft: "0px" }} title={props.selDetail.name}>{props.selDetail.name}</span>
                                </div>
                            </div>
                        </div>
                        <hr className={styles.horizontalLine} />
                        <h1 className={styles.title}>
                            {props.labels.labels.LBLTOTALCONBYSUP}
                        </h1>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={rysrecordsicon} alt='' />
                            <span className={styles.totalRecordCount}>{props.selDetail.totCon}</span>
                        </div>
                    </div>
                    <div className={styles.verticalLine}></div>
                    <div className={styles.RysRecordsInnerRightCtn}>
                        <RYSAveragesBar
                            {...props}
                            data={props.rysUserData}
                            count={props.labels.labels.LBLCOUNT}
                            date={props.labels.labels.LBLDATE}
                            onElementClick={false}
                            currentPage="rysConversation"
                            lables={props.labels}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
            </div>
            <h1 className={style.leadertitle}>
                {props.labels.labels?.LBLRYSDETAILS}
            </h1>
            <DataTable
                columns={columns}
                data={props.rysUserData}
                className="datatable-list rysConversation"
                defaultSortField="date"
                pagination
                noDataComponent={<EmptyState image={emptyImage} text2={props.labels.labels.LBLRYSREPORTEMPTYTXT} />}
                paginationComponentOptions={paginationComponentOptions}
                customStyles={customStyles}
            />
        </div>
    );
};

export default RateYourStateConversationPageTwo;
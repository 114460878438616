import React, { Component } from "react";
//import { Modal, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Loader from "../../components/loader/Loader";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import orderBy from "lodash/orderBy";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import styles from "./grouplist.module.scss";
import { DataGrid} from '@mui/x-data-grid';
import UserManagementHeader from "../../../common_components/datatable/UserManagementHeader";

const $ = require("jquery");

export default class MemberListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromAdd: false,
      groupParsedData:[],
      users: [],
      add_users: [],
      loading: true,
      defaultPage: 1,
      searchValue: "",
      roleFilter: 0,
      pageState: {
        isLoading: false,
        data: [], // rows
        total: 0 // total count of data grid we need to show
      },
      paginationModel: {
        page: 0,
        pageSize: 10,
      },
      rowSelectionModel: [],
      visibleRowsSelected: false,
      filteredValue:[],
      sortModel: [],
    };
  }
  componentDidMount() {
    let {paginationModel} = this.state;
    this.setState({
      loading: true,
    },
      () => { $('.MuiAutocomplete-input').attr('readOnly', 'true'); });
    if (this.props.showAssignList) {
      this.setState({
        fromAdd: true,
        users: this.props.non_grouped_users,
        pageState: { isLoading: false, data: this.props.non_grouped_users.slice((paginationModel.page * paginationModel.pageSize), ((paginationModel.page + 1) * paginationModel.pageSize)), total: this.props.non_grouped_users.length },
        loading: false,
        filteredValue: this.props.non_grouped_users,
        rowSelectionModel: this.props.add_users
      },
        () => { $('.MuiAutocomplete-input').attr('readOnly', 'true'); });
    } else {
      this.setState({
        fromAdd: false,
        users: this.props.grouped_users,
        pageState: { isLoading: false, data: this.props.non_grouped_users.slice((paginationModel.page * paginationModel.pageSize), ((paginationModel.page + 1) * paginationModel.pageSize)), total: this.props.non_grouped_users.length },
        loading: false,
        filteredValue: this.props.non_grouped_users,
        rowSelectionModel: this.props.add_users
      },
        () => { $('.MuiAutocomplete-input').attr('readOnly', 'true'); });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.pageState.data !== this.state.pageState.data ||
      prevState.rowSelectionModel !== this.state.rowSelectionModel
    ) {
      this.handleVisibleRowsSelection();
   }
  }
  handleVisibleRowsSelection = (e) => { 
    //e.target = {name : this.state.rowSelectionModel};
    this.props.setSelectedUsers(this.state.rowSelectionModel);
    let totalavailableRows =  this.state.pageState.data.length;
    if (this.state.pageState.data.length > 0) {
      let selectedRowCount = 0;
      this.state.pageState.data.forEach(val => {
        if (this.state.rowSelectionModel.includes(val.user_id)) {
          selectedRowCount++;
        }
      });

      let totalSelectedRows = selectedRowCount ;
      if(totalavailableRows === 0) {
        this.setState({visibleRowsSelected:false})
      } else if (totalavailableRows === totalSelectedRows  && this.state.rowSelectionModel.length !== this.state.users.length ) {
        this.setState({visibleRowsSelected:true,
          add_users : this.state.rowSelectionModel
        })
      } else {
        this.setState({visibleRowsSelected:false})
      }
    }
  }
  handleSortModelChange = (sortModel) => {
    console.log("sortModelstart",sortModel)
    // if (sortModel?.length > 0) {
      this.setState({sortModel:sortModel});
      const field = sortModel[0]?.field ;
      let direction = sortModel[0]?.sort ;
      let sortedData = [...this.state.filteredValue];
       if(direction === undefined) {
        sortedData = [...this.state.filteredValue];
       }
       console.log("sortedData",sortedData)
       
       sortedData.sort((a, b) => {
        const valueA = a[field] === null ? "" : a[field]; // Convert null to empty string
        const valueB = b[field] === null ? "" : b[field]; // Convert null to empty string
        if (direction === 'asc') {
            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB);
            } else {
                return valueA - valueB;
            }
        } else {
            if (typeof valueB === 'string') {
                return valueB.localeCompare(valueA);
            } else {
                return valueB - valueA;
            }
        }
    });
    
      console.log("sortedData",sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)))
      this.setState({ pageState : { isLoading: false, data: sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)), total: sortedData?.length },groupParsedData:sortedData });
    // }
    console.log("sortModel end",sortModel[0]?.sort)
  };
  searchUser = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };
  roleFilter = (e, i) => {
    let roleFilter = i.role_id; 
    this.setState({
      roleFilter: roleFilter,
      rowSelectionModel: [] 
    });
  };
  handlePageChange = (pageValue) => {
    this.setState({paginationModel: pageValue}, ()=> {
      //this.searchUser();
      this.handleSortModelChange(this.state.sortModel);
    });
  }
  tableHeader = () => {
    return (
      <div>
      <div className={styles.tableFilter}>
        <div className={styles.filterWthLabel}>

            <label className="label" style={{marginTop:"5px"}}><b>{this.props.labels.labels.LBLROLE}</b></label>

          <Autocomplete
            className={styles.filterDrpdwn}
            aria-required="true"
            required
            disableClearable
            options={this.props.roles}
            getOptionLabel={(option) => {
                return this.props.labels.labels[option.role_name] || option.role_name;
            }}          
            value={
              this.state.roleFilter !== null
                ? this.props.roles.find((option) => option.role_id === this.state.roleFilter)
                : null
            }            
            onChange={this.roleFilter}
            renderOption={(option) => {
                return <div style={{ fontSize: "14px" }}>{this.props.labels.labels[option.role_name]|| option.role_name}</div>;
            }}
            renderInput={(params) => (
              <div>
                <TextField
                  {...params}
                />
              </div>
            )}
          />
        </div>
        <input
          type="text"
          className={`input-field ${styles.addNewGroupInput}`}
          placeholder={this.props.labels.labels.LBLSEARCHBYNAMEEMAIL}
          value={this.state.searchText}
          onChange={this.searchUser}
        />
      </div>
      {this.state.rowSelectionModel.length > 0 ?
        <UserManagementHeader
          currentPageSelected = {this.state.rowSelectionModel.length} 
          selectedRowsCount = {this.state.rowSelectionModel.length}
          labels = {this.props.labels.labels}
          currentPageLabel = {this.props.labels.labels.LBLUSERSSELECTED !== undefined ? this.props.labels.labels.LBLUSERSSELECTED :"Users selected"}
          handleClear = {this.handleClear}
          selectedRows = {null}
          dropObj = {null}
          selectAll={this.props.labels.labels.LBLSELECTALLUSERS !== undefined ? this.props.labels.labels.LBLSELECTALLUSERS.replace("$$$","") : "Select All Users"}
          currentPage = "groups"
          showDelete={true} 
          handleDelete = {() => this.openDeleteModal1()}
         // deleteDisable = {this.state.disableMultiDelete}
          handleRowChange={this.handleSelectAll}
          visibleRowsSelected = {this.state.visibleRowsSelected}
          visiblerowsselectedLabel = {this.props.labels.labels.LBLALLUSERSSELECTED !== undefined ? this.props.labels.labels.LBLALLUSERSSELECTED : "All users on this page are selected."}
        /> 
       :
        null
      }
      </div>
    );
  };
  handleClear = () => {
    this.setState({
      //toggleClearRows: !this.state.toggleClearRows,
      // groupId: [],
      // selectAllEnabled:false
      rowSelectionModel: []
    });
  }
  openDeleteModal1 = () => {
    this.setState({
      showDel: true,
      delGrpId:this.state.rowSelectionModel
    });
  };
  handleSelectAll = () => {   
    console.log("1111==>",111)
    let filteredUsers;
    let {users, filteredValue,roleFilter,searchValue}= this.state;
    if(roleFilter!=0){
      filteredValue = users.filter((item) => item.role.indexOf(roleFilter)>-1 );
      filteredValue = filteredValue;
    }
///
filteredUsers = users;
let unAssignList = this.props.showUnAssignList;
if(unAssignList){
 filteredValue = filteredUsers.filter((item, i) => {
    if (
      this.state.fromAdd ||
      (this.props.groupInfo &&
        this.props.groupInfo.group_id === item.group_id)
    ) {
      return item;
    }
  });
  filteredUsers = filteredValue;
}

if (roleFilter === 0) {
  filteredValue = filteredUsers.filter((item) => {
    return (
      (item.name &&
        (item.name.includes(searchValue) ||
          item.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
      (item.email &&
        (item.email.includes(searchValue) ||
          item.email.toLowerCase().includes(searchValue.toLowerCase())))
    );
  });
} else {
  filteredValue = users.filter((item) => {
    if (
      (item.name &&
        (item.name.includes(searchValue) ||
          item.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
      (item.email &&
        (item.email.includes(searchValue) ||
          item.email.toLowerCase().includes(searchValue.toLowerCase())))
    ) {
      if (roleFilter !== "") {
        if (item.role && item.role.indexOf(roleFilter) !== -1) {
          return true;
        }
      } else {
        return true;
      }
    }
    return null;
  });
}

///

    const userId = filteredValue.map((item) => item.user_id );
    this.setState({rowSelectionModel: userId})
  }
  render() {
    const { users, searchValue, roleFilter } = this.state;
    const {pageState, paginationModel, rowSelectionModel} = this.state;
    const {
      LBLCHOOSEUSERSTOASSIGN,
      LBLCHOOSEUSERSTOUNASSIGN,
      LBLSELECTUSERS,
      LBLEMAIL,LBLUSERS,
      LBLOF,
      LBLROWSPERPAGE,
      LBLNORECORDS,
      LBLALL,
    } = this.props.labels.labels;

    let tableHeader = this.tableHeader();
    let filteredUsers = [];
    if (users && users.length > 0) {
      if (roleFilter === 0) {
        filteredUsers = users.filter((item) => {
          return (
            (item.name &&
              (item.name.includes(searchValue) ||
                item.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
            (item.email &&
              (item.email.includes(searchValue) ||
                item.email.toLowerCase().includes(searchValue.toLowerCase())))
          );
        });
      } else {
        filteredUsers = users.filter((item) => {
          if (
            (item.name &&
              (item.name.includes(searchValue) ||
                item.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
            (item.email &&
              (item.email.includes(searchValue) ||
                item.email.toLowerCase().includes(searchValue.toLowerCase())))
          ) {
            if (roleFilter !== "") {
              if (item.role && item.role.indexOf(roleFilter) !== -1) {
                return true;
              }
            } else {
              return true;
            }
          }
          return null;
        });
      }
    } 

    const customSort = (rows, field, direction) => {
      const handleField = (row) => {
        if (
          row[field] &&
          row[field] !== 1 &&
          row[field] !== 0 &&
          typeof row[field] !== "boolean"
        ) {
          return row[field].toString().toLowerCase();
        }
        return row[field];
      };

      return orderBy(rows, handleField, direction);
    };

    let data = filteredUsers.filter((item, i) => {
      if (
        this.state.fromAdd ||
        (this.props.groupInfo &&
          this.props.groupInfo.group_id === item.group_id)
      ) {
        return item;
      }
    });
    pageState.data = data.slice(paginationModel.page * paginationModel.pageSize,paginationModel.pageSize * (paginationModel.page  +1));
    pageState.total = data.length; 
    const columns = [
      {
        field: 'name',
        headerName: LBLUSERS?LBLUSERS:"User",
        sortable: true, 
        width: 200,
        renderCell: (row) => {
          let user_id = row.row.user_id.toString();
          return (
          <div className={""}>
             <span>{row.row.first_name !== null ? row.row.first_name : ""} {row.row.last_name !== null ? row.row.last_name : ""}</span>
           </div>)
        }
      },
      {
        field: 'email', 
        headerName: LBLEMAIL, 
        sortable: true, 
        // width: $('.main-pad').width() / 4,
        width:300,
          renderCell: (row) => {
            return (
              <div className={""}>
                {row.row.email}
              </div>
              )            
          }
        } 
    ];
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    
    return (
      <div className=" grp-fxd-chkbox">
        {!this.state.loading ? (
          <div className={styles.grpFltr}>
            {this.props.groupInfo !== null &&
              this.props.showUnAssignList === true ? (
              <p>
                {LBLCHOOSEUSERSTOUNASSIGN}{" "}
                <b>{this.props.groupInfo.group_name}</b>.
              </p>
            ) : (
              <p className="pageSubTitle ">
                {LBLCHOOSEUSERSTOASSIGN}{" "}
                <b>
                  {this.props.groupInfo ? this.props.groupInfo.group_name : ""}
                </b>{" "}
              </p>
            )}  
             {tableHeader}
            {data?.length>0?
            <> 
            <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                onSortModelChange={this.handleSortModelChange}
                // initialState={{
                //   sorting: {
                //     sortModel: [{ field: 'group_name', sort: 'asc' }],
                //   },
                // }}
                  style={{ border: 'none' }}
                  //className="cust-data-grid"
                  autoHeight
                  checkboxSelection
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = data.rows.filter((row) =>
                      selectedIDs.has(row.id),
                    );
                    console.log("VVVVV == >",selectedRows)
                     
                  }}
                  //disableRowSelectionOnClick
                  getRowId={(row) => row.user_id} // key value of the row item
                  columns={columns}
                  //onSelectionModelChange={itm => console.log(itm)}
                  onCheck={this.props.onCheck}
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  pageSizeOptions={[10, 15, 20, 25, 30]}
                  pagination
                  paginationMode="server"
                  paginationModel={paginationModel}
                  onPaginationModelChange={(pageValue) => this.handlePageChange(pageValue)}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    this.setState({ rowSelectionModel: newRowSelectionModel })
                  }}
                  rowSelectionModel={rowSelectionModel}
                  keepNonExistentRowsSelected
                  hideFooterSelectedRowCount
                  disableColumnMenu
                  slotProps={{
                    pagination: {
                      labelRowsPerPage: LBLROWSPERPAGE + ":"
                    }
                  }}
                  sx={{
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },          
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    }
                 }}
                /> </>
                :<div style={{ marginTop: "5%" }}><EmptyState image={emptyImage} text2={LBLNORECORDS} /></div>
}
          </div>
        ) : (
          <Loader></Loader>
        )}
      </div>
    );
  }
}

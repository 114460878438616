import React from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";  
import "date-fns"; 
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Form } from "react-bootstrap";
import "./steercomconfiguration.scss"; 
import UsersService from "./../user-list/UsersService"; 
import orderBy from "lodash/orderBy";
import {Checkbox,Radio,RadioGroup,MuiThemeProvider } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel'; 
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import Skeleton from "react-loading-skeleton";
import emptyImage from '../../../assets/images/toolboxtalks_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
const $ = require("jquery"); 
class UserAssign extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },  
      showAll:true,
      openalert:false,
      loading:true, 
      assignedAllUsers:[],
      selectedUsers:[],
      searchValue: "",
      userlist:[],
      selectProps:[],
      assignedUsers:false,
      showtime: 3000,
    };
  }
 
  componentDidMount() { 
    this.getUsers(); 
  }
  componentDidUpdate() {
    $("input[name='select-all-rows']").parent().css({"display":"none"});
  }
  getUsers = () => {
    UsersService.getUsers({ active: true })
      .then((res) => {
        this.sortResults(res.result, "name", true); 
       let loc_userlist=[], userlist = res.result;
       userlist.map((item)=>{
        if(item.active){
          loc_userlist.push(item);
        }
        return null;
       });
        userlist = loc_userlist;
        this.setState({
          userlist: userlist  
        },
        function(){
          setTimeout(() => {
            this.getSteercomUsers()
          }, 1000) 
        }
       ) 
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  getSteercomUsers=async(userlist)=>{
    let result, result_all = await SteercomServices.getStreercomUsers();
    result_all = result_all.result;
    result = result_all[0][0].users==null?[]:JSON.parse(result_all[0][0].users);
    if( result.users)
    result = result.users;
    let assigned_members = result_all[1];
    this.setAssignedUsers(result, assigned_members );
    this.setState({ 
      assignedAllUsers:result 
 })
  } 
  setAssignedUsers=(assignedUsers,assigned_members,mode)=>{
    let {userlist} = this.state; 
    let assignedAllUsers=[];
   if(mode==1){
       if(!assignedUsers){
         assignedUsers = this.state.assignedAllUsers;
       }
       if(!assigned_members){
         assigned_members = this.state.assigned_members;
       }
   }
    userlist.map((item,idx)=>{
       item.isAssigned=false;
       assignedUsers.map((itm)=>{
          if(itm.user_id==item.user_id){
           item.isAssigned=true;
           assignedAllUsers.push(item)
           if(assigned_members){
             let x = assigned_members.filter(im=>im.user_id==itm.user_id);
             if(x.length>0){
               item.isAssociated=true;
             }
            }
          } 
          return null;
       }); 
       return null;
    });
     
   this.setState({
       selectedUsers : assignedAllUsers,
       userlist:userlist,
       assigned_members:assigned_members,
       loading:false
  })
} 
handleChange=(items,status)=>{
  let {userlist,selectedUsers} =this.state;
  status = status==1?false:true;
  userlist.map((item,idx)=>{
    if(items.user_id == item.user_id){
      item.isAssigned=status;
      if(status){
        selectedUsers.push(item);
      }else{
        let i= selectedUsers.findIndex(item=>item.user_id == items.user_id);
        selectedUsers.splice(i,1);
      }
    } 
    return null;
  });
  this.setState({ 
    userlist:userlist ,
    selectedUsers:selectedUsers
})
} 

  saveSteercomUsers=async()=>{
    let assignedUsers = this.state.selectedUsers;
    let result;
    if(assignedUsers.length>0){
      let filteredUsers = this.filteringUsers(assignedUsers);
      result = await SteercomServices.saveSteercomUsers(filteredUsers); 
      if(result.status=="SUCCESS"){
        this.showAlert("success", this.props.labels.labels["LBLADDUSERSSUCCESS"],3000);
        this.getSteercomUsers();
      }
    }else{
        this.showAlert("warning", this.props.labels.labels["LBLNOUSERS"],3000);
    } 
  }
  filteringUsers = (assignedUsers)=>{
    let allusers={},users=[], userss={}
    assignedUsers.map((item)=>{
      userss= {
        "user_id": item.user_id , 
        }
        users.push(userss)
        return null;
    });
    allusers = {users};
    return allusers;
  }
  sortResults = (arr, prop, asc) => {
    arr.sort(function (a, b) {
      if (asc) {
        return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
      } else {
        return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
      }
    });
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  checkTemplatewithPlan=(teamSuccessFactorList)=>{
    let isAssociated=false ;//, teamSuccessFactorList = this.state.teamSuccessFactorList;
    if(teamSuccessFactorList)
    teamSuccessFactorList.map((item,i)=>{
      if(item.team_member_id!=null){
        isAssociated=true;
      }
      return null;
    });
    return isAssociated;
  }

 resetAssignedUsers=()=>{ 
  let assignedAllUsers = this.state.assignedAllUsers;
  this.setAssignedUsers(assignedAllUsers);
 }
 handleUserChange=(event)=>{
    let assigned = event.target.checked;
     this.setState({
        assignedUsers:assigned
     })
 }
 showAlert = (messageType, message) => {
  this.setState({
    openalert: true,
    messageType: messageType,
    message: message
  });
};
handleClose = () => {
  this.setState({ openalert: false });
};
handleAssignChange= (event) =>{
let val = event.target.value;
  this.setState({
    showAll: (val=='1')?true:false,
    assignedUsers:(val=='2')?true:false,
  });
}
 searchUser = (e) => {
  let search_text= e.target.value;
  $("#searchclear").css({visibility:"hidden"});
  if (search_text.length > 0) {
    $("#searchclear").css({visibility:"visible"});
  }
    this.setState({
      searchValue: e.target.value,
    });
  };
 
  tableHeader = () => {
    return (
      <div className="tabelCtn"> 
        <div  className="cls_user_check" style={{ float: "left !important", textAlign: "left !important" }}>
        <MuiThemeProvider><FormControl> 
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            
            <FormControlLabel   onClick={this.handleAssignChange} value="1" checked={this.state.showAll?true:false} 
            control={<Radio color="primary"/>} label={this.props.labels.labels.LBLALLUSERS} />
            <FormControlLabel onClick={this.handleAssignChange} value="2" checked={this.state.showAll?false:true} 
            control={<Radio color="primary" />} label= {this.props.labels.labels.LBLUSERASSIGNED} />  
          </RadioGroup>
        </FormControl></MuiThemeProvider>
        </div>  
        <div >
          {/* <span class="cls_search_icon"><i class="fa fa-search"></i></span> */}
          <input
            style={{width:"260px"}}
            type="text"
            id="user_search" 
            className="input-field"
            placeholder={this.props.labels.labels.LBLSEARCH}
            value={this.state.searchValue}
            onChange={this.searchUser}
           />
        </div>
         
      </div> 
    );
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (
        row[field] &&
        row[field] !== 1 &&
        row[field] !== 0 &&
        typeof row[field] !== "boolean"
      ) {
        return row[field].toString().toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  filterUsers = () => {
    const { userlist, searchValue,assignedUsers, selectedUsers} = this.state;
    let filteredUsers=[],userData=userlist;
    if(assignedUsers){
        userData = selectedUsers;
    }
    if (userData && userData.length > 0) {
      filteredUsers = userData.filter((item) => { 
        if (
          (item.name &&
            (item.name.includes(searchValue) ||
              item.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
          (item.email &&
            (item.email.includes(searchValue) ||
              item.email.toLowerCase().includes(searchValue.toLowerCase())))   
        ) {
            return item;
        }
        return null;
      });
    }
    return filteredUsers
  }
   tt=()=>{
   return <Checkbox label='My checkbox' 
    style={{color: '#1190db'}}
    iconStyle={{fill: 'white'}}/>
  }

  render() {
    const {
        LBLNAME, LBLACTIVE,LBLSTATUS, LBLEMAIL,LBLINACTIVE,LBLTEAMNORECORDS,
        LBLROWSPERPAGE,LBLOF,LBLALL,LBLRESET,LBLSAVE, LBLLEGUNSELECT,
    } = this.props.labels.labels;
    let {selectedRows}= this.state;
    let horizontal = "top",
    vertical = "center";
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    let userAllData, tableHeader;
    tableHeader = this.tableHeader();
    userAllData = this.filterUsers();
    let columns = [ 
        {
          name: LBLNAME,
          selector: "name",
          sortable: true,
          compact: true,
          width: "40%",
          cell: (row) => {
            return (
              <div style={{display:"flex", overflow: "unset"}}>
              <div
                  style={{ 
                    border: "0px solid",
                    display: "flex", 
                  }}
                >
                  <div className="userNameBorderColor"
                  ></div>
                    <div
                      style={{ display: "inline-block", paddingLeft: "10px" }}
                    >
                      <label
                        class={
                          row.isAssociated?"check-lbl check-newassigned":row.isAssigned
                            ? "check-lbl"
                            : "check-lbl check-newassigned1"
                        }
                      >
                        <input
                          type="checkbox"
                          name={"chkg_" + row.id}
                          id={"chkg_" + row.id}
                          data-toggle="modal"
                          onClick={this.handleChange.bind(this,row,row.isAssigned ? 1 : 0)}
                          checked={row.isAssigned} 
                          disabled={row.isAssociated?true:false}
                          style={{ color:row.isAssociated?"red":"green"}}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  
                  
                </div>
              <div data-toggle="tooltip" title={row.name}>
                <span className="rle-brdr"
                style={{color: (row.active === false || row.active === 0)?"#FF5959":
                row.isAssociated==true?"#716B6B":"#716B6B",lineHeight:"30px" }}
                >{row.name.length<40?row.name:row.name.substring(0, 40) + "..."}</span>
                <span className="show-sm-xs">
                </span>
              </div>
              </div>
            );
          },
        },
        {
          name: LBLSTATUS,
          selector: "active",
          width: "10%",
          sortable: true,
          compact: true, 
          cell: (row) => {
            return (
              <div className="vfy-col"> 
                <Form.Check
                  type="switch"
                  label=""
                  title={`${row.active === true || row.active === 1
                    ? LBLACTIVE
                    : LBLINACTIVE
                    }`}
                  className="danger"
                  disabled={true}
                  checked={row.active === true || row.active === 1}
                  icons={false}
                />
              </div>
            );
          },
        }, 
        {
          name: LBLEMAIL,
          selector: "email",
          sortable: true,
          compact: true,
          cell: (row) => {
            return (
              <div
                className="hde-mail-icn"
                data-toggle="tooltip"
                title={row.email}
                style={{marginLeft: "10px"}}
              > 
               <span className="rle-brdr"> {row.email}</span>
                <span className="show-sm-xs"> 
                </span>
                <div className="vfy-col">
                  <span className="show-sm"> 
                  </span>
                </div>
              </div>
            );
          },
        },   
      ];    
      
    return (
        <div className="cls_user_topcontainer"> 
        <Snackbar
          anchorOrigin={{ horizontal, vertical }}
          key={"top-center"}
          open={this.state.openalert}
          autoHideDuration={this.state.showtime ? this.state.showtime : 3000}
          onClose={this.handleClose}
          bodyStyle={{ backgroundColor: "teal", color: "coral" }}
        >
          <Alert
            variant="filled"
            onClose={this.handleClose}
            severity={this.state.messageType}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        {this.state.loading ? (
          <Skeleton height={300} />
        ) : (
        <div>   
          <DataTable
              columns={columns}
              data={userAllData}
              defaultSortField="name"
              className="datatable-list steercom-configuration"
              data-tag="allowRowEvents"
              selectableRowsComponent={Checkbox} 
              onSelectedRowsChange={this.handleChange}
              selectableRowsComponentProps={selectedRows}
              selectableRowSelected = {row=> row.isAssigned}
              checked={row=>row.active === true || row.active === 1}
              defaultChecked
              pagination
              subHeader
              subHeaderComponent={tableHeader}
              sortFunction={this.customSort} 
              noDataComponent={ <span style={{marginTop: "5%"}}> <EmptyState image={emptyImage} text2={LBLTEAMNORECORDS}/></span>  }
              paginationComponentOptions={paginationComponentOptions}
            />
            <div style={{display:"flex"}}>  
              <div className="cls_legend_container">
              <img
                src={"../images/steercom/assigned.jpg"}
                className="img_team_status"
                alt=""
              ></img>
                </div>
              <div className="cls_legend_unselect">{LBLLEGUNSELECT}</div>
            </div>
             <div className="col-sm-12 btm-submit">
                <button
                  type="submit"
                  className="button-style primary-btn"
                  disabled={this.state.submitting}
                  style={{ float: "right"}}  
                  onClick={this.saveSteercomUsers}
                >
                  {LBLSAVE}
                </button>
                <button
                  type="button"
                  className="button-style secondary-btn"
                  id="btnaddCancel"
                  onClick={this.resetAssignedUsers}
                  style={{ float: "right", marginRight: "10px"}}
                >
                  {LBLRESET}
                </button> 
              </div>

        </div>
        )}
        <br></br><br></br>
        </div>
      );
     
  }
}
 
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(UserAssign);

import React from "react";
import UsersService from "../user-list/UsersService";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { TextField } from "@material-ui/core";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import DataTable from "react-data-table-component";
import "date-fns";
import Skeleton from "react-loading-skeleton";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../../components/loader/Loader";
import * as FileSaver from "file-saver";
import { Modal } from "react-bootstrap";
import ReferenceMaterial from "./referencematerials";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./successfactors.scss"; 
import HighchartsReact from "highcharts-react-official";
import Loading from "../../../shared/Loading";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import * as  locales from '@material-ui/core/locale';
import emptyImage from '../../../assets/images/prodlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state"; 
let theme;// = createTheme(  enUS); 
let lang = locales['enUS'];
highchartsGantt(Highcharts); 
require("highcharts/modules/exporting")(Highcharts);
const Excel = require('exceljs');
const $ = require("jquery");
let parentask = "";
var gCnt=0;
class SuccessFactors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGantPopup: false,
      allTeamSuccessFactorList:[],
      loadChart: false,
      successFactorDataChart:[],
      taskDetails: props.taskDetails,
      selectedBaseDate: new Date(),
      steercomSettings: [],//this.props.steercomSettings,
      teamList: [],
      teamlistcnt: false,
      userlist: [],
      teamSFListLoading: false,
      parentask: "",
      show_language: false,
      dateformat: props.dateformat,
      successFactorsList: [],//this.props.steercomSettings.successfactors,
      teamData: {},
      opendialog: false,
      opendialog1: false,
      opendialog3: false,
      firstLoad: true,
      teamRolesList: [],
      teamMembersList: [],
      loadingSection: false,
      isConfirm: false,
      lang_confirmdata: "",
      successFactorData: [],//this.props.successFactorData,
      teamSuccessFactorList: [],
      editingTeamSF: {
        M_NEW: {
          teamId: null,
        },
      },
      reconfirm: false,
      teamTemplate: { "template_id": "default", "template_name": "Default" },
      loadlanguage: { "template_id": "default", "template_name": "Default" },
      email_notification: false,
      notification_enabled: false,
      expandFlag: true,
      base_date: true,
      loading: false,
      showtime: 5000,
      openalert: false,
      isPriorTeam: false,
      showPriorMemebers: true,
      memberIdOpenState: "",
      memberSteercomRoleIdOpenState: "",
      isAssociated: false,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
      showAddMember: true, 
      mobView:false
    }; 
     lang = this.props.language_code.replace('-','');
     lang =  locales[lang];
     theme = createTheme( lang);
  }
  componentWillUnmount() {
    $("#root").css("height", "100%");
    $("#root").css("overflow", "auto");
  }
  componentDidMount() {
    $('#root').scrollTop(0);
    this.getSteercomSettings();
    $("#root").css("height", "100%");
    $("#root").css("border", "0px solid red");
    $("#root").css("overflow", "hidden");
    $(".cls_save_warn").show();
    $(".success-factors").css({ "margin-top": "-15px" });
    $(".cls_resetall").show();
    $(".cls_add_teams").show();
    var header = this.props.labels.labels.LBLSTEERCOMSFSETUP;
    $("#subheader_det2").text(header);
    $("#subheader_det1").css("display", "");
    // this.setContent();
    this.getCompanySettings();
    this.setdateformat();
    //this.getTeamList();
    this.getUsers(); 
    this.callScroll();
    let mobView = this.isMobileView();//navigator.userAgentData.mobile;
    this.setState({ 
      mobView:mobView
    }); 
  }
  isMobileView=()=>{
    let mView=false;
    if(/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone|Opera Mini/i.test(navigator.userAgent)
    || window.screen.availWidth < 750 || document.body.clientWidth < 750){
      mView=true;
    } 
    return mView;
  }
  componentDidUpdate() {
    $('#root').scrollTop(0);
    $(".rdt_TableBody").css({ height: $("#root").height() - 323 + "px" }); // 288 
    $(".cls_chart_main_container").css({ height: $("#root").height() - 103 + "px", width: "103%" }); // 288 
    $(".rdt_TableBody").scroll(function () {
      $("#minipop").hide();
      $("input").blur();
      var scrollLeft = $(this).scrollLeft();
      $(".rdt_TableHead").scrollLeft(scrollLeft);
    });
    $("body").on("onresize", function () {
      $(".divDataTable").css({
        width: $(".main-content").width() - 10 + "px",
        transition: "0.5s",
      });
    });
    this.changeColor();
    var header = this.props.labels.labels.LBLSTEERCOMSFSETUP;
    $("#subheader_det2").text(header);
    
  }
  callScroll=()=>{  
    (function(H) { 
      //internal functions
      function stopEvent(e) { 
        if (e) {
          if (e.preventDefault) {
            e.preventDefault();
          }
          if (e.stopPropagation) {
            e.stopPropagation();
          }
          e.cancelBubble = true;
        }
      }
    
      //the wrap
      H.wrap(H.Chart.prototype, 'render', function(proceed) {
        var chart = this,
          mapNavigation = chart.options.mapNavigation;
    
        proceed.call(chart);
    
        // Add the mousewheel event
        H.addEvent(chart.container, document.onmousewheel === undefined ? 'DOMMouseScroll' : 'mousewheel', function(event) {
    
          var delta, extr, step, newMin, newMax, axis = chart.yAxis[0];
    
          let e = chart.pointer.normalize(event);
          // Firefox uses e.detail, WebKit and IE uses wheelDelta
          delta = e.detail || -(e.wheelDelta / 120);
          delta = delta < 0 ? -1 : 1;
    
          if (chart.isInsidePlot(e.chartX - chart.plotLeft, e.chartY - chart.plotTop)) {
            extr = axis.getExtremes();
            step = (extr.max - extr.min) / 10 * delta;
            newMin = extr.min + step;
            newMax = extr.max + step;
            if (newMin > axis.dataMin && newMax < axis.dataMax) {
              axis.setExtremes(newMin, newMax, true, false);
    
            }
    
          }
    
          stopEvent(event); // Issue #5011, returning false from non-jQuery event does not prevent default
          return false;
        });
      });
    }(Highcharts));
    
  }
  changeColor = () => {
    //$(".cls_dialog .MuiDialog-paperWidthSm").css("background-color","red") 
  }
  setContent = () => {
    setTimeout(() => {
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("aside").addClass("slideOutLeft");
      $("#sidenav").css({ display: "none" });
    }, 1500);
  };
  //this.sortResults(res.result, "name", true)
  sortResults = (arr, prop, asc) => {
    arr.sort(function (a, b) {
      if (a[prop] != null) {
        if (asc) {
          return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
        } else {
          return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
        }
      }
    });
  }
  getCompanySettings = () => {
    HttpInterceptor.get("settings/?language=" + this.props.language).then(
      (res) => {
        this.setState({
          email_notification: res.data.result.templates_status
        });
      }
    );
  };

  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    for (let x in steercomSettings) {
      steercomSettings[x].map((item, index) => {
        if (item.name && item.name.indexOf("LBL") == 0) {
          item.name = this.getLabel(item.name);
        }
        if (item.title && item.title.indexOf("LBL") == 0) {
          item.title = this.getLabel(item.title);
        }
        if (item.description && item.description.indexOf("LBL") == 0) {
          item.description = this.getLabel(item.description);
        }
      });
    }
    let obj = steercomSettings.successfactors.find(v => v.name === 'LBLALL');
    if (obj == undefined || obj.length == 0) {
      steercomSettings.successfactors.unshift({
        active: 1,
        color: "sf_color1",
        description: "",
        id: "0",
        name: "LBLALL",
        title: this.props.labels.labels.LBLALL,
        version: "1"
      });
    }
    this.sortResults(steercomSettings.roles, "steercom_role_name", true);
    this.setState({
      steercomSettings: steercomSettings,
      successFactorsList: steercomSettings.successfactors,
      //successFactorsListYears: steercomSettings.successfactorsyears,
      successFactorData: steercomSettings.successfactors[0],
      successFactorDataChart: steercomSettings.successfactors[0],
    }, this.getTeamList());
  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  getTeamList = () => {
    let apiUrl = "steercomteams";
    this.getTeamListCallback();
  };
  getTeamListCallback = async () => {
    let mainTasksList = [], res = await SteercomServices.getTeamListSetup();
    let teamlist_active = [], teamList = res.result;
    $("#viewmode").show();
    teamlist_active = teamList.filter((content, i) => {
      return content.team_status == 1 || content.team_status == null;
    })
    let filterTeamList = teamlist_active.filter((item) => {
      if (this.props.role_id > 1) {
        if (item.team_administrator == this.props.userId || this.props.userId == item.team_addnl_administrator || this.props.userId == this.state.teamData.created_by) {
          return item;
        }
      } else {
        return item;
      }
      return null;
    });

    this.setState(
      {
        teamList: filterTeamList,
        teamlistcnt: true
      },
      () => {
        if (this.props.location.state && this.props.location.state.teamData) {
          let locteamid = this.props.location.state.teamData.team_id;
          var teamData = locteamid && filterTeamList.find(function (items) {
            return items.team_id == locteamid;
          });
          if (teamData == undefined || teamData == null) {
            teamData = filterTeamList[0];
          } else {
            if (this.props.location.state.teamData.active != undefined) {
              teamData = this.props.location.state.teamData;
            }
          }
          this.setState({
            teamData: teamData,
            successFactorData: this.props.location.state.successFactorData
          })
          this.getTeamMembersList(teamData.team_id);
        } else {
          let team_id = "";
          let backurl = this.props.history.location.state ? this.props.history.location.state.backlinkurl : "";
          if (backurl != "") {
            let urlData = this.props.history.location.state;
            team_id = (urlData.teamData && urlData.teamData.teamId) ? urlData.teamData.team_id : filterTeamList[0].team_id
            this.getTeamMembersList(team_id);
          } else if (filterTeamList[0]) {
            let team_id=filterTeamList[0].team_id;
            if(this.props.history.location.teamData)
            {
              team_id = this.props.history.location.teamData.team_id
            }
            this.getTeamMembersList(team_id);
          }
        }
      }

    );
  };
  onSFChange = (sfData) => {
    this.setState({
      successFactorData: sfData
    });
  };
  onTeamChange = (teamData) => {
    this.setState({
      teamData: teamData,
      isloading:true
    });
  };
  getTeamSFList = () => {
    if (
      this.state.teamData &&
      this.state.teamData.team_id &&
      this.state.successFactorData &&
      this.state.successFactorData.id
    ) {
      this.onTeamChange(this.state.teamData)
      let sfid = this.state.successFactorData.id;
      let teamid = this.state.teamData.team_id;
      this.getTeamSFListCallback(teamid, sfid);
    }
  };
  getTeamSFListCallback = async (teamid, sfid) => {
    let l_sfid = "0";
    let res = await SteercomServices.getTeamSFList(teamid, l_sfid);
    this.setState({
      teamSFListLoading: false,
    })
    let base_date,
      teamSFList = res.result;
    if (teamSFList.length > 0) {
      base_date = teamSFList[0].base_date;
      for (let i = 0; i < teamSFList.length; i++) {
        if (this.state.firstLoad) {
          teamSFList[i].expandFlag = 0;
        } else if (parentask == teamSFList[i].parent_task_display_id) {
          teamSFList[i].expandFlag = 1;
        } else {
          teamSFList[i].expandFlag = 0;
        }
        teamSFList[i].expandFlag = 1;
        if (teamSFList[i].task_name_custom_translation && teamSFList[i].task_name_custom_translation != "") {
          teamSFList[i].task_name = teamSFList[i].task_name_custom_translation;
        }
        if (teamSFList[i].task_description_custom_translation && teamSFList[i].task_description_custom_translation != "") {
          teamSFList[i].task_description = teamSFList[i].task_description_custom_translation;
        }
        teamSFList[i].recommended_start_date = base_date ? new Date(base_date) : new Date();
        //teamSFList[i].recommended_start_date = new Date(base_date);
        teamSFList[i].recommended_start_date.setDate(
          teamSFList[i].recommended_start_date.getDate() +
          teamSFList[i].recommended_start_days
        );
        if (
          teamSFList[i].task_type == 1 &&
          base_date &&
          !teamSFList[i].planned_start_date
        ) {
          teamSFList[i].planned_start_date = new Date(base_date);
          teamSFList[i].planned_start_date.setDate(
            teamSFList[i].planned_start_date.getDate() +
            teamSFList[i].recommended_start_days
          );
        }
        if (teamSFList[i].task_type == 1 && !teamSFList[i].steercom_role_id) {
          teamSFList[i].steercom_role_id = teamSFList[i].default_role_id;
        }
      }
    } else {
      teamSFList = [];
    }
    this.sortByKeyAsc(teamSFList, "parent_task_display_id");
    let loc_teamSFList = [];
    loc_teamSFList = teamSFList.filter((item, idx) => {
      if (sfid == "Y1SF1") {
        if (item.success_factor_id == "Y1SF1") {
          return item;
        }
      } else if (sfid == "Y1SF2") {
        if (item.success_factor_id == "Y1SF2") {
          return item;
        }
      } else if (sfid == "Y1SF3") {
        if (item.success_factor_id == "Y1SF3") {
          return item;
        }
      } else if (sfid == "Y1SF4") {
        if (item.success_factor_id == "Y1SF4") {
          return item;
        }
      } else if (sfid == "Y1SF5") {
        if (item.success_factor_id == "Y1SF5") {
          return item;
        }
      } else {
        return item;
      }
      return null;
    });

    let cnts = 0, milestones = [], mainTasksList = [], userdetails = [], childtasks = [], parenttasks = [], alltasks = [], count = 0;
    let { userlist,allTeamSuccessFactorList } = this.state;

    loc_teamSFList.map((item, idx) => {
      if (item.task_type == 2) {
        milestones.push(item);
      }
      if (item.task_type == 0) {
        childtasks = [];
        parenttasks[count] = item;
        count++;
      } else if (item.task_type == 1 && item.del_flag != '1') {
        childtasks.push(item);
      }
      if (loc_teamSFList.length == (idx + 1) || loc_teamSFList[idx + 1].task_type == 0) {
        this.sortByKeyAsc(childtasks, "recommended_start_days");
        if (childtasks.length > 0)
          alltasks[parenttasks.length - 1] = parenttasks[count - 1];
        childtasks.map((x, y) => {
          alltasks.push(x);
          count++;
        })
      }
      return null;
    });
    let AddedMilestoneSFList = [], lenMile = milestones.length;
    milestones.sort(function (a, b) {
      return a.recommended_start_days - b.recommended_start_days;
    });
    alltasks = this.sortByKeyAsc(alltasks, "recommended_start_days");
   // if(this.state.firstLoad){
      alltasks.map((item, i) => {
        if (item.member_user_id) {
          userdetails = userlist.filter((itm) => itm.user_id == item.member_user_id);
          if(userdetails[0]) 
          alltasks[i].user_status = userdetails[0].active;
        }
        if (item.task_type == 0) {
          mainTasksList.push(item);
        }

        milestones.map(items => {
          if (cnts < lenMile) {
            if (item.task_type == 1 && item.recommended_start_days > milestones[cnts].recommended_start_days) {
              AddedMilestoneSFList.push(milestones[cnts]);
              cnts++;
            }
          }
          return null;
        });
        AddedMilestoneSFList.push(item);
        return null;
      });
      loc_teamSFList = AddedMilestoneSFList;
    let isAssociated = this.checkTemplatewithPlan(loc_teamSFList);

    loc_teamSFList = this.sortByKeyAsc(loc_teamSFList, "recommended_start_days");
    let teamlist = this.state.allTeamSuccessFactorList;
    if(allTeamSuccessFactorList.length==0 || teamlist[0] && loc_teamSFList[0] &&  teamlist[0].team_id !=  loc_teamSFList[0].team_id){
      allTeamSuccessFactorList =  loc_teamSFList; 
    }
    
    this.setState({
      allTeamSuccessFactorList: allTeamSuccessFactorList, 
      teamSuccessFactorList: loc_teamSFList, //teamSFList,//
      base_date: base_date,
      new_base_date: base_date,
      selectedBaseDate: base_date,
      loading: false,
      firstLoad: false,
      teamSFListLoading: true,
      expandFlag: false,
      loadingSection: true,
      isAssociated: isAssociated,
      mainTasksList: mainTasksList,
      milestones: milestones,
      isloading:false
    }); 
  };
  sortByKeyAsc = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  getUsers = () => {
    UsersService.getUsers({ active: true })
      .then((res) => {
        this.sortResults(res.result, "name", true);

        this.setState((state, props) => ({
          userlist: res.result
        }));
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  getTeamMembersList = (team_id) => {
    // let apiUrl = "steercomteammembers/" + team_id ;
    this.getTeamMembersListCallback(team_id);
  };
  getTeamMembersListCallback = async (team_id) => {
    let res = await SteercomServices.getTeamMembersList(team_id);
    let teamData = res.result[0][0];
    let teamMembersList = res.result[1];
    let teamRolesList = [];
    //{ id: "LOCALMANAGEMENT", name: ("LBLLOCALMANAGEMENT") },
    let userdetails, rolesObj = {};
    let { steercomSettings, userlist, email_notification } = this.state;
    let roles = steercomSettings.roles.filter(item => item.template_id == teamData.template_id);
    for (let i = 0; i < roles.length; i++) {
      rolesObj[roles[i].steercom_role_id] = roles[i];
    }
    let addedRoles = {};
    for (let j = 0; j < teamMembersList.length; j++) {
      if (rolesObj.hasOwnProperty(teamMembersList[j].steercom_role_id)) {
        if (
          addedRoles.hasOwnProperty(teamMembersList[j].steercom_role_id) ==
          false
        ) {
          addedRoles[teamMembersList[j].steercom_role_id] = 1;
          teamRolesList.push(rolesObj[teamMembersList[j].steercom_role_id]);
        }
      }
      userdetails = userlist.filter((itm) => itm.user_id == teamMembersList[j].user_id);
      teamMembersList[j].user_status = userdetails[0] ? userdetails[0].active : null;
    }

    if (!this.state.base_date && this.state.teamData.team_id) {
      //this.showAlert("info",this.props.labels.labels.LBLSELECTBASEDATE,5000) 
    }
    let mailactive = null;
    if (steercomSettings.emailsettings) {
      mailactive = ((steercomSettings.emailsettings[0].active && email_notification) &&
        (steercomSettings.emailsettings[0].user_task_assign ||
          steercomSettings.emailsettings[0].user_task_unassign)) ? true : false;
    }

    let selected_language, languages = this.state.steercomSettings.templates;
    languages.map((item, idx) => {
      if (item.template_id == teamData.template_id) {
        selected_language = item;
      }
      return null;
    })

    this.setState(
      {
        teamData: teamData,
        teamMembersList: teamMembersList,
        teamRolesList: teamRolesList,
        expandFlag: false,
        steercomRoles: roles,
        loadlanguage: selected_language,
        teamTemplate: selected_language,
        show_language: false,
        notification_enabled: mailactive
      },
      this.getTeamSFList
    );
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (xfield != undefined) {
        if (
          typeof xfield === "object" &&
          this.props.labels.labels.hasOwnProperty(xfield.label)
        ) {
          return this.props.labels.labels[xfield.label].replace(
            "$$$",
            xfield.val
          );
        } else {
          return xfield + "111";
        }
      } else {
        return "";
      }
    }
  };

  deleteSuccessFactorCallback = (res) => {
    this.getSuccessFactorsList();
  };
  deleteSuccessFactor = (item) => {
    if (isNaN(item.team_member_id) == true) {
      this.setState({
        addSuccessFactor: false,
      });
    } else {
      if (
        this.state.editingTeamSF.hasOwnProperty("M_" + item.task_display_id) ==
        true
      ) {
        let teamSuccessFactorList = this.state.teamSuccessFactorList;
        let editingTeamSF = this.state.editingTeamSF;
        teamSuccessFactorList.map((xitem, index) => {
          if ((xitem.isNewAssigned || 0) == 1) {
            xitem.isNewAssigned = 0;
            //delete editingTeamSF["M_" + xitem.team_member_id]
          }
          return null;
        });

        this.setState({
          teamSuccessFactorList,
          editingTeamSF,
        });
      }
    }
  };
  saveSuccessFactor = (item) => {
    let tmpdata = this.state.editingTeamSF["M_" + item.task_display_id];
    if (
      this.state.editingTeamSF.hasOwnProperty("M_" + item.task_display_id) ==
      false
    ) {
      return false;
    }
    parentask = item.parent_task_display_id;
    let successfactorid = this.state.successFactorData.id;
    if (successfactorid == 0) {
      successfactorid = parentask.substring(0, 5)
    }
    var tmpSuccessFactorData = {
      team_id: this.state.teamData.team_id,
      success_factor_id: successfactorid,
      task_display_id: tmpdata.task_display_id,
      steercom_role_id: tmpdata.steercom_role_id,
      team_member_id: tmpdata.team_member_id,
      planned_start_date: tmpdata.planned_start_date,
      planned_end_date: tmpdata.planned_end_date,
      language_code: tmpdata.language_code,
      template_id: tmpdata.template_id,
      version: tmpdata.version,
    };
    if (tmpdata.task_name != tmpdata.oldtask_name) {
      tmpSuccessFactorData.custom_task_name = tmpdata.task_name + "CSTM"
    }
    if (tmpdata.task_description != tmpdata.oldtask_description) {
      tmpSuccessFactorData.custom_task_description = tmpdata.task_description + "CSTM"
    }
    if (this.checkValidations(tmpSuccessFactorData)) {
      // let apiUrl = "steercomteamsf";
      this.saveSuccessFactorCallback(tmpSuccessFactorData);
    }
  };
  saveSuccessFactorCallback = async (tmpSuccessFactorData) => {
    let res = await SteercomServices.saveSuccessFactor(tmpSuccessFactorData); 
    $("#gantt_btn").hide();
    if (res.status == "SUCCESS") { 
      this.getTeamSFList();
      this.showAlert("success", this.props.labels.labels["LBLSUCCESS"]);
    } else { 
      this.showAlert("success", "ERROR!!");
    }
    setTimeout(() => {
      $("#gantt_btn").show();
    },4000);
  };
  saveSFBaseDate = () => {
    var tmpSFData = {
      team_id: this.state.teamData.team_id,
      success_factor_id: this.state.successFactorData.id,
      base_date: this.state.new_base_date,
    };
    parentask = this.state.teamSuccessFactorList[0].parent_task_display_id;
    if (tmpSFData.team_id && tmpSFData.base_date) {
      // let apiUrl = "steercomteamsfbasedate";
      this.saveSFBaseDateCallback(tmpSFData);
    }
  };
  saveSFBaseDateCallback = async (tmpSFData) => {
    let res = await SteercomServices.saveSFBaseDate(tmpSFData);
    if (res.status == "SUCCESS") {
      this.showAlert("success", this.props.labels.labels["LBLAPPLYBASEDATE"]);
      this.getTeamSFList();
    } else {
      this.showAlert("alert", "ERROR::");
    }
  };
  sendNotification = async () => {
    let { teamSuccessFactorList, userlist, teamData, successFactorData, steercomSettings, email_notification } = this.state;

    let task_labels = {}, notification_users = {};
    let allLabels = this.props.labels.labels;
    let mailactive = null, assigned = false, unassigned = false;
    if (steercomSettings.emailsettings) {
      mailactive = ((steercomSettings.emailsettings[0].active && email_notification) &&
        steercomSettings.emailsettings[0].user_task_assign ||
        steercomSettings.emailsettings[0].user_task_unassign) ? true : false;
      assigned = steercomSettings.emailsettings[0].user_task_assign;
      unassigned = steercomSettings.emailsettings[0].user_task_unassign;
    }
    // let notification_enabled = mailactive;
    let parentId = "", ParentName = "";
    teamSuccessFactorList.map((item, idx) => {
      if (item.parent_task_display_id == item.task_display_id) {
        parentId = item.parent_task_display_id;
        ParentName = this.getParentName(item);
      }
      if (item.member_user_id) {
        let isInsert = true, duplicateParent = Object.keys(task_labels);
        task_labels[item.task_display_id] = item.custom_task_name ? item.custom_task_name : allLabels["LBL" + item.task_display_id];
        task_labels[item.parent_task_display_id] = parentId == item.parent_task_display_id ? ParentName : this.getParentName(item);
        if (item.parent_task_display_id.indexOf("C") > -1) {
          let parentLabels = teamSuccessFactorList.filter((itm) => itm.task_display_id == item.parent_task_display_id);
          task_labels[item.parent_task_display_id] = parentLabels[0].custom_task_name;
        }

      }
      return null;
    });
    //let taskLabels=[];taskLabels.push(task_labels)
    task_labels["LBLASSIGNED"] = allLabels["LBLASSIGNED"];
    task_labels["LBLUNASSIGNED"] = allLabels["LBLUNASSIGNED"];
    task_labels["LBLMODIFIED"] = allLabels["LBLMODIFIED"]?allLabels["LBLMODIFIED"]:"Modified";
    let userdetails = userlist.find((users, idx) => users.user_id == teamData.team_administrator)
    // let userdetails1 = userlist.find((users, idx) => users.user_id == teamSuccessFactorList.member_user_id)
    notification_users = 
      {
        user_id: teamData.created_by,
        teamid: teamData.team_id,
        team_admin: userdetails.email,
        //language_code: userdetails1.language_code,
        success_factor_id: successFactorData.id,
        task_labels: task_labels,
        notify_assigned: assigned,
        notify_unassigned: unassigned
      };
    let res = await SteercomServices.sendNotification(notification_users);
    if (res.status == "SUCCESS") {
      this.showAlert("success", this.props.labels.labels["LBLSUCCNOTIFICATION"], 3000);
    } else if (res.status == "NOUSER") {
      this.showAlert("success", this.props.labels.labels["LBLNOUSRNOTIFICATION"], 3000);
    }
  }
  openConfirmDialog = (val) => {
    this.setState({
      opendialog: true
    })
  }

  handleCloseDialog = (val) => {
    if (val.currentTarget.id == 2) {
      $(".cls_confirm_details").html(this.props.labels.labels["LBLRUSURE"]);
      $(".cls_confirm_details1").hide();
      let isConfirm = this.state.isConfirm ? false : true;
      if (!isConfirm) {
        this.resetTeamSuccessFactorSetup();
      }
      this.setState({
        isConfirm: isConfirm,
        opendialog: isConfirm
      })
    } else {
      this.setState({
        opendialog: false,
        isConfirm: false,
      })
    }
  }
  getConfirm = (val) => {
    if (val.currentTarget.id == 2) {
      if (this.state.isConfirm) {

      }
    }
    //alert(val.currentTarget.id)
    this.setState({
      opendialog: false
    })
  }

  openConfirmDialog1 = (val) => {

    this.setState({
      opendialog1: true
    })
  }

  handleCloseDialog1 = (val) => {
    if (val.currentTarget.id == 2) {
      this.saveSFBaseDate();
    }
    this.setState({
      opendialog1: false
    })
  }
  openConfirmDialog3 = (item, status) => {
    let opendialog3 = false, teamSuccessFactorList = this.state.teamSuccessFactorList;
    for (var i = 0; i < teamSuccessFactorList.length; i++) {
      if (teamSuccessFactorList[i]) {
        if (teamSuccessFactorList[i].isNewAssigned == 1 && teamSuccessFactorList[i].task_display_id != item.task_display_id) {
          opendialog3 = true;
        }
      }
    }
    if (!opendialog3) {
      this.updateEditFlag(item, status);
    }
    this.setState({
      opendialog3: opendialog3,
      updateItem: item,
      updateStatus: status,
    })
  }
  handleCloseDialog3 = (val) => {
    let { updateItem, updateStatus } = this.state;
    if (val.currentTarget.id == 2) {
      this.updateEditFlag(updateItem, updateStatus);
    }
    this.setState({
      opendialog3: false
    })
  }
  handleTemplateChange = (event, values) => {
    let { loadlanguage, teamTemplate, teamSuccessFactorList } = this.state;
    let lang, isAssociated = false;
    if (teamTemplate.template_id != values.template_id) {
      isAssociated = this.checkTemplatewithPlan(teamSuccessFactorList);
      lang = values;
      if (isAssociated) {
        this.openConfirmDialog4(1, lang);
      } else {
        this.openConfirmDialog4(2, lang);
      }
    }
  };
  openConfirmDialog4 = (status, lang) => {
    let opendialog3 = false;
    let lang_confirmdata = "";//this.props.labels.labels.LBLCHNGLANGCONFIRM;
    if (status == 1) {
      lang_confirmdata = this.props.labels.labels.LBLCHNGLANGCONFIRM;
    } else {
      lang_confirmdata = this.props.labels.labels.LBLCHNGLANGRECONFIRM;
    }
    if (!opendialog3) {
      //this.updateEditFlag(item,status);
    }
    this.setState({
      opendialog4: true,
      reconfirm: true,
      lang: lang,
      status: status,
      lang_confirmdata: lang_confirmdata
    })
  }
  handleCloseDialog4 = (val) => {
    let { status, lang_confirmdata, reconfirm, teamData, lang } = this.state;
    let data = {}, opendialog4 = false;
    data.template_id = lang.template_id;
    data.language_code = "en-US";
    data.team_id = teamData.team_id;
    if (status == 1 && val.currentTarget.id == 2) {
      lang_confirmdata = this.props.labels.labels.LBLCHNGLANGRECONFIRM;
      if (reconfirm) {
        opendialog4 = true;
      } else {
        opendialog4 = false;
        this.updateTeamLanguage(data);
      }
    } else {
      opendialog4 = false;
      if (val.currentTarget.id == 2) {
        this.updateTeamLanguage(data);
      }
    }
    this.setState({
      opendialog4: opendialog4,
      reconfirm: false,
      lang_confirmdata: lang_confirmdata
    })
  }
  updateTeamLanguage = async (data) => {
    let { lang } = this.state;
    let res = await SteercomServices.updateTeamLanguage(data);
    let label =this.props.labels.labels["LBLCHNGLNGSUCC"];
    label=label.indexOf("Language")>0?"Team Template changed successfully":label;
    if (res.result.status == "SUCCESS") {
      this.showAlert("success", label);
      this.getTeamSFList();
      this.setState({
        teamTemplate: lang,
        show_language: false
      });
    }
  }
  checkTemplatewithPlan = (teamSuccessFactorList) => {
    let isAssociated = false;//, teamSuccessFactorList = this.state.teamSuccessFactorList;
    if (teamSuccessFactorList)
      teamSuccessFactorList.map((item, i) => {
        if (item.team_member_id != null) {
          isAssociated = true;
        }
        return null;
      });
    return isAssociated;
  }
  resetTeamSuccessFactorSetup = () => {
    var tmpSFData = {
      team_id: this.state.teamData.team_id,
      success_factor_id: this.state.successFactorData.id,
    };
    parentask = this.state.teamSuccessFactorList[0].parent_task_display_id;
    // let apiUrl = "resetsteercomteamsfactivity";
    this.resetTeamSuccessFactorSetupCallback(tmpSFData);
  };
  resetTeamSuccessFactorSetupCallback = async (tmpSFData) => {
    let res = await SteercomServices.resetTeamSuccessFactor(tmpSFData);

    if (res.status == "SUCCESS") {
      this.getTeamSFList();
      this.showAlert("success", this.props.labels.labels["LBLRESETSUCC"]);
    } else {
      this.showAlert("warning", "ERROR::");
    }
  };

  editSuccessFactorsList = async (team_id) => {
    // let apiUrl = "steercomteammembers/" + team_id;
    this.editSuccessFactorsListCallback();
  };
  editSuccessFactorsListCallback = async () => {
    let res = await SteercomServices.getTeamSFList();

    $("#priorSuccessFactors").show();
    let teamData = res.result[0][0];
    let successFactorsList = res.result[1];
    successFactorsList.push({
      team_id: teamData.team_id,
      team_member_id: "NEW",
      user_id: "",
      steercom_role_id: "",
      service_duration: "18",
      task_basedate: teamData.start_date,
      member_enddate: teamData.start_date,
      status: 1, //values.active,
    });
    let editingTeamSF = {
      M_NEW: {
        teamId: teamData.team_id,
        memberId: "NEW",
        memberUserId: "",
        memberSteercomRoleId: "",
        memberDuration: "18",
        memberStartDate: teamData.start_date,
        memberEndDate: teamData.start_date,
        memberStatus: 1,
        memberOpenState: false,
        roleOpenState: false,
        serviceDurationOpenState: false,
        oldMumberUserId: "",
      },
    };
    this.setState({
      teamData,
      successFactorsList,
      editingTeamSF,
      addSuccessFactor: false,
    });
  };

  showAlert = (messageType, message) => {
    this.setState({
      openalert: true,
      messageType: messageType,
      message: message,
    });
  };
  checkValidations = (tmpTeamDate) => {
    let checkval = true;
    if ((tmpTeamDate.steercom_role_id || "") == "") {
      checkval = false;
      $("#span_steercom_role_id_" + tmpTeamDate.task_display_id).show();
    }
    if ((tmpTeamDate.team_member_id || "") == "") {
      checkval = false;
      $("#span_team_member_id_" + tmpTeamDate.task_display_id).show();
    }
    if ((tmpTeamDate.planned_start_date || "") == "") {
      checkval = false;
      $("#span_planned_start_date_" + tmpTeamDate.task_display_id).show();
    }
    if ((tmpTeamDate.planned_end_date || "") == "") {
      checkval = false;
      $("#span_planned_end_date_" + tmpTeamDate.task_display_id).show();
    }
    if (
      tmpTeamDate.planned_end_date &&
      new Date(tmpTeamDate.planned_end_date) <
      new Date(tmpTeamDate.planned_start_date)
    ) {
      this.showAlert("warning", this.props.labels.labels["LBLPSTARTGRTPEND"]);
      return false;

    }
    return checkval;
  };
  updateEditFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    let editingTeamSF = this.state.editingTeamSF;
    for (var i = 0; i < teamSuccessFactorList.length; i++) {
      if (teamSuccessFactorList[i]) {
        if (teamSuccessFactorList[i] && item.task_display_id == teamSuccessFactorList[i].task_display_id) {
          teamSuccessFactorList[i].isNewAssigned = status ? 0 : 1;
          if ((teamSuccessFactorList[i].isNewAssigned || 0) == 1) {
            editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id] = {
              team_id: this.state.teamData.team_id,
              success_factor_id: this.state.successFactorData.id,
              task_display_id: teamSuccessFactorList[i].task_display_id,
              steercom_role_id: teamSuccessFactorList[i].steercom_role_id,
              team_member_id: teamSuccessFactorList[i].team_member_id,
              planned_start_date: teamSuccessFactorList[i].planned_start_date,
              planned_end_date: teamSuccessFactorList[i].planned_end_date,
              oldMemberId: teamSuccessFactorList[i].team_member_id,
              language_code: teamSuccessFactorList[i].language_code,
              template_id: teamSuccessFactorList[i].template_id,
              version: teamSuccessFactorList[i].version,
              oldtask_name: this.getLabel(teamSuccessFactorList[i].task_name),
              oldtask_description: this.getLabel(teamSuccessFactorList[i].task_description),
              task_name: this.getLabel(teamSuccessFactorList[i].task_name),
              task_description: this.getLabel(teamSuccessFactorList[i].task_description),
            };
          } else {
            delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
          }
        } else if (
          status == 0 &&
          (teamSuccessFactorList[i].isNewAssigned || 0) == 1
        ) {
          this.openConfirmDialog3(teamSuccessFactorList, editingTeamSF, i);
          teamSuccessFactorList[i].isNewAssigned = 0;
          delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
        }
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
      editingTeamSF: editingTeamSF,
    }));
  };
  updateExpandFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    for (let i = 0; i < teamSuccessFactorList.length; i++) {
      if (teamSuccessFactorList[i]) {
        if (
          item.task_display_id == teamSuccessFactorList[i].parent_task_display_id
        ) {
          teamSuccessFactorList[i].expandFlag = status ? 0 : 1;
        }
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
    }));
  };
  updateExpandFlagAll = (status, item) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    let expandFlag = this.state.expandFlag ? 0 : 1;
    for (let i = 0; i < teamSuccessFactorList.length; i++) {
      if (teamSuccessFactorList[i]) {
        teamSuccessFactorList[i].expandFlag = status ? 0 : 1;
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
      expandFlag: expandFlag
    }));
  };

  handleSFChange = (event, values) => {
    let {allTeamSuccessFactorList} = this.state;
    let teamSuccessFactors = allTeamSuccessFactorList;
    if(values.id!="0"){
      teamSuccessFactors = allTeamSuccessFactorList.filter(item=>item.success_factor_id == values.id);
    }
    this.setState(
      {
        successFactorData: values,
        teamSuccessFactorList : teamSuccessFactors
      },
      () => {
        this.onSFChange(values)
        this.getTeamSFList() // Temp
      } 
    );
  };
  handleSFChangeChart = (event, values) =>{
    $(".highcharts-scrollbar-thumb").scrollTop = 0; 
    this.showGanttChartPopup(2,values); 
    this.setState({
      successFactorDataChart:  values , 
    }); 
  }
  handleTeamChange = (event, values) => {
    this.getTeamMembersList(values.team_id);
  };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
  handleBaseDateChange = (xyz, date) => {
    this.setState({
      new_base_date: date,
    });
  };
  showLanguage = () => {
    this.setState({
      show_language: true,
    });
  }
  handleDateChange = (xVar, item, mode, date) => {
    let editingTeamSF = this.state.editingTeamSF;
    editingTeamSF["M_" + item.task_display_id][xVar] = date;
    let locitem = editingTeamSF["M_" + item.task_display_id];
    let warnlabel = "LBLRECGRTSTART";
    if (mode == 2)
      warnlabel = "LBLRECGRTEND";

    if (item.recommended_start_date > date) {
      //this.showAlert("warning", this.props.labels.labels[warnlabel]);
    }
    if (locitem.planned_end_date && new Date(locitem.planned_start_date) > new Date(locitem.planned_end_date)) {
      this.showAlert("warning", this.props.labels.labels["LBLPSTARTGRTPEND"]);
    }
    this.setState({
      editingTeamSF,
    });
  };
  handleChange = (xVar, item, id, event, values) => {
    let isMemberChange = id == "team_member_id" ? true : false;
    let editingTeamSF = this.state.editingTeamSF;
    editingTeamSF["M_" + item.task_display_id][xVar] = values[id];
    this.setState({
      editingTeamSF,
      isMemberChange
    });
  };
  handleOpen = (xVar, item) => {
    let editingTeamSF = this.state.editingTeamSF;
    editingTeamSF["M_" + item.task_display_id][xVar] = true;
    this.setState({
      editingTeamSF,
    });
  };
  handleClose = (xVar, item) => {
    this.handleCloseAlert();
    let editingTeamSF = this.state.editingTeamSF;
    if (item && editingTeamSF["M_" + item.task_display_id]) {
      editingTeamSF["M_" + item.task_display_id][xVar] = false;
      this.setState({
        editingTeamSF,
        openalert: false
      });
    }
  };
  handleCloseAlert = () => {
    this.setState({ openalert: false });
  };
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
    return dateformat;
  };
  addSuccessFactor = () => {
    this.setState({
      addSuccessFactor: true,
    });
  };
  closeRefModal = () => {
    this.setState({
      showmodal: false,
    });
  };
  openRefModal = (row) => {
    this.setState({
      showmodal: true,
      resourceData: JSON.parse(row.references || '{"folder_id":998}'),
      resourceDataCustom: row.custom_references || ""
    });
  };

  getParentItems = (row) => {
    let items;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        items = i;
      }
      return null;
    });
    return items;
  }
  showDescription = (desc, name, tid, row) => {
    if (row.custom_task_name) {
      let items = this.getParentItems(row);
      name = items.custom_task_name;
      desc = items.custom_task_description;
    }
    this.setState({
      showDescModal: true,
      descriptionData: desc,
      nameData: name,
      taskid: tid,
    });
  };
  closeDescription = () => {
    this.setState({
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
    });
  };
  addTeamMember = () => {
    let currrflag = this.state.expandFlag;
    this.setState({
      expandFlag: currrflag ? false : true
    })
  }
  getRoles = (data, teamRolesList) => {
    let { steercomSettings } = this.state;
    let steercom_roles = steercomSettings.roles.filter((item) => {
      return item.template_id == data.template_id;
    });
    return steercom_roles;
  }
  getSteerLabel = (roleid) => {
    if(roleid=="ManagementHR"){
      let xx=0;
    }
    roleid=roleid?roleid.toUpperCase():roleid;
    let rolename, teamRolesList = this.state.teamRolesList;
    let steercomRoles = this.state.steercomRoles;
    let roleobj = steercomRoles.filter(item => item.steercom_role_id == roleid);
    roleobj = roleobj ? roleobj[0] : []
    if (roleobj) {
      rolename = roleobj.steercom_role_name;
    }
    rolename = rolename ? rolename : "";
    return rolename;
  };
  getFactorName = (row) => {
    let factorName = ""; 
    let SFTITLE = row.success_factor_id.replace("Y1","LBL") + "TITLE";
    factorName = this.getLabel(SFTITLE);
    return factorName;
  }
  getParentName = (row) => {
    let parentName = "", parentTaskId = row.parent_task_display_id;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        parentName = i.custom_task_name ? i.custom_task_name : this.getLabel("LBL" + parentTaskId);
      }
      return null;
    });
    return parentName;
  }
  getDateFormat = (xdata, item, mode) => {
    let enddate;
    if (xdata) {
      if (item && mode != 1) {
        enddate = xdata; 
        enddate = new Date(enddate).toLocaleDateString("en-US"); 
        enddate = enddate.split(",")[0]; //enddate.substr(0, 10);
      } else { 
          enddate = xdata.split(",")[0]; //xdata.substr(0, 10); 
      } 
      enddate = this.formattingDate(enddate);
      return enddate; //.substr(0, 10)
    } else {
      return false;
    }
  };
  getParentDescription = (row, eve) => {
    let parentName = "", parentTaskId = row.parent_task_display_id;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        parentName = i.custom_task_name ? i.custom_task_name : this.getLabel("LBL" + parentTaskId + "DSCR");
      }
      return null;
    });
    return parentName;
  }
  onExport = (filetype) => {
    let { reportname, teamSuccessFactorList } = this.state;
    let milestones=[], loclistData = [];
    let listData , listDataAll = teamSuccessFactorList;
    listData = listDataAll.filter((items)=>items.task_type !=0)
    let allLabels = this.props.labels.labels;
    // member_user_id, 
    let {LBLTASK, LBLTASKDESC,LBLSF, LBLRECOMMENDEDDEADLINE,LBLPLANNEDSTARTDATE,LBLMEMBER,LBLPLANNEDENDDATE,LBLRECOMMENDEDSTARTDATE,LBLBASEDATE,LBLMILESTONES} = this.props.labels.labels;
    for (var i = 0, len = listData.length; i < len; i++) {
      let loclistitems = {};
      loclistitems[LBLSF] = this.getFactorName(listData[i]);
      loclistitems[LBLTASK] = listData[i].custom_task_name ? listData[i].custom_task_name : allLabels[listData[i].task_name];
      loclistitems[LBLTASKDESC] = listData[i]["custom_task_description"] ? listData[i]["custom_task_description"] : allLabels[listData[i].task_description];
      loclistitems[LBLMEMBER] = listData[i].membername;
      loclistitems[LBLRECOMMENDEDDEADLINE] = listData[i].recommended_start_days;
      loclistitems[LBLBASEDATE] = listData[i].task_type==2?"":listData[i].base_date ? this.getDateFormat(new Date(listData[i].base_date).toLocaleDateString().replace(/\//g, "-")) : "";
      loclistitems[LBLRECOMMENDEDSTARTDATE] = listData[i].task_type==2?"":listData[i].recommended_start_date ? this.getDateFormat(listData[i].recommended_start_date.toLocaleDateString().replace(/\//g, "-")) : "";
      loclistitems[LBLPLANNEDSTARTDATE] = listData[i].task_type==2?"":listData[i].planned_start_date ? this.getDateFormat(new Date(listData[i].planned_start_date).toLocaleDateString().replace(/\//g, "-")) : "";
      loclistitems[LBLPLANNEDENDDATE] = listData[i].task_type==2?"":listData[i].planned_end_date ? this.getDateFormat(new Date(listData[i].planned_end_date).toLocaleDateString().replace(/\//g, "-")) : "";
      if (listData[i].task_type == 0) {
        loclistitems[LBLBASEDATE] = "";
        loclistitems[LBLPLANNEDSTARTDATE] = "";
      }
      if (listData[i].task_type == 2) {
        milestones.push(listData[i])
        //loclistitems[LBLPARENTTASK] = LBLMILESTONES;
        loclistitems[LBLSF] = LBLMILESTONES; 
      }
      loclistData.push(loclistitems);
    }

    let newval, upperval, arr, fieldName, arrfieldName;
    $("#loadingexport").show();
    loclistData.map((itm) => {
      arrfieldName = Object.keys(itm);
      arrfieldName.forEach((obj) => {
        upperval = obj.toString().toUpperCase();
        arr = upperval.split("_");
        if (arr.length > 1) {
          newval = arr.join(" ");
          itm[newval] = itm[obj];
          delete itm[obj];
        } else {
          newval = upperval;
          itm[newval] = itm[obj];
          delete itm[obj];
        }
      });
      return true;
    });
    reportname = "Task_Planning";
    if (!loclistData) return null;
    if (filetype.toLowerCase() == "csv") {
      this.exportCSV(loclistData, reportname);
    } else if (filetype.toLowerCase() == "excel") {
      this.exportToExcel(loclistData, reportname);
    }
    loclistData = listData;
  };
  convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    let result_key = "";
    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        if (key.toLowerCase().indexOf("summary") > 0) {
          result_key = item[key].toString().replace(/\n/g, "");
        } else {
          if (typeof item[key] == "undefined") item[key] = "";
          result_key = item[key];
        }
        result_key = ((result_key) && isNaN(result_key)) ? result_key.replace(/[,]/g, '-').replace(/\n/g, " ") : result_key;
        result_key = result_key == null ? "" : result_key;
        result += result_key;
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  exportCSV = (listdata, reportname) => {
    var data, filename, link;
    var stockData = [];
    var objHeader = {};

    let
      xfields = listdata;
    stockData = xfields;
    var csv = this.convertArrayOfObjectsToCSV({
      data: stockData,
    });
    if (csv == null) return;

    filename = reportname + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    data = encodeURI(csv);
    //  data = "data:text/csv;charset=utf-8,%EF%BB%BF" + data;
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
    $("#loadingexport").hide();
  };
  exportToExcel = (data, sCallBack, fCallBack) => {
    let fileName = "Task_Planning";
    let width, headers = [];
    const wb = new Excel.Workbook();
    let ws = wb.addWorksheet('Tasks');
    const header_names = Object.keys(data[0]);
    header_names.map((item, x) => {
      width = (x < 3) ? 40 : (x == 3 || x == 4) ? 25 : 18;
      headers.push({
        header: item, key: ("row_" + (x + 1)), width: width
      });
      return null;
    });
    let cols = [];
    ws.columns = headers;
    data.map((items, idx) => {
      cols = [];
      header_names.map(x => {
       return cols.push(items[x])
      });
      ws.addRow(cols);
      return null;
    })
    ws.getColumn('A').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('B').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('C').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('D').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('E').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getColumn('F').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getRow(1).style.alignment = { vertical: 'middle', horizontal: 'center' };

    ws = this.excelFormatting(ws, headers)
    wb.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${fileName + "_" + Date.now()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
  }
  excelFormatting = (worksheet, xcolumns) => {
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } }
        };
        if (rowNumber === 1) {
          row.height = 30;
          row.font = { bold: true }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "538DD5" }
          };
          cell.alignment = {
            vertical: 'middle', horizontal: 'center', wrapText: true
          };
        } else {
          let fgColor = '538DD5';
          //if(cell._address == "A2"){
          if (row._cells[3] && row._cells[3]._value.model.value.trim() == "") {
            fgColor = '000';
          }
          //}

          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: '538DD5'
          };
          cell.alignment = {
            vertical: 'middle', horizontal: colNumber < 5 ? 'left' : 'center'
          };
        }
      })
    })
    return worksheet;
  } 
  getRoleName = (xdata) => {
    let roleName = "";//, steercomRoles = this.state.steercomSettings.roles;
    let steercomRoles = this.state.steercomRoles;
    //console.log("xdata - ",xdata);  
    if (xdata) {
      xdata = xdata.toUpperCase();
      roleName = steercomRoles.filter(item => item.steercom_role_id.toUpperCase() == xdata);
      return roleName[0].steercom_role_name;
    } else {
      return "";
    }
  };
  showGanttChartPopup = (mode, sfData) => {
    let mainTasks = [], allTasks = [], allItems = this.state;
    //let mode = items == 1 ? "add" : "edit";
    let successFactorDataChart = allItems.successFactorDataChart;
    let successFactorData  = allItems.successFactorData;
    let cnt = 0, taskDetails = [],baseDate=allItems.base_date;
    gCnt=0;
    allTasks = allItems.teamSuccessFactorList; 
    if(mode==2 && sfData.id != "0" && successFactorDataChart.id=="0"){
       allTasks = allItems.teamSuccessFactorList.filter(items=> sfData.id == items.success_factor_id); 
    }
    if(mode==1){ 
      successFactorDataChart = successFactorData;
    }
    allTasks.map((item) => {
      if (item.task_type == 0) {
        item.task_name = item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name);
        if (cnt == 0) mainTasks.push(item);
        if (mainTasks.findIndex(items => items.task_name != item.task_name) > -1) {
          mainTasks.push(item);
        }
        cnt++;
      }
      return null;
    });
    taskDetails["all_tasks"] =  allTasks;
    //taskDetails["row"] = (items);
    taskDetails["team_language"] = allItems.teamTemplate;
    taskDetails["main_tasks"] = mainTasks;
    taskDetails["steercom_roles"] = allItems.steercom_roles;
    taskDetails["success_factors"] = allItems.successFactorsList;
    taskDetails["task_type"] = allItems.taskTypeArr;
    taskDetails["task_category"] = allItems.taskCategoryArr;
   
    this.showGantChart(1,taskDetails);

    this.setState({
      showGantPopup: true,
      taskDetails: taskDetails,
      selectedBaseDate:baseDate,
      successFactorDataChart:successFactorDataChart
    });
  }
  closeGantPopup = () => {
   // this.getTeamListCallback();  
    $("#page-header-field").css({ "display": "flex" });
    let {successFactorsList} = this.state;
    this.setState({
      showGantPopup: false,
      successFactorDataChart: successFactorsList[0]
    })
  }
  exportPdf=()=>{
      // Merge the options
      let charts=[];
      let options = Highcharts.merge(Highcharts.getOptions().exporting, options);
      options ={
        filename: 'chart',
          type: 'PDF',
          width: 500,
          svg: Highcharts.getSVG(charts)
      }
      // Post to export server
      Highcharts.post(options.url, {
          filename: options.filename || 'chart',
          type: options.type,
          width: options.width,
          svg: Highcharts.getSVG(charts)
      });
  }
  handleStartDateChange = (date) => {
    // console.log(date);
    this.setState({
      selectedBaseDate: date,
      error: true,
    });
  };
  showGantChart = (mode, taskDetails ) => {
    let task_details = taskDetails;
    if (mode == 2) {
      task_details = this.state.taskDetails;
    }
    let prevSelectedDate = this.state.prevSelectedDate;
    if (new Date(prevSelectedDate).getTime() == new Date(this.state.selectedBaseDate).getTime() && mode==2) {
      return false;
    } else {
      prevSelectedDate = this.state.selectedBaseDate;
    }
    $("#page-header-field").css({ "display": "none" })
    let all_tasks = task_details.all_tasks;
    let flagTime = true, loctime = 0, base_date, new_base_date, teamSFList = [];
    new_base_date = this.state.selectedBaseDate;
    base_date = this.state.base_date;
    teamSFList = all_tasks;
    
    if (mode == 2) {
      if (new Date(base_date).getTime() > new Date(new_base_date).getTime()) {
        loctime = new Date(base_date).getTime() - new Date(new_base_date).getTime();
        flagTime = true;
      } else if (new Date(base_date).getTime() < new Date(new_base_date).getTime()) {
        loctime = new Date(new_base_date).getTime() - new Date(base_date).getTime();
        flagTime = false;
      }
    }
    this.setState({
      all_tasks: teamSFList,
      showLoading: true,
      showGantPopup: true,
      taskDetails: task_details,
      flagTime: flagTime,
      loctime: loctime,
      prevSelectedDate: prevSelectedDate,
      error: true,
      loadChart: true,
    });
  }
  findBrowser = () => { 
    let browser = "";
    if((navigator.userAgent.indexOf("Edg/") !== -1 ) || (!!document.documentMode == true )){
      browser = "MSIE";
    }  
    else if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 ){
      browser = "Opera";
    }
    else if(navigator.userAgent.indexOf("Chrome") !== -1 ){
      browser = "Chrome";
    }
    else if(navigator.userAgent.indexOf("Safari") !== -1){
      browser = "Safari";
    }
    else if(navigator.userAgent.indexOf("Firefox") !== -1 ){
      browser = "Firefox";
    } 
    else{
      browser = "unknown";
    }
    return browser
}
  render() {
    const styles = {
      floatingLabelFocusStyle: {
        color: "blue"
      }
    }
    let convertDateFormat = (xdata, item, mode) => {
      let enddate;
      if (xdata) {
        if (item && mode !== 1) {
          enddate = xdata;
          enddate = new Date(enddate).toLocaleDateString("en-US");
          enddate = enddate.split(",")[0]; //enddate.substr(0, 10);
        } else {
          enddate = xdata.split(",")[0]; //xdata.substr(0, 10);
        }
        enddate = this.formattingDate(enddate);
        return enddate; //.substr(0, 10)
      } else if (xdata == null) {
        return new Date().toLocaleString().split(",")[0];
      } else {
        return false;
      }
    };
    let getMinimumDate = (xdata, item, mode) => {
      let enddate;
      if (xdata) {
        enddate = xdata;
        //enddate = new Date(enddate).toLocaleString();
        var d = new Date(enddate),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        let fulldate = [month, day, year].join("/");
        return fulldate; //.substr(0, 10)
      } else {
        return false;
      }
    };

    this.formattingDate = (date) => {
      let dateformat,
        fulldate = "";
      dateformat = this.state.dateformat;
      if (dateformat.toString().indexOf("YYYY") > 0)
        dateformat = this.setdateformat();
      let dateArray = date.indexOf("/")>-1? date.split("/"): date.indexOf("-")>-1? date.split("-"):"";
      if (date) {
        var d = new Date(), month, day, year;
        d = new Date(date);
        day = "" + d.getDate();
        if (!d && navigator.userAgent.indexOf("Firefox") != -1) {
          d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
        } else if (d == undefined || isNaN(d.getDate())) {
          d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
        }
        month = "" + (d.getMonth() + 1);
        day = "" + d.getDate();
        year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        if (dateformat == "dd/MM/yyyy") {
          fulldate = [day, month, year].join("/");
        } else if (dateformat == "MM/dd/yyyy") {
          fulldate = [month, day, year].join("/");
        } else if (dateformat == "yyyy/MM/dd") {
          fulldate = [year, month, day].join("/");
        } else {
          fulldate = [day, month, year].join("/");
        }
      }
      return fulldate;
    };
    let cwidth = ["150px"]
    if(this.props.language_code=="fr-FR"){
      cwidth=["220px"]
    }
    let teamSuccessFactorColumnsAll = [
      {
        name: "",
        selector: "",
        width: "40px",
        cell: (row) => (
          <>
            <div
              style={{
                display: "inline-block",
                paddingLeft: "0px",
                position: "absolute",
                backgroundColor: "white",
                left: "-5px",
                width: "52px",
                height: "52px",
              }}
            ></div>
            {row.task_type != 1 ? (
              ""
            ) : (
              <div
                style={{
                  display: "inline-block",
                  paddingLeft: "0px",
                  position: "absolute",
                  border: "0px solid red",
                  top: "-4px",
                  left: "2px",
                }}
              >
                <div className="sub_container3" style={{ height: "auto" }}>
                  <img
                    src={
                      "../images/steercom/" +
                      getStatusClass(row.task_status) +
                      ".png"
                    }
                    className="img_team_status"
                    alt=""
                  ></img>
                </div>
                <div> { }</div>
              </div>
            )}
          </>
        ),
      },
      {
        name: this.props.labels.labels.LBLSF,//LBLPARENTTASK
        fixedHeader: true,
        selector: "name",
        // sortable: true,
        width: "240px",
        compact: true,
        cell: (row) => {
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == this.state.teamData.created_by ||
              this.props.userId == this.state.teamData.team_administrator ||
              this.props.userId == this.state.teamData.team_addnl_administrator) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false;
          let task_name = "";
          task_name = this.getFactorName(row);//this.getParentName(row);//row.custom_task_name ? row.custom_task_name : this.getLabel("LBL" + row.parent_task_display_id);
          if (row.is_default == 0) {
            task_name = this.getFactorName(row);//this.getParentName(row);
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {row.task_type == 2 ? row.custom_task_name ? row.custom_task_name : row.task_name : row.task_type == 1 ? (
                <>
                  <div
                    style={{
                      width: "14px",
                      height: "34px",
                      border: "0px solid",
                      display: "block",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "34px", 
                        backgroundColor: "rgb(128, 227, 186)",
                        border: "0px solid",
                        display: "block",
                      }}
                    ></div>
                  </div>

                  <div
                    style={{
                      width: "calc(100% - 14px)",
                      border: "0px solid",
                      display: "flex",
                    }}
                  >
                    {xEditCheckBox == true ? (
                      <div
                        style={{
                          display: "inline-block",
                          paddingLeft: "0px",
                          width: "30px",
                        }}
                      >
                        <label
                          class={
                            row.isNewAssigned
                              ? "check-lbl"
                              : "check-lbl check-newassigned"
                          }
                        >
                          <input
                            type="checkbox"
                            name={"chkg_" + row.team_id}
                            id={"chkg_" + row.team_id}
                            data-toggle="modal"
                            checked={row.isNewAssigned}
                            onClick={this.openConfirmDialog3.bind(
                              this,
                              row,
                              row.isNewAssigned ? 1 : 0
                            )
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          border: "0px solid red",
                          width: xEditCheckBox == true ? "calc(100% - 62px)" : "calc(100% - 29px)",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: "24px" /* exactly 2 lines */,
                            textOverflow: "ellipsis",
                            fontWeight: "410"
                          }}
                          title={task_name}
                        >
                          {task_name}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "none",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          width: "28px",
                        }}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                          }}
                          src="../images/steercom/info.png"
                          onClick={this.showDescription.bind(
                            this,
                            this.getParentDescription(row),
                            this.getParentName(row),
                            row.task_display_id, row
                          )}
                          title={LBLTASKDESC} //LBLTASKDESC
                          alt=""
                        />
                      </div>

                    </>
                  </div>
                </>
              ) : (
                ""
              )
              }
            </div >
          )
        },
      },
      {
        name: this.props.labels.labels.LBLTASK,
        fixedHeader: true,
        selector: "name",
        // sortable: true,
        width: "340px",
        compact: true,
        cell: (row) => {
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == this.state.teamData.created_by ||
              this.props.userId == this.state.teamData.team_administrator ||
              this.props.userId == this.state.teamData.team_addnl_administrator) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {row.task_type == 1 ? (
                <>
                  <div
                    style={{
                      width: "14px",
                      height: "34px",
                      border: "0px solid",
                      display: "block",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "34px", 
                        border: "0px solid",
                        display: "block",
                      }}
                    ></div>
                  </div>

                  <div
                    style={{
                      width: "calc(100% - 14px)",
                      border: "0px solid",
                      display: "flex",
                    }}
                  >
                    <>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          border: "0px solid red",
                          width: xEditCheckBox == true ? "calc(100% - 62px)" : "calc(100% - 29px)",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: "24px" /* exactly 2 lines */,
                            textOverflow: "ellipsis",
                            fontWeight: "410"
                          }}
                          title={row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name)}
                        >
                          {/* {row.task_display_id}  */}
                          {row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          width: "28px",
                        }}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                          }}
                          src="../images/steercom/info.png"
                          onClick={this.showDescription.bind(
                            this,
                            row.custom_task_description ? row.custom_task_description : this.getLabel(row.task_description),
                            row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name),
                            row.task_display_id
                          )}
                          title={LBLTASKDESC} //LBLTASKDESC
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          width: "28px",
                        }}
                      > 
                        {(row.references  || row.custom_references ) ?
                        <img
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                          }}
                          src="../images/steercom/SC_Folder.png"
                          onClick={this.openRefModal.bind(this, row)}
                          title={LBLREFMATERIAL}
                          alt=""
                        />:<img
                          style={{ 
                            background:"",
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                            //pointerEvents: "none",
                            opacity:".2"
                          }}
                          src="../images/steercom/SC_Folder.png"
                          title={LBLNORESOURCES}
                          alt=""
                        />}
                      </div> 

                    </>
                  </div>
                </>
              ) : (
                ""
              )
              }
            </div >
          )
        },
      }, 
      {
        name: this.props.labels.labels.LBLMEMBER,
        selector: "service_duration",
        //sortable: true,
        width: "200px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <>
                {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                  <>
                  <ThemeProvider theme={theme}>
                    <Autocomplete
                      id="members"
                      noOptionsText={memberNoOptionsNode()}
                      options={filteredMembersList(row)}
                      style={{ width: "calc(100%)" }}
                      getOptionLabel={(option) => option.membername.toString()}
                      getOptionSelected={(option, values) =>
                        option.team_member_id === values.team_member_id
                      }
                      PaperComponent={({ children }) => (
                        <div id="minipop" style={{ zIndex: "100", backgroundColor: "#FFF", border: "1px solid #CDCDCD" }}>
                          {children}
                        </div>
                      )}
                      onChange={this.handleChange.bind(
                        this,
                        "team_member_id",
                        row,
                        "team_member_id"
                      )}
                      disableClearable
                      underlineStyle={{ display: "none" }}
                      defaultValue={filteredMembersList(row).find(
                        (v) =>
                          v.team_member_id ==
                          editingTeamSF["M_" + row.task_display_id]
                            .team_member_id
                      )}
                      renderInput={(params) => (
                        <TextField id="memberlist" {...params}
                          floatingLabelFocusStyle={styles.floatingLabelFocusStyle}
                        />
                      )}
                      renderOption={(option) => (
                        <span style={{ color: option.user_status == 0 ? "#FF5959" : "#5F5E5E" }}>
                          {option.membername}
                        </span>
                      )}
                      underline="none"
                    />
                    </ThemeProvider>
                    <span
                      className="required"
                      id={"span_team_member_id_" + row.task_display_id}
                    >
                      {LBLREQUIRED}
                    </span>
                  </>
                ) : (
                  <>
                <div title={row.membername} className="cls_ellipsis"  style={{display:"flex", color: row.user_status == 0 ? "#FF5959" : "#5F5E5E" }}> 
                  <div className="cls_ellipsis" > {row.membername}</div> 
                      <img
                        src="../images/steercom/admin.png"
                        style={{
                          //position: "absolute",
                          float: "right",
                          display:
                            teamData.adminname == row.membername ? "" : "none",
                        }}
                        title="Admin"
                        className="img_team_admin"
                        alt=""
                      ></img>{" "}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: this.props.labels.labels.LBLRECOMMENDEDDEADLINE,
        selector: "task_basedate",
        // sortable: true,
        width: cwidth[0] ,
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox cls_ellipsis"}
            style={{
              width: "100%",
              border: "0px solid",
              letterSpacing: "initial",
              display:"block"
            }}
          >
            {row.task_type == 1 ? (
              <>
                {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                  <>
                    <span>{LBLBASEDATE}</span>{" "}
                    <span style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                      {row.recommended_start_days > 0 ? "+" : ""}
                      {row.recommended_start_days}
                    </span>
                    {LBLDAYS}
                  </>
                ) : (
                  <>
                  <div style={{display:"inline"}} 
                  title= {row.recommended_start_days > 0 ? "+" : "" + row.recommended_start_days + " " + LBLDAYS}>
                    <span>{LBLBASEDATE}</span>{" "}
                    <span style={{ paddingLeft: "2px", paddingRight: "2px" }}>
                      {row.recommended_start_days > 0 ? "+" : ""}
                      {row.recommended_start_days}
                    </span>
                    {LBLDAYS}
                  </div>
                   </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: this.props.labels.labels.LBLRECOMMENDEDSTARTDATE,
        selector: "member_enddate",
        //  sortable: true,
        width: "130px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <>
                <>
                  <div>
                    <img
                      src="../images/steercom/Expired.png"
                      style={{
                        visibility:
                          (new Date().getTime() >=
                            new Date(row.recommended_start_date).getTime() &&
                            (new Date(row.recommended_start_date).getTime() < new Date(row.planned_start_date).getTime() ||
                              new Date(row.recommended_start_date).getTime() < new Date(row.planned_end_date).getTime()) &&
                            row.task_status != "COMPLETED")
                            ? "visible"
                            : "hidden",
                      }}
                      title={LBLEXPIRED}
                      className="img_team_cross"
                      alt=""
                    ></img>
                    {row.recommended_start_date && convertDateFormat(row.recommended_start_date, row)}
                  </div>
                </>
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: this.props.labels.labels.LBLPLANNEDSTARTDATE,
        selector: "member_enddate",
        //sortable: true,
        width: "130px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <>
                {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                  <>
                    <MuiPickersUtilsProvider
                      className="planned_start_date"
                      utils={DateFnsUtils}
                      style={{ width: "calc(100% - 1px)", float: "right" }}
                    >
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          minDate={"2015-01-01"}
                          id={"planned_start_date"}
                          format={this.state.dateformat}
                          value={
                            this.state.editingTeamSF["M_" + row.task_display_id]
                              .planned_start_date || row.planned_start_date
                          }
                          onChange={this.handleDateChange.bind(
                            this,
                            "planned_start_date",
                            row, 1
                          )}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <span
                        className="required"
                        id={"span_planned_start_date_" + row.task_display_id}
                      >
                        {LBLREQUIRED}
                      </span>
                    </MuiPickersUtilsProvider>
                  </>
                ) : (
                  <>
                    <div>
                      <img
                        src="../images/steercom/Expired.png"
                        style={{
                          visibility:
                            (new Date().getTime() >=
                              new Date(row.planned_start_date).getTime() &&
                              new Date(row.planned_start_date) > new Date(row.recommended_start_date) &&
                              row.task_status != "COMPLETED")
                              ? "visible"
                              : "hidden",
                        }}
                        title={LBLEXPIRED}
                        className="img_team_cross"
                        alt=""
                      ></img>
                      {row.planned_start_date && convertDateFormat(row.planned_start_date, row)}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: this.props.labels.labels.LBLPLANNEDENDDATE,
        selector: "member_enddate",
        //  sortable: true,
        width: "130px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <>
                {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                  <>
                    <MuiPickersUtilsProvider
                      className="member_enddate"
                      utils={DateFnsUtils}
                      style={{ width: "calc(100% - 1px)", float: "right" }}
                    >
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          minDate={editingTeamSF["M_" + row.task_display_id].planned_start_date}
                          id={"member_enddate"}
                          format={this.state.dateformat}
                          value={
                            this.state.editingTeamSF["M_" + row.task_display_id]
                              .planned_end_date || row.planned_end_date
                          }
                          onChange={this.handleDateChange.bind(
                            this,
                            "planned_end_date",
                            row, 2
                          )}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                          }}
                        />
                      </Grid>

                      <span
                        className="required"
                        style={{ position: "absolute" }}
                        id={"span_planned_end_date_" + row.task_display_id}
                      >
                        {LBLREQUIRED}
                      </span>
                    </MuiPickersUtilsProvider>
                  </>
                ) : (
                  <>
                    <div>
                      <img
                        src="../images/steercom/Expired.png"
                        style={{
                          visibility:
                            (new Date().getTime() >=
                              new Date(row.planned_end_date).getTime() &&
                              new Date(row.planned_end_date) > new Date(row.recommended_start_date) &&
                              row.task_status != "COMPLETED") ==
                              true
                              ? "visible"
                              : "hidden",
                        }}
                        title={LBLEXPIRED}
                        className="img_team_cross"
                        alt=""
                      ></img>
                      {row.planned_end_date && convertDateFormat(row.planned_end_date, row)}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: this.props.labels.labels.LBLACTION,
        selector: "status",
        // sortable: true,
        width: "100px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{ border: "0px solid", width: "100%", paddingTop: "6px", left: "-10px", position: "relative" }}
          >
            {row.task_type == 1 && row.isNewAssigned ? (
              <>
                {this.state.base_date &&
                  (this.props.userId == this.state.teamData.created_by ||
                    this.props.userId == this.state.teamData.team_administrator ||
                    this.props.userId == this.state.teamData.team_addnl_administrator) ? (
                  <>
                    <label className="show-sm">
                      {LBLACTION} </label>
                    <div
                      className="sub_container3"
                      style={{ display: "inline-block", marginLeft: "10px" }}
                    >
                      <img
                        src="../images/steercom/Checkbox.png"
                        onClick={this.saveSuccessFactor.bind(this, row)}
                        title="Save"
                        className="img_team_cross"
                        alt=""
                      ></img>
                    </div>
                    <div
                      className="sub_container3 cls_team_cross"
                      style={{ display: "inline-block" }}
                    >
                      <i
                        class="fas fa-undo"
                        onClick={this.deleteSuccessFactor.bind(this, row)}
                        title="Reset"
                      ></i>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
    ];
    let {
      LBLTASK, LBLSTEERCOMTEAMS,LBLVWFULL,LBLPRINTCRT,LBLDNPNG,LBLDNJPEG,LBLDNSVG,LBLDNPDF,
      LBLCNFRESETALLDATE,LBLDUPRCD,
      LBLCNFRMUPDDATE1, LBLCNFRMUPDDATE11, LBLCNFRMUPDDATE2, LBLCNFRMUPDDATE3,
      LBLNORECORDS, LBLLEGENDS,  LBLREQUIRED, LBLSTEERCOMSFSETUP, LBLUNSAVEDATA, 
      LBLRESETALL, LBLNONE, LBLJAN, LBLFEB, LBLMAR, LBLAPR, LBLMAY, LBLJUN, LBLJUL, LBLAUG, LBLSEP, LBLOCT, LBLNOV, LBLDEC,
      LBLSELECTBASEDATE,LBLACTUALSDATE, LBLACTUALEDATE,  
      LBLNOMEMBERFOUND, LBLPLANNEDSTARTDATE,
      LBLPLANNEDENDDATE, LBLACTION, LBLCONTMENU,LBLMILESTONES,
      LBLSETBASEDATE, LBLGANTTCHART, LBLBASEDATE,
      LBLDAYS, LBLTEAM, LBLSF, LBLUPDATEBASEDATE,  LBLTEAMNORECORDS, LBLEXPORT, LBLEXPORTTOCSV, LBLEXPORTTOEXCEL,
      LBLREFERENCELNKS, LBLEXPIRED, LBLSENDNOTIFICATION, LBLCHNGLANGUAGE,
      LBLVIEWPLAN, LBLUPDATEPLAN, LBLREFMATERIAL, LBLTASKDESC,LBLNORESOURCES,
      LBLAGREE, LBLNO, LBLYETTOSTART, LBLINPROGONTRACK, LBLINPROGONDELAY, LBLCOMPLETED
    } = this.props.labels.labels;  
    const LABELS = this.props.labels.labels;
    const {
      teamData, show_language, isAssociated,mobView,  teamSuccessFactorList,
      loadChart, showLoading, editingTeamSF, isMemberChange,
      teamMembersList, lang_confirmdata, teamRolesList, notification_enabled
    } = this.state;


    const filterSuccessFactorsListData = teamSuccessFactorList.filter(
      (item) => {
        if (
          (item.task_type == 1 && item.expandFlag == 1) ||
          item.task_type == 0 || item.task_type == 2
        ) {
          return item;
        }
        // }
      }
    );
    const filteredMembersList = (row) => {
      return teamMembersList.filter((item) => {
        return item;
      });
    };

    const taskTeamRolesList = (row) => {
      let defaultExist = false;
      let taskRoleList = [];
      for (let i = 0; i < teamRolesList.length; i++) {
        if (teamRolesList[i].steercom_role_id == row.default_role_id) {
          defaultExist = true;
        }
        taskRoleList.push(teamRolesList[i]);
      }
      if (defaultExist == false) {
        let steercomRoles = this.state.steercomSettings.roles;
        for (let i = 0; i < steercomRoles.length; i++) {
          if (steercomRoles[i].steercom_role_id == row.default_role_id &&
            steercomRoles[i].template_id == row.template_id) {
            taskRoleList.push(steercomRoles[i]);
          }
        }
      }
      return taskRoleList;
    };
    const memberNoOptionsNode = () => {
      return <span>{LBLNOMEMBERFOUND}</span>;
    };
    let healthactivity = {
      YETTOSTART: "notstarted",
      INPROGONTRACK: "ontrack",
      INPROGONDELAY: "delayed",
      COMPLETED: "completed",
    };
    let statusColors = {
      YETTOSTART: "#aeacac",
      INPROGONTRACK: "#4785d7",
      INPROGONDELAY: "#d6a71c",
      COMPLETED: "#07f037",
    }
    let getStatusClass = (status) => {
      if (typeof status == "string") {
        return healthactivity[status];
      } else {
        return healthactivity["YETTOSTART"];
      }
    }; 
    let getHeight=()=>{
      let { all_tasks } = this.state; 
      let cheight , height = all_tasks? all_tasks.length * 71 : 6250; 
      cheight =  $(".highcharts-container ").css("height");
      cheight= cheight?parseInt(cheight.split("px")[0]) :height
      return cheight;
    }
      
    let teamSuccessFactorColumns = teamSuccessFactorColumnsAll;
    let teamSuccessFactorListData = filterSuccessFactorsListData.filter(item => item.task_type == 1 || item.task_type == 2);
    //let teamSuccessFactorListData = filterSuccessFactorsListData;
    let horizontal = "top",
      vertical = "center";
    if (this.state.teamData && (this.props.userId == this.state.teamData.created_by ||
      this.props.userId == this.state.teamData.team_administrator ||
      this.props.userId == this.state.teamData.team_addnl_administrator)) {
      $("#updateplan").html(LBLUPDATEPLAN)
    } else {
      $("#updateplan").html(LBLVIEWPLAN)
    }

    let hasData = true;
    let goptions={};
    
    if(this.state.showGantPopup ){  
      if(LBLJAN){ 
        Highcharts.setOptions({
        lang: {
          months: [LBLJAN, LBLFEB, LBLMAR, LBLAPR, LBLMAY, LBLJUN, LBLJUL, LBLAUG, LBLSEP, LBLOCT, LBLNOV, LBLDEC],
          //weekdays: [LBLMON, LBLTUE, LBLWED, LBLTHU, LBLFRI, LBLSAT, LBLSUN],
        }
      });
    }
    const dateInMilliseconds = 86400000;
    const now = new Date().getTime();
  
    let color = "#0FC", clr = 1, yax = 0, { all_tasks, loctime, flagTime, successFactorDataChart } = this.state;
     
    var today = new Date(),
      day = 1000 * 60 * 60 * 24;

    // Set to 00:00:00:000 today
    today.setUTCHours(0);
    today.setUTCMinutes(0);
    today.setUTCSeconds(0);
    today.setUTCMilliseconds(0);

    let categories = [], finalData = [], allTasks = [],category="";
    let maxDates = [], minDates = [], minX = new Date(), maxX = new Date();
    let maxModDates=[],minModDates=[];
    let prevParentName, currParentName, currTaskName, xx, yy, flagBase = false, cnt = 1;
    let startDate = "", endDate;

    allTasks = all_tasks;  
    //////////////////////////
    let {allTeamSuccessFactorList } = this.state;
    let teamSuccessFactors = allTeamSuccessFactorList;
    if(successFactorDataChart.id!="0"){
      teamSuccessFactors = allTeamSuccessFactorList.filter(item=>item.success_factor_id == successFactorDataChart.id);
    }
    allTasks = teamSuccessFactors; 
 
let statusColors={
  YETTOSTART: "#aeacac",
  INPROGONTRACK: "#4785d7",
  INPROGONDELAY: "#d6a71c",
  COMPLETED: "#07f037", 
}
    let sColors = {
      YETTOSTART: "#c8c6c6",
      INPROGONTRACK: "#7db1f5",
      INPROGONDELAY: "#f5c947",
      COMPLETED: "#79da8d",
    };  
    let sDate, eDate,asDate,aeDate,cc,dd;
    let singleItem= {};
    let secondItem= {},custTaskName="";
    let finalItems=[],formatData={};
    if (allTasks) {
      // this.sortByKeyAsc(allTasks, "parent_task_display_id");
       allTasks.map((item, idx) => {
         if ((item.task_type == 1 || item.task_type == 2) && this.state.new_base_date && loadChart ) { //&& item.task_status !=null
           startDate = item.planned_start_date;
           sDate = item.planned_start_date ? item.planned_start_date : new Date().toLocaleDateString();
           eDate = item.planned_end_date ? item.planned_end_date : sDate; //item.planned_start_date;
           asDate = item.actual_start_date ? item.actual_start_date : item.planned_start_date;
           aeDate = item.actual_end_date ? item.actual_end_date :  item.actual_start_date ?item.actual_start_date:asDate;
          if (!flagTime) {
             sDate = new Date(new Date(sDate).getTime() + loctime);
             eDate = new Date(new Date(eDate).getTime() + loctime);
           } else {
             sDate = new Date(new Date(sDate).getTime() - loctime);
             eDate = new Date(new Date(eDate).getTime() - loctime);
           }
           xx = sDate;//item.planned_start_date?item.planned_start_date:new Date().toLocaleDateString();
           let fEnddate = false;
           if (!item.planned_end_date) {
             fEnddate = true;
           }
           yy = eDate;//item.planned_end_date ? item.planned_end_date:new Date(startDate).setDate(new Date(startDate).getDate()+30); 
           cc = asDate;
           dd = aeDate;
            
           if(item.actual_start_date && !item.actual_end_date  ){
              let aa = new Date(yy).getTime() - new Date(xx).getTime();
              //dd = new Date(cc).getTime()  + aa;
           }
           if(item.actual_start_date == item.actual_end_date  ){
            let aa = new Date(yy).getTime() - new Date(xx).getTime();
            //dd = new Date(cc).getTime()  + aa;
           }
           clr = (clr + 1);
           let colors = color + (clr < 10 ? ("00" + clr) : clr < 100 ? ("0" + clr) : clr);
           
 
           if (fEnddate) {
             // console.log("END DATE - " , startDate, yy );
             colors = "#B3936D";
           } else {
             colors = item.task_status ? sColors[item.task_status] : "#c8c6c6";
           }
 
           xx = new Date(xx);
           yy = new Date(yy);
           cc = new Date(cc);
           dd = new Date(dd);
 
           
           minDates.push(new Date(xx).getTime());
           maxDates.push(new Date(yy).getTime());
           if (item.recommended_start_days >= 0) {
           }
           currParentName = this.getFactorName(item);
           if(item.custom_task_name.indexOf("Integrate SafeStart")>-1){
             let xx = currParentName
           }
           if ((idx == 0 || currParentName != prevParentName) &&  item.task_type == 1) {
             finalData.push({
               name: currParentName + "***",
               id: currParentName  ,
               color: "#33609C",
               y: yax,
             });
             categories.push(currParentName + "***");
             cnt++;
             yax++;
           }
           
           currTaskName = item.custom_task_name.replace(/"/g, "'").replace("***", "");
           singleItem = {};
           secondItem = {};
           if(yy)
           maxModDates.push(new Date(yy).getTime());
           if(item.task_type == 1){ 
            singleItem= {
             completed_per:  item.completion_percentage,
             color: item.task_status ? sColors[item.task_status] : "#c8c6c6", //colors,
             assignee:  getStatusClass(item.task_status),
             y: yax,  
            pointWidth: 28,
             start: Date.UTC(xx.getFullYear(), xx.getMonth(), xx.getDate()),
             end: item.task_type == 1?Date.UTC(yy.getFullYear(), yy.getMonth(), yy.getDate()):"", 
           } ;  
 
           secondItem= {
             completed: {
               amount: (item.completion_percentage/100),
               fill: item.task_status ? statusColors[item.task_status] : "#aeacac",
             },
            completed_per:  item.completion_percentage,
            pointWidth: 18,
             color: item.task_status ? statusColors[item.task_status] : "#aeacac", //colors,
            // assignee:  getStatusClass(item.task_status),
             y: yax,
            // id: cnt,
             type:"actual",
             start: Date.UTC(cc.getFullYear(), cc.getMonth(), cc.getDate()),
             end: item.task_type == 1?Date.UTC(dd.getFullYear(), dd.getMonth(), dd.getDate()):"", 
           } ;  
           }else if(item.task_type == 2){
           sDate = allTasks[idx-1].planned_start_date ? allTasks[idx-1].planned_start_date : new Date().toLocaleDateString();
           if (!flagTime) {
             sDate = new Date(new Date(sDate).getTime() + loctime); 
           } else {
             sDate = new Date(new Date(sDate).getTime() - loctime); 
           }
           xx = sDate;
           singleItem= { 
             color: '#EA3A77',//'#FECA04',
             y: yax, 
             milestone: true, 
             x: Date.UTC(xx.getFullYear(), xx.getMonth(), xx.getDate()),
             x2: maxModDates ? Math.max(...maxModDates) :"", 
           } ; 
           }
           let singleItems = singleItem;
           finalItems=[];formatData={};
           finalItems.push(singleItems);
           finalItems.push(secondItem); 
           formatData =  {
                 name: currTaskName,
                 current: 0,
                 data: finalItems
             }
           finalData.push(formatData);
           yax++;
           cnt++;
           if(item.task_type == 1){
            custTaskName =  item.custom_task_name;
            if(custTaskName){
              custTaskName =  custTaskName.replace("***","");
            }
            custTaskName =  item.custom_task_name.replace("***","");
           category = (custTaskName ? custTaskName : this.getLabel(item.task_name))+"~-~" + getStatusClass(item.task_status)
           }else if(item.task_type == 2){
           category = (item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name))+"!-!"  
           }
           categories.push(category);
         }
         prevParentName = currParentName;
         return null;
       });
     };
    if (minDates.length > 0) {
      minX = new Date(Math.min(...minDates));
      maxX = new Date(Math.max(...maxDates));
      minX = minX ? Date.UTC(minX.getFullYear(), minX.getMonth()-1, minX.getDate()) : Date.UTC(2022, 1, 1)
      maxX = maxX ? Date.UTC(maxX.getFullYear(), maxX.getMonth(), maxX.getDate()) : Date.UTC(2022, 12, 31)
    }
    let teamName = "", retval = "";
    let displayData = [];
    displayData = (finalData); 
    if(this.state.teamData){
      teamName = this.state.teamData.team_name;
      teamName = teamName.length>20?teamName.substring(0,20)+"..." : teamName;
    }
    // var pointsAmount = 51; // Set the amount of your points
    // var categorySize = 20; 
    var chartTitle = LBLTASK + " (" +  teamName + ")"; 
    goptions= {
      chart: {
        height: $("#root").height() - 113,
        scrollablePlotArea: {
        },
        marginRight: 30
      },
      exporting: { 
        filename: 'Planning-GanttChart', 
        chartOptions: {
          chart: {
            height: (displayData.length) * 55,
          },
          yAxis:{ 
            max:undefined,
            min:undefined,
            scrollbar:{
              enabled:false,
          }
          },
          scrollbar: {
            enabled: false
          },
          rangeSelector: {
            enabled: false,
          }, 
        }, 
      },
      xAxis: {
        currentDateIndicator: false
      },
      yAxis: {
        min: 0,
        max: displayData.length > 6 ? 6 : displayData.length - 1,
        type: 'category',
        useHTML: true,
        title: {
          text: "<div style='white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:99%;display:inline' class='cls_ellipsis'>" + LBLTASK + " ( <span style='color:red'>" +  teamName + "</span> )" + "</div>" ,
          paddingBottom: 20
        },
        scrollbar: {
          enabled: true
        },
        grid: {
          columns: [{
            useHTML: true,
            title: {
              useHTML: true,
              text:  "<div title='" + teamName + "' style='white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:99%;display:inline' class='cls_ellipsis'>" + chartTitle + "</div>" ,
            },
            categories: categories  
          } ]
        },
        labels: {
          align: 'left',
          useHTML: true,
          style: {
            textOverflow: 'ellipsis',
            //marginLeft: 20,
          },
          formatter: function(x,y) {
            let assignee, divs = "", vals = this.value;
            if(isNaN(vals) && vals.indexOf("***") > 1 ){
              vals = vals.replace("***","");  
              divs = '<span style="color:#4C1327; font-style:italic; font-weight:600; float:left">' + vals + '</span>'; 
            } else if(isNaN(vals) && vals.indexOf('~-~') > 1 ){
              vals = this.value.split("~-~")[0]; 
              assignee = this.value.split("~-~")[1]; 
              divs =  '<div title="' + vals + '" ><img class="cls_chart_image_container" src="../images/steercom/' + assignee  + '.png" ></img><span style=" ">' + vals + '</span></div>'; 
            }else if(isNaN(vals) && vals.indexOf('!-!') > 1  ){ 
              vals = this.value.split("!-!")[0]; 
              assignee = this.value.split("!-!")[1];  
              divs = '<span style="color:#EA3A77; font-style:italic; font-weight:600; float:left">' + vals + '</span>'; 
            } 
            return divs;
           }
        },
      }, 
      series: displayData,
      title: {
        //text: 'Planned Vs Actual' 
      },
      tooltip: {
        xDateFormat: '%A, %b %e, %Y',
        formatter: function() {
          let parent = (this.point.parent) ? (this.point.parent) : this.key;
          parent = this.yCategory;
          parent = parent.length > 50 ? parent.substring(0, 49) + "..." : parent;
          let startDate, endDate = this.point.type ? 'Actual ' : "Plan ";
          startDate = this.point.type? LBLACTUALSDATE :LBLPLANNEDSTARTDATE + ': ';
          endDate = this.point.type? LBLACTUALEDATE :LBLPLANNEDENDDATE+ ': ';
          if(isNaN(parent) && parent.indexOf("***") > 1 ){
            parent = parent.split("***")[0]; 
          }else if(isNaN(parent) && parent.indexOf("~-~") > 1 ){
            parent = parent.split("~-~")[0]; 
          }else if(isNaN(parent) && parent.indexOf("!-!") > 1 ){
            parent = parent.split("!-!")[0]; 
          }   
          retval = parent + '<br />' + startDate + ": " +  Highcharts.dateFormat('%A, %b %d %Y', this.x) + '<br />' + endDate + Highcharts.dateFormat('%A, %b %d %Y', this.x2)
          return retval;
        }
      },
      lang: {
        noData: 'Nichts zu anzeigen',
        accessibility: {
          axis: {
            xAxisDescriptionPlural: 'The chart has a two-part X axis showing time in both week numbers and days.',
            yAxisDescriptionSingular: 'The chart has a tabular Y axis showing a data table row for each point.'
          }
        }, 
        contextButtonTitle:LBLCONTMENU,
        decimalPoint:'.',
        printChart:LBLPRINTCRT,
        downloadJPEG:LBLDNJPEG,
        downloadPDF:LBLDNPDF,
        downloadPNG:LBLDNPNG,
        downloadSVG:LBLDNSVG, 
        downloadCSV:'Download CSV',
        downloadXLS:'Download XLS', 
        exitFullscreen:'Exit from full screen',
        viewFullscreen:LBLVWFULL
      },
      accessibility: {
        point: {
          descriptionFormatter: function(point) {
            var completedValue = point.completed ?
              point.completed.amount || point.completed : null,
              completed = completedValue ?
              ' Task completed ' + Math.round(completedValue * 1000) / 10 + '%.' :
              '';
            return Highcharts.format(
              '{point.yCategory}.{completed} Start {point.x:%Y-%m-%d}, end {point.x2:%Y-%m-%d}.', {
                point,
                completed
              }
            );
          }
        }
      },
      credits: {
        enabled: false
      }, 
      scrollbar: {
        enabled: true
      },
      rangeSelector: {
        enabled: true,
      },
   
    }  
    
    }
  
    return (
      <div className="success-factors">
        <div className="page-header-field" id="page-header-field">
          <h3 className="page-title" id="header_det_list">
            <span id="subheader_det2"></span>
          </h3>

          <div className="btn-align" id="header_reset">
            <div className="btn-align" id="edit_teams1" tabindex="-1">
            {mobView?"":
              <button   id="gantt_btn"
                style={{ display: teamSuccessFactorListData.length > 0 && this.state.base_date ? "" : "none", marginRight: "10px", pointerEvents:this.state.base_date?"all":"none" }}  
                className={"button-style primary-btn"}
                onClick={this.showGanttChartPopup.bind(this, 1)}
              >
                <span id="ganttchart"> {LBLGANTTCHART ? LBLGANTTCHART : "Gantt Chart"} </span>
              </button>
              }
              <button style={{ marginRight: "10px" }}
                className="button-style primary-btn"
                onClick={this.props.location.pathname.indexOf("/successfactors") > -1 ? () => {
                  let dirpath = "/schome/"
                  if (window.location.href.indexOf("/home/") > -1) {
                    dirpath = "/home/"
                  }
                  let backlinkurl = "steeringcommittee"
                  this.props.history.push({
                    pathname: dirpath + "steeringcommittee",
                    state: {
                      backlink: true,
                      backlinkurl: backlinkurl,
                      successFactorData: this.state.successFactorData,
                      teamData: this.state.teamData
                    }
                  });
                } : () => { }}
              >
                <span id="updateteam"> {LBLSTEERCOMTEAMS}</span>
              </button>

              <button style={{ marginRight: "10px" }}
                className="button-style primary-btn"
                onClick={this.props.location.pathname.indexOf("/successfactors") > -1 ? () => {
                  let dirpath = "/schome/"
                  if (window.location.href.indexOf("/home/") > -1) {
                    dirpath = "/home/"
                  }
                  let backlinkurl = this.state.steercomSettings &&
                    this.state.loadingSFTasksList == true ? "successfactorsetup" : "successfactors"
                  this.props.history.push({
                    pathname: dirpath + "successfactorplan",
                    state: {
                      backlink: true,
                      backlinkurl: backlinkurl,
                      successFactorData: this.state.successFactorData,
                      teamData: this.state.teamData
                    }
                  });
                } : () => { }}
              >
                <span id="updateplan"> {LBLUPDATEPLAN}</span>
              </button>
              <div className="btn-group ml-auto mr-13" role="group" style={{ marginTop: "-15px", marginRight: "-10px !important",
                   display: teamSuccessFactorListData.length > 0 && this.state.base_date ? "" : "none" }}>
                <span
                  id="CSTFilter_spinner"
                  style={{
                    marginTop: "10px",
                    display: "none",
                    marginRight: "10px",
                  }}
                >
                  {/* <Spinner animation="grow" /> */}
                  <Loader></Loader>
                </span>

                <button
                  id="btnGroupDrop1"
                  type="button"
                  className="btn basicSelItem dropdown-toggle cls_export"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-mail-forward" aria-hidden="true">
                    &#xf064;
                  </i>{" "}
                  {LBLEXPORT}
                </button>

                <div
                  className="dropdown-menu  dropdown-menu-right"
                  aria-labelledby="btnGroupDrop1"
                >
                  {!this.props.restrictExport || (this.props.restrictExport && this.props.restrictExport !== "csv") ?
                    <span
                      className="dropdown-item cls_export_items"
                      onClick={this.onExport.bind(this, "csv")}
                    >
                      {LBLEXPORTTOCSV}
                    </span>
                    : ""
                  }

                  <span
                    className="dropdown-item cls_export_items"
                    onClick={this.onExport.bind(this, "excel")}
                  >
                    {LBLEXPORTTOEXCEL}
                  </span>
                 
                  
                </div>

              </div>
            </div>

            <div style={{ display: "none" }} className="cls_save_warn">{LBLCNFRMUPDDATE2}</div>
          </div>
        </div>

        <div
          className="team_main_container"
        >
          <div id="dialog1">
            <Dialog
              open={this.state.opendialog1}
              onClose={this.handleCloseDialog1}
              aria-labelledby="responsive-dialog-title"
              style={{ minWidth: 600 }}
              ref="addNewDialog1"
              className="success-factors"
            >
              <div>
                <DialogTitle id="responsive-dialog-title" className="mui-title">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div > {!this.state.base_date ? LBLSETBASEDATE : LBLUPDATEBASEDATE} </div>
                    <i style={{ margin: "2px 13px 0px 13px"}} class="fas fa-times fas fa-bars mui-close-icon" onClick={this.handleCloseDialog1}></i>
                  </div>
                </DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    {this.state.base_date ?
                      <div className="mui-modal-body">
                        <div style={{ marginBottom: "12px" }}> {LBLCNFRMUPDDATE1} </div>
                        <div style={{ marginBottom: "12px" }}> {LBLCNFRMUPDDATE11} </div>
                        <div style={{ marginBottom: "12px" }}> {LBLDUPRCD} </div>

                      </div>
                      :
                      <div className="mui-modal-body">
                        <div style={{ marginBottom: "12px" }}> {LBLCNFRMUPDDATE3} </div>
                      </div>
                    }
                  </DialogContentText>
                </DialogContent>
                <DialogActions className="mui-footer">
                  <button id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog1}>
                    {LBLNO}
                  </button>
                  <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog1}>
                    {LBLAGREE}
                  </button>
                </DialogActions>
              </div>
            </Dialog>
          </div>
          <div id="dialog2">
            <Dialog 
              className="cls_dialog success-factors"
              open={this.state.opendialog}
              onClose={this.handleCloseDialog}
              aria-labelledby="responsive-dialog-title"
              contentStyle={{ minWidth: "500px" }}
            >
              <div style={{ width: "500px"}}>
                <DialogTitle id="responsive-dialog-title" className="mui-title">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>{LBLRESETALL} </div>
                    <i style={{ margin: "2px 13px 0px 13px"}} class="fas fa-times fas fa-bars mui-close-icon" onClick={this.handleCloseDialog}></i>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText >
                    <div >
                    <div className="cls_confirm_details mui-modal-body" > {LBLCNFRESETALLDATE} </div>
                    <br></br>
                    <div className="cls_confirm_details1 mui-modal-body" > {LBLDUPRCD} </div>
                    </div>
                  </DialogContentText>
                </DialogContent>

                <DialogActions className="mui-footer">
                  <button autoFocus id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog}>
                    {LBLNO}
                  </button>
                  <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog}>
                    {LBLAGREE}
                  </button>
                </DialogActions>
              </div>
            </Dialog>
          </div>
          <div id="dialog3">
            <Dialog 
              className="cls_dialog success-factors"
              open={this.state.opendialog3}
              onClose={this.handleCloseDialog3}
              aria-labelledby="responsive-dialog-title"
              contentStyle={{minWidth: "500px" }}
            >
              <div style={{ width: "500px"}}>
                <DialogTitle id="responsive-dialog-title" className="mui-title">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div >{LBLSTEERCOMSFSETUP} </div>
                    <i style={{ margin: "2px 13px 0px 13px"}} className="fas fa-times fas fa-bars mui-close-icon" onClick={this.handleCloseDialog3}></i>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText >
                    <div className="cls_confirm_details mui-modal-body"> {LBLUNSAVEDATA} </div>
                  </DialogContentText>
                </DialogContent>

                <DialogActions className="mui-footer">
                  <button autoFocus id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog3}>
                    {LBLNO}
                  </button>
                  <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog3}>
                    {LBLAGREE}
                  </button>
                </DialogActions>
              </div>
            </Dialog>
          </div>
          <div id="dialog4">
            <Dialog 
              className="cls_dialog success-factors"
              open={this.state.opendialog4}
              onClose={this.handleCloseDialog4}
              aria-labelledby="responsive-dialog-title"
              contentStyle={{ minWidth: "500px" }}
            >
              <div style={{ width: "500px"}}>
                <DialogTitle id="responsive-dialog-title" className="mui-title">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div >{LBLSTEERCOMSFSETUP} </div>
                    <i style={{ margin: "2px 13px 0px 13px"}} class="fas fa-times fas fa-bars mui-close-icon" onClick={this.handleCloseDialog4}></i>
                  </div>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText >
                    {/* <div style={{ color: "#f33434" }}> {LBLCNFRESETALLDATE} </div> */}
                    <div className="mui-modal-body"> {lang_confirmdata} </div>
                  </DialogContentText>
                </DialogContent>

                <DialogActions  className="mui-footer">
                  <button autoFocus id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog4} >
                    {LBLNO}
                  </button>
                  <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog4} >
                    {LBLAGREE}
                  </button>
                </DialogActions>
              </div>
            </Dialog>
          </div>
          <Snackbar
            anchorOrigin={{ horizontal, vertical }}
            key={"top-center"}
            open={this.state.openalert}
            autoHideDuration={this.state.showtime ? this.state.showtime : 5000}
            onClose={this.handleCloseAlert}
            bodyStyle={{ backgroundColor: "teal", color: "coral" }}
          >
            <Alert
              variant="filled"
              onClose={this.handleClose}
              severity={this.state.messageType}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <Modal
            animation={false}
            show={this.state.showDescModal}
            onHide={this.closeDescription}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title"> 
                  {this.state.nameData}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
              >
                {this.state.descriptionData}
              </div>
            </Modal.Body>
            <Modal.Footer style={{borderTop:"none"}}>
              <div className="ssi-feeds-commentsActionBtn"></div>
            </Modal.Footer>
          </Modal>
          <Modal
            animation={false}
            show={this.state.showmodal}
            onHide={this.closeRefModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          //centered
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title  className="alert-modal-title">
                <h4 className="modal-title" style={{ lineHeight: "2.5" }}>
                  {LBLREFERENCELNKS}
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReferenceMaterial
                initialValues={this.state.initialValues}
                resultData={this.props.productListData}
                labels={this.props.labels}
                changesIn={this.changesIn}
                closeModal={this.props.closeRefModal}
                resourceData={this.state.resourceData}
                resourceDataCustom={this.state.resourceDataCustom}
              ></ReferenceMaterial>
            </Modal.Body>
          </Modal> 
          {this.state.loading ? (
            <Skeleton height={300} />
          ) : (
            this.state.showGantPopup == false ?
              <div>
                <div style={{ height: "115px" }} class="cls_top_border">
                  <div class="row" style={{marginBottom:"10px" }}>
                    <div className="col-xl-2 col-sm-2">
                      <label className="label">{LBLSF}</label>
                      <ThemeProvider theme={theme}>
                      <Autocomplete
                        id="members"
                        style={{ width: "calc(100%)" }}
                        label={LBLSF}
                        placeholder={LBLSF}
                        disableClearable
                        options={this.state.successFactorsList}
                        //options={this.state.successFactorsListYears}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, values) =>
                          option.id === values.id
                        }
                        onChange={this.handleSFChange.bind(this)}
                        underlineStyle={{ display: "none" }}
                        value={this.state.successFactorData}
                        defaultValue={this.state.successFactorData}
                        renderOption={(option) => (
                          <div style={{ fontSize: "14px" }}>{option.title}</div>
                        )}
                        renderInput={(params) => (
                          <TextField id="sflist" {...params} />
                        )}
                        underline="none"
                      />
                      </ThemeProvider>
                    </div>
                    <div className="col-xl-2 col-sm-2">
                      <label className="label">{LBLTEAM}</label>
                      {this.state.teamList.length > 0 && this.state.teamData.team_id ? <>
                        <ThemeProvider theme={theme}>
                        <Autocomplete
                          id="members"
                          style={{ width: "calc(100%)" }}
                          label={LBLTEAM}
                          disableClearable
                          options={this.state.teamList}
                          getOptionLabel={(option) => option.team_name.toString()}
                          getOptionSelected={(option, values) =>
                            option.team_id === values.team_id
                          }
                          onChange={this.handleTeamChange.bind(this)}
                          underlineStyle={{ display: "none" }}
                          defaultValue={
                            this.state.teamData.team_id
                              ? this.state.teamData
                              : this.state.teamList[0]
                          }
                          renderOption={(option) => (
                            <div style={{ fontSize: "14px" }}>{option.team_name}</div>
                          )}
                          renderInput={(params) => (
                            <TextField id="sflist" {...params} />
                          )}
                          underline="none"
                        />
                       </ThemeProvider>
                      </> :
                        <>
                          <div>
                          <ThemeProvider theme={theme}>
                            <Autocomplete
                              style={{ width: "calc(100%)" }}
                              label={LBLTEAM}
                              disableClearable
                              options={[{ "team_id": 0, "team_name": LBLNONE ? LBLNONE : "-- None --" }]}
                              getOptionLabel={(option) => (option || { "team_id": 0, "team_name": LBLNONE ? LBLNONE : "-- None --" }).team_name.toString()}
                              getOptionSelected={(option, values) =>
                                option.team_id === values.team_id
                              }
                              //onChange={this.handleTeamChange.bind(this)}
                              underlineStyle={{ display: "none" }}
                              defaultValue={
                                { "team_id": 0, "team_name": LBLNONE ? LBLNONE : "-- None --" }
                              }
                              renderInput={(params) => (
                                <TextField id="tmlist" {...params} />
                              )}
                              underline="none"
                            />
                            </ThemeProvider>
                          </div>
                        </>
                      }
                      {(this.props.userId == this.state.teamData.created_by ||
                        this.props.userId == this.state.teamData.team_administrator ||
                        this.props.userId == this.state.teamData.team_addnl_administrator) ? "" :
                        <div id="viewmode" style={{ display: this.state.loadingSection ? "" : "none" }} className="cls_view_mode">
                          {this.state.teamList.length > 0 && this.state.teamData.team_id ? this.props.labels.labels.LBLVIEWMODE : ""}
                        </div>}

                    </div>
                    {!show_language ? (
                      <div className="col-xl-2 col-sm-2">
                        {this.props.userId == this.state.teamData.created_by ?
                          <label><span onClick={this.showLanguage}
                            className="label labelChangeTemplate">{LBLCHNGLANGUAGE}</span></label> :
                          <label><span className="label labelChangeTemplateDefault">{LBLCHNGLANGUAGE}</span></label>}
                        <p className="cls_ellipsis" title={this.state.teamTemplate && this.state.teamTemplate.template_name}>
                          <span className="labelTextDefault">{this.state.teamTemplate && this.state.teamTemplate.template_name}</span></p>
                      </div>)
                      : ""}
                    {this.state.steercomSettings.templates && show_language ?
                      <div className="col-xl-2 col-sm-2">
                        <label className="label">{LBLCHNGLANGUAGE}</label>
                        <ThemeProvider theme={theme}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={this.state.steercomSettings.templates}
                          getOptionLabel={(option) => option.template_name.toString()}
                          getOptionSelected={(option, values) =>
                            option.template_id === values.template_id
                          }

                          // style={{ width: 400 }}
                          onChange={this.handleTemplateChange}
                          disableClearable
                          disabled={(this.props.userId == this.state.teamData.created_by) ? false : true}
                          defaultValue={this.state.teamTemplate}
                          renderOption={(option) => (
                            <div style={{ fontSize: "14px" }}>{option.template_name}</div>
                          )}
                          value={this.state.teamTemplate}
                          renderInput={(params) => (
                            <TextField
                              value="surs"
                              text="sur"
                              {...params}

                            />
                          )}
                        />
                         </ThemeProvider>
                      </div>
                      : ""}
                    <div className="col-xl-1"></div>
                    {this.state.teamSuccessFactorList.length > 0 ?
                      <div className="col-xl-5" style={{ border: "0px solid" }}>
                        <div className="row" style={{justifyContent:"right",left: "-10px",position: "relative",alignItems : "center"}}>
                          {/* <div className="col-sm-1"></div> */}
                          <div className="col-xl-5" style={{ marginRight: "23px" }}>
                            <label className="label">{LBLBASEDATE}</label>
                            <MuiPickersUtilsProvider
                              className="member_startdate"
                              utils={DateFnsUtils}
                              style={{ width: "calc(100% - 1px)", float: "right" }}
                            >
                              <Grid container justify="space-around">
                                <KeyboardDatePicker
                                  margin="normal"
                                  //minDate={new Date()}
                                  minDate={"2018-01-02"}
                                  id={"task_basedate"}
                                  // label={LBLBASEDATE}
                                  format={this.state.dateformat}
                                  value={this.state.new_base_date}
                                  onChange={this.handleBaseDateChange.bind(
                                    this,
                                    "base_date"
                                  )}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  disabled={(this.props.userId == this.state.teamData.created_by ||
                                    this.props.userId ==
                                    this.state.teamData.team_administrator ||
                                    this.props.userId == this.state.teamData.team_addnl_administrator) ? false : true}
                                  InputProps={{
                                    disableUnderline: false,
                                    readOnly: true,
                                  }}
                                  style={{ width: "100%" }}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </div>
                          {this.props.userId == this.state.teamData.created_by ||
                            this.props.userId == this.state.teamData.team_administrator ||
                            this.props.userId == this.state.teamData.team_addnl_administrator ? (
                            <>
                              {this.state.teamSuccessFactorList.length > 0 ?
                                <div className="col-xl-66 ">
                                  <button
                                    type="button"
                                    className="button-style primary-btn"
                                    id="btnApplyDate"
                                    disabled={this.state.new_base_date ? false : true}
                                    onClick={this.openConfirmDialog1.bind(this)}
                                    // onClick={this.saveSFBaseDate.bind(this)}
                                    style={{
                                      marginTop: "10px",
                                      maxWidth:"200px"
                                    }}
                                  >
                                    {!this.state.base_date
                                      ? LBLSETBASEDATE
                                      : LBLUPDATEBASEDATE}
                                  </button>
                                </div>
                                : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      : ""}
                  </div>
                  {this.state.loadingSection && this.state.teamSuccessFactorList.length > 0 ?
                    <div class="row" style={{ paddingBottom: "0px" }}>
                      {this.state.base_date &&
                        (this.props.userId == this.state.teamData.created_by ||
                          this.props.userId == this.state.teamData.team_administrator ||
                          this.props.userId == this.state.teamData.team_addnl_administrator) ? (
                        <>
                          <div style={{ marginRight: "18px" }}
                            className="cls_resetall"
                            onClick={this.openConfirmDialog.bind(this)}
                          >
                            <div>{LBLRESETALL}</div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {!this.state.base_date && this.state.teamData.team_id ? (
                        <>
                          {this.props.userId == this.state.teamData.created_by ||
                            this.props.userId == this.state.teamData.team_administrator ||
                            this.props.userId == this.state.teamData.team_addnl_administrator ?
                            <div
                              className="clsBaseData"
                            >
                              {LBLSELECTBASEDATE}
                            </div> : ""}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    : ""}
                </div>
                <div
                  id="legends"
                  class="row"
                  style={{ float: "left", marginTop: "10px", display: this.state.teamSuccessFactorList.length > 0 ? "" : "none" }} //marginBottom:"-18px"
                >
                  <div className="cls_legends_name">{LBLLEGENDS}</div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/notstarted.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div style={{ marginLeft: "-7px" }} class="cls_img_status">{LBLYETTOSTART}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/ontrack.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLINPROGONTRACK}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/delayed.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLINPROGONDELAY}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/completed.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLCOMPLETED}</div>
                  </div>
                  <div className=" cls_legends cls_ellipsis" style={{ width: "120px" }}>
                    <img style={{ transform: "scale(0.5)" }}
                      src={"../images/steercom/Expired.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLEXPIRED}</div>
                  </div>
                </div>
                {(isAssociated && this.state.base_date && (this.props.userId == this.state.teamData.created_by ||
                  this.props.userId == this.state.teamData.team_administrator ||
                  this.props.userId == this.state.teamData.team_addnl_administrator)) ?
                  <div className="cls_notification cls_legends" style={{ borderRadius: "3px", float: "right", 
                  display: teamSuccessFactorListData.length > 0 && this.state.base_date && notification_enabled ? "" : "none",minHeight:"40px" }}>
                   <button className="button-style primary-btn"  >
                      <span onClick={this.sendNotification.bind()} id="updateplan"> {LBLSENDNOTIFICATION}</span>
                    </button>
                  </div> : ""}                 
                <div
                  class="row"
                  style={{
                    borderBottom: "0px solid red",
                    margin: "20px",
                    width: "95.5%",
                  }}
                >
                  <table></table>
                  <div
                    style={{
                      marginBottom: "0px",
                      width: "calc(100% + 105px)",
                      marginLeft: "-47px",
                    }}
                  >
                    {this.state.isloading? 
                      <div style={{margin:'10% 0px'}}><Loader></Loader></div> : 
                     this.state.teamSFListLoading === true && this.state.teamList.length > 0 && this.state.teamData.team_id && this.state.teamSuccessFactorList.length > 0 ? (
                      <DataTable
                        columns={teamSuccessFactorColumns}
                        data={teamSuccessFactorListData}
                        className="datatable-list"
                        rowDrag={true} 
                        noDataComponent= {<EmptyState image={emptyImage} text2={LBLNORECORDS}/> }  
                      style={{
                          border: "0px solid red",
                          backgroundColor: "#fff",
                          position: "relative",
                          top: "-37px",
                        }}
                        fixedHeader
                        fixedHeaderScrollHeight={$("#root").height() - 334 + "px"}

                        subHeader="true"
                        subHeaderAlign="left"
                        subHeaderComponent={<Tooltip class="cls_add_expand_collapse"
                          title={""} arrow>
                          <div className="cls_add_expand_collapse" style={{ top: "10px", display: "none" }}>
                            <img
                              src={this.state.expandFlag ?
                                "../images/steercom/expanded.png" :
                                "../images/steercom/collapsed.png"
                              }
                              className="img_collapse_all"
                              alt=""
                              onClick={this.updateExpandFlagAll.bind(
                                this, this.state.expandFlag
                              )}
                              title={this.state.expandFlag ? "Collapse All" : "Expand All"}
                            ></img>
                          </div>
                        </Tooltip>}
                      />
                    ) : (
                      <div
                        class="cls_norecords"
                        style={{ borderTop: "0px solid lightgrey" }}
                       >
                        {this.state.teamlistcnt == true && this.state.teamList.length === 0 ?
                          <EmptyState image={emptyImage} text2={LBLTEAMNORECORDS}/>  
                          : null
                        }
                      </div>
                    )}
                  </div>
                </div>
                {this.state.isPriorTeam ? (
                  ""
                ) : (
                  <div class="row" style={{ borderTop: "0px solid lightgrey" }}>
                    {this.state.base_date &&
                      (this.props.userId === this.state.teamData.created_by ||
                        this.props.userId === this.state.teamData.team_administrator ||
                        this.props.userId === this.state.teamData.team_addnl_administrator) ? (
                      <>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              : ""
          )}
        </div> 
        {this.state.showGantPopup ? (

          <div className="cls_chart_container" >
            <div className="page-header-field" style={{ marginTop: "10px" }}>
              <h3 className="page-title" id="header_det_list">
              <i
              className="fas fa-arrow-left backBtn"
              aria-hidden="true"
              onClick={this.closeGantPopup.bind(this, 1)}
            ></i>
                <span id="subheader_det3">{this.props.labels.labels.LBLSTEERCOMSFSETUP}</span>
              </h3> 
              <div className="row"  style={{display:"flex",width:"55%"}}> 
              <div className="col-sm-7" style={{display:"flex"}}>
                      <label style={{position:"relative",top:"2px",right:"4px"}} className="label">{LBLSF}</label>
                      <ThemeProvider theme={theme}> 
                      <Autocomplete
                        id="memberss"
                        style={{ width: "calc(100%)",marginLeft: "20px" }}
                        label={LBLSF}
                        placeholder={LBLSF}
                        disableClearable
                        options={this.state.successFactorsList}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, values) =>
                          option.id === values.id
                        }
                        onChange={this.handleSFChangeChart.bind(this)}
                        underlineStyle={{ display: "none" }}
                        value={this.state.successFactorDataChart}
                        defaultValue={this.state.successFactorDataChart}
                        renderOption={(option) => (
                          <div style={{ fontSize: "14px" }}>{option.title}</div>
                        )}
                        renderInput={(params) => (
                          <TextField id="sflists" {...params} />
                        )}
                        underline="none"
                      /> 
                      </ThemeProvider>
              </div>
              <div className="col-xl-5" style={{ position: "relative", top: "2px" }}>  
              <div style={{display:"flex"}}>
                <label style={{minWidth:"80px",marginTop:"4px"}} className="label">{LBLBASEDATE}</label>
                <MuiPickersUtilsProvider utils={DateFnsUtils}> 
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      margin="normal"
                      id="startdate" 
                      format={this.state.dateformat}
                      value={this.state.selectedBaseDate}
                      onChange={this.handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{ readOnly: true }} 
                      disabled = {true}
                    />
                  </Grid>
                  
                  <span style={{ display: "none" }} className="required" id="span_startdate">
                    {LBLREQUIRED}
                  </span>
                  
                </MuiPickersUtilsProvider>
                </div>
              </div> 
              </div>
            </div>
            <hr></hr>
            <div id="chart_legents" className="row cls_chart_legends">
            <div
                  id="legends"
                  class="row"
                  style={{ float: "left", marginTop: "10px", display: this.state.teamSuccessFactorList.length > 0 ? "" : "none" }} //marginBottom:"-18px"
                >
                  <div className="cls_legends_name">{LBLLEGENDS}</div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/notstarted.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div style={{ marginLeft: "-7px" }} class="cls_img_status">{LBLYETTOSTART}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/ontrack.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLINPROGONTRACK}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/delayed.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLINPROGONDELAY}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/completed.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLCOMPLETED}</div>
                  </div>
                   
                  <div className=" cls_legends"> <div className="cls_milestone"></div>  {LBLMILESTONES ? LBLMILESTONES : "Milestone"}</div> 

                </div>
            </div> 
            {loadChart ?
            
              <div id="plan_report_container" className="cls_chart_main_container" > 
                <HighchartsReact
                  ref={this.chart} 
                  highcharts={Highcharts}
                  constructorType={"ganttChart"}
                  options={goptions} 
                />
              </div>
              : showLoading === true ?
                <div style={{ height: "200px", textAlign: "center", marginTop: "15%" }}>
                  <Loading></Loading>
                </div> :
                <div style={{ height: "200px", textAlign: "center", marginTop: "15%" }}>
                  {""}
                </div>}
          </div>
        ) : ""}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};
export default connect(mapStateToProps, null)(SuccessFactors);

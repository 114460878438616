import React from "react";
import UsersService from "../user-list/UsersService"; 
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Select } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import Skeleton from "react-loading-skeleton";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import "date-fns";
import ScCommonServices from "../../../services/common_services";
import Image from "material-ui-image";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { S3MultiUpload } from "../../../shared/s3upload";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import utils from "../../../utils/utils";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { enUS,itIT,esES,deDE, ptPT, ptBR, frFR,svSE, etEE, faIR, fiFI ,ruRU} from '@material-ui/core/locale';
 
let locale_code = {"en-US":enUS,"it-IT":enUS,"es-MX":esES,"de-DE":deDE,
                   "pt-BR":ptBR,"fr-FR":frFR,"sv-SE":svSE,"ru-RU":ruRU};
let theme = createTheme(  enUS); 

let currId = "";
const $ = require("jquery");
const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

class CreateTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editTeam: this.props.editTeam,
      teamData: this.props.teamData,
      steercomSettings: this.props.steercomSettings,
      userlist: [],
      addnluserlist: [],
      groupTypeNames: [],
      prevImageName:"",
      openalert: false,
      groups: [],
      imageUrl:"", 
      loading: true,
      uploadingSize:0,
      submitting: false,
      showtime: 3000,
      teamLanguage: { "template_id": "default", "template_name": "Default" },
      dateformat: props.dateformat,// "dd/MM/yyyy",
      viewmode: true,
      viewadmin: true,
      adminOpenState: "",
      groupOpenState: {
        GP_1: false,
        GP_2: false,
        GP_3: false,
        GP_4: false,
        GP_5: false,
      },
      image_name:"",
      attachedFiles: [],
      budgetOpenState: "",
      currencyOpenState: "",
      frequencyOpenState: "",
      currId: "",
      teamName: "",
      teamDescription: "",
      teamAdmin: "",
      addnlteamAdmin: "",
      teamAdminName: "",
      addnlteamAdminName: "",
      email_notification:null,
      teamStartDate: new Date().toISOString().split("T")[0] + "T00:00:00.000Z",
      teamGroups: {
        GP_1: [{ id: "", name: "" }],
        GP_2: [{ id: "", name: "" }],
        GP_3: [{ id: "", name: "" }],
        GP_4: [{ id: "", name: "" }],
        GP_5: [{ id: "", name: "" }],
      },
      teamMeetingFrequency: "EVERY2WEEKS",
      teamIsBudgetAllocated: 1,
      showBudget: true,
      teamBudgetYear: "2021",
      teamBudgetAmount: "",
      teamCurrency: "",

      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
    };
      theme = createTheme( locale_code[this.props.language_code]);   
  }
  getSteercomUsers=async()=>{
    let xx=0;
    let result = await SteercomServices.getStreercomUsers();
    result = result.result;
    result = result[0].users==null?[]:JSON.parse(result[0].users.users); 
    let {userlist} = this.state;  
  } 
  getUsers = async() => { 
    let result = await SteercomServices.getStreercomUsers();
    UsersService.getUsers({ active: true })
      .then((res) => {
       let allusers=[], suserslist=[], userlist = res.result; 
       suserslist = result.result[0]?JSON.parse(result.result[0][0].users):[];
        userlist.map((item,x)=>{
          suserslist.map((itm,y)=>{
            if(itm.user_id == item.user_id){
              allusers.push(item)
            }
          });
        });

        this.sortResults(allusers, "name", true);
        let user_response = allusers; 
        user_response.splice(0, 0, { user_id: "0", name: "" });
        this.setState((state, props) => ({
          userlist: allusers,
          addnluserlist: user_response
        }));
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  sortResults = (arr, prop, asc) => {
    arr.sort(function (a, b) {
      if (asc) {
        return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
      } else {
        return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
      }
    });
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };

  componentDidMount() {
    $('#root').scrollTop(0);
    var header = this.props.labels.labels.LBLSTEERCOMDET;
    var isedit = this.state.isedit;
    //var subheader = this.props.
    $("#header_det_committee").text(header);
    $("#sub_header_det").hide();
    $("#header_top_edit").show();
    if (this.state.editTeam) {
      //$("#edit_teams").show();
      $("#edit_teams").css({ display: "flex" });
      $("#task_setup").show();
    }
    let getData = {
      language: this.props.language,
    };
    this.getUsers(); 
    this.setdateformat();
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups; 
      //{group_type_id: "GP_1", lbl_GP_1: "Location", Active: "1"}
      let iresponse = [];
      let ipgroups = {};
      let igroupnames = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];

        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroupnames["_" + groups[i].groups_by_type[x].group_id] =
            groups[i].groups_by_type[x].group_name;
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        this.sortResults(igroups, "value", true)
        ipgroups[groups[i].group_type_id] = igroups;
      }
      let filters = {};
      let xfilters = { blur: 1 };
      let conditionsdata = {};
      for (var i = 0; i < iresponse.length; i++) {
        if (iresponse[i].Active === "1") {
          console.log(iresponse[i]["lbl_" + iresponse[i].group_type_id]);
          filters[iresponse[i].group_type_id] = {
            name: iresponse[i]["lbl_" + iresponse[i].group_type_id],
            filter: {
              type: "string",
              showField: true,
              checked: true,
              multi: true,
              condition: {
                name: iresponse[i]["lbl_" + iresponse[i].group_type_id],
                operator: "MULTI_EQUALS",
                value: [],
                operatorText: this.props.labels.labels.MULTI_EQUALS,
              },
            },
          };
          xfilters[iresponse[i].group_type_id] = {
            filterData: ipgroups[iresponse[i].group_type_id],
            responseData: [],
            selItemforMultiInput: {},
            query: "",
          };
        }
      } 
      this.getCompanySettings();
      iresponse.map((item, idx) => {
        let xx = item;
        ipgroups[item.group_type_id].splice(0, 0, { id: "0", value: "" });
      });
      this.setState({
        groupTypeNames: iresponse,
        groups: ipgroups,
        groupnames: igroupnames,
        filters: filters,
        xfilters: xfilters,
        loading: false,
      });
    });
    let loc_team_id="";
    if(window.location.search!=""){
      loc_team_id = window.location.search.split("=")[1];
    }
    if (this.state.editTeam || loc_team_id!="") {
      this.editTeamDetails();
    } 
  }

  setviewmode = (viewmode) => {
    if (!viewmode) {
      $("#top_container").css({ "pointer-events": "none" });
    } else {
      $("#top_container").css({ "pointer-events": "all" });
    }
  };
  getCompanySettings = () => {
    HttpInterceptor.get("settings/?language=" + this.props.language).then(
      (res) => { 
        this.setState({ 
          email_notification: res.data.result.templates_status 
        });
      }
    );
  };  
  editTeamDetails = () => {
    var viewmode = true,teamData;
     teamData = this.props.teamData;//this.state.teamData;
    // teamData =  this.state.teamData;
    let ltime = teamData.start_date.split("T")[1];
    let startDate = teamData.start_date.split("T")[0] + "T00:00:00.000Z";
    if (teamData.created_by == this.props.userId || teamData.team_administrator == this.props.userId ||
      teamData.team_addnl_administrator == this.props.userId) {
      viewmode = true;
    } else {
      viewmode = false;
    }
    if (teamData.team_status == 0) {
      viewmode = false;
    }
    let selected_language, languages = this.props.steercomSettings.templates;
    languages.map((item, idx) => {
      if (item.template_id == teamData.template_id) {
        selected_language = item;
      }
    })

    this.setState({
      teamId: teamData.teamId,
      teamName: teamData.team_name,
      teamLanguage: selected_language,
      teamDescription: teamData.team_description,
      teamAdmin: teamData.team_administrator,
      addnlteamAdmin: teamData.team_addnl_administrator ? teamData.team_addnl_administrator : null, //need to change
      teamStartDate: startDate,
      teamGroups: JSON.parse(teamData.groups),
      teamMeetingFrequency: teamData.meeting_frequency,
      teamIsBudgetAllocated: teamData.is_annual_budget_allocated,
      teamBudgetYear: new Date("01/02/" + teamData.budget_year),
      teamBudgetAmount: teamData.budget_amount,
      teamCurrency: teamData.currency,
      showBudget: teamData.is_annual_budget_allocated == 0 ? false : true,
      viewmode: viewmode,
      image_name:teamData.image_name,
      prevImageName:teamData.image_name,
      attachedFiles:[{name:teamData.image_name}]
    }); 
     this.getImage(teamData.image_name)
  };
  handleNameChange = (event) => {
    let currname = event.target.value;
    if ($.trim(currname) != "") {
      $("#span_teamname").hide();
    } else {
      $("#span_teamname").show();
    }
    this.setState({
      teamName: currname,
    });
  };
  handleDescriptionChange = (event) => {
    if ($.trim(event.target.value).length < 2000) {
      $("#span_teamdesc").hide();
    } else {
      $("#span_teamdesc").show();
    }

    this.setState({
      teamDescription: event.target.value,
    });
  };
  handleAdminChange = (event, values) => {
    let curr_userid = parseInt(values.user_id);
    let { teamAdmin } = this.state;
    if ((curr_userid || 0) != "") {
      $("#span_teamadmin").hide();
    } else {
      $("#span_teamadmin").show();
    }
    this.setState({
      teamAdmin: values.user_id,
      teamAdminName: values.name
    });
  };
  handleAddnlAdminChange = (event, values) => {
    let curr_userid = values.user_id;
    this.setState({
      addnlteamAdmin: values.user_id,
      addnlteamAdminName: values.name
    });
  };
  handleLanguageChange = (event, values) => {
    if (values.id != "") {
      $("#span_teamlanguage").hide();
    } else {
      $("#span_teamlanguage").show();
    }
    this.setState({
      teamLanguage: values,
    });
  };
  handleAdminOpen = () => {
    this.setState({
      adminOpenState: true,
    });
  };
  handleAdminClose = () => {
    this.setState({
      adminOpenState: false,
    });
  };
  handleStartDateChange = (date) => {
    console.log(date);
    if ((date != "" || date == "Invalid Date") && date != null) {
      $("#span_startdate").hide();
    } else {
      $("#span_startdate").show();
    }
    this.setState({
      teamStartDate: date,
      //teamBudgetYear: date.getFullYear(),
      error: true,
    });
  };
  handleGroupChange = (item, event, values) => {
    console.log("event.target", event.target);
    let teamGroups = this.state.teamGroups;
    if (values.value == "" || values.id == "0") {
      teamGroups[item.group_type_id] = [{ id: "", name: "" }];
    } else {
      teamGroups[item.group_type_id] = [
        { id: values.id, name: this.state.groupnames["_" + values.id] },
      ];
    }
    console.log(
      "teamGroups[item.group_type_id]",
      teamGroups[item.group_type_id]
    );
    this.setState({
      teamGroups,
    });
  };
  handleGroupOpen = (item) => {
    let groupOpenState = this.state.groupOpenState;
    groupOpenState[item.group_type_id] = true;
    this.setState({
      groupOpenState,
    });
  };
  handleGroupClose = (item) => {
    let groupOpenState = this.state.groupOpenState;
    groupOpenState[item.group_type_id] = false;
    this.setState({
      groupOpenState,
    });
  };
  handleMeetingFrequencyChange = (event, values) => {
    this.setState({
      teamMeetingFrequency: values.id,
    });
  };
  handleMeetingFrequencyOpen = () => {
    this.setState({
      frequencyOpenState: true,
    });
  };
  handleMeetingFrequencyClose = () => {
    this.setState({
      frequencyOpenState: false,
    });
  };
  handleIsBudgetChange = (val, value) => {
    var teamBudgetYear = "",
      teamBudgetAmount = "",
      teamCurrency = "",
      currVal,
      showBudget = true;
    currVal = parseInt(val.currentTarget.value);
    if (currVal == 0) {
      showBudget = false;
      teamBudgetAmount = "";
      teamCurrency = "";
    } else {

    }
    this.setState({
      teamIsBudgetAllocated: currVal,
      showBudget: showBudget,
      teamBudgetAmount: teamBudgetAmount,
      teamCurrency: teamCurrency,
    });
  };
  handleBudgetYearChange = (value) => {
    let teamBudgetYear = value;
    this.setState({
      teamBudgetYear: teamBudgetYear,
    });
  };
  handleBudgetYearOpen = () => {
    this.setState({
      budgetOpenState: true,
    });
  };
  handleBudgetYearClose = () => {
    this.setState({
      budgetOpenState: false,
    });
  };
  handleBudgetAmountChange = (event) => {
    let currval = event.target.value;
    if (!currval.match(/^(\d|-)+$/)) {
      currval = currval.replace(/[^0-9-]/g, "");
    } else if (currval < 1 || currval.includes("-")) {
      currval = "";
    }
    if (currval.toString().length > 6) {
      currval = currval.substring(0, 6, currval.toString().length - 1);
    }
    if (currval != "") {
      $("#span_teambudgetamount").hide();
    } else {
      $("#span_teambudgetamount").show();
    }
    this.setState({
      teamBudgetAmount: currval,
    });
  };
  setStatus = (e) => {
    let helperScore,
      currval = this.state.selected_score,
      coursestatus = this.state.selected_status;
    let currstatus = this.state.selectedStatus;
    if (currstatus == "1") {
      helperScore = this.props.labels.labels["LBLSCORENOTSEL"];
      if (!currval.match(/^(\d|-)+$/)) {
        currval = currval.replace(/[^0-9-]/g, "");
      } else if (
        currval < 0 ||
        currval > 100 ||
        currval.includes("-") ||
        currval.length > 3
      ) {
        currval = "";
      } else {
        helperScore = "";
      }
    } else if (coursestatus == "") {
      helperScore = this.props.labels.labels["LBLSTATUSNOTSEL"];
    }
    this.setState({
      selected_score: currval,
      helperScore: helperScore,
    });
  };
  handleCurrencyChange = (event) => {
    if (event.target.value != "") {
      $("#span_budgetcurrency").hide();
    } else {
      $("#span_budgetcurrency").show();
    }
    this.setState({
      teamCurrency: event.target.value,
    });
  };
  handleCurrencyOpen = () => {
    this.setState({
      currencyOpenState: true,
    });
  };
  handleCurrencyClose = () => {
    this.setState({
      currencyOpenState: false,
    });
  };
  checkValidations = (tmpTeamData) => {
    let locAdmin, checkval = true;
    const {
      teamAdmin, 
      teamStartDate, 
      teamLanguage,
      teamBudgetYear, addnlteamAdmin,
    } = this.state;
    let errorItems = [];
    if ($.trim(tmpTeamData.team_name) == "") {
      checkval = false;
      let required = this.props.labels.labels.LBLREQUIRED;
      $("#span_teamname").show();
      $("#span_teamname").text(required);
      errorItems.push("teamname");
    } else if (tmpTeamData.team_name.length > 45) {
      checkval = false;
      let lengthval = this.props.labels.labels.LBLMAXCHARS;
      $("#span_teamname").show();
      $("#span_teamname").text(lengthval);
      errorItems.push("teamname");
    } else {
      $("#span_teamname").hide();
    }
    if (teamLanguage == "") {
      checkval = false;
      $("#span_teamlanguage").show();
      errorItems.push("teamLanguage");
    } else {
      $("#span_teamlanguage").hide();
    }
    if (tmpTeamData.team_description.length > 512) {
      let lengthval = this.props.labels.labels.LBLDESCMAXCHARS;
      $("#span_teamdesc").show();
      checkval = false;
      errorItems.push("team_description");
    } else {
      $("#span_teamdesc").hide();
    }
    locAdmin = (teamAdmin);
    if (teamAdmin != "")
      locAdmin = parseInt(teamAdmin);
    if ((locAdmin || 0) == "") {
      checkval = false;
      $("#span_teamadmin").show();
      errorItems.push("teamAdmin");
    } else {
      $("#span_teamadmin").hide();
      if (locAdmin != 0 && teamAdmin == addnlteamAdmin) {
        this.showAlert("warning", this.props.labels.labels["LBLADMNEQADDNL"]);
        return false;
      }
    }

    if (
      teamStartDate == "" ||
      teamStartDate == null ||
      teamStartDate == "Invalid Date"
    ) {
      checkval = false;
      if (teamStartDate == "Invalid Date") {
        $("#span_startdate").hide();
      } else {
        $("#span_startdate").show();
      }
    } else {
      $("#span_startdate").hide();
    }

    if (this.state.showBudget) {
      if (teamBudgetYear == "") {
        checkval = false;
        $("#span_budgetyear").show();
        errorItems.push("teamBudgetYear");
      } else {
        $("#span_budgetyear").hide();
      }
      if (tmpTeamData.budget_amount == "") {
        checkval = false;
        $("#span_teambudgetamount").show();
        errorItems.push("budget_amount");
      } else {
        $("#span_teambudgetamount").hide();
      }
      if (tmpTeamData.currency == "" || tmpTeamData.currency == null) {
        checkval = false;
        $("#span_budgetcurrency").show();
        errorItems.push("currency");
      } else {
        $("#span_budgetcurrency").hide();
      }
    }
    if (errorItems.length > 0) {
      $("#" + errorItems[0]).focus();
    }
    return checkval;
  };
  getTeamDetails = (team_id,mode) => {
    let teamid = team_id;

    let company_id = this.props.companyId;
    let apiUrl = "steercomteam/" + team_id ;
    this.getTeamDetailsCallback(teamid, mode);
  };
  getTeamDetailsCallback = async (team_id,mode) => {
    let response = await SteercomServices.getTeamDetails(team_id);
    console.log("response.data.result", response.result[0]);
    let teamData= response.result[0];
    if(mode==1){
      this.props.setTeamDetails(currId, teamData);
    }
    this.setState({
      editTeam: true,
      currId: response.result[0].team_id,
      teamData: teamData
    });
  };
  addTeamDetails = (values, vals) => {
    const {
      teamData,
      teamName,
      teamLanguage,
      teamDescription,
      teamGroups,
      teamAdmin, addnlteamAdmin,
      teamStartDate,
      teamMeetingFrequency,
      teamIsBudgetAllocated,
      teamBudgetYear,
      teamBudgetAmount,
      teamCurrency, userlist,attachedFiles,prevImageName
    } = this.state;
    let teamId, languages_ = this.props.steercomSettings.templates;
    var teamDatas = this.props.steercomSettings.templates.find(function (items) {
      return items.template_id == teamLanguage.template_id;
    });
    teamId = teamDatas.team_id;
     let adminDatas=[],addnlAdminDatas=[],adminDetails=[],adminUnassign=[],addnlAdminUnassign=[];
    let cratedby = this.state.editTeam?this.state.teamData.created_by:this.props.userId;
    let mode = (this.state.editTeam || this.state.currId != "") ? "put" : "post";
    let lang="en-US";//this.props.language_code;
    userlist.map((items)=> {
      if(items.user_id == teamAdmin && items.email_verified && items.active==1){
        adminDatas = items;
      }
      if(items.user_id == addnlteamAdmin && items.email_verified && items.active==1){
        addnlAdminDatas = items;
      }
      if(items.user_id == cratedby){
        adminDetails = items;
      }
      if(mode=="put"){
        if(items.user_id == teamData.team_administrator  && items.email_verified && items.active==1){
          adminUnassign = items;
        }
        if(items.user_id == teamData.team_addnl_administrator && items.email_verified && items.active==1){
          addnlAdminUnassign = items;
        }
      }
    });
    var tmpTeamData = {
      team_name: $.trim(teamName),
      team_description: $.trim(teamDescription),
      team_administrator: teamAdmin,
      team_addnl_administrator: addnlteamAdmin,
      language_code: lang,// teamLanguage.id,
      template_id: teamLanguage.template_id,
      start_date: teamStartDate,
      groups:  JSON.parse(JSON.stringify(teamGroups)),
      meeting_frequency: teamMeetingFrequency,
      is_annual_budget_allocated: teamIsBudgetAllocated,
      budget_year: teamBudgetYear,
      budget_amount: teamBudgetAmount,
      currency: teamCurrency,
      active: 1,
      version: teamDatas && teamDatas.version || "1.0",
      team_status: null,
      created_by_email:adminDetails.email,

      userid1: adminDatas ? adminDatas.user_id  : null,
      email1: adminDatas ? adminDatas.email  : null,
      language_code1: adminDatas.language_code ? adminDatas.language_code  : null,
      first_name1: adminDatas ? adminDatas.first_name : null,
      last_name1: adminDatas ? adminDatas.last_name : null,
      user_name1: adminDatas ? adminDatas.user_name : null,
      role1:this.props.labels.labels.LBLTEAMADMIN, 

      userid2: addnlAdminDatas ? addnlAdminDatas.user_id  : null,
      email2: addnlAdminDatas ? addnlAdminDatas.email  : null,
      language_code2: addnlAdminDatas.language_code ? addnlAdminDatas.language_code  : null,
      first_name2: addnlAdminDatas ? addnlAdminDatas.first_name : null, 
      last_name2: addnlAdminDatas ? addnlAdminDatas.last_name : null,
      user_name2: addnlAdminDatas ? addnlAdminDatas.user_name : null,
      role2:this.props.labels.labels.LBLADDNLTEAMADMIN, 
      
      userid_u1: adminUnassign ? adminUnassign.user_id  : null,
      email_u1: adminUnassign.email ? adminUnassign.email  : null,
      language_code_u1: adminUnassign.language_code ? adminUnassign.language_code  : null,
      first_name_u1: adminUnassign ? adminUnassign.first_name : null,
      last_name_u1: adminUnassign ? adminUnassign.last_name : null,
      user_name_u1: adminUnassign ? adminUnassign.user_name : null, 

      userid_u2: addnlAdminUnassign ? addnlAdminUnassign.user_id  : null,
      email_u2: addnlAdminUnassign.email ? addnlAdminUnassign.email  : null,
      language_code_u2: addnlAdminUnassign.language_code ? addnlAdminUnassign.language_code  : null,
      first_name_u2: addnlAdminUnassign ? addnlAdminUnassign.first_name : null, 
      last_name_u2: addnlAdminUnassign ? addnlAdminUnassign.last_name : null,
      user_name_u2: addnlAdminUnassign ? addnlAdminUnassign.user_name : null,  

      image_name:attachedFiles[0]?attachedFiles[0].name:null
    };
    let currId = this.state.currId;
    if (this.state.editTeam || this.state.currId != "") {
      if (teamData) {
        tmpTeamData.team_id = teamData.team_id;
      }
      else if (currId && currId != "") {
        tmpTeamData.team_id = currId;
      } else {
        tmpTeamData.team_id = teamDatas.team_id;
      }
    }
    if (this.checkValidations(tmpTeamData)) { 
      let { steercomSettings,  email_notification } = this.state;
      let mailactive = null,admin_assigned=null,admin_unassigned=null;
      
      if (steercomSettings && steercomSettings.emailsettings){
        mailactive = (email_notification && steercomSettings.emailsettings[0].active && 
                     (steercomSettings.emailsettings[0].team_admin_assign || 
                      steercomSettings.emailsettings[0].team_admin_unassign)) ? true : false;
        admin_assigned = steercomSettings.emailsettings[0].team_admin_assign;   
        admin_unassigned = steercomSettings.emailsettings[0].team_admin_unassign;   
        //mailactive =  email_notification?true:false        
        }               
      let calltype = (this.state.editTeam || currId != "") ? "put" : "post";
      let a = 0;
      if (!mailactive) {
        tmpTeamData.email1 = null;
        tmpTeamData.email2 = null;
        tmpTeamData.email_u1 = null;
        tmpTeamData.email_u2 = null; 
      }else if(calltype == "put" ){ 
        if (teamData.team_administrator == tmpTeamData.team_administrator) {
          tmpTeamData.email1 = null; 
          tmpTeamData.email_u1 = null; 
        } 
        if(teamData.team_addnl_administrator == tmpTeamData.team_addnl_administrator){
          tmpTeamData.email2 = null;
          tmpTeamData.email_u2 = null; 
        }
        if(!admin_assigned){
          tmpTeamData.email1 = null; 
          tmpTeamData.email2 = null; 
        }if(!admin_unassigned){
          tmpTeamData.email_u1 = null; 
          tmpTeamData.email_u2 = null; 
        }
      }else{
        tmpTeamData.email_u1= null;
        tmpTeamData.email_u2 = null; 
        if(!admin_assigned){
          tmpTeamData.email1 = null; 
          tmpTeamData.email2 = null; 
        }
      } 
        if(tmpTeamData.image_name){
        if(prevImageName != tmpTeamData.image_name){
         let image_name = utils.generateFeedsPostFileName(tmpTeamData.image_name);   
         this.createPostHandler(tmpTeamData,calltype,image_name);
         tmpTeamData.image_name = image_name;
        } 
         this.addTeamDetailsCallback(tmpTeamData, calltype); 
      }else{
         this.addTeamDetailsCallback(tmpTeamData, calltype,);
      } 
      currId = "";
    }
  };
  addTeamDetailsCallback = async (tmpTeamData, calltype) => {
    let res;
    if (calltype == "post") {
      res = await SteercomServices.addTeam(tmpTeamData);
    } else {
      res = await SteercomServices.editTeam(tmpTeamData);
    }
    console.log("res", res);
    let succlabel = this.state.editTeam ? "LBLUPDTSUCC" : "LBLSAVESUCC";
    if (res) {
      if (res.status == "SUCCESS") {
        this.showAlert("success", this.props.labels.labels[succlabel]);
        setTimeout(() => {
          $(".head-label").html(this.props.labels.labels.LBLEDITTEAM);
          currId = res.newId?res.newId:this.state.currId; 
          if (currId != "") { 
            this.getTeamDetails(currId,1);
          }else{
            this.getTeamDetails(tmpTeamData.team_id);
          }
          $("#edit_teams").css({ display: "flex" });
          $("#task_setup").show();
        }, 500);
        $("#edit_teams").focus();
        $("#task_setup").focus();
      } else {
        this.showAlert("warning", this.props.labels.labels.LBLTEAMMEXISTS);
      }
    }
  };
  showAlert = (messageType, message) => {
    this.setState({
      openalert: true,
      messageType: messageType,
      message: message
    });
  };

  handleClose = () => {
    this.setState({ openalert: false });
  };
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
      loading: true,
    });
    return dateformat;
  };
  getFileName = () => {
    let fileName,
      currDate = new Date();
    fileName =
      currDate.getDate() +
      "" +
      (parseInt(currDate.getMonth()) + 1).toString() +
      "" +
      currDate.getFullYear() +
      currDate.getTime();
    return fileName;
  };
  handleImageChange(e, mode) {

    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    var preview = document.getElementById("preview");
    var fileInput = document.querySelector("input[type=file]");
    $("#noImage").addClass("noImage");
    $("#addplus").show();
    let lenFiles = 1;//fileInput.files.length;
    let lenPreview = $("#preview li").length;
    let imgcnt = lenPreview + 1;
    lenPreview = lenFiles + lenPreview;
    if (lenFiles > 3 || lenPreview > 3) {
      $(".fileInput").value = "";
      return false;
    }
    if (lenFiles >= 3 || lenPreview >= 3) {
      $("#noImage").css({ display: "none" });
    } else {
      $("#noImage").css({ display: "block" });
    }
    let idx = 0;
    let { attachedFiles } = this.state;
    console.log("xxxxxxxx", lenPreview, imgcnt);
    let filename = file.name;
    if(!filename){
      return false;
    }
    
    if(!filename.endsWith(".jpg") && !filename.endsWith(".jpeg") && !filename.endsWith(".png")){
      this.showAlert("warning", this.props.labels.labels["LBLWARNFORMATS"]);
      $("#fileInput")[0].value="";
      return false;  
    }else if(file.size>2048000){
      this.showAlert("warning", this.props.labels.labels["LBLWARNMAXFILESIZE"]);
      $("#fileInput")[0].value="";
      return false;
    }
    reader = new FileReader();
    reader.onload = function (readerEvent) {
      $(".cls_imgContainer").attr("src",readerEvent.target.result );
      $(".spn_close_image").css({"display":"block"});
    } 
      let fileName = this.getFileName(); 
      attachedFiles=[];
      attachedFiles.push(file);
      reader.readAsDataURL(fileInput.files[0]);
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        attachedFiles: attachedFiles,
      });
    }; 
  };
  createPostHandler = (tmpTeamData,calltype,image_name) => {
    this.state.attachedFiles.map((item) => {
      let postAsset = {};
      let timeStamp = new Date().getTime(); 
      postAsset.asset_name = image_name;
      postAsset.asset_type = item.type.match("image.*")? "image":""; 
       
      if(this.uploadImgAssets(item, timeStamp, postAsset)) //uploadAssets
         return postAsset.asset_name;
      else
         return null;   
    });
  }; 
  uploadImgAssets = (data, timeStamp, uploadFileInfo) => {
    // console.log(data);
    let ret_succ=false;
    this.setState({
      uploadingSize: this.state.uploadingSize + data.size,
    }); 
    let folderPath = this.props.companyId + "/";
    let s3Upload = new S3MultiUpload(
      data,
      uploadFileInfo.asset_name,
      "",
      folderPath
    );
    s3Upload.start(); 
    s3Upload.onProgressChanged = (uploadedSize, totalSize, bitrate) => { 
    };
    s3Upload.onUploadCompleted = (data) => {
       console.log("@@@@@@@%%%%%%%", data);
      this.saveImgStatus(uploadFileInfo.asset_name);  
      ret_succ=true;
    };
    return ret_succ;
  };
  
  uploadAssets = (data, i,uploadFileInfo) => {
    let count = i;
    let folderPath = this.props.companyId + "/"; //this.props.userInfo.currentuser.company_id + "/";
    let s3Upload = new S3MultiUpload(
      data,
      uploadFileInfo.asset_name,
      "",
      folderPath
    );
    s3Upload.start();
    let uploadedFiles = []
    s3Upload.onUploadCompleted = (data) => {
      if (data) {
        let { attachedFiles } = this.state;
        let cnt = count;
        uploadedFiles.push(uploadFileInfo.asset_name)
        let lenFiles = this.state.attachedFiles.length

        this.isFileUploadFinish = 1;
          this.setState({
            showLoader: 0,
            isUploadBtnActive: 0,
            isDeleteBtnActive: 1,
            attachedFiles: attachedFiles
          });
        //}
      }
    };
  };
   saveImgStatus=(asset_name)=>{
    console.log("############", asset_name);
   }
   addTimeStampToFileName = (fName, timeStamp) => {
    let fileName = fName.split(".");
    fileName[fileName.length - 2] = fileName[fileName.length - 2] + timeStamp; 
    return fileName.join(".");
  };

  deleteTeamImage = () => {
    this.state.attachedFiles = [];
    $(".cls_imgContainer").attr("src","");
    $(".spn_close_image").css({"display":"none"})
    this.setState(
      {
        attachedFiles: [],
      } 
    );
  };
  getImage=(image_name)=>{
    let imageName = image_name;
    if (imageName) {
      let assetFolder = String( imageName).split(".");
      assetFolder.pop();
      let postData = {
        filename:  imageName,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      //this.getImagePath(postData);
      this.getAttachedImages(imageName);
    }
  }
  getImagePath(postData) {
    ScCommonServices.getAssyncSignedUrl(postData).then((resultData) => {
      let arrUrl,arrUrlCompany,url = resultData.data;
       arrUrl = url.split("image/");
       arrUrlCompany = arrUrl[1].split("/")
       url = arrUrl[0]+ "image/"+ this.props.companyId+"/"+arrUrlCompany[1];
      fetch(url, {
        retryOn: function (attempt, error, response) {
          if (!response || response.status >= 403) {
            return false;
          }
        },
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            imageUrl: resultData.data,
          });
        }
      });
    });
  }
  
  async getAttachedImages(assets) {
    let asset_name = "ss.jpg";
    let FEEDAPIURL = process.env.REACT_APP_FEEDAPI_URL;
    let assetFolder = String(assets).split(".");
    assetFolder.pop();
    let data = {
      filename: assets,
      assettype: "image",
      assetFoldet: assetFolder.join(),
    };
    // console.log('S3 Paths', data, response.data.data);
    let state = this.state;
    let urlinfo,
      allInfo = [],
      urlData = [];
    if (assets.length > 0) {
        let data = {
          filename: assets,
          assettype: "image",
          assetFoldet: assetFolder.join(),
        };
        const response = HttpInterceptor.post(
          FEEDAPIURL + "gets3signedassetsurl",
          data
        ).then(
          (response) => {
            if (response) {
              allInfo = response.data.data;
              urlinfo = allInfo;
              urlData.push(urlinfo);
              this.setState({ imageUrl: urlData[0] });
              setTimeout(() => {
              }, 100);
            }
          },
          (err) => {}
        );
    }
    return urlData;
  }
  render() {
    const {
      LBLLANGUAGE, LBLADDNLTEAMADMIN,
      LBLNONE, LBLSELECTOPT, LBLCHOSEIMG,
      LBLCANCEL,
      LBLCURRENCY,LBLUPLOADIMG,LBLDELETE,
      LBLTEAMNAME,LBLMAXIMGSIZE,LBLIMGSUPPORTFORMAT,LBLRECIMGSIZE,
      LBLTEAMDESC,
      LBLTEAMADMIN,
      LBLSTEERCOMSTARTDATE,
      LBLPLANTASSOCIATE,
      LBLMEETFREQ,
      LBLANNUALBUDGET,
      LBLBUDGETYEAR,
      LBLBUDGETAMOUNT,
      LBLADDNEWTEAM,
      LBLCREATETEAM,
      LBLEDITTEAM,
      LBLYES,
      LBLNO, LBLTEAMVIEW,
      LBLREQUIRED,
      LBLUPDATETEAM,
      LBLDESCMAXCHARS,
    } = this.props.labels.labels;

    let horizontal = "top",
      vertical = "center";
    let {
      userlist, addnluserlist,
      teamAdmin, teamAdminName, addnlteamAdmin, addnlteamAdminName,
      teamGroups,
      teamMeetingFrequency,
      groupTypeNames,
    } = this.state;

    this.formattingDate = (date) => {
      let dateformat,
        fulldate = "";
      dateformat = this.state.dateformat;
      if (dateformat.toString().indexOf("YYYY") > 0)
        dateformat = this.setdateformat();
      let dateArray = date.split("/");
      if (date) {
        var d = new Date(), month, day, year;
        d = new Date(date);
        day = "" + d.getDate();
        if (d == undefined || isNaN(d.getDate())) {
          d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
        }
        // d = new Date(date.split("/")[1] + "/" + date.split("/")[0] + "/" + date.split("/")[2]);
        month = "" + (d.getMonth() + 1);
        day = "" + d.getDate();
        year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        if (dateformat == "dd/MM/yyyy") {
          fulldate = [day, month, year].join("/");
        } else if (dateformat == "MM/dd/yyyy") {
          fulldate = [month, day, year].join("/");
        } else if (dateformat == "yyyy/MM/dd") {
          fulldate = [year, month, day].join("/");
        } else {
          fulldate = [day, month, year].join("/");
        }
      }
      return fulldate;
    };
    return (
      <div className="team_main_container create-tm">
        <Snackbar
          anchorOrigin={{ horizontal, vertical }}
          key={"top-center"}
          open={this.state.openalert}
          autoHideDuration={this.state.showtime ? this.state.showtime : 3000}
          onClose={this.handleClose}
          bodyStyle={{ backgroundColor: "teal", color: "coral" }}
        >
          <Alert
            variant="filled"
            onClose={this.handleClose}
            severity={this.state.messageType}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <div>
          <h4 className="pageSubTitle">
            {" "}
            {this.state.editTeam ? <>
              {(this.state.teamData.created_by == this.props.userId ||
                this.state.teamData.team_administrator == this.props.userId ||
                this.state.teamData.team_addnl_administrator == this.props.userId) ? LBLEDITTEAM : LBLTEAMVIEW}</> : <>{LBLADDNEWTEAM}</>}
          </h4>
        </div>
        {/* <hr className="head-line" /> */}
        {this.state.loading ? (
          <Skeleton height={300} />
        ) : (
          <div className="sc-form">
            <div
              id="top_container"
              className={this.state.viewmode ? "viewmodeon" : "viewmodeoff"}
            >
              <div className="row">
              <div class="col-xl-6 col-sm-12">
              <div className="row">
                <div className="col-xl-8 col-sm-12">
                  <FormControl>
                    <TextField
                      id="teamname"
                      label={LBLTEAMNAME}
                      value={this.state.teamName}
                      // required="true"
                      class="clsteamname"
                      onChange={this.handleNameChange}
                    />
                  </FormControl>
                </div>
                <div className="col-xl-4">
                  <div>
                  <ThemeProvider theme={theme}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={this.props.steercomSettings && this.props.steercomSettings.templates}
                      getOptionLabel={(option) => option.template_name.toString()}
                      getOptionSelected={(option, values) =>
                        option.template_id === values.template_id
                      }
                      onOpen={(title) => {
                        title="xxxx"
                      }}
                      onClose={(title) => {
                        title="yyyy"
                      }}
                      // sx={{ width: 400 }}
                      onChange={this.handleLanguageChange}
                      disableClearable
                      disabled={this.state.editTeam ? true : false}
                      defaultValue={this.state.teamLanguage}
                      renderOption={(option) => (
                        <div className="auto-style">{option.template_name}</div>
                      )}

                      renderInput={(params) => (
                        <TextField
                          value="surs"
                          text="sur"
                          {...params}
                          label={LBLLANGUAGE}
                        />
                      )}
                    />
                    </ThemeProvider>
                    <span className="creatrequired required-field" id="span_teamlanguage">
                      {LBLREQUIRED}
                    </span>
                  </div>
                </div>
              </div>
              <span
                className="creatrequired required-field"
                style={{ display: this.state.helperName }}
                id="span_teamname"
              >
                {LBLREQUIRED}
              </span>
              <br></br>
              <div className="row">
                <div className="col-xl-12 col-sm-12">
                  <FormControl className="row">
                    <TextField
                      id="teamdescription"
                      label={LBLTEAMDESC}
                      value={this.state.teamDescription}
                      class="clsdescription"
                      onChange={this.handleDescriptionChange}
                    />
                  </FormControl>
                  <span
                    className="creatrequired required-field"
                    style={{ display: this.state.helperName }}
                    id="span_teamdesc"
                  >
                    {LBLDESCMAXCHARS}
                  </span>
                </div>
              </div>
              <br></br>

              <div className="row">
                <div className="col-xl-8">
                  <div tabIndex="-1">
                    {userlist.length > 0 ? (<>
                      <ThemeProvider theme={theme}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={userlist}
                        disabled={this.state.editTeam && this.state.teamData && (this.state.teamData.created_by == this.props.userId ? false : this.props.userId == teamAdmin ? true : true)}
                        getOptionLabel={(option) => option.name.toString()}
                        getOptionSelected={(option, values) =>
                          option.user_id === values.user_id
                        }
                        // style={{ width: 400 }}
                        onChange={this.handleAdminChange}
                        disableClearable
                        defaultValue={userlist.find(
                          (v) => v.user_id == teamAdmin
                        )}
                        renderOption={(option) => (
                          (option.name != "" ?
                            <div className="auto-style">{option.name}</div>
                            : LBLSELECTOPT)
                        )}
                        renderInput={(params) => (
                          <TextField
                            value="surs"
                            text="sur"
                            title={teamAdminName}
                            {...params}
                            label={LBLTEAMADMIN}
                          />
                        )}
                      />
                    </ThemeProvider>
                      <span className="creatrequired required-field " id="span_teamadmin">
                        {LBLREQUIRED}
                      </span></>) : ""} 

                  </div>
                </div>
                <div className="col-xl-4">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                      <KeyboardDatePicker
                        margin="normal"
                        id="startdate"
                        // minDate={'01-01-2021'}
                        minDate={
                          this.state.editTeam
                            ? this.state.teamStartDate
                            : "2000-08-17T00:00:00.000Z"
                        }
                        label={LBLSTEERCOMSTARTDATE}
                        format={this.state.dateformat}
                        value={this.state.teamStartDate}
                        onChange={this.handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <span className="creatrequired required-field " id="span_startdate">
                      {LBLREQUIRED }
                    </span>
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <br></br>
              {/* style={{display:"none"}} */}
              <div className="row" >
                <div className="col-xl-8">
                  <div tabIndex="-1">
                    {addnluserlist.length > 0 ? (<>
                      <ThemeProvider theme={theme}>
                      <Autocomplete
                        id="combo-box-demo"
                        options={addnluserlist}
                        disabled={this.state.editTeam && this.state.teamData && (this.state.teamData.created_by == this.props.userId ? false : this.props.userId == addnlteamAdmin ? true : true)}
                        //getOptionLabel={(option) => option.name.toString()}
                        getOptionLabel={(option) => option.name.toString() == "" ? LBLNONE : option.name.toString()}
                        getOptionSelected={(option, values) =>
                          option.user_id === values.user_id
                        }
                        // style={{ width: 400 }}
                        onChange={this.handleAddnlAdminChange}
                        disableClearable
                        // value={addnluserlist[0]}
                        defaultValue={addnluserlist.find(
                          (v) => v.user_id == addnlteamAdmin
                        )}
                        renderOption={(option) => (
                          <div className="auto-style">{option.name.toString() == "" ? LBLNONE : option.name.toString()}</div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            value="surs"
                            text="sur"
                            title={addnlteamAdminName}
                            {...params}
                            label={LBLADDNLTEAMADMIN}
                          />
                        )}
                      />
                     </ThemeProvider>
                      <span className="creatrequired required-field " id="span_teamadmin">
                        {LBLREQUIRED }
                      </span></>) : ""}

                  </div>
                </div>

              </div>
              </div>
              <div class="col-xl-6 col-sm-12 cls_img_container">
              <div className="cls_upload_content">
              <div className="cls_sub_title1">
                <span>{LBLUPLOADIMG}</span>
              </div> 
                <form onSubmit={(e) => this._handleSubmit(e)}>
                  <div className="image_container uploadImageCtn">

                    <input 
                      className="fileInput"
                      multiple
                      id="fileInput"
                      type="file"
                      style={{ display: "block" , visibility:"visible" }}
                      accept="image/x-png,image/jpeg,image/jpg"
                      ref={this.fileNameRef}
                      onChange={(e) => this.handleImageChange(e)}
                    /> 
                    <div id="maxSize"style={{ marginTop:"5px"}}  className="cls_max_size">{LBLRECIMGSIZE}</div>
                    <ul 
                      className="row cls_img_top_container" 
                      id="preview"
                    >
                      {/* {this.state.imageUrl? */}
                      <li className="cls_liContainer">
                      <span id="cross" style={{"display":"none"}} ></span>
                      <div id="img_2" style={{"display":this.state.imageUrl?"":"none"}}  title={LBLDELETE}  className="spn_close_image" onClick={this.deleteTeamImage}>X</div>
                      <img className="cls_imgContainer" src={ this.state.imageUrl } style={{  border: "1px solid #cccccc" }} alt=""  > 
                      </img> 
                      </li>
                    </ul>
                  </div> 
                </form>
                
              </div>
              </div></div>
              <br></br>
              <div className="cls_sub_title">
                <span>{LBLPLANTASSOCIATE}</span>
              </div>
              {this.state.groupTypeNames.length > 0 ? <>
                <div className="row">
                  {this.state.groupTypeNames.map((item, index) => {
                    if (item.Active === "1") {
                      return (
                        <>
                          <div
                            className="col-xl-2"
                            style={{ marginBottom: "20px" }}
                          >
                            <ThemeProvider theme={theme}>
                            <Autocomplete
                              options={this.state.groups[item.group_type_id]}
                              getOptionLabel={(option) => option.value.toString() == "" ? LBLNONE : option.value.toString()}
                              getOptionSelected={(option, values) => option.id === values.id}
                              disableClearable
                              onChange={this.handleGroupChange.bind(this, item)}
                              defaultValue={this.state.groups[
                                item.group_type_id
                              ].find(
                                (v) =>
                                  v.id == teamGroups[item.group_type_id][0].id
                              )}
                              renderOption={(option) => (
                                <div style={{ fontSize: "14px" }}>{option.value.toString() == "" ? LBLNONE : option.value.toString()}</div>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={item["lbl_" + item.group_type_id]}
                                />
                              )}
                            />
                            </ThemeProvider>
                            <span
                              className="creatrequired required-field "
                              id="span_{item.group_type_id}"
                            >
                              {LBLREQUIRED }
                            </span>
                          </div>
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </> : ""}
              {/* <br></br> */}
              <div className="row" style={{marginTop:"20px"}}>
                <div className="col-xl-4">
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={this.props.steercomSettings.meeting_frequency}
                    getOptionLabel={(option) => option.name.toString()}
                    getOptionSelected={(option, values) => option.id === values.id}
                    // style={{ width: 300 }}
                    disableClearable
                    onChange={this.handleMeetingFrequencyChange}
                    defaultValue={this.props.steercomSettings.meeting_frequency.find(
                      (v) => v.id == teamMeetingFrequency
                    )}
                    renderOption={(option) => (
                      <div className="auto-style" >{option.name}</div>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label={LBLMEETFREQ} />
                    )}
                  />
                </ThemeProvider>
                </div>
              </div>
              <span className="creatrequired required-field " id="span_teammeetfrq">
                {LBLREQUIRED}
              </span>
              <br></br>
              <div className="cls_title">
                <span>{LBLANNUALBUDGET}</span>
              </div>
              <div className="row cls-rad-budget">
                <Radio
                  checked={this.state.teamIsBudgetAllocated === 1}
                  onChange={this.handleIsBudgetChange}
                  value="1"
                  id="yes"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "A" }}
                />
                <span className="cls_yes">{LBLYES}</span>
                <Radio
                  checked={this.state.teamIsBudgetAllocated === 0}
                  onChange={this.handleIsBudgetChange}
                  value="0"
                  id="no"
                  name="radio-button-demo"
                  inputProps={{ "aria-label": "B" }}
                />
                <span className="cls_no">{LBLNO}</span>
              </div>
              {/* <br></br> */}
              {this.state.showBudget ? (
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-xl-2">

                    <InputLabel className="cls_title_year" id="demo-controlled-open-select-label">
                      {LBLBUDGETYEAR}
                    </InputLabel>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          views={["year"]}
                          margin="normal"
                          id="budgetyear"
                          minDate={new Date("2018")}
                          maxDate={new Date("2024")}
                          minDateMessage=""
                          maxDateMessage=""
                          value={this.state.teamBudgetYear}
                          onChange={this.handleBudgetYearChange}
                          KeyboardButtonProps={{
                            "aria-label": "change year",
                          }}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <span className="creatrequired required-field " id="span_teambudgetyear">
                        {LBLREQUIRED }
                      </span>
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-xl-3">
                    <FormControl className="row buget-currency-ctn">
                      <TextField
                        id="teambudgetamount"
                        label={LBLBUDGETAMOUNT}
                        value={this.state.teamBudgetAmount}
                        onChange={this.handleBudgetAmountChange}
                        tabIndex="-1"
                      />
                      <span className="creatrequired required-field " id="span_teambudgetamount">
                        {LBLREQUIRED }
                      </span>
                    </FormControl>
                  </div>
                  <div className="col-xl-2 buget-currency-ctn" tabIndex="-1">
                    <FormControl>
                      <InputLabel id="demo-controlled-open-select-label">
                        {LBLCURRENCY}
                      </InputLabel>
                      <Select
                        labelId="demo-controlled-open-select-label"
                        id="budgetyear"
                        open={this.state.currencyOpenState}
                        onClose={this.handleCurrencyClose}
                        onOpen={this.handleCurrencyOpen}
                        value={this.state.teamCurrency}
                        onChange={this.handleCurrencyChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {this.props.steercomSettings.currencies &&
                          this.props.steercomSettings.currencies.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            }
                          )}
                      </Select>
                      <span className="creatrequired required-field " id="span_budgetcurrency">
                        {LBLREQUIRED}
                      </span>
                    </FormControl>
                  </div>
                </div>
              ) : (
                ""
              )}
              <br></br>
            </div>
            <div className="col-sm-12 btm-submit">
              {this.state.editTeam ? (
                <>
                  <button
                    type="submit"
                    className="button-style primary-btn"
                    disabled={this.state.submitting}
                    style={{
                      float: "right",
                      display: this.state.viewmode ? "block" : "none",
                    }}
                    onClick={this.addTeamDetails}
                  >
                    {LBLUPDATETEAM}
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="submit"
                    className="button-style primary-btn"
                    disabled={this.state.submitting}
                    style={{ float: "right" }}
                    onClick={this.addTeamDetails}
                  >
                    {LBLCREATETEAM}
                  </button>
                </>
              )}
              <button
                type="button"
                className="button-style secondary-btn  button-inner-style"
                id="btnaddCancel"
                onClick={this.props.closeModal}
              >
                {LBLCANCEL}
              </button>
            </div>
            <br></br>
            <br></br>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
    email_notification: state.user_info_reducer.user_info.email_with_code
  };
};
export default CreateTeam;

import React from 'react';
import "./ogcontent.css";
import VideoPlayer from '../videoPlayer/videoplayer';

class DisplayOGConent extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            videoUrl:""
        }
        this.videoPlayerLabels = '{"course_engine_meida_lblPlay":"Play","course_engine_meida_lblPause":"Pause","course_engine_meida_lblReplay":"Replay","course_engine_meida_lblStop":"Stop","course_engine_meida_lblMute":"Mute","course_engine_meida_lblUnmute":"Unmute","course_engine_meida_lblFullScreen":"Full Screen","course_engine_meida_lblNormalScreen":"Normal Screen","course_engine_videoNotFound":"Video Not Found"}';
        this.videoPlayerLabels = JSON.parse(this.videoPlayerLabels);
    }
    componentDidMount(){
        if(this.props.ogItem.link){
            this.setState({
                videoUrl:this.props.ogItem.link
            })
        }
    }
    
    render(){
        let readAssetsContainer = (this.props.totalAssets > 1 && (this.props.totalAssets === 3 && this.props.index === 0)) ? "ssi-feeds-ogContentContainer multiassets threeAssets" : (this.props.totalAssets > 1) ? "ssi-feeds-ogContentContainer multiassets" : "ssi-feeds-ogContentContainer";
        return(
            <div className={readAssetsContainer}>
                <div className="ssi-feeds-ogContentWrapper">
                    <VideoPlayer url={this.state.videoUrl} propFullScreen={true} autoPlay={false} propProgressBar={true} labels={this.videoPlayerLabels} />
                </div>
            </div>
        )
    }
}

export default DisplayOGConent;
const initialState = {
   access: null
}

const access_reducer = (state = initialState, action)=>{
    switch(action.type){ 
        case "SET_ACCESS": 
            state.access = action.payload;
            return state;
        default: return state
    }
}

export default access_reducer;
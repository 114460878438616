import React, { Component } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';


class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
        <div className="d-flex flex-column"> 
        <ButtonGroup size="sm" className="mt-3">
          <Button>When to Send</Button>
          <Button>Who will Receive</Button>
          <Button>Right</Button>
        </ButtonGroup>
      </div> 
      );
    }
}
 
export default Notification;
import React from "react";
import "./readImage.css";
import FeedsServices from "../../feedService/feedservices";
import { connect } from "react-redux";
//import { Spinner } from "react-bootstrap";
import Loader from "../../../admin/components/loader/Loader";

var originalFetch = require("isomorphic-fetch");
var fetch = require("fetch-retry")(originalFetch);

class ReadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      user_id: this.props.user_id,
      company_id: this.props.company_id,
    };
  }
  componentDidMount() {
    if (this.props.assetItem.asset_name) {
      let assetFolder = String(this.props.assetItem.asset_name).split(".");
      // assetFolder.pop();
      if (assetFolder.length > 1) {
        assetFolder.pop();
      }
      let postData = {
        filename: this.props.assetItem.asset_name,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      FeedsServices.feedsAPIServicesCall(
        "gets3signedassetsurl",
        "post",
        this.signedUrlResultHandler,
        postData
      );
    }
  }
  signedUrlResultHandler = (resultData) => {
    if (this.props.newpost === undefined || this.props.newpost === 0) {
      this.setState({
        imageUrl: resultData.data.data,
      });
    } else {
      let url = resultData.data.data;
      fetch(url, {
        retryOn: function (attempt, error, response) {
          if (error !== null || response.status >= 403) {
            return true;
          }
        },
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            imageUrl: resultData.data.data,
          });
        }
      });
    }
  };
  render() {
    let readAssetsContainer =
      this.props.totalAssets > 1 &&
        this.props.totalAssets === 3 &&
        this.props.index === 0
        ? "ssi-feeds-readImageContainer multiassets threeAssets"
        : this.props.totalAssets > 1
          ? "ssi-feeds-readImageContainer multiassets"
          : "ssi-feeds-readImageContainer";
    return (
      <div className={readAssetsContainer}>
        <div className="ssi-feeds-readImageWrapper">
          {
            this.state.imageUrl ? (
              <img src={this.state.imageUrl} alt="" />
            ) : (
                <Loader></Loader>
              )
            //<Spinner animation="grow" />
          }
        </div>
      </div>
    );
  }
}

//export default ReadImage
const mapStateToProps = (state) => {
  return {
    company_id: state.user_info_reducer.user_info.company_id,
    user_id: state.user_info_reducer.user_info.user_id,
  };
};
export default connect(mapStateToProps, null)(ReadImage);

import React, { Component } from "react";
import "./rptcertificate.scss";
import CompanyLogo from "../../admin/components/companylogo/companylogo";

const moment = require("moment");

class CCCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = { showPrint: false,sitetype:localStorage.getItem('site_type') , };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  handlePrintX = (xid) => {
    this.setState({ showPrint: false });

    this.setState({ showPrint: false }, function () {
      var print_div = document.getElementById(xid);
      var newWindow = window.open();
      newWindow.document.write("<!DOCTYPE html");
      newWindow.document.write("<html>");
      newWindow.document.write("<head>");
      newWindow.document.write("</head>");
      newWindow.document.write("<body>");
      newWindow.document.write(print_div.innerHTML);
      newWindow.document.write("</body>");
      newWindow.document.write("</html>");
      // Append the new container to the body of the new window
      const parentHead = window.document.querySelector("head").childNodes;
      parentHead.forEach((item) => {
        newWindow.document.head.appendChild(item.cloneNode(true)); // deep copy
      });
      newWindow.document.close();
      setTimeout(() => {
        newWindow.focus();
        newWindow.print();
        newWindow.close();
      }, 2500);
    });
  };
  handlePrint = (xid) => {
    if (xid === "PRINTDIV") {
      document.getElementById("hideprint").style.display = "none";
    }
    this.setState({ showPrint: false }, function () {
      var print_div = document.getElementById(xid);
      var newWindow = window.open();
      newWindow.document.write("<!DOCTYPE html");
      newWindow.document.write("<html>");
      newWindow.document.write("<head>");
      newWindow.document.write("</head>");
      newWindow.document.write("<body>");
      newWindow.document.write(print_div.innerHTML);
      newWindow.document.write("</body>");
      newWindow.document.write("</html>");
      newWindow.document.write(
        "<script>window.onload = function(e){window.print();window.close();}</script>"
      );
      // Append the new container to the body of the new window
      const parentHead = window.document.querySelector("head").childNodes;
      parentHead.forEach((item) => {
        newWindow.document.head.appendChild(item.cloneNode(true)); // deep copy
      });
      newWindow.document.close();
      newWindow.focus();
      // newWindow.print();

      if (xid === "PRINTDIV") {
        document.getElementById("hideprint").style.display = "";
      }
    });
  };
  render() {
    const { labels, company_logo, company_aws_logo_path, productData, userData } = this.props;
    const logoClassName = "col-md-6 col-sm-6 cert-logowrapper";
    return (
      <div className="reportsCertificate">
        <div
          className="xcert-popup"
          id={productData.unique_id}
          onMouseOver={() => {
            this.setState({ showPrint: false });
          }}
        >
          <div className="cert-wrapper">
            <div
              className="cert-body"
              onMouseOverCapture={(evt) => {
                evt.stopPropagation();
                this.setState({ showPrint: true });
              }}
              onMouseLeave={(evt) => {
                evt.stopPropagation();
                this.setState({ showPrint: false });
              }}
            >
              {this.state.showPrint ? (
                <div className="cert-printbtn">
                  <img
                    src="../../images/print.png"
                    width="100%"
                    height="100%"
                    alt={labels.LBLPRINT}
                    onClick={this.handlePrint.bind(
                      this,
                      productData.unique_id
                    )}
                  />
                </div>
              ) : (
                <div
                  className="cert-printbtn"
                >
                </div>
              )}
               
              <div className="cert-logo-ctn row">
                {company_logo ? (
                  <CompanyLogo
                    className={logoClassName}
                    imageName={company_logo}
                    company_aws_logo_path={company_aws_logo_path}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="row cert-hdr">
                <span >{labels.LBLCERTOFCOMPLETION}</span>
              </div>
              <div className="row cert-name-hdr">
                <span>{labels.LBLCERTIFYTHAT}</span>
              </div>
              <div className="row cert-uname-value">
                <span className="cert-username">
                  {this.props.userData?.first_name?.charAt(0).toUpperCase() +
                    this.props.userData?.first_name?.slice(1) +
                    " " +
                    this.props.userData?.last_name?.charAt(0).toUpperCase() +
                    this.props.userData?.last_name?.slice(1)}
                </span>
              </div>
              <div className="row cert-crsname-hdr" >
                <span>{productData.cert_type==1?labels.LBLCRSSUCCESS:this.state.sitetype=="SafeStart"?labels.LBLUNITSUCESS:labels.LBLMODULESUCESS}</span>
              </div>
               
              <div className="row cert-crsname-value">
                <span>
                  {productData.description !=="" ?
                    productData.description : productData.name}
                </span>  
              </div>
               <div className="cert-course-section">
                <div className="row cert-crsname-hdr">
                  <span>{productData.coursetype==2?labels.LBLIN?labels.LBLIN:"in":""}</span>
                </div>
                <div className="row cert-crsname-value"> 
                  <span>
                  {productData.coursetype==2?productData.coursename:""} 
                    </span>
                </div> 
                </div> 
                <div className="cert-footer-container">
                <div className="cert-datesign-ctn">
                  <div className="cert-date-value">
                    {productData.CourseCompletionDate !== null &&
                    productData.CourseCompletionDate !== "" &&
                    productData.CourseCompletionDate !==
                      "0000-00-00 00:00:00"
                      ? moment(
                          productData.CourseCompletionDate //.replace(/\s/g,'/')
                        ).format(userData.dateformat)
                      : ""}
                  </div>
                  <div className="cert-date-label">
                    {labels.LBLDATE.toUpperCase()}
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CCCertificate;

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import "./filter.scss";
import { Modal } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "../../admin/components/loader/Loader";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const $ = require("jquery");
class FilterComp extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleDisplay = this.handleDisplay.bind(this);
    this.handleClickSels = this.handleClickSels.bind(this);
    this.state = {
      filterHeight: 0,
      getFilters: props.toFilters,
      roles: [],
      vals: "",
      groups: [],
      groupAll: [],
      items: [],
      singleLength: "",
      multiLength: "",
      singleItemNames: [],
      multiItemNames: [],
      selectedItems: [1, 2, 3, 4],
      selectedItems1: [],
      selectedItems2: [],
      selectedItems3: [],
      selectedItems4: [],
      selectedItems5: [],
      helpersLoaded: false,
      roleid: "",
      selectedDate: new Date(),
      statusid: "",
      load: true,
      currentId: "",
      selectedValues: [46, 76],
      object: {},
      sGroup1: [],
      sGroup2: [],
      sGroup3: [],
      sRoles: "",
      sStatus: "",
      selAllMulti: {},
      selAllSingle: {},
      showDate1: "none",
      showDate2: "none",
      selectedDate1: new Date(),
      selectedDate2: new Date(),
      selAllValues: {
        singleItems: [],
        multiItems: [],
        dateItems: [],
      },
      showrange: "none",
    };
    let response = this.state.getFilters;
    let singleItemsS = [],
      multiItemsM = [],
      multiname,
      singlename,
      dateItemNames;
    let multiItemsLen = 0,
      singleItemsLen = 0;
    let showrange, drange;
    if (response) {
      multiItemsLen = response.multi ? response.multi.length : 0;
      singleItemsLen = response.single.length;

      for (var x = 0; x < multiItemsLen; x++) {
        multiname = response.multi[x].itemsinfo.itemid;
        multiItemsM[x] = multiname;
        this["multiselectRef" + multiname + "_" + (x + 1)] = React.createRef();
      }

      for (var y = 0; y < singleItemsLen; y++) {
        singlename = response.single[y].itemsinfo.itemid;
        singleItemsS[y] = singlename;
      }
      for (var z = 0; z < singleItemsLen; z++) {
        singlename = response.single[z].itemsinfo.itemid;
        singleItemsS[z] = singlename;
      }

      if (response.dateRange) {
        dateItemNames = response.dateRange[0].itemsinfo.itemid;
        drange = response.dateRange;
        if (drange.length > 0) showrange = "";
        else showrange = "none";
      } else {
        showrange = "none";
      }
    }
    var event = new Event("i", { bubbles: true });
  }

  resetValues = () => {
    let mlen,
      slen,
      mItems,
      sItems,
      selItem,
      groupsM = [],
      groupsS = [];
    let allitems = this.state;
    mlen = this.state.multiLength;
    slen = this.state.singleLength;
    mItems = this.state.multiItemNames;
    sItems = this.state.singleItemNames;

    for (var x = 0; x < mlen; x++) {
      this["multiselectRef" + mItems[x] + "_" + (x + 1)].current.resetSelectedValues();
      selItem = allitems.selAllMulti[mItems[x]];
      if (typeof selItem == "object" && selItem.length > 0) {
        selItem.map((item) => {
         return  $("#" + item.id).hide();
        });
      }
      $("#divMore_" + mItems[x] + "_" + (x + 1)).hide();
      $("#selectedvals_" + mItems[x] + "_" + (x + 1)).css({
        whiteSpace: "nowrap",
      });
      groupsM[mItems[x]] = [];
    }

    for (var y = 0; y < slen; y++) {
      $("#" + sItems[y] + "_" + (y + 1))
        .val("-1")
        .attr("selected", "selected");
      groupsS[sItems[y]] = "";
    }
    $("#date_1").val("-1").attr("selected", "selected");
    $("#daterange0").hide();
    $("#daterange1").hide();
    $("#daterange2").hide();

    this.setState({ selAllMulti: groupsM, selAllSingle: groupsS });
    let groups = this.state.selAllValues;
    groups.group1 = [];
    groups.group2 = [];
    groups.group3 = [];
    groups.roles = "";
    groups.status = "";
    groups.reset = true;
    this.setState({
      sGroup1: [],
      sGroup2: [],
      sGroup3: [],
      selectedItems1: [],
      selectedItems2: [],
      selectedItems3: [],
      roleid: "",
      statusid: "",
      selAllValues: {
        singleItems: groupsS,
        multiItems: groupsM,
        showDate1: "none",
        showDate2: "none",
      },
    });

  };
  getValue(x) {
    return this.state[x] || "No";
  }

  customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      textOverflow: "ellipsis",
      maxWidth: "90%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "initial",
    }),
  };
  multiValueContainer = ({ selectProps, data }) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex((selected) => selected.label === label);
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? ` (${allSelected.length})` : ", ";
    const val = `${label}${labelSuffix}`;
    return val;
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // alert("Error occured");
  }
  componentDidMount() {
    let response = this.state.getFilters;
    let multiItemsLen = 0, singleItemsLen = 0;
    multiItemsLen = response.multi.length;
    singleItemsLen = response.single.length;
    let groupsM = [],
      groupsS = [],
      singleName,
      multiname;

    let itemsM = [],
      itemsS = [];
    for (var m = 0; m < multiItemsLen; m++) {
      multiname = this.state.getFilters.multi[m].itemsinfo.itemid;
      groupsM[multiname] = [];
      itemsM[m] = multiname;
    }
    for (var n = 0; n < singleItemsLen; n++) {
      singleName = this.state.getFilters.single[n].itemsinfo.itemid;
      groupsS[singleName] = "";
      itemsS[n] = singleName;
    }
    this.setState({
      singles: response.single,
      dateRange: response.dateRange,
      fStatus: response.single.roles,
      groupAll: response.multi,
      helpersLoaded: true,
      multiLength: multiItemsLen,
      singleLength: singleItemsLen,
      multiItemNames: itemsM,
      singleItemNames: itemsS,
      selAllMulti: groupsM,
      selAllSingle: groupsS,
    });
    $("._7ahQImyV4dj0EpcNOjnwA").css({ display: "none" });
    $(".optionContainer li").removeClass("lhyQmCtWOINviMz0WG_gr");

    setTimeout(() => {
      this.setState({
        filterHeight: $('.userFilterComp')[0].scrollHeight,
      });
      $('.userFilterComp').scrollTop(0)
    }, 500);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }
  componentDidUpdate() {
    //$("#multi div:nth-child(1)").find("span").remove()
    if ($("._7ahQImyV4dj0EpcNOjnwA")) {
      $("._7ahQImyV4dj0EpcNOjnwA").css({ display: "none" });
      $("#ddlRoles ._7ahQImyV4dj0EpcNOjnwA").css({ display: "" });
      $(".searchBox").css({ margin: "-2px 2px", "font-weight": "500" });
      $(".optionContainer li").removeClass("lhyQmCtWOINviMz0WG_gr");
    }
    //   $("._2iA8p44d0WZ-WqRBGcAuEV").css({ minwidth: "220px", "background-color":"white", "height":"32px", "overflow": "hidden", "margin-right": "9px" });
  }
  handleDisplay = (event) => {
    let currSelId,
      selId = event.target.id;
    currSelId = selId.split("spnMore")[1];
    if ($("#selectedvals" + currSelId).css("whiteSpace") === "nowrap") {
      $("#selectedvals" + currSelId).css({ whiteSpace: "normal" });
    } else {
      $("#selectedvals" + currSelId).css({ whiteSpace: "nowrap" });
    }
  };

  handleClick(event) {
    let newHeight = $('.userFilterComp')[0].scrollHeight
    console.log("@@@@@@@@@@@@@@@", newHeight, this.state.filterHeight)
    if (newHeight > this.state.filterHeight) {
      $('.userFilterComp').scrollTop(newHeight)
      $('.userFilterComp').css('padding-bottom', (newHeight - this.state.filterHeight + 20) + "px")
      this.setState({
        filterHeight: newHeight,
      });
    }

    if (event.target && event.target.id) {
      let cntrlId;
      cntrlId = event.target.id.split("_input")[0];
      this.setState({ currentId: cntrlId });
    }
  }

  handleItemChange = (event) => {
    let controlid, idx, currSelId;
    controlid = event.target.id;
    idx = controlid.split("_")[1];
    currSelId = event.target.value === "-1" ? "" : event.target.value;
    // locCurrSelId = event.target.value;
    this.setStateValues("single", currSelId, idx);
  };
  async setStateValues(ctype, val, idx, itemId, currId) {
    let allListsM = this.state.selAllMulti;
    let allListsS = this.state.selAllSingle;
    let selectedList = val,
      locCurrSelId = val; //,selCurrId=val;
    if (locCurrSelId === "-1" || locCurrSelId === -1) {
      locCurrSelId = "";
    }

    let singlesel1, multisel1, singleLen, multiLen, objsingle, objMulti;
    let multiName, singleName, itemsinfo;
    singleLen = this.state.singleLength;
    multiLen = this.state.multiLength;

    singlesel1 = this.state.selAllSingle;
    multisel1 = this.state.selAllMulti;

    objsingle = Object.keys(singlesel1);
    objMulti = Object.keys(multisel1);
    if (ctype === "single") {
      itemsinfo = this.state.getFilters.single[idx - 1].itemsinfo;
      singleName = itemsinfo ? itemsinfo.itemid : "Item";
    } else {
      if (this.state.getFilters.multi[idx - 1]) {
        itemsinfo = this.state.getFilters.multi[idx - 1].itemsinfo;
        multiName = itemsinfo ? itemsinfo.itemid : "Item";
      }
    }
    let locSelectedItems, idx1;

    if (idx !== "") {
      if (ctype === "single") {
        allListsS[singleName] = locCurrSelId;
        this.setState({ selectedItems1: selectedList, sGroup1: selectedList });
      } else if (ctype === "multi") {
        allListsM[multiName] = selectedList;
        this.setState({ selectedItems1: selectedList, sGroup1: selectedList });
      } else {
        locSelectedItems = allListsM[multiName];//this.state.selectedItems1;
        idx1 = locSelectedItems.findIndex((item) => item.id === itemId);
        locSelectedItems.splice([idx1], 1);
        this.setState({ selectedItems: locSelectedItems });
        allListsM[multiName] = locSelectedItems;
      }
    }
    let selVals = "selectedValue" + currId;
    let { dateItems } = this.state;

    if (ctype === "multidel") {
      await this.setState({
        selAllValues: {
          multiItems: allListsM,
          singleItems: allListsS,
          dateItems: dateItems,
          [selVals]: locSelectedItems,
        },
      });
    } else {
      this.setState({
        selAllValues: {
          multiItems: allListsM,
          singleItems: allListsS,
          dateItems: dateItems,
        },
      });
    }
    return locSelectedItems;
  }
  onSelect = (selectedList, selectedItem) => {
    let currId;
    let index = 0;
    let allLists = [];
    index = selectedList.length;
    currId = this.state.currentId;
    allLists = this.state.selAllMulti;
    let idx = currId.split("_")[currId.split("_").length - 1];

    $(".chip").css({ display: "none" });
    let itemId = selectedItem.id;
    $("#selectedvals_" + currId)
      .find("span[id=" + itemId + "]")
      .css({ display: "" });
    $("#spnMore_" + currId).html(
      selectedList.length + " " + this.props.labels.labels.LBLITMADDED
    );
    this.setStateValues("multi", selectedList, idx);
  };

  onRemove = (selectedList, removedItem) => {
    let currId = this.state.currentId;
    let allLists = [];
    let itemId = 0;
    itemId = removedItem.id;
    let index = selectedList.length;
    allLists = this.state.selAllSingle;
    let idx = currId.split("_")[currId.split("_").length - 1];
    this.setStateValues("multi", selectedList, idx);
    $("#selectedvals_" + currId)
      .find("span[id=" + itemId + "]")
      .hide();
    index > 1 ? $("#divMore_" + currId).show() : $("#divMore_" + currId).hide();
    $("#spnMore_" + currId).html(selectedList.length + " Items Added");
  };
  async handleClickSels(eve, item, currids) {
    let locSelectedItems = item,
      idx,
      currId = currids[0],
      allLists = [];
    let itemId = item.items.id;
    allLists = this.state.selAllMulti;
    idx = currId.split("_")[currId.split("_").length - 1];
    locSelectedItems = this.setStateValues(
      "multidel",
      locSelectedItems,
      idx,
      itemId,
      currId
    );

    let selVals = "selectedValue" + currId;
    const object = this.state.object;
    object[selVals] = locSelectedItems;
    await this.setState({ object });

    $("#selectedvals_" + currId)
      .find("span[id=" + itemId + "]")
      .hide();
    let index = $("#selectedvals_" + currId + " span").length - $("#selectedvals_" + currId + "> :hidden").length
    index > 1 ? $("#divMore_" + currId).show() : $("#divMore_" + currId).hide();
    $("#spnMore_" + currId).html(
      index + " " + this.props.labels.labels.LBLITMADDED
    );
  }
  handleItemDateChange = (event) => {
    let controlid, idx, currSelId, locCurrSelId;
    controlid = event.target.id;
    idx = controlid.split("_")[1];

    currSelId = event.target.value === "-1" ? "" : event.target.value;
    locCurrSelId = event.target.value;

    let { selectedDate1, selectedDate2 } = this.state;
    let { multiItems, singleItems } = this.state.selAllValues;
    let showDate1 = "none",
      showDate2 = "none",
      objvals = {};
    objvals.datetype = currSelId;
    objvals.values = [];
    let datevals = this.setReadInputs(currSelId);

    switch (currSelId) {
      case "1": //Today
        objvals.values = this.setReadInputs(currSelId);
        break;
      case "2": //operators.YESTERDAY:
        objvals.values = this.setReadInputs(currSelId);
        break;
      case "3": //Today
        objvals.values = this.setReadInputs(currSelId);
        break;
      case "4": //operators.YESTERDAY:
        objvals.values = this.setReadInputs(currSelId);
        break;
      case "5": //Today
        objvals.values = this.setReadInputs(currSelId);
        break;
      case "6": //operators.YESTERDAY:
        objvals.values = this.setReadInputs(currSelId);
        break;
      case "7": //Today
        objvals.values = [selectedDate1];
        showDate1 = "";
        showDate2 = "none";
        break;
      case "8": //operators.YESTERDAY:
        objvals.values = [selectedDate1, selectedDate2];
        showDate1 = "";
        showDate2 = "";
        break;
      default:
        break;
    }

    this.setState({
      dateRangeObj: objvals,
      selAllValues: {
        dateItems: objvals,
        multiItems: multiItems,
        singleItems: singleItems,
      },
      showDate1: showDate1,
      showDate2: showDate2,
    });
  };

  handleDateChange1 = (date) => {
    console.log(date);
    let { dateRangeObj, selAllValues, multiItems, singleItems } = this.state;
    if (dateRangeObj.datetype === "8") {
      dateRangeObj.values[0] = date;
    }

    this.setState({
      selectedDate1: date,
      dateRangeObj: dateRangeObj,
      selAllValues: {
        dateItems: dateRangeObj,
        multiItems: multiItems,
        singleItems: singleItems,
      },
      helperDate1: "",
      error: false,
    });
  };
  handleDateChange2 = (date) => {
    let { dateRangeObj, selAllValues, multiItems, singleItems } = this.state;
    if (dateRangeObj.datetype === "8") {
      dateRangeObj.values[1] = date;
    }

    this.setState({
      selectedDate2: date,
      dateRangeObj: dateRangeObj,
      selAllValues: {
        dateItems: dateRangeObj,
        multiItems: multiItems,
        singleItems: singleItems,
      },
      helperDate2: "",
      error: false,
    });
  };

  handleMerge = () => {
    let singlesel = [],
      multisel = [],
      singlesel1,
      multisel1,
      singleLen,
      multiLen,
      objsingle,
      objMulti;

    singleLen = this.state.singleLength;
    multiLen = this.state.multiLength;

    singlesel1 = this.state.selAllSingle;
    multisel1 = this.state.selAllMulti;

    objsingle = Object.keys(singlesel1);
    objMulti = Object.keys(multisel1);

    let allValues = [];
    allValues[0] = singlesel;
    allValues[1] = multisel;
  };
  onOperatorChange = (operator) => {
    const operators = this.getOperators();

    let xcondition = this.state.condition;
    xcondition.operator = operator;
    xcondition.operatorText = operators[operator];
    if (this.props.filtertype === "date") {
      switch (operators[operator]) {
        case 1:
        case 2:
        case 3:
        case 4:
          xcondition.value = this.setReadInputs(operator);
          break;
        case 5:
        case operators.YESTERDAY:
          let y = this.setReadInputs(operator);
          xcondition.value = y[0];
          break;
        case operators.NOCONDITION:
          xcondition.value = "";
          break;
        default:
          break;
      }
    } else if (this.props.filtertype === "string") {
      switch (operators[operator]) {
        case operators.NOCONDITION:
          xcondition.value = "";
          break;
        default:
          xcondition.value = "";
      }
    } else if (this.props.filtertype === "number") {
      switch (operators[operator]) {
        case operators.NOCONDITION:
          xcondition.value = "";
          break;
        default:
          xcondition.value = 0;
      }
    }

    this.setState(
      {
        condition: xcondition,
        selItemforMultiInput: {},
      }
    );
  };
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  setReadInputs(operators) {
    let xrArr = [];
    let cDate = new Date();
    switch (operators) {
      case "1": //Today
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "2": //operators.YESTERDAY:
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "3": //operators.LAST7DAYS:
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "4": //operators.LAST30DAYS:
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "5": //operators.THISMONTH:
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "6": //operators.LASTMONTH:
        let thisMonthFDate = new Date(
          cDate.getFullYear(),
          cDate.getMonth(),
          1,
          23,
          59,
          59,
          999
        );
        xrArr.push(
          new Date(
            thisMonthFDate.getFullYear(),
            thisMonthFDate.getMonth() - 1,
            1,
            0,
            0,
            0,
            0
          )
        );
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
        default:
          break;
    }
    return xrArr.slice();
  }
  getOperators() {
    return {

    };
  }
  render() {
    const {
      multiValueContainer,
      customStyles
    } = this;
    const { handleSubmit } = this.props;
    let {
      selectedDate1,
      selectedDate2,
      dateRange,
      showDate1,
      showDate2,
    } = this.state;
    dateRange = dateRange ? dateRange : [];
    return (
      <div className="filterComp">
        {!this.state.helpersLoaded ? (
          <Loader></Loader>
        ) : (
          <form action="" class="form-horizontal">
            <div className="clsfilterbody">
              <Modal.Body>
                <div className="userFilterComp" style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden", paddingBottom: "20px" }}>
                  <div class="container">
                    <div className="row">
                      {this.state.singles &&
                        this.state.singles.map((item, cnt) => {
                          return (
                            <div key={cnt} className="mob-wid" style={{width:"315px"}}>
                              {item !== 0 ? (
                                <div
                                  class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12"
                                  onClick={this.handleClick}
                                >
                                  <div
                                    className="cls_filter_wth_label"
                                    style={{ minwidth: "180px" }}
                                  >
                                    {" "}
                                    <label
                                      style={{ textTransform: "capitalize" }}
                                      className="label"
                                    >
                                      {
                                        this.props.labels.labels[
                                        ("lbl" + item.itemsinfo.itemname)
                                          .replace(/ +/g, "")
                                          .toUpperCase()
                                        ]
                                      }
                                    </label>
                                    <select
                                      id={
                                        item.itemsinfo.itemid + "_" + (cnt + 1)
                                      }
                                      className="input-field"
                                      style={{ minWidth: "186px" }}
                                      onChange={this.handleItemChange}
                                    >
                                      <option className="clsoption" value={-1}>
                                        {this.props.labels.labels["LBLALL"] +
                                          "\n"}
                                      </option>
                                      {item.items.map((itm) => {
                                        return (
                                          <option
                                            className="clsoption"
                                            key={itm.id}
                                            value={itm.id}
                                          >
                                            {item.itemsinfo.trans
                                              ? this.props.labels.labels[
                                              itm.name
                                              ]
                                              : itm.name + "\n"}
                                          </option>
                                        );
                                      })}
                                    </select>{" "}
                                  </div>
                                </div>
                              ) : (
                                <div className="cls_filter_wth_label">
                                  {" "}
                                  <Loader></Loader>
                                </div>
                              )}

                              <div class="col-xl-2"></div>
                            </div>
                          );
                        })}
                    </div>
                    <br></br>
                    <div
                      className="row"
                      style={{ display: dateRange.length > 0 ? "" : "none" }}
                      id="daterange"
                    >
                      {dateRange &&
                        dateRange.map((item, cnt) => {
                          return (
                            <div key={cnt} className="mob-wid">
                              {item !== 0 ? (
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div
                                    className="cls_filter_wth_label"
                                    style={{ minwidth: "180px" }}
                                  >
                                    {" "}
                                    <label
                                      style={{ textTransform: "capitalize" }}
                                      className="label"
                                    >
                                      {item.itemsinfo.itemname}
                                    </label>
                                    <select
                                      id={
                                        item.itemsinfo.itemid + "_" + (cnt + 1)
                                      }
                                      className="input-field"
                                      style={{ minWidth: "186px" }}
                                      onChange={this.handleItemDateChange}
                                    >
                                      <option className="clsoption" value={-1}>
                                        {this.props.labels.labels["LBLALL"] +
                                          "\n"}
                                      </option>
                                      {item.items.map((itm) => {
                                        return (
                                          <option
                                            className="clsoption"
                                            key={itm.id}
                                            value={itm.id}
                                          >
                                            {item.itemsinfo.trans
                                              ? this.props.labels.labels[
                                              itm.name
                                              ]
                                              : itm.name + "\n"}
                                          </option>
                                        );
                                      })}
                                    </select>{" "}
                                  </div>
                                </div>
                              ) : (
                                <div className="cls_filter_wth_label">
                                  {" "}
                                  <Loader></Loader>
                                </div>
                              )}

                              <div class="col-xl-2"></div>
                            </div>
                          );
                        })}
                      <div
                        className="cls_filter_wth_label"
                        style={{ minwidth: "180px" }}
                      >
                        <label style={{ textTransform: "capitalize" }} className="label"> </label>
                        <div
                          id="daterange1"
                          style={{ display: showDate1 }}
                          class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12"
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              format="dd/MM/yyyy"
                              style={{ fontSize: "24px" }}
                              helperText={this.state.helperDate}
                              id="Date1"
                              value={selectedDate1}
                              maxDate={new Date()}
                              onChange={this.handleDateChange1}
                              required
                            />
                            <span className="required">
                              {this.state.helperDate}
                            </span>
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div
                        className="cls_filter_wth_label"
                        style={{ minwidth: "30px" }}
                      >
                        <label style={{ textTransform: "capitalize" }} className="label"> </label>
                        <div
                          id="daterange0"
                          style={{ display: showDate2 }}
                          class="col-xl-1"
                        >
                          {" "}
                          <span>To</span>
                        </div>
                      </div>
                      <div
                        className="cls_filter_wth_label"
                        style={{ minwidth: "180px" }}
                      >
                        <label style={{ textTransform: "capitalize" }} className="label"> </label>
                        <div
                          id="daterange2"
                          style={{ display: showDate2 }}
                          class="col-xl-2 col-lg-6 col-md-6 col-sm-12 col-xs-12"
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              variant="inline"
                              format="dd/MM/yyyy"
                              style={{ fontSize: "24px" }}
                              helperText={this.state.helperDate}
                              id="Date2"
                              value={selectedDate2}
                              maxDate={new Date()}
                              onChange={this.handleDateChange2}
                              required
                            />
                            <span className="required">
                              {this.state.helperDate}
                            </span>
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    </div>


                    {this.state.groupAll &&
                      this.state.groupAll.map((item, idx) => {
                        return (
                          <div key={idx} className="row">
                            <div
                              class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12"
                              onClick={this.handleClick}
                            >
                              <label className="label">
                                {
                                  this.props.labels.labels[
                                    item.itemsinfo.itemname
                                  ] ? this.props.labels.labels[
                                  item.itemsinfo.itemname
                                  ] : item.itemsinfo.itemname
                                }
                              </label>
                              <img
                                className="cssCaret"
                                src="../../images/Caret.png"
                                alt=""
                                width="25px"
                                data-toggle="modal"
                              ></img>
                              <Multiselect
                                showCheckbox={true}
                                closeIcon="circle"
                                className="input-field"
                                options={
                                  item.items && $.isArray(item.items)
                                    ? item.items
                                    : JSON.parse(item.items)
                                } // Options to display in the dropdown
                                selectedValues={
                                  this.state[
                                  "selectedValue" +
                                  item.itemsinfo.itemid +
                                  "_" +
                                  (idx + 1)
                                  ]
                                } // Preselected value to persist in dropdown
                                onSelect={this.onSelect} // Function will trigger on select event
                                onRemove={this.onRemove} // Function will trigger on remove event
                                onClick={() =>
                                  this.handleClick(
                                    item.itemsinfo.itemid + "_" + (idx + 1)
                                  )
                                }
                                defaultValue={["df"]}
                                ref={
                                  this[
                                  "multiselectRef" +
                                  item.itemsinfo.itemid +
                                  "_" +
                                  (idx + 1)
                                  ]
                                }
                                closeOnSelect={false}
                                closeMenuOnSelect={false}
                                displayValue="name" // Property name to display in the dropdown options
                                style={customStyles}
                                emptyRecordMsg={
                                  this.props.labels.labels.LBLNOOPTION
                                }
                                isClearable={false}
                                maxLength={7}
                                maxSize={2}
                                autoBlur={true}
                                id={item.itemsinfo.itemid + "_" + (idx + 1)}
                                placeholder={this.props.labels.labels.LBLSELECT}
                                busy
                                busySpinner={
                                  <span className="fas fa-sync fa-spin" />
                                }
                                autoClose={false}
                                native
                                components={{
                                  MultiValueContainer: multiValueContainer,
                                }}
                              />{" "}
                            </div>

                            <div
                              id={
                                "selectedvals_" +
                                item.itemsinfo.itemid +
                                "_" +
                                (idx + 1)
                              }
                              class="col-sm-6"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                marginTop: "24px",
                              }}
                            >
                              <span style={{ display: "none" }}></span>
                              {item.items &&
                                ($.isArray(item.items)
                                  ? item.items
                                  : JSON.parse(item.items)
                                ).map((items) => {
                                  return (
                                    // <div key={item.id}>{item.title}</div>
                                    <span
                                      data-toggle="tooltip"
                                      title={items.name}
                                      key={items.id}
                                      id={items.id}
                                      style={{
                                        display: "none",
                                        marginLeft: "5px",
                                      }}
                                      className="filter_select_item"
                                    >
                                      {items.name.length > 15
                                        ? items.name.substring(0, 15) + "..."
                                        : items.name}
                                      <i
                                        onClick={() =>
                                          this.handleClickSels(
                                            this,
                                            { items },
                                            [
                                              item.itemsinfo.itemid +
                                              "_" +
                                              (idx + 1),
                                            ]
                                          )
                                        }
                                        className="icon_cancel _3VhVi3lFI1vobEEti0dqNg _3crOX-etLxsZ8OgjhYCvt7"
                                      ></i>
                                    </span>
                                  );
                                })}
                            </div>
                            <div
                              id={
                                "divMore_" +
                                item.itemsinfo.itemid +
                                "_" +
                                (idx + 1)
                              }
                              class="col-sm-1"
                              style={{
                                display: "none",
                                color: "white",
                                marginTop: "26px",
                              }}
                            >
                              <div onClick={this.handleDisplay}>
                                <span
                                  id={
                                    "spnMore_" +
                                    item.itemsinfo.itemid +
                                    "_" +
                                    (idx + 1)
                                  }
                                  className="filter_select_item_cnt"
                                >
                                  {this.state.selectedItems.count}
                                </span>
                              </div>
                            </div>
                            <div
                              class="w-100"
                              style={{ marginTop: "30px" }}
                            ></div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{borderTop:"0px"}}>
                {this.props.message === "1" ? (
                  ""
                ) : (
                  <React.Fragment>
                    <button
                      type="button"
                      className="button-style secondary-btn"
                      id="btnReset"
                      onClick={this.resetValues}
                    >
                      {this.props.labels.labels.LBLRSTFILTER}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        this.handleMerge();
                        handleSubmit(this.state.selAllValues);
                      }}
                      className="button-style primary-btn"
                    >
                      {this.props.labels.labels.LBLAPPLYFILTER}
                    </button>
                  </React.Fragment>
                )}
              </Modal.Footer>
            </div>
          </form>
        )}
      </div>
    );
  }
}

FilterComp = reduxForm({
  form: "filtercomp", // a unique name for this form
})(FilterComp);

export default connect()(FilterComp);

import React from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './RysReportsBarChart.scss';
const RysReportBarChart = (props) => {

    const categories = props.chartXAxisTitle.map((title, index) => {
      if(props.colPeriod.length > 0 && window.innerWidth > 767){
        return `${props.colPeriod}`;
      }else{
        return `${title}`;
      }
    });
  const options = {
    chart: {
      type: 'column',
      marginBottom:  30 
    },
    title: {
      text: null, // Remove the chart title
    },
    xAxis: {
      categories: props.chartXAxisTitle, // Set the x-axis categories to the chartXAxisTitle prop
      title: {
        text: null 
      },
      labels: {
        rotation: 0 ,// Rotate x-axis labels to 0 degrees
        style: {
          fontSize: '0.9em'  ,
          fontWeight: '500',
          color: '#000000'
        }
      }
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        enabled: true // Disable y-axis labels
      }   
    },
    plotOptions: {
      series: {
        cursor: 'default', // Set the cursor to default
        marker: {
          enabled: false // Disable markers
        }
      },
      column: {
        pointWidth: 30, // Adjust the width of the bars
        color: '#55AAFF', // Set the color of the bars
        dataLabels: {
          inside: true, 
          enabled: true, // Enable data labels
          style: {
            color: '#333333',
            fontWeight: '900', 
            textOutline: 'none',
            fontSize:"0.9em" ,
          }
        }
      }
    },    
    series: [{
      name: '', // Set the name to an empty string
      data:props.chartXAxisCount,
      marker: {
        enabled: false, // Disable data markers for this series
        radius: 0 
      }
    }],
    tooltip: {
      formatter: function() {
        const pointIndex = this.series.data.indexOf(this.point);
        const category = props.colPeriod.length > 0 ? props.colPeriod[pointIndex] : '';
        let tooltipContent = `<div style="font-family: Roboto">${props.labels?.LBLTOTALRESPONSES}: </div>` +
                             '<div style="font-weight: bold">' + this.y + '</div><br/>';
    
        if (props.colPeriod.length > 0) {
          tooltipContent += `<div style="font-family: Roboto">${props.labels?.LBLDATE}: </div>` +
                            `<div style="font-weight: bold">${category}</div>`;
        }
    
        return tooltipContent;
      }
    },    
    exporting: {
      enabled: false // Disable the menu icon
    },
    credits: {
      enabled: false // Disable the watermark
    },
  };

  return (
    <div style={{ height: '268px' }} className='RysReportBarChart'>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { height: '100%' } }}
      />
    </div>
  );
};

export default RysReportBarChart;

import React, { Component } from "react";
import "./dashboard.scss";
import ScServices from "../../services/services";
import { Chart } from "react-chartjs-2";
import RoleChanger from "../../../services/RoleChanger";
import { connect } from "react-redux";
import ReportServices from "../reports/report_services";
import DoughnutChart from "../../components/charts/DoughnutChart";
import Skeleton from "react-loading-skeleton";

///NEW CHANGE
import RYSAveragesBar from "../../components/charts/rys_average_states";
import RYSAveragesByState from "../../components/charts/rys_average_states_bystate";
import StackedColumn100Chart from "../../components/charts/stackedcolumn100chart";
import UsersService from "../user-list/UsersService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import $ from 'jquery';
import emptyImage from '../../../assets/images/dashboard _Emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import styles from "./dashboard.module.scss";
import { Modal } from "react-bootstrap";
import infoIcon from "../../../../src/assets/images/infoicon.png";
import Infored from "../../../../src/assets/images/Infored.png";
// import ScCommonServices from "../../../services/common_services";
import infoaccu from "../../../../src/assets/images/infoaccu.png"

highchartsGantt(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

var _ = {};
const moment = require("moment");
const cstLabels = {
  "LBLCOURSENAME": "Course Name",
  "LBLVIEWTRAININGCOMPLETIONBYUNIT": "View Completion Level by Learning Unit",
  "LBLAVERAGERYS": "Average Rate Your State",
  "LBLVIEWLBLAVERAGERYS": "View Average Rate Your State",
  "LBLTRAININGCOMPLETION": "Training Completion",
}
const REFS = {};
let objFilterData = {};
//UPTO THIS
var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);
    var chart = this.chart;
    var width = chart.chart.width,
      height = chart.chart.height,
      ctx = chart.chart.ctx;
    ctx.textBaseline = "top";
    var text = "",
      textX = Math.round((width - ctx.measureText(text).width) / 2),
      textY = height / 2;
    ctx.fillText(text, textX, textY);
  },
});

// let data = {
//   labels: [""],
//   datasets: [
//     {
//       data: [300, 50],
//       backgroundColor: ["#689ee3", "#80c6e5"],
//       hoverBackgroundColor: ["#689ee3", "#80c6e5"],
//     },
//   ],
// };

// const options = {
//   display: true,
//   position: "bottom",
//   align: "center",
//   fullWidth: true,
//   reverse: false,
//   labels: {
//     fontColor: "rgb(255, 99, 132)",
//     boxWidth: 10,
//   },
// };

// const rysStatusFields = {
//   1: "rushing",
//   3: "fatigue",
//   2: "frustration",
//   4: "complacency",
// };

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGraphData: false,
      rysmode: false,
      trainingCompDataLoaded: false,
      firstLoad: false,
      rysStates: "",
      data: {},
      token: "",
      get: false,
      userId: "",
      dash_labels: {},
      rys: {},
      trainingCompData: {},
      active_courseusers: "",
      scatter_date: {},
      rysstate: 1,
      linedata: {},
      loading: false,
      RYS_D1_GPH_SelStateId: "1",
      visible: true,
      trainingGraphType: "CourseWise",
      trainingGraphTitle: this.getLabel("LBLTRAININGCOMPLETION"),
      trainingGraphViewTitle: this.getLabel("LBLVIEWTRAININGCOMPLETIONBYUNIT"),
      trainingGraphImage: "baricon.png",

      rysGraphType: "AverageRYS",
      rysGraphTitle: this.getLabel("LBLAVERAGERYS"),//LBLTRAININGCOMPLETION,LBLVIEWTRAININGCOMPLETIONBYUNIT,LBLAVERAGERYS
      rysGraphViewTitle: "",//this.getLabel("LBLVIEWLBLAVERAGERYSBYSTATE") + "-" + this.getLabel("LBLRUSHING"),
      rysGraphImage: "baricon.png",
      dates: [
        { "id": "TODAY", "value": "LBLTODAY" },
        { "id": "YESTERDAY", "value": "LBLYESTERDAY" },
        { "id": "LAST7DAYS", "value": "LBLLAST7DAYS" },
        { "id": "LAST30DAYS", "value": "LBLLAST30DAYS" },
        { "id": "THISMONTH", "value": "LBLTHISMONTH" },
        { "id": "LASTMONTH", "value": "LBLLASTMONTH" },
        { "id": "YTD", "value": "LBLYTD" },
      ],
      rys_date: "YTD",
      showExpiryModal: null,
      showExpiryModalText: '',
      expiryBannerText: '',
      showExpiryBannerText: false,
      deactivationBannerText: '',
      expiryBannerColor: "",
      deactivebannerColor: "",
      showDeactiveText: "",
      Sitetype: localStorage.getItem('site_type'),
      alreadyactPopupShown: true,
      alreadydeactPopupshown: true,
      showexpmodal: true,
      showdecmodal: true,
      newdate: ""

    };
  }
  contactSupportModal = () => {
    alert("hii")
    this.setState({
      showExpiryBannerText: false,
      deactivationBannerText: false
    });
  }
  handleClick = () => {
    this.setState({
      expiryBannerText: false,
      deactivationBannerText: false
    })
  }

  closeModal = () => {
    this.setState({
      showExpiryModal: false,
      showactmodal: false,
      showdecmodal: false
    });
    let currentDate = moment((new Date())).format(this.props.dateformat);
    console.log("currentDate", currentDate)
    if (this.state.showExpiryModalText) {
      localStorage.setItem("showExpiryModal", currentDate)
    } else if (this.state.deactivationBannerText) {
      localStorage.setItem("showDeactivateModal", currentDate)
    }
  }
  cancelModal = () => {
    this.setState({
      showExpiryModal: false,
      showactmodal: false,
      showdecmodal: false,
    });
    let currentDate = moment((new Date())).format(this.props.dateformat);
    console.log("currentDate", currentDate)
    if (this.state.showExpiryModalText) {
      localStorage.setItem("showExpiryModal", currentDate)
    } else if (this.state.deactivationBannerText) {
      localStorage.setItem("showDeactivateModal", currentDate)
    }
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  rysStatusTranslation = {
    1: this.getLabel("LBLRUSHING"),
    3: this.getLabel("LBLFATIGUE"),
    2: this.getLabel("LBLFRUSTRATION"),
    4: this.getLabel("LBLCOMPLACENCY"),
  };

  getData = async () => {

    //return await setTimeout(async () => {
    await ScServices.dashboardService().then((res) => {
      let allData = res.result[0];
      allData.active_users = allData.active_users - allData.consultant_active;
      allData.inactive_users = allData.inactive_users - allData.consultant_inactive;

      this.setState({
        data: allData,
        get: true,
      });
      console.log(" res.result[0]", res.result[0])
    });

    objFilterData = {};
    let getData = {
      language: this.props.language,
    };
    await UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      //{group_type_id: "GP_1", lbl_GP_1: "Location", Active: "1"}
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
          groupsLoaded: "YES"
        },
      );
      this.setSiteExpiry();
    });

    let lp_data = {};
    lp_data.offsetTime = this.getOffsetTime();
    this.getLP_DSBD_D1(lp_data)
    // setTimeout(() => {
    let data = {};
    data.offsetTime = this.getOffsetTime();
    data.rys_dates = this.getrysdates("YTD")
    this.getRYS_D1_List(data);

    this.setState({
      trainingGraphType: "CourseWise",
      trainingGraphTitle: this.getLabel("LBLTRAININGCOMPLETION"),
      trainingGraphViewTitle: this.getLabel("LBLVIEWTRAININGCOMPLETIONBYUNIT"),
      trainingGraphImage: "baricon.png",

      rysGraphType: "AverageRYS",
      rysGraphTitle: this.getLabel("LBLAVERAGERYS"),
      rysGraphViewTitle: "",//this.getLabel("LBLVIEWLBLAVERAGERYSBYSTATE") + "-" + this.getLabel("LBLRUSHING"),
      rysGraphImage: "baricon.png",

    });

    // }, 1000);
    await ScServices.dashboardActiveCourseUsers().then((res) => {
      this.setState({
        active_courseusers: res[0].activecourseuserscount,
        get: true
      });
    });
    this.setState({ visible: false });
    //}, 5000)
  };

  getReportTrainingCompletion = (data) => {
    let pieColors = ["#A6D486", "#7DB1F5", "#C8C6C6"]; //7DB1F5  F5C947 C8C6C6
    let objData = [];
    let { LBLCOMPLETED, LBLINPROGRESS, LBLNOTSTARTED } = this.props.labels.labels;
    let categories = [LBLCOMPLETED, LBLINPROGRESS, LBLNOTSTARTED];
    if (data && data.length > 0) {
      objData.push({ name: LBLCOMPLETED, y: data[0].Completed },
        { name: LBLINPROGRESS, y: data[0].Inprogress },
        { name: LBLNOTSTARTED, y: data[0].Notstarted }
      );
    }
    let chart_data = {
      chart: {
        type: 'pie',
        marginTop: -150,
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      legend: {
        layout: 'horizontal',
        floating: true,
        //align: 'left',
        verticalAlign: 'top',
        y: 200,
        //x: 90
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{}: <b>{point.y} </b>',
        percentageDecimals: 0
      },
      title: {
        text: null,
        align: 'left',
      },
      subtitle: {
        text: null,
        align: 'left'
      },
      plotOptions: {
        pie: {
          size: 160,
          innerSize: 80,
          colors: pieColors,
          depth: 35,
          dataLabels: {
            enabled: true,
            format: '<b>{point.y}</b>',
            distance: -22,
            filter: {
              property: 'percentage',
              operator: '>',
              value: 4
            }
          }
        },
        series: {
          cursor: 'pointer',
          events: {
            click: function (event) {
              alert(
                this.name + ' clicked\n' +
                'Alt: ' + event.altKey + '\n' +
                'Control: ' + event.ctrlKey + '\n' +
                'Meta: ' + event.metaKey + '\n' +
                'Shift: ' + event.shiftKey
              );
            }
          }
        }
      },
      xAxis: {
        categories: categories
      },
      series: [{
        showInLegend: true,
        data: objData
      }]
    };
    return chart_data;
  }
  getLP_DSBD_D1 = (data) => {
    ScServices.getLP_DSBD_D1(data).then((res) => {
      let result2 = res.result[9];
      let courses = res.result[10];
      let result1 = res.result[11];
      // let colors = ["#9bbb58", "#4f81bc", "#c0504e"];
      // let hovercolors = ["#9bbb58", "#4f81bc", "#c0504e"];
      let data1 = {
        labels: [],
        datasets: [{ graphData: result1, backgroundColor: [], hoverBackgroundColor: [], ynames: [] }],
      };
      let data2 = {
        labels: [],
        datasets: [{ graphData: result2, backgroundColor: [], hoverBackgroundColor: [], ynames: [] }],
      };
      // let options_sf = this.getReportTrainingCompletion(result1);
      this.setState({
        //options_sts:options_sf,
        trainingCompData: data1,
        trainingCompDataUnitWise: data2,
        orgTrainingCompData: result1,
        loading: true,
        trainingCompDataLoaded: true,
        courses: courses,
        tcdstate: result1 && result1[0] ? result1[0].product_id : this.state.tcdstate,
        selCourseUnits: result2
      });
      if (result2.length === 1) {
        this.showTrainingGraph("CourseWise")
      }

    });
  }

  getNumberOfDays = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  componentDidMount() {
    _ = this
    this.getData();
    this.setState({
      rysGraphTitle: this.props.labels.labels.LBLAVERAGERYS
    })

    $(document).on('mouseup', function (e) {
      if (REFS) {
        for (let x in REFS) {
          if (REFS[x]) {
            REFS[x].handlePreviousInputBlur("aa")
          }
        }
      }
    });
  }
  setSiteExpiry = () => {
    console.log("iii", this.state.deactivationBannerText, this.state.expiryBannerText)
    var a = moment(new Date());
    var b = moment(this.props.companyInfo.site_expire_date);
    let remainingDays = a.diff(b, 'days');
    console.log('showExpiryModalText', remainingDays);
    if (remainingDays > (this.props.companyInfo.site_expire_alert_days * -1) && remainingDays <= 0) {
      console.log("remainingDays", remainingDays)
      console.log("this.props.companyInfo.site_expire_alert_days", this.props.companyInfo.site_expire_alert_days)
      console.log("warning alert text");
      let warningText = this.getLabel("LBLEXPIRYWARNINGTXT")
      let expiryBannerTxt = this.getLabel("LBLEXPIRYWARNINGBANNERTXT")
      let expirySupportTxt = this.getLabel("LBLSUPPORT")
      let accuSupportTxt = this.getLabel("LBLACCUSUPPORT")
      // warningText = warningText.replace('_$$_', (30 + remainingDays));
      warningText = warningText.replace('$$$$$$', moment(this.props.companyInfo.site_expire_date).format(this.props.dateformat));
      // expiryBannerTxt = expiryBannerTxt.replace('_$$_', (30 + remainingDays));
      expiryBannerTxt = expiryBannerTxt.replace('$$$$$$', moment(this.props.companyInfo.site_expire_date).format(this.props.dateformat));
      let tempShowExpiry = remainingDays === ((this.props.companyInfo.site_expire_alert_days - 1) * -1);
      console.log("showExpiryModal", this.state.showExpiryModal)
      let currentDate = moment((new Date())).format(this.props.dateformat);
      console.log("currentDate", currentDate)
      tempShowExpiry = localStorage.getItem('showExpiryModal') === currentDate ? false : tempShowExpiry;
      this.setState({
        showExpiryModal: tempShowExpiry,
        showExpiryModalText: warningText,
        expiryBannerText: tempShowExpiry ? expiryBannerTxt : '',
        expirySupportText: tempShowExpiry ? expirySupportTxt : '',
        accuSupportText: accuSupportTxt,
        deactivationBannerText: '',
        showDeactiveText: "",
        showexpmodal: localStorage.getItem('showExpiryModal')
      });
    }
    else if (remainingDays > 0 && remainingDays <=this.props.companyInfo.bonus_days) {
      console.log("Deactivation alert text");
      console.log("deactivationBannerText", this.state.deactivationBannerText)
      let deactiveSupportTxt = this.getLabel("LBLSUPPORT")
      let accudeSupportTxt = this.getLabel("LBLACCUSUPPORT")
      let DeactiveWarningText = this.getLabel("LBLEXPIRYWARNINGDEACTIVEBANNERTXT")
      // DeactiveWarningText =DeactiveWarningText.replace('_$$_', (30 + remainingDays));
      DeactiveWarningText = DeactiveWarningText.replace('$$$$$$', moment(this.props.companyInfo.site_expire_date).add(this.props.companyInfo.bonus_days, "days").format(this.props.dateformat));
      let deactivationBannerTxt = this.getLabel("LBLEXPIRYWARNINGDEACTIVEBANNERTXT")
      // deactivationBannerTxt = deactivationBannerTxt.replace('_$$_', (30 + remainingDays));
      deactivationBannerTxt = deactivationBannerTxt.replace('$$$$$$', moment(this.props.companyInfo.site_expire_date).add(this.props.companyInfo.bonus_days, "days").format(this.props.dateformat));
      console.log("deactivationBannerTxt", deactivationBannerTxt)
      let tempShowDeactivateExpiry = (remainingDays === 1)
      let currentDate = moment((new Date())).format(this.props.dateformat);
      console.log("currentDate", currentDate)
      tempShowDeactivateExpiry = localStorage.getItem('showDeactivateModal') === currentDate ? false : tempShowDeactivateExpiry;
      this.setState({
        showExpiryModal: tempShowDeactivateExpiry,
        deactivationBannerText: tempShowDeactivateExpiry ? deactivationBannerTxt : '',
        deactiveSupportText: tempShowDeactivateExpiry ? deactiveSupportTxt : '',
        accudeSupportText: accudeSupportTxt,
        expiryBannerText: '',
        showDeactiveText: DeactiveWarningText,
        showExpiryModalText: "",
        showdecmodal: localStorage.getItem('showDeactivateModal'),
      });
    }

  }
  getOffsetTime = () => {
    return "053000";
  };

  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };

  getRYS_D1_Summary = (xresult, ydata) => {
    let data = {
      labels: [],
      datasets: [{ graphData: xresult, backgroundColor: [], hoverBackgroundColor: [], ynames: [] }],
    };

    // let yearpos, monthpos, datepos;
    // let arrDtFmt = this.props.dateformat.split("/");
    // for (let txi = 0; txi < arrDtFmt.length; txi++) {
    //   if (arrDtFmt[txi].toUpperCase().indexOf("D") > -1) {
    //     datepos = txi;
    //   }
    //   if (arrDtFmt[txi].toUpperCase().indexOf("M") > -1) {
    //     monthpos = txi;
    //   }
    //   if (arrDtFmt[txi].toUpperCase().indexOf("Y") > -1) {
    //     yearpos = txi;
    //   }
    // }
    // var getObjDate = (xstrday) => {

    //   let arrdt = xstrday.split("/").length > 1 ? xstrday.split(" ")[0].split("/") : xstrday.split(" ")[0].split("-");
    //   let arrdt2 = xstrday.split(" ")[1].split(":");
    //   return new Date(
    //     arrdt[yearpos],
    //     parseInt(arrdt[monthpos]) - 1,
    //     arrdt[datepos],
    //     arrdt2[0],
    //     arrdt2[1],
    //     0, 0
    //   );
    // }

    // var getObjDate = (xstrday) => {
    //   let arrdt = xstrday.split(" ")[0].split("-");
    //   let arrdt2 = xstrday.split(" ")[1].split(":");
    //   return new Date(
    //     arrdt[0],
    //     parseInt(arrdt[1]) - 1,
    //     arrdt[2],
    //     arrdt2[0],
    //     arrdt2[1],
    //     0, 0
    //   );
    // }
    let xstartDay = ""
    let xendDay = ""
    let ryscolumns = objFilterData.availableRYS_D1Fields

    let xcondition
    if (!ryscolumns) {
      let columns = this.getRYS_D1_Columns();
      ryscolumns = this.getFilterColumns("RYS_D1", columns);
      let xdata = { filtercolumns: ryscolumns }
      this.setsvaluefordate(xdata)
      for (let i = 0; i < ryscolumns.length; i++) {
        if (ryscolumns[i].selector === "created_on") {
          xcondition = ryscolumns[i].filter.condition
        }
      }
    } else {
      for (var key in ryscolumns) {
        if (ryscolumns[key].selector === "created_on") {
          xcondition = ryscolumns[key].condition
        }
      }
    }

    if (xcondition.svalue) {
      xstartDay = xcondition.svalue[0]
      if (xcondition.svalue[1]) {
        xendDay = xcondition.svalue[1]
      } else {
        xendDay = xstartDay.split(" ")[0] + " 23:59:59.000"
      }
    }
    //alert(xstartDay + "--" + xendDay)
    // let objStartDate = getObjDate(xstartDay)
    // let objEndDate = getObjDate(xendDay)



    data.date1 = this.state.rys_date1;
    data.date2 = this.state.rys_date2;
    this.setState({
      rysGraphTitle: this.props.labels.labels.LBLAVERAGERYS
    })
    return data;

  };
  setsvaluefordate = (data) => {
    if (data.filtercolumns) {
      for (let j = 0; j < data.filtercolumns.length; j++) {
        if (data.filtercolumns[j].filter.type === "date" && data.filtercolumns[j].filter.condition) {
          let dt1, dt2;
          let xcondition = JSON.parse(JSON.stringify(data.filtercolumns[j].filter.condition))
          // let ddata = {}
          if (typeof xcondition.value == "string") {
            let arrdt = xcondition.value.split("T")[0].split("-");
            let arrdt2 = xcondition.value.split("T")[1].split(":");
            xcondition.xvalue = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          } else {
            if (typeof xcondition.value == "object" && xcondition.value[0] && typeof xcondition.value[0] == "string") {
              xcondition.xvalue = []
              let arrdt = xcondition.value[0].split("T")[0].split("-");
              let arrdt2 = xcondition.value[0].split("T")[1].split(":");
              xcondition.xvalue[0] = new Date(
                arrdt[0],
                parseInt(arrdt[1]) - 1,
                arrdt[2],
                arrdt2[0],
                arrdt2[1],
                0, 0
              );
            }
            if (typeof xcondition.value == "object" && xcondition.value[1] && typeof xcondition.value[1] == "string") {
              let arrdt = xcondition.value[1].split("T")[0].split("-");
              let arrdt2 = xcondition.value[1].split("T")[1].split(":");
              xcondition.xvalue[1] = new Date(
                arrdt[0],
                parseInt(arrdt[1]) - 1,
                arrdt[2],
                arrdt2[0],
                arrdt2[1],
                0, 0
              );
            }
          }
          if (typeof xcondition.value == "object" && xcondition.value[0] && xcondition.value[1]) {
            dt1 = new Date(xcondition.xvalue[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
            dt2 = new Date(xcondition.xvalue[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          } else {
            dt1 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
            dt2 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          }
          data.filtercolumns[j].filter.condition["svalue"] = [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
        }
      }
    }
  }
  getRYS_D1_List(data) {
    data.report = "RYS_D1";
    this.setsvaluefordate(data)
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;
    data.offsetTime = this.getOffsetTime();
    ReportServices.getRYS_D1_List(data).then((response) => {
      let result = response.result[7];
      // let xsummaryData = {};
      // xsummaryData.graphData = this.getRYS_D1_Summary(result, data);
      // xsummaryData.records = result.length
      this.setState({
        pageState: "RYS_D1",
        RYS_D1_List: result,
        orgRYS_D1_List: result,
        //RYS_D1_SummaryData_Old: this.getRYS_D1_Summary_Old(result, data),
        RYS_D1_SummaryData: this.getRYS_D1_Summary(result, data),
        sel_D1_Data: data,
        RYSDataLoaded: true,
        zoomChanged: 0
      });
    });
  }

  getRYSDatesFilter() {
    let strdateval = (id) => {
      return this.getLabel("LBL" + id)// + " (" + this.getrysdatesvalues(id) + ")"
    }
    return [
      { "id": "TODAY", "value": strdateval("TODAY") },
      { "id": "YESTERDAY", "value": strdateval("YESTERDAY") },
      { "id": "LAST7DAYS", "value": strdateval("LAST7DAYS") },
      { "id": "LAST30DAYS", "value": strdateval("LAST30DAYS") },
      { "id": "THISMONTH", "value": strdateval("THISMONTH") },
      { "id": "LASTMONTH", "value": strdateval("LASTMONTH") },
      { "id": "YTD", "value": strdateval("YTD") },
    ]
  }

  getLearnerProgressColumns = (x) => {
    let f1columns = [
      {
        name: this.getLabel("LBLCOURSENAME"),
        selector: "product_name",
        filter: {
          type: "stringsingle",
          showField: false,
          checked: true,
          required: true,
          condition: {
            name: this.getLabel("LBLCOURSENAME"),
            operator: "EQUALS",
            value: this.state.courses ? [this.state.courses[0]] : "",
            operatorText: "",
          }
        },
        filterData: this.state.courses,
        sortable: true,
        exportcell: "_product_name",
        exportcellproperty: { order: 300, cellwidth: 20 },
        tableIndex: 1,
        width: "220px",
        cell: (row) => {
          return ""
        },
      }
    ];
    let xcolumns = [];
    let response = this.state.groupTypeNames;
    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        //let x1 = response[i].group_type_id
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          filter: {
            type: "string",
            showField: false,
            checked: false,
          },
          filterData: this.state.groups[response[i].group_type_id],
          sortable: true,
          compact: true,
        });
        ////alert(this.state[response[i].group_type_id + "Names"])
      }
    }

    f1columns.splice.apply(f1columns, [2, 0].concat(xcolumns));
    ////alert(f1columns.length)
    // console.log("oooooooooooooooooooooooo", f1columns);
    return f1columns; //JSON.parse(JSON.stringify(columns))
  };
  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "LearnerProgress") {
      let xobj = objFilterData.availableLearnProgressFields;
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    } else if (mode === "rysAverage") {
      let xobj = objFilterData.availableRYS_D1Fields;
      if (xobj) {
        for (var k = 0; k < xcols.length; k++) {
          xcols[k].filter = xobj[xcols[k].name];
        }
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };

  onFilterChange = (id, conditions) => {
    let isValid = true
    conditions.map((item, i) => {
      if (item.filter.checked && item.filter.required) {
        try {
          if ((item.filter.condition.operator === "MULTI_EQUALS" || item.filter.condition.operator === "EQUALS") && item.filter.condition.value === "") {
            isValid = false
            REFS[id].onError(item)
          }
        } catch (e) {
          isValid = false
          REFS[id].onError(item)
        }
      }
      return null;
    });
    if (isValid === true) {
      if (id === "F1") {
        REFS.F1.onCloseCheckList()
        this.setFilterFields("F1", conditions);
        let data = { type: "web", columns: objFilterData.availableLearnProgressFields };
        data.filtercolumns = conditions;
        this.getLP_DSBD_D1(data);
      } else if (id === "F2") {
        this.setFilterFields("F2", conditions);
        let data = { type: "web", columns: objFilterData.availableRYS_D1Fields };
        data.filtercolumns = conditions;
        this.setsvaluefordate(data)
        this.getRYS_D1_List(data);
      }
    }
  };

  setFilterFields = (id, conditions) => {
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportcellproperty) {
        xobj[conditions[i].name].exportcellproperty =
          conditions[i].exportcellproperty;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      objFilterData.availableLearnProgressFields = xobj;
    } else if (id === "F2") {
      objFilterData.availableRYS_D1Fields = xobj;
    }
  };
  showFilter = (id) => {
    if (id === "F1" && REFS) {
      if (REFS.F1.getOpenStatus() === "CLOSE") {
        REFS.F1.showCheckList()
      } else {
        REFS.F1.onCloseCheckList()
      }
    } else if (id === "F2" && REFS) {
      if (REFS.F2.getOpenStatus() === "CLOSE") {
        REFS.F2.showCheckList()
      } else {
        REFS.F2.onCloseCheckList()
      }
    }
  }
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  getRYS_D1_Columns = (x) => {
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
    xrArr.push(new Date());
    let columns = [
      {
        name: this.props.labels.labels.LBLDATE,
        selector: "created_on",
        filter: {
          type: "date",
          showField: true,
          checked: true,
          render: "server",
          condition: {
            name: "Date",
            operator: "YTD",
            value: xrArr.slice(),
            operatorText: this.getLabel("LBLYTD"),
          },
          exportcell: "_created_on_dateformat",
        },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLDATE}
              </label>
              <span className="rle-brdr">
                {row.created_on_format}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLRUSHING,
        selector: "rushing",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLRUSHING}
              </label>
              <span className="rle-brdr">{row.rushing}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLFATIGUE,
        selector: "fatigue",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLFATIGUE}
              </label>
              <span className="rle-brdr">{row.fatigue}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLFRUSTRATION,
        selector: "frustration",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLFATIGUE}
              </label>
              <span className="rle-brdr">{row.frustration}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLACENCY,
        selector: "complacency",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLCOMPLACENCY}
              </label>
              <span className="rle-brdr">{row.complacency}</span>
            </div>
          );
        },
      },
    ];

    let xcolumns = [];
    let response = this.state.groupTypeNames || [];
    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          filter: { type: "string", showField: false, checked: false },
          filterData: this.state.groups[response[i].group_type_id],
          sortable: true,
          compact: true
        });
      }
    }

    columns.splice.apply(columns, [1, 0].concat(xcolumns));
    return columns; //JSON.parse(JSON.stringify(columns))
  };


  showRYSGraph = (x, dp) => {
    if (x === "AverageRYS-State") {
      let rysState = dp.states ? dp.states : this.state.rysStates;
      rysState = this.getLabel("LBL" + rysState.toUpperCase());
      this.setState({
        rysGraphType: "AverageRYS-State",
        rysGraphTitle: this.getLabel("LBLAVERAGERYSBYSTATE") + " - " + rysState,
        rysGraphViewTitle: this.getLabel("LBLVIEWLBLAVERAGERYS"),
        rysLevel2Data: dp,
        rysStates: dp.states,
        rysGraphImage: "baricon.png",
        rysmode: true
      })
    } else {
      this.setState({
        rysGraphType: "AverageRYS",
        rysGraphTitle: false,
        rysGraphViewTitle: "",//this.getLabel("LBLVIEWLBLAVERAGERYSBYSTATE") + "-" + this.getLabel("LBLRUSHING"),
        rysLevel2Data: {},
        rysGraphImage: "baricon.png",
        rysmode: false
      });
    }
  }
  showTrainingGraph = (x, mode) => {
    if (x === "UnitWise") {
      if (this.state.selCourseUnits.length === 1) {
        this.onDetailReportLevel2()
      } else {
        this.setState({
          trainingGraphType: "UnitWise",
          trainingGraphTitle: this.getLabel("LBLTRAININGCOMPLETIONBYUNIT"),
          trainingGraphViewTitle: this.getLabel("LBLVIEWTRAININGCOMPLETION"),
          trainingGraphImage: "pieicon.png"
        })
      }
    } else {
      this.setState({
        trainingGraphType: "CourseWise",
        trainingGraphTitle: this.getLabel("LBLTRAININGCOMPLETION"),
        trainingGraphViewTitle: this.getLabel("LBLVIEWTRAININGCOMPLETIONBYUNIT"),
        trainingGraphImage: "baricon.png"
      })
    }
  }
  onDetailReportLevel2 = (xdataPoint) => {
    // xdataPoint = xdataPoint ? xdataPoint : {}
    // let ryscolumns = objFilterData.availableRYS_D1Fields
    // if (!ryscolumns) {
    //   let columns = this.getLearnerProgressColumns();
    //   ryscolumns = this.getFilterColumns("LearnerProgress", columns);
    // }
    this.props.history.push({
      pathname: "/schome/reports/lcs",
      state: {
        selProduct: this.state.tcdstate,
        name: "Ina"
      },
    });
    // this.props.history.push("/schome/reports/lcs");
  }
  onRYSDetailReportLevel2 = (xdataPoint) => {
    let ryscolumns = objFilterData.availableRYS_D1Fields
    if (!ryscolumns) {
      let columns = this.getRYS_D1_Columns();
      ryscolumns = this.getFilterColumns("RYS_D1", columns);
    }

    this.props.history.push({
      pathname: "/schome/reports/rate_your_state",
      state: {
        rys_date: this.state.rys_date,
      },
    });

    //this.props.history.push("/schome/reports/rate_your_state");


    // this.setState({
    //   showGraphData:true
    // })
  }
  getrysdatesvalues = (xvalue) => {
    let xrArr = []
    let cDate = new Date();
    switch (xvalue) {
      case "LAST7DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "LAST30DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "THISMONTH":
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "LASTMONTH":
        let thisMonthFDate = new Date(cDate.getFullYear(), cDate.getMonth(), 1, 23, 59, 59, 999);
        xrArr.push(new Date(thisMonthFDate.getFullYear(), thisMonthFDate.getMonth() - 1, 1, 0, 0, 0, 0));
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
      case "YTD":
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "TODAY":
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "YESTERDAY":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      default:
        break;
    }
    if (xrArr.length == 1) {
      return moment(xrArr[0]).format(this.props.dateformat)
    } else {
      return moment(xrArr[0]).format(this.props.dateformat) + " " + this.getLabel("LBLTO") + " " + moment(xrArr[1]).format(this.props.dateformat)
    }
  }
  getrysdates = (xvalue) => {
    let cDate = new Date();
    let xrArr = []
    switch (xvalue) {
      case "LAST7DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "LAST30DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "THISMONTH":
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "LASTMONTH":
        let thisMonthFDate = new Date(cDate.getFullYear(), cDate.getMonth(), 1, 23, 59, 59, 999);
        xrArr.push(new Date(thisMonthFDate.getFullYear(), thisMonthFDate.getMonth() - 1, 1, 0, 0, 0, 0));
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
      case "YTD":
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "TODAY":
        xrArr.push(new Date(cDate.getTime()));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "YESTERDAY":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      default:
        break;
    }
    let dt1 = new Date(xrArr[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
    let dt2 = new Date(xrArr[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));

    let xxdt1 = new Date(xrArr[0].getTime());
    let xxdt2 = new Date(xrArr[1].getTime());
    xxdt1.setHours(0)
    xxdt1.setMinutes(0)
    xxdt1.setSeconds(0)
    xxdt1.setMilliseconds(0)
    xxdt2.setHours(23)
    xxdt2.setMinutes(59)
    xxdt2.setSeconds(59)
    xxdt2.setMilliseconds(0)
    this.setState({
      rys_date1: xxdt1,
      rys_date2: xxdt2
    })
    return [this.getDateFormat(dt1) + " 00:00:00.000", this.getDateFormat(dt2) + " 23:59:59.999"]
  }

  onZooomChangeEvent = (x) => {
    this.setState({
      zoomChanged: x
    })
  }
  render() {
    console.log(this.state.RYS_D1_SummaryData, "RYS_D1_SummaryData")
    let RYSShow = Boolean($('body').attr('RYS-SHOW'));
    let optionClass = RYSShow
      ? "col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12"
      : "col-xl-12 col-lg-12 col-md-12 col-sm-12";
    let selectClass = RYSShow
      ? "col-xl-12 col-lg-12 col-md-12 col-sm-12 ml-auto"
      : "col-xl-7 col-lg-7 col-md-7 col-sm-12 ml-auto"
    const {
      LBLACTIVEUSERS,
      LBLTOTALRATEYOURSTATE,
      LBLTRAININGCOMPLETION,
      LBLUSERDATA,
      LBLCOUNT,
      LBLDATE,
      LBLAVERAGERYS,
      LBLDASHBOARDEMPTYTXT,
      LBLNOOPTIONSDROPDOWN,
      LBLTOTALLICENSES,
      LBLAVAILABLELICENSES,
      LBLINACTIVEUSERS,
      LBLCONTACTSUPPORT,
      LBLRENEWLICENSE,
      LBLCANCEL,
      LBLEXPIRYWARNINGDEACTIVEBANNERTXT,
      LBLEXPIRYWARNINGTX,
      LBLEXPIRYWARNINGBANNERTXT,
      LBLEXPIRYWARNINGTXT,
      LBLOK,
      LBLSUPPORT,
    } = this.props.labels.labels;
    if (this.state.showGraphData === false) {
      // let f1columns = []
      // let f1filterFields = []
      // let f1resetfilterFields = []
      // let f2columns = []
      // let f2filterFields = []
      // let f2resetfilterFields = []

      // if (this.state.groupsLoaded === "YES") {
      //   f1columns = this.getLearnerProgressColumns();
      //   f1filterFields = this.getFilterColumns("LearnerProgress", f1columns);
      //   f1resetfilterFields = this.getResetFilterColumns("LearnerProgress", f1columns);
      //   f2columns = this.getRYS_D1_Columns();
      //   f2filterFields = this.getFilterColumns("rysAverage", f2columns);
      //   f2resetfilterFields = this.getResetFilterColumns("rysAverage", f2columns);
      // }
      if (this.state.get === true && this.state.visible === false) {
        return (
          <div>

            <div>
              {console.log("deactivationBannerTxt before modal", this.state.deactivationBannerText)}
              {this.state.showExpiryModal === false && (this.state.expiryBannerText || this.state.deactivationBannerText) ?
                <div className={this.state.expiryBannerText && this.state.Sitetype === "SafeStart" ? styles.siteExpirationCtn : this.state.expiryBannerText ? styles.siteExpirationaccuCtn : styles.siteDeactivationCtn}>
                  <div className={styles.siteExpiration}>
                    <img src={this.state.expiryBannerText && this.state.Sitetype === 'SafeStart' ? infoIcon : this.state.expiryBannerText ? infoaccu : Infored} style={{ height: "30px", width: "30px" }} alt="" />
                    <div className={styles.expirationtext}>
                      {/* <span>{this.state.expiryBannerText}</span><span>{this.state.expirySupportText}</span>||{this.state.deactivationBannerText} */}
                      {/* { this.state.expiryBannerText ? {`${this.state.expiryBannerText} ${this.state.expirySupportText}`}} : this.state.deactivationBannerText} */}
                      {this.state.expiryBannerText ? <><span className={styles.exsupporttxt}>{this.state.expiryBannerText}</span> <span className={styles.supporttxt}>{this.state.Sitetype === "SafeStart" ? this.state.expirySupportText : this.state.accuSupportText}</span></> : <><span className={styles.exsupporttxt}>{this.state.deactivationBannerText}</span> <span className={styles.supporttxt}>{this.state.Sitetype === "SafeStart" ? this.state.deactiveSupportText : this.state.accudeSupportText}</span></>}
                    </div>
                  </div>
                  <div className={styles.expityCancelbtnctn}>
                    <i class={`fas fa-times ${styles.expiryclosebtn} `} onClick={this.handleClick}></i>
                  </div>

                </div>
                : null}
            </div>


            <section className="home-section ">
              <div className={`row container ${styles.drpdwnRol}`}>
                <RoleChanger labels={this.props.labels.labels} />
              </div>
              {this.state.data &&
                this.state.data.active_users === 0 &&
                this.state.data.rate_your_state === 0 ? (
                <div>
                  {" "}
                  {
                    <EmptyState
                      image={emptyImage}
                      text2={this.props.labels.labels.LBLDASHBOARDEMPTYTXT}
                    />
                  }
                </div>
              ) : (
                <>
                  <div></div>
                  <div className={styles.usrmnctn}>
                    <ul
                      className={styles.usrcountctn}
                      style={{ width: RYSShow ? "80%" : "100%" }}
                    >
                      <li className={`${styles.usertxt1} ${styles.usertxt}`}>
                        <div
                          className={styles.userctntxt}
                          title={LBLTOTALLICENSES}
                        >
                          {LBLTOTALLICENSES}
                        </div>
                        <div
                          className={`${styles.usercount} ${styles.usercounts1}`}
                        >
                          {this.state.data.total_user_license}
                        </div>
                      </li>
                      <hr className={styles.verticleline}></hr>
                      <li className={`${styles.usertxt1} ${styles.usertxt}`}>
                        <div
                          className={styles.userctntxt}
                          title={LBLAVAILABLELICENSES}
                        >
                          {LBLAVAILABLELICENSES}
                        </div>
                        <div
                          className={`${styles.usercount} ${styles.usercounts2}`}
                        >
                          {this.state.data.total_user_license -
                            this.state.data.active_users}
                        </div>
                      </li>
                      <hr className={styles.verticleline}></hr>
                      <li className={`${styles.usertxt1} ${styles.usertxt}`}>
                        <div
                          className={styles.userctntxt}
                          title={LBLACTIVEUSERS}
                        >
                          {LBLACTIVEUSERS}
                        </div>
                        <div
                          className={`${styles.usercount} ${styles.usercounts3}`}
                        >
                          {this.state.data.active_users}
                        </div>
                      </li>
                      <hr className={styles.verticleline}></hr>
                      <li className={`${styles.usertxt1} ${styles.usertxt}`}>
                        <div
                          className={styles.userctntxt}
                          title={LBLINACTIVEUSERS}
                        >
                          {LBLINACTIVEUSERS}
                        </div>
                        <div
                          className={`${styles.usercount} ${styles.usercounts4}`}
                        >
                          {this.state.data.inactive_users}
                        </div>
                      </li>
                    </ul>
                    {RYSShow ? (
                      <ul className={styles.rysctn}>
                        <li className={styles.usertxt}>
                          <div
                            className={styles.userctntxt}
                            title={LBLTOTALRATEYOURSTATE}
                          >
                            {LBLTOTALRATEYOURSTATE}
                          </div>
                          <div
                            className={`${styles.usercount} ${styles.usercounts5}`}
                          >
                            {this.state.data.rate_your_state}
                          </div>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>

                  {this.state.loading === false ? (
                    ""
                  ) : (
                    <div className="row" style={{ marginTop: "-9px" }}>
                      <div className={optionClass}>
                        <div className="s-card">
                          <div
                            className={styles.chartCard}
                            style={{ marginTop: "-10px" }}
                          >
                            <h3>
                              {/* <i className="fas fa-filter" style={{ paddingRight: "10px" }} aria-hidden="true" onClick={() => { this.showFilter("F1") }}></i> */}
                              {LBLTRAININGCOMPLETION}
                            </h3>
                            <div
                              className="cls_back_status"
                              onClick={(x) => {
                                if (
                                  this.state.trainingGraphType === "CourseWise"
                                ) {
                                  //this.showRYSGraph("AverageRYS-State", { state: "rushing" })
                                } else {
                                  this.showTrainingGraph("CourseWise");
                                }
                              }}
                              title={this.state.rysGraphViewTitle}
                              style={
                                {
                                  //display: options_sf.data[0].dataPoints.length > 0 ? "" : "none"
                                }
                              }
                            >
                              {this.state.trainingGraphType === "CourseWise" ? (
                                <img
                                  className={styles.clsBar}
                                  src={
                                    "../images/steercom/" +
                                    this.state.trainingGraphImage
                                  }
                                  style={{ visibility: "hidden" }}
                                  alt=""
                                />
                              ) : (
                                <i
                                  class="fas fa-arrow-left"
                                  style={{
                                    cursor: "pointer",
                                    margin: "4px 4px 4px 4px",
                                  }}
                                  aria-hidden="true"
                                  title={this.getLabel("LBLBACK")}
                                ></i>
                              )}
                            </div>
                          </div>
                          {this.state.groupsLoaded === "YES" &&
                            this.state.trainingCompDataLoaded === true ? (
                            <>
                              <div className="d-flex">
                                <div
                                  className={selectClass}
                                  style={{ margin: "-20px -10px 10px 0px " }}
                                >
                                  <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.courses}
                                    noOptionsText={LBLNOOPTIONSDROPDOWN}
                                    getOptionLabel={(option) =>
                                      option.value.toString()
                                    }
                                    getOptionSelected={(option) =>
                                      option.id === this.state.tcdstate
                                    }
                                    disableClearable
                                    onChange={(event, values) => {
                                      let data = { type: "web" };
                                      data.offsetTime = this.getOffsetTime();
                                      data.product_id = values.id;
                                      this.getLP_DSBD_D1(data);
                                      // let i = values.id;
                                      this.setState({
                                        tcdstate: values.id,
                                        get: true,
                                      });
                                    }}
                                    defaultValue={this.state.courses.find(
                                      (v) => v.id == this.state.tcdstate
                                    )}
                                    renderOption={(option) => (
                                      <div className={styles.renderOptionDash}>
                                        {option.value}
                                      </div>
                                    )}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </div>
                              </div>
                              <div
                                style={{ marginTop: "0px", height: "260px" }}
                              >
                                {this.state.trainingCompData.datasets[0]
                                  .graphData.length > 0 ? (
                                  <>
                                    {this.state.trainingGraphType ===
                                      "CourseWise" ? (
                                      <DoughnutChart
                                        {...this.props}
                                        data={this.state.trainingCompData}
                                        legend={true}
                                        onElementClick={true}
                                        onDetailReport={this.showTrainingGraph}
                                        height="260"
                                      />
                                    ) : (
                                      <>
                                        <StackedColumn100Chart
                                          {...this.props}
                                          data={
                                            this.state.trainingCompDataUnitWise
                                          }
                                          legend={true}
                                          onElementClick={true}
                                          onDetailReport={
                                            this.onDetailReportLevel2
                                          }
                                        />
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <EmptyState
                                    image={emptyImage}
                                    text2={LBLDASHBOARDEMPTYTXT}
                                  />
                                )}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        {console.log(
                          "this.props.companyInfo.site_expire_date",
                          this.props.companyInfo.site_expire_date
                        )}

                        <Modal
                          show={this.state.showExpiryModal}
                          onHide={this.closeModal}
                        >
                          <Modal.Header style={{ borderBottom: "none", justifyContent: "center", padding: "0px" }}
                            className={`alert-modal-title ${styles.expirymodalheader}  ${this.state.showExpiryModalText ? styles.expirymodalexpiryheader : styles.expirymodaldeactiveheader}`}
                          >
                            {this.props.labels.labels.LBLRENEWLICENSE}
                          </Modal.Header>
                          {/* <Modal.Title style={{padding:"none"}} >
                          {this.props.labels.labels.LBLRENEWLICENSE}
                          </Modal.Title> */}

                          <Modal.Body>
                            {/* {this.state.expiryBannerText ? this.state.showExpiryModalText : this.state.deactivationBannerText} */}
                            {this.state.expiryBannerText ? <><span className={styles.exsupporttxt}>{this.state.expiryBannerText}</span> <span className={styles.supporttxt}>{this.state.Sitetype === "SafeStart" ? this.state.expirySupportText : this.state.accuSupportText}</span></> : <><span className={styles.exsupporttxt}>{this.state.deactivationBannerText}</span> <span className={styles.supporttxt}>{this.state.Sitetype === "SafeStart" ? this.state.deactiveSupportText : this.state.accudeSupportText}</span></>}
                          </Modal.Body>
                          <Modal.Footer className={styles.expiryfooterctn}  >
                            <button
                              type="button"
                              className="button-style primary-btn"
                              onClick={this.cancelModal}
                            >
                              {this.props.labels.labels.LBLOK}
                            </button>

                          </Modal.Footer >
                        </Modal>

                      </div>
                      {!this.state.RYS_D1_SummaryData && RYSShow ? (
                        <>
                          <Skeleton height={400} />
                        </>
                      ) : (
                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                          {RYSShow ? (
                            <div className="s-card">
                              <div
                                className={styles.chartCard}
                                style={{ marginTop: "-10px" }}
                              >
                                <h3>
                                  {/* <i className="fas fa-filter" style={{ paddingRight: "10px" }} aria-hidden="true" onClick={() => { this.showFilter("F2") }}></i> */}
                                  {this.state.rysmode
                                    ? this.getLabel("LBLAVERAGERYSBYSTATE") +
                                    " - " +
                                    this.getLabel(
                                      "LBL" +
                                      this.state.rysStates.toUpperCase()
                                    )
                                    : LBLAVERAGERYS}
                                </h3>
                                <div
                                  className="cls_back_status"
                                  onClick={(x) => {
                                    if (
                                      this.state.rysGraphType === "AverageRYS"
                                    ) {
                                      //this.showRYSGraph("AverageRYS-State", { state: "rushing" })
                                    } else {
                                      this.showRYSGraph("AverageRYS", {});
                                    }
                                  }}
                                  // title={this.state.rysGraphViewTitle}
                                  style={
                                    {
                                      //display: options_sf.data[0].dataPoints.length > 0 ? "" : "none"
                                    }
                                  }
                                >
                                  {this.state.rysGraphType === "AverageRYS" ? (
                                    <img
                                      className={styles.clsBar}
                                      src={
                                        "../images/steercom/" +
                                        this.state.rysGraphImage
                                      }
                                      style={{ visibility: "hidden" }}
                                      alt=""
                                    />
                                  ) : (
                                    <i
                                      class="fas fa-arrow-left"
                                      style={{
                                        cursor: "pointer",
                                        margin: "4px 4px 4px 4px",
                                      }}
                                      aria-hidden="true"
                                      title={this.getLabel("LBLBACK")}
                                    ></i>
                                  )}
                                </div>
                              </div>

                              {this.state.groupsLoaded === "YES" &&
                                this.state.RYSDataLoaded === true ? (
                                <>
                                  <div className="d-flex">
                                    <div
                                      className="col-xl-4 col-lg-4 col-md-5 col-sm-12 ml-auto"
                                      style={{
                                        margin: "-20px -10px 10px 0px ",
                                      }}
                                    >
                                      <Autocomplete
                                        id="combo-box-demo"
                                        options={this.getRYSDatesFilter()}
                                        noOptionsText={LBLNOOPTIONSDROPDOWN}
                                        getOptionLabel={(option) =>
                                          this.getLabel("LBL" + option.id)
                                        }
                                        getOptionSelected={(option) =>
                                          option.id === this.state.rys_date
                                        }
                                        disableClearable
                                        onChange={(event, values) => {
                                          let data = { type: "web" };
                                          data.offsetTime =
                                            this.getOffsetTime();
                                          //data.product_id = values.id
                                          data.rys_dates = this.getrysdates(
                                            values.id
                                          );
                                          if (this.state.zoomChanged === 1) {
                                            this.setState({
                                              pageState: "RYS_D1",
                                              RYS_D1_List: [],
                                              orgRYS_D1_List: [],
                                              //RYS_D1_SummaryData_Old: this.getRYS_D1_Summary_Old(result, data),
                                              RYS_D1_SummaryData:
                                                this.getRYS_D1_Summary([], {}),
                                              sel_D1_Data: {},
                                              RYSDataLoaded: true,
                                            });
                                          }
                                          this.getRYS_D1_List(data);
                                          this.setState({
                                            rys_date: values.id,
                                            get: true,
                                          });
                                        }}
                                        defaultValue={this.state.dates.find(
                                          (v) => v.id == this.state.rys_date
                                        )}
                                        renderOption={(option) => (
                                          <div
                                            className={styles.renderOptionDash}
                                          >
                                            {option.value}
                                          </div>
                                        )}
                                        renderInput={(params) => (
                                          <TextField {...params} />
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div style={{ marginTop: "0px" }}>
                                    {this.state.RYS_D1_SummaryData.datasets[0]
                                      .graphData.length > 0 ? (
                                      <>
                                        {this.state.rysGraphType ===
                                          "AverageRYS" ? (
                                          <RYSAveragesBar
                                            {...this.props}
                                            data={this.state.RYS_D1_SummaryData}
                                            date1={
                                              this.state.RYS_D1_SummaryData
                                                .date1
                                            }
                                            date2={
                                              this.state.RYS_D1_SummaryData
                                                .date2
                                            }
                                            count={LBLCOUNT}
                                            date={LBLDATE}
                                            onElementClick={true}
                                            onBarClick={this.showRYSGraph}
                                          />
                                        ) : (
                                          <RYSAveragesByState
                                            {...this.props}
                                            data={this.state.RYS_D1_SummaryData}
                                            date1={
                                              this.state.RYS_D1_SummaryData
                                                .date1
                                            }
                                            date2={
                                              this.state.RYS_D1_SummaryData
                                                .date2
                                            }
                                            dataPoint={this.state.rysLevel2Data}
                                            dataStates={this.state.rysStates}
                                            linktoreport={true}
                                            count={LBLCOUNT}
                                            date={LBLDATE}
                                            onElementClick={true}
                                            onBarClick={
                                              this.onRYSDetailReportLevel2
                                            }
                                            onZoomChange={
                                              this.onZooomChangeEvent
                                            }
                                            graphId={"DBRYSBYSTATE"}
                                            tcdstate={this.state.tcdstate}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {this.state.zoomChanged === 0 ? (
                                          <EmptyState
                                            image={emptyImage}
                                            text2={LBLDASHBOARDEMPTYTXT}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </section>
          </div>
        );
      } else {
        return (

          <section className="home-section">
            <div style={{ marginBottom: "15px" }}>
              <Skeleton height={35} />
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="s-card">
                  <div>
                    <Skeleton height={30} />
                  </div>
                  <ul className={styles.multiColumn}>
                    <li className="li-one">
                      <Skeleton height={40} />
                    </li>
                    <li className="li-one">
                      <Skeleton height={40} />
                    </li>
                    <li className="li-one">
                      <Skeleton height={40} />
                    </li>
                    {RYSShow && (
                      <li className="li-one">
                        <Skeleton height={40} />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={optionClass}>
                <div className="s-card">
                  <Skeleton height={30} />
                  <Skeleton height={35} />
                  <Skeleton height={400} />
                </div>
              </div>
              {RYSShow && (
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                  <Skeleton height={550} />
                </div>
              )}
            </div>
          </section>

        );
      }
    } else {
      return <>
        <graphData
          {...this.props}
        />
      </>
    }

  }


}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
    company_logo: state.user_info_reducer.user_info.company_logo,
    companyInfo: state.companyinfo_reducer.companyInfo.data
  };
};

export default connect(mapStateToProps, null)(Dashboard);

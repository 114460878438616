import axios from "axios";

//let req = require("request");

axios.interceptors.request.use(async (config, needagent) => {
  config.headers = {
    ...config.headers,
    "Access-Control-Allow-Origin": process.env.REACT_APP_Domain_URL
  };
  config.withCredentials = true;
  const urlRegex = /(https?:\/\/[^\s]+)/gi;
  const urls = config.url.match(urlRegex);
  if ((config.data && (config.data["requestMethod"] || config.data["requestLanguage"])) || (config["request-method"])) {
    config.headers = {
      ...config.headers,
      "request-method": config["request-method"],
      "request-service": config["request-service"],
      "request-modules": config["request-modules"],
      "request-language": config.data ? config.data["requestLanguage"] ? config.data["requestLanguage"] : "en-US" : "en-US"

    };

  }
  if (!urls) {
    config.url = process.env.REACT_APP_APIURL + config.url;
    return config;
  } else {
    return config;
  }
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (error.message === 'Network Error') {
      throw new Error('SSIAPI Network Error');
    }
    else if (error.response && error.response.status === 401) {
      localStorage.setItem("sc_user", false);
      localStorage.removeItem("sc_user");
      localStorage.clear();
      window.location.reload();
    } else {
      throw error;
    }
  }
);

export const HttpInterceptor = axios;
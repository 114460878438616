import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import DragAndDrop from "../../components/upload/DragAndDrop2.js";
import PostImage from "../../components/postimage2/postimage";
import * as CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import "./users.scss"
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import UsersService from "./UsersService";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import DataTable from "react-data-table-component";
import ScCommonData from "../../../services/common_data";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import {
  required,
  email,
  maxLength255,
  maxLength128,
  minValue8,
  checkSpace,
} from "../validate/Validate";
import Loader from "../../../admin/components/loader/Loader";
import styles from "./usersImport.module.scss"
var myTimeout;
const $ = require("jquery");
const cstLabels = {} 
let isNotified = false;
let warnMaxUser='';
let active_users,inactive_users, total_user_license, available_licenses;
class UsersImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: 0,
      showlastimportstatus: false,
      loading: true,
      importStep: 1,
      importSubStep: 1,
      attachedFiles: [],
      sendNotifyMail: false,
      forcePassword:true,
      importFieldUnmappedCount: 0,
      importFieldMappedCount: 0,
      importFieldFilter: "All",
      objUserFields: {},
      dataHeader: [],
      rowData: [],
      errormessage: "",
      newGroupsData: [],
      newGroupsDataCopy: [],
      searchText: "",
      newGroupsCreated: false,
      importedData: [],
      successRecords: 0,
      importprofileerror: false, 
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
    };
    this.fileAttachRef = React.createRef();
  }
  componentDidMount() { 
    let getData = {
      language: this.props.language
      , wildCard: "*", allGroups: "1"
    };
    let warnMessage = this.props.labels.labels.LBLMAXNUMBERREACHED; 
    warnMaxUser = warnMessage?warnMessage:"The number of users exceeds the number of licenses on the site. Please contact support for further assistance";
    let Languages = ScCommonData.getLanguages();
    UsersService.usersHelpers(getData).then((response) => {
      let objUserFields = {
        first_name: {
          name: this.getLabel("LBLFIRSTNAME"),
          mapfield: this.getLabel("LBLFIRSTNAME"),
          required: 1,
          xvalidate: "string",
          validate: [required, maxLength255],
          sampledata: "John",
          width: 150,
          errorsuffixlabel: "FIRSTNAME"
        },
        last_name: {
          name: this.getLabel("LBLLASTNAME"),
          mapfield: this.getLabel("LBLLASTNAME"),
          required: 1,
          xvalidate: "string",
          validate: [required, maxLength255],
          sampledata: "Ryder",
          width: 150,
          errorsuffixlabel: "LASTNAME"
        },
        user_name: {
          name: this.getLabel("LBLUSERNAME"),
          mapfield: this.getLabel("LBLUSERNAME"),
          required: 1,
          xvalidate: "string",
          validate: [maxLength255, checkSpace],
          sampledata: "JohnRyder",
          width: 100,
          errorsuffixlabel: "USERNAME"
        },
        password: {
          name: this.getLabel("LBLPASSWORD"),
          mapfield: this.getLabel("LBLPASSWORD"),
          required: 1,
          xvalidate: "string",
          validate: [minValue8, checkSpace],
          sampledata: "JohnRyder",
          width: 100,
          errorsuffixlabel: "PASSWORD"
        },
        email: {
          name: this.getLabel("LBLPRIMARYEMAIL"),
          mapfield: this.getLabel("LBLPRIMARYEMAIL"),
          required: 1,
          xvalidate: "email",
          validate: [email, checkSpace, maxLength128],
          sampledata: "samplemail@xxx.com",
          width: 200,
          errorsuffixlabel: "EMAIL"
        },
        role: {
          name: this.getLabel("LBLROLE"),
          mapfield: this.getLabel("LBLROLE"),
          required: 1,
          xvalidate: "string",
          validate: [],
          sampledata: this.getLabel("LBLUSER"),
          width: 100,
          errorsuffixlabel: "ROLE"
        },
      }; 
      //console.log("!!@@###!!!!!objUserFields", JSON.stringify(objUserFields)) 
      let groups = response.groups;
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        objUserFields[groups[i].group_type_id] = {
          name: this.getLabel(
            groups[i].group_info["lbl_" + groups[i].group_type_id]
          ),
          mapfield: this.getLabel(
            groups[i].group_info["lbl_" + groups[i].group_type_id]
          ),
          validate: [maxLength128],
          width: 150
        };
      } 
      //console.log("!!@@###@@@@@objUserFields", JSON.stringify(objUserFields)) 
      this.setState({
        roles: response.roles,
        groups_by_type: response.groups,
        helpersLoaded: true,
        objUserFields: objUserFields,
        languages: Languages
      });
    });
    this.getImportProcessStatus(-1)
    $('.MuiAutocomplete-input').attr('readOnly', 'true');
    this.getUserCount();
  }
  getUserCount = async() =>{ 
    const response = await HttpInterceptor.get('userscount'); 
    const result = response.data.result[0];
    let active_users = result.active_users - result.consultant_active;
    let inactive_users = result.inactive_users - result.consultant_inactive;
    if(response){
      //  this.setState({ 
          active_users = active_users;
          inactive_users = inactive_users;
          total_user_license = result.total_user_license;
          available_licenses = total_user_license - active_users;
      //  });
      }
}
  StopTimeOutFunction = () => {
    this.setState({
      importStep: 1,
    });
    clearTimeout(myTimeout);
  }
  openBrowseWindow = () => {
    this.setState({
      showEmoji: 0,
    });
    this.fileAttachRef.current.click();
  };
  setProError = (x) => {
    this.setState({
      importprofileerror: true,
      importprofileerrormsg: x,
      importfileerror: true
    });
  }
  clearProError = (x) => {
    this.setState({
      importprofileerror: false,
      importfileerror: false
    })
  }

  fileAttachOnChangeHandler = (evt) => {
    if (evt && evt.target && evt.target.files && evt.target.files.length) {
      let fnm = evt.target.files[0].name;
      let extnsion = fnm.substr(fnm.lastIndexOf("."));
      let fsize = Math.round(evt.target.files[0].size / 1024)
      this.state.attachedFiles.splice(0, 1, evt.target.files[0]);
      let cbError = this.setProError
      let cbFunction1 = this.getAttachedFile
      let cbCheckHeaderData = this.checkReadFileData
      if ((extnsion === ".csv" || extnsion === ".xlsx") && fsize < 1024) {
        if (extnsion === ".xlsx") {

          var reader = new FileReader();
          if (reader.readAsBinaryString) {
            reader.onload = function (e) {
              try {
                for (let i = 0; i < evt.target.files.length; i++) {
                  cbFunction1(evt.target.files[i]);
                }
                cbCheckHeaderData()
              } catch (e) {
                cbError("LBLPASSWORDPROTECTIONERROR")
              }
            };
            reader.readAsBinaryString(evt.target.files[0]);
          }
        } else {
          for (let i = 0; i < evt.target.files.length; i++) {
            this.getAttachedFile(evt.target.files[i]);
          }
          cbCheckHeaderData()
        }
      } else {
        this.setState({
          importprofileerror: false,
          importfileerror: true,
          errormessage: this.getLabel("LBLIMPORTFILETYPESMESSAGE1"),
        });
      }
      this.checkFileAttacheBtn();
    }
  };
  getAttachedFile = (fileObj) => {
    this.setState({
      attachedFiles: this.state.attachedFiles,
      errormessage: "",
    });
  };
  checkFileAttacheBtn = () => {
    let flag = this.state.attachedFiles.length >= this.maxNoOfAssetPOst ? 0 : 1;
    this.setState({
      fileAttacheBtnEnable: flag,
      importSubStep: 2
    });
  };
  handleClose = (event) => {
    let status = event && event.target.id === "btn_ok" ? true : false;
    if (status) {  
      isNotified = true;
      this.setState({
        open: false,
        isNotified:true
      });
      this.moveNextStep(this);
    } else {
      this.setState({
        open: status
      });
    }
  }
  
  changeNotifyMail = () => {
    let isNotified = !this.state.sendNotifyMail;
    this.setState({
      sendNotifyMail: !this.state.sendNotifyMail,
      isNotified:isNotified,
    });
  };
  changeForcePassword = () => { 
      this.setState({
        forcePassword: !this.state.forcePassword,
      });  
  };
  showImportStage1 = () => {
    this.setState({
      importStep: 1,
    });
  };
  showImportStage2 = (data, type) => {
    var xfields = this.state.objUserFields;
    for (let x in xfields) {
      xfields[x].mapindex = data[0].join(",").toLowerCase().split(",").indexOf(xfields[x].mapfield.toLowerCase());
      xfields[x].mapfield = xfields[x].mapindex === -1 ? xfields[x].mapfield : data[0][xfields[x].mapindex]
      if (data[1]) {
        xfields[x].mapdata = data[1][xfields[x].mapindex] || "";
      }
      delete xfields[x].errormessage;
    }
    if (data.length === 1) {
      this.setState({
        errormessage: this.getLabel("LBLEMPTYFILE"),
      })

    } else {
      this.setState({
        errormessage1: "",
        objUserFields: xfields,
        rawUserData: data,
        rowData: data[1],
        dataHeader: data[0],
        lcaseDataHeader: data[0].join(",").toLowerCase().split(","),
        importStep: 2,
        importSubStep: 1,
        filetype: type
      });
      this.validateEntityMap()
      window.scrollTo(0, 0)
    }

  };
  showImportSummaryData = () => {
    this.setState({
      showImportSummaryData: true
    })
  }
  readNewGroups = () => {
    let newGroupsByType = {}
    let newGroupsByTypeLC = {}
    let newGroupsByTypeLCIA = {}
    let xrdata = this.state.rawUserData;
    let xfields = this.state.objUserFields;
    for (let i = 1; i < xrdata.length; i++) {
      let xrow = {};
      for (let x in xfields) {
        if (xfields[x].mapindex !== -1) {
          xrow[x] = xrdata[i][xfields[x].mapindex];
        }
      }
      for (let x in xfields) {
        if (x.indexOf("GP_") === 0) {
          let post_groups = [];
          let post_groups_ia = []
          let groupname = String((xrow[x] || "")); //"Chennai"
          let grouptype = x;
          this.state.groups_by_type.map((item) => {
            if (grouptype === item.group_type_id) {
              if (item.groups_by_type) {
                let groups = JSON.parse(item.groups_by_type);
                groups.map((xitem) => {
                  //console.log("GROUPS = ", grouptype, xitem.group_name.toLowerCase(), groupname.toLowerCase())
                  if (xitem.group_name.toLowerCase() === groupname.toLowerCase()) {
                    let xgrpobj = {
                      group_id: xitem.group_id.toString(),
                      group_type_id: x,
                    };
                    if (!xrow["groups"]) {
                      xrow["groups"] = [xgrpobj];
                    } else {
                      xrow["groups"].push(xgrpobj);
                    }
                    post_groups.push({
                      group_id: xitem.group_id.toString(),
                      group_type_id: x,
                      active: xitem.active
                    });
                    if (xitem.active === 0) {
                      post_groups_ia.push({
                        group_id: xitem.group_id.toString(),
                        group_type_id: x
                      });
                    }
                  }
                  return null;
                });
              }
            }
            return null;
          });
          if (groupname !== "" && post_groups.length === 0) {
            if (!newGroupsByTypeLC[x]) {
              newGroupsByTypeLC[x] = [groupname.toLowerCase()]
              newGroupsByType[x] = [groupname]
            } else if (newGroupsByTypeLC[x].indexOf(groupname.toLowerCase()) === -1) {
              newGroupsByTypeLC[x].push(groupname.toLowerCase())
              newGroupsByType[x].push(groupname)
            }
          } else if (groupname !== "" && post_groups_ia.length > 0) {
            if (!newGroupsByTypeLCIA[x]) {
              newGroupsByTypeLCIA[x] = [groupname.toLowerCase()]
              newGroupsByType[x] = [groupname]
            } else if (newGroupsByTypeLCIA[x].indexOf(groupname.toLowerCase()) === -1) {
              newGroupsByTypeLCIA[x].push(groupname.toLowerCase())
              newGroupsByType[x].push(groupname)
            }
          }
        }
      }
    }
    let newGroupsData = []
    let groupValidation = true
    let groupValidationIA = true
    for (let gt in newGroupsByType) {
      for (let i = 0; i < newGroupsByType[gt].length; i++) {
        let xnewGrp = { group_type_id: gt, group_type_name: xfields[gt].name, group_name: newGroupsByType[gt][i], "error_message": "", active: 1 }

        if (newGroupsByTypeLCIA[gt] && newGroupsByTypeLCIA[gt].indexOf(newGroupsByType[gt][i].toLowerCase()) > -1) {
          xnewGrp.active = 0
        }
        newGroupsData.push(xnewGrp)
        for (let s = 0; s < xfields[gt].validate.length; s++) {
          if (xfields[gt].validate[s](xnewGrp["group_name"]) !== undefined) {
            groupValidation = false
            xnewGrp["error_message"] = this.getLabel(xfields[gt].validate[s](xnewGrp["group_name"]))
          } else if (xnewGrp.active === 0) {
            groupValidationIA = false
            groupValidation = false
            xnewGrp["error_message"] = this.getLabel("LBLINACTIVE")
          }
        }
      }
    }
    this.setState({
      newGroupsData: newGroupsData,
      groupValidation: groupValidation,
      groupValidationIA: groupValidationIA
    })
    if (this.state.newGroupsCreated === false) {
      this.setState({
        newGroupsDataCopy: JSON.parse(JSON.stringify(newGroupsData)),
      })
    }
    //console.log("newGroupsData", newGroupsData);
  }
  showToast = (x) => {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: x,
        changeInToast: new Date(),
      },
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 1500);
  }
  addGroups = () => {
    let data = { groups: this.state.newGroupsData };
    HttpInterceptor.post("groups/import", data).then(
      (res) => {
        // console.log("addgroups result", res.data);
        let getData = {
          language: this.props.language,
          wildCard: "*"
        };
        UsersService.usersHelpers(getData).then((response) => {
          this.setState({
            groups_by_type: response.groups,
            newGroupsCreated: true
          });
          this.readNewGroups()
          this.showToast(this.getLabel("LBLADDGROUPSSUCCESS"));
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  moveNextStep = () => {
    if (this.state.importStep === 1) {
      if (this.state.importSubStep === 1) {
        this.setState({
          importSubStep: 2
        })
      } else if (this.state.importSubStep === 2) {
        this.readFileData()
      }
    } else if (this.state.importStep === 2) {
      this.readNewGroups()
      this.setState({
        importStep: 3,
        importSubStep: 1
      })
      window.scrollTo(0, 0)
    } else if (this.state.importStep === 3) {
      if (this.state.importSubStep === 1) {
        this.readImportUsersData()
        this.setState({
          importStep: 4,
          importSubStep: 1
        })
      }
      window.scrollTo(0, 0)
    } else if (this.state.importStep === 4) {
      if (this.state.importSubStep === 1) { 
        if(!isNotified && !this.state.sendNotifyMail){
          this.setState({
            open:true
          })
        }else{
        isNotified=false;
        this.setState({
          importStep: 5,
          importSubStep: 1,
          completed: 0,
          totalRecords: this.state.importUsersData.length,
          processedRecords: 0,
          failedRecords: 0,
          successRecords: 0,
          pendingRecords: this.state.importUsersData.length,
          importedFilename: this.state.attachedFiles[0].name
        })
        window.scrollTo(0, 0)
        setTimeout(this.submitData(), 500)
      }
    }
    }
  }
  moveStartOverStep = () => {
    let xfields = this.state.objUserFields;
    let all_vals = Object.values(xfields);
    all_vals.map((item)=>{
      return item.mapdata="";
    })
    this.setState({
      importStep: 1,
      importSubStep: 2,
      attachedFiles: [],
      newGroupsCreated:false,
      importSubStep: 1,
      importprofileerror: false,
      importfileerror: false
    })
    window.scrollTo(0, 0)
  }
  movePrevStep = () => {
    if (this.state.importStep === 1) {
      if (this.state.importSubStep === 2) {
        this.removeImportFile()
      }
      window.scrollTo(0, 0)
    } else if (this.state.importStep === 2) {
      this.setState({
        importStep: 1,
        importSubStep: 2
      })
      window.scrollTo(0, 0)
    } else if (this.state.importStep === 3) {
      if (this.state.importSubStep === 1) {
        this.validateEntityMap()
        this.setState({
          importStep: 2,
          importSubStep: 2
        })
      }
      window.scrollTo(0, 0)
    } else if (this.state.importStep === 4) {
      if (this.state.importSubStep === 1) {
        this.setState({
          importStep: 3,
          importSubStep: 1
        })
      }
      window.scrollTo(0, 0)
    } else if (this.state.importStep === 5) {
      if (this.state.importSubStep === 1) {
        this.setState({
          importStep: 4,
          importSubStep: 1
        })
      }
      window.scrollTo(0, 0)
    }
  }
  readFileData = () => {
    if (!this.state.attachedFiles[0].name) {
      this.setState({
        errormessage: this.getLabel("LBLREQUIRED"),
      });
      return;
    }
    let type = this.state.attachedFiles[0].name.split(".")[1]
    let callback = this.showImportStage2;
    if (type === "xlsx") {
      var reader = new FileReader();

      if (reader.readAsBinaryString) {
        reader.onload = function (e) {
          try {
            const bookData = XLSX.read(e.target.result, { "type": "binary" })
            if (bookData.SheetNames.length <= 0) {
              throw new Error(`${e.target.result}ERRRO SHEETS`)
            }
            const sheetRaw = bookData.Sheets[bookData.SheetNames[0]]
            const xdata = XLSX.utils.sheet_to_json(sheetRaw);
            var data = []
            var headers = []
            var cellindexx = 0
            var celcolumn = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"]
            var address_of_cell = celcolumn[cellindexx] + "1";
            /* Find desired cell */
            var desired_cell = sheetRaw[address_of_cell];
            while (desired_cell) {
              headers.push(desired_cell.v)
              cellindexx++
              desired_cell = sheetRaw[celcolumn[cellindexx] + "1"];
            }
            data.push(headers)
            for (let i = 0; i < xdata.length; i++) {
              let curr_val = '', fields = []
              for (let j = 0; j < headers.length; j++) {
                //console.log("xdata[i][headers[j]]", headers[j], typeof xdata[i][headers[j]]); 
                curr_val='';
                if (typeof xdata[i][headers[j]] == "string") { 
                  fields.push(xdata[i][headers[j]].trim()); 
                } else { 
                  curr_val = xdata[i][headers[j]]?xdata[i][headers[j]].toString().trim() :""; 
                  fields.push(curr_val); 
                }
              }
              data.push(fields)
            }
            callback(data, "xlsx");
          } catch (e) {

          }

        };
        reader.readAsBinaryString(this.state.attachedFiles[0]);
      }
    } else if (type === "csv") {
      try {
        var readerTwo = new FileReader();
        readerTwo.onload = function (e) {
          // Use reader.result
          var lines = readerTwo.result.split(/[\r\n]+/g);
          var curr_val = '', data = [];
          lines.forEach(function (line) {
            if (line.split(",").length > 1) {
              let xline = line.split(",")
              for (let i = 0; i < xline.length; i++) {
                curr_val = xline[i] ? xline[i].trim() : "";
                xline[i] = curr_val
              }
              data.push(xline);
            }
          });
          //console.log("data", data);
          callback(data, "csv");
        };
        readerTwo.readAsText(this.state.attachedFiles[0]);
      } catch (e) { }
    }
  };
  checkReadFileData = () => {
    if (!this.state.attachedFiles[0].name) {
      this.setState({
        errormessage: this.getLabel("LBLREQUIRED"),
      });
      return;
    }
    let type = this.state.attachedFiles[0].name.split(".")[1]
    let cbError = this.setProError
    let cbClearError = this.clearProError
    if (type === "xlsx") {
      var reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = function (e) {
          //GetTableFromExcel(e.target.result);
          try {
            const bookData = XLSX.read(e.target.result, { "type": "binary" })
            if (bookData.SheetNames.length <= 0) {
              throw new Error(`${e.target.result}ERRRO SHEETS`)
            }
            const sheetRaw = bookData.Sheets[bookData.SheetNames[0]]
            const xdata = XLSX.utils.sheet_to_json(sheetRaw); 
            //console.log("xdata", xdata)
            //console.log("@@@@@@@@@xdata[0]", xdata[0]) 
            var data = []
            var headers = []
            var headersc = []
            //console.log()
            var cellindexx = 0
            var celcolumn = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"]
            var address_of_cell = celcolumn[cellindexx] + "1";

            /* Find desired cell */
            //console.log("sheetRaw", sheetRaw)
            //console.log("address_of_cell", address_of_cell)
            var desired_cell = sheetRaw[address_of_cell];
            //console.log("desired_cell", desired_cell)
            for (let i = 0; i < 14; i++) {
              if (desired_cell) {
                headers.push(desired_cell.v)
              }
              cellindexx++
              desired_cell = sheetRaw[celcolumn[cellindexx] + "1"];
            }
            cellindexx = 0
            desired_cell = sheetRaw[celcolumn[cellindexx] + "1"];
            while (desired_cell) {
              headersc.push(desired_cell.v)
              cellindexx++
              desired_cell = sheetRaw[celcolumn[cellindexx] + "1"];
            }


            if (headers.length === 0 || headersc.length !== headers.length) {

              cbError("LBLINVALIDHEADERERROR")
              return
            } else {
              for (let i = 0; i < headers.length; i++) {
                if (headers[i] === undefined || headers[i] === null || headers[i] === "") {
                  cbError("LBLINVALIDHEADERERROR")
                  return
                }
              }
            }
            data.push(headers)
            //console.log("headers", headers)
            for (let i = 0; i < xdata.length; i++) {
              let fields = []
              for (let j = 0; j < headers.length; j++) {
                //console.log("xdata[i][headers[j]]", headers[j], xdata[i][headers[j]])
                if (typeof xdata[i][headers[j]] == "string") {
                  fields.push(xdata[i][headers[j]].trim())
                } else {
                  fields.push(xdata[i][headers[j]])
                }
              }
              data.push(fields)
            }
            ///
              let allow_user_count = 0, import_user_count = xdata.length;   
              if(available_licenses < import_user_count){  
                //let warnError = "The maximum number of licenses has been reached. Please contact support for further assistance"
                cbError(warnMaxUser)
                return; 
              }   
              console.log("xdata", xdata.length);
              
            ////
          
            cbClearError();
          } catch (e) {
          }

        };
        reader.readAsBinaryString(this.state.attachedFiles[0]);
      }
    } else if (type === "csv") {
      try {
        var readerTwo = new FileReader();
        readerTwo.onload = function (e) {
          // Use reader.result
          var lines = readerTwo.result.split(/[\r\n]+/g);
          var data = [];
          lines.forEach(function (line) {
            if (line.split(",").length > 1) {
              let xline = line.split(",")
              for (let i = 0; i < xline.length; i++) {
                xline[i] = xline[i].trim()
              }
              data.push(xline);
            }
          });
          let headers = data[0]
          if (headers.length === 0) {
            cbError("LBLINVALIDHEADERERROR")
            return
          } else {
            for (let i = 0; i < headers.length; i++) {
              if (headers[i] === undefined || headers[i] === null || headers[i] === "") {
                cbError("LBLINVALIDHEADERERROR")
                return
              }
            }
          }
          //console.log("data", data);
          cbClearError();
        };
        readerTwo.readAsText(this.state.attachedFiles[0]);
      } catch (e) { }
    }


  };
  showImportStage3 = () => {
    this.setState({
      importStep: 3,
      importSubStep: 1
    });
  };
  changeImportFieldFilter = (name) => {
    this.setState({
      importFieldFilter: name,
    });
  };
  renderMapFields = (item) => {
    return (
      <div>
        <div className="">{item}</div>
        <div className=""></div>
        <div className=""></div>
      </div>
    );
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        if (cstLabels.hasOwnProperty(xfield.label)) {
          return cstLabels[xfield.label].replace(
            "$$$",
            xfield.val
          );
        } else {
          return xfield.label;
        }
      }
    }
  };
  removeImportFile = (index) => {
    this.setState(
      {
        attachedFiles: [],
        importSubStep: 1,
        importprofileerror: false,
        importfileerror: false
      }
    );
  };
  handleDrop = (files) => {
    // console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF", files);
    this.setState({ file: files[0] });
    this.state.attachedFiles.splice(0, 1, files[0]);
    if (files[0].type.match("image.*")) {
      this.getAttachedImages(files[0]);
    }
    let fnm = files[0].name;
    let fsize = Math.round(files[0].size / 1024)
    let extnsion = fnm.substr(fnm.lastIndexOf("."));
    if ((extnsion === ".csv" || extnsion === ".xlsx") && fsize < 1024) {
      let cbError = this.setProError
      let cbFunction1 = this.getAttachedFile
      let cbCheckHeaderData = this.checkReadFileData
      if (extnsion === ".xlsx") {

        var reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = function (e) {
            try {
              for (let i = 0; i < files.length; i++) {
                cbFunction1(files[i]);
              }
              cbCheckHeaderData()
            } catch (e) {
              cbError("LBLPASSWORDPROTECTIONERROR")
            }
          };
          reader.readAsBinaryString(files[0]);
        }
      } else {
        for (let i = 0; i < files.length; i++) {
          this.state.attachedFiles.splice(0, 1, files[i]);
          this.getAttachedFile(files[i]);
        }
        cbCheckHeaderData()

      }
    } else {
      this.setState({
        importfileerror: true,
        importprofileerror: false,
        errormessage: this.getLabel("LBLIMPORTFILETYPESMESSAGE1"),
      });
    }
    this.checkFileAttacheBtn();
  };

  onOperatorChange = (xname, value) => {
    var xfields = this.state.objUserFields;

    xfields[xname].mapfield = value;
    xfields[xname].mapindex = this.state.lcaseDataHeader.indexOf(
      value.toLowerCase()
    );
    xfields[xname].mapdata = this.state.dataHeader.indexOf(value);
    delete xfields[xname].errormessage;

    for (let x in xfields) {
      xfields[x].mapindex = this.state.lcaseDataHeader.indexOf(
        xfields[x].mapfield.toLowerCase()
      );
      xfields[x].mapdata = this.state.rowData[xfields[x].mapindex];
    }
    this.setState({
      objUserFields: xfields,
    });
    this.validateEntityMap()
  };

  searchGroup = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  render() {
    let renderHeader = (x) => {
      return (
      <div>
        <div className="page-header-field ">
          <h3 className="page-title">{labels.LBLIMPORTUSER}</h3> 
          
          <div style={{display:"flex"}}> 
          {this.state.importStep === 1 ? <>
          <div className={`btn-align ${styles.avlLicenseContainer}`}>
          <div className={`button-style ${styles.avlLicense}`}> 
          
           <span>{labels.LBLAVAILABLELICENSES} : </span>  
          <span className={styles.avlLicenseCnt}> {available_licenses}</span>
           
          </div>
          </div></>:""}
          <div className="btn-align">
            {this.state.showlastimportstatus === true && this.state.importStep === 1 ? <>
              <button className="button-style primary-btn"
                onClick={this.getLastImportStatus}
              >{labels.LBLLASTIMPORTSTATUS}</button>
            </> : this.state.importStep >1 ? <>
              <div className="btn-align">
                <Link variant="primary" to={"/schome/userlist"}>
                  <i
                    className={`fas fa-times ml-2 ${styles.closeIcon}`}
                    style={{
                    }}
                    onClick={this.StopTimeOutFunction}
                  ></i>
                </Link>
              </div>
            </>:""
            }

          </div>
          </div>
        </div>
        <hr className="head-line" />
        <div className={`row col-sm-12 ${styles.stepbarCtn}`}>
          <div className={styles.stepbarPar} >
            <div className={this.state.importStep === 1 ? `${styles.stepbar} ${styles.importStepInprogress}` :  `${styles.stepbar} ${styles.importStepCompleted}`} ></div>
            <div className={styles.stepbarInnerDiv} >
              <span style={{ display: "block", height: "26px" }}>{this.state.importStep > 1 ? <i class="fa fa-check"></i> : 1}</span>
              <div className={this.state.importStep === 1 ? `${styles.stepcircle} ${styles.importStepInprogress}` : `${styles.stepcircle} ${styles.importStepCompleted}` }>
                <div className={styles.selectFile}></div>
              </div>
              <span className={styles.stepCircleTitle}>{this.getLabel("LBLSELECTFILE")}</span>
            </div>
          </div>
          <div className={styles.stepbarPar} >
            <div className={this.state.importStep === 2 ? `${styles.stepbarChild} ${styles.importStepInprogress}` : this.state.importStep < 2 ? `${styles.stepbarChild} ${styles.importStepNotStarted}`: `${styles.stepbarChild} ${styles.importStepCompleted}` } ></div>
            <div className={styles.stepbarInnerDivChild} >
              <span style={{ display: "block", height: "26px" }}>{this.state.importStep > 2 ? <i class="fa fa-check"></i> : 2}</span>

              <div className={this.state.importStep === 2 ? `${styles.stepcircle} ${styles.importStepInprogress}` : this.state.importStep < 2 ? `${styles.stepcircle} ${styles.importStepNotStarted}`: `${styles.stepcircle} ${styles.importStepCompleted}` } >
                <div className={styles.entityMapping}></div>
              </div>
              <span className={styles.stepCircleTitle}>{this.getLabel("LBLENTITYMAPPING")}</span>
            </div>
          </div>

          <div className={styles.stepbarPar} >
          <div className={this.state.importStep === 3 ? `${styles.stepbarChild} ${styles.importStepInprogress}` : this.state.importStep < 3 ? `${styles.stepbarChild} ${styles.importStepNotStarted}`: `${styles.stepbarChild} ${styles.importStepCompleted}` } ></div>
            <div className={styles.stepbarInnerDivChild} >
              <span style={{ display: "block", height: "26px" }}>{this.state.importStep > 3 ? <i class="fa fa-check"></i> : 3}</span>

              <div className={this.state.importStep === 3 ? `${styles.stepcircle} ${styles.importStepInprogress}` : this.state.importStep < 3 ? `${styles.stepcircle} ${styles.importStepNotStarted}`: `${styles.stepcircle} ${styles.importStepCompleted}` } >
                <div className={styles.createNewGroup}></div>
              </div>
              <span className={styles.stepCircleTitle}>{this.getLabel("LBLADDNEWGROUPS")}</span>
            </div>
          </div>

          <div className={styles.stepbarPar} >
          <div className={this.state.importStep === 4 ? `${styles.stepbarChild} ${styles.importStepInprogress}` : this.state.importStep < 4 ? `${styles.stepbarChild} ${styles.importStepNotStarted}`: `${styles.stepbarChild} ${styles.importStepCompleted}` } ></div>
            <div className={styles.stepbarInnerDivChild} >
              <span style={{ display: "block", height: "26px" }}>{this.state.importStep > 4 ? <i class="fa fa-check"></i> : 4}</span>

              <div className={this.state.importStep === 4 ? `${styles.stepcircle} ${styles.importStepInprogress}` : this.state.importStep < 4 ? `${styles.stepcircle} ${styles.importStepNotStarted}`: `${styles.stepcircle} ${styles.importStepCompleted}` } >
                <div className={styles.dataValidation}></div>
              </div>
              <span className={styles.stepCircleTitle}>{this.getLabel("LBLDATAVALIDATION")}</span>
            </div>
          </div>

          <div className={styles.stepbarPar} >
          <div className={this.state.importStep === 5 && this.state.pendingRecords > 0 ? `${styles.stepbarChild} ${styles.importStepInprogress}` : this.state.importStep < 5 ? `${styles.stepbarChild} ${styles.importStepNotStarted}`: `${styles.stepbarChild} ${styles.importStepCompleted}` } style={{left:"0px",width:"50%"}}></div>
            <div className={styles.stepbarInnerDivChild} style={{ border: "0px solid red", position: "relative", top: "-4px", textAlign: "center" }}>
              <span style={{ display: "block", height: "26px" }}>{this.state.importStep === 5 && this.state.pendingRecords === 0 ? <i class="fa fa-check"></i> : 5}</span>

              <div className={this.state.importStep === 5 && this.state.pendingRecords > 0 ? `${styles.stepcircle} ${styles.importStepInprogress}` : this.state.importStep < 5 ? `${styles.stepcircle} ${styles.importStepNotStarted}`: `${styles.stepcircle} ${styles.importStepCompleted}` } >
                <div className={styles.summary}></div>
              </div>
              <span className={styles.stepCircleTitle}>{this.getLabel("LBLSUMMARY")}</span>
            </div>
          </div>


        </div>

      </div>
      );
    };
    let noGroupsComponent = () => {
      return (
        <EmptyState image={emptyImage} text2={this.getLabel("LBLNONEWGROUPS")} />
      );
    };
    let renderOperatorSelector = (xname) => {
      return (
        <div>

          <Autocomplete
            id="combo-box-demo"
            aria-required="true"
            required
            disableClearable
            noOptionsText={this.getLabel("LBLNOOPTIONSDROPDOWN")}
            options={this.state.dataHeader}
            getOptionLabel={(option) => option}
            value={this.state.objUserFields[xname].mapfield === "" || this.state.objUserFields[xname].mapindex === -1 ? this.getLabel("LBLSELECT") : this.state.objUserFields[xname].mapfield}
            onChange={(e, i) => this.onOperatorChange(xname, i)}
            renderOption={(option) => (
              <div style={{ fontSize: "14px" }}>{option}</div>
            )}
            renderInput={(params) => (
              <div>
                <TextField
                  {...params}

                />
              </div>
            )}
          />

        </div>
      );
    };
    let LBLGROUPTYPE = this.getLabel("LBLGROUPTYPE")
    let LBLGROUPNAME = this.getLabel("LBLGROUPNAME")
    let groupCoumns = [
      {
        name: this.getLabel("LBLGROUPTYPE"),
        selector: "group_type_name",
        sortable: true,
        compact: true,
        width: "20%",
        cell: (row) => (
          <div>
            <label className="show-sm">{LBLGROUPTYPE}</label>
            <span title={row.group_type_name}>{row.group_type_name}</span>
          </div>
        ),
      },
      {
        name: this.getLabel("LBLGROUPNAME"),
        selector: "group_name",
        sortable: true,
        width: "40%",
        compact: true,
        cell: (row) => (
          <div className={row.error_message === "" ? "" : styles.notMapped} >
            <label className="show-sm">{LBLGROUPNAME}</label>
            <span title={row.group_name}>{row.group_name}</span>
          </div>
        ),
      }
    ]
    groupCoumns.push({
      name: this.getLabel("LBLVALIDATION"),
      selector: "error_message",
      sortable: true,
      compact: true,
      width: "40%",
      cell: (row) => (
        <div className= {row.error_message === "" ? "" :  `hde-user-icn ${styles.notMapped}`} >
          <label className="show-sm">{this.getLabel("LBLVALIDATION")}</label>
          <span title={row["error_message"] === "" ? this.getLabel("LBLVALID") : row["error_message"]}>{row["error_message"] === "" ? this.getLabel("LBLVALID") : row["error_message"]}</span>
        </div>
      )
    })
    let setErrorstyle = (x, xrow) => {
      //console.log("setErrorstyle", x, xrow)
      if ((x === "error_message" && xrow["error_message"] && xrow["error_message"] !== "") || (xrow["error_message"] && xrow["error_message"] !== "" && xrow["error_data"][x])) {
        return { color: "red" }
      } else {
        return {}
      }
    }
    let userColumns = []
    userColumns.push({
      name: this.getLabel("LBLVALIDATION"),
      selector: "error_message",
      sortable: true,
      compact: true,
      width: "200px",
      cell: (row) => (
        <div className= {row.error_message === "" ? "" :  `hde-user-icn ${styles.notMapped}`} >
          <label className="show-sm">{this.getLabel("LBLVALIDATION")}</label>
          <span style={{ lineHeight: "12px" }} title={row["error_message"] === "" ? this.getLabel("LBLVALID") : row["error_message"]}>{row["error_message"] === "" ? this.getLabel("LBLVALID") : row["error_message"]}</span>
        </div>
      )
    })
    let userFields = this.state.objUserFields
    let userTablewidth = 0
    for (let x in userFields) {
      userTablewidth = userTablewidth + userFields[x].width
      let tObj = {
        name: userFields[x].name,
        selector: x,
        sortable: true,
        compact: true,
        width: userFields[x].width + "px",
        cell: (row) => (
          <div className="grid-ellipsis" style={setErrorstyle(x, row)}>
            <label className="show-sm">{userFields[x].name}</label>
            <span title={row[x]}>{row[x]}</span>
          </div>
        ),
      }
      if (x === "password") {
        tObj.selector = "display_password"
        tObj.cell = (row) => (
          <div className="grid-ellipsis" style={setErrorstyle(x, row)}>
            <label className="show-sm">{userFields[x].name}</label>
            <span title={row["display_password"]}>{row["display_password"]}</span>
          </div>
        )
      } else if (x === "role") {
        tObj.selector = "display_role"
        tObj.cell = (row) => (
          <div className="grid-ellipsis" style={setErrorstyle(x, row)}>
            <label className="show-sm">{userFields[x].name}</label>
            <span title={row["display_role"]}>{row["display_role"]}</span>
          </div>
        )
      }
      //display_password
      userColumns.push(tObj)
    }
    userTablewidth = userTablewidth + 200

    const { labels } = this.props.labels;
    const rowsPerPageText = [this.getLabel("LBLROWSPERPAGE") + ":", this.getLabel("LBLOF"), this.getLabel("LBLALL")];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    if (this.state.importStep === 1) {
      return (
        <div className="mob-pad">
          {renderHeader()}

          {this.state.loading ? (
            <div style={{ marginTop: "60px" }}><Loader></Loader></div>

          ) : (
            <div style={{ padding: "15px", textAlign: "center", marginTop: "35px" }}>
              <div
                style={{
                  position: "relative",
                  textAlign: "right",
                  width: "70%",
                  height: "20px",
                  marginTop: "30px",
                  left: "13%"
                }}>

                <div style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                  <i class={`fa fa-download ${styles.downloadIcon}`}></i>
                  <span className={styles.downloadSample} > {this.getLabel("LBLDOWNLOADSAMPLE")}</span>
                  <button
                    style={{ padding: "0px 4px 3px" }}
                    className="link-btn"
                    onClick={this.downloadCSV.bind()}
                  >
                    csv
                  </button>{this.getLabel("LBLOR")}
                  <button
                    style={{ padding: "0px 4px 3px" }}
                    className="link-btn"
                    onClick={this.downloadXLSX.bind()}
                  >
                    xlsx
                  </button>
                </div>

              </div>
              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  width: "70%",
                  marginTop: "-7px",
                  marginBottom: "8px",
                  left: "15%",
                  borderRadius: "6px"
                }}
              >
                <div className={styles.dragAndDropCtn}>
                  <DragAndDrop handleDrop={this.handleDrop}>
                    <div
                      style={{
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <ul class="list-unstyled mt-3 mb-6">
                        {this.state.importSubStep === 1 ? <>
                          <li style={{ margin: "5px" }}>
                            <i class={`fa fa-upload ${styles.dragDropIcon}`} ></i>
                          </li>
                          <li style={{ margin: "5px" }}>
                            <i className={styles.DragAndDropText}> {this.getLabel("LBLDRAGNDROP")}<br /></i>
                            <i className={styles.DragAndDropText}> {this.getLabel("LBLOR")}</i>
                          </li>
                          <li style={{ margin: "5px" }}>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={this.openBrowseWindow}
                              title={this.getLabel("LBLIMPORTFILETYPESMESSAGE1")}
                            >
                              <input
                                type="file"
                                ref={this.fileAttachRef}
                                hidden
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"//"application/csv, application/xls, application/xlsx"
                                onChange={this.fileAttachOnChangeHandler}
                              />
                              <button className="button-style primary-btn">
                                {labels.LBLBROWSE}
                              </button>
                            </div>
                          </li>
                        </> : <>
                          <li style={{ margin: "5px 5px 15px 5px" }}>
                            <div className={styles.ssiFeedsPostAssetsContainerWrapper}>
                              {this.state.attachedFiles.map(
                                (fileObj, index) => {
                                  return (
                                    <>
                                      <PostImage
                                        key={fileObj.name}
                                        index={index}
                                        imageObj={fileObj}
                                        removePostAssets={
                                          this.removeImportFile
                                        }
                                        labels={this.props.labels}
                                      />
                                    </>

                                  );
                                }
                              )}
                            </div>
                          </li>
                        </>
                        }
                        <li style={{ margin: "5px 5px 15px", display: this.state.importprofileerror === false ? 'block' : 'none' }}>
                          <span style={{ color: this.state.importfileerror === true ? "red" : "",fontSize:"14px" }}>{this.getLabel("LBLIMPORTFILETYPESMESSAGE1")}</span> &nbsp;
                          <span style={{ color: this.state.importfileerror === true ? "red" : "",fontSize:"14px" }}>{this.getLabel("LBLIMPORTFILESIZESMESSAGE")}</span>
                        </li>

                        <li style={{ margin: "5px 5px 15px 5px", display: this.state.importprofileerror === true ? 'block' : 'none' }}>
                          <i className={styles.notMapped} >{this.getLabel(this.state.importprofileerrormsg || "")}</i> &nbsp;
                        </li>
                      </ul>
                    </div>
                  </DragAndDrop>
                </div>
              </div>
              <div class="row">
                <div
                  className="col-sm-12"
                  style={{ marginTop: "60px", display: "inline-block" }}
                >
                  <button
                    type="submit"
                    className={this.state.importfileerror === false && this.state.attachedFiles.length > 0 ? "button-style primary-btn" : "button-style default-btn"}
                    style={this.state.importfileerror === false && this.state.attachedFiles.length > 0 ? { minWidth: "100px", float: "right", marginRight: "10px", cursor: "pointer" }
                      : { minWidth: "100px", marginRight: "10px", float: "right", cursor: "default" }}
                    onClick={this.state.importfileerror === false && this.state.attachedFiles.length > 0 ? this.moveNextStep.bind(this) : ""}
                  >
                    {labels.LBLNEXT}
                  </button>
                  <Link variant="primary" to={"/schome/userlist"}>
                    <button type="button" style={{ minWidth: "100px", float: "right", marginRight: "10px" }} className="button-style secondary-btn"
                      onClick={this.StopTimeOutFunction}
                    >
                      {labels.LBLCANCEL}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (this.state.importStep === 2) {
      return (
        <div className="mob-pad">
          {renderHeader()}
          {this.state.loading ? (
            <div style={{ marginTop: "60px" }}><Loader></Loader></div>
          ) : (
            <div style={{ padding: "15px", marginTop: "35px" }}>
              <div class="row">
                <div className="col-sm-12" style={{ paddingTop: "0px", marginTop: "0px" }}>
                  <span className={styles.pageSubTitle}>{this.getLabel("LBLAUTOMAPPED")}</span>
                  <div className={`col-sm-12 ${styles.coreFieldCtn}`} >
                    {this.getLabel("LBLMAPHEADERFIELD")}
                  </div>
                  <div className={`row col-sm-12 ${styles.coreFieldInnerCtn}`} >
                    <div className="col-sm-3" style={{ padding: "10px" }}>
                      {this.getLabel("LBLYOUFACTORFIELD")}
                    </div>
                    <div className="col-sm-2" style={{ padding: "10px" }}>

                    </div>
                    <div className="col-sm-3" style={{ padding: "10px" }}>
                      {this.getLabel("LBLFILEHEADER")}
                    </div>
                    <div className="col-sm-4" style={{ padding: "10px" }}>
                      {this.state.importSubStep === 1 ? this.getLabel("LBLFIRSTROWDATA") : this.getLabel("LBLFIRSTROWDATA")}
                    </div>
                  </div>
                  <div className={`row col-sm-12 ${styles.entityMappingCol}`}  >
                    {Object.keys(this.state.objUserFields).map((item, i) => {
                      return (
                        <>
                          <div className={` col-sm-3 ${styles.entityMappingInnerCol}`}>
                            {this.state.objUserFields[item].name}
                          </div>
                          <div className={`col-sm-2 ${styles.entityMappingInnerCol}`} >
                            {this.state.objUserFields[item].mapfield === "" || this.state.objUserFields[item].mapindex === -1 ?
                              <span className= {styles.notMapped} >{this.getLabel("LBLNOTMAPPED")}</span> : <>
                              </>
                            }
                          </div>
                          <div className={` col-sm-3 ${styles.entityMappingInnerCol}`}>
                            {renderOperatorSelector(item)}
                          </div>
                          <div className={` col-sm-4 ${styles.entityMappingInnerCol}`}>
                            {this.state.objUserFields[item].errormessage ? (
                              <div
                                class="alert alert-danger"
                                style={{ marginBottom: "-14px", padding: "5px" }}
                              >
                                {this.state.objUserFields[item].errormessage}
                              </div>
                            ) : (
                              <div style={{ padding: "5px 0px 10px 5px" }}>
                                {this.state.objUserFields[item].mapdata}&nbsp;
                              </div>
                            )}

                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  className="col-sm-12"
                  style={{ marginTop: "60px", display: "inline-block" }}
                >
                  {this.state.entityMapValidity === true ? <button
                    type="submit"
                    className="button-style primary-btn"
                    style={{ minWidth: "100px", marginRight: "10px", float: "right" }}
                    onClick={this.moveNextStep.bind(this)}>
                    {labels.LBLNEXT}
                  </button> :
                    <button
                      type="submit"
                      className="button-style default-btn"
                      style={{ minWidth: "100px", marginRight: "10px", float: "right", cursor: "default" }}>
                      {labels.LBLNEXT}
                    </button>
                  }

                  <button
                    type="submit"
                    className="button-style default-btn"
                    style={{ minWidth: "100px", marginRight: "10px", float: "right" }}
                    onClick={this.movePrevStep.bind(this)}
                  >
                    {this.getLabel("LBLPREVIOUS")}
                  </button>
                  <button type="button" style={{ minWidth: "100px", float: "right", marginRight: "10px" }} className="button-style secondary-btn"
                    onClick={this.moveStartOverStep.bind(this)}
                  >
                    {labels.LBLSTARTOVER}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (this.state.importStep === 3) {
      return (
        <div className="mob-pad userimport">
          {renderHeader()}

          {this.state.loading ? (
            <div style={{ marginTop: "60px" }}><Loader></Loader></div>
          ) : (
            <div  style={{ padding: "15px", marginTop: "35px" }}>
              <div class="row">
                <div className="col-sm-12" style={{ paddingTop: "0px" }}>
                  {this.state.newGroupsDataCopy.length > 0 ? <>
                    {this.state.newGroupsCreated === true ?
                      <span className={styles.addGroupSuccess} >{this.getLabel("LBLADDGROUPSSUCCESS")}</span> : <>
                        {this.state.groupValidationIA === false ?
                          <span className={styles.inActGrpDesc} >{this.getLabel("LBLINACTIVEGROUPSDESC")}</span> :
                          <span>{this.getLabel("LBLADDGROUPSDESC")}</span>
                        }
                      </>
                    }
                  </> :
                    <>
                      <span className={styles.pageSubTitle} >{this.getLabel("LBLNOADDGROUPSDESC")}</span>
                    </>
                  }
                  <div className={`col-sm-12 ${styles.coreFieldCtn}`}>
                    {this.getLabel("LBLADDGROUPHEADER")}
                  </div>
                  <div className={`row col-sm-12 usr-mang-datatable ${styles.addGroupInnerCtn}`} >
                    <div style={{ width: "100%" }}>
                      <DataTable
                        columns={groupCoumns}
                        data={this.state.newGroupsDataCopy}
                        className="datatable-list"
                        pagination
                        noDataComponent={noGroupsComponent()}
                        paginationComponentOptions={paginationComponentOptions}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div className="col-sm-12"
                      style={{ marginTop: "0px", display: "inline-block" }}>
                      {this.state.groupValidationIA === true && this.state.groupValidation === true && this.state.newGroupsDataCopy.length > 0 && this.state.newGroupsCreated === false ?
                        <button
                          type="submit"
                          className="button-style primary-btn"
                          style={{ minWidth: "100px", marginTop: "20px", marginRight: "10px", float: "right" }}
                          onClick={this.addGroups.bind(this)}>
                          {this.getLabel("LBLADDNEWGROUPS")}
                        </button> : <></>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  className="col-sm-12"
                  style={{ marginTop: "60px", display: "inline-block" }}
                >
                  {this.state.newGroupsCreated === true ||
                    (this.state.newGroupsDataCopy.length === 0) ?
                    <button
                      type="submit"
                      className="button-style primary-btn"
                      style={{ minWidth: "100px", marginRight: "10px", float: "right" }}
                      onClick={this.moveNextStep.bind(this)}>
                      {labels.LBLNEXT}
                    </button> :
                    <button
                      type="submit"
                      className="button-style default-btn"
                      style={{ minWidth: "100px", marginRight: "10px", float: "right", cursor: "default" }}>
                      {labels.LBLNEXT}
                    </button>
                  }

                  <button
                    type="submit"
                    className="button-style default-btn"
                    style={{ minWidth: "100px", marginRight: "10px", float: "right" }}
                    onClick={this.movePrevStep.bind(this)}
                  >
                    {this.getLabel("LBLPREVIOUS")}
                  </button>
                  <button type="button" style={{ minWidth: "100px", float: "right", marginRight: "10px" }} className="button-style secondary-btn"
                    onClick={this.moveStartOverStep.bind(this)}
                  >
                    {labels.LBLSTARTOVER}
                  </button>
                </div>
              </div>
              {this.state.showToast.show ? (
                <ToastMessage
                  show={this.state.showToast["show"]}
                  helpers={this.state.showToast}
                ></ToastMessage>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      );
    } else if (this.state.importStep === 4) {
      return (
        <div className="mob-pad">
          {renderHeader()}
          <Modal
            show={this.state.open}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className= "alert-modal-title">
                {this.props.labels.labels.LBLINVITELATER}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <div className="alert-modal-body">
                <p>
                 {labels.LBLCNFINVITE?labels.LBLCNFINVITE.split('&&&')[0]: "Proceeding will trigger emails to the eligible users."}
                </p>
                <p style={{marginTop:"-10px"}}>
                 {labels.LBLCNFINVITE?labels.LBLCNFINVITE.split('&&&')[1]:"Are you sure you want to continue?."}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer style={{borderTop:"0px"}}>
                  <button
                    type="button" id="btn_cancel"
                    className="button-style secondary-btn"
                    onClick={this.handleClose}
                  >
                    {this.props.labels.labels.LBLCANCEL}
                  </button>
                  <button
                    type="button" id="btn_ok"
                    className="button-style primary-btn"
                    onClick={this.handleClose}
                  >
                    {this.props.labels.labels.LBLCONFIRM}
                  </button>
            </Modal.Footer>
          </Modal> 
          {this.state.loading ? (
            <div style={{ marginTop: "60px" }}><Loader></Loader></div>
          ) : (
            <div  style={{ padding: "15px", marginTop: "35px" }}>
              <div class="row ">
                <div className="col-sm-12" style={{ paddingTop: "0px" }}>
                  {this.state.dataValidation === true ? <>
                    <span className={styles.pageSubTitle} >{this.getLabel("LBLDATAVALIDATOIONDESC")}</span>
                  </> :
                    <>
                      <span className={styles.noDataAvailable} >{this.getLabel("LBLNODATAVALIDATOIONDESC")}</span>
                    </>
                  }
                  <div class="row" style={{ marginTop: "15px", paddingLeft: "10px" }}>
                    <div
                    className={styles.importOption}
                    >
                      {this.getLabel("LBLIMPORTOPTIONS")}:
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        paddingBottom: "10px",
                      }}
                      className="icoBtn"
                    >
                      <label className="check-lbl">
                        <input
                          type="checkbox"
                          data-toggle="modal"
                          checked={this.state.sendNotifyMail}
                          onChange={this.changeNotifyMail}
                        />
                        <span className="checkmark" style={{ marginTop: "-4px",width:"21px",height:"21px" }}></span>
                        <span  >
                          {labels.LBLINVITELATER}
                        </span>
                      </label>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        paddingLeft: "10px",
                        paddingBottom: "15px",
                      }}
                      className="icoBtn"
                    >
                      <label className="check-lbl">
                        <input
                          type="checkbox"
                          data-toggle="modal"
                          checked={this.state.forcePassword}
                          onChange={this.changeForcePassword}
                        />
                        <span className="checkmark" style={{ marginTop: "-4px",width:"21px",height:"21px" }}></span>
                        <span >
                          {labels.LBLFORCEPASSWORD?labels.LBLFORCEPASSWORD:"Force Password Change"}
                        </span>
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      textAlign: "right",
                      width: "100%",
                      height: "20px",
                      marginTop: "-24px"
                    }}>
                    <div style={{ paddingTop: "3px", paddingBottom: "3px" }}>
                      <i class={`fa fa-download ${styles.downloadIcon}`}></i>
                      <span className={styles.downloadSample}> {this.getLabel("LBLDOWNLOADSUMMARY")}</span>
                      <button
                        style={{ padding: "0px 4px 3px" }}
                        className="link-btn"
                        onClick={this.downloadSummaryCSV.bind(this, "LBLVAILDATIONSUMMARY")}
                      >
                        csv
                      </button>{this.getLabel("LBLOR")}
                      <button
                        style={{ padding: "0px 4px 3px" }}
                        className="link-btn"
                        onClick={this.downloadSummaryXLSX.bind(this, "LBLVAILDATIONSUMMARY")}
                      >
                        xlsx
                      </button>
                    </div>

                  </div>
                  <div className={`col-sm-12 ${styles.datValCtn}`} >
                    {this.state.dataValidation === true ? <>
                      <div className={styles.rightimg}
                        style={{ display: "inline-block", margin: "0px 6px", float: "left" }}
                        title={this.getLabel("LBLDATAVALIDATOIONDESC")}>
                      </div>
                    </> : <>
                      <div className={styles.wrongimg}
                        style={{ display: "inline-block", margin: "0px 6px", float: "left" }}
                        title={this.getLabel("LBLNODATAVALIDATOIONDESC")}>
                      </div>
                    </>}{this.getLabel("LBLDATAVALIDATOIONHEADER")}
                  </div>
                  <div className={`row col-sm-12 usr-mang-datatable ${styles.dataTableCt}`}  >
                    <div style={{ width: userTablewidth + "px" }}>
                      <DataTable
                        columns={userColumns}
                        data={this.state.importUsersData}
                        className="datatable-list"
                        pagination
                        noDataComponent={this.getLabel("LBLNORECORDS")}
                        paginationComponentOptions={paginationComponentOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  className="col-sm-12"
                  style={{ marginTop: "60px", display: "inline-block" }}
                >
                  {
                    (this.state.dataValidation === true) ?
                      <button
                        type="submit"
                        className="button-style primary-btn"
                        style={{ minWidth: "100px", marginRight: "10px", float: "right" }}
                        onClick={this.moveNextStep.bind(this)}>
                        {this.getLabel("LBLIMPORT")}
                      </button> :
                      <button
                        type="submit"
                        className="button-style default-btn"
                        style={{ minWidth: "100px", marginRight: "10px", float: "right", cursor: "default" }}>
                        {this.getLabel("LBLIMPORT")}
                      </button>
                  }

                  <button
                    type="submit"
                    className="button-style default-btn"
                    style={{ minWidth: "100px", marginRight: "10px", float: "right" }}
                    onClick={this.movePrevStep.bind(this)}
                  >
                    {this.getLabel("LBLPREVIOUS")}
                  </button>
                  <button type="button" style={{ minWidth: "100px", float: "right", marginRight: "10px" }} className="button-style secondary-btn"
                    onClick={this.moveStartOverStep.bind(this)}
                  >
                    {labels.LBLSTARTOVER ? labels.LBLSTARTOVER : "Start Over"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (this.state.importStep === 5) {
      return (
        <div className="mob-pad">
          {renderHeader()}

          {this.state.loading ? (
            <div style={{ marginTop: "60px" }}><Loader></Loader></div>
          ) : (
            <div  style={{ padding: "15px", marginTop: "25px" }}>
              <div class="row">
                <div className="row col-sm-12" style={{ paddingTop: "0px" }}>
                  <div style={{ width: "100%", textAlign: "center", marginTop: "12px" }}>
                    {this.state.pendingRecords > 0 ? <>
                      <span className={styles.importingProgress}>{this.getLabel("LBLIMPORTINPROGRESS")}</span>
                    </> :
                      <>
                        <span className={styles.imptUsrSuccess}>{this.getLabel({ label: "LBLIMPORTEDHEADER", val: this.state.successRecords })}</span>
                      </>
                    }
                  </div>
                </div>
                <br></br> <br></br>
                <div className="row col-sm-12" style={{ paddingTop: "0px" }}>
                  <div style={{
                    width: "83%",
                    left: "9%",
                    height: "22px",
                    position: "relative",
                    top: "0px"
                  }}>
                    <div className={styles.progress1}>
                      <div
                        className={`bg-play ${styles.progressBar }`}
                        role="progressbar"
                        style={{
                          width: this.state.completed + "%",
                          height: "24px",
                          borderRadius: "4px"
                        }}
                        aria-valuenow={
                          this.state.completed
                        }
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <span style={{
                          left: "45%",
                          position: "absolute",
                          fontWeight: "500",
                          color: this.state.completed < 50 ? "#000" : "white"
                        }}
                        > {(this.state.completed === null || isNaN(this.state.completed))
                          ? 0
                          : this.state.completed}{" %"} </span>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className= {`row col-sm-12 ${styles.viewImportDataCtn}`} >

                  <div className="row col-sm-12" style={{ paddingTop: "0px" }}>
                    <div style={{
                      position: "relative",
                      backgroundColor: "#fcfcfc",
                      width: "80%",
                      left: "10%"
                    }}>
                      <div style={{ float: "left", width: "auto" }} >
                        <div className={`row ${styles.fileTitleWrapp}`}  style={{ padding: "8px" }}>
                          <span className={styles.fileTitle}>{this.getLabel("LBLFILENAME")}</span>
                          <span>: {this.state.importedFilename}</span>
                        </div>
                        <div className={`row ${styles.fileTitleWrapp}`} style={{ padding: "8px" }}>
                          <span className={styles.fileTitle}>{this.getLabel("LBLREMAININGRECORDS")}</span>
                          <span>: {this.state.pendingRecords}</span>
                        </div>
                        <div className={`row ${styles.fileTitleWrapp}`} style={{ padding: "8px" }}>
                          <span className={styles.fileTitle}>{this.getLabel("LBLCOMPLETEDRECORDS")}</span>
                          <span>: {this.state.processedRecords}</span>
                        </div>


                      </div>
                      <div style={{ float: "right", width: "auto" }}>
                        <div className={`row ${styles.fileTitleWrapp}`} style={{ padding: "8px" }}>
                          <span className={ `${styles.fileTitle} ${styles.successTextColor}`}>{this.getLabel("LBLSUCCESSRECORDS")}</span>
                          <span>: {this.state.successRecords}</span>
                        </div>
                        <div className={`row ${styles.fileTitleWrapp}`} style={{ padding: "8px" }}>
                          <span className={`${styles.fileTitle} ${styles.notMapped}`} >{this.getLabel("LBLFAILEDRECORDS")}</span>
                          <span>: {this.state.failedRecords}</span>
                        </div>
                        <div className={`row ${styles.fileTitleWrapp}`} style={{ padding: "8px" }}>
                          <span className={styles.fileTitle}>{this.getLabel("LBLTOTALRECORDS")}</span>
                          <span>: {this.state.totalRecords}</span>
                        </div>

                      </div>
                    </div>
                  </div>

                  {
                    this.state.importedData.length > 0 ?
                      <div className="row col-sm-12" style={{ paddingTop: "0px" }}>
                        <div
                          style={{
                            position: "relative",
                            textAlign: "center",
                            width: "100%",
                            height: "20px",
                            marginTop: "20px"
                          }}>
                          <button
                            type="submit"
                            className={this.state.pendingRecords === 0 ? "button-style primary-btn" : "button-style default-btn"}
                            style={this.state.pendingRecords === 0 ? { minWidth: "100px", marginRight: "10px", cursor: "pointer" }
                              : { minWidth: "100px", marginRight: "10px", border: "1px solid #d2d2d2", cursor: "default" }}
                            onClick={this.state.pendingRecords === 0 ? this.showImportSummaryData.bind(this) : ""}
                          >
                            {this.getLabel("LBLSHOWIMPORTDATA")}
                          </button>
                        </div>
                      </div>
                      : ""
                  }

                </div>
                <div className="col-sm-12" style={{ paddingTop: "0px", display: this.state.showImportSummaryData === true ? '' : 'none' }}>
                  <div className="col-sm-12">
                    <div style={{ paddingTop: "6px", paddingBottom: "0px", float: "right" }}>
                      <i class={`fa fa-download ${styles.downloadIcon}`}></i>
                      <span className={styles.downloadSample}> {this.getLabel("LBLDOWNLOADIMPORTDATA")}</span>
                      <button
                        style={{ padding: "0px 4px 0px" }}
                        className="link-btn"
                        onClick={this.downloadSummaryCSV.bind(this, "LBLIMPORTSUMMARY")}
                      >
                        csv
                      </button>{this.getLabel("LBLOR")}
                      <button
                        style={{ padding: "0px 4px 0px" }}
                        className="link-btn"
                        onClick={this.downloadSummaryXLSX.bind(this, "LBLIMPORTSUMMARY")}
                      >
                        xlsx
                      </button>
                    </div>
                  </div>
                  <div className={`col-sm-12 ${styles.datValCtn}`} style={{ marginTop: "32px" }}>
                    {this.getLabel("LBLIMPORTDATA")}
                  </div>
                  <div className={`row col-sm-12 usr-mang-datatable ${styles.dataTableCt}`} >
                    <div style={{ width: userTablewidth + "px" }}>
                      <DataTable
                        columns={userColumns}
                        data={this.state.importedData}
                        className="datatable-list"
                        pagination
                        noDataComponent={this.getLabel("LBLNORECORDS")}
                        paginationComponentOptions={paginationComponentOptions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          }
        </div >
      );
    }

  }
  getLastImportStatus = () => {
    this.setState({
      importStep: 5,
      importSubStep: 1,
      loading: true,
      import_id: 0
    })
    this.getImportProcessStatus(0)
  }
  validateEntityMap = () => {
    let xfields = this.state.objUserFields;
    for (let x in xfields) {
      delete xfields[x].errormessage
    }
    let username_nomap = false
    let email_nomap = false
    let valid = true
    let xvalid = true
    for (let x in xfields) {
      if (xfields[x].mapfield === "" || xfields[x].mapindex === -1) {
        if (x === "password") {
          xfields[x].errormessage = this.getLabel("LBLPASSWORDREQUIRED")
          valid = false
        } else if (x === "first_name") {
          xfields[x].errormessage = this.getLabel("LBLREQUIREDFIRSTNAME")
          valid = false
        } else if (x === "last_name") {
          xfields[x].errormessage = this.getLabel("LBLREQUIREDLASTNAME")
          valid = false
        } else if (x === "user_name") {
          xfields[x].errormessage = this.getLabel("LBLEMAILORUSERNAMEREQUIRED")
          xvalid = false
          username_nomap = true
        } else if (x === "email") {
          xfields[x].errormessage = this.getLabel("LBLEMAILORUSERNAMEREQUIRED")
          xvalid = false
          email_nomap = true
        } if (x === "role") {
          xfields[x].errormessage = this.getLabel("LBLREQUIREDROLE")
          valid = false
        } if (x === "language") {
          xfields[x].errormessage = this.getLabel("LBLREQUIREDLANGUAGE")
          valid = false
        }
      }
    }
    if (username_nomap === true && email_nomap === true) {
      xvalid = false
    } else {

      xfields["user_name"].errormessage = ""
      xfields["email"].errormessage = ""
      xvalid = true
    }
    let fValid = (xvalid === false || valid === false) ? false : true
    this.setState({
      objUserFields: xfields,
      entityMapValidity: fValid
    });
  };
  readImportUsersData = () => {
    let xusers = [];
    let xrdata = this.state.rawUserData;
    let forcePassword = this.state.forcePassword?1:0;

    let password;
    let hash = CryptoJS.SHA512(password);
    hash = Base64.stringify(hash);
    let xfields = this.state.objUserFields;
    let objunexists = {};
    let objemailexists = {};
    for (let i = 1; i < xrdata.length; i++) {
      for (let x in xfields) {
        delete xfields[x].errormessage
      }
    }
    let dataValidation = true
    for (var i = 1; i < xrdata.length; i++) {
      let isValid = true;
      let xrow = {};
      xrow["error_data"] = {}
      xrow["error_message"] = ""
      for (let x in xfields) {
        if (xfields[x].mapindex !== -1) {
          xrow[x] = xrdata[i][xfields[x].mapindex];
        }
        delete xfields[x].errormessage
      }
      for (let x in xfields) {
        if (x === "password") {
          xrow["display_password"] = xrow[x]
          if (String(xrow["password"] || "") === "" && String(xrow["user_name"] || "") !== "" && (xrow["email"] === "" || xrow["email"] === undefined)) {
            isValid = false;
            xrow["error_data"][x] = this.getLabel("LBLPASSWORDREQUIRED")
            xfields[x].errormessage = this.getLabel("LBLPASSWORDREQUIRED") + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + (xrow["first_name"] || "");
          } else {
            for (let s = 0; s < xfields[x].validate.length; s++) {
              if (
                !xfields[x].errormessage &&
                xfields[x].validate[s](String(xrow[x] || "")) !== undefined
              ) {
                isValid = false;
                //console.log("AAAAAAA", xfields[x].validate[s], "BBBBBBBBBBBB", xfields[x].validate[s](String(xrow[x] || "")))
                let eobj = xfields[x].validate[s](String(xrow[x] || ""))
                if (eobj.label) {
                  eobj.label = eobj.label + xfields[x].errorsuffixlabel
                } else {
                  eobj = eobj + (eobj === "LBLINVALIDEMAIL" ? "" : xfields[x].errorsuffixlabel)
                }
                xrow["error_data"][x] = this.getLabel(eobj)
                xfields[x].errormessage = this.getLabel(xfields[x].validate[s](String(xrow[x] || ""))) + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + (xrow["first_name"] || "");
              }
            }
            if (isValid === true) {
              if (typeof xrow[x] === "number")
                xrow[x] = xrow[x].toString();
              let xhash = CryptoJS.SHA512(xrow[x]);
              xhash = Base64.stringify(xhash);
              xrow[x] = xhash;
            }
          }
        } else if (x === "user_name") {
          if (xrow[x] !== "" && xrow[x] !== undefined) {
            if (!objunexists[xrow[x]]) {
              objunexists[xrow[x]] = 1;
            } else {
              isValid = false;
              xrow["error_data"][x] = this.getLabel("LBLUNAMEDUPLICATEMSG")
              xfields[x].errormessage = this.getLabel("LBLUNAMEDUPLICATEMSG") + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + xrow[x];
            }
          }
        } else if (x === "email") {
          if (xrow[x] !== "" && xrow[x] !== undefined) {
            if (!objemailexists[xrow[x]]) {
              objemailexists[xrow[x]] = 1;
            } else {
              isValid = false;
              xrow["error_data"][x] = this.getLabel("LBLEMAILDUPLICATEMSG")
              xfields[x].errormessage = this.getLabel("LBLEMAILDUPLICATEMSG") + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + xrow[x];
            }
          } else {
            if ((xrow["email"] === "" || xrow["email"] === undefined) && xrow["user_name"] !== "" && xrow["user_name"] !== undefined) {
              xrow["user_with_nomail"] = 1
            } else if ((xrow["email"] === "" || xrow["email"] === undefined) && (xrow["user_name"] === "" || xrow["user_name"] === undefined)) {
              isValid = false;
              xrow["error_data"][x] = this.getLabel("LBLEMAILORUSERNAMEREQUIRED")
              xfields[x].errormessage = this.getLabel("LBLEMAILORUSERNAMEREQUIRED") + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + (xrow["first_name"] || "");
            }
          }
        } else if (x === "role") {
          let roles = [];
          let rolename = String(xrow[x] || ""); //"User"
          //console.log("rolename22222", rolename, "1111111", "33333")
          this.state.roles.map((item, index) => {
            if (
              rolename.toLowerCase() ===
              this.getLabel(item.role_name).toLowerCase()
            ) {
              roles.push(item.role_id);
            }
            return null;
          });
          xrow[x] = roles;
          if ((xrow["email"] === "" || xrow["email"] === undefined) && roles.indexOf(1) > -1) {
            isValid = false;
            xrow["error_data"]["email"] = this.getLabel("LBLEMAILREQUIREDFORADMIN")
          }
          xrow["display_role"] = rolename
          //console.log("!@!@!@xfields[x].errormessage", i, rolename, roles.length, xfields[x].errormessage)
          if (!xfields[x].errormessage && rolename === "") {
            isValid = false;
            xrow["error_data"][x] = this.getLabel("LBLREQUIREDROLE")
            xfields[x].errormessage = this.getLabel("LBLREQUIREDROLE") + " " + this.getLabel("LBLROWNO") + " " + (i + 1)
          } else if (
            !xfields[x].errormessage &&
            rolename !== "" && rolename !== undefined &&
            roles.length === 0
          ) {
            isValid = false;
            xrow["error_data"][x] = this.getLabel("LBLINVALIDROLE")
            xfields[x].errormessage = this.getLabel("LBLINVALIDROLE") + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + rolename;
          }
        } else if (x.indexOf("GP_") === 0) {
          let post_groups = [];
          let groupname = String((xrow[x] || "")); //"Chennai"
          let grouptype = x;
          this.state.groups_by_type.map((item) => {
            if (grouptype === item.group_type_id) {
              if (item.groups_by_type) {
                let groups = JSON.parse(item.groups_by_type);
                groups.map((xitem) => {
                  if (xitem.group_name.toLowerCase() === groupname.toLowerCase()) {
                    let xgrpobj = {
                      group_id: xitem.group_id.toString(),
                      group_type_id: x,
                      group_name: xitem.group_name
                    };
                    if (!xrow["groups"]) {
                      xrow["groups"] = [xgrpobj];
                    } else {
                      xrow["groups"].push(xgrpobj);
                    }
                    post_groups.push({
                      group_id: xitem.group_id.toString(),
                      group_type_id: x,
                    });
                  }
                  return null;
                });
              }
            }
            return null;
          });
          if (
            !xfields[x].errormessage &&
            groupname !== "" &&
            post_groups.length === 0
          ) {
            isValid = false;
            xrow["error_data"][x] = this.getLabel("LBLVALUEDOESNOTEXIST")
            xfields[x].errormessage = this.getLabel("LBLVALUEDOESNOTEXIST") + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + groupname;
            xrow["display_valid"] = xfields[x].errormessage
          } else {
            xrow["display_valid"] = this.getLabel("LBLVALID")
          }

          for (let s = 0; s < xfields[x].validate.length; s++) {
            if (
              !xfields[x].errormessage &&
              xfields[x].validate[s](xrow[x]) !== undefined
            ) {
              isValid = false;
              xrow["error_data"][x] = xfields[x].name + " - " + this.getLabel(xfields[x].validate[s](xrow[x]))
              xfields[x].errormessage = this.getLabel(xfields[x].validate[s](xrow[x])) + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + (xrow["first_name"] || "");
            }
          }
        }

        for (let s = 0; s < xfields[x].validate.length; s++) {
          if (
            !xfields[x].errormessage &&
            xfields[x].validate[s](xrow[x]) !== undefined
          ) {
            isValid = false;
            let eobj = xfields[x].validate[s](String(xrow[x] || ""))
            if(eobj){
              if (eobj.label) {
                eobj.label = eobj.label + xfields[x].errorsuffixlabel
              } else {
                eobj = eobj + (eobj === "LBLINVALIDEMAIL" ? "" : xfields[x].errorsuffixlabel)
              }
              xrow["error_data"][x] = this.getLabel(eobj)
              xfields[x].errormessage = this.getLabel(xfields[x].validate[s](xrow[x])) + " " + this.getLabel("LBLROWNO") + " " + (i + 1) + " - " + (xrow["first_name"] || "");
            }
          }
        }


      }
      xrow.status = 1;  
      forcePassword = this.state.forcePassword?1:0;
      forcePassword = ($.trim(xrow.email) == "" || $.trim(xrow.email) == undefined)?forcePassword:0; 
      xrow.force_password_change = forcePassword;  
      if (isValid === false) {
        dataValidation = false
        let ermsg = []
        //console.log('xrow["error_data"]', xrow["error_data"])
        for (let ef in xrow["error_data"]) {
          ermsg.push(xrow["error_data"][ef])
        }
        xrow["error_message"] = ermsg.join(", ")
      }
      xusers.push(xrow);
    }
    //console.log("SubmitData", JSON.stringify(xusers));

    this.setState({
      importUsersData: xusers,
      dataValidation: dataValidation
    })

  };

  submitData = () => {
    //console.log(this.props)
    let data = {
      user_id: this.props.userId,
      users: this.state.importUsersData,
      invite_later: this.state.sendNotifyMail,
      filename: this.state.attachedFiles[0].name,
      company_url: String(window.location.href).split('/schome/userlist/import')[0]
    };
    let fPassword = this.state.forcePassword?1:0; 
    if(data.users.length>0 && fPassword==0){
      data.users.map((item)=>{
        item.force_password_change = fPassword;
      })
    }
    let url = process.env.REACT_APP_IMPORTUSERS_APIURL + "api/importuser"
    this.setState({
      completed: 0,
      totalRecords: data.users.length,
      processedRecords: 0,
      failedRecords: 0,
      successRecords: 0,
      pendingRecords: data.users.length,
      importedFilename: data.filename
    })
    HttpInterceptor.post(url, data).then(
      (res) => {
        //console.log("importusers result", res.data);
        let xsdata = res.data.data || [];
        let import_id = res.data.body.result.import_id
        let objUsersError = { count: 0, reason: [] };
        let objemailError = { count: 0, reason: "LBLEMAILALREADYEXISTS" };
        let objusernameError = { count: 0, reason: "LBLUSERNAMEALREADYEXISTS" };
        let invalidUsersCount = 0;
        for (let i = 0; i < xsdata.length; i++) {
          if (xsdata[i].validuser === 0) {
            invalidUsersCount++;
            objUsersError.count++
          }
          objemailError.count = objemailError.count + xsdata[i].exist_email;
          objusernameError.count = objusernameError.count + xsdata[i].exist_username;
          if (objemailError.count === 0 && xsdata[i].exist_email === 1) {
            objUsersError.reason.push(this.getLabel("LBLEMAILALREADYEXISTS"))
          }
          if (objusernameError.count === 0 && xsdata[i].exist_username === 1) {
            objUsersError.reason.push(this.getLabel("LBLUSERNAMEALREADYEXISTS"))
          }
          xsdata[i].index = i + 1;
        }
        this.setState({
          resultData: xsdata,
          objemailError: objemailError,
          objusernameError: objusernameError,
          objUsersError: objUsersError,
          invalidUsersCount: invalidUsersCount,
          import_id: import_id
        });
        setTimeout(() => {
          this.getImportProcessStatus(1)
        }, 1000);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  getImportProcessStatus = (s) => {
    let data = { user_id: this.props.userId };
    let url = process.env.REACT_APP_IMPORTUSERS_APIURL + "api/importuser/" + (this.state.import_id || 0) + "?user_id=" + this.props.userId
    HttpInterceptor.get(url, data).then(
      (res) => {
        // console.log("getimportprocessstatus result ", res.data);
        let resdata = res.data.body[0]
        //console.log("resdata", resdata);
        if (resdata && resdata.import_id) {
          this.setState({
            import_id: resdata.import_id,
            loading: false,
            showlastimportstatus: true
          })
        } else {
          this.setState({
            loading: false
          })
        }
        let rescompleted = (resdata.completed <= resdata.total_users) ? resdata.completed : resdata.total_users
        let completedpercent = parseInt((rescompleted / resdata.total_users) * 100)
        this.setState({
          import_id: resdata.import_id,
          completed: completedpercent,
          loading: false,
          totalRecords: resdata.total_users,
          processedRecords: rescompleted,
          failedRecords: resdata.fail_count,
          successRecords: resdata.success_count,
          pendingRecords: resdata.total_users - rescompleted,
          importedFilename: resdata.filename
        });
        if (resdata.completed < resdata.total_users && this.state.importStep === 5) {
          console.log("summary fetch import process data")
          if (s >= 0) {
            s = s + 1
            s = s > 4 ? 4 : s
            myTimeout = setTimeout(() => {
              // alert("recalling")
              this.getImportProcessStatus(s)
            }, s * 1000);
          }
          return
        } else {
          console.log("complete fetch import process data")
          this.getImportedData()
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getImportedData = (s) => {
    let data = { user_id: this.props.userId, fulldata: 1 };
    let url = process.env.REACT_APP_IMPORTUSERS_APIURL + "api/importuser/" + (this.state.import_id || 0) + "?user_id=" + this.props.userId + "&fulldata=1"

    HttpInterceptor.get(url, data).then(
      (res) => {
        //console.log("getImportedData result ", res.data);
        let resdata = res.data.body[0]
        let records = resdata.user_data //res.data.data.user_data
        for (let i = 0; i < records.length; i++) {
          let errmsg = []
          if (records[i]["validuser"] === 0) {
            errmsg.push(this.getLabel("LBLUSERNAMEOREMAILALREADYEXISTS"))
          } else {
            if (records[i]["validuser"] === 2) {
              errmsg.push(this.getLabel("LBLEMAILALREADYEXISTS"))
            }
            if (records[i]["validuser"] === 3) {
              errmsg.push(this.getLabel("LBLUSERNAMEALREADYEXISTS"))
            }
          }
          records[i].error_message = ""
          records[i]["error_data"] = {}
          records[i].error_message = errmsg.join(", ")
        }
        //console.log("records", records)
        this.setState({
          importedData: records
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  importUsers = () => {
    let users = [
      {
        first_name: "impt_fname1",
        last_name: "impt_lname1",
        email: "abc1@g.com",
        secondary_email: "",
        user_name: "importuser_1" + Math.random(),
        password:
          "z4PhNX7vuL3xVChQ1m2AB9Yg5AULVxXcg/SpIdNs6c5H0NE8XYXysP+DGNKHfuwvY7kxvUdBeoGlODJ6+SfaPg==",
        company_id: 11,
        status: 1,
        role: [4],
        groups: [
          { group_id: "21", group_type_id: "GP_1" },
          { group_id: "26", group_type_id: "GP_2" },
          { group_id: "156", group_type_id: "GP_3" },
        ],
      },
      {
        first_name: "impt_fname2",
        last_name: "impt_lname2",
        email: "abc2@g" + Math.random() + ".com",
        secondary_email: "",
        user_name: "importuser_2" + Math.random(),
        password:
          "z4PhNX7vuL3xVChQ1m2AB9Yg5AULVxXcg/SpIdNs6c5H0NE8XYXysP+DGNKHfuwvY7kxvUdBeoGlODJ6+SfaPg==",
        company_id: 11,
        status: 1,
        role: [4],
        groups: [
          { group_id: "21", group_type_id: "GP_1" },
          { group_id: "26", group_type_id: "GP_2" },
          { group_id: "156", group_type_id: "GP_3" },
        ],
      },
      {
        first_name: "impt_fname3",
        last_name: "impt_lname3",
        email: "abc3@g" + Math.random() + ".com",
        secondary_email: "",
        user_name: "importuser_3" + Math.random(),
        password:
          "z4PhNX7vuL3xVChQ1m2AB9Yg5AULVxXcg/SpIdNs6c5H0NE8XYXysP+DGNKHfuwvY7kxvUdBeoGlODJ6+SfaPg==",
        company_id: 11,
        status: 1,
        role: [4],
        groups: [
          { group_id: "21", group_type_id: "GP_1" },
          { group_id: "26", group_type_id: "GP_2" },
          { group_id: "156", group_type_id: "GP_3" },
        ],
      },
    ];
    let data = { users: users, invite_later: this.state.sendNotifyMail };

    HttpInterceptor.post("importusers", data).then(
      (res) => {
        //console.log("importusers result 1 ", res.data);
        this.showImportStage3();
      },
      (err) => {
        console.log(err);
      }
    );
  };

  convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  downloadCSV = (args) => {
    var data, filename, link;
    var stockData = [];
    var objHeader = {};
    let xfields = this.state.objUserFields;
    for (let x in xfields) {
      objHeader[xfields[x].name] = xfields[x].sampledata || "";
    }
    stockData.push(objHeader);
    var csv = this.convertArrayOfObjectsToCSV({
      data: stockData,
    });
    if (csv == null) return;
    filename = this.props.labels.labels.LBLSAMPLEUSERIMPORT + ".csv";
    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-16," + csv;
      //csv = "data:text/csv;\uFEFF," + csv;
    }
    data = encodeURI(csv);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  };
  exportToExcel = (excelData, fileName) => {
    //console.log(excelData, fileName)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  downloadXLSX = (args) => {
    var filename;
    var stockData = [];
    var objHeader = {};
    let xfields = this.state.objUserFields;
    for (let x in xfields) {
      objHeader[xfields[x].name] = xfields[x].sampledata || "";
    }
    stockData.push(objHeader);
    filename = this.props.labels.labels.LBLSAMPLEUSERIMPORT
    this.exportToExcel(stockData, filename)
  };

  downloadSummaryCSV = (xlabel) => {
    var data, filename, link;
    let stockData = this.fillSummaryData("csv")
    var csv = this.convertArrayOfObjectsToCSV({
      data: stockData,
    });
    if (csv == null) return;
    filename = this.getLabel(xlabel) + ".csv";
    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-16," + csv;
      //csv = "data:text/csv;\uFEFF," + csv;
    }
    data = encodeURI(csv);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  };

  downloadSummaryXLSX = (xlabel) => {
    var  filename;
    let stockData = this.fillSummaryData("xls")
    filename = this.getLabel(xlabel)
    this.exportToExcel(stockData, filename)
  };

  fillSummaryData = (mode) => {
    let summaryData = []

    let importUsersData = this.state.importUsersData
    if (this.state.importStep === 5) {
      importUsersData = JSON.parse(JSON.stringify(this.state.importedData))
    }
    let xfields = this.state.objUserFields;
    for (let i = 0; i < importUsersData.length; i++) {
      let objHeader = {};
      if (this.state.importStep > 3) {
        if (mode === "csv") {
          objHeader[this.getLabel("LBLVALIDATION")] = importUsersData[i]["error_message"].split(",").join(";") || this.getLabel("LBLVALID");
        } else {
          objHeader[this.getLabel("LBLVALIDATION")] = importUsersData[i]["error_message"] || this.getLabel("LBLVALID");
        }
      }
      for (let x in xfields) {
        objHeader[xfields[x].name] = importUsersData[i][x] || "";
        if (x === "password") {
          objHeader[xfields[x].name] = importUsersData[i]["display_password"] || "";
        } else if (x === "role") {
          objHeader[xfields[x].name] = importUsersData[i]["display_role"] || "";
        }
      }

      summaryData.push(objHeader)
    }
    return summaryData
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(UsersImport);

import React, { useState, useEffect } from "react";
import styles from "./AboutProduct.module.scss";
import ScCommonData from "../../../services/common_data";
import utils from "../../../utils/utils";
import whiteBeltOuter from '../../../assets/images/karate/beltoutercircle/whiteBanner.png'
import yellowBeltOuter from '../../../assets/images/karate/beltoutercircle/yellowBanner.png'
import orangeBeltOuter from '../../../assets/images/karate/beltoutercircle/orangeBanner.png'
import greenBeltOuter from '../../../assets/images/karate/beltoutercircle/greenBanner.png'
import brownBeltOuter from '../../../assets/images/karate/beltoutercircle/brownBanner.png'
import blueBeltOuter from '../../../assets/images/karate/beltoutercircle/blueBanner.png'
import blackBeltOuter from '../../../assets/images/karate/beltoutercircle/blackBanner.png'
const AboutProductBanner = (props) => {
    const [productDetails, setProductDetails] = useState({});
    const [whiteBeltCompleted,setWhiteBeltCompleted] = useState(false);
    useEffect(() => {
        setProductDetails(props.productDetails);
        if(props.gamificationDetails?.length > 0 && props?.gamificationDetails[0]?.earned_badge === 2){
            setWhiteBeltCompleted(true);
        }else{
            setWhiteBeltCompleted(false);
        }
    }, [props.gamificationDetails]);

    const language = {
        "en-US": {
          code: "en-US",
          name: "English",
        },
        "en-uk": {
          code: "en-uk",
          name: "English",
        },
        "en-UK": {
          code: "en-UK",
          name: "English",
        },
        "es-MX": {
          code: "es-MX",
          name: "Español (LA)",
        },
        "it-IT": {
          code: "it-IT",
          name: "Italian"
        },
        "de-DE": {
          code: "de-DE",
          name: "Deutsch",
        },
        "fr-FR": {
          code: "fr-FR",
          name: "Français",
        },
        "pt-BR": {
          code: "pt-BR",
          name: "Português",
        },
        "sv-SE": {
          code: "sv-SE",
          name: "Swedish",
        },
        "ru-RU": {
          code: "ru-RU",
          name: "Russian",
        },
        "nl-NL": {
          code: "nl-NL",
          name: "Dutch"
        },
        "pl-PL": {
          code: "pl-PL",
          name: "Polish"
        },
        "da-DK": {
          code: "da-DK",
          name: "Danish"
        },
      };

    const Languages = Object.values(language).sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
        });

    const getLanguage = (xvalue) => {
        var xObj = {};
        let dText = "";
        xObj =
            Languages[
            Object.keys(Languages).find(
                (d, key) => Languages[d].code === xvalue
            )
            ];
        dText = xObj?.name;
        return dText;
    };

    const bannerImage = () => {
        const windowWidth = window.innerWidth;
    
        if (windowWidth > 440) {
            return (
                productDetails?.bannerImage
                    ? `${process.env.REACT_APP_CRSENGINE2_IMAGEURL}image/${productDetails?.productId}/${productDetails?.bannerImage}`
                    : productDetails?.productImage
                        ? `${process.env.REACT_APP_CRSENGINE2_IMAGEURL}image/${productDetails?.productId}/${productDetails?.productImage}`
                        : props.productImage
            );
        }    
        else {
            return (
                productDetails?.bannerImage_m
                    ? `${process.env.REACT_APP_CRSENGINE2_IMAGEURL}image/${productDetails?.productId}/${productDetails?.bannerImage_m}`
                    : productDetails?.productImage
                        ? `${process.env.REACT_APP_CRSENGINE2_IMAGEURL}image/${productDetails?.productId}/${productDetails?.productImage}`
                        : props.productImage
            );
        }
    };

    const beltOuter = {
        "white": whiteBeltOuter,
        "yellow": yellowBeltOuter,
        "orange": orangeBeltOuter,
        "green": greenBeltOuter,
        "brown": brownBeltOuter,
        "blue": blueBeltOuter,
        "black": blackBeltOuter
      }
    const imageUrl = bannerImage();

    const backgroundImageStyle = imageUrl ? `url(${imageUrl})` : 'none';

    const calcTime = () => {
      return utils.convertToHoursAndMinutes(productDetails?.productEstimationTime ? productDetails?.productEstimationTime : 0, props.labels)
    };
    const timeValue = calcTime();
    console.log("145",props.resultData && props.resultData[0]?.languagecode,"props.resultData && props")
    return(
        <div className={styles.bannerCtn} style={{ backgroundImage: backgroundImageStyle}}>
        <div className = { `${styles.overlay} ${!imageUrl && styles.overlayFullGradiant}`}>
            <div className={styles.overlayInnCtn}>
            <span className={styles.productName} >
                {productDetails?.productName ? productDetails?.productName : props.productName}
            </span>

            <div className={styles.feedbackCtn}>
                <img src="/images/Module.png" className={styles.bannerIcon}/>
                <span className={styles.unitsLangText}>{props.unitsCount}{' '}{localStorage.getItem('site_type') === "Accutrain" || props.isModule ?  props.labels.LBLMODULES : props.labels.LBLUNITS }</span>
                {(timeValue !== `0 ${props.labels?.LBLMIN}` || 0 )?
                <>
                <img src="/images/time.png" className={styles.bannerIcon}/>
                <span className={styles.unitsLangText}>{calcTime()}</span>
                </>
                :
                null
                }
                <img src="/images/Lan.png" className={styles.bannerIcon}/>
                <span className={styles.unitsLangText}>{getLanguage(props.resultData && props.resultData[0]?.languagecode)}</span>
                <div style={{ display: "flex" }}>
                </div>
            </div>

            <p className={styles.productDesc}>
            {productDetails?.productDescription}
            </p>
            {whiteBeltCompleted ?
                <div className={styles.karateImgContainer}>
                        {props?.gamificationDetails?.length > 0 && props.gamificationDetails.map((item, index) => (
                                item.earned_badge === 2 ?
                                <span key={index} className={`${styles.circleContainer} ${index === 0 ? styles.circleContainerOne : ''}`}>
                                    <img className={styles.circle} src={beltOuter[item.badge_animation]} alt="Circular Image" />
                                    <div className={index === 0 ? styles.gifOne : styles.gif}>
                                        <img className={styles.karateImage} src={require(`../../../assets/images/karate/Badges/${item.badge_animation}.gif`)} alt="Badge Image" />
                                    </div>
                                </span>
                                :
                                <div className={styles.circleEmptyContainer}></div>
                            )
                        )}
                </div>
                :
                null
            }
            </div>
       </div>
        </div>
    )
}

export default AboutProductBanner;
import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import DragAndDrop from "../../components/upload/DragAndDrop.js";
import CompanyLogo1 from "../../components/companylogo/companylogo.js";
import PostImage from "../../components/postimage/postimage";
import { S3MultiUpload } from "../../../shared/s3upload";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import utils from "../../../utils/utils";
import styles from "./globalsettings.module.scss";

class EditCompanyLogo extends Component {
  constructor(props) {
    super(props);
    this.fileUploaderInput = HTMLElement | null;
    this.state = {
      load: true,
      company_logo: props.company_logo,
      attachedFiles: [],
      feedPostBtnStatus: 0,
    };
    this.fileAttachRef = React.createRef();
  }

  saveSettings = (filename) => {
    let data = {
      company_logo: filename,
    };
    HttpInterceptor.post("setcompanylogo", data).then(
      (res) => {
        if (res) {
          this.props.ShowToast();
        }
      },
      (err) => {
        if (
          err.response.data.errorType === 2 ||
          err.response.data.errorType === 2.1
        ) {
          this.setState({
            errorType: err.response.data.errorType,
          });
        }
      }
    );
  };

  handleDrop = (files) => {
    // console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF", files);
    this.setState({ file: files[0] });
    this.state.attachedFiles.splice(0, 1, files[0]);
    if (files[0].type.match("image.*")) {
      this.getAttachedImages(files[0]);
    }
    this.checkFileAttacheBtn();
    this.feedPostBtnStatus();
  };

  onSelectFileClick = () => {
    this.fileUploaderInput && this.fileUploaderInput.click();
  };

  onFileChanged = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ file: event.target.files[0] });
    }
  };
  openBrowseWindow = () => {
    this.setState({
      showEmoji: 0,
    });
    this.fileAttachRef.current.click();
  };
  getAttachedImages = (fileObj) => {
    this.setState({
      attachedFiles: this.state.attachedFiles,
    });
  };
  getAttachedVideo = (fileObj) => {
    this.setState({
      attachedFiles: this.state.attachedFiles,
    });
  };
  checkFileAttacheBtn = () => {
    let flag = this.state.attachedFiles.length >= this.maxNoOfAssetPOst ? 0 : 1;
    this.setState({
      fileAttacheBtnEnable: flag,
    });
  };
  feedPostBtnStatus = () => {
    this.setState({
      feedPostBtnStatus: this.state.attachedFiles.length ? 1 : 0,
    });
  };
  fileAttachOnChangeHandler = (evt) => {
    if (evt && evt.target && evt.target.files && evt.target.files.length) {
      for (let i = 0; i < evt.target.files.length; i++) {
        this.state.attachedFiles.splice(0, 1, evt.target.files[i]);
        if (evt.target.files[i].type.match("image.*")) {
          this.getAttachedImages(evt.target.files[i]);
        }
      }

      this.checkFileAttacheBtn();
    }
    this.feedPostBtnStatus();
  };
  createPostHandler = (evt) => {
    this.state.attachedFiles.map((item) => {
      let postAsset = {};
      let timeStamp = new Date().getTime();
      postAsset.asset_name = utils.generateFeedsPostFileName(item.name);
      postAsset.asset_type = item.type.match("image.*") ? "image" : "";
      if(postAsset.asset_type === 'image'){
        this.uploadAssets(item, timeStamp, postAsset);
      }
    });
  };
  addTimeStampToFileName = (fName, timeStamp) => {
    let fileName = fName.split(".");
    fileName[fileName.length - 2] = fileName[fileName.length - 2] + timeStamp;

    return fileName.join(".");
  };

  uploadAssets = (data, timeStamp, uploadFileInfo) => {
    this.setState({
      uploadingSize: this.state.uploadingSize + data.size,
    });
    // console.log(this.state.uploadingSize, this.state.uploadedSize);

    let folderPath = this.props.companyId + "/";
    let s3Upload = new S3MultiUpload(
      data,
      uploadFileInfo.asset_name,
      timeStamp,
      folderPath
    );
    s3Upload.start();
    s3Upload.onProgressChanged = (uploadedSize, totalSize, bitrate) => {
      // console.log(uploadedSize, totalSize);
    };
    s3Upload.onUploadCompleted = async (data) => {
      // console.log("@@@@@@@@@@@@@@@@@@@@@%%%%%%%%%%%%%%%%", data);
      this.saveSettings(uploadFileInfo.asset_name);
      // console.log(data, this.state.feedsData1);
      // console.log(this.state.upload_completed, this.state.uploaded.length);
    };
  };
  removePostAssets = (index) => {
    this.state.attachedFiles = [];
    this.setState(
      {
        attachedFiles: [],
      },
      this.feedPostBtnStatus()
    );
  };
  render() {
    const { labels } = this.props.props.labels;
    // console.log("QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ", this.props.company_logo);
    return (
      <div>
        <Modal.Header closeButton className='alert-modal-header'>
          <Modal.Title  className="alert-modal-title">
            {" "}
            <h4 className="modal-title alert-modal-title" id="exampleModalLongTitle">
              {labels.LBLCOMPANYLOGO}
            </h4>
          </Modal.Title>
        </Modal.Header>

        <form className="form">
          <Modal.Body>
            <div className="modal-body">
              <div>
                <div class="rounded spx-3 text-center" style={{}}>
                  <div class="row">
                    <div
                      class="col-md-8"
                      style={{
                        border: "2px dashed red",
                        borderRadius: "4px",
                        padding: "10px 10px 20px 10px",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div style={{ height: "75px" }}>
                          {this.state.company_logo !== "" ? (
                            <CompanyLogo1
                              imageName={this.state.company_logo}
                              imgWidth="100%"
                              imgHeight="75px"
                            />
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </div>
                      <div className={styles.Dragcontain}
                      >
                        <DragAndDrop handleDrop={this.handleDrop}>
                          <div className={styles.DragDrop}
                          >
                            <ul class="list-unstyled mt-3 mb-6">
                              <li style={{ margin: "5px" }}>
                                <i> {labels.LBLDRAGNDROP}</i>
                              </li>
                              <li style={{ margin: "5px" }}>
                                <i> {labels.LBLOR}</i>
                              </li>
                              <li style={{ margin: "5px" }}>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={this.openBrowseWindow}
                                  title="Photo or Video"
                                >
                                  <input
                                    type="file"
                                    ref={this.fileAttachRef}
                                    hidden
                                    accept="image/*"
                                    onChange={this.fileAttachOnChangeHandler}
                                  />
                                  <span className="button-style primary-btn">
                                    {labels.LBLBROWSE}
                                  </span>
                                </div>
                              </li>
                              <li style={{ margin: "15px" }}>
                                <hr class="d-none d-md-block" />
                              </li>
                              <li>
                                <div className="ssi-feeds-postAssetsContainerWrapper">
                                  {this.state.attachedFiles.map(
                                    (fileObj, index) => {
                                      return (
                                        <PostImage
                                          key={fileObj.name}
                                          index={index}
                                          imageObj={fileObj}
                                          removePostAssets={
                                            this.removePostAssets
                                          }
                                          labels={this.props.labels}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </DragAndDrop>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8" style={{ padding: "10px" }}>
                      {labels.LBLLOGORECOMMENDEDSIZE}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="text-left"
                style={{
                  borderLeft: "4px solid grey",
                  marginLeft: "-15px",
                  paddingLeft: "10px",
                  paddingTop: "20px",
                }}
              >
                <b>{labels.LBLNOTE}</b>
                <ul>
                  <li>{labels.LBLSUPRTDFILEFORMAT} </li>
                  <li>{labels.LBLIMGSIZELIMIT}</li>
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ float: "right" }}>
              <button
                type="button"
                className="button-style secondary-btn"
                id="btnaddCancel"
                onClick={this.props.onCancel}
              >
                {labels.LBLCANCEL}
              </button>
              <button
                style={{ marginLeft: "10px" }}
                type="button"
                className={
                  this.state.feedPostBtnStatus === 1
                    ? " button-style primary-btn"
                    : "button-style secondary-btn"
                }
                onClick={this.createPostHandler}
              >
                {labels.LBLUPLOAD}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
  };
};

export default connect(mapStateToProps, null)(EditCompanyLogo);

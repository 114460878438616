import React, { useState, useEffect, useRef } from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { Modal } from "react-bootstrap";
import "./classroom.scss";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@material-ui/core";
import emptyImage from "../../../assets/images/nearmiss_emptyst.png";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import moment from "moment";
import FilterComponent from "./FilterComponent";
import UsersService from "../user-list/UsersService";
import { DataGrid } from '@mui/x-data-grid';
import TraingCompletionHeader from "../../../common_components/datatable/TrainingCompletionHeader";
import  "../../../assets/scss/datatable.scss";
import styles from "./classroom.module.scss";

const TraingCompletion = (props) => {
  const [productList, setProductList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [dateformat, setDateformat] = useState(props.dateformat);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [prevSelectedUnit, setPrevSelectedUnit] = useState(null);  
  const [selected_status, setSelected_Status] = useState("");
  const [helperDate, setHelperDate] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersCount, setSelectedUsersCount] = useState(0);
  const [childNode,setChildNode]=useState(null)
  const [showToast, setShowToast] = useState({
    show: false,
    alertClass: "",
    status: "",
    message: "",
    changeInToast: new Date(),
    input: "",
  });
  const [toFilters, setToFilters] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [allFilterValues, setAllFilterValues] = useState([]);
  const [showSurvey, setShowSurvey] = useState(true);
  const [selectedMultiArrIds, setSelectedMultiArrIds] = useState([]);
  const [selectedMultiArrNames, setSelectedMultiArrNames] = useState([]);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [visibleRowsSelected, setVisibleRowsSelected] = useState(false);
  const [pageState, setPageState] = useState({ // Loading data in the datagrid component
    isLoading: false,
    data: [], // rows
    total: 0 // total count of data grid we need to show
  })
  const [paginationModel, setPaginationModel] = useState({ // pagination variables
    page: 0,
    pageSize: 10,
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [disableSubmit,setDisableSubmit] = useState(true);
  const wrapperRef = useRef(null);
  const [sequentialAccessUnit,setSequentialAccessUnit]=useState(0)
  const [resultData,setResultData] = useState([]);
  const [sortModel,setSortModel] = useState([]);

  useEffect(() => {
    // fetchData function
    const fetchData = async () => {
      try {
        const getData = {
          language: props.language,
        };
        const response = await UsersService.usersHelpers(getData);
        keyvaluechanges(response);
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchData();
    getProducts(); // Call getProducts function
    // Cleanup function
  }, [props.language, props.labels.labels]);

  useEffect(() => {
    settingdateformat(); // Call setDateFormat function
  },[props.dateformat])

  const handleClickOutside = (event) => {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowFilter(false);
    }
  };
  useEffect(() => {
    const handleClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClickOutside]);
  useEffect(() => {
    if (selectedUnit !== null) {
      getUsersList();
      setPaginationModel({ // pagination variables
        page: 0,
        pageSize: 10,
      });
      setRowSelectionModel([]);
      setSelectedUsersCount(0);
    }
  }, [selectedUnit,prevSelectedUnit])

  useEffect(() => {
    if (selectedProduct !== null) {
      getUnits();
    }
  }, [selectedProduct])

  useEffect(() => {
    // if(searchValue !== ""){
    filterHandler();
    // }
  }, [searchValue])

  useEffect(() => {
    const fetchData = () => {
      setPageState(({ isLoading: false, data: filteredUsers.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page + 1) * paginationModel.pageSize)), total: filteredUsers.length }))
    }
    fetchData();
  }, [paginationModel.page, paginationModel.pageSize, filteredUsers]);

  useEffect(() => {
      if (columns) {
        createColumns();
      }
  },[showSurvey,selectedProduct,selectedUnit])

  const getProducts = () => {
    HttpInterceptor.get("clsproducts", null).then((res) => {
      clearAllSelectedValues();
      setProductList(res.data);
      setSelectedUnit(null);
      setUsersList([]);
      setFilteredUsers([]);
      setAllFilterValues([]);
      setSearchValue("");
      setUnitList([]);
      setResultData([]);
      setSelectedUsers([]);
      setSelectedUsersCount(0);
    });
  };
  const getUnits = () => {
    HttpInterceptor.get(
      "productunits/" +
      selectedProduct.productid,
      null
    ).then((res) => {
      let SequentialAccessUnit=res.data[1][0].sequential_access_unit
      let cNode = res.data[2][0].childNode
      res.data=res.data[0];
      clearAllSelectedValues();
      setUnitList(res.data);
      setSelectedUnit(res.data.length === 1 ? res.data[0] : null);
      setUsersList([]);
      setResultData([]);
      setSelectedUsers([]);
      setSelectedUsersCount(0);
      setFilteredUsers([]);
      setAllFilterValues([]);
      setSearchValue("");
      setSequentialAccessUnit(SequentialAccessUnit);
      setChildNode(cNode)
    });
  };
  const getUsersList = () => {
    let prev_unit = prevSelectedUnit && sequentialAccessUnit ===1
      ? prevSelectedUnit.unit_id
        : selectedUnit.unit_id;

    HttpInterceptor.get(
      "productassignedusers/" +
      selectedUnit.product_id +
      "/" +
      selectedUnit.unit_id +
      "/" +
      prev_unit,
      {}
    ).then((res) => {
      let userdata;
      if (res.data && res.data.length > 0) {
        if (res.data[0].fieldCount !== undefined) {
          userdata = res.data[3];
        } else {
          userdata = res.data;
        }
      } else userdata = res.data;
      let hideSurvey = userdata.every((itm) => {
        return itm.learning_object_name == null;
      });
      setUsersList(userdata);
      setResultData(userdata);
      setFilteredUsers(userdata);
      setPageState({ isLoading: false, data: userdata.slice((paginationModel.page * paginationModel.pageSize), ((paginationModel.page + 1) * paginationModel.pageSize)), total: userdata.length });
      //console.log(!hideSurvey,"hideSurvey")
      setShowSurvey(!hideSurvey);
    });
  };
  const settingdateformat = () => {
    let newDateFormat;
    const locFormat = dateformat;

    if (locFormat === "DD/MM/YYYY") {
      newDateFormat = "dd/MM/yyyy";
    } else if (locFormat === "MM/DD/YYYY") {
      newDateFormat = "MM/dd/yyyy";
    } else if (locFormat === "YYYY/MM/DD") {
      newDateFormat = "yyyy/MM/dd";
    } else {
      newDateFormat = "dd/MM/yyyy";
    }

    setDateformat(newDateFormat);
  };
  const createColumns = () => {
    const {
      LBLPQ,
      LBLCOMPLETEDDATE,
      LBLINCOMPLETED,
      LBLSTATUS,
      LBLEMAILID,
      LBLNAME,
      LBLCOMPLETED,
    } = props.labels.labels;
    const cols = [
      {
        headerName: LBLNAME,
        field: "name",
        sortable: true,
        width: "150",
        renderCell: (row) => {
          return (
            <div
              data-toggle="tooltip"
              title={row.row.name}
              className="grid-ellipsis"
            >
              <span className="rle-brdr">{row.row.name}</span>
              <span className="show-sm-xs"></span>
            </div>
          );
        },
      },
      {
        headerName: LBLEMAILID ,
        field: "email",
        sortable: true,
        width: "300",
        renderCell: (row) => {
          return (
            <div
              data-toggle="tooltip"
              className="grid-ellipsis"
              title={row.row.email}
            >
              <span className="rle-brdr">{row.row.email}</span>
              <span className="show-sm-xs"></span>
            </div>
          );
        },
      },
      {
        headerName: LBLSTATUS,
        field: "status",
        sortable: true,
        width: "150",
        renderCell: (row) => {
          return (
            <div
              className="hde-mail-icn"
              data-toggle="tooltip"
              title={row.row.email}
            >
              <i
                style={{ display: row.row.status ? "" : "none" }}
                title={
                  props.labels.labels[
                  ("lbl" + (row.row.status !== "Not Started" ? row.row.status : "NOTSTARTED")).toUpperCase()
                  ]
                }
              ></i>
              <span className="rle-brdr">
                {
                  props.labels.labels[
                  ("lbl" + (row.row.status !== "Not Started" ? row.row.status : "NOTSTARTED")).toUpperCase()
                  ]
                }
              </span>
            </div>
          );
        },
      },
      {
        headerName: LBLCOMPLETEDDATE,
        field: "completed_date",
        sortable: true,
        width: "150",
        renderCell: (row) => {
          return (
            <div data-toggle="tooltip" title={row.row.completed_date !== ""
            ? moment(row.row.completed_date).format(props.dateformat)
            : null}>
              <span className="rle-brdr">
                {row.row.completed_date !== ""
                  ? moment(row.row.completed_date).format(props.dateformat)
                  : null}
              </span>
            </div>
          );
        },
      },
      {
        headerName: LBLPQ,
        field: "trigger_status",
        sortable: true,
        width: "200",
        renderCell: (row) => {
          return (
            <div className={row.row.trigger_status && row.row.trigger_status === 3  ? styles.completedPQ : styles.incompletedPQ } data-toggle="tooltip" title={row.email} >
              {/* onClick={row.row.trigger_status !== 3  ?  () => manualRecordSurvey(row.row)  : () => {}} */}
              <i style={{ display: row.row.trigger_status ? "" : "none" }}>
              <span className="rle-brdr" >
                {row.row.trigger_status && row.row.trigger_status === 3
                  ? LBLCOMPLETED
                  : row.row.trigger_status !== null &&  LBLINCOMPLETED}
              </span>
              </i>
            </div>
          );
        },
      },
    ];

    if (showSurvey === false) {
      cols.splice(4, 1);
      cols[0].width = "200"
      cols[1].width = "300";
      cols[2].width = "200";
      cols[3].width = "200";
    }
    setColumns(cols);
  };
  
  const manualRecordSurvey = (row) => {
    let xurl = window.location.href;
    let launchmode = 0;
    xurl =
      xurl.substr(0, xurl.lastIndexOf("/")) +
      "/safestart-survey-manual#" +
      (row.encref || "") +
      "_" +
      launchmode;
    if (props.location.pathname.indexOf("/schome/") > -1) {
      props.history.push({
        pathname: "/schome/safestart-survey-m",
        state: {
          productid: row.product_id,
          unitid: selectedUnit.unit_id,
          userid: row.user_id,
          activity_id: row.activity_id,
          passvars: xurl,
        },
      });
    } else {
      props.history.push({
        pathname: "/home/safestart-survey-m",
        state: {
          productid: row.product_id,
          passvars: xurl,
        },
      });
    }
  };
  const handleInputChange = (e) => {
    // Prevent updating the input field when typing
    e.preventDefault();
  };
  const openSaveModal = () => {
    let showUpdateStatusVal = true;
    let status = {};
    let finalstatus = [];
    //score = selected_score == "" ? null : selected_score;
    status =
      selected_status === "" || selected_status === undefined
        ? null
        : selected_status == 2
          ? "Completed"
          : "Inprogress";

    finalstatus = status;
    if (!finalstatus) {
      return false;
    } else if (helperDate !== "") {
      return false;
    }
    if (status === "Inprogress") {
      showUpdateStatusVal = false;
      setSelectedUsersCount(0);
      updateClassRoomDetails();
    } else {
      setShowUpdateStatus(showUpdateStatusVal)
    }
  };
  const handleDateChange = (date) => {
    let helperDateVal = ""
    if (date === "Invalid Date" || date === null) {
      helperDateVal = props.labels.labels.LBLVLDDATE;
    } else if (date > new Date()) {
      helperDateVal = props.labels.labels.LBLGRTDATE;
    }
    setSelectedDate(date);
    setHelperDate(helperDateVal);
    // setError(errorVal);
  };
  const recordHandleCancel = () => {
    setSelectedDate(new Date())
    setDisableSubmit(true);
  }
  const updateClassRoomDetails = () => {
    let score,
      data = {};
    let userid,
      finalstatus,
      trainingtype,
      selusers = [];
    finalstatus = selected_status == 2 ? "Completed" : "Inprogress";
    let unit_id = selectedUnit.unit_id;
    selectedUsers.map((x) => selusers.push(x.user_id));
    selusers = selusers.join(",");    
    userid = props.userId;
    trainingtype = "Classroom";
    data = {
      user_id: selusers,
      product_id: selectedProduct.productid,
      unit_id: unit_id,
      training_type: trainingtype,
      score: score,
      status: finalstatus,
      final_status: finalstatus,
      updated_by: userid,
      updated_on: new Date(),
      training_time: null,
      training_id: "CLSRMTRN-ONL-001",
      completed_date: selectedDate,
      created_by: "",
      created_on: "",
      training_reset_id: 0,
      
    };
    HttpInterceptor.post("updateclassroom", { data }).then((res) => {
      // setLoading(true);
      setSelectedUsersCount(0);
      setDisableSubmit(true);
      setRowSelectionModel([]);
      setPaginationModel({
        page: 0,
        pageSize: 10
      })
      resetFilterValues();
      if (res.data.errno > 0) {
        // setLoading(false);
      } else {
        getUsersList();
        setShowUpdateStatus(false);
        setShowToast({
          show: true,
          alertClass: "success",
          status: "Success",
          message: props.labels.labels.LBLCLASSSROOMCORESUCC,
          changeInToast: new Date(),
        })
        setSelectedDate(new Date());
        setSelected_Status("");
        setTimeout(() => {
          setShowToast({
            show: false,
            alertClass: "",
            status: "",
            message: "",
            changeInToast: new Date(),
          })
        }, 1500);
      }
    });
  };
  const closesavemodal = () => {
    setShowUpdateStatus(false);
    recordHandleCancel();
  };

  const handleSortModelChange = (sortModel) => {
    console.log("sortModelstart",sortModel)
      setSortModel(sortModel);
      const field = sortModel[0]?.field ;
      let direction = sortModel[0]?.sort ;
      let sortedData = [...filteredUsers];
       if(direction === undefined) {
        sortedData =  (selectedMultiArrNames.length > 0 || searchValue !== "") ? [...filteredUsers] : [...resultData];
       }
       console.log("sortedData",sortedData)
       sortedData.sort((a, b) => {
        const valueA = a[field] === null ? "" : a[field]; // Convert null to empty string
        const valueB = b[field] === null ? "" : b[field]; // Convert null to empty string
        if (direction === 'asc') {
            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB);
            } else {
                return valueA - valueB;
            }
        } else {
            if (typeof valueB === 'string') {
                return valueB.localeCompare(valueA);
            } else {
                return valueB - valueA;
            }
        }
    });
    
    console.log("sortedData", sortedData.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page+1) * paginationModel.pageSize)));
    setPageState( ({ isLoading: false, data: sortedData.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page+1) * paginationModel.pageSize)), total: sortedData.length }))
    // setUsersList(sortedData);
    setUsersList((selectedMultiArrNames.length === 0 || searchValue === "") ? sortedData : filteredUsers);
  };
  const searchUser = (e) => {
    setPaginationModel({
      page:0,
      pageSize:10
   })
    setSearchValue(e.target.value);
    filterHandler("");
  };
  const keyvaluechanges = (response) => {
    let keys1,
      keys2,
      allGroups,
      objAllGroups = [];
    let groupname,
      grouptypeid,
      objRoles,
      singles = [];
    let objStatus = [
      { name: "LBLNOTSTARTED", id: "0" },
      { name: "LBLINPROGRESS", id: "2" },
      { name: "LBLCOMPLETED", id: "3" },
    ];
    allGroups = response.groups;
    objAllGroups.push({
      items: JSON.stringify(objStatus),
      itemsinfo: {
        itemid: "coursestatus",
        itemname: "LBLSTATUS",
        label: "LBLSTATUS",
        trans: true,
      },
    });
    if (typeof allGroups == "object") {
      keys1 = Object.keys(allGroups);
      allGroups.map((element) => {
        if (typeof element == "object") {
          keys2 = Object.keys(element);

          if (typeof element.groups_by_type != "undefined") {
            if (typeof element.group_info == "string") {
              grouptypeid = JSON.parse(element.group_info).group_type_id;
              groupname = JSON.parse(element.group_info)["lbl_" + grouptypeid];
            } else if (typeof element.group_info == "object") {
              grouptypeid = element.group_info.group_type_id;
              groupname = element.group_info["lbl_" + grouptypeid];
            } else {
              groupname = "Group";
            }
            let groups = "";
            if (typeof element.groups_by_type == "string") {
              groups = element.groups_by_type
                .split('"group_id":')
                .join('"id":')
                .split('"group_name":')
                .join('"name":');
              JSON.parse(
                objAllGroups.push({
                  items: groups,
                  itemsinfo: {
                    itemid: grouptypeid,
                    itemname: groupname,
                    label: groupname,
                  },
                })
              );
            } else {
              groups = JSON.stringify(
                element.groups_by_type
                  .split('"group_id":')
                  .join('"id":')
                  .split('"group_name":')
                  .join('"name":')
              );
              objAllGroups.push({
                items: groups,
                itemsinfo: {
                  itemid: grouptypeid,
                  itemname: groupname,
                  label: groupname,
                },
              });
            }
          }
        }
        return null;
      });
    }
    setToFilters({
      multi: objAllGroups,
      single: singles,
    });

    return objAllGroups;
    // response.groups[0].groups_by_type.split('"group_id":').join('"id":').split('"group_name":').join('"name":')
  };

  const filterHandler = (pAllFilterValues) => {
    let allFilterVal = pAllFilterValues;
    if (!pAllFilterValues) {
      allFilterVal = allFilterValues;
    } else {
      setAllFilterValues(pAllFilterValues === "reset" ? [] : pAllFilterValues);
    }

    if (allFilterVal.multiItems) {
      let courseStVal =
        allFilterVal.multiItems["coursestatus"].length === 0
          ? []
          : allFilterVal.multiItems["coursestatus"].map((item) => {
            let val =
              item.id == 0
                ? "not started"
                : item.id == 2
                  ? "inprogress"
                  : "completed";
            return val;
          });
      courseStVal = courseStVal.flat();
      let departmentIds = (!allFilterVal.multiItems['GP_2'] || allFilterVal.multiItems['GP_2'].length === 0) ? [] : allFilterVal.multiItems['GP_2'].map((item) => { return Number(item.id) });
      let groupIds = (!allFilterVal.multiItems['GP_3'] || allFilterVal.multiItems['GP_3'].length === 0) ? [] : allFilterVal.multiItems['GP_3'].map((item) => { return Number(item.id) });
      let locationIds = (!allFilterVal.multiItems['GP_1'] || allFilterVal.multiItems['GP_1'].length === 0) ? [] : allFilterVal.multiItems['GP_1'].map((item) => { return Number(item.id) });
      let shiftIds = (!allFilterVal.multiItems['GP_4'] || allFilterVal.multiItems['GP_4'].length === 0) ? [] : allFilterVal.multiItems['GP_4'].map((item) => { return Number(item.id) });

      let filtUsers = resultData.filter((item) => {
        let isStContained = false,
          isDeptContained = false,
          isGroupsContained = false,
          isLocsContained = false,
          isShiftContained = false,
          isSearchcontained = false;
        if (
          (departmentIds && departmentIds.length && item.user_groups && item.user_groups.split('~').some((val, ind) => {
            return departmentIds.includes(Number(val));
          }))
          ||
          !departmentIds.length
        ) {
          isDeptContained = true;
        }
        if (
          (groupIds.length && item.user_groups && item.user_groups.split('~').some((val, ind) => {
            return groupIds.includes(Number(val));
          }))
          ||
          !groupIds.length
        ) {
          isGroupsContained = true;
        }
        if (
          (locationIds.length && item.user_groups && item.user_groups.split('~').some((val, ind) => {
            return locationIds.includes(Number(val));
          }))
          ||
          !locationIds.length
        ) {
          isLocsContained = true;
        }
        if (
          (shiftIds.length && item.user_groups && item.user_groups.split('~').some((val, ind) => {
            return shiftIds.includes(Number(val));
          }))
          ||
          !shiftIds.length
        ) {
          isShiftContained = true;
        }
        if (
          (courseStVal.length &&
            courseStVal.includes(
              item.status ? item.status.toLowerCase() : "null"
            )) ||
          !courseStVal.length
        ) {
          isStContained = true;
        }
        if (
          !searchValue ||
          (searchValue &&
            ((item.name &&
              item.name.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.email &&
                item.email.toLowerCase().includes(searchValue.toLowerCase())) ||
              (item.status &&
                item.status
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())) ||
              (item.score &&
                item.score
                  .toString()
                  .includes(String(searchValue).toLowerCase()))))
        ) {
          isSearchcontained = true;
        }

        if (isSearchcontained && isStContained && isLocsContained && isDeptContained && isGroupsContained && isShiftContained) {
          return item;
        }
        return null;
      });
      setFilteredUsers(filtUsers);
      setPaginationModel({ // pagination variables
        page: 0,
        pageSize: 10,
      });
      // setFilteredSelected(true);
    } else if (searchValue) {
      let filtUsers = resultData.filter((item) => {
        if (
          (item.name &&
            item.name.toLowerCase().includes(searchValue.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(searchValue.toLowerCase())) ||
          (item.status &&
            item.status.toLowerCase().includes(searchValue.toLowerCase())) ||
          (item.score &&
            item.score.toString().includes(String(searchValue).toLowerCase()))
        ) {
          return item;
        }
        return null;
      });
      setFilteredUsers(filtUsers);
      // setFilteredSelected(false);
    } else {
      setFilteredUsers(usersList);
      // setFilteredSelected(false);
    }
  };
  useEffect(() => {
    let disableRows = 0;
    let totalavailableRowsPerpage = 0;
    let totalavailableRows = 0;
    if(pageState.data.length > 0){
        pageState.data.map((item) => {
          if(item.status === "Completed" ) {
              disableRows++
          }else {
              totalavailableRowsPerpage ++
            }
            return null;
        });
        if(usersList.length > 0){
          usersList.map((item) => {
            if(item.status !== "Completed" ) {
              totalavailableRows++
            }
            return null;
          });
        }
        let selctedRowCount = 0 ;
        (pageState.data.length  && pageState.data.map((val, ind) => {
          if(rowSelectionModel.includes(val.user_id)){
              selctedRowCount ++;
          }
        let totalSelectedRows = selctedRowCount ;
        if(totalavailableRowsPerpage === 0) {
          setVisibleRowsSelected(false)
        } else if (totalavailableRowsPerpage === totalSelectedRows && totalavailableRows !== selectedUsersCount) {
          setVisibleRowsSelected(true)
        } else {
          setVisibleRowsSelected(false)
        }
        return null;
      }))
    }
  },[pageState.data,rowSelectionModel,selectedUsersCount,usersList])
  const handlepaginationModalChange = (paginationVal) => {
    setPaginationModel(paginationVal);
  }
  const handleSelectionModelChange = (newRowSelectionModel) => {
    const filteredArray = newRowSelectionModel.filter(item => item !== false);
    setRowSelectionModel(newRowSelectionModel);
    setSelectedUsersCount(filteredArray.length)
    const selectedRows = newRowSelectionModel.map((id) =>
      usersList.find((row) => row.user_id === id)
    );
    setSelectedUsers(selectedRows)
  };
  const rowDisabledCriteria = (row) => {
    // console.log(row,"row.isAssigned")
    if (row.row.status !== "Completed") {
      return true;
    }
  };
  const handleClear = () => {
    setRowSelectionModel([]);
    setSelectedUsersCount(0);
  };
  const handleSelectAll = () => {
    const selecteRowsData = filteredUsers;
    setSelectedUsers(selecteRowsData)
    //     console.log("aaaaaaaaaaaaaaaabcccc")
    const userIds = filteredUsers.map((item) => item.status !== "Completed" && item.user_id);
    setRowSelectionModel(userIds);
    setSelectedUsersCount(filteredUsers.filter((item) => item.status !== "Completed").length)

  }
  const resetFilterValues = () => {
    filterHandler("reset");
    setSelectedMultiArrNames([]);
    setSelectedMultiArrIds([]);
    setAllFilterValues([]);
    setSearchValue("");
    setFilteredUsers(resultData);
    setUsersList(resultData);
  };
  const clearAllSelectedValues = () => {
    filterHandler("reset");
    setSelectedMultiArrNames([]);
    setSelectedMultiArrIds([]);
    setAllFilterValues([]);
    setSearchValue("");
  }
  const clearFilterByName = (filterObj) => {
    console.log("asuyvbhjkw")
    let selMultiArrNames = [...selectedMultiArrNames];
    let selMultiArrIds = [...selectedMultiArrIds];
    let allFilterVal = { ...allFilterValues };
    if (allFilterVal.multiItems && selectedMultiArrNames.length) {
      Object.keys(allFilterVal.multiItems).map((key) => {
        if (allFilterVal.multiItems[key].length && allFilterVal.multiItems[key].indexOf(filterObj) > -1) {
          allFilterVal.multiItems[key].splice(allFilterVal.multiItems[key].indexOf(filterObj), 1);
          let fObjIndex = selMultiArrNames.indexOf(filterObj)
          selMultiArrNames.splice(fObjIndex, 1);
          selMultiArrIds.splice(fObjIndex, 1);
        }
        return null;
      })
      setSelectedMultiArrNames(selMultiArrNames);
      setSelectedMultiArrIds(selMultiArrIds);
      setAllFilterValues(allFilterVal);
      filterHandler(allFilterVal);
    }
  }
  const handleClick = () => {
    setShowFilter(true);
  };
  const handleSelectedIds = (ids) => {
    setSelectedMultiArrIds(ids);
    // setFilteredSelected(ids.length > 0);
  };

  const handleSelectedNames = (names) => {
    setSelectedMultiArrNames(names);
  };
  const handleStatusChange = (e) => {
    setSelected_Status(e);
    // setHelperScore("");
    setDisableSubmit(false);
  };
  const handleChangeUnits = (e, product) => {
    setSelectedProduct(product);
  };
  const {
    LBLCLASSROOM,
    LBLCANCEL,
    LBLOK,
    LBLNORECORDS,
    LBLUNITS,
    LBLPRODUCTS,
    LBLNOOPTIONSDROPDOWN,
    LBLCNFALERT,
    LBLCOURSECOMPLETION,
    LBLMODULES
  } = props.labels.labels;
  
  // columns, filteredUsers, selectedUsers,
  return (
    <>
      {
        <div className="mob-pad cls_class_rooms">
          <React.StrictMode>
            <ToastMessage
              show={showToast["show"]}
              helpers={showToast}
            ></ToastMessage>
            <Modal show={showUpdateStatus} onHide={closesavemodal}>
              <Modal.Header closeButton className='alert-modal-header'>
                <Modal.Title className="alert-modal-title">
                  {LBLCOURSECOMPLETION}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="alert-modal-body">{LBLCNFALERT}</div>
              </Modal.Body>
              <Modal.Footer style={{borderTop:"none"}}>
                    <button
                      type="button"
                      className="button-style secondary-btn"
                      onClick={closesavemodal}
                    >
                      {LBLCANCEL}
                    </button>
                    <button
                      type="button"
                      className="button-style danger-btn"
                      onClick={updateClassRoomDetails}
                    >
                      {LBLOK}
                    </button>
              </Modal.Footer>
            </Modal>
            <div >
              <h3 className="page-title">{LBLCLASSROOM}</h3>
            </div>
            <hr className="head-line"/>
              <div>
                <div
                  id="course_section"
                  className={`${styles.clsCourseSection}`}
                  // style={{}}
                >
                  <div className={`col-xl-4 ${styles.filterWthLabel}`}>
                    <label className="label" style={{marginBottom:"0px"}}>{LBLPRODUCTS}</label>
                    <Autocomplete
                      id="courseTypes"
                      className="filter-drpdwn"
                      aria-required="true"
                      required
                      disableClearable
                      noOptionsText={LBLNOOPTIONSDROPDOWN}
                      options={productList}
                      style={{ width: "400px" }}
                      getOptionLabel={(option) => option.name}
                      value={selectedProduct}
                      onChange={handleChangeUnits}
                      renderOption={(props,option) => (
                        <div {...props} style={{ fontSize: "14px" }}>{option.name}</div>
                      )}
                      renderInput={(params) => (
                        <div>
                          <TextField
                            variant="standard"
                            placeholder={props.labels.labels.LBLSELECT}
                            onChange={handleInputChange}
                            {...params}
                          />
                        </div>
                      )}
                    />
                  </div>

                  {unitList.length > 1 ? (
                    <div className={`col-xl-4 ${styles.filterWthLabel}`}>
                      <label className="label" style={{marginBottom:"0px"}}>{(childNode && childNode === 'modules' ? LBLMODULES : LBLUNITS)}</label>
                      <Autocomplete
                        id="combo-box-demo"
                        className="filter-drpdwn"
                        aria-required="true"
                        required
                        disableClearable
                        noOptionsText={LBLNOOPTIONSDROPDOWN}
                        options={unitList}
                        style={{ width: "400px" }}
                        getOptionLabel={(option) => option && option.title}
                        getOptionSelected={(option, values) => {
                          return option.unit_id === values.unit_id;
                        }}
                        value={selectedUnit}
                        onChange={(e, units) => {
                          setPrevSelectedUnit(units.length === 1 ? units[0] : unitList[unitList.indexOf(units) - 1]);
                          setSelectedUnit(units);
                          setUsersList([]);
                          setFilteredUsers([]);
                          setAllFilterValues([]);
                          // setFilteredSelected(false);
                          setSearchValue("");
                        }}
                        renderOption={(props,option) => (
                          <div {...props} style={{ fontSize: "14px" }}>{option.title}</div>
                        )}
                        renderInput={(params) => (
                          <div>
                            <TextField
                              variant="standard"
                              placeholder={props.labels.labels.LBLSELECT}
                              {...params}
                            />
                          </div>
                        )}
                      />
                    </div>
                  ) : null}
                </div>
                {usersList.length ? (
                  <>
                    <div className={`${styles.clsTableFilter}`}>
                      <div className={styles.filterall}>
                        <div className={styles.clsFilterIcnctn}>
                          {selectedMultiArrIds.length ? (
                            <div className={styles.clsSelFilters}>
                              <span></span>
                            </div>
                          ) : null}
                          <button
                            title={props.labels.labels.LBLFILTER}
                            data-toggle="modal"
                            data-target="#myModal"
                            type="button"
                            className={styles.basicSelItem}
                            onClick={handleClick}
                          >
                            <i
                              data-target="#myModal"
                              class="fa fa-filter"
                              aria-hidden="true"
                              style={{ marginRight: "4px" }}
                            ></i>
                            {props.labels.labels.LBLFILTER !== undefined
                              ? props.labels.labels.LBLFILTER
                              : "Filter"}
                          </button>
                        </div>
                        <div style={{ display: "flex" }}>
                          <input
                            type="text"
                            style={{ width: "auto"}}
                            className="input-field"
                            placeholder={props.labels.labels.LBLSEARCH}
                            value={searchValue}
                            onChange={searchUser}
                            onKeyDown={searchUser}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className={`${showFilter ? styles.clsUserlistFilter : styles.clsUserlistNofilter}`}>
                  <>
                  {usersList.length && showFilter ? (
                    <div className={styles.filterCompCtn} id="filterComp" ref={wrapperRef}> 
                      <FilterComponent
                        toFilters={toFilters}
                        labels={props.labels}
                        showHideFilter={(status) =>
                          setShowFilter(status)
                        }
                        filterChange={filterHandler}
                        resetFilterValues={resetFilterValues}
                        setSelectedMultiArrIds={handleSelectedIds}
                        setSelectedMultiArrNames={handleSelectedNames}
                        selectedMultiArrIds={selectedMultiArrIds}
                        selectedMultiArrNames={selectedMultiArrNames}
                        allFilterValues={allFilterValues}
                        currentPage = {"recordCompletion"}
                      />
                    </div>
                  ) : null}
                  <div style={{overflowX:"auto",minHeight:"350px",width:"100%"}}>                  
                  <div className="data-table-ctn">
                  {selectedMultiArrNames.length ? (
                    <div className={styles.selectedFilterCnt}>                      
                        <>
                          {selectedMultiArrNames.map((filter, index) => {
                            return (
                              <div className={styles.selectedFilter}>
                                <span>
                                  {props.labels.labels[filter.name] ||
                                    filter.name}{" "}
                                </span>
                                <i
                                  className={`fa fa-times ${styles.closeIcon}`}
                                  aria-hidden="true"
                                  onClick={() =>
                                    clearFilterByName(filter)
                                  }
                                ></i>
                              </div>
                            );
                          })}
                          <div className={styles.selectedFilter}>
                            <span>{props.labels.labels.LBLCLEARALL !== undefined ? props.labels.labels.LBLCLEARALL : "Clear All"} </span>
                            <i
                              className={`fa fa-times ${styles.closeIcon}`}
                              aria-hidden="true"
                              onClick={() => resetFilterValues()}
                            ></i>
                          </div>
                        </>
                    </div>
                  ) : null}
                  {selectedUsersCount > 0 &&
                      <TraingCompletionHeader
                        selectedRowsCount={selectedUsersCount}
                        labels={props.labels.labels}
                        handleRowChange={handleSelectAll}
                        handleClear={handleClear}
                        selectedRows={null}
                        dropObj={false}
                        showdropdown={true}
                        selectAll={props.labels.labels.LBLSELECTALLUSERS !== undefined ? props.labels.labels.LBLSELECTALLUSERS.replace("$$$","") : "Select All Users"}
                        visibleRowsSelected={visibleRowsSelected}
                        visiblerowsselectedLabel={props.labels.labels.LBLALLUSERSSELECTED !== undefined ? props.labels.labels.LBLALLUSERSSELECTED : `All users on this page are selected.`}
                        handleDateChange={handleDateChange}
                        dateformat={dateformat}
                        selectedDate={selectedDate}
                        handleStatusChange={handleStatusChange}
                        openSaveModal={openSaveModal}
                        showFilter = {showFilter}
                        recordHandleCancel = {recordHandleCancel}
                        disableSubmit = {disableSubmit}
                      />
                    }
                
                    {pageState.data.length > 0 ?
                      <DataGrid
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'name', sort: 'asc' }],
                        },
                      }}
                      onSortModelChange={(sortVal) => handleSortModelChange(sortVal)}
                      // initialState={{
                      //   sorting: {
                      //     sortModel: [{ field: 'name', sort: 'asc' }],
                      //   },
                      // }}
                        style={{ border: 'none' }}
                        className="cust-data-grid"
                        autoHeight
                        checkboxSelection
                        disableRowSelectionOnClick
                        isRowSelectable={rowDisabledCriteria} // matched condition rows only enabled
                        getRowId={(row) => row.user_id} // key value of the row item
                        columns={columns}
                        rows={pageState.data}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pageSizeOptions={[10, 15, 20, 25, 30]}
                        pagination
                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlepaginationModalChange}
                        onRowSelectionModelChange={handleSelectionModelChange}
                        rowSelectionModel={rowSelectionModel}
                        keepNonExistentRowsSelected
                        hideFooterSelectedRowCount
                        disableColumnMenu
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: props.labels.labels.LBLROWSPERPAGE + ":"
                          }
                        }}
                        sx={{
                          '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                          },

                          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                            outline: 'none',
                          },
                          '& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg path': {
                            display: "none"
                          },
                          '& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg': {
                            backgroundColor: "#cfcbcb",
                            width: "18px",
                            height: "18px",
                            borderRadius: "3px"
                          },
                        }}
                      />
                      :
                      <div style={{ marginTop: "5%" }}><EmptyState image={emptyImage} text2={LBLNORECORDS} /> </div>
                    }
                
                  </div>
                  </div>
                  </>
                </div>
              </div>
          </React.StrictMode>
        </div>
      }
    </>
  );
}
export default TraingCompletion;
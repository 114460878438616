import React, { useState } from "react";
import { MenuItem, Select, Box, } from "@mui/material";
import styles from "./ManagementHeader.module.scss";
import ViewModal from "./ViewModal";
import "./UserManagementHeader.css"

const UserManagementHeader = (props) => {
  const {
    LBLDELETE,
    LBLVIEW,
    LBLCLEAR,
    LBLUSERSSELECTED
  } = props.labels
  const [showList, setShowList] = useState(false);
  const handleView = () => {
    setShowList(true);
  }
  const closeMoreModal = () => {
    setShowList(!showList)
  }
  const containerTop = {
    marginTop: props.currentPage === "users" ? '0px' : '20px'
  }
  let Sitetype=localStorage.getItem('site_type');
  return (
    <>
      <div className={Sitetype==='SafeStart'?styles.container :styles.greencontainer } style={containerTop}>
        <ViewModal
          showList={showList}
          closeMoreModal={closeMoreModal}
          label={LBLUSERSSELECTED}
          selectedRows={props.selectedRows}
        />
        {props.currentPage !== "groups" ?
        <>
        <div className={Sitetype ==='SafeStart'? styles.selectedcount: styles.greenselectedcount}>{props.selectedRowsCount}
        </div>
        <span className={styles.coursesselected}>{props.usersSelectedLabel}</span>
        {props.currentPage === "users" ?
          <span className={"link-btn"} style={{marginLeft:"10px"}} onClick={props.handleClear}>{LBLCLEAR}</span>
          :
          <span className={"link-btn"} style={{marginLeft:"10px"}} onClick={handleView}>{LBLVIEW}</span>
        }
        </>
        :
        null
        }
        {props.currentPage !== "users" ?
          <>
            {props.currentPage !== "users" && props.currentPage !== "groups" && 
            <div className={styles.hrline}></div>
            }
            <div className={Sitetype==='SafeStart'?styles.selectedcount :styles.greenselectedcount}>{props.currentPageSelected}</div>
            <span className={styles.coursesselected}>{props.currentPageLabel}</span>
            <span className={"link-btn"} style={{marginLeft:"10px"}} onClick={props.handleClear}>{LBLCLEAR}</span>
          </>
          :
          null
        }
        <button className={Sitetype==='SafeStart'?styles.selectallbtn :styles.greenselectallbtn} onClick={props.handleRowChange}>{props.selectAll}</button>
        {props.currentPage === "users" &&
          <div className={styles.hrline} style={{marginRight:"0px"}}></div>
        }
        {props.currentPage === "users" && props.dropObj !== null &&
          <Box style={{ minWidth: 190, display: "flex" }}>
            {props.dropObj.map((item, index) => {
              return (
                <Select
                  labelId="select-status"
                  id="select-status"
                  value={item.selectedValue}
                  onChange={(e) => item.changeHandler(e)}
                  style={{
                    height: "40px", width: "190px", marginLeft: "20px",  color: "#FFF", 
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0,border:"none"},
                  }}
                  className={Sitetype ==='SafeStart'? styles.selectedactbtn:styles.greenselectedactbtn}
                  key={index}
                >
                  {item.data.map((subitem, subindex) => {
                    return (
                      <MenuItem key={subindex} style={{ fontSize: 14, display: subindex === 0 ? "none" : "block" }} value={subitem.value} 
                      disabled={subitem.diasabled ? !subitem.diasabled.length  : false}>
                        {subitem.lable}
                      </MenuItem>
                    )
                  }
                  )
                  }
                </Select>
              )
            }
            )
            }
          </Box>
        }
        {props.selectedRowsCount && (props.currentPage === "users" || props.currentPage === "groups") && !props.showDelete ? (
          <div className="page-header-field" style={{marginBottom:"0px"}}>
          {props.currentPage === "groups" && 
          <div className={styles.hrline} style={{marginRight:"0px"}}></div>
          }
            <div className="btn-align" style={{marginLeft:"20px"}}>
              <button
                className="button-style danger-btn"
                onClick={props.handleDelete}
                disabled={props.deleteDisable}
              >
                {LBLDELETE}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {props.visibleRowsSelected ?
        <div className={styles.visiblerowsselected}>
            {props.visiblerowsselectedLabel}
        </div>
        :
        null
      }
    </>
  );
}

export default UserManagementHeader;
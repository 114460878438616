import React from "react";
import "./profileimage.css";
import FeedsServices from "../../feedService/feedservices";

var originalFetch = require("isomorphic-fetch");
var fetch = require("fetch-retry")(originalFetch);

class ProfileImageDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      tagWidth: 35,
      tagHeight: 35,
      imageName: "",
      firstLetterDisplay: "",
    };
  }
  componentDidMount() {
    this.setState({
      tagWidth: this.props.width ? this.props.width : this.state.tagWidth,
      tagHeight: this.props.height ? this.props.height : this.state.tagHeight,
      imageName: this.props.imageName ? this.props.imageName : "",
      firstLetterDisplay: this.props.firstLetterDisplay
        ? this.props.firstLetterDisplay
        : "",
    });
    this.loadImageFromS3();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.imageName !== undefined &&
      this.props.imageName !== prevProps.imageName
    ) {
      this.loadImageFromS3();
    }
  }
  loadImageFromS3 = () => {
    if (this.props.imageName === undefined || this.props.imageName === "") {
      this.setState({
        imageUrl: "",
      });
    } else if (String(this.props.imageName).indexOf("../images/") !== -1) {
      this.setState({
        imageUrl: this.props.imageName,
      });
    } else if (this.props.imageName) {
      let assetFolder = String(this.props.imageName).split(".");
      assetFolder.pop();
      let postData = {
        filename: this.props.imageName,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      FeedsServices.feedsAPIServicesCall(
        "gets3signedassetsurl",
        "post",
        this.signedUrlResultHandler,
        postData
      );
      /*FeedsServices.getS3AssetsSignedURL({"filename": this.props.imageName, "assettype": "image"})
                .then(respnse => respnse.json())
                .then(result => {
                    this.setState({
                        imageUrl:result.data
                    })
                    console.log(result.data)
                })*/
    }
  };
  signedUrlResultHandler = (resultData) => {
    //console.log(resultData.data.data);
    let url = resultData.data.data;
    fetch(url, {
      retryOn: function (attempt, error, response) {
        if (response && response.status && response.status >= 403) {
          return false;
        }
      },
    }).then((response) => {
      if (response && response.status && response.status === 200) {
        this.setState({
          imageUrl: resultData.data.data,
        });
      }
    });
  };
  componentWillReceiveProps() {
    this.setState({
      firstLetterDisplay: this.props.firstLetterDisplay
        ? this.props.firstLetterDisplay
        : "",
    });
  }
  render() {
    const { tagWidth, tagHeight, imageUrl, firstLetterDisplay } = this.state;
    return (
      <div
        style={{ width: tagWidth, height: tagHeight }}
        className="ssi-feeds-profileImageContainer"
      >
        <div className="ssi-feeds-profileImageWrapper">
          {imageUrl ? (
            <div className="ssi-feeds-profileImageHolder">
              <img src={imageUrl} alt="" />
            </div>
          ) : !imageUrl && firstLetterDisplay === "" ? (
            this.props.placeHolderType !== undefined &&
            this.props.placeHolderType === "channel" ? (
              <div className="ssi-feeds-profileImageDefaultHolder">
                <span className="ssi-channelDefaultImage"></span>
              </div>
            ) : (
              <div className="ssi-feeds-profileImageDefaultHolder">
                <img src="../images/profile.png" alt="" />
              </div>
            )
          ) : (
            <div className="ssi-feeds-profileImageCaption">
              <span>
                {firstLetterDisplay.substring(0, 1).toLocaleUpperCase()}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProfileImageDisplay;

import { HttpInterceptor } from '../../../auth/HttpInterceptor';


class GroupServices {
    async getGroups(getData) {
        let url = 'groups';
        const response = await HttpInterceptor.get(url, { data: { "requestMethod": "getGroups", "requestLanguage": getData.language } });
        let syn_labels = await this.syncGrouplabels(response.data.groups);
        return syn_labels;
    }

    async syncGrouplabels(groups) {

        let group_types = require('../../../json_files/groups.json');
        let groups_labels = groups['group_types'].map(item => {
            let tt = groups['groups'].findIndex(item2 => {
                return item2.group_type_id === item
            })
            if (tt !== -1) {
                let parsed = JSON.parse(groups['groups'][tt].group_info);
                if (parsed === null) {
                    let obj = Object.assign({}, groups['groups'][tt]);
                    obj['translated'] = false;
                    obj.group_info = group_types[item]["group_type_id"] ? group_types[item] : JSON.parse(group_types[item]);
                    return obj;
                } else {
                    let obj = Object.assign({}, groups['groups'][tt]);
                    obj['translated'] = true;
                    obj.group_type_id = groups['groups'][tt].group_type_id;
                    obj.group_info = JSON.parse(groups['groups'][tt].group_info);
                    return obj;
                    // return groups['groups'][tt];
                }

            } else if (tt === -1) {
                let obj = {};
                // obj['group_info'] = group_types[item];
                obj['group_info'] = groups["labels"]["group_labels"][item];
                obj['group_type_id'] = item;
                obj['translated'] = true;
                return obj
            }
        })
        return groups_labels;

    }
    async updateGroup(data) {
        const response = await HttpInterceptor.put('groups', data);
        return response.data;
    }
    async addGroup(data) {
        const response = await HttpInterceptor.post('groups', data);
        return response.data;
    }
    async getUsers(data) {
        const response = await HttpInterceptor.post('groups/users', data);
        return response.data;
    }
    async deleteGroup(delete_data) {
        const response = await HttpInterceptor.delete('groups', { data: { delete_data } });
        return response.data;
    }
}

export default new GroupServices();

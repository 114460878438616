import React from "react";
import { Modal } from "react-bootstrap";
import ProfileImageDisplay from "../profileimage/profileimage";
import SeeMoreText from "../../../common_components/seemore_text/SeeMoreText";

class comments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentEditable: false,
            showFeedRemoveBtnPanner: 0,
            commentPostBtnStatus: 0,
            showAssetsViewer: 0,
            showAllComments: 0,
            feedRemovePermission: 1,
            descriptionText: "",
            openComments: false,
            isModalOpen: false,
            urlRegex: /(https?:\/\/[^\s]+)/gi,
            show: false,
            current_comment: this.props.comment.comment_text
        };
        this.commentRef = React.createRef();
        this.wrapperRef = React.createRef();
        this.urlRegex = /(https?:\/\/[^\s]+)/gi;
    }

    handleClickOutside = (event) => {
        console.log(this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target),"eventeventeventevent")
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showFeedRemoveBtnPanner: 0,
        });
        }
      };

    componentDidMount() {
        this.setState({ current_comment: this.props.comment.comment_text });
        document.addEventListener("mousedown", this.handleClickEdit);
    }
    
      componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickEdit);
      }
    
      handleClickEdit = (event) => {
        this.handleClickOutside(event);
      };

    onClickEditComments = () => {
        this.setState({
            contentEditable: true,
            showFeedRemoveBtnPanner: 0
        });
    }

    onClickDeleteComments = () => {
        this.props.deleteComments(this.props.comment._id, this.props.feed_id)
    }

    openModal = () => {
        this.setState({
            show: true
        })
    }

    closeModal = () => {
        this.setState({ show: false,showFeedRemoveBtnPanner: 0 })
    }

    cancel = () => {
        this.setState({ contentEditable: false, current_comment: this.props.comment.comment_text, })
    }

    onClickSaveComments = () => {
        this.setState({
            contentEditable: false
        });
        this.props.updateComments(this.state.current_comment, this.props.comment._id, this.props.feed_id)
    }
    removeBtnOutsideClickHandler = (evt) => {
        let elements = evt.target;  //document.getElementById("target");
        let els = [];
        let classAtr = "";
        while (elements) {
            els.unshift(elements);
            elements = elements.parentNode;
            classAtr =
                elements && elements.getAttribute && elements.getAttribute("class")
                    ? elements.getAttribute("class")
                    : "";
            if (classAtr === "ssi-feeds-displayBlockRemoveBtnPannel") {
                break;
            }
        }
        if (!classAtr || classAtr !== "ssi-feeds-displayBlockRemoveBtnPannel") {

            document.removeEventListener("click", this.removeBtnOutsideClickHandler);
            this.setState({
                showFeedRemoveBtnPanner: 1,

            });
        }
    };
    showFeedRemoveBtnHandler = (evt) => {
        this.setState(
            {
                showFeedRemoveBtnPanner:
                    this.state.showFeedRemoveBtnPanner === 0 ? 1 : 0,
            },
            () => {

                if (this.state.showFeedRemoveBtnPanner === 1) {
                    document.addEventListener("click", this.removeBtnOutsideClickHandler);
                }
            }
        );
    }
    handleChange = (e) => {

        this.setState({ current_comment: e.target.value });
    }
    render() {
        //console.log(this.state.current_comment)
        return (<div
            key={this.props.index}
            className={
                this.props.showAllComments === 0 &&
                    this.props.index + 1 > this.props.initNoOfCommentShow
                    ? "ssi-feeds-displayBlockCommentWrapper hide"
                    : "ssi-feeds-displayBlockCommentWrapper"
            }
        >
            {
                console.log("this.props.comment.profile_id", this.props.comment.profile_id)
            }
            <div className="ssi-feeds-displayBlockCommentProfileInfo">
                <ProfileImageDisplay
                    imageName={""}
                    firstLetterDisplay={
                        this.props.comment.profile_id
                            ? this.props.getProfileDetails(this.props.comment.profile_id)
                            : ""
                    }
                />
            </div>
            <div className="ssi-feeds-displayBlockCommentTextHolder">
                <div className="ssi-feeds-displayBlockCommentProfileName">
                    {this.props.getProfileDetails(this.props.comment.profile_id)}
                </div>
                <div className="ssi-feeds-commentGotoRight">
                    <div className="ssi-feeds-displayBlockCommentCreatedTime">
                        {this.props.calcForTimeCreation(
                            this.props.comment.createdAt,
                            "single"
                        )}
                    </div>
                    <div className={
                        this.state.feedRemovePermission === 1
                            ? "ssi-feeds-displayRemoveFeedBtn"
                            : "ssi-feeds-displayRemoveFeedBtn disable"
                    }>
                        {
                            console.log(this.state.contentEditable + "=====" + typeof this.props.loginUserId + "====" + typeof this.props.comment.profile_id)
                        }
                        {

                            (this.state.contentEditable !== true) && (this.props.loginUserId === this.props.comment.profile_id) || (this.props.role_id === 1) ?
                                (<span
                                    className="removeicon fas fa-ellipsis-h"
                                    onClick={this.showFeedRemoveBtnHandler}
                                    title={this.props.title}
                                ></span>) : null
                        }

                        {this.state.showFeedRemoveBtnPanner === 1 ? (
                            <div className="ssi-feeds-displayBlockRemoveBtnPannel" id="btnPannel" ref={this.wrapperRef}>
                                <ul>
                                    {
                                        this.props.page!== "moderate" ?(<li onClick={this.onClickEditComments}>
                                            <i class="fas fa-pencil-alt"></i>
                                            <span>{this.props.edit}</span>
                                        </li>):null
                                    }
                                    
                                    <li onClick={this.openModal}>
                                        <i class="far fa-trash-alt"></i>
                                        <span>{this.props.delete}</span>
                                    </li>
                                    <Modal
                                        show={this.state.show}
                                        onHide={this.closeModal}
                                    
                                    >
                                        
                                        <Modal.Body>
                                            <div className="ssi-feeds-commentsActionBtn" ref={this.wrapperRef}>
                                                <h6 className="modal-title alert-modal-title">{this.props.deleteComment}</h6>
                                                <div className="ssi-feeds-commentsActionBtns">
                                                    <button className="button-style secondary-btn "style={{marginRight:"5px"}} onClick={this.closeModal}>{this.props.cancel}</button>
                                                    <button className="button-style danger-btn " onClick={this.onClickDeleteComments}>{this.props.delete}</button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </ul>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div
                    id={this.props.comment._id}
                    className="ssi-feeds-displayBlockCommentText"
                >{
                        this.state.contentEditable !== false ? (               
                            <textarea maxlength='1024' onChange={this.handleChange} value={this.state.current_comment} autoFocus />
                        ) : (
                            <SeeMoreText
                                textValue={this.state.current_comment}
                                seemoretext={this.props.seeMore}
                                seelesstext={this.props.seeLess}
                                showAllComments={this.props.showAllComments}
                            >
                            </SeeMoreText>
                        )
                    }
                    {/*  */}

                </div>
                {this.state.contentEditable === true && (
                    <div className="ssi-feeds-commentsEditChanges">
                        <button style={{marginRight:"10px"}} className="button-style primary-btn btn-sm" disabled={this.state.current_comment.trim().length === 0} onClick={this.onClickSaveComments}>{this.props.saveChanges}</button>
                        <button className="button-style secondary-btn btn-sm" onClick={this.cancel}>{this.props.cancel}</button>
                    </div>
                )}
            </div>
        </div>)
    }
}

export default comments;
import React, { Component } from "react";
import { HttpInterceptor } from "../auth/HttpInterceptor";
import "./forgot-password.scss";
import ResetPassword from "./ResetPassword";

export default class AuthenticationPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paramid: "",
      msg: "",
      openResetComp: false,
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    let data = {
      body: urlParams.get("id"),
    };
    if (urlParams.get("id") !== undefined) {
      this.setState({ paramid: urlParams.get("id") });
    } else {
      this.state.paramid = undefined;
      this.props.history.push("/");
    }
    console.log(data);
    console.log("----", this.state.paramid);

    HttpInterceptor.post("forgotpassword", data).then(
      (res) => {
        if (
          res.data.message.length > 0 &&
          res.data.message.indexOf("Verification Success") > -1
        ) {
          this.setState({ openResetComp: true });
        } else if (res.data.message.toLowerCase().indexOf("expired") > -1) {
          this.setState({ msg: res.data.message });
        } else if (res.data.message.length > 0) {
          this.setState({ msg: res.data.message });
        } else {
          this.setState({ msg: "Invalid authentication link" });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  render() {
    const { labels } = this.props;
    if (this.state.openResetComp === true && this.state.msg === "") {
      return (
        <ResetPassword
          validate={this.state.openResetComp}
          paramid={this.state.paramid}
          labels={labels}
        />
      );
    } else {
      return (
        <div className="forgot-password-section">
          <div class="card">
            <div class="card-body fp-auth">
              <img src="../images/login-logo.png" alt="" />
              <h3>{this.state.msg}</h3>
            </div>
          </div>
        </div>
      );
    }
  }
}

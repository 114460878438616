import React, { Component } from 'react';
import { Link } from "react-router-dom"
import './report.scss'

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportType: [
                { id: 1, name: this.props.labels.labels.LBLCRSLISTS, link: 'course-list' },
                { id: 2, name: this.props.labels.labels.LBLROLEACCESS, link: 'role-access' },
                { id: 3, name: this.props.labels.labels.LBLLEARNERPROGRESS, link: 'learner_progress' },
                { id: 3, name: this.props.labels.labels.LBLRATEYOURSTATE, link: 'rate_your_state' }
            ]
        }
    }
    render() {
        const { LBLREPORTS } = this.props.labels.labels;
        return (
            // <div className="card-box">
            <div className="mob-pad">
                <div>
                    <h3 className="page-title">{LBLREPORTS}</h3>
                </div>
                <hr className="head-line" />
                <div className="row rpt-row">
                    {this.state.reportType.map((item, index) => {
                        return (

                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 rpt-col">
                                <Link variant="primary" to={"/schome/reports/" + item.link}>
                                    <div className="s-card rpt-card">
                                        <div className="rpt-flex">
                                            <i class="fas fa-clipboard-list"></i>
                                            <h5>{item.name}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div >
        );
    }
}

export default Reports;
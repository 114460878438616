import React, { Component } from 'react';
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "../admin/pages/validate/Validate";

function GenerateAgain(props) {
    const { handleSubmit, submitting } = props;
    const { labels } = props.labels;

    return (
        <form className="form" onSubmit={handleSubmit(props.GenerateCode)}>
            <div className="text-left">
                <div className="form-group">
                    <Field name="email" type="text" component={renderField} label={labels.LBLEMAIL}
                        className="form-field"
                        validate={[required]} labels={labels}
                    />
                </div>

            </div>
            <div className="form-group">
                <button
                    disabled={submitting}
                    className="btn login-btn primary-btn btn-block"
                    type="submit" >
                    {labels.LBLSUBMIT}
                </button>
            </div>
        </form>
    )
}
const renderField = ({ input, label, labels, type, className, meta: { touched, error, warning } }) => {
    return (<div className="form-group">
        <input {...input} type={type} className={`${className} ${input.value ? 'complete' : ''}`} />
        <label htmlFor={input.name}>{label}</label>
        {touched &&
            ((error && <span className="required-field">{typeof (error) === "string" ? labels[error] : labels[error.label].replace("$$$", error.val)}</span>) ||
                (warning && <span className="required-field">{warning}</span>))}
    </div>
    )
}





GenerateAgain = reduxForm({
    form: "generateagain", // a unique name for this form
})(GenerateAgain);


export default connect()(GenerateAgain);
import React, { Component } from "react";
import ReportServices from "../report_services";
import DataTable from "react-data-table-component";
import orderBy from "lodash/orderBy";
import GroupServices from "../../group-list/group_services";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import { connect } from "react-redux";
import UsersService from "../../user-list/UsersService";
import Skeleton from "react-loading-skeleton";
import emptyImage from '../../../../assets/images/learnerprogress_emptyst.png'
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from "moment";
require("highcharts/modules/exporting")(Highcharts);

const $ = require("jquery");
let objFilterData = {};
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};
const xoptions = {
  cutoutPercentage: 80,
  responsive: true,
  maintainAspectRatio: true,
};
$(window).resize(function () {
  $(window).height();
  $(window).width();
});
const bardata = {
  labels: [],
  datasets: [
    {
      data: [10, 90],
      backgroundColor: ["#007bff", "#c8cbce"],
      hoverBackgroundColor: ["#007bff", "#c8cbce"],
    },
  ],
};
const bardataLR = {
  labels: [],
  datasets: [
    {
      data: [10, 90],
      backgroundColor: ["#007bff", "#c8cbce"],
      hoverBackgroundColor: ["#007bff", "#c8cbce"],
    },
  ],
};
const bardataLRD = {
  labels: [],
  datasets: [
    {
      data: [10, 90],
      backgroundColor: ["#007bff", "#c8cbce"],
      hoverBackgroundColor: ["#007bff", "#c8cbce"],
    },
  ],
};

class LearnerProgressList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteType:localStorage.getItem('site_type')=="SafeStart"?1:2,
      open: false,
      loading: true,
      orgLearnerProgressList: [],
      learnerProgressList: [],
      roles: [{ role_id: 1, role_name: "learner" }],
      groupTypeNames: [],
      groupTypeColumns: [],
      loadgrouptype: 0,
      loadlearnerProgressList: 0,
      columns: [],
      summaryData: {
        Trained: "",
        Completed: "",
        Inprogress: "",
        Notstarted: "",
      },
      chartdata: [10, 90],
      pageState: "LearnerProgress",
      orgLearnerResultList: [],
      learnerResultList: [],
      summaryLRData: {
        Trained: "",
        Completed: "",
        Inprogress: "",
        Notstarted: "",
      },

      orgLearnerDetailResultList: [],
      learnerDetailResultList: [],
      summaryLRDData: {
        Trained: "",
        Completed: "",
        Inprogress: "",
        Notstarted: "",
      },

      userData: {},
      productData: {},
      userNames: [],
      childNode: null
    };
  }
  getDateFormat = async (x) => {
    try {
      if (x !== undefined && x !== "undefined" && x !== "" && x.length > 10) {
        x = x.substr(0, 10);
      } else {
        x = "";
      }
      return x;
    } catch (e) {
      x = "Error";
      return x;
    }
  };
  loadpage(a, x) {
    if (a === "group") {
      this.setState({loadgrouptype: 1})
    } else if (a === "learnerlist") {
      this.setState({loadlearnerProgressList: 1})
    }
    if (
      this.state.loadgrouptype === 1 &&
      this.state.loadlearnerProgressList === 1
    ) {
      this.setState({
        loading: false,
      });
    }
  }
  getGroupTypes = async () => {
    GroupServices.getGroupTypeLabels(this.props.language_code).then(
      (response) => {
        this.setState(
          {
            groupTypeNames: response,
          },
          this.loadpage("group", response)
        );
      }
    );
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };

  componentDidMount() {
    objFilterData = {};
    let getData = {
      language: this.props.language,
    };
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
        },
        this.loadpage("group", iresponse)
      );
    });

    this.getLearnerProgressList();
  }
  getLearnerProgressExport(data) {
    data.report = "LearnerProgress";
    ReportServices.getLearnerProgressExport(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        // console.log("TTTTTTTTTTTTTTTTTTTT,fileUrl", fileUrl);
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            // console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob);
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName =
        this.props.labels.labels.LBLLPEXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }
  getLearnerResultExport(data) {
    data.report = "LearnerResult";
    REFS.F2.onExportDone();
    data.userid = this.state.userData.user_id;
    ReportServices.getLearnerResultExport(data).then((response) => {
      // console.log("getLearnerResultExport!!!!!!!!11", response);
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        // console.log("TTTTTTTTTTTTTTTTTTTT,fileUrl", fileUrl);
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            // console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob);
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName =   this.props.labels.labels.LBLLREXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }
  getLearnerDetailResultExport(data) {
    data.report = "LearnerDetailResult";
    REFS.F3.onExportDone();
    data.userid = this.state.userData.user_id;
    data.productid = this.state.productData.product_id;
    ReportServices.getLearnerDetailResultExport(data).then((response) => {
      // console.log("getLearnerDetailResultExport!!!!!!!!11", response);
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        // console.log("TTTTTTTTTTTTTTTTTTTT,fileUrl", fileUrl);
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            // console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob);
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName =  this.props.labels.labels.LBLLDREXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }
  getLearnerProgressList() {
    ReportServices.getLearnerProgressList().then((response) => {
      // console.log("getLearnerProgressList!!!!!!!!11", response.result[2]);
      let result = response.result[2];
      let xsummaryData = {
        Learners: 0,
        Enrollments: 0,
        Trained: 0,
        Completed: 0,
        Inprogress: 0,
        Notstarted: 0,
      };
      let userdata = [];
      let arrgp1data = [],
        gp1data = {};
      let arrgp2data = [],
        gp2data = {};
      let arrgp3data = [],
        gp3data = {};
      for (var i = 0; i < result.length; i++) {
        xsummaryData.Enrollments =
          xsummaryData.Enrollments + result[i].Enrollments;
        xsummaryData.Inprogress =
          xsummaryData.Inprogress + result[i].Inprogress;
        xsummaryData.Completed = xsummaryData.Completed + result[i].Completed;
        xsummaryData.Notstarted =
          xsummaryData.Notstarted + result[i].Notstarted;
        userdata.push({
          id: result[i].user_id,
          value: result[i].first_name + " " + result[i].last_name,
        });
        if (result[i].GP_1) {
          let xg = result[i].GP_1.split(", ");
          for (let xgi = 0; xgi < xg.length; xgi++) {
            if (!gp1data[xg[xgi]]) {
              gp1data[xg[xgi]] = 1;
              arrgp1data.push({ id: xg[xgi], value: xg[xgi] });
            }
          }
        }
        if (result[i].GP_2) {
          let xg = result[i].GP_2.split(", ");
          for (let xgi = 0; xgi < xg.length; xgi++) {
            if (!gp2data[xg[xgi]]) {
              gp2data[xg[xgi]] = 1;
              arrgp2data.push({ id: xg[xgi], value: xg[xgi] });
            }
          }
        }
        if (result[i].GP_3) {
          let xg = result[i].GP_3.split(", ");
          for (let xgi = 0; xgi < xg.length; xgi++) {
            if (!gp3data[xg[xgi]]) {
              gp3data[xg[xgi]] = 1;
              arrgp3data.push({ id: xg[xgi], value: xg[xgi] });
            }
          }
        }
      }
      let prcntTrained =
        parseInt((xsummaryData.Completed * 1000) / xsummaryData.Enrollments) /
        10;
      prcntTrained = isNaN(prcntTrained) ? 0 : prcntTrained;
      xsummaryData.Trained = prcntTrained;
      xsummaryData.Learners = result.length;
      bardata.datasets[0].data = [prcntTrained, 100 - prcntTrained];
      bardata.labels = [
        this.props.labels.labels["LBLTRAINED"],
        this.props.labels.labels["LBLUNTRAINED"],
      ];

      this.setState(
        {
          pageState: "LearnerProgress",
          learnerProgressList: result,
          orgLearnerProgressList: result,
          summaryData: xsummaryData,
          userNames: userdata,
          GP_1Names: arrgp1data,
          GP_2Names: arrgp2data,
          GP_3Names: arrgp3data,
        },
        this.loadpage("learnerlist")
      );
    });
  }

  getLearnerResultList(item) {
    // console.log("getLearnerResultList", item);
    let data = {
      type: "web",
      dateformat: this.props.dateformat,
      userid: item.user_id,
    };
    ReportServices.getLearnerResultList(data).then((response) => {
      // console.log("getLearnerResultList-Response", response);
      let result = response.result[2];
      let xsummaryData = {
        Learners: 0,
        Enrollments: 0,
        Trained: 0,
        Completed: 0,
        Inprogress: 0,
        Notstarted: 0,
      };
      let coursedata = [];
      let arrcoursestatusdata = [],
        coursestatusdata = {};
      for (var i = 0; i < result.length; i++) {
        coursedata.push({ id: result[i].product_id, value: result[i].name });
        if (result[i].Status) {
          if (!coursestatusdata[result[i].Status]) {
            coursestatusdata[result[i].Status] = 1;
            arrcoursestatusdata.push({
              id: result[i].Status,
              value:
                this.props.labels.labels[
                "LBL" + result[i].Status.toUpperCase()
                ],
            });
          }
        }
        ////alert("arrcoursestatusdata" + JSON.stringify(arrcoursestatusdata))
        xsummaryData.Enrollments =
          xsummaryData.Enrollments + result[i].Enrollments;
        xsummaryData.Inprogress =
          xsummaryData.Inprogress + result[i].Inprogress;
        xsummaryData.Completed = xsummaryData.Completed + result[i].Completed;
        xsummaryData.Notstarted =
          xsummaryData.Notstarted + result[i].Notstarted;
        result[i].dispStatus =
          this.props.labels.labels["LBL" + result[i].Status.toUpperCase()];
        if (
          result[i].start_date !== null &&
          result[i].start_date !== undefined &&
          result[i].start_date !== "undefined" &&
          result[i].start_date !== "" &&
          result[i].start_date.length > 10
        ) {
          result[i].start_date = result[i].start_date
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/");
        }
        if (
          result[i].completed_date !== null &&
          result[i].completed_date !== undefined &&
          result[i].completed_date !== "undefined" &&
          result[i].completed_date !== "" &&
          result[i].completed_date.length > 10
        ) {
          result[i].completed_date = result[i].completed_date
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/");
        }
      }
      let prcntTrained =
        parseInt((xsummaryData.Completed * 10000) / xsummaryData.Enrollments) /
        100;
      xsummaryData.Trained = prcntTrained;
      xsummaryData.Courses = result.length;
      bardataLR.datasets[0].data = [prcntTrained, 100 - prcntTrained];
      bardataLR.labels = [
        this.props.labels.labels["LBLTRAINED"],
        this.props.labels.labels["LBLUNTRAINED"],
      ];
      this.setState(
        {
          pageState: "LearnerResult",
          learnerResultList: result,
          orgLearnerResultList: result,
          summaryLRData: xsummaryData,
          userData: item,
          loading: false,
          courseData: coursedata,
          courseStatusData: arrcoursestatusdata,
        },
        this.loadpage("learnerlist")
      );
    });
  }

  getLearnerDetailResultList(item) {
    // console.log("getLearnerDetailResultList-item", item);
    let data = {
      type: "web",
      dateformat: this.props.dateformat,
      userid: this.state.userData.user_id,
      productid: item.product_id,
    };
    ReportServices.getLearnerDetailResultList(data).then((response) => {
      // console.log("getLearnerDetailResultList-Response", response);
      let result = response.unitDetails;
      let cNode = response.childNode;
      let xsummaryData = {
        Units: 0,
        Trained: 0,
        Completed: 0,
        Inprogress: 0,
        Notstarted: 0,
      };
      let unitdata = [];
      let arrunitstatusdata = [],
        unitstatusdata = {};
      for (var i = 0; i < result.length; i++) {
        unitdata.push({ id: result[i].unit_id, value: result[i].name });
        if (result[i].Status) {
          if (!unitstatusdata[result[i].Status]) {
            unitstatusdata[result[i].Status] = 1;
            arrunitstatusdata.push({
              id: result[i].Status,
              value:
                this.props.labels.labels[
                "LBL" + result[i].Status.toUpperCase()
                ],
            });
          }
        }
        // console.log("i", result[i]);
        xsummaryData.Inprogress =
          xsummaryData.Inprogress + result[i].Inprogress;
        xsummaryData.Completed = xsummaryData.Completed + result[i].Completed;
        xsummaryData.Notstarted =
          xsummaryData.Notstarted + result[i].Notstarted;
        result[i].dispStatus =
          this.props.labels.labels["LBL" + result[i].Status.toUpperCase()];
        if (
          result[i].start_date !== null &&
          result[i].start_date !== undefined &&
          result[i].start_date !== "undefined" &&
          result[i].start_date !== "" &&
          result[i].start_date.length > 10
        ) {
          result[i].start_date = result[i].start_date
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/");
        }
        if (
          result[i].completed_date !== null &&
          result[i].completed_date !== undefined &&
          result[i].completed_date !== "undefined" &&
          result[i].completed_date !== "" &&
          result[i].completed_date.length > 10
        ) {
          result[i].completed_date = result[i].completed_date
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/");
        }
      }
      // console.log("LearnerDetailResult xsummaryData", xsummaryData);
      xsummaryData.Units = result.length;
      let prcntTrained =
        parseInt((xsummaryData.Completed * 1000) / xsummaryData.Units) / 10;
      prcntTrained = isNaN(prcntTrained) ? 0 : prcntTrained;
      xsummaryData.Trained = prcntTrained;
      bardataLRD.datasets[0].data = [prcntTrained, 100 - prcntTrained];
      bardataLRD.labels = [
        this.props.labels.labels["LBLTRAINED"],
        this.props.labels.labels["LBLUNTRAINED"],
      ];
      this.setState(
        {
          pageState: "LearnerDetailResult",
          learnerDetailResultList: result,
          orgLearnerDetailResultList: result,
          summaryLRDData: xsummaryData,
          productData: item,
          loading: false,
          unitData: unitdata,
          unitStatusData: arrunitstatusdata,
          childNode: cNode
        },
        this.loadpage("LearnerDetailResult")
      );
    });
  }
  showLearnerResult = (item) => {
    // console.log("showLearnerResult", item);
    this.getLearnerResultList(item);
    this.setState({
      pageState: "LearnerResult",
      loading: true,
      courseStatusData: [],
    });
  };
  showLearnerDetailResult = (item) => {
    // console.log("showLearnerDetailResult", item);
    this.getLearnerDetailResultList(item);
    this.setState({
      pageState: "LearnerDetailResult",
      loading: true,
      unitStatusData: [],
    });
  };
  showLearnerProgress = () => {
    // console.log("showLearnerProgress");
    this.setState({
      pageState: "LearnerProgress",
    });
  };

  getLearnerProgressColumns = (x) => {
    let columns = [
      {
        name: this.props.labels.labels.LBLSELECT,
        selector: "order",
        sortable: true,
        width: "10%",
        compact: true,
        cell: (row) => (
          <div className="w100">
            <label className="show-sm">
              {this.props.labels.labels.LBLSELECT}
            </label>
            <div className="icoBtn">
              <label className="check-lbl">
                <input
                  type="checkbox"
                  name={"chkg_" + row.user_id}
                  id={"chkg_" + row.user_id}
                  data-toggle="modal"
                  onClick={this.showLearnerResult.bind(this, row)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        ),
      },
      {
        name: this.props.labels.labels.LBLFULLNAME,
        selector: "name",
        filter: { type: "string", showField: true, checked: true, multi: true },
        filterData: this.state.userNames,
        show: false,
        sortable: true,
        compact: true,
        exportcell: "Full_Name",
        cell: (row) => {
          return (
            <div className='hde-user-icn'>
              <label className="show-sm">
                {this.props.labels.labels.LBLFULLNAME}
              </label>
              <span className="rle-brdr" title={row.name}>
                {row.name}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLTRAINED,
        selector: "Trained",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        exportcell: "Trained",
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLTRAINED}
              </label>
              <span className="rle-brdr" title={row.Trained}>
                {parseInt(row.Trained * 10) / 10}%
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLENROLLMENTS,
        selector: "Enrollments",
        filter: { type: "number", showField: true, checked: false },

        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLENROLLMENTS}
              </label>
              <span className="rle-brdr" title={row.Enrollments}>{row.Enrollments}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLACTIVE || 'Active',
        selector: "act_st",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLACTIVE}
              </label>
              <span className={row.act_st === "Active" ? "LBLACTIVE" : "LBLINACTIVE"} title={row.act_st}>
                {
                  this.props.labels.labels["LBL" + row.act_st.toUpperCase()]

                }</span>
            </div>
          );
        },
      }
    ];
    let xcolumns = [];
    let response = this.state.groupTypeNames;
    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          filter: {
            type: "string",
            showField: true,
            checked: true,
          },
          filterData: this.state.groups[response[i].group_type_id],
          sortable: true,
          compact: true,

        });
      }
    }

    columns.splice.apply(columns, [2, 0].concat(xcolumns));
    return columns; //JSON.parse(JSON.stringify(columns))
  };
  getLearnerResultColumns = (x) => {
    let columns = [
      {
        name: this.props.labels.labels.LBLSELECT,
        selector: "order",
        sortable: true,
        width: "10%",
        compact: true,
        cell: (row) => (
          <div className="w100">
            <label className="show-sm">
              {this.props.labels.labels.LBLSELECT}
            </label>
            <div className="icoBtn">
              <label className="check-lbl">
                <input
                  type="checkbox"
                  name={"chkg_" + row.user_id + "_" + row.product_id}
                  id={"chkg_" + row.user_id + "_" + row.product_id}
                  data-toggle="modal"
                  onClick={this.showLearnerDetailResult.bind(this, row)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        ),
      },
      {
        name: this.props.labels.labels.LBLCOURSENAME,
        selector: "name",
        filter: { type: "string", showField: true, checked: true },

        filterData: this.state.courseData,
        sortable: true,
        compact: true,
        width: "30%",
        cell: (row) => {
          return (
            <div className="grid-ellipsis">
              <label className="show-sm">
                {this.props.labels.labels.LBLCOURSENAME}
              </label>
              <span className="courseName rle-brdr" title={row.name}>{row.name}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLSTARTDATE,
        selector: "start_date",
        filter: { type: "date", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLSTARTDATE}
              </label>
              <span className="rle-brdr">{row.start_date_format}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLETIONDATE,
        selector: "completed_date",
        filter: { type: "date", showField: true, checked: false },

        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLCOMPLETIONDATE}
              </label>
              <span className="rle-brdr">{row.completed_date_format}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLTIMESPENT,
        selector: "total_timespent",
        filter: {
          type: "number",
          showField: true,
          checked: false,
          unit: "minutes",
        },

        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLTIMESPENT}
              </label>
              <span className="rle-brdr">{row.total_timespent}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLSTATUS,
        selector: "Status",
        filter: { type: "string", showField: true, checked: true, label: true },

        filterData: this.state.courseStatusData,
        sortable: true,
        compact: true,
        exportcell: "CourseStatus",
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLSTATUS}
              </label>
              <span className="rle-brdr" title={row.dispStatus}>{row.dispStatus}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLPROGRESS,
        selector: "Progress",
        filter: {
          type: "number",
          showField: true,
          checked: false,
          unit: "percentage",
        },

        sortable: true,
        compact: true,
        exportcell: "Progress",
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLPROGRESS}
              </label>
              <span className="rle-brdr">{row.Progress}%</span>
            </div>
          );
        },
      },
    ];
    return columns.slice();
  };
  getLearnerDetailResultColumns = (x) => {
    let unitstaus, unitname = this.state.siteType==1?(this.state.childNode && this.state.childNode === 'modules' ? this.props.labels.labels.LBLMODULENAME : this.props.labels.labels.LBLUNITNAME ):this.props.labels.labels.LBLMODULENAME;
    unitname = unitname?unitname:"Module Name";
    let columns = [
      {
        name: unitname,
        selector: "name",
        filter: { type: "string", showField: true, checked: true },

        filterData: this.state.unitData,
        sortable: true,
        compact: true,
        width: "30%",
        cell: (row) => {
          return (
            <div className="grid-ellipsis">
              <label className="show-sm">
                {this.state.childNode && this.state.childNode === 'modules' ?  this.props.labels.labels.LBLMODULENAME : this.props.labels.labels.LBLUNITNAME}
              </label>
              <span className="courseName rle-brdr" title={row.name}>{row.name}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLETEDTHROUGH,
        selector: "name",
        filter: { type: "string", showField: true, checked: true },

        filterData: this.state.unitData,
        sortable: true,
        compact: true,
        width: "30%",
        exportcell: "_training_type",
        exportlabels: {
          LBLCLASSROOM: this.props.labels.labels["LBLCLASSROOM"],
          LBLONLINE: this.props.labels.labels["LBLONLINE"],
          LBLONLINEANDCLASSROOM:
            this.props.labels.labels["LBLONLINEANDCLASSROOM"],
        },
        cell: (row) => {
          return (
            <div className="grid-ellipsis">
              <label className="show-sm">
                {this.props.labels.labels.LBLCOMPLETEDTHROUGH}
              </label>
              <span className="courseName rle-brdr" title={row.training_type}>
                {
                  this.props.labels.labels[
                  "LBL" + row.training_type.toUpperCase()
                  ]
                }
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLSTARTDATE,
        selector: "start_date",
        filter: { type: "date", showField: true, checked: true },

        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLSTARTDATE}
              </label>
              <span className="rle-brdr">{row.start_date_format}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLETIONDATE,
        selector: "completed_date",
        filter: { type: "date", showField: true, checked: true },

        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLCOMPLETIONDATE}
              </label>
              <span className="rle-brdr">{row.completed_date_format}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLTIMESPENT,
        selector: "total_units_timespent",
        filter: {
          type: "number",
          showField: true,
          checked: false,
          unit: "minutes",
        },

        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLTIMESPENT}
              </label>
              <span className="rle-brdr">{row.total_units_timespent}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLSTATUS,
        selector: "Status",
        filter: { type: "string", showField: true, checked: true, label: true },

        filterData: this.state.unitStatusData,
        sortable: true,
        compact: true,
        exportcell: "UnitStatus",
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLSTATUS}
              </label>
              <span className="rle-brdr" title={row.dispStatus}>{row.dispStatus}</span>
            </div>
          );
        },
      },
    ];
    ////alert(columns.length)
    return columns.slice();
  };
  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "LearnerProgress") {
      let xobj = objFilterData.availableLearnProgressFields;
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    } else if (mode === "LearnerResult") {
      let xobj = objFilterData.availableLearnResultFields;
      if (xobj) {
        for (var k = 0; k < xcols.length; k++) {
          xcols[k].filter = xobj[xcols[k].name];
          xcols[k].show = xobj[xcols[k].name].showField;
        }
      }
    } else if (mode === "LearnerDetailResult") {
      let xobj = objFilterData.availableLearnDetailResultFields;
      if (xobj) {
        for (var m = 0; m < xcols.length; m++) {
          xcols[m].filter = xobj[xcols[m].name];
          xcols[m].show = xobj[xcols[m].name].showField;
        }
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      // console.log(row[field]);
      if (row[field] && typeof row[field] === "string") {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  checkFilterCondition = (filters, data) => {
    // console.log("checkFilterCondition 0", filters, data);
    for (var i in filters) {
      if (filters[i].condition && filters[i].checked) {
        let dvalue = data[filters[i].selector];
        let fdvalue = [];
        let fselector = filters[i].selector.split(",");
        for (var xi = 0; xi < fselector.length; xi++) {
          fdvalue.push(data[fselector[xi]]);
        }
        dvalue = fdvalue.join(" ");
        let cvalue = filters[i].condition.value || "";
        let xr = false;
        let dtime;
        let ctime;
        let ctime1;
        let ctime2;

        if (filters[i].condition.operator === "NOCONDITION") {
          xr = true;
        } else {
          if (filters[i].type === "string") {
            if (filters[i].condition.operator === "CONTAINS") {
              //cvalue != "" && dvalue != null &&
              if (dvalue == null) {
                xr = false;
              } else if (
                dvalue.toLowerCase().split(cvalue.toLowerCase()).length > 1
              ) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "DOES_NOT_CONTAIN") {
              if (dvalue == null) {
                xr = false;
              } else if (
                dvalue.toLowerCase().split(cvalue.toLowerCase()).length === 1
              ) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue.toLowerCase() === cvalue.toLowerCase()) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue.toLowerCase() !== cvalue.toLowerCase()) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "MULTI_EQUALS") {
              if (cvalue === "" || (typeof cvalue == "object" && cvalue.length === 0)) {
                xr = true;
              } else {
                let rxr = 0;

                for (let xi = 0; xi < cvalue.length; xi++) {
                  if (dvalue.toLowerCase() === cvalue[xi].value.toLowerCase()) {
                    rxr = 1;
                    break;
                  }
                }
                if (filters[i].label) {
                  for (let xi = 0; xi < cvalue.length; xi++) {
                    if (
                      (
                        this.props.labels.labels[
                        "LBL" + dvalue.toUpperCase()
                        ] || ""
                      )
                        .toLowerCase()
                        .indexOf(cvalue[xi].value.toLowerCase()) > -1
                    ) {
                      rxr = 1;
                      break;
                    }
                  }
                }
                xr = rxr === 0 ? false : true;
              }
            }
          } else if (filters[i].type === "number") {
            if (filters[i].condition.operator === "EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue === cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue !== cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "GREATER_THAN") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > cvalue) {
                xr = true;
              }
            } else if (
              filters[i].condition.operator === "GREATER_THAN_EQUALS"
            ) {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue >= cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue <= cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "BETWEEN") {
              var min = Math.min.apply(Math, cvalue),
                max = Math.max.apply(Math, cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > min && dvalue < max) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_BETWEEN") {
              var minTwo = Math.min.apply(Math, cvalue),
                maxTwo = Math.max.apply(Math, cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < minTwo || dvalue > maxTwo) {
                xr = true;
              }
            }
          } else if (filters[i].type === "date") {
            var xgetTime = function (x) {
              let dx = new Date(x);
              dx.setHours(0);
              dx.setMinutes(0);
              dx.setMinutes(0);
              dx.setSeconds(0);
              dx.setMilliseconds(0);
              return dx.getTime();
            };
            if (filters[i].condition.operator === "EQUALS") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dtime === ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "GREATER_THAN") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dtime > ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dtime < ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "BETWEEN") {
              dtime = xgetTime(dvalue);
              ctime1 = xgetTime(cvalue[0]);
              ctime2 = xgetTime(cvalue[1]);
              var minThree = Math.min.apply(Math, [ctime1, ctime2]),
                maxThree = Math.max.apply(Math, [ctime1, ctime2]);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > minThree && dvalue < maxThree) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_BETWEEN") {
              dtime = xgetTime(dvalue);
              ctime1 = xgetTime(cvalue[0]);
              ctime2 = xgetTime(cvalue[1]);
              var minFour = Math.min.apply(Math, [ctime1, ctime2]),
                maxFour = Math.max.apply(Math, [ctime1, ctime2]);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < minFour || dvalue > maxFour) {
                xr = true;
              }
            }
          }
        }

        if (xr === false) {
          return false;
        }
      }
    }
    return true;
  };
  onExport = (type, id, conditions) => {
    if (id === "F1") {
      this.setFilterFields("F1", conditions);
      let data = {
        type: type,
        columns: objFilterData.availableLearnProgressFields,
      };
      this.getLearnerProgressExport(data);
    } else if (id === "F2") {
      this.setFilterFields("F2", conditions);
      let data = {
        type: type,
        columns: objFilterData.availableLearnResultFields,
      };
      this.getLearnerResultExport(data);
    } else if (id === "F3") {
      this.setFilterFields("F3", conditions);
      let data = {
        type: type,
        columns: objFilterData.availableLearnDetailResultFields,
      };
      this.getLearnerDetailResultExport(data);
    }
  };
  setFilterFields = (id, conditions) => {
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableLearnProgressFields = xobj;
      objFilterData.availableLearnProgressConditions = conditions;
    } else if (id === "F2") {
      //this.setState({
      objFilterData.availableLearnResultFields = xobj;
    } else if (id === "F3") {
      //this.setState({
      objFilterData.availableLearnDetailResultFields = xobj;
    }
  };
  onFilterChange = (id, conditions) => {
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportfunction) {
        xobj[conditions[i].name].exportfunction = conditions[i].exportfunction;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableLearnProgressFields = xobj;
      objFilterData.availableLearnProgressConditions = conditions;
      let result = [];

      for (var j = 0; j < this.state.orgLearnerProgressList.length; j++) {
        if (
          this.checkFilterCondition(
            xobj,
            this.state.orgLearnerProgressList[j]
          ) === true
        ) {
          result.push(this.state.orgLearnerProgressList[j]);
        }
      }

      let xsummaryData = {
        Learners: 0,
        Enrollments: 0,
        Trained: 0,
        Completed: 0,
        Inprogress: 0,
        Notstarted: 0,
      };
      for (var k = 0; k < result.length; k++) {
        xsummaryData.Enrollments =
          xsummaryData.Enrollments + result[k].Enrollments;
        xsummaryData.Inprogress =
          xsummaryData.Inprogress + result[k].Inprogress;
        xsummaryData.Completed = xsummaryData.Completed + result[k].Completed;
        xsummaryData.Notstarted =
          xsummaryData.Notstarted + result[k].Notstarted;
      }
      let prcntTrained =
        parseInt((xsummaryData.Completed * 1000) / xsummaryData.Enrollments) /
        10;
      prcntTrained = isNaN(prcntTrained) ? 0 : prcntTrained;
      xsummaryData.Trained = prcntTrained;
      xsummaryData.Learners = result.length;
      bardata.datasets[0].data = [prcntTrained, 100 - prcntTrained];
      bardata.labels = [
        this.props.labels.labels["LBLTRAINED"],
        this.props.labels.labels["LBLUNTRAINED"],
      ];
      this.setState({
        learnerProgressList: result,
        summaryData: xsummaryData,
      });
      //})
    } else if (id === "F2") {
      //this.setState({
      objFilterData.availableLearnResultFields = xobj;
      let result = [];
      for (var l = 0; l < this.state.orgLearnerResultList.length; l++) {
        if (
          this.checkFilterCondition(
            xobj,
            this.state.orgLearnerResultList[l]
          ) === true
        ) {
          result.push(this.state.orgLearnerResultList[l]);
        }
      }
      let xsummaryData = {
        Learners: 0,
        Enrollments: 0,
        Trained: 0,
        Completed: 0,
        Inprogress: 0,
        Notstarted: 0,
      };
      for (var m = 0; m < result.length; m++) {
        xsummaryData.Enrollments =
          xsummaryData.Enrollments + result[m].Enrollments;
        xsummaryData.Inprogress =
          xsummaryData.Inprogress + result[m].Inprogress;
        xsummaryData.Completed = xsummaryData.Completed + result[m].Completed;
        xsummaryData.Notstarted =
          xsummaryData.Notstarted + result[m].Notstarted;
        result[m].dispStatus =
          this.props.labels.labels["LBL" + result[m].Status.toUpperCase()];
        if (
          result[m].start_date !== null &&
          result[m].start_date !== undefined &&
          result[m].start_date !== "undefined" &&
          result[m].start_date !== "" &&
          result[m].start_date.length > 10
        ) {
          result[m].start_date = result[m].start_date
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/");
        }
        if (
          result[m].completed_date !== null &&
          result[m].completed_date !== undefined &&
          result[m].completed_date !== "undefined" &&
          result[m].completed_date !== "" &&
          result[m].completed_date.length > 10
        ) {
          result[m].completed_date = result[m].completed_date
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/");
        }
      }
      let prcntTrained =
        parseInt((xsummaryData.Completed * 10000) / xsummaryData.Enrollments) /
        100;
      xsummaryData.Trained = isNaN(prcntTrained) ? 0 : prcntTrained;
      xsummaryData.Courses = result.length;
      bardataLR.datasets[0].data = [prcntTrained, 100 - prcntTrained];
      this.setState({
        learnerResultList: result,
        summaryLRData: xsummaryData,
      });
    } else if (id === "F3") {
      //this.setState({
      objFilterData.availableLearnDetailResultFields = xobj;
      let result = [];
      for (var n = 0; n < this.state.orgLearnerDetailResultList.length; n++) {
        if (
          this.checkFilterCondition(
            xobj,
            this.state.orgLearnerDetailResultList[n]
          ) === true
        ) {
          result.push(this.state.orgLearnerDetailResultList[n]);
        }
      }
      let xsummaryData = {
        Units: 0,
        Trained: 0,
        Completed: 0,
        Inprogress: 0,
        Notstarted: 0,
      };
      for (var o = 0; o < result.length; o++) {
        xsummaryData.Inprogress =
          xsummaryData.Inprogress + result[o].Inprogress;
        xsummaryData.Completed = xsummaryData.Completed + result[o].Completed;
        xsummaryData.Notstarted =
          xsummaryData.Notstarted + result[o].Notstarted;
        result[o].dispStatus =
          this.props.labels.labels["LBL" + result[o].Status.toUpperCase()];
        if (
          result[o].start_date !== null &&
          result[o].start_date !== undefined &&
          result[o].start_date !== "undefined" &&
          result[o].start_date !== "" &&
          result[o].start_date.length > 10
        ) {
          result[o].start_date = result[o].start_date
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/");
        }
        if (
          result[o].completed_date !== null &&
          result[o].completed_date !== undefined &&
          result[o].completed_date !== "undefined" &&
          result[o].completed_date !== "" &&
          result[o].completed_date.length > 10
        ) {
          result[i].completed_date = result[o].completed_date
            .substr(0, 10)
            .split("-")
            .reverse()
            .join("/");
        }
      }
      xsummaryData.Units = result.length;
      let prcntTrained =
        parseInt((xsummaryData.Completed * 10000) / xsummaryData.Units) / 100;
      prcntTrained = isNaN(prcntTrained) ? 0 : prcntTrained;
      xsummaryData.Trained = prcntTrained;
      xsummaryData.Courses = result.length;
      bardataLRD.datasets[0].data = [prcntTrained, 100 - prcntTrained];
      this.setState({
        learnerDetailResultList: result,
        summaryLRDData: xsummaryData,
      });
    }

    //
  };
  getOptions = () => {
    let pieColors = ["#007bff", "#c8cbce"];
    let container = $("#chart_container");
    let options = {
      chart: {
        renderTo: container,
        type: 'pie',
        // width:120,
        height: 120
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      xAxis: {
        categories: [],
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<strong>{point.name}</strong> : <strong>{point.y}%</strong>',
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: pieColors,
          size: '150%',
          innerSize: '80%',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        colorByPoint: true,
        data: []
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              enabled: false
            }
          }
        }]
      }
    };
    return options;
  }
  render() {
    let {
      LBLLEARNERS,
      LBLLEARNERPROGRESS,
      LBLTRAINED,
      LBLCOMPLETED,
      LBLINPROGRESS,
      LBLNOTSTARTED,
      LBLLEARNERDETAILSRESULT,
      LBLCOURSE,
      LBLUNITS,
      LBLMODULES,
      LBLUSER,
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLLEARNERCRSPROGEXPTYTXT,
    } = this.props.labels.labels;
    LBLMODULES=LBLMODULES?LBLMODULES:"Modules";
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };

    try {
      if (this.state.pageState === "LearnerProgress") {
        let columns = this.getLearnerProgressColumns();
        let filterFields = this.getFilterColumns("LearnerProgress", columns);
        let resetfilterFields = this.getResetFilterColumns(
          "LearnerProgress",
          columns
        );

        for (let i = 0; i < columns.length; i++) {
          for (let j = 0; j < filterFields.length; j++) {

            if (
              filterFields[j].name === columns[i].name &&
              filterFields[j].hasOwnProperty("filter") === true && filterFields[j].filter.showField === false
            ) {
              columns.splice(i, 1);
              i--;
            }
          }
        }

        let data = this.state.learnerProgressList;

        //Data Conversion
        let optionsData = {};
        optionsData = this.getOptions();
        if (bardata.labels[0]) {
          optionsData.xAxis.categories = bardata.labels;
          let data1 = bardata.datasets[0].data;
          optionsData.series[0].data.push({ y: data1[0], name: bardata.labels[0] });
          optionsData.series[0].data.push({ y: data1[1], name: bardata.labels[1] });
        }
        return (
          <div className="reportsCtn">
            {/* <div className="card-box"> */}
            <div className="mob-pad">
              <div>
                <h3 className="page-title">{LBLLEARNERPROGRESS}</h3>
              </div>
              <hr className="head-line" />
              <div>
                {this.state.loading === false &&
                  this.state.userNames.length > 0 &&
                  (filterFields.length >= 5 || this.props.ssi_data_priv) ? (
                  <div>
                    <CustomFilter
                      labels={this.props.labels.labels}
                      availableFields={filterFields}
                      selectedFields={filterFields}
                      orgAvailableFields={resetfilterFields}
                      onFilterChange={this.onFilterChange}
                      onExport={this.onExport}
                      id="F1"
                      reportName={LBLLEARNERPROGRESS}
                      companyName={this.props.company_name}
                      onRef={(ref) => (REFS.F1 = ref)}
                      showFieldFilter={true}
                    />
                  </div>
                ) : this.state.loading === false && filterFields.length === 0 ? (
                  <div>
                    <div>
                      <div>
                        <div className="row box ml-1">
                          <Skeleton height={46} />
                        </div>
                        <div>
                          <h3>
                            <Skeleton height={21} />
                          </h3>
                          <h2>
                            <Skeleton height={26} />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div id="accordion" className="row rptlst-row">
                {this.state.loading ? (
                  //<div className="text-center"></div>
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div className="row prowbox">
                      <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-xs-12">
                        <Skeleton height={212} />
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-8 col-sm-8 col-xs-12 my-auto">
                        <div className="row rowbox my-auto">
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">
                                  <Skeleton height={21} />
                                </p>
                                <span>
                                  <Skeleton height={36} />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">
                                  <Skeleton height={21} />
                                </p>
                                <span>
                                  <Skeleton height={36} />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">
                                  <Skeleton height={21} />
                                </p>
                                <span>
                                  <Skeleton height={36} />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="p-user">
                                  <Skeleton height={21} />
                                </p>
                                <span>
                                  <Skeleton height={36} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottomborder">
                      <span className="subHeader">
                        <h4>
                          <Skeleton height={19} />
                        </h4>
                      </span>
                    </div>
                    <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                      <div className="sc-fznWqX gnahTY">
                        <div className="sc-AxjAm gIMaKV rdt_Table">
                          <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                            <Skeleton height={56} />
                          </div>
                          <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                            <Skeleton height={480} />
                          </div>
                        </div>
                      </div>
                      <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                        <Skeleton height={56} />
                      </nav>
                    </div>
                    <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                      <header className="sc-fzoXzr hlidmt">
                        <Skeleton height={34} />
                      </header>
                      <div className="sc-fznWqX gnahTY">
                        <div className="sc-AxjAm gIMaKV rdt_Table">
                          <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                            {skel.map((i) => {
                              return (
                                <div
                                  id={"row-" + i}
                                  className="sc-fzoLsD doBktq rdt_TableRow"
                                >
                                  <Skeleton height={192} />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                        <div className="sc-fzpmMD ftIMtk">
                          <Skeleton height={40} />
                        </div>
                      </nav>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div className="row prowbox">
                      <div
                        className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-xs-12"
                        style={{ padding: "20px" }}
                      >
                        <div>
                          <HighchartsReact highcharts={Highcharts}
                            options={optionsData}
                            xoptions={xoptions}
                          />
                        </div>
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-8 col-sm-8 col-xs-12 my-auto">
                        <div
                          className="row rowbox my-auto"
                          style={{ margin: "10px" }}
                        >
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">{LBLTRAINED}</p>
                                <span> {this.state.summaryData.Trained}%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="user-c">{LBLCOMPLETED}</p>
                                <span> {this.state.summaryData.Completed}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="user-c">{LBLINPROGRESS}</p>
                                <span> {this.state.summaryData.Inprogress}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="p-user">{LBLNOTSTARTED}</p>
                                <span> {this.state.summaryData.Notstarted}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottomborder">
                      <span className="subHeader">
                        <h4>{`${LBLLEARNERS} : ${this.state.summaryData.Learners}`}</h4>
                      </span>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      className="datatable-list"
                      sortFunction={this.customSort}
                      pagination
                      noDataComponent={<EmptyState image={emptyImage} text2={LBLLEARNERCRSPROGEXPTYTXT} />}
                      defaultSortField="name"
                      paginationComponentOptions={paginationComponentOptions}

                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      } else if (this.state.pageState === "LearnerResult") {
        window.scrollTo(500, 0);
        let columns = this.getLearnerResultColumns();
        let xfilterFields = this.getFilterColumns("LearnerResult", columns);
        let xresetfilterFields = this.getResetFilterColumns(
          "LearnerResult",
          columns
        );
        let data = this.state.learnerResultList;
        let showfilter = 0;
        if (xfilterFields.length === 6) {
          if (this.state.courseStatusData.length > 0) {
            showfilter = 1;
          }
        }
        //Data Conversion
        let optionsDataLR = {};
        if (bardataLR.labels[0]) {
          optionsDataLR = this.getOptions();
          optionsDataLR.xAxis.categories = bardataLR.labels;
          let data3 = bardataLR.datasets[0].data;
          optionsDataLR.series[0].data.push({ y: data3[0], name: bardataLR.labels[0] });
          optionsDataLR.series[0].data.push({ y: data3[1], name: bardataLR.labels[1] });
        }
        return (
          <div className="reportsCtn">
            <div className="mob-pad">
              <div >
                <h3 className="page-title">
                  <i
                    className="fas fa-arrow-left cu-back-btn"
                    aria-hidden="true"
                    onClick={this.showLearnerProgress.bind(this)}
                  ></i>{" "}
                  &nbsp; &nbsp;{LBLLEARNERDETAILSRESULT}
                </h3>
              </div>
              <hr className="head-line" />

              <div>
                {this.state.loading === false && showfilter === 1 ? (
                  <div>
                    <CustomFilter
                      availableFields={xfilterFields}
                      selectedFields={xfilterFields}
                      orgAvailableFields={xresetfilterFields}
                      onFilterChange={this.onFilterChange}
                      onExport={this.onExport}
                      id="F2"
                      onRef={(ref) => (REFS.F2 = ref)}
                      labels={this.props.labels.labels}
                      reportName={LBLLEARNERDETAILSRESULT}
                      companyName={this.props.company_name}
                      showFieldFilter={true}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div id="accordion" className="row rptlst-row">
                {this.state.loading ? (
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div className="row prowbox">
                      <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-xs-12">
                        <Skeleton height={212} />
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-8 col-sm-8 col-xs-12 my-auto">
                        <div className="row rowbox my-auto">
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">
                                  <Skeleton height={21} />
                                </p>
                                <span>
                                  <Skeleton height={36} />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">
                                  <Skeleton height={21} />
                                </p>
                                <span>
                                  <Skeleton height={36} />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">
                                  <Skeleton height={21} />
                                </p>
                                <span>
                                  <Skeleton height={36} />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="p-user">
                                  <Skeleton height={21} />
                                </p>
                                <span>
                                  <Skeleton height={36} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottomborder">
                      <span className="subHeader">
                        <h4>
                          <Skeleton height={19} />
                        </h4>
                      </span>
                      <span className="subHeader">
                        <h4>
                          <Skeleton height={19} />
                        </h4>
                      </span>
                    </div>
                    <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                      <div className="sc-fznWqX gnahTY">
                        <div className="sc-AxjAm gIMaKV rdt_Table">
                          <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                            <Skeleton height={56} />
                          </div>
                          <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                            <Skeleton height={480} />
                          </div>
                        </div>
                      </div>
                      <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                        <Skeleton height={56} />
                      </nav>
                    </div>
                    <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                      <header className="sc-fzoXzr hlidmt">
                        <Skeleton height={34} />
                      </header>
                      <div className="sc-fznWqX gnahTY">
                        <div className="sc-AxjAm gIMaKV rdt_Table">
                          <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                            {skel.map((i) => {
                              return (
                                <div
                                  id={"row-" + i}
                                  className="sc-fzoLsD doBktq rdt_TableRow"
                                >
                                  <Skeleton height={192} />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                        <div className="sc-fzpmMD ftIMtk">
                          <Skeleton height={40} />
                        </div>
                      </nav>
                    </div>
                  </div>
                ) : (
                  // <Loader></Loader>
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div className="row prowbox">
                      <div
                        className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-xs-12"
                        style={{ padding: "20px" }}
                      >
                        <HighchartsReact highcharts={Highcharts}
                          options={optionsDataLR}
                          xoptions={xoptions}
                          ref={this.chart}
                        />
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-8 col-sm-8 col-xs-12 my-auto">
                        <div
                          className="row rowbox my-auto"
                          style={{ margin: "10px" }}
                        >
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">{LBLTRAINED}</p>
                                <span> {this.state.summaryLRData.Trained}%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="user-c">{LBLCOMPLETED}</p>
                                <span> {this.state.summaryLRData.Completed}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="user-c">{LBLINPROGRESS}</p>
                                <span>
                                  {" "}
                                  {this.state.summaryLRData.Inprogress}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="p-user">{LBLNOTSTARTED}</p>
                                <span>
                                  {" "}
                                  {this.state.summaryLRData.Notstarted}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottomborder">
                      <span className="subHeader">
                        <h4 className="grid-ellipsis" style={{ width: "300px" }}>
                          {LBLUSER} : {this.state.userData.first_name}{" "}
                          {this.state.userData.last_name}
                        </h4>
                      </span>
                      <span className="subHeader">
                        <h4>
                          {LBLCOURSE} : {this.state.summaryLRData.Courses}
                        </h4>
                      </span>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      className="datatable-list"
                      sortFunction={this.customSort}
                      pagination
                      noDataComponent={<EmptyState image={emptyImage} text2={LBLLEARNERCRSPROGEXPTYTXT} />}
                      defaultSortField="name"
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      } else if (this.state.pageState === "LearnerDetailResult") {
        window.scrollTo(500, 0);
        let columns = this.getLearnerDetailResultColumns();
        let xfilterFields = this.getFilterColumns("LearnerDetailResult", columns);
        let xresetfilterFields = this.getResetFilterColumns(
          "LearnerDetailResult",
          columns
        );
        let data = this.state.learnerDetailResultList;
        let showfilter = 0;
        if (xfilterFields.length === 5) {
          if (this.state.unitStatusData.length > 0) {
            showfilter = 1;
          }
        }
        //Data Conversion
        let  optionsDataLRD = {};
        if (bardataLRD.labels[0]) {
          optionsDataLRD = this.getOptions();
          optionsDataLRD.xAxis.categories = bardataLRD.labels;
          let data2 = bardataLRD.datasets[0].data;
          optionsDataLRD.series[0].data.push({ y: data2[0], name: bardataLRD.labels[0] });
          optionsDataLRD.series[0].data.push({ y: data2[1], name: bardataLRD.labels[1] });
        }
        return (
          <div className="reportsCtn">
            {/* <div className="card-box"> */}
            <div className="mob-pad">
              <div >
                <h3 className="page-title">
                  <i
                    className="fas fa-arrow-left cu-back-btn"
                    aria-hidden="true"
                    onClick={this.showLearnerResult.bind(
                      this,
                      this.state.userData
                    )}
                  ></i>{" "}
                  &nbsp; &nbsp;{LBLLEARNERDETAILSRESULT}{" "}
                </h3>
              </div>
              <hr className="head-line" />
              <div>
                {this.state.loading === false && showfilter === 1 ? (
                  <div>
                    <CustomFilter
                      availableFields={xfilterFields}
                      selectedFields={xfilterFields}
                      orgAvailableFields={xresetfilterFields}
                      onFilterChange={this.onFilterChange}
                      onExport={this.onExport}
                      id="F3"
                      onRef={(ref) => (REFS.F3 = ref)}
                      labels={this.props.labels.labels}
                      reportName={LBLLEARNERDETAILSRESULT}
                      companyName={this.props.company_name}
                      showFieldFilter={true}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div id="accordion" className="row rptlst-row">
                {this.state.loading ? (
                  <div className="text-center"></div>
                ) : (
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div className="row prowbox">
                      <div
                        className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-xs-12"
                        style={{ padding: "20px" }}
                      >
                        <HighchartsReact highcharts={Highcharts}
                          options={optionsDataLRD}
                          xoptions={xoptions}
                          ref={this.chart}
                        />
                      </div>
                      <div className="col-xl-10 col-lg-10 col-md-8 col-sm-8 col-xs-12 my-auto">
                        <div
                          className="row rowbox my-auto"
                          style={{ margin: "10px" }}
                        >
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity ">
                              <div className="act-user">
                                <p className="users-c">{LBLTRAINED}</p>
                                <span> {this.state.summaryLRDData.Trained}%</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="user-c">{LBLCOMPLETED}</p>
                                <span>
                                  {" "}
                                  {this.state.summaryLRDData.Completed}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="user-c">{LBLINPROGRESS}</p>
                                <span>
                                  {" "}
                                  {this.state.summaryLRDData.Inprogress}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12 boxpad">
                            <div className="user-activity">
                              <div className="act-user">
                                <p className="p-user">{LBLNOTSTARTED}</p>
                                <span>
                                  {" "}
                                  {this.state.summaryLRDData.Notstarted}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottomborder">
                      <span className="subHeader">
                        <h4>
                          {LBLUSER} : {this.state.userData.first_name}{" "}
                          {this.state.userData.last_name}
                        </h4>
                      </span>
                      <span className="subHeader">
                        <h4>
                          {LBLCOURSE} : {this.state.productData.name}{" "}
                        </h4>
                      </span>
                      <span className="subHeader">
                        <h4>
                          {this.state.siteType==1? (this.state.childNode && this.state.childNode === 'modules' ? LBLMODULES : LBLUNITS) :LBLMODULES} : {this.state.summaryLRDData.Units}
                        </h4>
                      </span>
                    </div>
                    <DataTable
                      columns={columns}
                      data={data}
                      className="datatable-list"
                      sortFunction={this.customSort}
                      pagination
                      noDataComponent={<EmptyState image={emptyImage} text2={LBLLEARNERCRSPROGEXPTYTXT} />}
                      defaultSortField="name"
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return <div>No Data for {this.state.pageState}</div>;
      }
    } catch (e) {
      return <div>No Data for ERR:{this.state.pageState}</div>;
    }

  }
}
const mapStateToProps = (state) => {
  return {
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(LearnerProgressList);
//export default ;

import React, { Component } from "react";
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@mui/material/Checkbox';
import { styled } from "@mui/material/styles";
import './catalogpopper.scss';
import {
    Grid,
    Box
} from "@material-ui/core/";
console.log("catalog popper comp")
class CatalogPopper extends Component {
    constructor(props) {
        super(props);
        this.selectallref = []
        this.state = {
            anchorEl: null,
            selectedIndex: this.props.selectedCatageryIndex,
            allCatagoryIds: this.props.allCatagoryIds,
            checked: this.props.selectedCatageryIds
        }
    }
    componentDidMount() {
        console.log("catalog popper comp did mount");
        let state = {
            anchorEl: null,
            selectedIndex: this.props.selectedCatageryIndex,
            allCatagoryIds: this.props.allCatagoryIds,
            checked: this.props.selectedCatageryIds,
        }
        this.setState(state);
    }
    componentWillReceiveProps(prevProps) {
        if(prevProps.selectedCatageryIds !== this.state.checked) {               
            this.setState({checked: this.props.selectedCatageryIds});
        }
    }
    selectCourseCatalog = (e) => {
        this.setState({ anchorEl: e.currentTarget })
    }
    closeCatalog = () => {
        this.setState({ anchorEl: null, checked: this.props.selectedCatageryIds  })
    }

    selectAllCategory = (cat, index) => {
       let isChecked =  this.state.checked[0] !== 0 ?  (this.state.checked.length === this.props.categoriesJSON.length ? true : false) : false        
       if(!isChecked){
            this.setState({
                selectedIndex: -1, checked: this.props.allCatagoryIds})
        }else {
            this.setState({
                selectedIndex: -1, checked: []})
        }
    }

    handleToggle = (cat) => () => {
        const currentIndex = this.state.checked ? this.state.checked.indexOf(cat.id) : -1;
        const newChecked = this.state.checked.length && this.state.checked[0] === 0 ? [] : [...this.state.checked];
        if (currentIndex === -1) {
          newChecked.push(cat.id);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        this.setState({checked: newChecked});
    };

    selectedMultipleCategories = () => {
        let values = [{
            id: 0,
            name: 'All',
            indexValue: -1
        }];
        if(this.state.checked.length) {
            values = this.props.categoriesJSON.filter((item, index) => {
                if(this.state.checked.indexOf(item.id) > -1) {
                    item.indexValue = index;
                    return item;
                }
                else {
                    return false;
                }
            })
        }
        this.setState({ anchorEl: null, selectedIndex: this.state.checked.length ? values[0].indexValue : -1})
        this.props.selectedMultipleCategories(values)
    }

    render() {
        const { checked } = this.state;
        const { categoriesJSON, labels } = this.props
        const popopen = Boolean(this.state.anchorEl);
        const popid = popopen ? 'simple-popover' : undefined;
        return (
            <Box sx={{ paddingRight: 10 }} className="catalog_popper">
                <Button
                    disabled={categoriesJSON.length ? false : true}
                    aria-describedby={popid} variant="outlined"
                    className="catalog_button"
                    onClick={(e) => this.selectCourseCatalog(e)}>
                        <div className="catalog-icon"></div>                        
                        {labels.labels.LBLCOURSECATALOG || 'Course Catalog'}
                </Button>
                <Popover
                    id={popid}
                    open={popopen}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.closeCatalog()}
                    PaperProps={{
                        style: {
                         marginTop: 0,
                         marginRight: 20,
                         minWidth:220,
                         maxWidth: 300,
                        }
                      }}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                    <List
                        className="catalog_popper_popup"
                        subheader={
                            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <ListSubheader component="div" variant={'h5'} id="nested-list-subheader">
                                {labels.labels.LBLCOURSECATALOG  || 'Course Catalog'}
                                </ListSubheader>
                                <CloseIcon style={{ marginTop: 10, marginRight: 5, cursor: 'pointer' }} onClick={this.closeCatalog} />
                            </Box>
                        }>
                        <ListItem disablePadding>
                            <ListItemButton 
                            onClick={() => this.selectAllCategory({
                                    id: 0,
                                    name: 'All'
                                }, null)} 
                                dense>
                            <ListItemIcon>
                                            <Checkbox
                                            edge="start"
                                            checked={checked[0] !== 0 ?  (checked.length === categoriesJSON.length ? true : false) : false}
                                            tabIndex={-1}
                                            disableRipple
                                            inputRef={this.selectallref}
                                            style={{cursor: 'pointer'}}
                                            />
                                        </ListItemIcon>
                                <ListItemText primary={'Select All'}  />
                            </ListItemButton>
                        </ListItem>
                        {categoriesJSON && categoriesJSON.map((category, index) => {
                            const labelId = `checkbox-list-label-${category.id}`;
                            return (
                                <ListItem disablePadding key={index} >
                                    <ListItemButton onClick={this.handleToggle(category)} dense>
                                        <ListItemIcon className="catalog_pop_listicon">
                                            <Checkbox
                                            edge="start"
                                            checked={checked.length  ? checked.indexOf(category.id) !== -1 : false}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            style={{cursor: 'pointer'}}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={category.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                        <ListItem disablePadding style={{flex: 1, justifyContent: 'center', width: '100%', padding: '1%'}} alignItems="center" >                            
                                <Button disabled={ checked.length ? (checked[0] === 0 ? true : false)  : true} 
                                className="ok_button"
                                style={{
                                    backgroundColor: checked.length ? (checked[0] === 0 ? '#cccccc' : '#019ade')  : '#cccccc',
                                    color: checked.length ? (checked[0] === 0 ? '#666666' : '#fff')  : '#666666'
                                    }} 
                                    onClick={() => this.selectedMultipleCategories()}>
                                    {'Apply'}
                                </Button>
                        </ListItem>
                    </List>
                </Popover>
            </Box>
        );
    }
}
export default CatalogPopper;
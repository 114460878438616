import React, { Component } from 'react';
import RiskCard from './RiskCard'

class RiskPattern extends Component {
    render() {
        return (
            <React.Fragment>
                {/* <section className="card-box"> */}
                <section className="mob-pad">
                    <div className="section-header">
                        <h3>{this.props.labels.labels.LBLRISKHDR}</h3>
                    </div>
                    <hr className="head-line" />
                    <RiskCard labels={this.props.labels.labels} />
                </section>
            </React.Fragment>
        );
    }
}

export default RiskPattern;
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required, minValue6 } from "../admin/pages/validate/Validate";
import $ from 'jquery'
class PasswordConfirmForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = (values) => {
    console.log(values);
    this.props.set_user_password(values);
  };
  renderField = ({ input, label, type, className, meta: { touched, error, warning } }) => (
    <div className="form-group">
      <input {...input} type={type} className={`${className} ${input.value ? 'complete' : ''}`} />
      <label htmlFor={input.name}>{label}</label>
      {touched &&
        ((error && <span className="required-field">{typeof (error) === "string" ? this.props.labels.labels[error] : this.props.labels.labels[error.label].replace("$$$", error.val)}</span>) ||
          (warning && <span className="required-field">{warning}</span>))}
    </div>
  )

  onChangeHandler = (e) => {
    if (e.target.value) {
      $(e.target).addClass("complete")
    }
    else {
      $(e.target).removeClass("complete")
    }
  }
  render() {
    const { handleSubmit, submitting, labels } = this.props;
    return (
      <form
        className="form"
        onSubmit={handleSubmit(this.props.PasswordSubmit)}
      >
        <div className="text-left">
          <div className="form-group">
            <Field
              name="password"
              type="password"
              component={this.renderField}
              label={labels.labels.LBLPASSWORD}
              className="form-field"
              validate={[required, minValue6]}
            />
          </div>
        </div>
        <div className="form-group">
          <button
            disabled={submitting}
            className="btn login-btn primary-btn btn-block"
            type="submit"
          >
            {labels.labels.LBLSUBMIT}
          </button>
        </div>
      </form>
    );
  }
}

PasswordConfirmForm = reduxForm({
  form: "setpassword", // a unique name for this form
})(PasswordConfirmForm);

export default connect()(PasswordConfirmForm);

import React from "react";
import DefaultWidget from "./DefaultWidget";
import dateFormat from "dateformat";
import * as operators from "../operators";
import moment from "moment";
let currentFromDate = null;
class DateWidget extends DefaultWidget {
  getOperators() {
    return {
      [operators.TODAY]: this.props.labels.LBLTODAY, //'Today',
      [operators.YESTERDAY]: this.props.labels.LBLYESTERDAY, //'Yesterday',
      [operators.LAST7DAYS]: this.props.labels.LBLLAST7DAYS, //'Last 7 days',
      [operators.LAST30DAYS]: this.props.labels.LBLLAST30DAYS, //'Last 30 days ',
      [operators.THISMONTH]: this.props.labels.LBLTHISMONTH, //'This Month',
      [operators.LASTMONTH]: this.props.labels.LBLLASTMONTH, //'Last Month',
      [operators.YTD]: this.props.labels.LBLYTD || "LBLYTD", //'Year Till Date',
     // [operators.EQUALS]: this.props.labels.LBLSPECIFICDATE, //'Specific Date',
      [operators.BETWEEN]: this.props.labels.LBLCUSTOMRANGE, //'Custom Range',
      // [operators.GREATER_THAN]: 'after',
      // [operators.LESS_THAN]: 'before',
      // [operators.NOT_BETWEEN]: 'not between',
      // [operators.LAST]: 'last'
    };
  }

  renderInputElement(value, onChange, inputValType="null") {
    console.log("renderInputElement@@@@@@@@@@@@@@@@@@ date widget inputValType",value);
    if(inputValType === "FROM"){
      currentFromDate = moment(value).format("YYYY-MM-DD");
    }else {
      console.log(currentFromDate,"currentFromDate");
    }
    const currentDate = new Date().toISOString().split('T')[0];
    // const minDate = new Date("2024-06-20").toISOString().split('T')[0];
    if (typeof value == "object" && value && value[1]) {
      value = "";
    }
    const dateAsString = value ? dateFormat(value, "isoDate") : null;
    const onChangeWithDate = (e) => {
      const stringValue = e.target.value;
      onChange(stringValue === "" ? null : new Date(stringValue));
    };
    console.log(currentDate,"currentDate")
    return (
      <input
        style={{ maxWidth: "160px", display: "inline-block" }}
        className="input-field-widget"
        type="date"
        value={dateAsString}
        onChange={onChangeWithDate}
        max = {currentDate}
        min = {inputValType === "TO" ? currentFromDate : null}
      />
    );
  }

  renderReadInputElement(value, onChange) {
    // console.log("renderInputElement@@@@@@@@@@@@@@@@@@", value)
    if (typeof value == "object" && value && value[1]) {
      value = "";
    }
    const dateAsString = value ? dateFormat(value, "isoDate") : null;

    return (
      <input
        readOnly={true}
        style={{ maxWidth: "160px", display: "inline-block" }}
        className="input-field-widget"
        type="date"
        value={dateAsString}
      />
    );
  }
}

DateWidget.defaultOperator = operators.NOCONDITION;
DateWidget.defaultValue = new Date();

export default DateWidget;

import React, { Component } from "react";
import { reduxForm, change } from "redux-form";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import products_services from "../../../services/products_services";
import Skeleton from "react-loading-skeleton";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import styles from "./userManagementForm.module.scss"; 
import info from "../../../assets/images/info.png";
import infoaccu from "../../../assets/images/infoaccu.png"

let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const valueSort = (a, b) =>
  (a["value"] > b["value"] && 1) || (a["value"] === b["value"] ? 0 : -1);

class TrainingAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      status: this.props.status,
      resultData: this.props.resultData,
      LSresponseData: [],
      selItemforMultiInput: {},
      query: "",
      show: false,
      showBtn: false,
      courseSearchValue: "",
      showErrorAssign: false,
      showErrorUnassign: false,
      Sitetype:localStorage.getItem('site_type'),
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
    };
  }

  componentDidMount() {
    this.loadProdctList();
  }

  loadProdctList() {
    var vParams = {
      userid: this.props.initialValues.user_id,
      languagecode: "en-US", //this.props.initialValues.language_code,
    };
    products_services
      .getproducts_all(vParams)
      .then((res) => {
        return res;
      })
      .then((data) => {
        this.setState({
          resultData: data.data,
          load: false,
        });
      });
  }

  handleInputChange = (event) => {
    this.setState(
      {
        query: event.target.value,
      },
      () => {
        this.filterArray();
      }
    );
  };

  handleInputFocus = (event) => {
    this.setState(
      {
        query: event.target.value,
        blur: 0,
      },
      () => {
        this.filterArray();
      }
    );
  };

  handleInputBlur = (event) => {
    this.setState(
      {
        blur: 1,
      },
      () => {
        this.filterArray();
      }
    );
  };

  filterAssignCourses = () => {
    let xresData =
      this.state.resultData && this.state.resultData.length
        ? this.state.resultData
        : [];
    let resData = [];
    for (let i = 0; i < xresData.length; i++) {
      if (xresData[i].delflag === 0) {
        resData.push(xresData[i]);
      }
    }
    let searchString = this.state.courseSearchValue.toLowerCase();
    let responseData =
      this.state.blur === 1
        ? []
        : this.state.resultData.length
        ? this.state.resultData
        : [];
    if (searchString.length > 0 && responseData) {
      const filteredOptions = responseData.filter(
        (x) =>
          x &&
          x.value &&
          x.delflag === 1 &&
          x.value.toLowerCase().indexOf(searchString) > -1
      );
      resData = filteredOptions;
    } else {
      const filteredOptions = responseData.filter(
        (x) => x && x.value && x.delflag === 1 //&& x.value.toLowerCase().indexOf(searchString) > -1
      );
      resData = filteredOptions;
    }
  };

  filterArray = () => {
    let searchString = this.state.query.toLowerCase();
    let responseData =
      this.state.blur === 1
        ? []
        : this.state.resultData.length
        ? this.state.resultData
        : [];
    if (searchString.length > 0 && responseData) {
      const filteredOptions = responseData.filter(
        (x) =>
          x &&
          x.value &&
          x.delflag === 1 &&
          x.value.toLowerCase().indexOf(searchString) > -1
      );
      this.setState({
        LSresponseData: filteredOptions,
      });
    } else {
      const filteredOptions = responseData.filter(
        (x) => x && x.value && x.delflag === 1 //&& x.value.toLowerCase().indexOf(searchString) > -1
      );
      this.setState({
        LSresponseData: filteredOptions,
      });
    }
  };

  searchCourse = (e) => {
    this.setState({ courseSearchValue: e.target.value });
  };

  ToastMessageDetails(vStatus, vMessage) {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: vStatus, //'Success',
        message: vMessage, //'The user is created successfully',
        changeInToast: new Date(),
      },
      errorType: null,
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 5000);
  }

  groupTableHeader = (filterGroup) => {
    return (
      <>
        {this.state.showToast.show ? (
          <ToastMessage
            showTime={this.state.showToast.showTime}
            show={this.state.showToast["show"]}
            helpers={this.state.showToast}
          ></ToastMessage>
        ) : (
          ""
        )}
        <div className={`col-xl-12 col-sm-12 col-xs-12 ${styles.userTraining}`}>
          <div
            className="col-sm-1 sm-icn"
            style={{ padding: "0px", marginRight: "-3px" }}
          >
            <button
              className="button-style primary-btn"
              style={{ width: "max-content" }}
              onClick={this.setAssign}
            >
              {this.props.labels.labels.LBLASSIGNCOURSES}
            </button>
          </div>
          
          <input
            className="input-field"
            style={{
              width: "auto",
              margin: "0px",
              float: "right",
              marginBottom: "5px",
            }}
            placeholder={this.props.labels.labels.LBLSEARCH}
            value={this.state.courseSearchValue}
            onChange={this.searchCourse}
            onKeyDown={this.searchCourse}
            ref="myInput"
          />
        </div>
        {console.log("this.state.LSresponseData.length",this.state.LSresponseData,this.state.LSresponseData.length,)}
        {/* {console.log("this.state.resultData.length",this.state.resultData.length)} */}
         {filterGroup.length > 0 ? 
         <div className={this.state.Sitetype==='SafeStart'?styles.usertraingDescrip :styles.usertraingaccuDescrip}>
          <img className={styles.trainingimg} src= {this.state.Sitetype==='SafeStart'?info : infoaccu } alt = ""/>
         <div className={styles.usertraingDescriptext}>{this.props.labels.labels.LBLSAVECOURSE}</div>
          </div> 
          :""} 
        
        {this.state.assign === true ? (
                 
          <>
            <div className={ `row col-sm-12 ${styles.modPop}`} style={{ display: "flex" }}>
              <Modal
                show={this.state.show}
                onHide={this.setAssign}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton className='alert-modal-header'>
                  <Modal.Title className="alert-modal-title">
                      {this.props.labels.labels.LBLASSIGNCOURSES}
                  </Modal.Title>
                </Modal.Header >
                <Modal.Body style={{marginTop:"5px"}}>
                  <div
                    className="col-md-12 required-field text-center renderOption"
                    style={
                      this.state.showErrorAssign
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    {this.props.labels.labels.LBLCOURSENOTASSIGN}
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className= {styles.pageSubTitle}>
                      {this.props.labels.labels.LBLCOURSENAME}
                    </div>
                    <input
                      className="input-field"
                      style={{
                        width: "auto",
                        margin: "0px",
                        float: "right",
                        marginBottom: "5px",
                      }}
                      placeholder={this.props.labels.labels.LBLSEARCH}
                      onChange={this.handleInputChange}
                      onKeyDown={this.handleInputChange}
                      ref="myInput"
                    />
                  </div>
                  {this.state.LSresponseData.length > 0 ? (
                    <div>
                      <ul
                        className={styles.listGroup}
                        style={{ width: "100%", overflowX: "auto" }}
                      >
                        {this.state.LSresponseData.sort(valueSort).map(
                          (item) => (
                            <li
                            className={styles.listGroupItem}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <label
                                className="check-lbl"
                                style={{
                                  marginBottom: "0px",
                                  marginLeft: "0px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  data-toggle="modal"
                                  checked={this.state.selItemforMultiInput.hasOwnProperty(
                                    item.id
                                  )}
                                  onClick={this.onItemCheckStateChange.bind(
                                    this,
                                    item
                                  )}
                                />
                                <span className="checkmark"></span>
                                <span
                                  style={{
                                    display: "block",
                                    paddingTop: "6px",
                                    textAlign: "left",
                                  }}
                                >
                                  {item.value}
                                </span>
                              </label>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  ) : (
                    <div>
                      <ul
                        className={styles.listGroup}
                        style={{ width: "100%", overflowX: "auto" }}
                      >
                        <li
                          className={styles.listGroupItem}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <label
                            className="check-lbl"
                            style={{ marginBottom: "0px", marginLeft: "0px" }}
                          >
                            <span
                              style={{
                                display: "block",
                                paddingTop: "6px",
                                textAlign: "left",
                              }}
                            >
                              {<EmptyState image={emptyImage} text2={this.props.labels.labels.LBLNOCOURSES}/>}
                              
                              
                            </span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  )}
                </Modal.Body>
                {this.state.LSresponseData.length > 0 ? (
                  <Modal.Footer>
                      <button
                        className="button-style secondary-btn"
                        onClick={this.setAssign}
                      >
                        {this.props.labels.labels.LBLCANCEL}
                      </button>
                      <button
                        className="button-style primary-btn"
                        onClick={this.updateTrainingAssignment}
                      >
                        {this.props.labels.labels.LBLSAVE}
                      </button>
                  </Modal.Footer>
                ) : (
                  ""
                )}
              </Modal>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  render() {
    const {
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLASSIGNED,
      LBLSAVE,
      LBLSAVECOURSE,
    } = this.props.labels.labels;
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    
   
    let groupcolumns = [
      {
        name: this.props.labels.labels.LBLCOURSENAME,
        selector: "name",
        sortable: true,
        width: "85%",
        compact: true,
        cell: (row) => (
          <div>
            <label className="show-sm">
              {this.props.labels.labels.LBLCOURSENAME}
            </label>
            {row.name}
          </div>
        ),
      },
      {
        name: LBLASSIGNED,
        selector: "order",
        sortable: true,
        width: "15%",
        headerAlign: "center",
        className: "cssCenter",
        style: { textAlign: "center" },
        compact: true,
        cell: (row) => (
          <div className="w100">
            <div className="icoBtn">
              <label className="check-lbl">
                <input
                  type="checkbox"
                  data-toggle="modal"
                  checked={
                    this.state.selItemforMultiInput.hasOwnProperty(row.id)
                      ? false
                      : true
                  }
                  onChange={this.onItemCheckStateChange.bind(this, row)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        ),
      },
    ];
    let xresData =
      this.state.resultData && this.state.resultData.length
        ? this.state.resultData
        : [];
    let resData = [];
    for (let i = 0; i < xresData.length; i++) {
      if (xresData[i].delflag === 0) {
        resData.push(xresData[i]);
      }
    }
    const filterGroup = resData.filter((item) => {
      if (
        item.name &&
        item.name
          .toLowerCase()
          .includes(this.state.courseSearchValue.toLocaleLowerCase()) //&& item.isAssigned == selectedValue
      ) {
        return item;
      }
      return null;
    });   
    let groupdata = filterGroup;
    let groupTableHeader = this.groupTableHeader(filterGroup);
    return (
      <div className="usr-mang-datatable">
        <div className="row">
          <div className={`row col-sm-12 ${styles.modPop}`}>
            {this.state.load ? (
              <div style={{ width: "100%" }}>
                <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                  <header className="sc-fzoXzr hlidmt">
                    <Skeleton height={41} />
                  </header>
                  <div className="sc-fznWqX gnahTY">
                    <div className="sc-AxjAm gIMaKV rdt_Table">
                      <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                        <Skeleton height={56} />
                      </div>
                      <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                        <Skeleton height={480} />
                      </div>
                    </div>
                  </div>
                  <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                    <Skeleton height={56} />
                  </nav>
                </div>
                <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                  <header className="sc-fzoXzr hlidmt">
                    <Skeleton height={34} />
                  </header>
                  <div className="sc-fznWqX gnahTY">
                    <div className="sc-AxjAm gIMaKV rdt_Table">
                      <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                        {skel.map((i) => {
                          return (
                            <div
                              id={"row-" + i}
                              className="sc-fzoLsD doBktq rdt_TableRow"
                            >
                              <Skeleton height={90} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                    <div className="sc-fzpmMD ftIMtk">
                      <Skeleton height={40} />
                    </div>
                  </nav>
                </div>
              </div>
            ) : (
              <>
                <div
                  className="col-md-12 required-field text-center renderOption"
                  style={
                    this.state.showErrorUnassign
                      ? { display: "block"}
                      : { display: "none" }
                  }
                >
                  {this.props.labels.labels.LBLCOURSENOTUNASSIGN}.
                </div>
                {(this.state.assign || true) === true ? (
                  <>
                    <DataTable
                      id="assignedlist"
                      columns={groupcolumns}
                      data={groupdata}
                      className="datatable-list"
                      pagination
                      subHeader
                      subHeaderComponent={groupTableHeader}
                      noDataComponent={<EmptyState image={emptyImage} text2={this.props.labels.labels.LBLNORECORDS}/>}
                      paginationComponentOptions={paginationComponentOptions}
                    />
                  </>
                ) : ( 
                  <></>
                 )} 
                <div
                  className="col-sm-12"
                  style={{
                    display: groupdata.length > 0 ? "inline-block" : "none",
                    marginTop: "10px",
                  }}
                >
                  <button
                    type="button"
                    className="button-style primary-btn"
                    style={{
                      display: resData.length === 0 ? "" : "inline-block",
                      float: "right",
                    }}
                    onClick={this.unAssign}
                  >
                    {this.props.labels.labels.LBLSAVE}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
       
       
    );
  }

  getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  closeModal = () => {
    this.setState({ show: false });
  };

  updateTrainingAssignment = () => {
    let postData = { productUsers: [] };
    let todaydate = this.getTodayDate();
    for (let xkey in this.state.selItemforMultiInput) {
      let xitem = this.state.selItemforMultiInput[xkey];
      let item = {
        id: this.props.initialValues.user_id,
        productid: [xitem.id],
        delflag: 0,
        assigned_date: todaydate,
      };
      postData.productUsers.push(item);
    }
    if (postData.productUsers.length === 0) {
      this.setState({ showErrorAssign: true });
    } else {
      this.setState({
        load: true,
        assign: false,
        selItemforMultiInput: {},
        resultData: [],
        showErrorAssign: false,
      });
      HttpInterceptor.post("updateUsersProducts", postData).then((res) => {
        this.ToastMessageDetails(
          "Success",
          this.props.labels.labels.LBLUPDTSCCESS
        );
        this.loadProdctList();
      });
    }
  };

  unAssignCourse = (xitem) => {
    let postData = { productUsers: [] };
    let todaydate = this.getTodayDate();
    let item = {
      id: this.props.initialValues.user_id,
      productid: [xitem.id],
      delflag: 1,
      assigned_date: todaydate,
    };
    postData.productUsers.push(item);
    if (postData.productUsers.length === 0) {
    } else {
      this.setState({
        load: true,
        assign: false,
        selItemforMultiInput: {},
        resultData: [],
      });
      HttpInterceptor.post("updateUsersProducts", postData).then((res) => {
        this.ToastMessageDetails(
          "Success",
          this.props.labels.labels.LBLUPDTSCCESS
        );
        this.loadProdctList();
      });
    }
  };

  unAssign = () => {
    let postData = { productUsers: [] };
    let todaydate = this.getTodayDate();
    for (let xkey in this.state.selItemforMultiInput) {
      let xitem = this.state.selItemforMultiInput[xkey];
      let item = {
        id: this.props.initialValues.user_id,
        productid: [xitem.id],
        delflag: 1,
        assigned_date: todaydate,
      };
      postData.productUsers.push(item);
    }
    if (postData.productUsers.length === 0) {
      this.setState({ showErrorUnassign: true });
    } else {
      this.setState({
        load: true,
        assign: false,
        selItemforMultiInput: {},
        resultData: [],
        showErrorUnassign: false,
      });
      HttpInterceptor.post("updateUsersProducts", postData).then((res) => {
        this.ToastMessageDetails(
          "Success",
          this.props.labels.labels.LBLUPDTSCCESS
        );
        this.loadProdctList();
      });
    }
  };

  onItemCheckStateChange = (item) => {
    if (this.state.selItemforMultiInput.hasOwnProperty(item.id)) {
      this.setState({ showErrorUnassign: false, showErrorAssign: false });
      this.dropItemFromMultiInput(item);
    } else {
      this.setState({ showErrorUnassign: false, showErrorAssign: false });
      this.addItemtoMultiInput(item);
    }
  };

  addItemtoMultiInput = (item) => {
    let tmpselItemforMultiInput = this.state.selItemforMultiInput;
    tmpselItemforMultiInput[item.id] = item;
    this.setState({
      selItemforMultiInput: tmpselItemforMultiInput,
    });
  };

  dropItemFromMultiInput = (item) => {
    let tmpselItemforMultiInput = this.state.selItemforMultiInput || {};
    delete tmpselItemforMultiInput[item.id];
    this.setState({
      selItemforMultiInput: tmpselItemforMultiInput,
    });
  };

  submitting = () => {
    if (Object.keys(this.state.selItemforMultiInput).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  setAssign = () => {
    if (this.state.assign === true) {
      this.setState({
        assign: false,
        selItemforMultiInput: {},
        show: false,
        showBtn: true,
        query:""
      });
    } else {
      this.setState({
        assign: true,
        selItemforMultiInput: {},
        show: true,
        showBtn: false,
      });
    }
    this.filterArray();
  };
}

TrainingAssignment = reduxForm({
  form: "trainingAssignmentform", // a unique name for this form
  enableReinitialize: true,
})(TrainingAssignment);

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default connect(null, mapDispatchToProps)(TrainingAssignment);

import { HttpInterceptor } from '../../../auth/HttpInterceptor';
class ReportServices {
    async getCourseList() {
        const response = await HttpInterceptor.get('courselist');
        return response.data;
    }
    async getRbac(role) {
        const response = await HttpInterceptor.get('rbac?role=' + role);
        return response.data;
    }
    async getLearnerProgressList(data) {
        const response = await HttpInterceptor.post('learnerprogresslist', data);
        return response.data;
    }
    async getLearnerResultList(data) {
        const response = await HttpInterceptor.post('learnerresultlist', data);
        return response.data;
    }
    async getLearnerDetailResultList(data) {
        const response = await HttpInterceptor.post('learnerdetailresultlist', data);
        return response.data;
    }
    async getLearnerProgressExport(data) {
        const response = await HttpInterceptor.post('learnerprogresslist_export', data);
        return response.data;
    }
    async getLearnerResultExport(data) {
        const response = await HttpInterceptor.post('learnerresultlist_export', data);
        return response.data;
    }
    async getLearnerDetailResultExport(data) {
        const response = await HttpInterceptor.post('learnerdetailresultlist_export', data);
        return response.data;
    }


    async getRYS_D1_List(data) {        
        const response = await HttpInterceptor.post('rateyourstate_d1_list', data);
        return response.data;
    }
    async getRYS_Conversation_List(data) {        
        const response = await HttpInterceptor.post('reports/rys/conversation', data);
        return response.data;
    }
    async getRYS_Conversation_User_List(vParams,data) {        
        const response = await HttpInterceptor.post(`reports/rys/conversation/users/${vParams}`, data);
        return response.data;
    }
    async getRYS_D2_List(data) {
        const response = await HttpInterceptor.post('rateyourstate_d2_list', data);
        return response.data;
    }
    async getRYS_D3_List(data) {
        const response = await HttpInterceptor.post('rateyourstate_d3_list', data);
        return response.data;
    }
    async getRYS_D1_Export(data) {
        const response = await HttpInterceptor.post('rateyourstate_d1_export', data);
        return response.data;
    }
    async getRYS_D2_Export(data) {
        const response = await HttpInterceptor.post('rateyourstate_d2_export', data);
        return response.data;
    }
    async getRYS_D3_Export(data) {
        const response = await HttpInterceptor.post('rateyourstate_d3_export', data);
        return response.data;
    }

    async getLCP_D1_List(data) {
        const response = await HttpInterceptor.post('lcp_d1_list', data);
        return response.data;
    }

    async getLCP_D1_Export(data) {
        const response = await HttpInterceptor.post('lcp_d1_export', data);
        return response.data;
    }
    async getFilterDatasUnits(data) {
        const response = await HttpInterceptor.post('filterdatasunits', data);
        return response.data;
    }
    async getFilterDatas(data) {
        const response = await HttpInterceptor.post('filterdatas', data);
        return response.data;
    }
    async getSSS_D1_List(data) {
        const response = await HttpInterceptor.post('sss_d1_list', data);
        return response.data;
    }
    async getSSS_D1_INDVDL_List(data) {
        const response = await HttpInterceptor.post('sss_d1_indvdl_list', data);
        return response.data;
    }
    async getSSS_D1_Export(data) {
        const response = await HttpInterceptor.post('sss_d1_export', data);
        return response.data;
    }
    async getSSS_D1_INDVDL_Export(data) {
        const response = await HttpInterceptor.post('sss_d1_indvdl_export', data);
        return response.data;
    }


    async getLCC_D1_List(data) {
        const response = await HttpInterceptor.post('lcc_d1_list', data);
        return response.data;
    }

    async getLCC_D1_Export(data) {
        const response = await HttpInterceptor.post('lcc_d1_export', data);
        return response.data;
    }

    async getLCH_D1_List(data) {
        const response = await HttpInterceptor.post('lch_d1_list', data);
        return response.data;
    }

    async getLCH_D1_Export(data) {
        const response = await HttpInterceptor.post('lch_d1_export', data);
        return response.data;
    }

    async getLCS_D1_List(data) {
        const response = await HttpInterceptor.post('lcs_d1_list', data);
        return response.data;
    }

    async getLCS_D1_Export(data) {
        const response = await HttpInterceptor.post('lcs_d1_export', data);
        return response.data;
    }

    async getCapsuleAssignedUserList(vParams) {        
        let url = 'report/capsule/'+vParams+'/users';
        const response = await HttpInterceptor.get(url);
        return response.data;
    }
    async getRefresherReportList(vParams) {        
        // let url = '/report/products/units/capsules',;
        const response = await HttpInterceptor.post('report/products/units/capsules',{product_id: vParams});
        return response.data;
    }
    async postRCCProdExport(data) {
        const response = await HttpInterceptor.post('report/products/units/capsules/export', data);
        return response.data;
    }
    async postRCCUserExport(data) {
        const response = await HttpInterceptor.post('report/products/units/capsules/users/export', data);
        return response.data;
    }
}
export default new ReportServices();


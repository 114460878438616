import { HttpInterceptor } from "../../../../auth/HttpInterceptor";



class SteercomServices {

    async getTeamList(teamId) {
        let apiUrl = "steercom/teamsetup/steercomteams";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomTeams", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" });
        return response.data;
    }
    async getTeamDetails(teamId) {
        let apiUrl = "steercom/teamsetup/steercomteam/" + teamId;
        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomTeams", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" });
        return response.data;
    }
    async addTeam(data) {
        let apiUrl = "steercom/teamsetup/steercomteam";
        data.check_list = { "request-method": "addSteercomTeam", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" };

        const response = await HttpInterceptor.post(apiUrl, data);
        return response.data;
    }
    async editTeam(data) {
        let apiUrl = "steercom/teamsetup/steercomteam";
        data.check_list = { "request-method": "updateSteercomTeam", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" };

        const response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    } 
    async getTeamMembersList(teamId) {
        let apiUrl = "steercom/teamsetup/teammembers/" + teamId ;

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomTeamMembers", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" });
        return response.data;
    }
    async getPriorTeamMembersList(teamId) {
        let apiUrl = "steercom/teamsetup/priorteammembers/" + teamId ;

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomPriorMembers", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" });
        return response.data;
    }
    async saveTeamMember(teamId,data,callType) {
        let response=[];
        //let apiUrl = "steercom/teamsetup/teammember/"+ teamId ;
        let apiUrl = "steercom/teamsetup/teammember";
        //data.check_list = { "request-method": "saveTeamMember", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" };
        if(callType=="put"){
            data.check_list = { "request-method": "updateTeamMember", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" };
            response = await HttpInterceptor.put(apiUrl, data);
        }else{
            data.check_list = { "request-method": "saveTeamMember", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" };
            response = await HttpInterceptor.post(apiUrl, data);
        } 
        return response.data;
    }
    async deleteTeamMember(data) {
        let response=[];
        //let apiUrl = "steercom/teamsetup/teammember/"+ teamId ;
        let apiUrl = "steercom/teamsetup/steercomteammember";
        //data.check_list = { "request-method": "saveTeamMember", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" };
     
        data.check_list = { "request-method": "deleteTeamMember", "request-service": "/steeringcommittee", "request-modules": "steeringcommittee" };
        response = await HttpInterceptor.delete(apiUrl, {data});
        
        return response.data;
    }
    /* Planning */
    async getTeamSFList(teamId,sfid, data) {
        let apiUrl = "steercom/successfactorssetup/steercomteamsf/"+ teamId +"/" + sfid ;
        data = { "request-method": "getTeamSFList", "request-service": "/successfactors", "request-modules": "successfactors" }
        const response = await HttpInterceptor.get(apiUrl, data);
        return response.data;
    }

    async saveSuccessFactor(data) {
        let apiUrl = "steercom/successfactorssetup/steercomteamsf";
        data.check_list = { "request-method": "saveSuccessFactor", "request-service": "/successfactors", "request-modules": "successfactors" };

        const response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    }

    async saveSFBaseDate(data) {
        let apiUrl = "steercom/successfactorssetup/steercomteamsfbasedate";
        data.check_list = { "request-method": "saveSFBaseDate", "request-service": "/successfactors", "request-modules": "successfactors" };

        const response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    }

    async resetTeamSuccessFactor(data) {
        let apiUrl = "steercom/successfactorssetup/resetsteercomteamsfactivity";
        data.check_list = { "request-method": "resetTeamSuccessFactor", "request-service": "/successfactors", "request-modules": "successfactors" };

        const response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    }

    async getTeamListSetup() {
        let apiUrl = "steercom/successfactorssetup/steercomteams";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomTeams", "request-service": "/successfactors", "request-modules": "successfactors" });
        return response.data;
    }
    async getTeamMembersListSetup(teamId) {
        let apiUrl = "steercom/teamsetup/teammembers/" + teamId ;

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomMembers", "request-service": "/successfactors", "request-modules": "successfactors" });
        return response.data;
    }
    async sendNotification(data) {
        let apiUrl = "steercom/successfactorssetup/steercomsendnotification";
        data.check_list = { "request-method": "sendNotification", "request-service": "/successfactors", "request-modules": "successfactors" };

        const response = await HttpInterceptor.post(apiUrl, data);
        return response.data;
    }
    async updateTeamLanguage(data) {
        let response, apiUrl = "steercom/successfactorssetup/steercomupdatelanguage";
        data.check_list  =  { "request-method": "checkTemplatePlan", "request-service": "/successfactors", "request-modules": "successfactors" } ;
        response = await HttpInterceptor.put(apiUrl, data); 
        return response.data;
    }

    /* Successfactors Plan */
    async getTeamSFListPlan(teamId,sfid, data) {
        let apiUrl = "steercom/successfactorsplan/steercomteamsf/"+ teamId +"/" + sfid ;
        data = { "request-method": "getTeamSFListPlan", "request-service": "/successfactorplan", "request-modules": "successfactors" }
        const response = await HttpInterceptor.get(apiUrl, data);
        return response.data;
    }  
    async getTeamListPlan() {
        let apiUrl = "steercom/successfactorsplan/steercomteams";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomTeams", "request-service": "/successfactorplan", "request-modules": "successfactorplan" });
        return response.data;
    }
    async getTeamMembersListPlan(teamId) {
        let apiUrl = "steercom/successfactorsplan/teammembers/" + teamId ;

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomMembers", "request-service": "/successfactorplan", "request-modules": "successfactorplan" });
        return response.data;
    }

    async saveSuccessFactorPlan(data) {
        let apiUrl = "steercom/successfactorsplan/steercomteamsfactivity";
        data.check_list = { "request-method": "updateSuccessFactor", "request-service": "/successfactorplan", "request-modules": "successfactorplan" };

        const response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    }

    /* Admin Dashboard */
    async getTeamSummary(teamId,sfid,roleid) {
        let apiUrl = "steercom/admindashboard/teamsummary/"+ teamId +"/" + sfid + "/" + roleid;

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getTeamSummary", "request-service": "/steercomdashboard", "request-modules": "steercomdashboard" });
        return response.data;
    }
    async getTeamListDash() {
        let apiUrl = "steercom/admindashboard/steercomteams";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomTeams", "request-service": "/steercomdashboard", "request-modules": "steercomdashboard" });
        return response.data;
    }
    async getTeamSFListDash(teamId,sfid, roleid,data) {
        let apiUrl = "steercom/admindashboard/myteamsf/"+ teamId +"/" + sfid + "/" + roleid;

        data = { "request-method": "getTeamSFList", "request-service": "/steercomdashboard", "request-modules": "steercomdashboard" }
        const response = await HttpInterceptor.get(apiUrl, data);
        return response.data;
    } 

    async getMemberTypeDash() {
        let apiUrl = "steercom/admindashboard/teammembertype";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getMemberType", "request-service": "/steercomdashboard", "request-modules": "steercomdashboard" });
        return response.data;
    }
    async getTeamMembersListDash(teamId) {
        let apiUrl = "steercom/admindashboard/teammembers/" + teamId ;

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomMembers", "request-service": "/steercomdashboard", "request-modules": "steercomdashboard" });
        return response.data;
    }
 
    /* My Task */
    async getMemberTypeMyTask() {
        let apiUrl = "steercom/mytask/teammembertype";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getMemberType", "request-service": "/steercomuserdashboard", "request-modules": "steercomuserdashboard" });
        return response.data;
    }
    async getTeamListMyTask() {
        let apiUrl = "steercom/mytask/steercomteams";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomTeams", "request-service": "/steercomuserdashboard", "request-modules": "steercomuserdashboard" });
        return response.data;
    } 
    async getMyTasks(teamId,sfid,roleid) {
        let apiUrl = "steercom/mytask/mytasks/"+ teamId +"/" + sfid + "/" + roleid;

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getMyTasks", "request-service": "/steercomuserdashboard", "request-modules": "steercomuserdashboard" });
        return response.data;
    } 
    
    async getTeamMembersListMyTask(teamId) {
        let apiUrl = "steercom/mytask/teammembers/" + teamId ;

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getSteercomMembers", "request-service": "/steercomuserdashboard", "request-modules": "steercomuserdashboard" });
        return response.data;
    }

    /* Task Setup */
    async getTeamSFListForTask(teamId,language_code,sfid, data) {
        let apiUrl = "steercom/tasksetup/steercomteamsftask/" + language_code +"/"  + sfid ;
        data = { "request-method": "getSteerComTeamSFListTask", "request-service": "/steercomtasksetup", "request-modules": "steercomtasksetup" }
        const response = await HttpInterceptor.get(apiUrl, data);
        return response.data;
    }
    async saveSuccessFactorTask(data,callType) {
        let apiUrl = "steercom/tasksetup/steercomsftemplates";

        let response;
        if(callType=="add"){
            data.check_list = { "request-method": "addSFTemplate", "request-service": "/steercomtasksetup", "request-modules": "steercomtasksetup" };
            response = await HttpInterceptor.post(apiUrl, data);
        }else{
            data.check_list = { "request-method": "updateSFTemplate", "request-service": "/steercomtasksetup", "request-modules": "steercomtasksetup" };
            response = await HttpInterceptor.put(apiUrl, data);
        } 
        return response.data;
    } 
    async saveNewTemplate(data,callType) {
        let response, apiUrl = "steercom/tasksetup/steercomnewtemplate";  
        data.check_list = { "request-method": "addNewTemplate", "request-service": "/steercomtasksetup", "request-modules": "steercomtasksetup" };
        response = await HttpInterceptor.post(apiUrl, data);
        
        return response.data;
    } 
    async deleteTask(data,callType) {
        let apiUrl = "steercom/tasksetup/steercomstask";
        let response; 
        data.check_list = { "request-method": "deleteSFTemplates", "request-service": "/steercomtasksetup", "request-modules": "steercomtasksetup" };
        response = await HttpInterceptor.put(apiUrl, data);
         
        return response.data;
    }
    async getAllResetTasks(language_code) {
        let apiUrl = "steercom/tasksetup/steercomresetalltask/" +  language_code;
        let data  = { "request-method": "getAllResetTasks", "request-service": "/steercomresetalltask", "request-modules": "steercomresetalltask" }
        const response = await HttpInterceptor.get(apiUrl, data); 
        return response.data;
    } 
    async updateResetTask(data) {
        let response,apiUrl = "steercom/tasksetup/steercomresettask";

        data.check_list  =  { "request-method": "updateResetTask", "request-service": "/steercomresettask", "request-modules": "steercomresettask" } ;
        response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    }  
    async updateResetAllTask(data) {
        let response,apiUrl = "steercom/tasksetup/steercomresetalltask";

        data.check_list  =  { "request-method": "updateResetAllTask", "request-service": "/steercomresetalltask", "request-modules": "steercomresetalltask" } ;
        response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    } 
    async updateTaskDescription(data) {
        let response,apiUrl = "steercom/tasksetup/steercomupdatedesc";

        data.check_list  =  { "request-method": "updateResetTask", "request-service": "/steercomupdatedesc", "request-modules": "steercomupdatedesc" } ;
        response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    } 
    /* Email Settings */
    async getEmailSettings() {
        let apiUrl = "steercom/mailconfiguration/steercommailsettings";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "getEmailSettings", "request-service": "/steercomconfiguration", "request-modules": "steercommailsettings" });
        return response.data;
    } 
    async updateEmailSettings(data) {
        let response,apiUrl = "steercom/mailconfiguration/steercommailsettings";

        data.check_list  =  { "request-method": "updateEmailSettings", "request-service": "/steercomconfiguration", "request-modules": "steercommailsettings" } ;
        response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    } 
     /* User Settings */
     async getStreercomUsers() {
        let apiUrl = "steercom/steercomconfiguration/steercomuserlist";

        const response = await HttpInterceptor.get(apiUrl, { "request-method": "steercomuserlist", "request-service": "/steercomconfiguration", "request-modules": "steercomuserlist" });
        return response.data;
    }

    async saveSteercomUsers(data) {
        let apiUrl = "steercom/steercomconfiguration/steercomusers";
        data.check_list = { "request-method": "steercomusers", "request-service": "/steercomconfiguration", "request-modules": "steercomusers" };

        const response = await HttpInterceptor.post(apiUrl, data);
        return response.data;
    }
  
    /* Role Settings */
    async updateRolesDetails(data,callType) {
        let apiUrl = "steercom/steercomconfiguration/steercomsroles"; 
        //let apiUrl = "steercom/successfactorsplan/steercomteamsfactivity";
        //data.check_list = { "request-method": "updateSuccessFactor", "request-service": "/successfactorplan", "request-modules": "successfactorplan" };

        let response;
        if(callType=="add"){
            data.check_list = { "request-method": "addRolesDetails", "request-service": "/steercomsroles", "request-modules": "steercomsroles" };
            response = await HttpInterceptor.post(apiUrl, data);
        }else{
            data.check_list = { "request-method": "updateRolesDetails", "request-service": "/steercomsroles", "request-modules": "steercomsroles" };
            response = await HttpInterceptor.put(apiUrl, data);
        } 
        return response.data;
    } 
     
    async updateResetRole(data) {
        let response,apiUrl = "steercom/steercomconfiguration/steercomsresetrole"; 
        data.check_list  =  { "request-method": "updateResetRole", "request-service": "/steercomsresetrole", "request-modules": "steercomsresetrole" } ;
        response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    } 
    async checkRoleAssigned() {
        let apiUrl = "steercom/steercomconfiguration/steercomsdeleterole"; 
        const response = await HttpInterceptor.get(apiUrl, { "request-method": "steercomsdeleterole", "request-service": "/steercomconfiguration", "request-modules": "steercomsdeleterole" });
        return response.data;
    }
    async deleteRole(data) {
        let response,apiUrl = "steercom/steercomconfiguration/steercomsdeleterole"; 
        data.check_list  =  { "request-method": "deleteRole", "request-service": "/steercomsdeleterole", "request-modules": "steercomsdeleterole" } ;
        response = await HttpInterceptor.put(apiUrl, data);
        return response.data;
    }   
    async createNewTemplate(data) {
        let apiUrl = "steercom/tasksetup/steercomtemplate";
        data.check_list = { "request-method": "createNewTemplate", "request-service": "/steercomtemplate", "request-modules": "steercomtemplate" };

        const response = await HttpInterceptor.post(apiUrl, data);
        return response.data;
    }
    /* Notification Settings */
    async setValidUserDetails(data) {
        let apiUrl = "steercom/steercomlanding/steercomverification"; 
        data.check_list  = { "request-method": "setValidUserDetails", "request-service": "/steercomverification", "request-modules": "steercomverification" }
        const response = await HttpInterceptor.post(apiUrl, data );
        return response.data;
    }

}  
 
export default new SteercomServices();

//import { common } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import translate_fetch from './translate_fetch';
import Translator from './Translator';


const TranslatedRoutes = ({ component: Component, ...rest }) => {
    const [labels2, setLabel] = useState(null);
    const [compName, setCompName] = useState(rest.comp_name);
    useEffect(() => {
        if (labels2 === null) {
            getLabels()
        }
    }, [labels2]);


    const getLabels = async () => {
        let lang_code = 
        rest.location?.state && rest.location?.state?.course_language ? rest.location?.state?.course_language : rest.location?.hash && rest.location?.hash?.split("~~")[1] ? rest.location?.hash.split("~~")[1] : rest.language ? rest.language : 'en-US';
        if(rest.comp_name === "safestartsurvey") lang_code = rest.location.state.language_code;
        let labelst = await translate_fetch.getLabels({
             comp_name: rest.comp_name, 
            lang_code: lang_code
        });
        if (labelst) {
            setLabel(labelst);
        } else {
            setLabel(null);
        }

    }
    if (compName !== rest.comp_name) {
        setCompName(rest.comp_name);
        getLabels()
    }
    const renderRoute = (props) => {
        //let complabels = Translator.getlabels({ comp_name: rest.comp_name, lang_code: rest.language ? rest.language : 'en-US' });
        let complabels;
        if (labels2) {
            complabels = labels2;
            let lang_code = (rest.comp_name === "safestartsurvey") ? rest.location.state.language_code : rest.language ? rest.language : 'en-US';            
            //let complabels = Translator.getlabels({ comp_name: rest.comp_name, lang_code: rest.language ? rest.language : 'en-US' });
            let commonlabels = Translator.getCommonLabels({ lang_code: lang_code })

            let labels = Object.keys(complabels).reduce((a, key) => {
                a[key] = { ...commonlabels[key], ...complabels[key] }
                return a
            }, {});

            return <Component {...props} {...rest} labels={labels} />
        } else {
            let lang_code = rest.location?.state && rest.location?.state?.course_language ? rest.location?.state?.course_language : rest.location?.hash ? rest.location?.hash?.split("~~")[1] : rest.language ? rest.language : 'en-US';
            if(rest.comp_name === "safestartsurvey") lang_code = rest.location.state.language_code;
            complabels = Translator.getlabels({ comp_name: rest.comp_name, lang_code: lang_code });
            //let complabels = Translator.getlabels({ comp_name: rest.comp_name, lang_code: rest.language ? rest.language : 'en-US' });
            let commonlabels = Translator.getCommonLabels({ lang_code: rest.language ? rest.language : 'en-US' });            
             let labels = Object.keys(complabels).reduce((a, key) => {
                a[key] = { ...commonlabels[key], ...complabels[key] }
                return a
            }, {});

            return <Component {...props} {...rest} labels={labels} />
        }

    }

    return (
        <Route {...rest} render={props => {
            return renderRoute(props)
        }}></Route>
    )
}

export default TranslatedRoutes;
import React from "react";
import "./products.scss";
import Coursepopup from "../coursepopup/Coursepopup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import UnitBannerDescription from "./UnitBannerDescription";
import TransitionPopUp from "./TransitionPopUp";
import Survey from "../../../survey/Survey";
import survey_services from "../../../survey/survey_services";
import SurveyAlert from "../../../survey/SurveyAlert";

class UnitDescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showPopup: false,
            resultData: [{}],
            showToast: {
                show: false,
                alertClass: "",
                status: "",
                message: "",
                changeInToast: new Date(),
            },
            feedbackData:{},
            showFeedbackPopUp:false,
            showFeedbackTransition:true,
            labels:{},
            postSurveyData:{},
            showPostSurvey:false,
            showPostSurveyAlert:false
        };
        this.togglePopup = this.togglePopup.bind(this);
        this.showCourseDetails = this.showCourseDetails.bind(this);
    }

    componentDidMount = () => {
        this.loadUnitDescription();
    };

    loadUnitDescription = () => {
        if (this.props.location.state === null) {
            this.props.history.push("/home/products");
        } else {
            console.log("dateformat", this.props.dateformat)
            const dataArray = [];
            dataArray.push(this.props?.location?.state?.resultData)
            this.setState({
                resultData: dataArray,
            });
            this.setState({ loading: false });
            this.fetchFeedbackTransltion(this.props?.location?.state?.resultData);
            console.log(this.props.survey,"unitdescriptionsurveyid")
            if(this.props.survey === "") {
                this.checkPostSurvey();
            }
        }
    };

      fetchFeedbackTransltion =  async (units) => {
        try {
          const url = `${process.env.REACT_APP_YOUFACTORS_TRANSLATION_URL}products/products.json`;
          const response = await fetch(url, { cache: "force-cache" });
          if (response.status === 200) {
            const data = await response.json();
            console.log(data[`${units.languagecode}`] ? data[`${units.languagecode}`].labels : data["en-US"].labels, "dataaa");
            this.setState({labels:(data[`${units.languagecode}`] ? data[`${units.languagecode}`].labels : data["en-US"].labels)});
          } else {
            console.log("Failed to fetch data");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      checkfeedback = () => {
        var vParams = {
          userid: this.props.userid,
          productid: this.props.location.state.productID,
        };
        survey_services.getfeedback(vParams).then((res) => {
          this.setState({feedbackData:res?.data});
         console.log(res?.data,"feedbackresponse");
        })
        .catch((error) => {
          console.error("Error while fetching Feedback status:", error);
        });
      };

      checkPostSurvey = () => {
        var vParams = {
          userid: this.props.userid,
        };
        survey_services.getpostsurvey(vParams).then((res) => {
          this.setState({postSurveyData:res?.data, showPostSurveyAlert:res?.data.survey_id})
         if(res?.data.survey_id === "") {
           this.checkfeedback();
         }
        })
        .catch((error) => {
          console.error("Error while fetching postSurveyData status:", error);
        });
      };

    showCourseDetails = (vProductunitKey, vEngineType, activityDetails) => {
        var vCourseID, vActivityID, vCapsuleID, vCourseVersion;
        if (vEngineType === "Capsule") {
            vActivityID = activityDetails.activity_id;
            vCapsuleID = activityDetails.capsule_id;
            vCourseID = activityDetails.capsule_content_id;
            vCourseVersion = activityDetails.capsule_content_version;
            vProductunitKey = 0;
        } else {
            vCourseVersion = this.state.resultData[vProductunitKey].course_version;
            vCourseID = this.state.resultData[vProductunitKey].courseid;
            vActivityID = this.state.resultData[vProductunitKey].activity_id;
        }

        var vUnitsInfoID = this.state.resultData[vProductunitKey].unitsinfoid;
        var vProductUnitID = this.state.resultData[vProductunitKey].unitid;
        var vProductID = this.state.resultData[vProductunitKey].productid;
        var vCourseCompletionStatus =
            this.state.resultData[vProductunitKey].CourseCompletionStatus;
        var vUnitname = this.state.resultData[vProductunitKey].units;
        var vUnitDesc = this.state.resultData[vProductunitKey].description;
        var vLanguageCode = this.state.resultData[vProductunitKey].languagecode;
        var vEngineVersion = this.state.resultData[vProductunitKey].engineversion;

        var vCourseDet = {
            courseid: vCourseID,
            unitcompletionstatus: vCourseCompletionStatus,
            unitname: vUnitname,
            unitdesc: vUnitDesc,
            unitsinfoid: vUnitsInfoID,
            languagecode: vLanguageCode,
            courseversion: vCourseVersion,
            engineType: vEngineType,
            activityid: vActivityID,
            capsuleid: vCapsuleID,
            trainingType: "CourseUnits",
            engineVersion: vEngineVersion,
        };
        this.setState({
            showPopup: !this.state.showPopup,
            productid: vProductID,
            unitid: vProductUnitID,
            unitedetails: vCourseDet,
            showModalDetails: false,
        });
    };

    togglePopup = (e) => {
        let vProductunitKey = 0;
        console.log(vProductunitKey, "vProductunitKey")
        if (typeof (e) === 'object') {
            vProductunitKey = e.currentTarget.dataset.div_key;
        } else if (typeof (e) === 'number') { vProductunitKey = e };
        if (
            vProductunitKey !== undefined &&
            this.state.resultData[vProductunitKey]?.unit_launch_type === "classroom"
        ) {
            return;
        }
        if (vProductunitKey !== undefined) {
            this.showCourseDetails(vProductunitKey, "Course");
        }
        if (vProductunitKey === undefined) {
            console.log("capsuleclosed")
            this.loadUnitDescription();
            this.setState({
                showPopup: !this.state.showPopup,
                productid: undefined,
                unitid: undefined,
                unitedetails: undefined,
            });
        }
    };

    handleClose = () => {
        this.setState({ showCert: false });
        document.getElementById("root").className = "";
    };

    goBackHandler = () => {
        if (this.props.location.pathname === "/schome/unit-detail") {
            this.props.history.push({
                pathname: "/schome/product-lists",
                state: {
                    productid: this.props.location.state.productID,
                    companyid: this.props.companyid,
                    userid: this.props.userid,
                    productName: this.props.location.state.productName,
                    bgThumbnail: this.props.location.state.bgThumbnail,
                    selectedTab: 2
                },
            });
        } else {
            this.props.history.push({
                pathname: "/home/product-lists",
                state: {
                    productid: this.props.location.state.productID,
                    companyid: this.props.companyid,
                    userid: this.props.userid,
                    productName: this.props.location.state.productName,
                    bgThumbnail: this.props.location.state.bgThumbnail,
                    selectedTab: 2
                },
            });
        }
    };

    getImageSource = (res) => {
        const baseUrl = res.engineversion === "2" ? process.env.REACT_APP_CRSENGINE2_IMAGEURL : process.env.REACT_APP_RESOURCES_URL;
        const imageName = res?.prod_unit_imagename?.replace("jpg", "png");
        return `${baseUrl}image/${res.courseid}/${imageName}`;
    };

    handleTransitionClose = () => {
        this.setState({showFeedbackTransition:false});
    };
    
    handleFeedback = () => {
        this.setState({showFeedbackPopUp:true,showFeedbackTransition:false});
    };
    
    handleFeedBackClose = () => {
        console.log("feedback Completion called")
        this.setState({showFeedbackPopUp:false});
    };

    showFeedbackToast = (message) => {
        this.setState({
          showToast: {
            show: true,
            alertClass: "success",
            status: "Success",
            message: message,
            changeInToast: new Date(),
          },
        });
    
        setTimeout(() => {
          this.setState({
            showToast: {
              show: false,
              alertClass: "",
              status: "",
              message: "",
              changeInToast: new Date(),
            },
          });
          this.checkfeedback();
        }, 2000);
      }

      handleFeedbackCompletion = () => {
        this.setState({showFeedbackPopUp:false});
        this.showFeedbackToast(this.props?.labels?.labels?.LBLFEEDBACKCOMPLETED);
      };
      handleSurvey = () => {
        this.setState({showPostSurvey:true});
      };
    
      handlePostSurveyClose = () => {
        this.setState({showPostSurvey:false,showPostSurveyAlert:false});
      };
      
      handleSurveyCompletion = () => {
        this.showFeedbackToast(this.props?.labels?.labels?.LBLPOSTSURVEYCOMPLETED)
      };

    render() {
        return (
            <div className="row productCtn">
                <ToastMessage
                    show={this.state.showToast["show"]}
                    helpers={this.state.showToast}
                ></ToastMessage>
                {this.state.showPopup}
                {this.state.showPopup ? (
                    <Coursepopup
                        text='Click "Close Button" to hide popup'
                        closePopup={this.togglePopup.bind(this)}
                        showPopup={this.state?.showPopup}
                        Params1={this.state?.productid}
                        Params2={this.state?.unitid}
                        unitedetails={this.state?.unitedetails}
                        resultDataString={this.props?.location?.state?.resultDataString}
                        dateformat={this.props?.dateformat}
                        courseName = {this.props.location.state.productName}
                        gameAvailable = {this.props?.location?.state?.gameAvailable}
                        isModule = {this.props?.location?.state?.isModule}
                    ></Coursepopup>
                ) : null}
                <div className="col-xl-12">
                    {this.state.loading || this.state.resultData.length === 0 ? (
                        <Skeleton height={180} style={{ transform: "scale(1, 1)" }} />
                    ) : (
                        this.state?.resultData?.map((res, i) => {
                            console.log(res, "resres")
                            return (
                                <div className={`mob-pad ${this.state.listView ? "capsule-card" : "capsule-list"}`}>
                                    <div className="page-header-field">
                                        <h3 className="page-title">
                                            <i
                                                className="fas fa-arrow-left cuBackBtn"
                                                aria-hidden="true"
                                                onClick={this.goBackHandler}
                                            ></i>
                                            {res.products}
                                        </h3>
                                    </div>
                                    <hr className="head-line" />
                                    <div
                                        className="row img-card-pad"
                                        style={{ marginLeft: "0px" }}
                                    >
                                        {!this.state.listView ? (
                                            <div
                                                className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                                style={{
                                                    width: "100%",
                                                    maxWidth: "inherit",
                                                    marginBottom: "15px",
                                                    paddingLeft: "0px",
                                                }}
                                            >
                                                <div
                                                    className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                                    style={{
                                                        width: "100%",
                                                        maxWidth: "inherit",
                                                        marginBottom: "15px",
                                                        paddingRight: "0px",
                                                        paddingLeft: "0px",
                                                    }}
                                                >
                                                    {this.state.loading === false ?
                                                        <UnitBannerDescription
                                                            unitImage={this.getImageSource(res)}
                                                            unitDesc={res.unitDescription}
                                                            unitAverageTime={res.unitEstimationTime}
                                                            labels={this.props.labels.labels}
                                                            unitName={res.units}
                                                            description={res.description}
                                                            onClick={() => this.togglePopup(i)}
                                                            unitModuleCount={res.unit_module_count}
                                                        />
                                                        :
                                                        null
                                                    }
                                                    <div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
                {this.state.showFeedbackTransition && this.state.feedbackData?.feedback_available === "1" && Object.keys(this.state.feedbackData?.feedback_details).length > 0 && this.state.feedbackData?.feedback_id === "" && Object.keys(this.state.labels).length > 0 ?
                    <TransitionPopUp
                        labels={this.state.labels}
                        handleStartnow={this.handleFeedback}
                        handleTransitionClose={this.handleTransitionClose}
                    />
                    :
                    null
                }
                {this.state.showFeedbackPopUp ?
                    <Survey
                        hide={this.handleFeedBackClose}
                        surveyId={this.state.feedbackData?.feedback_details?.capsule_content_id}
                        feedback_file_name = {this.state.feedbackData?.feedback_file_name}
                        handleFeedbackCompletion={this.handleFeedbackCompletion}
                        labels={this.state.labels}
                        surveyType="feedback"
                        launch_data={this.state.feedbackData?.feedback_details?.encref}
                        closeButton = {true}
                        showFeedbackToast = {this.showFeedbackToast}
                        languagecode = {this.state.resultData[0].languagecode}
                        productID = {this.props.location.state.productID}
                    />
                    :
                    null
                }
                {this.state.showPostSurveyAlert ?
                    <SurveyAlert
                        handleSurvey={this.handleSurvey}
                        skip={false}
                        label={this.props.labels.labels}
                        title={this.props.labels.labels.LBLPOSTSURVEY}
                        description={this.props.labels.labels.LBLMOMPOSTSURVEY}
                    />
                    :
                    null
                }

                {this.state.showPostSurvey ?
                    <Survey
                        hide={this.handlePostSurveyClose}
                        surveyId={this.state.postSurveyData?.survey_id}
                        handleSurveyCompletion={this.handleSurveyCompletion}
                        labels={this.props.labels.labels}
                        surveyType="post"
                        closeButton={false}
                    />
                    :
                    null
                }

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userid: state.user_info_reducer.user_info.user_id,
        first_name: state.user_info_reducer.user_info.first_name,
        last_name: state.user_info_reducer.user_info.last_name,
        companyid: state.user_info_reducer.user_info.company_id,
        language_code: state.user_info_reducer.user_info.language_code,
        company_logo: state.user_info_reducer.user_info.company_logo,
        dateformat: state.user_info_reducer.user_info.dateformat,
        survey:state.user_info_reducer.user_info.survey
    };
};

export default connect(mapStateToProps, null)(UnitDescription);

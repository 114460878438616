import React, { Component } from "react";
//import { connect } from "react-redux";
//import { createBrowserHistory } from 'history';
import { withRouter } from "react-router";
import $ from 'jquery';
//import CanvasJSReact from "../../../assets/canvasjs/canvasjs.reactdashbrd";
import { da } from "date-fns/locale";
import { parse } from "crypto-js/enc-base64";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
//import highchartsGantt from "highcharts/modules/gantt";  

//highchartsGantt(Highcharts); 
require("highcharts/modules/exporting")(Highcharts);
//var CanvasJSChart = CanvasJSReact.CanvasJSChart;
//let chart = null;
const cstLabels = {
  "LBLCOMPLEVEL":"Completion Level",
}
class StackedColumn100Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
    };
  }

  componentDidMount() {
    this.chartDetails();
    var chart = this.chart;
    $('body').on("onresize", function () {
      //console.log("doughnut resize")
      chart.render();
    })
  }
  componentWillUnmount() {
    $('body').off("onresize")
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.chartDetails();
    }
  }

  onBarClick = (e) => {
    if (this.props.onElementClick) {
      this.props.onBarClick("AverageRYS-State", e.point) //e.dataPoint
    }
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getOption = (data)=>{
    let pieColors = ["#A6D486", "#FFFF99", "#FF8F8F"]; //7DB1F5  F5C947 C8C6C6 
    let gData=[]
    let objData =[];  
    let unitslength = 0
    let { LBLCOMPLACENCY,LBLFATIGUE, LBLFRUSTRATION,LBLRUSHING} = this.props?.labels?.labels;   
    let categories = ["",LBLRUSHING,LBLFRUSTRATION,LBLFATIGUE, LBLCOMPLACENCY];
    let total = [9,10,9,10,9] 
    let chart_data = {
      chart: {
          type: 'bar',
		      height : 270,
          marginTop: 10, 
          style: {
            fontFamily: "'Roboto', sans-serif"
          }
      },
      legend: {
        // align: 'left',
        // x: 70,
        // verticalAlign: 'top',
        // y: 70,
        // floating: true,
        // backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white', 
          borderColor: '#CCC',
        // borderWidth: 1,
        // shadow: false
        //width: '100%',
        itemStyle: { 
          fontWeight: '450',
          fontSize: '12px',
          border:'1px solid black',
          fontFamily: "'Roboto', sans-serif", 
      }
    },
      credits: { 
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<b>{point.tpcount}</b><br/>',
        //pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
        pointFormat: this.getLabel("LBLTOTALRESPONSES") + ": <strong>{point.tpcount}</strong> <br/>" + this.getLabel("LBLAVGRYSRATINGS") + ": <strong>{point.y} </strong>"
      },
      title: {
          text: null ,
          align: 'left',
      },
      subtitle: {
          text: null ,
          align: 'left'
      },
      plotOptions: {
        colors: pieColors,  
        //click: this.onBubbleClick,
        bar: {
          stacking: 'normal',
          pointPadding: 0,
          groupPadding: 0,
          dataLabels: {
            enabled: true
          }
          // ,
          // formatter: function() {
          //   if (this.tpcount) {
          //     return this.tpcount;
          //   }
          // }
        }, 
        series: {
          stacking: 'normal',
          pointWidth: 28,
          cursor: this.props.onElementClick? 'pointer' : 'default',
          events: { 
            click: this.onBarClick,
          },
          dataLabels: {
            enabled: true,
            formatter: function() {
              if (this.y) {
                return this.y;
              }
            }
          }
        }
    },
    xAxis: { 
        categories: categories ,
        labels: { 
          style: {
            fontSize: '1em',  
            fontWeight:450
          }
        }
    },
    yAxis: { 
      gridLineColor: 'transparent', 
      min: 0,
      max: 10,
      title: {
          text: this.getLabel("LBLAVGRYSRATINGS"),
          style: { 
            fontSize:12,
            fontWeight:450 
        },
      },
      style: { 
        fontWeight:450
      },  
      stackLabels: {
        style: {
            color: 'black' 
        }, 
        enabled:false,
        horizontallAlign: 'top',
        x:$(".s-card").width()-50,
        formatter: function() {   
          return (total[0]);                                
      }
    }
    },
    series: [{ 
        showInLegend: false,         
        data: objData
    }]
};
   return chart_data;
  }

  chartDetails() { 
    let options_bar=[];
    let gData = {};
    let datasets = [];
    let obj = {};
    let dataPoints = [];
    let dateformat = this.props.dateformat;
    let summaryData = {
      rushing: 0,
      fatigue: 0,
      frustration: 0,
      complacency: 0,
      cnt_rushing: 0,
      cnt_fatigue: 0,
      cnt_frustration: 0,
      cnt_complacency: 0,
    };
    // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@", this.props.data)
    let result = this.props.currentPage === "rysConversation" ? this.props.data : this.props?.data?.datasets[0]?.graphData
    if (this.props.data.datasets && this.props.currentPage !== "rysConversation") {
      if (result) {
        for (var i = 0; i < result.length; i++) {
          let xdate = result[i].created_on_format;//.substr(0, 10).split("/").reverse().join("/")
          if (parseInt(result[i].rushing) >= 0) {
            summaryData.rushing = summaryData.rushing + parseInt(result[i].rushing)
            summaryData.cnt_rushing++
          }
          if (parseInt(result[i].fatigue) >= 0) {
            summaryData.fatigue = summaryData.fatigue + parseInt(result[i].fatigue)
            summaryData.cnt_fatigue++
          }
          if (parseInt(result[i].frustration) >= 0) {
            summaryData.frustration = summaryData.frustration + parseInt(result[i].frustration)
            summaryData.cnt_frustration++
          }
          if (parseInt(result[i].complacency) >= 0) {
            summaryData.complacency = summaryData.complacency + parseInt(result[i].complacency)
            summaryData.cnt_complacency++
          }
        }
      } 
    } else {
      if (result) {
        for (var i = 0; i < result.length; i++) {
          let xdate = result[i].created_on_format;//.substr(0, 10).split("/").reverse().join("/")
          if (parseInt(result[i].rushing) >= 0) {
            summaryData.rushing = summaryData.rushing + parseInt(result[i].rushing)
            summaryData.cnt_rushing++
          }
          if (parseInt(result[i].fatigue) >= 0) {
            summaryData.fatigue = summaryData.fatigue + parseInt(result[i].fatigue)
            summaryData.cnt_fatigue++
          }
          if (parseInt(result[i].frustration) >= 0) {
            summaryData.frustration = summaryData.frustration + parseInt(result[i].frustration)
            summaryData.cnt_frustration++
          }
          if (parseInt(result[i].complacency) >= 0) {
            summaryData.complacency = summaryData.complacency + parseInt(result[i].complacency)
            summaryData.cnt_complacency++
          }
        }
      }
    }
    let data = [{
      type: "bar",
      showInLegend: false,
      legendMarkerColor: "grey",
      toolTipContent: this.getLabel("LBLTOTALRESPONSES") + ": <strong>{tpcount}</strong> <br/>" + this.getLabel("LBLAVGRYSRATINGS") + ": <strong>{y} </strong>",
      indexLabel: "{y}",
      indexLabelPlacement: "inside",
      indexLabelFontSize: 12,
      indexLabelFontFamily: "'Roboto', sans-serif",
      indexLabelFontWeight: "400",
      indexLabelFontColor: "#333",      
      click: this.onBarClick,
      cursor: this.props.onElementClick? 'pointer' : 'default',
      dataPoints: [
        { y: parseFloat((summaryData.cnt_rushing > 0 ? summaryData.rushing / summaryData.cnt_rushing : 0).toFixed(1)), x: 1 , color: "#F46980", tpcount: summaryData.cnt_rushing, tpsum: summaryData.rushing, states: "rushing" },
        { y: parseFloat((summaryData.cnt_frustration > 0 ? summaryData.frustration / summaryData.cnt_frustration : 0).toFixed(1)), x: 2, color: "#FBBF7D", tpcount: summaryData.cnt_frustration, tpsum: summaryData.frustration, states: "frustration" },
        { y: parseFloat((summaryData.cnt_fatigue > 0 ? summaryData.fatigue / summaryData.cnt_fatigue : 0).toFixed(1)), x: 3, color: "#B7B3AE", tpcount: summaryData.cnt_fatigue, tpsum: summaryData.fatigue, states: "fatigue" },
        { y: parseFloat((summaryData.cnt_complacency > 0 ? summaryData.complacency / summaryData.cnt_complacency : 0).toFixed(1)), x: 4, color: "#58AFA9", tpcount: summaryData.cnt_complacency, tpsum: summaryData.complacency, states: "complacency" },
      ],
    }]
    let rysStatusTranslation = {
      4: this.getLabel("LBLRUSHING"),
      3: this.getLabel("LBLFRUSTRATION"),
      2: this.getLabel("LBLFATIGUE"),
      1: this.getLabel("LBLCOMPLACENCY"),
    };
    let rysmaxCounts = {
      4: summaryData.cnt_rushing,
      3: summaryData.cnt_frustration,
      2: summaryData.cnt_fatigue,
      1: summaryData.cnt_complacency,
    }
    data.push({
      type: "stackedBar100",
      name: "",
      showInLegend: true,
      legendMarkerColor: "grey",
      dataPoints: [
        { y: 0, x: 1 },
        { y: 0, x: 2 },
        { y: 0, x: 3 },
        { y: 0, x: 4 },
      ],
      toolTipContent: "",
      indexLabelPlacement: "outside",
      indexLabel: "{count}",
      legendMarkerColor: "#FFFFFF",
      axisXType: "secondary",
      indexLabelFontSize: 12,
      indexLabelFontFamily: "'Roboto', sans-serif",
      indexLabelFontWeight: "400",
      indexLabelFontColor: "#333",
    })
    let total = [19,10,19,10,19] ;
    options_bar = this.getOption(); 
    let datas = data[0].dataPoints;//.reverse();
    options_bar.series[0].data = datas ;
    // console.log("@@@@@@@@@#@@@@@@@@@@@@@$$$$$$$$$$$$$$$$$$$$$", data[0])
    let unitnames = ["", "U1", "U2", "U3", "U4", "U5"]
    this.setState({
      options_bar:options_bar,
      options: {
        height: 260,
        interactivityEnabled: true,
        animationEnabled: true,
        animationDuration: 1000,
        backgroundColor: "transparent",
        legend: {
          horizontalAlign: "center",
          verticalAlign: "bottom",
          fontSize: 12,
          fontFamily: "'Roboto', sans-serif",
          fontWeight: "500",
          fontColor: "#333",
        },
        toolTip:{
          fontSize: 13,
          fontFamily: "'Roboto', sans-serif",
          fontColor: "#333",
        },
        axisY2: {
          interlacedColor: "rgba(1,77,101,.2)",
          gridColor: "rgba(1,77,101,.1)",
          titleFontSize: 12,
          titleFontFamily: "'Roboto', sans-serif",
          titleFontWeight: "400",
          titleFontColor:"#333",
          labelFontSize: 12,
          labelFontFamily: "'Roboto', sans-serif",
          labelFontWeight: "400",
          labelFontColor:"#333",
        },
        axisX2: {
          interval: 1,
          gridThickness: 0,
          lineThickness: 0,
          tickLength: 4,
          tickColor: "#FFFFFF",
          gridColor: "orange",
          position: "top",
          labelFormatter: function (e) {
            let index = e.value;
            return rysmaxCounts[index]
          },
          titleFontSize: 12,
          titleFontFamily: "'Roboto', sans-serif",
          titleFontWeight: "400",
          titleFontColor:"#333",
          labelFontSize: 12,
          labelFontFamily: "'Roboto', sans-serif",
          labelFontWeight: "400",
          labelFontColor:"#333",
        },
        axisY: {
          interval: 1,
          gridThickness: 0,
          gridColor: "orange",
          position: "top",
          maximum: 10,
          title: this.getLabel("LBLAVGRYSRATINGS"),//this.getLabel("LBLAVGRYSRATINGS"),
          tickLength: 10,
          tickColor: "#FFFFFF",
          titleFontSize: 12,
          titleFontFamily: "'Roboto', sans-serif",
          titleFontWeight: "400",
          titleFontColor:"#333",
          labelFontSize: 12,
          labelFontFamily: "'Roboto', sans-serif",
          labelFontWeight: "400",
          labelFontColor:"#333",
        },
        axisX: {
          gridThickness: 1,
          gridColor: "#fafafa",
          interval: 1,
          tickLength: 0,
          labelFormatter: function (e) {
            let index = e.value;
            return rysStatusTranslation[index]
          },
          //title: this.getLabel("LBLCOMPLEVEL"),//this.getLabel("LBLAVGRYSRATINGS"),
          titleFontSize: 12,
          titleFontFamily: "'Roboto', sans-serif",
          titleFontWeight: "400",
          titleFontColor:"#333",
          labelFontSize: 12,
          labelFontFamily: "'Roboto', sans-serif",
          labelFontWeight: "400",
          labelFontColor:"#333",
        },
        dataPointMaxWidth: 30,
        data: data
      },
    });
  }

  render() { 
    return (
      // <CanvasJSChart options={this.state.options}
      //   onRef={ref => this.chart = ref}
      // />
      <HighchartsReact highcharts={Highcharts} 
        options={this.state.options_bar}
        ref={this.chart}   
      />
    );
  }
}
StackedColumn100Chart = withRouter(StackedColumn100Chart)
export default StackedColumn100Chart;

import React, { Component } from 'react';
import ReportServices from '../report_services'

class CourseList extends Component {
    constructor() {
        super();
        this.state = { open: false, courseList: [] }
    }
    componentDidMount() {
        this.getCourseList();
    }
    getCourseList() {
        ReportServices.getCourseList().then(response => {
            this.setState({ courseList: response.result })
        })
    }
    setOpen = () => {
        this.setState({
            open: !this.state.open
        })
    }
    render() {
        const { courseList } = this.state;
        return (
            <div >
                <div className="mob-pad">
                    <div>
                        <h3 className="page-title">Course Lists</h3>
                    </div>
                    <hr className="head-line" />
                    <div id="accordion" class="row rptlst-row">

                        {this.state.courseList.length > 0 ?
                            <>
                                {courseList.map((item, i) => {
                                    return <div className="col-xl-6 rptlst-col">
                                        <div class="card-header collapsed" data-toggle="collapse" href={"#" + item.productid}>
                                            <h2 class="mb-0">
                                                <b>{(i + 1) + '. ' + item.product} </b>
                                                <label className="lb-mid"> {item.type} </label>
                                            </h2>
                                        </div>
                                        <div id={item.productid} class="card-body collapse" data-parent="#accordion">
                                            <ul className="rptlst-list">
                                                {courseList[i].unitNames.split('~').sort().map((unit, i2) => {
                                                    return <li className="" > {(i2 + 1) + '. ' + unit}  </li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                                )}
                            </>
                            : ''}
                    </div>
                </div>

            </div>


        );
    }
}

export default CourseList;
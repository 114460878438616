import React, { Component } from "react";
import ReportServices from "../report_services";
import GroupServices from "../../group-list/group_services";
import orderBy from "lodash/orderBy";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import UsersService from "../../user-list/UsersService";
import { connect } from "react-redux";
import Loader from "../../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import emptyImage from '../../../../assets/images/learnerprogress_emptyst.png'
import moment from "moment";
let objFilterData = {};
let colLength1;
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};


class LCS1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 100,
      repos: [],
      open: false,
      loading: true,
      reportloading: false,
      userData: [],
      user_roles: [],
      pageState: "LCS_D1",
      toggleunitstate: {},
      roles: [{ role_id: 1, role_name: "learner" }],
      groupTypeNames: [],
      groupTypeColumns: [],
      loadLCS_D1_List: 0,
      loadLCS_D1_List_Groups: 0,
      orgLCS_D1_List: [],
      LCS_D1_List: [],
      LCS_D1_List_Fetched: 0,
      LCS_D1_SummaryData: { records: 0 },
      rpt_showfields: [],
      sel_D1_Data: {},

      LCS_D1_GPH_SelStateId: "1",
      filterDataLoaded: 0,
      coursestatus: [{ "id": "Notstarted", "value": this.props.labels.labels["LBLNOTSTARTED"] },
      { "id": "Inprogress", "value": this.props.labels.labels["LBLINPROGRESS"] },
      { "id": "Completed", "value": this.props.labels.labels["LBLCOMPLETED"] }],
      activestatus: [{ "id": "yes", "value": "yes" }, { "id": "no", "value": "no" }]
    };
    this.LCS_D1_Filter = React.createRef();
    this.LCP_D2_Filter = React.createRef();
  }
  loadpage(a, x) {
    if (a === "LCS_D1") {
      this.setState({loadLCS_D1_List:1})
    }
    if (a === "group") {
      this.setState({loadLCS_D1_List_Groups:1})
    }
    if (
      this.state.loadLCS_D1_List === 1 &&
      this.state.loadLCS_D1_List_Groups === 1
    ) {
      this.setState({
        loading: false,
      });
    }
  }
  componentDidMount() {
    //JUST TO APPLY LATEST ON 2022-04-20
    objFilterData = {};
    let data = {};
    data.date1 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    data.date2 = new Date(new Date());
    data.sdate1 = this.getDateFormat(data.date1);
    data.sdate2 = this.getDateFormat(data.date2);
    data.offsetTime = this.getOffsetTime();
    this.getFilterDatas();
    let getData = {
      language: this.props.language,
    };
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      //{group_type_id: "GP_1", lbl_GP_1: "Location", Active: "1"}
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
        },
        this.loadpage("group", iresponse)
      );
    });
  }
  async getFilterDatas() {
    let data = {};
    data.report = "LCS_D1";
    let group_types = await require("../../../../json_files/group_types.json");
    ReportServices.getFilterDatas(data).then((response) => {
      let xgroup_types = response.result[0];
      let labels;
      let groups = {};
      for (var i = 0; i < xgroup_types.length; i++) {
        if (this.props.language_code === xgroup_types[i].language_code) {
          labels = JSON.parse(xgroup_types[i].labels).group_labels;
        }
      }
      if (labels && labels.length > 0) {
        for (const grouptype of group_types) {
          if (labels[grouptype.group_type_id]) {
            grouptype["lbl_" + grouptype.group_type_id] =
              labels[grouptype.group_type_id]["lbl_" + grouptype.group_type_id];
            grouptype["Active"] = labels[grouptype.group_type_id]["Active"];
            groups[grouptype.group_type_id] = [];
            groups["obj" + grouptype.group_type_id] = {};
          } else {
            grouptype["Active"] = "0";
          }
        }
      }
      let xgroups = response.result[1];
      for (var i = 0; i < xgroups.length; i++) {
        if (
          groups[xgroups[i].group_type_id] &&
          !groups["obj" + xgroups[i].group_type_id][xgroups[i].value]
        ) {
          groups["obj" + xgroups[i].group_type_id][xgroups[i].value] = 1;
          groups[xgroups[i].group_type_id].push(xgroups[i]);
        }
      }
      let courses = response.result[2].sort(this.sortorder);
      let users = response.result[3];
      let usersemail = []
      for (let i = 0; i < users.length; i++) {
        if (users[i].email) {
          usersemail.push({ "id": users[i].id, value: users[i].email })
        }
      }
      let courseunits = response.result[4];
      let xrs5 = response.result[5];
      let rpt_showfields = ""
      if (xrs5 && xrs5[0] && xrs5[0].user_showfields && String(xrs5[0].user_showfields) !== "") {
        rpt_showfields = xrs5[0].user_showfields.split(",")
      }
      this.setState(
        {
          filterDataLoaded: 1,
          pageState: "LCS_D1",
          courses: courses,
          userNames: users,
          userMails: usersemail,
          courseunits: courseunits,
          rpt_showfields: rpt_showfields
        },
        this.loadpage("LCS_D1")
      );
    });
  }
  checkShowFilter = (field) =>{
    let columns = this.state.columns;
    if(field == "completed_date"){ 
    }
   // this.setState({})
  }
  checkShowField = (xfield) => {  
    if (this.state.rpt_showfields.indexOf(xfield) > -1) {
      return true
    } else {
      return false
    }
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getLCS_D1_Export(data) {
    data.report = "LCS_D1";
    ReportServices.getLCS_D1_Export(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        //console.log("TTTTTTTTTTTTTTTTTTTT,fileUrl", fileUrl)
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob)
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName = this.props.labels.labels.LBLUTSEXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }

  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  getLCS_D1_Summary = (xresult, ydata) => {
    //console.log("getLCS_D1_SummaryQQQQQQQQQQQQQQQQQ", ydata);
    let xsummaryData = {
      rushing: {},
      fatigue: {},
      frustration: {},
      complacency: {},
    };
    let grpSummaryData = {
      sum_rushing: 0,
      sum_fatigue: 0,
      sum_frustration: 0,
      sum_complacency: 0,
      dwgh_rushing: {},
      dwgh_fatigue: {},
      dwgh_frustration: {},
      dwgh_complacency: {},
    };
    let summaryData = {
      rushing: [],
      fatigue: [],
      frustration: [],
      complacency: [],
    };
    for (var i = 0; i < xresult.length; i++) {
      let xdate = xresult[i].created_on
        .substr(0, 10)
        .split("-")
        .reverse()
        .join("/");
      if (xsummaryData["rushing"].hasOwnProperty(xdate) === false) {
        xsummaryData["rushing"][xdate] = {};
        xsummaryData["fatigue"][xdate] = {};
        xsummaryData["frustration"][xdate] = {};
        xsummaryData["complacency"][xdate] = {};
      }
      if (
        xsummaryData["rushing"][xdate].hasOwnProperty(xresult[i].rushing) ===
        false
      ) {
        xsummaryData["rushing"][xdate][xresult[i].rushing] = 0;
      }
      xsummaryData["rushing"][xdate][xresult[i].rushing]++;
      grpSummaryData["sum_rushing"]++;

      if (
        xsummaryData["fatigue"][xdate].hasOwnProperty(xresult[i].fatigue) ===
        false
      ) {
        xsummaryData["fatigue"][xdate][xresult[i].fatigue] = 0;
      }
      xsummaryData["fatigue"][xdate][xresult[i].fatigue]++;
      grpSummaryData["sum_fatigue"]++;
      if (
        xsummaryData["frustration"][xdate].hasOwnProperty(
          xresult[i].frustration
        ) === false
      ) {
        xsummaryData["frustration"][xdate][xresult[i].frustration] = 0;
      }
      xsummaryData["frustration"][xdate][xresult[i].frustration]++;
      grpSummaryData["sum_frustration"]++;
      if (
        xsummaryData["complacency"][xdate].hasOwnProperty(
          xresult[i].complacency
        ) === false
      ) {
        xsummaryData["complacency"][xdate][xresult[i].complacency] = 0;
      }
      xsummaryData["complacency"][xdate][xresult[i].complacency]++;
      grpSummaryData["sum_complacency"]++;
    }
    var getcount = function (xday, ydays) {
      let xdt = xday.split("/");
      let xdays =
        parseInt(xday.split("/")[2]) * 1000 +
        getDays(
          new Date(parseInt(xdt[2]), parseInt(xdt[1]) - 1, parseInt(xdt[0]))
        );
      return xdays - ydays;
    };
    var getDays = function (xdate) {
      var now = new Date(xdate.getTime());
      var start = new Date(now.getFullYear(), 0, 0);
      var diff =
        now -
        start +
        (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      return day;
    };

    let startDay = ydata.date1.getFullYear() * 1000 + getDays(ydata.date1);
    for (let xstate in xsummaryData) {
      for (let xday in xsummaryData[xstate]) {
        var dtotal = 0;
        for (let xrate in xsummaryData[xstate][xday]) {
          let obj = {
            x: getcount(xday, startDay),
            y: xrate,
            org_value: xsummaryData[xstate][xday][xrate],
            day: xday,
          };
          summaryData[xstate].push(obj);
          dtotal = dtotal + xsummaryData[xstate][xday][xrate];
        }
        grpSummaryData["dwgh_" + xstate][xday] =
          dtotal / grpSummaryData["sum_" + xstate];
      }
      grpSummaryData["mwgh_" + xstate] = 0;
    }
    //set weight value
    for (let xstate in summaryData) {
      for (i = 0; i < summaryData[xstate].length; i++) {
        summaryData[xstate][i].weight =
          summaryData[xstate][i].org_value *
          grpSummaryData["dwgh_" + xstate][summaryData[xstate][i].day];
        grpSummaryData["mwgh_" + xstate] =
          grpSummaryData["mwgh_" + xstate] > summaryData[xstate][i].weight
            ? grpSummaryData["mwgh_" + xstate]
            : summaryData[xstate][i].weight;
      }
    }
    for (let xstate in summaryData) {
      for (i = 0; i < summaryData[xstate].length; i++) {
        summaryData[xstate][i].value = Math.round(
          (summaryData[xstate][i].weight / grpSummaryData["mwgh_" + xstate]) *
          100,
          0
        );
      }
    }
    return summaryData;
  };
  getLCS_D1_List(data) {
    this.setState({
      reportloading: true,
      LCS_D1_List_Fetched: 1,
    });
    data.report = "LCS_D1";

    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;

    ReportServices.getLCS_D1_List(data).then((response) => {
      let result = response.result[9];
      let r2cNames = response.result[10];
      let xsummaryData = {};
      xsummaryData.records = result.length;
      let pageCounts = (result.length / this.state.pageSize) + 1
      let pageIndex = 1
      let xaverage = {}
      for (let xname in r2cNames[0]) {
        if (xname.indexOf("name_") > -1) {
          if (r2cNames[0][xname] !== "") {
            xaverage[xname.split("_")[1]] = { name: r2cNames[0][xname], CmpCnt: 0 }
          }
        }
      }
      for (let i = 0; i < result.length; i++) {
        for (let xname in xaverage) {
          xaverage[xname]["CmpCnt"] = xaverage[xname]["CmpCnt"] + ((result[i][xname].status === "Completed") ? 1 : 0)
          if (result[i][xname] && result[i][xname].hasOwnProperty("status")) {
            result[i][xname + "_status"] = this.props.labels.labels["LBL" + result[i][xname].status.toUpperCase()];
          } else {
            result[i][xname + "_status"] = ""
          }
        }
        result[i].activeuser = ((result[i].email_verified || 0) === 1 || (result[i].email || "") === "") ? this.props.labels.labels["LBLYES"] : this.props.labels.labels["LBLNO"]
        result[i].activeuserclass = ((result[i].email_verified || 0) === 1 || (result[i].email || "") === "") ? "yes" : "no"
        result[i].start_date = result[i].product.start_date_format
        result[i].completed_date = result[i].product.completed_date_format
        result[i].total_timespent = result[i].product.total_timespent
        result[i].full_name = result[i].first_name + " " + result[i].last_name
      }
      xsummaryData.averages = xaverage
      this.setState(
        {
          pageState: "LCS_D1",
          LCS_D1_List_Fetched: 1,
          orgLCS_D1_List: result,
          LCS_D1_List: result,
          pageIndex: pageIndex,
          pageCounts: pageCounts,
          r2cNames: r2cNames,

          LCS_D1_SummaryData: xsummaryData,
          sel_D1_Data: data,
          reportloading: false,
        },
        this.applyFilterCondition(result, data),
        REFS.F1.onExportDone()
      );
    });
  }
  applyFilterCondition = (orgresult, data) => {
    if (objFilterData.availableLCS_D1Fields) {
      let xobj = objFilterData.availableLCS_D1Fields;
      let result = [];
      //console.log("applyFilterCondition!!!!!!!", xobj);
      for (var i = 0; i < orgresult.length; i++) {
        if (this.checkFilterCondition(xobj, orgresult[i]) === true) {
          result.push(orgresult[i]);
        }
      }
      this.setState(
        {
          LCS_D1_List: result,
        },
        this.loadpage("LCS_D1")
      );
    } else {
      let result = orgresult;
      this.setState(
        {
          LCS_D1_List: result,
        },
        this.loadpage("LCS_D1")
      );
    }
  };
  showLCS_D1 = () => {
    this.setState({
      pageState: "LCS_D1",
    });
  };

  getGroupTypes = async () => {
    GroupServices.getGroupTypeLabels().then((response) => {
      this.setState(
        {
          groupTypeNames: response,
        },
        this.loadpage("group", response)
      );
    });
  };
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  toggletree = (xid) => {
    let xtoggleunitstate = this.state.toggleunitstate;
    xtoggleunitstate[xid] = !(xtoggleunitstate[xid] || false);
    this.setState({
      toggleunitstate: xtoggleunitstate,
    });
  };
  getColumnName = (xindex, xname) => {
    try {
      return this.state.r2cNames[xindex][xname]
    } catch (e) {
      return ""
    }
  }
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  getrysdates = (xvalue) => {
    let cDate = new Date();
    let xrArr = []
    switch (xvalue) {
      case "LAST7DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "LAST30DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "THISMONTH":
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "LASTMONTH":
        let thisMonthFDate = new Date(cDate.getFullYear(), cDate.getMonth(), 1, 23, 59, 59, 999);
        xrArr.push(new Date(thisMonthFDate.getFullYear(), thisMonthFDate.getMonth() - 1, 1, 0, 0, 0, 0));
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
      case "YTD":
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "TODAY":
        xrArr.push(new Date(cDate.getTime()));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "YESTERDAY":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      default:
        break;
    }
    return xrArr
  }
  getCondition_date(mode) {
    if (mode === "date") {
      // if (this.props.location && this.props.location.state && this.props.location.state.rys_date) {
      //   return {
      //     name: "Date",
      //     operator: this.props.location.state.rys_date,
      //     value: this.getrysdates(this.props.location.state.rys_date).slice(),
      //     operatorText: this.getLabel("LBL" + this.props.location.state.rys_date),
      //   }
      // } else {
        let xrArr = [];
        let cDate = new Date();
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date());
        return {
          name: "Date",
          operator: "YTD",
          value: this.getrysdates("YTD").slice(),
          operatorText: this.getLabel("LBLYTD"),
        }
     // }
    }
  }
  getCondition(mode) {
    if (mode === "product_name") {
      if (this.props.location && this.props.location.state && this.props.location.state.selProduct) {
        let selindex = -1

        if (this.state.courses) {
          for (let i = 0; i < this.state.courses.length; i++) {
            if (this.state.courses[i].id === this.props.location.state.selProduct) {
              selindex = i
            }
          }
        }
        return {
          name: this.getLabel("LBLCOURSENAME"),
          operator: "EQUALS",
          value: this.state.courses && selindex > -1 ? [this.state.courses[selindex]] : "",
          operatorText: "",
        }
      } else {
        return {
          name: this.getLabel("LBLCOURSENAME"),
          operator: "EQUALS",
          value: this.state.courses && this.state.courses[0] ? [this.state.courses[0]] : "",
          operatorText: "",
        }
      }
    }
  }
  sortorder = (a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }
  getLCS_D1_Columns = (x) => {
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1))); 
    let columns = [ 
      {
        name: this.props.labels.labels.LBLFULLNAME,
        selector: "full_name",
        filter: {
          type: "string",
          showField: this.checkShowField("full_name"),
          checked: true,
          render: "server",
        },
        filterData: this.state.userNames,
        sortable: true,
        // compact: true,
        exportcell: "Full_Name",
        exportcellproperty: { order: 100, cellwidth: 20 },
        tableIndex: 0,
        width: "220px",
        cell: (row) => {
          return (
            <div className='grid-ellipsis'>
              <label className="show-sm">
                {this.props.labels.labels.LBLFULLNAME}
              </label>
              <span className="rle-brdr" title={row.first_name + " " + row.last_name}>
                {row.first_name + " " + row.last_name}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLUSERNAME || "User Name",
        selector: "user_name",
        filter: { type: "string", showField: this.checkShowField("user_name"), checked: false },
        filterData: [],
        sortable: true,
        // compact: true,
        exportcell: "_user_name",
        exportcellproperty: { order: 101, cellwidth: 20 },
        tableIndex: 1,
        width: "150px",
        cell: (row) => {
          return (
            <div className='grid-ellipsis'>
              <label className="show-sm">
                {this.props.labels.labels.LBLUSERNAME || "User Name"}
              </label>
              <span className="rle-brdr" title={row.user_name}>
                {row.user_name}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLEMAIL || "LBLEMAIL",
        selector: "email",
        filter: { type: "string", showField: this.checkShowField("email"), checked: false },
        filterData: this.state.userMails,
        sortable: true,
        // compact: true,
        exportcell: "_email",
        exportcellproperty: { order: 101, cellwidth: 20 },
        tableIndex: 1,
        width: "220px",
        cell: (row) => {
          return (
            <div className='grid-ellipsis'>
              <label className="show-sm">
                {this.props.labels.labels.LBLEMAIL || "LBLEMAIL"}
              </label>
              <span className="rle-brdr" title={row.email}>
                {row.email}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLSTATUS,
        selector: "act_st",
        filter: { type: "stringsingle", showField: true, checked: true },
        filterData: [{ "id": "1", "value": this.props.labels.labels["LBLACTIVE"] }, { "id": "0", "value":this.props.labels.labels["LBLINACTIVE"]}], 
        sortable: true,
        compact: true,
        exportcell: "_product.act_st",
        exportcellproperty: { order: 102, cellwidth: 20 },
        tableIndex: 1,
        width: "150px",
        exportlabels: {
          "LBLACTIVE": this.props.labels.labels["LBLACTIVE"],
          "LBLINACTIVE": this.props.labels.labels["LBLINACTIVE"],
        },
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLACTIVE}
              </label>
              <span className="rle-brdr" style={{ color: row.act_st === "Active" ? "#2DA703" : "#D90000" }}>{
                this.props.labels.labels["LBL" + row.act_st.toUpperCase()]
              }</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLACTIVEUSER || "LBLACTIVEUSER",
        selector: "activeuser",
        filter: { type: "string", showField: this.checkShowField("activeuser"), checked: false },
        filterData: this.state.activestatus,
        sortable: true,
        exportcell: "_activeuser",
        // compact: true,
        exportcellproperty: {
          order: 290,
          cellformats: {
            [this.props.labels.labels["LBLYES"]]: "A6D486",
            [this.props.labels.labels["LBLNO"]]: "FF8F8F"
          }, cellwidth: 20
        },
        tableIndex: 1,
        width: "150px",
        exportlabels: {
          "LBLYES": this.props.labels.labels["LBLYES"] || "LBLYES",
          "LBLNO": this.props.labels.labels["LBLNO"] || "LBLNO",
          "LBLPERCENTCOMPLETED": this.props.labels.labels["LBLPERCENTCOMPLETED"] || "LBLPERCENTCOMPLETED"
        },
        cell: (row, idx) => {
          return <>
            <div id={row.user_id + "usract_" + idx}
              className={"sfcellbox usract_" + row.activeuserclass}
              style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap", textAlign: "center", alignContent: "center" }}
            >
              {row.activeuser}
            </div>
          </>
        },
      },
      {
        name: this.props.labels.labels.LBLCOURSENAME,
        selector: "product_name",
        filter: {
          type: "stringsingle",
          showField: this.checkShowField("product_name"),
          checked: true,
          required: true,
          condition: this.getCondition("product_name")
        },
        filterData: this.state.courses,
        sortable: true,
        exportcell: "_product_name",
        exportcellproperty: { order: 300, cellwidth: 20 },
        tableIndex: 1,
        width: "220px",
        cell: (row) => {
          return (<div className="grid-ellipsis">
            <label className="show-sm">
              {this.props.labels.labels.LBLCOURSENAME}
            </label>
            <span  title={row.product_name} style={{paddingLeft:"8px"}}>
              {row.product_name}
            </span>
          </div>
          )
        },
      },
      {
        name: this.props.labels.labels.LBLSTARTDATE,
        selector: "start_date",
        sortable: true,
        filter: { type: "date", showField: this.checkShowField("start_date"), checked: false },
        exportcell: "_product.start_date_format",
        exportcellproperty: { order: 301 },
        tableIndex: 1,
        width: "150px",
      },
      // {
      //   name: this.props.labels.labels.LBLCOMPLETIONDATE,
      //   selector: "completed_date",
      //   sortable: true,
      //   //filterData: filtered_date,
      //   //filter: { type: "date", showField: this.checkShowField("completed_date"), checked: false },
      //   filter: {
      //   type: "date",
      //   showField: true,
      //   checked: true,
      //   render: "server",
      //   condition: this.getCondition_date("date"),
      //   exportcell: "_created_on_dateformat",
      //   },
      //   exportcell: "_product.completed_date_format",
      //   exportcellproperty: { order: 302 },
      //   tableIndex: 1,
      //   width: "150px",
      // },   
      {
        name: "u0",
        selector: "u0_status",
        filter: { type: "string", showField: true, checked: false, showFieldFilter: false },
        filterData: this.state.coursestatus,
        exportcell: "_u0.status",
        exportcellproperty: {
          order: 900, cellheader: "0$name_u0", defaultheader: "LBLCOURSESTATUS", cellformats: {
            [this.props.labels.labels["LBLCOMPLETED"]]: "A6D486",
            [this.props.labels.labels["LBLNOTSTARTED"]]: "FF8F8F",
            [this.props.labels.labels["LBLINPROGRESS"]]: "FFFF99",
          }, cellwidth: 20
        },
        tableIndex: 2,
        width: "150px",
        sortable: true,
        exportlabels: {
          "LBLPERCENTCOMPLETED": this.props.labels.labels["LBLPERCENTCOMPLETED"] || "LBLPERCENTCOMPLETED",
          "LBLCOURSESTATUS": this.props.labels.labels["LBLCOURSESTATUS"],
          "LBLNOTSTARTED": this.props.labels.labels["LBLNOTSTARTED"],
          "LBLINPROGRESS": this.props.labels.labels["LBLINPROGRESS"],
          "LBLCOMPLETED": this.props.labels.labels["LBLCOMPLETED"],
        },
        cell: (row, idx) => {
          if (row.u0 && row.u0.hasOwnProperty("status")) {
            return <>
              <div id={row.u0.status + "_" + idx}
                className={"sfcellbox " + row.u0.status.toLowerCase()}
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {this.props.labels.labels["LBL" + row.u0.status.toUpperCase()]}
              </div>
            </>
          } else {
            return ""
          }
        },
      },
      {
        name: "u1",
        selector: "u1_status",
        filter: { type: "string", showField: true, checked: false, showFieldFilter: false },
        filterData: this.state.coursestatus,
        exportcell: "_u1.status",
        exportcellproperty: {
          order: 901, cellheader: "0$name_u1", cellformats: {
            [this.props.labels.labels["LBLCOMPLETED"]]: "A6D486",
            [this.props.labels.labels["LBLNOTSTARTED"]]: "FF8F8F",
            [this.props.labels.labels["LBLINPROGRESS"]]: "FFFF99"
          }, cellwidth: 20
        },
        tableIndex: 2,
        width: "150px",
        sortable: true,
        exportlabels: {
          "LBLNOTSTARTED": this.props.labels.labels["LBLNOTSTARTED"],
          "LBLINPROGRESS": this.props.labels.labels["LBLINPROGRESS"],
          "LBLCOMPLETED": this.props.labels.labels["LBLCOMPLETED"],
        },
        cell: (row, idx) => {
          if (row.u1 && row.u1.hasOwnProperty("status")) {
            return <>
              <div id={row.u1.status + "_" + idx}
                className={"sfcellbox " + row.u1.status.toLowerCase()}
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {this.props.labels.labels["LBL" + row.u1.status.toUpperCase()]}
              </div>
            </>
          } else {
            return ""
          }
        },
      },
      {
        name: "u2",
        selector: "u2_status",
        filter: { type: "string", showField: true, checked: false, showFieldFilter: false },
        filterData: this.state.coursestatus,
        exportcell: "_u2.status",
        exportcellproperty: {
          order: 902, cellheader: "0$name_u2", cellformats: {
            [this.props.labels.labels["LBLCOMPLETED"]]: "A6D486",
            [this.props.labels.labels["LBLNOTSTARTED"]]: "FF8F8F",
            [this.props.labels.labels["LBLINPROGRESS"]]: "FFFF99"
          }, cellwidth: 20
        },
        tableIndex: 2,
        width: "150px",
        sortable: true,
        exportlabels: {
          "LBLNOTSTARTED": this.props.labels.labels["LBLNOTSTARTED"],
          "LBLINPROGRESS": this.props.labels.labels["LBLINPROGRESS"],
          "LBLCOMPLETED": this.props.labels.labels["LBLCOMPLETED"],
        },
        cell: (row, idx) => {
          if (row.u2 && row.u2.hasOwnProperty("status")) {
            return <>
              <div id={row.u2.status + "_" + idx}
                className={"sfcellbox " + row.u2.status.toLowerCase()}
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {this.props.labels.labels["LBL" + row.u2.status.toUpperCase()]}
              </div>
            </>
          } else {
            return ""
          }
        },
      },
      {
        name: "u3",
        selector: "u3_status",
        filter: { type: "string", showField: true, checked: false, showFieldFilter: false },
        filterData: this.state.coursestatus,
        exportcell: "_u3.status",
        exportcellproperty: {
          order: 902, cellheader: "0$name_u3", cellformats: {
            [this.props.labels.labels["LBLCOMPLETED"]]: "A6D486",
            [this.props.labels.labels["LBLNOTSTARTED"]]: "FF8F8F",
            [this.props.labels.labels["LBLINPROGRESS"]]: "FFFF99"
          }, cellwidth: 20
        },
        tableIndex: 2,
        width: "150px",
        sortable: true,
        exportlabels: {
          "LBLNOTSTARTED": this.props.labels.labels["LBLNOTSTARTED"],
          "LBLINPROGRESS": this.props.labels.labels["LBLINPROGRESS"],
          "LBLCOMPLETED": this.props.labels.labels["LBLCOMPLETED"],
        },
        cell: (row, idx) => {
          if (row.u3 && row.u3.hasOwnProperty("status")) {
            return <>
              <div id={row.u3.status + "_" + idx}
                className={"sfcellbox " + row.u3.status.toLowerCase()}
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {this.props.labels.labels["LBL" + row.u3.status.toUpperCase()]}
              </div>
            </>
          } else {
            return ""
          }
        },
      },
      {
        name: "u4",
        selector: "u4_status",
        filter: { type: "string", showField: true, checked: false, showFieldFilter: false },
        filterData: this.state.coursestatus,
        exportcell: "_u4.status",
        exportcellproperty: {
          order: 902, cellheader: "0$name_u4", cellformats: {
            [this.props.labels.labels["LBLCOMPLETED"]]: "A6D486",
            [this.props.labels.labels["LBLNOTSTARTED"]]: "FF8F8F",
            [this.props.labels.labels["LBLINPROGRESS"]]: "FFFF99"
          }, cellwidth: 20
        },
        tableIndex: 2,
        width: "150px",
        sortable: true,
        exportlabels: {
          "LBLNOTSTARTED": this.props.labels.labels["LBLNOTSTARTED"],
          "LBLINPROGRESS": this.props.labels.labels["LBLINPROGRESS"],
          "LBLCOMPLETED": this.props.labels.labels["LBLCOMPLETED"],
        },
        cell: (row, idx) => {
          if (row.u4 && row.u4.hasOwnProperty("status")) {
            return <>
              <div id={row.u4.status + "_" + idx}
                className={"sfcellbox " + row.u4.status.toLowerCase()}
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {this.props.labels.labels["LBL" + row.u4.status.toUpperCase()]}
              </div>
            </>
          } else {
            return ""
          }
        },
      },
      {
        name: "u5",
        selector: "u5_status",
        filter: { type: "string", showField: true, checked: false, showFieldFilter: false },
        filterData: this.state.coursestatus,
        exportcell: "_u5.status",
        exportcellproperty: {
          order: 902, cellheader: "0$name_u5", cellformats: {
            [this.props.labels.labels["LBLCOMPLETED"]]: "A6D486",
            [this.props.labels.labels["LBLNOTSTARTED"]]: "FF8F8F",
            [this.props.labels.labels["LBLINPROGRESS"]]: "FFFF99"
          }, cellwidth: 20
        },
        tableIndex: 2,
        width: "150px",
        sortable: true,
        exportlabels: {
          "LBLNOTSTARTED": this.props.labels.labels["LBLNOTSTARTED"],
          "LBLINPROGRESS": this.props.labels.labels["LBLINPROGRESS"],
          "LBLCOMPLETED": this.props.labels.labels["LBLCOMPLETED"],
        },
        cell: (row, idx) => {
          if (row.u5 && row.u5.hasOwnProperty("status")) {
            return <>
              <div id={row.u5.status + "_" + idx}
                className={"sfcellbox " + row.u5.status.toLowerCase()}
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {this.props.labels.labels["LBL" + row.u5.status.toUpperCase()]}
              </div>
            </>
          } else {
            return ""
          }
        },
      }

    ];

    let averages = this.state.LCS_D1_SummaryData && this.state.LCS_D1_SummaryData.averages;
    let lenAve = 0, arrAverage = averages && Object.keys(averages);
    let newCols = JSON.parse(JSON.stringify(columns[12]));
    //console.log("AAAAAA", newCols);
    let colLen = columns.length;
    colLength1 = colLen;
    lenAve = arrAverage && arrAverage.length;
    if (lenAve > 5 && columns.length <= colLen) {
      for (var i = 0; i < lenAve; i++) {
        let item = arrAverage[i];
        const allValues = this.state.LCS_D1_List;
        if (i > 5) {
          newCols = this.getColumns(allValues[i + colLen], item, i)[0];
          //console.log("CCCCCCCC",  newCols); 
          columns.push(newCols);
        }
      }
    }
    let xcolumns = [];

    let response = this.state.groupTypeNames;
    for (var j = 0; j < response.length; j++) {
      if (response[j].Active === "1") {
        //let x1 = response[i].group_type_id
        xcolumns.push({
          name: response[j]["lbl_" + response[j].group_type_id],
          selector: response[j].group_type_id,
          filter: {
            type: "string",
            showField: this.checkShowField(response[j].group_type_id),
            checked: true,
          },
          exportcellproperty: { order: (200 + j), cellwidth: 20 },
          filterData: this.state.groups[response[j].group_type_id],
          //filterData: this.state[response[i].group_type_id + "Names"],
          sortable: true,
          // compact: true,
          width: "150px",
        });
        ////alert(this.state[response[i].group_type_id + "Names"])
      }
    }

    columns.splice.apply(columns, [4, 0].concat(xcolumns));
    //columns.splice(0,1)
    return columns; //JSON.parse(JSON.stringify(columns)) 
  };
  getColumns = (values, item, i) => {
    let averages = this.state.LCS_D1_SummaryData && this.state.LCS_D1_SummaryData.averages;
    let arrAverage = averages && Object.values(averages);
    let cols = Object.keys(["newCols"]).map(key => {
      return {
        name: arrAverage[i].name,
        selector: item + "_status",
        filter: { type: "string", showField: true, checked: false, showFieldFilter: false },
        filterData: this.state.coursestatus,
        exportcell: "_" + item + ".status",
        exportcellproperty: {
          order: 902, cellheader: "0$name_" + item, cellformats: {
            [this.props.labels.labels["LBLCOMPLETED"]]: "A6D486",
            [this.props.labels.labels["LBLNOTSTARTED"]]: "FF8F8F",
            [this.props.labels.labels["LBLINPROGRESS"]]: "FFFF99"
          }, cellwidth: 20
        },
        tableIndex: 2,
        width: "150px",
        sortable: true,
        exportlabels: {
          "LBLNOTSTARTED": this.props.labels.labels["LBLNOTSTARTED"],
          "LBLINPROGRESS": this.props.labels.labels["LBLINPROGRESS"],
          "LBLCOMPLETED": this.props.labels.labels["LBLCOMPLETED"],
        },
        cell: (row, idx) => {
          if (row[item] && row[item].hasOwnProperty("status")) {
            return <>
              <div id={row[item].status + "_" + idx}
                className={"sfcellbox " + row[item].status.toLowerCase()}
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {this.props.labels.labels["LBL" + row[item].status.toUpperCase()]}
              </div>
            </>
          } else {
            return ""
          }
        },
      };
    });
    return cols;
  }
  getTranslation = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    let xcols = [];
    for (var i = 0; i < cols.length; i++) {
      if (cols[i]) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    return xcols;
  }
  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    //console.log("getFilterColumns", mode, cols)
    let xcols = [];
    //console.log("getFilterColumns-111", mode, xcols)
    for (var i = 0; i < cols.length; i++) {
      if (cols[i] && cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "LCS_D1") {
      let xobj = objFilterData.availableLCS_D1Fields;
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    }
    //console.log("getFilterColumns-222", mode, xcols, cols)
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      //console.log(row[field])
      if (row[field] && row[field] !== 1 && row[field] !== 0) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  checkFilterCondition = (filters, data) => {
    for (var i in filters) {
      if (filters[i].condition && filters[i].checked) {
        let dvalue = data[filters[i].selector];
        let fdvalue = [];
        let fselector = filters[i].selector.split(",");
        for (var xi = 0; xi < fselector.length; xi++) {
          fdvalue.push(data[fselector[xi]]);
        }
        dvalue = fdvalue.join(" ");
        let cvalue = filters[i].condition.value;
        let xr = false;
        let dtime;
        let ctime;
        let ctime1;
        let ctime2;
        if (filters[i].type === "string") {

          if (filters[i].condition.operator === "CONTAINS") {
            //cvalue != "" && dvalue != null &&
            if (cvalue === "") {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (
              dvalue.toLowerCase().split(cvalue.toLowerCase()).length > 1
            ) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "DOES_NOT_CONTAIN") {
            if (cvalue === "") {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (
              dvalue.toLowerCase().split(cvalue.toLowerCase()).length === 1
            ) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "EQUALS") {
            if (cvalue === "") {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue.toLowerCase() === cvalue.toLowerCase()) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "NOT_EQUALS") {
            if (cvalue === "") {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue.toLowerCase() !== cvalue.toLowerCase()) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "MULTI_EQUALS") {
            for (let xOb in cvalue) {
              if (dvalue.toLowerCase() === cvalue[xOb].value.toLowerCase()) {
                xr = true;
                break;
              }
            }
            xr = false;
          }
        } else if (filters[i].type === "number") {
          if (filters[i].condition.operator === "EQUALS") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue === cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "NOT_EQUALS") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue !== cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "GREATER_THAN") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue > cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "GREATER_THAN_EQUALS") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue >= cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "LESS_THAN") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue < cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "LESS_THAN_EQUALS") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue <= cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "BETWEEN") {
            var min = Math.min.apply(Math, cvalue),
              max = Math.max.apply(Math, cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue > min && dvalue < max) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "NOT_BETWEEN") {
            var minTwo = Math.min.apply(Math, cvalue),
              maxTwo = Math.max.apply(Math, cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue < minTwo || dvalue > maxTwo) {
              xr = true;
            }
          }
        } else if (filters[i].type === "date") {
          var xgetTime = function (x) {
            let dx = new Date(x);
            dx.setHours(0);
            dx.setMinutes(0);
            dx.setMinutes(0);
            dx.setSeconds(0);
            dx.setMilliseconds(0);
            return dx.getTime();
          };
          let dayscondition =
            "BETWEEN,LAST7DAYS,LAST30DAYS,THISMONTH,LASTMONTH";
          let daycondition = "TODAY,YESTERDAY,EQUALS";
          if (daycondition.indexOf(filters[i].condition.operator) > -1) {
            dtime = xgetTime(dvalue);
            ctime = xgetTime(cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null || cvalue === null) {
              xr = false;
            } else if (dtime === ctime) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "GREATER_THAN") {
            dtime = xgetTime(dvalue);
            ctime = xgetTime(cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null) {
              xr = false;
            } else if (dtime > ctime) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "LESS_THAN") {
            dtime = xgetTime(dvalue);
            ctime = xgetTime(cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null) {
              xr = false;
            } else if (dtime < ctime) {
              xr = true;
            }
          } else if (
            dayscondition.indexOf(filters[i].condition.operator) > -1
          ) {
            dtime = xgetTime(dvalue);
            ctime1 = xgetTime(cvalue[0]);
            ctime2 = xgetTime(cvalue[1]);
            var minThree = Math.min.apply(Math, [ctime1, ctime2]);
            var maxThree = Math.max.apply(Math, [ctime1, ctime2]);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null) {
              xr = false;
            } else if (dtime >= minThree && dtime <= maxThree) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "NOT_BETWEEN") {
            dtime = xgetTime(dvalue);
            ctime1 = xgetTime(cvalue[0]);
            ctime2 = xgetTime(cvalue[1]);
            var minFour = Math.min.apply(Math, [ctime1, ctime2]),
              maxFour = Math.max.apply(Math, [ctime1, ctime2]);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null) {
              xr = false;
            } else if (dtime < minFour || dtime > maxFour) {
              xr = true;
            }
          }
        }
        if (xr === false) {
          return false;
        }
      }
    }
    return true;
  };
  setsvaluefordate = (data) => {
    for (let j = 0; j < data.filtercolumns.length; j++) {
      if (data.filtercolumns[j].filter.type === "date" && data.filtercolumns[j].filter.condition) {
        //console.log("setsvaluefordate", data.filtercolumns[j].filter)
        let dt1, dt2;
        let xcondition = JSON.parse(JSON.stringify(data.filtercolumns[j].filter.condition))
        // let ddata = {}
        if (typeof xcondition.value == "string") {
          let arrdt = xcondition.value.split("T")[0].split("-");
          let arrdt2 = xcondition.value.split("T")[1].split(":");
          xcondition.xvalue = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2],
            arrdt2[0],
            arrdt2[1],
            0, 0
          );
        } else {
          if (typeof xcondition.value == "object" && xcondition.value[0] && typeof xcondition.value[0] == "string") {
            xcondition.xvalue = []
            //console.log("xcondition.value[0]", xcondition.value[0])
            let arrdt = xcondition.value[0].split("T")[0].split("-");
            let arrdt2 = xcondition.value[0].split("T")[1].split(":");
            xcondition.xvalue[0] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
          if (typeof xcondition.value == "object" && xcondition.value[1] && typeof xcondition.value[1] == "string") {
            let arrdt = xcondition.value[1].split("T")[0].split("-");
            let arrdt2 = xcondition.value[1].split("T")[1].split(":");
            xcondition.xvalue[1] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
        }
        if (typeof xcondition.value == "object" && xcondition.value[0] && xcondition.value[1]) {
          dt1 = new Date(xcondition.xvalue[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        } else {
          dt1 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        }
        data.filtercolumns[j].filter.condition["svalue"] = [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
      }
    }
  }

  onExport = (type, id, conditions) => {

    if (id === "F1") {
      let allcols = this.getLCS_D1_Columns();
      let len = allcols.length ;
      let averages = this.state.LCS_D1_SummaryData && this.state.LCS_D1_SummaryData.averages;
      let currcols = objFilterData.availableLCS_D1Fields;
      let lenAve = 0, arrAverage = averages && Object.keys(averages);
      lenAve = arrAverage && arrAverage.length;
      if (lenAve > 5 && allcols.length > colLength1 && conditions.length < 19) {
        let lenCols = colLength1 + this.state.groupTypeNames.length;
        //alert(lenCols + " - " + len)
        let cols = allcols.slice(lenCols, len);
        cols.map((item, i) => {
          delete item.cell;
          item.name = arrAverage[i + 6];
          item.showField = true
          item.showFieldFilter = false
          // currcols[item.name] = item.filter;
          currcols[arrAverage[i + 6]] = (JSON.parse(JSON.stringify(item)));
          conditions.push(JSON.parse(JSON.stringify(item)));
        })
      }
      this.setFilterFields("F1", conditions);
      let data = { type: type, columns: currcols };//objFilterData.availableLCS_D1Fields 
      data.dateformat = this.props.dateformat;
      data.timeformat = this.props.timeformat;
      data.filtercolumns = conditions;
      if (type === "web") {
        this.getLCS_D1_List(data);
      } else {
        this.getLCS_D1_Export(data);
      }
    }
  };
  setFilterFields = (id, conditions) => {
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportcellproperty) {
        xobj[conditions[i].name].exportcellproperty =
          conditions[i].exportcellproperty;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableLCS_D1Fields = xobj;
    }
  };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions, index) => {
    //console.log("onFilterChangeeeeee2", conditions);

    let isValid = true
    conditions.map((item, i) => {
      if (item.filter.checked && item.filter.required) {
        try {
          if ((item.filter.condition.operator == "MULTI_EQUALS" || item.filter.condition.operator == "EQUALS") && item.filter.condition.value == "") {
            isValid = false
            REFS.F1.onError(item)
            //WIDGETREFS[item.name].onError();
          }
        } catch (e) {
          isValid = false
          REFS.F1.onError(item)
          //WIDGETREFS[item.name].onError();
        }
      }
      return null;
    });
    if (isValid === true) {
      REFS.F1.onCloseCheckList()
      this.setFilterFields("F1", conditions);
      let data = { type: "web", columns: objFilterData.availableLCS_D1Fields };
      data.filtercolumns = conditions;
      this.setsvaluefordate(data)
      this.getLCS_D1_List(data);
    } else {

    }
    //
  };

  RunReport = () => {
    REFS.F1.onRunReport();
  };
  timeOutRunReport = () => {
    //let showFieldInitialMode = "OPEN"
    if (this.state.LCS_D1_List_Fetched === 0) {
      if (REFS && REFS.F1) {
        setTimeout(() => {
          this.RunReport()
        }, 10)
      } else {
        setTimeout(() => {
          this.timeOutRunReport()
        }, 10)
      }
    }
  }
  render() {
    const {
      LBLRUNREPORT,
      LBLLOADINGREPORT,
      LBLLEARNERS,
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLPERCENTCOMPLETED,
      LBLLEARNERCRSPROGEXPTYTXT,

    } = this.props.labels.labels;

    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    let unitscount = 0

    let averages = this.state.LCS_D1_SummaryData.averages
    if (this.state.pageState === "LCS_D1") {
      //const { LCS_D1_List } = this.state; 
      let columns = this.getLCS_D1_Columns();
      let lenCols = this.state.groupTypeNames.length + colLength1;
      let newColumns = columns.slice(0, lenCols);
      let filterFields = this.getFilterColumns("LCS_D1", newColumns);
      let resetfilterFields = this.getResetFilterColumns("LCS_D1", newColumns);
      let trans = this.getTranslation("LCS_D1", columns);
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < filterFields.length; j++) {
          // if (filterFields[j] && columns[i] && filterFields[j].name === columns[i].name) {
          if (filterFields[j] && columns[i] && filterFields[j].selector === columns[i].selector) {
            if (filterFields[j].filter && filterFields[j].filter.showField === false) {
              columns.splice(i, 1);
              i--;
            } else if (filterFields[j].exportcellproperty && filterFields[j].exportcellproperty.cellheader) {
              let xnm = filterFields[j].exportcellproperty.cellheader.split("$")
              if (this.getColumnName(xnm[0], xnm[1]) === "") {
                columns.splice(i, 1);
                i--;
              } else {
                unitscount++
                if (Object.keys(averages).length == 1) {
                  columns[i].name = this.props.labels.labels[filterFields[j].exportcellproperty.defaultheader]
                } else {
                  columns[i].name = this.getColumnName(xnm[0], xnm[1])
                }
              }
            }
          }
        }
      }
      let rowwidth = 0
      let colwidth = columns.length;
      for (let i = 0; i < colwidth; i++) {
        rowwidth = rowwidth + parseInt(columns[i].width.split("px")[0])
      }
      //alert(rowwidth)
      objFilterData.orgavailableLCS_D1Fields = this.getFilterColumns(
        "LCS_D1",
        this.getLCS_D1_Columns()
      );
      let data = this.state.LCS_D1_List;
      try {
        let xp = document.getElementById("F1_checkList").parentElement.offsetWidth;
        rowwidth = rowwidth > xp ? rowwidth : xp
      } catch (e) {

      }
      let showFieldInitialMode = "OPEN"
      if (this.state.LCS_D1_List_Fetched === 0) {
        if (this.props.location && this.props.location.state && this.props.location.state.selProduct) {
          showFieldInitialMode = "CLOSE"
          if (this.state.loading === false && this.state.filterDataLoaded === 1 && filterFields.length > 3) {
            if (REFS && REFS.F1) {
              setTimeout(() => {
                this.RunReport()
              }, 100)
            } else {
              setTimeout(() => {
                this.timeOutRunReport()
              }, 100)
            }
          }
        }
      }

      let customStyles = {  
        headRow: {
          style: {
            width: rowwidth + 'px !important',
          }
        },
        rows: {
          style: {
            width: rowwidth + 'px !important',
          },
        },
        headCells: {
          style: {
            '&:nth-child(1)': {
              left: "0px",
              position: "sticky",
              backgroundColor: "white",
              zIndex: 1000,
              borderRight: "1px solid rgba(0, 0, 0, 0.12)"
            },

          }
        },
        cells: {
          style: {
            '&:nth-child(1)': {
              left: "0px",
              position: "sticky",
              backgroundColor: "white",
              zIndex: 1000,
              borderRight: "1px solid rgba(0, 0, 0, 0.12)"
            },

          },
        },
      };
      return (
        <div className="reportsCtn">
          {/* <div className="card-box"> */}
          <div className="mob-pad">
            <div >
              <h3 className="page-title">{this.props.labels.labels.LBLLCS || "LBLLCS"}</h3>
            </div>
            <hr className="head-line" />
            <div id="accordion" className="row rptlst-row">
              {this.state.loading ? (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div className="row box ml-1">
                            <Skeleton height={46} />
                          </div>
                          <div className="row col-sm-12 prowfilterbox"></div>
                        </div>
                        <div>
                          <h3>
                            <Skeleton height={21} />
                          </h3>
                          <h2>
                            <Skeleton height={26} />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    {this.state.loading === false &&
                      this.state.filterDataLoaded === 1 &&
                      filterFields.length > 3 ? (
                      <div>
                        <CustomFilter
                          availableFields={filterFields}
                          selectedFields={filterFields}
                          orgAvailableFields={resetfilterFields}
                          onFilterChange={this.onFilterChange}
                          onExport={this.onExport}
                          id="F1"
                          onRef={(ref) => (REFS.F1 = ref)}
                          labels={this.props.labels.labels}
                          server={true}
                          reportName={
                            this.props.labels.labels.LBLLCS || "LBLLCS"
                          }
                          companyName={this.props.company_name}
                          showFieldFilter={true}
                          initialMode={showFieldInitialMode}
                          restrictExport="csv"

                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {this.state.LCS_D1_List_Fetched === 0 ? (
                    <div style={{ textAlign: "center", padding: "30px" }}> 
                      <button
                        onClick={this.RunReport.bind(this)}
                        id="btnGroupDrop12"
                        type="button"
                        class="button-style primary-btn" 
                      >
                        {/* <i className="fas fa-table" aria-hidden="true"></i>  */}
                        {LBLRUNREPORT}
                      </button>
                    </div>
                  ) : (
                    <>
                      {this.state.LCS_D1_List_Fetched !== 0 &&
                        this.state.reportloading === false ? (
                        <>
                          <div className="bottomborder">
                          </div>
                          {this.state.LCS_D1_List.length === 0 ? (
                            <div
                              style={{ textAlign: "center", padding: "30px" }}
                            >
                              {<EmptyState image={emptyImage} text2={LBLLEARNERCRSPROGEXPTYTXT} />}
                            </div>
                          ) : (
                            <>
                              <div>
                                <span className="subHeader" style={{ top: "5px", position: "relative" }}><b>{LBLPERCENTCOMPLETED}</b></span>
                                <div className="row prowbox" style={{ overflow: "auto" }} >
                                  <div className={Object.keys(averages).length < 8 ? "col-sm-12" : "col-sm-112"} style={{ padding:"10px" }}>

                                    <div className="rowbox d-flex bd-highlight" style={{ marginTop: "0px" }}>
                                      {Object.keys(averages).map(
                                        (d, key) => (
                                          <>
                                            <div className={"p-2 flex-fill bd-highlight "}>
                                              <div className={+ key > 0 ? "boxpad user-activity" : "boxpadnl user-activity"}>
                                                <div className="act-user">
                                                  <p className="user-c">{averages[d].name}</p>
                                                  <span> {parseInt((averages[d].CmpCnt * 100) / this.state.LCS_D1_List.length)}%</span>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="bottomborder">
                                <span className="subHeader">
                                  <h4>{`${LBLLEARNERS} : ${this.state.orgLCS_D1_List.length}`}</h4>
                                </span>
                              </div>
                              <DataTable
                                columns={columns}
                                data={data}
                                width={"150%"}
                                className="datatable-list reportsCtn"
                                sortFunction={this.customSort}
                                pagination
                                noDataComponent={<EmptyState image={emptyImage} text2={LBLLEARNERCRSPROGEXPTYTXT} />}
                                paginationComponentOptions={paginationComponentOptions}
                                customStyles={customStyles}
                              //conditionalRowStyles={conditionalRowStyles}
                              />
                              {this.state.orgLCS_D1_List.length > this.state.LCS_D1_List.length ? (
                                <>
                                  <Loader></Loader> {LBLLOADINGREPORT}
                                </>

                              ) : (<></>)}

                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Loader></Loader> {LBLLOADINGREPORT}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div>No Data for {this.state.pageState}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(LCS1);
//export default LCS1;


import React from 'react';
import './postimage.css';
//import ScCommonServices from '../../../services/common_services';
import NewFeedsServices from "../../feedService/feedsServices";

class PostImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageTag: "",
            imageSrc: "",
        }
    }
    componentDidMount() {
        if (this.props.type === 1) {
            this.setState({
                imageSrc: this.props.imageObj,
            })
        } else {
            let assetFolder = String(this.props.imageObj.asset_name).split(".");
            // assetFolder.pop();
            if (assetFolder.length > 1) {
                assetFolder.pop()
            }
            let postData = { "filename": this.props.imageObj.asset_name, "assettype": "image", "assetFoldet": assetFolder.join() };
            NewFeedsServices.setSignedUrl(postData).then(response => {
                this.setState({
                    imageSrc: response.data,
                })
            })



        }

    }
    signedUrlResultHandler = (resultData) => {
        if ((this.props.newpost === undefined) || this.props.newpost === 0) {
            this.setState({
                imageSrc: resultData.data.data
            })
        }
        else {
            let url = resultData.data.data
            fetch(url, {
                retryOn: function (attempt, error, response) {
                    if (error !== null || response.status >= 403) {

                        return true;
                    }
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            imageSrc: resultData.data.data
                        })
                    }
                })
        }
    }
    getResourceFromLabels = (paramLBL) => {
        let resourse = (this.props.feedsLanguageData && this.props.feedsLanguageData[paramLBL]) ? this.props.feedsLanguageData[paramLBL] : paramLBL;
        return resourse
    }
    removeImageHandler = (evt) => {
        console.log("removeImageHandler ");
        this.props.removePostAssets(this.props.index);
    }

    render() {
        //let {imageTag} = this.state;

        return (
            <div className="ssi-feeds-imageHolder">
                <div className="ssi-feeds-imageHolderWrapper">
                    {
                        this.state.imageSrc ? <img src={this.state.imageSrc} className="ssi-feeds-thamnailImage" alt="" /> : null
                    }

                    {
                        this.props.type === 1 ?
                            <div className="ssi-feeds-imageCloseBtn" title={this.getResourceFromLabels("LBLREMOVEIMAGE")} onClick={this.removeImageHandler}><span className="fas fa-times-circle"></span></div>
                            : null
                    }
                    <div className="ssi-feeds-imageTag" title={this.getResourceFromLabels("LBLTAG")} ><span className="fas fa-user-tag"></span></div>
                </div>
            </div>
        )
    }
}

export default PostImage;
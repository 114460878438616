import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required, minValue8, passwordsMustMatch } from "../admin/pages/validate/Validate";
import $ from 'jquery';
class PasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleSubmit = (values) => {
    console.log(values);
    this.props.set_user_password(values);
  };
  renderField = ({ input, label, type, className,onKeyUp, id, meta: { touched, error, warning } }) => (
    <div className="form-group">
      <input {...input} type={type} onKeyUp={onKeyUp} id={id} className={`${className} ${input.value ? 'complete' : ''}`} />
      <label htmlFor={input.name}>{label}</label>
      {touched &&
        ((error && <span className="required-field">{typeof (error) === "string" ? this.props.labels.labels[error] : this.props.labels.labels[error.label].replace("$$$", error.val)}</span>) ||
          (warning && <span className="required-field">{warning}</span>))}
    </div>
  )

  onChangeHandler = (e) => {
    if (e.target.value) {
      $(e.target).addClass("complete")
    }
    else {
      $(e.target).removeClass("complete")
    }
  }
  changePwdVisible =(e)=> {
     let keyid,keyclass;
     if(e.target.id==1){
      keyid =  "password";
      keyclass = ".pwdvisibility1 i";
     }else{
      keyid =  "cpassword";
      keyclass = ".pwdvisibility2 i";
     }
    var cntl = document.getElementsByName(keyid)[0];
    cntl.type = cntl.type === "password"? "text":"password";  
    if(cntl.type === "password"){
      $(keyclass).removeClass("fa-eye-slash").addClass("fa-eye");
    }else{
      $(keyclass).removeClass("fa-eye").addClass("fa-eye-slash");
    }
  }
  onKeyInput = (e) =>{ 
    let keyclass = e.target.id =="password"?".pwdvisibility1":".pwdvisibility2";
    let x = e.target.value;  
    $(keyclass).css({"display":x.length>0?"block":"none"})
  }
  render() {
    const { handleSubmit, submitting, labels } = this.props;
    return (
      <form
        className="form"
        onSubmit={handleSubmit(this.props.PasswordSubmit)}
      >
        <div className="text-left">
          <div className="form-group">
            <Field
              name="password"
              type="password"
              id="password"
              component={this.renderField}
              label={labels.labels.LBLNEWPASSWORD}
              className="form-field"
              validate={[required, minValue8]}
              onKeyUp={this.onKeyInput}
            />
            <span id="1" className="pwdvisibility1" onClick={this.changePwdVisible} ><i id="1" class="fas fa-eye"></i></span>  
          </div>

          <div className="form-group">
            <Field
              name="cpassword"
              type="password"
              id="cpassword"
              component={this.renderField}
              label={labels.labels.LBLCONFIRMPASSWORD}
              className="form-field"
              validate={[required, minValue8, passwordsMustMatch]}
              onKeyUp={this.onKeyInput}
            />
            <span id="2" className="pwdvisibility2" onClick={this.changePwdVisible} ><i id="2" class="fas fa-eye"></i></span> 
            
          </div>
        </div>
        <div className="form-group">
          <button
            disabled={submitting}
            className="btn login-btn primary-btn btn-block"
            type="submit"
          >
            {labels.labels.LBLSAVE}
          </button>
        </div>
      </form>
    );
  }
}

PasswordForm = reduxForm({
  form: "setpassword", // a unique name for this form
})(PasswordForm);

export default connect()(PasswordForm);

import { useLayoutEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import './style.scss';

const SeeMoreText = (props) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [showLink, setShowLink] = useState(false);

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setShowLink(true);
    }

    else {
      setShowLink(false);
    }
  }, [ref, props.textValue, props.showAllComments])


  let textClass = "some-more-text";
  if (open) {
    textClass += " some-more-open";
  }
  // console.log("show link", showLink, open);
  return <div className="see-more-text some-more-container">
    <span className={`${textClass}${props.textClass ? ' props.textClass' : ''}`} ref={ref} dangerouslySetInnerHTML={{ __html: props.textValue }}></span>
    {showLink && (
      <span className={`see-moreless-text${props.seemoreStyle ? ' props.seemoreStyle' : ''}`} onClick={() => setOpen(!open)}>{open ? props.seelesstext : props.seemoretext}</span>
    )}
  </div>
};

export default SeeMoreText;
const initialState = {
   
}

const logout_reducer = (state = initialState,action)=>{
     switch(action.type){ 
        default: return state
    }

}

export default logout_reducer;
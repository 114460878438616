import React, { Component } from "react";
import ReportServices from "../../report_services";
import DataTable from "react-data-table-component";
import orderBy from "lodash/orderBy";
import CustomFilter from "../../../../components/reportfilter/containers/CustomFilter";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import emptyImage from '../../../../../assets/images/learnerprogress_emptyst.png'
import EmptyState from "../../../../../common_components/empty_state/Empty_state";
import moment from "moment";

import { exportToExcel, exportCSV } from '../../../../../common_components/rptexport';
const $ = require("jquery");
let objFilterData = {};
//let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];                                 
const REFS = {};
$(window).resize(function () {
  $(window).height();
  $(window).width();
});
class RefresherCapsuleusers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orgLearnersList: [],
      learnersList: [],
      groupTypeNames: [],
      groupTypeColumns: [],
      loadgrouptype: 0,
      loadlearnersList: 0,
      columns: [],
      summaryData: {
        Trained: "",
        Completed: "",
        Inprogress: "",
        Notstarted: "",
      },
      chartdata: [10, 90],
      pageState: "RefresherCapsuleReport",
      orgLearnerResultList: [],
      learnerResultList: [],
      summaryLRData: {
        Trained: "",
        Completed: "",
        Inprogress: "",
        Notstarted: "",
      },
      orgLearnerDetailResultList: [],
      learnerDetailResultList: [],
      summaryLRDData: {
        Trained: "",
        Completed: "",
        Inprogress: "",
        Notstarted: "",
      },
      userData: {},
      productData: {},
      userNames: [],
      userMails: [],
      coursestatus: [],
      selectedCapsuleName: "",
      // showUser:false
    };
  }
  loadpage(a) {
    if (a === "learnerlist") {
      this.setState({
        loading: false,
        loadlearnersList: 1
      });
    }
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.hasOwnProperty(xfield)) {
        return this.props.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  componentDidMount() {
    $("html").scrollTop(0);
    objFilterData = {};
    this.setState(
      {
        filterDataLoaded: 1,
        pageState: "LCS_D1",
        courses: this.props.courses,
        userNames: this.props.userNames,
        userMails: this.props.userMails,
        courseunits: this.props.courseunits,
        rpt_showfields: this.props.rpt_showfields,
        coursestatus: this.props.coursestatus,
        groupTypeNames: this.props.groupTypeNames,
        groups: this.props.groups
      },
      this.loadpage("LCS_D1")
    );
    this.getUsers(this.props.selectedCapsuleItem);
  }
  getUsers(params) {
    ReportServices.getCapsuleAssignedUserList(params.cid).then((response) => {
      // console.log("getLearnersList!!!!!!!!11", response.result[2]);
      let result = response;
      let xsummaryData = {
        Learners: 0,
        Enrollments: 0,
        Trained: 0,
        Completed: 0,
        Inprogress: 0,
        Notstarted: 0,
      };
      let userdata = [];
      let arrgp1data = [],
        gp1data = {};
      let arrgp2data = [],
        gp2data = {};
      let arrgp3data = [],
        gp3data = {};
      for (var i = 0; i < result.length; i++) {
        xsummaryData.Enrollments =
          xsummaryData.Enrollments + result[i].Enrollments;
        xsummaryData.Inprogress =
          xsummaryData.Inprogress + result[i].Inprogress;
        xsummaryData.Completed = xsummaryData.Completed + result[i].Completed;
        xsummaryData.Notstarted =
          xsummaryData.Notstarted + result[i].Notstarted;
        userdata.push({
          id: result[i].user_id,
          value: result[i].full_name,
        });
        if (result[i].GP_1) {
          let xg = result[i].GP_1.split(", ");

          for (let xgi = 0; xgi < xg.length; xgi++) {
            if (!gp1data[xg[xgi]]) {
              gp1data[xg[xgi]] = 1;
              arrgp1data.push({ id: xg[xgi], value: xg[xgi] });
            }
          }
        }
        if (result[i].GP_2) {
          let xg = result[i].GP_2.split(", ");
          for (let xgi = 0; xgi < xg.length; xgi++) {
            if (!gp2data[xg[xgi]]) {
              gp2data[xg[xgi]] = 1;
              arrgp2data.push({ id: xg[xgi], value: xg[xgi] });
            }
          }
        }
        if (result[i].GP_3) {
          let xg = result[i].GP_3.split(", ");
          for (let xgi = 0; xgi < xg.length; xgi++) {
            if (!gp3data[xg[xgi]]) {
              gp3data[xg[xgi]] = 1;
              arrgp3data.push({ id: xg[xgi], value: xg[xgi] });
            }
          }
        }
      }
      let prcntTrained =
        parseInt((xsummaryData.Completed * 1000) / xsummaryData.Enrollments) /
        10;
      prcntTrained = isNaN(prcntTrained) ? 0 : prcntTrained;
      xsummaryData.Trained = prcntTrained;
      xsummaryData.Learners = result.length;
      this.setState(
        {
          pageState: "RefresherCapsuleReport",
          learnersList: result,
          orgLearnersList: result,
          summaryData: xsummaryData,
          userNames: userdata,
          GP_1Names: arrgp1data,
          GP_2Names: arrgp2data,
          GP_3Names: arrgp3data,
          selectedCapsuleName: params.c_name,
        },
        this.loadpage("learnerlist")
      );
    });
  }

  showRefresherCapsuleReport = () => {
    // console.log("showRefresherCapsuleReport");
    this.setState({
      pageState: "RefresherCapsuleReport",
    });
  };

  getRefresherCapsuleReportColumns = (x) => {
    let columns = [
      {
        name: this.props.labels.LBLFULLNAME,
        selector: "full_name",
        filter: { type: "string", showField: true, checked: true, multi: true },
        filterData: this.state.userNames,
        show: true,
        sortable: true,
        compact: true,
        exportcell: "Full_Name",
        cell: (row) => {
          return (
            <div className='hde-user-icn'>
              <label className="show-sm">
                {this.props.labels.LBLFULLNAME}
              </label>
              <span className="rle-brdr" title={row.full_name}>
                {row.full_name}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.LBLEMAIL,
        selector: "email",
        filter: { type: "string", showField: true, checked: true, multi: true },
        filterData: this.state.userMails,
        sortable: true,
        compact: true,
        exportcell: "email",
        cell: (row) => {
          return (
            <div className='hde-user-icn'>
              <label className="show-sm">
                {this.props.labels.LBLEMAIL}
              </label>
              <span className="rle-brdr" title={row.email}>
                {row.email}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.LBLRELEASEDON,
        selector: "released_on",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.LBLENROLLMENTS}
              </label>
              <span className="rle-brdr">{row.released_on ? moment(row.released_on).format(this.props.dateformat) : null}</span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.LBLCOMPLETEDDATE,
        selector: "completed_date",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        exportcell: "Trained",
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.LBLCOMPLETEDDATE}
              </label>
              <span className="rle-brdr">
                {row.completed_date ? moment(row.completed_date).format(this.props.dateformat) : ''}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.LBLSTATUS,
        selector: "status",
        filter: { type: "string", showField: true, checked: true, multi: true },
        filterData: this.state.coursestatus,
        // show: false,
        sortable: true,
        compact: true,
        exportcell: "status",
        cell: (row) => {
          return (
            <div className='hde-user-icn'>
              <label className="show-sm">
                {this.props.labels.LBLSTATUS}
              </label>
              <span className="rle-brdr">
                {

                  this.props.labels["LBL" + row.status.trim().toUpperCase()]
                }
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.LBLACTIVE || 'Active',
        selector: "act_st",
        filter: { type: "number", showField: true, checked: false },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.LBLACTIVE}
              </label>
              <span className={row.act_st === "Active"? "LBLACTIVE" : "LBLINACTIVE"} >{
                this.props.labels["LBL" + row.act_st.toUpperCase()]
              }</span>
            </div>
          );
        },
      },

    ];
    let xcolumns = [];
    let response = this.state.groupTypeNames;
    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        //let x1 = response[i].group_type_id
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          filter: {
            type: "string",
            showField: false,
            checked: true,
          },
          filterData: this.state.groups[response[i].group_type_id],
          sortable: true,
          compact: true,
        });
        ////alert(this.state[response[i].group_type_id + "Names"])
      }
    }

    columns.splice.apply(columns, [2, 0].concat(xcolumns));
    return columns; //JSON.parse(JSON.stringify(columns))
  };

  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    //console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    let xobj = objFilterData.availableLearnProgressFields;
    if (xobj) {
      for (var j = 0; j < xcols.length; j++) {
        xcols[j].filter = xobj[xcols[j].name];
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      // console.log(row[field]);
      if (row[field] && typeof row[field] === "string") {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  checkFilterCondition = (filters, data) => {
    //console.log("checkFilterCondition 0", filters, data);
    for (var i in filters) {
      if (filters[i].condition && filters[i].checked) {
        let dvalue = data[filters[i].selector];
        let fdvalue = [];
        let fselector = filters[i].selector.split(",");
        for (var xi = 0; xi < fselector.length; xi++) {
          fdvalue.push(data[fselector[xi]]);
        }
        dvalue = fdvalue.join(" ");
        let cvalue = filters[i].condition.value || "";
        let xr = false;
        let dtime;
        let ctime;
        let ctime1;
        let ctime2;

        // if(dvalue==null){
        //     return
        // }

        if (filters[i].condition.operator === "NOCONDITION") {
          xr = true;
        } else {
          if (filters[i].type === "string") {

            if (filters[i].condition.operator === "CONTAINS") {
              //cvalue != "" && dvalue != null &&
              if (dvalue == null) {
                xr = false;
              } else if (
                dvalue.toLowerCase().split(cvalue.toLowerCase()).length > 1
              ) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "DOES_NOT_CONTAIN") {
              if (dvalue == null) {
                xr = false;
              } else if (
                dvalue.toLowerCase().split(cvalue.toLowerCase()).length === 1
              ) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue.toLowerCase() === cvalue.toLowerCase()) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue.toLowerCase() !== cvalue.toLowerCase()) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "MULTI_EQUALS") {
              if (cvalue === "" || (typeof cvalue == "object" && cvalue.length === 0)) {
                xr = true;
              } else {
                let rxr = 0;

                for (let xi = 0; xi < cvalue.length; xi++) {
                  if (dvalue.toLowerCase() === cvalue[xi].value.toLowerCase()) {
                    rxr = 1;
                    break;
                  }
                }
                if (filters[i].label) {
                  for (let xi = 0; xi < cvalue.length; xi++) {
                    if (
                      (
                        this.props.labels[
                        "LBL" + dvalue.toUpperCase()
                        ] || ""
                      )
                        .toLowerCase()
                        .indexOf(cvalue[xi].value.toLowerCase()) > -1
                    ) {
                      rxr = 1;
                      break;
                    }
                  }
                }
                xr = rxr === 0 ? false : true;
              }
            }
          } else if (filters[i].type === "number") {
            if (filters[i].condition.operator === "EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue === cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue !== cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "GREATER_THAN") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > cvalue) {
                xr = true;
              }
            } else if (
              filters[i].condition.operator === "GREATER_THAN_EQUALS"
            ) {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue >= cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue <= cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "BETWEEN") {
              var min = Math.min.apply(Math, cvalue),
                max = Math.max.apply(Math, cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > min && dvalue < max) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_BETWEEN") {
              var minTwo = Math.min.apply(Math, cvalue),
                maxTwo = Math.max.apply(Math, cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < minTwo || dvalue > maxTwo) {
                xr = true;
              }
            }
          } else if (filters[i].type === "date") {
            var xgetTime = function (x) {
              let dx = new Date(x);
              dx.setHours(0);
              dx.setMinutes(0);
              dx.setMinutes(0);
              dx.setSeconds(0);
              dx.setMilliseconds(0);
              return dx.getTime();
            };
            if (filters[i].condition.operator === "EQUALS") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dtime === ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "GREATER_THAN") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dtime > ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dtime < ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "BETWEEN") {
              dtime = xgetTime(dvalue);
              ctime1 = xgetTime(cvalue[0]);
              ctime2 = xgetTime(cvalue[1]);
              var minThree = Math.min.apply(Math, [ctime1, ctime2]),
                maxThree = Math.max.apply(Math, [ctime1, ctime2]);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > minThree && dvalue < maxThree) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_BETWEEN") {
              dtime = xgetTime(dvalue);
              ctime1 = xgetTime(cvalue[0]);
              ctime2 = xgetTime(cvalue[1]);
              var minFour = Math.min.apply(Math, [ctime1, ctime2]),
                maxFour = Math.max.apply(Math, [ctime1, ctime2]);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < minFour || dvalue > maxFour) {
                xr = true;
              }
            }
          }
        }
        if (xr === false) {
          return false;
        }
      }
    }
    return true;
  };
  onExport = (type, id, conditions) => {
    this.setFilterFields(conditions, type);
  };
  setFilterFields = (conditions, type) => {
    var xobj = {};
    let filterCols = [];
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
      if (xobj[conditions[i].name].showField) {
        filterCols.push({ name: conditions[i].name, selector: conditions[i].selector });
      }
    }
    objFilterData.availableLearnProgressFields = xobj;
    objFilterData.availableLearnProgressConditions = conditions;
    let finalData = [];
    this.state.learnersList.map((item) => {
      let tempObj = {};
      tempObj[this.props.labels.LBLCAPSULENAME] = this.state.selectedCapsuleName;
      //console.log(" filterCols", filterCols);
      for (var k in filterCols) {
        let dataValue = '';
        //console.log("filterCols[k].selector", filterCols[k].selector)
        if ((filterCols[k].selector === "released_on" || filterCols[k].selector === "completed_date") && item[filterCols[k].selector]) {
          dataValue = moment(item[filterCols[k].selector]).format(this.props.dateformat) || '';
        }
        else if ((filterCols[k].selector === "status" || filterCols[k].selector === "act_st") && item[filterCols[k].selector]) {
          // dataValue = (item[filterCols[k].selector])
          dataValue = this.props.labels["LBL" + (item[filterCols[k].selector].trim().toUpperCase())] || ''
          // toUpperCase()
        }
        else {
          // console.log(" dataValue ", dataValue )
          dataValue = item[filterCols[k].selector] || '';
          //console.log(" dataValue ", dataValue)
        }
        tempObj[filterCols[k].name] = dataValue;
      }
      finalData.push(tempObj);
      return null;
    })
    //console.log("final data", finalData);
    const fileName = this.props.labels.LBLREFDETAILEDSUMMARYEPRT;
    let data = finalData;
    if (type === "csv") {
      exportCSV(data, fileName,this.props.dateformat);
    } else if (type === "xlsx") {
      exportToExcel(data, fileName,this.props.dateformat);
    }
  };

  onFilterCancel = (id, conditions) => {
  };
  onFilterChange = (id, conditions) => {
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportfunction) {
        xobj[conditions[i].name].exportfunction = conditions[i].exportfunction;
      }
    }
    objFilterData.availableLearnProgressFields = xobj;
    objFilterData.availableLearnProgressConditions = conditions;
    let result = [];

    for (var j = 0; j < this.state.orgLearnersList.length; j++) {
      if (
        this.checkFilterCondition(
          xobj,
          this.state.orgLearnersList[j]
        ) === true
      ) {
        result.push(this.state.orgLearnersList[j]);
      }
    }

    let xsummaryData = {
      Learners: 0,
      Enrollments: 0,
      Trained: 0,
      Completed: 0,
      Inprogress: 0,
      Notstarted: 0,
    };
    for (var k = 0; k < result.length; k++) {
      xsummaryData.Enrollments =
        xsummaryData.Enrollments + result[k].Enrollments;
      xsummaryData.Inprogress =
        xsummaryData.Inprogress + result[k].Inprogress;
      xsummaryData.Completed = xsummaryData.Completed + result[k].Completed;
      xsummaryData.Notstarted =
        xsummaryData.Notstarted + result[k].Notstarted;
    }
    let prcntTrained =
      parseInt((xsummaryData.Completed * 1000) / xsummaryData.Enrollments) /
      10;
    prcntTrained = isNaN(prcntTrained) ? 0 : prcntTrained;
    xsummaryData.Trained = prcntTrained;
    xsummaryData.Learners = result.length;

    this.setState({
      learnersList: result,
      summaryData: xsummaryData,
    });
    //})
    // console.log("id",id)
  };
  render() {
    const {
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLLEARNERCRSPROGEXPTYTXT,
    } = this.props.labels;
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    // console.log("this.state.pageState", this.state.pageState, this.state.learnersList);
    try {
      let xcolumns = this.getRefresherCapsuleReportColumns();
      let filterFields = this.getFilterColumns("RefresherCapsuleReport", xcolumns);
      let resetfilterFields = this.getResetFilterColumns(
        "RefresherCapsuleReport",
        xcolumns
      );
      let columns = [];
      for (let i = 0; i < xcolumns.length; i++) {
        for (let j = 0; j < filterFields.length; j++) {
          if (
            filterFields[j].name === xcolumns[i].name &&
            filterFields[j].hasOwnProperty("filter") === true && filterFields[j].filter.showField === true
          ) {
            columns.push(xcolumns[i])
          }
        }
      }
      let data = this.state.learnersList;
      //console.log("data", data)
      return (
        <div>
          <div className="mob-pad">
            <div>
              {this.state.loading === false &&
                this.state.userNames.length > 0 &&
                (filterFields.length >= 2 || this.props.ssi_data_priv) ? (
                <div>
                  <CustomFilter
                    labels={this.props.labels}
                    availableFields={filterFields}
                    selectedFields={filterFields}
                    orgAvailableFields={resetfilterFields}
                    onFilterChange={this.onFilterChange}
                    onExport={this.onExport}
                    id="F1"
                    onRef={(ref) => (REFS.F1 = ref)}
                    showFieldFilter={true}
                  />
                </div>
              ) : this.state.loading === false && filterFields.length === 0 ? (
                <div>
                  <div>
                    <div>
                      <div className="row box ml-1">
                        <Skeleton height={46} />
                      </div>
                      <div>
                        <h3>
                          <Skeleton height={21} />
                        </h3>
                        <h2>
                          <Skeleton height={26} />
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="ref_capsubhead">
              <div className="ref_capsule">{this.props.labels.LBLCAPSULENAME} :</div>
              <div className="ref_capsulename">{this.state.selectedCapsuleName}</div>
            </div>
            <div className="ref_usersubhead">
              <div className="ref_users">{this.props.labels.LBLNUMBEROFUSERS} :</div>
              <div className="ref_usersname">{this.state.summaryData.Learners}</div>
            </div>
            {this.state.loadlearnersList === 1 ?
              <div id="accordion" className="row rptlst-row">
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <DataTable
                    columns={columns}
                    data={data}
                    className="datatable-list"
                    sortFunction={this.customSort}
                    pagination
                    noDataComponent={<EmptyState image={emptyImage} text2={LBLLEARNERCRSPROGEXPTYTXT} />}
                    defaultSortField="name"
                    paginationComponentOptions={paginationComponentOptions}
                  />
                </div>
              </div>
              : null}
          </div>
        </div>
      );

    } catch (e) {
      // return <div>No Data for ERR:{this.state.pageState}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};
export default connect(mapStateToProps, null)(RefresherCapsuleusers);

import React from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { userUpdate } from "../../../redux/action";
import UserManagementForm from "./userManagementForm";
import { Modal } from "react-bootstrap";
import * as CryptoJS from "crypto-js";
import styles from "./userlist.module.scss"
import Base64 from "crypto-js/enc-base64";
import orderBy from "lodash/orderBy";
import { connect } from "react-redux";
import UsersService from "./UsersService";
import GroupServices from "../group-list/GroupServices";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import emptyImage from "../../../assets/images/userlist_emptyst.png";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import FilterUsers from "../../../common_components/filter/FilterUsers";
import Alert from "../../../common_components/Alert/Alert";
import TrainingMultiAssign from "./trainingMultiAssign";
import ChannelMultiAssign from "./ChannelMultiAssign";
import UserManagementHeader from "../../../common_components/datatable/UserManagementHeader";
import SendNotificationPopUp from "../../../common_components/datatable/SendNotificationPopUp";
import { DataGrid } from '@mui/x-data-grid';
import { email } from "../validate/Validate";
const $ = require("jquery");
const moment = require("moment");
let changesIn = {};
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
let disable_send_invite = 0;

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      email: props.email,
      edituseremail: "",
      user_id: props.user_id,
      userlist: [],
      resultData: [],
      roles: [],
      visible: "none",
      orgs: [],
      showDelInfo: false,
      groups: [],
      languages: [],
      loading: true,
      show: false,
      showFilter: false,
      status: 0,
      initialValues: null,
      ssi_errors: null,
      searchValue: "",
      roleFilter: "",
      groupData: [],
      group_type: "",
      roleValid: false,
      mailPhoneValid: false,
      groups_by_type: [],
      helpersLoaded: false,
      showTab: "info",
      showEditUserNameAlert: false,
      allFilterValues: [],
      enableEditUsername: false,
      disableEmailField: false,
      rp_submitbutton: false,
      emailField: true,
      enableEmailField: false,
      showemailtab: false,
      emailalerttext: "",
      selectedRoleIds: [],
      showverifyalert: false,
      uservalueparams: null,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      setSingleDropDown: false,
      showFilterSideMenu: false,
      showFilterMobile: false,
      openDropDown: [],
      singleStatusChecked: null,
      selectedMultiArrIds: [],
      selectedMultiArrNames: [],
      width: 0,
      height: 0,
      selectedRowsCount: 0,
      selectedRows: [],
      showAssignDropDwn: false,
      showAlert: false,
      showPopUP: false,
      alertTitle: "",
      alertMessage: "",
      alertContent: {},
      initialPage: "userlist",
      assignedStatus: null,
      channelStatus: null,
      clearSelectedRows: false,
      multiNotifyType: "",
      currentId: "",
      showNode: true,
      singleItemStatus: null,
      user_reinvite_disable: false,
      currentAction: -1,
      currentNoftiyStatus: -1,
      disableMultiDelete: false,
      IMPressUsers: [],
      toggleClearRows: true,
      invite: 0,
      reInvite: 0,
      verify: 0,
      reVerify: 0,
      showButton: true,
      visibleRowsSelected: false,
      pageStatus: { // Loading data in the datagrid component
        isLoading: false,
        data: [], // rows
        total: 0 // total count of data grid we need to show
      },
      paginationModel: { // pagination variables
        page: 0,
        pageSize: 10,
      },
      rowSelectionModel: [],
      filteredUsers: [],
      showEmailTab: false,
      isShowUserLicenseCnt: false,
      sortModel: [],
      showimport:false,
    };
  }
  showHideUserLicenceCnt = () => {
    this.setState({
      isShowUserLicenseCnt: !this.state.isShowUserLicenseCnt
    })
  }

  openModalImport = () => {
    console.log("userCount",this.state.userCount)
    this.setState({
      showCount: true
    })
  }
  applyFilters = (filter) => {
    this.setState({
      allFilterValues: filter,
      showNode: true,
      paginationModel: { // pagination variables
        page: 0,
        pageSize: this.state.paginationModel.pageSize,
      },
    }, () => {
      this.filterUsers();
      this.handleSortModelChange(this.state.sortModel);
    });
    if (!filter.reset) {
      this.setState({ visible: "block" });
    }
    $(".bounceInDown").hide();
    $(".modal-backdrop").css({ position: "relative" });
    $(".modal-backdrop.show").css({ opacity: "0" });
    $("#root").css({ overflowY: "auto", overflowX: "hidden" });
  };

  keyvaluechanges = (response) => {
    let
      allGroups,
      objAllGroups = [];
    let groupname,
      grouptypeid,
      objRoles,
      singles = [];
    let objStatus = [
      { name: "LBLACTIVE", id: "1" },
      { name: "LBLINACTIVE", id: "0" },
    ];
    let objUserTypes = [
      { id: 101, name: "LBLNOTINVITEDUSERS" },
      { id: 102, name: "LBLPENDINGACCEPTANCE" },
      { id: 103, name: "LBLINVITEACCEPTED" },
      { id: 104, name: "LBLUSERNOTHAVEEMAIL" },
    ];
    let xroles = response.roles;
    allGroups = response.groups;
    objRoles = JSON.parse(
      JSON.stringify(xroles)
        .split('"role_id":')
        .join('"id":')
        .split('"role_name":')
        .join('"name":')
    );
    objAllGroups.push({
      items: JSON.stringify(objUserTypes),
      itemsinfo: {
        itemid: "usertype",
        itemname: "LBLUSERTYPE",
        label: "LBLUSERTYPE",
        trans: true,
      },
    });
    objAllGroups.push({
      items: JSON.stringify(objRoles),
      itemsinfo: {
        itemid: "roles",
        itemname: "LBLROLE",
        label: "LBLROLE",
        trans: true,
      },
    });
    singles.push({
      items: objStatus,
      itemsinfo: {
        itemid: "status",
        itemname: "LBLSTATUS",
        label: "LBLSTATUS",
        trans: true,
      },
    });

    if (typeof allGroups == "object") {
      allGroups.map((element) => {
        if (typeof element == "object") {
          if (typeof element.groups_by_type != "undefined") {
            if (typeof element.group_info == "string") {
              grouptypeid = JSON.parse(element.group_info).group_type_id;
              groupname = JSON.parse(element.group_info)["lbl_" + grouptypeid];
            } else if (typeof element.group_info == "object") {
              grouptypeid = element.group_info.group_type_id;
              groupname = element.group_info["lbl_" + grouptypeid];
            } else {
              groupname = "Group";
            }
            let groups = "";
            if (typeof element.groups_by_type == "string") {
              groups = element.groups_by_type
                .split('"group_id":')
                .join('"id":')
                .split('"group_name":')
                .join('"name":');
              JSON.parse(
                objAllGroups.push({
                  items: groups,
                  itemsinfo: {
                    itemid: groupname,
                    itemname: groupname,
                    label: groupname,
                  },
                })
              );
            } else {
              groups = JSON.stringify(
                element.groups_by_type
                  .split('"group_id":')
                  .join('"id":')
                  .split('"group_name":')
                  .join('"name":')
              );
              objAllGroups.push({
                items: groups,
                itemsinfo: {
                  itemid: groupname,
                  itemname: groupname,
                  label: groupname,
                },
              });
            }
          }
        }
        return null;
      });
    }

    this.setState({
      toFilters: {
        multi: objAllGroups,
        single: singles,
      },
    });

    return objAllGroups;
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.paginationModel.page !== this.state.paginationModel.page ||
      prevState.paginationModel.pageSize !== this.state.paginationModel.pageSize ||
      prevState.filteredUsers !== this.state.filteredUsers
    ) {
      this.setState({
        pageStatus: { ...this.state.pageStatus, isLoading: false, data: this.state.filteredUsers.slice(((this.state.paginationModel.page) * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: this.state.filteredUsers.length }
      });
    }

    if (
      prevState.pageStatus.data !== this.state.pageStatus.data ||
      prevState.rowSelectionModel !== this.state.rowSelectionModel
    ) {
      this.handleVisibleRowsSelection();
    }
  }
  handleVisibleRowsSelection = () => {
    let totalavailableRows = this.state.pageStatus.data.length;
    if (this.state.pageStatus.data.length > 0) {
      let selectedRowCount = 0;
      this.state.pageStatus.data.forEach(val => {
        if (this.state.rowSelectionModel.includes(val.user_id)) {
          selectedRowCount++;
        }
      });

      let totalSelectedRows = selectedRowCount;
      if (totalavailableRows === 0) {
        this.setState({ visibleRowsSelected: false })
      } else if (totalavailableRows === totalSelectedRows && this.state.selectedRowsCount !== this.state.userlist.length) {
        this.setState({ visibleRowsSelected: true })
      } else {
        this.setState({ visibleRowsSelected: false })
      }
    }
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.updateWindowDimensions();
    this.getUsers();
    this.getSettings();
    let getData = {
      language: this.props.language,
    };
    UsersService.usersHelpers(getData).then((response) => {
      this.keyvaluechanges(response);
      this.setState({
        roles: response.roles,
        groups_by_type: response.groups,
        languages: response.languages,
        helpersLoaded: true,
      });
    });
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.state = {
      email: this.props.email,
      edituseremail: "",
      user_id: this.props.user_id,
      userlist: [],
      resultData: [],
      roles: [],
      visible: "none",
      orgs: [],
      showDelInfo: false,
      groups: [],
      languages: [],
      loading: true,
      show: false,
      showFilter: false,
      status: 0,
      initialValues: null,
      ssi_errors: null,
      searchValue: "",
      roleFilter: "",
      groupData: [],
      group_type: "",
      roleValid: false,
      mailPhoneValid: false,
      groups_by_type: [],
      helpersLoaded: false,
      showTab: "info",
      showEditUserNameAlert: false,
      allFilterValues: [],
      enableEditUsername: false,
      disableEmailField: false,
      rp_submitbutton: false,
      emailField: true,
      enableEmailField: false,
      showemailtab: false,
      emailalerttext: "",
      selectedRoleIds: [],
      showverifyalert: false,
      uservalueparams: null,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      setSingleDropDown: false,
      showFilterSideMenu: false,
      showFilterMobile: false,
      openDropDown: [],
      singleStatusChecked: null,
      selectedMultiArrIds: [],
      selectedMultiArrNames: [],
      width: 0,
      height: 0,
      selectedRowsCount: 0,
      selectedRows: [],
      showAssignDropDwn: false,
      showAlert: false,
      showPopUP: false,
      alertTitle: "",
      alertMessage: "",
      alertContent: {},
      initialPage: "userlist",
      assignedStatus: null,
      channelStatus: null,
      clearSelectedRows: false,
      multiNotifyType: "",
      currentId: "",
      showNode: true,
      singleItemStatus: null,
      user_reinvite_disable: false,
      currentAction: -1,
      currentNoftiyStatus: -1,
      disableMultiDelete: false,
      IMPressUsers: [],
      toggleClearRows: true,
      invite: 0,
      reInvite: 0,
      verify: 0,
      reVerify: 0,
      showButton: true,
      visibleRowsSelected: false,
      pageStatus: { // Loading data in the datagrid component
        isLoading: false,
        data: [], // rows
        total: 0 // total count of data grid we need to show
      },
      paginationModel: { // pagination variables
        page: 0,
        pageSize: 10,
      },
      rowSelectionModel: [],
      filteredUsers: [],
      showEmailTab: false,
      isShowUserLicenseCnt: false,
      sortModel: [],
      showimport:false,
    };
    this.wrapperRef = null;
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  handleClickOutside(event) {
    console.log('handle click out side', event.target, this.wrapperRef && this.wrapperRef.current);
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target) && this.state.showFilterSideMenu
    ) {
      console.log('handle click in')
      this.setState({ showFilterSideMenu: false });
      $(".user-list-filter").css({ display: "none", transition: "0.5s" });
    }
  }
  getUserCount = () => {
    HttpInterceptor.get("userscount").then((res) => {
      let item = res.data.result[0];
      let userCount = item.total_user_license -( item.active_users-item.consultant_active)
      console.log("usercoun1",userCount)
      this.setState({
        showimport:true,
        total_user_license: item.total_user_license,
        active_users: item.active_users-item.consultant_active,
        inactive_users: item.inactive_users-item.consultant_inactive,
        userCount: userCount,
        licenseDetails: item,
        consultants_count:item.consultants_count


      });
    });
  };
  getSettings = () => {
    HttpInterceptor.get("settings").then((res) => {
      let item = res.data.result[0];
      this.setState({
        userimport: item.userimport,
        emailfailure: item.emailfailure ? item.emailfailure : 0,
        login_with_phone_number: item.login_with_phone_number,
      });
    });
  };
  getUsers = () => {
    UsersService.getUsers({ active: true })
      .then((res) => {
        this.setState((state, props) => ({
          userlist: res.result,
          filteredUsers: res.result,
          resultData: res.result,
          loading: false,
          clearSelectedRows: false,
          pageStatus: { isLoading: false, data: res.result.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: res.result.length },
          rowSelectionModel: []
        }));
        this.getUserCount();
      })
      .catch((err) => {
        if (err) throw err;
      });
  };

  emailtab = () => {
    const profile_div = document.querySelector("#sub-heading");
    let module_change = profile_div.querySelectorAll(".cst");

    module_change.forEach((module) => {
      module.classList.remove("active_cst");
    });

    module_change[1].click();
  };

  openEditModal1 = (item) => {
    this.setInitialValues(item);
    this.setState({
      show: true,
      roleValid: false,
      mailPhoneValid: false,
      showTab: "info",
    });
  };
  getGroups = () => {
    this.setState((state, props) => ({
      loading: true,
    }));
    GroupServices.getGroups()
      .then((response) => {
        this.setState((state, props) => ({
          groups_by_type: response,
          loading: false,
        }));
      })
      .catch((err) => {
        if (err) console.log(err);
      });
  };
  delUser = () => {
    let editData = {
      pType: 1,
      userid: [this.state.userId],
    };
    this.resetFilterValues();
    HttpInterceptor.delete("users", { data: { data: editData } }).then(
      (res) => {
        console.log("abccccccccccccccccccccccccccccc")
        let lbl_team = this.props.labels.labels.LBLTEAMS;
        let arr_lbl_message,
          lbl_message = this.props.labels.labels.LBLWARNSCOMUSRDEL;
        arr_lbl_message = lbl_message.split("::");
        lbl_message =
          arr_lbl_message.length > 1 ? arr_lbl_message[0] : lbl_message;
        lbl_team = lbl_team ? lbl_team : "Associated Teams";
        let teams_all = "",
          teams = res.data;
        if (teams && teams.length > 0) {
          let teamsArr = [];
          teams.map((item) => {
            if (teamsArr.findIndex((n) => n === item.team_name) < 0) {
              teams_all =
                teams_all +
                "<p class='cls_del_info'>" +
                item.team_name +
                (item.is_admin === 0
                  ? ""
                  : " (<span class='cls_del_admin'>" +
                  (item.is_admin === 1
                    ? "Admin"
                    : item.is_admin === 2
                      ? "Additional Admin"
                      : "") +
                  "</span>)") +
                "</p>";
              teamsArr.push(item.team_name);
            }
            return null;
          });
          lbl_message =
            lbl_message +
            "<p>" +
            (arr_lbl_message.length > 1 ? arr_lbl_message[1] : "");

          teams_all = "<p></p><p><b>" + lbl_team + "</b></p>" + teams_all;
        }

        let message = lbl_message + teams_all;
        if (res.data.message) {
          this.setState({ rowSelectionModel: [], searchValue: "" })
          this.setState({
            show: false,
            showDel: false,
            initialValues: null,
            showToast: {
              show: true,
              alertClass: "success",
              status: "Success",
              message: this.state.rowSelectionModel.length > 1 ? this.props.labels.labels.LBLUSRSDELETED : this.props.labels.labels.LBLUSRDELETED,
              changeInToast: new Date(),
            },
            toggleClearRows: !this.state.toggleClearRows,
          });
        } else {
          this.setState({
            showDelInfo: true,
            messageInfo: message,
          });
          setTimeout(() => {
            this.setState({
            });
          }, 5000);
          $("#user_delete").html(message);
        }
        setTimeout(() => {
          this.setState({
            showToast: {
              show: false,
              alertClass: "",
              status: "",
              message: "",
              changeInToast: new Date(),
            },
            toggleClearRows: !this.state.toggleClearRows,
          });
        }, 1500);
        this.getUsers();
      },
      (err) => {
        console.log(err);
      }
    );
  };
  reloadUsers = () => {
    this.setState({
      show: false,
      showDel: false,
      initialValues: null,
      mailPhoneValid: false,
    });
    this.getUsers();
  };
  changesIn = (field, id) => {
    if (id === 0) {
      delete changesIn[field];
    } else {
      changesIn[field] = 1;
    }
  };
  dropdownValues = null;
  setSelectedDropDownValues = (params) => {
    this.dropdownValues = params;
  };

  emailToast = () => {
    let { LBLINVALIDEMAIL } = this.props.labels.labels;
    this.setState({
      showToast: {
        show: true,
        alertClass: "warning",
        status: "warning",
        message: LBLINVALIDEMAIL,
        changeInToast: new Date(),
      },
      showEmailTab: true
    })
    return (
      <ToastMessage
        show={this.state.showToast["show"]}
        helpers={this.state.showToast}
      ></ToastMessage>
    )
  }
  addUser = (values) => {
    console.log(values, "adduserValues")
    this.setState({
      edituseremail: values.email,
      });
    if (this.state.selectedRoleIds.indexOf("1") > -1 && !values.email) {
      console.log("Email Empty");

      let { LBLADMINEMAIL } = this.props.labels.labels;

      this.setState(
        {
          emailalerttext: LBLADMINEMAIL,
        },
        function () {
          console.log("beforeclosemodal");
          this.openModal1();
          return true;
        }
      );
      return true;
    }
    else {
      this.saveuser(values);
    }
    console.log("User/RM");
  };

  saveuser = (values) => {
    this.setState({ searchValue: "" })
    if (values.email && email(values.email)) {
      this.emailToast();
      setTimeout(() => {
        this.setState({
          showToast: {
            show: false,
            alertClass: "",
            status: "",
            message: "",
            changeInToast: new Date(),
          },
          showEmailTab: false
        })
      }, 1500);
      return true;
    }
    values = { ...values, ...this.dropdownValues };
    let post_groups = [];
    this.state.groups_by_type.map((item) => {
      if (values[item.group_type_id]) {
        post_groups.push({
          group_id: parseInt(values[item.group_type_id]),
          group_type_id: item.group_type_id,
        });
      }
      return null;
    });
    values["role_ids"] = [];
    let status = 0;
    if (values.status === true || values.status === undefined) {
      status = 1;
    } else {
      status = 0;
    }
    let password = values.password && !values.email ? values.password : null;

    let hash = CryptoJS.SHA512(password);
    hash = Base64.stringify(hash);

    if (this.state.initialValues === null) {
      console.log(this.state.roles, "this.state.roles")
      values["role_ids"] = [];
      this.state.roles.map((item, index) => {
        if ($("#checkbox-4")[0].checked) {
          values["checkbox-4"] = true;
        }
        if (values["checkbox-" + item.role_id]) {
          values["role_ids"].push(item.role_id);
        }
        return null;
      });
      let data = {
        username: values.username ? values.username.trim() : null,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        password: hash,
        reference: btoa(values.password),
        email: this.state.disableEmailField ? null : values.email,
        role: values.role_ids.sort(),
        secondary_email: values.s_email,
        group: values.group,
        groups: post_groups,
        status: status,
        email_verified: values.email_verified,
        sec_email_verified: values.sec_email_verified,
        invite_later: values.invite_later,
        phone_number: values.phone_number,
        force_password_change: values.force_password_change,
        user_with_nomail: values.user_with_nomail,
        user_language: values.language ? values.language : null,
        check_params: {
          "request-method": "addUsers",
        },
      };
      if (data.role.length === 0) {
        this.setState({
          roleValid: true,
        });
        return false;
      } else {
        this.setState({
          roleValid: false,
        });
      }
      if (this.state.login_with_phone_number === 0) {
        if (values.user_with_nomail === 0 && !values.email) {
          this.setState({
            mailPhoneValid: true,
          });
          return false;
        } else {
          this.setState({
            mailPhoneValid: false,
          });
        }
      } else {
        if (!values.user_with_nomail && !values.email && !values.phone_number) {
          this.setState({
            mailPhoneValid: true,
          });
          return false;
        } else {
          this.setState({
            mailPhoneValid: false,
          });
        }
      }
      this.dropdownValues = null;
      UsersService.postUsers(data)

        .then((results) => {
          if (results) {
            let message = this.props.labels.labels.LBLCRTDSCCESS;
            if (results.data.Message == "NoLicense") {
              message = this.props.labels.labels.LBLMAXNUMBERREACHED;
            }
            this.setState({
              show: false,
              initialValues: null,
              enableEditUsername: false,
              showToast: {
                show: true,
                alertClass: "success",
                status: "Success",
                message: message,
                changeInToast: new Date(),
              },
              ssi_errors: null,
              disableEmailField: false,
              selectedRoleIds: [],
              searchValue: ""
            }, () => {
              this.resetFilterValues()
            });
            $(".modal-backdrop").css({ position: "relative" });

            setTimeout(() => {
              this.setState({
                showToast: {
                  show: false,
                  alertClass: "",
                  status: "",
                  message: "",
                  changeInToast: new Date()
                },
              });
            }, 1500);

            this.getUsers();

          }
        })
        .catch((err) => {
          if (err)
            if (err.response.data && err.response.data) {
              this.setState({
                ssi_errors: err.response.data,
              });
            }
        });
    } else {
      let roles = values.roles ? values.roles.split("~") : [];
      values["current_roles"] = [];
      roles.map((item, index) => {
        let role_id = values["checkbox-" + item.split("-")[0].toString()];
        if (
          (role_id === undefined || role_id) &&
          values["role_ids"].indexOf(item.split("-")[0].toString()) === -1
        ) {
          values["role_ids"].push(item.split("-")[0]);
        }
        if (
          values["current_roles"].indexOf(item.split("-")[0].toString()) === -1
        ) {
          values["current_roles"].push(item.split("-")[0].toString());
        }
        return null;
      });
      this.state.roles.map((item, index) => {
        let new_role_id = item.role_id.toString().trim();
        new_role_id = values["role_ids"].indexOf(new_role_id);
        if (values["checkbox-" + item.role_id] && new_role_id === -1) {
          values["role_ids"].push(item.role_id.toString().trim());
        }
        return null;
      });
      let editData = {
        pType: 2,
        username: this.state.enableEditUsername ? values.username.trim() : null,
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        email: values.email,
        role: values.role_ids.sort(),
        status: status,
        userid: this.state.initialValues.userid,
        current_roles: values.current_roles,
        secondary_email: values.s_email,
        group: values.group,
        groups: post_groups,
        prevGroup: values.prevGroup,
        email_verified: values.email_verified,
        sec_email_verified: values.sec_email_verified,
        sec_email_to_primary: values.sec_email_to_primary,
        phone_number: values.phone_number,
        user_language: values.language ? values.language : null,
        has_access_priv: values.role_ids.find(
          (item) => item === "1" || item === "3"
        )
          ? true
          : false,

        check_params: {
          "request-method": "editUsers",
        },
        changes_in: changesIn,
      };
      if (values.password && values.password.length >= 8) {
        let hash = CryptoJS.SHA512(values.password);
        hash = Base64.stringify(hash);
        editData.password = hash;
        editData.reference = btoa(values.password);
        editData.force_password_change = values.force_password_change;
        editData.notify_password = values.notify_password;
      }
      if (editData.sec_email_to_primary === true) {
        editData.email = values.s_email;
        editData.secondary_email = values.email;
      }
      if (editData.role.length === 0) {
        this.setState({
          roleValid: true,
        });
        return false;
      } else {
        this.setState({
          roleValid: false,
        });
      }
      if (this.state.login_with_phone_number === 0) {
        if (values.user_with_nomail === 0 && !values.email) {
          if (values.role_ids.indexOf("1") >= 0) {
            this.setState({
              mailPhoneValid: true,
            });
            return false;
          }
        } else {
          this.setState({
            mailPhoneValid: false,
          });
        }
      } else {
        if (!values.user_with_nomail && !values.email && !values.phone_number) {
          this.setState({
            mailPhoneValid: true,
          });
          return false;
        } else {
          this.setState({
            mailPhoneValid: false,
          });
        }
      }

      let { initialValues } = this.state;
      let userName = initialValues.username;
      UsersService.editUsers(editData)
        .then((result) => {
          this.setState({ rowSelectionModel: [], })
          if (
            result.data[0] &&
            result.data[0][0] &&
            result.data[0][0].isexist
          ) {
            let lbl_team = this.props.labels.labels.LBLTEAMS;
            let arr_lbl_message,
              lbl_message = this.props.labels.labels.LBLWARNSCOMUSRDEL; //LBLWARNSCOMUSRSTATUS;
            arr_lbl_message = lbl_message.split("::");
            lbl_message =
              arr_lbl_message.length > 1 ? arr_lbl_message[0] : lbl_message;
            lbl_team = lbl_team ? lbl_team : "Associated Teams";
            let teams_all = "",
              teams = result.data[1];
            if (teams && teams.length > 0) {
              let teamsArr = [];
              teams.map((item) => {
                if (teamsArr.findIndex((n) => n === item.team_name) < 0) {
                  teams_all =
                    teams_all +
                    "<p class='cls_del_info'>" +
                    item.team_name +
                    (item.is_admin === 0
                      ? ""
                      : " (<span class='cls_del_admin'>" +
                      (item.is_admin === 1
                        ? "Admin"
                        : item.is_admin === 2
                          ? "Additional Admin"
                          : "") +
                      "</span>)") +
                    "</p>";
                  teamsArr.push(item.team_name);
                }
                return null;
              });
              lbl_message =
                lbl_message + "<p>" + arr_lbl_message.length > 1
                  ? arr_lbl_message[0]
                  : lbl_message;
              teams_all = "<p></p><p><b>" + lbl_team + "</b></p>" + teams_all;
            }

            let message = lbl_message + teams_all;
            this.setState({
              selectedRoleIds: [],
              enableEditUsername: false,
              showDelInfo: true,
              messageInfo: message,
              userName: userName,

              show: false,
              searchValue: ""
            });
            $("#user_delete").html(message);
          } else {
            if (result) {
              $(".modal-backdrop").css({ position: "relative" });

              this.setState({
                show: false,
                initialValues: null,
                enableEditUsername: false,
                showToast: {
                  show: true,
                  alertClass: "success",
                  status: "Success",
                  message: this.props.labels.labels.LBLUPDTSCCESS,
                  changeInToast: new Date(),
                },
                ssi_errors: null
              });
              changesIn = {};
              setTimeout(() => {
                this.setState({
                  showToast: {
                    show: false,
                    alertClass: "",
                    status: "",
                    message: "",
                    changeInToast: new Date(),
                  },
                });
              }, 1500);
              this.resetFilterValues();
              this.getUsers();
            }
          }
        })
        .catch((err) => {
          if (err) {
            if (err.response && err.response.data) {
              this.setState({
                ssi_errors: err.response.data,
              });
            }
          }
        });
    }
  };
  closeModalCount = () => {
    this.setState({
      showCount: false,
    })

  }

  closeModal = () => {
    $(".modal-backdrop").css({ position: "relative" });

    this.setState({
      show: false,
      initialValues: null,
      showDel: false,
      showDelInfo: false,
      ssi_errors: null,
      enableEditUsername: false,
      disableEmailField: false,
      selectedRoleIds: [],
    });
  };

  closeModalInfo = () => {
    $(".modal-backdrop").css({ position: "relative" });

    this.setState({
      showDel: false,
      showDelInfo: false,
      IMPressUsers: [],
      rowSelectionModel: []
    });
  };

  openModal1 = () => {
    console.log("state");

    if (this.state.selectedRoleIds.indexOf("1") > -1) {
      this.setState({
        showemailtab: true,
      });
    }
  };

  closeModal1 = () => {
    $(".modal-backdrop").css({ position: "relative" });

    this.emailtab();

    this.setState({
      showemailtab: false,
    });
  };

  closeModal2 = () => {
    this.saveuser(this.state.uservalueparams);

    this.setState({
      showverifyalert: false,
      uservalueparams: null,
    });
  };

  openModal = () => {
    let count = this.state.total_user_license - this.state.active_users
    console.log("count",count)
    this.setState({ selectedRoleIds: [] }, () => {
      this.setState({ show: count > 0 ? true : false, mailPhoneValid: false, showCount: count <= 0 ? true : false });

    })
  };

  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  exportUsers = () => {
    var filename;
    var stockData = [];

    let objUserFields = {
      first_name: {
        name: this.getLabel("LBLFIRSTNAME"),
        mapfield: this.getLabel("LBLFIRSTNAME"),
        field: "first_name",
        required: 1,
        xvalidate: "string",
        sampledata: "John",
      },
      last_name: {
        name: this.getLabel("LBLLASTNAME"),
        mapfield: this.getLabel("LBLLASTNAME"),
        field: "last_name",
        required: 1,
        xvalidate: "string",
        sampledata: "Ryder",
      },
      user_name: {
        name: this.getLabel("LBLUSERNAME"),
        mapfield: this.getLabel("LBLUSERNAME"),
        field: "user_name",
        required: 1,
        xvalidate: "string",
        sampledata: "JohnRyder",
      },
      email: {
        name: this.getLabel("LBLEMAILID"),
        mapfield: this.getLabel("LBLEMAILID"),
        field: "email",
        required: 1,
        xvalidate: "email",
        sampledata: "samplemail@xxx.com",
      },
      createdDate: {
        name: this.getLabel("LBLCREATEDDATE"),
        mapfield: this.getLabel("LBLCREATEDDATE"),
        field: "created_date",
        required: 1,
        xvalidate: "created_date",
        sampledata: "xx/xx/xxxx",
      },
      status: {
        name: this.getLabel("LBLSTATUS"),
        mapfield: this.getLabel("LBLSTATUS"),
        field: "active",
        type: "active",
        required: 1,
        xvalidate: "number",
        sampledata: 1,
      },
      usertype: {
        name: this.getLabel("LBLUSERTYPE"),
        mapfield: this.getLabel("LBLUSERTYPE"),
        field: "usertype",
        type: "usertype",
        required: 1,
        sampledata: this.getLabel("LBLUSER"),
      },
      role: {
        name: this.getLabel("LBLROLE"),
        mapfield: this.getLabel("LBLROLE"),
        field: "role",
        type: "role",
        required: 1,
        xvalidate: "string",
        validate: [],
        sampledata: this.getLabel("LBLUSER"),
      },
    };
    let groups = this.state.groups_by_type;
    let groupNames = {};
    for (let i = 0; i < groups.length; i++) {
      if (typeof groups[i].group_info == "string") {
        groups[i].group_info = JSON.parse(groups[i].group_info);
      }
      objUserFields[groups[i].group_type_id] = {
        name: this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        ),
        mapfield: this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        ),
        validate: [],
        field: groups[i].group_type_id,
        type: "groups",
      };

      let groups_by_type = groups[i].groups_by_type;

      if (typeof groups_by_type === "string") {
        groups_by_type = JSON.parse(groups_by_type);
      }
      if (groups_by_type) {
        for (let j = 0; j < groups_by_type.length; j++) {
          groupNames["" + groups_by_type[j].group_id] =
            groups_by_type[j].group_name;
        }
      }
    }
    let xfields = objUserFields;
    let filteredUsers = this.filterUsers();
    filteredUsers.sort((a, b) =>
      a.first_name > b.first_name ? 1 : b.first_name > a.first_name ? -1 : 0
    );
    for (let i = 0; i < filteredUsers.length; i++) {
      let objHeader = {};
      for (let x in xfields) {
        if (xfields[x].type === "groups") {
          let usergroups = filteredUsers[i].user_groups.split("~~~");
          let userGroupNames = [];
          for (let j = 0; j < usergroups.length; j++) {
            if (typeof usergroups[j] === "string") {
              usergroups[j] = JSON.parse(usergroups[j]);
            }
            if (usergroups[j].group_type_id === xfields[x].field) {
              userGroupNames.push(groupNames["" + usergroups[j].group_id]);
            }
          }
          objHeader[xfields[x].name] = "" + userGroupNames.join(", ");
        } else if (xfields[x].type === "role") {
          let userroles = (filteredUsers[i].role || "").split("~");
          let userRoleNames = [];
          for (let j = 0; j < userroles.length; j++) {
            userRoleNames.push(
              this.getLabel(String(userroles[j].split("-")[1]))
            );
          }
          let xuserRoleNames = [...new Set(userRoleNames)];
          objHeader[xfields[x].name] = "" + xuserRoleNames.join(", ");
        } else if (xfields[x].type === "active") {
          let xstatus = filteredUsers[i].active;
          objHeader[xfields[x].name] =
            xstatus === 1
              ? this.getLabel("LBLACTIVE")
              : this.getLabel("LBLINACTIVE");
        } else if (xfields[x].type === "usertype") {
          let xusertype = "";
          if (
            (filteredUsers[i].user_with_nomail || 0) === 0 &&
            (filteredUsers[i].email || "") !== "" &&
            (filteredUsers[i].email_verified || 0) === 0 &&
            (filteredUsers[i].email_invite_sent || 0) === 0
          ) {
            xusertype = this.getLabel("LBLNOTINVITEDUSERS");
          } else if (
            (filteredUsers[i].user_with_nomail || 0) === 0 &&
            (filteredUsers[i].email || "") !== "" &&
            (filteredUsers[i].email_invite_sent || 0) > 0 &&
            (filteredUsers[i].email_verified || 0) === 0
          ) {
            xusertype = this.getLabel("LBLPENDINGACCEPTANCE");
          } else if (
            (filteredUsers[i].user_with_nomail || 0) === 0 &&
            (filteredUsers[i].email || "") !== "" &&
            (filteredUsers[i].email_invite_sent || 0) > 0 &&
            (filteredUsers[i].email_verified || 0) === 1
          ) {
            xusertype = this.getLabel("LBLINVITEACCEPTED");
          } else if (
            (filteredUsers[i].user_with_nomail || 0) === 1 ||
            (filteredUsers[i].email || "") === ""
          ) {
            xusertype = this.getLabel("LBLUSERNOTHAVEEMAIL");
          }
          objHeader[xfields[x].name] = "" + xusertype;
        } else {
          objHeader[xfields[x].name] = filteredUsers[i][xfields[x].field] || "";
        }
      }
      stockData.push(objHeader);
    }

    filename = this.props.labels.labels.LBLUSERS;
    this.exportToExcel(stockData, filename);
  };
  openEditModal = (item, e) => {
    this.setInitialValues(item);
    let status = item.active
    this.setState({
      show: true,
      roleValid: false,
      mailPhoneValid: false,
      showTab: "info",
      showWarning: status,

    });
  };
  openDelModal = (item, e) => {
    this.setState({
      showDel: true,
      userId: item.user_id,
      userName: item.username,
    });
  };

  openFilterModal = () => {
    this.setState({
      isShowUserLicenseCnt: false
    })
    if (this.state.width < 720) {
      this.setState({ showFilterMobile: !this.state.showFilterMobile });
    } else {
      console.log('this.state.initialPage',this.state.initialPage, $(".user-list-filter").css("display"))
      if ($(".user-list-filter").css("display") === "none") {
        this.setState({ showFilterSideMenu: true });
        $(".user-list-filter").css({
          display: "block"          
        });
        $(".user-list-table-container").css({ overflow: "hidden" });
      } else {
        this.setState({ showFilterSideMenu: false });
        $(".user-list-filter").css({
          display: "none",
          transition: "opacity 1s ease-out",
        });
      }
    }
  };
  closeFilterModal = () => {
    $(".modal-backdrop").css({ position: "relative" });
    this.setState({
      showFilterMobile: false,
      initialValues: null,
      selectedRoleIds: [],
    });
  };
  setInitialValues = (item) => {
    if (item.active === 0 || !item.active) {
      item.active = false;
    } else {
      item.active = true;
    }
    let obj = {};
    let groups = item.user_groups.split("~~~").reduce((acc, cur, i) => {
      cur = JSON.parse(cur);
      obj = { ...acc, [cur.group_type_id]: cur.group_id };
      return obj;
    }, {});
    this.setState({
      initialValues: {
        user_id: item.user_id,
        first_name: item.first_name,
        last_name: item.last_name,
        language_code: this.state.language_code,
        username: item.user_name,
        roles: item.role,
        email: item.email,
        organization: item.company_id,
        userid: item.user_id,
        status: item.active,
        s_email: item.secondary_email,
        groups: item.user_groups.split("~~~"),
        prevGroup: item.user_groups,
        email_verified: item.email_verified,
        sec_email_verified: item.sec_email_verified,
        sec_email_to_primary: false,
        email_invite_sent: item.email_invite_sent || 0,
        sec_email_invite_sent: item.sec_email_invite_sent || 0,
        phone_number: item.phone_number,
        phone_number_verified: item.phone_number_verified,
        phone_number_invite_sent: item.phone_number_invite_sent || 0,
        force_password_change: item.force_password_change,
        password: item.password,
        feed_user_id: item.feed_user_id,
        user_with_nomail: item.user_with_nomail,
        language: item.language_code,
        ...groups,
      },
    });
  };
  searchUser = (e) => {
    this.setState({
      searchValue: e.target.value,
      paginationModel: {
        page: 0,
        pageSize: 10,
      },
    }, () => {
      this.filterUsers();
      // this.handleSortModelChange(this.state.sortModel);
    });
  };
  roleFilter = (e) => {
    this.setState({
      roleFilter: e.target.value,
      searchValue: "",
    });
  };
  getHeader = () => {
    if (this.state.initialPage === "userlist") {
      return this.props.labels.labels.LBLUSERS;
    }
    let label = "";
    switch (this.state.currentAction) {
      case 0:
        label = this.props.labels.labels.LBLUSERS + " - " + this.props.labels.labels.LBLCOURSEASSIGN; //'Course Assign';
        break;
      case 1:
        label = this.props.labels.labels.LBLUSERS + " - " + this.props.labels.labels.LBLCOURSEUNASSIGN; //'Course Unassign';
        break;
      case 2:
        label = this.props.labels.labels.LBLUSERS + " - " + this.props.labels.labels.LBLCHANNELASSIGN; //'Channel Assign';
        break;
      case 3:
        label = this.props.labels.labels.LBLUSERS + " - " + this.props.labels.labels.LBLCHANNELUNASSIGN; //'Channel Unassign';
        break;
      default:
      // code block
    }
    return label;
  };
  handleClear = () => {
    this.setState({
      rowSelectionModel: [],
      selectedRowsCount: 0,
      selectedRows: []
    });
  }
  handleSelectAll = () => {
    const selectedRowsData = this.state.filteredUsers
    const selectedRowsCount = this.state.filteredUsers.length;
    const userId = this.state.filteredUsers.map((item) => item.user_id);
    // this.setState({ });
    let selectedRowsUpdated = selectedRowsData.map((row, index) => {
      console.log(row,"reInvite")
      return {
        ...row,
        notify_type:
          (row.email_invite_sent == null || row.email_invite_sent === 0) &&
            row.email_verified == null &&
            row.email &&
            row.email !== "" &&
            (row.user_with_nomail == null || row.user_with_nomail === 0)
            ? "invite"
            : null ||
              (row.email &&
                row.email !== "" &&
                row.email_invite_sent !== null &&
                row.email_invite_sent > 0 &&
                row.email_verified == null &&
                (row.user_with_nomail == null || row.user_with_nomail === 0))
              ? "reinvite"
              : "" ||
                (row.email &&
                  row.email !== "" &&
                  row.email_verified === null &&
                  row.email_verify_sent === null &&
                  row.user_with_nomail === 1)
                ? "verify"
                : null ||
                  (row.email &&
                    row.email !== "" &&
                    row.email_verified === null &&
                    row.email_verify_sent > 0 &&
                    row.user_with_nomail === 1)
                  ? "reverify"
                  : null,
      };
    });
    //console.log(selectedRowsUpdated,"selectedRowsUpdated")
    const invite = selectedRowsUpdated.filter(item => item.notify_type === "invite")
    const reInvite = selectedRowsUpdated.filter(item => item.notify_type === "reinvite")
    const verify = selectedRowsUpdated.filter(item => item.notify_type === "verify")
    const reVerify = selectedRowsUpdated.filter(item => item.notify_type === "reverify")
    this.setState({
      selectedRows: selectedRowsUpdated,
      invite: invite.length,
      reInvite: reInvite.length,
      verify: verify.length,
      reVerify: reVerify.length,
      rowSelectionModel: userId, 
      selectedRowsCount: selectedRowsCount
    });
  }
  tableHeader = () => {
    
    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <div className={styles.userTab} style={{ alignItems: "center" }}>
            <Modal
              show={this.state.showFilterMobile}
              size="lg"
              data-easein="expandIn"
              className={
                "animated  bounceInDown " +
                (this.state.showFilterMobile ? "expandIn" : "fadeOutUp")
              }
            >
              <div className={"user-list-filter"} style={{ display: "block" }}>
                <FilterUsers
                  openFilterModal={this.openFilterModal}
                  toFilters={this.state.toFilters}
                  setSingleDropDown={() =>
                    this.setState({
                      setSingleDropDown: !this.state.setSingleDropDown,
                    })
                  }
                  showSingleDropDown={this.state.setSingleDropDown}
                  handleStatusFilter={(id) => this.handleStatusFilter(id)}
                  handleOpenDropDown={(index) => this.handleOpenDropDown(index)}
                  openDropDown={this.state.openDropDown}
                  singleStatusChecked={this.state.singleStatusChecked}
                  labels={this.props.labels}
                  handleSubmit={this.applyFilters}
                  resetValues={this.resetFilterValues}
                  selectedMultiArrIds={this.state.selectedMultiArrIds}
                  selectedMultiArrNames={this.state.selectedMultiArrNames}
                  setSelectedMultiArrIds={(ids) =>
                    this.setState({ selectedMultiArrIds: ids })
                  }
                  setSelectedMultiArrNames={(name) =>
                    this.setState({ selectedMultiArrNames: name })
                  }
                  setCurrentId={(id) => this.setState({ currentId: id })}
                  currentId={this.state.currentId}
                  prevFilterValues={this.state.allFilterValues}
                  singleItemStatus={(status) =>
                    this.setState({ singleItemStatus: status })
                  }
                />
              </div>
            </Modal>
            <div
              style={{
                display: "flex",
              }}
            >
              {!this.state.showFilterSideMenu && (
                <>
                  <div
                    className="clsSelFilters"
                    style={{ display: this.state.singleItemStatus === null ? "none" : this.state.visible }}
                  >
                    <span></span>
                  </div>
                  <button
                    title={this.props.labels.labels.LBLFILTER}
                    data-toggle="modal"
                    data-target="#myModal"
                    type="button"
                    className={styles.basicSelItem}
                    onClick={this.openFilterModal}
                  >
                    <i
                      data-target="#myModal"
                      class="fa fa-filter"
                      aria-hidden="true"
                      style={{ marginRight: "4px" }}
                    ></i>
                    {this.props.labels.labels.LBLFILTER !== undefined ? this.props.labels.labels.LBLFILTER : "Filter"}
                  </button>
                </>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.state.emailfailure === 1 ? (
                <Link
                  variant="primary"
                  to={"/schome/emailfailure"}
                  className={styles.emailfailure}
                >
                  <img
                    width={"25px"}
                    style={{ paddingRight: "5px" }}
                    src="../images/failed delivery-30x30.png"
                    alt=""
                  ></img>
                  {this.props.labels.labels.LBLEMAILFAILURE}
                </Link>
              ) : (
                ""
              )}
              <input
                type="text"
                className="input-field"
                placeholder={this.props.labels.labels.LBLSEARCH}
                value={this.state.searchValue}
                onChange={this.searchUser}
              />
            </div>
          </div>
        </div>
    );
  };

  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (
        row[field] &&
        row[field] !== 1 &&
        row[field] !== 0 &&
        typeof row[field] !== "boolean"
      ) {
        return row[field].toString().toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };

  sendInvitation = (item, xPref, e) => {
    if (disable_send_invite === 0) {
      disable_send_invite++;
      var data = {
        user_id: item.user_id,
        mail_id: item.email,
        first_name: item.first_name,
        last_name: item.last_name,
        mail_pref: xPref,
      };

      HttpInterceptor.post("sendinvitation", data).then((res) => {
        this.setState({
          show: false,
          initialValues: null,
          showToast: {
            show: true,
            alertClass: "success",
            status: "Success",
            message: this.props.labels.labels.LBLEMAILSENTSUCCESS,
            changeInToast: new Date(),
          },
          ssi_errors: null,
        });
        setTimeout(() => {
          this.setState({
            showToast: {
              show: false,
              alertClass: "",
              status: "",
              message: "",
              changeInToast: new Date(),
            },
          });
          this.setState({ searchValue: "" }, () => { this.resetFilterValues() })
        }, 1500);
        this.reloadUsers();
      });

      setTimeout(() => {
        disable_send_invite = 0;
      }, 2000);
    }
  };

  sendVerification = (item, xPref, e) => {
    if (disable_send_invite === 0) {
      disable_send_invite++;
      var data = {
        user_id: item.user_id,
        mail_id: item.email,
        first_name: item.first_name,
        mail_pref: xPref,
      };
      HttpInterceptor.post("sendverification", data).then((res) => {
        this.setState({
          show: false,
          initialValues: null,
          showToast: {
            show: true,
            alertClass: "success",
            status: "Success",
            message: this.props.labels.labels.LBLEMAILSENTSUCCESS,
            changeInToast: new Date(),
          },
          ssi_errors: null,
        });
        setTimeout(() => {
          this.setState({
            showToast: {
              show: false,
              alertClass: "",
              status: "",
              message: "",
              changeInToast: new Date(),
            },
          });
          this.setState({ searchValue: "" }, () => { this.resetFilterValues() })
        }, 1500);
        this.reloadUsers();
      });
      setTimeout(() => {
        disable_send_invite = 0;
      }, 2000);
    }
  };
  closeToast = () => {
    this.setState({ showToast: { show: false } });
  };
  filterUsers = () => {
    const { userlist, searchValue, allFilterValues,resultData } = this.state;
    let allGroups = [],
      allGroupIds = [],
      allFilters = [],
      group_filter = [],
      roleFilters = "",
      statusFilter = "",
      usertypeFilter = "";
    if (Object.keys(allFilterValues).length > 0) {
      allFilters = allFilterValues.multiItems;
      statusFilter = allFilterValues.singleItems.status
        ? allFilterValues.singleItems.status
        : "";

      let objkeys = Object.keys(allFilters);
      for (var m = 0; m < objkeys.length; m++) {
        if (objkeys[m] === "roles" && allFilters["roles"].length > 0) {
          let allRoleIds = [];
          allFilters["roles"].map((item) => {
            if (item.id) {
              allRoleIds.push(item.id);
            }
            return null;
          });
          roleFilters = "," + allRoleIds.join(",") + ",";
        } else if (
          objkeys[m] === "usertype" &&
          allFilters["usertype"].length > 0
        ) {
          let allUserTypeIds = [];
          allFilters["usertype"].map((item) => {
            if (item.id) {
              allUserTypeIds.push(item.id);
            }
            return null;
          });
          usertypeFilter = "," + allUserTypeIds.join(",") + ",";
        } else {
          allGroups =
            allFilters[objkeys[m]] && allFilters[objkeys[m]].length > 0
              ? allGroups.concat(allFilters[objkeys[m]])
              : allGroups.concat({});
          if (allFilters[objkeys[m]] && allFilters[objkeys[m]].length > 0) {
            group_filter.push(
              Object.values(allFilters[objkeys[m]]).map((item) => item.id)
            );
          }
        }
      }
      allGroups.map((item) => {
        if (item.id) {
          allGroupIds.push(item.id);
        }
        return null;
      });
    }

    let xallFilters = JSON.parse(JSON.stringify(allFilters));

    delete xallFilters["usertype"];
    delete xallFilters["roles"];
    let filteredUsers = [];
    if (resultData && resultData.length > 0) {
      filteredUsers = resultData.filter((item) => {
        let checkRole = (role) => {
          let matchRole = role.split("~").filter((xr) => {
            if (roleFilters.indexOf("," + xr.split("-")[0]) > -1) {
              return xr;
            }
            return null;
          });
          return matchRole.length > 0 ? "yes" : "no";
        };
        if (
          (item.name &&
            (item.name.includes(searchValue) ||
              item.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
          (item.email &&
            (item.email.includes(searchValue) ||
              item.email.toLowerCase().includes(searchValue.toLowerCase()))) ||
          (item.phone_number &&
            (item.phone_number.includes(searchValue) ||
              item.phone_number
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          (item.user_name &&
            (item.user_name.includes(searchValue) ||
              item.user_name.toLowerCase().includes(searchValue.toLowerCase())))
        ) {
          if (
            (roleFilters === "" ||
              (item.role && checkRole(item.role) === "yes")) &&
            (statusFilter === "" || item.active === parseInt(statusFilter)) &&
            (usertypeFilter === "" ||
              (usertypeFilter.indexOf(",101") > -1 &&
                (item.user_with_nomail || 0) === 0 &&
                (item.email || "") !== "" &&
                (item.email_verified || 0) === 0 &&
                (item.email_invite_sent || 0) === 0) ||
              (usertypeFilter.indexOf(",102") > -1 &&
                (item.user_with_nomail || 0) === 0 &&
                (item.email || "") !== "" &&
                (item.email_invite_sent || 0) > 0 &&
                (item.email_verified || 0) === 0) ||
              (usertypeFilter.indexOf(",103") > -1 &&
                (item.user_with_nomail || 0) === 0 &&
                (item.email || "") !== "" &&
                (item.email_invite_sent || 0) > 0 &&
                (item.email_verified || 0) === 1) ||
              (usertypeFilter.indexOf(",104") > -1 &&
                ((item.email || "") === "")))
          ) {
            let mx = 0,
              blnRetrn = false,
              usergrp_assgn = [],
              usrGroupsNew = [{}, {}, {}, {}, {}, {}, {}],
              usrGroups = item.user_groups.split("~~~");

            for (let v = 0; v < usrGroups.length; v++) {
              if (usrGroups[v] && JSON.parse(usrGroups[v]).group_id != null) {
                mx = JSON.parse(usrGroups[v]).group_type_id.split("_")[1];
                usrGroupsNew[mx - 1] = usrGroups[v];
                usergrp_assgn.push(JSON.parse(usrGroups[v]).group_id);
              }
            }
            let output = [],
              ln = Object.keys(allFilters).length - 2,
              len = usrGroupsNew.length;
            if (usrGroupsNew.length > 0) usrGroupsNew.splice(ln, len - ln);
            if (allGroupIds.length > 0) {
              let cnt = 0,
                count = 0;
              /**** *************/
              blnRetrn = true;
              group_filter.map((items) => {
                output = items.filter(function (obj) {
                  return usergrp_assgn.indexOf(obj) !== -1;
                });
                if (output.length > 0) {
                } else {
                  blnRetrn = false;
                }
                return null;
              });
              if (blnRetrn && cnt >= count) {
                return item;
              }
            } else {
              return item;
            }
          } else {
          }
        }
        return null;
      });
    }
    this.setState({
      pageStatus: { isLoading: false, data: filteredUsers.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: filteredUsers.length },
      filteredUsers: filteredUsers
    });
    return filteredUsers;
  };
  EditUserNameConfirmation = (undoEnable) => {
    if (!undoEnable) {
      this.setState({
        showEditUserNameAlert: !this.state.showEditUserNameAlert,
      });
    } else {
      this.setState({
        showEditUserNameAlert: !this.state.showEditUserNameAlert,
        enableEditUsername: false,
      });
    }
  };
  enableEditUsername = () => {
    this.setState({
      enableEditUsername: true,
      showEditUserNameAlert: !this.state.showEditUserNameAlert,
    });
  };
  disableEmailField = () => {
    this.setState({
      disableEmailField: !this.state.disableEmailField,
    });
  };

  emailField = () => {
    this.setState({
      disableEmailField: !this.state.emailField,
    });
  };

  handleOpenDropDown = (index) => {
    let newArr = [...this.state.openDropDown];
    if (newArr.indexOf(index) === -1) {
      newArr.push(index);
    } else {
      newArr.splice(newArr.indexOf(index), 1);
    }
    this.setState({ openDropDown: newArr });
  };
  handleStatusFilter = (id) => {
    this.setState({
      singleStatusChecked: this.state.singleStatusChecked === id ? null : id,
    });
  };
  resetFilterValues = () => {
    console.log("reset values");
    this.setState({
      singleStatusChecked: null,
      selectedMultiArrIds: [],
      selectedMultiArrNames: [],
      visible: "none",
      openDropDown: [],
      setSingleDropDown: false,
      allFilterValues: [],
      singleItemStatus: null,
      pageStatus: { isLoading: false, data: this.state.resultData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: this.state.resultData.length },
      paginationModel: { // pagination variables
        page: 0,
        pageSize: 10,
      },
      searchValue: "",
      filteredUsers: this.state.resultData,
      userlist: this.state.resultData,
    });
  };
  deleteUsers = () => {
    const { LBLCONBULKACTION, LBLUSERS, LBLDELUSERALERT, LBLDELETEMULTIUSERSPROCEED } = this.props.labels.labels
    let alertContent = {
      title: this.state.rowSelectionModel.length > 1 ? LBLCONBULKACTION !== undefined ? LBLUSERS + " - " + LBLCONBULKACTION : "Confirm bulk action" : LBLUSERS, //this.props.labels.labels.LBLDELETEMULTIUSERS,
      body: this.state.rowSelectionModel.length > 1 ? LBLDELETEMULTIUSERSPROCEED : LBLDELUSERALERT,
      button: [
        this.props.labels.labels["LBLCANCEL"],
        this.props.labels.labels["LBLDELETE"],
      ],
      type: "warning", // warning, primary
      alertFor: "delete",
    };
    this.setState({ showAlert: true, alertContent: alertContent });
  };

  handleAlertAction = (name, index, alertFor) => {
    this.setState({ showAlert: false, showPopUP: false });
    if (index === 0) {
      // Cancel
      this.setState({
        showAlert: false,
        alertContent: {},
        multiNotifyType: "",
        currentNoftiyStatus: -1,
      });
      return true;
    }
    if (alertFor === "delete") {
      if (index === 1) {
        this.deleteMultiUsers();
      }
    } else if (alertFor === "notify") {
      this.sendMultiNotification();
    }
  };
  deleteMultiUsers = () => {
    this.setState({
      toggleClearRows: !this.state.toggleClearRows, searchValue: ""
    }, () => { this.resetFilterValues() });
    const userIds = this.state.selectedRows
      .map((item) => item.user_id)
      .filter((item2) => item2 !== this.state.user_id);
    let editData = {
      pType: 1,
      userid: userIds,
    };
    HttpInterceptor.delete("users", { data: { data: editData } }).then(
      (response) => {
        if (response.data.message) {
          this.setState({
            showToast: {
              show: true,
              alertClass: "success",
              status: "Success",
              message: this.state.rowSelectionModel.length > 1 ? this.props.labels.labels.LBLUSRSDELETED : this.props.labels.labels.LBLUSRDELETED,
              changeInToast: new Date(),
            },
          });
          setTimeout(() => {
            this.setState({
              showToast: {
                show: false,
                alertClass: "",
                status: "",
                message: "",
                changeInToast: new Date(),
              },
            });
          }, 5000);
          this.setState({
            selectedRowsCount: 0,
            selectedRows: [],
            clearSelectedRows: true,
          });
          this.getUsers();
        } else {
          const filtered = this.state.userlist.filter((item) => {
            return response.data.find(
              (item2) => item2.user_id === item.user_id
            );
          });
          console.log("userlist filtered", filtered);

          this.setState({
            enableEditUsername: false,
            showDelInfo: true,
            messageInfo: this.props.labels.labels.LBLWARNSCOMUSRSTATUS2, //'You have selected users who have been assigned as IMPress users. You cannot deactivate/degrade a user who is Assigned as an IMPress User and Associated with an IMPress Team.', //this.props.labels.labels.LBLWARNSCOMUSRDEL,
            userName: this.props.labels.labels.LBLUSERS,
            IMPressUsers: filtered,
            show: false,
          });
          this.setState({
            selectedRowsCount: 0,
            selectedRows: [],
            clearSelectedRows: true,
          });
          this.getUsers();
        }
      }
    );
  };

  handleSendMultiNotification = (e) => {
    const {
      LBLSELECTEDUSERSRECEIVEINVITENOTIFICTION,
      LBLSELECTEDUSERSRECEIVEREINVITENOTICATION,
      LBLSELECTEDUSERSRECEIVEVERIFYNOTICATION,
      LBLSELECTEDUSERSRECEIVEREVERIFYNOTICATION,
      LBLINVITEELIGIBLEUSERSNOTIFICATION,
      LBLREINVITENOTIFCATION,
      LBLVERIFYELIGIBLEUSERSNOTIFICATION,
      LBLREVERIFYELIGIBLEUSERSNOTIFICATION,
      LBLSELECTEDUSERSNOTRECEIVEINVITENOTIFICTION,
      LBLSELECTEDUSERSNOTRECEIVEREINVITENOTICATION,
      LBLSELECTEDUSERSNOTRECEIVEVERIFYNOTICATION,
      LBLSELECTEDUSERSNOTRECEIVEREVERIFYNOTICATION
    } = this.props.labels.labels
    let LBLSELECTEDUSERSRECEIVEINVITENOTIFICTIONS = LBLSELECTEDUSERSRECEIVEINVITENOTIFICTION.replace("$$$", this.state.selectedRowsCount);
    let LBLSELECTEDUSERSRECEIVEREINVITENOTICATIONS = LBLSELECTEDUSERSRECEIVEREINVITENOTICATION.replace("$$$", this.state.selectedRowsCount);
    let LBLSELECTEDUSERSRECEIVEVERIFYNOTICATIONS = LBLSELECTEDUSERSRECEIVEVERIFYNOTICATION.replace("$$$", this.state.selectedRowsCount);
    let LBLSELECTEDUSERSRECEIVEREVERIFYNOTICATIONS = LBLSELECTEDUSERSRECEIVEREVERIFYNOTICATION.replace("$$$", this.state.selectedRowsCount);
    let LBLINVITEELIGIBLEUSERSNOTIFICATIONS = LBLINVITEELIGIBLEUSERSNOTIFICATION.replace("1$$$", this.state.selectedRowsCount).replace("2$$$", this.state.invite).replace("3$$$", this.state.selectedRowsCount);
    let LBLREINVITENOTIFCATIONS = LBLREINVITENOTIFCATION.replace("1$$$", this.state.selectedRowsCount).replace("2$$$", this.state.reInvite).replace("3$$$", this.state.selectedRowsCount);
    let LBLVERIFYELIGIBLEUSERSNOTIFICATIONS = LBLVERIFYELIGIBLEUSERSNOTIFICATION.replace("1$$$", this.state.selectedRowsCount).replace("2$$$", this.state.verify).replace("3$$$", this.state.selectedRowsCount);
    let LBLREVERIFYELIGIBLEUSERSNOTIFICATIONS = LBLREVERIFYELIGIBLEUSERSNOTIFICATION.replace("1$$$", this.state.selectedRowsCount).replace("2$$$", this.state.reVerify).replace("3$$$", this.state.selectedRowsCount);
    let LBLSELECTEDUSERSNOTRECEIVEINVITENOTIFICTIONS = LBLSELECTEDUSERSNOTRECEIVEINVITENOTIFICTION.replace("$$$", this.state.selectedRowsCount);
    let LBLSELECTEDUSERSNOTRECEIVEREINVITENOTICATIONS = LBLSELECTEDUSERSNOTRECEIVEREINVITENOTICATION.replace("$$$", this.state.selectedRowsCount);
    let LBLSELECTEDUSERSNOTRECEIVEVERIFYNOTICATIONS = LBLSELECTEDUSERSNOTRECEIVEVERIFYNOTICATION.replace("$$$", this.state.selectedRowsCount);
    let LBLSELECTEDUSERSNOTRECEIVEREVERIFYNOTICATIONS = LBLSELECTEDUSERSNOTRECEIVEREVERIFYNOTICATION.replace("$$$", this.state.selectedRowsCount);
    this.setState({ currentNoftiyStatus: e.target.value });
    const option = e.target.value;
    let description = "";
    let modalTitle = "";
    if (option === 0) {
      modalTitle = this.props.labels.labels.LBLSENDINVITE;
      description = this.state.invite === this.state.selectedRowsCount ? LBLSELECTEDUSERSRECEIVEINVITENOTIFICTIONS : this.state.invite > 0 ? LBLINVITEELIGIBLEUSERSNOTIFICATIONS : LBLSELECTEDUSERSNOTRECEIVEINVITENOTIFICTIONS;
    } else if (option === 1) {
      modalTitle = this.props.labels.labels.LBLSENDREINVITE;
      description = this.state.selectedRowsCount === this.state.reInvite ? LBLSELECTEDUSERSRECEIVEREINVITENOTICATIONS : this.state.reInvite > 0 ? LBLREINVITENOTIFCATIONS : LBLSELECTEDUSERSNOTRECEIVEREINVITENOTICATIONS;
    } else if (option === 2) {
      modalTitle = this.props.labels.labels.LBLSENDVERIFY;
      description = this.state.selectedRowsCount === this.state.verify ? LBLSELECTEDUSERSRECEIVEVERIFYNOTICATIONS : this.state.verify > 0 ? LBLVERIFYELIGIBLEUSERSNOTIFICATIONS : LBLSELECTEDUSERSNOTRECEIVEVERIFYNOTICATIONS;
    } else if (option === 3) {
      modalTitle = this.props.labels.labels.LBLSENDREVERIFY;
      description = this.state.selectedRowsCount === this.state.reVerify ? LBLSELECTEDUSERSRECEIVEREVERIFYNOTICATIONS : this.state.reVerify > 0 ? LBLREVERIFYELIGIBLEUSERSNOTIFICATIONS : LBLSELECTEDUSERSNOTRECEIVEREVERIFYNOTICATIONS;
    }
    this.checkConditions(e);
    let alertContent = {
      modalTitle: modalTitle,
      description: description,
      button: [
        this.props.labels.labels["LBLCANCEL"],
        this.props.labels.labels["LBLCONFIRM"] !== undefined ? this.props.labels.labels["LBLCONFIRM"] : "Confrim",
      ],
      type: "primary", // warning, primary
      alertFor: "notify",
    };
    this.setState({
      showPopUP: true,
      alertContent: alertContent,
      multiNotifyType: option,
    });
  };

  checkConditions(event) {
    // Check conditions and update state
    console.log(event, "aibnslk")
    if (event.target.value === 0 && this.state.invite === 0) {
      this.setState({ showButton: false });
    } else if (event.target.value === 1 && this.state.reInvite === 0) {
      this.setState({ showButton: false });
    } else if (event.target.value === 2 && this.state.verify === 0) {
      this.setState({ showButton: false });
    } else if (event.target.value === 3 && this.state.reVerify === 0) {
      this.setState({ showButton: false });
    } else {
      this.setState({ showButton: true });
    }
  }
  sendMultiNotification = () => {
    console.log(this.state.selectedRows,this.state.currentNoftiyStatus,"abcccccc")
    //let filteredRow = this.state.selectedRows.filter(row => row.notify_type === this.state.multiNotifyType)
    let xPref = "primary";
    // if (filteredRow.length) {
    let multi_data = "";
    if (this.state.currentNoftiyStatus === 0) {
      const inviteRows = this.state.selectedRows.filter(item => item.notify_type === "invite")
      multi_data = inviteRows.map((elem) => ({
        user_id: elem.user_id,
        mail_id: elem.email,
        first_name: elem.first_name,
        last_name: elem.last_name,
        mail_pref: xPref,
      }));
    } else if (this.state.currentNoftiyStatus === 1) {
      const reInviteRows = this.state.selectedRows.filter(item => item.notify_type === "reinvite")
      console.log(this.state.selectedRows,"reInviteRows")
      multi_data = reInviteRows.map((elem) => ({
        user_id: elem.user_id,
        mail_id: elem.email,
        first_name: elem.first_name,
        last_name: elem.last_name,
        mail_pref: xPref,
      }));
    } else if (this.state.currentNoftiyStatus === 2) {
      const verifyRows = this.state.selectedRows.filter(item => item.notify_type === "verify")
      multi_data = verifyRows.map((elem) => ({
        user_id: elem.user_id,
        mail_id: elem.email,
        first_name: elem.first_name,
        last_name: elem.last_name,
        mail_pref: xPref,
      }));
    } else if (this.state.currentNoftiyStatus === 3) {
      const reVerifyRows = this.state.selectedRows.filter(item => item.notify_type === "reverify")
      multi_data = reVerifyRows.map((elem) => ({
        user_id: elem.user_id,
        mail_id: elem.email,
        first_name: elem.first_name,
        last_name: elem.last_name,
        mail_pref: xPref,
      }));
    }
    let apiName =
      (this.state.multiNotifyType === 0 || this.state.multiNotifyType === 1)
        ? "users/sendinvitation"
        : "users/sendverification";
    HttpInterceptor.post(apiName, multi_data).then((res) => {
      this.setState({
        show: false,
        initialValues: null,
        showToast: {
          show: true,
          alertClass: "success",
          status: "Success",
          message: this.props.labels.labels.LBLEMAILSENTSUCCESS,
          changeInToast: new Date(),
        },
        ssi_errors: null,
        clearSelectedRows: true,
        currentNoftiyStatus: -1,
        toggleClearRows: !this.state.toggleClearRows,
      });
      setTimeout(() => {
        this.setState({
          showToast: {
            show: false,
            alertClass: "",
            status: "",
            message: "",
            changeInToast: new Date(),
          },
        });
        this.setState({ searchValue: "", selectedRowsCount: 0 }, () => { this.resetFilterValues() })
      }, 1500);
      this.reloadUsers();
    });
  };

  handleMultiCourse = (e) => {
    //console.log(e.target.value,"e.target.value")
    let status =
      typeof e.target.value === "string"
        ? Number(e.target.value)
        : e.target.value;
    this.setState({
      initialPage: "trainingassign",
      assignedStatus: status === 0 ? 1 : 0,
    });
  };

  handleMultiChannel = (e) => {
    let status =
      typeof e.target.value === "string"
        ? Number(e.target.value)
        : e.target.value;
    this.setState({
      initialPage: "channelassign",
      channelStatus: status === 0 ? 1 : 0,
    });
  };
  handleAction = (e) => {
    let initialPage =
      e.target.value === 0 || e.target.value === 1
        ? "trainingassign"
        : "channelassign";
    let aStatus = e.target.value === 0 ? 1 : e.target.value === 1 ? 0 : null;
    let cStatus = e.target.value === 2 ? 1 : e.target.value === 3 ? 0 : null;
    this.setState({
      currentAction: e.target.value,
      initialPage: initialPage,
      assignedStatus: aStatus,
      channelStatus: cStatus,
      paginationModel: { // pagination variables
        page: 0,
        pageSize: 10,
      },
    });
  };

  clearFilterByName = (type, filter) => {
    let response = this.state.toFilters;
    let multiItemsLen = 0,
      singleItemsLen = 0;
    multiItemsLen = response.multi.length;
    singleItemsLen = response.single.length;
    let groupsM = [],
      groupsS = [],
      singleName,
      multiname;

    let itemsM = [],
      itemsS = [];
    for (var m = 0; m < multiItemsLen; m++) {
      multiname = this.state.toFilters.multi[m].itemsinfo.itemid;
      groupsM[multiname] = [];
      itemsM[m] = multiname;
    }
    for (var x = 0; x < singleItemsLen; x++) {
      singleName = this.state.toFilters.single[x].itemsinfo.itemid;
      groupsS[singleName] = "";
      itemsS[x] = singleName;
    }
    let obj = {
      multiItems: groupsM,
      singleItems: groupsS,
    };
    let submitObj =
      Object.keys(this.state.allFilterValues).length !== 0
        ? this.state.allFilterValues
        : obj;

    if (type === "multi") {
      let arrIds = [...this.state.selectedMultiArrIds];
      let arrNames = [...this.state.selectedMultiArrNames];
      let someRes = response.multi.map((mul) => {
        JSON.parse(mul.items).map((itm) => {
          if (itm.id === filter.id) {
            submitObj.multiItems[mul.itemsinfo.itemid] = submitObj.multiItems[
              mul.itemsinfo.itemid
            ].filter((itm2) => itm2.id !== filter.id);
            arrIds = arrIds.filter((item2) => item2 !== filter.id);
            arrNames = arrNames.filter((item3) => item3.id !== filter.id);
          }
        });
      });
      this.setState({
        selectedMultiArrIds: arrIds,
        selectedMultiArrNames: arrNames,
      });
    } else {
      submitObj.singleItems["status"] = null;
      this.setState({ singleItemStatus: null, singleStatusChecked: null });
    }

    if (this.state.width > 720) {
      this.applyFilters(submitObj);
    }
  };
  handlepaginationModalChange = (paginationVal) => {
    console.log(this.state.sortModel,paginationVal,"handlepaginationModalChange")
    this.setState({ paginationModel: paginationVal }, () => {
      // this.filterUsers();
      this.handleSortModelChange(this.state.sortModel);
    });

  }
  handleSelectionModelChange = (newRowSelectionModel) => {
    const selectedRows = newRowSelectionModel.map((id) =>
      this.state.resultData.find((row) => row.user_id === id)
    );
    this.setState({
      rowSelectionModel: newRowSelectionModel,
      selectedRowsCount: newRowSelectionModel.length,
      selectedRows: selectedRows
    }, () => { this.handleRowChange() });
  };

  handleRowChange = () => {
    console.log(this.state.selectedRows,"selectedRows")
    if (this.state.selectedRows.length) {
      if (this.state.selectedRowsCount === 1) {
        this.state.selectedRows.map((item) => {
          this.setState({
            disableMultiDelete:
              item.user_id === this.state.user_id ? true : false,
          });
          return null;
        });
      } else {
        this.setState({ disableMultiDelete: false });
      }
      let selectedRowsUpdated = this.state.selectedRows.map((row, index) => {
        console.log(row,"reInvite")
        return {
          ...row,
          notify_type:
            (row.email_invite_sent == null || row.email_invite_sent === 0) &&
              row.email_verified == null &&
              row.email &&
              row.email !== "" &&
              (row.user_with_nomail == null || row.user_with_nomail === 0)
              ? "invite"
              : null ||
                (row.email &&
                  row.email !== "" &&
                  row.email_invite_sent !== null &&
                  row.email_invite_sent > 0 &&
                  row.email_verified == null &&
                  (row.user_with_nomail == null || row.user_with_nomail === 0))
                ? "reinvite"
                : "" ||
                  (row.email &&
                    row.email !== "" &&
                    row.email_verified === null &&
                    row.email_verify_sent === null &&
                    row.user_with_nomail === 1)
                  ? "verify"
                  : null ||
                    (row.email &&
                      row.email !== "" &&
                      row.email_verified === null &&
                      row.email_verify_sent > 0 &&
                      row.user_with_nomail === 1)
                    ? "reverify"
                    : null,
        };
      });
      //console.log(selectedRowsUpdated,"selectedRowsUpdated")
      const invite = selectedRowsUpdated.filter(item => item.notify_type === "invite")
      const reInvite = selectedRowsUpdated.filter(item => item.notify_type === "reinvite")
      const verify = selectedRowsUpdated.filter(item => item.notify_type === "verify")
      const reVerify = selectedRowsUpdated.filter(item => item.notify_type === "reverify")
      this.setState({
        selectedRows: selectedRowsUpdated,
        selectedRowsCount: this.state.selectedRowsCount,
        // userIdSelected:user_id,
        invite: invite.length,
        reInvite: reInvite.length,
        verify: verify.length,
        reVerify: reVerify.length
      });
    } else {
      this.setState({
        selectedRows: this.state.selectedRows,
        selectedRowsCount: this.state.selectedRowsCount,
        // userIdSelected:user_id,
        invite: [],
        reInvite: [],
        verify: [],
        reVerify: []
      });
    }
  }

  handleSortModelChange = (sortModel) => {
    console.log("sortModelstart",(this.state.singleItemStatus !== null || this.state.selectedMultiArrNames.length > 0 || this.state.searchValue !== ""))
    // if (sortModel?.length > 0) {
      this.setState({sortModel:sortModel});
      const field = sortModel[0]?.field ;
      let direction = sortModel[0]?.sort ;
      let sortedData = [...this.state.filteredUsers];
      if (direction === undefined) {
        sortedData = ((this.state.singleItemStatus !== null || this.state.selectedMultiArrNames.length > 0 || this.state.searchValue !== ""))
          ? [...this.state.filteredUsers]
          : [...this.state.resultData];
      }
       console.log("sortedData",sortedData)
       
       sortedData.sort((a, b) => {
        const valueA = a[field] === null ? "" : a[field]; // Convert null to empty string
        const valueB = b[field] === null ? "" : b[field]; // Convert null to empty string
        if (direction === 'asc') {
            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB);
            } else {
                return valueA - valueB;
            }
        } else {
            if (typeof valueB === 'string') {
                return valueB.localeCompare(valueA);
            } else {
                return valueB - valueA;
            }
        }
    });
    
    this.setState({ pageStatus : { isLoading: false, data: sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)), total: sortedData?.length },userlist:(this.state.singleItemStatus !== null || this.state.selectedMultiArrNames.length > 0 || this.state.searchValue !== "") ? sortedData  : this.state.resultData });
  };

  render() {
    // console.log(this.state.selectedRows,"reInviteRows")
    const {
      LBLVERIFY,
      LBLIMPORT,
      LBLEXPORT,
      LBLUSERS,
      LBLADDNEWUSER,
      LBLNAME,
      LBLSTATUS,
      LBLACTION,
      LBLEDIT,
      LBLDELETE,
      LBLACTIVE,
      LBLINACTIVE,
      LBLVERIFIED,
      LBLREJECTED,
      LBLPENDING,
      LBLCANCEL,
      LBLEDITUSER,
      LBLDELUSERALERT,
      LBLROWSPERPAGE,
      LBLREINVITE,
      LBLINVITE,
      LBLEMAILVERIFICATION,
      LBLUSERNAME,
      LBLEMAILID,
      LBLOK,
      LBLREVERIFY,
      LBLCONFIRMEDIT,
      LBLEDITUSERNAMECONFIRMATION,
      LBLCREATEDDATE,
      LBLMAXNUMBERREACHED,
    } = this.props.labels.labels;
    
    let dropObj = [
      {
        data: [
          { id: 1, value: -1, lable: this.props.labels.labels.LBLACTIONS !== undefined ? this.props.labels.labels.LBLACTIONS : "Actions" },
          { id: 2, value: 0, lable: this.props.labels.labels.LBLCOURSEASSIGN !== undefined ? this.props.labels.labels.LBLCOURSEASSIGN : "Assign Course" },
          { id: 3, value: 1, lable: this.props.labels.labels.LBLCOURSEUNASSIGN !== undefined ? this.props.labels.labels.LBLCOURSEUNASSIGN : "Unassign Course" },
          { id: 4, value: 2, lable: this.props.labels.labels.LBLCHANNELASSIGN !== undefined ? this.props.labels.labels.LBLCHANNELASSIGN : "Assign Channel" },
          { id: 5, value: 3, lable: this.props.labels.labels.LBLCHANNELUNASSIGN !== undefined ? this.props.labels.labels.LBLCHANNELUNASSIGN : "Unassign Channel" }
        ],
        changeHandler: this.handleAction,
        selectedValue: this.state.currentAction
      },
      {
        data: [
          { id: 1, value: -1, lable: this.props.labels.labels.LBLSENDNOTIFICATION !== undefined ? this.props.labels.labels.LBLSENDNOTIFICATION : "Send Notifications", diasabled: this.state.selectedRows },
          { id: "invite", value: 0, lable: LBLINVITE !== undefined ? LBLINVITE : "Invite", diasabled: this.state.selectedRows },
          { id: "reinvite", value: 1, lable: LBLREINVITE !== undefined ? LBLREINVITE : "Reinvite", diasabled: this.state.selectedRows },
          { id: "verify", value: 2, lable: LBLVERIFY !== undefined ? LBLVERIFY : "Verify", diasabled: this.state.selectedRows },
          { id: "reverify", value: 3, lable: LBLREVERIFY !== undefined ? LBLREVERIFY : "Reverify", diasabled: this.state.selectedRows }
        ],
        changeHandler: this.handleSendMultiNotification,
        selectedValue: this.state.currentNoftiyStatus
      }
    ]
    let columns = [
      {
        headerName: LBLNAME,
        field: "name",
        width: "150",
        renderCell: (row) => (
          <div title={row.row.name} className="grid-ellipsis">
            {row.row.name}
          </div>
        ),
      },
      {
        headerName: LBLUSERNAME,
        field: "user_name",
        width: "150",
        renderCell: (row) => {
          return (
            <div
              data-toggle="tooltip"
              className="grid-ellipsis"
              title={row.row.user_name}
            >
              <span className="rle-brdr">{row.row.user_name}</span>
            </div>
          );
        },
      },
      {
        headerName: LBLEMAILID,
        field: "email",
        width: "250",
        renderCell: (row) => {
          return (
            <div
              className="grid-ellipsis"
              data-toggle="tooltip"
              title={row.row.email}
            >
              <i
                style={{ display: row.row.email ? "" : "none" }}
                title={`${LBLEMAILVERIFICATION} : ${row.row.email_verified === 1
                  ? LBLVERIFIED
                  : row.row.email_verified === 0
                    ? LBLREJECTED
                    : LBLPENDING
                  }`}
                class={
                  row.row.email_verified === 1
                    ? "fas fa-check-circle"
                    : row.row.email_verified === 0
                      ? "fas fa-times-circle"
                      : "fas fa-pause-circle"
                }
              ></i>

              <span className="rle-brdr"> {row.row.email}</span>
            </div>
          );
        },
      },

      {
        headerName: LBLCREATEDDATE,
        field: "created_date",
        width: "150",
        sortable: true,
        renderCell: (row) => {
          return (
            <div
              className="grid-ellipsis"
              data-toggle="tooltip"
              title={row.row.created_date !== "" ? moment(row.row.created_date,"YYYY/MM/DD").format(this.props.dateformat): row.row.created_date}
            >
              <span className="rle-brdr"> {row.row.created_date !== "" ? moment(row.row.created_date,"YYYY/MM/DD").format(this.props.dateformat): row.row.created_date}</span>
            </div>
          );
        },
      },

      {
        headerName: LBLSTATUS,
        field: "active",
        width: "100",
        renderCell: (row) => {
          return (
            <div className={row.row.active === true || row.row.active === 1 ? styles.LBLACTIVE : styles.LBLINACTIVE}>
              {row.row.active === true || row.row.active === 1 ? LBLACTIVE : LBLINACTIVE}
            </div>
          );
        },
      },
      {
        headerName: LBLACTION,
        field: "actions",
        minWidth: "300",
        // flex: 1,
        sortable: false,
        renderCell: (row) => {
          return (
            <div
              className={`icoBtn ${this.state.selectedRowsCount ? "disable grid-ellipsis" : "grid-ellipsis"
                }`}
            >
              <i
                className=""
                data-toggle="modal"
                onClick={this.openEditModal.bind(this, row.row)}
                alt={LBLEDIT}
                title={LBLEDIT}
              >
                {LBLEDIT}
              </i>
              <i
                className={this.props.userId === row.row.user_id ? "disabled" : ""}
                data-toggle="modal"
                onClick={this.openDelModal.bind(this, row.row)}
                alt={LBLDELETE}
                title={LBLDELETE}
              >
                {LBLDELETE}
              </i>
              {(row.row.email_invite_sent == null || row.row.email_invite_sent === 0) &&
                row.row.email_verified == null &&
                row.row.email &&
                row.row.email !== "" &&
                (row.row.user_with_nomail == null || row.row.user_with_nomail === 0) ? (
                <i
                  class=""
                  onClick={this.sendInvitation.bind(this, row.row, "primary")}
                  alt={LBLINVITE}
                  title={LBLINVITE}
                >
                  {LBLINVITE}
                </i>
              ) : (
                ""
              )}
              {row.row.email &&
                row.row.email !== "" &&
                row.row.email_invite_sent !== null &&
                row.row.email_invite_sent > 0 &&
                row.row.email_verified == null &&
                (row.row.user_with_nomail == null || row.row.user_with_nomail === 0) ? (
                <i
                  class=""
                  onClick={this.sendInvitation.bind(this, row.row, "primary")}
                  alt={LBLREINVITE}
                  title={LBLREINVITE}
                >
                  {LBLREINVITE}
                </i>
              ) : (
                ""
              )}
              {row.row.email &&
                row.row.email !== "" &&
                row.row.email_verified === null &&
                row.row.email_verify_sent === null &&
                row.row.user_with_nomail === 1 ? (
                <i
                  class=""
                  onClick={this.sendVerification.bind(this, row.row, "primary")}
                  alt={LBLVERIFY}
                  title={LBLVERIFY}
                >
                  {LBLVERIFY}
                </i>
              ) : (
                ""
              )}
              {row.row.email &&
                row.row.email !== "" &&
                row.row.email_verified === null &&
                row.row.email_verify_sent > 0 &&
                row.row.user_with_nomail === 1 ? (
                <i
                  class=""
                  onClick={this.sendVerification.bind(this, row.row, "primary")}
                  alt={LBLREVERIFY}
                  title={LBLREVERIFY}
                >
                  {LBLREVERIFY}
                </i>
              ) : (
                ""
              )
              }
            </div>
          );
        },
      },
    ];
    console.log('this.wrapperRef',this.wrapperRef, this.state.initialPage);
    return (
      <div className="mob-pad user_list">
        <div className="page-header-field">
          <h3 className="page-title" style={{ marginBottom: "0px" }}>{this.getHeader()}</h3>
          {this.state.initialPage === "userlist" ? (
            <div className="btn-align">
              {this.state.userimport === 1 ? (
                <Link
                  variant="primary"
                  to={this.state.userCount >0 ? "/schome/userlist/import" : "/schome/userlist"}
                  onClick={this.openModalImport}
                  disabled={this.state.loading}
                >
                  <button className="button-style primary-btn"
                    style={{ marginRight: "10px" }}
                    disabled={!this.state.showimport}

                  >
                    {LBLIMPORT}
                  </button>
                </Link>
              ) : (
                ""
              )}
              <button
                className="button-style primary-btn"
                data-toggle="modal"
                onClick={this.exportUsers}
                style={{ marginRight: "10px" }}
                disabled={this.state.loading}
              >
                {LBLEXPORT}
              </button>
              <button
                className="button-style primary-btn"
                data-toggle="modal"
                onClick={this.openModal}
                disabled={this.state.loading}
              >
                {LBLADDNEWUSER}
              </button>
            </div>
          ) : null}
        </div>
        
        <hr className="head-line" />

        {this.state.loading ? (
          <div>
            <div className="sc-fznxsB fWHyCt datatable-list templates-table web-skull">
              <header className="sc-fzqNJr kwRiGp rdt_TableHeader"></header>
              <header className="sc-fzoXzr hlidmt">
                <Skeleton height={34} />
              </header>
              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                    <div className="sc-AxiKw cTEQON rdt_TableHeadRow">
                      <Skeleton height={56} />
                    </div>
                  </div>
                </div>
                <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                  <Skeleton height={480} />
                </div>
              </div>
              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <Skeleton height={56} />
              </nav>
            </div>
            <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
              <header className="sc-fzoXzr hlidmt">
                <Skeleton height={34} />
              </header>
              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                    {skel.map((i) => {
                      return (
                        <div
                          id={"row-" + i}
                          className="sc-fzoLsD doBktq rdt_TableRow"
                        >
                          <Skeleton height={130} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <div className="sc-fzpmMD ftIMtk">
                  <Skeleton height={40} />
                </div>
              </nav>
            </div>
          </div>
        ) : (
          <>
          <div>
          <div className={styles.usercoctn}>
            <ul className={styles.usrnavctn}>
              <li className={styles.usernavtxt}>
                <div className={styles.usersub} >
                  <span className={styles.usertxt} >{this.props.labels.labels.LBLTOTALLICENSES} : </span>
                  <span className={`${styles.usercount} ${styles.usercount4}`}>{this.state.total_user_license ? this.state.total_user_license :0}</span>
                </div>
              </li>
              <span className={styles.vertical}></span>
              <li className={styles.usernavtxt}>
                <div className={styles.usersub} >
                  <span className={styles.usertxt} >{this.props.labels.labels.LBLAVAILABLELICENSES} : </span>
                  <span className={`${styles.usercount} ${styles.usercount1}`}>{this.state.total_user_license ? this.state.total_user_license - this.state.active_users : 0}</span>
                </div>
              </li>
              <span className={styles.vertical}></span>
              <li className={styles.usernavtxt} >
                <div className={styles.usersub}>
                  <span className={styles.usertxt}>{this.props.labels.labels.LBLACTIVEUSERS} : </span>
                  <span className={`${styles.usercount} ${styles.usercount2}`}>{this.state.active_users ? this.state.active_users : 0}</span>
                </div>
              </li>
              <span className={styles.vertical}></span>
              <li className={styles.usernavtxt}>
                <div className={styles.usersub}>
                  <span className={styles.usertxt}>{this.props.labels.labels.LBLINACTIVEUSERS} : </span>
                  <span className={`${styles.usercount} ${styles.usercount3}`}>{this.state.inactive_users ? this.state.inactive_users : 0}</span>
                </div>
              </li>

            </ul>

          </div>
        </div>
          {this.state.initialPage === "userlist" ? (
              <div className={styles.userListTableHeader}>
                <ToastMessage
                  show={this.state.showToast["show"]}
                  helpers={this.state.showToast}
                ></ToastMessage>
                <div>{this.tableHeader()}</div>
                </div>
                ): null }
          <div className={this.state.initialPage === "userlist" && styles.userListContainer}>
            {this.state.initialPage === "userlist" ? (
              <div className={styles.userlistDatatableCtn}>                
                <div
                className={"user-list-filter"}
                ref={this.wrapperRef}                
              >
          {this.state.showFilterSideMenu && (
            <FilterUsers
              openFilterModal={this.openFilterModal}
              toFilters={this.state.toFilters}
              setSingleDropDown={() =>
                this.setState({
                  setSingleDropDown: !this.state.setSingleDropDown,
                })
              }
              showSingleDropDown={this.state.setSingleDropDown}
              handleStatusFilter={(id) => this.handleStatusFilter(id)}
              handleOpenDropDown={(index) => this.handleOpenDropDown(index)}
              openDropDown={this.state.openDropDown}
              singleStatusChecked={this.state.singleStatusChecked}
              labels={this.props.labels}
              handleSubmit={this.applyFilters}
              resetValues={this.resetFilterValues}
              selectedMultiArrIds={this.state.selectedMultiArrIds}
              selectedMultiArrNames={this.state.selectedMultiArrNames}
              setSelectedMultiArrIds={(ids) =>
                this.setState({ selectedMultiArrIds: ids })
              }
              setSelectedMultiArrNames={(name) =>
                this.setState({ selectedMultiArrNames: name })
              }
              setCurrentId={(id) => this.setState({ currentId: id })}
              currentId={this.state.currentId}
              prevFilterValues={this.state.allFilterValues}
              singleItemStatus={(status) =>
                this.setState({ singleItemStatus: status })
              }
            />
          )}
        </div>
                {console.log(this.state.pageStatus.data,"DataGridpageStatus")}
                
                  <div className="datagrid-container">
                    {(this.state.singleItemStatus && this.state.singleItemStatus !== "") || this.state.selectedMultiArrNames.length ? (
                        <div className={styles.selectedFilterCnt}>
                          {(this.state.singleItemStatus && this.state.singleItemStatus !== "") &&
                          <div className={styles.selectedFilter}>
                            <span>
                              {this.state.singleItemStatus === "1"
                                ? this.getLabel("LBLACTIVE")
                                : this.getLabel("LBLINACTIVE")}
                            </span>
                            <i
                              className={`fa fa-times ${styles.closeIcon}`}
                              aria-hidden="true"
                              onClick={() => this.clearFilterByName("single", null)}
                            ></i>
                          </div> }
                    
            {this.state.selectedMultiArrNames.length ? (
              <>
                {this.state.selectedMultiArrNames.map((filter, index) => {
                  return (
                    <div className={styles.selectedFilter}>
                      <span>{this.props.labels.labels[filter.name] || filter.name} </span>
                      <i
                        className={`fa fa-times ${styles.closeIcon}`}
                        aria-hidden="true"
                        onClick={() => this.clearFilterByName("multi", filter)}
                      ></i>
                    </div>
                  );
                })}
                <div className={styles.selectedFilter}>
                  <span>{this.props.labels.labels.LBLCLEARALL !== undefined ? this.props.labels.labels.LBLCLEARALL : "Clear All"} </span>
                  <i
                    className={`fa fa-times ${styles.closeIcon}`}
                    aria-hidden="true"
                    onClick={() => this.resetFilterValues()}
                  ></i>
                </div>
              </>
            ) : null}
          </div>
                    ) : null}
                    {this.state.selectedRowsCount > 0 && this.state.pageStatus.data.length > 0 &&
                      <UserManagementHeader
                        selectedRowsCount={this.state.selectedRowsCount}
                        labels={this.props.labels.labels}
                        usersSelectedLabel={this.props.labels.labels.LBLUSERSSELECTED !== undefined ? this.props.labels.labels.LBLUSERSSELECTED : "Users selected"}
                        handleClear={this.handleClear}
                        selectedRows={null}
                        dropObj={dropObj}
                        showdropdown={true}
                        selectAll={this.props.labels.labels.LBLSELECTALLUSERS.replace("$$$", "")}
                        currentPage="users"
                        handleDelete={() => this.deleteUsers()}
                        deleteDisable={this.state.disableMultiDelete}
                        handleRowChange={this.handleSelectAll}
                        visibleRowsSelected={this.state.visibleRowsSelected}
                        visiblerowsselectedLabel={this.props.labels.labels.LBLALLUSERSSELECTED}
                      />
                    }
                {this.state.pageStatus.data.length > 0 ?
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                      },
                    }}
                    onSortModelChange={this.handleSortModelChange}
                    style={{ border: 'none' }}
                    className="cust-data-grid"
                    autoHeight                    
                    checkboxSelection
                    disableRowSelectionOnClick
                    getRowId={(row) => row.user_id} // key value of the row item
                    columns={columns}
                    rows={this.state.pageStatus.data}
                    rowCount={this.state.pageStatus.total}
                    loading={this.state.pageStatus.isLoading}
                    pageSizeOptions={[10, 15, 20, 25, 30]}
                    pagination
                    paginationMode="server"
                    paginationModel={this.state.paginationModel}
                    onPaginationModelChange={this.handlepaginationModalChange}
                    onRowSelectionModelChange={this.handleSelectionModelChange}
                    rowSelectionModel={this.state.rowSelectionModel}
                    keepNonExistentRowsSelected
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: LBLROWSPERPAGE + ":"
                      }
                    }}
                    sx={{
                      '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                      },

                      '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                        outline: 'none',
                      },
                    }}
                  />                  
                  :
                  <div style={{width: 'inherit', marginTop: "5%" }}><EmptyState image={emptyImage} text2={this.props.labels.labels.LBLUSERSEMPTYTXT} /> </div>
                }
                </div>
              </div>
            ) : null}
            {this.state.initialPage === "trainingassign" ? (
              <TrainingMultiAssign
                initialValues={this.state.initialValues}
                resultData={this.state.resultData}
                labels={this.props.labels}
                selectedRowsCount={this.state.selectedRowsCount}
                selectedRows={this.state.selectedRows}
                initialPage={() =>
                  this.setState({
                    initialPage: "userlist",
                    selectedRowsCount: 0,
                    selectedRows: [],
                    rowSelectionModel: [],
                    currentAction: -1,
                    // userIdSelected: []
                    pageStatus: { isLoading: false, data: this.state.resultData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: this.state.resultData.length },
                    paginationModel: { // pagination variables
                      page: 0,
                      pageSize: 10,
                    },
                    searchValue: ""
                  }, () => {
                    this.resetFilterValues()
                  })
                }
                assignedStatus={this.state.assignedStatus}
              />
            ) : null}
            {this.state.initialPage === "channelassign" ? (
              <ChannelMultiAssign
                initialValues={this.state.initialValues}
                resultData={this.state.resultData}
                labels={this.props.labels}
                selectedRowsCount={this.state.selectedRowsCount}
                selectedRows={this.state.selectedRows}
                initialPage={() =>
                  this.setState({
                    initialPage: "userlist",
                    selectedRowsCount: 0,
                    selectedRows: [],
                    rowSelectionModel: [],
                    currentAction: -1,
                    pageStatus: { isLoading: false, data: this.state.userlist.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: this.state.userlist.length },
                    paginationModel: { // pagination variables
                      page: 0,
                      pageSize: 10,
                    },
                    searchValue: ""
                    // userIdSelected: []
                  }, () => {
                    this.resetFilterValues()
                  })
                }
                channelStatus={this.state.channelStatus}
              />
            ) : null}
          </div>
          </>
        )}

{/* {this.state.showCount===true && !this.state.loading? */}
        <Modal
          show={this.state.showCount}
          onHide={this.closeModalCount} 
        >
          <Modal.Body>
            <div className="alert-modal-body general-text">{LBLMAXNUMBERREACHED}</div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none", justifyContent: "center" }}>

            <button
              type="button"
              className="button-style primary-btn"
              onClick={this.closeModalCount}
            >
              {LBLOK}
            </button>
          </Modal.Footer>
        </Modal>
  {/* :""} */}
        <Modal
          show={this.state.show}
          backdrop="static"
          onHide={this.closeModal}
          size="xl"
          className={
            "animated  modal-right-side " +
            (this.state.show ? "fadeInRight" : "fadeOutRight")
          }
          style={{ overflowY: "scroll" }}
        >
          <Modal.Header closeButton className='alert-modal-header'>
            <Modal.Title className="alert-modal-title" id="exampleModalLongTitle">
              {this.state.initialValues === null ? LBLADDNEWUSER : LBLEDITUSER}
            </Modal.Title>
          </Modal.Header>
          <UserManagementForm
            active={this.state.active}
            licenseDetails={this.state.licenseDetails}
            initialValues={this.state.initialValues}
            groups_by_type={this.state.groups_by_type}
            onSubmit={this.addUser}
            sendInvitation={this.sendInvitation}
            roles={this.state.roles}
            languages={this.state.languages}
            group_type={this.state.group_type}
            status={this.state.status}
            helpersLoaded={this.state.helpersLoaded}
            closeModal={this.closeModal}
            ssi_errors={this.state.ssi_errors}
            groupData={this.state.groupData}
            roleValid={this.state.roleValid}
            mailPhoneValid={this.state.mailPhoneValid}
            labels={this.props.labels}
            changesIn={this.changesIn}
            lang_code={this.props.language_code}
            productListData={this.state.resultData}
            login_with_phone_number={this.state.login_with_phone_number}
            RootgenereatePassword={this.RootgenereatePassword}
            EditUserNameConfirmation={this.EditUserNameConfirmation}
            enableEditUsername={this.state.enableEditUsername}
            disableEmailFunc={this.disableEmailField}
            enableEmailField={this.emailField}
            emailDisable={this.state.disableEmailField}
            setSelectedRoleIds={(val) => {
              this.setState({ selectedRoleIds: val })
            }
            }
            editemail={this.state.edituseremail}
            openModal1={this.openModal1}
            setSelectedDropDownValues={this.setSelectedDropDownValues}
            showEmailTab={this.state.showEmailTab}
            {...this.props}
          ></UserManagementForm>
        </Modal>


        <Modal
          show={this.state.showEditUserNameAlert}
          onHide={this.EditUserNameConfirmation}
        >
          <Modal.Header closeButton className='alert-modal-header'>
            <Modal.Title className="alert-modal-title">{LBLCONFIRMEDIT}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert-modal-body">{LBLEDITUSERNAMECONFIRMATION}</div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <button
              type="button"
              className="button-style secondary-btn"
              onClick={() => {
                this.EditUserNameConfirmation(true);
              }}
            >
              {LBLCANCEL}
            </button>
            <button
              type="button"
              className="button-style primary-btn"
              onClick={this.enableEditUsername}
            >
              {LBLEDIT}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showDel}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton className='alert-modal-header'>
            <Modal.Title className="alert-modal-title">{LBLUSERS}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert-modal-body">{LBLDELUSERALERT}</div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <button
              type="button"
              className="button-style secondary-btn"
              onClick={this.closeModal}
            >
              {LBLCANCEL}
            </button>
            <button
              type="button"
              className="button-style danger-btn"
              onClick={this.delUser}
            >
              {LBLDELETE}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showemailtab} onHide={this.closeModal1}>
          <Modal.Header closeButton className='alert-modal-header'>
            <Modal.Title className="alert-modal-title">
              {this.props.labels.labels.LBLEMAIL}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert-modal-body">{this.state.emailalerttext}</div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <button
              type="button"
              className="button-style primary-btn"
              id="btn-ok"
              onClick={this.closeModal1}
            >
              {LBLOK}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showverifyalert} onHide={this.closeModal2}>
          <Modal.Header closeButton className='alert-modal-header'>
            <Modal.Title className="alert-modal-title">
              {this.props.labels.labels.LBLEMAIL}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert-modal-body">{this.state.emailalerttext}</div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <button
              type="button"
              className="button-style primary-btn"
              id="btn-ok"
              onClick={this.closeModal2}
            >
              {LBLOK}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showDelInfo}
          onHide={this.closeModalInfo}
        >
          <Modal.Header closeButton className='alert-modal-header'>
            <Modal.Title className="alert-modal-title">{LBLUSERS}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert-modal-body">
              <span id="user_delete">{this.state.messageInfo}</span>
              {this.state.IMPressUsers.length ? (
                <div className={styles.imUsersList}>
                  <div style={{ marginBottom: "5px" }}>
                    <span >{LBLUSERS}</span>
                  </div>
                  {this.state.IMPressUsers.map((list, index) => {
                    return (
                      <span>
                        {list.name}
                        {index < this.state.IMPressUsers.length - 1 ? "," : ""}
                      </span>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none" }}>
            <button
              type="button"
              className="button-style primary-btn"
              onClick={this.closeModalInfo}
            >
              {LBLOK ? LBLOK : "Ok"}
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.showAlert && (
          <Alert
            title={this.state.alertContent.title}
            body={this.state.alertContent.body}
            button={this.state.alertContent.button}
            type={this.state.alertContent.type}
            alertFor={this.state.alertContent.alertFor}
            action={(name, index, alertFor) =>
              this.handleAlertAction(name, index, alertFor)
            }
            onClose={() =>
              this.setState({
                showAlert: false,
                alertContent: {},
                multiNotifyType: "",
                currentNoftiyStatus: -1,
              })
            }
          />
        )}
        {this.state.showPopUP && (
          <SendNotificationPopUp
            title={this.state.alertContent.modalTitle}
            body={this.state.alertContent.description}
            button={this.state.alertContent.button}
            type={this.state.alertContent.type}
            alertFor={this.state.alertContent.alertFor}
            action={(name, index, alertFor) =>
              this.handleAlertAction(name, index, alertFor)
            }
            onClose={() =>
              this.setState({
                showPopUP: false,
                alertContent: {},
                multiNotifyType: "",
                currentNoftiyStatus: -1,
              })
            }
            currentNoftiyStatus={this.state.currentNoftiyStatus}
            invite={this.state.invite}
            reInvite={this.state.reInvite}
            verify={this.state.verify}
            reVerify={this.state.reVerify}
            showButton={this.state.showButton}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    user_info_reducer: (userReducerData) => {
      return dispatch(userUpdate(userReducerData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
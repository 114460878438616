import React, { Component } from "react";
import TemplateForm from "./templateform";
import ScMailServices from "./templateService";
import { withRouter } from "react-router";
import Skeleton from "react-loading-skeleton";

let templateId = null;

class CreateTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      groups: [],
      status: null,
      loading: false,
      templateInfo: [],
      initialValues: null,
    };
    templateId = this.props.match.params.id;
  }
  componentDidMount() {
    if (templateId !== undefined) {
      ScMailServices.getTemplatesList(templateId).then((templateInfo) => {
        this.setState({
          templateInfo: templateInfo.templates,
          groups: templateInfo.groups,
        });
        let item = templateInfo.templates[0];
        this.setState({
          initialValues: {
            templatetype: item.template_type_id.toString(),
            templatename: item.template_name,
            from: item.from_address,
            subject: item.subject,
            body: item.body,
            status: item.active,
            cc: item.cc,
            replyto: item.reply_to,
          },
        });
      });
    }
    ScMailServices.getTemplates().then((templatesRes) => {
      this.setState({
        templates: templatesRes.templates,
        loading: true,
      });
    });
  }

  changePassword = (values) => {};
  openModal = () => {
    this.setState({ show: true });
  };
  closeModal = () => {
    this.setState({ show: false });
  };
  createTemplate = (values) => {
    if (this.state.initialValues === null) {
      let data = {
        templatename: values.templatename,
        body: values.body,
        templatetype: values.templatetype,
        from: values.from,
        subject: values.subject,
        cc: values.cc,
        replyto: values.replyto,
      };
      ScMailServices.addTemplates(data).then((tempRes) => {
        this.props.history.push("/schome/templates");
      });
    } else {
      let data = {
        templatename: values.templatename,
        body: values.body,
        templatetype: values.templatetype,
        from: values.from,
        subject: values.subject,
        bcc: "test@g.com",
        cc: values.cc,
        replyto: values.replyto,
        group: 1,
        id: parseInt(templateId),
        scheduledTime: "78",
        edit: true,
      };
      console.log(data);

      ScMailServices.updateTemplates(data).then((tempRes) => {
        this.props.history.push("/schome/templates");
      });
    }
  };
  render() {
    const {
      LBLCREATETEMPLATES,
      LBLUPDATE,
      LBLCANCEL,
    } = this.props.labels.labels;
    const { templates, loading, groups } = this.state;
    return (
      <div>
        <section className="mob-pad">
          <div>
            <h3 className="page-title">{LBLCREATETEMPLATES}</h3>
          </div>
          <hr className="head-line" />
          {!this.state.loading ? (
            <div>
              <form className="form">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <Skeleton height={59} />
                    </div>
                    <div className="form-group">
                      <Skeleton height={59} />
                    </div>
                    <div className="form-group">
                      <Skeleton height={59} />
                    </div>
                    <div className="form-group">
                      <Skeleton height={59} />
                    </div>
                    <div className="form-group">
                      <Skeleton height={59} />
                    </div>
                    <div className="form-group">
                      <Skeleton height={59} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <Skeleton height={325} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="">
              {templates.length > 0 ? (
                <TemplateForm
                  initialValues={this.state.initialValues}
                  groups={groups}
                  onSubmit={this.createTemplate}
                  templates={templates}
                  load={loading}
                  labels={this.props.labels}
                ></TemplateForm>
              ) : (
                ""
              )}
            </div>
          )}
          <div className="modal-footer text-center" style={{ display: "none" }}>
            <button type="button" className="btn cancel" id="btnaddCancel">
              {LBLCANCEL}
            </button>
            <input
              type="submit"
              className="btn btn-primary"
              id="submitUser"
              value={LBLUPDATE}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(CreateTemplates);

import React from "react";
import "./steeringcommittee.scss";
import { HttpInterceptor } from "../../../auth/HttpInterceptor"; 
import ResourcePopup from "./resourcespopup"; 
import Skeleton from "react-loading-skeleton";
import "./referencematerials.scss";
import "../../../employee/pages/resources/resources.scss";
const $ = require("jquery");
export default class ReferenceMaterials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultData: [],
      parentFolderres: [],
      initialValues: [],
      resourceData: this.props.resourceData,
      resourceDataCustom: this.props.resourceDataCustom,
      showCustomResource: false,
      resourceFrom:this.props.resourceFrom,
      paths: {}
    };
    this.loadResources = this.loadResources.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.goBack = this.goBack.bind(this); // i think you are missing this
    this.togglePopup = this.togglePopup.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
  }

  handleClick(e) {
    var vParentFolderid = e.currentTarget.dataset.div_parentid;
    var vFileid = e.currentTarget.dataset.div_id;
    HttpInterceptor(
      "resources?fileId=" + vFileid + "&parentId=" + vParentFolderid
    )
      .then((res) => {
        return res;
      })
      .then((data) => {
        this.setState({
          resultData: data.data.files,
          parentFolderres: data.data.parent,
        });
      });
  }

  downloadFiles(e) {
    let headers = new Headers({
      responseType: "blob",
    });
    var vRowKey = e.currentTarget.dataset.div_key;

    var vFileId = this.state.resultData[vRowKey].resource_id;
    var vFileformat = this.state.resultData[vRowKey].format;
    var vFilename = this.state.resultData[vRowKey].file_name;

    HttpInterceptor("sthrees?type=" + 1 + "&fileId=" + vFileId, {
      headers,
      responseType: "arraybuffer",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/" + vFileformat })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", vFilename); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  goBack(e) {
    var vFileid = e.currentTarget.dataset.div_id;  
    if(vFileid==990) 
    return false;
    HttpInterceptor("resourcesparent?fileId=" + vFileid)
      .then((res) => {
        return res;
      })
      .then((data) => {
        this.setState({
          resultData: data.data.files,
          parentFolderres: data.data.parent,
        });
      });
  }

  bclosePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  togglePopup(e) {
    var vFilename = e.currentTarget.dataset.div_filename;
    var vFileid = e.currentTarget.dataset.div_id;
    var vFileformat = e.currentTarget.dataset.div_type;
    var x = vFilename.split(".");
    let format = x[x.length-1];
    if(["jpeg","jpg","png"].indexOf(format)>-1){ 
      return false;
    }
    this.setState({
      showPopup: !this.state.showPopup,
      filename: vFilename,
      fileid: vFileid,
      fileformat: vFileformat,
    });
  }

  componentDidMount() {
    this.loadResources();
  }

  loadResources() {
    let xurl = "resources?fileId=" + this.state.resourceData.folder_id + "&parentId=" + this.state.resourceData.folder_id
    HttpInterceptor(xurl)
      .then((res) => {
        return res;
      })
      .then((data) => {
        if (data.message === undefined) {
          this.setState({
            resultData: data.data.files,
            parentFolderres: data.data.parent,
          });
        }
      });
  }
  handleTabsChange = (target) => {
    let showCustomResource = false, val = target.currentTarget.id;
    if (val == "tabs2") {
      showCustomResource = true;
    }
    this.setState({
      showCustomResource: showCustomResource
    })
  }
  getPath = (res) => {
    return process.env.REACT_APP_RESOURCES_URL + "appasset/images/resource_image/" +
      res.file_name
        .substring(0, res.file_name.length - res.format.length)
        .replace("#", "%23") +
      "png"
  }
  getErrorPath = (res) => {
    return `${"../images/format_image/"}${res.format === "pdf"
      ? "Pdf_icon.png"
      : res.format === "docx"
        ? "Doc_icon.png"
        : res.format === "xls"
          ? "Excel_icon.png"
          : res.format === "xlsx"
            ? "Excel_icon.png"
            : (res.format === "ppt" || res.format === "pptx")
              ? "PPT_icon.png"
              : res.format === "mp4"
                ? "Video_icon.png"
                : res.format === "wmv"
                  ? "Video_icon.png"
                  : res.format === "mov"
                  ? "Video_icon.png"
                    : "Image_icon.png"
      }`
  }
  redirectTo=()=>{
    let resource_path= this.state.resourceDataCustom;
    if(resource_path && resource_path!="" && resource_path.indexOf("http")>-1){
      window.open(resource_path); 
    }
  } 
  render() {
    const { LBLNORESOURCES, LBLUPLOADRESOURCE, LBLDEFAULTRESOURCE } = this.props.labels.labels;
    const { showCustomResource } = this.state;
    var vFolderDet = this.state.resultData.map((res) => {
      if (res.name !== undefined) {
        return (
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 resourses referenceMaterial">
            <div
              className="card"
              data-div_parentid={res.folder_id}
              data-div_id={res.folder_id}
              data-div_filename={res.file_name}
              onClick={this.handleClick}
            >
              <div
                className="card-body folder-dtls fa fa-folder"
                data-title={res.name}
              >
                <img src="../images/steercom/folder_icon.png" />
                <span>{res.name}</span>
              </div>
            </div>
          </div>
        );
      }
    });
    var vFileDet = this.state.resultData.map((res, i) => {
      if (res.name === undefined) {
        return (
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 resourses referenceMaterial">
            <div className="card" data-title={res.file_name}>
              <div
                className={"card-files cursor-pointer"} // + (["jpeg","jpg","png"].indexOf(res.format)>=0?"cls_img_zoom":"")
                data-div_type={res.format}
                data-div_id={res.resource_id}
                data-div_filename={res.file_name}
                onClick={this.togglePopup}
              >
                {/* {console.log("res.filename", res.file_name)} */}
                {
                  !this.state.paths[this.getPath(res)] ?
                    <img
                      src={
                        process.env.REACT_APP_RESOURCES_URL + "appasset/images/resource_image/" +
                        ( ["jpeg","jpg"].indexOf(res.format)>=0?res.file_name :
                          res.file_name
                          .substring(0, res.file_name.length - res.format.length)
                          .replace("#", "%23") +
                        "png")
                      }
                      onError={() => {
                        let paths = this.state.paths
                        paths[this.getPath(res)] = this.getErrorPath(res)
                        this.setState({
                          paths: paths
                        })
                      }}
                      alt={res.format}
                      data-div_filename={res.file_name}
                    /> :
                    <img
                      src={
                        this.getErrorPath(res)
                      }      
                      style={{width:"80%",marginLeft:"calc(100% - 90%)"}}               
                      alt={res.format}
                      data-div_filename={res.file_name}
                    />
                }
                <div class="overlay"></div>
              </div>

              <div className="card-body">
                <img
                  src={`${"../images/format_image/"}${res.format === "pdf"
                    ? "Pdf_icon.png"
                    : res.format === "docx"
                      ? "Doc_icon.png"
                      : res.format === "xls"
                        ? "Excel_icon.png"
                        : res.format === "xlsx"
                          ? "Excel_icon.png"
                          : (res.format === "ppt" || res.format === "pptx")
                            ? "PPT_icon.png"
                            : res.format === "mp4"
                              ? "Video_icon.png"
                              : res.format === "wmv"
                                ? "Video_icon.png"
                                : res.format === "mov"
                                  ? "Video_icon.png"
                                : "Image_icon.png"
                    }`}
                  alt={res.format}
                  style={{ width: "30px", height: "30px" }}
                />
                <a
                  className="sc-rcs-link"
                  href="javascript:void(0)"
                  alt={res.format}
                >
                  {res.file_name}
                </a>
                <ul className="resource-link sc-icn">
                  <li>
                    <img
                      src="../images/steercom/download_icon.png"
                      onClick={this.downloadFiles}
                      data-div_key={i}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      }
    });

    if (this.state.parentFolderres.length > 0) {
      var parentFolderDet = this.state.parentFolderres.map((res) => {
        return (
          <div className="resourses referenceMaterial">
            <div>
              <h5 className="">
                <a
                  href="javascript:void(0)"
                  data-div_parentid={res.folder_id}
                  data-div_id={res.folder_id}
                  onClick={this.goBack}
                >
                  <i class="fas fa-chevron-left"></i>
                  {res.name}
                </a>
              </h5>
            </div>
            <hr />
          </div>
        );
      });
    }

    return (
      <div className="referenceMaterial">
        <div className="cls_tabs_view resourses" style={{ visibility: "visible" }}>
          <ul className="nav nav-tabs" style={{borderBottom:"0px"}}>
                <li className={ this.state.showCustomResource === false ? "active" : ""}>
                  <div id="tabs1" onClick={this.handleTabsChange}>
                  {LBLDEFAULTRESOURCE ? LBLDEFAULTRESOURCE : "Default Resources"}
                  </div>
                </li>
                <li  className={ this.state.showCustomResource == true ? "active" : ""}>
                  <div id="tabs2" onClick={this.handleTabsChange}>
                  {LBLUPLOADRESOURCE ? LBLUPLOADRESOURCE : "Custom Resources"}
                  </div>
                </li>
              </ul>
        </div>

        {!showCustomResource ?
        <div className="referenceMaterial">
          <div className="row sc-up resourses" style={{ minHeight: "200px" }}>
            <div className="col-xl-12">
              {this.state.resultData.length === 0 ? (
                <div className="mob-pad c-course resource-bg">
                  <h6>{LBLNORESOURCES}</h6>
                  {parentFolderDet}
                  <div className="row resources-section">
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                      <div className="card">
                        <div className="card-body fa fa-folder">
                          <Skeleton height={17} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mob-pad c-course resource-bg resourses">
                  {parentFolderDet}
                  <div className="row resources-section">
                    {vFolderDet}
                    <div className="col-xl-12">&nbsp;</div>
                    {vFileDet}
                  </div>
                </div>
              )}
            </div>
            {this.state.showPopup}
            {this.state.showPopup ? (
              <ResourcePopup
                filename={this.state.filename}
                fileid={this.state.fileid}
                fileformat={this.state.fileformat}
                text='Click "Close Button" to hide popup'
                closePopup={this.togglePopup.bind(this)}
                bclosePopup={this.bclosePopup}
                showPopup={this.state.showPopup}
              ></ResourcePopup>
            ) : null}
          </div>
        </div> : <>
          <div style={{ minHeight: "200px" }}>
            <div style={{width: "calc(100% - 20px)", marginLeft: "10px", marginRight: "10px", border: "1px solid yellow", height: "200px" }}>
               <div style={{cursor:this.state.resourceDataCustom.indexOf('http')>-1?"pointer":"",position:"relative",top:"30px",left:"30px"}} onClick={this.redirectTo}>{this.state.resourceDataCustom.length>0?this.state.resourceDataCustom:<h6>{LBLNORESOURCES}</h6>}</div> 
            </div>
            <iframe src={this.state.resourceDataCustom} style={{ display:"none", width: "calc(100% - 20px)", marginLeft: "10px", marginRight: "10px", border: "1px solid yellow", height: "400px" }}></iframe>
          </div>
        </>}
      </div>
    );
  }
}

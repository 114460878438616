import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { required, email } from "../admin/pages/validate/Validate";
import ScCommonServices from "../services/common_services";
import $ from 'jquery';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactDOM from 'react-dom';
import { classList } from "dynamic-class-list";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenstatus: "input",
            cdTimer: 60,
            showphone: false,
            countryCode: "",
            showTab: "emailaddress"
        }
        this.inputSearch = {}
    }
    renderField = ({ input, label, type, className, meta: { touched, error, warning } }) => (
        <div className="form-group">
            <input {...input} type={type} className={`${className} ${input.value ? 'complete' : ''}`} placeholder={label === "Email" ? label : ""} autoFocus />
            <label htmlFor={input.name} >{label === "Password" || label === "OTP" ? label : ""}</label>
            {touched &&
                ((error && <span className="required-field">{this.props.labels.labels[error]}</span>) ||
                    (warning && <span className="required-field">{warning}</span>))}
        </div>
    )
    onChangeHandler = (e) => {
        if (e.target.value) {
            $(e.target).addClass("complete")
        }
        else {
            $(e.target).removeClass("complete")
        }
    }
    componentDidUpdate() {
        if (this.props.errorMessage) {
            setTimeout(this.closeToastMsg, 2000);
        }
    }
    closeToastMsg = () => {
        this.props.clearErrorMsg();
        this.setState({ errorMessage: "" });
    }
    countDownTimer = () => {
        let cdTimer = this.state.cdTimer
        if (cdTimer > 0) {
            this.setState({
                cdTimer: cdTimer - 1
            })
            setTimeout(this.countDownTimer, 1000)
        }
    }
    generateSMSOtp = (values) => {
        let data = {};
        let li = document.getElementsByTagName('input');
        for (let i = 0; i < li.length; i++) {
            if (li[i].name === "username") {
                data.name = li[i].value
            }
        }
        ScCommonServices.getGenerateOTP(data)
            .then((res_id) => {
                this.setState({
                    cdTimer: 60,
                    screenstatus: "otp"
                })
                setTimeout(this.countDownTimer, 1000)
            })
            .catch((err) => {               
                if (!err.response) {
                    this.setState({
                        apiError: true,
                        errorMessage: this.props.labels.labels.LBLINTERNALSERERROR,
                    });
                } else {
                    let errMsg = this.props.labels.labels[err.response.data.message].replace('$$$', '30');
                    this.setState({
                        apiError: true,
                        errorMessage: errMsg,
                    });
                    setTimeout(this.closeToastMsg, 3000)
                }                
            });
    }
    onChangeValue = () => {
        alert(1)
    }
    goBackToEmailLogin = () => {          
        this.setState({
            screenstatus: "input"
        })        
    }
    goBackToPhoneLogin = () => {        
        this.setState({
            screenstatus: "input"
        })        
    }
    goBackToPasswordLogin = () => {        
        this.setState({
            screenstatus: "selectmode",
            cdTimer: 0
        })        
    }
    setTab = (x) => {
        if (x === "phonenumber") {
          this.setState({
            showTab: x,
            showphone: true
          });
        } else {   
          this.setState({            
            showTab: x,
            showphone: false
          });
        }    
    };
    render() {
        const { handleSubmit, getUsername, labels } = this.props;
        return (<div className="login-form">
            {
                this.props.errorMessage ?
                    <div className="login-toastmsg" id="login-toastmsg">
                        <div className="login-error">{this.props.errorMessage}</div>
                        <div className="login-error-close" onClick={this.closeToastMsg}>x</div>
                    </div> : ''
            }
            {
                this.state.errorMessage ?
                    <div className="login-toastmsg" id="login-toastmsg">
                        <div className="login-error">{this.state.errorMessage}</div>
                        <div className="login-error-close" onClick={this.closeToastMsg}>x</div>
                    </div> : ''
            }
            <form className="form" method="post" onSubmit={handleSubmit} >
                {!getUsername ? (
                    <>
                        {
                            this.state.screenstatus === "input" ? <>
                                <div>
                                    <div className="col-sm-12 tab-dbl" style={{ border: "1px solid white", marginTop: "-40px", marginBottom: "30px",paddingLeft:"0px",paddingRight:"0px", margin: "0px" }}>
                                        <ul className="nav nav-tabs">
                                            <li className={classList([((this.state.showTab === "emailaddress") ? "active" : "")])}>
                                                <div onClick={this.setTab.bind(this, "emailaddress")}>{labels.labels.LBLEMAIL}</div>
                                            </li>
                                            <li className={classList([((this.state.showTab === "phonenumber") ? "active" : "")])}>
                                                <div onClick={this.setTab.bind(this, "phonenumber")}>{labels.labels.LBLPHONE}</div>
                                            </li>                                            
                                        </ul>
                                    </div>
                                    <div style={{ color: "red", display: (this.state.showphone === true) ? "none" : "" }}>
                                        <Field name="username" type="text" component={this.renderField} className="form-field"
                                            validate={required} label={labels.labels.LBLEMAIL} onChange={(e) => {
                                                return
                                                let xval = e.target.value
                                                if (xval !== "" && isNaN(xval) === false) {
                                                    this.setState({
                                                        showphone: true,
                                                        xusername: xval
                                                    })
                                                } else {
                                                    this.setState({
                                                        showphone: false
                                                    })
                                                    return this.onChangeHandler
                                                }
                                            }}
                                            ref="name"
                                        />
                                    </div>
                                    <div className="form-group" style={{ display: (this.state.showphone === false) ? "none" : "" }}>
                                        {
                                            this.state.showphone === true ? <>
                                                <PhoneInput
                                                    localization={this.props.lang_code.split("-")[0]}
                                                    inputStyle={{ width: "100%" }}
                                                    inputProps={{
                                                        name: 'phone_number2',
                                                        autoFocus: true,
                                                        jumpCursorToEnd:true,
                                                        required: true
                                                    }}
                                                    country={this.props.country_code}
                                                    countryCodeEditable={false}
                                                    excludeCountries={["us"]}
                                                    regions={['america', 'europe', 'asia']}
                                                    value={this.state.xusername}
                                                    onChange={(value, country, e, formattedValue) => {                                                       
                                                        this.setState({
                                                            countryCode: country.countryCode
                                                        })                                                    
                                                        let li = document.getElementsByTagName('input');
                                                        for (let i = 0; i < li.length; i++) {
                                                            if (li[i].name === "username") {
                                                                li[i].value = value
                                                                this.doEvent(li[i], 'blur')
                                                                if (value === "") {
                                                                    return
                                                                    this.setState({
                                                                        showphone: false,
                                                                        error: ""
                                                                    }, () => ReactDOM.findDOMNode(this.refs.name).focus())
                                                                    setTimeout(() => {
                                                                        for (let i = 0; i < li.length; i++) {
                                                                            if (li[i].name === "username") {
                                                                                let element = li[i]
                                                                                var eventType = "onfocusin" in element ? "focusin" : "focus",
                                                                                    bubbles = "onfocusin" in element,
                                                                                    event;

                                                                                if ("createEvent" in document) {
                                                                                    event = document.createEvent("Event");
                                                                                    event.initEvent(eventType, bubbles, true);
                                                                                }
                                                                                else if ("Event" in window) {
                                                                                    event = new Event(eventType, { bubbles: bubbles, cancelable: true });
                                                                                }

                                                                                element.focus();
                                                                                element.dispatchEvent(event);
                                                                            }

                                                                        }
                                                                    }, 300)
                                                                }
                                                            }
                                                        }

                                                    }}
                                                />
                                                {
                                                    this.state.error ? <>
                                                        <span className="required-field">{this.props.labels.labels[this.state.error]}</span>
                                                    </> : <></>
                                                }

                                            </> : <></>
                                        }
                                    </div>
                                    {!this.state.showphone &&
                                        <div className="form-group">                                                                     
                                            <button className="btn btn-primary btn-block login-submitbtn" type="submit"
                                                onClick={(event) => {                                                
                                                    let li = document.getElementsByTagName('input');
                                                    for (let i = 0; i < li.length; i++) {
                                                        if (li[i].name === "username") {
                                                            let username = li[i].value
                                                            if(username.indexOf("@") > 0 && username.indexOf(".") > 0) {                                                            
                                                                this.setState({
                                                                    screenstatus: "password"
                                                                })                                                        
                                                            }
                                                        }
                                                    }

                                                }}
                                            >
                                                {labels.labels.LBLNEXT}
                                            </button>                                        
                                        </div>
                                    }
                                    {this.state.showphone &&
                                        <div className="form-group">                                                                     
                                            <button className="btn btn-primary btn-block login-submitbtn" type="submit"
                                                onClick={(event) => {                                                
                                                    let li = document.getElementsByTagName('input');
                                                    for(let i = 0; i < li.length; i++) {
                                                        if(li[i].name === "username") {
                                                            let username = li[i].value
                                                            if(isNaN(username) === false) {                                                            
                                                                if(this.state.countryCode && this.state.countryCode !== "") {
                                                                    this.setState({
                                                                        screenstatus: "selectmode"                                                                    
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        error: "LBLINVALIDPHONE"
                                                                    })
                                                                }
                                                            }
                                                        }
                                                    }

                                                }}
                                            >
                                                {labels.labels.LBLNEXT}
                                            </button>                                        
                                        </div>
                                    }
                                </div>
                            </> : <></>
                        }
                        {
                            this.state.screenstatus === "selectmode" ? <>
                                <div>
                                    <Field name="password" type="password" component={this.renderField} className="form-field"
                                        validate={required} label={labels.labels.LBLPASSWORD} onChange={this.onChangeHandler} />
                                    <div className="form-group btn-dbl">
                                        <button className="btn btn-primary btn-block" type="button" value="Back" onClick={this.goBackToPhoneLogin}>
                                            {labels.labels.LBLBACK}
                                        </button>
                                        <button className="btn btn-primary btn-block login-submitbtn" type="submit" value="Login">
                                            {labels.labels.LBLLOGIN}
                                        </button>
                                    </div>
                                    <div className="form-group">                                       
                                        <button className="btn btn-primary btn-block login-submitbtn" type="button"
                                            onClick={(values) => {
                                                this.generateSMSOtp(1)
                                            }}
                                        >
                                            {labels.labels.LBLLOGINWITHOTP}
                                        </button>
                                    </div>
                                    <Field name="username" type="hidden" component={this.renderField} className="form-field"
                                        validate={required} onChange={this.onChangeHandler} />
                                </div>
                            </> : <></>
                        }
                        {
                            this.state.screenstatus === "password" ? <>
                                <div>
                                    <Field name="password" type="password" component={this.renderField} className="form-field"
                                        validate={required} label={labels.labels.LBLPASSWORD} onChange={this.onChangeHandler} />
                                    <div className="form-group btn-dbl">
                                        <button className="btn btn-primary btn-block" type="button" value="Back" onClick={this.goBackToEmailLogin}>
                                            {labels.labels.LBLBACK}
                                        </button>
                                        <button className="btn btn-primary btn-block login-submitbtn" type="submit" value="Login">
                                            {labels.labels.LBLLOGIN}
                                        </button>
                                    </div>
                                </div>
                            </> : <></>
                        }
                        {
                            this.state.screenstatus === "otp" ? <>
                                <div>
                                    <Field name="passwordotp" type="password" component={this.renderField} className="form-field"
                                        validate={required} label={labels.labels.LBLOTP} onChange={this.onChangeHandler} />
                                    <div className="form-group btn-dbl">
                                        <button className="btn btn-primary btn-block" type="button" value="Back" onClick={this.goBackToPasswordLogin}>
                                            {labels.labels.LBLBACK}
                                        </button>
                                        <button className="btn btn-primary btn-block login-submitbtn" type="submit" value="Login">
                                            {labels.labels.LBLLOGIN}
                                        </button>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block login-submitbtn" type="button"
                                            onClick={(values) => {
                                                this.generateSMSOtp(1)
                                            }}
                                            disabled={this.state.cdTimer > 0 ? true : false}
                                        >
                                            {
                                                this.state.cdTimer > 0 ? <>
                                                    {this.state.cdTimer} {labels.labels.LBLOTPSECONDSLEFT}
                                                </> : <>{labels.labels.LBLRESENDOTP}
                                                    </>
                                            }
                                        </button>
                                    </div>
                                    <Field name="username" type="hidden" component={this.renderField} className="form-field"
                                        validate={required} onChange={this.onChangeHandler} />
                                </div>
                            </> : <></>
                        }
                    </>) : 
                    <>
                        <div className="form-group">
                            <Field name="username" type="text" component={this.renderField} className="form-field"
                                validate={required} label={labels.labels.LBLUSERNAME} onChange={this.onChangeHandler} />
                        </div>
                        <div className="form-group">
                            <button className="btn btn-primary btn-block login-submitbtn" type="submit" value="Login">
                                {labels.labels.LBLLOGIN}
                            </button>
                        </div>
                    </>
                }
                <div className="text-center">
                </div>
            </form >
        </div>);
    }
    doEvent = (obj, event) => {
        /* Created by David@Refoua.me */
        var event = new Event(event, { target: obj, bubbles: true });
        return obj ? obj.dispatchEvent(event) : false;
    }
}
LoginForm = reduxForm({
    form: 'login' // a unique name for this form
})(LoginForm);

export default connect()(LoginForm);
import React from 'react';
import './Empty_state.css';
function EmptyState(props) {

  return (
    <>
      <div className='empty_page'>
        <div className='emptyimage_ctn'>
          <img src={props.image} className={props.className ? props.className:"emptyimage_style"}alt="" />
        </div>

        {props.text1 == undefined ? null : <div className='empty_style1'> {props.text1}</div>}
        {props.text2 == undefined ? null : <div className='empty_style2'> {props.text2}</div>}
        {props.text3 == undefined ? null : <div className='empty_style3'> {props.text3}</div>}
      </div>
    </>
  )
}
export default EmptyState;
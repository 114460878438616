import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "../admin/pages/validate/Validate";
import $ from "jquery";
import Loader from "../admin/components/loader/Loader";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderField = ({
    input,
    label,
    type,
    className,
    disabled,
    onKeyUp,
    meta: { touched, error, warning },
  }) => (
    <div className="form-group">
      <input
        {...input}
        type={type}
        onKeyUp={onKeyUp}
        disabled={disabled}
        className={`${className} ${input.value ? "complete" : ""}`}
      />
      <label htmlFor={input.name}>{label}</label>
      {touched &&
        ((error && (
          <span className="required-field">{this.props.labels.labels[error]}</span>
        )) ||
          (warning && <span className="required-field">{warning}</span>))}
      {/* </div> */}
    </div>
  );
  changePwdVisible =()=> {
    var cntl = document.getElementsByName("password")[0];
    cntl.type = cntl.type === "password"? "text":"password";  
    if(cntl.type === "password"){
      $(".pwd_visibility i").removeClass("fa-eye-slash").addClass("fa-eye");
    }else{
      $(".pwd_visibility i").removeClass("fa-eye").addClass("fa-eye-slash");
    }
  }
  onKeyInput = (e) =>{
    let x = e.target.value;  
    $(".pwd_visibility").css({"display":x.length>0?"block":"none"})
  }
  onChangeHandler = (e) => {
    if (e.target.value) {
      $(e.target).addClass("complete");
    } else {
      $(e.target).removeClass("complete");
    }
  };
  componentDidUpdate() {
    if (this.props.errorMessage) {
      setTimeout(this.closeToastMsg, 2000);
    }
  }
  closeToastMsg = () => {
    this.props.clearErrorMsg();
  };
  render() {
    const {
      handleSubmit,
      getUsername,
      labels,
      logging_in,
      sso_login_loading,
    } = this.props;
    return (
      <div className="login-form">
        {this.props.errorMessage ? (
          <div className="login-toastmsg" id="login-toastmsg">
            <div className="login-error">
              {this.props.errorMessage}
              <sup onClick={this.closeToastMsg}>x</sup>
            </div>
            {/* <div className="login-error-close" onClick={this.closeToastMsg}>
              {" "}
              x
            </div> */}
          </div>
        ) : (
          ""
        )}
        <form className="form" method="post" onSubmit={handleSubmit}>
          {!getUsername ? (
            <div>
              <Field
                name="username"
                type="text"
                component={this.renderField}
                className="form-field"
                disabled={logging_in || sso_login_loading}
                validate={required}
                label={labels.labels.LBLUSERNAME}
                onChange={this.onChangeHandler}
              />

              <Field
                name="password"
                type="password"
                component={this.renderField}
                className="form-field pwd_override"
                disabled={logging_in || sso_login_loading}
                validate={required}
                label={labels.labels.LBLPASSWORD}
                onChange={this.onChangeHandler}
                onKeyUp={this.onKeyInput}
                onKeyInput={this.onKeyInput}
              />
              <span className="pwd_visibility" onClick={this.changePwdVisible} ><i class="fas fa-eye"></i></span> 
            </div>
          ) : (
            <div className="form-group">
              <Field
                name="username"
                type="text"
                component={this.renderField}
                className="form-field"
                validate={required}
                label={labels.labels.LBLUSERNAME}
                onChange={this.onChangeHandler}
              />
            </div>
          )}
          {!logging_in ? (
            <div className="form-group">
              <button
                className="btn login-btn primary-btn btn-block"
                type="submit"
                value="Login"
                disabled={sso_login_loading}
              >
                {labels.labels.LBLLOGIN}
              </button>
            </div>
          ) : (
            <Loader></Loader>
          )}

          <div className="text-center">
          </div>
        </form>
      </div>
    );
  }
}
LoginForm = reduxForm({
  form: "login", // a unique name for this form
})(LoginForm);

export default connect()(LoginForm);

import React, {useState, useEffect } from 'react';
import styles from "./BuildOption.module.scss"
const BuildOptions = (props) => {

    const updatedOption = props.option;

    useEffect(() => {
        props.handleUpdatedJson(updatedOption, checkOptionSelected(updatedOption));
    }, [])

    const handleOptionChange = (event, index) => {

        if (props.questionType.toLowerCase() === "single") {
            const checkedJsonUpdate = updatedOption.map((item, index) => {
                if (item.sid === event.target.id) {
                    return { ...item, userAnswer: true };
                } else {
                    return { ...item, userAnswer: false };
                }
            })
            props.handleUpdatedJson(checkedJsonUpdate, checkOptionSelected(checkedJsonUpdate));
            setTimeout(() => {
                props.handleNext();
            }, 500);
            // console.log(checkedJsonUpdate, "checkedJsonUpdate")

        } else if (props.questionType.toLowerCase() === "multiple") {
            updatedOption[index].userAnswer = !updatedOption[index].userAnswer
            props.handleUpdatedJson(updatedOption, checkOptionSelected(updatedOption));
            console.log(updatedOption, "updatedOption")
        } else {
            if (updatedOption[index].mode === "single") {
                const checkedJsonUpdate = updatedOption.map((item, index) => {
                    if (item.sid === event.target.id) {
                        return { ...item, userAnswer: !item.userAnswer };
                    } else {
                        return { ...item, userAnswer: false };
                    }
                })
                props.handleUpdatedJson(checkedJsonUpdate, checkOptionSelected(checkedJsonUpdate));
            } else {
                updatedOption[index].userAnswer = !updatedOption[index].userAnswer
                const checkedJsonUpdate = updatedOption.map((item, index) => {
                    if (item.mode === "single") {
                        return { ...item, userAnswer: false };
                    } else {
                        return item;
                    }
                })

                props.handleUpdatedJson(checkedJsonUpdate, checkOptionSelected(checkedJsonUpdate));
            }
        }
    };

    const checkOptionSelected = (pUpdatedJson) => {
        let checkUserAnswer = pUpdatedJson.some((item) => item.userAnswer === true);
        return checkUserAnswer;
    }
    console.log(updatedOption, "updatedOptionupdatedOption")
    return (
        <div className='buildOptions'>
            <div className={styles.optionctn}>
                {props.questionType === "single" &&
                    updatedOption && updatedOption.map((item, index) => (
                        <div key={index}>
                            <label className={item.userAnswer ? styles.optionChecked : styles.option} >
                                <input
                                    id={item.sid}
                                    type="radio"
                                    value={item.text}
                                    onChange={(event) => handleOptionChange(event, index)}
                                    checked={item.userAnswer}
                                    className={styles.optionRadio}
                                />
                                {props.labels[item.text]}
                            </label>
                        </div>
                    ))}
                {props.questionType === "multiple" || props.questionType === "singleormulti" ? (
                    updatedOption && updatedOption.map((item, index) => (
                        <div key={index}>
                            <label className={item.userAnswer ? styles.optionChecked : styles.option} >
                                <input
                                    id={item.sid}
                                    type="checkbox"
                                    value={item.text}
                                    onChange={(event) => handleOptionChange(event, index)}
                                    checked={item.userAnswer}
                                    className={styles.optionRadio}
                                />
                                {props.labels[item.text]}
                            </label>
                        </div>
                    ))
                ) : null}
            </div>
        </div>
    );
};

export default BuildOptions;

import React from "react";
import ReadImage from "../readImage/readImage";
import CollageView from "../readImage/collageView";
import "./feedblock.css";
import ReadVideo from "../readVideo/readvideo";
import ProfileImageDisplay from "../profileimage/profileimage";
//import DisplayOGConent from "../ogcontent/ogcontent";
import AssetsViewer from "../assetsviewer/assetsviewer";
import html from "react-inner-html";
import FeedsServices from "../../feedService/feedservices";
import UploadPopup from "../feedUploadPopup/uploadPopup";
import CommentsComponent from "../comments/comments";
import { ReactTinyLink } from "react-tiny-link";
import Carousel from "react-bootstrap/Carousel";
import { Modal } from "react-bootstrap";
import SeeMoreText from "../../../common_components/seemore_text/SeeMoreText";

class FeedDisplayBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeedRemoveBtnPanner: 0,
      commentPostBtnStatus: 0,
      showAssetsViewer: 0,
      showAllComments: 0,
      feedRemovePermission: 1,
      descriptionText: "",
      openComments: false,
      isModalOpen: false,
      activeIndex: 0,
      urlRegex: /(https?:\/\/[^\s]+)/gi,      
      showLikeList: false,
    };
    this.commentRef = React.createRef();
    this.removeBtnRef = React.createRef();
    this.initNoOfCommentShow = 2;
    this.urlRegex = /(https?:\/\/[^\s]+)/gi;
    this.anchorRegExp = /<a[^>]*>([^<]+)<\/a>/g;

    this.wwwRegex = /(www?.[^\s]+)/gi;

  }


  componentDidMount() {
    let deletePermission = 0;
console.log("this.props",this.props.role_id)
// console.log("this.state",this.state)
    if (
      (this.props.feedItemData.profile_id ===
      this.props.userInfo.currentuser.user_id)|| (this.props.role_id===1 && this.props.page === "moderate" )
    ) {
      deletePermission = 1;
    }
    this.setState({
      feedRemovePermission: deletePermission,
    });
  }
  handleClick = () => {
    this.setState({
      showLikeList: true,
    });
  };
  closeModal1 = () => {
    this.setState({
      showLikeList: false,
    });
  };
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.feedsLanguageData && this.props.feedsLanguageData[paramLBL]
        ? this.props.feedsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  showFeedRemoveBtnHandler = (evt) => {
    this.setState(
      {
        showFeedRemoveBtnPanner:
          this.state.showFeedRemoveBtnPanner === 0 ? 1 : 0,
      },
      () => {
        document.addEventListener("click", this.removeBtnOutsideClickHandler);
      }
    );
  };
  removeBtnOutsideClickHandler = (evt) => {
    let elements = evt.target;
    if (elements && !this.removeBtnRef.contains(elements) && !elements.classList.contains("feeds-actions")) {
      document.removeEventListener("click", this.removeBtnOutsideClickHandler);
      this.setState({
        showFeedRemoveBtnPanner: 0,
      });
    }
  };
  removeFeedHandler = (evt) => {
    this.setState({
      showFeedRemoveBtnPanner: 0,     
    });
    this.props.reomoveFeedBlock(this.props.feedItemData);
  };
  editHandler = (evt) => {
    this.setState({
      isModalOpen: true,
    });
  };
  sendCommentHandler = (evt) => {
    if (this.commentRef.current.value) {
      this.props.sendFeedComment(
        this.props.feedItemData,
        this.commentRef.current.value
      );
      this.props.feedItemData.comments.unshift({
        comment_text: this.commentRef.current.value,
        profile_id: this.props.userInfo.currentuser.user_id,
        createdAt: new Date(),
      });
      this.commentRef.current.value = "";
      this.setState({
        commentPostBtnStatus: 0,
      });
    }
  };
  sendLikeActionHandler = (evt) => {
    this.props.sendFeedLikeAction(this.props.feedItemData);
    if (this.props.feedItemData.like_status === 1) {
      this.props.feedItemData.like_status = 0;
      let likeArr = [];
      this.props.feedItemData.likes.map((likeItem) => {
        if (likeItem.profile_id !== this.props.userInfo.currentuser.user_id) {
          likeArr.push(likeItem);
        }
      });
      this.props.feedItemData.likes = likeArr;
    } else {
      this.props.feedItemData.like_status = 1;
      this.props.feedItemData.likes.push({
        profile_id: this.props.userInfo.currentuser.user_id,
      });
    }
    this.setState({
      commentPostBtnStatus: this.state.commentPostBtnStatus,
    });
  };
  checkImageExtention = (imgName) => { };
  commentPostBtnStatus = () => {
    this.setState({
      commentPostBtnStatus: String(this.commentRef.current.value).trim()
        ? 1
        : 0,
    });
  };
  commentsPostTextAreaChangeHandler = (evt) => {
    this.commentPostBtnStatus();
  };
  calcForTimeCreation = (createdAt, txtFormat = "") => {
    let displayTimeText = "";
    let createdDate = new Date(createdAt);
    let diffMS = new Date() - createdDate;
    let roundSec = Math.floor(diffMS / 1000);
    let hr = Math.floor(roundSec / (60 * 60));
    let min = Math.floor((roundSec % (60 * 60)) / 60);
    let sec = (roundSec % (60 * 60)) % 60;
    let day = Math.floor(hr / 24);
    let week = Math.floor(day / 7);
    let year = Math.floor(day / 365);
    if (this.props.langCode === "fr-FR" || this.props.langCode === "de-DE") {
      if (hr <= 0 && min <= 0 && sec <= 0) {
        displayTimeText =
          txtFormat === "single"
            ? this.getResourceFromLabels("LBLFEWSEC")
            : `${this.getResourceFromLabels(
              "LBLAGO"
            )} ${this.getResourceFromLabels(
              "LBLFEW"
            ).toLowerCase()} ${this.getResourceFromLabels(
              "LBLSECONDS"
            ).toLowerCase()}`;
      } else if (hr <= 0 && min <= 0 && sec > 0) {
        displayTimeText =
          txtFormat === "single"
            ? sec + " sec"
            : `${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()} ${sec} ${this.getResourceFromLabels(
              "LBLSECONDS"
            ).toLowerCase()}`;
      } else if (hr <= 0 && min > 0) {
        displayTimeText =
          txtFormat === "single"
            ? min + " min"
            : `${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()} ${min} ${this.getResourceFromLabels(
              "LBLMINUTES"
            ).toLowerCase()}`;
      } else if (hr > 0 && day <= 0) {
        displayTimeText =
          txtFormat === "single"
            ? hr + " h"
            : `${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()} ${hr} ${this.getResourceFromLabels(
              "LBLHOURS"
            ).toLowerCase()}`;
      } else if (day > 0 && week <= 0) {
        displayTimeText =
          txtFormat === "single"
            ? day + " d"
            : `${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()} ${day} ${this.getResourceFromLabels(
              "LBLDAYS"
            ).toLowerCase()}`;
      } else if (week > 0 && year <= 0) {
        displayTimeText =
          txtFormat === "single"
            ? week + " w"
            : `${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()} ${week} ${this.getResourceFromLabels(
              "LBLWEEKS"
            ).toLowerCase()}`;
      } else if (year > 0) {
        displayTimeText =
          txtFormat === "single"
            ? year + " y"
            : `${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()} ${year} ${this.getResourceFromLabels(
              "LBLYEAR"
            ).toLowerCase()}`;
      }
    } else {
      if (hr <= 0 && min <= 0 && sec <= 0) {
        displayTimeText =
          txtFormat === "single"
            ? this.getResourceFromLabels("LBLFEWSEC")
            : `${this.getResourceFromLabels(
              "LBLFEW"
            )} ${this.getResourceFromLabels(
              "LBLSECONDS"
            ).toLowerCase()} ${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()}`;
      } else if (hr <= 0 && min <= 0 && sec > 0) {
        displayTimeText =
          txtFormat === "single"
            ? sec + " s"
            : `${sec} ${this.getResourceFromLabels(
              "LBLSECONDS"
            ).toLowerCase()} ${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()}`;
      } else if (hr <= 0 && min > 0) {
        displayTimeText =
          txtFormat === "single"
            ? min + " m"
            : `${min} ${this.getResourceFromLabels(
              "LBLMINUTES"
            ).toLowerCase()} ${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()}`;
      } else if (hr > 0 && day <= 0) {
        displayTimeText =
          txtFormat === "single"
            ? hr + " h"
            : `${hr} ${this.getResourceFromLabels(
              "LBLHOURS"
            ).toLowerCase()} ${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()}`;
      } else if (day > 0 && week <= 0) {
        displayTimeText =
          txtFormat === "single"
            ? day + " d"
            : `${day} ${this.getResourceFromLabels(
              "LBLDAYS"
            ).toLowerCase()} ${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()}`;
      } else if (week > 0 && year <= 0) {
        displayTimeText =
          txtFormat === "single"
            ? week + " w"
            : `${week} ${this.getResourceFromLabels(
              "LBLWEEKS"
            ).toLowerCase()} ${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()}`;
      } else if (year > 0) {
        displayTimeText =
          txtFormat === "single"
            ? year + " y"
            : `${year} ${this.getResourceFromLabels(
              "LBLYEAR"
            ).toLowerCase()} ${this.getResourceFromLabels(
              "LBLAGO"
            ).toLowerCase()}`;
      }
    }
    return displayTimeText;
  };
  showAssetsViewerHandler = (evt) => {
    this.setState({
      showAssetsViewer: 1,
    });
  };
  closeAssetsViewer = () => {
    this.setState({
      showAssetsViewer: 0,
    });
  };
  showMoreCommentsHandler = (evt) => {
    this.setState({
      showAllComments: this.state.showAllComments === 0 ? 1 : 0,
    });
  };
  getProfileDetails = (profileId, property = "username") => {
    if (!profileId) return "";
    let profileVal = 
      property === "name"
        ? this.props.userInfo[profileId]?.profile_name
        : property === "image"
          ? this.props.userInfo[profileId]?.profile_image
          : this.props.userInfo[profileId]?.user_name;
    return profileVal;
  };
  replaceContent = labelVal => {
    labelVal = labelVal.indexOf('<a') === -1 ? this.replacer(labelVal) : labelVal;
    return labelVal;
  };

  replacer = (descriptionText) => {
    let urls = descriptionText.match(this.urlRegex);

    let wwwurls = descriptionText.match(this.wwwRegex);
    if (urls) {

      for (let i = 0; i < urls.length; i++) {
        let newStr = `<a class="ssi-feeds-displayBlockOGContentLink" target="_blank" href="${urls[i]}">${urls[i]}</a>`;
        descriptionText = descriptionText.replace(urls[i], newStr);
      }
      if (wwwurls) {
        let urlJns = urls.join();
        for (let i = 0; i < wwwurls.length; i++) {
          if (urlJns.indexOf(wwwurls[i]) === -1) {
            let newStr1 = `<a class="ssi-feeds-displayBlockOGContentLink" target="_blank" href="http://${wwwurls[i]}">${wwwurls[i]}</a>`;
            descriptionText = descriptionText.replace(wwwurls[i], newStr1);
          }
        }
      }
      return descriptionText;
    }
    else if (wwwurls) {
      for (let i = 0; i < wwwurls.length; i++) {
        let newStr1 = `<a class="ssi-feeds-displayBlockOGContentLink" target="_blank" href="http://${wwwurls[i]}">${wwwurls[i]}</a>`;
        descriptionText = descriptionText.replace(wwwurls[i], newStr1);
      }
      return descriptionText;
    } else {
      return descriptionText;
    }
  }


  feedsBasedOnSelectedUserHandler = (evt) => {
    this.props.getSelectedFeeds("user", this.props.feedItemData.profile_id);
  };
  feedsBasedOnSelectedChannelHandler = (evt) => {
    this.props.getSelectedFeeds(
      "channel",
      this.props.feedItemData.channel_info.channel_id
    );
  };
  openCommentBox = () => {
    this.props.feedItemData.comments_hide = true;
    this.setState({
      openComments: !this.props.hideComment,
    });
  };
  editComments = () => {
    alert("edit");
  };
  deleteComments = () => {
    alert("delete");
  };
  closeUploadFormHandler = () => {
    this.setState({
      isModalOpen: false,
    });
  };
  handleSelect = (selectedIndex, e) => { };
  feedsPostResultHandler = (resultData) => {
    if (
      resultData.data &&
      (resultData.data._id || resultData.data.message === "update_successfully")
    ) {
      this.setState({
        isModalOpen: false,
      });
      this.props.feedsPostResultHandler(resultData);
    }
  };
  createPostHandler = (evt, postData, assest) => {
    let apiUrl = "feeds/" + postData._id;
    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "put",
      this.feedsPostResultHandler,
      postData
    );
  };
  onSelectAsset = (eventKey) => {
    this.setState({
      activeIndex: eventKey,
    });
  };
  render() {
    let feedLidedClass =
      this.props.feedItemData.like_status === 1 ? "feedLiked" : "";
    const maxNoOfAssetsDisplay = 4;
    let totalAssets =
      this.props.feedItemData.assets.length +
      this.props.feedItemData.og_content.length;
    let currNoOfAssets = 0;
    return (
      <div className="ssi-feeds-displayBlockContainer feeds-block">
        <div className="ssi-feeds-displayBlockHeaderContainer">
          <div className="ssi-feeds-blockHeaderProfileContainer">
            <div className="ssi-feeds-blockHeaderUserContainer">
              <ProfileImageDisplay
                imageName={""}
                firstLetterDisplay={this.getProfileDetails(
                  this.props.feedItemData.profile_id
                )}
              />
              {this.props.page !== "moderate" ? (
                <div className="ssi-feeds-displayProfileName">
                  <div className="blockProfileName">
                    <span onClick={this.feedsBasedOnSelectedUserHandler}>
                      {this.getProfileDetails(
                        this.props.feedItemData.profile_id
                      )}
                    </span>
                    <span>
                      {" "}
                      <i class="fa fa-caret-right"></i>
                    </span>
                    {this.props.page !== "moderate" ? (
                      <span onClick={this.feedsBasedOnSelectedChannelHandler}>
                        {this.props.feedItemData.channel_info.channel_name}
                      </span>
                    ) : null}
                  </div>
                  <div className="ssi-feeds-displayBlockTimeMsg">
                    <span>
                      {this.calcForTimeCreation(
                        this.props.feedItemData.createdAt
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="ssi-feeds-displayProfileName">
                  <div className="blockProfileName">
                    <span onClick={this.feedsBasedOnSelectedUserHandler}>
                      {this.getProfileDetails(
                        this.props.feedItemData.profile_id
                      )}
                    </span>
                    
                  </div>
                  <div className="ssi-feeds-displayBlockTimeMsg">
                    <span style={{ cursor: "default" }}>
                      {this.calcForTimeCreation(
                        this.props.feedItemData.createdAt
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={
              this.state.feedRemovePermission === 1
                ? "ssi-feeds-displayRemoveFeedBtn"
                : "ssi-feeds-displayRemoveFeedBtn disable"
            }
          >
            <span
              className="removeicon fas fa-ellipsis-h"
              onClick={this.showFeedRemoveBtnHandler}
              ref={(ref) => this.removeBtnRef = ref}
              title={this.getResourceFromLabels("LBLMORE")}
            ></span>
            {this.state.showFeedRemoveBtnPanner === 1 ? (
              <div className="ssi-feeds-displayBlockRemoveBtnPannel">
                <ul>
                {this.props.page !== "moderate" ? (
                  <li
                    onClick={this.editHandler} className="feeds-actions">
                    <i class="fas fa-pencil-alt"></i>

                    <span>{this.getResourceFromLabels("LBLEDITPOST")}</span>
                  </li>):null}
                  <li onClick={this.removeFeedHandler} className="feeds-actions">
                    <i class="far fa-trash-alt"></i>
                    <span>{this.getResourceFromLabels("LBLREMOVE")}</span>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {this.props.feedItemData.description ? (
          <div className="ssi-feeds-displayBlockDescriptionContainer">
            <div className="ssi-feeds-displayBlockDescriptionText">
              <SeeMoreText
                textValue={this.replaceContent(this.props.feedItemData.description)}
                seemoretext={this.getResourceFromLabels("LBLSEEMORE")}
                seelesstext={this.getResourceFromLabels("LBLSEELESS")}
              >
              </SeeMoreText>


            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.feedItemData.assets.length === 0 &&
          this.props.feedItemData.description.match(this.state.urlRegex) ? (
          <ReactTinyLink
            cardSize="small"
            showGraphic={true}
            maxLine={3}
            minLine={1}
            url={this.props.feedItemData.description
              .match(this.state.urlRegex)
              .toString()}
          />
        ) : null}
        {this.props.feedItemData.assets.length ||
          this.props.feedItemData.og_content.length ? (
          <div className="ssi-feeds-displayBlockContentContainer">
            <div className="ssi-feeds-displayBlockContentWrapper">
              {this.props.feedItemData.assets.length > 0 ? (
                <Carousel
                  controls={this.props.feedItemData.assets.length > 1}
                  indicators={this.props.feedItemData.assets.length > 1}
                  onSelect={this.onSelectAsset}
                  interval={null}
                >
                  {this.props.feedItemData.assets.map((assetItem, index) => {
                    return (
                      <Carousel.Item>
                        {assetItem.asset_type === "image" ||
                          assetItem.asset_type === "image/jpeg" ||
                          assetItem.asset_type === "image/png" ? (
                          <ReadImage
                            newpost={
                              this.props.feedItemData.newpost
                                ? this.props.feedItemData.newpost
                                : 0
                            }
                            index={index}
                            key={assetItem._id}
                            assetItem={assetItem}
                            totalAssets={1}
                          />
                        ) : (
                          <ReadVideo
                            playingstatus={false}
                            currNoOfAssets={0}
                            keyvalue={0}
                            company_id={assetItem.company_id}
                            showAssetsViewer={this.state.showAssetsViewer}
                            index={index}
                            key={assetItem._id}
                            feed_id={this.props.feedItemData._id}
                            assetItem={assetItem}
                            totalAssets={1}
                            mediaProcessing={this.getResourceFromLabels(
                              "LBLMEDIAPROCESSING"
                            )}
                          />
                        )}
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              ) : (
                
                ""
              )}
            </div>
            {totalAssets > 0 ? (
              <div
                className="ssi-feeds-displayBlockContentOverFlow"
                onClick={this.showAssetsViewerHandler}
              >
                {totalAssets > maxNoOfAssetsDisplay ? (
                  <div className="ssi-feeds-displayBlockContentOverFlowWrapper">
                    <span>+ {totalAssets - maxNoOfAssetsDisplay}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {this.state.showAssetsViewer === 1 ? (
              <AssetsViewer
                playingstatus={true}
                showAssetsViewer={this.state.showAssetsViewer}
                feedItemData={this.props.feedItemData}
                closeAssetsViewer={this.closeAssetsViewer}
                activeIndex={this.state.activeIndex}
                feedsLanguageData={this.props.feedsLanguageData}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div className="ssi-feeds-displayBlockActionContainer">
          <div className="ssi-feeds-displayBlockLikeBtnContainer">
            <div
              className={`ssi-feeds-displayBlockLikeBtn ${feedLidedClass}`}
              onClick={this.sendLikeActionHandler}
            >
              <span className="fas fa-thumbs-up"></span>
            </div>
            <div className="ssi-deeds-displayBlockLikeText">
              <div
                onClick={this.handleClick}
                className={
                  this.props.feedItemData.likes.length === 0
                    ? "like_disable"
                    : ""
                }
              >
                {this.props.feedItemData.likes.length === 0
                  ? `${this.getResourceFromLabels("LBLLIKE")}`
                  : `${this.props.feedItemData.likes.length
                  } ${this.getResourceFromLabels("LBLLIKES")}
                    `}
              </div>
            </div>
          </div>
          <div
            className="ssi-feeds-displayBlockCommentIconContainer"
            onClick={this.openCommentBox}
          >
            <div className="ssi-feeds-displayBlockCommentIcon">
              <span className="far fa-comment"></span>
            </div>
            <div className="ssi-deeds-displayBlockLikeText">
              <span>
                {this.props.feedItemData.comments.length === 0
                  ? `${this.getResourceFromLabels("LBLCOMMENT")}`
                  : `${this.props.feedItemData.comments.length
                  } ${this.getResourceFromLabels("LBLCOMMENTS")}`}
              </span>
            </div>
          </div>
        </div>
        {this.props.feedItemData.comments_hide !== false && (
          <div className="ssi-feeds-displayBlockCommentContainer">
            {this.props.feedItemData.comments &&
              this.props.feedItemData.comments.length




              ? this.props.feedItemData.comments.map((comment, index) => {
                comment.show = 0;
                return (
                  <CommentsComponent
                    feed_id={this.props.feedItemData._id}
                    deleteComments={this.props.deleteComments}
                    updateComments={this.props.updateComments}
                    initNoOfCommentShow={this.initNoOfCommentShow}
                    showAllComments={this.state.showAllComments}
                    calcForTimeCreation={this.calcForTimeCreation}
                    page={this.props.page} 
                    role_id={this.props.role_id} 
                    getProfileDetails={this.getProfileDetails}
                    comment={comment}
                    index={index}
                    edit={this.getResourceFromLabels("LBLEDIT")}
                    delete={this.getResourceFromLabels("LBLDELETE")}
                    title={this.getResourceFromLabels("LBLMORE")}
                    deleteComment={this.getResourceFromLabels(
                      "LBLDELETECOMMENT"
                    )}
                    seeMore={this.getResourceFromLabels("LBLSEEMORE")}
                    seeLess={this.getResourceFromLabels("LBLSEELESS")}
                    cancel={this.getResourceFromLabels("LBLCANCEL")}
                    saveChanges={this.getResourceFromLabels("LBLSAVECHANGES")}
                    loginUserId={this.props.userInfo.currentuser.user_id}
                    key={comment._id}

                  />
                );
              })
              : ""}
            {this.props.feedItemData.comments_hide !== false &&
              this.props.feedItemData.comments &&
              this.props.feedItemData.comments.length >
              this.initNoOfCommentShow ? (
              <div
                className="ssi-feeds-displayBlockCommentSeeMore"
                onClick={this.showMoreCommentsHandler}
              >
                {this.state.showAllComments === 0
                  ? `${this.getResourceFromLabels("LBLSEEMORECOMMENTS")}...`
                  : `${this.getResourceFromLabels("LBLSEELESSCOMMENTS")}...`}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {this.props.feedItemData.comments_hide !== false && (
          <div className="ssi-feeds-displayBlockCommentsContainer">
            <div className="ssi-feeds-displayBlockComments">
              <textarea
                maxlength="1024"
                placeholder={`${this.getResourceFromLabels(
                  "LBLADDCOMMENT"
                )}...`}
                ref={this.commentRef}
                onChange={this.commentsPostTextAreaChangeHandler}
              ></textarea>
              <div
                className={
                  this.state.commentPostBtnStatus === 1
                    ? "ssi-feeds-displayBlockPostBtn"
                    : "ssi-feeds-displayBlockPostBtn disable"
                }
                onClick={this.sendCommentHandler}
              >
                <span>{this.getResourceFromLabels("LBLPOSTCOMMENT")}</span>
              </div>
            </div>
          </div>
        )}

        {this.state.isModalOpen && (
          <UploadPopup
            isOpen={this.state.isModalOpen}
            channelsData={this.props.channelsData}
            onHide={this.closeUploadFormHandler}
            label={this.getResourceFromLabels("LBLEDITPOST")}
            submitPost={this.createPostHandler}
            getResourceFromLabels={this.getResourceFromLabels}
            userInfo={this.props.userInfo}
            type={2}
            feedItemData={this.props.feedItemData}
            signedUrlResponseHandler={this.props.signedUrlResponseHandler}
          />
        )}
        <Modal show={this.state.showLikeList} onHide={this.closeModal1} className="feeds-block">
          <Modal.Header closeButton className="alert-modal-header">
            <Modal.Title className="alert-modal-title">{this.getResourceFromLabels("LBLLIKES")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="ssi-feeds-lbl">
              <div className="ssi-feeds-modal-sub-title2">
                {this.getResourceFromLabels("LBLLIKEDBY")}
              </div>

              <div className="ssi-feeds-displayBlockLikeText1">
                <div onClick={this.handleClick}>
                  {this.props.feedItemData.likes.length === 0
                    ? ""
                    : `${this.props.feedItemData.likes.length
                    } ${this.getResourceFromLabels("LBLLIKES")}`}
                </div>
              </div>
            </div>
            {this.props.feedItemData.likes.map((data, index) => (
              <div
                className="List"
                style={{
                  borderBottom:
                    this.props.feedItemData.likes.length - 1 === index
                      ? "0px solid #dee2e6"
                      : "1px solid #dee2e6",
                }}
              >
                <div className="profile">
                  <ProfileImageDisplay
                    imageName={""}
                    firstLetterDisplay={this.getProfileDetails(data.profile_id)}
                  />
                </div>
                <div className="ListName">
                  {this.props.userInfo[data.profile_id]?.profile_name}
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-md-12 text-center">
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default FeedDisplayBlock;

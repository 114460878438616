const userInfo = (userReducerData) => {
  return {
    type: "USER_INFO",
    payload: { data: userReducerData },
  };
};

export const userUpdate = (userReducerData) => {
  return {
    type: "USER_INFO",
    payload: { data: userReducerData },
  };
};

export default userInfo;

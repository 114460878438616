import React, { Component } from "react";
import ActivitiesModalpopup from "../../../common_components/activitiesmodalanddatatable/ActivitiesModalpopup";

class Activities extends Component {
    constructor(props) {
        super(props);
        this.state = {  searchValue:''}
        this.onActvityRowClick =  this.onActvityRowClick.bind(this);
      }
 

  closeModalDetails = () => {
    this.props.hideActivities(false);
  }
  
  searchActivity = (e) => {
    this.setState({searchValue: e.target.value});
  }

  onActvityRowClick = (e, index)=>{
    console.log("--"+index,e)
  }


  activityDetTableHeader = () => {
    return (
      <div className="assignment-header">
        <div></div>
        <div className="table-filter">
          <input
            type="text"
            className="form-control"
            placeholder={this.props.labels.LBLSEARCHBYNAME}
            // value={this.state.searchText}
            onChange={this.searchActivity}
            onKeyDown={this.searchActivity}
          />
        </div>
      </div>
    );
  }

  loadActivityDetails = () => {
       const activityDetailsFilter =  
      this.props.activityData !== undefined ?
          this.props.activityData.filter((item) => {
            if (item.capsule_content_name && (item.capsule_content_name.toLowerCase().includes(this.state.searchValue) ||
                item.capsule_content_name.toLowerCase().includes(this.state.searchValue.toLowerCase()))) {
              return item; 
            }
          })        
      : "" 
 
    let activityTableHeader = this.activityDetTableHeader();
    let activityTableData = activityDetailsFilter;
    return(
      <ActivitiesModalpopup
      Showmodal={this.props.showActivities}
      closemodal={this.closeModalDetails}
      ModalTiltle={this.props.activityHeader}
      DataTableHeader={activityTableHeader}
      Norecords={this.props.labels.LBLNORECORDS}
      DatatableData={activityTableData}
      onActvityRowClick = {this.onActvityRowClick}
      {...this.props}
      ></ActivitiesModalpopup>
    )
  }

    render() { 
        return (              
            this.loadActivityDetails()
        );
    } 
}

const mapStateToProps = (state) => {
  return {
    userid: state.user_info_reducer.user_info.user_id,
    first_name: state.user_info_reducer.user_info.first_name,
    last_name: state.user_info_reducer.user_info.last_name,
    companyid: state.user_info_reducer.user_info.company_id,
    language_code: state.user_info_reducer.user_info.language_code,
    company_logo: state.user_info_reducer.user_info.company_logo,
  };
};
export default Activities;
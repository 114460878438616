
class TranslateFetch {
    async getLabels() {
        let comp_name = arguments[0].comp_name;
        let lang_code = arguments[0].lang_code;
        if (comp_name.includes("reports") && !comp_name.includes("steercomreports")) {
            comp_name = "reports";
        } else if (comp_name.includes("channel")) {
            comp_name = "channels";
        } else if (comp_name.includes("rate") && comp_name != "rateyourstate") {
            comp_name = "rateyourstate";
        }
        let url = !arguments[0].url ? process.env.REACT_APP_YOUFACTORS_TRANSLATION_URL + comp_name + '/' + comp_name + '.json' : arguments[0].url;
        const data = await fetch(url, {cache: "force-cache"}).then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                return false
            }
        }).then(resp => {
            return resp[lang_code]
        })
          return data;
    }
}

export default new TranslateFetch();

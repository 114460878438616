import React from "react";
import "./resourcepopup.scss";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { Modal } from "react-bootstrap";
import Loader from "../../loader/Loader";


export const ResourcedownloadFiles = (n) => {
  this.downloadFiles();
};
export default class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.filePreview = this.filePreview.bind(this);
    this.state = {
      source: null,
      iframe_loaded: false,
    };
    this.iframeLoaderss = this.iframeLoader.bind(this);
  }

  downloadFiles() {
    let headers = new Headers({
      responseType: "blob",
    });

    var fileformat = this.props.fileformat;
    let fileId = this.props.fileid;
    HttpInterceptor("sthrees?type=" + 1 + "&fileId=" + fileId, {
      headers,
      responseType: "arraybuffer",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/" + fileformat })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.props.filename + "." + fileformat); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  componentDidMount() {
    this.filePreview();
  }

  filePreview() {
    HttpInterceptor("sthrees?type=" + 2 + "&fileId=" + this.props.fileid).then(
      (res) => {
        let data = encodeURIComponent(res.data.data);
        if (["mp4", "wmv"].indexOf(this.props.fileformat) !== -1) {
          console.log("1");
          document.getElementById("mp4").data = res.data.data;
          this.setState({
            iframe_loaded: true,
          });
        } else {
          this.setState({
            source: process.env.REACT_APP_DOCS_VIEWER_URL + data,
          });
          this.iframeLoaderss(process.env.REACT_APP_DOCS_VIEWER_URL + data);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  iframeLoader(url) {
    let iframe = document.getElementById("fileFrame");
    this.ift = setInterval(() => {
      if (iframe && !iframe.contentDocument) {
        clearInterval(this.ift);
      } else {
        if (document.getElementById("fileFrame")) {
          document.getElementById("fileFrame").src = url;
        }
      }
      if (!document.getElementById("fileFrame")) {
        clearInterval(this.ift);
      }
      console.log("propss");
    }, 2000);
  }

  closePopUp(propss) {
    propss.bclosePopup();
    clearInterval(this.ift);
  }
  render() {
    return (
      <Modal
        size="lg"
        show={this.props.showPopup}
        onHide={() => this.closePopUp(this.props)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="r-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <h4>
              <span>{this.props.filename}</span>
              <div className="download-icn" onClick={this.downloadFiles}>
                <a class="padding0" href="javascript:void(0)">
                  <span className="fa fa-download text-right" alt="" />{" "}
                  Download
                </a>
              </div>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="hidden" id="fileId" value={this.props.fileid} />
          <input type="hidden" id="fileName" value={this.props.filename} />
          <input type="hidden" id="fileFormat" value={this.props.fileformat} />
          {!this.state.iframe_loaded ? <Loader></Loader> : ""}
          {["mp4", "wmv"].indexOf(this.props.fileformat) !== -1 ? (
            <div style={{width:400, height:500}}>
                <object id="mp4" height="300" width="400"></object>
            </div>
          ) : this.state.source ? (
            <iframe
              id="fileFrame"
              height="500"
              width="100%"
              ref="ssi_iframe"
              title=""
              onLoad={() => {
                this.setState({
                  iframe_loaded: true,
                });
              }}
              onError={() => {
                console.log(" ");
              }}
              src={this.state.source}
            ></iframe>
          ) : null}
        </Modal.Body>
      </Modal>
    );
  }
}

import React, { Component } from "react";
import { animateScroll, animateScroll as scroller } from "react-scroll";
import "./safestartsurvey.scss";
import xobj_safestartsurvey from "./surveyfile.json";
import ScCommonServices from "../../../services/common_services";
import InputRange from "react-input-range";
import { Modal } from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import Loader from "../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";

let response = {};
let scrollPoint = null;
let obj_safestartsurvey = xobj_safestartsurvey["obj_safestartsurvey"];
class SafeStartSurvey extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const uid = params.get("uid");
    this.totalPages = 4;
    this.state = {
      passvars: window.location.href.split("#")[1] || "",

      showscreen: "",
      surveysubmitted: false,
      completed: 40,
      cur_mod_index: 0,

      page_question_count: 1,
      cur_mod_page_index: 0,
      total_questions: 0,
      cur_question: 0,

      page_endscreen_count: 1,
      cur_endscreen_page_index: 0,
      total_endscreens: 0,
      cur_endscreen: 0,

      page_startscreen_count: 1,
      cur_startscreen_page_index: 0,
      total_startscreens: 0,
      cur_startscreen: 0,

      total_answered_question: 0,
      selected: 0,
      finalpage: 0,
      show: false,
      loading: true,
      version: 1.0,
      submit: false,
      next: true,
      previous: false,
      err: false,
      qanswers: [],
      sssurvey_date: new Date(),
      choices: [],
      user_id: uid || this.props.user_id,
      LBLOTHER: "",
      loadNext: false,
    };
    this.baseState = this.state;
  }
  componentDidMount() {
    ScCommonServices.stateContents().then((contents) => {
      let questions = contents.result2.map((item, index) => {
        let tempItem = [];
        JSON.parse(item.options).map((opt) => {
          opt = { ...opt, userAnswer: index === 0 ? true : false };
          return tempItem.push(opt);
        });
        item.options = tempItem;
        return item;
      });
      let passvars;
      console.log("safestart survey launch url", window.location.href);
      if (window.location.href.indexOf("#") > -1) {
        // Mobile App 
        passvars = window.location.href.split("#")[1] || "";
        try {
          document.getElementsByTagName("nav")[0].hidden = true;
          document.getElementsByTagName("footer")[0].hidden = true;
          document.getElementById("main-content").style.marginTop = "10px";
        } catch (e) {

        }
      } else {
        passvars = this.props.location.state.passvars.split("#")[1] || "";
      }
      this.setState({
        cur_mod_index: 0,
        passvars: passvars,
        loadNext: true,
      });
      this.setInitialState();
    });
  }

  checkAnswerType = (item2) => {
    let sid = item2.sid;
    this.state.qanswers.map((objmod, modindex) => {
      objmod.questions.map((questions, questionindex) => {
        questions.options.map((item, index) => {
          if (item.sid == sid) {
            if (questions.answertype && questions.answertype.mode == "single") {
              questions.options.map((xitem, xindex) => {
                if (xitem.sid != sid) {
                  xitem.userAnswer = false;
                  if (xitem.type == "user_input") {
                    response[xitem.sid] = "";
                  }
                }
              });
            } else if (
              questions.answertype &&
              questions.answertype.mode == "singleormulti"
            ) {
              if (item.mode == "single") {
                questions.options.map((xitem, xindex) => {
                  if (xitem.sid != sid) {
                    xitem.userAnswer = false;
                    if (xitem.type == "user_input") {
                      response[xitem.sid] = "";
                    }
                  }
                });
              } else {
                questions.options.map((xitem, xindex) => {
                  if (xitem.sid != sid && xitem.mode == "single") {
                    xitem.userAnswer = false;
                    if (xitem.type == "user_input") {
                      response[xitem.sid] = "";
                    }
                  }
                });
              }
            }
          }
        });
      });
    });
  };
  getAnswers = (e, item2, item) => {
    scrollPoint = window.scrollY;
    this.checkAnswerType(item2);
    item.userAnswer = false;
    item2.userAnswer = e.target.checked;
    let qanswers = this.state.qanswers;
    if (item.answertype.mode == "single") {
      this.setState({ qanswers });
      setTimeout(() => {
        this.ok();
      }, 500);
    } else {
      this.setState({ qanswers });
    }
  };
  userInputChange = (e, item2) => {
    const enterVal = e.target.value.replace(/\n/g, " ");
    this.checkAnswerType(item2);
    this.setState({ [item2.sid]: enterVal });
    if (enterVal != "") {
      item2.userAnswer = true;
    } else {
      item2.userAnswer = false;
    }
    let qanswers = this.state.qanswers;
    this.setState({ qanswers });
  };
  scrollToSection = () => {
    scroller.scrollTo("test2", {
      duration: 800,
      delay: 0,
      smooth: "easeInQuad",
    });
  };

  setInitialState = () => {
    response = {
      cur_mod_index: 0,
      cur_mod_page_index: 0,
      submit: false,
      next: true,
      previous: false,
      loading: false,
    };
    let xqanswers = JSON.parse(JSON.stringify(obj_safestartsurvey));
    let passvarsValSplit = this.state.passvars ? this.state.passvars.split("_")[1] || "0" : "0";
    let displaycondvalue = passvarsValSplit.length === 1 ? passvarsValSplit : passvarsValSplit.split('~~')[0];
    let total_questions = 0;
    let qanswers = xqanswers.filter((objmod, modindex) => {
      if (
        isNaN(objmod.modid) == false &&
        (!objmod.displaycondition ||
          (objmod.displaycondition &&
            objmod.displaycondition.LAUNCHMODE == displaycondvalue))
      ) {
        objmod.questions = objmod.questions.filter(
          (questions, questionindex) => {
            if (
              questionindex >= 0 &&
              (!questions.displaycondition ||
                (questions.displaycondition &&
                  questions.displaycondition.LAUNCHMODE == displaycondvalue))
            ) {
              total_questions++;
              return questions;
            }
          }
        );
        return objmod;
      }
    });
    let total_endscreens = 0;
    let endscreens = xqanswers.filter((objmod, modindex) => {
      if (
        objmod.mode == "endscreen" &&
        (!objmod.displaycondition ||
          (objmod.displaycondition &&
            objmod.displaycondition.LAUNCHMODE == displaycondvalue))
      ) {
        objmod.contents = objmod.contents.filter((screens, screenindex) => {
          if (
            screenindex >= 0 &&
            (!screens.displaycondition ||
              (screens.displaycondition &&
                screens.displaycondition.LAUNCHMODE == displaycondvalue))
          ) {
            total_endscreens++;
            return screens;
          }
        });
        return objmod;
      }
    });

    let total_startscreens = 0;
    let startscreens = xqanswers.filter((objmod, modindex) => {
      if (
        objmod.mode == "startscreen" &&
        (!objmod.displaycondition ||
          (objmod.displaycondition &&
            objmod.displaycondition.LAUNCHMODE == displaycondvalue))
      ) {
        objmod.contents = objmod.contents.filter((screens, screenindex) => {
          if (
            screenindex >= 0 &&
            (!screens.displaycondition ||
              (screens.displaycondition &&
                screens.displaycondition.LAUNCHMODE == displaycondvalue))
          ) {
            total_startscreens++;
            return screens;
          }
        });
        return objmod;
      }
    });

    qanswers.map((objmod, modindex) => {
      objmod.questions.map((questions, questionindex) => {
        questions.qno = questionindex + 1;
        questions.userAnswer = false;
        questions.options.map((item, index) => {
          item.userAnswer = false;

          delete this.state[item.sid];
          if (item.type == "rating") {
            response[item.sid] = 0;
          } else if (item.type == "user_input") {
            response[item.sid] = "";
          }
        });
      });
    });
    response["qanswers"] = qanswers;
    response["endscreens"] = endscreens;
    response["startscreens"] = startscreens;
    if (total_startscreens > 0) {
      response["showscreen"] = "startscreens";
    } else {
      response["showscreen"] = "questions";
    }
    this.setState({
      total_questions: total_questions,
      total_endscreens: total_endscreens,
      total_startscreens: total_startscreens,
    });
    this.setState(response);
  };
  submitAnswer = () => {
    response = {};
    let resObj = [];
    console.log("this.state.qanswers", this.state.qanswers)
    this.state.qanswers.map((objmod, modindex) => {
      objmod.questions.map((questions, questionindex) => {
        let version = questions.version;
        questions.options.map((item, index) => {
          if (item.userAnswer === true) {
            if (item.type === "option") {
              let xresObj =
                '"' +
                item.sid +
                '":{"qid":"' +
                questions.sid +
                '"' +
                ',"vr":' +
                version +
                ',"ovl":' +
                1 +
                "}";
              resObj.push(xresObj);
            } else if (item.type === "user_input") {
              let xresObj =
                '"' +
                item.sid +
                '":{"qid":"' +
                questions.sid +
                '"' +
                ',"vr":' +
                version +
                ',"ovl":"' +
                this.state[item.sid].split('"').join('"') +
                '"}';
              resObj.push(xresObj);
            }
          } else if (item.type === "rating" && parseInt(item.userAnswer) >= 0) {
            let xresObj =
              '"' +
              item.sid +
              '":{"qid":"' +
              questions.sid +
              '"' +
              ',"vr":' +
              version +
              ',"ovl":' +
              item.userAnswer +
              "}";
            resObj.push(xresObj);
          }
        });
      });
    });
    let passvarsValSplit = this.state.passvars ? this.state.passvars.split("_")[1] || "0" : "0";
    let displaycondvalue = passvarsValSplit.length === 1 ? passvarsValSplit : passvarsValSplit.split('~~')[0];
    response.resObj = "{" + resObj.join(",") + "}";
    let datenew = new Date();
    datenew.setHours(datenew.getHours() + 5);
    datenew.setMinutes(datenew.getMinutes() + 30);
    let postData = {
      response,
      user_id: this.state.user_id || this.props.user_id,
      card_id: "SVYSAFESTART",
      time_stamp: datenew,
      activity_id: this.state.passvars.split("_")[0],
      launchmode: displaycondvalue.slice(0, 1),
      
    };
    this.setState({
      loading: true,
      surveysubmitted: true,
    });

    ScCommonServices.record_survey_response(postData)
      .then((response) => {
        scrollPoint = 0;
        this.setState(
          {
            show: true,
          }
        );
        setTimeout(() => {
          this.setState({
            show: false,
          });
          setTimeout(() => {
            if (this.state.passvars != "") {
              let xurl = window.location.href;
              xurl =
                xurl.substr(0, xurl.lastIndexOf("/safestart-survey")) +
                "/products";

              if (window.location.href.indexOf("#") > -1) {
                this.refreshURLToClose();
              } else {
                if (this.props.location.pathname.indexOf("/schome/") > -1) {
                  this.props.history.goBack();
                } else {
                  this.props.history.goBack();
                }
              }
            } else {
              window.scrollTo({ top: 0 });
            }
          }, 1000);
        }, 3000);
      })
      .catch((err) => {
        scrollPoint = 0;
        if (err) {
          console.log(err);
        }
      });
  };

  handleSelectedChange = (selected) => {
    this.setState({ selected });
  };

  stateDateHandler = (date) => {
    this.setState({ sssurvey_date: date });
  };

  handleRangeInMobile = (event, item) => {
    scrollPoint = window.scrollY;
    this.checkAnswerType(item);
    item.userAnswer = event;
    let qanswers = this.state.qanswers;
    this.setState({ qanswers });
    this.setState({ [item.sid]: event });
  };

  checkNext = (modindex, pageindex) => {
    let nextpageindex = pageindex + 1;
    let cur_mod_page_index = this.state.cur_mod_page_index + 1;
    let cur_mod_total_qtns = this.state.qanswers[modindex].questions.length;
    let cur_mod_total_page =
      parseInt(cur_mod_total_qtns / this.state.page_question_count) +
      (cur_mod_total_qtns % this.state.page_question_count > 0 ? 1 : 0);

    if (nextpageindex == cur_mod_total_page) {
      if (this.getNextModIndex(modindex) >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  getNextModIndex = (index) => {
    let nextindex = index + 1;
    let passvarsValSplit = this.state.passvars ? this.state.passvars.split("_")[1] || "0" : "0";
    let displaycondvalue = passvarsValSplit.length === 1 ? passvarsValSplit : passvarsValSplit.split('~~')[0];
    if (!this.state.qanswers[nextindex]) {
      return -1;
    } else if (this.state.qanswers[nextindex].displaycondition) {
      if (
        this.state.qanswers[nextindex].displaycondition.LAUNCHMODE ==
        displaycondvalue
      ) {
        return nextindex;
      } else {
        return -1;
      }
    } else {
      return nextindex;
    }
  };
  getNextModPageIndex = (modindex, pageindex) => {
    let nextpageindex = pageindex + 1;
    let cur_mod_page_index = this.state.cur_mod_page_index + 1;
    let cur_mod_total_qtns = this.state.qanswers[modindex].questions.length;
    let cur_mod_total_page =
      parseInt(cur_mod_total_qtns / this.state.page_question_count) +
      (cur_mod_total_qtns % this.state.page_question_count > 0 ? 1 : 0);
    if (nextpageindex == cur_mod_total_page) {
      return -1;
    } else {
      return nextpageindex;
    }
  };
  getPrevModIndex = (index) => {
    let previndex = index - 1;
    let passvarsValSplit = this.state.passvars ? this.state.passvars.split("_")[1] || "0" : "0";
    let displaycondvalue = passvarsValSplit.length === 1 ? passvarsValSplit : passvarsValSplit.split('~~')[0];
    if (this.state.qanswers[previndex].displaycondition) {
      if (
        this.state.qanswers[previndex].displaycondition.LAUNCHMODE ==
        displaycondvalue
      ) {
        return previndex;
      } else {
        return -1;
      }
    } else {
      return previndex;
    }
  };
  getPrevModPageIndex = (modindex, pageindex) => {
    let prevpageindex = pageindex - 1;
    if (prevpageindex < 0) {
      return -1;
    } else {
      return prevpageindex;
    }
  };
  ok = () => {
    if (document.getElementById("slide-qn")) {
      document.getElementById("slide-qn").scrollTop = 0;
    }
    if (this.state.showscreen == "questions") {
      let UARCondition = true;
      let qanswers = this.state.qanswers;
      qanswers.map((objmod, modindex) => {
        objmod.questions.map((questions, questionindex) => {
          let q_userAnswer = false;
          if (
            this.state.cur_mod_index == modindex &&
            questionindex == this.state.cur_mod_page_index
          ) {
            questions.options.map((item, index) => {
              if (item.userAnswer === true) {
                q_userAnswer = true;
              } else if (item.type === "rating" && item.userAnswer >= 0) {
                q_userAnswer = true;
              }
            });
            if (
              questions.answertype.required == true &&
              q_userAnswer == false
            ) {
              UARCondition = false;
              questions.errorMessage = this.props.labels.labels.LBLREQUIRED;
            } else {
              questions.errorMessage = "";
            }
            questions.userAnswer = q_userAnswer;
          }
        });
      });
      if (UARCondition == true) {
        this.setState({ qanswers });
        this.next();
      } else {
        this.setState({ qanswers });
      }
    } else {
      this.next();
    }
  };

  next = () => {
    this.setState({ loadNext: true });
    window.scrollTo({ top: 0 });
    if (document.getElementById("slide-qn")) {
      document.getElementById("slide-qn").scrollTop = 0;
    }
    if (this.state.showscreen == "questions") {
      this.state.qanswers.map((objmod, modindex) => {
        objmod.questions.map((questions, questionindex) => {
          if (
            this.state.cur_mod_index == modindex &&
            questionindex == this.state.cur_mod_page_index
          ) {
            if (questions.userAnswer === true) {
            } else if (questions.userAnswer === false) {
              questions.options.map((item, index) => {
                item.userAnswer = false;
                delete this.state[item.sid];
              });
            }
          }
        });
      });
      let qanswers = this.state.qanswers;
      this.setState({ qanswers });
      let nextModPageIndex = this.getNextModPageIndex(
        this.state.cur_mod_index,
        this.state.cur_mod_page_index
      );
      if (nextModPageIndex >= 0) {
        this.setState((state) => ({
          cur_mod_page_index: nextModPageIndex,
          previous: true,
          next: this.checkNext(this.state.cur_mod_index, nextModPageIndex),
          submit: !this.checkNext(this.state.cur_mod_index, nextModPageIndex),
        }));
      } else {
        let nextModIndex = this.getNextModIndex(this.state.cur_mod_index);
        if (nextModIndex >= 0) {
          this.setState((state) => ({
            cur_mod_index: nextModIndex,
            cur_mod_page_index: 0,
            previous: true,
            next: this.checkNext(nextModIndex, 0),
            submit: !this.checkNext(nextModIndex, 0),
          }));
        } else {
          this.setState((state) => ({
            previous: true,
            next: false,
            submit: true,
          }));
        }
      }
    } else if (this.state.showscreen == "startscreens") {
      let next_startscreen_page_index =
        this.state.cur_startscreen_page_index + 1;
      if (next_startscreen_page_index == this.state.total_startscreens) {
        this.setState({
          showscreen: "questions",
        });
      } else {
        this.setState({
          cur_startscreen_page_index: next_startscreen_page_index,
        });
      }
    } else if (this.state.showscreen == "endscreens") {
      let next_endscreen_page_index = this.state.cur_endscreen_page_index + 1;
      if (next_endscreen_page_index == this.state.total_endscreens) {
        setTimeout(() => {
          if (this.state.surveysubmitted == false) {
            this.setState({ submit: true });
          }
        }, 500);
      } else {
        this.setState({
          cur_endscreen_page_index: next_endscreen_page_index,
        });
      }
    }
  };

  previous = () => {
    this.setState({ loadNext: false });
    window.scrollTo({ top: 0 });
    if (document.getElementById("slide-qn")) {
      document.getElementById("slide-qn").scrollTop = 0;
    }
    this.state.qanswers.map((objmod, modindex) => {
      objmod.questions.map((questions, questionindex) => {
        if (
          questions.userAnswer === false &&
          this.state.cur_mod_index == modindex &&
          questionindex == this.state.cur_mod_page_index
        ) {
          questions.options.map((item, index) => {
            item.userAnswer = false;
            delete this.state[item.sid];
          });
        }
      });
    });
    let qanswers = this.state.qanswers;
    this.setState({ qanswers });

    let prevModPageIndex = this.getPrevModPageIndex(
      this.state.cur_mod_index,
      this.state.cur_mod_page_index
    );
    if (prevModPageIndex >= 0) {
      this.setState((state) => ({
        cur_mod_page_index: prevModPageIndex,
        previous:
          prevModPageIndex > 0
            ? true
            : this.state.cur_mod_index > 0
              ? true
              : false,
        next: true,
      }));
    } else {
      let prevModIndex = this.getPrevModIndex(this.state.cur_mod_index);
      if (prevModIndex >= 0) {
        let cur_mod_total_qtns =
          this.state.qanswers[prevModIndex].questions.length;
        let cur_mod_total_page =
          parseInt(cur_mod_total_qtns / this.state.page_question_count) +
          (cur_mod_total_qtns % this.state.page_question_count > 0 ? 1 : 0);
        this.setState((state) => ({
          cur_mod_index: prevModIndex,
          cur_mod_page_index: cur_mod_total_page - 1,
          previous: true,
          next: true,
          submit: false,
        }));
      } else {
        this.setState((state) => ({
          previous: false,
          next: true,
        }));
      }
    }
  };

  closeModal = () => {
    this.setState({ show: false, loading: true }, () => {
      setTimeout(() => {
        window.scrollTo({ top: 0 });
      }, 3000);
    });
  };

  renderCheckBox = (item2, item) => {
    const { labels } = this.props;
    return (
      <label
        class="check-lbl bg-opts ssi-svy-opt"
      >
        <span class="rys-textTwo">{labels.labels[item2.label]}</span>
        <input
          type="checkbox"
          value={item2.sid}
          onClick={(e) => this.getAnswers(e, item2, item)}
          checked={item2.userAnswer}
        />
        <span
          class="checkmark"
          style={{
            borderRadius: item.answertype.mode != "single" ? "5px" : "default",
          }}
        ></span>
      </label>
    );
  };
  renderInputRange = (item2) => {
    const { labels } = this.props;
    return (
      <div className="rate-range rate-range1" style={{ width: "50%" }}>
        <div className="form-group">
          <h4>
            {this.state.loading === false ? (
              labels.labels[item2.label]
            ) : (
              <Skeleton height={21} />
            )}
          </h4>
          <div className="form-group">
            {this.state.loading === false ? (
              <>
                <InputRange
                  maxValue={6}
                  minValue={0}
                  value={this.state[item2.sid] === undefined ? 0 : this.state[item2.sid]}
                  onChange={(e) => this.handleRangeInMobile(e, item2)}
                />
              </>
            ) : (
              <Skeleton height={16} />
            )}
          </div>
        </div>
      </div>
    );
  };
  renderElement = (item2, item) => {
    const { labels } = this.props;
    return <div className="rateIfield">{labels.labels[item2.label]}</div>;
  };
  renderInputText = (item2) => {
    const { labels } = this.props;
    return (
      <div className="rateIfield">
        {item2.label && item2.label != "" ? (
          <>
            <label>{labels.labels.LBLOTHER} :</label>
          </>
        ) : (
          <></>
        )}
        <textarea
          rows="2"
          className="input-field text-brdclr"
          style={{width: "100%"}}
          onChange={(e) => this.userInputChange(e, item2)}
          value={this.state[item2.sid]}
        ></textarea>
      </div>
    );
  };
  refreshURLToClose = () => {
    let xurl = window.location.href;
    window.location.href = xurl.concat("/close");
  };
  render() {
    const { qanswers, endscreens, startscreens } = this.state;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      touchMove: false,
    };
    var todayDate = this.state.sssurvey_date.toLocaleDateString("en-GB"); //this.props.language_code
    let todate = new Date();
    todate.setDate(todate.getDate() - 30);
    const { labels } = this.props;
    let cur_mod_index = this.state.cur_mod_index;
    let cur_mod_page_index = this.state.cur_mod_page_index;
    let page_question_count = this.state.page_question_count;
    let passvarsValSplit = this.state.passvars ? this.state.passvars.split("_")[1] || "0" : "0";
    let displaycondvalue = passvarsValSplit.length === 1 ? passvarsValSplit : passvarsValSplit.split('~~')[0];
    let UARCondition = true;
    let xconsole = "1";
    let total_answered_question = 0;
    let qcount = 0;
    let cur_question = 0;
    this.state.qanswers.map((objmod, modindex) => {
      objmod.questions.map((questions, questionindex) => {
        qcount++;
        if (
          this.state.cur_mod_index == modindex &&
          questionindex == this.state.cur_mod_page_index
        ) {
          cur_question = qcount;
          if (
            questions.answertype.required == true &&
            questions.userAnswer === false
          ) {
            xconsole = "2";
            UARCondition = false;
          }
        }

        if (questions.userAnswer === true) {
          total_answered_question++;
        }
      });
    });
    let completed = parseInt(
      (total_answered_question * 100) / this.state.total_questions
      );
      {console.log(completed,"completed")}

    let checkNext_CQ = () => {
      if (this.state.next == true && UARCondition == true) {
        return true;
      } else {
        return false;
      }
    };
    let showscreen = this.state.showscreen;

    let cur_startscreen_page_index = this.state.cur_startscreen_page_index;
    let page_startscreen_count = this.state.page_startscreen_count;
    let cur_endscreen_page_index = this.state.cur_endscreen_page_index;
    let page_endscreen_count = this.state.page_endscreen_count;
    if (
      this.state.showscreen == "questions" &&
      completed == 100 &&
      cur_question == this.state.total_questions
    ) {
      setTimeout(() => {
        if (this.state.surveysubmitted == false) {
          this.setState({ showscreen: "endscreens" });
        }
      }, 500);
    }
    return (
      <div className="ssi-svy">
        <div className="page-header-field">
          <h3 className="page-title" style={{marginBottom: "0px"}}>{labels.labels.LBLSAFESTARTSURVEY}</h3>
          <button
            onClick={() => {
              window.location.href.indexOf("#") > -1
                ? this.refreshURLToClose()
                : this.props.history.goBack();
            }}
            title={this.props.labels.labels.LBLCLOSE}
          >
            <span>×</span>
          </button>
        </div>
        <hr className="head-line" />
        <div className="card-box rateSection">
          <div className="row rate-not-xs ssi-svy-res">
            {this.state.loading === false ? (
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <section className="rateCard">
                  <div className="ratecardSection" style={{ height: "100%" }}>
                    <h3
                      className="text-center"
                      style={{ marginBottom: "18px" }}
                    >
                      {labels.labels[qanswers[cur_mod_index].titlelabel]}
                    </h3>
                    {showscreen == "questions" ? (
                      <>
                        <div
                          className="rate-slide qa-rate-board qa-rate-board"
                          id="slide-qn"
                        >
                          {qanswers[cur_mod_index].questions
                            .slice(
                              cur_mod_page_index * page_question_count,
                              cur_mod_page_index * page_question_count +
                              page_question_count
                            )
                            .map((item) => {
                              if (
                                !item.displaycondition ||
                                (item.displaycondition &&
                                  item.displaycondition.LAUNCHMODE ==
                                  displaycondvalue)
                              ) {
                                return (
                                  <div
                                    className={
                                      "qaCardbd " +
                                      `${this.state.loadNext
                                        ? "distractor-nxt"
                                        : "distractor-prev"
                                      }` // + cur_mod_page_index
                                    }
                                    key={cur_mod_page_index}
                                  >
                                    <h4 style={{ marginBottom: "18px" }}>
                                      {item.qno}. {labels.labels[item.label]}
                                    </h4>
                                    <div>
                                      {item.options.map((item2, index2) => {
                                        if (item2.type == "option") {
                                          return this.renderCheckBox(
                                            item2,
                                            item
                                          );
                                        } else if (item2.type == "user_input") {
                                          return this.renderInputText(
                                            item2,
                                            item
                                          );
                                        } else if (item2.type == "rating") {
                                          return this.renderInputRange(
                                            item2,
                                            item
                                          );
                                        }
                                      })}
                                    </div>
                                    <p>
                                      {item.errorMessage &&
                                        item.errorMessage != "" ? (
                                        <>
                                          <div
                                            style={{
                                              color: "red",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            {item.errorMessage}
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <button
                                        className="button-style primary-btn btn-ok"
                                        onClick={this.ok}
                                      >
                                        {labels.labels.LBLOK}
                                      </button>
                                    </p>
                                  </div>
                                );
                              } else {
                                return <></>;
                              }
                            })}
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}
                    {showscreen == "startscreens" ? (
                      <>
                        <div className="rate-slide qa-rate-board qa-rate-board">
                          {startscreens[0].contents
                            .slice(
                              cur_startscreen_page_index *
                              page_startscreen_count,
                              cur_startscreen_page_index *
                              page_startscreen_count +
                              page_startscreen_count
                            )
                            .map((item) => {
                              if (
                                !item.displaycondition ||
                                (item.displaycondition &&
                                  item.displaycondition.LAUNCHMODE ==
                                  displaycondvalue)
                              ) {
                                return (
                                  <div
                                    className={
                                      "qaCardbd " +
                                      `${this.state.loadNext
                                        ? "distractor-nxt"
                                        : "distractor-prev"
                                      }` // + cur_mod_page_index
                                    }
                                    key={cur_mod_page_index}
                                    style={{ border: "0px solid" }}
                                  >
                                    <h4 style={{ marginBottom: "18px" }}>
                                      {labels.labels[item.label]}
                                    </h4>
                                    <p>
                                      {item.elements.map((item2, index2) => {
                                        if (item2.type == "text") {
                                          return this.renderElement(
                                            item2,
                                            item
                                          );
                                        }
                                      })}
                                    </p>
                                    <p>
                                      {item.errorMessage &&
                                        item.errorMessage != "" ? (
                                        <>
                                          <div
                                            style={{
                                              color: "red",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            {item.errorMessage}
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <button
                                        className="button-style primary-btn btn-ok"
                                        onClick={this.ok}
                                        style={{ width: "auto" }}
                                      >
                                        {labels.labels.LBLCONTINUE}
                                      </button>
                                    </p>
                                  </div>
                                );
                              } else {
                                return <></>;
                              }
                            })}
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}
                    {showscreen == "endscreens" ? (
                      <>
                        <div className="rate-slide qa-rate-board qa-rate-board">
                          {endscreens[0].contents
                            .slice(
                              cur_endscreen_page_index * page_endscreen_count,
                              cur_endscreen_page_index * page_endscreen_count +
                              page_endscreen_count
                            )
                            .map((item) => {
                              if (
                                !item.displaycondition ||
                                (item.displaycondition &&
                                  item.displaycondition.LAUNCHMODE ==
                                  displaycondvalue)
                              ) {
                                return (
                                  <div
                                    className={
                                      "qaCardbd " +
                                      `${this.state.loadNext
                                        ? "distractor-nxt"
                                        : "distractor-prev"
                                      }` // + cur_mod_page_index
                                    }
                                    key={cur_mod_page_index}
                                  >
                                    <h4 style={{ marginBottom: "18px" }}>
                                      {labels.labels[item.label]}
                                    </h4>
                                    <p>
                                      {item.elements.map((item2, index2) => {
                                        if (item2.type == "text") {
                                          return this.renderElement(
                                            item2,
                                            item
                                          );
                                        }
                                      })}
                                    </p>
                                    <p>
                                      {item.errorMessage &&
                                        item.errorMessage != "" ? (
                                        <>
                                          <div
                                            style={{
                                              color: "red",
                                              paddingLeft: "20px",
                                            }}
                                          >
                                            {item.errorMessage}
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {cur_endscreen_page_index ==
                                        page_endscreen_count - 1 ? (
                                        <button
                                          className="button-style primary-btn btn-ok"
                                          onClick={this.submitAnswer}
                                          style={{ width: "auto" }}
                                        >
                                          {labels.labels.LBLSUBMIT}
                                        </button>
                                      ) : (
                                        <button
                                          className="button-style primary-btn btn-ok"
                                          onClick={this.ok}
                                          style={{ width: "auto" }}
                                        >
                                          {labels.labels.LBLCONTINUE}
                                        </button>
                                      )}
                                    </p>
                                  </div>
                                );
                              } else {
                                return <></>;
                              }
                            })}
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}
                  </div>
                  {showscreen == "questions" ? (
                    <>
                      <div
                        className="rate-footer"
                        style={{ borderTop: "none" }}
                      >
                        <div
                          className="row col-sm-3"
                          style={{
                            marginTop: "13px",
                            backgroundColor: "#dedede",
                          }}
                        >
                          {completed <= 100 ? (
                            <>
                              <h5>
                                {completed}% {labels.labels.LBLCOMPLETED}
                              </h5>
                              <div
                                className="progress"
                                style={{ height: "3px !important" }}
                              >
                                <div
                                  className="progress-bar bg-play"
                                  role="progressbar"
                                  style={{
                                    width: completed + "%",
                                  }}
                                  aria-valuenow={completed}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <button
                          className="col-sm-1 btn btn-sm"
                          style={{
                            cursor: "default",
                            fontWeight: "bold",
                            color: "black",
                          }}
                          disabled={true}
                        >
                          {cur_question} / {this.state.total_questions}
                        </button>
                        <button
                          className={
                            !this.state.previous
                              ? "btn btn-secondary btn-sm btn-prev-sec"
                              : "btn btn-primary btn-sm btn-prev"
                          }
                          style={{
                            background: !this.state.previous
                              ? "#717172"
                              : "#0a0a0a",
                          }}
                          onClick={this.previous}
                          disabled={!this.state.previous}
                          title={labels.labels.LBLPREVIOUS}
                        >
                          <img src="../images/up-arrow.png" />
                        </button>
                        <button
                          className={
                            !checkNext_CQ()
                              ? "btn btn-secondary btn-sm btn-nxt-sec"
                              : "btn btn-primary btn-sm btn-nxt"
                          }
                          style={{
                            background: !this.state.next
                              ? "#717172"
                              : "#0a0a0a",
                          }}
                          onClick={this.next}
                          disabled={!checkNext_CQ()}
                          title={labels.labels.LBLNEXT}
                        >
                          <img src="../images/down-arrow.png" />
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </section>
              </div>
            ) : (
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <section className="rateCard">
                  <Loader />
                </section>
              </div>
            )}
          </div>
          <Modal show={this.state.show} onHide={this.closeModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <h4 className="modal-title" style={{ lineHeight: "2.5" }}>
                  {labels.labels.LBLSAFESTARTSURVEY}
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <div className="row">
                  <div className="col-xl-12 rate-sub-card">
                    <div class="center">
                      <label class="label">
                        <input class="label__checkbox" type="checkbox" />
                        <span class="label__text">
                          <span class="label__check">
                            <i class="fa fa-check icon"></i>
                          </span>
                        </span>
                      </label>
                    </div>
                    <h2>{labels.labels.LBLSVYALERTHDR}</h2>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    user_id: state.user_info_reducer.user_info.user_id,
    language_code: state.user_info_reducer.user_info.language_code,
    dateformat: state.user_info_reducer.user_info.dateformat,
  };
};
export default connect(mapStateToProps, null)(SafeStartSurvey);

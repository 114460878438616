import React, { useEffect, useState } from "react";
import sso_services from '../services/sso_services';
const CognitoAuth = (props) => {
  let location= window.location;
  let origin;
  if(location.hostname ==="localhost")
    origin = "https://"+location.host;
  else
    origin = location.origin;

  const redirecttoCognitoLogin = () => {
    sso_services.getSSOConnectionCreds(location.origin, "cognito_conn_creds").then((responseurl) => {
      if(responseurl != "" && responseurl != undefined){
        window.location.href = responseurl;
      }
    });
  }
  return (
    <>
    <a onClick={redirecttoCognitoLogin}
      className="btn btn-block sso-btn"
    >
      <span className="sso-btn-text">
      {props.labels.labels.LBLSIGNINWITHSSO}
      </span>
    </a>
    </>)

}

export default CognitoAuth;
import React from "react";
import "../.././App.scss";

class Loader extends React.Component {
  render() {
    return (
      <>
         <div className={localStorage.getItem('site_type')==='SafeStart'?"loader mx-auto mt-5": "greenloader mx-auto mt-5"}/> 
      </>
    );
  }
}
export default Loader;

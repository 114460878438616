import React from "react";
import { connect } from "react-redux";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent"; 
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services"; 
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';  
import Checkbox from "@material-ui/core/Checkbox";
import Switch from '@material-ui/core/Switch';  
import "date-fns";
import {  styled } from '@material-ui/styles';
import "./steercomconfiguration.scss";
import Skeleton from "react-loading-skeleton";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const $ = require("jquery");
const GreenSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:  '#38c16f',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#38c16f',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#CDCDCD',
    opacity: 1,
  },
}));
class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },  
      day1:false,
      day2:false,
      day3:false,
      day4:false,
      day5:false,
      day6:false,
      day7:false,
      selectedDays:{},
      overdueType:2,
      overdue_notifications:[{ id: "EVERYDAY", name: props.labels.labels.LBLEVERYDAY? props.labels.labels.LBLEVERYDAY:"Every Day" }],
      overdueFrequency: "EVERYDAY",
      openalert: false,
      loading:false,
      showEmailConfig:false,
      adminassign:false,adminunassign:false,taskconfig:false,taskassign:false,
      taskdue2day:false,taskduetoday:false,taskoverdue:false,taskstatuschange:false,
      usertaskstatuschange:false, usertaskassign:false, usertaskunassign:false, 
      usertaskoverdue:true,  usertaskduetoday:false,  usertaskduetomorrow:false,
      active:1          
    };
  }
  componentWillUnmount() {
    $("#root").css("height", "100%");
    $("#root").css("overflow", "auto");
  }
  componentDidMount() {
    $('#root').scrollTop(0);  
    this.addSheduledNotifyTypes(); 
    this.getEmailSettings();
  }
  addSheduledNotifyTypes=()=>{
    let {LBLEVERYDAY,LBLTWOINWEEKS,LBLWEEKLY,LBLONCEINMONTHS} = this.props.labels.labels;
    LBLEVERYDAY = LBLEVERYDAY?LBLEVERYDAY:"Every Day";
    LBLTWOINWEEKS = LBLTWOINWEEKS?LBLTWOINWEEKS:"Two days once";
    LBLWEEKLY = LBLWEEKLY?LBLWEEKLY:"Weekly";
    LBLONCEINMONTHS = LBLONCEINMONTHS?LBLONCEINMONTHS:"Once in a Month";
    let  overdue_notifications = [
      { id: "EVERYDAY", name: LBLEVERYDAY },
      { id: "TWOINWEEKS", name: LBLTWOINWEEKS },
      { id: "WEEKLY", name: LBLWEEKLY},
      { id: "ONCEINMONTHS", name: LBLONCEINMONTHS }];
     this.setState({
      overdue_notifications:overdue_notifications, 
     })
  } 
  setContent = () => {
    setTimeout(() => {
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("aside").addClass("slideOutLeft");
      $("#sidenav").css({ display: "none" });
    }, 1500);
  };
  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    for (let x in steercomSettings) {
      steercomSettings[x].map((item, index) => {
        if (item.name && item.name.indexOf("LBL") == 0) {
          item.name = this.getLabel(item.name);
        }
        if (item.title && item.title.indexOf("LBL") == 0) {
          item.title = this.getLabel(item.title);
        }
        if (item.description && item.description.indexOf("LBL") == 0) {
          item.description = this.getLabel(item.description);
        }
        return null;
      });
    }
    if (this.props.location.state && this.props.location.state.successFactorData) {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: this.props.location.state.successFactorData,
      });
    } else {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: steercomSettings.successfactors[0],
      });
    }

  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  }; 
  getLabel = (xfield, mode) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if(xfield!=undefined){
      if (
        typeof xfield === "object" &&
        this.props.labels.labels.hasOwnProperty(xfield.label)
      ) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield + "111";
      }
    }else{
      return "";
    }
    }

  };
  getEmailSettings=async()=>{
    let response=  await SteercomServices.getEmailSettings();
    console.log("response.data.result", response.result[0]);
    let email_settings= response.result[0];
    if(email_settings.active){
        $(".cls_content_container").css({ "pointer-events": "all",opacity:"1"  });
    }else{
        $(".cls_content_container").css({ "pointer-events": "none",opacity:".5" });
    }
    this.setState({ 
      email_settings:email_settings,
      adminassign:email_settings.team_admin_assign==1?true:false,
      adminunassign:email_settings.team_admin_unassign==1?true:false,
      taskconfig:email_settings.task_config==1?true:false,
      taskassign:email_settings.task_assign==1?true:false,
      taskstatuschange:email_settings.task_status_change==1?true:false,

      taskdue2day:email_settings.task_due_2day==1?true:false,
      taskduetoday:email_settings.task_due_today==1?true:false,
      taskoverdue:email_settings.task_overdue==1?true:false, 
      
      usertaskassign:email_settings.user_task_assign==1?true:false, 
      usertaskunassign:email_settings.user_task_unassign==1?true:false, 
      usertaskstatuschange:email_settings.user_task_status_change==1?true:false, 

      usertaskduetomorrow:email_settings.user_task_due_tomorrow==1?true:false,  
      usertaskduetoday:email_settings.user_task_due_today==1?true:false,  
      usertaskoverdue:email_settings.user_task_overdue,
      active:email_settings.active,
      overdueType:email_settings.user_task_overdue==1?2:email_settings.user_task_overdue,
      selectedDays:email_settings.user_overdue_week?JSON.parse(email_settings.user_overdue_week):{}, 
      overdueFrequency:"EVERYDAY"
    }); 

    // let xx= this.state;
  }
  updateEmailSettings=async(alldata)=>{
    let { adminassign,adminunassign,taskconfig,taskassign,taskstatuschange,
        taskdue2day,taskduetoday,taskoverdue,
        usertaskassign,usertaskunassign,usertaskstatuschange,selectedDays,
        usertaskduetomorrow,usertaskduetoday,usertaskoverdue,active} = this.state;

   let usertask_overdue = usertaskoverdue ;
   let selected_DaysAll = usertask_overdue==3?selectedDays:{};
   let data={
    team_admin_assign: adminassign==true?1:0,
    team_admin_unassign: adminunassign==true?1:0,
    task_config: taskconfig==true?1:0,
    task_assign: taskassign==true?1:0,
    task_status_change: taskstatuschange==true?1:0,

    task_due_2day:taskdue2day==true?1:0,
    task_due_today:taskduetoday==true?1:0,
    task_overdue:taskoverdue==true?1:0,

    user_task_assign: usertaskassign==true?1:0,
    user_task_unassign: usertaskunassign==true?1:0,
    user_task_status_change: usertaskstatuschange==true?1:0,

    user_task_due_tomorrow: usertaskduetomorrow==true?1:0,
    user_task_due_today: usertaskduetoday==true?1:0,
    user_task_overdue: usertask_overdue,
    active: active==true?1:0,
    user_overdue_week:JSON.stringify(selected_DaysAll),
    updated_by :this.props.user_id, 
   };
    
   if(this.checkvalidation()){
      let response=  await SteercomServices.updateEmailSettings(data);
      console.log("response.data.result", response.result);
      //let email_settings= response.result;
      if ( response.status == "SUCCESS") {
        this.showAlert("success", this.props.labels.labels.LBLMAILCONFIGUPDTSUCCESS);
      }
    }else{
      let label= this.props.labels.labels.LBLMAILCONFIGWEEK;
      this.showAlert("warning", label?label:"Please select atleast one day");
    }
  }
  resetEmailSettings=()=>{ 
    let {email_settings}= this.state;
    this.setState({  
      adminassign:email_settings.team_admin_assign==1?true:false,
      adminunassign:email_settings.team_admin_unassign==1?true:false,
      taskconfig:email_settings.task_config==1?true:false,
      taskassign:email_settings.task_assign==1?true:false,
      taskstatuschange:email_settings.task_status_change==1?true:false,

      taskdue2day:email_settings.task_due_2day==1?true:false,
      taskduetoday:email_settings.task_due_today==1?true:false,
      taskoverdue:email_settings.task_overdue==1?true:false,
   
      usertaskassign:email_settings.user_task_assign==1?true:false, 
      usertaskunassign:email_settings.user_task_unassign==1?true:false, 
      usertaskstatuschange:email_settings.user_task_status_change==1?true:false, 

      usertaskduetomorrow:email_settings.user_task_due_tomorrow==1?true:false,  
      usertaskduetoday:email_settings.user_task_due_today==1?true:false,  
      usertaskoverdue:email_settings.user_task_overdue  ,
      selectedDays:email_settings.user_overdue_week?JSON.parse(email_settings.user_overdue_week):{}, 
      active:email_settings.active
    }); 
  }
  checkvalidation=()=>{ 
     let {selectedDays,usertaskoverdue} = this.state;
     let retVal=usertaskoverdue==3?false:true;
     if(usertaskoverdue==3){ 
      if(Object.keys(selectedDays).length>0){
        for (let item in selectedDays) {
          if(selectedDays[item]==1){
            retVal=true;
          }
        } 
      } 
     }
     return retVal;
  }
  showAlert = (messageType, message) => {
    this.setState({
      openalert: true,
      messageType: messageType,
      message: message
    });
  };
  handleSwitchChange=(val,teamdata)=>{
    // alert(idx)
    let stats,value; 
    value = val.currentTarget.value;
     
    stats = value==true||value=="true"||value=='true'?false:true;  
    //alert(stats)
    if(stats){
        $(".cls_content_container").css({ "pointer-events": "all", opacity:"1" });
    }else{
        $(".cls_content_container").css({ "pointer-events": "none",opacity:".5"  });
    }
      this.setState({
        active:stats
      })
  } 
  handleClose = () => {
    this.setState({ openalert: false });
  };
  handleChange=(event)=>{
    let value = event.target.checked;
    let name = event.target.name;
    if(name=='usertaskoverdue' && value)
     { 
      value = value + 1;
      this.setState({
        usertaskoverdue:value 
      });
    }else{ 
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
      });
    }
  }  
  handleNotifyFrequencyChange = (event, values) => {
    this.setState({
      overdueFrequency: values.id,
    });
  };
  handleNotifyType =(val,teamdata)=>{
    if(val.target.value){
    this.setState({ overdueType:  val.target.value, usertaskoverdue : val.target.value});
    }
  }
  handleNotifyWeek =(val,teamdata)=>{
    let {selectedDays}=this.state;
    selectedDays[val.target.id] = val.target.checked?1:0; 
    this.setState({ selectedDays:  selectedDays  });
  }

  render() {
    let horizontal = "top",vertical = "center";
    let {
           LBLSCOMCONFIG,LBLTASKDUEFORSTARTDATE,LBLNO,LBLAGREE,LBLUNSAVEDATA,
           LBLTEAMADMINASSIGN,LBLTEAMADMINUNASSIGN,
           LBLTASKASSIGNMENT,LBLTASKDUE1DAYSBEFORE,LBLTASKTODAY,LBLTASKOVERDUE,LBLTASKSTATUSCHANGE ,
           LBLTASKUNASSIGNMENT,LBLUPDATESETTINGS,LBLRESET,LBLNOTIFICATIONENABLED,
           LBLSETNOTIFYCONFIG,LBLADMINISTRATOR,LBLUSERS,LBLSCHREMNOTIFY,LBLNOTIFYFREQ,
           LBLDAILY, LBLWEEKLY, LBLMONTHLY,
           LBLMONDAY, LBLTUESDAY, LBLWEDNESDAY, LBLTHURSDAY, LBLFRIDAY, LBLSATURDAY, LBLSUNDAY
         } = this.props.labels.labels; 
          LBLMONDAY = LBLMONDAY?LBLMONDAY:"Monday";
          LBLTUESDAY = LBLTUESDAY?LBLTUESDAY:"Tuesday"; 
          LBLWEDNESDAY = LBLWEDNESDAY?LBLWEDNESDAY:"Wednesday"; 
          LBLTHURSDAY = LBLTHURSDAY?LBLTHURSDAY:"Thursday"; 
          LBLFRIDAY = LBLFRIDAY?LBLFRIDAY:"Friday"; 
          LBLSATURDAY = LBLSATURDAY?LBLSATURDAY:"Saturday"; 
          LBLSUNDAY = LBLSUNDAY?LBLSUNDAY:"Sunday";
         LBLDAILY = LBLDAILY?LBLDAILY:"Daily";
         LBLWEEKLY = LBLWEEKLY?LBLWEEKLY:"Weekly";
         LBLMONTHLY=LBLMONTHLY?LBLMONTHLY:"Monthly";
         LBLTASKDUE1DAYSBEFORE=LBLTASKDUE1DAYSBEFORE?LBLTASKDUE1DAYSBEFORE:"One day prior to Start Date";
         LBLNOTIFYFREQ = LBLNOTIFYFREQ?LBLNOTIFYFREQ:"Frequency";
         LBLTASKDUEFORSTARTDATE = LBLTASKDUEFORSTARTDATE?LBLTASKDUEFORSTARTDATE:"Task Dues";

         const { adminassign,adminunassign,selectedDays,
                taskstatuschange, usertaskassign,  usertaskunassign,
                usertaskoverdue,  usertaskduetoday,  usertaskduetomorrow 
          } = this.state;         
 
 
    return (
      <div className="mob-pad steercom-configuration">
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage>
        <Snackbar
            style={{ width: "43%", margin: "0% 30%", justifyItems: "center" }}
            anchorOrigin={{ horizontal, vertical }}
            key={"top-center"}
            open={this.state.openalert}
            autoHideDuration={
            this.state.showtime ? this.state.showtime : 2000
            }
            onClose={this.handleClose}
            bodyStyle={{ backgroundColor: "teal", color: "coral" }}
        >
            <Alert
            variant="filled"
            onClose={this.handleClose}
            severity={this.state.messageType}
            >
             
                <>{this.state.message}  
                </>
          
            </Alert>
        </Snackbar>
           
        <div id="dialog3">
          <Dialog className="cls_dialog"
            open={this.state.opendialog3}
            onClose={this.handleCloseDialog3}
            aria-labelledby="responsive-dialog-title"
            contentStyle={{ width: "100%", minWidth: "500px" }}
          >
            <div style={{ width: "500px", backgroundColor: "#FCEDEA" }}>
              <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ fontSize: "16px" }} className="cls_reset_all">{LBLSCOMCONFIG} </div>
                  <i style={{ margin: "0px 13px", color: "#ff4040", float: "right" }} class="fas fa-exclamation-triangle cls_reset_img"></i>
                </div>
              </DialogTitle> 
              <DialogContent>
                <DialogContentText style={{ color: "white" }}>
                  <div className="cls_confirm_details" style={{ color: "#313236" }}> {LBLUNSAVEDATA} </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button autoFocus id="1" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog3} color="primary">
                  {LBLNO}
                </Button>
                <Button id="2" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog3} color="primary">
                  {LBLAGREE}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
 
            {this.state.loading === false ? (
               <>
               <div className="cls_config_main_container">
               <div className="row">
                  <div className="col-sm-10 col-lg-10 col-xl-10">
                   <span className="cls_mail_titile">{LBLNOTIFICATIONENABLED}</span>
                  </div>
                
                  <div className="col-sm-2 col-lg-2 col-xl-2 cls-rad-mail" style={{marginBottom:"4px",display:"block"}}>
                  <GreenSwitch
                    id={"mailenable"}
                    checked={this.state.active}
                    value={this.state.active}
                    onChange={(e)=>this.handleSwitchChange(e,this)}
                    color="primary"  
                  />
                  </div>
                </div>
                <hr/>
                <div className="cls_content_container">
                <div className="">
                <span className="cls_mail_titile">{LBLSETNOTIFYCONFIG}</span>
               </div>
               <br></br>
               <div className="row cls_container_box"> 
                 <div className="cls_admin col-sm-6">
                      <div className="cls_title_box">
                      <span className="cls_mail_subtitile"> {LBLADMINISTRATOR}</span> 
                      </div>                   
                      <hr/>
                      <div className="cls_box_container" style={{marginLeft:"10px"}}>
                          <div className="col-sm-66">
                          <Box sx={{ display: 'flex' }}>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                              <FormGroup> 
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={adminassign} onChange={this.handleChange} name="adminassign" />
                                  } 
                                  label={ 
                                    <span className="cls_task_name">{LBLTEAMADMINASSIGN}</span>
                                  }
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={adminunassign} onChange={this.handleChange} name="adminunassign" />
                                  }
                                  label={ 
                                    <span className="cls_task_name">{LBLTEAMADMINUNASSIGN}</span>
                                  }
                                />
    
                                  <FormControlLabel
                                  control={
                                    <Checkbox checked={taskstatuschange} onChange={this.handleChange} name="taskstatuschange" />
                                  }
                                  label={ 
                                    <span className="cls_task_name">{LBLTASKSTATUSCHANGE}</span>
                                  }
                                  
                                />
                                <br></br>
                                
                              </FormGroup> 
                            </FormControl> 
                          </Box>
                          </div> 
                      </div>
                 </div>
                 <div className="cls_users col-sm-6">
                    <div className="cls_title_box">
                    <span className="cls_mail_subtitile"> {LBLUSERS}</span>
                    </div>
                    <hr/>
                    <div className="rows" style={{marginLeft:"10px"}}>
                    <div className="col-sm-66">
                    <Box sx={{ display: 'flex' }}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormGroup>
                          
                          <FormControlLabel
                            control={
                              <Checkbox checked={usertaskassign} onChange={this.handleChange} name="usertaskassign" />
                            }
                            label={ 
                              <span className="cls_task_name">{LBLTASKASSIGNMENT}</span>
                            }
                            
                          />
                          <FormControlLabel
                            control={
                              <Checkbox checked={usertaskunassign} onChange={this.handleChange} name="usertaskunassign" />
                            }
                            label={ 
                              <span className="cls_task_name">{LBLTASKUNASSIGNMENT}</span>
                            }
                            
                          /> 
                      </FormGroup> 
                      </FormControl> 
                    </Box>
                    </div> 
                    <div className="col-sm-66">
                    <Box sx={{ display: 'flex' }}>
                      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">  
                      </FormControl> 
                    </Box>
                    </div> 
                  </div> 
                 </div>
               </div> 
               <br></br> 
 
 
               <div className="cls_container_box" style={{display:"none"}} > 
               <div className="cls_admin" style={{display:""}} >
                  <div className="col-sm-12 cls_title_box">
                  <span className="cls_mail_subtitile"> {LBLSCHREMNOTIFY}</span>
                  </div>
                  <hr/>
                  <div className="row" style={{marginLeft:"10px"}}>
                      <div className="col-sm-12">
                      <Box sx={{ display: 'flex' }}> 
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard"> 
                          <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox checked={usertaskduetomorrow} onChange={this.handleChange} name="usertaskduetomorrow" />
                            }
                            label={ 
                              <span className="cls_task_name">{LBLTASKDUE1DAYSBEFORE}</span>
                            }
                            
                          />
                          <FormControlLabel
                            control={
                              <Checkbox checked={usertaskduetoday} onChange={this.handleChange} name="usertaskduetoday" />
                            }
                            label={ 
                              <span className="cls_task_name">{LBLTASKTODAY}</span>
                            }
                            
                          />
                          <FormControlLabel
                            control={
                              <Checkbox checked={usertaskoverdue>0?true:false} onChange={this.handleChange} name="usertaskoverdue" />
                            }
                            label={ 
                              <span className="cls_task_name">{LBLTASKOVERDUE}</span>
                            }
                            
                          />

                           {usertaskoverdue>0? 
                           <div className="cls_overdues"> 
                            <div className="rows"> 
<div className="cls_radio_group">
<FormControl> 
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel onClick={this.handleNotifyType} checked={(usertaskoverdue==1 || usertaskoverdue==2 )?true:false} value={2} control={<Radio  color="primary"/>} label={LBLDAILY} />
        <FormControlLabel onClick={this.handleNotifyType}  checked={usertaskoverdue==3?true:false} value={3} control={<Radio  color="primary"/>} label={LBLWEEKLY} />
        {/* <FormControlLabel onClick={this.handleNotifyType}  checked={overdueType==3?true:false} value={3} control={<Radio  color="primary"/>} label={LBLMONTHLY} />  */}
      </RadioGroup>
    </FormControl>
 </div>               
<br></br>
{usertaskoverdue==3?
  <div className="row cls_check_group_top">      
     <div className="cls_chk_group"> <Checkbox value="1" onClick={this.handleNotifyWeek} id="day1" defaultChecked={selectedDays["day1"]===1 }  /><div className="cls_chk_label">{LBLSUNDAY}</div> </div> 
     <div className="cls_chk_group"> <Checkbox value="2" onClick={this.handleNotifyWeek} id="day2" defaultChecked={selectedDays["day2"]===1 }  /><div className="cls_chk_label">{LBLMONDAY}</div></div> 
     <div className="cls_chk_group"> <Checkbox value="3" onClick={this.handleNotifyWeek} id="day3" defaultChecked={selectedDays["day3"]===1 }  /><div className="cls_chk_label">{LBLTUESDAY}</div></div> 
     <div className="cls_chk_group"> <Checkbox value="4" onClick={this.handleNotifyWeek} id="day4" defaultChecked={selectedDays["day4"]===1 }  /><div className="cls_chk_label">{LBLWEDNESDAY}</div></div> 
     <div className="cls_chk_group"> <Checkbox value="5" onClick={this.handleNotifyWeek} id="day5" defaultChecked={selectedDays["day5"]===1 }  /><div className="cls_chk_label">{LBLTHURSDAY}</div></div> 
     <div className="cls_chk_group"> <Checkbox value="6" onClick={this.handleNotifyWeek} id="day6" defaultChecked={selectedDays["day6"]===1 }  /><div className="cls_chk_label">{LBLFRIDAY}</div></div> 
     <div className="cls_chk_group"> <Checkbox value="7" onClick={this.handleNotifyWeek} id="day7" defaultChecked={selectedDays["day7"]===1 }  /><div className="cls_chk_label">{LBLSATURDAY}</div></div> 
  </div>  
  :""}            
                      </div>
                          </div> 
                          
                          :""
                           }  
                         </FormGroup> 
                         
                        </FormControl>  
                      </Box>
                      <br></br>
                        <br></br>
                      </div>
                    
                  </div>
                </div>
               
               </div> 
               </div>
                </div>
                
               </>
            ) : (
              <Skeleton height={300} />
            )}
         <br></br>
          <div className="col-sm-12 btm-submit">
               <button
                    type="submit"
                    className="button-style primary-btn"
                    disabled={this.state.submitting}
                    style={{ float: "right" }}
                    onClick={this.updateEmailSettings}
                  >
                    {LBLUPDATESETTINGS}
                  </button>
              <button
                type="button"
                className="button-style secondary-btn"
                id="btnaddCancel"
                onClick={this.resetEmailSettings}
                style={{ float: "right", marginRight: "10px" }}
              >
                {LBLRESET}
              </button>
            </div>
            <br></br>
      </div>
    );
  }
}
//export default SuccessFactorPlan;
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(Configuration);

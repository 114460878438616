import { HttpInterceptor } from '../../../../auth/HttpInterceptor'; //'../../ ../../auth/HttpInterceptor';

class SteeringCommitteeService {
    SteeringAPIServicesCall(apiName, method, callback, data, domainURL = process.env.REACT_APP_STEERCOMAPI_URL) {
        let apiUrl = apiName;
        console.log("SteeringCommitteeService", domainURL, apiName)
        if (method === "get") {
            HttpInterceptor[method](apiUrl, data)
                .then(result => {
                    if (callback) {
                        callback(result);
                    }
                }).catch(errr => {
                    if (errr && errr.response && errr.response.status && Number(errr.response.status)) {
                        alert("Error code : " + Number(errr.response.status))
                    }

                })
        }
        else {
            HttpInterceptor[method](apiUrl, data)
                .then(result => {
                    if (callback) {
                        callback(result);
                    }
                }).catch(errr => {
                    if (callback) {
                        console.log("ERRRRRRRR", errr)
                        callback(errr.response)
                    }
                })
        }
    }
}
export default new SteeringCommitteeService();


import React from "react";
import "./resourcepopup.scss";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { Modal } from "react-bootstrap";
import Loader from "../../../employee/loader/Loader";


export const ResourcedownloadFiles = (n) => {
  this.downloadFiles();
};
export default class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.filePreview = this.filePreview.bind(this);
    this.state = {
      source: null,
      iframe_loaded: false,
      previewsource:null,
      imagesource:null
    };
    this.iframeLoaderss = this.iframeLoader.bind(this);
  }

  downloadFiles() {
    let headers = new Headers({
      responseType: "blob",
    });

    var fileformat = this.props.fileformat; 
    let fileId = this.props.fileid;
    HttpInterceptor("sthrees?type=" + 1 + "&fileId=" + fileId, {
      headers,
      responseType: "arraybuffer",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/" + fileformat })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.props.filename + "." + fileformat); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  componentDidMount() {
    this.filePreview();
  }

  filePreview() {
    HttpInterceptor("sthrees?type=" + 2 + "&fileId=" + this.props.fileid).then(
      (res) => {
        let data = encodeURIComponent(res.data.data); 
        if (["mp4", "wmv","mov"].indexOf(this.props.fileformat) !== -1) {
          data = decodeURIComponent(res.data.data); 
          //document.getElementById("mp4").data = data;
          this.setState({
            iframe_loaded: true,
            previewsource:data
          });
        } 
        else if (["jpg", "jpeg", "png"].indexOf(this.props.fileformat) !== -1) {  
          this.setState({
            imagesource: process.env.REACT_APP_RESOURCES_URL + "appasset/images/resource_image/" + this.props.filename,
            iframe_loaded:true  
          });
        }else {
          this.setState({
            source: process.env.REACT_APP_DOCS_VIEWER_URL + data, 
          });
          this.iframeLoaderss(process.env.REACT_APP_DOCS_VIEWER_URL + data); 
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  iframeLoader(url) {
    let iframe = document.getElementById("fileFrame");
    this.ift = setInterval(() => {
      if (iframe && !iframe.contentDocument) {
        clearInterval(this.ift);
      } else {
        if (document.getElementById("fileFrame")) {
          document.getElementById("fileFrame").src = url;
        }
      }
      if (!document.getElementById("fileFrame")) {
        clearInterval(this.ift);
      }
    }, 2000);
  }

  closePopUp(propss) {
    propss.bclosePopup();
    clearInterval(this.ift);
  }
  render() {
    return (
      <Modal
        size="xl"
        show={this.props.showPopup}
        onHide={() => this.closePopUp(this.props)}
        aria-labelledby="example-modal-sizes-title-xl"
        className="r-popup"
      >
        <Modal.Header closeButton  className='alert-modal-header'>
          <Modal.Title id="example-modal-sizes-title-xl" className="alert-modal-title">
            <>
              <span>{this.props.filename}</span>
              <div className="download-icn" onClick={this.downloadFiles}>
                <a class="padding0" href="javascript:void(0)">
                  <span className="fa fa-download text-right" alt="" style={{marginBottom:"5px"}}/>{" "}
                  Download
                </a>
              </div>
            </>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="hidden" id="fileId" value={this.props.fileid} />
          <input type="hidden" id="fileName" value={this.props.filename} />
          <input type="hidden" id="fileFormat" value={this.props.fileformat} /> 
          {!this.state.iframe_loaded ? <Loader></Loader> : ""}  
          {["mp4", "wmv","mov"].indexOf(this.props.fileformat) !== -1 ? (
            <div>
            {/* <object id="mp4" height="500" width="400"></object> */} 
              <iframe
                id="mp4"
                height="500"
                width="100%" 
                ref="ssi_iframe_1" 
                title=""
                onLoad={() => {
                  this.setState({
                    iframe_loaded: true,
                  });
                }}
                onError={() => {
                  console.log(" ");
                }}
                src={this.state.previewsource}
              ></iframe>
            </div>
          ) : this.state.source ? (
            <iframe
              id="fileFrame"
              height="500"
              width="100%"
              ref="ssi_iframe"
              title=""
              onLoad={() => {
                this.setState({
                  iframe_loaded: true,
                });
              }}
              onError={() => {
                console.log(" ");
              }}
              src={this.state.source}
            ></iframe>
          ) : ( 
            <object id="image_loader" className="cls_img_center" height="400" width="400" data = {this.state.imagesource}>
              <div style={{width:"400px", height:"400px"}}></div>
            </object> 
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
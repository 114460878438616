import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect
} from 'react';
import BuildQuestionText from "../elements/BuildQuestionText";
import BuildOptions from "../elements/BuildOptions";
import styles from "../elements/BuildOption.module.scss"
const QuestionBoard = forwardRef((props, ref) => {
  // const [textareaValue, setTextareaValue] = useState('');
  const [updatedJson, setUpdatedJson] = useState(props.content);

  useImperativeHandle(ref, () => ({
    getAnswerDetails: () => {
      return updatedJson;
    },
  }));
 
  useEffect(() => {
    const checkTextarea = () => {
      updatedJson.map((item)=> {
        if(item.type === "textarea" && !item.showOther){
          props.handleNextEnabled(true);
        }else if(item.type === "textarea" && item.showOther && item.text !== "" && item.text !== undefined){
          props.handleNextEnabled(true);
        }
      })
    }
    checkTextarea();
  },[updatedJson])
  const handleUpdatedJson = (value, nextStatus,optIndex) => {
    let newArr = [...updatedJson];
    newArr[optIndex].option = value;
    setUpdatedJson(newArr)
    props.handleNextEnabled(nextStatus);
  }

  const handleChange = (event,tIndex,item) => {
    let newArr = [...updatedJson];
    newArr[tIndex].text = event.target.value;
    setUpdatedJson(newArr);
    if(item.showOther && event.target.value === "" ){
      props.handleNextEnabled(false);
    }
  };

  return (
    <div>
      {console.log(props.content, "props.contentQuestion")}
      {updatedJson && updatedJson.map((item, index) => (
        item.type === "question_txt" ? (
          <BuildQuestionText key={index} text={item.text} currentIndex = {props.currentIndex} labels = {props.labels}/>
        ) : (
          null
        )
      ))}
      {updatedJson && updatedJson.map((item, index) => (
        item.type === "options" ? (
          <BuildOptions key={index} option={item.option} questionType={props.questionType} handleUpdatedJson={(value, nextStatus) =>handleUpdatedJson(value, nextStatus, index)} labels = {props.labels} handleNext = {props.handleNext}/>
        ) : (
          null
        )
      ))}
      {updatedJson.map((item, index) => (
        <>
        {item.type === "textarea" && item.showOther ?
        <span style={{marginBottom:"5px"}}>{props.labels.LBLOTHER}</span>
        :
        null
        }
        {item.type === "textarea" && (
          <div style={{display:"flex",flexDirection:"column",margin:"5px 0px"}}>
          <textarea
            key={index}
            value={item.text}
            onChange={(event)=> handleChange(event,index,item)}
            placeholder={props.labels?.LBLENTERYOURTEXTHERE !== undefined ? props.labels?.LBLENTERYOURTEXTHERE : "Enter your text here..."}
            className={styles.textArea}
          />
          </div>
        )}
        </>
      ))}
    </div>
  );
});

export default QuestionBoard;
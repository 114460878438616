import React, { Component } from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import Loader from "../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import ChannelPermissions from "./ChannelPermissions";
import UsersService from "./UsersService";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import styles from "./userManagementForm.module.scss";
import info from "../../../assets/images/info.png";
import infoaccu from "../../../assets/images/infoaccu.png";

const $ = require("jquery");
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class AccessList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productlist: [],
      fullprodlist: [],
      checkitems: [],
      checkassgnitems: [],
      groupTypeInfo: [],
      selAll: false,
      selAllCurrValues: [],
      selected_group_type: "GP_1",
      prodtype: "Participant",
      roles: [],
      orgs: [],
      groups: [],
      loading: true,
      show: false,
      status: 0,
      initialValues: null,
      errorType: null,
      selectedValue: "1",
      assignmentstate: "groups",
      selProductid: "",
      selProductName: "",
      accessGroups: [],
      productUsers: [],
      enrollUsersSelected: false,
      enrollGroupsSelected: false,
      prodroles: { Participant: 4, Trainer: 3, Consultant: 2 },
      isChecked: false,
      searchValue: "",
      userSearchValue: "",
      groupSearchValue: "",
      groupUnassignSearch: "",
      showModalDetails: false,
      selItemforMultiInput: {},
      groupName: "",
      showBtn: false,
      Sitetype:localStorage.getItem('site_type'),
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      wildshow: false,
      allGroups: [],
      wildcard_access: [],
      permission_type: "1",
    };

    this.modalChild = React.createRef();
  }

  componentDidMount() {
    this.getGroupTypes("groups");
  }
  componentDidUpdate() {
    $(".icdHOq").css({ marginTop: "50px" });
  }
  handleChange = (event) => {
    let val = event.target.value;
    let selval = event.target.value;
    this.setState({ value: val, selectedValue: selval });
  };
  setGroupAssignment = (item, status) => {
    let
      checkitems = [],
      inx,
      itemid = item.group_id;
    checkitems = this.state.checkitems;
    inx = checkitems.findIndex((id) => id === itemid);
    if (inx > -1) {
      checkitems.splice(inx, 1);
    } else {
      checkitems[checkitems.length] = itemid;
    }
    $("#chkSelAll").prop("checked", false);
    if (status) this.setState({ checkitems: checkitems, selAll: false });
    if (item !== undefined) {
      var showErrorid = document.getElementById("selectproductError");
      var showErrorselector = document.querySelector("#selectproductError");

      showErrorselector.classList.remove("d-block");
      showErrorid.classList.add("d-none");
    }
    let accessGropsloc = this.state.accessGroups;
    for (var i = 0; i < accessGropsloc.length; i++) {
      if (item.group_id === accessGropsloc[i].group_id) {
        accessGropsloc[i].isNewAssigned = status ? 0 : 1;
      }
    }
    this.setState((state, props) => ({
      accessGroups: accessGropsloc,
      loading: false,
    }));
  };
  getdelflag = (x) => {
    if (x) {
      return 0;
    } else {
      return 1;
    }
  };
  getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };
  updateGroupAssignment = (e) => {
    let currid = e.target.id;
    var itemSel = [];
    var todaydate = this.getTodayDate();
    if (this.state.assignmentstate === "groups") {
      var postData = { accessGroups: [], groupItems: [] };
      let locAccessGrops = this.state.accessGroups;
      for (var i = 0; i < locAccessGrops.length; i++) {
        if (locAccessGrops[i].isNewAssigned === 1) {
          var item = {
            id: locAccessGrops[i].group_id,
            grouptypeid: locAccessGrops[i].group_typeid,
            productid: this.state.selProductid,
            delflag: this.getdelflag(locAccessGrops[i].isNewAssigned),
            assigned_date: todaydate,
          };
          itemSel.push(locAccessGrops[i].group_id);
          postData.accessGroups.push(item);
          locAccessGrops[i].isAssigned = 1;
        } else if (
          locAccessGrops[i].isNewAssigned === 0 &&
          locAccessGrops[i].isAssigned === 1
        ) {
          locAccessGrops[i].isAssigned = 0;
        }
      }
      this.setState((state, props) => ({
        accessGroups: locAccessGrops,
        loading: true,
        show: false,
      }));

      const { selected_group_type, wildcard_access, selAll } =
        this.state;
      if (wildcard_access) {
        let wildcard = wildcard_access.findIndex(
          (itemid) => itemid === selected_group_type
        );
        if (wildcard > -1 && selAll) {
          itemSel = "*";
        }
      }

      var items = {
        user_id: this.props.initialValues.userid,
        group_type: this.state.selected_group_type,
        managing_groups: itemSel,
      };

      let colcnt = $("#column-group_name")
        .parent()
        .parent()
        .parent()
        .parent()
        .find(".rdt_TableBody")
        .find("input").length;
      colcnt = 0;
      let unassgnitems = $("#column-group_name")
        .parent()
        .parent()
        .parent()
        .parent()
        .find(".rdt_TableBody")
        .find("input");
      if (unassgnitems.length > 0) {
        for (var j = 0; j < unassgnitems.length; j++) {
          if (!$(unassgnitems[j]).prop("checked")) {
            colcnt++;
          }
        }
      }
      let chkitems = this.state.checkitems,
        len; //= chkitems.length;
      len = chkitems.length;
      if ($("#chkSelAll").prop("checked")) {
        len = 1;
      }
      let blnChk = false;
      if (currid === "assign" && len === 0) {
        blnChk = true;
      } else if (currid === "" && colcnt === 0) {
        blnChk = true;
      } else {
        blnChk = false;
      }
      if (blnChk) {
        "#btnsubmit".hide();
        //return false;
      } else {
        postData.groupItems.push(items);
        // let idx;
        HttpInterceptor.post("ssiprivileges", postData.groupItems).then(
          (res) => {
            this.setState({
              show: true,
              showDel: false,
              loading: false,
              initialValues: null,
              groupSearchValue: "",
              checkitems: [],
              wildshow: itemSel === "*" ? true : false,
            });

            this.ToastMessageDetails(
              "Success",
              this.props.labels.labels.LBLUPDTSCCESS
            );
          }
        );
      }
    }
  };

  getGroupTypes = async (assignmentstate) => {
    this.getGroups("groups");
  };
  getPrivilegedGroups = () => {
    var postData = { user_id: this.props.initialValues.userid };
    HttpInterceptor.post("ssiprivilegesget", postData).then((res) => {
      let wildstate = false,
        pGroups,
        groups = this.state.allGroups,
        curType = this.state.selected_group_type;
      pGroups = groups[curType];

      if (typeof pGroups != "object")
        pGroups = pGroups ? JSON.parse(pGroups) : [];
      if (pGroups) {
        pGroups.map((items, idx) => {
          items.isNewAssigned = 0;
          items.isAssigned = 0;
          items.group_typeid = this.state.selected_group_type;
        });
      }

      let idx1,
        assgndGroups = res.data;
      let pAllGroups, pCurrGroups;
      if (assgndGroups && JSON.parse(assgndGroups.result[0].managing_groups)) {
        pAllGroups = JSON.parse(assgndGroups.result[0].managing_groups);
        let objKeys = Object.keys(pAllGroups);
        let grpType = this.state.selected_group_type;
        for (var x = 0; x < objKeys.length; x++)
          if (objKeys[x] === grpType) {
            pCurrGroups = pAllGroups[objKeys[x]];
          }

        if (pCurrGroups) {
          if (pCurrGroups[0] === "*") {
            wildstate = true;
            for (var y = 0; x < pGroups.length; y++) {
              pGroups[y].isNewAssigned = 1;
              pGroups[y].isAssigned = 1;
            }
          } else {
            wildstate = false;
            for (var z = 0; z < pCurrGroups.length; z++) {
              idx1 = pGroups.findIndex(
                (item) => item.group_id === pCurrGroups[z]
              );
              if (idx1 >= 0) {
                pGroups[idx1].isNewAssigned = 1;
                pGroups[idx1].isAssigned = 1;
              }
            }
          }
        }
      }
      wildstate = assgndGroups.result[0].managing_groups;
      wildstate = wildstate == null || wildstate === "{}" ? true : false;
      this.setState((state, props) => ({
        accessGroups: pGroups,
        loading: false,
        assignmentstate: "groups",
        wildshow: wildstate,
      }));
    });
  };
  getGroups = (xstate) => {
    let getData1 = {
      language: this.props.language_code,
    };
    this.setState({ assignmentstate: xstate, loading: true });
    let allGroups = [];
    let groupsData = this.state.allGroups;
    if (Object.keys(groupsData).length === 0) {
      UsersService.usersHelpers(getData1)
        .then((response) => {
          let wAccess;
          if (response) {
            wAccess = response.wildcard_access;
            response = response.groups;
            for (var m = 0; m < response.length; m++) {
              if (response[m].groups_by_type)
                allGroups[response[m].group_type_id] =
                  response[m].groups_by_type;
            }
            this.setState(
              {
                groupTypeInfo: response,
                group_type: "lbl_" + response[0].group_type_id,
                selected_group_type: response[0].group_type_id,
                allGroups: allGroups,
                loading: true,
                groupName:
                  response[0].group_info["lbl_" + response[0].group_type_id],
                checkitems: [],
                wildcard_access: wAccess ? wAccess : [],
              },
              () => {
                this.getPrivilegedGroups();
              }
            );
          }
        })
        .catch((err) => {
          if (err) console.log(err);
        });
    } else {
      this.getPrivilegedGroups();
    }
  };

  selectProduct = (item) => {
    this.setState({ selProductid: item.productid, selProductName: item.name, searchValue: "" })
    var showErrorid = document.getElementById("selectproductError");
    var showErrorselector = document.querySelector("#selectproductError");
    if (item.productid !== "") {
      showErrorselector.classList.remove("d-block");
      showErrorid.classList.add("d-none");
    }
  };

  setInitialValues = (item) => {
    if (item.active === 1) {
      item.active = true;
    } else {
      item.active = false;
    }
    this.setState({
      initialValues: {
        name: item.name,
        username: item.username,
        role: item.roleid,
        email: item.email,
        group: item.groupId,
        organization: item.company_id,
        userid: item.id,
        status: item.active,
      },
    });
  };
  searchAssignGroup = (e) => {
    this.setState({
      groupUnassignSearch: e.target.value,
    });
  };
  searchGroup = (e) => {
    this.setState({
      groupSearchValue: e.target.value,
    });
  };

  ToastMessageDetails(vStatus, vMessage) {
    this.setState({
      show: false,
      initialValues: null,
      groupSearchValue: "",
      groupUnassignSearch: "",
      showToast: {
        show: true,
        alertClass: "success",
        status: vStatus, //'Success',
        message: vMessage, //'The user is created successfully',
        changeInToast: new Date(),
      },
      errorType: null,
    });

    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 5000);
  }

  setAssignmentAll(e) {
    let curVal = e.target.checked;

    if (curVal === true) {
      this.setState({ selAll: true });
      $("#spnSelAll").css({ "background-color": "#9a9798" });
    } else {
      this.setState({ selAll: false });
      $("#spnSelAll").css({ "background-color": "#eee" });
    }
    const { accessGroups } = this.state;

    let selectedValueLoc = 0;

    const filterUnassignGroup = accessGroups.filter((item) => {
      if (
        item.group_name &&
        item.group_name
          .toLowerCase()
          .includes(this.state.groupUnassignSearch.toLocaleLowerCase()) &&
        item.isAssigned === selectedValueLoc
      ) {
        return item;
      }
      return null;
    });
    let currListVals = filterUnassignGroup;
    currListVals.map((items, idx) => {
      if (curVal) {
        $("#" + items.group_id).prop("checked", true);
        items.isNewAssigned = 1;
      } else {
        $("#" + items.group_id).prop("checked", true);
        items.isNewAssigned = 0;
      }
      return null;
    });
  }
  closeFilterModal = () => {
    let eve = { target: { checked: false } };
    this.setAssignmentAll(eve);

    this.setState({
      show: false,
      groupUnassignSearch: "",
      selAll: false,
    });
  };
  getAssign = () => {
    this.setState({
      assign: true,
      show: true,
      showBtn: false,
    });

    $(".modal-lg").css({ "max-width": "550px" });
  };
  groupTableHeader = (filterGroup) => {
    const { accessGroups } = this.state;
    let selectedValueLoc = 0;
    let grptype = $("#grpTypes option:selected").text();
    const filterUnassignGroup = accessGroups
      ? accessGroups.filter((item) => {
        if (
          item.group_name &&
          item.group_name
            .toLowerCase()
            .includes(this.state.groupUnassignSearch.toLocaleLowerCase()) &&
          item.isAssigned === selectedValueLoc
        ) {
          return item;
        }
        return null;
      })
      : [];

    return (
      <div
        className={`row asgn-done ${styles.assignmentHeader}`}
      >
        {this.state.showToast.show ? (
          <ToastMessage
            showTime={this.state.showToast.showTime}
            show={this.state.showToast["show"]}
            helpers={this.state.showToast}
          ></ToastMessage>
        ) : (
          ""
        )}
        <Modal
          show={this.state.show}
          onHide={this.closeFilterModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton className='alert-modal-header'>
            <Modal.Title className="alert-modal-title">
              {this.props.labels.labels.LBLASSIGN + " " + grptype}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ marginTop: "5px", padding: "0px" }}>
            <div >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={styles.pageSubTitle}>
                  {grptype}
                </div>
                <input
                  className="input-field"
                  style={{
                    width: "auto",
                    float: "right",
                    marginBottom: "5px",
                  }}
                  placeholder={this.props.labels.labels.LBLSEARCH}
                  value={this.state.groupUnassignSearch}
                  onChange={this.searchAssignGroup}
                  onKeyDown={this.searchAssignGroup}
                  ref="myInput"
                />
              </div>

              {filterUnassignGroup.length > 0 ? (
                <div className={styles.filterWthLabel}>
                  {" "}
                  <ul
                    className={styles.listGroup}
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <li className={styles.listGroupItem} style={{ whiteSpace: "nowrap" }}>
                      <label
                        className="check-lbl"
                        style={{ marginBottom: "0px", marginLeft: "0px" }}
                      >
                        <input
                          type="checkbox"
                          data-toggle="modal"
                          id="chkSelAll"
                          value={this.state.selAll}
                          onClick={this.setAssignmentAll.bind(this)}
                        />
                        <span
                          className="checkmark"
                          id="spnSelAll"
                        >
                          --
                        </span>
                        <span
                          style={{
                            display: "block",
                            paddingTop: "6px",
                            textAlign: "left",
                          }}
                        >
                          {this.props.labels.labels.LBLSELECTALL}
                        </span>
                      </label>
                    </li>
                    <hr
                      style={{
                        border: "1px solid #f5eaea",
                        marginBottom: "0px",
                        width: "98%",
                      }}
                    ></hr>
                    {filterUnassignGroup.map((item, i) => (
                      <li
                        key={i}
                        className={styles.listGroupItem}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <label
                          className="check-lbl"
                          style={{ marginBottom: "0px", marginLeft: "0px" }}
                        >
                          <input
                            type="checkbox"
                            data-toggle="modal"
                            checked={item.isNewAssigned}
                            onClick={this.setGroupAssignment.bind(
                              this,
                              item,
                              item.isNewAssigned ? 1 : 0
                            )}
                          />
                          <span className="checkmark"></span>
                          <span
                            style={{
                              display: "block",
                              paddingTop: "6px",
                              textAlign: "left",
                            }}
                          >
                            {item.group_name}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div>
                  <ul
                    className={styles.listGroup}
                    style={{ width: "100%", overflowX: "auto" }}
                  >
                    <li className={styles.listGroupItem} style={{ whiteSpace: "nowrap" }}>
                      <label
                        className="check-lbl"
                        style={{ marginBottom: "0px", marginLeft: "0px" }}
                      >
                        <span
                          style={{
                            display: "block",
                            paddingTop: "6px",
                            marginTop: "30px",
                            textAlign: "left",
                          }}
                        >
                          <EmptyState image={emptyImage} text2={this.props.labels.labels.LBLNORECORDS} />
                        </span>
                      </label>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            
          </Modal.Body>
          <Modal.Footer >
            {this.state.checkitems.length > 0 || this.state.selAll === true ? (
              <div>
                <button
                  style={{
                    display: filterUnassignGroup.length > 0 ? "" : "none",
                  }}
                  className="button-style primary-btn"
                  id="assign"
                  onClick={this.updateGroupAssignment}
                >
                  {this.props.labels.labels.LBLSAVE}
                </button>
              </div>
            ) : null}
          </Modal.Footer>
        </Modal>
      
        <div className={`table-filter ${styles.cssAccessSelectionGT}`}>
           </div>
        <div
          className="row table-filter cls-right"
          style={{ display: "inline-block", marginRight: "10px" }}
        >
          <input
            type="text"
            className="input-field"
            style={{ width: "180px" }}
            placeholder={this.props.labels.labels.LBLSEARCH}
            value={this.state.groupSearchValue}
            onChange={this.searchGroup}
            onKeyDown={this.searchGroup}
          />
        </div>
        {filterGroup?.length > 0 ?
          <div className={this.state.Sitetype==='SafeStart'?styles.usertraingDescrip: styles.usertraingaccuDescrip}>
             <img className="trainingimg" src= {this.state.Sitetype==='SafeStart'?info: infoaccu} alt = ""/>
            <div className={styles.userDescription1}>{this.props.labels.labels.LBLDATAPERMISSIONSAVECOURSE}</div>
          </div> 
        : null }
      </div>
      
    );
  };

  render() {
    const {
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLUNASSIGN,
      LBLASSIGNED,
      LBLSAVE,
      LBLDATAPERMISSIONSAVECOURSE
    } = this.props.labels.labels;

    const selectedValue = this.state.selectedValue;
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    {
      $("#column-group_name")
        .parent()
        .css({ "max-width": "81%", "min-width": "81%" });
    }
    if (this.state.assignmentstate === "groups") {
      const { accessGroups } = this.state;

      const filterGroup = accessGroups
        ? accessGroups.filter((item) => {
          if (
            item.group_name &&
            item.group_name
              .toLowerCase()
              .includes(this.state.groupSearchValue.toLocaleLowerCase()) &&
            item.isAssigned == selectedValue
          ) {
            return item;
          }
          return null;
        })
        : [];
      let groupTableHeader = this.groupTableHeader(filterGroup);
      let LBLGROUPNAME = this.state.groupName; //?this.state.groupName:"Location"; //$("#grpTypes option:selected").text(); //this.state.group_type
      let groupcolumns = [
        {
          name: LBLGROUPNAME,
          selector: "group_name",
          sortable: true,
          width: "85%",
          compact: true,
          cell: (row) => (
            <div>
              <label className="show-sm">{LBLGROUPNAME}</label>
              {row.group_name}
            </div>
          ),
        },
        {
          name: LBLASSIGNED,
          selector: "order",
          width: "15%",
          headerAlign: "center",
          className: "cssCenter",
          style: { textAlign: "center" },
          compact: true,
          cell: (row) => (
            <div className="w100">
              <label className="show-sm"> </label>
              <div className="icoBtn">
                <label
                  class={
                    row.isAssigned
                      ? row.isNewAssigned
                        ? "check-lbl"
                        : "check-lbl check-newunassigned1"
                      : "check-lbl check-newassigned1"
                  }
                >
                  <input
                    type="checkbox"
                    name={"chkg_" + row.group_id}
                    id={"chkg_" + row.group_id}
                    data-toggle="modal"
                    checked={row.isNewAssigned}
                    onClick={this.setGroupAssignment.bind(
                      this,
                      row,
                      row.isNewAssigned ? 1 : 0
                    )}
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          ),
        },
      ];
      let groupdata = filterGroup;
     return (
        <div className="usr-mang-datatable">
          <div>
            <div className={styles.datactn}>
              <div className={styles.permissionctn}>
                <div className={styles.filterWthLabel}>
                  <div className="label">{this.props.labels.labels.LBLPERMISSIONFIELDS}</div>
                  <select
                    id="permissions"
                    class="form-control-utils"
                    value={this.state.permission_type}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      this.setState({
                        permission_type: e.target.value,
                      });
                    }}
                  >
                    <option value="0">Groups</option>
                    <option value="1">Channels</option>
                  </select>
                </div>
              </div>

              {(this.state.groupTypeInfo.length !== 0 && this.state.permission_type === '0') ? (
                <div className={styles.grpctn}>
                  <div className={styles.filterWthLabel}>
                    <label className="label">{this.props.labels.labels.LBLGROUPTYPES}</label>
                    <select
                      id="grpTypes"
                      className={`form-control-utils ${styles.grpstyle}`}
                      style={{ width: '100%' }}
                      value={this.state.selected_group_type}
                      onChange={(e) => {
                        let index = e.nativeEvent.target.selectedIndex;
                        this.setState(
                          {
                            selected_group_type: e.target.value,
                            group_type: e.nativeEvent.target[index].text,
                            groupName: e.nativeEvent.target.selectedOptions[0].text,
                          },
                          () => {
                            this.getGroups("groups");
                          }
                        );
                      }}
                    >
                      {this.state.groupTypeInfo.map((item, index) => {
                        let list_item;
                        if (typeof item.group_info === "string") {
                          list_item = JSON.parse(item.group_info);
                        } else {
                          list_item = item.group_info;
                        }
                        if (item.groups_by_type) {
                          if (item.translated === false) {
                            return (
                              <option
                                data-group={item["lbl_" + item.group_type_id]}
                                key={item.group_type_id}
                                value={item.group_type_id}
                              >
                                {
                                  this.props.labels.labels[
                                  list_item["lbl_" + item.group_type_id]
                                  ]
                                }
                              </option>
                            );
                          } else {
                            return (
                              <option
                                data-group={item["lbl_" + item.group_type_id]}
                                key={item.group_type_id}
                                value={item.group_type_id}
                              >
                                {list_item["lbl_" + item.group_type_id]}
                              </option>
                            );
                          }
                        }
                        return null;
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.permission_type === '0' ?
                <div className={styles.assignbtn}>
                  <div
                    data-toggle="tooltip"
                    title="Assign Groups"
                    className="sm-icn"
                    style={{
                      cursor: "pointer",
                      paddingRight: "0px",
                      maxHeight: "40px",
                      margin: "1px 0px",
                    }}
                  >
                    <button
                      className="button-style primary-btn"
                      data-toggle="modal"
                      onClick={this.getAssign}
                    >
                      {this.props.labels.labels.LBLASSIGN}
                    </button>
                  </div>
                </div>
                : null}

            </div>
            {this.state.loading ? (
              <div>
                <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                  <header className="sc-fzoXzr hlidmt">
                    <Skeleton height={41} />
                  </header>
                  <div className="sc-fznWqX gnahTY">
                    <div className="sc-AxjAm gIMaKV rdt_Table">
                      <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                        <Skeleton height={56} />
                      </div>
                      <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                        <Skeleton height={480} />
                      </div>
                    </div>
                  </div>
                  <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                    <Skeleton height={56} />
                  </nav>
                </div>
                <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                  <header className="sc-fzoXzr hlidmt">
                    <Skeleton height={34} />
                  </header>
                  <div className="sc-fznWqX gnahTY">
                    <div className="sc-AxjAm gIMaKV rdt_Table">
                      <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                        {skel.map((i) => {
                          return (
                            <div
                              id={"row-" + i}
                              className="sc-fzoLsD doBktq rdt_TableRow"
                            >
                              <Skeleton height={90} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                    <div className="sc-fzpmMD ftIMtk">
                      <Skeleton height={40} />
                    </div>
                  </nav>
                </div>
              </div>
            ) : this.state.permission_type === "0" ? (
              <div>
                <DataTable
                  id="assignedlist"
                  columns={groupcolumns}
                  data={groupdata}
                  className={"datatable-list"}
                  pagination
                  subHeader
                  subHeaderComponent={groupTableHeader}
                  noDataComponent={
                    this.state.wildshow ? <EmptyState image={emptyImage} text2={this.props.labels.labels.LBLWILDCARDMSG} /> : <EmptyState image={emptyImage} text2={this.props.labels.labels.LBLWILDCARDMSG} />
                  }
                  paginationComponentOptions={paginationComponentOptions}
                />
                <div className="row section-body">
                  <div
                    id="selectproductError"
                    className="col-md-12 required-field text-center d-none"
                    style={{ fontSize: "14px" }}
                  >
                    {this.props.labels.errors.LBLSELANYGROUP}
                  </div>
                  <div className="col-md-12 text-right">
                    <button
                      type="button"
                      id="btnsubmit"
                      class="btnspacin"
                      style={{ display: groupdata.length > 0 ? "" : "none" }}
                      className="button-style primary-btn"
                      onClick={this.updateGroupAssignment}
                    >
                      {LBLSAVE}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <ChannelPermissions
                labels={this.props.labels}
                user_id={
                  this.props.initialValues
                    ? this.props.initialValues.user_id
                    : null
                }
              />
            )}
          </div>

          {this.state.showModalDetails ? (
            this.state.enrollredDetails.length === 0 ? (
              <div className="text-center">
                <Loader></Loader>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    language_code: state.user_info_reducer.user_info.language_code,
  };
};
export default connect(mapStateToProps, null)(AccessList);

import React from 'react';
import './toggleswitch.css';

class ToggleSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            switchStatus: (this.props.initStatus !== undefined) ? this.props.initStatus : 0
        }
    }
    switchToggleHanler = (evt) => {
        console.log(evt.target.checked)
        this.setState({
            switchStatus: (evt.target.checked === true) ? 1 : 0
        }, () => {
            if (this.props.switchToggleHanler !== undefined) {
                this.props.switchToggleHanler(this.state.switchStatus);
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <label className="ssi-toggleSwitch"><input type="checkbox" checked={(this.state.switchStatus) ? true : false} onChange={this.switchToggleHanler} /><span className="slider round"></span></label>
            </React.Fragment>
        )
    }
}

export default ToggleSwitch;
import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import UserList from "./pages/user-list/UserList";
import UserImport from "./pages/user-list/UsersImport";
import GroupList from "./pages/group-list/GroupList";
import Profile from "./pages/profile/Profile";
import Support from "./pages/support/Support";
import $ from "jquery";
import Feed from "../ssifeeds/feed";
import ManageChannels from "../ssifeeds/channels/managechannel/managechannel";
import Reports from "./pages/reports/Reports";
import ReportLayout from "./pages/reports/Report_layout";
import WatchChannels from "../ssifeeds/channels/watchchannel/watchchannels";
import Products from "../employee/pages/products/Products";
import UnitListingPage from "../employee/pages/products/UnitListingPage";
import Templates from "../admin/pages/templates/templates";
import CreateTemplates from "../admin/pages/templates/createTemplate";
import Resources from "../employee/pages/resources/Resources";
import ProdList from "./pages/prod-list/ProdList";
import RiskPattern from "./pages/riskpattern/RiskPattern";
import NearMiss from "./pages/nearmiss/NearMiss";
import NearMissReport from "./pages/nearmiss/NearMissReport";
import ClassRoom from "./pages/classroom/classroom";
import Notifications from "./pages/notifications/notification";
import RateState from "./pages/rate_your_state/RateState";
import SafeStartSurvey from "./pages/survey/SafeStartSurvey";
import SafeStartSurveyManual from "./pages/survey/SafeStartSurveyManual";
import { connect } from "react-redux";
import Not_Found from "../shared/Not_Found";
import Loading from "../shared/Loading";
import TranslatedRoutes from "../translator/TranslatedRoutes";
import GlobalSettings from "./pages/settings/GlobalSettings";
import SafeStartSurveyReport from "./pages/reports/report_types/SafeStartSurveyReport";
import SafeStartSurveyReportSummary from "./pages/reports/report_types/SafeStartSurveyReportSummary";
import RefCapsCompletion from './pages/reports/report_types/RefresherCapsuleCompletion';
import ToolBox from "./pages/toolbox/toolbox";
import CapsuleList from "../employee/pages/products/CapsuleList";
import TrainingReset from "./pages/trainingreset/trainingreset";
import SteerComMailLanding from "./pages/steeringcommittee/steercommaillanding";
import SteerCom from "./pages/steeringcommittee/steeringcommittee";
import SuccessFactors from "./pages/steeringcommittee/successfactors";
import SuccessFactorPlan from "./pages/steeringcommittee/successfactorplan";
import SteerComDashboard from "./pages/steeringcommittee/steercomdashboard";
import SteerComUserDashboard from "./pages/steeringcommittee/steercomuserdashboard";
import SteerComTaskSetup from "./pages/steeringcommittee/steercomtasksetup";
import SteerComConfiguration from "./pages/steeringcommittee/steercomconfiguration";
import EmailFailure from "./pages/emailfailure/EmailFailure";
import SteerComReports from "./pages/steeringcommittee/steercomreports";
import UnitDescription from "../employee/pages/products/UnitDescription";
//const store = createStore(reducer); 
function AdminRoutes(props) {
  let { url } = useRouteMatch(); 
  useEffect(() => {
    if ($(window).width() <= 991) {
      $("#sidenav").css({ display: "none" });
      $(".main-content").css({ marginLeft: "0", transition: "0s" });
      $("#side-backdrop").css({ display: "none" });
    }
  });
  if (props.loaded) {
    if (props.role === 1) {
      return (
        <Switch>
          {/* <Route exact path="/" component={Login} /> */}
          <TranslatedRoutes
            exact
            path={`${url}/userlist`}
            comp_name={"users"}
            {...props}
            component={UserList}
          />
          <TranslatedRoutes
            path={`${url}/dashboard`}
            comp_name={"dashboard"}
            {...props}
            component={Dashboard}
          />

          <TranslatedRoutes
            path={`${url}/userlist/import`}
            comp_name={"users"}
            {...props}
            component={UserImport}
          />

          <TranslatedRoutes
            path={`${url}/safestart-survey`}
            comp_name={"safestartsurvey"}
            {...props}
            component={SafeStartSurvey}
          />
        <TranslatedRoutes
            path={`${url}/safestart-survey-m`}
            comp_name={"safestartsurvey"}
            {...props}
            component={SafeStartSurveyManual}
          />
          <TranslatedRoutes
            path={`${url}/rate-state`}
            comp_name={"rateyourstate"}
            {...props}
            component={RateState}
          />
          <TranslatedRoutes
            path={`${url}/group-list`}
            comp_name={"grouplist"}
            {...props}
            component={GroupList}
          />
          <TranslatedRoutes
            path={`${url}/prodlist`}
            comp_name={"prodlist"}
            {...props}
            component={ProdList}
          />
          <TranslatedRoutes
            path={`${url}/globalsetting`}
            comp_name={"globalsettings"}
            {...props}
            component={GlobalSettings}
          />

          <TranslatedRoutes
            path={`${url}/products`}
            comp_name={"products"}
            {...props}
            component={Products}
          />
          <TranslatedRoutes
            path={`${url}/product-lists`}
            comp_name={"products"}
            {...props}
            component={UnitListingPage}
          />
          <TranslatedRoutes
            path={`${url}/capsule-list`}
            comp_name={"products"}
            {...props}
            component={CapsuleList}
          />
          <TranslatedRoutes
            path={`${url}/unit-detail`}
            comp_name={"products"}
            {...props}
            component={UnitDescription}
          />
          <TranslatedRoutes
            path={`${url}/riskpattern`}
            comp_name={"riskpattern"}
            {...props}
            component={RiskPattern}
          />
          <TranslatedRoutes
            path={`${url}/nearmiss`}
            comp_name={"nearmiss"}
            {...props}
            component={NearMiss}
          />
          <TranslatedRoutes
            path={`${url}/nearmissreport`}
            comp_name={"nearmissreport"}
            {...props}
            component={NearMissReport}
          />
          <TranslatedRoutes
            path={`${url}/steercommaillanding`}
            comp_name={"steercommaillanding"}
            {...props}
            component={SteerComMailLanding}
          />
          <TranslatedRoutes
            path={`${url}/steeringcommittee`}
            comp_name={"steeringcommittee"}
            {...props}
            component={SteerCom}
          />
          <TranslatedRoutes
            path={`${url}/successfactors`}
            comp_name={"successfactors"}
            {...props}
            component={SuccessFactors}
          />
          <TranslatedRoutes
            path={`${url}/successfactorplan`}
            comp_name={"successfactorplan"}
            {...props}
            component={SuccessFactorPlan}
          />
          <TranslatedRoutes
            path={`${url}/steercomdashboard`}
            comp_name={"steercomdashboard"}
            {...props}
            component={SteerComDashboard}
          />
          <TranslatedRoutes
            path={`${url}/steercomuserdashboard`}
            comp_name={"steercomuserdashboard"}
            {...props}
            component={SteerComUserDashboard}
          />
          <TranslatedRoutes
            path={`${url}/steercomtasksetup`}
            comp_name={"steercomtasksetup"}
            {...props}
            component={SteerComTaskSetup}
          />
           <TranslatedRoutes
            path={`${url}/steercomconfiguration`}
            comp_name={"steercomconfiguration"}
            {...props}
            component={SteerComConfiguration}
          />
          <TranslatedRoutes
            path={`${url}/steercomreports`}
            comp_name={"steercomreports"}
            {...props}
            component={SteerComReports}
          /> 
          <TranslatedRoutes
            path={`${url}/classroom`}
            comp_name={"classroom"}
            {...props}
            component={ClassRoom}
          />
          <TranslatedRoutes
            path={`${url}/trainingreset`}
            comp_name={"trainingreset"}
            {...props}
            component={TrainingReset}
          />
          <TranslatedRoutes
            path={`${url}/templates`}
            comp_name={"templates"}
            {...props}
            component={Templates}
          />
          <TranslatedRoutes
            exact
            path={`${url}/create-templates`}
            comp_name={"templates"}
            {...props}
            component={CreateTemplates}
          />
          <TranslatedRoutes
            exact
            path={`${url}/create-templates/:id`}
            comp_name={"templates"}
            {...props}
            component={CreateTemplates}
          />
          <TranslatedRoutes
            path={`${url}/manage-channels`}
            comp_name={"feeds"}
            {...props}
            component={ManageChannels}
          />
          <TranslatedRoutes
            path={`${url}/watch-channels`}
            comp_name={"feeds"}
            {...props}
            component={WatchChannels}
          />
          <TranslatedRoutes
            exact
            path={`${url}/reports/safe_start_survey_report`}
            comp_name={"reports"}
            {...props}
            component={SafeStartSurveyReportSummary}
          />
          <TranslatedRoutes
            exact
            path={`${url}/reports/safe_start_survey_report_summary`}
            comp_name={"reports"}
            {...props}
            component={SafeStartSurveyReportSummary}
          />
          <TranslatedRoutes
            exact
            path={`${url}/reports/rcs`}  
            comp_name={"reports"}
            {...props}
            component={RefCapsCompletion}
          />
          <TranslatedRoutes
            exact
            path={`${url}/reports`}
            comp_name={"reports"}
            {...props}
            component={Reports}
          />
          <TranslatedRoutes
            path={`${url}/reports/`}
            comp_name={"reports"}
            {...props}
            component={ReportLayout}
          />
          <TranslatedRoutes
            path={`${url}/profile`}
            comp_name={"profile"}
            {...props}
            component={Profile}
          />
          <TranslatedRoutes
            path={`${url}/support`}
            comp_name={"support"}
            {...props}
            component={Support}
          />
          <TranslatedRoutes
            path={`${url}/resources`}
            comp_name={"resources"}
            {...props}
            component={Resources}
          />
          <TranslatedRoutes
            path={`${url}/feeds`}
            comp_name={"feeds"}
            {...props}
            component={Feed}
          />
          <TranslatedRoutes
            path={`${url}/toolbox`}
            comp_name={"toolbox"}
            {...props}
            component={ToolBox}
          />
          <TranslatedRoutes
            path={`${url}/emailfailure`}
            comp_name={"emailfailure"}
            {...props}
            component={EmailFailure}
          /> 
          <Route path={`${url}/support`} component={Support} />
          <Route path={`${url}/notifications`} component={Notifications} />
          <Route path={`${url}/safestart-survey`} component={SafeStartSurvey} />
          <Route path={`${url}/rate-state`} component={RateState} />

          <Route path="*">
            {" "}
            <Not_Found />{" "}
          </Route>
        </Switch>
      );
    } else if (props.role === 2) {
      return (
        <Switch>
          <TranslatedRoutes
            path={`${url}/dashboard`}
            comp_name={"dashboard"}
            {...props}
            component={Dashboard}
          />
          <TranslatedRoutes
            path={`${url}/userlist`}
            comp_name={"users"}
            {...props}
            component={UserList}
          />
          <TranslatedRoutes
            path={`${url}/group-list`}
            comp_name={"grouplist"}
            {...props}
            component={GroupList}
          />
          <TranslatedRoutes
            path={`${url}/prodlist`}
            comp_name={"prodlist"}
            {...props}
            component={ProdList}
          />
          <TranslatedRoutes
            path={`${url}/products`}
            comp_name={"products"}
            {...props}
            component={Products}
          />
          <TranslatedRoutes
            path={`${url}/product-lists`}
            comp_name={"products"}
            {...props}
            component={UnitListingPage}
          />
          <TranslatedRoutes
            path={`${url}/capsule-list`}
            comp_name={"products"}
            {...props}
            component={CapsuleList}
          />
          <TranslatedRoutes
            path={`${url}/unit-detail`}
            comp_name={"products"}
            {...props}
            component={UnitDescription}
          />
          <TranslatedRoutes
            path={`${url}/riskpattern`}
            comp_name={"riskpattern"}
            {...props}
            component={RiskPattern}
          />
          <TranslatedRoutes
            path={`${url}/nearmiss`}
            comp_name={"nearmiss"}
            {...props}
            component={NearMiss}
          />
          <TranslatedRoutes
            path={`${url}/nearmissreport`}
            comp_name={"nearmissreport"}
            {...props}
            component={NearMissReport}
          />
          <TranslatedRoutes
            path={`${url}/classroom`}
            comp_name={"classroom"}
            {...props}
            component={ClassRoom}
          />
          <TranslatedRoutes
            path={`${url}/steercommaillanding`}
            comp_name={"steercommaillanding"}
            {...props}
            component={SteerComMailLanding}
          />
          <TranslatedRoutes
            path={`${url}/trainingreset`}
            comp_name={"trainingreset"}
            {...props}
            component={TrainingReset}
          />
          <TranslatedRoutes
            path={`${url}/steeringcommittee`}
            comp_name={"steeringcommittee"}
            {...props}
            component={SteerCom}
          />
          <TranslatedRoutes
            path={`${url}/successfactors`}
            comp_name={"successfactors"}
            {...props}
            component={SuccessFactors}
          />
          <TranslatedRoutes
            path={`${url}/successfactorplan`}
            comp_name={"successfactorplan"}
            {...props}
            component={SuccessFactorPlan}
          />
          <TranslatedRoutes
            path={`${url}/steercomdashboard`}
            comp_name={"steercomdashboard"}
            {...props}
            component={SteerComDashboard}
          />
          <TranslatedRoutes
            path={`${url}/steercomuserdashboard`}
            comp_name={"steercomuserdashboard"}
            {...props}
            component={SteerComUserDashboard}
          />
          <TranslatedRoutes
            path={`${url}/steercomtasksetup`}
            comp_name={"steercomtasksetup"}
            {...props}
            component={SteerComTaskSetup}
          />
            <TranslatedRoutes
            path={`${url}/steercomconfiguration`}
            comp_name={"steercomconfiguration"}
            {...props}
            component={SteerComConfiguration}
          />
         <TranslatedRoutes
            path={`${url}/steercomreports`}
            comp_name={"steercomreports"}
            {...props}
            component={SteerComReports}
          />
          <TranslatedRoutes
            path={`${url}/templates`}
            comp_name={"templates"}
            {...props}
            component={Templates}
          />
          <TranslatedRoutes
            exact
            path={`${url}/create-templates`}
            comp_name={"templates"}
            {...props}
            component={CreateTemplates}
          />
          <TranslatedRoutes
            exact
            path={`${url}/create-templates/:id`}
            comp_name={"templates"}
            {...props}
            component={CreateTemplates}
          />
          <TranslatedRoutes
            path={`${url}/manage-channels`}
            comp_name={"feeds"}
            {...props}
            component={ManageChannels}
          />
          <TranslatedRoutes
            path={`${url}/watch-channels`}
            comp_name={"feeds"}
            {...props}
            component={WatchChannels}
          />

          <TranslatedRoutes
            path={`${url}/emailfailure`}
            comp_name={"emailfailure"}
            {...props}
            component={EmailFailure}
          />
          <TranslatedRoutes
            path={`${url}/feeds`}
            comp_name={"feeds"}
            {...props}
            component={Feed}
          />
          <Route path={`${url}/profile`} component={Profile} />
          <Route path={`${url}/support`} component={Support} />
          <Route path={`${url}/resources`} component={Resources} />
          <Route path={`${url}/notifications`} component={Notifications} />
          <Route path={`${url}/safestart-survey`} component={SafeStartSurvey} />
          <Route path={`${url}/rate-state`} component={RateState} />
          <Route path="*">
            {" "}
            <Not_Found />{" "}
          </Route>
        </Switch>
      );
    } else if (props.role > 2) {
      return (
         <Switch>
           <TranslatedRoutes
            path={`${url}/steercommaillanding`}
            comp_name={"steercommaillanding"}
            {...props}
            component={SteerComMailLanding}
          />  
          <Route path="*">
          <Redirect
            replace
            to={{
              pathname: "/home/dashboard",
              state: { from: "/home/dashboard" },
            }}
          />
        </Route>
      </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="*">
            {" "}
            <Loading />{" "}
          </Route>
        </Switch>
      );
    }
  } else {
    return (
      <Switch>
        <Route path="*">
          {" "}
          <Loading />{" "}
        </Route>
      </Switch>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    language: state.user_info_reducer.user_info.language_code,
    role: state.user_info_reducer.user_info.role_id,
    loaded: state.user_info_reducer.user_info.loaded,
  };
};
export default connect(mapStateToProps, null)(AdminRoutes);

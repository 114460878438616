import React, { useState, useEffect } from "react";
import styles from "./AboutProduct.module.scss";
import AboutTabs from "./AboutTabs";
import AboutProductBanner from "./AboutProductBanner";
import CertificateTabs from "./CertificateTabs";
const AboutProduct = (props) => {
    let Sitetype =localStorage.getItem('site_type');
    const initialActiveTab = (productDetails) => {
        console.log(props.activeTab,"props.activeTab")
        if (Object.keys(productDetails).length > 0 ) {
            const { productAbout, authorDescription,skillsGain} = productDetails;
            if (productAbout !== "" || skillsGain.length > 0  || authorDescription !== "") {
                return props.activeTab;
            } else {
                return 2;
            }
        } else {
            return 2;
        }
        
    };
    
    const [activeTab, setActiveTab] = useState(() => initialActiveTab(props?.productDetails));
    console.log(activeTab,"activeTabactiveTab")
    useEffect(()=>{
        props.handleUnitTab(activeTab === 2);
    },[activeTab]);
    
    // useEffect(() => {
    //  setActiveTab(props.activeTab);
    // },[props.activeTab]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        props.handleUnitTab(activeTab === 2);
    };
    
    return (
        <div>
            <AboutProductBanner 
                labels={props.labels} 
                productDetails={props.productDetails} 
                productImage = {props?.productImage} 
                productName = {props?.productName} 
                unitsCount={props.unitsCount} 
                resultData={props.resultData} 
                gamificationDetails = {props.gamificationDetails}
                isModule = {props.isModule}
            />
            <div className={styles.tabsCtn}>
                <ul className= {styles.nav}>
                    { props?.productDetails && Object.keys(props.productDetails).length > 0 && (props.productDetails.productAbout !== "" || props.productDetails.skillsGain.length > 0  || props.productDetails.authorDescription !== "") ?
                        <li className={styles.navList} >
                            <span className={localStorage.getItem('site_type') === "SafeStart"?activeTab === 1 ? styles.activeTab : styles.tab:activeTab === 1?styles.greenactiveTab: styles.tab} onClick={() => handleTabChange(1)}>
                                {props.labels.LBLABOUT !== undefined ? props.labels.LBLABOUT : "About"}
                            </span>
                        </li>
                        :
                        null
                    }
                    <li className={styles.navList}>
                        <span className={localStorage.getItem('site_type') === "SafeStart"?activeTab === 2 ? styles.activeTab : styles.tab:activeTab === 2?styles.greenactiveTab: styles.tab} onClick={() => handleTabChange(2)}>
                            {localStorage.getItem('site_type') === "Accutrain" || props.isModule && props.isModule === true ? props.labels.LBLMODULES !== undefined ? props.labels.LBLMODULES : "Modules" : props.labels.LBLUNITS !== undefined ? props.labels.LBLUNITS : "Units"}
                        </span>
                        {/* activeTab === 2 */}
                    </li>
                    <li className={styles.navList}>
                        <span className={localStorage.getItem('site_type') === "SafeStart"?activeTab === 3 ? styles.activeTab : styles.tab:activeTab === 3?styles.greenactiveTab: styles.tab} onClick={() => handleTabChange(3)}>
                            {props.labels.LBLCERTIFICATE !== undefined ? props.labels.LBLCERTIFICATE : "Certificate"}
                        </span>
                    </li>
                </ul>
            </div>
            {activeTab === 1 &&
                <AboutTabs labels={props.labels} unitsCount={props.unitsCount} productDetails={props.productDetails} />
            }
            {activeTab === 3 &&
                <CertificateTabs labels={props.labels} productDetails={props?.productDetails} allUnitsCompletionStatus={props.allUnitsCompletionStatus} CourseCompletionCount={props.CourseCompletionCount} resultData={props.resultData} showFeedback = {props.showFeedback} feedBackCompleted = {props.feedBackCompleted} showEvalution= {props.showEvalution} FeedbackLabels = {props.FeedbackLabels} gamificationDetails = {props.gamificationDetails} isModule = {props.isModule}/>
            }
        </div>
    )
}

export default AboutProduct;
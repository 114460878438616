import React, { useState, useRef, useEffect } from 'react';
import Lottie from 'react-lottie';
import './expandablediv.scss';
import white from '../../../assets/images/karate/White_Belt.json'
import yellow from '../../../assets/images/karate/Yellow_Belt.json'
import orange from '../../../assets/images/karate/Orange_Belt.json'
import green from '../../../assets/images/karate/Green_Belt.json'
import brown from '../../../assets/images/karate/Brown_Belt.json'
import blue from '../../../assets/images/karate/Blue_Belt.json'
import black from '../../../assets/images/karate/Black_Belt.json'
import whiteBeltOuter from '../../../assets/images/karate/beltoutercircle/white.png'
import yellowBeltOuter from '../../../assets/images/karate/beltoutercircle/yellow.png'
import orangeBeltOuter from '../../../assets/images/karate/beltoutercircle/orange.png'
import greenBeltOuter from '../../../assets/images/karate/beltoutercircle/green.png'
import brownBeltOuter from '../../../assets/images/karate/beltoutercircle/brown.png'
import blueBeltOuter from '../../../assets/images/karate/beltoutercircle/blue.png'
import blackBeltOuter from '../../../assets/images/karate/beltoutercircle/black.png'
const ExpandableDiv = ({ isExpanded, toggleExpand, item }) => {
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);
  const belts = {
    "white": white,
    "yellow": yellow,
    "orange": orange,
    "green": green,
    "brown": brown,
    "blue": blue,
    "black": black
  }
  const beltOuter = {
    "white": whiteBeltOuter,
    "yellow": yellowBeltOuter,
    "orange": orangeBeltOuter,
    "green": greenBeltOuter,
    "brown": brownBeltOuter,
    "blue": blueBeltOuter,
    "black": blackBeltOuter
  }

  useEffect(() => {
    const element = elementRef.current;
    // Function to update the width
    const updateWidth = () => {
      if (element) {
        if(element.offsetWidth === 500){
          elementRef.current.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start' })
        }
        setWidth(element.offsetWidth);
      }
    };

    // Initialize width
    updateWidth();

    // Create a ResizeObserver instance
    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    // Observe the element
    if (element) {
      resizeObserver.observe(element);
    }

    // Cleanup observer on component unmount
    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, []);
  const handleSelectBelt = () => {
    toggleExpand()
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: item?.badge_animation ? belts[item.badge_animation] : belts['white'],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div ref={elementRef} style={{ minWidth: isExpanded ? '500px' : '150px' }} className={`expandableDiv`} onClick={() => handleSelectBelt()}>
      <div className='karateWrapper'>
        {(item?.earned_badge === 0 && !isExpanded )|| (item?.earned_badge && item.earned_badge === 1 && !isExpanded) ?   
        <div class="overlay-div"></div> : null}
        <div className='karateImgContainer'>
          {/* <div className="circle-image">
              <img className="circle-image-img" src={beltOuter[item.badge_animation]} alt="Circular Image" />
              <Lottie options={defaultOptions} className='karateImage' />
            </div> */}
          <div className="circle-container">
            <img className="circle" src={beltOuter[item.badge_animation]} alt="Circular Image" />
            <div className="gif">
            <img className='karateImage' src={require(`../../../assets/images/karate/Badges/${item.badge_animation}.gif`)} alt="Badge Image" />

            {/* <Lottie options={defaultOptions} className='karateImage' /> */}
            </div>
            <img className="status" src={require(`../../../assets/images/karate/${item?.earned_badge && item.earned_badge === 2 ? 'completed.png' : 'locked.png'}`)} />
          </div>

          <span className='badgeName'>{item.badge_name}</span>
        </div>
        {isExpanded && width > 450 ?
        <div className="verticalLine"></div> : null }
        {isExpanded && width > 450 ?
          <div className='beltDescription'>
            <span className='beltDescriptionText'>{item.badge_description}</span>
            <div className="progressWrapperCert">
              <div className="progressContainerCert">
                <div className="progressBarCert" style={{ width: `${item.earned_points}%` }}>
                </div>
              </div>
              <div>
                <span className="progressStatusCert">{item.completed_units_count} of {item.units.length} </span>
              </div>
            </div>
          </div>
          : null}
      </div>
    </div>

  );
};

export default ExpandableDiv;
import React from "react";
//import ReactDOM from "react-dom";
import UploadPopup from "../feedUploadPopup/uploadPopup";
import "./createpost.css";
import FeedsServices from "../../feedService/feedservices";
import utils from "../../../utils/utils";

class Feed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }
  componentDidMount() { }
  onClickCreatePost = () => {
    if(this.props.channelsData.length>0){
      this.setState({
        isModalOpen: true,
      });
    }
    
  };
  closeUploadFormHandler = () => {
    this.setState({
      isModalOpen: false,
    });
  };
  createPostHandler = (evt, postData, attachedFiles, channelStatus) => {
    if (channelStatus === 1) {
    attachedFiles.map((item) => {
      let postAsset = {};
      let fileName = utils.generateFeedsPostFileName(item.name);
      postAsset.asset_name = fileName;
      postAsset.asset_type = item.type.match("image.*")
        ? "image"
        : item.type.match("video.*")
          ? "video"
          : "";
      if (item.type.match("video.*")) {
        postAsset.is_job_done = 0;
        postData.assets.push(postAsset);
      } else {
        postData.assets.push(postAsset);
      }

      

      this.props.uploadAssets(item, fileName, "", this.props.imageUploadCallBack);
    })
   
      FeedsServices.feedsAPIServicesCall(
        "feeds",
        "post",
        this.feedsPostResultHandler,
        postData
      );
    } else {
      this.props.errorReportChannel(this.props.getResourceFromLabels("LBLCHOOSECHANNELFORPOSTMSG"))
    }


  }
  feedsPostResultHandler = (resultData) => {
    console.log(resultData);
    if (resultData.data && resultData.data._id) {
      this.setState({
        isModalOpen: false,
      });
      this.props.feedsPostResultHandler(resultData)
    }
  };

  render() {
    return (
      <div className="ssi-feeds-feedsPostContainer">

        <div
          className="ssi-feeds-feedsPostHeader" 
          onClick={this.onClickCreatePost}
        >
          <span className="ssi-feeds-createEditIcon">
            <i class="far fa-edit"></i>
          </span>
          <span>{this.props.tittle}</span>
        </div>
        {this.state.isModalOpen && (
          <UploadPopup
            isOpen={this.state.isModalOpen}
            channelsData={this.props.channelsData}
            errorReportChannel={this.props.errorReportChannel}
            onHide={this.closeUploadFormHandler}
            label={this.props.getResourceFromLabels("LBLCREATEPOST")}
            getResourceFromLabels={this.props.getResourceFromLabels}
            submitPost={this.createPostHandler}
            userInfo={this.props.userInfo}
            type={1}
            removePostAssets={this.props.removePostAssets}
            feedItemData={""}
          />
        )}
      </div>
    );
  }
}

export default Feed;

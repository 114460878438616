import React, { Component } from "react";
import ReportServices from "../report_services";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import UsersService from "../../user-list/UsersService";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import DataTable from "react-data-table-component";
import emptyImage from '../../../../assets/images/rateyourstate_emptyst.png';
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import RysConversationRecords from "./RysCoversationRecords";
import RysCoversation from "./RysConversation.json";
import styles from "./RysCoversation.module.scss"
import  "./RateYourStateConversation.scss";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RateYourStateConversationPageTwo from "./RateYourStateConversationPageTwo";
import {
  exportCSV,
  exportToExcel,
} from "../../../../common_components/rptexport";

let objFilterData = {};
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const REFS = {};


class RateYourStateConversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_logo: this.props.company_logo,
      open: false,
      loading: true,
      reportloading: false,
      userData: [],
      user_roles: [],
      pageState: "RYS_D1",
      roles: [{ role_id: 1, role_name: "learner" }],
      loadRYS_D1_List: 0,
      orgRYS_D1_List: [],
      RYS_D1_List: [],
      filterDataStatus: false,
      filterDataLoaded: 0,
      filterCol:[],
      showRysPage: false,
      rysUserData: []
    };
    this.RYS_D1_Filter = React.createRef();
  }
  loadpage(a, x) {
    if (a === "RYS_D1") {
      this.setState({loadRYS_D1_List: 1,});
    }

    if (this.state.loadRYS_D1_List === 1) {
      this.setState({
        loading: false,
      });
    }
  }
  componentDidMount() {
    this.setState({resultData: RysCoversation})
    objFilterData = {};
    let data = {};
    let getData = {
      language: this.props.language,
    };
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
          filterDataLoaded: 1,
        },
        this.loadpage("group", iresponse)
      );
    });
    let ryscolumns = objFilterData.availableRYS_D1Fields
    if (!ryscolumns) {
      let columns = this.getRYS_D1_Columns();
      ryscolumns = this.getFilterColumns("RYS_D1", columns);
    }
    data.filtercolumns = ryscolumns
    console.log(ryscolumns,"ryscolumns")
    this.getRYS_Conversation_List(data);
  }

  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };

  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  getRYS_D1_Summary = (xresult, ydata) => {
    let data = {
      labels: [],
      datasets: [{ graphData: xresult, backgroundColor: [], hoverBackgroundColor: [], ynames: [] }],
    };


    var getObjDate = (xstrday) => {
      let arrdt = xstrday.split(" ")[0].split("-");
      let arrdt2 = xstrday.split(" ")[1].split(":");
      return new Date(
        arrdt[0],
        parseInt(arrdt[1]) - 1,
        arrdt[2],
        arrdt2[0],
        arrdt2[1],
        0, 0
      );
    }
    let xstartDay = ""
    let xendDay = ""
    let ryscolumns = objFilterData.availableRYS_D1Fields
    let xcondition
    if (!ryscolumns) {
      console.log("REASSIGN ryscolumns")
      let columns = this.getRYS_D1_Columns();
      ryscolumns = this.getFilterColumns("RYS_D1", columns);
      let xdata = { filtercolumns: ryscolumns }
      this.setsvaluefordate(xdata)
      for (let i = 0; i < ryscolumns.length; i++) {
        if (ryscolumns[i].selector === "created_on") {
          xcondition = ryscolumns[i].filter.condition
        }
      }
    } else {
      for (var key in ryscolumns) {
        if (ryscolumns[key].selector === "created_on") {
          xcondition = ryscolumns[key].condition
        }
      }
    }
    if (xcondition.svalue) {
      xstartDay = xcondition.svalue[0]
      if (xcondition.svalue[1]) {
        xendDay = xcondition.svalue[1]
      } else {
        xendDay = xstartDay.split(" ")[0] + " 23:59:59"
      }
    }
    let objStartDate = getObjDate(xstartDay)
    let objEndDate = getObjDate(xendDay)
    data.date1 = objStartDate
    data.date2 = objEndDate
    return data;

  };
  getRYS_Conversation_List(data) {
    this.setState({filterCol:data.filtercolumns})
    this.setsvaluefordate(data)
    const updatedFilteredColumns = data.filtercolumns.map(column => {
      const { filterData,sortable,compact,...rest } = column;
      return rest;
    });
    let dataSet = {
      dateformat : this.props.dateformat,
      filtercolumns : updatedFilteredColumns
    };
    ReportServices.getRYS_Conversation_List(dataSet).then((response) => {
      let result = response.result;
      this.setState(
        {
          pageState: "RYS_D1",
          RYS_D1_List: result,
          orgRYS_D1_List: result?.rysConversation,
          filterDataStatus: true,
          RYSDataLoaded: true,
          zoomChanged: 0
        },
        this.loadpage("RYS_D1")
      );
    });
  }
  showRYS_D1 = () => {
    this.setState({
      pageState: "RYS_D1",
    });
  };

  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  getrysdates = (xvalue) => {
    let cDate = new Date();
    let xrArr = []
    switch (xvalue) {
      case "LAST7DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "LAST30DAYS":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case "THISMONTH":
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "LASTMONTH":
        let thisMonthFDate = new Date(cDate.getFullYear(), cDate.getMonth(), 1, 23, 59, 59, 999);
        xrArr.push(new Date(thisMonthFDate.getFullYear(), thisMonthFDate.getMonth() - 1, 1, 0, 0, 0, 0));
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
      case "YTD":
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "TODAY":
        xrArr.push(new Date(cDate.getTime()));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case "YESTERDAY":
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      default:
        break;
    }
    return xrArr
  }
  getCondition(mode) {
    if (mode === "date") {
      if (this.props.location && this.props.location.state && this.props.location.state.rys_date) {
        return {
          name: "Date",
          operator: this.props.location.state.rys_date,
          value: this.getrysdates(this.props.location.state.rys_date).slice(),
          operatorText: this.getLabel("LBL" + this.props.location.state.rys_date),
        }
      } else {
        let xrArr = [];
        let cDate = new Date();
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date());
        return {
          name: "Date",
          operator: "YTD",
          value: this.getrysdates("YTD").slice(),
          operatorText: this.getLabel("LBLYTD"),
        }
      }
    }
  }
  columnHeader = (header) => {
    const labelPattern = /LBL\w+/g;
    const labelsToReplace = header.match(labelPattern);

    if (labelsToReplace) {
      let updatedHeader = header;

      labelsToReplace.forEach((label) => {
        const replacement = this.props.labels.labels[label];
        if (replacement) {
          const labelRegex = new RegExp(label, 'g');
          updatedHeader = updatedHeader.replace(labelRegex, replacement);
        }
      });

      return updatedHeader;
    }

    return header;
  }
  getRYS_D1_Columns = () => {

    let columns = [
      {
        name: this.props.labels.labels.LBLDATE,
        selector: "created_on",
        filter: {
          type: "date",
          showField: false,
          checked: true,
          render: "server",
          condition: this.getCondition("date"),
        },
        cell: (row) => {
          return (
            <div>
              <span className="rle-brdr" style={{}}>
                {row.created_on_format}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLRANK,
        selector: "rank",
        sortable: true,
        compact: true,
        width:window.innerWidth < 768 ? "20%" : "10%",
        cell: (row) => {
          return (
            <div className={row.rank < 4 ? "rptConCell" : "rptConCellNormal"} style={{borderLeft:row.rank < 4 ? "1px solid #3399FF" : "1px solid rgba(0, 0, 0, 0.12)",borderRadius:"10px 0px 0px 10px"}}>
              <span className={row.rank < 4 ? "rank" : "rle-brdr1"} style={{paddingLeft:"15px"}}>
                { row.rank}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLSUPERVISOR,
        selector: "name",
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div className={row.rank < 4 ? "rptConCell" : "rptConCellNormal"} style={{paddingRight:"5px"}}>
              <span className="rle-brdr grid-ellipsis" title={row.name}>{row.name}</span>
            </div>
          );
        },
        style: {
          maxWidth: '300px'
        },
      },
      {
        name: this.props.labels.labels.LBLTOTALCOVERSATION,
        selector: "totCon",
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div className={row.rank < 4 ? "rptConCell" : "rptConCellNormal"} style={{borderRight:row.rank < 4 ? "1px solid #3399FF" : "1px solid rgba(0, 0, 0, 0.12)",borderRadius:"0px 10px 10px 0px",justifyContent:"flex-end"}}>
            <div className="totCon" onClick = { () => this.handleRYS(row)}>
              <span className="rle-brdr">{row.totCon}</span>
              <div className="verticalline"></div>
              <ArrowForwardIosIcon  className="greaterThanArrow"/>
            </div>
            </div>
          );
        },
        style: {
          position: 'sticky', 
          right: 0,
          maxWidth: '120px'
        },
      }
    ];

    let xcolumns = [];
    let response = this.state.groupTypeNames || [];
    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        console.log(response,"active")
        let selctor = response[i].group_type_id;
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: selctor,
          filter: { type: "string", showField: false, checked: true },
          filterData: this.state.groups[response[i].group_type_id],
          sortable: true,
          compact: true,
          cell: (row) => {
            console.log(row,selctor,"row")
            return (
              <div className={row.rank < 4 ? "rptConCell" : "rptConCellNormal"}>
                <span className="rle-brdr grid-ellipsis" title={row[selctor]}>{row[selctor]}</span>
              </div>
            );
          }
        });
      }
    }
    columns.splice.apply(columns, [3, 0].concat(xcolumns));
    Object.keys(objFilterData).length > 0 && this.state.RYS_D1_List?.coloumnHeader?.forEach((header, index) => {
      let selector = String.fromCharCode(97 + index);
      columns.splice(objFilterData.orgavailableRYS_D1Fields.length + 2 + index, 0, { 
        name: this.columnHeader(header), 
        selector: selector,
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div className={row.rank < 4 ? "rptConCell" : "rptConCellNormal"}>
              <span className="rle-brdr">{row[selector]}</span>
            </div>
          );
        }
      });
    });
    return columns; //JSON.parse(JSON.stringify(columns))
  };
   
  handleRYS = (item) => {
    const updatedFilteredColumns = this.state.filterCol.map(column => {
      const { filterData,sortable,compact,...rest } = column;
      return rest;
    });
    let data = {
      dateformat: this.props.dateformat,
      filtercolumns: updatedFilteredColumns,
    };
    this.setState({showRysPage: true,sel_D1_Data: item})
    ReportServices.getRYS_Conversation_User_List(item.userId,data).then((response) => {
      this.setState({rysUserData: response.result})
    });
  }
  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    let xcols = [];
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        xcols.push(cols[i]);
      }
    }
    if (mode === "RYS_D1") {
      let xobj = objFilterData.availableRYS_D1Fields;
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    }
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    let xcols = [];
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        xcols.push(cols[i]);
      }
    }
    console.log(xcols,"xcols")
    return xcols;
  };

  setsvaluefordate = (data) => {
    for (let j = 0; j < data.filtercolumns.length; j++) {
      if (data.filtercolumns[j].filter.type === "date" && data.filtercolumns[j].filter.condition) {
        let dt1, dt2;
        let xcondition = JSON.parse(JSON.stringify(data.filtercolumns[j].filter.condition))
        if (typeof xcondition.value == "string") {
          let arrdt = xcondition.value.split("T")[0].split("-");
          let arrdt2 = xcondition.value.split("T")[1].split(":");
          xcondition.xvalue = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2],
            arrdt2[0],
            arrdt2[1],
            0, 0
          );
        } else {
          if (typeof xcondition.value == "object" && xcondition.value[0] && typeof xcondition.value[0] == "string") {
            xcondition.xvalue = []
            let arrdt = xcondition.value[0].split("T")[0].split("-");
            let arrdt2 = xcondition.value[0].split("T")[1].split(":");
            xcondition.xvalue[0] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
          if (typeof xcondition.value == "object" && xcondition.value[1] && typeof xcondition.value[1] == "string") {
            let arrdt = xcondition.value[1].split("T")[0].split("-");
            let arrdt2 = xcondition.value[1].split("T")[1].split(":");
            xcondition.xvalue[1] = new Date(
              arrdt[0],
              parseInt(arrdt[1]) - 1,
              arrdt[2],
              arrdt2[0],
              arrdt2[1],
              0, 0
            );
          }
        }
        if (typeof xcondition.value == "object" && xcondition.value[0] && xcondition.value[1]) {
          dt1 = new Date(xcondition.xvalue[0].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue[1].getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        } else {
          dt1 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
          dt2 = new Date(xcondition.xvalue.getTime() - ((new Date()).getTimezoneOffset() * 60 * 1000));
        }
        data.filtercolumns[j].filter.condition["svalue"] = [this.getDateFormat(dt1) + " 00:00:00", this.getDateFormat(dt2) + " 23:59:00"]
      }
    }
  }

  onExport = (type) => {
    // if (this.state.orgRYS_D1_List) {
      this.convertExportData(this.state.orgRYS_D1_List, type);
    // }

  };
  convertExportData = (rysData, type) => {
    const fileName = this.props.labels.labels.LBLRYSCONVERSATATION;
    let tList = [];
    rysData && rysData.map((rItem) => {
      let aObj = {};
      let bObj = {};
      let cObj = {};
      let dObj = {};
      aObj[this.props.labels.labels.LBLRANK] = rItem.rank;
      aObj[this.props.labels.labels.LBLSUPERVISOR] = rItem.name;
      dObj[this.props.labels.labels.LBLTOTALCOVERSATION] = rItem.totCon;
      let response = this.state.groupTypeNames || [];
      
      for (let i = 0; i < response.length; i++) {
        console.log(response[i], "active"); // Log each response item (optional)
        let filteredData = objFilterData.orgavailableRYS_D1Fields;
        let propName = `GP_${i + 1}`;
        let key = response[i]["lbl_" + response[i].group_type_id];
        for(let j = 1; j<filteredData.length; j ++){
          if(filteredData[j].name === key &&
            filteredData[j].filter.showField === true){
              bObj[key] = rItem?.[propName];
            }
        }
      }
      this.state.RYS_D1_List?.coloumnHeader?.forEach((header, index) => {
          let selector = String.fromCharCode(97 + index);
          let key = this.columnHeader(header);
          cObj[key] = rItem?.[selector];
      });
      tList.push(Object.assign({}, aObj, bObj, cObj,dObj));
      return null;
    });
    let data = tList;
    if (type === "csv") {
      exportCSV(data, fileName,this.props.dateformat);
    } else if (type === "xlsx") {
      exportToExcel(data, fileName,this.props.dateformat);
    }
  };

  setFilterFields = (id, conditions) => {
    let xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcellproperty) {
        xobj[conditions[i].name].exportcellproperty =
          conditions[i].exportcellproperty;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      objFilterData.availableRYS_D1Fields = xobj;
    }
  };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions) => {
    console.log("onFilterChangeeeeee2",conditions);
    this.setFilterFields("F1", conditions);
    let data = {  };
    data.filtercolumns = conditions;
    this.setsvaluefordate(data)
    if (this.state.zoomChanged === 1) {
      this.setState({
        pageState: "RYS_D1",
        RYS_D1_List: [],
        orgRYS_D1_List: [],
        sel_D1_Data: {},
        RYSDataLoaded: true,
      });
    }
    this.getRYS_Conversation_List(data);
  };
  chartXAxisTitle = () => {
    let columnName = [];
    this.state.RYS_D1_List?.coloumnHeader.map((header) => { 
      if(header.includes("LBLWEEK")){
        let xVal = header.split(" ")[0]
        console.log(header.split(" "),"header")
        columnName.push(this.columnHeader(xVal));
      }else if(header.includes("LBL")){
        columnName.push(this.columnHeader(header));
    }else{
      columnName.push(header);
    }
    })
    console.log(columnName,"columnName")
  return columnName;
  }

  goBackHandler = () => {
    this.setState({showRysPage: false,rysUserData:[],sel_D1_Data:{}})
  }

  render() {
    const {
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLRYSREPORTEMPTYTXT,
      LBLRYSCONVERSATATION
    } = this.props.labels.labels;

    const totalConvoSum = this.state?.orgRYS_D1_List?.reduce((sum, convo) => {
      return sum + convo.totCon;
    }, 0);
    
    let customStyles = {  
      headCells: {
        style: {
          '&:last-child': {
            position: "sticky",
            right: 0,
            display: "flex",
            justifyContent: "end",
            minWidth:"150px",
          },
          '&:nth-child(1)': {
            left: "5px"
          },
          '&:nth-child(2)': {
            maxWidth: "300px"
          },
        }
      },
      cells: {
        style: {
          '&:last-child': {
            right: 0,
            position: "sticky",
            zIndex: 1000,
            minWidth:"150px",
            background: 'white',
          },

        },
      },
    };

    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    if (this.state.pageState === "RYS_D1") {
      let columns = this.getRYS_D1_Columns();
      let filterFields = this.getFilterColumns("RYS_D1", columns);
      let resetfilterFields = this.getResetFilterColumns("RYS_D1", columns);
      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < filterFields.length; j++) {
          if (
            filterFields[j] &&
            columns[i] &&
            filterFields[j].name === columns[i].name &&
            filterFields[j].filter.showField === false
          ) {
            columns.splice(i, 1);
            i--;
          }
        }
      }
      objFilterData.orgavailableRYS_D1Fields = this.getFilterColumns("RYS_D1", this.getRYS_D1_Columns());
      return (
        <div className="reportsCoversation">
          <div className="mob-pad">
            {!this.state.showRysPage ?
              <>
              <div style={{display:"flex",alignItems:"center"}}>
                <h3 className="page-title">
                  {LBLRYSCONVERSATATION}
                </h3>
              </div>
              <hr className="head-line" />
              </>
              :
              null
            }

            <div id="accordion" className="row rptlst-row">
              {this.state.loading === false &&
                this.state.filterDataStatus === true &&
                filterFields.length > 1 && !this.state.showRysPage? (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    {this.state.loading === false &&
                      this.state.filterDataLoaded === 1 &&
                      (filterFields.length > 1 || this.props.ssi_data_priv) ? (
                      <div>
                        <CustomFilter
                          availableFields={filterFields}
                          selectedFields={filterFields}
                          orgAvailableFields={resetfilterFields}
                          onFilterChange={this.onFilterChange}
                          onExport={this.onExport}
                          id="F1"
                          onRef={(ref) => (REFS.F1 = ref)}
                          labels={this.props.labels.labels}
                          reportName="RYSCONVERSATION"
                          companyName={this.props.company_name}
                          showFieldFilter={true}
                          showExport = {this.state.orgRYS_D1_List?.length > 0 ? true : false}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {this.state.orgRYS_D1_List?.length > 0 ?  (
                    <>
                    <div>
                    <RysConversationRecords
                    titleOne = {this.props.labels.labels?.LBLTOTALCONVERSATIONSRECORDED }
                    titleTwo = {this.props.labels.labels?.LBLSUPERVISORSINVOLVED }
                    totalRecordCount = {totalConvoSum}
                    totalSupervisorsCount = {this.state.orgRYS_D1_List?.length}
                    chartXAxisTitle = {this.chartXAxisTitle()}
                    chartXAxisCount = {this.state.RYS_D1_List?.coloumnCount}
                    colPeriod = {this.state.RYS_D1_List?.colPeriod}
                    labels = {this.props.labels.labels}
                    />
                  </div>
                  <h1 className={styles.leadertitle}>
                    {this.props.labels.labels?.LBLLEADERBOARD }
                  </h1>
                      <DataTable
                        columns={columns}
                        data={this.state.orgRYS_D1_List}
                        className="datatable-list rysConversation"
                        pagination
                        noDataComponent={<EmptyState image={emptyImage} text2={LBLRYSREPORTEMPTYTXT} />}
                        defaultSortField="rank"
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                      />
                    </>
                  ) :
                  <EmptyState image={emptyImage} text2={LBLRYSREPORTEMPTYTXT} />
                  }
                </div>
              ) :
              this.state.showRysPage && this.state.rysUserData.length > 0  ? 
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                <RateYourStateConversationPageTwo labels = {this.props.labels} rysUserData= {this.state.rysUserData} selDetail = {this.state.sel_D1_Data} goBackHandler = {this.goBackHandler} dateformat={this.props.dateformat}/>
              </div>
               :
              (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    <div>
                      <div>
                        <div>
                          <h3>
                            <Skeleton height={21} />
                          </h3>
                          <h2>
                            <Skeleton height={26} />
                          </h2>
                          <div>
                            <div>
                              <div className="align-items-top">
                                <div>
                                  <Skeleton height={19} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row zrowbox">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="form-group col-xl-3 col-lg-3 col-md-3 ml-auto mr-12">
                        <Skeleton height={34} />
                      </div>
                      <Skeleton height={400} />
                    </div>
                  </div>

                  <div className="bottomborder">
                    <span className="subHeader">
                      <h4>
                        <Skeleton height={38} />
                      </h4>
                    </span>
                  </div>
                  <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                    <div className="sc-fznWqX gnahTY">
                      <div className="sc-AxjAm gIMaKV rdt_Table">
                        <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                          <Skeleton height={56} />
                        </div>
                        <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                          <Skeleton height={480} />
                        </div>
                      </div>
                    </div>
                    <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                      <Skeleton height={56} />
                    </nav>
                  </div>
                  <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                    <header className="sc-fzoXzr hlidmt">
                      <Skeleton height={34} />
                    </header>
                    <div className="sc-fznWqX gnahTY">
                      <div className="sc-AxjAm gIMaKV rdt_Table">
                        <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                          {skel.map((i) => {
                            return (
                              <div
                                id={"row-" + i}
                                className="sc-fzoLsD doBktq rdt_TableRow"
                              >
                                <Skeleton height={280} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                      <div className="sc-fzpmMD ftIMtk">
                        <Skeleton height={40} />
                      </div>
                    </nav>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div >
      );
    } else {
      return <div>No Data for {this.state.pageState}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(RateYourStateConversation);
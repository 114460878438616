import React, { Component } from "react";
import { Field, reduxForm, change } from "redux-form";
import PhoneInput from "react-phone-input-2";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "react-phone-input-2/lib/style.css";
import { Modal } from "react-bootstrap"
import {
  required,
  email,
  maxLength255,
  checkSpace,
  minValue8,
  phoneno,
} from "../validate/Validate";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { bindActionCreators } from "redux";
import Loader from "../../components/loader/Loader";
import TrainingAssignment from "./trainingAssignment";
import AccessList from "./AccessRights";
import ChannelAssignment from "../../../ssifeeds/channels/managechannel/channelAssignment";
import { classList } from "dynamic-class-list";
import UsersService from "./UsersService";
import styles from "./userManagementForm.module.scss";
import $ from "jquery";

let role;
let groups;
let avlLicense = 1;
let countStatus = ""

class UserManagementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      groups: null,
      orgs: [],
      load: true,
      language: "",
      status: this.props.status,
      filename: "",
      isConformEmail: 0,
      showSecondaryEmail: false,
      editMail: true,
      editPassword: true,
      user_group: "",
      group_id: "",
      showTab: "info",
      manageMode: "profile",
      xuser_with_nomail: "nomail",
      login_with_phone_number: this.props.login_with_phone_number,
      disableEmailField: false,
      rp_submitbutton: false,
      reset_button_disable: false,
      showUserExesAlert: false,
      Sitetype:localStorage.getItem('site_type')
    };
  }

  componentDidMount() {
    let group_id = "";
    let editmode = false;
    console.log("this.props.initialValues", this.props.initialValues)
    if (this.props.initialValues !== null && this.props.initialValues.roles) {
      editmode = true;
      role = this.props.initialValues.roles
        ? this.props.initialValues.roles.split("~")
        : [];

      role = role.map((item) => {
        return item.split("-")[0].trim();
      });

      role = [...new Set(role)];

      this.props.setSelectedRoleIds(role);

      let obj = {};
      if (this.props.initialValues.groups.length > 0) {
        groups = this.props.initialValues.groups.reduce((acc, cur, i) => {
          cur = JSON.parse(cur);
          if (cur.group_id) {
            obj = { ...acc, [cur.group_type_id]: cur.group_id.toString() };
            return obj;
          }
          return null;
        }, {});
      }

      this.setState({
        editmode: editmode,
        roles: role,
        group_id: group_id.length > 0 ? group_id[0].toString() : "",
        groups: groups,
        language: this.props.initialValues.language,
        temppassword: this.props.initialValues
          ? this.props.initialValues.password
          : "oo",
      });

    }
    if (editmode) {
      let items = this.props.licenseDetails
      avlLicense = items.total_user_license - items.active_users
      countStatus = this.props.initialValues.status

    }
  }
  closeModalCount1=()=>{
    this.setState({
      showUserExesAlert:false
    })
  }
  renderToggleInput = (field) => (
    <Form.Check
        type="switch"
        id="custom-switch"
        label=""
        checked={field.input.value === "" ? true : field.input.value}
        onChange={(e) => { 
          if(!field.input.value && avlLicense === 0 && countStatus === false) { this.setState({ showUserExesAlert: true }) } else { field.input.onChange(e) }}}
        icons={false}
      />
    );
    
  UNSAFE_componentWillReceiveProps() {
    if (this.props.showEmailTab) {
      this.setState({ manageMode: "email" })
    }
  }
  componentWillUnmount() {
    this.setState({
      roles: [],
      groups: null,
      orgs: [],
      load: true,
      language: "",
      status: this.props.status,
      filename: "",
      isConformEmail: 0,
      showSecondaryEmail: false,
      editMail: true,
      editPassword: true,
      user_group: "",
      group_id: "",
      showTab: "info",
      manageMode: "profile",
      xuser_with_nomail: "nomail",
      login_with_phone_number: this.props.login_with_phone_number,
      disableEmailField: false,
      rp_submitbutton: false,
      reset_button_disable: false,
    });
  }

  setTab = (x) => {
    if (x === "training") {
      this.setState({
        showTab: x,
      });
    } else {
      this.setState({
        showTab: x,
      });
    }
  };
  toggleSwitch = () => {
    this.setState({ status: !this.state.status });
  };
  formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };
  handleCustomChange = () => { };
  genereatePassword = () => {
    UsersService.generatePassword(1)
      .then((res) => {
        this.props.change("password", res.data.message[0].password);
      })
      .catch((err) => {
        if (err) throw err;
      });
  };

  doEvent = (obj, event) => {
    /* Created by David@Refoua.me */
    var evt = new Event(event, { target: obj, bubbles: true });
    return obj ? obj.dispatchEvent(evt) : false;
  };

  ownSubmit = (e, values, gp_id) => {
    e.preventDefault();
    this.props.handleSubmit(this.state.group_id, 1);
  };

  checkPriEmailEnabled = (initialValues) => {
    if (
      (this.state.editMail && initialValues === null) ||
      (!this.state.editMail && (initialValues || {}).email_verified !== 1)
    ) {
      return "enable";
    } else {
      return "disable";
    }
  };
  checkSecEmailEnabled = (initialValues) => {
    if (
      (this.state.editMail && initialValues === null) ||
      (!this.state.editMail &&
        (initialValues || {}).email_verified === 1 &&
        (initialValues || {}).sec_email_verified !== 1)
    ) {
      return "enable";
    } else {
      return "disable";
    }
  };
  checkSecEmail2PriEnabled = (initialValues) => {
    if (
      !this.state.editMail &&
      (initialValues || {}).email_verified === 1 &&
      (initialValues || {}).sec_email_verified === 1
    ) {
      return "enable";
    } else {
      return "disable";
    }
  };
  changePwdVisible =()=> {
    var cntl = document.getElementsByName("password")[0];
    cntl.type = cntl.type === "password"? "text":"password";  
    if(cntl.type === "password"){
      $("#pwdvisibility i").removeClass("fa-eye-slash").addClass("fa-eye");
    }else{
      $("#pwdvisibility i").removeClass("fa-eye").addClass("fa-eye-slash");
    }
  }
  onKeyInput = (e) =>{
    let x = e.target.value;  
    $("#pwdvisibility").css({"display":x.length>0?"block":"none"});
  }
  renderInviteLink = (initialValues, xPref) => {
    if (
      this.state.editMail === false &&
      xPref === "primary" &&
      initialValues !== null &&
      initialValues.email !== null &&
      initialValues.email_invite_sent === 0 &&
      initialValues.email_verified !== 1
    ) {
      return (
        <i
          class=""
          onClick={this.props.sendInvitation.bind(
            this,
            initialValues,
            "primary"
          )}
          alt={this.props.labels.labels.LBLINVITE}
        >
          {this.props.labels.labels.LBLINVITE}
        </i>
      );
    } else if (
      this.state.editMail === false &&
      xPref === "primary" &&
      initialValues !== null &&
      initialValues.email !== null &&
      initialValues.email_invite_sent > 0 &&
      initialValues.email_verified !== 1
    ) {
      return (
        <i
          class=""
          onClick={this.props.sendInvitation.bind(
            this,
            initialValues,
            "primary"
          )}
          alt={this.props.labels.labels.LBLREINVITE}
        >
          {this.props.labels.labels.LBLREINVITE}
        </i>
      );
    } else if (
      this.state.editMail === false &&
      xPref === "secondary" &&
      initialValues !== null &&
      initialValues.s_email !== null &&
      initialValues.sec_email_invite_sent === 0 &&
      initialValues.sec_email_verified !== 1
    ) {
      return (
        <i
          class=""
          onClick={this.props.sendInvitation.bind(
            this,
            initialValues,
            "secondary"
          )}
          alt={this.props.labels.labels.LBLINVITE}
        >
          {this.props.labels.labels.LBLINVITE}
        </i>
      );
    } else if (
      this.state.editMail === false &&
      xPref === "secondary" &&
      initialValues !== null &&
      initialValues.s_email !== null &&
      initialValues.sec_email_invite_sent > 0 &&
      initialValues.sec_email_verified !== 1
    ) {
      return (
        <i
          class=""
          onClick={this.props.sendInvitation.bind(
            this,
            initialValues,
            "secondary"
          )}
          alt={this.props.labels.labels.LBLREINVITE}
        >
          {this.props.labels.labels.LBLREINVITE}
        </i>
      );
    } else {
      return "";
    }
  };

  renderField = ({
    input,
    label,  
    type,
    className, 
    onKeyUp,  
    meta: { touched, error, warning },
    ssi_errors,
    LBLUSERNAMEEXISTS,
    LBLEMAILEXISTS,
    readOnly,
    children,
    disable_field
  }) => {
    if (ssi_errors) {
      ssi_errors = ssi_errors.data ? ssi_errors.data : ssi_errors;
    }

    return (
      <div>
        <label className="label">{label}</label>
        <div className={styles.roleCheckBox}>
          {readOnly ? (
            <>
              <input
                {...input}
                placeholder={label}
                type={type}
                className={className}
                readOnly
              />
            </>
          ) : (
            <>
              <input
                {...input}
                disabled={disable_field}
                placeholder={label}
                type={type}
                className={className}  
                onKeyUp={onKeyUp} 
              />
              {/* {children} */}
            </>
          )}
          {touched &&
            ((error && (
              <span className="required-field ">
                {typeof error === "string"
                  ? this.props.labels.labels[error]
                  : this.props.labels.labels[error.label].replace(
                    "$$$",
                    error.val
                  )}
              </span>
            )) ||
              (warning !== undefined ? <span className="required-field ">{warning}</span> : null))}
          {ssi_errors &&
            ssi_errors.error !== undefined && ssi_errors.error.error_field === "user_name" &&
            ssi_errors.error.user_name === input["value"] ? (
            <p className="required-field ">{LBLUSERNAMEEXISTS}</p>
          ) : (
            ""
          )}
          {ssi_errors &&
            ssi_errors.error.error_field === "email" &&
            ssi_errors.error.email === input["value"] ? (
            <p className="required-field ">{LBLEMAILEXISTS}</p>
          ) : (
            ""
          )}
          {ssi_errors &&
            ssi_errors.error.error_field === "phone_number" &&
            ssi_errors.error.phone_number === input["value"] ? (
            <p className="required-field ">
              {this.props.labels.labels.LBLPHONENUMBEREXISTS}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  renderGroupSelectField = ({
    input,
    label,
    name,
    g_value,
    type,
    id,
    className,
    meta: { touched, error },
    children,
    onChng,
  }) => {
    return (
      <div>
        <label className="label">{label}</label>
        <div>
          <select
            value={g_value}
            name={name}
            id={id}
            className={className}
            onChange={onChng}
          >
            {children}
          </select>
          {touched && error && (
            <span className="required-field ">
              {this.props.labels.labels.LBLREQUIRED}
            </span>
          )}
        </div>
      </div>
    );
  };
  renderSelectField = ({
    input,
    label,
    name,
    g_value,
    type,
    id,
    className,
    meta: { touched, error },
    children,
    onChng,
  }) => {
    return (
      <div>
        <label className="label">{label}</label>
        <div>
          <select
            value={g_value}
            name={name}
            id={id}
            className={className}
            onChange={onChng}
            {...input}
          >
            {children}
          </select>
          {touched && error && (
            <span className="required-field ">
              {this.props.labels.labels.LBLREQUIRED}
            </span>
          )}
        </div>
      </div>
    );
  };
  getElementValue = (xname) => {
    let li = document.getElementsByTagName("input");
    for (let i = 0; i < li.length; i++) {
      if (li[i].name === xname) {
        return li[i].value;
      }
    }
    return undefined;
  };

  setOptionlabel = (option) => {
    return option.translated === false
      ? this.props.labels.labels[
      option.groups_by_type["lbl_" + option.groups_by_type.group_name]
      ]
      : option.groups_by_type["lbl_" + option.groups_by_type.group_name];
  };

  render() {
    const {
      handleSubmit,
      submitting,
      roles,
      initialValues,
      ssi_errors,
      roleValid,
      changesIn,
      mailPhoneValid,
    } = this.props;

    const {
      LBLFIRSTNAME,
      LBLLASTNAME,
      LBLROLE,
      LBLVERIFIED,
      LBLREJECTED,
      LBLPENDING,
      LBLREQUIRED,
      LBLLANGUAGE,
      LBLPRIMARYEMAIL,
      LBLPASSWORD,
      LBLSUBMIT,
      LBLCANCEL,
      LBLUSERNAMEEXISTS,
      LBLEMAILEXISTS,
      LBLPHONENUMBER,
      LBLSTATUS,
      LBLUSERNAME,
      LBLNOOPTIONSDROPDOWN
    } = this.props.labels.labels;
    let LABELS = this.props.labels.labels;
    let LABELS_LBLMAILPHONE = LABELS.LBLMAILPHONE;
    let LABELS_LBLEMAILORPHONEREQUIRED = LABELS.LBLEMAILORPHONEREQUIRED;
    if (this.state.login_with_phone_number === 0) {
      LABELS_LBLMAILPHONE = LABELS.LBLEMAIL;
      LABELS_LBLEMAILORPHONEREQUIRED = LABELS.LBLEMAILREQUIRED;
    }

    return (
      <div>
        {initialValues === null ? (
          <></>
        ) : (
          <>
            <div className="row col-sm-12 password_section" style={{ display: "inline-block" }}>
              <div className={styles.imgContents}>
                <img src="../images/edit-user-profile.png" alt=""></img>
                <div className={styles.imgContOnly}>
                  <h5>
                    {initialValues.first_name}  {initialValues.last_name}
                  </h5>
                  <h5>{initialValues.email}</h5>
                  {this.state.login_with_phone_number === 1 ? (
                    <>
                      <h5>{initialValues.phone_number}</h5>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {initialValues !== null ? (
          <>
            <div
              className="col-sm-12"
              style={{ marginTop: "15px", padding: "0px" }}
            >
              <ul class="nav nav-tabs">
                <li
                  className={classList([
                    this.state.showTab === "info" ? "active" : "",
                  ])}
                >
                  <div onClick={this.setTab.bind(this, "info")}>
                    {LABELS.LBLINFO}
                  </div>
                </li>
                <li              
                  className={classList([
                    this.state.showTab === "training" ? "active" : "",
                  ])}
                >
                  <div onClick={this.setTab.bind(this, "training")}>
                    {LABELS.LBLTRAINING}
                  </div>
                </li>
                <li
                  className={classList([
                    this.state.showTab === "channels" ? "active" : "",
                  ])}
                >
                  <div onClick={this.setTab.bind(this, "channels")}>
                    {LABELS.LBLCHANNELS}
                  </div>
                </li>{" "}
                {initialValues !== null &&
                  this.props.userId !== initialValues.user_id &&
                  initialValues.roles ? (
                  initialValues.roles.lastIndexOf("LBLADMINISTRATOR") > 0 ||
                    initialValues.roles.lastIndexOf("LBLSUPERVISOR") > 0 ||
                    initialValues.roles.lastIndexOf("LBLREPORTMANAGER") > 0 ? (
                    <li
                      className={classList([
                        this.state.showTab === "accessrights" ? "active" : "",
                      ])}
                    >
                      <div onClick={this.setTab.bind(this, "accessrights")}>
                        {LABELS.LBLDATAPERMISSIONS}
                      </div>
                    </li>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </ul>
            </div>
          </>
        ) : (
          <></>
        )}
        {this.state.showTab === "info" && initialValues !== null ? (
          <div className={styles.subHeading}>
            <ul
              className="nav nav-sub-tabs"
              id="sub-heading"
            >
              {this.state.showTab === "info" && initialValues !== null ? (
                <>
                  <li
                    style={{ paddingBottom: "0px", borderRadius: "0" }}
                    type="button"
                    className={classList([
                      this.state.manageMode === "profile"
                        ? "active  cst active_cst"
                        : "cst",
                    ])}
                    onClick={() => {
                      this.setState({
                        manageMode: "profile",
                      });
                    }}
                  >
                    <div>{LABELS.LBLPROFILEINFORMATION}</div>
                  </li>
                  <li
                    style={{ paddingBottom: "0px", borderRadius: "0" }}
                    type="button"
                    className={classList([
                      this.state.manageMode === "email"
                        ? "active  cst active_cst"
                        : " cst",
                    ])}
                    onClick={() => {
                      this.setState({
                        manageMode: "email",
                      });
                    }}
                  >
                    <div>{LABELS_LBLMAILPHONE}</div>
                  </li>
                  <li
                    style={{ paddingBottom: "0px", borderRadius: "0" }}
                    type="button"
                    className={classList([
                      this.state.manageMode === "password"
                        ? "active  cst active_cst"
                        : " cst",
                    ])}
                    onClick={() => {
                      this.setState({
                        manageMode: "password",
                      });
                    }}
                  >
                    <div>{LABELS.LBLRESETPASSWORD}</div>
                  </li>{" "}
                </>
              ) : (
                ""
              )}
            </ul>
          </div>
        ) : (
          <></>
        )}

        {this.state.showTab === "info" ? (
          <>
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              {!this.props.helpersLoaded ? (
                <Loader></Loader>
              ) : (
                <>
                  {this.state.manageMode === "email" &&
                    initialValues !== null ? (
                    <>
                      <form
                        className="form"
                        onSubmit={(values) => {
                          handleSubmit(values);
                        }}
                      >
                        <div className="">
                          <div className="row">
                            {
                              <>
                                <>
                                  <div
                                    className={`col-sm-7 ${styles.mng_email}`}
                                    style={{ display: "inline-block" }}
                                  >
                                    {
                                      <>
                                        <div className="form-group">
                                          <Field
                                            name="username"
                                            type="text"
                                            component={this.renderField}
                                            label={LBLUSERNAME}
                                            className={
                                              `input-field ${styles.inputFieldWidth}`
                                            }
                                            LBLUSERNAMEEXISTS={
                                              LBLUSERNAMEEXISTS
                                            }
                                            validate={
                                              this.props.enableEditUsername ||
                                                this.state.roles.indexOf("1") ===
                                                -1
                                                ? [required, maxLength255]
                                                : null
                                            }
                                            ssi_errors={ssi_errors}
                                            readOnly={
                                              this.props.enableEditUsername
                                                ? false
                                                : true
                                            }
                                            onKeyPress={() => {
                                              return false;
                                            }}
                                          />
                                        </div>
                                      </>
                                    }

                                    {this.state.login_with_phone_number ===
                                      1 ? (
                                      <>
                                        <div className="form-group">
                                          <label className="label">{LBLPHONENUMBER}</label>
                                          <PhoneInput
                                            placeholder=""
                                            localization={
                                              this.props.lang_code.split("-")[0]
                                            }
                                            inputStyle={{ width: "100%" }}
                                            inputProps={{
                                              name: "phone_number2",
                                            }}
                                            countryCodeEditable={false}
                                            value={initialValues.phone_number}
                                            excludeCountries={["us"]}
                                            regions={[
                                              "america",
                                              "europe",
                                              "asia",
                                            ]}
                                            onChange={(
                                              value,
                                              country,
                                              e,
                                              formattedValue
                                            ) => {
                                              let li =
                                                document.getElementsByTagName(
                                                  "input"
                                                );
                                              for (
                                                let i = 0;
                                                i < li.length;
                                                i++
                                              ) {
                                                if (
                                                  li[i].name === "phone_number"
                                                ) {
                                                  li[i].value = value;
                                                  this.doEvent(li[i], "blur");
                                                }
                                              }
                                            }}
                                          />
                                          {ssi_errors &&
                                            ssi_errors.error.error_field ===
                                            "phone_number" &&
                                            ssi_errors.error.phone_number ===
                                            this.getElementValue(
                                              "phone_number"
                                            ) ? (
                                            <p className="required-field ">
                                              {
                                                this.props.labels.labels
                                                  .LBLPHONENUMBEREXISTS
                                              }
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div
                                          className="form-group col-sm-7"
                                          style={{ display: "none" }}
                                        >
                                          <Field
                                            name="phone_number"
                                            type="text"
                                            component={this.renderField}
                                            label={LBLPHONENUMBER}
                                            className={`input-field ${styles.inputFieldWidth}`}
                                            onChange={() => {
                                              if (initialValues) {
                                                changesIn("name");
                                              }
                                            }}
                                            validate={[phoneno]}
                                            ssi_errors={ssi_errors}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {mailPhoneValid ? (
                                      <span
                                        className="required-field "
                                        style={{
                                          float: "left",
                                          marginTop: "-10px",
                                          display: "inline-block",
                                        }}
                                      >
                                        {LABELS_LBLEMAILORPHONEREQUIRED}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className={`col-sm-3 ${styles.mng_email}`}>
                                    <div
                                      variant="link"
                                      onClick={
                                        this.props.EditUserNameConfirmation
                                      }
                                      className="link-btn"
                                    >
                                      {this.props.labels.labels.LBLEDIT}
                                    </div>
                                  </div>
                                </>
                              </>
                            }
                            <div
                              className={`col-sm-7 ${styles.mng_email}`}
                              style={{ display: "inline-block" }}
                            >
                              <div className="form-group">
                                <Field
                                  name="email"
                                  type="text"
                                  component={this.renderField}
                                  label={LBLPRIMARYEMAIL}
                                  className={`input-field ${styles.inputFieldWidth}`}
                                  validate={
                                    this.props.emailDisable
                                      ? []
                                      : this.state.roles.indexOf("1") === 0 ? [email, required, checkSpace] : [email, checkSpace]
                                  }
                                  disabled={
                                    this.checkPriEmailEnabled(initialValues) ===
                                    "disable"
                                  }
                                  onChange={() => {
                                    if (initialValues) {
                                      changesIn("email");
                                    }
                                  }}
                                  onkeypress="return avoidSpace(event)"
                                  ssi_errors={ssi_errors}
                                  LBLEMAILEXISTS={LBLEMAILEXISTS}
                                >
                                  <span
                                    style={{
                                      float: "right",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {initialValues !== null &&
                                      (initialValues || {}).email !== null ? (
                                      <i
                                        style={{ fontSize: "20px" }}
                                        title={`Email verification:${(initialValues || {})
                                          .email_verified === 1
                                          ? LBLVERIFIED
                                          : (initialValues || {})
                                            .email_verified === 0
                                            ? LBLREJECTED
                                            : LBLPENDING
                                          }`}
                                        class={
                                          (initialValues || {})
                                            .email_verified === 1
                                            ? "fas fa-check-circle"
                                            : (initialValues || {})
                                              .email_verified === 0
                                              ? "fas fa-times-circle"
                                              : "fas fa-pause-circle"
                                        }
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  <span
                                    className="icoBtn"
                                    style={{ float: "right" }}
                                  >
                                    {this.renderInviteLink(
                                      initialValues,
                                      "primary"
                                    )}
                                  </span>
                                </Field>
                              </div>
                            </div>
                            <div
                              className="col-sm-12"
                              style={{ display: "inline-block" }}
                            >
                              <button
                                type="submit"
                                className="button-style primary-btn"
                                disabled={submitting}
                                style={{ float: "right" }}
                              >
                                {LBLSUBMIT}
                              </button>
                              <button
                                type="button"
                                className="button-style secondary-btn"
                                id="btnaddCancel"
                                onClick={this.props.closeModal}
                                style={{ float: "right", marginRight: "10px" }}
                              >
                                {LBLCANCEL}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.manageMode === "password" &&
                    initialValues !== null ? (
                    <>
                      <form
                        className="form"
                        name="resetpassword"
                        onSubmit={(values) => {
                          if (values.target[0].value) {
                            this.setState({
                              reset_button_disable: true,
                            });
                            setTimeout(() => {
                              this.setState({
                                reset_button_disable: false,
                              });
                            }, 1000);
                          }
                          handleSubmit(values);
                        }}
                      >
                        <div className="">
                          <div className="row" style={{ display: "block" }}>
                            <div
                              className="mng_password col-sm-7"
                              style={{ display: "inline-block" }}
                            >
                              <div className="form-group">
                                <Field
                                  name="password"
                                  type="text"
                                  component={this.renderField}
                                  label={LBLPASSWORD}
                                  className={`input-field ${styles.inputFieldWidth}`}
                                  validate={[required, minValue8]}
                                  //onKeyUp={this.onKeyInput}
                                />  
                                 {/* <span id="pwdvisibility" className={styles.pwdvisibility} onClick={this.changePwdVisible} ><i class="fas fa-eye"></i></span>  */}
                              </div>
                            </div>
                            <div
                              className="mng_password col-sm-7"
                              style={{
                                marginTop: "-5px",
                                paddingTop: "-5px",
                                display: "inline-block",
                              }}
                            >
                              <div className="form-group">
                                <div
                                  class="link-btn"
                                  onClick={(values) => {
                                    this.genereatePassword(1);
                                  }}
                                  style={{ padding: "0" }}
                                >
                                  {LABELS.LBLGENERATEPASSWORD}
                                </div>
                              </div>
                            </div>
                            <div
                              className="mng_password col-xl-12"
                              style={{ display: "inline-block" }}
                            >
                              <div className="form-group usr-radio usr-radio-primary">
                                <Field
                                  type="checkbox"
                                  label={LABELS.LBLFORCECHANGEPASSWORD}
                                  init_value={""}
                                  name="force_password_change"
                                  id="force_password_change"
                                  component={renderCheckField}
                                />
                              </div>
                              {(this.state.login_with_phone_number === 1 &&
                                (initialValues.email_verified ||
                                  initialValues.phone_number_verified)) ||
                                (this.state.login_with_phone_number === 0 &&
                                  initialValues.email_verified) ? (
                                <>
                                  <div className="form-group usr-radio usr-radio-primary">
                                    <Field
                                      type="checkbox"
                                      label={LABELS.LBLNOTIFYNEWPASSWORD}
                                      init_value={""}
                                      name="notify_password"
                                      id="notify_password"
                                      component={renderCheckField}
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              className="col-sm-12"
                              style={{ display: "inline-block" }}
                            >
                              <button
                                type="submit"
                                className="button-style primary-btn"
                                ref="btn"
                                disabled={this.state.reset_button_disable}
                                style={{ float: "right" }}
                              >
                                {LBLSUBMIT}
                              </button>
                              <button
                                type="button"
                                className="button-style secondary-btn"
                                id="btnaddCancel"
                                onClick={this.props.closeModal}
                                style={{ float: "right", marginRight: "10px" }}
                              >
                                {LBLCANCEL}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.manageMode === "profile" ||
                    initialValues === null ? (
                    <>
                      <form
                        className="form"
                        onSubmit={(values) => {
                          values.isAdmin = 1;
                          let drpDownValues = {
                            language: this.state.language,
                          };
                          this.props.setSelectedDropDownValues(drpDownValues);
                          handleSubmit(values);
                        }}
                      >
                        <div className="row">
                          <div
                            className={styles.usrFrmList}
                            style={{
                              marginTop: initialValues !== null ? "" : "20px",
                              verticalAlign: "top",
                            }}
                          >
                            <div
                              className="col-sm-7"
                              style={{ verticalAlign: "top" }}
                            >
                              <div className="form-group">
                                <Field
                                  name="first_name"
                                  type="text"
                                  component={this.renderField}
                                  label={LBLFIRSTNAME}
                                  className={`input-field ${styles.inputFieldWidth}`}
                                  validate={[required, maxLength255]}
                                  onChange={() => {
                                    if (initialValues) {
                                      changesIn("name");
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="col-sm-7"
                              style={{ verticalAlign: "top" }}
                            >
                              <div className="form-group">
                                <Field
                                  name="last_name"
                                  type="text"
                                  component={this.renderField}
                                  label={LBLLASTNAME}
                                  className={`input-field ${styles.inputFieldWidth}`}
                                  onChange={() => {
                                    if (initialValues) {
                                      changesIn("name");
                                    }
                                  }}
                                  validate={[required, maxLength255]}
                                />
                              </div>
                            </div>

                            <div
                              className="col-sm-12"
                              style={{ verticalAlign: "top" }}
                            >
                              <div className="form-group">
                                <label className="label">{LBLROLE}</label>
                                <div
                                  className="form-group "
                                  style={{ display: "block" }}
                                >
                                  {roles.map((option, index) => {
                                    let label = this.props.labels.labels[option.role_name];
                                    return (
                                      <div
                                        key={option.role_id}
                                      >
                                        {initialValues ? (
                                          <Field
                                            type="checkbox"
                                            label={
                                              label
                                            }
                                            name={"checkbox-" + option.role_id}
                                            id={"checkbox-" + option.role_id}
                                            checked_bool={
                                              this.state.roles.indexOf(
                                                option.role_id.toString()
                                              ) !== -1
                                            }
                                            component={renderCheckField}
                                            onChange={(ev) => {
                                              let id = ev.target.id
                                                .split("-")[1]
                                                .toString();
                                              changesIn("roles");

                                              var newValue = [
                                                ...this.state.roles,
                                              ];
                                              if (ev.target.checked) {
                                                newValue.push(id);
                                              } else {
                                                newValue.splice(
                                                  newValue.indexOf(id),
                                                  1
                                                );
                                              }
                                              this.props.setSelectedRoleIds(
                                                newValue
                                              );
                                              this.setState({
                                                roles: newValue,
                                              });

                                              if (id === "1") {
                                                if (
                                                  ev.target.value === "false" ||
                                                  index === -1
                                                ) {
                                                  // console.log("======3");
                                                  this.setState({
                                                    isConformEmail: 1,
                                                  });
                                                } else {
                                                  // console.log("======2");
                                                  this.setState({
                                                    isConformEmail: 0,
                                                  });
                                                }
                                              } else {
                                                // console.log("======1");
                                                this.setState({
                                                  isConformEmail: 0,
                                                });
                                              }
                                            }}
                                          />
                                        ) : (
                                          <Field
                                            type="checkbox"
                                            label={
                                              label
                                            }
                                            init_value={""}
                                            name={"checkbox-" + option.role_id}
                                            id={"checkbox-" + option.role_id}
                                            defaultChecked={option.role_id === 4 ? true : false}
                                            onChange={(ev) => {
                                              if (option.role_id === 1) {
                                                if (
                                                  ev.target.value === "false" ||
                                                  ev.target.value === ""
                                                ) {
                                                  this.setState({
                                                    isConformEmail: 1,
                                                    disableEmailField: false,
                                                  });

                                                  this.props.enableEmailField();
                                                } else {
                                                  this.setState({
                                                    isConformEmail: 0,
                                                    disableEmailField: true,
                                                  });
                                                }
                                              }
                                            }}
                                            component={renderCheckField}
                                          />
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                                {roleValid ? (
                                  <span className="required-field ">
                                    {LBLREQUIRED}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {this.props.languages ? (
                              <div className="col-sm-7">
                                <div className="form-group">
                                  <label>
                                    {LBLLANGUAGE}
                                  </label>
                                  <Autocomplete
                                    id="combo-box-demo"
                                    aria-required="true"
                                    //required
                                    noOptionsText={LBLNOOPTIONSDROPDOWN}
                                    disableClearable={this.state.editmode}
                                    options={this.props.languages}
                                    getOptionLabel={(option) =>
                                      option.language_name
                                    }
                                    value={
                                      this.state.language
                                        ? this.props.languages.find(
                                          (option) => {
                                            return (
                                              this.state.language ===
                                              option.language_code
                                            );
                                          }
                                        ) ?? null
                                        : null
                                    }
                                    onChange={(e, i) => {
                                      this.setState({
                                        language: i ? i.language_code : "",
                                      });
                                    }}
                                    renderOption={(option, i) => (
                                      <div style={{ fontSize: "14px" }}>
                                        {option.language_name}
                                      </div>
                                    )}
                                    renderInput={(params) => (
                                      <div>
                                        <TextField
                                          placeholder={
                                            this.props.labels.labels.LBLSELECT
                                          }
                                          {...params}
                                        />
                                      </div>
                                    )}
                                  />
                                </div>
                              </div>
                            ) : (
                              "null"
                            )}

                            {this.props.groups_by_type
                              ? this.props.groups_by_type.map((item, index) => {
                                if (item.groups_by_type) {
                                  let group_info = item.group_info;
                                  let groups_by_type = item.groups_by_type;
                                  if (typeof item.group_info === "string") {
                                    group_info = JSON.parse(item.group_info);
                                  }
                                  return (
                                    <div className="col-sm-7">
                                      <div className="form-group">
                                        <label>
                                          {item.translated === false
                                            ? this.props.labels.labels[
                                            group_info[
                                            "lbl_" + item.group_type_id
                                            ]
                                            ]
                                            : group_info[
                                            "lbl_" + item.group_type_id
                                            ]}
                                        </label>
                                        {/* //{console.log("groupssss", this.state.groups)} */}
                                        <Autocomplete
                                          id="combo-box-demo"
                                          aria-required="true"
                                          noOptionsText={LBLNOOPTIONSDROPDOWN}
                                          options={JSON.parse(groups_by_type)}
                                          getOptionLabel={(option) =>
                                            option.group_name
                                          }

                                          getOptionSelected={(
                                            option,
                                            value
                                          ) =>
                                            option.group_name ===
                                            value.group_name
                                          }
                                          value={
                                            this.state.groups &&
                                              this.state.groups[
                                              item.group_type_id
                                              ]
                                              ? JSON.parse(
                                                item.groups_by_type
                                              ).find((option) => {
                                                return (
                                                  Number(this.state.groups[
                                                    item.group_type_id
                                                  ]) === Number(option.group_id)
                                                );
                                              }) ?? null
                                              : null
                                          }
                                          onChange={(e, product) => {
                                            if (initialValues) {
                                              changesIn("groups");
                                            }
                                            this.setState({
                                              groups: {
                                                ...this.state.groups,
                                                [item.group_type_id]: product
                                                  ? product.group_id
                                                  : "",
                                              },
                                            });
                                            this.props.change(
                                              item.group_type_id,
                                              product ? product.group_id : ""
                                            );
                                          }}
                                          renderOption={(option) => (
                                            <div style={{ fontSize: "14px" }}>
                                              {option.group_name}
                                            </div>
                                          )}
                                          renderInput={(params) => (
                                            <div>
                                              <TextField
                                                style={{
                                                  fontSize: "14px",
                                                  fontWeight: "bold",
                                                  color: "red",
                                                }}
                                                placeholder={
                                                  this.props.labels.labels
                                                    .LBLSELECT
                                                }
                                                {...params}
                                              />
                                            </div>
                                          )}
                                        />
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              })
                              : "Group List is empty"}
                            <div
                              className="col-sm-2"
                              style={{ verticalAlign: "top" }}
                            >
                              <div className="form-group xusr-act-sts">
                                <div className="userstatus usr-mang-datatable">
                                  <label className="label">{LBLSTATUS}</label>
                                  <Field
                                    name="status"
                                    id="custom-switch"
                                    label="test"
                                    component={this.renderToggleInput}
                                    onChange={(e) => { this.setState({ status: !e.target.value }) }}
                                  />
                                </div>
                              </div>
                            </div>

                            {initialValues === null ? (
                              <>
                                {this.state.xuser_with_nomail === "nomail" ? (
                                  <>
                                    <div
                                      className="row"
                                      style={{ marginLeft: 0 }}
                                    >
                                      <div
                                        className="col-sm-7"
                                        style={{ verticalAlign: "top" }}
                                      >
                                        <div className="form-group">
                                          <Field
                                            name="email"
                                            type="text"
                                            component={this.renderField}
                                            label={LBLPRIMARYEMAIL}
                                            className={`input-field ${styles.inputFieldWidth}`}
                                            value={""}
                                            disabled={
                                              this.checkPriEmailEnabled(
                                                initialValues
                                              ) === "disable"
                                            }
                                            validate={
                                              this.props.emailDisable
                                                ? []
                                                : [email, required, checkSpace]
                                            }
                                            onChange={() => {
                                              if (initialValues) {
                                                changesIn("email");
                                              }
                                            }}
                                            disable_field={
                                              this.props.emailDisable
                                            }
                                            ssi_errors={ssi_errors}
                                            LBLEMAILEXISTS={LBLEMAILEXISTS}
                                          ></Field>
                                        </div>
                                      </div>
                                      <div
                                        className="col-sm-5"
                                        style={{
                                          verticalAlign: "top",
                                          margin: "auto",
                                          paddingTop: "25px",
                                        }}
                                      >
                                        <div className="form-group usr-radio usr-radio-primary">
                                          <Field
                                            type="checkbox"
                                            label={LABELS.LBLINVITELATER}
                                            init_value={""}
                                            name="invite_later"
                                            id="invite_later"
                                            component={renderCheckField}
                                            disable_field={
                                              this.props.emailDisable
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="col-sm-7"
                                      style={{ display: "inline-block" }}
                                    >
                                      {initialValues === null &&
                                        this.state.isConformEmail === 0 ? (
                                        <>
                                          <div className="form-group usr-radio usr-radio-primary">
                                            <Field
                                              type="checkbox"
                                              label={LABELS.LBLUSERNOTHAVEEMAIL}
                                              init_value={""}
                                              name="user_with_nomail"
                                              id="user_with_nomail"
                                              component={renderCheckField}
                                              disabled={
                                                this.state.disableEmailField
                                              }
                                              onChange={(ev) => {
                                                if (initialValues) {
                                                  changesIn("name");
                                                }
                                                this.props.disableEmailFunc();
                                              }}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                    {this.state.isConformEmail === 1 ||
                                      this.state.isConformEmail === 0 ? (
                                      <div
                                        className="col-sm-7"
                                        style={{ verticalAlign: "top" }}
                                      >
                                        <div className="form-group">
                                          <Field
                                            name="username"
                                            type="text"
                                            component={this.renderField}
                                            label={LBLUSERNAME}
                                            className={`input-field ${styles.inputFieldWidth}`}
                                            ssi_errors={ssi_errors}
                                            LBLUSERNAMEEXISTS={
                                              LBLUSERNAMEEXISTS
                                            }
                                            validate={
                                              this.props.emailDisable
                                                ? [required, maxLength255]
                                                : [maxLength255]
                                            }
                                            onChange={() => {
                                              if (initialValues) {
                                                changesIn("name");
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {this.props.emailDisable &&
                                      this.state.isConformEmail !== 1 ? (
                                      <>
                                        <div
                                          className="col-sm-7"
                                          style={{ verticalAlign: "top" }}
                                        >
                                          <div
                                            className=" form-group pwd-only"
                                            style={{
                                              paddingLeft: "0",
                                              marginBottom: "0px",
                                            }}
                                          >
                                            <Field
                                              name="password"
                                              type="text"
                                              component={this.renderField}
                                              label={LBLPASSWORD}
                                              value={""}
                                              className={`input-field ${styles.inputFieldWidth}`}
                                              validate={[required, minValue8]}
                                              //onKeyUp={this.onKeyInput}
                                            /> 
                                            {/* <span id="pwdvisibility" className={styles.pwdvisibility} onClick={this.changePwdVisible} ><i class="fas fa-eye"></i></span>  */}

                                          </div>
                                          <div
                                            className="col-sm-12 form-group"
                                            style={{
                                              padding: "0",
                                              display: "inline-block",
                                            }}
                                          >
                                            <div
                                              className="form-group btn-div"
                                              style={{ marginBottom: "0px" }}
                                            >
                                              <div
                                                class="link-btn"
                                                onClick={(values) => {
                                                  this.genereatePassword(1);
                                                }}
                                                style={{ padding: "6px 12px 6px 0px" }}
                                              >
                                                {LABELS.LBLGENERATEPASSWORD}
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                        {initialValues === null ? (
                                          <div
                                            className="col-sm-12 form-group usr-radio usr-radio-primary"
                                            style={{
                                              margin: "auto",
                                              display: "block",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <div
                                              className="col-xl-12 force-only"
                                              style={{
                                                paddingRight: "0px !important",
                                              }}
                                            >
                                              {" "}
                                              <Field
                                                type="checkbox"
                                                label={
                                                  LABELS.LBLFORCECHANGEPASSWORD
                                                }
                                                init_value={""}
                                                name="force_password_change"
                                                id="force_password_change"
                                                component={renderCheckField}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <div></div>
                                        )}
                                      </>
                                    ) : null}

                                    {this.state.login_with_phone_number ===
                                      1 ? (
                                      <>
                                        <div className="col-sm-7">
                                          <div className="form-group">
                                            <label className="label">{LBLPHONENUMBER}</label>
                                            <PhoneInput
                                              placeholder=""
                                              localization={
                                                this.props.lang_code.split(
                                                  "-"
                                                )[0]
                                              }
                                              inputStyle={{ width: "100%" }}
                                              inputProps={{
                                                name: "phone_number2",
                                              }}
                                              countryCodeEditable={false}
                                              excludeCountries={["us"]}
                                              regions={[
                                                "america",
                                                "europe",
                                                "asia",
                                              ]}
                                              onChange={(
                                                value,
                                                country,
                                                e,
                                                formattedValue
                                              ) => {
                                                let li =
                                                  document.getElementsByTagName(
                                                    "input"
                                                  );
                                                for (
                                                  let i = 0;
                                                  i < li.length;
                                                  i++
                                                ) {
                                                  if (
                                                    li[i].name ===
                                                    "phone_number"
                                                  ) {
                                                    li[i].value = value;
                                                    this.doEvent(li[i], "blur");
                                                  }
                                                }
                                              }}
                                            />
                                            {ssi_errors &&
                                              ssi_errors.error.error_field ===
                                              "phone_number" &&
                                              ssi_errors.error.phone_number ===
                                              this.getElementValue(
                                                "phone_number"
                                              ) ? (
                                              <p className="required-field ">
                                                {" "}
                                                {
                                                  this.props.labels.labels
                                                    .LBLPHONENUMBEREXISTS
                                                }{" "}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>

                                          <div
                                            className="form-group"
                                            style={{ display: "none" }}
                                          >
                                            <Field
                                              name="phone_number"
                                              type="text"
                                              component={this.renderField}
                                              label={LBLPHONENUMBER}
                                              value={""}
                                              className={`input-field ${styles.inputFieldWidth}`}
                                              onChange={() => {
                                                if (initialValues) {
                                                  changesIn("phone_number");
                                                }
                                              }}
                                              validate={[phoneno]}
                                              ssi_errors={ssi_errors}
                                            />
                                          </div>
                                        </div>

                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {mailPhoneValid ? (
                                      <span
                                        className="required-field "
                                        style={{
                                          float: "left",
                                          marginTop: "-10px",
                                          display: "inline-block",
                                        }}
                                      >
                                        {LABELS_LBLEMAILORPHONEREQUIRED}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="col-sm-12">
                                      <h2 style={{ marginTop: "20px" }}>
                                        {LABELS.LBLUSERNAME}
                                      </h2>
                                    </div>
                                    <div
                                      className="col-sm-7"
                                      style={{ verticalAlign: "top" }}
                                    >
                                      <div className="form-group">
                                        <Field
                                          name="username"
                                          type="text"
                                          component={this.renderField}
                                          label={LBLUSERNAME}
                                          className={`input-field ${styles.inputFieldWidth}`}
                                          ssi_errors={ssi_errors}
                                          LBLUSERNAMEEXISTS={LBLUSERNAMEEXISTS}
                                          validate={[required, maxLength255]}
                                          onChange={() => {
                                            if (initialValues) {
                                              changesIn("name");
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-sm-12">
                                      <h2 style={{ marginTop: "0px" }}>
                                        {LABELS.LBLPASSWORD}
                                      </h2>
                                    </div>
                                    <div className="col-xl-12">
                                      <div className="usr-pwd">

                                        <div
                                          className="col-sm-7 form-group pwd-only"
                                          style={{
                                            paddingLeft: "0",
                                            display: "inline-block",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          <Field
                                            name="password"
                                            type="text"
                                            component={this.renderField}
                                            label={LBLPASSWORD}
                                            value={""}
                                            className={`input-field ${styles.inputFieldWidth}`}
                                            validate={[required, minValue8]}
                                          />
                                        </div>
                                        <div
                                          className="col-sm-7 form-group"
                                          style={{
                                            padding: "0",
                                            display: "inline-block",
                                          }}
                                        >
                                          <div
                                            className="form-group btn-div"
                                            style={{ marginBottom: "0px" }}
                                          >
                                            <button
                                              type="button"
                                              class="link-btn"
                                              onClick={(values) => {
                                                this.genereatePassword(1);
                                              }}
                                              style={{ paddingLeft: "0" }}
                                            >
                                              {LABELS.LBLGENERATEPASSWORD}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      {initialValues === null ? (
                                        <div
                                          className="col-sm-12 form-group usr-radio usr-radio-primary"
                                          style={{ paddingLeft: "0" }}
                                        >
                                          <div
                                            className="col-xl-12 force-only"
                                            style={{
                                              paddingRight: "0px !important",
                                            }}
                                          >
                                            {" "}
                                            <Field
                                              type="checkbox"
                                              label={
                                                LABELS.LBLFORCECHANGEPASSWORD
                                              }
                                              init_value={""}
                                              name="force_password_change"
                                              id="force_password_change"
                                              component={renderCheckField}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}

                            <div className="col-sm-12">
                              <button
                                type="submit"
                                className="button-style primary-btn"
                                disabled={submitting}
                                style={{ float: "right" }}
                              >
                                {LBLSUBMIT}
                              </button>
                              <button
                                type="button"
                                className="button-style secondary-btn"
                                id="btnaddCancel"
                                onClick={this.props.closeModal}
                                style={{ float: "right", marginRight: "10px" }}
                              >
                                {LBLCANCEL}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            {this.state.showTab === "training" ? (
              <>
                <div
                  style={{
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    marginTop: "10px",
                  }}
                >
                  <TrainingAssignment
                    initialValues={initialValues}
                    resultData={this.props.productListData}
                    labels={this.props.labels}
                    changesIn={this.changesIn}
                  ></TrainingAssignment>
                </div>
              </>
            ) : (
              <>
                {this.state.showTab === "channels" ? (
                  <>
                    <div style={{ marginTop: "10px" }}>
                      <ChannelAssignment
                        initialValues={initialValues}
                        resultData={this.props.productListData}
                        labels={this.props.labels}
                        changesIn={this.changesIn}
                      ></ChannelAssignment>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        marginTop: "10px",
                      }}
                    >
                      <AccessList
                        initialValues={initialValues}
                        resultData={this.props.productListData}
                        labels={this.props.labels}
                        changesIn={this.changesIn}
                        closeModal={this.props.closeModal}
                      ></AccessList>
                    </div>
                  </>
                )}
              </>
            )}
          </>

        )}
        
        <Modal
          show={this.state.showUserExesAlert}
          onHide={this.closeModalCount1}
        >
          <Modal.Body>
            <div className="alert-modal-body general-text">{this.props.labels.labels.LBLMAXNUMBERREACHED}</div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: "none", justifyContent: "center" }}>
            <button
              type="button"
              className="button-style primary-btn"
              onClick={this.closeModalCount1}
            >
              {this.props.labels.labels.LBLOK}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );

  }

}

const renderCheckField = ({
  input,
  label,
  title,
  name,
  _value,
  checked_bool,
  type,
  init_value,
  id,
  className,
  disable_field,
  meta: { touched, error, warning },
  defaultChecked
}) => {
  if (init_value === "") {
    // console.log(checked_bool,id,"checked_bool")
    return (
      <div className={styles.roleCheckBox}>
        <input
          {...input}
          type={type}
          name={name}
          id={id}
          checked={checked_bool}
          disabled={disable_field}
          defaultChecked={defaultChecked}
        />

        <label className="label" for={id} title={title}>
          {label}
        </label>
      </div>
    );
  } else {
    //console.log(checked_bool,id,"checked_bool")
    return (
      <div className={styles.roleCheckBox}>
        <input
          {...input}
          type={type}
          name={name}
          id={id}
          checked={checked_bool}
          value={_value}

        />
        <label className="label" for={id} data-title={title}>
          {label}
        </label>
      </div>
    );
  }
};

UserManagementForm = reduxForm({
  form: "usermanagementform", // a unique name for this form
  enableReinitialize: true
})(UserManagementForm);

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
};

export default connect(null, mapDispatchToProps)(UserManagementForm);

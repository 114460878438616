import React, { Component } from "react";
import "./products.scss";
import { connect } from "react-redux";
import Coursepopup from "../coursepopup/Coursepopup";
import CCCertificate from "../../../common_components/certificate/certificate";
import products_services from "../../../services/products_services";
import Skeleton from "react-loading-skeleton";
import Activities from "../activities/Activities";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import emptyImage from "../../../assets/images/Courses_Emptyst.png";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { TextField } from "@material-ui/core";
import {Autocomplete} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Box } from "@material-ui/core/";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import CatalogPopper from "../../components/popper/CatalogPopper";
import SurveyToastNav from "../../../survey/surveyToastNav";
import userInfo from "../../../redux/action";
import survey_service from "../../../survey/survey_services"
import CourseCardComponent from "./CourseCardComponent";
import ProductList from "./ProductList";

const moment = require("moment");
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const cstLabels = {};
class Products extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      resultData: [],
      searchData: [],
      showPopup: false,
      showCert: false,
      userData: null,
      productData: null,
      loading: true,
      showLoader: false,
      showModalDetails: false,
      selectedRowID: null,
      activityDetailsData: [],
      courseSearchValue: "",
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      sortCatalog: 0,
      statusFilter: 0,
      cardViewType: "thumbnail",
      filterParam: [0],
      catalogList: [],
      selectedCatageryName: ["All"],
      allCatagoryIds: [],
      width: 0,
      height: 0,
      selectedCatageryIndex: [-1],
      surveyAvailable:false,
      prodSearchLength: false, // used to find searched product length.
    };
    // this.showCourseDetails = this.showCourseDetails.bind(this);
    this.loadActivities = this.loadActivities.bind(this);
    this.searchKeyTimeout = null;
  }

  componentDidMount() {
    console.log("products page did mount called");
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.loadProdctList();
    this.checksurveyStatus()
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    this.setState( {
      resultData: [],
      searchData: [],
      showPopup: false,
      showCert: false,
      userData: null,
      productData: null,
      loading: true,
      showLoader: false,
      showModalDetails: false,
      selectedRowID: null,
      activityDetailsData: [],
      courseSearchValue: "",
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      sortCatalog: 0,
      statusFilter: 0,
      cardViewType: "thumbnail",
      filterParam: [0],
      catalogList: [],
      selectedCatageryName: ["All"],
      allCatagoryIds: [],
      width: 0,
      height: 0,
      selectedCatageryIndex: [-1],
      surveyAvailable:false,
    });
    // this.showCourseDetails = null;
    this.loadActivities = null;
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  loadProdctList() {
    var vParams = {
      userid: this.props.userid,
      // type: process.env.REACT_APP_PRODUCTTYPE,
      languagecode: this.props.language_code,
    };

    products_services
      .getproducts(vParams)
      .then((res) => {
        return res;
      })
      .then((data) => {
        //data.data = unitsdetails;
        if (data.data.length) {
          this.getCatalogList(data.data);
          console.log('getproducts ',data.data)
          this.setState({
            resultData: data.data,
            searchData: data.data,
            showPopup: false,
            loading: false,
            prodSearchLength : data.data.length < 3 ? true : false
          });
        } else {
          this.setState({
            resultData: [],
            searchData: [],
            showPopup: false,
            loading: false,
          });
        }
      });
  }

  checksurveyStatus = () => {
      var vParams = {
        userid: this.props.userid, 
      };
      survey_service
        .getsurveyStatus(vParams)
        .then((res) => {
          return res;
        })
        .then((data) => {
          this.props.user_info_reducer({...this.props.userinfo,survey:data.data.survey_id});
        });
  };
  getImageSrc = (res) => {
    console.log(res.product_imagename,"res.product_imagename")
    let productAltImage = '';
    if(res.product_imagename === null || res.product_imagename === undefined || res.product_imagename === ""){
      return productAltImage = "/images/thumbnailImage.png";
    }else{
      return `${res.engineversion === "2"
        ? process.env.REACT_APP_CRSENGINE2_IMAGEURL
        : process.env.REACT_APP_RESOURCES_URL
      }${"image/"}${res.engineversion === "2"
        ? res.productid
        : res.courseid
      }${"/"}${res.is_bg_tumbnail === 1
        ? res?.product_imagename?.replace("jpg", "png")
        : res.product_imagename
      }`;
    }
  };

  GotoProductUnits = (e) => {
    console.log("goto product units",e.currentTarget);
    // if (e.currentTarget.id) {
    //   this.setState({
    //     sortCatalog: 0,
    //     statusFilter: 0,
    //     cardViewType: "thumbnail",
    //     filterParam: [0],
    //     selectedCatageryName: ["All"],
    //   });
    // }
    var vRowID = e;
    if (vRowID !== undefined) {
      var vProductID = this.state.searchData[vRowID].productid;
      var vProductName = this.state.searchData[vRowID].name;
      var vTotalunits = this.state.searchData[vRowID].totalunits;
      // var vIsBgThumbnail = this.state.searchData[vRowID].is_bg_tumbnail;
      var vActivityID = this.state.searchData[vRowID].activity_id;
      var vLaunchType = this.state.searchData[vRowID].unit_launch_type;
      var vProductImage = this.getImageSrc(this.state.searchData[vRowID]);
      var isModule = this.state.searchData[vRowID].childNode && this.state.searchData[vRowID].childNode === 'modules' ? true : false;

      var vParams = {
        userid: this.props.userid,
        productid: this.state.searchData[vRowID].productid,
      };

      products_services
        .updateproducttakentime(vParams)
        .then((res) => {
          return res;
        })

      console.log("goto product units", vTotalunits);

      if (
        vTotalunits > 0 ||
        (vActivityID !== null &&
          vActivityID !== undefined &&
          vActivityID !== "")
      ) {
        if (this.props.location.pathname === "/schome/products") {
          this.props.history.push({
            pathname: "/schome/product-lists",
            state: {
              productid: vProductID,
              productname: vProductName,
              productImage: vProductImage,
              selectedTab: 1,
              isModule: isModule
              // bgThumbnail: vIsBgThumbnail,
            },
          });
        } 
        else {
          this.props.history.push({
            pathname: "/home/product-lists",
            state: {
              productid: vProductID,
              productname: vProductName,
              productImage: vProductImage,
              selectedTab: 1,
              isModule: isModule
              // bgThumbnail: vIsBgThumbnail,
            },
          });
        }
        // this.setState({
        //   showPopup: !this.state.showPopup,
        //   courseSearchValue: "",
        // });
      } 
      // else if (vTotalunits === 1 && vLaunchType !== "classroom") {
      //   this.showCourseDetails(vRowID, "Course");
      // }
    } 
    else {
      this.loadProdctList();
      // this.setState({
      //   showPopup: !this.state.showPopup,
      //   courseSearchValue: "",
      // });
    }
  };

  // showCourseDetails(vRowID, vEngineType, activityDetails) {
  //   var vUnitsInfoID = this.state.searchData[vRowID].unitsinfoid;
  //   var vProductUnitID = this.state.searchData[vRowID].unitid;
  //   var vProductID = this.state.searchData[vRowID].productid;
  //   var vCourseCompletionStatus =
  //     this.state.searchData[vRowID].CourseCompletionStatus;
  //   var vUnitname = this.state.searchData[vRowID].units;
  //   var vUnitDesc = this.state.searchData[vRowID].unitdescription;
  //   var vLanguageCode = this.state.searchData[vRowID].languagecode;
  //   var vEngineVersion = this.state.searchData[vRowID].engineversion;

  //   var vCourseVersion =
  //     vEngineType === "Course"
  //       ? this.state.searchData[vRowID].course_version
  //       : vEngineType === "Capsule"
  //         ? "1.1"
  //         : "";
  //   // var vCourseID = vEngineType === 'Course' ? this.state.searchData[vRowID].courseid : vEngineType === 'Capsule' ? vCapsuleID : "";

  //   var vCourseID, vActivityID, vCapsuleID;
  //   if (vEngineType === "Capsule") {
  //     vActivityID = activityDetails.activity_id;
  //     vCapsuleID = activityDetails.capsule_id;
  //     vCourseID = activityDetails.capsule_content_id;
  //   } else {
  //     vCourseID = this.state.searchData[vRowID].courseid;
  //   }

  //   var vCourseDet = {
  //     courseid: vCourseID,
  //     unitcompletionstatus: vCourseCompletionStatus,
  //     unitname: vUnitname,
  //     unitdesc: vUnitDesc,
  //     unitsinfoid: vUnitsInfoID,
  //     courseversion: vCourseVersion,
  //     languagecode: vLanguageCode,
  //     totalunitscount: this.state.TotalUnitsCount,
  //     engineType: vEngineType,
  //     activityid: vActivityID,
  //     capsuleid: vCapsuleID,
  //     trainingType: "Courses",
  //     engineVersion: vEngineVersion,
  //   };
  //   this.setState({
  //     showPopup: !this.state.showPopup,
  //     productid: vProductID,
  //     unitid: vProductUnitID,
  //     unitedetails: vCourseDet,
  //     showModalDetails: false,
  //     courseSearchValue: "",
  //   });
  // }

  searchCourse = (e) => {
    console.log("search course", e.target.value);
    const str = e.target.value;    
    this.setState({ courseSearchValue: str });
    if(this.searchKeyTimeout) clearTimeout(this.searchKeyTimeout);
    this.searchKeyTimeout = setTimeout(() => {      
      this.search();      
    }, 500);
  };

  showCertificate = (evt, productData) => {
    evt.stopPropagation();
    console.log("Welcome", this.props);
    let userData = {
      userid: this.props.userid,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      dateformat: this.props.dateformat,
    };
    this.setState({ userData, productData, showCert: true });
    document.getElementById("root").className = "no-scroll";
  };
  handleClose = () => {
    this.setState({ showCert: false });
    document.getElementById("root").className = "";
  };

  loadActivities = (evt, vRowID) => {
    evt.stopPropagation();

    this.setState({ showLoader: !this.state.showLoader });

    var vParams = {
      userid: this.props.userid,
      productid: this.state.resultData[vRowID].productid,
      activityid: this.state.resultData[vRowID].activity_id,
    };
    products_services
      .getactivitieslist(vParams)
      .then((result) => {
        if (result.data.length > 0)
          this.setState({
            showLoader: !this.state.showLoader,
            showModalDetails: !this.state.showModalDetails,
            selectedRowID: vRowID,
            activityDetailsData: result.data,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield];
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getObjDate = (xstrday) => {
    let arrdt = xstrday.split("T")[0].split("-");
    let arrdt2 = xstrday.split("T")[1].split(":");
    return new Date(
      arrdt[0],
      parseInt(arrdt[1]) - 1,
      arrdt[2],
      arrdt2[0],
      arrdt2[1],
      0,
      0
    );
  };
  showalert = (e) => {
    let rowKey = e;
    let xobj = { label: "LBLDATEUNLOCKFROMUNIT" };
    xobj.val = moment(this.state.searchData[rowKey].assigned_date)
      .add(this.state.searchData[rowKey].unit_drip_days, "days")
      .format(this.props.dateformat);
    if (rowKey !== undefined) {
      this.setState({
        showToast: {
          show: true,
          alertClass: "success",
          status: "Success",
          message: this.getLabel(xobj),
          changeInToast: new Date(),
        },
      });
      setTimeout(() => {
        this.setState({
          showToast: {
            show: false,
            alertClass: "",
            status: "",
            message: "",
            changeInToast: new Date(),
          },
        });
      }, 5000);
    }
  };
  getCatalogList = (data) => {
    let uniqueList = null;
    let selectedIds = [0];
    if (data && this.props.showcategory_filter) {
      var list = data.map(function (val) {
        return {
          id: val.productCategoryId,
          name: val.productCategoryName,
        };
      });
      uniqueList = list.reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id && obj.name === o.name)) {
          if (o.id) unique.push(o);
        }
        return unique;
      }, []);
      selectedIds = uniqueList.map((item) => {
        return item.id;
      })
    }
    console.log("unique list", uniqueList, selectedIds);
    this.setState({ catalogList: uniqueList, filterParam: [0], allCatagoryIds: selectedIds });
  };

  selectedCatagery = (value, index) => {
    console.log("selected catagory", index);
    this.setState(
      {
        filterParam: [value.id],
        selectedCatageryName: ["All"],
        selectedCatageryIndex: [-1],
      },
      () => this.search()
    );
  };
  selectedMultipleCategories = (values) => {
    console.log("selected multiple categories", values);
    let ids = [];
    let categoryNames = [];
    let categoryindexes = [];
    values.map((item) => {
      ids.push(item.id);
      categoryNames.push(item.name);
      categoryindexes.push(item.indexValue);
    });
    console.log("ids", ids, categoryNames, categoryindexes);
    this.setState(
      {
        filterParam: ids,
        selectedCatageryName: categoryNames,
        selectedCatageryIndex: categoryindexes,
      },
      () => this.search()
    );
  };
  clearCatagory = (index) => {
    console.log("clear catagory", index, this.state.selectedCatageryIndex);
    if (this.state.selectedCatageryIndex.length === 1) {
      this.setState({
          selectedCatageryIndex:[-1],
          filterParam: [0],
          selectedCatageryName: ["All"],
        })
    } else {

      this.state.selectedCatageryIndex.splice(index, 1);
      this.state.filterParam.splice(index, 1);
      this.state.selectedCatageryName.splice(index, 1);
    }
    this.setState(
      {
        selectedCatageryIndex: this.state.selectedCatageryIndex,
        filterParam: this.state.filterParam,
        selectedCatageryName: this.state.selectedCatageryName,
      },
      () => this.search()
    );
  };

  clearAllCatagory = () => {

    this.setState(
      {
        selectedCatageryIndex:[-1],
        filterParam: [0],
        selectedCatageryName: ["All"],
      },
      () => this.search()
    );
  }

  handleSortCatalog = () => {
    this.setState({ sortCatalog: this.state.sortCatalog ? 0 : 1 }, () => this.search());
  };
  handlefilterStaus = (e, i) => {
    console.log("chckkk", this.state.statusFilter)
    this.setState({
      statusFilter: i.value
    },
      () => this.search());
  };
  search = () => {
    console.log("search", this.state.filterParam, this.state.allCatagoryIds);
    const filteredData = this.state.resultData.filter((item) => {
      if (
        this.state.filterParam[0] === 0 &&
        item.name &&
        item.name.toLowerCase().includes(this.state.courseSearchValue?.toLowerCase()) &&
        ((this.state.statusFilter === 1 &&
          item.CourseCompletionStatus === "Not Started") ||
          (this.state.statusFilter === 2 &&
            item.CourseCompletionStatus === "Inprogress") ||
          (this.state.statusFilter === 3 &&
            item.CourseCompletionStatus === "Completed") ||
          this.state.statusFilter === 0)
      ) {
        return item;
      } else if (
        this.state.filterParam.indexOf(item.productCategoryId) > -1 &&
        item.name &&
        item.name.toLowerCase().includes(this.state.courseSearchValue) &&
        ((this.state.statusFilter === 1 &&
          item.CourseCompletionStatus === "Not Started") ||
          (this.state.statusFilter === 2 &&
            item.CourseCompletionStatus === "Inprogress") ||
          (this.state.statusFilter === 3 &&
            item.CourseCompletionStatus === "Completed") ||
          this.state.statusFilter === 0)
      ) {
        return item;
      }
      return null;
    });
    const sortedData = filteredData.sort((a, b) => {
      const aName = a.description.toLowerCase(),
        bName = b.description.toLowerCase();
      const sorted =
        this.state.sortCatalog === 0
          ? aName > bName
            ? 1
            : bName > aName
              ? -1
              : 0
          : aName < bName
            ? 1
            : bName < aName
              ? -1
              : 0;
      return sorted;
    });
    this.setState({ searchData: sortedData });
    if(sortedData.length < 3) {
      this.setState({prodSearchLength: true})
    } else {
      this.setState({prodSearchLength: false})
    }
  }

  handleSurvey = () => {
    this.props.user_info_reducer({...this.props.userinfo,showsurvey:true});
   }
  
  
  handleProductClick = (pres, pindex) => {
    console.log(this.props.survey,"this.props.survey")
        if (pres.DRIPBLOCKED === true) {
            this.showalert(pindex);
        } else if (pres.product_locked_status === 0) {
            this.GotoProductUnits(pindex);
        } else {
            return null; 
        }
}
  
  render() {
    console.log(this.props.survey,"surveyAvailable")
    const { labels } = this.props.labels;
    const statusupdate = [
      { value: 0, label: labels.LBLALLSTATUS },
      { value: 1, label: labels.LBLNOTSTARTED },
      { value: 2, label: labels.LBLINPROGRESS },
      { value: 3, label: labels.LBLCOMPLETED }
    ]
    //console.log("labels.LBLCOMPLETED", statusupdate.label)

    return (
      <div className="productCtn">
        <div className="row">
          <ToastMessage
            show={this.state.showToast["show"]}
            helpers={this.state.showToast}
          ></ToastMessage>
          {this.state.showPopup}
          {this.state.showPopup ? (
            <Coursepopup
              text='Click "Close Button" to hide popup'
              closePopup={this.GotoProductUnits.bind(this)}
              showPopup={this.state.showPopup}
              Params1={this.state.productid}
              Params2={this.state.unitid}
              unitedetails={this.state?.unitedetails}
            ></Coursepopup>
          ) : null}
          {this.state.showModalDetails &&
            this.state.activityDetailsData.length > 0 ? (
            <Activities
              selectedRowID={this.state.selectedRowID}
              showActivities={this.state.showModalDetails}
              hideActivities={(vParams) =>
                this.setState({ showModalDetails: vParams })
              }
              activityHeader={
                this.state.searchData[this.state.selectedRowID].name
              }
              activityData={this.state.activityDetailsData}
              // showCourseDetailsfn={this.showCourseDetails}
              {...this.props.labels}
            ></Activities>
          ) : (
            ""
          )}
          <div className="col-xl-12">
            <div className="mob-pad">
              <div className="page-header-field">
                <h3 className="page-title" style={{marginBottom: "0px"}}>{this.props.labels.labels.LBLCOURSES}</h3>
                <input
                  className="input-field"
                  style={{
                    width: "auto",
                    margin: "0px",
                    float: "right",
                    marginBottom: "5px",
                  }}
                  placeholder={this.props.labels.labels.LBLSEARCH}
                  value={this.state.courseSearchValue}
                  onChange={this.searchCourse}
                  onKeyDown={this.searchCourse}
                  ref="myInput"
                />
              </div>
              <hr className="head-line" />
              {this.props.skipnow && !this.state.loading ?
              <SurveyToastNav handleSurvey={this.handleSurvey} labels = {this.props.labels.labels}/> 
              :
              null
              }
              <div className="row grid-container">
                {this.state.loading ? (
                  <>
                    {skel.map((i) => {
                      return (
                        <div
                          // className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12 img-card-box"
                          style={{ marginBottom: "15px" }}
                          id={"row-" + i}
                        >
                          <div
                            className="card img-cnt-card imgCtnLoading"
                          >
                            <div className="imgHold">
                              <Skeleton height={190} />
                            </div>
                            <div
                              className="card-body imgCtnLoading"
                            >
                              <h5>
                                <Skeleton height={15} />
                              </h5>
                              <div>
                                <Skeleton height={3} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : this.state.searchData.data !== undefined ? (
                  <div className="col-md-12 text-center text-danger">
                    {this.state.searchData.data === "No records" ? (
                      <div style={{ marginTop: "5%" }}>
                        <EmptyState
                          image={emptyImage}
                          text2={this.props.labels.labels.LBLCOURSESEMPTYTXT}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : this.state.searchData.length >= 0 ? (
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "inherit",
                    }}
                  >
                    <Box container className="boxContainerProduct" >
                      <Box style={{ display: "flex" }}>
                        {console.log("CatalogPopper", this.state.catalogList)}
                        {this.props.showcategory_filter ? (
                          <CatalogPopper
                            categoriesJSON={this.state.catalogList}
                            selectedCatagery={(value, index) =>
                              this.selectedCatagery(value, index)
                            }
                            selectedCatageryIndex={
                              this.state.selectedCatageryIndex
                            }
                            selectedCatageryIds={this.state.filterParam}
                            allCatagoryIds={this.state.allCatagoryIds}
                            selectedMultipleCategories={
                              this.selectedMultipleCategories
                            }
                            labels={this.props.labels}
                          />
                        ) : null}

                        <Box style={{ minWidth: 120, display: "flex", marginRight: 5, paddingTop: "5px" }}>
                          {/* {console.log("this.state.statusFilter ? statusupdate.find((option)", this.state.statusFilter ? statusupdate.find((option) => { return this.state.statusFilter === option.value }) ?? null : null)}
                          {console.log("statusupdate", statusupdate.label)}
                          {console.log("pppp", this.state.statusFilter ? statusupdate.find((option) => { return this.state.statusFilter === option.value }) ?? null : null)} */}
                          <Autocomplete
                            className="filter-drpdwn"
                            aria-required="true"
                            noOptionsText={this.props.labels.labels.LBLNOOPTIONSDROPDOWN}
                            required
                            disableClearable
                            options={statusupdate}
                            style={{
                              width: 160
                            }}
                            sx={{
                              "& legend": { display: "none" },
                              "& fieldset": { top: 0 },
                            }}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, values) =>
                              option.value === values.value
                            }
                            value={this.state.statusFilter>-1 ? statusupdate.find((option) => { return this.state.statusFilter === option.value }) ?? null : null}
                            onChange={(e, i) => {
                              console.log("qqqqqq", e, i)
                            
                              this.handlefilterStaus(e, i)}}
                            renderOption={(props,option) => (
                              <div {...props} style={{fontSize:"14px"}}>{option.label}</div>
                            )}

                            renderInput={(params) => (
                              <div>
                                <TextField
                                  {...params}
                                />
                              </div>
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {this.state.resultData.length === 0 ? null :
                      this.state.allCatagoryIds ? this.state.filterParam[0] !== 0 && (
                        <Box component={"div"} style={{ margin: "10px 0px" }}>
                          <Typography
                            className="filteredBy"
                            variant="body1"
                            component="span"
                          >
                            {"Filtered by:"}
                          </Typography>
                          {this.state.selectedCatageryName.map((item, index) => {
                            return (
                              <Typography
                                boxShadow={2}
                                className="categoryNameStyle"
                                variant="body1"
                                component="span"
                              >
                                {item}
                                <Typography
                                  className="categoryNameClosebtnStyle"
                                  variant="body1"
                                  component="button"
                                  onClick={() => this.clearCatagory(index)}
                                >
                                  {"x"}
                                </Typography>
                              </Typography>
                            );
                          })}
                          {this.state.selectedCatageryName.length && this.state.selectedCatageryName[0] !== 'All' &&
                            <Typography
                              style={{
                                fontWeight: "500 !important",
                                fontSize: "13px",
                                fontFamily: "Roboto !important",
                                padding: "5px 0px 5px 15px",
                                color: "black",
                                marginRight: 5,
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                              variant="body1"
                              component="span"
                              onClick={() => this.clearAllCatagory()}
                            >
                              {'Clear All'}
                            </Typography>
                          }
                        </Box>
                      ) : null
                    }
                    {this.state.searchData.length === 0 ? (
                      <div className="col-md-12 text-center text-danger">
                        <EmptyState
                          image={emptyImage}
                          text2={this.props.labels.labels.LBLNOCOURSEFOUND}
                        />
                      </div>
                    ) : (
                      <div className={this.state.prodSearchLength ?  "grid-fixed-container row " : "grid-container row"} style={{opacity:this.props.survey !== "" && this.props.survey !== undefined ? 0.5 : 1,pointerEvents:this.props.survey !== "" && this.props.survey !== undefined && "none" }}>
                        {this.state.searchData.map((res, i) => {
                          if (res !== undefined) {
                            return (
                            <ProductList 
                                unitTitle={res.name}
                                unitdesc={res.description}
                                currentPage="products"
                                CourseCompletionStatus={res.CourseCompletionStatus}
                                CourseCompletionPercentage={res.CourseCompletionPercentage}
                                productImage={this.getImageSrc(res)}
                                courseEstimationTime={(res.productEstimationTime !== null || res.productEstimationTime !== undefined) ? res.productEstimationTime : 0}
                                handleProductClick = {() => this.handleProductClick(res,i)}
                                survey = {this.props.survey}
                                courseid = {res.courseid}
                                capsuleListLength={0}
                                data = {res}
                                labels = {this.props.labels.labels}
                                totalunits = {res.totalunits}
                                courseFeedback = {res.courseFeedback}
                                prodSearchLength = {this.state.prodSearchLength}
                                isModule= {res.childNode && res.childNode === 'modules' ? true : false}
                            />
                            )
                          }
                        })}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userid: state.user_info_reducer.user_info.user_id,
    companyid: state.user_info_reducer.user_info.company_id,
    language_code: state.user_info_reducer.user_info.language_code,
    first_name: state.user_info_reducer.user_info.first_name,
    last_name: state.user_info_reducer.user_info.last_name,
    company_logo: state.user_info_reducer.user_info.company_logo,
    showcategory_filter: state.user_info_reducer.user_info.showcategory_filter,
    skipnow: state.user_info_reducer.user_info.skipnow,
    userinfo: state.user_info_reducer.user_info,
    survey: state.user_info_reducer.user_info.survey
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    user_info_reducer: (userReducerData) => dispatch(userInfo(userReducerData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);

import { HttpInterceptor } from '../../../auth/HttpInterceptor';

class GroupServices {

    async getGroupTypeLabels(language) {
        let lang = '';
        if (language) {
            lang = language
        } else {
            lang = 'en-US';
        }
        let group_types = await require('../../../json_files/group_types.json');
        const response = await HttpInterceptor.get('grouptypes');
        let language_group = response.data.filter(item => (item.language_code === lang));
        let labels = JSON.parse(language_group[0].labels).group_labels;

        if (labels) {
            let i = 0;
            var grouptypeArray=[]
            for (const grouptype of group_types) {
                i++;
                if (labels[grouptype.group_type_id] !=undefined) {
                    console.log(grouptype)
                    grouptype["lbl_" + grouptype.group_type_id] = labels[grouptype.group_type_id]["lbl_" + grouptype.group_type_id]
                     grouptypeArray.push(grouptype)
                }
                if (i === group_types.length) {
                    return grouptypeArray;
                }
            }
        } else {
            return group_types;
        }
    }
    async getGroups(group_type_id, active) {
        let url = '';
        if (active) {
            url = 'getgroups?active=1'
        } else {
            url = 'getgroups'
        }
        let data = {
            group_type_id: group_type_id
        }
        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    async addGroup(data) {
        const response = await HttpInterceptor.post('groups', data);
        return response.data;
    }
    async getUsers(data) {
        const response = await HttpInterceptor.post('groups/users', data);
        return response.data;
    }
    async updateGroup(data) {
        const response = await HttpInterceptor.put('groups', data);
        return response.data;
    }

    async editGroup(data) {
        const response = await HttpInterceptor.put('groups', data);
        return response.data;
    }

}

export default new GroupServices();


import React,{useState,useEffect} from "react";
import "./ProdList.scss";
import Modalpopup from "../../../common_components/modalanddatatable/Modalpopup";
import Loader from "../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import grouplist_emptyst from '../../../assets/images/grouplist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import TrainingManagementHeader from "../../../common_components/datatable/TrainingManagementHeader"
import ConfrimPopup from "./ConfirmPopup";
import { DataGrid} from '@mui/x-data-grid';
import assignedCheckbox from "../../../assets/images/assignedCheckboxImg.png";
import newlyAssignmentCheckbox from "../../../assets/images/newlyAssignedCheckboxImg.png";
import assignedaccucheckbox from "../../../assets/images/assignedaccucheckbox.png";
import styles from "./ProdList.module.scss";
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const EnrollUser = (props) => {
    // Sitetype:localStorage.getItem('site_type');
    const [selectedUser,setSelectedUser] = useState([]);
    const [slectedUserCount,setSlectedUserCount] = useState(0);
    const [showConfrimPopup,setShowConfrimPopup] = useState(false);
    const [visibleRowsSelected,setVisibleRowsSelected] = useState(false);
    const [pageState, setPageState] = useState({ // Loading data in the datagrid component
        isLoading: false,
        data: [], // rows
        total: 0 // total count of data grid we need to show
      })  
    const [paginationModel, setPaginationModel] = useState({ // pagination variables
        page: 0,
        pageSize: 10,
      });
    const [rowSelectionModel, setRowSelectionModel] = useState([]); 
    const [userlist, setUserList] = useState([]); // total list 
    const [filteredUser,setFilteredUser]  = useState([]);
    const [resultData,setResultData] = useState([]);
    const [sortModel,setSortModel] = useState([]);
    const [searchUser,setSearchUser] = useState("");
    const [copyFilteredUser,setCopyFilteredUser] = useState([]);
    const {
        LBLNAME,
        LBLEMAILID,
        LBLSTATUS,
        LBLACTIVE,
        LBLINACTIVE,
        LBLGROUPSENROLLED,
        LBLGROUPNAME,
        LBLGROUPTYPES,
        LBLCANCEL,
        LBLCONFIRM,
        LBLUSERSEMPTYTXT,
        LBLGROUPSEXPTYTXT1,
        LBLASSIGNUSER,
        LBLUNASSIGNUSER,
        LBLSEARCHBYNAMEEMAIL,
        LBLCOURSESASSIGNEDTOELIGIBLEUSERS,
        LBLCOURSEASSIGNMENT
    } = props.labels

    const fetchData = () => {
        setUserList(props.productUsers);
        setResultData(props.productUsers);
        setFilteredUser(props.productUsers)
        setPageState( ({ isLoading: false, data: filteredUser.slice((paginationModel.page * paginationModel.pageSize), ((paginationModel.page + 1) * paginationModel.pageSize)), total: filteredUser.length }))
      }
    
      useEffect(()=> {
        fetchData();  
      }, [])
      // When page change/ rows per page selection change / total list changes (add / delete)
    useEffect(() => {
        const fetchData = () => {
        setPageState( ({ isLoading: false, data: filteredUser.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page+1) * paginationModel.pageSize)), total: filteredUser.length }))
        }
        fetchData();
  }, [paginationModel.page, paginationModel.pageSize, filteredUser]);
    useEffect(() => {
      let totalavailableRowsPerpage = 0;
      let totalavailableRows = 0;
      if(pageState.data.length > 0){
          pageState.data.map((item) => {
            if(item.isAssigned !== 1  && props.statusFilter === 1) {
                totalavailableRowsPerpage ++
            }
            return null;
          });
          if(userlist.length > 0){
            userlist.map((item) => {
              if(item.isAssigned === 0 ) {
                totalavailableRows++
              }
              return null;
            });
          }
          let selctedRowCount = 0 ;
          (pageState.data.length  && pageState.data.map((val, ind) => {
            if(rowSelectionModel.includes(val.id)){
                selctedRowCount ++;
            }
          let totalSelectedRows = selctedRowCount ;
          if(totalavailableRowsPerpage === 0) {
            setVisibleRowsSelected(false)
          } else if (totalavailableRowsPerpage === totalSelectedRows && totalavailableRows !== slectedUserCount) {
            setVisibleRowsSelected(true)
          } else {
            setVisibleRowsSelected(false)
          }
          return null;
        }))
      }
    },[pageState.data,rowSelectionModel,props.statusFilter,slectedUserCount,userlist])
    
  const handlepaginationModalChange = (paginationVal) => {
    console.log(paginationVal,"paginationVal");
    setPaginationModel(paginationVal);
  }
  const handleSelectionModelChange = (newRowSelectionModel) => {
    const filteredArray = newRowSelectionModel.filter(item => item !== false);
    setRowSelectionModel(newRowSelectionModel);
    setSlectedUserCount(filteredArray.length)
    const selectedRows = newRowSelectionModel.map((id) =>
    userlist.find((row) => row.id === id)
    );
    setSelectedUser(selectedRows)
  };
    const handleSelectAll = () => {  
        const selecteRowsData = filteredUser
        setSelectedUser(selecteRowsData)
        if(props.statusFilter === 1) {
            console.log("aaaaaaaaaaaaaaaabcccc")
            const userIds = filteredUser.map((item) => item.isAssigned !==1 && item.id);
            setRowSelectionModel(userIds);
            setSlectedUserCount( filteredUser.filter((item) => item.isAssigned === 0).length)
        } else {
            const userIds =  filteredUser.map((item) =>  item.id);
            setRowSelectionModel(userIds);
            setSlectedUserCount( filteredUser.length)
        }
    }

    const handleSortModelChange = (sortModel) => {
        console.log("sortModelstart",sortModel)
          setSortModel(sortModel);
          const field = sortModel[0]?.field ;
          let direction = sortModel[0]?.sort ;
          let sortedData = [...filteredUser];
          if(direction === undefined) {
            sortedData = searchUser !== "" ? copyFilteredUser : [...resultData];
           }
           sortedData.sort((a, b) => {
            const valueA = a[field] === null ? "" : a[field]; // Convert null to empty string
            const valueB = b[field] === null ? "" : b[field]; // Convert null to empty string
            if (direction === 'asc') {
                if (typeof valueA === 'string') {
                    return valueA.localeCompare(valueB);
                } else {
                    return valueA - valueB;
                }
            } else {
                if (typeof valueB === 'string') {
                    return valueB.localeCompare(valueA);
                } else {
                    return valueB - valueA;
                }
            }
        });
        
        console.log("sortedData", sortedData.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page+1) * paginationModel.pageSize)));
        setPageState( ({ isLoading: false, data: sortedData.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page+1) * paginationModel.pageSize)), total: sortedData.length }))
        setFilteredUser(sortedData);
      };

    const filterUser = (e) => {
        setPaginationModel({
            page:0,
            pageSize:10
         })
      let userSearchedVal = e.target.value;
      setSearchUser(userSearchedVal);
      const filteredVal =  resultData.filter((item) => {
            return(
                (item.first_name + " " + item.last_name &&
                ((item.first_name + " " + item.last_name).includes(
                    userSearchedVal
                ) ||
                    (item.first_name + " " + item.last_name)
                        .toLowerCase()
                        .includes(userSearchedVal.toLowerCase()))) ||
            (item.email &&
                (item.email.includes(userSearchedVal) ||
                    item.email
                        .toLowerCase()
                        .includes(userSearchedVal.toLowerCase())))
            )     
    });
    console.log(filteredVal,"filteredValfilteredValfilteredVal")
    setFilteredUser(filteredVal);
    setCopyFilteredUser(filteredVal);
    setPageState({ isLoading: false, data: filteredVal, total: filteredVal.length })
    return filteredVal;
    }
    

    let columns = [
        {
            headerName: LBLNAME,
            field: "first_name",
            sortable: true,
            width: "200",
            renderCell: (row) => (
                <div className='hde-user-icn'>
                    {row.row.first_name +
                        " " +
                        ("" + row.row.last_name === "null" ? "" : row.row.last_name)}
                </div>
            ),
        },
        { 
            headerName: LBLEMAILID,
            field: "email",
            width: "400",
        },
        {
            headerName: LBLSTATUS,
            field: "active",
            width: "200",
            renderCell: (row) => {
                return(
                    <div className={row.row.active === 1 ? styles.LBLACTIVE  : styles.LBLINACTIVE}>
                        {row.row.active === 1 ? LBLACTIVE : LBLINACTIVE}
                    </div>
                );
            }
        },
        {
            headerName: LBLGROUPSENROLLED,
            field: "usersgroupcount",
            width: "150",
            sortable:true,
            renderCell: (row) => {
                const rowId = row.row.id;
            return(
                <div>
                    <div
                        className={styles.EnrolledCount}
                        data-toggle="modal"
                        onClick={row.row.usersgroupcount > 0 ? () => {props.ShowEnrolledDetails(rowId)} : undefined  
                        }
                    >
                        <span>
                            {row.row.usersgroupcount > 0 ? row.row.usersgroupcount : "-"}
                        </span>
                    </div>
                </div>
            )
        },
        },
    ];
    const rowDisabledCriteria = (row) => {
        if(props.statusFilter !== 2){
            if(row.row.isAssigned === 0) {
                return true;
            } 
        } else {
            return true;
        } 
    }
    /**
     * Function used to filter groups enrolled modal data.
     */
    const UserGroupfilter =
        props.enrollredDetails !== undefined
            ? props.enrollredDetails.filter((item) => {
                if (
                    (item.group_name &&
                        (item.group_name.includes(props.groupSearchValueModal) ||
                            item.group_name
                                .toLowerCase()
                                .includes(props.groupSearchValueModal))) ||
                    (item.group_type_id &&
                        (item.group_type_id.includes(props.groupSearchValueModal) ||
                            item.group_type_id
                                .toLowerCase()
                                .includes(props.groupSearchValueModal)))
                ) {
                    return item;
                }
                return null;
            })
            : undefined;


    /**
        * Variable used for display coloums inside groups enrolled Modal using datatable.
        */
    let usergroupColumns = [
        {
            name: LBLGROUPNAME,
            selector: "Group Name",
            sortable: true,
            width: "50%",
            compact: true,
            cell: (rows) => (
                <div className="w100">
                    <label className="show-sm">{LBLGROUPNAME}</label>
                    <div>{rows.group_name}</div>
                </div>
            ),
        },
        {
            name: LBLGROUPTYPES,
            selector: "Group Type",
            sortable: true,
            width: "50%",
            compact: true,
            cell: (rows) => (
                <div className="w100">
                    <label className="show-sm">{LBLGROUPTYPES}</label>
                    <div>{rows.grouptype}</div>
                </div>
            ),
        },
    ];

    let usergroupData = UserGroupfilter;

    const handleConfrimPopup = () => {
        console.log(props.statusFilter,"props.statusFilter")
        if(props.statusFilter === 1){
            setShowConfrimPopup(true);
        } else{
            props.updateTrainingAssignment(selectedUser);
        }
    }

    const handleCancelConfrimPopup = () => {
        setShowConfrimPopup(false);
    }
    const handleConfrim = () => {
        props.updateTrainingAssignment(selectedUser);
    }
    const handleClear = () => {
        setRowSelectionModel([]);
        setSlectedUserCount(0);
       }
      const dataGridTableHeader = () => {
        return (
          <>
            <div style={{display:"flex",justifyContent:"flex-end"}}>
                <input
                  type="text"
                  className="input-field"
                  placeholder={LBLSEARCHBYNAMEEMAIL}
                  value={props.value}
                  onChange={filterUser}
                  onKeyDown={filterUser}
                />
              </div>
            <div  className={styles.legendsAllCtn}>
                {props.statusFilter === 1 &&
                <div className={styles.legendCtn}>
                <img src= {assignedCheckbox} alt = ""/>
                {props.labels.LBLASSIGNED !== undefined ? props.labels.LBLASSIGNED : "Assigned"}
                </div>
                }
                <div className={styles.legendCtn}>
                <img src= {localStorage.getItem('site_type')==='SafeStart'? newlyAssignmentCheckbox:assignedaccucheckbox} alt = ""/>
                {props.labels.LBLSELECTED !== undefined ? props.labels.LBLSELECTED : "Selected"}
                </div>
            </div>
            <TrainingManagementHeader
              selectedRowsCount={props.selectedRowsCount}
              labels={props.labels}
              handleClear={handleClear}
              assignmentstate={props.assignmentstate}
              selectedRows={props.selectedRows}
              selectAll={props.labels.LBLSELECTALLUSERS?.replace("$$$","")}
              usersGroupsSelected={props.labels.LBLUSERSSELECTED !== undefined ? props.labels.LBLUSERSSELECTED : "Users selected"}
              usersGroupsSelectedCount= {slectedUserCount}
              dropObj={[]}
              courseAssignment = {true}
              handleRowChange = {handleSelectAll}
              visibleRowsSelected = {visibleRowsSelected}
              visiblerowsselectedLabel = {
                props.statusFilter === 1 ?
                props.labels.LBLELIGIBLEUSERSSELECTED !== undefined ? props.labels.LBLELIGIBLEUSERSSELECTED :`All eligible users on this page are selected.`
                :
                props.labels.LBLALLUSERSSELECTED !== undefined ? props.labels.LBLALLUSERSSELECTED :`All users on this page are selected.`
            }
            />
          </>
        );
      }
    console.log(filteredUser,"filteredUser")
    return (
        <div className="prodlist">
            <div>
                <h3 className="page-title">
                    {props.statusFilter === 1 ? LBLASSIGNUSER ?  LBLCOURSEASSIGNMENT + " - " + LBLASSIGNUSER  : "Assign users" : LBLUNASSIGNUSER ?  LBLCOURSEASSIGNMENT + " - " + LBLUNASSIGNUSER : "Unassign users"}
                </h3>
            </div>
            <hr className="head-line" />
            <div >
                {props.loading ? (
                    <div>
                        <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                            <header className="sc-fzqNJr kwRiGp rdt_TableHeader"></header>
                            <header className="sc-fzoXzr hlidmt">
                                <Skeleton height={34} />
                            </header>
                            <div className="sc-fznWqX gnahTY">
                                <div className="sc-AxjAm gIMaKV rdt_Table">
                                    <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                                        <div className="sc-AxiKw cTEQON rdt_TableHeadRow">
                                            <Skeleton height={56} />
                                        </div>
                                    </div>
                                </div>
                                <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                                    <Skeleton height={432} />
                                </div>
                            </div>
                            <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                                <Skeleton height={56} />
                            </nav>
                        </div>
                        <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                            <header className="sc-fzoXzr hlidmt">
                                <Skeleton height={34} />
                            </header>
                            <div className="sc-fznWqX gnahTY">
                                <div className="sc-AxjAm gIMaKV rdt_Table">
                                    <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                                        {skel.map((i) => {
                                            return (
                                                <div
                                                    id={"row-" + i}
                                                    className="sc-fzoLsD doBktq rdt_TableRow"
                                                >
                                                    <Skeleton height={181} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                                <div className="sc-fzpmMD ftIMtk">
                                    <Skeleton height={40} />
                                </div>
                            </nav>
                        </div>
                    </div>
                ) : (
                    <>
                      <div>{dataGridTableHeader()}</div>
                        {pageState.data.length > 0 ?
                            <DataGrid
                            initialState={{
                                sorting: {
                                  sortModel: [{ field: 'first_name', sort: 'asc' }],
                                },
                              }}
                            onSortModelChange={(sortVal) => handleSortModelChange(sortVal)}
                            // initialState={{
                            //   sorting: {
                            //     sortModel: [{ field: 'first_name', sort: 'asc' }],
                            //   },
                            // }}
                              style={{border: 'none'}}
                              className="cust-data-grid"
                              autoHeight
                              checkboxSelection
                              disableRowSelectionOnClick          
                              isRowSelectable={rowDisabledCriteria} // matched condition rows only enabled
                              getRowId={(row) => row.id} // key value of the row item
                              columns={columns}
                              rows={pageState.data}
                              rowCount={pageState.total}
                              loading={pageState.isLoading}
                              pageSizeOptions={[10, 15, 20,25,30]}
                              pagination
                              paginationMode="server"
                              paginationModel={paginationModel}
                              onPaginationModelChange={handlepaginationModalChange}
                              onRowSelectionModelChange={handleSelectionModelChange}
                              rowSelectionModel={rowSelectionModel}        
                              keepNonExistentRowsSelected
                              hideFooterSelectedRowCount
                              disableColumnMenu
                              slotProps={{
                                pagination: {
                                  labelRowsPerPage: props.labels.LBLROWSPERPAGE + ":"
                                }
                              }}
                              sx={{
                                '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                                  outline: 'none',
                                },
                      
                                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                                  outline: 'none',
                                },
                                '& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg path': {
                                    display: "none"
                                  },
                                '& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg': {
                                    backgroundColor:"#cfcbcb",
                                    width:"18px",
                                    height:"18px",
                                    borderRadius:"3px"
                                },
                             }}
                            />
                            :
                            <div style={{ marginTop: "5%" }}><EmptyState image={emptyImage} text2={LBLUSERSEMPTYTXT !== undefined ? LBLUSERSEMPTYTXT : "There aren't any users to display"} /> </div>
                        }

                    </>
                )}
            </div>
            <div className="row section-body">
                <div
                    id="selectproductError"
                    className="col-md-12 text-danger text-center d-none"
                >
                    {props.LBLSELANYUSER}
                </div>

                <div className="col-md-12 text-right">
                    <button
                        type="button"
                        class="btnspacin"
                        className="button-style secondary-btn"
                        onClick={props.handleCancel}
                        style={{marginRight:"10px"}}
                    >
                        {LBLCANCEL}
                    </button>
                    <button
                        type="button"
                        class="btnspacin"
                        className="button-style primary-btn"
                        onClick={handleConfrimPopup}
                        disabled = {slectedUserCount === 0}
                    >
                        {LBLCONFIRM}
                    </button>
                   </div>
                 <ConfrimPopup
                   show = {showConfrimPopup}
                   hide = {handleCancelConfrimPopup}
                   title = {LBLASSIGNUSER !== undefined ? LBLASSIGNUSER  : "Assign users"} 
                   selectedRowsCount = {props.selectedRowsCount}
                   usersGroupsSelectedCount = {slectedUserCount}
                   buttonName = {LBLCONFIRM !== undefined ? LBLCONFIRM : "Confirm"}
                   handleConfrim = {handleConfrim}
                   handleCancel = {handleCancelConfrimPopup}
                   description = {LBLCOURSESASSIGNEDTOELIGIBLEUSERS}
                   btnCancel = {LBLCANCEL}
                 />
            </div>
            {props.showModalDetails ? (
                props.enrollredDetails.length === 0 ? (
                    <div className="text-center">
                        <Loader></Loader>
                    </div>
                ) : (
                    <Modalpopup
                        Showmodal={props.showModalDetails}
                        closemodal={props.CloseEnrolledDetails}
                        ModalTiltle={props.enrollredDetails[0].name}
                        DataTableHeader={props.usergroupTableHeader}
                        Norecords={<EmptyState image={grouplist_emptyst} text2={LBLGROUPSEXPTYTXT1 !== undefined ? LBLGROUPSEXPTYTXT1 : "There aren't any groups to display"} />}
                        paginationComponentOptions={props.paginationComponentOptions}
                        DatatableColumns={usergroupColumns}
                        DatatableData={usergroupData}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    ></Modalpopup>
                )
            ) : undefined
            }
        </div>
    );
}

export default EnrollUser;
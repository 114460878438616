import React from 'react';
import './feed.css';
// import Feeds from './components/feeds/feeds';
import Feeds from './Pages/feeds/feeds';

class Feed extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showChannelMenu:1,
            labels: this.props.labels.labels
        }
        //console.log("feeds main compnent",this.props.labels)
    }
    componentDidMount(){        
    } 
    componentDidUpdate(prevProps) {
        if(this.props.labels.labels !== prevProps.labels.labels) {
            this.setState({
              labels: this.props.labels.labels
            })
          }
    }
    render(){
        return(
            <div className="ssi-feed-feedPageContainer">
                <div className="ssi-feed-feedPageWrapper">
                    <Feeds channelMenu={this.state.showChannelMenu} {...this.props} />
                </div>
            </div>
        )
    }
}

export default Feed

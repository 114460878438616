import React, { useState, useEffect } from 'react';

import { HttpInterceptor } from '../../../auth/HttpInterceptor';
import ProfileImageDisplay from "../../components/profileimage/profileimage";
import { formatDistanceToNow } from 'date-fns';
import EmptyState from "../../../common_components/empty_state/Empty_state";
import emptyImage from '../../../assets/images/smallimg.png'
import './NotificationComponent.css'; // Import the CSS file
import { el } from 'date-fns/locale';
export default function NotificationDisplayBar(props) {
    const readNotifications = async (notification) => {
        props.readNotifications(notification)

        props.notificationFilter(notification.category_id)
        // setIsOpen(isOpen);

        // await fetchNotifications();


    };


    return (
        <div >
            <div >
                {props.notificationInfo.length ? (
                    props.notificationInfo.map((notification, index) => {
                    let asseturl="";
                        if (notification?.category_assets?.length >0) {

                            if(notification.category_assets[0].asset_type==="image"){
                                let assetFolder = String(notification.category_assets[0].asset_name).split(".");
                                if (assetFolder.length > 1) {
                                    assetFolder.pop();
                                }
                                let postData = {
                                    filename: notification.category_assets[0].asset_name,
                                    assettype: "image",
                                    assetFoldet:assetFolder.join(),
                                };
                                HttpInterceptor.post(`${process.env.REACT_APP_FEEDAPI_URL}gets3signedassetsurl`, postData)
                                    .then(async result => {
                                        console.log("bibibn---image", result.data.data)
                                        notification.category_image = result.data.data
                                        asseturl=result.data.data
                                    })
                            }else{
                                // notification.category_image = 
                                console.log("notification.category_assets[0].asset_name -",notification.category_assets[0].asset_name)
                                let assetFolder = String(notification.category_assets[0].asset_name).split(".");
                                if (assetFolder.length > 1) {
                                    assetFolder.pop();
                                }
                                console.log("assetFolder --",assetFolder)
                                // let postData = {
                                //     filename: notification.category_assets[0].asset_name,
                                //     assettype: "video",
                                //     assetFolder:assetFolder.join(),
                                //   };
                                let postData = {
                                    filename: notification.category_assets[0].asset_name,
                                    assettype: "video",
                                    assetFolder:assetFolder.join(),
                                };
                                
                                HttpInterceptor.post(`${process.env.REACT_APP_FEEDAPI_URL}gets3signedassetsurl`, postData)
                                .then(async result => {
                                    console.log("bibibn-- video-", result.data.data)
                                    notification.category_image =result.data.data.replace("/playlist.m3u8", "/thumb/thumbnail_00001.png")
                                    asseturl=result.data.data.replace("/playlist.m3u8", "/thumb/thumbnail_00001.png")
                                    console.log("notification.category_image",notification.category_image)
                                }) .catch((err) => {
                                    console.log("BBIIN VIDEO ERR",err)

                                })

                                //   resultData.data.data.replace("/playlist.m3u8", "/thumb/thumbnail_00001.png"
                            }
                            console.log("asseturl=====",asseturl)
                        }
                        if (
                            notification.category_action === "post-feeds"
                        ) {
                            return (
                                <div
                                    key={index}
                                    className={notification.isRead > 0 ? "ssi-feeds-notification-feedsChannelItemContainer read-notification" : "ssi-feeds-notification-feedsChannelItemContainer"}

                                    data-channelId={notification._id}
                                    data-channelIndex={index}
                                    onClick={() => readNotifications(notification)}
                                >
                                    <div className="ssi-feeds-notification-feedsChannelItemContainerWrapper">
                                        <ProfileImageDisplay
                                          
                                            imageName={
                                                ""
                                            }
                                            firstLetterDisplay={props.getProfileDetails(notification.action_by[0].profile_id, 0)}
                                        />
                                        <div className='ssi-feeds-notification-text'>
                                            <div className="ssi-feeds-notification-feedsChannelItemText">
                                                <div className="txt text1">

                                                    <span>
                                                        <b>{props.getProfileDetails(notification.action_by[0].profile_id, props.user_id)}</b> {props.labels.LBLPOSTEDIN} <b> {notification.title_info.channel_name}</b> {notification.category_description?`: ${notification.category_description}`:""}

                                                    </span>
                                                </div>
                                            </div>
                                            <div className="ssi-feeds-notification-displayBlockTimeMsg">
                                                <span style={{ cursor: "default" }}>
                                                    {formatDistanceToNow(new Date(notification.action_by[0].createdAt), { addSuffix: true })}

                                                    {/* {props.calcForTimeCreation(
            this.props.feedItemData.createdAt
          )} */}
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            notification.category_image != "" ? (<img style={{ width: "50px", maxHeight: "60px" }} src={notification.category_image} alt="" />) : null
                                        }
                                        {/* https://d233uyqro7nd7g.cloudfront.net/media/video/11/j7tqkstgae83sst6n10jub_1723092996234/thumb/thumbnail_00001.png */}
                                        {/* {
                                            notification.category_image != "" ? (<img style={{ width: "15%" }} src={notification.category_image} alt="" />) : null
                                        } */}
                                    </div>
                                </div>
                            );
                        } else if (
                            notification.category_action === "likes"
                        ) {
                            let content="LBLLIKEDYOURPOST"

                            if(notification.category_assets?.length>0){
                                if(notification.category_assets[0].asset_type==="image"){
                                    content="LBLLIKEDYOURPHOTO"
                                }else if(notification.category_assets[0].asset_type==="video"){
                                     content="LBLLIKEDYOURVIDEO"
                                }
                                console.log("content",content)
                            }
                            return (
                                <div
                                    key={index}
                                    className={notification.isRead > 0 ? "ssi-feeds-notification-feedsChannelItemContainer read-notification" : "ssi-feeds-notification-feedsChannelItemContainer"}
                                    //                                     className={notification.isRead>0?"ssi-feeds-notification-feedsChannelItemContainer read-notification":"ssi-feeds-notification-feedsChannelItemContainer"}

                                    data-channelId={notification._id}
                                    data-channelIndex={index}
                                    onClick={readNotifications.bind(this, notification)}
                                >
                                    <div className="ssi-feeds-notification-feedsChannelItemContainerWrapper">
                                        <ProfileImageDisplay
                                           
                                            imageName={
                                                ""
                                            }
                                            firstLetterDisplay={props.getProfileDetails(notification.action_by[0].profile_id, 0)}
                                        />
                                        <div className='ssi-feeds-notification-text'>
                                            <div className="ssi-feeds-notification-feedsChannelItemText">
                                                <div className="txt text1">
                                                    <span> <b>{props.getProfileDetails(notification.action_by[0].profile_id, props.user_id)}{notification.action_by.length > 1 ? `${props.labels.LBLAND} ${props.labels.LBLOWERCASEOTHERS} ${notification.action_by.length - 1}` : ""} </b> {props.labels[content]} <b>{notification.title_info.channel_name}</b> {notification.category_description?`: ${notification.category_description}`:""}

                                                    </span>
                                                </div>
                                            </div>

                                            <div className="ssi-feeds-notification-displayBlockTimeMsg">
                                                <span style={{ cursor: "default" }}>
                                                    {formatDistanceToNow(new Date(notification.action_by[0].createdAt), { addSuffix: true })}

                                                    {/* {props.calcForTimeCreation(
            this.props.feedItemData.createdAt
          )} */}
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            notification.category_image != "" ? (<img style={{ width: "50px", maxHeight: "60px" }} src={notification.category_image} alt="" />) : null
                                        }
                                    </div>
                                </div>
                            );
                        } else if (
                            notification.category_action === "comments"
                        ) {
                            return (
                                <div
                                    key={index}
                                    className={notification.isRead > 0 ? "ssi-feeds-notification-feedsChannelItemContainer read-notification" : "ssi-feeds-notification-feedsChannelItemContainer"}

                                    data-channelId={notification._id}
                                    data-channelIndex={index}
                                    onClick={readNotifications.bind(this, notification)}
                                >
                                    <div className="ssi-feeds-notification-feedsChannelItemContainerWrapper">
                                        <ProfileImageDisplay

                                            imageName={
                                                ""
                                            }
                                            firstLetterDisplay={props.getProfileDetails(notification.action_by_union[0], 0)}
                                        />
                                        <div className='ssi-feeds-notification-text'>
                                            <div className="ssi-feeds-notification-feedsChannelItemText">
                                                <div className="txt text1">
                                                    <span> <b>{props.getProfileDetails(notification.action_by_union[0], props.user_id)}{notification.action_by_union.length > 1 ? ` ${props.labels.LBLAND} ${props.labels.LBLOWERCASEOTHERS}  ${notification.action_by_union.length - 1}` : ""} </b> {props.labels.LBLCOMMENTEDYOURPOST} <b> ${notification.title_info.channel_name}</b>  {notification.category_description?`: ${notification.category_description}`:""}
                                                    </span>
                                                    {/* <span>
                                                        <b>{props.getProfileDetails(notification.action_by[0].profile_id, props.user_id)}</b> Commented in: <b>{notification.category_description}</b>

                                                    </span> */}
                                                </div>
                                            </div>
                                            <div className="ssi-feeds-notification-displayBlockTimeMsg">
                                                <span style={{ cursor: "default" }}>
                                                    {formatDistanceToNow(new Date(notification.action_by[0].createdAt), { addSuffix: true })}
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            notification.category_image != "" ? (<img style={{ width: "50px", maxHeight: "60px" }} src={notification.category_image} alt="" />) : null
                                        }
                                    </div>
                                </div>
                            );
                        }
                    })
                ) : (
                    <div className="ssi-feeds-feedsNoChannelItemContainer">
                      
                            <span>
                            <EmptyState 
                            className="notifyemptyimg"
                            image={emptyImage} 
                            text2={props.labels.LBLNONOTIFICATIONS} 
                             
                             ></EmptyState> 
                           </span>
                    </div>
                )}
            </div>

        </div>
    )

}
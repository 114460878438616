import React from "react";
import DataTable from "react-data-table-component";
import Loader from "../../../admin/components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import ChannelsDatatable from "./ChannelsDatatable.js";
import emptyImage from '../../../assets/images/storyfeeds_emptyst.png'
import orderBy from "lodash/orderBy";
import EmptyState from "../../../common_components/empty_state/Empty_state";


let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userSearchValue: "",
      showErrMesg: false,
      listUsers: this.props.listUsers,
      selUserDetailsItem: null,
      showGroupsPopup: 0,
      isLoadAPI: 0,
      showModalDetails: false,
      searchText: "",
      Sitetype:localStorage.getItem('site_type')
    };
  }
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.channelsLanguageData &&
      this.props.channelsLanguageData[paramLBL]
        ? this.props.channelsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  cancelUserList = () => {
    this.props.cancelUserList();
  };
  updateUserListToChannel = () => {
    var postData = [];
    this.state.listUsers.map((obj) => {
      if (
        obj.isNewAssigned !== undefined &&
        obj.isNewAssigned !== obj.channel_assigned
      ) {
        obj.channel_assigned = obj.isNewAssigned;
        delete obj.isNewAssigned;
        postData.push(obj);
      }
    });
    this.setState({ isLoadAPI: 1 });
    this.props.updateUserListToChannel(postData);
  };
  tableHeader = () => {
    return (
      <div className="table-filter">
        <input
          type="text"
          className="input-field"
          placeholder={this.getResourceFromLabels("LBLSEARCHBYNAMEEMAIL")}
          value={this.state.userSearchValue}
          onChange={this.searchUserHandler}
          onKeyDown={this.searchUserHandler}
        />
      </div>
    );
  };
  popupUserTableHeader = () => {
    return (
      <div className="table-filter">
        <div className="assignment-header">
          <input
            type="text"
            className="input-field"
            placeholder={this.getResourceFromLabels("LBLGROUPNAME")}
            value={this.state.searchText}
            onChange={this.searchUser}
            onKeyDown={this.searchUser}
          />
        </div>
      </div>
    );
  };
  searchUser = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };
  searchUserHandler = (e) => {
    this.setState({
      userSearchValue: e.target.value,
    });
  };
  updateUserAssignment = (item, status, evt) => {
    if (item !== undefined) {
      this.state.showErrMesg = false;

      for (var i = 0; i < this.state.listUsers.length; i++) {
        if (item._id === this.state.listUsers[i]._id) {
          this.state.listUsers[i].isNewAssigned = evt.target.checked ? 1 : 0;

          this.setState((state, props) => ({
            listUsers: this.state.listUsers,
          }));
          break;
        }
      }
    }
  };
  assignedGroupViewHandler = (item, evt) => {
    this.setState(
      {
        selUserDetailsItem: item,
        showGroupsPopup: 1,
        showModalDetails: true,
      },
      () => {
        if (this.state.showGroupsPopup === 1) {
        }
      }
    );
  };
  popupOutsideClickHandler = (evt) => {
    let elements = evt.target; //document.getElementById("target");
    let els = [];
    let classAtr = "";
    while (elements) {
      els.unshift(elements);
      elements = elements.parentNode;
      classAtr =
        elements && elements.getAttribute && elements.getAttribute("class")
          ? elements.getAttribute("class")
          : "";
      if (classAtr === "ssi-mc-userListGroupPopupWrapper") {
        break;
      }
    }
    if (!classAtr || classAtr !== "ssi-mc-userListGroupPopupWrapper") {
      document.removeEventListener("click", this.popupOutsideClickHandler);
      this.setState({
        showGroupsPopup: 0,
      });
    }
  };
  closeModalHandler = () => {
    this.setState({
      showModalDetails: false,
      searchText: "",
    });
  };
  render() {
    const { listUsers } = this.state;
    const filterGroup = listUsers.filter((item) => {
      let fullName = item.first_name + " " + item.last_name;
      if (
        (fullName &&
          (fullName.includes(this.state.userSearchValue) ||
            fullName.toLowerCase().includes(this.state.userSearchValue))) ||
        (item.email &&
          (item.email.includes(this.state.userSearchValue) ||
            item.email.toLowerCase().includes(this.state.userSearchValue)))
      ) {
        return item;
      }
    });
    const filterPopupGroup =
      this.state.selUserDetailsItem !== null
        ? this.state.selUserDetailsItem.userfollowed.filter((item) => {
            if (
              item.following_type_name &&
              (item.following_type_name.includes(this.state.searchText) ||
                item.following_type_name
                  .toLowerCase()
                  .includes(this.state.searchText)) //||
            ) {
              return item;
            }
          })
        : "";
    let userTableHeader = this.tableHeader();
    let popupUserTableHeader = this.popupUserTableHeader();
    const {
      // LBLADDNEWGROUP,
      // LBLGROUPNAME,
      // LBLSTATUS,
      // LBLACTION,
      // LBLEDIT,
      // LBLDELETE,
      // LBLACTIVE,
      // LBLINACTIVE,
      // LBLCANCEL,
      LBLOF,
      LBLROWSPERPAGE,
      //LBLNOGROUPS,
      LBLALL,
    } = this.props.channelsLanguageData;
    let usercolumns = [
      {
        name: this.getResourceFromLabels("LBLSELECT"),
        selector: "order",
        sortable: true,
        compact: true,
        width: "10%",
        cell: (row) => (
          <div className="w100">
            <label className="show-sm">
              {this.getResourceFromLabels("LBLSELENROLLUSERS")}
            </label>
            <label className="check-lbl">
              <input
                type="checkbox"
                name={"chk_" + row.id}
                id={"chk_" + row.id}
                data-toggle="modal"
                checked={
                  row.isNewAssigned !== undefined
                    ? row.isNewAssigned
                    : row.channel_assigned
                }
                onChange={this.updateUserAssignment.bind(
                  this,
                  row,
                  row.isNewAssigned ? 1 : 0
                )}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        ),
      },
      { 
        name: this.getResourceFromLabels("LBLNAME"),
        selector: "first_name",
        sortable: true,
        width: "30%",
        compact: true,
        cell: (row) => (
          <div className="grid-ellipsis">
            <label className="show-sm">
              {this.getResourceFromLabels("LBLNAME")}
            </label>
            {row.first_name +
              " " +
              ("" + row.last_name === "null" ? "" : row.last_name)}
          </div>
        ),
      },
      {
        name: this.getResourceFromLabels("LBLEMAILID"),
        selector: "email",
        sortable: true,
        width: "30%",
        compact: true,
        cell: (row) => (
          <div className="grid-ellipsis">
            <label className="show-sm">
              {this.getResourceFromLabels("LBLEMAILID")}
            </label>
            {row.email}
          </div>
        ),
      },
      {
        name: this.getResourceFromLabels("LBLSTATUS"),
        selector: "active_status",
        sortable: true,
        width: "10%",
        compact: true,
        cell: (row) => (
          <div>
            <label className="show-sm">
              {this.getResourceFromLabels("LBLSTATUS")}
            </label>
            {row.active_status === 0
              ? this.getResourceFromLabels("LBLINACTIVE")
              : this.getResourceFromLabels("LBLACTIVE")}
          </div>
        ),
      },
      {
        name: this.getResourceFromLabels("LBLGROUPSENROLLED"),
        sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => (
          <div>
            <label className="show-sm">
              {this.getResourceFromLabels("LBLGROUPSENROLLED")}
            </label>
            <div
              className={
                row.userfollowed && row.userfollowed.length
                  ? "ssi-mc-userListAssigned"
                  : "ssi-mc-userListAssignedDisable"
              }
              onClick={this.assignedGroupViewHandler.bind(this, row)}
            >
              <span className="count">
              {row.userfollowed && row.userfollowed.length
                  ?  row.userfollowed.length
                  : "-"}
              </span>
              <span className="groupIcon fa fa-users"></span>
            </div>
          </div>
        ),
      },
    ];

    const customSort = (rows, field, direction) => {
      const handleField = (row) => {
        if (
          row[field] &&
          row[field] !== 1 &&
          row[field] !== 0 &&
          typeof row[field] !== "boolean"
        ) {
          return row[field].toString().toLowerCase();
        }
  
        return row[field];
      };
  
      return orderBy(rows, handleField, direction);
    };
    

    let popupUserColumns = [
      {
        name: this.getResourceFromLabels("LBLGROUPNAME"),
        selector: "following_type_name",
        sortable: true,
        // width: "30%",
        compact: true,
        cell: (row) => (
          <div>
            {row.following_type_name.charAt(0).toUpperCase() +
              row.following_type_name.slice(1)}
          </div>
        ),
      },
      {
        name: this.getResourceFromLabels("LBLGROUPTYPES"),
        selector: "following_type",
        sortable: true,
        right: true,
        cell: (row) => (
          <div>
            {row.following_type.charAt(0).toUpperCase() +
              row.following_type.slice(1)}
          </div>
        ),
      },
    ];
    let userdata = filterGroup;
    let popupUserData = filterPopupGroup;
    //let groupdata = filterGroup;
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };

    return (
      //<div className="card-box">
      <div className="mob-pad">
        <div className="page-title">
          <h3>
            {this.getResourceFromLabels("LBLCHANNELASSINMENTENROLLUSERS")}
          </h3>
        </div>
        <hr className="head-line" />
        <div className="ssi-manch-userListChannelHeader">
          <span className="pageSubTitle">{this.props.selectedChannelItem.channel_name}</span>
        </div>
        <div className="">
          {this.state.loading ? (
            <div>
              <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                <header className="sc-fzoXzr hlidmt">
                  <Skeleton height={41} />
                </header>
                <div className="sc-fznWqX gnahTY">
                  <div className="sc-AxjAm gIMaKV rdt_Table">
                    <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                      <Skeleton height={56} />
                    </div>
                    <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                      <Skeleton height={480} />
                    </div>
                  </div>
                </div>
                <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                  <Skeleton height={56} />
                </nav>
              </div>
              <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                <header className="sc-fzoXzr hlidmt">
                  <Skeleton height={34} />
                </header>
                <div className="sc-fznWqX gnahTY">
                  <div className="sc-AxjAm gIMaKV rdt_Table">
                    <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                      {skel.map((i) => {
                        return (
                          <div
                            id={"row-" + i}
                            className="sc-fzoLsD doBktq rdt_TableRow"
                          >
                            <Skeleton height={183} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                  <div className="sc-fzpmMD ftIMtk">
                    <Skeleton height={40} />
                  </div>
                </nav>
              </div>
            </div>
          ) : ( 
            <DataTable
              columns={usercolumns}
              data={userdata}
              className="datatable-list"
              pagination
              subHeader
              subHeaderComponent={userTableHeader}
              sortFunction={customSort}
              noDataComponent=
              {<EmptyState image={emptyImage} text2={this.getResourceFromLabels("LBLNORECORDS")}/>}
              paginationComponentOptions={paginationComponentOptions}
            />
          )}
        </div>
        <div className="row section-body">
          <div
            id="selectproductError"
            className="col-md-12 required-field text-center d-none ssifeeds-style"
          >
            {this.getResourceFromLabels("LBLCHOOSEONEUSER")}
          </div>
          {this.state.isLoadAPI === 1 ? (
            <div className="ssi-manch-spinnerAbsoluteBotton">
              <Loader></Loader>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12 text-right">
            <button
              type="button"
              className="button-style  secondary-btn"
              style={{marginRight:"8px"}}
              onClick={this.cancelUserList}
            >
              {this.getResourceFromLabels("LBLCANCEL")}
            </button>
            <button
              type="button"
              className="button-style primary-btn"
              onClick={this.updateUserListToChannel}
            >
              {this.getResourceFromLabels("LBLCONFIRM")}
            </button>
          </div>
          <div className="ssi-userListLegends">
            <div className="assigned">
              <span className={this.state.Sitetype==='SafeStart'?"legendsIcon" :"greenlegendsIcon"}></span>
              <span>{this.getResourceFromLabels("LBLASSIGNEDUSERS")}</span>
            </div>
            <div className="unAssigned">
              <span className="legendsIcon1"></span>
              <span>{this.getResourceFromLabels("LBLUNASSIGNEDUSERS")}</span>
            </div>
          </div>
        </div>
        {this.state.showGroupsPopup === 1 ? (
          <ChannelsDatatable
            showModal={this.state.showModalDetails}
            closeModal={this.closeModalHandler}
            modalTitle={`${this.state.selUserDetailsItem.first_name} ${this.state.selUserDetailsItem.last_name}`}
            datatableHeader={popupUserTableHeader}
            datatableColumns={popupUserColumns}
            datatableData={popupUserData}
            noDataComponent={this.getResourceFromLabels("LBLNORECORDS")}
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={5}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default UserList;

import React, { Component } from "react";
import LoginMultiForm from "./LoginMultiForm";
import LoginForm from "./LoginForm";
import { Redirect } from "react-router-dom";
import ScAuth from "../auth/auth";
import { HttpInterceptor } from "../auth/HttpInterceptor";
import "./login.scss";
import { Modal } from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import userInfo from "../redux/action";
import ScCommonServices from "../services/common_services";
import CompanyLogo from "../admin/components/companylogo/companylogo";
import Auth0Login from "../sso/auth0login";
import Loader from "../admin/components/loader/Loader";
import common_data from "../services/common_data";
import CognitoLogin from "../sso/cognito_auth";
import ClasslinkLogin from "../sso/ClasslinkLogin";
import {email} from "../admin/pages/validate/Validate";
import utils from "../utils/utils";

let languages = common_data.getLanguages();

let hash = "";
let checkSessionCalled = 0;


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      userId: null,
      userRole: null,
      apiError: false,
      errorMessage: null,
      userData: [],
      getUsername: false,
      user_pwd: null,
      company_logo: "",
      show_sso: {},
      sso_enabled:false,
      logging_in: false,
      google_logging_in: false,
      loading: true,
      cdTimer: 0,
      lang_code: "",
      showToast: false,
      showToast1: false,
      showToast2: false,
      isEmail: true,
      emailalerttext: "",
      site_type:"",
      profileDefaultRole: 0,
      default: ""
    };
    this.userLogin.bind(this);
  }
  componentDidMount() {
    if (window.gapi) {
      window.gapi.load("auth2", () => {
        window.gapi.auth2.getAuthInstance({
          client_id: process.env.REACT_APP_SSO_GOOGLE_SIGNON_ID
        });
      });
    }
    this.getCompanyInitSet();
  }

  getCompanyInitSet = () => {
    let logoname = this.props.company_logo;
    let google_sso;
    let sso_enabled;
    if (this.props.company_logo === "") {
      logoname = "./images/login-logo.png";
    }
    if (this.props.show_sso["google"]) {
      google_sso = true;
    } else {
      google_sso = false;
    }
    let auth0_sso =  this.props.show_sso["auth0_sso"]??this.props.show_sso["auth0_sso"];
    let cognito_sso =   this.props.show_sso["cognito_sso"]??this.props.show_sso["cognito_sso"]; 
    let classlink_sso =   this.props.show_sso["classlink_sso"]?? this.props.show_sso["classlink_sso"];

     sso_enabled= (Object.values(this.props.show_sso).includes(true));
    localStorage.setItem("site_type", this.props.site_type);
    this.setState({
      company_logo: logoname,
      show_sso: { google:google_sso,auth0_sso,cognito_sso,classlink_sso},
      sso_enabled,
      loading: false,
      login_with_phone_number: this.props.login_with_phone_number,
      lang_code: this.props.lang_code,
      country_code: this.props.country_code,
      site_type: this.props.site_type,
    });
  };
  responseGoogle = (response) => {
    if (response["profileObj"]) {
      this.setState({
        google_logging_in: true,
      });
      let LBLLOGINAGAIN = this.props.labels.labels.LBLLOGINAGAIN;
      let LBLINTERNALSERERROR = this.props.labels.labels.LBLINTERNALSERERROR;
      let data = {};
      data.login_type = 1;
      data.name = response.profileObj.name;
      data.email = response.profileObj.email;
      ScCommonServices.generateId(data)
        .then((res_id) => {
          if (res_id.length > 1) {
            this.setState({
              getUsername: true,
              user_pwd: hash,
            });
            return null;
          } else if (res_id.length === 1) {
            let userReducerData = {
              name: res_id[0].user_name,
              user_id: res_id[0].user_id,
              first_name: res_id[0].first_name,
              last_name: res_id[0].last_name,
              role: res_id[0].role_name,
              email: res_id[0].email,
              email_verified: res_id[0].email_verified,
              company_id: res_id[0].company_id,
              role_id: res_id[0].role_id,
              roleIds: res_id[0].roleIds,
              language_code: res_id[0].language_code,
              user_language: languages[res_id[0].language_code],
              company_name: res_id[0].company_name,
              timezone: res_id[0].timezone,
              dateformat: res_id[0].dateformat,
              timeformat: res_id[0].timeformat,
              currency: res_id[0].currency,
              company_logo: res_id[0].company_logo,
              ssi_iam_user: res_id[0].iam_user,
              showcategory_filter: res_id[0].showcategory_filter,
              showunits_certificate: res_id[0].showunits_certificate,
            };
            ScCommonServices.generateAccess({
              sc_id: res_id[0].token,
              login_type: res_id[0].login_type,
            })
              .then((access) => {
                if (access.grant === "true") {
                  this.props.user_info_reducer(userReducerData);
                  this.setState({
                    get: false,
                    apiError: false,
                  });
                  localStorage.setItem("ssi_google_sso", true);
                  ScAuth.login(() => {
                    if (res_id[0].role_id === 1 || res_id[0].role_id === 2) {
                      this.props.history.push("/schome/products");
                    } else {
                      console.log("home/products")
                      this.props.history.push("/home/products");
                    }
                  });
                }
              })
              .catch((errr) => {
                this.setState({
                  apiError: true,
                  errorMessage: { LBLLOGINAGAIN },
                  google_logging_in: false,
                });
              });
          }
        })
        .catch((err) => {
          if (!err.response) {
            this.setState({
              apiError: true,
              errorMessage: { LBLINTERNALSERERROR },
              google_logging_in: false,
            });
          } else {
            let errMsg = this.props.labels.labels[err.response.data.message];
            this.setState({
              apiError: true,
              errorMessage: errMsg,
              google_logging_in: false,
            });
          }
        });
    }
  };


  onGoogleSignOnClick = (googleInfo) => {
    if (googleInfo !== undefined) {
      let googleprofile = googleInfo.getBasicProfile();
      let data = {};
      data.login_type = 1;
      data.name = googleprofile.ofa;
      data.email = googleprofile.U3;
      let LBLEMAILNOTLINKED = this.props.labels.labels.LBLEMAILNOTLINKED;

      HttpInterceptor.post("scauthe", data)
        .then((res) => {
          let roleId = res.data.item[0].role_id;
          HttpInterceptor.post("scautho", { sc_id: res.data.item[0].token })
          .then((ress) => {
              if (ress.data.grant === "true") {
                this.setState({
                  get: false,
                  apiError: false,
                });
                ScAuth.login(() => {
                  localStorage.setItem("sc_userid", res.data.item[0].id);
                  localStorage.setItem(
                    "sc_company_id",
                    res.data.item[0].company_id
                  );
                  localStorage.setItem("sc_roleid", res.data.item[0].role_id);
                  if (roleId === 100 || roleId === 80) {
                    this.props.history.push("/schome/dashboard");
                  } else {
                    this.props.history.push("/home/feeds");
                  }
                });
              }
            })
            .catch((errr) => {
              this.setState({
                apiError: true,
                errorMessage: { LBLEMAILNOTLINKED },
              });
            });
        })
        .catch((err) => {
          this.setState({
            apiError: true,
            errorMessage: { LBLEMAILNOTLINKED },
          });
        });
    }
  };

  generateOtp = async (values) => {

    let data = {};
    if (values.password) {

      await sha512(values.password || values.passwordotp).then(result => {
        hash = result;
      })
      data = {
        name: values.username,
        password: this.state.getUsername ? this.state.user_pwd : hash,
        login_type: 0,
      };
    } else {
      data = values;
    }

    ScCommonServices.generateId(data).then((res_id) => {
      if (res_id.length > 1) {
        this.setState({
          getUsername: true,
          user_pwd: hash,
        });
        return null;
      } else if (res_id.length === 1) {
      }
    });
  };
  countDownTimer = () => {
    let cdTimer = this.state.cdTimer;
    if (cdTimer > 0) {
      this.setState({
        cdTimer: cdTimer - 1,
      });
      setTimeout(this.countDownTimer, 1000);
    }
  };
  generateSMSOtp = (values) => {
    let data = {};
    let li = document.getElementsByTagName("input");
    for (let i = 0; i < li.length; i++) {
      if (li[i].name === "username") {
        data.name = li[i].value;
      }
    }

    ScCommonServices.getGenerateOTP(data)
      .then((res_id) => {
        this.setState({
          cdTimer: 60,
          screenstatus: "otp",
        });

        setTimeout(this.countDownTimer, 1000);
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            apiError: true,
            errorMessage: this.props.labels.labels.LBLINTERNALSERERROR,
          });
        } else {
          let errMsg = this.props.labels.labels[err.response.data.message];
          this.setState({
            apiError: true,
            errorMessage: errMsg,
          });
        }
      });
  };

  closeModal1 = () => {
    console.log('closemodal1')
    this.setState({
      showToast1: false,
      emailalerttext: ""
    })
    this.props.history.push("/home/products")
  }

  closeModal2 = () => {
    console.log('closemodal2')
    this.setState({
      showToast2: false,
      emailalerttext: ""
    })
    this.props.history.push("/home/products")
  }

  closeModal3 = () => {
    localStorage.removeItem("sc_user");
    console.log('closemodal3')
    this.setState({
      showToast: false,
      emailalerttext: ""
    })
  }

  emailpatt = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  checkIsEmail = (value) => {
    console.log(value.username,"vaues.username")
    if (value.username && this.emailpatt.test(value.username.toLowerCase())) {
      if(email(value.username) === "LBLINVALIDEMAIL"){
        return "LBLINVALIDEMAIL";
      } else {
        return true
      }
    } else {
      return false;
    } 
  }
  
  userLogin = async (values) => {
    this.setState({
      logging_in: true,
      isEmail: false,
    });
    let LBLLOGINAGAIN = this.props.labels.labels.LBLLOGINAGAIN;
    let LBLINTERNALSERERROR = this.props.labels.labels.LBLINTERNALSERERROR;
    let data = {};
    if(this.checkIsEmail(values) === "LBLINVALIDEMAIL"){
      this.setState({
        showToast2: true,
        emailnotverified: false,
        isEmail: true,
        logging_in: false,
        emailalerttext:  this.props.labels.labels.LBLINVALIDEMAIL ,
        
      });
      
      return true;
    }

    if (values.password || values.passwordotp) {
     await sha512(values.password || values.passwordotp).then(result => {
        hash = result;
      })
      data = {
        name: values.username,
        password: this.state.getUsername ? this.state.user_pwd : hash,
        passwordotp: values.passwordotp,
        login_type: 0,
      };
    } else {
      data = values;
    }

    ScCommonServices.generateId(data)
      .then((res_id) => {
        if (res_id.length > 1) {
          this.setState({
            getUsername: true,
            user_pwd: hash,
          });
          return null;
        } else if (res_id.length === 1) {
          this.setState({profileDefaultRole:res_id[0].role_id})
          let userReducerData = {
            name: res_id[0].user_name,
            user_id: res_id[0].user_id,
            first_name: res_id[0].first_name,
            last_name: res_id[0].last_name,
            role: res_id[0].role_name,
            email: res_id[0].email,
            email_verified: res_id[0].email_verified,
            company_id: res_id[0].company_id,
            role_id: res_id[0].role_id,
            roleIds: res_id[0].roleIds,
            language_code: res_id[0].language_code,
            user_language: languages[res_id[0].language_code],
            company_name: res_id[0].company_name,
            timezone: res_id[0].timezone,
            dateformat: res_id[0].dateformat,
            timeformat: res_id[0].timeformat,
            currency: res_id[0].currency,
            company_logo: res_id[0].company_logo,
            ssi_iam_user: res_id[0].iam_user,
            ssi_data_priv: res_id[0].managing_groups ? true : false,
            showcategory_filter: res_id[0].showcategory_filter,
            showunits_certificate: res_id[0].showunits_certificate,
          };
          ScCommonServices.generateAccess({
            sc_id: res_id[0].token,
            login_type: res_id[0].login_type,
          })
            .then((access) => {
              userReducerData.skip = access?.skip?.toLowerCase();
              userReducerData.survey = access.survey_id ? access.survey_id : "";
              userReducerData.default = access.default_page;
              this.setState({default:access.default_page})
              if (access.grant === "true") {
                this.props.user_info_reducer(userReducerData);
                this.setState({
                  get: false,
                  apiError: false,
                });
                if (res_id[0].force_password_change === 1) {
                  this.props.history.push(
                    "/forgot-password/?4hf_verification=" +
                    res_id[0].resetpasswordtoken +
                    "&status=true&fg=1"
                  );
                } else {
                  ScAuth.login(async () => {
                    let search,
                      redir_path = "",
                      history = this.props.history;
                    if (history.location.state) {
                      redir_path = history.location.state.from.pathname;
                      search = history.location.state.from.search;
                    }

                    if ((res_id[0].roleIds.indexOf(1) > -1 || res_id[0].roleIds.indexOf(2) > -1)) {
                      if (
                        (
                          (res_id[0].email === "" || res_id[0].email === null) && res_id[0].roleIds.length === 1) || (res_id[0].email && res_id[0].email_verified === null && res_id[0].roleIds.length === 1
                        )
                      ) {
                        console.log("666666666666666666666666666666666666666")

                        console.log("Check 1")
                       
                        this.setState({
                          showToast: true,
                          emailnotverified: false,
                          isEmail: true,
                          logging_in: false,
                          emailalerttext: res_id[0].email ? this.props.labels.labels.LBLROLEEMAILVERIFY : this.props.labels.labels.LBLADMINEMAIL
                        });

                        return;
                      }
                      else if (
                        (res_id[0].email === null || (res_id[0].email !== "" && res_id[0].email !== null && res_id[0].email_verified === null)) && (res_id[0].roleIds.indexOf(4) > -1 || res_id[0].roleIds.indexOf(3) > -1)){
                        console.log("!1111111111111111111111111111")

                        if (res_id[0].roleIds.indexOf(3) > -1) {
                          userReducerData.role_id = 3;
                          userReducerData.role = 'LBLREPORTMANAGER';
                          this.props.user_info_reducer(userReducerData);

                          console.log("Check 2")
                          this.setState({
                            showToast2: true,
                            emailnotverified: false,
                            isEmail: true,
                            logging_in: false,
                            emailalerttext: res_id[0].email ? this.props.labels.labels.LBLROLEEMAILVERIFYWITHMULTIPLEROLE : this.props.labels.labels.LBLADMINEMAILWITHMULTIPLEROLE
                          });

                          return true;

                        }
                        else if (res_id[0].roleIds.indexOf(4) > -1) {
                          userReducerData.role_id = 4;
                          userReducerData.role = 'LBLUSER';
                          this.props.user_info_reducer(userReducerData);

                          console.log("Check 3")
                          this.setState({
                            showToast2: true,
                            emailnotverified: false,
                            isEmail: true,
                            logging_in: false,
                            emailalerttext: res_id[0].email ? this.props.labels.labels.LBLROLEEMAILVERIFYWITHMULTIPLEROLE : this.props.labels.labels.LBLADMINEMAILWITHMULTIPLEROLE
                          });
                          return true;
                        }
                      }
                      else {
                        if (redir_path) {
                          this.props.history.push(redir_path + search);
                        } else {
                          if (this.state.isRedirect) {
                            this.props.history.push("/schome/userlist")
                          } else {
                            let path = await utils.filePath(res_id[0].role_id,this.state.default);
                            console.log(this.props.history,path,"filePath called")
                           this.props.history.push(path);
                          }
                        }                        
                      }
                    } 
                    else {
                        console.log("else1",redir_path)
                        if (redir_path) {
                          this.props.history.push(redir_path + search);
                        } else {
                          console.log('closemodal22222')
                          this.props.history.push("/home/products");
                        }

                    }
                  });
                }
              }
            })
            .catch((errr) => {
              this.setState({
                apiError: true,
                errorMessage: { LBLLOGINAGAIN },
              });
            });
        }
      })
      .catch((err) => {
        if (!err.response) {
          this.setState({
            apiError: true,
            errorMessage: { LBLINTERNALSERERROR },
            logging_in: false,
          });
        } else {
          let errMsg = this.props.labels.labels[err.response.data.message];
          this.setState({
            apiError: true,
            errorMessage:
              errMsg === this.props.labels.labels.LBLLOGINERROR3
                ? this.props.labels.labels.LBLENTEREMAIL
                : errMsg,
            logging_in: false,
          });
        }
      });
  };
  checkSession = () => {
    if (ScAuth.isLoggedIn() === "true" && checkSessionCalled === 0) {
      checkSessionCalled = 1;
      HttpInterceptor.get("authVerification")
        .then((res) => {
          if (res.data.auth === "true") {
            this.setState({
              checked: true,
              userRole: res.data.user.role,
              default: res.data.default_page
            });
          } else {
            localStorage.setItem("sc_user", false);
            localStorage.removeItem("sc_user");
            localStorage.clear();
            ScAuth.logout(async() => {
              const setCok = await HttpInterceptor.get('cookieset');
              this.props.history.push("/");
            });
          }
        })
        .catch((err) => {
          if (
            err.response.status === 401 &&
            err.response.data.message === "UnAuthorized Request"
          ) {
            this.setState({
              checked: false,
            });
            localStorage.setItem("sc_user", false);
            localStorage.removeItem("sc_user");
            localStorage.clear();
            ScAuth.logout(async() => {
              const setCok = await HttpInterceptor.get('cookieset');
              this.props.history.push("/");
            });
          }
        });
    }
  };
  clearErrorMsg = () => {
    this.setState({ apiError: "", errorMessage: "" });
  };
  render() {
    const { labels } = this.props;
    const { show_sso, logging_in, google_logging_in, loading,sso_enabled } = this.state;
    const { enable_register } = this.props;
    if (ScAuth.isLoggedIn() === null || this.state.showToast1 || this.state.showToast2 || this.state.showToast) {
      if (!loading) {
        return (
          <>
            <div className={`login-bg  ${this.state.site_type === 'Accutrain'? "login-bg-accutrain":""}`}>
              <div className="container login-container">
                <div className="row">
                  <div className="col-12 ">
                     <div className="login-card-tt">
                      {this.state.company_logo !== "" ? (
                        <CompanyLogo
                          className="applogo"
                          imageName={this.state.company_logo}
                          imgWidth="317px"
                          company_id={this.props.company_id}
                        />
                      ) : (
                        <span></span>
                      )}
                    </div> 
                  </div>
                  <div className="col-12">
                    <div className="login-card sign-in-section">
                      <h2>{labels.labels.LBLSIGNINURACCOUNT}</h2>
                      <>
                        {this.state.login_with_phone_number === 0 ? (
                          <>
                            <LoginForm
                              loading={loading}
                              logging_in={logging_in}
                              sso_login_loading={google_logging_in}
                              onSubmit={this.userLogin}
                              labels={labels}
                              getUsername={this.state.getUsername}
                              errorMessage={
                                this.state.apiError ? this.state.errorMessage : ""
                              }
                              clearErrorMsg={this.clearErrorMsg}
                            ></LoginForm>
                          </>
                        ) : (
                          <>
                            <LoginMultiForm
                              onSubmit={this.userLogin}
                              labels={labels}
                              lang_code={this.state.lang_code}
                              country_code={this.state.country_code}
                              getUsername={this.state.getUsername}
                              errorMessage={
                                this.state.apiError ? this.state.errorMessage : ""
                              }
                              clearErrorMsg={this.clearErrorMsg}
                            ></LoginMultiForm>
                          </>

                        )}

                      </>

                      <div className="">
                        <div className="text-center links">
                          <Link
                            to={{
                              pathname: "/forgot-password",
                              state: this.state,
                            }}
                          >
                            {labels.labels.LBLFORGOTPASSWORD}
                          </Link>
                          {enable_register === 1 ? (
                            <Link
                              to={{
                                pathname: "/user-register",
                                state: this.state,
                              }}
                            >
                              {" "}
                              {labels.labels.LBLREGISTERWITHCODE}{" "}
                            </Link>
                          ) : null}
                        </div>
                      </div>
                      { sso_enabled ? (
                            <div className="sso-btn-container">
                              <h6>
                                <span>{labels.labels.LBLOR}</span>
                              </h6>
                                      {show_sso["google"] ? (
                                          <div className="sso-btn-item">
                                            <GoogleLogin
                                              clientId={process.env.REACT_APP_SSO_GOOGLE_SIGNON_ID}
                                              render={(renderProps) => {
                                                if (!google_logging_in) {
                                                  return (
                                                    <button
                                                      className="btn btn-block sso-btn"
                                                      id="loginButton"
                                                      onClick={renderProps.onClick}
                                                      disabled={google_logging_in || logging_in}
                                                    >
                                                      <img
                                                        src="./images/glogo.jpg"
                                                        title="Google"
                                                        alt=""
                                                        className="sso-btn-img"
                                                      />
                                                      <span className="sso-btn-text">
                                                        {labels.labels.LBLSIGNINWITHGOOGLE}
                                                      </span>
                                                    </button>
                                                  );
                                                } else if (google_logging_in) {
        
                                                  return <Loader></Loader>;
                                                }
                                              }}
                                              buttonText="Login"
                                              onSuccess={this.responseGoogle}
                                              onFailure={this.responseGoogle}
                                              cookiePolicy={"single_host_origin"}
                                            />
                                          </div>
                                        ):''}
                                      {show_sso["auth0_sso"] ?(
                                      
                                        <div className="sso-btn-item">
                                          <Auth0Login labels={labels}></Auth0Login>
                                        </div>
                                        
                                      ):''}
                                      {show_sso["cognito_sso"] ?(
                                        
                                          <div className="sso-btn-item">
                                            <CognitoLogin labels={labels}></CognitoLogin>
                                          </div>
                                        
                                        ):''} 
                                      {show_sso["classlink_sso"] ?(
        
                                          <div className="sso-btn-item">
                                            <ClasslinkLogin labels={labels}></ClasslinkLogin>
                                          </div>
        
                                        ):''} 
                            </div>
                        ):''}
                    </div>
                  </div>
                </div>
                {this.state.site_type === 'Accutrain'?
                <div className="paragraph">
                <p className="para-line">  We are pleased to let you know that the AccuTrain Connect elearning platform has a new name:
                    the AccuEd Connect elearning platform. </p>
                  <p className="para-line">On July 29, 2023, your elearning site was updated with the new AccuEd Connect logo, and
                  notifications/emails are sent now from notifications@accuedconnect.app</p> 
                  <p className="para-line">There will be no impact to your data, courses or information. You are automatically redirected
                 from “yoursitename.youfactors.com” to “yoursitename.accuedconnect.app” until January 1,
                 2024. We suggest updating your bookmark with the new link.</p>
                 <p className="para-line">If you have any questions, please contact support@accutrain.com</p>
                </div>
     :"" }
              </div>
              
            </div>

            <Modal
              show={this.state.showToast1}
              onHide={this.closeModal1}
            >
              <Modal.Header closeButton className='alert-modal-header'>
                <Modal.Title className= "alert-modal-title">
                  {this.props.labels.labels.LBLEMAILVERIFICATION}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">{this.state.emailalerttext}</div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button
                      type="button"
                      className="button-style primary-btn"
                      id="btn-ok"
                      onClick={this.closeModal1}
                    >
                      {this.props.labels.labels.LBLOK}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>


            <Modal
              show={this.state.showToast2}
              onHide={this.closeModal2}
            >
              <Modal.Header closeButton className='alert-modal-header'>
                <Modal.Title className= "alert-modal-title">
                  {this.props.labels.labels.LBLEMAILVERIFICATION}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">{this.state.emailalerttext}</div>
               
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button
                      type="button"
                      className="button-style primary-btn"
                      id="btn-ok"
                      onClick={this.closeModal2}
                    >
                      {this.props.labels.labels.LBLOK}
                    </button>    
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={this.state.showToast}
              onHide={this.closeModal3}
            >
              <Modal.Header closeButton className='alert-modal-header'>
                <Modal.Title className= "alert-modal-title">
                 {this.props.labels.labels.LBLEMAILVERIFICATION}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">{this.state.emailalerttext}</div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button
                      type="button"
                      className="button-style primary-btn"
                      id="btn-ok"
                      onClick={this.closeModal3}
                    >
                      {this.props.labels.labels.LBLOK}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>

        );
      } else {
        console.log("ffff")
        return <Loader></Loader>;
      }
    } else if (!this.state.showToast1 && !this.state.showToast2 && !this.state.showToast) {
      this.checkSession();
      if (!this.state.checked) {
        return <Loader></Loader>;
      } else {
        let path = utils.filePath(this.state.userRole,this.state.default);
        console.log("Redirect login called",path,this.state.userRole,this.state.default)
        if ((this.state.userRole === 1 || this.state.userRole === 2)){
          return <Redirect to={path} />;
        } else {
          return <Redirect to="/home/products" />;
        }
      }
    }
  }
}

async function sha512(str) {
  return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(arrayBuffer => {
    var base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
    return(base64String);
  });
}

const mapDispatchToProps = (dispatch) => {
  return {
    user_info_reducer: (userReducerData) => dispatch(userInfo(userReducerData)),
  };
};
export default connect(null, mapDispatchToProps)(Login);
//export default  Login;
import React from "react";
import UsersService from "../user-list/UsersService";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Select, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import DataTable from "react-data-table-component";
import "date-fns";
import Alert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { RemoveFromQueue } from "@material-ui/icons";
import { addMonths } from "date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getFormValues } from "redux-form";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
// import { TRUE } from 'node-sass';
const $ = require("jquery");
let addnewmember = false;
let g_inactive_user = false;
let onmember = false, onrole = false;
class TeamMembersList extends React.Component {
  constructor(props) { 
    super(props);
    const { callcomponents, editTeamDetails } = props;

    this.state = {
      addTeamMember: false, 
      steercomSettings: this.props.steercomSettings,
      userlist: [],
      isEditRole:false,
      opendialog: false,
      opendialog1: false,
      showAddMember: true,
      dateformat: props.dateformat,
      loading: false,
      showtime: 3000,
      openalert: false,
      isPriorTeam: false,
      addnlAdminName: "",
      teamMembersList: this.props.teamMembersList,
      teamData: this.props.teamData,
      teamMemberData: {},
      customDuration: true,
      showPriorMemebers: true,
      maxEndDate: "",
      isNewRecord: true,
      editingMembers: {
        M_NEW: {
          teamId: this.props.teamData.team_id,
          memberId: "NEW",
          memberUserId: "",
          memberSteercomRoleId: "",
          memberDuration: "18",
          memberStartDate: new Date(new Date(this.props.teamData.start_date).setMonth(new Date(this.props.teamData.start_date).getMonth() + 0)),
          memberEndDate: new Date(new Date(this.props.teamData.start_date).setMonth(new Date(this.props.teamData.start_date).getMonth() + 18)),
          memberStatus: 1,
          memberOpenState: false,
          roleOpenState: false,
          serviceDurationOpenState: false,
          oldMumberUserId: "",
          customDuration: true
        },
      },

      memberIdOpenState: "",
      memberSteercomRoleIdOpenState: "",

      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
    };
    this.box = React.createRef(); 
  }
  getUsers = async() => { 
    let addnlAdminName="",result = await SteercomServices.getStreercomUsers();
    UsersService.getUsers({ active: true })
      .then((res) => {
       let allusers=[], suserslist=[], userlist = res.result; 
       suserslist = result.result[0]?JSON.parse(result.result[0][0].users):[];
        userlist.map((item,x)=>{
          suserslist.map((itm,y)=>{
            if(itm.user_id == item.user_id){
              allusers.push(item)
            }
          });
        });

        this.sortResults(allusers, "name", true);
        this.setactiveusers(allusers);
        let user_response = allusers; 
        allusers.map((itm, idx) => {
          if (itm.user_id == this.state.teamData.team_addnl_administrator) {
            addnlAdminName = (itm.first_name) + ', ' + (itm.last_name);
          }
        })
        this.setState((state, props) => ({
          userlist:allusers,
          addnlAdminName: addnlAdminName
        }));
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  getUsers_old = () => {
    UsersService.getUsers({ active: true })
      .then((res) => {
        let addnlAdminName = "", user_list = res.result;
        this.sortResults(user_list, "name", true);
        this.setactiveusers(user_list);
        user_list.map((itm, idx) => {
          if (itm.user_id == this.state.teamData.team_addnl_administrator) {
            addnlAdminName = (itm.first_name) + ', ' + (itm.last_name);
          }
        })
        this.setState((state, props) => ({
          userlist: res.result,
          addnlAdminName: addnlAdminName
        }));
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  setactiveusers = (user_list) => {
    let { teamMembersList } = this.state;
    teamMembersList.map((item, index) => {
      user_list.map((itm, idx) => {
        if ((item.user_id == itm.user_id) && (itm.active == 0)) {
          item.user_status = 0;
          g_inactive_user = true;
        }
      })
    })
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  componentDidMount() {
    $('#root').scrollTop(0); 
    $("#root").css("height", "100%");
    $("#header_top_edit").show();
    $("#priorTeamMembers").show();
    $("#root").css("overflow", "hidden");
    $("#edit_teams").hide();
    $(".cls_add_teams").show(); 
    var header = this.props.labels.labels.LBLTEAMMEMBERSLIST;
    $("#header_det_committee").text(header);

    $("#sub_header_det").css("display", "none");
    this.setdateformat();
    this.getUsers();
    let showPriorMemebers = true;
    let showAddMemebers = false;
    if (this.props.userId == this.state.teamData.created_by ||
      this.props.userId == this.state.teamData.team_administrator || 
      this.props.userId == this.state.teamData.team_addnl_administrator ) {
      showAddMemebers = true;
    }
    if (this.props.teamMembersList[1] && this.props.teamMembersList[1].steercom_role_id == "") {
      showPriorMemebers = false;
    }
    if (!this.props.teamMembersList[1]) { 
      $(".rdt_TableBody").css({ visibility: "hidden" });
    }
    addnewmember = false;
    $(document).click(function (event) {
      if (event.target.id == "addnewmember1" || event.target.id == "addnewmember2") {
        addnewmember = true;
      }
    });
    let teamData = this.state.teamData;
    this.getRoles(teamData.template_id);
    this.setState({
      showPriorMemebers,
      showAddMember: showAddMemebers
    });
  }
  componentWillUnmount() {
    $("#root").css("height", "100%");
    $("#root").css("overflow", "auto"); 
    $("#priorTeamMembers").hide();
  }
  componentDidUpdate() {
    let viewmode = false, currstatus = this.state.addTeamMember;
    let showhide = this.state.showAddMember;
    $(".rdt_TableBody").css({ height: $("#root").height() - 304 + "px" });
    if (!this.props.teamMembersList[1] && this.state.isNewRecord == true) {
      $(".rdt_TableBody").css({ visibility: "hidden" });
    } else {
      $(".rdt_TableBody").css({ visibility: "visible" });
    }
    $(".rdt_TableBody").css({ marginTop: "36px" });
    if (this.state.isPriorTeam) {
      $("#header_det_committee").html(this.props.labels.labels.LBLPRIORTEAMMEMBERSLIST)
    }
    if (showhide) {
      if (this.props.userId == this.state.teamData.created_by ||
        this.props.userId == this.state.teamData.team_administrator || 
        this.props.userId == this.state.teamData.team_addnl_administrator ) {
        showhide = true;
        viewmode = false;
      } else {
        showhide = false;
        viewmode = true;
      }
    }
    if (showhide) {
      $(".rdt_TableBody").css({ marginTop: "36px" });
      $(".rdt_TableBody").css({ height: $("#root").height() - 304 + "px" });
      $("#addnewmember").html(this.props.labels.labels.LBLADDNEWMEMBER);
      $(".datatable-list").css({ marginTop: "0px" });
    } else {
      $(".rdt_TableBody").css({ marginTop: "0px" });
      $(".rdt_TableBody").css({ height: $("#root").height() - 264 + "px" });
      $("#addnewmember").html("");
      $(".datatable-list").css({ marginTop: "36px" });
    }
    if (viewmode == true)
      $(".datatable-list").css({ marginTop: "0px" });

    $("header").css({ "min-height": "0px" });
    $(".rdt_TableBody").scroll(function () {
      var scrollLeft = $(this).scrollLeft();
      $(".rdt_TableHead").scrollLeft(scrollLeft);
    });
    $("#addnewmember").addClass("cls_add_new_member");
    $($(".datatable-list")[0]).find("header").css({ "min-height": "10px" }); 
  }
  getRoles=(template)=>{
    let {steercomSettings} = this.state;
    let steercom_roles = steercomSettings.roles.filter((item)=>{
      return item.template_id == template;
    });
    this.setState({
      steercom_roles : steercom_roles
    });
  }
  sortResults = (arr, prop, asc) => {
    arr.sort(function (a, b) {
      if (asc) {
        return (a[prop].toLowerCase() > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
      } else {
        return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
      }
    });
  } 
  openConfirmDialogDel=(item, status)=>{
    let {updateStatus, teamMembersList} = this.state;
    let delstatus = teamMembersList.filter((i)=>i.isNewAssigned == 1 || this.state.addTeamMember == true);
    if(delstatus.length>0) return false; 
    this.setState({
      opendialog: true, 
      delitem:item,
    })
  }
  handleCloseDialogDel = (val) => {
    let { delitem, updateStatus, i } = this.state;
    if (val.currentTarget.id == 2) {
      this.deleteTeamMember(delitem);
    }
    this.setState({
      opendialog: false,
      delitem:null
    })
  }
  deleteTeamMember = async(item) => {
    if (item.team_member_id) {
      $("#addnewmember").html(this.props.labels.labels.LBLADDNEWMEMBER);
      let editingMembers = this.state.editingMembers;
      let isNewRecord, teamMembersLists = this.state.teamMembersList;
      if (!teamMembersLists[1]) {
        $(".rdt_TableBody").css({ visibility: "hidden" });
        isNewRecord = true;
      } else {
        isNewRecord = false;
      }
      editingMembers["M_NEW"]["memberSteercomRoleId"] = "";
      editingMembers["M_NEW"]["memberUserId"] = "";
      let tmpTeamMemberData = {
        team_id : item.team_id,
        team_member_id:item.team_member_id
      }
      let LBLWARNMEMBERDEL, result = await SteercomServices.deleteTeamMember(tmpTeamMemberData);
      if(result?.message =="LBLWARNMEMBERDEL"){
        LBLWARNMEMBERDEL = this.props.labels.labels.LBLWARNMEMBERDEL;
        LBLWARNMEMBERDEL=LBLWARNMEMBERDEL?LBLWARNMEMBERDEL:"A team member assigned to any task cannot be deleted";
        this.showAlert("warning", LBLWARNMEMBERDEL); 
      }else if(result?.message == "LBLDELETESUCCESS"){ 
        this.showAlert("success", this.props.labels.labels.LBLDELETESUCCESS); 
        this.editTeamMembersListCallback(item.team_id); 
      }
      this.setState({
        addTeamMember: false,
        showAddMember: true,
        editingMembers,
        isNewRecord: isNewRecord,
        isEditRole:false
      }); 
    }  
  };
  resetTeamMember = (item, items) => {
    if (isNaN(item.team_member_id) == true) {
      $("#addnewmember").html(this.props.labels.labels.LBLADDNEWMEMBER);
      let editingMembers = this.state.editingMembers;
      let isNewRecord, teamMembersLists = this.state.teamMembersList;
      if (!teamMembersLists[1]) {
        $(".rdt_TableBody").css({ visibility: "hidden" });
        isNewRecord = true;
      } else {
        isNewRecord = false;
      }
      editingMembers["M_NEW"]["memberSteercomRoleId"] = "";
      editingMembers["M_NEW"]["memberUserId"] = "";
      this.setState({
        addTeamMember: false,
        showAddMember: true,
        editingMembers,
        isNewRecord: isNewRecord,
        isEditRole:false
      });
    } else {
      $("#addnewmember").html(this.props.labels.labels.LBLADDNEWMEMBER);

      if (
        this.state.editingMembers.hasOwnProperty("M_" + item.team_member_id) ==
        true
      ) {
        let teamMembersList = this.state.teamMembersList;
        let editingMembers = this.state.editingMembers;
        teamMembersList.map((xitem, index) => {
          if ((xitem.isNewAssigned || 0) == 1) {
            xitem.isNewAssigned = 0;
            delete editingMembers["M_" + xitem.team_member_id];
          }
        });

        this.setState({
          teamMembersList,
          editingMembers,
          showAddMember: true
        });
      }

    }
  };
  saveTeamMember = (item) => {
    let tmpdata = this.state.editingMembers["M_" + item.team_member_id];
    if (
      this.state.editingMembers.hasOwnProperty("M_" + item.team_member_id) ==
      false
    ) {
      return false;
    }
    var tmpTeamMemberData = {
      team_id: this.state.teamData.team_id,
      team_member_id: tmpdata.memberId,
      user_id: tmpdata.memberUserId,
      steercom_role_id: tmpdata.memberSteercomRoleId,
      service_duration: tmpdata.memberDuration,
      member_startdate: tmpdata.memberStartDate,
      member_enddate: tmpdata.memberEndDate,
      status: tmpdata.memberStatus, //values.active,
      old_user_id: tmpdata.oldMumberUserId,
    }; 
    if (this.checkValidations(tmpTeamMemberData)) {
      let checkRoles, memberData, teamMembersList = this.state.teamMembersList; 
      let teamid = this.state.teamData.team_id;
      let calltype = tmpdata.memberId == "NEW" ? "post" : "put";
        memberData = teamMembersList.filter(item=>item.steercom_role_id == tmpdata.memberSteercomRoleId && item.team_member_id != tmpdata.memberId) ;
        if(memberData.length>0){
          //checkRoles=true;
          tmpTeamMemberData.checkRoles=true;
        }
      this.saveTeamMemberCallback(teamid, tmpTeamMemberData, calltype);
    }

  };
  saveTeamMemberCallback = async (teamid, tmpTeamMemberData, calltype) => {
    let res;
    res = await SteercomServices.saveTeamMember(teamid, tmpTeamMemberData, calltype); 
    if (res.status == "SUCCESS") {
      this.editTeamMembersList(this.state.teamData.team_id);
      this.showAlert("success", this.props.labels.labels.LBLMEMBERSUCCESS);
    }else if(res.error.result1[0] && res.error.result1[0][0] && res.error.result1[0][0].team_member_id){
      this.showAlert("warning", this.props.labels.labels.LBLTEAMMEMBEREXISTS);
    }else if(res.error.result1[1] && res.error.result1[1][0] && res.error.result1[1][0].team_member_id){
      this.showAlert("warning", this.props.labels.labels.LBLTEAMROLEEXISTS); 
    }
  };
  editTeamMembersList = (team_id) => {
    this.editTeamMembersListCallback(team_id);
  };
  editTeamMembersListCallback = async (team_id) => {
    let res = await SteercomServices.getTeamMembersList(team_id); 
    let showAddMember = true;
    $("#priorTeamMembers").show();
    $("#addnewmember").html(this.props.labels.labels.LBLADDNEWMEMBER);
    let teamData = res.result[0][0];
    let teamMembersList = res.result[1];
    let isNewRecord;
    showAddMember = teamMembersList.length > 0 ? true : false;
    if (!teamMembersList[0]) {   //&& this.state.isNewRecord==true ){ 
      isNewRecord = true;
      showAddMember = true;
    } else {
      isNewRecord = false;
    }
    teamMembersList.unshift({
      team_id: teamData.team_id,
      team_member_id: "NEW",
      user_id: "",
      steercom_role_id: "",
      service_duration: "24",
      member_startdate: teamData.start_date,
      member_enddate: teamData.start_date,
      status: 1, //values.active,
      customDuration: teamData.service_duration == 99 ? false : true
    });
    //}

    let editingMembers = {
      M_NEW: {
        teamId: teamData.team_id,
        memberId: "NEW",
        memberUserId: "",
        memberSteercomRoleId: "",
        memberDuration: "18",
        memberStartDate: new Date(new Date(this.props.teamData.start_date).setMonth(new Date(this.props.teamData.start_date).getMonth() + 0)),
        memberEndDate: new Date(new Date(this.props.teamData.start_date).setMonth(new Date(this.props.teamData.start_date).getMonth() + 18)),
        memberStatus: 1,
        memberOpenState: false,
        roleOpenState: false,
        serviceDurationOpenState: false,
        oldMumberUserId: "",
        customDuration: true
      },
    };
    this.setState({
      teamData,
      teamMembersList,
      editingMembers,
      addTeamMember: false,
      showAddMember: showAddMember,
      // isNewRecord:false,
      showPriorMemebers: true,
      isNewRecord: isNewRecord
    });
  };

  getPriorTeamMembersList = (team_id) => {
    $("#priorTeamMembers").hide();
    $(".cls_legends_tsp").css({ "visibility": "hidden" });
    let teamId = this.props.teamData.team_id;
    var header = this.props.labels.labels.LBLPRIORSTEERCOMDET;
    this.props.setPriorValaues(true);
    $("#header_det").text(header);
    this.getPriorTeamMembersListCallback(teamId);
  };
  getPriorTeamMembersListCallback = async (teamId) => {
    let res = await SteercomServices.getPriorTeamMembersList(teamId); 
    let teamData = res.result[0][0];
    let teamMembersList = res.result[1];
    teamMembersList.push({
      team_id: teamData.team_id,
      team_member_id: "NEW",
      user_id: "",
      steercom_role_id: "",
      service_duration: "18",
      steercom_role_name: "",
      member_startdate: teamData.start_date,
      member_enddate: teamData.start_date,
      status: 1, //values.active,
      customDuration: true
    });
    let editingMembers = {
      teamId: teamData.team_id,
      memberId: "NEW",
      memberUserId: "",
      memberSteercomRoleId: "",
      memberSteercomRoleName: "",
      memberDuration: "18",
      memberStartDate: teamData.start_date,
      memberEndDate: teamData.start_date,
      memberStatus: 1,
      memberOpenState: false,
      roleOpenState: false,
      serviceDurationOpenState: false,
      oldMumberUserId: "",
      customDuration: true
    };
    $("#header_det_committee").html(this.props.labels.labels.LBLPRIORTEAMMEMBERSLIST);
    this.setState({
      teamData,
      teamMembersList,
      editingMembers,
      addTeamMember: false,
      isPriorTeam: true,
      showPriorMemebers: false,
      showAddMember: false
    });
  };
  showAlert = (messageType, message, showtime) => {
    let showtimes = showtime ? showtime : this.state.showtime;
    this.setState({
      openalert: true,
      messageType: messageType,
      message: message,
      showtime: showtimes
    });
  };
  checkValidations = (tmpTeamDate) => {
    let checkval = true, steercom_role_name = $("#txt_member_role").val(); 
    const {
      teamAdmin,
      teamStartDate,
      selectGroup1,
      selectGroup2,
      selectGroup3,
      teamMeetingFrequency,
      teamIsBudgetAllocated,
      teamBudgetYear,
    } = this.state;
    if (tmpTeamDate.user_id == "") {
      checkval = false;
      let required = this.props.labels.labels.LBLREQUIRED;
      //$("#span_user_id_" + tmpTeamDate.team_member_id).show();
      //$("#span_user_id_" + tmpTeamDate.team_member_id).text("*");
      $("#members_" + tmpTeamDate.team_member_id).addClass("cls_error");
      onmember = true;
    } else {
      let exist = 0;
      let tMList = this.state.teamMembersList;
      for (let i = 0; i < this.state.teamMembersList.length; i++) {
        if (
          tMList.team_member_id != tmpTeamDate.team_member_id &&
          tMList.user_id == tmpTeamDate.user_id
        ) {
          exist = 1;
        }
      }
      if (exist == 1) {
        checkval = false;
        onmember = true;
        let lengthval = this.props.labels.labels.LBLMAXCHARS;
        $("#span_user_id_" + tmpTeamDate.team_member_id).show();
        $("#span_user_id_" + tmpTeamDate.team_member_id).text(lengthval);
      } else {
        $("#span_user_id_" + tmpTeamDate.team_member_id).hide();
      }
    } 
    tmpTeamDate.steercom_role_name = steercom_role_name;
    if(steercom_role_name.length>100){
      let warnlabel = this.props.labels.labels.LBLROLENAMEEXCEED;
      warnlabel  = warnlabel?warnlabel: "Role name should not exceed the 100 characters";
      this.showAlert("warning", warnlabel, 3000);
      checkval = false;
    }
    tmpTeamDate.steercom_role_id = "DEFAULT";
    if (
      tmpTeamDate.member_startdate == "" ||
      tmpTeamDate.member_startdate == null ||
      tmpTeamDate.member_startdate == "Invalid Date"
    ) {
      checkval = false;
      if (teamStartDate == "Invalid Date") {
        $("#span_member_startdate_" + tmpTeamDate.team_member_id).hide();
      } else {
        $("#span_member_startdate_" + tmpTeamDate.team_member_id).show();
      }
    } else {
      $("#span_member_startdate_" + tmpTeamDate.team_member_id).hide();
    }
    if (
      tmpTeamDate.member_enddate == "" ||
      tmpTeamDate.member_enddate == null ||
      tmpTeamDate.member_enddate == "Invalid Date"
    ) {
      checkval = false;
      $("#span_member_enddate_" + tmpTeamDate.team_member_id).show();
    } else {
      $("#span_member_enddate_" + tmpTeamDate.team_member_id).hide();
    }
    if (new Date(tmpTeamDate.member_startdate).getTime() > new Date(tmpTeamDate.member_enddate || new Date()).getTime()) {
      let warnlabel = this.props.labels.labels.LBLGRTSTARTDATE;
      this.showAlert("warning", warnlabel, 3000);
      checkval = false;
    }

    return checkval;
  };
  updateEditFlag = (item, status, mode) => {
    let isEditRole= (status==0)?true:false, addTeamMember = this.state.addTeamMember;
    if (addTeamMember) {
      this.showAlert("warning", this.props.labels.labels.LBLUNSAVEDATA1, 10000);
      return false;
    }
    let teamMembersList = this.state.teamMembersList;
    let editingMembers = this.state.editingMembers;
    for (var i = 0; i < teamMembersList.length; i++) {
      //alert(status + "--" + item.team_member_id + "--" + teamMembersList[i].team_member_id + "--" + teamMembersList[i].isNewAssigned || 0)
      if (item.team_member_id == teamMembersList[i].team_member_id) {
        teamMembersList[i].isNewAssigned = status ? 0 : 1;
        //alert(teamMembersList[i].isNewAssigned)
        if ((teamMembersList[i].isNewAssigned || 0) == 1) {
          editingMembers["M_" + teamMembersList[i].team_member_id] = {
            teamId: this.state.teamData.team_id,
            memberId: teamMembersList[i].team_member_id,
            memberUserId: teamMembersList[i].user_id,
            memberSteercomRoleName: teamMembersList[i].steercom_role_name,
            memberSteercomRoleId: teamMembersList[i].steercom_role_id,
            memberDuration: teamMembersList[i].service_duration,
            memberStartDate: teamMembersList[i].member_startdate,
            memberEndDate: teamMembersList[i].member_enddate,
            memberStatus: teamMembersList[i].status,
            memberOpenState: false,
            roleOpenState: false,
            serviceDurationOpenState: false,
            oldMumberUserId: teamMembersList[i].user_id,
            customDuration: teamMembersList[i].service_duration == 99 ? false : true
          };
          $("#txt_member_role").val(teamMembersList[i].steercom_role_name);
        } else {
          delete editingMembers["M_" + teamMembersList[i].team_member_id];
        }
      } else if (status == 0 && (teamMembersList[i].isNewAssigned || 0) == 1) {
        if (mode == 1) {
          teamMembersList[i].isNewAssigned = 0;
          delete editingMembers["M_" + teamMembersList[i].team_member_id];
        } else {
          this.openConfirmDialog(teamMembersList, editingMembers, i);
          teamMembersList[i].isNewAssigned = 0;
          delete editingMembers["M_" + teamMembersList[i].team_member_id];
        }
      }
    }
    
    this.setState((state, props) => ({
      teamMembersList: teamMembersList,
      editingMembers: editingMembers,
      isEditRole:isEditRole
    }));
  };

  handleDateChange = (xVar, item, mode, date) => {
    let startdate,
      enddate,
      editingMembers = this.state.editingMembers;
    let locDuration = editingMembers["M_" + item.team_member_id].customDuration;
    if (mode == 1) {
      if (locDuration == false) {
        enddate = editingMembers["M_" + item.team_member_id]["memberEndDate"];
        if (date > enddate) {
          this.showAlert("warning", this.props.labels.labels.LBLGRTSTARTDATE);
          return false;
        } else {
          editingMembers["M_" + item.team_member_id][xVar] = date;
        }
      } else {
        enddate = addMonths(date, editingMembers["M_" + item.team_member_id].memberDuration);
        editingMembers["M_" + item.team_member_id][xVar] = date;
        editingMembers["M_" + item.team_member_id]["memberEndDate"] = enddate;
      }
    } else if (mode == 3) {
      if (editingMembers["M_" + item.team_member_id].memberDuration != "99")
        enddate = addMonths(date, editingMembers["M_" + item.team_member_id].memberDuration);
      else
        enddate = editingMembers["M_" + item.team_member_id]["memberEndDate"]
      editingMembers["M_" + item.team_member_id][xVar] = date;
      editingMembers["M_" + item.team_member_id]["memberEndDate"] = enddate;
    } else if (mode == 4) {
      editingMembers["M_" + item.team_member_id][xVar] = date;
    } else {
      editingMembers["M_" + item.team_member_id]["memberEndDate"] = date;
    }

    this.setState({
      editingMembers,
    });
  };
  handleRoleNameFocus = (row, event) => {
    let editingMembers = this.state.editingMembers;
    if (editingMembers["M_" + row.team_member_id]["auto_focus"] != "member_role_name") {
        editingMembers["M_" + row.team_member_id]["auto_focus"] = "member_role_name";
      this.setState({
        editingMembers: editingMembers,
      });
    }
  }
  handleChange = (xVar, item, id, event, values) => {
    let editingMembers = this.state.editingMembers;
    if(event.target.id=="txt_member_role"){
      editingMembers["M_" + item.team_member_id][xVar] = event.target.value; 
      editingMembers["M_" + item.team_member_id]["focus_position"] = event.target.selectionStart;
    }else{
      editingMembers["M_" + item.team_member_id][xVar] = values[id]; 
    }
     
    this.setState({
      editingMembers, 
    });
  };
  onfocus2 = (item, event) => {
    let editingMembers = this.state.editingMembers;
 
    event.target.selectionStart = event.target.selectionEnd =
    editingMembers["M_" + item.team_member_id]["focus_position"] || event.target.value.length;
      
  };
  handleSDChange = (xVar, item, id, event, values) => {
    let selServDuration, editingMembers = this.state.editingMembers;
    editingMembers["M_" + item.team_member_id][xVar] = values[id];
    selServDuration = values[id];
    let startdate, enddate;//, editingMembers = this.state.editingMembers; 
    if (item.team_member_id) {
      if (selServDuration == 99) {
        editingMembers["M_" + item.team_member_id]["customDuration"] = false; 
      } else {
        editingMembers["M_" + item.team_member_id]["customDuration"] = true;
        enddate = new Date(new Date(editingMembers["M_" + item.team_member_id].memberStartDate).setMonth(new Date(editingMembers["M_" + item.team_member_id].memberStartDate).getMonth() + parseInt(editingMembers["M_" + item.team_member_id].memberDuration)))
        editingMembers["M_" + item.team_member_id]["memberEndDate"] = enddate;
      }
    }
    this.setState({
      editingMembers,
    });
  };
  
  handleOpen = (xVar, item) => {
    let editingMembers = this.state.editingMembers;
    editingMembers["M_" + item.team_member_id][xVar] = true;
    this.setState({
      editingMembers,
    });
  };
  handleClose = (xVar, item) => { 
    let editingMembers = this.state.editingMembers;
    if (item && editingMembers["M_" + item.team_member_id]) {
      editingMembers["M_" + item.team_member_id][xVar] = false;
      this.setState({
        editingMembers,
        openalert: false,
      });
    }
  };
  handleCloseAlert = () => {
    this.setState({ openalert: false });
  };
  openConfirmDialog = (item, status) => {
    let opendialog = false, teamMembersList = this.state.teamMembersList;
    let editingMembers = this.state.editingMembers;
    for (var i = 0; i < teamMembersList.length; i++) {
      if (teamMembersList[i].team_member_id != "NEW" && teamMembersList[i].isNewAssigned == 1 && teamMembersList[i].team_member_id != item.team_member_id) {
        opendialog = true;
      }
    }
    if (!opendialog) {
      this.updateEditFlag(item, status);
    }
    this.setState({
      opendialog: opendialog,
      updateItem: item,
      updateStatus: status,
    })
  }
  handleCloseDialog = (val) => {
    let { updateItem, updateStatus,delitem, i } = this.state;
    if(delitem){
      this.handleCloseDialogDel(val);
      return false;
    }
    if (val.currentTarget.id == 2) {
      this.updateEditFlag(updateItem, updateStatus);
    }
    this.setState({
      opendialog: false,
      delitem:null
    })
  }
  openConfirmDialog1 = (item, status) => {
    let opendialog = false, teamMembersList = this.state.teamMembersList;
    let editingMembers = this.state.editingMembers;
    for (var i = 0; i < teamMembersList.length; i++) {
      if (teamMembersList[i].team_member_id != "NEW" && teamMembersList[i].isNewAssigned == 1 && teamMembersList[i].team_member_id != item.team_member_id) {
        opendialog = true;
      }
    }
    if (!opendialog) {
      this.addTeamMember();
    }
    this.setState({
      opendialog1: opendialog
    })
  }
  handleCloseDialog1 = (val) => {
    let { updateItem, updateStatus } = this.state;
    let opendialog = false, teamMembersList = this.state.teamMembersList;
    let item = this.state.editingMembers;
    let status = item.isNewAssigned ? 1 : 0
    for (var i = 0; i < teamMembersList.length; i++) {
      if (teamMembersList[i].team_member_id != "NEW" && teamMembersList[i].isNewAssigned == 1 && teamMembersList[i].team_member_id != item.team_member_id) {
        opendialog = true;
      }
    }

    if (val.currentTarget.id == 2) {
      //this.updateEditFlag(updateItem,updateStatus);
      let locItem = item[Object.keys(item)[1]];
      this.updateEditFlag(locItem, status, 1);
      this.addTeamMember();
    }
    this.setState({
      opendialog1: false
    })
  }
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
      loading: true,
    });
    return dateformat;
  };
  addTeamMember = () => {
    $(".rdt_TableBody").css({ height: $("#root").height() - 264 + "px" });
    $(".rdt_TableBody").css({ marginTop: "0px !important" });
    $("#addnewmember").html("");
    $(".rdt_TableBody").css({ visibility: "visible" });

    this.setState({
      addTeamMember: true,
      isNewRecord: false,
      showAddMember: false,
      opendialog1: false,
      isEditRole:true,
    });
  };
  loadMemberList = () => {
    let team_id = this.props.teamData.team_id;
    var header = this.props.labels.labels.LBLSTEERCOMMEMDET;
    $("#header_det").text(header);
    $(".cls_legends_tsp").css({ "visibility": "visible" });
    $("#header_det_committee").html(this.props.labels.labels.LBLTEAMMEMBERSLIST);
    this.editTeamMembersList(team_id);
    this.props.setPriorValaues(false);
    this.setState({
      isPriorTeam: false,
    });
  };
  subtractMonths = (date, item, mode, currval) => {
    let startdate = date; //item.memberStartDate;
    let service_duration = item.memberDuration;
    var ret_date,
      dt = new Date(startdate);
    if (mode == 5) ret_date = new Date(date);
    else ret_date = dt.setMonth(dt.getMonth() - parseInt(service_duration));
    return ret_date;
  };
  getSteerLabel = (roleid) => {
   let rolename, steercom_roles =  this.state.steercom_roles;
   let roleobj= steercom_roles.filter(item=>item.steercom_role_id==roleid);
   roleobj = roleobj?roleobj[0]:[]
   if(roleobj){
    rolename = roleobj.steercom_role_name;
   }
   rolename = rolename?rolename:"";
   return rolename;
  };
  render() {
    let getPlantNames = (xdata) => {
      xdata = JSON.parse(xdata);
      let xnames = []; 
      Object.keys(xdata).map((item, index) => { 
        xdata[item].map((citem, index) => {
          if (citem.name != "") {
            xnames.push(citem.name);
          }
        });
      }); 
      return xnames.join(", ");
    };
    let getRoleName = (xdata) => {
      return this.getLabel("LBL" + xdata);
    };
    let getDurationName = (xdata) => {
      return this.getLabel("LBL" + xdata + "MONTHS");
    };

    let getDateFormat = (xdata, item, mode) => {
      let enddate;
      if (xdata) {
        if (item && mode != 1) {
          enddate = xdata;
          enddate = new Date(enddate).toLocaleDateString("en-US");
          enddate = enddate.split(",")[0]; //enddate.substr(0, 10);
        } else {
          if (xdata.split(",")[0] != "undefined") enddate = xdata.split(",")[0]; //xdata.substr(0, 10);
        }
        // }
        enddate = this.formattingDate(enddate);
        return enddate; //.substr(0, 10)
      } else {
        return false;
      }
    };

    let addMonths = (date, item, mode) => {
      let startdate = item.member_startdate;
      let service_duration = item.service_duration;
      var ret_date,
        dt = new Date(startdate);
      if (mode == 5) ret_date = new Date(date);
      else ret_date = dt.setMonth(dt.getMonth() + service_duration);
      return ret_date;
    };
    let setMaxMonths = (date, item, mode) => {
      let startdate = item.member_startdate;
      let service_duration = 24;//item.service_duration;
      var ret_date,
        dt = new Date(startdate);
      if (mode == 5) ret_date = new Date(date);
      else ret_date = dt.setMonth(dt.getMonth() + service_duration);
      this.setState({
        maxEndDate: ret_date
      })
      return ret_date;
    };
    this.formattingDate = (date) => {
      let dateformat,
        fulldate = "";
      dateformat = this.state.dateformat;
      if (dateformat.toString().indexOf("YYYY") > 0)
        dateformat = this.setdateformat();
      let dateArray = date.split("/");
      if (date) {
        var d = new Date(), month, day, year;
        d = new Date(date);
        day = "" + d.getDate();
        if (d == undefined || isNaN(d.getDate())) {
          d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
        }
        month = "" + (d.getMonth() + 1);
        day = "" + d.getDate();
        year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        if (dateformat == "dd/MM/yyyy") {
          fulldate = [day, month, year].join("/");
        } else if (dateformat == "MM/dd/yyyy") {
          fulldate = [month, day, year].join("/");
        } else if (dateformat == "yyyy/MM/dd") {
          fulldate = [year, month, day].join("/");
        } else {
          fulldate = [day, month, year].join("/");
        }
      }
      return fulldate;
    };

    const {
      LBLTEAMNAME, LBLADDNEWMEMBER, LBLSTEERCOMMEMBER,
      LBLEXPIRED, LBLLEGENDS, LBLUNSAVEDATA, LBLNO, LBLYES, LBLINACTIVEUSER,
      LBLTEAMADMINS, LBLADDNLADMINS,
      LBLTOTMEMBERS, LBLPLANTASSOCIATE,
      LBLPLANT, LBLTEAMSTARTDATE,
      LBLNORECORDS,
      LBLREQUIRED,
      LBLMEMBER,
      LBLROLE,
      LBLSERVICEDURATION,
      LBLMEMBERSTARTDATE,
      LBLMEMBERENDDATE,LBLSAVE,LBLRESET,LBLDELETE,
      LBLACTION
    } = this.props.labels.labels;
    let memberscount = 0;
    const { teamData,isEditRole, teamMembersList, userlist, editingMembers, isPriorTeam,steercom_roles } =
      this.state;
    for (let i = 0; i < teamMembersList.length; i++) {
      if (teamMembersList[i].user_id != "") {
        memberscount++;
      }
    }
    let xx = false;
    const filterTeamMembersListData = teamMembersList.filter((item) => {
      if ((this.state.addTeamMember == true) || teamMembersList.length == 1) {
        xx = true;
        return item;
      } else {
        if (isNaN(item.team_member_id) == false) {
          return item;
        }
      }
    });

    let teamMembersColumns = [
      {
        name: LBLMEMBER,
        selector: "membername",
        sortable: true,
        width: "250px",
        subHeader: true,
        subHeaderAlign: "left",
        subHeaderComponent: ["Add New "],
        compact: true,

        cell: (row, index) => (
          <div
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "4px",
                height: "34px",
                backgroundColor: "#80E3BA",
                border: "0px solid",
                display: "block",
              }}
            ></div>
            {this.props.userId == this.state.teamData.created_by ||
              this.props.userId == this.state.teamData.team_administrator || 
              this.props.userId == this.state.teamData.team_addnl_administrator  ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid",
                    display: "flex",
                  }}
                >
                  {row.team_member_id != "NEW" &&
                    isNaN(row.team_member_id) == false ? (
                    <div
                      style={{ display: "inline-block", paddingLeft: "4px" }}
                    >
                      <label
                        class={
                          row.isNewAssigned
                            ? "check-lbl"
                            : "check-lbl check-newassigned"
                        }
                      >
                        <input
                          type="checkbox"
                          name={"chkg_" + row.id}
                          id={"chkg_" + row.id}
                          data-toggle="modal"
                          checked={row.isNewAssigned}
                          onClick={this.openConfirmDialog.bind(
                            this,
                            row,
                            row.isNewAssigned ? 1 : 0
                          )}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                  {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                    <>
                      <Autocomplete
                        id={"members_" + row.team_member_id}
                        options={userlist}
                        style={{ width: "calc(100%)" }}
                        getOptionLabel={(option) => option.name.toString()}
                        getOptionSelected={(option, values) =>
                          option.user_id === values.user_id
                        }
                        onChange={this.handleChange.bind(
                          this,
                          "memberUserId",
                          row,
                          "user_id"
                        )}
                        disableClearable
                        underlineStyle={{ display: "none" }}
                        defaultValue={
                          editingMembers["M_" + row.team_member_id] &&
                          userlist.find(
                            (v) =>
                              v.user_id ==
                              editingMembers["M_" + row.team_member_id]
                                .memberUserId
                          )
                        }
                        renderInput={(params) => (
                          <TextField id="memberlist"   {...params} />
                        )}
                        renderOption={(option) => (
                          <span style={{color:option.active==0?"#FF5959":"#5F5E5E"}}> 
                              {option.name}
                           </span>
                        )} 
                        underline="none"
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        display: "inline-block",
                        position: "absolute",
                        top: "9px",
                        left: "40px",
                      }}
                    >
                      <div style={{ color: row.user_status == 0 ? "#fb5a5a" : "#5f5e5e" }}>
                        {row.membername.length < 16
                          ? row.membername
                          : row.membername.substring(0, 20) + "..."}
                        <img
                          src="../images/steercom/admin.png"
                          style={{
                            visibility:
                              teamData.adminname == row.membername
                                ? "visible"
                                : "hidden",
                          }}
                          title="Admin"
                          className="img_team_admin1"
                          alt=""
                        ></img>{" "}
                      </div>
                    </div>
                  )}
                </div>
                <span
                  className="required"
                  id={"span_user_id_" + row.team_member_id}
                >
                  {LBLREQUIRED}
                </span>
              </>
            ) : (
              <>
                <div style={{ display: "inline-block" }}>
                  <div style={{ marginLeft: "5px",color:row.user_status == 0 ? "#fb5a5a" : "#5f5e5e" }}>
                    {(row.membername || "").length < 16
                      ? row.membername
                      : row.membername.substring(0, 16) + "..."}
                    <img
                      src="../images/steercom/admin.png"
                      style={{
                        visibility:
                          teamData.adminname == row.membername
                            ? "visible"
                            : "hidden",
                      }}
                      title="Admin"
                      className="img_team_admin"
                      alt=""
                    ></img>{" "}
                  </div>
                </div>
              </>
            )}
          </div>
        ),
      },
      {
        name: LBLROLE, 
        selector: "steercom_role_id",
        sortable: true,
        width: "220px",
        compact: true,
        cell: (row) => { 
          return (
            <div style={{ width: "100%", border: "0px solid" }}> 
              {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                <input id="txt_member_role" className="cls_role_name" 
                value={  isEditRole?
                editingMembers["M_" + row.team_member_id] && editingMembers["M_" + row.team_member_id].memberSteercomRoleName :
                row.steercom_role_name}  
                onClick={this.handleRoleNameFocus.bind(this, row)}
                onChange={this.handleChange.bind(
                      this, "memberSteercomRoleName",row,"steercom_role_name" 
                )}
                autoFocus={
                  editingMembers["M_" + row.team_member_id] && editingMembers["M_" + row.team_member_id][
                  "auto_focus"
                ] == "member_role_name"
                  ? true
                  : false
              }
              onFocus={(e) => this.onfocus2(row, e)}
                     />
              ) : (
                <div className="cls_ellipsis" title={row.steercom_role_name}> {row.steercom_role_name}</div>
              )}
            </div>
          );
        },
      },
      {
        name: LBLSERVICEDURATION,
        selector: "service_duration",
        //  sortable: true,
        width: "140px",
        compact: true,
        center: true,
        cell: (row) => (
          <div
            style={{ width: "100%", border: "0px solid", textAlign: "center" }}
          >
            {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
              <>
                <Autocomplete
                  id="members"
                  style={{ width: "calc(100%)" }}
                  disableClearable
                  options={this.state.steercomSettings.service_duration}
                  getOptionLabel={(option) => option.name.toString()}
                  getOptionSelected={(option, values) =>
                    option.id === values.id
                  }
                  onChange={this.handleSDChange.bind(
                    this,
                    "memberDuration",
                    row,
                    "id"
                  )}
                  underlineStyle={{ display: "none" }}
                  defaultValue={
                    editingMembers["M_" + row.team_member_id] &&
                    this.state.steercomSettings.service_duration.find(
                      (v) =>
                        v.id ==
                        editingMembers["M_" + row.team_member_id].memberDuration
                    )
                  }
                  renderInput={(params) => (
                    <TextField id="memberlist" {...params} />
                  )}
                  underline="none"
                />
              </>
            ) : (
              <>{getDurationName(row.service_duration)}</>
            )}
          </div>
        ),
      },
      {
        name: LBLTEAMSTARTDATE,
        selector: "member_startdate",
        //  sortable: true,
        width: "155px",
        compact: true,
        center: true,
        cell: (row) => (
          <div
            style={{ width: "100%", border: "0px solid", textAlign: "center" }}
          >
            {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      margin="normal"
                      id="startdate"
                      minDate={teamData.start_date}
                      format={this.state.dateformat}
                      value={
                        editingMembers["M_" + row.team_member_id] &&
                        this.state.editingMembers["M_" + row.team_member_id]
                          .memberStartDate
                      }
                      onChange={this.handleDateChange.bind(
                        this,
                        "memberStartDate",
                        row,
                        row.team_member_id == "NEW" ? 1 : 3
                      )}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <span className="required" id="span_startdate">
                    {LBLREQUIRED}
                  </span>
                </MuiPickersUtilsProvider>

              </>
            ) : (
              <>{getDateFormat(row.member_startdate)}</>
            )}
          </div>
        ),  
      },
      {
        name: LBLMEMBERENDDATE,
        selector: "member_enddate",
        //  sortable: true,
        width: "155px",
        compact: true,
        center: true,
        cell: (row) => (
          <div
            style={{ width: "100%", border: "0px solid", textAlign: "center" }}
          >
            {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-around">
                    <KeyboardDatePicker
                      margin="normal"
                      id="startdate"
                      minDateMessage=""
                      maxDateMessage=""
                      minDate={teamData.start_date}
                      format={this.state.dateformat}
                      value={
                        editingMembers["M_" + row.team_member_id] &&
                        this.state.editingMembers["M_" + row.team_member_id]
                          .memberEndDate
                      }
                      onChange={this.handleDateChange.bind(
                        this,
                        "memberEndDate",
                        row,
                        row.team_member_id == "NEW" ? 2 : 4
                      )}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                      disabled={editingMembers["M_" + row.team_member_id] ? editingMembers["M_" + row.team_member_id].customDuration : editingMembers.customDuration}
                    />
                  </Grid>
                  <span className="required" id="span_startdate">
                    {LBLREQUIRED}
                  </span>
                </MuiPickersUtilsProvider>
              </>
            ) : (
              <>
                <div>
                  <img
                    src="../images/steercom/Expired.png"
                    style={{
                      visibility:
                        row.member_enddate && new Date().getTime() >=
                          new Date(row.member_enddate).getTime()
                          ? "visible"
                          : "hidden",
                    }}
                    title={LBLEXPIRED}
                    className="img_team_cross"
                    alt=""
                  ></img>
                  {getDateFormat(row.member_enddate, row)}
                </div>
              </>
            )}
          </div>
        ),
      },
      {
        name: LBLACTION,
        selector: "status",
        //  sortable: true,
        width: "100px",
        compact: true,
        cell: (row) => (
 
          
 
          <div style={{position:"relative", top:"4px",left:"-3px"}}>
            {this.props.userId == this.state.teamData.created_by ||
              this.props.userId == this.state.teamData.team_administrator || 
              this.props.userId == this.state.teamData.team_addnl_administrator ? (
                (row.isNewAssigned === 1 || row.team_member_id == "NEW")?
              <>
                <label className="show-sm">{LBLACTION}</label>
                <div
                  className="sub_container3"
                  style={{ display: "inline-block" }}
                >
                  <img
                    src="../images/steercom/Checkbox.png"
                    onClick={this.saveTeamMember.bind(this, row)}
                    title={LBLSAVE}
                    className="img_team_cross"
                    alt=""
                  ></img>
                </div>
                <div
                  className="sub_container3 cls_team_cross"
                  style={{ display: "inline-block" }}
                >
                  <i
                    class="fas fa-undo"
                    onClick={this.resetTeamMember.bind(this, row)}
                    title={LBLRESET}
                  ></i>
                </div> 
              </> :
               (row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (""):
              <div
                  className="sub_container3 cls_team_delete"
                  style={{ display: row.team_member_id == "NEW" ? "none" : "inline-block"}}
                >
                  <i
                    class="fas fa-trash-alt"
                    onClick={this.openConfirmDialogDel.bind(this, row)}
                    title={LBLDELETE?LBLDELETE:"Delete"}
                  ></i>
                </div>)
            ) : (
              <></>
            )}
          </div> 
        ),
      },
    ];

    let oldTeamMembersColumns = [
      {
        name: LBLMEMBER,
        selector: "membername",
        sortable: true,
        width: "30%",
        compact: true,
        cell: (row) => (
          <div style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                width: "4px",
                height: "34px",
                backgroundColor: "#80E3BA",
                border: "0px solid",
                display: "block",
              }}
            ></div>
            <div style={{ marginLeft: "10px", marginTop: "6px" }}>
              {row.membername && row.membername.length < 30
                ? row.membername
                : row.membername.substring(0, 30) + "..."}{" "}
              <img
                src="../images/steercom/admin.png"
                style={{
                  marginTop: "0px",
                  visibility:
                    teamData.adminname == row.membername
                      ? "visible"
                      : "hidden",
                }}
                title="Admin"
                className="img_team_admin"
                alt=""
              ></img>{" "}
            </div>

          </div>
        ),
      },
      {
        name: LBLSERVICEDURATION,
        selector: "service_duration",
        //sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => (
          <div style={{ width: "100%", border: "0px solid" }}>
            {getDurationName(row.service_duration)}
          </div>
        ),
      },
      {
        name: LBLTEAMSTARTDATE,
        selector: "member_startdate",
        //sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => (
          <div style={{ width: "100%", border: "0px solid" }}>
            {getDateFormat(row.member_startdate)}
          </div>
        ),
      },
      {
        name: LBLMEMBERENDDATE,
        selector: "member_enddate",
        //sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => (
          <div style={{ width: "100%", border: "0px solid" }}>
            <div> {getDateFormat(row.member_enddate, row)}</div>
          </div>
        ),
      },
    ];

    let teamMembersListData = filterTeamMembersListData;
    let horizontal = "top",
      vertical = "center";

    if (isPriorTeam) {

      teamMembersColumns = oldTeamMembersColumns;
      if (
        teamMembersListData[0].steercom_role_id == "" ||
        teamMembersListData[0].steercom_role_id == "undefined"
      ) {
        teamMembersListData = [];
        teamMembersColumns = [];
      }
    }
    if (this.state.showPriorMemebers && this.props.userId == this.state.teamData.created_by ||
      this.props.userId == this.state.teamData.team_administrator || this.props.userId == this.state.teamData.team_addnl_administrator) {
    } 
    return (
      <div>
        <h4>
        </h4>
        <div
          className="team_main_container sc-form"
          style={{ paddingTop: "0px" }}
        >
          <Snackbar
            anchorOrigin={{ horizontal, vertical }}
            key={"top-center"}
            open={this.state.openalert}
            autoHideDuration={this.state.showtime ? this.state.showtime : 2000}
            onClose={this.handleCloseAlert}
            bodyStyle={{ backgroundColor: "teal", color: "coral" }}
          >
            <Alert
              variant="filled"
              onClose={this.handleCloseAlert}
              severity={this.state.messageType}
              style={{ maxWidth: "500px" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <div id="dialog">
            <Dialog className="cls_dialog"
              open={this.state.opendialog}
              onClose={this.handleCloseDialog}
              aria-labelledby="responsive-dialog-title"
              contentStyle={{ width: "100%", minWidth: "500px" }}
            >
              <div style={{ width: "500px", backgroundColor: "#FCEDEA" }}>
                <DialogTitle id="responsive-dialog-title">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "16px" }} className="cls_reset_all">{LBLSTEERCOMMEMBER} </div>
                    <i style={{ margin: "0px 13px", color: "#ff4040", float: "right" }} class="fas fa-exclamation-triangle cls_reset_img"></i>
                  </div>
                </DialogTitle>

                <hr></hr>
                <DialogContent>
                  <DialogContentText style={{ color: "white" }}>
                    <div className="cls_confirm_details" style={{ color: "#313236" }}> {this.state.delitem?"Are you sure want to delete this team member ?":LBLUNSAVEDATA} </div>
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button autoFocus id="1" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog} color="primary">
                    {LBLNO}
                  </Button>
                  <Button id="2" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog} color="primary">
                    {LBLYES}
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </div>
          <div id="dialog1">
            <Dialog className="cls_dialog"
              open={this.state.opendialog1}
              onClose={this.handleCloseDialog1}
              aria-labelledby="responsive-dialog-title"
              contentStyle={{ width: "100%", minWidth: "500px" }}
            >
              <div style={{ width: "500px", backgroundColor: "#FCEDEA" }}>
                <DialogTitle id="responsive-dialog-title">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "16px" }} className="cls_reset_all">{LBLSTEERCOMMEMBER} </div>
                    <i style={{ margin: "0px 13px", color: "#ff4040", float: "right" }} class="fas fa-exclamation-triangle cls_reset_img"></i>
                  </div>
                </DialogTitle>

                <hr></hr>
                <DialogContent>
                  <DialogContentText style={{ color: "white" }}>
                    <div className="cls_confirm_details" style={{ color: "#313236" }}> {LBLUNSAVEDATA} </div>
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button autoFocus id="1" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog1} color="primary">
                    {LBLNO}
                  </Button>
                  <Button id="2" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog1} color="primary">
                    {LBLYES}
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </div>
          {this.state.loading ? (
            <div>
              <div class="row cls_header_container" style={{}}>
                <div className="col-sm-4" style={{ display: "flex" }}>
                  <span className="cls_header_names">
                    {LBLTEAMNAME}
                  </span>
                  :
                  <span
                    title={teamData.team_name}
                    style={{ paddingLeft: "5px" }}
                    className="team-dtl"
                  >
                    {teamData.team_name}
                  </span>
                </div>
                <div className="col-sm-5" style={{ display: "flex" }}>
                  <span className="cls_header_names cls_names1" style={{ position: "relative" }}>
                    <img
                      src="../images/steercom/admin.png"
                      title="Admin"
                      className="img_team_admin"
                      alt=""
                    ></img>{" "}
                    {LBLTEAMADMINS}
                  </span>
                  :
                  <span
                    title={teamData.adminname}
                    style={{ paddingLeft: "5px" }}
                    className="team-dtl"
                  >
                    {teamData.adminname}
                  </span>
                </div>
                <div className="col-sm-3" style={{ display: "flex" }}>
                  <span className="cls_header_names cls_names2">
                    {LBLTOTMEMBERS}
                  </span>
                  :
                  <span
                    title={memberscount}
                    style={{ paddingLeft: "5px" }}
                    className="team-dtl"
                  >
                    {memberscount}
                  </span>
                </div>

                <div className="col-sm-4" style={{ display: "flex" }}>
                  <span className="cls_header_names">
                    {LBLPLANTASSOCIATE}
                  </span>
                  :
                  <span
                    title={getPlantNames(teamData.groups)}
                    style={{ paddingLeft: "5px" }}
                    className="team-dtl"
                  >
                    {getPlantNames(teamData.groups)}
                  </span>
                </div>
                <div className="col-sm-5" style={{display:"flex"}}>
                </div>
                <div className="col-sm-3" style={{ display: "flex" }}>
                  <span className="cls_header_names cls_names2">
                    {LBLTEAMSTARTDATE}
                  </span>
                  :
                  <span
                    title={getDateFormat(teamData.start_date)}
                    style={{ paddingLeft: "5px" }}
                    className="team-dtl"
                  >
                    {getDateFormat(teamData.start_date)}
                  </span>
                </div>
              </div>
              {teamMembersList && teamMembersList.length > 0 ? (
                <div
                  id="legends"
                  class="row"
                  style={{ float: "left", marginTop: "10px", visibility: this.state.teamMembersList.length > 0 && this.state.isPriorTeam == false ? "visible" : "hidden" }} //marginBottom:"-18px"
                >
                  <div className="cls_legends_name">{LBLLEGENDS}</div>
                  <div className="cls_legends_tsp">
                    <img
                      src={"../images/steercom/Expired.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLEXPIRED}</div>
                  </div>
                  <div style={{ display: g_inactive_user ? "flex" : "none" }} className="cls_legends_tsp1">
                    <div className="cls_legend_box"></div>
                    <div class="cls_img_status">{LBLINACTIVEUSER}</div>
                  </div>
                </div>
              ) : ""}

              <div class="sc-forms"
                style={{
                  marginBottom: "0px",
                  width: "calc(100% + 124px)",
                  marginLeft: "-44px",
                  top: "0px",
                  position: "relative"
                }}
              >
                <table></table>
                 {teamMembersList && teamMembersList.length > 0 ? (
                  <div>
                  <DataTable
                    columns={teamMembersColumns}
                    data={teamMembersListData}
                    noDataComponent={<div style={{marginTop: "5%"}}> <EmptyState image={emptyImage} text2={LBLNORECORDS}/></div>}
                    className="datatable-list" 
                    fixedHeader
                    fixedHeaderScrollHeight={$("#root").height() - 240 + "px"}
                    subHeader="true"
                    subHeaderAlign="left"
                    subHeaderComponent={<Tooltip class="cls_add_new_member"
                    title={LBLADDNEWMEMBER} arrow><span
                      style={{ display: this.state.showAddMember ? "" : "none" }}
                      title={LBLADDNEWMEMBER} id="addnewmember"
                      onClick={this.openConfirmDialog1}
                      class="cls_add_new_member"> {LBLADDNEWMEMBER} </span></Tooltip>}
                  /> 
                   {teamMembersList && teamMembersList.length < 2 && 
                   teamMembersList[0].team_member_id!="NEW" && 
                   !this.state.isPriorTeam ? 
                   (<div style={{position:"relative",top:"-315px"}}>{<EmptyState image={emptyImage} text2={LBLNORECORDS}/>}
                      </div>)
                   :("")}  
                  </div>
                ) : (
                  <div style={{marginTop: "5%"}}> { <EmptyState image={emptyImage} text2={LBLNORECORDS}/>}</div>
                )}
              </div>
              {this.state.isPriorTeam ? (
                ""
              ) : (
                <div
                  class="row sc-form"
                >
                </div>
              )}
              <br></br>


            </div>
          ) : (
            ""
          )}
        </div>

      </div>
    )
  }
}
export default TeamMembersList;

import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
const NotFound = (props) => {
    if (props.no_origin) {
        var full = window.location.host;
        var parts = full.split('.');
        var sub = parts[0];
        var domain = parts[1];
        var type = parts[2];
        if(domain.indexOf('accu')> -1) {            
            var newUrl = 'http://' + domain + '.' + type;
            window.location.href = newUrl;
        }
        else {
            return (<div className="main-content" id="main-content">
                <h1>Origin not found</h1>
            </div>);
        }
    } else {
        return (<div className="main-content" id="main-content">
            <h1>Page not found</h1>
            {props.role >= 80 ? <Link to="/schome/dashboard">Go Back to Home  </Link> : <Link to="/home/dashboard">   Go Back to Home  </Link>}
        </div>);
    }
}
const mapStateToProps = (state) => {
    return {
        role: state.user_info_reducer.user_info.roleId
    }
}

export default connect(mapStateToProps, null)(NotFound)


import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { required, minValue8, passwordsMustMatch } from "../admin/pages/validate/Validate";
import { connect } from 'react-redux';
import { Link } from "react-router-dom"

class ResetPasswordForm extends Component {  
    renderField = ({ input, label, type, className, meta: { touched, error, warning } }) => (
        <div>
            <label>{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="required-field">{typeof (error) === "string" ? this.props.labels.labels[error] : this.props.labels.labels[error.label].replace("$$$", error.val)}</span>) ||
                        (warning && <span className="required-field">{warning}</span>))}
            </div>
        </div>
    )
    render() {
        const { handleSubmit, submitting, btnStatus, labels } = this.props
        return (
            <div>
                <form className="form" onSubmit={handleSubmit} >
                    <div className="form-group">
                        <Field name="password" type="password"
                            component={this.renderField} label={labels.labels.LBLNEWPASSWORD} className="input-field"
                            validate={[required, minValue8]}
                        />
                    </div >
                    <div className="form-group">
                        <Field name="cpassword" type="password"
                            component={this.renderField} label={labels.labels.LBLCONFIRMPASSWORD} className="input-field"
                            validate={[required, minValue8, passwordsMustMatch]}
                        />
                    </div >
                    <div className="form-group">
                        {btnStatus && <Link to="/"><button className="btn btn-light"><i class="fas fa-chevron-left"></i>{labels.labels.LBLLOGIN}</button></Link>}

                        <button type="submit" className={"btn btn-primary " + (btnStatus ? 'disabled' : '')} disabled={submitting}>{labels.labels.LBLRESETPASSWORD}</button>
                    </div>
                </form >
            </div>
        )
    }
}

ResetPasswordForm = reduxForm({
    form: 'resetpasswordform' // a unique name for this form
})(ResetPasswordForm);

export default connect()(ResetPasswordForm);
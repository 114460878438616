import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserList from "./UserList";
import IAMutils from '../../../utils/IAMutils'



class Users extends Component {
    constructor(props) {
        super(props);
        //console.log("yyyy", this.props)
        this.state = {
            permission: props.permission || props.location.state ? IAMutils.userPermission(props.permission || props.location.state.permission) : false
        }


    }
    async componentDidMount() {
        // let ururu = await IAMutils.userPermission(this.state.permission);
        // console.log(ururu)
    }
    render() {
        //console.log("fggfdg", this.state, this.props)
        const { ssi_iam_user, allow } = this.props;
        const { permission } = this.state;
        if (!ssi_iam_user) {
            return (<div>
                <UserList {...this.props} permission={true} />
            </div>);
        } else if (ssi_iam_user) {
            if (permission && permission.hasRead && allow) {
                return (<div>
                    <UserList {...this.props} hasRead={permission.hasRead} hasExecute={permission.hasExecute} hasWrite={permission.hasWrite} hasDelete={permission.hasDelete} />
                </div>);
            } else if (permission && !permission.hasRead && allow) {
                return (<div>
                    <h2>You cannot access this service</h2>
                </div>);
            } else if (allow === 0) {
                return (<div>
                    <h2>Policy Not activated</h2>
                </div>);
            } else {
                return (<div>
                    <h2>Loading...</h2>
                </div>);
            }

        }

    }
}
const mapStateToProps = (state) => {
    console.log('state')
    return {
        ssi_iam_user: state.user_info_reducer.user_info.ssi_iam_user
    }
}
export default connect(mapStateToProps, null)(Users)


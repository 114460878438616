import React from "react";
import "./managechannel.scss";
import info from "../../../assets/images/info.png";
import infoaccu from "../../../assets/images/infoaccu.png";
//import channelsLanguageData from "../translations/channels.json";
import { Modal } from "react-bootstrap";
//import UserList from "../channelcomponents/userlist";
//import GroupList from "../channelcomponents/grouplist";
import FeedsServices from "../../../ssifeeds/feedService/feedservices";
import { connect } from "react-redux";
//import ChannelRowItem from "../channelcomponents/channelRowItem";
//import ChannelForm from "../channelcomponents/channelform";
//import GroupServices from "../../../admin/pages/group-list/group_services";
//import Loader from "../../../admin/components/loader/Loader";
//import ProfileImageDisplay from "../../components/profileimage/profileimage";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];


class ManageChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      resultData: [],
      LSresponseData: [],
      selItemforMultiInput: {},
      query: "",
      channelsData: [],
      channelsNewData: [],
      channelUsers: [],
      channelGroups: [],
      groupTypeNames: [],
      channelsLanguageData: this.props.labels.labels,
      userInfo: {},
      language_code: this.props.language_code,
      role_id: this.props.role_id,
      showChannelForm: 0,
      selectedChannelItem: null,
      isChannelLoad: 0,
      isLoadAPI: 0,
      show: false,
      showBtn: false,
      courseSearchValue: "",
      showErrorAssign: false,
      showErrorUnassign: false,
      Sitetype:localStorage.getItem('site_type'),
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
    };
  }

  componentDidMount() {
    this.getChannels();
  }
  componentDidUpdate(prevProps) {
    if(this.props.labels.labels !== prevProps.labels.labels) {
        this.setState({
          channelsLanguageData: this.props.labels.labels
        })
      }
}
  channelListResultHandler = (resultData) => {
    if (resultData.data.length === 0) {
      this.setState({
        channelsData: [],
        channelsNewData: [],
        isChannelLoad: 1,
        load: false,
      });
    } else {
      resultData.data.sort(function (a, b) {
        var nameA = a.channel_name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.channel_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      for (let i = 0; i < resultData.data.length; i++) {
        resultData.data[i]["id"] = String(resultData.data[i]._id);
        resultData.data[i]["value"] = String(resultData.data[i].channel_name);
      }
      this.setState({
        channelsData: [...resultData.data],
        channelsNewData: resultData.data,
        isChannelLoad: 1,
        load: false,
      });
    }
  };

  searchCourse = (e) => {
    this.setState({ courseSearchValue: e.target.value });
  };

  ToastMessageDetails(vStatus, vMessage) {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: vStatus, //'Success',
        message: vMessage, //'The user is created successfully',
        changeInToast: new Date(),
      },
      errorType: null,
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 5000);
  }
 
  groupTableHeader = (filterGroup) => {
    return (
      <>
        {this.state.showToast.show ? (
          <ToastMessage
            showTime={this.state.showToast.showTime}
            show={this.state.showToast["show"]}
            helpers={this.state.showToast}
          ></ToastMessage>
        ) : (
          ""
        )}
        <div className="col-xl-12 col-sm-12 col-xs-12 user-channel">
          <div
            className="col-sm-1 sm-icn"
            style={{ padding: "0px", marginRight: "-3px" }}
          >
            <button
              className="button-style primary-btn"
              style={{ width: "max-content" }}
              onClick={this.setAssign}
            >
              {this.props.labels.labels.LBLASSIGNCHANNELS}
            </button>
          </div>
          <input
            className="input-field"
            style={{
              width: "auto",
              float: "right",
              marginBottom: "5px",
            }}
            placeholder={this.props.labels.labels.LBLSEARCH}
            value={this.state.courseSearchValue}
            onChange={this.searchCourse}
            onKeyDown={this.searchCourse}
            ref="myInput"
          />
          
        </div>
        {filterGroup?.length >0 ?
        <div className={this.state.Sitetype==='SafeStart'?"usertraingDescrip" :"usertraingaccuDescrip"}>
           <img className="trainingimg" src= {this.state.Sitetype==='SafeStart'? info :infoaccu} alt = ""/>
         <div className="userchannelDescriptext">{this.props.labels.labels.LBLCHANNELSAVECOURSE}</div>

          </div> 
 :"" }
        {this.state.assign === true ? (
          <>
            <div className={"row col-sm-12 ssi-modPop"} style={{ display: "flex" }}>
              <Modal
                show={this.state.show}
                onHide={this.setAssign}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                //centered
              >
                <Modal.Header closeButton className='alert-modal-header'>
                  <Modal.Title className="alert-modal-title">
                      {this.props.labels.labels.LBLASSIGNCHANNELS}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{marginTop:"5px"}}>
                  <div
                    className="col-md-12 required-field text-center"
                    style={
                      this.state.showErrorAssign
                        ? { display: "block",fontSize:"14px" }
                        : { display: "none" }
                    }
                  >
                    {this.props.labels.labels.LBLCHANNELNOTASSIGN}
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="pageSubTitle">
                      <h4>{this.props.labels.labels.LBLCHANNELNAME}</h4>
                    </div>
                    <input
                      className="input-field"
                      style={{
                        width: "auto",
                        float: "right",
                        marginBottom: "5px",
                      }}
                      placeholder={this.props.labels.labels.LBLSEARCH}
                      onChange={this.handleInputChange}
                      onKeyDown={this.handleInputChange}
                      ref="myInput"
                      //autofocus
                    />
                  </div>
                  {this.state.LSresponseData.length > 0 ? (
                    <div>
                      <ul
                        class="ssi-listGroup"
                        style={{ width: "100%", overflowX: "auto" }}
                      >
                        {this.state.LSresponseData.sort(valueSort).map(
                          (item) => (
                            <li
                              class="ssi-listGroupItem"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <label
                                className="check-lbl"
                                style={{
                                  marginBottom: "0px",
                                  marginLeft: "0px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  data-toggle="modal"
                                  checked={this.state.selItemforMultiInput.hasOwnProperty(
                                    item.id
                                  )}
                                  onClick={this.onItemCheckStateChange.bind(
                                    this,
                                    item
                                  )}
                                />
                                <span className="checkmark"></span>
                                <span
                                  style={{
                                    display: "block",
                                    paddingTop: "6px",
                                    textAlign: "left",
                                  }}
                                >
                                  {item.value}
                                </span>
                              </label>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  ) : (
                    <div>
                      <ul
                        class="ssi-listGroup"
                        style={{ width: "100%", overflowX: "auto" }}
                      >
                        <li
                          class="ssi-listGroupItem"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <label
                            className="check-lbl"
                            style={{ marginBottom: "0px", marginLeft: "0px" }}
                          >
                            <span
                              style={{
                                display: "block",
                                paddingTop: "6px",
                                textAlign: "left",
                              }}
                            >
                               {<EmptyState image={emptyImage} text2={this.props.labels.labels.LBLNOCHANNELS}/>}
                            </span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  )}
                </Modal.Body>
                {this.state.assign === true &&
                this.state.LSresponseData.length > 0 ? (
                  <Modal.Footer >
                      <button
                        className="button-style secondary-btn"
                        onClick={this.setAssign}
                      >
                        {this.props.labels.labels.LBLCANCEL}
                      </button>
                      <button
                        className="button-style primary-btn"
                        onClick={this.updateChannelAssignment}
                      >
                        {this.props.labels.labels.LBLSAVE}
                      </button>
                  </Modal.Footer>
                ) : (
                  ""
                )}
              </Modal>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  render() {
    const {
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLASSIGNED,
      LBLUNASSIGN,
      LBLSAVE,
      LBLCHANNELSAVECOURSE
    } = this.props.labels.labels;
    let xresData =
      this.state.channelsNewData && this.state.channelsNewData.length
        ? this.state.channelsNewData
        : [];
    let resData = [];
    for (let i = 0; i < xresData.length; i++) {
      if (xresData[i].follow > 0) {
        resData.push(xresData[i]);
      }
    }
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    let groupTableHeader = this.groupTableHeader();
    let groupcolumns = [
      {
        name: this.props.labels.labels.LBLCHANNELNAME,
        selector: "value",
        sortable: true,
        width: "85%",
        compact: true,
        cell: (row) => (
          <div>
            <label className="show-sm">
              {this.props.labels.labels.LBLCHANNELNAME}
            </label>
            {row.value}
          </div>
        ),
      },
      {
        name: LBLASSIGNED,
        selector: "order",
        sortable: true,
        width: "15%",
        headerAlign: "center",
        className: "cssCenter",
        style: { textAlign: "center" },
        compact: true,
        cell: (row) => (
          <div className="w100">
            {/* <label className="show-sm"> </label> */}
            <div className="icoBtn">
              <label className="check-lbl">
                <input
                  type="checkbox"
                  data-toggle="modal"
                  checked={
                    this.state.selItemforMultiInput.hasOwnProperty(row.id)
                      ? false
                      : true
                  }
                  //checked={row.checked}
                  onChange={this.onItemCheckStateChange.bind(this, row)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        ),
      },
    ];
    const filterGroup = resData.filter((item) => {
      if (
        item.value &&
        item.value
          .toLowerCase()
          .includes(this.state.courseSearchValue.toLocaleLowerCase()) //&& item.isAssigned == selectedValue
      ) {
        return item;
      }
    });
    let groupdata = filterGroup;
    if (this.state.load === true) {
      return (
        <div className={"row col-sm-12 ssi-modPop"}>
          <div className="sc-fznxsB fWHyCt datatable-list web-skull">
            <header className="sc-fzoXzr hlidmt">
              <Skeleton height={41} />
            </header>
            <div className="sc-fznWqX gnahTY">
              <div className="sc-AxjAm gIMaKV rdt_Table">
                <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                  <Skeleton height={56} />
                </div>
                <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                  <Skeleton height={480} />
                </div>
              </div>
            </div>
            <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
              <Skeleton height={56} />
            </nav>
          </div>
          <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
            <header className="sc-fzoXzr hlidmt">
              <Skeleton height={34} />
            </header>
            <div className="sc-fznWqX gnahTY">
              <div className="sc-AxjAm gIMaKV rdt_Table">
                <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                  {skel.map((i) => {
                    return (
                      <div
                        id={"row-" + i}
                        className="sc-fzoLsD doBktq rdt_TableRow"
                      >
                        <Skeleton height={90} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
              <div className="sc-fzpmMD ftIMtk">
                <Skeleton height={40} />
              </div>
            </nav>
          </div>
        </div>
      );
    } else {
      let groupdata = filterGroup;
     let groupTableHeader = this.groupTableHeader(filterGroup);
      return (
        <div className="usr-mang-datatable">
          <div
            className="col-md-12 required-field text-center"
            style={
              this.state.showErrorUnassign
                ? { display: "block",fontSize:"14px" }
                : { display: "none" }
            }
          >
            
           {this.props.labels.labels.LBLCHANNELNOTUNASSIGN} 
          </div>
          {(this.state.assign || true) === true ? (
            <>
              <div className={"row col-sm-12 ssi-modPop"}>
                <DataTable
                  id="assignedlist"
                  columns={groupcolumns}
                  data={groupdata}
                  className="datatable-list"
                  // selectableRows
                  pagination
                  subHeader
                  subHeaderComponent={groupTableHeader}
                  noDataComponent={<EmptyState image={emptyImage} text2={this.props.labels.labels.LBLNORECORDS}/>}
                  paginationComponentOptions={paginationComponentOptions}
                />
                <div
                  className="col-xl-12 col-sm-12 col-xs-12"
                  style={{ padding: "0px" }}
                >
                  <div className="">
                    <div className="row img-card-pad"></div>
                    <div
                      className="col-sm-12"
                      style={{
                        display:
                          groupdata.length !== 0 ? "inline-block" : "none",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        type="button"
                        className="button-style primary-btn"
                        style={{
                          display:
                            resData.length !== 0 ? "inline-block" : "none",
                          float: "right",
                        }}
                        onClick={this.unAssign}
                      >
                        {LBLSAVE}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      );
    }
  }

  onItemCheckStateChange = (item) => {
    if (this.state.selItemforMultiInput.hasOwnProperty(item.id)) {
      this.setState({ showErrorUnassign: false, showErrorAssign: false });
      this.dropItemFromMultiInput(item);
    } else {
      this.setState({ showErrorUnassign: false, showErrorAssign: false });
      this.addItemtoMultiInput(item);
    }
  };

  addItemtoMultiInput = (item) => {
    let tmpselItemforMultiInput = this.state.selItemforMultiInput;
    tmpselItemforMultiInput[item.id] = item;
    this.setState({
      selItemforMultiInput: tmpselItemforMultiInput,
    });
  };

  dropItemFromMultiInput = (item) => {
    let tmpselItemforMultiInput = this.state.selItemforMultiInput || {};
    delete tmpselItemforMultiInput[item.id];
    this.setState({
      selItemforMultiInput: tmpselItemforMultiInput,
    });
  };

  submitting = () => {
    if (Object.keys(this.state.selItemforMultiInput).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  setAssign = () => {
    if (this.state.assign === true) {
      this.setState({
        assign: false,
        selItemforMultiInput: {},
        show: false,
        showBtn: true,
        query:""
      });
    } else {
      this.setState({
        assign: true,
        selItemforMultiInput: {},
        show: true,
        showBtn: false,
      });
    }
    this.filterArray();
  };

  handleInputChange = (event) => {
    this.setState(
      {
        query: event.target.value,
      },
      () => {
        this.filterArray();
      }
    );
  };

  handleInputFocus = (event) => {
    this.setState(
      {
        query: event.target.value,
        blur: 0,
      },
      () => {
        this.filterArray();
      }
    );
  };

  handleInputBlur = (event) => {
    this.setState(
      {
        blur: 1,
      },
      () => {
        this.filterArray();
      }
    );
  };

  filterArray = () => {
    let searchString = this.state.query.toLowerCase();
    let responseData =
      this.state.blur === 1
        ? []
        : this.state.channelsNewData.length
        ? this.state.channelsNewData
        : [];
    if (searchString.length > 0 && responseData) {
      const filteredOptions = responseData.filter(
        (x) =>
          x &&
          x.value &&
          x.follow === 0 &&
          x.value.toLowerCase().indexOf(searchString) > -1
      );
      this.setState({
        LSresponseData: filteredOptions,
      });
    } else {
      const filteredOptions = responseData.filter(
        (x) => x && x.value && x.follow === 0 //&& x.value.toLowerCase().indexOf(searchString) > -1
      );
      this.setState({
        LSresponseData: filteredOptions,
      });
    }
  };

  getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  updateChannelAssignment = () => {
    var params = {
      channeldata: [],
    };
    for (let xkey in this.state.selItemforMultiInput) {
      let xitem = this.state.selItemforMultiInput[xkey];
      params.channeldata.push(xitem);
    }
    if (params.channeldata.length === 0) {
      // showErrorselector.classList.remove("d-none");
      // showErrorid.classList.add("d-block");
      this.setState({ showErrorAssign: true });
    } else {
      this.setState({
        load: true,
        assign: false,
        selItemforMultiInput: {},
        resultData: [],
        showErrorAssign: false,
      });
      var apiUrl =
        "users/" + this.props.initialValues.user_id + "/channels/1";
      FeedsServices.feedsAPIServicesCall(
        apiUrl,
        "put",
        this.getChannels,
        params
      );
      this.ToastMessageDetails(
        "Success",
        this.props.labels.labels.LBLUPDTSCCESS
      );
    }
  };

  getChannels = (resultData) => {
    FeedsServices.feedsAPIServicesCall(
      "users/" + this.props.initialValues.user_id + "/channels",
      "get",
      this.channelListResultHandler
    );
  };

  unAssign = () => {
    var params = {
      channeldata: [],
    };
    let xitem = [];
    for (let xkey in this.state.selItemforMultiInput) {
      xitem = this.state.selItemforMultiInput[xkey];
      params.channeldata.push(xitem);
    }
    if (params.channeldata.length === 0) {
      this.setState({ showErrorUnassign: true });
    } else {
      this.setState({
        load: true,
        assign: false,
        selItemforMultiInput: {},
        resultData: [],
        showErrorUnassign: false,
      });
      var apiUrl =
        "users/" + this.props.initialValues.user_id + "/channels/2"; // + params.channeldata._id;
      FeedsServices.feedsAPIServicesCall(
        apiUrl,
        "put",
        this.getChannels,
        params
      );
      this.ToastMessageDetails(
        "Success",
        this.props.labels.labels.LBLUPDTSCCESS
      );
    }
  };
}

const valueSort = (a, b) =>
  (a["value"] > b["value"] && 1) || (a["value"] === b["value"] ? 0 : -1);

const mapStateToProps = (state) => {
  return {
    language_code: state.user_info_reducer.user_info.language_code,
    role_id: state.user_info_reducer.user_info.role_id,
  };
};

export default connect(mapStateToProps, null)(ManageChannels);
//export default ManageChannels

import React from "react";
import "./uploadimage.css";
import { S3MultiUpload } from "../../../shared/s3upload";
//import { Spinner } from "react-bootstrap";
import utils from "../../../utils/utils";
import Loader from "../../../admin/components/loader/Loader";

class UploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachedFiles: [],
      isUploadBtnActive: 1,
      isDeleteBtnActive: 0,
      showLoader: 0,
    };
    this.isFileUploadFinish = 1;
    this.fileNameWithTimeStamp = 0;
    this.fileAttachRef = React.createRef();
    this.fileNameRef = React.createRef();
    this.fileAccptance = ".jpg, .jpeg, .png, .gif";
  }
  componentDidMount() {
    this.fileNameRef.current.value = this.props.uploadedImageName;
    this.isFileUploadFinish = this.props.uploadedImageName ? 1 : 0;
    if (this.fileNameRef.current.value) {
      this.setState({
        isDeleteBtnActive: 1,
      });
    }
  }
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.channelsLanguageData &&
        this.props.channelsLanguageData[paramLBL]
        ? this.props.channelsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  openBrowseWindow = (evt) => {
    this.fileAttachRef.current.click();
  };
  fileAttachOnChangeHandler = (evt) => {
    if (evt && evt.target && evt.target.files && evt.target.files.length) {
      this.setState({ attachedFiles: [] });
      for (let i = 0; i < evt.target.files.length; i++) {
        this.state.attachedFiles.push(evt.target.files[i]);
        this.fileNameWithTimeStamp = utils.generateFeedsPostFileName(
          evt.target.files[i].name
        );
        this.fileNameRef.current.value = this.fileNameWithTimeStamp;
        this.isFileUploadFinish = 0;
        this.props.uploadStatusHandler(
          this.isFileUploadFinish,
          this.fileNameRef.current.value
        );
        this.imageUploadHandler();
      }
      if (this.state.attachedFiles.length) {
        this.setState({
          isUploadBtnActive: 0,
        });
      }
    }
  };
  removeFileNameHandler = (evt) => {
    this.fileNameRef.current.value = "";
    this.isFileUploadFinish = 0;
    this.props.uploadStatusHandler(
      this.isFileUploadFinish,
      this.fileNameRef.current.value
    );
    this.setState({
      attachedFiles: [],
      isUploadBtnActive: 1,
      isDeleteBtnActive: 0,
    });
  };
  imageUploadHandler = () => {
    if (this.state.attachedFiles.length) {
      this.setState({ showLoader: 1 });
      this.state.attachedFiles.map((item) => {
        //this.timeStamp = new Date().getTime();
        this.uploadAssets(item);
      });
    }
  };
  uploadAssets = (data) => {
    let folderPath = this.props.userInfo.currentuser.company_id + "/";
    let s3Upload = new S3MultiUpload(
      data,
      this.fileNameWithTimeStamp,
      "",
      folderPath
    );
    s3Upload.start();
    s3Upload.onUploadCompleted = async (data) => {
      if (data) {
        this.setState({
          showLoader: 0,
          isUploadBtnActive: 0,
          isDeleteBtnActive: 1,
        });
        this.isFileUploadFinish = 1;
        this.props.uploadStatusHandler(
          this.isFileUploadFinish,
          this.fileNameWithTimeStamp
        );
      }
    };
  };
  // addTimeStampToFileName = (fName, timeStamp) => {
  //     let fileName = fName.split(".");
  //     fileName[fileName.length - 2] = fileName[fileName.length - 2] + timeStamp;

  //     return fileName.join(".");
  // }

  render() {
    return (
      <div className="ssi-upload-mainContainer">
        <div className="ssi-upload-mainContainerWrapper">
          <div className="ssi-upload-stripContainer">
            {/* <div className="ssi-upload-browseBtn" onClick={this.openBrowseWindow} title={this.getResourceFromLabels("LBLADDIMAGE")} >
                            <input type="file" ref={this.fileAttachRef} hidden accept={this.fileAccptance} onChange={this.fileAttachOnChangeHandler} />
                            <span className="plusIcon">+</span>
                        </div> */}
            <div className="ssi-upload-fileNameContainer">
              <input
                type="text"
                className="ssi-upload-fileName"
                readOnly
                ref={this.fileNameRef}
                placeholder={this.getResourceFromLabels("LBLADDIMAGE")}
              />
            </div>
            <div
              className={
                this.state.isDeleteBtnActive === 1
                  ? "ssi-upload-deleteContainer"
                  : "ssi-upload-deleteContainer disable"
              }
              onClick={this.removeFileNameHandler}
            >
              <span className="deleteIcon fas fa-trash-alt"></span>
            </div>
            <div className="ssi-upload-spliter">
              <span className="spliter"></span>
            </div>
            <div
              className={
                this.state.isUploadBtnActive === 1
                  ? "ssi-upload-uploadBtnContainer"
                  : "ssi-upload-uploadBtnContainer disable"
              }
              onClick={this.openBrowseWindow}
            >
              <input
                type="file"
                ref={this.fileAttachRef}
                hidden
                accept={this.fileAccptance}
                onChange={this.fileAttachOnChangeHandler}
              />
              <span className="uploadIcon">
                {this.getResourceFromLabels("LBLUPLOAD")}
              </span>
            </div>
          </div>
          {this.state.showLoader === 1 ? (
            <div className="ssi-manch-spinnerAlignCenter">
              <Loader></Loader>
            </div>
          ) : (
              ""
            )}
        </div>
      </div>
    );
  }
}

export default UploadImage;

import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import emptyImage from '../../../assets/images/storyfeeds_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";

class ChannelsDatatable extends Component {
  render() {
    const PropsData = this.props;
    return (
      <Modal show={PropsData.showModal} onHide={PropsData.closeModal} size="md">
        <Modal.Header closeButton className="alert-modal-header">
          <Modal.Title>
            <h4 className="alert-modal-title " style={{ lineHeight: "2.5" }}>
              {PropsData.modalTitle}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={PropsData.datatableColumns}
            data={PropsData.datatableData}
            className="datatable-list"
            subHeader
            subHeaderComponent={PropsData.datatableHeader}
            paginationPerPage={PropsData.paginationPerPage}
            pagination
            noDataComponent={<EmptyState image={emptyImage} text2={PropsData.noDataComponent}/>}
            paginationComponentOptions={PropsData.paginationComponentOptions}
          />
        </Modal.Body>
      </Modal>
    );
  }
}
export default ChannelsDatatable;

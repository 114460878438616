import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScMailServices from "./templateService";
import DataTable from "react-data-table-component";
import { Form } from "react-bootstrap";
import "./templates.scss";
import { Editor } from "@tinymce/tinymce-react";
import Skeleton from "react-loading-skeleton";

const a = "<h1>Test</h1>";
let userId = localStorage.getItem("sc_userid");

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      status: null,
      loading: false,
      disableActive: false,
      show: false,
      searchValue: "",
    };
  }
  componentDidMount() {
    ScMailServices.getTemplatesList().then((templatesRes) => {
      this.setState({
        templates: templatesRes.templates,
        loading: true,
      });
    });
  }
  goToEdit = () => {
    this.props.history.push("/schome/templates");
  };
  openDelModal = (item, e) => {
    this.setState({
      showDel: true,
      userId: item.template_id,
      userName: item.name,
    });
  };
  closeModal = () => {
    this.setState({ show: false });
  };
  openModal = () => {
    this.setState({ show: true });
  };
  changeStatus = (data) => {
    this.setState({
      disableActive: true,
    });
    let queryinfo = {
      templateId: data.template_id,
      templateType: data.template_type,
      active: data.active,
    };
    ScMailServices.changeTemplateStatus(queryinfo).then((statusres) => {
      console.log(statusres);

      ScMailServices.getTemplatesList().then((templatesRes) => {
        this.setState({
          templates: templatesRes.templates,
          loading: true,
          disableActive: false,
        });
      });
    });
  };

  searchUser = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  tableHeader = () => {
    return (
      <div className="table-filter">
        <input
          type="text"
          className="form-control"
          placeholder={this.props.labels.labels.LBLSEARCHBYNAMETYPE}
          value={this.state.searchText}
          onChange={this.searchUser}
        />
      </div>
    );
  };

  render() {
    const {
      LBLTEMPLATENAME,
      LBLTEMPLATEUSE,
      LBLTEMPLATEVIEW,
      LBLSTATUS,
      LBLINACTIVE,
      LBLACTIVE,
      LBLDEFAULT,
      LBLACTION,
      LBLEDIT,
      LBLDELETE,
      LBLCLOSE,
      LBLBODY,
      LBLFROM,
      LBLSUBJECT,
      LBLADDNEWUSER,
      LBLCHANGEPASSWORD,
      LBLUPDATE,
      LBLCANCEL,
      LBLCREATENEW,
      LBLTEMPLATES,
      LBLOF,
      LBLROWSPERPAGE,
      LBLNORECORDS,
      LBLALL,
    } = this.props.labels.labels;

    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };

    const { templates, searchValue } = this.state;

    let filteredUsers = [];
    filteredUsers = templates.filter((item) => {
      if (
        (item.template_name &&
          (item.template_name.includes(searchValue) ||
            item.template_name.toLowerCase().includes(searchValue))) ||
        (item.template_use &&
          (item.template_use.includes(searchValue) ||
            item.template_use.toLowerCase().includes(searchValue)))
      ) {
        return item;
      }
    });

    let columns = [
      {
        name: LBLTEMPLATENAME,
        selector: "template_name",
        sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => {
          return (
            <div>
              <span className="rle-brdr">{row.template_name}</span>
              <span className="show-sm-xs">
                <Form.Check
                  type="switch"
                  disabled={this.state.disableActive || row.is_default === 1}
                  id={row.template_id}
                  label=""
                  checked={row.active}
                  onChange={this.changeStatus.bind(this, row)}
                  icons={false}
                />
              </span>
            </div>
          );
        },
      },
      {
        name: LBLTEMPLATEUSE,
        selector: "template_use",
        sortable: true,
        compact: true,
        width: "20%",
        cell: (row) => {
          return (
            <div>
              <span className="rle-brdr">{row.template_use}</span>
              <span className="show-sm-xs">
                <div className="icoBtn temp-icn">
                  <i
                    class="fas fa-eye hd-brdr"
                    data-toggle="modal"
                    onClick={this.openModal}
                  ></i>
                </div>
              </span>
            </div>
          );
        },
      },
      {
        name: LBLTEMPLATEVIEW,
        selector: "template_view",
        sortable: true,
        compact: true,
        width: "20%",
        cell: (row) => {
          return (
            <div className="vfy-col">
              <div className="icoBtn temp-icn ">
                <i
                  class="fas fa-eye hd-brdr"
                  data-toggle="modal"
                  onClick={this.openModal}
                ></i>
              </div>
            </div>
          );
        },
      },
      {
        name: LBLSTATUS,
        selector: "uactive",
        sortable: true,
        compact: true,
        width: "20%",
        cell: (row) => {
          return (
            <div className="vfy-col">
              <Form.Check
                type="switch"
                disabled={this.state.disableActive || row.is_default === 1}
                id={row.template_id}
                label=""
                checked={row.active}
                onChange={this.changeStatus.bind(this, row)}
                icons={false}
              />
              {row.active === 0 ? LBLINACTIVE : LBLACTIVE}
              {row.is_default === 1 ? <label>{`(${LBLDEFAULT})`}</label> : ""}
            </div>
          );
        },
      },
      {
        name: LBLACTION,
        selector: "actions",
        sortable: true,
        compact: true,
        width: "20%",
        cell: (row) => {
          return (
            <div className="icoBtn">
              <Link to={`/schome/create-templates/${row.template_id}`}>
                {" "}
                <i class="fas fa-edit" alt={LBLEDIT}>
                  {" "}
                </i>{" "}
              </Link>
              <i
                class="fas fa-trash-alt"
                data-toggle="modal"
                onClick={this.openDelModal.bind(this, row)}
                alt={LBLDELETE}
              ></i>
            </div>
          );
        },
      },
    ];

    let data = filteredUsers;
    let tableHeader = this.tableHeader();

    return (
      <div>
        <section className="mob-pad">
          <div className="spage-header-field">
            <h3 className="page-title">{LBLTEMPLATES}</h3>
            <div className="btn-align">
              <Link to="/schome/create-templates">
                <button className="btn btn-primary btn-sm">
                  {LBLCREATENEW}
                </button>
              </Link>
            </div>
          </div>
          <hr className="head-line" />

          {!this.state.loading ? (
            <div className="sc-fznxsB fWHyCt datatable-list">
              <header className="sc-fzqNJr kwRiGp rdt_TableHeader"></header>
              <header className="sc-fzoXzr hlidmt">
                <Skeleton height={34} />
              </header>
              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                    <div className="sc-AxiKw cTEQON rdt_TableHeadRow">
                      <Skeleton height={56} />
                    </div>
                  </div>
                </div>
                <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                  <Skeleton height={480} />
                </div>
              </div>
              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <Skeleton height={56} />
              </nav>
            </div>
          ) : (
            <DataTable
              columns={columns}
              data={data}
              className="datatable-list templates-table"
              pagination
              subHeader
              subHeaderComponent={tableHeader}
              noDataComponent={LBLNORECORDS}
              paginationComponentOptions={paginationComponentOptions}
            />
          )}
          <div className="modal-footer text-center" style={{ display: "none" }}>
            <button
              type="button"
              className="btn cancel"
              id="btnaddCancel"
              data-dismiss="modal"
            >
              {LBLCANCEL}
            </button>
            <input
              type="submit"
              className="btn btn-primary"
              id="submitUser"
              value={LBLUPDATE}
            />
          </div>
        </section>
        <Modal show={this.state.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="modal-title" id="exampleModalLongTitle">
                {LBLCHANGEPASSWORD}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
        </Modal>

        <Modal
          show={this.state.show}
          onHide={this.closeModal}
          size="lg"
          className="tmp-mail-mdl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="modal-title" id="exampleModalLongTitle">
                {LBLADDNEWUSER}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row ">
              <div className="col-xl-12">
                <label>
                  {LBLSUBJECT}
                  <span>noreply@4humanfactores.com</span>
                </label>
              </div>
              <div className="col-xl-12">
                <label>
                  {LBLFROM}
                  <span>noreply@4humanfactores.com</span>
                </label>
              </div>
              <div className="col-xl-12">
                <label>{LBLBODY}</label>
                <Editor
                  initialValue={a}
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  disabled={true}
                />
              </div>
              <div className="col-xl-12">
                <label>
                  {LBLSTATUS}
                  <span>
                    <Form.Check type="switch" label="" />
                  </span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12 text-right">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={this.closeModal}
              >
                {LBLCLOSE}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Templates;

import  {  useEffect,useState } from "react";
import ScAuth from "../auth/auth";
import SSOService from "../services/sso_services";
import { parse } from "query-string";
import common_data from "../services/common_data";
import { useDispatch } from 'react-redux'
import reduceruserInfo from "../redux/action";
import Loader from '../admin/components/loader/Loader';
import SSOLoginError from "../common_components/SSOLoginError/SSOLoginError";

let languages = common_data.getLanguages();

const CbClasslink = (props) => {
  const { location,site_type,labels, history: { push } }=props;
  const code = parse(location.search);
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({});

  useEffect(() => {
    if (!code) return;  
    
    SSOService.getClasslinkClientAccess(code).then((res) => {
     if (res.error) {
        let err_code = res.error;
        const labelValue = labels.labels[err_code];
        setToastConfig({
          show: true,
          alertClass: "",
          status: "",
          message: labelValue || res.error,
          changeInToast: new Date(),
          input: "",
          sso_error: true

        });
        setShowToast(true);
      } else {
        let userData = res.data;
        if (userData && userData.userInfo ) {
          redirectToHomepage(userData);
        }

      }

    });
  }, code);

  const redirectToHomepage = async (userData) => { 
      let userReducerData;
      const grant=['true',true];
      // const  userInfo = await SSOService.ssoAuthDecoder(userData.Tokeninfo);
      const  userInfo = userData.userInfo[0]
      if (userInfo && grant.includes(userData.grant)){
                    userReducerData = {
                      name: userInfo.user_name,
                      user_id: userInfo.user_id,
                      first_name: userInfo.first_name,
                      last_name: userInfo.last_name,
                      role: userInfo.role_name,
                      email: userInfo.email,
                      email_verified: userInfo.email_verified,
                      company_id: userInfo.company_id,
                      role_id: userInfo.role_id,
                      roleIds: userInfo.roleIds,
                      language_code: userInfo.language_code,
                      user_language: languages[userInfo.language_code],
                      company_name: userInfo.company_name,
                      timezone: userInfo.timezone,
                      dateformat: userInfo.dateformat,
                      timeformat: userInfo.timeformat,
                      currency: userInfo.currency,
                      company_logo: userInfo.company_logo,
                      ssi_iam_user: userInfo.iam_user,
                      showcategory_filter: userInfo.showcategory_filter,
                      showunits_certificate: userInfo.showunits_certificate,
                    };


            dispatch(reduceruserInfo(userReducerData));
            localStorage.setItem("ssi_classlink_sso", true);

              ScAuth.login(() => {          
                       const companylist=  ['Accutrain'];
                       const redirectPage = companylist.includes(site_type) ?"/schome/products": "/schome/dashboard";

                      if((userInfo.email_verified == 0 || userInfo.email_verified == null)  && userInfo.role_name == "LBLADMINISTRATOR") {
                          setToastConfig({
                            show: true,
                            alertClass: "",
                            status: "",
                            message: props.labels.labels.LBLNOROLEEMAILVERIFY,
                            changeInToast: new Date(),
                            input: "",
                            sso_admin_verify: true
                          });
                          setShowToast(true);
                      }
                      else if (userInfo.role_id === 1 || userInfo.role_id === 2) {
                        push(redirectPage);
                      } else {
                        push("/home/products");
                      }
            
              });
  }
}
 return (
    <>
      {showToast ? (
        <SSOLoginError
        show={toastConfig.show}
        helpers={toastConfig}
        oklabel={props.labels.labels.LBLOK ? props.labels.labels.LBLOK : "OK"}
      />
      ):Loader}
    </>
  );
}
export default CbClasslink;


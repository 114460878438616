import { Modal } from "react-bootstrap";
import styles from "./ViewModal.module.scss"
const ViewModal = (props) => {
    return(
        <Modal
        show={props.showList}
        onHide={props.closeMoreModal}
        
        >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title">{props.label}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minHeight:"100px", maxHeight: '350px', overflowY: 'auto' }}>
          <div className={styles.selectedItemWrap}>
          <div className={styles.selectedItemInnerWrap}>
                {props.selectedRows !== null && props.selectedRows.reverse().map((item, index) => {
                  return (
                    <span
                      className={styles.selectedItems}
                      key={index}
                      title={item.name}
                    >
                      { item.name }
                    </span>
                  )

                })
                }
          </div>
          </div>
        </Modal.Body>
      </Modal>
    );
}

export default ViewModal;
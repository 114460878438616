import { HttpInterceptor } from '../../auth/HttpInterceptor';

class ScServices {
    async dashboardService() {
        const response = await HttpInterceptor.get('userscount');
        return response.data;
    }
    async dashboardServiceRYS() {
        const response = await HttpInterceptor.get('dashboard_rys');
        return response.data;
    }
    async changeLanguage(data) {
        const response = await HttpInterceptor.put('changelanguage', data);
        return response.data;
    }
    async getUserInfo(data) {
        const response = await HttpInterceptor.get('user');
        return response.data;
    }
    async changeRoles(data) {
        const response = await HttpInterceptor.put('roles', data);
        return response.data;
    }
    async getLineChart(user_id) {
        let data = {
            user_id: user_id
        }
        const response = await HttpInterceptor.post('dashboard_rys_line', data);
        return response.data;
    }
    async getTrainingCompletionList() {
        const response = await HttpInterceptor.get('trainingcompletionlist');
        return response.data;
    }
    async gettUserTrainingStatusList(user_id) {
        const response = await HttpInterceptor.get('usertrainingstatuslist?userid=' + user_id);
        return response.data;
    }
    async dashboardActiveCourseUsers() {
        const response = await HttpInterceptor.get('dashboardactivecourseusers');
        return response.data;
    }
    async dashboardServiceRYSbasedonUser(data) {
        const response = await HttpInterceptor.get('dashboardrysuser?userid=' + data);
        return response.data;
    }
    async dashboardServiceRYSbasedonAllUser(vdata) {
        const response = await HttpInterceptor.post('dashboardrysalluser', vdata);
        return response.data;
    }
    async getLP_DSBD_D1(vdata) {
        const response = await HttpInterceptor.post('lp_dsbd_d1_list', vdata);
        return response.data;
    }
    async getLP_DSBD_D1_USER(vdata) {
        const response = await HttpInterceptor.post('lp_dsbd_d1_user_list', vdata);
        return response.data;
    }
    async getRYS_D1_USER_List(data) {        
        const response = await HttpInterceptor.post('rateyourstate_d1_user_list', data);
        return response.data;
    }
    async toolboxService() {
        const response = await HttpInterceptor.get('toolbox');
        return response.data;
    }
    async toolboxSearch(data) {
        const response = await HttpInterceptor.put('toolbox', data);
        return response.data;
    }
       async getEmailFailureList(data) { 
        const response = await HttpInterceptor.get('emailfailures/'+ data);
        return response.data;
    }
    async getEmailFailureListExport(data) { 
        const response = await HttpInterceptor.post('emailfailuresexport/', data);
        return response.data;
    }
   
}

export default new ScServices();

import React from 'react';
import PropTypes from 'prop-types';
import './toggleButton.scss';

const ToggleButton = ({ type = 'checkbox', name, checked = false, onChange, disabled, label }) => (
    <label className={!disabled ? (checked ? "togglebutton-custom-label checked" : "togglebutton-custom-label") : (checked ? "togglebutton-custom-label checked disabled" : "togglebutton-custom-label disabled")}>
        < input className="custom-control-input" type={type} name={name} checked={checked} onChange={onChange} />
        {label}
        <span className="checkboxmark"></span>
    </label>
);

ToggleButton.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}

export default ToggleButton;
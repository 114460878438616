import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import emptyImage from "../../../assets/images/userlist_emptyst.png";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import FeedsServices from "../../../ssifeeds/feedService/feedservices";
import ChannelServices from "../../../ssifeeds/channelService/channelServices";
import UserManagementHeader from "../../../common_components/datatable/UserManagementHeader";
import ConfrimPopup from "../prod-list/ConfirmPopup";
import { DataGrid } from "@mui/x-data-grid";
import assignedCheckbox from "../../../assets/images/assignedCheckboxImg.png";
import newlyAssignmentCheckbox from "../../../assets/images/newlyAssignedCheckboxImg.png";
import styles from "./userlist.module.scss"
import assignedaccucheckbox from "../../../assets/images/assignedaccucheckbox.png";
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class ChannelMultiAssign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      status: this.props.status,
      resultData: [],
      LSresponseData: [],
      selItemforMultiInput: {},
      query: "",
      show: false,
      showBtn: false,
      channelSearchValue: "",
      showErrorAssign: false,
      showErrorUnassign: false,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      selectedChlArr: [],
      selectedUnassginArr: [],
      disMoreCourses: true,
      showList: false,
      selectedRowsCount: 0,
      channelId: [],
      toggleClearRows: true,
      visibleRowsSelected: false,
      showConfrimPopup: false,
      Sitetype:localStorage.getItem('site_type'),
      pageStatus: {
        // Loading data in the datagrid component
        isLoading: false,
        data: [], // rows
        total: 0, // total count of data grid we need to show
      },
      paginationModel: {
        // pagination variables
        page: 0,
        pageSize: 10,
      },
      rowSelectionModel: [],
      filteredVal: [],
      channelData: [],
      sortModel: [],
    };
  }

  componentDidMount() {
    this.getChannels();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.pageStatus.data !== this.state.pageStatus.data ||
      prevState.rowSelectionModel !== this.state.rowSelectionModel
    ) {
      this.handleVisibleRowsSelection();
    }
  }
  handleVisibleRowsSelection = () => {
    let totalavailableRowsPerpage = 0;
    let totalavailableRows = 0;
    if (this.state.pageStatus.data.length > 0) {
      this.state.pageStatus.data.map((item) => {
        if (item.delflag !== 0 && this.props.channelStatus === 1) {
          totalavailableRowsPerpage++;
        }
        return null;
      });
      if (this.state.resultData.length > 0) {
        this.state.resultData.map((item) => {
          if (item.delflag === 1) {
            totalavailableRows++;
          }
          return null;
        });
      }
      let selectedRowCount = 0;
      this.state.pageStatus.data.forEach((val) => {
        if (this.state.rowSelectionModel.includes(val.channel_id)) {
          selectedRowCount++;
        }
      });

      let totalSelectedRows = selectedRowCount;
      if (totalavailableRowsPerpage === 0) {
        this.setState({ visibleRowsSelected: false });
      } else if (
        totalavailableRowsPerpage === totalSelectedRows &&
        totalavailableRows !== this.state.selectedRowsCount
      ) {
        this.setState({ visibleRowsSelected: true });
      } else {
        this.setState({ visibleRowsSelected: false });
      }
    }
  };
  getChannels = () => {
    const userId =
      this.props.selectedRows &&
      this.props.selectedRows.map((item) => item.user_id);

    var vParams = {
      userid: userId,
      assigned_status: this.props.channelStatus,
    };

    FeedsServices.feedsAPIServicesCall(
      "users/channels/multi",
      "post",

      this.channelListResultHandler,
      vParams
    );
  };


  ToastMessageDetails(vStatus, vMessage) {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: vStatus, //'Success',
        message: vMessage, //'The user is created successfully',
        changeInToast: new Date(),
      },
      errorType: null,
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 5000);
  }

  updateChannelAssignment = () => {
    let postData = { channeldata: [] };
    let message = this.props.channelStatus
      ? this.props.labels.labels.LBLCHANNELSASSIGNEDSUCCESSMSG
      : this.props.labels.labels.LBLCHANNELSUNASSIGNEDSUCCESSMSG;
    const channelId = this.state.rowSelectionModel;
    for (let i = 0; i < this.props.selectedRows.length; i++) {
      let item = {
        user_id: this.props.selectedRows[i].user_id,
        channel_id: channelId,
      };
      postData.channeldata.push(item);
    }
    var apiUrl = `api/user_channel/channel_details/${
      this.props.channelStatus ? "0" : "1"
    }`;

    ChannelServices.channelsAPIServicesCall(
      apiUrl,
      "put",
      this.updateResponse(message),
      postData
    );
    this.ToastMessageDetails("Success", message);
    setTimeout(() => {
      this.props.initialPage();
    }, 2000);
  };
  updateResponse = (message) => {
    this.ToastMessageDetails("Success", message);
    setTimeout(() => {
      this.props.initialPage();
    }, 2000);
  };
  channelListResultHandler = (data) => {
    this.setState({
      resultData: data.data,
      channelData: data.data,
      load: false,
      pageStatus: {
        isLoading: false,
        data: data.data.slice(
          this.state.paginationModel.page * this.state.paginationModel.pageSize,
          (this.state.paginationModel.page + 1) *
            this.state.paginationModel.pageSize
        ),
        total: data.data.length,
        filteredVal: data.data
      },
      filteredVal: data.data
    });
  };

  searchChannel = (e) => {
    this.setState({ channelSearchValue: e.target.value });
  };
  getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };
  handleShowMore = () => {
    this.setState({
      disMoreCourses: !this.state.disMoreCourses,
      showList: true,
    });
  };
  closeModal = () => {
    this.setState({
      showList: false,
      disMoreCourses: !this.state.disMoreCourses,
    });
  };
  handleClear = () => {
    this.setState({
      selectedRowsCount: 0,
      rowSelectionModel: [],
      channelId: [],
    });
  };
  handleSelectAll = () => {
    if (this.props.channelStatus === 1) {
      const channelIds = this.state.filteredVal.map(
        (item) => item.delflag !== 0 && item.channel_id
        );
      this.setState({
        rowSelectionModel: channelIds,
        selectedRowsCount: this.state.filteredVal.filter(
          (item) => item.delflag === 1
        ).length,
      });
    } else {
      const channelIds = this.state.filteredVal.map((item) => item.channel_id);
      this.setState({
        rowSelectionModel: channelIds,
        selectedRowsCount: this.state.filteredVal.length,
        visibleRowsSelected: false,
      });
    }
  };
  tableHeader = () => {
    return (
     <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div>
          <input
            className="input-field"
            style={{
              width: "auto",
              margin: "0px",
              float: "right",
              marginBottom: "5px",
            }}
            placeholder={this.props.labels.labels.LBLSEARCH}
            value={this.state.courseSearchValue}
            onChange={this.searchCourse}
            onKeyDown={this.searchCourse}
            ref="myInput"
          />
        </div>
         <div  className={styles.legends_all_ctn}>
          {this.props.channelStatus === 1 && 
          <div className={styles.legend_ctn}>
          <img src= {assignedCheckbox} alt = "" />
          {this.props.labels.labels.LBLASSIGNED !== undefined ? this.props.labels.labels.LBLASSIGNED : "Assigned"}
          </div>
          }
          <div className={styles.legend_ctn}>
          <img src= {this.state.Sitetype==='SafeStart'?newlyAssignmentCheckbox:assignedaccucheckbox} alt = ""/>
            {this.props.labels.labels.LBLSELECTED !== undefined ? this.props.labels.labels.LBLSELECTED : "Selected"}
          </div>
        </div>
        <UserManagementHeader
          modalTitle={
            this.props.labels.labels.LBLUSERSSELECTED !== undefined
              ? this.props.labels.labels.LBLUSERSSELECTED
              : "Users selected"
          }
          selectedRowsCount={this.props.selectedRowsCount}
          currentPageSelected={this.state.selectedRowsCount}
          currentPageLabel={
            this.props.labels.labels.LBLCHANNELSSELECTED !== undefined
              ? this.props.labels.labels.LBLCHANNELSSELECTED
              : "Channels selected"
          }
          labels={this.props.labels.labels}
          usersSelectedLabel={
            this.props.labels.labels.LBLUSERSSELECTED !== undefined
              ? this.props.labels.labels.LBLUSERSSELECTED
              : "Users selected"
          }
          handleClear={this.handleClear}
          selectedRows={this.props.selectedRows}
          dropObj={null}
          showdropdown={false}
          selectAll={
            this.props.labels.labels.LBLSELECTALLCHANNELS !== undefined
              ? this.props.labels.labels.LBLSELECTALLCHANNELS.replace(
                  "$$$",
                  ""
                )
              : "Select All Channels"
          }
          currentPage="courseAssignment"
          handleRowChange={this.handleSelectAll}
          visibleRowsSelected={this.state.visibleRowsSelected}
          visiblerowsselectedLabel={
            this.props.channelStatus === 1
              ? this.props.labels.LBLELIGIBLECHANNELSELECTED !== undefined
                ? this.props.labels.LBLELIGIBLECHANNELSELECTED
                : `All eligible channels on this page are selected.`
              : this.props.labels.LBLALLCHANNELSELECTED !== undefined
              ? this.props.labels.LBLALLCHANNELSELECTED
              : `All channels on this page are selected.`
          }
        />
      </div>
    );
  };
  handleConfrimPopup = () => {
    if (this.props.channelStatus === 1) {
      this.setState({ showConfrimPopup: true });
    } else {
      this.updateChannelAssignment();
    }
  };
  handleCancelConfrimPopup = () => {
    this.setState({ showConfrimPopup: false });
  };
  handleConfrim = () => {
    this.setState({ showConfrimPopup: false });
    this.updateChannelAssignment();
  };

  handleSortModelChange = (sortModel) => {
    console.log("sortModelstart",sortModel)
    // if (sortModel?.length > 0) {
      this.setState({sortModel:sortModel});
      const field = sortModel[0]?.field ;
      let direction = sortModel[0]?.sort ;
      let sortedData = [...this.state.filteredVal];
       if(direction === undefined) {
        sortedData = [...this.state.filteredVal];
       }
       console.log("sortedData",sortedData)
       
       sortedData.sort((a, b) => {
        const valueA = a[field] === null ? "" : a[field]; // Convert null to empty string
        const valueB = b[field] === null ? "" : b[field]; // Convert null to empty string
        if (direction === 'asc') {
            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB);
            } else {
                return valueA - valueB;
            }
        } else {
            if (typeof valueB === 'string') {
                return valueB.localeCompare(valueA);
            } else {
                return valueB - valueA;
            }
        }
    });
    
      console.log("sortedData",sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)))
      this.setState({ pageStatus : { isLoading: false, data: sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)), total: sortedData?.length },resultData:sortedData });
    // }
    console.log("sortModel end",sortModel[0]?.sort)
  };

  handlepaginationModalChange = (paginationVal) => {
    this.setState({ paginationModel: paginationVal }, () => {
      this.filterChannel();
      this.handleSortModelChange(this.state.sortModel);
    });
  };
  handleSelectionModelChange = (newRowSelectionModel) => {
    const filteredArray = newRowSelectionModel.filter((item) => item !== false);
    this.setState({
      rowSelectionModel: newRowSelectionModel,
      selectedRowsCount: filteredArray.length,
    });
  };
  searchCourse = (e) => {
    this.setState(
      {
        channelSearchValue: e.target.value,
        paginationModel: {
          page: 0,
          pageSize: 10,
        },
      },
      () => {
        this.filterChannel();
      }
    );
  };
  filterChannel = () => {
    const filteredVal = this.state.channelData.filter((item) => {
      if (
        item.channel_name &&
        item.channel_name
          .toLowerCase()
          .includes(this.state.channelSearchValue.toLocaleLowerCase()) //&& item.isAssigned == selectedValue
      ) {
        return item;
      }
      return null;
    });
    this.setState({
      pageStatus: {
        isLoading: false,
        data: filteredVal.slice(
          this.state.paginationModel.page * this.state.paginationModel.pageSize,
          (this.state.paginationModel.page + 1) *
            this.state.paginationModel.pageSize
        ),
        total: filteredVal.length,
      },
      filteredVal:filteredVal,
    });
    return filteredVal;
  };
  rowDisabledCriteria = (row) => {
    if (this.props.channelStatus === 1) {
      if (row.row.delflag === 1) {
        return true;
      }
    } else {
      return true;
    }
  };
  render() {
    const {
      LBLCANCEL,
      LBLROWSPERPAGE,
    } = this.props.labels.labels;

    const columns = [
      {
        headerName: this.props.labels.labels.LBLCHANNELNAME,
        field: "channel_name",
        sortable: true,
        width: "500",
        renderCell: (row) => <div>{row.row.channel_name}</div>,
      },
    ];

    return (
      <div>
        {this.state.load ? (
          <div style={{ width: "100%" }}>
            <Loader></Loader>
            <div className="sc-fznxsB fWHyCt datatable-list web-skull">
              <header className="sc-fzoXzr hlidmt">
                <Skeleton height={41} />
              </header>
              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                    <Skeleton height={56} />
                  </div>
                  <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                    <Skeleton height={480} />
                  </div>
                </div>
              </div>
              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <Skeleton height={56} />
              </nav>
            </div>
            <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
              <header className="sc-fzoXzr hlidmt">
                <Skeleton height={34} />
              </header>
              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                    {skel.map((i) => {
                      return (
                        <div
                          id={"row-" + i}
                          className="sc-fzoLsD doBktq rdt_TableRow"
                        >
                          <Skeleton height={90} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <div className="sc-fzpmMD ftIMtk">
                  <Skeleton height={40} />
                </div>
              </nav>
            </div>
          </div>
        ) : (
          <div style={{ overflowY: "auto", marginBottom: 5 }}>
            <div>
              <div className="user-list-table-container">
                <ToastMessage
                  show={this.state.showToast["show"]}
                  helpers={this.state.showToast}
                ></ToastMessage>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                ></div>
                <div>{this.tableHeader()}</div>
                {this.state.pageStatus.data.length > 0 ? (
                  <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'channel_name', sort: 'asc' }],
                      },
                    }}
                    onSortModelChange={this.handleSortModelChange}
                    // initialState={{
                    //   sorting: {
                    //     sortModel: [{ field: 'channel_name', sort: 'asc' }],
                    //   },
                    // }}
                    style={{ border: "none" }}
                    className="cust-data-grid"
                    autoHeight
                    checkboxSelection
                    disableRowSelectionOnClick
                    isRowSelectable={this.rowDisabledCriteria} // matched condition rows only enabled
                    getRowId={(row) => row.channel_id} // key value of the row item
                    columns={columns}
                    rows={this.state.pageStatus.data}
                    rowCount={this.state.pageStatus.total}
                    loading={this.state.pageStatus.isLoading}
                    pageSizeOptions={[10, 15, 20, 25, 30]}
                    pagination
                    paginationMode="server"
                    paginationModel={this.state.paginationModel}
                    onPaginationModelChange={this.handlepaginationModalChange}
                    onRowSelectionModelChange={this.handleSelectionModelChange}
                    rowSelectionModel={this.state.rowSelectionModel}
                    keepNonExistentRowsSelected
                    hideFooterSelectedRowCount
                    disableColumnMenu
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: LBLROWSPERPAGE + ":",
                      },
                    }}
                    sx={{
                      "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                        {
                          outline: "none",
                        },

                      "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                        {
                          outline: "none",
                        },
                      "& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg path":
                        {
                          display: "none",
                        },
                      "& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg":
                        {
                          backgroundColor: "#cfcbcb",
                          width: "18px",
                          height: "18px",
                          borderRadius: "3px",
                        },
                    }}
                  />
                ) : (
                  <div style={{ marginTop: "5%" }}>
                    <EmptyState
                      image={emptyImage}
                      text2={
                        this.props.labels.labels.LBLCHANNELEMPTYTXT !==
                        undefined
                          ? this.props.labels.labels.LBLCHANNELEMPTYTXT
                          : "There are no channels available to view"
                      }
                    />{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="section-body bn-ctn">
              <div className="col-sm-12">
                <button
                  type="button"
                  className="button-style primary-btn"
                  onClick={this.handleConfrimPopup}
                  style={{ float: "right" }}
                  disabled={this.state.selectedRowsCount === 0}
                >
                  {this.props.labels.labels.LBLCONFIRM}
                </button>
                <button
                  type="button"
                  className="button-style secondary-btn"
                  id="btnaddCancel"
                  onClick={this.props.initialPage}
                  style={{ float: "right", marginRight: "10px" }}
                >
                  {this.props.labels.labels.LBLCANCEL}
                </button>
              </div>
              {this.state.showConfrimPopup ? (
                <ConfrimPopup
                  show={this.state.showConfrimPopup}
                  hide={this.handleCancelConfrimPopup}
                  title={
                    this.props.labels.labels.LBLASSIGNCHANNELS !== undefined
                      ? this.props.labels.labels.LBLASSIGNCHANNELS
                      : "Assign Channels"
                  }
                  selectedRowsCount={this.props.selectedRowsCount}
                  usersGroupsSelectedCount={this.state.selectedRowsCount}
                  buttonName={
                    this.props.labels.labels.LBLCONFIRM !== undefined
                      ? this.props.labels.labels.LBLCONFIRM
                      : "Confirm"
                  }
                  handleConfrim={this.handleConfrim}
                  handleCancel={this.handleCancelConfrimPopup}
                  description={
                    this.props.labels.labels.LBLCHANNELSASSIGNEDTOELIGIBLEUSERS
                  }
                  btnCancel={LBLCANCEL}
                />
              ) : null}
            </div>
          </div>
        )}
        <Modal show={this.state.showList} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="modal-title">
                {this.props.labels.labels.LBLUSERS}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="courListWrap">
              <div className={"userlist-popup"}>
                {this.props.selectedRows.map((item, index) => {
                  return (
                    <span
                      className="xlist-group-item-tc"
                      key={index}
                      title={item.name}
                    >
                      {item.name}
                    </span>
                  );
                })}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ChannelMultiAssign;

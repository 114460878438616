import React, { Component } from "react";
import { classList } from "dynamic-class-list";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { Modal } from "react-bootstrap";
import ScCommonData from "../../../services/common_data";
import "./GlobalSettings.scss";
import CompanyLogo1 from "../../components/companylogo/companylogo.js";
import { Link } from "react-router-dom";
import EditGlobalSettings from "./editGlobalSettings";
import EditCompanyLogo from "./editCompanyLogo";
import { reduxForm } from "redux-form";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import Skeleton from "react-loading-skeleton";
import styles from "./globalsettings.module.scss";
// import infoIcon from "../../../../src/assets/images/infoicon.png"
import { connect } from "react-redux";
import infoIcon from "../../../../src/assets/images/infoicon.png";
import Infored from "../../../../src/assets/images/Infored.png";
import infoaccu from "../../../../src/assets/images/infoaccu.png"
const $ = require("jquery");
const moment = require("moment");
const cstLabels = {
  // "LBLEXPIRYWARNINGBANNERTXT": "You have just _$$_ days left.it's time to renew your license,expiring on $$$$$$",
  // "LBLEXPIRYWARNINGTXT": "You have _$$_ days remaining  your current license, expiring on $$$$$$. Please reach out to support to renew your license and maintain uninterrupted service",
  // "LBLEXPIRYWARNINGDEACTIVEBANNERTXT": "Your site has expired and will be deactivated if not renewed promptily.Deactivation date: $$$$$$ ",
  // "LBLCONTACTSUPPORT":"Contact Support",
  // "LBLLICENCNCESTARTDATE" :"License start date",
  // "LBLLICENCNCELASTDATE":"License end date"

}
class GlobalSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      loading: true,
      errorType: null,
      company_name: "",
      company_currency: "",
      company_timezone: "",
      company_language: "",
      company_dateformat: "",
      company_timeformat: "",
      company_logo: "",
      company_group_types: null,
      company_group_labels: null,
      email_notification: false,
      expiryBannerText: '',
      showExpiryBannerText: false,
      deactivationBannerText: '',
      expiryBannerColor: "",
      deactivebannerColor: "",
      showExpiryModal: null,
      showExpiryModalText: '',
      Sitetype:localStorage.getItem('site_type'),
      files: [
        "nice.pdf",
        "verycool.jpg",
        "amazing.png",
        "goodstuff.mp3",
        "thankyou.doc",
      ],
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        cha: new Date(),
      },
    };
    this.modalChild = React.createRef();
  }

  componentDidMount() {
    this.getSettings();
    console.log("companyInfo", this.props.companyInfo);
  }

  getSettings = () => {
    HttpInterceptor.get("settings/?language=" + this.props.language).then(
      (res) => {
        let item = res.data.result[0];
        let group_labels = res.data.result.group_labels;
        this.setState({
          company_name: item.company_name,
          company_currency: item.currency,
          company_timezone: item.timezone,
          company_language: item.language_code,
          company_dateformat: item.dateformat,
          company_timeformat: item.timeformat,
          company_logo: item.company_logo,
          company_group_types:
            item.group_types.length > 0 ? JSON.parse(item.group_types) : null,
          company_group_labels: group_labels ? JSON.parse(group_labels) : null,
          email_notification: res.data.result.templates_status,
          email_with_code: item.email_with_code,
          loading: false,
        },
        ()=> {
          this.setExpiryConditions(); 
        });
      }
    );
  };
  setExpiryConditions = () => {
    console.log("global settings props values", this.props.companyInfo);
    console.log("global settings labels values", this.props.labels.labels);
    console.log("iii", this.state.deactivationBannerText, this.state.expiryBannerText)
    var a = moment(new Date());
    var b = moment(this.props.companyInfo.site_expire_date);
    let remainingDays = a.diff(b, 'days')
    console.log('showExpiryModalText', remainingDays);
    if (remainingDays > (this.props.companyInfo.site_expire_alert_days * -1) && remainingDays <= 0) {
      console.log("warning alert text");
      let expirySupportTxt=this.getLabel("LBLSUPPORT")
      let warningText=this.getLabel("LBLEXPIRYWARNINGTXT")
      let accuSupportTxt=this.getLabel("LBLACCUSUPPORT")
      let expiryBannerTxt=this.getLabel("LBLEXPIRYWARNINGBANNERTXT")
      warningText = warningText.replace('$$$$$$', moment(this.props.companyInfo.site_expire_date).format(this.props.dateformat));
      expiryBannerTxt = expiryBannerTxt.replace('$$$$$$', moment(this.props.companyInfo.site_expire_date).format(this.props.dateformat));
      this.setState({
        showExpiryModal:  remainingDays === (this.props.companyInfo.site_expire_alert_days* -1) || remainingDays === ((this.props.companyInfo.site_expire_alert_days-1) * -1),
        showExpiryModalText: warningText,
        expiryBannerText: expiryBannerTxt,
        expirySupportText:expirySupportTxt,
        accuSupportText:accuSupportTxt,
        deactivationBannerText: '',
        showDeactiveText: ""
      });
    }
    else if (remainingDays > 0 && remainingDays<= this.props.companyInfo.bonus_days) {
      console.log("Deactivation alert text");
      console.log("deactivationBannerText", this.state.deactivationBannerText)
      let deactiveSupportTxt=this.getLabel("LBLSUPPORT")
      let accudeSupportTxt=this.getLabel("LBLACCUSUPPORT")
      let  DeactiveWarningText=this.getLabel("LBLEXPIRYWARNINGDEACTIVEBANNERTXT")
      DeactiveWarningText = DeactiveWarningText.replace('$$$$$$', moment(this.props.companyInfo.site_expire_date).format(this.props.dateformat));
      let deactivationBannerTxt=this.getLabel("LBLEXPIRYWARNINGDEACTIVEBANNERTXT")
      deactivationBannerTxt = deactivationBannerTxt.replace('$$$$$$', moment(this.props.companyInfo.site_expire_date).add(this.props.companyInfo.bonus_days, "days").format(this.props.dateformat));
      console.log("deactivationBannerTxt", deactivationBannerTxt)
      this.setState({
        showExpiryModal:remainingDays = -1,
        deactivationBannerText: deactivationBannerTxt,
        expiryBannerText: '',
        showDeactiveText: DeactiveWarningText,
        showExpiryModalText: "",
        deactiveSupportText:deactiveSupportTxt,
        accudeSupportText:accudeSupportTxt,
      });
    }
  }

  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  saveSettings = (values) => {
    let data = {
      company_name: this.state.company_name,
      company_currency: this.state.company_currency,
      company_timezone: this.state.company_timezone,
      company_language: this.state.company_language,
      company_dateformat: this.state.company_dateformat,
      company_timeformat: this.state.company_timeformat,
    };
    HttpInterceptor.post("settings", data).then(
      (res) => {
        this.setState({ loading: true, initialValues: null });
        if (res) {
          this.getSettings();
        }
      },
      (err) => {
        if (
          err.response.data.errorType === 2 ||
          err.response.data.errorType === 2.1
        ) {
          this.setState({
            errorType: err.response.data.errorType,
          });
        }
      }
    );
  };
  getValue = (x) => {
    return this.state[x];
  };
  cancelChange() {
    window.location.reload();
  }

  handleDrop = (files) => {
    let fileList = this.state.files;
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
      fileList.push(files[i].name);
    }
    this.setState({ files: fileList });
  };

  renderField = ({
    input,
    label,
    type,
    g_value,
    className,
    disabled,
    meta: { touched, error, warning },
    required,
    onChng,
    name,
  }) => (
    <div
      key={name}
      className={classList([
        "form-group",
        "row",
        required === true ? "required" : "",
      ])}
    >
      <label className={` col-sm-3 ${styles.controlLabel}`}>{label}</label>
      <div class="col-sm-9 colsm">
        <input
          key={name}
          {...input}
          value={g_value}
          type={type}
          className={className}
          readOnly={true}
        />
        {touched &&
          ((error && (
            <span className="required-field">
              {typeof error === "string"
                ? this.props.labels.labels[error]
                : this.props.labels.labels[error.label].replace(
                  "$$$",
                  error.val
                )}
            </span>
          )) ||
            (warning && <span className="required-field">{warning}</span>))}
      </div>
    </div>
  );

  openModal1 = () => {
    this.setState({ show1: true });
  };
  closeModal1 = () => {
    $(".modal-backdrop").css({ position: "relative" });

    this.setState({ show1: false });
  };
  openModal2 = () => {
    this.setState({ show2: true });
  };
  closeModal2 = () => {
    $(".modal-backdrop").css({ position: "relative" });

    this.setState({ show2: false });
  };
  ShowToast = () => {
    this.closeModal2();
    this.closeModal1();
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: this.props.labels.labels.LBLUPDTSCCESS,
        cha: new Date(),
      },
    });
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  render() {
    const { labels } = this.props.labels;
    const {
      LBLTIMEZONE,
      LBLTIMEFORMAT,
      LBLDATEFORMAT,
      LBLLANGUAGE,
      LBLEMAILNOTIFICATION,
      LBLON,
      LBLOFF,
      LBLEMAILWITHCODE,
      LBLGROUPTYPE,
      LBLEDIT,
      LBLCONTACTSUPPORT,
      LBLLICENCNCESTARTDATE,
      LBLLICENCNCELASTDATE,
      LBLSETTINGSCONSUPPORT,
      LBLEXPIRYWARNINGBANNERTXT,
      LBLEXPIRYWARNINGDEACTIVEBANNERTXT,
      LBLEXPIRYWARNINGTXT,
      LBLOK,
      LBLSUPPORT,
      LBLACCUSUPPORT
      
    } = this.props.labels.labels;
    const {
      submitting
    } = this.state;

    const TimeZones = ScCommonData.getTimeZones();
    const Languages = ScCommonData.getLanguages();
    const DateFormats = ScCommonData.getDateFormats();
    const TimeFormats = ScCommonData.getTimeFormats();
    let company_logo = this.state.company_logo;
    return (
      //<div className="card-box">
<>
       <div>
           {(this.state.expiryBannerText || this.state.deactivationBannerText)?
           <div className={this.state.expiryBannerText && this.state.Sitetype === "SafeStart" ? styles.siteExpirationCtn : this.state.expiryBannerText ? styles.siteExpirationaccuCtn : styles.siteDeactivationCtn}>
                  <div className={styles.siteExpiration}>
                  <img src={this.state.expiryBannerText && this.state.Sitetype === 'SafeStart' ? infoIcon : this.state.expiryBannerText ? infoaccu: Infored} style={{ height: "30px", width: "30px" }} alt="" />
                    <div className={styles.expirationtext}>
                      {this.state.expiryBannerText ? <><span className={styles.exsupporttxt}>{this.state.expiryBannerText}</span> <span className={styles.supporttxt}>{this.state.Sitetype === "SafeStart" ? this.state.expirySupportText : this.state.accuSupportText}</span></> : <><span className={styles.exsupporttxt}>{this.state.deactivationBannerText}</span> <span className={styles.supporttxt}>{this.state.Sitetype === "SafeStart" ? this.state.deactiveSupportText : this.state.accudeSupportText}</span></>}
                    </div>
                  </div>
                </div>
                  : null}
              </div>

      <div className="mob-pad">
        <div className="page-header-field">
          <h3 className="page-title" style={{marginTop:"6px"}}>{labels.LBLGLOBALSETTINGS}</h3>
        </div>
        <hr className="head-line" />
        <div>
          {this.state.showToast.show ? (
            <ToastMessage
              show={this.state.showToast["show"]}
              helpers={this.state.showToast}
            ></ToastMessage>
          ) : (
            ""
          )}
          <form className="form">
            <div>
              <div className="row"
                style={{
                  padding: "10px 0px 10px 0px",
                  borderBottom: "1px solid grey",
                }} >
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <h1
                    className={styles.formfieldcontainer}
                    style={{ marginTop: "11px" }}  >
                    <b className="page-logo-title">
                      {this.state.company_name ? (
                        this.state.company_name
                      ) : (
                        <Skeleton height={32} />
                      )}
                    </b>
                  </h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div className={styles.formfieldcontainerright} style={{
                    float: "right",
                    textAlign: "center",
                    paddingBottom: "16px",
                  }}  >
                    {company_logo === "" ? (
                      <div>
                        <Skeleton width={200} height={35} />
                      </div>
                    ) : (
                      <div>
                        <CompanyLogo1
                          imageName={company_logo}
                          imgWidth="200px"
                        />
                      </div>
                    )}
                    <a href="#" data-toggle="modal" onClick={this.openModal1}
                      className="lightblue" >
                      {this.props.labels.labels.LBLEDITLOGO}
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 usr-frm-list"
                  style={{ marginTop: "12px"}} >
                  <div className={styles.formfieldcontainer}>
                    <div
                      key="company_language"
                      className="form-group row ssi-settings-row"
                    >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {LBLLANGUAGE}
                      </label>
                      {this.state.loading === false ? (
                        <div
                          class="col-sm-9 colsm"
                          style={{ padding: "6px 6px 6px 16px" }}
                        >
                          {Object.keys(Languages).map((d, key) =>
                            this.getValue("company_language") ===
                              Languages[d].code
                              ? Languages[d].name
                              : ""
                          )}
                        </div>
                      ) : (
                        <div className="col-sm-9 colsm  " >
                          <Skeleton height={33} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.formfieldcontainer}>
                    <div
                      key="company_timezone"
                      className="form-group row ssi-settings-row"
                    >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {LBLTIMEZONE}
                      </label>
                      {this.state.loading === false ? (
                        <div
                          class="col-sm-9 colsm "
                          style={{ padding: "6px 6px 6px 16px" }}
                        >
                          {Object.keys(TimeZones).map((d, key) =>
                            this.getValue("company_timezone") ===
                              TimeZones[d].value
                              ? TimeZones[d].name
                              : ""
                          )}
                        </div>
                      ) : (
                        <div
                          class="col-sm-9 colsm "
                          style={{ padding: "6px 6px 6px 16px" }}
                        >
                          <Skeleton height={33} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.formfieldcontainer}>
                    <div
                      key="company_dateformat"
                      className="form-group row ssi-settings-row"
                    >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {LBLDATEFORMAT}
                      </label>
                      {this.state.loading === false ? (
                        <div
                          class="col-sm-9 colsm "
                          style={{ padding: "6px 6px 6px 16px" }}
                        >
                          {Object.keys(DateFormats).map((d, key) =>
                            this.getValue("company_dateformat") ===
                              DateFormats[d].code
                              ? DateFormats[d].name
                              : ""
                          )}
                        </div>
                      ) : (
                        <div
                          class="col-sm-9 colsm "
                          style={{ padding: "6px 6px 6px 16px" }}
                        >
                          <Skeleton height={33} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.formfieldcontainer}>
                    <div
                      key="company_timeformat"
                      className="form-group row ssi-settings-row"
                    >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {LBLTIMEFORMAT}
                      </label>
                      {this.state.loading === false ? (
                        <div
                          class="col-sm-9  colsm "
                          style={{ padding: "6px 6px 6px 16px" }}
                        >
                          {Object.keys(TimeFormats).map((d, key) =>
                            this.getValue("company_timeformat") ===
                              TimeFormats[d].code
                              ? TimeFormats[d].name
                              : ""
                          )}
                        </div>
                      ) : (
                        <div
                          class="col-sm-9 colsm "
                          style={{ padding: "6px 6px 6px 16px" }}
                        >
                          <Skeleton height={33} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.formfieldcontainer}>
                    <div
                      key="company_group_types"
                      className="form-group row ssi-settings-row"
                    >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {LBLGROUPTYPE}
                      </label>
                      <div
                        class="col-sm-9 colsm "
                        style={{ padding: "6px 6px 6px 16px" }}
                      >
                        {this.state.company_group_types ? (
                          <ul className="company_group_types">
                            {this.state.company_group_types.map((group) => {
                              if (
                                this.state.company_group_labels[group] &&
                                this.state.company_group_labels[group][
                                "lbl_" + group
                                ]
                              ) {
                                return (
                                  <li className="company_group_types">
                                    {labels["LBLGROUP" + group.split("_")[1]] +
                                      " -- " +
                                      this.state.company_group_labels[group][
                                      "lbl_" + group
                                      ]}
                                  </li>
                                );
                              } else {
                                return (
                                  <li className="company_group_types">
                                    {labels["LBLGROUP" + group.split("_")[1]] +
                                      " -- " +
                                      labels["LBLGROUP" + group.split("_")[1]]}
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        ) : (
                          <Skeleton height={33} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.formfieldcontainer} >
                    <div key="company_group_types" className="form-group row ssi-settings-row"style={{marginBottom:"0px"}} >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {LBLEMAILNOTIFICATION}
                      </label>
                      <div class="col-sm-9 colsm "
                        style={{ padding: "6px 6px 6px 16px" }} >
                        <ul className="company_group_types">
                          {!this.state.loading ? (
                            <li className="company_group_types">
                              {this.state.email_notification ? LBLON : LBLOFF}
                            </li>
                          ) : (
                            <Skeleton height={33} />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={styles.formfieldcontainer}>
                    <div key="company_group_types" className="form-group row ssi-settings-row" >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {LBLEMAILWITHCODE}
                      </label>
                      <div class="col-sm-9 colsm "
                        style={{ padding: "6px 6px 6px 16px" }} >
                        {/* <ul className="company_group_types"> */}
                          {!this.state.loading ? (
                            // <li className="company_group_types">
                            <span>
                              {this.state.email_with_code ? LBLON : LBLOFF}
                              </span>
                            // </li>
                          ) : (
                            <Skeleton height={33} />
                          )}
                        {/* </ul> */}
                      </div>
                    </div>
                  </div>
                  <div className={styles.formfieldcontainer}>
                    <div key="company_dateformat" className="form-group row ssi-settings-row" >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {this.props.labels.labels.LBLLICENCNCESTARTDATE}
                      </label>
                      <div class="col-sm-9 colsm "
                        style={{ padding: "6px 6px 6px 16px" }} >
                      
                          {!this.state.loading ? (
                            <span className="company_dateformat">
                              {/* {this.props.companyInfo.site_start_date} */}
                             {moment(this.props.companyInfo.site_start_date).format(this.props.dateformat)}
                              
                            </span>
                          ) : (
                            <Skeleton height={33} />
                          )}
                        
                      </div>
                    </div>
                  </div>
                  <div className={styles.formfieldcontainer}>
                    <div key="company_group_types" className="form-group row ssi-settings-row" >
                      <label className={`col-sm-3 ${styles.controlLabel}`}>
                        {this.props.labels.labels.LBLLICENCNCELASTDATE}
                      </label>
                      <div class="col-sm-9 colsm "
                        style={{ padding: "6px 6px 6px 16px" }}
                        
                       >
                          {!this.state.loading ? (
                            <span className="company_group_types">
                              {/* {this.props.companyInfo.site_expire_date}  */}
                              {moment(this.props.companyInfo.site_expire_date).format(this.props.dateformat)}
                            </span>


                          ) : (
                            <Skeleton height={33} />
                          )}

                        
                        
                        

                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <div
                    style={{
                      float: "right",
                      marginTop: "28px",
                      marginRight: "20px",
                      textAlign: "center",
                    }}
                  >
                    {
                      !this.state.loading ?
                        <button
                          type="button"
                          className="button-style primary-btn"
                          disabled={submitting}
                          onClick={this.openModal2}
                        >
                          {LBLEDIT}
                        </button> : null
                    }

                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Modal
          size="lg"
          className={
            "animated  modal-right-side " +
            (this.state.show1 ? "fadeInRight" : "fadeOutRight")
          }
          show={this.state.show1}
          onHide={this.closeModal1}
          onCancel={this.closeModal2}
        >
          <EditCompanyLogo
            props={this.props}
            company_logo={company_logo}
            onCancel={this.closeModal1}
            ShowToast={this.ShowToast}
            labels={this.props.labels}
          />
        </Modal>
        <Modal
          size="lg"
          className={
            "animated  modal-right-side " +
            (this.state.show2 ? "fadeInRight" : "fadeOutRight")
          }
          show={this.state.show2}
          onHide={this.closeModal2}
          onCancel={this.closeModal2}
        >
          <EditGlobalSettings
            {...this.props}
            onCancel={this.closeModal2}
            ShowToast={this.ShowToast}
            company_name={this.state.company_name}
            company_currency={this.state.company_currency}
            company_timezone={this.state.company_timezone}
            company_language={this.state.company_language}
            company_dateformat={this.state.company_dateformat}
            company_timeformat={this.state.company_timeformat}
            company_logo={company_logo}
            email_with_code={this.state.email_with_code}
            company_group_types={this.state.company_group_types}
            company_group_labels={this.state.company_group_labels}
            user_language={this.props.language}
          />
        </Modal>
      </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyInfo: state.companyinfo_reducer.companyInfo.data
  };
};
export default reduxForm({
  form: "simple", // a unique identifier for this form
})(connect(mapStateToProps, null)(GlobalSettings));

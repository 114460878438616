import React, { Component } from "react";
import ReportServices from "../report_services";
import GroupServices from "../../group-list/group_services";
import orderBy from "lodash/orderBy";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import UsersService from "../../user-list/UsersService";
import { connect } from "react-redux";
import Loader from "../../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import CCCertificate from "../../../../common_components/rptcertificate/rptcertificate";
import ScCommonServices from "../../../../services/common_services";
import emptyImage from '../../../../assets/images/learnerprogress_emptyst.png'
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import moment from "moment";
import $ from 'jquery';
import { TrendingUpSharp } from "@material-ui/icons";

let prev_courseid='Empty';
let resetCourse=false;
let gConditions=[];
let objFilterData = {};
let totalRecords = 0; 
let inc=0,val=1,fload=1;
const unitordersort = (a, b) =>
  (a["order"] > b["order"] && 1) || (a["order"] === b["order"] ? 0 : -1);
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};

class LCCReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_logo: this.props.company_logo,
      siteType:localStorage.getItem('site_type')=="SafeStart"?1:2,
      pageSize: 100,
      repos: [],
      firstLoad:1,
      changeMode:false,
      open: false,
      loading: true,
      reportloading: false,
      userData: [],
      user_roles: [],
      pageState: "LCC_D1",
      toggleunitstate: {},
      roles: [{ role_id: 1, role_name: "learner" }],
      show_course:0,
      loadLCC_D1_List: 0,
      loadLCC_D1_List_Groups: 0,
      orgLCC_D1_List: [],
      LCC_D1_List: [],
      LCC_D1_List_Fetched: 0,
      LCC_D1_SummaryData: { records: 0 },
      value:true,
      sel_D1_Data: {},

      LCC_D1_GPH_SelStateId: "1",
      filterDataLoaded: 0,
      coursestatus: [
        { id: "Notstarted", value: this.props.labels.labels["LBLNOTSTARTED"] },
        { id: "Inprogress", value: this.props.labels.labels["LBLINPROGRESS"] },
        { id: "Completed", value: this.props.labels.labels["LBLCOMPLETED"] },
      ],
    };
    this.LCC_D1_Filter = React.createRef();
    this.LCC_D2_Filter = React.createRef();
  }
  appLogo = process.env.REACT_APP_LOGO;
  loadpage(a, x) {
    if (a === "LCC_D1") {
      this.setState({loadLCC_D1_List: 1});
    }
    if (a === "group") {
      this.setState({loadLCC_D1_List_Groups: 1});
    }
    if (
      this.state.loadLCC_D1_List === 1 &&
      this.state.loadLCC_D1_List_Groups === 1
    ) {
      this.setState({
        loading: false,
      });
    }
  }
  getCompanyLogoPath() {
    if (String(this.props.company_logo).indexOf("/images/") == -1) {
      let assetFolder = String(this.props.imageName).split(".");
      assetFolder.pop();
      let postData = {
        filename: this.props.company_logo,
        assettype: "image",
        assetFoldet: assetFolder.join(),
      };
      this.getLogoPath(postData);
    } else {
      this.setState({
        company_aws_logo_path: "",
      });
    }
  }
  getLogoPath(postData) {
    ScCommonServices.getAssyncSignedUrl(postData).then((resultData) => {
      let url = resultData.data;
      let appLogo = process.env.REACT_APP_LOGO;
      fetch(url, {
        retryOn: function (attempt, error, response) {
          if (!response || response.status >= 403) {
            return false;
          }
        },
      }).then((response) => {
        if (response.status === 200) {
          this.setState({
            company_aws_logo_path: resultData.data,
          });
        } else {
          this.setState({
            company_aws_logo_path: "",
            company_logo: "/images/" + appLogo,
          });
        }
      });
    });
  } 
  componentDidUpdate(){
    if(this.state.firstLoad){
    $("#Unit_Name").css({"display":"none"});
    $("#Module_Name").css({"display":"none"});  
    }   
  }
  componentDidMount() {
    objFilterData = {}; 
    let data = {};
    data.date1 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    data.date2 = new Date(new Date());
    data.sdate1 = this.getDateFormat(data.date1);
    data.sdate2 = this.getDateFormat(data.date2);
    data.offsetTime = this.getOffsetTime();
    this.getFilterDatas();
    let getData = {
      language: this.props.language,
    };
    this.getCompanyLogoPath();
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      //{group_type_id: "GP_1", lbl_GP_1: "Location", Active: "1"}
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
        },
        this.loadpage("group", iresponse)
      );
    });
    let {courses,courseunits} = this.state; 
    if(this.props.showunits_certificate)
    document.addEventListener("click", this.unitHandler2);    
     
  } 
  onClickFilter=()=>{
    this.showHideTitle();
  }
  unitHandler2 = async(event,a)=>{ 
    let courseunits, cId =  event.target.id ;
    if(this.props.showunits_certificate==0){
      return;
    }
    if(cId.indexOf("Course__Name_")>-1){
      let show_unit,courseunits; 
      resetCourse=true;
      prev_courseid=""; 
      $("#Unit_Name").css({"display":"none"});
      $("#Module_Name").css({"display":"none"});
       
      let data = {report : "LCC_D1"};  
      await ReportServices.getFilterDatas(data).then((response) => {
      courseunits = response.result[4]; 
      gConditions.map(item=>{
        if(item.cntid=="Unit_Name"){
          item.filterData = courseunits;
          if(item.filter.condition)
          item.filter.condition.value  = "";
        }
        if(item.cntid=="Course_Name"){
        if(item.filter.condition && item.filter.condition.value.length==1)  
          item.filter.condition.value  = [];
        }
      })
        val = val<10?val+1:1;
        this.setState(
          {
            filterDataLoaded: 1,
            pageState: "LCC_D1",  
            courseunits: courseunits, 
            columns: gConditions,
            show_course:1,
            changeMode:true
          } 
        ); 
      });
    }
  } 
  setOpen = async(item) =>{
    let courseunits,show_unit ; 
    this.setState({changeMode:false,selItem:item})
  }
  onCheckChanges = (data,items) =>{ 
    this.unitHandler1(data,items); 
  } 
  unitHandler1= (item,items)=>{ 
    let courseunits, cId =  item;
    let condition1 = {
        name:"Course Name",
        operator: "EQUALS",
        operatorText: "is",
        value: [] ,
        showField: true,
        notext:true,
        type: "stringsingle"
      }
    let {LBLFULLNAME,LBLUNITNAME,LBLMODULENAME,LBLCOURSENAME} = this.props.labels.labels;
    if(this.state.siteType ==2)
      LBLUNITNAME = LBLMODULENAME; 
    let allItems = Object.keys(items);
    let show_unit = 0; 
      let data, crsFilter = item;
      let {selItem} = this.state;
      console.log("CCCCCC ==>",selItem); 
      condition1.value.push(crsFilter); 
        if(crsFilter){
          data = crsFilter;
          data.report = "LCC_D1"
        }else{
          return;
        } 
          ReportServices.getFilterDatasUnits(data).then((response) => {
      
          console.log("DDDDD ==>" ); 
          courseunits = response.result[0];
          show_unit = response.result[1][0].showunits_certificates; 
          prev_courseid = data.id;
          gConditions.map(item=>{
            if(item.name==LBLUNITNAME){
              item.filterData = courseunits;
              if(item.filter.condition)
              item.filter.condition.value="";
            }
            allItems.map((i)=>{
              if(i==item.name){
                item.filter.condition = items[i].state.condition; 
                if(item.cntid=="Course_Name"){
                  item.filter.condition.notext=true;
                }
                if(i==LBLUNITNAME && item.filter.condition)
                item.filter.condition.value="";
              }
            });
          })
          this.setFilterFields("F1", gConditions); 
          val = val<10?val+1:1;
          console.log("VVVVVVVVV ==>>", val); 
          this.setState(
            {
              filterDataLoaded: 1,
              pageState: "LCC_D1",  
              courseunits: courseunits, 
              columns: gConditions,
              value:val,
              firstLoad:false,
              show_course:1,
              show_unit_certificate:show_unit,
              changeMode:true
            }   
          );  
          this.showHideTitle();
          if(show_unit==0){
            $("#Unit_Name").css({"display":"none"});
            $("#Module_Name").css({"display":"none"});  
          }else{
            $("#Unit_Name").css({"display":"block"});
            $("#Module_Name").css({"display":"block"});
          }
          this.setUnitsFilter(2)
        });  
  }  
  showHideTitle=()=>{
      $(".prowfilterbox").css({position:""});  
      let flag = $('.prowfilterbox').is(':visible')
        
      if(flag) {
        $("#reports_title").css({display:"none"}); 
        $("#btnGroupDrop122").css({display:"none"});  
      } 
      else{
        $("#reports_title").css({display:"block"}); 
        $("#btnGroupDrop122").css({display:"initial"});  
      }
          
  }
  setUnitsFilter = (mode) =>{
    let len = $("#Module_Name li").length;
    let {courseunits} = this.state;
    let id = "#Unit_Name li";
    if(courseunits){
    $("#Unit_Name li").map((i,item)=>{
      let txt = $(item).find("span").text()
      if(mode==1){
        $(item).css({"display": "block"});
      }else{
      let idx = courseunits.findIndex(i=>
        {
          if(i.value == txt){ //i.value??""
            console.log("AAAA ==>> ",i.value, txt);
            $(item).css({"display": "block"}); 
          }else{
            $(item).css({"display": "none"});
          }
          return i.value == txt;
        }) ; 
      }
    }); 
  }
  } 
  async getFilterDatas() {
    let data = {};
    data.report = "LCC_D1";
    let group_types = await require("../../../../json_files/group_types.json");
    ReportServices.getFilterDatas(data).then((response) => {
      let xgroup_types = response.result[0];
      let labels;
      let groups = {};
      for (var i = 0; i < xgroup_types.length; i++) {
        if (this.props.language_code === xgroup_types[i].language_code) {
          labels = JSON.parse(xgroup_types[i].labels).group_labels;
        }
      }
      if (labels && labels.length > 0) {
        for (const grouptype of group_types) {
          if (labels[grouptype.group_type_id]) {
            grouptype["lbl_" + grouptype.group_type_id] =
              labels[grouptype.group_type_id]["lbl_" + grouptype.group_type_id];
            grouptype["Active"] = labels[grouptype.group_type_id]["Active"];
            groups[grouptype.group_type_id] = [];
            groups["obj" + grouptype.group_type_id] = {};
          } else {
            grouptype["Active"] = "0";
          }
        }
      }
      let xgroups = response.result[1];
     
      for (var j = 0; j < xgroups.length; j++) {
        if (
          groups[xgroups[j].group_type_id] &&
          !groups["obj" + xgroups[j].group_type_id][xgroups[j].value]
        ) {
          groups["obj" + xgroups[j].group_type_id][xgroups[j].value] = 1;
          groups[xgroups[j].group_type_id].push(xgroups[j]);
        }
      }
      let courses = response.result[2];
      let users = response.result[3];
      let courseunits = response.result[4];  
      this.setState(
        {
          filterDataLoaded: 1,
          pageState: "LCC_D1",
          courses: courses,
          userNames: users,
          courseunits: courseunits,
        },
        this.loadpage("LCC_D1")
      );
    }); 
  }
  
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getLCC_D1_Export(data) {
    data.report = "LCC_D1";
    ReportServices.getLCC_D1_Export(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF";
          bytesBase64 = encodeURI(bytesBase64);
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        //console.log("TTTTTTTTTTTTTTTTTTTT,fileUrl", fileUrl)
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob)
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName = this.props.labels.labels.LBLLCEXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }

  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  getLCC_D1_Summary = (xresult, ydata) => {
    //console.log("getLCC_D1_SummaryQQQQQQQQQQQQQQQQQ", ydata);
    let xsummaryData = {
      rushing: {},
      fatigue: {},
      frustration: {},
      complacency: {},
    };
    let grpSummaryData = {
      sum_rushing: 0,
      sum_fatigue: 0,
      sum_frustration: 0,
      sum_complacency: 0,
      dwgh_rushing: {},
      dwgh_fatigue: {},
      dwgh_frustration: {},
      dwgh_complacency: {},
    };
    let summaryData = {
      rushing: [],
      fatigue: [],
      frustration: [],
      complacency: [],
    };
    for (var i = 0; i < xresult.length; i++) {
      let xdate = xresult[i].created_on
        .substr(0, 10)
        .split("-")
        .reverse()
        .join("/");
      if (xsummaryData["rushing"].hasOwnProperty(xdate) === false) {
        xsummaryData["rushing"][xdate] = {};
        xsummaryData["fatigue"][xdate] = {};
        xsummaryData["frustration"][xdate] = {};
        xsummaryData["complacency"][xdate] = {};
      }
      if (
        xsummaryData["rushing"][xdate].hasOwnProperty(xresult[i].rushing) ===
        false
      ) {
        xsummaryData["rushing"][xdate][xresult[i].rushing] = 0;
      }
      xsummaryData["rushing"][xdate][xresult[i].rushing]++;
      grpSummaryData["sum_rushing"]++;

      if (
        xsummaryData["fatigue"][xdate].hasOwnProperty(xresult[i].fatigue) ===
        false
      ) {
        xsummaryData["fatigue"][xdate][xresult[i].fatigue] = 0;
      }
      xsummaryData["fatigue"][xdate][xresult[i].fatigue]++;
      grpSummaryData["sum_fatigue"]++;
      if (
        xsummaryData["frustration"][xdate].hasOwnProperty(
          xresult[i].frustration
        ) === false
      ) {
        xsummaryData["frustration"][xdate][xresult[i].frustration] = 0;
      }
      xsummaryData["frustration"][xdate][xresult[i].frustration]++;
      grpSummaryData["sum_frustration"]++;
      if (
        xsummaryData["complacency"][xdate].hasOwnProperty(
          xresult[i].complacency
        ) === false
      ) {
        xsummaryData["complacency"][xdate][xresult[i].complacency] = 0;
      }
      xsummaryData["complacency"][xdate][xresult[i].complacency]++;
      grpSummaryData["sum_complacency"]++;
    }
    var getcount = function (xday, ydays) {
      let xdt = xday.split("/");
      let xdays =
        parseInt(xday.split("/")[2]) * 1000 +
        getDays(
          new Date(parseInt(xdt[2]), parseInt(xdt[1]) - 1, parseInt(xdt[0]))
        );

      return xdays - ydays;
    };
    var getDays = function (xdate) {
      var now = new Date(xdate.getTime());
      var start = new Date(now.getFullYear(), 0, 0);
      var diff =
        now -
        start +
        (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
      var oneDay = 1000 * 60 * 60 * 24;
      var day = Math.floor(diff / oneDay);
      return day;
    };

    let startDay = ydata.date1.getFullYear() * 1000 + getDays(ydata.date1);
    for (let xstate in xsummaryData) {
      for (let xday in xsummaryData[xstate]) {
        var dtotal = 0;
        for (let xrate in xsummaryData[xstate][xday]) {
          let obj = {
            x: getcount(xday, startDay),
            y: xrate,
            org_value: xsummaryData[xstate][xday][xrate],
            day: xday,
          };
          summaryData[xstate].push(obj);
          dtotal = dtotal + xsummaryData[xstate][xday][xrate];
        }
        grpSummaryData["dwgh_" + xstate][xday] =
          dtotal / grpSummaryData["sum_" + xstate];
      }
      grpSummaryData["mwgh_" + xstate] = 0;
    }
    //set weight value
    for (let xstate in summaryData) {
      for (i = 0; i < summaryData[xstate].length; i++) {
        summaryData[xstate][i].weight =
          summaryData[xstate][i].org_value *
          grpSummaryData["dwgh_" + xstate][summaryData[xstate][i].day];
        grpSummaryData["mwgh_" + xstate] =
          grpSummaryData["mwgh_" + xstate] > summaryData[xstate][i].weight
            ? grpSummaryData["mwgh_" + xstate]
            : summaryData[xstate][i].weight;
      }
    }
    for (let xstate in summaryData) {
      for (i = 0; i < summaryData[xstate].length; i++) {
        summaryData[xstate][i].value = Math.round(
          (summaryData[xstate][i].weight / grpSummaryData["mwgh_" + xstate]) *
          100,
          0
        );
      }
    }
    //console.log("sssssssssssssummaryData",summaryData)
    return summaryData;
  };
  getLCC_D1_List(data) {
    
    this.setState({
      reportloading: true,
      LCC_D1_List_Fetched: 1 ,
      firstLoad: false
    });
    data.report = "LCC_D1";  
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat; 
    ReportServices.getLCC_D1_List(data).then((response) => { 
      let result = response.result[8];

      let xsummaryData = {}; 
      xsummaryData.records = result.length;
      let pageCounts = result.length / this.state.pageSize + 1;
      let pageIndex = 1;
      let flgCnt=false,flgUnit=false,totalUnits=0,  xresult = result.slice(0, pageIndex * this.state.pageSize); 
      totalRecords = 0;
      result.map((item,i)=>{
        flgCnt=true;flgUnit=true;
        item.products.map((prditem, i) =>{ 
            let showunits_certificate = prditem.showunits_certificate;
           // if (showunits_certificate == 0) {
              if(prditem.status === "Completed"){
                if(flgCnt){
                  totalRecords++;
                  flgCnt=false;
                }
              }
           // } 
           prditem.units.map((unititem, i) =>{ 
            if(unititem.status === "Completed"){
              if(flgUnit){
                totalUnits++;
                flgUnit=false;
              }
            }
           })
        });
      }) 
      if(this.props.showunits_certificate==0 ){
          totalRecords = totalRecords ;
      }else{  
        if(this.state.show_unit_certificate){
          totalRecords = totalUnits ;
        }else{
          totalRecords = totalRecords ;
        } 
      }
      this.setState(
        {
          pageState: "LCC_D1",
          LCC_D1_List_Fetched: 1,
          orgLCC_D1_List: result,
          LCC_D1_List: xresult,
          pageIndex: pageIndex,
          pageCounts: pageCounts,
          totalRecords:totalRecords,
          show_course:0,
          //LCC_D1_SummaryData: xsummaryData,
          sel_D1_Data: data,
          reportloading: false,
          changeMode:false
        },
        this.applyFilterCondition(result, data),
        REFS.F1.onExportDone(),
        setTimeout(() => {
          this.pageRender();
        }, 1000)
      );
    });
  }
  pageRender = () => {
    if (this.state.pageIndex >= 0) {
      let pageIndex = this.state.pageIndex + 1;
      // let pageCounts = this.state.pageCounts;
      let pageSize = this.state.pageSize;
      //let dsipcount = (pageIndex ^ pageIndex) * pageSize 
      inc++;
      if (this.state.LCC_D1_List.length <= this.state.orgLCC_D1_List.length && inc<2) {
        let xresult = this.state.orgLCC_D1_List.slice(
          0,
          Math.pow(pageIndex, pageIndex) * pageSize
        );
        this.setState({
          LCC_D1_List: xresult,
          pageIndex: pageIndex,
        });
        setTimeout(() => {
          this.pageRender();
        }, 300);
      } else {
        //alert("completed")
      }
    } else {
      setTimeout(() => {
        this.pageRender();
      }, 300);
    }
  };
  applyFilterCondition = (orgresult, data) => {
    if (objFilterData.availableLCC_D1Fields) {
      let xobj = objFilterData.availableLCC_D1Fields;
      let result = [];
      //console.log("applyFilterCondition!!!!!!!", xobj);
      for (var i = 0; i < orgresult.length; i++) {
        if (this.checkFilterCondition(xobj, orgresult[i]) === true) {
          result.push(orgresult[i]);
        }
      }
      this.setState(
        {
          LCC_D1_List: result,
        },
        this.loadpage("LCC_D1")
      );
    } else {
      let result = orgresult;
      this.setState(
        {
          LCC_D1_List: result,
        },
        this.loadpage("LCC_D1")
      );
    }
  };
  showLCC_D1 = () => {
    //console.log("showLCC_D1")
    this.setState({
      pageState: "LCC_D1",
    });
  };

  getGroupTypes = async () => {
    GroupServices.getGroupTypeLabels().then((response) => {
      //console.log("response!!!!!!!!!!!!!1", response);
      this.setState(
        {
          groupTypeNames: response,
        },
        this.loadpage("group", response)
      );
    });
  };
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  toggletree = (xid) => { 
    let xtoggleunitstate = this.state.toggleunitstate;
    xtoggleunitstate[xid] = !(xtoggleunitstate[xid] || false);
    //alert(xid + "-" + xtoggleunitstate[xid])
    this.setState({
      toggleunitstate: xtoggleunitstate,
    });
  };
 
  getLCC_D1_Columns = (x) => {
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
    let {LBLUNITNAME,LBLUNITCOMPLETEDTHROUGH,LBLUNITSTARTDATE, LBLUNITCOMPLETIONDATE, LBLUNITTIMESPENT, LBLUNITSTATUS,
         LBLMODULENAME,LBLMODULECOMPLETEDTHROUGH,LBLMODULESTARTDATE, LBLMODULECOMPLETIONDATE, LBLMODULETIMESPENT, LBLMODULESTATUS
        }=this.props.labels.labels;
        LBLUNITNAME = this.state.siteType==1?LBLUNITNAME:LBLMODULENAME?LBLMODULENAME :"Module Name" ;
        LBLUNITCOMPLETEDTHROUGH = this.state.siteType==1?LBLUNITCOMPLETEDTHROUGH:LBLMODULECOMPLETEDTHROUGH?LBLMODULECOMPLETEDTHROUGH :"Module Training Type" ;
        LBLUNITSTARTDATE = this.state.siteType==1?LBLUNITSTARTDATE:LBLMODULESTARTDATE?LBLMODULESTARTDATE :"Module Start Date" ;
        LBLUNITCOMPLETIONDATE = this.state.siteType==1?LBLUNITCOMPLETIONDATE:LBLMODULECOMPLETIONDATE?LBLMODULECOMPLETIONDATE:"Module Completion Date" ;
        LBLUNITTIMESPENT = this.state.siteType==1?LBLUNITTIMESPENT:LBLMODULETIMESPENT?LBLMODULETIMESPENT:"Module Time Spent" ;
        LBLUNITSTATUS = this.state.siteType==1?LBLUNITSTATUS:LBLMODULESTATUS?LBLMODULESTATUS:"Module Status" ;

    let columns = [
      {
        name: this.props.labels.labels.LBLFULLNAME,
        cntid:"Full_Name",
        selector: "first_name",
        filter: {
          type: "string",
          showField: true,
          checked: true,
          render: "server",
        },
        filterData: this.state.userNames,
        sortable: true,
        compact: true,
        exportcell: "Full_Name",
        exportcellproperty: { order: 100 },
        tableIndex: 0,
        cell: (row) => {
          return (
            <div>
              <span className="userName rle-brdr">
                {row.first_name + " " + row.last_name}
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLENROLLMENTS,
        selector: "Enrollments",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        exportcellproperty: { order: 0 },
        exportcell: "Enrollments",
        tableIndex: 0,
        cell: (row) => {
          return row.Enrollments;
        },
      },
      {
        name: this.props.labels.labels.LBLTRAINED,
        selector: "Trained",
        filter: { type: "number", showField: false, checked: false },
        sortable: true,
        compact: true,
        exportcellproperty: { order: 0 },
        exportcell: "Trained",
        tableIndex: 0,
        cell: (row) => {
          return parseInt(row.Trained * 10) / 10 + " %";
        },
      },
      {
        name: this.props.labels.labels.LBLCOURSENAME,
        selector: "name",
        cntid:"Course_Name",
        filter: { type: this.props.showunits_certificate == 0 ?"string": "stringsingle", checkId:"course_names", changeEvent:true, showField: true, checked: true },
        filterData: this.state.courses,
        width: "30%",
        exportcell: "_products_name",
        exportcellproperty: { order: 300 },
        tableIndex: 1,
        cell: (row) => {
          return (
            <>
              {row.ActUnitsCount > 1 ? (
                <>
                  <i
                    id={row.product_id}
                    onClick={this.toggletree.bind(
                      this,
                      row.product_id + "-" + row.user_id
                    )}
                    style={{ float: "left", marginTop: "3px" }}
                    class={
                      this.state.toggleunitstate[
                        row.product_id + "-" + row.user_id
                      ]
                        ? "fa fa-minus"
                        : "fa fa-plus"
                    }
                    aria-hidden="true"
                  ></i>
                </>
              ) : (
                <>
                  <i
                    id={row.product_id}
                    style={{ float: "left", visibility: "hidden" }}
                    class={
                      this.state.toggleunitstate[
                        row.product_id + "-" + row.user_id
                      ]
                        ? "fa fa-minus"
                        : "fa fa-minus"
                    }
                    aria-hidden="true"
                  ></i>
                </>
              )}
              <span className="courseName rle-brdr">{row.name}</span>
            </>
          );
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLETEDTHROUGH,
        selector: "xtraining_type",
        filter: { type: "string", showField: true, checked: false },
        width: "30%",
        exportcell: "_products_xtraining_type",
        exportcellproperty: { order: 0 },
        tableIndex: 1,
        cell: (row) => {
          return row.ActUnitsCount === 1
            ? this.props.labels.labels[
            "LBL" + row.units[0].training_type.toString().toUpperCase()
            ]
            : "";
        },
      },
      {
        name: this.props.labels.labels.LBLSTARTDATE,
        selector: "start_date",
        filter: { type: "date", showField: true, checked: false },
        width: "30%",
        exportcell: "_products_start_date_dateformat",
        exportcellproperty: { order: 0 },
        tableIndex: 1,
        cell: (row) => {
          return row.start_date_format;
        },
      },
      {
        name: this.props.labels.labels.LBLCOMPLETIONDATE,
        selector: "completed_date",
        filter: { type: "date", showField: true, checked: false },
        width: "30%",
        exportcell: "_products_completed_date_dateformat",
        exportcellproperty: { order: 0 },
        tableIndex: 1,
        cell: (row) => {
          return row.completed_date_format;
        },
      },
      {
        name: this.props.labels.labels.LBLTIMESPENT,
        selector: "total_timespent",
        filter: { type: "string", showField: true, checked: false },
        width: "30%",
        exportcell: "_products_total_timespent_timeformat",
        exportcellproperty: { order: 0 },
        tableIndex: 1,
        cell: (row) => {
          return (row.total_timespent || "").split(".")[0];
        },
      },
      {
        name: this.props.labels.labels.LBLCOURSESTATUS,
        selector: "status",
        filter: { type: "string", showField: false, checked: false },
        filterData: this.state.coursestatus,
        exportcell: "_products_status",
        exportcellproperty: { order: 0 },
        tableIndex: 1,
        cell: (row) => {
          return this.props.labels.labels["LBL" + row.status.toUpperCase()];
        },
      },
      {
        name: this.props.labels.labels.LBLPROGRESS,
        selector: "Progress",
        filter: { type: "string", showField: false, checked: false },
        filterData: this.state.courses,
        exportcell: "_products_Progress",
        exportcellproperty: { order: 0 },
        tableIndex: 1,
        cell: (row) => {
          return parseInt(row.Progress * 10) / 10 + " %";
        },
      },
      {
        name: LBLUNITCOMPLETEDTHROUGH,
        selector: "training_type",
        filter: { type: "string", showField: true, checked: false },
        exportcell: "_products_units_training_type",
        exportcellproperty: { order: 500 },
        tableIndex: 2,
        exportlabels: {
          LBLCLASSROOM: this.props.labels.labels["LBLCLASSROOM"],
          LBLONLINE: this.props.labels.labels["LBLONLINE"],
          LBLONLINEANDCLASSROOM:
            this.props.labels.labels["LBLONLINEANDCLASSROOM"],
        },
        cell: (row) => {
          return this.props.labels.labels[
            "LBL" + row.training_type.toUpperCase()
          ];
        },
      },
      {
        name: LBLUNITSTARTDATE,
        selector: "start_date",
        filter: { type: "date", showField: true, checked: false },
        exportcell: "_products_units_start_date_dateformat",
        exportcellproperty: { order: 600 },
        tableIndex: 2,
        cell: (row) => {
          return row.start_date_format;
        },
      },
      {
        name:  LBLUNITCOMPLETIONDATE, 
        selector: "completed_date",
        filter: { type: "date", showField: true, checked: false },
        exportcell: "_products_units_completed_date_dateformat",
        exportcellproperty: { order: 700 },
        tableIndex: 2,
        cell: (row) => {
          return row.completed_date_format;
        },
      },
      {
        name: LBLUNITTIMESPENT, 
        selector: "total_units_timespent",
        filter: { type: "string", showField: true, checked: false },
        exportcell: "_products_units_total_units_timespent_timeformat",
        exportcellproperty: { order: 800 },
        tableIndex: 2,
        cell: (row) => {
          return (row.total_units_timespent || "").split(".")[0];
        },
      },
      {
        name: LBLUNITSTATUS,
        selector: "unitstatus",
        filter: { type: "string", showField: false, checked: false },
        filterData: this.state.coursestatus,
        exportcell: "_products_units_status",
        exportcellproperty: { order: 900 },
        tableIndex: 2,
        cell: (row) => {
          return this.props.labels.labels["LBL" + row.status.toUpperCase()];
        },
      },
    ];
    let idx = columns.findIndex(item => item.selector === "unitdescription"); 
    if (idx < 0 && this.props.showunits_certificate == 1) {
      columns.push(
        {
          name: LBLUNITNAME,
          selector: "unitdescription",
          cntid:"Unit_Name",
          filter: { type: "string", showField: true, nosort : true, checked: true },
          filterData: this.state.courseunits, 
          exportcell: "_products_units_unitdescription",
          exportcellproperty: { order: 400 },
          tableIndex: 2,
          cell: (row) => {
            return (
              <div>
                <span className="unitName rle-brdr">{row.unitdescription}</span>
              </div>
            );
          },
        })
    }
    let xcolumns = [];
    let response = this.state.groupTypeNames || [];

    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          cntid:"group_"+i,
          filter: { type: "string", showField: true, checked: true },
          filterData: this.state.groups[response[i].group_type_id],
          exportcellproperty: { order: 200 + i },
          sortable: true,
          compact: true,
          tableIndex: 0,
          cell: (row, condition) => {
            return row[condition.selector];
          },
        });
      }
    }

    columns.splice.apply(columns, [2, 0].concat(xcolumns)); 
    return columns; //JSON.parse(JSON.stringify(columns))
  };

  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols)); 
    let xcols = []; 
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "LCC_D1") {
      let xobj = objFilterData.availableLCC_D1Fields;
      //console.log("getFilterColumns-xobj", xobj)
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    }
    //console.log("getFilterColumns-222", mode, xcols, cols)
    gConditions = xcols;
    return xcols;
  }; 
  onReset = () =>{
    $("#Unit_Name").css({"display":"none"});
    $("#Module_Name").css({"display":"none"}); 
  }
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];  
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    this.setUnitsFilter(1);  
    return xcols;
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      //console.log(row[field])
      if (row[field] && row[field] !== 1 && row[field] !== 0) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  checkFilterCondition = (filters, data) => {
    //console.log("checkFilterCondition 0", filters, data);
    for (var i in filters) {
      if (filters[i].condition && filters[i].checked) {
        let dvalue = data[filters[i].selector];
        let fdvalue = [];
        let fselector = filters[i].selector.split(",");
        for (var xi = 0; xi < fselector.length; xi++) {
          fdvalue.push(data[fselector[xi]]);
        }
        dvalue = fdvalue.join(" ");
        let cvalue = filters[i].condition.value;
        let xr = false;
        let dtime;
        let ctime;
        let ctime1;
        let ctime2;
        if (filters[i].type === "string") {

          if (filters[i].condition.operator === "CONTAINS") {
            //cvalue != "" && dvalue != null &&
            if (cvalue === "") {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (
              dvalue.toLowerCase().split(cvalue.toLowerCase()).length > 1
            ) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "DOES_NOT_CONTAIN") {
            if (cvalue === "") {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (
              dvalue.toLowerCase().split(cvalue.toLowerCase()).length === 1
            ) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "EQUALS") {
            if (cvalue === "") {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue.toLowerCase() === cvalue.toLowerCase()) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "NOT_EQUALS") {
            if (cvalue === "") {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue.toLowerCase() !== cvalue.toLowerCase()) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "MULTI_EQUALS") {
            for (let xOb in cvalue) {
              if (dvalue.toLowerCase() === cvalue[xOb].value.toLowerCase()) {
                xr = true;
                break;
              }
            }
            xr = false;
          }
        } else if (filters[i].type === "number") {
          if (filters[i].condition.operator === "EQUALS") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue === cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "NOT_EQUALS") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue !== cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "GREATER_THAN") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue > cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "GREATER_THAN_EQUALS") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue >= cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "LESS_THAN") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue < cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "LESS_THAN_EQUALS") {
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue <= cvalue) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "BETWEEN") {
            var min = Math.min.apply(Math, cvalue),
              max = Math.max.apply(Math, cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue > min && dvalue < max) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "NOT_BETWEEN") {
            var minTwo = Math.min.apply(Math, cvalue),
              maxTwo = Math.max.apply(Math, cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue == null) {
              xr = false;
            } else if (dvalue < minTwo || dvalue > maxTwo) {
              xr = true;
            }
          }
        } else if (filters[i].type === "date") {
          var xgetTime = function (x) {
            let dx = new Date(x);
            dx.setHours(0);
            dx.setMinutes(0);
            dx.setMinutes(0);
            dx.setSeconds(0);
            dx.setMilliseconds(0);
            return dx.getTime();
          };
          let dayscondition =
            "BETWEEN,LAST7DAYS,LAST30DAYS,THISMONTH,LASTMONTH";
          let daycondition = "TODAY,YESTERDAY,EQUALS";
          if (daycondition.indexOf(filters[i].condition.operator) > -1) {
            dtime = xgetTime(dvalue);
            ctime = xgetTime(cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null || cvalue === null) {
              xr = false;
            } else if (dtime === ctime) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "GREATER_THAN") {
            dtime = xgetTime(dvalue);
            ctime = xgetTime(cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null) {
              xr = false;
            } else if (dtime > ctime) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "LESS_THAN") {
            dtime = xgetTime(dvalue);
            ctime = xgetTime(cvalue);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null) {
              xr = false;
            } else if (dtime < ctime) {
              xr = true;
            }
          } else if (
            dayscondition.indexOf(filters[i].condition.operator) > -1
          ) {
            dtime = xgetTime(dvalue);
            ctime1 = xgetTime(cvalue[0]);
            ctime2 = xgetTime(cvalue[1]);
            var minThree = Math.min.apply(Math, [ctime1, ctime2]);
            var maxThree = Math.max.apply(Math, [ctime1, ctime2]);
            //console.log("min,max,dvalue,", minThree, maxThree, dtime);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null) {
              xr = false;
            } else if (dtime >= minThree && dtime <= maxThree) {
              xr = true;
            }
          } else if (filters[i].condition.operator === "NOT_BETWEEN") {
            dtime = xgetTime(dvalue);
            ctime1 = xgetTime(cvalue[0]);
            ctime2 = xgetTime(cvalue[1]);
            var minFour = Math.min.apply(Math, [ctime1, ctime2]),
              maxFour = Math.max.apply(Math, [ctime1, ctime2]);
            if (cvalue === "" || cvalue == null) {
              xr = true;
            } else if (dvalue === null) {
              xr = false;
            } else if (dtime < minFour || dtime > maxFour) {
              xr = true;
            }
          }
        }
        if (xr === false) {
          return false;
        }
      }
    }
    return true;
  };

  onExport = (type, id, conditions) => {
    //console.log("onExport", type, id, conditions)
    if (id === "F1") {
      this.setFilterFields("F1", conditions);

      let data = { type: type, columns: objFilterData.availableLCC_D1Fields };
      data.dateformat = this.props.dateformat;
      data.timeformat = this.props.timeformat;
      data.filtercolumns = conditions;
      if (type === "web") {
        this.getLCC_D1_List(data);
      } else {
        this.getLCC_D1_Export(data);
      }
    }
  };
  setFilterFields = (id, conditions) => {
    //console.log("!!!@@@@setFilterFields", id, conditions)
    var xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportcellproperty) {
        xobj[conditions[i].name].exportcellproperty =
          conditions[i].exportcellproperty;
      }
      if (conditions[i].exportlabels) {
        xobj[conditions[i].name].exportlabels = conditions[i].exportlabels;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableLCC_D1Fields = xobj;
    }
  };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions, index) => { 
    //console.log("onFilterChangeeeeee2", conditions); 
    this.setFilterFields("F1", conditions);
    let data = { type: "web", columns: objFilterData.availableLCC_D1Fields };
    data.filtercolumns = conditions;
    gConditions = conditions; 
    this.getLCC_D1_List(data); 
  };

  RunReport = () => {
    REFS.F1.onRunReport(); 
  };
  handleClose = () => {
    this.setState({ showCert: false });
    document.getElementById("root").className = "";
  };
  handlePrint = (xid) => {
    if (xid === "PRINTDIV") {
      document.getElementById("hideprint").style.display = "none";
    }

    this.setState({ showPrint: false }, function () {
      var print_div = document.getElementById(xid);
      var newWindow = window.open();
      newWindow.document.write("<!DOCTYPE html");
      newWindow.document.write("<html>");
      newWindow.document.write("<head>");
      //newWindow.document.write("<link href=\"src/admin/pages/reports/report.css\" rel=\"stylesheet\" />");
      //newWindow.document.write("<style>.userwinners{margin-top: 51px;margin-left: 0;} .no-Print{display:none;}  @media print {.no-Print{display:none;} title {display:none;} }</style>");
      newWindow.document.write("</head>");
      newWindow.document.write("<body>");
      newWindow.document.write(print_div.innerHTML);
      newWindow.document.write("</body>");
      newWindow.document.write("</html>");
      newWindow.document.write(
        "<script>window.onload = function(e){window.print();window.close();}</script>"
      );
      // Append the new container to the body of the new window
      const parentHead = window.document.querySelector("head").childNodes;
      parentHead.forEach((item) => {
        newWindow.document.head.appendChild(item.cloneNode(true)); // deep copy
      });
      newWindow.document.close();
      newWindow.focus();
      // newWindow.print();
      // newWindow.close();
      if (xid === "PRINTDIV") {
        document.getElementById("hideprint").style.display = "";
      }
    });
  }; 
  render() {
    const {
      LBLRUNREPORT,
      LBLLOADINGREPORT,
      LBLRECORDS,
    } = this.props.labels.labels; 
    if (this.state.pageState === "LCC_D1") {
      //const { LCC_D1_List } = this.state;
      let columns = this.getLCC_D1_Columns();
      let filterFields = this.getFilterColumns("LCC_D1", columns);
      let resetfilterFields = this.getResetFilterColumns("LCC_D1", columns); 
      objFilterData.orgavailableLCC_D1Fields = this.getFilterColumns(
        "LCC_D1",
        this.getLCC_D1_Columns()
      );
      this.showHideTitle();
      const { labels } = this.props.labels;
      let icount = 0;
      let allItems = this.state.LCC_D1_List;
      let idx = 0, isEmpty = false, lenList = allItems.length;
      if (this.props.showunits_certificate == 0) {
        allItems.length > 0 && allItems.map(item => {
          if (item.products[0].status !== "Completed") { idx++; }
          return null;
        });
        isEmpty = (lenList === idx) ? true : isEmpty;
      }
      return (
        <div className="reportsCtn reportsCertificate">
          <div className="mob-pad">
            <div >
              <h3 className="page-title">{this.props.labels.labels.LBLLEARNERCOURSECERTIFICATES}</h3>
            </div>
            <hr className="head-line" />
            <div id="accordion" className="row rptlst-row">
              {this.state.loading ? (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    <div>
                      <div>
                        <div>
                          <div className="row box ml-1">
                            <Skeleton height={46} />
                          </div>
                          <div className="row col-sm-12 prowfilterbox"></div>
                        </div>
                        <div>
                          <h3>
                            <Skeleton height={21} />
                          </h3>
                          <h2>
                            <Skeleton height={26} />
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div className="print_hide">
                    {this.state.loading === false &&
                      this.state.filterDataLoaded === 1 &&
                      filterFields.length > 3 ? (
                      <div>
                        <CustomFilter
                          availableFields={filterFields}
                          selectedFields={filterFields}
                          orgAvailableFields={resetfilterFields}
                          onFilterChange={this.onFilterChange} 
                          onReset ={this.onReset}
                          onChanges = {this.props.showunits_certificate?this.onCheckChanges:""}
                          onSetVals = {this.props.showunits_certificate?this.setOpen:""}
                          onExport={this.onExport}
                          id="F1"
                          value={this.state.value} 
                          onRef={(ref) => (REFS.F1 = ref)}
                          labels={this.props.labels.labels}
                          server={true}
                          reportName={
                            this.props.labels.labels
                              .LBLLEARNERCOURSECERTIFICATES
                          }
                          onClickFilter={this.onClickFilter}
                          companyName={this.props.company_name}
                          showFieldFilter={false}
                          changeMode={this.state.changeMode}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                 
                  {this.state.LCC_D1_List_Fetched === 0 ? (
                    <div style={{ textAlign: "center", padding: "30px" }}>
                      <button
                        onClick={this.RunReport.bind(this)}
                        id="btnGroupDrop122"
                        type="button"
                        className="button-style primary-btn"
                      >
                        {/* <i className="fas fa-table" aria-hidden="true"></i>  */}
                        {LBLRUNREPORT}
                      </button>
                    </div>
                  ) : (
                    <>
                      {this.state.LCC_D1_List_Fetched !== 0 &&
                        this.state.reportloading === false ? (
                        <>
                          <div className="bottomborder print_hide">
                            <span
                              className="subHeader"
                              style={{ display: "inline-block" }}
                            >
                              <h4 style={{ display: isEmpty ? "none" : "" }}>
                                {LBLRECORDS} :{" "}
                                { this.state.totalRecords }
                              </h4>
                            </span>
                          </div>
                          {this.state.LCC_D1_List.length === 0 || isEmpty ? (
                            <EmptyState image={emptyImage} text2={this.props.labels.labels.LBLLEARNERCRSPROGEXPTYTXT}
                            />
                          ) : (
                            <>
                              <div id="PRINTDIV">
                                {this.state.LCC_D1_List.map((item, i) => {
                                   if(item.length==i){
                                    inc++;
                                  } 
                                  //console.log("SSSSS==>>", i);  
                                  let userData = {
                                    userid: item.userid,
                                    first_name: item.first_name,
                                    last_name: item.last_name,
                                    dateformat: this.props.dateformat,
                                  };
                                  return (
                                    <>
                                  
                                      {item.products.map((prditem, i) => {
                                        let showunits_certificate = prditem.showunits_certificate;
                                        if (showunits_certificate == 0) { // && prditem.product_id == "TSE00ACTUSE0002"
                                          let prductitem1 = prditem;
                                          delete prductitem1.units;
                                          prditem.units = [];
                                          prductitem1.CourseCompletionDate = prditem.completed_date;
                                          prductitem1.name = prditem.name;
                                          prductitem1.unitdescription = prditem.name;
                                          if (prditem.status === "Completed") { 
                                            prductitem1.cert_type=1;
                                            prditem.units.push(prductitem1);
                                          }
                                        }else if (showunits_certificate == 1) { // && prditem.product_id == "TSE00ACTUSE0002"
                                          let prductitem1 = prditem; 
                                          prductitem1.product_id = prditem.product_id;
                                          prductitem1.unit_id = prditem.product_id;
                                          prductitem1.CourseCompletionDate = prditem.completed_date;
                                          prductitem1.name = prditem.name;
                                          prductitem1.unitdescription = prditem.name; 
                                          if (prditem.status === "Completed") { 
                                            if (prditem.units.findIndex(a=>a.product_id == prditem.product_id && a.name == prditem.name)<0) {
                                              prductitem1.cert_type=1; 
                                              prditem.units.push(prductitem1);
                                            } 
                                          }
                                        }
                                        return (
                                          <>
                                            {prditem.units
                                              //.sort(unitordersort)
                                              .map((untitem, i) => {
                                                //console.log(untitem,"untitem")
                                                icount++;
                                                let productData = {};
                                                productData.unique_id =
                                                  item.userid +  "-" + (icount) +
                                                  "-" +
                                                  prditem.product_id +
                                                  "-" +
                                                  untitem.unit_id;
                                                productData.coursetype = !untitem.unit_id || (untitem.unit_id==prditem.product_id)?1:2;  
                                                productData.coursename = prditem.name;
                                                productData.CourseCompletionDate =
                                                  untitem.completed_date;
                                                productData.name = 
                                                  untitem.unitname; 
                                                productData.description =
                                                  untitem.unitdescription;
                                                productData.cert_type = untitem.cert_type?untitem.cert_type:2;  
                                                return (
                                                  <>
                                                    {icount === 1 ? (
                                                      <>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "right",
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <div
                                                            id="hideprint"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={this.handlePrint.bind(
                                                              this,
                                                              "PRINTDIV"
                                                            )}
                                                          >
                                                            <div className="cert-printallbtn hideprint">
                                                              <img
                                                                src="../../images/print.png"
                                                                width="100%"
                                                                height="100%"
                                                                alt=""
                                                              />
                                                            </div>
                                                            {labels.LBLPRINTALL}
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    <div
                                                      id={productData.unique_id}
                                                      className={
                                                        icount > 1
                                                          ? "pagebreak"
                                                          : ""
                                                      }
                                                    >
                                                      <CCCertificate
                                                        handleClose={
                                                          this.handleClose
                                                        }
                                                        labels={labels}
                                                        userData={userData}
                                                        productData={
                                                          productData
                                                        }
                                                        isReport={1}
                                                        company_logo={
                                                          this.state
                                                            .company_logo
                                                        }
                                                        company_aws_logo_path={
                                                          this.state
                                                            .company_aws_logo_path
                                                        }
                                                        icount={icount}
                                                      />
                                                    </div>
                                                  </>
                                                );
                                              })}
                                          </>
                                        );
                                      })}
                                    </>
                                  );
                                })}
                              </div>
                              {this.state.orgLCC_D1_List.length >
                                this.state.LCC_D1_List.length && inc<2? (
                                <>
                                  <Loader></Loader> {LBLLOADINGREPORT}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Loader></Loader> {LBLLOADINGREPORT}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div>No Data for {this.state.pageState}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
    showunits_certificate: state.user_info_reducer.user_info.showunits_certificate
  };
};

export default connect(mapStateToProps, null)(LCCReport);
//export default LCCReport;

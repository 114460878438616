import React, { useState } from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import white from '../../../assets/images/karate/White_Belt.json'
import yellow from '../../../assets/images/karate/Yellow_Belt.json'
import orange from '../../../assets/images/karate/Orange_Belt.json'
import green from '../../../assets/images/karate/Green_Belt.json'
import brown from '../../../assets/images/karate/Brown_Belt.json'
import blue from '../../../assets/images/karate/Blue_Belt.json'
import black from '../../../assets/images/karate/Black_Belt.json'
import whiteBeltOuter from '../../../assets/images/karate/beltoutercircle/white.png'
import yellowBeltOuter from '../../../assets/images/karate/beltoutercircle/yellow.png'
import orangeBeltOuter from '../../../assets/images/karate/beltoutercircle/orange.png'
import greenBeltOuter from '../../../assets/images/karate/beltoutercircle/green.png'
import brownBeltOuter from '../../../assets/images/karate/beltoutercircle/brown.png'
import blueBeltOuter from '../../../assets/images/karate/beltoutercircle/blue.png'
import blackBeltOuter from '../../../assets/images/karate/beltoutercircle/black.png'

function BadgesModal({ badges, close, title }) {
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false);
        close();
    };
    const belts = {
        "white": white,
        "yellow": yellow,
        "orange": orange,
        "green": green,
        "brown": brown,
        "blue": blue,
        "black": black
    }
    const beltOuter = {
        "white": whiteBeltOuter,
        "yellow": yellowBeltOuter,
        "orange": orangeBeltOuter,
        "green": greenBeltOuter,
        "brown": brownBeltOuter,
        "blue": blueBeltOuter,
        "black": blackBeltOuter
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className='achievHeader'>
                <ArrowBackIosIcon style={{ cursor: 'pointer', marginRight: '5px', marginTop: '3px' }} fontSize="16" onClick={handleClose} />
                <Modal.Title style={{ fontSize: 'inherit' }}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-wrap" style={{ justifyContent: 'space-between' }}>
                    {badges && badges.map((item, index) => (
                        <div key={index} className={`p-2 achievCnt ${item?.earned_badge !== 2 ? 'card-overlay' : ''} `}>
                            <div className='karateWrapper achiev'>
                                <div className='karateImgContainer'>
                                    <div className="circle-container">
                                        <img className="circle" src={beltOuter[item.badge_animation]} alt="Circular Image" />
                                        <div className="gif">
                                            <img className='karateImage' src={require(`../../../assets/images/karate/Badges/${item.badge_animation}.gif`)} alt="Badge Image" />

                                            {/* <Lottie options={defaultOptions} className='karateImage' /> */}
                                        </div>
                                        <img className="status" src={require(`../../../assets/images/karate/${item?.earned_badge && item.earned_badge === 2 ? 'completed.png' : 'locked.png'}`)} />
                                    </div>

                                    <span className='badgeName'>{item.badge_name}</span>
                                    <span className='badgeDesc'>{item.badge_description}</span>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default BadgesModal;

import { HttpInterceptor } from '../../../auth/HttpInterceptor';
import GroupService from '../group-list/GroupServices';

class UsersService {
    async getUsers() {
        let url = '';
        if (arguments[0]['active']) {
            url = 'users/?active=0';
        } else {
            url = "/users";
        }
        const response = await HttpInterceptor.get(url, { data: { "requestMethod": "getUsers" } });
        return response.data;
    }
    async getNotifyingUsers() {
        let url = '';
        if (arguments[0]['active']) {
            url = 'notifyingusers/?active=0';
        } else {
            url = "notifyingusers";
        }
        const response = await HttpInterceptor.get(url, { data: { "requestMethod": "getNotifyingUsers" } });
        return response.data;
    }
    async usersHelpers(getData) {
        let sGroups, sWildCard, url = 'usershelpers';
        let data = { "requestMethod": "getUsers", "requestLanguage": getData.language }
        if (getData.wildCard == "*") {
            data["requestWildCard"] = "*"
        }
        if (getData.allGroups === "1") {
            url = url + "?allgroups=" + getData.allGroups
        }
        const response = await HttpInterceptor.get(url, { data: data });
        sWildCard = response.data.groups.wildcard_access
        if (response.data.groups) {
            let syncelabels = await GroupService.syncGrouplabels(response.data.groups);
            response.data.groups = syncelabels;
            if (sWildCard) {
                response.data.wildcard_access = sWildCard;
            }
            return response.data;
        } else {
            return response.data;

        }
    }
    
    async postUsers(data) {
        let url = 'users';
        const response = await HttpInterceptor.post(url, data);
        return response;
    }
    async editUsers(data) {
        let url = 'users';
        const response = await HttpInterceptor.put(url, data);
        return response;
    }
    async checksteercomuser(data, blnexecute) {
        let url = 'checksteercomuser/' + data.userid + '/' + 101;
        let response = 1;
        if (blnexecute) {
            response = await HttpInterceptor.get(url, { data: { "requestMethod": "checksteercomuser" } });
        }
        return response;
    }
    async generatePassword(n) {
        let url = 'generate_password?count=1'
        const response = await HttpInterceptor.get(url);
        return response;
    }
    //Get assigned channels
    async getChannels(data) {
        let url = 'channels/?get_managing_channels=1&pointer=' + data.user_id;
        try {
            const channels = await HttpInterceptor["get"](process.env.REACT_APP_FEEDAPI_URL + url, data);
            //console.log(channels);
            return channels.data;
        }
        catch (e) {
            console.log("errors on feeds chaneels", e)
        }
    }
    //Add or update managing channels
    async manageAssignedChannels(data) {
        let url = 'ssiprivileges/channels';
        try {
            const channels = await HttpInterceptor.put(url, data)
            return channels;
        }
        catch (e) {
            console.log("errors on feeds chaneels", e)
        }
    }
}

export default new UsersService();

import React from "react";
import { connect } from "react-redux";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import UsersService from "../user-list/UsersService";
import { TextField } from "@material-ui/core";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import DataTable from "react-data-table-component";
import "date-fns";
import Skeleton from "react-loading-skeleton";
import Alert from "@material-ui/lab/Alert";
import { Modal } from "react-bootstrap";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import ReferenceMaterial from "./referencematerials";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "../../components/loader/Loader";
import * as FileSaver from "file-saver";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./successfactors.scss";
import HighchartsReact from "highcharts-react-official";
import Loading from "../../../shared/Loading";
import Highcharts from "highcharts";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
//import { enUS,itIT,esES,deDE, ptPT, ptBR, frFR,svSE, etEE, faIR, fiFI ,ruRU} from '@material-ui/core/locale';
import * as  locales from '@material-ui/core/locale';
import emptyImage from '../../../assets/images/learnerprogress_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
 
let theme;// = createTheme(  enUS); 
let lang = locales['enUS'];
const Excel = require('exceljs');
const $ = require("jquery");

let parentask = "";
let g_sfid = "";
let showMessage1 = ""
class SuccessFactorPlan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showGantPopup: false,
      setEditFlag: 0,
      allTeamSuccessFactorList: [],
      completion_percentage: "a",
      role_id: props.role,
      showmodal: false,
      initialValues: [],
      successFactorDataChart: [],
      user_id: props.user_id,
      opendialog3: false,
      steercomSettings: {},
      currentTaskStatus: "YETTOSTART",
      ctstatus: false,
      teamList: [],
      teamlistcnt: false,
      expandFlag: false,
      userlist: [],
      firstLoad: true,
      dateformat: props.dateformat,
      successFactorsList: {},
      teamData: {},
      teamRolesList: [],
      teamMembersList: [],
      showMessage1: "",
      successFactorData: {},
      teamSuccessFactorList: [],
      email_notification: null,
      editingTeamSF: {
        M_NEW: {
          teamId: null,
        },
      },
      loading: false,
      showtime: 9000,
      openalert: false,
      isPriorTeam: false,
      showPriorMemebers: true,
      teamSFListLoading: false,
      memberIdOpenState: "",
      memberSteercomRoleIdOpenState: "",
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
      mobView: false
    };
    lang = this.props.language_code.replace('-','');
    lang =  locales[lang];
    theme = createTheme( lang);
  }
  componentWillUnmount() {
    $("#root").css("height", "100%");
    $("#root").css("overflow", "auto");
  }
  componentDidMount() {
    $('#root').scrollTop(0);
    $('#root').scrollTop(0);

    $("#root").css("height", "100%");
    $("#root").css("border", "0px solid red");
    $("#root").css("overflow", "hidden");
    $(".cls_add_teams").show();
    var header = this.props.labels.labels.LBLSTEERCOMSFTRACK;
    $("#sub_header_det_plan").css("display", "none");
    if (this.props.location.state && this.props.location.state.parentId) {
      parentask = this.props.location.state.parentId;
    }
    this.setdateformat();
    this.getCompanySettings();
    this.getSteercomSettings();
    //this.getTeamList();
    this.getUsers();
    this.callScroll();
    let mobView = this.isMobileView();//navigator.userAgentData.mobile;
    this.setState({
      mobView: mobView
    });
  }
  rowReorder = () => {
    $('.rdt_TableBody').sortable({
      stop: function (event, ui) {
        var prev = $(ui.item).prev('div.rdt_TableRow').attr('id');
        var next = $(ui.item).next('div.rdt_TableRow').attr('id');
        console.log('prev = ' + prev + ' ' + $(ui.item).attr('id') + ' next = ' + next);
      }
    });
  }
  isMobileView = () => {
    let mView = false;
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone|Opera Mini/i.test(navigator.userAgent)
      || window.screen.availWidth < 750 || document.body.clientWidth < 750) {
      mView = true;
    }
    return mView;
  }
  componentDidUpdate() {
    if (document.getElementsByClassName("rdt_TableBody").length > 0) {
      //alert(document.getElementsByClassName("rdt_TableBody")[0].offsetTop)
    }
    let height = 148, mobView = this.isMobileView();
    height = mobView ? 48 : height;
    $(".rdt_TableBody").css({ height: $("#root").height() - (height) + "px" }); //288
    $(".rdt_TableBody").scroll(function () {
      $("#minipop").hide();
      $("input").blur();
      var scrollLeft = $(this).scrollLeft();
      $(".rdt_TableHead").scrollLeft(scrollLeft);
    });
    $("body").on("onresize", function () {
      $(".divDataTable").css({
        width: $(".main-content").width() - 10 + "px",
        transition: "0.5s",
      });
    });
    if (navigator.userAgent.indexOf("Firefox") != -1) {
      $(".cls_add_expand_collapse").css({ "top": "0px" })
    }
   // this.rowReorder();
  }
  callScroll = () => {
    (function (H) {
      //internal functions
      function stopEvent(e) {
        if (e) {
          if (e.preventDefault) {
            e.preventDefault();
          }
          if (e.stopPropagation) {
            e.stopPropagation();
          }
          e.cancelBubble = true;
        }
      }

      //the wrap
      H.wrap(H.Chart.prototype, 'render', function (proceed) {
        var chart = this,
          mapNavigation = chart.options.mapNavigation;

        proceed.call(chart);

        // Add the mousewheel event
        H.addEvent(chart.container, document.onmousewheel === undefined ? 'DOMMouseScroll' : 'mousewheel', function (event) {

          var delta, extr, step, newMin, newMax, axis = chart.yAxis[0];

          let e = chart.pointer.normalize(event);
          // Firefox uses e.detail, WebKit and IE uses wheelDelta
          delta = e.detail || -(e.wheelDelta / 120);
          delta = delta < 0 ? -1 : 1;

          if (chart.isInsidePlot(e.chartX - chart.plotLeft, e.chartY - chart.plotTop)) {
            extr = axis.getExtremes();
            step = (extr.max - extr.min) / 20 * delta;
            newMin = extr.min + step;
            newMax = extr.max + step;
            console.log('max', extr.max)
            console.log('min', extr.min)

            if (newMin > axis.dataMin && newMax < axis.dataMax) {
              axis.setExtremes(newMin, newMax, true, false);

            }

          }

          stopEvent(event); // Issue #5011, returning false from non-jQuery event does not prevent default
          return false;
        });
      });
    }(Highcharts));

  }
  getUsers = () => {
    UsersService.getUsers({ active: true })
      .then((res) => {
        //this.sortResults(res.result,"name",true);

        this.setState((state, props) => ({
          userlist: res.result
        }));
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  setContent = () => {
    setTimeout(() => {
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("aside").addClass("slideOutLeft");
      $("#sidenav").css({ display: "none" });
    }, 100);
  };
  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    for (let x in steercomSettings) {
      steercomSettings[x].map((item, index) => {
        if (item.name && item.name.indexOf("LBL") == 0) {
          item.name = this.getLabel(item.name);
        }
        if (item.title && item.title.indexOf("LBL") == 0) {
          item.title = this.getLabel(item.title);
        }
        if (item.description && item.description.indexOf("LBL") == 0) {
          item.description = this.getLabel(item.description);
        }
      });
    }
    let obj = steercomSettings.successfactors.find(v => v.name === 'LBLALL');
    if (obj == undefined || obj.length == 0) {
      steercomSettings.successfactors.unshift({
        active: 1,
        color: "sf_color1",
        description: "",
        id: "0",
        name: "LBLALL",
        title: this.props.labels.labels.LBLALL,
        version: "1"
      });
    }
    if (this.props.location.state && this.props.location.state.successFactorData) { 
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: this.props.location.state.successFactorData,
      }, this.getTeamList());
    } else {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: steercomSettings.successfactors[0],
        successFactorDataChart: steercomSettings.successfactors[0],
      }, this.getTeamList());
    } 
  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  getTeamList = () => {
    let apiUrl = "steercomteams"; 
    this.getTeamListCallback();
  };
  getTeamListCallback = async () => {
    let res = await SteercomServices.getTeamListPlan();
    let teamlist_active = [], teamList = res.result;
    teamlist_active = teamList.filter((content, i) => {
      return content.team_status == 1 || content.team_status == null;
    })
    let filterTeamList = teamlist_active.filter((item) => {
      if (this.props.role_id > 1) {
        if (item.team_administrator == this.props.userId ||
          this.props.userId == item.team_addnl_administrator) {
          return item;
        } else if (item.memberofteamsf > 0) {
          return item;
        }
      } else { 
        return item;
      }
    });
    let listCnt = filterTeamList.length > 0 ? false : true;
    this.setState(
      {
        teamList: filterTeamList,
        // teamData:filterTeamList[0],
        teamlistcnt: listCnt,
        loading: listCnt,
      },
      () => {

        if (this.props.location.state && this.props.location.state.teamData) {
          let locteamid = this.props.location.state.teamData.team_id;
          var teamData = locteamid && filterTeamList.find(function (items) {
            return items.team_id == locteamid;
          });
          if (teamData == undefined || teamData == null) {
            teamData = filterTeamList[0];
          } else {
            // teamData = this.props.location.state.teamData; 
          }
          if (!teamData)
            teamData = [];
          this.setState({
            teamData: teamData,//filterTeamList[0],
            successFactorData: this.props.location.state.successFactorData
          })
          this.getTeamMembersList(teamData.team_id);
          //this.getTeamMembersList(this.props.location.state.teamData.team_id);
        } else {
          let team_id = "";
          if (window.location.search != "") {
            team_id = window.location.search.split("=")[1];
            this.getTeamMembersList(team_id);
          } else if (filterTeamList.length > 0) {
            this.getTeamMembersList(filterTeamList[0].team_id)
          }
          this.setState(
            {
              //teamSFListLoading: true,
            }
          )

        }
      }

    );
  };
  getTeamSFList = () => {
    //alert("getTeamSFList")
    if (
      this.state.teamData &&
      this.state.teamData.team_id &&
      this.state.successFactorData &&
      this.state.successFactorData.id
    ) { 
      let teamid = this.state.teamData.team_id;
      let sfid = this.state.successFactorData.id;
      this.getTeamSFListCallback(teamid, sfid)
    }
  };
  getTeamSFListCallback = async (teamid, sfid) => {
    let res = await SteercomServices.getTeamSFListPlan(teamid, sfid);
    let filterTeamSFList, mainTasksList = [], milestones = [], base_date, teamSFList = res.result;
    if (teamSFList.length > 0) {
      base_date = teamSFList[0].base_date;
      for (let i = 0; i < teamSFList.length; i++) {
        if (this.state.firstLoad && parentask == "") {
          teamSFList[i].expandFlag = 0;
        } else if (parentask == teamSFList[i].parent_task_display_id) {
          teamSFList[i].expandFlag = 1;
        } else {
          teamSFList[i].expandFlag = 0;
        }
        teamSFList[i].expandFlag = 1;
        if (teamSFList[i].task_name_custom_translation && teamSFList[i].task_name_custom_translation != "") {
          teamSFList[i].task_name = teamSFList[i].task_name_custom_translation;
        }
        if (teamSFList[i].task_description_custom_translation && teamSFList[i].task_description_custom_translation != "") {
          teamSFList[i].task_description = teamSFList[i].task_description_custom_translation;
        }
        if (
          teamSFList[i].task_type == 1 &&
          base_date &&
          !teamSFList[i].planned_start_date
        ) {
          teamSFList[i].planned_start_date = new Date(base_date);
          teamSFList[i].planned_start_date.setDate(
            teamSFList[i].planned_start_date.getDate() +
            teamSFList[i].recommended_start_days
          );
        }
      }
      let m = 1;
      filterTeamSFList = teamSFList.filter((item) => {
        if (item.task_type == 0) {
          let childitems = teamSFList.filter((citem) => {
            if (citem.parent_task_display_id == item.task_display_id) {
              if (
                typeof citem.team_member_id == "number" ||
                typeof citem.team_member_id == "string"
              ) {
                if (this.props.role_id > 1) {
                  if (
                    this.state.teamData.team_administrator == this.props.userId ||
                    this.props.userId == this.state.teamData.team_addnl_administrator
                  ) {
                    return citem;
                  } else if (citem.member_user_id == this.props.userId) {
                    return citem;
                  }
                } else {
                  return citem;
                }
              }
            }
          });
          if (childitems.length > 0) {
            return item;
          }
        } else {
          if (
            typeof item.team_member_id == "number" ||
            typeof item.team_member_id == "string"
          ) {
            if (this.props.role_id > 1) {
              if (this.state.teamData.team_administrator == this.props.userId ||
                this.props.userId == this.state.teamData.team_addnl_administrator) {
                return item;
              } else if (item.member_user_id == this.props.userId) {
                return item;
              }
            } else {
              return item;
            }
          }
        }
      });
    } else {
      filterTeamSFList = [];
    }
    let { allTeamSuccessFactorList, editingTeamSF } = this.state;
    let exp = 0, type = 0, expand = false;
    filterTeamSFList.map((item, idx) => {
      if (item.task_type == 0) {
        type++;
        if (item.expandFlag == 1) {
          exp++;
        }
        mainTasksList.push(item);
      } 
    });
    if (type == exp) {
      expand = true;
    }

    this.sortByKeyAsc(filterTeamSFList, "parent_task_display_id");
    let loc_teamSFList = [];
    loc_teamSFList = filterTeamSFList.filter((item, idx) => {
      if (sfid == "Y1SF1") {
        if (item.success_factor_id == "Y1SF1") {
          return item;
        }
      } else if (sfid == "Y1SF2") {
        if (item.success_factor_id == "Y1SF2") {
          return item;
        }
      } else if (sfid == "Y1SF3") {
        if (item.success_factor_id == "Y1SF3") {
          return item;
        }
      } else if (sfid == "Y1SF4") {
        if (item.success_factor_id == "Y1SF4") {
          return item;
        }
      } else if (sfid == "Y1SF5") {
        if (item.success_factor_id == "Y1SF5") {
          return item;
        }
      } else {
        return item;
      }
    });
    // this.sortByKeyAsc(loc_teamSFList,"recommended_start_days");
    filterTeamSFList = loc_teamSFList;
    allTeamSuccessFactorList = allTeamSuccessFactorList.length == 0 ? loc_teamSFList : allTeamSuccessFactorList;
    this.setState({
      allTeamSuccessFactorList: allTeamSuccessFactorList,
      teamSuccessFactorList: filterTeamSFList,
      base_date: base_date,
      loading: true,
      firstLoad: false,
      expandFlag: expand,
      teamSFListLoading: true,
      mainTasksList: mainTasksList,
      milestones: milestones,
      teamlistcnt: true, 
      isloading:false
    },
      () => {
        setTimeout(() => {
          if (this.props.location.state && this.props.location.state.parentId) {
            let teamData = this.props.location.state.teamData;
            let taskItem = this.props.location.state.taskItem;
            let status, values;
            //alert(taskItem.team_name + "--" + taskItem.task_display_id)
            filterTeamSFList.map((item, idx) => {
              if (this.state.setEditFlag == 0 && taskItem.team_id == item.team_id && taskItem.task_id == item.task_id) {
                this.setState({
                  setEditFlag: 1,
                })
                this.updateEditFlag(item, 0)
              }
            })
          }
        }, 100)
      });

  };
  sortByKeyAsc = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  getTeamMembersList = (team_id) => {
    let apiUrl = "steercomteammembers/" + team_id;
    this.getTeamMembersListCallback(team_id);
  };
  getTeamMembersListCallback = async (team_id) => {
    let res = await SteercomServices.getTeamMembersListPlan(team_id);

    let teamData = res.result[0][0];
    let teamMembersList = res.result[1];
    let teamRolesList = [];
    let rolesObj = {};
    let { steercomSettings } = this.state;
    let roles = steercomSettings.roles.filter(item => item.language_code == teamData.language_code);
    for (let i = 0; i < roles.length; i++) {
      rolesObj[roles[i].id] = roles[i];
    }

    let addedRoles = {};
    for (let j = 0; j < teamMembersList.length; j++) {
      if (rolesObj.hasOwnProperty(teamMembersList[j].steercom_role_id)) {
        if (
          addedRoles.hasOwnProperty(teamMembersList[j].steercom_role_id) ==
          false
        ) {
          addedRoles[teamMembersList[j].steercom_role_id] = 1;
          teamRolesList.push(rolesObj[teamMembersList[j].steercom_role_id]);
        }
      }
    }
    this.setState(
      {
        teamData: teamData,
        teamMembersList: teamMembersList,
        teamRolesList: teamRolesList,
        expandFlag: true,// false
        steercomRoles: roles,
        showGantPopup: false
      },
      () => {
        setTimeout(() => {
          this.getTeamSFList()
        }, 100)
      }
    );
  };
  getLabel = (xfield, mode) => {
    if (xfield) {
      if (typeof xfield === "string") {
        if (this.props.labels.labels.hasOwnProperty(xfield)) {
          return this.props.labels.labels[xfield];
        } else {
          return xfield;
        }
      } else {
        if (
          typeof xfield === "object" &&
          this.props.labels.labels.hasOwnProperty(xfield.label)
        ) {
          return this.props.labels.labels[xfield.label].replace(
            "$$$",
            xfield.val
          );
        } else {
          return xfield + "111";
        }
      }
    } else {
      return xfield;
    }

  };
  getCompanySettings = () => {
    HttpInterceptor.get("settings/?language=" + this.props.language).then(
      (res) => {
        this.setState({
          email_notification: res.data.result.templates_status
        });
      }
    );
  };
  deleteSuccessFactorCallback = (res) => {
    this.getSuccessFactorsList();
  };
  deleteSuccessFactor = (item, row) => {
    if (isNaN(item.team_member_id)) {
      this.setState({
        addSuccessFactor: false,
      });
    } else {
      if (
        this.state.editingTeamSF.hasOwnProperty("M_" + item.task_display_id) ==
        true
      ) {
        let { successFactorsList, teamSuccessFactorList } = this.state;
        let editingTeamSF = this.state.editingTeamSF;
        item.isNewAssigned = 0;
        teamSuccessFactorList.map((xitem, index) => {
          if ((xitem.isNewAssigned || 0) == 1) {
            xitem.isNewAssigned = 0;
          }
        });
        this.setState({
          teamSuccessFactorList,
          editingTeamSF,
        });
      }
 
    }
  };
  saveSuccessFactor = (item) => {
    let tmpdata = this.state.editingTeamSF["M_" + item.task_display_id];
    if (
      this.state.editingTeamSF.hasOwnProperty("M_" + item.task_display_id) ==
      false
    ) {
      return false;
    }
    parentask = item.parent_task_display_id;
    var tmpSuccessFactorData = {
      team_id: tmpdata.team_id,
      success_factor_id: tmpdata.success_factor_id,
      task_display_id: tmpdata.task_display_id,
      actual_start_date: tmpdata.actual_start_date,
      actual_end_date: tmpdata.task_status=="COMPLETED"?tmpdata.actual_end_date:"",
      task_status: tmpdata.task_status,
      completion_percentage: tmpdata.completion_percentage,
      last_activity_description: $.trim(tmpdata.last_activity_description),
      planned_end_date: item.planned_end_date
    };
    let mailactive = null, taskchange = false, unassigned = false;
    let { steercomSettings, email_notification } = this.state;
    if (steercomSettings.emailsettings) {
      mailactive = (email_notification && steercomSettings.emailsettings[0].active &&
        steercomSettings.emailsettings[0].task_status_change) ? true : false;
    }

    if (this.checkValidations(tmpSuccessFactorData)) {
      let { teamData, teamSuccessFactorList, userlist } = this.state;
      let prevStatus = teamSuccessFactorList.filter(i => i.task_display_id == tmpSuccessFactorData.task_display_id);
      let maindetails = teamSuccessFactorList.filter(i => i.task_display_id == prevStatus[0].parent_task_display_id)
      tmpSuccessFactorData.notification = false;
      if (mailactive && prevStatus[0].task_status != tmpSuccessFactorData.task_status) {
        let userdetails = [], super_admin, memberDetails, memberId = this.state.editingTeamSF["M_" + item.task_display_id].oldMemberId;
        memberDetails = this.state.teamMembersList.filter((itm) => {
          return itm.team_member_id == memberId;
        });

        let user_items = [], retval = true, retval1 = true;
        userlist.map((itm) => {
          if (itm.user_id == teamData.team_administrator) {
            retval = itm.user_with_nomail == 1 ? false : (itm.email && itm.email_verified && itm.active) ? true : false
            if (retval) {
              userdetails = [];
              userdetails.push(itm);
            }
          }
          if (itm.user_id == teamData.team_addnl_administrator) {
            retval1 = itm.user_with_nomail == 1 ? false : (itm.email && itm.email_verified && itm.active) ? true : false
            if (retval1 && userdetails.length == 0) {
              userdetails.push(itm);
            }
          }
        });
        user_items = userdetails[0]; maindetails = maindetails[0]; prevStatus = prevStatus[0];
        if (userdetails.length > 0) {
          let mainTaskId, subTaskId;
          mainTaskId = prevStatus.parent_task_display_id;
          subTaskId = prevStatus.task_display_id;
          tmpSuccessFactorData.main_task = maindetails.custom_task_name ? maindetails.custom_task_name : this.props.labels.labels["LBL" + mainTaskId];
          tmpSuccessFactorData.sub_task = prevStatus.custom_task_name ? prevStatus.custom_task_name : this.props.labels.labels["LBL" + subTaskId];
          tmpSuccessFactorData.old_task_status = this.props.labels.labels["LBL" + prevStatus.task_status];
          tmpSuccessFactorData.curr_task_status = this.props.labels.labels["LBL" + tmpSuccessFactorData.task_status];
          tmpSuccessFactorData.admin_eamil = user_items.email;
          tmpSuccessFactorData.user_id = user_items.user_id;
          tmpSuccessFactorData.language_code =  user_items.language_id;
          tmpSuccessFactorData.team_admin = teamData.team_administrator;
          tmpSuccessFactorData.team_id = teamData.team_id;
          tmpSuccessFactorData.team_name = teamData.team_name;
          tmpSuccessFactorData.notification = true;
        } else {
          tmpSuccessFactorData.notification = false;
        }
      }
      this.saveSuccessFactorCallback(tmpSuccessFactorData);
    }
  };
  saveSuccessFactorCallback = async (tmpSuccessFactorData) => {
    let res = await SteercomServices.saveSuccessFactorPlan(tmpSuccessFactorData);
    if (res.status == "SUCCESS") {
      this.getTeamSFList();
      this.showAlert("success", this.props.labels.labels["LBLSUCCESS"], 3000);
    } else {
      this.showAlert("success", "ERROR!!");
    }
  };
  saveSFBaseDate = () => {
    var tmpSFData = {
      team_id: this.state.teamData.team_id,
      success_factor_id: this.state.successFactorData.id,
      base_date: this.state.base_date,
    };
    if (tmpSFData.team_id && tmpSFData.base_date) {
      let apiUrl = "steercomteamsfbasedate";
      SteeringCommitteeService.SteeringAPIServicesCall(
        apiUrl,
        "put",
        this.saveSFBaseDateCallback,
        tmpSFData
      );
    }
  };
  saveSFBaseDateCallback = (res) => {
    if (res.data.status == "SUCCESS") {
      this.getTeamSFList();
    } else {
      this.showAlert("alert", "ERROR::", 2000);
    }
  };
  showAlert = (messageType, message, showtime) => {

    this.setState({
      openalert: true,
      messageType: messageType,
      message: message,
      showtime: showtime ? showtime : 3000
    });
  };
  doEvent = (obj, event) => {
    /* Created by David@Refoua.me */
    var event = new Event(event, { target: obj, bubbles: true });
    let x = obj ? obj.dispatchEvent(event) : false;
    return x;
  };

  checkValidations = (tmpTeamDate) => {
    let checkval = true;
    showMessage1 = "";
    if ((tmpTeamDate.planned_end_date == "" || tmpTeamDate.planned_end_date == null)) {
      if (this.state.teamData.memberofteamsf > 0 && this.props.userId != this.state.teamData.created_by)
        showMessage1 = this.props.labels.labels.LBLWARNPLANEND1 + this.state.teamData.adminname;
      else
        showMessage1 = "";

      this.showAlert("warning", this.props.labels.labels.LBLWARNPLANEND, 20000);
      checkval = false;
    }
    if (
      tmpTeamDate.task_status != "YETTOSTART" && (!tmpTeamDate.actual_start_date || tmpTeamDate.actual_start_date == "Invalid Date" ||
        (tmpTeamDate.actual_start_date || "") == "")
    ) {
      checkval = false;
      $("#span_actual_start_date_" + tmpTeamDate.task_display_id).show();
    }
    if (tmpTeamDate.task_status == "COMPLETED") {
      if (
        !tmpTeamDate.actual_end_date || tmpTeamDate.actual_end_date == "Invalid Date" || 
         tmpTeamDate.actual_end_date.toString().indexOf("0000")>-1 || 
        (tmpTeamDate.actual_end_date || "") == ""
      ) {
        checkval = false;
        $("#span_actual_end_date_" + tmpTeamDate.task_display_id).show();
      }
      if (tmpTeamDate.actual_end_date && new Date(tmpTeamDate.actual_end_date) < new Date(tmpTeamDate.actual_start_date)) {
        checkval = false;
        this.showAlert("warning", this.props.labels.labels.LBLACTENDLESSSTART, 3000);
        return false;
      }
    }
    if (
      tmpTeamDate.completion_percentage == 100 &&
      (tmpTeamDate.actual_end_date || "") == ""
    ) {
      checkval = false;
      $("#span_actual_end_date_" + tmpTeamDate.task_display_id).show();
    }
    if ((tmpTeamDate.task_status || "") == "") {
      checkval = false;
      $("#span_task_status_" + tmpTeamDate.task_display_id).show();
    }
    if ((tmpTeamDate.completion_percentage || "") == "") {
      if (tmpTeamDate.task_status != "YETTOSTART") {
        checkval = false;
        $("#span_completion_percentage_" + tmpTeamDate.task_display_id).show();
      }
    }
    if (tmpTeamDate.last_activity_description.length > 255) {
      checkval = false;
      $("#span_last_activity_description_" + tmpTeamDate.task_display_id).html(
        this.props.labels.labels.LBLDESCMAXCHARS
      );
      $(
        "#span_last_activity_description_" + tmpTeamDate.task_display_id
      ).show();
    }
    return checkval;
  };
  updateEditFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    let editingTeamSF = this.state.editingTeamSF;
    for (var i = 0; i < teamSuccessFactorList.length; i++) {
      if (teamSuccessFactorList[i]) {
        if (item.task_display_id == teamSuccessFactorList[i].task_display_id) {
          teamSuccessFactorList[i].isNewAssigned = status ? 0 : 1;
          if ((teamSuccessFactorList[i].isNewAssigned || 0) == 1) {
            editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id] = {
              team_id: teamSuccessFactorList[i].team_id,
              success_factor_id: teamSuccessFactorList[i].success_factor_id,
              task_display_id: teamSuccessFactorList[i].task_display_id,
              actual_start_date: teamSuccessFactorList[i].actual_start_date,
              actual_end_date: teamSuccessFactorList[i].actual_end_date,
              task_status: teamSuccessFactorList[i].task_status ? teamSuccessFactorList[i].task_status : "YETTOSTART",
              completion_percentage:
                teamSuccessFactorList[i].completion_percentage,
              last_activity_description:
                teamSuccessFactorList[i].last_activity_description,
              oldMemberId: teamSuccessFactorList[i].team_member_id,
            };
          } else {
            delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
          }
        } else if (
          status == 0 &&
          (teamSuccessFactorList[i].isNewAssigned || 0) == 1
        ) {
          this.openConfirmDialog3(teamSuccessFactorList, editingTeamSF, i);
          teamSuccessFactorList[i].isNewAssigned = 0;
          delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
        }
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
      editingTeamSF: editingTeamSF,
    }));
  };
  updateExpandFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    for (let i = 0; i < teamSuccessFactorList.length; i++) {
      if (
        item.task_display_id == teamSuccessFactorList[i].parent_task_display_id
      ) {
        teamSuccessFactorList[i].expandFlag = status ? 0 : 1;
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
    }));
  };
  updateExpandFlagAll = (status, item) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    let expandFlag = this.state.expandFlag ? 0 : 1;
    for (let i = 0; i < teamSuccessFactorList.length; i++) {
      teamSuccessFactorList[i].expandFlag = status ? 0 : 1;
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
      expandFlag: expandFlag
    }));
  };
  openConfirmDialog3 = (item, status) => {
    let opendialog3 = false, teamSuccessFactorList = this.state.teamSuccessFactorList;
    let editingTeamSF = this.state.editingTeamSF;
    for (var i = 0; i < teamSuccessFactorList.length; i++) {
      if (teamSuccessFactorList[i].isNewAssigned == 1 && teamSuccessFactorList[i].task_display_id != item.task_display_id) {
        opendialog3 = true;
      }
    }
    if (!opendialog3) {
      this.updateEditFlag(item, status);
    }
    this.setState({
      opendialog3: opendialog3,
      updateItem: item,
      updateStatus: status,
    })
  }
  handleCloseDialog3 = (val) => {
    let { updateItem, updateStatus, i } = this.state;
    if (val.currentTarget.id == 2) {
      this.updateEditFlag(updateItem, updateStatus);
    }
    this.setState({
      opendialog3: false
    })
  }
  handleSFChange = (event, values) => {
    g_sfid = values;
    this.setState(
      {
        successFactorData: values,
      },
      this.getTeamSFList
    );
  };
  handleSFChangeChart = (event, values) => {
    $(".highcharts-scrollbar-thumb").scrollTop = 0;
    this.showGanttChartPopup(2, values);
    this.setState({
      successFactorDataChart: values,
    });
  }
  handleTeamChange = (event, values) => {
    parentask = "";
    this.setState({
      isloading:true
    })
    this.getTeamMembersList(values.team_id);
  };
  handleBaseDateChange = (xyz, date) => {
    this.setState({
      base_date: date,
    });
  };
  handleDateChange = (xVar, item, date) => {
    let editingTeamSF = this.state.editingTeamSF;
    editingTeamSF["M_" + item.task_display_id][xVar] = date;
    if (item.task_status == "COMPLETED") {
      if (editingTeamSF["M_" + item.task_display_id].actual_end_date && new Date(date).getTime() > new Date(editingTeamSF["M_" + item.task_display_id].actual_end_date).getTime()) {
        this.showAlert("warning", this.props.labels.labels.LBLACTENDLESSSTART);
      }
    }
    this.setState({
      editingTeamSF,
    });
  };
  handleChange = (xVar, item, id, event, values) => {

    let editingTeamSF = this.state.editingTeamSF;
    editingTeamSF["M_" + item.task_display_id][xVar] = values[id];
    if (values[id] == "COMPLETED") {
      editingTeamSF["M_" + item.task_display_id].completion_percentage = 100;
    } else if (values[id] == "YETTOSTART") {
      editingTeamSF["M_" + item.task_display_id].completion_percentage = 0;
    }
    if (values[id] != "COMPLETED" && editingTeamSF["M_" + item.task_display_id].completion_percentage == 100) {
      editingTeamSF["M_" + item.task_display_id].completion_percentage = "";
    }
    if (event.target)
      event.target.className = ""

    this.setState({
      editingTeamSF,
      currentTaskStatus: (editingTeamSF["M_" + item.task_display_id][xVar] = values[id]),
      ctstatus: true,
    });

  };
  onfocus1 = (item, event) => {
    // sets cursor position at end of MaskedTextBox
    let editingTeamSF = this.state.editingTeamSF;
    event.target.selectionStart = event.target.selectionEnd =
      editingTeamSF["M_" + item.task_display_id]["focus_position"] ||
      event.target.value.length;
  };
  handleDescription = (item, event) => {
    let locval = event.target.value;
    let editingTeamSF = this.state.editingTeamSF;
    editingTeamSF["M_" + item.task_display_id]["last_activity_description"] =
      locval;
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] =
      "last_activity_description";
    editingTeamSF["M_" + item.task_display_id]["focus_position"] = event.target.selectionStart;
    this.setState(
      {
        editingTeamSF,
      },
      event.target.focus()
    );
  };
  handleCompletionPercentage = (item, event) => {
    let currval = event.target.value;
    let editingTeamSF = this.state.editingTeamSF;
    if (editingTeamSF["M_" + item.task_display_id].task_status == "COMPLETED") {
      return false;
    } else if (editingTeamSF["M_" + item.task_display_id].task_status == "YETTOSTART") {
      return false;
    }
    if (!currval.match(/^(\d|-)+$/)) {
      currval = currval.replace(/[^0-9-]/g, "");
    } else if (currval < 0 || currval.includes("-")) {
      currval = 0;
    } else if (currval > 100) {
      currval = 100;
    }
    if (editingTeamSF["M_" + item.task_display_id].task_status != "COMPLETED" && currval == 100) {
      currval = 99;
    }
    if (editingTeamSF["M_" + item.task_display_id].task_status == "YETTOSTART") {
      if (currval > 0) currval = 0;
    }
    if (editingTeamSF["M_" + item.task_display_id].task_status == "INPROGONTRACK" ||
      editingTeamSF["M_" + item.task_display_id].task_status == "INPROGONDELAY") {
      if (currval == 0) currval = 1;
      else if (currval == 100) currval = 99;
    }

    //$("#txtCompleted").focus();
    editingTeamSF["M_" + item.task_display_id]["completion_percentage"] = "";
    editingTeamSF["M_" + item.task_display_id]["completion_percentage"] = currval;

    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "completion_percentage";

    this.setState({
      editingTeamSF,
    });
  };
  handleClose = (xVar, item) => {
    this.handleCloseAlert();
    let editingTeamSF = this.state.editingTeamSF;
    if (item && editingTeamSF["M_" + item.task_display_id]) {
      editingTeamSF["M_" + item.task_display_id][xVar] = false;
      this.setState({
        editingTeamSF,
        openalert: false
      });
    }
  };
  handleCloseAlert = () => {
    this.setState({ openalert: false });
  };
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
    return dateformat;
  };
  addSuccessFactor = () => {
    this.setState({
      addSuccessFactor: true,
    });
  };
  closeRefModal = () => {
    this.setState({
      showmodal: false,
    });
  };
  openRefModal = (row) => {
    //return
    this.setState({
      showmodal: true,
      resourceData: JSON.parse(row.references || '{"folder_id":998}'),
      resourceDataCustom: row.custom_references || ""
    });
  };
  showNotes = (row) => {
    let editingTeamSF = this.state.editingTeamSF;
    if (
      editingTeamSF["M_" + row.task_display_id].hasOwnProperty("notes_flag") ==
      true
    ) {
      editingTeamSF["M_" + row.task_display_id].notes_flag =
        !editingTeamSF["M_" + row.task_display_id].notes_flag;
    } else {
      editingTeamSF["M_" + row.task_display_id].notes_flag = true;
    }
    if (editingTeamSF["M_" + row.task_display_id].notes_flag == true) {
      editingTeamSF["M_" + row.task_display_id]["auto_focus"] =
        "last_activity_description";
    } else {
      editingTeamSF["M_" + row.task_display_id]["auto_focus"] = "";
    }

    this.setState({
      editingTeamSF: editingTeamSF,
    });
  };
  getParentItems = (row) => {
    let items, parentName = "", parentTaskId = row.parent_task_display_id;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        items = i;
      }
    });
    return items;
  }
  showDescription = (desc, name, tid, row) => {
    if (row.custom_task_name) {
      let items = this.getParentItems(row);
      name = items.custom_task_name;
      desc = items.custom_task_description;
    }
    this.setState({
      showDescModal: true,
      descriptionData: desc,
      nameData: name,
      taskid: tid,
    });
  };
  closeDescription = () => {
    showMessage1 = "";
    this.setState({
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
      showMessage1: ""
    });
  };
  onExport = (filetype) => {
    let { reportname, teamSuccessFactorList } = this.state;
    let loclistData = [];
    let listData = teamSuccessFactorList;
    let actEndDate='', allLabels = this.props.labels.labels;
    // member_user_id, 
    if (listData.length == 0) {
      this.showAlert("warning", allLabels["LBLWARNNORECSEXPORT"]);
      return false;
    }
    let {LBLPARENTTASK,LBLTASK, LBLTASKDESC,LBLSF,LBLTASKWEIGHT,LBLCURRENTSTATUS,LBLACTUALSDATE,LBLACTUALEDATE, LBLRECOMMENDEDDEADLINE,LBLPLANNEDSTARTDATE,LBLMEMBER,LBLPLANNEDENDDATE,LBLRECOMMENDEDSTARTDATE,LBLBASEDATE,LBLMILESTONES} = this.props.labels.labels;
    for (var i = 0, len = listData.length; i < len; i++) {
      actEndDate = listData[i].actual_end_date;
      if(actEndDate) 
      actEndDate = actEndDate && actEndDate.indexOf("0000")>=0?'': actEndDate; 
      let loclistitems = {};
      loclistitems[LBLSF] = this.getFactorName(listData[i]);
      loclistitems[LBLTASK] = listData[i].custom_task_name ? listData[i].custom_task_name : allLabels[listData[i].task_name];
      loclistitems[LBLTASKDESC] = listData[i]["custom_task_description"] ? listData[i]["custom_task_description"] : allLabels[listData[i].task_description];
      loclistitems[LBLMEMBER] = listData[i].membername;
      loclistitems[LBLPLANNEDSTARTDATE] = listData[i].planned_start_date ? this.getDateFormat( new Date(listData[i].planned_start_date).toLocaleDateString().replace(/\//g, "-")) : "";
      loclistitems[LBLPLANNEDENDDATE] = listData[i].planned_end_date ? this.getDateFormat( new Date(listData[i].planned_end_date).toLocaleDateString().replace(/\//g, "-") ): "";
      loclistitems[LBLACTUALSDATE] = listData[i].actual_start_date ? this.getDateFormat( new Date(listData[i].actual_start_date).toLocaleDateString().replace(/\//g, "-")) : "";
      loclistitems[LBLACTUALEDATE] = actEndDate ? this.getDateFormat( new Date(listData[i].actual_end_date).toLocaleDateString().replace(/\//g, "-")) : "";
      loclistitems[LBLCURRENTSTATUS] = listData[i].task_status? allLabels["LBL" + listData[i].task_status]:"";
      //loclistitems["weightage"] = listData[i].weightage;
      loclistitems[LBLTASKWEIGHT] = listData[i].completion_percentage ? listData[i].completion_percentage + "%" : "";
      //loclistitems["success_factor"] = allLabels["LBL"+ (listData[i].success_factor_id).replace("Y1","") + "TITLE"];

      if (listData[i].task_type == 0) {
        //loclistitems["weightage"] =""; 
      }

      loclistitems[LBLTASK] = listData[i].custom_task_name ? listData[i].custom_task_name : this.props.labels.labels[listData[i].task_name];
      loclistitems[LBLTASKDESC] = listData[i]["custom_task_description"] ? listData[i]["custom_task_description"] : this.props.labels.labels[listData[i].task_description];
      loclistitems[LBLRECOMMENDEDSTARTDATE] = listData[i].recommended_start_days;
      if (listData[i].task_type == 2) {
        //loclistitems[LBLPARENTTASK] = LBLMILESTONES;
        loclistitems[LBLSF] = LBLMILESTONES; 
      }
      loclistData.push(loclistitems)
    }
    let newval, upperval, arr, fieldName, arrfieldName;
    $("#loadingexport").show();
    loclistData.map((itm) => {
      arrfieldName = Object.keys(itm);
      arrfieldName.forEach((obj) => {
        upperval = obj.toString().toUpperCase();
        arr = upperval.split("_");
        if (arr.length > 1) {
          newval = arr.join(" ");
          itm[newval] = itm[obj];
          delete itm[obj];
        } else {
          newval = upperval;
          itm[newval] = itm[obj];
          delete itm[obj];
        }
      });
      return true;
    });
    reportname = "Task_Planning";
    if (!loclistData) return null;
    if (filetype.toLowerCase() == "csv") {
      this.exportCSV(loclistData, reportname);
    } else if (filetype.toLowerCase() == "excel" || filetype.toLowerCase() =="xlsx") {
      this.exportToExcel(loclistData, reportname);
    }
    loclistData = listData;
  };
  getDateFormat = (xdata, item, mode) => {
    let enddate;
    if (xdata) {
      if (item && mode != 1) {
        enddate = xdata; 
        enddate = new Date(enddate).toLocaleDateString("en-US"); 
        enddate = enddate.split(",")[0]; //enddate.substr(0, 10);
      } else {
        enddate = xdata.split(",")[0]; //xdata.substr(0, 10);
      } 
      enddate = this.formattingDate(enddate);
      return enddate; //.substr(0, 10)
    } else {
      return false;
    }
  };
  convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    let result_key = "";
    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        if (key.toLowerCase().indexOf("summary") > 0) {
          result_key = item[key].toString().replace(/\n/g, "");
        } else {
          if (typeof item[key] == "undefined") item[key] = "";
          result_key = item[key];
        }
        result_key = ((result_key) && isNaN(result_key)) ? result_key.replace(/[,]/g, '-').replace(/\n/g, " ") : result_key;
        result_key = result_key == null ? "" : result_key;
        result += result_key;
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  exportCSV = (listdata, reportname) => {
    var data, filename, link;
    var stockData = [];
    var objHeader = {};

    let lheader,
      xfields = listdata;
    stockData = xfields;
    var csv = this.convertArrayOfObjectsToCSV({
      data: stockData,
    });
    if (csv == null) return;

    filename = reportname + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    data = encodeURI(csv);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
    $("#loadingexport").hide();
  };
  exportToExcel = (data, sCallBack, fCallBack) => {
    let fileExtension = ".xlsx";
    let fileName = "Task_Planning";
    let width, headers = [];
    const wb = new Excel.Workbook();
    let ws = wb.addWorksheet('Tasks');
    const header_names = Object.keys(data[0]);
    header_names.map((item, x) => {
      width = (x < 3) ? 40 : (x == 3 || x == 4) ? 25 : 20;
      headers.push({
        header: item, key: ("row_" + (x + 1)), width: width
      });

    });
    let cols = [];
    ws.columns = headers;
    data.map((items, idx) => {
      cols = [];
      header_names.map(x => {
        return cols.push(items[x])
      });
      ws.addRow(cols);
    })
    ws.getColumn('A').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('B').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('C').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('D').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('E').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getColumn('F').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getRow(1).style.alignment = { vertical: 'middle', horizontal: 'center' };

    ws = this.excelFormatting(ws, headers)
    wb.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${fileName + "_" + Date.now()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
  }
  excelFormatting = (worksheet, xcolumns) => {
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } }
        };
        if (rowNumber === 1) {
          row.height = 30;
          row.font = { bold: true }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "538DD5" }
          };
          cell.alignment = {
            vertical: 'middle', horizontal: 'center', wrapText: true
          };
        } else {

          let fgColor = '538DD5';
          //if(cell._address == "A2"){
          if (row._cells[3] && row._cells[3]._value.model.value && row._cells[3]._value.model.value.trim() == "") {
            fgColor = '000';
            row.font = { bold: true }
            row._cells[4]._value.model.value = "";
            row._cells[5]._value.model.value = ""; 
          } 

          cell.alignment = {
            vertical: 'middle', horizontal: colNumber < 5 ? 'left' : 'center'
          };
        }
      })
    })
    return worksheet;
  }
  getRoleName = (xdata) => {
    let role, roleName = "", steercomRoles = this.state.steercomRoles;
    if (xdata) {
      role = steercomRoles.filter(item => item.steercom_role_id == xdata)
      roleName = role[0].steercom_role_name;
    }
    return roleName;
  };
  getFactorName = (row) => {
    let factorName = ""; 
    let SFTITLE = row.success_factor_id.replace("Y1","LBL") + "TITLE";
    factorName = this.getLabel(SFTITLE);
    return factorName;
  }
  getParentName = (row) => {
    let parentName = "", parentTaskId = row.parent_task_display_id;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        parentName = i.custom_task_name ? i.custom_task_name : this.getLabel("LBL" + parentTaskId);
      }
      return null;
    });
    return parentName;
  }
  getParentDescription = (row, eve) => {
    let parentName = "", parentTaskId = row.parent_task_display_id;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        parentName = i.custom_task_name ? i.custom_task_name : this.getLabel("LBL" + parentTaskId + "DSCR");
      }
      return null;
    });
    return parentName;
  }
  showGanttChartPopup = (mode, sfData) => {
    let mainTasks = [], allTasks = [], allItems = this.state;
    //let mode = items == 1 ? "add" : "edit";
    let successFactorDataChart = allItems.successFactorDataChart;
    // let successFactorsList = allItems.successFactorsList;
    let successFactorData = allItems.successFactorData;
    let cnt = 0, taskDetails = [], baseDate = allItems.base_date;
    allTasks = allItems.teamSuccessFactorList;
    if (mode == 2 && sfData.id != "0") {
      allTasks = allItems.teamSuccessFactorList.filter(items => sfData.id == items.success_factor_id);
    }
    if (mode == 1) {
      successFactorDataChart = successFactorData;
    }
    allTasks.length > 0 && allTasks.map((item) => {
      if (item.task_type == 0) {
        item.task_name = item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name);
        if (cnt == 0) mainTasks.push(item);
        if (mainTasks.findIndex(items => items.task_name != item.task_name) > -1) {
          mainTasks.push(item);
        }
        cnt++;
      }
      return null;
    });
    taskDetails["all_tasks"] = allTasks;
    //taskDetails["row"] = (items);
    taskDetails["team_language"] = allItems.teamLanguage;
    taskDetails["main_tasks"] = mainTasks;
    taskDetails["steercom_roles"] = allItems.steercom_roles;
    taskDetails["success_factors"] = allItems.successFactorsList;
    taskDetails["task_type"] = allItems.taskTypeArr;
    taskDetails["task_category"] = allItems.taskCategoryArr;

    this.showGantChart(1, taskDetails);

    this.setState({
      showGantPopup: true,
      taskDetails: taskDetails,
      selectedBaseDate: baseDate,
      successFactorDataChart: successFactorDataChart
    });
  }
  closeGantPopup = () => {
    // this.getTeamListCallback(); 
    $("#section-header").css({ "display": "flex" })
    let { successFactorsList } = this.state;
    this.setState({
      showGantPopup: false,
      successFactorDataChart: successFactorsList[0]
    })
  }
  handleStartDateChange = (date) => {
    console.log(date);
    this.setState({
      selectedBaseDate: date,
      error: true,
    });
  };
  showGantChart = (mode, taskDetails) => {
    let task_details = taskDetails;
    if (mode == 2) {
      task_details = this.state.taskDetails;
    }
    let prevSelectedDate = this.state.prevSelectedDate;
    if (new Date(prevSelectedDate).getTime() == new Date(this.state.selectedBaseDate).getTime() && mode == 2) {
      return false;
    } else {
      prevSelectedDate = this.state.selectedBaseDate;
    }
    $("#section-header").css({ "display": "none" })
    let all_tasks = task_details.all_tasks;
    let flagTime = true, loctime = 0, base_date, new_base_date, teamSFList = [];
    new_base_date = this.state.selectedBaseDate;
    base_date = this.state.base_date;
    teamSFList = all_tasks;

    if (mode == 2) {
      // base_date = this.state.selectedBaseDate;
      if (new Date(base_date).getTime() > new Date(new_base_date).getTime()) {
        loctime = new Date(base_date).getTime() - new Date(new_base_date).getTime();
        //base_date = new Date(loctime);
        flagTime = true;
      } else if (new Date(base_date).getTime() < new Date(new_base_date).getTime()) {
        loctime = new Date(new_base_date).getTime() - new Date(base_date).getTime();
        //base_date = new Date(loctime);
        flagTime = false;
      }
    } 
    this.setState({
      all_tasks: teamSFList,
      showLoading: true,
      showGantPopup: true,
      taskDetails: task_details,
      flagTime: flagTime,
      loctime: loctime,
      prevSelectedDate: prevSelectedDate,
      error: true,
      loadChart: true,
    });
  }

  render() {
    const styles = {
      floatingLabelFocusStyle: {
        color: "blue"
      }
    }
    let getRoleName = (xdata) => {
      if (xdata) {
        return this.getLabel("LBL" + xdata);
      } else {
        return "";
      }
    };

    let convertDateFormat = (xdata, item, mode) => {
      let enddate;
      if (xdata) {
        if (item && mode != 1) {
          enddate = xdata;
          //enddate = new Date(parseInt(enddate, 10)).toLocaleString();
          enddate = new Date(enddate).toLocaleDateString("en-US");
          //enddate = new Date(enddate).toLocaleString();
          enddate = enddate.split(",")[0]; //enddate.substr(0, 10);
        } else {
          enddate = xdata.split(",")[0]; //xdata.substr(0, 10);
        }
        //if (navigator.userAgent.search("Firefox") < 0 || !item)
        enddate = this.formattingDate(enddate);
        return enddate; //.substr(0, 10)
      } else {
        return false;
      }
    };
    this.formattingDate = (date) => {
      let dateformat,
        fulldate = "";
      dateformat = this.state.dateformat;
      let dateArray = date.indexOf("/")>-1? date.split("/"): date.indexOf("-")>-1? date.split("-"):"";
      if (dateformat.toString().indexOf("YYYY") > 0)
        dateformat = this.setdateformat();
      if (date) {
        var d = new Date(), month, day, year;
        d = new Date(date);
        day = "" + d.getDate();
        if (d == undefined || isNaN(d.getDate())) {
          d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
        }
        month = "" + (d.getMonth() + 1);
        day = "" + d.getDate();
        year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        if (dateformat == "dd/MM/yyyy") {
          fulldate = [day, month, year].join("/");
        } else if (dateformat == "MM/dd/yyyy") {
          fulldate = [month, day, year].join("/");
        } else if (dateformat == "yyyy/MM/dd") {
          fulldate = [year, month, day].join("/");
        } else {
          fulldate = [day, month, year].join("/");
        }
      }
      return fulldate;
    };
    const {
      LBLTASK,LBLVWFULL,LBLPRINTCRT,LBLDNPNG,LBLDNJPEG,LBLDNSVG,LBLDNPDF,LBLMILESTONES,
      LBLTEAMADMINS, LBLUNSAVEDATA, LBLSTEERCOMTEAMS, LBLREQUIRED, LBLEXPORT, LBLEXPORTTOCSV, LBLEXPORTTOEXCEL, 
      LBLSTEERCOMSFTRACK, LBLACTUALSDATE, LBLGANTTCHART, LBLCURRENTSTATUS, LBLPCOMPLETED, LBLACTUALEDATE,
      LBLPLANNEDSTARTDATE, LBLPLANNEDENDDATE, LBLACTION,LBLSAVE,LBLRESET,
      LBLREFERENCELNKS, LBLCANCEL,
      LBLBASEDATE, LBLACTDESCRIPTION, LBLTEAM,  LBLNONE,
      LBLSF, LBLUPDATEBASEDATE, LBLAGREE, LBLNO, LBLPLANNORECORDS, LBLNORESOURCES,
      LBLYETTOSTART, LBLINPROGONTRACK,LBLCONTMENU, 
      LBLINPROGONDELAY, LBLVIEWMODE, LBLLEGENDS, LBLCOMPLETED, LBLEXPIRED, LBLREFMATERIAL, LBLTASKDESC,
      LBLJAN, LBLFEB, LBLMAR, LBLAPR, LBLMAY, LBLJUN, LBLJUL, LBLAUG, LBLSEP, LBLOCT, LBLNOV, LBLDEC,LBLNOMEMBERFOUND
    } = this.props.labels.labels;
    const { teamData, mobView, teamSuccessFactorList, editingTeamSF} = this.state;
    let { loadChart, showLoading } = this.state;
    const filterSuccessFactorsListData = teamSuccessFactorList.filter(
      (item) => { 
        if (
          (item.task_type == 1 && item.expandFlag == 1)
          //|| item.task_type == 0
        ) {
          return item;
        }
      return null;
      }
    );

    const memberNoOptionsNode = () => {
      return <span>{LBLNOMEMBERFOUND}</span>;
    };
    let teamSuccessFactorColumnsAll = [
      {
        name: "",
        selector: "",
        width: "40px",
        cell: (row) => (
          <>
            <div
              style={{
                display: "inline-block",
                paddingLeft: "0px",
                position: "absolute",
                backgroundColor: "white",
                left: "-5px",
                width: "52px",
                height: "52px",
              }}
            ></div>
            {row.task_type != 1 ? (
              "" 
            ) : (
              <div
                style={{
                  display: "inline-block",
                  paddingLeft: "0px",
                  position: "absolute",
                  border: "0px solid red",
                  top: "-4px",
                  left: "2px",
                }}
              >
                <div className="sub_container3" style={{ height: "auto" }}>
                  <img
                    src={
                      "../images/steercom/" +
                      getStatusClass(row.task_status) +
                      ".png"
                    }
                    className="img_team_status"
                    alt=""
                  ></img>
                </div>
                <div> { }</div>
              </div>
            )}
          </>
        ),
      },
      {
        name: LBLSF, //LBLPARENTTASK
        fixedHeader: true,
        selector: "name",
        // sortable: true,
        width: "250px",
        compact: true,
        cell: (row) => { 
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == this.state.teamData.created_by ||
              this.props.userId == this.state.teamData.team_administrator ||
              this.props.userId == this.state.teamData.team_addnl_administrator || this.state.teamData.memberofteamsf > 0) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          let task_name = "";
          task_name = this.getFactorName(row);//this.getParentName(row); //row.custom_task_name ? row.custom_task_name : this.getLabel("LBL" + row.parent_task_display_id);
          if (row.is_default == 0) {
            task_name = this.getFactorName(row);//this.getParentName(row);
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {row.task_type == 2 ? row.custom_task_name ? row.custom_task_name : row.task_name : row.task_type == 1 ? (
                <>
                  <div
                    style={{
                      width: "14px",
                      height: "34px",
                      border: "0px solid",
                      display: "block",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "34px", 
                        backgroundColor: "rgb(128, 227, 186)",
                        border: "0px solid",
                        display: "block",
                      }}
                    ></div>
                  </div>

                  <div
                    style={{
                      width: "calc(100% - 14px)",
                      border: "0px solid",
                      display: "flex",
                    }}
                  >
                    {xEditCheckBox == true ? (
                      <div
                        style={{
                          display: "inline-block",
                          paddingLeft: "0px",
                          width: "30px",
                        }}
                      >
                        <label
                          class={
                            row.isNewAssigned
                              ? "check-lbl"
                              : "check-lbl check-newassigned"
                          }
                        >
                          <input
                            type="checkbox"
                            name={"chkg_" + row.team_id}
                            id={"chkg_" + row.team_id}
                            data-toggle="modal"
                            checked={row.isNewAssigned}
                            onClick={this.openConfirmDialog3.bind(
                              this,
                              row,
                              row.isNewAssigned ? 1 : 0
                            )
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          border: "0px solid red",
                          width: xEditCheckBox == true ? "calc(100% - 62px)" : "calc(100% - 29px)",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: "24px" /* exactly 2 lines */,
                            textOverflow: "ellipsis",
                            fontWeight: "410"
                          }}
                          title={task_name}
                        >
                          {/* {row.task_display_id}  */}
                          {task_name}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "none",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          width: "28px",
                        }}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                          }}
                          src="../images/steercom/info.png"
                          //onClick={this.getParentDescription.bind(this,row)}
                          onClick={this.showDescription.bind(
                            this,
                            this.getParentDescription(row),
                            this.getParentName(row),
                            row.task_display_id, row
                          )}
                          title={LBLTASKDESC}
                          alt=""
                        />
                      </div>
                    </>
                  </div>
                </>
              ) : (
                ""
              )
              }
            </div >
          )
        },
      },
      {
        name: LBLTASK,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "310px",
        compact: true,
        cell: (row) => {
          let xEditCheckBox = this.state.base_date &&
            (this.props.userId == this.state.teamData.created_by ||
              this.props.userId == this.state.teamData.team_administrator ||
              this.props.userId == this.state.teamData.team_addnl_administrator || this.state.teamData.memberofteamsf > 0) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {row.task_type == 1 ? (
                <>
                  <div
                    style={{
                      width: "14px",
                      height: "34px",
                      border: "0px solid",
                      display: "block",
                    }}
                  >
                  </div>

                  <div
                    style={{
                      width: "calc(100% - 14px)",
                      border: "0px solid",
                      display: "flex",
                    }}
                  >
                    <>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          border: "0px solid red",
                          width: xEditCheckBox == true ? "calc(100% - 62px)" : "calc(100% - 29px)",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: "24px" /* exactly 2 lines */,
                            textOverflow: "ellipsis",
                            fontWeight: "410"
                          }}
                          title={row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name)}
                        >
                          {row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          width: "28px",
                        }}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                          }}
                          src="../images/steercom/info.png"
                          onClick={this.showDescription.bind(
                            this,
                            row.custom_task_description ? row.custom_task_description : this.getLabel(row.task_description),
                            row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name),
                            row.task_display_id
                          )}
                          title={LBLTASKDESC}
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          width: "28px",
                        }}
                      >
                         {(row.references  || row.custom_references ) ?
                        <img
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                          }}
                          src="../images/steercom/SC_Folder.png"
                          onClick={this.openRefModal.bind(this, row)}
                          title={LBLREFMATERIAL}
                          alt=""
                        /> :
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                            opacity:".2"
                          }}
                          src="../images/steercom/SC_Folder.png"
                          title={LBLNORESOURCES}
                          alt=""
                        />}
                      </div>
                    </>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="MAINTASK"
                    style={{
                      width: "100%",
                      border: "0px solid",
                      height: "100%",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "left",
                    }}
                  >
                    <>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "8px",
                          left: "2px",
                          border: "0px solid blue",
                          width: "calc(100% - 28px)",
                          textAlign: "left",
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: "24px" /* exactly 2 lines */,
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            fontWeight: "430"
                          }}
                          title={row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name)}
                        >
                          {row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          width: "28px",
                        }}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            width: "15px",
                            height: "15px",
                            objectFit: "contain",
                            marginRight: "5px",
                          }}
                          src="../images/steercom/info.png"
                          onClick={this.showDescription.bind(
                            this,
                            row.custom_task_description ? row.custom_task_description : this.getLabel(row.task_description),
                            row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name),
                            row.task_display_id
                          )}
                          title={LBLTASKDESC}
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          position: "relative",
                          top: "3px",
                          left: "2px",
                          width: "28px",
                        }}
                      >

                      </div>
                    </>
                  </div>
                </>
              )}
            </div>
          )
        },
      },
      {
        name: this.props.labels.labels.LBLMEMBER,
        selector: "service_duration",
        //sortable: true,
        width: "200px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <> 
                <div title={row.membername} className="cls_ellipsis"  style={{display:"flex", color: row.user_status == 0 ? "#FF5959" : "#5F5E5E" }}> 
                  <div className="cls_ellipsis" > {row.membername}</div> 
                  <img
                    src="../images/steercom/admin.png"
                    style={{
                      //position: "absolute",
                      float: "right",
                      display:
                        teamData.adminname == row.membername ? "" : "none",
                    }}
                    title="Admin"
                    className="img_team_admin"
                    alt=""
                  ></img>{" "}
                </div>
              </>  
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: LBLPLANNEDSTARTDATE,
        selector: "member_enddate",
        //    sortable: true,
        reorder: true,
        width: "130px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <>
                <>
                  <div>
                    <img
                      src="../images/steercom/Expired.png"
                      style={{
                        visibility:
                          (new Date().getTime() >=
                            new Date(row.planned_start_date).getTime() &&
                            row.task_status != "COMPLETED")
                            ? "visible"
                            : "hidden",
                      }}
                      title={LBLEXPIRED}
                      className="img_team_cross"
                      alt=""
                    ></img>
                    {convertDateFormat(row.planned_start_date, row)}
                  </div>
                </>
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: LBLPLANNEDENDDATE,
        selector: "member_enddate",
        //    sortable: true,
        width: "130px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <>
                <>
                  <div>
                    <img
                      src="../images/steercom/Expired.png"
                      style={{
                        visibility:
                          (row.planned_end_date && new Date().getTime() >=
                            new Date(row.planned_end_date).getTime() &&
                            row.task_status != "COMPLETED")
                            ? "visible"
                            : "hidden",
                      }}
                      title={LBLEXPIRED}
                      className="img_team_cross"
                      alt=""
                    ></img>
                    {convertDateFormat(row.planned_end_date, row)}
                  </div>
                </>
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },

      {
        name: LBLCURRENTSTATUS,
        selector: "task_status",
        //   sortable: true,
        width: "160px",
        compact: true,
        cell: (row, idx) => (
          <div id={getStatusClass(row.task_status) + "_" + idx}
            className={
              row.task_type == 0
                ? "sfcellbox MAINTASK"
                : "sfcellbox " + getStatusClass(editingTeamSF["M_" + row.task_display_id] ?
                  editingTeamSF["M_" + row.task_display_id].task_status :
                  row.task_status)
            }
            style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
          >
            {row.task_type == 1 ? (
              <>
                {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                  <>
                  <ThemeProvider theme={theme}>
                    <Autocomplete
                      id={"ac_task_status" + row.task_display_id}
                      disableClearable
                      options={this.state.steercomSettings.taskstatuses}
                      getOptionLabel={(option) => option.name.toString()}
                      getOptionSelected={(option, values) =>
                        option.id === values.id
                      }
                      PaperComponent={({ children }) => (
                        <div id="minipop" style={{ zIndex: "100", width: "115%", marginLeft: "-12px", backgroundColor: "#FFF", padding: "0" }}>
                          {children}
                        </div>
                      )}
                      onChange={this.handleChange.bind(
                        this,
                        "task_status",
                        row,
                        "id"
                      )}
                      underlineStyle={{ display: "none" }}
                      defaultValue={this.state.steercomSettings.taskstatuses.find(
                        (v) =>
                          v.id ==
                          editingTeamSF["M_" + row.task_display_id].task_status
                      )}
                      renderInput={(params) => (
                        <TextField
                          style={{ textAlign: "center", width: "100%" }}
                          id={"task_status" + row.task_display_id}
                          {...params}
                        />
                      )}
                      renderOption={(option) => (
                        <div
                          className={
                            option.id == null
                              ? statusClasses["YETTOSTART"]
                              : statusClasses[option.id]
                          }
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            width: "150%",
                            // padding: "12px 12px 12px 6px",
                            marginLeft: "-10px",
                            marginBottom: "-9px",
                            marginRight: "-10px",
                            backgroundColor:
                              statusClasses[option.id] == "notstarted"
                                ? "#c8c6c6"
                                : statusClasses[option.id] == "ontrack"
                                  ? "#7db1f5"
                                  : statusClasses[option.id] == "delayed"
                                    ? "#f5c947"
                                    : statusClasses[option.id] == "completed"
                                      ? "#79da8d"
                                      : "#c8c6c6",
                          }}
                        >
                          {" "}
                          {option.name}{" "}
                        </div>
                      )}
                    />
                    </ThemeProvider>
                    <span
                      className="required"
                      id={"span_task_status_" + row.task_display_id}
                    >
                      {LBLREQUIRED}
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="cls_img_status_name" id={row.task_status + "_" + idx}  >
                      {getRoleName(row.task_status || "YETTOSTART")}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },

      {
        name: LBLACTUALSDATE,
        selector: "member_startdate",
        //   sortable: true,
        width: "130px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK cls_actual_date" : "sfcellbox cls_actual_date"}
          >
            {row.task_type == 1 &&
              ((editingTeamSF["M_" + row.task_display_id] &&
                (editingTeamSF["M_" + row.task_display_id].task_status || "YETTOSTART") !=
                "YETTOSTART") ||
                (row.task_status || "YETTOSTART") != "YETTOSTART") ? (
              <>
                {row.task_type == 1 ? (
                  <>
                    {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                      <>
                        <MuiPickersUtilsProvider
                          className="member_startdate"
                          utils={DateFnsUtils}
                          style={{ width: "calc(100% - 1px)", float: "right" }}
                        >
                          <Grid container justify="space-around">
                            <KeyboardDatePicker
                              margin="normal"
                              //minDate={new Date()}
                              minDate={'2018-01-01'}
                              // minDate={"01-01-2021"}
                              id={"actual_start_date" + row.task_display_id}
                              // label={LBLMEMBERSTARTDATE}
                              format={this.state.dateformat}
                              value={
                                editingTeamSF["M_" + row.task_display_id]
                                  .actual_start_date
                              }
                              onChange={this.handleDateChange.bind(
                                this,
                                "actual_start_date",
                                row
                              )}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                          </Grid>
                          <span
                            className="required"
                            id={"span_actual_start_date_" + row.task_display_id}
                          >
                            {LBLREQUIRED}
                          </span>
                        </MuiPickersUtilsProvider>
                      </>
                    ) : (
                      <>
                        <div>
                          <img
                            src="../images/steercom/Expired.png"
                            style={{
                              visibility:
                                (row.actual_start_date && new Date().getTime() >
                                  new Date(row.actual_start_date).getTime() &&
                                  row.task_status != "COMPLETED" && (row.actual_start_date > row.planned_start_date))
                                  ? "visible"
                                  : "hidden",
                            }}
                            title={LBLEXPIRED}
                            className="img_team_cross"
                            alt=""
                          ></img>

                          {convertDateFormat(
                            row.actual_start_date &&
                              row.actual_start_date.indexOf("00") == 0
                              ? null
                              : row.actual_start_date
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>) : <></>
            }
          </div>
        ),
      },
      {
        name: LBLACTUALEDATE,
        selector: "member_enddate",
        //    sortable: true,
        width: "130px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK cls_actual_date" : "sfcellbox cls_actual_date"}
          >
            {row.task_type == 1 &&
              ((editingTeamSF["M_" + row.task_display_id] &&
                editingTeamSF["M_" + row.task_display_id].task_status ==
                "COMPLETED") ||
                row.task_status == "COMPLETED") ? (
              <>
                {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                  <>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          minDate={editingTeamSF["M_" + row.task_display_id].actual_start_date ?
                            editingTeamSF["M_" + row.task_display_id].actual_start_date : '2018-01-01'}
                          id={"actual_end_date_" + row.task_display_id}
                          format={this.state.dateformat}
                          value={
                            editingTeamSF["M_" + row.task_display_id]
                              .actual_end_date
                          }
                          onChange={this.handleDateChange.bind(
                            this,
                            "actual_end_date",
                            row
                          )}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                      <span
                        className="required"
                        id={"span_actual_end_date_" + row.task_display_id}
                      >
                        {LBLREQUIRED}
                      </span>
                    </MuiPickersUtilsProvider>
                  </>
                ) : (
                  <>
                    <div>
                      <img
                        src="../images/steercom/Expired.png"
                        style={{
                          visibility:
                            (new Date().getTime() >=
                              new Date(row.actual_end_date).getTime() &&
                              row.task_status != "COMPLETED")
                              ? "visible"
                              : "hidden",
                        }}
                        title={LBLEXPIRED}
                        className="img_team_cross"
                        alt=""
                      ></img>
                      {convertDateFormat(row.actual_end_date)}</div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: LBLPCOMPLETED,
        selector: "member_enddate",
        //    sortable: true,
        width: "60px",
        compact: true,
        cell: (row) => {
          return (
            <div
              className={
                row.task_type == 0 ? "sfcellbox MAINTASK cls_actual_date" : "sfcellbox cls_actual_date"
              }

            >
              {row.task_type == 1 ? (
                <>
                  {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                    <>
                      <div style={{ display: "flex" }}>
                        <input
                          onChange={(e) =>
                            this.handleCompletionPercentage(row, e)
                          }
                          className="cls_sm_text"
                          value={
                            editingTeamSF["M_" + row.task_display_id].task_status == "YETTOSTART" ? 0 :
                              editingTeamSF["M_" + row.task_display_id]["completion_percentage"]
                          }
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "completion_percentage"
                              ? true
                              : false
                          }
                        />
                        <span className="cls_spn_percent">% </span>
                      </div>
                      <span
                        className="required" style={{ top: "26px", zIndex: "1",width:"100%" }}
                        id={"span_completion_percentage_" + row.task_display_id}
                      >
                        {LBLREQUIRED}
                      </span>
                    </>
                  ) : (
                    <>
                      <div style={{ display: "flex" }}>
                        <span className="cls_span_sm_text" type="text">
                          {row.completion_percentage}
                        </span>
                        <span className="cls_spn_percent">
                          {(row.completion_percentage == null ||
                            row.completion_percentage == "") &&
                            row.completion_percentage != "0"
                            ? "-"
                            : "%"}
                        </span>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          );
        },
      }, 
      {
        name: LBLACTDESCRIPTION,
        selector: "member_enddate",
        width: "100px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <>
                {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                  <>
                    <div className="cls_act_description">
                      <div
                        className="cls_act_description"
                        onClick={this.showNotes.bind(this, row)}
                      >
                        {
                          editingTeamSF["M_" + row.task_display_id]
                            .last_activity_description
                        }
                      </div>
                      {editingTeamSF["M_" + row.task_display_id].notes_flag ==
                        true ? (
                        <>
                          <div id={"notes_" + row.task_display_id}>
                            <textarea
                              style={{
                                width: "178px",
                                height: "106px",
                                position: "absolute",
                                zIndex: "200",
                                top: "-4px",
                                left: "0px",
                                overflow: "hidden",
                                lineHeight: "1.2",
                                borderRadius: "4px",
                                border: "1px solid black",
                              }}
                              onBlur={this.showNotes.bind(this, row)}
                              className="cls_act_inp_description"
                              onChange={this.handleDescription.bind(this, row)}
                              value={
                                editingTeamSF["M_" + row.task_display_id]
                                  .last_activity_description
                              }
                              autoFocus={
                                editingTeamSF["M_" + row.task_display_id][
                                  "auto_focus"
                                ] == "last_activity_description"
                                  ? true
                                  : false
                              }
                              onFocus={(e) => this.onfocus1(row, e)}
                            ></textarea>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <span
                      className="required"
                      id={
                        "span_last_activity_description_" + row.task_display_id
                      }
                    >
                      {LBLREQUIRED}
                    </span>
                  </>
                ) : (
                  <>
                    <div className="cls_act_description">
                      <span title={row.last_activity_description}>
                        {row.last_activity_description}
                      </span>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        name: LBLACTION,
        selector: "planstatus",
        //     sortable: true,
        width: "90px",
        compact: true,
        cell: (row) => (
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 && row.isNewAssigned ? (
              <>
                {" "}
                {this.props.userId == this.state.teamData.created_by ||
                  this.props.userId == this.state.teamData.team_administrator ||
                  this.props.userId == this.state.teamData.team_addnl_administrator || this.state.teamData.memberofteamsf > 0 ? (
                  <>
                    <label className="show-sm">{LBLACTION}</label>
                    <div
                      className="sub_container33"
                      style={{ display: "inline-block" }}
                    >
                      <img
                        src="../images/steercom/Checkbox.png"
                        onClick={this.saveSuccessFactor.bind(this, row)}
                        title={LBLSAVE}
                        className="img_team_cross"
                        alt=""
                      ></img>
                    </div>
                    <div
                      className="sub_container3 cls_team_cross"
                      style={{ display: "inline-block" }}
                    >
                      <i
                        class="fas fa-undo"
                        onClick={this.deleteSuccessFactor.bind(this, row)}
                        title={LBLRESET}
                      ></i>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ),
      },
    ];

    let teamSuccessFactorColumns = teamSuccessFactorColumnsAll;
    let teamSuccessFactorListData = filterSuccessFactorsListData;
    let horizontal = "top",
      vertical = "center";
    let statusClasses = {
      YETTOSTART: "notstarted",
      INPROGONTRACK: "ontrack",
      INPROGONDELAY: "delayed",
      COMPLETED: "completed",
    };

    let statusColors = {
      YETTOSTART: "#aeacac",
      INPROGONTRACK: "#4785d7",
      INPROGONDELAY: "#d6a71c",
      COMPLETED: "#07f037",
    }
    let getStatusClass = (status) => {
      if (typeof status == "string") {
        return statusClasses[status];
      } else {
        return statusClasses["YETTOSTART"];
      }
    };
    let hasData = true;
    let xdefaultValue = this.state.teamData.team_id ? this.state.teamData : this.state.teamList[0]
    try {
      if (this.state.successFactorData && this.state.teamData && xdefaultValue) {
        //alert("AAAAAAAA" + xdefaultValue.team_id)
      }
    } catch (e) {
      //alert("ee")
    }
    let goptions = {};
    if (this.state.showGantPopup) {
      if (LBLJAN) {
        Highcharts.setOptions({
          lang: {
            months: [LBLJAN, LBLFEB, LBLMAR, LBLAPR, LBLMAY, LBLJUN, LBLJUL, LBLAUG, LBLSEP, LBLOCT, LBLNOV, LBLDEC],
            //weekdays: [LBLMON, LBLTUE, LBLWED, LBLTHU, LBLFRI, LBLSAT, LBLSUN],
          }
        });
      }

      let color = "#0FC", clr = 1, yax = 0, { all_tasks, loctime, flagTime } = this.state;
      // let ccc = teamSuccessFactorList;
      var today = new Date();

      // Set to 00:00:00:000 today
      today.setUTCHours(0);
      today.setUTCMinutes(0);
      today.setUTCSeconds(0);
      today.setUTCMilliseconds(0);

      let categories = [], finalData = [], allTasks = [], category = "";
      let maxDates = [], minDates = [], minX = new Date(), maxX = new Date();
      let maxModDates = [];
      let prevParentName, currParentName, currTaskName, xx, yy, cnt = 1;
      let startDate = "";

      allTasks = all_tasks;
      //////////////////////////
      let { successFactorDataChart } = this.state; 
      //let teamSuccessFactors = allTeamSuccessFactorList;
      let teamSuccessFactors = allTasks;
      if (successFactorDataChart.id != "0") {
        //teamSuccessFactors = allTeamSuccessFactorList.filter(item => item.success_factor_id == successFactorDataChart.id);
        teamSuccessFactors = allTasks.filter(item => item.success_factor_id == successFactorDataChart.id); 
        hasData = teamSuccessFactors.length > 0 ? true : false;
      }
      allTasks = teamSuccessFactors;
      ///////////////////
      let sColors = {
        YETTOSTART: "#c8c6c6",
        INPROGONTRACK: "#7db1f5",
        INPROGONDELAY: "#f5c947",
        COMPLETED: "#79da8d",
      };
      let sDate, eDate, asDate, aeDate, cc, dd;
      let singleItem = {};
      let secondItem = {};
      let finalItems = [], formatData = {};
      if (allTasks) {
        // this.sortByKeyAsc(allTasks, "parent_task_display_id");
        allTasks.map((item, idx) => {
          if ((item.task_type == 1 || item.task_type == 2) && item.base_date && loadChart) {
            startDate = item.planned_start_date;
            sDate = item.planned_start_date ? item.planned_start_date : new Date().toLocaleDateString();
            eDate = item.planned_end_date ? item.planned_end_date : new Date(startDate).setDate(new Date(startDate).getDate() + 7);
            asDate = item.actual_start_date ? item.actual_start_date : new Date().toLocaleDateString();
            aeDate = item.actual_end_date ? item.actual_end_date : item.planned_end_date;
            if (!flagTime) {
              sDate = new Date(new Date(sDate).getTime() + loctime);
              eDate = new Date(new Date(eDate).getTime() + loctime);
            } else {
              sDate = new Date(new Date(sDate).getTime() - loctime);
              eDate = new Date(new Date(eDate).getTime() - loctime);
            }
            xx = sDate;//item.planned_start_date?item.planned_start_date:new Date().toLocaleDateString();
            let fEnddate = false;
            if (!item.planned_end_date) {
              fEnddate = true;
            }
            yy = eDate;//item.planned_end_date ? item.planned_end_date:new Date(startDate).setDate(new Date(startDate).getDate()+30); 
            cc = asDate;
            dd = aeDate;

            if (!item.actual_end_date) {
              let aa = new Date(yy).getTime() - new Date(xx).getTime();
              dd = new Date(cc).getTime() + aa;
            }

            clr = (clr + 1);
            let colors = color + (clr < 10 ? ("00" + clr) : clr < 100 ? ("0" + clr) : clr); 
            if (fEnddate) {
              // console.log("END DATE - " , startDate, yy );
              colors = "#B3936D";
            } else {
              colors = item.task_status ? sColors[item.task_status] : "#c8c6c6";
            }

            xx = new Date(xx);
            yy = new Date(yy);
            cc = new Date(cc); 
            dd = new Date(dd);  
            
            minDates.push(new Date(xx).getTime());
            maxDates.push(new Date(yy).getTime());
            // yax = yax==1?0:1;
            if (item.recommended_start_days >= 0) {
              // color="#007"; 
            }

            currParentName = this.getFactorName(item);//getParentName(item);
            if ((idx == 0 || currParentName != prevParentName) && item.task_type == 1) {
              finalData.push({
                name: currParentName + "***",
                id: currParentName,
                color: "#33609C",
                y: yax,
              });
              categories.push(currParentName + "***");
              cnt++;
              yax++; 
            } 
 
            currTaskName = item.custom_task_name.replace(/"/g, "'");
            singleItem = {};
            secondItem = {};
            if (yy)
              maxModDates.push(new Date(yy).getTime());
            if (item.task_type == 1) {
 
              singleItem = {  
                completed_per: item.completion_percentage,
                assignee: getStatusClass(item.task_status),
                color: item.task_status ? sColors[item.task_status] : "#c8c6c6", //colors,
                // assignee:  getStatusClass(item.task_status), 
                y: yax,  
                pointWidth: 28,
                start: Date.UTC(xx.getFullYear(), xx.getMonth(), xx.getDate()),
                end: item.task_type == 1 ? Date.UTC(yy.getFullYear(), yy.getMonth(), yy.getDate()) : "",
              };

              secondItem = {
                completed: {
                  amount: (item.completion_percentage / 100),
                  fill: item.task_status ? statusColors[item.task_status] : "#aeacac",
                },
                completed_per: item.completion_percentage,
                pointWidth: 18,
                color: item.task_status ? statusColors[item.task_status] : "#aeacac", //colors,
                // assignee:  getStatusClass(item.task_status), 
                y: yax,  
                type: "actual",
                start: Date.UTC(cc.getFullYear(), cc.getMonth(), cc.getDate()),
                end: item.task_type == 1 ? Date.UTC(dd.getFullYear(), dd.getMonth(), dd.getDate()) : "",
              };
            } else if (item.task_type == 2) {
              sDate = allTasks[idx - 1].planned_start_date ? allTasks[idx - 1].planned_start_date : new Date().toLocaleDateString();
              if (!flagTime) {
                sDate = new Date(new Date(sDate).getTime() + loctime);
              } else {
                sDate = new Date(new Date(sDate).getTime() - loctime);
              }
              xx = sDate;
              singleItem = {
                color: '#EA3A77',//'#FECA04',
                y: yax,
                x: Date.UTC(xx.getFullYear(), xx.getMonth(), xx.getDate()),
                x2: maxModDates ? Math.max(...maxModDates) : "",
              };
            }
            let singleItems = singleItem;
            finalItems = []; formatData = {};
            finalItems.push(singleItems);
            finalItems.push(secondItem);
            formatData = {
              name: currTaskName,
              current: 0,
              data: finalItems
            }
            finalData.push(formatData);
            yax++;
            cnt++;
            if (item.task_type == 1) {
              category = (item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name)) + "~-~" + getStatusClass(item.task_status)
            } else if (item.task_type == 2) {
              category = (item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name)) + "!-!"
            }
            categories.push(category);
          }
          prevParentName = currParentName;
          return null;
        });
      };
      if (minDates.length > 0) {
        minX = new Date(Math.min(...minDates));
        maxX = new Date(Math.max(...maxDates));
        minX = minX ? Date.UTC(minX.getFullYear(), minX.getMonth(), minX.getDate()) : Date.UTC(2022, 1, 1)
        maxX = maxX ? Date.UTC(maxX.getFullYear(), maxX.getMonth(), maxX.getDate()) : Date.UTC(2022, 12, 31)
      }
      let teamName = "", retval = "";
      let displayData = [];
      displayData = (finalData);
      if (this.state.teamData) {
        teamName = this.state.teamData.team_name;
        teamName = teamName.length > 20 ? teamName.substring(0, 20) + "..." : teamName;
      }
      var chartTitle = LBLTASK + " (" +  teamName + ")"; 
      goptions = {
        chart: {
          height: $("#root").height()>600? $("#root").height() - 143:460,
          scrollablePlotArea: {
            //     minHeight:(getHeight())
          },
          marginRight: 30
        },
        exporting: {
          filename: 'Planning-GanttChart',
          chartOptions: {
            chart: {
              height: displayData.length>3?(displayData.length) * 55:(displayData.length)*120,
              // width: 1700,
            },
            //style: {fontSize: '14px'},
            yAxis: {
              max: undefined,
              min: undefined,
              scrollbar: {
                enabled: false,
              }
            },
            scrollbar: {
              enabled: false
            },
            rangeSelector: {
              enabled: false,
            },
          }
        },
        credits: {
          enabled: false
        },
        series: displayData,
        dataLabels: [{
          enabled: true,
          format: '<div class="cls_chart_image_container" >' +
            '<img> src="../images/steercom/{point.assignee}.png" class="cls_chart_image"></div> ',
          useHTML: true,
          align: 'left'
        }, {
          enabled: true,
          format: '<span style="font-size: 1.5em">{point.complete_per}</span>',
          useHTML: true,
          align: 'right'
        }],
        title: {
          text: ''
        },
        tooltip: {
          xDateFormat: '%A, %b %e, %Y',
          formatter: function () {
            let parent = (this.point.parent) ? (this.point.parent) : this.key;
            parent = this.yCategory;
            parent = parent.length > 50 ? parent.substring(0, 49) + "..." : parent;
            let startDate, endDate, type = this.point.type ? 'Actual ' : "Plan ";
            startDate = this.point.type? LBLACTUALSDATE :LBLPLANNEDSTARTDATE + ': ';
            endDate = this.point.type? LBLACTUALEDATE :LBLPLANNEDENDDATE+ ': ';
            if (isNaN(parent) && parent.indexOf("***") > 1) {
              parent = parent.split("***")[0];
            } else if (isNaN(parent) && parent.indexOf("~-~") > 1) {
              parent = parent.split("~-~")[0];
            } else if (isNaN(parent) && parent.indexOf("!-!") > 1) {
              parent = parent.split("!-!")[0];
            }
            retval = parent + '<br />' + startDate + Highcharts.dateFormat('%A, %b %d %Y', this.x) + '<br />' + endDate + Highcharts.dateFormat('%A, %b %d %Y', this.x2)
            return retval;
          }
        },
        lang: {
          accessibility: {
            axis: {
              xAxisDescriptionPlural: 'The chart has a two-part X axis showing time in both week numbers and days.',
              yAxisDescriptionSingular: 'The chart has a tabular Y axis showing a data table row for each point.'
            }
          }, 
          contextButtonTitle: LBLCONTMENU,
          decimalPoint:'.',
          printChart:LBLPRINTCRT,
          downloadJPEG:LBLDNJPEG,
          downloadPDF:LBLDNPDF,
          downloadPNG:LBLDNPNG,
          downloadSVG:LBLDNSVG, 
          downloadCSV:'Download CSV',
          downloadXLS:'Download XLS', 
          exitFullscreen:'Exit from full screen',
          viewFullscreen:LBLVWFULL
        },
        accessibility: {
          point: {
            descriptionFormatter: function (point) {
              var completedValue = point.completed ?
                point.completed.amount || point.completed : null,
                completed = completedValue ?
                  ' Task completed ' + Math.round(completedValue * 1000) / 10 + '%.' :
                  '';
              return Highcharts.format(
                '{point.yCategory}.{completed} Start {point.x:%Y-%m-%d}, end {point.x2:%Y-%m-%d}.',
                { point, completed }
              );
            }
          }
        },
        xAxis: {
          currentDateIndicator: false
        },
        yAxis: {
          min: 0,
          //max: 2,
          type: 'category',
          useHTML: true,
          title: {
            text: (LBLTASK),
            paddingBottom: 20
          },
          scrollbar: {
            enabled: true
          },
          max: displayData.length > 6 ? 6 : displayData.length - 1,
          grid: {
            columns: [{
              title: {
                text:  "<div title='" + teamName + "' style='white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width:99%;display:inline' class='cls_ellipsis'>" + chartTitle + "</div>" ,
              },
              categories: categories
            },
            ]
          },
          labels: {
            align: 'left',
            useHTML: true,
            style: {
              textOverflow: 'ellipsis',
            },
            formatter: function (x, y) {
              let assignee, divs = "", vals = this.value;
              if (isNaN(vals) && vals.indexOf("***") > 1) {
                vals = vals.replace("***", "");
                divs = '<span style="color:#4C1327; font-style:italic; font-weight:600; float:left">' + vals + '</span>';
              } else if (isNaN(vals) && vals.indexOf('~-~') > 1) {
                vals = this.value.split("~-~")[0];
                assignee = this.value.split("~-~")[1];
                divs = '<div title="' + vals + '" ><img class="cls_chart_image_container" src="../images/steercom/' + assignee + '.png" ></img><span style=" ">' + vals + '</span></div>';
              } else if (isNaN(vals) && vals.indexOf('!-!') > 1) {
                vals = this.value.split("!-!")[0];
                assignee = this.value.split("!-!")[1];
                divs = '<span style="color:#EA3A77; font-style:italic; font-weight:600; float:left">' + vals + '</span>';
              }
              return divs;
            }
          },
        },
        scrollbar: {
          enabled: true
        },
        rangeSelector: {
          enabled: true,
        }
      }
    }
    return (
      <div className="mob-pad success-factors"> 
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage>
        <div id="dialog3">
          <Dialog 
            className="cls_dialog success-factors"
            open={this.state.opendialog3}
            onClose={this.handleCloseDialog3}
            aria-labelledby="responsive-dialog-title"
            contentStyle={{ minWidth: "500px" }}
          >
            <div>
              <DialogTitle id="responsive-dialog-title" className="mui-title">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div>{LBLSTEERCOMSFTRACK} </div>
                  <i style={{ margin: "2px 13px 0px 13px"}} class="fas fa-times fas fa-bars mui-close-icon" onClick={this.handleCloseDialog3}></i>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  <div className="cls_confirm_details mui-modal-body" > {LBLUNSAVEDATA} </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <button autoFocus id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog3} >
                  {LBLNO}
                </button>
                <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog3} >
                  {LBLAGREE}
                </button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
        {this.state.showGantPopup == false ?
          <div>
            <div className="page-header-field">
              <h3 className="page-title" title={this.props.labels.labels.LBLBACK} id="header_det_plan">
                <i
                  className="fas fa-arrow-left backBtn"
                  aria-hidden="true"
                  //style={{display:window.location.href.indexOf("/home/")>-1?"none":""}} 
                  style={{
                    display:
                      this.props.userId == this.state.teamData.created_by ||
                        this.props.userId == this.state.teamData.team_administrator ||
                        this.props.userId == this.state.teamData.team_addnl_administrator ||
                        this.props.role_id == 1 ? "" : "none"
                  }}
                  onClick={() => {
                    let dirpath = "/schome/"
                    if (window.location.href.indexOf("/home/") > -1) {
                      dirpath = "/home/"
                    }
                    // let xx = g_sfid;
                    if (this.props.location.state && this.props.location.state.backlinkurl == "successfactorsetup") {
                      this.props.history.push({
                        pathname: dirpath + "successfactors",
                        state: {
                          backlinkurl: this.props.location.state.backlinkurl,
                          successFactorData: g_sfid ? g_sfid : this.props.location.state.successFactorData,
                          teamData: this.state.teamData
                        }
                      });
                    } else {
                      this.props.history.push({
                        pathname: dirpath + "successfactors",
                        teamData: this.state.teamData
                      });
                    }

                  }}
                ></i>
                <span id="subheader_det_plan">{LBLSTEERCOMSFTRACK}</span>
              </h3>

              <div className="btn-aligns">
                {mobView?"":
                <button
                  style={{ display: filterSuccessFactorsListData.length > 0 && this.state.base_date ? "" : "none", marginRight: "10px", pointerEvents: this.state.base_date ? "all" : "none" }}
                  className="button-style primary-btn"
                  onClick={this.showGanttChartPopup.bind(this, 1)}
                >
                  <span id="ganttchart"> {LBLGANTTCHART ? LBLGANTTCHART : "Gantt Chart"} </span>
                </button>
                }
                <button style={{
                  marginRight: "20px",
                  display:
                    this.props.userId == this.state.teamData.created_by ||
                      this.props.userId == this.state.teamData.team_administrator ||
                      this.props.userId == this.state.teamData.team_addnl_administrator ||
                      this.props.role_id == 1 ? "" : "none"
                }}
                  className="button-style primary-btn"
                  onClick={() => {
                    let dirpath = "/schome/"
                    if (window.location.href.indexOf("/home/") > -1) {
                      dirpath = "/home/"
                    }
                    let backlinkurl = "steeringcommittee"
                    this.props.history.push({
                      pathname: dirpath + "steeringcommittee",
                      state: {
                        backlink: true,
                        backlinkurl: backlinkurl,
                        successFactorData: this.state.successFactorData,
                        teamData: this.state.teamData
                      }
                    });
                  }}
                >
                  <span id="updateteam"> {LBLSTEERCOMTEAMS}</span>
                </button>
                {(this.props.userId == this.state.teamData.created_by ||
                  this.props.userId == this.state.teamData.team_administrator ||
                  this.props.userId == this.state.teamData.team_addnl_administrator) ?
                  <div className="btn-group ml-auto mr-3" role="group"
                    style={{ marginTop: "-12px" }}>
                    <span
                      id="CSTFilter_spinner"
                      style={{
                        marginTop: "10px",
                        display: "none",
                        marginRight: "10px",
                      }}
                    >
                      {/* <Spinner animation="grow" /> */}
                      <Loader></Loader>
                    </span>
                    <button
                id="btnGroupDrop1"
                //className="Exportbtnclass"
                type="button"
                className="btn basicSelItem dropdown-toggle cls_export"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-mail-forward" aria-hidden="true">
                  &#xf064;
                </i>{" "}
                <span style={{padding: '5px 10px'}}>{LBLEXPORT}</span>
              </button>
              <div
                className="dropdown-menu  dropdown-menu-right"
                aria-labelledby="btnGroupDrop1"
              >
                {!this.props.restrictExport || (this.props.restrictExport && this.props.restrictExport !== "csv") ?
                  <span
                    className="dropdown-item export-item"
                    onClick={this.onExport.bind(this, "csv")}
                  >
                    {LBLEXPORTTOCSV}
                  </span>
                  : ""
                }

                <span
                  className="dropdown-item export-item"
                  onClick={this.onExport.bind(this, "xlsx")}
                >
                  {LBLEXPORTTOEXCEL}
                </span>
              </div>
                  </div>
                  : ""}
              </div>
            </div>
            <hr className="head-line" />
          </div> : ""}
        {this.state.showGantPopup == false ?
          <div>
            <div
              className="team_main_container"
              style={{ paddingTop: "0px", margin: "0px" }}
            >
              <Snackbar 
                style={{ width: mobView ? "100%" : "50%",margin:"0 25%" ,justifyItems: "center" }} 
                anchorOrigin={{ horizontal, vertical }}
                key={"top-center"}
                open={this.state.openalert}
                autoHideDuration={
                  this.state.showtime ? this.state.showtime : 2000
                }
                onClose={this.handleCloseAlert}
                bodyStyle={{ backgroundColor: "teal", color: "coral" }}
              >
                <Alert
                  variant="filled"
                  onClose={this.handleClose}
                  severity={this.state.messageType}
                >
                  {(showMessage1 == "" ? this.state.message :
                    <>{this.state.message} <br /> <br /> <span style={{ fontSize: "15px", marginTop: "-10px" }}>{showMessage1}</span>
                    </>
                  )}
                </Alert>
              </Snackbar>
              <Modal
                animation={false}
                show={this.state.showDescModal}
                onHide={this.closeDescription}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton className='alert-modal-header'>
                  <Modal.Title className="alert-modal-title">
                     {this.state.nameData}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    {this.state.descriptionData}
                  </div>
                </Modal.Body>
                <Modal.Footer style={{borderTop:"none"}}>
                  <div className="ssi-feeds-commentsActionBtn"></div>
                </Modal.Footer>
              </Modal>
              <Modal
                animation={false}
                show={this.state.showmodal}
                onHide={this.closeRefModal}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton className='alert-modal-header'>
                  <Modal.Title className="alert-modal-title">
                      {LBLREFERENCELNKS}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ReferenceMaterial
                    initialValues={this.state.initialValues}
                    resultData={this.props.productListData}
                    labels={this.props.labels}
                    changesIn={this.changesIn}
                    closeModal={this.props.closeRefModal}
                    resourceData={this.state.resourceData}
                    resourceDataCustom={this.state.resourceDataCustom}
                  ></ReferenceMaterial>
                </Modal.Body>
              </Modal>

              {this.state.loading ? (
                <div>
                  <div className="cls_top_border">
                    <div class="row">
                      {this.state.successFactorsList.length > 0 ?
                        <div  className="col-sm-3">
                          <label className="label ">{LBLSF}</label>
                          <ThemeProvider theme={theme}>
                          <Autocomplete
                            style={{ width: "calc(100%)" }}
                            label={LBLSF}
                            placeholder={LBLSF}
                            disableClearable
                            options={this.state.successFactorsList}
                            getOptionLabel={(option) => option.title}
                            getOptionSelected={(option, values) =>
                              option.id === values.id
                            }
                            onChange={this.handleSFChange.bind(this)}
                            underlineStyle={{ display: "none" }}
                            value={this.state.successFactorData}
                            defaultValue={this.state.successFactorData}
                            renderOption={(option) => (
                              <div style={{ fontSize: "14px" }}>{option.title}</div>
                            )}
                            renderInput={(params) => (
                              <TextField id="sflist" {...params} />
                            )}
                            underline="none"
                          />
                          </ThemeProvider>
                        </div> : ""}
                      <div className="col-sm-3" style={{ height: "80px" }}>
                        <label className="label ">{LBLTEAM}</label>
                        {
                          this.state.teamList.length > 0 && this.state.teamData.team_id ? <>
                            <ThemeProvider theme={theme}>
                            <Autocomplete
                              style={{ width: "calc(100%)" }}
                              label={LBLTEAM}
                              disableClearable
                              options={this.state.teamList}
                              getOptionLabel={(option) => option.team_name.toString()}
                              getOptionSelected={(option, values) =>
                                option.team_id === values.team_id
                              }
                              onChange={this.handleTeamChange.bind(this)}
                              underlineStyle={{ display: "none" }}
                              defaultValue={
                                this.state.teamData
                              }
                              renderOption={(option) => (
                                <div style={{ fontSize: "14px" }}>{option.team_name}</div>
                              )}
                              renderInput={(params) => (
                                <TextField id="tmlist" {...params} />
                              )}
                              underline="none"
                            />
                            </ThemeProvider>
                          </> : <>
                            <div>
                            <ThemeProvider theme={theme}>
                              <Autocomplete
                                style={{ width: "calc(100%)" }}
                                label={LBLTEAM}
                                disableClearable
                                options={[{ "team_id": 0, "team_name": LBLNONE }]}
                                getOptionLabel={(option) => (option || { "team_id": 0, "team_name": LBLNONE }).team_name.toString()}
                                getOptionSelected={(option, values) =>
                                  option.team_id === values.team_id
                                }
                                onChange={this.handleTeamChange.bind(this)}
                                underlineStyle={{ display: "none" }}
                                defaultValue={
                                  { "team_id": 0, "team_name": LBLNONE }
                                }
                                renderInput={(params) => (
                                  <TextField id="tmlist" {...params} />
                                )}
                                underline="none"
                              />
                              </ThemeProvider>
                            </div>
                          </>
                        }
                        {(this.props.userId == this.state.teamData.created_by ||
                          this.props.userId == this.state.teamData.team_administrator ||
                          this.props.userId == this.state.teamData.team_addnl_administrator || this.state.teamData.memberofteamsf > 0) ? "" :
                          <div className="cls_view_mode">{this.state.teamList.length > 0 && this.state.teamData.team_id ? LBLVIEWMODE : "\n"}
                          </div>}
                      </div>
                      <div className="col-sm-2"></div>
                      {mobView ? "" :
                        <div className="col-sm-4" style={{ display: "flex", margin: "auto" }}>
                          <span className="cls_header_names" style={{ position: "relative" }}>
                            <img
                              src="../images/steercom/admin.png"
                              title="Admin"
                              className="img_team_admin"
                              alt=""
                            ></img>{" "}
                            {LBLTEAMADMINS}
                          </span>
                          :
                          <span
                            title={teamData.adminname}
                            style={{ paddingLeft: "5px" }}
                            className="team-dtl"
                          >
                            {teamData.adminname}
                          </span>
                        </div>}
                      <div className="col-sm-4" style={{ display: "none" }}>
                        <div className="row">
                          <div className="col-sm-7">
                            <label>{LBLBASEDATE}</label>
                            <MuiPickersUtilsProvider
                              className="member_startdate"
                              utils={DateFnsUtils}
                              style={{
                                width: "calc(100% - 1px)",
                                float: "right",
                              }}
                            >
                              <Grid container justify="space-around">
                                <KeyboardDatePicker
                                  margin="normal"
                                  minDate={"01-01-2021"}
                                  id={"top_basedate"}
                                  format={this.state.dateformat}
                                  value={this.state.base_date}
                                  onChange={this.handleBaseDateChange.bind(
                                    this,
                                    "base_date"
                                  )}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                  style={{ width: "100%" }}
                                  autoFocus={false}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </div>
                          <div className="col-sm-5 ">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              id="btnaddCancel"
                              onClick={this.saveSFBaseDate.bind(this)}
                              style={{
                                float: "left",
                                marginTop: "23px",
                                marginLeft: "-30px",
                                width: "max-content",
                              }}
                            >
                              {LBLUPDATEBASEDATE}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  {mobView ? "" :
                    <div
                      id="legends"
                      class="row"
                      style={{ width: "100%",marginTop:"5px", float: "left", display: this.state.teamSuccessFactorList.length > 0 ? "" : "none" }} //marginBottom:"-18px"
                    >
                      <div className="cls_legends_name">{LBLLEGENDS}</div>
                      <div className=" cls_legends">
                        <img
                          src={"../images/steercom/notstarted.png"}
                          className="img_team_status"
                          alt=""
                        ></img>
                        <div class="cls_img_status">{LBLYETTOSTART}</div>
                      </div>
                      <div className=" cls_legends">
                        <img
                          src={"../images/steercom/ontrack.png"}
                          className="img_team_status"
                          alt=""
                        ></img>
                        <div class="cls_img_status">{LBLINPROGONTRACK}</div>
                      </div>
                      <div className=" cls_legends">
                        <img
                          src={"../images/steercom/delayed.png"}
                          className="img_team_status"
                          alt=""
                        ></img>
                        <div class="cls_img_status">{LBLINPROGONDELAY}</div>
                      </div>
                      <div className=" cls_legends">
                        <img
                          src={"../images/steercom/completed.png"}
                          className="img_team_status"
                          alt=""
                        ></img>
                        <div class="cls_img_status">{LBLCOMPLETED}</div>
                      </div>
                      <div className=" cls_legends">
                        <img style={{ transform: "scale(0.5)" }}
                          src={"../images/steercom/Expired.png"}
                          className="img_team_status"
                          alt=""
                        ></img>
                        <div style={{ width: "150px" }} class="cls_img_status">{LBLEXPIRED}</div>
                      </div>
                    </div>
                  }
 
                  <div class="row cls_data_table">   
                    <div
                      style={{
                        marginBottom: "0px",
                        width: "calc(100% + 140px)",
                        marginLeft: mobView ? "-18px" : "-47px",
                      }}
                    >
                      {this.state.isloading? 
                      <div style={{margin:'10% 0px'}}><Loader></Loader></div> : 
                       this.state.teamSFListLoading == true && this.state.teamList.length > 0 && this.state.teamData.team_id && this.state.teamSuccessFactorList.length > 0 ? (

                        <DataTable
                          id="sortable1"
                          columns={teamSuccessFactorColumns}
                          data={teamSuccessFactorListData}
                          className="datatable-list"
                          onColumnOrderChange={cols => console.log(cols)}
                          style={{
                            position: "relative",
                            top: mobView ? "-115px" : "-42px"
                          }}
                          fixedHeader
                          fixedHeaderScrollHeight={$("#root").height() - 315 + "px"}
                          subHeader="true"
                          subHeaderAlign="left"
                          subHeaderComponent={<Tooltip class="cls_add_expand_collapse"
                            title={""} arrow>
                            <div className="cls_add_expand_collapse" style={{ top: "10px", display: "none" }}>
                              <img
                                src={this.state.expandFlag ?
                                  "../images/steercom/expanded.png" :
                                  "../images/steercom/collapsed.png"
                                }
                                className="img_collapse_all"
                                alt=""
                                onClick={this.updateExpandFlagAll.bind(
                                  this, this.state.expandFlag
                                )}
                                title={this.state.expandFlag ? "Collapse All" : "Expand All"}
                              ></img> 
                            </div>
                          </Tooltip>} 
                        />
                      ) : ( 
                        <div
                          class="cls_norecords"
                          style={{ borderTop: "0px solid lightgrey" }}
                        > 
                          {this.state.teamlistcnt == true && this.state.teamList.length == 0 ?
                           <EmptyState image={emptyImage} text2={LBLPLANNORECORDS}/>
                          : <EmptyState image={emptyImage} text2={LBLPLANNORECORDS}/>
                          }
                        </div>
                      )}
                    </div>

                  </div>
                  {this.state.isPriorTeam ? (
                    ""
                  ) : (
                    <div class="row" style={{ borderTop: "0px solid lightgrey" }}>
                      {this.props.userId == this.state.teamData.created_by ||
                        this.props.userId == this.state.teamData.team_administrator ||
                        this.props.userId == this.state.teamData.team_addnl_administrator || this.state.teamData.memberofteamsf > 0 ? (
                        <></>
                      ) : (
                        ""
                      )}
                    </div>
                  )}

                  <br></br>
                  <br></br>
                  {this.state.isPriorTeam ? (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      id="btnaddCancel"
                      onClick={this.loadMemberList.bind(this)}
                      style={{
                        float: "right",
                        marginRight: "0px",
                        lineHeight: "17px",
                      }}
                    >
                      {LBLCANCEL}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      id="btnaddCancel"
                      onClick={this.props.closeModal}
                      style={{
                        float: "right",
                        marginRight: "0px",
                        display: "none",
                        lineHeight: "17px",
                      }}
                    >
                      {LBLCANCEL}
                    </button>
                  )}
                  <br></br>
                </div>
              ) : (
                <Skeleton height={300} />
              )}
            </div>
          </div> :  
          <div className="cls_chart_container" style={{ marginTop: "-5px" }}>
            <div className="page-header-field" style={{ marginTop: "5px" }}>
              <h3 className="page-title" id="header_det_list">
                <i
                  className="fas fa-arrow-left backBtn"
                  aria-hidden="true"
                  onClick={this.closeGantPopup.bind(this, 1)}
                ></i>
                <span id="subheader_det3">{this.props.labels.labels.LBLSTEERCOMSFTRACK}</span>
              </h3>
              <div className="row" style={{ display: "flex", width: "55%" }}>
                <div className="col-sm-7" style={{ display: "flex" }}>
                  <label style={{ position: "relative", top: "7px", right: "4px",marginTop:"0px" }} className="label">{LBLSF}</label>
                  <ThemeProvider theme={theme}>
                  <Autocomplete
                    id="memberss"
                    style={{ width: "calc(100%)", marginLeft: "20px" }}
                    label={LBLSF}
                    placeholder={LBLSF}
                    disableClearable
                    options={this.state.successFactorsList}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, values) =>
                      option.id === values.id
                    }
                    onChange={this.handleSFChangeChart.bind(this)}
                    underlineStyle={{ display: "none" }}
                    value={this.state.successFactorDataChart}
                    defaultValue={this.state.successFactorDataChart}
                    renderOption={(option) => (
                      <div style={{ fontSize: "14px" }}>{option.title}</div>
                    )}
                    renderInput={(params) => (
                      <TextField id="sflists" {...params} />
                    )}
                    underline="none"
                  />
                  </ThemeProvider>
                </div>
                <div className="col-xl-5" style={{ position: "relative", top: "2px" }}>
                  <div style={{ display: "flex" }}>
                    <label style={{ minWidth: "80px", marginTop: "4px" }} className="label">{LBLBASEDATE}</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="space-around">
                        <KeyboardDatePicker
                          margin="normal"
                          id="startdate"
                          //value={this.state.new_base_date}
                          format={this.state.dateformat}
                          value={this.state.selectedBaseDate}
                          onChange={this.handleStartDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          InputProps={{ readOnly: true }}
                          disabled={true}
                        />
                      </Grid>

                      <span style={{ display: "none" }} className="required" id="span_startdate">
                        {LBLREQUIRED}
                      </span>

                    </MuiPickersUtilsProvider>
                  </div>
                </div> 
              </div>
            </div>
            <hr></hr>
            {mobView ? "" :
              <div id="chart_legents" className="row cls_chart_legends">
                <div
                  id="legends"
                  class="row"
                  style={{ float: "left", marginTop: "10px", display: hasData ? "" : "none" }} //marginBottom:"-18px"
                >
                  <div className="cls_legends_name">{LBLLEGENDS}</div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/notstarted.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div style={{ marginLeft: "-7px" }} class="cls_img_status">{LBLYETTOSTART}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/ontrack.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLINPROGONTRACK}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/delayed.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLINPROGONDELAY}</div>
                  </div>
                  <div className=" cls_legends">
                    <img
                      src={"../images/steercom/completed.png"}
                      className="img_team_status"
                      alt=""
                    ></img>
                    <div class="cls_img_status">{LBLCOMPLETED}</div>
                  </div>

                  <div className=" cls_legends"> <div className="cls_milestone"></div>  {LBLMILESTONES}</div>

                </div> 
              </div>}
            {loadChart ? hasData ?
              <div className="cls_chart_main_container" >
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={"ganttChart"}
                  options={goptions}
                />
              </div> : <div style={{ margin: "10% 40%" }}><EmptyState image={emptyImage} text2={LBLPLANNORECORDS}/></div>
              : showLoading == true ?
                <div style={{ height: "200px", textAlign: "center", marginTop: "15%" }}>
                  <Loading></Loading>
                </div> :
                <div style={{ height: "200px", textAlign: "center", marginTop: "15%" }}>
                  {""}
                </div>}
          </div>
        }
        
      </div>
    );
  }
} 
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(SuccessFactorPlan);

import { HttpInterceptor } from '../auth/HttpInterceptor';
import CryptoJS from 'crypto-js';

class SSOService{
  ssoAuthDecoder = async (Tokeninfo)=>{
    try{
    if(!Tokeninfo)return false;

    var userinfobytes  = CryptoJS.AES.decrypt(Tokeninfo, 'secret');
    var userinfojson = userinfobytes.toString(CryptoJS.enc.Utf8);
    let userJson = JSON.parse(userinfojson);

    return userJson[0];
  }catch(err){
    return false;
  }}
  getCognitoResponse= async (params) =>{
    let response
    try {
        response = await HttpInterceptor.get("getCognitoResponse?code="+ params.code);
        return response
     } catch (error) {
      return error.response.data
     }
    }
    getSSOConnectionCreds = async (origin, conn_creds) => {
        try {
    let response = await HttpInterceptor.get(`sso/getSSOConnectionCreds?origin=${encodeURIComponent(origin)}&conn_creds=${conn_creds}`);

         // console.log(response.data, "responseresponseresponseresponse");
          if(conn_creds == "auth0_conn_creds"){
            return response.data.responseurl;
          }
          else{
            return response.data.responseurl;
          }

        } catch (error) {
          console.error("Error in getSSOConnectionCreds:", error);
          throw error;
        }
      };
      
    getAuth0Response= async (params) =>{
      let response
        try {
            response =  await HttpInterceptor.get("getAuth0AccessToken?code="+ params.code);
            return response
         } catch (error) {
          return error.response.data
         }
    }

    getClasslinkClientAccess= async (params) =>{
      let response
      try {
          response =  await HttpInterceptor.get("getClasslinkAccess?code="+ params.code);
          return response
       } catch (error) {
        return error.response.data
       }
    }
}
export default new SSOService;

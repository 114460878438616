import React, { useState } from "react";
import { MenuItem, Select, Box, } from "@mui/material";
import styles from "./ManagementHeader.module.scss";
import ViewModal from "./ViewModal";
import "./ManagementHeader.module.scss"

const TrainingManagementHeader = (props) => {
  const {
    LBLCOURSESSELECTED,
    LBLVIEW,
    LBLCLEAR
  } = props.labels
  const [showList, setShowList] = useState(false);
  const handleView = () => {
    setShowList(true);
  }
  const closeMoreModal = () => {
    setShowList(!showList)
  }
  let Sitetype=localStorage.getItem('site_type');
  return (
    <>
      <div className={Sitetype==='SafeStart'?styles.container:styles.greencontainer }>
        <ViewModal
          showList={showList}
          closeMoreModal={closeMoreModal}
          label={LBLCOURSESSELECTED}
          selectedRows={props.selectedRows}
        />
        <div className={Sitetype==='SafeStart'?styles.selectedcount: styles.greenselectedcount}>{props.selectedRowsCount}</div>
        <span className={styles.coursesselected}>{LBLCOURSESSELECTED}</span>
        {props.assignmentstate === "products" ?
          <span className={styles.clearbtn} onClick={props.handleClear}>{LBLCLEAR}</span>
          :
          <span className={styles.clearbtn} onClick={handleView}>{LBLVIEW}</span>
        }
        {props.assignmentstate !== "products" &&
          <>
            <div className={styles.hrline}></div>
            <div className={Sitetype==='SafeStart'?styles.selectedcount:styles.greenselectedcount}>{props.usersGroupsSelectedCount}</div>
            <span className={styles.coursesselected}>{props.usersGroupsSelected}</span>
            <span className={styles.clearbtn} onClick={props.handleClear}>{LBLCLEAR}</span>
          </>
        }
        <button className={Sitetype==='SafeStart'?styles.selectallbtn:styles.greenselectallbtn} onClick={props.handleRowChange}>{props.selectAll}</button>
        {props.assignmentstate === "products" &&
          <div className={styles.hrline}></div>
        }
        {props.showdropdown === true && props.dropObj !== null &&
          <Box style={{ minWidth: 190, display: "flex", marginRight: 5, }}>
            {props.dropObj.map((item, index) => {
              return (
                <Select
                  labelId="select-status"
                  id="select-status"
                  value={item.selectedValue}
                  onChange={(e) => item.changeHandler(e)}
                  style={{
                    height: "40px", width: "190px",  color: "#FFF", 
                  }}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 ,border:"none"}
                  }}
                  className={Sitetype==='SafeStart'?styles.selectedactbtn:styles.greenselectedactbtn}
                  key={index}
                >
                  {item.data.map((subitem, subindex) => {
                    return (
                      <MenuItem key={subindex} style={{ fontSize: 14, display: subindex === 0 ? "none" : "block" }} value={subitem.value} disabled={subitem.diasabled}>
                        {subitem.lable}
                      </MenuItem>
                    )
                  }
                  )
                  }
                </Select>
              )
            }
            )
            }
          </Box>
        }
      </div>
      {props.visibleRowsSelected ?
        <div className={styles.visiblerowsselected}>
            {props.visiblerowsselectedLabel}
        </div>
        :
        null
      }
    </>
  );
}

export default TrainingManagementHeader;
import React,{ useRef, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Loading from "../../../shared/Loading";
import orderBy from "lodash/orderBy";
import { connect } from "react-redux";
import "./steercomreports.scss";
import { TextField, withStyles, Checkbox } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CanvasJSReact from "../../../assets/canvasjs/canvasjs.react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Multiselect } from "multiselect-react-dropdown";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import { isUndefined } from "lodash";
import labelss from "./translations/steercomreports.json"
import MultiSelect from "./multiselect";
import * as FileSaver from "file-saver";
import Loader from "../../components/loader/Loader"; 
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt"; 
import { red } from "@material-ui/core/colors";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { enUS,itIT,esES,deDE, ptPT, ptBR, frFR,svSE, etEE, faIR, fiFI ,ruRU} from '@material-ui/core/locale';
import emptyImage from '../../../assets/images/rateyourstate_emptyst.png';
import EmptyState from "../../../common_components/empty_state/Empty_state";
 
let locale_code = {"en-US":enUS,"it-IT":enUS,"es-MX":esES,"de-DE":deDE,
                   "pt-BR":ptBR,"fr-FR":frFR,"sv-SE":svSE,"ru-RU":ruRU};
let theme = createTheme(  enUS); 

const Excel = require('exceljs');

const $ = require("jquery"); 
highchartsGantt(Highcharts); 
require("highcharts/modules/exporting")(Highcharts);

let customLabels =  require("./translations/steercomreports.json");
let prevX = 0;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let parentask = "", loadanimate = true;
let team_count;
let stack_smoptions={};
class SteercomReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: [],
      graphType: "Bar",
      team_count:false,
      options: {},
      team_filter: false,
      dataPoints: [],
      loading: false,
      isAdminUser: true,
      topLoading: true,
      currSuccessFactor: "SF1",
      filterTeamList: [],
      filterTeamSummaryList: [],
      team_name: "",
      teamsummary_sfid: "Y1SF1",
      teammtsummary: [],
      loadAdminList: false,
      teamsummary: [],
      dateformat: props.dateformat,
      team_details: [],
      loadStatusSummary: false,
      mobView:false, 
      selectedTeams:[],
      showExport:false,
      teamSuccessFactorList: []
    }; 
    theme = createTheme( locale_code[this.props.language_code]);
  }
  componentDidMount() {
    $('#root').scrollTop(0);
    $("#back_to_list").show();
    $("#header_det_admin").html(this.props.labels.labels.LBLSTEERTASKS)
    let currstatus = this.props.currstatus;
    this.getSteercomSettings();
    this.setdateformat();
    this.getTeamList();
    let mobView = this.isMobileView();//navigator.userAgentData.mobile;
  
    this.setState({ 
      mobView:mobView
    }); 
  }
  isMobileView=()=>{
    let mView=false;
    if(/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone|Opera Mini/i.test(navigator.userAgent)
    || window.screen.availWidth < 750 || document.body.clientWidth < 750){
      mView=true;
    } 
    return mView;
  }
  componentDidUpdate() {
    $(".rdt_TableBody").scroll(function () {
      var scrollLeft = $(this).scrollLeft();
      $(".rdt_TableHead").scrollLeft(scrollLeft);
    });
    $(document).click(function(obj){
      if(obj.currentTarget.activeElement.className.indexOf("MuiInputBase-input")>=0){
        if($(".MuiChip-label").length==0) {
          $("#process_reports").addClass("cls_disable");
        }
      }
    })
  }
  getTeamSummaryList = () => {
    let roleid = this.props.role_id;
    let team_id = 0
    let sfid = 0 
    this.getTeamSummaryListCallback(team_id, sfid, roleid)
  };
  getTeamSummaryListCallback = async (team_id, sfid, roleid) => {
    let res = await SteercomServices.getTeamSummary(team_id, sfid, roleid);
    let teamList1 = [],curr_teams=[], teamList = res.result;

    let prev_id="", curr_id="", teams = this.state.filterTeamList;

    teams.map((item, j) => {
      teamList.map((items, i) => {
        if (item.team_id == items.team_id && item.team_status != 0) {
          curr_id = item.team_id;
          teamList1.push(items);
          if(curr_id != prev_id){
            curr_teams.push(item);
            prev_id=curr_id;
          }
        }
      });
    }); 
    this.setState(
      {
        filterTeamSummaryList: teamList1, 
        topLoading: false,
        filterTeamList: curr_teams,  
        teamList: curr_teams,
        all_team_list:teamList
      }
    );
          this.loadSuccessFactors(teamList1[0])
  };
  getTeamList = () => { 
    this.getTeamListCallback();
  };
  getTeamListCallback = async () => {
    let res = await SteercomServices.getTeamListDash(); 
    let dbteamLists = [], teamList = res.result;
    if (teamList.length > 0) {
      teamList.map((item, idx) => {
        if (item.team_status != 0) {
          dbteamLists.push(item);
        }
      })
    }
    let filterTeamList = dbteamLists.filter((item) => {
      if (this.props.role_id > 1) {
        if (item.team_administrator == this.props.userId || this.props.userId == item.team_addnl_administrator) {
          return item;
        } else if (item.memberofteam > 0) {
          return item;
        }
      } else {
        return item;
      }
    });
    let teamloading = false; 
    this.setState(
      {
        filterTeamList: filterTeamList, 
        loading: teamloading,
        teamList: filterTeamList
      },
      this.getTeamSummaryList()
    );
  };

  getFilteredSummary=()=>{
    let dbteamLists = [], teamList =  this.state.teamList;
    if (teamList.length > 0) {
      teamList.map((item, idx) => {
        if (item.team_status != 0) {
          dbteamLists.push(item);
        }
      })
    }
    let filterTeamList = dbteamLists.filter((item) => {
      if (this.props.role_id > 1) {
        if (item.team_administrator == this.props.userId || this.props.userId == item.team_addnl_administrator) {
          return item;
        } else if (item.memberofteam > 0) {
          return item;
        }
      } else {
        return item;
      }
    });
    
    let teamloading = false; 
    console.log("resresresresres");
    let teamList1 = [],curr_teams=[] ; 
    let prev_id="", curr_id="" , teams = this.state.filterTeamList;

    teams.map((item, j) => {
      teamList.map((items, i) => {
        if (item.team_id == items.team_id && item.team_status != 0) {
          curr_id = item.team_id;
          teamList1.push(items);
          if(curr_id != prev_id){
            curr_teams.push(item);
            prev_id=curr_id;
          }
        }
      });
    }); 
    
    this.setState(
      {
        filterTeamSummaryList: teamList1, 
        topLoading: false,
        filterTeamList: curr_teams,  
        teamList: curr_teams,
        loading: teamloading,
      }
    );
       this.loadSuccessFactors(teamList1[0])
  }
  getColors = (status) => {
    let colors = { "YETTOSTART": "#f5f4f5", "INPROGONTRACK": "#e2f3ff", "INPROGDELAYED": "#DBF2F3", "COMPLETED": "#DBF2F3" };
    let selcolor = colors[status];//colors[Math.floor(Math.random() * colors.length)];
    return selcolor;
  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    for (let x in steercomSettings) {
      steercomSettings[x].map((item, index) => {
        if (item.name && item.name.indexOf("LBL") == 0) {
          item.name = this.getLabel(item.name);
        }
        if (item.title && item.title.indexOf("LBL") == 0) {
          item.title = this.getLabel(item.title);
        }
        if (item.description && item.description.indexOf("LBL") == 0) {
          item.description = this.getLabel(item.description);
        }
      });
    }
    let obj = steercomSettings.successfactors.find(v => v.name === 'LBLALL');
    if (obj == undefined || obj.length == 0) {
      steercomSettings.successfactors.unshift({
        active: 1,
        color: "sf_color1",
        description: "",
        id: "0",
        name: "LBLALL",
        title: this.props.labels.labels.LBLALL,
        version: "1"
      });
    }
    if (this.props.location.state && this.props.location.state.successFactorData) {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: this.props.location.state.successFactorData,
      });
    } else {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: steercomSettings.successfactors[0],
      });
    }
  };
  getLabel = (xfield, mode) => {
    let labels = this.props.labels.labels ;// customLabels["en-US"].labels;//
    if (typeof xfield === "string") {
      if (labels.hasOwnProperty(xfield)) {
        return labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (
        typeof xfield === "object" &&
        labels.hasOwnProperty(xfield.label)
      ) {
        return labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield + "111";
      }
    } 
  };

  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
    return dateformat;
  };
  formattingDate = (date) => {
    let dateformat,
      fulldate = "";
    dateformat = this.state.dateformat;
    if (dateformat.toString().indexOf("YYYY") > 0)
      dateformat = this.setdateformat();
    let dateArray = date.split("/");
    if (date) {
      var d = new Date(), month, day, year;
      d = new Date(date);
      day = "" + d.getDate();
      if (d == undefined || isNaN(d.getDate())) {
        d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
      }
      month = "" + (d.getMonth() + 1);
      day = "" + d.getDate();
      year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      if (dateformat == "dd/MM/yyyy") {
        fulldate = [day, month, year].join("/");
      } else if (dateformat == "MM/dd/yyyy") {
        fulldate = [month, day, year].join("/");
      } else if (dateformat == "yyyy/MM/dd") {
        fulldate = [year, month, day].join("/");
      } else {
        fulldate = [day, month, year].join("/");
      }
    }
    return fulldate;
  };

  redirecttoplan = (item, event) => {
    let dirpath = "/schome/"
    if (window.location.href.indexOf("/home/") > -1) {
      dirpath = "/home/"
    }
    let backlinkurl = dirpath + "steercomdashboard";
    var teamData = this.state.teamList.find(function (items) {
      return items.team_id == item.team_id;
    });
    this.props.history.push({
      pathname: dirpath + "successfactorplan",
      state: {
        backlink: true,
        backlinkurl: backlinkurl,
        successFactorData: this.state.successFactorData,
        teamData: teamData,
        parentId: item.parent_task_display_id
      }
    });
  }
  sortResults = (arr, prop, asc) => {
    console.log("sortResults", arr, prop, asc)
    arr.sort(function (a, b) {
      console.log("a[prop]", a[prop], b[prop], (a[prop] > b[prop]))
      return 1
    });
  }
  getweekdays = (mode) => {
    let currDays, currentDate = new Date();
    if (isUndefined(mode)) {
      mode = 1;
    }
    if (mode == 0) {
      currDays = 7 - (new Date().getDay());
      currentDate = new Date(new Date().setDate(currDays));
    } else if (mode == 1) {
      currDays = 7 - (new Date().getDay());
      currentDate = new Date(new Date().setDate(currDays + 7));
    } else {
      currentDate = new Date(new Date().setMonth(360));
    }
    return currentDate;
  }

  selectedItemStatus = (item) => { 
    let { teammtsummary, filterTeamSummaryList } = this.state; 
    let currSuccessFactor = item.chart.options.data[0].chartName;
    let loadStatusSummary = false;
    this.getTeamSFList(0, 0, 1); 
  }
  getTeamSFList = (team_id, sfid, mode) => {
    loadanimate = true;
    if (
      this.state.team_details &&
      this.state.team_details.team_id
    ) {
      if (mode == 1) {
        loadanimate = false;
      }
      let roleid = this.props.role_id;
      let teamid = this.state.team_details.team_id;
      this.getTeamSFListCallback(teamid, sfid, roleid);
    }
  };
  getTeamSFListCallback = async (teamid, sfid, roleid) => {
    let mainTasksList = [], res = await SteercomServices.getTeamSFListDash(teamid, sfid, roleid);
    let filterTeamSFList,
      base_date,
      teamSFList = res.result;
    if (teamSFList.length > 0) {
      base_date = teamSFList[0].base_date;
      for (let i = 0; i < teamSFList.length; i++) {
        if (this.state.firstLoad) {
          teamSFList[i].expandFlag = 0;
        } else if (parentask == teamSFList[i].parent_task_display_id) {
          teamSFList[i].expandFlag = 1;
        } else {
          teamSFList[i].expandFlag = 0;
        }
        teamSFList[i].expandFlag = 1;
        if (
          teamSFList[i].task_type == 1 &&
          base_date &&
          !teamSFList[i].planned_start_date
        ) {
          teamSFList[i].planned_start_date = new Date(base_date);
          teamSFList[i].planned_start_date.setDate(
            teamSFList[i].planned_start_date.getDate() +
            teamSFList[i].recommended_start_days
          );
        }
      }
      let allData = this.state;
      filterTeamSFList = teamSFList.filter((item) => {
        if (allData.teamsummary_sfid == item.success_factor_id) {
          if (item.task_type == 0) {
            let childitems = teamSFList.filter((citem) => {
              if (citem.parent_task_display_id == item.task_display_id) {
                if (
                  typeof citem.team_member_id == "number" ||
                  typeof citem.team_member_id == "string"
                ) {
                  if (this.props.role_id > 1) {
                    if (
                      citem.team_administrator == this.props.userId ||
                      this.props.userId == citem.team_addnl_administrator
                    ) {
                      return citem;
                    } else if (citem.member_user_id == this.props.userId) {
                      return citem;
                    }
                  } else {
                    return citem;
                  }
                }
              }
              if (item.task_type == 0) {
                mainTasksList.push(item);
              }
            });
            if (childitems.length > 0) {
              return item;
            }
          } else {
            if (
              typeof item.team_member_id == "number" ||
              typeof item.team_member_id == "string"
            ) {
              return item;
            }
          }
        }
      });
    } else {
      filterTeamSFList = [];
    }

    let prev_type = "", curr_item = {}, curr_item_cnt = 0, locTeamSFList = [], locTeamSFListSub = [];
    let filterTeamSFList1 = filterTeamSFList;
    // locTeamSFList = filterTeamSFList;
    filterTeamSFList.map((item, i) => {
      //locTeamSFList.push(item);
      if (item.task_type == 0) {
        let tid = filterTeamSFList[i].team_id;
        filterTeamSFList1.map((items, i) => {
          if (items.parent_task_display_id == item.parent_task_display_id) {
            if (items.task_type == 0 && tid == items.team_id) {
              curr_item = item;
            }
            if (items.task_type == 1 && tid == items.team_id) {
              curr_item_cnt++;
              locTeamSFListSub.push(items);
            }
          }
        });
        if (curr_item_cnt > 0) {
          locTeamSFList.push(item)
          locTeamSFListSub.map((i, j) => {
            locTeamSFList.push(i)
          })
          curr_item_cnt = 0;
          locTeamSFListSub = [];
        }
      } else if (item.task_type == 1) {
        //locTeamSFList.push(item);
      }
    });
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    var groubedByTeam = groupBy(locTeamSFList, 'team_id')

    let prevParent = "", allSFs = groubedByTeam[null];

    let groupedList = [], prev_parent = "";
    if (allSFs != undefined) {
      Object.keys(groubedByTeam).map((items, idx) => {
        prevParent = "";
        let team_details = this.state.team_details;
        items != null && groubedByTeam[items].map((item, ind) => {
          if (prevParent != item.parent_task_display_id) {
            var teamData = allSFs.find(function (items) {
              return items.parent_task_display_id == item.parent_task_display_id &&
                items.language_code == item.language_code && items.version == item.version
            });
            if (ind == 0)
              groupedList.push({ "task_type": "2", "team_id": item.team_id, "team_name": item.team_name, "success_factor_id": item.success_factor_id });
            groupedList.push(teamData);
            prevParent = item.parent_task_display_id;
          }
          groupedList.push(item);
        });
        // }
      })
    } else {
      Object.keys(groubedByTeam).map((items, idx) => {
        let team_details = this.state.team_details;
        groubedByTeam[items].map((item, ind) => {
          if (prev_parent != item.parent_task_display_id) //&& items.parent_task_display_id == item.parent_task_display_id 
          {
            if (ind == 0) {
              groupedList.push({ "task_type": "2", "team_id": item.team_id, "team_name": item.team_name, "success_factor_id": item.success_factor_id });
            }
            groupedList.push(item);
            prev_parent = items.parent_task_display_id;
          } else {
            groupedList.push(item);
          }
        });
        // }
      });
    }

    let team_details = this.state.team_details;
    filterTeamSFList = groupedList.filter(item => (item.task_type == 1 || item.task_type == 2))
    this.setState({
      teamSuccessFactorList: filterTeamSFList,
      teamSuccessFactorListPrev: filterTeamSFList,
      base_date: base_date,
      firstLoad: false,
      loadAdminList: true,
      mainTasksList: mainTasksList,
    }, this.sefocus());
  };
  sefocus = () => {
    $('#root').scrollTop(window.innerHeight + 200);
    $('html').scrollTop(window.innerHeight + 200);
  }
  loadSuccessFactors = (item) => {
    let filterTeamSummaryList = this.state.all_team_list;//this.state.filterTeamSummaryList
    let teammtsummary = [] //{ "Y1SF1M1": [], "Y1SF1M2": [], "Y1SF1M3": [], "Y1SF1M4": [] }
    let teamsummary = {} // "Y1SF1": 0, "Y1SF2": 0, "Y1SF3": 0, "Y1SF4": 0, "Y1SF5": 0 }
    let sftotaltasks = {}
    let teamname = item ? item.team_name : "";
    let completed_y = 0,sfid=0,tmid=0 ;
    var groubedByTeam = this.groupBy(filterTeamSummaryList, 'team_id');
    let teamlen= Object.keys(groubedByTeam).length;
    teamlen=teamlen?teamlen:0;
    for (let i = 0; i < filterTeamSummaryList.length; i++) {
      let tdp = filterTeamSummaryList[i].parent_task_display_id
      sfid = filterTeamSummaryList[i].success_factor_id;
      tmid = filterTeamSummaryList[i].success_factor_id; 
      if (filterTeamSummaryList[i].del_flag != 1) {
        if(filterTeamSummaryList[i].team_id==1195){
          let xx = 0 ;
        }
       if(!sftotaltasks[tmid]){
        sftotaltasks[tmid]=[];
       }
 
        teammtsummary.push(filterTeamSummaryList[i])
        teamsummary[sfid] = (teamsummary[sfid] || 0) + filterTeamSummaryList[i].total_tasks;
        sftotaltasks[tmid][sfid] = (teamsummary[sfid] || 0) + filterTeamSummaryList[i].total_tasks;
      } 
    } 
    filterTeamSummaryList.map((item)=>{
      sfid = item.success_factor_id;
      item.completed_weight = sftotaltasks[sfid]/teamlen;
    })
    this.setState({
      teammtsummary: teammtsummary,
      teamsummary: teamsummary,
      sftotaltasks:sftotaltasks,
      teamname: teamname,
      team_details: item,
      loading: false,
    })

  }
  getParentName = (row) => {
    let parentName = "", parentTaskId = row.parent_task_display_id;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        parentName = i.custom_task_name ? i.custom_task_name : this.getLabel("LBL" + parentTaskId);
      }
    });
    return parentName;
  }
  handleTabs = (mode, event) => {
    let currX = 0;
    let movelength = 87;
    let maxLeft = window.document.getElementsByClassName("team_bar")[window.document.getElementsByClassName("team_bar").length - 1].offsetLeft;// -92
    let barswidth = (window.document.getElementById("team_bars").clientWidth / 2) + (movelength);
    currX = mode == 2 ? maxLeft > (prevX + (barswidth * 2)) ? prevX + barswidth : maxLeft : prevX > -1 ? maxLeft == prevX ? prevX - barswidth * 2 : prevX - barswidth : prevX;
    $(".nav-pills").scrollLeft(currX);
    console.log(currX, maxLeft)
    prevX = currX;
  }
  handleTeamChange = (event, values) => {
    parentask = "";
    this.getTeamMembersList(values.team_id);
  };
  
  handleSelected = (item, event) => {
    let class_name = event.currentTarget.className;
    let teamname = $(event.currentTarget).find("span").html()
    this.setSelection(event.currentTarget);
    this.loadSuccessFactors(item);
    this.props.setteamdetails(item);
    this.setState({
      team_name: teamname,
      team_details: item,
      loadAdminList: false
    })
  }
  handleTeamChange = (event, values) => {
    parentask = "";
  };
  onSelect = (selectedTeams, event) => {
    if (event.team_id === "0") {
      this.setState(this.options);
    } else {
      this.setState({
        selectedTeams: selectedTeams
      });
    }
  }
  onRemove = (selectedTeams, y) => {
    this.setState({
      selectedTeams: selectedTeams
    });
  }
  onChange = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (event.action === "deselect-option" &&
      event.option.value === "*") {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter(o => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }
  setSelectedOptions = (selectedOptions) => {
    this.setState({
      selectedOptions
    })
  }
  handleClearOptions = (selectedOptions) =>{
    this.setState({
      selectedOptions
    })
  }
  handleSFChange = (event, values) => {
    let { teamSuccessFactorListPrev, teamSuccessFactorList } = this.state;
    if (values.id) { // == "Y1SF1"
      //this.getTeamList();
      this.getFilteredSummary();
    } else if (values.id == "0") {
      this.getFilteredSummary();
    } else {
      teamSuccessFactorList = [];
    }
    this.setState({
      successFactorData: values,
      teamSuccessFactorList: teamSuccessFactorList
    },this.handleProcessReports(1,values))
  }
  handleChange=(selectedValues)=>{
    let team_count = selectedValues.length>0?true:false;
    if(team_count){
      $("#process_reports").removeClass("cls_disable");
    }else{
      $("#process_reports").addClass("cls_disable");
    }
    this.setState({
      selectedTeams:selectedValues,
      team_count:team_count
    })
  }
  handleProcessReports = (mode,sfData) => {
    let { teammtsummary, selectedTeams, selectedOptions,successFactorData } = this.state;
    let filter_summary = [],team_filter=true;
    if(mode==1){
      successFactorData = sfData;
    }
    selectedTeams && selectedTeams.map(teams => {
      teammtsummary.map(items => {
        if (items.team_id == teams.team_id) {
          if(successFactorData.id =="0"){
            filter_summary.push(items);
          }else if(successFactorData.id == items.success_factor_id){
            filter_summary.push(items);
          } 
        }
      })
    }
    );
    if (selectedTeams.length == 0 || $(".MuiChip-label").length==0) {
      filter_summary = [];
      team_filter=false;
    }
    this.setState({
      teammtsummary_filter: filter_summary,
      team_filter: team_filter,
      showExport:false
    });
  }
  handleShowFilter=(mode,eve)=>{
    if(mode==2){ 
      $(".cls_tabs_section").hide();  
      $("#show_filter").show();
    }else{
      $("#show_filter").hide();
      $(".cls_tabs_section").show(); 
    }
  }
  setSelection = (target) => {
    let class_name = "";
    $(".column>.box").next().map((idx, item) => {
      item.className = "";
      $(item.parentElement).find("img").removeClass("active")
    })
    $(target).find("img").addClass("active")
    $(target).find("div").next().addClass("selected")
  }
  backtoSFstaus = () => {
    this.setState({
      loadStatusSummary: false
    })
  }
  loadSFData = (e) => {
    let sfid = e.dataSeries.chartName;
    let currSuccessFactor = sfid;
    let graphType = "Bar";
    let { teamsummary_sfid, successfactors } = this.state;
    sfid = "Y1" + sfid;
    let successFactorData = this.props.steercomSettings.successfactors.filter((items, idx) => {
      return items.id == sfid;
    })

    this.props.setSFDetails(successFactorData);
    this.setState({
      teamsummary_sfid: sfid,
      successFactorData: successFactorData,
      currSuccessFactor: currSuccessFactor,
      graphType: graphType,
      loadAdminList: false
    })
  }
  onExport = (filetype) => {
    let { reportname, teamSuccessFactorList,all_team_list,filterTeamSummaryList,selectedTeams,successFactorData} = this.state;
    let milestones=[], loclistData = [];
    let listData , listDataAll = all_team_list;//teamSuccessFactorList;
    if(successFactorData.id!=0){
      listDataAll = all_team_list.filter(itm=>itm.success_factor_id == successFactorData.id);
    }
    listData = listDataAll.filter((items)=> selectedTeams.findIndex(itm=>itm.team_id == items.team_id)>=0);
    let allLabels = this.props.labels.labels;
    for (var i = 0, len = listData.length; i < len; i++) {
      let loclistitems = {};
      loclistitems["team_name"] =  listData[i].team_name ;
      loclistitems["success_factor"] =  this.props.labels.labels["LBL" + listData[i].success_factor_id.split("Y1")[1] +"TITLE"];
      loclistitems["task_name"] = listData[i].custom_task_name ? listData[i].custom_task_name : allLabels[listData[i].task_name];
      loclistitems["weight_completion(%)"] = listData[i].completed_weight; 
      if (listData[i].task_type == 0) {
        loclistitems["base_date"] = "";
        loclistitems["recommended_start_date"] = "";
      }
      if (listData[i].task_type == 2) {
        milestones.push(listData[i])
        loclistitems["parent_task_name"] = "Milestone";
      }
      loclistData.push(loclistitems); 
    }

    let newval, upperval, arr, fieldName, arrfieldName;
    $("#loadingexport").show();
    loclistData.map((itm) => {
      arrfieldName = Object.keys(itm);
      arrfieldName.forEach((obj) => {
        upperval = obj.toString().toUpperCase();
        arr = upperval.split("_");
        if (arr.length > 1) {
          newval = arr.join(" ");
          itm[newval] = itm[obj];
          delete itm[obj];
        } else {
          newval = upperval;
          itm[newval] = itm[obj];
          delete itm[obj];
        }
      });
      return true;
    });
    reportname = "Admin_Reports";
    if (!loclistData) return null;
    if (filetype.toLowerCase() == "csv") {
      this.exportCSV(loclistData, reportname);
    } else if (filetype.toLowerCase() == "excel") {
      this.exportToExcel(loclistData, reportname);
    }
    loclistData = listData;
  };
  convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    } 
    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    let result_key = "";
    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        if (key.toLowerCase().indexOf("summary") > 0) {
          result_key = item[key].toString().replace(/\n/g, "");
        } else {
          if (typeof item[key] == "undefined") item[key] = "";
          result_key = item[key];
        }
        result_key = ((result_key) && isNaN(result_key)) ? result_key.replace(/[,]/g, '-').replace(/\n/g, " ") : result_key;
        result_key = result_key == null ? "" : result_key;
        result += result_key;
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  exportCSV = (listdata, reportname) => {
    var data, filename, link;
    var stockData = [];
    var objHeader = {};

    let lheader,
      xfields = listdata;
    stockData = xfields;
    var csv = this.convertArrayOfObjectsToCSV({
      data: stockData,
    });
    if (csv == null) return;

    filename = reportname + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    data = encodeURI(csv);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
    $("#loadingexport").hide();
  };
  exportToExcel = (data, sCallBack, fCallBack) => {
    let fileExtension = ".xlsx"; 
    let fileName = "Admin_Reports";  
    let width, headers = [];
    const wb = new Excel.Workbook();
    let ws = wb.addWorksheet('Tasks');
    const header_names = Object.keys(data[0]);
    header_names.map((item, x) => {
      width = (x < 3) ? 40 : (x == 3 || x == 4) ? 25 : 18;
      headers.push({
        header: item, key: ("row_" + (x + 1)), width: width
      });

    });
    let cols = [];
    ws.columns = headers;
    data.map((items, idx) => {
      cols = [];
      header_names.map(x => {
        cols.push(items[x])
      });
      ws.addRow(cols);
    })
    ws.getColumn('A').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('B').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('C').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('D').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('E').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getColumn('F').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getRow(1).style.alignment = { vertical: 'middle', horizontal: 'center' };

    ws = this.excelFormatting(ws, headers)
    wb.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${fileName + "_" + Date.now()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
  }
  excelFormatting = (worksheet, xcolumns) => {
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } }
        };
        if (rowNumber === 1) {
          row.height = 30;
          row.font = { bold: true }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "538DD5" }
          };
          cell.alignment = {
            vertical: 'middle', horizontal: 'center', wrapText: true
          };
        } else {
          let fgColor = '538DD5';
          if (row._cells[3] && row._cells[3]._value.model.value  == "") {
            fgColor = '000';
          }
          //}

          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: '538DD5'
          };
          cell.alignment = {
            vertical: 'middle', horizontal: colNumber < 5 ? 'left' : 'center'
          };
        }
      })
    })
    return worksheet;
  }
  exportPDF=(event)=>{ 
    var margins = {top: 80,bottom: 60,left: 40,width: 522};  
    var specialElementHandlers = { 
      '#bypassme': function(element, renderer){ 
        return true
      }
    }
 
  } 
 groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  render() {
    let { LBLPROCESSREPORT, LBLINPROGONTRACK, LBLINPROGONDELAY,LBLSHOWFILTER,
      LBLSF1TITLE, LBLSF2TITLE, LBLSF3TITLE, LBLSF4TITLE, LBLSF5TITLE,LBLSELECTALL,LBLSELECT,
      LBLVWFULL,LBLPRINTCRT,LBLDNPNG,LBLDNJPEG,LBLDNSVG,LBLDNPDF,LBLCONTMENU,
      LBLCOMPLETED, LBLY1SF1M1, LBLY1SF1M2, LBLY1SF1M3, LBLY1SF1M4, LBLY1SF1M5,
      LBLADMINREPORTS, LBLSF, LBLTEAM, LBLNONE, LBLYETTOSTART, LBLEXPORTTOCSV,LBLEXPORTTPDF, LBLEXPORT, LBLEXPORTTOEXCEL, 
      LBLTITLE, LBLNORECSFOUNDAR , LBLSFTITLE } =  this.props.labels.labels; // customLabels["en-US"].labels;//
      LBLEXPORTTPDF = LBLEXPORTTPDF?LBLEXPORTTPDF:"PDF";
      LBLNONE = LBLNONE?LBLNONE:"-- None --";
    let { teamList,successFactorsList, teamsummary_sfid, selectedOptions, showExport,isMobileView, 
          successFactorData, loadStatusSummary, currSuccessFactor, teammtsummary_filter,
          team_count, team_filter, team_name, teamsummary, teammtsummary, mobView,selectedTeams } = this.state;
    let labels = this.props.labels.labels; 
    let teamname = "", smoptions = [], graphType = "",sfcont=false;
    let dataPoints = [], options_sf = [], team_success=[];
    let colors = ["#ed6886","#57d3ee","#f1cf5c","#B56BF0","#10d877"];
    let sub_colors =  ["#FBDEE4","#C3EFF8","#E0E4BA", "#ECD7F5", "#9DE1C4"];
    //let sub_colors =  ["#E6E6E6","#E6E6E6","#E6E6E6", "#E6E6E6", "#E6E6E6"];
    let line_colors =  ["#B8B8B8","#B8B8B8","#B8B8B8","#B8B8B8","#B8B8B8"];
    let tool_tips = [LBLSF1TITLE,LBLSF2TITLE,LBLSF3TITLE,LBLSF4TITLE,LBLSF5TITLE,LBLYETTOSTART];
       /////   START  ///// 
       let LBLSF0TITLE = LBLYETTOSTART; 
       let summary_data = [];
       for(let i=0;i<5;i++){
        summary_data.push(
          {
          type: "1",
          //toolTipContent: "<b>{name} : </b> {y}%", 
          toolTipContent: null, 
          showInLegend: false,
          name: "",//labels["LBLCOMPLETED"]?labels["LBLCOMPLETED"]:"Completed",
          color: line_colors[i],
          sub_color:sub_colors[i],
          total_tasks: 100,
          data : []
        },
        {
          type: "2",
          toolTipContent: "<b>{name} : </b> {y}%",
          showInLegend: true,
          //name:  tool_tips[i],//labels["LBLCOMPLETED"]?labels["LBLCOMPLETED"]:"Completed",
          color: colors[i],
          sub_color:sub_colors[i],
          total_tasks: 100,
          data: []
        },{
          type: "3",
          toolTipContent: "<b>{name} : </b> {y}%",
          //showInLegend:i==0?true:false,
        //  showInLegend:true,// i==0? true :false,
          //name: LBLYETTOSTART ,//labels["LBLCOMPLETED"]?labels["LBLCOMPLETED"]:"Completed",
          color: sub_colors[i],
          sub_color:sub_colors[i],
          borderColor:"red",
          total_tasks: 100,
          data: []
        })
       } 
    let maxLength=false,totalweight = 0, taskName = "", mtindex = 0 ,categories=[],cntYts=0;
    teammtsummary = team_filter?teammtsummary_filter:[];
    
    let teammtsummaryloc = teammtsummary.filter((item, idx) => {
      return (item.YETTOSTART != null)
    });
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    teammtsummary = teammtsummaryloc;
    var groupedByTeam = groupBy(teammtsummaryloc, 'team_id')
    if (loadStatusSummary == false) {
      let allFactors = [];
      let taskCnt1 = 0, taskCnt2 = 0, taskCnt3 = 0, taskCnt4 = 0, taskCnt5 = 0,team_count=0;
      for (var item in groupedByTeam) {
        teammtsummary = groupedByTeam[item];
        categories.push(groupedByTeam[item][0].team_name);
        team_count++;
        taskCnt1 = 0; taskCnt2 = 0; taskCnt3 = 0; taskCnt4 = 0; taskCnt5 = 0;
        if(team_count < 9){
        for (let i = 0; i < teammtsummary.length; i++) {
          teamname = teammtsummary[0].team_name;
          totalweight = totalweight + teammtsummary[i].completed_weight;
          taskName = teammtsummary[i].custom_task_name ? teammtsummary[i].custom_task_name : labels[teammtsummary[i].task_name]

          if (teammtsummary[i].success_factor_id == "Y1SF1") {
            taskCnt1 = taskCnt1 + teammtsummary[i].completed_weight;//teammtsummary[i].TASKS_COMPLETED + teammtsummary[i].TASKS_INPROGONDELAY + teammtsummary[i].TASKS_INPROGONTRACK + teammtsummary[i].TASKS_YETTOSTART_ASSIGNED; 
            taskName = teammtsummary[i].team_name;
            //taskName = taskName.length>15? taskName.substring(0,15)+"..." : taskName ;
            sfcont=true;
            allFactors["Y1SF1"] = {
              y: taskCnt1,
              label: taskName,
              name: LBLSF1TITLE,
              sf:"Y1SF1"
            }
          } else if (teammtsummary[i].success_factor_id == "Y1SF2") {
            taskCnt2 = taskCnt2 + teammtsummary[i].completed_weight;// teammtsummary[i].TASKS_COMPLETED + teammtsummary[i].TASKS_INPROGONDELAY + teammtsummary[i].TASKS_INPROGONTRACK + teammtsummary[i].TASKS_YETTOSTART_ASSIGNED; 
            taskName = teammtsummary[i].team_name;
           // taskName = taskName.length>15? taskName.substring(0,15)+"..." : taskName ;
            sfcont=true;
            allFactors["Y1SF2"] = {
              y: taskCnt2,
              label: taskName,
              name: LBLSF2TITLE,
              sf:"Y1SF2"
            }
          } else if (teammtsummary[i].success_factor_id == "Y1SF3") {
            taskCnt3 = taskCnt3 + teammtsummary[i].completed_weight;// teammtsummary[i].TASKS_COMPLETED + teammtsummary[i].TASKS_INPROGONDELAY + teammtsummary[i].TASKS_INPROGONTRACK + teammtsummary[i].TASKS_YETTOSTART_ASSIGNED; 
            taskName = teammtsummary[i].team_name;
           // taskName = taskName.length>15? taskName.substring(0,15)+"..." : taskName ;
           sfcont=true;
            allFactors["Y1SF3"] = {
              y: taskCnt3,
              label: taskName,
              name: LBLSF3TITLE,
              sf:"Y1SF3"
            }
          } else if (teammtsummary[i].success_factor_id == "Y1SF4") {
             taskCnt4 = taskCnt4 + teammtsummary[i].completed_weight;// teammtsummary[i].TASKS_COMPLETED + teammtsummary[i].TASKS_INPROGONDELAY + teammtsummary[i].TASKS_INPROGONTRACK + teammtsummary[i].TASKS_YETTOSTART_ASSIGNED; 
             taskName = teammtsummary[i].team_name;
            //taskName = taskName.length>15? taskName.substring(0,15)+"..." : taskName ;
            sfcont=true;
            allFactors["Y1SF4"] = {
              y: taskCnt4,
              label: taskName,
              name: LBLSF4TITLE,
              sf:"Y1SF4"
            }
          } else if (teammtsummary[i].success_factor_id == "Y1SF5") {
            taskCnt5 = taskCnt5 + teammtsummary[i].completed_weight;// teammtsummary[i].TASKS_COMPLETED + teammtsummary[i].TASKS_INPROGONDELAY + teammtsummary[i].TASKS_INPROGONTRACK + teammtsummary[i].TASKS_YETTOSTART_ASSIGNED; 
            taskName = teammtsummary[i].team_name;
            //taskName = taskName.length>15? taskName.substring(0,15)+"..." : taskName ;
            sfcont=true;
            allFactors["Y1SF5"] = {
              y: taskCnt5,
              label: taskName,
              name: LBLSF5TITLE,
              sf:"Y1SF5"
            }
          }

          if(taskName.length>15){
            maxLength=true;
          }
          if(sfcont==false && successFactorData){
            allFactors[successFactorData.id] = {}
          }
        }
 
       // if(maxLength)
       // stack_smoptions.axisX.labelWrap = true;
          let cnt_arr=[], wt = 1.5;
          wt = successFactorData.id==0?1.5:0;
          wt=0;
          let m=team_count-1;
        if (Object.keys(allFactors).length>0) { 
          allFactors.Y1SF1=allFactors.Y1SF1?allFactors.Y1SF1:{}
          allFactors.Y1SF2=allFactors.Y1SF2?allFactors.Y1SF2:{} 
          allFactors.Y1SF3=allFactors.Y1SF3?allFactors.Y1SF3:{}  
          allFactors.Y1SF4=allFactors.Y1SF4?allFactors.Y1SF4:{} 
          allFactors.Y1SF5=allFactors.Y1SF5?allFactors.Y1SF5:{}

          // allFactors.Y1SF1.color=colors[0];
          // allFactors.Y1SF2.color=colors[1];
          // allFactors.Y1SF3.color=colors[2]; 
          // allFactors.Y1SF4.color=colors[3];
          // allFactors.Y1SF5.color=colors[4];

          //line_colors[i] colors[i] sub_colors[i]
          let LBLSF0TITLE = " - Yet to Start";
          if(Object.keys(allFactors.Y1SF1).length>0){
            LBLSF0TITLE = LBLYETTOSTART + " - " + LBLSF1TITLE;
            LBLSF0TITLE = LBLSF0TITLE.length>30?LBLSF0TITLE.substring(0,30)+"...":LBLSF0TITLE;
            allFactors["Y1SF11"] = {y: (100 - taskCnt1), yts:taskCnt1==0?true:false, color:sub_colors[0],name:LBLSF0TITLE} //, label: allFactors.Y1SF1.label, name:LBLSF1TITLE + LBLSF0TITLE 
            allFactors["Y1SF21"] = {y: (0),color:line_colors[m]} 
            summary_data[0].data.push(allFactors.Y1SF21);
            summary_data[1].data.push(allFactors.Y1SF1);
            summary_data[2].data.push(allFactors.Y1SF11);
            cnt_arr.push("Y1SF1")
          }
          if(Object.keys(allFactors.Y1SF2).length>0){
            LBLSF0TITLE = LBLYETTOSTART + " - " + LBLSF2TITLE;
            LBLSF0TITLE = LBLSF0TITLE.length>30?LBLSF0TITLE.substring(0,30)+"...":LBLSF0TITLE;
            allFactors["Y1SF12"] = {y: (100 - taskCnt2), yts:taskCnt2==0?true:false, color:sub_colors[0],name:LBLSF0TITLE } //, label: allFactors.Y1SF2.label, name:LBLSF2TITLE + LBLSF0TITLE
            allFactors["Y1SF22"] = {y: (wt),color:line_colors[m]} 
            summary_data[3].data.push(allFactors.Y1SF22);
            summary_data[4].data.push(allFactors.Y1SF2);
            summary_data[5].data.push(allFactors.Y1SF12);
            cnt_arr.push("Y1SF2")
          }
          if(Object.keys(allFactors.Y1SF3).length>0){
            LBLSF0TITLE = LBLYETTOSTART + " - " + LBLSF3TITLE;
            LBLSF0TITLE = LBLSF0TITLE.length>30?LBLSF0TITLE.substring(0,30)+"...":LBLSF0TITLE;
            allFactors["Y1SF13"] = {y: (100 - taskCnt3), yts:taskCnt3==0?true:false, color:sub_colors[0],name:LBLSF0TITLE } //, label: allFactors.Y1SF3.label, name:LBLSF3TITLE + LBLSF0TITLE
            allFactors["Y1SF23"] = {y: (wt),color:line_colors[m]}
            summary_data[6].data.push(allFactors.Y1SF23);
            summary_data[7].data.push(allFactors.Y1SF3);
            summary_data[8].data.push(allFactors.Y1SF13);
            cnt_arr.push("Y1SF3")
          }
          if(Object.keys(allFactors.Y1SF4).length>0){ 
            LBLSF0TITLE = LBLYETTOSTART + " - " + LBLSF4TITLE;
            LBLSF0TITLE = LBLSF0TITLE.length>30?LBLSF0TITLE.substring(0,30)+"...":LBLSF0TITLE;
            allFactors["Y1SF14"] = {y: (100 - taskCnt4), yts:taskCnt4==0?true:false, color:sub_colors[0],name:LBLSF0TITLE} //, label: allFactors.Y1SF4.label, name:LBLSF4TITLE + LBLSF0TITLE 
            allFactors["Y1SF24"] = {y: (wt),color:line_colors[m]} 
            summary_data[9].data.push(allFactors.Y1SF24 );
            summary_data[10].data.push(allFactors.Y1SF4 );
            summary_data[11].data.push(allFactors.Y1SF14 );
            cnt_arr.push("Y1SF4")
          }
          if(Object.keys(allFactors.Y1SF5).length>0){ 
            LBLSF0TITLE = LBLYETTOSTART + " - " + LBLSF5TITLE;
            LBLSF0TITLE = LBLSF0TITLE.length>30?LBLSF0TITLE.substring(0,30)+"...":LBLSF0TITLE;
            allFactors["Y1SF15"] = {y: (100 - taskCnt5), yts:taskCnt5==0?true:false, color:sub_colors[m],name:LBLSF0TITLE} //, label: allFactors.Y1SF5.label, name:LBLSF5TITLE + LBLSF0TITLE 
            allFactors["Y1SF25"] = {y: (wt),color:line_colors[m]} 
            summary_data[12].data.push(allFactors.Y1SF25); 
            summary_data[13].data.push(allFactors.Y1SF5); 
            summary_data[14].data.push(allFactors.Y1SF15);
            cnt_arr.push("Y1SF5")
          }  
        }
        if(successFactorData.id==0){
            summary_data[(cnt_arr.length * 3) -1].showInLegend = true;
        }else{
            let x = cnt_arr[0].split("Y1SF")[1]
            summary_data[(x * 3) -1].showInLegend = true;
        } 
      }
    } 
   // summary_data[1].showInLegend = true;
    } 
 
    stack_smoptions={
      chart: {
        type: "column",
        spacing: [24, 24, 24, 24]
      },
      credits: {
        enabled: false
      },
      title: {
        text: '' 
      },
      xAxis: {
        categories: categories
     },
    yAxis: {
        min: 0,
        title: {
            text: LBLSF
        }
    },
    lang: {
      accessibility: { 
      }, 
      contextButtonTitle:LBLCONTMENU,
      decimalPoint:'.',
      printChart:LBLPRINTCRT,
      downloadJPEG:LBLDNJPEG,
      downloadPDF:LBLDNPDF,
      downloadPNG:LBLDNPNG,
      downloadSVG:LBLDNSVG, 
      downloadCSV:'Download CSV',
      downloadXLS:'Download XLS', 
      exitFullscreen:'Exit from full screen',
      viewFullscreen:LBLVWFULL
    },
      plotOptions: {
        column: {
          stacking: 'normal',
          pointWidth: 60,
          borderColor: '#d6c7c7',
          borderWidth: '.5',
        },
        series: {
          stacking: "normal"
        }
      },
      series: [],
      defs: {
        gradient0: {
            tagName: 'linearGradient',
            id: 'gradient-0',
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            children: [{
                tagName: 'stop',
                offset: 0
            }, {
                tagName: 'stop',
                offset: 1
            }]
        },
        gradient1: {
            tagName: 'linearGradient',
            id: 'gradient-1',
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            children: [{
                tagName: 'stop',
                offset: 0
            }, {
                tagName: 'stop',
                offset: 1
            }]
        }
    },

    };
 
     options_sf = stack_smoptions;
    // options_sf.series.push({"data": summary_data});
    let getGradient=(item,mode)=>{
      let color = mode==1?item.sub_color :item;
      let stops = [[0, "#FFF"],[0.3,  "#FFF"],[1, color]]; 
      return {
        linearGradient: {x1: 1, y0: 0, x2: 0,y2: 0  }, 
        stops: stops
      }};
    let final_sf=[]
    if (teammtsummary.length > 0) {
      options_sf.series=[];
      $('#root').scrollTop(0);
      summary_data.map((item,idx)=>{
        if(item.data.length>0 ){
        final_sf={
          name:item.name?item.name:item.data[0].name,
          color:(item.type==3)?getGradient(item, 1) : item.color ,
          showInLegend:(item.type==1 || item.type==3)? false:true, //item.type==1?false:true,
          data:$.map(item.data, function(value, index){
            return [value.y]
          }) 
        }
        options_sf.series.push(final_sf);
      }
      });
      options_sf.series.showInLegend = true ;
      options_sf.series[0].chartName = currSuccessFactor;
      options_sf.series[0].innerRadius = 40;
      options_sf.height = 350;
      LBLTITLE = LBLSF5TITLE;
      options_sf.series.reverse();
     }

    
    /* Teams dropdown section */
    const getOptionLabel = option => (option.team_name)
    const getOptionDisabled = option => option.team_id === "0";
    const handleToggleOption = selectedOptions =>
    this.setSelectedOptions(selectedOptions);
    const handleClearOptions = () => this.setSelectedOptions([]);
    const handleSelectAll = isSelected => {
      if (isSelected) {
        this.setSelectedOptions(teamList);
      } else {
        handleClearOptions();
      }
    };
    //LBLTEAM = LBLTEAM + " :";
    return (
      <div className="mob-pad steercom_reports">
        <div className="page-header-field ">
          <h3 className="cls_teams" id="header_top">
            <span className="page-title ">{LBLADMINREPORTS ? LBLADMINREPORTS : "Admin Reports"}</span>
          </h3>
          {showExport? 
          <div  className="btn-group ml-auto mr-3 cls_task_export" role="group">
                  <span
                    className="CSTFilter_spinner"
                  > 
                    <Loader></Loader>
                  </span> 
                  <button
                    id="btnGroupDrop1"
                    type="button"
                    className="btn basicSelItem dropdown-toggle cls_export"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-mail-forward" aria-hidden="true">
                      &#xf064;
                    </i>{" "}
                    {LBLEXPORT}
                  </button>

                  <div
                    className="dropdown-menu  dropdown-menu-right"
                    aria-labelledby="btnGroupDrop1"
                  >
                     
                    <span id = "pdf" 
                      className="dropdown-item cls_export_items"
                      //onClick={this.onExport.bind(this, "csv")}
                      onClick={this.exportPDF.bind(this, "csv")} 
                    >
                      {LBLEXPORTTPDF}
                    </span> 
                    <span  id = "excel" 
                      className="dropdown-item cls_export_items"
                      onClick={this.onExport.bind(this, "excel")}
                    >
                      {LBLEXPORTTOEXCEL}
                    </span>
                  </div>
          </div>
          :""}
        </div>
        <hr></hr>
        <div onClick={this.handleShowFilter.bind(1,this)} id="show_filter" className="cls_show_filter" style={{ marginLeft: "50px" }}>
                <button className="button-style primary-btn" > 
                   {LBLSHOWFILTER}
                </button>
       </div>
        <div className="card cls_tabs_section">
          <div className="cls_tabs_body">
          {this.state.successFactorsList && this.state.successFactorsList.length > 0 ?
            <div class="cls_admn_filters" style={{}}> 
                <div className="col-xl-4 col-sm-8  cls_admn_filt_cnt"> 
                  <label className="label">{LBLSF} :</label>
                  <ThemeProvider theme={theme}>
                  <Autocomplete
                    style={{ width: "150px" }}
                    // label={LBLSF}
                    placeholder={LBLSF}
                    disableClearable
                    options={[ ...successFactorsList]}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, values) =>
                      option.id === values.id 
                    }
                    onChange={this.handleSFChange.bind(this)}
                    underlineStyle={{ display: "none" }}
                    value={this.state.successFactorData}
                    defaultValue={this.state.successFactorData}
                    renderOption={(option) => (
                      <div style={{ fontSize: "14px" }}>{option.title}</div>
                    )}
                    renderInput={(params) => (
                      <TextField id="sflist" {...params} />
                    )}
                    underline="none"
                  />
                  </ThemeProvider>
                </div>  
              <div className="col-xl-5 col-sm-8 cls_admn_filt_cnt">
                <div> 
                <label className="label" >{LBLTEAM }:</label>
                </div>  
                {
                 
                  this.state.teamList && this.state.teamList.length > 0 ? <> 
                  <ThemeProvider theme={theme}>
                      <MultiSelect
                        items={teamList}
                        getOptionLabel={getOptionLabel}
                        getOptionDisabled={getOptionDisabled}
                        selectedValues={selectedOptions}
                        // label="Select complex values"
                        placeholder={LBLSELECT?LBLSELECT:"Select"}
                        selectAllLabel={LBLSELECTALL?LBLSELECTALL:"Select All"}
                        onToggleOption={handleToggleOption}
                        onClearOptions={handleClearOptions}
                        onSelectAll={handleSelectAll}
                        onChange={this.handleChange}
                        limitTags={mobView?1:2} 
                        getLimitTagsText={count => `+${count}📦`}
                      />   
                      </ThemeProvider>
                  </> : <>
                    <div>
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        disableClearable
                        options={[{ "team_id": 0, "team_name": LBLNONE }]}
                        getOptionLabel={(option) => (option || { "team_id": 0, "team_name": LBLNONE }).team_name.toString()}
                        getOptionSelected={(option, values) =>
                          option.team_id === values.team_id
                        }
                        onChange={this.handleTeamChange.bind(this)}
                        underlineStyle={{ display: "none" }}
                        defaultValue={
                          { "team_id": 0, "team_name": LBLNONE }
                        }
                        renderInput={(params) => (
                          <TextField id="tmlist" {...params} />
                        )}
                        underline="none"
                      />
                      </ThemeProvider>
                    </div>
                  </>
                }
              </div>
              <div  className="col-xl-2 col-sm-8  bt-head"> 
                <button id="process_reports"
                  className={team_count?"button-style primary-btn" : "button-style primary-btn cls_disable"}
                  onClick={this.handleProcessReports}
                >
                  {LBLPROCESSREPORT}
                </button>
              </div>
            </div>: ""}
          </div>
        </div>
        {this.state.loading == false ?
          <div id="AdminSection"> 
              <div className="row cls_adm_sf_container">  
                  <div id="report_container" style={{ width: teammtsummary.length>0 ? "90%" : "100%", position: "relative", marginBottom: "10px" }}>
                      { teammtsummary.length>0 ?
                     <div>
                       <HighchartsReact highcharts={Highcharts} options={options_sf}
                        ref={this.chart} 
                        />
                    </div>
                      :  
                       <div className="cls_nodata"><EmptyState image={emptyImage} text2={LBLNORECSFOUNDAR}/></div>
                    }
                  </div> 
              </div>
            
            <br></br>
            <br></br>

          </div>
          :
          <Skeleton height={300} />
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(SteercomReport);

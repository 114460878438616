import React, { Component } from "react";
//import { connect } from "react-redux";
//import { createBrowserHistory } from 'history';
import { withRouter } from "react-router";
import $ from 'jquery';
//import CanvasJSReact from "../../../assets/canvasjs/canvasjs.reactdashbrd"; 
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";  
//import highcharts3d from 'highcharts/highcharts-3d'
//highcharts3d(Highcharts);
highchartsGantt(Highcharts); 
require("highcharts/modules/exporting")(Highcharts);

//var CanvasJSChart = CanvasJSReact.CanvasJSChart;
//let chart = null;

class DoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
    };
  }

  componentDidMount() {
    this.chartDetails();
    var chart = this.chart;
    $('body').on("onresize", function () {
      //console.log("doughnut resize")
      chart.render();
    })
  }
  componentWillUnmount() {
    $('body').off("onresize")
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.chartDetails();
    }
  }
  onDoughnutClick = () => {
    if (this.props.onElementClick) {
      this.props.history.push("/schome/reports/learner_progress");
    }
  }
  getOption = (datas)=>{
    let data = datas.data;
    //let pieColors = ["#A6D486", "#7DB1F5", "#C8C6C6"]; //7DB1F5  F5C947 C8C6C6
    let pieColors = ["#A6D486", "#FFFF99", "#FF8F8F"]; //7DB1F5  F5C947 C8C6C6
    let objData =[]; 
    let allLabels = this.props.data.labels ;    
    let categories = [allLabels[0],allLabels[1],allLabels[2]];
    if(data && data.length>0){
      // objData.push({name : allLabels[0], y:data[0].Completed},
      //              {name : allLabels[1], y:data[0].Inprogress},
      //              {name : allLabels[2], y:data[0].Notstarted}
      // );
      objData.push({name : allLabels[0], y:data[0]},
        {name : allLabels[1], y:data[1]},
        {name : allLabels[2], y:data[2]}
       );
     } 
    let chart_data = {
      chart: {
          type: 'pie',
          marginTop: -200,
          backgroundColor: null,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          options3d: {
              enabled: true,
              alpha: 45
          },
          style: {
            fontFamily: "'Roboto', sans-serif"
          }
      },
      legend: {
          layout: 'horizontal',
          floating: true,  
          colors: pieColors, 
        //align: 'left',
          verticalAlign: 'top',
          y: 200,
        //x: 90,
        width: '100%',
        itemStyle: { 
          fontWeight: '450',
          fontSize: '12px', 
          fontFamily: "'Roboto', sans-serif",
      }
      },
      credits: { 
        enabled: false
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        headerFormat: '', 
        pointFormat: '{point.name} : <b>{point.y} </b>',
        percentageDecimals: 0
      },
      title: {
          text: null ,
          align: 'left',
      },
      subtitle: {
          text: null ,
          align: 'left'
      },
      plotOptions: {
          pie: { 
              size: 160,
              innerSize: 80, 
              colors: pieColors, 
              depth: 35,
              dataLabels: {
                enabled: true,
                format: '<b>{point.y}</b>',
                distance: -22,
                filter: {
                  property: 'percentage',
                  operator: '>',
                  value: 4
              }
            }
          },
            series: {
            cursor: 'pointer',
            events: {
                click:  this.onDoughnutClick    
            }
        }
      },
      xAxis: {
         categories: categories
      },
      series: [{ 
          showInLegend: true,         
          data: objData
    }]
};
   return chart_data;
  }
  chartDetails() {
    let data = {};
    let datasets = [];
    let obj = {};
    let dataPoints = [];
    if (this.props.data.datasets) {
      obj.backgroundColor = this.props.data.datasets[0].backgroundColor;
      obj.data = this.props.data.datasets[0].data;
      obj.hoverBackgroundColor = this.props.data.datasets[0].hoverBackgroundColor;
      datasets.push(obj);
      data.datasets = datasets;
      let labels = this.props.data.labels;
      data.labels = labels;
      for (var i = 0; i < data.datasets[0].data.length; i++) {
        let dataPoint = {};
        dataPoint.name = data.labels[i];
        dataPoint.y = data.datasets[0].data[i];
        dataPoint.color = data.datasets[0].backgroundColor[i];
        dataPoints.push(dataPoint);
      }
    }
    let options_sts =[];
    if (this.props.data.datasets) {
      let data = this.props.data.datasets[0];
      options_sts = this.getOption(data)
    }
    this.setState({
      options_sts:options_sts,
      options: {
        height: this.props.height ? this.props.height : 280,
        interactivityEnabled: true,
        animationEnabled: true,
        animationDuration: 1000,
        backgroundColor: "transparent",
        /*subtitles: [{
          text: "Total Units 20",
          verticalAlign: "center",
          fontSize: 24,
          dockInsidePlotArea: false
        }],*/
        legend: {
          horizontalAlign: "center",
          verticalAlign: "bottom",
          fontSize: 12,
          fontFamily: "'Roboto', sans-serif",
          fontWeight: "500",
          fontColor: "#333",
        },
        toolTip:{
          fontSize: 13,
          fontFamily: "'Roboto', sans-serif",
          fontColor: "#333",
        },
        data: [
          {
            click: this.onDoughnutClick,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            type: "doughnut",
            startAngle: 270,
            radius: "90%",
            showInLegend: this.props.legend,
            innerRadius: '50%',
            indexLabel: "{y}",
            toolTipContent: "{name}: <strong>{y}</strong>",
            indexLabelPlacement: "outside",
            dataPoints: dataPoints,
            indexLabelFontSize: 12,
            indexLabelFontFamily: "'Roboto', sans-serif",
            indexLabelFontWeight: "400",
            indexLabelFontColor: "#333",
          },
        ],
      },
    });
  }

  render() {
    return (
      // <CanvasJSChart options={this.state.options}
      //   onRef={ref => this.chart = ref}
      // />
      <div style={{height:"260px"}}>
      <HighchartsReact highcharts={Highcharts} 
        options={this.state.options_sts}
        ref={this.chart}   
      />
      </div>
    );
  }
}
DoughnutChart = withRouter(DoughnutChart)
export default DoughnutChart;

import React, { Component } from "react";
import { classList } from "dynamic-class-list";
import { debounce } from "../../components/reportfilter/functionUtils";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ScCommonData from "../../../services/common_data";
import "./GlobalSettings.scss";
import { connect } from "react-redux";
import Loader from "../../components/loader/Loader";
import SCUtils from "../../../utils/utils";
import {
  required,
  maxLength255,
} from "../validate/Validate";
import { Field } from "redux-form";
import styles from "./globalsettings.module.scss";

const ON_CHANGE_DELAY = 200;

class EditGlobalSettings extends Component {
  constructor(props) {
    console.log("propsss", props)
    super(props);
    this.state = {
      initialValues: {},
      loading: false,
      errorType: null,
      company_name: this.props.company_name,
      company_currency: this.props.company_currency,
      company_timezone: { value: this.props.company_timezone },
      company_language: { name: this.props.company_language, code: this.props.company_language },
      company_dateformat: { name: this.props.company_dateformat, code: this.props.company_dateformat },
      company_timeformat: { code: this.props.company_timeformat },
      company_group_types: this.props.company_group_types,
      company_group_labels: this.props.company_group_labels,
      email_with_code: this.props.email_with_code,
      groups: {},
      group_change: false,
      files: [
        "nice.pdf",
        "verycool.jpg",
        "amazing.png",
        "goodstuff.mp3",
        "thankyou.doc",
      ],
    };
    console.log("company_language", this.state.company_language)
    console.log("company_timezone", this.state.company_timezone)
    console.log("company_dateformat", this.state.company_dateformat)
    console.log("company_timeformat", this.state.company_timeformat)
    console.log("propssss222", this.props)
    this.modalChild = React.createRef();
    this.onCancelDebounced = debounce(props.onCancel, ON_CHANGE_DELAY);
  }

  componentDidMount() {
    this.props.company_group_types.map((group) => {
      if (
        this.props.company_group_labels[group] &&
        this.props.company_group_labels[group]["lbl_" + group]
      ) {
        let grp = this.props.company_group_labels[group]["lbl_" + group];
        this.setState((prevState) => ({
          groups: { ...prevState.groups, [group]: grp },
        }));
      } else {
        let grp = this.props.labels.labels["LBLGROUP" + group.split("_")[1]];
        this.setState((prevState) => ({
          groups: { ...prevState.groups, [group]: grp },
        }));
      }
    });
  }

  getSettings = () => {
    HttpInterceptor.get("settings").then((res) => {
      let item = res.data.data;
      this.setState({
        company_name: item.company_name,
        company_currency: item.currency,
        company_timezone: item.timezone,
        company_language: item.language_code,
        company_dateformat: item.dateformat,
        company_timeformat: item.timeformat,
        loading: false,
      });
    });
  };

  saveSettings = (values) => {
    let data = {
      company_name: this.state.company_name,
      company_currency: this.state.company_currency,
      company_timezone: this.state.company_timezone.value,
      company_language: this.state.company_language.code,
      company_dateformat: this.state.company_dateformat.code,
      company_timeformat: this.state.company_timeformat.code,
      user_language: this.props.user_language,
      groups: this.state.groups,
      group_change: this.state.group_change,
      email_with_code: this.state.email_with_code,
    };


    if (
      data.company_name &&
      !/^ *$/.test(data.company_name) &&
      SCUtils.findEmptyObjectFromObject(this.state.groups)
    ) {
      HttpInterceptor.post("settings", data).then(
        (res) => {
          //this.setState({ loading: true, initialValues: null });
          if (res) {
            this.props.ShowToast();
            window.location.reload();
          }
        },
        (err) => {
          if (
            err.response.data.errorType === 2 ||
            err.response.data.errorType === 2.1
          ) {
            this.setState({
              errorType: err.response.data.errorType,
            });
          }
        }
      );
    } else {
      return false;
    }
  };
  //let cObj = this.state.initialValues
  getValue = (x) => {
    return this.state[x];
  };
  cancelChange = () => {
    this.onCancelDebounced();
  };

  handleDrop = (files) => {
    let fileList = this.state.files;
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
      fileList.push(files[i].name);
    }
    this.setState({ files: fileList });
  };

  renderField = ({
    input,
    label,
    type,
    g_value,
    className,
    disabled,
    meta: { touched, error, warning },
    required,
    onChng,
    name,
  }) => (
    <div
      key={name}
      className={classList([
        "form-group",
        "row",
        required === true ? "required" : "",
      ])}
    >
      <label className="col-sm-3 control-label">{label}</label>
      <div class="col-sm-7">
        <input
          key={name}
          {...input}
          value={g_value}
          placeholder={label}
          type={type}
          className={className}
          disabled={disabled}
          autoComplete="new-password"
          ref={this[name]}
          onChange={onChng}
        />
        {touched &&
          ((error && (
            <span className="required-field">
              {typeof error === "string"
                ? this.props.labels.labels[error]
                : this.props.labels.labels[error.label].replace(
                  "$$$",
                  error.val
                )}
            </span>
          )) ||
            (warning && <span className="required-field">{warning}</span>))}
      </div>
    </div>
  );

  render() {
    const { labels } = this.props.labels;
    console.log(labels);
    const {
      LBLCOMPANYINFORMATION,
      LBLREGIONALSETTINGS,
      LBLCANCEL,
      LBLSUBMIT,
      LBLCOMPANYNAME,
      LBLTIMEZONE,
      LBLTIMEFORMAT,
      LBLDATEFORMAT,
      LBLLANGUAGE,
      LBLINDICATESREQUIRED,
      LBLEMAILWITHCODE,
      LBLNOOPTIONSDROPDOWN
    } = labels;
    const {
      submitting,
    } = this.state;

    const renderSelectField = ({
      input,
      label,
      name,
      g_value,
      type,
      id,
      className,
      meta: { touched, error },
      children,
      onChng,
      required,
    }) => {
      return (
        <div
          className={classList([
            "form-group",
            "row",
            required === true ? "required" : "",
          ])}
        >
          <label className="col-sm-3 control-label">{label}</label>
          <div className="col-sm-7">
            <select
              value={g_value}
              name={name}
              id={id}
              className={className}
              onChange={onChng}
            >
              {children}
            </select>
            {touched && error && <span className="required-field">{error}</span>}
          </div>
        </div>
      );
    };
 
    const TimeZones = ScCommonData.getTimeZones();
    const Languages = ScCommonData.getLanguages();
    const DatenewFormats = ScCommonData.getDatenewFormats();
    const TimenewFormats = ScCommonData.getTimenewFormats();


    console.log("Languages", Languages)
    console.log("TimeZones", TimeZones)
    console.log("DatenewFormats", DatenewFormats)
    console.log("TimenewFormats", TimenewFormats)

    let company_timezone = TimeZones && TimeZones.filter(j => j.value == this.state.company_timezone.value);
    company_timezone = company_timezone[0]
    let company_language = Languages && Languages.filter(j => j.code == this.state.company_language.code);
    company_language = company_language[0]
    let company_timeformat = TimenewFormats && TimenewFormats.filter(j => j.code == this.state.company_timeformat.code)
    company_timeformat = company_timeformat[0]
    console.log("AAAAAA", this.state.user_timezone)

    return (
      <div>
        <Modal.Header closeButton className='alert-modal-header'>
          <Modal.Title className= "alert-modal-title">
            {" "}
            <h4 className="modal-title alert-modal-title " id="exampleModalLongTitle">
              {labels.LBLGLOBALSETTINGS}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <form className="form">
          <Modal.Body>
            <div className="xcard-box">
              {this.state.loading ? (
                <Loader></Loader>
              ) : (
                <div className="frm-ctn">
                  <form className="form">
                    <div>
                      <div className="row">
                        <div className="col-sm-12 usr-frm-list">
                          <h4 className="mformfieldcontainer mt-0">
                            <b className="settingSubTitle ">{LBLCOMPANYINFORMATION}</b>
                          </h4>
                          <div
                            className="mr-3"
                            style={{
                              textAlign: "right",
                              marginBottom: "-10px",
                            }}
                          >
                            <span style={{ marginRight: "-15px" }} className={styles.incidentRequired}>
                              <span  className={styles.required}>*</span>{" "}
                              {LBLINDICATESREQUIRED}
                            </span>
                          </div>
                          <div className="mformfieldcontainer">
                            <Field
                              name="input-field"
                              type="text"
                              g_value={this.state.company_name}
                              component={this.renderField}
                              label={LBLCOMPANYNAME}
                              required={true}
                              className="input-field"
                              validate={[required, maxLength255]}
                              onChng={(e) => {
                                e.preventDefault();
                                let value = e.target.value;
                                this.setState({
                                  company_name: value,
                                });
                              }}
                            />
                          </div>
                          <h4 className="mformfieldcontainer mt-0 ">
                            <b className="settingSubTitle ">{LBLREGIONALSETTINGS}</b>
                          </h4>
                          <div className="mformfieldcontainer">
                            <p  className={styles.languagelabel}>{LBLLANGUAGE}</p>
                            {console.log("LanguagesLanguages", this.state.company_language)}
                            <Autocomplete
                              id="combo-box-demo"
                              //component={renderSelectField}
                              aria-required="true"
                              required
                              disableClearable
                              noOptionsText={LBLNOOPTIONSDROPDOWN}
                              style={{ width: "350px" }}
                              options={Languages}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, values) =>
                                option.code === values.code
                              }
                              value={company_language}
                              defaultValue={company_language}
                              onChange={(e, i) => {
                                console.log("LANLAN", i, i.code)
                                this.setState({
                                  company_language: i,
                                });
                              }}
                              renderOption={(option) => (
                                <div style={{ fontSize: "14px" }}>{option.name}</div>
                              )}
                              renderInput={(params) => (
                                <div>
                                  <TextField
                                    {...params}
                                  />
                                </div>
                              )}
                            />
                          </div>
                          <br />
                          <div className="mformfieldcontainer">
                            <p className={styles.timezonelabel} >{LBLTIMEZONE}</p>
                            <Autocomplete
                              id="combo-box-demo"
                              //component={renderSelectField}
                              aria-required="true"
                              required
                              disableClearable
                              noOptionsText={LBLNOOPTIONSDROPDOWN}
                              style={{ width: "350px" }}
                              options={TimeZones}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, values) =>
                                option.value === values.value
                              }
                              value={company_timezone}
                              defaultValue={company_timezone}
                              onChange={(e, i) => {
                                console.log("TZ", i, i.code)
                                this.setState({
                                  company_timezone: i,
                                });
                              }}
                              renderOption={(option) => (
                                <div style={{ fontSize: "14px" }}>{option.name}</div>
                              )}
                              renderInput={(params) => (
                                <div>
                                  <TextField

                                    {...params}
                                  />
                                </div>
                              )}
                            />
                          </div>
                          <br />
                          <div className="mformfieldcontainer">
                            <p className={styles.dateformatlabel}>{LBLDATEFORMAT}</p>
                            <Autocomplete
                              id="combo-box-demo"
                              aria-required="true"
                              required
                              disableClearable
                              style={{ width: "350px" }}
                              //label={LBLDATEFORMAT}
                              options={DatenewFormats}
                              noOptionsText={LBLNOOPTIONSDROPDOWN}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, values) =>
                                option.code === values.code
                              }
                              value={this.state.company_dateformat}
                              defaultValue={this.state.company_dateformat}
                              onChange={(e, i) => {
                                console.log("DFF", i)
                                this.setState({
                                  company_dateformat: i,
                                });
                              }}
                              renderOption={(option) => (
                                <div style={{ fontSize: "14px" }}>{option.name}</div>
                              )}
                              renderInput={(params) => (
                                <div>
                                  <TextField
                                    {...params}
                                  />
                                </div>
                              )}
                            />

                          </div>
                          <br />
                          <div className="mformfieldcontainer">
                            <p className={styles.timeformatlabel}>{LBLTIMEFORMAT}</p>
                            <Autocomplete
                              id="combo-box-demo"
                              //className="form-control role-change-drp"
                              aria-required="true"
                              required
                              disableClearable
                              noOptionsText={LBLNOOPTIONSDROPDOWN}
                              style={{ width: "350px" }}
                              options={TimenewFormats}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, values) =>
                                option.code === values.code
                              }
                              value={company_timeformat}
                              defaultValue={company_timeformat}
                              onChange={(e, i) => {
                                console.log("TimeF", i)
                                this.setState({
                                  company_timeformat: i,
                                });
                              }}
                              renderOption={(option) => (
                                <div style={{ fontSize: "14px" }}>{option.name}</div>
                              )}
                              renderInput={(params) => (
                                <div>
                                  <TextField
                                    {...params}
                                  />
                                </div>
                              )}
                            />
                          </div>
                          <br />

                          {this.state.company_group_types.map((group, i) => {
                            if (
                              this.state.company_group_labels[group] &&
                              this.state.company_group_labels[group][
                              "lbl_" + group
                              ]
                            ) {
                              return (
                                <div className="mformfieldcontainer">
                                  <Field
                                    name={
                                      this.state.company_group_labels[group][
                                      "lbl_" + group
                                      ]
                                    }
                                    type="text"
                                    g_value={this.state.groups[group]}
                                    component={this.renderField}
                                    label={
                                      labels["LBLGROUP" + group.split("_")[1]]
                                    }
                                    className="input-field"
                                    validate={[required, maxLength255]}
                                    onChng={(e) => {
                                      e.preventDefault();
                                      let value = e.target.value;

                                      if (!/^ *$/.test(value)) {
                                        this.setState((prevState) => ({
                                          group_change: true,
                                          groups: {
                                            ...prevState.groups,
                                            [group]: value,
                                          },
                                        }));
                                      } else {
                                        this.setState((prevState) => ({
                                          group_change: true,
                                          groups: {
                                            ...prevState.groups,
                                            [group]: value,
                                          },
                                        }));
                                      }
                                    }}
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div className="mformfieldcontainer">
                                  <Field
                                    name={group + "_" + i}
                                    type="text"
                                    g_value={this.state.groups[group]}
                                    component={this.renderField}
                                    label={
                                      labels["LBLGROUP" + group.split("_")[1]]
                                    }
                                    className="input-field"
                                    validate={[required, maxLength255]}
                                    onChng={(e) => {
                                      e.preventDefault();
                                      let value = e.target.value;

                                      if (!/^ *$/.test(value)) {
                                        this.setState((prevState) => ({
                                          group_change: true,
                                          groups: {
                                            ...prevState.groups,
                                            [group]: value,
                                          },
                                        }));
                                      } else {
                                        this.setState((prevState) => ({
                                          group_change: true,
                                          groups: {
                                            ...prevState.groups,
                                            [group]: value,
                                          },
                                        }));
                                      }
                                    }}
                                  />
                                </div>
                              );
                            }
                          })}
                          <div className="mformfieldcontainer">
                            <label className="emailCodeTitle ">{LBLEMAILWITHCODE}</label>
                            <Field
                              name="status"
                              g_value={this.getValue("email_with_code")}
                              component={renderToggleInput}
                              id="custom-switch2"
                              label={LBLEMAILWITHCODE}
                              className="input-field"
                              validate={[required]}
                              onChng={(e) => {
                                this.setState({
                                  email_with_code: !this.state.email_with_code,
                                });
                                console.log(e.target.value);
                              }}
                            ></Field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </Modal.Body>
        </form>
        <Modal.Footer>
          <div style={{ float: "right" }}>
            <button
              type="button"
              className="button-style secondary-btn"
              id="btnaddCancel"
              onClick={this.cancelChange}
            >
              {LBLCANCEL}
            </button>
            <button
              style={{ marginLeft: "10px" }}
              type="button"
              className="button-style primary-btn"
              disabled={submitting}
              onClick={this.saveSettings}
            >
              {LBLSUBMIT}
            </button>
          </div>
        </Modal.Footer>
      </div>
    );
  }
}
const renderToggleInput = (field) => {
  console.log(field);
  return (
    <Form.Check
      type="switch"
      id="custom-switch2"
      label=""
      checked={field.g_value}
      onChange={field.onChng}
      icons={false}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
  };
};

export default connect(mapStateToProps, null)(EditGlobalSettings);

import { HttpInterceptor } from "../auth/HttpInterceptor";
class products_service{
    
    getproducts= async (vParams) =>{
        return await HttpInterceptor.get("products/userproductsdetails/"+vParams.userid+"/"+vParams.languagecode, vParams);
    }

    updateproducttakentime= async (vParams) =>{
        return await HttpInterceptor.put("courses/updateproducttakensessiontime", vParams);
    }

    getproductunits= async (vParams) =>{
        return await HttpInterceptor.get("products/userproductunitsdetails/"+vParams.productid+"/"+vParams.userid+"/"+vParams.languagecode);
    }

    getproducts_all= async (vParams) =>{
        return await HttpInterceptor.get("products/userproducts_all/"+vParams.userid, vParams);
    }
    getproducts_all_multi= async (vParams) =>{
        return await HttpInterceptor.post("products/userproducts_all", vParams);
    }

    getactivitieslist= async (vParams) =>{
        return await HttpInterceptor.get("products/useractivitycapsules/"+vParams.userid+"/"+vParams.productid +"/"+vParams.activityid, vParams);
    }

    getuserunitactivitieslist= async (vParams) =>{
        return await HttpInterceptor.get("products/useractivityunitcapsules/"+vParams.userid+"/"+vParams.productid +"/"+vParams.activityid+"/"+vParams.unitid, vParams);
    }
    
    getproductunitscapsules= async (vParams) =>{
        return await HttpInterceptor.get("products/userproductunitcapusuledetails/"+vParams.productid+"/"+vParams.unitid+"/"+vParams.userid+"/"+vParams.languagecode+"/?dateformat="+vParams.dateformat+" ");
    }

    getproductunitscertificate = async (vParams) => {
        return await HttpInterceptor.get("products/coursecertificatedetails/"+ vParams.productid + "/" + vParams.userid+ "/" +  vParams.languagecode+"/?dateformat="+vParams.dateformat);
    }
}

export default new products_service;
import React from "react";
import DataTable from "react-data-table-component";
import Loader from "../../../admin/components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import ChannelsDatatable from "./ChannelsDatatable.js";
import emptyImage from '../../../assets/images/storyfeeds_emptyst.png'
import orderBy from "lodash/orderBy";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class GroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupSearchValue: "",
      showErrMesg: false,
      showUserPopup: 0,
      userList: [],
      selUserDetailsItem: null,
      groupTypeNames: this.props.groupTypeNames,
      selectedGroupType: this.props.groupTypeNames[0].group_type_id,
      userInfo: this.props.userInfo,
      listGroups: this.props.listGroups,
      isLoadAPI: 0,
      showModalDetails: false,
      searchText: "",
    };
  }
  componentDidMount() {
  }
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.channelsLanguageData &&
        this.props.channelsLanguageData[paramLBL]
        ? this.props.channelsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };

  groupTableHeader = () => {
    return (
      <div className="table-filter channelenrollGroupHeader" >
        {this.state.groupTypeNames.length !== 0 ? (
          <div className="filter-wth-label feeds-manage-channels">
            <span className="label">{this.getResourceFromLabels("LBLGROUPTYPES")} </span>
            <Autocomplete
              className="filter-drpdwn"
              aria-required="true"
              required
              disableClearable
              noOptionsText={this.getResourceFromLabels("LBLNOOPTIONSDROPDOWN")}
              options={this.state.groupTypeNames}
              getOptionLabel={(option) => option["lbl_" + option.group_type_id]}
              value={this.state.selectedGroupType ? this.state.groupTypeNames.find((option) => { return (this.state.selectedGroupType === option.group_type_id) }) ?? null : null}
              onChange={(e, product) => {
                this.setState(
                  {
                    selectedGroupType: product.group_type_id,
                  },
                  () => {
                    this.props.getGroupList(this.state.selectedGroupType);
                  }
                );
              }}

              renderOption={(option) => (
                <div style={{ fontSize: "14px" }}>{option["lbl_" + option.group_type_id]}</div>
              )}
              renderInput={(params) => (
                <div>
                  <TextField
                    {...params}
                  />
                </div>
              )}
            />
          </div>
        ) : (
          ""
        )}
        <input
          type="text"
          className="input-field channelenrollGroupInputField"
          placeholder={this.getResourceFromLabels("LBLSEARCHGRPNAME")}
          value={this.state.groupSearchValue}
          onChange={this.searchGroupHandler}
          onKeyDown={this.searchGroupHandler}
        />
      </div>
    );
  };
  popupGroupTableHeader = () => {
    return (
      <div className="table-filter">
        <div className="assignment-header">
          <input
            type="text"
            className="input-field"
            placeholder={this.getResourceFromLabels("LBLSEARCHBYNAMEEMAIL")}
            value={this.state.searchText}
            onChange={this.searchUser}
            onKeyDown={this.searchUser}
          />
        </div>
      </div>
    );
  };
  searchUser = (e) => {
    this.setState({ searchText: e.target.value });
  };
  searchGroupHandler = (e) => {
    this.setState({
      groupSearchValue: e.target.value,
    });
  };

  updateGroupAssignment = (item, status, evt) => {
    if (item !== undefined) {
      this.state.showErrMesg = false;
      for (var i = 0; i < this.props.listGroups.length; i++) {
        if (item.group_id === this.props.listGroups[i].group_id) {
          this.props.listGroups[i].isNewAssigned = evt.target.checked ? 1 : 0;
          this.setState((state, props) => ({
            listGroups: this.props.listGroups,
            loading: false,
          }));
          break;
        }
      }
    }
  };
  cancelGroupList = () => {
    this.props.cancelGroupList();
  };

  updateGroupListToChannel = (evt) => {
    var postData = [];
    this.props.listGroups.map((obj) => {
      if (
        obj.isNewAssigned !== undefined &&
        obj.isNewAssigned !== obj.asigned_groups
      ) {
        obj.asigned_groups = obj.isNewAssigned;
        delete obj.isNewAssigned;
        postData.push(obj);
      }
    });
    this.setState({ isLoadAPI: 1 });
    this.props.updateGroupListToChannel(postData);
  };
  assignedUserViewHandler = (item, evt) => {
    let assigned_user_info = [];
    if (item.users_assigned_info.length > 0) {
      item.users_assigned_info.map((userItem) => {
        assigned_user_info.push(this.props.userInfo[userItem]);
      });
    }

    this.setState(
      {
        userList: assigned_user_info,
        selUserDetailsItem: item,
        showUserPopup: 1,
        showModalDetails: true,
      },
      () => {
        if (this.state.showUserPopup === 1) {
        }
      }
    );
  };

  popupOutsideClickHandler = (evt) => {
    let elements = evt.target; //document.getElementById("target");
    let els = [];
    let classAtr = "";
    while (elements) {
      els.unshift(elements);
      elements = elements.parentNode;
      classAtr =
        elements && elements.getAttribute && elements.getAttribute("class")
          ? elements.getAttribute("class")
          : "";
      if (classAtr === "ssi-mc-userListGroupPopupWrapper") {
        break;
      }
    }

    if (!classAtr || classAtr !== "ssi-mc-userListGroupPopupWrapper") {
      document.removeEventListener("click", this.popupOutsideClickHandler);
      this.setState({
        showUserPopup: 0,
      });
    }
  };
  displayUserName = (profileId) => {
    return this.props.userInfo[profileId] &&
      this.props.userInfo[profileId].profile_name
      ? this.props.userInfo[profileId].profile_name
      : profileId;
  };
  closeModalHandler = () => {
    this.setState({
      showModalDetails: false,
      searchText: "",
    });
  };

  render() {
    const { LBLOF, LBLROWSPERPAGE, LBLALL } = this.props.channelsLanguageData;
    const { listGroups } = this.props;
    const filterGroup = listGroups.filter((item) => {
      if (
        item.group_name &&
        item.group_name
          .toLowerCase()
          .includes(this.state.groupSearchValue.toLocaleLowerCase())
      ) {
        return item;
      }
    });
    const filterPopupGroup = this.state.userList.filter((item) => {
      if (
        (item.profile_name &&
          item.profile_name
            .toLowerCase()
            .includes(this.state.searchText.toLocaleLowerCase())) ||
        (item.email &&
          item.email
            .toLowerCase()
            .includes(this.state.searchText.toLocaleLowerCase()))
      ) {
        return item;
      }
    });
    let groupTableHeader = this.groupTableHeader();
    let popupGroupTableHeader = this.popupGroupTableHeader();
    let groupcolumns = [
      {
        name: this.getResourceFromLabels("LBLSELECT"),
        selector: "order",
        sortable: true,
        width: "10%",
        compact: true,
        cell: (row) => (
          <div className="w100">
            <label className="show-sm">
              {this.getResourceFromLabels("LBLSELECTENROLLGROUPS")}
            </label>
            <div className="icoBtn">
              <label className="check-lbl">
                <input
                  type="checkbox"
                  name={"chkg_" + row.id}
                  id={"chkg_" + row.id}
                  data-toggle="modal"
                  checked={
                    row.isNewAssigned !== undefined
                      ? row.isNewAssigned
                      : row.asigned_groups
                  }
                  onChange={this.updateGroupAssignment.bind(
                    this,
                    row,
                    row.isNewAssigned ? 1 : 0
                  )}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        ),
      },
      {
        name: this.getResourceFromLabels("LBLGROUPNAME"),
        selector: "group_name",
        sortable: true,
        width: "50%",
        compact: true,
        cell: (row) => (
          <div>
            <label className="show-sm">
              {this.getResourceFromLabels("LBLGROUPNAME")}
            </label>
            {row.group_name}
          </div>
        ),
      },
      {
        name: this.getResourceFromLabels("LBLSTATUS"),
        selector: "active",
        sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => (
          <div>
            <label className="show-sm">
              {this.getResourceFromLabels("LBLSTATUS")}
            </label>
            {row.active === 0
              ? this.getResourceFromLabels("LBLINACTIVE")
              : this.getResourceFromLabels("LBLACTIVE")}
          </div>
        ),
      },
      {
        name: this.getResourceFromLabels("LBLUSERENROLLED"),
        sortable: true,
        width: "20%",
        compact: true,
        cell: (row) => (
          <div>
            <label className="show-sm">
              {this.getResourceFromLabels("LBLUSERENROLLED")}
            </label>
            <div
              className={
                row.users_assigned_info &&
                  row.users_assigned_info.length &&
                  row.asigned_groups > 0
                  ? "ssi-mc-userListAssigned"
                  : "ssi-mc-userListAssignedDisable"
              }
              onClick={this.assignedUserViewHandler.bind(this, row)}
            >
              <span className="count">
                {row.users_assigned_info &&
                  row.users_assigned_info.length &&
                  row.asigned_groups > 0
                  ? row.users_assigned_info.length
                  : "-"}
              </span>
              <span className="groupIcon fa fa-users"></span>
            </div>
          </div>
        ),
      },
    ];

    const customSort = (rows, field, direction) => {
      const handleField = (row) => {
        if (
          row[field] &&
          row[field] !== 1 &&
          row[field] !== 0 &&
          typeof row[field] !== "boolean"
        ) {
          return row[field].toString().toLowerCase();
        }

        return row[field];
      };

      return orderBy(rows, handleField, direction);
    };

    let popupGroupColumns = [
      {
        name: this.getResourceFromLabels("LBLNAME"),
        selector: "profile_name",
        sortable: true,
        width: "50%",
        compact: true,
      },
      {
        name: this.getResourceFromLabels("LBLEMAILID"),
        selector: "email",
        sortable: true,
        width: "50%",
        // right: true,
        compact: true,
      },
    ];
    let groupdata = filterGroup;
    let popupGroupData = filterPopupGroup;
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };

    return (
      <div className="mob-pad">
        <div className="page-title">
          <h3>
            {this.getResourceFromLabels("LBLCHANNELASSINMENTENROLLGROUPS")}
          </h3>
        </div>
        <hr className="head-line" />
        <div className="ssi-manch-userListChannelHeader">
          <span className="pageSubTitle">{this.props.selectedChannelItem.channel_name}</span>
        </div>
        <div className="">
          {this.state.loading ? (
            <div>
              <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                <header className="sc-fzoXzr hlidmt">
                  <Skeleton height={41} />
                </header>
                <div className="sc-fznWqX gnahTY">
                  <div className="sc-AxjAm gIMaKV rdt_Table">
                    <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                      <Skeleton height={56} />
                    </div>
                    <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                      <Skeleton height={480} />
                    </div>
                  </div>
                </div>
                <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                  <Skeleton height={56} />
                </nav>
              </div>
              <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                <header className="sc-fzoXzr hlidmt">
                  <Skeleton height={34} />
                </header>
                <div className="sc-fznWqX gnahTY">
                  <div className="sc-AxjAm gIMaKV rdt_Table">
                    <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                      {skel.map((i) => {
                        return (
                          <div
                            id={"row-" + i}
                            className="sc-fzoLsD doBktq rdt_TableRow"
                          >
                            <Skeleton height={152} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                  <div className="sc-fzpmMD ftIMtk">
                    <Skeleton height={40} />
                  </div>
                </nav>
              </div>
            </div>
          ) : (
            <DataTable
              columns={groupcolumns}
              data={groupdata}
              className="datatable-list"
              pagination
              subHeader
              subHeaderComponent={groupTableHeader}
              sortFunction={customSort}
              noDataComponent={<EmptyState image={emptyImage} text2={this.getResourceFromLabels("LBLNORECORDS")} />}
              paginationComponentOptions={paginationComponentOptions}
            />
          )}
        </div>
        <div className="row section-body">
          <div
            id="selectproductError"
            className="col-md-12 text-danger text-center d-none"
          >
            {this.getResourceFromLabels("LBLATLEASTGROUP")}
          </div>
          {this.state.isLoadAPI === 1 ? (
            <div className="ssi-manch-spinnerAbsoluteBotton">
              {/* <Spinner animation="grow" /> */}
              <Loader></Loader>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12 text-right">
            <button
              type="button"
              className="button-style  secondary-btn"
              style={{marginRight:"8px"}}
              //className="btn btnspacing btn-secondary"
              onClick={this.cancelGroupList}
            >
              {this.getResourceFromLabels("LBLCANCEL")}
            </button>
            <button
              type="button"
              className="button-style primary-btn"
              //className="btn btnspacing btn-primary"
              onClick={this.updateGroupListToChannel}
            >
              {this.getResourceFromLabels("LBLCONFIRM")}
            </button>
          </div>
        </div>
        {this.state.showUserPopup === 1 ? (
          <ChannelsDatatable
            showModal={this.state.showModalDetails}
            closeModal={this.closeModalHandler}
            modalTitle={this.state.selUserDetailsItem.group_name}
            datatableHeader={popupGroupTableHeader}
            datatableColumns={popupGroupColumns}
            datatableData={popupGroupData}
            noDataComponent={this.getResourceFromLabels("LBLNORECORDS")}
            paginationComponentOptions={paginationComponentOptions}
            paginationPerPage={5}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default GroupList;

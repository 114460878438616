import React, { Component } from 'react';
import "./forgot-password.scss"
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ForgotPasswordForm from './ForgotPasswordForm';
import ScCommonServices from '../services/common_services';
import PasswordForm from '../set_password/PasswordForm';
import CompanyLogo from '../admin/components/companylogo/companylogo';
import ToastMessage from '../shared/ToastComponent/ToastComponent';

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        let url = this.props.location.search;
        let params = this.parseQuery(url);
        this.state = {
            loading: true,
            email: "",
            validmsg: "",
            vSuccessStatus: true,
            showModal: false,
            btnStatus: false,
            closeFunc: true,
            token: params['4hf_verification'],
            isFg: params.fg,
            link_state: false,
            showToast: {
                show: false,
                alertClass: "",
                status: "",
                message: "",
                changeInToast: new Date(),
            },
            wait_here: false,
            user_attribute: props.location.state ? props.location.state.user_attribute : null,
            invite_code: props.location.state ? props.location.state.code : null,
        }
    }
    parseQuery = (queryString) => {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    toastFunction = (show,validMsg) => {
        this.setState({
            showToast: {
                show: show,
                alertClass: "success",
                status: "Success",
                message: validMsg,
                changeInToast: new Date(), loading: false, wait_here: false
            },
        });
        setTimeout(() => {
            this.setState({
                showToast: {
                    show: false,
                    alertClass: "",
                    status: "",
                    message: "",
                    changeInToast: new Date(), loading: false
                },
            });
        }, 1500);
    }
    componentDidMount() {
        if (this.state.token) {
            this.checkUser();
        } else {
            this.setState({
                loading: false
            })
        }
    }
    checkUser = () => {
        let data = {
            fg_code: this.state.token
        }
        ScCommonServices.validatePasswordLink(data).then(response => {
            this.setState({
                loading: false
            })
        }).catch(err => {
            if (!err.response.data.status) {
                this.setState({
                    vSuccessStatus: false,
                    validmsg: this.props.labels.labels[err.response.data.message],
                    loading: false,

                })
            }
        })
    }
    forgotPassword = (values) => {
        this.setState({ btnStatus: true })
        if (this.state.closeFunc === true) {
            this.setState({ closeFunc: false })
            let data = {
                "email": values.email,
                "app_type": 'website',
                "auth_type": 'forgotpassword_link'
            }
            ScCommonServices.forgotPassword(data).then(response => {

                if (response.status) {
                    this.toastFunction(true,this.props.labels.labels.LBLEMAILSENTSUCCESS)
                    this.setState({
                        vSuccessStatus: true,
                        loading: false, wait_here: false

                    })
                } else if (!response.status) {
                    this.setState({
                        validmsg: response.message,
                        vSuccessStatus: false,
                        btnStatus: false, loading: false

                    })
                }
            }).catch(err => {
                console.log(err,"error")
                if (err) {        
                    if(typeof err.response.data === "object"){
                        this.toastFunction(true,this.props.labels.labels["LBLEMAILNOTFOUND"])
                        this.setState({
                            btnStatus: false,
                            closeFunc: true, loading: false, wait_here: true
                        })
                    }else{
                        this.toastFunction(true,this.props.labels.labels[err.response.data.message])
                        this.setState({
                            btnStatus: false,
                            closeFunc: true, loading: false, wait_here: true
                        })
                    }
                }
            })
        }
    }
    set_password = async (values) => {
        this.setState({
            vSuccessStatus: true,
            validmsg: ''
        })
        let password = await ScCommonServices.createHash(values.password);
        let data = null;
        if (this.state.invite_code && this.state.user_attribute) {
            data = {
                otp: true,
                user_attribute: this.state.user_attribute,
                u_pwd: password,
            }
        } else {
            data = {
                fg_code: this.state.token,
                u_pwd: password,
            }
        }

        ScCommonServices.setUserPassword(data).then(res => {
            if (res.status) {
                this.setState({
                    showToast: {
                        show: true,
                        alertClass: "success",
                        status: "Success",
                        message: this.state.isFg ? this.props.labels.labels.LBLUPDTSCCESS : this.state.invite_code ? this.props.labels.labels.LBLSETPASSWDSUCCESS : "",
                        changeInToast: new Date(), loading: false, wait_here: false
                    },
                });
                setTimeout(() => {
                    this.setState({
                        showToast: {
                            show: false,
                            alertClass: "",
                            status: "",
                            message: "",
                            changeInToast: new Date(), loading: false
                        },
                    });
                    this.props.history.push('/');
                }, 1500);

                // 
            }
        }).catch(err => {
            if (err.response.data && !err.response.data.status) {
                this.setState({
                    vSuccessStatus: false,
                    validmsg: this.props.labels.labels[err.response.data.message], loading: false
                })
            }
        })
    }
    closeModal = () => {
        this.setState({ showModal: false });
        if (this.state.vSuccessStatus && !this.state.wait_here) {
            this.props.history.push('/');
        }
    }
    render() {

        const { labels, location } = this.props;
        const { isFg, invite_code } = this.state;
        if (((this.state.token && this.state.isFg && this.state.vSuccessStatus) || (this.state.invite_code && this.state.user_attribute)) && !this.state.loading) {
            return (
                <div className="forgot-password-section">
                    <ToastMessage
                        show={this.state.showToast["show"]}
                        helpers={this.state.showToast}
                    ></ToastMessage>
                    <div className="card">
                        <div className="card-body">
                            <div className="container text-center">
                                {location.state && location.state.company_logo ? <CompanyLogo className="applogo" imageName={location.state.company_logo} imgWidth="317px" /> : this.props.company_logo ? <CompanyLogo className="applogo" imageName={this.props.company_logo} imgWidth="317px" /> : ""}
                            </div>
                            {isFg ? <h3>{labels.labels.LBLRESETPASSWORD}</h3> : null}
                            {invite_code ? <h3>{labels.labels.LBLSETPASSWORD}</h3> : null}
                            <PasswordForm PasswordSubmit={this.set_password} labels={labels} />
                        </div>
                    </div>
                </div>
            )
        } else if (!this.state.vSuccessStatus && !this.state.loading) {
            return (
                <div className="forgot-password-section">

                    <div className="card">
                        <div className="card-body">
                            <div className="container text-center">
                                {location.state ? location.state.company_logo ? <CompanyLogo className="applogo" imageName={location.state.company_logo} imgWidth="317px" /> : "" : this.props.company_logo ? <CompanyLogo className="applogo" imageName={this.props.company_logo} imgWidth="317px" /> : ""}
                            </div>
                            <h3>{labels.labels.LBLRESETPASSWORD}</h3>
                            <p className={(this.state.vSuccessStatus ? 'text-success' : 'text-danger')}>{this.state.validmsg}</p>

                        </div>
                    </div>
                </div>
            )
        } else if (!this.state.loading) {
            return (
                <div className="forgot-password-section">

                    <div className="card">
                        <div className="card-body">
                            <div className="container text-center">
                                {/* <img alt='' src="./images/login-logo.png" /> */}
                                {location.state ? location.state.company_logo ? <CompanyLogo className="applogo" imageName={location.state.company_logo} imgWidth="317px" /> : "" : ""}
                            </div>
                            <h3>{labels.labels.LBLFORGOTPASSWORD}</h3>
                            <ForgotPasswordForm labels={labels} onSubmit={this.forgotPassword} btnStatus={this.state.btnStatus}></ForgotPasswordForm>
                    <ToastMessage
                        show={this.state.showToast["show"]}
                        helpers={this.state.showToast}
                    ></ToastMessage>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="forgot-password-section">

                    <div className="card">
                        <div className="card-body">
                            <div className="container text-center">
                                {location.state ? location.state.company_logo ? <CompanyLogo className="applogo" imageName={location.state.company_logo} imgWidth="317px" /> : "" : this.props.company_logo ? <CompanyLogo className="applogo" imageName={this.props.company_logo} imgWidth="317px" /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

ForgotPassword = reduxForm({
    form: 'forgotpasswordform'
    // a unique name for this form
})(ForgotPassword);

export default connect()(ForgotPassword)
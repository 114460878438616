import React from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { Field, reduxForm } from "redux-form";
import { Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { classList } from "dynamic-class-list";
import QRCode from "qrcode";
import {
  required,
  number,
} from "../validate/Validate";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import styles from "./trainingsettings.module.scss"
const $ = require("jquery");



const cstLabels = {
  "LBLUSERNAME": "User Name",
  "LBLPRODUCTNAME": "Course Name",
  "LBLUNITNAME": "Unit Name",
  "LBLCAPSULENAME": "Capsule Name",
  "LBLASSIGNEDCOURSE": "Assigned Courses",
  "LBLVIEWNOTIFICATION": "View Message",
  "LBLHIDENOTIFICATION": "Close Message",
  "LBLUPDTNSETTINGSSCCESS": "The Notification settings are updated successfully",
  "LBLNOTIFICATIONS": "Notifications",
  "LBLSURVEY": "Survey",
  "LBLNOTIFYGENERALASSIGNMENT": "General Notification",
  "LBLNOTIFYSURVEYAVAILABLE": "Survey Notification",
  "LBLNOTIFYUNITDRIPAVAILABLE": "Unit activation Notification",
  "LBLNOTIFYUNITDRIPAVAILABLEMOD": "Module activation Notification",
  "LBLNOTIFYCAPSULEDRIPAVAILABLE": "Capsule activation Notification",
  "LBLNOTIFYGENERALASSIGNMENTDC": "Users will be notified on enrollment of course",
  "LBLNOTIFYSURVEYAVAILABLEDC": "Users will be notified when Survey is available for the course",
  "LBLNOTIFYUNITDRIPAVAILABLEDC": "Users will be notified when an Unit is activated in the course",
  "LBLNOTIFYMODDRIPAVAILABLEDC": "Users will be notified when an Module is activated in the course",
  "LBLNOTIFYCAPSULEDRIPAVAILABLEDC": "Users will be notified when a capsule is activated for the respective unit in the course",
  "LBLDOWNLOAD": "Download",
  "LBLDRIPBYDAY": "Drip by Day",
  "LBLDRIPBYDAYDESCP": "Unlock contents based on the date the user has enrolled to the course (i.e. after X Days). Each user will get access to the learning material separately.",
  "LBLDRIPDAYSASSIGNEDDATEPRODUCT": "How many day(s) will this section drip after user enrollment? $br$ $$$2 Day(s)",
  "LBLDRIPDAYSASSIGNEDDATEUNIT": "How many day(s) will this section drip after user enrollment? $br$ $$$2 Day(s)",
  "LBLDRIPDAYSCOMPLETEDDATEUNIT": "How many day(s) will this section drip after the completion of $$$3? $br$ $$$2 Day(s)",
  "LBLDRIPDAYSCOMPLETEDDATECAPSULE": "How many day(s) will this section drip after the completion of $$$3? $br$ $$$2 Day(s)",
  "LBLREMOTELAUNCH": "SafeStart Survey Launch",
  "LBLSURVEYLINKS": "Survey Links",
  "LBLPRESURVEY": "Pre SafeStart Survey",
  "LBLPOSTSURVEY": "Post SafeStart Survey",
  "LBLLINKLOGINPPWD": "Link for survey access with user's login credentials",
  "LBLLINKUSERNAME": "Link for survey access with user's username",
  "LBLDRIPFOR": "Refresher Capsule For",
  "LBLUNITDRIP": "Unit Drip $$$",
  "LBLCAPSDRIP": "Refresher Capsule Drip $$$",
  "LBLONCOURSEENROLLMENT": "On Enrollment",
  "LBLONUNITCOMPLETE": "On Unit Complete",
  "LBLONCAPSULECOMPLETE": "On Capsule Complete",
  "LBLDRIPTYPE": "Drip Type",
  "LBLPRODUCTNAME": "Course Name",
  "LBLUNITS": "Unit(s)",
  "LBLGENERAL": "General",
  "LBLUPDTSCCESS": "The Training Options are updated successfully",
  "LBLUNITDRIPUPDATESUCCESS": "Course Drip updated successfully",
  "LBLCAPSULEDRIPUPDATESUCCESS": "Refresher Capsule Drip updated successfully",
  "LBLWILLBERELEASED": "will be released",
  "LBLDAYS": "days",
  "LBLTRAININGOPTIONS": "Training Options",
  "LBLTRAININGDRIP": "Course Drip",
  "LBLTRAININGDRIPMOD" : "Module Release",
  "LBLNOTIFYUNITDRIPAVAILABLEMOD": "Module Activation Notification",
  "LBLUNITDRIPENABLEDMOD" : "Module(s) Timed Release Enabled",
  "LBLCAPSULEDRIP": "Refresher Capsule Drip",
  "LBLNOCAPSULES": "No Refresher Capsules",
  "LBLAFTERENROLLMENT": "after enrollment",
  "LBLAFTERCOMPPREVUNIT": "after completing the previous unit",
  "LBLAFTERCOMPUNIT": "after completing the unit",
  "LBLAFTERCOMPPREVCAPSULE": "after completing the previous refresher capsule",
  "LBLUNITDRIPENABLED": "Units Drip Enabled",
  "LBLCAPSULEDRIPENABLED": "Capsules Drip Enabled",
  "LBLUNIT": "Unit",
  "LBLCAPSULE": "Capsule",
  "LBLACTIVITY_ENABLED": "Refresher Capsules Enabled",
  "LBLIMAGE_LOADING_TIME": "Images Transition Time",
  "LBLAUTO_ADVANCE_BOARD": "Enable Auto Advance Board",
  "LBLAUTO_ADVANCE_QUESTION_BOARD": "Question Board Auto Advance Duration",
  "LBLAUTO_ADVANCE_CARD_BOARD": "Card Board Auto Advance Duration",
  "LBLIDLE_TIMEOUT_IN_MINUTES": "Idle Timeout Duration",
  "LBLWARNING_TIMEOUT_IN_MINUTES": "Idle Timeout Warning Duration",
  "LBLHIDE_IDLE_TIMEOUT": "Hide Idle Timeout",
  "LBLSEQUENTIAL_ACCESS_ALL": "Enable Sequential Flow for All",
  "LBLSEQUENTIAL_ACCESS_UNIT": "Enable Sequential Flow for Unit",
  "LBLSEQUENTIAL_ACCESS_MODULE": "Enable Sequential Flow for Module",
  "LBLSEQUENTIAL_ACCESS_BOARD": "Enable Sequential Flow for Board",
  "LBLQUIZ_RANDOMIZATION": "Randomize Quiz",
  "LBLQUIZ_PASS_PERCENTAGE": "Quiz Mastery Score",
  "LBLHIDE_BOARD_NUMBER": "Hide Board Number",
  "LBLHIDE_RIGHT_ANSWER": "Hide Right Answer",
  "LBLHIDE_INCORRECT_FEEDBACK": "Hide Incorrect Feedback",
  "LBLFORCE_USER_FOR_RIGHT_ANSWER": "Force Learner Right Answer",
  "LBLEDITPRODUCT": "Edit Course",
  "LBLEDIT": "Edit",
  "LBLSETTINGS": "Settings",
  "LBLUNITLEVELCERTIFICATE": "Unit Level Certificate",
  "LBLSEQUENTIALACCESS": "Unit Sequential Access",
  "LBLVIDEOSEEKBARRESTRICTION": "Video Seek Bar Restriction",
  "LBLENABLEDISABLECERTIFICATE": "Enable or disable certificate awarded upon completion of individual course units.",
  "LBLENABLEDISABLEUNITSEQUENTIAL": "Enable or disable accessing course units in a sequential order.",
  "LBLENABLEDISABLEVIDEOSEEKBAR": "Enable or disable the navigation using the video seek bar.",
  "LBLSETTINGSUPDATED": "Settings have been successfully updated",
  "LBLMODULES":"Module(s)" ,
  "LBLMODULELEVELCERTIFICATE":"Module level certificate",
  "LBLENABLEDISABLECERTIFICATEMODULE": "Enable or disable certificate awarded upon completion of individual course modules",
  "LBLMODULESEQUENTIALACCESS":"Module Sequential Access",
  "LBLENABLEDISABLEMODULESEQUENTIAL": "Enable or disable accessing course module in a sequential order."
  
}
const re = /^[0-9\b]+$/;
class TrainingSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTab: "general",
      loading: true,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      dateformat: props.dateformat,
      toggleunitstate: {},
      mailview: false,
      modal_mail_view_show: false,
      crsoption_settings: null,
      subShow: false,
      childNode: null
    };
  }
  getMessage = (xname) => {
    let xlabels = {
      capsule_available: this.getLabel("LBLNOTIFYCAPSULEDRIPAVAILABLE"),
      unit_available: this.getLabel(this.state.childNode && this.state.childNode === 'modules'? "LBLNOTIFYUNITDRIPAVAILABLEMOD" :"LBLNOTIFYUNITDRIPAVAILABLE"),
      courses_enrolled: this.getLabel("LBLNOTIFYGENERALASSIGNMENT"),
      presurvey_available: this.getLabel("LBLNOTIFYSURVEYAVAILABLE"),
      postsurvey_available: this.getLabel("LBLNOTIFYSURVEYAVAILABLE")
    }
    try {
      let xurl = String(window.location.href).split("/schome")[0]
      let xmail_body = this.state.mail_templates[xname].body[this.state.units[0].languagecode]
      xmail_body = xmail_body.split("{{user_name}}").join("&lt;" + this.getLabel("LBLUSERNAME") + "&gt;")
      xmail_body = xmail_body.split("{{company_url}}").join(xurl)
      xmail_body = xmail_body.split("{{product_name}}").join("&lt;" + this.getLabel("LBLPRODUCTNAME") + "&gt;")
      xmail_body = xmail_body.split("{{product_unit_name}}").join("&lt;" + this.getLabel("LBLUNITNAME") + "&gt;")
      xmail_body = xmail_body.split("{{capsule_content_name}}").join("&lt;" + this.getLabel("LBLCAPSULENAME") + "&gt;")
      xmail_body = xmail_body.split("{{{assigned_courses}}}").join("&lt;" + this.getLabel("LBLASSIGNEDCOURSE") + "&gt;")
      let xmail_subject = this.state.mail_templates[xname].subject[this.state.units[0].languagecode]
      xmail_subject = xmail_subject.split("{{user_name}}").join("&lt;" + this.getLabel("LBLUSERNAME") + "&gt;")
      xmail_subject = xmail_subject.split("{{company_url}}").join(xurl)
      xmail_subject = xmail_subject.split("{{product_name}}").join("&lt;" + this.getLabel("LBLPRODUCTNAME") + "&gt;")
      xmail_subject = xmail_subject.split("{{product_unit_name}}").join("&lt;" + this.getLabel("LBLUNITNAME") + "&gt;")
      xmail_subject = xmail_subject.split("{{capsule_content_name}}").join("&lt;" + this.getLabel("LBLCAPSULENAME") + "&gt;")
      xmail_subject = xmail_subject.split("{{{assigned_courses}}}").join("&lt;" + this.getLabel("LBLASSIGNEDCOURSE") + "&gt;")
      this.setState({
        mailview: true,
        modal_mail_view_show: true,
        modal_mail_heading: xlabels[xname],
        modal_mail_body: xmail_body,
        modal_mail_subject: xmail_subject
      });
    } catch (e) {
      console.log("getMessage ERROR", e.message)
    }
  }
  closeMailView = () => {
    this.setState({
      mailview: false,
      modal_mail_view_show: false,
    });
  };

  getQRCode = (xurl, mode) => {
    QRCode.toDataURL(xurl, { width: 200 }, (err, url) => {
      if (err) {
        console.log(err)
      }

      if (mode === "encref_pre_un") {
        this.setState({
          encref_pre_un: url
        })
      } else if (mode === "encref_post_un") {
        this.setState({
          encref_post_un: url
        })
      }
      return url
    })
    QRCode.toDataURL(xurl, { width: 450 }, (err, url) => {
      if (err) {
        console.log(err)
      }
      console.log("!!!!!!!!!!!!!!!!!!!!!!!qrcode", url)

      if (mode === "encref_pre_un") {
        this.setState({
          encref_pre_un_download: url
        })
      } else if (mode === "encref_post_un") {
        this.setState({
          encref_post_un_download: url
        })
      }

      return url
    })
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        if (cstLabels.hasOwnProperty(xfield.label)) {
          return cstLabels[xfield.label].replace(
            "$$$",
            xfield.val
          );
        } else {
          return xfield.label;
        }
      }
    }
  };
  componentDidMount() {
    console.log("this.state.Sitetype",this.props.showunits_certificate,this.props)
    // alert("hii",this.props.showunits_certificate)
    //  this.state.Sitetype
    this.getProduct();
    this.setdateformat();
  }
  componentDidUpdate() {
  }
  sortorder = (a, b) => {
    if (a.capsule_order < b.capsule_order) {
      return -1;
    }
    if (a.capsule_order > b.capsule_order) {
      return 1;
    }
    return 0;
  };
  getProduct = () => {
    let data = {};
    let url = "product/" + this.props.initialValues.product_id
    

    HttpInterceptor.get(url, data).then((res) => {
      if (res.data[0]) {
        let options = JSON.parse(res.data[0][0].options.split(":null").join(":\"null\""));
        let notification_settings = {}, crsoption_settings = { fast_forward_enable: true, showunits_certificate: false, sequential_access_unit: false }
         let sequential={ sequential_access_unit:false}
        let mail_templates = {}
        if (res.data[2][0]?.notification_settings) {
          notification_settings = JSON.parse(res.data[2][0].notification_settings);
        }
        if (res.data[2][0]?.crsoption_settings) {
          let crsoption = JSON.parse(res.data[2][0].crsoption_settings);
          console.log("crs option---",crsoption);
          crsoption_settings.fast_forward_enable = crsoption.fast_forward_enable === 1 ? false : true;
          crsoption_settings.showunits_certificate = crsoption.showunits_certificate === 0 ? false : true;
          crsoption_settings.sequential_access_unit = crsoption.sequential_access_unit === 0 ? false : true;
        } 
        if (res.data[0][0]?.sequential_access_unit) {
          let sequentialaccess = JSON.parse(res.data[0][0].sequential_access_unit);
          sequential.sequential_access_unit= sequentialaccess.sequential_access_unit === 0 ? false : true;
        } 
        if (res.data[3]) {
          for (let i = 0; i < res.data[3].length; i++) {
            try {
              res.data[3][i].body = JSON.parse(res.data[3][i].body)
              res.data[3][i].subject = JSON.parse(res.data[3][i].subject)
            } catch (e) {

            }
            if (res.data[3][i].template_type_id === 100) {
              mail_templates["capsule_available"] = res.data[3][i]
            }
            if (res.data[3][i].template_type_id === 110) {
              mail_templates["unit_available"] = res.data[3][i]
            }
            if (res.data[3][i].template_type_id === 112) {
              mail_templates["courses_enrolled"] = res.data[3][i]
            }
            // if (res.data[3][i].template_type_id === 113) {
            //   mail_templates["presurvey_available"] = res.data[3][i]
            // }
            if (res.data[3][i].template_type_id === 114) {
              mail_templates["postsurvey_available"] = res.data[3][i]
            }
          }
        }
        let childNode = null;
        if(res.data[4] && res.data[4][0]){
          childNode = res.data[4][0].childNode
        }
        console.log('childNode', childNode)
        let units = res.data[1]
        let tabCapsuleDrip = 0
        let tabUnitDrip = 1
        let tabNotifications = 1
        let totalCapsuleCount = 0
        let totalLearnByteCount = 0
        let capsule_drip_enabled = res.data[0][0].capsule_drip_enabled
        let arrDripCapsules = []
        let arrDripUnits = []
        let tabSurveyLinks = 0
        let tabsettings = 1
        for (let i = 0; i < units.length; i++) {
          arrDripUnits.push(
            {
              drip_id: units[i].unit_id,
              drip_name: units[i].unit_name,
              drip_type: "UNIT",
              drip_order: i
            }
          )
          units[i].unit_drip = JSON.parse((units[i].unit_drip || "{}").split(":null").join(":\"null\""));
          if (units[i].unit_drip && units[i].unit_drip.and && units[i].unit_drip.and[0].type) {
            let condition = units[i].unit_drip["and"] ? units[i].unit_drip["and"][0].condition : ""
            if (condition.split("DADD").length > 1) {
              units[i].dripDays = parseInt(condition.split(",")[1].split(")")[0].split(" ").join(""))
            } else {
              units[i].dripDays = 0
            }
            units[i].dripContentType = units[i].unit_drip.and[0].type.toUpperCase()
            units[i].dripConditionId = units[i].unit_drip.and[0].id
            if (condition.split("ASSIGNEDDATE").length > 1) {
              units[i].dripConditionType = "ASSIGNEDDATE" + units[i].dripContentType
            } else if (condition.split("COMPLETEDDATE").length > 1) {
              units[i].dripConditionType = "COMPLETEDDATE" + units[i].dripContentType
            } else {
              units[i].dripConditionType = "COMPLETEDDATE" + units[i].dripContentType
            }
          } else {
            if (i === 0) {
              units[i].dripConditionType = "ASSIGNEDDATEUNIT"
              units[i].dripContentType = "UNIT"
              units[i].dripConditionId = units[i].unit_id
              units[i].dripDays = 0
            } else {
              units[i].dripConditionType = "COMPLETEDDATEUNIT"
              units[i].dripContentType = "UNIT"
              units[i].dripConditionId = units[i - 1].unit_id
              units[i].dripDays = 0
            }
          }
          units[i].activities = JSON.parse(units[i].activities.split(":null").join(":\"null\"")).sort(this.sortorder);
          totalCapsuleCount = totalCapsuleCount + units[i].capsule_count
          for (let j = 0; j < units[i].activities.length; j++) {
            if (units[i].activities[j].capsule_content_type === "SURVEY") {
              tabSurveyLinks = 1
            }
            if (units[i].activities[j].capsule_content_type === "LEARNINGOBJECT" || units[i].activities[j].capsule_content_type === "RYS" || units[i].activities[j].capsule_content_type === "SURVEY") {
              arrDripCapsules.push(
                {
                  drip_id: units[i].activities[j].capsule_id,
                  drip_name: units[i].activities[j].capsule_content_name,
                  drip_type: "CAPSULE",
                  drip_order: units[i].activities[j].capsule_order,
                  drip_mapped_id: units[i].unit_id,
                }
              )
              if (units[i].activities[j].capsule_content_type === "LEARNINGOBJECT" || units[i].activities[j].capsule_content_type === "RYS") {
                totalLearnByteCount++
              }

              if (units[i].activities[j].trigger_condition["and"] && units[i].activities[j].trigger_condition["and"][0].type === "capsule") {
                if (units[i].activities[j].trigger_condition["and"][0].hasOwnProperty("active") === true) {
                  if (units[i].activities[j].trigger_condition["and"][0].active === "1") {
                    capsule_drip_enabled = 1
                  }
                } else {
                  capsule_drip_enabled = 1
                }
              }
              let condition = units[i].activities[j].trigger_condition["and"] ? units[i].activities[j].trigger_condition["and"][0].condition : ""
              if (condition.split("DADD").length > 1) {
                units[i].activities[j].dripDays = parseInt(condition.split(",")[1].split(")")[0].split(" ").join(""))
              } else {
                units[i].activities[j].dripDays = 0
              }
              let citem = units[i].activities[j]
              if (units[i].activities[j].trigger_condition && units[i].activities[j].trigger_condition.and && units[i].activities[j].trigger_condition.and[0].type) {
                units[i].activities[j].dripContentType = units[i].activities[j].trigger_condition.and[0].type.toUpperCase()
                units[i].activities[j].dripConditionId = units[i].activities[j].trigger_condition.and[0].id
                if (condition.split("ASSIGNEDDATE").length > 1) {
                  units[i].activities[j].dripConditionType = "ASSIGNEDDATE" + units[i].activities[j].dripContentType
                } else if (condition.split("COMPLETEDDATE").length > 1) {
                  units[i].activities[j].dripConditionType = "COMPLETEDDATE" + units[i].activities[j].dripContentType
                } else {

                }
              }
            }
          }
        }
        if (totalCapsuleCount > 0 && totalLearnByteCount > 0) {
          tabCapsuleDrip = 1
        }
        let toggleunitstate = this.state.toggleunitstate
        try {
          if (toggleunitstate.hasOwnProperty(units[0].unit_id + "-toggle") === false) {
            this.toggletree(units[0].unit_id + "-toggle")
          }
        } catch (e) { }
        this.setState((state, props) => ({
          options: options,
          notification_settings: notification_settings,
          crsoption_settings: crsoption_settings,
          units: units,
          training_options_id: res.data[0][0].training_options_id,
          loading: false,
          firstload: true,
          tabUnitDrip: tabUnitDrip,
          tabsettings: tabsettings,
          tabCapsuleDrip: tabCapsuleDrip,
          tabSurveyLinks: tabSurveyLinks,
          tabNotifications: tabNotifications,
          unit_drip_enabled: res.data[0][0].unit_drip_enabled,
          capsule_drip_enabled: capsule_drip_enabled,
          arrDripCapsules: arrDripCapsules,
          arrDripUnits: arrDripUnits,
          encref_pre_un: "",
          encref_post_un: "",
          mail_templates: mail_templates,
          subShow: false,
          childNode: childNode,
          // sequential_access_unit:res.data[0][0].sequential_access_unit
          sequential:sequential,
          // show_video_seek_bar:res.data[0][0].show_video_seek_bar
          videoseekbar:res.data[0][0].show_video_seek_bar,
          productname:res.data[1][0].product_name,
          Sitetype:localStorage.getItem('site_type')
        }));
      }
    });
    
  };

  // saveUnitDrips = (values) => {
  //   let data = {
  //     units: this.state.units,
  //     product_id: this.props.initialValues.product_id,
  //     training_options_id: this.state.training_options_id,
  //     unit_drip_enabled: this.state.unit_drip_enabled,
  //     capsule_drip_enabled: this.state.capsule_drip_enabled,
  //     sequential_access_unit:this.state.sequential_access_unit

  //   };
  //   let url = "updatetrainingunitdrips"
  //   HttpInterceptor.post(url, data).then((res) => {
  //     if (res) {
  //       this.ShowToast(this.getLabel("LBLUNITDRIPUPDATESUCCESS"));
  //     }
  //   },
  //     (err) => {
  //     }
  //   );
  // };
  saveCapsuleDrips = (values) => {
    let data = {
      units: this.state.units,
      product_id: this.props.initialValues.product_id,
      training_options_id: this.state.training_options_id,
      unit_drip_enabled: this.state.unit_drip_enabled,
      capsule_drip_enabled: this.state.capsule_drip_enabled
    };
    let url = "updatetrainingcapsuledrips"
    HttpInterceptor.post(url, data).then((res) => {
      if (res) {
        this.ShowToast(this.getLabel("LBLCAPSULEDRIPUPDATESUCCESS"));
      }
    },
      (err) => {
      }
    );
  };
  saveUnitDrips = (values) => {
    let data = {
      units: this.state.units,
      product_id: this.props.initialValues.product_id,
      training_options_id: this.state.training_options_id,
      unit_drip_enabled: this.state.unit_drip_enabled,
      capsule_drip_enabled: this.state.capsule_drip_enabled,
      // sequential_access_unit:this.state.sequential_access_unit
      sequential_access_unit:this.state.sequential.sequential_access_unit === true ? 1 : 0
    };
    let url = "updatetrainingunitdrips"
    HttpInterceptor.post(url, data).then((res) => {
      if (res) {
        this.ShowToast(this.getLabel(this.state.childNode && this.state.childNode === 'modules'? "LBLMODDRIPUPDATESUCCESS" : "LBLUNITDRIPUPDATESUCCESS"));
      }
    },
      (err) => {
      }
    );
  };
  saveSettings = (values) => {
    let data = {
      options: this.state.options,
      product_id: this.props.initialValues.product_id,
      training_options_id: this.state.training_options_id
    };
    let url = "updatetrainingoptions"
    HttpInterceptor.post(url, data).then((res) => {
      if (res) {
        this.ShowToast(this.getLabel("LBLUPDTSCCESS"));
      }
    },
      (err) => {
      }
    );
  };
  saveNotifications = (values) => {
    let notification_settings = {
      notify_general: this.state.notification_settings.notify_general,
      notify_unit_available: this.state.notification_settings.notify_unit_available,
      notify_survey_available: this.state.notification_settings.notify_survey_available,
      notify_capsule_available: this.state.notification_settings.notify_capsule_available
    }
    let data = {
      notification_settings: notification_settings,
      product_id: this.props.initialValues.product_id,
    };
    console.log("product_id1",this.props.initialValues.product_id)
    let url = "updatetrainingnotifications"
    HttpInterceptor.post(url, data).then((res) => {
      if (res) {
        this.ShowToast(this.getLabel("LBLUPDTNSETTINGSSCCESS"));
      }
    },
      (err) => {
      }
    );
  };
  saveCourseOptionSettings = (value) => {
    console.log("this.state.crsoption_settings", this.state.crsoption_settings)
    this.setState({
      subShow: true,
    })
    
    let data = {
       crsoption_settings : {
          showunits_certificate: this.state.crsoption_settings.showunits_certificate === true ? 1 : 0, 
          sequential_access_unit: this.state.crsoption_settings.sequential_access_unit === true ? 1 : 0,
          fast_forward_enable: this.state.crsoption_settings.fast_forward_enable === true ? 0 : 1
        }
      };
    console.log("productid",this.props.initialValues.product_id)

    let url = "product/"+this.props.initialValues.product_id +"/option_setting"
    HttpInterceptor.put(url, data).then((res) => {
      if (res) {
        this.ShowToast(this.getLabel("LBLSETTINGSUPDATED"));
      }
    },
      (err) => {
      }
    );
  }

  closeSubModal = () => {
    this.setState({
      subShow: false,
    });
  }
  closeBtnHandler = () => {
    this.setState({
      subShow: false,
    })
  }




  handleDateChange = (date) => {
    let helperDate = "",
      error = false;
    if (date == "Invalid Date" || date == null) {
      helperDate = this.props.labels.labels.LBLVLDDATE;
      error = false;
    } else if (date > new Date()) {
      helperDate = this.props.labels.labels.LBLGRTDATE;
      error = false;
    }
    this.setState({
      selectedDate: date,
      helperDate: helperDate,
      error: error,
    });
  };

  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
  };

  setTab = (x) => {
    this.setState({
      showTab: x,
    });
  };
  getOptionValue = (x) => {
    return this.state.options[x];
  };
  getNotificationValue = (x) => {
    return this.state.notification_settings[x];
  };
  getCrsOptionsValue = (x) => {
    return this.state.crsoption_settings[x];
  };
  getSequentialValue=(x)=>{
    return this.state.sequential[x];
  }
  getCapsuleDripDaysValue = (x, y) => {
    if (this.state.capsule_drip_enabled === 0 || this.state.capsule_drip_enabled === false) {
      return 0
    } else {
      for (let i = 0; i < this.state.units.length; i++) {
        if (this.state.units[i].unit_id === x) {
          for (let j = 0; j < this.state.units[i].activities.length; j++) {
            if (this.state.units[i].activities[j].capsule_content_id === y) {
              if (this.state.units[i].activities[j].dripDays) {
                return this.state.units[i].activities[j].dripDays
              } else {
                return 0
              }
            }
          }
        }
      }
      return 0
    }
  };
  getCapsuleDripCoditionId = (x, y) => {
    for (let i = 0; i < this.state.units.length; i++) {
      if (this.state.units[i].unit_id === x) {
        for (let j = 0; j < this.state.units[i].activities.length; j++) {
          if (this.state.units[i].activities[j].capsule_content_id === y) {
            if (this.state.units[i].activities[j].dripConditionId) {
              return this.state.units[i].activities[j].dripConditionId
            } else {
              return this.state.units[i].activities[j].trigger_condition.and[0].id
            }
          }
        }
      }
    }
    return 0
  };
  getCapsuleDripCoditionType = (x, y) => {
    if (this.state.capsule_drip_enabled === 0 || this.state.capsule_drip_enabled === false) {
      return 0
    } else {
      let presurvey = 0
      let postsurvey = 0
      for (let i = 0; i < this.state.units.length; i++) {
        let item = this.state.units[i]

        if (item.activities && item.activities[0] && item.activities[0].capsule_content_type && item.activities[0].capsule_content_type.toUpperCase() === "SURVEY") {
          console.log("presurvey")
          presurvey = 1
        } else if (item.activities && item.activities[item.activities.length - 1] && item.activities[item.activities.length - 1].capsule_content_type && item.activities[item.activities.length - 1].capsule_content_type.toUpperCase() === "SURVEY") {
          console.log("postsurvey")
          postsurvey = 1
        }
        if (this.state.units[i].unit_id === x) {
          for (let j = 0; j < this.state.units[i].activities.length; j++) {

            if (this.state.units[i].activities[j].capsule_content_id === y) {
              let units = this.state.units
              if (this.state.capsule_drip_enabled === 1
                && units[i].activities[j].trigger_condition["and"][0].hasOwnProperty("active") === true
                && units[i].activities[j].trigger_condition["and"][0].active === "0"
              ) {
                if (presurvey === 1 && i === 0) {
                  if (j === 1) {
                    return "COMPLETEDDATEUNIT"
                  } else if (j > 1) {
                    return "COMPLETEDDATECAPSULE"
                  }
                } else if (presurvey === 1 && i > 0) {
                  if (j === 0) {
                    return "COMPLETEDDATEUNIT"
                  } else if (j > 0) {
                    return "COMPLETEDDATECAPSULE"
                  }
                } else if (presurvey === 0) {
                  if (j === 0) {
                    return "COMPLETEDDATEUNIT"
                  } else if (j > 0) {
                    return "COMPLETEDDATECAPSULE"
                  }
                }
              } else {
                return this.state.units[i].activities[j].dripConditionType
              }

            }
          }
        }
      }
      return 0
    }
    return 0
  };
  getUnitDripDaysValue = (x) => {
    if (this.state.unit_drip_enabled === 0 || this.state.unit_drip_enabled === false) {
      return 0
    } else {
      for (let i = 0; i < this.state.units.length; i++) {
        if (this.state.units[i].unit_id === x) {
          if (this.state.units[i].dripDays) {
            return this.state.units[i].dripDays
          } else {
            return 0
          }
        }
      }
      return 0
    }

  };
  getUnitDripCoditionId = (x) => {
    if (this.state.unit_drip_enabled === 0 || this.state.unit_drip_enabled === false) {
      return 4
    } else {
      for (let i = 0; i < this.state.units.length; i++) {
        if (this.state.units[i].unit_id === x) {
          if (this.state.units[i].hasOwnProperty("dripConditionId")) {
            return this.state.units[i].dripConditionId
          } else {
            return 2
          }
        }
      }
      return 3
    }
  };
  getUnitDripCoditionType = (x, y) => {
    if (this.state.unit_drip_enabled === 0 || this.state.unit_drip_enabled === false) {
      return 0
    } else {
      for (let i = 0; i < this.state.units.length; i++) {
        if (this.state.units[i].unit_id === x) {
          return this.state.units[i].dripConditionType
        }
      }
      return 0
    }
  };
  getCapsuleDripFor = (x, y) => {
    if (this.state.capsule_drip_enabled === 0 || this.state.capsule_drip_enabled === false) {
      return 0
    } else {
      for (let i = 0; i < this.state.units.length; i++) {
        if (this.state.units[i].unit_id === x) {
          return this.state.units[i].dripFor
        }
      }
      return 0
    }
  };

  reOrderUnits = (units) => {
    for (let i = 0; i < units.length; i++) {
      if (units[i].dripConditionId) {

      }
    }
  }
  reOrderCapsules = (capsules) => {
    let xlen = capsules.length
    let setOrder = (xOrder, obj) => {
      capsules.map((item, index) => {
        if (item.capsule_id === obj.capsule_id) {

        }
      })
    }
    capsules.map((item, index) => {
      item.newOrder = capsules.length
    })
    capsules.map((item, index) => {
      if (item.dripConditionId) {
        item.newOrder--
      }
    })
  }
  renderToggleInput = (field) => {
    return (
      <div
        key={field.name}
        className={classList([
          "form-group",
          "row",
          required === true ? "required" : "",
        ])}
        style={{ margin: "0px" }}
      >
        <label className="col-sm-12 control-label">{field.label}</label>
        <div class="col-sm-7">
          <Form.Check
            type="switch"
            id={field.id}
            label=""
            checked={field.g_value}
            onChange={field.onChng}
            icons={false}
          />
        </div>
      </div>
    );
  };
  renderInlineToggleInput = (field) => {
    return (
      <div
        key={field.name}
        className={classList([
          "form-group",
          "row",
          required === true ? "required" : "",
        ])}
        style={{ margin: "0px", paddingTop: "10px" }}
      >
        <label className="col-sm-7 control-label" style={{ fontSize: "14px" }}>{field.label}
          <span style={{ display: "inline-block", marginLeft: "20px", marginTop: "0px" }}>
            <Form.Check
              type="switch"
              id={field.id}
              label=""
              checked={field.g_value}
              onChange={field.onChng}
              icons={false}
            />
          </span>
        </label>

      </div>
    );
  };
  renderField = ({
    input,
    label,
    type,
    g_value,
    className,
    disabled,
    meta: { touched, error, warning },
    required,
    onChng,
    name,
  }) => (
    <div
      key={name}
      className={classList([
        "form-group",
        "row",
        required === true ? "required" : "",
      ])}
      style={{ margin: "0px" }}
    >
      <label className="col-sm-12 control-label">{label}</label>
      <div class="col-sm-7">
        <input
          key={name}
          {...input}
          value={g_value}
          placeholder={label}
          type={type}
          className={className}
          disabled={disabled}
          autoComplete="new-password"
          ref={this[name]}
          onChange={onChng}
        />
        {touched &&
          ((error && (
            <span className="required-field">
              {typeof error === "string"
                ? this.props.labels.labels[error]
                : this.props.labels.labels[error.label].replace(
                  "$$$",
                  error.val
                )}
            </span>
          )) ||
            (warning && <span className="required-field">{warning}</span>))}
      </div>
    </div>
  );
  renderInlineSelectField = ({
    input,
    label,
    name,
    g_value,
    type,
    className,
    meta: { touched, error },
    children,
    onChng,
    g_style
  }) => {
    return (
      <span style={{ paddingLeft: "0px", display: "inline-block" }}>
        <select
          key={name}
          {...input}
          value={g_value}
          placeholder={label}
          type={type}
          className={className}
          ref={this[name]}
          onChange={onChng}
          style={g_style ? g_style : {}}
        >
          {children}
        </select>
        {touched && error && (
          <span className="required-field">
            {this.props.labels.labels.LBLREQUIRED}
          </span>
        )}
      </span>
    );
  };
  renderInlineField = ({
    input,
    label,
    type,
    g_value,
    className,
    disabled,
    meta: { touched, error, warning },
    required,
    onChng,
    name,
    label1,
    label2,
    label3,
    id
  }) => (
    <span style={{ paddingLeft: "0px", width: "65px", display: "inline-block" }}>
      <input
        key={name}
        id={id}
        {...input}
        value={g_value}
        placeholder={label}
        type={type}
        className={classList([
          `input-field ${styles.inputTriningSettings}`,
          "row",
          required === true ? "required" : "",
        ])}
        disabled={disabled}
        autoComplete="new-password"
        ref={this[name]}
        onChange={onChng}
      />
      {touched &&
        ((error && (
          <span className="required-field">
            {typeof error === "string"
              ? this.props.labels.labels[error]
              : this.props.labels.labels[error.label].replace(
                "$$$",
                error.val
              )}
          </span>
        )) ||
          (warning && <span className="required-field">{warning}</span>))}
    </span>
  );
  renderFieldSmall = ({
    label,
    meta: { touched, error, warning },
    required,
    name,
    label1,
    label2,
  }) => (
    <div
      key={name}
      className={classList([
        "form-group",
        "row",
        required === true ? "required" : "",
      ])}
    >
      <label className="col-sm-12 control-label">{label}</label>
      <div className="col-sm-12">
        <span style={{ color: "black" }}>{label1}</span>
        <span style={{ paddingLeft: "20px", width: "65px", display: "inline-block" }}>
          Component1
        </span>
        <span style={{ paddingLeft: "5px", color: "black" }}>{label2}</span>
        <span>
          Component 2
        </span>
      </div>
    </div>
  );

  renderCheckBox = ({ name, label, value, checked, onChng, mode, g_value }) => {
    return (
      <label

        className="radio-lbl bg-opts ssi-svy-opt"
        style={{ fontSize: "13px", minHeight: "32px" }}
      >
        <span className="rys-textTwo" >{this.getLabel(label)}
          {/* - {g_value} */}
        </span>
        <input
          name={name}
          type="checkbox"
          value={value}
          onClick={onChng}
          checked={checked}
        />
        <span
          className="radio-mark2"
          style={{
            borderRadius: "25px",
          }}
        ></span>
      </label>
    );
  };
  renderInlineSelectField = ({
    input,
    label,
    name,
    g_value,
    type,
    id,
    className,
    meta: { touched, error },
    children,
    onChng,
    g_style,
  }) => {
    return (
      <span style={{ paddingLeft: "0px", display: "inline-block" }}>
        <select
          key={name}
          {...input}
          value={g_value}
          placeholder={label}
          type={type}
          className={className}
          ref={this[name]}
          onChange={onChng}
          style={g_style ? g_style : {}}
        >
          {children}
        </select>
        {touched && error && (
          <span className="required-field">
            {this.props.labels.labels.LBLREQUIRED}
          </span>
        )}
      </span>
    );
  };
  toggletree = (xid) => {
    let xtoggleunitstate = this.state.toggleunitstate;
    xtoggleunitstate[xid] = !(xtoggleunitstate[xid] || false);
    this.setState({
      toggleunitstate: xtoggleunitstate,
    });
  };
  setDefaultCapsuleDrip = () => {
    let presurvey = 0
    let postsurvey = 0
    let units = this.state.units
    for (let i = 0; i < units.length; i++) {
      let item = units[i]
      if (item.activities && item.activities[0] && item.activities[0].capsule_content_type && item.activities[0].capsule_content_type.toUpperCase() === "SURVEY") {
        console.log("presurvey")
        presurvey = 1
      } else if (item.activities && item.activities[item.activities.length - 1] && item.activities[item.activities.length - 1].capsule_content_type && item.activities[item.activities.length - 1].capsule_content_type.toUpperCase() === "SURVEY") {
        console.log("postsurvey")
        postsurvey = 1
      }
      for (let j = 0; j < units[i].activities.length; j++) {
        if (units[i].activities[j].trigger_condition && units[i].activities[j].trigger_condition["and"][0].hasOwnProperty("active") === true
          && units[i].activities[j].trigger_condition["and"][0].active === "0"
        ) {
          if (presurvey === 1 && i === 0) {
            if (j === 1) {
              units[i].activities[j].dripContentType = "UNIT"
              units[i].activities[j].dripConditionId = units[i].unit_id
              units[i].activities[j].dripConditionType = "COMPLETEDDATEUNIT"
            } else if (j > 1) {
              units[i].activities[j].dripContentType = "CAPSULE"
              units[i].activities[j].dripConditionId = units[i].activities[j - 1].capsule_id
              units[i].activities[j].dripConditionType = "COMPLETEDDATECAPSULE"
            }
          } else if (presurvey === 1 && i > 0) {
            if (j === 0) {
              units[i].activities[j].dripContentType = "UNIT"
              units[i].activities[j].dripConditionId = units[i].unit_id
              units[i].activities[j].dripConditionType = "COMPLETEDDATEUNIT"
            } else if (j > 0) {
              units[i].activities[j].dripContentType = "CAPSULE"
              units[i].activities[j].dripConditionId = units[i].activities[j - 1].capsule_id
              units[i].activities[j].dripConditionType = "COMPLETEDDATECAPSULE"
            }
          } else if (presurvey === 0) {
            if (j === 0) {
              units[i].activities[j].dripContentType = "UNIT"
              units[i].activities[j].dripConditionId = units[i].unit_id
              units[i].activities[j].dripConditionType = "COMPLETEDDATEUNIT"
            } else if (j > 0) {
              units[i].activities[j].dripContentType = "CAPSULE"
              units[i].activities[j].dripConditionId = units[i].activities[j - 1].capsule_id
              units[i].activities[j].dripConditionType = "COMPLETEDDATECAPSULE"
            }
          }
        }
      }
    }
    this.setState({
      units: units
    })
  }

  closeModal = () => {
    this.setState({
      show: false,
    });
    try {
      $(".modal-backdrop").css({ position: "relative" });
    } catch (e) {
    }

  };
  setInitialValues = (item) => {
    this.setState({
      initialValues: {
        product_id: item.productid,
        name: item.name,
        type: item.type,
        language_id: item.language_id
      },
      
    });
  };
  ShowToast = (x) => {
    let element = document.getElementById('training_Modal');
    element.scrollIntoView(true);
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: x,
        changeInToast: new Date(),
      },
    });
  };

  render() {
    const {
      LABELS
    } = this.props.labels;
    const { initialValues } = this.props
    let cDripindex = -1;
    return (
      <div className="trining_settings">
        <div className="col-sm-12 img-contents" style={{ padding: "0px" }}>
          <img
            src={`${process.env.REACT_APP_RESOURCES_URL
              }${"image/"}${initialValues.courseid}${"/"}${initialValues.is_bg_tumbnail === 1
                ? initialValues.product_imagename.replace(
                  "jpg",
                  "png"
                )
                : initialValues.product_imagename
              }`}
            className={
              initialValues.is_bg_tumbnail === 0 ? "img-contain" : ""
            }
            alt=""
          />
          <div className="pageSubTitle">
            <div>
              {initialValues.name} ({initialValues.product_id})
            </div>
            <div>{initialValues.email}</div>

          </div>
        </div>
        <div
          className="col-sm-12"
          style={{ marginTop: "0px", padding: "0px" }}
        >
          <ul class="nav nav-tabs" >
            <li style={{ marginTop: "20px" }}
              className={classList([
                this.state.showTab === "general" ? "active" : "",
              ])}
            >
              <div onClick={this.setTab.bind(this, "general")}>
                {this.getLabel("LBLGENERAL")}
              </div>
            </li>
            {this.state.Sitetype === 'SafeStart'?<>
            {this.state.tabSurveyLinks === 1 ?
              <li style={{ marginTop: "20px" }}
                className={classList([
                  this.state.showTab === "surveylinks" ? "active" : "",
                ])}
              >
                <div onClick={this.setTab.bind(this, "surveylinks")}>
                  {this.getLabel("LBLSURVEY")}
                </div>
              </li> : ""
            }
            {this.state.tabUnitDrip === 1 ?
              <li style={{ marginTop: "20px" }}
                className={classList([
                  this.state.showTab === "trainingdrip" ? "active" : "",
                ])}
              >
                <div onClick={this.setTab.bind(this, "trainingdrip")}>
                  {this.getLabel(this.state.childNode && this.state.childNode === 'modules' ? 'LBLTRAININGDRIPMOD' : "LBLTRAININGDRIP")}  
                </div>
              </li> : ""
            }
            {this.state.tabCapsuleDrip === 1 ?
              <li style={{ marginTop: "20px" }}
                className={classList([
                  this.state.showTab === "capsuledrip" ? "active" : "",
                ])}
              >
                <div onClick={this.setTab.bind(this, "capsuledrip")}>
                  {this.getLabel("LBLCAPSULEDRIP")}
                </div>
              </li> : ""
            }

            {this.state.tabNotifications === 1 ?
              <li style={{ marginTop: "20px" }}
                className={classList([
                  this.state.showTab === "notifications" ? "active" : "",
                ])}
              >
                <div onClick={this.setTab.bind(this, "notifications")}>
                  {this.getLabel("LBLNOTIFICATIONS")}
                </div>
              </li> : ""
            }
            </>:""}
            {/* {console.log("this.state.units1",this.state.units)} */}

            {(this.state.units?.length > 1 && this.state.Sitetype=== 'Accutrain' && this.state.units[0].engineversion ==='2')||( this.state.Sitetype=== 'SafeStart'&&this.state.units?.length > 1) &&(this.props.showunits_certificate=== 1 && this.state.videoseekbar == 1) ?<>


            {this.state.tabsettings === 1 ?
              <li style={{ marginTop: "20px" }}
                className={classList([  
                  this.state.showTab === "settings" ? "active" : "",
                ])}
              >
                <div onClick={this.setTab.bind(this, "settings")}>
                  {this.getLabel("LBLSETTINGS")}
                </div>
              </li> 
              : ""
            }
          </>:""}
        {/* </>:""} */}
          </ul>
        </div>
        {this.state.loading === false ? <>
          {this.state.showTab === "general" ? <>
            <div>
              <div className="row" style={{ marginLeft: "0px" }}>
                <div className="col-sm-12 ">
                  <div style={{ marginTop: "10px" }}>
                    <div
                      className={`row ${styles.generalCourseNameRow}`}
                    >
                      <label className={`col-sm-3 label ${styles.generalHeaderLbl}`}>
                        {this.getLabel("LBLPRODUCTNAME")}
                      </label>
                      <label
                        class={`col-sm-9 label ${styles.generalHeaderLbl}`}
                      >
                        {initialValues.name}
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="row">
                      <div className={`col-sm-3 ${styles.courseNameCol}`}>
                        <label className={styles.unitsLbl} >
                          {this.getLabel(this.state.Sitetype === 'SafeStart' ? (this.state.childNode && this.state.childNode === 'modules' ? "LBLMODULES" : "LBLUNITS"):"LBLMODULES")}
                        </label>

                      </div>
                      <div
                        class="col-sm-9"
                        style={{ padding: "0px 6px 6px 6px" }}
                      >
                        {console.log("this.state.units",this.state.units)}
                        
                        {this.state.units.length > 1 ?<>
                        {this.state.units.map((item, index) => {
                          return (
                            <>
                              <div className={styles.unitNameCol}>
                                {console.log("item.unit_name",item.unit_name)}
                                {index + 1}. {item.unit_name}
                              </div>
                              {item.description && item.description !==item.unit_name ?
                              <div className={styles.generalDescription}>
                                {item.description}
                              </div>
                       :""}                                                                                                                                                                                                                                          
                            </>
                          )
                        })
                        }
                      </>: this.state.productname}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </> : <></>
          }

         {this.state.showTab === "trainingoptions" ? <>
            <div>
              <div>
                <div className="row">
                  <div className="col-sm-12 ">
                    <div  >
                    </div>
                    <div  >
                      <Field
                        name="image_loading_time"
                        type="text"
                        g_value={this.getOptionValue("image_loading_time")}
                        component={this.renderField}
                        label={this.getLabel("LBLIMAGE_LOADING_TIME")}
                        required={true}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required, number]}
                        onChng={(e) => {
                          if (re.test(e.target.value) === true || e.target.value === "") {
                            e.preventDefault();
                            let value = e.target.value;
                            let options = this.state.options
                            options.image_loading_time = value === "" ? 0 : parseInt(value)
                            this.setState({
                              options,
                            });
                          }
                        }}
                      />
                    </div>
                    <div  >
                      <Field
                        name="auto_advance_board"
                        g_value={this.getOptionValue("auto_advance_board")}
                        component={this.renderToggleInput}
                        id="auto_advance_board"
                        label={this.getLabel("LBLAUTO_ADVANCE_BOARD")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.auto_advance_board = !options.auto_advance_board
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div  >
                      <Field
                        name="auto_advance_question_board"
                        type="text"
                        g_value={this.getOptionValue("auto_advance_question_board")}
                        component={this.renderField}
                        label={this.getLabel("LBLAUTO_ADVANCE_QUESTION_BOARD")}
                        required={true}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required, number]}
                        onChng={(e) => {
                          if (re.test(e.target.value) === true || e.target.value === "") {
                            e.preventDefault();
                            let value = e.target.value;
                            let options = this.state.options
                            options.auto_advance_question_board = value === "" ? 0 : parseInt(value)
                            this.setState({
                              options,
                            });
                          }
                        }}
                      />
                    </div>
                    <div  >
                      <Field
                        name="auto_advance_card_board "
                        type="text"
                        g_value={this.getOptionValue("auto_advance_card_board")}
                        component={this.renderField}
                        label={this.getLabel("LBLAUTO_ADVANCE_CARD_BOARD")}
                        required={true}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required, number]}
                        onChng={(e) => {
                          if (re.test(e.target.value) === true || e.target.value === "") {
                            e.preventDefault();
                            let value = e.target.value;
                            let options = this.state.options
                            options.auto_advance_card_board = value === "" ? 0 : parseInt(value)
                            this.setState({
                              options,
                            });
                          }
                        }}
                      />
                    </div>
                    <div  >
                      <Field
                        name="idle_timeout_in_minutes"
                        type="text"
                        g_value={this.getOptionValue("idle_timeout_in_minutes")}
                        component={this.renderField}
                        label={this.getLabel("LBLIDLE_TIMEOUT_IN_MINUTES")}
                        required={true}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required, number]}
                        onChng={(e) => {
                          if (re.test(e.target.value) === true || e.target.value === "") {
                            e.preventDefault();
                            let value = e.target.value;
                            let options = this.state.options
                            options.idle_timeout_in_minutes = value === "" ? 0 : parseInt(value)
                            this.setState({
                              options,
                            });
                          }
                        }}
                      />
                    </div>
                    <div  >
                      <Field
                        name="warning_timeout_in_minutes"
                        type="text"
                        g_value={this.getOptionValue("warning_timeout_in_minutes")}
                        component={this.renderField}
                        label={this.getLabel("LBLWARNING_TIMEOUT_IN_MINUTES")}
                        required={true}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required, number]}
                        onChng={(e) => {
                          if (re.test(e.target.value) === true || e.target.value === "") {
                            e.preventDefault();
                            let value = e.target.value;
                            let options = this.state.options
                            options.warning_timeout_in_minutes = value === "" ? 0 : parseInt(value)
                            this.setState({
                              options,
                            });
                          }
                        }}
                      />
                    </div>
                    <div  >
                      <Field
                        name="hide_idle_timeout"
                        g_value={this.getOptionValue("hide_idle_timeout")}
                        component={this.renderToggleInput}
                        id="hide_idle_timeout"
                        label={this.getLabel("LBLHIDE_IDLE_TIMEOUT")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.hide_idle_timeout = !options.hide_idle_timeout
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div  >
                      <Field
                        name="sequential_access_all"
                        g_value={this.getOptionValue("sequential_access_all")}
                        component={this.renderToggleInput}
                        id="sequential_access_all"
                        label={this.getLabel("LBLSEQUENTIAL_ACCESS_ALL")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.sequential_access_all = !options.sequential_access_all
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div>
                      <Field
                        name="sequential_access_unit"
                        g_value={this.getOptionValue("sequential_access_unit")}
                        component={this.renderToggleInput}
                        id="sequential_access_unit"
                        label={this.getLabel("LBLSEQUENTIAL_ACCESS_UNIT")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.sequential_access_unit = !options.sequential_access_unit
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div  >
                      <Field
                        name="sequential_access_module"
                        g_value={this.getOptionValue("sequential_access_module")}
                        component={this.renderToggleInput}
                        id="sequential_access_module"
                        label={this.getLabel("LBLSEQUENTIAL_ACCESS_MODULE")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.sequential_access_module = !options.sequential_access_module
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div  >
                      <Field
                        name="sequential_access_board"
                        g_value={this.getOptionValue("sequential_access_board")}
                        component={this.renderToggleInput}
                        id="sequential_access_board"
                        label={this.getLabel("LBLSEQUENTIAL_ACCESS_BOARD")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.sequential_access_board = !options.sequential_access_board
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div  >
                      <Field
                        name="quiz_randomization"
                        g_value={this.getOptionValue("quiz_randomization")}
                        component={this.renderToggleInput}
                        id="quiz_randomization"
                        label={this.getLabel("LBLQUIZ_RANDOMIZATION")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.quiz_randomization = !options.quiz_randomization
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div  >
                      <Field
                        name="quiz_pass_percentage"
                        type="text"
                        g_value={this.getOptionValue("quiz_pass_percentage")}
                        component={this.renderField}
                        label={this.getLabel("LBLQUIZ_PASS_PERCENTAGE")}
                        required={true}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required, number]}
                        onChng={(e) => {
                          if (re.test(e.target.value) === true || e.target.value === "") {
                            e.preventDefault();
                            let value = e.target.value;
                            let options = this.state.options
                            options.quiz_pass_percentage = value === "" ? 0 : parseInt(value)
                            this.setState({
                              options,
                            });
                          }
                        }}
                      />
                    </div>

                    <div  >
                      <Field
                        name="hide_board_number"
                        g_value={this.getOptionValue("hide_board_number")}
                        component={this.renderToggleInput}
                        id="hide_board_number"
                        label={this.getLabel("LBLHIDE_BOARD_NUMBER")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.hide_board_number = !options.hide_board_number
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div>
                      <Field
                        name="hide_right_answer"
                        g_value={this.getOptionValue("hide_right_answer")}
                        component={this.renderToggleInput}
                        id="hide_right_answer"
                        label={this.getLabel("LBLHIDE_RIGHT_ANSWER")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.hide_right_answer = !options.hide_right_answer
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div  >
                      <Field
                        name="hide_incorrect_feedback"
                        g_value={this.getOptionValue("hide_incorrect_feedback")}
                        component={this.renderToggleInput}
                        id="hide_incorrect_feedback"
                        label={this.getLabel("LBLHIDE_INCORRECT_FEEDBACK")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.hide_incorrect_feedback = !options.hide_incorrect_feedback
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                    <div  >
                      <Field
                        name="force_user_for_right_answer"
                        g_value={this.getOptionValue("force_user_for_right_answer")}
                        component={this.renderToggleInput}
                        id="force_user_for_right_answer"
                        label={this.getLabel("LBLFORCE_USER_FOR_RIGHT_ANSWER")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let options = this.state.options
                          options.force_user_for_right_answer = !options.force_user_for_right_answer
                          this.setState({
                            options,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <button
                      type="button"
                      className="button-style primary-btn"
                      onClick={this.saveSettings}
                      style={{ float: "right" }}
                    >
                      {this.getLabel("LBLSUBMIT")}
                    </button>
                    <button
                      type="button"
                      className="button-style secondary-btn"
                      id="btnaddCancel"
                      onClick={this.props.closeModal}
                      style={{ float: "right", marginRight: "10px" }}
                    >
                      {this.getLabel("LBLCANCEL")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </> : <></>
          }
          {this.state.showTab === "surveylinks" ? <>
            <div>
              <div className="row">
                <div className="col-sm-12" style={{ marginBottom: "10px", marginTop: "-8px" }}>
                  {this.state.units.map((item, index) => {
                    if (item.capsule_count > 0) {
                      return (
                        <>
                          {item.activities.map((citem, cindex) => {
                            cDripindex++
                            let xlabels = ""
                            let dripDurationType = "DAYS"
                            let dripContentType = citem.dripContentType
                            let dripConditionId = citem.dripConditionId
                            let dripConditionType = citem.dripConditionType
                            let xurl = String(window.location.href).split("schome")[0] + "safestart-survey-un#";
                            xlabels = this.getLabel("LBLDRIP" + dripDurationType + dripConditionType)
                            let xrrlabels = xlabels.split("$$$1").join("@@@1$$$1@@@1").split("$$$2").join("@@@1$$$2@@@1").split("$$$3").join("@@@1$$$3@@@1").split("$br$").join("@@@1$br$@@@1")
                            let zrrlabels = xrrlabels.split("@@@1")
                            let xloop = [0]
                            if (citem.capsule_content_type.toUpperCase() === "SURVEY") {
                              return (
                                <>
                                  <div className="">
                                    <div
                                      className={classList([
                                        "form-group",
                                        "row",
                                        required === true ? "required" : "",
                                      ])}
                                      style={{ margin: "0px" }}
                                    >
                                      <fieldset className={styles.postSurveyFieldSet}>
                                        <div className="col-sm-12" >
                                          {xloop.map((strText, zcindex) => {
                                            if (citem.capsule_content_type.toUpperCase() === "SURVEY" && citem.trigger_condition.and[0].when && citem.trigger_condition.and[0].when == "pre") {
                                              if (this.state.encref_pre_un === "") {
                                                this.getQRCode(xurl + citem.encref_pre_un, "encref_pre_un")
                                              }
                                              return (
                                                <>
                                                  <span className={styles.postSurvey}>
                                                    {this.getLabel("LBLPRESURVEY")}
                                                  </span>
                                                  <div className="col-sm-12" style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px" }}>
                                                    <label>{this.getLabel("LBLLINKUSERNAME")}</label>
                                                    <div className={`col-sm-12 ${styles.surveyLink}`}>{xurl + citem.encref_pre_un}</div>
                                                    <div className="col-sm-12" style={{ borderRadius: "6px", textAlign: "center", paddingLeft: "0px", paddingRight: "0px" }}>
                                                      <img src={this.state.encref_pre_un} /><br />
                                                      <a href={this.state.encref_pre_un_download} download={this.getLabel("LBLPRESURVEY").split(" ").join("_") + ".png"}>{this.getLabel("LBLDOWNLOAD")}</a>
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            } else if (citem.capsule_content_type.toUpperCase() === "SURVEY" && ((citem.trigger_condition.and[0].when && citem.trigger_condition.and[0].when != "pre") || citem.trigger_condition.and[0].hasOwnProperty("when") === false)) {
                                              if (this.state.encref_post_un === "") {
                                                this.getQRCode(xurl + citem.encref_post_un, "encref_post_un")
                                              }
                                              return (
                                                <>
                                                  <span className={styles.postSurvey}>
                                                    {this.getLabel("LBLPOSTSURVEY")}
                                                  </span>
                                                  <div className="col-sm-12" style={{ paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px" }}>
                                                    <label>{this.getLabel("LBLLINKUSERNAME")}</label>
                                                    <div className={`col-sm-12 ${styles.surveyLink}`}>{xurl + citem.encref_post_un}</div>
                                                    <div className="col-sm-12" style={{ borderRadius: "6px", textAlign: "center", paddingLeft: "0px", paddingRight: "0px" }}>
                                                      <img src={this.state.encref_post_un} /><br />
                                                      <a href={this.state.encref_post_un_download} download={this.getLabel("LBLPOSTSURVEY").split(" ").join("_") + ".png"}>{this.getLabel("LBLDOWNLOAD")}</a>
                                                    </div>
                                                  </div>
                                                </>
                                              )
                                            }
                                          })}
                                        </div>

                                      </fieldset>
                                    </div>
                                  </div>
                                </>

                              )
                            }
                          })
                          }
                        </>
                      )
                    } else {
                      return (
                        ""
                      )
                    }

                  })}
                </div>
                <div className="col-sm-12" style={{ marginTop: "12px" }}>
                  <button
                    type="button"
                    className="button-style primary-btn"
                    onClick={this.saveCapsuleDrips}
                    style={{ float: "right" }}
                  >
                    {this.getLabel("LBLSUBMIT")}
                  </button>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.props.closeModal}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {this.getLabel("LBLCANCEL")}
                  </button>
                </div>
              </div>
            </div>
          </> : <></>
          }
          {this.state.showTab === "trainingdrip" ? <>
            <div>
              <div className="row">
                <div className="col-sm-12 ">
                  <div  >
                    <Field
                      name="unit_drip_enabled"
                      g_value={this.state.unit_drip_enabled}
                      component={this.renderInlineToggleInput}
                      id="unit_drip_enabled"
                      label={this.getLabel(this.state.childNode === 'modules' ? "LBLUNITDRIPENABLEDMOD" : "LBLUNITDRIPENABLED")}
                      className={`input-field ${styles.inputTriningSettings}`}
                      validate={[required]}
                      onChng={(e) => {
                        this.setState({
                          unit_drip_enabled: !this.state.unit_drip_enabled,
                        });
                        console.log(e.target.value);
                      }}
                    ></Field>

                  </div>
                  <div  >
                    <div className={styles.timedReleaseCtn}>
                      <label className={`label ${styles.timedReleaseLbl}`}>{this.getLabel("LBLDRIPBYDAY")}</label>
                      <label className={`label ${styles.timedReleaseLbl}`}>
                        {this.getLabel("LBLDRIPBYDAYDESCP")}
                      </label>
                    </div>
                  </div>
                  {(this.state.unit_drip_enabled === 1 || this.state.unit_drip_enabled === true) && this.state.units.map((item, index) => {
                    cDripindex++
                    let xlabels = ""
                    let dripDurationType = "DAYS"
                    let dripContentType = item.dripContentType
                    let dripConditionId = item.dripConditionId
                    let dripConditionType = item.dripConditionType

                    xlabels = this.getLabel("LBLDRIP" + dripDurationType + dripConditionType)
                    let xrrlabels = xlabels.split("$$$1").join("@@@1$$$1@@@1").split("$$$2").join("@@@1$$$2@@@1").split("$$$3").join("@@@1$$$3@@@1").split("$br$").join("@@@1$br$@@@1")
                    let zrrlabels = xrrlabels.split("@@@1")
                    let renderUnitDripTypeSelection = ({ name, onChng, g_value, mode }) => {
                      if (index === 0) {
                        return (<>
                          {this.renderCheckBox({
                            label: "LBLONCOURSEENROLLMENT",
                            value: 'ASSIGNEDDATEUNIT',
                            checked: g_value === 'ASSIGNEDDATEUNIT' ? true : false,
                            onChng: onChng,
                            mode: mode,
                            name: name
                          })}
                        </>)
                      } else {
                        return (<>
                          {this.renderCheckBox({
                            label: "LBLONUNITCOMPLETE",
                            value: 'COMPLETEDDATEUNIT',
                            checked: g_value === 'COMPLETEDDATEUNIT' ? true : false,
                            onChng: onChng,
                            mode: mode,
                            name: name
                          })}
                        </>)
                      }
                    }
                    let component1 = () => {
                      return (
                        <>
                          <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                            {item.unit_name}
                          </span>
                        </>
                      )
                    }
                    let component2 = () => {
                      return (
                        <>
                          <span style={{ display: "inline-block", paddingLeft: "20px" }}>
                            <Field
                              name={item.unit_id}
                              id={item.unit_id}
                              type="text"
                              g_value={this.getUnitDripDaysValue(item.unit_id)}
                              component={this.renderInlineField}
                              label={item.unit_name}
                              required={false}
                              className={`input-field ${styles.inputTriningSettings}`}
                              validate={[number]}
                              onChng={(e) => {
                                e.preventDefault();
                                if (re.test(e.target.value) === true || e.target.value === "") {
                                  if (e.target.value < 366) {
                                    if (this.state.unit_drip_enabled === 1 || this.state.unit_drip_enabled === true) {
                                      let value = e.target.value;
                                      let units = this.state.units
                                      for (let i = 0; i < units.length; i++) {
                                        if (units[i].unit_id === e.target.id) {
                                          units[i].dripDays = value === "" ? 0 : parseInt(value)
                                        }
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }
                                  }
                                }
                              }}
                            />
                          </span>
                        </>
                      )
                    }
                    let gvalue = this.getUnitDripCoditionId(item.unit_id)
                    let component3 = () => {
                      if (dripConditionType.indexOf("COMPLETEDDATEUNIT") > -1) {
                        let getCom3Items = () => {
                          let rsItems = []
                          let xrrItems = this.state.arrDripCapsules
                          if (dripConditionType.indexOf("UNIT") > -1) {
                            xrrItems = this.state.arrDripUnits
                          }
                          xrrItems.map((ditem, iindex) => {
                            if (index > 0 && iindex === index - 1) {
                              rsItems.push(ditem)
                            }
                          })
                          if (rsItems.length === 1) {
                            if (gvalue !== rsItems[0].drip_id) {
                              let units = this.state.units
                              let x = item.unit_id
                              for (let i = 0; i < units.length; i++) {
                                if (units[i].unit_id === x) {
                                  units[i].dripConditionId = rsItems[0].drip_id
                                }
                              }
                              if (this.state.unit_drip_enabled === 1 || this.state.unit_drip_enabled === true) {
                                this.setState({
                                  units,
                                });
                              }
                            }
                          }
                          return rsItems
                        }

                        let arrItems = getCom3Items()
                        if (arrItems.length > 1) {
                          return (
                            <>
                              <span>
                                <Field
                                  name={item.unit_id + "~DRIPCONTENT"}
                                  id={item.unit_id + "~DRIPCONTENT"}
                                  component={this.renderInlineSelectField}
                                  type="checkbox"
                                  g_value={this.getUnitDripCoditionId(item.unit_id)}
                                  className={`input-field ${styles.inputTriningSettings}`}
                                  validate={[]}
                                  onChng={(e) => {
                                    e.preventDefault();
                                    if (this.state.capsule_drip_enabled === 1 || this.state.capsule_drip_enabled === true) {
                                      let value = e.target.value;
                                      let units = this.state.units
                                      let x = e.target.name.split("~")[0]
                                      for (let i = 0; i < units.length; i++) {
                                        if (units[i].unit_id === x) {
                                          units[i].dripConditionId = value
                                        }
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }
                                  }}
                                  g_style={{ fontStyle: "italic", fontWeight: "bold", color: "#031b4e" }}
                                >
                                  {arrItems.map((ditem, iindex) => {
                                    if (ditem.drip_id !== item.unit_id) {
                                      return (
                                        <option
                                          key={ditem.drip_id}
                                          value={ditem.drip_id}
                                        >
                                          {ditem.drip_name}
                                        </option>
                                      );
                                    } else {
                                      return (<></>)
                                    }
                                  })}

                                </Field>
                              </span>
                            </>
                          )
                        } else if (arrItems.length === 1) {
                          return (
                            <span className={styles.unitName}>
                              {arrItems[0].drip_name}
                            </span>
                          )
                        }
                      } else {
                        let units = this.state.units
                        if (gvalue !== units[0].unit_id) {
                          let x = item.unit_id
                          for (let i = 0; i < units.length; i++) {
                            if (units[i].unit_id === x) {
                              units[i].dripConditionId = units[0].unit_id
                            }
                          }
                          if (this.state.unit_drip_enabled === 1 || this.state.unit_drip_enabled === true) {
                            this.setState({
                              units,
                            });
                          }
                        }

                        return (
                          <span className={styles.unitName}>
                            {item.unit_name}
                          </span>
                        )
                      }

                    }
                    return (
                      <div  >
                        <>                     
                         <div
                            className={classList([
                              "form-group",
                              "row",
                              required === true ? "required" : "",
                            ])}
                            style={{ margin: "0px", flexDirection: "column" }}
                        >
                              <fieldset className={styles.postSurveyFieldSet} style={{ marginTop: "0px" }}>                  
                              {
                                this.state.unit_drip_enabled === 1 || this.state.unit_drip_enabled === true ? <>
                                  <div style={{ paddingBottom: "4px" }}></div>
                                  <div className="row" style={{ display: "none", border: "0px solid red", margin: "0px" }}>
                                    <div className="col-sm-12">
                                      <span style={{ float: "left", margin: "0px -23px 0px 0px" }}>
                                        <span className={`label ${styles.timedReleaseLbl}`}>{this.getLabel("LBLDRIPTYPE")}</span>
                                        <span>
                                          {renderUnitDripTypeSelection({
                                            className: `input-field ${styles.inputTriningSettings}`,
                                            mode: "single",
                                            g_value: this.getUnitDripCoditionType(item.unit_id),
                                            name: item.unit_id + "~DRIPCONDITIONTYPE",
                                            onChng: (e) => {
                                              if (e.target.checked === true) {
                                                if (this.state.unit_drip_enabled === 1 || this.state.unit_drip_enabled === true) {
                                                  let value = e.target.value;
                                                  let units = this.state.units
                                                  let x = e.target.name.split("~")[0]
                                                  for (let i = 0; i < units.length; i++) {
                                                    console.log("===", units[i].unit_id, x)
                                                    if (units[i].unit_id === x) {
                                                      units[i].dripConditionType = value
                                                    }
                                                  }
                                                  this.setState({
                                                    units,
                                                  });
                                                }
                                              }
                                            }
                                          }
                                          )}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-sm-12" style={{ lineHeight: "30px", fontSize: "14px" }}>
                                    {zrrlabels.map((strText, cindex) => {
                                      if (strText === "$$$1") {
                                        return (component1())
                                      } else if (strText === "$$$2") {
                                        return (component2())
                                      } else if (strText === "$$$3") {
                                        return (component3())
                                      } else if (strText === "$br$") {
                                        return (<br />)
                                      } else {
                                        return strText
                                      }
                                    })}
                                  </div>
                                </> : <></>
                              }

                            </fieldset>
                          </div>
                        </>
                      </div>
                    )

                  })}
                   {this.state.unit_drip_enabled === 0 || this.state.unit_drip_enabled === false?
                <div >
                    <Field
                      name="sequential_access_unit"
                      g_value={this.getSequentialValue("sequential_access_unit")}
                      component={this.renderInlineToggleInput}
                      id="sequential_access_unit"
                      label={this.getLabel((this.state.childNode && this.state.childNode === 'modules' ? "LBLMODULESEQUENTIALACCESS" : "LBLSEQUENTIALACCESS"))}
                      className={`input-field ${styles.inputTriningSettings}`}
                      validate={[required]}
                      onChng={(e) => {
                        let sequential=this.state.sequential;
                        sequential.sequential_access_unit= !sequential.sequential_access_unit;
                       this.setState({
                          sequential
                        });
                        // console.log(e.target.value, "crsoption_settings123")
                        console.log("sequential_access_unit", this.state.sequential)
                      }}
                    ></Field>
                    <div style={{ padding: "8px 16px 0px 16px" }}>
                      <label className={`label ${styles.timedReleaseLbl}`}>
                        {this.getLabel(this.state.childNode && this.state.childNode === 'modules' ? "LBLENABLEDISABLEMODULESEQUENTIAL" : "LBLENABLEDISABLEUNITSEQUENTIAL")}
                      </label>
                    </div>
                  </div>
             :"" }
                </div>
               
                <div className="col-sm-12" style={{ paddingTop: "20px" }}>
                  <button
                    type="button"
                    className="button-style primary-btn"
                    onClick={this.saveUnitDrips}
                    style={{ float: "right" }}
                  >
                    {this.getLabel("LBLSUBMIT")}
                  </button>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.props.closeModal}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {this.getLabel("LBLCANCEL")}
                  </button>
                </div>
              </div>
            </div>
          </> : <></>
          }
          {this.state.showTab === "capsuledrip" ? <>
            <div>
              <div className="row">
                <div className="col-sm-12 ">
                  <div  >
                    <Field
                      name="capsule_drip_enabled"
                      g_value={this.state.capsule_drip_enabled}
                      component={this.renderInlineToggleInput}
                      id="capsule_drip_enabled"
                      label={this.getLabel("LBLCAPSULEDRIPENABLED")}
                      className={`input-field ${styles.inputTriningSettings}`}
                      validate={[required]}
                      onChng={(e) => {

                        let capsule_drip_enabled = this.state.capsule_drip_enabled === 1 ? 0 : 1
                        this.setState({
                          capsule_drip_enabled: this.state.capsule_drip_enabled === 1 ? 0 : 1,
                        });
                        if (capsule_drip_enabled === 1) {
                          this.setDefaultCapsuleDrip()
                        }
                      }}
                    ></Field>
                  </div>
                  {this.state.units.map((item, index) => {
                    let xcapsule_count = item.capsule_count
                    let presurvey = 0
                    if (item.activities && item.activities[0] && item.activities[0].capsule_content_type && item.activities[0].capsule_content_type.toUpperCase() === "SURVEY") {
                      xcapsule_count--
                      presurvey = 1
                    } else if (item.activities && item.activities[item.activities.length - 1] && item.activities[item.activities.length - 1].capsule_content_type && item.activities[item.activities.length - 1].capsule_content_type.toUpperCase() === "SURVEY") {
                      xcapsule_count--
                    }
                    if (xcapsule_count > 0) {

                      return (
                        <>
                          <div  >
                            <div className="form-group row" style={{ margin: "0px" }}>
                              <div className={`col-sm-12 ${styles.capsulesheader}`}
                                class={
                                  this.state.toggleunitstate[
                                    item.unit_id + "-toggle"
                                  ]
                                    ? `col-sm-12 ${styles.capsulesheaderminus}`
                                    : `col-sm-12 ${styles.capsulesheader}`
                                }
                                onClick={this.toggletree.bind(
                                  this,
                                  item.unit_id + "-toggle"
                                )}
                              >
                                <label style={{ color: "white", cursor: "pointer" }}>{item.unit_name}
                                </label>
                                <span style={{ color: "white" }}>
                                  <i
                                    id={item.unit_id}

                                    style={{ float: "right", marginTop: "3px" }}
                                    class={
                                      this.state.toggleunitstate[
                                        item.unit_id + "-toggle"
                                      ]
                                        ? "fa fa-minus"
                                        : "fa fa-plus"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div>
                              <br />
                            </div>
                          </div>
                          {item.activities.map((citem, cindex) => {
                            cDripindex++
                            if ((cindex === 0 && citem.capsule_content_type && citem.capsule_content_type.toUpperCase() === "SURVEY") ||
                              (cindex === (item.activities.length - 1) && citem.capsule_content_type && citem.capsule_content_type.toUpperCase() === "SURVEY")
                            ) {
                              return <></>
                            }
                            let xlabels = ""
                            let dripDurationType = "DAYS"
                            let dripContentType = citem.dripContentType
                            let dripConditionId = citem.dripConditionId
                            let dripConditionType = citem.dripConditionType
                            xlabels = "LBLDRIP" + dripDurationType + dripConditionType
                            let ylabel = xlabels
                            xlabels = this.getLabel(xlabels)
                            let xrrlabels = xlabels.split("$$$1").join("@@@1$$$1@@@1").split("$$$2").join("@@@1$$$2@@@1").split("$$$3").join("@@@1$$$3@@@1").split("$br$").join("@@@1$br$@@@1")
                            let zrrlabels = xrrlabels.split("@@@1")
                            let renderCapsuleDripTypeSelection = ({ name, onChng, g_value, mode }) => {

                              if (item.capsule_count > 1) {
                                if (cindex === 0) {
                                  if (index === 0 && citem.capsule_content_type.toUpperCase() === "SURVEY") {
                                    return (<>
                                      {this.renderCheckBox({
                                        label: "LBLONCOURSEENROLLMENT",
                                        value: 'ASSIGNEDDATEUNIT',
                                        checked: g_value === 'ASSIGNEDDATEUNIT' || g_value === 'ASSIGNEDDATEPRODUCT' ? true : false,
                                        onChng: onChng,
                                        mode: mode,
                                        name: name,
                                        g_value: g_value
                                      })}
                                    </>)
                                  } else {
                                    return (<>
                                      {this.renderCheckBox({
                                        label: "LBLONUNITCOMPLETE",
                                        value: 'COMPLETEDDATEUNIT',
                                        checked: g_value === 'COMPLETEDDATEUNIT' ? true : false,
                                        onChng: onChng,
                                        mode: mode,
                                        name: name,
                                        g_value: g_value
                                      })}
                                    </>)
                                  }
                                } else {
                                  if (cindex === 1 && presurvey === 1) {
                                    return (<>
                                      {this.renderCheckBox({
                                        label: "LBLONUNITCOMPLETE",
                                        value: 'COMPLETEDDATEUNIT',
                                        checked: g_value === 'COMPLETEDDATEUNIT' ? true : false,
                                        onChng: onChng,
                                        mode: mode,
                                        name: name,
                                        g_value: g_value
                                      })}
                                    </>)
                                  } else {
                                    return (<>
                                      {this.renderCheckBox({
                                        label: "LBLONUNITCOMPLETE",
                                        value: 'COMPLETEDDATEUNIT',
                                        checked: g_value === 'COMPLETEDDATEUNIT' ? true : false,
                                        onChng: onChng,
                                        mode: mode,
                                        name: name,
                                        g_value: g_value
                                      })}
                                      {this.renderCheckBox({
                                        label: "LBLONCAPSULECOMPLETE",
                                        value: 'COMPLETEDDATECAPSULE',
                                        checked: g_value === 'COMPLETEDDATECAPSULE' ? true : false,
                                        onChng: onChng,
                                        mode: mode,
                                        name: name,
                                        g_value: g_value
                                      })}
                                    </>)
                                  }
                                }
                              } else {
                                if (cindex === 0) {
                                  if (index === 0 && citem.capsule_content_type.toUpperCase() === "SURVEY") {
                                    return (<>
                                      {this.renderCheckBox({
                                        label: "LBLONCOURSEENROLLMENT",
                                        value: 'ASSIGNEDDATEUNIT',
                                        checked: g_value === 'ASSIGNEDDATEUNIT' || g_value === 'ASSIGNEDDATEPRODUCT' ? true : false,
                                        onChng: onChng,
                                        mode: mode,
                                        name: name,
                                        g_value: g_value
                                      })}
                                    </>)
                                  } else {
                                    return (<>
                                      {this.renderCheckBox({
                                        label: "LBLONUNITCOMPLETE",
                                        value: 'COMPLETEDDATEUNIT',
                                        checked: g_value === 'COMPLETEDDATEUNIT' ? true : false,
                                        onChng: onChng,
                                        mode: mode,
                                        name: name,
                                        g_value: g_value
                                      })}
                                    </>)
                                  }
                                } else {
                                  return (<>
                                    {this.renderCheckBox({
                                      label: "LBLONUNITCOMPLETE",
                                      value: 'COMPLETEDDATEUNIT',
                                      checked: g_value === 'COMPLETEDDATEUNIT' ? true : false,
                                      onChng: onChng,
                                      mode: mode,
                                      name: name,
                                      g_value: g_value
                                    })}
                                  </>)
                                }
                              }
                            }
                            let component1 = () => {
                              return (
                                <>
                                  <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                                    {citem.capsule_content_name}
                                  </span>
                                </>
                              )
                            }
                            let component2 = () => {
                              return (
                                <>
                                  <span style={{ paddingLeft: "20px", width: "74px", display: "inline-block" }}>
                                    <Field
                                      name={item.unit_id + "~" + citem.capsule_content_id}
                                      id={item.unit_id + "~" + citem.capsule_content_id}
                                      type="text"
                                      g_value={this.getCapsuleDripDaysValue(item.unit_id, citem.capsule_content_id)}
                                      component={this.renderInlineField}
                                      required={false}
                                      className={`input-field ${styles.inputTriningSettings}`}
                                      validate={[number]}
                                      onChng={(e) => {
                                        e.preventDefault();
                                        if (re.test(e.target.value) === true || e.target.value === "") {
                                          if (e.target.value < 366) {
                                            if (this.state.capsule_drip_enabled === 1 || this.state.capsule_drip_enabled === true) {
                                              let value = e.target.value;
                                              let units = this.state.units
                                              let x = e.target.id.split("~")[0]
                                              let y = e.target.id.split("~")[1]
                                              for (let i = 0; i < units.length; i++) {
                                                if (units[i].unit_id === x) {
                                                  for (let j = 0; j < units[i].activities.length; j++) {
                                                    if (units[i].activities[j].capsule_content_id === y) {
                                                      units[i].activities[j].dripDays = value === "" ? 0 : parseInt(value)
                                                    }
                                                  }
                                                }
                                              }
                                              this.setState({
                                                units,
                                              });
                                            }
                                          }
                                        }
                                      }}

                                    />
                                  </span>
                                </>
                              )
                            }
                            let gvalue = this.getCapsuleDripCoditionId(item.unit_id, citem.capsule_content_id)
                            let caps_enable_condition = "previous" //"all_previous","all","previous"
                            let component3 = () => {
                              if (dripConditionType.indexOf("COMPLETEDDATECAPSULE") > -1) {
                                let getCom3Items = () => {
                                  let rsItems = []
                                  let xrrItems = this.state.arrDripCapsules
                                  xrrItems.map((ditem, iindex) => {
                                    console.log(ditem.drip_id, "!==", citem.capsule_id, "**", ditem.drip_mapped_id, "=== ", item.unit_id, "**", (iindex + 1), "===", cDripindex)
                                    if (caps_enable_condition === "previous") {
                                      if (ditem.drip_id !== citem.capsule_id && ditem.drip_mapped_id === item.unit_id && cDripindex > 0 && iindex === (cDripindex - 1)) {
                                        rsItems.push(ditem)
                                      }
                                    } else if (caps_enable_condition === "all_previous") {
                                      if (ditem.drip_id !== citem.capsule_id && ditem.drip_mapped_id === item.unit_id && iindex < cDripindex) {
                                        rsItems.push(ditem)
                                      }
                                    } else {
                                      rsItems.push(ditem)
                                    }

                                  })
                                  if (rsItems.length === 1) {
                                    if (gvalue !== rsItems[0].drip_id) {
                                      let units = this.state.units
                                      let x = item.unit_id
                                      let y = citem.capsule_content_id
                                      for (let i = 0; i < units.length; i++) {
                                        if (units[i].unit_id === x) {
                                          for (let j = 0; j < units[i].activities.length; j++) {
                                            if (units[i].activities[j].capsule_content_id === y) {
                                              units[i].activities[j].dripConditionId = rsItems[0].drip_id
                                            }
                                          }
                                        }
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }
                                  }
                                  return rsItems
                                }

                                let arrItems = getCom3Items()
                                if (arrItems.length > 1) {
                                  return (
                                    <>
                                      <span>
                                        <Field
                                          name={item.unit_id + "~" + citem.capsule_content_id + "~DRIPCONTENT"}
                                          id={item.unit_id + "~" + citem.capsule_content_id + "~DRIPCONTENT"}
                                          component={this.renderInlineSelectField}
                                          type="select"
                                          g_value={this.getCapsuleDripCoditionId(item.unit_id, citem.capsule_content_id)}
                                          className={`input-field ${styles.inputTriningSettings}`}
                                          validate={[]}
                                          onChng={(e) => {
                                            e.preventDefault();
                                            if (this.state.capsule_drip_enabled === 1 || this.state.capsule_drip_enabled === true) {
                                              let value = e.target.value;
                                              let units = this.state.units
                                              let x = e.target.name.split("~")[0]
                                              let y = e.target.name.split("~")[1]
                                              for (let i = 0; i < units.length; i++) {
                                                if (units[i].unit_id === x) {
                                                  for (let j = 0; j < units[i].activities.length; j++) {
                                                    if (units[i].activities[j].capsule_content_id === y) {
                                                      units[i].activities[j].dripConditionId = value
                                                    }
                                                  }
                                                }
                                              }
                                              this.setState({
                                                units,
                                              });
                                            }
                                          }}
                                          g_style={{ fontStyle: "italic", fontWeight: "bold", color: "#031b4e" }}
                                        >
                                          {arrItems.map((ditem, iindex) => {
                                            return (
                                              <option
                                                key={ditem.drip_id}
                                                value={ditem.drip_id}
                                              >
                                                {ditem.drip_name}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                      </span>
                                    </>
                                  )
                                } else if (arrItems.length === 1) {
                                  return (
                                    <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
                                      {arrItems[0].drip_name}
                                    </span>
                                  )
                                }
                              } else {
                                if (gvalue !== item.unit_id) {
                                  let units = this.state.units
                                  let x = item.unit_id
                                  let y = citem.capsule_content_id
                                  for (let i = 0; i < units.length; i++) {
                                    if (units[i].unit_id === x) {
                                      for (let j = 0; j < units[i].activities.length; j++) {
                                        if (units[i].activities[j].capsule_content_id === y) {
                                          units[i].activities[j].dripConditionId = item.unit_id
                                        }
                                      }
                                    }
                                  }
                                  this.setState({
                                    units,
                                  });
                                }
                              }
                              return (
                                <span className={styles.unitName}>
                                  {item.unit_name}
                                </span>
                              )
                            }
                            return (
                              <>
                                <div style={{
                                  display: this.state
                                    .toggleunitstate[
                                    item.unit_id + "-toggle"
                                  ]
                                    ? ""
                                    : "none",
                                }}>
                                  <div
                                    className={classList([
                                      "form-group",
                                      "row",
                                      required === true ? "required" : "",
                                    ])}
                                    style={{ margin: "0px", flexDirection: "column" }}
                                  >
                                    <fieldset className={styles.postSurveyFieldSet} style={{ marginTop: "0px" }}>
                                      <div className="form-group row" style={{ margin: "0px" }}>
                                        <div className={`col-sm-12 ${styles.capsuleContentName}`}>
                                          <label>{citem.capsule_content_name}</label>
                                        </div>
                                      </div>
                                      {
                                        this.state.capsule_drip_enabled === 1 ? <>
                                          <div style={{ paddingBottom: "14px" }}></div>
                                          <div className="row" style={{ border: "0px solid red", margin: "0px" }}>
                                            <div className="col-sm-12">
                                              <span style={{ float: "left", margin: "0px -23px 0px 0px" }}>
                                                <span className={`label ${styles.timedReleaseLbl}`}>{this.getLabel("LBLDRIPTYPE")}</span>
                                                <span>

                                                  {renderCapsuleDripTypeSelection({
                                                    mode: "single",
                                                    name: item.unit_id + "~" + citem.capsule_content_id + "~DRIPCONDITIONTYPE",
                                                    g_value: this.getCapsuleDripCoditionType(item.unit_id, citem.capsule_content_id),
                                                    onChng: (e) => {
                                                      if (e.target.checked === true) {
                                                        if (this.state.capsule_drip_enabled === 1 || this.state.capsule_drip_enabled === true) {
                                                          console.log("!@!@!@!!!!!!!!!!!!", e.target.value)
                                                          let value = e.target.value;
                                                          let units = this.state.units
                                                          let x = e.target.name.split("~")[0]
                                                          let y = e.target.name.split("~")[1]
                                                          console.log(x, y)
                                                          for (let i = 0; i < units.length; i++) {
                                                            console.log("===", units[i].unit_id, x)
                                                            if (units[i].unit_id === x) {
                                                              console.log("Unit")
                                                              for (let j = 0; j < units[i].activities.length; j++) {
                                                                if (units[i].activities[j].capsule_content_id === y) {
                                                                  console.log("Capsule")

                                                                  units[i].activities[j].dripConditionType = value
                                                                  if (value.indexOf("UNIT") > -1) {
                                                                    units[i].activities[j].dripContentType = "UNIT"
                                                                  } else {
                                                                    units[i].activities[j].dripContentType = "CAPSULE"
                                                                  }
                                                                  try {
                                                                    units[i].activities[j].trigger_condition["and"][0].active = "1"
                                                                  } catch (e) { }

                                                                }
                                                              }
                                                            }
                                                          }
                                                          this.setState({
                                                            units,
                                                          });
                                                        }
                                                      }
                                                    }
                                                  })}

                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-sm-12" style={{ paddingTop: "10px", lineHeight: "30px", fontSize: "14px" }}>
                                            {zrrlabels.map((strText, zcindex) => {
                                              if (strText === "$$$1") {
                                                return (component1())
                                              } else if (strText === "$$$2") {
                                                return (component2())
                                              } else if (strText === "$$$3") {
                                                return (component3())
                                              } else if (strText === "$br$") {
                                                return (<div></div>)
                                              } else {
                                                return strText
                                              }
                                            })}
                                          </div>
                                        </> : <></>
                                      }


                                    </fieldset>
                                  </div>
                                </div>
                              </>

                            )
                          })
                          }
                        </>
                      )
                    } else {
                      return (
                        <></>
                      )
                    }

                  })}
                </div>
                <div className="col-sm-12" style={{ paddingTop: "20px" }}>
                  <button
                    type="button"
                    className="button-style primary-btn"
                    onClick={this.saveCapsuleDrips}
                    style={{ float: "right" }}
                  >
                    {this.getLabel("LBLSUBMIT")}
                  </button>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.props.closeModal}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {this.getLabel("LBLCANCEL")}
                  </button>
                </div>
              </div>
            </div>
          </> : <></>
          }
          {this.state.showTab === "notifications" ? <>
            <div>
              <div className="row">
                <div className="col-sm-12 " style={{ marginBottom: "10px" }}>
                  <div  >
                    <div className={styles.icoBtn} >
                      <i
                        className=""
                        data-toggle="modal"
                        onClick={(e) => {
                          this.getMessage("courses_enrolled")
                        }}
                        alt={this.getLabel("LBLVIEWNOTIFICATION")}
                      >
                        {this.getLabel("LBLVIEWNOTIFICATION")}
                      </i>
                    </div>
                    <Field
                      name="notify_general"
                      g_value={this.getNotificationValue("notify_general")}
                      component={this.renderInlineToggleInput}
                      id="notify_general"
                      label={this.getLabel("LBLNOTIFYGENERALASSIGNMENT")}
                      className={`input-field ${styles.inputTriningSettings}`}
                      validate={[required]}
                      onChng={(e) => {
                        let notification_settings = this.state.notification_settings
                        notification_settings.notify_general = !notification_settings.notify_general
                        this.setState({
                          notification_settings,
                        });
                        console.log(e.target.value);
                      }}
                    ></Field>
                    <div style={{ padding: "8px 16px 0px 16px" }}>
                      <label className={`label ${styles.timedReleaseLbl}`}>
                        {this.getLabel("LBLNOTIFYGENERALASSIGNMENTDC")}
                      </label>
                    </div>
                  </div>
                  {this.state.tabSurveyLinks === 1 ? <>
                    <div  >
                      <div className={styles.icoBtn} >
                        <i
                          className=""
                          data-toggle="modal"
                          onClick={(e) => {
                            this.getMessage("presurvey_available")
                          }}
                          alt={this.getLabel("LBLVIEWNOTIFICATION")}
                        >
                          {this.getLabel("LBLVIEWNOTIFICATION")}
                        </i>
                      </div>
                      <Field
                        name="notify_survey_available"
                        g_value={this.getNotificationValue("notify_survey_available")}
                        component={this.renderInlineToggleInput}
                        id="notify_survey_available"
                        label={this.getLabel("LBLNOTIFYSURVEYAVAILABLE")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let notification_settings = this.state.notification_settings
                          notification_settings.notify_survey_available = !notification_settings.notify_survey_available
                          this.setState({
                            notification_settings,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                      <div style={{ padding: "8px 16px 0px 16px" }}>
                        <label className={`label ${styles.timedReleaseLbl}`}>
                          {this.getLabel("LBLNOTIFYSURVEYAVAILABLEDC")}
                        </label>
                      </div>
                    </div></> : <></>}

                  {(this.state.unit_drip_enabled === 1 || this.state.unit_drip_enabled === true) ? <>
                    <div  >
                      <div className={styles.icoBtn} >
                        <i
                          className=""
                          data-toggle="modal"
                          onClick={(e) => {
                            this.getMessage("unit_available")
                          }}
                          alt={this.getLabel("LBLVIEWNOTIFICATION")}
                        >
                          {this.getLabel("LBLVIEWNOTIFICATION")}
                        </i>
                      </div>
                      <Field
                        name="notify_unit_available"
                        g_value={this.getNotificationValue("notify_unit_available")}
                        component={this.renderInlineToggleInput}
                        id="notify_unit_available"
                        label={this.getLabel(this.state.childNode && this.state.childNode === 'modules'? "LBLNOTIFYUNITDRIPAVAILABLEMOD" :"LBLNOTIFYUNITDRIPAVAILABLE")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let notification_settings = this.state.notification_settings
                          notification_settings.notify_unit_available = !notification_settings.notify_unit_available
                          this.setState({
                            notification_settings,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                      <div style={{ padding: "8px 16px 0px 16px" }}>
                        <label className={`label ${styles.timedReleaseLbl}`}>
                          {this.getLabel(this.state.childNode && this.state.childNode === 'modules' ? "LBLNOTIFYMODDRIPAVAILABLEDC": "LBLNOTIFYUNITDRIPAVAILABLEDC")}
                        </label>
                      </div>
                    </div></> : <></>}

                  {(this.state.tabCapsuleDrip === 1 && (this.state.capsule_drip_enabled === 1 || this.state.capsule_drip_enabled === true)) ? <>
                    <div  >
                      <div className={styles.icoBtn} >
                        <i
                          className=""
                          data-toggle="modal"
                          onClick={(e) => {
                            this.getMessage("capsule_available")
                          }}
                          alt={this.getLabel("LBLVIEWNOTIFICATION")}
                        >
                          {this.getLabel("LBLVIEWNOTIFICATION")}
                        </i>
                      </div>
                      <Field
                        name="notify_capsule_available"
                        g_value={this.getNotificationValue("notify_capsule_available")}
                        component={this.renderInlineToggleInput}
                        id="notify_capsule_available"
                        label={this.getLabel("LBLNOTIFYCAPSULEDRIPAVAILABLE")}
                        className={`input-field ${styles.inputTriningSettings}`}
                        validate={[required]}
                        onChng={(e) => {
                          let notification_settings = this.state.notification_settings
                          notification_settings.notify_capsule_available = !notification_settings.notify_capsule_available
                          this.setState({
                            notification_settings,
                          });
                          console.log(e.target.value);
                        }}
                      ></Field>
                      <div style={{ padding: "8px 16px 0px 16px" }}>
                        <label className={`label ${styles.timedReleaseLbl}`}>
                          {this.getLabel("LBLNOTIFYCAPSULEDRIPAVAILABLEDC")}
                        </label>
                      </div>
                    </div></> : <></>}

                </div>
                <div className="col-sm-12">
                  <button
                    type="button"
                    className="button-style primary-btn"
                    onClick={this.saveNotifications}
                    style={{ float: "right" }}
                  >
                    {this.getLabel("LBLSUBMIT")}
                  </button>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.props.closeModal}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {this.getLabel("LBLCANCEL")}
                  </button>
                </div>
              </div>
            </div>
            {this.state.mailview === true ? (
              <div className="row col-sm-12 mod-pop" style={{ display: "flex" }}>
                <Modal
                  show={this.state.modal_mail_view_show}
                  onHide={this.closeMailView}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                >
                  <Modal.Header closeButton className='alert-modal-header'>
                    <Modal.Title className="alert-modal-title">
                      {this.state.modal_mail_heading}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: this.state.modal_mail_body }}></div>
                  </Modal.Body>
                </Modal>
              </div>
            ) : ""}
          </> : <></>
          }
         
          {this.state.showTab === "settings" ? <>
            <div>
              <div className="row">
                <div className="col-sm-12 " style={{ marginBottom: "10px" }}>

                  {this.state.units.length>1 && this.props.showunits_certificate === 1?

                  <div>
                    <Field
                      name="showunits_certificate"
                      g_value={this.getCrsOptionsValue("showunits_certificate")}
                      component={this.renderInlineToggleInput}
                      id="showunits_certificate"
                      label={this.getLabel(this.state.Sitetype=== 'SafeStart'? (this.state.childNode && this.state.childNode === 'modules' ? "LBLMODULELEVELCERTIFICATE" : "LBLUNITLEVELCERTIFICATE") :"LBLMODULELEVELCERTIFICATE")}
                      className={`input-field ${styles.inputTriningSettings}`}
                      onChng={(e) => {
                        let crsoption_settings = this.state.crsoption_settings;
                        crsoption_settings.showunits_certificate = !crsoption_settings.showunits_certificate;
                        this.setState({
                          crsoption_settings,
                        });
                        console.log(crsoption_settings);
                      }}
                    ></Field>
                    <div style={{ padding: "8px 16px 0px 16px" }}>
                      <label className={`label ${styles.timedReleaseLbl}`}>
                        {this.getLabel(this.state.Sitetype=== 'SafeStart'? (this.state.childNode && this.state.childNode === 'modules' ? "LBLENABLEDISABLECERTIFICATEMODULE" : "LBLENABLEDISABLECERTIFICATE") :"LBLENABLEDISABLECERTIFICATEMODULE")}
                      </label>
                    </div>
                  </div>
           :""}
                   {this.state.units.length>1 && this.state.Sitetype=== 'Accutrain'? 
                  <div>
                    <Field
                      name="sequential_access_unit"
                      g_value={this.getCrsOptionsValue("sequential_access_unit")}
                      component={this.renderInlineToggleInput}
                      id="sequential_access_unit1"
                      label={this.getLabel(this.state.Sitetype === 'SafeStart'?"LBLSEQUENTIALACCESS":"LBLMODULESEQUENTIALACCESS")}
                      className={`input-field ${styles.inputTriningSettings}`}
                      onChng={(e) => {
                        let crsoption_settings = this.state.crsoption_settings;
                        crsoption_settings.sequential_access_unit = !crsoption_settings.sequential_access_unit;
                        this.setState({
                          crsoption_settings,
                        });
                        console.log(e.target.value, "crsoption_settings123")
                      }}
                    ></Field>
                    <div style={{ padding: "8px 16px 0px 16px" }}>
                      <label className={`label ${styles.timedReleaseLbl}`}>
                        {this.getLabel(this.state.Sitetype === 'SafeStart'? (this.state.childNode && this.state.childNode === 'modules' ? "LBLENABLEDISABLEMODULESEQUENTIAL" : "LBLENABLEDISABLEUNITSEQUENTIAL"):"LBLENABLEDISABLEMODULESEQUENTIAL")}
                      </label>
                    </div>
                  </div>
             :"" }
           {this.state.units[0].engineversion ==='2' && this.state.videoseekbar == 1 ?
                  <div>
                    
                    <Field
                      name="fast_forward_enable"
                      g_value={this.getCrsOptionsValue("fast_forward_enable")}
                      component={this.renderInlineToggleInput}
                      id="fast_forward_enable"
                      label={this.getLabel("LBLVIDEOSEEKBARRESTRICTION")}
                      className={`input-field ${styles.inputTriningSettings}`}
                      onChng={(e) => {
                        let crsoption_settings = this.state.crsoption_settings;
                        crsoption_settings.fast_forward_enable = !crsoption_settings.fast_forward_enable;
                        this.setState({
                          crsoption_settings,
                        });
                      }}
                    ></Field>
                    <div style={{ padding: "8px 16px 0px 16px" }}>
                      <label className={`label ${styles.timedReleaseLbl}`}>
                        {this.getLabel("LBLENABLEDISABLEVIDEOSEEKBAR")}
                      </label>
                    </div>
             
                  </div>
  :""}
                </div>
                <div className="col-sm-12">
                  <button
                    type="button"
                    className="button-style primary-btn"
                    onClick={this.saveCourseOptionSettings}
                    style={{ float: "right" }}

                  >
                    {this.getLabel("LBLSUBMIT")}
                  </button>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.props.closeModal}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {this.getLabel("LBLCANCEL")}
                  </button>
                </div>
              </div>
             </div>
              </> : <></>
          }
        </> : <></>}
        {this.state.showToast.show ? (
          <ToastMessage
            show={this.state.showToast["show"]}
            helpers={this.state.showToast}
            showGreybg={true}
          ></ToastMessage>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const renderToggleInput = (field) => {
  console.log(field);
  return (
    <Form.Check
      type="switch"
      id={field.id}
      label=""
      checked={field.g_value}
      onChange={field.onChng}
      icons={false}
    />
  );
};
TrainingSettings = reduxForm({
  form: "trainingsettings", // a unique name for this form
})(TrainingSettings);

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    language_code: state.user_info_reducer.user_info.language_code,
    showunits_certificate: state.user_info_reducer.user_info.showunits_certificate
  };
};

export default connect(mapStateToProps, null)(TrainingSettings);


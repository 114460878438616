import { HttpInterceptor } from '../../auth/HttpInterceptor';

class ChannelService {
    channelsAPIServicesCall(apiName, method, callback, data = null, domainURL = process.env.REACT_APP_CHANNELAPI_URL) {
        let apiUrl = domainURL + apiName;
        console.log('apiUrl ',apiUrl)
        if (method === "get") {
            HttpInterceptor[method](apiUrl)
                .then(result => {
                    if (callback) {
                        callback(result);
                    }
                }).catch(errr => {
                    if (errr && errr.response && errr.response.status && Number(errr.response.status)) {
                        alert("Error code : " + Number(errr.response.status))
                    }
                })
        }
        else {
            HttpInterceptor[method](apiUrl, data)
                .then(result => {
                    if (callback) {
                        callback(result);
                    }
                }).catch(errr => {
                })
        }
    }

    async getS3AssetsSignedURL(param) {
        const options = {
            method: 'post',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(param)
        };

        let response = await fetch(process.env.REACT_APP_CHANNELAPI_URL + "gets3signedassetsurl", options);

        return response;
    }


}
export default new ChannelService();


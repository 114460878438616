import React from "react";
import DataTable from "react-data-table-component";
import Loading from "../../../shared/Loading";
import orderBy from "lodash/orderBy";
import { connect } from "react-redux";
import NearMissService from "./NearMissService/nearMissService";
import GroupServices from "../group-list/GroupServices";
import Loader from "../../components/loader/Loader";
import FeedsServices from "../../../ssifeeds/feedService/feedservices";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as FileSaver from "file-saver";
import CustomFilter from "../../components/reportfilter/containers/CustomFilter";
import * as XLSX from "xlsx";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReportComponent from "./NearMissReportComp";
import emptyImage from "../../../assets/images/learnerprogress_emptyst.png";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import "../../pages/reports/report.scss";
import  "./NearMissReport.scss";

require("highcharts/modules/exporting")(Highcharts);
//near_miss_report
const moment = require("moment");
let horizontal = "top",
  vertical = "top";

const $ = require("jquery");
let changesIn = {};
let objFilterData = {};
const REFS = {};
let loaddata = false;
let firstLoad = 0;
let loadfilter = true;
let objLabels = [];
let resetColumns = [];
class NearMissReport extends React.Component {
  constructor(props) {
    super(props);
    objLabels = props.labels.labels;
    this.createPareto = this.createPareto.bind(this);
    this.state = {
      labels: props,
      dateformat: props.dateformat,
      changedChart: false,
      prevChartType: false,
      chartName: "",
      chartFilter: "",
      userlist: [],
      searchreport: "",
      roles: [],
      filterval: [],
      visible: "none",
      orgs: [],
      groups: [],
      loadingtypes: true,
      loading: true,
      show: false,
      showFilter: false,
      status: 0,
      initialValues: null,
      ssi_errors: null,
      searchValue: "",
      roleFilter: "",
      groupData: [],
      group_type: "",
      roleValid: false,
      mailPhoneValid: false,
      groups_by_type: [],
      helpersLoaded: false,
      showTab: "info",
      allFilterValues: [],
      listData: [],
      singleObj: [],
      dateRange: [],
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      subchartloading: false,
      loadingchart: true,
      showchart: false,
      options: {},
      state1: 0,
      state2: 0,
      state3: 0,
      state4: 0,
      error1: 0,
      error2: 0,
      error3: 0,
      error4: 0,
      open: false,
      selectedIndex: "6",
      transition: "",
      message: "",
      messageType: "success",
      inccount: 0,
      selyear: "2020",
      reporttype: 0,
      reporttype1: 0,
      dataPoints: [],
      dataPoints1: [],
      dataPoints2: [],
      dataPoints3: [],
      dataPoints4: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data: [],
      reportname: "",
      reportname1: "",
      reportname2: "",
      reportname3: "",
      reportname4: "",
      exporting: false,
      rcount: 1,
      dataStatesErrors: [],
      dataStates: [],
      dataErrors: [],
      ddlreporttypes: [],
      subchartloadingfull: false,
      subchartloadingtop: true,
      hasError: false,
      showList: false,
      currChartType: "pie",
      filteredChartData: [],
      ddlFilterReports: [],
    };
    loadfilter = true;
  }
  setincidentcount = (count) => {
    this.setState({
      inccount: count,
    });
  };
  componentDidMount() {
    this.filterDetails();
    this.getResetFilterColumns = (1, []);

    loaddata = false;
    let getData = {
      language: this.props.language,
    };
    let filters = this.setdatefiltervals();
    this.getNearMissList(filters, 1);

    //this.getPlacesVsSeveritysReport();

    this.loadReportTypes();

    let { reporttype } = this.state;
    if (reporttype == 8) {
      this.createPareto();
    }
    $(document).on("click", function (event) {
      if ($(".prowfilterbox").css("display") != undefined) {
        let blnopen =
          $(".prowfilterbox").css("display") == "none" ? false : true;
        if (blnopen) {
          $(".cls_report_list").addClass("cls_fade");
        } else {
          $(".cls_report_list").removeClass("cls_fade");
        }
      }
    });
    setTimeout(() => {
      this.setState({
        loadingtypes: false,
      });
    }, 1000);
  }
  componentWillUnmount() {
    // this.ref.resetfilterFields() ;
    firstLoad = 0;
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.state.currChartType !== prevProps.currChartType) {
      this.fetchData(this.props.userID);
    }
  }
  setdatefiltervals = (mode) => {
    let finalval = "",
      xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
    let year, month, date, maxdate;
    if (xrArr.length > 1) {
      maxdate = new Date(xrArr[1]);
      year = maxdate.getFullYear();
      month = maxdate.getMonth();
      date = maxdate.getDate();
      xrArr[1] = new Date(year, month, date, 23, 59, 59);
    }
    finalval = { operator: "LAST7DAYS", value: xrArr };
    if (mode == 1) {
      // $("#selItem_span_Date").next().next().html(this.props.labels.labels["LBLLAST7DAYS"] +" " + new Date(xrArr[0]).toLocaleDateString() + " - " + new Date(xrArr[0]).toLocaleDateString())
    } else {
      finalval = { date: finalval };
      this.setState({
        filterval: finalval,
      });
    }
    return finalval;
  };

  filterDetails = () => {
    this.getNearMisCollections();
  };
  loadReportTypes = () => {
    let ddlMainReports,
      ddlReportsPie = [],
      ddlReportsBarStates,
      ddlReportsPieErrors;
    let {
      LBLWORKVSSTATES,
      LBLHOMEKVSSTATES,
      LBLPUBLICKVSSTATES,
      LBLTRANSKVSSTATES,
      LBLWORKVSERRORS,
      LBLHOMEVSERRORS,
      LBLPUBLICVSERRORS,
      LBTRANSVSERRORS,
      LBLMONTHCOUNT,
      LBLMONTHSEVERITYCOUNT,
      LBLMONTHINCTYPECOUNT,
      LBLMONTHINCIDENTCOUNT,
      LBLPLACESVSSTATES,
      LBLPLACESVSERRORS,
      LBLSTATESVSINCIDENT,
      LBLERRORSVSINCIDENT,
      LBLSEVERITYCOMPARE,
      LBLINCTYPEPARETO,
    } = this.props.labels.labels;

    ddlMainReports = [
      LBLMONTHINCIDENTCOUNT,
      LBLPLACESVSSTATES,
      LBLPLACESVSERRORS,
      LBLMONTHSEVERITYCOUNT,
      LBLMONTHINCTYPECOUNT,
      LBLSTATESVSINCIDENT,
      LBLERRORSVSINCIDENT,
      LBLINCTYPEPARETO,
      LBLSEVERITYCOMPARE,
    ];
    let ddlMainReports1 = [
      { name: "LBLMONTHINCIDENTCOUNT", value: "1" },
      { name: "LBLPLACESVSSTATES", value: "2" },
      { name: "LBLPLACESVSERRORS", value: "3" },
      { name: "LBLMONTHSEVERITYCOUNT", value: "4" },
      { name: "LBLMONTHINCTYPECOUNT", value: "5" },
      { name: "LBLSTATESVSINCIDENT", value: "6" },
      { name: "LBLERRORSVSINCIDENT", value: "7" },
      { name: "LBLINCTYPEPARETO", value: "8" },
      { name: "LBLSEVERITYCOMPARE", value: "9" },
      //  { name: LBLPLACESVSSEVERITYS, value: "10" },
    ];
    ddlReportsPie = [
      LBLMONTHCOUNT,
      LBLMONTHSEVERITYCOUNT,
      LBLMONTHINCTYPECOUNT,
    ];
    ddlReportsBarStates = [
      LBLWORKVSSTATES,
      LBLHOMEKVSSTATES,
      LBLPUBLICKVSSTATES,
      LBLTRANSKVSSTATES,
    ];
    ddlReportsPieErrors = [
      LBLWORKVSERRORS,
      LBLHOMEVSERRORS,
      LBLPUBLICVSERRORS,
      LBTRANSVSERRORS,
    ];

    this.setState({
      reportsIncidents: ddlReportsPie,
      reportsStates: ddlReportsBarStates,
      reportsErrors: ddlReportsPieErrors,
      ddlMainReports: ddlMainReports,
      ddlFilterReports: ddlMainReports1,
      ddlMainReports1: ddlMainReports1,
      loading: false,
    });
  };
  getNearMisCollections = (evt) => {
    let apiUrl = "nearmiss/incidentmasters";
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getResultHandler,
      ""
    );
  };
  getResultHandler = (response) => {
    let locshift,
      other = true,
      Data = [],
      allData = response.data.nearmiss_master;
    let allGroupsResponse = response.data.groups;
    let allUserResponse = response.data.userlist;
    allData.map((item, idx) => {
      if (item.type == "LBLINCIDENTTYPE") {
        this.labelTranslation(item.values, "incident_type_name");
        Data.incident_type = item.values;
      } else if (item.type == "LBLINCSOURCE") {
        this.labelTranslation(item.values, "incident_source_name");
        Data.incident_source = item.values;
      } else if (item.type == "LBLINCIDENTSEVERITY") {
        this.labelTranslation(item.values, "incident_severity_name");
        Data.severity = item.values;
      }
    });

    let singleobjectVals = [];
    singleobjectVals["incident_type"] = Data.incident_type;
    singleobjectVals["incident_source"] = Data.incident_source;
    singleobjectVals["severity"] = Data.severity;
    singleobjectVals["user_list"] = allUserResponse;
    let { initialValues } = this.state;
    let allGroups = [];
    response = allGroupsResponse;
    if (response) {
      for (var m = 0; m < response.length; m++) {
        if (response[m].group_type)
          allGroups[response[m].group_type] = response[m].groups;
      }
    }

    this.setState({
      allDataList: allData,
      Data: Data,
      singleObj: singleobjectVals,
      CAothers: other,
      shift: Data.shift[0],
      loadingadd: false,
      groupTypeInfo: allGroupsResponse,
      allGroupss: allGroups,
    });
  };
  labelTranslation = (allLabels, key) => {
    let transLabels = [];
    if (allLabels.length > 0) {
      allLabels.map((item, i) => {
        item[key] = this.props.labels.labels[item[key]]
          ? this.props.labels.labels[item[key]]
          : item[key];
      });
    }
    return transLabels;
  };
  componentDidUpdate() {
    $(".rdt_TableHeadRow").css({ "background-color": "#f7f7f7" });
    let { reporttype1 } = this.state;
    if (reporttype1 > 10) {
      $(".canvasjs-chart-canvas").css({
        padding: "0px",
        height: "",
        width: "",
      });
    } else {
      $(".canvasjs-chart-canvas").css({
        padding: "40px",
        height: "400px",
        width: "100%",
      });
    }
    if (loadfilter == true) {
      this.setdatefiltervals(1);
    }
    $("#btnGroupDrop1").hide();

    let { reporttype } = this.state;
    if (reporttype == 8) {
      this.createPareto();
    }
  }
  showDefaultText = (chart, text) => {
    chart = this.chart;
    var isEmpty = !(
      chart.options.data[0].dataPoints &&
      chart.options.data[0].dataPoints.length > 0
    );

    if (!chart.options.subtitles) chart.options.subtitles = [];

    if (isEmpty)
      chart.options.subtitles.push({
        text: text,
        verticalAlign: "center",
      });
    else chart.options.subtitles = [];
  };
  getGroupList = (selGroupTypeId) => {
    let apiUrl = "groupstype";

    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "get",
      this.productGroupResultHandler
    );
  };
  productGroupResultHandler = (res) => {
    let xx = res;
  };
  getNearMissList(filters, mode) {
    let filtervals,
      params = { year: "2020" };
    filtervals = this.state.filterval;
    filtervals = filtervals ? filtervals : [];
    if (mode == 1) filtervals = filters ? filters : [];
    let filtervalues = JSON.stringify(filtervals);
    let apiUrl =
      "nearmiss/report/incidentdetails?params=" + encodeURI(filtervalues);
    //let apiUrl = "nearmiss";
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getNearMissListHandler,
      filtervals
    );
  }
  getNearMissListHandler = (response) => {
    //this.setdatefiltervals(1);
    let item,
      inccount,
      Data = [],
      allDataList = [],
      //allData = response.data;
      allData = response.data.result;
    let objLabels = this.props.labels.labels;
    allDataList = allData;
    inccount = allData.length;
    allData.map((item, idx) => {
      //item = items.values[0];
      allDataList = [];
      allDataList.rowid_id = item._id;
      allDataList.incident_id = item.incident_id;
      allDataList.incident_title = item.incident_title;
      allDataList.areas = item.area.area_name;
      //allDataList.corrective_actions = (item.corrective_actions?item.corrective_actions.corrective_action_name:item.corrective_actions.corrective_action_name)
      allDataList.department =
        item.department == null ? "" : item.department.department_name;
      allDataList.location =
        item.location == null ? "" : item.location.location_name;
      allDataList.place = $.isArray(item.incident_place)
        ? item.incident_place[0].incident_place_name
        : item.incident_place.incident_place_name;
      allDataList.shift =
        item.shift == null
          ? ""
          : item.shift[0]
            ? item.shift[0].shift_name
            : item.shift.shift_name;

      let inctype = item.incident_type.incident_type_name;
      let incsource = item.incident_source
        ? item.incident_source.incident_source_name
        : "";
      let sevtype = item.incident_severity.incident_severity_name;
      allDataList.incident_type =
        inctype.indexOf("LBL") > -1 ? objLabels[inctype] : inctype;
      allDataList.incident_source =
        incsource.indexOf("LBL") > -1
          ? objLabels[incsource]
            ? objLabels[incsource]
            : incsource
          : incsource;
      allDataList.incident_severity =
        sevtype.indexOf("LBL") > -1 ? objLabels[sevtype] : sevtype;

      allDataList.incident_summary = item.incident_summary.summary_value;
      allDataList.event_date = item.event_date
        ? //? new Date(item.event_date).toLocaleDateString()
        moment.utc(new Date(item.event_date)).format(this.state.dateformat)
        : "";
      allDataList.status = item.status;
      allDataList.created_by = item.created_by;
      allDataList.incident_states = item.incident_states;
      allDataList.incident_errors = item.incident_errors;
      //allDataList.areas = (item.area.area_name)

      Data.push(allDataList);
    });
    let xData = Data;
    this.setState({
      show: false,
      initialValues: null,
      showDel: false,
      listData: Data,
      listDataReport: xData,
      allData: allData,
      loading: false,
      inccount: inccount,
    });
  };
  showAlert = (messageType, message) => {
    this.setState({
      open: true,
      messageType: messageType,
      message: message,
    });
  };
  handleClose = (event) => {
    this.setState({
      open: false,
      transition: "Fade",
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
      initialValues: null,
      showDel: false,
      ssi_errors: null,
    });
  };

  openModal = () => {
    this.setState({ show: true });
  };

  openDelModal = (item, e) => {
    this.setState({
      showDel: true,
      userId: item.user_id,
      userName: item.username,
    });
  };
  getGroupTypes = () => {
    this.setState({ isLoadAPI: 1 });
    GroupServices.getGroupTypeLabels().then((response) => {
      this.setState(
        {
          groupTypeNames: response,
        },
        () => {
          this.getGroupList(this.state.groupTypeNames[0].group_type_id);
        }
      );
    });
  };

  searchNearMiss = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };
  roleFilter = (e) => {
    this.setState({
      roleFilter: e.target.value,
      searchValue: "",
    });
  };
  tableHeader = () => {
    return (
      <div className="table-filter neatmiss_tab">
        <div class="cls_selected_report">
          <div
            id="selected_report"
            class="cls_rpt_proc_title title_color"
            style={{ display: "flex" }}
          >
            <div>{this.state.reportname}</div>
            <div>
              <span class="title_clear">:</span> { }
            </div>
            {this.state.chartFilter}
          </div>
        </div>
        <div style={{ float: "right ", textAlign: "right " }}>
          <div
            className="clsSelFilters"
            style={{ display: this.state.visible }}
          >
            <span></span>
          </div>
        </div>
        <input
          type="text"
          className="input-field near-miss-search"
          placeholder={this.props.labels.labels.LBLSEARCH}
          value={this.state.searchValue}
          onChange={this.searchNearMiss}
        />
      </div>
    );
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (
        row[field] &&
        row[field] !== 1 &&
        row[field] !== 0 &&
        typeof row[field] !== "boolean"
      ) {
        return row[field].toString().toLowerCase();
      }
      return row[field];
    };
    return orderBy(rows, handleField, direction);
  };
  onExport = (filetype) => {
    let { listData, reportname, rpt_type_val } = this.state;
    let loclistData = [];
    for (var i = 0, len = listData.length; i < len; i++) {
      loclistData[i] = {}; // empty object to hold properties added below
      for (var prop in listData[i]) {
        if ((prop == "place" || prop == "shift") && listData[i][prop] != "") {
          loclistData[i][prop] = this.props.labels.labels[listData[i][prop]];
        } else {
          loclistData[i][prop] = listData[i][prop]; // copy properties from arObj to ar2
        }
      }
    }
    let newval, upperval, arr, fieldName, arrfieldName;
    $("#loadingexport").show();
    loclistData.map((itm) => {
      if (delete itm["areas"]) {
        delete itm["rowid_id"];
        delete itm.incident_states;
        delete itm.incident_errors;
        delete itm.created_by;
      }
      if (
        rpt_type_val.value == "2" ||
        rpt_type_val.value == "3" ||
        rpt_type_val.value == "6" ||
        rpt_type_val.value == "7"
      ) {
        //if(itm.type=="incident_source"){
        //delete itm.incident_source;
        //  }
      }
      if (typeof itm.status != "undefined")
        itm.status =
          itm.status.toString().toLowerCase() == "true"
            ? objLabels["LBLOPEN"]
            : objLabels["LBLCLOSED"];
      if (typeof itm.STATUS != "undefined")
        itm.STATUS =
          itm.STATUS.toString().toLowerCase() == "true"
            ? objLabels["LBLOPEN"]
            : objLabels["LBLCLOSED"];
      if (itm.incident_id)
        itm.incident_id = itm.incident_id.toString().substring(7, 12);
      else itm["INCIDENT ID"] = itm["INCIDENT ID"].substring(7, 12);

      arrfieldName = Object.keys(itm);
      arrfieldName.forEach((obj) => {
        upperval = obj.toString().toUpperCase();
        arr = upperval.split("_");
        if (arr.length > 1) {
          newval = arr[0] + " " + arr[1];
          itm[newval] = itm[obj];
          delete itm[obj];
        } else {
          newval = upperval;
          itm[newval] = itm[obj];
          delete itm[obj];
        }
      });

      return true;
    });
    loclistData.map((itm) => { });
    if (!loclistData) return null;
    if (filetype.toLowerCase() == "csv") {
      this.exportCSV(loclistData, reportname);
    } else if (filetype.toLowerCase() == "excel") {
      this.exportToExcel(loclistData, reportname);
    }
    loclistData = listData;
  };

  convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    let result_key = "";
    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        if (key.toLowerCase().indexOf("summary") > 0) {
          result_key = item[key].toString().replace(/\n/g, "");
        } else {
          if (typeof item[key] == "undefined") item[key] = "";
          result_key = item[key];
        }
        result += result_key;
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  exportCSV = (listdata, filename) => {
    var data, filename, link;
    var stockData = [];
    var objHeader = {};

    let lheader,
      xfields = listdata;
    stockData = xfields;
    var csv = this.convertArrayOfObjectsToCSV({
      data: stockData,
    });
    if (csv == null) return;

    filename = filename + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }

    data = encodeURI(csv);
    //  data = "data:text/csv;charset=utf-8,%EF%BB%BF" + data;
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
    $("#loadingexport").hide();
  };
  exportToExcel = (excelData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    $("#loadingexport").hide();
  };

  closeToast = () => {
    this.setState({ showToast: { show: false } });
  };
  setselectioncriteria = () => {
    let scriteria = this.props.labels.labels["LBLSHOWCRITERIA"];
    $(".cls_select_show").html(scriteria);
    $(".cls_container_selection").html($("#custom_reports h2").next().html());
    $(".cls_container_selection").css({ display: "none" });

    $(".cls_container_selection")
      .find("#selItem_span_Date")
      .next()
      .addClass("cls_select_box_items");
    $(".cls_container_selection")
      .find("#selItem_span_Incident_Type")
      .next()
      .addClass("cls_select_box_items");
    $(".cls_container_selection")
      .find("#selItem_span_Severity")
      .next()
      .addClass("cls_select_box_items");
    $(".cls_container_selection")
      .find("#selItem_span_Location")
      .next()
      .addClass("cls_select_box_items");
    $(".cls_container_selection")
      .find("#selItem_span_Department")
      .next()
      .addClass("cls_select_box_items");
    $(".cls_container_selection")
      .find("#selItem_span_Status")
      .next()
      .addClass("cls_select_box_items");

    $(".cls_container_selection")
      .find("#selItem_span_Date")
      .next()
      .next()
      .addClass("cls_selitems_color");
    $(".cls_container_selection")
      .find("#selItem_span_Incident_Type")
      .next()
      .next()
      .addClass("cls_selitems_color");
    $(".cls_container_selection")
      .find("#selItem_span_Severity")
      .next()
      .next()
      .addClass("cls_selitems_color");
    $(".cls_container_selection")
      .find("#selItem_span_Location")
      .next()
      .next()
      .addClass("cls_selitems_color");
    $(".cls_container_selection")
      .find("#selItem_span_Department")
      .next()
      .next()
      .addClass("cls_selitems_color");
    $(".cls_container_selection")
      .find("#selItem_span_Status")
      .next()
      .next()
      .addClass("cls_selitems_color");
  };
  handleReportChange = (event, mode) => {
    let selval = 0;
    let rpt_type = this.state.rpt_type_val;
    if (rpt_type) {
      event = this.state.rpt_type_val;
      event = event.value;
    } else {
      return false;
    }
    if (event.currentTarget) {
      selval = event.currentTarget.value;
      $("#custom_reports_header").show();
      $("#cls_report_list").show();
    } else if (event == 0) {
      $("#custom_reports_header").show();
      $("#cls_report_list").show();
      $("#reportHeaderSection").hide();
      $("#reportSection").hide();
      selval = event;
    } else {
      this.setselectioncriteria(1);
      $("#reportSection").show();
      selval = event;
    }
    setTimeout(() => {
      // console.log("near miss report",$("#reportSection")[0]);
      if($("#reportSection")[0]) {
        let offset = $("#reportSection").offset();
        window.scrollTo(offset.left, offset.top - 100);
      }
    }, 500);

    this.setState({
      reporttype: selval,
      showList: false,
    });
    if (selval > 0) this.processReports(selval);
    //}
  };
  handleNameChange = (e, item) => {
    let type = item;
    if (type != "") {
      this.setState({
        helperType: "",
        error: false,
        rpt_type_val: type,
      });
    }
  };
  handleReportTypeChange = (event) => {
    let selval = event.currentTarget.value;
    if (selval == "") {
      alert("Please select the report type");
      return false;
    }

    this.processReportsTypes(selval);
    this.setState({
      reporttype1: selval,
      subchartloading: true,
      subchartloadingfull: false,
      subchartloadingtop: true,
    });
  };
  processReportsTypes = (selval) => {
    let reporttype1 = selval;
    let reportname = "",
      cntlid = "reportprocess";
    this.setState({
      reporttype1: selval,
      subchartloading: true,
    });
    if (reporttype1 == 1) {
      reportname = "LBLMONTHCOUNT";
      this.getNearMisMonthReport();
    } else if (reporttype1 == 2) {
      reportname = "LBLMONTHSEVERITYCOUNT";
      this.getNearMisSeverityReport();
    } else if (reporttype1 == 3) {
      reportname = "LBLMONTHINCTYPECOUNT";
      this.getIncidentTypeReport();
    } else if (reporttype1 == 20) {
      reportname = "LBLWORKVSSTATES";
      this.getWorkVsStatesReport(selval);
    } else if (reporttype1 == 21) {
      reportname = "LBLHOMEKVSSTATES";
      this.getHomeVsStatesReport(selval);
    } else if (reporttype1 == 22) {
      reportname = "LBLPUBLICKVSSTATES";
      this.getPublicVsStatesReport(selval);
    } else if (reporttype1 == 23) {
      reportname = "LBLTRANSKVSSTATES";
      this.getTransVsStatesReport(selval);
    } else if (reporttype1 == 40) {
      reportname = "LBLWORKVSERRORS";
      this.getWorkVsErrorsReport();
    } else if (reporttype1 == 41) {
      reportname = "LBLHOMEVSERRORS";
      this.getHomeVsErrorsReport();
    } else if (reporttype1 == 42) {
      reportname = "LBLPUBLICVSERRORS";
      this.getPublicVsErrorsReport();
    } else if (reporttype1 == 43) {
      reportname = "LBTRANSVSERRORS";
      this.getTransVsErrorsReport();
    } else {
      // this.showAlert("warning", this.props.labels.labels.LBLSELREPORT);
      return false;
    }
    this.setState({
      reportname: reportname,
      subchartloading: false,
    });
    //this.showhidereports(cntlid)
  };
  getWorkVsStatesReport = (selval) => {
    let { workStates } = this.state;
    let
      ReportData = [],
      allData = workStates;
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsStatesReport(allData);

    setTimeout(() => {
      this.setState({
        dataPoints: ReportData,
        loadingchart: false,
        showchart: true,
        subchartloading: false,
      });
    }, 1000);
  };

  getHomeVsStatesReport = () => {
    let { homeStates } = this.state;
    let ReportData = [],
      allData = homeStates;
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsStatesReport(allData);

    setTimeout(() => {
      this.setState({
        dataPoints: ReportData,
        loadingchart: false,
        showchart: true,
        subchartloading: false,
      });
    }, 1000);
  };
  getHomeVsStatesReportHandler = () => { };

  getPublicVsStatesReport = () => {
    let { publicStates } = this.state;
    let
      ReportData = [],
      allData = publicStates;
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsStatesReport(allData);
    setTimeout(() => {
      this.setState({
        dataPoints: ReportData,
        loadingchart: false,
        showchart: true,
        subchartloading: false,
      });
    }, 1000);
  };

  getTransVsStatesReport = () => {
    let { driveStates } = this.state;
    let 
      ReportData = [],
      allData = driveStates;
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsStatesReport(allData);

    setTimeout(() => {
      this.setState({
        dataPoints: ReportData,
        loadingchart: false,
        showchart: true,
        subchartloading: false,
      });
    }, 1000);
  };
  getTransVsStatesReportHandler = () => { };

  getWorkVsErrorsReport = () => {
    let { workErrors } = this.state;
    let ReportData = [],
      allData = workErrors;
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsErrorsReport(allData);

    setTimeout(() => {
      this.setState({
        dataPoints: ReportData,
        loadingchart: false,
        showchart: true,
        subchartloading: false,
      });
    }, 1000);
  };
  getWorkVsErrorsReportHandler = () => { };

  getHomeVsErrorsReport = () => {
    let { homeErrors } = this.state;
    let ReportData = [],
      allData = homeErrors;
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsErrorsReport(allData);

    setTimeout(() => {
      this.setState({
        dataPoints: ReportData,
        loadingchart: false,
        showchart: true,
        subchartloading: false,
      });
    }, 1000);
  };
  getHomeVsErrorsReportHandler = () => { };

  getPublicVsErrorsReport = () => {
    let { publicErrors } = this.state;
    let ReportData = [],
      allData = publicErrors,
      obj = {};
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsErrorsReport(allData);
    setTimeout(() => {
      this.setState({
        dataPoints: ReportData,
        loadingchart: false,
        showchart: true,
        subchartloading: false,
      });
    }, 1000);
  };
  getPublicVsErrorsReportHandler = () => { };

  getTransVsErrorsReport = () => {
    let { driveErrors } = this.state;
    let ReportData = [],
      allData = driveErrors,
      obj = {};
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsErrorsReport(allData);

    setTimeout(() => {
      this.setState({
        dataPoints: ReportData,
        loadingchart: false,
        showchart: true,
        subchartloading: false,
      });
    }, 1000);
  };
  getTransVsErrorsReportHandler = () => { };

  buildMonthVsCountReport = (allData) => {
    let ReportData = [];
    let j = 0;
    allData.map((item, i) => {
      ReportData.push({
        name:
          objLabels[item.month] + "' " + item.year.toString().substring(2, 4),
        y: item.incident_counts,
        itemid: j++,
        year: item.year,
      });
    });
    ReportData.sort(function (a, b) {
      return b.itemid - a.itemid;
    });
    return ReportData;
  };
  buildPlacesVsSeveritysReport = (allData) => {
    let xx = 1,
      items = {
        Home: "1",
        Work: "2",
        Driving: "3",
        "Public Transportation": "4",
      },
      ReportsData = [],
      ReportData = [];

    let menuitems = [
      "#c00000",
      "#fc0201",
      "#f97d2a",
      "#fec000",
      "#fbe805",
      "#92d051",
    ];
    allData.map((itm, idx) => {
      xx = itm.incident_place_name;
      ReportData = [];
      itm.incident_severity.map((item, i) => {
        if (item.incident_severity_name == "Minor") {
          ReportData.push({
            name: item.incident_severity_name,
            y: item.count,
            color: menuitems[2],
          });
        } else if (item.incident_severity_name == "Major") {
          ReportData.push({
            name: item.incident_severity_name,
            y: item.count,
            color: menuitems[1],
          });
        } else if (item.incident_severity_name == "Serious") {
          ReportData.push({
            name: item.incident_severity_name,
            y: item.count,
            color: menuitems[0],
          });
        }
        else if (item.incident_severity_name == "Low") {
          ReportData.push({
            name: item.incident_severity_name,
            y: item.count,
            color: menuitems[4],
          });
        } else if (item.incident_severity_name == "Very Low") {
          ReportData.push({
            name: item.incident_severity_name,
            y: item.count,
            color: menuitems[5],
          });
        }
      });
      ReportsData[xx] = ReportData;
    });
    ReportsData.sort(function (a, b) {
      return b.y - a.y;
    });
    return ReportsData;
  };

  buildMonthVsSeverityReport = (allData) => {
    let incseverity = "",
      ReportData = [];
    let menuitems = [
      "#c00000",
      "#fc0201",
      "#f97d2a",
      "#fec000",
      "#fbe805",
      "#92d051",
    ];
    allData.sort(function (a, b) {
      if (b.incident_severity_name > a.incident_severity_name) return -1;
      if (b.incident_severity_name < a.incident_severity_name) return 1;
    });
    allData.map((item, idx) => {
      incseverity = this.props.labels.labels[item.incident_severity_name];
      if (item.incident_severity_name == "LBLSEVERITY1") {
        ReportData.push({
          name: incseverity,
          y: item.count,
          color: menuitems[0],
          lblname: item.incident_severity_name,
        });
      } else if (item.incident_severity_name == "LBLSEVERITY2") {
        ReportData.push({
          name: incseverity,
          y: item.count,
          color: menuitems[1],
          lblname: item.incident_severity_name,
        });
      } else if (item.incident_severity_name == "LBLSEVERITY3") {
        ReportData.push({
          name: incseverity,
          y: item.count,
          color: menuitems[2],
          lblname: item.incident_severity_name,
        });
      } else if (item.incident_severity_name == "LBLSEVERITY5") {
        ReportData.push({
          name: incseverity,
          y: item.count,
          color: menuitems[4],
          lblname: item.incident_severity_name,
        });
      } else if (item.incident_severity_name == "LBLSEVERITY6") {
        ReportData.push({
          name: incseverity,
          y: item.count,
          color: menuitems[5],
          lblname: item.incident_severity_name,
        });
      }
    });
    return ReportData;
  };

  buildIncidentTypeReport = (allData) => {
    let inctypename = "",
      ReportData = [];
    allData.map((item, idx) => {
      // if (item._id == "5f92917d2c7af547346d4488") {sa
      inctypename = this.props.labels.labels[item.incident_type_name];
      if (item.incident_type_name == "LBLITYPE1") {
        ReportData.push({
          name: inctypename,
          y: item.incident_counts,
          itemid: 1,
        });
        //} else if (item._id == "5f9291ba2c7af547346d448d") {env
      } else if (item.incident_type_name == "LBLITYPE2") {
        ReportData.push({
          name: inctypename,
          y: item.incident_counts,
          itemid: 2,
        });
        //} else if (item._id == "5f9291952c7af547346d448a") {sc
      } else if (item.incident_type_name == "LBLITYPE6") {
        ReportData.push({
          name: inctypename,
          y: item.incident_counts,
          itemid: 3,
        });
        //} else if (item._id == "5f92918b2c7af547346d4489") {fi
      } else if (item.incident_type_name == "LBLITYPE7") {
        ReportData.push({
          name: inctypename,
          y: item.incident_counts,
          itemid: 4,
        });
        // } else if (item._id == "5f9291a22c7af547346d448b") {qu
      } else if (item.incident_type_name == "LBLITYPE4") {
        ReportData.push({
          name: inctypename,
          y: item.incident_counts,
          itemid: 5,
        });
        //} else if (item._id == "5f9291b12c7af547346d448c") {erg
      } else if (item.incident_type_name == "LBLITYPE3") {
        ReportData.push({
          name: inctypename,
          y: item.incident_counts,
          itemid: 6,
        });
      } else if (item.incident_type_name == "LBLITYPE5") {
        ReportData.push({
          name: inctypename,
          y: item.incident_counts,
          itemid: 7,
        });
      } else if (item.incident_type_name == "LBLITYPE8") {
        ReportData.push({
          name: inctypename,
          y: item.incident_counts,
          itemid: 8,
        });
      }
    });
    ReportData.sort(function (a, b) {
      return b.y - a.y;
    });
    return ReportData;
  };
  buildStatesReport = (allData) => {
    let ReportData = [];
    allData.map((item, idx) => {
      if (item.incident_id == 1 && item.incident_counts > 0) {
        ReportData.push({
          name: item.incident_state_name,
          y: item.incident_counts,
          itemid: 1,
        });
      } else if (item.incident_id == 2 && item.incident_counts > 0) {
        ReportData.push({
          name: item.incident_state_name,
          y: item.incident_counts,
          itemid: 2,
        });
      } else if (item.incident_id == 3 && item.incident_counts > 0) {
        ReportData.push({
          name: item.incident_state_name,
          y: item.incident_counts,
          itemid: 2,
        });
      } else if (item.incident_id == 4 && item.incident_counts > 0) {
        ReportData.push({
          name: item.incident_state_name,
          y: item.incident_counts,
          itemid: 2,
        });
      }
    });
    return ReportData;
  };
  buildErrorsReport = (allData) => {
    let ReportData = [];
    allData.map((item, idx) => {
      if (item.incident_id == 1 && item.incident_counts > 0) {
        ReportData.push({
          name: item.incident_error_name,
          y: item.incident_counts,
          itemid: 1,
        });
      } else if (item.incident_id == 2 && item.incident_counts > 0) {
        ReportData.push({
          name: item.incident_error_name,
          y: item.incident_counts,
          itemid: 2,
        });
      } else if (item.incident_id == 3 && item.incident_counts > 0) {
        ReportData.push({
          name: item.incident_error_name,
          y: item.incident_counts,
          itemid: 2,
        });
      } else if (item.incident_id == 4 && item.incident_counts > 0) {
        ReportData.push({
          name: item.incident_error_name,
          y: item.incident_counts,
          itemid: 2,
        });
      }
    });
    return ReportData;
  };
  buildPlacesVsStatesReport = (allData, type) => {
    let LBLRUSHING, LBLFRUSTRATION, LBLFATIGUE, LBLCOMPLACENCY;
    let xx,
      colormenu = [],
      colormenu1 = ["#F796A6", "#FCD2A4", "#CDCAC6", "#8AC7C3"],
      colormenu2 = ["#F796A6", "#FCD2A4", "#CDCAC6", "#8AC7C3"],
      colormenu3 = ["#F796A6", "#FCD2A4", "#CDCAC6", "#8AC7C3"],
      colormenu4 = ["#F796A6", "#FCD2A4", "#CDCAC6", "#8AC7C3"],
      colormenu5 = {
        LBLRUSHING: "#F46980",
        LBLFRUSTRATION: "#FBBF7D",
        LBLFATIGUE: "#B7B3AE",
        LBLCOMPLACENCY: "#58AFA9",
      },
      totCount = 0,
      ReportData = [];
    if (type == 1) {
      colormenu = colormenu1;
    } else if (type == 2) {
      colormenu = colormenu2;
    } else if (type == 3) {
      colormenu = colormenu3;
    } else if (type == 4) {
      colormenu = colormenu4;
    }
    allData.map((item, idx) => {
      totCount = totCount + item.count;
    });
    allData.sort((a, b) => {
      if (a.state_name > b.state_name) {
        return -1;
      }
    });
    allData.map((item, idx) => {
      ReportData.push({
        name:
          item.state_name.indexOf("LBL") > -1
            ? this.props.labels.labels[item.state_name]
            : item.state_name,
        y: Math.round((item.count / totCount) * 100),
        itemid: idx,
        color: colormenu5[item.state_name],
      });
    });
    return ReportData;
  };
  buildPlacesVsErrorsReport = (allData, type) => {
    let LBLEYENOTONTASK, LBLMINDNOTTASK, LBLLINEOFFIRE, LBLBALANCETRACGRIP;
    let xx,
      colormenu = [],
      eyecolor = "",
      colormenu1 = ["#EC6B56", "#FFC154", "#47B39C", "#49A3E0"],
      colormenu2 = ["#EC6B56", "#FFC154", "#47B39C", "#49A3E0"],
      colormenu3 = ["#EC6B56", "#FFC154", "#47B39C", "#49A3E0"],
      colormenu4 = ["#EC6B56", "#FFC154", "#47B39C", "#49A3E0"],
      colormenu5 = {
        LBLEYENOTONTASK: "#67B7DC",
        LBLMINDNOTTASK: "#6771DC",
        LBLLINEOFFIRE: "#9B62D2",
        LBLBALANCETRACGRIP: "#DC67CE",
      },
      totCount = 0,
      ReportData = [];

    if (type == 1) {
      colormenu = colormenu1;
    } else if (type == 2) {
      colormenu = colormenu2;
    } else if (type == 3) {
      colormenu = colormenu3;
    } else if (type == 4) {
      colormenu = colormenu4;
    }
    allData.map((item, idx) => {
      return totCount = totCount + item.count;
    });
    allData.sort((a, b) => {
      if (a.error_name > b.error_name) {
        return -1;
      }
      return null;
    });
    allData.map((item, idx) => {
      eyecolor =
        item.error_name.indexOf("Eye") >= 0 ||
          item.error_name.indexOf("Inattention") >= 0
          ? "#0396B4"
          : "";
      ReportData.push({
        name:
          item.error_name.indexOf("LBL") > -1
            ? this.props.labels.labels[item.error_name]
            : item.error_name,
        y: Math.round((item.count / totCount) * 100),
        itemid: idx,
        color: colormenu5[item.error_name],
      });
    });
    ReportData.sort((a, b) => {
      if (a.state_name > b.state_name) {
        return -1;
      }
      return null;
    });
    // ReportData.sort(function (a, b) { return a.itemid - b.itemid });
    return ReportData;
  };
  buildWorkVsStatesReport = (allData) => {
    let xx,
      colormenu = ["#045903", "#017474", "#033481", "#A652A5"],
      totCount = 0,
      ReportData = [];
    allData.map((item, idx) => {
      totCount = totCount + item.count;
    });
    allData.map((item, idx) => {
      ReportData.push({
        name: item.state_name,
        y: Math.round(item.count / totCount) * 100,
        itemid: 1,
        color: colormenu[idx],
      });
    });
    ReportData.sort(function (a, b) {
      return a.itemid - b.itemid;
    });
    return ReportData;
  };

  showhidereports = (event) => {
    let showchart = true,
      currid = "reportprocess";
    //currid = event?event:currid;
    if (event.currentTarget) {
      currid = event.currentTarget.id;
    }

    currid = "reportprocess";
    if (currid == "reportprocess") {
      $("#reportHeaderSection").slideUp(1000);
      $("#reportSection").show();
      $(".ml-1 > .btn-group").hide();
      showchart = true;
    } else if (currid == "reportback") {
      $("#reportSection").slideDown(1000);
      $("#reportHeaderSection").show();
      showchart = false;
      $(".ml-1 > .btn-group").show();
    }
    this.setState({
      loadingchart: showchart,
      showchart: showchart,
    });
  };

  processReports = (rptType) => {
    let reporttype = rptType;
    //let {reporttype} = this.state;
    //reporttype = reporttype==8?9:reporttype;
    let reporttype1,
      locloadingchart = true,
      rcount,
      reportname = "",
      cntlid = "reportprocess",
      ddlReportType;
    let { reportsIncidents, filterval, reportsStates, reportsErrors } =
      this.state;
    let {
      LBLMONTHINCIDENTCOUNT,
      LBLPLACESVSSTATES,
      LBLPLACESVSERRORS,
      LBLMONTHSEVERITYCOUNT,
      LBLMONTHINCTYPECOUNT,
      LBLSTATESVSINCIDENT,
      LBLERRORSVSINCIDENT,
      LBLSEVERITYCOMPARE,
      LBLPLACESVSSEVERITYS,
      LBLINCTYPEPARETO,
    } = this.props.labels.labels;
    $("#reportSection").css({ marginTop: "80px" });
    if (reporttype) {
      this.getNearMissList(filterval, 1);
    }
    if (reporttype == 1) {
      reportname = LBLMONTHINCIDENTCOUNT;
      ddlReportType = reportsIncidents;
      rcount = 1;
      $("#reportSection").css({ marginTop: "-100px" });
      this.getNearMisMonthReport();
    } else if (reporttype == 2) {
      ddlReportType = reportsStates;
      reportname = LBLPLACESVSSTATES;
      rcount = 20;
      this.changeReportType(1);
      this.getPlacesVsStatesReport();
    } else if (reporttype == 3) {
      reportname = LBLPLACESVSERRORS;
      ddlReportType = reportsErrors;
      rcount = 40;
      this.changeReportType(1);
      this.getPlacesVsStatesReport();
    } else if (reporttype == 4) {
      this.processReportsTypes(2);
      reportname = LBLMONTHSEVERITYCOUNT;
    } else if (reporttype == 5) {
      this.processReportsTypes(3);
      reportname = LBLMONTHINCTYPECOUNT;
    } else if (reporttype == 6) {
      this.getStatesAndErrorsReport();
      reportname = LBLSTATESVSINCIDENT;
      ddlReportType = reportsErrors;
      rcount = 40;
    } else if (reporttype == 7) {
      this.getStatesAndErrorsReport();
      reportname = LBLERRORSVSINCIDENT;
      ddlReportType = reportsErrors;
      rcount = 40;
    } else if (reporttype == 8) {
      this.getIncidentTypeReport();
      reportname = LBLINCTYPEPARETO;
      ddlReportType = reportsErrors;
      rcount = 40;
    } else if (reporttype == 9) {
      this.filterseveritycompare();
      // locloadingchart = false;
      reportname = LBLSEVERITYCOMPARE;
      ddlReportType = reportsErrors;
      rcount = 40;
    } else if (reporttype == 10) {
      this.getPlacesVsSeveritysReport();
      // locloadingchart = false;
      reportname = LBLPLACESVSSEVERITYS;
      ddlReportType = reportsErrors;
      rcount = 40;
    } else {
      //this.showAlert("warning", this.props.labels.labels.LBLSELREPORT);
      return false;
    }

    this.setState({
      reportname: reportname,
      ddlreporttypes: ddlReportType,
      rcount: rcount,
      loadingchart: locloadingchart,
      reporttype1: "",
      showList: false,
      filteredChartData: [],
    });
  };
  getStatesAndErrorsReport = (evt) => {
    let filtervals,
      params = { year: "2020" };
    filtervals = this.state.filterval;
    filtervals = filtervals ? filtervals : [];
    let filtervalues = JSON.stringify(filtervals);
    let apiUrl =
      "nearmiss/report/incidentreports?params=" + encodeURI(filtervalues);
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getStatesAndErrorsReportHandler,
      filtervals
    );
  };
  getStatesAndErrorsReportHandler = (response) => {
    let xx,
      ReportData,
      allData = response.data.result,
      obj = {};
    let { reporttype } = this.state;
    // ReportData = this.setStatesAndErros(allData)
    if (reporttype == "6") {
      this.labelTranslation(allData.states_report, "incident_state_name");
      ReportData = this.buildStatesReport(allData.states_report);
    } else {
      this.labelTranslation(allData.errors_report, "incident_error_name");
      ReportData = this.buildErrorsReport(allData.errors_report);
    }
    let locdata = true;
    if (loaddata) {
      locdata = false;
      loaddata = false;
    }
    this.setState({
      dataPoints: ReportData,
      loadingchart: false,
      showchart: locdata,
      subchartloading: false,
      subchartloadingfull: false,
      subchartloadingtop: true,
    });
  };
  getIncidentTypeReport = () => {
    let filtervals,
      params = { year: "2020" };
    filtervals = this.state.filterval;
    filtervals = filtervals ? filtervals : [];
    let filtervalues = JSON.stringify(filtervals);
    let apiUrl =
      "nearmiss/report/incidenttypereports?params=" + encodeURI(filtervalues);
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getIncidentTypeReportHandler,
      filtervals
    );
  };
  getIncidentTypeReportHandler = (response) => {
    let xx,
      ReportData = [],
      allData = response.data.result,
      obj = {};
    let { filteredListItems } = this.props;
    let { reporttype } = this.state;
    //	allData = filteredListItems
    if (reporttype == 8) {
      ReportData = this.buildIncidentTypeReport(allData);
    } else {
      ReportData = this.buildIncidentTypeReport(allData);
    }

    let locdata = true;
    if (loaddata) {
      locdata = false;
      loaddata = false;
    }
    this.setState({
      dataPoints: ReportData,
      loadingchart: false,
      showchart: locdata,
      subchartloading: false,
      subchartloadingfull: false,
      subchartloadingtop: true,
    });
  };
  getNearMisMonthReport = (filterval) => {
    let filtervals,
      params = { year: "2021" };
    filtervals = this.state.filterval;
    filtervals = filtervals ? filtervals : [];
    let filtervalues = JSON.stringify(filtervals);
    let apiUrl =
      "nearmiss/report/monthly_report?params=" + encodeURI(filtervalues);
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getNearMisMonthReportHandler
      //,filtervals
    );
  };
  getNearMisMonthReportHandler = (response) => {
    let xx,
      locdata = true,
      ReportData = [],
      allData = response.data.result,
      obj = {};
    let { filteredListItems } = this.props;
    //	allData = filteredListItems

    ReportData = this.buildMonthVsCountReport(allData);
    if (loaddata) {
      locdata = false;
      loaddata = false;
    }
    this.setState({
      dataPoints: ReportData,
      loadingchart: false,
      showchart: locdata,
      subchartloading: false,
      subchartloadingfull: false,
      subchartloadingtop: true,
    });
  };
  getNearMisSeverityReport = (evt) => {
    let filtervals,
      params = { year: "2020" };
    filtervals = this.state.filterval;
    filtervals = filtervals ? filtervals : [];
    let filtervalues = JSON.stringify(filtervals);
    let apiUrl =
      "nearmiss/report/severity_report?params=" + encodeURI(filtervalues);
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getNearMisSeverityReportHandler,
      filtervals
    );
  };
  getNearMisSeverityReportHandler = (response) => {
    let xx,
      ReportData = [],
      allData = response.data.result,
      obj = {};
    let { filteredListItems } = this.props;
    //	allData = filteredListItems

    ReportData = this.buildMonthVsSeverityReport(allData);
    let locdata = true;
    if (loaddata) {
      locdata = false;
      loaddata = false;
    }
    this.setState({
      dataPoints: ReportData,
      loadingchart: false,
      showchart: locdata,
      subchartloading: false,
      subchartloadingfull: false,
      subchartloadingtop: true,
    });
  };
  getPlacesVsSeveritysReport = (evt) => {
    let apiUrl = "nearmiss/report/placesseveritysreports";
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "put",
      this.getPlacesVsSeveritysReportHandler,
      ""
    );
  };
  getPlacesVsSeveritysReportHandler = (response) => {
    let ReportData = [],
      allData = response.data.results.placevsseveritys,
      obj = {};
    let { filteredListItems } = this.props;
    //	allData = filteredListItems

    ReportData = this.buildPlacesVsSeveritysReport(allData);
    let locdata = true;
    if (loaddata) {
      locdata = false;
      loaddata = false;
    }
    this.setState({
      dataPoints: ReportData,
      loadingchart: false,
      showchart: locdata,
      subchartloading: false,
      subchartloadingfull: false,
      subchartloadingtop: true,
    });
  };
  getPlacesVsStatesReport = (evt) => {
    //let apiUrl = "nearmiss";
    let filtervals,
      params = { year: "2020" };
    filtervals = this.state.filterval;
    filtervals = filtervals ? filtervals : [];
    let filtervalues = JSON.stringify(filtervals);
    let apiUrl =
      "nearmiss/report/incidentplacesreports?params=" + encodeURI(filtervalues);
    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.getPlacesVsStatesReportHandler,
      filtervals
    );
  };
  getPlacesVsStatesReportHandler = (response) => {
    this.loadReportTypes();
    let xx,
      ReportData = [],
      allData,
      allErrorsStates,
      obj = {};
    let ReportData1, ReportData2, ReportData3, ReportData4;
    let { filteredListItems } = this.props;
    let { dataStatesErrors, dataStates, dataErrors, rcount } = this.state;

    allErrorsStates = response.data.result;
    dataStatesErrors = allErrorsStates;
    dataStates = allErrorsStates.placevsstates;
    dataErrors = allErrorsStates.placevserros;
    if (rcount == 20) {
      allData = allErrorsStates.placevsstates;
      dataStates = allData;
    } else if (rcount == 40) {
      allData = allErrorsStates.placevserros;
      dataErrors = allData;
    }
    // }
    let reportname1, reportname2, reportname3, reportname4;
    let workStates,
      workErrors,
      homeStates,
      homeErrors,
      publicStates,
      publicErrors,
      driveStates,
      driveErrors;
    if (rcount == 20) {
      allData.map((item, idx) => {
        if (item.incident_place_id == "incident_place_01") {
          workStates = item.incident_states;
        } else if (item.incident_place_id == "incident_place_02") {
          homeStates = item.incident_states;
        } else if (item.incident_place_id == "incident_place_03") {
          publicStates = item.incident_states;
        } else if (item.incident_place_id == "incident_place_04") {
          driveStates = item.incident_states;
        }
        return null;
      });
      workStates = workStates ? workStates : [];
      homeStates = homeStates ? homeStates : [];
      publicStates = publicStates ? publicStates : [];
      driveStates = driveStates ? driveStates : [];
      ReportData1 = this.buildPlacesVsStatesReport(workStates, 1);
      ReportData2 = this.buildPlacesVsStatesReport(homeStates, 2);
      ReportData3 = this.buildPlacesVsStatesReport(publicStates, 3);
      ReportData4 = this.buildPlacesVsStatesReport(driveStates, 4);
      let {
        LBLWORKVSSTATES,
        LBLHOMEKVSSTATES,
        LBLPUBLICKVSSTATES,
        LBLTRANSKVSSTATES,
      } = this.props.labels.labels;
      reportname1 = LBLWORKVSSTATES;
      reportname2 = LBLHOMEKVSSTATES;
      reportname3 = LBLTRANSKVSSTATES;
      reportname4 = LBLPUBLICKVSSTATES;
    } else {
      allData.map((item, idx) => {
        if (item.incident_place_id == "incident_place_01") {
          workErrors = item.incident_errors;
        } else if (item.incident_place_id == "incident_place_02") {
          homeErrors = item.incident_errors;
        } else if (item.incident_place_id == "incident_place_03") {
          publicErrors = item.incident_errors;
        } else if (item.incident_place_id == "incident_place_04") {
          driveErrors = item.incident_errors;
        }
      });
      workErrors = workErrors ? workErrors : [];
      homeErrors = homeErrors ? homeErrors : [];
      publicErrors = publicErrors ? publicErrors : [];
      driveErrors = driveErrors ? driveErrors : [];
      ReportData1 = this.buildPlacesVsErrorsReport(workErrors, 1);
      ReportData2 = this.buildPlacesVsErrorsReport(homeErrors, 2);
      ReportData3 = this.buildPlacesVsErrorsReport(publicErrors, 3);
      ReportData4 = this.buildPlacesVsErrorsReport(driveErrors, 4);
      let {
        LBLWORKVSERRORS,
        LBLHOMEVSERRORS,
        LBLPUBLICVSERRORS,
        LBTRANSVSERRORS,
      } = this.props.labels.labels;
      reportname1 = LBLWORKVSERRORS;
      reportname2 = LBLHOMEVSERRORS;
      reportname3 = LBTRANSVSERRORS;
      reportname4 = LBLPUBLICVSERRORS;
    }

    let data = [],
      data1 = [],
      data2 = [],
      data3 = [],
      data4 = [],
      datapoints = [],
      objData = {};

    objData = {
      type: "pie",
      startAngle: 270,
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 14,
      toolTipContent: "<b>{label}</b>: {y}%",
      percentFormatString: "#",
      indexLabel: "{label} - {y}%",
    };
    objData.dataPoints = ReportData;
    data.push(objData);
    data1.push(objData);
    data2.push(objData);
    data3.push(objData);
    data4.push(objData);
    this.setState({
      subchartloadingfull: true,
      subchartloadingtop: false,
      reportname1: reportname1,
      reportname2: reportname2,
      reportname3: reportname3,
      reportname4: reportname4,
      data: data,
      data1: data1,
      data2: data2,
      data3: data3,
      data4: data4,
      dataPoints: ReportData,
      dataPoints1: ReportData1,
      dataPoints2: ReportData2,
      dataPoints3: ReportData3,
      dataPoints4: ReportData4,
      dataStatesErrors: dataStatesErrors,
      dataStates: dataStates,
      dataErrors: dataErrors,

      workStates: workStates,
      homeStates: homeStates,
      publicStates: publicStates,
      driveStates: driveStates,

      workErrors: workErrors,
      homeErrors: homeErrors,
      publicErrors: publicErrors,
      driveErrors: driveErrors,

      loadingchart: false,
      showchart: true,
      subchartloading: false,
    });
  };

  visitorsChartDrilldownHandler = () => {
    alert(11);
  };
  setFilterFields = (id, conditions) => {
    let xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportfunction) {
        xobj[conditions[i].name].exportfunction = conditions[i].exportfunction;
      }
    }
    if (id === "Near") {
      //this.setState({
      objFilterData.availableRYS_D1Fields = xobj;
    }
  };
  applyFilters_conditions = (conditions) => {
    let locfilter = { singleItems: [], multiItems: [] };
    for (var i = 0; i < Object.keys(conditions).length; i++) {
      if (Object.keys(conditions)[i] == "Incident Type") {
        locfilter.singleItems["Incident Type"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      } else if (Object.keys(conditions)[i] == "Incident Source") {
        locfilter.singleItems["Incident Source"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      } else if (Object.keys(conditions)[i] == "Severity") {
        locfilter.singleItems["Severity"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      } else if (Object.keys(conditions)[i] == "Status") {
        locfilter.singleItems["Status"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      } else if (Object.keys(conditions)[i] == "Location") {
        locfilter.multiItems = {
          GP_1: conditions[Object.keys(conditions)[i]].condition.value,
        };
      } else if (Object.keys(conditions)[i] == "Date") {
        locfilter["dateItems"] =
          conditions[Object.keys(conditions)[i]].condition.value;
      }
    }
    loadfilter = false;
    this.bindfilters(conditions);
    this.setState({ allFilterValues: locfilter, showNode: true });
    $("#selItem_spanless_Date")
      .closest("div")
      .parent()
      .parent()
      //  .parent()
      .show();
  };
  bindfilters = (locfilter) => {
    let orgfilter = locfilter,
      filterval = {};
    let datefilter = {},
      inctype = {},
      incsource = {},
      location = {},
      department = {},
      severity = {},
      status = {};
    let status_condition = [],
      statuslen;
    let inc_type = [],
      inctypelen;
    let inc_source = [],
      incsourcelen;
    let loc_det = [],
      locationlen;
    let dep_det = [],
      departmentlen;
    let sev_det = [],
      severitylen;
    let lblsource = this.props.labels.labels.LBLERRORSOURCE;
    let lblincidenttypes = this.props.labels.labels.LBLINCTYPES;
    let lblseverity = this.props.labels.labels.LBLSEVERITY;
    let lbllocation = this.props.labels.labels.LBLLOCATIONS;
    let lbldepartment = this.props.labels.labels.LBLDEPARTMENT;
    let lblstatus = this.props.labels.labels.LBLSTATUS;
    inctypelen = orgfilter[lblincidenttypes].condition.value.length;
    if (inctypelen > 0) {
      orgfilter[lblincidenttypes].condition.value.map((itm) =>
        inc_type.push(itm.id)
      );
    }

    lblsource = lblsource ? lblsource : "Source of Error";
    incsourcelen = orgfilter[lblsource].condition.value.length;

    if (incsourcelen > 0) {
      orgfilter[lblsource].condition.value.map((itm) =>
        inc_source.push(itm.id)
      );
    }
    severitylen = orgfilter[lblseverity].condition.value.length;
    if (severitylen > 0) {
      orgfilter[lblseverity].condition.value.map((itm) => sev_det.push(itm.id));
    }
    locationlen = orgfilter[lbllocation].condition.value.length;
    if (locationlen > 0) {
      orgfilter[lbllocation].condition.value.map((itm) => loc_det.push(itm.id));
    }

    departmentlen = orgfilter[lbldepartment].condition.value.length;
    if (departmentlen > 0) {
      orgfilter[lbldepartment].condition.value.map((itm) =>
        dep_det.push(itm.id)
      );
    }

    statuslen = orgfilter[lblstatus].condition.value.length;
    if (statuslen > 1 || statuslen == 0) {
      status_condition = "";
    } else if (statuslen == 1) {
      status_condition =
        orgfilter[lblstatus].condition.value[0].id == 1 ? true : false;
    }
    let lbldate = this.props.labels.labels.LBLDATE;
    datefilter["operator"] =
      orgfilter[lbldate ? lbldate : "Date"].condition.operator;
    datefilter["value"] = orgfilter[lbldate ? lbldate : "Date"].condition.value;

    let year, month, date, maxdate;
    if (
      orgfilter[this.props.labels.labels.LBLDATE].condition.value.length > 1
    ) {
      maxdate = new Date(
        orgfilter[this.props.labels.labels.LBLDATE].condition.value[1]
      );
      //maxdate = orgfilter.Date.condition.value[1]
      year = maxdate.getFullYear();
      month = maxdate.getMonth();
      date = maxdate.getDate();
      orgfilter[this.props.labels.labels.LBLDATE].condition.value[1] = new Date(
        year,
        month,
        date,
        23,
        59,
        59
      );
    }
    inctype["operator"] = "INCIDENT_TYPE";
    inctype["value"] = inc_type;

    incsource["operator"] = "INCIDENT_SOURCE";
    incsource["value"] = inc_source;

    //severity["operator"]=orgfilter.Severity.condition.operator;
    severity["operator"] = "SEVERITY";
    severity["value"] = sev_det;

    //location["operator"]=orgfilter.Location.condition.operator;
    location["operator"] = "LOCATION";
    location["value"] = loc_det;

    //department["operator"]=orgfilter.Location.condition.operator;
    department["operator"] = "DEPARTMENT";
    department["value"] = dep_det;

    //status["operator"]=orgfilter.Status.condition.operator;
    status["operator"] = "STATUS";
    status["value"] = status_condition;

    filterval = {
      date: datefilter,
      incident_types: inctype,
      incident_sources: incsource,
      location: location,
      department: department,
      severity: severity,
      status: status,
    };

    this.setState({ filterval: filterval });
    //this.getNearMissList(filterval, 1);
  };
  applyFilterCondition = (orgresult, data) => {
    if (objFilterData.availableRYS_D1Fields) {
      let xobj = objFilterData.availableRYS_D1Fields;
      let result = [];
      if (!orgresult) {
        this.applyFilters_conditions(xobj);
      }
    }
  };
  onFilterCancel = (id, conditions) => { };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions) => {
    //this.setFilterFields("Near", conditions);
    let data = { type: "web", columns: objFilterData.availableRYS_D1Fields };
    data.filtercolumns = conditions;

    //let xobj = {};
    this.setFilterFields("Near", conditions);
    let index = 0;
    if (id === "Near") {
      let xcondition = conditions[index].filter.condition;
      if (conditions[index].filter.type === "date") {
        let dt1, dt2;
        if (typeof xcondition.value[0] == "string") {
          let arrdt = xcondition.value[0].split("T")[0].split("-");
          xcondition.value[0] = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2]
          );
        }
        if (xcondition.value[1] && typeof xcondition.value[1] == "string") {
          let arrdt = xcondition.value[1].split("T")[0].split("-");
          xcondition.value[1] = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2]
          );
        }
        if (xcondition.value[0] && xcondition.value[1]) {
          dt1 = new Date(xcondition.value[0].getTime());
          dt2 = new Date(xcondition.value[1].getTime());
        } else {
          dt1 = new Date(xcondition.value.getTime());
          dt2 = new Date(xcondition.value.getTime());
        }

        data.date1 = new Date(
          dt1.getFullYear(),
          dt1.getMonth(),
          dt1.getDate(),
          12,
          0,
          0,
          0
        );
        data.date2 = new Date(
          dt2.getFullYear(),
          dt2.getMonth(),
          dt2.getDate(),
          12,
          0,
          0,
          1
        );
        data.sdate1 = this.getDateFormat(data.date1);
        data.sdate2 = this.getDateFormat(data.date2);
        data.offsetTime = this.getOffsetTime();
        this.getRYS_D1_List(data);
      } else {
        this.applyFilterCondition();
      }
    }
  };
  loadTableData = (e) => {
    let offset = $("#nearmissListSection").offset();
    window.scrollTo(offset.left, offset.top - 100);
    let chartSubName,
      localchartname = "",
      monthmenu = [],
      stateflag = false,
      listDataLocal,
      filteredList,
      chartType,
      chartName,
      chartSection,
      chartFilter;
    chartName = e.point.series.chart.options.chart.chartName;
    chartType = e.point.series.chart.options.chart.type;
    chartFilter = e.point.options.name;
    chartFilter =
      chartName == "Serious" ? e.point.options.states : e.point.options.name;
    chartSubName = e.point.series.chart.options.chart.chartSubName;
    //monthmenu=["January","February","March","April","May","June","July","August","September","October","November","December"]
    monthmenu = [
      "LBLJAN",
      "LBLFEB",
      "LBLMAR",
      "LBLAPR",
      "LBLMAY",
      "LBLJUN",
      "LBLJUL",
      "LBLAUG",
      "LBLSEP",
      "LBLOCT",
      "LBLNOV",
      "LBLDEC",
    ];
    let { listDataReport, dateformat } = this.state;
    listDataLocal = listDataReport;
    if (chartName == "Severity") {
      filteredList = listDataLocal.filter((itm, x) => {
        return itm.incident_severity == chartFilter;
      });
    } else if (chartName == "Serious") {
      filteredList = listDataLocal.filter((itm, x) => {
        return itm.incident_severity == chartFilter;
      });
    } else if (chartName == "MVC") {
      let currdata,
        event_date = new Date(),
        currmonth,
        curryear = "20";
      filteredList = listDataLocal.filter((itm, x) => {
        currdata = chartFilter.split("'");
        currmonth = currdata[0].toString().trim();
        curryear = currdata[1].toString().trim();
        event_date = itm.event_date;
        if (dateformat == "DD/MM/YYYY") {
          event_date = this.formatChange(itm.event_date);
        }
        return (
          new Date(event_date).getMonth() ==
          monthmenu.findIndex((x) => objLabels[x] == currmonth) &&
          new Date(event_date).getFullYear().toString().substring(2, 4) ==
          curryear
        );
      });
    } else if (chartName == "Incident Type") {
      filteredList = listDataLocal.filter((itm, x) => {
        return itm.incident_type == chartFilter;
      });
    } else if (chartName == "IVS") {
      let stateflag = false,
        incstatename = "";
      filteredList = listDataLocal.filter((item, x) => {
        stateflag = false;
        item.incident_states.map((itm, i) => {
          incstatename = this.props.labels.labels[itm.incident_state_name];
          if (incstatename == chartFilter) stateflag = true;
        });
        return stateflag;
      });
    } else if (chartName == "IVE") {
      let errorflag = false,
        incerrorname = "";
      filteredList = listDataLocal.filter((item, x) => {
        errorflag = false;
        item.incident_errors.map((itm, i) => {
          incerrorname = this.props.labels.labels[itm.incident_error_name];
          if (incerrorname == chartFilter) errorflag = true;
        });
        return errorflag;
      });
    } else if (chartName == "PARETO") {
      filteredList = listDataLocal.filter((itm, x) => {
        return itm.incident_type == chartFilter;
      });
    } else if (
      chartName == "LBLPLACE1" ||
      chartName == "LBLPLACE2" ||
      chartName == "LBLPLACE3" ||
      chartName == "LBLPLACE4"
    ) {
      let place_labels = {
        Work: "LBLPLACE1",
        Home: "LBLPLACE2",
        Driving: "LBLPLACE3",
        PT: "LBLPLACE4",
      };
      filteredList = listDataLocal.filter((item, x) => {
        stateflag = false;
        localchartname = item.place;
        //  chartName = place_labels[chartName]
        if (chartSubName == "States") {
          let incstatename;
          item.incident_states.map((itm, i) => {
            incstatename = this.props.labels.labels[itm.incident_state_name];
            if (incstatename == chartFilter && localchartname == chartName)
              stateflag = true;
          });
        } else {
          let incerrorname;
          item.incident_errors.map((itm, i) => {
            incerrorname = this.props.labels.labels[itm.incident_error_name];
            if (incerrorname == chartFilter && localchartname == chartName)
              stateflag = true;
          });
        }
        return stateflag;
      });
    }

    this.setState({
      showList: true,
      chartFilter: chartFilter,
      filteredChartData: filteredList,
    });
    /******************************/
  };
  formatChange(date) {
    let locDate = date,
      arrDate;
    if (date != null) {
      arrDate = date.split("/");
      locDate = arrDate[1] + "/" + arrDate[0] + "/" + arrDate[2];
    }
    return locDate;
  }
  getSeverityCompareHandler = (response) => {
    let xx,
      ReportData = [],
      allData = response,
      obj = {};
    let { filteredListItems } = this.props;

    ReportData = this.buildSeverityCompareReport(allData);
    let locdata = true;
    if (loaddata) {
      locdata = false;
      loaddata = false;
    }
    this.setState({
      dataPoints: ReportData,
      loadingchart: false,
      showchart: locdata,
      subchartloading: false,
      subchartloadingfull: false,
      subchartloadingtop: true,
    });
  };
  buildSeverityCompareReport = (allData) => {
    let xx = [],
      monthname = "",
      ReportData = [],
      ReportData1 = [],
      ReportData2 = [],
      ReportData3 = [],
      ReportData4 = [];
    //let monthmenu=["January","February","March","April","May","June","July","August","September","October","November","December"]
    let monthmenu = [
      "LBLJAN",
      "LBLFEB",
      "LBLMAR",
      "LBLAPR",
      "LBLMAY",
      "LBLJUN",
      "LBLJUL",
      "LBLAUG",
      "LBLSEP",
      "LBLOCT",
      "LBLNOV",
      "LBLDEC",
    ];
    let reportsvals = [];
    let max_num = 1;
    for (var key in allData) {
      allData[key].map((item, idx) => {
        ReportData2.push(
          objLabels[item.month] + "' " + item.year.toString().substring(2, 4)
        );
        ReportData3.push({
          month:
            objLabels[item.month] + "' " + item.year.toString().substring(2, 4),
          month_num: item.month_num,
        });
      });
    }
    ReportData2 = [...new Set(ReportData2)];
    let idx = -1;
    ReportData2.map((ds, s) => {
      idx = ReportData3.findIndex((im, m) => ds == im.month);
      ReportData4.push(ReportData3[idx]);
    });
    ReportData4.sort(function (a, b) {
      return a.month_num - b.month_num;
    });
    ReportData4.sort(function (a, b) {
      return (
        a.month.toString().split("' ")[1].trim() -
        b.month.toString().split("' ")[1].trim()
      );
    });
    for (var key in allData) {
      ReportData1 = [];
      for (let j = 0; j < ReportData4.length; j++) {
        ReportData1.push({ x: j + 1, y: 0, name: ReportData4[j].month });
      }
      let curryear1,
        curryear = "";
      allData[key].map((item, idx) => {
        ReportData1.map((m, i) => {
          curryear = item.year.toString().substring(2, 4);
          curryear1 = m.name.split("'")[1].trim();
          if (
            m.name.indexOf(objLabels[item.month]) >= 0 &&
            curryear == curryear1
          ) {
            ReportData1[i].y = item.incident_counts;
          }
          return null;
        });
      });
      ReportData.push(ReportData1);
    }
    return ReportData;
  };
  setincidenttypecount = (filteredval, mon) => {
    let finalobj = [],
      obj = {};
    let Safety = 0,
      Environmental = 0,
      Ergonomics = 0,
      Quality = 0,
      Production = 0,
      SupplyChain = 0,
      Finance = 0,
      Others = 0;
    let arr = [
      "Safety",
      "Environmental",
      "Ergonomics",
      "Quality",
      "Production",
      "Supply Chain",
      "Finance",
      "Others",
    ];
    filteredval.map((item, c) => {
      if (arr[0] == item.incident_type) {
        Safety++;
      } else if (arr[1] == item.incident_type) {
        Environmental++;
      } else if (arr[2] == item.incident_type) {
        Ergonomics++;
      } else if (arr[3] == item.incident_type) {
        Quality++;
      } else if (arr[4] == item.incident_type) {
        Production++;
      } else if (arr[5] == item.incident_type) {
        SupplyChain++;
      } else if (arr[6] == item.incident_type) {
        Finance++;
      } else if (arr[7] == item.incident_type) {
        Others++;
      }
      return null;
    });
    obj["Safety"] = Safety;
    obj["Environmental"] = Environmental;
    obj["Ergonomics"] = Ergonomics;
    obj["Quality"] = Quality;
    obj["Production"] = Production;
    obj["Supply Chain"] = SupplyChain;
    obj["Finance"] = Finance;
    obj["Others"] = Others;

    if (mon == 0) {
      obj["month"] = "January";
      finalobj.push(obj);
    } else if (mon == 1) {
      obj["month"] = "February";
      finalobj.push(obj);
    } else if (mon == 2) {
      obj["month"] = "March";
      finalobj.push(obj);
    } else if (mon == 3) {
      obj["month"] = "April";
      finalobj.push(obj);
    } else if (mon == 4) {
      obj["month"] = "May";
      finalobj.push(obj);
    } else if (mon == 5) {
      obj["month"] = "June";
      finalobj.push(obj);
    } else if (mon == 6) {
      obj["month"] = "July";
      finalobj.push(obj);
    } else if (mon == 7) {
      obj["month"] = "August";
      finalobj.push(obj);
    } else if (mon == 8) {
      obj["month"] = "September";
      finalobj.push(obj);
    } else if (mon == 9) {
      obj["month"] = "October";
      finalobj.push(obj);
    } else if (mon == 10) {
      obj["month"] = "November";
      finalobj.push(obj);
    } else if (mon == 11) {
      obj["month"] = "December";
      finalobj.push(obj);
    }
    return finalobj;
  };
  setseveritycount = (filteredval, mon) => {
    let finalobj = [],
      obj = {};
    let Serious = 0,
      Major = 0,
      Minor = 0,
      FirstAid = 0,
      Low = 0,
      VeryLow = 0
    let arr = ["Serious", "Major", "Minor", "Low", "Very Low"]; //"First Aid",
    filteredval.map((item, c) => {
      if (arr[0] == item.incident_severity) {
        Serious++;
      } else if (arr[1] == item.incident_severity) {
        Major++;
      } else if (arr[2] == item.incident_severity) {
        Minor++;
      } else if (arr[3] == item.incident_severity) {
        FirstAid++;
      } else if (arr[4] == item.incident_severity) {
        Low++;
      } else if (arr[5] == item.incident_severity) {
        VeryLow++;
      }
      return null;
    });
    obj["Serious"] = Serious;
    obj["Major"] = Major;
    obj["Minor"] = Minor;
    // obj["FirstAid"] = FirstAid;
    obj["Low"] = Low;
    obj["VeryLow"] = VeryLow;

    if (mon == 0) {
      obj["month"] = "January";
      finalobj.push(obj);
    } else if (mon == 1) {
      obj["month"] = "February";
      finalobj.push(obj);
    } else if (mon == 2) {
      obj["month"] = "March";
      finalobj.push(obj);
    } else if (mon == 3) {
      obj["month"] = "April";
      finalobj.push(obj);
    } else if (mon == 4) {
      obj["month"] = "May";
      finalobj.push(obj);
    } else if (mon == 5) {
      obj["month"] = "June";
      finalobj.push(obj);
    } else if (mon == 6) {
      obj["month"] = "July";
      finalobj.push(obj);
    } else if (mon == 7) {
      obj["month"] = "August";
      finalobj.push(obj);
    } else if (mon == 8) {
      obj["month"] = "September";
      finalobj.push(obj);
    } else if (mon == 9) {
      obj["month"] = "October";
      finalobj.push(obj);
    } else if (mon == 10) {
      obj["month"] = "November";
      finalobj.push(obj);
    } else if (mon == 11) {
      obj["month"] = "December";
      finalobj.push(obj);
    }
    return finalobj;
  };

  filterseveritycompare = () => {
    let filtervals;
    filtervals = this.state.filterval;
    filtervals = filtervals ? filtervals : [];
    let filtervalues = JSON.stringify(filtervals);
    let apiUrl =
      "nearmiss/report/monthly_severity_report?params=" +
      encodeURI(filtervalues);

    NearMissService.nearmissAPIServicesCall(
      apiUrl,
      "get",
      this.filterseveritycomparehandler,
      filtervals
    );

    //  this.filterseveritycomparehandler();
  };

  filterseveritycomparehandler = (response) => {
    let { listData } = this.state;
    let arrdate,
      finalData = [],
      finalval,
      filteredval,
      localData = listData;
    for (var i = 0; i < 12; i++) {
      filteredval = localData.filter((itm) => {
        arrdate = itm.event_date.split("/");
        // return (i == arrdate[0] && arrdate[2]=="2020");
        return i == new Date(itm.event_date).getMonth();
      });
      //finalval = this.setincidenttypecount(filteredval, i);
      finalval = this.setseveritycount(filteredval, i);

      finalData.push(finalval[0]);
    }

    let finalData1 = response.data.result;
    //this.buildSeverityCompareReport11(finalData1)

    this.getSeverityCompareHandler(finalData1);
    // return finalData;
  };
  handleShowCriteria = () => {
    let scriteria, hcriteria;
    if ($(".cls_container_selection").css("display") == "block") {
      scriteria = this.props.labels.labels["LBLSHOWCRITERIA"];
      $(".cls_select_show").html(scriteria);
      // $(".cls_select_show_par").css({"margin":"-24px -4px"});
      $(".cls_select_show_par")
        .css({ left: "30px" })
        .animate({ left: "-4px" }, "slow");
      $(".cls_select_show").css({ "text-decoration": "underline" });
    } else {
      hcriteria = this.props.labels.labels["LBLHIDERITERIA"];
      $(".cls_select_show").html(hcriteria);
      // $(".cls_select_show_par").css({"margin":"-24px 26px"});
      $(".cls_select_show_par")
        .css({ left: "-4px" })
        .animate({ left: "30px" }, "slow");
      $(".cls_select_show").css({ "text-decoration": "auto" });
    }
    $(".cls_container_selection").slideToggle(300);
  };
  createPareto() {
    var dps = [];
    var chart = this.chart;
    var yValue,
      yTotal = 0,
      yPercent = 0;
    if (this.chart) {
      for (var i = 0; i < chart.data[0].dataPoints.length; i++)
        yTotal += chart.data[0].dataPoints[i].y;
      for (var i = 0; i < chart.data[0].dataPoints.length; i++) {
        yValue = chart.data[0].dataPoints[i].y;
        yPercent += (yValue / yTotal) * 100;
        dps.push({ name: chart.data[0].dataPoints[i].label, y: yPercent });
      }
      chart.addTo("data", {
        type: "line",
        yValueFormatString: '0.##"%"',
        dataPoints: dps,
      });
      chart.data[1].set("axisYType", "secondary", false);
      chart.axisY[0].set("maximum", Math.round(yTotal / 20) * 20);
      chart.axisY2[0].set("maximum", 100);
    }
  }
  changeReportType = (type) => {
    let loccurrChartType = "pie";
    if (type == 1) {
      loccurrChartType = "pie";
      $("#barchart").removeClass("cls_selected");
      $("#piechart").addClass("cls_selected");
    } else if (type == 2) {
      loccurrChartType = "column";
      $("#barchart").addClass("cls_selected");
      $("#piechart").removeClass("cls_selected");
    }
    let prevType = type == 1 ? "pie" : "column";
    this.setState({
      currChartType: loccurrChartType,
      changedChart: true,
      prevChartType: prevType,
    });
  };
  getOption = (data) => {
    let chartName = "IVS";
    let {
      LBLRUSHING,
      LBLFRUSTRATION,
      LBLFATIGUE,
      LBLCOMPLACENCY,
      LBLSTATES,
      LBLERRORS,
      LBLEYENOTONTASK,
      LBLMINDNOTTASK,
      LBLLINEOFFIRE,
      LBLBALANCETRACGRIP,
    } = this.props.labels.labels;
    let xAxisLabels = [LBLRUSHING, LBLFRUSTRATION, LBLFATIGUE, LBLCOMPLACENCY];
    let xValue = LBLSTATES,
      blnLegend = true;
    //let {  } =  this.props.labels.labels;
    blnLegend = this.state.currChartType == "pie" ? true : false;
    if (this.state.reporttype == 2) {
      chartName = "IVE";
      xValue = LBLERRORS;
      xAxisLabels = [
        LBLEYENOTONTASK,
        LBLMINDNOTTASK,
        LBLLINEOFFIRE,
        LBLBALANCETRACGRIP,
      ];
    }

    let objData = [];
    let categories = []; //[LBLCOMPLETED,LBLINPROGRESS,LBLNOTSTARTED];
    let chart_data = {
      chart: {
        type: "column",
        chartName: chartName,
        width: 400,
        height: 300,
        style: {
          fontFamily: "'Roboto', sans-serif",
        },
      },
      legend: {
        enabled: true,
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -10,
        y: 100,
        itemStyle: {
          fontWeight: "450",
          fontSize: "11px",
          border: "1px solid black",
          fontFamily: "'Roboto', sans-serif",
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "{point.name} : <strong>{point.y}%</strong>",
      },
      title: {
        text: null,
        align: "left",
      },
      subtitle: {
        text: null,
        align: "left",
      },
      plotOptions: {
        colors: [],
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            //colorByPoint: true
            format: "{point.y: .0f}%",
            style: {
              fontSize: 13,
            },
          },
        },
        series: {
          stacking: "normal",
          pointWidth: 55,
          dataLabels: {
            enabled: true,
            style: {
              fontWeight: "bold",
              color: "#FFF",
            },
          },
        },
        pie: {
          size: "60%",
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            style: {
              shadow: true,
              distance: -40,
              enabled: true,
              textOverflow: "ellipsis",
              x: -40,
              format: "{point.y: .0f} %",
            },
          },
        },
      },
      xAxis: {
        categories: xAxisLabels,
        title: {
          text: xValue,
          style: {
            fontWeight: "450",
            fontSize: 15,
          },
        },
        plotLines: [
          {
            width: 2,
          },
        ],
      },
      yAxis: {
        tickInterval: 10,
        title: {
          text: "",
          style: {
            fontWeight: "450",
            fontSize: 15,
          },
        },
        plotLines: [
          {
            value: -1,
            width: 2,
          },
        ],
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: "450",
          },
        },
      },
      series: [
        {
          cursor: "pointer",
          colorByPoint: true,
          showInLegend: blnLegend,
          data: objData,
          events: {
            click: this.loadTableData,
          },
        },
      ],
    };
    return chart_data;
  };

  render() {
    let {
      LBLINCIDENTID,
      LBLSTATUS,
      LBLPLACE1,
      LBLPLACE2,
      LBLPLACE3,
      LBLPLACE4,
      LBLOF,
      LBLMONTHINCIDENTCOUNT,
      LBLROWSPERPAGE,
      LBLNORECORDS,
      LBLALL,
      LBLDEPARTMENT,
      LBLINCTYPES,
      LBLERRORSOURCE,
      LBLSEVERITY,
      LBLDATE,
      LBLNMINCREPORT,
      LBLLOCATIONS,
      LBLOPEN,
      LBLCLOSED,
      LBLINCIDENTS,
      LBLINCTITLE,
      LBLEXPORTTOCSV,
      LBLEXPORTTOEXCEL,
      LBLEXPORT,
      LBLINCCOUNT,
      LBLSTATES,
      LBLERRORS,
      LBLHOME,
      LBLWORK,
      LBLPUBLICTRANS,
      LBLDRIVING,
      LBLMONTHS,
      LBLRUSHING,
      LBLFRUSTRATION,
      LBLFATIGUE,
      LBLCOMPLACENCY,
      LBLSELECTREPORT,
      LBLSELPROCREPORT,
    } = this.props.labels.labels;
    LBLSELECTREPORT = LBLSELECTREPORT ? LBLSELECTREPORT : "Select Report";
    LBLERRORSOURCE = LBLERRORSOURCE ? LBLERRORSOURCE : "Source of Error";
    let {
      searchValue,
      allFilterValues,
      listData,
      reportname,
      showList,
      filteredChartData,
    } = this.state;
    let allGroups = [],
      singleAll = [],
      allGroupIds = [],
      allFilters = [],
      dateAll,
      dateFilter,
      userFilter,
      statusFilter = "";
    // allFilters = JSON.parse(allFilterValues)
    if (Object.keys(allFilterValues).length > 0) {
      allFilters = allFilterValues.multiItems ? allFilterValues.multiItems : [];
      dateAll = allFilterValues.dateItems ? allFilterValues.dateItems : [];
      singleAll = allFilterValues.singleItems
        ? allFilterValues.singleItems
        : [];
      statusFilter = singleAll.status ? singleAll.status : "";
      userFilter = singleAll.users ? singleAll.users : "";
      userFilter = userFilter ? userFilter : "";
      // dateFilter=dateAll.values?dateAll.values:[]
      dateFilter = dateAll;

      let objkeys = Object.keys(allFilters);

      for (var m = 0; m < objkeys.length; m++) {
        allGroups =
          allFilters[objkeys[m]] && allFilters[objkeys[m]].length > 0
            ? allGroups.concat(allFilters[objkeys[m]])
            : allGroups.concat({});
      }

      allGroups.map((item) => {
        if (item.name) {
          allGroupIds.push(item.name);
        }
        return null;
      });
    }

    let filteredListItems = [];
    if (showList) {
      listData = filteredChartData;
    }
    if (listData && Object.keys(listData).length > 0) {
      filteredListItems = listData.filter((item) => {
        return (
          (item.incident_type &&
            (item.incident_type.includes(searchValue) ||
              item.incident_type
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          (item.incident_source &&
            (item.incident_source.includes(searchValue) ||
              item.incident_source
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          (item.event_date && item.event_date.includes(searchValue)) ||
          (item.incident_severity &&
            (item.incident_severity.includes(searchValue) ||
              item.incident_severity
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          (item.incident_title &&
            (item.incident_title.includes(searchValue) ||
              item.incident_title
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          (item.incident_id &&
            (item.incident_id.toString().includes(searchValue) ||
              item.incident_id
                .toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          item.location.includes(searchValue) ||
          item.location.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.department.includes(searchValue) ||
          item.department.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }

    //   this.setState({filteredListItems:filteredListItems})
    this.dateCompare = (rdate, dateFilter) => {
      dateFilter = dateFilter ? dateFilter : [];
      let dtype = dateFilter.datetype;
      let values = dateFilter.values;
      let startDate,
        d1,
        endDate,
        d2,
        blnDate = false;
      if (dateFilter) {
        if (values && values.length == 0) {
          return true;
        }
        if (
          dtype == "3" ||
          dtype == "4" ||
          dtype == "5" ||
          dtype == "6" ||
          dtype == "8"
        ) {
          d1 = values[0];
          d2 = values[1];
          startDate = d1;
          endDate = d2;

          if (new Date(d1) >= new Date(d2)) {
            startDate = d2;
            endDate = d1;
          }
        }
        switch (dtype) {
          case "1": //Today
            if (
              new Date(rdate).toLocaleDateString() ==
              new Date(values[0]).toLocaleDateString()
            ) {
              blnDate = true;
            }
            break;
          case "2": //YESTERDAY:
            if (
              new Date(rdate).toLocaleDateString() ==
              new Date(values[0]).toLocaleDateString()
            ) {
              blnDate = true;
            }
            break;
          case "3": //LAST7DAYS
            if (
              new Date(rdate).toLocaleDateString() >=
              new Date(startDate).toLocaleDateString() &&
              new Date(rdate).toLocaleDateString() <=
              new Date(endDate).toLocaleDateString()
            ) {
              blnDate = true;
            }
            break;
          case "4": //LAST30DAYS:
            if (
              new Date(rdate).toLocaleDateString() >=
              new Date(startDate).toLocaleDateString() &&
              new Date(rdate).toLocaleDateString() <=
              new Date(endDate).toLocaleDateString()
            ) {
              blnDate = true;
            }
            break;
          case "5": //THISMONTH
            if (
              new Date(rdate).toLocaleDateString() >=
              new Date(startDate).toLocaleDateString() &&
              new Date(rdate).toLocaleDateString() <=
              new Date(endDate).toLocaleDateString()
            ) {
              blnDate = true;
            }
            break;
          case "6": //LASTMONTH:
            if (
              new Date(rdate).toLocaleDateString() >=
              new Date(startDate).toLocaleDateString() &&
              new Date(rdate).toLocaleDateString() <=
              new Date(endDate).toLocaleDateString()
            ) {
              blnDate = true;
            }
            break;
          case "7": //SPECIFIC
            if (
              new Date(rdate).toLocaleDateString() ==
              new Date(values[0]).toLocaleDateString()
            ) {
              blnDate = true;
            }
            break;
          case "8": //operators.CUSTOM:
            if (
              new Date(rdate).toLocaleDateString() >=
              new Date(startDate).toLocaleDateString() &&
              new Date(rdate).toLocaleDateString() <=
              new Date(endDate).toLocaleDateString()
            ) {
              blnDate = true;
            }
            break;
          default:
            break;
        }
      } else {
        blnDate = true;
      }

      return blnDate;
    };

    const options = {
      animationEnabled: true,
      exportEnabled: false,

      theme: "light1", //"light1", "dark1", "dark2"
      title: {
        text: this.props.labels.labels[reportname],
        fontSize: 20,
        fontColor: "#4B72B5",
      },

      subtitles:
        this.state.dataPoints.length > 0
          ? []
          : [
            {
              text: this.props.labels.labels.LBLNORECORDS,
              verticalAlign: "center",
              fontSize: 18,
              fontWeight: 300,
              fontColor: "#7B7B7B",
              dockInsidePlotArea: true,
            },
          ],
      axisX: {
        title: LBLMONTHS,
        fontFamily: "Roboto, sans-serif",
        reversed: true,
      },
      axisY: {
        title: LBLINCCOUNT,
        fontFamily: "Roboto, sans-serif",
        includeZero: true,
        gridThickness: 0.7,
      },
      data: [
        {
          indexLabel: "{y}",
          fontFamily: "Roboto, sans-serif",
          type: "column", //change type to bar, line, area, pie, etc
          //indexLabel: "{y}", //Shows y value on all Data Points
          indexLabelFontColor: "#5A5757",
          indexLabelPlacement: "outside",
          dataPoints: this.state.dataPoints,
          chartName: "MVC",
          click: (e) => {
            this.loadTableData(e);
          },
        },
      ],
    };
    let { currChartType } = this.state;
    let locreporttype = this.state.reporttype;
    let options1 = {
      animationEnabled: true,
      theme: "light1", //"light1", "dark1", "dark2"
      title: {
        text: this.props.labels.labels[reportname],
        fontFamily: "Roboto, sans-serif",
        fontSize: 20,
        fontColor: "#4B72B5",
      },
      subtitles: [],
      legend: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 15,
        fontWeight: 500,
      },
      axisX: {
        title: LBLSTATES,
        reversed: true,
      },
      axisY: {
        title: LBLWORK,
        includeZero: true,
        gridThickness: 0.4,
      },
      data: [
        {
          type: currChartType,
          startAngle: 270,
          showInLegend: "true",
          legendText: currChartType == "pie" ? "{label}" : "{}",
          indexLabelFontSize: 18,
          indexLabelFontColor: "white", //currChartType == "pie" ? "white" : "black",
          toolTipContent: "<b>{label}</b>: {y}%",
          percentFormatString: "#",
          indexLabelPlacement: "inside", // currChartType == "pie" ? "inside" : "outside",
          indexLabel: "{y}%",
          chartName: "LBLPLACE1",
          chartSubName: locreporttype == 2 ? "States" : "Errors",
          click: (e) => {
            this.loadTableData(e);
          },
        },
      ],
    };
    let options2 = {
      //  colorSet:  "colorShades",
      animationEnabled: true,
      exportEnabled: false,
      click: this.visitorsChartDrilldownHandler,
      theme: "light1", //"light1", "dark1", "dark2"
      title: {
        text: this.props.labels.labels[reportname],
        fontFamily: "Roboto, sans-serif",
        fontSize: 20,
        fontColor: "#4B72B5",
      },
      subtitles: [],
      legend: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 15,
      },
      axisX: {
        title: LBLSTATES,
        reversed: true,
      },
      axisY: {
        title: LBLHOME,
        includeZero: true,
        gridThickness: 0.4,
      },
      data: [
        {
          type: currChartType,
          startAngle: 270,
          showInLegend: "true",
          legendText: currChartType == "pie" ? "{label}" : "{}",
          indexLabelFontSize: 18,
          indexLabelPlacement: "inside", //currChartType == "pie" ? "inside" : "outside",
          indexLabelFontColor: "white", //currChartType == "pie" ? "white" : "black",
          toolTipContent: "<b>{label}</b>: {y}%",
          percentFormatString: "#",
          // indexLabel: currChartType=="pie"?"{label} - {y}%":"{y}%",
          indexLabel: "{y}%",
          chartName: "LBLPLACE2",
          chartSubName: locreporttype == 2 ? "States" : "Errors",
          click: (e) => {
            this.loadTableData(e);
          },
        },
      ],
    };
    let options3 = {
      //   colorSet:  "colorShades",
      animationEnabled: true,
      exportEnabled: false,

      theme: "light1", //"light1", "dark1", "dark2"
      title: {
        text: this.props.labels.labels[reportname],
        fontFamily: "Roboto, sans-serif",
        fontSize: 20,
        fontColor: "#4B72B5",
      },
      subtitles: [],
      legend: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 15,
      },
      axisX: {
        title: LBLSTATES,
        reversed: true,
      },
      axisY: {
        title: LBLPUBLICTRANS,
        includeZero: true,
        gridThickness: 0.4,
      },
      data: [
        {
          type: currChartType,
          startAngle: 270,
          showInLegend: "true",
          legendText: currChartType == "pie" ? "{label}" : "{}",
          indexLabelFontSize: 16,
          indexLabelPlacement: "inside", // currChartType == "pie" ? "inside" : "outside",
          indexLabelFontColor: "white", //currChartType == "pie" ? "white" : "black",
          toolTipContent: "<b>{label}</b>: {y}%",
          percentFormatString: "#",
          //indexLabel: currChartType=="pie"?"{label} - {y}%":"{y}%",
          indexLabel: "{y}%",
          chartName: "LBLPLACE3",
          chartSubName: locreporttype == 2 ? "States" : "Errors",
          click: (e) => {
            this.loadTableData(e);
          },
        },
      ],
    };
    let options4 = {
      animationEnabled: true,
      //  colorSet:  "colorShades",
      exportEnabled: false,
      click: this.visitorsChartDrilldownHandler,
      theme: "light1", //"light1", "dark1", "dark2"
      title: {
        text: this.props.labels.labels[reportname],
        fontFamily: "Roboto, sans-serif",
        fontSize: 20,
        fontColor: "#4B72B5",
      },
      subtitles: [],
      legend: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 15,
      },
      axisX: {
        title: LBLSTATES,
        fontFamily: "Roboto, sans-serif",
        reversed: true,
      },
      axisY: {
        title: LBLDRIVING,
        includeZero: true,
        gridThickness: 0.4,
      },
      data: [
        {
          type: currChartType,
          startAngle: 270,
          showInLegend: "true",
          legendText: currChartType == "pie" ? "{label}" : "{}",
          indexLabelFontSize: 16,
          indexLabelPlacement: "inside", // currChartType == "pie" ? "inside" : "outside",
          indexLabelFontColor: "white", // currChartType == "pie" ? "white" : "black",
          toolTipContent: "<b>{label}</b>: {y}%",
          percentFormatString: "#",
          // indexLabel: currChartType=="pie"?"{label} - {y}%":"{y}%",
          indexLabel: "{y}%",
          chartName: "LBLPLACE4",
          chartSubName: locreporttype == 2 ? "States" : "Errors",
          click: (e) => {
            this.loadTableData(e);
          },
        },
      ],
    };

    let {
      data1,
      reporttype,
      data,
      dataPoints,
      dataPoints1,
      dataPoints2,
      dataPoints3,
      dataPoints4,
      reporttype1,
    } = this.state;
    //data1=data;data2=data;data3=data;data4=data;
    if (reporttype > 1) {
      if (data1[0] && (reporttype == 2 || reporttype == 3)) {
        options1.data[0].dataPoints = dataPoints1;
        options2.data[0].dataPoints = dataPoints2;
        options3.data[0].dataPoints = dataPoints3;
        options4.data[0].dataPoints = dataPoints4;

        let subtitles = [
          {
            text: this.props.labels.labels.LBLNORECORDS,
            verticalAlign: "center",
            fontSize: 18,
            fontWeight: "normal",
            fontColor: "#7B7B7B",
            dockInsidePlotArea: true,
          },
        ];
        if (dataPoints1.length == 0) options1.subtitles = subtitles;
        if (dataPoints2.length == 0) options2.subtitles = subtitles;
        if (dataPoints3.length == 0) options3.subtitles = subtitles;
        if (dataPoints4.length == 0) options4.subtitles = subtitles;
      }
      if (reporttype1 >= 20 && data[0]) {
        data[0].dataPoints = dataPoints;
        options.data = data;
        let subtitles = [
          {
            text: this.props.labels.labels.LBLNORECORDS,
            verticalAlign: "center",
            fontSize: 18,
            fontColor: "#7B7B7B",
            fontWeight: "normal",
            dockInsidePlotArea: true,
          },
        ];
        options.subtitles = subtitles;
      }
    }
    if (reporttype == 4) {
      options.dataPointWidth = 40;
      // options.height=550;
      //options.width=550;
      options.data[0].type = "bar";
      options.data[0].chartName = "Severity";
      options.data[0].indexLabelFontSize = 14;
      options.data[0].axisXIndex = 1;
      //  options.data[0].indexLabelBackgroundColor= "LightBlue";
      options.axisX = {
        title: LBLSEVERITY,
        reversed: true,
        fontFamily: "Roboto, sans-serif",
        marginTop: 30,
        interval: 1, // dataPoints[0].y
        intervalType: "number", // "month"
      };
      options.axisY = {
        title: LBLINCCOUNT,
        fontFamily: "Roboto, sans-serif",
        gridThickness: 0.3,
        interval: 1, // dataPoints[0].y
        intervalType: "number", // "month"
      };
    } else if (reporttype == 5) {
      options.height = 420;
      options.data[0].startAngle = 270;
      options.data[0].type = "doughnut";
      options.data[0].legendMarkerType = "circle";
      options.data[0].showInLegend = true;
      options.data[0].indexLabel = "{label} - {y}";
      options.data[0].legendText = "{label}";
      options.data[0].innerRadius = "55%";
      options.data[0].chartName = "Incident Type";
      options.data[0].click = (e) => {
        this.loadTableData(e);
      };
      let incdetails = this.state.inccount + " " + LBLINCIDENTS;
      let subtitles = [
        {
          text:
            dataPoints.length == 0
              ? this.props.labels.labels.LBLNORECORDS
              : incdetails,
          verticalAlign: "center",
          fontSize: 18,
          fontWeight: 300,
          fontColor: "#7B7B7B",
          dockInsidePlotArea: true,
        },
      ];
      options.subtitles = subtitles;

      options["legend"] = {
        horizontalAlign: "bottom",
        verticalAlign: "bottom",
      };
    } else if (reporttype == 6) {
      let maxVal = 10,
        intvl = 1,
        dNum = 10;
      let arrNum = [1, 10, 100, 1000, 10000, 100000];
      if (options.data[0].dataPoints.length > 0) {
        maxVal = Math.max(...options.data[0].dataPoints.map((o) => o.y));
        if (maxVal > 10) {
          dNum = arrNum[maxVal.toString().length - 1];
          intvl = (Math.ceil(maxVal / dNum) * dNum) / 10;
        }
      }
      options.axisX = {
        title: LBLSTATES,
        reversed: true,
        fontFamily: "Roboto, sans-serif",
        gridThickness: 0,
      };
      options.axisY = {
        title: LBLINCCOUNT,
        fontFamily: "Roboto, sans-serif",
        gridThickness: 0.3,
        interval: intvl, // dataPoints[0].y
        intervalType: "number", // "month"
      };

      options.data[0].type = "splineArea";
      options.data[0].color = "#fdc0a3";
      options.data[0].indexLabel = "{label} - {y}";
      options.data[0].legendText = "{label}";

      options.data[0].chartName = "IVS";
      options.data[0].click = (e) => {
        this.loadTableData(e);
      };
    } else if (reporttype == 7) {
      let maxVal = 10,
        intvl = 1,
        dNum = 10;
      let arrNum = [1, 10, 100, 1000, 10000, 100000];
      if (options.data[0].dataPoints.length > 0) {
        maxVal = Math.max(...options.data[0].dataPoints.map((o) => o.y));
        if (maxVal > 10) {
          dNum = arrNum[maxVal.toString().length - 1];
          intvl = (Math.ceil(maxVal / dNum) * dNum) / 10;
        }
      }

      options.data[0].type = "splineArea";
      options.data[0].markerSize = 5;
      options.data[0].color = "#9de0e3";
      options.data[0].chartName = "IVE";
      options.data[0].click = (e) => {
        this.loadTableData(e);
      };
      options.toolTip = {
        fontColor: "#000000",
        text: "",
      };
      options.data[0].indexLabel = "{label} - {y}";
      options.data[0].legendText = "{label}";

      options["legend"] = {
        horizontalAlign: "right",
        verticalAlign: "center",
        reversed: true,
      };
      options.axisX = {
        title: LBLERRORS,
        gridThickness: 0,
      };
      options.axisY = {
        title: LBLINCCOUNT,
        gridThickness: 0.3,
        interval: intvl, // dataPoints[0].y
        intervalType: "number", // "month"
      };
    } else if (reporttype == 8) {
      let obj = {
        axisX: {
          title: LBLINCTYPES,
          fontFamily: "Roboto, sans-serif",
        },
        axisY: {
          title: LBLINCCOUNT,
          lineColor: "#4F81BC",
          tickColor: "#4F81BC",
          labelFontColor: "#4F81BC",
          gridThickness: 0.5,
        },
        axisY2: {
          title: this.props.labels.labels.LBLCUMULATIVEPERCENTAGE,
          suffix: "%",
          lineColor: "#C0504E",
          tickColor: "#C0504E",
          labelFontColor: "#C0504E",
        },
      };
      options.axisX = obj.axisX;
      options.axisY = obj.axisY;
      options.axisY2 = obj.axisY2;
      options.animationEnabled = true;
      options.data[0].chartName = "PARETO";
    } else if (reporttype == 9) {
      let { dataPoints } = this.state;
      if (dataPoints.length > 5) {
        dataPoints.splice(3, 1);
      }
      let arr,
        obj = { data: [] },
        ReportDataloc = dataPoints,
        emptydata = false;
      let {
        LBLSEVERITY1,
        LBLSEVERITY2,
        LBLSEVERITY3,
        LBLSEVERITY5,
        LBLSEVERITY6,
      } = this.props.labels.labels;
      arr = [
        LBLSEVERITY1,
        LBLSEVERITY2,
        LBLSEVERITY3,
        //LBLSEVERITY4,
        LBLSEVERITY5,
        LBLSEVERITY6,
      ];
      let arrNames = ["Serious", "Major", "Minor", "Low", "Very Low"];
      options.axisX.interval = 1; // dataPoints[0].y
      options.axisX.intervalType = "month";
      if (ReportDataloc[0]) {
        // && ReportDataloc[0].length>11){
        ReportDataloc.map((item, i) => {
          options.data[i] = {};
          //options.data[i].type = "stackedColumn";
          options.data[i].type = "stackedArea";
          options.data[i].toolTipContent =
            '<span style="color:#4F81BC"><strong>{name}: </strong></span> {y}';
          options.data[i].showInLegend = true;
          options.data[i].name = arr[i];
          options.data[i].indexLabel = "{y}";
          options.data[i].legendText = "{name}";
          options.data[i].dataPoints = item;
          options.data[i].chartName = arrNames[i];
          options.data[i].legendMarkerType = "square";
          if (!emptydata && item.length)
            emptydata = item.findIndex((i) => i.y > 0) > -1 ? true : false;
        });
      } else {
        options.data[0].dataPoints = [];
      }
      let subtitles = [
        {
          //text: this.props.parentstates.inccount + " " + LBLINCIDENTS,
          text: emptydata == false ? this.props.labels.labels.LBLNORECORDS : "",
          fontSize: 22,
          verticalAlign: "center",
          fontWeight: 300,
          fontColor: "#7B7B7B",
          dockInsidePlotArea: true,
        },
      ];
      options.subtitles = subtitles;

      options.axisX = {
        title: LBLSEVERITY,
        fontFamily: "Roboto, sans-serif",
        // reversed: true,
      };
      options.axisY = {
        title: LBLINCCOUNT,
        fontFamily: "Roboto, sans-serif",
        gridThickness: 0.5,
      };
    }

    this.handleSearch = (event) => {
      let val = event.target.value;
      let ddllocalFiltered,
        ddllocal = this.state.ddlMainReports1;
      ddllocalFiltered = ddllocal.filter((itm, i) => {
        return itm.name.toLowerCase().indexOf(val.toLowerCase()) >= 0;
      });

      this.setState({
        searchreport: val,
        ddlFilterReports: ddllocalFiltered,
      });
    };

    this.getTimeforDays = (x) => {
      return x * 24 * 60 * 60 * 1000;
    };
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
    let locations = [],
      departments = [],
      loc_element = [],
      dep_element = []; //,incident_type,severity,user_list;
    loc_element = this.state.allGroupss ? this.state.allGroupss["GP_1"] : [];
    dep_element = this.state.allGroupss ? this.state.allGroupss["GP_2"] : [];
    let { incident_type, incident_source, severity, user_list } =
      this.state.singleObj;
    incident_type = incident_type ? incident_type : [];
    incident_source = incident_source ? incident_source : [];
    severity = severity ? severity : [];
    user_list = user_list ? user_list : [];
    let objStatus = [
      { value: LBLOPEN, id: "1" },
      { value: LBLCLOSED, id: "0" },
    ];
    incident_type = JSON.parse(
      JSON.stringify(incident_type)
        .split('"incident_type_id":')
        .join('"id":')
        .split('"incident_type_name":')
        .join('"value":')
    );
    incident_source = JSON.parse(
      JSON.stringify(incident_source)
        .split('"incident_source_id":')
        .join('"id":')
        .split('"incident_source_name":')
        .join('"value":')
    );
    severity = JSON.parse(
      JSON.stringify(severity)
        .split('"incident_severity_id":')
        .join('"id":')
        .split('"incident_severity_name":')
        .join('"value":')
    );
    user_list = JSON.parse(
      JSON.stringify(user_list)
        .split('"_id":')
        .join('"id":')
        .split('"user_name":')
        .join('"value":')
    );
    locations = loc_element
      ? JSON.parse(
        JSON.stringify(loc_element)
          .split('"group_id":')
          .join('"id":')
          .split('"group_name":')
          .join('"value":')
      )
      : null;
    departments = dep_element
      ? JSON.parse(
        JSON.stringify(dep_element)
          .split('"group_id":')
          .join('"id":')
          .split('"group_name":')
          .join('"value":')
      )
      : null;
    let columns = [
      {
        name: "",
        selector: "name",
        sortable: true,
        compact: true,
        width: "1%",
        cell: (row) => {
          return (
            <div data-toggle="tooltip" title={row.incident_id}>
              <span style={{ display: "none" }} className="rle-brdr1">
                {" "}
              </span>

              <span className="show-sm-xs"></span>
            </div>
          );
        },
      },
      {
        name: "",
        selector: "name",
        sortable: true,
        compact: true,
        width: "1%",
        cell: (row) => {
          return (
            <div data-toggle="tooltip" title={row.incident_id}>
              <span style={{ display: "none" }} className="rle-brdr1">
                {" "}
              </span>

              <span className="show-sm-xs"></span>
            </div>
          );
        },
      },
      {
        name: LBLINCIDENTID,
        selector: "incident_id",
        sortable: true,
        compact: true,
        width: "9%",
        cell: (row) => {
          return (
            <div
              data-toggle="tooltip"
              style={{ width: "100%" }}
            // title={row.incident_id}
            >
              <span className="rle-brdr1">
                {row.incident_id > 0
                  ? row.incident_id.toString().substring(7, 12)
                  : row.incident_id}
              </span>

              <span className="show-sm-xs"></span>
            </div>
          );
        },
      },
      {
        name: LBLINCTITLE,
        selector: "incident_title",
        width: "14%",
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          return (
            <div
              data-toggle="tooltip"
              style={{ width: "100%" }}
              title={row.incident_title}
            >
              <span className="rle-brdr1 cls_ellipsis">
                {row.incident_title}
              </span>

              <span className="show-sm-xs"></span>
            </div>
          );
        },
      },
      {
        name: LBLERRORSOURCE,
        selector: "incident_source",
        width: "13%",
        sortable: true,
        compact: true,
        filter: { type: "string", showField: true, checked: true },
        filterData: incident_source,
        //center: true,
        cell: (row) => {
          let incident_source =
            row.incident_source.indexOf("LBLSOURCE") > -1
              ? this.props.labels.labels[row.incident_source]
              : row.incident_source;
          return (
            <div className="vfy-col">
              <span className="rle-brdr">{incident_source}</span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLINCTYPES,
        selector: "incident_type",
        width: "12%",
        sortable: true,
        compact: true,
        filter: { type: "string", showField: true, checked: true },
        filterData: incident_type,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col">
              <span className="rle-brdr">{row.incident_type}</span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLSEVERITY,
        selector: "incident_severity",
        width: "10%",
        sortable: true,
        compact: true,
        //center: true,
        filter: { type: "string", showField: true, checked: true },
        filterData: severity,
        cell: (row) => {
          return (
            <div className="vfy-col">
              <span
                className="rle-brdr"
              >
                {row.incident_severity}
              </span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLLOCATIONS,
        selector: "Location",
        width: "10%",
        sortable: true,
        compact: true,

        filter: {
          type: "string",
          showField: true,
          checked: true,
          multi: true,
        },
        filterData: locations,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col" style={{ width: "100%" }}>
              <span title={row.location} className="rle-brdr cls_ellipsis">
                {row.location}
              </span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLDEPARTMENT,
        selector: "Department",
        width: "10%",
        sortable: true,
        compact: true,
        filter: {
          type: "string",
          showField: true,
          checked: true,
          multi: true,
        },
        filterData: departments,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col" style={{ width: "100%" }}>
              <span title={row.department} className="rle-brdr cls_ellipsis">
                {row.department}
              </span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLDATE,
        selector: "event_date",
        width: "13%",
        sortable: true,
        compact: true,
        filter: {
          type: "date",
          showField: true,
          checked: true,
          render: "server",
          condition: {
            name: "Date",
            operator: "LAST7DAYS",
            value: xrArr.slice(),
            operatorText: this.props.labels.labels.LBLLAST7DAYS,
          },
          exportcell: "_created_on_dateformat",
        },
        //center: true,
        cell: (row) => {
          let event_date = row.event_date; //moment.utc(row.event_date).format(dateformat);
          return (
            <div className="vfy-col">
              <span className="rle-brdr">{event_date}</span>
              {/* <span>{row.active === 0 ? 'Inactive' : 'Active'}</span> */}
            </div>
          );
        },
      },
      {
        name: LBLSTATUS,
        selector: "status",
        width: "7%",
        style: {},
        sortable: true,
        compact: true,
        filter: { type: "string", showField: true, checked: true },
        filterData: objStatus,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col">
              <span
                style={{ fontSize: "15px", fontWeight: "600" }}
                className="rle-brdr"
              >{`${row.status === true || row.status === 1 ? LBLOPEN : LBLCLOSED
                }`}</span>
            </div>
          );
        },
      },
    ];

    let filtereddata;
    if (searchValue !== "") {
      filtereddata = filteredListItems;
    } else if (showList) {
      filtereddata = filteredChartData;
    } else {
      filtereddata = filteredListItems;
    }

    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    let {
      open,
      message,
      messageType,
      ddlreporttypes,
      ddlMainReports,
      allGroupss,
      ddlFilterReports,
    } = this.state;
    ddlreporttypes = ddlreporttypes ? ddlreporttypes : [];
    ddlMainReports = ddlMainReports ? ddlMainReports : [];
    let ddlFilterReportsNew;
    ddlFilterReportsNew = JSON.parse(JSON.stringify(ddlFilterReports));
    ddlFilterReportsNew.map((item) => {
     return item.name = this.props.labels.labels[item.name];
    });
    let tableHeader = this.tableHeader();
    this.getFilterColumns = (mode, ycols) => {
      let cols = JSON.parse(JSON.stringify(ycols));
      resetColumns = cols;
      let xcols = [];
      for (var i = 0; i < cols.length; i++) {
        if (cols[i].filter) {
          cols[i].selector = cols[i].selector;
          cols[i].displayName = cols[i].name;
          xcols.push(cols[i]);
        }
      }

      if (mode === "RYS_D1") {
        let xobj = objFilterData.availableRYS_D1Fields;
        if (xobj) {
          for (var j = 0; j < xcols.length; j++) {
            xcols[j].filter = xobj[xcols[j].name];
          }
        }
      }
      return xcols;
    };
    this.getResetFilterColumns = (mode, ycols) => {
      let cols = JSON.parse(JSON.stringify(ycols));
      let xcols = [];
      for (var i = 0; i < cols.length; i++) {
        if (cols[i].filter) {
          cols[i].selector = cols[i].selector;
          cols[i].displayName = cols[i].name;
          xcols.push(cols[i]);
        }
      }
      return xcols;
    };
    let columnss = [],
      filterFields = [],
      resetfilterFields = [];
    if (allGroupss && filteredListItems) {
      columnss = columns ? columns : [];
      filterFields = this.getFilterColumns("RYS_D1", columnss);
      filterFields[0].sortable = false;
      resetfilterFields = this.getResetFilterColumns("RYS_D1", columnss);
      firstLoad++;
      if (firstLoad < 4) {
        filterFields = resetfilterFields;
      }
    }
    let options_plc1 = this.getOption();
    let options_plc2 = this.getOption();
    let options_plc3 = this.getOption();
    let options_plc4 = this.getOption();
    if (this.state.currChartType == "column") {
      let xAxisLabels = [];
      options1.data[0].dataPoints &&
        options1.data[0].dataPoints.map((i) => {
          return xAxisLabels.push(i.name);
        });
      xAxisLabels = this.sortingData(xAxisLabels);
      options_plc1.xAxis.categories = xAxisLabels;
      xAxisLabels = [];
      options2.data[0].dataPoints &&
        options2.data[0].dataPoints.map((i) => {
          return xAxisLabels.push(i.name);
        });
      xAxisLabels = this.sortingData(xAxisLabels);
      options_plc2.xAxis.categories = xAxisLabels;
      xAxisLabels = [];
      options3.data[0].dataPoints &&
        options3.data[0].dataPoints.map((i) => {
          return xAxisLabels.push(i.name);
        });
      xAxisLabels = this.sortingData(xAxisLabels);
      options_plc3.xAxis.categories = xAxisLabels;
      xAxisLabels = [];
      options4.data[0].dataPoints &&
        options4.data[0].dataPoints.map((i) => {
         return xAxisLabels.push(i.name);
        });
      xAxisLabels = this.sortingData(xAxisLabels);
      options_plc4.xAxis.categories = xAxisLabels;
      options_plc1.chart.chartName = options1.data[0].chartName;
      options_plc2.chart.chartName = options2.data[0].chartName;
      options_plc3.chart.chartName = options3.data[0].chartName;
      options_plc4.chart.chartName = options4.data[0].chartName;
      options_plc1.chart.chartSubName = options1.data[0].chartSubName;
      options_plc2.chart.chartSubName = options2.data[0].chartSubName;
      options_plc3.chart.chartSubName = options3.data[0].chartSubName;
      options_plc4.chart.chartSubName = options4.data[0].chartSubName;
      options_plc1.yAxis.title.text = LBLPLACE1;
      options_plc2.yAxis.title.text = LBLPLACE2;
      options_plc3.yAxis.title.text = LBLPLACE3;
      options_plc4.yAxis.title.text = LBLPLACE4;
      options_plc1.series[0].data = this.sortData(options1.data[0].dataPoints);
      options_plc2.series[0].data = this.sortData(options2.data[0].dataPoints);
      options_plc3.series[0].data = this.sortData(options3.data[0].dataPoints);
      options_plc4.series[0].data = this.sortData(options4.data[0].dataPoints);
    }
    this.sortingData = (array) => {
      if (array) {
        array.sort((a, b) => {
          if (a > b) {
            return -1;
          }
          return null;
        });
      }
      return array;
    };
    this.sortData = (array) => {
      if (array) {
        array.sort((a, b) => {
          if (a.name > b.name) {
            return -1;
          }
          return null;
        });
      }
      return array;
    };
    // console.log("rpt_type_val", this.state.rpt_type_val);
    let rptVal = this.state.rpt_type_val
      ? this.state.rpt_type_val.value
      : this.state.rpt_type_val;
    if (rptVal == 2 || rptVal == 3 || rptVal == 6 || rptVal == 7) {
      //columns.splice(4,1);
      // columns[3].width = "30%";
    }
    return (
      <div className="near_miss_report ">
        <div className="mob-pad">
          <div >
            <h3 class="page-title">{LBLNMINCREPORT}</h3>
          </div>
          <hr></hr>
        </div>
        <Snackbar
          anchorOrigin={{ horizontal, vertical }}
          key={"top-center"}
          open={open}
          autoHideDuration={100000}
          onClose={this.handleClose}
          bodyStyle={{ backgroundColor: "teal", color: "coral" }}
        >
          <Alert
            variant="filled"
            onClose={this.handleClose}
            severity={messageType}
          >
            {message}
          </Alert>
        </Snackbar>
        {this.state.loading ? (
          <Loader></Loader>
        ) : (
          <div id="custom_reports_header">
            {filterFields.length > 0 ? (
              <div id="custom_reports" >
                <CustomFilter
                  availableFields={filterFields}
                  selectedFields={filterFields}
                  orgAvailableFields={resetfilterFields}
                  onFilterChange={this.onFilterChange}
                  onExport={this.onExport}
                  id="Near"
                  onRef={(ref) => (REFS.Near = ref)}
                  labels={this.props.labels.labels}
                  reportName={this.props.labels.labels.LBLNMINCREPORT}
                  //reportName={LBLNMINCREPORT?LBLNMINCREPORT:"Near Miss Incident Reports"}
                  companyName={this.props.company_name}
                  showFieldFilter={true}
                  submitText={this.props.labels.labels.LBLOK}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {/* <hr className="head-line" /> */}
        {this.state.loadingtypes ? (
          <div>
            <Loader></Loader>
          </div>
        ) : (
          <hr style={{ marginTop: "10px" }}></hr>
        )}

        <div
          style={{ display: "" }}
          id="cls_report_list"
          className="cls_report_list"
        >
          <div style={{ display: "none" }} className="cls_reports_item1">
            <div class="input-group md-form form-sm form-1 pl-0">
              <div class="input-group-prepend">
                <span
                  class="input-group-text purple lighten-3"
                  id="basic-text1"
                >
                  <i class="fas fa-search text-white" aria-hidden="true"></i>
                </span>
              </div>
              <input
                onChange={(e) => this.handleSearch(e)}
                id="search_reports"
                value={this.state.searchreport}
                class="input-field my-0 py-1"
                type="text"
                placeholder="Search"
                aria-label="Search"
              ></input>
            </div>
          </div>
          {/* {console.log("set", this.state.rpt_type_val)} */}
          <div className="cls_reports_item3">
            <div className="row">
              <div className="col-xl-5 col-sm-6">
                <Autocomplete
                  id="combo-box-demo"
                  aria-required="true"
                  required
                  disableClearable
                  options={ddlFilterReportsNew ? ddlFilterReportsNew : []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  value={this.state.rpt_type_val ? this.state.rpt_type_val : null}
                  onChange={this.handleNameChange}
                  renderOption={(option, i) => (
                    <div style={{ fontSize: "14px" }}> 
                      {option.name}
                    </div>
                  )}
                  renderInput={(params) => (
                    <div>
                      <TextField placeholder={LBLSELECTREPORT} {...params} />
                    </div>
                  )}
                />
                {/* </FormControl> */}
                <span className="required">{this.state.helperType}</span>
              </div>
              <div>
                <div
                  className="button-style primary-btn"
                  onClick={() => {
                    this.handleReportChange();
                  }}
                >
                  {this.props.labels.labels.LBLPROCESSREPORT}
                </div>
              </div>
              <div className="col-xl-21 cls_rpt_process_container"></div>
            </div>
          </div>
        </div>
        {this.state.showchart ? (
          this.state.loadingchart ? (
            <div style={{ marginTop: "200px" }}>
              {" "}
              <Loader> </Loader>
            </div>
          ) : (
            <div
              id="reportSection"
              style={{
                display: "",
                width: "100%",
              }}
            >
              <div
                id="reportHeaderSection cls_new_header"
                className="row reportHeaderSection"
                style={{ display: "" }}
              >
                <div
                  className="col-xl-12 col-sm-6 cls_export "
                  role="group"
                  style={{ float: "right", textAlign: "right" }}
                >
                  <div style={{ display: "none" }} id="loadingexport">
                    {" "}
                    <Loading> </Loading>
                  </div>
                  <span
                    id="report_drop"
                    style={{
                      marginTop: "10px",
                      display: "none",
                      marginRight: "10px",
                    }}
                  ></span>               
                  <button
                id="btnGroupDrop1"
                //className="Exportbtnclass"
                type="button"
                className="btn basicSelItem dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  marginRight: "-2px",
                  height: "36px",
                  minWidth: '100px',
                  padding: "0",
                  borderRadius: "0px",
                  border: "none",
                  borderBottom: "1px solid black",
                  background: "none",                  
                  textAlign: 'left'
                }}
              >
                <i className="fas fa-mail-forward" aria-hidden="true">
                  &#xf064;
                </i>{" "}
                <span style={{padding: '5px 10px'}}>{LBLEXPORT}</span>
              </button>
              {/* {console.log("report", this.props.restrictExport)} */}
              <div
                className="dropdown-menu  dropdown-menu-right"
                aria-labelledby="btnGroupDrop1"
              >
                {!this.props.restrictExport || (this.props.restrictExport && this.props.restrictExport !== "csv") ?
                  <span
                    className="dropdown-item"
                    style={{fontSize: '14px', cursor: 'pointer'}}
                    onClick={this.onExport.bind(this, "csv")}
                  >
                    {LBLEXPORTTOCSV}
                  </span>
                  : ""
                } 

                <span
                  className="dropdown-item"
                  style={{fontSize: '14px', cursor: 'pointer'}}
                  onClick={this.onExport.bind(this, "excel")}
                >
                  {LBLEXPORTTOEXCEL}
                </span>
              </div>
                </div>
              </div>
              {this.state.subchartloading ? (
                <Loader></Loader>
              ) : (
                <div
                  style={{
                    display: this.state.subchartloadingtop ? "" : "none",
                  }}
                  id="cls_chart_section"
                  className="cls_chart_section"
                  width="100%"
                  height="700px"
                >
                  <ReportComponent
                    //data={this.state.trainingStatusData}
                    compOptions={options}
                    labels={this.props.labels.labels}
                    chartType={1}
                    reporttype={reporttype}
                    legend={true}
                    chartName={options.data[0].chartName}
                    onElementClick={true}
                    onChartClick={this.loadTableData}
                    reportLabels={{
                      LBLMONTHS: LBLMONTHS,
                      LBLINCCOUNT: LBLINCCOUNT,
                      LBLMONTHINCIDENTCOUNT: LBLMONTHINCIDENTCOUNT,
                    }}
                    onRef={(ref) => (this.chart = ref)}
                  />
                </div>
              )}
              <div
                style={{
                  display: this.state.subchartloadingfull ? "" : "none",
                  marginTop: "20px",
                }}
                id="cls_chart_full_section"
                className="cls_chart_full_section"
                width="100%"
                height="600px"
              >
                <div className="row">
                  <div className="col-xl-10" style={{ display: "flex" }}></div>
                  <div
                    className="col-xl-2 cls_rpt_chng"
                    style={{ display: "flex" }}
                  >
                    <div
                      id="piechart"
                      onClick={() => this.changeReportType(1)}
                      className="cls_chart_type cls_selected"
                    >
                      <img src="../../images/nearmiss/pie_icon.png" alt=""></img>
                    </div>
                    <div
                      id="barchart"
                      onClick={() => this.changeReportType(2)}
                      className="cls_chart_type"
                    >
                      <img src="../../images/nearmiss/Bar_icon.png" alt=""></img>
                    </div>
                  </div>
                </div>
                {this.state.currChartType == "pie" ? (
                  <div>
                    <div class="row ">
                      <div class="col-xl-6 cls_multi_chart">
                        <div class="cls_report_title">
                          {this.state.reportname1}
                        </div>
                        <ReportComponent
                          data={this.state.trainingStatusData}
                          compOptions={options1}
                          labels={this.props.labels.labels}
                          reportLabels={{
                            LBLRUSHING: LBLRUSHING,
                            LBLFRUSTRATION: LBLFRUSTRATION,
                            LBLFATIGUE: LBLFATIGUE,
                            LBLCOMPLACENCY: LBLCOMPLACENCY,
                          }}
                          chartType={2}
                          reporttype={reporttype}
                          onElementClick={true}
                          onChartClick={this.loadTableData}
                          chartName={options1.data[0].chartName}
                          legend={true}
                          ref={this.chart}
                        />
                      </div>
                      {/* <div class="col-xl-1"></div> */}
                      <div class="col-xl-6  cls_multi_chart">
                        <div class="cls_report_title">
                          {this.state.reportname2}
                        </div>
                        <ReportComponent
                          data={this.state.trainingStatusData}
                          compOptions={options2}
                          labels={this.props.labels.labels}
                          reportLabels={{
                            LBLRUSHING: LBLRUSHING,
                            LBLFRUSTRATION: LBLFRUSTRATION,
                            LBLFATIGUE: LBLFATIGUE,
                            LBLCOMPLACENCY: LBLCOMPLACENCY,
                          }}
                          chartType={2}
                          reporttype={reporttype}
                          onElementClick={true}
                          onChartClick={this.loadTableData}
                          chartName={options2.data[0].chartName}
                          legend={true}
                          ref={this.chart}
                        />
                      </div>
                      {/* <div class="col-xl-1"></div> */}
                    </div>
                    <div
                      class="row"
                      style={{ marginBottom: "67px", marginTop: "20px" }}
                    >
                      <div class="col-xl-6 cls_multi_chart">
                        <div class="cls_report_title">
                          {this.state.reportname3}
                        </div>
                        <ReportComponent
                          data={this.state.trainingStatusData}
                          compOptions={options3}
                          labels={this.props.labels.labels}
                          reportLabels={{
                            LBLRUSHING: LBLRUSHING,
                            LBLFRUSTRATION: LBLFRUSTRATION,
                            LBLFATIGUE: LBLFATIGUE,
                            LBLCOMPLACENCY: LBLCOMPLACENCY,
                          }}
                          chartType={2}
                          reporttype={reporttype}
                          onElementClick={true}
                          onChartClick={this.loadTableData}
                          chartName={options3.data[0].chartName}
                          legend={true}
                          ref={this.chart}
                        />
                      </div>
                      {/* <div class="col-xl-1"></div> */}
                      <div class="col-xl-6 cls_multi_chart">
                        <div class="cls_report_title">
                          {this.state.reportname4}
                        </div>
                        <ReportComponent
                          data={this.state.trainingStatusData}
                          compOptions={options4}
                          labels={this.props.labels.labels}
                          reportLabels={{
                            LBLRUSHING: LBLRUSHING,
                            LBLFRUSTRATION: LBLFRUSTRATION,
                            LBLFATIGUE: LBLFATIGUE,
                            LBLCOMPLACENCY: LBLCOMPLACENCY,
                          }}
                          chartType={2}
                          reporttype={reporttype}
                          onElementClick={true}
                          onChartClick={this.loadTableData}
                          chartName={options4.data[0].chartName}
                          legend={true}
                          ref={this.chart}
                        />
                      </div>
                      {/* <div class="col-xl-1"></div> */}
                    </div>
                  </div>
                ) : (
                  <div>
                    <div class="row ">
                      <div class="col-xl-6 cls_multi_chart">
                        <div class="cls_report_title">
                          {this.state.reportname1}
                        </div>
                        {options_plc1.series &&
                          options_plc1.series[0].data &&
                          options_plc1.series[0].data.length > 0 ? (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options_plc1}
                            ref={this.chart}
                          />
                        ) : (
                          <div style={{ margin: "5%" }}>
                            {
                              <EmptyState
                                image={emptyImage}
                                text2={LBLNORECORDS}
                              />
                            }{" "}
                          </div>
                        )}
                      </div>
                      {/* <div class="col-xl-1"></div> */}
                      <div class="col-xl-6  cls_multi_chart">
                        <div class="cls_report_title">
                          {this.state.reportname2}
                        </div>
                        {options_plc2.series &&
                          options_plc2.series[0].data &&
                          options_plc2.series[0].data.length > 0 ? (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options_plc2}
                            ref={this.chart}
                          />
                        ) : (
                          <div style={{ margin: "5%" }}>
                            {" "}
                            {
                              <EmptyState
                                image={emptyImage}
                                text2={LBLNORECORDS}
                              />
                            }{" "}
                          </div>
                        )}
                      </div>
                      {/* <div class="col-xl-1"></div> */}
                    </div>
                    <div
                      class="row"
                      style={{ marginBottom: "67px", marginTop: "20px" }}
                    >
                      <div class="col-xl-6 cls_multi_chart">
                        <div class="cls_report_title">
                          {this.state.reportname3}
                        </div>
                        {options_plc3.series &&
                          options_plc3.series[0].data &&
                          options_plc3.series[0].data.length > 0 ? (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options_plc3}
                            ref={this.chart}
                          />
                        ) : (
                          <div style={{ margin: "5%" }}>
                            {" "}
                            {
                              <EmptyState
                                image={emptyImage}
                                text2={LBLNORECORDS}
                              />
                            }{" "}
                          </div>
                        )}
                      </div>
                      {/* <div class="col-xl-1"></div> */}
                      <div class="col-xl-6 cls_multi_chart">
                        <div class="cls_report_title">
                          {this.state.reportname4}
                        </div>
                        {options_plc4.series &&
                          options_plc4.series[0].data &&
                          options_plc4.series[0].data.length > 0 ? (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={options_plc4}
                            ref={this.chart}
                          />
                        ) : (
                          <div style={{ margin: "5%" }}>
                            {" "}
                            {
                              <EmptyState
                                image={emptyImage}
                                text2={LBLNORECORDS}
                              />
                            }{" "}
                          </div>
                        )}
                      </div>
                      {/* <div class="col-xl-1"></div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <EmptyState image={emptyImage} text2={LBLSELPROCREPORT} />
        )}
        <div id="nearmissListSection">
          {this.state.showList ? (
            <div id="nearmissReportList" className="near_miss_report">
              <hr></hr>
              <DataTable
                columns={columns}
                data={filtereddata}
                defaultSortField="name"
                className="datatable-list"
                pagination
                subHeader
                subHeaderComponent={tableHeader}
                sortFunction={this.customSort}
                noDataComponent={
                  <EmptyState image={emptyImage} text2={LBLNORECORDS} />
                }
                paginationComponentOptions={paginationComponentOptions}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(NearMissReport);
//export default NearMiss;

import React, { Component } from "react";
import ReportServices from "../report_services";
import GroupServices from "../../group-list/group_services";
import orderBy from "lodash/orderBy";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import { connect } from "react-redux";
import UsersService from "../../user-list/UsersService";
import Skeleton from "react-loading-skeleton";
import obj_safestartsurvey from "../../survey/surveyfile.json"; //"./surveyfile.json";
import CanvasJSReact from "../../../../assets/canvasjs/canvasjs.react";
import { classList } from "dynamic-class-list";

import emptyImage from '../../../../assets/images/learnerprogress_emptyst.png'
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import moment from "moment";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let objFilterData = {};
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};


class SSSReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      graphType: "bar",
      cur_mod_index: 0,
      page_question_count: 19,
      cur_mod_page_index: 0,
      passvars: "",
      qanswers: JSON.parse(JSON.stringify(obj_safestartsurvey)),
      open: false,
      loading: true,
      userData: [],
      user_roles: [],
      pageState: "SSS_D1",
      roles: [{ role_id: 1, role_name: "learner" }],
      loadSSS_D1_List: 0,
      orgSSS_D1_List: [],
      SSS_D1_List: [],
      SSS_D1_SummaryData: {
        records: 0,
        graphData: {
          rushing: [],
          fatigue: [],
          frustration: [],
          complacency: [],
        },
      },
      sel_D1_Data: {},
      SSS_D1_GPH_SelStateId: "1",
      filterDataStatus: false,
      filterDataLoaded: 0,
    };
    this.SSS_D1_Filter = React.createRef();
  }
  rysStatusTranslation = {
    1: this.props.labels.labels.LBLRUSHING,
    3: this.props.labels.labels.LBLFATIGUE,
    2: this.props.labels.labels.LBLFRUSTRATION,
    4: this.props.labels.labels.LBLCOMPLACENCY,
  };
  loadpage(a, x) {
    if (a === "SSS_D1") {
      this.setState({loadSSS_D1_List:1});
    }

    if (this.state.loadSSS_D1_List === 1) {
      this.setState({
        loading: false,
      });
    }
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  componentDidMount() {
    let data = {};
    data.date1 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    data.date2 = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000);
    data.sdate1 = this.getDateFormat(data.date1);
    data.sdate2 = this.getDateFormat(data.date2);
    data.offsetTime = this.getOffsetTime();
    //this.getGroupTypes();
    let getData = {
      language: this.props.language,
    };
    this.getFilterDatas();
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      //{group_type_id: "GP_1", lbl_GP_1: "Location", Active: "1"}
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
        },
        this.loadpage("group", iresponse)
      );
    });
    this.getSSS_D1_List(data);
  }
  async getFilterDatas() {
    let data = {};
    data.report = "LCP_D1";
    let group_types = await require("../../../../json_files/group_types.json");
    ReportServices.getFilterDatas(data).then((response) => {
      // console.log("response.result", response.result);
      let xgroup_types = response.result[0];
      let labels;
      let groups = {};
      for (var i = 0; i < xgroup_types.length; i++) {
        if (this.props.language_code === xgroup_types[i].language_code) {
          labels = JSON.parse(xgroup_types[i].labels).group_labels;
        }
      }
      if (labels && labels.length > 0) {
        for (const grouptype of group_types) {
          if (labels[grouptype.group_type_id]) {
            grouptype["lbl_" + grouptype.group_type_id] =
              labels[grouptype.group_type_id]["lbl_" + grouptype.group_type_id];
            grouptype["Active"] = labels[grouptype.group_type_id]["Active"];
            groups[grouptype.group_type_id] = [];
            groups["obj" + grouptype.group_type_id] = {};
          } else {
            grouptype["Active"] = "0";
          }
        }
      }
      let xgroups = response.result[1]; 
      for (var j = 0; j < xgroups.length; j++) {
        if (
          groups[xgroups[j].group_type_id] &&
          !groups["obj" + xgroups[j].group_type_id][xgroups[j].value]
        ) {
          groups["obj" + xgroups[j].group_type_id][xgroups[j].value] = 1;
          groups[xgroups[j].group_type_id].push(xgroups[j]);
        }
      }
      let courses = response.result[2];
      let users = response.result[3];
      let courseunits = response.result[4];
      this.setState(
        {
          filterDataLoaded: 1,
          pageState: "SSS_D1",
          courses: courses,
          userNames: users,
          courseunits: courseunits,
        },
        this.loadpage("SSS_D1")
      );
    });
  }
  getSSS_D1_Export(data) {
    data.report = "SSS_D1";
    ReportServices.getSSS_D1_Export(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        //console.log("TTTTTTTTTTTTTTTTTTTT,fileUrl", fileUrl)
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob)
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName = this.props.labels.labels.LBLSSEXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }

  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  getSSS_D1_Summary = (xresult, ydata) => {
    let dateformat = this.props.dateformat;
    // console.log("getSSS_D1_SummaryQQQQQQQQQQQQQQQQQ", ydata);
    let xsummaryData = {
      rushing: {},
      fatigue: {},
      frustration: {},
      complacency: {},
    };
    let grpSummaryData = {
      sum_rushing: 0,
      sum_fatigue: 0,
      sum_frustration: 0,
      sum_complacency: 0,
      dwgh_rushing: {},
      dwgh_fatigue: {},
      dwgh_frustration: {},
      dwgh_complacency: {},
    };
    let summaryData = {
      rushing: [],
      fatigue: [],
      frustration: [],
      complacency: [],
    };

    for (var i = 0; i < xresult.length; i++) {
      let xdate = xresult[i].created_on_format; //.substr(0, 10).split("/").reverse().join("/")
      if (xsummaryData["rushing"].hasOwnProperty(xdate) === false) {
        xsummaryData["rushing"][xdate] = {};
        xsummaryData["fatigue"][xdate] = {};
        xsummaryData["frustration"][xdate] = {};
        xsummaryData["complacency"][xdate] = {};
      }
      if (
        xsummaryData["rushing"][xdate].hasOwnProperty(xresult[i].rushing) ===
        false
      ) {
        xsummaryData["rushing"][xdate][xresult[i].rushing] = 0;
      }
      xsummaryData["rushing"][xdate][xresult[i].rushing]++;
      grpSummaryData["sum_rushing"]++;

      if (
        xsummaryData["fatigue"][xdate].hasOwnProperty(xresult[i].fatigue) ===
        false
      ) {
        xsummaryData["fatigue"][xdate][xresult[i].fatigue] = 0;
      }
      xsummaryData["fatigue"][xdate][xresult[i].fatigue]++;
      grpSummaryData["sum_fatigue"]++;
      if (
        xsummaryData["frustration"][xdate].hasOwnProperty(
          xresult[i].frustration
        ) === false
      ) {
        xsummaryData["frustration"][xdate][xresult[i].frustration] = 0;
      }
      xsummaryData["frustration"][xdate][xresult[i].frustration]++;
      grpSummaryData["sum_frustration"]++;
      if (
        xsummaryData["complacency"][xdate].hasOwnProperty(
          xresult[i].complacency
        ) === false
      ) {
        xsummaryData["complacency"][xdate][xresult[i].complacency] = 0;
      }
      xsummaryData["complacency"][xdate][xresult[i].complacency]++;
      grpSummaryData["sum_complacency"]++;
    }
    //console.log("grpSummaryData!!!!!!!!!!!!!!!!!!!!!!!!",grpSummaryData)
    //console.log("xsummaryData!!!!!!!!!!!!!!!!!!!!!!!!",xsummaryData)
    var getcount = function (xday, ydays) {
      let xdt = xday.split("/");
      var txyear, txmonth, txdate;
      let txFmt = dateformat.split("/");

      for (let txi = 0; txi < txFmt.length; txi++) {
        if (txFmt[txi].toUpperCase().indexOf("D") > -1) {
          txdate = xdt[txi];
        }
        if (txFmt[txi].toUpperCase().indexOf("M") > -1) {
          txmonth = xdt[txi] - 1;
        }
        if (txFmt[txi].toUpperCase().indexOf("Y") > -1) {
          txyear = xdt[txi];
        }
      }

      var Difference_In_Time =
        new Date(txyear, txmonth, txdate).getTime() - ydays.getTime();
      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days;
    };
    for (let xstate in xsummaryData) {
      for (let xday in xsummaryData[xstate]) {
        var dtotal = 0;
        for (let xrate in xsummaryData[xstate][xday]) {
          //console.log("[xstate][xday][xrate]",xstate,xday,xrate)
          let obj = {
            x: getcount(xday, ydata.date1),
            y: xrate,
            org_value: xsummaryData[xstate][xday][xrate],
            day: xday,
          };
          summaryData[xstate].push(obj);
          dtotal = dtotal + xsummaryData[xstate][xday][xrate];
        }
        grpSummaryData["dwgh_" + xstate][xday] =
          dtotal / grpSummaryData["sum_" + xstate];
      }
      grpSummaryData["mwgh_" + xstate] = 0;
    }
    //set weight value
    for (let xstate in summaryData) {
      for (i = 0; i < summaryData[xstate].length; i++) {
        summaryData[xstate][i].weight =
          summaryData[xstate][i].org_value *
          grpSummaryData["dwgh_" + xstate][summaryData[xstate][i].day];
        grpSummaryData["mwgh_" + xstate] =
          grpSummaryData["mwgh_" + xstate] > summaryData[xstate][i].weight
            ? grpSummaryData["mwgh_" + xstate]
            : summaryData[xstate][i].weight;
      }
    }
    for (let xstate in summaryData) {
      for (i = 0; i < summaryData[xstate].length; i++) {
        summaryData[xstate][i].value = Math.round(
          (summaryData[xstate][i].weight / grpSummaryData["mwgh_" + xstate]) *
          100,
          0
        );
      }
    }
    //console.log("!!!!!!!!!!!!summaryData", summaryData);
    return summaryData;
  };
  getSSS_D1_List(data) {
    data.report = "SSS_D1";

    //console.log("getSSS_D1_List data", data);
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;

    data.jsonOptionIds = [];

    obj_safestartsurvey.map((objmod, modindex) => {
      if (objmod.questions) {
        objmod.questions.map((questions, questionindex) => {
         return questions.options.map((item, index) => {
          return  data.jsonOptionIds.push(item.sid);
          });
        });
      }
      return null;
    });

    ReportServices.getSSS_D1_List(data).then((response) => {
      //console.log("getSSS_D1_List!!!!!!!!11", response.result[3])
      let result = response.result[5];
      let xsummaryData = {};
      xsummaryData.graphData = this.getSSS_D1_Summary(result, data);
      xsummaryData.records = result.length;

      this.setState(
        {
          pageState: "SSS_D1",
          orgSSS_D1_List: result,
          SSS_D1_List: result,
          SSS_D1_SummaryData: xsummaryData,
          sel_D1_Data: data,
          filterDataStatus: true,
        },
        //setTimeout(() => {
        this.loadpage("SSS_D1")
        //}, 2000)
      );
    });
  }
  applyFilterCondition = (orgresult, data) => {
    if (objFilterData.availableSSS_D1Fields) {
      let xobj = objFilterData.availableSSS_D1Fields;
      let result = [];
      // console.log("applyFilterCondition!!!!!!!", xobj);
      for (var i = 0; i < orgresult.length; i++) {
        if (this.checkFilterCondition(xobj, orgresult[i]) === true) {
          result.push(orgresult[i]);
        }
      }
      let xsummaryData = {};
      xsummaryData.graphData = this.getSSS_D1_Summary(result, data);
      xsummaryData.records = result.length;
      this.setState(
        {
          SSS_D1_List: result,
          SSS_D1_SummaryData: xsummaryData,
        },
        this.loadpage("SSS_D1")
      );
    } else {
      let result = orgresult;
      let xsummaryData = {};
      xsummaryData.graphData = this.getSSS_D1_Summary(result, data);
      xsummaryData.records = result.length;
      this.setState(
        {
          SSS_D1_List: result,
          SSS_D1_SummaryData: xsummaryData,
        },
        this.loadpage("SSS_D1")
      );
    }
  };
  showSSS_D1 = () => {
    //console.log("showSSS_D1")
    this.setState({
      pageState: "SSS_D1",
    });
  };

  getGroupTypes = async () => {
    GroupServices.getGroupTypeLabels(this.props.language_code).then(
      (response) => {
        // console.log("response!!!!!!!!!!!!!1", response);
        this.setState(
          {
            groupTypeNames: response,
          },
          this.loadpage("group", response)
        );
      }
    );
  };
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  getSSS_D1_Columns = (x) => {
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
    let columns = [
      {
        name: this.props.labels.labels.LBLDATE,
        selector: "created_on",
        filter: {
          type: "date",
          showField: true,
          checked: true,
          render: "server",
          condition: {
            name: "Date",
            operator: "LAST7DAYS",
            value: xrArr.slice(),
            operatorText: this.props.labels.labels.LBLLAST7DAYS,
          },
          exportcell: "_created_on_dateformat",
        },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLDATE}
              </label>
              <span className="rle-brdr">{row.created_on_format}</span>
            </div>
          );
        },
      },
    ];


    let xcolumns = [];
    let response = this.state.groupTypeNames || [];

    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          filter: { type: "string", showField: true, checked: true },
          filterData: this.state.groups[response[i].group_type_id],
          exportcellproperty: { order: 200 + i },
          sortable: true,
          compact: true,
          tableIndex: 0,
          cell: (row, condition) => {
            return row[condition.selector];
          },
        });
      }
    }

    columns.splice.apply(columns, [1, 0].concat(xcolumns));
    return columns; //JSON.parse(JSON.stringify(columns))
  };

  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    //console.log("getFilterColumns", mode, cols)
    let xcols = [];
    //console.log("getFilterColumns-111", mode, xcols)
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "SSS_D1") {
      let xobj = objFilterData.availableSSS_D1Fields;
      //console.log("getFilterColumns-xobj", xobj)
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    }
    //console.log("getFilterColumns-222", mode, xcols, cols)
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      //console.log(row[field])
      if (row[field] && row[field] !== 1 && row[field] !== 0) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  checkFilterCondition = (filters, data) => {
    // console.log("checkFilterCondition 0", filters, data);
    for (var i in filters) {
      if (filters[i].condition && filters[i].checked) {
        let dvalue = data[filters[i].selector];
        let fdvalue = [];
        let fselector = filters[i].selector.split(",");
        for (var xi = 0; xi < fselector.length; xi++) {
          fdvalue.push(data[fselector[xi]]);
        }
        dvalue = fdvalue.join(" ");
        let cvalue = filters[i].condition.value;
        let xr = false;
        let dtime;
        let ctime;
        let ctime1;
        let ctime2;
        if (filters[i].condition.operator === "NOCONDITION") {
          xr = true;
        } else {
          if (filters[i].type === "string") {
            if (filters[i].condition.operator === "CONTAINS") {
              //cvalue != "" && dvalue != null &&
              if (dvalue == null) {
                xr = false;
              } else if (
                dvalue.toLowerCase().split(cvalue.toLowerCase()).length > 1
              ) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "DOES_NOT_CONTAIN") {
              if (dvalue == null) {
                xr = false;
              } else if (
                dvalue.toLowerCase().split(cvalue.toLowerCase()).length === 1
              ) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue.toLowerCase() === cvalue.toLowerCase()) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue.toLowerCase() !== cvalue.toLowerCase()) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "MULTI_EQUALS") {
              let rxr = 0;
              if (cvalue === "") {
                xr = true;
              } else {
                for (let xi = 0; xi < cvalue.length; xi++) {
                  if (dvalue.toLowerCase() === cvalue[xi].value.toLowerCase()) {
                    rxr = 1;
                    break;
                  }
                }
                if (filters[i].multi) {
                  for (let xi = 0; xi < cvalue.length; xi++) {
                    if (
                      dvalue
                        .toLowerCase()
                        .indexOf(cvalue[xi].value.toLowerCase()) > -1
                    ) {
                      rxr = 1;
                      break;
                    }
                  }
                }
                if (filters[i].label) {
                  for (let xi = 0; xi < cvalue.length; xi++) {
                    if (
                      (
                        this.props.labels.labels[
                        "LBL" + dvalue.toUpperCase()
                        ] || ""
                      )
                        .toLowerCase()
                        .indexOf(cvalue[xi].value.toLowerCase()) > -1
                    ) {
                      rxr = 1;
                      break;
                    }
                  }
                }
                xr = rxr === 0 ? false : true;
              }
            }
          } else if (filters[i].type === "number") {
            if (filters[i].condition.operator === "EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue === cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue !== cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "GREATER_THAN") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > cvalue) {
                xr = true;
              }
            } else if (
              filters[i].condition.operator === "GREATER_THAN_EQUALS"
            ) {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue >= cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue <= cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "BETWEEN") {
              var min = Math.min.apply(Math, cvalue),
                max = Math.max.apply(Math, cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > min && dvalue < max) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_BETWEEN") {
              var minTwo = Math.min.apply(Math, cvalue),
                maxTwo = Math.max.apply(Math, cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < minTwo || dvalue > maxTwo) {
                xr = true;
              }
            }
          } else if (filters[i].type === "date") {
            var xgetTime = function (x) {
              let dx = new Date(x);
              dx.setHours(0);
              dx.setMinutes(0);
              dx.setMinutes(0);
              dx.setSeconds(0);
              dx.setMilliseconds(0);
              return dx.getTime();
            };
            let dayscondition =
              "BETWEEN,LAST7DAYS,LAST30DAYS,THISMONTH,LASTMONTH";
            let daycondition = "TODAY,YESTERDAY,EQUALS";
            if (daycondition.indexOf(filters[i].condition.operator) > -1) {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue === null || cvalue === null) {
                xr = false;
              } else if (dtime === ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "GREATER_THAN") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue === null) {
                xr = false;
              } else if (dtime > ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue === null) {
                xr = false;
              } else if (dtime < ctime) {
                xr = true;
              }
            } else if (
              dayscondition.indexOf(filters[i].condition.operator) > -1
            ) {
              //LAST7DAYS,LAST30DAYS,THISMONTH,LASTMONTH:

              //TODAY,YESTERDAY:
              dtime = xgetTime(dvalue);
              ctime1 = xgetTime(cvalue[0]);
              ctime2 = xgetTime(cvalue[1]);
              var minThree = Math.min.apply(Math, [ctime1, ctime2]);
              var maxThree = Math.max.apply(Math, [ctime1, ctime2]);
              // console.log("min,max,dvalue,", min, max, dtime);
              if (dvalue === null) {
                xr = false;
              } else if (dtime >= minThree && dtime <= maxThree) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_BETWEEN") {
              dtime = xgetTime(dvalue);
              ctime1 = xgetTime(cvalue[0]);
              ctime2 = xgetTime(cvalue[1]);
              var minFour = Math.min.apply(Math, [ctime1, ctime2]),
                maxFour = Math.max.apply(Math, [ctime1, ctime2]);
              if (dvalue === null) {
                xr = false;
              } else if (dtime < minFour || dtime > maxFour) {
                xr = true;
              }
            }
          }
        }
        if (xr === false) {
          return false;
        }
      }
    }
    return true;
  };

  onExport = (type, id, conditions) => {
    //console.log("onExport", type, id, conditions)
    if (id === "F1") {
      this.setFilterFields("F1", conditions);
      let data = { type: type, columns: objFilterData.availableSSS_D1Fields };
      data.filtercolumns = conditions;
      let index = 0;
      let xcondition = conditions[index].filter.condition;
      // console.log("SSSSSSSSSSSSSSSSSSSS", xcondition);
      let dt1, dt2;
      if (typeof xcondition.value[0] == "string") {
        let arrdt = xcondition.value[0].split("T")[0].split("-");
        xcondition.value[0] = new Date(
          arrdt[0],
          parseInt(arrdt[1]) - 1,
          arrdt[2]
        );
      }
      if (xcondition.value[1] && typeof xcondition.value[1] == "string") {
        let arrdt = xcondition.value[1].split("T")[0].split("-");
        xcondition.value[1] = new Date(
          arrdt[0],
          parseInt(arrdt[1]) - 1,
          arrdt[2]
        );
      }
      if (xcondition.value[0] && xcondition.value[1]) {
        dt1 = new Date(xcondition.value[0].getTime());
        dt2 = new Date(xcondition.value[1].getTime());
      } else {
        dt1 = new Date(xcondition.value.getTime());
        dt2 = new Date(xcondition.value.getTime());
      }
      data.date1 = new Date(
        dt1.getFullYear(),
        dt1.getMonth(),
        dt1.getDate(),
        12,
        0,
        0,
        0
      );
      data.date2 = new Date(
        dt2.getFullYear(),
        dt2.getMonth(),
        dt2.getDate(),
        12,
        0,
        0,
        1
      );
      data.sdate1 = this.getDateFormat(data.date1);
      data.sdate2 = this.getDateFormat(data.date2);
      data.offsetTime = this.getOffsetTime();
      data.dateformat = this.props.dateformat;
      data.timeformat = this.props.timeformat;
      this.getSSS_D1_Export(data);
    }
  };
  setFilterFields = (id, conditions) => {
    //console.log("setFilterFields", id, conditions)
    let xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportfunction) {
        xobj[conditions[i].name].exportfunction = conditions[i].exportfunction;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableSSS_D1Fields = xobj;
    }
  };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions) => {
    // console.log("onFilterChangeeeeee2", conditions);
    this.setFilterFields("F1", conditions);
    let data = { type: "web", columns: objFilterData.availableSSS_D1Fields };
    data.filtercolumns = conditions;

    // console.log("onFilterChangeeeeee", conditions);
    //let xobj = {};
    this.setFilterFields("F1", conditions);
    let index = 0;
    if (id === "F1") {
      let xcondition = conditions[index].filter.condition;
      if (conditions[index].filter.type === "date") {
        // console.log("SSSSSSSSSSSSSSSSSSSS", xcondition);
        let dt1, dt2;
        if (typeof xcondition.value[0] == "string") {
          let arrdt = xcondition.value[0].split("T")[0].split("-");
          xcondition.value[0] = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2]
          );
        }
        if (xcondition.value[1] && typeof xcondition.value[1] == "string") {
          let arrdt = xcondition.value[1].split("T")[0].split("-");
          xcondition.value[1] = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2]
          );
        }
        if (xcondition.value[0] && xcondition.value[1]) {
          dt1 = new Date(xcondition.value[0].getTime());
          dt2 = new Date(xcondition.value[1].getTime());
        } else {
          dt1 = new Date(xcondition.value.getTime());
          dt2 = new Date(xcondition.value.getTime());
        }

        data.date1 = new Date(
          dt1.getFullYear(),
          dt1.getMonth(),
          dt1.getDate(),
          12,
          0,
          0,
          0
        );
        data.date2 = new Date(
          dt2.getFullYear(),
          dt2.getMonth(),
          dt2.getDate(),
          12,
          0,
          0,
          1
        );
        data.sdate1 = this.getDateFormat(data.date1);
        data.sdate2 = this.getDateFormat(data.date2);
        data.offsetTime = this.getOffsetTime();
        this.getSSS_D1_List(data);
      } else {
        this.applyFilterCondition();
      }
    }
  };
  renderPartDetailResponse = (xcc_item, xprogress) => {
    let completed = xcc_item.y
    return <div className="col-sm-2" style={{ display: "inline-block", padding: "0", textAlign: "left" }}>
      <div style={{ width: "75%", textAlign: "right" }}>
        {xcc_item.count}
      </div>

      {
        (xprogress == true) ? (
          <>
            <div style={{ width: "80%", height: "6px", overflow: "hidden", borderRadius: "3px", marginTop: "-2px" }}>
              <div
                className="progress"
                style={{ height: "3px !important", top: "-3px" }}
              >
                <div
                  className="progress-bar bg-play"
                  role="progressbar"
                  style={{
                    width: completed + "%",
                  }}
                  aria-valuenow={completed}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div></>
        ) : (<></>)
      }
    </div>
  }
  renderDetailResponse = (options, tvObj) => {
    const { labels } = this.props;
    return <div style={{ marginTop: "20px", padding: "10px" }}>
      <div className="row cls_rpt_title">
        <div className="col-sm-4" style={{ display: "inline-block", padding: "0" }}>{labels.labels.LBLDETAILRESPONSE}</div>
        <div className="col-sm-2" style={{ display: "inline-block", padding: "0", textAlign: "left" }}>{labels.labels.LBLHOURLY}</div>
        <div className="col-sm-2" style={{ display: "inline-block", padding: "0", textAlign: "left" }}>{labels.labels.LBLSUPERVISOR}</div>
        <div className="col-sm-2" style={{ display: "inline-block", padding: "0", textAlign: "left" }}>{labels.labels.LBLMANAGER}</div>
        <div className="col-sm-2" style={{ display: "inline-block", padding: "0", textAlign: "left" }}>{labels.labels.LBLALLROLE}</div>
      </div>
      {
        options.data.map((c_item, c_index) => {
          return <div className="row" style={{ padding: "6px" }}>
            <div className="col-sm-4" style={{ display: "inline-block", padding: "0" }}>{c_item.legendText}</div>
            {
              c_item.dataPoints.map((cc_item, cc_index) => {
                return this.renderPartDetailResponse(cc_item, true)
              })
            }
            {this.renderPartDetailResponse(c_item.thdataPoints, true)}
          </div>
        })
      }
      <div className="row" style={{ padding: "6px" }}>
        <div className="col-sm-4" style={{ display: "inline-block", padding: "0" }}>{labels.labels.LBLTOTAL}Total </div>
        {this.renderPartDetailResponse(tvObj["LBLHOURLY"], false)}
        {this.renderPartDetailResponse(tvObj["LBLSUPERVISOR"], false)}
        {this.renderPartDetailResponse(tvObj["LBLMANAGER"], false)}
        {this.renderPartDetailResponse(tvObj["LBLALLROLE"], false)}
      </div>
    </div>
  }
  renderCanvasComponent = (qitem, mode) => {
    const { labels } = this.props;
    let qanswersids = [];
    let dataPoints0 = [];
    let dataPoints1 = [];

    let graphtype = this.state.graphType;
    let options0 = {};
    let options1 = {};
    if (graphtype == "pie") {
      obj_safestartsurvey.map((objmod, modindex) => {
        if (objmod.questions) {
          objmod.questions.map((questions, questionindex) => {
            if (qitem.sid == questions.sid) {
              questions.options.map((item, index) => {
                qanswersids.push(item.sid);
                dataPoints0.push({
                  y: this.state.SSS_D1_List[0][item.sid],
                  label: labels.labels[item.label],
                });
                if (this.state.SSS_D1_List[1]) {
                  dataPoints1.push({
                    y: this.state.SSS_D1_List[1][item.sid],
                    label: labels.labels[item.label],
                  });
                }
              });
            }
          });
        }
      });
      options0 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPRETRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        data: [
          {
            type: "pie",
            //startAngle: 75,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            toolTipContent: "<b>{label}</b>: {y}",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}",
            dataPoints: dataPoints0,
          },
        ],
      };
      options1 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPOSTTRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        data: [
          {
            type: "pie",
            //startAngle: 75,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            toolTipContent: "<b>{label}</b>: {y}",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}",
            dataPoints: dataPoints1,
          },
        ],
      };
      return (
        <div className="col-xl-12">
          <div
            className="col-xl-6"
            style={{ display: "inline-block", padding: "0" }}
          >
            <CanvasJSChart options={options0} />
          </div>
          <div
            className="col-xl-6"
            style={{ display: "inline-block", padding: "0" }}
          >
            <CanvasJSChart options={options1} />
          </div>
        </div>
      );
    } else if (graphtype == "bar") {
      let xDatas0 = []
      let xDatas1 = []
      let xComments0 = []
      let xComments1 = []
      let tvdataPoints0 = {
        "LBLHOURLY": { tcount: 0, count: 0 },
        "LBLSUPERVISOR": { tcount: 0, count: 0 },
        "LBLMANAGER": { tcount: 0, count: 0 },
        "LBLALLROLE": { tcount: 0, count: 0 }
      }
      let tvdataPoints1 = {
        "LBLHOURLY": { tcount: 0, count: 0 },
        "LBLSUPERVISOR": { tcount: 0, count: 0 },
        "LBLMANAGER": { tcount: 0, count: 0 },
        "LBLALLROLE": { tcount: 0, count: 0 }
      }
      obj_safestartsurvey.map((objmod, modindex) => {
        if (objmod.questions) {
          objmod.questions.map((questions, questionindex) => {
            if (qitem.sid == questions.sid) {
              // console.log("renderCanvasComponent", questions)
              questions.options.map((item, index) => {
                qanswersids.push(item.sid);
                let xODatas0 = {
                  type: "stackedBar",
                  toolTipContent: labels.labels[item.label] + " - {y} %<br /> Value: {count} Out Of {tcount}",
                  legendText: labels.labels[item.label],
                  showInLegend: "true",
                  dataPoints: [],

                  thdataPoints: {
                    tcount: 0,
                    count: 0
                  }
                }
                let xODatas1 = {
                  type: "stackedBar",
                  toolTipContent: labels.labels[item.label] + " - {y} %<br /> Value: {count} Out Of {tcount}",
                  legendText: labels.labels[item.label],
                  showInLegend: "false",
                  dataPoints: [],

                  thdataPoints: {
                    tcount: 0,
                    count: 0
                  }
                }
                let xOComments0 = {
                  Text: labels.labels[item.label],
                  user_inputs: []
                }
                let xOComments1 = {
                  Text: labels.labels[item.label],
                  user_inputs: []
                }

                for (let i = 0; i < this.state.SSS_D1_List.length; i++) {
                  console.log("launchmode==", this.state.SSS_D1_List[i])
                  if (this.state.SSS_D1_List[i].role == "LBLHOURLY" || this.state.SSS_D1_List[i].role == "LBLSUPERVISOR" || this.state.SSS_D1_List[i].role == "LBLMANAGER") {
                    if (parseInt(this.state.SSS_D1_List[i].launchmode) == 0) {
                      xODatas0.dataPoints.push(
                        {
                          y: parseInt((this.state.SSS_D1_List[i][item.sid] * 100) / this.state.SSS_D1_List[i].counts),
                          label: labels.labels[this.state.SSS_D1_List[i].role],
                          tcount: this.state.SSS_D1_List[i].counts,
                          count: this.state.SSS_D1_List[i][item.sid],
                          role_value: this.state.SSS_D1_List[i].role_value
                        }
                      )
                      xODatas0.thdataPoints.count = xODatas0.thdataPoints.count + this.state.SSS_D1_List[i][item.sid]
                      xODatas0.thdataPoints.tcount = xODatas0.thdataPoints.tcount + this.state.SSS_D1_List[i].counts

                      tvdataPoints0[this.state.SSS_D1_List[i].role].count = tvdataPoints0[this.state.SSS_D1_List[i].role].count + this.state.SSS_D1_List[i][item.sid]
                      tvdataPoints0[this.state.SSS_D1_List[i].role].tcount = tvdataPoints0[this.state.SSS_D1_List[i].role].tcount

                      tvdataPoints0["LBLALLROLE"].count = tvdataPoints0["LBLALLROLE"].count + this.state.SSS_D1_List[i][item.sid]
                      tvdataPoints0["LBLALLROLE"].tcount = tvdataPoints0["LBLALLROLE"].tcount

                      if (item.type === "user_input" && this.state.SSS_D1_List[i][item.sid + "_userval"]) {
                        let xs = this.state.SSS_D1_List[i][item.sid + "_userval"].split(";~:~;")
                        for (let j = 0; j < xs.length; j++) {
                          if (xs[j] && xs[j] != "") {
                            xOComments0.user_inputs.push(xs[j])
                          }
                        }
                      }

                    } else {
                      xODatas1.dataPoints.push(
                        {
                          y: parseInt((this.state.SSS_D1_List[i][item.sid] * 100) / this.state.SSS_D1_List[i].counts),
                          label: labels.labels[this.state.SSS_D1_List[i].role],
                          tcount: this.state.SSS_D1_List[i].counts,
                          count: this.state.SSS_D1_List[i][item.sid],
                          role_value: this.state.SSS_D1_List[i].role_value
                        },
                      )
                      xODatas1.thdataPoints.count = xODatas1.thdataPoints.count + this.state.SSS_D1_List[i][item.sid]
                      xODatas1.thdataPoints.tcount = xODatas1.thdataPoints.tcount + this.state.SSS_D1_List[i].counts

                      tvdataPoints1[this.state.SSS_D1_List[i].role].count = tvdataPoints1[this.state.SSS_D1_List[i].role].count + this.state.SSS_D1_List[i][item.sid]
                      tvdataPoints1[this.state.SSS_D1_List[i].role].tcount = tvdataPoints1[this.state.SSS_D1_List[i].role].tcount + this.state.SSS_D1_List[i].counts

                      tvdataPoints1["LBLALLROLE"].count = tvdataPoints1["LBLALLROLE"].count + this.state.SSS_D1_List[i][item.sid]
                      tvdataPoints1["LBLALLROLE"].tcount = tvdataPoints1["LBLALLROLE"].tcount

                      if (item.type == "user_input" && this.state.SSS_D1_List[i][item.sid + "_userval"]) {
                        let xs = this.state.SSS_D1_List[i][item.sid + "_userval"].split(";~:~;")
                        for (let j = 0; j < xs.length; j++) {
                          if (xs[j] && xs[j] != "") {
                            xOComments1.user_inputs.push(xs[j])
                          }
                        }
                      }
                    }
                  }

                }
                console.log("xODatas0", xODatas0)
                console.log("xODatas1", xODatas1)
                xODatas0.dataPoints.sort((a, b) => {
                  return a.role_value - b.role_value;
                });
                xODatas1.dataPoints.sort((a, b) => {
                  return a.role_value - b.role_value;
                });
                xDatas0.push(xODatas0)
                xDatas1.push(xODatas1)
                xComments0.push(xOComments0)
                xComments1.push(xOComments1)
              });
            }
          return null;
          });
        }
        return null;
      });
      console.log("xDatas0", xDatas0)
      console.log("xDatas1", xDatas1)
      options0 = {
        exportEnabled: false,
        animationEnabled: true,
        // height: 300,
        title: {
          text: this.props.labels.labels.LBLPRETRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        axisY: {
          gridThickness: 0,
          interval: 10,
          maximum: 100,
        },
        data: xDatas0
      };
      options1 = {
        exportEnabled: false,
        animationEnabled: true,
        // height: 300,
        title: {
          text: this.props.labels.labels.LBLPOSTTRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        axisY: {
          gridThickness: 0,
          interval: 10,
          maximum: 100,
        },
        // dataPointMaxWidth: 20,
        data: xDatas1
      };
      let optionx = {
        title: {
          text: "Evening Sales of a Restaurant"
        },
        data: [
          {
            type: "stackedBar",
            toolTipContent: "option1: - {y} ",
            legendText: "option1",
            showInLegend: "true",
            dataPoints: [
              { y: 20, label: "role1x", name: "role1" },
              { y: 40, label: "role2x", name: "role2" },
              { y: 60, label: "role3x", name: "role3" }
            ]
          },
          {
            type: "stackedBar",
            toolTipContent: "option2: - {y} ",
            legendText: "option2",
            showInLegend: "true",
            dataPoints: [
              { y: 25, label: "role1x", name: "role1" },
              { y: 45, label: "role2x", name: "role2" },
              { y: 65, label: "role3x", name: "role3" }
            ]
          },
          {
            type: "stackedBar",
            toolTipContent: "option3: - {y} ",
            legendText: "option3",
            showInLegend: "true",
            dataPoints: [
              { y: 10, label: "role1x", name: "role1" },
              { y: 10, label: "role2x", name: "role2" },
              { y: 10, label: "role3x", name: "role3" }
            ]
          },
          {
            type: "stackedBar",
            toolTipContent: "option4: - {y} ",
            legendText: "option4",
            showInLegend: "true",
            dataPoints: [
              { y: 13, label: "role1x", name: "role1" },
              { y: 45, label: "role2x", name: "role2" },
              { y: 22, label: "role3x", name: "role3" }
            ]
          }
        ]
      }
      return (
        <>
          <div className="row col-xl-12">
            <div
              className="col-xl-12"
              style={{ display: "inline-block", padding: "0", textAlign: "center" }}
            >
              <div

                style={{ display: "inline-block", padding: "0", width: "80%" }}
              >
                <CanvasJSChart options={options0} />
              </div>
              {this.renderDetailResponse(options0, tvdataPoints0)}
              {xComments0.map((c_item, c_index) => {
                return <>
                  {
                    (c_item.user_inputs.length > 0) ? (<>
                      <div style={{ marginTop: "20px", padding: "10px" }}>
                        <div className="cls_rpt_title">
                          {labels.labels.LBLOTHERSSPECIFIED} - {c_item.Text}
                        </div>
                        <div style={{ backgroundColor: "#ffc0cb4d", borderBottom: "1px solid grey", borderRight: "1px solid grey", borderLeft: "1px solid grey", padding: "0px !important" }}>
                          {
                            c_item.user_inputs.map((cc_item, cc_index) => {
                              return <>
                                <div style={{ margin: "0px", borderTop: "1px solid grey", padding: "4px 4px 4px 10px" }}>{cc_item}</div>
                              </>
                            })
                          }
                        </div>
                      </div>
                    </>) : (<></>)
                  }
                </>
              })}
            </div>
            <div
              className="col-xl-12"
              style={{ display: "inline-block", padding: "0" }}
            >
              <div

                style={{ display: "inline-block", padding: "0", width: "80%" }}
              >
                <CanvasJSChart options={options1} />
              </div>

              {this.renderDetailResponse(options1, tvdataPoints1)}
              {xComments1.map((c_item, c_index) => {
                return <>
                  {
                    (c_item.user_inputs.length > 0) ? (<>
                      <div style={{ marginTop: "20px", padding: "10px" }}>
                        <div className="cls_rpt_title">
                          {labels.labels.LBLOTHERSSPECIFIED} - {c_item.Text}
                        </div>
                        <div style={{ backgroundColor: "#ffc0cb4d", borderBottom: "1px solid grey", borderRight: "1px solid grey", borderLeft: "1px solid grey", padding: "0px !important" }}>
                          {
                            c_item.user_inputs.map((cc_item, cc_index) => {
                              return <>
                                <div style={{ margin: "0px", borderTop: "1px solid grey", padding: "4px 4px 4px 10px" }}>{cc_item}</div>
                              </>
                            })
                          }
                        </div>
                      </div>
                    </>) : (<></>)
                  }
                </>
              })}
            </div>
          </div>
        </>
      );
    }
  };
  changeReportType = (graphtype) => {
    this.setState({
      graphType: graphtype,
    });
  };
  getGraphTypeClassName = (xtype) => {
    if (xtype === this.state.graphType) {
      return classList({
        cls_selected: true,
        cls_chart_type: true,
      });
    } else {
      return classList({
        cls_selected: false,
        cls_chart_type: true,
      });
    }
  };
  render() {
    const {
      LBLSSSURVEYREPORT,
      LBLLEARNERCRSPROGEXPTYTXT,
    } = this.props.labels.labels;

    if (this.state.pageState === "SSS_D1") {
      let columns = this.getSSS_D1_Columns();
      let filterFields = this.getFilterColumns("SSS_D1", columns);
      let resetfilterFields = this.getResetFilterColumns("SSS_D1", columns);

      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < filterFields.length; j++) {
          if (
            filterFields[j] &&
            columns[i] &&
            filterFields[j].name === columns[i].name &&
            filterFields[j].filter.showField === false
          ) {
            columns.splice(i, 1);
            i--;
          }
        }
      }
      objFilterData.orgavailableSSS_D1Fields = this.getFilterColumns(
        "SSS_D1",
        this.getSSS_D1_Columns()
      );

      const { SSS_D1_List, filterDataLoaded } = this.state;
      let cur_mod_index = this.state.cur_mod_index;
      let cur_mod_page_index = this.state.cur_mod_page_index;
      let page_question_count = this.state.page_question_count;
      let displaycondvalue =
        this.state.passvars !== "" ? this.state.passvars.split("_")[1] || 0 : 0;
      const { qanswers } = this.state;
      const { labels } = this.props;
      return (
        <div className="reportsCtn"> 
          {/* <div className="card-box"> */}
          <div className="mob-pad">
            <div >
              <h3 className="page-title">{LBLSSSURVEYREPORT}</h3>
            </div>
            <hr className="head-line" />
            <div id="accordion" className="row rptlst-row">
              {this.state.loading === false &&
                this.state.filterDataStatus === true &&
                filterFields.length >= 1 ? (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    {this.state.loading === false &&
                      this.state.filterDataLoaded === 1 &&
                      (filterFields.length > 1 || this.props.ssi_data_priv) ? (
                      <div>
                        <CustomFilter
                          availableFields={filterFields}
                          selectedFields={filterFields}
                          orgAvailableFields={resetfilterFields}
                          onFilterChange={this.onFilterChange}
                          onExport={this.onExport}
                          id="F1"
                          onRef={(ref) => (REFS.F1 = ref)}
                          labels={this.props.labels.labels}
                          reportName={LBLSSSURVEYREPORT}
                          companyName={this.props.company_name}
                          showFieldFilter={true}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {SSS_D1_List.length === 0 ? (
                    <EmptyState image={emptyImage} text2={LBLLEARNERCRSPROGEXPTYTXT} />
                  ) : (
                    <>
                      <div
                        style={{ display: "" }}
                        id="cls_report_list"
                        className="cls_report_list"
                      >
                        <div className="cls_reports_item2">
                          <div className="row">
                            <div
                              className="col-xl-10"
                              style={{ display: "flex" }}
                            ></div>
                            <div
                              className="col-xl-2 cls_rpt_chng"
                              style={{ display: "flex" }}
                            >
                              <div
                                id="piechart"
                                onClick={() => this.changeReportType("pie")}
                                className={this.getGraphTypeClassName("pie")}
                              >
                                <img src="../../images/nearmiss/pie_icon.png" alt=""></img>
                              </div>
                              <div
                                id="barchart"
                                onClick={() => this.changeReportType("bar")}
                                className={this.getGraphTypeClassName("bar")}
                              >
                                <img src="../../images/nearmiss/Bar_icon.png" alt=""></img>
                              </div>
                            </div>
                          </div>
                          {qanswers[cur_mod_index].questions
                            .slice(
                              cur_mod_page_index * page_question_count,
                              cur_mod_page_index * page_question_count +
                              page_question_count
                            )
                            .map((item, i) => {
                              if (
                                !item.displaycondition ||
                                (item.displaycondition &&
                                  item.displaycondition.LAUNCHMODE ==
                                  displaycondvalue)
                              ) {
                                if (item.options[0].type === "user_input") {
                                  return <></>;
                                } else {
                                  if (i === 0) {
                                    return <></>;
                                  } else {
                                    return (
                                      <div
                                        className="row cls_rpt_titleset"
                                        style={{
                                          borderBottom:
                                            i == qanswers.length - 1
                                              ? "1px solid #CDCDCD"
                                              : "",
                                        }}
                                      >
                                        <div className="col-xl-12">
                                          <div className="cls_rpt_title" style={{ fontSize: "14" }}>
                                            {i}. {labels.labels[item.label]}
                                          </div>
                                        </div>
                                        <div className="col-xl-12 ss-svy-rpt">
                                          <>
                                            {this.renderCanvasComponent(item)}
                                          </>

                                        </div>
                                      </div>
                                    );
                                  }

                                }
                              }
                              return null;
                            })}
                        </div>
                      </div>

                      <div className="row zrowbox">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div style={{ width: "100%", paddingLeft: "15px" }}>
                  <div>
                    <div>
                      <div>
                        <div>
                          <h3>
                            <Skeleton height={21} />
                          </h3>
                          <h2>
                            <Skeleton height={26} />
                          </h2>
                          <div>
                            <div>
                              <div className="align-items-top">
                                <div>
                                  <Skeleton height={19} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row zrowbox">
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="form-group col-xl-3 col-lg-3 col-md-3 ml-auto mr-12">
                        <Skeleton height={34} />
                      </div>
                      <Skeleton height={400} />
                    </div>
                  </div>

                  <div className="bottomborder">
                    <span className="subHeader">
                      <h4>
                        <Skeleton height={38} />
                      </h4>
                    </span>
                  </div>
                  <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                    <div className="sc-fznWqX gnahTY">
                      <div className="sc-AxjAm gIMaKV rdt_Table">
                        <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                          <Skeleton height={56} />
                        </div>
                        <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                          <Skeleton height={480} />
                        </div>
                      </div>
                    </div>
                    <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                      <Skeleton height={56} />
                    </nav>
                  </div>
                  <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                    <header className="sc-fzoXzr hlidmt">
                      <Skeleton height={34} />
                    </header>
                    <div className="sc-fznWqX gnahTY">
                      <div className="sc-AxjAm gIMaKV rdt_Table">
                        <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                          {skel.map((i) => {
                            return (
                              <div
                                id={"row-" + i}
                                className="sc-fzoLsD doBktq rdt_TableRow"
                              >
                                <Skeleton height={280} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                      <div className="sc-fzpmMD ftIMtk">
                        <Skeleton height={40} />
                      </div>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div>No Data for {this.state.pageState}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(SSSReport);

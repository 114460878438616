import React from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import UserManagementForm from "./userManagementForm";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import { Form } from "react-bootstrap";
import orderBy from "lodash/orderBy";
import { connect } from "react-redux";
import UsersService from "./UsersService";
import GroupServices from "../group-list/GroupServices";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import { Link } from "react-router-dom";
import FilterComp from "../../../common_components/filter/filterComp";
import Skeleton from "react-loading-skeleton";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import styles from "./userManagementForm.module.scss";

const $ = require("jquery");
let changesIn = {};
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortObj: { "column": "name", order: "asc" },
      shownorecs: false,
      userlist: [],
      roles: [],
      visible: "none",
      orgs: [],
      groups: [],
      languages: [],
      loading: true,
      show: false,
      showFilter: false,
      status: 0,
      initialValues: null,
      ssi_errors: null,
      searchValue: "",
      roleFilter: "",
      groupData: [],
      group_type: "",
      roleValid: false,
      mailPhoneValid: false,
      groups_by_type: [],
      helpersLoaded: false,
      showTab: "info",
      showEditUserNameAlert: false,
      allFilterValues: [],
      enableEditUsername: false,
      disableEmailField: false,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
    };
    this.handleSelectAll = this.handleSelectAll.bind(this);
  }

  applyFilters = (filter) => {
    let { userlist, filteredUsers } = this.state;
    filteredUsers.map((item, idx) => {
      $("#chk_" + item.user_id).prop("checked", false);
      item.isNewAssigned = 0;
    });

    this.setState({
      filteredUsers: filteredUsers,
      allFilterValues: filter,
      showNode: true,
      showval: true,
      shownorecs: true,
    }, () => {
      this.filterUsers("filter")
      this.props.usersLoaded(this.state.filteredUsers)
    });
    if (!filter.reset) {
      this.setState({ visible: "block" });
    }
    $(".bounceInDown").hide();
    $(".modal-backdrop").hide();

    $("#root").css({ overflowY: "auto", overflowX: "hidden" });
    $("body").css({ overflowY: "auto", paddingRight:"0px", overflowX: "hidden"});
  };
  keyvaluechanges = (response) => {
    let keys1,
      keys2,
      allGroups,
      objAllGroups = [];
    let groupname,
      grouptypeid,
      objRoles,
      singles = [];
    let objStatus = [
      { name: this.props.getLabel("LBLACTIVE"), id: "1" },
      { name: this.props.getLabel("LBLINACTIVE"), id: "0" },
    ];
    let objUserNS = [
      { name: this.props.getLabel("LBLEMAILVERIFIED"), id: 201 },
      { name: this.props.getLabel("LBLAPPUSER"), id: 202 },
    ];
    let objUserTypes = [
      { id: 101, name: this.props.getLabel("LBLNOTINVITEDUSERS") },
      { id: 102, name: this.props.getLabel("LBLPENDINGACCEPTANCE") },
      { id: 103, name: this.props.getLabel("LBLSENDREMINDERACCEPTED") },
      { id: 104, name: this.props.getLabel("LBLUSERNOTHAVEEMAIL") }
    ];
    this.props.labels.labels.LBLITMADDED = this.props.getLabel("LBLITMADDED")
    let xroles = JSON.parse(JSON.stringify(response.roles))
    for (let i = 0; i < xroles.length; i++) {
      xroles[i].role_name = this.props.getLabel(xroles[i].role_name)
    }
    allGroups = response.groups;
    objRoles = JSON.parse(
      JSON.stringify(xroles)
        .split('"role_id":')
        .join('"id":')
        .split('"role_name":')
        .join('"name":')
    );
    objAllGroups.push({
      items: JSON.stringify(objRoles),
      itemsinfo: {
        itemid: "roles",
        itemname: this.props.getLabel("LBLROLE"),
        label: this.props.getLabel("LBLROLE"),
        trans: false
      },
    });
    singles.push({
      items: objStatus,
      itemsinfo: {
        itemid: "status",
        itemname: "status",
        label: this.props.getLabel("LBLSTATUS"),
        trans: false,
      },
    });

    if (typeof allGroups == "object") {
      keys1 = Object.keys(allGroups);
      allGroups.map((element) => {
        if (typeof element == "object") {
          keys2 = Object.keys(element);

          if (typeof element.groups_by_type != "undefined") {
            if (typeof element.group_info == "string") {
              grouptypeid = JSON.parse(element.group_info).group_type_id;
              groupname = JSON.parse(element.group_info)["lbl_" + grouptypeid];
            } else if (typeof element.group_info == "object") {
              grouptypeid = element.group_info.group_type_id;
              groupname = element.group_info["lbl_" + grouptypeid];
            } else {
              groupname = "Group";
            }
            let groups = "";
            if (typeof element.groups_by_type == "string") {
              groups = element.groups_by_type
                .split('"group_id":')
                .join('"id":')
                .split('"group_name":')
                .join('"name":');
              JSON.parse(
                objAllGroups.push({
                  items: groups,
                  itemsinfo: {
                    itemid: groupname,
                    itemname: groupname,
                    label: groupname
                  },
                })
              );
            } else {
              groups = JSON.stringify(
                element.groups_by_type
                  .split('"group_id":')
                  .join('"id":')
                  .split('"group_name":')
                  .join('"name":')
              );
              objAllGroups.push({
                items: groups,
                itemsinfo: {
                  itemid: groupname,
                  itemname: groupname,
                  label: groupname,
                },
              });
            }
          }
        }
      });
    }

    this.setState({
      toFilters: {
        multi: objAllGroups,
        single: singles,
      },
    });
    return objAllGroups;
  };
  closeModal = () => {
    this.setState({
      show: false,
      initialValues: null,
      showDel: false,
      ssi_errors: null,
    });
  };
  getAllUsers = () => {
    return this.state.alluserlist
  }
  getSelUsers = () => {
    return this.state.userlist
  };
  resetSelUsers = () => {
    let { userlist } = this.state;
    userlist.map((item, idx) => {
      $("#chk_" + item.user_id).prop("checked", false);
      item.isNewAssigned = 0;
    });
    
    this.setState({
      userlist

    })
  };
  componentDidMount() {
    this.props.onRef(this);
    this.getUsers();
    let getData = {
      language: this.props.language,
    };

    UsersService.usersHelpers(getData).then((response) => {
      this.keyvaluechanges(response);

      this.setState({
        roles: response.roles,
        groups_by_type: response.groups,
        languages: response.languages,
        helpersLoaded: true,
      });
    });
  }
  getUsers = () => {
    let xcheckNotifyType = this.props.checkNotifyType()
    let xisDisabled = (item) => {
      let xyz1
      if ((item.email_verified === 1 && xcheckNotifyType.indexOf("mail") > -1) || (item.user_with_app === 1 && xcheckNotifyType.indexOf("app") > -1)) {
        xyz1 = false
      } else {
        xyz1 = true
      }
      return xyz1
    }
    UsersService.getNotifyingUsers({})
      .then((res) => {
        let users = []
        let allusers = []
        for (let i = 0; i < res.result.length; i++) {
          if ((res.result[i].email_verified === 1 || res.result[i].user_with_app === 1)) {
            res.result[i].isDisabled = xisDisabled(res.result[i])
            res.result[i].isNewAssigned = 0
            users.push(res.result[i])
            allusers.push(res.result[i])
          }
        }
        this.setState((state, props) => ({
          userlist: users,
          alluserlist: allusers,
          loading: false,
          showFilter: false,
          shownorecs: true,
        }), (state) => {
          this.filterUsers()
        });
        this.props.usersLoaded(users)
      })
      .catch((err) => {
        if (err) throw err;
      });
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  openFilterModal = () => {
    let state = this.state.showNode;
    if (state) {
      $("#filterModal").show();
      this.setState({ showNode: false, visible: "none" });
    } else {
      this.setState({ showFilter: true, showNode: false, visible: "none" });
    }
    $(".bounceInDown").show();
    $(".modal-backdrop").show();
  };
  closeFilterModal = () => {
    $(".bounceInDown").hide();
    $(".modal-backdrop").hide();
    this.setState({
      showFilter: false,
      initialValues: null,
    });
  };
  searchUser = (e) => {
    this.setState({
      searchValue: e.target.value,
      shownorecs: true,
    }, () => {
      this.filterUsers("search");
    });
  };
  tableHeader = () => {
    if (this.state.loading === false && this.props.show_users === true && this.state.helpersLoaded === true) {
      return (
        <div style={{ marginTop: "30px" }} className="table-filter user-tab">
          <Modal
            show={this.state.showFilter}
            id="filterModal"
            backdrop="static"
            onHide={this.closeFilterModal}
            size="lg"
            data-easein="expandIn"
            className={
              "animated  bounceInDown " +
              (this.state.showFilter ? "expandIn" : "fadeOutUp")
            }
          >
            <Modal.Header closeButton>
              <h4 className="modal-title" id="exampleModalLongTitle">
                {this.props.getLabel("LBLUSRFILTER")}
              </h4>
            </Modal.Header>

            <FilterComp
              initialValues={this.state.initialValues}
              sendInvitation={this.sendInvitation}
              handleSubmit={this.applyFilters}
              filter={this.state.selAllValues}
              toFilters={this.state.toFilters}
              helpersLoaded={this.state.helpersLoaded}
              closeModal={this.closeModal}
              ssi_errors={this.state.ssi_errors}
              groupData={this.state.groupData}
              roleValid={this.state.roleValid}
              labels={this.props.labels}
              className="toggleClass"
            ></FilterComp>
          </Modal>
          <div style={{ float: "left !important", textAlign: "left !important" }}>
            <div
              className="clsSelFilters"
              style={{ display: this.state.visible }}
            >
              <span></span>
            </div>
            <div
              title={this.props.getLabel("LBLFILTER")}
              data-toggle="modal"
              data-target="#myModal"
              style={{
                width: "25px",
                zoom: "1.4",
                cursor: "pointer",
                margin: "0px 5px",
              }}
            >
              <i
                data-target="#myModal"
                onClick={this.openFilterModal}
                class="fa fa-filter"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <input
            style={{}}
            type="text"
            className={`input-field ${styles.usrNameOnly}`}
            placeholder={this.props.getLabel("LBLSEARCH")}
            value={this.state.searchValue}
            onChange={this.searchUser}
          />
        </div>
      );
    } else {
      return ""
    }
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (
        row[field] &&
        row[field] !== 1 &&
        row[field] !== 0 &&
        typeof row[field] !== "boolean"
      ) {
        return row[field].toString().toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  filterUsers = () => {
    const { userlist, searchValue, allFilterValues } = this.state;
    let allGroups = [],
      allGroupIds = [],
      allFilters = [],
      roleFilters = "",
      statusFilter = "",
      usertypeFilter = "";
    if (Object.keys(allFilterValues).length > 0) {
      allFilters = allFilterValues.multiItems;

      statusFilter = allFilterValues.singleItems.status
        ? allFilterValues.singleItems.status
        : "";


      let objkeys = Object.keys(allFilters);
      for (var m = 0; m < objkeys.length; m++) {
        if (objkeys[m] === "roles" && allFilters["roles"].length > 0) {
          let allRoleIds = []
          allFilters["roles"].map((item) => {
            if (item.id) {
              allRoleIds.push(item.id);
            }
          });
          roleFilters = "," + allRoleIds.join(",") + ","
        } else {
          allGroups =
            allFilters[objkeys[m]] && allFilters[objkeys[m]].length > 0
              ? allGroups.concat(allFilters[objkeys[m]])
              : allGroups.concat({});
        }
      }
      allGroups.map((item) => {
        if (item.id) {
          allGroupIds.push(item.id);
        }
      });
    }
    let xallFilters = JSON.parse(JSON.stringify(allFilters))

    delete xallFilters["usertype"]
    delete xallFilters["roles"]
    let filteredUsers = [];

    let xc = 1 // count of other multifilters like roles / usertypes
    let ftrChkCount = 0
    let ftrMchCount = 0
    let ftrChkIds = []
    if (allGroupIds.length > 0) {
      let xfilterData = Object.values(allFilters)
      for (let i = xc; i < xfilterData.length; i++) {
        if (xfilterData[i].length > 0) {
          ftrChkIds[ftrChkCount] = []
          for (let j = 0; j < xfilterData[i].length; j++) {
            ftrChkIds[ftrChkCount].push(xfilterData[i][j].id)
          }
          ftrChkCount++
        }
      }
    }

    if (userlist && userlist.length > 0) {
      filteredUsers = userlist.filter((item) => {        
        let checkRole = (role) => {
          let matchRole = role.split("~").filter((xr) => {
            if (roleFilters.indexOf("," + xr.split("-")[0]) > -1) {
              return xr
            }
          })
          return (matchRole.length > 0) ? "yes" : "no"
        }
        if (
          (item.name &&
            (item.name.includes(searchValue) ||
              item.name.toLowerCase().includes(searchValue.toLowerCase()))) ||
          (item.email &&
            (item.email.includes(searchValue) ||
              item.email.toLowerCase().includes(searchValue.toLowerCase()))) ||
          (item.phone_number &&
            (item.phone_number.includes(searchValue) ||
              item.phone_number
                .toLowerCase()
                .includes(searchValue.toLowerCase()))) ||
          (item.user_name &&
            (item.user_name.includes(searchValue) ||
              item.user_name.toLowerCase().includes(searchValue.toLowerCase())))
        ) {
          // console.log("XXC item.user_groups", item.user_groups)
          if (
            (roleFilters === "" || (item.role && checkRole(item.role) === "yes")) &&
            (statusFilter === "" || item.active == parseInt(statusFilter))
            && (usertypeFilter === ""
              || (usertypeFilter.indexOf(",101") > -1
                && ((item.user_with_nomail || 0) === 0)
                && (item.email || "") !== ""
                && (item.email_verified || 0) === 0
                && (item.email_invite_sent || 0) === 0)
              || (usertypeFilter.indexOf(",102") > -1
                && ((item.user_with_nomail || 0) === 0)
                && (item.email || "") !== ""
                && (item.email_invite_sent || 0) > 0
                && (item.email_verified || 0) === 0)
              || (usertypeFilter.indexOf(",103") > -1
                && ((item.user_with_nomail || 0) === 0)
                && (item.email || "") !== ""
                && (item.email_invite_sent || 0) > 0
                && (item.email_verified || 0) === 1)
              || (usertypeFilter.indexOf(",104") > -1
                && ((item.user_with_nomail || 0) === 1
                  || (item.email || "") === "")
              )
            )

          ) {


            let mx = 0,
              blnRetrn = false,
              usrGroupsNew = [{}, {}, {}, {}, {}, {}, {}],
              usrGroups = item.user_groups.split("~~~");

            for (let v = 0; v < usrGroups.length; v++) {
              if (JSON.parse(usrGroups[v]).group_id != null) {
                mx = JSON.parse(usrGroups[v]).group_type_id.split("_")[1];
                usrGroupsNew[mx - 1] = usrGroups[v];
              }
            }
            let ln = Object.keys(allFilters).length - 1,
              len = usrGroupsNew.length;

            if (allGroupIds.length > 0) {
              let ftrMchCount = 0
              for (let fxi = 0; fxi < ftrChkIds.length; fxi++) {
                // console.log("XXXXXX", fxi, "ftrChkIds[fxi]", ftrChkIds[fxi])
                for (let ugi = 0; ugi < usrGroupsNew.length; ugi++) {
                  if (typeof usrGroupsNew[ugi] === "string" && ftrChkIds[fxi].indexOf(JSON.parse(usrGroupsNew[ugi]).group_id) >= 0) {
                    ftrMchCount++
                  }
                }
                // console.log("ftrMchCount", ftrMchCount)
              }
              // console.log("ftrChkCount", ftrChkCount)
              if (ftrChkCount === ftrMchCount) {
                return item;
              }
            } else {
              return item;
            }            
          } else {
          }
        }
      });
    }

    this.setState({
      filteredUsers: filteredUsers,
    })
    return filteredUsers
  }
  updateUserAssignment = (item, status) => {
    for (var i = 0; i < this.state.userlist.length; i++) {
      if (item.user_id === this.state.userlist[i].user_id) {
        this.state.userlist[i].isNewAssigned = status ? 0 : 1;
        this.setState({
          userlist: this.state.userlist,
          loading: false,
        }, () => {
          this.props.usersLoaded(this.state.userlist)
        });
      }
    }
  };
  onSort = (column, sortDirection, event) => {
    let sortObj = { "column": column.selector, "order": sortDirection }
    this.setState({
      sortObj: sortObj
    })
  }
  handleSelectAll(items) {
    let { userlist, filteredUsers } = this.state;
    // let isChecked = event.currentTarget.checked;
    console.log("filteredUsers===", items.allSelected)
    let sortFlfName = this.state.sortObj.column
    let sortOrder = this.state.sortObj.order
    let labels = this.props.labels.labels
    // alert(sortFlfName + "--" + sortOrder)
    let formatDate = (date) => {
      return this.formattingDate(date)
    }
    function compare(a, b) {
      let xa = ""
      let xb = ""
      if (sortFlfName === "status") {
        xa = labels[("lbl" + (a.status || "Notstarted")).toUpperCase()]
        xb = labels[("lbl" + (b.status || "Notstarted")).toUpperCase()]
      } else if (sortFlfName === "completed_date") {
        if (a.completed_date == null) {
          xa = "zzzzzz"
        } else {
          xa = formatDate(a.completed_date)
        }
        if (b.completed_date == null) {
          xb = "zzzzzz"
        } else {
          xb = formatDate(b.completed_date)
        }
      } else if (sortFlfName === "trigger_status") {
        xa = a.trigger_status == null ? "9999999" : String(a.trigger_status)
        xb = b.trigger_status == null ? "9999999" : String(b.trigger_status)
      } else {
        xa = a[sortFlfName]
        xb = b[sortFlfName]
      }
      if (xa == null || xa == undefined) {
        xa = "zzzzzz"
      }
      xa = xa.toLowerCase()
      if (xb == null || xb == undefined) {
        xb = "zzzzzz"
      }
      xb = xb.toLowerCase()

      if (sortOrder == "asc") {
        if (xa < xb) {
          return -1;
        }
        if (xa > xb) {
          return 1;
        }
        return 0;
      } else {
        if (xa > xb) {
          return -1;
        }
        if (xa < xb) {
          return 1;
        }
        return 0;
      }

    }
    filteredUsers.sort(compare).map((item, idx) => {
      if (items.allSelected) {
        if (item.isDisabled === false) {
          $("#chk_" + item.user_id).prop("checked", true);
          item.isNewAssigned = 1;
        }
      } else {
        $("#chk_" + item.user_id).prop("checked", false);
        item.isNewAssigned = 0;
      }
    });
    this.setState({
      filteredUsers: filteredUsers,
      selUsersListCount: items.selectedCount
    })
  }
  componentDidUpdate() {

    if (this.state.shownorecs) {
      $(".sc-fzqARJ").css({ "font-size": "14px" });
    } else {
      $(".sc-fzqARJ").css({ "font-size": "20px" });
    }
    $("input[name=select-row-undefined]").css({ "display": "none" });
    $("input[name=select-all-rows]").css({ "width": "25px", "height": "25px", "left": "18px", "z-index": "100" });
    let ecount = 0
    let dcount = 0
    let eacount = 0
    this.state.userlist.map((item, idx) => {
      if (item.isDisabled === false) {
        ecount++
        if (item.isNewAssigned === 1) {
          eacount++
        }
      } else {
        dcount++
      }
    });
    //alert(eacount + "==" + ecount + "++" + dcount)
    if (ecount === eacount) {
      $("input[name=select-all-rows]").prop("checked", true);
    } else {
      $("input[name=select-all-rows]").prop("checked", false);
    }
    $(document).ready(function () {
      $("#cls_room_sel_all").on('click', function (event) {
      });
      $('body').on('DOMSubtreeModified', '.sc-fznMAR', function () {
        $("input[name=select-row-undefined]").css({ "display": "none" });
      });
    });
  }
  clearallselect = () => {
    $("input[name=select-row-undefined]").css({ "display": "none" });
  }
  render() {

    let LBLSELECTUSERSTOSENDREMINDER = this.props.getLabel("LBLSELECTUSERSTOSENDREMINDER")
    let LBLNAME = this.props.getLabel("LBLNAME")
    let LBLEMAIL = this.props.getLabel("LBLEMAIL")
    let LBLSTATUS = this.props.getLabel("LBLSTATUS")
    let LBLACTION = this.props.getLabel("LBLACTION")
    let LBLOF = this.props.getLabel("LBLOF")
    let LBLROWSPERPAGE = this.props.getLabel("LBLROWSPERPAGE")
    let LBLNORECORDS = this.props.getLabel("LBLNORECORDS")
    let LBLALL = this.props.getLabel("LBLALL")
    let LBLUSERNAME = this.props.getLabel("LBLUSERNAME")
    let LBLACTIVE = this.props.getLabel("LBLACTIVE")
    let LBLINACTIVE = this.props.getLabel("LBLINACTIVE")
    let LBLSENDREMINDER = this.props.getLabel("LBLSENDREMINDER")
    let LBLVERIFIED = this.props.getLabel("LBLVERIFIED")
    let LBLREJECTED = this.props.getLabel("LBLREJECTED")
    let LBLPENDING = this.props.getLabel("LBLPENDING")
    let LBLEMAILVERIFICATION = this.props.getLabel("LBLEMAILVERIFICATION")
    let LBLSELRECORDS = this.props.getLabel("LBLSELRECORDS") //test1

    const { userlist, searchValue, allFilterValues, shownorecs, filteredUsers } = this.state;

    let columns = [
      {
        selector: "order",
        sortable: true,
        compact: true,
        width: "4%",
        cell: (row) => (
          <div className="w1000" style={{ marginLeft: "-20px" }}>
            <label className="show-sm"></label>
            <label
              class={
                row.isAssigned
                  ? row.isNewAssigned
                    ? "check-lbl"
                    : "check-lbl check-newunassigned"
                  : "check-lbl check-newassigned"
              }
            >

              <input
                type="checkbox"
                name={"chk_" + row.user_id}
                id={"chk_" + row.user_id}
                data-toggle="modal"
                checked={row.isNewAssigned}
                disabled={row.isDisabled === true ? true : false}
                onChange={this.updateUserAssignment.bind(
                  this,
                  row,
                  row.isNewAssigned ? 1 : 0
                )}
              />
              <span
                class="checkmark"
                style={{
                  backgroundColor: row.isDisabled === true ? "#cfcbcb" : "",//((row.trigger_status!=3 && row.trigger_status!=null) || row.status == "Completed") ? "#cfcbcb" : "",
                  cursor: row.isDisabled === false ? "" : "auto",
                }}
              ></span>
            </label>
          </div>
        )
      },
      {
        name: this.props.getLabel("LBLNAME"),
        selector: "name",
        sortable: true,
        compact: true,
        width: "20%",
        cell: (row) => {
          return (
            <div data-toggle="tooltip" className="grid-ellipsis" title={row.name}>
              <span className="rle-brdr">{row.name}</span>
              <span className="show-sm-xs">
                <Form.Check
                  type="switch"
                  label=""
                  title={`${row.active === true || row.active === 1
                    ? LBLACTIVE
                    : LBLINACTIVE
                    }`}
                  disabled={true}
                  checked={row.active === true || row.active === 1}
                  icons={"false"}
                />
              </span>
            </div>
          );
        },
      },
      {
        name: this.props.getLabel("LBLUSERNAME"),
        selector: "user_name",
        sortable: true,
        compact: true,
        width: "20%",
        cell: (row) => {
          return (
            <div data-toggle="tooltip" className="grid-ellipsis" title={row.user_name}>
              <span className="rle-brdr">{row.user_name}</span>
            </div>
          );
        },
      },
      {
        name: this.props.getLabel("LBLEMAIL"),
        selector: "email",
        sortable: true,
        compact: true,
        width: "40%",
        cell: (row) => {
          return (
            <div
              className="hde-mail-icn"
              data-toggle="tooltip"
              title={row.email}
            >
              <i style={{ display: row.email ? "" : "none" }} title={`${LBLEMAILVERIFICATION} : ${row.email_verified === 1
                ? LBLVERIFIED
                : row.email_verified === 0
                  ? LBLREJECTED
                  : LBLPENDING
                }`} class={row.email_verified === 1 ? "fas fa-check-circle  fa-check-circle-color" : row.email_verified === 0 ? "fas fa-times-circle fa-times-circle-color" : "fas fa-pause-circle fa-pause-circle-color"} ></i>

              <span className="rle-brdr"> {row.email}</span>
              <span className="show-sm-xs">
                <i style={{ display: row.email ? "" : "none" }}
                  class={
                    row.email_verified === 1
                      ? "fas fa-check-circle  fa-check-circle-color"
                      : row.email_verified === 0
                        ? "fas fa-times-circle fa-times-circle-color"
                        : "fas fa-pause-circle fa-pause-circle-color"
                  }
                ></i>{" "}
                {`${row.email_verified === 1
                  ? LBLVERIFIED
                  : row.email_verified === 0
                    ? LBLREJECTED
                    : LBLPENDING
                  }`}
              </span>
              <div className="vfy-col">
                <span className="show-sm">
                  <i
                    style={{ display: row.email ? "" : "none" }}
                    class={
                      row.email_verified === 1
                        ? "fas fa-check-circle"
                        : row.email_verified === 0
                          ? "fas fa-times-circle"
                          : "fas fa-pause-circle"
                    }
                  ></i>{" "}
                  {`${row.email_verified === 1
                    ? LBLVERIFIED
                    : row.email_verified === 0
                      ? LBLREJECTED
                      : LBLPENDING
                    }`}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        name: LBLSTATUS,
        selector: "active",
        width: "10%",
        sortable: true,
        compact: true,
        //center: true,
        cell: (row) => {
          return (
            <div className="vfy-col">
              {row.active === 1 ? LBLACTIVE : LBLINACTIVE}
            </div>
          );
        },
      }
    ];
    let data = filteredUsers;
    columns[0].width = "5%";
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    };
    let tableHeader = this.tableHeader();
    return (
      <>
        {
          this.props.show_users === true ? <>
            <div className="mob-pad">
              <div>
                <ToastMessage
                  show={this.state.showToast["show"]}
                  helpers={this.state.showToast}
                ></ToastMessage>
                {this.state.loading === false ? <>
                  <DataTable
                    selectableRows
                    onSelectedRowsChange={this.handleSelectAll}
                    id="table_trainingreset"
                    columns={columns}
                    data={data}
                    defaultSortField="name"
                    className="datatable-list"
                    pagination
                    subHeader
                    subHeaderComponent={tableHeader}
                    sortFunction={this.customSort}
                    noDataComponent={
                      shownorecs == true ? 
                      <EmptyState image={emptyImage}text2={LBLNORECORDS} /> : <EmptyState image={emptyImage}text2={LBLSELRECORDS} />
                    }
                    paginationComponentOptions={paginationComponentOptions}
                    onSort={this.onSort} //(column, sortDirection, event)
                    onChangeRowsPerPage={this.clearallselect}
                    onChangePage={this.clearallselect}
                  />
                </>
                  : <div className="sc-fznWqX gnahTY">
                    <div className="sc-AxjAm gIMaKV rdt_Table">
                      <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                        <div className="sc-AxiKw cTEQON rdt_TableHeadRow">
                          <Skeleton height={56} />
                        </div>
                      </div>
                    </div>
                    <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                      <Skeleton height={480} />
                    </div>
                  </div>
                }
              </div>
            </div>
          </> : <></>
        }
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(UserList);

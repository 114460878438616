import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./header.scss";
import $ from "jquery";
import ScAuth from "../../auth/auth";
import logout_actions from "../../redux/Logout/logout-action/logout_actions";
import userInfo from "../../redux/action";
import langLabels from "../../admin/layout/translations/layout.json";
import CompanyLogo from "../../admin/components/companylogo/companylogo";
import { GoogleLogout } from "react-google-login";
import SupportForm from "../../admin/pages/support/Support";
import { Modal } from "react-bootstrap";
import "../../admin/pages/support/support.scss";
import Image from "material-ui-image";
import common_data from "../../services/common_data";
import Survey from "../../survey/Survey";
import SurveyAlert from "../../survey/SurveyAlert";
import survey_services from "../../survey/survey_services";
import ToastMessage from "../../shared/ToastComponent/ToastComponent";
import { HttpInterceptor } from "../../auth/HttpInterceptor";

let lang = "en";
let supportenable = process.env.REACT_APP_SUPPORT_ENABLE;
class Header extends Component {
  state = {
    show: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      languages: {},
      lang_code: props.user_lang,
      labels: langLabels[this.props.user_lang],
      supportenable: process.env.REACT_APP_SUPPORT_ENABLE,
      showSurveyAlert: false,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
        selectGroup: [],
      },
      show_release_all: false,
      surveyCompleted: false,
      postSurveyData: {},
      showPostSurvey: false,
      showPostSurveyAlert: false,
      Sitetype: localStorage.getItem('site_type')
    };
    this.getLanguages();
    lang = this.props.user_lang;
    supportenable = process.env.REACT_APP_SUPPORT_ENABLE;
  }

  getLanguages = async () => {
    let languages = common_data.getLanguages();
    let supportenable = "";
    this.setState({
      languages: languages,
      supportenable: supportenable,
    });
  };
  componentDidMount() {
    setTimeout(() => {
      $("#cls_blinks").removeClass("blink_text");
    }, 6000);
    $(window).resize(function () {
      if ($(window).width() <= 991) {
        $("#sidenav").css({ display: "none" });
        $("#side-backdrop").css({ display: "none" });
        $(".main-content").css({ marginLeft: "0" });
      } else {
        $("#side-backdrop").css({ display: "none" });
      }
      setTimeout(function () {
        $("body").trigger("onresize");
      }, 500);
    });
    $("#side-backdrop").click(function () {
      $("aside").removeClass("slideInLeft");
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("#side-backdrop").css({ display: "none" });
      $(".slideOutLeft").css({ animationDuration: "0.5s" });
      $("aside").addClass("slideOutLeft");
    });
    var vParams = {
      userid: this.props.userId,
    };
    survey_services.getsurveyStatus(vParams)
      .then((res) => {
        if (res.data.survey_id !== "") {
          this.setState({ showSurveyAlert: true })
        } else {
          this.checkPostSurvey();
        }
        this.props.user_info_reducer({ ...this.props.userinfo, survey: res.data.survey_id, skipnow: false, skip: res.data?.skip?.toLowerCase() });
      })
      .catch((error) => {
        console.error("Error while fetching survey status:", error);
      });
    if (this.props.survey) {
      this.setState({ showSurveyAlert: true })
    }
    this.getReleaseDetails();
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (!prevState.surveyCompleted && this.state.surveyCompleted) {
  //     this.showSurveyToast();
  //   }
  // }

  checkPostSurvey = () => {
    var vParams = {
      userid: this.props.userId,
    };
    survey_services.getpostsurvey(vParams).then((res) => {
      // This contdition is added to hide the post survey alert when we are in product list page because same alert is shown in product list page
      if (this.props.location.pathname.includes("product-lists") || this.props.location.pathname.includes("capsule-list") || this.props.location.pathname.includes("unit-detail")) {
        this.setState({ showPostSurveyAlert: false })
      } else {
        this.setState({ postSurveyData: res?.data, showPostSurveyAlert: res?.data.survey_id })
      }
      console.log(res?.data, "postSurveyData");
    })
      .catch((error) => {
        console.error("Error while fetching postSurveyData status:", error);
      });
  };

  toggleSideMenu = () => {
    this.sidebar();
  };

  sidebar() {
    if (
      $("#sidenav").hasClass("slideOutLeft") ||
      $("#sidenav").css("display") === "none"
    ) {
      $("#sidenav").css({ display: "block", transition: "0.5s" });
      $(".main-content").css({ marginLeft: "250px", transition: "0.5s" });
      if ($(window).width() <= 991) {
        $("#side-backdrop").css({ display: "block" });
        $(".main-content").css({ marginLeft: "0px", transition: "0.5s" });
        $(".slideInLeft").css({ animationDuration: "0.5s" });
      } else {
        $(".slideInLeft").css({ animationDuration: "0.5s" });
      }
      $("aside").removeClass("slideOutLeft");
      $("aside").addClass("slideInLeft");
    } else {
      $("aside").removeClass("slideInLeft");
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("#side-backdrop").css({ display: "none" });
      $(".slideOutLeft").css({ animationDuration: "0.5s" });
      $("aside").addClass("slideOutLeft");
    }
    setTimeout(function () {
      $("body").trigger("onresize");
    }, 500);
  }
  closeModal = () => {
    this.setState({
      show: false,
      initialValues: null,
      showDel: false,
      ssi_errors: null,
    });
  };

  openModal = () => {
    let supportenables = supportenable;
    let curLang = lang.split("-")[0]; //this.props.user_lang.split[0];
    curLang = curLang == "pt" ? curLang : "en";
    https: window.open(
      process.env.REACT_APP_SUPPORT_URL + curLang + "/home"
    );
  };

  handleCloseSurveyAlert = (status) => {
    this.props.user_info_reducer({ ...this.props.userinfo, skipnow: true });
    this.setState({ showSurveyAlert: status })
    let vParams = {
      userid: this.props.userId
    }
    let datenew = new Date();
    let data = {
      survey_id: this.props.survey,
      launch_type: "pre",
      language_code: this.props.user_lang,
      time_stamp: datenew,
    }
    survey_services
      .skipsurvey(vParams, data)
      .then(response => {
        console.log("Skip request successful", response);
      })
      .catch(error => {
        console.error("Error while skipping survey:", error);
      });
  };

  handleSurvey = () => {
    this.props.user_info_reducer({ ...this.props.userinfo, showsurvey: true });
  };

  handleOnClose = (value) => {
    this.props.user_info_reducer({ ...this.props.userinfo, showsurvey: value });
    this.setState({ showSurveyAlert: false });
  };

  showSurveyToast = (message) => {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: message,
        changeInToast: new Date(),
      },
    });

    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 1500);
    setTimeout(() => {
      this.checkPostSurvey();
    }, 1600);
  };

  showToastStatus = (status) => {
    this.setState({ surveyCompleted: status });
    this.showSurveyToast(this.state?.labels?.labels?.LBLPRESURVEYCOMPLETED);
  };

  handlePostSurvey = () => {
    this.setState({ showPostSurvey: true });
  };

  handlePostSurveyClose = () => {
    this.setState({ showPostSurvey: false, showPostSurveyAlert: false });
  };

  handleSurveyCompletion = () => {
    this.showSurveyToast(this.state.labels.labels?.LBLPOSTSURVEYCOMPLETED);
  };
  getReleaseDetails = () => {
    let show_release = false, cookie_name = "";
    let release_date_env = process.env.REACT_APP_RELEASE_DATE;
    let release_date = new Date(release_date_env);
    let view_days = process.env.REACT_APP_DISPLAY_DAYS;
    let release_key = process.env.REACT_APP_RELEASE_KEY;
    let show_release_all = process.env.REACT_APP_RELEASE_SHOW == '1' ? true : false;
    console.log("CCCCC", this.props)
    if (show_release_all) {
      if (this.props.role_id == 1 && this.props.user_lang == "en-US") {
        show_release_all = true;
      } else {
        show_release_all = false;
      }
    }
    let current_date = new Date();
    if (release_date) {
      cookie_name = "release_notes";// + release_date;
      this.addScript(release_key);
      let cookval = this.getCookie(cookie_name);
      current_date = current_date.setDate(parseInt(current_date.getDate()) + parseInt(0));

      release_date = release_date.setDate(parseInt(release_date.getDate()) + parseInt(view_days));
      //release_date = new Date(release_date);  
      if (release_key.length > 0) {
        if (this.props.role_id == 1) { //item.role_id 
          console.log("BBBB==>>>", current_date, release_date);
          if (current_date <= release_date) {
            show_release = true;
            if (cookval == release_date_env) {
              show_release = false;
            }
          } else {
            this.setCookie(cookie_name, release_date_env, -1);
          }
        }
      };
      show_release = show_release_all ? show_release : false;
      this.setState({
        release_key: release_key,
        show_release: show_release,
        show_release_all: show_release_all
      });
    }
  }
  getDays = (release_date) => {
    const date1 = new Date(release_date);
    const date2 = new Date();
    const diff_time = Math.abs(date2 - date1);
    const diff_days = Math.floor(diff_time / (1000 * 60 * 60 * 24));
    return diff_days;
  }
  getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  setCookie = (name, value, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }
  showRelease = () => {
    window.releasesapp.updates();
    $("#releasesapp__iframe__wrapper").css({ "z-index": "10005", top: "-13px", height: "104%" });
    $("#releasesapp__backdrop").css({ zIndex: "1100" });
    let view_days = process.env.REACT_APP_DISPLAY_DAYS;
    let release_date = process.env.REACT_APP_RELEASE_DATE;
    let cookie_name = "release_notes";// + release_date;
    this.setCookie(cookie_name, release_date, 1);
    $("#cls_blinks").css({ "display": "none" });
    //this.setState({showRelease:false}); 
  }
  addScript = (release_key) => {
    //let release_key = process.env.REACT_APP_RELEASE_KEY;
    let src = process.env.REACT_APP_RELEASE_URL;
    console.log("2222==>>", src, release_key);
    //src = src?src:'https://widget.releasesapp.com/js/v1.js';
    if ($("#release_id").length < 1) {
      $(function () {
        $('<script async>')
          .attr('type', 'text/javascript')
          .attr('src', src)
          .attr('id', 'release_id')
          .attr('data-releasesapp-key', release_key)
          .appendTo('body');
      });
    }
  }

  render() {
    const { labels } = this.state;
    const { role_id, company_logo } = this.props;
    let profileImgPath = "/images/profile.png";
    let menuImgPath = "/images/side_menu_icon.png";
    return (
      <div>
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage>
        <div id="helpcontent" className="cls_helpcontent"></div>
        <div className="header">
          <nav className="navbar navbar-expand-sm navbar-fixed-top">
            <div className="container-fluid p-0">
              <ul className="navbar-nav">
                <li className="brand-clr">
                  <span
                    className="menuicon"
                    id="menu-toggle"
                    onClick={this.toggleSideMenu}
                  >
                    <img className="img-fluid" src={menuImgPath} alt="" />
                  </span>
                </li>
                <li className="nav-item brand-clr">
                  <Link className="navbar-brand" to="" href="">
                    {company_logo !== "" ? (
                      <div>
                        <CompanyLogo
                          imageName={company_logo}
                          maxHeight="36px"
                        />
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </Link>
                </li>
              </ul>
              <Modal
                show={this.state.show}
                backdrop="static"
                onHide={this.closeModal}
                size="md"
                id="modal"
                data-easein="expandIn"
                className={
                  "animated  modal-right-side " +
                  (this.state.show ? "fadeInRight" : "fadeOutRight")
                }
              >
                <Modal.Header closeButton>
                  <div class="col-xl-11 col-lg-8 col-md-8 col-sm-12 col-xs-12 col-xl-11 col-lg-8 col-md-8 col-sm-12 col-xs-12 clsSupportBg d-flex align-items-center justify-content-center">
                    <span className="clsContactUs" id="spprotTitle">
                      {labels.labels.LBLCONTACTUS}
                    </span>
                    <Image
                      style={{ position: "initial" }}
                      src="../images/contactus1.jpg"
                    ></Image>
                  </div>
                </Modal.Header>
                <SupportForm
                  initialValues={this.state.initialValues}
                  sendInvitation={this.sendInvitation}
                  show={this.state.show}
                  status={this.state.status}
                  helpersLoaded={this.state.helpersLoaded}
                  closeModal={this.closeModal}
                  ssi_errors={this.state.ssi_errors}
                  groupData={this.state.groupData}
                  roleValid={this.state.roleValid}
                  labels={this.state.labels}
                  changesIn={this.changesIn}
                ></SupportForm>
              </Modal>
              <ul className="nav navbar-nav navbar-right">
                {this.state.show_release ?
                  <li>
                    <div id="cls_blinks" className="button-style primary-btn cls_release_txt blink_text" onClick={this.showRelease.bind()}>
                      <span className="fas fa-exclamation-circle cls_release"></span><span>{labels.labels.LBLNEWRELEASES ? labels.labels.LBLNEWRELEASES : "New Releases"}</span>
                    </div>
                  </li> : ""}

                <li className="nav-item dropdown h-profile">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={profileImgPath} alt="" />
                  </a>
                  <div
                    className="dropdown-menu animated fadeInUp "
                    aria-labelledby="navbarDropdown"
                  >
                    <Link
                      className="dropdown-item"
                      to={role_id <= 2 ? "/schome/profile" : "/home/profile"}
                    >
                      <div className="my-profile"></div>
                      {labels.labels.LBLMYPROFILE}
                    </Link>

                    <Link
                      style={{ display: this.state.supportenable }}
                      className="dropdown-item"
                      onClick={this.openModal}
                    >
                      <div className="support"></div>
                      {labels.labels.LBLHELP}
                    </Link>
                    {this.state.Sitetype === 'SafeStart' ?
                      <Link
                        className="dropdown-item app-ios"
                        onClick={() => {
                          window.open(
                            process.env.REACT_APP_IOS_APP_URL
                          );
                        }}
                      >
                        <div className="apple"></div>
                        {labels.labels.LBLIOSAPP}
                      </Link>
                      : ""}
                    {this.state.Sitetype === 'SafeStart' ?
                      <Link
                        className="dropdown-item"
                        onClick={() => {
                          window.open(
                            process.env.REACT_APP_ANDROID_APP_URL
                          );
                        }}
                      >
                        <div className="android"></div>
                        {labels.labels.LBLANDROIDAPP}
                      </Link>
                      : ""}
                    {this.state.show_release_all ?
                      <Link
                        style={{ display: this.state.supportenable }}
                        className="dropdown-item"
                        onClick={this.showRelease.bind()}
                      >
                        <div className="fas fa-exclamation-circle release_image"></div>
                        {labels.labels.LBLNEWRELEASES ? labels.labels.LBLNEWRELEASES : "New Releases"}
                      </Link>
                      : ""}
                    {localStorage.getItem("ssi_google_sso") ? (
                      <GoogleLogout
                        clientId={process.env.REACT_APP_SSO_GOOGLE_SIGNON_ID}
                        buttonText="Logout"
                        render={(renderProps) => {
                          return (
                            <Link
                              className="dropdown-item logout-item"
                              onClick={() => {
                                if (window.gapi) {
                                  const auth2 =
                                    window.gapi.auth2.getAuthInstance();

                                  if (auth2 != null) {
                                    auth2.signOut().then(() => {
                                      ScAuth.logout(async () => {
                                        this.props.logout_actions();
                                        this.props.history.push("/");
                                      });
                                      auth2.disconnect().then(() => {
                                      });
                                    });
                                  }
                                }
                                return renderProps.onClick;
                              }}
                            >
                              {" "}
                              <div className="log-out"></div>{" "}
                              {labels.labels.LBLLOGOUT}
                            </Link>
                          );
                        }}
                        onLogoutSuccess={() => {
                          ScAuth.logout(async () => {
                            const setCok = await HttpInterceptor.get('cookieset');
                            this.props.logout_actions();
                            this.props.history.push("/");
                          });
                        }}
                        cookiePolicy={"single_host_origin"}
                        onFailure={() => {
                        }}
                      ></GoogleLogout>
                    ) : (
                      <Link
                        className="dropdown-item logout-item"
                        onClick={() => {
                          ScAuth.logout(async () => {
                            this.props.logout_actions();
                            this.props.history.push("/");
                          });
                        }}
                      >
                        <div className="log-out"></div>
                        {labels.labels.LBLLOGOUT}
                      </Link>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        {this.props.showsurvey ?
          <Survey
            hide={this.handleOnClose}
            userId={this.props.userId}
            surveyId={this.props.survey}
            showToastStatus={this.showToastStatus}
            labels={this.state.labels.labels}
            language_code={this.props.user_lang}
            surveyType="pre"
            closeButton={this.props?.skip ? this.props?.skip?.toLowerCase() === "yes" : false}
          /> : null}

        {this.state.showSurveyAlert && !this.props.skipnow ?
          <SurveyAlert
            onHide={this.handleCloseSurveyAlert}
            handleSurvey={this.handleSurvey}
            skip={this.props?.skip ? this.props?.skip?.toLowerCase() : null}
            label={this.state.labels.labels}
            title={this.state.labels.labels.LBLPRESURVEY}
            description={this.state.labels.labels.LBLPLEASECOMPPRESURVEY}
          />
          :
          null
        }

        {this.state.showPostSurveyAlert ?
          <SurveyAlert
            handleSurvey={this.handlePostSurvey}
            skip={false}
            label={this.state.labels.labels}
            title={this.state.labels.labels?.LBLPOSTSURVEY}
            description={this.state.labels.labels?.LBLMOMPOSTSURVEY}
          />
          :
          null
        }
        {console.log(this.state.showPostSurvey, "this.state.showPostSurveycalled")}
        {this.state.showPostSurvey ?
          <Survey
            hide={this.handlePostSurveyClose}
            surveyId={this.state.postSurveyData?.survey_id}
            handleSurveyCompletion={this.handleSurveyCompletion}
            labels={this.state.labels.labels}
            surveyType="post"
            closeButton={false}
          />
          :
          null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_name: state.user_info_reducer.user_info.firs_tname,
    user_lang: state.user_info_reducer.user_info.language_code,
    role_id: state.user_info_reducer.user_info.role_id,
    company_logo: state.user_info_reducer.user_info.company_logo,
    skip: state.user_info_reducer.user_info.skip,
    survey: state.user_info_reducer.user_info.survey,
    skipnow: state.user_info_reducer.user_info.skipnow,
    userinfo: state.user_info_reducer.user_info,
    showsurvey: state.user_info_reducer.user_info.showsurvey,
    userId: state.user_info_reducer.user_info.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout_actions: () => dispatch(logout_actions()),
    user_info_reducer: (userReducerData) => dispatch(userInfo(userReducerData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

import React, { Component } from "react";
import "./toolbox.scss";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import FileView from "./FileView";
import ScServices from "../../services/services";
import emptyImage from '../../../assets/images/toolboxtalks_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import styles from "./toolbox.module.scss";

let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
 searchKeyTimeout = null;;

 class ToolBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folderView: true,
      toolboxData: [],
      toolData: [],
      parentData: [],
      popupData: null,
      showPopup: false,
      resourceSearchValue: "",
      loading: true,
      selectedValue: null,
      prevValue: "",
      backValue: [],
      backClick: false
    };
  }

  componentDidMount = () => {
    this.loadResources();
  }

  loadResources = () => {
    ScServices.toolboxService().then((res) => {
      this.setState({
        toolboxData: res.toolbox_data,
        toolData: res.toolbox_data,
        selectedValue: this.props.labels.labels.LBLSHAREDWITHME,
        backValue: [this.props.labels.labels.LBLSHAREDWITHME],
        loading: false
      });
    });
  }

  handleSearch = (e) => {
    clearTimeout(searchKeyTimeout);
    this.setState({resourceSearchValue: e.target.value});
    searchKeyTimeout = setTimeout(()=> {
      this.searchResource(this.state.resourceSearchValue);
    }, 700);
  }

  searchResource = (txt) => {
    let data = {
      search_word: txt,
    };
    if(this.state.resourceSearchValue === "") {
      ScServices.toolboxService().then((res) => {
        this.setState({
          toolboxData: res.toolbox_data,
          toolData: res.toolbox_data
        });
      });
    } else {
      ScServices.toolboxSearch(data).then((res) => {
        this.setState({
          toolData: res.toolbox_data,
          toolboxData: res.toolbox_data
        });
      })
    }
  };

  loadNextItems = (e, sdata, tdata) => {
    this.setState({toolData: tdata, toolboxData: tdata, selectedValue: sdata.file_name, backClick: false});
    let pData = this.state.toolData;
    this.state.parentData.push(pData);
    this.state.backValue.push(sdata.file_name);
  }

  loadDefaultItems = () => {
    this.setState({toolboxData: this.state.toolData})
  }

  changeViewType = () => {
    this.setState({folderView: !this.state.folderView});
  }

  togglePopup = (e, sdata) => {
    this.setState({showPopup: true, popupData: sdata});
  }

  closePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  goBack = () => {
    let pData = this.state.parentData;
    this.setState({parentData: pData, backClick: true});
    let popData = this.state.parentData.pop();
    this.setState({toolData: popData, toolboxData: popData, parentData: this.state.parentData});
    this.state.backValue.pop();
    this.setState({prevValue: this.state.backValue[this.state.backValue.length-1]})
  }

  render() {
    let tableHeader = !this.state.folderView ? 
      <div className={`col-xl-12 ${styles.lstHdr}`}>
        <div>{this.props.labels.labels.LBLNAME}</div>
        <div>{this.props.labels.labels.LBLFILEPATH}</div>
      </div> : null;
      let folderDetails = this.state.toolData.map((tbData, i) => {  
      if (tbData !== undefined && tbData.length > 0) {
        return tbData.map((tbxData, i) => {
          if (this.state.folderView) {
            return (
              <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ${styles.fdrView}`} title={tbxData.file_name} data-div_key={i} data-div_id={tbxData.id} onClick={(e) => this.loadNextItems(e, tbxData, tbxData.children ? tbxData.children : null)}>
                <div style={{ marginBottom: 10 }}>
                  <img src={`${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/ToolBox/folder_icon.png"}`} alt="" />
                </div>
                <div className={styles.filesName}>{tbxData.file_name}</div>
              </div>
            )
          } else {
            return (
              <div className={`col-xl-12 ${styles.fdrlView}`} data-div_id={tbxData.id} data-div_key={i} onClick={(e) => this.loadNextItems(e, tbxData, tbxData.children ? tbxData.children : null)}>
                <div style={{ display: "flex" }}>
                  <div className={styles.txtOverFlowCtn}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/ToolBox/folder_icon_27px.png"}`} alt="" />
                    <div className={styles.txtOvrflw} title={tbxData.file_name} style={{ color: "#25527e" }}>{tbxData.file_name}</div>
                  </div>
                  <div className={styles.txtOvrflw} title={tbxData.file_path} style={{ width: "50%", color: "#496077" }}>{tbxData.file_path}</div>
                </div>
              </div>
            )
          }
        })
      } else if (tbData !== undefined) {
        if (tbData.type === "folder") {
          if (this.state.folderView) {
            return (
              <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ${styles.fdrView}`} title={tbData.file_name} data-div_key={i} data-div_id={tbData.id} onClick={(e) => this.loadNextItems(e, tbData, tbData.children ? tbData.children : null)}>
                <div style={{ marginBottom: 10 }}>
                  <img src={`${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/ToolBox/folder_icon.png"}`} alt="" />
                </div>
                <div className={styles.filesName}>{tbData.file_name}</div>
              </div>
            )
          } else {
            return(
              <div className={`col-xl-12 ${styles.fdrlView}`} data-div_id={tbData.id} data-div_key={i} onClick={(e) => this.loadNextItems(e, tbData, tbData.children ? tbData.children : null)}>
                <div style={{display: "flex"}}>
                  <div className={styles.txtOverFlowCtn}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/ToolBox/folder_icon_27px.png"}`} alt="" />
                    <div className={styles.txtOvrflw} title={tbData.file_name} style={{color: "#25527e"}}>{tbData.file_name}</div>
                  </div>                  
                  <div className={styles.txtOvrflw} title={tbData.file_path} style={{width: "50%", color: "#496077"}}>{tbData.file_path}</div>
                </div>
              </div>
            )
          }
        } else {
          if(this.state.folderView) {
            return(
              <div className={styles.fileView} title={tbData.file_name} data-div_id={tbData.id} onClick={(e) => this.togglePopup(e, tbData)}>
                <img 
                  className={styles.fileImg }
                  src={`${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/format_image/"}${
                    tbData.file_format.toLowerCase() === "pdf"
                      ? "Toolbox-PDF-200x150-01.png"
                      : tbData.file_format.toLowerCase() === "docx"
                      ? "Doc_icon.png"
                      : tbData.file_format.toLowerCase() === "xls"
                      ? "Excel_icon.png"
                      : tbData.file_format.toLowerCase() === "xlsx"
                      ? "Excel_icon.png"
                      : tbData.file_format.toLowerCase() === "ppt"
                      ? "PPT_icon.png"
                      : tbData.file_format.toLowerCase() === "mp4"
                      ? "Toolbox-MP4-200x150-01.png"
                      : tbData.file_format.toLowerCase() === "wmv"
                      ? "Toolbox-MP4-200x150-01.png"
                      : "Image_icon.png"
                    }`}
                  alt={tbData.file_format}
                />
                <div className={styles.fileCard }>
                  <div className={styles.fileName }>{tbData.file_name}</div>
                </div>
              </div>
            )
          } else {
            return(
              <div className={`col-xl-12  ${styles.filelView}`} data-div_id={tbData.id} onClick={(e) => this.togglePopup(e, tbData)}>
                <div style={{display: "flex"}}>
                  <div className={styles.nameFilePathRow}>
                     <img
                      src={`${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/format_image/"}${
                        tbData.file_format.toLowerCase() === "pdf"
                          ? "Pdf_icon.png"
                          : tbData.file_format.toLowerCase() === "docx"
                          ? "Doc_icon.png"
                          : tbData.file_format.toLowerCase() === "xls"
                          ? "Excel_icon.png"
                          : tbData.file_format.toLowerCase() === "xlsx"
                          ? "Excel_icon.png"
                          : tbData.file_format.toLowerCase() === "ppt"
                          ? "PPT_icon.png"
                          : tbData.file_format.toLowerCase() === "mp4"
                          ? "Video_icon.png"
                          : tbData.file_format.toLowerCase() === "wmv"
                          ? "Video_icon.png"
                          : "Image_icon.png"
                      }`}
                      alt={tbData.file_format}
                      style={{ width: "20px", height: "20px", display: "flex", alignItems: "center", marginRight: 10 }}
                    />
                    <div className={styles.txtOvrflw } title={tbData.file_name} style={{color: "#25527e"}}>{tbData.file_name}</div>
                  </div>
                  <div className={styles.txtOvrflw } title={tbData.file_path} style={{width: "50%", color: "#496077"}}>{tbData.file_path}</div>
                </div>
              </div>
            )
          }
        }
      }
    })
    return (
      <div className="mob-pad toolbox">
        <div className="page-header-field">
          <h3 className="page-title">{this.props.labels.labels.LBLTOOLBOX}</h3>
        </div>
        <hr className="head-line" />
        <div>
           <div className="ip-search">
            <input
              type="text"
              className="input-field"
              placeholder={this.props.labels.labels.LBLSEARCH}
              value={this.state.resourceSearchValue}
              onChange={this.handleSearch}
              onKeyDown={this.handleSearch}
            />
          </div>
          <div>
            <div className={styles.tbsubHdr }>
              <div style={{display: "flex", alignItems: "center"}}>
                {this.state.toolData !== undefined && this.state.parentData.length > 0 ? 
                  <i
                    className="fas fa-arrow-left cu-back-btn"
                    aria-hidden="true"
                    onClick={this.goBack}
                  ></i>
                : null}
                <h5 className="alert-modal-title">{this.state.backClick === false ? this.state.selectedValue : this.state.prevValue}</h5>
              </div>
              <div className={styles.cType}>
                <img 
                  src={this.state.folderView ? `${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/ToolBox/list-view.png"}` : `${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/ToolBox/card-view.png"}`} 
                  onClick={this.changeViewType}
                  title={this.state.folderView ? this.props.labels.labels.LBLLISTVIEW : this.props.labels.labels.LBLCARDVIEW}
                  alt=""
                />
              </div>
            </div>
            <hr className="head-line" />
          </div>
          <div>
            <div className={`${this.state.folderView ? styles.fdrStr : styles.fdrListStr}`}>              
              {this.state.loading ? (
                <>
                  {skel.map((i) => {
                    return (  
                      <>
                        {this.state.folderView ?
                          <div className={`col-xl-2 col-lg-2 col-md-4 col-sm-12 col-xs-12 ${styles.fdrView}`} data-div_key={"row-" + i}>
                            <div style={{marginBottom: 10}}>
                              <div>
                                <Skeleton height={45} width={45} />
                              </div>
                            </div>
                            <div className={styles.filesName}>
                              <Skeleton height={21} />
                            </div>
                          </div>
                        :
                           <div className={`col-xl-12 ${styles.fdrlView}`} data-div_key={"row-" + i}>
                            <div style={{display: "flex"}}>
                              <Skeleton height={27} width={27} />
                              <div style={{marginLeft: "20px"}}>
                                <Skeleton height={21} width={150} />
                              </div>
                            </div>
                          </div>
                        }
                      </>
                    );
                  })}
                </>
              ) : folderDetails !== undefined ? (
                <>
               
                  {
                    
                    folderDetails.length === 0
                      ? <div style={{display:'flex', justifyContent:'center', flex: 1}}>
                          <EmptyState image={emptyImage} text2={this.props.labels.labels.LBLTOOLBOXTALKEMPTYTXT} /> 
                        </div>
                      : <>{tableHeader}{folderDetails}</>
                  } 
                  
                </>
              ) : null} 
            </div>
          </div>
        </div>
        {this.state.showPopup ? 
          <FileView 
            show={this.state.showPopup}
            popupData={this.state.popupData}
            closePopup={this.closePopup}
            labels={this.props.labels}
          />
        : null}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
    company_logo: state.user_info_reducer.user_info.company_logo,
  };
};

export default connect(mapStateToProps, null)(ToolBox);


import React from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import ScCommonData from "../../../services/common_data";
import { Field, reduxForm, change } from "redux-form";
import { bindActionCreators } from "redux";
import { Modal, Form } from "react-bootstrap";
import orderBy from "lodash/orderBy";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import Loader from "../../components/loader/Loader";
import Loading from "../../../shared/Loading";
import { classList } from "dynamic-class-list";
import UserList from "../user-list/userslist_with_filter";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent"; //"../../../shared/ToastComponent/ToastComponent";
import QRCode from "qrcode";
import Checkbox from "@material-ui/core/Checkbox";


import {
  required,
  number,
  maxLength255,
  maxLength24,
  passwordsMustMatch,
  checkSpace,
} from "../validate/Validate";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from "@material-ui/pickers";
import { it } from "date-fns/locale";
import { values } from "lodash";
const $ = require("jquery");
const moment = require("moment");
const REFS = {};
const cstLabels = {
  "LBLNORECORDS": "There are no records to display",
  "LBLALERTSELECTUSERS": "Select individual user(s) from the list.",
  "LBLNOTIFICATIONSENTFAIL": "No Notification sent",
  "LBLSUCCESSNOTIFYALL": "The notification was sent successfully to all the users.",
  "LBLSUCCESSNOTIFYPENDING": "The notification was sent successfully to Pending Users who have not taken Rate Your State on their scheduled week.",
  "LBLSUCCESSNOTIFYSELECTED": "The notification was sent successfully to selective users.",
  "LBLALERTENABLESETTINGS": "Enable Notification Setting(s) to send Reminder to user(s)",
  "LBLFILTER": "Filter",
  "LBLEMAILVERIFIED": "Email Verified",
  "LBLAPPUSER": "App Users",
  "LBLITMADDED": "Items Added",
  "LBLUSERTYPE": "User Type",
  "LBLUSRFILTER": "User Filter",
  "LBLROLE": "Role",
  "LBLADMINISTRATOR": "Administrator",
  "LBLREPORTMANAGER": "Report Manager",
  "LBLUSER": "User",
  "LBLEMAILNOTIFYDESC": "The users with a verified email address will receive the Reminder Notification.",
  "LBLAPPNOTIFYDESC": "Users logged in to the Mobile App will receive the Push Notification.",
  "LBLPENDINGUSERSNOTIFYDESC": "Active users who have not taken Rate Your State on the scheduled week.",
  "LBLSELUSERSNOTIFYDESC": "Selected User(s) will receive a Reminder Notification.",
  "LBLNOTIFYSETTINGS": "Notification Settings",
  "LBLSENDREMINDERS": "Send Reminders",
  "LBLVERIFIED": "Verified",
  "LBLEMAILVERIFICATION": "Email verification",
  "LBLRYSNOTIFICATIONSETTINGS": "RYS Reminder Settings",
  "LBLSEARCH": "Search",
  "LBLNAME": "Name",
  "LBLEMAIL": "Email",
  "LBLSTATUS": "Status",
  "LBLACTION": "Action",
  "LBLACTIVE": "Active",
  "LBLINACTIVE": "Inactive",
  "LBLSENDREMINDER": "Send Reminder",
  "LBLSELECTUSERSTOSENDREMINDER": "Individual User(s)",
  "LBLQUALIFIEDUSERSTONOTIFY": "List of Users qualified for sending notifications",
  "LBLSENDRYSREMINDERTO": "Send RYS Reminder Notification to",
  "LBLLASTUPDATEDBY": "Last updated by",
  "LBLAT": "at",
  "LBLUSERS": "Users",
  "LBLSENDRYSREMINDERNOW": "Send RYS Reminder",
  "LBLSENDALLUSERS": "All Users",
  "LBLSENDUSERSRYSNOTCOMPLETED": "RYS pending Users",
  "LBLSELECTEDUSERS": "Selected Users only",
  "LBLEDITMAILSETTINGS": "RYS Mail Settings",
  "LBLSUBJECT": "Subject",
  "LBLLANGUAGE": "Language",
  "LBLNOSUBJECT": "No Subject Found",
  "LBLNOCONTENT": "No Content Found",
  "LBLPUSHNOTIFYRYSREMINDER": "Rate Your State Push Notification",
  "LBLNOTIFYRYSREMINDER": "Rate Your State Mail Notification",
  "LBLPUSHNOTIFYRYSREMINDERDC": "Users will be reminded with a Push Notification in the Mobile App to take Rate Your State.",
  "LBLNOTIFYRYSREMINDERDC": "Users will be reminded with an email to take Rate Your State.",
  "LBLNOTIFYRYSREMINDERONDAY": "Send Rate Your State Reminder Notification on $$$1 of every week.",
  "LBLNOTIFYRYSREMINDERONDAYDC": "Rate Your State Reminder Notification will be sent on the selected day of every week.",
  "LBLMONDAY": "Monday",
  "LBLTUESDAY": "Tuesday",
  "LBLWEDNESDAY": "Wednesday",
  "LBLTHURSDAY": "Thursday",
  "LBLFRIDAY": "Friday",
  "LBLSATURDAY": "Saturday",
  "LBLSUNDAY": "Sunday",
  "LBLUSERNAME": "User Name",
  "LBLPRODUCTNAME": "Course Name",
  "LBLUNITNAME": "Unit Name",
  "LBLCAPSULENAME": "Capsule Name",
  "LBLASSIGNEDCOURSE": "Assigned Courses",
  "LBLVIEWNOTIFICATION": "View Message",
  "LBLHIDENOTIFICATION": "Close Message",
  "LBLUPDTNSETTINGSSCCESS": "The Notification settings are updated successfully",
  "LBLNOTIFICATIONS": "Notifications",
  "LBLSURVEY": "Survey",
  "LBLNOTIFYGENERALASSIGNMENT": "General Notification",
  "LBLNOTIFYSURVEYAVAILABLE": "Survey Notification",
  "LBLNOTIFYUNITDRIPAVAILABLE": "Unit activation Notification",
  "LBLNOTIFYCAPSULEDRIPAVAILABLE": "Capsule activation Notification",
  "LBLNOTIFYGENERALASSIGNMENTDC": "Users will be notified on enrollment of course",
  "LBLNOTIFYSURVEYAVAILABLEDC": "Users will be notified when Survey is available for the course",
  "LBLNOTIFYUNITDRIPAVAILABLEDC": "Users will be notified when an Unit is activated in the course",
  "LBLNOTIFYCAPSULEDRIPAVAILABLEDC": "Users will be notified when a capsule is activated for the respective unit in the course",
}
const re = /^[0-9\b]+$/;
class MailSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disable_reminder: true,
      showTab: "notifysettings",
      loading: true,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      dateformat: props.dateformat,
      toggleunitstate: {},
      mailview: false,
      modal_notification_view_show: false,
      notification_settings: {},
      mailLanguage: this.props.language,
      size: "xl",
      send_rys_reminder: "notify_all_users",
      show_users: false,
      audit_log: {},
      users_loading: true,
      users: undefined
    };
  }


  usersLoaded = (xusers) => {
    let ic = 0
    for (let i = 0; i < xusers.length; i++) {
      if (xusers[i].isNewAssigned === 1) {
        ic++
      }
    }
    this.setState({
      users: xusers,
      users_loading: false
    })
  }
  ShowToastMS = (x, time) => {
    this.setState({
      showToastMS: true,
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: x,
        changeInToast: new Date(),
      },
    });
    setTimeout(() => {
      this.setState({
        showToastMS: false,
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 5000);
  };
  ShowToast = (x, y) => {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: "Success",
        message: x,
        changeInToast: new Date(),
      },
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      }
      );
    }, y ? y : 2500);
  };
  getMessage = (xname, type) => {
    let xlabels = {
      capsule_available: this.getLabel("LBLNOTIFYCAPSULEDRIPAVAILABLE"),
      unit_available: this.getLabel("LBLNOTIFYUNITDRIPAVAILABLE"),
      courses_enrolled: this.getLabel("LBLNOTIFYGENERALASSIGNMENT"),
      presurvey_available: this.getLabel("LBLNOTIFYSURVEYAVAILABLE"),
      postsurvey_available: this.getLabel("LBLNOTIFYSURVEYAVAILABLE"),
      rys_reminder: this.getLabel("LBLNOTIFYRYSREMINDER"),
      pushnotify_rys_reminder: this.getLabel("LBLPUSHNOTIFYRYSREMINDER"),
    }
    let notification_body = ""
    let notification_subject = ""
    try {
      if (type === "mail") {
        //console.log("mail_templates", xname, this.state.mail_templates)

        let xurl = String(window.location.href).split("/schome")[0]
        if (typeof this.state.mail_templates[xname].body === "string") {
          this.state.mail_templates[xname].body = JSON.parse(this.state.mail_templates[xname].body)
        }
        if (typeof this.state.mail_templates[xname].subject === "string") {
          this.state.mail_templates[xname].subject = JSON.parse(this.state.mail_templates[xname].subject)
        }

        notification_body = this.state.mail_templates[xname].body[this.state.mailLanguage] || this.getLabel("LBLNOCONTENT")

        notification_body = notification_body.split("{{user_name}}").join("&lt;" + this.getLabel("LBLUSERNAME") + "&gt;")
        notification_body = notification_body.split("{{company_url}}").join(xurl)
        notification_body = notification_body.split("{{product_name}}").join("&lt;" + this.getLabel("LBLPRODUCTNAME") + "&gt;")
        notification_body = notification_body.split("{{product_unit_name}}").join("&lt;" + this.getLabel("LBLUNITNAME") + "&gt;")
        notification_body = notification_body.split("{{capsule_content_name}}").join("&lt;" + this.getLabel("LBLCAPSULENAME") + "&gt;")
        notification_body = notification_body.split("{{{assigned_courses}}}").join("&lt;" + this.getLabel("LBLASSIGNEDCOURSE") + "&gt;")
        notification_subject = this.state.mail_templates[xname].subject[this.state.mailLanguage] || this.getLabel("LBLNOSUBJECT")
        notification_subject = notification_subject.split("{{user_name}}").join("&lt;" + this.getLabel("LBLUSERNAME") + "&gt;")
        notification_subject = notification_subject.split("{{company_url}}").join(xurl)
        notification_subject = notification_subject.split("{{product_name}}").join("&lt;" + this.getLabel("LBLPRODUCTNAME") + "&gt;")
        notification_subject = notification_subject.split("{{product_unit_name}}").join("&lt;" + this.getLabel("LBLUNITNAME") + "&gt;")
        notification_subject = notification_subject.split("{{capsule_content_name}}").join("&lt;" + this.getLabel("LBLCAPSULENAME") + "&gt;")
        notification_subject = notification_subject.split("{{{assigned_courses}}}").join("&lt;" + this.getLabel("LBLASSIGNEDCOURSE") + "&gt;")
        //console.log("notification_subject", notification_subject)
        //console.log("notification_body", notification_body)
      } else {
        //console.log("pushnotify_templates", xname, this.state.mail_templates)

        let xurl = String(window.location.href).split("/schome")[0]
        if (typeof this.state.mail_templates[xname].body === "string") {
          this.state.mail_templates[xname].body = JSON.parse(this.state.mail_templates[xname].body)
        }
        if (typeof this.state.mail_templates[xname].subject === "string") {
          this.state.mail_templates[xname].subject = JSON.parse(this.state.mail_templates[xname].subject)
        }

        notification_body = this.state.mail_templates[xname].body[this.state.mailLanguage] || this.getLabel("LBLNOCONTENT")

        notification_body = notification_body.split("{{user_name}}").join("&lt;" + this.getLabel("LBLUSERNAME") + "&gt;")
        notification_body = notification_body.split("{{company_url}}").join(xurl)
        notification_body = notification_body.split("{{product_name}}").join("&lt;" + this.getLabel("LBLPRODUCTNAME") + "&gt;")
        notification_body = notification_body.split("{{product_unit_name}}").join("&lt;" + this.getLabel("LBLUNITNAME") + "&gt;")
        notification_body = notification_body.split("{{capsule_content_name}}").join("&lt;" + this.getLabel("LBLCAPSULENAME") + "&gt;")
        notification_body = notification_body.split("{{{assigned_courses}}}").join("&lt;" + this.getLabel("LBLASSIGNEDCOURSE") + "&gt;")
        notification_subject = this.state.mail_templates[xname].subject[this.state.mailLanguage] || "";//this.getLabel("LBLNOSUBJECT")
        notification_subject = notification_subject.split("{{user_name}}").join("&lt;" + this.getLabel("LBLUSERNAME") + "&gt;")
        notification_subject = notification_subject.split("{{company_url}}").join(xurl)
        notification_subject = notification_subject.split("{{product_name}}").join("&lt;" + this.getLabel("LBLPRODUCTNAME") + "&gt;")
        notification_subject = notification_subject.split("{{product_unit_name}}").join("&lt;" + this.getLabel("LBLUNITNAME") + "&gt;")
        notification_subject = notification_subject.split("{{capsule_content_name}}").join("&lt;" + this.getLabel("LBLCAPSULENAME") + "&gt;")
        notification_subject = notification_subject.split("{{{assigned_courses}}}").join("&lt;" + this.getLabel("LBLASSIGNEDCOURSE") + "&gt;")
        //console.log("notification_subject", notification_subject)
        //console.log("notification_body", notification_body)
      }

      this.setState({
        mailview: true,
        modal_notification_name: xname,
        modal_notification_type: type,
        modal_notification_view_show: true,
        modal_notification_heading: xlabels[xname],
        modal_notification_body: notification_body,
        modal_notification_subject: notification_subject
      });
    } catch (e) {
      console.log("getMessage ERROR", e.message)
    }
  }
  closeMailView = () => {
    this.setState({
      mailview: false,
      modal_notification_view_show: false,
    });
  };
  getQRCode = (xurl, mode) => {
    QRCode.toDataURL(xurl, { width: 200 }, (err, url) => {
      if (err) {
        console.log(err)
      }
      //console.log("!!!!!!!!!!!!!!!!!!!!!!!qrcode", url)

      if (mode === "encref_pre_un") {
        this.setState({
          encref_pre_un: url
        })
      } else if (mode === "encref_post_un") {
        this.setState({
          encref_post_un: url
        })
      }
      return url
    })
    QRCode.toDataURL(xurl, { width: 450 }, (err, url) => {
      if (err) {
        console.log(err)
      }
      //console.log("!!!!!!!!!!!!!!!!!!!!!!!qrcode", url)

      if (mode === "encref_pre_un") {
        this.setState({
          encref_pre_un_download: url
        })
      } else if (mode === "encref_post_un") {
        this.setState({
          encref_post_un_download: url
        })
      }

      return url
    })
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        if (cstLabels.hasOwnProperty(xfield.label)) {
          return cstLabels[xfield.label].replace(
            "$$$",
            xfield.val
          );
        } else {
          return xfield.label;
        }
      }
    }
  };
  componentDidMount() {
    this.getMailSettings();
    // this.setdateformat();
  }
  componentDidUpdate() {
  }
  checkNotifyType = () => {
    let xnotification_settings = this.state.notification_settings
    let notify_type = ""
    if (xnotification_settings.notify_rys_reminder === true || xnotification_settings.notify_rys_reminder === 'true') {
      notify_type = notify_type + ",mail"
    }
    if (xnotification_settings.pushnotify_rys_reminder === true || xnotification_settings.pushnotify_rys_reminder === 'true') {
      notify_type = notify_type + ",app"
    }
    return notify_type
  }
  getMailSettings = () => { //test1
    HttpInterceptor.get("notificationsettings/?language=" + this.props.language).then(
      (res) => {
        //console.log(res.data)
        let item = res.data[0][0]
        let group_labels = res.data[1][0].group_labels;
        let templates_status = res.data[2][0].templates_status;
        let mail_templates = {}
        let audit_log = {}
        if (res.data[3]) {
          for (let i = 0; i < res.data[3].length; i++) {
            if (res.data[3][i].template_type_id === 100) {
              mail_templates["capsule_available"] = res.data[3][i]
            }
            if (res.data[3][i].template_type_id === 110) {
              mail_templates["unit_available"] = res.data[3][i]
            }
            if (res.data[3][i].template_type_id === 112) {
              mail_templates["courses_enrolled"] = res.data[3][i]
            }
            if (res.data[3][i].template_type_id === 113) {
              mail_templates["presurvey_available"] = res.data[3][i]
            }
            if (res.data[3][i].template_type_id === 114) {
              mail_templates["postsurvey_available"] = res.data[3][i]
            }
            if (res.data[3][i].template_type_id === 115) {
              mail_templates["rys_reminder"] = res.data[3][i]
            }
            if (res.data[3][i].template_type_id === 116) {
              mail_templates["pushnotify_rys_reminder"] = res.data[3][i]
            }
          }
        }

        if (res.data[4] && res.data[4][0]) {
          audit_log = res.data[4][0]
        }
        let xnotification_settings = JSON.parse(item.notification_settings)
        if (this.state.showTab === "sendreminders") {
          if ((xnotification_settings.notify_rys_reminder === true || xnotification_settings.notify_rys_reminder === 'true') ||
            (xnotification_settings.pushnotify_rys_reminder === true || xnotification_settings.pushnotify_rys_reminder === 'true')) {
            this.setState({
              disable_reminder: false
            })
          } else {
            this.setState({
              disable_reminder: true
            })
            this.ShowToast(this.getLabel("LBLALERTENABLESETTINGS"), 4000)
          }
        }
        if (!xnotification_settings.notify_rys_reminder_ondays || xnotification_settings.notify_rys_reminder_ondays.hasOwnProperty("Monday") === false) {
          xnotification_settings.notify_rys_reminder_ondays = {
            "Monday": 0,
            "Tuesday": 0,
            "Wednesday": 0,
            "Thursday": 0,
            "Friday": 0,
            "Saturday": 0,
            "Sunday": 0
          }
        }
        this.setState({
          notification_settings: xnotification_settings,
          company_name: item.company_name,
          company_currency: item.currency,
          company_timezone: item.timezone,
          company_language: item.language_code,
          company_dateformat: item.dateformat,
          company_timeformat: item.timeformat,
          company_logo: item.company_logo,
          company_group_types:
            item.group_types.length > 0 ? JSON.parse(item.group_types) : null,
          company_group_labels: group_labels ? JSON.parse(group_labels) : null,
          email_notification: templates_status,
          email_with_code: item.email_with_code,
          mail_templates: mail_templates,
          loading: false,
          audit_log: audit_log
        });


      }
    );
  };

  sortorder = (a, b) => {
    if (a.capsule_order < b.capsule_order) {
      return -1;
    }
    if (a.capsule_order > b.capsule_order) {
      return 1;
    }
    return 0;
  };

  saveNotifications = (values) => {
    let notification_settings = {
      notify_rys_reminder: this.state.notification_settings.notify_rys_reminder,
      // notify_rys_reminder_onday: "",
      pushnotify_rys_reminder: this.state.notification_settings.pushnotify_rys_reminder,
      notify_rys_reminder_ondays: this.state.notification_settings.notify_rys_reminder_ondays
    }

    let data = {
      notification_settings: notification_settings
    };

    let url = "notificationsettings"
    HttpInterceptor.post(url, data).then((res) => {
      if (res) {
        this.ShowToast(this.getLabel("LBLUPDTNSETTINGSSCCESS"));
      }
    },
      (err) => {
      }
    );
  };

  getNotificationValue = (x) => {
    let ans = ""
    if (x === "notify_rys_reminder_onday") {
      if (!this.state.notification_settings[x]) {
        ans = "Monday"
      } else {
        ans = this.state.notification_settings[x];
      }
    } else {
      ans = this.state.notification_settings[x];
    }
    //console.log("QQQQQQQQQQQQQQQQQQQQQQQQQ", x, ans)
    return ans
  };

  getValue = (x) => {
    return this.state[x];
  };

  renderToggleInput = (field) => {
    //console.log(field);
    return (
      <div
        key={field.name}
        className={classList([
          "form-group",
          "row",
          required === true ? "required" : "",
        ])}
        style={{ margin: "0px" }}
      >
        <label className="col-sm-12 control-label">{field.label}</label>
        <div class="col-sm-7">
          <Form.Check
            type="switch"
            id={field.id}
            label=""
            checked={field.g_value}
            onChange={field.onChng}
            icons={"false"}
          />
        </div>
      </div>
    );
  };
  renderInlineToggleInput = (field) => {
    //console.log(field);
    return (
      <div
        key={field.name}
        className={classList([
          "form-group",
          "row",
          required === true ? "required" : "",
        ])}
        style={{ margin: "0px", paddingTop: "10px" }}
      >
        <label className="col-sm-12 control-label" style={{ fontSize: "14px" }}>{field.label}
          <span style={{ display: "inline-block", marginLeft: "20px", marginTop: "0px" }}>
            <Form.Check
              type="switch"
              id={field.id}
              label=""
              checked={field.g_value}
              onChange={field.onChng}
              icons={"false"}
            />
          </span>
        </label>

      </div>
    );
  };
  renderField = ({
    input,
    label,
    type,
    g_value,
    className,
    disabled,
    meta: { touched, error, warning },
    required,
    onChng,
    name,
  }) => (
    <div
      key={name}
      className={classList([
        "form-group",
        "row",
        required === true ? "required" : "",
      ])}
      style={{ margin: "0px" }}
    >
      <label className="col-sm-12 control-label">{label}</label>
      <div class="col-sm-7">
        <input
          key={name}
          {...input}
          value={g_value}
          placeholder={label}
          type={type}
          className={className}
          disabled={disabled}
          autoComplete="new-password"
          ref={this[name]}
          onChange={onChng}
        />
        {touched &&
          ((error && (
            <span className="required-field">
              {typeof error === "string"
                ? this.props.labels.labels[error]
                : this.props.labels.labels[error.label].replace(
                  "$$$",
                  error.val
                )}
            </span>
          )) ||
            (warning && <span className="required-field">{warning}</span>))}
      </div>
    </div>
  );
  renderInlineField = ({
    input,
    label,
    type,
    g_value,
    className,
    disabled,
    meta: { touched, error, warning },
    required,
    onChng,
    name,
    label1,
    label2,
    label3,
    id
  }) => (
    <span style={{ paddingLeft: "0px", width: "65px", display: "inline-block" }}>
      <input
        key={name}
        id={id}
        {...input}
        value={g_value}
        placeholder={label}
        type={type}
        className={classList([
          "form-control",
          "row",
          required === true ? "required" : "",
        ])}
        disabled={disabled}
        autoComplete="new-password"
        ref={this[name]}
        onChange={onChng}
        style={{ textAlign: "center", fontWeight: "bold", fontStyle: "italic" }}
      />
      {touched &&
        ((error && (
          <span className="required-field">
            {typeof error === "string"
              ? this.props.labels.labels[error]
              : this.props.labels.labels[error.label].replace(
                "$$$",
                error.val
              )}
          </span>
        )) ||
          (warning && <span className="required-field">{warning}</span>))}
    </span>
  );
  renderFieldSmall = ({
    input,
    label,
    type,
    g_value,
    className,
    disabled,
    meta: { touched, error, warning },
    required,
    onChng,
    name,
    label1,
    label2,
    label3,
    id
  }) => (
    <div
      key={name}
      className={classList([
        "form-group",
        "row",
        required === true ? "required" : "",
      ])}
    >
      <label className="col-sm-12 control-label">{label}</label>
      <div className="col-sm-12">
        <span style={{ color: "black" }}>{label1}</span>
        <span style={{ paddingLeft: "20px", width: "65px", display: "inline-block" }}>
          Component1
        </span>
        <span style={{ paddingLeft: "5px", color: "black" }}>{label2}</span>
        <span>
          Component 2
        </span>
      </div>
    </div>
  );
  renderRadioButton = ({ name, label, value, disabled, checked, onChng, mode, g_value }) => {
    let xpointer = disabled === false ? "pointer" : "default"


    return (
      <>

        <label
          className="radio-lbl xbg-opts xssi-svy-opt"
          style={{
            fontSize: "13px",
            minHeight: "32px",
            paddingRight: "40px",
            cursor: xpointer,
            width: "fit-content"
          }}
        >
          <span className="rys-textTwo">{this.getLabel(label)}
            {/* - {g_value} */}
          </span>
          <input
            name={name}
            type="checkbox"
            value={value}
            onClick={onChng}
            checked={disabled === false ? checked : false}
            disabled={disabled}
          />
          <span
            className="radio-mark2"
            style={{
              borderRadius: "25px",
            }}
          ></span>
        </label>
        {/* <label> */}
        {
          disabled === true ?
            <div style={{ position: "absolute", border: "0px solid red", width: "34px", height: "40px", top: "2px", backgroundColor: "rgb(251, 251, 251, .5)" }}>

            </div> : ""
        }

        {/* </label>  */}
      </>
    );
  };

  renderInlineSelectField = (field) => {
    return (
      <div
        key={field.name}
        className={classList([
          "form-group",
          "row",
          required === true ? "required" : "",
        ])}
        style={{ margin: "0px 2px 0px -14px", paddingTop: "10px" }}
      >
        <label className="col-sm-12 control-label" style={{
          fontSize: "14px",
          textAlign: field.style && field.style.textAlign ? field.style.textAlign : "start",
          paddingRight: field.style && field.style.paddingRight ? field.style.paddingRight : "inherit"
        }}>{field.label} &nbsp;
          <span style={{ paddingLeft: "0px", display: "inline-block" }}>
            <select
              key={field.name}
              {...field.input}
              value={field.g_value}
              placeholder={field.label}
              type={field.type}
              className={field.className}
              ref={this[field.name]}
              onChange={field.onChng}
              style={field.g_style ? field.g_style : {}}
            >
              {field.children}
            </select>
            {field.touched && field.error && (
              <span className="required-field">
                {this.props.labels.labels.LBLREQUIRED}
              </span>
            )}
          </span>
        </label>
      </div>
    );
  };
  renderSelectField = ({
    input,
    label,
    name,
    g_value,
    type,
    id,
    className,
    meta: { touched, error },
    children,
    onChng,
    required,
  }) => {
    return (
      <div
        className={classList([
          "form-group",
          "row",
          required === true ? "required" : "",
        ])}
      >
        <label className="col-sm-3 control-label">{label}</label>
        <div className="col-sm-7">
          <select
            value={g_value}
            name={name}
            id={id}
            className={className}
            onChange={onChng}
          >
            {children}
          </select>
          {touched && error && <span className="required-field">{error}</span>}
        </div>
      </div>
    );
  };
  sendReminder = (xtype, xitem) => {
    let notifytype = this.state.send_rys_reminder
    var data = {
      type: this.state.send_rys_reminder
    };
    if (xitem) {
      data.user_id = xitem.user_id
    } else {
      let xusers = []
      if (notifytype === "notify_all_users" || notifytype === "notify_users_rys_incomplete") {
        let users = REFS.F1.getAllUsers(); // this.state.users
        for (let i = 0; i < users.length; i++) {
          xusers.push(users[i].user_id)
        }
      } else {
        let users = REFS.F1.getSelUsers(); // this.state.users        
        for (let i = 0; i < users.length; i++) {
          if (parseInt(users[i].isNewAssigned) === 1) {
            xusers.push(users[i].user_id)
          }
        }
      }
      data.user_ids = xusers.join(",")
      if (notifytype === "notify_selected_users" && xusers.length === 0) {
        this.ShowToastMS(this.getLabel("LBLALERTSELECTUSERS"))
        return;
      }
    }
    HttpInterceptor.post("sendrysreminder", data).then((res) => {
      //console.log("!!!!!!!!!!!!!!!", JSON.stringify(res))
      if (res.data.message === "SENT") {
        if (notifytype === "notify_all_users") {
          this.ShowToastMS(this.getLabel("LBLSUCCESSNOTIFYALL"))
        } else if (notifytype === "notify_users_rys_incomplete") {
          this.ShowToastMS(this.getLabel("LBLSUCCESSNOTIFYPENDING"))
        } else {
          REFS.F1.resetSelUsers();
          this.ShowToastMS(this.getLabel("LBLSUCCESSNOTIFYSELECTED"))
        }
      }
    });
  };



  /////////////////////////////////////////TAB CODE/////////////////////////////////

  setTab = (x) => {
    this.getMailSettings();
    let rys_reminder = "notify_all_users"
    this.setState({
      send_rys_reminder: rys_reminder,
      show_users: false,
      showTab: x,
    });

  };



  ////////////////////////////////////////

  render() {
    // { console.log("this.props.language", this.props.language) }
    let timeformat = this.props.timeformat.split("_24").join("").split("_12").join("_A").split("MM").join("mm").split("SS").join("ss")
    const {
      LABELS
    } = this.props.labels;
    const { initialValues } = this.props

    const weekDays = {
      "Monday": {
        code: "Monday",
        name: this.getLabel("LBLMONDAY")
      },
      "Tuesday": {
        code: "Tuesday",
        name: this.getLabel("LBLTUESDAY")
      },
      "Wednesday": {
        code: "Wednesday",
        name: this.getLabel("LBLWEDNESDAY")
      },
      "Thursday": {
        code: "Thursday",
        name: this.getLabel("LBLTHURSDAY")
      },
      "Friday": {
        code: "Friday",
        name: this.getLabel("LBLFRIDAY")
      },
      "Saturday": {
        code: "Saturday",
        name: this.getLabel("LBLSATURDAY")
      },
      "Sunday": {
        code: "Sunday",
        name: this.getLabel("LBLSUNDAY")
      }
    };
    const Languages = ScCommonData.getLanguages();

    let notify_rys_reminder_ondays = this.state.notification_settings.notify_rys_reminder_ondays


    //////////////////////////////////////////USER LIST TABLE////////////////////////////////////

    let xlabels = this.getLabel("LBLNOTIFYRYSREMINDERONDAY")
    //LBLDRIPDAYSENROLLUNIT
    let xrrlabels = xlabels.split("$$$1").join("@@@1$$$1@@@1").split("$$$2").join("@@@1$$$2@@@1").split("$$$3").join("@@@1$$$3@@@1").split("$br$").join("@@@1$br$@@@1")
    let zrrlabels = xrrlabels.split("@@@1")

    let component1 = () => {
      return (
        <>
          <span style={{ display: "inline-block", paddingLeft: "0px" }}>
            <Field
              name="notify_rys_reminder_onday"
              g_value={this.getNotificationValue("notify_rys_reminder_onday")}
              component={this.renderInlineSelectField}
              type="select"
              id="notify_rys_reminder_onday"
              // label={this.getLabel("LBLNOTIFYRYSREMINDERONDAY")}
              required={true}
              className="form-control"
              validate={[required]}
              onChng={(e) => {
                let notification_settings = this.state.notification_settings
                notification_settings.notify_rys_reminder_onday = e.target.value
                this.setState({
                  notification_settings,
                });
                //console.log(e.target.value);
              }}
            >
              {Object.keys(weekDays).map((d, key) => {
                //console.log("d !", d !== "it")
                if (d !== "it") {
                  return (
                    <option
                      key={weekDays[d].code}
                      value={weekDays[d].code}
                    >
                      {weekDays[d].name}
                    </option>
                  );
                }
              })}
            </Field>
          </span>
        </>
      )
    }


    ///////////////////////////////////////////////////////////////////////////
    return (
      <>
        <div>
          <h3 className="page-title">{this.getLabel("LBLRYSNOTIFICATIONSETTINGS")}</h3>
          <div className="btn-align">
            <i
              class="fas fa-times ml-2"
              style={{
                float: "right",
                fontSize: "large",
                marginTop: "-30px",
                color: "#6c757d",
                cursor: "pointer"
              }}
              onClick={this.props.closeModal}
            ></i>
          </div>
        </div>
        <hr className="head-line" />
        <div
          className="col-sm-12"
          style={{ marginTop: "-20px", padding: "0px" }}
        >
          <ul class="nav nav-tabs"
          >
            <li key={"ul12"}
              style={{ marginTop: "20px" }}
              className={classList([this.state.showTab === "notifysettings" ? "active" : ""])}
            >
              <div onClick={this.setTab.bind(this, "notifysettings")}>
                {this.getLabel("LBLNOTIFYSETTINGS")}
              </div>
            </li>

            <li key={"ul13"}
              style={{ marginTop: "20px", marginLeft: "10px" }}
              className={classList([
                this.state.showTab === "sendreminders" ? "active" : "",
              ])}
            >
              <div onClick={this.setTab.bind(this, "sendreminders")}>
                {this.getLabel("LBLSENDREMINDERS")}
              </div>
            </li>
          </ul>
        </div>
        {this.state.showTab === "notifysettings" ? <>
          <div className="mob-pad" >            
              <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-sm-12">
                {this.state.audit_log && this.state.audit_log.first_name ?
                  <div className="label-head" >
                    <label className="label" >
                      {this.getLabel("LBLLASTUPDATEDBY")} {this.state.audit_log.first_name} ({this.state.audit_log.user_name || this.state.audit_log.email}) {this.getLabel("LBLAT")} {moment(this.state.audit_log.audit_on).format(this.props.dateformat + " " + timeformat)}
                    </label>
                  </div> : null }
                  {this.state.loading === false ? 
                    <div>
                      <label className="labelctn">
                          {this.getLabel("LBLNOTIFYRYSREMINDERONDAY")}
                      </label>
                    </div>
                    : null }
                </div>
              </div>
            
            {this.state.loading === false ? (
              <div className="row" style={{ padding: "10px" }}>
                <div className="col-sm-12"
                  style={{
                    border: "0px solid #dee2e6",
                    padding: "0px",
                    //marginTop: "-16px",
                    marginBottom: "17px"
                  }}
                >
     
                  <div className="mformfieldcontainer">
                    
                    <div className="rows"
                      style={{ marginLeft: "20px", marginRight: "-10px" }}
                    >
                      <div className="row cls_check_group_top">
                        {Object.keys(weekDays).map((d, key) => {
                          if (d !== "it") {
                            return (<>
                              <div key={"skc1_" + d} className="cls_chk_group"
                                onClick={(ev) => {
                                  let notification_settings = this.state.notification_settings
                                  let xvalue = notification_settings.notify_rys_reminder_ondays[weekDays[d].code]
                                  xvalue = xvalue === 1 ? 0 : 1
                                  notification_settings.notify_rys_reminder_ondays[weekDays[d].code] = xvalue
                                  this.setState({
                                    notification_settings,
                                  });
                                }}
                              >
                                <Checkbox
                                  name={"checkbox_" + d}
                                  id={"checkbox_" + d}
                                  checked={notify_rys_reminder_ondays[weekDays[d].code] === 1 ? true : false}
                                />
                                <div key={"skc_" + d} className="cls_chk_label"
                                >{weekDays[d].name}</div>
                              </div>
                            </>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div style={{ padding: "8px 16px 0px 16px", backgroundColor: "" }}>
                      <label className="label labeltxt"
                      >
                        {this.getLabel("LBLNOTIFYRYSREMINDERONDAYDC")}
                      </label>
                    </div>
                    <div style={{ padding: "8px 16px 0px 16px", backgroundColor: "" }}></div>
                  </div>
                  <div className="mformfieldcontainer" style={{ marginTop: "10px" }}>
                    <div className="icoBtn" style={{ float: "right", paddingTop: "16px" }}>
                      <i
                        className="icoBtn "
                        data-toggle="modal"
                        onClick={(e) => {
                          this.getMessage("rys_reminder", "mail")
                        }}
                        alt={this.getLabel("LBLVIEWNOTIFICATION")}
                      >
                        {this.getLabel("LBLVIEWNOTIFICATION")}
                      </i>
                    </div>
                    <Field
                      name="notify_rys_reminder"
                      g_value={this.getNotificationValue("notify_rys_reminder")}
                      component={this.renderInlineToggleInput}
                      id="notify_rys_reminder"
                      label={this.getLabel("LBLNOTIFYRYSREMINDER")}
                      className="form-control"
                      validate={[required]}
                      onChng={(e) => {
                        let notification_settings = this.state.notification_settings
                        notification_settings.notify_rys_reminder = !notification_settings.notify_rys_reminder
                        this.setState({
                          notification_settings,
                        });
                      }}
                    ></Field>
                    <div style={{ padding: "8px 16px 0px 16px", backgroundColor: "" }}>
                      <label className="label labeltxt"
                      >
                        {this.getLabel("LBLNOTIFYRYSREMINDERDC")}
                      </label>
                    </div>
                    <div style={{ padding: "8px 16px 0px 16px", backgroundColor: "" }}>

                    </div>
                  </div>
                  <div className="mformfieldcontainer" style={{ marginTop: "10px" }}>
                    <div className="icoBtn" style={{ float: "right", paddingTop: "16px" }}>
                      <i
                        className=""
                        data-toggle="modal"
                        onClick={(e) => {
                          this.getMessage("pushnotify_rys_reminder", "pushnotify")
                        }}
                        alt={this.getLabel("LBLVIEWNOTIFICATION")}
                      >
                        {this.getLabel("LBLVIEWNOTIFICATION")}
                      </i>
                    </div>
                    <Field
                      name="pushnotify_rys_reminder"
                      g_value={this.getNotificationValue("pushnotify_rys_reminder")}
                      component={this.renderInlineToggleInput}
                      id="pushnotify_rys_reminder"
                      label={this.getLabel("LBLPUSHNOTIFYRYSREMINDER")}
                      className="form-control"
                      validate={[required]}
                      onChng={(e) => {
                        let notification_settings = this.state.notification_settings
                        notification_settings.pushnotify_rys_reminder = !notification_settings.pushnotify_rys_reminder
                        this.setState({
                          notification_settings,
                        });

                        //console.log("pushnotify_rys_reminder", notification_settings.pushnotify_rys_reminder);
                      }}
                    ></Field>
                    <div style={{ padding: "8px 16px 0px 16px", backgroundColor: "" }}>
                      <label className="label labeltxt">
                        {this.getLabel("LBLPUSHNOTIFYRYSREMINDERDC")}
                      </label>
                    </div>
                    <div style={{ padding: "8px 16px 0px 16px", backgroundColor: "" }}>

                    </div>
                  </div>

                </div>
                <div className="col-sm-12"
                  style={{ verticalAlign: "top" }}
                >
                  <div className="form-group">
                    <div
                      className="form-group usr-radio"
                      style={{ display: "block" }}
                    >

                    </div>
                  </div>
                </div>
                <div className="col-sm-12"
                  style={{
                    border: "0px solid #dee2e6",
                    padding: "0px",
                    borderRadius: "4px"
                  }}
                >
                  <button
                    type="button"
                    className="button-style primary-btn"
                    // disabled={submitting}
                    onClick={this.saveNotifications}
                    style={{ float: "right" }}
                  >
                    {this.getLabel("LBLSUBMIT")}
                  </button>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.props.closeModal}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {this.getLabel("LBLCANCEL")}
                  </button>
                </div>
              </div>
            ) : ""
            }


            {this.state.mailview === true ? (
              <div className="row col-sm-12 mod-pop" style={{ display: "flex" }}>
                <Modal
                  show={this.state.modal_notification_view_show}
                  onHide={this.closeMailView}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="page-title"  >
                      <h4 className="modal-title" style={{ lineHeight: "2.5" }}>
                        {this.state.modal_notification_heading}
                      </h4>

                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>

                    <div className="rysdropdown" style={{ marginTop: "-20px" }}>
                      <div className="label" style={{ marginRight: "5px", paddingTop: "3px" }}>{this.getLabel("LBLLANGUAGE")}</div>
                      <Autocomplete
                        id="notify_rys_reminder_onday"
                        // className="filterDrpdwn"
                        label={this.getLabel("LBLLANGUAGE")}
                        style={{ width: "125px", marginRight: "25px", justifyContent: "center" }}
                        aria-required="true"
                        required
                        disableClearable
                        options={Languages}
                        getOptionLabel={(option) => option.name}
                        value={this.state.mailLanguage ? Languages.find((option) => { return this.state.mailLanguage === option.code }) ?? null : null}
                        onChange={(e, i) => {
                          let notification_settings = this.state.notification_settings
                          notification_settings.notify_rys_reminder_onday = e.target.value;
                          this.setState({
                            mailLanguage: i.code
                          });
                          setTimeout(() => {
                            this.getMessage(this.state.modal_notification_name, this.state.modal_notification_type)
                          }, 1000)
                        }}
                        renderOption={(option) => (
                          <div style={{ fontSize: "14px" }}>{option.name}</div>
                        )}
                        renderInput={(params) => (
                          <div>
                            <TextField
                              {...params}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div style={{ padding: "8px 16px 16px 24px", backgroundColor: "" }}>
                      <span>
                        {this.getLabel("LBLSUBJECT")}: {this.state.modal_notification_subject}
                      </span>
                    </div>
                    {
                      this.state.modal_notification_type === "mail" ?
                        <div dangerouslySetInnerHTML={{ __html: this.state.modal_notification_body }}></div>
                        :
                        <div dangerouslySetInnerHTML={{ __html: this.state.modal_notification_body }} style={{ padding: "4px 24px 24px 24px" }}></div>
                    }

                  </Modal.Body>
                </Modal>
              </div>
            ) : ""}
          </div>
        </> : ""
        }
        {this.state.showTab === "sendreminders" ? <>
          <div className="mob-pad">
            <div className="hdr-style" >
              <div className="hdr-ctn">
                <label className="label">
                  {this.getLabel("LBLEMAILNOTIFYDESC")}
                </label><br />
                <label className="label">
                  {this.getLabel("LBLAPPNOTIFYDESC")}
                </label>
              </div>
            </div>
            <div
              class="usr-radio-primary"
            >
              {
                1 === 1 ? <>
                  <div className="row" style={{ margin: "-8px 15px 0px 0px", backgroundColor: "#fbfbfb", paddingTop: "10px" }}>
                    <div className="sndrym-ctn row-ctn">
                      <div className="col-sm-12 sndrym-style" >
                        <Field
                          type="checkbox"
                          label={this.getLabel("LBLSENDALLUSERS")}
                          init_value={""}
                          name={"notify_all_users"}
                          id={"notify_all_users"}
                          component={this.renderRadioButton}
                          disabled={this.state.disable_reminder}
                          checked={this.state.send_rys_reminder === "notify_all_users" ? true : false}
                          onChng={(ev) => {
                            this.setState({
                              send_rys_reminder: "notify_all_users",
                              show_users: false,
                              size: "xl"
                            });
                          }}
                        />
                      </div>
                      <div className="col-sm-12 sndrym-style" >
                        <Field
                          type="checkbox"
                          label={this.getLabel("LBLSENDUSERSRYSNOTCOMPLETED")}
                          init_value={""}
                          name={"notify_rys_incomplete"}
                          id={"notify_users_rys_incomplete"}
                          component={this.renderRadioButton}
                          disabled={this.state.disable_reminder}
                          checked={this.state.send_rys_reminder === "notify_users_rys_incomplete" ? true : false}
                          onChng={(ev) => {
                            this.setState({
                              send_rys_reminder: "notify_users_rys_incomplete",
                              show_users: false,
                              size: "xl"
                            });
                          }}
                        />
                        <div style={{ padding: "8px 16px 0px 36px", backgroundColor: "", marginTop: "-8px" }}>
                          <label style={{ color: "#6c757d", fontWeight: "bold" }}>
                            {this.getLabel("LBLPENDINGUSERSNOTIFYDESC")}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 sndrym-style" >
                        <Field
                          type="checkbox"
                          label={this.getLabel("LBLSELECTUSERSTOSENDREMINDER")}
                          init_value={""}
                          name={"notify_selected_users"}
                          id={"notify_selected_users"}
                          component={this.renderRadioButton}
                          disabled={this.state.disable_reminder}
                          checked={this.state.send_rys_reminder === "notify_selected_users" ? true : false}
                          onChng={(ev) => {
                            this.setState({
                              send_rys_reminder: "notify_selected_users",
                              show_users: true,
                              size: "xl"
                            });
                          }}
                        />
                        <div style={{ padding: "8px 16px 0px 36px", backgroundColor: "", marginTop: "-8px" }}>
                          <label style={{ color: "#6c757d", fontWeight: "bold" }}>
                            {this.getLabel("LBLSELUSERSNOTIFYDESC")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12  col-min-ctn">
                    <button
                      type="button"
                      className={this.state.disable_reminder === true ? "button-style secondary-btn button-sndrym" : "button-style primary-btn button-sndrym"}
                      disabled={this.state.disable_reminder}
                      onClick={this.sendReminder}
                    >
                      {this.getLabel("LBLSENDREMINDER")}
                    </button>
                  </div>
                </> : <>
                  <div className="sc-fznWqX gnahTY">
                    <div className="sc-AxjAm gIMaKV rdt_Table">
                      <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                        <div className="sc-AxiKw cTEQON rdt_TableHeadRow">
                          <Skeleton height={56} />
                        </div>
                      </div>
                      <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                        <div className="sc-AxiKw cTEQON rdt_TableHeadRow">
                          <Skeleton height={56} />
                        </div>
                      </div>
                      <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                        <div className="sc-AxiKw cTEQON rdt_TableHeadRow">
                          <Skeleton height={56} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
            <div className="mformfieldcontainer" style={{ paddingLeft: "16px", paddingRight: "16px", marginTop: "-17px" }}>
              <UserList {...this.props}
                permission={true}
                sendReminder={this.sendReminder}
                getLabel={this.getLabel}
                usersLoaded={this.usersLoaded}
                show_users={this.state.show_users}
                onRef={(ref) => (REFS.F1 = ref)}
                checkNotifyType={this.checkNotifyType}
              />
            </div>
            {this.state.showToastMS ? (
              <ToastMessage
                show={this.state.showToast["show"]}
                helpers={this.state.showToast}
              ></ToastMessage>
            ) : (
              ""
            )}
          </div>
        </> : ""
        }
        <div style={{ border: "0px solid red" }}>
          {this.state.showToast.show ? (
            <ToastMessage
              show={this.state.showToast["show"]}
              helpers={this.state.showToast}
            ></ToastMessage>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}


const renderToggleInput = (field) => {
  //console.log(field);
  return (
    <Form.Check
      type="switch"
      id={field.id}
      label=""
      checked={field.g_value}
      onChange={field.onChng}
      icons={"false"}
    />
  );
};
MailSettings = reduxForm({
  form: "trainingsettings", // a unique name for this form
})(MailSettings);

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    language_code: state.user_info_reducer.user_info.language_code,
  };
};

export default connect(mapStateToProps, null)(MailSettings);


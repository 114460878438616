import React from 'react';
import CourseCardComponent from './CourseCardComponent';

const ProductList = (props) => {
  if (
    props.data.totalunits === 1 &&
    parseInt(props.data.unit_drip_enabled) === 1
  ) {
    if (
      parseInt(props.data.drip_status) === 0 &&
      props.data.CourseCompletionStatus === "Not Started"
    ) {
      props.data.CourseHide = true;
      props.data.DRIPBLOCKED = true;
    } else {
      props.data.CourseHide = false;
    }
  } else {
    if (
      props.data.CourseCompletionStatus === "Completed" &&
      props.data.CourseCompletionPercentage === 100
    ) {
      props.data.CourseHide = false;
    } else {
      props.data.CourseHide = true;
    }
  }
  return (
    <>
      <div className={props.prodSearchLength ?  "grid-item grid-item-fixed" : "grid-item"} key={props.courseid} onClick={props.handleProductClick}>
      {/* style={{ opacity: props.survey !== "" ? 0.5 : 1 }} */}
        
      {props.currentPage === "products" && props.data?.badge_animation?
                    <div className="badgeCtn">
                        <div className="badgeWrapper">
                            <img className="outerCircle" src={require(`../../../assets/images/karate/beltoutercircle/${props.data?.badge_animation}.png`)} alt="" style={{ marginRight: "5px" }} />
                            <div className="innerAnimation">
                                <img className="karateAnimation" src={require(`../../../assets/images/karate/Badges/${props.data?.badge_animation}.gif`)} alt="" style={{ marginRight: "5px" }} />

                            </div>

                        </div>
                    </div> : null}
        <CourseCardComponent
          unitTitle={props.unitTitle}
          unitdesc={props.unitdesc}
          currentPage={props.currentPage}
          CourseCompletionStatus={props.CourseCompletionStatus}
          CourseCompletionPercentage={props.CourseCompletionPercentage}
          unitImage={props.productImage}
          unitAverageTime={props.courseEstimationTime}
          capsuleListLength={props.capsuleListLength}
          labels = {props.labels}
          totalunits = {props.totalunits}
          courseFeedback = {props.courseFeedback}
          showMoreDetails = {false}
          currentBelt={props.data?.badge_animation}
          isModule={props.isModule}
        />
      </div >
    </>
  );
};


export default ProductList;
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
//import { Spinner } from "react-bootstrap";
import { required, maxLength15 } from "../validate/Validate";
import { connect } from "react-redux";
//import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
//import Loader from "../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";

class TemplateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      groups: [],
      orgs: [],
      load: true,
      status: this.props.status,
      showScheduler: false,
    };
  }

  handleEditorChange = (content, editor) => {
    console.log("Content was updated:", content);
  };

  toggleSwitch = () => {
    this.setState({ status: !this.state.status });
  };
  changeType = (e) => {
    let tempId = e.target.value;
    let selectedTemp = this.props.templates.find(
      (temp) => temp.id.toString() === tempId
    );
    if (selectedTemp.can_schedule === 1) {
      this.setState({
        showScheduler: true,
      });
    } else {
      this.setState({
        showScheduler: false,
      });
    }
  };
  insertKeyword = (areaId, text) => {
    var txtarea = document.getElementById("txtarea");

    //  this.props.initialize({ body: 'some value here' });

    var scrollPos = txtarea.scrollTop;
    var strPos = 0;
    var br =
      txtarea.selectionStart || txtarea.selectionStart === "0"
        ? "ff"
        : document.selection
        ? "ie"
        : false;
    if (br === "ie") {
      txtarea.focus();
      var range = document.selection.createRange();
      range.moveStart("character", -txtarea.value.length);
      strPos = range.text.length;
    } else if (br === "ff") strPos = txtarea.selectionStart;

    var front = txtarea.value.substring(0, strPos);
    var back = txtarea.value.substring(strPos, txtarea.value.length);
    // txtarea.value = "{{"+front + text + back+"}}";
    this.props.initialize({ body: "{{" + front + text + back + "}}" });

    strPos = strPos + text.length;
    if (br === "ie") {
      txtarea.focus();
      var range = document.selection.createRange();
      // range.moveStart('character', -txtarea.value.length);
      range.moveStart("character", -this.props.initialValues.body.length);
      range.moveStart("character", strPos);
      range.moveEnd("character", 0);
      range.select();
    } else if (br === "ff") {
      txtarea.selectionStart = strPos;
      txtarea.selectionEnd = strPos;
      txtarea.focus();
    }
    txtarea.scrollTop = scrollPos;
  };
  renderSelectField = ({
    input,
    label,
    type,
    className,
    meta: { touched, error },
    children,
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <select {...input} className={className}>
          {children}
        </select>
        {touched && error && (
          <span className="required-field">{this.props.labels.labels[error]}</span>
        )}
      </div>
    </div>
  );
  renderField = ({
    input,
    label,
    type,
    className,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          {...input}
          placeholder={label}
          type={type}
          className={className}
        />
        {touched &&
          ((error && (
            <span className="required-field">
              {typeof error === "string"
                ? this.props.labels.labels[error]
                : this.props.labels.labels[error.label].replace(
                    "$$$",
                    error.val
                  )}
            </span>
          )) ||
            (warning && <span className="required-field">{warning}</span>))}
      </div>
    </div>
  );
  render() {
    const {
      LBLSELECT,
      LBLTEMPLATES,
      LBLTEMPLATENAME,
      LBLUPDATE,
      LBLFROMADDRESS,
      LBLCCRECEPIENTS,
      LBLREPLYTO,
      LBLSUBJECT,
      LBLBODY,
      LBLCANCEL,
      LBLSAVE,
    } = this.props.labels.labels;
    const {
      handleSubmit,
      submitting,
      templates,
      initialValues,
    } = this.props;
    return (
      <div>
        {!this.state.load ? (
          <form className="form">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                <div className="form-group">
                  <Skeleton height={59} />
                </div>
                <div className="form-group">
                  <Skeleton height={59} />
                </div>
                <div className="form-group">
                  <Skeleton height={59} />
                </div>
                <div className="form-group">
                  <Skeleton height={59} />
                </div>
                <div className="form-group">
                  <Skeleton height={59} />
                </div>
                <div className="form-group">
                  <Skeleton height={59} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <Skeleton height={325} />
                </div>
              </div>
            </div>
          </form>
        ) : (
          <form className="form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                <div className="form-group">
                  <Field
                    name="templatetype"
                    component={this.renderSelectField}
                    label={LBLTEMPLATES}
                    onChange={this.changeType}
                    className="form-control"
                    validate={[required]}
                  >
                    <option
                      value=""
                      disabled
                      style={{ color: "#cccccc" }}
                    >{`--${LBLSELECT}--`}</option>
                    {templates.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.template_use}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="form-group">
                  <Field
                    name="templatename"
                    type="text"
                    component={this.renderField}
                    label={LBLTEMPLATENAME}
                    className="form-control"
                    validate={[required, maxLength15]}
                  />
                </div>

                <div className="form-group">
                  <Field
                    name="from"
                    type="text"
                    component={this.renderField}
                    label={LBLFROMADDRESS}
                    className="form-control"
                    validate={[required]}
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="cc"
                    type="text"
                    component={this.renderField}
                    label={LBLCCRECEPIENTS}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="replyto"
                    type="text"
                    component={this.renderField}
                    label={LBLREPLYTO}
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <Field
                    name="subject"
                    type="text"
                    component={this.renderField}
                    label={LBLSUBJECT}
                    className="form-control"
                    validate={[required]}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label>{LBLBODY}</label>
                  <Editor
                    initialValue={
                      this.props.initialValues
                        ? this.props.initialValues.body
                        : ""
                    }
                    init={{
                      height: 300,
                      menubar: false,
                      language: this.props.user_lang,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={this.handleEditorChange}
                  />
                </div>
                <Link className="btn btn-secondary" to="/schome/templates">
                  {LBLCANCEL}
                </Link>
                &nbsp;
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitting}
                >
                  {initialValues === null ? LBLSAVE : LBLUPDATE}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

TemplateForm = reduxForm({
  form: "templateform", // a unique name for this form
})(TemplateForm);

const mapStateToProps = (state) => {
  return {
    user_lang: state.user_info_reducer.user_info.language_code,
  };
};

export default connect(mapStateToProps, null)(TemplateForm);

const initialState = {
   companyInfo: null
}

const companyinfo_reducer = (state = initialState, action)=>{    
    switch(action.type){ 
        case "SET_COMPANYINFO": 
            state.companyInfo = action.payload;
            console.log("company_info reducer",state.companyInfo);
            return state;
        default: return state
    }
}

export default companyinfo_reducer;
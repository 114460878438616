import React, { Component } from "react";
import userInfo from "../redux/action";
import { Modal } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import services from "../services/common_services";
import admin_services from "../admin/services/services";
import { createBrowserHistory } from "history";
import logout_actions from "../redux/Logout/logout-action/logout_actions";
import Skeleton from "react-loading-skeleton";


const history = createBrowserHistory();
const $ = require("jquery");


class RoleChanger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_roles: [],
      showErr: false,
      showalert: false,
      alerttext: "",
      selected_role: { role_name: this.props.role, role_id: this.props.role_id },
      selected_role_name: "",
    };
  }

  componentDidMount() {
    this.getUserRole();

  }

  getUserRole = () => {
    let defaultrole = {
      role_id: this.props.role_id,
      role_name: this.props.role
    }
    services
      .getRoles(true)
      .then((user_roles) => {
        let current_role = user_roles.filter(i => i.role_id === defaultrole.role_id)
        this.setState({ user_roles: user_roles, selected_role: current_role[0] },
          () => { $('.form-control-profile .MuiAutocomplete-input').attr('readOnly', 'true'); });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeRole = (e, roles) => {
    console.log("changeRole", this.changeRole)
    var role = roles.role_id;
    if (role === 1 && this.props.email !== null && !this.props.email_verified) {
      console.log("email_verified1", this.props)
      this.setState({
        showalert: true,
        alerttext: this.props.labels.LBLNOROLEEMAILVERIFY
      })
      return true
    } else if (role === 1 && this.props.email === null && !this.props.email_verified) {
      console.log("email_verified2", this.props)
      this.setState({
        showalert: true,
        alerttext: this.props.labels.LBLNOEMAILVERIFY
      })
      return true
    }
    this.setState({
      selected_role: roles,
    })
    this.props.logout_actions();
    setTimeout(() => {
      this.setReducer(role);
    }, 1);
  };

  setReducer = (role) => {
    console.log("set reducer", role)
    let userReducerData = {
      name: this.props.name,
      role: this.props.role,
      email: this.props.email,
      email_verified: this.props.email_verified,
      company: this.props.company,
      role_id: parseInt(role),
      roleIds: this.props.roleIds,
      language_code: this.props.language_code,
      user_language: this.props.user_language,
      user_id: this.props.user_id,
      company_id: this.props.company_id,
      company_name: this.props.company_name,
      timezone: this.props.timezone,
      dateformat: this.props.dateformat,
      timeformat: this.props.timeformat,
      currency: this.props.currency,
      company_logo: this.props.company_logo,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      ssi_iam_user: null,
      ssi_data_priv: this.props.ssi_data_priv,
      showcategory_filter: this.props.showcategory_filter,
      showunits_certificate: this.props.showunits_certificate
    };
    this.props.user_info_reducer(userReducerData);
  };

  changeDefault = (e, roles) => {
    let role = roles.role_id;    
    if (role === 1 && this.props.email !== null && !this.props.email_verified) {
      console.log("email_verified3", this.props)
      this.setState({
        showalert: true,
        alerttext: this.props.labels.LBLNOROLEEMAILVERIFY
      })
      return true
    } else if (role === 1 && this.props.email === null && !this.props.email_verified) {
      console.log("email_verified4", this.props)
      this.setState({
        showalert: true,
        alerttext: this.props.labels.LBLNOEMAILVERIFY
      })
      return true
    }

    if (role === 0) {
      this.setState({
        showErr: true,
      });
    } else {
      let data = {
        role_id: role,
        user_id: this.props.user_id
      };
      console.log(roles,"roleschangedefault")
      let usInfo = {...this.props.userInfo, role_id: role,role: roles.role_name
      };
      this.props.user_info_reducer(usInfo);
      // return true;
      admin_services
        .changeRoles(data)
        .then((res) => {
          if (this.state.showErr) {
            this.setState({
              showErr: false,
            });
          }
          if (res) {
            console.log(role,"changeDefaultSuccess")            
            this.setState({
              selected_role: roles,
            }, () => {
              if (role === 1 || role === 2) {              
                history.replace("/schome/dashboard");              
              } else if (role === 3 || role === 4) {
                history.replace("/home/dashboard");              
              }
              window.location.reload();
            })            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  closeModal = () => {
    this.setState({
      showalert: false
    })
  }

  render() {
    const { changeDefault } = this.props;
    return (
      <div className="container">
        <div className="row form-group" style={{display:"flex",alignItems:"center"}}>
        
          <label htmlFor="role" style={{marginBottom:this.props.currentpage==="profile"? "" : "0px"}}>
          
              {this.props.currentpage==="profile"?this.props.labels.LBLDEFAULTROLE: this.props.labels.LBLCHANGEROLE}

          </label>  
  
          {/* //console.log("this.state.selected_role", this.state.selected_role)} */}
          {this.state.user_roles.length > 0 ? (
            <Autocomplete
              className="form-control-dashboard form-control-profile"
              style={{marginLeft:this.props.currentpage==="profile"? "0px" : "10px"}}
              aria-required="true"
              filterOptions={(options) => options}
              required
              disableClearable
              options={this.state.user_roles}
              getOptionLabel={(option) => this.props.labels[option.role_name]}
              getOptionSelected={(option, values) =>
                option.role_id === values.role_id
              }
              value={this.state.selected_role}
              defaultValue={this.state.selected_role}
              onChange={changeDefault ? this.changeDefault : this.changeRole}
              renderOption={(option) => (
                <div style={{ fontSize: "14px" }}>{this.props.labels[option.role_name]}</div>
              )}
              renderInput={(params) => (
                <div>
                  <TextField
                    {...params}
                  />
                </div>
              )}
            />
          ) : (
            <div>
              <div>
                <Skeleton height={35} />
              </div>
            </div>
          )}
          {this.state.showErr ? <p>Required</p> : ""}
        </div>
        <div>
          <Modal
            show={this.state.showalert}
            onHide={this.closeModal}
          >
            <Modal.Header className='alert-modal-header'>
              <Modal.Title className= "alert-modal-title">
                <h4 className="modal-title">{this.props.labels.LBLEMAILVERIFICATION}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">{this.state.alerttext}</div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    className="button-style primary-btn"
                    id="btn-ok"
                    onClick={this.closeModal}
                  >
                    {this.props.labels.LBLOK ? this.props.labels.LBLOK : "OK"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.user_info_reducer.user_info,
    name: state.user_info_reducer.user_info.name,
    user_id: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    user_language: state.user_info_reducer.user_info.user_language,
    company: state.user_info_reducer.user_info.company,
    company_id: state.user_info_reducer.user_info.company_id,
    email: state.user_info_reducer.user_info.email,
    email_verified: state.user_info_reducer.user_info.email_verified,
    language_code: state.user_info_reducer.user_info.language_code,
    company_name: state.user_info_reducer.user_info.company_name,
    timezone: state.user_info_reducer.user_info.timezone,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    currency: state.user_info_reducer.user_info.currency,
    company_logo: state.user_info_reducer.user_info.company_logo,
    first_name: state.user_info_reducer.user_info.first_name,
    last_name: state.user_info_reducer.user_info.last_name,
    ssi_data_priv: state.user_info_reducer.user_info.ssi_data_priv,
    roleIds: state.user_info_reducer.user_info.roleIds,
    showcategory_filter: state.user_info_reducer.user_info.showcategory_filter,
    showunits_certificate: state.user_info_reducer.user_info.showunits_certificate,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    user_info_reducer: (userReducerData) => dispatch(userInfo(userReducerData)),
    logout_actions: () => dispatch(logout_actions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleChanger);

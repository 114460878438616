import React, { Component, createElement } from "react";
import { classList } from "dynamic-class-list";
import { Map } from "immutable";
import { debounce } from "../functionUtils";
import Loader from "../../loader/Loader";
import StringWidget from "../components/StringWidget";
import StringSingleWidget from "../components/StringSingleWidget";
import NumberWidget from "../components/NumberWidget";
import DateWidget from "../components/DateWidget";
import BooleanWidget from "../components/BooleanWidget";
import { connect } from "react-redux";
import  "./CustomFilter.scss"
import $ from 'jquery';
const moment = require("moment");
const widgets = {
  string: StringWidget,
  number: NumberWidget,
  date: DateWidget,
  boolean: BooleanWidget,
  stringsingle: StringSingleWidget,
};
let showDet=false;
var _ = {};
const REFS = {};
const WIDGETREFS = {};
const ON_CHANGE_DELAY = 200;
class CustomFilter extends Component {
  state = {
    show: 0,
  };
  constructor(props) {
    super(props);
    this.state = {
      availableFields: this.props.availableFields,
      orgavailableFields: JSON.parse(
        JSON.stringify(this.props.availableFields)
      ),
      checkListId: this.props.id + "_checkList",
      filterListId: this.props.id + "_filterList",
      selectedFilter: { filter: {} },
      ComponentId: this.props.id,
      headerText: this.props.headerText,
      filterObj: {},
      submitText: this.props.submitText,
    };
    this.onFilterChangeDebounced = debounce(
      props.onFilterChange,
      ON_CHANGE_DELAY
    );
    this.onExportDebounced = debounce(props.onExport, ON_CHANGE_DELAY);
    this.onResetDebounced = debounce(props.onReset, ON_CHANGE_DELAY);
    this.onFilterCancelDebounced = debounce(
      props.onFilterCancel,
      ON_CHANGE_DELAY
    );
    this.Exportoptions = [{ id: 1, value: this.props.labels.LBLEXPORTTOCSV },
    { id: 2, value: this.props.labels.LBLEXPORTTOEXCEL }]
  }


  redraw = (xx) => { };
  onConditionCancel = (condition) => { };
  onError = (item) => {
    if (document.getElementById(this.state.checkListId).style.display == "none") {
      this.showCheckList()
    }
    WIDGETREFS[item.name].onError();
  }
  onCloseCheckList = () => {
    document.getElementById(this.state.checkListId).style.display = "none";
  }
  onRunReport = () => {
    let isValid = true
    // this.state.availableFields.map((item, i) => {
    //   if (item.filter.checked && item.filter.required) {
    //     try {
    //       if ((item.filter.condition.operator == "MULTI_EQUALS" || item.filter.condition.operator == "EQUALS") && item.filter.condition.value == "") {
    //         isValid = false
    //         WIDGETREFS[item.name].onError();
    //       }
    //     } catch (e) {
    //       isValid = false
    //       WIDGETREFS[item.name].onError();
    //     }
    //   }
    // });
    if (isValid === true) {
      this.state.availableFields.map((item, i) => {
        if (item.filter.checked) {
          WIDGETREFS[item.name].onDoneClick();
        }
      });
      this.onFilterChangeDebounced(
        this.state.ComponentId,
        this.state.availableFields
      );
      if (this.props.initialMode && this.props.initialMode === "OPEN") {
      } else {
        document.getElementById(this.state.checkListId).style.display = "none";
      }
    } else {
      if (document.getElementById(this.state.checkListId).style.display == "none") {
        this.showCheckList()
      }
    }

  };
  handlePreviousInputBlur = (xname) => {
    this.state.availableFields.map((item, i) => {
      if (item.filter.checked && item.name !== xname) {
        WIDGETREFS[item.name].handleInputBlur();
      }
    });
  };
  onCancel = (condition) => {
    //console.log("onCancel", this.state.availableFields);
    document.getElementById(this.state.checkListId).style.display = "none";

    this.setState({
      selectedFilter: { filter: {} },
      availableFields: JSON.parse(
        JSON.stringify(this.state.orgavailableFields)
      ),
    });

    this.state.orgavailableFields.map((item, i) => {
      if (item.filter.checked) {
        ////alert(item.name + " - " + (item.filterData || []).length)
        const filter = this.getFilter(item);
        const xfield = filter.get("field");
        const xprops = Object.assign(
          {
            operator: filter.get("operator"),
            value: filter.get("value"),
            filterName: item.name,
            labels: this.props.labels,
            filterData: item.filterData || [],
            filtertype: item.filter.type,
            required: item.filter.required,
            onRef: (ref) => {
              WIDGETREFS[item.name] = ref;
            },
          },
          xfield.widgetOptions
        );
        //console.log("xprops", xprops);
        ////alert(JSON.stringify(xprops))
        WIDGETREFS[item.name].cancelDone(xprops);
      }
    });
    if(this.props.onClickFilter)
      this.props.onClickFilter()
  };
  //orgAvailableFields
  onReset = (condition) => {
    //console.log("onReset", this.state.availableFields);
    if(this.props.onReset)
    this.props.onReset()
    this.setState({
      selectedFilter: { filter: {} },
      availableFields: JSON.parse(
        JSON.stringify(this.props.orgAvailableFields)
      ),
      orgavailableFields: JSON.parse(
        JSON.stringify(this.props.orgAvailableFields)
      ),
    });

    this.props.orgAvailableFields.map((item, i) => {
      if (item.filter.checked) {
        ////alert(item.name + " - " + (item.filterData || []).length)
        const filter = this.getFilter(item);
        const xfield = filter.get("field");
        const xprops = Object.assign(
          {
            operator: filter.get("operator"),
            value: filter.get("value"),
            filterName: item.name,
            labels: this.props.labels,
            filterData: item.filterData || [],
            filtertype: item.filter.type,
            required: item.filter.required,
            onRef: (ref) => {
              WIDGETREFS[item.name] = ref;
            },
          },
          xfield.widgetOptions
        );
        //console.log("xprops", xprops);
        ////alert(JSON.stringify(xprops))
        WIDGETREFS[item.name].cancelDone(xprops);
      }
    });
  };
  cancelDone(data) {
    //alert("cancelDOne")
    //console.log("cancelDone", data);
    this.setState({
      availableFields: data,
    });
    data.map((item, i) => {
      if (item.filter.checked) {
        ////alert(item.name + " - " + (item.filterData || []).length)
        const filter = this.getFilter(item);
        const xfield = filter.get("field");
        const xprops = Object.assign(
          {
            operator: filter.get("operator"),
            value: filter.get("value"),
            filterName: item.name,
            labels: this.props.labels,
            filterData: item.filterData || [],
            filtertype: item.filter.type,
            required: item.filter.required,
            onRef: (ref) => {
              WIDGETREFS[item.name] = ref;
            },
          },
          xfield.widgetOptions
        );
        //console.log("xprops", xprops);
        ////alert(JSON.stringify(xprops))
        WIDGETREFS[item.name].cancelDone(xprops);
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    //alert("2nd")
    //console.log("componentWillReceiveProps", nextProps);
    let crsdet, availableFields = nextProps.availableFields;
    availableFields.map((item)=>{
      if(item.cntid == "Unit_Name"){
        crsdet = availableFields.filter(i=>i.cntid=="Course_Name");
        crsdet= crsdet && crsdet[0];
        if(crsdet && crsdet.filter.condition){
          if(crsdet.filter.condition.value=="" || crsdet && crsdet.filter.condition.value.length==0){
            item.filter.condition.value="";
          }
        }
      } 
    })
    this.setState({
      availableFields: nextProps.availableFields,
      orgavailableFields: JSON.parse(JSON.stringify(nextProps.availableFields)),
      checkListId: nextProps.id + "_checkList",
      filterListId: nextProps.id + "_filterList",
      selectedFilter: { filter: {} },
      ComponentId: nextProps.id,
      filterObj: {},
    });
  }
  componentDidMount() {
    // this.showCheckList()
    _ = this
    //console.log("componentDidMount", this.state);
    this.props.onRef(this);
    $(document).on('mouseup', function (e) {
      _.handlePreviousInputBlur("aa")
    });
    if (this.props.initialMode && this.props.initialMode === "OPEN") {
      this.showCheckList()
      this.state.availableFields.map((item, i) => {
        if (item.filter.checked) {
          WIDGETREFS[item.name].onDoneClick();
        }
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.initialMode !== this.props.initialMode) {
      if (this.props.initialMode && this.props.initialMode === "OPEN") {
      } else {
        document.getElementById(this.state.checkListId).style.display = "none";
      }
    }
    else if (this.props.changeMode ) {
      document.getElementById(this.state.checkListId).style.display = "block";
      this.props.onSetVals()
    }

  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  onExportDone = () => {
    setTimeout(function () {
      document.getElementById("CSTFilter_spinner").style.display = "none";
    }, 100);
  };
  onExport = (xtype) => {
    //console.log("xtype", this.state.availableFields, xtype)
    let isValid = true
    this.state.availableFields.map((item, i) => {
      if (item.filter.checked && item.filter.required) {
        try {
          if ((item.filter.condition.operator == "MULTI_EQUALS" || item.filter.condition.operator == "EQUALS") && item.filter.condition.value == "") {
            isValid = false
            WIDGETREFS[item.name].onError();
          }
        } catch (e) {
          isValid = false
          WIDGETREFS[item.name].onError();
        }
      }
    });
    if (isValid === true) {
      document.getElementById("CSTFilter_spinner").style.display = "";
      this.onExportDebounced(
        xtype,
        this.state.ComponentId,
        this.state.availableFields
      );
    } else {
      if (document.getElementById(this.state.checkListId).style.display == "none") {
        this.showCheckList()
      }
    }
  };
  selectField = (field, chkstate) => {
    //alert('selectfield')
    let name = field.name;
    //console.log("selectField", name, chkstate);
    let xavailableFields = this.state.availableFields;
    let xindex = -1;
    for (var i = 0; i < xavailableFields.length; i++) {
      if (xavailableFields[i].name === name) {
        //console.log("xselected.dispName", xavailableFields[i].dispName, name);
        xavailableFields[i].filter.checked = chkstate;
        xindex = i;
      }
    }
    this.onFilterChangeDebounced(
      this.state.ComponentId,
      xavailableFields,
      xindex
    );
    // console.log(
    //   "xselectedxselectedxselectedxselected",
    //   xavailableFields,
    //   xindex
    // );
    //to show the widget
    //document.getElementById(this.state.checkListId).style.display = ""
    this.setState({
      availableFields: xavailableFields,
    });
  };

  onSelClick = (field) => {
    //close checkListId filter popup
    let x = document.getElementById(this.state.checkListId).parentElement
      .offsetWidth;
    document.getElementById(this.state.checkListId).style.width = x + "px";
    document.getElementById(this.state.checkListId).style.display = "";
    this.setState({
      selectedFilter: field,
    });
    this.props.onChanges()
    // if (field.name != this.state.selectedFilter.name || !this.state.selectedFilter.name) {
    //   this.setState({
    //     selectedFilter: field
    //   })
    // } else {
    //   this.setState({
    //     selectedFilter: { filter: {} }
    //   })
    // }
  };
  showCheckList = (e) => {
    //raise cancel event for the slected filter popup
    this.setState({
      selectedFilter: { filter: {} },
    });
    showDet=true;
    this.handlePreviousInputBlur("");
    if (
      document.getElementById(this.state.checkListId).style.display === "none"
    ) {
      let x = document.getElementById(this.state.checkListId).parentElement
        .offsetWidth;
      document.getElementById(this.state.checkListId).style.width = x + "px";
      document.getElementById(this.state.checkListId).style.display = "";
    } else {
      this.onCancel();
    }
    if(this.props.onClickFilter)
    this.props.onClickFilter()
  };
  clickField = (field) => { };

  getClassName = (field) => {
    if (field.filter.type === "date") {
      return classList({
        fas: true,
        "fa-calendar-check": true,
      });
    } else if (field.name === "Location") {
      return classList({
        fas: true,
        "fa-map-marker": true,
      });
    } else if (field.name === "Department") {
      return classList({
        fas: true,
        "fa-sitemap": true,
      });
    } else if (field.name === "Name") {
      return classList({
        fas: true,
        "fa-user": true,
      });
    } else if (field.name === "Trained") {
      return classList({
        fas: true,
        "fa-graduation-cap": true,
      });
    } else if (field.name === "Enrollments") {
      return classList({
        fas: true,
        "fa-toggle-on": true,
      });
    } else if (field.name === "Course Name") {
      return classList({
        fas: true,
        "fa-book": true,
      });
    } else if (field.name === "Time Spent") {
      return classList({
        fas: true,
        "fa-clock": true,
      });
    } else if (field.name === "Status") {
      return classList({
        fas: true,
        "fa-certificate": true,
      });
    } else if (field.name === "Progress") {
      return classList({
        fas: true,
        "fa-percent ": true,
      });
    } else {
      return classList({
        fas: true,
        "fa-filter": true,
      });
    }
  };

  onChangeCall=(item)=>{
    if(this.props.onChanges){
       this.props.onChanges(item,WIDGETREFS) 
    }
  } 
  render() {
    //{ console.log("restrictExport", this.props.restrictExport) }
    const {
      LBLRESET,
      LBLSHOWHIDECOLUMNS,
      LBLFILTERS,
      LBLRUNREPORT,
      LBLADDFILTER,
      LBLEXPORT,
      LBLEXPORTTOEXCEL,
      LBLEXPORTTOCSV,
    } = this.props.labels;


    return (
      <div className="customfilter reportsCtn" key={this.props.value}>
        <div>
          <div className="row box ml-1" style={{ marginBottom: "10px" }}>
            <div className="btn-group" role="group">
              <button
                type="button"  
                className="basicSelItem"
                onClick={this.showCheckList}
              >
                <i className="fas fa-filter" aria-hidden="true"></i>{" "}
                {LBLADDFILTER}
              </button>
            </div>
            <div className="btn-group ml-auto mr-3 customExportCtn" role="group">
              <span
                id="CSTFilter_spinner"
                style={{
                  // marginTop: "10px",
                  display: "none",
                  marginRight: "10px",
                }}
              >
                <Loader></Loader>
              </span>
              <button
                id="btnGroupDrop1"
                type="button"
                className="dropdown-toggle "
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  marginRight: "-2px",
                  height: "36px",
                  minWidth: "100px",
                  padding: "0px",
                  borderRadius: "0px",
                  borderTop: "none",
                  borderRight: "none",
                  borderBottom: "1px solid black",
                  borderLeft: "none",
                  borderImage: "initial",
                  background: "none",
                  textAlign: "left"
              }}
              >
                <i className="fas fa-mail-forward" aria-hidden="true">
                  &#xf064;
                </i>{" "}
                <span style={{padding: '5px 10px'}}>{LBLEXPORT}</span>
              </button> 
              <div
                className="dropdown-menu  dropdown-menu-right"
                aria-labelledby="btnGroupDrop1"
              >
                {!this.props.restrictExport || (this.props.restrictExport && this.props.restrictExport !== "csv") ?
                  <span
                    className="dropdown-item exportCsvXlsv"
                    onClick={this.onExport.bind(this, "csv")}
                  >
                    {LBLEXPORTTOCSV}
                  </span>
                  : ""
                }

                <span
                  className="dropdown-item exportCsvXlsv"
                  onClick={this.onExport.bind(this, "xlsx")}
                >
                  {LBLEXPORTTOEXCEL}
                </span>
              </div> 
            </div>
          </div>
          <div
            className="row col-sm-12 prowfilterbox"
            id={this.state.checkListId}
            style={{
              display: "none",
              position: "absolute",
              paddingTop: "16px",
            }}
          >
            <div className="col-sm-12">
              <i
                class="fas fa-times ml-2"
                style={{
                  float: "right",
                  fontSize: "large",
                  marginRight: "-18px",
                  marginTop: "-10px",
                  cursor: "pointer"
                }}
                onClick={this.onCancel.bind(this, this.state.condition)}
              ></i>
            </div>
            {this.props.showFieldFilter ? (
              <>
                <div className="col-sm-12" style={{ paddingLeft: "0px" }}>
                  <h4>{LBLSHOWHIDECOLUMNS}</h4>
                </div>
                <div className="col-sm-12">
                  <ul>
                  {this.state.availableFields.map(field => this.renderField(field, this.props.reportName))}
                  </ul>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="col-sm-12" style={{ paddingLeft: "0px" }}>
              <h4>{LBLFILTERS}</h4>
            </div>
            <div
              className="col-sm-12 box"
            >
              <div className="col-sm-12" id={this.state.filterListId}>
                {this.state.availableFields ? (
                  <>
                    {this.state.availableFields.map((item, i) => {
                      if (item.filter.checked) {
                        const filter = this.getFilter(item);
                        const xfield = filter.get("field");
                        const xprops = Object.assign(
                          {
                            operator: filter.get("operator"),
                            value: filter.get("value"),
                            filterName: item.name,
                            onConditionChange: this.onConditionChange,
                            labels: this.props.labels,
                            onConditionCancel: this.onConditionCancel,
                            handlePreviousInputBlur: this
                              .handlePreviousInputBlur, 
                            filterData: item.filterData || [],
                            filtertype: item.filter.type,
                            required: item.filter.required,
                            nosort:item.filter.nosort,
                            onRef: (ref) => {
                              WIDGETREFS[item.name] = ref;
                            },
                          },
                          xfield.widgetOptions
                        );
                        if(item.filter.changeEvent){
                          xprops["onChangeCall"]=this.onChangeCall
                        }else{
                          xprops["onChangeCall"]=false
                        }
                        //console.log("xprops", xprops);
                        return (
                          <div
                            className="col-sm-12" id={item.cntid}
                            key={this.state.ComponentId + "_" + item.name}
                            style={{ marginBottom: "3px" }}
                          >
                            <div
                              className="col-xl-3 col-sm-12"
                              style={
                                {
                                  display: "inline-block",
                                  verticalAlign: "top",
                                  padding: "0px 7px 0px 0px",
                                }}
                            >
                              <span className="custItemName"
                              >
                                {item.name}{item.filter.required ? <span className="requiredHas"> *</span> : ""}
                              </span>

                            </div>
                            {createElement(xfield.widget, xprops)}
                          </div>
                        );
                        // return <div>{createElement(xfield.widget, xprops)}</div>
                      } else {
                        return <div key={Math.random()}></div>;
                      }
                    })}
                  </>
                ) : (
                  ""
                )}
                <div
                  className="row box ml-1"
                  style={{ marginBottom: "30px", marginTop: "20px" }}
                >
                  <div className="ml-auto mr-3" role="group">
                    <button
                      style={{ display: "inline-block" }}
                      onClick={this.onReset.bind(this, this.state.condition)}
                      type="button"
                      className="button-style secondary-btn"
                    >
                      {LBLRESET}
                    </button>

                    <button
                      style={{ display: "inline-block", marginLeft: "10px" }}
                      onClick={this.onRunReport.bind(this)}
                      type="button"
                      className="button-style primary-btn"
                    >
                      {this.state.submitText ? this.state.submitText : LBLRUNREPORT}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.props.reportName !== "RYSCONVERSATION" ?
            <div id="reports_title"
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <h3>{this.props.companyName}</h3>
              <h2>{this.props.reportName}</h2>
              </div>
            :
            null
        }
              <div
                style={{
                  textAlign: "start",
                  // marginLeft: "-5px",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                <div>
                  {this.state.availableFields.map(
                    this.renderSelectedField.bind(this)
                  )}
                </div>
              </div>
      </div>
    );
  }
  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  showReportField = (field, chkstate) => {
    let name = field.name;
    //console.log("selectReportField", name, chkstate);
    let xavailableFields = this.state.availableFields;
    //let xindex;
    for (var i = 0; i < xavailableFields.length; i++) {
      if (xavailableFields[i].name === name) {
        //console.log("xselected.dispName", xavailableFields[i].dispName, name);
        xavailableFields[i].filter.showField = chkstate;
        //let xindex = i;
      }
    }
    this.setState({
      availableFields: xavailableFields,
    });
  };
  renderField(field,reportName) {
    console.log(reportName,"reportName")
    const onChange = (e) => {
      if (e.target.checked) {
      } else {
      }
      this.showReportField(field, e.target.checked);
    };
    
    if (field.filter.showFieldFilter === false ) {
      return ""
    } else if(reportName === "RYSCONVERSATION" && field.name === this.props.labels.LBLDATE) { 
      return ""
    }else {
      return (
        <li key={field.name} style={{ display: "inline-block" }}>
          <div
            style={{ display: "inline-block", paddingTop: "10px" }}
            className="icoBtn"
          >
            <label className="check-lbl">
              <input
                type="checkbox"
                name={"chkg_" + field.name}
                id={"chkg_" + field.name}
                data-toggle="modal"
                checked={field.filter.showField}
                onChange={onChange}
              />
              <span className="checkmark"></span>
              <span style={{ display: "block", paddingTop: "6px" }}>
                {field.name}
              </span>
            </label>
          </div>
          <label
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              marginLeft: "-10px",
              marginRight: "10px",
            }}
            className={this.getBtnBasicClassName(
              field,
              this.state.selectedFilter
            )}
          >
          </label>
        </li>
      );
    }

  }

  renderSelectedField(field) {
    const setMoreLink = (x) => {
      if (x !== 1) {
        setTimeout(() => {
          setMoreLink(1);
        }, 100);
      } else {
        try {
          let vcp = "selItem_parent_" + field.name.split(" ").join("_");
          let vcx = "selItem_" + field.name.split(" ").join("_");
          let vca = "selItem_span_" + field.name.split(" ").join("_");
          let vcmore = "selItem_spanmore_" + field.name.split(" ").join("_");
          let vcless = "selItem_spanless_" + field.name.split(" ").join("_");
          let xhight = this.refs[vcx].offsetHeight;
          let vcphight = this.refs[vcp].offsetHeight;

          this.refs[vca].innerHTML = xhight + "px";
          if (xhight > 25) {
            if (vcphight < 25) {
              this.refs[vcmore].style.display = "";
              this.refs[vcless].style.display = "none";
            } else {
              this.refs[vcmore].style.display = "none";
              this.refs[vcless].style.display = "";
            }
          } else {
            this.refs[vcmore].style.display = "none";
            this.refs[vcless].style.display = "none";
          }
        } catch (e) {
        }
      }
    };
    if (field.filter.checked) {
      let dispText = this.getConditionDisplayText(field);
      if (dispText !== "") {
        return (
          <>
          <div className= {localStorage.getItem('site_type')==='SafeStart'?"custItemWrapp":"greencustItemWrapp"}>
            <div
              className="align-items-top"
              style={{
                padding: "0px",
                height: "24px",
                overflow: "hidden",
                width: "calc(100% - 11px)",
              }}
              onChange={setMoreLink()}
              ref={"selItem_parent_" + field.name.split(" ").join("_")}
            >
              <div
                id={"selItem_spanmore_" + field.name.split(" ").join("_")}
                ref={"selItem_spanmore_" + field.name.split(" ").join("_")}
                style={{
                  float: "right",
                  marginTop: "0px",
                  color: "blue",
                  cursor: "pointer",
                  display: "none",
                }}
                href=""
                onClick={() => {
                  let vcp = "selItem_parent_" + field.name.split(" ").join("_");
                  this.refs[vcp].style.height = "auto";
                  let vcmore =
                    "selItem_spanmore_" + field.name.split(" ").join("_");
                  let vcless =
                    "selItem_spanless_" + field.name.split(" ").join("_");
                  this.refs[vcmore].style.display = "none";
                  this.refs[vcless].style.display = "";
                }}
              >
                <i
                  class="fa fa-plus"
                  style={{
                    marginRight: "5px",
                    // backgroundColor: "white",
                    color: "black",
                  }}
                ></i>
                {this.props.labels.LBLMORE}
              </div>
              <div
                id={"selItem_spanless_" + field.name.split(" ").join("_")}
                ref={"selItem_spanless_" + field.name.split(" ").join("_")}
                style={{
                  float: "right",
                  marginTop: "0px",
                  color: "blue",
                  cursor: "pointer",
                  display: "none",
                }}
                href=""
                onClick={() => {
                  let vcp = "selItem_parent_" + field.name.split(" ").join("_");
                  this.refs[vcp].style.height = "24px";
                  let vcmore =
                    "selItem_spanmore_" + field.name.split(" ").join("_");
                  let vcless =
                    "selItem_spanless_" + field.name.split(" ").join("_");
                  this.refs[vcmore].style.display = "";
                  this.refs[vcless].style.display = "none";
                }}
              >
                <i
                  class="fa fa-minus"
                  style={{
                    marginRight: "5px",
                    // backgroundColor: "white",
                    color: "black",
                  }}
                ></i>{" "}
                {this.props.labels.LBLLESS}
              </div>
              <div ref={"selItem_" + field.name.split(" ").join("_")}>
                <span
                  id={"selItem_span_" + field.name.split(" ").join("_")}
                  ref={"selItem_span_" + field.name.split(" ").join("_")}
                  style={{
                    //display:"inline-block"
                    display: "none",
                  }}
                ></span>
                <b className="renderOptionCust">{field.name} :</b>
                <span
                  className={localStorage.getItem('site_type')==='SafeStart'?"renderOptionVal":"greenrenderOptionVal"}
                >
                  {dispText}
                </span>
              </div>
            </div>
          </div>
          </>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getBtnBasicClassName = (field, selectedFilter) => {

    //if(this.selectedFilter && field){
    if (selectedFilter.name === field.name) {
      return classList({
        btn: true,
        basicItem: true,
        btnactive: true,
      });
    }
    //}
    return classList({
      btn: true,
      basicItem: true,
      btnactive: false,
    });
  };
  getBtnClassName = (field, selectedFilter) => {
    //if(this.selectedFilter && field){
    if (selectedFilter.name === field.name) {
      return classList({
        btn: true,
        basicSelItem: true,
        btnactivew: true,
      });
    }
    //}
    return classList({
      btn: true,
      basicSelItem: true,
      btnactive: false,
    });
  };
  formatdate = function (xDate) {
    if (xDate) {
      return moment(xDate).format(this.props.dateformat);
    } else {
      return "";
    }
  };
  getConditionDisplayText = (field) => {
    let xtext = "";
    if (
      field.filter.condition &&
      field.filter.condition.operator !== "NOCONDITION"
    ) {
      if (
        (typeof field.filter.condition.value == "string" &&
        field.filter.condition.value === "") || field.filter.condition.notext == true
      ) {
        xtext = "";
      } else {
        if (field.filter.type === "date") {
          let xval = field.filter.condition.value;
          if (xval === "Null") {
            xtext = ""; //this.props.labels.LBLALL
          } else {
            if (xval[0] && xval[1]) {
              if (xval[0]) {
                xtext =
                  field.filter.condition.operatorText +
                  " " +
                  this.formatdate(xval[0]) +
                  "";
              }
              if (xval[1]) {
                xtext = xtext + " " + "-" + " " + this.formatdate(xval[1]) + "";
              }
            } else {
              xtext =
                field.filter.condition.operatorText +
                " " +
                this.formatdate(xval) +
                "";
            }
          }
        } else {
          if (typeof field.filter.condition.value == "string") {
            xtext =
              field.filter.condition.operatorText +
              "" +
              field.filter.condition.value +
              "";
          } else if (typeof field.filter.condition.value == "number") {
            xtext =
              field.filter.condition.operatorText +
              "" +
              field.filter.condition.value +
              "";
          } else {
            xtext = ""; //field.filter.condition.operatorText
            let arrVx = [];
            for (let vx in field.filter.condition.value) {
              arrVx.push("" + field.filter.condition.value[vx].value + "");
            }
            if (arrVx.length === 0) {
              xtext = "";
            } else {
              xtext = xtext + " " + arrVx.join(", ");
            }
          }
        }
      }
    } else {
      xtext = "";
    }

    //alert(field.filter.type + "--" + xtext)
    return xtext;
  };

  onFieldChange = (fieldName) => {
  };

  onOperatorChange = (operator) => {
    let xx = this.state.selectedFilter;
    xx.filter.operator = operator;
    xx.filter.value = "ttt";
    this.setState({
      selectedFilter: xx,
    });
  };

  onValueChange = (value) => {
    return {};
  };

  getField = (item) => {
    let type = item.filter.type;
    let field = {
      name: item.name,
      displayName: item.displayName,
      widget: widgets[type],
    };

    if (field.hasOwnProperty("widget") === false) {
      field.widget = StringWidget;
    }
    return field;
  };
  getFilter = (item) => {
    let field = this.getField(item);
    const { defaultOperator, defaultValue } = field.widget;
    var condition = { operator: defaultOperator, value: defaultValue };
    if (item.filter.condition) {
      condition = item.filter.condition;
    }
    return Map({ field, operator: condition.operator, value: condition.value });
  };
  onConditionChange = (condition) => {
    const xavailableFields = this.state.availableFields;
    //let xindex;
    for (var i = 0; i < xavailableFields.length; i++) {
      if (xavailableFields[i].name === condition.name) {
        xavailableFields[i].filter.condition = condition;
      }
    }

    this.setState({
      availableFields: xavailableFields,
      selectedFilter: { filter: {} },
    });
  };

  onRef = (ref) => {
    //console.log("QWERTYU", ref);
    REFS.F1 = ref;
  };
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(CustomFilter);
//export default CustomFilter;
import React, { useState, useEffect } from 'react';
import './toast.css';
import { Toast } from 'react-bootstrap';
let viewed = false;

const handleOkClick = (props,setShow) => {
    setShow(false)

    if (props.helpers && props.helpers.sso_error === true) {
          window.location.href = "/";
    }
    if (props.helpers && props.helpers.sso_admin_verify === true) {
        window.location.href = "/home/products";
  }
};

function ToastMessage(props) {
    const [show, setShow] = useState(false);
    useEffect(() => {
        //console.log(props, show, viewed)
        if (props.show && !show) {
            viewed = true;
            //console.log(props.show, show)
            setShow(true);
        }
    }, [props.helpers.changeInToast]);
    if (props.show) {
        return (
            <>
            {show && props.showGreybg ? <div className='bg-grey'></div> : null }
            <Toast className={props.position && props.position == 'center' ? "ssi_toast center" : "ssi_toast"}  show={show}  >
                {/* <Toast.Header>

                    <strong className="mr-auto">{props.helpers ? props.helpers.status : "Message not passed"}</strong>

                </Toast.Header> */}
                <Toast.Body> {props.helpers ? props.helpers.message : 'Message not passed'}
                    <div className="toast_btn">
                        <button className="button-style primary-btn" onClick={() => { handleOkClick(props, setShow) }} >Ok</button>
                    </div>

                </Toast.Body>

            </Toast>
            </>
        );
    } else {

    } return (null);

}

export default ToastMessage;

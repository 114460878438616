import { Modal } from "react-bootstrap";

const SurveyAlert = (props) => {

    const handleCloseSurveyAlert = () => {
        props.onHide(false);
    }

    console.log(props.label.LBLALLRIGHTSRESERVED,"props.label")
    return (
        <Modal
            show={true}
            onHide={handleCloseSurveyAlert}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton = {props.skip === "yes"} className='alert-modal-header'>
                <Modal.Title className="alert-modal-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <span>{props.description}</span>
                {/* <span className="alert-modal-body">{props.label.LBLSRVYSECTION1HEADER !== undefined ? props.label.LBLSRVYSECTION1HEADER : "Part I - Site-Specific Information"}</span> */}
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "none",justifyContent:props.skip !== "yes" ? "center" : "flex-end" }}>
                {props.skip === "yes" ?
                    <button className="button-style secondary-btn" onClick={handleCloseSurveyAlert}>
                        {props.label?.LBLSKIPNOW !== undefined ? props.label?.LBLSKIPNOW : "Skip Now"}
                    </button>
                    :
                    null
                }
                <button onClick={props.handleSurvey} className="button-style primary-btn">
                    {props.label?.LBLNOTSTARTED !== undefined ? props.label?.LBLNOTSTARTED : "Start Now"}
                </button>
            </Modal.Footer>
        </Modal>
    )
};

export default SurveyAlert;
import React, { Component } from 'react';
import "./remote-launch.scss"
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import RemoteLaunchForm from './RemoteLaunchForm';
import ScCommonServices from '../services/common_services';
import { Modal } from 'react-bootstrap';
import CompanyLogo from '../admin/components/companylogo/companylogo';
import ToastMessage from '../shared/ToastComponent/ToastComponent';

const cstLabels = {
    "LBLUNAUTHREQUEST": "Unauthorized request",
    "LBLPOSTREMOTELAUNCH": "Post-Training SafeStart Survey Launch",
    "LBLPREREMOTELAUNCH": "Pre-Training SafeStart Survey Launch",
    "LBLINVALIDREQUEST": "Invalid Request",
    "LBLINVALIDURL": "Invalid URL",
    "LBLUSERNOTFOUND": "Invalid Email / Username",
    "LBLINVALIDACTIVITY": "This activity is not available for you or removed by admin.",
    "LBLSTATUSUNLOCKUNIT": "Please complete the unit ($$$).",
    "LBLACTCOMPLETEDALERTMSG": "This activity has been already completed or override by admin.",
    "LBLDATEUNLOCKFROMCAPSULE": "This will be available from $$$.",
    "LBLSTATUSUNLOCKPRODUCT": "Please complete the course ($$$).",
    "LBLREMOTELAUNCH": "SafeStart Survey Launch",
    "LBLENTERREGISTEMAILID": "Please enter your username / registered email id",
    "LBLCOURSENOTCOMPLETED":"Please complete the course to take the post-survey"
}
class RemoteLaunch extends Component {

    constructor(props) {
        super(props);
        let url = this.props.location.search;
        let params = this.parseQuery(url);
        this.state = {
            loading: true,
            email: "",
            validmsg: "",
            vSuccessStatus: true,
            showModal: false,
            showError: false,
            btnStatus: false,
            closeFunc: true,
            isFg: params.fg,
            link_state: false,
            showToast: {
                show: false,
                alertClass: "",
                status: "",
                message: "",
                changeInToast: new Date(),
            },
            wait_here: false,
            user_attribute: props.location.state ? props.location.state.user_attribute : null,
            invite_code: props.location.state ? props.location.state.code : null,
        }
    }
    parseQuery = (queryString) => {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

    componentDidMount() {
        localStorage.setItem('sc_user', 'true');   
        if (window.gapi) {
            window.gapi.load("auth2", () => {
                window.gapi.auth2.getAuthInstance({
                    client_id: process.env.REACT_APP_SSO_GOOGLE_SIGNON_ID,
                });
            });
        }
        this.checkLink();
    }

    checkLink = () => {
        let data = {
            encref: window.location.href.split("#")[1]
        }
        this.setState({
            encref: window.location.href.split("#")[1]
        })
        ScCommonServices.validateSurveyLink(data).then(response => { 
            this.setState({
                loading: false
            })
        }).catch(err => {
            if (!err.response.data.status) {
                this.setState({
                    vSuccessStatus: false,
                    validmsg: this.getLabel(err.response.data.message),
                    loading: false,
                    showError: true,
                })
            }
        })
    }
    getLabel = (xfield) => {
        if (typeof xfield === "string") {
            if (this.props.labels.labels.hasOwnProperty(xfield)) {
                return this.props.labels.labels[xfield];
            } else {
                if (cstLabels.hasOwnProperty(xfield)) {
                    return cstLabels[xfield]
                } else {
                    return xfield;
                }
            }
        } else if(xfield) {
            if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
                return this.props.labels.labels[xfield.label].replace(
                    "$$$",
                    xfield.val
                );
            } else {
                if (cstLabels.hasOwnProperty(xfield.label)) {
                    return cstLabels[xfield.label].replace(
                        "$$$",
                        xfield.val
                    );
                } else {
                    return xfield.label;
                }
            }
        }
    };
    getFailMessage = (res) => {
        let faillable = "";
        try {
            if (res.triggerFail) {
                faillable = res.triggerFail.conditionData.lable;
                if (faillable.startsWith("LBLSTATUSUNLOCK")) {
                    return this.getLabel({ label: faillable, val: res.triggerFail.conditionData.name })
                } else if (faillable.startsWith("LBLDATEUNLOCKFROM")) {
                    return this.getLabel({ label: faillable, val: res.triggerFail.conditionData.unlockdate })
                }
            }
            return faillable;
        } catch (e) {
            return faillable;
        }
    };
    remoteLaunch = (values) => {
        if (this.state.btnStatus === true) {
            return false
        }
        this.setState({ btnStatus: true })
        if (this.state.closeFunc === true) {
            let data = {
                "email": values.email,
                "encref": this.state.encref
            }
            ScCommonServices.validateSurveyUser(data).then(response => {                
                if (response.status) {  
                    localStorage.setItem("sc_user", false);                    
                    let resData = response.data;
                    let xurl = window.location.href.split("safestart-survey")[0] +
                        "safestart-survey-rl#" +
                        window.location.href.split("#")[1] 
                    const { location } = this.props;
                    this.props.history.push({
                        pathname: "/safestart-survey-rl",
                        state: {
                            passvars: xurl,
                            language_code: resData.language_code,
                            user_id: resData.user_id,
                            company_id: resData.company_id,
                            survey_type: resData.survey_type,
                            survey_id: resData.survey_id,
                            company_logo: location.state ? location.state.company_logo ? location.state.company_logo : "" : this.props.company_logo ? this.props.company_logo : ""
                        },
                    });                            
                } else if (!response.status) {
                    this.setState({
                        validmsg: this.getLabel(response.message),
                        vSuccessStatus: false,
                        showModal: true,
                        btnStatus: false, loading: false, wait_here: true
                    })                    
                }
            }).catch(err => {
                if (err) {
                    this.setState({
                        validmsg: this.getLabel(err.response.data.message),
                        btnStatus: false,
                        showModal: true,
                        closeFunc: true, loading: false, wait_here: true
                    })                    
                }
            })
        }
    }

    set_password = async (values) => {
        this.setState({
            vSuccessStatus: true,
            validmsg: ''
        })
        let password = await ScCommonServices.createHash(values.password);
        let data = null;
        if (this.state.invite_code && this.state.user_attribute) {
            data = {
                otp: true,
                user_attribute: this.state.user_attribute,
                u_pwd: password,
            }
        } else {
            data = {
                fg_code: this.state.token,
                u_pwd: password,
            }
        }

        ScCommonServices.setUserPassword(data).then(res => {
            if (res.status) {
                this.setState({
                    showToast: {
                        show: true,
                        alertClass: "success",
                        status: "Success",
                        message: this.state.isFg ? this.props.labels.labels.LBLUPDTSCCESS : this.state.invite_code ? this.props.labels.labels.LBLSETPASSWDSUCCESS : "",
                        changeInToast: new Date(), loading: false, wait_here: false
                    },
                });
                setTimeout(() => {
                    this.setState({
                        showToast: {
                            show: false,
                            alertClass: "",
                            status: "",
                            message: "",
                            changeInToast: new Date(), loading: false
                        },
                    });
                    this.props.history.push('/');
                }, 1500);
            }
        }).catch(err => {
            if (err.response.data && !err.response.data.status) {
                this.setState({
                    vSuccessStatus: false,
                    validmsg: this.props.labels.labels[err.response.data.message], loading: false
                })
            }
        })
    }
    closeModal = () => {
        this.setState({ showModal: false });
        if (this.state.vSuccessStatus && !this.state.wait_here) {
            this.props.history.push('/');
        }
    }
    render() {
        const { labels, location } = this.props;
        let launch_header = this.getLabel("LBLREMOTELAUNCH");
        console.log("labels", labels)
        if (this.state.vSuccessStatus === false && this.state.showError === true && this.state.loading === false) {

            return (
                <div className="fp-section">
                    <ToastMessage
                        show={this.state.showToast["show"]}
                        helpers={this.state.showToast}
                    ></ToastMessage>
                    <div className="card">
                        <div className="card-body">
                            <div className="container text-center">
                                <p className="text-center" style={{ color: "red" }}>{this.state.validmsg}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (!this.state.loading) {
            return (
                <div className="fp-section">

                    <div className="card">
                        <div className="card-body">
                            <div className="login-card-tt">
                                {location.state ? location.state.company_logo ? <CompanyLogo className="applogo" imageName={location.state.company_logo} imgWidth="317px" /> : "" : this.props.company_logo ? <CompanyLogo className="applogo" imageName={this.props.company_logo} imgWidth="317px" /> : ""}
                            </div>
                            <h3>{launch_header}</h3>
                            <RemoteLaunchForm labels={labels} onSubmit={this.remoteLaunch} btnStatus={this.state.btnStatus}></RemoteLaunchForm>

                            <Modal show={this.state.showModal} onHide={null}
                            >
                                <Modal.Body>
                                    <div className="form-group p-2">
                                        <p className="text-center">{this.state.validmsg}</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <button type="button" className="btn btn-primary" onClick={this.closeModal}>{labels.labels.LBLOK}</button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="fp-section">

                    <div className="card">
                        <div className="card-body">
                            <div className="container text-center">
                                {location.state ? location.state.company_logo ? <CompanyLogo className="applogo" imageName={location.state.company_logo} imgWidth="317px" /> : "" : this.props.company_logo ? <CompanyLogo className="applogo" imageName={this.props.company_logo} imgWidth="317px" /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

RemoteLaunch = reduxForm({
    form: 'remotelaunchform'
    // a unique name for this form
})(RemoteLaunch);

export default connect()(RemoteLaunch)
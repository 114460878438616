import React from "react";
import InputRange from "react-input-range";
import './BuildRange.scss';

const BuildRange = (props) => {
    
    const handleRange = (e) => {
        props.handleUpdatedJson(e, true);
    }
    
    console.log(props.text, "props.text");
    return (
        <div className="range" style={{ padding: "20% 10% 10% 5%" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <InputRange
                    maxValue={5}
                    minValue={0}
                    value={ props.text === "" ? 0 : parseInt(props.text)}
                    onChange={(e) =>
                        handleRange(e)
                    }
                />
            </div>
        </div>
    );
};

export default BuildRange;

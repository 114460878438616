import React from "react";
import "./group-list.scss";
import { Modal } from "react-bootstrap";
import GroupForm from "./GroupForm";
import GroupServices from "./GroupServices";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import orderBy from "lodash/orderBy";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import Skeleton from "react-loading-skeleton";
import UserManagementHeader from "../../../common_components/datatable/UserManagementHeader";
import emptyImage from '../../../assets/images/grouplist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import { DataGrid} from '@mui/x-data-grid';
import  "../../../assets/scss/datatable.scss";
import styles from "./grouplist.module.scss";


const $ = require("jquery");

let changesIn = {};
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default class GroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupData: [],
      groupParsedData:[],
      // initialParsedData:[],
      groupTypeNames: [],
      selected_group_type: "GP_1",
      group_type: {},
      grouped_users: [],
      non_grouped_users: [],
      group_initial_values: {},
      add_users: [],
      add_nongrpusers: [],
      add_groupusers: [],
      initialValues: null,
      loading: true,
      groupId: null,
      hideLabel: true,
      hideNLabel: true,
      status: 0,
      items: 20,
      checkedItems: new Map(),
      itemsShowAll: false,
      groupInfo: { group_id: null, group_name: null, group_type: null },
      searchValue: "",
      roles: [],
      groups: null,
      ssi_errors: null,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
        selectGroup: [],
      },
      pageState: {
        isLoading: false,
        data: [], // rows
        total: 0 // total count of data grid we need to show
      },
      paginationModel: {
        page: 0,
        pageSize: 10,
      },
      rowSelectionModel: [],
      visibleRowsSelected: false,
      filteredValue:[],
      sortModel: [],
    };
  }
  
  openDeleteModal1 = () => {
    this.setState({
      showDel: true,
      delGrpId:this.state.rowSelectionModel
    });
  };

  getGroupTypes = async () => {
    GroupServices.getGroupTypeLabels().then((response) => {
      this.setState(
        {
          groupTypeNames: response,
          group_type: response[0]["lbl_" + this.state.selected_group_type],
        },
        this.getGroups(this.state.selected_group_type)
      );
    });
  };

  componentDidMount() {
    this.getGroups();
    this.getNonGroupUsers();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.pageState.data !== this.state.pageState.data ||
      prevState.rowSelectionModel !== this.state.rowSelectionModel
    ) {
      this.handleVisibleRowsSelection();
   }
  }
  handleVisibleRowsSelection = () => {
    let totalavailableRows =  this.state.pageState.data.length;
    if (this.state.pageState.data.length > 0) {
      let selectedRowCount = 0;
      this.state.pageState.data.forEach(val => {
        if (this.state.rowSelectionModel.includes(val.group_id)) {
          selectedRowCount++;
        }
      });

      let totalSelectedRows = selectedRowCount ;
      if(totalavailableRows === 0) {
        this.setState({visibleRowsSelected:false})
      } else if (totalavailableRows === totalSelectedRows  && this.state.rowSelectionModel.length !== this.state.groupParsedData.length ) {
        this.setState({visibleRowsSelected:true})
      } else {
        this.setState({visibleRowsSelected:false})
      }
    }
  }
  groupJsonParse = () => {
    const { paginationModel } = this.state;
    this.setState({
      groupParsedData: this.state.groupData["groups_by_type"]
        ? JSON.parse(this.state.groupData.groups_by_type) : [],
        // initialParsedData: this.state.groupData["groups_by_type"]
        // ? JSON.parse(this.state.groupData.groups_by_type) : []
    }, () => {
      this.setState({
        pageState: { isLoading: false, data: this.state.groupParsedData.slice((paginationModel.page * paginationModel.pageSize), ((paginationModel.page + 1) * paginationModel.pageSize)), total: this.state.groupParsedData.length },
        filteredValue: this.state.groupParsedData,
      }, () => {
      });
    })
    console.log(this.state.groupParsedData,"groupParsedData")
  }
  getGroups = (selected_group_type) => {
    let getData = {
      language: this.props.language,
    };
    GroupServices.getGroups(getData)
      .then((response) => {
        this.setState((state, props) => ({
          groupData: response.find((item) => {
            if (item.group_type_id) {
              return item.group_type_id === this.state.selected_group_type;
            }
            return false;
          }),
          groups: response,
          loading: false,
          group_type: response[0].group_info.group_type_id ? response.find((item) => {
            if (item.group_type_id) {
              if (item.group_type_id === this.state.selected_group_type) {
                return item;
              }
            }
            return false;
          }).group_info : JSON.parse(response.find((item) => {
            if (item.group_type_id) {
              if (item.group_type_id === this.state.selected_group_type) {
                return item;
              }
            }
            return false;
          }).group_info)
        }),
          () => {
            $('.MuiAutocomplete-input').attr('readOnly', 'true');            
          });
        this.groupJsonParse();
      })
      .catch((err) => {
        if (err) console.log(err);
      });
  };
  set_add_users = (grpusers, nogrpusers, add_users) => {
    this.setState(
      {
        add_nongrpusers: grpusers,
        add_groupusers: nogrpusers,
        add_users: add_users,
      }
      // ,
      // () =>
      //   console.log([
      //     this.state.add_nongrpusers,
      //     this.state.add_groupusers,
      //     this.state.add_users,
      //   ])
    );
  };
  submitGroup = (values) => {
    this.setState({
      submitting: true,
      searchValue:""
    })
    if (this.state.initialValues === null) {
      let add_users = this.state.add_users;
      if(typeof add_users[0] == 'object')
        add_users = add_users[0]
      let data = {
        group_name: values.group_name.trim(),
        group_type_id: this.state.selected_group_type,
        description: values.description ? values.description.trim() : null,
        status: values.status === undefined ? true : values.status,
        users: add_users,
        eType: 1,
      };
      GroupServices.addGroup(data)
        .then((response) => {
          this.setState({ show: false, initialValues: null });
          this.getGroups();
          if (this.state.add_users.length > 0) {
            this.getNonGroupUsers();
          }
          $(".modal-backdrop").css({ position: "relative" });

          this.setState({
            add_users: [],
            add_nongrpusers: [],
            add_groupusers: [],
            show: false,
            ssi_errors: null,
            submitting: false,
            showToast: {
              show: true,
              alertClass: "success",
              status: "Success",
              message: this.props.labels.labels.LBLGRPCREATED,
              changeInToast: new Date(),


            },
          });
          changesIn = {};
          setTimeout(() => {
            this.setState({
              showToast: {
                show: false,
                alertClass: "",
                status: "",
                message: "",
                changeInToast: new Date(),
              },
            });
          }, 1500);
        })
        .catch((err) => {
          if (err) {
            if (err && err.response.data) {
              this.setState({
                ssi_errors: err.response.data,
                submitting: false,
              });
            }
          }
        });
    } else {
      //Edit group
      let data = {
        group_name: values.group_name,
        group_type_id: this.state.selected_group_type,
        description: values.description ? values.description.trim() : null,
        status: values.status,
        groupId: values.groupId,
        users: this.state.add_users,
        grouped_users: this.state.add_groupusers,
        non_grouped_users: this.state.add_nongrpusers,
        eType: 2,
        changes_in: changesIn,
      };

      GroupServices.updateGroup(data)
        .then((response) => {
          this.setState({ show: false, initialValues: null });
          this.getGroups();
          if (this.state.add_users.length > 0) {
            this.getNonGroupUsers();
          }
          $(".modal-backdrop").css({ position: "relative" });

          this.setState({
            add_users: [],
            add_nongrpusers: [],
            add_groupusers: [],
            ssi_errors: null,
            submitting: false,
            showToast: {
              show: true,
              alertClass: "success",
              status: "Success",
              message: this.props.labels.labels.LBLGRPUPDATED,
              changeInToast: new Date(),

            },
          });
          changesIn = {};
          setTimeout(() => {
            this.setState({
              showToast: {
                show: false,
                alertClass: "",
                status: "",
                message: "",
                changeInToast: new Date(),
              },
            });
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          if (err) {
            if (err && err.response.data) {
              this.setState({
                ssi_errors: err.response.data,
                submitting: false
              });
            }
          }
        });
    }
  };

  closeModal = () => {
    $(".modal-backdrop").css({ position: "relative" });

    this.setState({
      show: false,
      showDel: false,
      initialValues: null,
      showUsers: false,
      add_users: [],
      add_nongrpusers: [],
      add_groupusers: [],
      ssi_errors: null,
    });
  };
  getNonGroupUsers = () => {
    console.log("asbujkas")
    let data = {
      group_type: this.state.selected_group_type,
    };
    GroupServices.getUsers(data).then((res) => {
      this.setState({
        non_grouped_users: res[0],
        grouped_users: res[1],
        roles: res[2],
      },
        () => { $('.MuiAutocomplete-input').attr('readOnly', 'true'); });
    });
  };

  ChangesIn = (field) => {
    changesIn = { [field]: 1 };
  };
  openModal = (item) => {
    this.setState({ groupInfo: null, show: true });
  };
  openEditModal = (e, item) => {
    e.stopPropagation();
    this.setInitialValues(item);
    this.setState({ show: true });
  };
  openDeleteModal = (e, item) => {
    console.log(item,"itemitem")
    e.stopPropagation();
    this.setState({
      showDel: true,
      delGrpId: [item.group_id],
      groupName: item.group_name,
      // rowSelectionModel: [item.group_id]
    });
  };
  deletegroup = (e) => {
    this.setState({
      toggleClearRows: !this.state.toggleClearRows,searchValue:""
    });    
    let editData = {
      groupId: this.state.delGrpId,
      group_type: this.state.selected_group_type,
      eType: 1,
    };

    GroupServices.deleteGroup(editData)
      .then((response) => {
        this.getGroups(this.state.selected_group_type);
        this.setState({
          showDel: false,
          add_users: [],
          rowSelectionModel: [],
          showToast: {
            show: true,
            alertClass: "success",
            status: "Success",
            message: this.state.rowSelectionModel.length > 1 ? this.props.labels.labels.LBLGRPDELETED : this.props.labels.labels.LBLGRPSINDELETED,
            changeInToast: new Date(),
            pageState: {isLoading: false, data: this.state.groupParsedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: this.state.groupParsedData.length }
          },
        });
        setTimeout(() => {
          this.setState({
            showToast: {
              show: false,
              alertClass: "",
              status: "",
              message: "",
              changeInToast: new Date(),
            },
            toggleClearRows: !this.state.toggleClearRows,
          });
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setInitialValues = (item) => {
    this.setState({
      initialValues: {
        group_name: item.group_name,
        description: item.description,
        status: item.active,
        groupId: item.group_id,
      },
    });
  };
  handleSortModelChange = (sortModel) => {
    console.log("sortModelstart",sortModel)
    // if (sortModel?.length > 0) {
      this.setState({sortModel:sortModel});
      const field = sortModel[0]?.field ;
      let direction = sortModel[0]?.sort ;
      let sortedData = [...this.state.filteredValue];
       if(direction === undefined) {
        sortedData = [...this.state.filteredValue];
       }
       console.log("sortedData",sortedData)
       
       sortedData.sort((a, b) => {
        const valueA = a[field] === null ? "" : a[field]; // Convert null to empty string
        const valueB = b[field] === null ? "" : b[field]; // Convert null to empty string
        if (direction === 'asc') {
            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB);
            } else {
                return valueA - valueB;
            }
        } else {
            if (typeof valueB === 'string') {
                return valueB.localeCompare(valueA);
            } else {
                return valueB - valueA;
            }
        }
    });
    
      console.log("sortedData",sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)))
      this.setState({ pageState : { isLoading: false, data: sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)), total: sortedData?.length },groupParsedData:sortedData });
    // }
    console.log("sortModel end",sortModel[0]?.sort)
  };

  searchUser = (e) => {
    this.setState({
      searchValue: e.target.value,
      paginationModel: {
        page: 0,
        pageSize: 10,
      },
    }, ()=> {
      this.filterGrps();
      // this.handleSortModelChange(this.state.sortModel);
    });

  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (
        row[field] &&
        row[field] !== 1 &&
        row[field] !== 0 &&
        typeof row[field] !== "boolean"
      ) {
        return row[field].toLowerCase();
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  handleClear = () => {
    this.setState({
      //toggleClearRows: !this.state.toggleClearRows,
      // groupId: [],
      // selectAllEnabled:false
      rowSelectionModel: []
    });
  }
  handleSelectAll = () => {   
    const groupId = this.state.filteredValue.map((item) => item.group_id);
    this.setState({rowSelectionModel: groupId})
  }
  tableHeader = () => {
    if (this.state.groups && this.state.groups.length > 0) {
      return (
        <>
          <div className={styles.grpTableFilter}>
            <div className={styles.filterAll}>
              {this.state.groups.length !== 0 ? (
                <div className={styles.grpFilterWthLabel}>
                  <label className="label">{this.props.labels.labels.LBLGROUPTYPES}</label>
                  <Autocomplete
                    className={styles.filterDrpdwn}
                    aria-required="true"
                    required
                    disableClearable
                    options={this.state.groups}
                    getOptionLabel={(option) => this.state.group_type["lbl_" + this.state.selected_group_type]}
                    getOptionSelected={(option, values) =>                      
                      option.group_type_id === values
                    }
                    value={this.state.selected_group_type}
                    onChange={(e, product) => {
                      this.setState(
                        {
                          //selectedRows: [],
                          //selectedRowsCount: 0,
                          selected_group_type: product.group_info.group_type_id,
                          group_type: product.group_info,
                          rowSelectionModel:[],
                          groupData: this.state.groups.find((item) => {
                            if (item.group_type_id) {
                              return item.group_type_id === product.group_info.group_type_id;
                            }
                            return false;
                          }),
                        },
                        ()=> {
                          this.filterGrps();
                          this.getNonGroupUsers();
                        }
                      );
                    }}
                    renderOption={(option) => (
                      <div style={{ fontSize: "14px" }}>{this.setOptionlabel(option)}</div>
                    )}
                    underlineStyle={{ display: "none" }}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                        />
                      </div>
                    )}
                  />
                </div>
              ) : (
                ""
              )}
              <input
                type="text"
                className="input-field"
                placeholder={
                  this.state.group_type["lbl_" + this.state.selected_group_type]
                    ? this.state.group_type["lbl_" + this.state.selected_group_type]
                    : this.state.group_type
                      ? this.state.group_type
                      : this.props.labels.labels[
                      "LBLGROUP" + this.state.selected_group_type.split("_")[1]
                      ]
                }
                value={this.state.searchValue}
                onChange={this.searchUser}
                onKeyDown={this.searchUser}
                style={{height:"40px",width:"180px"}}
              />
            </div>
          </div>

          {this.state.rowSelectionModel.length > 0 ?
            <UserManagementHeader
              currentPageSelected = {this.state.rowSelectionModel.length} 
              selectedRowsCount = {this.state.rowSelectionModel.length}
              labels = {this.props.labels.labels}
              currentPageLabel = {this.props.labels.labels.LBLGRPSELECTED !== undefined ? this.props.labels.labels.LBLGRPSELECTED :"Groups selected"}
              handleClear = {this.handleClear}
              selectedRows = {null}
              dropObj = {null}
              selectAll= {this.props.labels.labels.LBLSELECTALLGROUP.replace("$$$","")}
              currentPage = "groups"
              handleDelete = {() => this.openDeleteModal1()}
              deleteDisable = {this.state.disableMultiDelete}
              handleRowChange={this.handleSelectAll}
              visibleRowsSelected = {this.state.visibleRowsSelected}
              visiblerowsselectedLabel = {this.props.labels.labels.LBLALLGRPSELECTED !== undefined ? this.props.labels.labels.LBLALLGRPSELECTED : "All groups on this page are selected."}
            /> 
           :
            null
          }
        </>

      );

    } else {
      return null;
    }


  };

  setOptionlabel = (option) => {
    return option.translated === false ?
      this.props.labels.labels[option.group_info["lbl_" + this.state.selected_group_type]]
      : option.group_info["lbl_" + option.group_type_id];
  }

  filterGrps = () => {
    const { groupData,paginationModel } = this.state;
    const filterGroup = groupData["groups_by_type"]
      ? JSON.parse(groupData.groups_by_type).filter((item) => {
        return (
          item.group_name &&
          (item.group_name.includes(this.state.searchValue) ||
            item.group_name
              .toLowerCase()
              .includes(this.state.searchValue.toLowerCase()))
        );
      })
      : []; 
      this.setState({ 
        pageState: {isLoading: false, data: filterGroup.slice((paginationModel.page * paginationModel.pageSize), ((paginationModel.page + 1) * paginationModel.pageSize)), total: filterGroup.length },
        filteredValue:filterGroup
    });
      return filterGroup;
  }

  handlePageChange = (pageValue) => {
    this.setState({paginationModel: pageValue}, ()=> {
      this.filterGrps();
      this.handleSortModelChange(this.state.sortModel);
    });
  }

  render() {
    console.log("this.state",this.state.filteredValue)
    const {pageState, paginationModel, rowSelectionModel} = this.state;
    //let tableHeader = this.tableHeader();

    const {
      LBLADDNEWGROUP,
      LBLSTATUS,
      LBLACTION,
      LBLEDIT,
      LBLDELETE,
      LBLACTIVE,
      LBLINACTIVE,
      LBLCANCEL,
      LBLROWSPERPAGE,
      LBLGROUPSEXPTYTXT1,
    } = this.props.labels.labels;
    
    const columns = [
      {
        field: 'group_name',
        headerName: this.state.group_type["lbl_" + this.state.selected_group_type]
          ? this.state.group_type["lbl_" + this.state.selected_group_type]
          : this.state.group_type
            ? this.state.group_type
            : this.props.labels.labels[
            "LBLGROUP" + this.state.selected_group_type.split("_")[1]
            ],
        sortable: true,
        // width: $('.main-pad').width()  / 2
        width: 200
      },
      {
        field: 'active', 
        headerName: LBLSTATUS, 
        sortable: true, 
        // width: $('.main-pad').width() / 4,
        width:300,
          renderCell: (row) => {
            return (
              <div className={row.row.active === 1 ? styles.LBLACTIVE  : styles.LBLINACTIVE}>
                {row.row.active === 1 ? LBLACTIVE : LBLINACTIVE}
              </div>
              )            
          }
        },
      {
        field: 'actions', headerName: LBLACTION, sortable: false, 
        // width: $('.main-pad').width() / 4,
        width:400,
        renderCell: (row) => {
          return (
            <div className={`icoBtn ${this.state.rowSelectionModel.length ? 'disable' : ''}`}>
          <i
            className={""}
            // this.state.rowSelectionModel.length > 0 ? "group-delete-disable" :
            onClick={(e)=>this.openEditModal(e, row.row) }
            alt={LBLEDIT}
          >
            {LBLEDIT}
          </i>
          <i
            className={ ""}
            // this.state.rowSelectionModel.length > 0 ? "group-delete-disable" :
            onClick={(e)=>this.openDeleteModal(e, row.row)}
            alt={LBLDELETE}
          >
            {LBLDELETE}
          </i>
        </div>)
        }
      }
    ];

    return (
      <div className="mob-pad group-list-page">
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage>

        <div className="page-header-field">
          <h3 className="page-title" style={{marginBottom: "0px"}}>{this.props.labels.labels.LBLMANAGEGROUPS}</h3>
          <div className="btn-align">
            <button
              className="button-style primary-btn"
              data-toggle="modal"
              onClick={this.openModal}
              disabled={this.state.loading}
              // style={{height:"40px"}}
            >
              {LBLADDNEWGROUP}
            </button>
          </div>
        </div>
        <hr className="head-line" />
        {this.state.loading ? (
          <div>
            <div className="sc-fznxsB fWHyCt datatable-list templates-table web-skull">
              <header className="sc-fzqNJr kwRiGp rdt_TableHeader"></header>
              <header className="sc-fzoXzr hlidmt">
                <Skeleton height={34} />
              </header>

              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                    <div className="sc-AxiKw cTEQON rdt_TableHeadRow">
                      <Skeleton height={56} />
                    </div>
                  </div>
                </div>
                <div className={this.state.rowSelectionModel > 0 ? "selectedCourses" : "selectedCoursesNone"}>

                </div>
                <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                  <Skeleton height={480} />
                </div>

              </div>
              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <Skeleton height={56} />
              </nav>
            </div>
            <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
              <header className="sc-fzoXzr hlidmt">

                <Skeleton height={34} />
              </header>
              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                    {skel.map((i) => {
                      return (
                        <div
                          id={"row-" + i}
                          className="sc-fzoLsD doBktq rdt_TableRow"
                        >
                          <Skeleton height={90} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <div className="sc-fzpmMD ftIMtk">
                  <Skeleton height={40} />
                </div>
              </nav>
            </div>
          </div>

        ) : (
            <div>
              {/* style={{width: '100%'}} */}
              {this.tableHeader()}
              {this.state.pageState.data.length > 0 ?
                <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'group_name', sort: 'asc' }],
                  },
                }}
                onSortModelChange={this.handleSortModelChange}
                // initialState={{
                //   sorting: {
                //     sortModel: [{ field: 'group_name', sort: 'asc' }],
                //   },
                // }}
                  style={{ border: 'none' }}
                  className="cust-data-grid"
                  autoHeight
                  checkboxSelection
                  disableRowSelectionOnClick
                  getRowId={(row) => row.group_id} // key value of the row item
                  columns={columns}
                  rows={pageState.data}
                  rowCount={pageState.total}
                  loading={pageState.isLoading}
                  pageSizeOptions={[10, 15, 20, 25, 30]}
                  pagination
                  paginationMode="server"
                  paginationModel={paginationModel}
                  onPaginationModelChange={(pageValue) => this.handlePageChange(pageValue)}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    this.setState({ rowSelectionModel: newRowSelectionModel })
                  }}
                  rowSelectionModel={rowSelectionModel}
                  keepNonExistentRowsSelected
                  hideFooterSelectedRowCount
                  disableColumnMenu
                  slotProps={{
                    pagination: {
                      labelRowsPerPage: LBLROWSPERPAGE + ":"
                    }
                  }}
                  sx={{
                    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },          
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    }
                 }}
                />
                :
                <div style={{ marginTop: "5%" }}><EmptyState image={emptyImage} text2={LBLGROUPSEXPTYTXT1} /></div>
              }
            </div>
        )}
        {!this.state.loading && this.state.show ?
          <Modal
            show={this.state.show}
            backdrop="static"
            onHide={this.closeModal}
            size="lg"
            className={
              "animated  modal-right-side " +
              (this.state.show ? "fadeInRight" : "fadeOutRight")
            }
          >
            <GroupForm
              ChangesIn={this.ChangesIn}
              ssi_errors={this.state.ssi_errors}
              group_type={
                this.state.group_type["lbl_" + this.state.selected_group_type]
                  ? this.props.labels.labels[this.state.group_type["lbl_" + this.state.selected_group_type]] ? this.props.labels.labels[this.state.group_type["lbl_" + this.state.selected_group_type]] : this.state.group_type["lbl_" + this.state.selected_group_type]
                  : this.state.group_type
                    ? this.state.group_type
                    : this.props.labels.labels[
                    "LBLGROUP" + this.state.selected_group_type.split("_")[1]
                    ]
              }
              non_grouped_users={this.state.non_grouped_users}
              group_users={this.state.grouped_users}
              initialValues={this.state.initialValues}
              onSubmit={this.submitGroup}
              closeModal={this.closeModal}
              getRef={this.getRef}
              groupInfo={this.state.groupInfo}
              roles={this.state.roles}
              add_users={this.set_add_users}
              labels={this.props.labels}
              processing={this.state.submitting}
            ></GroupForm>
          </Modal>
          : null}
        {!this.state.loading ? 
        <Modal

          show={this.state.showDel}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton  className="alert-modal-header">
           {/* style={{display:"flex",alignItems:"center"}} */}
            <Modal.Title className="alert-modal-title">
              <h4 className="alert-modal-title">{this.state.rowSelectionModel.length > 1 ?  this.props.labels.labels.LBLGROUP  + " - " + this.props.labels.labels.LBLCONBULKACTION : this.props.labels.labels.LBLMANAGEGROUPS}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="general-text">
              {this.state.rowSelectionModel.length > 1 ? this.props.labels.labels.LBLDELGROUPALERTS : this.props.labels.results.LBLDELGROUPALERT}
            </div>
          </Modal.Body>
          <Modal.Footer style={{borderTop:"none"}}>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    onClick={this.closeModal}
                  >
                    {LBLCANCEL}
                  </button>

                  <button
                    type="button"
                    className="button-style danger-btn "
                    value={this.state.delGrpId}
                    onClick={this.deletegroup}
                  >

                    {LBLDELETE}
                  </button>
          </Modal.Footer>
        </Modal> : null}

      </div>
    );
  }
}
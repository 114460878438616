import React from "react";
import styles from "./surveyToastNav.module.scss"
import infoIcon from "../../src/assets/images/infoicon.png"
const SurveyToastNav = (props) => {
    
    console.log("sxCYTVGhjbnsm")
    return(
        <div className={styles.surveyToastCtn}>
        <div className={styles.surveyToastInnerCtn}>
        <img src= {infoIcon} alt = ""/>
        <span className={styles.toastText}>
          {props.labels?.LBLPLEASECOMPPRESURVEY}
        </span>
        <button className="button-style primary-btn" onClick={props.handleSurvey}>
            {props.labels?.LBLNOTSTARTED}
        </button >
        </div>
        </div>
    )
}

export default SurveyToastNav;
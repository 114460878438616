import { HttpInterceptor } from '../auth/HttpInterceptor';
import axios from "axios";
import SSOService from "../services/sso_services";

class ScAuth {
    login(callback) {
        localStorage.setItem("sc_user", true);
        callback();
    }
    logout(callback) {
       console.log(localStorage.getItem('ssi_auth0_sso'))
       let login_type = "sc_user"
       if(localStorage.getItem('ssi_auth0_sso')){
        login_type = "ssi_auth0_sso"
       }
       else if(localStorage.getItem('ssi_cognito_sso')){
        login_type = "ssi_cognito_sso"
       }
       else if(localStorage.getItem('ssi_classlink_sso')){
        login_type = "ssi_classlink_sso"
       }
        console.log("in logout")
        localStorage.setItem("sc_user", false);
        localStorage.removeItem("sc_user");
        localStorage.removeItem("ssi_google_sso");
        localStorage.clear();


        //HttpInterceptor.post(process.env.REACT_APP_FEEDAPI_URL + 'logout')
        //.then((Feedresponse) => {
        HttpInterceptor.post(process.env.REACT_APP_APIURL + 'logout', {login_type}, {
            headers: { "X-User-Agent": window.navigator.userAgent }
        })
            .then(async(response) => {

                // let origin = window.location.origin;
                localStorage.setItem("sc_user", false);
                localStorage.removeItem("sc_user");
                console.log(response.data.logoutUrl)
                let logoutUrl = response.data.logoutUrl.logoutUrl
                if (logoutUrl){
                    window.location.replace(logoutUrl);
                }
                if(login_type == "ssi_classlink_sso"){
                    window.location.replace(origin);
                }
                    /****************** cognito logout***************************/
                // let origin = window.location.origin;//.replace("http","https");
                //let hosteUIUrl = `https://youfactorss.auth.eu-north-1.amazoncognito.com/logout?client_id=5731srapkgg1i7sittnp49eftie&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&state=STATE&logout_uri=${origin.replace("http","https")}`
                // let hosteUIUrl = `https://youfactorss.auth.eu-north-1.amazoncognito.com/logout?client_id=5731srapkgg1i7sittnp49eftie&logout_uri=${origin}`;
                // HttpInterceptor.get(hosteUIUrl,{
                //     headers: { "Access-Control-Allow-Origin":  process.env.REACT_APP_Domain_URL},
                //     headers: {"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS"},
                //     header:  {"Access-Control-Allow-Headers":"Origin, Content-Type, Accept, Authorization, X-Requested-With"}
                // }).then((res)=>{
                // console.log("e444",res)
                // }).catch((err)=>{
                //     console.log("e55",err)
                // })

                // window.location.assign(hosteUIUrl);
    
                /****************** cognito logout***************************/
                // const classlinkDomain = 'launchpad.classlink.com';        
                // window.location.assign(`https://${classlinkDomain}/logout`);  //classlink logout
               // window.location.replace(origin);  // return to orginal origin
                if(window.gapi) {
                    window.gapi.load('auth2', () => {
                        window.gapi.auth2.getAuthInstance({
                            client_id: process.env.REACT_APP_SSO_GOOGLE_SIGNON_ID
                        }).then(auth => {
                            auth.signOut().then(res => {
                                callback(res);
                            });
                            callback();
                        })
                    });
                }
                const setCok = await HttpInterceptor.get('cookieset');
                callback();

            })
            .catch((error) => {
                console.log(error);
                if (error) throw error
                callback(error);
            });
        /*}).catch((error) => {
            HttpInterceptor.post(process.env.REACT_APP_APIURL + 'logout')
                .then((response) => {
                    localStorage.setItem("sc_user", false);
                    localStorage.removeItem("sc_user");
                    localStorage.clear();
                    window.gapi.load('auth2', () => {
                        window.gapi.auth2.init({
                            client_id: '865321694165-le7t500vvsjc6pgvgg4ldtk4m6g5jmp0.apps.googleusercontent.com'
                        }).then(auth => {
                            console.log(auth.isSignedIn.get());
                            auth.signOut().then(res => {
                                callback();
                            });
                            callback();
                        })
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        });*/

    };

    isLoggedIn() {
        let auth = localStorage.getItem("sc_user");
        return auth;
    }
}

export default new ScAuth();
import React from "react";
import { connect } from "react-redux";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";  
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import Switch from '@material-ui/core/Switch';  
import "date-fns";
import {styled } from '@material-ui/styles';
import "./steercomconfiguration.scss";
import Skeleton from "react-loading-skeleton";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserAssign from "./steercomuserassignment";
import TaskSetup from "./steercomtasksetup";
import IMRoles from "./steercomroles"; 
import EmailSetup from "./steercomemailconfig";


const $ = require("jquery");
const GreenSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor:  '#38c16f',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#38c16f',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      // color:
      //   theme.palette.mode === 'light'
      //     ? theme.palette.green[100]
      //     : theme.palette.green[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
     // opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: '#CDCDCD',
    opacity: 1,
  },
}));
class Configuration extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        email_notification:0,
        changeInToast: new Date(),
      },  
      loading:false,
      showEmailConfig:false, 
      showCurrTabs:"1",
      active:1,
      showTab:1          
    };
  }
  componentWillUnmount() {
    $("#root").css("height", "100%");
    $("#root").css("overflow", "auto");
  }
  componentDidMount() {
    $('#root').scrollTop(0);  
    this.getCompanySettings();
  }
   
  setContent = () => {
    setTimeout(() => {
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("aside").addClass("slideOutLeft");
      $("#sidenav").css({ display: "none" });
    }, 1500);
  };
  getCompanySettings = () => {
    HttpInterceptor.get("settings/?language=" + this.props.language).then(
      (res) => {
        let item = res.data.result[0];
        let group_labels = res.data.result.group_labels;
        this.setState({ 
          email_notification: res.data.result.templates_status 
        });
      }
    );
  }; 
  showAlert = (messageType, message) => {
    this.setState({
      openalert: true,
      messageType: messageType,
      message: message
    });
  };
  handleSwitchChange=(val,teamdata)=>{
    
    let stats,value; 
    value = val.currentTarget.value;
    stats = value == true?false:true;  
   
      this.setState({
        active:stats
      })
  } 
  handleClose = () => {
    this.setState({ openalert: false });
  };
  handleChange=(event)=>{
    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  }   
  handleTabsChange = (target)=>{
    let showEmailConfig = false, val = target.currentTarget.id;
    if(val==="3"){ 
      showEmailConfig=true;
    }
    this.setState({
      showCurrTabs:val,
      showEmailConfig:showEmailConfig
    })
  }
  render() {
    let horizontal = "top",vertical = "center";
    const {LBLSCOMCONFIG,LBLNO,LBLAGREE,LBLUNSAVEDATA, LBLTASKCONFIG,LBLEMAIL,
          LBLUSRASSIGN  } = this.props.labels.labels;
    const {showCurrTabs } = this.state;       
    return (
      LBLUSRASSIGN && LBLUSRASSIGN.length>0 ? 
      <div className="mob-pad steercom-configuration"  style={{marginTop:"-20px"}}>
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage>
        <Snackbar
            style={{ width: "43%", margin: "0% 30%", justifyItems: "center" }}
            anchorOrigin={{ horizontal, vertical }}
            key={"top-center"}
            open={this.state.openalert}
            autoHideDuration={
            this.state.showtime ? this.state.showtime : 2000
            }
            onClose={this.handleCloseAlert}
            bodyStyle={{ backgroundColor: "teal", color: "coral" }}
        >
            <Alert
            variant="filled"
            onClose={this.handleClose}
            severity={this.state.messageType}
            >
             
                <>{this.state.message}  
                </>
          
            </Alert>
        </Snackbar>
           
        <div id="dialog3">
          <Dialog className="cls_dialog"
            open={this.state.opendialog3}
            onClose={this.handleCloseDialog3}
            aria-labelledby="responsive-dialog-title"
            contentStyle={{ width: "100%", minWidth: "500px" }}
          >
            <div style={{ width: "500px", backgroundColor: "#FCEDEA" }}>
              <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ fontSize: "16px" }} className="cls_reset_all">{LBLSCOMCONFIG} </div>
                  <i style={{ margin: "0px 13px", color: "#ff4040", float: "right" }} class="fas fa-exclamation-triangle cls_reset_img"></i>
                </div>
              </DialogTitle> 
              <DialogContent>
                <DialogContentText style={{ color: "white" }}>
                  <div className="cls_confirm_details" style={{ color: "#313236" }}> {LBLUNSAVEDATA} </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button autoFocus id="1" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog3} color="primary">
                  {LBLNO}
                </Button>
                <Button id="2" style={{ border: "1px solid #323234", backgroundColor: "#FFF" }} onClick={this.handleCloseDialog3} color="primary">
                  {LBLAGREE}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </div>
             <div className="col-sm-12">
              <ul className="nav nav-tabs" style={{marginBottom:"10px"}}>
                <li className={ this.state.showCurrTabs === "1" ? "active" : ""}>
                  <div id="1" onClick={this.handleTabsChange} style={{minWidth:"130px",display:"flex",justifyContent:"center"}}>
                  {LBLUSRASSIGN}
                  </div>
                </li>
                <li  className={ this.state.showCurrTabs === "2" ? "active" : ""}>
                  <div id="2" onClick={this.handleTabsChange} style={{minWidth:"130px",display:"flex",justifyContent:"center"}}>
                  {LBLTASKCONFIG}
                  </div>
                </li>
                <li className={ this.state.showCurrTabs === "4" ? "active" : ""}>
                  <div id="4" onClick={this.handleTabsChange} style={{minWidth:"130px",display:"flex",justifyContent:"center"}}>
                  {LBLEMAIL}
                  </div>
                </li>
              </ul>
             </div>

           {showCurrTabs=== "1"? 
            <div>
            <UserAssign
              initialValues={this.state.initialValues}
              steercomSettings={this.state.steercomSettings}
              editTeamDetails={this.editTeamDetails}
              editTeamMembersList={this.editTeamMembersList}
              labels={this.props.labels}
              lang_code={this.props.language_code}
              setTeamDetails={this.setTeamDetails}
              {...this.props}
            ></UserAssign>
          </div>
           : (showCurrTabs=== "2"?
          <div>
            <TaskSetup
              initialValues={this.state.initialValues}
              steercomSettings={this.state.steercomSettings}
              editTeamDetails={this.editTeamDetails}
              editTeamMembersList={this.editTeamMembersList}
              labels={this.props.labels}
              lang_code={this.props.language_code}
              setTeamDetails={this.setTeamDetails}
              {...this.props}
            ></TaskSetup>
          </div>:(showCurrTabs=== "3"?
          <div>
          <IMRoles 
            initialValues={this.state.initialValues}
            steercomSettings={this.state.steercomSettings}
            editTeamDetails={this.editTeamDetails}
            editTeamMembersList={this.editTeamMembersList}
            labels={this.props.labels}
            lang_code={this.props.language_code}
            setTeamDetails={this.setTeamDetails}
            {...this.props}
          ></IMRoles>
           </div> :
           <div>
           <EmailSetup 
             initialValues={this.state.initialValues}
             steercomSettings={this.state.steercomSettings}
             editTeamDetails={this.editTeamDetails}
             editTeamMembersList={this.editTeamMembersList}
             labels={this.props.labels}
             lang_code={this.props.language_code}
             setTeamDetails={this.setTeamDetails}
             {...this.props}
           ></EmailSetup>
         </div>)
          )} 
          
            <br></br>
      </div>
      : <Skeleton height={300} />
    )
          }
  }
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(Configuration);

import { HttpInterceptor } from "../auth/HttpInterceptor";
class survey_service{
    getsurveyStatus= async (vParams) =>{
        return await HttpInterceptor.get("survey/users/"+vParams.userid);
    }
    skipsurvey= async (vParams,data) =>{
        return await HttpInterceptor.patch(`survey/users/${vParams.userid}/skip`,data);
    }
    updatesurvey= async (vParams,data) =>{
        return await HttpInterceptor.patch(`survey/users/${vParams}`,data);
    }
    updatefeedback = async (vParams,data) =>{
        return await HttpInterceptor.patch(`feedback/users/${vParams}`,data);
    }
    getfeedback = async (vParams) => {
        return await HttpInterceptor.get(`coursefeedback/${vParams.productid}/${vParams.userid}/Feedback`);
    }
    getpostsurvey = async(vParams) => {
        return await HttpInterceptor.get("postsurvey/users/"+vParams.userid);
    }
    updatepostsurvey= async (vParams,data) =>{
        return await HttpInterceptor.patch(`postsurvey/users/${vParams}`,data);
    }
    updateSurveyRemote = async (data) =>{
        return await HttpInterceptor.post(`survey/remote/v2/surveyupdate`,data);
    }
}

export default new survey_service;
import React, { Component } from "react";
import "./layout.scss";
import Header from "../../common_components/Header/header";
import Sidebar from "../../common_components/Sidebar/Sidebar";
import Footer from "../components/footer/Footer";
import EmployeeRoutes from "../Employee_routes";
import ScrollTop from "../../shared/ScrollTop";
import { connect } from "react-redux";
import userInfo from "../../redux/action";
import ScServices from "../../admin/services/services";
import Loader from "../loader/Loader";
import IAMRoutes from "../../iam_routes/IAMRoutes";
import common_data from "../../services/common_data";

let languages = common_data.getLanguages();

class Layoutm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      status: null,
      loading: true,
      lang_code: this.props.language_code,
    };
  }
  componentDidMount() {
    ScServices.getUserInfo()
      .then((userDetail) => {
        this.setState({
          userData: userDetail[0],
        });
        let userReducerData = {};
        let currentRoleName = userDetail[0].role_name;
        let current_roleId = userDetail[0].role_id; 
        if(userDetail.length > 1) {
          let filtered = userDetail.filter((item) => { 
            if(Number(item.is_default) === 1){
              currentRoleName = item.role_name;
            }
            return (Number(item.is_default) === 1)
          })
          current_roleId = filtered.length ? filtered[0].role_id : current_roleId;
        }
        if(userDetail[0].role_id === 1 && userDetail[0].is_default === 1 && ((userDetail[0].email && !userDetail[0].email_verified) || userDetail[0].email === '' || userDetail[0].email === null)) {
          console.log("safe start direct launch else ");
          let roleIds = userDetail[0].roleIds.sort();
          current_roleId = roleIds[1];
          currentRoleName = current_roleId === 3 ? "LBLREPORTMANAGER" : current_roleId === 4 ? "LBLUSER" : currentRoleName;
        }
        if (
          this.props.language_code === null ||
          this.props.dateformat === undefined
        ) {
          languages = languages[userDetail[0].language_code];
          this.setState({
            lang_code: userDetail[0].language_code,
            loading: false,
          });
          userReducerData = {
            name: userDetail[0].user_name,
            role: currentRoleName,
            email: userDetail[0].email,
            email_verified: userDetail[0].email_verified,
            company: userDetail[0].company,
            role_id: current_roleId,
            roleIds: userDetail[0].roleIds,
            showcategory_filter: userDetail[0].showcategory_filter,
            showunits_certificate: userDetail[0].showunits_certificate,
            language_code: userDetail[0].language_code,
            user_language: languages,
            user_id: userDetail[0].user_id,
            company_id: userDetail[0].company_id,
            company_name: userDetail[0].company_name,
            timezone: userDetail[0].timezone,
            dateformat: userDetail[0].dateformat,
            timeformat: userDetail[0].timeformat,
            currency: userDetail[0].currency,
            first_name: userDetail[0].first_name,
            last_name: userDetail[0].last_name,
            company_logo: userDetail[0].company_logo,
            ssi_iam_user: userDetail[0].iam_user,
            ssi_data_priv: userDetail[0].managing_groups ? true : false
          };
        } else {
          this.setState({
            loading: false,
          });
        }

        if (this.props.user_name === "...") {
          userReducerData = {
            name: userDetail[0].user_name,
            role: currentRoleName,
            email: userDetail[0].email,
            email_verified: userDetail[0].email_verified,
            company: userDetail[0].company,
            role_id: current_roleId,
            roleIds: userDetail[0].roleIds,
            showcategory_filter: userDetail[0].showcategory_filter,
            showunits_certificate: userDetail[0].showunits_certificate,
            language_code: userDetail[0].language_code,
            user_language: languages,
            user_id: userDetail[0].user_id,
            company_id: userDetail[0].company_id,
            company_name: userDetail[0].company_name,
            timezone: userDetail[0].timezone,
            dateformat: userDetail[0].dateformat,
            timeformat: userDetail[0].timeformat,
            currency: userDetail[0].currency,
            first_name: userDetail[0].first_name,
            last_name: userDetail[0].last_name,
            company_logo: userDetail[0].company_logo,
            ssi_iam_user: userDetail[0].iam_user,
            ssi_data_priv: userDetail[0].managing_groups ? true : false
          };
          //console.log("usuusu", userReducerData)
          this.props.user_info_reducer(userReducerData);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  }
  render() {
    
    if (!this.state.loading && this.props.loaded && !this.props.ssi_iam_user) {
      return (
        <div id="wrappers">
          {(this.props.role === 2 || this.props.role >= 3) &&
            this.props.role !== "..." ? (
            <ScrollTop>
              <Header />
              <Sidebar />
              <div className="main-content" id="main-content">
                <div className="main-pad">
                  {!this.props.language_code ? (
                    <IAMRoutes language={this.props.language_code} {...this.props} />
                  ) : (
                    <IAMRoutes language={this.state.lang_code} {...this.props} />
                  )}
                </div>
                <Footer id="footer" />
              </div>
            </ScrollTop>
          ) : (
            <EmployeeRoutes />
          )}
        </div>
      );
    } else if (this.props.ssi_iam_user && this.props.ssi_iam_user !== "...") {
      return (
        <div id="wrappers">
          {this.props.role <= 2 ? (
            <ScrollTop>
              <Header />
              <Sidebar />
              <div className="main-content" id="main-content">
                <div className="main-pad">
                  {this.props.language_code !== null ? (
                    <IAMRoutes language={this.props.language_code} />
                  ) : (
                    <IAMRoutes language={this.state.lang_code} />
                  )}
                </div>
                <Footer id="footer" />
              </div>
            </ScrollTop>
          ) : (
            <IAMRoutes />
          )}
        </div>
      );
    } else {
      return (
        <div id="wrappers">
          <Loader></Loader>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user_name: state.user_info_reducer.user_info.name,
    role: state.user_info_reducer.user_info.role_id,
    language_code: state.user_info_reducer.user_info.user_language_code,
    loaded: state.user_info_reducer.user_info.loaded,
    timezone: state.user_info_reducer.user_info.timezone,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    currency: state.user_info_reducer.user_info.currency,
    company_name: state.user_info_reducer.user_info.company_name,
    first_name: state.user_info_reducer.user_info.first_name,
    last_name: state.user_info_reducer.user_info.last_name,
    company_logo: state.user_info_reducer.user_info.company_logo,
    ssi_iam_user: state.user_info_reducer.user_info.ssi_iam_user,
    ssi_data_priv: state.user_info_reducer.user_info.ssi_data_priv
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    user_info_reducer: (userReducerData) => dispatch(userInfo(userReducerData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layoutm);

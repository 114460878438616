// import React, { Component } from 'react'
import React, { useEffect } from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Feed from "../ssifeeds/feed";
import Resources from "./pages/resources/Resources";
import Products from "./pages/products/Products";
import UnitListingPage from "./pages/products/UnitListingPage";
import CapsuleList from "./pages/products/CapsuleList";
import Profile from "../admin/pages/profile/Profile";
import RateState from "../admin/pages/rate_your_state/RateState";
import NearMiss from "../admin/pages/nearmiss/NearMiss";  
import $ from "jquery";
import Not_Found from "../shared/Not_Found";
//import Not_authorized from '../shared/Not_authorized';
import Loading from "../shared/Loading";

import { connect } from "react-redux";
//import AdminRoutes from '../admin/AdminRoutes';
import TranslatedRoutes from "../translator/TranslatedRoutes";
import Reports from "../admin/pages/reports/Reports";
import ReportLayout from "../admin/pages/reports/Report_layout";
import SteerComMailLanding from "../admin/pages/steeringcommittee/components/steercom_mail_landing";
import SteercomUserDashboard from "../admin/pages/steeringcommittee/components/steercom_user_dashboard";
import SteercomDashboard from "../admin/pages/steeringcommittee/components/steercom_dashboard";
import Success_Factor_Plan from "../admin/pages/steeringcommittee/components/success_factor_plan";
import Success_Factors from "../admin/pages/steeringcommittee/components/success_factors";
import TeamSetup from "../admin/pages/steeringcommittee/components/Team_Setup"; 
import UnitDescription from "./pages/products/UnitDescription";

function EmployeeRoutes(props) {
  // const history = createBrowserHistory; 
  let { url } = useRouteMatch();
  useEffect(() => {
    if ($(window).width() <= 991) {
      $("#sidenav").css({ display: "none" });
      $(".main-content").css({ marginLeft: "0", transition: "0s" });
      $("#side-backdrop").css({ display: "none" });
    }
  });
  if (props.role > 2) {
    return (
      <Switch>
        <TranslatedRoutes
          path={`${url}/dashboard`}
          comp_name={"dashboard"}
          {...props}
          component={Dashboard}
        />
        <TranslatedRoutes
          path={`${url}/products`}
          comp_name={"products"}
          {...props}
          component={Products}
        />
        <TranslatedRoutes
          path={`${url}/product-lists`}
          comp_name={"products"}
          {...props}
          component={UnitListingPage}
        />
        <TranslatedRoutes
          path={`${url}/capsule-list`}
          comp_name={"products"}
          {...props}
          component={CapsuleList}
        />
        <TranslatedRoutes
            path={`${url}/unit-detail`}
            comp_name={"products"}
            {...props}
            component={UnitDescription}
          />
        <TranslatedRoutes
          path={`${url}/rate-state`}
          comp_name={"rateyourstate"}
          {...props}
          component={RateState}
        />
        <TranslatedRoutes
          path={`${url}/profile`}
          comp_name={"profile"}
          {...props}
          component={Profile}
        />
        <TranslatedRoutes
          path={`${url}/resources`}
          comp_name={"resources"}
          {...props}
          component={Resources}
        />
        <TranslatedRoutes
          exact
          path={`${url}/reports`}
          comp_name={"reports"}
          {...props}
          component={Reports}
        />
        <TranslatedRoutes
          path={`${url}/reports/`}
          comp_name={"reports"}
          {...props}
          component={ReportLayout}
        />
        <TranslatedRoutes
          path={`${url}/nearmiss/`}
          comp_name={"nearmiss"}
          {...props}
          component={NearMiss}
        />
        <Route exact path={`${url}/feeds`} component={Feed} />
        <TranslatedRoutes
            path={`${url}/steercommaillanding`}
            comp_name={"steercommaillanding"}
            {...props}
            component={SteerComMailLanding}
          />
        <TranslatedRoutes
          path={`${url}/steeringcommittee`}
          comp_name={"steeringcommittee"}
          {...props}
          component={TeamSetup}
        />
        <TranslatedRoutes
          path={`${url}/successfactors`}
          comp_name={"successfactors"}
          {...props}
          component={Success_Factors}
        />
        <TranslatedRoutes
          path={`${url}/successfactorplan`}
          comp_name={"successfactorplan"}
          {...props}
          component={Success_Factor_Plan}
        />
        <TranslatedRoutes
          path={`${url}/steercomdashboard`}
          comp_name={"steercomdashboard"}
          {...props}
          component={SteercomDashboard}
        />
        <TranslatedRoutes
          path={`${url}/steercomuserdashboard`}
          comp_name={"steercomuserdashboard"}
          {...props}
          component={SteercomUserDashboard}
        /> 
        <Route path="*">
          {" "}
          <Not_Found />{" "}
        </Route>
      </Switch>
    );
  } else if (props.role < 2) {
    return (
      // <Switch>
      //     <Route path="*"> <Not_authorized /> </Route>
      // </Switch>
      <Redirect
        replace
        to={{
          pathname: "/schome/dashboard",
          state: { from: "/schome/dashboard" },
        }}
      />
    );
  } else {
    return (
      <Switch>
        <Route path="*">
          {" "}
          <Loading />{" "}
        </Route>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.user_info_reducer.user_info.language_code,
    role: state.user_info_reducer.user_info.role_id,
  };
};
export default connect(mapStateToProps, null)(EmployeeRoutes);

import React from "react";
//import ReactDOM from "react-dom";
import ProfileImageDisplay from "../profileimage/profileimage";
import Carousel from "react-multi-carousel";
import "./channelCoruselView.css";
import FeedsServices from "../../feedService/feedservices";
import utils from "../../../utils/utils";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4,
    paritialVisibilityGutter: 0,
  },
  tablet: {
    breakpoint: { max: 1024, min: 888 },
    items: 4,
    slidesToSlide: 4,
    paritialVisibilityGutter: 10,
  },
  tablet1: {
    breakpoint: { max: 888, min: 464 },
    items: 4,
    slidesToSlide: 4,
    paritialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
    slidesToSlide: 4,
    paritialVisibilityGutter: 30,
  },
};

class ChannelCoruselView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() { }
  onClickCreatePost = () => {
    if (this.props.channelsData.length > 0) {
      this.setState({
        isModalOpen: true,
      });
    }

  };
  closeUploadFormHandler = () => {
    this.setState({
      isModalOpen: false,
    });
  };
  createPostHandler = (evt, postData, attachedFiles, channelStatus) => {
    if (channelStatus === 1) {
      attachedFiles.map((item) => {
        let postAsset = {};
        let fileName = utils.generateFeedsPostFileName(item.name);
        postAsset.asset_name = fileName;
        postAsset.asset_type = item.type.match("image.*")
          ? "image"
          : item.type.match("video.*")
            ? "video"
            : "";
        if (item.type.match("video.*")) {
          postAsset.is_job_done = 0;
          postData.assets.push(postAsset);
        } else {
          postData.assets.push(postAsset);
        }



        this.props.uploadAssets(item, fileName, "", this.props.imageUploadCallBack);
      })

      FeedsServices.feedsAPIServicesCall(
        "feeds",
        "post",
        this.feedsPostResultHandler,
        postData
      );
    } else {
      this.props.errorReportChannel(this.props.getResourceFromLabels("LBLCHOOSECHANNELFORPOSTMSG"))
    }


  }
  feedsPostResultHandler = (resultData) => {
    console.log(resultData);
    if (resultData.data && resultData.data._id) {
      this.setState({
        isModalOpen: false,
      });
      this.props.feedsPostResultHandler(resultData)
    }
  };

  render() {
    return (
      <div className="ssi-feeds-channel-feedsPostContainer">
           <div className="ssi-feeds-feedsChannelNavHeader">
                    <div className="ssi-feeds-feedsChannelNavHeaderText">
                      <span>{"Channels"}</span>
                    </div>
                  </div>
        <Carousel
          ssr
          partialVisbile
          //deviceType={deviceType}
          itemClass="image-item"
          responsive={responsive}
          containerClass="carousel-container"
          swipeable={true}
        >
          {this.props.channelsData.length>0 ? (
            this.props.channelsData.map(
              (channelItem, index) => {
                console.log("channelItem", channelItem.channel_profile_image)
                if (
                  channelItem.active_status === 1 &&
                  channelItem.follow > 0
                ) {
                  return (
                    <div
                      key={index}
                      className="ssi-feeds-corosul-feedsChannelItemContainer"
                      data-channelId={channelItem._id}
                      data-channelIndex={index}

                      onClick={this.props.loadIndividualChannelFeeds}
                    >
                      <div
                        //className="ssi-feeds-channelListingCarousel"
                        style={{ cursor: "pointer",textAlign:"center"}}
                      >
                        <ProfileImageDisplay
                        width={"50px"}
                        height={"50px"}
                          imageName={
                            channelItem.channel_profile_image
                          }
                          firstLetterDisplay={""}
                          placeHolderType={"channel"}
                        />
                       
                        <p
                        className={ channelItem._id.toString()===this.props.channel_filter_id?"ssi-feeds-channel-Selected":"ssi-feeds-channel-unSelected"}
                          style={{
                           fontWeight:"700",
                            // textAlign: "center",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            margin: "5px 0px",
                          }}
                        >
                        {channelItem.channel_name[0].toUpperCase() + channelItem.channel_name.substring(1)}
                        {/* {channelItem.channel_name} */}
                        </p>
                      </div>
                    </div>
                    
                  );
                }
              }
            )
          ) : (
            <div className="ssi-feeds-feedsNoChannelItemContainer">
              <div>
                <span>
                  "No CHannel"
                </span>
              </div>
            </div>
          )}
        </Carousel>
        {/* <div
          className="ssi-feeds-feedsPostHeader" 
         
        >
          <span className="ssi-feeds-createEditIcon">
            <i class="far fa-edit"></i>
          </span>
          <span>{"Channels"}</span>
        </div> */}

      </div>
    );
  }
}

export default ChannelCoruselView;

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { required, maxLength255, maxLength128 } from "../validate/Validate";
import { connect } from "react-redux";
//import { Spinner } from "react-bootstrap";
import { Modal, Form } from "react-bootstrap";
import MemberListModal from "./MemberListModal";
import Loader from "../../components/loader/Loader";
import styles from "./grouplist.module.scss";

class GroupForm extends Component {
  constructor(props) {
    super(props);
    if (this.props.initialValues != null) {
      this.state = {
        status: this.props.status,
        memberModel: false,
        items: 20,
        loadingState: false,
        itemsShowAll: false,
        showMemberList: false,
        showAssignList: false,
        showUnAssignList: false,
        groupInfo: {
          group_id: this.props.initialValues.groupId,
          group_name: this.props.initialValues.group_name,
        },
        add_users: [],
        add_nongrpusers: [],
        add_groupusers: [],
        Sitetype:localStorage.getItem('site_type')
      };
    } else {
      this.state = {
        status: this.props.status,
        memberModel: false,
        items: 20,
        loadingState: false,
        itemsShowAll: false,
        showMemberList: false,
        showAssignList: false,
        showUnAssignList: false,
        groupInfo: null,
        add_users: [],
        add_nongrpusers: [],
        add_groupusers: [],
        Sitetype:localStorage.getItem('site_type')
      };
    }
  }

  toggleSwitch = () => {
    this.setState({ status: !this.state.status });
  };
  openAssignMemberList = () => {
    this.setState({
      memberModel: !this.state.memberModel,
      showAssignList: true,
      showUnAssignList: false,
    });
  };
  openUnAssignMemberList = () => {
    this.setState({
      memberModel: !this.state.memberModel,
      showAssignList: false,
      showUnAssignList: true,
    });
  };
  getUniqueVals = (data1,data2) =>{
    return [...data1, ...data2].reduce((acc, cur) => {
      const match = acc.find((el) => {
        return el.country === cur.country && el.value === cur.value;
      });
      
      if (!match) {
        acc.push(cur);
      }
      
      return acc;
    }, []);
  }
  setSelectedUsers = (assignedUsers,isAssignList=false) =>{
    const item = assignedUsers;
    const isChecked = assignedUsers.length>0?true:false;

    this.state.showAssignList || isAssignList
    ? this.setState((prevState) => ({
      add_nongrpusers: assignedUsers,
      add_users: this.state.add_groupusers.concat([assignedUsers]),
    }))
    : this.setState((prevState) => ({
      add_groupusers: assignedUsers,
      add_users: this.state.add_nongrpusers.concat([assignedUsers]),
    }));
 
    // var array =
    //   this.state.showAssignList || isAssignList
    //     ? [...this.state.add_nongrpusers]
    //     : [...this.state.add_groupusers];
    // let index = array.indexOf(item.toString());
    // if (isChecked) {
    //   if (index === -1) {
    //     this.state.showAssignList || isAssignList
    //       ? this.setState((prevState) => ({
    //         add_nongrpusers: [...prevState.add_nongrpusers, item],
    //         add_users: this.state.add_groupusers.concat([
    //           ...prevState.add_nongrpusers,
    //           item,
    //         ]),
    //       }))
    //       : this.setState((prevState) => ({
    //         add_groupusers: [...prevState.add_groupusers, item],
    //         add_users: this.state.add_nongrpusers.concat([
    //           ...prevState.add_groupusers,
    //           item,
    //         ]),
    //       }));
    //     /*this.setState(prevState => ({
    //                 add_users: [...prevState.add_users, item]
    //             }))*/
    //   }
    // } else {
    //   if (index !== -1) {
    //     array.splice(index, 1);
    //     this.state.showAssignList || isAssignList
    //       ? this.setState(
    //         {
    //           add_nongrpusers: array,
    //           add_users: this.state.add_groupusers.concat(array),
    //         },
    //         () => {
    //           this.props.add_users(
    //             this.state.add_groupusers,
    //             this.state.add_nongrpusers,
    //             this.state.add_users
    //           );
    //         }
    //       )
    //       : this.setState(
    //         {
    //           add_groupusers: array,
    //           add_users: this.state.add_nongrpusers.concat(array),
    //         },
    //         () => {
    //           this.props.add_users(
    //             this.state.add_groupusers,
    //             this.state.add_nongrpusers,
    //             this.state.add_users
    //           );
    //         }
    //       );
    //   }
    // }
  }
  handleChange = (e, isAssignList = false) => {
    const item = e.target.name ? e.target.name : e.target.getAttribute("name");
    const isChecked = e.target.checked;
    var array =
      this.state.showAssignList || isAssignList
        ? [...this.state.add_nongrpusers]
        : [...this.state.add_groupusers];
    let index = array.indexOf(item.toString());
    if (isChecked) {
      if (index === -1) {
        this.state.showAssignList || isAssignList
          ? this.setState((prevState) => ({
            add_nongrpusers: [...prevState.add_nongrpusers, item],
            add_users: this.state.add_groupusers.concat([
              ...prevState.add_nongrpusers,
              item,
            ]),
          }))
          : this.setState((prevState) => ({
            add_groupusers: [...prevState.add_groupusers, item],
            add_users: this.state.add_nongrpusers.concat([
              ...prevState.add_groupusers,
              item,
            ]),
          }));
        /*this.setState(prevState => ({
                    add_users: [...prevState.add_users, item]
                }))*/
      }
    } else {
      if (index !== -1) {
        array.splice(index, 1);
        this.state.showAssignList || isAssignList
          ? this.setState(
            {
              add_nongrpusers: array,
              add_users: this.state.add_groupusers.concat(array),
            },
            () => {
              this.props.add_users(
                this.state.add_groupusers,
                this.state.add_nongrpusers,
                this.state.add_users
              );
            }
          )
          : this.setState(
            {
              add_groupusers: array,
              add_users: this.state.add_nongrpusers.concat(array),
            },
            () => {
              this.props.add_users(
                this.state.add_groupusers,
                this.state.add_nongrpusers,
                this.state.add_users
              );
            }
          );
      }
    }
  };
  clearUsers = () => {
    let add_nongrp = this.state.add_nongrpusers;
    let add_grp = this.state.add_groupusers;
    this.state.showAssignList ? (add_nongrp = []) : (add_grp = []);
    this.setState(
      { add_nongrpusers: add_nongrp, add_groupusers: add_grp },
      () =>
        this.props.add_users(
          this.state.add_groupusers,
          this.state.add_nongrpusers,
          this.state.add_users
        )
    );
  };

  renderField = ({
    input,
    label,
    type,
    className,
    meta: { touched, error, warning },
    ssi_errors,
  }) => {
    return (
      <div>
        <label>{label}</label>
        <div>
          <input
            {...input}
            placeholder={label}
            type={type}
            className={className}
          />
          {touched &&
            ((error && (
              <span className="required-field">
                {typeof error === "string"
                  ? this.props.labels.labels[error]
                  : this.props.labels.labels[error.label].replace(
                    "$$$",
                    error.val
                  )}
              </span>
            )) ||
              (warning && <span className="required-field">{warning}</span>))}
          {ssi_errors &&
            input["value"] &&
            ssi_errors.error.group_name === input["value"] ? (
            ssi_errors.error.error_field === "group_name" ? (
              <p className="required-field">
                {this.props.labels.labels[ssi_errors.error.message]}
              </p>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  renderTextArea = ({
    input,
    textarea,
    label,
    placeholder,
    type,
    className,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <textarea
          {...input}
          placeholder={placeholder}
          className={className}
          rows="5"
          style={{width:"100%"}}
        ></textarea>
        {touched &&
          ((error && (
            <span className="required-field">
              {typeof error === "string"
                ? this.props.labels.labels[error]
                : this.props.labels.labels[error.label].replace(
                  "$$$",
                  error.val
                )}
            </span>
          )) ||
            (warning && <span className="required-field">{warning}</span>))}
      </div>
    </div>
  );

  render() {
    const { handleSubmit, processing, initialValues } = this.props;
    const {
      LBLADDNEWGROUP,
      LBLEDITGROUP,
      LBLGROUPDESCRIPTION,
      LBLMEMBERS,
      LBLENTERGROUPNAME,
      // LBLGROUPNAME,
      LBLACTIVE,
      LBLDESCRIPTION,
      LBLCANCEL,
      LBLSAVE,
      LBLCREATE,
      LBLCONFIRM,
      LBLASSIGNUSERS,
      LBLUNASSIGNUSERS,
      LBLNEWASSIGNEDUSERS,
      LBLNEWUNASSIGNEDUSERS,
    } = this.props.labels.labels;

    return (
      <div className="row">
        {1 == 0 ? (
          // <div className="text-center">
          //   <Spinner animation="grow" />
          // </div>
          <Loader></Loader>
        ) : (
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {this.state.memberModel === false ? (
              <form className="form modal-align" onSubmit={handleSubmit}>
                <Modal.Header closeButton  className="alert-modal-header">
                  <Modal.Title className="alert-modal-title" >
                  {/* style={{lineHeight: "1.2" }} */}
                    {" "}
                    <h4
                      className="alert-modal-title"
                      id="exampleModalLongTitle"
                          >
                      {this.props.initialValues === null
                        ? LBLADDNEWGROUP + " - " + this.props.group_type
                        : LBLEDITGROUP + " - " + this.props.group_type}
                    </h4>
                    {/* <p style={{ fontSize: "18px" }}>{this.props.group_type}</p> */}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-group">
                    <Field
                      name="group_name"
                      type="text"
                      id="group_name"
                      component={this.renderField}
                      //label={LBLGROUPNAME}
                      label={this.props.group_type}
                      placeholder={LBLENTERGROUPNAME}
                      className={
                        `input-field ${styles.grpinputfieldwidth}`
                      } 
                      validate={[required, maxLength128]}
                      onChange={() => {
                        this.props.ChangesIn("group_name");
                      }}
                      ssi_errors={this.props.ssi_errors}
                    />
                    {/* {this.props.ssi_errors ? this.props.ssi_errors.error.error_field === 'group_name' ? (
                        <p className="required-field">{this.props.ssi_errors.error.message}</p>
                      ) : (
                          ""
                        ) : ""} */}
                  </div>
                  <div className="form-group">
                    <Field
                      name="description"
                      type="textarea"
                      component={this.renderTextArea}
                      label={LBLDESCRIPTION}
                      placeholder={
                        LBLGROUPDESCRIPTION + " " + this.props.group_type
                      }
                      className="input-field"
                      validate={[maxLength255]}
                      onChange={() => {
                        this.props.ChangesIn("description");
                      }}
                    />
                  </div>
                  <div className="form-group usr-act-sts">
                    <label className="label">{LBLACTIVE}</label>
                    <Field
                      name="status"
                      id="custom-switch"
                      component={renderToggleInput}
                      forEdit={this.props.initialValues !== null ? true : false}
                      onChange={() => {
                        this.props.ChangesIn("status");
                      }}
                    />
                  </div>
                  <div className="form-group  btn-tree">
                    <label className="label">
                      {LBLMEMBERS} :{" "}
                      {this.props.initialValues != null
                        ? this.props.group_users.filter(
                          (item) =>
                            this.props.initialValues.groupId === item.group_id
                        ).length
                        : "..."}
                    </label>
                    <div>
                      <button
                        className="button-style primary-btn "
                        onClick={this.openAssignMemberList}
                      >
                        {" "}
                        <i className="fas fa-plus" style={{paddingRight:" 10px",transition: "0.5s"}}></i>
                        {LBLASSIGNUSERS}
                      </button>{" "}
                      {/* ({this.props.initialValues != null ? this.props.group_users.filter(item => this.props.initialValues.groupId === item.group_id).length : '...'}) */}
                      <button
                        className="button-style primary-btn"
                        
                        onClick={this.openUnAssignMemberList}
                      >
                        {" "}
                        <i className="fas fa-minus" style={{paddingRight:" 10px",transition: "0.5s"}}></i>
                        {LBLUNASSIGNUSERS}
                      </button>
                    </div>

                    {/* ({this.props.initialValues != null ? this.props.non_grouped_users.filter(item => this.props.initialValues.groupId === item.group_id).length : '...'}) */}
                  </div>

                  <div
                    className="form-group"
                    style={
                      this.state.showMemberList === true
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <ul className={styles.mbLstMod} >
                      {this.state.add_nongrpusers.length > 0 ? (
                        <div className={styles.newAssignLabel}>
                          {LBLNEWASSIGNEDUSERS}:
                        </div>
                      ) : (
                        ""
                      )}
                      {this.props.non_grouped_users
                        .filter(
                          (item) =>
                            this.state.add_nongrpusers.indexOf(
                              item.user_id 
                            ) > -1
                        )
                        .map((item) => {
                          return (
                            <li key={item.user_id}>
                              {item.name}
                              <i
                                name={item.user_id}
                                className="fas fa-times-circle"
                                onClick={(e) => this.handleChange(e, true)}
                              ></i>
                            </li>
                          );
                        })}
                    </ul>
                    <ul className={styles.mbLstMod}>
                      {this.state.add_groupusers.length > 0 ? (
                        <div className={styles.newAssignLabel}>
                          {LBLNEWUNASSIGNEDUSERS}:
                        </div>
                      ) : (
                        ""
                      )}
                      {this.props.group_users
                        .filter(
                          (item) =>
                            this.state.add_groupusers.indexOf(
                              item.user_id 
                            ) > -1
                        )
                        .map((item) => {
                          return (
                            <li key={item.user_id}>
                              {item.name}
                              <i
                                name={item.user_id}
                                className="fas fa-times-circle"
                                onClick={this.handleChange}
                              ></i>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.props.closeModal}
                  >
                    {LBLCANCEL}
                  </button>
                  <button
                    type="submit"
                    className="button-style primary-btn"
                    disabled={processing}
                  >
                    {initialValues != null ? LBLSAVE : LBLCREATE}
                  </button>
                </Modal.Footer>
              </form>
            ) : (
              <div className="modal-align">
                <Modal.Header closeButton  className="alert-modal-header"
>
                  <Modal.Title className="alert-modal-title">
                    {" "}
                    <h4 className="alert-modal-title" id="exampleModalLongTitle">
                      {this.props.initialValues === null
                        ? LBLADDNEWGROUP
                        : LBLEDITGROUP}
                    </h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="check-list-member">
                  <MemberListModal
                    groupInfo={this.state.groupInfo}
                    onCheck={this.handleChange}
                    grouped_users={this.props.group_users}
                    non_grouped_users={this.props.non_grouped_users}
                    setSelectedUsers={this.setSelectedUsers}
                    add_users={
                      this.state.showAssignList
                        ? this.state.add_nongrpusers
                        : this.state.add_groupusers
                    }
                    roles={[
                      { role_id: 0, role_name: 'LBLALL' },
                      ...this.props.roles
                    ]}
                    group_type={this.props.group_type}
                    showAssignList={this.state.showAssignList}
                    showUnAssignList={this.state.showUnAssignList}
                    labels={this.props.labels}
                  />
                </Modal.Body>

                <Modal.Footer style={{borderTop:"none"}}>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    onClick={() => {
                      this.clearUsers();
                      this.setState({ memberModel: false, add_users: [] });
                    }}
                  >
                    {LBLCANCEL}
                  </button>
                  <button
                    type="submit"
                    className="button-style primary-btn"
                    disabled={processing}

                    onClick={() => {
                      console.log("OOOOOOOOO", this.state,this.props)
                      this.props.add_users(
                        this.state.add_groupusers,
                        this.state.add_nongrpusers,
                        this.state.add_users
                      );
                      this.setState({
                        memberModel: false,
                        showMemberList: true,
                      });
                    }}
                  >
                    {LBLCONFIRM}
                  </button>
                </Modal.Footer>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const renderToggleInput = (field) => {
  return (
    <Form.Check
      type="switch"
      id="custom-switch"
      label=""
      checked={
        field.input.value === "" && !field.forEdit ? true : field.input.value
      }
      onChange={field.input.onChange}
      icons={false}
    />
  );
};


GroupForm = reduxForm({
  form: "groupform", // a unique name for this form
})(GroupForm);

export default connect()(GroupForm);

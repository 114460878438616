import React, { Component } from "react";
import * as operators from "../operators";
import { debounce } from "../functionUtils";
//import { classList } from "dynamic-class-list";
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { TextField } from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

const ON_CHANGE_DELAY = 200;
const valueSort = (a, b) =>
  (a["value"] > b["value"] && 1) || (a["value"] === b["value"] ? 0 : -1);
const getSelItems = function (x) {
  let rtobj = {};
  for (let i = 0; i < x.length; i++) {
    rtobj["" + x[i].id] = x[i];
  }
  return rtobj;
};
const getConditionValue = function (data) {
  if (data === "object") {
    let x = JSON.stringify(data);
    let y = data;
    if (x.indexOf("[{") === 0) {
      y = [];
      for (let i = 0; i < data.length; i++) {
        y.push(data[i]);
      }
    }
    return y;
  } else {
    return data;
  }
};
class DefaultWidget extends Component {
  constructor(props) {

    super(props);
    this.state = {

      condition: {
        name: this.props.filterName,
        operator: this.props.operator,
        value: getConditionValue(this.props.value)
      },
      query: "",
      blur: 1,
      filterData: this.props.filterData, //this.props.filterData,
      searchString: [],
      responseData: [], 
      nosort: this.props.nosort,
      selItemforMultiInput:
        typeof this.props.value == "object"
          ? getSelItems(this.props.value)
          : [],

    };
    this.onConditionChangeDebounced = debounce(
      props.onConditionChange,
      ON_CHANGE_DELAY
    );
    this.onhandlePreviousInputBlurDebounced = debounce(
      props.handlePreviousInputBlur,
      ON_CHANGE_DELAY
    );
    console.log("opppp", this.props.operator)
  }
  cancelDone(data) {
    ////alert("widgetcancelDOne" + JSON.stringify(data.value))
    this.clearText();
    this.setState({
      error: false,
      condition: {
        name: data.filterName,
        operator: data.operator,
        value: getConditionValue(data.value),
      },
      query: "",
      blur: 1,
      filterData: data.filterData, //this.props.filterData,
      searchString: [],
      responseData: [],
      selItemforMultiInput:
        typeof data.value == "object" ? getSelItems(data.value) : [],
    });
  }
  componentDidMount() {
    this.props.onRef(this);
    $('.stop-propagation').on('mouseup', function (e) {
      e.stopPropagation();
    });
    //ReactDOM.findDOMNode(this.refs.myInput).focus()
  }
  componentDidUpdate() {
    //ReactDOM.findDOMNode(this.refs.myInput).focus()
    $('.stop-propagation').on('mouseup', function (e) {
      e.stopPropagation();
    });
  }
  onCancelClicked = () => {
    ////alert("cancelClicked")
  };
  componentWillReceiveProps(nextProps) {
    ////alert("second")
    //ReactDOM.findDOMNode(this.refs.myInput).focus()
  }
  HiItems = (items) => {
    ////alert(2)
    //console.log(items);
  };
  XItems = (items) => {
    ////alert(3)
    //console.log(items);
  };
  multiInputChanged = (item) => {
    ////alert(4)
    //console.log(item);
    ////alert(5)
  };
  render() {
    //console.log("labelsreports", this.props.labels);
    // const {
    //   LBLAPPLY,
    //   LBLCONDITION,
    //   LBLVALUE,
    //   LBLFILTERBY,
    //   LBLCANCEL,
    //   LBLSEARCHFOR,
    //   LBLALLITEMS,
    // } = this.props.labels;
    return (
      <div
        className="col-xl-9 col-sm-12"
        style={{
          margin: "0px",
          padding: "0px",
          display: "inline-block",
          verticalAlign: "top"
        }}
      >
        {/* <h4 className="mt-0" style={{ color: "black" }}>
          {LBLFILTERBY} {this.state.condition.name}
        </h4> */}

        {this.props.filtertype === "date" ? (
          <>
            <div
              className="col-xl-4 col-sm-12"
              style={{
                margin: "0px",
                padding: "0px 7px 0px 0px",
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              {this.renderOperatorSelector()}
            </div>
            <div
              className="col-xl-8 col-sm-12"
              style={{
                margin: "0px",
                padding: "0px",
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              {/* <label htmlFor="Value">{LBLVALUE}</label> */}
              {this.renderInputs()}
            </div>
          </>
        ) : (
          <>
            <div
              className="col-sm-12"
              style={{
                margin: "0px",
                padding: "0px",
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              {/* <label htmlFor="Value">{LBLVALUE}</label> */}
              {this.renderInputs()}
            </div>
          </>
        )}

        <div style={{ paddingTop: "0px" }}>
          {/* <button
            style={{ display: "inline-block" }}
            onClick={this.onDoneClick.bind(this)}
            type="button"
            className={classList(["btn", "basicSelItem"])}
          >
            {LBLAPPLY}
          </button>
          <button
            style={{ display: "inline-block" }}
            onClick={this.onConditionCancelDebounced.bind(
              this,
              this.state.condition
            )}
            type="button"
            className={classList(["btn", "basicSelItem"])}
          >
            {LBLCANCEL}
          </button> */}
        </div>
      </div>
    );
  }
  onDoneClick = () => {
    const operators = this.getOperators();
    let xcondition = this.state.condition;
    xcondition.operatorText = operators[xcondition.operator];
    this.onConditionChangeDebounced(xcondition);
  };
  onError = () => {
    this.setState(
      {
        error: true,
        selItemforMultiInput: {},
      }
    );
  }
  onOperatorChange = (operator) => {
    const operators = this.getOperators();
    let xcondition = this.state.condition;
    xcondition.operator = operator;
    xcondition.operatorText = operators[operator];
    if (this.props.filtertype === "date") {
      switch (operators[operator]) {
        case operators.LAST7DAYS:
        case operators.LAST30DAYS:
        case operators.THISMONTH:
        case operators.LASTMONTH:
        case operators.YTD:
          //console.log("QQQQQQQQWWWWWWWWWWWWWWWWWWWonOperatorChange", this.setReadInputs(operator))
          xcondition.value = this.setReadInputs(operator);
          break;
        case operators.TODAY:
        case operators.YESTERDAY:
          //console.log("QQQQQQQQWWWWWWWWWWWWWWWWWWWonOperatorChange", this.setReadInputs(operator))
          let y = this.setReadInputs(operator);
          xcondition.value = y[0];
          break;
        case operators.NOCONDITION:
          xcondition.value = "";
          break;
      }
    } else if (this.props.filtertype === "string") {
      switch (operators[operator]) {
        case operators.NOCONDITION:
          xcondition.value = "";
          break;
        default:
          xcondition.value = "";
      }
    } else if (this.props.filtertype === "number") {
      switch (operators[operator]) {
        case operators.NOCONDITION:
          xcondition.value = "";
          break;
        default:
          xcondition.value = 0;
      }
    }

    //console.log("QQQQQQQQwwwwwwwwwwwwwwwwonOperatorChange", xcondition);
    //xcondition.value ="ttt"
    this.setState(
      {
        condition: xcondition,
        selItemforMultiInput: {},
      }
      //,this.onDoneClick()
    );
  };

  renderOperatorSelector() {
    const operators = this.getOperators();

    const options = [];
    // Object.keys(operators).map((operator) => {
    //   options.push({ label: operators[operator], id: operator })
    // })
    for (let key of Object.keys(operators)) {
      options.push({ label: operators[key], id: key })
    }
    return (
      <div>
        {/* <select
          style={{ width: "100%", fontSize: "16px", display: "inline-block" }}
          className="input-field"
          value={this.state.condition.operator}
          onClick={(e) => {
            console.log("eee", e.target.value)
            this.onOperatorChange(e.target.value)
          }}
          onChange={(e) => {
            console.log("eee", e.target.value)
            this.onOperatorChange(e.target.value)
          }}
        >
          {this.renderOperators()}
        </select> */}

        <Autocomplete
          style={{padding:"8px 0px"}}
          id="combo-box-demo"
          aria-required="true"
          required
          disableClearable
          options={options}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, values) =>
            option.id === values.id
          }
          value={this.state.condition.operator ? options.find((opt) => { return this.state.condition.operator === opt.id }) ?? null : null}
          //value={this.state.condition.operator}
          onChange={(e, i) => {
            // console.log("qq", i);
            this.onOperatorChange(i.id);
          }}
          renderInput={(params) => (
            <div>
              <TextField
                {...params}
              />
            </div>
          )}
        />
      </div>
    );
  }

  renderOperators() {

    const operators = this.getOperators();
    return Object.keys(operators).map((operator) => {
      console.log("mm", operators, operator)
      return (
        <option key={operator} value={operator}>
          {operators[operator]}
        </option>
      );
    });
  }

  getOperators() {
    // Should be overridden by subclasses
    return {};
  }

  renderInputs() {
    switch (this.state.condition.operator) {
      case operators.LAST7DAYS:
      case operators.LAST30DAYS:
      case operators.THISMONTH:
      case operators.LASTMONTH:
      case operators.YTD:
        return this.renderDoubleReadInputs();
      case operators.TODAY:
      case operators.YESTERDAY:
        return this.renderSingleReadInput();
      case operators.BETWEEN:
        return this.renderDoubleInputs();
      case operators.NOT_BETWEEN:
        return this.renderDoubleInputs();
      case operators.MULTI_EQUALS:
        return this.renderMultiSingleInput();
      case operators.EQUALS:
        return this.renderMultiSingleInput();
      default:
        return this.renderSingleInput();
    }
  }

  renderSingleInput() {
    return this.renderInputElement(
      this.state.condition.value,
      this.onValueChange.bind(this)
    );
  }
  handleInputChange = (event) => {
    this.setState(
      {
        query: event.target.value,
      },
      () => {
        this.filterArray();
      }
    );
  };
  handleInputFocus = (event) => {
    this.onhandlePreviousInputBlurDebounced(this.state.condition.name);

    this.setState(
      {
        query: event.target.value,
        blur: 0,
      },
      () => {
        this.filterArray();
      }
    );
  };
  doEvent = (obj, event) => {
    /* Created by David@Refoua.me */
    var event = new Event(event, { target: obj, bubbles: true });
    return obj ? obj.dispatchEvent(event) : false;
  };
  clearText = () => {
    let xname = "input_" + this.state.condition.name.split(" ").join("_");
    let li = document.getElementsByTagName("input");
    for (let i = 0; i < li.length; i++) {
      if (li[i].name === xname) {
        li[i].value = "";
        this.doEvent(li[i], "blur");
      }
    }
  };
  handleInputBlur = (event) => {
    //alert(this.state.condition.name + "--handleInputBlur")

    this.clearText();
    this.setState(
      {
        blur: 1,
        query: "",
      },
      () => {
        this.filterArray();
      }
    );
  };
  filterArray = () => {
    ////alert(this.state.filterData.length)
    let searchString = this.state.query.toLowerCase();
    let responseData = this.state.blur === 1 ? [] : this.state.filterData;
    //let tmpselItemforMultiInput = this.state.selItemforMultiInput;

    if (searchString.length > 0) {
      const filteredOptions = responseData.filter(
        (x) => x && x.value && x.value.toLowerCase().indexOf(searchString) > -1
      );
      this.setState({
        responseData: filteredOptions,
      });
    } else {
      this.setState({
        responseData: responseData,
      });
    }
  };
  addItemtoMultiInput = (item) => {
    //console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwww", item);
    let tmpselItemforMultiInput = this.state.selItemforMultiInput;
    tmpselItemforMultiInput[item.id] = item;
    const operators = this.getOperators();
    //console.log("addItemtoMultiInput", tmpselItemforMultiInput);
    let xcondition = this.state.condition;
    if (xcondition.operator === "EQUALS") {
      tmpselItemforMultiInput = {}
      tmpselItemforMultiInput[item.id] = item;
    }
    xcondition.operatorText = operators[xcondition.operator];
    let convalue = [];
    for (let x in tmpselItemforMultiInput) {
      convalue.push(tmpselItemforMultiInput[x]);
    }
    xcondition.value = JSON.parse(JSON.stringify(convalue));
    if(this.props.onChangeCall)
    this.props.onChangeCall(item)

    this.setState(
      {
        selItemforMultiInput: tmpselItemforMultiInput,
        condition: xcondition,
      }
      //,this.onDoneClick()
    );
  };
  dropItemFromMultiInput = (item) => {
    //console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwww", item);
    //console.log("qqqqqqqqqqqqqqqqqqqqqqq", this.state.selItemforMultiInput);
    let tmpselItemforMultiInput = this.state.selItemforMultiInput || {};
    delete tmpselItemforMultiInput[item.id];
    const operators = this.getOperators();
    //console.log("dropItemFromMultiInput", tmpselItemforMultiInput);
    let xcondition = this.state.condition;
    xcondition.operatorText = operators[xcondition.operator];
    let convalue = [];
    for (let x in tmpselItemforMultiInput) {
      convalue.push(tmpselItemforMultiInput[x]);
    }
    xcondition.value = JSON.parse(JSON.stringify(convalue));
    this.setState(
      {
        selItemforMultiInput: tmpselItemforMultiInput,
        condition: xcondition,
      }
      //,this.onDoneClick()
    );
  };
  renderItemsSelectedforMultiInput = () => {
    //let selItemforMultiInput = this.state.selItemforMultiInput;
    return (
      <div>{/* //   <div class="xlist-group">

          //   </div> */}</div>
    );
  };
  renderMultiSingleInput() {
    return this.renderMultiInputElement(
      this.state.condition.value,
      this.onValueChange.bind(this)
    );
    //this._animate();
  }
  onItemCheckStateChange = (item) => {
    if (this.state.selItemforMultiInput.hasOwnProperty(item.id)) {
      this.dropItemFromMultiInput(item); 
    } else {
      this.addItemtoMultiInput(item);
    }
  };
  renderMultiInputElement(value, onChange) {
    //console.log("!!!!!!!!!!!renderMultiInputElement", this.state.condition)
    // return <input className="input-field" style={{ maxWidth: "160px", fontSize: "16px" }} value={this.state.condition.value} onChange={(e) => onChange(e.target.value)} />;
    let seleElements = this.state.responseData; //.map((item) => {
    let valuesorting = valueSort;
    if(this.state.nosort)
      valuesorting=undefined;
    //   item["selected"] = this.state.selItemforMultiInput.hasOwnPrperty(Item.id)
    //   return
    //   return true
    // });
    //let selItemforMultiInput = this.state.selItemforMultiInput
    return (
      <div
        id={"selItem_div_" + this.state.condition.name.split(" ").join("_")}
        className="row col-sm-12 "
        style={{ marginLeft: "-15px", marginRight: "-15px" }}
      >
        <div
          className="col-xl-4 col-sm-12 align-items-top"
          style={{ margin: "0px", padding: "0px", border: "0px solid red" }}
        >
          <input
            name={"input_" + this.state.condition.name.split(" ").join("_")}
            className="input-field stop-propagation"
            style={{ width: "100%", fontSize: "14px", margin: "0px",paddingTop: "6px",paddingBottom: "6px" }} 
            placeholder={this.props.labels.LBLSEARCHFOR}
            onChange={this.handleInputChange}
            onFocus={this.handleInputFocus}
            onClick={() => {
              //this.onhandlePreviousInputBlurDebounced.bind(this,this.state.filterName)
              this.refs.myInput.focus();
            }}
            ref="myInput"
            autofocus
          />
          {this.state.responseData.length > 0 ? (
            <span
              className="xlist-group-item"
              style={{
                width: "25px",
                marginTop: "-27px",
                display: "inline-block",
                float: "right",
                position: "relative",
                cursor: "pointer"
              }}
            >
              <i
                class="fas fa-times mx-2"
                onClick={(e) => this.handleInputBlur()}
              ></i>
            </span>
          ) : (
            ""
          )}
          {
            this.state.error === true ? <div><span className="required-field">{this.props.labels.LBLREQUIRED}</span></div> : ""
          }
          {seleElements.length > 0 ? (
            <div
              className="searchListBox  filtersearch"
              style={{
                zIndex: 1,
                padding: "0px",
                position: "absolute",
                display: "",
              }}
            >
              <ul
                class="list-group stop-propagation"
                id={ this.state.condition.name.split(" ").join("_")}
                style={{ width: "100%", overflowX: "hidden" }}
              >
                {seleElements.sort(valuesorting).map((item,idx) => (
                  <li class="list-group-item" style={{ whiteSpace: "nowrap" }}>
                    <label
                      className="check-lbl"
                      style={{ marginBottom: "0px", marginLeft: "0px" }}
                    >
                      <input
                        type="checkbox"
                        data-toggle="modal"
                        id={ this.state.condition.name.split(" ").join("_") + "_" + idx }
                        checked={this.state.selItemforMultiInput.hasOwnProperty(
                          item.id
                        )}

                        onClick={this.onItemCheckStateChange.bind(this, item)}
                      />
                      <span className="checkmark"></span>
                      <span
                        id={ this.state.condition.name.split(" ").join("_") + "_crs_" + idx }
                        style={{ display: "block", paddingTop: "6px",minHeight:"27px" }}
                        title={item.value}
                      >
                        {item.value}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div
          className="col-xl-8 col-sm-12 align-items-top"
          style={{ margin: "0px", padding: "0px", border: "0px solid yellow" }}
        >
          <div
            className="align-items-top"
            style={{
              marginLeft: "6px",
              padding: "0px",
              border: "0px solid green",
              height: "34px",
              overflow: "hidden",
              width: "calc(100% + 35px)",
            }}
            onChange={this.setMoreLink()}
            ref={
              "selItem_parent_" + this.state.condition.name.split(" ").join("_")
            }
          >
            <div
              id={
                "selItem_spanmore_" +
                this.state.condition.name.split(" ").join("_")
              }
              ref={
                "selItem_spanmore_" +
                this.state.condition.name.split(" ").join("_")
              }
              style={{
                float: "right",
                marginTop: "0px",
                color: "blue",
                cursor: "pointer",
                display: "none",
              }}
              onClick={() => {
                let vcp =
                  "selItem_parent_" +
                  this.state.condition.name.split(" ").join("_");
                this.refs[vcp].style.height = "auto";
                let vcmore =
                  "selItem_spanmore_" +
                  this.state.condition.name.split(" ").join("_");
                let vcless =
                  "selItem_spanless_" +
                  this.state.condition.name.split(" ").join("_");
                this.refs[vcmore].style.display = "none";
                this.refs[vcless].style.display = "";
                //this.refs[vca].innerHTML=this.refs[vcx].offsetHeight + "kkkkk"
              }}
            >
              <i
                class="fa fa-plus"
                style={{
                  marginRight: "5px",
                  backgroundColor: "white",
                  color: "black",
                }}
              ></i>
              {this.props.labels.LBLMORE}
            </div>
            <div
              id={
                "selItem_spanless_" +
                this.state.condition.name.split(" ").join("_")
              }
              ref={
                "selItem_spanless_" +
                this.state.condition.name.split(" ").join("_")
              }
              style={{
                float: "right",
                marginTop: "0px",
                color: "blue",
                cursor: "pointer",
                display: "none",
              }}
              onClick={() => {
                let vcp =
                  "selItem_parent_" +
                  this.state.condition.name.split(" ").join("_");
                this.refs[vcp].style.height = "34px";
                let vcmore =
                  "selItem_spanmore_" +
                  this.state.condition.name.split(" ").join("_");
                let vcless =
                  "selItem_spanless_" +
                  this.state.condition.name.split(" ").join("_");
                this.refs[vcmore].style.display = "";
                this.refs[vcless].style.display = "none";
              }}
            >
              <i
                class="fa fa-minus"
                style={{
                  marginRight: "5px",
                  backgroundColor: "white",
                  color: "black",
                }}
              ></i>{" "}
              {this.props.labels.LBLLESS}
            </div>
            <div
              ref={"selItem_" + this.state.condition.name.split(" ").join("_")}
            >
              <span
                id={
                  "selItem_span_" +
                  this.state.condition.name.split(" ").join("_")
                }
                ref={
                  "selItem_span_" +
                  this.state.condition.name.split(" ").join("_")
                }
                style={{
                  //display:"inline-block"
                  display: "none",
                }}
              ></span>
              {Object.keys(this.state.selItemforMultiInput).length > 0
                ? Object.keys(this.state.selItemforMultiInput).map(
                  (item, index) => {
                    return (
                      <>
                        <span
                          className="xlist-group-item "
                          style={{
                            border: "1px solid grey",
                            borderRadius: "4px",
                            margin: "2px 2px 4px 2px",
                            padding: "3px 7px 2px 5px",
                            display: "inline-block",
                            maxWidth: "200px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            minHeight: "31px"
                          }}
                        >
                          <i
                            class="fas fa-times ml-2"
                            id = {this.state.condition.name.split(" ").join("__") + "_close"}  
                            style={{
                              display: "inline-block",
                              float: "right",
                              cursor: "pointer"
                            }}
                            onClick={(e) =>
                              this.dropItemFromMultiInput(
                                this.state.selItemforMultiInput[item]
                              )
                            }
                          ></i>
                          {this.state.selItemforMultiInput[item].value}
                        </span>
                      </>
                    );
                  }
                )
                : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
  setMoreLink = (x) => {
    if (x !== 1) {
      setTimeout(() => {
        this.setMoreLink(1);
      }, 100);
    } else {
      try {
        let vcp =
          "selItem_parent_" + this.state.condition.name.split(" ").join("_");
        let vcx = "selItem_" + this.state.condition.name.split(" ").join("_");
        let vca =
          "selItem_span_" + this.state.condition.name.split(" ").join("_");
        let vcmore =
          "selItem_spanmore_" + this.state.condition.name.split(" ").join("_");
        let vcless =
          "selItem_spanless_" + this.state.condition.name.split(" ").join("_");
        let xhight = this.refs[vcx].offsetHeight;
        let vcphight = this.refs[vcp].offsetHeight;

        this.refs[vca].innerHTML = xhight + "px";
        if (xhight > 50) {
          if (vcphight < 40) {
            this.refs[vcmore].style.display = "";
            this.refs[vcless].style.display = "none";
          } else {
            this.refs[vcmore].style.display = "none";
            this.refs[vcless].style.display = "";
          }
        } else {
          this.refs[vcmore].style.display = "none";
          this.refs[vcless].style.display = "none";
        }
      } catch (e) {
        //setTimeout(this.setMoreLink(),100000)
      }
    }
    //let xs = document.getElementById(vcx).style.outlineOffset
    //document.getElementById(vca).innerHTML = xs
  };
  renderDoubleInputs(x) {
    const value1 = this.state.condition.value[0];
    const value2 = this.state.condition.value[1];
    //const [value1, value2] = this.state.condition.value;
    return (
      <div>
        {this.renderInputElement(value1, (value) =>
          this.onValueChange([value, value2])
          , "FROM")}
        <span
          style={{
            display: "inline-block",
            paddingLeft: "6px",
            paddingRight: "6px",
          }}
        >
          {this.props.labels.LBLTO}
        </span>
        {this.renderInputElement(value2, (value) =>
          this.onValueChange([value1, value])
          , "TO")}
      </div>
    );
  }

  renderInputElement(value, onChange) {
    return (
      <input
        className="input-field-widget"
        style={{ maxWidth: "160px", display: "inline-block" }}
        value={this.state.condition.value}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  }

  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  setReadInputs(operator) {
    const operators = this.getOperators();
    let xrArr = [];
    let cDate = new Date();
    //console.log("RRRRRRRRRRR", operator, operators[operator]);

    switch (operators[operator]) {
      case operators.LAST7DAYS:
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case operators.LAST30DAYS:
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(30)));
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
      case operators.THISMONTH:
        xrArr.push(
          new Date(cDate.getFullYear(), cDate.getMonth(), 1, 0, 0, 0, 0)
        );
        xrArr.push(new Date(cDate.getTime()));
        break;
      case operators.LASTMONTH:
        let thisMonthFDate = new Date(cDate.getFullYear(), cDate.getMonth(), 1, 23, 59, 59, 999);
        xrArr.push(new Date(thisMonthFDate.getFullYear(), thisMonthFDate.getMonth() - 1, 1, 0, 0, 0, 0));
        xrArr.push(new Date(thisMonthFDate.getTime() - this.getTimeforDays(1)));
        break;
      case operators.YTD:
        xrArr.push(new Date(cDate.getFullYear(), 0, 1, 0, 0, 0, 0));
        xrArr.push(new Date(cDate.getTime()));
        break;
      case operators.TODAY:
        xrArr.push(new Date(cDate.getTime()));
        break;
      case operators.YESTERDAY:
        xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
        break;
    }
    //console.log("@@@@@@@@@@@@", xrArr);
    return xrArr.slice();
  }

  renderSingleReadInput() {
    return this.renderReadInputElement(this.state.condition.value);
  }
  renderDoubleReadInputs() {
    //console.log("renderDoubleReadInputs", this.state.condition.value);
    const value1 = this.state.condition.value[0];
    const value2 = this.state.condition.value[1];
    //const [value1, value2] = this.state.condition.value;
    return (
      <div>
        {this.renderReadInputElement(value1)}
        <span
          style={{
            display: "inline-block",
            paddingLeft: "6px",
            paddingRight: "6px",
          }}
        >
          {this.props.labels.LBLTO}
        </span>
        {this.renderReadInputElement(value2)}
      </div>
    );
  }
  renderReadInputElement() {
    return (
      <input
        readOnly={true}
        className="input-field-widget"
        style={{ maxWidth: "160px", display: "inline-block" }}
        value={this.state.condition.value}
      />
    );
  }

  onValueChange(value) {
    // this.setState({value});
    // this.onValueChangeDebounced(value);
    const operators = this.getOperators();
    //console.log("onValueChange", value);
    let xcondition = this.state.condition;
    xcondition.operatorText = operators[xcondition.operator];
    //xcondition.operator = operator
    xcondition.value = value;
    this.setState(
      {
        condition: xcondition,
      }
      //,this.onDoneClick()
    );
  }
}

export default DefaultWidget;

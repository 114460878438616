import React from "react";  
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import { connect } from "react-redux"; 
import { isUndefined } from "lodash";
import AdminDashboard from "./steercomadmindashboard";
 

const $ = require("jquery");
let currstatus="",parentask = "";
 

class SteerComDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      dataPoints:[],
      loading: false,
      viewTabsValue: 0,
      filterDate: this.getweekdays(),
      weekTabsValue: 0,
      successFactorData: {},
      successFactorsList: [],
      allStatus: [],
      firstLoad: true,
      dateformat: props.dateformat,
      loadingListView: true,
      teamMembersList: [],
      teamSuccessFactorList: [],
      teamSuccessFactorListPrev: [],
      teamData: {},
      teamRolesList: [],
      editingTeamSF: {},
      steercomSettings: {},
      isTeamAdminUser:-1 ,
      currstatus:"", 
      mobView: false
    };
    this.childref = React.createRef();
  }
 
  componentDidMount() {
    $('#root').scrollTop(0);
    $("#back_to_list").hide();
    this.setdateformat();
    this.loadFunctions();
    this.setState({
     // isTeamAdminUser: roleid
    });
  }
  loadFunctions=()=>{
    this.getSteercomSettings();
    this.getTeamList();  
  }
  isAdminCheck=()=>{
    this.isAdminCheckCallback();
  }
  isAdminCheckCallback= async()=>{
    let res =  await SteercomServices.getMemberTypeDash(); 
    let isTeamAdmin=0;
    let roleid = this.props.role_id;
     
    if(res.result[0][0].TeamAdmin_TeamCount>0 || roleid==1){
      isTeamAdmin =1;
    }
     isTeamAdmin = 0;
    this.setState({
      isTeamAdminUser: isTeamAdmin
    });
  } 
  getTeamList = () => {
    this.getTeamListCallback();
  };
  getTeamListCallback =async () => {
    let res =  await SteercomServices.getTeamListDash(); 
    let teamlist_active=[],teamList = res.result;
      teamlist_active = teamList.filter((content,i)=>{
        return content.team_status==1 ||  content.team_status==null;
      })
    let filterTeamList = teamlist_active.filter((item) => {
      if (this.props.role_id > 1) {
        if (item.team_administrator == this.props.userId || 
            this.props.userId == item.team_addnl_administrator) {
          return item;
        } else if (item.memberofteam > 0) {
          return item;
        }
      } else {
        return item;
      }
      return null;
    });
    let loading_loc =true;
    if(filterTeamList.length==0){
      loading_loc = false;
    }
    loading_loc=false;
    this.setState(
      {
        teamList: filterTeamList,
        loading: loading_loc,
      },
      () => {

        if (this.props.location.state && this.props.location.state.teamData) {
          this.getTeamMembersList(this.props.location.state.teamData.team_id)
        } else {
           if(filterTeamList[0])
           this.getTeamMembersList(filterTeamList[0].team_id)   
        }
      }

    );
  };
  getTeamSFList = (team_id, sfid) => {
    if (
      this.state.teamData &&
      this.state.teamData.team_id &&
      this.state.successFactorData &&
      this.state.successFactorData.id
    ) {
      let roleid = this.props.role_id; 
      this.getTeamSFListCallback(team_id,sfid,roleid);
    }
  };
  getTeamSFListCallback = async (team_id,sfid,roleid) => {
    let res =  await SteercomServices.getTeamSFListDash(team_id,sfid,roleid); 
    let filterTeamSFList,
      base_date,
      teamSFList = res.result;
    if (teamSFList.length > 0) {
      base_date = teamSFList[0].base_date;
      for (let i = 0; i < teamSFList.length; i++) {
        if (this.state.firstLoad) {
          teamSFList[i].expandFlag = 0;
        } else if (parentask == teamSFList[i].parent_task_display_id) {
          teamSFList[i].expandFlag = 1;
        } else {
          teamSFList[i].expandFlag = 0;
        }
        teamSFList[i].expandFlag = 1;
        if (
          teamSFList[i].task_type == 1 &&
          base_date &&
          !teamSFList[i].planned_start_date
        ) {
          teamSFList[i].planned_start_date = new Date(base_date);
          teamSFList[i].planned_start_date.setDate(
            teamSFList[i].planned_start_date.getDate() +
            teamSFList[i].recommended_start_days
          );
        }
      }
     
      filterTeamSFList = teamSFList.filter((item) => {
        if (item.task_type == 0) {
          let childitems = teamSFList.filter((citem) => {
            if (citem.parent_task_display_id == item.task_display_id) {
              if (
                typeof citem.team_member_id == "number" ||
                typeof citem.team_member_id == "string"
              ) {
                if (this.props.role_id > 1) {
                  if (
                    this.state.teamData.team_administrator == this.props.userId || 
                    this.props.userId == this.state.teamData.team_addnl_administrator
                  ) {
                    return citem;
                  } else if (citem.member_user_id == this.props.userId) {
                    return citem;
                  }
                } else {
                  return citem;
                }
              }
            }
          return null;
          });
          if (childitems.length > 0) {
            return item;
          }
        } else {
          if (
            typeof item.team_member_id == "number" ||
            typeof item.team_member_id == "string"
          ) {
            return item;
          }
        }
        return null;
      });
    } else {
      filterTeamSFList = [];
    }
   
    let filterTeamSFListActive=[],teamList = this.state.teamList;
    if(teamList.length>0){ 
        filterTeamSFList.map((val,cnt)=>{
          teamList.map((item,idx)=>{
            if(item.team_id == val.team_id && item.team_status!=0){
              filterTeamSFListActive.push(val);
            }
            return null;
        })
        return null;
      })
    }

    let locTeamSFList = [];
    filterTeamSFList = filterTeamSFListActive;
    filterTeamSFList.map((item, i) => { 
      if(item.task_type==0){
        let tid = filterTeamSFList[i].team_id;
        if(filterTeamSFList[i+1] && filterTeamSFList[i+1].task_type==1){
          if(tid==filterTeamSFList[i].team_id){
            locTeamSFList.push(item);
          }
        } 
      }else  if(item.task_type==1){
          locTeamSFList.push(item);
      } 
      return null;
    });

    var groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    var groubedByTeam=groupBy(locTeamSFList, 'team_id')

    let prevParent="",allSFs = groubedByTeam[null];
    
let groupedList=[];
if(allSFs !== undefined){
     Object.keys(groubedByTeam).map((items,idx)=>{
      prevParent="";
      items!=null && groubedByTeam[items].map((item,ind)=>{
       if(prevParent != item.parent_task_display_id){
        var teamData = allSFs.find( function(items){
          return items.parent_task_display_id == item.parent_task_display_id && 
          items.language_code == item.language_code &&  items.version==item.version
         });
         if(ind==0)
         groupedList.push({"task_type":"2","team_id": item.team_id,"team_name":item.team_name,"success_factor_id":item.success_factor_id});
         groupedList.push(teamData); 
         prevParent = item.parent_task_display_id;
       } 
        groupedList.push(item);
        return null;
      })
      return null;
    })
  }else{
    Object.keys(groubedByTeam).map((items,idx)=>{
      groubedByTeam[items].map((item,ind)=>{
        if(item.task_type==0 && ind==0) //&& items.parent_task_display_id == item.parent_task_display_id 
        { 
          groupedList.push({"task_type":"2","team_id": item.team_id,"team_name":item.team_name,"success_factor_id":item.success_factor_id});
          groupedList.push(item); 
        }else{
          groupedList.push(item);
        }
        return null;
      }); 
      return null;
    });
  }
    filterTeamSFList =groupedList;//groubedByTeam;//locTeamSFList; 
    this.setState({
      teamSuccessFactorList: filterTeamSFList,
      teamSuccessFactorListPrev: filterTeamSFList,
      base_date: base_date,
      loading: false,
      firstLoad: false,
    }, this.isAdminCheck());
  }; 
  getTeamMembersList = (team_id) => {
    this.getTeamMembersListCallback(team_id);
  };
  getTeamMembersListCallback =async (team_id) => {
    let res =  await SteercomServices.getTeamMembersListDash(team_id); 
    console.log("getTeamMembersListCallback", res.data);

    let teamData = res.result[0][0];
    let teamMembersList = res.result[1];
    let teamRolesList = [];
    let rolesObj = {};
    let roles = this.state.steercomSettings.roles;
    for (let i = 0; i < roles.length; i++) {
      rolesObj[roles[i].id] = roles[i];
    }
    let addedRoles = {};
    for (let j = 0; j < teamMembersList.length; j++) {
      if (rolesObj.hasOwnProperty(teamMembersList[j].steercom_role_id)) {
        if (
          addedRoles.hasOwnProperty(teamMembersList[j].steercom_role_id) ==
          false
        ) {
          addedRoles[teamMembersList[j].steercom_role_id] = 1;
          teamRolesList.push(rolesObj[teamMembersList[j].steercom_role_id]);
        }
      }
    }
    console.log("teamRolesList", teamRolesList);
    this.setState(
      {
        teamData: teamData,
        teamMembersList: teamMembersList,
        teamRolesList: teamRolesList,
      },
      () => {
        setTimeout(() => {
          this.getTeamSFList(0, 0)
        }, 1000)
      }
    );
  };
  getColors = (status) => {
    let colors = { "YETTOSTART": "#f5f4f5", "INPROGONTRACK": "#e2f3ff", "INPROGDELAYED": "#DBF2F3", "COMPLETED": "#DBF2F3" };
    let selcolor = colors[status];//colors[Math.floor(Math.random() * colors.length)];
    return selcolor;
  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    for (let x in steercomSettings) {
      steercomSettings[x].map((item, index) => {
        if (item.name && item.name.indexOf("LBL") == 0) {
          item.name = this.getLabel(item.name);
        }
        if (item.title && item.title.indexOf("LBL") == 0) {
          item.title = this.getLabel(item.title);
        }
        if (item.description && item.description.indexOf("LBL") == 0) {
          item.description = this.getLabel(item.description);
        }
        return null;
      });
    }
    
    steercomSettings.successfactors.unshift({
        active: 1,
        color: "sf_color1",
        description: "",
        id: "0",
        name: "LBLALL",
        title: "All",
        version: "1"
      });
    if (this.props.location.state && this.props.location.state.successFactorData) {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: this.props.location.state.successFactorData,
      });
    } else {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: steercomSettings.successfactors[0],
      });
    }
  };
  getLabel = (xfield, mode) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (
        typeof xfield === "object" &&
        this.props.labels.labels.hasOwnProperty(xfield.label)
      ) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield + "111";
      }
    }

  };
  getStatusDetails = (item, row) => {
    alert(item.CurrentStatus)
  }
  updateEditFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    let editingTeamSF = this.state.editingTeamSF;
    for (var i = 0; i < teamSuccessFactorList.length; i++) {
      if (item.task_display_id == teamSuccessFactorList[i].task_display_id) {
        teamSuccessFactorList[i].isNewAssigned = status ? 0 : 1;
        if ((teamSuccessFactorList[i].isNewAssigned || 0) == 1) {
          editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id] = {
            team_id: teamSuccessFactorList[i].team_id,
            success_factor_id: teamSuccessFactorList[i].success_factor_id,
            task_display_id: teamSuccessFactorList[i].task_display_id,
            actual_start_date: teamSuccessFactorList[i].actual_start_date,
            actual_end_date: teamSuccessFactorList[i].actual_end_date,
            task_status: teamSuccessFactorList[i].task_status,
            completion_percentage:
              teamSuccessFactorList[i].completion_percentage,
            last_activity_description:
              teamSuccessFactorList[i].last_activity_description,
            oldMemberId: teamSuccessFactorList[i].team_member_id,
          };
        } else {
          delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
        }
      } else if (
        status == 0 &&
        (teamSuccessFactorList[i].isNewAssigned || 0) == 1
      ) {
        teamSuccessFactorList[i].isNewAssigned = 0;
        delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
      editingTeamSF: editingTeamSF,
    }));
  };
  updateExpandFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    for (let i = 0; i < teamSuccessFactorList.length; i++) {
      if (
        item.task_display_id == teamSuccessFactorList[i].parent_task_display_id  
      ) {
        teamSuccessFactorList[i].expandFlag = status ? 0 : 1;
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
    }));
  }; 
  handleSFChange = (event, values) => {
    let { teamSuccessFactorList } = this.state;
    if (values.id == "Y1SF1") { 
      this.getTeamList(); 
    } else if(values.id == "0"){ 
      this.getTeamList();
    }else {
      teamSuccessFactorList = [];
    }
    this.setState({
      successFactorData: values,
      teamSuccessFactorList: teamSuccessFactorList
    })
  }
  handleTabChange = (event, newValue) => {
    this.setState({
      viewTabsValue: newValue,
      loadingListView: newValue
    });
  };
  handleWeekTabChange = (event, newValue) => {
    let filterDate = this.getweekdays(newValue);

    this.setState({
      weekTabsValue: newValue,
      filterDate: filterDate
    });
  };
  showDescription = (desc, name, tid) => {
    this.setState({
      showDescModal: true,
      descriptionData: desc,
      nameData: name,
      taskid: tid,
    });
  };
  closeDescription = () => {
    this.setState({
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
    });
  };
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
    return dateformat;
  };
  formattingDate = (date) => {
    let dateformat,
      fulldate = "";
    dateformat = this.state.dateformat;
    if (dateformat.toString().indexOf("YYYY") > 0)
      dateformat = this.setdateformat();
    if (date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      if (dateformat == "dd/MM/yyyy") {
        fulldate = [day, month, year].join("/");
      } else if (dateformat == "MM/dd/yyyy") {
        fulldate = [month, day, year].join("/");
      } else if (dateformat == "yyyy/MM/dd") {
        fulldate = [year, month, day].join("/");
      } else {
        fulldate = [day, month, year].join("/");
      }
    }
    return fulldate;
  };
  openRefModal = (row) => {
    console.log(" row.references", row.references)
    this.setState({
      showmodal: true,
      resourceData: JSON.parse(row.references || '{"folder_id":998}'),
      resourceDataCustom: row.custom_references || ""      
    });
  };
  setteamdetails=(item)=>{ 
    this.setState({
         teamData:item
    })
  }
  setSFDetails=(successFactorData)=>{   
    this.setState({
      successFactorData:successFactorData[0]
    })
  }
  redirecttoplan = (item, event) => {
    let dirpath = "/schome/"
    if(window.location.href.indexOf("/home/")>-1){
      dirpath = "/home/"
    }
    let backlinkurl = dirpath + "steercomdashboard";
    var teamData = this.state.teamList.find( function(items){
      return items.team_id == item.team_id;
     });
     let successFactorData = this.state.successFactorData;
     if(successFactorData.id==0){
        successFactorData={
          active: 1,
          color: "sf_color1",
          description: "LBLALL",
          id: "0",
          name: "LBLALL",
          title: this.props.labels.labels.LBLALL,
        }
     }
    this.props.history.push({
      pathname: dirpath + "successfactorplan",
      state: {
        backlink: true,
        backlinkurl: backlinkurl,
        successFactorData: successFactorData,
        teamData: teamData,
        taskItem: item,
        parentId: item.parent_task_display_id
      }
    });

  }
  getweekdays = (mode) => {
    let currDays, currentDate = new Date();
    if (isUndefined(mode)) {
      mode = 1;
    }
    if (mode == 0) {
      currDays = 7 - (new Date().getDay());
      currentDate = new Date(new Date().setDate(currDays));
    } else if (mode == 1) {
      currDays = 7 - (new Date().getDay());
      currentDate = new Date(new Date().setDate(currDays + 7));
    } else {
      currentDate = new Date(new Date().setMonth(360));
    }
    // alert(currentDate)
    return currentDate;
  }
  handleAllSF =(event)=>{
   
    if(this.childref.current){
      this.childref.current.backLoadTableData();
    }else{
      this.loadFunctions();
    }
    currstatus="";
    this.setState({
      currstatus:currstatus
    })
  }
  render() { 
    let { LBLSTEERTASKS,LBLUPDATEPLAN,LBLSTEERCOMSFSETUP, 
    } = this.props.labels.labels; 
 
    return (
      <div className="mob-pad cls_dashboard">
        <div className="page-header-field ">
          
          <h3 className="page-title" id="header_top"> 
            <span id="header_det_admin">{LBLSTEERTASKS}</span>
          </h3>
          <div className="cls_sf_links"> 
          <div className="btn-align cls_sf_link1" id="header_reset">
            <button
              className="button-style primary-btn"
              onClick={() => 
                {
                let dirpath = "/schome/";
                if(window.location.href.indexOf("/home/")>-1){
                  dirpath = "/home/"
                }
                let backlinkurl =  "successfactors";
                let successFactorData = this.state.successFactorData;
                let teamid= this.state.team_id;
                var teamData1 =  this.state.teamData;
                var teamData = this.state.teamList.find(function (items) {
                  return items.team_id == teamid;
                });
                if(teamData==null)
                teamData = teamData1;
                if(successFactorData.id=== 0 || successFactorData=== undefined || successFactorData==null){
                   successFactorData={
                     active: 1,
                     color: "sf_color1",
                     description: "LBLALL",
                     id: "0",
                     name: "LBLALL",
                     title: this.props.labels.labels.LBLALL,
                   }
                }
                  this.props.history.push({
                  pathname: dirpath + "successfactors",
                  state: {
                    backlink: true,
                    backlinkurl: backlinkurl,
                    successFactorData: successFactorData,
                    teamData: this.state.teamData
                  }
                });
              }}
            >
            <span id="updateplan"> {LBLSTEERCOMSFSETUP}</span>
            </button>
          </div>
          <div className="btn-align" id="header_reset">
            <button
              className="button-style primary-btn"
              onClick={() => {
                let dirpath = "/schome/"
                if(window.location.href.indexOf("/home/")>-1){
                  dirpath = "/home/"
                }
                let backlinkurl = "successfactorsetup"
                  this.props.history.push({
                  pathname: dirpath + "successfactorplan",
                  state: {
                    backlink: true,
                    backlinkurl: backlinkurl,
                    successFactorData: this.state.successFactorData,
                    teamData: this.state.teamData
                  }
                });
              } }
            >
            <span id="updateplan"> {LBLUPDATEPLAN}</span>
            </button>
          </div>
          
          </div>
          
        </div>
        <hr class="head-line"></hr>
   
        <div className="cls_admin_section">
          <div className="cls_dsbrd_container"> 
            <AdminDashboard  
              ref={this.childref}  
              currstatus= {currstatus} 
              steercomSettings={this.state.steercomSettings}  
              labels={this.props.labels}
              localstates={this.state}
              filterTeamList={this.state.filterTeamList}
              lang_code={this.props.language_code}
              redirecttoplan={this.redirecttoplan}
              setteamdetails={this.setteamdetails}
              setSFDetails={this.setSFDetails} 
              teamSuccessFactorList={this.state.teamSuccessFactorList}
              {...this.props} 
            ></AdminDashboard> 
          </div>
        </div> 
        
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};
export default connect(mapStateToProps, null)(SteerComDashboard);
 
import React from "react";
import Skeleton from "react-loading-skeleton";
import Loader from "../../components/loader/Loader";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import emptyImage from '../../../assets/images/dashboard _Emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import { isUndefined } from "lodash";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
require("highcharts/modules/exporting")(Highcharts);
const $ = require("jquery");
let maxLeft = -1
let prevX = 0;
let prevF = 0, prevB = 0;
let parentask = "",loadanimate=true,firstLoad=true;
class SteerComAdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphType: "Bar",
      options: {},
      dataPoints: [],
      loading: false,
      isAdminUser: true,
      topLoading:true,
      currSuccessFactor: "SF1",
      filterTeamList: [],
      filterTeamSummaryList: [],
      team_name: "",
      teamsummary_sfid: "Y1SF1",
      teammtsummary: [],
      loadAdminList: false,
      teamsummary: [],
      dateformat: props.dateformat,
      team_details: [],
      loadStatusSummary: false,
      teamSuccessFactorList: this.props.teamSuccessFactorList
    };
  }

  componentDidMount() {
    $('#root').scrollTop(0);
    $("#back_to_list").show();
    $("#header_det_admin").html(this.props.labels.labels.LBLSTEERTASKS)
    this.getSteercomSettings();
    this.setdateformat();
    this.getTeamList();
    $('body').on("onresize", function () { 
    });  
  } 
  componentDidUpdate() {
    $(".rdt_TableBody").scroll(function () {
      var scrollLeft = $(this).scrollLeft();
      $(".rdt_TableHead").scrollLeft(scrollLeft);
    });  
  }
  getTeamSummaryList = () => {
    let roleid = this.props.role_id;
    let team_id = 0
    let sfid = 0
    this.getTeamSummaryListCallback(team_id,sfid,roleid)
  };
  getTeamSummaryListCallback = async(team_id,sfid,roleid) => {
    let res =  await SteercomServices.getTeamSummary(team_id,sfid,roleid);  
    let teamList1=[], teamList = res.result;
 
    let teams = this.state.filterTeamList;

    teams.map((item,j)=>{
      teamList.map((items,i)=>{
        if(item.team_id == items.team_id && item.team_status!=0 ){
          teamList1.push(items) ;
        }
        return null;
      }); 
      return null;
    }); 
    this.setState(
      {
        filterTeamSummaryList: teamList1,
        topLoading: false,
      } 
    ); 
    let backurl = this.props.history.location.state?this.props.history.location.state.backlinkurl:"";
     
    if(backurl!=""){  
      let teamData , team_id = this.props.history.location.state.teamData.team_id;
      teamData =  teamList1.filter((item)=>item.team_id==team_id)
      this.loadSuccessFactors(teamData[0]);
      let idx = teamList1.findIndex(item=>item.team_id==team_id)
      $("#sel_0").parent().parent().children().children("*").removeClass("active")
      $("#sel_0").parent().parent().children("*").children("*").removeClass("selected")
      $("#sel_"+idx).addClass("selected");
      $("#sel_"+idx).parent().children('img').addClass("active");
    }else{
      this.loadSuccessFactors(teamList1[0])
    } 
  };
  getTeamList = () => {
    this.getTeamListCallback();
  };
  getTeamListCallback = async () => {
    let res =  await SteercomServices.getTeamListDash(); 
    let dbteamLists=[], teamList = res.result;
    if(teamList.length>0){
      teamList.map((item,idx)=>{
        if(item.team_status!=0){
          dbteamLists.push(item);
        }
        return null;
      })
    }
    let filterTeamList = dbteamLists.filter((item) => {
      if (this.props.role_id > 1) {
        if (item.team_administrator == this.props.userId || this.props.userId == item.team_addnl_administrator) {
          return item;
        } else if (item.memberofteam > 0) {
          return item;
        }
      } else {  
        return item;
      }
      return null;
    });
    let teamloading=true;
  if(filterTeamList.length>0){
    teamloading=false;
    $(".cls_sf_links").css({"display":"flex"})
  }else{
    $(".cls_sf_links").css({"display":"none"})
  }
  teamloading=false;
    this.setState(
      {
        filterTeamList: filterTeamList,
        loading: teamloading,
      },
      this.getTeamSummaryList() 
    );
  };
  getColors = (status) => {
    let colors = { "YETTOSTART": "#f5f4f5", "INPROGONTRACK": "#e2f3ff", "INPROGDELAYED": "#DBF2F3", "COMPLETED": "#DBF2F3" };
    let selcolor = colors[status];//colors[Math.floor(Math.random() * colors.length)];
    return selcolor;
  };
  getSteercomSettings = () => { 
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  getSteercomSettingsCallback = (response) => { 
    let steercomSettings = response.data.result;
    for (let x in steercomSettings) {
      steercomSettings[x].map((item, index) => {
        if (item.name && item.name.indexOf("LBL") == 0) {
          item.name = this.getLabel(item.name);
        }
        if (item.title && item.title.indexOf("LBL") == 0) {
          item.title = this.getLabel(item.title);
        }
        if (item.description && item.description.indexOf("LBL") == 0) {
          item.description = this.getLabel(item.description);
        }
        return null;
      });
    }
   
    if (this.props.location.state && this.props.location.state.successFactorData) {
      //alert(this.props.location.state.successFactorData.id)
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: this.props.location.state.successFactorData,
      });
    } else {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: steercomSettings.successfactors[0],
      });
    }
  };
  getLabel = (xfield, mode) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (
        typeof xfield === "object" &&
        this.props.labels.labels.hasOwnProperty(xfield.label)
      ) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield + "111";
      }
    }
  };
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
    return dateformat;
  };
  formattingDate = (date) => {
    let dateformat,
      fulldate = "";
    dateformat = this.state.dateformat;
    if (dateformat.toString().indexOf("YYYY") > 0)
      dateformat = this.setdateformat();
     let dateArray = date.split("/"); 
    if (date) {
      var d=new Date(),month,day,year;
      d = new Date(date);  
      day = "" + d.getDate();
      if(d==undefined || isNaN(d.getDate()))
      {  
        d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
      } 
        month = "" + (d.getMonth() + 1);
        day = "" + d.getDate();
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      if (dateformat == "dd/MM/yyyy") {
        fulldate = [day, month, year].join("/");
      } else if (dateformat == "MM/dd/yyyy") {
        fulldate = [month, day, year].join("/");
      } else if (dateformat == "yyyy/MM/dd") {
        fulldate = [year, month, day].join("/");
      } else {
        fulldate = [day, month, year].join("/");
      }
    }
    return fulldate;
  };
  redirecttoplan = (item, event) => {
    let dirpath = "/schome/"
    if (window.location.href.indexOf("/home/") > -1) {
      dirpath = "/home/"
    }
    let backlinkurl = dirpath + "steercomdashboard";
    var teamData = this.state.teamList.find(function (items) {
      return items.team_id == item.team_id;
    });
    this.props.history.push({
      pathname: dirpath + "successfactorplan",
      state: {
        backlink: true,
        backlinkurl: backlinkurl,
        successFactorData: this.state.successFactorData,
        teamData: teamData,
        parentId: item.parent_task_display_id
      }
    });
  }
  sortResults = (arr, prop, asc) => {
    console.log("sortResults", arr, prop, asc)
    arr.sort(function (a, b) {
      console.log("a[prop]", a[prop], b[prop], (a[prop] > b[prop]))
      return 1
    });
  }
  getweekdays = (mode) => {
    let currDays, currentDate = new Date();
    if (isUndefined(mode)) {
      mode = 1;
    }
    if (mode == 0) {
      currDays = 7 - (new Date().getDay());
      currentDate = new Date(new Date().setDate(currDays));
    } else if (mode == 1) {
      currDays = 7 - (new Date().getDay());
      currentDate = new Date(new Date().setDate(currDays + 7));
    } else {
      currentDate = new Date(new Date().setMonth(360));
    }
    // alert(currentDate)
    return currentDate;
  }
  selectedItemStatus = (item) => {
    let { steercomSettings } = this.state;
    let  currentSF = "Y1" + item.point.chartName;
    let sfid =  steercomSettings.successfactors.filter(i=>i.id == currentSF)
    // let loadStatusSummary = false; 
    this.getTeamSFList(0, currentSF, 1);

    this.setState({
      teamsummary_sfid:currentSF, 
      successFactorData:sfid
    });
  }
  getTeamSFList = (team_id, sfid,mode) => {
    loadanimate=true;
    if (
      this.state.team_details &&
      this.state.team_details.team_id
    ) {
      if(mode==1){
        loadanimate=false;
      }
      let roleid = this.props.role_id;
      let teamid= this.state.team_details.team_id;
      this.getTeamSFListCallback(teamid,sfid,roleid);
    }
  };
  getTeamSFListCallback = async(teamid,sfid,roleid) => {
    let mainTasksList =[], res =  await SteercomServices.getTeamSFListDash(teamid,sfid,roleid); 
    let filterTeamSFList,
      base_date,
      teamSFList = res.result;
    if (teamSFList.length > 0) {
      base_date = teamSFList[0].base_date;
      for (let i = 0; i < teamSFList.length; i++) {
        if (this.state.firstLoad) {
          teamSFList[i].expandFlag = 0;
        } else if (parentask == teamSFList[i].parent_task_display_id) {
          teamSFList[i].expandFlag = 1;
        } else {
          teamSFList[i].expandFlag = 0;
        }
        teamSFList[i].expandFlag = 1;
        if (
          teamSFList[i].task_type == 1 &&
          base_date &&
          !teamSFList[i].planned_start_date
        ) {
          teamSFList[i].planned_start_date = new Date(base_date);
          teamSFList[i].planned_start_date.setDate(
            teamSFList[i].planned_start_date.getDate() +
            teamSFList[i].recommended_start_days
          );
        }
      }
let allData = this.state; 
      filterTeamSFList = teamSFList.filter((item) => {
        if(allData.teamsummary_sfid==item.success_factor_id){
          if (item.task_type == 0) {
            let childitems = teamSFList.filter((citem) => {
              if (citem.parent_task_display_id == item.task_display_id ) {
                if (
                  typeof citem.team_member_id == "number" ||
                  typeof citem.team_member_id == "string"
                ) {
                  if (this.props.role_id > 1) {
                    if (
                      citem.team_administrator == this.props.userId || 
                      this.props.userId == citem.team_addnl_administrator
                    ) {
                      return citem;
                    } else if (citem.member_user_id == this.props.userId) {
                      return citem;
                    }
                  } else {
                    return citem;
                  }
                }
              }
              if(item.task_type==0){
                mainTasksList.push(item);
              }  
              return null;
            });
            if (childitems.length > 0) {
              return item;
            }
          } else {
            if (
              typeof item.team_member_id == "number" ||
              typeof item.team_member_id == "string"
            ) {
              return item;
            }
          }
        }
        return null;
      });
    } else {
      filterTeamSFList = [];
    }

    let curr_item={},curr_item_cnt=0, locTeamSFList = [],locTeamSFListSub = [];
    let filterTeamSFList1=filterTeamSFList;
    filterTeamSFList.map((item, i) => {
      if (item.task_type == 0) {
        let tid = filterTeamSFList[i].team_id;
        filterTeamSFList1.map((items, i) => {
          if(items.parent_task_display_id==item.parent_task_display_id ){
            if (items.task_type==0 && tid == items.team_id) {
              curr_item = item; 
            }
            if(items.task_type==1 && tid == items.team_id){
              curr_item_cnt++; 
              locTeamSFListSub.push(items);
            }
          } 
          return null;
        });
        if(curr_item_cnt>0){ 
          locTeamSFList.push(item)
          locTeamSFListSub.map((i,j)=>{
           return locTeamSFList.push(i)
          })
          curr_item_cnt=0;
          locTeamSFListSub=[];
        }
      } else if (item.task_type == 1) { 
      }
      return null;
    });
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    var groubedByTeam = groupBy(locTeamSFList, 'team_id')
    let prevParent = "", allSFs = groubedByTeam[null];

    let groupedList = [], prev_parent = "";
    if (allSFs != undefined) {
      Object.keys(groubedByTeam).map((items, idx) => {
        prevParent = "";
        items != null && groubedByTeam[items].map((item, ind) => {
          if (prevParent != item.parent_task_display_id) {
            var teamData = allSFs.find(function (items) {
              return items.parent_task_display_id == item.parent_task_display_id &&
                items.language_code == item.language_code && items.version == item.version
            });
            if (ind == 0)
              groupedList.push({ "task_type": "2", "team_id": item.team_id, "team_name": item.team_name, "success_factor_id": item.success_factor_id });
            groupedList.push(teamData);
            prevParent = item.parent_task_display_id;
          }
          groupedList.push(item);
          return null;
        });
        return null;
      })
    } else {
      Object.keys(groubedByTeam).map((items, idx) => {
        groubedByTeam[items].map((item, ind) => {
          if (prev_parent != item.parent_task_display_id) //&& items.parent_task_display_id == item.parent_task_display_id 
          {
            if (ind == 0) {
              groupedList.push({ "task_type": "2", "team_id": item.team_id, "team_name": item.team_name, "success_factor_id": item.success_factor_id });
            }
            groupedList.push(item);
            prev_parent = items.parent_task_display_id;
          } else {
            groupedList.push(item);
          }
          return null;
        });
        return null;
      });
    }
     
    filterTeamSFList = groupedList.filter(item =>  (item.task_type==1 || item.task_type==2) )
    this.setState({
      teamSuccessFactorList: filterTeamSFList,
      teamSuccessFactorListPrev: filterTeamSFList,
      base_date: base_date,
      firstLoad: false,
      loadAdminList: true,
      mainTasksList:mainTasksList,
    },this.sefocus() );
  };
  sefocus=()=>{  
    $('#root').scrollTop(window.innerHeight + 200);
    $('html').scrollTop(window.innerHeight + 200);
  }
  loadSuccessFactors = (item) => {
    let filterTeamSummaryList = this.state.filterTeamSummaryList
    let teammtsummary = [] //{ "Y1SF1M1": [], "Y1SF1M2": [], "Y1SF1M3": [], "Y1SF1M4": [] }
    let teamsummary = {} // "Y1SF1": 0, "Y1SF2": 0, "Y1SF3": 0, "Y1SF4": 0, "Y1SF5": 0 }
    let teamname = item?item.team_name:"";
    let task_completion={};
    for (let i = 0; i < filterTeamSummaryList.length; i++) {
      if (filterTeamSummaryList[i].team_id == item.team_id) {
        let tdp = filterTeamSummaryList[i].parent_task_display_id
        let sfid = filterTeamSummaryList[i].success_factor_id
        if(filterTeamSummaryList[i].del_flag !=1){ 
          teammtsummary.push(filterTeamSummaryList[i]);
          task_completion[sfid] = (task_completion[sfid] || 0) + (filterTeamSummaryList[i].TASKS_COMPLETED + 
                                  filterTeamSummaryList[i].TASKS_INPROGONDELAY + 
                                  filterTeamSummaryList[i].TASKS_INPROGONTRACK + 
                                  filterTeamSummaryList[i].TASKS_YETTOSTART +  
                                  filterTeamSummaryList[i].TASKS_YETTOSTART_ASSIGNED); 
          teamsummary[sfid] = (teamsummary[sfid] || 0) + (filterTeamSummaryList[i].completion_percentage);
        }
      }
    } 
    for (let x in teamsummary){
      if(teamsummary[x]>-1){
        teamsummary[x] = (teamsummary[x]/task_completion[x]); 

        teammtsummary.map((item,i)=>{
          if(item.success_factor_id == x && (!item.convertions || item.convertions==undefined)){
            item.convertion=true;
            item.YETTOSTART = Math.round(((item.TASKS_YETTOSTART + item.TASKS_YETTOSTART_ASSIGNED)/task_completion[x])*100);
            item.INPROGONTRACK = Math.round(( item.TASKS_INPROGONTRACK/task_completion[x])*100);
            item.INPROGONDELAY = Math.round(( item.TASKS_INPROGONDELAY/task_completion[x])*100);
            item.COMPLETED = Math.round((item.TASKS_COMPLETED/task_completion[x])*100);
          }
          return null;
        });
      }
    }
    this.setState({
      teammtsummary: teammtsummary,
      teamsummary: teamsummary,
      teamname: teamname,
      team_details: item,
      loading: false,
      task_completion:task_completion
    }) 
  }
  getFactorName = (row) => {
    let factorName = ""; 
    let SFTITLE = row.success_factor_id.replace("Y1","LBL") + "TITLE";
    factorName = this.getLabel(SFTITLE);
    return factorName;
  }
  getParentName = (row) => {
    let parentName = "", parentTaskId = row.parent_task_display_id;
    let mainTasksList = this.state.mainTasksList;
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        parentName = i.custom_task_name?i.custom_task_name:this.getLabel( "LBL" + parentTaskId );
      }
      return null;
    }); 
    return parentName;
  }
  handleTabs = (mode, event) => {
    let currX = 0;
    let movelength=87;
    let maxLeft = window.document.getElementsByClassName("team_bar")[window.document.getElementsByClassName("team_bar").length - 1].offsetLeft;// -92
    let barswidth = (window.document.getElementById("team_bars").clientWidth / 2 ) + (movelength);
    currX = mode == 2 ? maxLeft > (prevX + (barswidth*2)) ? prevX + barswidth : maxLeft : prevX > -1 ? maxLeft == prevX ? prevX - barswidth*2 : prevX - barswidth : prevX;
    $(".nav-pills").scrollLeft(currX);
    console.log(currX,maxLeft)
    prevX = currX;
  }
  handleSelected = (item, event) => {
    let teamname =  $(event.currentTarget).find("span").html()
    this.setSelection(event.currentTarget);
    this.loadSuccessFactors(item);
    this.props.setteamdetails(item);
    this.setState({
      team_name: teamname,
      team_details: item,
      loadAdminList: false
    })
  }
  setSelection = (target) => {
    $(".column>.box").next().map((idx, item) => {
      item.className = "";
      $(item.parentElement).find("img").removeClass("active")
      return null;
    })
    $(target).find("img").addClass("active")
    $(target).find("div").next().addClass("selected")
  }
  backtoSFstaus = () => {
    this.setState({
      loadStatusSummary: false
    })
  }
  loadSFData=(e)=>{
    //let sfid=e.dataSeries.chartName; 
    let sfid = e.point.chartName
    let currSuccessFactor = sfid;
    let graphType = "Bar";
    // let {teamsummary_sfid,successfactors} = this.state; 
    sfid = "Y1" + sfid; 
    let successFactorData = this.props.steercomSettings.successfactors.filter((items,idx)=>
    {
      return items.id == sfid;
    })
    
    this.props.setSFDetails(successFactorData);
    this.setState({
      teamsummary_sfid:sfid,
      successFactorData:successFactorData,
      currSuccessFactor:currSuccessFactor,
      graphType:graphType,
      loadAdminList:false
    })
  }
  getIntervals=(options)=>{
    let maxVal = 10, intvl = 1, dNum=10;
    let arrNum = [1,10,100,1000,10000,100000]
    let arrVal=[];
    if(options.data[0].dataPoints.length>0){
      maxVal = Math.max(...options.data[0].dataPoints.map(o => o.total_tasks)); 
      if(maxVal>10){
        dNum = arrNum[(maxVal.toString().length) - 1];
        intvl = (Math.ceil(maxVal / dNum) * dNum)/10;
      }
    }
    arrVal.push(intvl);arrVal.push(dNum); 
    return arrVal;
  }
  getOptions = () => {
    let pieColors = ["#007bff", "#c8cbce"];
    let options =  {
       chart: { 
           type: 'pie',  
           width:160,
           height:120, 
           style: {
            fontFamily: "'Roboto', sans-serif"
          },
       },
       title: {
           text: '' 
       },
       subtitles: {
        //text: this.props.parentstates.inccount + " " + LBLINCIDENTS,
        text: "",
        verticalAlign: "center",
        fontSize: 18,
        fontWeight: 300,
        fontColor:"#7B7B7B", 
        dockInsidePlotArea: true,
      },
       credits: { 
         enabled: false
       },
       legend: { 
        enabled:true, 
        align: 'left',
        padding:12,  
        itemStyle: { 
          fontWeight: '450', 
          fontSize: '11px', 
      }
      },
       exporting: {
         enabled: false
       },
       xAxis: { 
           categories: [], 
           tickInterval:1
       },
       yAxis: {
        title:{
          text:""
        }
       },
       tooltip: { 
          pointFormat: ' {point.indexLabel} : <strong>{point.y}%</strong>', 
          headerFormat: '',  
      },
       accessibility: {
           point: {
               valueSuffix: '%'
           }
       },
       plotOptions: {
           pie: {
              // allowPointSelect: true,
               cursor: 'pointer',
               colors: pieColors, 
               size: 100,
               innerSize:60, 
               y: -5,
               format: "{y}%",
               dataLabels: {
                enabled: true,
                distance: -50,  
                style: {
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: '14px'
                },
                filter: {
                     property: 'indexLabel',
                     operator: '===',
                     value: 'Completed'
                 }, 
            },
           },
           series: {
            cursor: 'pointer', 
            stacking: 'normal',
            events: {
                click: this.selectedItemStatus //this.loadSFData
            }
        }
       },
       series: [{ 
           colorByPoint: true,
           data: [],
           event:{
            click: this.selectedItemStatus // this.loadSFData
          }
       }]
   };
   return options;
  } 
  render() {
    let {
      LBLY1SF1M1, LBLY1SF1M2, LBLY1SF1M3, LBLY1SF1M4, LBLTEAMS,
      LBLSF1TITLE, LBLSF2TITLE, LBLSF3TITLE, LBLSF4TITLE, LBLSF5TITLE, LBLCOMPLETED,
      LBLYETTOSTART, LBLINPROGONTRACK, LBLINPROGONDELAY, LBLSF, LBLNOTEAMFOUND, 
      LBLCURRENTSTATUS, LBLBACK, LBLFORWARD,
      LBLTASK, LBLREQUIRED, LBLPLANNORECORDS,
      LBLPLANNEDENDDATE, LBLTEAMNAME,LBLINCOMPLETED,
      LBLPCOMPLETED, LBLPLANNEDSTARTDATE
    } = this.props.labels.labels;
    let labels = this.props.labels.labels
    let teamname = "";
    let { teamsummary_sfid, loadStatusSummary, currSuccessFactor, filterTeamSummaryList, team_name, teamsummary, teammtsummary } = this.state;

    let dataPoints = []
    let stack_dataPoints = [{
      type: "stackedBar",
      toolTipContent: "<b>{name}:</b> {y}/{total_tasks}",
      showInLegend: true,
      name: labels["LBLCOMPLETED"],
      color: "#79da8d",
      dataPoints: []
    }, {
      type: "stackedBar",
      toolTipContent: "<b>{name}:</b> {y}/{total_tasks}",
      showInLegend: true,
      name: labels["LBLINPROGONTRACK"],
      color: "#7db1f5",
      dataPoints: []
    },{
      type: "stackedBar",
      toolTipContent: "<b>{name}:</b> {y}/{total_tasks}",
      showInLegend: true,
      name: labels["LBLINPROGONDELAY"],
      color: "#f5c947",
      dataPoints: []
    },{
      type: "stackedBar",
      toolTipContent: "<b>{name}:</b> {y}/{total_tasks}",
      showInLegend: true,
      name: labels["LBLYETTOSTARTASGND"],
      color: "#bbbaba",
      dataPoints: []
    },{
      type: "stackedBar",
      toolTipContent: "<b>{name}:</b> {y}/{total_tasks}",
      showInLegend: true,
      name: labels["LBLYETTOSTART"],
      color: "#c8c6c6",
      dataPoints: []
    }]
    let totalweight = 0
    let colors = [
      "#F53F68",
      "#00C8F4",
      "#10D877",
      "#984BD6",
      "#F5EF68",
      "#00F8F4",
      "#10A877",
      "#98BBD6",
      "#F5CF68",
      "#00D8F4",
      "#10E877",
      "#98FBD6",
    ]
    let taskName="", mtindex = 0;
    let teammtsummaryloc = teammtsummary.filter((item,idx)=>{
      return (item.YETTOSTART!=null) 
    });
    teammtsummary = teammtsummaryloc;
    if (loadStatusSummary == false) {
      for (let i = 0; i < teammtsummary.length; i++) {
        if (teammtsummary[i].del_flag !=1 && teammtsummary[i].success_factor_id == teamsummary_sfid) {
          teamname = teammtsummary[0].team_name;
          totalweight = totalweight + teammtsummary[i].completed_weight;
          taskName = teammtsummary[i].custom_task_name?teammtsummary[i].custom_task_name:labels[teammtsummary[i].task_name]
          dataPoints.push({ y: .1, color: "transparent", toolTipContent: null, legendText: "", indexLabel: "" })
          if (teammtsummary[i].YETTOSTART > 0) {
            dataPoints.push(
              {
                y: teammtsummary[i].YETTOSTART,
                label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
                id: teammtsummary[i].parent_task_display_id,
                state: labels["LBLYETTOSTART"],
                color: colors[mtindex] + "d9",
                showInLegend: "true",
                indexLabel: labels["LBLYETTOSTART"] + " " + teammtsummary[i].YETTOSTART + "%"
              }
            )

            // dataPoints.push({ y: .2, color: "transparent", toolTipContent: null, legendText: "", indexLabel: "" })
          }
          if (teammtsummary[i].INPROGONDELAY > 0) {
            dataPoints.push(
              {
                y: teammtsummary[i].INPROGONDELAY,
                label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
                id: teammtsummary[i].parent_task_display_id,
                state: labels["LBLINPROGONDELAY"],
                color: colors[mtindex] + "a8",
                showInLegend: "true",
                indexLabel: labels["LBLINPROGONDELAY"] + " " + teammtsummary[i].INPROGONDELAY + "%"
              }
            )
            // dataPoints.push({ y: .2, color: "transparent", toolTipContent: null, legendText: "", indexLabel: "" })
          }
          if (teammtsummary[i].INPROGONTRACK > 0) {
            dataPoints.push(
              {
                y: teammtsummary[i].INPROGONTRACK,
                label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
                id: teammtsummary[i].parent_task_display_id,
                state: labels["LBLINPROGONTRACK"],
                color: colors[mtindex] + "80",
                showInLegend: "true",
                indexLabel: labels["LBLINPROGONTRACK"] + " " + teammtsummary[i].INPROGONTRACK + "%"
              }
            )
            // dataPoints.push({ y: .2, color: "transparent", toolTipContent: null, legendText: "", indexLabel: "" })
          }
          if (teammtsummary[i].COMPLETED > 0) {
            dataPoints.push(
              {
                y: teammtsummary[i].COMPLETED,
                label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
                id: teammtsummary[i].parent_task_display_id,
                state: labels["LBLCOMPLETED"],
                color: colors[mtindex] + "ff",
                showInLegend: "true",
                indexLabel: labels["LBLCOMPLETED"] + " " + teammtsummary[i].COMPLETED + "%"
              }
            )
          }
           
          stack_dataPoints[0].dataPoints.push(
            {
              //y: teammtsummary[i].COMPLETED,
              y: teammtsummary[i].TASKS_COMPLETED,
              label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
              id: teammtsummary[i].parent_task_display_id,
              state: labels["LBLCOMPLETED"],
              color: "#79da8d",
              total: teammtsummary[i].total_weight,
              total_tasks: teammtsummary[i].total_tasks,
              click: (e) => {
                this.selectedItemStatus(e);
              },
            }
          )
          stack_dataPoints[1].dataPoints.push(
            {
              y: teammtsummary[i].TASKS_INPROGONTRACK,
              label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
              id: teammtsummary[i].parent_task_display_id,
              state: labels["LBLINPROGONTRACK"],
              color: "#7db1f5",
              total: teammtsummary[i].total_weight,
              total_tasks: teammtsummary[i].total_tasks,
              click: (e) => {
                this.selectedItemStatus(e);
              },
            }
          )
          stack_dataPoints[2].dataPoints.push(
            {
              y: teammtsummary[i].TASKS_INPROGONDELAY,
              label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
              id: teammtsummary[i].parent_task_display_id,
              state: labels["LBLINPROGONDELAY"],
              color: "#f5c947",
              total: teammtsummary[i].total_weight,
              total_tasks: teammtsummary[i].total_tasks,
              click: (e) => {
                this.selectedItemStatus(e);
              },
            }
          )
          stack_dataPoints[3].dataPoints.push(
            {
              y: teammtsummary[i].TASKS_YETTOSTART_ASSIGNED,
              label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
              id: teammtsummary[i].parent_task_display_id,
              state: labels["LBLYETTOSTARTASGND"],
              color: "#b7b6b6",
              lineColor: "Indigo",
              fontSize: 8,
              total: teammtsummary[i].total_weight,
              total_tasks: teammtsummary[i].total_tasks,
              click: (e) => {
                this.selectedItemStatus(e);
              },
            }
          )
          stack_dataPoints[4].dataPoints.push(
            {
              //y: teammtsummary[i].YETTOSTART,
              y: teammtsummary[i].TASKS_YETTOSTART,
              label: taskName,// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
              id: teammtsummary[i].parent_task_display_id,
              state: labels["LBLYETTOSTART"],
              color: "#c8c6c6",
              lineColor: "Indigo",
              fontSize: 8,
              total: teammtsummary[i].total_weight,
              total_tasks: teammtsummary[i].total_tasks,
              click: (e) => {
                this.selectedItemStatus(e);
              },
            }
          )
           
          mtindex++
        }
      }
    } else {
      let colors = ["#C8C6C6", "#7DB1F5", "#F5C947", "#79DA8D"]
      let status = [25, 35, 20, 20];
      let labels = [LBLY1SF1M1, LBLY1SF1M2, LBLY1SF1M3, LBLY1SF1M4];
      let statuslabels = [LBLYETTOSTART, LBLINPROGONTRACK, LBLINPROGONDELAY, LBLCOMPLETED]
      mtindex = 0;
      for (let i = 0; i < teammtsummary.length; i++) {
        dataPoints.push(
          {
            y: status[i],
            label: statuslabels[i],// + "<br />" + labels["LBLTOTAL"] + ":" + teammtsummary[i].total_weight + "<br />" + labels["LBLCOMPLETED"] + ":" + teammtsummary[i].completed_weight,
            color: colors[mtindex],
            showInLegend: "true",
            indexLabel: statuslabels[i] + " - " + status[i] + "%"
          })
        dataPoints.push({ y: .2, color: "transparent", toolTipContent: null, legendText: "", indexLabel: "" })
        mtindex++;
      }
    }

    const options = {
      animationEnabled: false,
      interactivityEnabled: false,
      exportEnabled: false,
      theme: "light1", //"light1", "dark1", "dark2"
      indexLabelPlacement: "inside",
      title: {
        //  text: "Success Factors 1",//this.props.labels.labels[reportname],
        verticalAlign: "center",
        dockInsidePlotArea: true,
        fontSize: 16,
        fontColor: "#4B72B5",
      },
      legend: {
        itemMaxWidth: 150,
        itemMaxHeight: 50,
        fontSize: 13,
        padding: 20,
        horizontalAlign: "right",
        verticalAlign: "bottom"
      },
      data: [
        {
          innerRadius: 30,
          fontFamily: "Roboto, sans-serif",
          showInLegend: false,
          type: "doughnut",
          indexLabelFontColor: "#5A5757",
          dataPoints: [
          ],
          chartName: "1",

          click: (e) => {
            this.loadSFData(e);
          }, 
        },
      ],
    };
    // dataPoints=[];
    const smoptions = {
      //animationEnabled: true,
      exportEnabled: false,
      theme: "light1",
      title: {
        verticalAlign: "center",
        dockInsidePlotArea: true,
        fontSize: 16,
        fontColor: "#4B72B5",
      }, 
      subtitles: dataPoints.length > 0 ? [] : [{
        text: "No Records", fontSize: 22, verticalAlign: "center",
        fontSize: 18, fontWeight: 300, dockInsidePlotArea: true
      }],
      legend: {
        fontFamily: "calibri",
        fontSize: 12,
      },
      data: [
        {
          type: "doughnut",
          percentFormatString: "#0:##",
          startAngle: 25,
          toolTipContent: "<b>{id}</b>-{label}<br/>{state}:{y}%",
          legendText: "{label} - {state}",
          indexLabelFontSize: 10,
          //indexLabel: (y==".2") ? "" : "{state}:{y}%",
          contentFormatter: function (e) {
            var content = ""; 
            e.entries.sort(function(a,b) {
              return b.dataPoint.id - a.dataPoint.id;
            });
      
            content	+= e.entries[0].dataPoint.label;
            content += "<br/>";
      
            var entries = e.entries;
            for(var j = 0; j < entries.length; j++) {
              content	+= "<span style=\"color:" + entries[j].dataSeries.color+"\"\>" + entries[j].dataSeries.name + " </span>: " + "<strong>" + entries[j].dataPoint.y + "</strong>";
              content += "<br/>"; 
            } 
            return content; 
          }, 
          dataPoints: [
          ],
          click: (e) => {
            this.selectedItemStatus(e);
          },
        },
      ],
    };

    const stack_smoptions = {
      exportEnabled: false,
      theme: "light1",
      dataPointWidth: 30,
      title: {
        verticalAlign: "center",
        dockInsidePlotArea: true,
        fontSize: 16,
        fontColor: "#4B72B5",
      }, 
      axisY: {
        gridThickness: .2,
      },
      subtitles: dataPoints.length > 0 ? [] : [{
        text: "No Records", fontSize: 22, verticalAlign: "center",
        fontWeight: 300, dockInsidePlotArea: true
      }],
      legend: {
        fontFamily: "calibri",
        fontSize: 12,
      },
      data: [
        {
          type: "stackedBar",
          percentFormatString: "#0:##",
          startAngle: 25,
          toolTipContent: "<b>{id}</b>-{label}<br/>{state}:{y}%",
          legendText: "{label} - {state}",
          indexLabelFontSize: 10,
          dataPoints: [
          ],
          click: (e) => {
            //this.selectedItemStatus(e);
              this.loadSFData(e)
          },
        },
      ],

    };
    let options_sf=[], LBLTITLE;

    let options1 = JSON.parse(JSON.stringify(options));
    options1.height = 120;
    options1.interactivityEnabled = true;
    options1.data[0].chartName = "SF1";
    options1.data[0].showInLegend = false;
    options1.title.text = (teamsummary["Y1SF1"] ? teamsummary["Y1SF1"].toFixed(2) : 0) + "%";
    options1.animationEnabled = false;
    options1.indexLabel = labels["LBLCOMPLETED"];
    options1.data[0].click=(e)=>{this.selectedItemStatus(e)} ;//{this.loadSFData(e)}
    options1.data[0].dataPoints = [
      { y: (teamsummary["Y1SF1"] || 0).toFixed(2), label: "", name:LBLCOMPLETED, indexLabel: LBLCOMPLETED, color: "#28a745",chartName:"SF1"},
      { y: (100 - (teamsummary["Y1SF1"] || 0)).toFixed(2), label: "", name:LBLINCOMPLETED, indexLabel:LBLINCOMPLETED, color: "#C9C8C9",chartName:"SF1"},
    ];

    let options2 = JSON.parse(JSON.stringify(options));
    options2.height = 120;
    options2.interactivityEnabled = true;
    options2.data[0].chartName = "SF2";
    options2.data[0].showInLegend = false;
    options2.data[0].click=(e)=>{this.selectedItemStatus(e)} ;//{this.loadSFData(e)}
    options2.title.text = (teamsummary["Y1SF2"] ? teamsummary["Y1SF2"].toFixed(2) : 0) + "%";
    options2.data[0].dataPoints = [
      { y: (teamsummary["Y1SF2"] || 0).toFixed(2), label: "",indexLabel:LBLCOMPLETED, color: "#28a745",chartName:"SF2" },
      { y: 100 - (teamsummary["Y1SF2"] || 0).toFixed(2), label: "",indexLabel:LBLINCOMPLETED, color: "#C9C8C9",chartName:"SF2" },
    ];
 
    let options3 = JSON.parse(JSON.stringify(options));
    options3.height = 120;
    options3.interactivityEnabled = true;
    options3.data[0].chartName = "SF3";
    options3.data[0].showInLegend = false;
    options3.data[0].click=(e)=>{this.selectedItemStatus(e)} ;//{this.loadSFData(e)}
    options3.title.text = (teamsummary["Y1SF3"] ? teamsummary["Y1SF3"].toFixed(2) : 0) + "%";
    options3.data[0].dataPoints = [
      { y: (teamsummary["Y1SF3"] || 0).toFixed(2), label: "",indexLabel:LBLCOMPLETED, color: "#28a745",chartName:"SF3" },
      { y: 100 - (teamsummary["Y1SF3"] || 0).toFixed(2), label: "",indexLabel:LBLINCOMPLETED, color: "#C9C8C9",chartName:"SF3" },
    ];

    let options4 = JSON.parse(JSON.stringify(options));
    options4.height = 120;
    options4.interactivityEnabled = true;
    options4.data[0].chartName = "SF4";
    options4.data[0].showInLegend = false;
    options4.data[0].click=(e)=>{this.selectedItemStatus(e)} ;//{this.loadSFData(e)}
    options4.title.text = (teamsummary["Y1SF4"] ? teamsummary["Y1SF4"].toFixed(2) : 0) + "%";
    options4.data[0].dataPoints = [
      { y: (teamsummary["Y1SF4"] || 0).toFixed(2), label: "",indexLabel:LBLCOMPLETED, color: "#28a745",chartName:"SF4" },
      { y: 100 - (teamsummary["Y1SF4"] || 0).toFixed(2), label: "",indexLabel:LBLINCOMPLETED, color: "#C9C8C9",chartName:"SF4" },
    ];

    let options5 = JSON.parse(JSON.stringify(options));
    options5.height = 120;
    options5.interactivityEnabled = true;
    options5.indexLabelPlacement = "inside";
    options5.data[0].chartName = "SF5";
    options5.data[0].showInLegend = false;
    options5.data[0].click=(e)=>{this.selectedItemStatus(e)} ;//{this.loadSFData(e)}
    options5.title.text = (teamsummary["Y1SF5"] ? teamsummary["Y1SF5"].toFixed(2) : 0) + "%";
    options5.data[0].dataPoints = [
      { y: (teamsummary["Y1SF5"] || 0).toFixed(2), label: "", indexLabel: "",indexLabel:LBLCOMPLETED, color: "#28a745",chartName:"SF5" },
      { y: 100 - (teamsummary["Y1SF5"] || 0).toFixed(2), label: "",indexLabel:LBLINCOMPLETED, color: "#C9C8C9",chartName:"SF5" },
    ];

    if (teamsummary_sfid == "Y1SF1") {
      $('#root').scrollTop(0);
      if (this.state.graphType == "Doughnut") {
        options_sf = smoptions;
        options_sf.data[0].dataPoints = dataPoints;
      } else {
        options_sf = stack_smoptions;
        options_sf.data = stack_dataPoints;
      } 
      options_sf.data[0].chartName = currSuccessFactor; 
      options_sf.data[0].innerRadius = 40;
      options_sf.height = 300;
      LBLTITLE = LBLSF1TITLE;

    } else if (teamsummary_sfid == "Y1SF2" ) {
      $('#root').scrollTop(0);
      if (this.state.graphType == "Doughnut") {
        options_sf = smoptions;
        options_sf.data[0].dataPoints = dataPoints;
      } else {
        options_sf = stack_smoptions;
        options_sf.data = stack_dataPoints;
      } 
      options_sf.data[0].chartName = currSuccessFactor; 
      options_sf.data[0].innerRadius = 40;
      options_sf.height = 300;
      LBLTITLE = LBLSF2TITLE;
    } else if (teamsummary_sfid == "Y1SF3" ) {
      $('#root').scrollTop(0);
      if (this.state.graphType == "Doughnut") {
        options_sf = smoptions;
        options_sf.data[0].dataPoints = dataPoints;
      } else {
        options_sf = stack_smoptions;
        options_sf.data = stack_dataPoints;
      } 
      options_sf.data[0].chartName = currSuccessFactor; 
      options_sf.data[0].innerRadius = 40;
      options_sf.height = 300;
      LBLTITLE = LBLSF3TITLE;
    } else if (teamsummary_sfid == "Y1SF4" ) {
      $('#root').scrollTop(0);
      if (this.state.graphType == "Doughnut") {
        options_sf = smoptions;
        options_sf.data[0].dataPoints = dataPoints;
      } else {
        options_sf = stack_smoptions;
        options_sf.data = stack_dataPoints;
      } 
      options_sf.data[0].chartName = currSuccessFactor; 
      options_sf.data[0].innerRadius = 40;
      options_sf.height = 300;
      LBLTITLE = LBLSF4TITLE;
    } else if (teamsummary_sfid == "Y1SF5" ) {
      $('#root').scrollTop(0);
      if (this.state.graphType == "Doughnut") {
        options_sf = smoptions;
        options_sf.data[0].dataPoints = dataPoints;
      } else {
        options_sf = stack_smoptions;
        options_sf.data = stack_dataPoints;
      } 
      options_sf.data[0].chartName = currSuccessFactor; 
      options_sf.data[0].innerRadius = 40;
      options_sf.height = 300;
      LBLTITLE = LBLSF5TITLE;
    } 
    let healthactivity = {
      YETTOSTART: "notstarted",
      INPROGONTRACK: "ontrack",
      INPROGONDELAY: "delayed",
      COMPLETED: "completed",
    };

    const {successFactorData, teamSuccessFactorList, editingTeamSF} = this.state;
    let getDateFormat = (xdata, item, mode) => {
      let enddate;
      if (xdata) {
        if (item && mode !== 1) {
          enddate = xdata;
          enddate = new Date(enddate).toLocaleDateString("en-US");
          enddate = enddate.split(",")[0]; //enddate.substr(0, 10);
        } else {
          enddate = xdata.split(",")[0]; //xdata.substr(0, 10);
        }
          enddate = this.formattingDate(enddate);
        return enddate; //.substr(0, 10)
      } else {
        return false;
      }
    };
    let getRoleName = (xdata) => {
      if (xdata) {
        return this.getLabel("LBL" + xdata);
      } else {
        return "";
      }
    };
    let getStatusClass = (status) => {
      if (typeof status == "string") {
        return healthactivity[status];
      } else {
        return healthactivity["YETTOSTART"];
      }
    };
    let filterSuccessFactorsListData = teamSuccessFactorList.filter((item) => { 
        if (
          (item.del_flag!=1 && (item.task_type == 1 && item.expandFlag == 1) || item.task_type == 0 || item.task_type == 2)
        ) {
          return item;
        }
        // }
        return null;
      }
    );
    let finaldata = [];

    filterSuccessFactorsListData.map((item, i) => { 
    // if((successFactorData.id==item.success_factor_id) || successFactorData.id=="0" || item.task_type == 2) {
      let sfid;    
      if(typeof(successFactorData[0])=="object"){
        sfid = successFactorData[0].id;
      }else{
        sfid = successFactorData.id;
      }
    if (item.task_type == 2) {
          console.log(successFactorData)   
          let val = filterSuccessFactorsListData.filter((items,idx)=>{
            if(sfid==0){
              return items.team_id == item.team_id && items.task_type!=2 
            }else{  
              return items.success_factor_id==sfid && 
                     items.team_id == item.team_id && items.task_type!=2
            }
            })
            let taskitems = val;
              taskitems=val.find((content,i)=>{
                return content.task_type==1
              })
          if (taskitems && taskitems.task_type ) {
             finaldata.push(item);
            val.map((itm,ix)=>{ 
              if(val[ix+1] && val[ix+1].task_type==1 && itm.task_type==0)
                finaldata.push(itm);
              if(itm.task_type==1)  
               finaldata.push(itm);
              return null;
            })
          }
        }  
      return null;
    })
    let finalTeamSuccessFactorListData = finaldata;

    let idx = 1;
    let teamSuccessFactorColumns = [
      {
        name: "",
        selector: "",
        width: "5%",
        cell: (row) => (
          <>
            <div
              style={{
                display: "inline-block",
                paddingLeft: "0px",
                position: "absolute",
                backgroundColor: "white",
                left: "0px",
                width: "72px",
                height: "52px",
              }}
            ></div>
            {row.main_type ? <div>{row.main_type}</div> :
              row.task_type == 2 ? "" :
                row.task_type == 0 ? (
                  ""
                ) : (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        paddingLeft: "0px",
                        position: "absolute",
                        border: "0px solid red",
                        top: "0px",
                        left: "8px",
                      }}
                    >
                      <div> { }</div>
                    </div>
                  </>
                )}
          </>
        ),
      },
      {
        name: LBLTASK,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "24%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.task_type == 2) {
            idx++;
          }
          if (row.main_type) {
            index = idx++;
          }

          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + (index)}>{row.main_type}</div> :
                row.task_type == 2 ? (
                  <div className="cls_team_name" >
                    <div className="cls_team_name_span">
                      {LBLTEAMNAME} -  {row.team_name}
                    </div>
                  </div>
                ) :
                  row.task_type == 1 ? (
                    <>
                      <div
                        style={{
                          width: "15px",
                          height: "34px",
                          border: "0px solid",
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            width: "4px",
                            height: "34px",

                            backgroundColor: "#80E3BA",
                            border: "0px solid",
                            display: "block",
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          width: "calc(100% - 14px)",
                          border: "0px solid",
                          display: "flex",
                        }}
                      >

                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              border: "0px solid red",
                              width: "calc(100% - 12px)",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name)}
                              style={{
                                width: "90%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                textOverflow: "ellipsis",
                                fontWeight: "410"
                              }}
                            >
                              {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name)}
                            </div>
                          </div>

                        </>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={"MAINTASK_" + (index)}
                        style={{
                          // width: "100%",
                          border: "0px solid",
                          height: "100%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "8px",
                              left: "2px",
                              border: "0px solid blue",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name)}
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                fontSize: "15px",
                                fontWeight: "430",
                              }}
                            >
                              {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                            </div>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              width: "28px",
                            }}
                          >

                          </div>
                        </>
                      </div>
                    </>
                  )}
            </div>
          )
        },
      },
      {
        name: LBLSF,
        selector: "member_enddate",
        width: "16%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ color: "#4486a6", fontWeight: "500" }}>
                        {(this.props.labels.labels["LBL" + row.success_factor_id.split("Y1")[1] + "TITLE"])}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },

      {
        name: LBLCURRENTSTATUS,
        selector: "task_status",
        width: "18%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div className="cls_status_container">
              <div className="cls_img_container" style={{ display: row.task_type == 1 ? "" : "none" }}>
                <img style={{ display: row.task_type == 1 ? "" : "none" }}
                  src={
                    "../images/steercom/" +
                    getStatusClass(row.task_status) +
                    ".png"
                  }
                  title={this.props.labels.labels["LBL"+ row.task_status]}
                  className="cls_img_team_status"
                  alt=""
                ></img>
              </div>

              <div
                className={
                  row.task_type == 0 || row.main_type
                    ? "sfcellbox MAINTASK" :
                    row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : ("sfcellbox " + getStatusClass(row.task_status))
                }
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                  row.task_type == 2 ? "" :
                    row.task_type == 1 ? (
                      <>
                        {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                          <>
                            <Autocomplete
                              id={"ac_task_status" + row.task_display_id}
                              disableClearable
                              options={this.state.steercomSettings.taskstatuses}
                              getOptionLabel={(option) => option.name.toString()}
                              getOptionSelected={(option, values) =>
                                option.id === values.id
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "task_status",
                                row,
                                "id"
                              )}
                              underlineStyle={{ display: "none" }}
                              defaultValue={this.state.steercomSettings.taskstatuses.find(
                                (v) =>
                                  v.id ==
                                  editingTeamSF["M_" + row.task_display_id].task_status
                              )}
                              renderInput={(params) => (
                                <TextField
                                  style={{ textAlign: "center", width: "100%" }}
                                  id={"task_status" + row.task_display_id}
                                  {...params}
                                />
                              )}
                              renderOption={(option) => (
                                <div
                                  className={
                                    option.id == null
                                      ? healthactivity["YETTOSTART"]
                                      : healthactivity[option.id]
                                  }
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    width: "150%",
                                    padding: "12px 12px 12px 6px",
                                    marginLeft: "-10px",
                                    marginBottom: "-9px",
                                    marginRight: "-10px",
                                    backgroundColor:
                                      healthactivity[option.id] == "notstarted"
                                        ? "#c8c6c6"
                                        : healthactivity[option.id] == "ontrack"
                                          ? "#7db1f5"
                                          : healthactivity[option.id] == "delayed"
                                            ? "#f5c947"
                                            : healthactivity[option.id] == "completed"
                                              ? "#79da8d"
                                              : "#c8c6c6",
                                  }}
                                >
                                  {" "}
                                  {option.name}{" "}
                                </div>
                              )}
                            />
                            <span
                              className="required"
                              id={"span_task_status_" + row.task_display_id}
                            >
                              {LBLREQUIRED}
                            </span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%",  
                              }}
                            >
                              {getRoleName(row.task_status || "YETTOSTART")}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
              </div>
            </div>
          )
        },
      },

      {
        name: LBLPCOMPLETED,
        selector: "member_enddate",
        width: "8%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{
                width: "100%",
                border: "0px solid",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <span className="cls_span_sm_text" type="text">
                            { row.completion_percentage}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ display: "flex" }}>
                          <span className="cls_span_sm_text" type="text">
                              {row.completion_percentage > 0 ?
                              ((row.completion_percentage) + "%") :
                              (row.completion_percentage)
                            }
                          </span>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },

      {
        name: LBLPLANNEDSTARTDATE,
        selector: "member_enddate",
        width: "13%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "95%" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        {getDateFormat(row.planned_start_date, row)}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },
      {
        name: LBLPLANNEDENDDATE,
        selector: "member_enddate",
        //    sortable: true,
        width: "13%",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type != 1) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "95%", textAlign: "center" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        {getDateFormat(row.planned_end_date, row)}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },
    ];
    let teamSuccessFactorColumnsAll = [
      {
        name: "",
        selector: "",
        width: "3px",
        cell: (row) => (
          <>
            <div
              style={{
                display: "inline-block",
                paddingLeft: "0px",
                position: "absolute",
                backgroundColor: "white",
                left: "0px",
                width: "72px",
                height: "52px",
              }}
            ></div>
            {row.main_type ? <div>{row.main_type}</div> :
              row.task_type == 2 ? "" :
                row.task_type == 0 ? (
                  ""
                ) : (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        paddingLeft: "0px",
                        position: "absolute",
                        border: "0px solid red",
                        top: "0px",
                        left: "8px",
                      }}
                    >
                      <div> { }</div>
                    </div>
                  </>
                )}
          </>
        ),
      },
      {
        name: LBLSF,//LBLPARENTTASK,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "250px",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.task_type == 2) {
            idx++;
          }
          if (row.main_type) {
            index = idx++;
          } 
            let task_name = "";
            task_name = this.getFactorName(row);//row.custom_task_name ? row.custom_task_name : this.getLabel("LBL" + row.parent_task_display_id);
          if (row.is_default == 0) {
            task_name = this.getFactorName(row);
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              
              {row.main_type ? <div className={"MAINTASK_" + (index)}>{row.main_type}</div> :
                row.task_type == 2 ? (
                  <div className="cls_team_name" >
                  <div className="cls_team_name_span" title={row.team_name}>
                    {LBLTEAMNAME} -  {row.team_name}
                  </div>
                </div>
                ) :
                  row.task_type == 1 ? (
                    <>
                      <div
                        style={{
                          width: "15px",
                          height: "34px",
                          border: "0px solid",
                          display: "block",
                        }}
                      >
                        <div
                          style={{
                            width: "4px",
                            height: "34px",

                            backgroundColor: "#80E3BA",
                            border: "0px solid",
                            display: "block",
                          }}
                        ></div>
                      </div>

                      <div
                        style={{
                          width: "calc(100% - 14px)",
                          border: "0px solid",
                          display: "flex",
                        }}
                      >

                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              border: "0px solid red",
                              width: "calc(100% - 12px)",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={task_name}
                              style={{
                                width: "90%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                textOverflow: "ellipsis",
                                fontWeight: "410"
                              }}
                            >
                             {task_name}
                            </div>
                          </div>

                        </>
                      </div>
                    </>
                  ) : row.task_type == 0 ?(
                    <>
                      <div
                        className={"MAINTASK_" + (index)}
                        style={{
                          // width: "100%",
                          border: "0px solid",
                          height: "100%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "8px",
                              left: "2px",
                              border: "0px solid blue",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) }
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                //textOverflow: "ellipsis",
                                fontSize: "15px",
                                fontWeight: "430",
                              }}
                            >
                            </div>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              width: "28px",
                            }}
                          >

                          </div>
                        </>
                      </div>
                    </>
                  ):""}
            </div>
          )
        },
      }, 
      {
        name: LBLTASK,
        fixedHeader: true,
        selector: "name",
        //   sortable: true,
        width: "200px",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.task_type == 2) {
            idx++;
          }
          if (row.main_type) {
            index = idx++;
          }

          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + (index)}>{row.main_type}</div> :
                row.task_type == 2 ? (
                  ""
                ) :
                  row.task_type == 1 ? (
                    <>
                      <div
                        style={{
                          width: "15px",
                          height: "34px",
                          border: "0px solid",
                          display: "block",
                        }}
                      >
                      </div>

                      <div
                        style={{
                          width: "calc(100% - 14px)",
                          border: "0px solid",
                          display: "flex",
                        }}
                      >

                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              border: "0px solid red",
                              width: "calc(100% - 12px)",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name)}
                              style={{
                                width: "90%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                textOverflow: "ellipsis",
                                fontWeight: "410"
                              }}
                            >
                              {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name)}
                            </div>
                          </div>

                        </>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={"MAINTASK_" + (index)}
                        style={{
                          border: "0px solid",
                          height: "100%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                        }}
                      >
                        <>
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "8px",
                              left: "2px",
                              border: "0px solid blue",
                              textAlign: "left",
                              overflow: "hidden",
                            }}
                          >
                            <div title={row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name)}
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                height: "24px" /* exactly 2 lines */,
                                fontSize: "15px",
                                fontWeight: "430",
                              }}
                            >
                              {row.custom_task_name?row.custom_task_name:this.getLabel(row.task_name) } 
                            </div>
                          </div>

                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              top: "3px",
                              left: "2px",
                              width: "28px",
                            }}
                          >

                          </div>
                        </>
                      </div>
                    </>
                  )}
            </div>
          )
        },
      }, 
      {
        name: LBLCURRENTSTATUS,
        selector: "task_status",
        width: "190px",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div className="cls_status_container">
              <div className="cls_img_container" style={{ display: row.task_type == 1 ? "" : "none" }}>
                <img style={{ display: row.task_type == 1 ? "" : "none" }}
                  src={
                    "../images/steercom/" +
                    getStatusClass(row.task_status) +
                    ".png"
                  }
                  title={this.props.labels.labels["LBL"+ row.task_status]}
                  className="cls_img_team_status"
                  alt=""
                ></img>
              </div>

              <div
                className={
                  row.task_type == 0 || row.main_type
                    ? "sfcellbox MAINTASK" :
                    row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : ("sfcellbox " + getStatusClass(row.task_status))
                }
                style={{ width: "100%", border: "0px solid", whiteSpace: "nowrap" }}
              >
                {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                  row.task_type == 2 ? "" :
                    row.task_type == 1 ? (
                      <>
                        {row.isNewAssigned === 1 || row.task_display_id == "NEW" ? (
                          <>
                            <Autocomplete
                              id={"ac_task_status" + row.task_display_id}
                              disableClearable
                              options={this.state.steercomSettings.taskstatuses}
                              getOptionLabel={(option) => option.name.toString()}
                              getOptionSelected={(option, values) =>
                                option.id === values.id
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "task_status",
                                row,
                                "id"
                              )}
                              underlineStyle={{ display: "none" }}
                              defaultValue={this.state.steercomSettings.taskstatuses.find(
                                (v) =>
                                  v.id ==
                                  editingTeamSF["M_" + row.task_display_id].task_status
                              )}
                              renderInput={(params) => (
                                <TextField
                                  style={{ textAlign: "center", width: "100%" }}
                                  id={"task_status" + row.task_display_id}
                                  {...params}
                                />
                              )}
                              renderOption={(option) => (
                                <div
                                  className={
                                    option.id == null
                                      ? healthactivity["YETTOSTART"]
                                      : healthactivity[option.id]
                                  }
                                  style={{
                                    textAlign: "center",
                                    fontSize: "14px",
                                    width: "150%",
                                    padding: "12px 12px 12px 6px",
                                    marginLeft: "-10px",
                                    marginBottom: "-9px",
                                    marginRight: "-10px",
                                    backgroundColor:
                                      healthactivity[option.id] == "notstarted"
                                        ? "#c8c6c6"
                                        : healthactivity[option.id] == "ontrack"
                                          ? "#7db1f5"
                                          : healthactivity[option.id] == "delayed"
                                            ? "#f5c947"
                                            : healthactivity[option.id] == "completed"
                                              ? "#79da8d"
                                              : "#c8c6c6",
                                  }}
                                >
                                  {" "}
                                  {option.name}{" "}
                                </div>
                              )}
                            />
                            <span
                              className="required"
                              id={"span_task_status_" + row.task_display_id}
                            >
                              {LBLREQUIRED}
                            </span>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div
                              style={{
                                textAlign: "center",
                                width: "100%", 
                              }}
                            >
                              {getRoleName(row.task_status || "YETTOSTART")}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
              </div>
            </div>
          )
        },
      },
      {
        name: LBLPLANNEDSTARTDATE,
        selector: "member_enddate",
        width: "120px",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "95%" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        {getDateFormat(row.planned_start_date, row)}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },
      {
        name: LBLPLANNEDENDDATE,
        selector: "member_enddate",
        //    sortable: true,
        width: "120px",
        compact: true,
        cell: (row) => {
          let index = 0;
          if (row.main_type != 1) {
            index = idx - 1;
          }
          return (
            <div
              className={row.task_type == 0 ? "sfcellbox MAINTASK" : row.task_type == 2 ? (idx % 2 == 1 ? "cls_team_title" : "cls_team_title_alter") : "sfcellbox"}
              style={{ width: "95%", textAlign: "center" }}
            >
              {row.main_type ? <div className={"MAINTASK_" + index}> </div> :
                row.task_type == 1 ? (
                  <>
                    <>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        {getDateFormat(row.planned_end_date, row)}
                      </div>
                    </>
                  </>
                ) : (
                  <></>
                )}
            </div>
          )
        },
      },
 
    ];
   teamSuccessFactorColumns  = teamSuccessFactorColumnsAll;

   //Chart Conersion;
  let loc_options1,loc_options2,loc_options3,loc_options4,loc_options5; 
  loc_options1 = this.getOptions();
  let data1 = options1.data[0].dataPoints;  
  loc_options1.series[0].chartName = options1.data[0].chartName;
  loc_options1.series[0].data.push({y:parseFloat(data1[0].y),name:data1[0].label,indexLabel:data1[0].indexLabel,chartName:data1[0].chartName}) ;
  loc_options1.series[0].data.push({y:parseFloat(data1[1].y),name:data1[1].label,indexLabel:data1[1].indexLabel,chartName:data1[1].chartName}) ;
  loc_options1.subtitles.text =  data1[0].y == '0.00'  ? "0%" : data1[0].y + "%" ; 

  loc_options2 = this.getOptions();
  let data2 = options2.data[0].dataPoints;  
  loc_options2.series[0].chartName = options1.data[0].chartName;
  loc_options2.series[0].data.push({y:parseFloat(data2[0].y),name:data2[0].label,indexLabel:data2[0].indexLabel,chartName:data2[0].chartName}) ;
  loc_options2.series[0].data.push({y:parseFloat(data2[1].y),name:data2[1].label,indexLabel:data2[1].indexLabel,chartName:data2[1].chartName}) ;
  
  loc_options3 = this.getOptions();
  let data3 = options3.data[0].dataPoints;  
  loc_options3.series[0].chartName = options3.data[0].chartName;
  loc_options3.series[0].data.push({y:parseFloat(data3[0].y),name:data3[0].label,indexLabel:data3[0].indexLabel,chartName:data3[0].chartName}) ;
  loc_options3.series[0].data.push({y:parseFloat(data3[1].y),name:data3[1].label,indexLabel:data3[1].indexLabel,chartName:data3[1].chartName}) ;
  
  loc_options4 = this.getOptions();
  let data4 = options4.data[0].dataPoints;  
  loc_options4.series[0].data.push({y:parseFloat(data4[0].y),name:data4[0].label,indexLabel:data4[0].indexLabel,chartName:data4[0].chartName}) ;
  loc_options4.series[0].data.push({y:parseFloat(data4[1].y),name:data4[1].label,indexLabel:data4[1].indexLabel,chartName:data4[1].chartName}) ;
  
  loc_options5 = this.getOptions();
  let data5 = options5.data[0].dataPoints;  
  loc_options5.series[0].data.push({y:parseFloat(data5[0].y),name:data5[0].label,indexLabel:data5[0].indexLabel,chartName:data5[0].chartName}) ;
  loc_options5.series[0].data.push({y:parseFloat(data5[1].y),name:data5[1].label,indexLabel:data5[1].indexLabel,chartName:data5[1].chartName}) ;
 
  // let xx =  options_sf;
  let stack_options = this.getOptions();
  stack_options.chart.type="bar";
  stack_options.plotOptions.series.pointWidth = 30;
  stack_options.chart.width="";
  stack_options.chart.height="300";
  let stack_data =  options_sf;
  stack_options.series = [];
 
  let locArray=[];
  let monArray=[];
  let ndx=0;

  if(stack_data.data && stack_data.data && stack_data.data.length>0){
    stack_data.data.map(item=>{
      locArray=[];
      //options_sts.series[0].data.push(item.dataPoints);
      if(item.dataPoints.length>0){
        item.dataPoints.map(itm=>{
          return locArray.push({y:itm.y,x:itm.x,name:itm.label,states:itm.state,color:itm.color});
          //locArray.push(itm.y); 
        });
        stack_options.series[ndx]={};
        stack_options.series[ndx].data=[];
        stack_options.series[ndx].name = item.name;
        stack_options.series[ndx].data = (locArray);
        ndx++;
      }
      return null;
    });
    stack_options.series.reverse();
    let categories=[],colors=[];
    if(stack_options.series.length>0){
      stack_options.series[0].data.map(itm=>{
        monArray.push(itm.label);
        colors.push(itm.color);
        categories.push(itm.label);
        return null;
      });
    }  
  }
    return (
      <div>
         { this.state.topLoading ?
         <div><Loader></Loader></div>:
         <> 
        <div className="card cls_tabs_section">
         
          <div className="cls_tabs_body">
          <span className="pageSubTitle" style={{marginLeft: "10px"}}>{LBLTEAMS}</span>
          <div className="cls_tabs_body_container">
           
            <div title={LBLBACK} style={{ display: filterTeamSummaryList.length > 23 ? "" : "none" }} onClick={this.handleTabs.bind(this, 1)} className="cls_nav_left"><i class="fas fa-angle-left"  style={{fontSize:"25px"}}></i> </div>
            {this.state.loading == false ?
              <div id="team_bars" className="row nav-pills">
                {filterTeamSummaryList && filterTeamSummaryList.length > 0 ?
                 filterTeamSummaryList.map((item, idx) => {
                    return (
                      item.parent_task_display_id == "Y1SF1M1" && item.team_status!=0 ?
                     
                        <div class="column team_bar" onClick={this.handleSelected.bind(this, item)}>
                          <img
                            src={"../images/steercom/blueshark.png"}
                            className={"cls_team_names " + (idx < 10 ? "active" : "")}
                            alt=""
                          ></img>
                           <div class="box">
                           <span title={item.team_name} className="nav-links"
                            style={{ cursor: "pointer"}}>
                               {item.team_name.length > 30 ? item.team_name.substring(0, 30) + " ..." : item.team_name}
                            </span> 
                          </div>
                          <div id={"sel_" + idx} className={idx == 0 ? "selected" : ""}></div>
                        </div>
                        
                        : ""
                    )
                  }) : <div className="cls_nodata_team"> <EmptyState image={emptyImage} text2={LBLNOTEAMFOUND}/></div>}
              </div>
              : ""}
            <div title={LBLFORWARD} style={{ display: filterTeamSummaryList.length > 23 ? "" : "none" }} onClick={this.handleTabs.bind(this, 2)} className="cls_nav_right"><i class="fas fa-angle-right" style={{fontSize:"25px"}}></i> </div>
          </div>
          </div>
        </div>
        {this.state.loading == false ?
          <div id="AdminSection">
            <div className="cls_admin_rpt_section">
              <div className="cls_adm_dsbrd_container">
                {options_sf.data[0].dataPoints.length > 0 ? (
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="pageSubTitle cls_sf_title">{LBLSF}</div>
                    </div>
                    <div className="row cls_admn_dash_inner">
                      <div className="col-sm-3 col-md-6 col-lg-4 cls_parent">
                        <div className="cls_child">
                          <HighchartsReact highcharts={Highcharts} 
                            options={loc_options1} 
                            ref={this.chart}   
                          />
                        <div className="cls_sf_name">{LBLSF1TITLE}</div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-6 col-lg-4 cls_parent" >
                        <div className="cls_child">
                        <HighchartsReact highcharts={Highcharts} 
                            options={loc_options2} 
                            ref={this.chart}   
                          />
                        <div className="cls_sf_name">{LBLSF2TITLE}</div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-6 col-lg-4 cls_parent" style={{padding: "0 10%"}}>
                        <div className="cls_child">
                        <HighchartsReact highcharts={Highcharts} 
                            options={loc_options3} 
                            ref={this.chart}   
                        />
                        <div className="cls_sf_name">{LBLSF3TITLE}</div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-6 col-lg-2"></div>
                      <div className="col-sm-3 col-md-6 col-lg-4 cls_parent cls_top_clear" >
                        <div className="cls_child">
                        <HighchartsReact highcharts={Highcharts} 
                            options={loc_options4} 
                            ref={this.chart}   
                        />
                        <div className="cls_sf_name">{LBLSF4TITLE}</div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-6 col-lg-4 cls_parent cls_top_clear" >
                        <div className="cls_child">
                        <HighchartsReact highcharts={Highcharts} 
                            options={loc_options5} 
                            ref={this.chart}   
                        />
                        <div className="cls_sf_name">{LBLSF5TITLE}</div>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-6 col-lg-2"></div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="pageSubTitle cls_sf_title">{LBLSF}</div>
                    </div>
                    <div  className="cls_nodata"><EmptyState image={emptyImage} text2={LBLPLANNORECORDS}/> {/*style={{ margin: "27% 32%" }}*/}
                      </div>
                  </>
                )}
              </div>
            </div>
            <br></br>
            <br></br>
            <div id="cls_admin_list_containers" className="cls_admin_list_container" tabIndex="-1" >
             <div id="cls_admin_list_container" tabIndex="-1"></div>
              {this.state.loadAdminList ?
                <div id="cls_admin_list"
                style={{
                  marginBottom: "0px",
                  width: "calc(100% )",
                  marginLeft: "1px",
                }}
                >

                  <div tabIndex="-1" id="cls_table_title" title={LBLTITLE + "  -  " + (team_name == "" ? teamname : team_name)} className="pageSubTitle cls_table_title">{LBLTITLE + "  -  " + (team_name == "" ? teamname : team_name)}</div>

                  <hr></hr>

                  {finalTeamSuccessFactorListData && this.state.teamSuccessFactorList.length > 0 ? (
                     
                    <DataTable
                      columns={teamSuccessFactorColumns}
                      data={finalTeamSuccessFactorListData}
                      noDataComponent={<EmptyState image={emptyImage} text2={LBLPLANNORECORDS}/>}
                     
                    />
                     
                  ) : (
                    <div className="cls_norecords" ><EmptyState image={emptyImage} text2={LBLPLANNORECORDS}/></div>//empty
                  )} 
                </div>
                : ""}
            </div>
          </div>
          :
          <Skeleton height={300} />
        }
        </> }
        
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};
export default connect(mapStateToProps, null)(SteerComAdminDashboard);

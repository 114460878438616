const required = (value) => {
  if (value && typeof value === "string" && value.trim()) {
    return undefined;
  } else if (value && typeof value === "number") {
    return undefined;
  } else {
    return "LBLREQUIRED";
  }
  // return value && value.trim() ? undefined : 'LBLREQUIRED'
};
//commenting this line for new file reference
//2nd commenting this line for new file reference
const maxLength = (max) => (value) =>
  value && value.length > max
    ? { label: `LBLMAXCHAR`, val: `${max}` }
    : undefined;
const maxLength15 = maxLength(15);
const maxLength255 = maxLength(255);
const maxLength24 = maxLength(24);
const maxLength128 = maxLength(128);
const phoneno = (value) =>
  value && !/^\d{12}$/i.test(value) ? "LBLINVALIDPHONENO" : undefined;
const number = (value) =>
  value && isNaN(Number(value)) ? "LBLMUSTBENUMBER" : undefined;
const minValue = (min) => (value) =>
  value && value.length < min
    ? { label: `LBLMUSTBEATLEAST`, val: `${min}` }
    : undefined;
const minValue8 = minValue(8);
const minValue6 = minValue(6);
const emailpatt = new RegExp(
  /^[a-zA-Z0-9._!+'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
);
const email = (value) => {
  if(value && !emailpatt.test(value.toLowerCase())){
      return "LBLINVALIDEMAIL" ;
  } else {
    return undefined;
  }
}
const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? "LBLPASSWORDSNOTMATCH" : undefined;
const checkSpace = (value) =>
  value && String(value).indexOf(" ") > -1 ? "LBLSPACENOTALLOW" : undefined;

export {
  required,
  maxLength,
  number,
  minValue,
  email,
  maxLength15,
  minValue8,
  minValue6,
  maxLength255,
  maxLength24,
  maxLength128,
  passwordsMustMatch,
  checkSpace,
  phoneno,
};

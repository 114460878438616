import React, { Component } from "react";
import "./toolbox.scss";
import { Modal } from 'react-bootstrap';
import { HttpInterceptor } from '../../../auth/HttpInterceptor';
import Loader from '../../components/loader/Loader';
import styles from "./toolbox.module.scss";

class FileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullScreen: true,
      source: null,
      fileLoaded: false,
      downloadSource: null
    };
    this.filePreview = this.filePreview.bind(this);
    this.iframeLoaderss = this.iframeLoader.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
  }

  componentDidMount = () => {
    this.filePreview();
  }

  downloadFiles() {
    let headers = new Headers({
      'responseType': 'blob'
    });
    HttpInterceptor('toolbox-files?fileName=' + this.props.popupData.file_path + this.props.popupData.file_name + '.' + this.props.popupData.file_format.toLowerCase() + '&download=' + 1, { headers, responseType: 'arraybuffer' }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/' + this.props.popupData.file_format.toLowerCase() }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', this.props.popupData.file_name + '.' + this.props.popupData.file_format.toLowerCase()); //or any other extension
      document.body.appendChild(link);
      link.click();
    }).catch(err => {
      console.log('error', err);
    })
  }

  filePreview = () => {
    HttpInterceptor('toolbox-files?fileName=' + this.props.popupData.file_path + this.props.popupData.file_name + '.' + this.props.popupData.file_format.toLowerCase()).then((res) => {
      let data = encodeURIComponent(res.data.url);
      this.setState({downloadSource: res.data.url});
      if(this.props.popupData.file_format.toLowerCase() === "mp4" || this.props.popupData.file_format.toLowerCase() === "wmv") {
        this.setState({source: res.data.url});
      } else {
        this.setState({source: process.env.REACT_APP_DOCS_VIEWER_URL + data});
        this.iframeLoaderss(process.env.REACT_APP_DOCS_VIEWER_URL + data);
      }
    }, (err) => {
      console.log(err)
    })
  }

  iframeLoader = (url) => {
    let iframe = document.getElementById("fileFrame");
    this.ift = setInterval(() => {
      if (iframe && !iframe.contentDocument) {
        clearInterval(this.ift)
      } else {
        if (document.getElementById("fileFrame")) {
          document.getElementById("fileFrame").src = url;
        }
      }
      if (!document.getElementById("fileFrame")) {
        clearInterval(this.ift)
      }
    }, 2000);
  }

  backHandler = (propss) => {
    this.setState({source: null});
    propss.closePopup();
    clearInterval(this.ift);
  }

  render() {
    return (
      <div className="tb-popup-container">
        <Modal className="tb-modal-popup" show={this.props.show} fullscreen={this.state.fullScreen.toString()} animation={false}>
          <Modal.Header>
            <div className="popup-header">
              <div className="pp-fname">
                <i
                  className="fas fa-arrow-left cu-back-btn"
                  aria-hidden="true"
                  onClick={() => this.backHandler(this.props)}
                  title={this.props.labels.labels.LBLBACK}
                ></i>
                <img
                  src={`${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/format_image/"}${
                    this.props.popupData.file_format.toLowerCase() === "pdf"
                      ? "Pdf_icon.png"
                      : this.props.popupData.file_format.toLowerCase() === "docx"
                      ? "Doc_icon.png"
                      : this.props.popupData.file_format.toLowerCase() === "xls"
                      ? "Excel_icon.png"
                      : this.props.popupData.file_format.toLowerCase() === "xlsx"
                      ? "Excel_icon.png"
                      : this.props.popupData.file_format.toLowerCase() === "ppt"
                      ? "PPT_icon.png"
                      : this.props.popupData.file_format.toLowerCase() === "mp4"
                      ? "Video_icon.png"
                      : this.props.popupData.file_format.toLowerCase() === "wmv"
                      ? "Video_icon.png"
                      : "Image_icon.png"
                  }`}
                  alt={this.props.popupData.file_format.toLowerCase()}
                  style={{ width: "18px", height: "18px", display: "flex", alignItems: "center", marginRight: 10, marginLeft: 10 }}
                />
                <div>{this.props.popupData.file_name}</div>
              </div>
              <div className="download" onClick={this.downloadFiles}>
                <a className="dload-icn" href="javascript:void(0)" title={this.props.labels.labels.LBLDOWNLOAD}>
                  <img src={`${process.env.REACT_APP_RESOURCES_URL}${"appasset/images/ToolBox/download-30x30.png"}`} alt={this.props.labels.labels.LBLDOWNLOAD}></img>
                </a>
              </div>             
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="popup-body">
              <div className="source-area">
                {!this.state.fileLoaded ? <Loader></Loader> : null}
                {this.state.source ?                   
                  <iframe id="fileFrame" src={this.state.source} width="100%" height={this.state.fileLoaded ? "100%" : "90%"} onLoad={() => this.setState({fileLoaded: true})} title={this.props.popupData.file_format.toLowerCase()} />
                  : null
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default (FileView);
import React, { forwardRef, useState } from "react";
import FilterUsers from "../../../common_components/filter/FilterUsers";
import { useEffect } from "react";
const $ = require("jquery");

const FilterComponent = forwardRef((props,ref) => {
    //console.log("filter component called",props);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [showFilterSideMenu, setShowFilterSideMenu] = useState(true)
    const [showFilterMobile, setShowFilterMobile] = useState(false);
    const [toFilters, setToFilters] = useState(props.toFilters);
    const [singleDropDown, setSingleDropDown] = useState(false);
    const [openDropDown, setOpenDropDown] = useState([]);
    const [selectedMultiArrIds, setSelectedMultiArrIds] = useState([]);
    const [selectedMultiArrNames, setSelectedMultiArrNames] = useState([]);
    const [allFilterValues, setAllFilterValues] = useState([]);
    const [singleStatusChecked, setSingleStatusChecked] = useState(false);
    const [visible, setVisible] = useState("none");
    const [singleItemStatus, setSingleItemStatus] = useState(null);
    const [currentId, setCurrentId] = useState("");
    const [searchValue, setSearchValue] = useState("");
    
    useEffect(()=>{
      //console.log("clear filterssssss",selectedMultiArrIds, props.selectedMultiArrIds);
      if(selectedMultiArrIds !== props.selectedMultiArrIds) {
        setSelectedMultiArrIds(props.selectedMultiArrIds);
        setSelectedMultiArrNames(props.selectedMultiArrNames);
        setAllFilterValues(props.allFilterValues);
      }
    }, [props.selectedMultiArrIds])
    //console.log("filtercomp selectedMultiArrIds",props.selectedMultiArrIds)
    const openFilterModal = () => {
      if (width < 720) {
        setShowFilterMobile(!showFilterMobile);
      } else {
        if ($(".user-list-filter").css("display") === "none") {
          setShowFilterSideMenu(true);
          $('#filterComp').show();
          props.showHideFilter(true);
          // $(".user-list-filter").css({
          //   display: "flex",
          //   float: "left",
          //   width: "25%",
          //   position: "relative",
          //   right: "30px",
          //   transition: "all 1s ease",
          // });
          // $(".user-list-table-container").css({ overflow: "hidden" });
        } else {
          props.showHideFilter(false);
          setShowFilterSideMenu(false);
          $('#filterComp').hide();
          // $(".user-list-filter").css({
          //   display: "none",
          //   transition: "opacity 1s ease-out",
          // });
        }
      }
    };
    const closeFilterModal = () => {
      $(".modal-backdrop").css({ position: "relative" });
      setShowFilterMobile(false);
    };
    const handleOpenDropDown = (index) => {
      let newArr = [...openDropDown];
      if (newArr.indexOf(index) === -1) {
        newArr.push(index);
      } else {
        newArr.splice(newArr.indexOf(index), 1);
      }
      setOpenDropDown(newArr);
    };
    const handleStatusFilter = (id) => {
      setSingleStatusChecked(singleStatusChecked === id ? null : id);      
    };
    const resetFilterValues = () => {
      //console.log("reset values");
      setSingleStatusChecked(null);
      setSelectedMultiArrIds([]);
      setSelectedMultiArrNames([]);
      setVisible('none');
      setOpenDropDown([]);
      setSingleDropDown(false);
      setAllFilterValues([]);
      setSingleItemStatus(null);      
      props.resetFilterValues();
    };
    const applyFilters = (filter) => {
      //console.log("apply filters",filter);
      setAllFilterValues(filter);
      if (!filter.reset) {
        setVisible("block");
      }
      $(".bounceInDown").hide();
      $(".modal-backdrop").css({ position: "relative" });
      $(".modal-backdrop.show").css({ opacity: "0" });
    };

    useEffect(()=> {
      props.filterChange(allFilterValues);
    }, [singleDropDown, singleStatusChecked, selectedMultiArrIds, selectedMultiArrNames, currentId, singleItemStatus])
    
    return (
        <div className="table-filter classroom-tab" style={{ alignItems: "center" }}>          
            <div className="user-list-filter" style={{ display: "block" }}>
              <FilterUsers
                openFilterModal={openFilterModal}
                toFilters={toFilters}
                setSingleDropDown={() =>
                  setSingleDropDown(!singleDropDown)                  
                }
                showSingleDropDown={singleDropDown}
                handleStatusFilter={(id) => handleStatusFilter(id)}
                handleOpenDropDown={(index) => handleOpenDropDown(index)}
                openDropDown={openDropDown}
                singleStatusChecked={singleStatusChecked}
                labels={props.labels}
                handleSubmit={applyFilters}
                resetValues={resetFilterValues}
                selectedMultiArrIds={selectedMultiArrIds}
                selectedMultiArrNames={selectedMultiArrNames}
                setSelectedMultiArrIds={(ids) => {
                    setSelectedMultiArrIds(ids);
                    props.setSelectedMultiArrIds(ids);
                  }
                }
                setSelectedMultiArrNames={(name) => {
                    setSelectedMultiArrNames(name);
                    props.setSelectedMultiArrNames(name);
                  }
                }
                setCurrentId={(id) => setCurrentId(id)}
                currentId={currentId}
                prevFilterValues={allFilterValues}
                singleItemStatus={(status) =>
                  setSingleItemStatus(status)
                }
                currentPage = {"recordCompletion"}
              />
            </div>          
          </div>
    )
});
export default FilterComponent;
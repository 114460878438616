

class SCUtils {


    validateFileName(fileName) {
        console.log(fileName);
        let newFileName = this.removeFileTypeFromName(fileName);
        let specialCharRegExp = /[!@#$%^&*(),.?":{}|<>]/g;
        console.log(newFileName);

        let haveSpecialChars = newFileName.match(specialCharRegExp);
        console.log(haveSpecialChars);

        if (haveSpecialChars && haveSpecialChars.length > 0) {
            return true;
        } else {
            return fileName;
        }

    }
    removeFileTypeFromName(fileName) {
        let splitName = fileName.split('.');
        splitName.pop();
        return (splitName.join())
    }
    generateFeedsPostFileName(fileName) {
        let splitName = fileName.split('.');
        let splitType = splitName[splitName.length - 1];
        let newFileName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + '_' + new Date().getTime() + '.' + splitType;
        return (newFileName)
    }
    convertStringtoInterger = (vString) => {
        return parseInt(vString);
    }

    isActivityEnabled = (vDBActivityEnabled) => {
        vDBActivityEnabled = vDBActivityEnabled === undefined ? 0 : vDBActivityEnabled;
        // return this.convertStringtoInterger(process.env.REACT_APP_ACTIVITY_ENABLED) === 1 
        // &&
        return this.convertStringtoInterger(vDBActivityEnabled) === 1 ? true : false;
    }


    findEmptyObjectFromObject = (obj) => {
        let validator = true;
        for (let i = 0; i < Object.keys(obj).length; i++) {
            if (!obj[Object.keys(obj)[i]] || (/^ *$/.test(obj[Object.keys(obj)[i]]))) {
                validator = false;
            }
            if (i + 1 === Object.keys(obj).length) {
                return validator
            }
        }
    }

    filePath = (roleid,componentName) => {
        console.log(roleid,componentName ,"utils")
        let pathName  = ""
        if(roleid === 1) {
            console.log("11111111111111")
            pathName = "/schome/"
        } else {
            console.log("2222222222222222")
            pathName = "/home/"
        }
        console.log(pathName + componentName,"pathName + componentName")
        return pathName + componentName;
    }

    convertToHoursAndMinutes = (totalMinutes,labels) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        
        if (hours === 0) {
            return `${minutes} ${labels?.LBLMIN}`;
        } else if (minutes === 0) {
            return `${hours} ${labels?.LBLHOUR}`;
        } else {
            return `${hours}${labels?.LBLHOUR} ${minutes}${labels?.LBLMIN}`;
        }
    };
}

export default new SCUtils();

import React, { useState, useEffect } from "react";
import unitsicon from "../../../assets/images/unitsicon.png";
import langicon from "../../../assets/images/languageicon.png";
import avgtimeicon from "../../../assets/images/avgtimeicon.png";
import skillsgaintickicon from "../../../assets/images/skillsgaintickicon.png";
import styles from "./AboutProduct.module.scss";
import ScCommonData from "../../../services/common_data";
import utils from "../../../utils/utils";

const AboutTabs = (props) => {
    const [productDetails, setProductDetails] = useState({});

    // const Languages = ScCommonData.getLanguages();

    useEffect(() => {
        setProductDetails(props.productDetails);
    }, []);

    // const getLanguage = (xvalue) => {
    //     var xObj = {};
    //     let dText = "";
    //     xObj =
    //         Languages[
    //         Object.keys(Languages).find(
    //             (d, key) => Languages[d].code === xvalue
    //         )
    //         ];
    //     dText = xObj?.name;
    //     return dText;
    // };
    
    return (
        <div style={{paddingLeft:"10px"}}>
            {/* <div className={styles.feedbackCtn}>
                <img src={unitsicon} />
                <span className={styles.unitsLangText}>{props.unitsCount}{' '}{localStorage.getItem('site_type') === "Accutrain" ?  props.labels.LBLMODULES : props.labels.LBLUNITS }</span>
                <img src={avgtimeicon} />
                <span className={styles.unitsLangText}>{utils.convertToHoursAndMinutes(productDetails?.productEstimationTime ? productDetails?.productEstimationTime : 0, props.labels)}</span>
                <img src={langicon} />
                <span className={styles.unitsLangText}>{getLanguage(productDetails?.languageCode)}</span>
                <div style={{ display: "flex" }}>
                </div>
            </div> */}
            {productDetails?.productAbout !== "" ?
                <>
                    <h3 className={styles.pageSubTitle}>
                        {props.labels.LBLABOUTCOURSE }
                    </h3>
                    <p className={styles.aboutPageDesc}>{productDetails.productAbout}</p>
                </>
                :
                null
            }
            {productDetails?.skillsGain?.length > 0 ?
                <>
                    <h3 className={styles.pageSubTitle}>
                        {props.labels.LBLSKILLSGAIN !== undefined ? props.labels.LBLSKILLSGAIN : "Skills you'll gain"}
                    </h3>
                    <div className={styles.aboutPageDesc}>
                        {productDetails?.skillsGain && productDetails.skillsGain.map((item, index) => (
                            <ul key={index}>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                    <img src={skillsgaintickicon} alt='' style={{ marginRight: "10px" }} />
                                    <li>{item.text}</li>
                                </div>
                            </ul>
                        ))}
                    </div>
                </>
                :
                null
            }
            {productDetails?.authorDescription !== "" ?
                <>
                    <h3 className={styles.pageSubTitle}>
                        {props.labels.LBLABOUTTHEPRESENTER !== undefined ? props.labels.LBLABOUTTHEPRESENTER : "About the presenter"}
                    </h3>
                    <div className={styles.aboutPresenterCtn}>
                        <div className={styles.authorImageCtn}>
                            <img src={`${process.env.REACT_APP_CRSENGINE2_IMAGEURL}image/${productDetails?.productId}/${productDetails?.presenterImage}`} alt="" className={styles.authorImage} />
                            <div>
                                <div className={styles.pageSubTitle}>
                                    {productDetails.authorName}
                                </div>
                                <div className={styles.role}>
                                    {productDetails.role}
                                </div>
                            </div>
                        </div>
                        <div className={styles.authorDes}>
                            {productDetails.authorDescription && productDetails.authorDescription.includes("~**~") ? (
                                productDetails.authorDescription.split("~**~").map((item, index) => (
                                    <ul key={index}>
                                        <li>{item}</li>
                                    </ul>
                                ))
                            ) : (
                                <ul>
                                    <li>{productDetails.authorDescription}</li>
                                </ul>
                            )}
                        </div>

                    </div>
                </>
                :
                null
            }
        </div>
    )
}

export default AboutTabs;
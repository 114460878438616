import React from "react";
import { Modal } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import preSurveyResponse from "./Survey.json";
import yfFeedback from "./yfFeedback.json";
import yfCertFeedback from "./yfCertFeedback.json"
import actFeeback from "./actFeeback.json";
import postSurveyResponse from "./PostSurvey.json";
import styles from "./Survey.module.scss";
import QuestionBoard from "./boards/Question";
import SliderBoard from "./boards/SliderBoard";
import survey_services from "./survey_services";
import { useSelector,useDispatch  } from 'react-redux';
import userInfo from "../../src/redux/action";
const $ = require("jquery");
let answeredArr = [];
let response = {};
const Survey = (props) => {
  const [surveyResponseData, setSurveyResponseData] = useState({});
  const [modifiedResponse, setModifiedResponse] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answeredQuestion, setAnsweredQuestion] = useState(0);
  const [answeredQuestionPercet, setAnsweredQuestionPercet] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [currentBoardType, setCurretBoardType] = useState(null);
  const [labels, setLabels] = useState({});

  const optionsRef = useRef(null);

  const userDetails = useSelector(state => state.user_info_reducer.user_info) ;
  const dispatch = useDispatch();
  useEffect(() => {
    console.log('fetch data in surveyjs useeffect')
    const fetchData = async () => {
      console.log('fetch data in surveyjs')
      try {
        const url = `${process.env.REACT_APP_YOUFACTORS_TRANSLATION_URL}safestartsurvey/safestartsurvey.json`;
        const response = await fetch(url, { cache: "force-cache" });

        if (response.status === 200) {
          const data = await response.json();
          if(props.surveyType === "feedback"){
            setLabels(data[props?.languagecode] ? data[props?.languagecode].labels : data["en-US"].labels);
          } else {
            setLabels(props?.languagecode ? data[props?.languagecode] : data[userDetails.language_code !== '...' ? userDetails.language_code : "en-US"].labels);
          }
        } else {
          console.log("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if(props.launchType) {
      $(".modal-backdrop.show").css({ 'background-color': "#FFF", 'opacity': 1  });
      setLabels(props.labels);      
    }
    else {
      fetchData();
    }    
  }, []);
  useEffect(()=> {
    if(props.launchType) {
      setLabels(props.labels);
    }
  },[props.labels])
  
  const loadFeedBackJson = () => {
    if(props.feedback_file_name === "yfCertsFeedback.json"){
      return yfCertFeedback;
    }else if(props.feedback_file_name === "yfFeedback.json"){
      return yfFeedback;
    }else{
      return actFeeback;
    }
  }
  
  const jsonResponse = () => {
    return props.surveyType === "pre" ? preSurveyResponse : props.surveyType === "post" ? postSurveyResponse : loadFeedBackJson();
  }
  useEffect(() => {
    let Obj = Object.assign({}, JSON.parse(JSON.stringify(jsonResponse())));
    setSurveyResponseData(Obj);
    setModifiedResponse(Obj);
    for(let i = 0 ; i < Obj?.preSurvey?.length; i++){
      answeredArr.push(0);
    }
    return () => {
      setSurveyResponseData({});
      setModifiedResponse({});
    }
  }, []);

  useEffect(() => {
    if (modifiedResponse?.preSurvey?.length !== 0) {
      let completed = parseInt((answeredQuestion * 100) / modifiedResponse?.preSurvey?.length);
      setAnsweredQuestionPercet(completed);
    }
  }, [answeredQuestion, modifiedResponse]);

  useEffect(() => {
    if (modifiedResponse?.preSurvey?.length === answeredArr.length) {
      if (nextEnabled) {
        const newArr = [...answeredArr];
        newArr[currentIndex] = 1;
        answeredArr = newArr;
      } else {
        let newArr = [...answeredArr];
        newArr[currentIndex] = 0;
        answeredArr = newArr;
      }
      getUpdatedCount()
    }
  }, [nextEnabled, currentIndex]);

  useEffect(() => {
    getCurrentBoardType();
  }, [currentIndex, surveyResponseData]);

  const getUpdatedCount = () => {
    let count = 0;
    if (answeredArr.length > 0) {
      for (let i = 0; i < answeredArr.length; i++) {
        if (answeredArr[i] === 1) {
          count++;
        }
      }
    }
    setAnsweredQuestion(count);
  };

  const getCurrentBoardType = () => {
    if (modifiedResponse?.preSurvey?.length > currentIndex) {
      const boardType = modifiedResponse.preSurvey[currentIndex].boardType;
      setCurretBoardType(boardType);
    }
  }

  useEffect(() => {
    if (currentIndex !== 0) {
      let selectedAns = optionsRef?.current?.getAnswerDetails();
      const updatedOption = modifiedResponse?.preSurvey?.map((item, index) => {
        if (index === currentIndex && selectedAns) {
          // If the index matches the currentIndex, update the content value
          return {
            ...item,
            content: selectedAns // Update the content with the provided value
          };
        } else {
          return item;
        }
      })

      setModifiedResponse(prevState => ({
        ...prevState,
        preSurvey: updatedOption
      }));
    }
  }, [currentIndex])

  const handleNext = () => {
    if (currentIndex < modifiedResponse.preSurvey.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
    return modifiedResponse?.preSurvey?.map((item, index) => { 
       if(item.boardType && item.boardType.toUpperCase() === "SLIDER" && currentIndex === 0){
        if (modifiedResponse?.preSurvey?.length === answeredArr.length) {
            const newArr = [...answeredArr];
            newArr[currentIndex] = 1;
            answeredArr = newArr;
          getUpdatedCount()
        }
       }
    })
  };

  const handleSubmit = () => {
    let resObj = [];
     
    if ((currentIndex + 1) === (modifiedResponse?.preSurvey?.length)) {
      modifiedResponse?.preSurvey.forEach((objmod, modindex) => {
        objmod.content.forEach((questions, questionindex) => {
          if (questions.type === "options") {
            questions?.option?.forEach((item, index) => {
              if (item.userAnswer === true) {
                let xresObj = '"' + item.sid + '":{"qid":"' + objmod.id + '","vr":1.0,"ovl":1}';
                resObj.push(xresObj);
              }
            });
          } else if (questions.type === "textarea"  ) {
            let xresObj = '"' + questions.sid + '":{"qid":"' + objmod.id + '","vr":1.0,"ovl":"' + questions.text + '"}';
            resObj.push(xresObj);
          } else if (questions.type === "range" && questions.text !== ""){
            let xresObj = '"' + questions.sid + '":{"qid":"' + objmod.id + '","vr":1.0,"ovl":' + questions.text + '}';
            resObj.push(xresObj);
          }
        });
      });

      let response_data = "{" + resObj.join(",") + "}";
      let datenew = new Date();
      let data = {
        survey_id: props.surveyId,
        launch_type: props.surveyType,
        language_code: userDetails && userDetails.language_code !== "..." ? userDetails.language_code : props.language_code,
        response_data: response_data,
        time_stamp: datenew,
        launch_data:props?.launch_data
      };
      let user_id = userDetails && userDetails.user_id !== "..." ? userDetails.user_id : props.user_id;
      if(props.launchType === "remote"){
          data = {
            ...data,
            user_id: props.user_id
          }
      }
      if(props.launchType === "remote"){
      survey_services.updateSurveyRemote(data)
      props.hide(false); 
      if(props.surveyType === "pre"){
        props.showToastStatus(true);
      }else if(props.surveyType === "post"){
        props.handleSurveyCompletion();
      }}else{
        if(props.surveyType === "pre"){  
        survey_services.updatesurvey(user_id, data)
          .then((response) => {
             var vParams = {
              userid: user_id,
            };
              survey_services.getsurveyStatus(vParams)
                .then((res) => {
                  dispatch(userInfo({ ...userDetails, survey: res.data.survey_id,showsurvey:false,skipnow:false}));
                })
                .catch((error) => {
                  console.error("Error while fetching survey status:", error);
                });    
              props.hide(false); 
              props.showToastStatus(true);
          })
          .catch((error) => {
            console.log("Error while updating survey:", error);
          });
        } else if(props.surveyType === "feedback") {
          survey_services.updatefeedback(user_id, data)
          .then((response) => {  
            console.log("feedbacksucess",response);
            props?.handleFeedbackCompletion(true);
            props.hide(false);
          })
        } else {
          survey_services.updatepostsurvey(user_id, data)
          .then((response) => {
            console.log("update post survey success:", response);
              props.hide(false);
              props.handleSurveyCompletion();
          })
          .catch((error) => {
            console.log("Error while updating post survey:", error);
          });
        }
      }
    }
    answeredArr = [];
  };
  
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextEnabled = (value) => {
    setNextEnabled(value);
  }
  const buildStoryboard = () => {
    return modifiedResponse?.preSurvey?.map((item, index) => {
      if (index === currentIndex) {
        return (
          <div key={index} className={styles.boardWrapper}>
            {item.boardType && item.boardType.toUpperCase() === "QUESTION" ? (
              <QuestionBoard
                key={index}
                content={item.content}
                questionType={item.questionType}
                ref={optionsRef}
                handleNextEnabled={handleNextEnabled}
                currentIndex={currentIndex}
                labels={labels}
                handleNext={handleNext}
              />
            ) : item.boardType && item.boardType.toUpperCase() === "SLIDER" ? (
              <SliderBoard
                key={index}
                content={item.content}
                questionType={item.questionType}
                ref={optionsRef}
                handleNextEnabled={handleNextEnabled}
                currentIndex={currentIndex}
                labels={labels}
              />
            ) : null}
          </div>
        );
      }
      return null;
    });
  };

  const handleClose = () => {
    answeredArr = [];
    setAnsweredQuestion(0);
    setCurrentIndex(0);
    props.hide(false);
    if(props.surveyType === "pre"){
      dispatch(userInfo({ ...userDetails,showsurvey:false,skipnow:true}));
    }
  };

  console.log(answeredQuestionPercet,answeredArr,"answeredQuestionPercet")

  const title = () => {
    if(props.surveyType === "pre"){
      return props.labels.LBLPRESURVEY  !== undefined ? props.labels.LBLPRESURVEY : "Pre Survey";
    }else if(props.surveyType === "feedback"){
      return props.labels.LBLCOURSEFEEDBACK  !== undefined ? props.labels.LBLCOURSEFEEDBACK : "Course Feedback"
    } else {
      return props.labels.LBLPOSTSURVEY ? props.labels.LBLPOSTSURVEY : 'Post Survey';
    }
  };

  const handleNextDisable = () => {
    if( (currentIndex + 1) === (modifiedResponse?.preSurvey?.length)){
      return true;
    }else if(currentBoardType !== "slider" && !nextEnabled){
      return true;
    }else{
      return false;
    }
  };

  const handleOkButton = () => {
    return modifiedResponse?.preSurvey?.map((item, index) => {
      if (index === currentIndex) {
        if (
          (item.questionType === "single" &&
          (currentIndex + 1) === modifiedResponse?.preSurvey?.length)
        ) {
          return (
            <button
              className="button-style primary-btn"
              onClick={handleSubmit}
              disabled={currentBoardType !== "slider" ? !nextEnabled : false}
              style={{ marginTop: "0.5rem", marginLeft: "20px" }}
            >
              {props.labels?.LBLSUBMIT}
            </button>
          );
        } else if(item.questionType !== "single"){
          return (
            <button
              className="button-style primary-btn"
              onClick={(currentIndex + 1) === modifiedResponse?.preSurvey?.length ? handleSubmit : handleNext}
              disabled={currentBoardType !== "slider" ? !nextEnabled : false}
              style={{ marginTop: "0.5rem", marginLeft: "20px" }}
            >
              {(currentIndex + 1) === modifiedResponse?.preSurvey?.length ? props.labels?.LBLSUBMIT : props.labels?.LBLOK}
            </button>
          );
        }
      }
      return null;
    });
  };
  
  return (
    <>
      {Object.keys(labels).length > 0 ? (
        <Modal
          id={props.launchType ? 'remoteSurvey' : 'survey'}
          show={true}
          onHide={handleClose}
          size="lg"
          backdrop="static"
          keyboard={false}
          className={props.launchType ? styles.remoteSurvey : ''}
          // onKeyDown={handleKeyDown}
        >
          <Modal.Header closeButton={props.closeButton} className='alert-modal-header'>
            <Modal.Title className="alert-modal-title">
              {title()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.customModalContent}>
          <div className={styles.progressBarWrapper}>
              <div className={styles.progressContainer}>
                <div className={styles.progressBar} style={{ width: answeredQuestionPercet + "%" }}></div>
              </div>
                <div className={styles.currentTotalBoardCount}>
                {`${currentIndex + 1} of ${modifiedResponse?.preSurvey?.length}`}
              </div>
            </div>
            <div style={{ marginLeft: "20px" }}>
              {buildStoryboard()}
            </div>
            <div>{handleOkButton()}</div>
          </Modal.Body>
          <Modal.Footer className={styles.cutomModalFooter}>
            <div>
              <button className={`button-style secondary-btn`} style={{ padding: "10.5px,15px", marginRight: "10px" }} onClick={handlePrevious} >
                {props.labels?.LBLPREVIOUS}
              </button>
              <button className="button-style secondary-btn" onClick={handleNext} disabled={handleNextDisable()}>
                { props.labels?.LBLNEXT }
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null
      }
    </>
  );

}

export default Survey;
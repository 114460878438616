import { useState } from "react";
import "./style.scss";
import DataTable from "react-data-table-component";
const Accordian2 = (props) => {
  const { item } = props;
  
  const columns = [
    {
      name: props.labels.LBLCAPSULENAME,
      selector: (row) => row.c_name,
      width: "35%",
      sortable: true,
      cell: (row) => {
        return (
          <div className='hde-user-icn' data-tag="allowRowEvents">
            <span className="rle-brdr" title={row.c_name} data-tag="allowRowEvents" >
              {row.c_name}
            </span>
          </div>
        );
      }      
    },
    {
      name: props.labels.LBLRELEASETYPE,
      selector: (row) => row.rel_ty,
      width: "15%",
      sortable: true,
      cell: (row) => {
        return (
          <div className='hde-user-icn' data-tag="allowRowEvents">
            <span className="rle-brdr" data-tag="allowRowEvents" title={props.labels["LBL" + row.rel_ty.toUpperCase() + "COMPLETION"]} >
              {props.labels["LBL" + row.rel_ty.toUpperCase() + "COMPLETION"]}
            </span>
          </div>
        );
      }
    },
    // row.rel_ty
    {
      name: props.labels.TIMEINTERVAL,
      selector: (row) => row.tm_intr,
      width: "12%",
      sortable: true,
      center: true,
      cell: (row) => {
        return (
          <div className='hde-user-icn' data-tag="allowRowEvents">
            <span className="rle-brdr" data-tag="allowRowEvents" >
            {row.tm_intr} {props.labels.LBLDAYS}
            </span>
          </div>
        );  
      }
    },
    {

      name: props.labels.LBLNUMBEROFUSERS,
      selector: (row) => row.n_lrn,
      width: "10%",
      sortable: true,
      center: true
    },
    {

      name: props.labels.LBLRELEASED,
      selector: (row) => row.rel,
      width: "10%",
      sortable: true,
      center: true
    },
    {
      name: props.labels.LBLCOMPLETED,
      selector: (row) => row.compl,
      width: "9%",
      sortable: true,
      center: true
    },
    {
      name: props.labels.LBLNOTSTARTED,
      selector: (row) => row.nt_str,
      width: "9%",
      sortable: true,
      center: true
    },
  ];
  return (
    <div className="accordian-data-table">
      <DataTable columns={columns} data={item} onRowClicked={(item) => props.onClicked(item)} highlightOnHover  />
    </div>
  );
};
export default Accordian2;

import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
//import { required, maxLength15, minValue6, passwordsMustMatch } from "../validate/Validate";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ScCommonData from "../../../services/common_data";

class RegionalSettingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      user_currency: this.props.props.currency,
      user_timezone: { value: this.props.props.timezone },
      user_language: { name: this.props.props.language, code: this.props.props.language_code },
      user_dateformat: { name: this.props.props.dateformat, code: this.props.props.dateformat },
      user_timeformat: { code: this.props.props.timeformat },
    };
    console.log("user_timezone", this.state.user_timezone)
    console.log("user_language", this.state.user_language)
    console.log("user_dateformat", this.state.user_dateformat)
    console.log("user_timeformat", this.state.user_timeformat)
    
  }

  getValue(x) {
    return this.state[x] || "No";
  }


  onSubmit = () => {
    let drpDownValues = {
      user_language: this.state.user_language,
      user_timezone: this.state.user_timezone,
      user_dateformat: this.state.user_dateformat,
      user_timeformat: this.state.user_timeformat,
    };
    this.props.handleSubmit(drpDownValues);
  }

  render() {
    console.log("papap", this.props.props)
    const {  submitting, labels } = this.props;
    const TimeZones = ScCommonData.getTimeZones();
    const Languages = ScCommonData.getLanguages();
    const DateFormats = ScCommonData.getDateFormats();
    const TimeFormats = ScCommonData.getTimeFormats();
    const DatenewFormats = ScCommonData.getDatenewFormats();
    const TimenewFormats = ScCommonData.getTimenewFormats();

    let user_timezone = TimeZones && TimeZones.filter(j => j.value === this.state.user_timezone.value);
    user_timezone = user_timezone[0]
    let user_language = Languages && Languages.filter(j => j.code === this.state.user_language.code);
    user_language = user_language[0]
    let user_timeformat = TimenewFormats && TimenewFormats.filter(j => j.code === this.state.user_timeformat.code)
    user_timeformat = user_timeformat[0]
    console.log("AAAAAA", this.state.user_timezone)
    console.log("user_timeformatuser_timeformat", this.state.user_timeformat)


    const {
      LBLCHNGREGIONALSETT,
      LBLINCORRECTALERT,
      LBLGOTOLOGIN,
      LBLCANCEL,
      LBLSUBMIT,
      LBLLANGUAGE,
      LBLTIMEZONE,
      LBLDATEFORMAT,
      LBLTIMEFORMAT,
      LBLNOOPTIONSDROPDOWN
    } = labels;

    console.log("regional", Languages)
    console.log("TimeZones", TimeZones)
    console.log("DateFormats", DateFormats)
    console.log("TimeFormats", TimeFormats)


    return (
      <div>
        <Modal.Header closeButton className='alert-modal-header'>
          <Modal.Title className="alert-modal-title">
              {LBLCHNGREGIONALSETT}
          </Modal.Title>
        </Modal.Header>
        <form className="form"

        >
          <Modal.Body>
            <div className="modal-body">
              <div className="form-group">

                <label>{LBLLANGUAGE}</label>

                <Autocomplete
                  id="combo-box-demo"
                  aria-required="true"
                  required
                  noOptionsText={LBLNOOPTIONSDROPDOWN}
                  disableClearable
                  options={Languages}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, values) =>
                    option.name === values.name
                  }
                  value={user_language}
                  defaultValue={user_language}
                  onChange={(e, i) => {
                    console.log("LAN", i, i.code)
                    this.setState({
                      user_language: i,
                    });
                    this.props.change("user_language", i);
                  }}
                  renderOption={(option) => (
                    <div style={{ fontSize: "14px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <div>
                      <TextField

                        {...params}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="form-group">
                <label>{LBLTIMEZONE}</label>
                <Autocomplete
                  id="combo-box-demo"
                  aria-required="true"
                  required
                  disableClearable
                  noOptionsText={LBLNOOPTIONSDROPDOWN}
                  options={TimeZones}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, values) =>
                    option.value === values.value
                  }
                  value={user_timezone}
                  defaultValue={user_timezone}
                  onChange={(e, i) => {
                    console.log("TZ", i)
                    this.setState({
                      user_timezone: i,
                    });
                    this.props.change("user_timezone", i);
                  }}
                  renderOption={(option) => (
                    <div style={{ fontSize: "14px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                      />
                    </div>
                  )}
                />

              </div>
              <div className="form-group">

                <label>{LBLDATEFORMAT}</label>
                <Autocomplete
                  id="combo-box-demo"
                  aria-required="true"
                  required
                  disableClearable
                  noOptionsText={LBLNOOPTIONSDROPDOWN}
                  options={DatenewFormats}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, values) =>
                    option.code === values.code
                  }
                  value={this.state.user_dateformat}
                  defaultValue={this.state.user_dateformat}
                  onChange={(e, i) => {
                    console.log("DFF", i)
                    this.setState({
                      user_dateformat: i,
                    });
                    this.props.change("user_dateformat", i);
                  }}
                  renderOption={(option) => (
                    <div style={{ fontSize: "14px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                      />
                    </div>
                  )}
                />

              </div>
              <div className="form-group">

                <label>{LBLTIMEFORMAT}</label>
                {/* <b>{LBLTIMEFORMAT}</b> */}

                <Autocomplete
                  id="combo-box-demo"
                  //className="form-control role-change-drp"
                  aria-required="true"
                  required
                  disableClearable
                  noOptionsText={LBLNOOPTIONSDROPDOWN}
                  options={TimenewFormats}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, values) =>
                    option.code === values.code
                  }
                  value={user_timeformat}
                  defaultValue={user_timeformat}
                  onChange={(e, i) => {
                    console.log("TimeF", i)
                    this.setState({
                      user_timeformat: i,
                    });
                    this.props.change("user_timeformat", i);
                  }}
                  renderOption={(option) => (
                    <div style={{ fontSize: "14px" }}>{option.name}</div>
                  )}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.message !== null ? (
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                  {this.props.message === "2" ? (
                    <label>{LBLINCORRECTALERT}</label>
                  ) : this.props.message === "1" ? (
                    <Link to="/">
                      <a href="#">
                        <i className="fas fa-id-badge"></i>
                        {LBLGOTOLOGIN}
                      </a>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.props.message === "1" ? (
              ""
            ) : (
              <React.Fragment>
                {" "}
                <button
                  type="button"
                  className="button-style secondary-btn"
                  id="btnaddCancel"
                  onClick={this.props.onCancel2}
                >
                  {LBLCANCEL}
                </button>
                <button
                  type="button"
                  onClick={this.onSubmit}
                  className="button-style primary-btn"
                  disabled={submitting}
                >
                  {LBLSUBMIT}
                </button>
              </React.Fragment>
            )}
          </Modal.Footer>
        </form>
      </div >
    );
  }
}

RegionalSettingForm = reduxForm({
  form: "regionalsettingsform", // a unique name for this form
})(RegionalSettingForm);

export default connect()(RegionalSettingForm);

import { param } from "jquery";
import React from "react";
import "./postimage.css";
//import ScCommonServices from '../../../services/common_services';

class PostImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageTag: "",
    };
  }
  componentDidMount() {
    let reader = new FileReader();
    reader.onload = (evt) => {
      this.setState({
        imageTag: (
          <img
            src={`${evt.target.result}`}
            className="ssi-feeds-thamnailImage"
            alt=""
          />
        ),
      });
    };
    reader.readAsDataURL(this.props.imageObj);
  }
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.feedsLanguageData && this.props.feedsLanguageData[paramLBL]
        ? this.props.feedsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  removeImageHandler = (evt) => {
    this.props.removePostAssets(this.props.index);
  };
  render() {
    let { imageTag } = this.state;
    return (
      <div
        className="ssi-feeds-imageHolder"
        style={{ width: "200px", height: "60px", marginTop: "3px" }}
      >
        <div className="ssi-feeds-imageHolderWrapper">
          {imageTag}
          <div
            className="ssi-feeds-imageCloseBtn"
            title={this.props.labels.labels.LBLREMOVEIMAGE}
            onClick={this.removeImageHandler}
          >
            <span className="fas fa-times-circle"></span>
          </div>
          <div
            className="ssi-feeds-imageTag"
            title={this.props.labels.labels.LBLTAG}
          >
            <span className="fas fa-user-tag"></span>
          </div>
        </div>
      </div>
    );
  }
}

export default PostImage;



class IAMutils {
    userPermission = (permission) => {
        if (permission) {
            let obj = {};
            let perm_arr = permission.split('-');
            let temp = perm_arr.map(item => {
                if (item === 'w') {
                    obj = { ...obj, hasWrite: true }
                }
                if (item === 'r') {
                    obj = { ...obj, hasRead: true }
                }
                if (item === 'ex') {
                    obj = { ...obj, hasExecute: true }
                }
                if (item === 'd') {
                    obj = { ...obj, hasDelete: true }
                }
            })
            return obj;
        }

    }
}

export default new IAMutils();

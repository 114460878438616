import React, { Component } from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import products_services from "../../../services/products_services";
import Loader from "../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import UserManagementHeader from "../../../common_components/datatable/UserManagementHeader";
import ConfrimPopup from "../prod-list/ConfirmPopup";
import { DataGrid} from '@mui/x-data-grid';
import assignedCheckbox from "../../../assets/images/assignedCheckboxImg.png";
import newlyAssignmentCheckbox from "../../../assets/images/newlyAssignedCheckboxImg.png";
import assignedaccucheckbox from "../../../assets/images/assignedaccucheckbox.png";
import styles from "./userlist.module.scss"
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];


class TrainingMultiAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      status: this.props.status,
      resultData: [],
      courseData:[],
      LSresponseData: [],
      selItemforMultiInput: {},
      query: "",
      show: false,
      showBtn: false,
      courseSearchValue: "",
      showErrorAssign: false,
      showErrorUnassign: false,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      selectedCrsArr: [],
      selectedUnassginArr: [],
      disMoreCourses : true,
      showList: false,
      selectedRowsCount:0,
      showConfrimPopup : false,
      courseId:[],
      toggleClearRows: true,
      visibleRowsSelected:false,
      Sitetype:localStorage.getItem('site_type'),
      pageStatus:{ // Loading data in the datagrid component
        isLoading: false,
        data: [], // rows
        total: 0 // total count of data grid we need to show
      },
      paginationModel:{ // pagination variables
        page: 0,
        pageSize: 10,
      },
      rowSelectionModel:[],
      filteredVal: [],
      sortModel: [],
    };
  }

  componentDidMount() {
    this.loadCourseList();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.pageStatus.data !== this.state.pageStatus.data ||
      prevState.rowSelectionModel !== this.state.rowSelectionModel
    ) {
      this.handleVisibleRowsSelection();
   }
  }
  handleVisibleRowsSelection = () => {
    let totalavailableRowsPerpage = 0;
    let totalavailableRows = 0;
    if (this.state.pageStatus.data.length > 0) {
      this.state.pageStatus.data.map((item) => {
        if(item.delflag !== 0 && this.props.assignedStatus === 1) {
          totalavailableRowsPerpage ++
        }
        return null;
      }
      );
    if(this.state.resultData.length > 0){
      this.state.resultData.map((item) => {
        if(item.delflag === 1 ) {
          totalavailableRows++
        }
        return null;
      }
      );
    }
      let selectedRowCount = 0;
      this.state.pageStatus.data.forEach(val => {
        if (this.state.rowSelectionModel.includes(val.productid)) {
          selectedRowCount++;
        }
      });

      let totalSelectedRows = selectedRowCount ;
      if(totalavailableRowsPerpage === 0) {
        this.setState({visibleRowsSelected:false})
      } else if (totalavailableRowsPerpage === totalSelectedRows && totalavailableRows !== this.state.selectedRowsCount) {
        this.setState({visibleRowsSelected:true})
      } else {
        this.setState({visibleRowsSelected:false})
      }
    }
  }
  loadCourseList() {
    const userId = this.props.selectedRows && this.props.selectedRows.map((item) => item.user_id);

    var vParams = {
      userid: userId,
      assigned_status: this.props.assignedStatus
    };
    products_services
      .getproducts_all_multi(vParams)
      .then((res) => {
        return res;
      })
      .then((data) => {
        console.log('dataa ', data.data)
        this.setState({
          resultData: data.data,
          courseData: data.data,
          load: false,
          pageStatus : { isLoading: false, data: data.data.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page + 1) * this.state.paginationModel.pageSize)), total: data.data.length },
          filteredVal: data.data
        });
      });
  }

  getTodayDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };

  ToastMessageDetails(vStatus, vMessage) {
    this.setState({
      showToast: {
        show: true,
        alertClass: "success",
        status: vStatus, //'Success',
        message: vMessage, //'The user is created successfully',
        changeInToast: new Date(),
      },
      errorType: null,
    });
    setTimeout(() => {
      this.setState({
        showToast: {
          show: false,
          alertClass: "",
          status: "",
          message: "",
          changeInToast: new Date(),
        },
      });
    }, 5000);
  }


  updateTrainingAssignment = () => {
    let postData = { productUsers: [] };
    let todaydate = this.getTodayDate();
    let message = this.props.assignedStatus ? this.props.labels.labels.LBLCOURSESASSIGNEDSUCCESSMSG : this.props.labels.labels.LBLCOURSESUNASSIGNEDSUCCESSMSG
    const crsId = this.state.rowSelectionModel;
    for (let i = 0; i < this.props.selectedRows.length; i++) {
      let item = {
        id: this.props.selectedRows[i].user_id,
        productid: crsId,
        delflag: this.props.assignedStatus ? 0 : 1,
        assigned_date: todaydate,
      };
      postData.productUsers.push(item);
    }
    HttpInterceptor.post("updateUsersProducts", postData).then((res) => {
      this.ToastMessageDetails(
        "Success",
        message
      );
      //this.loadCourseList();
      setTimeout(() => {
        this.props.initialPage()
      }, 2000);

    }).catch((error) => {
      this.ToastMessageDetails(
        "error",
        'Course(s) not assigned. Try again!'
      );
    });
  };

  handleRowChange = (state) => {
    const courseId = state.selectedRows.map((item) => item.id)
    this.setState({ 
      selectedCrsArr: state.selectedRows,
      selectedRowsCount:state.selectedCount,
      courseId:courseId
     })    
    let array1 = this.state.resultData
    let array2 = state.selectedRows
    const unassign = array1.filter(item1 => {
      return !array2.find(item2 => item1.id === item2.id)
    }
    );
    console.log('assign ',state.selectedRows )

    console.log('unassign ',unassign)
  }  
  
  handleSortModelChange = (sortModel) => {
    console.log("sortModelstart",sortModel)
    // if (sortModel?.length > 0) {
      this.setState({sortModel:sortModel});
      const field = sortModel[0]?.field ;
      let direction = sortModel[0]?.sort ;
      let sortedData = [...this.state.filteredVal];
       if(direction === undefined) {
        sortedData = [...this.state.filteredVal];
       }
       console.log("sortedData",sortedData)
       
       sortedData.sort((a, b) => {
        const valueA = a[field] === null ? "" : a[field]; // Convert null to empty string
        const valueB = b[field] === null ? "" : b[field]; // Convert null to empty string
        if (direction === 'asc') {
            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB);
            } else {
                return valueA - valueB;
            }
        } else {
            if (typeof valueB === 'string') {
                return valueB.localeCompare(valueA);
            } else {
                return valueB - valueA;
            }
        }
    });
    
      console.log("sortedData",sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)))
      this.setState({ pageStatus : { isLoading: false, data: sortedData.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)), total: sortedData?.length },resultData:sortedData });
    // }
    console.log("sortModel end",sortModel[0]?.sort)
  };

  searchCourse = (e) => {
    this.setState({ 
      courseSearchValue: e.target.value,
      paginationModel: {
        page: 0,
        pageSize: 10,
      },
     }, () => {
      this.filterCourse();
    });
  };
  handleShowMore= () => {
    this.setState({disMoreCourses :!this.state.disMoreCourses, showList: true})
  }
  closeModal = () => {
    this.setState({showList: false, disMoreCourses :!this.state.disMoreCourses})
  } 
  handleClear = () => {
    this.setState({
      rowSelectionModel : [],
      selectedRowsCount: 0
    });
  }
  handleSelectAll = () => {
    if(this.props.assignedStatus === 1) {
      const courseIds = this.state.filteredVal.map((item) => item.delflag !==0 && item.id);
      this.setState({
        rowSelectionModel : courseIds,
        selectedRowsCount : this.state.filteredVal.filter((item) => item.delflag === 1).length,
      })
  } else {
      const courseIds = this.state.filteredVal.map((item) =>  item.id);
      this.setState({
        rowSelectionModel : courseIds,
        selectedRowsCount : this.state.filteredVal.length,
        visibleRowsSelected: false
      })
  }
  }
  tableHeader = () => {
    return(
      <>
    <div style={{width:"100%",display: "flex", flexDirection: "column"}}>
      <div>
        <input
          className="input-field"
          style={{
            width: "auto",
            margin: "0px",
            float: "right",
            marginBottom: "5px",
          }}
          placeholder={this.props.labels.labels.LBLSEARCH}
          value={this.state.courseSearchValue}
          onChange={this.searchCourse}
          onKeyDown={this.searchCourse}
        />
      </div>
      <div  className={styles.legends_all_ctn}>
      {this.props.assignedStatus === 1 &&        
      <div className={styles.legend_ctn}>
       <img src= {assignedCheckbox} alt = ""/>
      {this.props.labels.labels.LBLASSIGNED !== undefined ? this.props.labels.labels.LBLASSIGNED  : "Assigned"}
       </div>
      }
      <div className={styles.legend_ctn}>
      <img src= {this.state.Sitetype==='SafeStart'?newlyAssignmentCheckbox :assignedaccucheckbox} alt = ""/>
         {this.props.labels.labels.LBLSELECTED !== undefined ? this.props.labels.labels.LBLNEWASSIGNEDCOURSES : "Selected"}
      </div>
      </div>
      <UserManagementHeader
        modalTitle = {this.props.labels.labels.LBLUSERSSELECTED !== undefined ? this.props.labels.labels.LBLUSERSSELECTED : "Users selected"}
        selectedRowsCount = {this.props.selectedRowsCount} 
        currentPageSelected = {this.state.selectedRowsCount}
        currentPageLabel = {this.props.labels.labels.LBLCOURSESSELECTED !== undefined ? this.props.labels.labels.LBLCOURSESSELECTED : "Courses selected"}
        labels = {this.props.labels.labels}
        usersSelectedLabel = {this.props.labels.labels.LBLUSERSSELECTED !== undefined ? this.props.labels.labels.LBLUSERSSELECTED : "Users selected"}
        handleClear = {this.handleClear}
        selectedRows = {this.props.selectedRows}
        dropObj = {null}
        showdropdown = {false}
        selectAll={this.props.labels.labels.LBLSELECTALLCOURSES !== undefined ? this.props.labels.labels.LBLSELECTALLCOURSES.replace("$$$",""): "Select All Courses"}
        currentPage = "courseAssignment"
        handleRowChange={this.handleSelectAll}
        visibleRowsSelected = {this.state.visibleRowsSelected}
        visiblerowsselectedLabel = {
          this.props.assignedStatus === 1 ?
          this.props.labels.LBLELIGIBLECOURSESSELECTED !== undefined ? this.props.labels.LBLELIGIBLECOURSESSELECTED :`All eligible courses on this page are selected.`
          :
          this.props.labels.LBLALLCOURSESSELECTED !== undefined ? this.props.labels.LBLALLCOURSESSELECTED :`All courses on this page are selected.`
      }
      />
    </div>
   
   </>
   );
  }
  handleConfrimPopup = () => {
    if (this.props.assignedStatus === 1) {
      this.setState({showConfrimPopup: true});
    } else {
      this.updateTrainingAssignment();
    }
  }
  handleCancelConfrimPopup = () => {
    this.setState({showConfrimPopup: false});
  }
  handleConfrim = () => {
    this.setState({showConfrimPopup : false})
    this.updateTrainingAssignment();
  }
  handlepaginationModalChange = (paginationVal) => {
    this.setState({paginationModel: paginationVal},() => {
      this.filterCourse();
      this.handleSortModelChange(this.state.sortModel);
    }
      );
  }
   handleSelectionModelChange = (newRowSelectionModel) => {
    const filteredArray = newRowSelectionModel.filter(item => item !== false);
    this.setState({
      rowSelectionModel: newRowSelectionModel,
      selectedRowsCount: filteredArray.length,
    });
  };
  filterCourse = (e) => {
    const filteredVal = this.state.courseData.filter((item) => {
      if (
        item.name &&
        item.name
          .toLowerCase()
          .includes(this.state.courseSearchValue.toLocaleLowerCase()) //&& item.isAssigned == selectedValue
      ) {
        return item;
      }
      return null;
    });
    this.setState({ pageStatus : { isLoading: false, data: filteredVal.slice((this.state.paginationModel.page * this.state.paginationModel.pageSize), ((this.state.paginationModel.page+1) * this.state.paginationModel.pageSize)), total: filteredVal.length },
    filteredVal:filteredVal,
  });
    return filteredVal;
   } 
   rowDisabledCriteria = (row) => {
    if(this.props.assignedStatus === 1){
        if(row.row.delflag === 1) {
            return true;
        } 
    } else {
        return true;
    } 
   }
  render() {
    const {
      LBLCANCEL,
      LBLROWSPERPAGE,
    } = this.props.labels.labels;

    const columns = [
      {
        headerName: this.props.labels.labels.LBLCOURSENAME,
        field: "name",
        width: "500",
        renderCell: (row) => (
          <div>
            {row.row.name}
          </div>
        ),
      }
    ];

    return (
      <div>
        {this.state.load ? (
          
          <div style={{ width: "100%" }}>
            <Loader></Loader>
            <div className="sc-fznxsB fWHyCt datatable-list web-skull">
              <header className="sc-fzoXzr hlidmt">
                <Skeleton height={41} />
              </header>
              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                    <Skeleton height={56} />
                  </div>
                  <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                    <Skeleton height={480} />
                  </div>
                </div>
              </div>
              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <Skeleton height={56} />
              </nav>
            </div>
            <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
              <header className="sc-fzoXzr hlidmt">
                <Skeleton height={34} />
              </header>
              <div className="sc-fznWqX gnahTY">
                <div className="sc-AxjAm gIMaKV rdt_Table">
                  <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                    {skel.map((i) => {
                      return (
                        <div
                          id={"row-" + i}
                          key={"skel-row-" + i}
                          className="sc-fzoLsD doBktq rdt_TableRow"
                        >
                          <Skeleton height={90} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                <div className="sc-fzpmMD ftIMtk">
                  <Skeleton height={40} />
                </div>
              </nav>
            </div>
          </div>
        ) : (
          <div style={{overflowY: 'auto', marginBottom: 5}}>
          <div >
            <div className="user-list-table-container">
              <ToastMessage
                show={this.state.showToast["show"]}
                helpers={this.state.showToast}
              ></ToastMessage>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                  
              </div>
                  <div>{this.tableHeader()}</div>
                  {this.state.pageStatus.data.length > 0 ?
                    <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                      },
                    }}
                    onSortModelChange={this.handleSortModelChange}
                    // initialState={{
                    //   sorting: {
                    //     sortModel: [{ field: 'name', sort: 'asc' }],
                    //   },
                    // }}
                      style={{ border: 'none' }}
                      className="cust-data-grid"
                      autoHeight
                      checkboxSelection
                      disableRowSelectionOnClick
                      isRowSelectable={this.rowDisabledCriteria} // matched condition rows only enabled
                      getRowId={(row) => row.productid} // key value of the row item
                      columns={columns}
                      rows={this.state.pageStatus.data}
                      rowCount={this.state.pageStatus.total}
                      loading={this.state.pageStatus.isLoading}
                      pageSizeOptions={[10, 15, 20, 25, 30]}
                      pagination
                      paginationMode="server"
                      paginationModel={this.state.paginationModel}
                      onPaginationModelChange={this.handlepaginationModalChange}
                      onRowSelectionModelChange={this.handleSelectionModelChange}
                      rowSelectionModel={this.state.rowSelectionModel}
                      keepNonExistentRowsSelected
                      hideFooterSelectedRowCount
                      disableColumnMenu
                      slotProps={{
                        pagination: {
                          labelRowsPerPage: LBLROWSPERPAGE + ":"
                        }
                      }}
                      sx={{
                        '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                          outline: 'none',
                        },
              
                        '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                          outline: 'none',
                        },
                        '& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg path': {
                          display: "none"
                        },
                        '& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg': {
                          backgroundColor:"#cfcbcb",
                          width:"18px",
                          height:"18px",
                          borderRadius:"3px"
                      },
                     }}
                    />
                    :
                    <div style={{ marginTop: "5%" }}><EmptyState image={emptyImage} text2={this.props.labels.labels.LBLASSIGNMENTEMPTYTXT} /> </div>
                  }
            </div>
            
          </div>
          <div className="section-body bn-ctn">
          <div className="col-sm-12"  >
                <button
                  type="button"
                  className="button-style primary-btn"
                  onClick={this.handleConfrimPopup}
                  style={{ float: "right" }}
                  disabled={this.state.selectedRowsCount === 0} 
                >
                  {this.props.labels.labels.LBLCONFIRM}
                </button>
                <button
                  type="button"
                  className="button-style secondary-btn"
                  id="btnaddCancel"
                  onClick={this.props.initialPage}
                  style={{ float: "right", marginRight: "10px" }}
                >
                  {this.props.labels.labels.LBLCANCEL}
                </button>
              </div>
              {this.state.showConfrimPopup ? 
                <ConfrimPopup
                  show = {this.state.showConfrimPopup}
                  hide = {this.handleCancelConfrimPopup}
                  title = {this.props.labels.labels.LBLASSIGNCOURSES !== undefined ? this.props.labels.labels.LBLASSIGNCOURSES  : "Assign Courses"} 
                  selectedRowsCount = {this.props.selectedRowsCount}
                  usersGroupsSelectedCount = {this.state.selectedRowsCount}
                  buttonName = {this.props.labels.labels.LBLCONFIRM !== undefined ? this.props.labels.labels.LBLCONFIRM : "Confirm"}
                  handleConfrim = {this.handleConfrim}
                  handleCancel = {this.handleCancelConfrimPopup}
                  description = { this.props.labels.labels.LBLCOURSESASSIGNEDTOELIGIBLEUSERS}
                  btnCancel = {LBLCANCEL}
                />
                :
                null
              }
          </div>
          </div>
        )}
      </div>
    );
  }
}

export default TrainingMultiAssignment;

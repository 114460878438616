import React from 'react';
import DistractorComp from './common/distractorComp';
import ToggleButtonGroup from './common/toggleButtonGroup';
import './RiskCardContent.scss';

class CardContentComp extends React.Component {
    state = {
        checkedItems: []
    }
    constructor(props) {
        super(props)
        this.state.checkedItems = props.data.map(item => {
            let tempItem = [];
            if (item.type !== "textarea") {
                item.option_contents.map(contents => {
                    tempItem = [];
                    contents.options.map(opt => {
                        opt = { ...opt, userAnswer: false }
                        return tempItem.push(opt);
                    });
                    contents.options = tempItem;
                })
            }
            return item;
        });
    }

    handleChange = (e, qIndex, cIndex, oIndex) => {
        let checkedItems = [...this.state.checkedItems];
        checkedItems[qIndex].option_contents[cIndex].options[oIndex].userAnswer = e.target.checked;
        let arr = [];
        checkedItems.forEach(element => {
            if (element.type !== "textarea") {
                let mc = false;
                element.option_contents.forEach((options, i) => {
                    if (mc) return true;
                    let tempoptions = element.option_contents[i].options;
                    mc = tempoptions.some((value) => {
                        return (value.userAnswer === true)
                    })
                })
                arr.push(mc);
            }
        });
        let allChecked = arr.every((value) => { return value === true })
        this.setState({ checkedItems });
        this.props.optionChanged(allChecked);
    }
    textAreaChangeHandler = (evt, index) => {
        let checkedItems = [...this.state.checkedItems];
        checkedItems[index].option_contents[0].details = evt.target.value;
        this.setState({ checkedItems });
    }
    correctAnswerChange = () => {
        console.log("show Answer");
    }
    renderPageContents = (type, ques_header, option_contents, i) => {
        let optionsClasses = (type === "option_2" ? (i === 1 ? "col-md-6 col-sm-12 pl-xl-0 pl-md-0" : "col-md-6 col-sm-12") : "col-md-12");
        return (
            <React.Fragment>
                <div className={optionsClasses} key={i}>
                    {ques_header ? <div className="question-hdr">{this.props.labels[ques_header] || ques_header}</div> : ""}
                    {
                        (type === "textarea") ? <div className="details-textarea-ctn form-group"><textarea placeholder={this.props.labels.lbl_typedetailshere} value={option_contents[0].details} rows={3} className="details-textarea form-control" disabled={this.props.showAnswer} onChange={(evt) => this.textAreaChangeHandler(evt, i)} /></div>
                            : (type === "option_4") ?
                                option_contents.map((element, j) => {
                                    return (
                                        <ToggleButtonGroup key={i} index={i} cIndex={j} type={type} options={element.options} onChange={this.handleChange} showAnswer={this.props.showAnswer} labels={this.props.labels} />
                                    )
                                })
                                :
                                option_contents.map((element, j) => {
                                    return (
                                        <DistractorComp key={i} index={i} cIndex={j} type={type} options={element.options} onChange={this.handleChange} showAnswer={this.props.showAnswer} labels={this.props.labels} />
                                    )
                                })
                    }
                </div>
            </React.Fragment>
        )
    }

    render() {
        const { checkedItems } = this.state;
        const { currentContent } = this.props;

        const page1Content = checkedItems.slice(0, 3).map(({ type, ques_header, option_contents }, i) => {
            return this.renderPageContents(type, ques_header, option_contents, i)
        });
        const page2Content = checkedItems.slice(3, checkedItems.length).map(({ type, ques_header, option_contents }, i) => {
            return this.renderPageContents(type, ques_header, option_contents, (i + 3))
        })
        return (
            <div className="riskcard-content">
                {currentContent === 1 ?
                    <div className="container page_1">
                        <div className="question-table row">
                            {page1Content}
                        </div>
                    </div>
                    :
                    <div className="container page_2">
                        <div className="question-table row">
                            {page2Content}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default CardContentComp;
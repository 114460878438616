import { useRef, useState } from "react";
import "./style.scss";
import Accordian1 from "./Accordian1";
const Accordian = (props) => {
  const { item } = props;
  const [isActive, setIsActive] = useState(false);
  const childAccoridianRef = useRef(null);
  const expandClickHandler = () => {
    setIsActive(!isActive);
  };
  const buildNextAccordian = (pItem) => {
    return pItem.map((item1) => {
      return (
        <Accordian1
          labels={props.labels}
          item={item1}
          key={"uni_" + item1.uid}
          ref={childAccoridianRef}
          onClicked={props.onClicked}  
        />
      );
    });
  };
  return (
    <div>
      <div className="acc-pro-ctn" key={item.pid} id={"pro_" + item.pid}>
        <div className="acc-pro-expand" onClick={expandClickHandler}>
          {item.units ? isActive ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i> : null} 
        </div>
        <div className="acc-pro-label">{props.labels.LBLCOURSE}:</div>

        <div className="acc-pro-name">{item.pname}</div>
      </div>
      {isActive && item.units ? buildNextAccordian(item.units) : null} 
    </div>
  );
};
export default Accordian;


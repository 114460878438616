import { useEffect } from "react";
import Accordian from "./Accordian";
import "./style.scss";
import { useState } from "react";
import ReactPaginate from "react-paginate";

const MultiLevelAccordian = (props) => {
  const {setSelectedCapsule, data } = props;  
  const [currentItems, setCurrentItems] = useState(data); //.slice(itemOffset, endOffset);
  useEffect(() => {
    setCurrentItems(data);
  }, [data]);
  return (
    <div className="multi-level-accordian">
      <div className="accordian-comp">
        {currentItems.map((item, i) => {
          return (
            <Accordian
              labels={props.labels}
              item={item}
              key={item.pid}
              onClicked={setSelectedCapsule}
            />
          );
        })}
      </div>
    </div>
  );
};
export default MultiLevelAccordian;

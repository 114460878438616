import React from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import steering_committee from "./steeringcommittee.scss";
import { connect } from "react-redux";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import CreateTeam from "./createteam";
import TeamList from "./teamlist";
import TeamMembersList from "./teammemberslist";
import { LiveTvRounded } from "@material-ui/icons";
const $ = require("jquery");
let teamid = "";
class SteerCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingTeam: false,
      selectAdmin: "",
      loading: false,
      loadingTeamsList: true,
      editTeam: false,
      teamdetails: {},
      isPriorTeams:false,
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      saved_team_id:""
    };
    this.childref = React.createRef();
  }
  componentDidMount() {
    $('#root').scrollTop(0); 
     setTimeout(() => {
      this.getSteercomSettings(); 
    }, 1000); 
  }
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    for (let x in steercomSettings) {
      if(x!="roles"){ 
      steercomSettings[x].map((item, index) => { 
          if (item.name && item.name.indexOf("LBL") == 0) {
            item.name = this.getLabel(item.name); 
          } 
      });}
    }
    let roles = this.sortResults(steercomSettings.roles,"steercom_role_name",true); 
    let loadingTeam=false,loadingTeamsList=true,loadingTeamMembersList=false,editTeam=false; 
    
    let backurl = this.props.history.location.state?this.props.history.location.state.backlinkurl:"";
    if(backurl != "" && backurl.indexOf("steercommaillanding")>0){ 
      let urlData =  this.props.history.location.state;       
      let team_id = urlData.teamData.team_id;
      let mteam_id = urlData.teamData.mteam_id;
      if(team_id > 0){
        this.getTeamDetailsCallback(team_id); 
      }else if(mteam_id>0){
        this.editTeamMembersList([],mteam_id,2); 
      } 
    } 
    this.setState({
      steercomSettings: steercomSettings 
    });
  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  setPriorValaues=(isPriorTeam)=>{
    isPriorTeam = isPriorTeam== undefined ?false:isPriorTeam;
    this.setState({
      isPriorTeams:isPriorTeam
    })
  }
  showCreateTeam = () => {
    this.setState({
      editTeam: false,
      loadingTeam: true,
      loadingTeamsList: false,
      loadingTeamMembersList: false,
      loading: false,
    });
  };
  setTeamDetails=(val, teamData)=>{
      teamid=val;
      this.setState({
        saved_team_id : val,
        teamData:teamData
      })
  }
  showTeamList = (event) => {
    $("#edit_teams").hide();
    var header = this.props.labels.labels.LBLSTEERCOMTEAMS; 
    $("#header_det_committee").text(header);
 if(this.state.isPriorTeams){
   if(this.childref.current){
    this.childref.current.loadMemberList();
   } 
 }else{
  $("#header_top_edit").hide();
  this.setState({
    loadingTeam: false,
    loadingTeamsList: true,
    loadingTeamMembersList: false,
    loading: false,
    saved_team_id:""
  });
 }
    
  };
  loadPriorDetails=()=>{
    if(this.childref.current){
      this.childref.current.getPriorTeamMembersList();
    }
  }
  createTeams = (values) => {
    var x = values;
  };
  editTeamDetails = (team_id) => {
    teamid = team_id;
    this.getTeamDetailsCallback(teamid);
  };

  getTeamDetailsCallback = async(teamid) => {
    let response=  await SteercomServices.getTeamDetails(teamid);
    // console.log("response.data.result", response.result[0]);
    this.setState({
      editTeam: true,
      teamData: response.result[0],
      loadingTeam: true,
      loadingTeamsList: false,
      loading: false,    });
  };

  editTeamMemberDetails = (team_id) => {
    teamid = team_id;
    let apiUrl = "steercomteam/" + team_id;
    let data = {
      team_id: team_id,
    };
    // this.edititems(team_id);
    SteeringCommitteeService.SteeringAPIServicesCall(
      apiUrl,
      "get",
      this.editTeamMemberDetailsCallback,
      data
    );
  };
  editTeamMemberDetailsCallback = (response) => {
    var teamData,
      alldatavals = response.data.result;
    alldatavals.map((item, index) => {
      if (item.team_id == teamid) {
        teamData = item;
      }
    });

    this.setState({
      editTeam: true,
      teamData: teamData,
      loadingTeam: true,
      loadingTeamsList: false,
      loading: false,
    });
  };
   
  sortResults = (arr, prop, asc) => {
    arr.sort(function (a, b) {
      if (asc) {
        return (a[prop].toLowerCase()  > b[prop].toLowerCase()) ? 1 : ((a[prop].toLowerCase() < b[prop].toLowerCase()) ? -1 : 0);
      } else {
        return (b[prop].toLowerCase() > a[prop].toLowerCase()) ? 1 : ((b[prop].toLowerCase() < a[prop].toLowerCase()) ? -1 : 0);
      }
    }); 
  }   
  editTeamMembersList = (item,team_id,mode) => {
    let teamdata = this.state.teamData;  
    let saved_team_id =this.state.saved_team_id;
    let teamId="";
    if(mode==2){
      teamId = team_id;   
    }else{
      if(saved_team_id){
        teamId = saved_team_id; 
      }else if(team_id){
        teamId = teamdata.team_id; 
      } 
    }
    $("#addnewmember").html(this.props.labels.labels.LBLADDNEWMEMBER);   
    this.editTeamMembersListCallback(teamId);
  };
  editTeamMembersListCallback = async (teamId) => {
    let res =  await SteercomServices.getTeamMembersList(teamId); 
    // console.log("getTeamMembersListCallback", res);
    // console.log("teamData", res.result[0][0]);
    // console.log("teamMembersList", res.result[1]);
    let teamData = res.result[0][0];
    let teamMembersList = res.result[1];
    teamMembersList.unshift({
      team_id: teamData.team_id,
      team_member_id: "NEW",
      user_id: "",
      steercom_role_id: "",
      service_duration: "18",
      member_startdate: teamData.start_date,
      member_enddate: teamData.start_date,
      status: 1, //values.active,
      customDuration:teamData.service_duration==99?false:true
    });
    this.setState({
      teamData: teamData,
      teamMembersList: teamMembersList,
      loadingTeam: false,
      loadingTeamsList: false,
      loadingTeamMembersList: true,
      loading: false,
    });
  };
  redirectoSFSetup=(item, event)=>{
    let dirpath = "/schome/"
    if (window.location.href.indexOf("/home/") > -1) {
      dirpath = "/home/"
    }
    let backlinkurl = dirpath + "successfactors";
     
    let teamData = this.state.teamData;
    let successFactorData =[]
    successFactorData.unshift({
      active: 1,
      color: "sf_color1",
      description: "",
      id: "0",
      name: "LBLALL",
      title: this.props.labels.labels.LBLALL,
      version: "1"
    });
    this.props.history.push({
      pathname: dirpath + "successfactors",
      state: {
        backlink: true,
        backlinkurl: backlinkurl,
        successFactorData: successFactorData[0],
        teamData: teamData,
        parentId: item.parent_task_display_id
      }
    });
  }
  redirectoSFPlan=(item, event)=>{
    let dirpath = "/schome/"
    if (window.location.href.indexOf("/home/") > -1) {
      dirpath = "/home/"
    }
    let backlinkurl = dirpath + "successfactorplan";
     
    let teamData = this.state.teamData;
    let successFactorData={ 
        active: 1,
        color: "sf_color1",
        description: "",
        id: "0",
        name: "LBLALL",
        title: "All",
        version: "1"
      } ;
    this.props.history.push({
      pathname: dirpath + "successfactorplan",
      state: {
        backlink: true,
        backlinkurl: backlinkurl,
        successFactorData: successFactorData, 
        teamData: teamData,
        parentId: item.parent_task_display_id
      }
    });
  } 
  showSuccessFactors = () => {
    alert("Work in progress....");
  };

  render() {
    const { LBLPRIORTEAMMEMBER,LBLBACK,LBLSTEERCOMSFSETUP,LBLUPDATEPLAN,LBLSTEERCOMTEAMS, LBLADDNEWTEAMS, LBLSTEERINGCOMMITTEE,LBLEDITTEAM,LBLTEAMMEMBERSLIST } =
      this.props.labels.labels;
    return (
      this.props.labels.labels ?
      <div className="mob-pad steering_committee"> 
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage> 
        <div className="page-header-field ">
        <h3 className="cls_teams" id="header_top">
            <i
              id="header_top_edit"  title={LBLBACK}  style={{display:"none",marginRight:"10px"}}
              className="fas fa-arrow-left cu-back-btn"
              aria-hidden="true"
              onClick={this.showTeamList}
            > </i>  
            <span id="header_det_committee" className=" page-title page-toptitile" > {LBLSTEERCOMTEAMS}</span>
          </h3>
             { LBLADDNEWTEAMS && LBLADDNEWTEAMS.length>0?
            <div className="btn-align cls_add_teams"  id="sub_header_det"  > 
                <button
                className="button-style primary-btn"
                onClick={this.showCreateTeam}
              >
                {LBLADDNEWTEAMS}
                </button>
            </div> 
            :""}
          <div id="edit_teams" style={{display:"none"}} >
          <div style={{display:"flex",marginRight:"10px"}} className="btn-align" id="edit_teams1" tabindex="-1">
            <button
              className="button-style primary-btn"
              onClick={this.redirectoSFSetup.bind(
                this,
                ''
              )}
            >
              {LBLSTEERCOMSFSETUP}
            </button>
          </div>

          <div style={{display:"flex",marginRight:"10px"}} className="btn-align" id="edit_teams2" tabindex="-1">
            <button
              className="button-style primary-btn"
              onClick={this.redirectoSFPlan.bind(
                this,
                ''
              )}
            >
              {LBLUPDATEPLAN}
            </button>
          </div>
          <div style={{display:"flex"}} className="btn-align" id="edit_teams3" tabindex="-1">
            <button
              className="button-style primary-btn"
              onClick={this.editTeamMembersList.bind(
                this,
                ''
              )}
            >
              {LBLTEAMMEMBERSLIST}
            </button>
          </div>
          </div>

          <div  id="priorTeamMembers"
                  class="btn-align cls_prior_lnk"
                  style={{
                    display:"none"
                  }} 
                  >
            <button
              className="button-style primary-btn"
              onClick={ this.loadPriorDetails}
            >
              {LBLPRIORTEAMMEMBER}
            </button>
          </div>
        </div> 
        <hr className="head-line" />
              
        {this.state.loading ? (
          <div className="row maincontainer" id="maincontainer">
            <div className="col-xl-3"></div>
            <div
              className="col-xl-3"
              style={{ marginRight: "14px", marginBottom: "20px" }}
            >
              <div
                className="container1"
                id="container1"
                onClick={() => this.showTeamList()}
              >
                <img
                  src="../images/steercom/Steeringcommittee.png"
                  className="img_container1"
                  alt=""
                ></img>
              </div>
            </div>
            <div className="col-xl-4" style={{ marginBottom: "20px" }}>
              <div
                className="container2"
                id="container2"
                onClick={() => this.showSuccessFactors()}
              >
                <img
                  src="../images/steercom/Successfactors.png"
                  className="img_container2"
                  alt=""
                ></img>
              </div>
            </div>
            <div className="col-xl-2"></div>
          </div>
        ) : (
          ""
        )}
        {this.state.loadingTeam ? (
          <div>
            <CreateTeam
              initialValues={this.state.initialValues}
              steercomSettings={this.state.steercomSettings}
              editTeam={this.state.editTeam}
              teamData={this.state.teamData}
              labels={this.props.labels}
              lang_code={this.props.language_code}
              closeModal={this.showTeamList}
              setTeamDetails={this.setTeamDetails}
              {...this.props}
            ></CreateTeam>
          </div>
        ) : (
          ""
        )}
        {this.state.loadingTeamsList ? (
          <div>
            <TeamList 
              initialValues={this.state.initialValues}
              steercomSettings={this.state.steercomSettings}
              editTeamDetails={this.editTeamDetails}
              editTeamMembersList={this.editTeamMembersList}
              labels={this.props.labels}
              lang_code={this.props.language_code}
              setTeamDetails={this.setTeamDetails}
              {...this.props}
            ></TeamList>
          </div>
        ) : (
          ""
        )}
        {this.state.loadingTeamMembersList ? (
          <div>
            <TeamMembersList
              ref={this.childref} 
              initialValues={this.state.initialValues}
              steercomSettings={this.state.steercomSettings}
              teamData={this.state.teamData}
              teamMembersList={this.state.teamMembersList}
              labels={this.props.labels}
              lang_code={this.props.language_code}
              closeModal={this.showTeamList}
              editTeamMembersList={this.editTeamMembersList}
              setPriorValaues={this.setPriorValaues}
              {...this.props}
            ></TeamMembersList>
          </div>
        ) : ( 
          ""  
        )}
      </div>:""
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(SteerCom);  
import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import services from "../../../services/common_services";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { required, phoneno } from "../validate/Validate";
import parsePhoneNumber from 'libphonenumber-js'
class PhoneNumberForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      user_phone_number: this.props.props.phone_number,
      user_language: this.props.props.language,
      cdTimer: 60,
      screen: 1,
      ssi_errors: { error: {} }
    };
  }
  getElementValue = (xname) => {
    let li = document.getElementsByTagName("input");
    for (let i = 0; i < li.length; i++) {
      if (li[i].name === xname) {
        return li[i].value;
      }
    }
    return ""
  }
  getValue(x) {
    return this.state[x] || "No";
  }
  setScreen = (x) => {
    if (x === 2) {
      let li = document.getElementsByTagName("input");
      var phneval = ""
      for (let i = 0; i < li.length; i++) {
        if (li[i].name === "user_phone_number") {
          phneval = li[i].value;
          this.doEvent(li[i], "blur");
        }
      }
      const phoneNumber = parsePhoneNumber("+" + phneval)
      let phvalid = false
      if (phoneNumber) {
        phvalid = phoneNumber.isValid()
      }

      if (phvalid === false) {
        let errMsg = this.props.labels["LBLINVALIDPHONE"]
        let ssi_errors = { error: { error_field: "phone_number", message: errMsg } }
        this.setState({
          ssi_errors: ssi_errors,
        });
      } else {
        let data = {};
        data.user_phone_number = this.getElementValue("user_phone_number")
        HttpInterceptor.post("updatephone", data).then(
          (response) => {
            if (response) {
              this.setState({
                user_phone_number: data.user_phone_number,
              });
              this.generateSMSOtp()
            }
          }
        ).catch((err) => {
          if (err) {
            if (err.response && err.response.data) {
              let errMsg = this.props.labels[err.response.data.error.message]
              let ssi_errors = { error: { error_field: "phone_number", message: errMsg } }
              this.setState({
                ssi_errors: ssi_errors,
              });
            }
          }
        });
      }
    }
  }
  doEvent = (obj, event) => {
    /* Created by David@Refoua.me */
    var event = new Event(event, { target: obj, bubbles: true });
    return obj ? obj.dispatchEvent(event) : false;
  };
  renderField = ({
    input,
    label,
    type,
    placeholder,
    className,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          className={className}
        />
        {touched &&
          ((error && (
            <span className="required-field">
              {typeof error === "string"
                ? this.props.labels[error]
                : this.props.labels[error.label].replace("$$$", error.val)}
            </span>
          )) ||
            (warning && <span className="required-field">{warning}</span>))}
      </div>
    </div>
  );
  countDownTimer = () => {
    let cdTimer = this.state.cdTimer
    if (cdTimer > 0) {
      this.setState({
        cdTimer: cdTimer - 1
      })
      setTimeout(this.countDownTimer, 1000)
    }
  }
  generateSMSOtp = () => {
    let data = {};
    data.user_phone_number = this.getElementValue("user_phone_number")
    services.getGeneratePHONEVERIFYOTP(data)
      .then((response) => {
        let ssi_errors = { error: {} }
        this.setState({
          screen: 2,
          ssi_errors: ssi_errors,
          cdTimer: 60
        })
        setTimeout(this.countDownTimer, 1000)
      })
      .catch((err) => {
        if (!err.response) {
          let errMsg = this.props.labels["LBLINTERNALSERERROR"]
          let ssi_errors = { error: { error_field: "verify_otp", message: errMsg } }
          this.setState({
            screen: 2,
            ssi_errors: ssi_errors,
          });
        } else {
          let errMsg = this.props.labels[err.response.data.message].replace('$$$', '30');
          let ssi_errors = { error: { error_field: "verify_otp", message: errMsg } }
          this.setState({
            screen: 2,
            ssi_errors: ssi_errors,
          });
        }
      });
  }
  validateSMSOtp = (values) => {
    let data = {};
    data.verify_otp = this.getElementValue("verify_otp")
    data.user_phone_number = this.getElementValue("user_phone_number")
    services.VERIFYOTP(data)
      .then((response) => {
        if (response) {
          let ssi_errors = { error: {} }
          this.setState({
            screen: 2,
            ssi_errors: ssi_errors,
            cdTimer: 60
          })
          this.props.onChange3(this.state.user_phone_number)
        }
      })
      .catch((err) => {
        if (!err.response) {
          let errMsg = this.props.labels["LBLINTERNALSERERROR"];
          let ssi_errors = { error: { error_field: "verify_otp", message: errMsg } }
          this.setState({
            screen: 2,
            ssi_errors: ssi_errors,
          });
        } else {
          let errMsg = this.props.labels["LBLINVALIDOTP"]
          let ssi_errors = { error: { error_field: "verify_otp", message: errMsg } }
          this.setState({
            screen: 2,
            ssi_errors: ssi_errors,
          });
        }
      });
  }
  render() {
    const { handleSubmit, submitting, labels } = this.props;

    const {
      LBLADDEDITPHONENEUMBER,
      LBLINCORRECTALERT,
      LBLGOTOLOGIN,
      LBLCANCEL,
      LBLPHONENUMBER,
    } = labels;
    let ssi_errors = this.state.ssi_errors
    return (
      <div>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            <h4
              className="modal-title"
              id="exampleModalLongTitle"
              style={{ lineHeight: "2.5" }}
            >
              {LBLADDEDITPHONENEUMBER}
            </h4>
          </Modal.Title>
        </Modal.Header>
        <form className="form" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="modal-body">
              {this.state.screen === 1 ? (
                <div>
                  <div className="form-group">
                    <label>{LBLPHONENUMBER}</label>
                    <PhoneInput
                      placeholder=""
                      localization={
                        this.state.user_language.split("-")[0]
                      }
                      inputStyle={{ width: "100%" }}
                      inputProps={{
                        name: "user_phone_number2",
                      }}
                      countryCodeEditable={false}
                      // value={}
                      excludeCountries={["us"]}
                      regions={["america", "europe", "asia"]}
                      onChange={(
                        value,
                        country,
                        e,
                        formattedValue
                      ) => {
                        let li = document.getElementsByTagName(
                          "input"
                        );
                        for (let i = 0; i < li.length; i++) {
                          if (li[i].name === "user_phone_number") {
                            li[i].value = value;
                            this.doEvent(li[i], "blur");
                          }
                        }
                      }}
                    />
                    {ssi_errors && ssi_errors.error.error_field === "phone_number" ?
                      (
                        <>
                          <p className="required-field">
                            {ssi_errors.error.message}
                          </p>
                        </>
                      ) : (<>
                      </>)
                    }

                  </div>
                  <div className="form-group" style={{ display: "none" }}>
                    <Field
                      name="user_phone_number"
                      type="text"
                      component={this.renderField}
                      label={LBLPHONENUMBER}
                      className="form-control"
                      validate={[phoneno]}
                    />
                  </div>
                  <div className="form-group btn-dbl">
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={submitting}
                      onClick={(values) => {
                        this.setScreen(2)
                      }}
                    >
                      {labels.LBLADD}
                    </button>
                  </div>
                </div>
              ) : (
                  <div>
                    <Field
                      name="verify_otp"
                      type="text"
                      component={this.renderField}
                      className="form-control"
                      validate={required}
                      label={labels.LBLOTP}
                      placeholder={labels.LBLENTEROTP}
                      onChange={this.onChangeHandler}
                    />
                    {ssi_errors && ssi_errors.error.error_field === "verify_otp" ?
                      (
                        <>
                          <p className="required-field">
                            {ssi_errors.error.message}
                          </p>
                        </>
                      ) : (<>
                        <div className="text-left pwdlink" style={{ "padding": "10px" }}>
                          {
                            this.state.cdTimer > 0 ? <>
                              {this.state.cdTimer} {labels.LBLOTPSECONDSLEFT}
                            </> : <>

                                <Link
                                  href="#"
                                  data-toggle="modal"
                                  onClick={(values) => {
                                    this.generateSMSOtp(1)
                                  }}
                                  className="lightblue"
                                >
                                  <i> {labels.LBLRESENDOTP} </i>
                                </Link>

                              </>
                          }
                        </div>
                      </>)
                    }
                    <div className="form-group btn-dbl">
                      <button className="btn btn-primary" type="button"
                        onClick={(values) => {
                          this.validateSMSOtp(1)
                        }}
                      >
                        {labels.LBLVERIFY}
                      </button>
                    </div>
                  </div>
                )
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.message !== null ? (
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                  {this.props.message === "2" ? (
                    <label>{LBLINCORRECTALERT}</label>
                  ) : this.props.message === "1" ? (
                    <Link to="/">
                      <a href="#">
                        <i className="fas fa-id-badge"></i>
                        {LBLGOTOLOGIN}
                      </a>
                    </Link>
                  ) : (
                        ""
                      )}
                </div>
              </div>
            ) : (
                ""
              )}
            {this.props.message === "1" ? (
              ""
            ) : (
                <React.Fragment>
                  {" "}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    id="btnaddCancel"
                    onClick={this.props.onCancel3}
                  >
                    {LBLCANCEL}
                  </button>
                </React.Fragment>
              )}
          </Modal.Footer>
        </form>
      </div>
    );
  }
}

const renderSelectField = ({
  input,
  label,
  name,
  g_value,
  type,
  id,
  className,
  meta: { touched, error },
  children,
  onChng,
}) => {
  return (
    <div>
      <label>{label}</label>
      <div>
        <select
          value={g_value}
          name={name}
          id={id}
          className={className}
          onChange={onChng}
        >
          {children}
        </select>
        {/* {touched && error && <span className="required-field">{error}</span>} */}
      </div>
    </div>
  );
};

PhoneNumberForm = reduxForm({
  form: "phonenumberform", // a unique name for this form
})(PhoneNumberForm);

export default connect()(PhoneNumberForm);

import { param } from "jquery";
import React from "react";
import "./postimage.css";
//import ScCommonServices from '../../../services/common_services';

class PostImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageTag: "",
    };
  }
  componentDidMount() {
    let reader = new FileReader();
    reader.onload = (evt) => {
      this.setState({
        imageTag: (
          <img
            src={`${evt.target.result}`}
            className="ssi-feeds-thamnailImage"
            alt=""
          />
        ),
      });
    };
    reader.readAsDataURL(this.props.imageObj);
  }
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.feedsLanguageData && this.props.feedsLanguageData[paramLBL]
        ? this.props.feedsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  removeImageHandler = (evt) => {
    this.props.removePostAssets(this.props.index);
  };
  render() {
    let { imageTag } = this.state;
    return (
      <div
        className="ssi-feeds-imageHolder"
        style={{ width: "200px", height: "100px", marginTop: "0px", border: "0px" }}
      >
        <div className="ssi-feeds-imageHolderWrapper">
          {/* <i className={this.props.imageObj.name.indexOf(".csv") > -1 ? "fa fa-file-csv" : "fa fa-file-excel"} style={{ marginTop: "20px", fontSize: "50px" }}></i> */}
          <div className="closeimg"
            style={{marginLeft:"154px"}}
            xclassName="ssi-feeds-imageCloseBtn"
            title={this.props.labels.labels.LBLREMOVEIMAGE}
            onClick={this.removeImageHandler}>
            {/* <span className="fas fa-times-circle"></span> */}
          </div>
          <div className={this.props.imageObj.name.indexOf(".csv") > -1 ? "csvimg" : "excelimg"} style={{ marginTop: "-10px", fontSize: "50px",marginLeft:"60px" }}></div> 
         
          
          <div>
            <span style={{ display: "block", marginTop: "5px",cursor:"default" }} className="grid-ellipsis" title={this.props.imageObj.name}>{this.props.imageObj.name}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default PostImage;

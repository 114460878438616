import React from "react";
import { Link } from "react-router-dom";
import "./feeds.css";
//import feedsLanguageData from "../../translations/feeds.json";
//import PostImage from "../postimage/postimage";
//import PostFile from "../postfile/postfile";
import FeedDisplayBlock from "../feedblock/feedblock";
import FeedsServices from "../../feedService/feedservices";
import { connect } from "react-redux";
//import Picker from "emoji-picker-react";
import { S3MultiUpload } from "../../../shared/s3upload";
import ProfileImageDisplay from "../profileimage/profileimage";
//import FeedsLoader from "../loader/feedsloder";
//import { Spinner, ProgressBar } from "react-bootstrap";
//import { filter, truncate } from "lodash";
//import SCUtils from "../../../utils/utils";
import utils from "../../../utils/utils";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Carousel from "../carousel/Carousel";
//import UploadPopup from "../uploadPopup/uploadPopup";
// import CreatePost from ''
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../admin/components/loader/Loader";
//import { Skeleton } from "@material-ui/lab";
import Skeleton from "react-loading-skeleton";
import emptyImage from '../../../assets/images/storyfeeds_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";

var originalFetch = require("isomorphic-fetch");
var fetch = require("fetch-retry")(originalFetch);
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const responsive = {
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    paritialVisibilityGutter: 60,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3.5,
    paritialVisibilityGutter: 30,
  },
};

class Feeds extends React.Component {
  constructor(props) {
    super(props);
    this.fileAttachRef = React.createRef();
    this.state = {
      user_filter_id: "",
      channel_filter_id: "",
      attachedFiles: [],
      feedsData: [],
      userInfo: {},
      language_code: this.props.language_code,
      role_id: this.props.role_id,
      chosenEmoji: {},
      showEmoji: 0,
      isAssetsUploadedToS3: 0,
      isPostSavedToDB: 0,
      fileAttacheBtnEnable: 1,
      ogUrls: [],
      feedPostBtnStatus: 0,
      channelsData: [],
      totalChannelData: [],
      showLoading: 1,
      showFeeds: 0,
      feedsLanguageData: this.props.labels,
      feedsTypes:
        this.props.feedsTypes !== undefined && this.props.feedsTypes
          ? this.props.feedsTypes
          : "default", //"default", "channel"
      feedsData1: null,
      restrictedFileName: [],
      feedsLoading: true,
      channelLoading: true,
      videoUploader: false,
      uploadMediaCount: 0,
      uploadFiles: [],
      processing: false,
      posted: 0,
      signedUrls: [],
      uploaded: [],
      upload_completed: 0,
      streamed: 0,
      uploadingSize: 0,
      uploadedSize: 0,
      feedContainerWidth: 0,
      noPostAllowed: 0,
      selectedChannelIndex: -1,
      showAlert: 0,
      alertMessage: "",
      //isModalOpen: false,
    };
    this.fileAccptance =
      ".PNG,.JPG,.JPEG,.GIF,.MP3,.MP4,.MPEG,.MOV,.WMV,.3GP,.3G2,.AVI,.MKV";
    this.feedPostDebounceTime = 1000; // milli seconds
    this.feedPostClearTime = null;
    this.urlRegex = /(https?:\/\/[^\s]+)/gi;
    this.fileNameRestrictChar = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?~]/;
    this.maxNoOfAssetPOst = 10;
    this.resizeClearTime = null;
  }

  componentDidMount() {
    this.setState({
      user_filter_id: "",
      channel_filter_id: "",
      feedContainerWidth: document.getElementsByClassName(
        "ssi-feeds-feedsContainer"
      )[0].clientWidth,
      //feedsLanguageData: this.props.labels//feedsLanguageData[this.state.language_code].labels, //feedsLanguageData["en-US"].labels
    });
    window.addEventListener("resize", this.windowResizeHandler);
    this.state.feedContainerWidth = document.getElementsByClassName(
      "ssi-feeds-feedsContainer"
    )[0].clientWidth;
    this.relocateChannelBlock();
    this.getUserInfo();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.feedsTypes === "channel" &&
      prevProps.selectedChannelItem._id !== this.props.selectedChannelItem._id
    ) {
      this.setState({
        user_filter_id: "",
        channel_filter_id: "",
      });
      this.getChannelFeeds();
    }
    if(this.props.labels.labels !== prevProps.labels.labels) {
        this.setState({
          feedsLanguageData: this.props.labels.labels
        })
      }
  }
  componentWillUnmount() {
    this.setState({
      user_filter_id: "",
      channel_filter_id: "",
    });
    window.removeEventListener("resize", this.windowResizeHandler);
  }
  getUserInfo = () => {
    FeedsServices.feedsAPIServicesCall(
      "users",
      "get",
      this.userInfoResultHandler
    );
  };
  userInfoResultHandler = (resultData) => {
    this.setState(
      {
        userInfo: resultData.data,
      },
      () => {
        this.getFeedsData();
        if (this.state.feedsTypes === "default") {
          this.getChennalList();
        }
      }
    );
  };
  getFeedsData = () => {
    if (this.state.feedsTypes === "channel") {
      this.getChannelFeeds();
    } else {
      this.getDefaultFeedsData();
    }
  };
  getDefaultFeedsData = () => {
    FeedsServices.feedsAPIServicesCall("feeds/", "get", this.feedsResultData);
  };
  getChannelFeeds = () => {
    console.log("channels/" + this.props.selectedChannelItem._id + "/feeds")
    let apiUrl = "channels/" + this.props.selectedChannelItem._id + "/feeds";
    FeedsServices.feedsAPIServicesCall(apiUrl, "get", this.feedsResultData);
  };

  feedsResultData = (resultData) => {
    resultData =
      typeof resultData == "string" ? JSON.parse(resultData) : resultData;
    this.setState({
      feedsData: [...resultData.data],
      showLoading: 0,
      feedsLoading: false,
      showFeeds: 1,
    });
  };
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.state.feedsLanguageData && this.state.feedsLanguageData[paramLBL]
        ? this.state.feedsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  getChennalList = () => {
    let apiUrl = "channels";
    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "get",
      this.channelListResultHandler
    );
  };
  channelListResultHandler = (resultData) => {
    this.state.totalChannelData = [...resultData.data];
    let assignedChannelList = [];
    this.state.totalChannelData.map((channelItem) => {
      if (channelItem.active_status === 1 && channelItem.follow > 0) {
        assignedChannelList.push(channelItem);
      }
    });
    //assignedChannelList = [];
    if (assignedChannelList.length === 0) {
      this.setState({
        channelsData: [],
        totalChannelData: [],
        channelLoading: false,
        noPostAllowed: 1,
      });
    } else {
      this.setState({
        channelsData: [...assignedChannelList],
        channelLoading: false,
        noPostAllowed: 0,
      });
    }
  };
  openBrowseWindow = () => {
    this.setState({
      showEmoji: 0,
    });
    this.fileAttachRef.current.click();
  };
  fileAttachOnChangeHandler = async (evt) => {
    console.log(evt.target.files);
    // let ress = patt.test()
    // let validationResult;
    if (evt && evt.target && evt.target.files && evt.target.files.length) {
      //this.state.restrictedFileName = [];
      for (let i = 0; i < evt.target.files.length; i++) {
        console.log(evt.target);
        console.log(evt.target.files[i].size);
        let splitArr = String(evt.target.files[i].name).split(".");
        if (
          !this.fileAccptance.includes(
            String(splitArr[splitArr.length - 1]).toUpperCase()
          )
        ) {
          this.setState({
            showAlert: 1,
            alertMessage: `${this.getResourceFromLabels(
              "LBLINVALIDFILEFORMATMES"
            )} ${this.fileAccptance}`,
            attachedFiles: [],
          });
          break;
        } else if (
          evt.target.files[i].size >
          process.env.REACT_APP_UPLOAD_VIDEO_SIZE_LIMIT
        ) {
          this.setState({
            showAlert: 1,
            alertMessage: `${this.getResourceFromLabels(
              "LBLMAXFILESIZEALERTMSG"
            )} ${
              process.env.REACT_APP_UPLOAD_VIDEO_SIZE_LIMIT / (1024 * 1024)
            } MB.`,
            attachedFiles: [],
          });
          break;
        } else {

          this.state.attachedFiles.push(evt.target.files[i]);
          if (evt.target.files[i].type.match("image.*")) {
            this.getAttachedImages(evt.target.files[i]);
          } else if (evt.target.files[i].type.match("video.*")) {
            this.getAttachedVideo(evt.target.files[i]);
          }
        }
      }
      this.checkFileAttacheBtn();
    }

    evt.target.value = "";
    this.feedPostBtnStatus();
  };
  restrictSpecialCharForFileName = (fName) => {
    let fileName = fName.split(".");
    //let extension = fileName[fileName.length - 1];
    fileName.pop();
    fileName = fileName.join(".");
    let flag = this.fileNameRestrictChar.test(fileName);

    return flag;
  };
  getAttachedImages = (fileObj) => {
    this.setState({
      attachedFiles: this.state.attachedFiles,
    });
  };
  getAttachedVideo = (fileObj) => {
    this.setState({
      attachedFiles: this.state.attachedFiles,
    });
  };
  createPostHandler = (evt) => {
    console.log("selectedChannelIndex : ", this.state.selectedChannelIndex);
    if (
      this.state.selectedChannelIndex === -1 &&
      this.state.feedsTypes === "default"
    ) {
      this.setState({
        showAlert: 1,
        alertMessage: this.getResourceFromLabels("LBLCHOOSECHANNELFORPOSTMSG"),
      });
      return true;
    }
    this.setState({
      showEmoji: 0,
      isAssetsUploadedToS3: 0,
      isPostSavedToDB: 0,
      showLoading: 1,
    });
    if (
      this.state.attachedFiles.length ||
      document.getElementById("ssi-feeds-feedsPostTextArea").innerText
    ) {
      this.setState({
        feedPostBtnStatus: 0,
      });
      let postData = this.createFeedsPostJSON();
      let firstVideoAssetIndex = -1;
      this.setState({
        uploadMediaCount: postData.assets.length,
      });
      if (postData.assets.length) {
        for (let i = 0; i < postData.assets.length; i++) {
          if (postData.assets[i].asset_type === "video") {
            this.setState({
              videoUploader: true,
            });
            firstVideoAssetIndex = i;
            break;
          }
        }
      }
      if (firstVideoAssetIndex > -1) {
        console.log(postData);
        this.setState({
          feedsData1: postData,
        });
      } else {
        FeedsServices.feedsAPIServicesCall(
          "feeds",
          "post",
          this.feedsPostResultHandler,
          postData
        );
      }
    }
    this.setState({
      user_filter_id: "",
      channel_filter_id: "",
    });
    this.clearPostFields();
  };
  createFeedsPostJSON = () => {
    let postData = {};
  postData.description = document.getElementById(
      "ssi-feeds-feedsPostTextArea"
    ).innerText;
    postData.profile_id = this.state.userInfo.currentuser.profile_info.profile_id;
    postData.profile_info = {
      profile_id: this.state.userInfo.currentuser.profile_info.profile_id,
      active_status: 1,
    };
    if (this.state.feedsTypes === "channel") {
      postData.channel_info = {
        channel_name: this.props.selectedChannelItem.channel_name,
        channel_profile_image: this.props.selectedChannelItem
          .channel_profile_image,
        channel_id: this.props.selectedChannelItem._id,
        active_status: this.props.selectedChannelItem.active_status,
      };
    } else {
      postData.channel_info = {
        channel_name: this.state.channelsData[this.state.selectedChannelIndex]
          .channel_name,
        channel_profile_image: this.state.channelsData[
          this.state.selectedChannelIndex
        ].channel_profile_image,
        channel_id: this.state.channelsData[this.state.selectedChannelIndex]
          ._id,
        active_status: this.state.channelsData[this.state.selectedChannelIndex]
          .active_status,
      };
    }
    postData.og_content = [];
    if (this.state.ogUrls && this.state.ogUrls.length) {
      this.state.ogUrls.map((url) => {
        postData.og_content.push({
          heading: "",
          sub_heading: "",
          description: "",
          cover_image: "",
          link: url,
        });
      });
    }
    postData.assets = [];
    if (this.state.attachedFiles.length) {
      this.state.attachedFiles.map((item) => {
        let postAsset = {};
        // let timeStamp = new Date().getTime();
        let fileName = utils.generateFeedsPostFileName(item.name);
        postAsset.asset_name = fileName;
        //this.addTimeStampToFileName(item.name, timeStamp);
        postAsset.asset_type = item.type.match("image.*")
          ? "image"
          : item.type.match("video.*")
          ? "video"
          : "";
        if (item.type.match("video.*")) {
          postAsset.is_job_done = 0;
          postData.assets.push(postAsset);
        } else {
          postData.assets.push(postAsset);
        }
        this.uploadAssets(item, fileName, "", this.imageUploadCallBack);
      });
    }

    console.log(postData);

    return postData;
  };
  uploadAssets = (data, fileName, timeStamp, callBackFun) => {
    console.log(data);
    this.setState({
      uploadingSize: this.state.uploadingSize + data.size,
    });
    console.log(this.state.uploadingSize, this.state.uploadedSize);
    let folderPath = this.state.userInfo.currentuser.company_id + "/";
    let s3Upload = new S3MultiUpload(data, fileName, timeStamp, folderPath);
    s3Upload.start();
    this.setState((prevState) => ({
      uploadFiles: [...prevState.uploadFiles, data.name],
      ["upload_percent" + data.name]: 0,
    }));
    s3Upload.onProgressChanged = (uploadedSize, totalSize, bitrate) => {

      this.setState({
        uploadedSize: Math.round(
          (uploadedSize / this.state.uploadingSize) * 100
        ),
      });
      // console.log(this.state.uploadingSize, this.state.uploadedSize);

      if (uploadedSize < totalSize) {
        this.setState({
          ["upload_percent" + data.name]: Math.round(
            (uploadedSize / totalSize) * 100
          ),
        });
      } else if (uploadedSize === totalSize) {
       
        console.log(
          "iupllo",
          this.state.feedsData1,
          this.state.uploaded.length
        );
        if (this.state.feedsData1 !== null) {
          if (
            this.state.feedsData1.assets.length >= this.state.uploaded.length
          ) {
            this.state.feedsData1.assets.map((item) => {
              // if (item.asset_type !== 'image') {
              let file = {
                file_name: item.asset_name,
                uploaded: true,
                asset_type: item.asset_type,
              };
              let checkArray = this.state.uploaded.some(
                (item1) => item1.file_name === item.asset_name
              );
              if (!checkArray) {
                this.setState((prevState) => ({
                  uploaded: [...prevState.uploaded, file],
                }));
              }
              // }
            });
            // console.log(this.state.uploaded)
          }
        }
      }
    };

    s3Upload.onUploadCompleted = async (data) => {
      if (data) {
        this.setState({
          upload_completed: this.state.upload_completed + 1,
        });
      }
      console.log(data, this.state.feedsData1);
      console.log(this.state.upload_completed, this.state.uploaded.length);
      if (
        this.state.feedsData1 !== null &&
        this.state.uploaded.length === this.state.feedsData1.assets.length &&
        this.state.upload_completed === this.state.uploaded.length
      ) {
        console.log("completed");

        let i = 0;
        let signed_url = "";
        while (i < this.state.uploaded.length) {
          console.log(i, signed_url, this.state.uploaded[i].file_name);
          console.log(i, signed_url, this.state.uploaded[i].file_name);
          if (this.state.uploaded[i].asset_type === "video") {
            let assetFolder = String(this.state.uploaded[i].file_name).split(
              "."
            );
            assetFolder.pop();
            let postData1 = {
              filename: this.state.uploaded[i].file_name,
              assettype: "video",
              assetFolder: assetFolder.join(),
            };
            FeedsServices.feedsAPIServicesCall(
              "gets3signedassetsurl",
              "post",
              this.signedUrlResultHandler,
              postData1
            );
          }
          i++;
          // if (i === this.state.uploaded.length) {
          //     callBackFun(data);
          // }
        }
      }
      if (this.state.feedsData1 === null) {
        callBackFun(data);
      }
    };
  };
  signedUrlResultHandler = (resultData) => {
    console.log(resultData.data.data);
    let filter_video = [];
    filter_video = this.state.uploaded.filter(
      (item) => item.asset_type === "video"
    );
    console.log(filter_video);
    if (this.state.signedUrls.length <= filter_video.length) {
      if (this.state.signedUrls === []) {
        this.setState({ signed_url: [resultData.data.data] });
      } else {
        this.setState((prevState) => {
          this.setState({
            signedUrls: [...prevState.signedUrls, resultData.data.data],
          });
        });
      }
      if (this.state.signedUrls.length === filter_video.length) {
        setTimeout(() => {
          FeedsServices.feedsAPIServicesCall(
            "feeds",
            "post",
            this.feedsPostResultHandler,
            this.state.feedsData1
          );
        }, 1000);
        // this.checkStream();
      }
    }
    console.log(this.state.signedUrls);
  };
  checkStream = () => {
    fetch(this.state.signedUrls[this.state.streamed], {
      retryOn: function (attempt, error, response) {
        // retry on any network error, or 4xx or 5xx status codes
        // console.log(error, response);
        if (error !== null || response.status >= 403) {
          console.log("Try", response);
          return true;
        }
      },
    }).then((response) => {
      console.log(response);
      if (response.status === 200) {
        this.setState({
          streamed: this.state.streamed + 1,
        });
        if (this.state.streamed === this.state.signedUrls.length) {
          console.log("streamed");
          FeedsServices.feedsAPIServicesCall(
            "feeds",
            "post",
            this.feedsPostResultHandler,
            this.state.feedsData1
          );
          this.setState({
            feedsData1: null,
            uploaded: [],
            //processing: [],
            signedUrls: [],
            streamed: 0,
            processing: false,
            videoUploader: false,
          });
        } else {
          this.checkStream();
        }
      }
    });
  };

  feedsPostResultHandler = (resultData) => {
    console.log(resultData);
    if (resultData.data && resultData.data._id) {
      this.setState({
        feedsData1: null,
        posted: 0,
        uploadFiles: [],
        totalAssets: 0,
        upload_completed: 0,
        selectedChannelIndex: -1,
        ogUrls: [],
      });
      resultData.data.newpost = 1;
      this.state.feedsData.unshift(resultData.data);
      this.setState(
        {
          isPostSavedToDB: 1,
          feedsData1: null,
          posted: 0,
          signedUrls: [],
          upload_completed: 0,
          uploadingSize: 0,
          uploadedSize: 0,
          uploaded: [],
          videoUploader: false,
        },
        () => {
          this.updateFeedsBlock();
        }
      );
    }else{

      this.updateFeedsBlock();
    }
  };
  addTimeStampToFileName = (fName, timeStampChars) => {
    let fileName = fName.split(".");
    let construct_name = [];
    construct_name.push(timeStampChars);
    construct_name.push(fileName[fileName.length - 1]);
    // fileName[fileName.length - 2] = fileName[fileName.length - 2] + timeStampChars;
    return construct_name.join(".");
  };
  imageUploadCallBack = (data) => {
    this.setState({
      isAssetsUploadedToS3: this.state.isAssetsUploadedToS3 + 1,
      upload_completed: 0,
      signedUrls: [],
      feedsData1: null,
      uploadingSize: 0,
      uploadedSize: 0,
    });
  };
  updateFeedsBlock = () => {
    if (
      this.state.isAssetsUploadedToS3 === this.state.attachedFiles.length &&
      this.state.isPostSavedToDB === 1
    ) {
      this.clearPostFields();
      this.getFeedsData();
      this.setState({
        showLoading: 0,
      });
    }else{

      this.getFeedsData();
      this.setState({
        showLoading: 0,
      });
    }
  };
  checkFileAttacheBtn = () => {
    let flag = this.state.attachedFiles.length >= this.maxNoOfAssetPOst ? 0 : 1;
    this.setState({
      fileAttacheBtnEnable: flag,
    });
  };
  clearPostFields = () => {
    document.getElementById("ssi-feeds-feedsPostTextArea").innerText = "";
    this.setState(
      {
        attachedFiles: [],
        isAssetsUploadedToS3: 0,
        isPostSavedToDB: 0,
        fileAttacheBtnEnable: 1,
        chosenEmoji: {},
        showEmoji: 0,
      },
      () => {
        this.feedPostBtnStatus();
      }
    );
  };
  removePostAssets = (index) => {
    this.state.attachedFiles.splice(index, 1);
    this.setState({
      attachedFiles: this.state.attachedFiles,
    });
    this.checkFileAttacheBtn();
    this.feedPostBtnStatus();
  };
  showImojiPickerHandler = () => {
    this.setState(
      {
        showEmoji: this.state.showEmoji === 1 ? 0 : 1,
      },
      () => {
        if (this.state.showEmoji === 1) {
          document.addEventListener("click", this.emojiOutsideClickHandler);
        }
      }
    );
  };
  onEmojiClickHandler = (evt, emojiObj) => {
    this.setState(
      {
        chosenEmoji: emojiObj,
      },
      () => {
        document.getElementById(
          "ssi-feeds-feedsPostTextArea"
        ).innerText += this.state.chosenEmoji.emoji;
        this.feedPostBtnStatus();
      }
    );
  };
  emojiOutsideClickHandler = (evt) => {
    let elements = evt.target; //document.getElementById("target");
    let els = [];
    let classAtr = "";
    while (elements) {
      els.unshift(elements);
      elements = elements.parentNode;
      classAtr =
        elements && elements.getAttribute && elements.getAttribute("class")
          ? elements.getAttribute("class")
          : "";
      if (classAtr === "ssi-feeds-feedsPostEmojiHolder") {
        break;
      }
    }
    console.log("classAtr", classAtr);
    if (!classAtr || classAtr !== "ssi-feeds-feedsPostEmojiHolder") {
      document.removeEventListener("click", this.emojiOutsideClickHandler);
      this.setState({
        showEmoji: 0,
      });
    }
  };

  reomoveFeedBlock = (data) => {
    let deletUrl = "feeds/" + data._id;
    FeedsServices.feedsAPIServicesCall(
      deletUrl,
      "delete",
      this.feedsDeleteResultHandler
    );
  };
  feedsDeleteResultHandler = (resultData) => {

    this.updateFeedsBlock();
    // this.clearPostFields();
    // this.getFeedsData();
  };
  sendFeedComment = (data, commentText) => {
    let param = {
      comment_text: commentText, //,
      //"profile_info":this.state.userInfo.profile_info
    };
    let apiUrl = "feeds/" + data._id + "/comment";
    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "put",
      this.feedsCommentsResultHandler,
      param
    );
  };
  feedsCommentsUpdateResultHandler = (resultData) => {
    this.setState({
      showLoading: 0,
    });
  };
  feedsCommentsResultHandler = (resultData) => {
    if(this.state.feedsTypes !== "channel"){

      this.clearPostFields();
    }
    //this.getFeedsData();
    let feedIndex = this.getCurrentFeedIndexByFeedId(resultData.data[0]._id);
    this.state.feedsData[feedIndex] = resultData.data[0];
    this.setState({
      showLoading: 0,
    });
  };
  sendFeedLikeAction = (data) => {
    let param = {
      like_status:
        data.like_status === undefined && data._id ? 0 : data.like_status,
    };
    let apiUrl = "feeds/" + data._id + "/likes";
    console.log(param);
    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "put",
      this.feedsLikeResultHandler,
      param
    );
  };
  feedsLikeResultHandler = (resultData) => {
    if(this.state.feedsTypes !== "channel"){
      this.clearPostFields();
    };
    let feedIndex = this.getCurrentFeedIndexByFeedId(resultData.data[0]._id);
    this.state.feedsData[feedIndex] = resultData.data[0];
    this.setState({
      showLoading: 0,
    });
  };
  getCurrentFeedIndexByFeedId = (feedId) => {
    let index = -1;
    for (let i = 0; i < this.state.feedsData.length; i++) {
      if (this.state.feedsData[i]._id === feedId) {
        index = i;
        break;
      }
    }
    return index;
  };
  feedPostTextAreaHandler = (evt) => {
    this.feedPostBtnStatus();
    clearTimeout(this.feedPostClearTime);
    this.feedPostClearTime = setTimeout(
      this.feedPostTextDebounce,
      this.feedPostDebounceTime
    );
  };
  feedPostTextDebounce = () => {
    console.log("feedPostTextDebounce");
    let postVal = document.getElementById("ssi-feeds-feedsPostTextArea")
      .innerText;
    this.splitOGContent(postVal);
  };
  splitOGContent = (postVal) => {
    const urls = postVal.match(this.urlRegex);
    console.log("urls", urls);
    this.setState({
      ogUrls: urls,
    });
  };
  feedPostBtnStatus = () => {
    this.setState({
      feedPostBtnStatus:
        String(
          document.getElementById("ssi-feeds-feedsPostTextArea").innerText
        ).trim() || this.state.attachedFiles.length
          ? 1
          : 0,
    });
  };
  joinChannelHandler = (evt) => {
    let index = Number(evt.target.getAttribute("index"));
    let selData = this.state.channelsData[index];
    console.log(selData);
    let param = {
      follow: selData.follow,
    };
    let apiUrl = "channels/" + selData._id + "/follow";
    console.log(param);
    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "put",
      this.channelsFollowResultHandler,
      param
    );
  };
  channelsFollowResultHandler = (resultData) => {
    this.getChennalList();
    this.getFeedsData();
  };
  loadIndividualChannelFeeds = (evt) => {
    let channelId = evt.currentTarget.getAttribute("data-channelId");
    this.setState(
      {
        channel_filter_id: channelId,
        user_filter_id: "",
      },
      () => {}
    );
    if (
      document.getElementsByTagName("html")[0] &&
      document.getElementsByTagName("html")[0].scrollTop
    ) {
      document.getElementsByTagName("html")[0].scrollTop = 0;
    }
  };
  reloadFeedsHandler = (evt) => {
    this.setState({
      user_filter_id: "",
      channel_filter_id: "",
      feedsLoading: true,
      selectedChannelIndex: -1,
    });
    this.getDefaultFeedsData();
    if (
      document.getElementsByTagName("html")[0] &&
      document.getElementsByTagName("html")[0].scrollTop
    ) {
      document.getElementsByTagName("html")[0].scrollTop = 0;
    }
  };
  getSelectedFeeds = (type, id) => {
    //let apiUrl = "";
    if (type === "user") {
      this.setState({
        user_filter_id: id,
        channel_filter_id: "",
      });
      // apiUrl = "users/" + id + "/feeds";
    } else if (type === "channel") {
      this.setState({
        user_filter_id: "",
        channel_filter_id: id,
      });

      // apiUrl = "channels/" + id + "/feeds";
    }
  };
  windowResizeHandler = (evt) => {
    clearTimeout(this.resizeClearTime);
    this.resizeClearTime = setTimeout(this.resizeDebounceHandler, 300);
    this.state.feedContainerWidth = document.getElementsByClassName(
      "ssi-feeds-feedsContainer"
    )[0].clientWidth;
  };
  resizeDebounceHandler = () => {
    this.relocateChannelBlock();
  };
  relocateChannelBlock = () => {
    let replaceElement;
    let container;
    //let appendBeforeElement;
    if (this.state.feedContainerWidth < 700) {
      replaceElement = document.getElementsByClassName(
        "ssi-feeds-feedsChannelContainer"
      )[0];
      container = document.getElementsByClassName(
        "ssi-feeds-channelBlockForMobile"
      )[0];
      if (container && replaceElement) {
        container.appendChild(replaceElement);
      }
    } else {
      replaceElement = document.getElementsByClassName(
        "ssi-feeds-feedsChannelContainer"
      )[0];
      container = document.getElementsByClassName(
        "ssi-feeds-feedsContainer"
      )[0];
      container.appendChild(replaceElement);
    }
  };
  channelChangeHandler = (evt) => {
    this.setState({
      selectedChannelIndex: Number(evt.target.value),
    });
  };
  closeAlertHandler = (evt) => {
    this.setState({
      showAlert: 0,
      alertMessage: "",
    });
  };
  deleteComments = (commentid, feedid) => {
    let deletUrl = "feeds/" + feedid + "/comments/" + commentid;
    FeedsServices.feedsAPIServicesCall(
      deletUrl,
      "delete",
      this.feedsDeleteResultHandler
    );
  };
  updateComments = (commentText, commentid, feedid) => {
    let param = {
      comment_text: commentText,
    };
    let apiUrl = "feeds/" + feedid + "/comments/" + commentid;
    FeedsServices.feedsAPIServicesCall(apiUrl, "put", "", param);
  };

  

  render() {
   
    return (
      <div className="ssi-feeds-feedsContainer">
        <div
          className={
            this.state.showFeeds === 1
              ? "ssi-feed-feedPageFeedsHolder"
              : "ssi-feed-feedPageFeedsHolder"
          }
        >
          <div className="ssi-feeds-channelBlockForMobile"></div>

          {this.state.feedsLoading ? (
            <div className="ssi-feeds-feedsReadContainer">
              <div className="ssi-feeds-readContainerWrapper">
                {skel.map((i) => {
                  return (
                    <div className="ssi-feeds-displayBlockContainer" key={i}>
                      <div className="ssi-feeds-displayBlockHeaderContainer">
                        <div className="ssi-feeds-blockHeaderProfileContainer">
                          <div className="ssi-feeds-blockHeaderUserContainer">
                            <div className="ssi-feeds-profileImageContainer">
                              <Skeleton circle={true} width={35} height={35} />
                            </div>
                            <div className="ssi-feeds-displayProfileName">
                              <div className="blockProfileName">
                                <Skeleton height={17} width={200} />
                              </div>
                              <div className="ssi-feeds-displayBlockTimeMsg">
                                <span>
                                  <Skeleton height={15} width={100} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ssi-feeds-displayBlockDescriptionContainer">
                        <div className="ssi-feeds-displayBlockDescriptionText">
                          <span>
                            <Skeleton height={17} width={350} />
                          </span>
                        </div>
                      </div>
                      <div className="ssi-feeds-displayBlockContentContainer">
                        <div
                          className="ssi-feeds-displayBlockContentWrapper"
                          style={{ height: "400px" }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="ssi-feeds-feedsReadContainer">
              <div className="ssi-feeds-readContainerWrapper">
                
                {this.state.feedsData.length &&
                this.state.noPostAllowed === 0 ? (
                  this.state.feedsData
                    .filter((name) =>
                      name.profile_id !== undefined
                        ? name.profile_id.toString().includes(this.state.user_filter_id) &&
                          name.channel_info.channel_id.toString().includes(
                            this.state.channel_filter_id
                          )
                        : null
                    )
                    .map((feedItem, index) => {
                      // this.state.feedsData.map((feedItem, index) => {
                      if (
                        feedItem.description ||
                        (feedItem.assets && feedItem.assets.length)
                      ) {
                        if (feedItem.comments && feedItem.comments.length) {
                          // feedItem.comments.reverse();
                        }
                        return (
                          <FeedDisplayBlock
                            index={index}
                            userInfo={this.state.userInfo}
                            key={feedItem._id}
                            feedItemData={feedItem}
                            channelsData={this.props.channelsData}
                            //userInfo={this.state.userInfo}
                            reomoveFeedBlock={this.reomoveFeedBlock}
                            sendFeedComment={this.sendFeedComment}
                            sendFeedLikeAction={this.sendFeedLikeAction}
                            deleteComments={this.deleteComments}
                            updateComments={this.updateComments}
                            getSelectedFeeds={this.getSelectedFeeds}

                            feedsPostResultHandler={this.feedsPostResultHandler}
                            feedsLanguageData={this.state.feedsLanguageData}
                            hideComment={this.props.hidePost}
                            page={this.props.page}
                            
                           role_id={this.props.role_id}
                            langCode={this.props.language_code}
                          />
                        );
                      }
                      // }
                    })
                ) : (
                  <div className="ssi-feeds-readContaineNoFeedData">
                  
                    <span>
                      {this.state.noPostAllowed === 0
                        ?<EmptyState image={emptyImage} text2={this.getResourceFromLabels("LBLNOFEEDSCREATEPOST")} />
                        :<EmptyState image={emptyImage} text2={ this.getResourceFromLabels("LBLNOCHANNELASSIGNMSG")} />
                        } 
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.showAlert === 1 ? (
            <div className="ssi-feeds-alertContainer">
              <div className="ssi-feeds-alertContainerWrapper">
                <div className="ssi-feeds-alertContainerInnerWrapper">
                  <div className="ssi-feeds-alertHeaderContainer">
                    <div className="ssi-feeds-alertHeaderWrapper">
                      <div className="ssi-feeds-alertHeaderText">
                        <span>{this.getResourceFromLabels("LBLALERT")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ssi-feeds-alertContentContainer">
                    <div className="ssi-feeds-alertContentWrapper">
                      <div className="ssi-feeds-alertContentText">
                        <span>{this.state.alertMessage}</span>
                      </div>
                    </div>
                  </div>
                  <div className="ssi-feeds-aletFooterContainer">
                    <div className="ssi-feeds-aletFooterWrapper">
                      <button
                        className="ssi-feeds-alertOkBtn"
                        onClick={this.closeAlertHandler}
                      >
                        {this.getResourceFromLabels("LBLOK")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {this.state.feedsTypes === "default" ? (
          <div
            className={
              this.state.showFeeds === 1
                ? "ssi-feeds-feedsChannelContainer"
                : "ssi-feeds-feedsChannelContainer"
            }
          >
            <div className="ssi-feeds-feedsChannelContainerWrapper">
              <div className="ssi-feeds-feedsChannelNavContainer">
                <div className="ssi-feeds-feedsChannelNavWrapper">
                  <div className="ssi-feeds-feedsChannelNavHeader">
                    <div className="ssi-feeds-feedsChannelNavHeaderText">
                      <span>{this.getResourceFromLabels("LBLEXPLORE")}</span>
                    </div>
                  </div>
                  <div className="ssi-feeds-channelExploreContent">
                    <div
                      className={
                        this.state.noPostAllowed === 0
                          ? "ssi-feeds-feedsChannelNavStrip feedsStory"
                          : "ssi-feeds-feedsChannelNavStrip feedsStory disable"
                      }
                      onClick={this.reloadFeedsHandler}
                    >
                      <span className="channelNavIcon fas fa-rss"></span>
                      <span className="channelNavText">
                        {this.getResourceFromLabels("LBLSTORYFEEDS")}
                      </span>
                    </div>
                    {this.state.role_id && this.state.role_id === 1 ? (
                      <Link to="/schome/manage-channels">
                        <div className="ssi-feeds-feedsChannelNavStrip">
                          <span className="channelNavIcon fas fa-plus-square"></span>
                          <span className="channelNavText">
                            {this.getResourceFromLabels("LBLMANAGECHANNELS")}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                    {this.state.role_id && this.state.role_id === 1 ? (
                      <Link to="/schome/watch-channels">
                        <div className="ssi-feeds-feedsChannelNavStrip">
                          <span className="channelNavIcon fas fa-broadcast-tower"></span>
                          <span className="channelNavText">
                            {this.getResourceFromLabels("LBLMODERATECHANNELS")}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="ssi-feeds-feedsChannelListContainer">
                <div className="ssi-feeds-feedsChannelListWrapper">
                  <div className="ssi-feeds-feedsChannelHeader">
                    <div className="ssi-feeds-feedsChannelHeaderText">
                      <span>{this.getResourceFromLabels("LBLCHANNELS")}</span>
                    </div>
                  </div>
                  {this.state.channelLoading ? (
                    // <Loader></Loader>
                    <div className="ssi-feeds-feedsChannelListHolder web-skull">
                      <div className="ssi-feeds-feedsChannelListHolderWrapper">
                        {skel.slice(0, 5).map((i) => {
                          return (
                            <div
                              className="ssi-feeds-feedsChannelItemContainer"
                              key={i}
                            >
                              <div className="ssi-feeds-feedsChannelItemContainerWrapper">
                                <div className="ssi-feeds-profileImageContainer">
                                  <Skeleton
                                    circle={true}
                                    height={35}
                                    width={35}
                                  />
                                </div>
                                <div className="ssi-feeds-feedsChannelItemText">
                                  <div className="txt">
                                    <Skeleton height={15} width={100} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="ssi-feeds-feedsChannelListHolder">
                      <div className="ssi-feeds-feedsChannelListHolderWrapper">
                        {this.state.channelsData.length ? (
                          this.state.channelsData.map((channelItem, index) => {
                            if (
                              channelItem.active_status === 1 &&
                              channelItem.follow > 0
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="ssi-feeds-feedsChannelItemContainer"
                                  data-channelId={channelItem._id}
                                  data-channelIndex={index}
                                  onClick={this.loadIndividualChannelFeeds}
                                >
                                  <div className="ssi-feeds-feedsChannelItemContainerWrapper">
                                    <ProfileImageDisplay
                                      imageName={
                                        channelItem.channel_profile_image
                                      }
                                      firstLetterDisplay={""}
                                      placeHolderType={"channel"}
                                    />
                                    <div className="ssi-feeds-feedsChannelItemText">
                                      <div className="txt">
                                        <span>{channelItem.channel_name}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <div className="ssi-feeds-feedsNoChannelItemContainer">
                            <div>
                              <span>
                                {this.getResourceFromLabels("LBLNOCHANNELS")}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="ssi-feeds-feedsChannelListHolderWrapperTwo">
                        <Carousel
                          ssr
                          partialVisbile
                          //deviceType={deviceType}
                          itemClass="image-item"
                          responsive={responsive}
                          containerClass="carousel-container"
                        >
                          {this.state.channelsData.length ? (
                            this.state.channelsData.map(
                              (channelItem, index) => {
                                if (
                                  channelItem.active_status === 1 &&
                                  channelItem.follow > 0
                                ) {
                                  return (
                                    <div
                                      key={index}
                                      className="ssi-feeds-feedsChannelItemContainer"
                                      data-channelId={channelItem._id}
                                      data-channelIndex={index}
                                      onClick={this.loadIndividualChannelFeeds}
                                    >
                                      <div
                                        //className="ssi-feeds-channelListingCarousel"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <ProfileImageDisplay
                                          imageName={
                                            channelItem.channel_profile_image
                                          }
                                          firstLetterDisplay={""}
                                          placeHolderType={"channel"}
                                        />
                                        <p
                                          style={{
                                            //position: "absolute",
                                            //left: "50%",
                                            //bottom: 0,
                                            color: "black",
                                            textAlign: "center",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            margin: "5px 0px",
                                            //transform: " translateX(-70%)",
                                          }}
                                        >
                                          {channelItem.channel_name}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )
                          ) : (
                            <div className="ssi-feeds-feedsNoChannelItemContainer">
                              <div>
                                <span>
                                  {this.getResourceFromLabels("LBLNOCHANNELS")}
                                </span>
                              </div>
                            </div>
                          )}
                        </Carousel>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

//export default Feeds;
const mapStateToProps = (state) => {
  return {
    language_code: state.user_info_reducer.user_info.language_code,
    role_id: state.user_info_reducer.user_info.role_id,
  };
};
export default connect(mapStateToProps, null)(Feeds);

import React, { useState, useEffect, useRef } from "react";
import Coursepopup from "../coursepopup/Coursepopup";
import products_services from "../../../services/products_services";
import utils from "../../../utils/utils";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import emptyImage from "../../../assets/images/Courses_Emptyst.png";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import AboutProduct from "./AboutProduct";
import "./products.scss";
import UnitListingSkeleton from "./UnitListingSkeleton";
import UnitList from "./UnitList";
import { useSelector,useDispatch } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Survey from "../../../survey/Survey";
import TransitionPopUp from "./TransitionPopUp";
import survey_services from "../../../survey/survey_services";
import SurveyAlert from "../../../survey/SurveyAlert";
import styles from "./AboutProduct.module.scss"
// import Lottie from 'react-lottie';
//import Lottie from 'react-lottie-player'
import white from '../../../assets/images/karate/White_Belt.json'
import yellow from '../../../assets/images/karate/Yellow_Belt.json'
import green from '../../../assets/images/karate/Green_Belt.json'
import orange from '../../../assets/images/karate/Orange_Belt.json'
import brown from '../../../assets/images/karate/Brown_Belt.json'
import blue from '../../../assets/images/karate/Blue_Belt.json'
import black from '../../../assets/images/karate/Black_Belt.json'
//import productListJson from './productList.json'
const moment = require("moment");
const cstLabels = {};
const UnitListingPage = (props) => {

  const [showPopup, setShowPopup] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [checkUnitsAvailable, setCheckUnitsAvailable] = useState(null);
  const [CourseCompletionCount, setCourseCompletionCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [resultDataString, setResultDataString] = useState(null);
  const [showToast, setShowToast] = useState({
    show: false,
    alertClass: "",
    status: "",
    message: "",
    changeInToast: new Date(),
  });
  const [productDetails, setProductDetails] = useState({});
  const [activeTabUnits, setActiveTabUnits] = useState(false);
  const [isSurveyStarted, setSurveyStarted] = useState(false);
  const [TotalUnitsCount, setTotalUnitsCount] = useState(0);
  const [productid, setProductID] = useState(null);
  const [unitid, setUnitID] = useState(null);
  const [unitedetails, setUnitedetails] = useState({});
  const [feedbackData,setFeedbackData] = useState({});
  const [showFeedbackPopUp,setShowFeedbackPopUp] = useState(false);
  const [showFeedbackTransition,setShowFeedbackTransition] = useState(true);
  // const [activeTabs,setActiveTabs] = useState(0);
  const [feedBackCompleted,setFeedbackCompleted] = useState(false);
  const [showEvalution,setShowEvalution] = useState(false);
  const [labels, setLabels] = useState({});
  const [postSurveyData,setPostSurveyData] = useState({});
  const [showPostSurvey,setShowPostSurvey] = useState(false);
  const [showPostSurveyAlert,setShowPostSurveyALert] = useState(false);
  const [gamificationDetails, setGamificationDetails] = useState([]);
  const [gameAvailable, setGameAvailable] = useState(false); 
  const [currentBelt, setCurrentBelt] = useState(null);
  const [nextBelt, setNextBelt] = useState(null);
  const beltAnimation = {
    "white" : white,
    "yellow" : yellow,
    "green" : green,
    "orange" : orange,
    "brown" : brown,
    "blue" : blue,
    "black" : black,
  }
  const userInformaton = useSelector(state => state.user_info_reducer.user_info);
  const showToastMessage = () => {
    setShowToast({
      show: true,
      alertClass: 'success',
      status: 'Success',
      message: props.labels.labels.LBLUNIT1ALERTMSG,
      changeInToast: new Date(),
    });

    setTimeout(() => {
      setShowToast({
        show: false,
        alertClass: '',
        status: '',
        message: '',
        changeInToast: new Date(),
      });
    }, 5000);
  };
  console.log(showToast, "showToast")

  const showCourseDetails = (vProductunitKey, vEngineType, activityDetails) => {
    const vUnitsInfoID = resultData[vProductunitKey].unitsinfoid;
    const vProductUnitID = resultData[vProductunitKey].unitid;
    const vProductID = resultData[vProductunitKey].productid;
    const vCourseCompletionStatus = resultData[vProductunitKey].CourseCompletionStatus;
    const vUnitname = resultData[vProductunitKey].units;
    const vUnitDesc = resultData[vProductunitKey].description;
    const vLanguageCode = resultData[vProductunitKey].languagecode;
    const vEngineVersion = resultData[vProductunitKey].engineversion;
    let vCourseID, vActivityID, vCapsuleID, vCourseVersion;

    if (vEngineType === 'Capsule') {
      vActivityID = activityDetails.activity_id;
      vCapsuleID = activityDetails.capsule_id;
      vCourseID = activityDetails.capsule_content_id;
      vCourseVersion = activityDetails.capsule_content_version;
    } else {
      vCourseID = resultData[vProductunitKey].courseid;
      vActivityID = resultData[vProductunitKey].activity_id;
      vCourseVersion = resultData[vProductunitKey].course_version;
    }

    const vCourseDet = {
      courseid: vCourseID,
      unitcompletionstatus: vCourseCompletionStatus,
      unitname: vUnitname,
      unitdesc: vUnitDesc,
      unitsinfoid: vUnitsInfoID,
      languagecode: vLanguageCode,
      courseversion: vCourseVersion,
      engineType: vEngineType,
      activityid: vActivityID,
      capsuleid: vCapsuleID,
      trainingType: 'CourseUnits',
      engineVersion: vEngineVersion,
    };
    setProductID(vProductID);
    setUnitID(vProductUnitID);
    setUnitedetails(vCourseDet);
    setShowModalDetails(false);
    setShowPopup(!showPopup);
    setCourseCompletionCount(0);
  };
  const goToCapsuleList = (index) => {
    console.log("goto capsule list page")
    if (index !== undefined) {
      let capsuledata = resultData[index];
      let capsules = resultData[index].activities.length;
      capsuledata.activities = capsuledata.activities.sort(sortorder);
      if (resultData[index].sequential_access_unit !== null) {
        resultData[index].activities.map((a, i) => {
          if (resultData[index].capsule_count > 0) {
            console.log("capsuleif")
            loadCapsuleList(index, capsules);
          }
          return null;
        });
      }
    } else {
      loadProdctUnitList();
    }
  };

  const loadCapsuleList = (index, capsules) => {
    if (TotalUnitsCount > 0) {
      const pathname = props.location.pathname === "/schome/product-lists" ? "/schome/capsule-list" : "/home/capsule-list";
      props.history.push({
        pathname: pathname,
        state: {
          capsuleUnits: capsules,
          indexId: index,
          productID: resultData[index].productid,
          unitID: resultData[index].unitid,
          productName: resultData[index].products,
          bgThumbnail: props.location.state.bgThumbnail,
          resultDataString: resultDataString,
          isModule: props?.location?.state?.isModule
        },
      });
    }
  };

  useEffect(() => {
    loadProdctUnitList();
    return () => {
      setShowEvalution(false);
      setGamificationDetails([]);
      setGameAvailable(false);
      setCurrentBelt(null);
      setNextBelt(null);
    };
  }, []);
  
  const loadProdctUnitList = async () => {
    console.log('loadProdctUnitList ')
    if (props.location.state === null) {
      props.history.push("/home/products");
    } else {
      var vParams = {
        userid: userInformaton?.user_id,
        productid: props?.location?.state?.productid,
        productidnext: props?.location?.state?.productid,
        languagecode: userInformaton?.language_code,
      };
      await products_services
        .getproductunits(vParams)
        .then((res) => res)
        .then((data) => {
          console.log('loadProdctUnitList ',data?.data)
          // gamification setting starts
          setGamificationDetails(data?.data?.gamification_details)
          if (data?.data?.gamification_details && data?.data?.gamification_details.length) {
            setGameAvailable(true);
            const array = data?.data?.gamification_details;
            const checkBelt = (element) => element.earned_badge === 0;
            const hasNoBelt = array.every(checkBelt)
            if (hasNoBelt) {
              setCurrentBelt(data?.data?.gamification_details[0])
              setNextBelt(data?.data?.gamification_details[1])
            } else {
              const inProgress = array.filter((element) => element.earned_badge === 1);
              if (inProgress.length) {
                setCurrentBelt(inProgress[0]);
                array.filter((element, index) => {
                  if (element.earned_badge && element.earned_badge === 1) {
                    setNextBelt(array[index + 1])
                  }
                });
              }else {
                let next = array.filter((element) => element.earned_badge === 0);
                if(next.length){
                  setCurrentBelt(next.length ? next[0] : null);
                  setNextBelt(next.length > 1 ? next[1] : null);
                }else{
                  setCurrentBelt(null);
                  setNextBelt(null);
                  // const nextAchiev = array.filter((element, index) => {
                  //   if (element.earned_badge && element.earned_badge === 1) {
                  //     console.log('nextAchiev', array[index + 1])
                  //     setNextBelt(array[index + 1])
                  //   } else if (element.earned_badge && element.earned_badge === 2) {
                  //     console.log('nextAchiev', array[index + 1])
                  //     setNextBelt(array[index + 1])
                  //   }
                  // });
                }

              }

            }
          }
          // gamification setting ends
          let pre_survey = 0;
          let unitsList = data.data.units;
          setSurveyStarted(true);
          unitsList?.forEach((x, index) => {
            if (pre_survey === 1 && unitsList[0].CourseCompletionStatus !== "Completed") {
              x.sequential_access_unit = 1;
            }

            if (unitsList[index].CourseCompletionStatus === "Completed") {
              setCourseCompletionCount((prevCount) => prevCount + 1);
            }
          });
          setResultData(data?.data?.units);
          // setFeedbackData({...data.data,showFeedBack:1,feedbackId:"903f2fb0-0208-11ef-82d5-0ada3931a943"})
          setCheckUnitsAvailable(data?.data?.data);
          setProductDetails(data?.data?.prod_details);
          setTotalUnitsCount(data?.data?.units?.length);
          setLoading(false);
          if(data?.data?.units){
            extractedResult(data?.data?.units);
          }
          // initialActiveTab(data?.data?.prod_details);
          fetchFeedbackTransltion(data?.data?.units);
          console.log(userInformaton.survey,"unitlistingsurveyid")
          // userInformaton.survey is used to validate user completed the pre-survey or not.
          if(userInformaton.survey === "") {
            checkPostSurvey();
          }
        });
    }
  };
  const extractedResult = (unitData) => {
    console.log(unitData,"resultDataextractedResult")
    const extractedData = [];
    for (const item of unitData ) {
      let activesData = [];
      const activities = item.activities;
      if (activities.map(item => item.mapped_id !== null)) {
        const foundItem = activities.find(item => item.capsule_content_type === "LEARNINGOBJECT");
        if (foundItem !== undefined) {
          const activitesObj = {
            capsule_content_name: foundItem.capsule_content_name !== undefined ? foundItem.capsule_content_name.replace('#', '\$') : null,
            trigger_status: foundItem.trigger_status,
            capsule_content_image_m: foundItem.capsule_content_image,
            capsule_content_id: foundItem.capsule_content_id,
            capsule_drip_days: foundItem.casule_drip_days
          }
          activesData.push(activitesObj);
        }
      }
      const extractedObject = {
        unit_drip_days: item.unit_drip_days,
        description: item.description,
        activity_id: item.activity_id,
        course_version: item.course_version,
        units: item.units,
        userId: item.userId,
        companyId: item.companyId,
        languagecode: item.languagecode,
        unitsinfoid: item.unitsinfoid,
        courseid: item.courseid,
        unitid: item.unitid,
        productid: item.productid,
        prod_unit_imagename: item.prod_unit_imagename,
        CourseCompletionDate: item.CourseCompletionDate,
        CourseCompletionStatus: item.CourseCompletionStatus,
        activities: activesData,
        unit_launch_type: item.unit_launch_type
      };

      extractedData.push(extractedObject);
    }
    const resultDataString = extractedData !== null && JSON.stringify(extractedData);
    setResultDataString(resultDataString);
  };

  const checkfeedback = () => {
    var vParams = {
      userid: userInformaton.user_id,
      productid: props.location.state.productid,
    };
    survey_services.getfeedback(vParams).then((res) => {
      setFeedbackData(res?.data);
     console.log(res?.data,"feedbackresponse");
    })
    .catch((error) => {
      console.error("Error while fetching Feedback status:", error);
    });
  };
  
  const checkPostSurvey = () => {
    var vParams = {
      userid: userInformaton.user_id,
    };
    survey_services.getpostsurvey(vParams).then((res) => {
      setPostSurveyData(res?.data);
      setShowPostSurveyALert(res?.data.survey_id);
     if(res?.data.survey_id === "") {
      checkfeedback();
     }
    })
    .catch((error) => {
      console.error("Error while fetching postSurveyData status:", error);
    });
  };

  const showFeedbackToast = (message) => {
    setShowToast({
      show: true,
      alertClass: 'success',
      status: 'Success',
      message: message,
      changeInToast: new Date(),
    });

    setTimeout(() => {
      setShowToast({
        show: false,
        alertClass: '',
        status: '',
        message: '',
        changeInToast: new Date(),
      });
      checkfeedback();
    }, 2000);
  };

  const fetchFeedbackTransltion =  async (units) => {
    try {
      const url = `${process.env.REACT_APP_YOUFACTORS_TRANSLATION_URL}products/products.json`;
      const response = await fetch(url, { cache: "force-cache" });
      if (response.status === 200) {
        const data = await response.json();
        console.log(data[`${units[0].languagecode}`] ? data[`${units[0].languagecode}`].labels : data["en-US"].labels, "dataaa");
        setLabels(data[`${units[0].languagecode}`] ? data[`${units[0].languagecode}`].labels : data["en-US"].labels)
      } else {
        console.log("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortorder = (a, b) => {
    if (a.capsule_order < b.capsule_order) {
      return -1;
    }
    if (a.capsule_order > b.capsule_order) {
      return 1;
    }
    return 0;
  };

  const togglePopup = (e) => {
    let vProductunitKey, vProductunitKeyofnext;
    if (e !== undefined) {
      vProductunitKey = e;
      let x = parseInt(e)
      let y = 1;
      vProductunitKeyofnext = x + y;
    } else {
      vProductunitKey = 0;
    }
    if (vProductunitKey && resultData && resultData[vProductunitKey]?.unit_launch_type === "classroom") {
      return;
    }

    if (vProductunitKey !== undefined) {
      if (resultData[vProductunitKey]?.sequential_access_unit !== null) {
        resultData[vProductunitKey]?.activities.map((a) => {
          if (resultData[vProductunitKey]?.capsule_count > 0) {
              showCourseDetails(vProductunitKey, "Course", "LEARNINGOBJECTS", vProductunitKeyofnext);
          } else if (!isSurveyStarted) {
            showToastMessage();
          } else {
            showCourseDetails(vProductunitKey, "Course", "LEARNINGOBJECTS", vProductunitKeyofnext);
          }
          return null;
        });
      }
    } 
    if (vProductunitKey?.target?.getAttribute('id') === "mclosepopup") {
      loadProdctUnitList();
      setShowPopup(!showPopup);
      // Reset other state variables if needed
    }
  };

  const backHandler = () => {
    if (props.location.pathname === "/schome/product-lists") {
      props.history.push("/schome/products");
    } else {
      props.history.push("/home/products");
    }
  };

  const handleUnitTab = (status) => {
    setActiveTabUnits(status);
  }
  const showalert = (index) => {
    let xobj = { label: "LBLDATEUNLOCKFROMUNIT" };
    if (index === 0) {
      xobj.val = moment(resultData[index].assigned_date)
        .add(resultData[index].unit_drip_days, "days")
        .format(userInformaton.dateformat);
    } else {
      if (
        resultData[index - 1].CourseCompletionStatus ===
        "Completed" &&
        resultData[index - 1].CourseCompletionPercentage === 100
      ) {
        xobj.val = moment(resultData[index - 1].CourseCompletionDate)
          .add(resultData[index].unit_drip_days, "days")
          .format(userInformaton.dateformat);
      } else {
        xobj.label = localStorage.getItem('site_type') === "Accutrain" || props?.location?.state?.isModule ? "LBLSTATUSUNLOCKMODULE" : "LBLSTATUSUNLOCKUNIT";
        xobj.val = resultData[index - 1].units;
      }
    }
    if (index !== undefined) {
      setShowToast({
        show: true,
        alertClass: 'success',
        status: 'Success',
        message: getLabel(xobj),
        changeInToast: new Date(),
      });

      setTimeout(() => {
        setShowToast({
          show: false,
          alertClass: '',
          status: '',
          message: '',
          changeInToast: new Date(),
        });
      }, 5000);
    }
  };

  const getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (props.labels.labels.hasOwnProperty(xfield)) {
        return props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield];
        } else {
          return xfield;
        }
      }
    } else {
      if (props.labels.labels.hasOwnProperty(xfield.label)) {
        return props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };


  const getImageSource = (res) => {
    let baseUrl = '';
    let productAltImage = '';
    if (res.engineversion === "2") {
      baseUrl = process.env.REACT_APP_CRSENGINE2_IMAGEURL;
    } else {
      baseUrl = process.env.REACT_APP_RESOURCES_URL;
    }

    const imageName = res?.prod_unit_imagename;
    if(imageName === null || imageName === undefined || imageName === ""){
      return productAltImage = "/images/thumbnailImage.png";
    }else{
      const imageUrl = `${baseUrl}image/${res.courseid}/${imageName}`;
      return imageUrl;
    }
  };
  const getCapsuleCount = (res) => {
    let capsuleCount = 0;
    res.activities?.forEach((item) => {
      if (item.capsule_content_type?.toLowerCase() === "learningobject") {
        capsuleCount++;
      }
    });
    return capsuleCount;
  };
  console.log(resultData, showToast, "unitlistingpageresultData")

  const handleCourseClick = (res, i) => {
    if (res.DRIPBLOCKED === true || (i !== 0 && resultData[i - 1].CourseCompletionStatus !== "Completed" &&
      resultData[i].CourseHide === false && (res.sequential_access_unit === 1||(res.sequential_access_unit === 0 && res.unit_drip_enabled === 1)))) {
      showalert(i);
    } else if (
        (((i !== 0 && resultData[i - 1].CourseHide === false) || i === 0) &&
          res.sequential_access_unit === 1) ||
        (((i !== 0 && resultData[i - 1].CourseHide === false) || i === 0) &&
          res.sequential_access_unit === 0)
      ) {
        togglePopup(i);
      }
  };

  const loadUnitDescription = (index) => {
    if (TotalUnitsCount > 0) {
      const pathname = props.location.pathname === "/schome/product-lists" ? "/schome/unit-detail" : "/home/unit-detail";
      props.history.push({
        pathname: pathname,
        state: {
          capsuleUnits: 0,
          indexId: index,
          productID: resultData[index].productid,
          unitID: resultData[index].unitid,
          productName: resultData[index].products,
          bgThumbnail: props.location.state.bgThumbnail,
          resultDataString: resultDataString,
          resultData:resultData[index],
          gameAvailable: gameAvailable,
          isModule : props?.location?.state?.isModule
        },
      });
    }
  };
  
  const handleCourseDesc = (res, i) => {
    if (res.DRIPBLOCKED === true || (i !== 0 && resultData[i - 1].CourseCompletionStatus !== "Completed" &&
    resultData[i].CourseHide === false && (res.sequential_access_unit === 1||(res.sequential_access_unit === 0 && res.unit_drip_enabled === 1)))) {
    showalert(i);
  } else if (
      utils.isActivityEnabled(res.activity_enabled) &&
      res.activities.find(
        (item) =>
          item?.capsule_content_type?.toLowerCase() === "learningobject"
      )
    ) {
      goToCapsuleList(i);
    }
    else if(res.unitDescription !== "" && res.unitDescription !== undefined ) {
      loadUnitDescription(i);
    } else {
      togglePopup(i);
    }
  }
 
  const handleTransitionClose = () => {
    setShowFeedbackTransition(false);
  }

  const handleFeedback = () => {
    setShowFeedbackPopUp(true);
    setShowFeedbackTransition(false);
    // setActiveTabs(3);
    setShowEvalution(true);
  }

  const handleFeedBackClose = () => {
    setShowFeedbackPopUp(false);
    setShowFeedbackTransition(false);
    checkfeedback();
  }
  
  const showFeedback = (status) => {
    setShowFeedbackTransition(status);
  }

  const handleFeedbackCompletion = (status) => {
    console.log("unittab handleFeedbackCompletion",status)
    setFeedbackCompleted(status);
    showFeedbackToast(props?.labels?.labels?.LBLFEEDBACKCOMPLETED);
  }

  const handleSurvey = () => {
    setShowPostSurvey(true);
  };

  const handlePostSurveyClose = () => {
    setShowPostSurvey(false);
    setShowPostSurveyALert(false);
  };
  
  const handleSurveyCompletion = () => {
    showFeedbackToast(props?.labels?.labels?.LBLPOSTSURVEYCOMPLETED)
  }
  return (
    <div className="row product-listing productCtn">
      <ToastMessage show={showToast["show"]} helpers={showToast} />
      {showPopup && (
        <Coursepopup
          text='Click "Close Button" to hide popup'
          closePopup={togglePopup}
          showPopup={showPopup}
          Params1={productid}
          Params2={unitid}
          unitedetails={unitedetails && unitedetails}
          resultDataString={resultDataString && resultDataString}
          dateformat={props.dateformat}
          courseName= {props?.location?.state?.productname || props?.location?.state?.productName}
          gameAvailable = {gameAvailable}
          isModule = {props?.location?.state?.isModule}
        />
      )}
      <div className="col-xl-12">
        <div className="mob-pad">
          <div style={{ display: "flex" }}>
            <h3 className="page-title titleHover" onClick={backHandler} style={{ cursor: "pointer" }}>
              {props.labels.labels.LBLCOURSES}
            </h3>
            <ArrowForwardIosIcon style={{ fontSize: "14px", margin: "4px 5px 0px 5px" }} />
            <h3 className="page-title">
              {props?.location?.state?.productname || props?.location?.state?.productName}
            </h3>
          </div>
          <hr className="head-line" />
          {checkUnitsAvailable === "No records" ? (
            <div className="col-md-12 text-center text-danger">
              <EmptyState image={emptyImage} text2="No units found" />
            </div>
          ) :
            !loading  ?
              (
                <AboutProduct
                  productDetails={productDetails}
                  productid={props.productid}
                  labels={props.labels.labels}
                  unitsCount={resultData.length}
                  handleUnitTab={handleUnitTab}
                  allUnitsCompletionStatus={CourseCompletionCount === resultData.length}
                  CourseCompletionCount={CourseCompletionCount}
                  resultData={resultData}
                  activeTab = {props?.location?.state?.selectedTab}
                  showFeedback = {showFeedback}
                  feedBackCompleted = {feedBackCompleted}
                  showEvalution = {showEvalution}
                  productImage = {props?.location?.state?.productImage}
                  productName = {props?.location?.state?.productname}
                  FeedbackLabels = {labels}
                  gamificationDetails = {gamificationDetails}
                  isModule = {props?.location?.state?.isModule}
                />)
              :
              null
          }
          {activeTabUnits === true && checkUnitsAvailable !== "No records" ? (
            <div className="row img-card-pad">
              {resultData.length === 0 ? (
                <UnitListingSkeleton />
              ) : (
                <div style={{
                  width: "100%",
                  maxWidth: "inherit",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}>
                  {currentBelt || nextBelt ? 
                    <div className={currentBelt && currentBelt.badge_animation == 'black' ? styles.currentBeltWrapperSingle : styles.currentBeltWrapper }>
                      {currentBelt ?
                        <div className={styles.currentBeltContainer}>
                          <div className={styles.currentBeltImageCtn}>
                            <img className={styles.currentBeltOuterCircle} src={require(`../../../assets/images/karate/beltoutercircle/${currentBelt.badge_animation}.png`)} alt="Circular Image" />
                            <div className={styles.currentBeltAnimie}>
                            <img className={styles.karateImage} src={require(`../../../assets/images/karate/Badges/${currentBelt.badge_animation}.gif`)} alt="Badge Image" />

                                {/* <Lottie
                                  options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: beltAnimation[currentBelt.badge_animation],
                                    rendererSettings: {
                                      preserveAspectRatio: 'xMidYMid slice'
                                    }
                                  }}
                                  className={styles.karateImage}
                                  
                                  /> */}
                                {/* <Lottie
                                  loop
                                  animationData={beltAnimation[currentBelt.badge_animation]}
                                  play
                                  className={styles.karateImage}
                                /> */}
                            </div>
                          </div>
                          <div className={styles.currentBeltDescCtn}>
                            <div>
                              <span className={styles.currentBeltTitle}>{currentBelt.badge_name}</span>
                            </div>
                            <div >
                              <span className={styles.currentBeltDescText}>{currentBelt.badge_description}</span>
                            </div>
                              <div className={styles.progressWrapper} >
                                <div className={styles.progressContainer}>
                                  <div className={styles.progressBar} style={{ width: `${currentBelt.earned_points}%` }}>
                                  </div>
                                </div>
                                <div>
                                <span className={styles.progressStatus}>{currentBelt.completed_units_count} of {currentBelt.units.length}</span>

                                  </div>
                              </div>
                          </div>
                        </div>
                        : null}
                        
                      {currentBelt && currentBelt.badge_animation !== 'black' ? <div className={styles.verticalLine2}></div> : null}

                      {/* Next Achievement */}
                      {nextBelt ?
                        <div className={styles.nexAchievCnt}>
                          <span className={styles.NexAchiev}>Next Achievement</span>
                          <div className={styles.nextBeltContainer}>
                            <div className={styles.nexttBeltImageCtn}>
                              <img className={styles.nextBeltOuterCircle} src={require(`../../../assets/images/karate/beltoutercircle/${nextBelt.badge_animation}.png`)} alt="Circular Image" />
                              <div className={styles.currentBeltAnimie}>
                            <img className={styles.karateImage} src={require(`../../../assets/images/karate/Badges/${nextBelt.badge_animation}.gif`)} alt="Badge Image" />
                               
                                {/* <Lottie options={{
                                loop: true,
                                autoplay: true,
                                animationData: beltAnimation[nextBelt.badge_animation],
                                rendererSettings: {
                                  preserveAspectRatio: 'xMidYMid slice'
                                }
                              }} className={styles.karateImage} /> */}
                                {/* <Lottie
                                  loop
                                  animationData={beltAnimation[nextBelt.badge_animation]}
                                  play
                                  className={styles.karateImage}
                                /> */}
                              </div>
                            </div>
                            <div className={styles.nexttBeltDescCtn}>
                              <div>
                                <span className={styles.nextBeltTitle}>{nextBelt.badge_name}</span>
                              </div>
                              <div >
                                <span className={styles.nextBeltDescText}>{nextBelt.badge_description}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        : null}
                    </div> : null}
                  <div className= {resultData?.lenght < 3 ? "grid-fixed-container row" : "grid-container row"}>
                    {resultData.map((res, i) => {
                      console.log(CourseCompletionCount, "CourseCompletionCount")
                      if (res !== undefined) {
                        return (
                          <>
                            <UnitList
                              handleCourseClick={() => handleCourseClick(res, i)}
                              handleCourseDesc={() => handleCourseDesc(res, i)}
                              unitTitle={res.units}
                              unitdesc={res.description}
                              currentPage="unitlisting"
                              CourseCompletionStatus={res.CourseCompletionStatus}
                              CourseCompletionPercentage={res.CourseCompletionPercentage}
                              courseid={res.courseid}
                              unitImage={getImageSource(res)}
                              res={res}
                              currentindex={i}
                              previousData={resultData[i - 1]}
                              resultData={resultData}
                              unitAverageTime={res.unitEstimationTime !== null ? res.unitEstimationTime : 0}
                              capsuleListLength={getCapsuleCount(res)}
                              labels={props.labels.labels}
                              showPlayIcon = {true}
                              showMoreDetails ={res?.unitDescription !== "" && res.unitDescription !== undefined || res?.activities.find(
                                (item) =>
                                  item?.capsule_content_type?.toLowerCase() === "learningobject"
                              )}
                              isModule = {props?.location?.state?.isModule}
                            />
                          </>
                        );
                      }
                      return null;
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : null
          }
        </div>
      </div>
      {showFeedbackTransition && feedbackData?.feedback_available === "1" && Object.keys(feedbackData?.feedback_details).length > 0 && feedbackData?.feedback_id === "" && Object.keys(labels).length > 0  ?
        <TransitionPopUp
          labels = {labels}
          handleStartnow = {handleFeedback}
          handleTransitionClose = {handleTransitionClose}
        />
        :
       null
      }
      {showFeedbackPopUp ?
        <Survey
          hide={handleFeedBackClose}
          surveyId={feedbackData?.feedback_details?.capsule_content_id}
          feedback_file_name = {feedbackData?.feedback_file_name}
          showToastStatus={handleFeedBackClose}
          labels={labels}
          surveyType="feedback"
          launch_data = {feedbackData?.feedback_details?.encref}
          closeButton = {true}
          handleFeedbackCompletion = {handleFeedbackCompletion}
          languagecode = {resultData[0]?.languagecode}
          productID = {resultData[0]?.productid}
        />
        :
        null
      }

      {showPostSurveyAlert ?
        <SurveyAlert
          handleSurvey = {handleSurvey}
          skip = {false}
          label={props.labels.labels}
          title = {props.labels.labels.LBLPOSTSURVEY}
          description= {props.labels.labels.LBLMOMPOSTSURVEY}
        />
        :
        null
      }

      {showPostSurvey ?
        <Survey
          hide={handlePostSurveyClose}
          surveyId={postSurveyData?.survey_id}
          handleSurveyCompletion = {handleSurveyCompletion}
          labels={props.labels.labels}
          surveyType="post"
          closeButton = {false}
        />
        :
        null
      }
    </div>
  );

};

export default UnitListingPage;

import React from 'react';
import DefaultWidget from './DefaultWidget';
import * as operators from '../operators';

class NumberWidget extends DefaultWidget {
  getOperators() {
    return {
      [operators.NOCONDITION]: this.props.labels.LBLSELECTCONDITION,
      [operators.EQUALS]: this.props.labels.LBLNW_EQUALS,
      [operators.NOT_EQUALS]: this.props.labels.LBLNW_NOT_EQUALS,
      [operators.GREATER_THAN]: this.props.labels.LBLNW_GREATER_THAN,
      [operators.GREATER_THAN_EQUALS]: this.props.labels.LBLNW_GREATER_THAN_EQUALS,
      [operators.LESS_THAN]: this.props.labels.LBLNW_LESS_THAN,
      [operators.LESS_THAN_EQUALS]: this.props.labels.LBLNW_LESS_THAN_EQUALS,
      [operators.BETWEEN]: this.props.labels.LBLBETWEEN,
      [operators.NOT_BETWEEN]: this.props.labels.LBLNOT_BETWEEN
    };
  }

  renderInputElement(value, onChange) {
    const onChangeWithNumber = (e) => {
      
      const xvalue = parseInt(e.target.value);
      onChange(isNaN(xvalue) ? NumberWidget.defaultValue : xvalue);
    };

    return <input  className="form-control" style={{maxWidth:"160px",fontSize:"16px",display:"inline-block"}} type="number" value={value} onChange={onChangeWithNumber} />;
  }
}

NumberWidget.defaultOperator = operators.NOCONDITION;
NumberWidget.defaultValue = "";

export default NumberWidget;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ScAuth from './auth/auth';

export const ProtectedRoutes = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            if (ScAuth.isLoggedIn()) {
                return <Component {...props} />

            } else {
                return <Redirect to={
                    {
                        pathname: "/",
                        state: { from: props.location }
                    }
                } />
            }
        }}></Route>
    )
}
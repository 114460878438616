import React from "react";
import { Link } from "react-router-dom";
import "./watchchannels.css";
//import channelsLanguageData from "../translations/channels.json";
import Feeds from "../../components/feeds/feeds";
import FeedsServices from "../../feedService/feedservices";
import { connect } from "react-redux";
import ProfileImageDisplay from "../../components/profileimage/profileimage";
import emptyImage from '../../../assets/images/storyfeeds_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
//import { Spinner } from "react-bootstrap";

class WatchChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedsTypes: "channel",
      channelsData: [],
      selectedChannelItem: null,
      prevSelectedChannelIndex: NaN,
      selectedChannelIndex: 0,
      channelsLanguageData: this.props.labels.labels,
      language_code: this.props.language_code,
      showChannelSeeAll: 0,
      showAboutChannel: 0,
      userInfo: {},
      hideCreatePost: false,
    };
    this.selChIdFromManageChannel = "";
    this.channelContentRef = React.createRef();
  }
  componentDidMount() {
    this.selChIdFromManageChannel = localStorage.getItem(
      "ssiselectedchannelid"
    );
    if (this.selChIdFromManageChannel) {
      localStorage.removeItem("ssiselectedchannelid");
    }
    this.setState({
      hideCreatePost: false,
    });
    this.getChennalList();
    this.getUserInfo();
  }
  componentDidUpdate = (prevProps) => {
    if (
      this.state.showChannelSeeAll === 0 &&
      this.state.showAboutChannel === 0 &&
      this.channelContentRef &&
      this.channelContentRef.current &&
      this.channelContentRef.current.clientHeight &&
      this.channelContentRef.current.clientHeight > 180
    ) {
      this.setState({
        showChannelSeeAll: 1,
        showAboutChannel: 1,
      });
      console.log(
        "this.channelContentRef.current.clientHeight : ",
        this.channelContentRef.current.clientHeight
      );
    }
    if(this.props.labels.labels !== prevProps.labels.labels) {
        this.setState({
          channelsLanguageData: this.props.labels.labels
        })
      }
  };
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.state.channelsLanguageData &&
        this.state.channelsLanguageData[paramLBL]
        ? this.state.channelsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  getUserInfo = () => {
    FeedsServices.feedsAPIServicesCall(
      "users",
      "get",
      this.userInfoResultHandler
    );
  };
  userInfoResultHandler = (resultData) => {
    this.setState({
      userInfo: resultData.data,
    });
  };
  getChennalList = () => {
    FeedsServices.feedsAPIServicesCall(
      "channels?page=moderate",
      "get",
      this.getChannelListResultHandler
    );
  };
  getChannelListResultHandler = (resultData) => {
    if (resultData.data.length === 0) {
      this.setState({
        channelsData: [],
        selectedChannelItem: null,
      });
    } else {
      let followChannel = [];
      followChannel = resultData.data;
      this.state.selectedChannelIndex = this.selChIdFromManageChannel
        ? this.getIndexForSelChannelId(
          this.selChIdFromManageChannel,
          followChannel
        )
        : this.state.selectedChannelIndex;
      this.setState({
        channelsData: [...followChannel],
        selectedChannelItem: followChannel[this.state.selectedChannelIndex],
      });
    }
  };
  getIndexForSelChannelId = (currId, channelArr) => {
    let index = 0;
    if (channelArr.length) {
      for (let i = 0; i < channelArr.length; i++) {


        if (parseInt(currId) === channelArr[i]._id) {
          index = i;
          break;
        }
      }
    }
    return index;
  };
  channelChangeHandler = (evt) => {
    this.setState({
      selectedChannelIndex: Number(evt.target.value),
    });
  };
  getFeedBtnHandler = (evt) => {
    if (
      this.state.prevSelectedChannelIndex !== this.state.selectedChannelIndex
    ) {
      this.setState({
        prevSelectedChannelIndex: this.state.selectedChannelIndex,
        selectedChannelItem: this.state.channelsData[
          this.state.selectedChannelIndex
        ],
      });
    }
  };
  selectChannelFeedsHandler = (evt) => {
    let currChannelId = evt.currentTarget.getAttribute("data-channelId");

    this.setCurrentChannelForFeed(currChannelId);
    this.setState({ showAboutChannel: 1 });
  };
  setCurrentChannelForFeed = (currChannelId) => {
 let channelIndex = this.getIndexForSelChannelId(
      currChannelId,
      this.state.channelsData
    );
    if (this.state.selectedChannelIndex !== channelIndex) {
      
      this.setState({
        selectedChannelIndex: channelIndex,
        selectedChannelItem: this.state.channelsData[channelIndex],
      });
    }
  };
  showAllChannelHandler = (evt) => {
    this.setState({
      showChannelSeeAll: 2,
    });
  };
  showAboutChannelHandler = (evt) => {
    this.setState({
      showAboutChannel: 2,
    });
  };

  render() {
    return (
      <div className="ssi-wc-watchChannelContainer">
        <div className="ssi-wc-watchChannelContainerWrapper">
          <div className="ssi-wc-headerContainer">
            <div className="ssi-wc-headerText">
              <h3 className="page-title">{this.getResourceFromLabels("LBLMODERATECHANNELS")}</h3>
            </div>
          </div>
          {this.state.selectedChannelItem && this.state.userInfo.currentuser ? (
            <div className="ssi-wc-contentContainer">
              <div className="ssi-wc-contentContainerWrapper">
                <div className="ssi-wc-contentLHSContainer">
                  <div className="ssi-wc-contentLHSContainerWrapper">
                    <div className="ssi-wc-subHeaderText">
                      <span className="pageSubTitle">
                        {this.getResourceFromLabels("LBLCHANNELS")}
                      </span>
                      <Link to="/schome/manage-channels">
                        <span>
                          {this.getResourceFromLabels("LBLMANAGECHANNELS")}
                        </span>
                      </Link>
                    </div>
                    <div className="ssi-wc-channelHeaderBlockContainer">
                      <div className="ssi-wc-channelHeaderBlockWrapper">
                        <div className="ssi-wc-channelHeaderBanner"></div>
                        <div className="ssi-wc-channelHeaderBody">
                          <div className="ssi-wc-channelHeaderChannelProfile">
                          <div className="ssi-wc-channelHeaderContent">
                          <div className="ssi-wc-channelHeaderChannelProfile">
                            <ProfileImageDisplay
                              imageName={
                                this.state.selectedChannelItem
                                  .channel_profile_image
                              } 
                              firstLetterDisplay={""}
                              placeHolderType={"channel"}
                            />
                            </div>
                            </div>
                          </div>
                        
                          <div className="ssi-wc-channelHeaderNameContainer">
                            <div className="ssi-wc-channelHeaderName">
                              <span>
                                {this.state.selectedChannelItem.channel_name}
                              </span>
                            </div>
                            <div className="ssi-wc-channelHeaderMembers">
                              <span>{`${this.state.selectedChannelItem.followers
                                } ${this.getResourceFromLabels(
                                  "LBLMEMBERS"
                                )}`}</span>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    </div>
                    <div className="ssi-wc-feedsBlockContainer">
                      {this.state.selectedChannelItem &&
                        this.state.hideCreatePost === false ? (
                          <Feeds
                            feedsTypes={this.state.feedsTypes}
                            labels={this.state.channelsLanguageData}
                            channelsData={this.state.channelsData}
                            selectedChannelItem={this.state.selectedChannelItem}
                            hidePost={this.state.hideCreatePost}
                            page={"moderate"}
                          />
                        ) : (
                          ""
                        )}
                    </div>
                  </div>
                </div>
                <div className="ssi-wc-contentRHSContainer">
                  <div className="ssi-wc-contentRHSContainerWrapper">
                    <div className="ssi-wc-adminBlockContainer">
                      <div className="ssi-wc-adminBlockContainerWrapper">
                        <div className="ssi-wc-adminBlockHeader">
                          <span className="pageSubTitle">
                            {this.getResourceFromLabels("LBLCHANNELADMIN")}
                          </span>
                        </div>
                        <div className="ssi-wc-adminBlockHeaderProfile">
                          <ProfileImageDisplay
                            imageName={""}
                            firstLetterDisplay={
                              this.state.userInfo.currentuser.profile_info
                                .profile_name
                            }
                          />
                        </div>
                        <div className="ssi-wc-adminBlockHeaderName">
                          <div className="ssi-wc-adminBlockHeaderNameWrapper">
                            <div className="profileName">
                              <span className="nameText">
                                {
                                  this.state.userInfo.currentuser.profile_info
                                    .profile_name
                                }
                              </span>
                            </div>
                            <div className="profileRole">
                              <span className="roleText">
                                {this.getResourceFromLabels("LBLCHANNELADMIN")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ssi-wc-channelBlockContainer">
                      <div className="ssi-wc-channelBlockWrapper">
                        <div className="ssi-wc-channelBlockHeader">
                          <span className="pageSubTitle">
                            {this.getResourceFromLabels("LBLYOURCHANNELS")}
                          </span>
                        </div>
                        <div
                          className={
                            this.state.showChannelSeeAll === 1
                              ? "ssi-wc-channelBlockContent setMaxHeight"
                              : "ssi-wc-channelBlockContent"
                          }
                          ref={this.channelContentRef}
                        >
                          {this.state.channelsData.length
                            ? this.state.channelsData.map(
                              (channelItem, index) => {
                                
                                return (
                                  <div
                                    className="ssi-wc-channelBolockStrip"
                                    key={channelItem._id}
                                    data-channelId={channelItem._id}
                                    onClick={this.selectChannelFeedsHandler}
                                  >
                                    <div className="ssi-wc-channelBolockStripProfile">
                                      <ProfileImageDisplay
                                        imageName={
                                          channelItem.channel_profile_image
                                        }
                                        firstLetterDisplay={""}
                                        placeHolderType={"channel"}
                                      />
                                    </div>
                                    <div className="ssi-wc-channelBolockStripName">
                                      <span>{channelItem.channel_name}</span>
                                    </div>
                                  </div>
                                );
                              }
                            )
                            : "channel Not Found"}
                        </div>
                        <div
                          className={
                            this.state.showChannelSeeAll === 1
                              ? "ssi-wc-seeAllContainer"
                              : "ssi-wc-seeAllContainer hide"
                          }
                        >
                          <div
                            className="ssi-wc-seeAllText"
                            onClick={this.showAllChannelHandler}
                          >
                            <span>
                              {this.getResourceFromLabels("LBLSEEALL")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ssi-wc-descrioptionBlockContainer">
                      <div className="ssi-wc-descrioptionBlockWrapper">
                        <div className="ssi-wc-descrioptionBlockHeader">
                          <span className="pageSubTitle">
                            {this.getResourceFromLabels("LBLABOUTCHANNEL")}
                          </span>
                        </div>
                        <div
                          className={
                            this.state.showAboutChannel === 1
                              ? "ssi-wc-aboutBlockContent setMaxHeight"
                              : "ssi-wc-aboutBlockContent"
                          }
                        >
                          <div className="ssi-wc-descriptionBlockContent">
                            <div className="ssi-wc-descriptionBlockContent">
                              <span className="descriptionText">
                                {this.state.selectedChannelItem.description}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.showAboutChannel === 1
                              ? "ssi-wc-seeAboutContainer"
                              : "ssi-wc-seeAboutContainer hide"
                          }
                        >
                          <div
                            className="ssi-wc-seeAboutText"
                            onClick={this.showAboutChannelHandler}
                          >
                            <span>
                              {this.getResourceFromLabels("LBLSEEMORE")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
              <div className="ssi-wc-spinnerAlignCenter"> 
                {<div style={{marginTop: "5%"}}><EmptyState image={emptyImage} text2={this.getResourceFromLabels("LBLCHANNELNOTAVAILABLE")}/></div>}

              </div>
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language_code: state.user_info_reducer.user_info.language_code,
  };
};
export default connect(mapStateToProps, null)(WatchChannels);

//export default WatchChannels;

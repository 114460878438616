import React from "react";
//import { Spinner } from "react-bootstrap";
import ToggleSwitch from "../../components/toggleswitch/toggleswitch";
import UploadImage from "../../components/uploadimage/uploadimage";
import Loader from "../../../admin/components/loader/Loader";

class ChannelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status:
        this.props.selectedChannelItem !== undefined&& this.props.selectedChannelItem !== null &&
        this.props.selectedChannelItem.active_status !== undefined && this.props.selectedChannelItem.active_status !== null
          ? this.props.selectedChannelItem.active_status
          : 1,
      showErrorMess: 0,
      isSaveClick: 0,
    };
    this.channelNameRef = React.createRef();
    this.channelDescriptionRef = React.createRef();
    this.uploadedImageName =
      this.props.selectedChannelItem &&
      this.props.selectedChannelItem.channel_profile_image
        ? this.props.selectedChannelItem.channel_profile_image
        : "";
    this.isUploadCompleted = 1;
  }
  componentDidMount() {
    if (this.props.selectedChannelItem && this.props.selectedChannelItem._id) {
      this.channelNameRef.current.value = this.props.selectedChannelItem.channel_name;
      this.channelDescriptionRef.current.value = this.props.selectedChannelItem.description;
      this.uploadedImageName = this.props.selectedChannelItem.channel_profile_image;
      this.setState({
        status: this.props.selectedChannelItem.active_status,
      });
    }
  }
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.props.channelsLanguageData &&
      this.props.channelsLanguageData[paramLBL]
        ? this.props.channelsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  switchToggleHanler = (toggleStatus) => {
    this.setState({
      status: toggleStatus,
    });
  };
  formCancelHandler = (evt) => {
    this.props.formCancelHandler();
  };
  formSaveHandler = (evt) => {
    let validateRes = this.validateInputFields();
    if (validateRes === 0) {
      this.setState({
        showErrorMess: 0,
        isSaveClick: 1,
      });
      let savedDetails = {};
      if (
        this.props.selectedChannelItem &&
        this.props.selectedChannelItem._id
      ) {
        savedDetails = this.props.selectedChannelItem;
      }
      savedDetails.channel_name = this.channelNameRef.current.value;
      savedDetails.description = this.channelDescriptionRef.current.value;
      savedDetails.channel_profile_image = this.uploadedImageName;
      savedDetails.active_status = this.state.status;
      this.props.formSaveHandler(savedDetails);
    } else {
      this.setState({
        showErrorMess: validateRes,
      });
    }
  };
  validateInputFields = () => {
    let flag = 0;
    if (
      !String(this.channelNameRef.current.value).trim() ||
      !String(this.channelDescriptionRef.current.value).trim()
    ) {
      flag = 1;
    }
    /*if(flag == 0 && (this.isUploadCompleted == 0 || !this.uploadedImageName)){
            flag = 2;
        }*/

    return flag;
  };
  uploadStatusHandler = (status, fileName) => {
    this.isUploadCompleted = status;
    this.uploadedImageName = fileName;
  };

  render() {
    return (
      <div className="ssi-chform-formContainer ">
        <div className="ssi-chform-formContainerOuterWrapper">
          <div className="ssi-chform-formContainerWrapper">
            <div className="ssi-chform-fomHeaderContainer">
              <div className="ssi-chform-fomHeaderText">
                <span className="page-title">{this.getResourceFromLabels("LBLCREATECHANNEL")}</span>
              </div>
              <div
                className="ssi-chform-fomHeaderClose"
                onClick={this.formCancelHandler}
              >
                <span class="fas fa-times fas fa-bars mui-close-icon"></span>
              </div>
            </div>
            <div className="ssi-chform-fomContentContainer">
              <div className="ssi-chform-fomContentWrapper">
                <div className="ssi-chform-fomContentHeader">
                  <span className="pageSubTitle">{this.getResourceFromLabels("LBLCHANNELDETAILS")}</span>
                  <div className="ssi-chform-requiredTxtContainer">
                    <span className="ssi-chform-star">*</span>
                    <span className="ssi-chform-starText">
                      {this.getResourceFromLabels("LBLINDICATESREQUIRED")}
                    </span>
                  </div>
                </div>
                <div className="ssi-chform-formStrip name">
                  <div className="ssi-chform-formStripLHS">
                    <span>{this.getResourceFromLabels("LBLCHANNELNAME")}</span>
                    <span className="ssi-chform-star">*</span>
                  </div>
                  <div className="ssi-chform-formStripRHS">
                    <input
                      className="input-field"
                      ref={this.channelNameRef}
                      style={{width:"100%"}}
                    ></input>
                  </div>
                </div>
                <div className="ssi-chform-formStrip description">
                  <div className="ssi-chform-formStripLHS">
                    <span>{this.getResourceFromLabels("LBLDESCRIPTION")}</span>
                    <span className="ssi-chform-star">*</span>
                  </div>
                  <div className="ssi-chform-formStripRHS">
                    <textarea
                      className="input-field"
                      ref={this.channelDescriptionRef}
                      style={{width:"100%"}}
                    ></textarea>
                  </div>
                </div>
                <div className="ssi-chform-formStrip image">
                  <div className="ssi-chform-formStripLHS">
                    <span>{this.getResourceFromLabels("LBLCOVERIMAGE")}</span>
                  </div>
                  <div className="ssi-chform-formStripRHS">
                    <UploadImage
                      uploadedImageName={this.uploadedImageName}
                      uploadStatusHandler={this.uploadStatusHandler}
                      userInfo={this.props.userInfo}
                      channelsLanguageData={this.props.channelsLanguageData}
                    />
                  </div>
                </div>
                {this.state.showErrorMess !== 0 ? (
                  <div className="ssi-chform-errorMsg">
                    <span>
                      {this.state.showErrorMess === 2
                        ? this.getResourceFromLabels("LBLUPLOADREQUIREDMSG")
                        : this.getResourceFromLabels("LBLRQUIREDFILEDMSG")}
                    </span>
                  </div>
                ) : (
                  <div className="ssi-chform-errorMsg"></div>
                )}
                <div className="ssi-chform-statusBlock">
                  <div className="ssi-chform-statusText">
                    <span className="label">{this.getResourceFromLabels("LBLACTIVE")}</span>
                  </div>
                  <div className="ssi-chform-statusCheckbox">
                    <ToggleSwitch
                     className="custom-control-label"
                      initStatus={this.state.status}
                      switchToggleHanler={this.switchToggleHanler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ssi-chform-fomFooterContainer">
              {this.state.isSaveClick === 1 ? (
                <div className="ssi-manch-spinnerAlignCenter">
                  <Loader></Loader>
                </div>
              ) : (
                ""
              )}
              <div className="ssi-chform-fomButtonContainer">
                <button
                  className="button-style  secondary-btn"
                  style={{marginRight:"8px"}}
                  onClick={this.formCancelHandler}
                >
                  {this.getResourceFromLabels("LBLCANCEL")}
                </button>
                <button
                  className={
                    this.state.isSaveClick === 1
                      ? "button-style primary-btn disable"
                      : "button-style primary-btn"
                  }
                  onClick={this.formSaveHandler}
                >
                  {this.getResourceFromLabels("LBLSAVE")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChannelForm;

const labels = {
    dashboard: {
        data: require('../admin/pages/dashboard/translations/dashboard')
    },
    users: {
        data: require('../admin/pages/user-list/translations/users')
    },
    grouplist: {
        data: require('../admin/pages/group-list/translations/grouplist')
    },
    prodlist: {
        data: require('../admin/pages/prod-list/translations/prodlist')
    },
    products: {
        data: require('../employee/pages/products/translations/products')
    },
    managechannel: {
        data: require('../admin/pages/ManageChannels/translations/managechannel')
    },
    watchchannel: {
        data: require('../admin/pages/ManageChannels/translations/watchchannel')
    },
    reports: {
        data: require('../admin/pages/reports/translations/reports')
    },
    templates: {
        data: require('../admin/pages/templates/translations/templates')
    },
    rateyourstate: {
        data: require('../admin/pages/rate_your_state/translations/rateyourstate')
    },
    "rate-state": {
        data: require('../admin/pages/rate_your_state/translations/rateyourstate')
    },
    riskpattern: {
        data: require('../admin/pages/riskpattern/translations/riskpattern')
    },
    nearmiss: {
        data: require('../admin/pages/nearmiss/translations/nearmiss')
    },
    nearmissreport: {
        data: require('../admin/pages/nearmiss/translations/nearmissreport')
    },
    classroom: {
        data: require('../admin/pages/classroom/translations/classroom')
    },
    steercommaillanding: {
        data: require('../admin/pages/steeringcommittee/translations/steercommaillanding')
    },
    steeringcommittee: {
        data: require('../admin/pages/steeringcommittee/translations/steeringcommittee')
    },
    successfactors: {
        data: require('../admin/pages/steeringcommittee/translations/successfactors')
    },
    successfactorplan: {
        data: require('../admin/pages/steeringcommittee/translations/successfactorplan')
    },
    steercomdashboard: {
        data: require('../admin/pages/steeringcommittee/translations/steercomdashboard')
    }, 
    steercomreports: {
        data: require('../admin/pages/steeringcommittee/translations/steercomreports') 
    },  
    steercomuserdashboard: {
        data: require('../admin/pages/steeringcommittee/translations/steercomuserdashboard')
    },
    steercomtasksetup: {
        data: require('../admin/pages/steeringcommittee/translations/steercomtasksetup')
    },
    steercomconfiguration: {
        data: require('../admin/pages/steeringcommittee/translations/steercomconfiguration')
    },
    login: {
        data: require('../login/translations/login')
    },
    forgotpassword: {
        data: require('../forgot-password/translations/ForgotPassword')
    },
    profile: {
        data: require('../admin/pages/profile/translations/profile')
    },
    support: {
        data: require('../admin/pages/support/translations/support')
    },
    globalsettings: {
        data: require('../admin/pages/settings/translations/globalsettings')
    },
    "set-password": {
        data: require('../set_password/translations/set-password')
    },
    resources: {
        data: require('../employee/pages/resources/translations/resources')
    }, safestartsurvey: {
        data: require('../admin/pages/survey/translations/safestartsurvey')
    },
    "safe-start-survey-report": {
        data: require('../admin/pages/reports/translations/reports'),
        data1: require('../admin/pages/survey/translations/safe-start-survey-report')
    },
    feeds: {
        data: require('../ssifeeds/translations/feeds.json')
    },
    "user-register": {
        data: require('../registration/translations/user-register.json')
    },
    servererror: {
        data: require('../shared/Server_Error/translations.json')
    },
    trainingreset: {
        data: require('../admin/pages/trainingreset/translations/trainingreset')
    },
    toolbox: {
        data: require('../admin/pages/toolbox/translations/toolbox')
    },
    remotelaunch:{
        data: require('../remote_launch/translations/remotelaunchnew')
    },
    emailfailure: {
        data: require('../admin/pages/emailfailure/translations/emailfailure')
    },
    deactivatedpage: {
        data: require('../shared/deactivated_site/translations.json')
    }
    // cognitologin: {
    //     data: require('../sso/translations/cognitologin')
    // },
};

export default labels;
import React from 'react';
import styles from "../Survey.module.scss"
const BuildQuestionText = (props) => {
    return(
        <div style={{display: "flex"}}>
            <h3 className={styles.question} style={{marginRight:"5px"}}>
            {props.currentIndex + 1 + "."}
            </h3>
            <h3 className={styles.question}>
             {props.labels[props.text]}
            </h3>
        </div>
    );
}

export default BuildQuestionText;
import React,{useState} from "react";
import "./ProdList.scss";
import Modalpopup from "../../../common_components/modalanddatatable/Modalpopup";
import Loader from "../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import emptyImage from '../../../assets/images/userlist_emptyst.png'
import grouplist_emptyst from '../../../assets/images/grouplist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
import ConfrimPopup from "./ConfirmPopup";
import TrainingManagementHeader from "../../../common_components/datatable/TrainingManagementHeader";
import { TextField } from "@material-ui/core";
import { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataGrid} from '@mui/x-data-grid';
import assignedCheckbox from "../../../assets/images/assignedCheckboxImg.png";
import newlyAssignmentCheckbox from "../../../assets/images/newlyAssignedCheckboxImg.png";
import assignedaccucheckbox from "../../../assets/images/assignedaccucheckbox.png";
import styles from "./ProdList.module.scss";

const EnrollGroup = (props) => {
  // Sitetype:localStorage.getItem('site_type');
  const [selectedGroup,setSelectedGroup] = useState([]);
  const [slectedGroupCount,setSlectedGroupCount] = useState(0);
  const [showConfrimPopup,setShowConfrimPopup] = useState(false);
  const [groupTypeInfo,setGroupTypeInfo] = useState([]);
  const [groupTypeData, setGroupTypeData] = useState([]);
  const[selected_group_type,setSelected_group_type] = useState(null);
  const [visibleRowsSelected,setVisibleRowsSelected] = useState(false);
  const [pageState, setPageState] = useState({ // Loading data in the datagrid component
    isLoading: false,
    data: [], // rows
    total: 0 // total count of data grid we need to show
  })  
const [paginationModel, setPaginationModel] = useState({ // pagination variables
    page: 0,
    pageSize: 10,
  });
const [rowSelectionModel, setRowSelectionModel] = useState([]); 
const [grouplist, setGroupList] = useState([]); // total list 
const [filteredGroup,setFilteredGroup]  = useState("");
const [resultData,setResultData] = useState([]);
const [sortModel,setSortModel] = useState([]);
const [searchGroup,setSearchGroup] = useState("");
const [copyFilteredGroup,setCopyFilteredGroup] = useState([]);
  const {
    LBLGROUPNAME,
    LBLSTATUS,
    LBLACTIVE,
    LBLINACTIVE,
    LBLUSERENROLLED,
    LBLNAME,
    LBLEMAILID,
    LBLCANCEL,
    LBLCONFIRM,
    LBLUSERSEMPTYTXT,
    LBLGROUPSEXPTYTXT1,
    LBLASSIGNGROUP,
    LBLUNASSIGNGROUP,
    LBLGROUPTYPES,
    LBLCOURSESASSIGNEDTOELIGIBLEGROUPS,
    LBLCOURSEASSIGNMENT
  } = props.labels

  useEffect(()=>{
   setGroupTypeInfo(props.groupTypeInfo);
  },[props.groupTypeInfo])

  useEffect(() => {
    const extractedData = groupTypeInfo.map(item => {
      const lblGPKey = Object.keys(item.group_info).find(key => key.startsWith('lbl_GP_'));
      const lbl_GP = lblGPKey ? item.group_info[lblGPKey] : null;
      return {
        group_name: lbl_GP,
        group_type_id: item.group_info.group_type_id
      };
    });
    
    setGroupTypeData(extractedData);
  }, [groupTypeInfo]);
  
  useEffect(() => {
    if (groupTypeData.length > 0) {
      setSelected_group_type(groupTypeData[0]);
    }
  }, [groupTypeData]);
  
  
  const fetchData = () => {
    setGroupList(props.productGroups);
    setFilteredGroup(props.productGroups)
    setResultData(props.productGroups);
    setPageState( ({  isLoading: false, data: filteredGroup.slice((paginationModel.page * paginationModel.pageSize), ((paginationModel.page + 1) * paginationModel.pageSize)), total: filteredGroup.length }))
  }

  useEffect(()=> {
    fetchData();  
  }, [props.productGroups])
  // When page change/ rows per page selection change / total list changes (add / delete)

  console.log(props.productGroups,"productGroups")
useEffect(() => {
    const fetchData = () => {
    setPageState( ({ isLoading: false, data: filteredGroup.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page+1) * paginationModel.pageSize)), total: filteredGroup.length }))
    }
    fetchData();
}, [paginationModel.page, paginationModel.pageSize, filteredGroup]);

  useEffect(() => {
    // let disableRows = 0;
    let totalavailableRowsPerpage = 0;
    let totalavailableRows = 0;
    if(pageState.data.length > 0){
        pageState.data.map((item) => {
          if(item.isAssigned !== 1  && props.statusFilter === 3) {
              totalavailableRowsPerpage ++
          }
          return null;
        }
        );
        if(grouplist.length > 0){
          grouplist.map((item) => {
            if(item.isAssigned === 0 ) {
              totalavailableRows++
            }
            return null;
          });
        }
        let selctedRowCount = 0 ;
        (pageState.data.length  && pageState.data.map((val, ind) => {
          if(rowSelectionModel.includes(val.id)){
              selctedRowCount ++;
          }
          console.log(selctedRowCount,"selctedRowCountselctedRowCount")
        let totalSelectedRows = selctedRowCount ;
        if(totalavailableRowsPerpage === 0) {
          setVisibleRowsSelected(false)
        } else if (totalavailableRowsPerpage === totalSelectedRows && totalavailableRows !== slectedGroupCount) {
          setVisibleRowsSelected(true)
        } else {
          setVisibleRowsSelected(false)
        }
        return null;
      }))
    }
  },[pageState.data,rowSelectionModel,grouplist,props.statusFilter,slectedGroupCount])
  
  const handleSortModelChange = (sortModel) => {
    console.log("sortModelstart",sortModel)
    //   setSortModel(sortModel);
      const field = sortModel[0]?.field ;
      let direction = sortModel[0]?.sort ;
      let sortedData = [...filteredGroup];
      if(direction === undefined) {
        sortedData = searchGroup !== "" ? copyFilteredGroup : [...resultData];
       }
       console.log("sortedData",sortedData)
       sortedData.sort((a, b) => {
        const valueA = a[field] === null ? "" : a[field]; // Convert null to empty string
        const valueB = b[field] === null ? "" : b[field]; // Convert null to empty string
        if (direction === 'asc') {
            if (typeof valueA === 'string') {
                return valueA.localeCompare(valueB);
            } else {
                return valueA - valueB;
            }
        } else {
            if (typeof valueB === 'string') {
                return valueB.localeCompare(valueA);
            } else {
                return valueB - valueA;
            }
        }
    });
    
    console.log("sortedData", sortedData.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page+1) * paginationModel.pageSize)));
    setPageState( ({ isLoading: false, data: sortedData.slice(((paginationModel.page) * paginationModel.pageSize), ((paginationModel.page+1) * paginationModel.pageSize)), total: sortedData.length }))
    setFilteredGroup(sortedData);
  };

  const filterGroup = (e) => {
    setPaginationModel({
      page:0,
      pageSize:10
    })
    let groupSearchedVal = e.target.value;
    setSearchGroup(groupSearchedVal);
    const filteredVal =  resultData.filter((item) => {
      if (
        item.name &&
        (item.name.includes(
          groupSearchedVal.toLocaleLowerCase()
        ) ||
          item.name
            .toLowerCase()
            .includes(groupSearchedVal.toLocaleLowerCase()))
      ) {
        return item;
      }
      return null;
    });
    setFilteredGroup(filteredVal);
    setCopyFilteredGroup(filteredVal);
    setPageState(({ isLoading: false, data: filteredVal, total: filteredVal.length }))
  } 

  let columns = [
    {
      headerName: LBLGROUPNAME,
      field: "name",
      width: "500",
      renderCell: (row) => (
        <div className="grid-ellipsis">
          {row.row.name}
        </div>
      ),
    },
    {
      headerName: LBLSTATUS,
      field: "active",
      width: "200",
      renderCell: (row) => (
        <div className={row.row.active === 1 ? styles.LBLACTIVE  : styles.LBLINACTIVE}>
          {row.row.active === 1 ? LBLACTIVE : LBLINACTIVE}
        </div>
      ),
    },
    {
      headerName: LBLUSERENROLLED,
      field: "groupuserscount",
      width: "200",
      sortable:true,
      renderCell: (row) => {
        const rowId = row.row.id;
        return(
        <div>
          <div
            className={styles.EnrolledCount}
            data-toggle="modal"
            onClick={
              row.row.groupuserscount > 0 ? () => {props.ShowEnrolledDetails(rowId)} : undefined
            }
          >
            <span>
              {row.row.isAssigned && row.row.groupuserscount > 0 ? row.row.groupuserscount : "-"}
            </span>
          </div>
        </div>
      )},
    },
  ];
  
  
  // let groupdata = filterGroup;

  /**** Enrolled user details *****/
  const GroupUserfilter =
    props.enrollredDetails !== undefined
      ? props.enrollredDetails.filter((item) => {
        if (
          (item.name &&
            (item.name.includes(props.userSearchValueModal) ||
              item.name
                .toLowerCase()
                .includes(props.userSearchValueModal))) ||
          (item.email &&
            (item.email.includes(props.userSearchValueModal) ||
              item.email
                .toLowerCase()
                .includes(props.userSearchValueModal)))
        ) {
          return item;
        }
        return null;
      })
      : "";
      
  let groupuserColumns = [
    {
      name: LBLNAME,
      selector: "Name",
      sortable: true,
      width: "50%",
      compact: true,
      cell: (rows) => (
        <div className="w100">
          <label className="show-sm">{LBLNAME}</label>
          <div>{rows.name}</div>
        </div>
      ),
    },
    {
      name: LBLEMAILID,
      selector: "Email",
      sortable: true,
      width: "50%",
      compact: true,
      cell: (rows) => (
        <div className="w100">
          <label className="show-sm">{LBLEMAILID}</label>
          <div>{rows.email}</div>
        </div>
      ),
    },
  ];
  let groupUserData = GroupUserfilter;

  /**** Enrolled user details *****/

  const handleConfrimPopup = () => {
    if(props.statusFilter === 3) {
      setShowConfrimPopup(true);
    }else{
      props.updateTrainingAssignmentGrp(selectedGroup);
    }
  }
  const handleCancelConfrimPopup = () => {
    setShowConfrimPopup(false);
  }
  const handleConfrim = () => {
    props.updateTrainingAssignmentGrp(selectedGroup);
  }
  const handlepaginationModalChange = (paginationVal) => {
    console.log(paginationVal,"paginationVal");
    setPaginationModel(paginationVal);
  }
  const handleSelectionModelChange = (newRowSelectionModel) => {
    const filteredArray = newRowSelectionModel.filter(item => item !== false);
    setRowSelectionModel(newRowSelectionModel);
    setSlectedGroupCount(filteredArray.length);
    const selectedRows = newRowSelectionModel.map((id) =>
    grouplist.find((row) => row.id === id)
    );
    setSelectedGroup(selectedRows)
  };
  const rowDisabledCriteria = (row) => {
    // console.log(props.statusFilter,row,"row.isAssigned")
    if(props.statusFilter !== 4){
        if(row.row.isAssigned === 0) {
            return true;
        } 
    } else{
        return true; 
    } 
    // row.isAssigned;
}
  const handleSelectAll = () => {  
    const selecteRowsData = filteredGroup
    setSelectedGroup(selecteRowsData)
    if(props.statusFilter === 3) {
        const groupIds = filteredGroup.map((item) => item.isAssigned !==1 && item.id);
        setRowSelectionModel(groupIds);
        setSlectedGroupCount(filteredGroup.filter((item) => item.isAssigned === 0).length)
    } else {
        const groupIds = filteredGroup.map((item) =>  item.id);
        setRowSelectionModel(groupIds);
        setSlectedGroupCount(filteredGroup.length)
    }
}
 const handleClear = () => {
  setRowSelectionModel([]);
  setSlectedGroupCount(0);
 }
 console.log(selected_group_type,"selected_group_type");
  const tableHeader = () => {
    return (
      <>
        <div className={styles.assignmentHeaderGrp}>
            {groupTypeInfo.length !== 0 ? (
            <>
              <div className={styles.groupTypeCtn}>
                <label className="label" style={{marginBottom:"0px",marginRight:"10px",marginTop:"6px"}}>{LBLGROUPTYPES}</label>
              <Autocomplete
                className="filter-drpdwn"
                style={{width:"150px"}}
                aria-required="true"
                required
                disableClearable
                options={groupTypeData}
                getOptionLabel={(option) => option.group_name}
                value={selected_group_type}
                onChange={(e,groups)=>{
                  setSelected_group_type(groups)
                  const selectedGroupId = groups.group_type_id;
                  props.onChangeHandler(selectedGroupId);
                }}
                renderOption={(option) => (
                  <div style={{ fontSize: "14px" }}>{option.group_name}</div>
                )}
                underlineStyle={{ display: "none" }}
                renderInput={(params) => (
                  <div>
                    <TextField
                      {...params}
                    />
                  </div>
                )}
              />
              </div>
            </>
            ) : (
              ""
            )}
            <input
              type="text"
              className="input-field"
              placeholder={selected_group_type && selected_group_type.group_name}
              value={props.value}
              onChange={filterGroup}
              onKeyDown={filterGroup}
            />
        </div>
        <div  className={styles.legendsAllCtn}>
          {props.statusFilter === 3 &&
          <div className={styles.legendCtn}>
          <img src= {assignedCheckbox} alt = ""/>
          {props.labels.LBLASSIGNED !== undefined ? props.labels.LBLASSIGNED : "Assigned"}
          </div>
          }
          <div className={styles.legendCtn}>
          <img src= {localStorage.getItem('site_type')==='SafeStart'?newlyAssignmentCheckbox:assignedaccucheckbox} alt = ""/>
          {props.labels.LBLSELECTED !== undefined ? props.labels.LBLSELECTED : "Selected"}
          </div>
        </div>
        <TrainingManagementHeader
          selectedRowsCount={props.selectedRowsCount}
          labels={props.labels}
          handleClear={handleClear}
          assignmentstate={props.assignmentstate}
          selectedRows={props.selectedRows}
          selectAll={props.labels.LBLSELECTALLGROUP.replace("$$$","")}
          usersGroupsSelected={props.labels.LBLGRPSELECTED !== undefined ? props.labels.LBLGRPSELECTED : "Groups selected"} 
          handleRowChange = {handleSelectAll}
          usersGroupsSelectedCount= {slectedGroupCount}
          courseAssignment = {true}
          visibleRowsSelected = {visibleRowsSelected}
          visiblerowsselectedLabel = {
            props.statusFilter === 3 ?
            props.labels.LBLELIGIBLEGRPSELECTED !== undefined ? props.labels.LBLELIGIBLEGRPSELECTED :`All eligible groups on this page are selected.`
            :
            props.labels.LBLALLGRPSELECTED !== undefined ? props.labels.LBLALLGRPSELECTED :`All groups on this page are selected.`
        }
        />
      </>
    );
  }

  return (
    <div className="prodlist">
      <div >
        <h3 className="page-title">
          {props.statusFilter === 3 ? LBLASSIGNGROUP ?  LBLCOURSEASSIGNMENT + " - " + LBLASSIGNGROUP : "Assign groups" : LBLUNASSIGNGROUP ?  LBLCOURSEASSIGNMENT + " - " + LBLUNASSIGNGROUP : "Unassign groups"}
        </h3>
      </div>

      <hr className="head-line" />
      <div >
        {
          props.loading ? (
            <div>
              <Skeleton height={34} />
              <Skeleton height={536} />
            </div>
          ) : (
            <>
            <div>{tableHeader()}</div>
            {pageState.data.length > 0 ?
              <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              onSortModelChange={(sortVal) => handleSortModelChange(sortVal)}
              // initialState={{
              //   sorting: {
              //     sortModel: [{ field: 'name', sort: 'asc' }],
              //   },
              // }}
                style={{border: 'none'}}
                className="cust-data-grid"
                autoHeight
                checkboxSelection
                disableRowSelectionOnClick          
                isRowSelectable={rowDisabledCriteria} // matched condition rows only enabled
                getRowId={(row) => row.id} // key value of the row item
                columns={columns}
                rows={pageState.data}
                rowCount={pageState.total}
                loading={pageState.isLoading}
                pageSizeOptions={[10, 15, 20,25,30]}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlepaginationModalChange}
                onRowSelectionModelChange={handleSelectionModelChange}
                rowSelectionModel={rowSelectionModel}        
                keepNonExistentRowsSelected
                hideFooterSelectedRowCount
                disableColumnMenu
                slotProps={{
                  pagination: {
                    labelRowsPerPage: props.labels.LBLROWSPERPAGE + ":"
                  }
                }}
                sx={{
                  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
        
                  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg path': {
                    display: "none"
                  },
                  '& .MuiCheckbox-root.Mui-disabled:not(.MuiCheckbox-indeterminate) svg': {
                    backgroundColor:"#cfcbcb",
                    width:"18px",
                    height:"18px",
                    borderRadius:"3px"
                    },
                    // '& .MuiCheckbox-root .MuiSvgIcon-root path': {
                    //   width:"25px",
                    //   height:"25px"   
                    // },
                    // "& .MuiButtonBase-root": { fontSize: 12 }
               }}
              /> 
              :
              <div style={{ marginTop: "5%" }}><EmptyState image={grouplist_emptyst} text2={LBLGROUPSEXPTYTXT1 !== undefined ? LBLGROUPSEXPTYTXT1 : "There aren't any groups to display"} /></div>
            }
        </>
          )
        }
      </div>
      <div className="row">
        <div
          id="selectproductError"
          className="col-md-12 text-danger text-center d-none"
        >
          {props.LBLSELANYGROUP}
        </div>
        <div className="col-md-12 text-right">
          <button
            type="button"
            class="btnspacin"
            className="button-style secondary-btn"
            onClick={props.handleCancel}
            style={{marginRight:"10px"}}
          >
            {LBLCANCEL}
          </button>
          <button
            type="button"
            class="btnspacin"
            className="button-style primary-btn"
            onClick={handleConfrimPopup}
            disabled = {slectedGroupCount === 0}
          >
            {LBLCONFIRM}
          </button>
        </div>
        <ConfrimPopup
          show={showConfrimPopup}
          hide = {handleCancelConfrimPopup}
          title={LBLASSIGNGROUP !== undefined  ? LBLASSIGNGROUP : "Assign groups"}
          selectedRowsCount={props.selectedRowsCount}
          usersGroupsSelectedCount={slectedGroupCount}
          buttonName={LBLCONFIRM !== undefined ? LBLCONFIRM : "Confirm"}
          handleConfrim={handleConfrim}
          handleCancel={handleCancelConfrimPopup}
          description = {LBLCOURSESASSIGNEDTOELIGIBLEGROUPS}
          btnCancel = {LBLCANCEL}
        />
      </div>
      {props.showModalDetails ? (
        props.enrollredDetails.length === 0 ? (
          <div className="text-center">
            {/* <Spinner animation="grow" /> */}
            <Loader></Loader>
          </div>
        ) : (
          <Modalpopup
            Showmodal={props.showModalDetails}
            closemodal={props.CloseEnrolledDetails}
            ModalTiltle={props.enrollredDetails[0].group_name}
            DataTableHeader={props.groupuserTableHeader}
            Norecords={<EmptyState image={emptyImage} text2={LBLUSERSEMPTYTXT !== undefined ? LBLUSERSEMPTYTXT : "There aren't any users to display"} />}
            paginationComponentOptions={props.paginationComponentOptions}
            DatatableColumns={groupuserColumns}
            DatatableData={groupUserData}
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          ></Modalpopup>
        )
      ) : (
        undefined
      )}

    </div>
  );
}

export default EnrollGroup
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./login/Login";
import ForgotPassword from "./forgot-password/ForgotPassword";
import Auth0Callback from "./sso/Auth0Callback.js";
import { createBrowserHistory } from "history";
import Layout from "./admin/layout/Layout";
import Layoutm from "./employee/layout/Layout";
import { ProtectedRoutes } from "./ProtectedRoutes";
import AuthenticationPassword from "./forgot-password/AuthenticationPassword";
import Not_Found from "./shared/Not_Found";
import SetPassword from "./set_password/SetPassword";
import RateState from "./admin/pages/rate_your_state/RateState";
import TranslatedRoutes from "./translator/TranslatedRoutes";
import ScCommonServices from "./services/common_services";
//import Loading from "./shared/Loading";
//import { Feed } from 'react-activity-feed';
import Feed from "./ssifeeds/feed";
import Loader from "./admin/components/loader/Loader";
import ServerError from "./shared/Server_Error/Server_Error";
import DeactivatedPage from "./shared/deactivated_site/DeactivatedPage.js";
import SafeStartSurvey from "./admin/pages/survey/SafeStartSurvey";
import NewUserRegistration from "./registration/Registration";
import RemoteLaunch from "./new_remote_launch/RemoteLaunch";
import SurveyRL from "./survey/SurveyRL";
import CognitoSuccess from "./sso/cognito_success";
//classlink
import CbClasslink from "./sso/CbClasslink.js";
import companyinfo_reducer from "./redux/companyinfo/reducer.js";
import { setCompanyInfo } from "./redux/companyinfo/actions.js";
import { connect } from "react-redux";
import moment from "moment";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lang_code: "",
      origin_not_found: false,
      server_error: false,
      site_deactivated: false
    };
  }
  componentDidMount() {
    this.getCompanyInitSet();
  }
  getCompanyInitSet = () => {
    ScCommonServices.getCompanyInfo()
      .then((res) => {
        let logoname = res.company_logo;
        let google_sso;
    
        let auth0_sso =(res.auth0_sso && res.auth0_sso ==1)?true:false;
        let cognito_sso =(res.cognito_sso &&res.cognito_sso ==1)?true:false;
        let classlink_sso =(res.classlink_sso &&res.classlink_sso ==1)?true:false;

        if (res.google_sso !== 0) {
          google_sso = true;
        } else {
          google_sso = false;
        }
        
 
        if (res.company_logo === "") {
          logoname = "./images/login-logo.png";
        }
        //res.language = "fr-FR"
        var a = moment(new Date());
        var b = moment(res.site_expire_date);
        let remainingDays = a.diff(b, 'days');
        let siteDeactive =false;
        if(remainingDays >= res.bonus_days) { 
          siteDeactive = true;
        } 
        this.setState({
          loading: false,
          lang_code: res.language,
          company_logo: logoname,
          show_sso: { google: google_sso,cognito_sso,classlink_sso,auth0_sso },
          login_with_phone_number: res.login_with_phone_number,
          country_code: res.country_code,
          enable_register: res.email_with_code,
          site_type: res.site_type,
          site_expire_date: res.site_expire_date,
          site_deactivated: siteDeactive
        });
        let comp_info = {
          lang_code: res.language,
          company_logo: logoname,
          show_sso: { google: google_sso,cognito_sso,classlink_sso,auth0_sso },
          login_with_phone_number: res.login_with_phone_number,
          country_code: res.country_code,
          enable_register: res.email_with_code,
          site_type: res.site_type,
          site_expire_date: res.site_expire_date,
          site_expire_alert_days:res.site_expire_alert_days,
          bonus_days: res.bonus_days,
          site_last_renewal_date: res.site_last_renewal_date,
          site_start_date: res.site_start_date          
        }
        if(res.site_type === 'Accutrain') {
          document.getElementById('main').className = document.getElementById('main').className.replace('light', 'green');
        }
        localStorage.setItem("site_type",res.site_type);
        // this.props.companyinfo_reducer(comp_info);        
        localStorage.setItem("site_type",res.site_type);
        this.props.companyinfo_reducer(comp_info);        
      })
      .catch((err) => {
        if (err.message === "invalid_origin") {
          this.setState({
            origin_not_found: true,
          });
        } else if (err.message === "SSIAPI Network Error") {
          this.setState({
            server_error: true,
          });
        } else {
          console.log(err)
          this.setState({
            origin_not_found: true,
          });
        }
      });
  };
  render() {
    const history = createBrowserHistory();
    const { loading, lang_code, origin_not_found, server_error, site_deactivated } = this.state;
    
    if (!origin_not_found && !server_error && !site_deactivated) {
      if (loading) {
        return <Loader></Loader>;
      } else {
        return (
          <Router history={history}>
            <Switch>
              <TranslatedRoutes
                path="/authentication"
                comp_name={"forgotpassword"}
                language={lang_code}
                component={AuthenticationPassword}
              />
              <TranslatedRoutes
                path="/rate-your-state"
                comp_name={"rateyourstate"}
                language={lang_code}
                component={RateState}
              />
              <TranslatedRoutes
                path="/safe-start-survey"
                comp_name={"safestartsurvey"}
                language={lang_code}
                component={SafeStartSurvey}
              />
              <TranslatedRoutes
                path="/feeds"
                comp_name={"feed"}
                language={lang_code}
                component={Feed}
              />

              <TranslatedRoutes
                path="/"
                exact
                comp_name={"login"}
                language={lang_code}
                component={Login}
                {...this.state}
              />
              <TranslatedRoutes
                path="/user-register"
                exact
                comp_name={"user-register"}
                language={lang_code}
                component={NewUserRegistration}
                {...this.state}
              />
              <TranslatedRoutes
                path="/forgot-password"
                exact
                comp_name={"forgotpassword"}
                language={lang_code}
                component={ForgotPassword}
                {...this.state}
              />
              <TranslatedRoutes
                path="/callback"
                exact
                comp_name={"login"}
                language={lang_code}
                component={Auth0Callback}
                {...this.state}
              />
              <TranslatedRoutes
                path="/cbclasslink"
                exact
                comp_name={"login"}
                language={lang_code}
                component={CbClasslink}
                {...this.state}
              />    

              <TranslatedRoutes
                path="/safestart-survey-un"
                exact
                comp_name={"remotelaunch"}
                language={lang_code}
                component={RemoteLaunch}
                {...this.state}
              />
              <TranslatedRoutes
                path="/safestart-survey-lp"
                exact
                comp_name={"remotelaunch"}
                language={lang_code}
                component={RemoteLaunch}
                {...this.state}
              />
              {/* <TranslatedRoutes
                path="/safestart-survey-rl"
                exact
                comp_name={"safestartsurvey"}
                language={lang_code}
                component={SafeStartSurveyRL}
                {...this.state}
              />               */}
              <TranslatedRoutes
                path="/safestart-survey-rl"
                exact
                comp_name={"safestartsurvey"}
                language={lang_code}
                component={SurveyRL}
                {...this.state}
              />
              <TranslatedRoutes
                exact
                path="/e"
                comp_name={"set-password"}
                language={lang_code}
                component={SetPassword}
                {...this.state}
              />
              <TranslatedRoutes
                path="/cognitosuccess"
                exact
                comp_name={"login"}
                language={lang_code}
                component={CognitoSuccess}
                {...this.state}
              />
              <ProtectedRoutes path="/schome" component={Layout} {...this.state} />
              <ProtectedRoutes path="/home" component={Layoutm} {...this.state} />
              <Route path="*">
                {" "}
                <Not_Found />{" "}
              </Route>
            </Switch>
          </Router>
        );
      }
    } else if (origin_not_found) {
      return <Not_Found no_origin={true}></Not_Found>;
    } else if (server_error) {
      return <TranslatedRoutes
        comp_name={"servererror"}
        language={lang_code}
        component={ServerError}    {...this.state}
      />
    } else if (server_error) {
      return <TranslatedRoutes
        comp_name={"servererror"}
        language={lang_code}
        component={ServerError}    {...this.state}
      />
    }
    else if (site_deactivated) {
      return <TranslatedRoutes
        comp_name={"deactivatedpage"}
        language={lang_code}
        component={DeactivatedPage}    {...this.state}
      />
    }
    // else if (site_deactivated) {
    //   return <TranslatedRoutes
    //     comp_name={"deactivatedpage"}
    //     language={lang_code}
    //     component={DeactivatedPage}    {...this.state}
    //   />
    // }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    companyinfo_reducer: (companyReducerData) => dispatch(setCompanyInfo(companyReducerData)),
  };
};
export default connect(null, mapDispatchToProps)(Routes);

import React from "react";
import "./resources.scss";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import ResourcePopup from "../resourcepopup/Resourcepopup";
import Skeleton from "react-loading-skeleton";

export default class Resources extends React.Component {
  constructor(props) {
    super(props);
    this.state = { resultData: [], parentFolderres: [] };
    this.loadResources = this.loadResources.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.goBack = this.goBack.bind(this); // i think you are missing this
    this.togglePopup = this.togglePopup.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
  }

  handleClick(e) {
    var vParentFolderid = e.currentTarget.dataset.div_parentid;
    var vFileid = e.currentTarget.dataset.div_id;
    HttpInterceptor(
      "resources?fileId=" + vFileid + "&parentId=" + vParentFolderid
    )
      .then((res) => {
        return res;
      })
      .then((data) => {
        this.setState({
          resultData: data.data.files,
          parentFolderres: data.data.parent,
        });
      });
  }

  downloadFiles(e) {
    let headers = new Headers({
      responseType: "blob",
    });
    var vRowKey = e.currentTarget.dataset.div_key;

    var vFileId = this.state.resultData[vRowKey].resource_id;
    var vFileformat = this.state.resultData[vRowKey].format;
    var vFilename = this.state.resultData[vRowKey].file_name;

    HttpInterceptor("sthrees?type=" + 1 + "&fileId=" + vFileId, {
      headers,
      responseType: "arraybuffer",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/" + vFileformat })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", vFilename + "." + vFileformat); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  goBack(e) {
    var vFileid = e.currentTarget.dataset.div_id;
    HttpInterceptor("resourcesparent?fileId=" + vFileid)
      .then((res) => {
        return res;
      })
      .then((data) => {
        this.setState({
          resultData: data.data.files,
          parentFolderres: data.data.parent,
        });
      });
  }

  bclosePopup = () => {
    this.setState({
      showPopup: !this.state.showPopup,
    });
  };

  togglePopup(e) {
    var vFilename = e.currentTarget.dataset.div_filename;
    var vFileid = e.currentTarget.dataset.div_id;
    var vFileformat = e.currentTarget.dataset.div_type;
    this.setState({
      showPopup: !this.state.showPopup,
      filename: vFilename,
      fileid: vFileid,
      fileformat: vFileformat,
    });
  }

  componentDidMount() {
    this.loadResources();
  }

  loadResources() {
    HttpInterceptor("resources?fileId=0&parentId=0")
      .then((res) => {
        return res;
      })
      .then((data) => {
        if (data.message === undefined) {
          this.setState({
            resultData: data.data,
            parentFolderres: "",
          });
        }
      });
  }

  render() {
    const { LBLRESOURCES } = this.props.labels.labels;

    var vFolderDet = this.state.resultData.map((res) => {
      if (res.name !== undefined) {
        return (
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 resourses">
            <div
              className="card"
              data-div_parentid={res.folder_id}
              data-div_id={res.folder_id}
              data-div_filename={res.file_name}
              onClick={this.handleClick}
            >
              <div className="card-body fa fa-folder" data-title={res.name}>
                <span>{res.name}</span>
              </div>
            </div>
          </div>
        );
      }
    });
    var vFileDet = this.state.resultData.map((res, i) => {
      if (res.name === undefined) {
        return (
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 resourses">
            <div className="card" data-title={res.file_name}>
              <div
                className="card-files cursor-pointer"
                data-div_type={res.format}
                data-div_id={res.resource_id}
                data-div_filename={res.file_name}
                onClick={this.togglePopup}
              >
                {
                  <img
                    src={
                      "../images/resource_image/" +
                      res.file_name
                        .substring(0, res.file_name.length - res.format.length)
                        .replace("#", "%23") +
                      "png"
                    }
                    alt={res.format}
                    data-div_filename={res.file_name}
                  />
                }
                <div class="overlay"></div>
              </div>

              <div className="card-body">
                <img
                  src={`${"../images/format_image/"}${
                    res.format === "pdf"
                      ? "Pdf_icon.png"
                      : res.format === "docx"
                      ? "Doc_icon.png"
                      : res.format === "xls"
                      ? "Excel_icon.png"
                      : res.format === "xlsx"
                      ? "Excel_icon.png"
                      : res.format === "ppt"
                      ? "PPT_icon.png"
                      : res.format === "mp4"
                      ? "Video_icon.png"
                      : res.format === "wmv"
                      ? "Video_icon.png"
                      : "Image_icon.png"
                  }`}
                  alt={res.format}
                  style={{ width: "16px", height: "16px" }}
                />
                <a href="javascript:void(0)" alt={res.format}>
                  {res.file_name}
                </a>
                <ul className="resource-link">
                  <li
                    data-div_filename={res.file_name}
                    data-div_type={res.format}
                    data-div_id={res.resource_id}
                    onClick={this.togglePopup}
                  >
                    <i class="fas fa-eye"></i>
                  </li>
                  <li>
                    <i
                      class="fas fa-download"
                      onClick={this.downloadFiles}
                      data-div_key={i}
                    ></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      }
    });

    if (this.state.parentFolderres.length > 0) {
      var parentFolderDet = this.state.parentFolderres.map((res) => {
        return (
          <div className="resourses">
            <div>
              <h5 className="">
                <a
                  href="javascript:void(0)"
                  data-div_parentid={res.folder_id}
                  data-div_id={res.folder_id}
                  onClick={this.goBack}
                >
                  <i class="fas fa-chevron-left"></i>
                  {res.name}
                </a>
              </h5>
            </div>
            <hr />
          </div>
        );
      });
    }

    return (
      <div className="row resourses">
        <div className="col-xl-12">
          {this.state.resultData.length === 0 ? (
            <div className="mob-pad c-course resource-bg">
              <h3>{LBLRESOURCES}</h3>
              <hr />
              {parentFolderDet}
              <div className="row resources-section">
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                  <div className="card">
                    <div className="card-body fa fa-folder">
                      <Skeleton height={17} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mob-pad c-course resource-bg">
              <h3>{LBLRESOURCES}</h3>
              <hr />
              {parentFolderDet}
              <div className="row resources-section">
                {vFolderDet}
                <div className="col-xl-12">&nbsp;</div>
                {vFileDet}
              </div>
            </div>
          )}
        </div>
        {this.state.showPopup}
        {this.state.showPopup ? (
          <ResourcePopup
            filename={this.state.filename}
            fileid={this.state.fileid}
            fileformat={this.state.fileformat}
            text='Click "Close Button" to hide popup'
            closePopup={this.togglePopup.bind(this)}
            bclosePopup={this.bclosePopup}
            showPopup={this.state.showPopup}
          ></ResourcePopup>
        ) : null}
      </div>
    );
  }
}

import React from "react";
import "./managechannel.scss";
//import channelsLanguageData from "../translations/channels.json";
import { Modal } from "react-bootstrap";
import UserList from "../channelcomponents/userlist";
import GroupList from "../channelcomponents/grouplist";
import FeedsServices from "../../../ssifeeds/feedService/feedservices";
import { connect } from "react-redux";
import ChannelRowItem from "../channelcomponents/channelRowItem";
import ChannelForm from "../channelcomponents/channelform";
import GroupServices from "../../../admin/pages/group-list/group_services";
//import Loader from "../../../admin/components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import EmptyState from "../../../common_components/empty_state/Empty_state";
import emptyImage from '../../../assets/images/storyfeeds_emptyst.png'

let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class ManageChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channelsData: [],
      channelsNewData: [],
      loading: true,
      prodroles: { Participant: 4, Trainer: 3, Consultant: 2 },
      channelUsers: [],
      channelGroups: [],
      currentChannelPage: "manage", //manage, users, groups
      groupTypeNames: [],
      channelsLanguageData: this.props.labels.labels,
      userInfo: {},
      language_code: this.props.language_code,
      role_id: this.props.role_id,
      showChannelForm: 0,
      selectedChannelItem: null,
      isChannelLoad: 0,
      isLoadAPI: 0,
    };
    this.searchChannelRef = React.createRef();
    this.searchFieldClearInterval = null;
    this.searchDebounceTime = 500; // milli seconds
    this.cancelUserList = this.cancelUserList.bind(this);
  }
  componentDidMount() {
    FeedsServices.feedsAPIServicesCall(
      "users",
      "get",
      this.userInfoResultHandler
    );
  }
  componentDidUpdate(prevProps) {
    if(this.props.labels.labels !== prevProps.labels.labels) {
        this.setState({
          channelsLanguageData: this.props.labels.labels
        })
      }
}
  userInfoResultHandler = (resultData) => {
    this.setState(
      {
        userInfo: resultData.data,
      },
      () => {
        this.getChannels();
      }
    );
  };
  getResourceFromLabels = (paramLBL) => {
    let resourse =
      this.state.channelsLanguageData &&
      this.state.channelsLanguageData[paramLBL]
        ? this.state.channelsLanguageData[paramLBL]
        : paramLBL;
    return resourse;
  };
  getChannels = () => {
    FeedsServices.feedsAPIServicesCall(
      "channels?page=channels",
      "get",
      this.channelListResultHandler
    );
  };
  channelListResultHandler = (resultData) => {
    if (resultData.data.length === 0) {
      this.setState({
        channelsData: [],
        channelsNewData: [],
        isChannelLoad: 1,
        loading: false,
      });
    } else {
      resultData.data.sort(function (a, b) {
        var nameA = a.channel_name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.channel_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.setState({
        channelsData: [...resultData.data],
        channelsNewData: resultData.data,
        isChannelLoad: 1,
        loading: false,
        currentChannelPage: "manage",
      });
    }
  };
  closeModal = () => {
    this.searchChannelRef.current.value = "";
    this.setState({
      selectedChannelItem: null,
      showDel: false,
      channelsNewData: [...this.state.channelsData],
    });
  };
  addAndUpdateChanel = () => {
    if (
      this.state.selectedChannelItem &&
      this.state.selectedChannelItem._id === undefined
    ) {
      var params = {
        user_id: this.state.userInfo.currentuser.user_id,
        channel_name: this.state.selectedChannelItem.channel_name,
        description: this.state.selectedChannelItem.description,
        channel_profile_image: this.state.selectedChannelItem
          .channel_profile_image,
        channel_cover_image: "",
        active_status: this.state.selectedChannelItem.active_status,
        channel_type: "channels",
      };
      FeedsServices.feedsAPIServicesCall(
        "channels",
        "post",
        this.addEditChannelResultHandler,
        params
      );
    } else {
      var params = {
        user_id: this.state.userInfo.currentuser.user_id,
        channel_name: this.state.selectedChannelItem.channel_name,
        description: this.state.selectedChannelItem.description,
        channel_profile_image: this.state.selectedChannelItem
          .channel_profile_image,
        channel_cover_image: "",
        active_status: this.state.selectedChannelItem.active_status,
        channel_type: this.state.selectedChannelItem.channel_type,
      };
      var apiUrl = "channels/" + this.state.selectedChannelItem._id;
      FeedsServices.feedsAPIServicesCall(
        apiUrl,
        "put",
        this.addEditChannelResultHandler,
        params
      );
    }
  };
  addEditChannelResultHandler = (resultData) => {
    this.getChannels();
    //this.closeModal();
    this.searchChannelRef.current.value = "";
    this.setState({
      selectedChannelItem: null,
      showChannelForm: 0,
    });
  };

  deleteChannel = () => {
    this.setState({ isLoadAPI: 1 });
    let apiUrl = "channels/" + this.state.selectedChannelItem._id;
    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "delete",
      this.deleteChannelResultHandler
    );
  };
  deleteChannelResultHandler = (resultData) => {
    this.searchChannelRef.current.value = "";
    this.setState({ showDel: false, selectedChannelItem: null, isLoadAPI: 0 });
    this.getChannels();
  };
  getProductUsers = () => {
    if (this.state.selectedChannelItem && this.state.selectedChannelItem._id) {
      this.setState({ isLoadAPI: 1 });
      let apiUrl = "channels/" + this.state.selectedChannelItem._id + "/users";
      FeedsServices.feedsAPIServicesCall(
        apiUrl,
        "get",
        this.productListResultHandler
      );
    }
  };
  productListResultHandler = (resultData) => {
    if (resultData.data.length === 0) {
      this.setState({
        channelUsers: [],
        loading: false,
        currentChannelPage: "users",
        isLoadAPI: 0,
      });
    } else {
      this.setState({
        channelUsers: resultData.data,
        loading: false,
        currentChannelPage: "users",
        isLoadAPI: 0,
      });
    }
  };
  getGroupTypes = () => {
    this.setState({ isLoadAPI: 1 });
    GroupServices.getGroupTypeLabels(this.props.language_code).then((response) => {
      //console.log(response)
      this.setState(
        {
          groupTypeNames: response,
        },
        () => {
          this.getGroupList(this.state.groupTypeNames[0].group_type_id);
        }
      );
    });
  };
  /*getGroupTypesResultHandler = (resultData) => {
        console.log(resultData);
        if(resultData.data.length){
            this.setState({ groupTypeNames: [...resultData.data]},()=>{
                this.getGroupList(this.state.groupTypeNames[0].group_type_id);
            })
        }
        else{
            this.setState({ groupTypeNames: []})
        }

    }*/
  getGroupList = (selGroupTypeId) => {
    let apiUrl =
      "channels/" +
      this.state.selectedChannelItem._id +
      "/groups/" +
      selGroupTypeId;
    FeedsServices.feedsAPIServicesCall(
      apiUrl,
      "get",
      this.productGroupResultHandler
    );
  };
  productGroupResultHandler = (resultData) => {
    if (resultData.data.length === 0) {
      this.setState({
        channelGroups: [],
        loading: false,
        currentChannelPage: "groups",
        isLoadAPI: 0,
      });
    } else {
      this.setState({
        channelGroups: [...resultData.data],
        loading: false,
        currentChannelPage: "groups",
        isLoadAPI: 0,
      });
    }
  };
  cancelUserList() {
    this.setState(
      {
        currentChannelPage: "manage",
        channelsNewData: [...this.state.channelsData],
      },
      () => {
        this.searchChannelRef.current.value = "";
      }
    );
  }
  updateUserListToChannel = (data) => {
    let params = {
      channel_userInfo: data,
      channel_id: this.state.selectedChannelItem._id,
      channel_name: this.state.selectedChannelItem.channel_name,
      channel_profile_image: this.state.selectedChannelItem
        .channel_profile_image,
    };
    if (data.length) {
      let apiUrl = "channels/" + this.state.selectedChannelItem._id + "/users";
      FeedsServices.feedsAPIServicesCall(
        apiUrl,
        "put",
        this.userAsignResultHandler,
        params
      );
    } else {
      this.setState(
        {
          currentChannelPage: "manage",
          channelsNewData: [...this.state.channelsData],
        },
        () => {
          this.searchChannelRef.current.value = "";
        }
      );
    }
  };
  userAsignResultHandler = (resutlData) => {
    // this.setState({
    //     currentChannelPage: "manage"
    // });
    this.getChannels();
  };
  cancelGroupList = () => {
    this.setState(
      {
        currentChannelPage: "manage",
        channelsNewData: [...this.state.channelsData],
      },
      () => {
        this.searchChannelRef.current.value = "";
      }
    );
  };
  updateGroupListToChannel = (data) => {
    let params = {
      channel_userInfo: data,
    };
    if (data.length) {
      let apiUrl = "channels/" + this.state.selectedChannelItem._id + "/groups";
      FeedsServices.feedsAPIServicesCall(
        apiUrl,
        "put",
        this.userAsignResultHandler,
        params
      );
    } else {
      this.setState(
        {
          currentChannelPage: "manage",
          channelsNewData: [...this.state.channelsData],
        },
        () => {
          this.searchChannelRef.current.value = "";
        }
      );
    }
  };
  editChannelItem = (data) => {
    //console.log(data);
    this.state.selectedChannelItem = data;
    this.setState({
      showChannelForm: 1,
    });
  };
  deleteChannelItem = (data) => {
    this.setState({
      showDel: 1,
      selectedChannelItem: data
    });
  };
  userEnrollForChannel = (data) => {
    this.setState({selectedChannelItem: data},()=> {
      this.getProductUsers();
    });    
  };
  groupEnrollForChannel = (data) => {
    this.setState({selectedChannelItem: data},()=> {
      this.getGroupTypes();
    });    
  };
  openChannelFormHandler = (evt) => {
    this.setState({
      showChannelForm: 1,
      selectedChannelItem: null,
    });
  };
  formCancelHandler = (data) => {
    this.searchChannelRef.current.value = "";
    this.setState({
      showChannelForm: 0,
      selectedChannelItem: null,
      channelsNewData: [...this.state.channelsData],
    });
  };
  formSaveHandler = (data) => {
    this.state.selectedChannelItem = data;
    this.addAndUpdateChanel();
  };
  channelSearchHandler = (evt) => {
    clearTimeout(this.searchFieldClearInterval);
    this.searchFieldClearInterval = setTimeout(
      this.searchTextDebounce,
      this.searchDebounceTime
    );
  };
  searchTextDebounce = () => {
    console.log("searchTextDebounce");
    let channelVal = this.searchChannelRef.current.value;

    const searchFilterValue = this.state.channelsData.filter((item) => {
      if (
        item.channel_name
          .toLowerCase()
          .includes(String(channelVal.toLowerCase()))
      ) {
        return item;
      }
      return null;
    });
    //console.log("searchFilterValue", searchFilterValue);
    this.setState({
      channelsNewData: searchFilterValue,
    });
  };

  render() {
    if (this.state.isChannelLoad === 0) {
      return (
        <div className="ssi-manCh-mainContainerWrapper">
          <div className="ssi-manCh-headerContainer">
            <div className="header">
              <h3 className="page-title">{this.getResourceFromLabels("LBLMANAGECHANNELS")}</h3>
            </div>
          </div>
          <div className="ssi-manCh-contentContainer">
            <div className="ssi-manCh-subHeaderContainer">
              <div className="header">
                <span className="pageSubTitle">{this.getResourceFromLabels("LBLCHANNELS")}</span>
              </div>
              <div className="ssi-manch-createChannelContainer">
                <div className="ssi-manch-searchInputContainer">
                  <input
                    className="input-field"
                    ref={this.searchChannelRef}
                    placeholder={this.getResourceFromLabels(
                      "LBLSEARCHBYCHANNELNAME"
                    )}
                    onKeyUp={this.channelSearchHandler}
                  />
                </div>
                <button
                  className="button-style primary-btn createChannelBtn"
                  onClick={this.openChannelFormHandler}
                >
                  {this.getResourceFromLabels("LBLCREATECHANNEL")}
                </button>
              </div>
            </div>
            <div className="ssi-manch-channelListContainer">
              <div className="ssi-manch-channelListWrapper">
                {skel.map((i) => {
                  return (
                    <div className="ssi-manch-channelStrip" key={i}>
                      <div className="ssi-manch-channelStripWrapper">
                        <div className="ssi-manch-channelImage">
                          <Skeleton circle={true} height={50} width={50} />
                        </div>
                        <div className="ssi-manch-channelDescription">
                          <a className="channelLink">
                            <div className="channelName">
                              <Skeleton width={95} height={22} />
                            </div>
                          </a>
                          <div className="channelDetails">
                            <span>
                              <Skeleton width={80} height={17} />
                            </span>
                            <span style={{ padding: "0px 10px" }}>
                              <Skeleton width={70} height={17} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (this.state.currentChannelPage === "manage") {
        return (
          <div className="ssi-manCh-mainContainer">
            <div className="ssi-manCh-mainContainerOuterWrapper">
              <div className="ssi-manCh-mainContainerWrapper">
                <div className="ssi-manCh-headerContainer">
                  <div className="header">
                    <h3 className="page-title">
                      {this.getResourceFromLabels("LBLMANAGECHANNELS")}
                    </h3>
                  </div>
                </div>
                <div className="ssi-manCh-contentContainer">
                  <div className="ssi-manCh-subHeaderContainer">
                    <div className="header">
                      <span className="pageSubTitle">{this.getResourceFromLabels("LBLCHANNELS")}</span>
                    </div>
                    <div className="ssi-manch-createChannelContainer">
                      <div className="ssi-manch-searchInputContainer">
                        <input
                          className="input-field"
                          ref={this.searchChannelRef}
                          placeholder={this.getResourceFromLabels(
                            "LBLSEARCHBYCHANNELNAME"
                          )}
                          onKeyUp={this.channelSearchHandler}
                        />
                      </div>
                      <button
                        className="button-style primary-btn createChannelBtn"
                        onClick={this.openChannelFormHandler}
                      >
                        {this.getResourceFromLabels("LBLCREATECHANNEL")}
                      </button>
                    </div>
                  </div>
                  <div className="ssi-manch-channelListContainer">
                    <div className="ssi-manch-channelListWrapper">                      
                      {this.state.channelsNewData.length 
                        ? this.state.channelsNewData.map(
                            (channelItem, index) => {
                              return (
                                <ChannelRowItem
                                  channelItem={channelItem}
                                  key={channelItem._id}
                                  channelsLanguageData={
                                    this.state.channelsLanguageData
                                  }
                                  editChannelItem={this.editChannelItem}
                                  deleteChannelItem={this.deleteChannelItem}
                                  userEnrollForChannel={
                                    this.userEnrollForChannel
                                  }
                                  groupEnrollForChannel={
                                    this.groupEnrollForChannel
                                  }
                                />
                              );
                            }
                          ) 
                        :<EmptyState image={emptyImage} text2={this.getResourceFromLabels("LBLCHANNELNOTAVAILABLE")}/>} 
  
                      <Modal
                        show={this.state.showDel}
                        onHide={this.closeModal}
                        //aria-labelledby="contained-modal-title-vcenter"
                        //centered
                      >
                        <Modal.Header closeButton className="alert-modal-header">
                          <Modal.Title>
                            <h4 className="alert-modal-title">
                              {this.state.userName}
                            </h4>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="form-group">
                            {this.getResourceFromLabels("LBLSELCHANNELALERT")}
                          </div>

                          <div className="row">
                            <div className="col-md-12 text-right">
                              <button
                                type="button"
                                className="button-style secondary-btn btn-align-style "
                                onClick={this.closeModal}
                              >
                                {this.getResourceFromLabels("LBLCANCEL")}
                              </button>
                              <button
                                type="button"
                                className="button-style danger-btn"
                                onClick={this.deleteChannel}
                              >
                                {this.getResourceFromLabels("LBLDELETE")}
                              </button>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                  {this.state.showChannelForm === 1 ? (
                    <div className="ssi-manch-formContainer">
                      <div className="ssi-manch-formContainerWrapper">
                        <ChannelForm
                          formCancelHandler={this.formCancelHandler}
                          formSaveHandler={this.formSaveHandler}
                          selectedChannelItem={this.state.selectedChannelItem}
                          userInfo={this.state.userInfo}
                          channelsLanguageData={this.state.channelsLanguageData}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* {this.state.isLoadAPI === 1 ? (
              <div className="ssi-manch-spinnerForAPI">                
                <Loader></Loader>
              </div>
            ) : (
              ""
            )} */}
          </div>
        );
      } else if (this.state.currentChannelPage === "users") {
        return (
          <UserList
            listUsers={this.state.channelUsers}
            cancelUserList={this.cancelUserList}
            updateUserListToChannel={this.updateUserListToChannel}
            selectedChannelItem={this.state.selectedChannelItem}
            userInfo={this.state.userInfo}
            channelsLanguageData={this.state.channelsLanguageData}
          />
        );
      } else if (this.state.currentChannelPage === "groups") {
        return (
          <GroupList
            groupTypeNames={this.state.groupTypeNames}
            selectedChannelItem={this.state.selectedChannelItem}
            listGroups={this.state.channelGroups}
            getGroupList={this.getGroupList}
            cancelGroupList={this.cancelGroupList}
            updateGroupListToChannel={this.updateGroupListToChannel}
            userInfo={this.state.userInfo}
            channelsLanguageData={this.state.channelsLanguageData}
            langCode={this.state.language_code}
          />
        );
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    language_code: state.user_info_reducer.user_info.language_code,
    role_id: state.user_info_reducer.user_info.role_id,
  };
};
export default connect(mapStateToProps, null)(ManageChannels);
//export default ManageChannels

import React from 'react';
import ToggleButton from './toggleButton';

const ToggleButtonComp = (props) => {
    return (
        <React.Fragment>
            <ul className="options-table" >
                {
                    props.options.map((opt, j) => {
                        return (
                            <li className="option-row" key={j}>
                                <div className="custom-control-togglebutton">
                                    <ToggleButton name={opt.name} checked={opt.userAnswer} disabled={props.showAnswer} label={props.labels[opt.name]} onChange={(e) => props.onChange(e, props.index, props.cIndex, j)} />
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </React.Fragment >
    );
}

export default ToggleButtonComp;
import React from "react";
import CourseCardComponent from "./CourseCardComponent";

const moment = require("moment");
const UnitList = (props) => {
    var prevCourseCompletionDays = 0;
    var CourseCompletionDate = props.currentindex !== 0 && props.previousData?.CourseCompletionDate ? props.previousData?.CourseCompletionDate.split("T")[0] : "";
    const calculateDaysRemaining = (complitiondate) => {
        const currentDate = moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD");
        const completeDate = complitiondate;
        const timeDifference = new Date(currentDate).getTime() - new Date(completeDate).getTime();
        const daysRemaining = timeDifference > 0 ? Math.floor(timeDifference / (1000 * 60 * 60 * 24)) : Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        prevCourseCompletionDays = daysRemaining;
    };
    if (props.currentindex !== 0) {
        calculateDaysRemaining(CourseCompletionDate);
    }
    if (parseInt(props.res.unit_drip_enabled) === 1 || (parseInt(props.res.unit_drip_enabled) === 0 && props.res.sequential_access_unit === 1)) {
        if (
            props.currentindex !== 0 && parseInt(props.res.drip_status) === 0 && props.res.CourseCompletionStatus === "Not Started" &&
            ((prevCourseCompletionDays < props.res.unit_drip_days) || isNaN(prevCourseCompletionDays))
        ) {
            props.res.CourseHide = true;
            props.res.DRIPBLOCKED = true;
        } else {
            props.res.CourseHide = false;
        }
    } else {
        if (
            props.res.sequential_access_unit === 0 ||
            (props.res.sequential_access_unit === 1 &&
                props.res.CourseCompletionStatus === "Completed" &&
                props.res.CourseCompletionPercentage === 100)
        ) {
            props.res.CourseHide = false;
        } else {
            props.res.CourseHide = true;
        }
    }
    console.log(CourseCompletionDate,"props.previousData")
    return (
        <>
            <div className={props.resultData?.length < 3 ?  "grid-item grid-item-fixed" : "grid-item"} key={props.courseid}>
                <CourseCardComponent
                    unitTitle={props.unitTitle}
                    unitdesc={props.unitdesc}
                    currentPage={props.currentPage}
                    CourseCompletionStatus={props.CourseCompletionStatus}
                    CourseCompletionPercentage={props.CourseCompletionPercentage}
                    unitImage={props.unitImage}
                    unitAverageTime={props.unitAverageTime}
                    capsuleListLength = {props.capsuleListLength}
                    labels = {props.labels}
                    handleCourseClick = {props.handleCourseClick} 
                    handleCourseDesc = {props.handleCourseDesc}
                    showPlayIcon = {props.showPlayIcon}
                    showMoreDetails ={props.showMoreDetails}
                    isModule = {props.isModule}
                />
            </div >
        </>
    );
};

export default UnitList;
import React, { Component } from "react";
import ReportServices from "../report_services";
import ScServices from "../../../services/services";
import { Form } from "react-bootstrap";
// import { MDBDataTable } from "mdbreact";
import Loader from "../../../components/loader/Loader";

class RoleAccess extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      access: [],
      roles: [],
      roleVal: 1,
      loading: true,
      roleName: "",
    };
    //   this.getRoles();
  }
  componentDidMount() {
    this.getRBAC(1);
  }

  getRBAC(role) {
    ScServices.getRoles().then((roles) => {
      ReportServices.getRbac(role).then((response) => {
        this.setState({
          access: response.result,
          loading: false,
          roles: roles,
          roleName: roles.find(this.getRoleName),
        });
      });
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.roleName.role) {
      return true;
    } else {
      return false;
    }
  }
  getRoleName = (roles, role) => {
    if (roles.id === parseInt(this.state.roleVal)) {
      return roles.role;
    }
  };
  roleFilter = (e) => {
    this.setState({
      loading: true,
      roleVal: e.target.value,
      access: [],
    });
    this.getRBAC(e.target.value);
  };
  setOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };
  render() {
    const { access, loading, roleVal } = this.state;
    // console.log("loadin", loading);

    let data = {
      columns: [
        {
          label: "Modules",
          field: "module",
          sort: "asc",
          width: 100,
        },
        {
          label: "Pages",
          field: "pages",
          sort: "asc",
          width: 100,
        },
        {
          label: "Full Access",
          field: "access",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    if (!loading) {
      data.rows = access;
      for (let i = 0; i < access.length; i++) {
        let pages = access[i].pages;
        if (pages !== null) {
          let arrPage = pages.split(",");
          let pageHtml = [];
          for (let j = 0; j < arrPage.length; j++) {
            let html = <li>{arrPage[j]}</li>;
            pageHtml.push(html);
          }
          data.rows[i]["pages"] = pageHtml;
        } else {
          data.rows[i]["pages"] = "-";
        }
        data.rows[i]["access"] = "Full";
      }
    }
    return (
      <div className="mob-pad">
        <div >
          <h3 className="page-title">Role Access</h3>
        </div>
        <hr className="head-line" />
        <div className="row rptac-row">
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-xs-12 rpt-rle">
            <label>Role Access</label>
            {this.state.roles.length > 0 ? (
              <Form>
                <Form.Control
                  as="select"
                  onChange={this.roleFilter}
                  value={roleVal}
                >
                  {this.state.roles.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.role}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form>
            ) : (
              ""
            )}
          </div>
          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 rptac-ordr">
            <b className="rptac-head">{this.state.roleName.role}</b>
          </div>
        </div>

        {loading ? (
          <Loader></Loader>
        ) : (
          <div>
          </div>
        )}
      </div>
    );
  }
}

export default RoleAccess;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ScCommonServices from "../../services/common_services";
import "./sidebar.scss";
import { connect } from "react-redux";
import $ from "jquery";
import Translator from '../../translator/Translator';
import { setAccess } from "../../redux/access/access-action/access_actions";

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [],
            lang_code: this.props.user_lang,
            showRateState: false,
            policy_user: false,
            base_url: null,
            Sitetype:localStorage.getItem('site_type')
        };
    }
    componentDidMount() {
        if (this.props.role && this.state.links.length === 0) {
            if (this.props.role === 1) {
                this.setState({
                    base_url: '/schome'
                })
            } else {
                this.setState({
                    base_url: '/home'
                })
            }
            this.getAccessLinks();
            this.getlabels();
        }
    }
    async getlabels() {
        let labels = await Translator.getComponentsLabels("sidebar", this.props.user_lang);
        this.setState({
            labels: labels
        })

    }
    getAccessLinks = () => {
        this.setState({
            showRateState: false,
        });
        if (this.props.role !== "") {
            ScCommonServices.access_privileges(this.props.role).then((access) => {
                if (access.policy_user) {
                    this.setState({
                        links: access.res,
                        policy_user: true,
                    });
                } else {
                    let rys = false
                    access.map((item, i) => {
                        if (
                            item.privilege_id === 17 &&
                            item.page_name === "LBLRATEYOURSTATE" &&
                            this.props.role > 0
                        ) {
                            rys = true;
                            $('body').attr('RYS-SHOW', true);
                        }
                        return null;
                    });
                    if (rys === true) {
                        this.setState({
                            showRateState: true,
                        });
                        localStorage.setItem("showRateState", true);
                    } else {
                        localStorage.setItem("showRateState", false);
                    }
                    this.setState({
                        links: access,
                    });
                    this.props.access_actions(access);
                }
            });
        } else {
            console.log("fjfsdklfjsdlfjsdfkljsdfkl")
        }

    };
    static getDerivedStateFromProps(props, prevState) {
        if (prevState.labels && props.user_lang && props.user_lang !== prevState.lang_code) {
            return {
                lang_code: props.language_code,
                labels: prevState.labels,
                loading: true,
            };
        } else {
            return null;
        }
    }
    close = () => {
        $("#sidenav").css({ display: "none", transition: "0.5s" });
        $(".main-content").css({ marginLeft: "0", transition: "0.2s" });
        $("#side-backdrop").css({ display: "none" });
    };
    render() {

        const { base_url } = this.state
        if (this.state.policy_user && this.state.links.length !== 0) {
            return (
                <div className="sidebar-styles">
                    <aside className="sidenav animated" id="sidenav">
                        <div>
                            <div className="panel-group">
                                {this.state.links.map((item, i) => (
                                    <div className="align" title={item.service_name}>
                                        <NavLink
                                            to={base_url + item.service_route}
                                            activeClassName="active"
                                        >
                                            <div className="active-menu-bar"></div>
                                            <div
                                                className={
                                                    "menubg_sprite " + item.service_name.toLowerCase()
                                                }
                                            ></div>
                                            <span className="collapsed">{item.service_name}</span>
                                        </NavLink>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </aside>
                </div>
            );
        } else if (this.state.links.length !== 0 && this.state.labels) {
            return (
                <div className="sidebar-styles">
                    <aside className="sidenav animated" id="sidenav">
                        <div>
                            <div className="panel-group">
                                {this.state.links.length > 0
                                    ? this.state.links.map((item, i) => {
                                        return (
                                            <div key={"kcq" + Math.random()}>
                                                {item.childrens && item.external_links === null ? (
                                                    <div
                                                        className="side-head"
                                                        data-toggle="collapse"
                                                        href={"#c" + item.privilege_id}
                                                        aria-expanded="false"
                                                        title={this.state.labels.labels[item.page_name]}
                                                    >
                                                        <div
                                                            className={
                                                               `${ this.state.Sitetype==='SafeStart'? 'menubg_sprite' : 'greenmenubg_sprite' } ${item.page_name.toLowerCase()}`
                                                            }
                                                        ></div>
                                                        <span className="drp-menu">
                                                            <h4>
                                                                {this.state.labels.labels[item.page_name]}
                                                            </h4>
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="align" title={this.state.labels.labels[item.page_name]}>
                                                        {item.external_links === null ? (
                                                            <NavLink
                                                                to={base_url + item.route_link}
                                                                activeClassName="active"
                                                            >
                                                                <div className="active-menu-bar"></div>
                                                                <div
                                                                    className={
                                                                      `${ this.state.Sitetype==='SafeStart'? 'menubg_sprite' : 'greenmenubg_sprite' } ${item.page_name.toLowerCase()}`
                                                                    }
                                                                ></div>
                                                                <span className="collapsed">
                                                                    {this.state.labels.labels[item.page_name]}
                                                                </span>
                                                            </NavLink>
                                                        ) : (
                                                            <a
                                                                activeClassName="active"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href={item.route_link}
                                                                title={item.page_name}
                                                            >
                                                                <div className="active-menu-bar"></div>
                                                                <div
                                                                    className={
                                                                        "menubg_sprite " +
                                                                        item.page_name.toLowerCase()
                                                                    }
                                                                ></div>
                                                                <span className="collapsed">
                                                                    {item.page_name}
                                                                </span>
                                                            </a>
                                                        )}
                                                    </div>
                                                )}

                                                {item.childrens
                                                    ? this.state.links[i].childrens.map((childItem) => {
                                                        return (
                                                            <div
                                                                className="collapse in alignment"
                                                                id={"c" + item.privilege_id}
                                                                title={this.state.labels.labels[childItem.page_name]}
                                                            >
                                                                <NavLink
                                                                    to={base_url + childItem.route_link}
                                                                    activeClassName="active"
                                                                >
                                                                    <div className="active-menu-bar"></div>
                                                                    <div
                                                                        className={
                                                                            "menubg_sprite " +
                                                                            childItem.page_name.toLowerCase()
                                                                        }
                                                                    ></div>
                                                                    <span className="collapsed">
                                                                        {
                                                                            this.state.labels.labels[
                                                                            childItem.page_name
                                                                            ]
                                                                        }
                                                                    </span>
                                                                </NavLink>
                                                            </div>
                                                        );
                                                    })
                                                    : ""}
                                            </div>
                                        );
                                    })
                                    : ""}
                            </div>
                        </div>
                    </aside>
                    <div id="side-backdrop"></div>
                </div>
            );
        } else {
            return "";
        }
    }
}
const mapStateToProps = (state) => {
    return {
        role: state.user_info_reducer.user_info.role_id,
        user_lang: state.user_info_reducer.user_info.language_code,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        access_actions: (data) => dispatch(setAccess(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

import React, { Component } from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import { Modal } from "react-bootstrap";
import ChangepasswordForm from "./ChangepasswordForm";
import RegionalSettingForm from "./RegionalSettingForm";
import "./profile.scss";
import * as CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import { Link } from "react-router-dom";
import ScAuth from "../../../auth/auth";
import { connect } from "react-redux";
import services from "../../../services/common_services";
import admin_services from "../../services/services";
import { createBrowserHistory } from "history";
import RoleChanger from "../../../services/RoleChanger";
import ScCommonData from "../../../services/common_data";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import Loader from "../../components/loader/Loader";
import PhoneNumberForm from "./PhoneNumberForm";

 
const history = createBrowserHistory();
const Currencies = ScCommonData.getCurrencies();
const TimeZones = ScCommonData.getTimeZones();
const Languages = ScCommonData.getLanguages();
const DatenewFormats = ScCommonData.getDatenewFormats();
const TimenewFormats = ScCommonData.getTimenewFormats();
 
class Profile extends Component {
  constructor(props) {
    //
    super(props);
    this.state = {
      userData: [],
      status: null,
      loading: false,
      user_roles: [],
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        cha: new Date(),
      },
      phone_number: this.props.phone_number,
      login_with_phone_number: 1,
      ssi_errors: {},
    };
  }
  
  getUserRole = () => {
    services
      .getRoles(true)
      .then((user_roles) => {
        this.setState({
          user_roles: user_roles,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  encryptPassword = (val) => {
    let hash = CryptoJS.SHA512(val);
    let encVal = Base64.stringify(hash);
    return encVal;
  };
  changePassword = (values) => {
    let data = {};
    let oldPassword = values.oldpassword;
    let password = values.password;
    data.id = this.props.userId;
    data.oldPassword = this.encryptPassword(oldPassword);
    data.password = this.encryptPassword(password);
    HttpInterceptor.post("changeuserpassword", data).then((response) => {
      this.setState({
        status: response.data.status,
      });
      if (response.data.status === "1") {
        this.closeModal();
        this.setState({
          status: response.data.status,
          showToast: {
            show: true,
            alertClass: "success",
            status: "Success",
            message: this.props.labels.labels.LBLPWDSCCESS,
            cha: new Date(),
          },
        });
        setTimeout(() => {
          ScAuth.logout(async() => {
            const setCok = await HttpInterceptor.get('cookieset');
          });
        }, 1000);
      }
    });
  };

  changeRegionalSettings = (values, i) => {
    // console.log("saveuser", values)
    let data = {}
    data["id"] = this.props.userId
    
    data["user_language"] = values.user_language ? values.user_language.code : "";
    data["user_timezone"] = values.user_timezone?.value? values.user_timezone.value.toString() : "";
    data["user_dateformat"] = values.user_dateformat? values.user_dateformat.code : "";
    data["user_timeformat"] = values.user_timeformat? values.user_timeformat.code : "";
    HttpInterceptor.post("changeuserregionalsettings", data).then(
      (response) => {
        // console.log("response", response)
        if (response) {
          this.closeModal2();
          this.setState({
            status: response.data.status,
            showToast: {
              show: true,
              alertClass: "success",
              status: "Success",
              message: this.props.labels.labels.LBLUPDTSCCESS,
              cha: new Date(),
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    );
  };

  countDownTimer = () => {
    let cdTimer = this.state.cdTimer
    if (cdTimer > 0) {
      this.setState({
        cdTimer: cdTimer - 1
      })
      setTimeout(this.countDownTimer, 1000)
    }
  }

  changePhoneNumber = (phone_number) => {
    this.closeModal3()
    this.setState({
      phone_number: phone_number
    })
  };
  deletePhone = () => {
    let data = {};
    HttpInterceptor.delete("updatephone", data).then(
      (response) => {
        if (response) {
          this.setState({
            phone_number: ""
          })
        }
      }
    ).catch((err) => {
      if (err) {
        console.log(err)
      }
    });
  }
  changeRole = (e) => {
    this.setState({
      user_roles: [],
    });
    let role = e.target.value;
    let data = {
      role_id: role,
    };
    admin_services
      .changeRoles(data)
      .then((res) => {
        if (res) {
          if (role === "1" || role === "2") {
            history.replace("/home/dashboard");
            window.location.reload();
          } else if (role === "3" || role === "4") {
            history.replace("/schome/dashboard");

            window.location.reload();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  openModal = () => {
    this.setState({ show: true });
  };
  closeModal = () => {
    this.setState({ show: false });
  };
  openModal2 = () => {
    this.setState({ show2: true });
  };
  closeModal2 = () => {
    this.setState({ show2: false });
  };
  openModal3 = () => {
    this.setState({ show3: true });
  };
  closeModal3 = () => {
    this.setState({ show3: false });
  };
  getRegionalData = (xType, xvalue) => {
    var xObj = {};
    let dText = "";
    switch (xType) {
      case "currency":
        xObj =
          Currencies[
          Object.keys(Currencies).find(
            (d, key) => Currencies[d].code === xvalue
          )
          ];
        dText = xObj.name + " (" + xObj.symbol + ")";
        break;
      case "timezone":
        xObj =
          TimeZones[
          Object.keys(TimeZones).find(
            (d, key) => TimeZones[d].value === xvalue
          )
          ];
        dText = xObj.name;
        break;
      case "language":
        xObj =
          Languages[
          Object.keys(Languages).find(
            (d, key) => Languages[d].code === xvalue
          )
          ];
        dText = xObj.name;
        console.log(xObj,"xObj.name",xvalue)
        break;
      case "dateformat":
        xObj =
          DatenewFormats[
          Object.keys(DatenewFormats).find(
            (d, key) => DatenewFormats[d].code === xvalue
          )
          ];
        dText = xObj.name;
        break;
      case "timeformat":
        xObj =
          TimenewFormats[
          Object.keys(TimenewFormats).find(
            (d, key) => TimenewFormats[d].code === xvalue
          )
          ];
        dText = xObj.name;
        break;
      default:
        dText = "--";
    }
    return dText;
  };
  doEvent = (obj, event) => {
    /* Created by David@Refoua.me */
    var event = new Event(event, { target: obj, bubbles: true });
    return obj ? obj.dispatchEvent(event) : false;
  };
  render() {
    // console.log("prpex", this.props)
    const {
      LBLMYPROFILE,
      LBLHERE,
      LBLTO,
      LBLCHANGELOGINPASSWD,
      LBLCANCEL,
      LBLUPDATE,
      LBLDATEFORMAT,
      LBLTIMEFORMAT,
      LBLCLICK,
      LBLTOCHNGREGSETT,
      LBLEMAIL,
      LBLLANGUAGE,
      LBLTIMEZONE,
      LBLUSERNAME,
      LBLLOGGEDINAS,
      LBLORGANIZATIONNAME,
      LBLPHONENUMBER,
    } = this.props.labels.labels;
    return (
      <div>
        {this.state.showToast.show ? (
          <ToastMessage
            show={this.state.showToast["show"]}
            helpers={this.state.showToast}
          ></ToastMessage>
        ) : (
          ""
        )}
        <section>
          <h3 className="page-title">{LBLMYPROFILE}</h3>
          <hr />
          {this.state.loading ? (
            <Loader></Loader>
          ) : (
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12 col-xs-12 ">
                <div className="profile-section">
                  <div className="profile-img">
                    <img
                      className="img-fluid"
                      src="../images/profile.png"
                      alt=""
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">{LBLUSERNAME}</label>
                    <input
                      type="text"
                      className=" input-field" 
                      style={{width: "100%"}}
                      id="username"
                      aria-required="true"
                      required
                      value={this.props.name}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="role">{LBLLOGGEDINAS}</label>
                    <input
                      type="text"
                      className=" input-field" 
                      style={{width: "100%"}}
                      id="txtrole"
                      aria-required="true"
                      maxLength="50"
                      required
                      value={this.props.labels.labels[this.props.role]}
                      disabled
                    />
                  </div>
                  <div>
                    <RoleChanger
                      changeDefault={true}
                      labels={this.props.labels.labels}
                       currentpage={"profile"}

                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="oraganization">{LBLORGANIZATIONNAME}</label>
                    <input
                      type="text"
                      className=" input-field" 
                      style={{width: "100%"}}
                      id="organization"
                      aria-required="true"
                      maxLength="50"
                      required
                      value={this.props.company_name}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">{LBLEMAIL}</label>
                    <input
                      type="email"
                      className=" input-field" 
                      style={{width: "100%"}}
                      id="email"
                      value={this.props.email}
                      disabled
                    />
                  </div>

                  {
                    this.props.login_with_phone_number === 1 ? (<>
                      {
                        this.state.phone_number == null || this.state.phone_number === "" ? (
                          <>
                            <div className="form-group">
                              <label htmlFor="email">{LBLPHONENUMBER}</label>
                              <input
                                type="email"
                                className=" input-field" 
                                style={{width: "100%"}}
                                id="email"
                                value={this.state.phone_number}
                                disabled
                              />
                            </div>
                            <div className="text-left pwdlink"
                              style={{ float: "right", marginTop: "-10px" }}
                            >
                              <Link
                                href="#"
                                data-toggle="modal"
                                onClick={this.openModal3}
                                className="lightblue"

                              >
                                <i> {this.props.labels.labels.LBLADDEDITPHONENEUMBER} </i>
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-left pwdlink"
                              style={{ float: "right", marginTop: "-10px" }}
                            >
                              <Link
                                href="#"
                                data-toggle="modal"
                                onClick={this.deletePhone}
                                className="lightblue"
                              >
                                <i> {this.props.labels.labels.LBLDELETEPHONE} </i>
                              </Link>
                            </div>
                          </>
                        )

                      }
                    </>) : (<>
                    </>)
                  }


                  <div className="login-change-link"
                    style={{ marginTop: this.props.login_with_phone_number === 1 ? "40px" : "20px" }}
                  >
                    {LBLCLICK}{" "}
                    <Link
                      href="#"
                      data-toggle="modal"
                      onClick={this.openModal}
                      className="lightblue"
                    >
                      <i> {LBLHERE} </i>
                    </Link>{" "}
                    {LBLTO}
                    {LBLCHANGELOGINPASSWD}
                  </div>
                  <div className="form-group">
                    <label htmlFor="language">{LBLLANGUAGE}</label>
                    <input
                      type="text"
                      className=" input-field" 
                      style={{width: "100%"}}
                      id="language"
                      value={this.getRegionalData(
                        "language",
                        this.props.language
                      )}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="timezone">{LBLTIMEZONE}</label>
                    <input
                      type="text"
                      className=" input-field" 
                      style={{width: "100%"}}
                      id="timezone"
                      value={this.getRegionalData(
                        "timezone",
                        this.props.timezone
                      )}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="dateformat">{LBLDATEFORMAT}</label>
                    <input
                      type="text"
                      className=" input-field" 
                      style={{width: "100%"}}
                      id="dateformat"
                      value={this.getRegionalData(
                        "dateformat",
                        this.props.dateformat
                      )}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="timeformat">{LBLTIMEFORMAT}</label>
                    <input
                      type="text"
                      className=" input-field" 
                      style={{width: "100%"}}
                      id="timeformat"
                      value={this.getRegionalData(
                        "timeformat",
                        this.props.timeformat
                      )}
                      disabled
                    />
                  </div>
                  <div className="text-left pwdlink"
                    style={{ marginTop: "20px" }}
                  >
                    {LBLCLICK}{" "}
                    <Link
                      href="#"
                      data-toggle="modal"
                      onClick={this.openModal2}
                      className="lightblue"
                    >
                      <i> {this.props.labels.labels.LBLHERE} </i>
                    </Link>{" "}
                    {LBLTOCHNGREGSETT}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="modal-footer text-center" style={{ display: "none" }}>
            <button
              type="button"
              className="btn cancel"
              id="btnaddCancel"
              data-dismiss="modal"
            >
              {LBLCANCEL}
            </button>
            <input
              type="submit"
              className="btn btn-primary"
              id="submitUser"
              value={LBLUPDATE}
            />
          </div>
        </section>
        <Modal show={this.state.show} onHide={this.closeModal}>
          <ChangepasswordForm
            labels={this.props.labels.labels}
            onCancel={this.closeModal}
            onSubmit={this.changePassword}
            message={this.state.status}
            langCode={this.props.language_code}
          ></ChangepasswordForm>
        </Modal>
        <Modal show={this.state.show2} onHide={this.closeModal2}>
          <RegionalSettingForm
            setSelectedDropDownValues={this.setSelectedDropDownValues}
            props={this.props}
            onCancel2={this.closeModal2}
            handleSubmit={this.changeRegionalSettings}
            message={this.state.status}
            labels={this.props.labels.labels}
          ></RegionalSettingForm>
        </Modal>
        <Modal show={this.state.show3} onHide={this.closeModal3}>
          <PhoneNumberForm
            props={this.props}
            onCancel3={this.closeModal3}
            onChange3={this.changePhoneNumber}
            message={this.state.status}
            labels={this.props.labels.labels}
          ></PhoneNumberForm>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
    phone_number: state.user_info_reducer.user_info.phone_number,
    login_with_phone_number: state.user_info_reducer.user_info.login_with_phone_number
  };
};

export default connect(mapStateToProps, null)(Profile);

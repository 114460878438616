import React, { useEffect, useState } from "react";
import PasswordForm from "./PasswordForm";
import PasswordConfirmForm from "./PasswordConfirmForm";
import ScCommonServices from "../services/common_services";
import { Redirect, Route, useLocation, withRouter } from "react-router-dom";
import CompanyLogo from "../admin/components/companylogo/companylogo";
import Loader from "../admin/components/loader/Loader";
import ToastMessage from "../shared/ToastComponent/ToastComponent";
import { HttpInterceptor } from "../auth/HttpInterceptor";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function SetPassword(props) {
  let query = useQuery();
  const [token, setToken] = useState(query.get("4hf_verification"));
  const [token2, setToken2] = useState(
    query.get("verification") ? query.get("verification") : false
  );

  const [status, setStatus] = useState(query.get("status"));
  const [new_url] = useState();

  const [content, setContent] = useState();
  const [user_with_nomail, setUserWithNomail] = useState();
  const [contentpref, setContentPref] = useState();
  const [message, setMessage] = useState(false);
  const [done, setDone] = useState(query.get("done"));
  const [labels] = useState(props.labels);
  const [toast_message, show_toast] = useState({
    show: false,
    alertClass: "",
    status: "",
    message: "",
    changeInToast: new Date(),
  });
  useEffect(() => {
    if (!content) {
      checkLink();
    }
  }, [token2]);
  const checkLink = async () => {
    if (query.get("done")) {
      if (query.get("done") && query.get("verification")) {
        let data = {
          verify: query.get("verification"),
          status: status,
        };
        ScCommonServices.checkInvitation(data)
          .then((response) => {
            setContent(response.data.result);
            setUserWithNomail(response.data.user_with_nomail);
            setContentPref(response.data.pref || "primary");
          })
          .catch((err) => {
            if (err) console.log(err);
            setContent("3");
            setStatus(false);
          });
      }
    } else {
      let data = {
        verify_01: token,
        status: status,
      };
      if (!done && !query.get("verification")) {
        // console.log("checking");

        ScCommonServices.checkInvitation(data)
          .then((response) => {
            // console.log("checking", response);

            if (response && response.data.result) {
              props.history.push(response.data.result);
              setDone(query.get("done"));
              setToken2(query.get("verification"));
            }
          })
          .catch((err) => {
            if (err) {
              console.log(err);
              setContent("3");
              setStatus("false");
            }
          });
      }
    }
  };
  const set_password = async (values) => {
    let data = {
      verify: query.get("verification"),
      status: status,
      mailpref: contentpref,
    };
    if (values.password) {
      let password = await ScCommonServices.createHash(values.password);
      data.u_pwd = password;
    }
    ScCommonServices.setUserPassword(data).then((response) => {
      show_toast({
        show: true,
        alertClass: "success",
        status: "Success",
        message: props.labels.labels.LBLPASSWORDRESETSUCCESS,
        changeInToast: new Date(),
      });
      if (response.status === "1" || response.status === "2") {
        setTimeout(() => {
          show_toast({
            showToast: {
              show: false,
              alertClass: "",
              status: "",
              message: "",
              changeInToast: new Date(),
            },
          });
          props.history.push("/");
        }, 1500);
      } else if (response.status === "3") {
        setMessage(true);
        setTimeout(() => {
          show_toast({
            showToast: {
              show: false,
              alertClass: "",
              status: "",
              message: "",
              changeInToast: new Date(),
            },
          });
        }, 1500);
      }
    });
  };
  const confirm_password = async (values) => {
    let data = {
      verify: token2 ? token2 : query.get("verification"),
      status: false,
      mailpref: contentpref,
    };
    if (values.password) {
      let password = await ScCommonServices.createHash(values.password);
      data.u_pwd = password;
    }

    ScCommonServices.checkUserPassword(data).then((response) => {
      if (response.status === "1" || response.status === "2") {
        show_toast({
          show: true,
          alertClass: "success",
          status: "Success",
          message: props.labels.labels.LBLEMAILVERIFICATIONSUCCESS,
          changeInToast: new Date(),
        });
        setTimeout(() => {
          show_toast({
            showToast: {
              show: false,
              alertClass: "",
              status: "",
              message: "",
              changeInToast: new Date(),
            },
          });
          props.history.push("/");
          window.location.reload();
        }, 1500);
      } else {
        setMessage(true);
      }
    });
  };
  const moveToLogin = async() => {
    localStorage.setItem("sc_user", false);
    localStorage.removeItem("sc_user");
    localStorage.clear();
    const setCok = await HttpInterceptor.get('cookieset');
    props.history.push("/");
  };

  if (user_with_nomail == 1 && status !== "false" && content === "0") {
    //invitation
    if (contentpref === "sprimary") {
      // Primary mail invitation
      return (
        <div className="setpassword-in-section">
          <div className="card">
            <div className="card-body">
              <div className="container text-center">
                {props.company_logo ? (
                  <CompanyLogo
                    className="applogo"
                    imageName={props.company_logo}
                    imgWidth="317px"
                  />
                ) : (
                  ""
                )}
              </div>
              <ToastMessage
                show={toast_message["show"]}
                helpers={toast_message}
              ></ToastMessage>
              <h3 className="conform-ac">{labels.labels.LBLCONFIRMACCOUNT}</h3>
              <PasswordConfirmForm
                PasswordSubmit={confirm_password}
                labels={labels}
              />
              <div className="text-right">
                <div className="text-left">
                  {message ? <p>{message}</p> : <p></p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Secondary mail invitation
      return (
        <>
          <section className="container-fluid bg-fluid">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sign-in-section">
                <div className="section-size">
                  <div className="container text-center">
                    {props.company_logo ? (
                      <CompanyLogo
                        className="applogo"
                        imageName={props.company_logo}
                        imgWidth="317px"
                      />
                    ) : (
                      ""
                    )}
                  </div>

                  <h1>{labels.labels.LBLMAILVERIFICATION}</h1>

                  <div className="text-left">
                    {!message ? (
                      <button
                        className="btn login-btn primary-btn btn-block"
                        type="button"
                        onClick={confirm_password}
                      >
                        {labels.labels.LBLCLICKTOVERIFY}
                      </button>
                    ) : (
                      <p>{labels.labels.LBLVERIFYSUCCESS}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 login-info">
                <div className="info-card ">
                  <img
                    className=" animated fadeInLeft delay-1s"
                    src="../images/home_featured.jpg"
                    alt=""
                  />
                  <p className="animated fadeInLeft delay-2s">
                    {labels.labels.LBLINVITEMESSAGE1}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <ToastMessage
            show={toast_message["show"]}
            helpers={toast_message}
          ></ToastMessage>
        </>
      );
    }
  } else if (user_with_nomail == 1 && status !== "false" && content === "2") {
    //Already Verified
    return (
      <section className="container-fluid bg-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sign-in-section">
            <div className="section-size">
              <div className="container text-center">
                {props.company_logo ? (
                  <CompanyLogo
                    className="applogo"
                    imageName={props.company_logo}
                    imgWidth="317px"
                  />
                ) : (
                  ""
                )}
              </div>
              <h1>{labels.labels.LBLMAILALREADYVERIFED}</h1>
              <div className="text-right">
                <button
                  className="btn login-btn primary-btn btn-block"
                  type="button"
                  onClick={moveToLogin}
                >
                  {labels.labels.LBLCLICKTOLOGIN}
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 login-info">
            <div className="info-card ">
              <img
                className=" animated fadeInLeft delay-1s"
                src="../images/home_featured.jpg"
                alt=""
              />
              <p className="animated fadeInLeft delay-2s">
                {labels.labels.LBLINVITEMESSAGE1}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  } else if (status !== "false" && content === "0") {
    //invitation
    if (contentpref === "primary") {
      // Primary mail invitation
      return (
        <div className="setpassword-in-section">
          <div className="card">
            <div className="card-body">
              <div className="container text-center">
                {props.company_logo ? (
                  <CompanyLogo
                    className="applogo"
                    imageName={props.company_logo}
                    imgWidth="317px"
                  />
                ) : (
                  ""
                )}
              </div>
              <ToastMessage
                show={toast_message["show"]}
                helpers={toast_message}
              ></ToastMessage>
              <h3 className="conform-ac">{labels.labels.LBLCONFIRMACCOUNT}</h3>
              <PasswordForm PasswordSubmit={set_password} labels={labels} />
              <div className="text-right">
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Secondary mail invitation
      return (
        // <div>
          <section className="container-fluid bg-fluid">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sign-in-section">
                <div className="section-size">
                  <div className="container text-center">
                    {props.company_logo ? (
                      <CompanyLogo
                        className="applogo"
                        imageName={props.company_logo}
                        imgWidth="317px"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <h1>{labels.labels.LBLMAILVERIFICATION}</h1>
                  <div className="text-left">
                    {!message ? (
                      <button
                        className="btn login-btn primary-btn btn-block"
                        type="button"
                        onClick={set_password}
                      >
                        {labels.labels.LBLCLICKTOVERIFY}
                      </button>
                    ) : (
                      <p>{labels.labels.LBLVERIFYSUCCESS}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 login-info">
                <div className="info-card ">
                  <img
                    className=" animated fadeInLeft delay-1s"
                    src="../images/home_featured.jpg"
                    alt=""
                  />
                  <p className="animated fadeInLeft delay-2s">
                    {labels.labels.LBLINVITEMESSAGE1}
                  </p>
                </div>
              </div>
            </div>
          </section>
      );
    }
  } else if (
    (status === "true" && content === "2") ||
    (status === "false" && content === "2")
  ) {
    //invitation already accepted mode
    return (
      <section className="container-fluid bg-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sign-in-section">
            <div className="section-size">
              <div className="container text-center">
                {props.company_logo ? (
                  <CompanyLogo
                    className="applogo"
                    imageName={props.company_logo}
                    imgWidth="317px"
                  />
                ) : (
                  ""
                )}
              </div>
              <h1>{labels.labels.LBLALREADYACCEPTINVITE}</h1>
              <div className="text-right">
                <button
                  className="btn login-btn primary-btn btn-block"
                  type="button"
                  onClick={moveToLogin}
                >
                  {labels.labels.LBLCLICKTOLOGIN}
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 login-info">
            <div className="info-card ">
              <img
                className=" animated fadeInLeft delay-1s"
                src="../images/home_featured.jpg"
                alt=""
              />
              <p className="animated fadeInLeft delay-2s">
                {labels.labels.LBLINVITEMESSAGE1}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  } else if (status === "false" && content === "0") {
    //invitation Rejection

    return (
        <section className="container-fluid bg-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sign-in-section">
              <div className="section-size">
                <div className="container text-center">
                </div>
                <h1>{labels.labels.LBLINVITEREJECT}</h1>
                <div className="text-left">
                  {!message ? (
                    <button
                      className="btn login-btn primary-btn btn-block"
                      type="button"
                      onClick={set_password}
                    >
                      {labels.labels.LBLCLICKTOREJECT}
                    </button>
                  ) : (
                    <p>{labels.labels.LBLREJECTIONSUCCESS}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 login-info">
              <div className="info-card ">
                <img
                  className=" animated fadeInLeft delay-1s"
                  src="../images/home_featured.jpg"
                  alt=""
                />
                <p className="animated fadeInLeft delay-2s">
                  {labels.labels.LBLINVITEMESSAGE1}
                </p>
              </div>
            </div>
          </div>
        </section>
    );
  } else if (
    (status === "false" && content === "1") ||
    (status === "true" && content === "1")
  ) {
    //invitation already Rejected mode

    return (
        <section className="container-fluid bg-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sign-in-section">
              <div className="section-size">
                <div className="container text-center">
                </div>
                <h1>{labels.labels.LBLALREADYREJECTTINVITE}</h1>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 login-info">
              <div className="info-card ">
                <img
                  className=" animated fadeInLeft delay-1s"
                  src="../images/home_featured.jpg"
                  alt=""
                />
                <p className="animated fadeInLeft delay-2s">
                  {labels.labels.LBLINVITEMESSAGE1}
                </p>
              </div>
            </div>
          </div>
        </section>
    );
  } else if (
    (status === "true" && content === "3") ||
    (status === "false" && content === "3")
  ) {
    //invitation mailid mismatch mode
    return (
      <section className="container-fluid bg-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sign-in-section">
            <div className="section-size">
              <div className="container text-center">
                {props.company_logo ? (
                  <CompanyLogo
                    className="applogo"
                    imageName={props.company_logo}
                    imgWidth="317px"
                  />
                ) : (
                  ""
                )}
              </div>
              <h1>{labels.labels.LBLURLEXPIRED}</h1>
              <div className="text-right">
                <button
                  className="btn login-btn primary-btn btn-block"
                  type="button"
                  onClick={moveToLogin}
                >
                  {labels.labels.LBLCLICKTOLOGIN}
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 login-info">
            <div className="info-card ">
              <img
                className=" animated fadeInLeft delay-1s"
                src="../images/home_featured.jpg"
                alt=""
              />
              <p className="animated fadeInLeft delay-2s">
                {labels.labels.LBLINVITEMESSAGE1}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  } else if (
    (status === "true" && content === "4") ||
    (status === "false" && content === "4")
  ) {
    //invitation mailid mismatch mode
    return (
      <section className="container-fluid bg-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 sign-in-section">
            <div className="section-size">
              <div className="container text-center">
                {props.company_logo ? (
                  <CompanyLogo
                    className="applogo"
                    imageName={props.company_logo}
                    imgWidth="317px"
                  />
                ) : (
                  ""
                )}
              </div>
              <h1>{labels.labels.LBLURLEXPIRED}</h1>
              <div className="text-right">
                <button
                  className="btn login-btn primary-btn btn-block"
                  type="button"
                  onClick={moveToLogin}
                >
                  {labels.labels.LBLCLICKTOLOGIN}
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-xs-12 login-info">
            <div className="info-card ">
              <img
                className=" animated fadeInLeft delay-1s"
                src="../images/home_featured.jpg"
                alt=""
              />
              <p className="animated fadeInLeft delay-2s">
                {labels.labels.LBLINVITEMESSAGE1}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <div>
        <Loader></Loader>
      </div>
    );
  }
}

export default withRouter(SetPassword);

import { HttpInterceptor } from '../../../../auth/HttpInterceptor'; //'../../ ../../auth/HttpInterceptor';

class NearMissService {
    nearmissAPIServicesCall(apiName, method, callback, data, domainURL = process.env.REACT_APP_FEEDAPI_URL) {
        let apiUrl = domainURL + apiName;
        // console.log("NearMissService",data)
        if (method === "get") {
            HttpInterceptor[method](apiUrl,data)
                .then(result => {
                    if (callback) {
                        callback(result);
                    }
                }).catch(errr => {
                    if (errr && errr.response && errr.response.status && Number(errr.response.status)) {
                        //alert("Error code : " + Number(errr.response.status))
                    }
                    
                })
        }
        else {
            HttpInterceptor[method](apiUrl, data)
                .then(result => {
                    if (callback) {
                        callback(result);
                    }
                }).catch(errr => {
                    
                })
        }
    }
     
  


}
export default new NearMissService();


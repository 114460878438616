import React, { useState,useEffect,useRef } from "react";
import styles from "./ManagementHeader.module.scss";
import $ from "jquery";
const TrainingResetHeader = (props) => {
    const [recordStatus, setRecordStatus] = useState(false);
    const recordStatusRef = useRef(null);
    const { 
        LBLUSERSSELECTED, 
        LBLCLEAR,        
        LBLRESET 
    } = props.labels;    
    useEffect(() => {
        function handleClickOutside(event) {
            console.log($(event.target) && $(event.target).parent() && $(event.target).parent().get(0),event.target,"event")
            let elements = event.target; //document.getElementById("target");
            let els = [];
            let classAtr = "";
            while (elements) {
                els.unshift(elements);
                elements = elements.parentNode;
                classAtr =
                  elements && elements.getAttribute && elements.getAttribute("class")
                    ? elements.getAttribute("class")
                    : "";
                //console.log(classAtr,elements,"classAtrconsole")
                if (classAtr.includes("MuiDayCalendar") || classAtr.includes("MuiPickersPopper-root")) {
                  break;
                }
              }
              //console.log(classAtr,"classAtr")
            if (recordStatusRef.current && !recordStatusRef.current.contains(event.target)&& !classAtr && !classAtr.includes("MuiDayCalendar") && !classAtr && !classAtr.includes("MuiPickersPopper-root") ) {
                setRecordStatus(false);
            }
        }

        // Attach the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    let Sitetype=localStorage.getItem('site_type');
    return (
        <>
            <div className={Sitetype==='SafeStart'?styles.container:styles.greencontainer}>
                <div className={Sitetype==='SafeStart'?styles.selectedcount:styles.greenselectedcount}>{props.selectedRowsCount}</div>
                <span className={styles.coursesselected}>{LBLUSERSSELECTED}</span>
                <span className={styles.clearbtn} onClick={props.handleClear}>{LBLCLEAR}</span>
                <button className={Sitetype==='SafeStart'?styles.selectallbtn:styles.greenselectallbtn} onClick={props.handleRowChange}>{props.selectAll}</button>
                <div className={styles.hrline}></div>
                <div style={{position:"relative"}}>
                    <button className={Sitetype==='SafeStart'?styles.recordStatusbtn:styles.greenrecordStatusbtn} onClick={()=>(props.saveHandler())} id="setcoursereset">{LBLRESET}</button>
                </div>
            </div>
            {props.visibleRowsSelected ?
                <div className={styles.visiblerowsselected}>
                    {props.visiblerowsselectedLabel}
                </div>
                :
                null
            }
        </>
    );
}
export default TrainingResetHeader;
import React from "react";
import { connect } from "react-redux";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import { TextField } from "@material-ui/core";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import DataTable from "react-data-table-component";
import "date-fns";
import Skeleton from "react-loading-skeleton";
import Alert from "@material-ui/lab/Alert";
import { Modal } from "react-bootstrap";
import ReferenceMaterial from "./referencematerials";
import orderBy from "lodash/orderBy";
import Snackbar from "@material-ui/core/Snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./steercomconfiguration.scss";
import Loader from "../../components/loader/Loader";
import * as FileSaver from "file-saver";
import AddTask from './addtask'; 
import FilterComp from "../../../common_components/filter/filterComp"; 
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { enUS,esES,deDE, ptBR, frFR,svSE ,ruRU} from '@material-ui/core/locale';
import emptyImage from '../../../assets/images/prodlist_emptyst.png'
import EmptyState from "../../../common_components/empty_state/Empty_state";
 
let locale_code = {"en-US":enUS,"it-IT":enUS,"es-MX":esES,"de-DE":deDE,
                   "pt-BR":ptBR,"fr-FR":frFR,"sv-SE":svSE,"ru-RU":ruRU};
let theme = createTheme(  enUS); 

const Excel = require('exceljs');
const $ = require("jquery");
let parentask = "";
let g_sfid = "";
let showMessage1 = ""
let lastsortorder = 50;
let lbltaskdeleteconfirm = "";
let lbltaskresetconfirm = "";
let g_sfchange = false;
let objLabels = [];
let parentDescription = "";
class TaskSetup extends React.Component {
  constructor(props) {
    super(props);
    const { callcomponents, editTeamDetails } = props; 
    this.state = {
      setEditFlag: 0,
      totMin:0, 
      isDefaultTemplate:true,
      isEditable:false,
      initialValues: null,
      ssi_errors: null,
      defaultTemplate:{ "template_id": "default", "template_name": "Default" },
      tempLanguages:[
        { "template_id": "default", "template_name": "Default" },
      ],
      templateName:"",
      errorTempName:false,
      errorDefLang:false,
      showNewTemp:false,
      totMax:0,
      msg_confirm1:"", 
      errorMile:false,
      showMilestone: false,
      taskDetails: [],
      showFilter: false,
      showEditMile: false,
      e_description: "",
      resetitem: "",
      reportname: "",
      visible: false,
      allrecords: true,
      showEdit: false,
      showSave: false,
      isParentEdit: false,
      minValue: 0,
      weightage1:100,
      weightage2:100,
      weightage3:100,
      weightage4:100,
      weightage5:100, 
      tasktype: -1,
      showWeightage: false,
      editModeFlag: 0,
      teamTemplate: { "template_id": "default", "template_name": "Default" },
      taskTypeArr: [],
      completion_percentage: "a",
      cntWeight: 0,
      inc_weight: 0,
      master_tasks: null,
      role_id: props.role,
      showmodal: false,
      initialValues: [],
      user_id: props.user_id,
      opendialog3: false,
      steercomSettings: {},
      currentTaskStatus: "YETTOSTART",
      ctstatus: false,
      selectedTask: 0,
      reconfirm: false,
      teamList: [],
      expandFlag: true,
      userlist: [],
      firstLoad: true,
      SuccessFactorListData: [],
      dateformat: props.dateformat,
      successFactorsList: {},
      teamData: {},
      teamRolesList: [],
      teamMembersList: [],
      showMessage1: "",
      successFactorData: {},
      teamSuccessFactorList: [],
      editingTeamSF: {
        M_NEW: {
          teamId: null,
        },
      },
      selectRange:0,
      tasknamefocus: false,
      descriptionfocus: true,
      referencefocus: false,
      prefocus: false,
      weightfocus: false,
      deadlinefocus: false,
      hasRights: false,
      loading: false,
      showtime: 9000,
      openalert: false,
      isPriorTeam: false,
      showPriorMemebers: true,
      teamSFListLoading: false,
      memberIdOpenState: "",
      memberSteercomRoleIdOpenState: "",
      showToast: {
        show: false,
        alertClass: "",
        status: "",
        message: "",
        changeInToast: new Date(),
      },
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
      err_task_name: false, err_task_description: false,
      err_weightage: false, err_default_role: false, err_references: false,
      err_recommended_start_days: false,
    };
    theme = createTheme( locale_code[this.props.language_code]);
  }
  applyFilters = (filter) => { 
    this.setState({ allFilterValues: filter, showNode: false , showFilter: false });
    if (!filter.reset) {
      this.setState({ visible: "block" });
    }
    $(".bounceInDown").hide();
    $(".modal-backdrop").css({ position: "relative" });
    $(".modal-backdrop.show").css({ opacity: "0" });

  };
  keyvaluechanges = (response) => {
    let objAllGroups = [];
    let 
      singles = [];
    let objStatus = [
      { name: "LBLMANDATORY", id: "1" },
      { name: "LBLBESTPRACTICE", id: "0" },
    ];

    singles.push({
      items: objStatus,
      itemsinfo: {
        itemid: "status",
        itemname: this.getLabel("LBLCATEGORY"),
        label: "LBLCATEGORY",
        trans: true,
      },
    });
    this.setState({
      toFilters: {
        multi: [],
        single: singles,
      },
    });

    return objAllGroups;
    // response.groups[0].groups_by_type.split('"group_id":').join('"id":').split('"group_name":').join('"name":')
  };
  componentWillUnmount() {
    $("#root").css("height", "100%");
    $("#root").css("overflow", "auto");
  }
  componentDidMount() {
    $('#root').scrollTop(0);
    $("#root").css("height", "100%");
    $("#root").css("border", "0px solid red");
    $("#root").css("overflow", "hidden");
    $(".cls_add_teams").show();
    var header = this.props.labels.labels.LBLSTEERCOMDET;
    $("#header_det").text(header);
    $("#sub_header_det").css("display", "none");
    if (this.props.location.state && this.props.location.state.parentId) {
      parentask = this.props.location.state.parentId;
    }
    //this.getAllResetTasks();
    this.setdateformat();
    this.getSteercomSettings();

    document.onkeydown = function (event) {
      if (event.key == 'ArrowLeft' || event.key == 'ArrowRight' || event.key == 'ArrowDown' || event.key == 'ArrowUp') {
        return false;
      }
    }
  }
  componentDidUpdate() { 
    $(".rdt_TableBody").css({ height: $("#root").height() - 188 + "px" }); //255
    // alert($("#root").height() - 188 )
    $(".rdt_TableBody").scroll(function () {
      $(".minipop").hide();
      $("input").blur();
      var scrollLeft = $(this).scrollLeft();
      $(".rdt_TableHead").scrollLeft(scrollLeft);
    });
    $("body").on("onresize", function () {
      $(".divDataTable").css({
        width: $(".main-content").width() - 10 + "px",
        transition: "0.5s",
      });
    });
    if (navigator.userAgent.indexOf("Firefox") != -1) {
      $(".cls_add_expand_collapse").css({ "top": "0px" })
    }
   // this.rowReorder();
  }
  rowReorder=()=>{ 
    let { teamTemplate, isDefaultTemplate, showWeightage } = this.state;
    let tablename,tempName=teamTemplate.template_id;
    if (!this.state.showWeightage && !isDefaultTemplate){
      $('.rdt_TableBody').sortable({
        stop:function(event,ui){
        var prev = $(ui.item).prev('div.rdt_TableRow').attr('id');
        var next = $(ui.item).next('div.rdt_TableRow').attr('id');
        console.log('prev = ' + prev + ' ' + $(ui.item).attr('id') + ' next = ' + next);
        console.log("ITEM = " + ui.item);
        }
      });
  }else{ 
    if($('.rdt_TableBody').hasClass("ui-sortable"))
    {
      $('.rdt_TableBody').removeClass("ui-sortable") ;
      $('.rdt_TableBody').removeClass("ui-sortable-disable") ;
      $('.rdt_TableBody').sortable('disable'); 
    }
       
  }
  }
  setContent = () => {
    setTimeout(() => {
      $(".main-content").css({ marginLeft: "0", transition: "1s" });
      $("aside").addClass("slideOutLeft");
      $("#sidenav").css({ display: "none" });
    },100);
  };
  getSteercomSettingsCallback = (response) => {
    let steercomSettings = response.data.result;
    let {tempLanguages,defaultTemplate} = this.state; 
    for (let x in steercomSettings) {
      steercomSettings[x].map((item, index) => {
        if (item.name && item.name.indexOf("LBL") == 0) {
          item.name = this.getLabel(item.name);
        }
        if (item.title && item.title.indexOf("LBL") == 0) {
          item.title = this.getLabel(item.title);
        }
        if (item.description && item.description.indexOf("LBL") == 0) {
          item.description = this.getLabel(item.description);
        }
      });
    } 
    let obj = steercomSettings.successfactors.find(v => v.name === 'LBLALL');
    if (obj == undefined || obj.length == 0) {
      steercomSettings.successfactors.unshift({
        active: 1,
        color: "sf_color1",
        description: "",
        id: "0",
        name: "LBLALL",
        title: this.props.labels.labels.LBLALL,
        version: "1"
      });
    } 
    let locTempLanguages=tempLanguages; 
    if(steercomSettings.templates){
      locTempLanguages=steercomSettings.defaulttemps.length>0?[]:locTempLanguages;
      steercomSettings.templates.map((itm)=>{
        steercomSettings.defaulttemps.map((item)=>{
        if(itm.template_id==item.template_id){
          locTempLanguages.push(item);
        }
      });
      });
      steercomSettings.defaulttemps = locTempLanguages; 
      tempLanguages = steercomSettings.defaulttemps;
    }
    if (this.props.location.state && this.props.location.state.successFactorData) {

      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: this.props.location.state.successFactorData, 
        tempLanguages:locTempLanguages 
      });
    } else {
      this.setState({
        steercomSettings: steercomSettings,
        successFactorsList: steercomSettings.successfactors,
        successFactorData: steercomSettings.successfactors[0],
        tempLanguages:tempLanguages
      }
        , this.getTeamMembersList(0)
      );
    }
  };
  getSteercomSettings = () => {
    SteeringCommitteeService.SteeringAPIServicesCall(
      "steercomsettings",
      "get",
      this.getSteercomSettingsCallback,
      {}
    );
  };
  getAllResetTasks = async (language) => {
    language = language ? language : "en-US"
    let res = await SteercomServices.getAllResetTasks(language);
    this.setState({
      master_tasks: res.result
    })
  }
  deleteResetTask = async (data) => {
    let result = await SteercomServices.deleteResetTask(data);
    if (result.result.status == "SUCCESS") {
      this.showAlert("success", this.props.labels.labels.deleteResetTask, 3000);
      this.getTeamSFList();
    }
  }
  updateResetAllTask = async (item) => {
    let { successFactorData, teamTemplate } = this.state;
    let data = {};
    data.language_code = teamTemplate.id;
    data.success_factor_id = successFactorData.id; 
    item.template_id = teamTemplate.template_id;
    item.template_from = item.template_from ;
    let result = await SteercomServices.updateResetAllTask(item);
    if (result.status == "SUCCESS") {
      this.showAlert("success", this.props.labels.labels.LBLRESETATASKSUCC, 3000);
      this.getTeamSFList();
    }
  }
  updateResetTask = async (item) => {
    let { master_tasks, teamTemplate } = this.state;
    let data = {};
    data.language_code = teamTemplate.id;
    data.template_id = "default";//teamTemplate.template_id;
    data.success_factor_id = item.success_factor_id;
    data.task_display_id = item.task_display_id;
    data.custom_task = item.custom_task;
    delete item.company_id;
    let result = await SteercomServices.updateResetTask(item);
    if (result.status == "SUCCESS") {
      this.showAlert("success", this.props.labels.labels.LBLRESETATASKSUCC, 3000);
      this.getTeamSFList();
    }
  }
  getTeamList = () => {
    let apiUrl = "steercomteams";
    this.getTeamListCallback();
  };
  getTeamListCallback = async () => {
    let res = await SteercomServices.getTeamListPlan();
    let teamList = res.result;
    let filterTeamList = teamList.filter((item) => {
      if (this.props.role_id > 1) {
        if (item.team_administrator == this.props.userId) {
          return item;
        } else if (item.memberofteamsf > 0) {
          return item;
        }
      } else {
        return item;
      }
    });
    let reportname = "Task-Configuration";
    this.setState(
      {
        teamList: filterTeamList,
        loading: false,
        reportname: reportname
      },
      () => {

        if (this.props.location.state && this.props.location.state.teamData) {
          let locteamid = this.props.location.state.teamData.team_id;
          var teamData = locteamid && filterTeamList.find(function (items) {
            return items.team_id == locteamid;
          });
          if (teamData == undefined || teamData == null) {
            teamData = filterTeamList[0];
          } else {
            teamData = this.props.location.state.teamData;
          }
          this.setState({
            teamData: teamData,//filterTeamList[0],
            successFactorData: this.props.location.state.successFactorData
          })
          this.getTeamMembersList(teamData.team_id);
        } else {
          this.getTeamMembersList(0)
          this.setState(
            {
              teamSFListLoading: true,
            }
          )

        }
      }

    );
  };
  getTeamSFList = (mode) => {
    let language_code = this.state.teamTemplate.template_id;
    let teamid = 0;//this.state.teamData.team_id;
    let sfid = this.state.successFactorData.id;
    this.getTeamSFListCallback(teamid, sfid, language_code,mode)
    // }
  };
  getTeamSFListCallback = async (teamid, sfid, language_code,mode) => {
    let l_sfid = sfid ? sfid : 0; //"0";
    teamid = 0
    let res = await SteercomServices.getTeamSFListForTask(teamid, language_code, l_sfid);
    this.setState({
      teamSFListLoading: false,
      loading: false
    })

    let base_date,
      teamSFList = res.result;
    if (teamSFList.length > 0) {
      base_date = teamSFList[0].base_date;
      for (let i = 0; i < teamSFList.length; i++) {
        if (this.state.firstLoad) {
          teamSFList[i].expandFlag = 0;
        } else if (parentask == teamSFList[i].parent_task_display_id) {
          teamSFList[i].expandFlag = 1;
        } else {
          teamSFList[i].expandFlag = 0;
        }
        teamSFList[i].expandFlag = 1; // remove
        if (teamSFList[i].task_name_custom_translation && teamSFList[i].task_name_custom_translation != "") {
          teamSFList[i].task_name = teamSFList[i].task_name_custom_translation;
        }
        if (teamSFList[i].task_description_custom_translation && teamSFList[i].task_description_custom_translation != "") {
          teamSFList[i].task_description = teamSFList[i].task_description_custom_translation;
        }
        teamSFList[i].recommended_start_date = base_date ? new Date(base_date) : new Date();

        teamSFList[i].recommended_start_date.setDate(
          teamSFList[i].recommended_start_date.getDate() +
          teamSFList[i].recommended_start_days
        );
        if (
          teamSFList[i].task_type == 1 &&
          base_date &&
          !teamSFList[i].planned_start_date
        ) {
          teamSFList[i].planned_start_date = new Date(base_date);
          teamSFList[i].planned_start_date.setDate(
            teamSFList[i].planned_start_date.getDate() +
            teamSFList[i].recommended_start_days
          );

        }
        teamSFList[i].team_id = "100";
        teamSFList[i].team_member_id = "100";
        if (teamSFList[i].task_type == 1 && !teamSFList[i].steercom_role_id) {
          teamSFList[i].steercom_role_id = teamSFList[i].default_role_id;
        }
      }
    } else {
      teamSFList = [];
    } 
    this.keyvaluechanges(teamSFList);
    this.loadTaskSFList(teamSFList); 
    let taskTypeArr = 
      [ 
        { "id": 0, "name": this.props.labels.labels.LBLPOSTBASEDATE },
        { "id": 1, "name": this.props.labels.labels.LBLPREBASEDATE }
      ]; 
    let taskCategoryArr =
      [{ "id": 0, "name": this.props.labels.labels.LBLBESTPRACTICE },
       { "id": 1, "name": this.props.labels.labels.LBLMANDATORY }
      ];
    this.setState({
      loading: false,
      visible: true,
      firstLoad: false,
      teamSFListLoading: false,
      expandFlag: true,
      loadingSection: false,
      taskTypeArr: taskTypeArr,
      taskCategoryArr: taskCategoryArr,
      editModeFlag: 0
    },  this.setUpdateEdit(mode));
     
  };
  sortByKeyAsc = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  loadTaskSFList = (teamSFList) => {
    let SuccessFactorListData = [], modSuccessFactorListData = [], modSuccessFactorListDataAll = [];
    let SuccessFactorListItems = [];
    SuccessFactorListData = teamSFList;
    let cntWeight = 0, cnt = -1;
    //SuccessFactorListData = this.sortByKeyAsc(SuccessFactorListData, "parent_task_display_id");
    if (!this.state.showWeightage){
      SuccessFactorListData = this.sortByKeyAsc(SuccessFactorListData, "recommended_start_days");
    }else{
      SuccessFactorListData = this.sortByKeyAsc(SuccessFactorListData, "parent_task_display_id");
    }
   
    let prevTask, currTask, AddedMilestoneSFList = [];
    let milestones = [];
    let flag0 = true, flag1 = true, flag2 = true, flag3 = true, flag4 = true, flag5 = true;
    let cnts = 0, subFlag = true, subTasks = [], mainTasks = [];
     let weightage1=0,weightage2=0,weightage3=0,weightage4=0,weightage5=0;
    SuccessFactorListData.map((item, idx) => { 
      if (item.task_type == 0) {
        item.task_name = item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name);
        if (mainTasks.length == 0) mainTasks.push(item);
        //if (mainTasks.findIndex(items => items.task_name == item.task_name) < 0) {
          mainTasks.push(item);
        //}
      }
 
      if (item.task_type == 1) {
        item.task_name = item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name);
        if (subTasks.length == 0) subTasks.push(item);
        if (subTasks.findIndex(items => items.recommended_start_days == item.recommended_start_days) < 0) {
          subTasks.push(item)
        }
      }
      if (item.task_type == 2) {
        milestones.push(item);
      }
       if(item.task_type != 2){
        modSuccessFactorListData.push(item)
       }
    });
    //modSuccessFactorListData = SuccessFactorListData;
    milestones.sort(function (a, b) {
      return a.recommended_start_days - b.recommended_start_days;
    });
    let previous = 2000,lenMile = milestones.length;
    modSuccessFactorListData = this.sortByKeyAsc(modSuccessFactorListData, "recommended_start_days");
    modSuccessFactorListData.map(items=>{
     // milestones.map(item=>{ 
        if(cnts<lenMile){
          if(items.recommended_start_days == milestones[cnts].recommended_start_days){
            let x = 0;
          }
          if (items.recommended_start_days > milestones[cnts].recommended_start_days) { 
            AddedMilestoneSFList.push(milestones[cnts]);
            cnts++;
          } 
        } 
   //   }); 
      previous = items.recommended_start_days;
      AddedMilestoneSFList.push(items);
    });
    let {LBLSELECTS,LBLALL} = this.props.labels.labels;
    LBLSELECTS = LBLSELECTS?LBLSELECTS:"-- Select --"; 
 
    milestones.unshift({ 
      task_id: "0",
      task_name:  LBLSELECTS,
      custom_task_name:  LBLSELECTS,
      title:  LBLSELECTS,
      recommended_start_days:-1000,
      version: "1"
    });
    subTasks.unshift({
      active: 1,
      color: "sf_color1",
      description: "",
      recommended_start_days :  LBLSELECTS,
      task_id: "0",
      task_name:  LBLSELECTS,
      custom_task_name:  LBLSELECTS,
      title:  LBLSELECTS,
      version: "1"
    });
    modSuccessFactorListData = AddedMilestoneSFList;

    this.setState({
      teamSuccessFactorList: modSuccessFactorListData,
      teamSuccessFactorListAll: modSuccessFactorListData,
      SuccessFactorListItems: SuccessFactorListItems,
      cntWeight: cntWeight,
      inc_weight: cntWeight,
      mainTasksList: mainTasks,
      subTasks: subTasks,
      selectRange:subTasks[0],
      milestones:milestones,
      selectMile:milestones[0],
      weightage1:weightage1,
      weightage2:weightage2,
      weightage3:weightage3,
      weightage4:weightage4,
      weightage5:weightage5
    })
  }

  getTeamMembersList = (team_id) => {
    let apiUrl = "steercomteammembers/" + team_id; 
    this.getTeamMembersListCallback(team_id);
  };
  getTeamMembersListCallback = async (team_id) => {
    let { hasRights } = this.state;
    if (this.props.userId == this.state.teamData.created_by || this.props.role_id == 1 ||
      this.props.userId == this.state.teamData.team_administrator) {
      hasRights = true;
    }

    this.setState(
      {
        teamData: [],// teamData,
        teamMembersList:[],// teamMembersList,
        teamRolesList: [],// teamRolesList,
        steercomRoles: [],// roles,
        expandFlag: false,
        hasRights: hasRights,
        teamSFListLoading: true,
        loading: false,
        steercom_roles: [],// roles
      },
      () => {
        setTimeout(() => {
          this.getTeamSFList()
        }, 100)
      }
    );
  };

  getRoles = (roles, template_id) => {
    let { steercomSettings } = this.state;
    let steercom_roles = steercomSettings.roles.filter((item) => {
      return item.template_id == template_id;
    });
    return steercom_roles;
  }



  getLabel = (xfield, mode) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (xfield != undefined) {
        if (
          typeof xfield === "object" &&
          this.props.labels.labels.hasOwnProperty(xfield.label)
        ) {
          return this.props.labels.labels[xfield.label].replace(
            "$$$",
            xfield.val
          );
        } else {
          return xfield + "111";
        }
      } else {
        return "";
      }
    }
  };

  deleteSuccessFactorCallback = (res) => {
    this.getSuccessFactorsList();
  };
  deleteSuccessFactor = (item, event) => {
    if (isNaN(item.team_member_id)) {
      this.setState({
        addSuccessFactor: false,
      });
    } else {
      if (
        this.state.editingTeamSF.hasOwnProperty("M_" + item.task_display_id) ==
        true
      ) {
        let { successFactorsList, editModeFlag, teamSuccessFactorList } = this.state;
        let editingTeamSF = this.state.editingTeamSF;
        item.isNewAssigned = 0;
        item.isNewAssigned = 0;
        teamSuccessFactorList.map((xitem, index) => {
          if ((xitem.isNewAssigned || 0) == 1) {
            xitem.isNewAssigned = 0;
            xitem.isNewAssigned = 0;
          }
        });
        this.setState({
          teamSuccessFactorList,
          editingTeamSF,
          editModeFlag: 0,
          showSave: false
        });
      }
    }
  };
  deleteTask = async (item, row) => {
    let tmpSuccessFactorData = {};
    tmpSuccessFactorData.steercom_role_id = item.steercom_role_id;
    tmpSuccessFactorData.task_display_id = item.task_display_id;
    tmpSuccessFactorData.del_flag = 1;
    tmpSuccessFactorData.updated_by = this.props.user_id;
    tmpSuccessFactorData.task_id = item.task_id;
    tmpSuccessFactorData.task_type = item.task_type;
    tmpSuccessFactorData.language_code = item.language_code;
    tmpSuccessFactorData.template_id = item.template_id;
    tmpSuccessFactorData.parent_task_display_id = item.parent_task_display_id;

    let res = await SteercomServices.deleteTask(tmpSuccessFactorData);
    let taskname = item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name);
    if (res.status == "SUCCESS") {
      this.showAlert("success", this.props.labels.labels.LBLDELTASK + " :  " + taskname, 3000);
      this.getTeamSFList();
    } else if (res.result.status == "LBLTASKASSIGNED") {
      this.showAlert("warning", this.props.labels.labels.LBLTASKASSIGNED, 3000);
    }
  }
  checkValidations = (tmpTemplateDetails) => {

    let taskname = "", checkVal = true;
    let { err_task_name, err_task_description, err_weightage, err_references, teamSuccessFactorList,
      err_default_role, err_task_type, err_recommended_start_days, cntWeight, editingTeamSF } = this.state;

    let loc_editingTeamSF = editingTeamSF["M_" + tmpTemplateDetails.task_display_id];
    if ($.trim(tmpTemplateDetails.task_name) == "") {
      checkVal = false;
      err_task_name = true;
    } else {
      tmpTemplateDetails.task_name = loc_editingTeamSF.task_name.indexOf("LBL") > -1 ? this.getLabel(loc_editingTeamSF.task_name) : loc_editingTeamSF.task_name;
      //tmpTemplateDetails.custom_task_name = loc_editingTeamSF.custom_task_name.indexOf("LBL")>-1?this.getLabel(loc_editingTeamSF.custom_task_name):loc_editingTeamSF.custom_task_name;
      err_task_name = false;
    }

    if (checkVal) {
    }
    if ($.trim(tmpTemplateDetails.task_description) == "") {
      checkVal = false;
      err_task_description = true;
    } else {
      err_task_description = false;
    }
    if ((tmpTemplateDetails.weightage || "") == "" && tmpTemplateDetails.task_type == 1) {
      tmpTemplateDetails.weightage = tmpTemplateDetails.weightage == "" ? 0 : tmpTemplateDetails.weightage;
    } else {
      cntWeight = cntWeight + parseInt(tmpTemplateDetails.weightage);
      if (cntWeight > 100) {
        err_weightage = false;
      } else {
        err_weightage = true;
      }
    }
    if ((tmpTemplateDetails.default_role_id || "") == "" && tmpTemplateDetails.task_type == 1) {
      checkVal = false;
      err_default_role = true;
    } else {
      err_default_role = false;
    }
    if ((tmpTemplateDetails.recommended_start_days || "") == "" && tmpTemplateDetails.task_type == 1) {
      // checkVal = false;
      // err_recommended_start_days = true;
      tmpTemplateDetails.recommended_start_days = 0;
    } else {
      err_recommended_start_days = false;
    }
    if (tmpTemplateDetails.task_type_base == undefined && tmpTemplateDetails.task_type == 1) {
      checkVal = false;
      err_task_type = true;
    } else {
      err_task_type = false;
    }
    // if ((tmpTemplateDetails.recommended_start_days || "") == "" && tmpTemplateDetails.task_type == 1) {
    //   checkVal = false;
    //   err_recommended_start_days = true;
    // } else {
    //   err_recommended_start_days = false;
    // }
    if (tmpTemplateDetails.task_type == 1 && loc_editingTeamSF.custom_references && loc_editingTeamSF.custom_references.length > 250) {
      checkVal = false;
    } else {
      var regex = new RegExp(/^([a-z]:((\\|\/|\\\\|\/\/))|(\\\\|\/\/))[^<>:"|?*]+/i);
      if (loc_editingTeamSF && $.trim(loc_editingTeamSF.custom_references) != "") {
        if (regex.test(loc_editingTeamSF.custom_references)) {
          err_references = false;
        } else {
          // checkVal = false;
          //  err_references = true;
        }
      }

    }
    if (tmpTemplateDetails.custom_references && tmpTemplateDetails.custom_references.length > 250) {
      checkVal = false;
      this.showAlert("warning", this.props.labels.labels.LBLREFMAXEXCEED, 3000);
    }
    this.setState({
      err_task_name: err_task_name,
      err_task_description: err_task_description,
      err_weightage: err_weightage,
      err_default_role: err_default_role,
      err_task_type: err_task_type,
      err_references: err_references,
      err_recommended_start_days: err_recommended_start_days,
      editingTeamSF
    })

    return checkVal;
  }
  handleEditParent = (item, mode) => {
    let showSave = true, status = 1;
    let { successFactorData, steercomSettings, teamSuccessFactorList, editingTeamSF, teamTemplate } = this.state;
    if (mode == 1) {
      for (var i = 0; i < teamSuccessFactorList.length; i++) {
        if (item.task_display_id == teamSuccessFactorList[i].task_display_id
          && teamSuccessFactorList[i].task_type == 0 && teamSuccessFactorList[i].team_id != "Add_Main_Task") {
          //$("#save_"+item.task_display_id).css({display:"inline-flex"});
          teamSuccessFactorList[i].isNewAssigned = 1;
          editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id] = {
            success_factor_id: teamSuccessFactorList[i].success_factor_id,
            task_display_id: teamSuccessFactorList[i].task_display_id,
            task_name: teamSuccessFactorList[i].custom_task_name ? teamSuccessFactorList[i].custom_task_name : teamSuccessFactorList[i].task_name,
            task_description: teamSuccessFactorList[i].custom_task_description ? teamSuccessFactorList[i].custom_task_description : this.getLabel(teamSuccessFactorList[i].task_description),
            custom_task_name: teamSuccessFactorList[i].custom_task_name,
            custom_task_description: teamSuccessFactorList[i].custom_task_description
          };
        }
      }
    } else {
      //$("#save_"+item.task_display_id).css({display:"none"});
      showSave = false;
      for (var j = 0; j < teamSuccessFactorList.length; j++) {
        if (item.task_display_id == teamSuccessFactorList[j].task_display_id) {
          teamSuccessFactorList[j].isNewAssigned = 0;
        }
      }
    }
    //editingTeamSF["M_" + row.task_display_id].task_name = item.custom_task_name?item.custom_task_name:item.task_name
    this.setState({
      editingTeamSF,
      teamSuccessFactorList,
      showEdit: false,
      showSave: showSave
    })
  }
  showEditParent = (item, mode) => {
    let { showSave, editModeFlag } = this.state;
    let showEdit = (mode == 1 && editModeFlag == 0) ? true : false;
    if (showSave) {
      showEdit = false;
    }
    this.setState({
      showEdit: showEdit,
      showTask: item.task_display_id
    })
  }
  saveSuccessFactorTemp = (tmpSuccessFactorData,currid,mode) => {
    let values = tmpSuccessFactorData;
    let { editingTeamSF, teamTemplate } = this.state;
    let loc_values = editingTeamSF["M_" + values.task_display_id];
    let sfid = values.parent_task_display_id ? values.parent_task_display_id.split("M")[0] : values.parent_task_display_id;
    loc_values = loc_values ? loc_values : [];
    lastsortorder++;
    var tmpTemplateDetails = {
      task_id: values.task_id,
      parent_task_name:loc_values.parent_task_name,
      template_id: teamTemplate.template_id, 
      template_name: teamTemplate.template_name,
      language_code: teamTemplate.id,
      version: "1.0",
      success_factor_id: sfid,//values.success_factor_id,
      task_display_id: values.task_display_id,
      parent_task_display_id: values.parent_task_display_id,
      task_type: values.task_type,
      sequece_order: lastsortorder,
      task_name: loc_values.task_name ? $.trim(loc_values.task_name) : "",
      task_description: tmpSuccessFactorData.custom_task_description ? $.trim(tmpSuccessFactorData.custom_task_description) : "",
      task_type_base: loc_values.task_type_base,
      recommended_start_days: loc_values.recommended_start_days,
      weightage: loc_values.weightage,
      created_by: this.props.userId,
      updated_by: this.props.userId,
      del_flag: 0,
      references: loc_values.references,
      custom_references: loc_values.custom_references,
      default_role_id: "default",// loc_values.default_role_id,
      directchild: loc_values.directchild,
      category: loc_values.category
    }
    if (this.checkValidations(tmpTemplateDetails)) {
      tmpTemplateDetails.recommended_start_days = Math.abs(tmpTemplateDetails.recommended_start_days);
      tmpTemplateDetails.recommended_start_days = tmpTemplateDetails.task_type_base == 0 ? tmpTemplateDetails.recommended_start_days : "-" + tmpTemplateDetails.recommended_start_days;
      this.saveSuccessFactorTempCallback(tmpTemplateDetails,mode);
    } 
  }
  saveSuccessFactorTempCallback = async (tmpSuccessFactorData,mode) => {
    let successFactorDetails = tmpSuccessFactorData;
    let labelname = "LBLADDSUCCESS", callType = "add";
    if (tmpSuccessFactorData.task_id) {
      callType = "put";
      labelname = "LBLUPDTSUCCESS"; 
    }
    if(tmpSuccessFactorData.parent_task_name){
      labelname =  "LBLADDMAINSUCCESS";
      if(callType == "put"){
        let locItems = this.getParentItems(tmpSuccessFactorData);
        //successFactorDetails.task_id = locItems.task_id;
        successFactorDetails.parent_task_name = tmpSuccessFactorData.parent_task_name;
        successFactorDetails.parent_task_id = locItems.task_id;
      }
    }
   delete successFactorDetails.company_id;
    let res = await SteercomServices.saveSuccessFactorTask(successFactorDetails, callType);
      
    if (callType == "put") {
      labelname = "LBLUPDTSUCCESS";
    }
    if (res.status == "SUCCESS") {
      this.getTeamSFList(mode);
      this.showAlert("success", this.props.labels.labels[labelname], 3000);
     
    } else {
     // alert(2)
      //this.showAlert("warning", this.props.labels.labels.LBLTASKEXISTS, 3000);
    }
    this.setState({
      showSave: false,
      opendialog3: false
    })
  }
  saveSuccessFactor = (item) => {
    let tmpdata = this.state.editingTeamSF["M_" + item.task_display_id];
    if (
      this.state.editingTeamSF.hasOwnProperty("M_" + item.task_display_id) ==
      false
    ) {
      return false;
    }
    parentask = item.parent_task_display_id;
    var tmpSuccessFactorData = {
      team_id: tmpdata.team_id,
      success_factor_id: tmpdata.success_factor_id,
      task_display_id: tmpdata.task_display_id,
      actual_start_date: tmpdata.actual_start_date,
      actual_end_date: tmpdata.actual_end_date,
      task_status: tmpdata.task_status,
      completion_percentage: tmpdata.completion_percentage,
      task_description: $.trim(tmpdata.task_description),
      planned_end_date: item.planned_end_date
    };
    if (this.checkValidations(tmpSuccessFactorData)) {
      this.saveSuccessFactorCallback(tmpSuccessFactorData);
    }
  };
  saveSuccessFactorCallback = async (tmpSuccessFactorData) => {
    delete tmpSuccessFactorData.company_id;
    let res = await SteercomServices.saveSuccessFactorTask(tmpSuccessFactorData);

    if (res.status == "SUCCESS") {
      this.getTeamSFList();
      this.showAlert("success", this.props.labels.labels["LBLSUCCESS"], 3000);
    } else {
      this.showAlert("success", "ERROR!!");
    }
  }; 
  showAlert = (messageType, message, showtime) => { 
    this.setState({
      openalert: true,
      messageType: messageType,
      message: message,
      showtime: showtime ? showtime : 3000
    });
  };
  doEvent = (obj, event) => {
    /* Created by David@Refoua.me */
    var event = new Event(event, { target: obj, bubbles: true });
    let x = obj ? obj.dispatchEvent(event) : false;
    return x;
  };

  updateEditFlag = (item, status) => {
    let { selectedTask, teamSuccessFactorList, editingTeamSF, editModeFlag, showSave } = this.state;
    let loc_editModeFlag = status ? 0 : 1;
    if (editModeFlag && status == 0 && g_sfchange || (showSave == true)) {
      this.showAlert("warning", this.props.labels.labels.LBLADDUNSAVEDATA, 5000);
      return false;
    }
    g_sfchange = false;
    for (var i = 0; i < teamSuccessFactorList.length; i++) {
      if (item.task_display_id == teamSuccessFactorList[i].task_display_id) {
        teamSuccessFactorList[i].isNewAssigned = status ? 0 : 1;
        if ((teamSuccessFactorList[i].isNewAssigned || 0) == 1) {
          selectedTask = item;
          editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id] = {
            // team_id: teamSuccessFactorList[i].team_id,
            success_factor_id: teamSuccessFactorList[i].success_factor_id,
            task_display_id: teamSuccessFactorList[i].task_display_id,
            task_name: teamSuccessFactorList[i].custom_task_name ? teamSuccessFactorList[i].custom_task_name : teamSuccessFactorList[i].task_name,
            task_description: teamSuccessFactorList[i].custom_task_description ? teamSuccessFactorList[i].custom_task_description : this.getLabel(teamSuccessFactorList[i].task_description),
            custom_task_name: teamSuccessFactorList[i].custom_task_name,
            custom_task_description: teamSuccessFactorList[i].custom_task_description,
            weightage: teamSuccessFactorList[i].weightage,
            task_type: teamSuccessFactorList[i].task_type,
            task_type_base: teamSuccessFactorList[i].recommended_start_days > -1 ? 0 : 1,
            category: (teamSuccessFactorList[i].category == "Mandatory" || teamSuccessFactorList[i].category == 0) ? 0 : 1,
            default_role_id: this.getRoleId(teamSuccessFactorList[i].default_role_id),
            recommended_start_days: teamSuccessFactorList[i].recommended_start_days,
            references: teamSuccessFactorList[i].references,
            custom_references: teamSuccessFactorList[i].custom_references,
            oldMemberId: teamSuccessFactorList[i].team_member_id,
            parent_task_name: this.getParentName(teamSuccessFactorList[i])
          };
          if (teamSuccessFactorList[i].task_display_id.indexOf("-C-") > -1) {
            editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id].task_name = teamSuccessFactorList[i].custom_task_name;
            editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id].task_description = teamSuccessFactorList[i].custom_task_description;
          }
        } else {
          delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
        }
      } else if (
        status == 0 &&
        (teamSuccessFactorList[i].isNewAssigned || 0) == 1
      ) {
        this.openConfirmDialog3(teamSuccessFactorList, editingTeamSF, i);
        teamSuccessFactorList[i].isNewAssigned = 0;
        delete editingTeamSF["M_" + teamSuccessFactorList[i].task_display_id];
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
      editingTeamSF: editingTeamSF,
      selectedTask: selectedTask,
      editModeFlag: loc_editModeFlag
    }));
  };
  updateExpandFlag = (item, status) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    for (let i = 0; i < teamSuccessFactorList.length; i++) {
      if (
        item.task_display_id == teamSuccessFactorList[i].parent_task_display_id
      ) {
        teamSuccessFactorList[i].expandFlag = status ? 0 : 1;
        if (teamSuccessFactorList[i].team_id == "Add_Task" || teamSuccessFactorList[i].team_id == "New_Task") {

        }
      }
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
    }));
  };
  updateExpandFlagAll = (status, item) => {
    let teamSuccessFactorList = this.state.teamSuccessFactorList;
    let expandFlag = this.state.expandFlag ? 0 : 1;
    for (let i = 0; i < teamSuccessFactorList.length; i++) {
      teamSuccessFactorList[i].expandFlag = status ? 0 : 1;
    }
    this.setState((state, props) => ({
      teamSuccessFactorList: teamSuccessFactorList,
      expandFlag: expandFlag
    }));
  };
  resetAllTasks = () => {
    let { successFactorData, teamTemplate,teamSuccessFactorList } = this.state;
    let item = {
      success_factor_id: successFactorData.id,
      language_code: teamTemplate.template_id,
      template_from:teamSuccessFactorList[0].template_from
    }
    //updateResetAllTask
    this.openConfirmDialog2(item, 2)
  }
  resetSingleTask = (item) => {
    this.openConfirmDialog2(item)
    //this.updateResetTask(item)
  }
  openConfirmDialog2 = (item, tstatus) => {
    // let opendialog3 = false, teamSuccessFactorList = this.state.teamSuccessFactorList;
    lbltaskresetconfirm = this.props.labels.labels.LBLRESETCONFIRM + (item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name)) + "?";
    if (tstatus == 2) {
      lbltaskresetconfirm = this.props.labels.labels.LBLRESETALLCONFIRM;
    }
    this.setState({
      opendialog2: true,
      resetitem: item,
      tstatus: tstatus,
      reconfirm: true
    })
  }
  handleCloseDialog2 = (val) => {
    let { tstatus, resetitem, reconfirm } = this.state;
    let opendialog = false;
    if (val.currentTarget.id == 2) {
      lbltaskresetconfirm = this.props.labels.labels.LBLRESETRECONFIRM;
      if (tstatus == 2) {
        lbltaskresetconfirm = this.props.labels.labels.LBLRESETALLRECONFIRM;
      }
      if (!reconfirm) {
        if (tstatus == 2) {
          this.updateResetAllTask(resetitem);
        } else {
          resetitem.custom_task = false;
          if (resetitem.task_display_id.indexOf("-C-") > -1) {
            resetitem.custom_task = true;
          }
          this.updateResetTask(resetitem);
        }
      }

    } else {
      reconfirm = false
    }

    if (reconfirm) {
      opendialog = true
    }
    this.setState({
      opendialog2: opendialog,
      tstatus: tstatus,
      reconfirm: false
    });
  }
  openConfirmDialog1 = (item, status, evt) => {
    let opendialog1 = false;
    let msg_confirm1="", taskDatas, editingTeamSF = this.state.editingTeamSF;
    taskDatas = editingTeamSF["M_" + item.task_display_id]; 
    if (status == 1) {
      opendialog1 = true;
      let label = item.task_type==0?this.props.labels.labels.LBLMAINDELCONFIRM:this.props.labels.labels.LBLDELCONFIRM;
      lbltaskdeleteconfirm = label + (item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name)) + "?";
      if(item.task_type==0)
      msg_confirm1 =  this.props.labels.labels.LBLMAINDELCONFIRM1?this.props.labels.labels.LBLMAINDELCONFIRM1:"  The below sub-task(s) will also be deleted." ;
    }else if (status == 2) { 
      taskDatas.task_id = item.task_id;
      taskDatas.task_type = item.task_type;
      if (this.checkValidations(taskDatas)) { 
        opendialog1 = true;
       lbltaskdeleteconfirm = this.props.labels.labels.LBLEDITCONFIRMRL;
       status=0;
      }
    } else {
      taskDatas.task_id = item.task_id;
      taskDatas.task_type = item.task_type;
      if (this.checkValidations(taskDatas)) {
        opendialog1 = true;
        lbltaskdeleteconfirm = this.props.labels.labels.LBLEDITCONFIRMRL;
      }
    }
    this.setState({
      opendialog1: opendialog1,
      curritem: item,
      currstatus: status,
      msg_confirm: lbltaskdeleteconfirm,
      msg_confirm1:msg_confirm1
    })
  }
  handleCloseDialog1 = (val) => {
    let lbltaskdeleteconfirm="",opendialog1=false;
    let { currstatus, curritem } = this.state;
    if (val.currentTarget.id == 2) {
      if (currstatus == 1) {
        opendialog1=true;
        currstatus=2;
        lbltaskdeleteconfirm = this.props.labels.labels.LBLDELRECONFIRM; 
        lbltaskdeleteconfirm=lbltaskdeleteconfirm?lbltaskdeleteconfirm:"Once you deleted, Permanently you will lose this task, Do you want to delete this task?."
      }else if (currstatus == 2) { 
        this.deleteTask(curritem, currstatus); 
      } else {
        this.saveSuccessFactorTemp(curritem, currstatus)
      }
    }
    this.setState({
      opendialog1: opendialog1,
      currstatus: currstatus,
      msg_confirm: lbltaskdeleteconfirm,
      currstatus:currstatus,
      msg_confirm1:""
    })
  }
  openConfirmDialog3 = (item, status) => { 
    let opendialog3 = false, teamSuccessFactorList = this.state.teamSuccessFactorList;
    // let editingTeamSF = this.state.editingTeamSF;
    let currentId=0;
    for (var i = 0; i < teamSuccessFactorList.length; i++) {
      if (teamSuccessFactorList[i].isNewAssigned == 1 && teamSuccessFactorList[i].task_display_id != item.task_display_id) {
        opendialog3 = true;
        currentId = teamSuccessFactorList[i].task_id;
      }
    }
    if (!opendialog3) {
      this.updateEditFlag(item, status);
    }
    this.setState({
      opendialog3: opendialog3,
      updateItem: item,
      updateStatus: status,
      currentId:currentId
    })
  }
  handleCloseDialog3 = (val) => {
    let { updateItem, updateStatus ,currentId,teamSuccessFactorList} = this.state;
    if (val.currentTarget.id == 2) {
      let currentItem = teamSuccessFactorList.filter((item,idx)=>{ return item.task_id == currentId});
      this.setState({
        opendialog3: false
      },this.saveSuccessFactorTemp(currentItem[0], currentId, 2)) 
      //this.updateEditFlag(updateItem, updateStatus);
    }else{
      this.updateEditFlag(updateItem, updateStatus);
    } 
    this.setState({
      opendialog3: false
    })
  }
  setUpdateEdit=(mode)=>{
    if(mode==2){
      let { updateItem, updateStatus } = this.state;
      this.updateEditFlag(updateItem, updateStatus);
    }
  }
  handleSFChange = (event, values) => {
    g_sfid = values;
    g_sfchange = false;
    let { isDefaultTemplate,steercomSettings} = this.state;
    let steercomSettingsLoc = steercomSettings;
    let showWeightage = values.id == 0 ? false : true;
    if(showWeightage){
      isDefaultTemplate=true;
    }else{
      isDefaultTemplate = steercomSettingsLoc.defaulttemps.findIndex(item=>item.template_id == values.template_id && item.is_default==1)>=0?true:false;
      //isDefaultTemplate = teamTemplate && (teamTemplate.template_id=='default' || teamTemplate.template_id=='french' || teamTemplate.template_id=='quick100')?true:false;
    }  
    let cnt_add = 1, teamSuccessFactorList = this.state.teamSuccessFactorList;
    teamSuccessFactorList.map((items, idx) => {
      if (items.team_id == "New_Task" || items.team_id == "New_Main_Task" || items.isNewAssigned == 1) {
        cnt_add++;
      }
    });
    if (cnt_add > 1) {
      this.showAlert("warning", this.props.labels.labels.LBLADDUNSAVEDATA, 5000);
      g_sfchange = true;
      return false;
    }
    this.setState(
      {
        successFactorData: values,
        showWeightage: showWeightage,
        isDefaultTemplate:isDefaultTemplate
      },
      this.getTeamSFList
    );
  };
  handleTeamChange = (event, values) => {
    parentask = "";
    this.getTeamMembersList(values.team_id);
  }; 
  handleRangeChange =(event, values)=>{
    this.setState({
      selectRange: values,
    });
   
  }
  handleTemplateChange = (event, values) => { 
      this.setState({
        teamTemplate: values,
        defaultTemplate:values
      });   
  };
  handleLanguageChange = (event, values) => {
    if (values.id != "") {
      $("#span_teamlanguage").hide();
    } else {
      $("#span_teamlanguage").show();
    }
    let cnt_add = 1;
    let { showWeightage, teamTemplate, teamSuccessFactorList,steercomSettings} = this.state;
    let steercomSettingsLoc = steercomSettings;
    teamSuccessFactorList.map((items, idx) => {
      if (items.team_id == "New_Task" || items.team_id == "New_Main_Task" || items.isNewAssigned == 1) {
        cnt_add++;
      }
      return null;
    });
    let isDefaultTemplate ;// = (values.template_id=='default' ||  values.template_id=='quick60' ||  values.template_id=='french' || values.template_id=='quick100') ?true:false;
    isDefaultTemplate = steercomSettingsLoc.defaulttemps.findIndex(item=>item.template_id == values.template_id && item.is_default==1)>=0?true:false;
    isDefaultTemplate = showWeightage?true:isDefaultTemplate;
    if (cnt_add > 1) {
      this.showAlert("warning", this.props.labels.labels.LBLADDUNSAVEDATA, 5000);
      this.setState({
        teamTemplate: teamTemplate,
        isDefaultTemplate:isDefaultTemplate
      });
    } else {
      this.setState({
        teamTemplate: values, 
        isDefaultTemplate:isDefaultTemplate,
        loading:true,
      }, this.getTeamSFList);
    }

  };
  onfocus1 = (item, event) => {
    let editingTeamSF = this.state.editingTeamSF;
    event.target.selectionStart = event.target.selectionEnd =
      editingTeamSF["M_" + item.task_display_id]["focus_position"] ||
      event.target.value.length;
  };

  handleCompletionPercentage = (item, event) => {
    let currval = event.target.value;
    let editingTeamSF = this.state.editingTeamSF;
    if (editingTeamSF["M_" + item.task_display_id].task_status == "COMPLETED") {
      return false;
    } else if (editingTeamSF["M_" + item.task_display_id].task_status == "YETTOSTART") {
      return false;
    }
    if (!currval.match(/^(\d|-)+$/)) {
      currval = currval.replace(/[^0-9-]/g, "");
    } else if (currval < 0 || currval.includes("-")) {
      currval = 0;
    } else if (currval > 100) {
      currval = 100;
    }
    if (editingTeamSF["M_" + item.task_display_id].task_status != "COMPLETED" && currval == 100) {
      currval = 99;
    }
    if (editingTeamSF["M_" + item.task_display_id].task_status == "YETTOSTART") {
      if (currval > 0) currval = 0;
    }
    if (editingTeamSF["M_" + item.task_display_id].task_status == "INPROGONTRACK" ||
      editingTeamSF["M_" + item.task_display_id].task_status == "INPROGONDELAY") {
      if (currval == 0) currval = 1;
      else if (currval == 100) currval = 99;
    }
    editingTeamSF["M_" + item.task_display_id]["completion_percentage"] = "";
    editingTeamSF["M_" + item.task_display_id]["completion_percentage"] = currval;

    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "completion_percentage";

    this.setState({
      editingTeamSF,
    });
  };
  handleClose = (xVar, item) => {
    this.handleCloseAlert();
    let editingTeamSF = this.state.editingTeamSF;
    if (item && editingTeamSF["M_" + item.task_display_id]) {
      editingTeamSF["M_" + item.task_display_id][xVar] = false;
      this.setState({
        editingTeamSF,
        openalert: false
      });
    }
  };
  handleCloseAlert = () => {
    this.setState({ openalert: false });
  };
  setdateformat = () => {
    let dateformat,
      locformat = this.state.dateformat;

    if (locformat == "DD/MM/YYYY") {
      dateformat = "dd/MM/yyyy";
    } else if (locformat == "MM/DD/YYYY") {
      dateformat = "MM/dd/yyyy";
    } else if (locformat == "YYYY/MM/DD") {
      dateformat = "yyyy/MM/dd";
    } else {
      dateformat = "dd/MM/yyyy";
    }
    this.setState({
      dateformat: dateformat,
    });
    return dateformat;
  };
  addSuccessFactor = () => {
    this.setState({
      addSuccessFactor: true,
    });
  };
  closeModal = () => {
    this.setState({
      show: false,
      initialValues: null,
      showDel: false,
      ssi_errors: null,
    });
  };
  closeRefModal = (mode, b) => {
    if (mode == 1) {
      this.getTeamSFList();
    }
    this.setState({
      showmodal: false,
    });
  };
  openRefModal = (row) => {
    this.setState({
      showmodal: true,
      resourceData: JSON.parse(row.references || '{"folder_id":998}'),
      resourceDataCustom: row.custom_references || ""
    });
  };
  showNotes = (row) => {
    let editingTeamSF = this.state.editingTeamSF;
    if (
      editingTeamSF["M_" + row.task_display_id].hasOwnProperty("notes_flag") ==
      true
    ) {
      editingTeamSF["M_" + row.task_display_id].notes_flag =
        !editingTeamSF["M_" + row.task_display_id].notes_flag;
    } else {
      editingTeamSF["M_" + row.task_display_id].notes_flag = true;
    }
    if (editingTeamSF["M_" + row.task_display_id].notes_flag == true) {
      editingTeamSF["M_" + row.task_display_id]["auto_focus"] =
        "task_description";
    } else {
      editingTeamSF["M_" + row.task_display_id]["auto_focus"] = "";
    }

    this.setState({
      editingTeamSF: editingTeamSF,
    });
  };
  handleDescription = (item, event) => {
    let locval = event.target.value;
    let editingTeamSF = this.state.editingTeamSF;
    editingTeamSF["M_" + item.task_display_id]["task_description"] = locval;
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "task_description";
    editingTeamSF["M_" + item.task_display_id]["focus_position"] = event.target.selectionStart;
    this.setState(
      {
        editingTeamSF,
      },
      event.target.focus()
    );
  };
  closeDescription = () => {
    showMessage1 = "";
    this.setState({
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
      showMessage1: ""
    });
  };
  showAddEdit = (items, details, obj) => {
    let mainTasks = [], allTasks = [], allItems = this.state;
    let teamTemplate = allItems.teamTemplate;
    let mode = items == 1 ? "add" : "edit";
    let cnt = 0, taskDetails = [];
    allTasks = allItems.teamSuccessFactorList;
    allTasks.map((item) => {
      if (item.task_type == 0) {
        item.task_name = item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name);
        if (cnt == 0) mainTasks.push(item);
        if (mainTasks.findIndex(items => items.task_name != item.task_name) > -1) {
          mainTasks.push(item);
        }
        cnt++;
      }
      return null;
    });
    let taskTypeArr =
    [ { "id":-1, "name": this.props.labels.labels.LBLSELECTS},
      { "id": 0, "name": this.props.labels.labels.LBLPOSTBASEDATE },
      { "id": 1, "name": this.props.labels.labels.LBLPREBASEDATE }
    ];
    taskDetails["all_tasks"] = allTasks;
    taskDetails["row"] = (items);
    taskDetails["template_data"] = teamTemplate;
    taskDetails["team_language"] = allItems.teamTemplate;
    taskDetails["main_tasks"] = mainTasks;
    taskDetails["steercom_roles"] = allItems.steercom_roles;
    taskDetails["success_factors"] = allItems.successFactorsList;
    taskDetails["task_type"] =  taskTypeArr;
    taskDetails["task_category"] = allItems.taskCategoryArr;

    this.setState({
      showPopUp: true,
      showmode: mode,
      taskDetails: taskDetails
    });
  }
  handleDescriptionPop = (event) => {
    let locval = event.target.value;
    let editingTeamSF = this.state.editingTeamSF;
    let currentRow = this.state.currentRow;
    editingTeamSF["M_" + currentRow.task_display_id]["task_description"] = locval;
    parentDescription = locval;
    this.setState(
      {
        editingTeamSF,
        e_description: locval
      }
    );
  };
  showDescNotes = (row, mode) => {
    let show = this.state.showDescNotes, e_description = this.state.e_description;
    let pDesc = $("#txt_desc1").val();
    show = show ? false : true;
    this.setState(
      {
        showDescNotes: show,
        e_description: pDesc
      }
    );
  }
  showAddEditDesc = (row, mode) => {
    let currTaskId, parentDesc = "", parentTaskId = row.parent_task_display_id;
    let taskid = (mode==0)?"parent_task_display_id": mode==2?"task_display_id":"";
    let allItems = this.state.teamSuccessFactorList;
    if (mode == 1) {
      parentDesc = row.custom_task_description ? row.custom_task_description : mode == 2?this.getLabel("LBL" + row[taskid]) :this.getLabel("LBL" + row[taskid] + "DSCR");
    }else if(mode == 3){
      parentDesc = row.custom_task_description;
    } else {
      allItems.map(i => {
        if (i.task_display_id == row.parent_task_display_id) {
          parentDesc = i.custom_task_description?i.custom_task_description:this.getLabel("LBL" + row.parent_task_display_id + "DSCR");
          currTaskId = i.task_id;
        }
      });
    }
    parentDescription = parentDesc;
    this.setState({
      e_description: parentDesc,
      currentRow: row,
      descMode: mode,
      currTaskId: currTaskId,
      showDescPopUp: true,
    });
  }
  showMilestonePop = () => { 
    let {teamSuccessFactorList,subTasks,milestones} = this.state;
    let mCount,mMin,mMax,currMilestone,totMin,totMax;
    mCount = milestones.length;
    currMilestone = milestones[mCount-1]; 
    mMin = currMilestone.recommended_start_days;
    totMin = teamSuccessFactorList[0].recommended_start_days;
    totMax = teamSuccessFactorList[teamSuccessFactorList.length-1].recommended_start_days;
    this.setState({
      showEditMile:false,
      showMilestone : true,
    //  milestones : milestones,
      minValue : mMin,
      totMin:totMin,
      totMax:totMax,
      selectMile:milestones[0],
      selectRange:subTasks[0],
    });
  }
  closeMilestone = () => {
    $("#mileName").val("");
    this.setState({
      showMilestone: false,
      selectMile:"",
      selectRange:"", 
      errorMileName:false,
      errorDuration:false,
      errorMile:false,
    });
  }
  showGantChartPopup=()=>{
    let mainTasks = [], allTasks = [], allItems = this.state;
    //let mode = items == 1 ? "add" : "edit";
    let cnt = 0, taskDetails = [];
    allTasks = allItems.teamSuccessFactorList;
    allTasks.map((item) => {
      if (item.task_type == 0) {
        item.task_name = item.custom_task_name ? item.custom_task_name : this.getLabel(item.task_name);
        if (cnt == 0) mainTasks.push(item);
        if (mainTasks.findIndex(items => items.task_name != item.task_name) > -1) {
          mainTasks.push(item);
        }
        cnt++;
      }
      return null;
    });
    taskDetails["all_tasks"] = allTasks;
    //taskDetails["row"] = (items);
    taskDetails["team_language"] = allItems.teamTemplate;
    taskDetails["main_tasks"] = mainTasks;
    taskDetails["steercom_roles"] = allItems.steercom_roles;
    taskDetails["success_factors"] = allItems.successFactorsList;
    taskDetails["task_type"] = allItems.taskTypeArr;
    taskDetails["task_category"] = allItems.taskCategoryArr;

    this.setState({
      showGantPopup : true, 
      taskDetails:taskDetails
    });
  } 
  closeGantPopup =()=> {
   //this.getTeamListCallback(); 
    this.setState({
      showGantPopup : false, 
    })
  }
  closePopUp = (labelname, b) => {   
    if (typeof labelname  == "string") { 
      this.getTeamSFList();
      this.showAlert("success", this.props.labels.labels[labelname], 3000);
    }
    this.setState({
      showPopUp: false,
    });
  }
  closeDescPopUp = (labelname, b) => {
    this.setState({
      showDescPopUp: false,
    });
  }
  openNewTemp =()=> {
    //this.getTeamListCallback(); 
     this.setState({ 
      showNewTemp : true, 
      errorTempName:false
     })
   } 
  closeNewTemp =()=> {
     $('.modal').hide();
     this.setState({
       showNewTemp : false, 
       show:false
     })
   } 
  createNewTemplate= async()=>{
    let {defaultTemplate,teamTemplate,steercomSettings,showNewTemp} = this.state;
    let loading=false, showNewTemps=true, temps = steercomSettings.templates;
    let isTempExist=false,templateName = $("#templateName").val();
    if(this.checkTemplates(templateName)){  
      let labelname, template_details={}; 
      temps.map(item=>{
        if(item.template_name == templateName){
          labelname = this.props.labels.labels.LBLWARNTEMPEXIST ;
          this.showAlert("warning", labelname, 3000);  
          isTempExist=true;
        }
        return null;
      });
      if(isTempExist){
        showNewTemps=true;
        return false;
      }
      labelname = this.props.labels.labels.LBLCREATETEMPSUCC ;
      template_details.template_name = templateName;
      template_details.parent_template_id = defaultTemplate.template_id;// teamTemplate.template_id;
      let res = await SteercomServices.createNewTemplate(template_details);  
      showNewTemps=false;
      if (res.status == "SUCCESS") { 
        loading =true;
        this.showAlert("success", labelname, 2000); 

        setTimeout(() => {
          this.getSteercomSettings();
        }, 100); 
      } else { 
        //this.showAlert("warning", this.props.labels.labels.LBLTASKEXISTS, 3000);
      }
    }
    this.setState({
      showWeightage:false,
      showNewTemp : showNewTemps, 
      loading:loading
    })
  }
  checkTemplates=(tempDetails)=>{
    let retval=true,msgTempName="", errorTempName=false, errorDefLang=false, templateName,showNewTemp=false;
    let {LBLREQUIRED, LBLTEMPNAMEEXCEED} = this.props.labels.labels;  
    templateName = $("#templateName").val();
    if($.trim(templateName)==""){
      errorTempName=true;
      showNewTemp=true;
      retval=false;
      msgTempName =  LBLREQUIRED?LBLREQUIRED:"Required";
    }else if($.trim(templateName).length>100){
      errorTempName=true;
      showNewTemp=true;
      retval=false;
      msgTempName=  LBLTEMPNAMEEXCEED ?LBLTEMPNAMEEXCEED : "Template name should not exceed the 100 characters";
    } 
    this.setState({
      errorTempName:errorTempName,
      msgTempName:msgTempName
    });
    //,1000);
   
    return retval;
  }
  checkMileStones = (milesData) =>{
    let errorMile = false,errorMileName=false,errorDuration=false, checkVal=true;
    if(milesData.callType == "edit" && milesData.task_id == "0"){
      checkVal=false;
      errorMile = true;
    }
    if($.trim(milesData.custom_task_name)=="")
    {
      checkVal=false;
      errorMileName = true;
    }if($.isNumeric(milesData.recommended_start_days)==false)
    {
      checkVal=false;
      errorDuration = true;
    }
    if(!checkVal){
      milesData.errorMileName=errorMileName;
      milesData.errorDuration=errorDuration;
      milesData.errorMile=errorMile;
    }
    return checkVal;
  }
  saveMileStones = (item) => { 
    let milesData = {};
    let mileName, min, max, mileDuration;
    mileName = $("#mileName").val();
    min = $("#mileDuration1").val();
    max = $("#mileDuration2").val();
    mileDuration = max;
    milesData = {
      task_type: 2,
      success_factor_id:"Y1SF",
      parent_task_display_id:"Y1SF",
      custom_task_name: mileName ? mileName : "",
      recommended_duration: mileDuration ? mileDuration : "",
      is_default: 0,
      // template_id: 1000,
      // template_name: "Template1",
      language_code: this.state.teamTemplate.id,
      template_id: this.state.teamTemplate.template_id,
      template_name: this.state.teamTemplate.template_name,
      version: "1.0",   
      sequece_order: 111,
      task_name: mileName ? mileName : "",
      task_description: mileName ? mileName : "",
      task_type_base:  "",
      category:  "",
      recommended_start_days: mileDuration ? mileDuration : "",
      weightage:  "",
      created_by: this.props.userId,
      updated_by: this.props.userId,
      del_flag: 0,
      references:   "",
      custom_references:  "",
      default_role_id:  "",
      directchild: 1 ,
      callType:""
    }
    
    let {selectRange,selectMile,showEditMile} = this.state ;
    let callType = showEditMile?"edit":"add"; 
    if(callType=="edit"){ 
      milesData = selectMile;
      milesData.callType = callType;
      milesData.custom_task_name = mileName;
      milesData.task_name = mileName;
      milesData.recommended_start_days = selectRange.recommended_start_days;
    }
    if (this.checkMileStones(milesData)) {
       this.saveMileStonesCallBack(milesData,callType);
    }else{
      this.setState({
        errorMile : milesData.errorMile,
        errorMileName:milesData.errorMileName,
        errorDuration:milesData.errorDuration
      });

    } 
  }
  saveMileStonesCallBack = async (milesData,callType) => {  
    delete milesData.company_id;
    let labelname="", res = await SteercomServices.saveSuccessFactorTask(milesData,callType); 
    labelname = this.state.showEditMile ? this.props.labels.labels.LBLMILEUPDSUCC:this.props.labels.labels.LBLMILESUCC
    if (res.status == "SUCCESS") {
      this.closeMilestone();
      this.showAlert("success", labelname, 3000);
      this.getTeamSFList();
    } else {
      //alert(1)
      //this.showAlert("warning", this.props.labels.labels.LBLTASKEXISTS, 3000);
    }
    this.setState({
      showSave: false
    })
  }
  getParentItems = (row) => {
    let items;
    let mainTasksList = this.state.mainTasksList; 
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) { 
        items = i;
      }
      return null;
    });
    return items;
  }
  getParentName = (row) => {
    let parentName = "";
    let mainTasksList = this.state.mainTasksList; 
    mainTasksList.map(i => {
      if (i.task_display_id == row.parent_task_display_id) {
        parentName = i.custom_task_name?i.custom_task_name:i.task_name;
      }
      return null;
    });
    return parentName;
  }
  getFactorName = (row) => {
    let factorName = ""; 
    let SFTITLE = row.success_factor_id.replace("Y1","LBL") + "TITLE";
    factorName = this.getLabel(SFTITLE);
    return factorName;
  }
  addTaskDescription = (e, row) => {
    let currItem = [];
    let { teamSuccessFactorList, e_description, currentRow, currTaskId, descMode } = this.state;
    if (currentRow) {
      if (descMode == 1) {
        currentRow.custom_task_description = e_description;
      } else if (descMode == 3) {
        currentRow.custom_task_description = e_description;
      }else {
        currItem = teamSuccessFactorList.filter(i => i.task_id == currTaskId);
        currItem[0].custom_task_description = e_description;
        currentRow = currItem[0];
      } 
      this.updateTaskDescription(currentRow);
    }
  }
  updateTaskDescription=async(currentRow)=>{
    let result = await SteercomServices.updateTaskDescription(currentRow); 
    if (result.status == "SUCCESS") { 
      this.showAlert("success", this.props.labels.labels.LBLUPDTSUCCESS, 3000); 
    } 
    this.closeDescPopUp();
  }
  showAddTask = (item) => {
    let { SuccessFactorListData, showSave, editModeFlag, teamSuccessFactorList, SuccessFactorListItems, editingTeamSF } = this.state;
    editingTeamSF = [];
    let len_items = teamSuccessFactorList.length;
    len_items = len_items > 0 ? len_items - 1 : len_items;
    let taskdisplayid = "", arrtaskid, taskid, parentId = "", curr_parent = item.currentTarget.id;
    let parent_displayid = '', directchild = 0, cnt_add = 1, cnt_subtasks = 1;
    teamSuccessFactorList.map((items, idx) => {
      if (items.team_id == "New_Task" || items.team_id == "New_Main_Task" || items.isNewAssigned == 1) {
        cnt_add++;
      }
      if (items.task_type == 1 && items.parent_task_display_id == curr_parent && items.team_id != "Add_Task") {
        cnt_subtasks++;
      }
    });
    g_sfchange = true;
    if (cnt_subtasks > 20) {
      this.showAlert("warning", this.props.labels.labels.LBLMAXSUBTASK, 5000);
      return false;
    }
    if (cnt_add > 1 || showSave) {
      this.showAlert("warning", this.props.labels.labels.LBLADDUNSAVEDATA, 5000);
      return false;
    }
    teamSuccessFactorList.map((items, idx) => {
      if (items.task_type == 1 && items.parent_task_display_id == curr_parent && items.team_id == "Add_Task") {

        if (teamSuccessFactorList[idx - 1].task_type == 1) {
          items.team_id = "New_Task";
          arrtaskid = teamSuccessFactorList[idx - 1].task_display_id.split("-");
          taskid = parseInt(arrtaskid[arrtaskid.length - 1]) + 1;
          if (arrtaskid.includes("C")) {
            items.task_display_id = arrtaskid[0] + "-C-" + taskid;
            taskdisplayid = arrtaskid[0] + "-C-" + taskid;
          } else {
            items.task_display_id = arrtaskid[0] + "-C-" + taskid;
            taskdisplayid = arrtaskid[0] + "-C-" + taskid;
          }
          parent_displayid = items.parent_task_display_id
        } else {
          taskid = 1;
          items.team_id = "New_Task";
          arrtaskid = teamSuccessFactorList[idx - 1].task_display_id.split("-");
          items.task_display_id = arrtaskid[0] + "-C-" + taskid;
          taskdisplayid = arrtaskid[0] + "-C-" + taskid;
          items.expandFlag = 1;
          directchild = 1;
        }
        items.success_factor_id = teamSuccessFactorList[0].success_factor_id;
      }
      parentId = items.parent_task_display_id;
      return null;
    });
    let sfid, tasktype = 0;
    editingTeamSF["M_" + taskdisplayid] = [];
    editingTeamSF["M_" + taskdisplayid].task_display_id = taskdisplayid;
    editingTeamSF["M_" + taskdisplayid].parent_task_display_id = parent_displayid;
    editingTeamSF["M_" + taskdisplayid].directchild = directchild;

    let cparentId = parentId;

    let showAdd = [{
      addtask1: false,
      tasktype: 0
    }]
    this.setState({
      SuccessFactorListData: SuccessFactorListData,
      editingTeamSF,
      tasknamefocus: false,
      descriptionfocus: false,
      weightfocus: false,
      prefocus: false,
      deadlinefocus: false,
      referencefocus: false,
      editModeFlag: 1
    })

  }
  handleRowHighlight = (row, item)=>{
    $(item.currentTarget).parent().parent().find(".rdt_TableCell")
    .css({ "box-shadow":"#def8ff 20px 20px 50px 10px inset"});
  }
  handleRowHighlightRemove = (row, item)=>{
    $(item.currentTarget).parent().parent().find(".rdt_TableCell").css("box-shadow", "");
  }
  hideAddTask = (row, item) => {
    let { SuccessFactorListData, teamSuccessFactorList, SuccessFactorListItems } = this.state;
    let len_items = teamSuccessFactorList.length;
    len_items = len_items > 0 ? len_items - 1 : len_items;
    let parentId = "", curr_parent = row.parent_task_display_id;
    teamSuccessFactorList.map((items, idx) => {
      if (items.parent_task_display_id == curr_parent && items.team_id == "New_Task") {
        items.team_id = "Add_Task";
      }
      parentId = items.parent_task_display_id;
      if (items.parent_task_display_id == curr_parent && items.team_id == "New_Main_Task") {
        items.team_id = "Add_Main_Task";
      }
      if (items.parent_task_display_id == curr_parent) {
        items.isNewAssigned = false;
      }
    });
    let showAdd = [{
      addtask1: false
    }]
    this.setState({
      SuccessFactorListData: SuccessFactorListData,
      editModeFlag: 0,
    })
  }
  showAddMainTask = (item) => {
    let { editModeFlag, editingTeamSF, SuccessFactorListData, teamSuccessFactorList, SuccessFactorListItems } = this.state;

    let taskdisplayid = "", lastparent, len_items = teamSuccessFactorList.length;
    len_items = len_items > 0 ? len_items - 1 : len_items;
    let splitter = "M", arrtaskid, taskid, parentId = "", curr_parent = item.currentTarget.id;
    let cnt_maintasks = 1, cnt_add = 1, cnt_limit = 4;
    teamSuccessFactorList.map((items, idx) => {
      if (items.team_id == "New_Task" || items.team_id == "New_Main_Task" || items.isNewAssigned == 1) {
        cnt_add++;
      }
      if (items.task_type == 0 && items.team_id != "Add_Main_Task") {
        cnt_maintasks++;
      }
      return null;
    });
    if (cnt_maintasks > 8) {
      this.showAlert("warning", this.props.labels.labels.LBLMAXMAINTASK, 5000);
      return false;
    }
    if (cnt_add > 1) {
      this.showAlert("warning", this.props.labels.labels.LBLADDUNSAVEDATA, 5000);
      return false;
    }
    teamSuccessFactorList.map((items, idx) => {
      if (items.task_type == 0 && items.team_id == "Add_Main_Task") {
        items.team_id = "New_Main_Task";
        if (idx > 0) {
          if (teamSuccessFactorList[idx - 1].parent_task_display_id.indexOf("MC") > -1) {
            splitter = "MC"
          }
          arrtaskid = teamSuccessFactorList[idx - 1].parent_task_display_id.split(splitter);
          taskid = parseInt(arrtaskid[arrtaskid.length - 1]) + 1;
          items.task_display_id = arrtaskid[0] + "MC" + taskid;
          items.parent_task_display_id = arrtaskid[0] + "MC" + taskid;
          taskdisplayid = arrtaskid[0] + "MC" + taskid;
          items.success_factor_id = teamSuccessFactorList[0].success_factor_id;
        } else {
          let sfid = this.state.successFactorData.id;
          taskdisplayid = sfid + "MC1";
          items.task_display_id = taskdisplayid;
          items.parent_task_display_id = taskdisplayid;
          items.success_factor_id = sfid;
        }
      }
      parentId = items.parent_task_display_id;
      return null;
    });
    // let tasktype = 0;
    editingTeamSF["M_" + taskdisplayid] = [];
    editingTeamSF["M_" + taskdisplayid].task_display_id = taskdisplayid;
    editingTeamSF["M_" + taskdisplayid]["auto_focus"] = "task_name";
    // let showAdd = [{
    //   addtask1: false,
    //   tasktype: 0
    // }]
    this.setState({
      SuccessFactorListData: SuccessFactorListData,
      tasknamefocus: false,
      descriptionfocus: false,
      weightfocus: false,
      prefocus: false,
      deadlinefocus: false,
      referencefocus: false,
      editModeFlag: 1
    })
  }
  //showEditParent
  handleParentTaskNameFocus = (row, event) => {
    let editingTeamSF = this.state.editingTeamSF;
    if (editingTeamSF["M_" + row.task_display_id]["auto_focus"] != "parent_task_name") {
      editingTeamSF["M_" + row.task_display_id]["auto_focus"] = "parent_task_name";
      this.setState({
        editingTeamSF: editingTeamSF,
      });
    }
  }
  handleTaskNameFocus = (row, event) => {
    let editingTeamSF = this.state.editingTeamSF;
    if (editingTeamSF["M_" + row.task_display_id]["auto_focus"] != "task_name") {
      editingTeamSF["M_" + row.task_display_id]["auto_focus"] = "task_name";
      this.setState({
        editingTeamSF: editingTeamSF,
      });
    }
  }
  handleTaskName1Focus = (row, event) => {
    let editingTeamSF = this.state.editingTeamSF;
    if (editingTeamSF["M_" + row.task_display_id]["auto_focus"] != "parent_task_name") {
      editingTeamSF["M_" + row.task_display_id]["auto_focus"] = "parent_task_name";
      this.setState({
        editingTeamSF: editingTeamSF,
      });
    }
  }
  onfocus2 = (item, event) => {
    // sets cursor position at end of MaskedTextBox
    let editingTeamSF = this.state.editingTeamSF;
    event.target.selectionStart = event.target.selectionEnd =
      editingTeamSF["M_" + item.task_display_id]["focus_position"] ||
      event.target.value.length;
  };

  handleResourceFocus = (row, event) => {
    let editingTeamSF = this.state.editingTeamSF;
    if (editingTeamSF["M_" + row.task_display_id]["auto_focus"] != "references") {
      editingTeamSF["M_" + row.task_display_id]["auto_focus"] = "references";
      this.setState({
        editingTeamSF: editingTeamSF,
      });
    }
  }
  handleParentTaskName = (item, event, mode) => {
    let taskname = event.currentTarget.value;
    item.task_name = taskname;
    let { editingTeamSF } = this.state;

    if (mode == 1) {
      editingTeamSF["M_" + item.task_display_id].team_id = "New_Task";
    } else if (mode == 2) {
      editingTeamSF["M_" + item.task_display_id].team_id = "New_Main_Task";
    }
    //editingTeamSF["M_" + item.task_display_id].task_name = taskname; 
    let locval = event.target.value;
    editingTeamSF["M_" + item.task_display_id]["task_name"] = locval;
    item.task_name = locval;
    // editingTeamSF["M_" + item.task_display_id]["task_name"] =locval;
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "task_name";
    editingTeamSF["M_" + item.task_display_id]["focus_position"] = event.target.selectionStart;

    this.setState(
      {
        editingTeamSF,
      },
      event.target.focus()
    );
  }
  handleTaskName = (item, event, mode) => {
    let taskname = event.currentTarget.value;
    item.task_name = taskname;
    let { editingTeamSF } = this.state;

    if (mode == 1) {
      editingTeamSF["M_" + item.task_display_id].team_id = "New_Task";
    } else if (mode == 2) {
      editingTeamSF["M_" + item.task_display_id].team_id = "New_Main_Task";
    }
    //editingTeamSF["M_" + item.task_display_id].task_name = taskname; 
    let locval = event.target.value;
    editingTeamSF["M_" + item.task_display_id]["task_name"] = locval;
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "task_name";
    editingTeamSF["M_" + item.task_display_id]["focus_position"] = event.target.selectionStart;

    this.setState(
      {
        editingTeamSF,
      },
      event.target.focus()
    );
  }
  handleTaskName1 = (item, event, mode) => {
    let  taskname = event.currentTarget.value;
    item.parent_task_name = taskname;
    let { editingTeamSF } = this.state;
    //editingTeamSF["M_" + item.task_display_id].task_name = taskname; 
    let locval = event.target.value;
    editingTeamSF["M_" + item.task_display_id]["parent_task_name"] = locval;
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "parent_task_name";
    editingTeamSF["M_" + item.task_display_id]["focus_position"] = event.target.selectionStart;

    this.setState(
      {
        editingTeamSF,
      },
      event.target.focus()
    );
  }
  handleTaskDescription = (item, evnt) => {
    let description = evnt.currentTarget.value;
    item.task_description = description;
    let { editingTeamSF } = this.state;
    editingTeamSF["M_" + item.task_display_id].task_description = description;
    editingTeamSF["M_" + item.task_display_id].custom_task_description = description;
    this.setState({
      editingTeamSF,
    });
  }
  handleWeightage = (item, event) => {
    let currweight, currval = event.target.value;
    let { cntWeight, editingTeamSF, inc_weight } = this.state;
    if (!currval.match(/^(\d|-)+$/)) {
      currval = currval.replace(/[^0-9-]/g, "");
    } else if (currval < 1 || currval.includes("-") || $.trim(currval) == "") {
      currval = 0;
    } else if (currval > 100) {
      currval = 100;
    }
    editingTeamSF["M_" + item.task_display_id].weightage = currval;
    currval = parseInt(currval);
    currval = Number.isInteger(currval) ? currval : 0;
    currweight = item.weightage ? item.weightage : 0;
    inc_weight = cntWeight + (currval - currweight);
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "weightage";
    this.setState({
      editingTeamSF,
      inc_weight,
    });
  }
  handleChange = (xVar, item, id, event, values) => {
    let editingTeamSF = this.state.editingTeamSF;
    editingTeamSF["M_" + item.task_display_id][xVar] = values[id];
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = xVar;
    this.setState({
      editingTeamSF,
    });
  };
  handleTaskType = (item, evnt, status) => {
    let val = status.id;//evnt.currentTarget.value;
    // let statusl = status;
    item.task_type_base = val;
    let {  editingTeamSF } = this.state;
    editingTeamSF["M_" + item.task_display_id].task_type_base = val;
    this.setState({
      editingTeamSF,
      item: item,
      tasktype:val
    })
  }
  handleCategoryType = (item, evnt, status) => {
    let val = status.id;//evnt.currentTarget.value;
    // let statusl = status;
    item.task_type_base = val;
    let {  editingTeamSF } = this.state;
    editingTeamSF["M_" + item.task_display_id].task_type_base = val;
    this.setState({
      editingTeamSF,
      item: item,
    })
  }
  handleRoleChange = (xVar, item, id, event, values) => {
    let { editingTeamSF } = this.state;
    editingTeamSF["M_" + item.task_display_id]["default_role_id"] = values;

    this.setState({
      editingTeamSF,
      selectedTask: values
    });
  }
  handleDeadline = (item, evnt) => {
    let currval = evnt.currentTarget.value;
    let { editingTeamSF } = this.state;
    if (!currval.match(/^(\d|-)+$/)) {
      currval = currval.replace(/[^0-9-]/g, "");
    } else if (currval <= 0 || currval.includes("-")) {
      currval = 0;
    } else if (currval > 365) {
      currval = 180;
    }
    editingTeamSF["M_" + item.task_display_id].recommended_start_days = currval;
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "deadline";
    this.setState({
      editingTeamSF,
    })
  }

  handleResource = (item, evnt) => {
    // var regex = new RegExp(/^([a-z]:((\\|\/|\\\\|\/\/))|(\\\\|\/\/))[^<>:"|?*]+/i);;
    let curr_resource = evnt.currentTarget.value;
    let { editingTeamSF } = this.state;
    item.custom_references = curr_resource;
    editingTeamSF["M_" + item.task_display_id].custom_references = curr_resource;
    // let locval = evnt.target.value;
    editingTeamSF["M_" + item.task_display_id]["auto_focus"] = "references";
    editingTeamSF["M_" + item.task_display_id]["focus_position"] = evnt.target.selectionStart;
    this.setState(
      {
        editingTeamSF,
      }
      ,
      evnt.target.focus()
    );
  }
  openFilterModal = () => {
    let state = this.state.showNode;

    if (state) {
      $("#filterModal").show();
      $(".bounceInDown").show();
      $(".modal-backdrop").css({ position: "fixed" });
      $(".modal-backdrop.show").css({ opacity: ".5" });
      this.setState({ showNode: false, visible: "none" });
    } else {
      this.setState({ showFilter: true, showNode: false, visible: "none" });
    }
    //let val = this.state.allFilterValues;
  };
  closeFilterModal = () => {
    $(".modal-backdrop").css({ position: "relative" });
    this.setState({
      showFilter: false,
      initialValues: null,
    });
  };
  showDescription = (row,mode,eve) => {  
    let desc=row.custom_task_description;
    let name = row.custom_task_name;
    let tid=row.task_id;
    if(mode==1)
    {
      let items = this.getParentItems(row);
      name = items.custom_task_name;
      desc = items.custom_task_description;
    } 
    this.setState({
      showDescModal: true,
      descriptionData: desc,
      nameData: name,
      taskid: tid,
      titleMode:mode
    });
  };
  closeDescription = () => {
    this.setState({
      showDescModal: false,
      descriptionData: "",
      nameData: "",
      taskid: "",
    });
  };
  showEditMilestone = () => {
    let emode = this.state.showEditMile?false:true
    let errorMile = emode?true:false;
    let {subTasks,milestones} = this.state;
    $("#mileName").val(""); 
    this.setState({
      errorDuration:false, 
      errorMileName:false, 
      errorMile:errorMile,
      showEditMile: emode,
      selectMile:milestones[0],
      selectRange:subTasks[0], 
    })
  }
  onExport = (filetype) => {
    let { reportname, teamSuccessFactorList, showWeightage } = this.state;
    let loclistData = [];
    let allLabels = this.props.labels.labels;
    teamSuccessFactorList = this.getExportingData(teamSuccessFactorList)
    let listData = teamSuccessFactorList;
    let {LBLTASK,LBLSF, LBLTASKDESC,LBLCATEGORY,LBLDEADLINES,LBLMILESTONES} = this.props.labels.labels;
    for (var i = 0, len = listData.length; i < len; i++) {
      let loclistitems = {};
      if (!showWeightage) {
        loclistitems[LBLSF] = this.getFactorName(listData[i]);
      }
      loclistitems[LBLTASK] = listData[i].custom_task_name ? listData[i].custom_task_name : allLabels[listData[i].task_name];
      loclistitems[LBLTASKDESC] = listData[i]["custom_task_description"] ? listData[i]["custom_task_description"] : allLabels[listData[i].task_description];
      if (!showWeightage) {
        loclistitems[LBLCATEGORY] = listData[i].category == 1 ? allLabels["LBLMANDATORY"] : allLabels["LBLBESTPRACTICE"];
      }
      loclistitems[LBLDEADLINES] = listData[i].recommended_start_days;
      if (listData[i].task_type == 2) { 
        loclistitems["parent_task_name"] = "Milestone";
        loclistitems[LBLSF] = LBLMILESTONES; 
      }

      loclistData.push(loclistitems)
    }
    let newval, upperval, arr, arrfieldName;
    $("#loadingexport").show();
    loclistData.map((itm) => {
      arrfieldName = Object.keys(itm);
      arrfieldName.forEach((obj) => {
        upperval = obj.toString().toUpperCase();
        arr = upperval.split("_");
        if (arr.length > 1) {
          newval = arr.join(" ");
          itm[newval] = itm[obj];
          delete itm[obj];
        } else {
          newval = upperval;
          itm[newval] = itm[obj];
          delete itm[obj];
        }

      });
      return true;
    });
    if (loclistData.length == 0) return null;
    if (filetype.toLowerCase() == "csv") {
      this.exportCSV(loclistData, reportname);
    } else if (filetype.toLowerCase() == "excel") {
      this.exportToExcel(loclistData, reportname);
    }
    loclistData = listData;
  };
  convertArrayOfObjectsToCSV = (args) => {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    let result_key = "";
    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        if (key.toLowerCase().indexOf("summary") > 0) {
          result_key = item[key].toString().replace(/\n/g, "");
        } else {
          if (typeof item[key] == "undefined") item[key] = "";
          result_key = item[key];
        }
        result_key = ((result_key) && isNaN(result_key)) ? result_key.replace(/[,]/g, '-').replace(/\n/g, " ") : result_key;
        result_key = result_key == null ? "" : result_key;
        result += result_key;
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };
  exportCSV = (listdata, reportname) => {
    var data, filename, link;
    var stockData = [];
    var objHeader = {};

    let lheader,
      xfields = listdata;
    stockData = xfields;
    var csv = this.convertArrayOfObjectsToCSV({
      data: stockData,
    });
    if (csv == null) return;

    filename = reportname + ".csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }

    data = encodeURI(csv);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
    $("#loadingexport").hide();
  };
  exportToExcel = (data, sCallBack, fCallBack) => {
    let fileExtension = ".xlsx";
    let fileName = "Task_Configuration";
    let width, headers = [];
    const wb = new Excel.Workbook();
    let ws = wb.addWorksheet('Tasks');
    const header_names = Object.keys(data[0]);
    header_names.map((item, x) => {
      width = (x < 3) ? 40 : x == 5 ? 28 : 18;
      headers.push({
        header: item, key: ("row_" + x + 1), width: width
      });
     
    });
    let cols = [];
    ws.columns = headers;
    data.map((items, idx) => {
      cols = [];
      header_names.map(x => {
        return cols.push(items[x])
      });
      ws.addRow(cols);
      return null;
    })
    ws.getColumn('A').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('B').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('C').style.alignment = { vertical: 'middle', horizontal: 'left' };
    ws.getColumn('D').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getColumn('E').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getColumn('F').style.alignment = { vertical: 'middle', horizontal: 'center' };
    ws.getRow(1).style.alignment = { vertical: 'middle', horizontal: 'center' };

    ws = this.excelFormatting(ws, headers)
    wb.xlsx.writeBuffer()
      .then(buffer => FileSaver.saveAs(new Blob([buffer]), `${fileName + "_" + Date.now()}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
  }
  excelFormatting = (worksheet, xcolumns) => {
    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } }
        };
        if (rowNumber === 1) {
          row.height = 30;
          row.font = { bold: true }
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "538DD5" }
          };
          cell.alignment = {
            vertical: 'middle', horizontal: 'center', wrapText: true
          };
        }
        else {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: '538DD5'
          };
          cell.alignment = {
            vertical: 'middle', horizontal: colNumber < 4 ? 'left' : 'center'
          };
        }
      })
    })
    return worksheet;
  }
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      if (
        row[field] &&
        row[field] !== 1 &&
        row[field] !== 0 &&
        typeof row[field] !== "boolean"
      ) {
        if (field == "recommended_start_days" || field == "weightage") {
          return row[field];
        } else if (field == "task_name") {
          return row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name);
        } else if (field == "default_role_id") {
          return this.getLabel("LBL" + row[field])
        } else if (field == "") {

        } else {
          return row[field].toString().toLowerCase();
        }
      }
      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  getExportingData = (teamSuccessFactorListData) => {
    let teamSFList, teamSFListData = [];
    teamSFList = teamSuccessFactorListData;
    if (!this.state.showWeightage) {
      teamSuccessFactorListData.map((item, idx) => {
        if ((item.task_type == 1 || item.task_type == 2 )&& item.team_id != "Add_Task") {
          teamSFListData.push(item);
        }
        return null;
      });
      
      if (this.state.allFilterValues && this.state.allFilterValues.singleItems.status != "" && this.state.allFilterValues.singleItems.status != undefined ) { 
        let filteredList = [], status = this.state.allFilterValues.singleItems.status;
        filteredList = teamSFListData.filter(item => item.category == status);
        teamSFListData = filteredList;
      }
      teamSFList = teamSFListData;
    }
    return teamSFList;
  }
  getDefRoleName = (xdata) => {
    let roleName = "";//, steercomRoles = this.state.steercomSettings.roles;
    let steercomRoles = this.state.steercomRoles;
    if (xdata && xdata!="NULL") {
      xdata = xdata.toUpperCase();
      roleName = steercomRoles.filter(item => item.steercom_role_id.toUpperCase() == xdata);
      return roleName[0].steercom_role_name;
    } else {
      return "";
    }
  };
 getRoleId = (roleName) => {
    let { steercom_roles } = this.state;
    if (roleName) {
      let xx = steercom_roles.filter(item => item.steercom_role_name == roleName)
      if (xx.length > 0)
        return xx[0].steercom_role_id;
      else {
        return roleName;
      }
    } else {
      return "";
    }
  };
  handleMileChange=(eve, item)=>{ 
    if(item.task_id==0){
      return false;
    }
    let errorMile=false, mileNameCombo = $("#mileNameCombo").val();
    let mileNameTxt = $("#mileNameTxt").val();
    let mileName = $("#mileName");
    $("#mileName").val(item.custom_task_name);
    if(item.task_id==0){ 
      errorMile=true;
      $("#mileName").val(""); 
    } 
    this.setState({
      selectMile:item,
      selectRange:item,
      errorMile:errorMile
    });  
  }
  render() { 
    this.formattingDate = (date) => {
      let dateformat,
        fulldate = "";
      dateformat = this.state.dateformat;
      let dateArray = date.split("/");
      if (dateformat.toString().indexOf("YYYY") > 0)
        dateformat = this.setdateformat();
      if (date) {
        var d = new Date(), month, day, year;
        d = new Date(date);
        day = "" + d.getDate();
        if (d == undefined || isNaN(d.getDate())) {
          d = new Date(dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2]);
        }
        month = "" + (d.getMonth() + 1);
        day = "" + d.getDate();
        year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        if (dateformat == "dd/MM/yyyy") {
          fulldate = [day, month, year].join("/");
        } else if (dateformat == "MM/dd/yyyy") {
          fulldate = [month, day, year].join("/");
        } else if (dateformat == "yyyy/MM/dd") {
          fulldate = [year, month, day].join("/");
        } else {
          fulldate = [day, month, year].join("/");
        }
      }
      return fulldate;
    };
    const {
      LBLUNSAVEDATA, LBLRESETALL, LBLTASKSETUP, LBLADDMILESTONE, LBLMILESTONES,
      LBLREQUIRED,LBLTASK, LBLDESCRIPTION, LBLTASKTYPE, LBLDEADLINE, LBLUPLOADRESOURCE,LBLTEMPDESC,
      LBLADDSUBTASK, LBLMANDATORY, LBLBESTPRACTICE, LBLCATEGORY, LBLMILESTONERANGE,LBLCREATETEMPLATE,LBLTEMPLATENAME,LBLTEMPLATEFROM,
      LBLPREBASEDATE, LBLPOSTBASEDATE, LBLTASKDESC,LBLADD,LBLWEIGTAGE,
 
      LBLACTION, LBLEXPORT, LBLEXPORTTOEXCEL, LBLEXPORTTOCSV, LBLSAVE, LBLMILESTONENAME,
      LBLRESET, LBLEDIT,LBLDELETE, LBLPARENTTASK, LBLADDTASK, LBLCANCEL, 
      LBLREFERENCELNKS, LBLSF, LBLAGREE, LBLNO,LBLGANTTASKVSREC,LBLTEMPLATE,LBLNORECORDS,LBLTASKFILTER
    } = this.props.labels.labels;


    const {
      tempLanguages, hasRights, showEditMile, subTasks,milestones,
      err_task_name, err_task_description,successFactorData,
      err_references,
      err_recommended_start_days, err_task_type,
      teamSuccessFactorList,
      steercomSettings,
      editingTeamSF, selectedTask,successFactorsList,
      showWeightage , taskTypeArr,  msg_confirm,msg_confirm1, taskCategoryArr
    } = this.state;

    let roleidx = 0;
    this.state.steercomSettings.roles && this.state.steercomSettings.roles.map((item, idx) => {
      if (item.id == selectedTask.default_role_id) {
        roleidx = idx;
      }
      return null;
    })

    const filterSuccessFactorsListData = teamSuccessFactorList.filter(
      (item) => {
        if (
          (item.task_type == 1 && item.expandFlag == 1) ||
          item.task_type == 0 || item.task_type == 2
        ) {
          return item;
        }
      }
    );
    let cwidth = ["40px","250px","380px","160px","160px","70px","200px","100px"];
    if(this.props.language_code=="fr-FR"){
      cwidth[3] = "180px";
      cwidth[4] = "220px";
    }
    let teamSuccessFactorColumns = [
      {
        name: "",
        selector: "",
        width: "40px",
        cell: (row) => (
          <>
            <div
              style={{
                display: "inline-block",
                paddingLeft: "0px",
                position: "absolute",
                backgroundColor: "white",
                left: "-5px",
                width: "52px",
                height: "52px",
              }}
            ></div>

            {row.task_type == 4 && row.team_id != "Add_Main_Task" ? (
              <div
                style={{
                  display: "inline-block",
                  paddingLeft: "0px",
                  position: "absolute",
                  border: "0px solid red",
                  top: "4px",
                }}
              >
                <label
                  class={row.expandFlag ? "checkplus-lbl" : "checkplus-lbl"}
                >
                  <input
                    type="checkbox"
                    name={"chkg_" + row.task_display_id}
                    id={"chkg_" + row.task_display_id}
                    data-toggle="modal"
                    checked={row.expandFlag}
                    onClick={this.updateExpandFlag.bind(
                      this,
                      row,
                      row.expandFlag ? 1 : 0
                    )}
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "inline-block",
                    paddingLeft: "0px",
                    position: "absolute",
                    border: "0px solid red",
                    top: "0px",
                    left: "8px",
                  }}
                >

                  <div> { }</div>
                </div>
              </>
            )}
          </>
        ),
      },
      {
        name: LBLTASK,
        fixedHeader: true,
        selector: "name",
        sortable: false,
        width: "300px",
        compact: true,
        cell: (row) => {
          let xEditCheckBox =
            (hasRights) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false;
          let task_name = "";
          task_name = row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name);
          xEditCheckBox = this.state.isEditable?true:false; 
          return (
            <div
              className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {row.task_type == 1 ? (
                <>
                  <div
                    style={{
                      width: "14px",
                      height: "29px",
                      border: "0px solid",
                      display: "block",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "29px",
                        backgroundColor: row.team_id == "Add_Task" || row.team_id == "New_Task" ? "white" : "#80E3BA",
                        border: "0px solid",
                        display: "block",
                      }}
                    ></div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                    }}
                  >

                    {xEditCheckBox == true && row.team_id != "Add_Task" ? (
                      <div
                        style={{
                          display: "inline-block",
                          paddingLeft: "0px",
                          width: "30px",
                        }}
                      >
                        <label
                          class={
                            row.isNewAssigned
                              ? "check-lbl"
                              : "check-lbl check-newassigned"
                          }
                        >
                          <input
                            type="checkbox"
                            name={"chkg_" + row.team_id}
                            id={"chkg_" + row.team_id}
                            data-toggle="modal"
                            checked={row.isNewAssigned}
                            //onClick={this.updateEditFlag.bind(
                            onClick={this.openConfirmDialog3.bind(
                              this, 
                              row,
                              row.isNewAssigned ? 1 : 0
                            )}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                  </div>
                  {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                    <>
                      <div id={"notes_" + row.task_display_id}
                        style={{ display: "flex", width: "100%"}}
                        className={err_task_name ? "required-input-border" : ""}
                        >
                        <input
                          onChange={(e) =>
                            this.handleTaskName(editingTeamSF["M_" + row.task_display_id], e)
                          }
                          onClick={this.handleTaskNameFocus.bind(this, row)}
                          className="cls_md_text"
                          value={
                            this.getLabel(editingTeamSF["M_" + row.task_display_id].task_name)
                          }
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "task_name"
                              ? true
                              : false
                          }
                          onFocus={(e) => this.onfocus2(row, e)}
                        />

                      </div>
                      <span
                        className="required-field" style={{ top: "26px", zIndex: "1" }}
                        id={"span_completion_percentage_" + row.task_display_id}
                      >
                        {LBLREQUIRED}
                      </span>
                    </>
                  ) :
                    <div
                      style={{
                        width: "calc(100% - 14px)",
                        border: "0px solid",
                        display: "flex",
                      }}
                    >

                      <>
                        <div
                          style={{
                            display: "inline-block",
                            position: "relative",
                            border: "0px solid red",
                            width: xEditCheckBox == true ? "calc(100% - 12px)" : "calc(100% - 12px)",
                            textAlign: "left",
                            overflow: "hidden",
                          }}
                        >
                          {row.team_id == "Add_Task" ?
                            <div>
                              <span
                                style={{ display: "" }}
                                title={LBLADDSUBTASK} id={row.parent_task_display_id}
                                class="cls_add_new_member"
                                onClick={this.showAddTask.bind(row)}
                              > {LBLADDSUBTASK}
                              </span>
                            </div> :
                            row.team_id == "New_Task" ?
                              <div id={"notes_" + row.task_display_id}
                                style={{ display: "flex", width: "100%"}} className={err_task_name ? "required-input-border" : ""}>
                                <input
                                  onChange={(e) =>
                                    this.handleTaskName(editingTeamSF["M_" + row.task_display_id], e)
                                  }
                                  onClick={this.handleTaskNameFocus.bind(this, row)}
                                  className="cls_md_text"
                                  value={
                                    this.getLabel(editingTeamSF["M_" + row.task_display_id].task_name)
                                  }
                                  autoFocus={
                                    editingTeamSF["M_" + row.task_display_id][
                                      "auto_focus"
                                    ] == "task_name"
                                      ? true
                                      : false
                                  }
                                  onFocus={(e) => this.onfocus2(row, e)}
                                />

                              </div>
                              :
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  height: "24px" /* exactly 2 lines */,
                                  textOverflow: "ellipsis",
                                  fontWeight: "410",
                                  position:"relative",
                                  top:"3px"
                                }}
                                title={this.getLabel(task_name)}
                              >
                                {this.getLabel(task_name).length < 41
                                  ? this.getLabel(task_name)
                                  : this.getLabel(task_name).substring(0, 40) + "..."}

                              </div>
                          }
                        </div>

                      </>
                    </div>}
                </>
              ) : (
                <>
                  {row.team_id == "Add_Main_Task" ? "" :
                    row.team_id == "New_Main_Task" ?
                      <div id={"notes_" + row.task_display_id}
                        style={{ display: "flex", width: "100%",}} className={err_task_name ? "required-input-border" : ""}>
                        <input
                          onChange={(e) =>
                            this.handleTaskName(editingTeamSF["M_" + row.task_display_id], e)
                          }
                          onClick={this.handleTaskNameFocus.bind(this, row)}
                          className="cls_md_text"
                          value={
                            this.getLabel(editingTeamSF["M_" + row.task_display_id].task_name)
                          }
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "task_name"
                              ? true
                              : false
                          }
                          onFocus={(e) => this.onfocus2(row, e)}
                        />

                      </div>
                      :
                      <div
                        className="MAINTASK"
                        style={{
                          width: "100%",
                          border: "0px solid",
                          height: "100%",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "left",
                        }}
                      >
                        <>
                          {!row.isNewAssigned==4 ?
                            <div
                              style={{
                                display: "inline-block",
                                position: "relative",
                                top: "8px",
                                left: "2px",
                                border: "0px solid blue",
                                width: "100%",// "calc(100% - 28px)",
                                textAlign: "left",
                                textOverflow: "ellipsis",
                                fontSize: "14px",
                                fontWeight: "430"
                              }}
                              title={row.custom_task_name ? this.getLabel(row.custom_task_name) : this.getLabel(row.task_name)}
                            >
                              {row.custom_task_name ?
                                this.getLabel(row.custom_task_name).length > 40 ? this.getLabel(row.custom_task_name).substring(0, 40) + "..." : this.getLabel(row.custom_task_name) :
                                this.getLabel(row.task_name).length > 40 ? this.getLabel(row.task_name).substring(0, 40) + "..." : this.getLabel(row.task_name)}
                              </div>
                            : row.isNewAssigned ==1?<><input
                              onChange={(e) =>
                                this.handleParentTaskName(editingTeamSF["M_" + row.task_display_id], e)
                              }
                              onClick={this.handleTaskNameFocus.bind(this, row)}
                              className="cls_md_text"
                              value={
                                this.getLabel(editingTeamSF["M_" + row.task_display_id].task_name)
                              }
                              autoFocus={
                                editingTeamSF["M_" + row.task_display_id][
                                  "auto_focus"
                                ] == "task_name"
                                  ? true
                                  : false
                              }
                              onFocus={(e) => this.onfocus2(row, e)}
                            />

                            </>:""}
                        </>
                      </div>
                  }
                </>
              )}
            </div>
          )
        },
      },
      {
        name: LBLDESCRIPTION,
        selector: "member_enddate",
        width: "250px",
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {row.task_type == 1 ? (
              <>
                {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (

                  <>
                    <div className="cls_act_description_top">
                      <div className="cls_act_description">
                        <input style={{ width: "100%", height: "26px", textOverflow: "ellipsis" }}
                          type="text"
                          name={"chkg_" + row.team_id}
                          id={"chkg_" + row.team_id}
                          data-toggle="modal"
                          value={editingTeamSF["M_" + row.task_display_id].task_description}
                          onClick={this.showNotes.bind(this, row)}
                          onKeyPress={this.showNotes.bind(this, row)}
                        />
                      </div>
                      {editingTeamSF["M_" + row.task_display_id].notes_flag ==
                        true ? (
                        <>
                          <div className="minipop" id={"notes_" + row.task_display_id}>
                            <textarea
                              style={{
                                width: "100%",
                                height: "106px",
                                position: "absolute",
                                zIndex: "2",
                                top: "-4px",
                                left: "0px",
                                overflow: "hidden",
                                lineHeight: "1.2",
                                borderRadius: "4px",
                                border: "1px solid black",
                                overflowY: "scroll"
                              }}
                              onBlur={this.showNotes.bind(this, row)}
                              className="cls_act_inp_description"
                              onChange={this.handleDescription.bind(this, row)}
                              value={
                                editingTeamSF["M_" + row.task_display_id].task_description
                              }
                              autoFocus={
                                editingTeamSF["M_" + row.task_display_id][
                                  "auto_focus"
                                ] == "task_description"
                                  ? true
                                  : false
                              }
                              onFocus={(e) => this.onfocus1(row, e)}
                            ></textarea>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <span
                      className="required-field"
                      id={
                        "span_task_description_" + row.task_display_id
                      }
                    >
                      {LBLREQUIRED}
                    </span>
                  </>
                ) : (
                  <>
                    <div className="cls_act_description">
                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div className="cls_act_description_top">

                            <div className="cls_act_description" style={{ border: err_task_description ? "1px solid red" : "" }}>
                              <input style={{ width: "100%", height: "26px", textOverflow: "ellipsis" }}
                                type="text"
                                name={"chkg_" + row.team_id}
                                id={"chkg_" + row.team_id}
                                data-toggle="modal"
                                value={editingTeamSF["M_" + row.task_display_id].task_description}
                                onClick={this.showNotes.bind(this, row)}
                                onKeyPress={this.showNotes.bind(this, row)}
                              />
                            </div>

                            {editingTeamSF["M_" + row.task_display_id].notes_flag ==
                              true ? (
                              <>
                                <div className="minipop" id={"notes_" + row.task_display_id}>
                                  <textarea
                                    style={{
                                      width: "100%",
                                      height: "106px",
                                      position: "absolute",
                                      zIndex: "2",
                                      top: "-4px",
                                      left: "0px",
                                      overflow: "hidden",
                                      lineHeight: "1.2",
                                      borderRadius: "4px",
                                      border: "1px solid black",
                                      overflowY: "scroll"
                                    }}
                                    onBlur={this.showNotes.bind(this, row)}
                                    className="cls_act_inp_description"
                                    onChange={this.handleDescription.bind(this, row)}
                                    value={
                                      editingTeamSF["M_" + row.task_display_id]
                                        .task_description
                                    }
                                    autoFocus={
                                      editingTeamSF["M_" + row.task_display_id][
                                        "auto_focus"
                                      ] == "task_description"
                                        ? true
                                        : false
                                    }
                                    onFocus={(e) => this.onfocus1(row, e)}
                                  ></textarea>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                          </div>
                          :
                          <span title={row.custom_task_description ? row.custom_task_description : this.getLabel(row.task_description)}>
                            {row.custom_task_description ? row.custom_task_description : this.getLabel(row.task_description).replace(/\n/g, '<br \\>')}
                          </span>
                      }
                    </div>
                  </>
                )}
              </>
            ) : (
              row.team_id == "Add_Main_Task" ? "" :
                (row.team_id == "New_Main_Task" || row.isNewAssigned === 1) ?
                  <div className="cls_act_description_top">
                    <div className="cls_act_description" style={{ border: err_task_description ? "1px solid red" : "" }}>
                      <input style={{ width: "100%", height: "26px", textOverflow: "ellipsis" }}
                        type="text"
                        name={"chkg_" + row.team_id}
                        id={"chkg_" + row.team_id}
                        data-toggle="modal"
                        value={editingTeamSF["M_" + row.task_display_id].task_description}

                        onClick={this.showNotes.bind(this, row)}
                        onKeyPress={this.showNotes.bind(this, row)}
                      />
                    </div>

                    {editingTeamSF["M_" + row.task_display_id].notes_flag ==
                      true ? (
                      <>
                        <div className="minipop cls_act_description_top" id={"notes_" + row.task_display_id}>
                          <textarea
                            style={{
                              width: "100%",
                              height: "106px",
                              position: "absolute",
                              zIndex: "200",
                              top: "-4px",
                              left: "0px",
                              overflowY: "scroll",
                              lineHeight: "1.2",
                              borderRadius: "4px",
                              border: "1px solid black",

                            }}
                            onBlur={this.showNotes.bind(this, row)}
                            className="cls_act_inp_description "
                            onChange={this.handleDescription.bind(this, row)}
                            value={
                              editingTeamSF["M_" + row.task_display_id]
                                .task_description
                            }
                            autoFocus={
                              editingTeamSF["M_" + row.task_display_id][
                                "auto_focus"
                              ] == "task_description"
                                ? true
                                : false
                            }
                            onFocus={(e) => this.onfocus1(row, e)}
                          ></textarea>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                  </div> : ""
            )}
          </div>
        ),
      },
      {
        name: LBLCATEGORY,
        selector: "name",
        //    sortable: true,
        width: cwidth[3],
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" :
              row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
            }}
          >
            {row.task_type == 2 ? "" : (hasRights && row.task_type == 1) ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid red",
                    display: "flex",
                  //  overflowY: "auto",
                  }}
                >
                  {row.isNewAssigned === 1 || row.team_id == "NEW" ? (
                    <>

                      <div className="cls_defaultrole" style={{ border: err_task_type ? "1px solid red" : "" }}>
                      <ThemeProvider theme={theme}>
                        <Autocomplete
                          id={"roles_" + row.team_member_id}
                          disableClearable
                          options={taskCategoryArr}
                          getOptionLabel={(option) => option.name.toString()}
                          getOptionSelected={(option, values) =>
                            option.id === values.id
                          }
                          onChange={this.handleChange.bind(
                            this,
                            "category",
                            row,
                            "id"
                          )}
                          underlineStyle={{ display: "none" }}
                          defaultValue={
                            editingTeamSF["M_" + row.task_display_id] &&
                            taskCategoryArr.find(
                              (v) =>
                                v.id ==
                                editingTeamSF["M_" + row.task_display_id]
                                  .category
                            )
                          }
                          renderInput={(params) => (
                            <TextField id="memberlist" {...params} />
                          )}
                          underline="none"
                        />
                       </ThemeProvider>
                      </div>
                    </>
                  ) : (
                    <>

                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div className="cls_defaultrole" style={{ border: err_task_type ? "1px solid red" : "" }}>
                            <ThemeProvider theme={theme}>
                            <Autocomplete
                              id={"roles_" + row.team_member_id}
                              disableClearable
                              options={taskCategoryArr}
                              getOptionLabel={(option) => option.name.toString()}
                              getOptionSelected={(option, values) =>
                                option.id === values.id
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "category",
                                row,
                                "id"
                              )}
                              underlineStyle={{ display: "none" }}
                              defaultValue={
                                editingTeamSF["M_" + row.task_display_id] &&
                                taskCategoryArr.find(
                                  (v) =>
                                    v.id ==
                                    editingTeamSF["M_" + row.task_display_id]
                                      .category
                                )
                              }
                              renderInput={(params) => (
                                <TextField id="memberlist" {...params} />
                              )}
                              underline="none"
                            />
                            </ThemeProvider>
                          </div>
                          :
                          <div
                            style={{
                              width: "100%",
                              border: "0px solid red",
                              display: "flex",
                              overflowY: "auto",
                              height: "100%",
                              color: row.category == "1" ? "green" : "#be043d",
                              cursor:"default"
                            }}
                          >
                            <div className="grid-ellipsis" title={row.category == "1" ? LBLMANDATORY : LBLBESTPRACTICE}> {row.category == "1" ? LBLMANDATORY : LBLBESTPRACTICE}</div>
                          </div>
                      }
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {
                  <div
                    style={{
                      width: "100%",
                      border: "0px solid red",
                      display: "flex",
                      overflowY: "auto",
                      height: "100%",
                    }}
                  >
                    <div>  </div>
                  </div>
                }
              </>
            )}
          </div>
        ),
      },
      {
        name: LBLTASKTYPE,
        selector: "name",
        //    sortable: true,
        width: cwidth[4],
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {hasRights && row.task_type == 1 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid red",
                    display: "flex",
                  //  overflowY: "auto",
                  }}
                >
                  {row.isNewAssigned === 1 || row.team_id == "NEW" ? (
                    <>

                      <div className="cls_defaultrole" style={{ border: err_task_type ? "1px solid red" : "" }}>
                      <ThemeProvider theme={theme}>
                        <Autocomplete
                          id={"roles_" + row.team_member_id}
                          disableClearable
                          options={taskTypeArr}
                          getOptionLabel={(option) => option.name.toString()}
                          getOptionSelected={(option, values) =>
                            option.id === values.id
                          }
                          onChange={this.handleChange.bind(
                            this,
                            "task_type_base",
                            row,
                            "id"
                          )}
                          underlineStyle={{ display: "none" }}
                          defaultValue={
                            editingTeamSF["M_" + row.task_display_id] &&
                            taskTypeArr.find(
                              (v) =>
                                v.id ==
                                editingTeamSF["M_" + row.task_display_id]
                                  .task_type_base
                            )
                          }
                          renderInput={(params) => (
                            <TextField id="memberlist" {...params} />
                          )}
                          underline="none"
                        />
                        </ThemeProvider>
                      </div>
                    </>
                  ) : (
                    <>

                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div className="cls_defaultrole" style={{ border: err_task_type ? "1px solid red" : "" }}>
                          <ThemeProvider theme={theme}> 
                            <Autocomplete
                              id={"roles_" + row.team_member_id}
                              disableClearable
                              options={taskTypeArr}
                              getOptionLabel={(option) => option.name.toString()}
                              getOptionSelected={(option, values) =>
                                option.id === values.id
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "task_type_base",
                                row,
                                "id"
                              )}
                              underlineStyle={{ display: "none" }}
                              defaultValue={
                                editingTeamSF["M_" + row.task_display_id] &&
                                taskTypeArr.find(
                                  (v) =>
                                    v.id ==
                                    editingTeamSF["M_" + row.task_display_id]
                                      .task_type_base
                                )
                              }
                              renderInput={(params) => (
                                <TextField id="memberlist" {...params} />
                              )}
                              underline="none"
                            />
                          </ThemeProvider>
                          </div>
                          :
                          <div
                            style={{
                              width: "100%",
                              border: "0px solid red",
                              display: "flex",
                              overflowY: "auto",
                              height: "100%",
                              alignItems: "center",
                            }}
                          >
                            <div> {row.recommended_start_days > -1 ? LBLPOSTBASEDATE : LBLPREBASEDATE}</div>
                          </div>
                      }
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {
                  <div
                    style={{
                      width: "100%",
                      border: "0px solid red",
                      display: "flex",
                      overflowY: "auto",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>  </div>
                  </div>
                }
              </>
            )}
          </div>
        ),
      },
      {
        name: LBLDEADLINE,
        selector: "name",
        width: "70px",
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {hasRights && row.task_type == 1 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid red",
                    display: "flex",
                    overflowY: "auto",
                  }}
                >
                  {row.isNewAssigned === 1 || row.team_id == "NEW" ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          border: "0px solid red",
                          display: "flex",
                          overflowY: "auto",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input type="text" style={{}}
                          className="cls_sm_text"
                          onChange={this.handleDeadline.bind(this, row)}
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "deadline"
                              ? true
                              : false
                          }
                          value={editingTeamSF["M_" + row.task_display_id].recommended_start_days}
                        >
                        </input>
                      </div>
                    </>
                  ) : (
                    <>
                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div style={{ border: err_recommended_start_days ? "1px solid red" : "" }}>
                            <input
                              value={editingTeamSF["M_" + row.task_display_id] && editingTeamSF["M_" + row.task_display_id].recommended_start_days}
                              onChange={this.handleDeadline.bind(this, row)}
                              autoFocus={
                                editingTeamSF["M_" + row.task_display_id][
                                  "auto_focus"
                                ] == "deadline"
                                  ? true
                                  : false
                              }
                              className="cls_deadline" type="text"></input>
                          </div>
                          :
                          <div
                            style={{
                              width: "100%",
                              border: "0px solid red",
                              display: "flex",
                              overflowY: "auto",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div>{row.recommended_start_days}</div>
                          </div>
                      }
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {
                  <div
                    style={{
                      width: "100%",
                      border: "0px solid red",
                      display: "flex",
                      overflowY: "auto",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div> </div>
                  </div>
                }
              </>
            )}
          </div>
        ),
      },
      {
        name: LBLUPLOADRESOURCE,
        selector: "name",
        width: "200px",
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {hasRights && row.task_type == 1 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid red",
                    display: "flex",
                    overflowY: "auto",
                  }}
                >

                  {row.isNewAssigned === 1 || row.team_id == "NEW" ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          overflowY: "auto",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input type="text"
                          style={{ border: err_references ? "1px solid red" : "" }}
                          className="cls_md_text"
                          onChange={this.handleResource.bind(this, row)}
                          value={editingTeamSF["M_" + row.task_display_id].custom_references}
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "references"
                              ? true
                              : false
                          }
                          onFocus={(e) => this.onfocus2(row, e)}
                        >
                        </input>
                      </div>
                    </>
                  ) : (
                    <>
                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div>
                            <input type="text"
                              style={{ border: err_references ? "1px solid red" : "" }}
                              className="cls_md_text"
                              onChange={this.handleResource.bind(this, row)}
                              value={editingTeamSF["M_" + row.task_display_id].custom_references}
                              autoFocus={
                                editingTeamSF["M_" + row.task_display_id][
                                  "auto_focus"
                                ] == "references"
                                  ? true
                                  : false
                              }
                              onFocus={(e) => this.onfocus2(row, e)}
                            >
                            </input>
                          </div>
                          :
                          <div
                            style={{
                              width: "100%",
                              border: "0px solid red",
                              display: "flex",
                              overflowY: "auto",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >

                            <div>{row.custom_references}</div>
                          </div>
                      }
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {
                  <div
                    style={{
                      width: "100%",
                      border: "0px solid red",
                      display: "flex",
                      overflowY: "auto",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div> </div>
                  </div>
                }
              </>
            )}
          </div>
        ),
      },

      {
        name: LBLACTION,
        selector: "status",
        //     sortable: true,
        width: "100px",
        compact: true,
        cell: (row) => ( 
           !this.state.isEditable?"": 
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          >
            {(row.task_type == 1 || row.task_type == 0) && (row.isNewAssigned || row.team_id == "New_Task" || row.team_id == "New_Main_Task" || row.task_type == 0 && row.isNewAssigned == true) ? (
              <>
                {" "}
                {hasRights ? (
                  <>
                    <label className="show-sm">{LBLACTION}</label>
                    <div
                      className="sub_container33"
                      style={{ display: "inline-block" }}
                    >
                      <img
                        src="../images/steercom/Checkbox.png"
                        //onClick={this.saveSuccessFactorTemp.bind(this, row)}
                        onClick={this.openConfirmDialog1.bind(this, row, 2)}
                        title={LBLSAVE}
                        className="img_team_cross"
                        alt=""
                      ></img>
                    </div>
                    <div
                      className="sub_container3 cls_team_cross"
                      style={{ display: "inline-block" }}
                    >
                      <i
                        class="fas fa-undo"
                        onClick={(row.team_id == "New_Task" || row.team_id == "New_Main_Task") ? this.hideAddTask.bind(this, row) : this.deleteSuccessFactor.bind(this, row)}
                        title={LBLCANCEL}
                      ></i>
                    </div>

                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {(row.task_type == 1 || row.task_type == 0) && row.team_id != "Add_Task" && row.team_id != "Add_Main_Task" ?
                  <>
                    <div
                      className="sub_container3 cls_team_cross"
                      style={{ display: "inline-block", textAlign: "center", width: "40%" }}
                    >
                      <i
                        class="far fa-trash-alt"
                        onClick={row.team_id == "New_Task" ? this.hideAddTask.bind(this, row) : this.openConfirmDialog1.bind(this, row, 1)} //</div>  this.deleteTask.bind(this, row)
                        title= {LBLDELETE}
                      ></i>
                      {/* <img src="../images/steercom/wrong.png" onClick={this.deleteSuccessFactor.bind(this, row)} title="Cancel Team Member" className="img_team_cross" alt="steer"></img> */}
                    </div>
                    <div
                      className=" cls_team_cross"
                      style={{ display: "inline-block" }}
                    >
                      <i
                        class="fas fa-reply"
                        onClick={this.resetSingleTask.bind(this, row)}
                        title={LBLRESET}
                      ></i>
                    </div></>
                  : ""} </>
            )}
          </div>
        ),
      },
    ];
    
    let teamSuccessFactorColumnsAll = [
      {
        name: "",
        selector: "",
        width: cwidth[0],
      },
      {
        name: LBLSF, //LBLPARENTTASK
        fixedHeader: true,
        selector: "parent_task_display_id",
        //sortable: true,
        width:  cwidth[1],
        compact: true,
        cell: (row) => {
          let xEditCheckBox =
            (hasRights) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false;
          let task_name = "";
          task_name =  this.getFactorName(row)//this.getParentName(row);//this.getLabel("LBL" + row.parent_task_display_id);
          if (row.is_default == 0) {
            task_name = this.getFactorName(row)//this.getParentName(row);
          }
          xEditCheckBox = this.state.isDefaultTemplate?false:true;
          return (
            <div
              className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" :
                row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >  
              {/* {this.state.tasknamefocus?"11":"00"} -- {this.state.descriptionfocus?"11":"00"}  */}
              {row.task_type == 2 ? row.custom_task_name? row.custom_task_name :row.task_name: row.task_type == 1 ? (
                <>
                  <div
                    style={{
                      width: "14px",
                      height: "34px",
                      border: "0px solid",
                      display: "block",
                    }}
                  >
                    <div
                      style={{
                        width: "4px",
                        height: "34px",
                        backgroundColor: row.team_id == "Add_Task" || row.team_id == "New_Task" ? "white" : "#80E3BA",
                        border: "0px solid",
                        display: "block",
                      }}
                    ></div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                    }}
                  >

                    {xEditCheckBox == true && row.team_id != "Add_Task" ? (
                      <div
                        style={{
                          display: "inline-block",
                          paddingLeft: "0px",
                          width: "30px",
                        }}
                      >
                        <label
                          class={
                            row.isNewAssigned
                              ? "check-lbl"
                              : "check-lbl check-newassigned"
                          }
                        >
                          <input
                            type="checkbox"
                            name={"chkg_" + row.team_id}
                            id={"chkg_" + row.team_id}
                            data-toggle="modal"
                            checked={row.isNewAssigned}
                            onClick={this.openConfirmDialog3.bind( 
                              this,
                              row,
                              row.isNewAssigned ? 1 : 0
                            )}
                          />
                          <span class="checkmark" 
                          > 
                          </span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                  </div>
                  {row.isNewAssigned === 4 || row.team_member_id == "NEW" ? (
                    <>
                      <div id={"notes_" + row.task_display_id}
                        style={{ display: "flex", width: "100%"}} className={err_task_name ? "required-input-border" : ""}>
                        <input
                          onChange={(e) =>
                            this.handleTaskName1(editingTeamSF["M_" + row.task_display_id], e)
                          }
                          onClick={this.handleTaskName1Focus.bind(this, row)}
                          className="cls_md_text"
                          value={
                            this.getLabel(editingTeamSF["M_" + row.task_display_id].parent_task_name)
                          }
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "parent_task_name"
                              ? true
                              : false
                          }
                          onFocus={(e) => this.onfocus2(row, e)}
                        />

                      </div>
                      <span
                        className="required-field" style={{ top: "26px", zIndex: "1" }}
                        id={"span_completion_percentage_" + row.task_display_id}
                      >
                        {LBLREQUIRED}
                      </span>
                      <img
                        style={{
                          cursor: "pointer",
                          width: "15px",
                          height: "15px",
                          objectFit: "contain",
                          marginRight: "5px",
                        }}
                        alt=""
                        src="../images/steercom/info.png"
                        onClick={this.showAddEditDesc.bind(
                          this, row, 0
                        )}
                        title={LBLTASKDESC}
                      />
                    </>
                  ) :
                    <div
                      style={{
                        width: "calc(100% - 14px)",
                        border: "0px solid",
                        display: "flex",
                      }}
                    >

                      <>
                        <div
                          style={{
                            display: "inline-block",
                            position: "relative",
                            width: xEditCheckBox == true ? "calc(100% - 12px)" : "calc(100% - 12px)",
                            textAlign: "left",
                          }}
                        >
                          {row.team_id == "Add_Task" ?
                            <div>
                              <span
                                style={{ display: "" }}
                                title={LBLADDSUBTASK} id={row.parent_task_display_id}
                                class="cls_add_new_member"
                                onClick={this.showAddTask.bind(row)}
                              > {LBLADDSUBTASK}
                              </span>
                            </div> :
                            row.team_id == "New_Task" ?
                              <div id={"notes_" + row.task_display_id}
                                style={{ display: "flex", width: "100%"}} className={err_task_name ? "required-input-border" : ""}>
                                <input
                                  onChange={(e) =>
                                    this.handleTaskName(editingTeamSF["M_" + row.task_display_id], e)
                                  }
                                  onClick={this.handleTaskNameFocus.bind(this, row)}
                                  className="cls_md_text"
                                  value={
                                    this.getLabel(editingTeamSF["M_" + row.task_display_id].task_name)
                                  }
                                  autoFocus={
                                    editingTeamSF["M_" + row.task_display_id][
                                      "auto_focus"
                                    ] == "task_name"
                                      ? true
                                      : false
                                  }
                                  onFocus={(e) => this.onfocus2(row, e)}
                                />

                              </div>
                              :
                              <div style={{ display: "inline-flex", width: "100%" }}>
                                <div
                                  style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    whiteSpace: "initial",
                                    height: "24px" /* exactly 2 lines */,
                                    textOverflow: "ellipsis",
                                    fontWeight: "410",
                                    position:"relative",
                                    top:"3px"
                                  }}
                                  title={this.getLabel(task_name)}
                                >
                                  {this.getLabel(task_name).length < 26
                                    ? this.getLabel(task_name)
                                    : this.getLabel(task_name).substring(0, 25) + "..."}

                                </div>
                                <div
                                  style={{
                                    display: "none",
                                    position: "relative",
                                    left: "2px",
                                    width: "25px",
                                  }}
                                >
                                  <img
                                    style={{
                                      cursor: "pointer",
                                      width: "15px",
                                      height: "15px",
                                      objectFit: "contain",
                                      marginRight: "5px",
                                    }}
                                    src="../images/steercom/info.png"
                                    alt=""
                                    onClick={this.showDescription.bind(
                                      this, row,1 
                                    )}
                                  />
                                </div>
                              </div>
                          }
                        </div>

                      </>
                    </div>}
                </>
              ) : ""}
            </div>
          )
        },
      }, 
      {
        name: LBLTASK,
        fixedHeader: true,
        selector: "task_name",
       // sortable: true,
        width: cwidth[2],
        compact: true,
        cell: (row) => {
          let xEditCheckBox =
            (hasRights) &&
            row.team_id != "NEW" &&
            isNaN(row.team_id) == false;
          let task_name = "";
          task_name = row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name);

          return (
            <div
              className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" :
                row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
              style={{ width: "100%", border: "0px solid", padding: "0px" }}
            >
              {/* {this.state.tasknamefocus?"11":"00"} -- {this.state.descriptionfocus?"11":"00"}  */}
              {row.task_type == 1 ? (
                <> 
                  <div
                    style={{
                      display: "flex",
                    }}
                  > 
                  </div>
                  {row.isNewAssigned === 1 || row.team_member_id == "NEW" ? (
                    <>
                      <div id={"notes_" + row.task_display_id}
                        style={{ display: "flex", width: "100%" }} className={err_task_name ? "required-input-border" : ""}>
                        <input
                          onChange={(e) =>
                            this.handleTaskName(editingTeamSF["M_" + row.task_display_id], e)
                          }
                          onClick={this.handleTaskNameFocus.bind(this, row)}
                          className="cls_md_text"
                          value={
                            this.getLabel(editingTeamSF["M_" + row.task_display_id].task_name)
                          }
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "task_name"
                              ? true
                              : false
                          }
                          onFocus={(e) => this.onfocus2(row, e)}
                        />

                      </div>
                      <span
                        className="required required-field" style={{ top: "26px", zIndex: "1" }}
                        id={"span_completion_percentage_" + row.task_display_id}
                      >
                        {LBLREQUIRED}
                      </span>
                      <img
                        style={{
                          cursor: "pointer",
                          width: "15px",
                          height: "15px",
                          objectFit: "contain",
                          marginRight: "5px",
                        }}
                        alt=""
                        src="../images/steercom/info.png" 
                          onClick={this.showAddEditDesc.bind(
                            this,
                            row,3, row.custom_task_name ? row.custom_task_name : this.getLabel(row.task_name),
                            row.task_display_id,  
                          )}
                        title={LBLTASKDESC}
                      />
                    </>
                  ) :
                    <div
                      style={{
                        width: "calc(100% - 12px)",
                        border: "0px solid",
                        display: "flex",
                        marginLeft: "10px"
                      }}
                    >

                      <>
                        <div
                          style={{
                            display: "inline-block",
                            position: "relative",
                            border: "0px solid red",
                            width: "100%",
                            textAlign: "left",
                            overflow: "hidden",
                          }}
                        >
                          {row.team_id == "Add_Task" ? "" : 
                            row.team_id == "New_Task" ?
                              <div id={"notes_" + row.task_display_id}
                                style={{ display: "flex", width: "100%" }} className={err_task_name ? "required-input-border" : ""}>
                                <input
                                  onChange={(e) =>
                                    this.handleTaskName(editingTeamSF["M_" + row.task_display_id], e)
                                  }
                                  onClick={this.handleTaskNameFocus.bind(this, row)}
                                  className="cls_md_text"
                                  value={
                                    this.getLabel(editingTeamSF["M_" + row.task_display_id].task_name)
                                  }
                                  autoFocus={
                                    editingTeamSF["M_" + row.task_display_id][
                                      "auto_focus"
                                    ] == "task_name"
                                      ? true
                                      : false
                                  }
                                  onFocus={(e) => this.onfocus2(row, e)}
                                />

                              </div>
                              :
                              <div style={{ width: "100%", display: "inline-flex" }}>
                                <div
                                  style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    height: "24px" /* exactly 2 lines */,
                                    textOverflow: "ellipsis",
                                    fontWeight: "410",
                                    position:"relative",
                                    top:"3px"
                                  }}
                                  title={this.getLabel(task_name)}
                                >
                                  {this.getLabel(task_name).length < 200
                                    ? this.getLabel(task_name)
                                    : this.getLabel(task_name).substring(0, 200) + "..."}
                                  {/* {row.task_display_id} {this.getLabel(row.task_name)} */}
                                </div>
                                <div
                                  style={{
                                    display: "inline-block",
                                    position: "relative",
                                    left: "2px",
                                    width: "25px",
                                  }}
                                >
                                  <img
                                    style={{
                                      cursor: "pointer",
                                      width: "15px",
                                      height: "15px",
                                      objectFit: "contain",
                                      marginRight: "5px",
                                    }}
                                    src="../images/steercom/info.png"
                                    onClick={this.showDescription.bind(
                                      this, row,2 
                                    )}
                                    title={LBLTASKDESC}
                                    alt=""
                                  />
                                </div>
                              </div>
                          }
                        </div>

                      </>
                    </div>}
                </>
              ) : (
                ""
              )}
            </div>
          )
        },
      }, 
      {
        name: LBLCATEGORY,
        selector: "name",
        //    sortable: true,
        width:  cwidth[3],
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" :
              row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
            }}
          >
            {row.task_type == 2 ? "" : (hasRights && row.task_type == 1) ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid red",
                    display: "flex",
                  //  overflowY: "auto",
                  }}
                >
                  {(row.isNewAssigned === 1 || row.team_id == "NEW") && row.is_default? (
                    <>

                      <div className="cls_defaultrole" style={{ border: err_task_type ? "1px solid red" : "" }}>
                      <ThemeProvider theme={theme}>
                        <Autocomplete
                          id={"roles_" + row.team_member_id}
                          disableClearable
                          options={taskCategoryArr}
                          getOptionLabel={(option) => option.name.toString()}
                          getOptionSelected={(option, values) =>
                            option.id === values.id
                          }
                          onChange={this.handleChange.bind(
                            this,
                            "category",
                            row,
                            "id"
                          )}
                          underlineStyle={{ display: "none" }}
                          defaultValue={
                            editingTeamSF["M_" + row.task_display_id] &&
                            taskCategoryArr.find(
                              (v) =>
                                v.id ==
                                editingTeamSF["M_" + row.task_display_id]
                                  .category
                            )
                          }
                          renderInput={(params) => (
                            <TextField id="memberlist" {...params} />
                          )}
                          underline="none"
                        />
                          </ThemeProvider>
                      </div>
                    </>
                  ) : (
                    <>

                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div className="cls_defaultrole" style={{ border: err_task_type ? "1px solid red" : "" }}>
                          <ThemeProvider theme={theme}>    
                            <Autocomplete
                              id={"roles_" + row.team_member_id}
                              disableClearable
                              options={taskCategoryArr}
                              getOptionLabel={(option) => option.name.toString()}
                              getOptionSelected={(option, values) =>
                                option.id === values.id
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "category",
                                row,
                                "id"
                              )}
                              underlineStyle={{ display: "none" }}
                              defaultValue={
                                editingTeamSF["M_" + row.task_display_id] &&
                                taskCategoryArr.find(
                                  (v) =>
                                    v.id ==
                                    editingTeamSF["M_" + row.task_display_id]
                                      .category
                                )
                              }
                              renderInput={(params) => (
                                <TextField id="memberlist" {...params} />
                              )}
                              underline="none"
                            />
                           </ThemeProvider>
                          </div>
                          :
                          <div
                            style={{
                              width: "100%",
                              border: "0px solid red",
                              display: "flex",
                              overflowY: "auto",
                              height: "100%",
                              color: row.category == "1" ? "green" : "#be043d",
                              cursor:"default"
                            }}
                          >
                            <div className="grid-ellipsis" title={row.category == "1" ? LBLMANDATORY : LBLBESTPRACTICE}> {row.category == "1" ? LBLMANDATORY : LBLBESTPRACTICE}</div>
                          </div>
                      }
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {
                  <div
                    style={{
                      width: "100%",
                      border: "0px solid red",
                      display: "flex",
                      overflowY: "auto",
                      height: "100%",
                    }}
                  >
                    <div>  </div>
                  </div>
                }
              </>
            )}
          </div>
        ),
      },
      {
        name: LBLTASKTYPE,
        selector: "name",
        //    sortable: true,
        width: cwidth[4],
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" :
              row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
            }}
          >
            {hasRights && row.task_type == 1 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid red",
                    display: "flex",
                    //overflowY: "auto",
                  }}
                >
                  {row.isNewAssigned === 1 || row.team_id == "NEW" ? (
                    <>

                      <div className="cls_defaultrole" style={{ border: err_task_type ? "1px solid red" : "" }}>
                      <ThemeProvider theme={theme}>
                        <Autocomplete
                          id={"roles_" + row.team_member_id}
                          disableClearable
                          options={taskTypeArr}
                          getOptionLabel={(option) => option.name.toString()}
                          getOptionSelected={(option, values) =>
                            option.id === values.id
                          }
                          onChange={this.handleChange.bind(
                            this,
                            "task_type_base",
                            row,
                            "id"
                          )}
                          underlineStyle={{ display: "none" }}
                          defaultValue={
                            editingTeamSF["M_" + row.task_display_id] &&
                            taskTypeArr.find(
                              (v) =>
                                v.id ==
                                editingTeamSF["M_" + row.task_display_id]
                                  .task_type_base
                            )
                          }
                          renderInput={(params) => (
                            <TextField id="memberlist" {...params} />
                          )}
                          underline="none"
                        />
                        </ThemeProvider>
                      </div>
                    </>
                  ) : (
                    <>

                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div className="cls_defaultrole" style={{ border: err_task_type ? "1px solid red" : "" }}>
                            <ThemeProvider theme={theme}>
                            <Autocomplete
                              id={"roles_" + row.team_member_id}
                              disableClearable
                              options={taskTypeArr}
                              getOptionLabel={(option) => option.name.toString()}
                              getOptionSelected={(option, values) =>
                                option.id === values.id
                              }
                              onChange={this.handleChange.bind(
                                this,
                                "task_type_base",
                                row,
                                "id"
                              )}
                              underlineStyle={{ display: "none" }}
                              defaultValue={
                                editingTeamSF["M_" + row.task_display_id] &&
                                taskTypeArr.find(
                                  (v) =>
                                    v.id ==
                                    editingTeamSF["M_" + row.task_display_id]
                                      .task_type_base
                                )
                              }
                              renderInput={(params) => (
                                <TextField id="memberlist" {...params} />
                              )}
                              underline="none"
                            />
                            </ThemeProvider>
                          </div>
                          :
                          <div
                            style={{
                              width: "99%",
                              border: "0px solid red",
                              display: "flex",
                              overflowY: "auto",
                              height: "100%",
                            }}
                          >
                            <div className="cls_ellipsis" title= {row.recommended_start_days > -1 ? LBLPOSTBASEDATE : LBLPREBASEDATE}> 
                            {row.recommended_start_days > -1 ? LBLPOSTBASEDATE : LBLPREBASEDATE}</div>
                          </div>
                      }
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {
                  <div
                    style={{
                      width: "100%",
                      border: "0px solid red",
                      display: "flex",
                      overflowY: "auto",
                      height: "100%",
                    }}
                  >
                    <div>  </div>
                  </div>
                }
              </>
            )}
          </div>
        ),
      }, 
      {
        name: LBLDEADLINE,
        selector: "recommended_start_days",
        //sortable: true,
        width:  cwidth[5],
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" :
              row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {hasRights && row.task_type == 1 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid red",
                    display: "flex",
                    overflowY: "auto",
                  }}
                >
                  {row.isNewAssigned === 1 || row.team_id == "NEW" ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          border: "0px solid red",
                          display: "flex",
                          overflowY: "auto",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          // border: err_recommended_start_days ? "1px solid red" : ""
                        }}
                      >
                        <input type="text" style={{}}
                          className="cls_sm_text"
                          onChange={this.handleDeadline.bind(this, row)}
                          // onClick={this.state.deadlinefocus ? "" : this.handleDeadlineFocus.bind(this, row)}
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "deadline"
                              ? true
                              : false
                          }
                          value={editingTeamSF["M_" + row.task_display_id].recommended_start_days}
                        >
                        </input>
                      </div>
                    </>
                  ) : (
                    <>
                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div style={{ border: err_recommended_start_days ? "1px solid red" : "" }}>
                            <input
                              value={editingTeamSF["M_" + row.task_display_id] && editingTeamSF["M_" + row.task_display_id].recommended_start_days}
                              onChange={this.handleDeadline.bind(this, row)}
                              //onClick={this.handleDeadlineFocus.bind(this, row)}
                              autoFocus={
                                editingTeamSF["M_" + row.task_display_id][
                                  "auto_focus"
                                ] == "deadline"
                                  ? true
                                  : false
                              }
                              className="cls_deadline" type="text"></input>
                          </div>
                          :
                          <div
                            style={{
                              width: "100%",
                              border: "0px solid red",
                              display: "flex",
                              overflowY: "auto",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div>{row.recommended_start_days}</div>
                          </div>
                      }
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {
                  <div
                    style={{
                      width: "100%",
                      border: "0px solid red",
                      display: "flex",
                      overflowY: "auto",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div> </div>
                  </div>
                }
              </>
            )}
          </div>
        ),
      },
      {
        name: LBLUPLOADRESOURCE,
        selector: "name",
        //    sortable: true,
        width:  cwidth[6],
        compact: true,
        cell: (row) => (
          <div
            className={(row.task_type == 0 || row.team_id == "Add_Task") ? "sfcellbox MAINTASK" :
              row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{
              width: "100%",
              border: "0px solid",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {hasRights && row.task_type == 1 ? (
              <>
                <div
                  style={{
                    width: "100%",
                    border: "0px solid red",
                    display: "flex",
                    overflowY: "auto",
                  }}
                >

                  {row.isNewAssigned === 1 || row.team_id == "NEW" ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          overflowY: "auto",
                          height: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input type="text"
                          style={{ border: err_references ? "1px solid red" : "" }}
                          className="cls_md_text"
                          onChange={this.handleResource.bind(this, row)}
                          value={editingTeamSF["M_" + row.task_display_id].custom_references}
                          autoFocus={
                            editingTeamSF["M_" + row.task_display_id][
                              "auto_focus"
                            ] == "references"
                              ? true
                              : false
                          }
                          onFocus={(e) => this.onfocus2(row, e)}
                        >
                        </input>
                      </div>
                    </>
                  ) : (
                    <>
                      {row.team_id == "Add_Task" ?
                        "" :
                        row.team_id == "New_Task" ?
                          <div>
                            <input type="text"
                              style={{ border: err_references ? "1px solid red" : "" }}
                              className="cls_md_text"
                              onChange={this.handleResource.bind(this, row)}
                              //onClick={this.handleResourceFocus.bind(this, row)}
                              value={editingTeamSF["M_" + row.task_display_id].custom_references}
                              //autoFocus={this.state.referencefocus}
                              autoFocus={
                                editingTeamSF["M_" + row.task_display_id][
                                  "auto_focus"
                                ] == "references"
                                  ? true
                                  : false
                              }
                              onFocus={(e) => this.onfocus2(row, e)}
                            >
                            </input>
                          </div>
                          :
                          <div
                            style={{
                              width: "100%",
                              border: "0px solid red",
                              display: "flex",
                              overflowY: "auto",
                              height: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >

                            <div>{row.custom_references}</div>
                          </div>
                      }
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {
                  <div
                    style={{
                      width: "100%",
                      border: "0px solid red",
                      display: "flex",
                      overflowY: "auto",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div> </div>
                  </div>
                }
              </>
            )}
          </div>
        ),
      }, 
      {
        name: LBLACTION,
        selector: "status",
        //     sortable: true,
        width:  cwidth[7],
        compact: true,
        cell: (row) => (
          this.state.isDefaultTemplate?"": 
          <div
            className={row.task_type == 0 ? "sfcellbox MAINTASK" :
            row.task_type == 2 ? "cls_mile_title" : "sfcellbox"}
            style={{ width: "100%", border: "0px solid" }}
          // , display:showWeightage?"":"none"
          onMouseLeave={this.handleRowHighlightRemove.bind(this, row)}
          onMouseOver={this.handleRowHighlight.bind(this, row)}
          >
            {(row.task_type == 1 || row.task_type == 0) && (row.isNewAssigned || row.team_id == "New_Task" || row.team_id == "New_Main_Task" || row.task_type == 0 && row.isNewAssigned == true) ? (
              <>
                {" "}
                {hasRights ? (
                  <>
                    <label className="show-sm">{LBLACTION}</label>
                    <div
                      className="sub_container33"
                      style={{ display: "inline-block" }}
                    >
                      <img
                        src="../images/steercom/Checkbox.png"
                        //onClick={this.saveSuccessFactorTemp.bind(this, row)}
                        onClick={this.openConfirmDialog1.bind(this, row, 2)}
                        title={LBLSAVE}
                        className="img_team_cross"
                        alt=""
                      ></img>
                    </div>
                    <div
                      className="sub_container3 cls_team_cross"
                      style={{ display: "inline-block" }}
                    >
                      <i
                        class="fas fa-undo"
                        onClick={(row.team_id == "New_Task" || row.team_id == "New_Main_Task") ? this.hideAddTask.bind(this, row) : this.deleteSuccessFactor.bind(this, row)}
                        title={LBLCANCEL}
                      ></i>
                    </div>

                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {(row.task_type == 1 || row.task_type == 0) && row.team_id != "Add_Task" && row.team_id != "Add_Main_Task" ?
                  <>
                    <div
                      className="sub_container3 cls_team_cross"
                      style={{ display: "inline-block", textAlign: "center", width: "40%" }}
                    >
                      <i
                        class="far fa-trash-alt"  
                        onClick={row.team_id == "New_Task" ? this.hideAddTask.bind(this, row) : this.openConfirmDialog1.bind(this, row, 1)} //</div>  this.deleteTask.bind(this, row)
                        title={LBLDELETE}
                      ></i>
                    </div>
                    <div
                      className=" cls_team_cross"
                      style={{ display: "inline-block" }}
                    >
                      <i
                        class="fas fa-reply"
                        onClick={this.resetSingleTask.bind(this, row)}
                        title={LBLRESET}
                      ></i>
                    </div></>
                  : ""} </>
            )}
          </div>
        ),
      },
    ];
     
    let teamSuccessFactorListData = filterSuccessFactorsListData;
    let horizontal = "top",vertical = "center";  
    let xdefaultValue = this.state.teamData.team_id ? this.state.teamData : this.state.teamList[0]
    
    let isFilter=false, teamSFColumns = [], teamSFListData = [];
    teamSFColumns = teamSuccessFactorColumns;
    teamSFListData = teamSuccessFactorListData;
    if (!this.state.showWeightage) {
      teamSFColumns = teamSuccessFactorColumnsAll;
      teamSFListData = [];
      teamSuccessFactorListData.map((item, idx) => {
        if (item.task_type == 1 && item.team_id != "Add_Task" || item.task_type == 2) {
          teamSFListData.push(item);
        }
        return null;
      });
      if (this.state.allFilterValues  && this.state.allFilterValues.singleItems.status != "" && this.state.allFilterValues.singleItems.status != undefined) {
        let filteredList = [], status = this.state.allFilterValues.singleItems.status;
        filteredList = teamSFListData.filter(item => item.category == status);
        teamSFListData = filteredList;
        isFilter=true;
      }
    }else if (this.state.showWeightage) {
      teamSFColumns = teamSuccessFactorColumnsAll;
      teamSFListData = [];
      if(successFactorData.id!=0){
        teamSuccessFactorListData = this.sortByKeyAsc(teamSuccessFactorListData, "recommended_start_days")
      }
      teamSuccessFactorListData.map((item, idx) => {
        if (item.task_type == 1) {
          teamSFListData.push(item);
        }
        return null;
      });
    }
    return (
      <div className="mob-pad success-factors steercom-configuration">
        <ToastMessage
          show={this.state.showToast["show"]}
          helpers={this.state.showToast}
        ></ToastMessage>
        <div id="dialogs">
          <Dialog className="cls_dialog steercom-configuration"
            open={this.state.opendialog3}
            onClose={this.handleCloseDialog3}
            aria-labelledby="responsive-dialog-title"
            contentStyle={{minWidth: "500px" }}
          >
            <div style={{width:"500px"}}>
              <DialogTitle id="responsive-dialog-title" className="mui-title" >
                <div style={{display:"flex",justifyContent:"space-between"}} >
                  <div>{LBLTASKSETUP} </div>
                  <i style={{ margin: "2px 13px 0px 13px"}} className="fas fa-times fas fa-bars mui-close-icon" onClick={this.handleCloseDialog3}></i>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  <div className="mui-modal-body" > {LBLUNSAVEDATA} </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions className="mui-footer">
                <button autoFocus id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog3}>
                  {LBLCANCEL}
                </button>
                <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog3}>
                  {LBLSAVE}
                </button>
              </DialogActions>
            </div>
          </Dialog>
          <Dialog className="cls_dialog steercom-configuration"
            open={this.state.opendialog2}
            onClose={this.handleCloseDialog2}
            aria-labelledby="responsive-dialog-title"
            // contentStyle={{ Width: "500px" }}
            style={{minWidth:"500px"}}
          >
            <div style={{width:"500px"}}>
              <DialogTitle id="responsive-dialog-title" className="mui-title">
                <div style={{ display: "flex", justifyContent: "space-between" }} >
                  <div >{LBLTASKSETUP} </div>
                  <i style={{ margin: "2px 13px 0px 13px"}} class="fas fa-times fas fa-bars mui-close-icon" onClick={this.handleCloseDialog2}></i>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  <div className="mui-modal-body"> {lbltaskresetconfirm} </div>
                </DialogContentText>
              </DialogContent>

              <DialogActions className="mui-footer">
                <button autoFocus id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog2} >
                  {LBLNO}
                </button>
                <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog2} >
                  {LBLAGREE}
                </button>
              </DialogActions>
            </div>
          </Dialog>
          <Dialog className="cls_dialog1 steercom-configuration"
            open={this.state.opendialog1}
            onClose={this.handleCloseDialog1}
            aria-labelledby="responsive-dialog-title"
            contentStyle={{minWidth: "500px" }}
          >
            <div style={{ width: "500px" }}>
              <DialogTitle id="responsive-dialog-title" className="mui-title">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div >{LBLTASKSETUP} </div>
                  <i style={{ margin: "2px 13px 0px 13px"}} class="fas fa-times fas fa-bars mui-close-icon"  onClick={this.handleCloseDialog1}></i>
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  <div className="mui-modal-body">{msg_confirm}</div>
                  <p className="mui-modal-body">{msg_confirm1}</p>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <button autoFocus id="1" className="button-style secondary-btn" onClick={this.handleCloseDialog1} color="primary">
                  {LBLNO}
                </button>
                <button id="2" className="button-style primary-btn" onClick={this.handleCloseDialog1} color="primary">
                  {LBLAGREE}
                </button>
              </DialogActions>
            </div>
          </Dialog>
        </div>

        <div
          className="team_main_container"
          style={{ paddingTop: "0px", marginTop: "-3x" }}
        >
          <Snackbar
            style={{ width: "43%", margin: "0% 30%", justifyItems: "center" }}
            anchorOrigin={{ horizontal, vertical }}
            key={"top-center"}
            open={this.state.openalert}
            autoHideDuration={
              this.state.showtime ? this.state.showtime : 2000
            }
            onClose={this.handleCloseAlert}
            bodyStyle={{ backgroundColor: "teal", color: "coral" }}
          >
            <Alert
              variant="filled"
              onClose={this.handleClose}
              severity={this.state.messageType}
            >
              {(showMessage1 == "" ? this.state.message :
                <>{this.state.message} <br /> <br /> <span style={{ fontSize: "15px", marginTop: "-10px" }}>{showMessage1}</span>
                </>
              )}
            </Alert>
          </Snackbar>
          <Modal
            animation={false}
            show={this.state.showDescPopUp}
            onHide={this.closeDescPopUp}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="steercom-configuration "
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title">
                  {this.state.nameData} 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="col-xl-12 col-md-12 col-sm-12" style={{ marginTop: "20px" }}>
                  <label className="clsTaskDesc">
                    {LBLDESCRIPTION}:</label>
                  <textarea
                    id={"txt_desc"}
                    className="cls_txt_area clsDescTextarea"
                    onChange={this.handleDescriptionPop.bind(this)}
                    value={this.state.e_description}
                  ></textarea>
                </div>

                <br></br>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop:"0px" }}>
                <div className="col-sm-12 btm-submit">
                  <>
                    <button
                      type="submit"
                      className="button-style primary-btn"
                      disabled={this.state.submitting}
                      style={{ float: "right" }}
                      onClick={this.addTaskDescription.bind(this, 1)}
                    >
                      {LBLSAVE}
                    </button>
                  </>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.closeDescPopUp}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {LBLCANCEL}
                  </button>
                </div>
            </Modal.Footer>
          </Modal>
          <Modal
            animation={false}
            show={this.state.showDescModal}
            onHide={this.closeDescription}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title">
                  {this.state.titleMode==1?
                   LBLPARENTTASK : 
                   this.state.nameData.length>70?this.state.nameData.substring(0,50)+"...":this.state.nameData 
                  }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="modal_body_desc"
                style={{
                  fontSize: "16px",
                  color: "#505050",
                  padding: "10px",
                }}
              >
                 <textarea
                    style={{
                      width: "100%", 
                     display: "inline-table",
                     overflow:"auto" 
                    }}
                    disabled={true}  
                    value={this.state.descriptionData}
                  ></textarea>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop:"0px" }}>
              <div className="ssi-feeds-commentsActionBtn"></div>
            </Modal.Footer>
          </Modal>
          <Modal
            animation={false}
            show={this.state.showmodal}
            onHide={this.closeRefModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          //centered
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title">
                  {LBLREFERENCELNKS}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReferenceMaterial
                initialValues={this.state.initialValues}
                resultData={this.props.productListData}
                labels={this.props.labels}
                changesIn={this.changesIn}
                closeModal={this.props.closeRefModal}
                resourceData={this.state.resourceData}
                resourceDataCustom={this.state.resourceDataCustom}
              ></ReferenceMaterial>
            </Modal.Body>
            <Modal.Footer >
              <div className="ssi-feeds-commentsActionBtn">
              </div>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.showFilter}
            id="filterModal"
            backdrop="static"
            onHide={this.closeFilterModal}
            size="lg"
            data-easein="expandIn"
            className={
              " " +
              (this.state.showFilter ? "expandIn" : "fadeOutUp")
            }
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title" id="exampleModalLongTitle">
                {LBLTASKFILTER}
              </Modal.Title>
            </Modal.Header>

            <FilterComp
              initialValues={this.state.initialValues}
              sendInvitation={this.sendInvitation}
              handleSubmit={this.applyFilters}
              toFilters={this.state.toFilters}
              helpersLoaded={this.state.helpersLoaded}
              closeModal={this.closeModal}
              labels={this.props.labels}
              className="toggleClass"
            ></FilterComp>
          </Modal> 
          <Modal
            animation={false}
            show={this.state.showMilestone}
            onHide={this.closeMilestone}
            size="lg"
            className= "modal fade2 steercom-configuration"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title">
                  {LBLADDMILESTONE} 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="cls_edit_mile"
                  onClick={this.showEditMilestone}><span className="link-btn">{showEditMile?LBLADD:LBLEDIT}</span></div>

                <div className="col-xl-9 col-md-9 col-sm-12" style={{ marginTop: "20px", display: showEditMile ? "" : "none" }}>
                  <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                    {LBLMILESTONES}:</label>
                    <ThemeProvider theme={theme}>
                  <Autocomplete
                    id="mileNameCombo"
                    style={{ width: "calc(100%)" }}
                    options={milestones}
                    getOptionLabel={(option) => option.custom_task_name}
                    getOptionSelected={(option, values) =>
                      option.id === values.id
                    }
                    onChange={this.handleMileChange.bind(this)}
                    disableClearable
                    defaultValue={""}
                    value={this.state.selectMile}
                    renderOption={(option) => (
                      <div style={{ fontSize: "14px" }}>{option.custom_task_name}</div>
                    )}
                    renderInput={(params) => (
                      <TextField
                       style={{ 
                          overflow: "hidden",
                          whiteSpace: "nowrap", 
                          textOverflow: "ellipsis", 
                        }}
                        value="mileNameTxt"
                        text="sur"
                        {...params}
                        
                      />
                    )}
                  />
                  </ThemeProvider>
                  <span className={"required-field"} style={{ display: this.state.errorMile ? "block" : "none"}}>{LBLREQUIRED}</span>
                </div>
                <div className="col-xl-10 col-md-9 col-sm-12" style={{ marginTop: "20px" }}>
                  <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                    {LBLMILESTONENAME}:</label>
                    <TextField id={"mileName"} 
                    style={{ 
                      overflow: "hidden",fontSize: "12px", width:"450px",
                      whiteSpace: "nowrap",  textOverflow: "ellipsis",  
                    }}
                    disabled={this.state.errorMile}
                    />
                     <span className={"required-field"} style={{ display: this.state.errorMileName ? "block" : "none"}}>{LBLREQUIRED}</span>
                </div>
                <div  className="col-xl-10 col-md-9 col-sm-12"  style={{ marginTop: "20px" }}>
                  <label style={{margin: "5px 11px 4px 0px"}} className="label labelAddEditMilestone">
                    {LBLMILESTONERANGE}:</label>
                  <div className="" style={{ display: "flex" ,marginTop: "6px"}}  >
                    <div className=" " >
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        id="mileDuration2"
                        style={{ width: "200px" }}
                        options={subTasks}
                        getOptionLabel={(option) => option.recommended_start_days && option.recommended_start_day==0?"00":option.recommended_start_days.toString()}
                        getOptionSelected={(option, values) =>
                          option.id === values.task_id
                        }
                        disabled={this.state.errorMile}
                        onChange={this.handleRangeChange}
                        disableClearable
                        // disabled={this.state.editTeam ? true : false}
                        defaultValue={""}
                        value={this.state.selectRange}
                        renderOption={(option) => (
                          <div style={{ fontSize: "14px" }}>{option.recommended_start_days.toString()}</div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            value="mileDuration1"
                            text="sur"
                            {...params}

                          />


                        )}
                      />
                      </ThemeProvider>
                    </div>
                  </div>
                  <span className={"required-field"} style={{ display: this.state.errorDuration ? "block" : "none" }}>{LBLREQUIRED}</span>
                  
                </div>

                <br></br>
              </div>
            </Modal.Body>
            <Modal.Footer style={{borderTop:"none"}}>
                <div className="col-sm-12 btm-submit">
                  <>
                    <button
                      type="submit"
                      className="button-style primary-btn"
                      disabled={this.state.submitting}
                      style={{ float: "right" }}
                      onClick={this.saveMileStones.bind(this, 1)}
                    >
                      {LBLSAVE}
                    </button>
                  </>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.closeMilestone}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {LBLCANCEL}
                  </button>
                </div>
            </Modal.Footer>
          </Modal>
          <Modal
          animation={false}
            show={this.state.showPopUp}
            onHide={this.closePopUp}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton style={{border:0}} cls_submit>
              <Modal.Title className="alert-modal-title">
                  {LBLADDTASK} 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddTask
                showmode={this.state.mode}
                taskDetails={this.state.taskDetails}  
                helpersLoaded={this.state.helpersLoaded}
                closeModal={this.closePopUp}
                labels={this.props.labels.labels}
                // changesIn={this.changesIn}
                className="toggleClass"
              ></AddTask>
            </Modal.Body>
          </Modal>

          <Modal 
            show={this.state.showGantPopup}
            onHide={this.closeGantPopup}
            size="lg" 
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title"> 
                  {LBLGANTTASKVSREC} 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body> 
            </Modal.Body>
            <Modal.Footer >
              <div className="ssi-feeds-commentsActionBtn"></div>
            </Modal.Footer>
          </Modal>
          <Modal 
            show={this.state.showNewTemp}
            onHide={this.closeNewTemp}
            size="md" 
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title"> 
                  {LBLCREATETEMPLATE?LBLCREATETEMPLATE:"Create Template"} 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body> 
              <div className="steercom-configuration ">
                <div className="cls_temp_top_container"> 
                <div className="cls_temp_desc"> 
                  <span>{LBLTEMPDESC?LBLTEMPDESC :"A new template can be created only by duplicating on existing template. Duplicating a template will create new versions of all tasks"}</span>
                </div>
                <div className="col-xl-10 col-md-9 col-sm-12" style={{ marginTop: "20px" }}>
                  <label className="label labelcreatTemp">
                    {LBLTEMPLATENAME?LBLTEMPLATENAME:"Template Name"}:</label>
                    <TextField id={"templateName"} 
                    style={{ 
                      overflow: "hidden",fontSize: "12px", width:"350px",
                      whiteSpace: "nowrap",  textOverflow: "ellipsis",  
                    }}
                    // value={this.state.templateName}
                    /> 
                     <span className="required-field" style={{ display: this.state.errorTempName ? "block" : "none" }}>{this.state.msgTempName}</span>
                      
                </div>
                <div  className="col-xl-12 col-md-9 col-sm-12"  style={{ marginTop: "20px" }}>
                  <label className="label labelcreatTemp">
                    {LBLTEMPLATEFROM?LBLTEMPLATEFROM:"Duplicate Template From"}:</label>
                  <div className="" style={{ display: "flex" ,marginTop: "6px"}}  >
                     
                    <div className=" " >
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        id="template_from"
                        style={{ width: "350px" }}
                        options={tempLanguages}
                        getOptionLabel={(option) => option.template_name && option.template_name.toString()}
                        getOptionSelected={(option, values) =>
                          option.template_id === values.template_id
                        }
                         
                        onChange={this.handleTemplateChange}
                        disableClearable 
                        defaultValue={""}
                        value={this.state.defaultTemplate}
                        renderOption={(option) => (
                          <div style={{ fontSize: "14px" }}>{option.template_name}</div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            value="tempLang"
                            text="tempLang"
                            {...params} 
                          /> 
                        )}
                      />
                      </ThemeProvider>
                    </div>
                     
                  </div> 
                  <span className={"required-field"} style={{ display: this.state.errorDefLang ? "block" : "none"}}>{LBLREQUIRED}</span>
                  
                </div>
                </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ padding: "0.5rem", marginRight:"-20px",borderTop:"0px" }}>
            <div className="col-sm-12 btm-submit">
                  <>
                    <button
                      type="submit"
                      className="button-style primary-btn"
                      disabled={this.state.submitting}
                      style={{ float: "right" }}
                      onClick={this.createNewTemplate.bind(this)}
                    >
                      {LBLCREATETEMPLATE?LBLCREATETEMPLATE:"Create Template"}
                    </button>
                  </>
                  <button
                    type="button"
                    className="button-style secondary-btn"
                    id="btnaddCancel"
                    onClick={this.closeNewTemp}
                    style={{ float: "right", marginRight: "10px" }}
                  >
                    {LBLCANCEL}
                  </button>
                </div>
            </Modal.Footer>
          </Modal>
          <div>
            <div className="cls_task_top_border">
              <div class="row" style={{ display:"flex",alignItems:"end" }}>
                <div className="col-sm-4 taskAutoCompleteFactor">
                  <label style={{margin: "5px 11px 4px 0px"}} className="label">{LBLSF}:</label>
                  <ThemeProvider theme={theme}>
                  <Autocomplete
                    style={{ width: "calc(100%)", fontSize: "12px" }}
                    size={"small"}
                    label={LBLSF}
                    placeholder={LBLSF}
                    disableClearable
                    options={this.state.successFactorsList}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, values) =>
                      option.id === values.id
                    }
                    onChange={this.handleSFChange.bind(this)}
                    underlineStyle={{ display: "none" }}
                    defaultValue={this.state.successFactorData}
                    value={this.state.successFactorData}
                    renderOption={(option) => (
                      <div style={{ fontSize: "14px" }}>{option.title}</div>
                    )}
                    renderInput={(params) => (
                      <TextField style={{ fontSize: "12px" }} id="sflist" {...params} />
                    )}
                    underline="none"
                  />
                  </ThemeProvider>
                </div>
                {Object.keys(this.state.steercomSettings).length > 0 ?
                  <div className="col-sm-4 taskAutoCompleteTemplate" >
                    <label style={{margin: "5px 11px 4px 0px"}} className="label ">{LBLTEMPLATE}:</label>
                    <ThemeProvider theme={theme}>
                    <Autocomplete
                      id="combo-box-demo"
                      style={{ width: "calc(100%)" }}
                      options={ steercomSettings &&  steercomSettings.templates}
                      getOptionLabel={(option) => option.template_name}
                      getOptionSelected={(option, values) =>
                        option.template_id === values.template_id
                      } 
                      onChange={this.handleLanguageChange}
                      disableClearable 
                      defaultValue={this.state.teamTemplate}
                      value={this.state.teamTemplate}
                      renderOption={(option) => {
                      return ( steercomSettings.defaulttemps &&  steercomSettings.defaulttemps.findIndex(item=>item.template_id == option.template_id && item.is_default==1)>=0)?
                           <div className="taskTemplateOptionColor">{option.template_name}</div>: 
                           <div className="renderOption">{option.template_name}</div>
                        }}
                      renderInput={(params) => (
                        <TextField
                          value="surs"
                          text="sur"
                          {...params} 
                        />
                      )}
                    />
                     </ThemeProvider>
                  </div>
                  : ""}
                  
                  <div className="col-xl-3" style={{display: "flex"}}>
                    <div className="cls_btn_items" style={{visibility: "visible"}}>
                      <button onClick={this.openNewTemp} className="button-style primary-btn btn-sm cls_btn_item">
                        {LBLCREATETEMPLATE ? LBLCREATETEMPLATE : "Create Template"}
                       </button>
                    </div> 
                  </div>
                           
                <div className="btn-group ml-auto mr-3 cls_task_export" role="group">
                  <span
                    id="CSTFilter_spinner"
                    style={{
                      marginTop: "10px",
                      display: "none",
                      marginRight: "10px",
                    }}
                  > 
                    <Loader></Loader>
                  </span> 
                  <button
                    id="btnGroupDrop1"
                    type="button"
                    className="btn basicSelItem dropdown-toggle cls_export"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-mail-forward" aria-hidden="true">
                      &#xf064;
                    </i>{" "}
                    {LBLEXPORT}
                  </button>

                  <div
                    className="dropdown-menu  dropdown-menu-right"
                    aria-labelledby="btnGroupDrop1"
                  >
                    {!this.props.restrictExport || (this.props.restrictExport && this.props.restrictExport !== "csv") ?
                      <span
                        className="dropdown-item cls_export_items"
                        onClick={this.onExport.bind(this, "csv")}
                      >
                        {LBLEXPORTTOCSV}
                      </span>
                      : ""
                    }

                    <span
                      className="dropdown-item cls_export_items"
                      onClick={this.onExport.bind(this, "excel")}
                    >
                      {LBLEXPORTTOEXCEL}
                    </span>
                  </div>
                </div>

              </div>
            </div>
            <br></br>
            <div
              class="rowss"
              style={{
                borderBottom: "0px solid red", 
                marginTop: "-7px"
              }}
            >
              <div className="cls_task_button_container">
                  <div className="col-xl-44" style={{ display: "flex" }}>
                  <div className={this.state.isDefaultTemplate? "cls_btn_items cls_disable":"cls_btn_items"} style={{ visibility: teamSuccessFactorListData.length > 0 ? "visible" : "hidden" }}>

                    <button
                      className={"button-style primary-btn "}
                      onClick={this.showAddEdit.bind(this, 1)}
                    >
                      {LBLADDTASK}
                    </button>
                  </div>
                  <div className={this.state.isDefaultTemplate? "cls_btn_items cls_disable":"cls_btn_items"} style={{ marginLeft: "10px", visibility: teamSuccessFactorListData.length > 0 ? "visible" : "hidden" }}>

                    <button
                      className={"button-style primary-btn"}
                      onClick={this.showMilestonePop.bind(this, 1)}
                    >
                      {LBLADDMILESTONE?LBLADDMILESTONE:"Add/Edit Milestone"}
                    </button>
                  </div> 
                  </div>
                  <div className="col-xl-66" style={{ display: "flex",marginRight:"35px",marginLeft:"-36px" }}> 
                  {this.state.successFactorsList.length>0?
                  // <span className="cls_span_weight1">{LBLSF5?LBLSF5:""}</span>
                  <div className="cls_all_weightage_container" style={{ display:"none", marginLeft: "10px", visibility: teamSuccessFactorListData.length > 0 ? "visible" : "hidden" }}>
                     <div className="cls_weightage_top">
                     <div className="cls_all_weightage cls_all_weight1" title={this.state.successFactorsList[1].title + " - " + LBLWEIGTAGE}> <span className="cls_span_weight11">{this.state.weightage1}</span></div> 
                     <div className="cls_sf_name">{successFactorsList[1].title.length>8?successFactorsList[1].title.substring(0,8)+"...":successFactorsList[1].title}</div>
                     </div>
                     <div className="cls_weightage_top">
                     <div className="cls_all_weightage cls_all_weight2" title={this.state.successFactorsList[2].title + " - " + LBLWEIGTAGE}> <span className="cls_span_weight11">{this.state.weightage2}</span></div>
                     <div className="cls_sf_name">{successFactorsList[2].title.length>8?successFactorsList[2].title.substring(0,8)+"...":successFactorsList[2].title}</div>
                     </div>
                     <div className="cls_weightage_top">
                     <div className="cls_all_weightage cls_all_weight3" title={this.state.successFactorsList[3].title + " - " + LBLWEIGTAGE}> <span className="cls_span_weight11">{this.state.weightage3}</span></div>
                     <div className="cls_sf_name">{successFactorsList[3].title.length>8?successFactorsList[3].title.substring(0,8)+"...":successFactorsList[3].title}</div>
                     </div>
                     <div className="cls_weightage_top">
                     <div className="cls_all_weightage cls_all_weight4" title={this.state.successFactorsList[4].title + " - " + LBLWEIGTAGE}> <span className="cls_span_weight11">{this.state.weightage4}</span></div>
                     <div className="cls_sf_name">{successFactorsList[4].title.length>8?successFactorsList[4].title.substring(0,8)+"...":successFactorsList[4].title}</div>
                     </div>
                     <div className="cls_weightage_top">
                     <div className="cls_all_weightage cls_all_weight5" title={this.state.successFactorsList[5].title + " - " + LBLWEIGTAGE}> <span className="cls_span_weight11">{this.state.weightage5}</span></div>
                     <div className="cls_sf_name">{successFactorsList[5].title.length>8?successFactorsList[5].title.substring(0,8)+"...":successFactorsList[5].title}</div>
                     </div>
                  </div>:""}
                  </div>
                  <div className="col-xl-44" style={{ display: "flex",alignItems: "center" }}>
                  <div className="cls_task_filter" style={{ display: this.state.showWeightage ? "none" : "block", visibility: teamSuccessFactorListData.length > 0 ? "visible" : "hidden" }}>
                    <div
                      className="clsSelFilters"
                      style={{ display: this.state.visible ? "block" : "none" }}
                    >
                      <span></span>
                    </div>
                    <div
                      // data-toggle="tooltip" 
                      title={this.props.labels.labels.LBLFILTER}
                      data-toggle="modal"
                      data-target="#myModal"
                      style={{
                        width: "25px",
                        zoom: "1.4",
                        cursor: "pointer",
                        //title: "Filter",
                        margin: "0px 5px",
                      }}
                    >
                      <i
                        data-target="#myModal"
                        onClick={this.openFilterModal}
                        class="fa fa-filter"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
 
                  <div className={this.state.isDefaultTemplate? "cls_btn_items cls_disable":"cls_btn_items"} style={{ visibility: teamSuccessFactorListData.length > 0 ? "visible" : "hidden"}}>
 
                    <button
                      className={ "button-style primary-btn btn-sm" }
                      onClick={this.resetAllTasks.bind(this)}
                    >
                      {LBLRESETALL}
                    </button>
                  </div>
                  </div>
              </div>
            
            </div>
            <div id="norecsplan"
                style={{
                  marginBottom: "0px",
                  width: "calc(100% +  55px)",
                  marginLeft: "-25px",
                }}
              >
                {showWeightage ?
                  teamSFListData.length > 0 ? (
                    <DataTable
                      id="dalalist"
                      columns={teamSFColumns}
                      data={teamSFListData}
                      className="datatable-list"
                      noDataComponent={<EmptyState image={emptyImage} text2={ LBLNORECORDS }/>}
                      style={{
                        border: "0px solid red",
                        backgroundColor: "#fff",
                        marginTop: "0px"
                      }}
                      fixedHeader
                      fixedHeaderScrollHeight={
                        $("#root").height() - 268 + "px" //271
                      }
                     // subHeader="true"
                      subHeaderAlign="left"
                      subHeaderComponent={<Tooltip class="cls_add_expand_collapse"
                        title={""} arrow>
                        <div className="cls_add_expand_collapse" style={{ visibility:"hidden" }}>
                        {/* style={{ visibility: showWeightage ? "visible" : "hidden" */}
                          <img
                            src={this.state.expandFlag ?
                              "../images/steercom/expanded.png" :
                              "../images/steercom/collapsed.png"
                            }
                            className="img_collapse_all"
                            alt=""
                            onClick={this.updateExpandFlagAll.bind(
                              this, this.state.expandFlag
                            )}
                            title={this.state.expandFlag ? "Collapse All" : "Expand All"}
                          ></img>
                        </div>
                      </Tooltip>}
                    />
                  ) : (
                    <div>
                      <Skeleton height={400} />
                    </div>
                  ) :
                  this.state.loading?
                  <div style={{position:"relative",top:"50px"}}>
                      <Skeleton height={400} />
                  </div>
                    :
                  teamSFListData.length > 0 ? (
                    <DataTable
                      id="dalalistAll"
                      columns={teamSFColumns}
                      data={teamSFListData}
                      className="datatable-list"
                      noDataComponent={<EmptyState image={emptyImage} text2={ LBLNORECORDS }/>}
                      style={{
                        marginTop: "10px"
                      }}
                      fixedHeader
                      fixedHeaderScrollHeight={
                        $("#root").height() - 268 + "px" //257
                      }
                      subHeaderAlign="left"
                      subHeaderComponent={<Tooltip class="cls_add_expand_collapse"
                        title={""} arrow>
                        <div className="cls_add_expand_collapse" style={{ visibility: showWeightage ? "visible" : "hidden" }}>
                          <img
                            src={this.state.expandFlag ?
                              "../images/steercom/expanded.png" :
                              "../images/steercom/collapsed.png"
                            }
                            className="img_collapse_all"
                            alt=""
                            onClick={this.updateExpandFlagAll.bind(
                              this, this.state.expandFlag
                            )}
                            title={this.state.expandFlag ? "Collapse All" : "Expand All"}
                          ></img>
                        </div>
                      </Tooltip>}
                    />
                  ) : ( isFilter?
                   <div style={{marginTop:"10%"}}>
                    <EmptyState image={emptyImage} text2={ LBLNORECORDS }/>
                    </div> :
                    <div>
                      <Skeleton height={400} />
                    </div>
                  )}
              </div>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(TaskSetup);

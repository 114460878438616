import React from "react";
import { HttpInterceptor } from "../../../auth/HttpInterceptor";
import steering_committee from "./steeringcommittee.scss";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ToastMessage from "../../../shared/ToastComponent/ToastComponent";
import SteeringCommitteeService from "./steeringcommitteeservice/steeringcommitteeservice";
import SteercomServices from "./steeringcommitteeservice/steercom_services";
import CreateTeam from "./createteam";
import TeamList from "./teamlist";
import TeamMembersList from "./teammemberslist";
import { LiveTvRounded } from "@material-ui/icons"; 
import * as CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64"; 
const $ = require("jquery");

class MailLanding extends React.Component {
  constructor(props) { 
      super(props); 
      this.state = {
          loading:false,
          showtime:5000
      };
 } 
componentDidMount() { 
  this.redirectToPages();
} 

// decryptWithAES = (encparams) => {  
//   encparams = encparams.toString();
//   const passphrase = (process.env.REACT_APP_DCT_KEY).toString();
//   const decrypted = CryptoJS.AES.decrypt(encparams, passphrase); 
//   const originalText = decrypted.toString(CryptoJS.enc.Utf8); 
//   return originalText;
// };
 
redirectToPages = async() => {
  console.log(window.location.search, "test");
  if(window.location.search !== "") { 
    let action_type = "", mailid = "", userid = 0, teamid = 0;
    let queryparams = window.location.search.split("?")[1];
   
    let params = queryparams.split("&");
    teamid = params[1].split("=")[1];
    if(params[1]) userid = params[2].split("=")[1];
    if(params[2]) mailid = params[3].split("=")[1]; 

    action_type = queryparams.indexOf("atm") === 0 ? "Edit Team" :
                  queryparams.indexOf("mtm") === 0 ? "Edit Member" :
                  queryparams.indexOf("stm") === 0 ? "Plan Task" :
                  queryparams.indexOf("ptm") === 0 ? "Progress Task" :
                  queryparams.indexOf("dtm") === 0 ? "Admin DashBoard" : "MyTask";
    let data = { user_id: userid, team_id: teamid, action_type: action_type, mailid: mailid }; 
    if(teamid) { 
      let userrole = this.props.role;
      let res = await SteercomServices.setValidUserDetails(data);
      
      teamid = res.result[0].length > 0 ? teamid : 0;

      if(res.result[0].length === 0) {
        this.showAlert("warning", this.props.labels.labels.LBLWARNINVALIDCALL);
        setTimeout(() => {
          if(userrole == 1) {
            this.redirecttoTeamList(teamid, 3);  
          } else {
            this.redirecttoPlan(teamid, 5);
          } 
        }, 4000); 
        return false;
      } 

      if(queryparams.indexOf("atm") === 0) { 
        this.redirecttoTeamList(teamid, 1);  
      } else if(queryparams.indexOf("mtm") === 0) { 
        this.redirecttoTeamList(teamid, 2);
      } else if(queryparams.indexOf("stm") === 0) {
        this.redirecttoPlan(teamid, 1);
      } else if(queryparams.indexOf("ptm") === 0) {
        this.redirecttoPlan(teamid, 2);
      } else if(queryparams.indexOf("dtm") === 0) {
        this.redirecttoPlan(teamid, 3);
      } else if(queryparams.indexOf("ytm") === 0) {
        this.redirecttoPlan(teamid, 4);
      } else {
        this.redirecttoPlan(teamid, 5);
      }
   } else {
      //this.redirecttoDashBoard()
   }    
  }
}

redirecttoTeamList = (teamid, mode) => {
  let dirpath = "/schome/";
  if (window.location.href.indexOf("/home/") > -1) {
    dirpath = "/home/";
  }
  let teamData, backlinkurl = dirpath + "steercommaillanding"; 
  teamData = mode === 1 ? {team_id: teamid} : {mteam_id: teamid};
  let pathname = dirpath + "steeringcommittee";
  console.log(teamData, "teamData1");
  this.props.history.push({
    pathname: pathname,
    state: {
      backlink: true,
      backlinkurl: backlinkurl, 
      teamData: teamData, 
    }
  });
} 

redirecttoPlan = (teamid, mode) => {
  let dirpath = "/schome/";
  let userrole = this.props.role;
  if (window.location.href.indexOf("/home/") > -1 || userrole > 2) {
    dirpath = "/home/";
  }
  let pathname;
  let backlinkurl = dirpath + "steercommaillanding"; 
  if(mode === 1) {
    pathname = dirpath + "successfactors"; 
  } else if(mode === 2) {
    pathname = dirpath + "successfactorplan"; 
  } else if(mode === 3) {
    pathname = dirpath + "steercomdashboard"; 
  } else if(mode === 4) {
    pathname = dirpath + "steercomuserdashboard"; 
  } else if(mode === 5) {
    pathname = dirpath + "dashboard"; 
  }
  let teamData = { team_id: teamid };
  let successFactorData = { 
    active: 1,
    color: "sf_color1",
    description: "",
    id: "0",
    name: "LBLALL",
    title: "All",
    version: "1"
  };
  console.log(teamData, "teamData");
  this.props.history.push({
    pathname: pathname,
    state: {
      backlink: true,
      backlinkurl: backlinkurl,
      successFactorData: successFactorData, 
      teamData: teamData, 
    }
  });
} 

redirecttoDashBoard=()=>{
  let dirpath = "/schome/"
  if (window.location.href.indexOf("/home/") > -1) {
    dirpath = "/home/"
  }
  let teamData,backlinkurl = dirpath + "steercommaillanding";  
  let pathname =  dirpath + "dashboard"; 
  this.props.history.push({
    pathname: pathname,
    state: {
      backlink: true,
      backlinkurl: backlinkurl, 
      teamData: teamData, 
    }
  });
} 
showAlert = (messageType, message) => {
  this.setState({
    openalert: true,
    messageType: messageType,
    message: message,
  });
}; 
handleCloseAlert = () => {
  this.setState({ openalert: false });
};
handleClose = (xVar, item) => { 
  this.setState({ 
    openalert:false
  }); 
};
render(){ 
  let isloading=true;
  let horizontal = "top", vertical = "center";
     
    return (
        <div className="cls_landing_container">
        <Snackbar
          anchorOrigin={{ horizontal, vertical }}
          key={"top-center"}
          open={this.state.openalert}
          autoHideDuration={this.state.showtime ? this.state.showtime : 5000}
          onClose={this.handleCloseAlert}
          bodyStyle={{ backgroundColor: "teal", color: "coral" }} 
        >
          <Alert
            variant="filled"
            onClose={this.handleClose}
            severity={this.state.messageType}
          >
            {this.state.message}
          </Alert>
        </Snackbar> 
          
        </div>
    )
}
}
 
const mapStateToProps = (state) => {
    return {
      companyId: state.user_info_reducer.user_info.company_id  
    };
  };
  
  export default connect(mapStateToProps, null)(MailLanding);
  
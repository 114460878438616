import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import "./filter.scss";
import styles from "../../admin/pages/user-list/FilterUser.module.scss";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const $ = require("jquery");
class FilterComp extends Component {
  constructor(props) {
    super(props);
    this.searchRef = [];
    this.state = {
      getFilters: props.toFilters,
      singleLength: "",
      multiLength: "",
      singleItemNames: [],
      multiItemNames: [],
      currentId: "",
      selAllMulti: {},
      selAllSingle: {},
      selAllValues: {
        singleItems: [],
        multiItems: [],
        dateItems: [],
      },
      searchParam: '',
      searchData: props.toFilters,
      searchIndex: null,
      showSearchIds: [],
      width: 0,
      height: 0,
      submitValue: {},
      showMore: false,
      toShowsearch: [],
      showMoreArr: []
    };
    let response = this.state.getFilters;
    let singleItemsS = [],
      multiItemsM = [],
      multiname,
      singlename,
      dateItemNames;
    let multiItemsLen = 0,
      singleItemsLen = 0;
    let showrange, drange;
    if (response) {
      multiItemsLen = response.multi ? response.multi.length : 0;
      singleItemsLen = response.single.length;

      for (var x = 0; x < multiItemsLen; x++) {
        multiname = response.multi[x].itemsinfo.itemid;
        multiItemsM[x] = multiname;
        this["multiselectRef" + multiname + "_" + (x + 1)] = React.createRef();
      }

      for (var y = 0; y < singleItemsLen; y++) {
        singlename = response.single[y].itemsinfo.itemid;
        singleItemsS[y] = singlename;
      }
      for (var z = 0; z < singleItemsLen; z++) {
        singlename = response.single[z].itemsinfo.itemid;
        singleItemsS[z] = singlename;
      }

      if (response.dateRange) {
        dateItemNames = response.dateRange[0].itemsinfo.itemid;
        drange = response.dateRange;
        if (drange.length > 0) showrange = "";
        else showrange = "none";
      } else {
        showrange = "none";
      }
    }
  }

  resetValues = () => {
    let groupsM = [],
      groupsS = [];


    this.setState({ selAllMulti: groupsM, selAllSingle: groupsS, submitValue: {} });
    let groups = this.state.selAllValues;
    this.setState({
      selAllValues: {
        singleItems: groupsS,
        multiItems: groupsM,
        showDate1: "none",
        showDate2: "none",
      },
      searchParam: '',
      searchIndex: null,
      showMore: false,
      toShowsearch: [],
      showMoreArr: []
    });
    this.props.handleSubmit({
      singleItems: groupsS,
      multiItems: groupsM,
      showDate1: "none",
      showDate2: "none",
    }
    )
    this.props.resetValues()
  };


  componentDidMount() {
    let response = this.state.getFilters;
    let multiItemsLen = 0, singleItemsLen = 0;
    multiItemsLen = response.multi.length;
    singleItemsLen = response.single.length;
    let groupsM = [],
      groupsS = [],
      singleName,
      multiname;

    let itemsM = [],
      itemsS = [];
    for (var m = 0; m < multiItemsLen; m++) {
      multiname = this.state.getFilters.multi[m].itemsinfo.itemid;
      groupsM[multiname] = [];
      itemsM[m] = multiname;
    }
    for (var m = 0; m < singleItemsLen; m++) {
      singleName = this.state.getFilters.single[m].itemsinfo.itemid;
      groupsS[singleName] = "";
      itemsS[m] = singleName;
    }
    this.setState({
      singles: response.single,
      dateRange: response.dateRange,
      fStatus: response.single.roles,
      groupAll: response.multi,
      helpersLoaded: true,
      multiLength: multiItemsLen,
      singleLength: singleItemsLen,
      multiItemNames: itemsM,
      singleItemNames: itemsS,
      selAllMulti: groupsM,
      selAllSingle: groupsS,
    });
    $("._7ahQImyV4dj0EpcNOjnwA").css({ display: "none" });
    $(".optionContainer li").removeClass("lhyQmCtWOINviMz0WG_gr");

    let showSearchIds = []
    let showSearchArr = []
    let toShowsearch =
    {
      searchId: null,
      showSearch: false
    }

    response.multi.map((itm, idx) => {
      if (JSON.parse(itm.items).length >= 7) {
        showSearchIds.push(itm.itemsinfo.itemid)
        toShowsearch =
        {
          searchId: itm.itemsinfo.itemid,
          showSearch: false
        }
        showSearchArr.push(toShowsearch)
      }
    })
    console.log('showSearchArr ', showSearchArr)
    this.setState({ showSearchIds: showSearchIds, toShowsearch: showSearchArr })
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  onSelect = (event, item, id, type) => {
    let response = this.state.getFilters;
    let multiItemsLen = 0, singleItemsLen = 0;
    multiItemsLen = response.multi.length;
    singleItemsLen = response.single.length;
    let groupsM = [],
      groupsS = [],
      singleName,
      multiname;

    let itemsM = [],
      itemsS = [];
    for (var m = 0; m < multiItemsLen; m++) {
      multiname = this.state.getFilters.multi[m].itemsinfo.itemid;
      groupsM[multiname] = [];
      itemsM[m] = multiname;
    }
    for (var m = 0; m < singleItemsLen; m++) {
      singleName = this.state.getFilters.single[m].itemsinfo.itemid;
      groupsS[singleName] = "";
      itemsS[m] = singleName;
    }
    let obj = {
      multiItems: groupsM,
      singleItems: groupsS,
    }

    let submitObj = Object.keys(this.props.prevFilterValues).length !== 0 ? this.props.prevFilterValues : obj

    let cntrlId;
    let arrIds = [...this.props.selectedMultiArrIds]
    let arrNames = [...this.props.selectedMultiArrNames]

    if (event.target && event.target.id) {
      cntrlId = event.target.id.split("_input")[0];
      this.setState({ currentId: cntrlId });
    }

    if (type === 'single') {
      if (!this.props.singleStatusChecked) {
        submitObj.singleItems['status'] = id
      } else {
        submitObj.singleItems['status'] = submitObj.singleItems['status'] === id ? '' : id
      }
      this.props.handleStatusFilter(id)
      this.props.singleItemStatus(submitObj.singleItems['status'])
    } else {
      if (submitObj.multiItems[id].length) {
        if (!submitObj.multiItems[id].some(itm => itm.id === item.id)) {
          submitObj.multiItems[id].push(item)
          console.log('itemss ', item)
          arrIds.push(item.id)
          arrNames.push(item)
        } else {
          submitObj.multiItems[id] = submitObj.multiItems[id].filter((itm2) => itm2.id !== item.id);
          arrIds = arrIds.filter((item2) => item2 !== item.id);
          arrNames = arrNames.filter((item3) => item3.id !== item.id);
        }
      } else {
        submitObj.multiItems[id].push(item)
        arrIds.push(item.id)
        arrNames.push(item)
      }
    }
    this.props.setSelectedMultiArrIds(arrIds)
    this.props.setSelectedMultiArrNames(arrNames)
    this.setState({ submitValue: submitObj })
    if (this.state.width > 720) {
      this.props.handleSubmit(submitObj);
    }
  };

  handleMobileSubmit = () => {
    this.props.openFilterModal()
    this.props.handleSubmit(this.state.submitValue);
  }
  setSearchParams = (e, index, name) => {
    this.setState({ searchParam: e.target.value, searchIndex: index })
  }
  handleShowMore = (name) => {
    let showMoreArr = [...this.state.showMoreArr]
    if (showMoreArr.length) {
      if (!showMoreArr.includes(name)) {
        showMoreArr.push(name);
      } else {
        showMoreArr = showMoreArr.filter(itm => itm !== name)
      }
      this.setState({ showMoreArr: showMoreArr })

    } else {
      showMoreArr.push(name);
      this.setState({ showMoreArr: showMoreArr })
    }
  }
  handleFilterSearch = (data) => {
    if (this.searchRef.length) {
      let userVerifySearch = this.searchRef[0] ? this.searchRef[0].value : ""
      let roleSearch = this.searchRef[1] ? this.searchRef[1].value : ""
      let locationSearch = this.searchRef[2] ? this.searchRef[2].value : ""
      let deptSearch = this.searchRef[3] ? this.searchRef[3].value : ""
      let groupSearch = this.searchRef[4] ? this.searchRef[4].value : ""
      let shiftSearch = this.searchRef[5] ? this.searchRef[5].value : ""
      let plantSearch = this.searchRef[6] ? this.searchRef[6].value : ""
      let filteredData = this.state.searchData.multi
        .map((e, idx) => {
          if (userVerifySearch) {
            return {
              ...e,
              items: idx === 0 ? (typeof e.items === 'string' ? JSON.parse(e.items) : e.items).filter((subItem) => subItem
                .name
                .toLowerCase()
                .includes(userVerifySearch.toLowerCase())) : typeof e.items === 'string' ? e.items : JSON.stringify(e.items)
            }
          } else {
            return e
          }

        })
        .map((e1, idx1) => {
          if (roleSearch) {
            return {
              ...e1,
              items: idx1 === 1 ? (typeof e1.items === 'string' ? JSON.parse(e1.items) : e1.items).filter((subItem) => subItem
                .name
                .toLowerCase()
                .includes(roleSearch.toLowerCase())) : typeof e1.items === 'string' ? e1.items : JSON.stringify(e1.items)
            }
          } else {
            return e1;
          }

        })
        .map((e2, idx2) => {
          if (locationSearch) {
            return {
              ...e2,
              items: idx2 === 2 ? (typeof e2.items === 'string' ? JSON.parse(e2.items) : e2.items).filter((subItem) => subItem
                .name
                .toLowerCase()
                .includes(locationSearch.toLowerCase())) : typeof e2.items === 'string' ? e2.items : JSON.stringify(e2.items)
            }
          } else {
            return e2;
          }
        })
        .map((e3, idx3) => {
          if (deptSearch) {
            return {
              ...e3,
              items: idx3 === 3 ? (typeof e3.items === 'string' ? JSON.parse(e3.items) : e3.items).filter((subItem) => subItem
                .name
                .toLowerCase()
                .includes(deptSearch.toLowerCase())) : typeof e3.items === 'string' ? e3.items : JSON.stringify(e3.items)
            }
          } else {
            return e3;
          }

        })
        .map((e4, idx4) => {
          if (groupSearch) {
            return {
              ...e4,
              items: idx4 === 4 ? (typeof e4.items === 'string' ? JSON.parse(e4.items) : e4.items).filter((subItem) => subItem
                .name
                .toLowerCase()
                .includes(groupSearch.toLowerCase())) : typeof e4.items === 'string' ? e4.items : JSON.stringify(e4.items)
            }
          } else {
            return e4;
          }
        })
        .map((e5, idx5) => {
          if (shiftSearch) {
            return {
              ...e5,
              items: idx5 === 5 ? (typeof e5.items === 'string' ? JSON.parse(e5.items) : e5.items).filter((subItem) => subItem
                .name
                .toLowerCase()
                .includes(shiftSearch.toLowerCase())) : typeof e5.items === 'string' ? e5.items : JSON.stringify(e5.items)
            }
          } else {
            return e5;
          }
        })
        .map((e6, idx6) => {
          if (plantSearch) {
            return {
              ...e6,
              items: idx6 === 6 ? (typeof e6.items === 'string' ? JSON.parse(e6.items) : e6.items).filter((subItem) => subItem
                .name
                .toLowerCase()
                .includes(plantSearch.toLowerCase())) : typeof e6.items === 'string' ? e6.items : JSON.stringify(e6.items)
            }
          } else {
            return e6;
          }
        })
        .map(newlist => {
          return {
            ...newlist,
            items: typeof newlist.items !== 'string' ? JSON.stringify(newlist.items) : newlist.items
          }
        })
      return filteredData;
    } else {
      return data;
    }

  }
  render() {
    return (
      <>
        <div className={styles.filterHeader}>
          <div
            className={styles.filterIcon}
            title={this.props.labels.labels.LBLFILTER}
            data-toggle="modal"
            data-target="#myModal"
            // style={{
            //   width: "25px",
            //   zoom: "1.1",
            //   cursor: "pointer",
            //   marginLeft: "10px",
            //   display: 'flex',
            //   alignItems: 'center',
            //   justifyContent: 'center'
            // }}
          >
            <i
              data-target="#myModal"
              onClick={this.props.openFilterModal}
              class="fa fa-filter"
              aria-hidden="true"
            ></i>
          </div>

          <div className={styles.filterLabel}>{this.props.labels.labels.LBLFILTER}</div>
          <div className={styles.filterClose} onClick={this.props.openFilterModal}>
            <i class="fa fa-times" aria-hidden="true"></i>
          </div>
        </div>

        <div className={styles.userListFilterContent}>
          <div className={styles.resetFilter} onClick={this.resetValues}>
            <span>{this.props.labels.labels['LBLRSTFILTER']}</span>
          </div>
          {this.state.getFilters && this.state.getFilters.single.map((single, index) => {
            return (
              <div className={styles.selector} key={'single_' + index}>
                <div className={styles.selectorLabelCnt} onClick={this.props.setSingleDropDown} >
                  <div className={styles.selectorLabel}>{
                    this.props.labels.labels[
                    single.itemsinfo.itemname
                      .replace(/ +/g, "")
                    ]
                  }</div>
                  <span className={styles.selectorArrow}>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                </div>
                <div id="selections" className={this.props.showSingleDropDown ? styles.selectionsShow : styles.selections}>
                  {single.items.map((itm, idx) => {
                    return (
                      <FormControlLabel key={'single_chk_' + idx} control={<Checkbox id={
                        single.itemsinfo.itemid + "_" + (index + 1)
                      }
                        value={itm.id}
                        name={itm.id}
                        checked={this.props.singleStatusChecked === itm.id ? true : false}
                        onChange={(event) => this.onSelect(event, null, itm.id, 'single')}
                      />}
                        label={
                          <div class={styles.flCheckboxLabel}>
                            <span> {single.itemsinfo.trans
                              ? this.props.labels.labels[
                              itm.name
                              ]
                              : itm.name + "\n"}</span>
                          </div>
                        }
                      />
                    )
                  })}
                </div>
              </div>
            )
          })}
          {this.props.currentPage !== "recordCompletion" && <div className={styles.divider} />}
          {this.state.getFilters && this.handleFilterSearch(this.state.getFilters.multi).map((group, index) => {
            const numberOfItems = this.state.showMore ? (JSON.parse(group.items)).length : 7
            return (
              <div className={styles.selector} key={'group_' + index}>
                <div className={styles.selectorLabelCnt} onClick={() => this.props.handleOpenDropDown(index)}>
                  <div className={styles.selectorLabel}>
                    {
                      this.props.labels.labels[
                        group.itemsinfo.itemname
                      ] ? this.props.labels.labels[
                      group.itemsinfo.itemname
                      ] : group.itemsinfo.itemname
                    }
                  </div>
                  <span className={styles.selectorArrow}>
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                </div>
                <div id="selections" className={this.props.openDropDown.indexOf(index) === -1 ? styles.selections : styles.selectionsShow}>
                  {this.state.showSearchIds.includes(group.itemsinfo.itemid) ?
                    <div class={styles.search}>
                      <input
                        ref={(el) => this.searchRef[index] = el}
                        type="text" className={styles.searchTerm} placeholder="Search"
                        onChange={(e) => this.setSearchParams(e, index, group.itemsinfo.itemname)}
                      />
                      <button type="submit" className={styles.searchButton}>
                        <i class="fa fa-search" style={{ color: '#a6a6a6' }}></i>
                      </button>
                    </div> : null}

                  {group.items && (JSON.parse(group.items)).length ? (JSON.parse(group.items)).slice(0, !this.state.showMoreArr.includes(group.itemsinfo.itemname) ? 7 : (JSON.parse(group.items)).length).map((itm, idx) => {
                    return (
                      <FormControlLabel key={'group_check_' + idx} control={<Checkbox
                        id={group.itemsinfo.itemid + "_" + (index + 1)}
                        value={itm.id}
                        name={itm.id}
                        checked={this.props.selectedMultiArrIds.includes(itm.id) ? true : false}
                        onChange={(event) => this.onSelect(event, itm, group.itemsinfo.itemid, 'multi')}
                      />}
                        label={<div class={styles.flCheckboxLabel}>
                          <span title={this.props.labels.labels[itm.name] ? this.props.labels.labels[itm.name] : itm.name}>{this.props.labels.labels[itm.name] ? this.props.labels.labels[itm.name] : itm.name}</span>
                        </div>} />
                    )
                  })
                    : <div className={styles.noOptions}><span>{this.props.labels.labels.LBLNOOPTION}</span></div>
                  }

                  {this.state.showSearchIds.includes(group.itemsinfo.itemid) && (JSON.parse(group.items)).length > 7 ?
                    <span style={{ color: '#007bff', marginLeft: '25px' }} onClick={() => this.handleShowMore(group.itemsinfo.itemname)}>{this.state.showMoreArr.includes(group.itemsinfo.itemname) ? '- less' : '+ ' + (JSON.parse(group.items).length - 7) + ' more'} </span>
                    : null}
                </div>
                <div className={styles.divider} />

              </div>

            )
          })}
          {this.state.width < 720 ?
            <button
              type="button"
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
              onClick={() => this.handleMobileSubmit()}

              className="btn btn-primary"
            >
              {this.props.labels.labels.LBLAPPLYFILTER}
            </button> : null}
        </div>
      </>
    );
  }
}

FilterComp = reduxForm({
  form: "filtercomp", // a unique name for this form
})(FilterComp);

export default connect()(FilterComp);

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

import { HttpInterceptor } from '../../../auth/HttpInterceptor';

class ScMailServices {
    async getTemplates() {
        const response = await HttpInterceptor.get('templates/types');
        return response.data;
    }
    async addTemplates(data) {
        const response = await HttpInterceptor.post('addtemplates', data);
        return response.data;
    }
    async getTemplatesList(templateId) {
        let url = '';
        if (templateId != null) {
            url = 'templates?tempId=' + templateId;
        } else {
            url = 'templates';
        }
        const response = await HttpInterceptor.get(url);
        return response.data;
    }
    async changeTemplateStatus(queryinfo) {
        const response = await HttpInterceptor.put('changetemplatestatus', queryinfo);
        return response.data;
    }
    async updateTemplates(queryinfo) {
        const response = await HttpInterceptor.put('updatetemplates', queryinfo);
        return response.data;
    }
}



export default new ScMailServices();


import React from 'react';
import styles from './RysRecords.module.scss';
import rysrecordsicon from '../../../../assets/images/rysrecordsicon.png';
import rysRecordsProfileIcon from '../../../../assets/images/rysRecorsProfileIcon.svg';
import RysReportBarChart from "./rysReportBarChart";

const RysConversationRecords = (props) => {
    return (
        <div className={styles.RysRecordsCtn}>
            <div className={styles.RysRecordsInnerCtn}>
                <div className={styles.RysRecordsInnerLeftCtn} >
                    <h1 className={styles.title}>
                        {props.titleOne}
                    </h1>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={rysrecordsicon} alt='' />
                        <span className={styles.totalRecordCount}>{props.totalRecordCount}</span>
                    </div>
                    <hr className={styles.horizontalLine } style={{marginTop:"10px",marginBottom:"10px"}}/>
                    <h1 className={styles.title}>
                        {props.titleTwo}
                    </h1>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={rysRecordsProfileIcon} alt='' />
                        <span className={styles.totalSupervisorCount}>{props.totalSupervisorsCount}</span>
                    </div>
                </div>
                <div className={styles.verticalLine}></div>
                <div className={styles.RysRecordsInnerRightCtn}>
                  <RysReportBarChart chartXAxisTitle = {props.chartXAxisTitle} chartXAxisCount= {props.chartXAxisCount} colPeriod = {props.colPeriod} labels={props.labels}/>
                </div>
            </div>
        </div>
    );
}

export default RysConversationRecords;
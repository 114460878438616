import React, { Component } from "react";
import ReportServices from "../report_services";
import GroupServices from "../../group-list/group_services";
import orderBy from "lodash/orderBy";
import CustomFilter from "../../../components/reportfilter/containers/CustomFilter";
import { connect } from "react-redux";
import UsersService from "../../user-list/UsersService";
import Loader from "../../../components/loader/Loader";
import Skeleton from "react-loading-skeleton";
import xobj_safestartsurvey from "../../survey/surveyfile.json"; //"./surveyfile.json";
import { classList } from "dynamic-class-list";
import $ from 'jquery';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField} from "@material-ui/core";
import { Modal } from "react-bootstrap";
import emptyImage from '../../../../assets/images/learnerprogress_emptyst.png'
import EmptyState from "../../../../common_components/empty_state/Empty_state";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import moment from "moment";

highchartsGantt(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
//var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var obj_safestartsurvey = xobj_safestartsurvey["obj_safestartsurvey"];
//const moment = require("moment");
let objFilterData = {};
let skel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const filemime = {
  csv: "attachment/csv;charset=utf-8,",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  pdf: "application/pdf;base64,",
};
const REFS = {};
let cnst_showResData = []
let g_labels = {}
const cstLabels = {
  "LBLCOMPARISON": "Comparison",
  "LBLVIEW": "View",
  "LBLRESPONSESOF": "Responses Of",
  "LBLCHOICES": "Choices",
  "LBLPERCENT": "Percent",
  "LBLCOUNT": "Count",
  "LBLPRETRAINING": "Pre Training"
}
class SSSReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showQItems: {},
      showResData: "LBLHOURLY,LBLSUPERVISOR,LBLMANAGER,LBLALLROLE",
      view1: { "id": "PRE", "name": "" },
      view2: { "id": "ALL", "name": "All" },
      tabTypeIndex: 0,
      tabType: "summary",
      graphType: "bar",
      cur_mod_index: 0,
      page_question_count: 19,
      cur_mod_page_index: 0,
      passvars: "",
      qanswers: JSON.parse(JSON.stringify(obj_safestartsurvey)),
      open: false,
      loading: true,
      userData: [],
      user_roles: [],
      pageState: "SSS_D1",
      roles: [{ role_id: 1, role_name: "learner" }],
      loadSSS_D1_List: 0,
      orgSSS_D1_List: [],
      SSS_D1_List: [],
      SSS_D1_SummaryData: {

      },
      sel_D1_Data: {},
      SSS_D1_GPH_SelStateId: "1",
      filterDataStatus: false,
      filterDataLoaded: 0,
    };
    this.SSS_D1_Filter = React.createRef();
  }
  rysStatusTranslation = {
    1: this.props.labels.labels.LBLRUSHING,
    3: this.props.labels.labels.LBLFATIGUE,
    2: this.props.labels.labels.LBLFRUSTRATION,
    4: this.props.labels.labels.LBLCOMPLACENCY,
  };
  loadpage(a, x) {
    if (a === "SSS_D1") {
      this.setState({loadSSS_D1_List: 1});
    }

    if (this.state.loadSSS_D1_List === 1) {
      this.setState({
        loading: false,
      });
    }
  }
  getLabelX = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        return xfield;
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        return xfield.label;
      }
    }
  };
  getLabel = (xfield) => {
    if (typeof xfield === "string") {
      if (this.props.labels.labels.hasOwnProperty(xfield)) {
        return this.props.labels.labels[xfield];
      } else {
        if (cstLabels.hasOwnProperty(xfield)) {
          return cstLabels[xfield]
        } else {
          return xfield;
        }
      }
    } else {
      if (this.props.labels.labels.hasOwnProperty(xfield.label)) {
        return this.props.labels.labels[xfield.label].replace(
          "$$$",
          xfield.val
        );
      } else {
        if (cstLabels.hasOwnProperty(xfield.label)) {
          return cstLabels[xfield.label].replace(
            "$$$",
            xfield.val
          );
        } else {
          return xfield.label;
        }
      }
    }
  };
  componentDidMount() {
    obj_safestartsurvey = xobj_safestartsurvey["obj_safestartsurvey"];
    let data = {};
    data.date1 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    data.date2 = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000);
    data.sdate1 = this.getDateFormat(data.date1);
    data.sdate2 = this.getDateFormat(data.date2);
    data.offsetTime = this.getOffsetTime();
    //this.getGroupTypes();
    let getData = {
      language: this.props.language,
    };
    this.getFilterDatas();
    UsersService.usersHelpers(getData).then((response) => {
      let groups = response.groups;
      //{group_type_id: "GP_1", lbl_GP_1: "Location", Active: "1"}
      let iresponse = [];
      let ipgroups = {};
      for (let i = 0; i < groups.length; i++) {
        if (typeof groups[i].group_info == "string") {
          groups[i].group_info = JSON.parse(groups[i].group_info);
        }
        if (typeof groups[i].groups_by_type == "string") {
          groups[i].groups_by_type = JSON.parse(groups[i].groups_by_type);
        }
        groups[i].groups_by_type = groups[i].groups_by_type || [];
        let iobj = { Active: "1" };
        iobj["group_type_id"] = groups[i].group_type_id;
        iobj["lbl_" + groups[i].group_type_id] = this.getLabel(
          groups[i].group_info["lbl_" + groups[i].group_type_id]
        );
        iresponse.push(iobj);
        let igroups = [];
        for (let x = 0; x < groups[i].groups_by_type.length; x++) {
          igroups.push({
            id: groups[i].groups_by_type[x].group_id,
            value: groups[i].groups_by_type[x].group_name,
          });
        }
        ipgroups[groups[i].group_type_id] = igroups;
      }
      this.setState(
        {
          groupTypeNames: iresponse,
          groups: ipgroups,
        },
        this.loadpage("group", iresponse)
      );
    });
    this.getSSS_D1_List(data);
  }
  async getFilterDatas() {
    let data = {};
    data.report = "LCP_D1";
    let group_types = await require("../../../../json_files/group_types.json");
    ReportServices.getFilterDatas(data).then((response) => {
      // console.log("response.result", response.result);
      let xgroup_types = response.result[0];
      let labels;
      let groups = {};
      for (var i = 0; i < xgroup_types.length; i++) {
        if (this.props.language_code === xgroup_types[i].language_code) {
          labels = JSON.parse(xgroup_types[i].labels).group_labels;
        }
      }
      if (labels && labels.length > 0) {
        for (const grouptype of group_types) {
          if (labels[grouptype.group_type_id]) {
            grouptype["lbl_" + grouptype.group_type_id] =
              labels[grouptype.group_type_id]["lbl_" + grouptype.group_type_id];
            grouptype["Active"] = labels[grouptype.group_type_id]["Active"];
            groups[grouptype.group_type_id] = [];
            groups["obj" + grouptype.group_type_id] = {};
          } else {
            grouptype["Active"] = "0";
          }
        }
      }
      let xgroups = response.result[1]; 
      for (var j = 0; j < xgroups.length; j++) {
        if (
          groups[xgroups[j].group_type_id] &&
          !groups["obj" + xgroups[j].group_type_id][xgroups[j].value]
        ) {
          groups["obj" + xgroups[j].group_type_id][xgroups[j].value] = 1;
          groups[xgroups[j].group_type_id].push(xgroups[j]);
        }
      }
      let courses = response.result[2];
      let users = response.result[3];
      let courseunits = response.result[4];
      this.setState(
        {
          filterDataLoaded: 1,
          pageState: "SSS_D1",
          courses: courses,
          userNames: users,
          courseunits: courseunits,
        },
        this.loadpage("SSS_D1")
      );
    });
  }

  getSSS_D1_INDVDL_Export(data) {
    data.report = "SSS_D1";
    data.jsonOptionIds = [];
    obj_safestartsurvey.map((objmod, modindex) => {
      if (objmod.questions) {
        objmod.questions.map((questions, questionindex) => {
        return  questions.options.map((item, index) => {
           return data.jsonOptionIds.push(item.sid);
          });
        });
      }
      return null;
    });
    data.qanswers = this.state.qanswers;
    data.labels = this.props.labels
    ReportServices.getSSS_D1_INDVDL_Export(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        //console.log("TTTTTTTTTTTTTTTTTTTT,fileUrl", fileUrl)
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob)
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName = this.props.labels.labels.LBLSSEXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }
  getSSS_D1_Export(data) {
    data.report = "SSS_D1";
    ReportServices.getSSS_D1_Export(data).then((response) => {
      REFS.F1.onExportDone();
      window.saveFile = function (bytesBase64, mimeType, fileName) {
        var universalBOM = "";
        if (data.type === "csv") {
          universalBOM = "%EF%BB%BF"
          bytesBase64 = encodeURI(bytesBase64)
        }
        var fileUrl = "data:" + mimeType + universalBOM + bytesBase64;
        //console.log("TTTTTTTTTTTTTTTTTTTT,fileUrl", fileUrl)
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            //console.log("TTTTTTTTTTTTTTTTTTTT,blob", blob)
            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob, { type: mimeType });
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
      };
      const dateStamp = moment(new Date()).format("DDMMYYYY");
      const timeStamp = new Date().getTime();
      let xmimeType = filemime[data.type];
      let xfileName = this.props.labels.labels.LBLSSEXPORT + '_' + dateStamp + "." + data.type;
      let xbytesBase64 = response;

      window.saveFile(xbytesBase64, xmimeType, xfileName);
    });
  }

  getDateFormat = (xDate) => {
    let yyyy = xDate.getFullYear();
    let mm =
      xDate.getMonth() >= 9
        ? xDate.getMonth() + 1
        : "0" + (xDate.getMonth() + 1);
    let dd = xDate.getDate() > 9 ? xDate.getDate() : "0" + xDate.getDate();
    return yyyy + "-" + mm + "-" + dd;
  };
  getSSS_D1_Summary = (xresult, ydata) => {
    // console.log("getSSS_D1_SummaryQQQQQQQQQQQQQQQQQ", ydata);
    let xsummaryData = {
      LBLHOURLY: { PRE: 0, POST: 0, PREINDEX: -1, POSTINDEX: -1, ROLEVALUE: 1 },
      LBLSUPERVISOR: { PRE: 0, POST: 0, PREINDEX: -1, POSTINDEX: -1, ROLEVALUE: 2 },
      LBLMANAGER: { PRE: 0, POST: 0, PREINDEX: -1, POSTINDEX: -1, ROLEVALUE: 3 },
      LBLALLROLE: { PRE: 0, POST: 0, PREINDEX: -1, POSTINDEX: -1, ROLEVALUE: 4 },
    };
    for (var i = 0; i < xresult.length; i++) {
      if (xresult[i].launchmode == 0) {
        xsummaryData[xresult[i].role].PRE = xsummaryData[xresult[i].role].PRE + xresult[i].counts
        xsummaryData["LBLALLROLE"].PRE = xsummaryData["LBLALLROLE"].PRE + xresult[i].counts
        xsummaryData[xresult[i].role].PREINDEX = i
      } else {
        xsummaryData[xresult[i].role].POST = xsummaryData[xresult[i].role].POST + xresult[i].counts
        xsummaryData["LBLALLROLE"].POST = xsummaryData["LBLALLROLE"].POST + xresult[i].counts
        xsummaryData[xresult[i].role].POSTINDEX = i
      }
    }
    // console.log("getSSS_D1_Summary SOURCE", xresult)
    // console.log("getSSS_D1_Summary RESULT", xsummaryData)
    return xsummaryData;
  };
  getSSS_D1_List(data) {
    data.report = "SSS_D1";

    //console.log("getSSS_D1_List data", data);
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;

    data.jsonOptionIds = [];

    obj_safestartsurvey.map((objmod, modindex) => {
      if (objmod.questions) {
        objmod.questions.map((questions, questionindex) => {
        return  questions.options.map((item, index) => {
        return   data.jsonOptionIds.push(item.sid);
          });
        });
      }
      return null;
    });

    ReportServices.getSSS_D1_List(data).then((response) => {
      //console.log("getSSS_D1_List!!!!!!!!11", response.result[3])
      let result = response.result[5];
      let xsummaryData = {};
      xsummaryData.graphData = this.getSSS_D1_Summary(result, data);
      xsummaryData.records = result.length;

      this.setState(
        {
          pageState: "SSS_D1",
          orgSSS_D1_List: result,
          SSS_D1_List: result,
          SSS_D1_SummaryData: xsummaryData,
          sel_D1_Data: data,
          filterDataStatus: true,
        },
        //setTimeout(() => {
        this.loadpage("SSS_D1"),
        this.getSSS_D1_INDVDL_List(data)
        //}, 2000)
      );
    });
  }
  getSSS_D1_INDVDL_List(data) {
    data.report = "SSS_D1_INDVDL";

    //console.log("getSSS_D1_List data", data);
    data.dateformat = this.props.dateformat;
    data.timeformat = this.props.timeformat;

    data.jsonOptionIds = [];

    obj_safestartsurvey.map((objmod, modindex) => {
      if (objmod.questions) {
        objmod.questions.map((questions, questionindex) => {
        return questions.options.map((item, index) => {
           return data.jsonOptionIds.push(item.sid);
          });
        });
        return null
      }
      return null;
    });

    ReportServices.getSSS_D1_INDVDL_List(data).then((response) => {
      //console.log("getSSS_D1_List!!!!!!!!11", response.result[3])
      let result = response.result[5];
      let xsummaryData = {};
      //xsummaryData.graphData = this.getSSS_D1_Summary(result, data);
      xsummaryData.records = result.length;

      this.setState(
        {
          pageState: "SSS_D1",
          orgSSS_D1_INDVDL_List: result,
          SSS_D1_INDVDL_List: result,
          SSS_D1_INDVDL_SummaryData: xsummaryData,
          sel_D1_Data: data,
          filterDataStatus: true,
          SSS_D1_INDVDL_index: 0
        },
      );
    });
  }
  applyFilterCondition = (orgresult, data) => {
    if (objFilterData.availableSSS_D1Fields) {
      let xobj = objFilterData.availableSSS_D1Fields;
      let result = [];
      for (var i = 0; i < orgresult.length; i++) {
        if (this.checkFilterCondition(xobj, orgresult[i]) === true) {
          result.push(orgresult[i]);
        }
      }
      let xsummaryData = {};
      xsummaryData.graphData = this.getSSS_D1_Summary(result, data);
      xsummaryData.records = result.length;
      this.setState(
        {
          SSS_D1_List: result,
          SSS_D1_SummaryData: xsummaryData,
        },
        this.loadpage("SSS_D1")
      );
    } else {
      let result = orgresult;
      let xsummaryData = {};
      xsummaryData.graphData = this.getSSS_D1_Summary(result, data);
      xsummaryData.records = result.length;
      this.setState(
        {
          SSS_D1_List: result,
          SSS_D1_SummaryData: xsummaryData,
        },
        this.loadpage("SSS_D1")
      );
    }
  };
  showSSS_D1 = () => {
    this.setState({
      pageState: "SSS_D1",
    });
  };

  getGroupTypes = async () => {
    GroupServices.getGroupTypeLabels(this.props.language_code).then(
      (response) => {
        this.setState(
          {
            groupTypeNames: response,
          },
          this.loadpage("group", response)
        );
      }
    );
  };
  getTimeforDays(x) {
    return x * 24 * 60 * 60 * 1000;
  }
  getSSS_D1_Columns = (x) => {
    let xrArr = [];
    let cDate = new Date();
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(7))); // this.getTimeforDays(240)));
    xrArr.push(new Date(cDate.getTime() - this.getTimeforDays(1)));
    let columns = [
      {
        name: this.props.labels.labels.LBLDATE,
        selector: "created_on",
        filter: {
          type: "date",
          showField: true,
          checked: true,
          render: "server",
          condition: {
            name: "Date",
            operator: "LAST7DAYS", //"BETWEEN",
            value: xrArr.slice(),
            operatorText: this.props.labels.labels.LBLLAST7DAYS, //this.props.labels.labels.LBLCUSTOMRANGE
          },
          exportcell: "_created_on_dateformat",
        },
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <div>
              <label className="show-sm">
                {this.props.labels.labels.LBLDATE}
              </label>
              <span className="rle-brdr">{row.created_on_format}</span>
            </div>
          );
        },
      },
    ];

    let xcolumns = [];
    let response = this.state.groupTypeNames || [];

    for (var i = 0; i < response.length; i++) {
      if (response[i].Active === "1") {
        xcolumns.push({
          name: response[i]["lbl_" + response[i].group_type_id],
          selector: response[i].group_type_id,
          filter: { type: "string", showField: true, checked: true },
          filterData: this.state.groups[response[i].group_type_id],
          exportcellproperty: { order: 200 + i },
          sortable: true,
          compact: true,
          tableIndex: 0,
          cell: (row, condition) => {
            return row[condition.selector];
          },
        });
      }
    }

    columns.splice.apply(columns, [1, 0].concat(xcolumns));
    return columns; //JSON.parse(JSON.stringify(columns))
  };

  getFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    let xcols = [];
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }

    if (mode === "SSS_D1") {
      let xobj = objFilterData.availableSSS_D1Fields;
      if (xobj) {
        for (var j = 0; j < xcols.length; j++) {
          xcols[j].filter = xobj[xcols[j].name];
        }
      }
    }
    return xcols;
  };
  getResetFilterColumns = (mode, ycols) => {
    let cols = JSON.parse(JSON.stringify(ycols));
    // console.log("getFilterColumns", mode, cols);
    let xcols = [];
    // console.log("getFilterColumns-111", mode, xcols);
    for (var i = 0; i < cols.length; i++) {
      if (cols[i].filter) {
        cols[i].selector = cols[i].selector;
        cols[i].displayName = cols[i].name;
        xcols.push(cols[i]);
      }
    }
    // console.log("getFilterColumns-222", mode, xcols, cols);
    return xcols;
  };
  customSort = (rows, field, direction) => {
    const handleField = (row) => {
      //console.log(row[field])
      if (row[field] && row[field] !== 1 && row[field] !== 0) {
        return row[field].toLowerCase();
      }

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  };
  checkFilterCondition = (filters, data) => {
    // console.log("checkFilterCondition 0", filters, data);
    for (var i in filters) {
      if (filters[i].condition && filters[i].checked) {
        let dvalue = data[filters[i].selector];
        let fdvalue = [];
        let fselector = filters[i].selector.split(",");
        for (var xi = 0; xi < fselector.length; xi++) {
          fdvalue.push(data[fselector[xi]]);
        }
        dvalue = fdvalue.join(" ");
        let cvalue = filters[i].condition.value;
        let xr = false;
        let dtime;
        let ctime;
        let ctime1;
        let ctime2;
        if (filters[i].condition.operator === "NOCONDITION") {
          xr = true;
        } else {
          if (filters[i].type === "string") {

            if (filters[i].condition.operator === "CONTAINS") {
              if (dvalue == null) {
                xr = false;
              } else if (
                dvalue.toLowerCase().split(cvalue.toLowerCase()).length > 1
              ) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "DOES_NOT_CONTAIN") {
              if (dvalue == null) {
                xr = false;
              } else if (
                dvalue.toLowerCase().split(cvalue.toLowerCase()).length === 1
              ) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue.toLowerCase() === cvalue.toLowerCase()) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue.toLowerCase() !== cvalue.toLowerCase()) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "MULTI_EQUALS") {
              let rxr = 0;
              if (cvalue === "") {
                xr = true;
              } else {
                for (let xi = 0; xi < cvalue.length; xi++) {
                  if (dvalue.toLowerCase() === cvalue[xi].value.toLowerCase()) {
                    rxr = 1;
                    break;
                  }
                }
                if (filters[i].multi) {
                  for (let xi = 0; xi < cvalue.length; xi++) {
                    if (
                      dvalue
                        .toLowerCase()
                        .indexOf(cvalue[xi].value.toLowerCase()) > -1
                    ) {
                      rxr = 1;
                      break;
                    }
                  }
                }
                if (filters[i].label) {
                  for (let xi = 0; xi < cvalue.length; xi++) {
                    if (
                      (
                        this.props.labels.labels[
                        "LBL" + dvalue.toUpperCase()
                        ] || ""
                      )
                        .toLowerCase()
                        .indexOf(cvalue[xi].value.toLowerCase()) > -1
                    ) {
                      rxr = 1;
                      break;
                    }
                  }
                }
                xr = rxr === 0 ? false : true;
              }
            }
          } else if (filters[i].type === "number") {
            if (filters[i].condition.operator === "EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue === cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue !== cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "GREATER_THAN") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > cvalue) {
                xr = true;
              }
            } else if (
              filters[i].condition.operator === "GREATER_THAN_EQUALS"
            ) {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue >= cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN_EQUALS") {
              if (dvalue == null) {
                xr = false;
              } else if (dvalue <= cvalue) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "BETWEEN") {
              var min = Math.min.apply(Math, cvalue),
                max = Math.max.apply(Math, cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue > min && dvalue < max) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_BETWEEN") {
              var minTwo = Math.min.apply(Math, cvalue),
                maxTwo = Math.max.apply(Math, cvalue);
              if (dvalue == null) {
                xr = false;
              } else if (dvalue < minTwo || dvalue > maxTwo) {
                xr = true;
              }
            }
          } else if (filters[i].type === "date") {
            var xgetTime = function (x) {
              let dx = new Date(x);
              dx.setHours(0);
              dx.setMinutes(0);
              dx.setMinutes(0);
              dx.setSeconds(0);
              dx.setMilliseconds(0);
              return dx.getTime();
            };
            let dayscondition =
              "BETWEEN,LAST7DAYS,LAST30DAYS,THISMONTH,LASTMONTH";
            let daycondition = "TODAY,YESTERDAY,EQUALS";
            if (daycondition.indexOf(filters[i].condition.operator) > -1) {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue === null || cvalue === null) {
                xr = false;
              } else if (dtime === ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "GREATER_THAN") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue === null) {
                xr = false;
              } else if (dtime > ctime) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "LESS_THAN") {
              dtime = xgetTime(dvalue);
              ctime = xgetTime(cvalue);
              if (dvalue === null) {
                xr = false;
              } else if (dtime < ctime) {
                xr = true;
              }
            } else if (
              dayscondition.indexOf(filters[i].condition.operator) > -1
            ) {
              dtime = xgetTime(dvalue);
              ctime1 = xgetTime(cvalue[0]);
              ctime2 = xgetTime(cvalue[1]);
              var minThree = Math.min.apply(Math, [ctime1, ctime2]);
              var maxThree = Math.max.apply(Math, [ctime1, ctime2]);
              // console.log("min,max,dvalue,", min, max, dtime);
              if (dvalue === null) {
                xr = false;
              } else if (dtime >= minThree && dtime <= maxThree) {
                xr = true;
              }
            } else if (filters[i].condition.operator === "NOT_BETWEEN") {
              dtime = xgetTime(dvalue);
              ctime1 = xgetTime(cvalue[0]);
              ctime2 = xgetTime(cvalue[1]);
              var minFour = Math.min.apply(Math, [ctime1, ctime2]),
                maxFour = Math.max.apply(Math, [ctime1, ctime2]);
              if (dvalue === null) {
                xr = false;
              } else if (dtime < minFour || dtime > maxFour) {
                xr = true;
              }
            }
          }
        }
        if (xr === false) {
          return false;
        }
      }
    }
    return true;
  };

  onExport = (type, id, conditions) => {
    //console.log("onExport", type, id, conditions)
    if (id === "F1") {
      this.setFilterFields("F1", conditions);
      let data = { type: type, columns: objFilterData.availableSSS_D1Fields };
      data.filtercolumns = conditions;
      let index = 0;
      let xcondition = conditions[index].filter.condition;
      // console.log("SSSSSSSSSSSSSSSSSSSS", xcondition);
      let dt1, dt2;
      if (typeof xcondition.value[0] == "string") {
        let arrdt = xcondition.value[0].split("T")[0].split("-");
        xcondition.value[0] = new Date(
          arrdt[0],
          parseInt(arrdt[1]) - 1,
          arrdt[2]
        );
      }
      if (xcondition.value[1] && typeof xcondition.value[1] == "string") {
        let arrdt = xcondition.value[1].split("T")[0].split("-");
        xcondition.value[1] = new Date(
          arrdt[0],
          parseInt(arrdt[1]) - 1,
          arrdt[2]
        );
      }
      if (xcondition.value[0] && xcondition.value[1]) {
        dt1 = new Date(xcondition.value[0].getTime());
        dt2 = new Date(xcondition.value[1].getTime());
      } else {
        dt1 = new Date(xcondition.value.getTime());
        dt2 = new Date(xcondition.value.getTime());
      }
      data.date1 = new Date(
        dt1.getFullYear(),
        dt1.getMonth(),
        dt1.getDate(),
        12,
        0,
        0,
        0
      );
      data.date2 = new Date(
        dt2.getFullYear(),
        dt2.getMonth(),
        dt2.getDate(),
        12,
        0,
        0,
        1
      );
      data.sdate1 = this.getDateFormat(data.date1);
      data.sdate2 = this.getDateFormat(data.date2);
      data.offsetTime = this.getOffsetTime();
      data.dateformat = this.props.dateformat;
      data.timeformat = this.props.timeformat;
      //this.getSSS_D1_Export(data);
      this.getSSS_D1_INDVDL_Export(data);
    }
  };
  setFilterFields = (id, conditions) => {
    //console.log("setFilterFields", id, conditions)
    let xobj = {};
    for (var i = 0; i < conditions.length; i++) {
      xobj[conditions[i].name] = conditions[i].filter;
      xobj[conditions[i].name].selector = conditions[i].selector;
      if (conditions[i].exportcell) {
        xobj[conditions[i].name].exportcell = conditions[i].exportcell;
      }
      if (conditions[i].exportfunction) {
        xobj[conditions[i].name].exportfunction = conditions[i].exportfunction;
      }
    }
    if (id === "F1") {
      //this.setState({
      objFilterData.availableSSS_D1Fields = xobj;
    }
  };
  getOffsetTime = () => {
    return "053000";
  };
  onFilterChange = (id, conditions) => {
    // console.log("onFilterChangeeeeee2", conditions);
    this.setFilterFields("F1", conditions);
    let data = { type: "web", columns: objFilterData.availableSSS_D1Fields };
    data.filtercolumns = conditions;

    // console.log("onFilterChangeeeeee", conditions);
    //let xobj = {};
    this.setFilterFields("F1", conditions);
    let index = 0;
    if (id === "F1") {
      let xcondition = conditions[index].filter.condition;
      if (conditions[index].filter.type === "date") {
        // console.log("SSSSSSSSSSSSSSSSSSSS", xcondition);
        let dt1, dt2;
        if (typeof xcondition.value[0] == "string") {
          let arrdt = xcondition.value[0].split("T")[0].split("-");
          xcondition.value[0] = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2]
          );
        }
        if (xcondition.value[1] && typeof xcondition.value[1] == "string") {
          let arrdt = xcondition.value[1].split("T")[0].split("-");
          xcondition.value[1] = new Date(
            arrdt[0],
            parseInt(arrdt[1]) - 1,
            arrdt[2]
          );
        }
        if (xcondition.value[0] && xcondition.value[1]) {
          dt1 = new Date(xcondition.value[0].getTime());
          dt2 = new Date(xcondition.value[1].getTime());
        } else {
          dt1 = new Date(xcondition.value.getTime());
          dt2 = new Date(xcondition.value.getTime());
        }

        data.date1 = new Date(
          dt1.getFullYear(),
          dt1.getMonth(),
          dt1.getDate(),
          12,
          0,
          0,
          0
        );
        data.date2 = new Date(
          dt2.getFullYear(),
          dt2.getMonth(),
          dt2.getDate(),
          12,
          0,
          0,
          1
        );
        data.sdate1 = this.getDateFormat(data.date1);
        data.sdate2 = this.getDateFormat(data.date2);
        data.offsetTime = this.getOffsetTime();
        this.getSSS_D1_List(data);
      } else {
        this.applyFilterCondition();
      }
    }
  };
  renderPartDetailResponse = (xcc_item, xprogress) => {
    //return <>XXXXXXXXXXXXX</>
    let xx = ""
    let prcnt = 0
    let sprcnt = ""
    if (xprogress == true) {
      xx = "BBB"
    } else {
    }

    if (!xcc_item || (xcc_item && xcc_item.label == "")) {
      ///console.log("AAAAAAAAAAAAAA",xcc_item)
      return ""
    }
    if (xx == "") {
      prcnt = (xcc_item.count / xcc_item.tcount) * 100
      if (isNaN(prcnt) == false && prcnt > 0) {
        sprcnt = ((parseInt(prcnt) == parseFloat(prcnt).toFixed(2)) ? parseInt(prcnt) : parseFloat(prcnt).toFixed(2)) + "%"
      }
    }
    return <div className="col" style={{ display: "inline-block", padding: "6px", borderRight: "0px solid rgba(68,197,195,.28)" }}>
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-sm-6">{sprcnt} </div>
        <div className="col-sm-6">{sprcnt != "" ? xcc_item.count : 0}</div>
      </div>
    </div>
  }

  renderDetailResponse = (options, tvObj, qitem, xComments) => {
    const { labels } = this.props;
    let showResData = this.state.showResData
    let showQItems = this.state.showQItems[qitem.sid]
    return <><div className="row" style={{ color: "blue", marginTop: "20px", marginBottom: "20px", padding: "10px" }}>
      {(showQItems && showQItems == "yes") ?
        <div
          style={{ float: "right", cursor: "pointer" }}
          className="showDetailText"
          onClick={(e) => {
            let showQItems = this.state.showQItems
            showQItems[qitem.sid] = "no"
            this.setState({
              showQItems: showQItems
            });
          }}
        >
          {labels.labels.LBLHIDEDETAILEDDATA}
        </div>
        :
        <div
          style={{ float: "right", cursor: "pointer" }}
          className="showDetailText"
          onClick={(e) => {
            let showQItems = this.state.showQItems
            showQItems[qitem.sid] = "yes"
            this.setState({
              showQItems: showQItems
            });
          }}
        >
          {labels.labels.LBLSHOWDETAILEDDATA}
        </div>
      }
      {xComments.map((c_item, c_index) => {
        return <>
          {
            (c_item.user_inputs.length > 0) ? (<>
              <div
                style={{ float: "right", cursor: "pointer", padding: "0px 20px" }}
                className="showDetailText"
                onClick={(e) => {
                  this.setState({
                    showmodal: true,
                    responseUserInputs: {
                      item: qitem,
                      userInputs: c_item.user_inputs
                    }
                  });
                }}
              >
                {labels.labels.LBLSHOWOTHERSSPECIFIED}
              </div>
            </>) : (<></>)
          }
        </>
      })}
    </div>
      {(showQItems && showQItems == "yes") ?
        <div style={{ marginTop: "20px", padding: "10px" }}>
          <div className="row cls_rept_subtitle" style={{ padding: "0px", backgroundColor: "rgba(68,197,195,.08)", borderLeft: "1px solid rgba(68,197,195,.28)", borderTop: "1px solid rgba(68,197,195,.28)", borderBottom: "1px solid rgba(68,197,195,.28)" }}>
            <div className="col" style={{ display: "inline-block", textAlign: "left", padding: "6px", borderRight: "1px solid rgba(68,197,195,.28)" }}>{this.getLabel("LBLCHOICES")}</div>
            {
              showResData.indexOf("LBLHOURLY") > -1 ? <>
                <div className="col" style={{ display: "inline-block", borderRight: "1px solid rgba(68,197,195,.28)" }}>
                  <div className="row" style={{ justifyContent: "center" }}> {labels.labels.LBLHOURLY}</div>
                  <div className="row">
                    <div className="col-sm-6">{this.getLabel("LBLPERCENT")} </div>
                    <div className="col-sm-6">{this.getLabel("LBLCOUNT")}</div>
                  </div>
                </div>
              </> : <></>
            }

            {
              showResData.indexOf("LBLSUPERVISOR") > -1 ? <>
                <div className="col" style={{ display: "inline-block", padding: "6px", borderRight: "1px solid rgba(68,197,195,.28)" }}>
                  <div className="row" style={{ justifyContent: "center" }}> {labels.labels.LBLSUPERVISOR}</div>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-sm-6">{this.getLabel("LBLPERCENT")}</div>
                    <div className="col-sm-6">{this.getLabel("LBLCOUNT")}</div>
                  </div>
                </div>
              </> : <></>
            }

            {
              showResData.indexOf("LBLMANAGER") > -1 ? <>
                <div className="col" style={{ display: "inline-block", padding: "6px", borderRight: "1px solid rgba(68,197,195,.28)" }}>
                  <div className="row" style={{ justifyContent: "center" }}> {labels.labels.LBLMANAGER}</div>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-sm-6">{this.getLabel("LBLPERCENT")}</div>
                    <div className="col-sm-6">{this.getLabel("LBLCOUNT")}</div>
                  </div>
                </div>
              </> : <></>
            }

            {
              showResData.split(",").length > 1 ? <>
                <div className="col" style={{ display: "inline-block", padding: "6px", borderRight: "1px solid rgba(68,197,195,.28)" }}>
                  <div className="row" style={{ justifyContent: "center" }}> {labels.labels.LBLALLROLE}</div>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-sm-6">{this.getLabel("LBLPERCENT")}</div>
                    <div className="col-sm-6">{this.getLabel("LBLCOUNT")}</div>
                  </div>
                </div>
              </> : <></>
            }

          </div>
          {
            options.data.map((c_item, c_index) => {
              return <div className="row" style={{ padding: "0px", borderLeft: "0px solid rgba(68,197,195,.28)", borderTop: "0px solid rgba(68,197,195,.28)", borderBottom: "0px solid rgba(68,197,195,.28)" }}>
                <div className="col" style={{ display: "inline-block", padding: "6px", textAlign: "left", borderRight: "0px solid rgba(68,197,195,.28)" }}>{c_item.legendText}</div>
                {
                  c_item.dataPoints.map((cc_item, cc_index) => {
                    return this.renderPartDetailResponse(cc_item, false)
                  })
                }
                {showResData.split(",").length > 1 ? this.renderPartDetailResponse(c_item.thdataPoints, false) : ""}
              </div>
            })
          }
        </div>
        : <></>
      }
    </>
  }
  renderCanvasComponent = (qitem, mode) => {
    const { labels } = this.props;
    let qanswersids = [];
    let dataPoints0 = [];
    let dataPoints1 = [];
    // let answerType = qitem.answertype.mode;
    let graphtype = "column" // answerType == "single" ? "pie" : "column";
    let options0 = {};
    let options1 = {};
    if (graphtype == "pie") {
      obj_safestartsurvey.map((objmod, modindex) => {
        if (objmod.questions) {
          objmod.questions.map((questions, questionindex) => {
            if (qitem.sid == questions.sid) {
              questions.options.map((item, index) => {
                qanswersids.push(item.sid);
                dataPoints0.push({
                  y: this.state.SSS_D1_List[0][item.sid],
                  label: labels.labels[item.label],
                });
                if (this.state.SSS_D1_List[1]) {
                  dataPoints1.push({
                    y: this.state.SSS_D1_List[1][item.sid],
                    label: labels.labels[item.label],
                  });
                }
              });
              return null;
            }
            return null;
          });
        }
        return null;
      });
      options0 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPRETRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        data: [
          {
            type: "pie",
            //startAngle: 75,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            toolTipContent: "<b>{label}</b>: {y}",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}",
            dataPoints: dataPoints0,
          },
        ],
      };
      options1 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPOSTTRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        data: [
          {
            type: "pie",
            //startAngle: 75,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            toolTipContent: "<b>{label}</b>: {y}",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}",
            dataPoints: dataPoints1,
          },
        ],
      };
      return (
        <div className="col-xl-12">
          <div
            className="col-xl-6"
            style={{ display: "inline-block", padding: "0" }}
          >
            {/* <CanvasJSChart options={options0} /> */}
            <HighchartsReact highcharts={Highcharts}
              options={options0}
              ref={this.chart}
            />
          </div>
          <div
            className="col-xl-6"
            style={{ display: "inline-block", padding: "0" }}
          >
            {/* <CanvasJSChart options={options1} /> */}
            <HighchartsReact highcharts={Highcharts}
              options={options1}
              ref={this.chart}
            />
          </div>
        </div>
      );
    } else if (graphtype == "xcolumn") {
      obj_safestartsurvey.map((objmod, modindex) => {
        if (objmod.questions) {
          objmod.questions.map((questions, questionindex) => {
            if (qitem.sid == questions.sid) {
              let i = 1;
              questions.options.map((item, index) => {
                qanswersids.push(item.sid);
                dataPoints0.push({
                  x: i,
                  y: this.state.SSS_D1_List[0][item.sid],
                  label: labels.labels[item.label],
                });
                if (this.state.SSS_D1_List[1]) {
                  dataPoints1.push({
                    x: i,
                    y: this.state.SSS_D1_List[1][item.sid],
                    label: labels.labels[item.label],
                  });
                }
                i++;
              });
              return null;
            }
            return null;
          });
        }
        return null;
      });
      options0 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPRETRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        data: [
          {
            type: "column",
            //startAngle: 75,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            toolTipContent: "<b>{label}</b>: {y}",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}",
            dataPoints: dataPoints0,
          },
        ],
      };
      options1 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPOSTTRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        data: [
          {
            type: "column",
            //startAngle: 75,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            toolTipContent: "<b>{label}</b>: {y}",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}",
            dataPoints: dataPoints1,
          },
        ],
      };
      return (
        <div className="col-xl-12">
          <div
            className="col-xl-6"
            style={{ display: "inline-block", padding: "0" }}
          >
            {/* <CanvasJSChart options={options0} /> */}
            <HighchartsReact highcharts={Highcharts}
              options={options0}
              ref={this.chart}
            />
          </div>
          <div
            className="col-xl-6"
            style={{ display: "inline-block", padding: "0" }}
          >
            {/* <CanvasJSChart options={options1} /> */}
            <HighchartsReact highcharts={Highcharts}
              options={options1}
              ref={this.chart}
            />
          </div>
        </div>
      );
    } else if (graphtype == "barx") {
      let tvdataPoints0 = {
        "LBLHOURLY": { tcount: 0, count: 0 },
        "LBLSUPERVISOR": { tcount: 0, count: 0 },
        "LBLMANAGER": { tcount: 0, count: 0 },
        "LBLALLROLE": { tcount: 0, count: 0 }
      }
      let tvdataPoints1 = {
        "LBLHOURLY": { tcount: 0, count: 0 },
        "LBLSUPERVISOR": { tcount: 0, count: 0 },
        "LBLMANAGER": { tcount: 0, count: 0 },
        "LBLALLROLE": { tcount: 0, count: 0 }
      }
      obj_safestartsurvey.map((objmod, modindex) => {
        if (objmod.questions) {
          objmod.questions.map((questions, questionindex) => {
            if (qitem.sid == questions.sid) {
              questions.options.map((item, index) => {
                qanswersids.push(item.sid);
                dataPoints0.push({
                  x: index + 1,
                  y: this.state.SSS_D1_List[0][item.sid],
                  label: labels.labels[item.label],
                });
                if (this.state.SSS_D1_List[1]) {
                  dataPoints1.push({
                    x: index + 1,
                    y: this.state.SSS_D1_List[1][item.sid],
                    label: labels.labels[item.label],
                  });
                }
              });
              return null;
            }
            return null;
          });
        }
        return null;
      });
      options0 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPRETRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        data: [
          {
            type: "column",
            //startAngle: 75,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            toolTipContent: "<b>{label}</b>: {y}",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}",
            dataPoints: dataPoints0,
          },
        ],
      };
      options1 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPOSTTRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        data: [
          {
            type: "column",
            //startAngle: 75,
            indexLabelFormatter: function (e) {
              if (e.dataPoint.y === 0) return "";
              else return e.dataPoint.y;
            },
            toolTipContent: "<b>{label}</b>: {y}",
            showInLegend: "true",
            legendText: "{label}",
            indexLabelFontSize: 16,
            indexLabel: "{label} - {y}",
            dataPoints: dataPoints1,
          },
        ],
      };
      return (
        <div className="col-xl-12">
          <div
            className="col-xl-6"
            style={{ display: "inline-block", padding: "0" }}
          >
            <HighchartsReact highcharts={Highcharts}
              options={options0}
              ref={this.chart}
            />
          </div>
          <div
            className="col-xl-6"
            style={{ display: "inline-block", padding: "0" }}
          >
            <HighchartsReact highcharts={Highcharts}
              options={options1}
              ref={this.chart}
            />
          </div>
        </div>
      );
    } else if (graphtype == "stackbar") {
      let xDatas0 = []
      let xDatas1 = []
      let xComments0 = []
      let xComments1 = []
      let tvdataPoints0 = {
        "LBLHOURLY": { tcount: 0, count: 0 },
        "LBLSUPERVISOR": { tcount: 0, count: 0 },
        "LBLMANAGER": { tcount: 0, count: 0 },
        "LBLALLROLE": { tcount: 0, count: 0 }
      }
      let tvdataPoints1 = {
        "LBLHOURLY": { tcount: 0, count: 0 },
        "LBLSUPERVISOR": { tcount: 0, count: 0 },
        "LBLMANAGER": { tcount: 0, count: 0 },
        "LBLALLROLE": { tcount: 0, count: 0 }
      }
      obj_safestartsurvey.map((objmod, modindex) => {
        if (objmod.questions) {
          objmod.questions.map((questions, questionindex) => {
            if (qitem.sid == questions.sid) {
              questions.options.map((item, index) => {
                qanswersids.push(item.sid);
                let xODatas0 = {
                  type: "stackedBar",
                  toolTipContent: labels.labels[item.label] + " - {y} %<br /> Value: {count} Out Of {tcount}",
                  legendText: labels.labels[item.label],
                  showInLegend: "true",
                  dataPoints: [],

                  thdataPoints: {
                    tcount: 0,
                    count: 0
                  }
                }
                let xODatas1 = {
                  type: "stackedBar",
                  toolTipContent: labels.labels[item.label] + " - {y} %<br /> Value: {count} Out Of {tcount}",
                  legendText: labels.labels[item.label],
                  showInLegend: "false",
                  dataPoints: [],

                  thdataPoints: {
                    tcount: 0,
                    count: 0
                  }
                }
                let xOComments0 = {
                  Text: labels.labels[item.label],
                  user_inputs: []
                }
                let xOComments1 = {
                  Text: labels.labels[item.label],
                  user_inputs: []
                }

                for (let i = 0; i < this.state.SSS_D1_List.length; i++) {
                  if (this.state.SSS_D1_List[i].role == "LBLHOURLY" || this.state.SSS_D1_List[i].role == "LBLSUPERVISOR" || this.state.SSS_D1_List[i].role == "LBLMANAGER") {
                    if (parseInt(this.state.SSS_D1_List[i].launchmode) == 0) {
                      xODatas0.dataPoints.push(
                        {
                          y: parseInt((this.state.SSS_D1_List[i][item.sid] * 100) / this.state.SSS_D1_List[i].counts),
                          label: labels.labels[this.state.SSS_D1_List[i].role],
                          tcount: this.state.SSS_D1_List[i].counts,
                          count: this.state.SSS_D1_List[i][item.sid],
                          role_value: this.state.SSS_D1_List[i].role_value
                        }
                      )
                      xODatas0.thdataPoints.count = xODatas0.thdataPoints.count + this.state.SSS_D1_List[i][item.sid]
                      xODatas0.thdataPoints.tcount = xODatas0.thdataPoints.tcount + this.state.SSS_D1_List[i].counts

                      tvdataPoints0[this.state.SSS_D1_List[i].role].count = tvdataPoints0[this.state.SSS_D1_List[i].role].count + this.state.SSS_D1_List[i][item.sid]
                      tvdataPoints0[this.state.SSS_D1_List[i].role].tcount = tvdataPoints0[this.state.SSS_D1_List[i].role].tcount

                      tvdataPoints0["LBLALLROLE"].count = tvdataPoints0["LBLALLROLE"].count + this.state.SSS_D1_List[i][item.sid]
                      tvdataPoints0["LBLALLROLE"].tcount = tvdataPoints0["LBLALLROLE"].tcount

                      if (item.type == "user_input" && this.state.SSS_D1_List[i][item.sid + "_userval"]) {
                        let xs = this.state.SSS_D1_List[i][item.sid + "_userval"].split(";~:~;")
                        for (let j = 0; j < xs.length; j++) {
                          if (xs[j] && xs[j] != "") {
                            xOComments0.user_inputs.push(xs[j])
                          }
                        }
                      }

                    } else {
                      xODatas1.dataPoints.push(
                        {
                          y: parseInt((this.state.SSS_D1_List[i][item.sid] * 100) / this.state.SSS_D1_List[i].counts),
                          label: labels.labels[this.state.SSS_D1_List[i].role],
                          tcount: this.state.SSS_D1_List[i].counts,
                          count: this.state.SSS_D1_List[i][item.sid],
                          role_value: this.state.SSS_D1_List[i].role_value
                        },
                      )
                      xODatas1.thdataPoints.count = xODatas1.thdataPoints.count + this.state.SSS_D1_List[i][item.sid]
                      xODatas1.thdataPoints.tcount = xODatas1.thdataPoints.tcount + this.state.SSS_D1_List[i].counts

                      tvdataPoints1[this.state.SSS_D1_List[i].role].count = tvdataPoints1[this.state.SSS_D1_List[i].role].count + this.state.SSS_D1_List[i][item.sid]
                      tvdataPoints1[this.state.SSS_D1_List[i].role].tcount = tvdataPoints1[this.state.SSS_D1_List[i].role].tcount + this.state.SSS_D1_List[i].counts

                      tvdataPoints1["LBLALLROLE"].count = tvdataPoints1["LBLALLROLE"].count + this.state.SSS_D1_List[i][item.sid]
                      tvdataPoints1["LBLALLROLE"].tcount = tvdataPoints1["LBLALLROLE"].tcount

                      if (item.type == "user_input" && this.state.SSS_D1_List[i][item.sid + "_userval"]) {
                        let xs = this.state.SSS_D1_List[i][item.sid + "_userval"].split(";~:~;")
                        for (let j = 0; j < xs.length; j++) {
                          if (xs[j] && xs[j] != "") {
                            xOComments1.user_inputs.push(xs[j])
                          }
                        }
                      }
                    }
                  }

                }

                xODatas0.dataPoints.sort((a, b) => {
                  return a.role_value - b.role_value;
                });
                xODatas1.dataPoints.sort((a, b) => {
                  return a.role_value - b.role_value;
                });
                xDatas0.push(xODatas0)
                xDatas1.push(xODatas1)
                xComments0.push(xOComments0)
                xComments1.push(xOComments1)
              });
              return null;
            }
            return null;
          });
        }
        return null;
      });

      options0 = {
        exportEnabled: false,
        animationEnabled: true,
        // height: 300,
        title: {
          text: this.props.labels.labels.LBLPRETRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        axisY: {
          gridThickness: 0,
          interval: 10,
          maximum: 100,
        },
        data: xDatas0
      };
      options1 = {
        exportEnabled: false,
        animationEnabled: true,
        // height: 300,
        title: {
          text: this.props.labels.labels.LBLPOSTTRAINING,
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Roboto, sans-serif",
        },
        titleFontSize: 14,
        axisY: {
          gridThickness: 0,
          interval: 10,
          maximum: 100,
        },
        // dataPointMaxWidth: 20,
        data: xDatas1
      };
      let optionx = {
        title: {
          text: "Evening Sales of a Restaurant"
        },
        data: [
          {
            type: "stackedBar",
            toolTipContent: "option1: - {y} ",
            legendText: "option1",
            showInLegend: "true",
            dataPoints: [
              { y: 20, label: "role1x", name: "role1" },
              { y: 40, label: "role2x", name: "role2" },
              { y: 60, label: "role3x", name: "role3" }
            ]
          },
          {
            type: "stackedBar",
            toolTipContent: "option2: - {y} ",
            legendText: "option2",
            showInLegend: "true",
            dataPoints: [
              { y: 25, label: "role1x", name: "role1" },
              { y: 45, label: "role2x", name: "role2" },
              { y: 65, label: "role3x", name: "role3" }
            ]
          },
          {
            type: "stackedBar",
            toolTipContent: "option3: - {y} ",
            legendText: "option3",
            showInLegend: "true",
            dataPoints: [
              { y: 10, label: "role1x", name: "role1" },
              { y: 10, label: "role2x", name: "role2" },
              { y: 10, label: "role3x", name: "role3" }
            ]
          },
          {
            type: "stackedBar",
            toolTipContent: "option4: - {y} ",
            legendText: "option4",
            showInLegend: "true",
            dataPoints: [
              { y: 13, label: "role1x", name: "role1" },
              { y: 45, label: "role2x", name: "role2" },
              { y: 22, label: "role3x", name: "role3" }
            ]
          }
        ]
      }
      return (
        <>
          <div className="row col-xl-12">
            <div
              className="col-xl-12"
              style={{ display: "inline-block", padding: "0", textAlign: "center" }}
            >
              <div

                style={{ display: "inline-block", padding: "0", width: "80%" }}
              >
                <HighchartsReact highcharts={Highcharts}
                  options={options0}
                  ref={this.chart}
                />
              </div>
              {this.renderDetailResponse(options0, tvdataPoints0, qitem, xComments0)}
            </div>
            <div
              className="col-xl-12"
              style={{ display: "inline-block", padding: "0" }}
            >
              <div

                style={{ display: "inline-block", padding: "0", width: "80%" }}
              >
                {/* <CanvasJSChart options={options1} /> */}
                <HighchartsReact highcharts={Highcharts}
                  options={options1}
                  ref={this.chart}
                />
              </div>

              {this.renderDetailResponse(options1, tvdataPoints1, qitem, xComments1)}
            </div>
          </div>
        </>
      );
    } else if (graphtype == "column") {
      let xDatas0 = []
      let xDatas1 = []
      let xComments0 = []
      let xComments1 = []
      let tvdataPoints0 = {
        "LBLHOURLY": { tcount: 0, count: 0, dataPoints: [] },
        "LBLSUPERVISOR": { tcount: 0, count: 0, dataPoints: [] },
        "LBLMANAGER": { tcount: 0, count: 0, dataPoints: [] },
        "LBLALLROLE": { tcount: 0, count: 0, dataPoints: [] }
      }
      let tvdataPoints1 = {
        "LBLHOURLY": { tcount: 0, count: 0, dataPoints: [] },
        "LBLSUPERVISOR": { tcount: 0, count: 0, dataPoints: [] },
        "LBLMANAGER": { tcount: 0, count: 0, dataPoints: [] },
        "LBLALLROLE": { tcount: 0, count: 0, dataPoints: [] }

      }
      let showResData = this.state.showResData
      cnst_showResData = this.state.showResData.split(",")
      g_labels["LBLHOURLY"] = this.props.labels.labels.LBLHOURLY
      g_labels["LBLSUPERVISOR"] = this.props.labels.labels.LBLSUPERVISOR
      g_labels["LBLMANAGER"] = this.props.labels.labels.LBLMANAGER
      g_labels["LBLALLROLE"] = this.props.labels.labels.LBLALLROLE
      obj_safestartsurvey.map((objmod, modindex) => {
        if (objmod.questions) {
          objmod.questions.map((questions, questionindex) => {
            if (qitem.sid == questions.sid) {
              questions.options.map((item, index) => {
                qanswersids.push(item.sid);
                let xODatas0 = {
                  type: "column",
                  toolTipContent: labels.labels[item.label] + " - {y} %<br /> Value: {count} Out Of {tcount}",
                  legendText: labels.labels[item.label],
                  showInLegend: "true",
                  dataPoints: [],

                  thdataPoints: {
                    tcount: 0,
                    count: 0
                  }
                }
                let xODatas1 = {
                  type: "column",
                  toolTipContent: labels.labels[item.label] + " - {y} %<br /> Value: {count} Out Of {tcount}",
                  legendText: labels.labels[item.label],
                  showInLegend: "false",
                  dataPoints: [],

                  thdataPoints: {
                    tcount: 0,
                    count: 0
                  }
                }

                let xODatas0Dummy = {
                  type: "column",
                  toolTipContent: "ZZZZ" + labels.labels[item.label] + " - {y} %<br /> Value: {count} Out Of {tcount}",
                  legendText: "",
                  //showInLegend: false,
                  color: "white",
                  dataPoints: [],


                }
                let xODatas1Dummy = {
                  type: "column",
                  toolTipContent: "QQQQ" + labels.labels[item.label] + " - {y} %<br /> Value: {count} Out Of {tcount}",
                  legendText: "",
                  //showInLegend: false,
                  color: "white",
                  dataPoints: [],


                }
                let xOComments0 = {
                  Text: labels.labels[item.label],
                  user_inputs: []
                }
                let xOComments1 = {
                  Text: labels.labels[item.label],
                  user_inputs: []
                }

                let sumData = this.state.SSS_D1_SummaryData.graphData
                let axisX = 0
                tvdataPoints0["LBLALLROLE"].count = 0
                tvdataPoints0["LBLALLROLE"].tcount = 0
                tvdataPoints1["LBLALLROLE"].count = 0
                tvdataPoints1["LBLALLROLE"].tcount = 0
                for (let xFld in sumData) {
                  let role_value = sumData[xFld].ROLEVALUE
                  if (showResData.indexOf(xFld) > -1) {
                    axisX++
                    let tcount = 0, count = 0//, sid = item.sid, label = item.label

                    if (sumData[xFld].PREINDEX > -1) { // Data present for the Role xFld
                      let i = sumData[xFld].PREINDEX;
                      tcount = this.state.SSS_D1_List[i].counts
                      count = this.state.SSS_D1_List[i][item.sid]
                      xODatas0.thdataPoints.count = xODatas0.thdataPoints.count + count
                      xODatas0.thdataPoints.tcount = xODatas0.thdataPoints.tcount + tcount

                      tvdataPoints0[xFld].count = tvdataPoints0[xFld].count + count
                      tvdataPoints0[xFld].tcount = tcount

                      tvdataPoints0["LBLALLROLE"].count = tvdataPoints0["LBLALLROLE"].count + count
                      tvdataPoints0["LBLALLROLE"].tcount = tvdataPoints0["LBLALLROLE"].tcount + tcount
                      if (item.type == "user_input" && this.state.SSS_D1_List[i][item.sid + "_userval"]) {
                        let xs = this.state.SSS_D1_List[i][item.sid + "_userval"].split(";~:~;")
                        for (let j = 0; j < xs.length; j++) {
                          if (xs[j] && xs[j] != "") {
                            xOComments0.user_inputs.push(xs[j])
                          }
                        }
                      }
                    }
                    if (xFld === "LBLALLROLE" && this.state.view1.id === "PREPOST") {
                      xODatas0.dataPoints.push(
                        {
                          x: axisX,
                          y: parseInt((tvdataPoints0["LBLALLROLE"].count * 100) / tvdataPoints0["LBLALLROLE"].tcount),
                          label: labels.labels[item.label],
                          tcount: tvdataPoints0["LBLALLROLE"].tcount,
                          count: tvdataPoints0["LBLALLROLE"].count,
                          role_value: role_value, // this.state.SSS_D1_List[i].role_value,
                          sid: item.sid
                        }
                      )
                      xODatas0Dummy.dataPoints.push(
                        {
                          x: axisX,
                          y: 0,
                          label: "",
                          tcount: 0,
                          count: 0,
                          role_value: role_value,//this.state.SSS_D1_List[i].role_value,
                          sid: item.sid
                        }
                      )
                    } else if (xFld !== "LBLALLROLE" && this.state.view1.id === "PRE") {
                      xODatas0.dataPoints.push(
                        {
                          x: axisX,
                          y: parseInt((count * 100) / tcount),
                          label: labels.labels[item.label],
                          tcount: tcount,
                          count: count,
                          role_value: role_value, // this.state.SSS_D1_List[i].role_value,
                          sid: item.sid
                        }
                      )
                      xODatas0Dummy.dataPoints.push(
                        {
                          x: axisX,
                          y: 0,
                          label: "",
                          tcount: 0,
                          count: 0,
                          role_value: role_value,//this.state.SSS_D1_List[i].role_value,
                          sid: item.sid
                        }
                      )
                    }

                    tcount = 0;
                    count = 0;//, sid = item.sid, label = item.label
                    if (sumData[xFld].POSTINDEX > -1) { // Data present for the Role xFld
                      let i = sumData[xFld].POSTINDEX;
                      tcount = this.state.SSS_D1_List[i].counts
                      count = this.state.SSS_D1_List[i][item.sid]
                      xODatas1.thdataPoints.count = xODatas1.thdataPoints.count + count
                      xODatas1.thdataPoints.tcount = xODatas1.thdataPoints.tcount + tcount

                      tvdataPoints1[xFld].count = tvdataPoints1[xFld].count + count
                      tvdataPoints1[xFld].tcount = tcount

                      tvdataPoints1["LBLALLROLE"].count = tvdataPoints1["LBLALLROLE"].count + count
                      tvdataPoints1["LBLALLROLE"].tcount = tvdataPoints1["LBLALLROLE"].tcount + tcount

                      if (item.type == "user_input" && this.state.SSS_D1_List[i][item.sid + "_userval"]) {
                        let xs = this.state.SSS_D1_List[i][item.sid + "_userval"].split(";~:~;")
                        for (let j = 0; j < xs.length; j++) {
                          if (xs[j] && xs[j] != "") {
                            xOComments1.user_inputs.push(xs[j])
                          }
                        }
                      }
                    }
                    if (xFld === "LBLALLROLE" && this.state.view1.id === "PREPOST") {
                      xODatas1.dataPoints.push(
                        {
                          x: axisX,
                          y: parseInt((tvdataPoints1["LBLALLROLE"].count * 100) / tvdataPoints1["LBLALLROLE"].tcount),
                          label: labels.labels[item.label],
                          tcount: tvdataPoints1["LBLALLROLE"].tcount,
                          count: tvdataPoints1["LBLALLROLE"].count,
                          role_value: role_value, // this.state.SSS_D1_List[i].role_value,
                          sid: item.sid
                        }
                      )
                      xODatas1Dummy.dataPoints.push(
                        {
                          x: axisX,
                          y: 0,
                          label: "",
                          tcount: 0,
                          count: 0,
                          role_value: role_value,//this.state.SSS_D1_List[i].role_value,
                          sid: item.sid
                        }
                      )
                    } else if (xFld !== "LBLALLROLE" && this.state.view1.id === "POST") {
                      xODatas1.dataPoints.push(
                        {
                          x: axisX,
                          y: parseInt((count * 100) / tcount),
                          label: labels.labels[item.label],
                          tcount: tcount,
                          count: count,
                          role_value: role_value, // this.state.SSS_D1_List[i].role_value,
                          sid: item.sid
                        }
                      )
                      xODatas1Dummy.dataPoints.push(
                        {
                          x: axisX,
                          y: 0,
                          label: "",
                          tcount: 0,
                          count: 0,
                          role_value: role_value,//this.state.SSS_D1_List[i].role_value,
                          sid: item.sid
                        }
                      )
                    }
                  } else {

                  }
                }
                xODatas0.dataPoints.sort((a, b) => {
                  return a.sid - b.sid && b.role_value - a.role_value;
                });
                xODatas1.dataPoints.sort((a, b) => {
                  return a.sid - b.sid && b.role_value - a.role_value;
                });
                xDatas0.push(xODatas0)
                xDatas0.push(xODatas0Dummy)
                xDatas1.push(xODatas1)
                xDatas1.push(xODatas1Dummy)
                xComments0.push(xOComments0)
                xComments1.push(xOComments1)
              });
            }
            return null;
          });
        }
        return null;
      });
      let xwidth = $(".main-content").width() - 200
      let dtpwidth = (xwidth / (cnst_showResData.length * xDatas0.length)) > 60 ? 60 : (xwidth / (cnst_showResData.length * xDatas0.length))
      if (this.state.view1.id === "PREPOST") {
        dtpwidth = (xwidth / (2.5 * xDatas0.length)) > 60 ? 60 : (xwidth / (2.5 * xDatas0.length))
      }
      options0 = {
        exportEnabled: false,
        animationEnabled: true,
        title: {
          text: this.props.labels.labels.LBLPRETRAINING,
          fontSize: 16,
          fontFamily: "'helvetica neue', helvetica, arial",
          fontColor: "rgba(0, 0, 0, 0.59)",
          fontWeight: "bold",
        },
        dataPointWidth: dtpwidth,
        axisY: {
          maximum: 100,
          lineColor: "rgba(0, 0, 0, 0.12)",
          gridColor: "rgba(0, 0, 0, 0.12)",
          tickColor: "rgba(0, 0, 0, 0.12)",
          labelFontColor: "rgba(0, 0, 0, 0.32)",
          fontFamily: "'helvetica neue', helvetica, arial",
          fontColor: "rgba(0, 0, 0, 0.59)",
          suffix: "%",
        },
        axisX: {
          tickThickness: dtpwidth * xDatas0.length,
          tickLength: 2,
          // labelBackgroundColor:"rgba(0, 0, 0, 0.12)",
          lineColor: "rgba(0, 0, 0, 0.12)",
          fontFamily: "'helvetica neue', helvetica, arial",
          fontColor: "rgba(0, 0, 0, 0.59)",
          labelFormatter: function (e) {
            let ashowResData = cnst_showResData//this.state.showResData.split(",")
            if (ashowResData[e.value - 1]) {
              return g_labels[ashowResData[e.value - 1]]
            } else {
              return "x: " + e.value;
            }
          }
        },
        legend: {
          fontColor: "rgba(0, 0, 0, 0.59)",
          fontFamily: "'helvetica neue', helvetica, arial",
          fontWeight: 200,
          markerMargin: 10
        },
        data: xDatas0
      };
      options1 = {
        exportEnabled: false,
        animationEnabled: true,
        // height: 300,
        title: {
          text: this.props.labels.labels.LBLPOSTTRAINING,
          fontSize: 16,
          fontFamily: "'helvetica neue', helvetica, arial",
          fontColor: "rgba(0, 0, 0, 0.59)",
          fontWeight: "bold",
        },
        dataPointWidth: dtpwidth,
        axisY: {
          maximum: 100,
          lineColor: "rgba(0, 0, 0, 0.12)",
          gridColor: "rgba(0, 0, 0, 0.12)",
          tickColor: "rgba(0, 0, 0, 0.12)",
          labelFontColor: "rgba(0, 0, 0, 0.32)",
          fontFamily: "'helvetica neue', helvetica, arial",
          fontColor: "rgba(0, 0, 0, 0.59)",
          suffix: "%",
        },
        axisX: {
          tickThickness: dtpwidth * xDatas0.length,
          tickLength: 2,
          // labelBackgroundColor:"rgba(0, 0, 0, 0.12)",
          lineColor: "rgba(0, 0, 0, 0.12)",
          fontFamily: "'helvetica neue', helvetica, arial",
          fontColor: "rgba(0, 0, 0, 0.59)",
          labelFormatter: function (e) {
            let ashowResData = cnst_showResData//this.state.showResData.split(",")
            if (ashowResData[e.value - 1]) {
              return g_labels[ashowResData[e.value - 1]]
            } else {
              return "x: " + e.value;
            }
          }
        },
        legend: {
          fontColor: "rgba(0, 0, 0, 0.59)",
          fontFamily: "'helvetica neue', helvetica, arial",
          fontWeight: 200,
          markerMargin: 10
        },
        data: xDatas1
      };
      let pre_options0, pre_options1;
      if (this.state.view1.id == "PRE" || this.state.view1.id == "PREPOST") {
        pre_options0 = this.convertToHigh(options0.data, cnst_showResData, g_labels);
        pre_options0.title.text = options0.title.text;
      }
      if (this.state.view1.id == "POST" || this.state.view1.id == "PREPOST") {
        pre_options1 = this.convertToHigh(options1.data, cnst_showResData, g_labels);
        pre_options1.title.text = options1.title.text;
      }
      return (
        <>
          <div className="row col-xl-12">
            {
              this.state.view1.id == "PRE" ? <>
                <div
                  className="col-xl-12"
                  style={{ display: "inline-block", padding: "0", textAlign: "center" }}
                >
                  <div style={{ display: "inline-block", padding: "0", width: "100%" }}>
                    <HighchartsReact highcharts={Highcharts}
                      options={pre_options0}
                      ref={this.chart}
                    />
                  </div>
                  {this.renderDetailResponse(options0, tvdataPoints0, qitem, xComments0)}
                </div>
              </> : <></>
            }
            {
              this.state.view1.id == "POST" ? <>
                <div
                  className="col-xl-12"
                  style={{ display: "inline-block", padding: "0" }}
                >
                  <div style={{ display: "inline-block", padding: "0", width: "100%" }}>
                    <HighchartsReact highcharts={Highcharts}
                      options={pre_options1}
                      ref={this.chart}
                    />
                  </div>
                  {this.renderDetailResponse(options1, tvdataPoints1, qitem, xComments1)}
                </div>
              </> : <></>
            }
            {
              this.state.view1.id == "PREPOST" ? <>
                <div
                  className="col-xl-6"
                  style={{ display: "inline-block", padding: "0", textAlign: "center" }}
                >
                  <div style={{ display: "inline-block", padding: "0", width: "100%" }}>
                    <HighchartsReact highcharts={Highcharts}
                      options={pre_options0}
                      ref={this.chart}
                    />
                  </div>
                </div>
                <div
                  className="col-xl-6"
                  style={{ display: "inline-block", padding: "0" }}
                >
                  <div style={{ display: "inline-block", padding: "0", width: "100%" }}>
                    <HighchartsReact highcharts={Highcharts}
                      options={pre_options1}
                      ref={this.chart}
                    />
                  </div>
                </div>
              </> : <></>
            }
          </div>
        </>
      );
    }
  };
  convertToHigh = (data, cnst_showResData, g_labels) => {
    let dPoints = [], colCnt = 0, plotWidth = 32, pre_options = this.getOption(data, cnst_showResData, g_labels);
    //pre_options.series[0] =  data[0].dataPoints;   
    if (pre_options && data.length > 0) {
      pre_options.series = [];
      data.map(item => {
        dPoints = item.dataPoints;
        if (item.legendText !== '')
          pre_options.series.push({ data: (dPoints), name: item.legendText ? item.legendText : null });
        colCnt++;
        return null;
      });
      plotWidth = cnst_showResData.length > 1 ? colCnt > 15 ? 20 : plotWidth : 50;
    }
    pre_options.plotOptions.series.pointWidth = plotWidth;
    return pre_options;
  }
  getOption = (data, cnst_showResData, g_labels) => {
    let xAxisLabels = [""]
    if (data[0].dataPoints.length > 0) {
      data[0].dataPoints.map(item => {
        return xAxisLabels.push(item.label);
      })
    }
    let chart_data =
    {
      chart: {
        type: 'column',
        style: {
          fontFamily: "'Roboto', sans-serif"
        }
      },
      title: {
        text: this.getLabel("LBLPRETRAINING"),
        style: {
          fontWeight: '500',
          fontSize: 18
        },
      },
      exporting: {
        enabled: false,
        allowHTML: true,
        chartOptions: {
          title: {
            text: '<b>test</b></br><div style="font-size:12px">Pre Training</div>',
          },
          xAxis: {
            labels: {
              style: {
                fontSize: '6px'
              },
              rotation: 90
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: true,
        align: 'center',
      },
      tooltip: {
        headerFormat: '',
        pointFormat: '<strong>{series.name} : <strong>{point.y}%<br/>Value: {point.count} Out Of {point.tcount}</strong>',
      },
      xAxis: {
        labels: {
          formatter: function (e) {
            let ashowResData = cnst_showResData;
            if (ashowResData[e.value - 1]) {
              return g_labels[ashowResData[e.value - 1]]
            } else {
              return "x: " + e.value;
            }
          },
          style: {
            fontWeight: '500',
            fontSize: 13
          },
        },
        title: {
          text: null,
          style: {
            fontWeight: '500',
            fontSize: 15
          },
        },
        plotLines: [{
          width: 2,
        }],
      },
      yAxis: {
        tickInterval: 10,
        min: 0,
        max: 100,
        title: {
          text: "",
          style: {
            fontWeight: '450',
            fontSize: 15
          }
        },
        plotLines: [{
          value: -1,
          width: 2,
        }],
        labels: {
          formatter: function (e) {
            return (this.value + "%")
          },
          style: {
            fontWeight: '500',
            fontSize: 13
          },
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: '450',
          }
        }
      },
      plotOptions: {
        column: {
          //borderRadius: '25%'
        },
        series: {
          pointWidth: 28,
        },
      },
      series: [],
      lang: {
        noData: 'No data found'
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030'
        }
      }
    };
    return chart_data;
  }
  renderUserComments = () => {
    const { labels } = this.props;
    if (this.state.responseUserInputs) {
      return (<>
        <div className="col-xl-12" style={{ paddingTop: "10px", maxHeight: "350px", overflowY: "scroll" }}>

          {
            this.state.responseUserInputs.userInputs.map((cc_item, cc_index) => {
              return <>
                <div
                  className="row  ss-svy-rptc"
                  style={{
                    fontFamily: "'helvetica neue', helvetica, arial",
                  }}
                >
                  <div className="col-xl-12" style={{ paddingTop: "10px" }}>
                    <>
                      {cc_item.split('"').join("")}
                    </>

                  </div>
                </div>
              </>
            })
          }

        </div>
      </>)
    } else {
      return ""
    }
  }
  renderUserAnswers = (qitem, data) => {
    const { labels } = this.props;
    return (<>
      {
        obj_safestartsurvey.map((objmod, modindex) => {
          if (objmod.questions) {
            return <>
              {
                objmod.questions.map((questions, questionindex) => {


                  if (qitem.sid == questions.sid) {
                    let usa = 0
                    questions.options.map((item, index) => {
                      if (data[item.sid] == 1) {
                        usa = 1
                      }
                    })
                    if (usa == 1) {
                      return <> {

                        questions.options.map((item, index) => {
                          if (data[item.sid] == 1) {
                            usa = 1
                            return (<>{labels.labels[item.label]} <br /></>)
                          }
                        })

                      }
                      </>
                    } else {
                      return <>{labels.labels.LBLSKIPPED}</>
                    }


                  }


                })
              }
            </>
          }
        })
      }
    </>)
  };
  changeTabReportType = (event, newValue) => {
    let tabTypes = ["summary", "individual"]
    this.setState({
      tabType: tabTypes[newValue],
      tabTypeIndex: newValue
    });
  };
  changeReportType = (graphtype) => {
    this.setState({
      graphType: graphtype,
    });
  };
  getGraphTypeClassName = (xtype) => {
    if (xtype === this.state.graphType) {
      return classList({
        cls_selected: true,
        cls_chart_type: true,
      });
    } else {
      return classList({
        cls_selected: false,
        cls_chart_type: true,
      });
    }
  };
  handleView1 = (event, values) => {
    let view1 = this.state.view1;
    if (values.id === "PREPOST") {
      this.setState({
        view2: { "id": "LBLALLROLE", "name": this.getLabel("LBLALLROLE") },
        showResData: "LBLHOURLY,LBLSUPERVISOR,LBLMANAGER,LBLALLROLE",
        view1: values
      });
    } else {
      this.setState({
        view1: values
      });
    }
  };
  handleView2 = (event, values) => {
    let view1 = this.state.view1;
    let showResData = values.id
    if (values.id == "LBLALLROLE") {
      showResData = "LBLHOURLY,LBLSUPERVISOR,LBLMANAGER,LBLALLROLE"
    }
    this.setState({
      view2: values,
      showResData: showResData
    });
  };
  closeRefModal = () => {
    this.setState({
      showmodal: false,
    });
  };
  render() {
    const {
      LBLSUMMARYRESPONSE,
      LBLINDIVIDUALRESPONSE,
      LBLSSSURVEYREPORT,
      LBLOF,
      LBLROWSPERPAGE,
      LBLALL,
      LBLLEARNERCRSPROGEXPTYTXT,
    } = this.props.labels.labels;
    let labels = this.props.labels.labels
    const rowsPerPageText = [LBLROWSPERPAGE + ":", LBLOF, LBLALL];
    const paginationComponentOptions = {
      rowsPerPageText: rowsPerPageText[0],
      rangeSeparatorText: rowsPerPageText[1],
      noRowsPerPage: false,
      selectAllRowsItem: false,
      selectAllRowsItemText: rowsPerPageText[2],
    }

    if (this.state.view1.id === "PRE" && (this.state.view1.name === "" || this.state.view1.name === "LBLPRETRAINING")) {
      this.setState({
        view1: { "id": "PRE", "name": this.getLabel("LBLPRETRAINING") }
      })
    }
    if (this.state.pageState === "SSS_D1") {
      //const { SSS_D1_List } = this.state;
      let columns = this.getSSS_D1_Columns();
      let filterFields = this.getFilterColumns("SSS_D1", columns);
      let resetfilterFields = this.getResetFilterColumns("SSS_D1", columns);

      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < filterFields.length; j++) {
          if (
            filterFields[j] &&
            columns[i] &&
            filterFields[j].name === columns[i].name &&
            filterFields[j].filter.showField === false
          ) {
            columns.splice(i, 1);
            i--;
          }
        }
      }
      objFilterData.orgavailableSSS_D1Fields = this.getFilterColumns(
        "SSS_D1",
        this.getSSS_D1_Columns()
      );

      const { SSS_D1_List, filterDataLoaded, SSS_D1_SummaryData, SSS_D1_INDVDL_List, SSS_D1_INDVDL_index } = this.state;
      let cur_mod_index = this.state.cur_mod_index;
      let cur_mod_page_index = this.state.cur_mod_page_index;
      let page_question_count = this.state.page_question_count;
      let displaycondvalue =
        this.state.passvars != "" ? this.state.passvars.split("_")[1] || 0 : 0;
      const { qanswers } = this.state;
      const { labels } = this.props;
      let view1 = [
        { "id": "PRE", "name": this.getLabel("LBLPRETRAINING") },
        { "id": "POST", "name": this.getLabel("LBLPOSTTRAINING") },
        { "id": "PREPOST", "name": this.getLabel("LBLCOMPARISON") }]
      let view2 = [
        { "id": "LBLMANAGER", "name": this.getLabel("LBLMANAGER") },
        { "id": "LBLSUPERVISOR", "name": this.getLabel("LBLSUPERVISOR") },
        { "id": "LBLHOURLY", "name": this.getLabel("LBLHOURLY") },
        { "id": "LBLALLROLE", "name": this.getLabel("LBLALLROLE") }]

      return (

        <div className="reportsCtn">
          <Modal
            show={this.state.showmodal}
            onHide={this.closeRefModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          //centered
          >
            <Modal.Header closeButton className='alert-modal-header'>
              <Modal.Title className="alert-modal-title">
                <div className="col-xl-12">
                  <div style={{
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    marginBottom: "30px",
                    marginTop: "20px"
                  }}>
                    {this.state.responseUserInputs && this.state.responseUserInputs.item ?
                      labels.labels[this.state.responseUserInputs.item.label] : ""
                    }
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.renderUserComments()}
            </Modal.Body>
            <Modal.Footer style={{ borderTop: "0px !important" }}>
              <div className="ssi-feeds-commentsActionBtn">

              </div>
            </Modal.Footer>
          </Modal>
          <div className="mob-pad">
            <div >
              <h3 className="page-title">{LBLSSSURVEYREPORT}</h3>
            </div>
            <hr className="head-line" />
            {console.log("values checking", this.state.loading, this.state.filterDataLoaded, this.state.filterDataStatus, filterFields.length)}
            {this.state.loading === true
              ? <Loader></Loader> :
              <div id="accordion" className="row rptlst-row">
                {this.state.loading === false &&
                  this.state.filterDataStatus === true &&
                  filterFields.length >= 1 ? (
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div>
                      {this.state.loading === false &&
                        this.state.filterDataLoaded === 1 &&
                        (filterFields.length > 1 || this.props.ssi_data_priv) ? (
                        <div>
                          <CustomFilter
                            availableFields={filterFields}
                            selectedFields={filterFields}
                            orgAvailableFields={resetfilterFields}
                            onFilterChange={this.onFilterChange}
                            onExport={this.onExport}
                            id="F1"
                            onRef={(ref) => (REFS.F1 = ref)}
                            labels={this.props.labels.labels}
                            reportName={LBLSSSURVEYREPORT}
                            companyName={this.props.company_name}
                            showFieldFilter={true}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>

                    {SSS_D1_List.length === 0 ? (
                      <div style={{ textAlign: "center" }}><EmptyState image={emptyImage} text2={LBLLEARNERCRSPROGEXPTYTXT} /></div>
                    ) : this.state.loading === false &&
                      this.state.filterDataLoaded === 1 &&
                      (filterFields.length > 1 || this.props.ssi_data_priv) ? (
                      <>
                        <div
                          style={{ display: "" }}
                          id="cls_report_list"
                          className="cls_report_list"
                        >
                          <div className="cls_reports_item2">
                            <div className="row">
                              <div
                                className="col-xl-12"
                                style={{
                                  display: "flex", alignItems: "center",
                                  justifyContent: "center"
                                }}
                              >
                                <div className="col-xl-6">
                                  <Paper square className={"cls_tabs_root"}>
                                    <Tabs
                                      value={this.state.tabTypeIndex}
                                      onChange={this.changeTabReportType}
                                      variant="fullWidth"
                                      aria-label=" label tabs example"
                                      TabIndicatorProps={{
                                        style: {
                                          backgroundColor: "black"
                                        }
                                      }}
                                    >
                                      <Tab label={LBLSUMMARYRESPONSE} />
                                      <Tab label={LBLINDIVIDUALRESPONSE} />
                                    </Tabs>
                                  </Paper>
                                </div>
                              </div>
                            </div>
                            <div>
                              {
                                this.state.tabType == "summary" ? <>
                                  <div className="row" style={{
                                    display: "flex", alignItems: "center",
                                    justifyContent: "right",
                                    marginBottom: "40px", paddingTop: "10px"
                                  }}>
                                    <div className="col-xl-2">
                                      <Autocomplete
                                        disableClearable
                                        options={view1}
                                        getOptionLabel={(option) => option.name.toString()}
                                        getOptionSelected={(option, values) =>
                                          option.id === values.id
                                        }
                                        onChange={this.handleView1.bind(
                                          this
                                        )}
                                        underlineStyle={{ display: "none" }}
                                        defaultValue={this.state.view1}
                                        renderInput={(params) => (
                                          <TextField
                                            value=""
                                            text=""
                                            {...params}
                                            label={this.getLabel("LBLVIEW")}
                                          />
                                        )}
                                        underline="none"
                                      />
                                    </div>
                                    <div className="col-xl-2"
                                      style={{ visibility: this.state.view1.id === "PREPOST" ? "hidden" : "visible" }}
                                    >
                                      <Autocomplete
                                        disableClearable
                                        options={view2}
                                        getOptionLabel={(option) => option.name.toString()}
                                        getOptionSelected={(option, values) =>
                                          option.id === values.id
                                        }
                                        onChange={this.handleView2.bind(
                                          this
                                        )}
                                        underlineStyle={{ display: "none" }}
                                        defaultValue={this.state.view2}
                                        renderInput={(params) => (
                                          <TextField
                                            value=""
                                            text=""
                                            {...params}
                                            label={this.getLabel("LBLRESPONSESOF")}
                                          />
                                        )}
                                        underline="none"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-12"
                                    style={{
                                      display: "flex", alignItems: "center",
                                      justifyContent: "center"
                                    }}
                                  >

                                    {
                                      this.state.view1.id == "PRE" || this.state.view1.id == "PREPOST" ? <>
                                        <div className="col-sm-12 col-xl-6">
                                          <div className="col-sm-12" style={{ textAlign: "center" }}><h3>{labels.labels.LBLPRETRAINING}</h3></div>
                                          <div className="col-sm-12"
                                            style={{
                                              display: "flex", alignItems: "center",
                                              justifyContent: "center"
                                            }}
                                          >
                                            <div className="surveyReportCircle">
                                              <div className="vertical_center" style={{ textAlign: "center" }}>
                                                {SSS_D1_SummaryData.graphData["LBLALLROLE"]["PRE"]} - {labels.labels.LBLTOTALRESPONSES}
                                              </div>
                                            </div>
                                            <div className="surveyReportCircleRight">
                                              <div className="vertical_center" style={{marginLeft:"30px"}}>
                                                {SSS_D1_SummaryData.graphData["LBLHOURLY"]["PRE"]} - {labels.labels.LBLHOURLY}<br />
                                                {SSS_D1_SummaryData.graphData["LBLSUPERVISOR"]["PRE"]} - {labels.labels.LBLSUPERVISOR}<br />
                                                {SSS_D1_SummaryData.graphData["LBLMANAGER"]["PRE"]} - {labels.labels.LBLMANAGER}<br />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </> : <></>
                                    }
                                    {
                                      this.state.view1.id == "POST" || this.state.view1.id == "PREPOST" ? <>
                                        <div className="col-sm-12 col-xl-6">
                                          <div className="col-sm-12" style={{ textAlign: "center" }}><h3>{labels.labels.LBLPOSTTRAINING}</h3></div>
                                          <div className="col-sm-12"
                                            style={{
                                              display: "flex", alignItems: "center",
                                              justifyContent: "center"
                                            }}
                                          >
                                            <div className="surveyReportCircle">
                                              <div className="vertical_center" style={{ textAlign: "center", width: "140px" }}>
                                                {SSS_D1_SummaryData.graphData["LBLALLROLE"]["POST"]} - {labels.labels.LBLTOTALRESPONSES}
                                              </div>
                                            </div>
                                            <div className="surveyReportCircleRight" >
                                              <div className="vertical_center" style={{marginLeft:"30px"}}>
                                                {SSS_D1_SummaryData.graphData["LBLHOURLY"]["POST"]} - {labels.labels.LBLHOURLY}<br />
                                                {SSS_D1_SummaryData.graphData["LBLSUPERVISOR"]["POST"]} - {labels.labels.LBLSUPERVISOR}<br />
                                                {SSS_D1_SummaryData.graphData["LBLMANAGER"]["POST"]} - {labels.labels.LBLMANAGER}<br />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </> : <></>
                                    }
                                  </div>
                                  {qanswers[cur_mod_index].questions
                                    .slice(
                                      cur_mod_page_index * page_question_count,
                                      cur_mod_page_index * page_question_count +
                                      page_question_count
                                    )
                                    .map((item, i) => {
                                      if (
                                        !item.displaycondition ||
                                        (item.displaycondition &&
                                          item.displaycondition.LAUNCHMODE ==
                                          displaycondvalue)
                                      ) {
                                        if (item.options[0].type == "user_input") {
                                          return <></>;
                                        } else {
                                          if (i == 0) {
                                            return <div className="col-xl-12" style={{ display: "block", height: "3px", backgroundColor: "black", marginTop: "40px" }}>

                                            </div>;
                                          } else if (i > 0) {
                                            return (
                                              <div
                                                className="row  ss-svy-rpt"
                                                style={{
                                                  fontFamily: "'helvetica neue', helvetica, arial",
                                                }}
                                              >
                                                <div className="col-xl-12" style={{ marginBottom: "20px" }}>
                                                  <div style={{
                                                    fontWeight: 400,
                                                    fontSize: "1.4rem",
                                                    border: "0px solid red"
                                                  }}>
                                                    {i}. {labels.labels[item.label]}
                                                  </div>
                                                </div>
                                                <div className="col-xl-12">
                                                  <>
                                                    {this.renderCanvasComponent(item)}
                                                  </>

                                                </div>
                                              </div>
                                            );
                                          } else {
                                            return <></>
                                          }

                                        }
                                      }
                                    })}
                                </> : this.state.tabType == "individual" ? <>

                                  {
                                    SSS_D1_INDVDL_List && SSS_D1_INDVDL_List.length > 0 && SSS_D1_INDVDL_index >= 0 && SSS_D1_INDVDL_index < SSS_D1_INDVDL_List.length ? <>
                                      <div className="row" style={{
                                        marginBottom: "40px"
                                      }}>
                                        <div className="col-xl-12" style={{ display: "block", height: "3px", backgroundColor: "black", marginTop: "40px", marginBottom: "30px" }}>

                                        </div>
                                        <div className="col-xl-2">
                                          {this.props.labels.labels.LBLCOMPLETIONDATE}
                                        </div>
                                        <div className="col-xl-4">
                                          : {SSS_D1_INDVDL_List[SSS_D1_INDVDL_index].created_on}
                                        </div>
                                        <div className="col-xl-6" style={{
                                          display: "flex",
                                          justifyContent: "right"
                                        }}>
                                          <button type="button" class="btn basicSelItem " style={{ backgroundColor: "white", borderColor: "rgba(0, 0, 0, 0.12)", height: "36px", margin: "0px", padding: "7px 13px" }}
                                            onClick={(e) => {
                                              let x = this.state.SSS_D1_INDVDL_index - 1
                                              x = x < 0 ? 0 : x
                                              this.setState({
                                                SSS_D1_INDVDL_index: x,
                                              });
                                            }}
                                          >
                                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                                          </button>
                                          <span style={{ backgroundColor: "white", borderColor: "rgba(0, 0, 0, 0.12)", height: "36px", padding: "7px 13px", margin: "0px 14px" }} >{SSS_D1_INDVDL_index + 1} / {SSS_D1_INDVDL_List.length}</span>
                                          <button type="button" class="btn basicSelItem " style={{ backgroundColor: "white", borderColor: "rgba(0, 0, 0, 0.12)", height: "36px", margin: "0px", padding: "7px 13px" }}
                                            onClick={(e) => {
                                              let x = this.state.SSS_D1_INDVDL_index + 1
                                              x = x == this.state.SSS_D1_INDVDL_List.length ? this.state.SSS_D1_INDVDL_List.length - 1 : x
                                              this.setState({
                                                SSS_D1_INDVDL_index: x,
                                              });
                                            }}
                                          >
                                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                                          </button>
                                        </div>
                                        <div className="col-xl-2">
                                          {this.props.labels.labels.LBLDGQUESTION2}
                                        </div>
                                        <div className="col-xl-4">
                                          : {this.props.labels.labels[SSS_D1_INDVDL_List[SSS_D1_INDVDL_index].role]}
                                        </div>
                                      </div>
                                      {qanswers[cur_mod_index].questions
                                        .slice(
                                          cur_mod_page_index * page_question_count,
                                          cur_mod_page_index * page_question_count +
                                          page_question_count
                                        )
                                        .map((item, i) => {
                                          if (
                                            !item.displaycondition ||
                                            (item.displaycondition &&
                                              item.displaycondition.LAUNCHMODE ==
                                              displaycondvalue)
                                          ) {
                                            if (item.options[0].type == "user_input") {
                                              return <></>;
                                            } else {
                                              if (i == 0) {
                                                return <>
                                                  <div className="row ss-svy-rpt" style={{ display: "block" }}>
                                                  </div>
                                                </>;
                                              } else if (i > 0) {
                                                return (
                                                  <div
                                                    className="row  ss-svy-rpt"
                                                    style={{
                                                      fontFamily: "'helvetica neue', helvetica, arial",
                                                    }}
                                                  >
                                                    <div className="col-xl-12">
                                                      <div style={{
                                                        fontWeight: 400,
                                                        fontSize: "1.2rem"
                                                      }}>
                                                        {i}. {labels.labels[item.label]}
                                                      </div>
                                                    </div>
                                                    <div className="col-xl-12" style={{ paddingTop: "10px", marginBottom: "20px" }}>
                                                      <>
                                                        {this.renderUserAnswers(item, SSS_D1_INDVDL_List[SSS_D1_INDVDL_index])}
                                                      </>

                                                    </div>
                                                  </div>
                                                );
                                              } else {
                                                return <></>
                                              }

                                            }
                                          }
                                        })}
                                    </> : <></>
                                  }
                                </> : <>
                                  No Report
                                </>
                              }
                            </div>
                          </div>
                        </div>

                        <div className="row zrowbox">
                          <div className="col-xl-12 col-lg-12 col-md-12">
                          </div>
                        </div>

                      </>
                    ) : null}
                  </div>
                ) : (
                  <div style={{ width: "100%", paddingLeft: "15px" }}>
                    <div>
                      <div>
                        <div>
                          <div>
                            <h3>
                              <Skeleton height={21} />
                            </h3>
                            <h2>
                              <Skeleton height={26} />
                            </h2>
                            <div>
                              <div>
                                <div className="align-items-top">
                                  <div>
                                    <Skeleton height={19} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row zrowbox">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <div className="form-group col-xl-3 col-lg-3 col-md-3 ml-auto mr-12">
                          <Skeleton height={34} />
                        </div>
                        <Skeleton height={400} />
                      </div>
                    </div>

                    <div className="bottomborder">
                      <span className="subHeader">
                        <h4>
                          <Skeleton height={38} />
                        </h4>
                      </span>
                    </div>
                    <div className="sc-fznxsB fWHyCt datatable-list web-skull">
                      <div className="sc-fznWqX gnahTY">
                        <div className="sc-AxjAm gIMaKV rdt_Table">
                          <div className="sc-AxirZ eBuPmJ rdt_TableHead">
                            <Skeleton height={56} />
                          </div>
                          <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                            <Skeleton height={480} />
                          </div>
                        </div>
                      </div>
                      <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                        <Skeleton height={56} />
                      </nav>
                    </div>
                    <div className="sc-fznxsB fWHyCt datatable-list mob-skull">
                      <header className="sc-fzoXzr hlidmt">
                        <Skeleton height={34} />
                      </header>
                      <div className="sc-fznWqX gnahTY">
                        <div className="sc-AxjAm gIMaKV rdt_Table">
                          <div className="sc-fzpjYC ieTOoW rdt_TableBody">
                            {skel.map((i) => {
                              return (
                                <div
                                  id={"row-" + i}
                                  className="sc-fzoLsD doBktq rdt_TableRow"
                                >
                                  <Skeleton height={280} />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <nav className="sc-fzoNJl dGqwdB rdt_Pagination">
                        <div className="sc-fzpmMD ftIMtk">
                          <Skeleton height={40} />
                        </div>
                      </nav>
                    </div>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      );
    } else {
      return <div>No Data for {this.state.pageState}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    companyId: state.user_info_reducer.user_info.company_id,
    name: state.user_info_reducer.user_info.name,
    userId: state.user_info_reducer.user_info.user_id,
    role_id: state.user_info_reducer.user_info.role_id,
    role: state.user_info_reducer.user_info.role,
    company: state.user_info_reducer.user_info.company,
    email: state.user_info_reducer.user_info.email,
    company_name: state.user_info_reducer.user_info.company_name,
    language_code: state.user_info_reducer.user_info.language_code,
    currency: state.user_info_reducer.user_info.currency,
    dateformat: state.user_info_reducer.user_info.dateformat,
    timeformat: state.user_info_reducer.user_info.timeformat,
    timezone: state.user_info_reducer.user_info.timezone,
  };
};

export default connect(mapStateToProps, null)(SSSReport);

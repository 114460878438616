import DefaultWidget from './DefaultWidget';
import * as operators from '../operators';

class StringWidget extends DefaultWidget {
  getOperators() {
    return {
      // [operators.ALL]: this.props.labels.LBLALL,//'contains',
      [operators.NOCONDITION]: this.props.labels.LBLSELECTCONDITION,
      [operators.MULTI_EQUALS]: this.props.labels.LBLMULTI_EQUALS,
      [operators.CONTAINS]: this.props.labels.LBLCONTAINS,//'contains',
      [operators.DOES_NOT_CONTAIN]: this.props.labels.LBLDOES_NOT_CONTAIN,
      [operators.EQUALS]: this.props.labels.LBLEQUALS,
      [operators.NOT_EQUALS]: this.props.labels.LBLNOT_EQUALS
    };
  }
}

StringWidget.defaultOperator = operators.MULTI_EQUALS;
StringWidget.defaultValue = '';

export default StringWidget;


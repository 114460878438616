import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { required, minValue8, passwordsMustMatch } from "../validate/Validate";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import  prof from "./profile.scss";
import $ from "jquery";

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
    };
  }

  renderField = ({
    input,
    label,
    type,
    id,
    placeholder,
    className,
    onKeyUp,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          {...input}
          placeholder={placeholder}
          type={type}
          id={id}
          onKeyUp={onKeyUp}
          className={className}
        />
        {touched &&
          ((error && (
            <span className="required-field">
              {typeof error === "string"
                ? this.props.labels[error]
                : this.props.labels[error.label].replace("$$$", error.val)}
            </span>
          )) ||
            (warning && <span className="required-field">{warning}</span>))}
      </div>
    </div>
  );
  changePwdVisible =(e)=> {
    let keyid,keyclass;
    if(e.target.id==1){
     keyid =  "oldpassword";
     keyclass = ".pwd_visibility i";
    }else if(e.target.id==2){
      keyid =  "password";
      keyclass = ".pwd_visibility1 i";
     }else{
     keyid =  "cpassword";
     keyclass = ".pwd_visibility2 i";
    }
   var cntl = document.getElementsByName(keyid)[0];
   cntl.type = cntl.type === "password"? "text":"password";  
   
   if(cntl.type === "password"){
     $(keyclass).removeClass("fa-eye-slash").addClass("fa-eye");
   }else{
     $(keyclass).removeClass("fa-eye").addClass("fa-eye-slash");
   }
 }
 onKeyInput = (e) =>{ 
   let keyclass = e.target.id =="oldpassword"?".pwd_visibility":e.target.id =="password"?".pwd_visibility1":".pwd_visibility2";
   let x = e.target.value;  
   $(keyclass).css({"display":x.length>0?"block":"none"})
 }
  render() {
    const { handleSubmit, submitting, labels } = this.props;

    return (
      <div>
        <Modal.Header closeButton className='alert-modal-header'>
          <Modal.Title className="alert-modal-title">
              {labels.LBLCHANGEPASSWORD}
          </Modal.Title>
        </Modal.Header>
        <form className="form" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="modal-body password_section">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                  <Field
                    name="oldpassword"
                    type="password"
                    id="oldpassword"
                    component={this.renderField}
                    label={
                      this.props.langCode === "fr-FR"
                        ? labels.LBLOLDPWD
                        : labels.LBLENTEROLDPWD
                    }
                    placeholder={labels.LBLENTEROLDPWD}
                    className="input-field input-field-profile"
                    validate={[required]}
                    onKeyUp={this.onKeyInput}
                  /> 
                 <span id="1" className="pwd_visibility" onClick={this.changePwdVisible} ><i id="1" class="fas fa-eye"></i></span>  
                 </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                  <Field
                    name="password"
                    type="password"
                    id="password"
                    component={this.renderField}
                    label={
                      this.props.langCode === "fr-FR"
                        ? labels.LBLNEWPWD
                        : labels.LBLENTERNEWPASSWORD
                    }
                    placeholder={labels.LBLENTERNEWPASSWORD}
                    className="input-field input-field-profile"
                    validate={[required, minValue8]}
                    onKeyUp={this.onKeyInput}
                  />
                <span id="2" className="pwd_visibility1" onClick={this.changePwdVisible} ><i id="2" class="fas fa-eye"></i></span>  

                </div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                  <Field
                    name="cpassword"
                    type="password"
                    id="cpassword"
                    component={this.renderField}
                    label={
                      this.props.langCode === "fr-FR"
                        ? labels.LBLCONFIRMPWD
                        : labels.LBLCONFIRMPASSWORD
                    }
                    placeholder={labels.LBLCONFIRMPASSWORD}
                    className="input-field input-field-profile"
                    validate={[required, minValue8, passwordsMustMatch]}
                    onKeyUp={this.onKeyInput}
                  />
                 <span id="3" className="pwd_visibility2" onClick={this.changePwdVisible} ><i id="3" class="fas fa-eye"></i></span>  

                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.props.message !== null ? (
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                  {this.props.message === "2" ? (
                    <label className="required-field">
                      {labels.LBLINCORRECTALERT}
                    </label>
                  ) : this.props.message === "1" ? (
                    <Link to="/">
                      <a href="#">
                        <i className="fas fa-id-badge"></i>
                        {labels.LBLGOTOLOGIN}
                      </a>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.props.message === "1" ? (
              ""
            ) : (
              <React.Fragment>
                {" "}
                <button
                  type="button"
                  className="button-style secondary-btn"
                  id="btnaddCancel"
                  onClick={this.props.onCancel}
                >
                  {labels.LBLCANCEL}
                </button>
                <button
                  type="submit"
                  className="button-style primary-btn"
                  disabled={submitting}
                >
                  {labels.LBLSUBMIT}
                </button>
              </React.Fragment>
            )}
          </Modal.Footer>
        </form>
      </div>
    );
  }
}

ChangePasswordForm = reduxForm({
  form: "changepasswordform", // a unique name for this form
})(ChangePasswordForm);

export default connect()(ChangePasswordForm);

import React, {Component}from "react";
import DataTable from "react-data-table-component";
class DataTableComponent extends Component {
  
  render (){
    const {columns,resultData,tableHeader, noDataComponent} = this.props;
   
    return(
        <DataTable
        columns = { columns }
        data = { resultData }
        defaultSortField = "name"
        className = "datatable-list"
        pagination
        subHeader
        subHeaderComponent = { tableHeader }
        noDataComponent = { noDataComponent }
        />
    )
  }
}
export default DataTableComponent;
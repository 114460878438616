import { HttpInterceptor } from '../auth/HttpInterceptor';
import * as CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';

class ScCommonServices {
    async access_privileges(role) {
        let data = {
            role_id: role
        }
        if (localStorage.getItem("sc_user")) {
            const response = await HttpInterceptor.put('access', data);
            return response.data;
        } else {
            //const response = await HttpInterceptor.put('access', data);
            return null;
        }

    }
    async generateId(data) {
        const response = await HttpInterceptor.post('scauthe', data);
        return response.data.ssi_user;
    }
    async generateAccess(data) {
        let user_agent = window.navigator.userAgent;
        const response = await HttpInterceptor.post('scautho', data, { headers: { "x-user-agent": user_agent } });
        return response.data;
    }
    async getCompanyInfo() {
        try {
            const setCok = await HttpInterceptor.get('cookieset');
            const response = await HttpInterceptor.get('initset');
            return response.data;
        } catch (err) {
            console.log("company init error",err);
            if (err.response && err.response.status === 406) {
                throw new Error('invalid_origin');
            } else {
                throw err;
            }
        }

    }
    async getRoles(user) {
        let url = user ? 'roles/?user=1' : 'roles';
        const response = await HttpInterceptor.get(url);
        return response.data;
    }
    async setUserPassword(data) {
        let url;
        if (data.verify) {
            url = "emailverification"
        } else {
            url = "forgotpassword"
        }

        const response = await HttpInterceptor.put(url, data);
        return response.data;
    }
    async checkUserPassword(data) {
        let url = 'verifypassword';


        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    async checkInvitation(data) {
        let url = "verifyinvitation"
        const response = await HttpInterceptor.put(url, data);
        return response;
    }
    async forgotPassword(data) {
        let url = "userlist";
        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    async validatePasswordLink(data) {
        let url = "forgotpassword";
        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    async createHash(text) {
        return new Promise((resolve, reject) => {
            let hash = CryptoJS.SHA512(text);
            hash = Base64.stringify(hash);
            resolve(hash);
            reject("error in hash creation")
        })
    }
    async rate_your_state(data) {
        let url = "statecontents";
        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    async record_survey_response(data) {
        let url = "surveyresponse";
        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    async record_survey_response_manual(data) {
        let url = "surveyresponsemanual";
        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    async stateContents() {
        const response = await HttpInterceptor.get('statecontents');
        return response.data;
    }
    async getAssyncSignedUrl(data) {
        const response = await HttpInterceptor.post('getSignedLogo', data);
        return response.data;
    }
    async getSignedUrl(data, callback) {
        HttpInterceptor.post("gets3signedassetsurl", data)
            .then(result => {
                if (callback) {
                    callback(result);
                }
            }).catch(errr => {
                alert("UnAuthorized Request")
            })
    }
    async getCompanyLogo() {
        const response = await HttpInterceptor.get('companylogo');
        return response.data;
    }
    async getGenerateOTP(data) {
        const response = await HttpInterceptor.post('smsotp', data);
        return response.data;
    }

    async getGeneratePHONEVERIFYOTP(data) {
        const response = await HttpInterceptor.post('sendverifyotp', data);
        return response.data;
    }
    async VERIFYOTP(data) {
        const response = await HttpInterceptor.post('verifyotp', data);
        return response.data;
    }
    async registerUser(data) {
        try {
            const response = await HttpInterceptor.put('verification/otp', data);
            return response.data;
        } catch (e) {
            console.log("fdsjfds", e.response.data.message);
            throw (e.response.data);
        }

    }
    async generateCode(data) {
        try {
            const response = await HttpInterceptor.put('verification', data);
            return response.data;
        } catch (e) {

            throw (e.response.data);
        }

    }
    async validateSurveyLink(data) {
        let url = "survey/remote/v2/verifysurveylink";
        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    async validateSurveyUser(data) {
        let url = "survey/remote/v2/verifyremoteuser";
        const response = await HttpInterceptor.post(url, data);
        return response.data;
    }
    
}


export default new ScCommonServices();


import { Modal } from "react-bootstrap";
import "./ProdList.scss";
const ConfrimPopup = (props) => {
    return(
        <Modal
        show={props.show}
        onHide= {props.hide}
        >
        <Modal.Header closeButton className='alert-modal-header'>
          <Modal.Title className= "alert-modal-title">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <span className="alert-modal-body">{props.description}</span>
        </Modal.Body>
        <Modal.Footer style={{borderTop:"none"}}>
                <button className="button-style secondary-btn" onClick={props.handleCancel}>
                    {props.btnCancel}
                </button>
                <button onClick={props.handleConfrim} className="button-style primary-btn">
                    {props.buttonName}
                </button>
        </Modal.Footer>
      </Modal>
    );
}

export default ConfrimPopup;
import React, { useEffect, useState } from "react";
import SSOService from "../services/sso_services";

const ClasslinkLogin = (props) => {
  
      const redirecttoClasslinkLogin = () => {
        let location= window.location;
        let origin;
        if(location.hostname==="localhost")
          origin = "http://"+location.host;
        else
          origin = location.origin;

        SSOService.getSSOConnectionCreds(origin, "classlink_conn_creds").then((responseurl) => {
          if(responseurl != "" && responseurl != undefined){
                    window.location.href= responseurl;
          }

        });
      }

  return (
    <>
    <a onClick={redirecttoClasslinkLogin} 
      className="btn btn-block sso-btn"
    >
      <img
        src="https://assets-global.website-files.com/5d6db64572061db9c481aaeb/60182c792aab73bd41f28d27_classlink-logo-vertical.png"
        title="Classlink"
        alt=""
        className="sso-btn-img"
      />
      <span className="sso-btn-text">
        {props.labels.labels.LBLSIGNINWITHCLASSLINK}
      </span>
    </a>
    </>)

}

export default ClasslinkLogin;



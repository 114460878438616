import React, { useEffect, useState } from "react";
import styles from "./CertificatePopUp.module.scss";
import { useSelector } from 'react-redux';
import CompanyLogo from "../../../admin/components/companylogo/companylogo";
import products_services from "../../../services/products_services";
import $ from 'jquery';

const CertifatePopUp = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [certificateDetails, setCertificateDetails] = useState();
    const company_logo = useSelector(state => state.user_info_reducer.user_info.company_logo);
    const dateformat = useSelector(state => state.user_info_reducer.user_info.dateformat);
    const user_id  = useSelector(state => state.user_info_reducer.user_info.user_id);

    useEffect(() => {
        var vParams = {
            productid:  props?.resultData[0]?.productid,
            userid: user_id, 
            languagecode: props?.resultData[0]?.languagecode,
            dateformat: dateformat
          };
          products_services
          .getproductunitscertificate(vParams)
          .then((res) => {
                const mergedCertificates = [
                    ...( props.showProductCertificate ? res.data.product_certificate || [] : []),
                    ...(props?.resultData[0]?.showunits_certificate === 1 && props?.resultData.length > 1 ? res.data.unit_certificate || [] : [])
                ];
    
                setCertificateDetails(mergedCertificates);
            })

        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            $("#currentCertificate")?.addClass('ios-device')
        }
    }, [props.show])

    console.log(certificateDetails,"certificateDetails")
    const handleActiveIndex = (currentVal) => {
        setActiveIndex(currentVal);
    }
    const handlePrint = (xid) => {
        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            $("#currentCertificate")?.addClass('ios-device')
        }
            window.print();
    };

    const checkDescription = (item) => {
         if(item?.productName){
          return item.productName;
         }else if(item.description !== "" && item.description !== null){
            return item.description;
         }else{
            return item.name;
         }
    }

    const getSuccessLabel = (labels,productId,unitId) => { 
        if(productId !== undefined ){ 
            return labels.LBLCRSSUCCESS;
        }else if(unitId && localStorage.getItem("site_type") === "Accutrain" || props.isModule){
            return labels.LBLMODULESUCESS;
        }else {
            return labels?.LBLUNITSUCESS;
            // LBLUNITSUCESS
        }
    }
    return (
        <>
            {props.show && (
                <>
                    <div className={styles.modal}>                        
                        <div className={styles.content}>                            
                        <div className={styles.certificateHeaderCtn}>
                        <div className={styles.certificate}>
                                    { certificateDetails?.length === 1 ? props?.labels?.LBLCERTIFICATE :  props?.labels?.LBLCERTIFICATES }
                                </div>
                    <div className={styles.CloseCtn} onClick={props.onClose}>                    
                                <div className={styles.closeInnerCtn} title="Close">
                                    <div className={styles.leftRight}></div>
                                    <div className={styles.rightLeft}></div>
                                </div>
                            </div>
                            
                                </div>
                            <div className={styles.certContentWrapper}>
                                <div className={styles.unitTitleCtn}>                                
                                    <div className={styles.unitNameWrapper}>
                                    {certificateDetails?.map((item, index) => (
                                        <>
                                            <ul key={index} onClick={() => handleActiveIndex(index)}  className={activeIndex === index ? styles.activeTab : styles.courseTab}>
                                                <li className={`grid-ellipsis ${styles.unitName}`} title = {item.name ? item.name : props.labels.LBLCOURSECERTIFICATE }>{item.name ? item.name : props.labels.LBLCOURSECERTIFICATE}</li>
                                                <li className={`${styles.unitDesc} grid-ellipsis`} title={item.description ? item.description : item.productName}>{item.description ? item.description : item.productName }</li>
                                            </ul>
                                            <hr style={{ marginBottom: "0px",marginLeft:"0px",width:"98%" }} />
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className={styles.certWrapper} id="currentCertificate">
                                    <div className={` ${styles.certBody} ${certificateDetails[activeIndex]?.productName === undefined && styles.unitCertBody}`} id = "certBody">
                                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                            {company_logo ? (
                                                <div  className={styles.certImage}>
                                                    <CompanyLogo className="col-md-6 col-sm-6" imageName={company_logo} />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            <div className={styles.certPrintBtn} style={{ marginLeft: "auto",position: "relative" }} onClick={() => handlePrint("currentCertificate")}>
                                                <img src="../images/print.png" width="100%" height="100%" alt="" id="hideprint"/>
                                            </div>
                                        </div>
                                        <div className={`${styles.certHdr} row`} >
                                            <span>{props.labels.LBLCERTOFCOMPLETION}</span>
                                        </div>
                                        {certificateDetails?.map((item, index) => {
                                            return activeIndex === index ? (
                                                <>
                                                <div>
                                                    <div className={`${styles.certNameHdr} row`}>
                                                        <span>{props.labels.LBLCERTIFYTHAT}</span>
                                                    </div>
                                                    <div className={`${styles.certUsrNameCtn} row`}>
                                                        <span className="cert-username">
                                                            {item.userName}
                                                        </span>
                                                    </div>
                                                    <div className={`${styles.certCrsNameHdr} row`}>
                                                        <span>{getSuccessLabel(props.labels,item?.productId,item?.unitId)}</span>
                                                    </div>
                                                
                                                </div>
                                                <div>
                                                    <div className={`${styles.certUsrNameCtn} row`}>
                                                        <span className={styles.certCoursename}>
                                                            {checkDescription(item) }
                                                        </span>
                                                    </div>
                                                    {certificateDetails[activeIndex].name ? 
                                                    <div>
                                                    <div className={`${styles.certCrsNameHdr} row`} >
                                                    <span>{props.labels?.LBLIN}</span>
                                                    </div>
                                                    <div className={`${styles.certUsrNameCtn} row`} >
                                                        <span>
                                                            {props.resultData[0]?.products}
                                                        </span>
                                                    </div>
                                                    </div>
                                                    :
                                                    null
                                                    }
                                                </div>
                                                </>
                                            ) : null;
                                        })}
                                        {certificateDetails?.map((item, index) => {
                                            return activeIndex === index ? (
                                        <div className={`${styles.certDatesignCtn} row`}>
                                            <div className="col-md-12 col-sm-12 text-center">
                                                <div className={`${styles.certDateValue} row`}>
                                                    {item.completedDate}
                                                </div>
                                                <div className={`${styles.certDateLabel} row`}>
                                                    {props.labels.LBLDATE.toUpperCase()}
                                                </div>
                                            </div>
                                        </div>
                                            ) : null;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default CertifatePopUp;
